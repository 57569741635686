import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import styles from '../CommonStyles';

const StyledParagraph = styled.p`
  margin: 0;
  padding: 0;
  display: inline-block;
  font: 800 16px/20px yorkten-slab-normal;
`;

const StyledPrimaryButton = styled(Button)`
  &.btn-light {
    background-color: #486d90;
  }
  &.btn-light:hover {
    background-color: #3c5a77;
  }
  &.btn-light:focus {
    background-color: #486d90;
  }
  &.btn-light:active,
  &.btn:active {
    background-color: #486d90;
  }
  &.btn-light .mainButtonCorner {
    border-bottom: 19px solid transparent;
    border-right: 19px solid #8cbf26;
  }
  &.btn-light:hover .mainButtonCorner {
    border-bottom: 19px solid transparent;
    border-right: 19px solid #8bc105;
  }
  &.btn:disabled {
    background-color: #56565a;
    opacity: 1;
    cursor: default;
  }
  &.btn:disabled .mainButtonCorner {
    border-right-color: transparent;
  }
`;
const StyledOutlineButton = styled(Button)`
  border-color: #707070;
  background-color: #ffffff;
  color: rgba(0, 74, 152, 1);
  text-align: center;
  font-size: 16px;
  font-family: benton-sans;
  font-weight: 600;
  height: 50px;
  width: 160px;
  :hover {
    color: rgba(0, 74, 152, 1);
  }
`;
const assignObj = (obj) => obj;

const WButton = (props) => {
  const {
    variant,
    active,
    disabled,
    size,
    type,
    block,
    buttonstyle,
    buttontext,
    buttontextstyle,
    onClick,
  } = props;
  if (variant.startsWith('outline')) {
    return (
      <StyledOutlineButton
        onClick={onClick}
        active={active}
        disabled={disabled}
        size={size}
        variant={variant}
        type={type}
        block={block}
        style={assignObj({ ...buttonstyle })}
      >
        <StyledParagraph style={buttontextstyle}>{buttontext}</StyledParagraph>
      </StyledOutlineButton>
    );
  }

  if (variant.startsWith('back')) {
    return (
      <Button
        onClick={onClick}
        className="backButton"
        active={active}
        disabled={disabled}
        size={size}
        variant={variant}
        type={type}
        block={block}
        style={assignObj({ ...styles.backButtonStyle, ...buttonstyle })}
      >
        {/* <div style={styles.arrow}>
                <div style={styles.arrowAfter} />
                <div style={styles.arrowBefore} />
            </div> */}
        {/* <img src={leftArrow} alt="left-arrow" style={styles.leftArrow}/> */}
        <StyledParagraph
          style={assignObj({
            ...styles.backButtonTextStyle,
            ...buttontextstyle,
          })}
        >
          {buttontext}
        </StyledParagraph>
      </Button>
    );
  }

  if (variant.startsWith('save')) {
    return (
      <Button
        onClick={onClick}
        className="saveButton"
        active={active}
        disabled={disabled}
        size={size}
        variant={variant}
        type={type}
        block={block}
        style={assignObj({ ...styles.saveButtonStyle, ...buttonstyle })}
      >
        <StyledParagraph
          style={assignObj({
            ...styles.backButtonTextStyle,
            ...buttontextstyle,
          })}
        >
          {buttontext}
        </StyledParagraph>
      </Button>
    );
  }

  return (
    <StyledPrimaryButton
      onClick={onClick}
      active={active}
      // disabled={disabled}
      size={size}
      variant={variant}
      type={type}
      block={block}
      style={
        disabled
          ? assignObj({ ...styles.disabledButtonStyle, ...buttonstyle })
          : assignObj({ ...styles.buttonStyle, ...buttonstyle })
      }
    >
      <StyledParagraph style={buttontextstyle}>{buttontext}</StyledParagraph>
      {disabled ? null : (
        <div style={styles.corner} className="mainButtonCorner" />
      )}
    </StyledPrimaryButton>
  );
};

WButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  type: PropTypes.oneOf(['button', 'submit', 'reset', 'null']),
  variant: PropTypes.string,
  buttontext: PropTypes.string,
  buttonstyle: PropTypes.instanceOf(Object),
  block: PropTypes.bool,
  buttontextstyle: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

WButton.defaultProps = {
  active: false,
  disabled: false,
  size: 'sm',
  type: 'button',
  variant: 'light',
  buttontext: 'Button',
  block: false,
  buttonstyle: {},
  buttontextstyle: {},
  onClick: () => {},
};

export default WButton;
