import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
// import { SelectInput } from 'common';
// import MaskedFormControl from 'react-bootstrap-maskedinput'

import classes from './Input.module.css';
// import MaskedInput from './MaskedInput';

// const assignObj = (obj) => {return obj};

export const MobileNumber = (props) => {
    const { changed, blurred, clicked, mobileNumberState, countryCodeState, opt, disable } = props
    const mobileRef = createRef();
    const data = {
        config: {
            id: "mobileNumber",
            type: 'text',
            label: 'Mobile Number',
            optional: '[ Optional ]',
            format: 'xxx-xxx-xxxx',
            options: [
                ...countryCodeState.options,
                { key: '+1', value: '+1' },
                { key: '+91', value: '+91' },
                { key: '+191', value: '+191' },
                { key: '+201', value: '+201' },
            ]
        },
        change: (event) => {
            if (event.target.id === 'mobileNumber') {
                changed(event, 'mobileNumber')
            } else {
                changed(event, 'countryCode')
            }
        },
        keyPress: (e) => {
            // console.log(e.keyCode)
            if((e.keyCode < 8 || e.keyCode > 40) && /\D/.test(e.key)){
                e.preventDefault();
            }
        },
        blur: () => {
            if(!disable)
            blurred('mobileNumber')
        },
        click: (event) => clicked(event),
        formatStyle: { font: '600 12px/20px benton-sans', color: '#56565A', margin: '6px 0 0 0', position: 'absolute' },
        right: { position: 'relative', top: 22}
    }

    // mobileRef.mask("000-000", false)

    let inputElement = null;
   //  let inputElementDisabled = null;
    const inputClasses = [classes.InputElement];
    const errorId = `${data.config.id}_error phno`;

    if (mobileNumberState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    // inputElement = <MaskedInput isReverse={false} mask={"00/0000"}/>
    inputElement =  <input
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={mobileNumberState.value}
        onKeyDown={data.keyPress}
        onChange={data.change}
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        maxLength={12}
        ref={mobileRef}
        aria-describedby={errorId}
        aria-invalid={!!mobileNumberState.errMsg}
        aria-required={!opt}
        readOnly={disable} />;
    
    const countryCode = <input
        id="countryCode"
        type="text"
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value="+1"
        onChange={data.change}
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        maxLength={4}
        aria-label="Country Code"
        labelrow="true"
        readOnly />;
        
    /* const countryCode = <SelectInput
        id="countryCode"
        value={countryCodeState.value}
        onChange={data.change}
        itemlist={data.config.options}
        label={null}
        ariaLabel="Country Code"
        labelrow="true" />; */

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor={data.config.id}>
                {data.config.label}{opt ? <span className={classes.optional}>{data.config.optional}</span> : null}
            </label>
            <Row>
                <Col md={4} lg={4} xl={4}>{countryCode}</Col>
                <Col md={8} lg={8} xl={8}>
                    {inputElement}
                    <p style={data.formatStyle} id="phno" >{data.config.format}</p>
                    {mobileNumberState.errMsg?<p className={classes.errMsg} style={data.right} aria-live="polite" aria-atomic="true"  id={errorId} >{mobileNumberState.errMsg}</p>:null}
                </Col>
            </Row>
        </div>
    );

};

MobileNumber.propTypes = {
    opt: PropTypes.bool,
    countryCodeState: PropTypes.instanceOf(Object),
    mobileNumberState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            maxLength: PropTypes.number,
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    disable: PropTypes.bool
}

MobileNumber.defaultProps = {
    opt: true,
    countryCodeState: {},
    mobileNumberState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            maxLength: 30,
            required: true
        }
    },
    changed: () => { },
    blurred: () => { },
    disable: false
}

export default MobileNumber;