import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import Config from 'env/Config';
import {
    put,
    post,
    get,
    remove,
    setMSRAuthHeaders,
    setFisTokenAuthHeaers,
    addAPIVersionHeader,
    removeAPIVersionHeader,
} from './Services';
import { FundAccountActionTypes } from '../ReduxConstants';
import { getSessionValues } from 'shared/Helpers/Utils';

const AccountOptionsApiEvents = () => {
    ApiEventHub.on(ActionTypes.GET_BANK_ACCOUNT_LIST, data => {
        const { token, vcmid } = data.payload;
        setMSRAuthHeaders(token, vcmid);
        get(
            Config.getbankaccounts,
            data.payload,
            data.endPointURL.msrBankAccountList,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS, data => {
        post(
            Config.addBank,
            data.payload.dataToPost,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.AUTO_INVEST_GET_PAD_DETAILS, data => {
        const xApikey = Config.msrPortfolioSummaryKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload.msrParams;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        addAPIVersionHeader('2023-10-06')

        get(
            Config.msrrtBankToFundAcc,
            '',
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS, data => {
        setFisTokenAuthHeaers();
        put(
            Config.updatePADDetails,
            data.payload,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS, data => {
        setFisTokenAuthHeaers();
        post(
            Config.addPADDetails,
            data.payload,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS, data => {
        post(
            Config.addSWPDEtails,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SKIP_INVEST_WITHDRAW_PLAN, data => {
        post(Config.skippadtransaction, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    
    ApiEventHub.on(ActionTypes.DELETE_INVEST_WITHDRAW_PLAN, data => {
        setFisTokenAuthHeaers();
        remove(
            Config.deleteAutoInvestPlan,
            data.payload,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS, data => {
        post(Config.getSWPDetails, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN, data => {
        post(Config.skipSWP, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS, data => {
        put(
            Config.updateSWPDetails,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS, data => {
        remove(
            Config.deleteSWP,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_BENEFICIARIES_MASTER_REGS, data => {
        const xApikey = Config.msrManageBenificiaryKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        addAPIVersionHeader('2023-10-06')
        get(
            Config.getMsrManageBenificiary,
            '',
            data.endPointURL.msrGetBenificiary,
            data.successCallBack,
            data.errorCallback,
        );
    });


    ApiEventHub.on(ActionTypes.GET_BENEFICIARY, data => {

        const xApikey = Config.msrManageBenificiaryKey;

        const params = getSessionValues();

        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, xApikey, params.fismemberid);
        post(
            Config.getMsrBenificiary,
            { masterRegId: data.payload.masterRegId },
            data.endPointURL.msrGetBenificiary,
            data.successCallBack,
            data.errorCallback,
        );
    });

   

    ApiEventHub.on(ActionTypes.DELETE_BENEFICIARY, data => {
        remove(
            Config.deleteBeneficiaries,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES, data => {
        const xApikey = Config.msrStatementSettingKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        addAPIVersionHeader('2023-10-06')
        get(
            Config.msrStatementSetting,
            '',
            data.endPointURL.msrStatementSettingAPI,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE, data => {
        post(
            Config.openedAccountsPreferences,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_CHECKBOOK_INFO, data => {
        const xApikey = Config.msrCheckbookKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        get(
            Config.getMsrCheckbookInfo,
            '',
            data.endPointURL.msrCheckBookInfo,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.UPDATE_CHECKBOOK, data => {
        put(
            Config.updateCheckbook,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_ACCOUNT_PREFERENCE, data => {
        get(
            Config.getAccountPreference,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(FundAccountActionTypes.GET_AIP_FUND_PLAN_DETAILS, data => {
        // console.log('data ',data);
        const xApikey = Config.msrTransactionKey;
        const { accesstoken, vcmid, msrid, fismemberid = '', editDetails = [] } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        addAPIVersionHeader('2023-10-06')
        get(
            Config.msrBankToFundPad,
            `?${Object.keys(editDetails)
                .map(key => `${key}=${encodeURIComponent(editDetails[`${key}`])}`)
                .join('&')}`,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(FundAccountActionTypes.GET_SWP_FUND_PLAN_DETAILS, data => {
        const xApikey = Config.msrTransactionKey;
        const { accesstoken, vcmid, msrid, fismemberid = '', editDetails = [] } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        addAPIVersionHeader('2023-10-06')
        get(
            Config.msrGetSingleSWP,
            `?${Object.keys(editDetails)
                .map(key => `${key}=${encodeURIComponent(editDetails[`${key}`])}`)
                .join('&')}`,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.EDIT_ACCOUNT_PREFERENCE, data => {
        post(
            Config.editAccountPreference,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_BTF_ACCOUNT_LIST, data => {
        get(
            Config.rtBankToFundForAdd,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.ADD_NEW_BTF_ACCOUNT, data => {
        post(
            Config.addRTBankToFundAccount,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });
    // for add page
    ApiEventHub.on(ActionTypes.GET_RMD_ACCOUNTS, data => {
        const xApikey = Config.msrAccountOptionKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        get(Config.msrGetRMDAcc, '', data.endPointURL.msrAccountOptions, data.successCallBack, data.errorCallback);
    });
    // for listing page
    ApiEventHub.on(ActionTypes.GET_RMD_TRANSACTIONS, data => {
        const xApikey = Config.msrTransactionKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        get(
            Config.msrRMDTransactions,
            '',
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });
    // add new RMD
    ApiEventHub.on(ActionTypes.ADD_RMD_TRANSACTIONS, data => {
        post(
            Config.addRMDTransaction,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });
    // delete RMD
    ApiEventHub.on(ActionTypes.DELETE_RMD_TRANSACTIONS, data => {
        remove(
            `${Config.deleteRMDTransaction}/${data.payload.accountNumber}`,
            undefined,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });
    // get tax state group
    ApiEventHub.on(ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP, data => {
        const xApikey = Config.msrAccountOptionKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        post(
            Config.getStateTaxesForGroup,
            data.payload.stateTaxPayload,
            data.endPointURL.msrTransactions,
            data.successCallBack,
            data.errorCallback,
        );
    });
    // get state code group
    ApiEventHub.on(ActionTypes.RMD_GET_STATE_CODE_GROUP, data => {
        const xApikey = Config.msrAPIKey;
        const { accesstoken, vcmid, msrid, fismemberid = '' } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
        get(
            `${Config.getStateCodeGroup}?masterReg=${data.payload.masterReg}&companyNumber=${data.payload.companyNumber}`,
            undefined,
            data.endPointURL.msrTransactions,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default AccountOptionsApiEvents;
