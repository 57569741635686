import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";


const money={
    data:[
        {
            key: '1',
            movementType: 'Automatic Withdrawal',
            account: 'Individual',
            fund: 'USIFX',
            fromTo: 'Bank1',
            amount: '100',
            frequency: 'Semi Annually',
            onTheDate: '21',
            lastActivity: '21/06/19',
            nextActivity: '21/01/20'
        },
        {
            key: '2',
            movementType: 'Automatic Investment',
            account: 'Joint',
            fund: 'USSPX',
            fromTo: 'Bank2',
            amount: '50',
            frequency: 'Monthly',
            onTheDate: '15',
            lastActivity: '15/11/19',
            nextActivity: '15/12/20'
        },
        {
            key: '3',
            movementType: 'Automatic RMD',
            account: 'IRA',
            fund: 'USAGX',
            fromTo: 'Bank1',
            amount: '2000',
            frequency: 'Quarterly',
            onTheDate: '1',
            lastActivity: '01/10/19',
            nextActivity: '01/01/20'
        }
    ]
};
const initialState = {
    isLoading:false,
    isSuccess:false,
    isFailure:false,
    movementList:money
};

function moneyAssestReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.MONEY_ASSEST_LOADING: {
            return {
                ...state,
                ...action,
                isLoading:true,
                isSuccess:false,
                isFailure:false,
            };
        }
        case ActionTypes.MONEY_ASSEST_SUCCESS: {
            return {
                ...state,
                isLoading:false,
                isSuccess:true,
                isFailure:false,
                movementList:money
            };
        }
        case ActionTypes.MONEY_ASSEST_FAILURE: {
            return {
                ...state,
                ...action,
                isLoading:false,
                isSuccess:false,
                isFailure:true,
            };
        }
        case ActionTypes.RESET_MOVEMENT_LIST:{
            return{
              ...state,
              isLoading: false,
              isSuccess: false,
              isError:false,
              movementList:{}
            };
          }
        default:
            return state;
    }
}

export default moneyAssestReducer;