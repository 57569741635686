import downArrow from 'assets/Icon_Dropdown_arrow.svg';

const outlineButtonStyle = {
    borderColor: '#707070',
    backgroundColor: '#FFFFFF',
    color: '#544A54',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'benton-sans',
    fontWeight: 600,
    height: 50,
    width: 160,
};

const buttonStyle = {
    /* backgroundColor: '#486D90', */
    borderRadius: 0,
    border: 'none',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'benton-sans',
    fontWeight: 600,
    height: 50,
    width: 160,
    position: 'relative',
    outlineOffset: '10px',
};

const disabledButtonStyle = {
    backgroundColor: '#D2D2D2',
    borderRadius: 0,
    border: 'none',
    color: 'rgb(73, 73, 74)',
    textAlign: 'center',
    font: '900 16px/20px yorkten-slab-normal',
    opacity: 1,
    height: 50,
    width: 160,
};

const backButtonStyle = {
    backgroundColor: 'none',
    border: 'none',
    color: '#004a98',
    fontSize: 16,
    fontWeight: 900,
    paddingLeft: 40,
    paddingTop: 2,
};

const cancelButtonStyle = {
    backgroundColor: 'none',
    border: 'none',
    color: '#004a98',
    fontSize: 16,
    fontWeight: 900,
    paddingLeft: 25,
    paddingTop: 2,
};

const saveButtonStyle = {
    backgroundColor: 'none',
    border: 'none',
    color: '#004a98',
    fontWeight: 900,
    fontSize: 16,
    paddingLeft: 25,
};

const corner = {
    width: '0',
    height: '0',
    // borderBottom: "19px solid transparent",
    // borderRight: "19px solid #8CBF26",
    position: 'absolute',
    top: '0',
    right: '0',
};

const backButtonTextStyle = {
    position: 'relative',
    top: '2px',
};

const uploadLabelStyle = {
    backgroundColor: '#707070',
    borderRadius: '4px',
    border: '1px rgba(210, 210, 210, 1)',
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'benton-sans',
    fontWeight: 200,
    height: '100%',
    width: 150,
    padding: 11,
    position: 'relative',
    marginBottom: 0,
};

const dropZoneStyle = {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems:'center',
    // justifyContent:'space-between',
    overflow: 'hidden',
};

const previewStyle = {
    border: 0,
};

const dropZoneLabel = {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(73, 73, 74, 1)',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'benton-sans',
};

const cardStyle = {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100%',
    marginBottom: 10,
    borderRadius: 0,
    border: '1px solid #D2D2D2',
    position: 'relative',
};
const leftCornerCard = {
    width: 0,
    height: 0,
    borderBottom: '35px solid transparent',
    borderLeft: '35px solid #D2D2D2',
    position: 'absolute',
    top: -2,
    left: -2,
};

const cardTitleStyle = {
    paddingBottom: 10,
    color: '#486D90',
    fontWeight: 'bold',
    textAlign: 'left',
    font: '800 18px/22px yorkten-slab-normal',
    marginBottom: 15,
};

const cardTextStyle = {
    font: '500 16px/20px benton-sans',
    color: 'rgb(73, 73, 74)',
    float: 'left',
    textAlign: 'left',
};

const cardsubtitletextStyle = {
    font: 'Regular 16px/24px benton-sans',
    color: 'rgba(73, 73, 74, 1)',
    textAlign: 'left',
};

const modalTitleStyle = {
    color: '#000000',
    fontSize: 26,
    textAlign: 'left',
    marginBottom: 30,
    fontFamily: 'benton-sans',
};

const modalBodyStyle = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 13,
    textAlign: 'left',
    fontFamily: 'benton-sans',
    marginTop: 20,
};

const modalHeaderStyle = {
    border: 0,
    marginRight: 50,
    marginTop: 26,
    paddingTop: 100,
};

const modalFooterStyle = {
    border: 0,
};

const separatorStyle = {
    marginTop: 16,
    marginBottom: 16,
    borderBottomWidth: 'thin',
    borderBottomStyle: 'ridge',
    borderBottomColor: 'rgba(0,0,0,.1)',
};

const radioButtonLayout = {
    width: '80%',
    /* borderWidth : 2,
    marginRight : "50%", */
    marginTop: '4%',
    flexDirection: 'row',
    marginBottom: '2%',
};

const innerCircle = {
    height: 11,
    width: 11,
    borderRadius: '50%',
    backgroundColor: 'rgba(79, 121, 159, 1)',
    border: '1px solid rgba(79, 121, 159, 1)',
};

const outerCircle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderColor: 'black',
    borderWidth: 'thin',
    borderRadius: 12,
    height: 20,
    width: 20,
    borderStyle: 'solid',
    marginRight: 20,
};

const radioDescStyles = {
    display: 'block',
    font: '400 16px/19px benton-sans',
    letterSpacing: 0,
    color: 'rgba(73, 73, 74, 1)',
    opacity: 1,
};

const radioText = {
    paddingTop: 2,
    textAlign: 'left',
    font: '500 16px/20px benton-sans' /* updated with design library line height */,
    letterSpacing: 0,
    color: 'rgba(73, 73, 74, 1)',
    opacity: 1,
};

const radioWithDesc = {
    textAlign: 'left',
};

const radiobtnstyles = {
    position: 'absolute',
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    border: '1px solid rgba(97, 99, 102, 1)',
    borderRadius: '50%',
    height: 32,
    width: 32,
    marginRight: 20,
};

const radioStyles = {
    paddingLeft: 30,
    position: 'relative',
    minHeight: 40,
    marginBottom: 5,
    color: '#56565A',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    verticalAlign: 'middle',
};

const outerSquare = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderColor: 'black',
    borderWidth: 'thin',
    height: 18,
    width: 18,
    borderStyle: 'solid',
    marginRight: 20,
    flexDirection: 'baseline',
};

const innerSquare = {
    height: 10,
    width: 10,
    backgroundColor: '#161414',
    borderColor: 'black',
    borderWidth: 'thin',
    borderStyle: 'solid',
};

const stepperCol = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderColor: 'black',
    borderWidth: 'thin',
    borderStyle: 'solid',
    flexDirection: 'center',
    padding: 5,
    margin: 5,
    textAlign: 'center',
};

const stepperLine = {
    marginTop: 45,
    marginBottom: 25,
    borderTopStyle: 'solid',
    borderTopColor: 'black',
};

const radioStyle = {
    appearance: 'initial',
    background: 'white',
    borderColor: 'black',
    borderWidth: 'thin',
    height: 18,
    width: 18,
    borderStyle: 'solid',
    position: 'relative',
    borderRadius: 12,
};

const checkBoxStyle = {
    appearance: 'initial',
    background: 'white',
    borderColor: 'black',
    borderWidth: 'thin',
    height: 18,
    width: 18,
    borderStyle: 'solid',
    position: 'relative',
    display: 'block',
    '&input[type="checkbox"]:checked:after': {
        backgroundColor: 'black',
    },
};

const checkBoxStyleSelected = {
    content: '',
    appearance: 'initial',
    background: 'white',
    borderColor: 'black',
    borderWidth: 'thin',
    height: 9,
    width: 9,
    borderStyle: 'solid',
    position: 'absolute',
    display: 'block',
    left: ' 50%',
    top: ' 50%',
};

const fontawesomeIconStyles = {
    height: 20,
    width: 20,
    margin: '20px 20px 10px 20px',
    lineHeight: 'unset',
};

const cardimgstyle = {
    position: 'absolute',
    right: 0,
    bottom: 0,
};

const fontawesomeIconCard = {
    height: 20,
    width: 20,
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 11,
    right: 11,
    color: 'rgba(172, 172, 172, 1)',
};

const checkBoxLabelStyle = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    fontFamily: 'benton-sans',
    fontWeight: '500',
    marginBottom: 0,
    marginRight: 5,
    lineHeight: '22px',
};

const checkBoxSubLabelStyle = {
    color: 'rgba(73, 73, 74, 1)',
    fontFamily: 'benton-sans',
    fontWeight: '500',
    marginBottom: 25,
    marginLeft: 40,
};

const labelStyle = {
    font: 'Bold 16px/28px benton-sans',
    color: 'rgba(73, 73, 74, 1)',
};

const spinnerDiv = {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9999,
    top: '0px',
    left: '0px',
};

const spinnerStyle = {
    position: 'fixed',
    top: '40%',
    left: '45%',
    zIndex: 3,
};

const popupModalStyle = { width: 600, left: '50%', top: '50%', transform: 'translate(-50%,-50%)', overflowY: 'hidden' };
const popupModalHeaderStyle = { borderBottom: 'none', marginLeft: '5%' };

const popupModalFooterStyle = { borderTop: 'none', justifyContent: 'center' };

const popupModalHeaderTextStyle = { font: '26px/36px benton-sans', color: '#000000' };

const popupModalBodyStyle = { marginLeft: '5%' };

const popupModalBodyTextStyle = { font: '15px benton-sans', color: 'rgba(73, 73, 74, 1)' };

const popupModalDivStyle = { width: 500, marginLeft: 35 };

const popupModalButtonTextStyle = { color: '#FFFFFF', font: 'Medium 14px benton-sans' };

const popupModalButtonStyle = { backgroundColor: '#5D83AE', borderColor: '#5D83AE', marginLeft: 40 };

const paddingZeroStyle = {
    padding: 0,
};

const contCard = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #7070701A',
    opacity: 1,
    width: '350px',
    margin: '15px',
    // minHeight: '243px'
};
const contactInnerDiv = {
    minHeight: '116px',
    textAlign: 'left',
    letterSpacing: 0,
    color: '#56565A',
    opacity: 1,
    padding: '20px 22px 0px',
    position: 'relative',
};
const dottedLine = {
    width: '307px',
    height: '0px',
};

const menuLine = {
    border: '1px solid #707070',
    opacity: '0.25',
    marginTop: '18px',
    width: '70px',
};

const basicLabelStyle = {
    fontSize: '30px',
    color: '#486D90',
    borderBottom: '2px solid #8BC105',
    fontWeight: 800,
    paddingBottom: '3px',
    fontFamily: 'yorkten-slab-normal',
    float: 'left',
    marginBottom: '30px',
};

const leftCornerStyle = {
    width: 0,
    height: 0,
    borderBottom: '35px solid transparent',
    borderLeft: '35px solid #8BC105',
    position: 'absolute',
};

const basicLabelStyle1 = {
    height: '32px',
    textAlign: 'left',
    /* font: 'Bold 22px/45px benton-sans', */
    letterSpacing: 0,
    color: '#486D90',
    opacity: 1,
    marginBottom: '20px',
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '45px',
    fontFamily: 'yorkten-slab-normal',
};

const lineStyle1 = {
    height: '0px',
    border: '1px solid #707070',
    opacity: '0.25',
    marginTop: '5px',
};

const lineStyle = {
    height: '0px',
    border: '1px solid #8BC105',
    opacity: '0.50',
    marginTop: '0px',
};

const bottomLine1 = {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: 0,
    color: 'rgb(73, 73, 74)',
    opacity: 1,
};

const manageStyle = {
    height: '21px',
    letterSpacing: 0,
    color: '#004A98',
    opacity: 1,
    backgroundColor: '#ffffff',
    border: 'none',
    padding: 0,
    paddingBottom: '23px',
    /* width: 110, */
    fontWeight: 'bold',
    fontFamily: 'yorkten-slab-normal',
};

const errorStyle = {
    color: 'rgba(230, 0, 0, 1)',
    font: ' 700 12px/22px benton-sans',
    position: 'absolute',
};

const errorSSNStyle = {
    color: 'rgba(230, 0, 0, 1)',
    font: ' 700 12px/22px benton-sans',
    marginTop: '3.25rem',
    position: 'absolute',
    /* // paddingLeft: 10, */
};

const leftArrow = {
    width: '33px',
    height: '33px',
    marginRight: 9,
};

const select = {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    height: '50px',
    borderRadius: '0',
    background: `url(${downArrow}) no-repeat right`,
    backgroundOrigin: 'content-box',
};

const selectArrow = {
    position: 'absolute',
    right: '20px',
    top: '16px',
    width: '10px',
    height: '10px',
    border: 'solid #4F799F',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

const selectArrowSpecial = {
    position: 'absolute',
    right: '20px',
    top: '37%',
    width: '10px',
    height: '10px',
    border: 'solid #4F799F',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

const inputLabel = {
    marginBottom: 25,
    alignItems: 'center',
};

const inputLabelText = {
    font: '600 16px/19px benton-sans',
    color: 'rgb(73, 73, 74)',
};

const selectLabel = {
    font: '600 14px/20px benton-sans',
    color: '#56565A',
    marginBottom: 5,
};

const subLabel = {
    font: '500 12px/20px benton-sans',
    color: '#56565A',
    opacity: 1,
    marginLeft: 10,
    position: 'relative',
    top: -1,
};

const inputFieldStyle = {
    height: '48px',
    borderRadius: '0',
    border: '1px solid #D2D2D2',
};

const saveIcon = {
    width: '21px',
    height: '22px',
    marginRight: 5,
};

const mangeCornerCard = {
    borderBottom: '38px solid transparent',
    borderLeft: '38px solid #486D90',
    position: 'absolute',
    margin: '-21px -21px -21px -23px',
};

const ssnSecondBoxStyle = {
    marginLeft: 10,
};

const editStyle = {
    width: '80px',
    height: '20px',
    textAlign: 'center',
    letterSpacing: 0,
    color: '#004A98',
    textTransform: 'capitalize',
    opacity: 1,
    backgroundColor: 'white',
    border: 'none',
    fontFamily: 'benton-sans !important',
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '20px',
};

const deleteStyle = {
    width: '80px',
    height: '20px',
    textAlign: 'center',
    letterSpacing: 0,
    color: '#004A98',
    textTransform: 'capitalize',
    opacity: 1,
    backgroundColor: 'white',
    border: 'none',
    padding: 0,
    fontFamily: 'benton-sans !important',
    fontWeight: 'bold',
    fontSize: '14px',
};

const menuIconStyle = {
    color: '#004A98',
    opacity: 1,
    backgroundColor: 'white',
    border: 'none',
    marginRight: '-0.9rem',
    marginTop: '-1.7rem',
    float: 'right',
};

const tableIconStyle = { height: '', width: '', margin: '0', color: '#8BC105', position: 'absolute', left: 0, top: 0 };

const tableIconStyleRed = {
    height: '',
    width: '',
    margin: '0',
    color: 'rgba(230, 0, 0, 1)',
    position: 'absolute',
    left: 0,
    top: 0,
};
const tooltipFontawesomestyle = {
    position: 'absolute',
    color: '#004A98',
    top: 10,
    right: 10,
    fontSize: 12,
    margin: 0,
};
const ssnHorizontalStyle = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
};

const zeroPadding = {
    padding: 0,
};

const infoMessage = {
    font: '500 18px/20px benton-sans',
    // fontStyle: 'italic',
    display: 'inline-block',
};

export default {
    menuIconStyle,
    deleteStyle,
    bottomLine1,
    zeroPadding,
    ssnHorizontalStyle,
    ssnSecondBoxStyle,
    errorStyle,
    paddingZeroStyle,
    popupModalStyle,
    popupModalButtonStyle,
    popupModalButtonTextStyle,
    popupModalDivStyle,
    popupModalHeaderTextStyle,
    popupModalBodyTextStyle,
    popupModalBodyStyle,
    popupModalFooterStyle,
    popupModalHeaderStyle,
    spinnerStyle,
    spinnerDiv,
    buttonStyle,
    outlineButtonStyle,
    cardTextStyle,
    cardTitleStyle,
    cardStyle,
    cardsubtitletextStyle,
    leftCornerCard,
    modalTitleStyle,
    modalBodyStyle,
    separatorStyle,
    radioButtonLayout,
    outerCircle,
    innerCircle,
    innerSquare,
    outerSquare,
    stepperCol,
    stepperLine,
    radioStyle,
    checkBoxStyle,
    checkBoxStyleSelected,
    fontawesomeIconStyles,
    fontawesomeIconCard,
    cardimgstyle,
    uploadLabelStyle,
    dropZoneLabel,
    dropZoneStyle,
    previewStyle,
    checkBoxLabelStyle,
    checkBoxSubLabelStyle,
    modalHeaderStyle,
    modalFooterStyle,
    radioStyles,
    radioDescStyles,
    radioText,
    radioWithDesc,
    radiobtnstyles,
    labelStyle,
    contCard,
    contactInnerDiv,
    dottedLine,
    basicLabelStyle,
    lineStyle,
    manageStyle,
    corner,
    backButtonStyle,
    cancelButtonStyle,
    disabledButtonStyle,
    leftArrow,
    backButtonTextStyle,
    saveButtonStyle,
    select,
    selectArrow,
    selectArrowSpecial,
    inputLabel,
    selectLabel,
    inputLabelText,
    subLabel,
    inputFieldStyle,
    saveIcon,
    mangeCornerCard,
    menuLine,
    basicLabelStyle1,
    lineStyle1,
    tableIconStyle,
    tableIconStyleRed,
    editStyle,
    tooltipFontawesomestyle,
    errorSSNStyle,
    leftCornerStyle,
    infoMessage,
};
