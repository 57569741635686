import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const {
    ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE,
    ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS,
    ADD_NEW_BANK_ACCOUNT_DETAILS,
    ADD_BANK_ACCOUNT_DETAILS,
    ADD_BANK_ACCOUNT_INFO,
    BANK_ACCOUNT_DETAILS_RESET,
    DELETE_BANK_ACCOUNT_DETAILS_FAILURE,
    DELETE_BANK_ACCOUNT_DETAILS_SUCCESS,
    DELETE_BANK_ACCOUNT_DETAILS,
    DELETE_BANK_ACCOUNT_INFO,
    GET_BANK_ACCOUNT_DETAILS_LOADER,
    GET_BANK_ACCOUNT_DETAILS_FAILURE,
    GET_BANK_ACCOUNT_DETAILS_SUCCESS,
    GET_BANK_ACCOUNT_DETAILS,
    GET_BANKACCOUNTS,
    VALIDATE_BANK_ACCOUNT_FAILURE,
    VALIDATE_BANK_ACCOUNT_SUCCESS,
    VALIDATE_BANK_ACCOUNT,
    GET_BANK_ACCOUNT_LIST,
    GET_BANK_ACCOUNT_LIST_SUCCESS,
    GET_BANK_ACCOUNT_LIST_FAILURE,
    ADD_BANK_ACCOUNT_NAO_TRANS_ERROR,
    ADD_BANK_ACCOUNT_NAO_TRANS_LOADER,
    ADD_BANK_ACCOUNT_NAO_TRANS_SUCCESS,
    ADD_BANK_ACCOUNT_NAO_TRANS_RESET,
    VERIFY_BANK_ACCOUNT_DETAILS_RESET,
    ADD_BANK_ACCOUNT_CLEAR_ERROR,
    GET_BROKEREAGE_BANK_ACCOUNTS_LIST_SUCCESS,
    GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE,
    RESET_BROKEREAGE_BANK_ACCOUNTS_LIST
} = ActionTypes;

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: {},
    bankDetails: [],
    verificationData: {},
    bankDisplayRules: [],
    bankAccountList: [],
    brokereageBankList:[],
    isBrokerageBankAccountSuccess:false
};

function bankAccountReducer(state = initialState, action = {}) {
    switch (action.type) {
        case DELETE_BANK_ACCOUNT_INFO: {
            const info = {};
            let bankAccountInfo = [];
            let index = -1;
            bankAccountInfo = action.state;
            bankAccountInfo.map(item => {
                if (item.accountNumber === action.accountNumber) {
                    index = bankAccountInfo.indexOf(item);
                }
                return index;
            });
            if (index !== -1) {
                bankAccountInfo.splice(index, 1);
            }
            info.bankAccountInfo = bankAccountInfo;
            return {
                ...info,
                ...action,
            };
        }

        case ADD_BANK_ACCOUNT_INFO: {
            const info = {};
            let bankAccountInfo = [];
            bankAccountInfo = action.state;
            bankAccountInfo.push(action.newAccountInfo);
            info.bankAccountInfo = bankAccountInfo;
            return {
                ...info,
                ...action,
            };
        }
        // ### END ###
        case GET_BANK_ACCOUNT_DETAILS:
        case VALIDATE_BANK_ACCOUNT:
        case ADD_NEW_BANK_ACCOUNT_DETAILS:
        case GET_BANKACCOUNTS:
        case GET_BANK_ACCOUNT_LIST:
        case DELETE_BANK_ACCOUNT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ADD_BANK_ACCOUNT_DETAILS: {
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
            };
        }
        // Get bank account actions
        case GET_BANK_ACCOUNT_DETAILS_LOADER: {
            return {
                ...state,
                isLoading: action.response.isLoading,
                isError: action.response.isError,
                isSuccess: false,
            };
        }
        case GET_BANK_ACCOUNT_DETAILS_SUCCESS: {
            return {
                ...state,
                bankDetails: action.response.bankList,
                // bankDetails: action.response.bankList.filter(data => data.bankName === 'AMERICAN BANK'),
                bankDisplayRules: action.response.rule_l07,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }

        case GET_BROKEREAGE_BANK_ACCOUNTS_LIST_SUCCESS: {
            return {
                ...state,
                brokereageBankList: action.response?.bankList,
                accountHolderName: action.response?.accountHolderName,
                bankDisplayRules: action.response?.rule_l07,
                isLoading: false,
                isSuccess: true,
                isError: false,
                isBrokerageBankAccountSuccess:true
            };
        }

        case GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE: {
            return {
                ...state,
                brokereageBankList: [],
                error: action.response,
                isError: true,
                isSuccess: false,
                isLoading: false,
                isBrokerageBankAccountSuccess:false
            };
        }

        case ADD_BANK_ACCOUNT_NAO_TRANS_SUCCESS: {
            const isBankInfoAdded = action.response && action.response.result !== null;
            return {
                ...state,
                bankDetails: isBankInfoAdded
                    ? [...action.response.result.addBankData.bankInformations]
                    : state.bankDetails,
                apiResponse: action.response && action.response.message,
                responseMessage: action.response && action.response.response,
                responseCode: action.response && action.response.responseCode,
                successMessage: isBankInfoAdded ? action.response.result.addBankStatus : '',
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        case ADD_BANK_ACCOUNT_NAO_TRANS_ERROR: {
            return {
                ...state,
                apiResponse: (action.response && action.response.message) || 'Falied', // typo in api response. Hence giving this spelling.
                responseCode: (action.response && action.response.responseCode) || '4501',
                responseMessage: action.response && action.response.response,
                isLoading: false,
                isSuccess: false,
                isError: true,
            };
        }

        case ADD_BANK_ACCOUNT_NAO_TRANS_LOADER: {
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false,
            };
        }
        case ADD_BANK_ACCOUNT_NAO_TRANS_RESET: {
            return {
                ...state,
                apiResponse: '',
                responseMessage: '',
                responseCode: '',
                successMessage: '',
            };
        }
        case GET_BANK_ACCOUNT_LIST_SUCCESS: {
            return {
                ...state,
                bankAccountList: action.response.bankList,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        case GET_BANK_ACCOUNT_DETAILS_FAILURE: {
            return {
                ...state,
                bankDetails: [],
                error: action.response,
                isError: true,
                isSuccess: false,
                isLoading: false,
            };
        }
        case VALIDATE_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                verificationData: action.response,
            };
        }
        case ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                addBankResponse: action.response,
            };
        }
        case DELETE_BANK_ACCOUNT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };
        }
        case VALIDATE_BANK_ACCOUNT_FAILURE:
        case ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE:
        case GET_BANK_ACCOUNT_LIST_FAILURE:
        case DELETE_BANK_ACCOUNT_DETAILS_FAILURE: {
            return {
                ...state,
                error: action.response,
                isLoading: false,
                isError: true,
            };
        }
        case BANK_ACCOUNT_DETAILS_RESET: {
            return {
                ...initialState,
            };
        }
        case VERIFY_BANK_ACCOUNT_DETAILS_RESET:{
            return{
                ...state,
                verificationData:{}
            };
        }
        case ADD_BANK_ACCOUNT_CLEAR_ERROR:{
            return{
                ...state,
                isError:false,
                error:{},
                verificationData:{}
            };
        }
        case RESET_BROKEREAGE_BANK_ACCOUNTS_LIST:{
            return{
                ...state,
                isBrokerageBankAccountSuccess:false,
                //brokereageBankList:[]
            }
        }
        default:
            return state;
    }
}

export default bankAccountReducer;
