import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";
import decrypt from '../Helpers/decrypt';
import { getDecryptedBankDetailsRedirectFlow } from "./BankAccountAction";


export const getMarketNAOInfo = (payload) => {
    // console.log('function called',payload)
    return async dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.GET_MARKET_NAO_INFO_LOADER,
            // payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.GET_MARKET_NAO_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.GET_MARKET_NAO_INFO_SUCCESS,
                            payload: response.data,
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.GET_MARKET_NAO_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_MARKET_NAO_INFO_ERROR,
                payload: error,
            });
        }
    };
}



export const postMarketNAOInfo = (payload) => {
    return async dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.POST_MARKET_NAO_INFO_LOADER,
            // payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {

                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_INFO_FAILURE,
                            payload: error.response.data,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_INFO_ERROR,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}



export const marketNAOPersonalInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}



export const marketNAOPersonalAddressInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}

export const marketNAOPersonalContactInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}

/// 
export const marketNAOPersonalEmploymenttInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}


export const marketNAOPersonalFinancialInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}


export const marketNAOPersonalMilitaryInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url

                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url

                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_FAILURE,
                payload: error,
                responseOf: payload.url

            });
        }
    };
}

///


/// 
export const marketNAOTrustedContactInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_SUCCESS,
                            payload: response.data,
                            responseOf: payload.url
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                            responseOf: payload.url
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE,
                payload: error,
                responseOf: payload.url
            });
        }
    };
}

export const marketNAODeliveryPreferencesInfo = (payload) => {
    return async dispatch => {
        const env = '';
        try {
            makeApiRequest(
                ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_SUCCESS,
                            payload: response.data,
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE,
                payload: error,
            });
        }
    };
}

///
export const updateMarketNAOInfo = (payload) => dispatch => {  
    dispatch({
        type: ActionTypes.UPDATE_MARKET_NAO_INFO,
        payload: payload,

      });
};



export const getMarketApplicationIdData = (payload) => {
    return async dispatch => {
        const env = '';
        
        dispatch({
            type: ActionTypes.GET_MARKET_APPLICATIONID_DATA_LOADER,
            // payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.GET_MARKET_APPLICATIONID_DATA,
                payload,
                env,
                await (response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.GET_MARKET_APPLICATIONID_DATA_SUCCESS,
                            payload: response.data,
                        });
                    }else{
                        dispatch({
                            type: ActionTypes.GET_MARKET_APPLICATIONID_DATA_ERROR,
                            payload: '',
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.GET_MARKET_APPLICATIONID_DATA_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    }
                },
            );
        } catch (error) {

            dispatch({
                type: ActionTypes.GET_MARKET_APPLICATIONID_DATA_ERROR,
                payload: error,
            });
        }
    };
}





export const submitMarketNAOInfo = (payload) => {
    console.log('Bulk api call')
    return async dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.SUBMIT_MARKET_NAO_INFO_LOADER,
            // payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.SUBMIT_MARKET_NAO_INFO,
                payload.payload,
                env,
                await (response => {
                    console.log('sucess api call')
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.SUBMIT_MARKET_NAO_INFO_SUCCESS,
                            payload: response.data,
                        });
                    }
                   
                }),
                error => {
                    console.log('error api call',error)
                  //  if (error.response) {
                        dispatch({
                            type: ActionTypes.SUBMIT_MARKET_NAO_INFO_FAILURE,
                            payload: error?.response?.data,
                        });
                   // }
                },
            );
        } catch (error) {
            console.log('catch api call')
            dispatch({
                type: ActionTypes.SUBMIT_MARKET_NAO_INFO_ERROR,
                payload: error,
            });
        }
    };
}


export const updateListOfCompletedPages = (payload) => dispatch => {  
    dispatch({
        type: ActionTypes.UPDATE_LIST_OF_COMPLETED_PAGES,
        payload: payload,

      });
};

export const marketUpdateLocal = (payload) => dispatch => {
    dispatch({
        type: ActionTypes.MARKET_UPDATE_LOCAL_STATE,
        payload: payload,
    });
}




// Bank Verification 

// Dynamic Advisor RTQ
export const getBankAccountVerificationInfo = payload => dispatch => {
    const env = '';

    // dispatch({
    //     type: ActionTypes.DYNAMIC_ADVISOR_GET_APP_ID_ROBO_LOADER,
    // });
    makeApiRequest(
        ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION,
        payload,
        env,
        async response => {
            if (response.data) {
                if (response.data.message === 'Success') {
                    const bankAccNumberList = JSON.parse(JSON.stringify(response.data?.result?.bankAccountInfo?.brokerageBankList));
                    const decryptedBankDetails = await getDecryptedBankDetailsRedirectFlow(bankAccNumberList);
                    dispatch({
                        type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_SUCCESS,
                        payload: response.data,
                        decryptedBankDetails:decryptedBankDetails
                    });
                } else if (response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR,
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR,
            });
        },
    );
};

export const postForKycVerification = (payload) => {
    return async dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.POST_KYC_VERIFICATION_LOADER,
        });
        try {
            makeApiRequest(
                ActionTypes.POST_KYC_VERIFICATION,
                payload,
                env,
                await (response => {
                    if (response.data.responseCode == 3000 ) {
                        dispatch({
                            type: ActionTypes.POST_KYC_VERIFICATION_SUCCESS,
                            payload: response.data,
                        });
                    }else{

                        dispatch({
                            type: ActionTypes.POST_KYC_VERIFICATION_FAILURE,
                            payload: response.data,
                        });
                    }
                   
                }),
                error => {
                    if (error.response) {

                        dispatch({
                            type: ActionTypes.POST_KYC_VERIFICATION_FAILURE,
                            payload: error.response.data,
                        });
                    }
                },
            );
        } catch (error) {

            dispatch({
                type: ActionTypes.POST_KYC_VERIFICATION_ERROR,
                payload: error,
            });
        }
    };
}

export const submitKycUploadedDocuments = payload => {
    return dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_LOADER,
            // payload: { isLoading: true, isError: false },
        });

        try {
            makeApiRequest(
                ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS,
                payload.payload,
                env,
                response => {
                    let responsePayload = [];
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_SUCCESS,
                            payload: response.data,
                        });
                    }
                },
                error => {
                    // if (error.response) {
                        dispatch({
                            type: ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_FAILURE,
                            payload: error?.response?.data ? error?.response?.data : ""
                        });
                   // }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_ERROR,
                payload: error,
            });
        }
    };
};

export const clearAPIStatus = (payload) => dispatch => {  
    dispatch({
          type: ActionTypes.CLEAR_API_STATUS,
          payload: payload
      });
};

export const clearKycInfo = () => dispatch => {  
    dispatch({
          type: ActionTypes.CLEAR_KYC_VERIFICATION_DATA,
      });
};


export const clearMarketNAOInfo = () => dispatch => {  
    dispatch({
          type: ActionTypes.CLEAR_MARKET_NAO_INFO,
      });
  };
  export const clearBankAccountInfo = () => dispatch => {
    dispatch({
        type: ActionTypes.CLEAR_BROKERAGE_BANK_ACCOUNT_INFO,
    });
};