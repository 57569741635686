import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import routeConstants from 'routeConstants';
import { BottomNavBar, InstructionalPanel, PageTitle, FieldValue, RadioGroup, Address, CheckOption } from 'modules/CustomerManagementModule/components';
import {
    getKeyfromValue,
    getValuefromkey,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';
import CONST from "../constants";

const primaryBtnProps = {};
const cancelBtnProps = {};

export default class AddContactInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };
    }
    
    componentDidMount() {
        const { location, isEdit, getCompositeLookUpData, setAddress } = this.props;
        const { state:locationState } = location;
        const { 
            addressLine1,
            addressLine2,
            addressType,
            city,
            state:stateName,
            zip,
        } = locationState || {};
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
        if(isEdit){
            // From API null values are possible so one level of checking is done.
            const addressLine1Value = addressLine1 || '';
            const addressLine2Value = addressLine2 || '';
            const cityValue = city || '';
            const stateValue = stateName || '';
            const zipValue = zip || '';
            const addressTypeValue = addressType || '';
            // Setting Employer Address to Address component
            setAddress({
                addressLine1: addressLine1Value,
                addressLine2: addressLine2Value,
                zip: zipValue,
                city: cityValue,
                state: stateValue,
                addressType: addressTypeValue,
            },'address');
        }
        addFormAbandonUnloadListner(isEdit);
    }
    
    componentDidUpdate(){
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, analyzeClickEvent, linkResponseError } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        if(isError){
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_managecontactinfo_editContactInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_managecontactinfo_editContactInfo_save`, analyzeClickEvent);
            history.push(routeConstants.contactInfo);
        }
    }
    
    componentWillUnmount() {
        const { profileInformationData, resetAddressInfo, isEdit } = this.props;
        const { isSuccess } = profileInformationData;
        resetAddressInfo('address');
        if(!isSuccess) {
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }
    
    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            address_type:addressType
        } = masterLookupStateData || {};
        const payLoad = [];
        if (!addressType) payLoad.push('address_type');
        return payLoad;
    };

    updateOrAddAddress = () => {
            const { addProfileContactInformations, addressInfo, location } = this.props;
            const { fields } = this.state;
            const { addressType:fieldsaddressType, applyToAllAccounts } = fields;
            const { state:locationState } = location;
            const { 
                isMailingAddress,
                isPhysicalAddress,
                isSeasonalAddress,
                addressType: locationaddressType
            } = locationState || {};
            const addressType = fieldsaddressType || locationaddressType;
            
            const { addressInformation }= addressInfo;
            const {
                addressLine1,
                addressLine2,
                zip,
                city,
                state,
              } = addressInformation;
              const sendingData = {
                addressType,
                addressLine1,
                addressLine2,
                city,
                state,
                zip,
                isPhysicalAddress,
                isMailingAddress,
                isSeasonalAddress,
            };
            const payload = {
               addressUpdate: [{...sendingData}],
               applyToAllAccounts,
             }
            addProfileContactInformations(payload);
    };
    
    getMasterDataValues = (masterLookupStateData) => {
        const {
            address_type: addressTypesMaster,
        } = masterLookupStateData || {};
        const { value: addressTypesMasterValue } = addressTypesMaster || {};

        const addressTypeData = addressTypesMasterValue || [];
        return { addressTypeData };
    };
    
    handleRadioChange = (e) => {
        const { fields } = this.state;
        const { target } = e;
        const { value } = target;
        const { masterLookupStateData } = this.props;
        const addressTypeValue = getKeyfromValue(masterLookupStateData, 'address_type',value);
        fields.addressType = addressTypeValue;
        this.setState({
            fields,
        });
    }
    
    onCheckOptionChange = (e) => {
        const { target } = e;
        this.setState((prevState)=>{
            const { fields } = prevState;
            fields.applyToAllAccounts = target.checked;
            return {
                fields
            }
        })
    }
    
    cancelContactInfo = () => {
        const {  history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managecontactinfo_editcontactInfo_cancel`
        })
        history.push(routeConstants.contactInfo);
    }
    
    isAnythingChanged = () => {
        const { addressInfo, location } = this.props;
        const { fields } = this.state;
        const { addressType:fieldsaddressType } = fields;
        const { state:locationState } = location;
        const { 
            addressType: locationaddressType
        } = locationState || {};
        const addressType = fieldsaddressType || locationaddressType;
        
        const { addressInformation }= addressInfo;
        const {
            addressLine2,
            zip,
            addressLine2ErrorText,
            zipError,
            } = addressInformation;
        // const isaddressTypeChanged = hasValueChanged(fields, locationState, 'addressType');
        return (!!addressType && (!addressLine2ErrorText) && (!zipError) && addressLine2 && zip); 
    }

    render() {
        const { profileInformationData, isEdit, masterLookupStateData, location } = this.props;
        const { state } = location;
        const { 
            addressType,
            isMailingAddress,
            isPhysicalAddress
        } = state || {};
        const addressTypeError = !addressType;
        const data = this.getMasterDataValues(masterLookupStateData);
        const { isLoading: loading } = profileInformationData;
        let typeOfAddress = CONST.SESSIONAL_ADDRESS;
        if(isMailingAddress) typeOfAddress = CONST.MAILING_ADDRESS;
        if(isPhysicalAddress) typeOfAddress = CONST.PHYSICAL_ADDRESS;

        primaryBtnProps.onClick = this.updateOrAddAddress;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelContactInfo;

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={isEdit ? CONST.BREADCRUM_CONTACT_EDIT : CONST.BREADCRUM_CONTACT_ADD}
                            activeCrumb={isEdit ? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                        />
                        <PageTitle 
                            title={isEdit? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                            showMandatoryTxt
                        />
                        <FieldValue
                            as="p"
                            id="vcm-cms-address-type"
                            label={CONST.ADDRESS_TYPE}
                            value={
                                <RadioGroup
                                    labelledBy="vcm-cms-address-type"
                                    vertical
                                    error={addressTypeError}
                                    errorTxt={CONST.ADDRESS_TYPE_ERROR}
                                    options={data.addressTypeData}
                                    initialValue={getValuefromkey(masterLookupStateData, 'address_type',addressType)}
                                    onChange={this.handleRadioChange}
                                    data-name={CONST.ADDRESS_TYPE}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <Address />
                        <CheckOption 
                            text={CONST.MAKE_SAME_AS_ADDRESS}
                            onChange={this.onCheckOptionChange}
                            mB='2.5'
                            data-name={CONST.MAKE_SAME_AS_ADDRESS}
                            data-form-field
                        />
                        <InstructionalPanel mb={2.5}>
                            <ul>
                                <li>{CONST.MAKING_CHANGE} {typeOfAddress} </li>
                                <li>{CONST.VERIFY_TEXT} </li>
                            </ul>
                        </InstructionalPanel>
                    </Container>
                    <BottomNavBar
                        hideBack
                        showCancelBtn
                        primaryBtnText={CONST.SAVE}
                        primaryBtnProps={primaryBtnProps}
                        cancelBtnProps={cancelBtnProps}
                    />
            </>
        );
    }
}

AddContactInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    addProfileContactInformations: PropTypes.func,
    profileInformationData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    isEdit: PropTypes.bool,
    masterLookupStateData: PropTypes.instanceOf(Object),
    addressInfo: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    setAddress: PropTypes.func,
    resetAddressInfo: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    linkResponseError: PropTypes.func
};

AddContactInfoComponent.defaultProps = {
    addProfileContactInformations: () => {},
    profileInformationData: {},
    clearExistingActions: () => {},
    isEdit: false,
    masterLookupStateData: {},
    addressInfo: {},
    getCompositeLookUpData: () => {},
    setAddress: () => {},
    resetAddressInfo: () => {},
    analyzeClickEvent: ()=>{},
    linkResponseError: ()=>{},
};