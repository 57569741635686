const data = {
    title: "Enter One Time Passcode",
    infoP2: "This One Time Passcode is valid for 10 minutes",
    info: ["We have sent One Time Passcode to your register Mobile no."],
    wrongOtp: 'Incorrect passcode, please try again or request a new passcode.',
    unknownLogin: 'You have signed in to an unknown device. ',
    sentOtp: 'We have sent One Time Passcode to your register Mobile Number ',
    resent: 'As requested, we have sent you a new One Time Passcode.',
    enterOtp: 'Enter One Time Passcode',
    proceed: ' to proceed.',
    welcome: 'Welcome ',
    signedAs: ' to Victory Capital. You have successfully logged as ',
    optionalMessage: '* All fields are required unless mentioned optional',
    otpExpiry: 'Please generate a new One Time Passcode.',
    invalidOtp:'Incorrect passcode, please try again or request new passcode.',
    enterMsg: 'Enter One Time Passcode sent to your registered Mobile number',
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Personal Details', 'Manage Address'],
    memberSignUpFlow: 'memberSignUpFlow',
    OTP: 'OTP',
    PHONE: 'PHONE',
    otpVerify: 'otpVerify',
    Failure: 'Failure',
    toMobileNumber: 'Back to mobile number',
    toOneTimeVerify: 'Back to Choose Security Code Method',
    RecoverUserId: 'Recover User Id',
    success: 'Success',
    true: 'true',
    OneTimePasscode: 'One Time Passcode',
    afterAnHour: 'Please try generating new one time passcode after an hour',
    after24Hour: 'We can not process your request at this time. Please try again after 24 hours.',
    unableToProcess: 'Unable to Process your request at this time. Please try again.',
    memberNotVerified: 'Member Not verified, Please do the verification',
    invalidOtpMemberSignup: 'Incorrect passcode, please generate a new one time passcode.',
  }

  export default data