import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { breadCrumbNames } from './consts';
import routeConstants from '../../../routeConstants';

const S = {};

S.SpecialtyTitle = styled.h2`
    font: 800 22px/45px yorkten-slab-normal;
    min-height: 42px;
    color: #486d90;
    border-bottom: 1px solid #d2d2d2;
`;

S.Container = styled.div`
    min-width: 100%;
    margin-bottom: -27px;
`;

const SpecialtyAccountHeading = ({ children }) => {
   
    return (
        <S.Container>
            <S.SpecialtyTitle>{children}</S.SpecialtyTitle>
        </S.Container>
    );
};

SpecialtyAccountHeading.defaultProps = {
    specialtyText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default SpecialtyAccountHeading;
