import downArrowBlue from 'assets/DownArrowBlue.svg';
import ErrorMsg from '../ErrorMsg';
import { getAriaDescribedBy, getKeyboardFocusableElements } from 'modules/CustomerManagementModule/utils';
import { isMobile } from 'react-device-detect';
import OffScreenText from '../OffScreenText';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { StyledBoxForInput } from '../InputText';

const SelectContainer = styled.div`
    position: relative;
`;

const errorBorder = '2px solid #E60000';

const getBorder = ({ isOpen, error }) => {
    let border = '1px solid #d2d2d2';
    if (isOpen) border = '1px solid #486d90';
    if (error) border = errorBorder;
    return border;
};

const SelectButton = styled(StyledBoxForInput)`
    &&& {
        padding-right: 2.1rem;
        white-space: nowrap;
        font-size: 16px;
        color: #56565A;
        text-align: left;
        text-align: -moz-left;
        text-overflow: ellipsis;
        overflow: hidden;
        border: ${props => getBorder({ isopen: props.isOpen, error: props.error })}
        position: relative;
        background: url(${downArrowBlue});
        background-repeat: no-repeat;
        background-position: right 1.25rem top 50%;
        background-size: 0.85rem 0.85rem;
        background-color: #FFFFFF;
    }
`;

const ListContainer = styled.div`
    display: ${props => (props.listOpen ? 'block' : 'none')};
    width: 100%;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 3rem;
    overflow-y: auto; 
    &::-webkit-scrollbar {
        width: 0.37rem;
        cursor: pointer;
        position:absolute;
        background:#fff;
    }
    &::-webkit-scrollbar-thumb {
        background: #486d90 0% 0% no-repeat padding-box;
        border-radius: 0.375rem;
    }
`;

const listBoxMaxHeight = '14.0625rem';

const ListBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.25rem;
    max-height: ${listBoxMaxHeight};
`;

const scrollbarStyle = {
    maxHeight: listBoxMaxHeight,
};

const List = styled.li`
    list-style: none;
    text-align: left;
    margin: 2px;
    height: auto;
    opacity: 1;
    line-height: 1;
`;

const StyledUl = styled.ul`
    margin: 0;
    padding: 0;
`;

const ListOption = styled.a`
    display: flex;
    padding: 0.625rem;
    min-height: 2.8125rem;
    align-items: center;
    &&& {
        color: inherit;
        text-decoration: none;
        &:focus {
            background: #e6e6e6 0% 0% no-repeat;
            outline: 1px solid #75757a;
            outline-offset: 0px;
        }
        &:visited,
        &:hover,
        &:active {
            color: inherit;
        }
    }
`;
const SPACEBAR_KEY_CODE = ' ';
const ENTER_KEY_CODE = 'Enter';
const LEFT_ARROW_KEY_CODE = 'ArrowLeft';
const UP_ARROW_KEY_CODE = 'ArrowUp';
const RIGHT_ARROW_KEY_CODE = 'ArrowRight';
const DOWN_ARROW_KEY_CODE = 'ArrowDown';
const ESCAPE_KEY_CODE = 'Escape';
const TAB_KEY_CODE = 'Tab';

const TAB_INDEX_CHANGED = 'tab-index-changed';
const PREV_TAB_INDEX = 'prev-tab-index';

const DEFAULT_VALUE = 'Select';

const hideFocus = optionsRef => {
    const rootEl = document.getElementById('root');
    const allFocusableElements = getKeyboardFocusableElements(rootEl);
    allFocusableElements.forEach(item => {
        const currTabIndex = item.getAttribute('tabindex');
        const isTabIndexChanged = item.getAttribute(`data-${TAB_INDEX_CHANGED}`);
        if (isTabIndexChanged !== 'true' && !optionsRef.current.includes(item)) {
            if (currTabIndex) item.setAttribute(`data-${PREV_TAB_INDEX}`, currTabIndex);
            item.setAttribute('tabindex', '-1');
            item.setAttribute(`data-${TAB_INDEX_CHANGED}`, 'true');
        }
    });
};

const enableFocus = optionsRef => {
    const focusChangedElements = document.querySelectorAll(`[data-${TAB_INDEX_CHANGED}="true"]`);
    focusChangedElements.forEach(item => {
        if (!optionsRef.current.includes(item)) {
            const prevTabIndex = item.getAttribute(`data-${PREV_TAB_INDEX}`);
            item.setAttribute(`data-${TAB_INDEX_CHANGED}`, 'false');
            if (prevTabIndex) {
                item.setAttribute('tabindex', prevTabIndex);
            } else {
                item.removeAttribute('tabindex');
            }
        }
    });
};

const setTimer = ({ timer, searcHistory, isSearching }) => {
    const searchTimer = timer;
    const wordHistory = searcHistory;
    const searchFlag = isSearching;
    searchTimer.current = setTimeout(() => {
        wordHistory.current = '';
        searchFlag.current = false;
        searchTimer.current = null;
    }, 800);
};

const getNextSearchIndex = ({ index, length }) => {
    if (index !== -1 && index < length - 1) {
        return index + 1;
    }
    return 0;
};

const getNewList = ({ list, index }) => {
    return list.map((item, i) => {
        const newItem = { ...item };
        if (i === index) {
            newItem.selected = true;
        } else newItem.selected = false;
        return newItem;
    });
};

const searchOption = ({
    searchKey,
    list,
    selectedIndex,
    setSelectedIndex,
    refs,
    isOpen,
    setChanged,
    isSearching,
    searcHistory,
    timer,
    setList,
}) => {
    const searchTimer = timer;
    const wordHistory = searcHistory;
    const searchFlag = isSearching;
    if (searchFlag.current) {
        if (searchKey !== wordHistory.current) {
            wordHistory.current += searchKey;
            clearTimeout(searchTimer.current);
            searchTimer.current = null;
            setTimer({ timer, searcHistory, isSearching });
        }
    } else {
        searchFlag.current = true;
        wordHistory.current += searchKey;
        setTimer({ timer, searcHistory, isSearching });
    }
    const currentIndex = isOpen ? refs.current.findIndex(item => item === document.activeElement) : selectedIndex;
    const currentItem = list[+currentIndex];
    const filteredList = list.filter(item => item.value.toLowerCase().startsWith(wordHistory.current.toLowerCase()));
    if (filteredList.length && currentItem) {
        const currentItemIndexInFilteredList = filteredList.findIndex(item => item.value === currentItem.value);
        const nextIndex = getNextSearchIndex({ index: currentItemIndexInFilteredList, length: filteredList.length });
        const nextItem = filteredList[+nextIndex];
        const originalIndex = list.findIndex(item => item.value === nextItem.value);
        if (isOpen) {
            refs.current[+originalIndex].focus();
        } else if (originalIndex !== selectedIndex) {
            const newList = getNewList({ list, index: originalIndex });
            setList(newList);
            setSelectedIndex(originalIndex);
            setChanged(true);
        }
    }
};

const getIndexForArrowMovement = ({ index, length, code }) => {
    let nextIndex = -1;
    if (code === DOWN_ARROW_KEY_CODE) {
        if (index < length - 1) {
            nextIndex = index + 1;
        } else {
            nextIndex = 0;
        }
    } else if (code === UP_ARROW_KEY_CODE) {
        if (index > 0) {
            nextIndex = index - 1;
        } else {
            nextIndex = length - 1;
        }
    }
    return nextIndex;
};

const focusListItem = ({ code, index, list, refs }) => {
    const nextIndex = getIndexForArrowMovement({ index, length: list.length, code });
    if (nextIndex !== -1) {
        refs.current[+nextIndex].focus();
    }
};

const onDropDownBlur = ({ onBlur, refs, inputRef, isOption, setListOpen }) => e => {
    const nextFocusedEl = e.relatedTarget || document.activeElement;
    const { current: inputReference } = inputRef;
    const isOptionMenu = refs.current.some(item => item === nextFocusedEl);
    if (isOption && !isOptionMenu) {
        setListOpen(false);
        enableFocus(refs);
    }
    if (nextFocusedEl !== inputReference && !isOptionMenu) {
        onBlur(e);
    }
};

const selectItem = ({
    index,
    list,
    setList,
    selectedIndex,
    setSelectedIndex,
    setChanged,
    setListOpen,
    inputRef,
    refs,
}) => e => {
    e.preventDefault();
    const newList = getNewList({ list, index });
    setList(newList);
    if (selectedIndex === null || list[+selectedIndex].key !== list[+index].key) {
        setSelectedIndex(index);
        setChanged(true);
    }
    setListOpen(false);
    enableFocus(refs);
    inputRef.current.focus();
};

const toggleList = ({ disabled, setListOpen, refs }) => () => {
    if (!disabled) {
        setListOpen(prevListOpen => {
            if (prevListOpen) enableFocus(refs);
            return !prevListOpen;
        });
    }
};

const onButtonKeyDown = ({
    setListOpen,
    list,
    selectedIndex,
    setSelectedIndex,
    setChanged,
    isSearching,
    searcHistory,
    timer,
    setList,
}) => e => {
    const openDropDown = e.key === SPACEBAR_KEY_CODE || e.key === ENTER_KEY_CODE;
    const selectOption = e.key === UP_ARROW_KEY_CODE || e.key === DOWN_ARROW_KEY_CODE;
    if (openDropDown) {
        e.preventDefault();
        setListOpen(true);
    } else if (selectOption) {
        e.preventDefault();
        const nextIndex = getIndexForArrowMovement({ index: selectedIndex, length: list.length, code: e.key });
        if (nextIndex !== -1) {
            const newList = getNewList({ list, index: nextIndex });
            setList(newList);
            setSelectedIndex(nextIndex);
            setChanged(true);
        }
    } else if (/^[a-zA-Z]$/.test(e.key)) {
        e.preventDefault();
        // searching logic starts
        searchOption({
            searchKey: e.key,
            list,
            selectedIndex,
            setSelectedIndex,
            isOpen: false,
            setChanged,
            isSearching,
            searcHistory,
            timer,
            setList,
        });
    }
};

const onOptionKeyDown = ({
    index,
    list,
    setList,
    selectedIndex,
    setSelectedIndex,
    setChanged,
    setListOpen,
    inputRef,
    refs,
    isSearching,
    searcHistory,
    timer,
}) => e => {
    e.preventDefault();
    switch (e.key) {
        case ENTER_KEY_CODE:
            selectItem({
                index,
                list,
                setList,
                selectedIndex,
                setSelectedIndex,
                setChanged,
                setListOpen,
                inputRef,
                refs,
            })(e);
            break;

        case DOWN_ARROW_KEY_CODE:
        case LEFT_ARROW_KEY_CODE:
            focusListItem({ code: DOWN_ARROW_KEY_CODE, index, list, refs });
            break;

        case UP_ARROW_KEY_CODE:
        case RIGHT_ARROW_KEY_CODE:
            focusListItem({ code: UP_ARROW_KEY_CODE, index, list, refs });
            break;

        case ESCAPE_KEY_CODE:
        case TAB_KEY_CODE:
            setListOpen(false);
            enableFocus(refs);
            inputRef.current.focus();
            break;

        default:
            break;
    }

    // searching logic starts
    if (/[a-z]/.test(e.key.toLowerCase())) {
        searchOption({
            searchKey: e.key,
            list,
            selectedIndex,
            setSelectedIndex,
            isOpen: true,
            refs,
            isSearching,
            searcHistory,
            timer,
            setList,
        });
    }
};

const toggleHover = ({ index, mouseState, refs }) => () => {
    if (mouseState === 'enter') {
        refs.current[+index].focus();
    }
};

const getOptionData = ({ itemlist, value }) => {
    let prevSelected = null;
    const newListItems = itemlist.map((item, index) => {
        let newItem;
        if (typeof item !== 'object') {
            let selected = false;
            if (value === index.toString()) {
                selected = true;
                prevSelected = index;
            }
            newItem = {
                value: item,
                key: index.toString(),
                selected,
            };
        } else {
            let selected = false;
            if (value === item.key) {
                selected = true;
                prevSelected = index;
            }
            newItem = { ...item, selected };
        }
        return newItem;
    });
    return { prevSelected, newListItems };
};

export const CustomSelect = props => {
    const {
        onChange,
        itemlist,
        id,
        value,
        errortext,
        onBlur,
        required,
        disabled,
        CustomOpt,
        CustomOption,
        displaySelValBold,
        CustomSelectedVal,
        ...restProps
    } = props;
    const { prevSelected, newListItems } = getOptionData({ itemlist, value });
    const [list, setList] = useState(newListItems);
    const [listOpen, setListOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(prevSelected);
    const [changed, setChanged] = useState(false);

    const refs = useRef([]);
    const inputRef = useRef(null);

    const isSearching = useRef(false);
    const timer = useRef(null);
    const searcHistory = useRef('');

    useEffect(() => {
        if (changed) {
            const target = inputRef.current;
            target.value = list[+selectedIndex].key;
            onChange({ target, list });
            setChanged(false);
        }
    }, [list, onChange, changed, selectedIndex]);

    useEffect(() => {
        if (listOpen) {
            const ind = selectedIndex || 0;
            if (refs.current.length > 0) {
                if (refs.current[+ind]) refs.current[+ind].focus();
                hideFocus(refs);
            }
        }
    }, [listOpen, selectedIndex]);

    useEffect(() => {
        const { prevSelected: prevSelectedd, newListItems: newListItemss } = getOptionData({ itemlist, value });
        setList(newListItemss);
        setSelectedIndex(prevSelectedd);
    }, [itemlist, value]);

    const setRef = index => el => {
        refs.current[+index] = el;
    };
    const errorId = `${id}-error`;
    const optionContainerId = `${id}-option-container`;

    let addtionalProps = {};
    const ariaDescibedByProp = getAriaDescribedBy({ error: !!errortext, errorTxt: errortext, errorId });
    addtionalProps = { ...ariaDescibedByProp };

    let buttonValue = '';
    let spanValue = DEFAULT_VALUE;
    let activeDescendantId = null;
    if (selectedIndex !== null) {
        buttonValue = list[+selectedIndex].value;
        spanValue = list[+selectedIndex].value;
        const selectedOption = refs.current[+selectedIndex];
        const { id: activeOptionId = null } = selectedOption || {};
        activeDescendantId = activeOptionId;
    }

    const liveRegionProps = {};
    if (list.length) {
        liveRegionProps.role = 'region';
        liveRegionProps['aria-live'] = 'polite';
        liveRegionProps['aria-atomic'] = 'true';
    }

    return (
        <SelectContainer>
            <SelectButton
                as="button"
                isOpen={listOpen}
                error={!!errortext}
                id={id}
                ref={inputRef}
                role="combobox"
                onClick={toggleList({ disabled, setListOpen, refs })}
                onBlur={onDropDownBlur({ onBlur, refs, inputRef, setListOpen })}
                onKeyDown={onButtonKeyDown({
                    setListOpen,
                    list,
                    selectedIndex,
                    setSelectedIndex,
                    setChanged,
                    isSearching,
                    searcHistory,
                    timer,
                    setList,
                })}
                value={buttonValue}
                role="combobox"
                aria-controls={optionContainerId}
                aria-expanded={listOpen}
                aria-invalid={!!errortext}
                aria-haspopup="listbox"
                aria-required={required}
                aria-owns={optionContainerId}
                disabled={disabled}
                data-form-field={!disabled}
                {...addtionalProps}
                {...restProps}
                /* title={spanValue} commenting as per Ax recomendation */
            >
                {displaySelValBold ? <CustomSelectedVal spanValue={spanValue} /> : <span>{spanValue}</span>}
            </SelectButton>
            {errortext && (
                <ErrorMsg id={errorId} aria-live="assertive" aria-atomic>
                    <OffScreenText hideTxt="Error:" />
                    <span className="analytics-error-field">{errortext}</span>
                </ErrorMsg>
            )}
            <ListContainer listOpen={listOpen}>
                    <ListBox
                        id={optionContainerId}
                        role="listbox"
                        tabIndex="-1"
                        aria-activedescendant={activeDescendantId}
                    >
                        <StyledUl role="presentation">
                            {list.map((item, index) => {
                                const optionId = `${id}-option-${index + 1}`;
                                return (
                                    <List key={item.key || index.toString()}>
                                        <ListOption
                                            href="#option"
                                            role="option"
                                            id={optionId}
                                            tabIndex="0"
                                            aria-selected={!!item.selected}
                                            aria-setsize={list.length}
                                            aria-posinset={index + 1}
                                            ref={setRef(index)}
                                            selected={!!item.selected}
                                            onBlur={onDropDownBlur({
                                                onBlur,
                                                refs,
                                                inputRef,
                                                isOption: true,
                                                setListOpen,
                                            })}
                                            onClick={selectItem({
                                                index,
                                                list,
                                                setList,
                                                selectedIndex,
                                                setSelectedIndex,
                                                setChanged,
                                                setListOpen,
                                                inputRef,
                                                refs,
                                            })}
                                            onKeyDown={onOptionKeyDown({
                                                index,
                                                list,
                                                setList,
                                                selectedIndex,
                                                setSelectedIndex,
                                                setChanged,
                                                setListOpen,
                                                inputRef,
                                                refs,
                                                isSearching,
                                                searcHistory,
                                                timer,
                                            })}
                                            onMouseEnter={toggleHover({ index, mouseState: 'enter', refs })}
                                            onMouseLeave={toggleHover({ index, mouseState: 'leave', refs })}
                                        >
                                            {CustomOpt ? <CustomOption details={item} /> : <span>{item.value}</span>}
                                        </ListOption>
                                    </List>
                                );
                            })}
                        </StyledUl>
                    </ListBox>
            </ListContainer>
        </SelectContainer>
    );
};

CustomSelect.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    displaySelValBold: PropTypes.bool,
    CustomSelectedVal: PropTypes.instanceOf(Object),
    CustomOpt: PropTypes.bool,
    CustomOption: PropTypes.instanceOf(Object),
};

CustomSelect.defaultProps = {
    id: '',
    onChange: () => {},
    onBlur: () => {},
    itemlist: [],
    value: '',
    errortext: '',
    required: false,
    disabled: false,
    displaySelValBold: false,
    CustomSelectedVal: {},
    CustomOpt: false,
    CustomOption: {},
};

const onDefaultSelectChange = ({ list, onChange, setSelectedValue }) => e => {
    setSelectedValue(e.target.value);
    onChange({ target: e.target, list });
};

const DefaultSelect = props => {
    const { onChange, itemlist, id, value, errortext, onBlur, disabled, CustomOpt, name, ...restProps } = props;

    const { newListItems } = getOptionData({ itemlist, value });
    const [list, setList] = useState(newListItems);
    const [selectedValue, setSelectedValue] = useState(value || '');

    const inputRef = useRef(null);

    useEffect(() => {
        const { newListItems: newListItemss } = getOptionData({ itemlist, value });
        setList(newListItemss);
        setSelectedValue(value || '');
    }, [itemlist, value]);

    const errorId = `${id}-error`;

    let addtionalProps = {};
    const ariaDescibedByProp = getAriaDescribedBy({ error: !!errortext, errorTxt: errortext, errorId });
    addtionalProps = { ...ariaDescibedByProp };

    return (
        <SelectContainer>
            <SelectButton
                as="select"
                error={!!errortext}
                role="combobox"
                id={id}
                ref={inputRef}
                onBlur={onBlur}
                onChange={onDefaultSelectChange({ list, onChange, setSelectedValue })}
                disabled={disabled}
                data-form-field={!disabled}
                value={selectedValue}
                name={name}
                {...addtionalProps}
                {...restProps}
                className="empStatusDropdown"
            >
                <option value="" hidden>
                    {DEFAULT_VALUE}
                </option>
                {list.map((item, index) => {
                    const optionId = `${id}-option-${index + 1}`;
                    return (
                        <option key={item.key || index.toString()} id={optionId} value={item.key}>
                            {item.value}
                        </option>
                    );
                })}
            </SelectButton>
            {errortext && (
                <ErrorMsg id={errorId} aria-live="assertive" aria-atomic>
                    <OffScreenText hideTxt="Error:" />
                    <span className="analytics-error-field">{errortext}</span>
                </ErrorMsg>
            )}
        </SelectContainer>
    );
};

DefaultSelect.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
    disabled: PropTypes.bool,
    CustomOpt: PropTypes.bool,
    name: PropTypes.string,
};

DefaultSelect.defaultProps = {
    id: '',
    onChange: () => {},
    onBlur: () => {},
    itemlist: [],
    value: '',
    errortext: '',
    disabled: false,
    CustomOpt: false,
    name: '',
};

const Select = isMobile ? DefaultSelect : CustomSelect;

export default Select;
