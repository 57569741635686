const msg = "Doesn't work still? Please feel free to try other pages";
const subhead = 'Well, This is unexpected...';
const heading = 'Uh Ohh!';
const errorDesc = 'An error has occured from our end. We are currently trying to fix the problem';
const meanText = 'Mean time, you can:';
const waitText = 'Wait for few minutes';
const or = 'Or';
const home = 'Home';
const mutual = 'Mutual Funds';
const searchText = 'Search';
const btStyle = {
    backgroundColor: '#56565A',
    border: '1px solid #61285F45',
    borderRadius: '50px',
    width: '250px',
    height: '50px',
};
const btTextStyle = { color: '#FFFFFF', fontFamily: 'benton-sans', fontSize: '18px', fontWeight: 500 };

const headerStyle = { marginTop: 30, marginLeft: 20, fontSize: 20 };

const statusCode = 'Status Code :';

const messageFromAPI = ' Error Description :';

const APIErrorHeader = 'Back End Error';

const descStyle = { marginLeft: 20, fontSize: 20, marginBottom: 40 };

const refreshStyle = { margin: 20 };

const pageHeaderStyle = { marginTop: 50, marginLeft: 20, marginBottom: 30 };

export default {
    msg,
    subhead,
    heading,
    errorDesc,
    meanText,
    waitText,
    or,
    home,
    mutual,
    searchText,
    btStyle,
    btTextStyle,
    headerStyle,
    statusCode,
    messageFromAPI,
    descStyle,
    refreshStyle,
    APIErrorHeader,
    pageHeaderStyle,
};
