import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    sortByAsc: true,
    dataFlag: false,
    displayStopCodeInDashboard: false,
};

function localStateManagementReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.WEB_ACCOUNT_LOCAL_STATE: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE: {
            return {
                state: initialState,
                ...action,
            };
        }

        case ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO: {
            return {
                customerProfile: state.customerProfile,
                dataFlag: state.dataFlag,
                otpDetails: state.otpDetails,
                displayStopCodeInDashboard: state.displayStopCodeInDashboard,

                // ...action,
            };
        }

        case ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO_ACC_TYPE: {
            return {
                customerProfile: state.customerProfile,
                dataFlag: state.dataFlag,
                otpDetails: state.otpDetails,
                accIDSelected: state.accIDSelected,
                accSelected: state.accSelected,
                masterDataLoadedForOpenAcc: state.masterDataLoadedForOpenAcc,
                OpenAccPageOne: state.OpenAccPageOne,
                accTypeDetails: state.accTypeDetails,
                accountType: state.accountType,
                companyNumber: state.companyNumber,
                masterDataLoadedForAccType: state.masterDataLoadedForAccType,
                displayStopCodeInDashboard: state.displayStopCodeInDashboard,
                // ...action,
            };
        }

        case ActionTypes.SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_SUCCESS: {
            // sorting for fund account name
            const fundAccounts = action.fundList;
            if (state.sortByAsc) {
                fundAccounts.sort((a, b) => (a.fundName > b.fundName ? 1 : -1));
            } else {
                fundAccounts.sort((a, b) => (a.fundName > b.fundName ? -1 : 1));
            }
            return {
                ...state,
                displayFundList: fundAccounts,
                sortByAsc: !state.sortByAsc,
            };
        }
        case ActionTypes.SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_FAILURE: {
            return {
                ...state,
                error: ActionTypes.SORT_FUND_ERROR,
                isError: true,
            };
        }
        case ActionTypes.GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_SUCCESS: {
            return {
                ...state,
                displayFundList: action.payload,
            };
        }
        case ActionTypes.GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_ERROR: {
            return {
                ...state,
                isError: true,
            };
        }
        default:
            return state;
    }
}

export default localStateManagementReducer;
