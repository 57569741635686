import routeConstants from 'routeConstants'

const routesBreadCrumb = new Map ([
    [routeConstants.accountholder, 'Home'],
    [routeConstants.accountservices, 'Account Services'],
    [routeConstants.automaticinvestment, 'Automatic Investment Plan'],
    [routeConstants.systematicwithdrawal, 'Automatic Withdrawal/Exchange Plan'],
    [routeConstants.createAutomaticInvestment, 'Add Automatic Investment Plan'],
    [routeConstants.createSystematicWithdrawal, 'Add Automatic Withdrawal/Exchange Plan'],
    [routeConstants.editautomaticinvestment, 'Edit Automatic Investment Plan'],
    [routeConstants.editsystematicwithdrawal, 'Edit Automatic Withdrawal/Exchange Plan'],
    [routeConstants.checkbook, 'Order Checkbook'],
    [routeConstants.manageBeneficiariesAccounts, 'Manage Beneficiaries'],
    [routeConstants.manageBeneficiaries, 'Edit Beneficiaries'],
    [routeConstants.manageBeneficiariesVerify, 'Verify Beneficiaries'],
    [routeConstants.dividends, 'Dividends and Capital Gains Preferences'],
    [routeConstants.bankaccounts, 'Bank Accounts'],
])

export default routesBreadCrumb