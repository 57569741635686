import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import JovTermsAndConditionsComponent from './JovTermsAndConditionsComponent';
import { localStateManagementActions} from "../../../../shared/Actions";



const mapStateToProps = (state,/* , props */) => (
    {
      localStateData:state.localStateManagementReducerData,
    }
  );


const mapDispatchToProps = {
  ...localStateManagementActions,
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(JovTermsAndConditionsComponent));
