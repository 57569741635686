export const INDIVIDUAL_ACCOUNT = 'Individual Account';
export const FUND_NAME = 'Fund Name';
export const MIN_TEXT = 'Minimum';
export const MIN_AUTO = 'Min w/ Auto Investing';
export const RISK = 'Risk';
export const WITH = 'w/';
export const MONTHLY = 'monthly';
export const NO_FUNS = 'No Funds';
export const LOAD = 'Load More';
export const DASH = '-';
export const FUND_YOUR_ACCOUNT = 'Fund Your Account';
export const CHOOSE_ACCOUNT = 'Choose how to fund your account.';
export const HINT_TEXT = 'This is sample content paragraph';
export const HINT_SUBTEXT =
    'This is sample content paragraph';
export const CONTACT = 'Contact MSR';
export const CALL_TOLL = 'Call toll-free';
export const PHONE = '(855) 756-7520';
export const OR = '[or]';
export const ADD_BANK = 'Add Bank Account';
export const ADD_BANK_SUB_TEXT = '* All fields are required except mentioned optional';
export const ACCOUNT_TYPE = 'Type of Account';
export const SPECIMEN = 'Specimen';
export const FUND_INVESTMENTS = 'Fund Your Investments';
export const CHOOSE_BELOW =
    'Choose your funding options below. For monthly investments; the start day of the month must be a business day and at least 1 business day from today. If the day of the month you select is a holiday; the transaction will occur on the next business day. If you are selecting a monthly investment; please read this important notice';
export const FUND_OPTIONS = 'Funding Options';
export const INIT_INVESTMENT = 'Initial Investment';
export const MONTHLY_INVESTMENT = 'Monthly Investment';
export const START_DATE = 'Start Date';
export const ACTION = 'Action';
export const DOLLAR = '$';
export const REMOVE = 'Remove';
export const MIN1 = 'Minimum $3000';
export const MIN2 = 'Minimum $500';
export const TOTAL = 'Total';
export const initialandMonthly = 'init_mon';

export const OF = 'of';
export const ITEMS_SELECTED = 'Items selected';

export const ATLEAST_ONE_FUND_ERROR_MESSAGE =
    'You must choose at least one fund to open your account, if you would like to change your selection, please go to the previous page to select another fund.';
export const ATLEAST_ONE_FUND_HAS_ERROR = 'Please check the fund information for errors.';
export const ATLEAST_ONE_FUND_METHOD_ERROR_MESSAGE = 'You must choose at least one fund source to fund your account';

export const FUND_INVESTMENT_TABLE = 'FUND INVESTMENT TABLE';

export const compositePayload = [
    'fund_source',
    'fund_options',
    // "bank_acct_type",
];

export const invSelectMasterDataError = 'Error in master Data of Investment Selection List';

export const AlertHeadingText = 'Oh snap! You got an error!';

export const alertContent = ' Backend returned error try after sometime or try another combination';

export const compareAlertMsg = 'Please select minimum 2 or maximum 4 funds to compare';

export const radioErrorMsg = 'Please select an Account Type';

export const fundsDetailsList = [
    {
        key: 'Min. / Max. Amount w/ Auto Investing',
        value: '$ 300 / $ 5,000 w/ $ 300 monthly',
    },
    {
        key: 'Change in NAV',
        value: '14.3',
    },
    {
        key: 'Last NAV (Previous day close)',
        value: '$ 143',
    },
    {
        key: '52 week Min. / Max. Values',
        value: '$ 3,000 / $ 5,000',
    },
    {
        key: 'Risk:',
        value: 'Medium',
    },
];

export const fundsDetailsList1 = [
    {
        fundName: 'USAA Intermediate-Term Bond Adviser',
        fundNumber: 330,
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
    {
        fundName: 'Aggressive Growth Fund',
        fundNumber: 330,
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
    {
        fundName: 'USAA Growth Income Adviser1',
        fundNumber: 330,
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
];

export const fundsList1 = [
    {
        fundName: 'USAA Growth & Income Adviser',
        fundNumber: 330,
        initialInvestment: 3000,
        monthlyInvestment: 300,
        startDate: '06/06/2020',
        fundingOption: 'monthlyInvestment',
    },
    {
        fundName: 'USAA Growth Adviser',
        fundNumber: 330,
        initialInvestment: 3000,
        fundingOption: 'initialInvestment',
    },
];

export const offlineFundingDetails = [
    {
        key: 'USAA Fund Name',
        value: 'Aggressive Growth Fund',
    },
    {
        key: 'USAA Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
    {
        key: 'Name',
        value: 'William',
    },
    {
        key: 'USAA Mutual Fund Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
    {
        key: 'Mailing to This Address',
        value: ['Mellon Trust of New England', '1 Boston Place', 'Boston, Massachusetts 02108', 'ABA # 011001234'],
    },
];

export const dateFormat = 'MM/DD/YYYY';

export const offlineFundingDetailsHintWT =
    'The transaction will be processed on confirmation of wire transfer details. Please wait for 3-5 business days for completion of your transaction.';

export const offlineFundingDetailsHintCheck =
    'The transaction will be processed only after receiving the check. Please wait for 3-5 business days for completion of your transaction.';

export const printText = 'Print';

export const printStyle = `    #offlineFundDetails {
    margin-top: 47px;
    padding: 30px 95px;
    border: 2px solid #8BC105;
    position: relative;
    background-color: #FAFAFA;
}
#offlineFundDetails::before {
    content: "";
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.offlineDetailsHeading {
    margin-bottom: 15px;
    font-size: 22px;
    color: #486D90;
    font-family: "yorkten-slab-normal";
    font-weight: 600;
}
.offlineDetailsSec {
    padding-top: 30px;
    border-top: 1px solid #CECECE;
}
.offlineDetailsSec .row {
    display:flex;
}
.offlineDetailsSec .row:not(.offlineDetailsHint) div {
    width: 50%
}
.offlineDetailsHint {
    margin-top: 40px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 25px 30px 25px 95px;
    font: 600 16px/22px benton-sans;
    position: relative;
}
.offlineDetailsHint::before {
    content: "";
    border-bottom: 25px solid transparent;
    border-left: 25px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.printAction {
    display: none;
}`;

export const checkOfflineDetails =
    'Please make the check payable to USAA Mutual Funds and include the account number of the mutual fund in the memo section. Note: We do not accept 3rd party checks, traveler’s checks and money orders..';

export const intAndMonthInv = 'init_mon';
export const initialInvestment = 'initialInvest';
export const monthlyInvestment = 'monthlyInvest';

export const validErrorMessage = 'Please enter a valid value';
export const validDateErrorMessage = 'Please enter a valid date';
export const enterValueErrorMessage = 'Please enter a value';
export const selectValueErrorMessage = 'Please select a value';
export const START_DATE_VALIDATION_ERROR_MESSAGE = 'Date needs to be at least 2 days from today';

export const onlineHeaderLabel = 'Fund with a Bank Account';
export const offlineHeaderLabel = 'Fund by Check or Wire Transfer';
export const onlineCardText = ' Add Bank Account';
export const ONLINE_TEXT = 'online';
export const BANK_TEXT = 'bank';
export const PUR_ACH_LIMIT = 50000;
export const VALIDATE_ACH_ERROR_MESSAGE =
    'PUR ACH transaction is over the daily Limit. You have over contributed daily limit by';
export const IRA_PUR_ACH_LIMIT_AGE_LESS_THAN_50 = 6000;
export const IRA_PUR_ACH_LIMIT_AGE_MORE_THAN_50 = 7000;
export const TRADIONAL_IRA_TEXT = 'Traditional IRA';
export const ROTH_IRA_TEXT = 'Roth IRA';
export const SEP_IRA_TEXT = 'SEP IRA';
export const FUNDING_SOURCE_ERROR = 'Please select a funding source.';
export const VALIDATE_IRA_CONTRIBUTION_ERROR_MESSAGE_AGE_LESS_THAN_50_PART1 =
    'IRA owners are allowed to contribute a maximum of $6,000 to their accounts in';
export const VALIDATE_IRA_CONTRIBUTION_ERROR_MESSAGE_AGE_LESS_THAN_50_PART2 =
    '. Contribution limits are per person, not per account.';
export const VALIDATE_IRA_CONTRIBUTION_ERROR_MESSAGE_AGE_MORE_THAN_50_PART1 =
    'IRA owners are allowed to contribute a maximum of $6,000 to their accounts in';
export const VALIDATE_IRA_CONTRIBUTION_ERROR_MESSAGE_AGE_MORE_THAN_50_PART2 =
    ', with an additional $1000 catch-up contribution allowed for savers who have already reached their 50th birthdays. Contribution limits are per person, not per account.';
export const CONTRIBUTION_IRA = 'Contribution for IRA Account';
export const CONTRIBUTION_TEXT = 'Contribution';
export const IRA_CONTRIBUTION_KEY_TEXT = 'ira_contribution_limit_';
export const CURRENT_YEAR_TEXT = ['Current Year'];
export const CONTRIBUTION_OPTIONS = ['Current Year', 'Previous Year'];
export const CONTIRBUTION_YEAR_ERROR_MESSAGE = 'Please select contribuiton year';
export const IRA_MAX_CONTR_WARNING = 'IRA Maximum Contribution Warning';
