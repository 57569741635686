const data = {
  title: 'DOB Verification',
  info: ['Please verify your date of birth'],
  dateOfBirthLabel: 'Date of Birth',
  dobValError: 'Date of Birth does not match with our records',
  dobConfig: {
      type: 'date',
      label: 'Date Of Birth',
      placeholder: 'DD/MM/YYYY',
  },
  errorStyle: {
      paddingLeft: 15,
  },
  
}

export default data;