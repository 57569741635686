import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import cancelIcon from 'assets/icon-delete-red.svg';
import CtaWithIcon from '../CtaWithIcon';

const Icon = styled.span`
    height: 17px;
    width: 17px;
    background: url(${cancelIcon}) no-repeat;
    background-position: center;
`;

const CANCEL = 'Cancel';

const CancelBtn = React.forwardRef((props, ref) => {
    return <CtaWithIcon ref={ref} icon={<Icon />} {...props} />;
});

CancelBtn.propTypes = {
    text: PropTypes.string,
};

CancelBtn.defaultProps = {
    text: CANCEL,
};

export default CancelBtn;
