
 export const assignObj = obj => {
    return obj;
};

export const horizontalStyle = {
    display: 'flex',
    flexDirection: 'row',
};

export const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

export const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};
export const alignSelectAccOpt = { maxWidth: '825px', flexBasis: '25.7%' };
export const radioText = { font: '500 16px/20px benton-sans' };
export const doProvideContacts='Do you want to provide trusted contacts?'
export const radioOptions = ['Yes','No']
export const relationships=['Aunt/Uncle','Brother/Sister','Brother/Sister-in-Law','Child','Father/Mother-in-Law','Grand Child','Niece/Nephew','Parent','Son/Daughter in Law','Spouse','Other'];
export const salutations=['Dr','Hon','Mr.','Mrs.','Ms.']
export const suffixes=['II','III','IV','V','CPA','DDS','Esq','JD','Jr','LLD','MD','PhD','Ret','RN','Sr','DO','DC']
export const genderlabel="Gender"
export const genderOptions = ['Male','Female','Prefer not to disclose']
export const HelpText_TrustedContact = "What is a trusted contact?\nFINRA Rule 4512 states that at the time of account opening a member shall disclose in writing, which may be electronic, to the customer that the member or an associated person of the member is authorized to contact the trusted contact person and disclose information about the customer's account to address possible financial exploitation, or to confirm the specifics of the customer's current contact information, health status, or the identity of any legal guardian, executor, trustee or holder of a power of attorney.\n\n Review Victory Capital's"
export const HelpText_EDelivery = "Why can't I change this information?\nBy default, we deliver notifications electronically. By providing your email address(es), you consent to have your financial documents (including statements, confirmations, and other types of both current and future communications related to your account(s)) made available to you electronically instead of by paper mail. Please review the Electronic Notification and Delivery Agreement Terms and Conditions.\n\n If you wish to change your e-delivery preferences to paper, please contact a Representative at 1-800-235-8396. You will be charged a fee per document if you switch your preferences to paper."
export const DisclosureLinkText= "Trusted Contact Person Disclosure"
export const ruleVoilationMessage = {
    title: 'Unable to proceed with online application',
    message: 'Due to Community Property laws, the account owner is required to have their spouse listed as the sole primary beneficiary unless we receive a signed Spousal Consent form, signed by their spouse, along with their application. Please review knowledge articles for more information.'
}
  
export const diableNextButtonStyle = {
    backgroundColor: '#d2d2d2',
    color:'black'
}
export const defaultArray = [{ id: 1, header: "Mutual Fund Cost Basis Lot Relief Method", value: "Average Cost Basis" }, { id: 2, header: "Equity/ETF Cost Basis Lot Relief Method", value: "First In, First Out" }, { id: 3, header: "Dividends/Capital Gains", value: "Cash Sweep" }, { id: 4, header: "Cash Sweep", value: "Federated Hermes Capital Reserves (FRFXX)" }]
export const AccountDefault_Popup_Txt = 'After establishing your account, if you wish to edit your account defaults, please contact a Representative at 1-800-235-8396.'
export const E_Delivery_Msg = 'After establishing your account, if you wish to edit your delivery preferences, please contact a Representative at 1-800-235-8396.'
export const netWorthHelpText = 'Net worth is the value of your assets minus your liabilities. For purposes of this application, assets include stocks, bonds, mutual funds, other securities, bank accounts, and other personal property. Do not include your primary residence among your assets. For liabilities, include any outstanding loans, credit card balances, taxes, etc. Do not include your mortgage.'
export const addBankAccounttext = 'Would you like to add a bank account to your profile?'
export const addexistBankAccounttext = 'We have detected the following bank accounts associated with your accounts. Please select ALL of the accounts that you would like made accessible to your Marketplace Account'
export const addexistBankAccounttext1='If one or more of the below bank accounts appears incorrect, please call (800) 235-8396'
export const addExistingBankAccounttext = 'Add Existing Bank Accounts (select below)'
export const addnewBankAccount = 'I would like to add a new bank account'
export const addBankAccountLater = 'I will add my bank account later'
export const accountTypelabel="Account Type"
export const accountTypeOptions = ['Checking','Savings']
export const isThisJointAccount="Is this a joint account?"
export const isThisJointAccountradioOptions = ['Yes','No']
export const bankValidationPopupMsg = 'We are unable to validate your bank account information at this time. An Investment Specialist will reach out to you shortly to assist. Please continue through your application.'
export const addBanklaterDesctextpara1 = `Adding a bank account now is the easiest way to fund your Marketplace account quickly and easily. If you choose to add a bank at a later time, you will receive directions at the end of your application for how to add a new bank account through VCM's Marketplace Terminal`
export const addBanklaterDesctextpara2 = 'For additional information on adding bank accounts to you Marketplace account, please review the following '
export const bankFundAcknowledmentLink = 'Banking and Funding Acknowledgment'
export const zeroAmountErrorMsg="Enter valid fund amount for selected bank"
export const initialFundAmountErrorMsg="This account requires a minimum of $250 be maintained in the cash sweep position."
export const allowedMinimumFundAmount=250
export const bankValidationErrorMessage={
    accountNumberBlank: "Account Number can't be left blank",
    accountNumberLength: "Account Number must be between 4 and 17 digits",
    routingNumberBlank: "Routing Number can't be left blank",
    routingNumberLength: "Routing Number must be exactly 9 digits",
    accountType: "Please select an account type",
    jointAccount: "Please select Yes or No for joint account",
}
export const IRA_CONTRIBUTION_KEY_TEXT = 'ira_contribution_limit_';
export const CURRENT_YEAR_TEXT = ['Current Year'];
export const SEP_IRA = 'SEP IRA';
export const CONTRIBUTION_OPTIONS = ['Current Year', 'Prior Year'];
export const CONTRIBUTION_OPTIONS_VALUE = ['currYear', 'prevYear'];
export const EMPLOYER_YEAR_TEXT= ['Employer', '-'];
export const WF_MAILING_ADDRESS = 'wf_mailing_address';
export const IRA_CONTRIBUTION_LIMIT = 'ira_contribution_limit_';