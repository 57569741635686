import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';


const StyledRating = withStyles({
    iconFilled: {
      color: 'rgba(195,130,26, 1)',
    },
    iconHover: {
      color: 'rgba(195,130,26, 1)',
    },
    iconEmpty: {
      color: 'rgba(195,130,26, 1)' // 'rgba(139, 193, 5, 1)',
    }
})(Rating);


const WRating=props=>{
    const { disabled, max,name,onChange,precision,readOnly,size,value} = props;
    return (
    <div>
        <StyledRating
            disabled={disabled}
            max={max}
            name={name}
            onChange={onChange}
            precision={precision}
            readOnly={readOnly}
            size={size}
            value={value}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}    
        /> 
    </div>
);}

WRating.propTypes={    
    disabled:PropTypes.bool,
    max:PropTypes.number,
    name:PropTypes.string,
    onChange:PropTypes.func,
    precision:PropTypes.number,
    readOnly:PropTypes.bool,
    size:PropTypes.oneOf(["small","medium","large"]),
    value:PropTypes.number,
}

WRating.defaultProps={     
    disabled:false,
    max:5,
    precision:1,
    readOnly:false,
    size:"medium",
    value:2,
    // name: 'customized-empty',
    name: '',
    onChange: () => {}
}

export default WRating;

