import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import routeConstants from 'routeConstants';
import consts from '../../components/constants';

import { resetPreRegistrationInfo } from '../../Actions/AccountRegistrationActions';

class Success extends React.Component {
  componentDidMount() {
    const { resetInfo, accountInfo, history } = this.props;

    if (isEmpty(accountInfo) || accountInfo === undefined) {
      const token = window.localStorage.getItem('token');
      if (token) {
        history.push(`/accountRegistration/?param=${token}`);
      }
    }
    resetInfo();
  }

  render() {
    return (
      <>
        <div className="success-title">
            <h1 className="pageHeading">{consts.successPostGoLiveTitle}</h1>

            <span className="anchorSpan">
              <a
                href={routeConstants.signIn}
                className="inlineLInk"
                target="_blank"
                rel="noopener noreferrer"
              >
                {consts.signin}
              </a>
            </span>
            <span aria-hidden="true">{consts.fullStop}</span>
        </div>
      </>
    );
  }
}

Success.propTypes = {
  resetInfo: PropTypes.func,
  accountInfo: PropTypes.instanceOf(Object),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

Success.defaultProps = {
  resetInfo: () => {},
  accountInfo: {},
  history: {},
};

const mapDispatchToProps = (dispatch) => ({
  resetInfo: () => dispatch(resetPreRegistrationInfo()),
});

const mapStateToProps = (state) => ({
  accountInfo: get(state, 'AccountRegistration.accountInfo', {}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
