import React, { memo  } from 'react';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ShowHideMaskText from 'common/ShowHideMaskText/ShowHideMaskText';

const Styles = {
  verifyDeatilsRow: {
    paddingBottom: 10
  }
}

const MEMBER_INFO_FIELD = [
  {
    key: 'memberid',
    description: 'Member ID',
    isMasked: true,
    dynamic: true,
    dynamicField:'vcmid',
    dynamicDescription: 'Member ID',
  },
  {
    key: 'rank',
    description: 'Rank',
    dynamic: true,
    dynamicField: 'prefix',
    dynamicDescription: 'Salutation',
  },
  { key: 'firstname', description: 'First Name' },
  { key: 'middlename', description: 'Middle Name/ Initial' },
  { key: 'lastname', description: 'Last Name' },
  {
    key: 'suffix',
    description: 'Name Suffix',
  },
  { description: '-' },
  { key: 'dob', description: 'Date of Birth' },
  { description: '-', displayWhen: 'dob' },
  
];

const MemberInfo = memo(({ userFields }) => {
  const DisplayMessage = vcmId => !isEmpty(vcmId) ? (
    <div className="container messageBarDiv" >
      <ShowHideMaskText text={vcmId} startIndex={0} maskLength={(vcmId.length - 4)} />
    </div>
  ): '';
  

  const getFieldData = (
    key,
    dynamic,
    dynamicField,
    dynamicDescription,
    description,
    isMasked,
  ) => {
    if (isMasked && !isEmpty(userFields[key])) {
      return {
        fieldDescription: description,
        value: DisplayMessage(userFields[key]),
      };
    }
    if (!dynamic || (dynamic && !isEmpty(userFields[key]))) {
      return { fieldDescription: description, value: userFields[key] };
    }
    if (dynamic && !isEmpty(userFields[dynamicField])) {
      return {
        fieldDescription: dynamicDescription,
        value: isMasked? DisplayMessage(userFields[dynamicField]) : userFields[dynamicField],
      };
    }
    return null;
  };

  return (
    <>
      {MEMBER_INFO_FIELD.map(
        ({
          key,
          description,
          displayWhen,
          dynamic,
          dynamicField,
          dynamicDescription,
          isMasked,
        }) => {
          if (description === '-') {
            if (
              !isEmpty(displayWhen)
              && (userFields[displayWhen] === null || !userFields[displayWhen])
            ) {
              return null;
            }
            return (
              <hr
                key={`${Math.random()}hr`}
                style={{ marginTop: 6, marginBottom: 6 }}
                aria-hidden="true"
              />
            );
          }
          if (userFields[key] === null || !userFields[key]) {
            if (!dynamic) {
              return null;
            }
            if (
              dynamic
              && (userFields[dynamicField] === null || !userFields[dynamicField])
            ) {
              return null;
            }
          }
          const { fieldDescription, value } = getFieldData(
            key,
            dynamic,
            dynamicField,
            dynamicDescription,
            description,
            isMasked,
          );
          return (
            <Row key={description} className="verifyDeatilsRow" style={Styles.verifyDeatilsRow}>
              <Col className="verifyDetailsLabel">{fieldDescription}</Col>
              <Col style={{ padding: 4 }} className="verifyDetailsText">
                {value}
              </Col>
            </Row>
          );
        },
      )}
    </>
  );
});

MemberInfo.propTypes = {
  userFields: PropTypes.shape({
    firstname: PropTypes.string,
    memberid: PropTypes.string,
    middlename: PropTypes.string,
    lastname: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    rank: PropTypes.string,
    dob: PropTypes.string,
  }),
};

MemberInfo.defaultProps = {
  userFields: {
    firstname: '',
    memberid: '',
    middlename: '',
    lastname: '',
    prefix: '',
    suffix: '',
    rank: '',
    dob: '',
  },
};

export default MemberInfo;
