import { Card, ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as TickMark } from 'assets/blueTick.svg';
import styled from 'styled-components';

const colorStyle = '2px solid #004A98';

const RwdTickMark = styled(TickMark)`
    @media (max-width: 767px) {
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
    }
    @media (min-width: 768px) {
        display: none;
    }
`;

const DesktopTickMark = styled(TickMark)`
    @media (max-width: 767px) {
        display: none;
    }
`;

const AccountCardFlex = styled.div`
    @media (max-width: 767px) {
        display: block;
    }
    display: flex;
`;
const AccountCardTopSection = styled(AccountCardFlex)`
    justify-content: space-between;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
    opacity: 1;
`;
const AccountCardBottomColumn = styled(AccountCardFlex)`
    @media (max-width: 767px) {
        border: none;
        padding: 10px 0;
    }
    
    flex-grow: 1;
    flex-shrink: 1;
    font-size:16px;
    letter-spacing:1px;
    padding: 0 15px;
    border-left: 1px solid #d2d2d2;
    &:first-child {
        padding-left: 0;
        border: none;
    }
    &:last-child {
        padding-right: 0;
        padding-bottom: 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 180px;
    }
`;

const StyledCard = styled.div.attrs(({ selected }) => ({}))`
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 1.875rem;
    border: 0;
    outline: ${props => (props.selected ? colorStyle : '1px solid #D2D2D2')};
    && {
        background: ${props => (props.selected ? '#fff' : '#ffffff')} 0% 0% no-repeat padding-box;
        box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
        border-radius: 0;
    }
    opacity: 1;
    &:hover {
        outline: ${props => (props.selected ? colorStyle : '1px solid #3c5a77')};
    }
    &:focus {
        outline: ${props => (props.selected ? colorStyle : '5px auto -webkit-focus-ring-color')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')};
        }
    }
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 1.25rem 2.25rem;
        @media (max-width: 767px) {
            padding: 1.5rem 1.25rem;
        }
    }
`;

const StyledFundName = styled.button.attrs(({ Id }) => ({ 'aria-describedby': Id }))`
    @media (max-width: 767px) {
        text-align: left;
    }
    font: 800 18px/45px yorkten-slab-normal;
    margin-bottom: 25px;
    line-height: 2;
    // color: #004a98;
    cursor: pointer;
    border: 0;
    background: none;
`;
const StyledCardRow = styled(AccountCardFlex).attrs(({ Id }) => ({ id: Id }))`
    @media (max-width: 767px) {
        padding-top: 5px;
    }
    position: relative;
    padding-top: 29px;
    &::after {
        @media (max-width: 767px) {
            width: calc(100% + 2rem);
            left: -1rem;
        }
        content: '';
        width: calc(100% + 76px);
        height: 0;
        border-top: 1px solid #d2d2d2;
        position: absolute;
        top: 0;
        left: -38px;
    }
`;



const boldText = {
    color: '#56565a',
    fontWeight: 'bold',
};

const CustomDiv = styled.div`
    > label > input[type='radio']{
        height: 185px;
        position: absolute;
        width: 100%;
        left:0;
        top:0.5px;
        z-index: -1;
    }   
    label  button{
        margin: 0;
        padding: 0;
    }
    label{
        padding: 0;
        margin: 0;
        @media (max-width: 767px){
        text-align: left;
        }
    }
 
}`;

const assignObj = obj => {
    return obj;
};

const ProportionateCardComponent = props => {
    
    const {
        accountName,
        fundNumber,
        selected,
        onClick,
        id,
        isRewardShares,
        fundName,
    } = props;

 

    const cardId = `id_${accountName}_${fundNumber}_${fundName}`;
    return (
        !isRewardShares && (
            <StyledCard
                onClick={onClick}
                data-analytic-id="Transactions_Buy_FundAccountSelection"
                selected={selected}
                Id={id}
                data-testid="card"
            >
                <StyledCardBody tabIndex="-1" aria-describedby={cardId} id={fundNumber.toString()} data-testid="card-body">
                    {selected && <RwdTickMark />}
                    <AccountCardTopSection>
                        <CustomDiv>
                        <ToggleButton
                                key="1"
                                type="radio"
                                variant="secondary"
                                name="radio"
                                style={assignObj({
                                    width: '100%',
                                    backgroundColor: '#FFFFFF',
                                    boder: 0,
                                    marginBottom: 20
                                })}
                                value={accountName}
                                // onChange={onClick}
                                checked={!!selected}
                                // aria-checked={!!selected}
                                className="btn btn-outline-light"
                            >
                                <StyledFundName Id={id} style={boldText}  tabIndex="-1" role="presentation">
                                    <span aria-hidden="true">Proportionate Transaction</span>
                                </StyledFundName>
                                </ToggleButton>
                            
                        </CustomDiv>
                        
                        {selected && <DesktopTickMark  data-testid='rwd-tick-mark'/>}
                    </AccountCardTopSection>
                    <StyledCardRow Id={id}>
                        <AccountCardFlex>
                        <AccountCardBottomColumn data-testid='proportionate-description'>
                                A proportionate buy transaction involves investing additional cash into each of your existing funds in accordance with their existing
                    percentages. Instead of favoring one specific fund, this strategy aims to maintain the relative distribution of assets within the portfolio.
                        </AccountCardBottomColumn>
                        </AccountCardFlex>
                    </StyledCardRow>
                </StyledCardBody>
            </StyledCard>
        )
    );
};

ProportionateCardComponent.propTypes = {
   

    accountName: PropTypes.string,
    fundNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
    onClick: () => {},
    isRewardShares: PropTypes.bool,
    id: PropTypes.string,
    fundName: PropTypes.string,
    
};
ProportionateCardComponent.defaultProps = {
    accountName: '',
    fundNumber: '',
    selected: false,
    onClick: () => {},
    isRewardShares: false,
    id: '',
    fundName: '',
};

export default ProportionateCardComponent;
