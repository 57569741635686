import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import {
    deleteProfileContactInformations,
    updateProfileContactInformations,
    clearExistingActions,
    getAccountLevelAddresses,
    updateAccountLevelAddresses,
} from 'shared/Actions/ProfileSettingsAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import CONST from 'modules/CustomerManagementModule/ContactInformation/constants';
import ContactInfoComponent from './ContactInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData } = state;
    const { isError, isSuccess } = profileInformationData;
    const notify = isError || isSuccess;
    const message = isSuccess ? CONST.CONTACT_INFO_SUCCESS : null;
    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        notify,
        message: {
            message,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    deleteProfileContactInformations,
    updateProfileContactInformations,
    clearExistingActions,
    getAccountLevelAddresses,
    updateAccountLevelAddresses,
    analyzeClickEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(ContactInfoComponent));
