import * as AccessFormConstants from '../ReduxConstants/AccessFormConstants';
import makeApiRequest from '../Network/ApiInterface';

export const getAccessForms = payload => dispatch => {
    const env = '';
    dispatch({
        type: AccessFormConstants.GET_ACCESS_FORMS,
    });

    makeApiRequest(
        AccessFormConstants.GET_ACCESS_FORMS,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: AccessFormConstants.GET_ACCESS_FORMS_SUCCESS,
                    payload: { data: response.data, searchPayload: payload.searchPayload },
                });
            }
        },
        error => {
            dispatch({
                type: AccessFormConstants.GET_ACCESS_FORMS_ERROR,
                payload: error,
            });
        },
    );
};

export const loadMore = () => dispatch => {
    dispatch({
        type: AccessFormConstants.LOAD_MORE,
    });
};
