import { ACCOUNT_NUMBER_TEXT } from '../Constants';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as AccImg } from '../../assets/Icon_Logo.svg';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

const AccountSection = styled(Row)`
    @media (max-width: 767.98px) {
        width: auto;
        justify-content: flex-start;
        padding-left: 30px;
        height: 82px;
    }
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: relative;
    width: 540px;
    height: 71px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
    opacity: 1;
    margin-bottom: 40px;
    justify-content: center;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8cbf26;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: 20px solid #8cbf26;
        }
    }
    .row span:nth-child(1) {
        @media (max-width: 767px) {
            display: none;
        }
        height: 40px;
        width: 40px;
        display: inline;
    }
    .row span:nth-child(2) {
        @media (max-width: 767px) {
            display: none;
        }
        border-right: 1px solid #d2d2d2;
        padding-right: 17.5px;
        padding-left: 20px;
    }
    > .row {
        align-items: center;
    }
    .row span:nth-child(4) {
        @media (max-width: 767px) {
            display: none;
        }
        padding-left: 19.5px;
    }
`;

const RwdAccountDetailsDiv = styled.div`
    @media (max-width: 767.98px) {
        margin-left: 20px;
        display: block;
    }
    @media (min-width: 768px) {
        display: none;
    }
`;

const RwdMobileAccountDetailsWrap = styled.div`
    @media (max-width: 767.98px) {
        display: none;
    }
    @media (min-width: 768px) {
        display: block;
        > span:first-child {
            border-right: 1px solid #ccc;
            padding: 0 15px;
            margin-right: 15px;
        }
    }
`;

const AccountInfoCard = props => {
    const { accountName, accountNumber, accountInfoStyles } = props;
    return (
        <AccountSection noGutters style={accountInfoStyles}>
            <Row noGutters>
                <AccImg />
                <RwdMobileAccountDetailsWrap>
                    <span>{accountName}</span>
                    <span className="sr-only">{ACCOUNT_NUMBER_TEXT}</span>
                    <span>{accountNumber}</span>
                </RwdMobileAccountDetailsWrap>
                <RwdAccountDetailsDiv>
                    <div>{accountName}</div>
                    <div className="sr-only">{ACCOUNT_NUMBER_TEXT}</div>
                    <div>{accountNumber}</div>
                </RwdAccountDetailsDiv>
            </Row>
        </AccountSection>
    );
};

AccountInfoCard.propTypes = {
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    accountInfoStyles: PropTypes.instanceOf(Object),
};

AccountInfoCard.defaultProps = {
    accountName: '',
    accountNumber: '',
    accountInfoStyles: {},
};

export default AccountInfoCard;
