import React from 'react';
import { formatPhoneNumber } from 'utils';
import routeConstants from './userRouteConstants';

const { profileLink, securityPreferencesLink, homeLink } = routeConstants;

const blockedText = 'Your online account has been locked.';
const noPhoneNo = 'Look like we don\'t have your Phone Number';
const noMemberMFAMsg = 'Look like MFA is not set for your User ID'
const signinPhoneNo = 'To Sign In please contact Victory Capital Management Service Representative at ';
const blockedReport = ' Please contact member service at ';
const contactNo = '1-800-235-8396';

export const getMaskedNumber = (number) => {
    const joinedNumber = number.split(/[\s()-]+/).join('')
    let maskedNumber = '';
    for(let i=0; i<joinedNumber.length; i+=1){
        const char = joinedNumber.charAt(i)
        if(joinedNumber[0] === '+' && i >= 3 && i <= joinedNumber.length-2){
            maskedNumber += 'X'
        }else if(i >= 2 && i < joinedNumber.length-2){
            maskedNumber += 'X';
        }else {
            maskedNumber += char;
        }

    }
    return maskedNumber
}

export const getMaskedPhoneNumber = (primaryNumber) => {
    let countryCode = primaryNumber ? primaryNumber.substring(0, primaryNumber.length-10) : '';
    const phoneNumber = countryCode===1 ? primaryNumber : primaryNumber.replace(countryCode, '');
    countryCode = countryCode.indexOf('+')>-1 ? countryCode : `+${countryCode||'1'}`;
    return  phoneNumber ? formatPhoneNumber(phoneNumber.replace(/X/g, '0'), true, countryCode) : '';
}

export function ContainsSpecialChar(string) {
    const formatSpecialChar = /[`!@#$%^&*()_[\]{};:|,.<>/?]/;
    if (string.indexOf('~') !== -1) return true;
    if (formatSpecialChar.test(string)) {
      return true;
    }
    return false;
}

export function ContainsSpecialCharOnineId(string) {
    const formatSpecialChar = '`~!@#$%^&*()_-+= ';
    if (formatSpecialChar.indexOf(string)>-1) {
      return true;
    }
    return false;
}
const formatSpecialUserIdChar = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
export function ContainsSpecialUserIdChar(string) {
  if (string.indexOf('~') !== -1) return true;
  if (string.indexOf('\\') !== -1) return true;
  if (string.indexOf(' ') !== -1) return true;
  if (formatSpecialUserIdChar.test(string)) {
    return true;
  }
  return false;
}

export const getPasswordGuidelines = (state) => {
    const {upperLower, numeral, symbol, min} = state;
    const minText= 'Be at least 8 characters in length';
    const upperLowerText= 'Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)';
    const numeralText= 'Have at least one numerical character (e.g. 0-9)';
    const symbolText= 'Have at least one special character (e.g. ~!@#$%^&*()_-+=) or space';
    return [
        { text: minText, valid: min },
        { text: upperLowerText, valid: upperLower },
        { text: numeralText, valid: numeral },
        { text: symbolText, valid: symbol },
    ]
}

const userConstants = {
    passwordText:'Forgot Password?',
    conditionText:'By signing in, you are agreeing to the Victory Capital terms of Use.',
    after1hr: 'Please try generating new one time passcode after an hour.',
    after24hr: 'Please try generating new one time passcode after 24 hours.',
    after24hrIVR: 'We can not process your request at this time. Please try again after 24 hours',
    alphabetRegex: new RegExp('^[a-zA-Z ]*$'),
    nameRegex: new RegExp(/^[a-zA-Z-' ]+$/),    
    alphabetMessage: 'Should contain only Alphabets',
    alphNumericRegex: new RegExp('^[a-zA-Z0-9]*$'),
    alphNumericMessage: ' should be alphanumeric',
    appStore: 'apple app store',
    appStoreLink: 'https://itunesconnect.apple.com',
    blockedMessage: {
        message: <span>{blockedText}</span>,
        info: (
            <span>
                {blockedReport}
                <strong className='strongMessage'>{contactNo}</strong>
            </span>
        ),
    },
    withoutPhoneNumber: {
        message: <span>{noPhoneNo}</span>,
        info: (
            <span>
                {signinPhoneNo}
                <strong className='strongMessage'>{contactNo}</strong>
            </span>
        ),
    },
    noMemberMFA: {
        message: <span>{noMemberMFAMsg}</span>,
        info: (
            <span>
                {signinPhoneNo}
                <strong className='strongMessage'>{contactNo}</strong>
            </span>
        ),
    },
    changeSignInMethod: 'changeSignInMethod',
    changeSignInMethodText: 'Change Sign in Method',
    confirmPasswordLabel: 'Confirm Password',
    container: 'container',
    current: ' (Current)',
    externalWeb: 'Opens PDF in new window',
    emailAddress: 'emailAddress',
    hidden: 'hidden',
    mobile: 'mobile',
    number: 'number',
    onlineId: 'onlineId',
    optionalMessage: '* All fields are required unless mentioned optional',
    otp: 'otp',
    otpText: 'One Time Passcode',
    otpViaMobile: 'otpViaMobile',
    passwordLabel: 'Password',
    passwordRegex: new RegExp('(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$'),
    passwordMessage: 'The Password does not follow the password policy',
    passwordError: "The password you entered doesn't match our records, please try again",
    preferredOTPDeliveryMethod: 'preferredOTPDeliveryMethod',
    playStore: 'google play store',
    playStoreLink: 'https://play.google.com/store',
    push: 'push',
    pushText: 'Push Notification',
    radioGroup: 'group',
    resetSuccess: 'Password Reset Successfully. Please Sign In.',
    selectRegex: new RegExp('^((?!Select).)*$', 'i'),
    softToken: 'softtoken',
    softTokenText: 'Soft Token',
    test: 'test',
    text: 'text',
    token: 'token',
    trueText: 'true',
    userId: 'userId',
    uuid: 'uuid',
    w100: 'w-100',

    changeSignInByOTPComponent: {
        pageHeaderName: 'Change Sign In Method to One Time Password',
        labelText: 'Select from below options to set your preferred Sign On Method',
        checkBoxText: 'Check this option to save current device and skip One Time Passcode authentication',
        methodChangeOtp:
            'Your Sign In method has been successfully changed to One Time Passcode via mobile authentication',
        otpTo: 'One Time Password to mobile no ',
        saveManageCheck: 'saveDevice manageCheckBox checked',
        saveManage: 'saveDevice manageCheckBox',
        saveDeviceText: 'saveDevice',
        saveCurrent: 'Save current device',
    },

    changeSignInMethodOptions: {
        breadCrumbValues: [
            { name: 'Home', url: homeLink },
            { name: 'Profiles & Preferences', url: profileLink },
            { name: 'Security Preferences', url: securityPreferencesLink },
        ],
        otpRdoBtn: 'otpRdoBtn',
        softTokenRdoBtn: 'softTokenRdoBtn',
        pushNotificationRdoBtn: 'pushNotificationRdoBtn',
        otpSubText: 'You will receive One Time Passcode during Sign in.',
        softSubText: 'Sample Content',
        pushSubText: 'Sample Content',
        changeByOtp: 'ChangeSignInByOTP',
        changeBySoft: 'ChangeSignInBySoftToken',
        changeByPush: 'ChangeSignInByPushNotification',
        pageHeaderName: 'Change Sign in Method',
        signInOptionsLabels: 'Select from below options to change your Sign on method',
        updateText: 'Last update : 21/05/2019 1:22 PM',
        updateTextClass: 'form-text text-muted float-right',
        lastUpdateId: 'lastUpdate',
        arialabelback: 'Back to Security Preferences'
    },
    changePush: {
        pageHeaderName: 'Change Sign in Method to Push Notification',
        downloadLabel: 'Download the app which can generate push notification',
        successMessage: 'Your Sign In method has been successfully changed to Push Notification',
        failureMessage: 'Your Sign In method has not been changed to Push Notification',
    },
    changeSoft: {
        firstToken: 'First Token',
        secondToken: 'Second Token',
        credentialId: 'Credential ID',
        firstTokenId: 'firstToken',
        secondTokenId: 'secondToken',
        credentialIdId: 'credentialId',
        pageHeaderName: 'Change Sign in Method to Soft Token',
        downloadLabel: 'Download the Token Generation Application from the below link and enter the Token Details',
        optionalMessage: '* All fields are required unless mentioned optional',
        notificationMessage: 'Your Sign In Method has been successfully changed to Soft Token',
    },
    msgForInvalidPrimNum: (flow) => {
       return  {
           message:`Looks like we don't have your Phone Number.`,
           info: `To ${flow} please contact Victory Management Service representative at +1 (800) 235-8396`
           
       };
    },
};

Object.freeze(userConstants);

export default userConstants;
