/**
 * Marketing and Privacy Component
 *********************************
 * 
 * @version 1.0.1
 * @author Manjunath V
 * @description This page contains the set up to privacy modes and the permissions to marketing calls 
 * @createdDate [25/11/2019]
 * ***************************************
 * @lastModifiedDate  Manjunath V
 * @lastModifiedBy [06/12/2019]
 * @lastModifiedReason Updated Styles for this page and updated Breadcrumbs
 */


import React, { Component } from 'react';
import styled from 'styled-components';
import {Row,Col,Form} from  'react-bootstrap';
import Switch from "react-switch";
import {faPhoneVolume,faVolumeMute} from '@fortawesome/free-solid-svg-icons';
import { WBreadCrumb, WSwitch , WIcon, WInputCheckBox} from '../../../common';
import SideHeader from '../../UserManagementAndSecurityModule/SideHeader/SideHeader';
import styles from './styles';
import responseData from './constants';
import Consts from '../DeliverySettings/constants';

const assignObj = obj => {
    return obj;
  };


const CardContainer = styled.div`
  position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    border: ${props => (props.selected ? '1px solid #8BC105' : '1px solid #D2D2D2')};
    background: ${props =>
        props.selected ? '#fafafa 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'};
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    &:hover {
        border: ${props => (props.selected ? '2px solid #8BC105' : '2px solid #004A98')};
        &::before {
            border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #004A98')};
        }
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

const CardHead = styled.div`
    text-align: left;
    letter-spacing: 0;
    color: #544a54;
    opacity: 1;
    padding: 25px;
    font-weight:bold
`;

const CallPreference = styled.div`
margin-bottom:50px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid rgba(183, 183, 183, 0.4);
opacity: 1;
`;

const CallTimeHeading = styled.span`
text-align: left;
font: 600 20px/23px benton-sans;
letter-spacing: 0;
color: #56565A;
opacity: 1;
padding-left:20px;
`;

const CallTimeDescription = styled.div`
text-align: left;
margin-top:10px;
margin-bottom:20px;
margin-left:64px;
span {
    font: 400 15px/25px Roboto;
    letter-spacing: 0.3px;
    color: #707070;
    padding-bottom:10px;
    display:block;
    margin:0;
    padding:0;
}
span:first-child {
    text-align: left;
font: 500 14px/25px Roboto;
letter-spacing: 0;
color: #707070;
padding-bottom:10px;
display:inline;
}
span:nth-child(2) {
    display:inline;
}

`;

const TimeZone = styled(Row)`
justify-content: flex-start;
margin-bottom:21px;
.col {
    max-width:190px;
}
`;

const TimeLabel = styled(Col)`
padding-top:15px;
margin-left:64px;
text-align: left;
font: 600 14px/17px benton-sans;
letter-spacing: 0;
color: #333333DE;
opacity: 1;
&& {
    max-width:130px;
}
span {
}
`;
const QuietTimeDescription = styled.div`
text-align: left;
margin-top:10px;
margin-bottom:20px;
margin-left:64px;
margin-right:30px;
span {
    font: 400 15px/25px Roboto;
    letter-spacing: 0.3px;
    color: #707070;
    padding-bottom:10px;
    display:block;
    margin:0;
    padding:0;
}`;

const QuietTimeHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const QuietSwitch = styled.div`
margin-top:25px;
margin-right:20px;
`;

const PreferedTime = styled(Row)`
&& {
    margin-left:49px;
    margin-bottom:23px;
}
.col {
    padding-right:18px;
}
`;

const TimePicker = styled(Row)`
justify-content:flex-start;
padding-bottom:30px;
vertical-align:middle;
.col:first-child {
    margin-left:64px;
}
.col{
    max-width:132px;
}
span {
    display:block;
    padding-top:16px;
    text-align: left;
font: 600 14px/17px benton-sans;
letter-spacing: 0;
color: #333333DE;
opacity: 1;
}
.col:nth-child(3) {
    margin-left:120px;
}
`;
export default class MarketingAndPrivacyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            headerClick: false,
            checked: '',
            quietTimeSelected: true,
            emailUrgent:false,
            emailNotification:false,
            pushUrgent:false,
            pushNotification:false,
            messageUrgent:false,
            messageNotification:false,
            loading: false,
            onSubmitFlag: false,
            preferedTime:'',
            quietTimeZone: '',
            callTimeZone: '',
            startTime: 'HH:MM',
            endTime: 'HH:MM'
        };

        this.bindSwitch = this.bindSwitch.bind(this);
    }

    /* 
        * Method : cardHeadreClick
        * Description : cardHeadreClick expans or closes the accordian
    */

    cardHeadreClick = e => {
        e.preventDefault();
        e.target.classList.toggle("expand");
    };

    emptyFunction = () => { };

    onSwitchClick = (id) => {
        if(id) {
            this.setState({
                checked: id
            })
        }
        
    }

    handleCheck = (key) => (e) => {
        this.setState({[key]: e.target.checked});
     }
 
     handleTime = (e) => {
         this.setState({ [e.target.id]: e.target.value});
     }
 
     handleChange = (key) => (e) => {
         if(key === 'call') {
            this.setState({ callTimeZone: e.target.value});
         }else if(key === 'quiet') {
             this.setState({ quietTimeZone: e.target.value});
         }
     }

     configureSettings = (id) => () => {
        this.setState((prevState) => {
            return { [id]: !prevState[id.toString()]}
        });
    }

    /* 
        * Method : bindSwitch
        * Description : bindSwitch displays the on off switch
    */
    bindSwitch = () => {
        const elements = [];
        elements.push(
            <Switch
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={11}
                width={42}
                className="react-switch"
                id="material-switch"
            />
        )
        return (elements);
    }

    renderCallPreference = () => {
        const stateData = this.state;
        const { callTimeZone } = this.state;
        return (
          <CallPreference >
              <div>
              <span><WIcon icon={faPhoneVolume} style={Consts.iconStyle} fontawesomeIconStyles={Consts.messageIconSvgStyle} fontawesomestyle={Consts.phoneIconStyle} alt="phone icon" isze="2x" /></span>
              <CallTimeHeading >{Consts.callTimeText}</CallTimeHeading>
              </div>
              <CallTimeDescription>
                  <span>{Consts.callTimeDescription1}</span><span>{Consts.optional}</span>
                  <span>{Consts.callTimeDescription2}</span>
                  <span>{Consts.callTimeDescription3}</span>
              </CallTimeDescription>
              <TimeZone>
                  <TimeLabel><span>{Consts.timeZone}</span></TimeLabel>
                  <Col>
                  <div style={Consts.selectArrow} />
                  <Form.Control
                    as="select"
                    onChange={this.handleChange('call')}
                    onBlur={this.handleBlur}
                    id="timeZone"
                    value={callTimeZone}
                    style={Consts.select}
                 ><option value="">{Consts.selectText}</option>
                {
                         Consts.timeZoneOptions.map(item => <option key={item.key}>{item.value}</option>)
                }
                </Form.Control>
                </Col>
              </TimeZone>
              <PreferedTime>
                  {
                      Consts.preferredTime.map(time => 
                       <Col >
                       <WInputCheckBox
                                    checkboxwrapperstyle={Consts.checkboxwrapperstyle}
                                    checkInputStyle={Consts.checkInputStyle}
                                    checked={!!stateData[time.key]}
                                    value={time.value}
                                    onChange={this.handleCheck(time.key)}
                                    checkboxstyle={Consts.checkboxstyle}
                                    labelstyle={Consts.labelstyle}
                                    label={time.value}
                                />
                       </Col>
                      )
                  }
              </PreferedTime>
          </CallPreference>
        );
    }

    renderQuietTime = () => {
        const { quietTimeSelected,quietTimeZone,startTime,endTime } = this.state;
        return (
          <CallPreference >
              <QuietTimeHeader>
                  <div>
              <span><WIcon icon={faVolumeMute} style={Consts.iconStyle} fontawesomeIconStyles={Consts.messageIconSvgStyle} fontawesomestyle={Consts.phoneIconStyle} alt="phone icon" isze="2x" /></span>
              <CallTimeHeading >{Consts.quietTimeText}</CallTimeHeading>
              </div>
              <QuietSwitch>
              <WSwitch
                      id="quietTimeSelected"
                      onSwitchClick={this.configureSettings("quietTimeSelected")}
                      checked={quietTimeSelected}
                    />
              </QuietSwitch>
              </QuietTimeHeader>
              <QuietTimeDescription>
                  <span>{Consts.quietDescription1}</span>
                  <span>{Consts.quietDescription2}</span>
              </QuietTimeDescription>
              <TimeZone>
                  <TimeLabel><span>{Consts.timeZone}</span></TimeLabel>
                  <Col>
                  <div style={Consts.selectArrow} />
                  <Form.Control
                    as="select"
                    onChange={this.handleChange('quiet')}
                    onBlur={this.handleBlur}
                    id="timeZone"
                    value={quietTimeZone}
                    style={Consts.select}
                ><option value="">{Consts.selectText}</option>
                    {
                         Consts.timeZoneOptions.map(item => <option  key={item.key}>{item.value}</option>)
                }
                </Form.Control>
                </Col>
              </TimeZone>
              <TimePicker noGutters>
                  <Col>
                  <span>{Consts.startText}</span>
                  </Col>
                  <Col>
                  <input
                  type="time"
                    onChange={this.handleTime}
                    onBlur={this.handleBlur}
                    id="startTime"
                    min="00:00"
                    value={startTime}
                    style={Consts.select}
                />
                  </Col>
                  <Col>
                  <span>{Consts.endText}</span>
                  </Col>
                  <Col>
                  <input
                  type="time"
                    onChange={this.handleTime}
                    onBlur={this.handleBlur}
                    min="00:00"
                    id="endTime"
                    value={endTime}
                    style={Consts.select}
                />
                  </Col>
              </TimePicker>
          </CallPreference>
        );
    }

    /* 
     * Method : render
     * Description :The complete DOM will be displayed when the render method is executed
    */

    render() {
        const docCard = responseData.accordionData.map((item) => {
            const { checked} = this.state;
            const dash = '  |  ';
            return (

                <CardContainer
                    key={item.accordionTitleText.toString()}
                    selected={!!item.selected}
                // onClick={this.onFundSelection(index)}
                >
                    <CardHead>{item.accordionTitleText}{dash}{item.accordionText} <div style={assignObj({float:'right', margin:'-5px'})}>
                        <WSwitch
                            id={item.accordionId}
                            onSwitchClick={this.onSwitchClick()}
                            checked={checked === item.accordionId}
                        />
                    </div></CardHead>
                    {item.accordionId === 3 ? <div>
                        {this.renderCallPreference()}
                        {this.renderQuietTime()}
                    </div> : ''}
                    
                </CardContainer>

            )
        })
        return (
            <div>
                {  /* 
                    * Component : Header
                    * Description :Displays a Header
                    */
                }
                <div style={styles.pageBgColor}>
                    <div className="container">
                        {  /* 
                            * Component : WBreadCrumb
                            * Description :WBreadCrumb will have breadCrumbItems and activeCrumb as props which displays a breadcrumb
                            */
                        }
                        <WBreadCrumb
                            breadCrumbItems={styles.breadCrumbValues}
                            activeCrumb='Marketing & Offers'
                        />
                        <SideHeader
                            title={styles.pageName}
                        />
                        <div className="container">
                            {docCard}
                        </div>
                    </div>
                </div>
                {  /* 
                    * Component : Footer
                    * Description :Displays a Footer
                    */
                }
            </div>
        );
    }
}