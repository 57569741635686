import {
  RESET_OTP_SUCCESS_FLG,
  REJECTED_GET_PASSCODE,
  RESOLVED_GET_PASSCODE,
  RESOLVED_IVR_REQ_STATUS,
  REJECTED_IVR_REQ_STATUS,
  RESET_EMAIL_OTP_FLG,
} from 'shared/ReduxConstants/AccountRegistrationConstants';

import {
  API_REQUEST_PHONE_OTP,
  API_IVR_REQUEST_STATUS,
} from 'shared/ReduxConstants/ServiceActionConstants'
import { dispatchLoading } from '../../utils.actions';
import accountRegistrationAPICall from '../../service/AccountRegistrationService';
import { errorCodeMessageMapping } from '../../components/constants';

export const getIVRStatus = (data) => (dispatch) => {
  if (data === '') return;

  accountRegistrationAPICall(
    API_IVR_REQUEST_STATUS,
    data,
    (response) => {
      if (response.status === 200) {
        const { message, result } = response.data;
        if (message === 'Success' && result.confirmed === 'true') {
          dispatch({
            type: RESOLVED_IVR_REQ_STATUS,
            payload: {
              ivrStatus: 'success',
              OTCMsg: '',
            },
          });
        } else {
          dispatch({
            type: REJECTED_IVR_REQ_STATUS,
            payload: {
              ivrStatus: 'failed',
              OTCMsg:
                errorCodeMessageMapping[response.data.errorCode]
                || response.data.result,
            },
          });
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    (error) => {
      dispatch({
        type: RESOLVED_IVR_REQ_STATUS,
        payload: {
          ivrStatus: 'rejected',
          OTCMsg: error.data.result,
        },
      });
    },
  );
};

const getErrorMessage = (response) => (dispatch) => {
  const errorMsg = response.data
  ? errorCodeMessageMapping[response.data.errorCode]
    || response.data.result
  : 'Error occured while requesting Security code';

  dispatch({
    type: REJECTED_GET_PASSCODE,
    payload: {
      OTCMsg: errorMsg,
      loading: false,
      OTPSuccess: 'failed',
      otpValidationErrorMsg: '',
      otpValidationErrorCode: response.data
        ? response.data.errorCode
        : '',
    },
  });
}


export const getPassCode = (data) => (dispatch) => {
  if (data !== '') {
    dispatchLoading('OTCDetails')(dispatch);

    accountRegistrationAPICall(
      API_REQUEST_PHONE_OTP,
      data,
      (response) => {
        if (response.status === 200 && response.data.errorCode === 0) {
          if (data.requestType === 'OTP') {
            dispatch({
              type: RESOLVED_GET_PASSCODE,
              payload: {
                OTPSuccess: 'success',
                loading: data.requestType !== 'OTP',
                OTCMsg: '',
                otpValidationErrorMsg: '',
              },
            });
          } else {
            setTimeout(() => {
              getIVRStatus(data)(dispatch);
            }, 25000);
          }
        } else {
          getErrorMessage(response)(dispatch);
        }
      },
      (error) => {
        if (error.data) {
          dispatch({
            type: REJECTED_GET_PASSCODE,
            payload: {
              OTCMsg: error.data.result,
              loading: false,
              OTPSuccess: 'failed',
              otpValidationErrorMsg: '',
              otpValidationErrorCode: '',
            },
          });
        }
      },
    );
  }
};

export const resetOTPFlag = () => (dispatch) => {
  dispatch({
    type: RESET_OTP_SUCCESS_FLG,
    payload: {
      OTPSuccess: '',
      OTCMsg: '',
      ivrStatus: '',
      otpValidationErrorMsg: '',
      otpValidationSuccess: false,
    },
  });
};

export const resetEmailOTPFlag = () => (dispatch) => {
  dispatch({
    type: RESET_EMAIL_OTP_FLG,
    payload: {
      isEmailOTPValidated: false,
      receivedEmailOTP: false,
    },
  });
};
