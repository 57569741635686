import React, { Component } from 'react';
import styled from 'styled-components';
import routeConstants from 'routeConstants';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import CONSTANTS, {
    WITHDRAWAL_FROM,
} from '../../../../constant';

import {
    AccountSelectedInfoCard,
    BottomNavWrapper,
} from './../../../../../../common';

import {
    formatDateToTwoDigit,
    formatCurrency
} from './../../../../../../utils';

import './WithdrawalFrom.css';
import YouSelect from '../YouSelect';

const CurrentValueSection = styled.div`
    width: 50%;
    padding: 0.75rem;
`;

const PriorYearEndBalanceAmount = styled.div`
    display: flex;
`;

const DollarSignDiv = styled.div`
    font-size: 17px;
    margin-right: 5px;
    margin-top: 10px;
`





const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Section = styled.div`
    width: 50%;
    font-size: 1rem;
    padding-right: 40px;
`;
const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 20px;
`;

const UnitSpan = styled.label`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    width: 1.25rem;
    && {
        margin-bottom: 0;
    }
`;
export const AmtInput = styled.input`
    background: ${props => (props.disabled ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: ${props => (props.error ? '1px solid red' : '1px solid #D2D2D2')};
    opacity: 1;
    height: 3.125rem;
    padding: 0.625rem;
    &&:hover {
        border-color: ${props => (props.disabled ? '#D2D2D2' : 'rgba(72, 109, 144, 1)')};
    }
    && {
        color: ${props => (props.negative ? 'red' : 'rgba(73, 73, 74, 1)')};
    }
`;


export const AmtTopsection = styled(FlexColumnDiv)`
    margin-left: ${props => (props.margin ? '3.75rem' : 0)};
    @media (max-width: 1200px) {
        margin-left: 1rem;
    }
`;


const ShareTitleSection = styled(FlexRowDiv)`
    align-items: center;
    padding: 1.25rem 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    opacity: 1;
`;

const ShareBody = styled.div``;

export const ShareBodySection = styled(FlexRowDiv)`
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const ShareTitle = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;
export const IndividualShare = styled.div`
    margin-bottom: 1.875rem;
    opacity: 1;
    position: relative;
    outline: ${props => (props.selected ? '2px solid #004A98' : '1px solid #D2D2D2')};
    background: ${props => (props.selected ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    border-radius: 0;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8CBF26' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const ErrMsgContainer = styled.div`
    position: relative;
    color: red;
`;


const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

const ShortFundName = styled.div`
    display: flex;
    padding: 0.5rem;
    width:75px;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
`;

const ShareCardRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.25rem;
    border-bottom: 1px solid #d2d2d2;
`;
const StyledInput = styled.input`
    width: 200px;
    height: 40px;
    border-radius: 0px;
    padding-left: 10px;
    background: white;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
        &:disabled {
            color: black;
        }
        &:invalid {
            border-color: red;
        }
    }
`;

const ShareInfoHeader = styled.div`
    text-align: left;
    font-size: 16px;
    font-family: benton-sans;
    letter-spacing: 0px;
    color: #56565A;
    opacity: 1;
    font-weight: bold;
`;



export const getNextInvestmentDate = (dateObj) => {
    const { startDate = "", frequency = "" } = dateObj || {};
    if (!startDate || !frequency) return "";
    const newDate = new Date(startDate);
    const day = newDate.getDate();
    const startMonth = newDate.getMonth();
    const startYear = newDate.getFullYear();
    let nextInvestment = "";
    switch (frequency) {
        case "M": {
            nextInvestment = new Date(startYear, startMonth + 1, day);
            break;
        }
        case "W": {
            nextInvestment = new Date(startYear, startMonth, day + 7);
            break;
        }
        case "S": {
            nextInvestment = new Date(startYear, startMonth, day + 14);
            break;
        }
        case "Q": {
            nextInvestment = new Date(startYear, startMonth + 3, day);
            break;
        }
        case "T": {
            nextInvestment = new Date(startYear, startMonth + 6, day);
            break;
        }
        case "P": {
            nextInvestment = new Date(startYear, startMonth + 2, day);
            break;
        }
        case "B": {
            nextInvestment = new Date(startYear, startMonth, day + 14);
            break;
        }
        case "A": {
            nextInvestment = new Date(startYear + 1, startMonth, day);
            break;
        }
        default:
            break;
    }
    return nextInvestment && formatDateToTwoDigit(nextInvestment);
}


export const ShareInputCard = (props) => {
    const {
        selected,
        fundName,
        shortFundName,
        index,
        onInputBlur,
        errorMsg,
        shareData,
        originalShareData,
        selectAll,
        handleInputClick,
        onClickShareInputCard,
        handleInputChange
    } = props;
    const { symbols: symbols_sign } = CONSTANTS;
    
    let error = (shareData.priorYearEndBalance === '0.00') ? 'Please enter balance' : errorMsg

    return (
        <IndividualShare onClick={onClickShareInputCard} selected={selectAll || selected}>
            <ShareTitleSection>
                <ShareNameDiv>
                    <ShortFundName>{shortFundName}</ShortFundName>
                    <ShareTitle>{fundName}</ShareTitle>
                </ShareNameDiv>
            </ShareTitleSection>
            <ShareBody>
                <ShareCardRow>
                    {originalShareData.priorYearEndBalance && originalShareData.priorYearEndBalance !== "0.00" ? (
                        <CurrentValueSection>
                            <p className="withdrawalSectionTitle">
                                {WITHDRAWAL_FROM.priorYearEndBalance}
                            </p>
                            <p>
                                {formatCurrency(originalShareData.priorYearEndBalance)}
                            </p>
                        </CurrentValueSection>
                    ) : (
                            <>
                                <CurrentValueSection>
                                    <p className="withdrawalSectionTitle">
                                        {WITHDRAWAL_FROM.priorYearEndBalance}
                                    </p>
                                    <PriorYearEndBalanceAmount>
                                        <DollarSignDiv>
                                            {symbols_sign.dollar}
                                        </DollarSignDiv>
                                        <StyledInput
                                            type='number'
                                            name="priorYearEndBalanceAmount"
                                            pattern='^[0-9]'
                                            min='1'
                                            step='1'
                                            // placeholder='0.00'
                                            defaultValue={shareData.priorYearEndBalance || '0.00'}
                                            onClick={handleInputClick}
                                            onChange={handleInputChange}
                                            onBlur={onInputBlur}
                                            required
                                        />
                                    </PriorYearEndBalanceAmount>

                                    {error && <ErrMsgContainer>{error}</ErrMsgContainer>}
                                </CurrentValueSection>
                            </>
                        )}

                    <CurrentValueSection>
                        <p className="withdrawalSectionTitle">
                            {WITHDRAWAL_FROM.balance}
                        </p>
                        <p>
                            {formatCurrency(shareData.currentAccountBalance)}
                        </p>
                    </CurrentValueSection>
                </ShareCardRow>
            </ShareBody>
        </IndividualShare>
    );
}

ShareInputCard.propTypes = {
    selected: PropTypes.bool,
    fundName: PropTypes.string,
    index: PropTypes.number,
    arialabel: PropTypes.string,
    inputRef: PropTypes.instanceOf(Object),
    shareData: PropTypes.instanceOf(Object),
    originalShareData: PropTypes.instanceOf(Object),
    onInputBlur: PropTypes.func,
    errorMsg: PropTypes.string,
    onClickShareInputCard: PropTypes.func,
    handleInputClick: PropTypes.func,
    handleInputChange: PropTypes.func
};

ShareInputCard.defaultProps = {
    selected: false,
    fundName: '',
    index: 0,
    arialabel: '',
    inputRef: null,
    onInputBlur: () => { },
    errorMsg: '',
    shareData: {},
    originalShareData: {},
    onClickShareInputCard: () => { },
    handleInputClick: () => { },
    handleInputChange: () => { }
};



class WithdrawalFrom extends Component {
    constructor(props) {
        super(props);
        const { pageIndex, shares } = this.props;

        let disableNext = false
        shares.map((item) => {
            if (item.priorYearEndBalance === "0.00") {
                disableNext = true
            }
        })

        this.state = {
            shares: shares || [],
            originalShares: cloneDeep(props.shares) || [],
            retrivedState: false,
            disableNext: disableNext,
            displayDetails: {
                titleTxt: 'Fund Selection',
                editTxt: 'Edit',
                pageIndex,
                data: [],
            },
            amountIn: '$',
            totalErrMsg: '',
            remainingErrMsg: '',
            selectedSharesData: [],
        };
        this.inputRefs = [];
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }

        return null;
    }

    componentDidMount() {
        this.updateRefs();
        this.formatShares();
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;

        saveChildrenState(this.state, pageIndex);
    }

    componentDidUpdate(prevProps) {
        const { shares, allSelected } = this.props;
        this.updateRefs(prevProps.shares !== shares);

        if (prevProps.allSelected !== allSelected) {
            this.formatShares();
        }

    }

    formatShares = () => {
        const { shares } = this.state;

        const {
            totalPriorYearBalance,
            displayData,
            selectedSharesData
        } = this.getNewShareDetails(shares);

        this.setState({
            totalPriorYearBalance,
            displayDetails: {
                ...this.state.displayDetails,
                data: displayData
            },
            selectedSharesData,
            disableNext: !this.areAllDataValid( shares )
        });
    }
    
    onClickShareInputCard = (index, allSelected) => e => {
        e.stopPropagation();

        if (!allSelected) {
            const { shares } = this.state;
            shares.map((item) => item.selected = false);
            shares[+index].selected = true;

            const disableNext = !this.areAllDataValid( shares );

            this.setState({ 
                shares,
                disableNext
            }, () => this.formatShares());
        }
    };

    handleInputClick = e => {
        e.stopPropagation();
    }

    handleInputChange = index => e => {
        const { shares } = this.state;

        if (+e.target.value > 0) {
            shares[+index].priorYearEndBalance = e.target.value;
            shares[+index].errorMsg = '';
        } else if (e.target.value < 0) {
            shares[+index].priorYearEndBalance = 0;
            shares[+index].errorMsg = 'Please enter a valid balance';
        } else if (e.target.value == 0) {
            shares[+index].priorYearEndBalance = 0;
            shares[+index].errorMsg = 'Please enter balance';
        }

        const disableNext = !this.areAllDataValid( shares );
       
        this.setState({ 
            shares,
            disableNext
        });
    }

    areAllDataValid( shares ) {
        const { allSelected } = this.props;

        let allItemsHaveValues = true;
        let atLeastOneItemIsSelected = false;
        let atLeastOneItemHasBalance = false;
        shares.map((item) => {
            if (allSelected) {
                if (item.priorYearEndBalance === "0.00" || !item.priorYearEndBalance) {
                    allItemsHaveValues = false;
                }
            } else {
                if (+item.priorYearEndBalance) {
                    atLeastOneItemHasBalance = true;
                }
            }

            if (item.selected === true) {
                atLeastOneItemIsSelected = true;
            }

        });

        return (allSelected && allItemsHaveValues) || (!allSelected && atLeastOneItemHasBalance && atLeastOneItemIsSelected);
    }

    // To get new details
    getNewShareDetails = (shares) => {
        const { allSelected } = this.props;
        let totalPriorYearBalance = 0;
        const displayData = [];
        const selectedSharesData = [];
        shares.forEach(el => {
            const newItem = { ...el };
            if (allSelected || newItem.selected) {
                selectedSharesData.push({
                    fundNumber: newItem.fundNumber,
                    fundAccountNumber: newItem.fundAccountNumber,
                    priorYearEndBalance: newItem.priorYearEndBalance
                });
                
                displayData.push(
                    {
                        field: <ShortFundName>{newItem.shortFundName}</ShortFundName>,
                        value: <ShareInfoHeader>{newItem.fundName}</ShareInfoHeader>,
                        fundNumber: newItem.fundNumber,
                        fundAccountNumber: newItem.fundAccountNumber
                    }
                );
            }

            totalPriorYearBalance += +newItem.priorYearEndBalance || 0;
        });

        return {
            totalPriorYearBalance,
            displayData,
            selectedSharesData
        }
    }

    updateRefs = (forceUpdate = false) => {
        const { shares } = this.state;
        if ((!this.inputRefs.length && shares.length) || forceUpdate) {
            this.inputRefs = shares.map(() => React.createRef());
        }
    };

    clickBack = () => {
        const { history } = this.props;
        history.push(routeConstants.addRequiredMinimumDistribution);
    }

    headerClick = e => {
        e.target.classList.toggle('expand');
    };

    getAllFundsView = () => {
        const { shares, originalShares } = this.state;
        const { allSelected, investTo } = this.props;
        const { detailName = '' } = investTo || {};

        const hasPriorYearEndBalance = originalShares.every(item=>item.priorYearEndBalance && (+item.priorYearEndBalance)!==0);
        return (
            <>
                {!hasPriorYearEndBalance && <SectionTitle>Enter the year-end balances from {new Date().getFullYear()-1} for all the funds bellow.</SectionTitle>}
                {shares.map((item, index) => {
                    const arialabel = `Select to ${detailName} ${item.fundName}`
                    return (
                        <ShareInputCard
                            shareData={item}
                            originalShareData={originalShares[+index]}
                            key={index.toString()}
                            selected={!!item.selected}
                            fundName={item.fundName}
                            shortFundName={item.shortFundName}
                            index={index}
                            arialabel={arialabel}
                            inputRef={this.inputRefs[+index]}
                            onInputBlur={this.formatShares}
                            errorMsg={item.errorMsg}
                            selectAll={allSelected}
                            onClickShareInputCard={this.onClickShareInputCard(index, allSelected)}
                            handleInputClick={this.handleInputClick}
                            handleInputChange={this.handleInputChange(index)}
                        />
                    );
                })}
            </>
        )
    }

    render() {
        const { disableNext } = this.state;

        const { accDetails = {}, navigationObj, pageIndex, answerDetails, allSelected} = this.props;

        const youSelectAccountDetails = accDetails ? [accDetails] : [];

        return (
            <div className="withdrawalfrom">
                <AccountSelectedInfoCard accDetails={accDetails} shouldShowBalance={true} />

                <SectionWrapper>
                    <YouSelect pageIndex={pageIndex} 
                        pageSubIndex={allSelected ? 0 : 1} 
                        answerDetails={answerDetails} 
                        accountDetails={youSelectAccountDetails}/>
                    <Section>
                        {this.getAllFundsView()}
                    </Section>
                </SectionWrapper>

                <BottomNavWrapper
                    {...navigationObj}
                    showBack={true}
                    disabled={disableNext}
                // dataAnalyticId={RMD_ADD_PLAN_DETAILS_NAV_BUTTON_ID}
                />
            </div>
        )
    }
}

WithdrawalFrom.propTypes = {
    withdrawalPreference: PropTypes.string,
    accDetails: PropTypes.instanceOf(Object),
    shares: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    forwardRef: PropTypes.instanceOf(Object),
    investTo: PropTypes.instanceOf(Object),
    plan: PropTypes.string,
    ammountChanged: PropTypes.func,
    onCancel: PropTypes.func,
    allSelected: PropTypes.bool,
    answerDetails: PropTypes.arrayOf(Object),
};

WithdrawalFrom.defaultProps = {
    withdrawalPreference:"",
    accDetails: {},
    shares: [],
    savedState: null,
    pageIndex: 0,
    allSelected: false,
    investTo: {},
    forwardRef: {
        current: null,
    },
    plan: '',
    ammountChanged: () => { },
    onCancel: () => { },
    answerDetails: [],
};

export default WithdrawalFrom;