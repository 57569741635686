import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    stateCitySuccess: false,
    addressFormatSuccess: false,
    showUSPSDialog: false,
    USPSVerifiedAddress: {},
    addressInformation: {
        addressLine1: '',
        addressLine2: '',
        zip: '',
        city: '',
        state: '',
        addressLine2ErrorText: '',
        zipError: '',
    },
    callAddressAPI: false,
    previousZip: '',
    previousAddrLine1: '',
    previousAddrLine2: '',
    isAddressReady: false,
};

const getReadyStatus = (addressInformation, USPSVerifiedAddress) => {
    
    let ready = false;
    const {
        addressLine1= '',
        addressLine2= '',
        zip= '',
        city= '',
        state= '',
        addressLine2ErrorText= '',
        zipError= '',
    } = addressInformation;
    
    const {
        addressLine1:uspsAddr1= '',
        addressLine2:uspsAddr2= '',
        zip:uspsZip= '',
        city:uspsCity= '',
        state:uspsState= '',
    } = USPSVerifiedAddress;
    
    if( addressLine1 === uspsAddr1 &&
        addressLine2 === uspsAddr2 &&
        zip === uspsZip &&
        city === uspsCity &&
        state === uspsState &&
        !addressLine2ErrorText &&
        !zipError){
        ready = true;
    }
    
    return ready;
};

function addressFormatReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_REDUX_DATA:
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false,
            };

        case ActionTypes.ADDRESS_REMOVE_EXISTING_VALUES:
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false,
                [ActionTypes.GET_ADDRESSFORMAT]: null,
                [ActionTypes.GET_STATECITY]: null,
            };
            
        case ActionTypes.GET_STATECITY_LOADER: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
                stateCitySuccess: false,
            };
        }

        case ActionTypes.GET_ADDRESSFORMAT_LOADER: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
                addressFormatSuccess: false,
            };
        }

        case ActionTypes.GET_STATECITY: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }

        case ActionTypes.GET_ADDRESSFORMAT: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }

        case ActionTypes.GET_STATECITY_SUCCESS: {
            const { addressInformation } = state;
            const { GET_STATECITY: response} = action;
            const { city, state: stateValue, zip} = response;
            const { addressLine2 } = addressInformation;
            addressInformation.city = city;
            addressInformation.state = stateValue;
            addressInformation.zip = zip;
            const previousZip = zip;
            const callAddressAPI = addressLine2 && city && stateValue && zip;
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
                stateCitySuccess: true,
                addressInformation,
                callAddressAPI,
                previousZip,
            };
        }

        case ActionTypes.GET_ADDRESSFORMAT_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
                addressFormatSuccess: true,
            };
        }
        
        case ActionTypes.GET_FORMATTED_ADDRESS_SUCCESS: {
            const { addressInformation } = state;
            const { response: formattedAddr } = action;
            const {
                addressLine2:formattedAddrLine2='',
                city:formattedCity='',
                state:formattedState='',
                zip:formattedZip='',
                description:addressError ='',
            } = formattedAddr || {};
            const showUSPSDialog = !addressError && formattedAddrLine2 && formattedCity && formattedState && formattedZip;
            const USPSVerifiedAddress = {...formattedAddr};
            const previousZip = formattedZip;
            addressInformation.addressLine2ErrorText = '';
            addressInformation.zipError = '';
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
                addressFormatSuccess: true,
                showUSPSDialog,
                callAddressAPI: false,
                USPSVerifiedAddress,
                previousZip,
                addressInformation
            };
        }
        
        case ActionTypes.GET_STATECITY_ERROR: {
            const { GET_STATECITY: response} = action;
            const { description } = response;
            const { addressInformation } = state;
            addressInformation.zipError = description;
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
                stateCitySuccess: false,
                addressInformation,
                previousZip: addressInformation.zip
            };
        }
        
        case ActionTypes.GET_ADDRESSFORMAT_ERROR: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
                addressFormatSuccess: false,
            };
        }
        
        case ActionTypes.GET_FORMATTED_ADDRESS_ERROR: {
            const { response } = action;
            const { description ='' } = response || {};
            const { addressInformation } = state;
            addressInformation.addressLine2ErrorText = description;
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
                addressFormatSuccess: false,
                showUSPSDialog: false,
                addressInformation,
                previousZip: addressInformation.zip,
                callAddressAPI: false,
            };
        }
        
        case ActionTypes.SET_ADDRESS_DATA: {
            const { addressData = {} } = action;
            const { USPSVerifiedAddress } = state;
            const addressInformation = { addressLine1: '', addressLine2ErrorText: '', zipError: '', ...addressData};
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                showUSPSDialog: false,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_ADDRESS_LINE1: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.addressLine1 = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_ADDRESS_LINE2: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.addressLine2 = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_ZIPCODE: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.zip = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_CITY: {
            const { addressInformation } = state;
            addressInformation.city = action.data;
            return{
                ...state,
                addressInformation,
            };
        }
        
        case ActionTypes.SET_STATE: {
            const { addressInformation } = state;
            addressInformation.state = action.data;
            return{
                ...state,
                addressInformation,
            };
        }
        
        case ActionTypes.SET_ADDRESS_LINE2_ERROR: {
            const { addressInformation, USPSVerifiedAddress} = state;
            addressInformation.addressLine2ErrorText = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_ZIPCODE_ERROR: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.zipError = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case ActionTypes.SET_CALL_ADDRESS_API: {
            const { addressInformation } = state;
            const { addressLine1, addressLine2, city, state: stateValue, zip} = addressInformation;
            const callAddressAPI = addressLine2 && city && stateValue && zip;
            return{
                ...state,
                callAddressAPI,
                previousAddrLine1: addressLine1,
                previousAddrLine2: addressLine2,
            };
        }
        
        case ActionTypes.RESET_ADDRESS_INFO: {
            return{
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                showUSPSDialog: false,
                USPSVerifiedAddress: {},
                addressInformation: {
                    addressLine1: '',
                    addressLine2: '',
                    zip: '',
                    city: '',
                    state: '',
                    addressLine2ErrorText: '',
                    zipError: '',
                },
                callAddressAPI: false,
                previousZip: '',
                previousAddrLine1: '',
                previousAddrLine2: '',
                isAddressReady: false,
            };
        }
        
        default:
            return state;
    }
}

export default addressFormatReducer;
