import {set} from "lodash";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = { 
    deliveryPreferences: {
        personEmail: '--@--.com',
        personPhone: '000000000',
        callTimePreference: {
          timeZone: 'Central',
          time: ''
        },
        quiteTimePreference: {
            timeZone: 'Central',
            startTime: '', 
            endTime: ''
        },
        notificationPreference: {
          preferEmail: false,
          preferPushChannel: false,
          preferTextMessage: false
        },
        urgentMessagePreference: {
          preferEmail: false,
          preferPushChannel: false,
          preferTextMessage: false
        }
    },
    marketingAndOffersPreference: {
        preferMailingAddress: false,
        preferEmail: false,
        preferMobile: false,
        preferQuietTime: false
    }
};

function memberPreferencesReducer (state = initialState, action){
    switch(action.type){
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false };
        }
        case ActionTypes.RESET_API_ERROR_MEMBER_PREFERENCES:{
            return {
                ...state,
                isError: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_MEMBER_PREFERENCES_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        
        case ActionTypes.GET_MEMBER_PREFERENCES_LOADING:{
            return {
                ...state,
                isLoading:true,
                isError:false,
                isSuccess:false
            };
        }
        case ActionTypes.GET_MEMBER_PREFERENCES_FAILURE:{
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }

        case ActionTypes.GET_MEMBER_MSR_PREFERENCES_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        
        case ActionTypes.GET_MEMBER_MSR_PREFERENCES_LOADING:{
            return {
                ...state,
                isLoading:true,
                isError:false,
                isSuccess:false
            };
        }
        case ActionTypes.GET_MEMBER_MSR_PREFERENCES_FAILURE:{
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }


        case ActionTypes.CHANGE_MEMBER_PREFERENCES:{
            const newState = {...state};
            set(newState, action.payload.path, action.payload.data);
            return{
                ...newState
            };
        }
        case ActionTypes.UPDATE_MEMBER_PREFERENCES_LOADING:{
            return {
                ...state,
                isLoading:true,
                isError:false,
                isSuccess:false,
            };
        }
        case ActionTypes.UPDATE_MEMBER_PREFERENCES_SUCCESS:{
            return {
                ...state,
                isLoading:false,
                isError:false,
                isSuccess:true,
            };
        }
        case ActionTypes.UPDATE_MEMBER_PREFERENCES_FAILURE:{
            return {
                ...state,
                isLoading:false,
                isError:true,
                isSuccess:false,
            };
        }
        case ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_FAILURE: {
            return {
                ...state,
                ...initialState,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }

        default:
            return state;
      }
  } 

export default memberPreferencesReducer;