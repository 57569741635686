const data = {
    title: "Great, let's start with your email address.",
    member: 'It looks like you are already a VCM Member ',
    signIn: 'Sign In',
    use: 'Use ',
    forgotOnlineId: 'Forgot Online ID',
    or: '[or]',
    style: {
        para: {
            font: '700 14px/20px benton-sans',
            color: '#56565A',
            marginBottom: 15
        },
        paraMiddle: {
            font: '500 14px/20px benton-sans',
            color: '#56565A',
            marginBottom: 15
        },
        paraEnd: {
            font: '700 14px/20px benton-sans',
            color: '#56565A',
            marginBottom: 6
        },
        button: {
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            font: '600 14px/17px benton-sans',
            color: '#004A98'
        }
    }
}

export default data;