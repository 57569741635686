/**
 * Military Info Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Military information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { formatDate, isValidDate } from 'utils';
import Consts from '../PersonalInfo/consts';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import { WInput, RadioList, VCMDatePicker, VCMSelect } from '../../../common';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';

const assignObj = obj => {
    return obj;
};
const militaryInfo = 'Military Information';
const confirmtext = 'Are you currently serving, or have you ever served in the US Military?';
const selectText = 'Please select if you are serving or not in Military';
const dateOfService = 'Date of Service';
const subLabel = {
    font: '500 12px/20px benton-sans',
    color: '#56565A',
    opacity: 1,
    marginLeft: 10,
    position: 'relative',
    top: -1,
};
const optionalText = '[Optional]';

class MilitaryInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: {},
            currServingUSM: 'N',
            commissionCourse: '',
            milityStatus: '',
            branchOfService: '',
            rank: '',
            serviceFromDate: '',
            serviceToDate: '',
            militaryOthers: '',
            branchOfServiceOthers: '',
        };
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {};
    }

    componentDidMount() {
        const { masterLookupStateData, getCompositeLookUpData } = this.props;
        // const { accountType } = localStateData;
        // const accType = accountType;
        // if (accType && localStateData[accType.toString()] && localStateData[accType.toString()].milInfo) {
        //     // console.log("Master Data Already Loaded")
        //     // const savedPerInfo =localStateData[accountType.toString()].perInfo;
        //     // const primaryDateofBirth=savedPerInfo.dateofBirth
        //     // this.setState({primaryDateofBirth})
        // } else {
        const payload = [];
        const compositePayloadData = [
            'mil_serv_branch',
            'mil_status',
            'mil_rank_01',
            'mil_rank_02',
            'mil_rank_03',
            'mil_rank_04',
            'mil_rank_05',
        ];

        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
        getCompositeLookUpData(payload);
        // }
    }

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        if (eventKey === 'serviceFromDate') {
            const todayDate = new Date();
            todayDate.setHours(0);
            todayDate.setMinutes(0);
            todayDate.setSeconds(0);
            if (Date.parse(e.target.value) === Date.parse(todayDate)) {
                err = ' Please enter a valid date';
            } else {
                err = '';
            }
        }
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg });
    };

    handleChange = eventKey => e => {
        const { masterLookupStateData, getCompositeLookUpData } = this.props;
        if (eventKey === 'branchOfService' && e.target.value !== 'other') {
            const bosRank = `mil_rank_${e.target.value}`;
            const payload = [];
            if (!masterLookupStateData[bosRank.toString()]) {
                payload.push(bosRank);
            }
            getCompositeLookUpData(payload);
        }
        this.setState({
            [eventKey]: e.target.value,
        });
    };

    handleDateChange = eventkey => e => {
        const { errorMsg, serviceFromDate } = this.state;
        const { primaryDOB } = this.props;
        let err = '';
        // this.setState({ [eventkey]: e.target.value });
        if (
            eventkey === 'serviceFromDate' &&
            primaryDOB !== '' &&
            e.target.value !== 'Error' &&
            e.target.value !== '' &&
            e.target.value
        ) {
            const todayDate = new Date();
            todayDate.setHours(0);
            todayDate.setMinutes(0);
            todayDate.setSeconds(0);
            if (Date.parse(e.target.value) >= Date.parse(todayDate)) {
                err = ' Please enter a valid date';
            } else {
                const fromDateYear = new Date(e.target.value); // .getFullYear();
                const dateofBirthYear = new Date(primaryDOB); // .getFullYear();
                // To calculate the time difference of two dates
                const differenceInTime = fromDateYear.getTime() - dateofBirthYear.getTime();
                // To calculate the no. of days between two dates
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                // To calculate the no. of years between two dates
                const differenceInyears = differenceInDays / 365;
                const isValid = differenceInyears >= 17;
                if (!isValid) {
                    err = 'From date should be greater than or equal to 17 years from your Date of Birth';
                }
            }
            errorMsg[eventkey.toString()] = err;
        }
        if (eventkey === 'serviceToDate') {
            // const startDate = serviceFromDate; // document.getElementById('serviceFromDate').value;
            // const endDate = e.target.value; // document.getElementById('serviceToDate').value;
            const startDateFormatted = new Date(serviceFromDate);
            const endDateFormatted = new Date(e.target.value);
            // To calculate the time difference of two dates
            const differenceInTime = endDateFormatted.getTime() - startDateFormatted.getTime();
            // To calculate the no. of days between two dates
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            // To calculate the no. of years between two dates
            const differenceInyears = differenceInDays / 365;
            const isValid = differenceInyears >= 1;
            if (!isValid) {
                err = 'To date should be greater than From date';
            } else {
                err = '';
            }
            errorMsg[eventkey.toString()] = err;
        }
        this.setState({ errorMsg, [eventkey]: e.target.value });
    };

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    handleRadioBlur = eventKey => e => {
        e.persist();
        const { errorMsg, currServingUSM } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: currServingUSM,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState(() => {
            return {
                errorMsg,
            };
        });
    };

    getValue = data => {
        this.setState(data);
    };

    validateFields = () => {
        const { currServingUSM, errorMsg } = this.state;
        errorMsg.currServingUSM = (!currServingUSM).toString();
        const valid = errorMsg.currServingUSM !== 'true';
        this.setState({ errorMsg });
        return valid;
    };

    sendDataToParent = () => {
        const valid = this.validateFields();

        if (!valid) {
            this.accordionRef.current.click();
        }

        return { ...this.state, valid };
    };

    render() {
        const {
            currServingUSM,
            commissionCourse,
            milityStatus,
            branchOfService,
            rank,
            serviceFromDate,
            serviceToDate,
            errorMsg,
            militaryOthers,
            branchOfServiceOthers,
        } = this.state;
        const { masterLookupStateData, isJoint } = this.props;
        const bosRank = branchOfService !== 'other' ? `mil_rank_${branchOfService}` : '';
        const { mil_serv_branch: milBranchMaster, mil_status: militaryStatusMasterData } = masterLookupStateData || {};
        const { value: milBranchValue } = milBranchMaster || {};
        const { value: militaryStatusMasterDataValue } = militaryStatusMasterData || {};
        const { value: rankValue } = masterLookupStateData[bosRank.toString()] || {};
        const milServBranch = milBranchValue || [];
        const militaryStatusList = militaryStatusMasterDataValue || [];
        const rankOp = rankValue || [];
        const accTypeHeading = isJoint ? 'Joint Owner' : '';
        const startServiceDateMax = new Date();
        const serviceDateError = errorMsg.serviceToDate || errorMsg.serviceFromDate ? 'serviceDateError' : '';

        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            ref={this.accordionRef}
                            style={styles.accordionToggleStyle}
                        >
                            {accTypeHeading} {militaryInfo}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Row className="Slabel">
                                <span className="label" style={styles.labelTextStyle}>
                                    {confirmtext}
                                </span>
                                <RadioList
                                    items={Consts.sameAddress.value}
                                    field="currServingUSM"
                                    setValue={this.getValue}
                                    selectedValue={currServingUSM}
                                    errorText={selectText}
                                    handleBlur={this.handleRadioBlur('currServingUSM')}
                                    initialError={!!errorMsg.currServingUSM}
                                />
                            </Row>
                            {currServingUSM === 'Y' && (
                                <>
                                    {/* <Row>
                                        <SelectInput
                                            id="milityStatus"
                                            name="milityStatus"
                                            label="Military Status"
                                            className="milityStatus"
                                            itemlist={militaryStatusList}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            required
                                            value={milityStatus}
                                            errortext={errorMsg.milityStatus}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="true"
                                        />
                                    </Row> */}
                                    {/* <Row>
                                        <SelectInput
                                            id="branchOfService"
                                            name="branchOfService"
                                            label="Branch of Service"
                                            className="branchOfService"
                                            itemlist={milServBranch}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            required
                                            value={branchOfService}
                                            errortext={errorMsg.branchOfService}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="true"
                                        />
                                    </Row> */}
                                    {/* <Row>
                                        <SelectInput
                                            id="rank"
                                            name="rank"
                                            label="Rank"
                                            className="rank"
                                            itemlist={rankOp}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            required
                                            value={rank}
                                            errortext={errorMsg.rank}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="true"
                                        />
                                    </Row> */}
                                    <VCMSelect
                                        id="milityStatus"
                                        name="milityStatus"
                                        itemlist={militaryStatusList}
                                        label="Military Status"
                                        onChange={this.handleChange('milityStatus')}
                                        value={milityStatus}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.milityStatus}
                                        noGutters
                                        optional
                                    />
                                    {milityStatus === 'other' && (
                                        <Row>
                                            <WInput
                                                label=""
                                                placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                                                className=""
                                                id="militaryOthers"
                                                name="militaryOthers"
                                                type="text"
                                                onChange={this.handleChange('militaryOthers')}
                                                onBlur={this.handleBlur('militaryOthers')}
                                                required
                                                value={militaryOthers || ''}
                                                errortext={errorMsg.militaryOthers}
                                                labelsm={4}
                                                valuesm={5}
                                                optional="false"
                                            />
                                        </Row>
                                    )}
                                    <VCMSelect
                                        id="branchOfService"
                                        name="branchOfService"
                                        itemlist={milServBranch}
                                        label="Branch of Service"
                                        onChange={this.handleChange('branchOfService')}
                                        value={branchOfService}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.branchOfService}
                                        noGutters
                                        optional
                                    />
                                    {branchOfService === '06' && (
                                        <Row>
                                            <WInput
                                                label=""
                                                placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                                                className=""
                                                id="branchOfServiceOthers"
                                                name="branchOfServiceOthers"
                                                type="text"
                                                onChange={this.handleChange('branchOfServiceOthers')}
                                                onBlur={this.handleBlur('branchOfServiceOthers')}
                                                required
                                                value={branchOfServiceOthers || ''}
                                                errortext={errorMsg.branchOfServiceOthers}
                                                labelsm={4}
                                                valuesm={5}
                                                optional="false"
                                            />
                                        </Row>
                                    )}
                                    {branchOfService !== '06' && (
                                        <VCMSelect
                                            id="rank"
                                            name="rank"
                                            label="Rank"
                                            itemlist={rankOp}
                                            onChange={this.handleChange('rank')}
                                            value={rank}
                                            errortext={errorMsg.rank}
                                            labelsm={4}
                                            valuesm={5}
                                            noGutters
                                            optional
                                        />
                                    )}
                                    {branchOfService === 'other' && (
                                        <Row>
                                            <WInput
                                                label="Rank"
                                                placeholder="Rank"
                                                className=""
                                                id="rank"
                                                name="rank"
                                                type="text"
                                                onChange={this.handleChange('rank')}
                                                onBlur={this.handleBlur('rank')}
                                                required
                                                value={rank || ''}
                                                errortext={errorMsg.rank}
                                                labelsm={4}
                                                valuesm={5}
                                                optional="false"
                                            />
                                        </Row>
                                    )}
                                    <Row>
                                        <Form.Group
                                            as={Row}
                                            className="no-gutters"
                                            style={assignObj({
                                                width: '100%',
                                                alignItems: 'center',
                                                marginBottom: 25,
                                            })}
                                        >
                                            {/* <Form.Label className="col-sm-4" style={styles.labelTextStyle}>
                                                {dateOfService}
                                                <span style={subLabel}>{optionalText}</span>
                                            </Form.Label>  */}
                                            <span
                                                className="label"
                                                style={styles.labelTextStyle}
                                                id="serviceDatesLabel"
                                            >
                                                {dateOfService}
                                                <span style={subLabel}>{optionalText}</span>
                                            </span>
                                            <div className={assignObj(`serviseDates col-sm-7 ${serviceDateError}`)}>
                                                {/* <div style={assignObj({ display: 'flex', flexDirection: 'column' })}> */}
                                                {/* <Form.Control
                                                        type="date"
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur}
                                                        className="fromDate"
                                                        id="serviceFromDate"
                                                        name='serviceFromDate'
                                                        required={false}
                                                        aria-required={false}
                                                        value={serviceFromDate || ''}
                                                        isInvalid={!!errorMsg.serviceFromDate}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errorMsg.serviceFromDate}
                                                    </Form.Control.Feedback> */}

                                                {/* </div> */}
                                                {/* <div style={assignObj({ display: 'flex', flexDirection: 'column' })}> */}
                                                {/* <Form.Control
                                                        type="date"
                                                        className="toDate"
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur}
                                                        id="serviceToDate"
                                                        name='serviceToDate'
                                                        required={false}
                                                        aria-required={false}
                                                        value={serviceToDate || ''}
                                                        isInvalid={!!errorMsg.serviceToDate}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errorMsg.serviceToDate}
                                                    </Form.Control.Feedback> */}
                                                {/* </div> */}
                                                <VCMDatePicker
                                                    name="serviceFromDate"
                                                    id="serviceFromDate"
                                                    max={
                                                        isValidDate(startServiceDateMax)
                                                            ? formatDate(startServiceDateMax)
                                                            : ''
                                                    }
                                                    onChange={this.handleDateChange('serviceFromDate')}
                                                    onBlur={this.handleBlur('serviceFromDate')}
                                                    errortext={errorMsg.serviceFromDate}
                                                    value={serviceFromDate}
                                                    labelsm={2}
                                                    label="From"
                                                    valuesm={12}
                                                    noGutters
                                                    classNameVal="fromDate"
                                                    labelledby="serviceDatesLabel"
                                                />
                                                <VCMDatePicker
                                                    name="serviceToDate"
                                                    id="serviceToDate"
                                                    // max={
                                                    //     isValidDate(startServiceDateMax)
                                                    //         ? formatDate(startServiceDateMax)
                                                    //         : ''
                                                    // }
                                                    // max={new Date()}
                                                    onBlur={this.handleBlur('serviceToDate')}
                                                    onChange={this.handleDateChange('serviceToDate')}
                                                    errortext={errorMsg.serviceToDate}
                                                    value={serviceToDate}
                                                    labelsm={2}
                                                    label="To"
                                                    valuesm={12}
                                                    classNameVal="toDate"
                                                    noGutters
                                                    labelledby="serviceDatesLabel"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <WInput
                                            label="Commission Source"
                                            // placeholder="Commission Course"
                                            className=""
                                            id="commissionCourse"
                                            name="commissionCourse"
                                            type="text"
                                            subtype="characters"
                                            onChange={this.handleDateChange('commissionCourse')}
                                            onBlur={this.handleBlur('commissionCourse')}
                                            required={false}
                                            aria-required={false}
                                            value={commissionCourse || ''}
                                            errortext={errorMsg.commissionCourse}
                                            labelsm={4}
                                            valuesm={5}
                                            maxlength={30}
                                            optional="true"
                                        />
                                    </Row>
                                </>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}

MilitaryInfoCard.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    // localStateData: PropTypes.instanceOf(Object),
    primaryDOB: PropTypes.string,
};

MilitaryInfoCard.defaultProps = {
    getCompositeLookUpData: () => {},
    masterLookupStateData: {},
    // localStateData: {},
    savedState: {},
    isJoint: false,
    primaryDOB: '',
};

export default MilitaryInfoCard;
