import React from 'react';
const optionalText = ' [Optional]';
export const regulatoryOptional = <span className="font-weight-normal d-inline" style={{ color: '#56565a', fontSize: '12px' }}>&nbsp;{optionalText}</span> 
export const optional = optionalText;
export const perInfoHeading = 'personal information';
export const dateOfBirthLabel = 'Time Horizon';
export const riskLabel = 'Risk Exposure';
export const employeeBDLabel = 'Are you an employee of this broker-dealer?';
export const relatedToEmployeeLabel = 'Are you related to an employee of this broker-dealer?';
export const EmployeeAnotherBorker = 'Are you an employee of another broker-dealer?';
export const relatedToEmpAnother = 'Are you related to an employee of another broker-dealer?';
export const memRelToStock = 'Are you a member/employee or related to a member/employee of the stock exchange/FINRA?';
export const maintainOtherAccount = 'Are you maintaining other brokerage accounts?';
export const foreignAccount ='Is this account being opened for a foreign institution, a foreign bank, or a private banking account?';
export const isThisForeignInst = 'Is this a foreign financial institution?';
export const foreignbank = 'Is this a foreign bank?';
export const CentralBank = 'Is this a central bank account?';
export const Offshorebanking = 'Is this a foreign bank account operating under an offshore banking license?';
export const Noncooperativecountry = 'Is this a foreign bank account operating under a banking license issued by a non-cooperative country or territory?';
export const section311 = 'Is this a foreign bank account operating under a banking license issued by a jurisdiction subject to section 311 of the USA Patriot Act?';
export const PoliticallyExposedPerson = 'Are you a Politically Exposed Person or related to someone who is?';
export const memofdir ='Are you (or a member of your immediate family) a member of the Board of Directors, 10% shareholder or policy-making officer of a publicly traded company?';
export const anotherBrokerLabel ='Are you an associated person of Victory Capital Services or another broker-dealer, or are you an employee of a stock exchange or FINRA, or are you related to someone who is?';
// export const politicalCountryOfOffice = [countryLists.countryLists.value.map(o => o.country)];
export const EmpofAnotherDealer = "Are you an employee of another broker-dealer?"


export const radioOptions = ['Yes', 'No']
export const investmentObjectives = ['Safety of Principal', 'Conservative', 'Moderate', 'Aggressive'];
export const timeHorizon = ['1-3 years', '3-5 years', '5-7 years', '10+ years'];
export const generalInvestments = ['None', 'Some', 'Intermediate', 'Advanced'];
export const investementGoalList = [
  'Buying a home',
  'Having children',
  'Rainy day fund',
  'Raising your family',
  'Getting Married',
  'College education',
  'Career change',
  'Starting a business',
];
export const sourceOFFund = [
  'Accounts Receivable',
  'Accumulated Savings',
  'Alimony',
  'Gift',
  'Income From Earnings',
  'Inheritance',
  'Insurance Proceeds',
  'Investment Proceeds',
  'Legal Settlement',
  'Lottery/Gaming',
  'Pension/IRA/Retirement Savings',
  'Rollover',
  'Rental Income',
  'Sale of Business',
  'Sales of Real Estate',
  'Spouse/Parent',
  'Other'
];
export const riskvalue = ['None', 'Conservative', 'Conservative Growth', 'Moderate Growth', 'Growth', 'Aggressive Growth'];

export const riskValueOptions = [
  { value: 'None', key: '0', selected: true },
  { value: 'Conservative', key: '1' },
  { value: 'Conservative Growth', key: '2' },
  { value: 'Moderate Growth', key: '3' },
  { value: 'Growth', key: '4' },
  { value: 'Aggressive Growth', key: '5' },
]
export const brokerDealer = 'Broker Dealer & PEP';
export const investorProfile = 'Investor Profile & Risk';
export const countryLists = {
  key: 'countrylists',
  value: [
    {
      key: 'Aruba',
      value: 'ABW'
    },
    {
      key: 'Afghanistan',
      value: 'AFG'
    },
    {
      key: 'Angola',
      value: 'AGO'
    },
    {
      key: 'Anguilla',
      value: 'AIA'
    },
    {
      key: 'Åland Islands',
      value: 'ALA'
    },
    {
      key: 'Albania',
      value: 'ALB'
    },
    {
      key: 'Andorra',
      value: 'AND'
    },
    {
      key: 'United Arab Emirates (the)',
      value: 'ARE'
    },
    {
      key: 'Argentina',
      value: 'ARG'
    },
    {
      key: 'Armenia',
      value: 'ARM'
    },
    {
      key: 'American Samoa',
      value: 'ASM'
    },
    {
      key: 'Antarctica',
      value: 'ATA'
    },
    {
      key: 'French Southern Territories (the)',
      value: 'ATF'
    },
    {
      key: 'Antigua and Barbuda',
      value: 'ATG'
    },
    {
      key: 'Australia',
      value: 'AUS'
    },
    {
      key: 'Austria',
      value: 'AUT'
    },
    {
      key: 'Azerbaijan',
      value: 'AZE'
    },
    {
      key: 'Burundi',
      value: 'BDI'
    },
    {
      key: 'Belgium',
      value: 'BEL'
    },
    {
      key: 'Benin',
      value: 'BEN'
    },
    {
      key: 'Bonaire, Sint Eustatius and Saba',
      value: 'BES'
    },
    {
      key: 'Burkina Faso',
      value: 'BFA'
    },
    {
      key: 'Bangladesh',
      value: 'BGD'
    },
    {
      key: 'Bulgaria',
      value: 'BGR'
    },
    {
      key: 'Bahrain',
      value: 'BHR'
    },
    {
      key: 'Bahamas (the)',
      value: 'BHS'
    },
    {
      key: 'Bosnia and Herzegovina',
      value: 'BIH'
    },
    {
      key: 'Saint Barthélemy',
      value: 'BLM'
    },
    {
      key: 'Belarus',
      value: 'BLR'
    },
    {
      key: 'Belize',
      value: 'BLZ'
    },
    {
      key: 'Bermuda',
      value: 'BMU'
    },
    {
      key: 'Bolivia (Plurinational State of)',
      value: 'BOL'
    },
    {
      key: 'Brazil',
      value: 'BRA'
    },
    {
      key: 'Barbados',
      value: 'BRB'
    },
    {
      key: 'Brunei Darussalam',
      value: 'BRN'
    },
    {
      key: 'Bhutan',
      value: 'BTN'
    },
    {
      key: 'Bouvet Island',
      value: 'BVT'
    },
    {
      key: 'Botswana',
      value: 'BWA'
    },
    {
      key: 'Central African Republic (the)',
      value: 'CAF'
    },
    {
      key: 'Canada',
      value: 'CAN'
    },
    {
      key: 'Cocos (Keeling) Islands (the)',
      value: 'CCK'
    },
    {
      key: 'Switzerland',
      value: 'CHE'
    },
    {
      key: 'Chile',
      value: 'CHL'
    },
    {
      key: 'China',
      value: 'CHN'
    },
    {
      key: 'Côte d\'Ivoire',
      value: 'CIV'
    },
    {
      key: 'Cameroon',
      value: 'CMR'
    },
    {
      key: 'Congo (the Democratic Republic of the)',
      value: 'COD'
    },
    {
      key: 'Congo (the)',
      value: 'COG'
    },
    {
      key: 'Cook Islands (the)',
      value: 'COK'
    },
    {
      key: 'Colombia',
      value: 'COL'
    },
    {
      key: 'Comoros (the)',
      value: 'COM'
    },
    {
      key: 'Cabo Verde',
      value: 'CPV'
    },
    {
      key: 'Costa Rica',
      value: 'CRI'
    },
    {
      key: 'Cuba',
      value: 'CUB'
    },
    {
      key: 'Curaçao',
      value: 'CUW'
    },
    {
      key: 'Christmas Island',
      value: 'CXR'
    },
    {
      key: 'Cayman Islands (the)',
      value: 'CYM'
    },
    {
      key: 'Cyprus',
      value: 'CYP'
    },
    {
      key: 'Czechia',
      value: 'CZE'
    },
    {
      key: 'Germany',
      value: 'DEU'
    },
    {
      key: 'Djibouti',
      value: 'DJI'
    },
    {
      key: 'Dominica',
      value: 'DMA'
    },
    {
      key: 'Denmark',
      value: 'DNK'
    },
    {
      key: 'Dominican Republic (the)',
      value: 'DOM'
    },
    {
      key: 'Algeria',
      value: 'DZA'
    },
    {
      key: 'Ecuador',
      value: 'ECU'
    },
    {
      key: 'Egypt',
      value: 'EGY'
    },
    {
      key: 'Eritrea',
      value: 'ERI'
    },
    {
      key: 'Western Sahara',
      value: 'ESH'
    },
    {
      key: 'Spain',
      value: 'ESP'
    },
    {
      key: 'Estonia',
      value: 'EST'
    },
    {
      key: 'Ethiopia',
      value: 'ETH'
    },
    {
      key: 'Finland',
      value: 'FIN'
    },
    {
      key: 'Fiji',
      value: 'FJI'
    },
    {
      key: 'Falkland Islands (the) [Malvinas]',
      value: 'FLK'
    },
    {
      key: 'France',
      value: 'FRA'
    },
    {
      key: 'Faroe Islands (the)',
      value: 'FRO'
    },
    {
      key: 'Micronesia (Federated States of)',
      value: 'FSM'
    },
    {
      key: 'Gabon',
      value: 'GAB'
    },
    {
      key: 'United Kingdom of Great Britain and Northern Ireland (the)',
      value: 'GBR'
    },
    {
      key: 'Georgia',
      value: 'GEO'
    },
    {
      key: 'Guernsey',
      value: 'GGY'
    },
    {
      key: 'Ghana',
      value: 'GHA'
    },
    {
      key: 'Gibraltar',
      value: 'GIB'
    },
    {
      key: 'Guinea',
      value: 'GIN'
    },
    {
      key: 'Guadeloupe',
      value: 'GLP'
    },
    {
      key: 'Gambia (the)',
      value: 'GMB'
    },
    {
      key: 'Guinea-Bissau',
      value: 'GNB'
    },
    {
      key: 'Equatorial Guinea',
      value: 'GNQ'
    },
    {
      key: 'Greece',
      value: 'GRC'
    },
    {
      key: 'Grenada',
      value: 'GRD'
    },
    {
      key: 'Greenland',
      value: 'GRL'
    },
    {
      key: 'Guatemala',
      value: 'GTM'
    },
    {
      key: 'French Guiana',
      value: 'GUF'
    },
    {
      key: 'Guam',
      value: 'GUM'
    },
    {
      key: 'Guyana',
      value: 'GUY'
    },
    {
      key: 'Hong Kong',
      value: 'HKG'
    },
    {
      key: 'Heard Island and McDonald Islands',
      value: 'HMD'
    },
    {
      key: 'Honduras',
      value: 'HND'
    },
    {
      key: 'Croatia',
      value: 'HRV'
    },
    {
      key: 'Haiti',
      value: 'HTI'
    },
    {
      key: 'Hungary',
      value: 'HUN'
    },
    {
      key: 'Indonesia',
      value: 'IDN'
    },
    {
      key: 'Isle of Man',
      value: 'IMN'
    },
    {
      key: 'India',
      value: 'IND'
    },
    {
      key: 'British Indian Ocean Territory (the)',
      value: 'IOT'
    },
    {
      key: 'Ireland',
      value: 'IRL'
    },
    {
      key: 'Iran (Islamic Republic of)',
      value: 'IRN'
    },
    {
      key: 'Iraq',
      value: 'IRQ'
    },
    {
      key: 'Iceland',
      value: 'ISL'
    },
    {
      key: 'Israel',
      value: 'ISR'
    },
    {
      key: 'Italy',
      value: 'ITA'
    },
    {
      key: 'Jamaica',
      value: 'JAM'
    },
    {
      key: 'Jersey',
      value: 'JEY'
    },
    {
      key: 'Jordan',
      value: 'JOR'
    },
    {
      key: 'Japan',
      value: 'JPN'
    },
    {
      key: 'Kazakhstan',
      value: 'KAZ'
    },
    {
      key: 'Kenya',
      value: 'KEN'
    },
    {
      key: 'Kyrgyzstan',
      value: 'KGZ'
    },
    {
      key: 'Cambodia',
      value: 'KHM'
    },
    {
      key: 'Kiribati',
      value: 'KIR'
    },
    {
      key: 'Saint Kitts and Nevis',
      value: 'KNA'
    },
    {
      key: 'Korea (the Republic of)',
      value: 'KOR'
    },
    {
      key: 'Kuwait',
      value: 'KWT'
    },
    {
      key: 'Lao People\'s Democratic Republic (the)',
      value: 'LAO'
    },
    {
      key: 'Lebanon',
      value: 'LBN'
    },
    {
      key: 'Liberia',
      value: 'LBR'
    },
    {
      key: 'Libya',
      value: 'LBY'
    },
    {
      key: 'Saint Lucia',
      value: 'LCA'
    },
    {
      key: 'Liechtenstein',
      value: 'LIE'
    },
    {
      key: 'Sri Lanka',
      value: 'LKA'
    },
    {
      key: 'Lesotho',
      value: 'LSO'
    },
    {
      key: 'Lithuania',
      value: 'LTU'
    },
    {
      key: 'Luxembourg',
      value: 'LUX'
    },
    {
      key: 'Latvia',
      value: 'LVA'
    },
    {
      key: 'Macao',
      value: 'MAC'
    },
    {
      key: 'Saint Martin (French part)',
      value: 'MAF'
    },
    {
      key: 'Morocco',
      value: 'MAR'
    },
    {
      key: 'Monaco',
      value: 'MCO'
    },
    {
      key: 'Moldova (the Republic of)',
      value: 'MDA'
    },
    {
      key: 'Madagascar',
      value: 'MDG'
    },
    {
      key: 'Maldives',
      value: 'MDV'
    },
    {
      key: 'Mexico',
      value: 'MEX'
    },
    {
      key: 'Marshall Islands (the)',
      value: 'MHL'
    },
    {
      key: 'Republic of North Macedonia',
      value: 'MKD'
    },
    {
      key: 'Mali',
      value: 'MLI'
    },
    {
      key: 'Malta',
      value: 'MLT'
    },
    {
      key: 'Myanmar',
      value: 'MMR'
    },
    {
      key: 'Montenegro',
      value: 'MNE'
    },
    {
      key: 'Mongolia',
      value: 'MNG'
    },
    {
      key: 'Northern Mariana Islands (the)',
      value: 'MNP'
    },
    {
      key: 'Mozambique',
      value: 'MOZ'
    },
    {
      key: 'Mauritania',
      value: 'MRT'
    },
    {
      key: 'Montserrat',
      value: 'MSR'
    },
    {
      key: 'Martinique',
      value: 'MTQ'
    },
    {
      key: 'Mauritius',
      value: 'MUS'
    },
    {
      key: 'Malawi',
      value: 'MWI'
    },
    {
      key: 'Malaysia',
      value: 'MYS'
    },
    {
      key: 'Mayotte',
      value: 'MYT'
    },
    {
      key: 'Namibia',
      value: 'NAM'
    },
    {
      key: 'New Caledonia',
      value: 'NCL'
    },
    {
      key: 'Niger (the)',
      value: 'NER'
    },
    {
      key: 'Norfolk Island',
      value: 'NFK'
    },
    {
      key: 'Nigeria',
      value: 'NGA'
    },
    {
      key: 'Nicaragua',
      value: 'NIC'
    },
    {
      key: 'Niue',
      value: 'NIU'
    },
    {
      key: 'Netherlands (the)',
      value: 'NLD'
    },
    {
      key: 'Norway',
      value: 'NOR'
    },
    {
      key: 'Nepal',
      value: 'NPL'
    },
    {
      key: 'Nauru',
      value: 'NRU'
    },
    {
      key: 'New Zealand',
      value: 'NZL'
    },
    {
      key: 'Oman',
      value: 'OMN'
    },
    {
      key: 'Pakistan',
      value: 'PAK'
    },
    {
      key: 'Panama',
      value: 'PAN'
    },
    {
      key: 'Pitcairn',
      value: 'PCN'
    },
    {
      key: 'Peru',
      value: 'PER'
    },
    {
      key: 'Philippines (the)',
      value: 'PHL'
    },
    {
      key: 'Palau',
      value: 'PLW'
    },
    {
      key: 'Papua New Guinea',
      value: 'PNG'
    },
    {
      key: 'Poland',
      value: 'POL'
    },
    {
      key: 'Puerto Rico',
      value: 'PRI'
    },
    {
      key: 'Korea (the Democratic People\'s Republic of)',
      value: 'PRK'
    },
    {
      key: 'Portugal',
      value: 'PRT'
    },
    {
      key: 'Paraguay',
      value: 'PRY'
    },
    {
      key: 'Palestine, State of',
      value: 'PSE'
    },
    {
      key: 'French Polynesia',
      value: 'PYF'
    },
    {
      key: 'Qatar',
      value: 'QAT'
    },
    {
      key: 'Réunion',
      value: 'REU'
    },
    {
      key: 'Romania',
      value: 'ROU'
    },
    {
      key: 'Russian Federation (the)',
      value: 'RUS'
    },
    {
      key: 'Rwanda',
      value: 'RWA'
    },
    {
      key: 'Saudi Arabia',
      value: 'SAU'
    },
    {
      key: 'Sudan (the)',
      value: 'SDN'
    },
    {
      key: 'Senegal',
      value: 'SEN'
    },
    {
      key: 'Singapore',
      value: 'SGP'
    },
    {
      key: 'South Georgia and the South Sandwich Islands',
      value: 'SGS'
    },
    {
      key: 'Saint Helena, Ascension and Tristan da Cunha',
      value: 'SHN'
    },
    {
      key: 'Svalbard and Jan Mayen',
      value: 'SJM'
    },
    {
      key: 'Solomon Islands',
      value: 'SLB'
    },
    {
      key: 'Sierra Leone',
      value: 'SLE'
    },
    {
      key: 'El Salvador',
      value: 'SLV'
    },
    {
      key: 'San Marino',
      value: 'SMR'
    },
    {
      key: 'Somalia',
      value: 'SOM'
    },
    {
      key: 'Saint Pierre and Miquelon',
      value: 'SPM'
    },
    {
      key: 'Serbia',
      value: 'SRB'
    },
    {
      key: 'South Sudan',
      value: 'SSD'
    },
    {
      key: 'Sao Tome and Principe',
      value: 'STP'
    },
    {
      key: 'Suriname',
      value: 'SUR'
    },
    {
      key: 'Slovakia',
      value: 'SVK'
    },
    {
      key: 'Slovenia',
      value: 'SVN'
    },
    {
      key: 'Sweden',
      value: 'SWE'
    },
    {
      key: 'Eswatini',
      value: 'SWZ'
    },
    {
      key: 'Sint Maarten (Dutch part)',
      value: 'SXM'
    },
    {
      key: 'Seychelles',
      value: 'SYC'
    },
    {
      key: 'Syrian Arab Republic',
      value: 'SYR'
    },
    {
      key: 'Turks and Caicos Islands (the)',
      value: 'TCA'
    },
    {
      key: 'Chad',
      value: 'TCD'
    },
    {
      key: 'Togo',
      value: 'TGO'
    },
    {
      key: 'Thailand',
      value: 'THA'
    },
    {
      key: 'Tajikistan',
      value: 'TJK'
    },
    {
      key: 'Tokelau',
      value: 'TKL'
    },
    {
      key: 'Turkmenistan',
      value: 'TKM'
    },
    {
      key: 'Timor-Leste',
      value: 'TLS'
    },
    {
      key: 'Tonga',
      value: 'TON'
    },
    {
      key: 'Trinidad and Tobago',
      value: 'TTO'
    },
    {
      key: 'Tunisia',
      value: 'TUN'
    },
    {
      key: 'Turkey',
      value: 'TUR'
    },
    {
      key: 'Tuvalu',
      value: 'TUV'
    },
    {
      key: 'Taiwan (Province of China)',
      value: 'TWN'
    },
    {
      key: 'Tanzania, United Republic of',
      value: 'TZA'
    },
    {
      key: 'Uganda',
      value: 'UGA'
    },
    {
      key: 'Ukraine',
      value: 'UKR'
    },
    {
      key: 'United States Minor Outlying Islands (the)',
      value: 'UMI'
    },
    {
      key: 'Uruguay',
      value: 'URY'
    },
    {
      key: 'United States of America (the)',
      value: 'USA'
    },
    {
      key: 'Uzbekistan',
      value: 'UZB'
    },
    {
      key: 'Holy See (the)',
      value: 'VAT'
    },
    {
      key: 'Saint Vincent and the Grenadines',
      value: 'VCT'
    },
    {
      key: 'Venezuela (Bolivarian Republic of)',
      value: 'VEN'
    },
    {
      key: 'Virgin Islands (British)',
      value: 'VGB'
    },
    {
      key: 'Virgin Islands (U.S.)',
      value: 'VIR'
    },
    {
      key: 'Viet Nam',
      value: 'VNM'
    },
    {
      key: 'Vanuatu',
      value: 'VUT'
    },
    {
      key: 'Wallis and Futuna',
      value: 'WLF'
    },
    {
      key: 'Samoa',
      value: 'WSM'
    },
    {
      key: 'Yemen',
      value: 'YEM'
    },
    {
      key: 'South Africa',
      value: 'ZAF'
    },
    {
      key: 'Zambia',
      value: 'ZMB'
    },
    {
      key: 'Zimbabwe',
      value: 'ZWE'
    }
  ]
};



export const mandatoryFieldlabel = {
  font: '100 16px/22px benton-sans',
  color: 'rgb(73, 73, 74)',
  marginTop: 10,
  float: 'right',
};

export const mandatoryText = '* All fields are required except mentioned optional';


export const VcmAccountInfo = 'VCM Investment Account Information'
export const personalinfo = 'Personal Information-Primary'
export const contactinfo = 'Contact Information'
export const financialIncome = "$3000001 and above"
export const financialNetWorth = "$3000000 and above"

export const annualIncomeErrorMsg= 'Please select annual income.';
export const netWorthErrorMsg = 'Please select net worth.';
export const taxBracketErrorMsg='Please select tax bracket.';
export const taxFilingErrorMsg='Please Select Tax Filing Status.';
//helper text for Regulatory
const investment_Text1 = ' – preserve my initial investment with minimum risk, even if this means my investments may not generate returns that keep pace with inflation or other types of investments.'
const investment_Text2 = ' – dividend and interest income that is achieved through investments which have lower to moderate volatility.'
const investment_Text3 = ' – moderate appreciation over time within a balanced portfolio that also generates income.'
const investment_Text4 = ' – aggressive appreciation through higher-risk investments that have historically generated greater returns.'
export const investment_Objective = <><b>Investment Objective Definitions:</b>{'\n'}<span></span><b>{'\n'}Safety of Principal</b><span>{investment_Text1}</span>{'\n'}<b>{'\n'}Income</b><span>{investment_Text2}</span>{'\n'}<b>{'\n'}Growth and Income</b><span>{investment_Text3}</span>{'\n'}<b>{'\n'}Growth</b><span>{investment_Text4}</span></>
export const HelpText_RegulatoryHeading = "Why do we ask for this information?\nFINRA Rule 2090 states that every member shall use reasonable diligence, in regard to the opening and maintenance of every account, to know (and retain) the essential facts concerning every customer and concerning the authority of each person acting on behalf of such customer.\n\nFor purposes of this Rule, facts \"essential\" to \"knowing the customer\" are those required to (a) effectively service the customer's account, (b) act in accordance with any special handling instructions for the account, (c) understand the authority of each person acting on behalf of the customer, and (d) comply with applicable laws, regulations, and rules.\n\n Need help? 1-800-235-8396"
export const HelpText_InvestorProfileRisk = "What does this mean?\nRisk exposure is the degree of variability in investment returns that an investor is willing to withstand. A conservative investor carefully or rarely takes risk and seeks to preserve their principal. An aggressive investor is willing to accept maximum risk exposure through higher-risk investments that have historically generated greater returns."
export const HelpText_RelatedBrokerDealer = "What does \"related\" mean?\nA related person is an immediate family member or a person in your household."
export const HelptText_BrokerDealerPolitical = "What does \"politically exposed\" mean?\nPlease tell us if you or any person of interest in the account is either:\n(1) A senior military, governmental or political official (foreign or domestic) or \n(2) Closely associated with or an immediate family member of such an official."
export const HelptText_AnotherBrokerDealer = "Why do we ask about broker-dealer and other industry affiliations?\nIf you are affiliated with a member Firm or Exchange or you are employed by FINRA, you are required, pursuant to FINRA Rule 3210, to receive written approval from your employer prior to opening and transacting or otherwise establishing this account with us. If you have answered \"yes\" to these questions and open the account, you attest that you have received such approval from your employer."
export const HelptText_BrokerDealerCompany = "Why do we ask this question?\nCurrent securities laws require a public company's officers, directors and any holders of more than 10% of a class of the public company's equity securities to make certain filings with and report transactions in the public company's equity securities to the U.S. Securities and Exchange Commission. \n\n What does \"immediate family\" mean? \nImmediate family includes one's spouse, parents, brothers, sisters, children, father-in-law, mother-in-law, sister-in-law, brother-in-law, and relatives supported financially."
export const vcEmployeeLabel="Are you an employee of Victory Capital Management, Inc. or any of its affiliates?";
export const vcEmployeeText="vcEmployee"
export const MARKET_NAO_ACCOUNT_TYPES = {
  INDIVIDUAL: "Individual",
  ROLLOVER_IRA: "Rollover IRA",
  JOINT_ACCOUNT : "JTWROS",
  JOINT_ACCOUNT_FULL_FORM : "Joint Tenants with Rights of Survivorship"
}

export const addBankProcedureSteps = {
  paraHeading: 'To add a bank account and fund your Marketplace account, please follow the steps below:',
  para1: 'Log in to your account and click on the "Marketplace" tab on your Home page',
  para2: 'Click “Transfer Funds” next to the Marketplace account you want to fund',
  para3: 'Follow the process in your Marketplace terminal to add your bank account details. This will require personal information, linking an existing bank account and specifying funding amount and frequency. Expect a day to complete the approval process.',
}
export const bulkApiSuccessCode = 7000;
export const confirmFundingTypeHeading = 'Funding Type';
export const finalConfirmationMessage = {
  part1: 'We have received your application to open',
  part2: 'and are actively processing the application. You will receive a notification confirming your application status shortly.'
}

export const primaryAppSubmissionSuccessful = 'Thanks for submitting your application. An email will be sent to the joint owner to complete the new account opening process. Once successfully completed, your account will be opened. To speak to a live U.S.-based investment specialist, please call 1-800-235-8396.';
export const rolloverConfirmationMsgInstructions = {
  line1: 'To complete the direct rollover, contact your previous employer’s benefits office or plan administrator to get distribution instructions and form(s). Ask your previous employer if it is their policy to send a check to you or directly to the new custodian. Also determine if an account number or signature from Victory Capital Services, inc. is required.',
  line2: 'If you are transferring from another rollover IRA, select “Transfer Account” on your dashboard or contact U.S.-based investment specialist at 1-800-235-8396.',
}