const generalUrls = [
    { url: '/', name: 'Dashboard' },
    { url: '/', name: 'Account Services' },
    { url: '/manageBeneficiaries', name: 'Manage Beneficiaries' },
];
const activeUrl = {
    primary: 'Add Primary Beneficiary',
    contingent: 'Add Contingent Beneficiary',
};

export default {
    generalUrls,
    activeUrl,
    registrationOwner: 'Registration Owner',
    balance: 'Balance',
};
