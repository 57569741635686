import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
      //   if (document.getElementById('hiddenBtn')){
      //   document.getElementById('hiddenBtn').focus(); //For Accessibility Purpose
      // }
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);

ScrollToTop.propTypes = {
  location: PropTypes.any
}

ScrollToTop.defaultProps = {
  location: {}
}