import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import {
    updateProfileRelationship,
    clearRelationActions,
    setInitialRelationState,
    setFamilyRelationError,
    setFirstNameError,
    setLastNameError,
    setDateOfBirthError,
    setGenderError,
    setMaritalStatusError,
    setCitizenShipError,
    setCitizenShipCountryError,
    setSSNError,
} from 'shared/Actions/ProfileRelationAction';
import { resetAddressInfo } from 'shared/Actions/AddressAction';
import { analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import RelationshipInfoComponent from './RelatioshipAddComponent';

export const mapStateToProps = state => {
  const { masterLookUpData, profileRelationshipData, mailingAddress, physicalAddress } = state;
  const { isError } = profileRelationshipData;
    return {
        masterLookupStateData: masterLookUpData,
        profileRelationshipData,
        mailingAddressData: mailingAddress,
        physicalAddressData: physicalAddress,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    updateProfileRelationship,
    clearRelationActions,
    resetAddressInfo,
    setInitialRelationState,
    setFamilyRelationError,
    setFirstNameError,
    setLastNameError,
    setDateOfBirthError,
    setGenderError,
    setMaritalStatusError,
    setCitizenShipError,
    setCitizenShipCountryError,    
    setSSNError,
    analyzeClickEvent, 
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipInfoComponent));
