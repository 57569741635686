import { connect } from 'react-redux';
import OopsPage from './OopsPageComponent';
import { accOpeningActions } from '../../../shared/Actions';

export const mapStateToProps = (state /* , props */) => ({
    masterLookupStateData: state.masterLookUpData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OopsPage);
