import React from 'react';
import PropTypes from 'prop-types';
import WInput from '../../../common/WInput/WInput';
import * as Styles from './Styles';
import * as Constants from './Constants';

const MessageBody = React.forwardRef((props, ref) => {
    const { messageBody, setMessageBodyValue, errorText, isItReplyMessage } = props;
    const inlineStyles = {
        messageBody: { marginLeft: 5, height: 300 },
    };

    return (
        <Styles.FormRow>
            <WInput
                ref={ref}
                label={Constants.FORMS.MESSAGE_BODY}
                id="message-body-text-box"
                arialabel={Constants.FORMS.MESSAGE_BODY}
                arialabelledby={Constants.FORMS.MESSAGE_BODY}
                valuesm={10}
                type="area"
                labelsm={2}
                inputfieldstyle={inlineStyles.messageBody}
                value={messageBody.value}
                onChange={setMessageBodyValue}
                maxlength={!isItReplyMessage && 500}
                minLength={5}
                errortext={errorText}
                as="textarea"
            />
        </Styles.FormRow>
    );
});

MessageBody.propTypes = {
    messageBody: PropTypes.instanceOf(Object),
    setMessageBodyValue: PropTypes.func,
    errorText: PropTypes.string,
    isItReplyMessage: PropTypes.bool,
};

MessageBody.defaultProps = {
    messageBody: {},
    setMessageBodyValue: () => {},
    errorText: '',
    isItReplyMessage: false,
};
export default React.memo(MessageBody);
