import { accOpeningActions, addressFormatActions, localStateManagementActions,transactionCompositeDataAction } from '../../../shared/Actions';
import MailingAddress from './MailingAddress';
import { connect } from 'react-redux';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    addressFormatData: state.addressFormatData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...addressFormatActions,
    ...localStateManagementActions,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MailingAddress);
