// DocumentCenterList.js
import * as DocumentCenterAction from '../../../shared/Actions/DocumentCenterActions';
import { connect } from 'react-redux';
import DocumentCenter from './index';
import {memberDashboardActions, loginActions} from '../../../shared/Actions';

const mapStateToProps = state => ({
    downloadedFileData: state.downloadedFileData,
    documentPreferenceData: state.documentPreferenceData,
    profileInformationData: state.profileInformationData,
    localStateManagementReducerData: state.localStateManagementReducerData,
    loginData: state.loginData,
    memberDashboardData: state.memberDashboardData,
});

const mapDispatchToProps = {
    ...DocumentCenterAction,
    ...memberDashboardActions,
    ...loginActions
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCenter);
