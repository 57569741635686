import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { WSpinner, WireTransferCard, PersonalCheckCard, WButton } from '../../../../common';
import BeneficiaryCardComponent from './BeneficiaryCardComponent';
import * as TransactionConstants from '../../TransactionConstants';

const HeaderRowFundingTypeStyle = styled(Row)`
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 40px;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ErrorP = styled.p`
    display: flex;
    justify-content: space-between;
    color: #e60000;
    margin-top: 20px;
`;

const PageSubHeadingH2 = styled.h2`
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
    margin-bottom: 15px;
`;

const PageHeading = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal,sans-serif
    letter-spacing: 0;
    color: #486D90;
    border-bottom: 2px solid #8BC105;
    padding-bottom: 10px;
    float: left;
    font-weight: 800;
    font-size: 30px;
`;

const ConfirmationMessageP = styled.p`
    margin-top: 10px;
`;
const {
    CONFIRMATION_MESSAGE_PART1,
    CONFIRMATION_MESSAGE_PART3,
    CONFIRMATION_MESSAGE_WIRE_TRANSFER,
    CONFIRMATION_MESSAGE_CHECK,
    CONFIRMATION_PAGE,
    FUNDING_TYPE,
    WIRE_TRANSFER_TEXT,
    PERSONAL_CHECK_TEXT,
    END_OF_SENTENCE_PERIOD,
    TRANSACTION_STANDARD_ERROR_MESSAGE,
    WIRE_TRANSFER_MAILING_ADDRESS_TEXT,
    ERROR_PAGE,
    PROPORTIANTE_TRANSACTION_ERROR_MESSAGE,
} = TransactionConstants;

function ConfirmationComponent(props) {
    const {
        isLoading,
        isError,
        fundAccountNumber,
        paymentType,
        selectedFund,
        mailingAddress,
        loginData,
        isPurchases,
        isExchange,
        isIRA,
        masterRegId,
        isProportinateSelected,
        proportianteSelectedFundsList,
        proportinateFundPassedList,
        displayFailedPropotinateFundListArray=[],
        proportionateSubmitAPICount,
        handleResubmit,
        setProportionateWhenOneFundFail,
        isProportionateResubmitFlow,
        flowName="",
    } = props;

    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                left: 0,
                behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
            },
            [],
        );
        const { setTradingWarning } = props;
        setTradingWarning();
    }, []);

    const [renderCount,setRenderCount] = useState(0);

    useEffect(()=>{
        const {resetGoToNextPageVariable, setProportionateWhenOneFundFail} = props;
        if(isProportinateSelected && proportianteSelectedFundsList?.length == proportionateSubmitAPICount && renderCount == 0 && displayFailedPropotinateFundListArray?.length > 1)
        {
            setRenderCount(1)
            resetGoToNextPageVariable();
            setProportionateWhenOneFundFail(displayFailedPropotinateFundListArray);
        }
        else if(isProportinateSelected && proportianteSelectedFundsList?.length == proportionateSubmitAPICount && renderCount == 0 && displayFailedPropotinateFundListArray?.length  == 1)
        {
            resetGoToNextPageVariable();
            setRenderCount(1)
            if(flowName == 'sell')
            {
                let faildFundListData = displayFailedPropotinateFundListArray;
                faildFundListData[0]['userinputamt']= faildFundListData[0].sellingamt;
                faildFundListData[0]['amtType']= 'dollaramt';
                setProportionateWhenOneFundFail(faildFundListData);
            }
            else
            {
                let faildFundListData = displayFailedPropotinateFundListArray;
                faildFundListData[0]['initialInvest_touched']= true;
                faildFundListData[0]['monthlyInvest_touched']= true;
                faildFundListData[0]['fundOptionSelected'] = "sub";
                setProportionateWhenOneFundFail(faildFundListData);
            }
        }else{
            console.log('negetive condition for test cases')
        }
    },[proportionateSubmitAPICount,displayFailedPropotinateFundListArray]);

    return (
        <div data-test="confirmationDiv">
            {(isLoading && isProportinateSelected == false ) && <WSpinner loading={isLoading} data-test="confirmationSpinner" />}
            {(isProportinateSelected && proportianteSelectedFundsList?.length != proportionateSubmitAPICount) && <WSpinner loading={proportianteSelectedFundsList?.length != proportionateSubmitAPICount} data-test="confirmationSpinner" />}
            <Container data-test="confirmationContainer">
                {isProportinateSelected ? (
                    <>  
                        {(displayFailedPropotinateFundListArray?.length == 0 && proportianteSelectedFundsList?.length == proportionateSubmitAPICount) ?
                         ( <>
                            <FlexDiv>
                                <PageHeading data-test="confirmationHeading">{CONFIRMATION_PAGE}</PageHeading>
                            </FlexDiv>

                            <Row>
                                <Col>
                                    <ConfirmationMessageP data-testid="confirmationMessage">
                                        {!isPurchases && CONFIRMATION_MESSAGE_PART1}
                                        {isPurchases &&
                                            !paymentType.wireTransfer &&
                                            !paymentType.check &&
                                            CONFIRMATION_MESSAGE_PART1}
                                        {isPurchases && paymentType.wireTransfer && CONFIRMATION_MESSAGE_WIRE_TRANSFER}
                                        {isPurchases && paymentType.check && CONFIRMATION_MESSAGE_CHECK}
                                        {isPurchases && (paymentType.check || paymentType.wireTransfer) && (
                                            <>
                                                {CONFIRMATION_MESSAGE_PART3}
                                                <strong>
                                                    {selectedFund.fundAccountNumber
                                                        ? selectedFund.fundAccountNumber
                                                        : fundAccountNumber}
                                                </strong>
                                                {END_OF_SENTENCE_PERIOD}
                                            </>
                                        )}
                                    </ConfirmationMessageP>
                                </Col>
                            </Row>

                            {isPurchases && isIRA && !selectedFund.fundAccountNumber && (
                                <Row>
                                    <BeneficiaryCardComponent
                                        masterRegId={masterRegId}
                                        fundId={selectedFund.fundNumber}
                                        fundAccountNumber={fundAccountNumber}
                                        isPurchases
                                    />
                                </Row>
                            )}
                            {isExchange && isIRA && !selectedFund.fundAccountNumber && (
                                <Row>
                                    <BeneficiaryCardComponent
                                        masterRegId={masterRegId}
                                        fundId={selectedFund.fundNumber}
                                        fundAccountNumber={fundAccountNumber}
                                        isExchange
                                    />
                                </Row>
                            )}
                            {(paymentType.check || paymentType.wireTransfer) && (
                                <HeaderRowFundingTypeStyle>
                                    <Col xs={12}>
                                        <PageSubHeadingH2 data-test="fundingTypeHeading">{FUNDING_TYPE}</PageSubHeadingH2>
                                    </Col>
                                </HeaderRowFundingTypeStyle>
                            )}
                            <Row>
                                {paymentType.wireTransfer && (
                                    <WireTransferCard
                                        heading={WIRE_TRANSFER_TEXT}
                                        fundAccountNumber={
                                            selectedFund.fundAccountNumber
                                                ? selectedFund.fundAccountNumber
                                                : fundAccountNumber
                                        }
                                        fundName={selectedFund.fundName}
                                        firstName={loginData.getCustomerProfile && loginData.getCustomerProfile.firstName}
                                        lastName={loginData.getCustomerProfile && loginData.getCustomerProfile.lastName}
                                        data-testid="wireTransferCard"
                                    />
                                )}
                                {paymentType.check && (
                                    <PersonalCheckCard
                                        heading={PERSONAL_CHECK_TEXT}
                                        mailingAddress={mailingAddress}
                                        data-testid="personalCheckCard"
                                    />
                                )}
                            </Row>
                         </>) 
                         : 
                         (<>
                            {(proportianteSelectedFundsList?.length == proportionateSubmitAPICount && (isError || displayFailedPropotinateFundListArray?.length > 0)) && 
                            <> 
                            <FlexDiv>
                                <PageHeading data-test="errorHeading">{ERROR_PAGE}</PageHeading>
                            </FlexDiv>
                            <Row>
                                <Col>
                                    <ErrorP data-test="proportionateerrorMessage">{PROPORTIANTE_TRANSACTION_ERROR_MESSAGE}</ErrorP>
                                </Col>
                                
                            </Row>
                            <Row>
                              <Col>
                                  <table className='table'>
                                      <thead>
                                          <tr>
                                              <th>Fund Name</th>
                                              <th>Account Number</th>
                                              <th>Dollar Amount</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {displayFailedPropotinateFundListArray.map((item)=>(
                                          <tr>
                                              <td>{item.fundName}</td>
                                              <td>{item.fundAccountNumber}</td>
                                              <td>{(flowName == "sell") ? item.sellingamt : item.initialInvest}</td>
                                          </tr>
                                      ))}
                                      </tbody>
                                  </table>
                              </Col>
                              </Row>

                              <Row>
                                <Col>
                                <WButton
                                buttontext='Resubmit'
                                data-test="submit-button"
                                role="link"
                                onClick={handleResubmit}
                            />
                                </Col>
                            </Row>
                            </>
                            }
                         </>) 
                        }
                    </>
                ) : (
                    <>
                         {!isError && (
                    <>
                        <FlexDiv>
                            <PageHeading data-test="confirmationHeading">{CONFIRMATION_PAGE}</PageHeading>
                        </FlexDiv>

                        <Row>
                            <Col>
                                <ConfirmationMessageP data-test="confirmationMessage">
                                    {!isPurchases && CONFIRMATION_MESSAGE_PART1}
                                    {isPurchases &&
                                        !paymentType.wireTransfer &&
                                        !paymentType.check &&
                                        CONFIRMATION_MESSAGE_PART1}
                                    {isPurchases && paymentType.wireTransfer && CONFIRMATION_MESSAGE_WIRE_TRANSFER}
                                    {isPurchases && paymentType.check && CONFIRMATION_MESSAGE_CHECK}
                                    {isPurchases && (paymentType.check || paymentType.wireTransfer) && (
                                        <>
                                            {CONFIRMATION_MESSAGE_PART3}
                                            <strong>
                                                {selectedFund.fundAccountNumber
                                                    ? selectedFund.fundAccountNumber
                                                    : fundAccountNumber}
                                            </strong>
                                            {END_OF_SENTENCE_PERIOD}
                                        </>
                                    )}
                                </ConfirmationMessageP>
                            </Col>
                        </Row>
                        {isPurchases && isIRA && !selectedFund.fundAccountNumber && (
                            <Row>
                                <BeneficiaryCardComponent
                                    masterRegId={masterRegId}
                                    fundId={selectedFund.fundNumber}
                                    fundAccountNumber={fundAccountNumber}
                                    isPurchases
                                />
                            </Row>
                        )}
                        {isExchange && isIRA && !selectedFund.fundAccountNumber && (
                            <Row>
                                <BeneficiaryCardComponent
                                    masterRegId={masterRegId}
                                    fundId={selectedFund.fundNumber}
                                    fundAccountNumber={fundAccountNumber}
                                    isExchange
                                />
                            </Row>
                        )}
                        {(paymentType.check || paymentType.wireTransfer) && (
                            <HeaderRowFundingTypeStyle>
                                <Col xs={12}>
                                    <PageSubHeadingH2 data-test="fundingTypeHeading">{FUNDING_TYPE}</PageSubHeadingH2>
                                </Col>
                            </HeaderRowFundingTypeStyle>
                        )}
                        <Row>
                            {paymentType.wireTransfer && (
                                <WireTransferCard
                                    heading={WIRE_TRANSFER_TEXT}
                                    fundAccountNumber={
                                        selectedFund.fundAccountNumber
                                            ? selectedFund.fundAccountNumber
                                            : fundAccountNumber
                                    }
                                    fundName={selectedFund.fundName}
                                    firstName={loginData.getCustomerProfile && loginData.getCustomerProfile.firstName}
                                    lastName={loginData.getCustomerProfile && loginData.getCustomerProfile.lastName}
                                    data-test="wireTransferCard"
                                />
                            )}
                            {paymentType.check && (
                                <PersonalCheckCard
                                    heading={PERSONAL_CHECK_TEXT}
                                    mailingAddress={mailingAddress}
                                    data-test="personalCheckCard"
                                />
                            )}
                        </Row>
                    </>
                )}
                {isError && (
                    <>
                        <FlexDiv>
                            <PageHeading data-test="errorHeading">{ERROR_PAGE}</PageHeading>
                        </FlexDiv>
                        <Row>
                            <Col>
                                <ErrorP data-test="errorMessage">{TRANSACTION_STANDARD_ERROR_MESSAGE}</ErrorP>
                            </Col>
                        </Row>
                    </>
                )}
                    </>
                )}
            </Container>
        </div>
    );
}

ConfirmationComponent.propTypes = {
    setTradingWarning: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    fundAccountNumber: PropTypes.string,
    paymentType: PropTypes.shape({
        wireTransfer: PropTypes.bool,
        check: PropTypes.bool,
    }),
    selectedFund: PropTypes.instanceOf(Object),
    mailingAddress: PropTypes.string,
    loginData: PropTypes.instanceOf(Object),
    isPurchases: PropTypes.bool,
    isExchange: PropTypes.bool,
    isIRA: PropTypes.bool,
    masterRegId: PropTypes.string,
};
ConfirmationComponent.defaultProps = {
    setTradingWarning: () => {},
    isLoading: false,
    isError: false,
    fundAccountNumber: '',
    paymentType: {
        wireTransfer: false,
        check: false,
    },
    selectedFund: {},
    mailingAddress: WIRE_TRANSFER_MAILING_ADDRESS_TEXT,
    loginData: {},
    isPurchases: false,
    isExchange: false,
    isIRA: false,
    masterRegId: '',
};

export default ConfirmationComponent;
