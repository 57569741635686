import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import PropTypes from "prop-types";
import { getElementIdFormat } from 'modules/CustomerManagementModule/utils';
import InputText from '../InputText';
import Separator from '../Separator';
import Toggle from '../Toggle';
import TopLeftCornerColor from '../TopLeftCornerColor';
import Label from '../Label';

const PanelWrap = styled(TopLeftCornerColor)`
	padding: 1.5em 2em;
	margin: 1.5em 0 2.5em;
	font: 1em benton-sans;
`
const HeaderWrap = styled.div`
  border-bottom: 1px solid #d2d2d2;
  font: 1.125em yorkten-slab-extra-bold;
  color:#004A98;
	text-transform: capitalize;
	padding-bottom: 0.625em;
`
const FlexDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1em 0 0;
`
export const Input = styled(Row)`
	margin-top: ${(props) => props['data-mt']}rem;
	margin-bottom: ${props => props['data-mb']}rem;
`

const COUNTRY_HINT_TEXT = 'Country';
const PHONE_HINT_TEXT = '(000)-000-0000';
const PHONEOPTION_HINT_TEXT = 'Is this is mobile number?';
const AX_PHONECODE_TXT = "Country phone number code"
const AX_OFFSCREEN_TXT = "Country code"
const AX_PHONE_NUM_TXT = "Country phone number"
const AX_PHONE_FORMAT_TXT =  "Phone Number format"

const PhoneCard = (props) => {
	const { 
		cardTitle, 
		countryCodeValue, 
		phoneNumberValue,
	    changeCountryCode, 
	    changePhoneNumber, 
	    changeIsMobile, 
	    onPhoneNumberError,
	    phoneNumErrValue, 
	    onCountryCodeBlur, 
	    onCountryCodeFocus,
	    onPhoneNumberFocus
	} = props;

	const id = getElementIdFormat(cardTitle);
	const cardTitleId = `vcm-cms-${id}`;
	const countryCodeId = `vcm-cms-${id}-country-code`;
	const phoneNumberId = `vcm-cms-${id}-field`;
	const toggleLabelId = `vcm-cms-${id}-is-mobile`;
	const toggleId = `vcm-cms-relation-${id}-is-mobile`;
	const toggleName = `${cardTitle} ${PHONEOPTION_HINT_TEXT}`
	const cornerColor = 'grey';

	return (
		<PanelWrap cornerColor={cornerColor}>
			<HeaderWrap as="p" id={cardTitleId} >{cardTitle}</HeaderWrap>
			<Input data-mt={1.5} data-mb={1.5} role="group" aria-labelledby={cardTitleId} data-name={cardTitle} >
				<Col xs={3}>
					<InputText
					    id={countryCodeId}
						instructionText={COUNTRY_HINT_TEXT} 
						value={countryCodeValue}
						onChange={changeCountryCode}
						maxLength={4}
						onBlur={onCountryCodeBlur}
						onFocus={onCountryCodeFocus}
						aria-label={AX_PHONECODE_TXT} 
						hintTxtFormat={AX_OFFSCREEN_TXT}
						data-form-field
                    	data-group-field
					/>
				</Col>
				<Col xs={9}>
					<InputText
						id={phoneNumberId}
						instructionText={PHONE_HINT_TEXT} 
						value={phoneNumberValue}
						onChange={changePhoneNumber}
						onBlur={onPhoneNumberError}
						onFocus={onPhoneNumberFocus}
						maxLength={15}
						error={!!phoneNumErrValue}
						errorTxt={phoneNumErrValue}
						aria-label={AX_PHONE_NUM_TXT}
						hintTxtFormat={AX_PHONE_FORMAT_TXT}
						data-form-field
                    	data-group-field
					/>
				</Col>
			</Input>
			<Separator/>
			<FlexDiv>
				<Label id={toggleLabelId}>{PHONEOPTION_HINT_TEXT}</Label>
				<Toggle
					id={toggleId}
					aria-labelledby={toggleLabelId}
					onChange={changeIsMobile}
					data-name={toggleName}
					data-form-field
				/>
			</FlexDiv>
		</PanelWrap>
	)
}

PhoneCard.propTypes = {
	cardTitle: PropTypes.string,
	countryCodeValue: PropTypes.string,
	phoneNumberValue: PropTypes.string,
	changeCountryCode: PropTypes.func,
	changePhoneNumber: PropTypes.func,
	changeIsMobile: PropTypes.func,
	onPhoneNumberError: PropTypes.func,
	phoneNumErrValue: PropTypes.string,
	onCountryCodeBlur: PropTypes.func,
	onCountryCodeFocus: PropTypes.func,
	onPhoneNumberFocus: PropTypes.func,
};

PhoneCard.defaultProps = {
	cardTitle: '',
	countryCodeValue: '',
	phoneNumberValue: '',
	changeCountryCode: () => {},
	changePhoneNumber: () => {},
	changeIsMobile: () => {},
	onPhoneNumberError: () => {},
	phoneNumErrValue: '',
	onCountryCodeBlur: () => {},
	onCountryCodeFocus: () => {},
	onPhoneNumberFocus: () => {},
};

export default PhoneCard;