/* eslint-disable react/jsx-no-literals */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FaPiggyBank, FaHandshake, FaGraduationCap } from 'react-icons/fa';
import person from '../../../assets/icon-support.svg';
import FAQ from './FAQ';
import PreRegistrationCard from './PreRegistrationCard';
import './WelcomePage.css';
import consts from './constants';

const assignObj = (obj) => obj;
class WelcomeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      isBottom: false,
      bottomPosition: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const windowHeight = 'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;


    const footerContainer = document.getElementById('main-footer');
    const objectCont = document.querySelector('.footer__copyright-Container');
    const objectTop = (objectCont) ? objectCont.getBoundingClientRect().top + 50 : 0;
    if (
      windowHeight
      - objectTop
      >= 0 && objectCont !== null
    ) {
      this.setState({
        isBottom: true,
        bottomPosition:
          windowHeight
          - objectTop,
      });
      if (footerContainer && footerContainer.className.indexOf('footerContainerFixed') === -1) {
        footerContainer.className = 'pad footerContainerFixed';
      }
    } else {
      this.setState({
        isBottom: false,
        bottomPosition: 0,
      });
      if (footerContainer) {
        footerContainer.className = 'pad';
      }
    }
  }

  render() {
    const { user, history } = this.props;
    const {
      firstname = '',
      lastname = '',
      prefix = '',
      rank = '',
      middlename = '',
    } = user;
    const { isBottom, bottomPosition } = this.state;
    const rankPrefixTxt = !isEmpty(rank) ? rank : prefix;
    const middleInitial = !isEmpty(middlename) ? `${middlename}` : '';
    const transform = 'scaleX(-1)';
    return (
      <div className="welcomePage">
        <Container fluid className="noPad">
          <Row className="bannerWrapper" noGutters>
            <Col>
              <div id="banner" className="pad">
                <div className="headerWrapper">
                  <h1 className="welcomeHeader">
                    <small>{consts.welcome}</small>
                    {rankPrefixTxt && (
                      <span className="userNameSpan">{rankPrefixTxt}</span>
                    )}
                    <span className="userNameSpan">
                      {`${firstname} ${middleInitial} ${lastname}`}
                    </span>
                  </h1>
                  <p className="d-none d-sm-block headerDesc">
                    Your USAA
                    <span className="tmspan">&reg;</span>
                    {' '}
                    investment accounts
                    are transitioning to Victory Capital’s technology platform
                    later this year. Please take a few minutes to pre-register
                    your Victory Capital online account today. Just three easy
                    steps!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row noGutters className="d-block d-sm-none headerDesc">
            <Col>
              <p>
                Your USAA
                <span className="tmspan">&reg;</span>
                {' '}
                investment accounts are
                transitioning to Victory Capital’s technology platform later
                this year. Please take a few minutes to pre-register your
                Victory Capital online account today. Just three easy steps!
              </p>
            </Col>
          </Row>
          <PreRegistrationCard
            cardDisplayName=""
            isBottom={isBottom}
            bottomPosition={bottomPosition}
            history={history}
          />
          <Row className="pad padRow" noGutters>
            <Col lg={7} md={12} className="benefitsTiles">
              <Container fluid className="nopad">
                <Row>
                  <Col sm={12}>
                    <h2 className="pageHeader2">{consts.benifits}</h2>
                  </Col>
                </Row>
                <Row role="list">
                  <Col
                    sm={12}
                    md={6}
                    className="benefitsTilesContent"
                    role="listitem"
                  >
                    <span>
                      <img src={person} alt="" />
                    </span>
                    <p>{consts.para5}</p>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="benefitsTilesContent"
                    role="listitem"
                  >
                    <span>
                      <FaHandshake
                        color="#004A98"
                        focusable="false"
                        style={assignObj({
                          width: 46,
                          height: 44,
                          transform,
                        })}
                      />
                    </span>
                    <p>{consts.para7}</p>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="benefitsTilesContent"
                    role="listitem"
                  >
                    <span>
                      {/* <img src={cap} alt="" /> */}
                      <FaGraduationCap
                        color="#004A98"
                        focusable="false"
                        style={assignObj({
                          width: 46,
                          height: 44,
                          transform,
                        })}
                      />
                    </span>
                    <p>{consts.para6}</p>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="benefitsTilesContent"
                    role="listitem"
                  >
                    <span>
                      <FaPiggyBank
                        color="#004A98"
                        focusable="false"
                        style={assignObj({
                          width: 46,
                          height: 44,
                          transform,
                        })}
                      />
                    </span>
                    <p>{consts.para4}</p>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col />
          </Row>
          <Row className="pad listPadRow" noGutters>
            <Col lg={7} md={12} className="benefitsTiles">
              <h2 className="pageHeader2">{consts.questions}</h2>
              <FAQ />
            </Col>
            <Col />
          </Row>
        </Container>
        {/* <PreRegistrationCard cardDisplayName="d-none d-sm-block" /> */}
      </div>
    );
  }
}

WelcomeWrapper.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    middlename: PropTypes.string,
    lastname: PropTypes.string,
    title: PropTypes.string,
    prefix: PropTypes.string,
    rank: PropTypes.string,
    suffix: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

WelcomeWrapper.defaultProps = {
  user: {
    firstname: '',
    middlename: '',
    lastname: '',
    title: '',
    prefix: '',
    rank: '',
    suffix: '',
  },
  history: {},
};

export default WelcomeWrapper;
