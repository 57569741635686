/**
 * Beneficiary Card Component
 ****************************************
*
* @version 1.0.1
* @author Anjana Joy
* @description This component allows user to add beneficiaries
* @createdDate [06/11/2019]
* ***************************************
* @lastModifiedDate [03/31/2020]
* @lastModifiedBy Anjana Joy
* @lastModifiedReason added VD styles
*/

import { Accordian, AccordianTitle, WIcon, WErrorModal } from '../../../common';
import BeneficiaryForm from '../MarketNAOBeneficiaryForm';
import { Card } from 'react-bootstrap';
import plusIcon from '../../../assets/plusIcon.png';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from '../PersonalInfo/styles';
import '../PersonalInfo/style.css';
import {validateSSNWithoutNANValidation } from 'utils/utils';
import StyledToolTip from '../MarketPersonalInfoCard/StyledToolTip.js'
import helperIcon from '../../../assets/helperIcon.png'

const BeneficiarisHeading = 'Beneficiaries';
const childUTMAHeading = 'Personal Information - Child Beneficiary';
const oneOrMoreBenetext = 'Please name one or more people or entities as beneficiaries';
const primaryPercAlloc = 'Primary distributions must total 100 percent';
const addPrimaryBeneficiaryText = 'Add Primary Beneficiary';
const addContingentBeneficiaryText = 'Add Contingent Beneficiary';
const noteText = "Note : Different states have different definitions and ways to determine a child's minor status.";
const distributionPercentageHint = 'Total Distribution Percentage of Primary Beneficiary = 100 %';
const distributionPercentageHintCon = 'Total Distribution Percentage of Contingent Beneficiary = 100 %';
const HelpText_Benificiary = "What is the difference between contingent and primary beneficiaries?\nContingent beneficiaries receive proceeds from the account only if one or more primary beneficiaries is unable to receive the proceeds from the account."
class BeneficiaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countPrimary: 1,
            countContingent: 0,
            displayPrimaryBenfList: [],
            displayContingentBenfList: [],
            savedState: null,
            beneficiaryrefsPrimary: [],
            beneficiaryrefsContingent: [],
            clicked: true,
            familyRelationShipInfoDropDown:[],
            selectedRelationList:[]
        };
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, familyRelationShipInfo } = nextProps;
        const { beneficiaryPrimaryDataArray = [], beneficiaryContingentDataArray = [], savedRelationshipDropDownData=[] } = savedState || {};
        if (!prevState.retrievedState && savedState) {
            const countPrimary =
                savedState.beneficiaryPrimaryDataArray && savedState.beneficiaryPrimaryDataArray.length;

        let selectedRelationList = savedRelationshipDropDownData.length > 0 ? savedRelationshipDropDownData : familyRelationShipInfo



            return {
                selectedRelationList:selectedRelationList,
                beneficiaryPrimaryDataArray,
                beneficiaryContingentDataArray,
                retrievedState: true,
                countPrimary: countPrimary || 1,
                countContingent:
                    (savedState.beneficiaryContingentDataArray && savedState.beneficiaryContingentDataArray.length) ||
                    0,
            };
        }
        return { ...prevState };
    }

    componentDidMount = () => {
        const {
            countPrimary,
            displayPrimaryBenfList,
            displayContingentBenfList,
            savedState,
            beneficiaryrefsPrimary,
            beneficiaryrefsContingent,
            beneficiaryPrimaryDataArray,
            beneficiaryContingentDataArray,
        } = this.state;
        if (countPrimary === 1 && displayPrimaryBenfList.length === 0 && !savedState) {
            const refs = this.generateBeneficiaryRefs('p');
            const payload = {
                cardId: 1,
                cardRef: refs[+countPrimary - 1],
            };
            displayPrimaryBenfList.push(payload);
        } else if (displayPrimaryBenfList.length > 1) {
            displayPrimaryBenfList.map((item, index) => {
                const newItem = item;
                newItem.cardRef = beneficiaryrefsPrimary[+index];
                return newItem;
            });
        } else if (displayPrimaryBenfList.length === 0 && !savedState && beneficiaryPrimaryDataArray.length > 0) {
            const displayPrimBenList = [];
            let primRefs = [];
            beneficiaryPrimaryDataArray.map((item, index) => {
                primRefs = [...primRefs, React.createRef()];
                const payload = {
                    cardId: index + 1,
                    cardRef: primRefs[+index],
                };
                displayPrimBenList.push(payload);
                return {};
            });
            this.setState({ displayPrimaryBenfList: displayPrimBenList, beneficiaryrefsPrimary: primRefs });
        }
        if (displayContingentBenfList.length > 0) {
            displayContingentBenfList.map((item, index) => {
                const newItem = item;
                newItem.cardRef = beneficiaryrefsContingent[+index];
                return newItem;
            });
        } else if (
            displayContingentBenfList.length === 0 &&
            !savedState &&
            beneficiaryContingentDataArray &&
            beneficiaryContingentDataArray.length > 0
        ) {
            const displayConBenList = [];
            let contRefs = [];
            beneficiaryContingentDataArray.map((item, index) => {
                contRefs = [...contRefs, React.createRef()];
                const payload = {
                    cardId: index + 1,
                    cardRef: contRefs[+index],
                };
                displayConBenList.push(payload);
                return {};
            });
            this.setState({ displayContingentBenfList: displayConBenList, beneficiaryrefsContingent: contRefs });
        }
    };

    generateBeneficiaryRefs = ben => {
        const { beneficiaryrefsPrimary = [], beneficiaryrefsContingent = [] } = this.state;
        const final =
            ben === 'p'
                ? [...beneficiaryrefsPrimary, React.createRef()]
                : [...beneficiaryrefsContingent, React.createRef()];
        if (ben === 'p') {
            this.setState({ beneficiaryrefsPrimary: final });
        } else {
            this.setState({ beneficiaryrefsContingent: final });
        }
        return final;
    };

    cardHeaderClick = e => {
        const { clicked } = this.state;
        e.target.classList.toggle('expand');
        this.setState({ clicked: !clicked });
    };

    addPrimaryBeneficiary = () => {
        let { countPrimary } = this.state;
        const { displayPrimaryBenfList = [] } = this.state;
        if (displayPrimaryBenfList.length < 100) {
            countPrimary += 1;
            const refs = this.generateBeneficiaryRefs('p');
            const payload = {
                cardId: countPrimary,
                cardRef: refs[+countPrimary - 1],
            };
            displayPrimaryBenfList.push(payload);
            this.setState({ displayPrimaryBenfList, beneficiaryrefsPrimary: refs, countPrimary }, () => {
                document.getElementById(`beneficiaryP${countPrimary}BeneficiaryType`).focus();
            });
        } else {
            this.setState({ showErrorModal: true, alertMessage: 'max 100 beneficiaries allowed', countPrimary });
        }
    };

    addContingentBeneficiary = () => {
        let { countContingent } = this.state;
        const { displayContingentBenfList = [] } = this.state;
        if (displayContingentBenfList.length < 100) {
            countContingent += 1;
            const refs = this.generateBeneficiaryRefs('c');
            const payload = {
                cardId: countContingent,
                cardRef: refs[+countContingent - 1],
            };
            displayContingentBenfList.push(payload);
            this.setState({ displayContingentBenfList, beneficiaryrefsContingent: refs, countContingent }, () => {
                document.getElementById(`beneficiaryC${countContingent}BeneficiaryType`).focus();
            });
        } else {
            this.setState({ showErrorModal: true, alertMessage: 'max 100 beneficiaries allowed', countContingent });
        }
    };

    removeBeneficiary = (index, benType, removeIdFromList) => {

        const {
            displayPrimaryBenfList = [],
            displayContingentBenfList = [],
            beneficiaryrefsContingent,
            beneficiaryrefsPrimary,
            countPrimary,
            countContingent
        } = this.state;
        let displayBenfList = [];
        let beneficiaryrefs = [];
        let count = 0;
        if (benType === 'P') {
            displayBenfList = displayPrimaryBenfList;
            beneficiaryrefs = beneficiaryrefsPrimary;
            count = 1;
        } else {
            displayBenfList = displayContingentBenfList;
            beneficiaryrefs = beneficiaryrefsContingent;
        }
        if (displayBenfList.length > count) {
            const removeCardIndex = displayBenfList.findIndex(item => item.cardId.toString() === index);
            const removeBenRef = displayBenfList[+removeCardIndex].cardRef;
            const removeBenRefIndex = beneficiaryrefs.findIndex(item => item === removeBenRef);

            displayBenfList.splice(removeCardIndex, 1);
            beneficiaryrefs.splice(removeBenRefIndex, 1);

            let updatedSelectedRelationList = this.state.selectedRelationList
            updatedSelectedRelationList = updatedSelectedRelationList.filter(item => item !== removeIdFromList)
    
                if (benType === 'P') {

                    // Removing the specific index from the local selected beneficiary data/array
                    let arrayAfterRemovingItem = this.state.beneficiaryPrimaryDataArray.filter(item => item.relationshipId != removeIdFromList)

                    this.setState({ 
                        beneficiaryPrimaryDataArray: arrayAfterRemovingItem,
                        selectedRelationList:updatedSelectedRelationList, 
                        displayPrimaryBenfList: displayBenfList, 
                        beneficiaryrefsPrimary: beneficiaryrefs , 
                        countPrimary: countPrimary-1},()=>{
                        document.getElementsByClassName('addAnotherFieldBtn addBtn addPrimaryBen')[0].focus()
                    });
                } else {
                    // Removing the specific index from the local selected beneficiary data/array
                    let arrayAfterRemovingItem = this.state.beneficiaryContingentDataArray.filter(item => item.relationshipId != removeIdFromList)

       
                    this.setState({
                        selectedRelationList:updatedSelectedRelationList,
                        displayContingentBenfList: displayBenfList,
                        beneficiaryrefsContingent: beneficiaryrefs,
                        beneficiaryContingentDataArray: arrayAfterRemovingItem,
                        countContingent: countContingent-1
                    });
                }

            // }
           
        } else {
            this.setState({ showErrorModal: true, alertMessage: '1 beneficiary is mandatory' });
        }
    };


    resetItemValues = (replaceItemRelationShipId, beneType, specialCategoryUser=false) => {

        const emptyObject = {
            distributionPercentage: "",
            relationToAccHolder: "",
            emailAdd: "",
            firstName: "",
            lastName: "",
            maskedSSN: "",
            beneficiaryType: "individuals",
            middleName: "",
            maskedDOB: "",
            dateOfBirth: "",
            relationshipId: "",
            ssn: "",
            beneficiaryDOB: "",
            beneficiarySSN: "",
            ssnBenData: {
              ssnOne: "",
              maskedssnOne: "",
              ssnTwo: "",
              maskedssnTwo: "",
              ssnThree: "",
              maskedssnThree: "",
              fullSSN: "",
              errorOneText: "",
              errorTwoText: "",
              errorThreeText: "",
              readOnlySsnOne: false,
              readOnlySsnTwo: false,
              typeSsnOne: "password",
              typeSsnTwo: "password",
              retrievedState: false,
              isSSNOneError: false,
              isSSNTwoError: false,
              isSSNThreeError: false,
              maskedFullSSN: ""
            }
        }



        if(beneType === 'P'){
            let arrayAfterReplacingItem = this.state.beneficiaryPrimaryDataArray
            let replaceObjectIndex = this.state.beneficiaryPrimaryDataArray.findIndex(item => item.relationshipId == replaceItemRelationShipId)
    
            if (replaceObjectIndex !== -1){
                arrayAfterReplacingItem[replaceObjectIndex] = emptyObject
    
            }

            // when this is a user who is only allowed to have one primary spouse and isExistInCommunityPropertyState and his already having a bene data, and trying to select other user from the relationship dropdown
            if(this.state.beneficiaryPrimaryDataArray.length == 1 && specialCategoryUser){
                arrayAfterReplacingItem[0] = emptyObject
            }
    
            this.setState({
                beneficiaryPrimaryDataArray: arrayAfterReplacingItem,
            });
        }else{
            let arrayAfterReplacingItem = this.state.beneficiaryContingentDataArray
            let replaceObjectIndex = this.state.beneficiaryContingentDataArray.findIndex(item => item.relationshipId == replaceItemRelationShipId)
    
            if (replaceObjectIndex !== -1){
                arrayAfterReplacingItem[replaceObjectIndex] = emptyObject
    
            }
    
            this.setState({
                beneficiaryContingentDataArray: arrayAfterReplacingItem,
            });
        }
       
    }


    handleDistribution = arr => {
        let totalDistribution = 0;
        const { accountSelected } = this.props;
        const childAccount = accountSelected.indexOf('child') > -1;
        if (!childAccount) {
            for (let i = 0; i < arr.length; i += 1) {
                if (Object.keys(arr[+i].length !== 0)) {
                    totalDistribution += Number(arr[+i].distributionPercentage);
                }
            }
            if (totalDistribution !== 100) {
                return true;
            }
        }
        return false;
    };

    handleValidation = arr => {
        const res = arr.filter(item => item.valid === false);
        if (res.length > 0) {
            return { valid: false, toFocus: res[0].toFocus };
        }
        return { valid: true, toFocus: '' };
    };

    handleDistributionErrorMsg = benType => {
        if (benType === 'P') {
            this.setState({ distributionPercentageErrorPrimary: false });
        } else {
            this.setState({ distributionPercentageErrorContingent: false });
        }
    };

    getBeneficiaryArrayValues = (arr, action) => {
        return arr.map((item,index) => {
            let data = {};
            if (item.current) {
                data = item.current.sendDataToBeneficaryCard(action);

                // assigning unique id if its newly entered without selecting dropdown value
                if(data.relationshipId == ''){
                    data.relationshipId = `new-${data.beneficiarySSN}`
                }
            }
            return data;
        });
    };

    sendDataToParent = (action='defaultVal') => {
        let save = action === 'save'
        
        const { beneficiaryrefsPrimary, beneficiaryrefsContingent, selectedRelationList } = this.state;
        const beneficiaryPrimaryDataArray = this.getBeneficiaryArrayValues(beneficiaryrefsPrimary, action);
        const beneficiaryContingentDataArray = this.getBeneficiaryArrayValues(beneficiaryrefsContingent, action);
        const { valid: benePrimValid, toFocus: benePrimToFocus } = this.handleValidation(beneficiaryPrimaryDataArray);
        const { valid: beneContValid, toFocus: beneContToFocus } = this.handleValidation(
            beneficiaryContingentDataArray,
          );

        let resultValidation = benePrimValid && beneContValid;
        const benePrimHandleDist = this.handleDistribution(beneficiaryPrimaryDataArray);
        const beneContHandleDist = this.handleDistribution(beneficiaryContingentDataArray);
        if (benePrimHandleDist && !save) {
        //    check if its not save button clicked and benePrimHandleDist is true else dont show the distribution error
                this.setState({ distributionPercentageErrorPrimary: true });
                resultValidation = false;
            
        } else {
            this.setState({ distributionPercentageErrorPrimary: false });
        }
        if (beneficiaryContingentDataArray.length > 0 && beneContHandleDist && !save) {
            // check if its not save button clicked and beneContHandleDist is true else dont show the distribution error
                this.setState({ distributionPercentageErrorContingent: true });
                resultValidation = false;
        } else {
            this.setState({ distributionPercentageErrorContingent: false });
        }


        let beneficiariesResultValidation = true;
        let beneficiariesPrimaryResultValidation = true;
        let beneficiariesContigentResultValidation = true;
        // Validation for SSN start
        // for Primary Beneficiary
        for(let i=0;i<beneficiaryrefsPrimary.length;i++)
        {
            let tempSSNValue = beneficiaryrefsPrimary[i].current.ssnBenRef?.current?.state;
            // beneficiariesResultValidation = validateSSN(tempSSNValue.ssnOne,tempSSNValue.ssnTwo,tempSSNValue.ssnThree);
            beneficiariesPrimaryResultValidation = tempSSNValue && validateSSNWithoutNANValidation(tempSSNValue.ssnOne,tempSSNValue.ssnTwo,tempSSNValue.ssnThree);
            if(beneficiariesPrimaryResultValidation == false)
            {
                break;
            }
        }
        // for Contingent Beneficiaryes
        for(let i=0;i<beneficiaryrefsContingent.length;i++)
        {
            let tempSSNValue = beneficiaryrefsContingent[i].current?.ssnBenRef?.current?.state;
            // beneficiariesResultValidation = validateSSN(tempSSNValue.ssnOne,tempSSNValue.ssnTwo,tempSSNValue.ssnThree);
            beneficiariesContigentResultValidation = tempSSNValue && validateSSNWithoutNANValidation(tempSSNValue.ssnOne,tempSSNValue.ssnTwo,tempSSNValue.ssnThree);
            if(beneficiariesContigentResultValidation == false)
            {
                break;
            }
        }

        // Validation for SSN end

        const returnValidation = beneficiariesPrimaryResultValidation && beneficiariesContigentResultValidation && resultValidation;

        let toFocus = '';
        if (!benePrimValid) {
            toFocus = benePrimToFocus;
        } else if (!beneContValid) {
            toFocus = beneContToFocus;
        } else if (benePrimHandleDist) {
            toFocus = `beneficiaryP1DistributionPercentage`;
        } else if (beneficiaryContingentDataArray.length > 0 && beneContHandleDist) {
            toFocus = `beneficiaryC1DistributionPercentage`;
        }

        return {
            beneficiaryPrimaryDataArray,
            beneficiaryContingentDataArray,
            savedRelationshipDropDownData: selectedRelationList,
            valid: returnValidation,
            toFocus,
        };
    };

    handleErrorModal = () => {
        this.setState({ showErrorModal: false, alertMessage: '' });
    };

    handleFamilyRelationShipInfoDropDown = (selectedRelation,currentRelation) => {

        let relationList = []
    
        const combineBothPrimaryAndContigentList = [...this.state.displayPrimaryBenfList , ...this.state.displayContingentBenfList]
     
        relationList = combineBothPrimaryAndContigentList.map(item => {
            if(item.cardRef?.current?.state?.relationshipId != ''){
                return item.cardRef?.current?.state?.relationshipId
            }
        })

        relationList = relationList.filter(item => item !== currentRelation)
  
        this.setState({
            selectedRelationList: [...relationList,selectedRelation]
        }) 
    }

    render() {
        const { accountSelected, accountType } = this.props;
        const {
            displayPrimaryBenfList,
            displayContingentBenfList,
            beneficiaryPrimaryDataArray = [],
            beneficiaryContingentDataArray = [],
            distributionPercentageErrorPrimary = false,
            distributionPercentageErrorContingent = false,
            alertMessage,
            showErrorModal,

        } = this.state;
        const childAccount = accountSelected === 'inv_child';
        const isUTMA = accountType === 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)';  
        //Tooltip functionality for Benificiary
        const { HelperTetxForBenificiary } = this.state
        const toggleToolTipForBenificiary = () => {
            this.setState({ HelperTetxForBenificiary: true })
        }

        const closeToolTipForBenificiary = () => {
            this.setState({ HelperTetxForBenificiary: false })
        }      
    

        return (
            <Accordian
                ref={this.accordionRef}
                key={childAccount ? childUTMAHeading : BeneficiarisHeading}
                accordianPaddingLeft={0}
                titleChild={
                    <AccordianTitle
                        accType={childAccount ? childUTMAHeading : BeneficiarisHeading}
                        id={childAccount ? childUTMAHeading : BeneficiarisHeading}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                titleSeperator
                labelledBy={childAccount ? childUTMAHeading : BeneficiarisHeading}
                Id={childAccount ? childUTMAHeading : BeneficiarisHeading}
            >
                <Card>
                    <WErrorModal message={alertMessage} show={showErrorModal} modalClick={this.handleErrorModal} />
                    <Card.Body>
                        <img id="Benificiary" className='TooltipForBenificiary' onMouseOut={closeToolTipForBenificiary} src={helperIcon} alt="" />
                        <StyledToolTip
                            id="Benificiary_Tooltip"
                            placement="top"
                            tooltipOpen={HelperTetxForBenificiary}
                            targetID="Benificiary"
                            handleToggle={toggleToolTipForBenificiary}
                            autohide={true}
                            triggerEvt="hover focus"
                            tooltipText={HelpText_Benificiary}
                            closeTooltip={closeToolTipForBenificiary}
                        />
                        {!childAccount && (
                            <>
                                <p className="oneOrMoreBenetext" style={styles.mobileHeaderStyle}>
                                    {oneOrMoreBenetext}
                                </p>
                                <span className="notError primaryPercAlloc">{primaryPercAlloc}</span>
                            </>
                        )}
                        {childAccount && <span className="notError primaryPercAlloc">{noteText}</span>}
                        {displayPrimaryBenfList &&
                            displayPrimaryBenfList.map((item, index) => {
                                return (
                                    <BeneficiaryForm
                                        isChild={childAccount}
                                        savedState={beneficiaryPrimaryDataArray[+index]}
                                        key={item.cardId}
                                        index={item.cardId.toString()}
                                        ref={item.cardRef}
                                        isUTMA={isUTMA}
                                        handleDistributionErrorMsg={this.handleDistributionErrorMsg}
                                        distributionPercentageError={distributionPercentageErrorPrimary}
                                        sendToBeneficiaryParentToRemove={this.removeBeneficiary}
                                        benType={childAccount ? '' : 'P'}
                                        hasRelationshipOption={true}
                                        familyRelationShipInfo={this.props.familyRelationShipInfo}
                                        isMarketNAOFlow= {true}
                                        handleFamilyRelationShipInfoDropDown={this.handleFamilyRelationShipInfoDropDown}
                                        familyRelationShipInfoDropDown={this.state.familyRelationShipInfoDropDown}
                                        selectedRelationList={this.state.selectedRelationList}
                                        relationshipId = {item.cardRef?.current?.state?.relationshipId}
                                        resetItemValues={this.resetItemValues}
                                        setDisableNextButton={this.props.setDisableNextButton}
                                        countPrimary = {this.state.countPrimary}
                                        setCommunityPropertyStateRuleVoilated={this.props.setCommunityPropertyStateRuleVoilated}
                                    />
                                );
                            })}
                        {!childAccount && (
                            <>
                                <div className="distributionPercentageHint">
                                    <p>{distributionPercentageHint}</p>
                                </div>
                                <div className="benBtnWrapper">
                                    <button
                                        type="button"
                                        className="addAnotherFieldBtn addBtn addPrimaryBen"
                                        onClick={this.addPrimaryBeneficiary}
                                        aria-label={addPrimaryBeneficiaryText}
                                    >
                                        <WIcon src={plusIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                                        {addPrimaryBeneficiaryText}
                                    </button>
                                </div>
                            </>
                        )}
                        {displayContingentBenfList &&
                            displayContingentBenfList.map((item, index) => {
                                return (
                                    <BeneficiaryForm
                                        isChild={childAccount}
                                        savedState={beneficiaryContingentDataArray[+index]}
                                        key={item.cardId}
                                        index={item.cardId.toString()}
                                        ref={item.cardRef}
                                        handleDistributionErrorMsg={this.handleDistributionErrorMsg}
                                        distributionPercentageError={distributionPercentageErrorContingent}
                                        sendToBeneficiaryParentToRemove={this.removeBeneficiary}
                                        benType="C"
                                        hasRelationshipOption={true}
                                        familyRelationShipInfo={this.props.familyRelationShipInfo}
                                        isMarketNAOFlow= {true}
                                        handleFamilyRelationShipInfoDropDown={this.handleFamilyRelationShipInfoDropDown}

                                        familyRelationShipInfoDropDown={this.state.familyRelationShipInfoDropDown}
                                        selectedRelationList={this.state.selectedRelationList}
                                        relationshipId = {item.cardRef?.current?.state?.relationshipId}
                                        resetItemValues={this.resetItemValues}
                                        setDisableNextButton={this.props.setDisableNextButton}
                                        countPrimary = {this.state.countPrimary}
                                        setCommunityPropertyStateRuleVoilated={this.props.setCommunityPropertyStateRuleVoilated}
                                    />

                                );
                            })}

                        {!childAccount && (
                            <>
                                {displayContingentBenfList.length > 0 && (
                                    <div className="distributionPercentageHint">
                                        <p>{distributionPercentageHintCon}</p>
                                    </div>
                                )}
                                <div className="benBtnWrapper">
                                    <button
                                        type="button"
                                        className="addAnotherFieldBtn addBtn addContingentBen"
                                        onClick={this.addContingentBeneficiary}
                                        aria-label={addContingentBeneficiaryText}
                                    >
                                        <WIcon src={plusIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                                        {addContingentBeneficiaryText}
                                    </button>
                                </div>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Accordian>
        );
    }
}
BeneficiaryCard.propTypes = {
    accountSelected: PropTypes.string,
    savedState: PropTypes.instanceOf(Object),
    accountType: PropTypes.string,
};

BeneficiaryCard.defaultProps = {
    accountSelected: '',
    savedState: {},
    accountType: '',
};
export default BeneficiaryCard;
