import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import WIcon from '../WIcon/WIcon';
import './stepper.css';

const StyledHR=styled.hr`
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width:calc((624%-${props => props.length}*33)/(${props => props.steps}-1)) !important;
    }
    @media only screen and (max-width: 1199px)  and (min-width: 992px) {
      width:calc((566%-${props => props.length}*52)/(${props => props.steps}-1)) !important;        
    }
    @media only screen and (min-width: 1200px) {
      width:calc((566%-${props => props.length}*55)/(${props => props.steps}-1)) !important;  
      border-top: 5px dashed;      
    }
    @media only screen and (max-width: 767px) {
      width:calc((624%-${props => props.length}*33)/(${props => props.steps}-1)) !important;
    }
`;

export const styles = {
  wizardSection: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontFamily: "benton-sans"
  },
  wizardPageSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center"
  },
  wizardGrid: {
    display: "flex",
    flexDirection:'column',
    justifyContent: "center",
    alignItems: "center",   
    borderColor: "rgba(112, 112, 112, 0.5)",
    borderWidth: 1,
    backgroundColor: "#FFFFFF"
  },
  wizardPageTxt: {
    fontSize: 15,
    color: "#535353",
    textAlign: "center"
  },
  wizardGridVisited: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",   
    borderColor: "rgba(112, 112, 112, 0.5)",
    borderWidth: 1,
    backgroundColor: "#58D460"
  },
  wizardGridVisitedTxt: {
    fontSize: 15,
    color: "#535353",
    textAlign: "center"
  },

    wizardGridCurrent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: 'rgba(112, 112, 112, 0.5)',
        borderWidth: 1,
        backgroundColor: '#CDDBFC',
    },
    wizardPageCurrentTxt: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#535353',
        textAlign: 'center',
    },

    wizardTitleTxt: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#535353',
        lineHeight: 22,
        marginHorizontal: 12,
    },
};

const WStepper = props => {
  
  /* ----------- Wizard grid rendering -------------------*/
  const {pages, currentPage, className, stepperColStyle, stepperTextStyle} = props;
  const pagesArr = [];
  const totalPages = pages;
  let tempViewSkin = '';
  let srOnlyText = '';
  let ariaCurrent=false;
  const {length} = totalPages;
  const assignObj = (obj) => { return obj; };
  const stepperCompClassName = `stepperComp stepperComp-${length}`;
  let stepperColAlign;
  if ( length <= 6) {
   stepperColAlign = assignObj({width: '100%', justifyContent: 'space-between'});
  } else if(length === 7) {
    stepperColAlign = assignObj({width: '100%', justifyContent: 'unset', flexWrap:'nowrap'});
  } else {
    stepperColAlign = assignObj({width: '100%', justifyContent: 'unset',});
  }

  for (let i = 0; i < length; i+=1) {
    if (currentPage === i) {
      tempViewSkin = 'stepperDiv activepage';
      srOnlyText = '';
      ariaCurrent = true;
    } else if (i < currentPage) {
      tempViewSkin = 'stepperDiv visitedpage';
      srOnlyText = ', Completed';
      ariaCurrent = false;
    } else {
      tempViewSkin = 'stepperDiv tobevistedpage';
      srOnlyText = '';
      ariaCurrent = false;
    }
    pagesArr.push(      
      <li key={i} className="stepperCol">
             
          <Col  style={assignObj({padding:0})}>
            {/* <WButton
              variant="outline"
              buttonstyle={assignObj({ boxSizing:'border-box', maxHeight: "50px",marginTop:20,width:'',...tempViewSkin,borderRadius:0,
              border: '1px solid #70707080'})}
              buttontext={assignObj(`${i+1}  ${totalPages[parseInt(i, 10)]}`)}
              buttontextstyle={tempTxtSkin}              
              block
            /> */}
            <div className={tempViewSkin}>
              <span className="circleSpan" aria-hidden>{tempViewSkin.indexOf('tobevistedpage') !== -1 ? assignObj(`${i+1}`) : 
                  <WIcon icon={faCheck} size="sm" fontawesomestyle={assignObj({height: 18, width: 15, margin: 0})} alt="" />}</span>
              <span className="stepperText" style={stepperTextStyle} aria-current={ariaCurrent}><span className="sr-only">{`Step ${i+1}. ${totalPages[parseInt(i, 10)]} ${srOnlyText}`}</span><span aria-hidden>{tempViewSkin.indexOf('tobevistedpage') !== -1 ? assignObj(`${totalPages[parseInt(i, 10)]}`) : 
                  assignObj(`${i+1}.  ${totalPages[parseInt(i, 10)]}`)}</span></span>
              {
                (i===length-1) ?'':
                // <hr style={assignObj({width:hrWidth})} />      
                <StyledHR length={length} steps={length-1} aria-hidden tabIndex="-1"/>
              } 
            </div>
          </Col>       
      </li>
      
    );
  }
  return (
    <div className={className}>
      <div style={styles.wizardSection}>
        <div style={styles.wizardPageSection}><ul style={assignObj({...stepperColAlign, ...stepperColStyle}) } className={stepperCompClassName} id="stepperComp">{pagesArr}</ul></div>
      </div>
    </div>    
  );
};

WStepper.propTypes = {
    currentPage: PropTypes.number,
    pages: PropTypes.instanceOf(Array),
    className: PropTypes.string,
    stepperColStyle: PropTypes.instanceOf(Object),
    stepperTextStyle: PropTypes.instanceOf(Object)
};

WStepper.defaultProps = {
    currentPage: 5,
    pages: [],
    className: '',
    stepperColStyle: {},
    stepperTextStyle: {}
};

export default WStepper;
