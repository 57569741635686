import { applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../Reducers';
import ApiEventRegistration from '../Network/ApiEventRegistration';
import AxiosMiddleWare from './AxiosMiddleWare';
// import LoggerMiddleware from './LoggerMiddleware';

const initialState = {
    initialAppData: {
        env: 'MOCK',
        email: 'vcm@gmail.com',
        securedPIN: 1167,
        phone: '+918754495245',
        firstName: 'John',
        middleInitial: '',
        lastName: 'Kye',
        suffix: 'Sr',
        onlineId: 'VcmUserId',
        currentPassword: 'Password@123',
        currentPIN: '1234',
        signedInSuccess: false,
        firsttimeloginflag: true,
        role: 'Member',
        loginStatus : 'prospect'
    },
};

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'VCM - Redux Store'
    }) : compose;

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, AxiosMiddleWare)));

ApiEventRegistration();

export default store;
