import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";
import { linkResponseSuccess, linkResponseError } from "./AnalyticsActions";

export const getAccountsList = (payload) => dispatch => {
    // SETTING ENVIRONMENT
    const env = '';
    // DISPATCHING ACTION FOR LOADER
    dispatch({
      type: ActionTypes.GET_ACCOUNTS_LIST_LOADING
    });
    // MAKE API REQUEST
    makeApiRequest(
      ActionTypes.GET_ACCOUNTS_LIST,
      payload,
      env,
      response => {
        // console.log(response.data.result)
          if (response.data && response.data.result) {
              dispatch({
                  type: ActionTypes.GET_ACCOUNTS_LIST_SUCCESS,
                  payload:response.data.result
              });
            dispatch(linkResponseSuccess());
          } else {
              dispatch({
                  type: ActionTypes.GET_ACCOUNTS_LIST_FAILURE,
              });
              dispatch(linkResponseError(response));
            }
      },
      error => {
          dispatch({
              type: ActionTypes.GET_ACCOUNTS_LIST_FAILURE,
              payload: error
          });
          dispatch(linkResponseError(error));
        }
    );
  };

  export const changeFundsOptions = data => dispatch => {
    dispatch({
        type: ActionTypes.CHANGE_FUNDS_OPTIONS,
        payload: data
    });
};

export const updateDividends = payload => dispatch => {
  // SETTING ENVIRONMENT
  const env = '';
  // DISPATCHING ACTION FOR LOADER
  dispatch({
    type: ActionTypes.UPDATE_DIVIDENDS_LOADING
  });
  // MAKE API REQUEST
  makeApiRequest(
    ActionTypes.UPDATE_DIVIDENDS,
    payload,
    env,
    response => {
      if (response.data && response.data.result && response.data.result.length) {
        // DISPATCHING SUCCESS ACTION
        dispatch({
          type: ActionTypes.UPDATE_DIVIDENDS_SUCCESS,
          payload: response.data.result
        });
        dispatch(linkResponseSuccess());
      } else {
        dispatch({
            type: ActionTypes.UPDATE_DIVIDENDS_FAILURE,
        });
        dispatch(linkResponseError(response));
      }
    },
    (error) => {
      // DISPATCHING FAILURE ACTION
      dispatch({
        type: ActionTypes.UPDATE_DIVIDENDS_FAILURE,
        response:error.data
      });
      dispatch(linkResponseError(error.data));
    }
  );
};

export const clearReduxData = (stateKey, obj) => (dispatch) => {
  dispatch({
      type: ActionTypes.CLEAR_REDUX_DATA,
      [stateKey]: obj
  });
};

export const resetUpdateFlag = () => dispatch => {
  dispatch({
    type: ActionTypes.RESET_UPDATE_FLAG
  });
};