import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: true,
    isError: null,
    isSuccess: false,
    transactionList: [],
    accountServiceOptions: {},
    accountServiceOptionsLinks: [],
    storeAccountData: {}
};

const accountServiceOptionsRoutes = {
    aip: '/automaticinvestment',
    swp: '/systematicwithdrawal',
    beneficiaries: '/manageBeneficiariesAccounts',
    dividends: '/dividends',
    rmd: '/requiredMinimumDistribution',
    bankAccount: '/bankaccounts'
};

const accountManagePreferencesRoutes = {
    deliveryPreference: '/accountPreference',
    phoneEmail: '/basicInformation',
    milingAddress: '/basicInformation'
};

const getAccountServiceLinks = (data) => {
    const accountServiceOptionsLinks = [];
    const {
        rmd,
        fund_to_bank: swp,
        manage_beneficiary: beneficiaries,
        bank_to_fund: aip,
        dividends_and_capital_gains: dividends,
        bank_accounts:bankAccount,
        manage_beneficiary:manageBeneficiary
    } = data || {};

    if (aip) {
        accountServiceOptionsLinks.push({
            route: accountServiceOptionsRoutes.aip,
            text: aip,
        });
    }
    if (swp) {
        accountServiceOptionsLinks.push({
            route: accountServiceOptionsRoutes.swp,
            text: swp,
        });
    }
    if (dividends) {
        accountServiceOptionsLinks.push({
            route: accountServiceOptionsRoutes.dividends,
            text: dividends,
        });
    }
    if (rmd) {
        accountServiceOptionsLinks.push({ route: accountServiceOptionsRoutes.rmd, text: rmd });
    }
    if (bankAccount) {
        accountServiceOptionsLinks.push({
            route: accountServiceOptionsRoutes.bankAccount,
            text: bankAccount,
        });
    }
    if (beneficiaries) {
        accountServiceOptionsLinks.push({
            route: accountServiceOptionsRoutes.beneficiaries,
            text: beneficiaries,
        });
    }

    return accountServiceOptionsLinks;
}

const getAccountManagePreferences = (data) =>
{
    const accountServicesManagePreferencesList = [];
    const {
        delivery_preferences: deliveryPreference,
        phone_email: phoneEmail,
        trusted_contact:trustedContect,
        mailing_address: milingAddress
    } = data || {};

    if (deliveryPreference) {
        accountServicesManagePreferencesList.push({
            route: accountManagePreferencesRoutes.deliveryPreference,
            text: deliveryPreference,
        });
    }
    if (phoneEmail) {
        accountServicesManagePreferencesList.push({
            route: accountManagePreferencesRoutes.phoneEmail,
            text: phoneEmail,
        });
    }
    if (trustedContect) {
        accountServicesManagePreferencesList.push({
            route: accountManagePreferencesRoutes.trustedContect,
            text: trustedContect,
        });
    }
    if (milingAddress) {
        accountServicesManagePreferencesList.push({
            route: accountManagePreferencesRoutes.milingAddress,
            text: milingAddress,
        });
    }
  
    return accountServicesManagePreferencesList;

}

const TransactionHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_TRANSACTION_HOLDER_LOADER:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };

        case ActionTypes.GET_TRANSACTION_HOLDER_RECEIVED:
            return {
                ...state,
                transactionList: action.payload.transactionList,
                isError: false,
                isLoading: false,
            };

        case ActionTypes.GET_TRANSACTION_HOLDER_SUCCESS:
            return {
                ...state,
                transactionList: action.payload.transactionList,
                isError: false,
                isLoading: false,
                isSuccess: true,
            };
        case ActionTypes.GET_TRANSACTION_HOLDER_ERROR:
        case ActionTypes.GET_TRANSACTION_HOLDER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                transactionList: [],
            };
        case ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS_SUCCESS:{

            return {
                ...state,
                accountServiceOptions: action.response,
                accountServiceOptionsLinks: getAccountServiceLinks(action.response),
                accountManagePreferences: getAccountManagePreferences(action.response),
                isError: false,
                isLoading: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_TRANSACTION_HOLDER_RESET:
            return initialState;
            case  ActionTypes.RESET_ACCOUNT_SERVICE_OPTIONS_LIST:
                return {
                    ...state,
                    accountServiceOptionsLinks: [],
                    accountServiceOptions: {},
                }
            case ActionTypes.GET_TRANSACTION_HOLDER_SUCCESS_FOR_MANAGE:
                return {
                    ...state,
                    transactionListForManage: action.payload.transactionList,
                    isError: false,
                    isLoading: false,
                    isSuccess: true,
                };
            case ActionTypes.GET_TRANSACTION_HOLDER_ERROR_FOR_MANAGE:
                case ActionTypes.GET_TRANSACTION_HOLDER_FAILURE_FOR_MANAGE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        transactionList: [],
                    };
                    case ActionTypes.STORE_ACCOUNTINFO_DATA:{

                        return {
                            ...state,
                            storeAccountData:action.payload
                        };
                    }
        default:
            return state;
    }
};

export default TransactionHistoryReducer;
