import React, { useCallback } from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { WIcon } from '../../../common';
import cancelButton from '../../../assets/btn_cancel_red.png';
import SortIndicator from '../../../assets/sor-indicator.svg';
import SortIndicatorUpArrow from '../../../assets/sort-indicator-up-arrow.svg';
import { ACC } from './Constants';
import {CANCEL_IN_FISTA} from './Constants';
import { getFisTokenWithExpiry } from 'common/Authenticate/session';

const C = {};

const S = {
    rectangle: {
        position: 'relative',
        top: '3px',
        left: '0',
        width: '55px',
        height: '26px',
        background: '#004A98 0% 0% no-repeat padding-box',
        opacity: '1',
        color: '#ffffff',
        fontSize: '12px',
    },
    p: {
        top: '5px',
        left: 'calc(50% - 21px)',
        width: '42px',
        height: '14px',
        textAlign: 'center',
        fontSize: '12px',
        letterSpacing: '0',
        color: '#FFFFFF',
        opacity: '1',
        margin: 0,
        position: 'absolute',
    },
    th: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '1em',
        lineHeight: '22px',
        padding: '12px 12px 12px 0',
        borderTop: 'none',
    },
    td: {
        fontSize: '0.875em',
        textAlign: 'right',
        paddingLeft: '0px',
        color: '#49494A',
    },
    arrowStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignContent: ' space-evenly',
    },
    basis1: {
        flexBasis: '30%',
    },
    basis2: {
        flexBasis: '70%',
    },
};

const E = {
    Status: styled.div`
        display: flex;
    `,
    CancelButton: styled.div`
        display: flex;
        color: #004a98;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        font-family: yorkten-slab-normal, sans-serif;
        cursor: pointer;
        padding-left: 5px;
        margin-left: 7px;
        margin-right: 10px;
        margin-top: -4px;

        :hover {
            :nth-child(odd) {
                background: #f7f7f7;
            }
        }

        :hover {
            :nth-child(even) {
                background: white;
            }
        }
    `,
    NoRecords: styled.div`
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        margin-top: 5px;
    `,
};

const assignObj = obj => obj;

const MyReactTable = props => {
    const { rectangle, p, th, td } = S;
    const { tabelData ,cancelTransaction,cancelTransactionInFista} = props;
    const {
        tickerHeading = 'Ticker',
        fundHeading = 'Fund Name',
        quantityHeading = 'Quantity',
        dateHeading = 'Date',
    } = C;
    const data = React.useMemo(() => [...tabelData], [tabelData]);
    const sortByDate = useCallback((a, b) => {
        if (!a.values.effectiveDate || !b.values.effectiveDate) {
            return Number.MIN_VALUE;
        }
        return new Date(Date.parse(a.values.effectiveDate)) - new Date(Date.parse(b.values.effectiveDate));
    }, []);

    const sortBy = React.useMemo(() => sortByDate, [sortByDate]);

    // const parseStrFloat = value => {
    //     return Number.isNaN(value) ? value : '_';
    // };

    const columns = React.useMemo(
        () => [
            {
                // Header: () => <span style={assignObj({ color: '#49494A' })}>{tickerHeading}</span>,
                // Header: {tickerHeading},
                Header: 'Ticker',
                accessor: 'ticker', // accessor is the "key" in the data,
                Cell: cellProps => {
                    return (
                        <div className="rectangle" style={rectangle}>
                            <p style={p}>{cellProps.value}</p>
                        </div>
                    );
                },
                width: '6.5%',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                // Header: () => <span style={assignObj({ color: '#49494A' })}>{fundHeading}</span>,
                Header: 'Fund Name',
                accessor: 'fundName',
                width: '18.5%',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Transaction Description',
                accessor: 'tranDesc',
                width: '12.5%',
                disableFilters: true,
                Cell: c => <span>{c.value ? c.value : '_'}</span>,
                canSort: true,
            },
            {
                Header: 'Date',
                id: 'effectiveDate',
                accessor: c => c.effectiveDate,
                width: '14.5%',
                sort: 'desc',
                Cell: c => <span>{c.value?.length > 0 ? c.value : '_'}</span>,
                sortType: sortBy,
            },
            {
                // Header: () => <span style={assignObj({ color: '#49494A' })}>{quantityHeading}</span>,
                Header: 'Quantity',
                accessor: 'quantity',
                width: '10.5%',
                disableSortBy: true,
                disableFilters: true,
                Cell: c => <span>{c.value ? c.value : '_'}</span>,
            },
            {
                Header: 'Price',
                accessor: 'price',
                width: '12.5%',
                canSort: true,
                disableFilters: true,
                Cell: c => <span>{c.value ? `$${parseFloat(c.value).toFixed(2)}` : '_'}</span>,
            },
            {
                Header: 'Value',
                accessor: 'value',
                width: '12.5%',
                canSort: true,
                Cell: c => <span>{c.value ? c.value : '_'}</span>,
            },
            {
                Header: 'Transaction Status',
                accessor: 'status',
                width: '12.5%',
                disableFilters: true,
                Cell: cellProps => {                   
                    const cancelTxt = 'Cancel';
                    const { value, row } = cellProps;
                    const cancelStyle = {
                        width: 20,
                        height: 20,
                        opacity: 1,
                        marginTop: -3,
                    };
                    const id = `cancel-transaction-${row.id}`;

                    const cancel = () => {
                        cancelTransaction(row.original);
                    };


                    const cancelInFista = ()=>
                    {
                        cancelTransactionInFista(row.original)
                    }


                    const limitCancelDateTime = new Date(`${row.original.effectiveDate} 15:59:00`);

                    const startTime = moment.tz('America/New_York').format('MM/DD/YYYY HH:mm:ss');
                    const endTime = moment(limitCancelDateTime).format('MM/DD/YYYY HH:mm:ss');
                    const durationMinutes = moment(endTime).diff(moment(startTime), 'minutes', true);
                    if (
                        value.toUpperCase() === 'PENDING' &&
                       // row.original.isUserCreated !== 'false' &&
                        Math.round(durationMinutes) > 0
                    ){
                        
                        let params  = getFisTokenWithExpiry();
                        let operator = "";
                        if(params !=null && params !="")
                        {
                            operator = params.userName.toUpperCase();
                        }
                       
                        return (
                            <E.Status className="transactionStatus">
                            {(row.original.operatorId.toUpperCase() == operator && row.original.operatorId !="")  ? (
                                <E.CancelButton onClick={cancel} className="cancelTransaction" >
                                    <WIcon
                                        src={cancelButton}
                                        data-testid="message-icon-component"
                                        id={id}
                                        alt="cancel transactions"
                                        fontawesomestyle={cancelStyle}
                                    />
                                    {cancelTxt}
                                </E.CancelButton> 
                            ) : (
                                
                                <E.CancelButton onClick={cancelInFista} className="cancelTransaction" >
                                    {/* <WIcon
                                        src={cancelButton}
                                        data-testid="message-icon-component"
                                        id={id}
                                        alt="cancel transactions"
                                        fontawesomestyle={cancelStyle}
                                    /> */}
                                    {CANCEL_IN_FISTA}
                                </E.CancelButton> 
                            ) }
                                


                                <span className={value.toUpperCase() === 'PENDING' ? 'pendingStatus' : ''}>
                                    {value}
                                </span>
                            </E.Status>
                        );
                    }

                    return (
                        <div>
                            <span>{value}</span>
                        </div>
                    );
                },
            },
        ],
        [dateHeading, fundHeading, p, quantityHeading, rectangle, tickerHeading, sortBy, cancelTransaction,cancelTransactionInFista],
    );
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);
    const determineAx = (cell) =>{

        const ax = {header:'',value:''} ;

        try{
            const {column:{Header} , value} = cell;
            ax.header = Header
            ax.value = value

        }catch(error){
            ax.header = ''
            ax.value = ''
        }
      return ax;
    }

    return (
        <>
            <table
                tabIndex ='0'
                aria-label='Your account activity table'
                {...getTableProps()}
                className="table table-striped mb-0 acntActivityTable"
                style={assignObj({ borderBottom: '1px solid #d2d2d2' })}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={assignObj({ color: '#004A98', ...th })}
                                    sortable={i === 3 ? 'true' : 'false'}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (
                                            <img src={SortIndicatorUpArrow} alt="sort_indicator" />
                                        ) : column.disableSortBy ? (
                                            ''
                                        ) : (
                                            <img src={SortIndicator} alt="sort_indicator" />
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.length > 0 &&
                        rows.map((row,rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr tabIndex='0' aria-label={`row ${rowIndex} of ${rows.length}`} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                       
                                       const {header , value} =  determineAx(cell);
                                        return (
                                            <td tabIndex='0' aria-label={`${header}-${value}`} {...cell.getCellProps()} style={td}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {rows.length === 0 && <E.NoRecords>{ACC.NO_RECORDS_FOUND}</E.NoRecords>}
        </>
    );
};

MyReactTable.defaultProps = {
    tabelData: [],

};

MyReactTable.propTypes = {
    tabelData: PropTypes.arrayOf(
        PropTypes.shape({
            ticker: PropTypes.string,
            fundName: PropTypes.string,
            trandesc: PropTypes.string,
            establisheddate: PropTypes.string,
            quantity: PropTypes.string,
            price: PropTypes.string,
            value: PropTypes.string,
            status: PropTypes.string,
            companynumber: PropTypes.string,
            batchnumber: PropTypes.string,
            sequencenumber: PropTypes.string,
        }),
    ),
    
};

export default MyReactTable;
