/**
 * Terms And Conditions Component
 ****************************************
 * 
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to view terms and conditions
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CommonButtons, WInputCheckBox, WIcon, WStepper } from 'common';
// import routeConstants from 'routeConstants';

import './JovTermsAndConditions.css';
import Consts from './Consts';
import pages from '../JovConstants';


export default class JovTermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            termsAndConditionsConsent: false,
            termsSelected: true
        };
    }

    componentDidMount() {
        // setTimeout(() => {
        //  this.setState({loading: false});          
        // },500);
        const { localStateData } = this.props;
        if (localStateData.termsAndConditionsConsent !== undefined) {
            this.setState({ termsAndConditionsConsent: localStateData.termsAndConditionsConsent })
        }
    }


    handleNext = () => {
        const { termsAndConditionsConsent } = this.state;
        if (termsAndConditionsConsent) {
            this.setState({ next: true })
            this.saveToLocal()
        } else if (!termsAndConditionsConsent) {
            this.setState({ termsSelected: false });
        }
    }

    handleBack = () => {
        const { history } = this.props
        history.push('/joint-owner-verification-ssn');
    }

    saveToLocal = () => {
        const { manageLocalState } = this.props;
        const { termsAndConditionsConsent } = this.state;
        const data = {
            onlineId: "aaru",
            customerId: "123456",
            accountType: "Individual",
            termsAndConditionsConsent
        }
        manageLocalState(data);
    }

    handleChange = (e) => {
        if (e.target.checked) {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: true });
        } else {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: false });
        }
    }

    render() {
        const { heading, desc1, desc2, desc3, subheading, listItems, list1, list2, list3, checkBoxLabel, iconStyle
            , faStyle, checkBoxLabelStyle, checkBoxWrapperStyle, checkInputsStyle, commonButtonStyle, redirectTo } = Consts;
        const { next, termsAndConditionsConsent, termsSelected } = this.state;
        return (
            <>
                <div>
                    <div className="terms-wrapper">
                        <div className="container">
                            <WStepper className="userWizard" currentPage={1} pages={pages} />
                            <Row noGutters> <h1 className="terms-heading" >{heading}</h1> </Row>
                            <div className="terms-content" >
                                <p className="terms-desc" ><span>{desc1}</span><span className="boldText">{desc2}</span><span>{desc3}</span></p>
                                <div className="list-terms" >
                                    <h2 className="terms-subheading">{subheading}</h2>
                                    <ul className="terms-points">
                                        <li className="term-list" key="stactic">
                                            <WIcon icon={faCheck} style={iconStyle} size="sm" fontawesomestyle={faStyle} />
                                            <span>{list1}</span><span className="boldText2">{list2}</span><span>{list3}</span>
                                        </li>
                                        {
                                            listItems.map((item) => (
                                                <li key={item} className="term-list">
                                                    <WIcon icon={faCheck} style={iconStyle} size="sm" fontawesomestyle={faStyle} />
                                                    {item}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="check-terms">
                                    <Row className="check-content" noGutters>
                                        <WInputCheckBox
                                            id="termsConsent"
                                            checked={termsAndConditionsConsent}
                                            onChange={this.handleChange}
                                            labelstyle={checkBoxLabelStyle}
                                            checkboxwrapperstyle={checkBoxWrapperStyle}
                                            checkInputStyle={checkInputsStyle}
                                            ariadescribedby="termsConsentError"
                                            label={checkBoxLabel} />
                                    </Row>
                                </div>
                                {!termsSelected && <span className="error-msg" aria-live="polite" id="termsConsentError">{Consts.radioError}</span>}
                            </div>
                        </div>
                        <CommonButtons nextClick={this.handleNext} style={commonButtonStyle} backClick={this.handleBack} disabled={!termsAndConditionsConsent} arialabelback="Back to SSN"/>
                        {next ? <Redirect
                            to={redirectTo}
                        /> : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}

JovTermsAndConditions.propTypes = {
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func
}

JovTermsAndConditions.defaultProps = {
    history: [],
    localStateData: {},
    manageLocalState: () => { }
}