
const switchContainer = {
    width: '25%',
    height: '42px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1,
    marginLeft: '75px'
}
const buttonStyle = {
    borderColor: '#56565A',
    backgroundColor: '#B7B7B7',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
    height: '40px',
    background: '#818181DD 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1
}
const buttonStyleDisable = {
    borderColor: '#56565A',
    borderRadius: 30,
    borderWidth: 1,
    width: '50%',
    height: '40px',
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
}
const TextOnStyle = {
    color: '#56565A',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%'
}
const TextOffStyle = {
    color: '#A2A2A2',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%'
}

const switchMargin={
    marginTop:"20px",
    marginBottom: 50
}

const subHeading = {
    font: '600 16px/19px benton-sans',
    color: '#49494A'
}

const nextStyle = { display: 'none' }

export default {
    switchContainer,
    buttonStyle,
    TextOffStyle,
    TextOnStyle,
    buttonStyleDisable,
    switchMargin,
    subHeading,
    nextStyle
}

