import { connect } from "react-redux";
import { withRouter } from "react-router";
import { loginActions, localStateManagementActions, analytics } from "../../../shared/Actions";
import RetrievePassword from './RetrievePasswordComponent';

export const mapStateToProps = (state) => {
    return {
        userDetails : state.loginData.memberOrGuest,
        recoverPwdCustomerData : state.loginData.recoverPwdCustomer,
        recoverPasswordGuestData : state.loginData.recoverPasswordGuest,
        isLoading: state.loginData.isLoading,
        otpDetails: state.localStateManagementReducerData.otpDetails
    };
};

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions,
    ...analytics
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RetrievePassword));