import envConfig from "env/EnvConfig";
import axios from 'axios';

const getApiInstance = () =>
{
    let instance = axios.create({});
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['vcmid'];
    delete instance.defaults.headers.common['msrid'];
    delete instance.defaults.headers.common['fismemberid'];
    return instance;
}

const getInvestorUrl = () => {
    let modifiedUrl = envConfig.HOST
    modifiedUrl = modifiedUrl.replace('investorapi','investor')
    return modifiedUrl
}

const loadAEMHtmlData = async (url) => {
    let apiStatus = 0;
    const instance = getApiInstance();
    
    return await instance
        .get(url, { headers: { Authorization: `${envConfig.PRELOGIN_HEADER_AUTH}` } })
        .then(response => {
            apiStatus = response.status;

            if (response) {
                return response.data;
            }
            
            throw new Error('Error message.');
        })
        .then(data => {
            let result = data.toString();

            let investorUrlString = getInvestorUrl()
     
            result = result.replace(/src="\//g, `src="${envConfig.ENV_URL}/`);
            result = result.replace(/href="\//g, `href="${envConfig.ENV_URL}/`);
            result = result.replace(/value="\/content/g, `value="${envConfig.ENV_URL}/content`);
            result = result.replace(`src="https:\/\/${investorUrlString}\/\/assets.adobedtm.com`, `src="https://assets.adobedtm.com`);
            result = result.replace(`src="${investorUrlString}\/etc.clientlibs\/vcm\/clientlibs\/jquery.mCustomScrollbar.min.js.map`, `src=""`);
            result = result.replace(`src="${investorUrlString}\/etc.clientlibs\/vcm\/clientlibs\/jquery.mCustomScrollbar.min.js`, `src=""`);
            
            return {
                result,
                apiStatus
            };
        })
        .catch(err => {
            return {
                apiStatus:0
            }
        });
};



const hideDomElements = () =>{
    let hideClassNames = ['breadcrumb aem-GridColumn aem-GridColumn--default--12','experiencefragment aem-GridColumn aem-GridColumn--default--12']

    let headerElement = document.getElementsByClassName("header aem-GridColumn aem-GridColumn--default--12")
    if(headerElement){
        let elements = headerElement[0]?.parentElement?.parentElement?.parentElement?.parentElement?.children

        let data = [].forEach.call(elements, elem => {

            if(hideClassNames.includes(elem.className)){
                elem.style.display = "none"
            }
           
        });
    }
   

    let footerElement = document.getElementsByClassName("footer aem-GridColumn aem-GridColumn--default--12")[0]
    if(footerElement){
        footerElement.parentElement.parentElement.parentElement.style.display = 'none'
    }

    document.getElementsByClassName("fix-container")[0].style.display = "contents"
    document.getElementsByClassName("mf-buy-btn")[0].style.display = "none"
    //document.getElementsByClassName("desclaimer")[0].style.display = "none"
}

const generatePageUrl = (selectedFund) => {
    let pageURL = selectedFund?.fundName.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-").replace(/\//g,"-");
    if (pageURL && pageURL.includes("usaa-500-index-")) {
        pageURL += '-shares';
    }
    return pageURL;
}

const hidePostLoginFooterDisclosure = (hideFooter) => {
    if(hideFooter){
        document.getElementById("fund-disclosure-footer").style.display = "none"
    }else{
        document.getElementById("fund-disclosure-footer").style.display = "block"
    }
}

export {
    loadAEMHtmlData,
    hideDomElements,
    generatePageUrl,
    hidePostLoginFooterDisclosure
}
