import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PersonalInfoComponent from './PersonalInfoComponent';
import {
    accOpeningActions,
    localStateManagementActions,
    loginActions,
    profileSettingsAction,
} from '../../../shared/Actions';
import withAccountManagement from '../AccountManagementHOC';

export const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.loginData,
    localStateData: state.localStateManagementReducerData,
    masterLookupStateData: state.masterLookUpData,
    profileInformationData: state.profileInformationData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...loginActions,
    ...profileSettingsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(PersonalInfoComponent)));
