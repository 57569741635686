/* eslint-disable import/no-cycle */
import ApiEventHub from './ApiEventHub';
import { LiveChatActionTypes } from '../ReduxConstants';
import { get, post, getWithoutToken, setAuthHeaders, removeAPIVersionHeader } from './Services';
import Config from 'env/Config';

const VendorApiEvents = () => {
    ApiEventHub.on(LiveChatActionTypes.GET_LIVECHAT_QUEUE_WAITTIME, data => {
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        get(
            Config.getLiveChatQueueWaitTime,
            data.payload.data,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_PHONE_QUEUE_WAITTIME, data => {
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        get(
            Config.getPhoneQueueWaitTime,
            data.payload.data,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_LIVECHAT_QUEUE_METRICS, data => {
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        post(
            Config.getLiveChatQueueMetrics,
            '',
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_LIVECHAT_QUEUES_STATUS, data => {
        const getLiveChatQueuesStatusPath = Config.getLiveChatQueueMetrics;
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        post(
            getLiveChatQueuesStatusPath,
            '',
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION, data => {
        getWithoutToken(
            Config.getLiveChatSupportHoursOfOperation,
            data.payload,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_PHONE_SUPPORT_HOURS_OF_OPERATION, data => {
        removeAPIVersionHeader()
        getWithoutToken(
            Config.getPhoneSupportHoursOfOperation,
            data.payload,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(LiveChatActionTypes.GET_MSR_AVAILABILITY, data => {
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        get(
            Config.msrAvailability,
            data.payload.data,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
            );
        });

        ApiEventHub.on(LiveChatActionTypes.GET_MSR_AVAILABILITY_FROM_SCHEDULES, data => {
        setAuthHeaders(data.payload.token, Config.liveChatApiKey);
        get(
            Config.getMsrAvailabilityFromSchedules,
            data.payload.data,
            Config.API_BASE_URL.CHAT_API,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default VendorApiEvents;
