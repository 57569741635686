import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
    WBreadCrumb,
    ConformationModelCancel,
    WSpinner,
    WMessageBar,
    VCMSelectWithoutLabel,
    VictoryDateRange,
    ConfirmModal
} from '../../../common';
import SideHeader from '../Common/SideHeaderMain/SideHeader';
import BackNavigation from '../Common/BackNavigation/BackNavigation';
import leftArrowGreen from '../../../assets/leftArrow_green.png';
import '../Common/Styles.css';
import iconDown from '../../../assets/Icon_Down.png';
import MyReactTable from './MyReactTable';
import { ACC, CANCEL_TRANSACTION_DESCRIPTION,CANCEL_IN_FISTA_TITLE,CANCEL_IN_FISTA_MESSAGE } from './Constants';
import { isValidDate } from '../../../utils';
import { isTokenAvailable} from '../../../common/Authenticate/session'
import Authenticate from '../../../common/Authenticate/Authenticate'
import { getSessionValues } from 'shared/Helpers/Utils';
import { LocalStorageHelper } from 'utils/sessionHelper';

const C = {
    breadCrumbValues: [{ name: 'Home', url: '/accountholder' }],
    pageName: 'Account Activity',
    backToPort: 'Back to Account',
    url: '/accountholder/transactions',
    loadmore: 'Load More',
    paramsRequired: true,
};

const ImgSrc = styled.img`
    height: 20px;
    width: 20px;
`;

const Footer = styled.div`
    cursor: pointer;
`;
const ColEnd = styled(Col)`
    align-self: flex-end;
`;
const StyledLabelCol = styled(ColEnd)`
    padding-right: 5px;
    padding-left: 0px;
    height: 38px;
    display: flex;
    align-items: center;
`;
const SelectDocLabel = styled.label`
    margin: 0px;
    text-align: left;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    height: 22px;
`;

const smObj = { span: 0, offset: 0 };
const assignObj = obj => obj;
const dateRange = ['Last 1 - Year', 'Last 1 - Month', 'Last 1 - Week', 'Custom'];
const dateFormatText = 'MM/DD/YYYY';
const dateRangeText = 'Date Range';
class TransactionActivity extends Component {
    constructor(props) {
        super(props);
        const { masterId = 0, accountName = '' } = this.getMasterRegId();

        this.state = {
            breadCrumb: [
                { name: 'Home', url: '/accountholder' },
                {
                    name: accountName,
                    url: '/accountholder/transactions',
                    payload: { masterId },
                },
            ],
            showModal: false,
            showModelInFista : false,
            showPromptModal: false,
            showCustomDateRange: false,
            applyFilterDisabled: true,
            pendingTransaction: {},
            filterStartDate: '',
            filterEndDate: '',
            activitiesData: [],
            accountName: '',
            cancelTransactionMsgBody: '',
            nickName: '',
            username: '',
            password: '',
        };

        this.loadMore = this.loadMore.bind(this);
        this.confirmCancel = this.confirmCancel.bind(this);
        this.closeTransactionModal = this.closeTransactionModal.bind(this);
        this.showCancelTransactionModal = this.showCancelTransactionModal.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.transactionActivityData.activities !== prevState?.transactionActivityData?.activities) {
            const { transactionActivityData } = nextProps;
            const { activities } = transactionActivityData;

            return { activitiesData: activities };
        }
        return null;
    }

    componentDidMount() {
        document.title = 'Account holder activity| Victory Capital';
        const { getAccountActivities } = this.props;
        const { masterId = 0, accountName } = this.getMasterRegId();
        const payload = {
            companyNumber: '591',
            masterRegId: masterId.toString(),
            fromDate: moment()
                .subtract(1, 'months')
                .format(dateFormatText),
            // Added 4 days to current day
            toDate: moment()
                .add(4, 'days')
                .format(dateFormatText),
        };
        this.setState({ accountName });
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues() ;
        const { accesstoken, vcmid } = params;
        getAccountActivities({ payload, token: accesstoken, vcmid });
    }

    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            const updatedState = {...this.state}
            const { transactionActivityData } = this.props
          //  const { selectedVal } = this.state
            const { activities } = transactionActivityData
           if(transactionActivityData && transactionActivityData.activities) {
                updatedState.activitiesData = activities
                updatedState.transData = activities
            }
            this.updateStateFn(updatedState);
            
        }
    }

    updateTransHistory = () => {
        const { transactionActivityData } = this.props
        const { activities } = transactionActivityData
        const stateData = this.state
        const updatedState = { ...stateData }
        if(transactionActivityData && transactionActivityData.activities) {
            updatedState.activitiesData = activities
        }
        return updatedState
    }

    updateTransFilter = () => {
        const { transFilter } = this.props
        const stateData = this.state
        const updatedState = { ...stateData }
        const tempArr = []
        if(transFilter && transFilter.value && transFilter.value.length > 0) {
            transFilter.value.map(el => tempArr.push(el.key))
            updatedState.transDescVal = tempArr
        }
        return updatedState
    }

    updateStateFn =  (updatedState) => {
        this.setState(prevState=>({...prevState,...updatedState}))
    }
    

    getMasterRegId() {
        const { location } = this.props;
        let { masterRegId = 0, name = '' } = location;
        if (masterRegId !== 0) {
            localStorage.setItem('masterId', masterRegId);
            localStorage.setItem('accountName', name);
        } else if (masterRegId === 0) {
            masterRegId = JSON.parse(LocalStorageHelper.getItem('accountInfo')).masterId ? JSON.parse(LocalStorageHelper.getItem('accountInfo')).masterId : masterRegId
            name = JSON.parse(LocalStorageHelper.getItem('accountInfo')).accountName ? JSON.parse(LocalStorageHelper.getItem('accountInfo')).accountName : name;
        }

        return { masterId: masterRegId, accountName: name };
    }
//onclick of confirmation poup yes button

    showUserIDPasswordPopUp = ()=>
    {
        if(isTokenAvailable() == null)
        {
            this.setState({ showModal: false, showPromptModal: true });
        }
        else
        {
            this.setState({showModal: false});
            this.confirmCancel();
        }
    }

    inFistaOkClick = ()=>
    {
        this.setState({ showModelInFista: false });
    }


    confirmCancel =  () => {
        const { cancelTransaction } = this.props;
        const { pendingTransaction } = this.state;
        cancelTransaction(pendingTransaction);
        this.setState({ showModal: false,showPromptModal: false });
    };

    showCancelTransactionModal = transaction => {
        let transactionType = 'Purchase';
        const transactionDescription = transaction.tranDesc && transaction.tranDesc.toLowerCase().trim();
        const transactionValue = transaction.value.replace('-', '');
        if (transactionDescription.includes('purchase') || transactionDescription.includes('employer contribution')) {
            transactionType = 'Purchase';
        }
        if (transactionDescription.includes('check redemption') || transactionDescription.includes('ach redemption')) {
            transactionType = 'Liquidation';
        }
        if (transactionDescription.includes('exchange')) {
            transactionType = 'Exchange';
        }
        const cancelTransactionMsg = `${CANCEL_TRANSACTION_DESCRIPTION} (${transactionType} of ${transactionValue} of ${transaction.fundName})?`;
        this.setState({
            showModal: true,
            pendingTransaction: transaction,
            cancelTransactionMsgBody: cancelTransactionMsg,
        });
    };

    showCancelTransactionModalInFista = transaction =>{
        let transactionType = 'Purchase';
        const transactionValue = transaction.value.replace('-', '');
        const cancelTransactionMsg = `${CANCEL_TRANSACTION_DESCRIPTION} (${transactionType} of ${transactionValue} of ${transaction.fundName})?`;
        this.setState({
            showModelInFista: true,
            pendingTransaction: transaction,
            cancelTransactionMsgBody: cancelTransactionMsg,
        });

    }

    closeTransactionModal = () => {
        this.setState({ showModal: false });
    };

    closePromptModal = () => {
        this.setState({ showPromptModal: false });
    };

    onSortBy = e => {
        const { getAccountActivities } = this.props;
        const { masterId = 0 } = this.getMasterRegId();

        if (+e.target.value !== undefined) {
            const payload = {
                companyNumber: '591',
                masterRegId: masterId.toString(),
                fromDate: moment()
                    .subtract(1, 'months')
                    .format(dateFormatText),
                toDate: moment()
                    .add(4, 'days')
                    .format(dateFormatText),
            };
            let showCustomDateRange = false;
            switch (+e.target.value) {
                case 0:
                    // Last 1 - Year
                    payload.fromDate = moment()
                        .subtract(1, 'years')
                        .format(dateFormatText);
                    break;
                case 1:
                    // Last 1 - Month
                    payload.fromDate = moment()
                        .subtract(1, 'months')
                        .format(dateFormatText);
                    break;
                case 2:
                    // Last 1 - Week
                    payload.fromDate = moment()
                        .subtract(1, 'weeks')
                        .format(dateFormatText);
                    break;
                case 3:
                    // Custom
                    showCustomDateRange = true;
                    break;
                default:
                // default
            }
            this.setState(prevState => {
                const { filterStartDate, filterEndDate } = prevState;
                return {
                    showCustomDateRange,
                    filterStartDate: showCustomDateRange ? filterStartDate : '',
                    filterEndDate: showCustomDateRange ? filterEndDate : '',
                };
            });

            // Make API call with payload data
            if (!showCustomDateRange) {
                //const msrInfo = sessionStorage.getItem("msr_dts");
                const params = getSessionValues() ;
                const { accesstoken, vcmid } = params;
                getAccountActivities({ payload, token: accesstoken, vcmid });
            }
        }
    };

    onFilterDateChange = (dateValue, id) => {
        const formatedDate = moment(dateValue).format(dateFormatText);
        this.setState(prevState => {
            let { filterStartDate, filterEndDate } = prevState;

            if (id === 'filterStartDate') {
                filterStartDate = formatedDate;

                if (
                    filterStartDate &&
                    (!isValidDate(filterStartDate) ||
                        (isValidDate(filterEndDate) && new Date(filterStartDate) > new Date(filterEndDate)))
                ) {
                    filterEndDate = '';
                }
            } else if (id === 'filterEndDate') {
                filterEndDate = formatedDate;
                if (
                    filterEndDate &&
                    (!isValidDate(filterEndDate) ||
                        (isValidDate(filterStartDate) && new Date(filterStartDate) > new Date(filterEndDate)))
                ) {
                    filterStartDate = '';
                }
            }

            return {
                filterStartDate,
                filterEndDate,
                applyFilterDisabled: !(isValidDate(filterStartDate) && isValidDate(filterEndDate)),
            };
        });
    };

    onApplyFilter = () => {
        const { filterStartDate, filterEndDate } = this.state;
        const { getAccountActivities } = this.props;
        const { masterId = 0 } = this.getMasterRegId();

        if (
            filterStartDate !== '' &&
            filterStartDate !== 'Error' &&
            filterEndDate !== '' &&
            filterEndDate !== 'Error'
        ) {
            const payload = {
                companyNumber: '591',
                masterRegId: masterId.toString(),
                fromDate: filterStartDate,
                toDate: filterEndDate,
            };
            //const msrInfo = sessionStorage.getItem("msr_dts");
            
            const params = getSessionValues()
            const { accesstoken, vcmid } = params;
            getAccountActivities({ payload, token: accesstoken, vcmid });
        }
    };

    formateRedemptionValues = data => {
        return data.map(item => {
            const temp = { ...item };
            if (item.tranDesc.includes('REDEMPTION') || item.tranDesc.includes('WITHDRAWAL')) {
                if (item.value) {
                    temp.value = `-$${item.value}`;
                }
                if (item.quantity) {
                    temp.quantity = `-${item.quantity}`;
                }
            } else {
                temp.value = `$${item.value}`;
            }
            return temp;
        });
    };

    loadMore() {
        const { loadMoreActivities } = this.props;
        loadMoreActivities();
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        const { pageName, backToPort, url, loadmore, paramsRequired } = C;
        const {
            breadCrumb,
            showModal,
            showPromptModal,
            filterStartDate,
            filterEndDate,
            showCustomDateRange,
            applyFilterDisabled,
            accountName,
            cancelTransactionMsgBody,
            showModelInFista,
        } = this.state;

        let { activitiesData } = this.state;

        if (activitiesData) {
            activitiesData = this.formateRedemptionValues(activitiesData);
        }

        const { transactionActivityData } = this.props;
        const { nextPage, isPageLoading, showMessage, message } = transactionActivityData;

        const backButtonPayload = this.getMasterRegId();
        const successMessage = () => {
            return { message };
        };

        return (
            <div className="borderDesign">
                {isPageLoading && <WSpinner loading={isPageLoading} />}
                {showMessage && <WMessageBar text={successMessage()} />}
                <div className="container">
                    <div className="transContainer">
                        <WBreadCrumb
                            breadCrumbItems={breadCrumb}
                            activeCrumb="Account Activity"
                            paramsRequired={paramsRequired}
                        />
                        <SideHeader title={pageName} headStyles={assignObj({ display: 'inline-block' })} />
                        <br />
                        <Row>
                            <Col xs={4}>
                                <StyledLabelCol sm={smObj}>
                                    <SelectDocLabel>{dateRangeText}</SelectDocLabel>
                                </StyledLabelCol>

                                <VCMSelectWithoutLabel
                                    inputclassname="dateRangeClass"
                                    itemlist={dateRange}
                                    onChange={this.onSortBy}
                                    value={'1'}
                                />
                            </Col>
                        </Row>
                        <br />
                        {showCustomDateRange && (
                            <Row>
                                <Col sm={12}>
                                    <VictoryDateRange
                                        onFilterDateChange={this.onFilterDateChange}
                                        filterStartDate={filterStartDate}
                                        filterEndDate={filterEndDate}
                                        onApplyFilter={this.onApplyFilter}
                                        applyFilterDisabled={applyFilterDisabled}
                                        dateRangeText
                                    />
                                   
                                </Col>
                            </Row>
                        )}
                        <br />
                        <Row className="transActivity">
                            <Col>
                                <MyReactTable
                                    tabelData={activitiesData}
                                    cancelTransaction={this.showCancelTransactionModal}
                                    cancelTransactionInFista={this.showCancelTransactionModalInFista}
                                />
                                {showModal && (
                                    <ConformationModelCancel
                                        modalTitle={ACC.CANCEL_POPUP.TITLE}
                                        modalBodyText={cancelTransactionMsgBody}
                                        secondaryButtonText={ACC.CANCEL_POPUP.SECONDARY_BUTTON}
                                        onPrimaryClick={this.showUserIDPasswordPopUp}
                                        onSecondaryClick={this.closeTransactionModal}
                                        primaryButtonText={ACC.CANCEL_POPUP.PRIMARY_BUTTON}
                                    />
                                )}

                                {showModelInFista && (
                                    <ConfirmModal
                                        modalTitle={CANCEL_IN_FISTA_TITLE}
                                        modalBodyText={CANCEL_IN_FISTA_MESSAGE}
                                        onPrimaryClick={this.inFistaOkClick}
                                        primaryButtonText={"OK"}
                                    />
                                )}


                                { showPromptModal && <Authenticate showAuthBox={this.closePromptModal} moveNextPage={this.confirmCancel} />}
                                {/* TODO: */}
                                
                            </Col>
                        </Row>
                        {nextPage && (
                            <Row>
                                <Footer
                                    className="mt-4"
                                    style={assignObj({ margin: 'auto' })}
                                    role="button"
                                    tabIndex="0"
                                    onClick={this.loadMore}
                                    onKeyPress={this.loadMore}
                                >
                                    {' '}
                                    <span
                                        style={assignObj({
                                            fontWeight: '800',
                                            fontSize: '16px',
                                            color: '#004A98',
                                            outline: 'none',
                                        })}
                                        role="button"
                                        tabIndex="0"
                                    >
                                        {loadmore}
                                    </span>
                                    <ImgSrc className="ml-2" src={iconDown} alt="downarrow" />
                                </Footer>{' '}
                            </Row>
                        )}
                    </div>
                </div>
                <div
                    className="container-fluid"
                    style={assignObj({ backgroundColor: '#FAFAFA', borderTop: '1px solid #D2D2D2' })}
                >
                    <div className="container pt-4 pb-4">
                        <BackNavigation
                            text={backToPort}
                            url={url}
                            icon={leftArrowGreen}
                            pageFrom={accountName}
                            payLoad={backButtonPayload}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

TransactionActivity.propTypes = {
    getAccountActivities: PropTypes.func,
    loadMoreActivities: PropTypes.func,
    cancelTransaction: PropTypes.func,
    location: PropTypes.shape({
        masterRegId: PropTypes.string,
        name: PropTypes.string,
    }),
    transactionActivityData: PropTypes.instanceOf(Object),
};
TransactionActivity.defaultProps = {
    location: {},
    getAccountActivities: () => { },
    loadMoreActivities: () => { },
    cancelTransaction: () => { },
    transactionActivityData: {},
};

export default TransactionActivity;
