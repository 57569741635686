// CONSTANTS
const PAGES = [
    "One Time Passcode Authentication",
    "Confirm One Time Passcode",
    "Setup Security Questions",
    "Personal Details",
    "Manage Address",
];

const PAGE_HEADING = "Manage Address Information";

const LINE1_DESCRIPTION =
    "We need a few minutes of your time to verify your information so that we can contact you";
    
const ADDRESS_TYPE = 'Address Type';
const ADDRESS_TYPE_ERROR = 'Please select Address Type';
const STEP_ONE = 'Can you choose your Mailing and Physical address from the below list of addresses from your profile?';
const STEP_TWO = 'We are trying to match your selected addresses with the records of the USPS';
const SUCCESS_MSG = 'USPS verification successfully completed.';
const INSTRUCTION_MSG = 'Great! You have set above addresses as your Physical & Mailing Address.';
const CHECK_BOX_TEXT = 'Apply this change of address to all of my accounts.';
const NEW_ADDRESS_LINK_TEXT = "I don't see my address in this list.";
const CANCEL = 'Cancel';
const SAVE = 'Save';
const USPS_ERROR = 'USPS verification failed.'
const USPS_ERROR_PHY_ADDR = 'USPS verification failed for Physical Address.';
const USPS_ERROR_MAIL_ADDR = 'USPS verification failed for Mailing Address.';
const ADD_ADDRESS = 'Add New Address';
const ADD_ANOTHER_ADDRESS = 'Add Another Address';
const NEW_ADDRESS = "New Address";
const DONE = 'Done';


export {
    DONE,
    PAGES,
    PAGE_HEADING,
    LINE1_DESCRIPTION,
    ADDRESS_TYPE,
    ADDRESS_TYPE_ERROR,
    STEP_ONE,
    STEP_TWO,
    SUCCESS_MSG,
    INSTRUCTION_MSG,
    CHECK_BOX_TEXT,
    NEW_ADDRESS_LINK_TEXT,
    CANCEL,
    SAVE,
    USPS_ERROR,
    USPS_ERROR_PHY_ADDR,
    USPS_ERROR_MAIL_ADDR,
    ADD_ADDRESS,
    ADD_ANOTHER_ADDRESS,
    NEW_ADDRESS
};