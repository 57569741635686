import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Label from '../Label';

export const StyledRow = styled(Row)`
    margin-bottom: ${props => props['data-mb']}rem;
    margin-top: ${props => props['data-mt']}rem;
    align-items: ${props => props['data-alignitems']};
`;

const FieldValue = props => {
    const { label, labelsm, valuesm, noGutters, value, mb, mt, alignItems, ...labelProps } = props;
    return (
        <StyledRow noGutters={noGutters} data-mb={mb} data-mt={mt} data-alignitems={alignItems}>
            <Col sm={labelsm}>
                <Label {...labelProps} >{label}</Label>
            </Col>
            <Col sm={valuesm}>{value}</Col>
        </StyledRow>
    );
};

FieldValue.propTypes = {
    value: PropTypes.instanceOf(Object),
    label: PropTypes.string,
    labelsm: PropTypes.number,
    valuesm: PropTypes.number,
    noGutters: PropTypes.bool,
    mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignItems: PropTypes.string,
};

FieldValue.defaultProps = {
    value: null,
    label: '',
    labelsm: 2,
    valuesm: 4,
    noGutters: false,
    mb: 0.125,
    mt: 0,
    alignItems: 'center',
};

export default FieldValue;
