import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccountImg from 'assets/account.png';
import AccordianSymbol from '../AccordionSymbol';
import DollarBillIcon from 'assets/icon-account-info-big.svg';

const VerticalSeperator = styled.div`
    border-left: 1px solid #cecece;
    margin-left: 1em;
    margin-right: 1em;
`;

const FlexDiv = styled.div`
    display: flex;
`;
const AccountTitleSection = styled(FlexDiv)`
    position: relative;
    margin: 0.875rem 2.4375rem;
    justify-content: space-between;
    opacity: 1;
    align-items: center;
    cursor: pointer;
`;
const AccountNameAndNumber = styled(FlexDiv)`
    position: relative;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;
const CardProfileSymbol = styled.img`
    margin-right: 0.625rem;
    margin-left: 0.9375rem;
`;
const SelfCenteredDiv = styled.div`
    align-self: center;
`;

const AddedAccountTitle = props => {
    const { accName, accNumber, children, showImg, showDollarBillIcon,divider } = props;
    const label = `Account Name ${accName} with Account Number ${accNumber}`
    let src = '';
    if(showDollarBillIcon){
        src = DollarBillIcon;
    }
    else if(showImg) {
        src = AccountImg;
    }
    const isDivider=divider?<VerticalSeperator />:null
    return (
        <AccountTitleSection>
            <FlexDiv>
                <AccordianSymbol aria-label={label} />
                <CardProfileSymbol src={src} alt="" />
                <AccountNameAndNumber>
                    <SelfCenteredDiv>{accName}</SelfCenteredDiv>
                    {showImg ? '' : isDivider}
                    <SelfCenteredDiv>{accNumber}</SelfCenteredDiv>
                </AccountNameAndNumber>
            </FlexDiv>
            {children}
        </AccountTitleSection>
    );
};

AddedAccountTitle.propTypes = {
    accName: PropTypes.string,
    accNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
    showImg: PropTypes.bool,
    divider:PropTypes.bool,
};

AddedAccountTitle.defaultProps = {
    accName: '',
    showImg: false,
    accNumber: '',
    divider:true,
};

export default AddedAccountTitle;
