// import axios from 'axios';
import AWS from 'aws-sdk';
import buffer from 'buffer';


// this is from reducer
const getKMSClientAPI = (data)=> {    
    if(data && data.initialAppData && data.initialAppData.kmsData){
        const credentials = new AWS.Credentials(data.initialAppData.kmsData.accessKeyId, data.initialAppData.kmsData.secretAccessKey,data.initialAppData.kmsData.sessionToken);

        AWS.config.update({
            region: data.initialAppData.awsconfigRegion,
            credentials
        });
    }
    return new AWS.KMS();
};

export const encryptValueAPI = (inputString,props) => {

    // console.log("---props----->",props);
    const kms = getKMSClientAPI(props);
    let kmKeyId='';
    if(props && props.initialAppData && props.initialAppData.kmsKeyId){
        kmKeyId = props.initialAppData.kmsKeyId;
    }

    return new Promise((resolve, reject) => {
        const params = {
            KeyId: kmKeyId, 
            EncryptionAlgorithm: 'SYMMETRIC_DEFAULT',
            Plaintext: inputString
        };
        
        kms.encrypt(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
               const encResult = data.CiphertextBlob.toString("base64");
               resolve(encResult);
            }
        });
    });
};

export const decryptValueAPI = (inputString,props) => {
    const kms = getKMSClientAPI(props);
    let kmsKey='';
    if(props && props.initialAppData && props.initialAppData.kmsKeyId){
        kmsKey = props.initialAppData.kmsKeyId;
    }
    return new Promise((resolve, reject) => {
        const params = {
            KeyId: kmsKey, 
            EncryptionAlgorithm: 'SYMMETRIC_DEFAULT',
            CiphertextBlob: buffer.Buffer.from(inputString, 'base64'),
        };
        
        kms.decrypt(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
               const decResult = data.Plaintext.toString();
               resolve(decResult);
            }
        });
    });
};
// export default PasswordEncryption;
