export const MARKET_NAO_ACCOUNT_TYPES = {
    JOINT_ACCOUNT : "JTWROS",
    JOINT_ACCOUNT_FULL_FORM : "Joint Tenants with Rights of Survivorship"
  }

export const MARKET_NAO_PATHS = {
    OPEN_ACCOUNT:'/marketOpenAccount',
    ACCOUNT_TYPE:'/marketAccountType',
    PERSONAL_INFO:'/marketPersonalInfo',
    NAO_FLOW:'/marketNAOFlow',
    CONFIRMATION_PAGE:'/ConfirmationFile',
    UPLOAD_PENDING_DOCUMENTS:'/uploadPendingDocuments',
}