import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    checkBookInfo: [],
    isLoadingPostUpdateCheckbook: false,
    isErrorPostUpdateCheckbook: false,
    isSuccessPostUpdateCheckbook: false,
    error: null
};

function getCheckbookError(action) {
    const errorCode = action.error.response && JSON.parse(action.error.response.data.result.exceptionDetails.logMessage).errorCode;
    if(errorCode === '1133') {
        return `Failed to Re-order checkbook. Please order your first checkbook by calling  Victory Capital Management service Rep at 1-800-235-8396.`;
    } 
        return action.error.response && JSON.parse(action.error.response.data.result.exceptionDetails.logMessage).messages[0].msg || action.error.message;
    
}

function orderCheckBookReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_CHECKBOOK_INFO: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.GET_CHECKBOOK_INFO_LOADING: {
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
                isErrorPostUpdateCheckbook: false,
                error: null,
            };
        }
        case ActionTypes.GET_CHECKBOOK_INFO_SUCCESS: {
            return {
                ...state,
                checkBookInfo: action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        case ActionTypes.GET_CHECKBOOK_INFO_FAILURE: {
            return {
                ...state,
                error: action.response,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        case ActionTypes.UPDATE_CHECKBOOK_LOADING: {
            return {
                ...state,
                isLoadingPostUpdateCheckbook: true,
                isErrorPostUpdateCheckbook: false,
                isSuccessPostUpdateCheckbook: false,
                error: null,
            };
        }
        case ActionTypes.UPDATE_CHECKBOOK_SUCCESS: {
            return {
                ...state,
                isLoadingPostUpdateCheckbook: false,
                isErrorPostUpdateCheckbook: false,
                isSuccessPostUpdateCheckbook: true
            };
        }
        case ActionTypes.UPDATE_CHECKBOOK_FAILURE: {
            return {
                ...state,
                error: getCheckbookError(action),
                isLoadingPostUpdateCheckbook: false,
                isErrorPostUpdateCheckbook: true,
                isSuccessPostUpdateCheckbook: false
            };
        }

        default:
            return state;
    }
}

export default orderCheckBookReducer;
