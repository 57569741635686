import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import vcmLogo from 'assets/VCM-logo.png';
import userRouteConstants from '../../userRouteConstants';
import '../Screens.css';
import SignInForm from '../../SignInForm';
import RegisterCardComponent from '../../RegisterCard/RegisterCard';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import { WMessageBar, WSpinner } from '../../../../common';
import data from './Constants';
import getAttemptsMessage from './getAttemptsMessage';
import envConfig from 'env/EnvConfig';
import { getDataFromORCondition, isUserGuestOrProspect } from 'utils/utils';
import { checkORConditionWithParameter } from 'commonHelper/CommonHelperFunctions';

const configuration = {
    aws_project_region: envConfig.AWS_PROJECT_REGION,
    //  "aws_cognito_identity_pool_id": "us-east-2:47948e7d-eb40-4622-918a-a5682bb6796f",
    aws_cognito_region: envConfig.AWS_COGNITO_REGION,
    aws_user_pools_id: envConfig.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: envConfig.AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
};

/* const assignObj = obj => {
    return obj;
}; */
console.log("Signin Call")
Amplify.configure(configuration);

// const type = {
//     firstTime: false,
//     unKnown: false,
//     softToken: false,
// };

class SignIn extends Component {
    styles = {
        leftColumn: {
            marginTop: '30px',
        },
        rightColumn: {
            marginTop: '75px',
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            form: {
                onlineId: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: 'User ID',
                    rules: {
                        required: true,
                    },
                },
                password: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: 'Password',
                    rules: {
                        required: true,
                    },
                },
            },
            formIsValid: false,
            error: null,
            loading: false,
            notificationMsg: null,
            failure: false,
            attempts: 4,
            redirectRoute: {
                pathname: '',
            },
            redirect: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    static getMemberInfoAndMFA = (role,MFA,firsttimeloginflag,localData,authenticationMethod,isProfileCompleted,userRouteConstants) =>
    {
        const { location, loginState, history, getCustomerProfile, loginAttempts, manageLocalState } = this.props;
        if (role === 'Member' && MFA) {
            if (firsttimeloginflag) {
                localData.otpDetails.backLink = userRouteConstants.signIn;
                localData.otpDetails.verifyNextLink = userRouteConstants.setupSecurityQuestions;
                getCustomerProfile('');
                manageLocalState(localData);
                history.push({
                    pathname: authenticationMethod,
                    showStepper: true,
                });
                return;
            }
            if (isProfileCompleted) {
                localData.otpDetails.backLink = userRouteConstants.signIn;
                localData.otpDetails.verifyNextLink = userRouteConstants.memberDashboard;
                getCustomerProfile('');
                manageLocalState(localData);
                history.push(authenticationMethod);
            } else {
                localData.otpDetails.backLink = userRouteConstants.signIn;
                localData.otpDetails.verifyNextLink = userRouteConstants.setupSecurityQuestions;
                getCustomerProfile('');
                manageLocalState(localData);
                history.push({
                    pathname: authenticationMethod,
                    showStepper: true,
                });
            }
        } else if (role === 'Member' && !MFA) {
            getCustomerProfile('');
            // updatedState = {
            //     ...updatedState,
            //     notificationMsg: userConstants.noMemberMFA,
            //     failure: true,
            //     loading: false,
            // };
            // handleLogout();
            history.push(userRouteConstants.memberDashboard);
        } else if (isUserGuestOrProspect(role)) {
            if (MFA) {
                localData.otpDetails.backLink = userRouteConstants.signIn;
                localData.otpDetails.verifyNextLink = userRouteConstants.guestDashboard;
                getCustomerProfile('');
                manageLocalState(localData);
                history.push(authenticationMethod);
            } else {
                getCustomerProfile('');
                history.push(userRouteConstants.guestDashboard);
            }
        } else {
            getCustomerProfile('');
            history.push(userRouteConstants.serverError);
        }

        return localData;
    }

    static getDerivedStateFromProps(props, state) {
        const { location, loginState, history, getCustomerProfile, loginAttempts, manageLocalState } = props;
        const { form } = state;
        const attempts = loginState.loginAttempts;
        let updatedState = { ...state };
        let updatedForm = { ...form };
        let updatedPasswordState = { ...updatedForm.password };
        let updatedOnlineIdState = { ...updatedForm.onlineId };
        if (location.message) {
            updatedState.notificationMsg = location.message;
            updatedState.failure = getDataFromORCondition(location.failure,false);
        }
        if (loginState.getCustomerProfile) {
            const customerData = { customerProfile: { ...loginState.getCustomerProfile } };
            manageLocalState(customerData);
            // loginState.getCustomerProfile = {"firstName":"John","suffix":"MD","prefix":"Mr","Online_Id":"usaaonlineid4","role":"Member","lastName":"Paul","rank":"First Lieutenant","MFA":true,"isProfileCompleted":false,"isBusinessEntity":false,"primaryPhoneCountryCode":"+1","primaryPhone":"2063478120","isMobilePrimary":true,"secondaryPhoneCountryCode":null,"secondaryPhone":null,"isMobileSecondary":true,"primaryPhoneNumber":"XXXXXX8120","firsttimeloginflag":true,"lastlogintime":"19/08/2020, 14:59:22","signInMethod":"otp","preferredMethod":"mobile"};
            const {
                role,
                firsttimeloginflag,
                firstName,
                lastName,
                signInMethod,
                Email,
                phoneInformation,
                MFA,
                primaryPhoneNumber,
                phoneType,
                isProfileCompleted,
            } = loginState.getCustomerProfile;

            let primaryMobilePhone = null;
            if (phoneInformation) {
                primaryMobilePhone = phoneInformation.primaryMobilePhone;
            }
            const onlineId = loginState.getCustomerProfile.Online_Id;
            const userName = `${firstName} ${lastName}`;
            let localData = {
                otpDetails: {
                    primaryNumber: primaryPhoneNumber,
                    phoneType,
                    onlineId,
                    backLink: '',
                    verifyNextLink: '',
                    flow: 'Sign In',
                    functionalityFlag: 'signIn',
                },
            };
            let authenticationMethod;

            if (checkORConditionWithParameter(signInMethod,'otp',signInMethod,'')) {
                authenticationMethod = userRouteConstants.otpivr;
            }
            // Below line to be enabled in for other sign in methods

            // else if (signInMethod === 'securityquestions') {

            // }
            if (primaryPhoneNumber !== 'nullnull' || primaryPhoneNumber !== '' || primaryPhoneNumber !== 'null') {

                localData = SignIn.getMemberInfoAndMFA(role,MFA,firsttimeloginflag,localData,authenticationMethod,isProfileCompleted,userRouteConstants);
                // if (role === 'Member' && MFA) {
                //     if (firsttimeloginflag) {
                //         localData.otpDetails.backLink = userRouteConstants.signIn;
                //         localData.otpDetails.verifyNextLink = userRouteConstants.setupSecurityQuestions;
                //         getCustomerProfile('');
                //         manageLocalState(localData);
                //         history.push({
                //             pathname: authenticationMethod,
                //             showStepper: true,
                //         });
                //         return;
                //     }
                //     if (isProfileCompleted) {
                //         localData.otpDetails.backLink = userRouteConstants.signIn;
                //         localData.otpDetails.verifyNextLink = userRouteConstants.memberDashboard;
                //         getCustomerProfile('');
                //         manageLocalState(localData);
                //         history.push(authenticationMethod);
                //     } else {
                //         localData.otpDetails.backLink = userRouteConstants.signIn;
                //         localData.otpDetails.verifyNextLink = userRouteConstants.setupSecurityQuestions;
                //         getCustomerProfile('');
                //         manageLocalState(localData);
                //         history.push({
                //             pathname: authenticationMethod,
                //             showStepper: true,
                //         });
                //     }
                // } else if (role === 'Member' && !MFA) {
                //     getCustomerProfile('');
                //     // updatedState = {
                //     //     ...updatedState,
                //     //     notificationMsg: userConstants.noMemberMFA,
                //     //     failure: true,
                //     //     loading: false,
                //     // };
                //     // handleLogout();
                //     history.push(userRouteConstants.memberDashboard);
                // } else if (isUserGuestOrProspect(role)) {
                //     if (MFA) {
                //         localData.otpDetails.backLink = userRouteConstants.signIn;
                //         localData.otpDetails.verifyNextLink = userRouteConstants.guestDashboard;
                //         getCustomerProfile('');
                //         manageLocalState(localData);
                //         history.push(authenticationMethod);
                //     } else {
                //         getCustomerProfile('');
                //         history.push(userRouteConstants.guestDashboard);
                //     }
                // } else {
                //     getCustomerProfile('');
                //     history.push(userRouteConstants.serverError);
                // }
            } else {
                getCustomerProfile('');
                updatedState = {
                    ...updatedState,
                    notificationMsg: userConstants.withoutPhoneNumber,
                    failure: true,
                    loading: false,
                };
            }
        }

        if (attempts) {
            // console.log(attempts);
            if (attempts.errorCode === 5008) {
                /* updatedState = {
                    ...updatedState,
                    notificationMsg: { message: attempts.result },
                    failure: true,
                }; */
                updatedPasswordState = { ...updatedPasswordState, errMsg: data.wrongCredntials };
                updatedOnlineIdState = { ...updatedOnlineIdState, errMsg: ' ' };
            } else if (attempts.errorCode === 0) {
                const number = attempts.result.match(/\d/);
                const attemptsMessage = getAttemptsMessage(number);
                updatedState = {
                    ...updatedState,
                    notificationMsg: { message: attemptsMessage },
                    failure: true,
                };
                updatedPasswordState = { ...updatedPasswordState, errMsg: data.wrongCredntials };
                updatedOnlineIdState = { ...updatedOnlineIdState, errMsg: ' ' };
            } else {
                updatedState = {
                    ...updatedState,
                    notificationMsg: { message: data.wrongCredntials },
                    failure: true,
                };
            }
            updatedForm = {
                ...updatedForm,
                password: { ...updatedPasswordState },
                onlineId: { ...updatedOnlineIdState },
            };
            // console.log(updatedForm)
            updatedState = { ...updatedState, form: { ...updatedForm }, loading: false };
            loginAttempts('');
        }

        return updatedState;
    }

    componentDidMount() {
        const { manageLocalState } = this.props;
        //  sessionStorage.setItem('stepname', 'Sign In');
         manageLocalState({ otpDetails: {}, personalDetailsState: {}, dataFlag: true }); // added for recoveronlineId / recoverpassword flows
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    analyticsLastTouch = (event) =>{
        analyticsFormAbandLastTouch(event);
    }

    onChange(event, fieldId) {
        inputChangedHandler(event, fieldId, this);
    }

    onBlur(fieldId) {
        inputValidityHandler(fieldId, this);
    }

    onClose = () => {
        this.setState({ failure: false, notificationMsg: '' });
    };

    signInHandler = () => {
        const { form } = this.state;
        const { setJWTToken, getCustomerProfile, resetFab, loginAttempts } = this.props;
        const { password, onlineId } = form;
        resetFab(); // Collapse chat if it is open
        this.setState(state => ({ ...state, loading: true, error: null, notificationMsg: null, failure: false }));
        const username = onlineId.value.toLowerCase();
        const passwordValue = password.value;
        const signInPayload = { username, password: passwordValue };

        Auth.signIn(signInPayload)
            .then(tokenData => {
                // console.log(tokenData);
                this.setState(state => ({ ...state, loading: false }));
                setJWTToken(tokenData.signInUserSession.idToken.jwtToken);
                // localStorage.setItem('token', tokenData.signInUserSession.idToken.jwtToken);
                // localStorage.setItem('accessToken', tokenData.signInUserSession.accessToken.jwtToken);
                // localStorage.setItem('userId', tokenData.username);
                // localStorage.setItem('vcmId', tokenData.signInUserSession.idToken.payload.vcmID);
                // localStorage.setItem('fisMemberId', tokenData.signInUserSession.idToken.payload.fisMemberId);

                // Get User Information Starts
                const payLoad = {};
                getCustomerProfile(payLoad);

                // Get User Information Ends
            })
            .catch(error => {
                /* if (/UL_ERR_002/.test(error.message)){
                    this.setState(state => ({
                        ...state,
                        notificationMsg: { message:data.blockedText1 },
                        failure: true,
                        loading: false,
                    }));
                }else */ if (
                    /UL_ERR_001|UL_ERR_002|UL_ERR_01|UL_ERR_003/.test(error.message)
                ) {
                    this.setState(state => ({
                        ...state,
                        notificationMsg: userConstants.blockedMessage,
                        failure: true,
                        loading: false,
                        form: {
                            password: {
                                ...password,
                                errMsg: '',
                            },
                            onlineId: {
                                ...onlineId,
                                errMsg: '',
                            },
                        },
                    }));
                } else if (/UL_ERR_004/.test(error.message)) {
                    this.setState(state => ({
                        ...state,
                        notificationMsg: { message: data.inactiveMessage },
                        failure: true,
                        loading: false,
                        form: {
                            password: {
                                ...password,
                                errMsg: '',
                            },
                            onlineId: {
                                ...onlineId,
                                errMsg: '',
                            },
                        },
                    }));
                } else if (error.code === 'NotAuthorizedException') {
                    // console.log('@@@');
                    /* this.setState(state => ({
                        ...state,
                        notificationMsg: { message: data.wrongCredntials },
                        failure: true,
                        loading: false,
                    })); */
                    const payload = { onlineId: onlineId.value };
                    loginAttempts(payload);
                } else {
                    /* this.setState(state => ({
                        ...state,
                        notificationMsg: { message: data.wrongCredntials },
                        failure: true,
                        loading: false,
                    })); */

                    this.setState({
                        notificationMsg: { message: data.wrongCredntials },
                        failure: true,
                        loading: false,
                        form: {
                            password: {
                                ...password,
                                errMsg: '',
                            },
                            onlineId: {
                                ...onlineId,
                                errMsg: '',
                            },
                        },
                    });
                }
            });
    };

    render() {
        const { loading, notificationMsg, failure, redirectRoute, redirect } = this.state;
        const { loginState } = this.props;
        let redirectionResult = '';
        if (redirect) {
            redirectionResult = <Redirect to={redirectRoute} />;
        }
        return (
            <div className="container-fluid sign-in-page">
                {redirectionResult}
                {notificationMsg ? <WMessageBar text={notificationMsg} error={failure} onClose={this.onClose} /> : null}
                <WSpinner loading={loading || loginState.isLoading} />
                <div className="container sign-in-page">
                    <Row className="main">
                        <Col md lg xl style={this.styles.leftColumn}>
                            <SignInForm
                                state={this.state}
                                signInHandler={this.signInHandler}
                                changed={this.onChange}
                                blurred={this.onBlur}
                                clicked={this.analyticsLastTouch}
                            />
                            <div className="signinFooter">
                                <span className="signinPageLogo">
                                    <img src={vcmLogo} alt="VCM LOGO" />{' '}
                                </span>
                                <span className="signinPageLink">
                                    {' '}
                                    {data.victoryFundsIncestorQ}
                                    <a
                                        className="inlineLink"
                                        href="https://www.vcm.com/shareholder-login"
                                        alt="External Website"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.signinHere}
                                        <span className="sr-only">{data.extWebsiteAlert}</span>
                                        <span aria-hidden="true" className="icon-newpage" />
                                    </a>
                                </span>
                            </div>
                        </Col>
                        <Col md lg xl style={this.styles.rightColumn}>
                            <RegisterCardComponent />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

SignIn.propTypes = {
    loginState: PropTypes.instanceOf(Object),
    getCustomerProfile: PropTypes.func,
    manageLocalState: PropTypes.func,
    loginAttempts: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    setJWTToken: PropTypes.func,
    location: PropTypes.shape({
        message: PropTypes.shape({
            messageText: PropTypes.string,
            info: PropTypes.string,
        }),
        failure: PropTypes.bool,
    }),
    resetFab: PropTypes.func.isRequired,
};

SignIn.defaultProps = {
    loginState: {},
    getCustomerProfile: () => {},
    loginAttempts: () => {},
    manageLocalState: () => {},
    history: [],
    setJWTToken: () => {},
    location: {},
};

export default SignIn;
