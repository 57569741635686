import React, { useState, useEffect } from 'react';
import { Accordian, CommonButtons, AccordianTitle, WInput, WRadio, ConfirmModal, WSpinner } from 'common';
import '../MarketNAOStyles.css';
import { FieldValue, SelectDropDown } from '../../../CustomerManagementModule/components';
import DatePicker from 'react-datepicker';
import { Row, Card, Alert} from 'react-bootstrap';
import styles from '../../PersonalInfo/styles';
import PhoneDetails from '../../../AccountManagementModule/MarketPhoneDetails/PhoneDetails';
import { assignObj, radiodescstyles, radiostyles, radioText, doProvideContacts, radioOptions, genderlabel, genderOptions, HelpText_TrustedContact,DisclosureLinkText } from './consts'
import EmailDetails from '../../../AccountManagementModule/MarketEmail/MarketEmailDetails';
import Address from '../../Address';
import styled from 'styled-components';
import { useSelector,useDispatch} from 'react-redux';
import {updateMarketNAOInfo, clearAPIStatus, postMarketNAOInfo, clearBankAccountInfo} from '../../../../shared/Actions/MarketNAOActions';
import Rules from '../../PersonalInfo/Rules';
import moment from 'moment-timezone';
import checkValidity from '../../../../utils/checkValidity';
import { encrypt } from 'utils';
import StyledToolTip from '../../MarketPersonalInfoCard/StyledToolTip.js'
import helperIcon from '../../../../assets/helperIcon.png'
import PrintPdf from '../../../../common/TermsAndConditions/PrintPdf';
import { MARKET_NAO_ACCOUNT_TYPES } from 'modules/AccountManagementModule/consts';
import envConfig from 'env/EnvConfig';
import {resetBrokerageAccountListState} from 'shared/Actions/BankAccountAction';
const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const PHONE_ERROR = ' Please enter at least one primary phone number';
const EMAIL_ERROR = 'Please enter your primary email address';

const trusted_contact_person_discloser = {
   url: envConfig.TERMS_PDF_TRUSTED_CONTACT_PERSON_DISCLOSER,
   withCredentials: true,
}

export default function TrustedContact(props) {

    //DFM-298 DFW-3480 relation ship new drop down values
    const relationshipDropDownData = useSelector(state => state.transactionCompositeDataReducerData?.brkg_relationship?.value)
    let relationships=[]
    for (let i = 0; i < relationshipDropDownData?.length; i++) {
        relationships.push(relationshipDropDownData[i].value)
    }
    const dropDownValues = useSelector(state => state?.transactionCompositeDataReducerData)
    const salutations = dropDownValues?.prefix?.value?.map(data => data.value) || []
    const suffixes= dropDownValues?.suffix?.value?.map(data => data.value) || []
 
    
    const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
    const [state, setStateVariables] = useState(marketNAOInfoReducerData?.marketNAOInfo?.trustedContact);
    const [emailAlert,setEmailAlert] = useState(false);
    const [phoneAlert,setPhoneAlert] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [provideTrustedContactsFlag,setProvideTrustedContactsFlag] = useState(false);
    const [printPdf, setPrintPdf] = useState(false);
    const [pdfFile, setpdfFile] = useState(null)
    const primaryPhoneDetailsRef = React.createRef();
    const primaryEmailDetailsRef = React.createRef();

    const trustedContactAddressRef = React.createRef();
    const dispatch = useDispatch();
    const [apiError, setApiError] = useState(false)
    const isLoading = marketNAOInfoReducerData.isLoading


    let addressInfo = {
        "addressLine1": state.address.addressLine1,
        "addressLine2": state.address.addressLine2,
        "city": state.address.city,
        "state": state.address.state,
        "zip": state.address.zipCode
    }
    let isPrimaryNumberChanged = false;
    useEffect(() => {
    // 👇️ scroll to top on page load
    dispatch(resetBrokerageAccountListState());
    dispatch(clearBankAccountInfo());
     {
       if( props.TrustedContactVerifyPageHide){ return} 
       else {
         document.title = `Trusted Contact Page | Victory Capital`;
        return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
       }}
  }, []);


    useEffect(() => {
        const TrustedContactVerifyPageHide = props.TrustedContactVerifyPageHide
        if (marketNAOInfoReducerData.istrustedContactApiSuccess) {
            TrustedContactVerifyPageHide ? props.AfterEditVerifyHide(false) : props.clickNext();
            dispatch(clearAPIStatus('trustedContact'))
        } else if (marketNAOInfoReducerData.istrustedContactApiError) {
            setApiError(true)
            dispatch(clearAPIStatus('trustedContact'))
        }
    }, [marketNAOInfoReducerData])

    const inputNoNumOnly = val => {
        return val ? val.toString().replace(/[0-9]/g, '') : 0;
    };

    function stateHandler(x, y) {
        return setStateVariables(prevState => ({ ...prevState, [x]: y }));
    }

    const validdatePhoneNumber = () =>
    {
        const phoneInfo = primaryPhoneDetailsRef.current.getPhoneDetails();

        if(phoneInfo.phoneNumber == undefined || phoneInfo.phoneNumber == null ||  phoneInfo.phoneNumber == "" ||  phoneInfo.phoneNumber.toString().length<10 )
        {
            setPhoneAlert(true);
            return false;
        }
        else
        {
            setPhoneAlert(false);
            return true;
        }
    }


    const validateEmailAddress = () =>
    {
        const emailDetails = primaryEmailDetailsRef.current.getEmailDetails();
        if(emailDetails.email == undefined || emailDetails.email == null ||  emailDetails.email == "")
        {
            setEmailAlert(true);
            return false;
        }
        else
        {
            setEmailAlert(false);
            return true;
        }
    }

    const createPayloadAndSave = async()=>
    {
        if(state.provideTrustedContacts == "Yes")
        {
            const phoneInfo = primaryPhoneDetailsRef.current.getPhoneDetails();
            const emailDetails = primaryEmailDetailsRef.current.getEmailDetails();
            const addressDetails = trustedContactAddressRef.current.getAddress();
       
            const dateFormatText = 'MM/DD/YYYY';
            const formatedDate = moment(state.dob).format(dateFormatText);

            const trustedContactInfo = {
                applicationId: marketNAOInfoReducerData.applicationId,
                pageNumber: 7,
                source: "web",
                trustedContact:{
                    "prefix": state.prefix,
                    "firstName":state.firstName,
                    "lastName": state.lastName,
                    "suffix": state.suffix,
                    "middleInitial": state.middleInitial,
                    "dob": await encrypt(formatedDate),
                    "maskedDob": "",
                    "gender": state.gender,
                    "citizenship": "",
                    "countryOfCitizenship": "",
                    "countryOfCitizenshipCode": "",
                    "maritalStatus": "",
                    "ssn": "",
                    "maskedSSN": "",
                    "primary": {
                        "number": phoneInfo.phoneNumber,
                        "countryCode": phoneInfo.countryCode,
                        "isMobile": phoneInfo.isMobile
                    }, 
                    "email":emailDetails.email,
                    "relationship":state.relationship,
                    "provideTrustedContacts":state.provideTrustedContacts,
                    "address": {
                        "addressLine1": addressDetails.lineTwo,
                        "addressLine2": addressDetails.lineOne,
                        "city": addressDetails.city,
                        "state": addressDetails.state,
                        "zipCode": addressDetails.zipCode
                    },
            
                }
            }

            dispatch(postMarketNAOInfo({
                url: 'trustedContact',
                payloadData: trustedContactInfo
            }))


            // ---------------------------- save to local
            const trustedContactState = {
                "prefix": state.prefix,
                "firstName":state.firstName,
                "lastName": state.lastName,
                "suffix": state.suffix,
                "middleInitial": state.middleInitial,
                "dob": state.dob,
                "maskedDob": state.dob,
                "gender": state.gender,
                "citizenship": "",
                "countryOfCitizenship": "",
                "countryOfCitizenshipCode": "",
                "maritalStatus": "",
                "ssn": "",
                "maskedSSN": "",
                "email":emailDetails.email,
                "relationship":state.relationship,
                "provideTrustedContacts":state.provideTrustedContacts,
                "address": {
                    "addressLine1": addressDetails.lineOne,
                    "addressLine2": addressDetails.lineTwo,
                    "city": addressDetails.city,
                    "state": addressDetails.state,
                    "zipCode": addressDetails.zipCode
                },
                "primary": {
                    "number": phoneInfo.phoneNumber,
                    "countryCode": phoneInfo.countryCode,
                    "isMobile": phoneInfo.isMobile
                }, 
        
            }

            const saveToLocalpayload = {
                sectionToBeUpdated:'trustedContact',
                state: trustedContactState
            }
            
            dispatch(updateMarketNAOInfo(saveToLocalpayload))
        }
        else if(state.provideTrustedContacts == "No")
        {
            const trustedContactInfo = {
                applicationId: marketNAOInfoReducerData.applicationId,
                pageNumber: 7,
                source: "web",
                trustedContact:{
                    "prefix": "",
                    "firstName":"",
                    "lastName": "",
                    "suffix": "",
                    "middleInitial": "",
                    "dob": "",
                    "maskedDob": "",
                    "gender": "",
                    "citizenship": "",
                    "countryOfCitizenship": "",
                    "countryOfCitizenshipCode": "",
                    "maritalStatus": "",
                    "ssn": "",
                    "maskedSSN": "",
                    "email":"",
                    "provideTrustedContacts":state.provideTrustedContacts,
                    "relationship":"",
                    "address": {
                        "addressLine1": "",
                        "addressLine2": "",
                        "city": "",
                        "state": "",
                        "zipCode": ""
                    },
                    "primary": {
                        "number": "",
                        "countryCode": "",
                        "isMobile": false
                    }, 
            
                }
            }
            dispatch(postMarketNAOInfo({
                url: 'trustedContact',
                payloadData: trustedContactInfo
            }))


            // ---------------------------------save to local

            const trustedContactState = {
                "prefix": "",
                "firstName":"",
                "lastName": "",
                "suffix": "",
                "middleInitial": "",
                "dob": "",
                "maskedDob": "",
                "gender": "",
                "citizenship": "",
                "countryOfCitizenship": "",
                "countryOfCitizenshipCode": "",
                "maritalStatus": "",
                "ssn": "",
                "maskedSSN": "",
                "email":"",
                "relationship":"",
                "provideTrustedContacts":state.provideTrustedContacts,
                "address": {
                    "addressLine1": "",
                    "addressLine2": "",
                    "city": "",
                    "state": "",
                    "zipCode": ""
                },
                "primary": {
                    "number": "",
                    "countryCode": "",
                    "isMobile": false
                }, 
        
            }

            const saveToLocalpayload = {
                sectionToBeUpdated:'trustedContact',
                state: trustedContactState
            }
            
            dispatch(updateMarketNAOInfo(saveToLocalpayload))
        }

    }

    const validateEachInputFiled = ()=>
    {
        if(state.relationship == undefined || state.relationship == null || state.relationship == "" || 
        state.firstName == undefined || state.firstName == null || state.firstName == "" ||
        state.lastName == undefined || state.lastName == null || state.lastName == "" ||
        state.dob == undefined || state.dob == null || state.dob == "" ||
        state.gender == undefined || state.gender == null || state.gender == "" || 
        formErrors?.DOB?.length > 0 )
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    const validateEachFieldOnNextButton = ()=>
    {
        let validFlag = true;
        if(state.provideTrustedContacts == undefined || state.provideTrustedContacts == "" )
        {
            setProvideTrustedContactsFlag(true);
            validFlag = false; 
        }
        else if(state.provideTrustedContacts == "No")
        {
            setProvideTrustedContactsFlag(false);
            validFlag = true;
        }
        else if(state.provideTrustedContacts == "Yes")
        {
            setProvideTrustedContactsFlag(false);
            const phonePhone = validdatePhoneNumber()
            const emailDetails = validateEmailAddress();
            const addressDetails = trustedContactAddressRef.current.getAddress();
            let addressFlag = true;
            if(addressDetails.valid == false ||
              addressDetails.errorMsg?.city?.length > 0 ||
              addressDetails.errorMsg?.cityName?.length > 0 || 
              addressDetails.errorMsg?.lineOne?.length > 0 || 
              addressDetails.errorMsg?.lineTwo?.length > 0 || 
              addressDetails.errorMsg?.state?.length > 0 ||  
              addressDetails.errorMsg?.stateName?.length > 0 ||
              addressDetails.errorMsg?.zipCode?.length > 0  )
            {
                addressFlag = false;
            }

            const returnFlag = (phonePhone && emailDetails && addressFlag);
            return returnFlag;
        }   
        
        return validFlag;
    }

    const handleChangeDropDownChild = eventKey => e => {
        const targetValue = e.target.value;
        if (eventKey == 'relationship') {
            setStateVariables(prevState => ({ ...prevState, relationship: relationships[targetValue] }));
        } else if (eventKey == 'prefix') {
            setStateVariables(prevState => ({ ...prevState, prefix: salutations[targetValue] }));
        } else if (eventKey == 'suffix') {
            setStateVariables(prevState => ({ ...prevState, suffix: suffixes[targetValue] }));
        } else {
            stateHandler(eventKey, targetValue)
        }
    };

    const onInputChange = eventKey => (e) => {
        const targetValue = e.target.value
        stateHandler(eventKey, targetValue)
    }

      // on next button click if there are internal step, complete them and later switch next section
  const handleClickNext = () => {

    let validFlag= true;
    if(state.provideTrustedContacts == "No")
    {
        validFlag = true;
        setProvideTrustedContactsFlag(false);
    }
    else
    {
        const inputFiled = validateEachInputFiled();
        const inputFiledComponent = validateEachFieldOnNextButton();
        validFlag = (inputFiled && inputFiledComponent);
        setFormErrors(handleValidation(state));
    }

    if(validFlag == true)
    {
        createPayloadAndSave();
    }

  };

  const onBlurValidation = ()=>
  {

  }

  // Hnadle validation
  const handleValidation = (state) => {
    let errors={}

    if(state.provideTrustedContacts === ''){
        errors.provideTrustedContacts = "Mandatory field"
    }
    if(state.relationship === ''){
        errors.relationship = "Mandatory field";
    }
    if(state.firstName === ''){
        errors.firstName = "Mandatory field";
    }

    if(state.lastName === ''){
        errors.lastName = "Mandatory field";
    }
    if(state.dob === ''){
        errors.DOB = "Mandatory field";
    }
    else
    {
        if(formErrors?.DOB)
        {
            errors.DOB = formErrors?.DOB;
        }
    }
    if(state.gender === ''){
        errors.gender = "Mandatory field";
    }

    return errors
}


    const marketNAOInfoReducerData1 = useSelector(state => state.marketNAOReducerData);
    const accountType = marketNAOInfoReducerData1.marketNAOInfo.account.accountType

  // on back button click if there are internal step, complete them and later switch next section
  const handleClickBack = () => {
     if( accountType == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT){
     let regulatoryPageIndex = state;
      regulatoryPageIndex['regulatoryPageIndex'] = 1
     }
    const {clickBack} = props;
    clickBack();
  };


    const radioButtonClick = (eventKey, buttonType) => e => {
        const targetValue = e.target.value
        if (eventKey == 'provideTrustedContacts') {
            setStateVariables(prevState => ({ ...prevState, provideTrustedContacts: targetValue }));
        }
        else if (eventKey == 'gender') {
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
        }
        else {
            stateHandler(eventKey, targetValue)
        }

        setProvideTrustedContactsFlag(false);

    }

    const handleDOB = (dateValue, eventKey) =>
    {
        let err = '';
        const dateFormatText = 'MM/DD/YYYY';
        const formatedDate = moment(dateValue).format(dateFormatText);
        err = Rules['ownerDateOfBirth']
        ? checkValidity({
              rules: Rules['ownerDateOfBirth'],
              value: formatedDate,
          })
        : '';

        if(err !="" && err !=null)
        {
            let errors={};
            errors.DOB = err;
            setFormErrors(errors);
        }
        else
        {
            setFormErrors({});
        }

        setStateVariables(prevState => ({ ...prevState, dob: dateValue }));
    }

        //Verifypage after edit save and  hide condition
    const TrustedContactVerifyPageHide = props.TrustedContactVerifyPageHide
    const VerifyEditSave = () => {
        let validFlag = true;
        if (state.provideTrustedContacts == "No") {
            validFlag = true;
            setProvideTrustedContactsFlag(false);
        }
        else {
            const inputFiled = validateEachInputFiled();
            const inputFiledComponent = validateEachFieldOnNextButton();
            validFlag = (inputFiled && inputFiledComponent);
            setFormErrors(handleValidation(state));
        }
        if (validFlag == true) {
            createPayloadAndSave();
            props.AfterEditVerifyHide(false)
        }
    };
    
    const VerifyCancel = () => {
        props.AfterEditVerifyHide(false)
    }
    //Tooltip functionality for TrustedContact
    const [HelperTetxForTrustedContact, setHelperTetxForTrustedContact] = useState(false)

    const toggleToolTipForTrustedContact = () => {
        setHelperTetxForTrustedContact(!HelperTetxForTrustedContact)
    }

    const printPdfFunction = () => {
        setPrintPdf(true)
        setHelperTetxForTrustedContact(false)
        setpdfFile(trusted_contact_person_discloser)
    }
    const PrintPdfHide=()=>{
        setPrintPdf(false)
    }


    const handleExit = () => {
        setApiError(false)
    }
    
    return (
        <div>


            {isLoading && <WSpinner loading={isLoading} /> }


            {apiError && (
                <ConfirmModal
                    modalTitle={"Error"}
                    modalBodyText={marketNAOInfoReducerData.postStatusMsg}
                    primaryButtonText="OK"
                    onPrimaryClick={handleExit}
                />
                
            )}

            <div>
                <Accordian
                    accordianPaddingLeft={0}
                    id={TrustedContact}
                    titleChild={
                        <AccordianTitle
                            accType={'Trusted Contact'}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                        />
                    }
                    titleSeperator
                >
                    <div className="TrustedContactInfo first-section">
                        <Card>
                            <Card.Body>
                                <img id="TrustedContact" className='TooltipforTrustedContact lg-2'  src={helperIcon} alt="" />
                                <StyledToolTip
                                    id="TrustedContact_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxForTrustedContact}
                                    targetID="TrustedContact"
                                    handleToggle={toggleToolTipForTrustedContact}
                                    autohide={false}
                                    triggerEvt="hover focus"
                                    tooltipText={[HelpText_TrustedContact,<span style={{color:'#486D90', cursor:'pointer'}} onClick={printPdfFunction}> {DisclosureLinkText}</span>]}
                                />
                                <PrintPdf
                                   file={pdfFile}
                                   show={printPdf}
                                   handleHide={PrintPdfHide}
                                   maximumModalHeight={window.innerHeight}
                                 />
                                <Row className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`provideTrustedContacts`)}>
                                    <span
                                        className="label col-sm-4 alignRadioBtnTrustedContact"
                                        id={assignObj(`provideTrustedContacts`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {doProvideContacts}
                                    </span>
                                    <div style={{ flexBasis: '20%' }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="provideTrustedContacts"
                                                        key={state.provideTrustedContacts + item}
                                                        id={state.provideTrustedContacts + item + 'ptcontact'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.provideTrustedContacts == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="provideTrustedContacts"
                                                        onClick={radioButtonClick('provideTrustedContacts', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        {provideTrustedContactsFlag && (
                                        <div
                                            className="errorMsgInputStyle errorSameAddressDiv"
                                            id="genderError"
                                            aria-atomic="true"
                                            aria-live="assertive"
                                        >
                                            <div id="errGender">Please select provide trusted contact</div>
                                        </div>
                                    )}
                                    </div>
                                </Row>
                                {state.provideTrustedContacts == 'Yes' && (
                                    <div className="childboxsection">
                                        <div>
                                            
                                       
                                            <FieldValue
                                                as="p"
                                                id="relationship"
                                                label="Relationship to You"
                                                value={
                                                    <SelectDropDown
                                                        aria-labelledby="selectDropDown-relationship"
                                                        id="selectDropDown-relationship"
                                                        data-name="Salutation"
                                                        itemlist={relationships}
                                                        value={`${relationships.indexOf(
                                                            `${state.relationship}`,
                                                        )}`}
                                                        errortext={formErrors.relationship}
                                                        className="inputAlign"
                                                        onChange={handleChangeDropDownChild('relationship')}
                                                        onBlur={onBlurValidation}
                                                    />
                                                }
                                                labelsm={4}
                                                valuesm={5}
                                                mb={1.5625}
                                                noGutters
                                               
                                            />
                                          
                                            
                                            <FieldValue
                                                as="p"
                                                id="prefix"
                                                label="Prefix"
                                                value={
                                                    <SelectDropDown
                                                        aria-labelledby="selectDropDown-prefix"
                                                        id="selectDropDown-relationship"
                                                        data-name="Salutations"
                                                        itemlist={salutations}
                                                        value={`${salutations.indexOf(
                                                            `${state.prefix}`,
                                                        )}`}
                                                        errortext={formErrors.prefix}
                                                        className="inputAlign"
                                                        onChange={handleChangeDropDownChild('prefix')}
                                                        onBlur={onBlurValidation}
                                                        
                                                    />
                                                }
                                                labelsm={4}
                                                valuesm={5}
                                                optional
                                                mb={1.5625}
                                                noGutters
                                            />
                                            
                                        <Row>
                                            <WInput
                                                label="First Name"
                                                arialabel="FirstName"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`FirstName`)}
                                                name="First Name"
                                                type="text"
                                                value={state.firstName ? inputNoNumOnly(state.firstName).toString()
                                                    : ''}
                                                labelsm={4}
                                                valuesm={5}
                                                maxlength={40}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange('firstName')}
                                                onBlur={onBlurValidation}
                                                errortext={formErrors.firstName}
                                            />
                                           </Row>
                                            <WInput
                                                label="Middle Initial"
                                                arialabel="MiddleInitial"
                                                aria-required
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`middleName`)}
                                                name="First Name"
                                                type="text"
                                                value={state.middleInitial ? inputNoNumOnly(state.middleInitial).toString()
                                                    : ''}
                                                labelsm={4}
                                                valuesm={5}
                                                maxlength={40}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange('middleInitial')}
                                                onBlur={onBlurValidation}
                                                optional="true"
                                            />
                                            
                                            <WInput
                                                label="Last Name"
                                                arialabel="LastName"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`LastName`)}
                                                name="Last Name"
                                                type="text"
                                                value={state.lastName ? inputNoNumOnly(state.lastName).toString()
                                                    : ''}
                                                labelsm={4}
                                                valuesm={5}
                                                maxlength={40}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange('lastName')}
                                                onBlur={onBlurValidation}
                                                errortext={formErrors.lastName}
                                            />
                                            <FieldValue
                                                as="p"
                                                id="suffix"
                                                label="Suffix"
                                                value={
                                                    <SelectDropDown
                                                        aria-labelledby="selectDropDown-suffix"
                                                        id="selectDropDown-suffix"
                                                        data-name="Salutations"
                                                        itemlist={suffixes}
                                                        value={`${suffixes.indexOf(
                                                            `${state.suffix}`,
                                                        )}`}
                                                        className="inputAlign"
                                                        onChange={handleChangeDropDownChild('suffix')}
                                                        onBlur={onBlurValidation}
                                                        errortext={formErrors.suffix}
                                                       
                                                    />
                                                }
                                                labelsm={4}
                                                valuesm={5}
                                                optional
                                                mb={1.5625}
                                                noGutters
                    
                                            />
                                           
                                            <FieldValue
                                                as="p"
                                                id="Date of Birth"
                                                label="Date of Birth"
                                                value={
                                                    <DatePicker 
                                                    selected={state.dob} 
                                                    onChange={handleDOB} 
                                                    showYearDropdown 
                                                    
                                                     />
                                                }
                                                labelsm={4}
                                                valuesm={5}
                                                noGutters
                                                />

                                            {formErrors?.DOB && (
                                                <div
                                                    className="row errorMsgInputStyle errorSameAddressDiv"
                                                    id="genderError"
                                                    aria-atomic="true"
                                                    aria-live="assertive"
                                                >
                                                    <div className='col-sm-4'></div>
                                                    <div className='col-sm-8 pl-0' id="errGender">{formErrors?.DOB}</div>
                                                </div>
                                            )}

                                        </div>
                                       

                                        <Row >
                                            <span
                                                className="label alignRadioBtnTrustedContact col-sm-4"
                                                id={assignObj(`provideTrustedContacts`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {genderlabel}
                                            </span>
                                            <span className="col-sm-5 marketradioalign pl-0">
                                                    {genderOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="gender"
                                                                key={state.gender + item}
                                                                id={state.gender + item + 'gender'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.gender == item}
                                                                ariaLabel="None"
                                                                // ariaRequired="true"
                                                                ariaDescribedBy="gender"
                                                                onClick={radioButtonClick('gender', item)}
                                                                onBlur={onBlurValidation}
                                                                errortext={formErrors.gender}
                                                            />
                                                        );
                                                    })}
                                               
                                                {formErrors?.gender && (
                                                <div
                                                    className="errorMsgInputStyle errorSameAddressDiv"
                                                    id="genderError"
                                                    aria-atomic="true"
                                                    aria-live="assertive"
                                                    style={{width: '100%', float:'left'}}
                                                >
                                    
                                                    <div id="errGender">Please select a gender</div>
                                                </div>
                                            )}
                                            </span>
                                        </Row>
                                        <div className='addressMargin'>
                                        <Address 
                                        savedState={addressInfo}
                                        ref={trustedContactAddressRef}
                                        labelAdd="Trusted Address"
                                        required
                                        labelAdd1="Address Line 1"
                                        labelAdd2="Address Line 2"
                                        type="physical"
                                        handleValidationsOnBlur = {true}
                
                                        />
                                        </div>

                                        {phoneAlert && (
                                        <StyledAlert
                                            variant="danger"
                                            aria-atomic="true"
                                            aria-live="assertive"
                                            aria-describedby={PHONE_ERROR}
                                        >
                                            <StyledPara>{PHONE_ERROR}</StyledPara>
                                        </StyledAlert>
                                    )}
                                        <PhoneDetails
                                            ref={primaryPhoneDetailsRef}
                                            required
                                           savedState={state}
                                            phtype="Primary"
                                        savedPhoneNumber={state.primary.number}
                                         savedCountryCode={state.primary.countryCode}
                                        isNumberChanged={isPrimaryNumberChanged}
                                        savedIsMobile={state.primary.isMobile}
                                        isMarketNAOSavedData={true}
                                       
                                        />
                                         {emailAlert && (
                                        <StyledAlert
                                            variant="danger"
                                            aria-atomic="true"
                                            aria-live="assertive"
                                            aria-describedby={EMAIL_ERROR}
                                        >
                                            <StyledPara>{EMAIL_ERROR}</StyledPara>
                                        </StyledAlert>
                                    )}
                                        <EmailDetails
                                            ref={primaryEmailDetailsRef}
                                            required
                                            emailType="Primary"
                                        savedEmailAddress={state.email}
                                        />

                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </Accordian>
            </div>
           {TrustedContactVerifyPageHide ?
                <div className='footer'>
                    <CommonButtons backText="Cancel" backClick={VerifyCancel} nextClick={VerifyEditSave} nextText="Save" isShowBtn={true} disabled={false} />
                </div> : <div><CommonButtons backClick={handleClickBack} nextClick={handleClickNext} nextText="Next"  isShowBtn={true} disabled={false} /></div>}
        </div>
    )
}

