import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Message from './MessageComponent';
import { setPageSize } from '../../../shared/Actions/MessageCenterActions';
import iconDown from '../../../assets/Icon_Down.svg';
import { MESSAGE_CENTER_LOAD_MORE, MESSAGE_CENTER_NO_MESSAGES } from './constants';

const MessageListContainerDiv = styled.div`
    margin-top: 20px;
`;

const LoadMoreDiv = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family; benton-sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #004A98;
    cursor:pointer;
`;

const EmptyMessageDiv = styled.div`
    min-height: 80px;
    padding: 40px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    font-family: benton-sans, sans-serif;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    :first-child {
        margin-top: 30px;
    }
`;

const LoadMoreSpan = styled.span`
    margin-right: 5px;
`;

function MessageListComponent() {
    const messages = useSelector(state => state.messageCenterData.messages);
    const messageType = useSelector(state => state.messageCenterData.messageType);
    const messagesPerPage = useSelector(state => state.messageCenterData.messagesPerPage);
    const isLoadingMessages = useSelector(state => state.messageCenterData.isLoadingMessages);
    const [toggleMessage, setToggleMessage] = useState(false);
    const [messageToggleId, setMessageToggleId] = useState();
    const hasMoreMessagesToLoad = useSelector(state => state.messageCenterData.hasMoreMessagesToLoad);
    const dispatch = useDispatch();

    // On clicking message header expands to show the body of the message
    function handleExpandMessage(MessageId, toggleValue) {
        setToggleMessage(toggleValue);
        setMessageToggleId(MessageId);
    }

    // On clicking load more when there are more messages
    function handleLoadMore(e) {
        e.preventDefault();
        const pageSize = (messages ? messages.length : 0) + messagesPerPage;
        dispatch(setPageSize(messageType, pageSize));
    }

    return (
        <MessageListContainerDiv data-test="messageListContainer">
            {messages &&
                messages.map(item => {
                    return item.skCreatedAt === messageToggleId && !toggleMessage ? (
                        <Message
                            key={item.skCreatedAt}
                            message={item}
                            handleExpandMessage={handleExpandMessage}
                            toggleMessage
                        />
                    ) : (
                        <Message
                            key={item.skCreatedAt}
                            message={item}
                            handleExpandMessage={handleExpandMessage}
                            toggleMessage={false}
                        />
                    );
                })}
            {!isLoadingMessages && (!messages || messages.length === 0) && (
                <EmptyMessageDiv>{MESSAGE_CENTER_NO_MESSAGES}</EmptyMessageDiv>
            )}

            {hasMoreMessagesToLoad && (
                <LoadMoreDiv onClick={handleLoadMore}>
                    <LoadMoreSpan>{MESSAGE_CENTER_LOAD_MORE}</LoadMoreSpan>
                    <img src={iconDown} alt={MESSAGE_CENTER_LOAD_MORE} />
                </LoadMoreDiv>
            )}
        </MessageListContainerDiv>
    );
}

export default MessageListComponent;
