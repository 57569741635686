import React, { useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { getElementIdFormat } from 'modules/CustomerManagementModule/utils';
import Radio from '../Radio';
import ErrorMsg from '../ErrorMsg';

export const FlexDiv = styled.div`
    display: flex;
    flex-direction: ${props => props.vertical ? 'column' : 'row'};
`;

const StyledDiv = styled.div`
    position: relative;
`;

export const onRadioClick = ({index, disabled, radioOptions, onChange, options, setRadioOptions})=> (e)=>{
    e.preventDefault();
    e.stopPropagation();
    if(!disabled) {
        const { value } = radioOptions[+index];
        const target = {...e.target, value };
        onChange({target, options})
        const newRadioOptions = radioOptions.map((item,ind)=>{
            return {
                ...item, selected: index === ind
            }
        })
        setRadioOptions(newRadioOptions);   
    }
};

const KEYCODE = {
   DOWN: 40,
   LEFT: 37,
   RIGHT: 39,
   SPACE: 32,
   UP: 38
};

export const onRadioKeyDown = ({index, disabled, radioOptions, onChange, options, setRadioOptions, elementsRef})=> (event)=>{
        if(!disabled){
            let nextIndex = 'default';
              switch (event.keyCode) {
                case KEYCODE.DOWN:
                case KEYCODE.RIGHT:
                  nextIndex = (index + 1) % radioOptions.length;
                  break;
    
                case KEYCODE.UP:
                case KEYCODE.LEFT:
                  nextIndex = index ? index - 1 : radioOptions.length -1 ;
                  break;
    
                case KEYCODE.SPACE:
                  nextIndex = index;
                  break;
                 default: break;
              }
            if(nextIndex !=='default'){
                onRadioClick({index: nextIndex, disabled, radioOptions, onChange, options, setRadioOptions})(event);
                elementsRef.current[+nextIndex].current.focus();
            }
        }
    }

const RadioGroup = (props) =>{
    
    const { options, vertical, labelledBy, initialValue, onBlur, onChange, error, errorTxt, disabled, mL, mB, mR, mT, ...restProps } = props;
    
    const [radioOptions, setRadioOptions] = useState([]);
    const elementsRef = useRef();
    
    const selectedIndex = radioOptions.findIndex((option)=> option.selected);
        
    React.useEffect(() => {
        if(options.length){
            elementsRef.current = [];
            const newList = options.map((item, index)=>{
                elementsRef.current[+index] = createRef(); 
                const selected = item.value === initialValue; 
                return {
                    ...item,
                    selected
                }
            });
            setRadioOptions(newList);
        }
    }, [options, initialValue]);
    
    return (
        <StyledDiv>
        <FlexDiv 
            role="radiogroup"
            aria-labelledby={labelledBy}
            vertical={vertical}
            aria-disabled={disabled}
            {...restProps}
        >
        {
        radioOptions.map((data, index)=>{ 
            const tIndex = ( !disabled && ((selectedIndex !== -1 && data.selected) || (selectedIndex === -1 && index === 0))) ? 0 : -1; 
            const id = `vcm-rb${index+1}-${getElementIdFormat(data.key)}`
            return (
                <Radio 
                    ref={elementsRef.current[+index]}
                    key={index.toString()}
                    label={data.value}
                    selected={data.selected}
                    tabIndex={ tIndex}
                    onClick={onRadioClick({index, disabled, radioOptions, onChange, options, setRadioOptions})}
                    onKeyDown={onRadioKeyDown({index, disabled, radioOptions, onChange, options, setRadioOptions, elementsRef})}
                    mL={!vertical && index ? mL : 0}
                    mB={vertical && index !== (radioOptions.length-1) ? mB : 0}
                    mR={mR}
                    disabled={disabled}
                    id={id}
                    data-form-field={!disabled}
                    data-group-field
                    onBlur={onBlur}
                />
            )
        })
    }
    </FlexDiv>
    {error && <ErrorMsg mT={0.25}>{errorTxt}</ErrorMsg>}
    </StyledDiv>
  )
}

RadioGroup.propTypes = {
    options: PropTypes.instanceOf(Object),
    vertical: PropTypes.bool,
    labelledBy: PropTypes.string,
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorTxt: PropTypes.string,
    disabled: PropTypes.bool,
    mL: PropTypes.number,
    mB: PropTypes.number,
    mR: PropTypes.number,
    mT: PropTypes.number,
};

RadioGroup.defaultProps = {
    options: [],
    vertical: false,
    labelledBy: '',
    initialValue: '',
    onChange: () => {},
    onBlur: () => {},
    error: false,
    errorTxt: 'Please select a option',
    disabled: false,
    mL: 2.5,
    mB: 1.875,
    mR: 0,
    mT: 0,
};

export default RadioGroup;