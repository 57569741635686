import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../CommonStyles';
import './Radio.css';

const assignObj = obj => {
    return obj;
};

const S = {};

S.RadioContainer = styled.span`
    label::before {
        cursor: pointer;
    }
`;

const Radio = props => {
    const {
        radiostyles,
        value,
        radiotext,
        radiodescstyles,
        desc,
        selected,
        radioFor,
        onClick,
        id,
        className,
        onBlur,
        ariaLabel,
        viewOnly,
        disabled,
        dataTestId='',
    } = props;
    // const iserror=(errortext!== undefined && errortext!=='' && errortext!==null);
    let radioClass = 'wradio';
    // const ariaLabel = value.replace('U.S', 'US').replace('U.S', 'US');
    radioClass += selected ? ' selected' : '';
    // const onChangeRadio = assignObj((e) => {
    //     onClick(e);
    // });
    // const onBlurRadio = assignObj((e) => {
    //     if (onBlur) {
    //         setTimeout(() => onBlur(e), 200);
    //     }
    // });

    if (viewOnly) {
        if (selected) {
            return (
                <div className="container-fluid" style={styles.paddingZeroStyle}>
                    {value}
                </div>
            );
        } else {
            return null;
        }
    }
    return (
        <div className="container-fluid" style={styles.paddingZeroStyle}>
            <div className={radioClass}>
                {' '}
                {/* role="radio" is not needed when type="radio" input is there */}
                <S.RadioContainer>
                    <input
                        aria-label={ariaLabel}
                        id={id}
                        data-testid={dataTestId}
                        name={radioFor}
                        value={value}
                        type="radio"
                        onChange={onClick}
                        className={className}
                        onBlur={onBlur}
                        checked={selected}
                        aria-checked={selected}
                        disabled={disabled}
                    />
                    <label style={assignObj({ ...styles.radioStyles, ...radiostyles })} htmlFor={id}>
                        <span style={assignObj({ ...styles.radioText, ...radiotext })}>{value}</span>
                        <div
                            style={assignObj(
                                desc
                                    ? { ...styles.radioWithDesc, ...radiotext }
                                    : { ...styles.radioText, ...radiotext },
                            )}
                        >
                            <span style={assignObj({ ...styles.radioDescStyles, ...radiodescstyles })}>{desc}</span>
                        </div>
                    </label>
                </S.RadioContainer>
            </div>
        </div>
    );
};

Radio.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    radioFor: PropTypes.string,
    radiostyles: PropTypes.instanceOf(Object),
    radiodescstyles: PropTypes.instanceOf(Object),
    radiotext: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    desc: PropTypes.string,
    ariaLabel: PropTypes.string,
    viewOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    // field:PropTypes.string,
};

Radio.defaultProps = {
    dataTestId: '',
    id: '',
    className: '',
    onBlur: null,
    onClick: () => {},
    selected: false,
    value: 'Radio Label',
    desc: '',
    radioFor: 'defaultRadio',
    radiostyles: {},
    radiodescstyles: {},
    radiotext: {},
    ariaLabel: '',
    viewOnly: false,
    disabled: false,
    // field:''
};

export default Radio;
