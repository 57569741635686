import React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Styles from './Styles';
import iconPdf from '../../../../assets/icon_pdf.png';
import { analyzeClickEvent } from '../../../../shared/Actions/AnalyticsActions';
import constant from '../AccountDashboardConstants'

const AttachmentIcon = styled.img`
    width: 16px;
    height: 16px;
`;

const SavedItem = styled.div`
    line-height: 22px;
    >a &:focus {
        outline: 2px solid #000;
        outline-offset: 0px;
    }
`;

const SavedLink = styled(Link)`
    &:focus {
        outline: 2px solid #000;
        outline-offset: 0px;
    }
`

const constants = {
    dot: '.',
    savedText: 'Saved'
};

export default function WCardList({ card }) {
    const dispatch = useDispatch();
    const { assignObj } = constant

    const handleLinksClick = (e) => {
        const target = e.target.innerText || '';
        const screenName = localStorage.getItem("screenName")
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_My Saved Items_${target}`,
            }),
        );
    }
    return (
        <div data-test="cardlist">
            {/* {card &&
                card.map((item, index) => {
                    const { link, uuid } = item */}
            {/* return ( */}
            <ListGroup variant="flush">
                <ListGroup.Item className="text-left pl-0 pt-0" style={Styles.listgroup} role="list">
                    {card &&
                        card.map((item, index) => {
                            const { link, uuid } = item
                            return (<Row role="listitem" noGutters key={item.id} style={Styles.borderStyle}>
                                {item.savedate && (
                                    <Col md={1}>
                                        <div className="text-center" style={Styles.number}>
                                            {index + 1}
                                            {constants.dot}{' '}
                                        </div>
                                    </Col>
                                )}{' '}
                                <Col md={11} className={item.savedate ? 'pl-0' : ''}>
                                    <SavedItem>
                                        <SavedLink style={Styles.link}
                                            data-test="test-link"
                                            to={assignObj({
                                                pathname: link,
                                                state: {
                                                    uuid
                                                },
                                            })}
                                            onClick={handleLinksClick}
                                            tabIndex="0"
                                        >
                                            {item.linktext}
                                        </SavedLink>
                                        <div style={Styles.saveItems}>
                                            <span>{item.attachment ? <AttachmentIcon src={iconPdf} alt="pdf" /> : ''}</span>
                                            <span style={Styles.font}>{constants.savedText} {item.savedate}</span>
                                        </div>
                                    </SavedItem>
                                </Col>
                            </Row>
                            )
                        })
                    }
                </ListGroup.Item>
            </ListGroup>
            {/* //   );
        // })} */}
        </div>
    );
}

WCardList.propTypes = {
    card: PropTypes.instanceOf(Object),
};
WCardList.defaultProps = {
    card: {},
};
