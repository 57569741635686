import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions } from '../../../shared/Actions';
import MobileQuickSignInComponent from './MobileQuickSignInComponent';



const mapStateToProps = (state /* , props */) => (
  {
    loginState: state.loginData
  }
);

const mapDispatchToProps = {
  ...loginActions
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileQuickSignInComponent));