import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import backArrowIconGreen from 'assets/back_arrow.svg';
import backArrowIconBlue from 'assets/leftArrow.svg';
import CtaWithIcon from '../CtaWithIcon';

const Icon = styled.span`
    height: 30px;
    width: 30px;
    background: url(${backArrowIconGreen}) no-repeat;
    background-position: center;
`;

const StyledCtaWithIcon = styled(CtaWithIcon)`
    &:hover, &:active {
        ${Icon} {
            background: url(${backArrowIconBlue}) no-repeat;
        }
    }
`;

const BACK = 'Back';

const BackBtn = React.forwardRef((props, ref) => {
    return <StyledCtaWithIcon ref={ref} icon={<Icon />} {...props} />;
});

BackBtn.propTypes = {
    text: PropTypes.string,
};

BackBtn.defaultProps = {
    text: BACK,
};

export default BackBtn;
