const filterFontStyles = {
    fontSize: 16,
    color: 'rgb(73, 73, 74)',
};

const yorktenFontFamily = 'yorkten-slab-normal';
const bentonSansFontFamily = 'benton-sans';

const filterHeaderTextStyle = {
    marginLeft: 50,
    fontSize: 30,
    color: '#4F799F',
    fontWeight: '800',
    paddingBottom: 5,
    borderBottom: '2px solid #8CBF26',
    fontFamily: yorktenFontFamily,
    float: 'left',
    marginTop: '100px',
};

const clearFilterButtonTextStyle = {
    color: '#5D83AE',
    fontWeight: 600,
};

const clearFilterButtonStyle = {
    borderColor: '#FFFFFF',
};

const applyFilterButtonStyle = {
    marginLeft: 30,
    borderColor: '#FFFFFF',
};

const applyFilterButtonTextStyle = {
    fontWeight: 600,
    color: '#FFFFFF',
};

const accordionInfoStyleFYInv = {
    color: '#616366',
    fontSize: 16,
    fontFamily: bentonSansFontFamily,
    marginTop: 0,
    marginBottom: 40,
};

const accordionInfoStyle = {
    color: '#49494A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: bentonSansFontFamily,
    marginTop: 0,
    marginBottom: 40,
};

const subHeadingStyle = {
    color: '#49494A',
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 8,
    fontFamily: bentonSansFontFamily,
};

const orStyle = {
    textAlign: 'center',
    color: 'rgba(97, 99, 102, 1)',
    fontSize: 16,
    fontWeight: 100,
    fontFamily: bentonSansFontFamily,
    marginTop: 25,
    marginBottom: 30,
};

const bankAccountLabels = {
    font: '600 16px/19px benton-sans',
    color: 'rgb(73, 73, 74)',
};

const radiotext = {
    cololr: '#56565A',
    font: '500 16px/22px benton-sans',
};

const muteText = {
    color: '#56565A',
    font: '12px/22px benton-sans',
    padding: 15,
};
const rowStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center' };

const rowStyleDollar = { display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20 };

const fundFooter = {
    font: '700 22px/22px benton-sans',
    padding: '7px 0',
};

const removeLink = {
    color: 'rgba(0, 74, 152, 1)',
    font: '700 16px/22px yorkten-slab-normal',
    marginTop: 5,
    position: 'relative',
    paddingLeft: 20,
};

const removeLinkIconStyle = {
    height: 16,
    width: 16,
    margin: 0,
    color: 'rgb(139, 193, 5)',
};

const offlineMethodWrapper = { border: 1, borderColor: '#CFCFCF', borderStyle: 'solid', marginTop: 34, padding: 20 };

const offlineMethodCol1 = {
    font: '100 14px/17px benton-sans',
    color: 'rgba(86, 86, 90, 1)',
    textAlign: 'left',
    alignItems: 'center',
};

const offlineMethodCol2 = { font: '600 15px/36px benton-sans', color: '#000000', textAlign: 'left' };

const offlineMethodCol3 = {
    border: 1,
    borderColor: '#707070',
    borderRadius: 31,
    borderStyle: 'solid',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    color: 'rgba(86, 86, 90, 1)',
    font: '700 18px/22px benton-sans',
};

const displayNoneStyle = {
    display: 'none',
};

const containerStyle = { /* backgroundColor:'#F9FBFC', */ minHeight: ' 100vh', position: 'relative' };

const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150 };

const pageHeaderStyle = {
    paddingTop: 40,
    fontSize: 30,
    color: '#4F799F',
    fontWeight: '800',
    paddingBottom: 10,
    borderBottom: '2px solid #8CBF26',
    fontFamily: yorktenFontFamily,
    float: 'left',
    marginBottom: 40,
};

const pageSubheaderStyle = {
    paddingTop: 40,
    fontSize: 22,
    fontFamily: yorktenFontFamily,
    fontWeight: '800',
    color: '#486D90',
    paddingBottom: 10,
    marginBottom: 0,
};

const pageSubheaderTextStyle = {
    fontSize: 14,
    fontFamily: bentonSansFontFamily,
    fontWeight: '600',
    marginBottom: 18,
    color: '#56565A',
};

const pageButtonStyle = { color: '#004A98', border: 'none', height: 'unset', width: 'unset', padding: '0 0 0 22px' };
const printButtonStyle = {
    color: '#004A98',
    border: 'none',
    height: 'unset',
    width: 'unset',
    padding: '0 0 0 25px',
    backgroundColor: 'transparent',
};

const tableHeaderStyle = { borderBottom: '2px solid rgba(139, 193, 5, 1)', wordWrap: 'break-word' };

const tableFooterStyle = {
    color: '#fff',
    fontSize: 16,
    fontFamily: bentonSansFontFamily,
    fontWeight: '600',
    backgroundColor: 'rgba(72, 109, 144, 1)',
};

const tableIconStyle = { height: '', width: '', marginTop: 5, marginLeft: 8, marginBottom: 0, color: '#8BC105' };

const loadMoreIcon = { padding: 0, margin: 0, color: '#8BC105', height: 20, width: 20 };

const accordionIconStyle = { padding: 0, margin: 0, color: '#8BC105', height: 20, width: 20 };

const checkboxlabelStyle = { color: '#49494A', font: '16px/22px benton-sans', cursor: 'pointer' };

const fundSourceSubheadingStyle = {
    color: '#56565A',
    fontSize: 14,
    marginBottom: 30,
    fontFamily: bentonSansFontFamily,
};

const cardIconStyle = {};

const cardStyle = { display: 'flex', flexDirection: 'column', minHeight: 90, minWidth: 220 };

const tollFreeStyle = { font: '15px/18px benton-sans', marginRight: 3 };

const colStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center' };

const specimenStyle = {
    font: '600 16px/22px benton-sans',
    textDecoration: 'underline',
    justifyContent: 'center',
    display: 'flex',
};

const specimenIconStyle = {
    height: 200,
    width: '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 200,
};

const cardtitleStyle = {
    textAlign: 'left',
    maxWidth: 287,
    color: 'rgba(72, 109, 144, 1)',
    fontSize: 18,
    fontWeight: '600',
    fontFamily: yorktenFontFamily,
    margin: '20px 0 0',
};

const specimentTitleStyle = {
    textAlign: 'left',
    color: 'rgba(73, 73, 74, 1)',
    font: '600 26px/30px yorkten-slab-normal',
    float: 'left',
    borderBottom: '1px solid rgba(139, 193, 5, 1)',
};

const specimenCornerColor = { borderLeftColor: 'rgba(139, 193, 5, 1)' };

const tableColor = { color: '#56565A' };

const fullWidthStyle = { width: '100%' };

const halfWidthStyle = { width: '72%' };

const widthStyleFundOpts = { maxWidth: 210 };

const tableCaption = { display: 'none' };

const fundSourceTableStyle = {
    borderBottomWidth: 'thin',
    borderBottomStyle: 'ridge',
    borderBottomColor: '#707070',
    marginTop: 30,
    marginBottom: 30,
};
const fundUrInvTableStyle = { marginTop: 30, marginBottom: 30, fontSize: 16, fontFamily: bentonSansFontFamily };
const errorRadioStyles = { color: 'red', position: 'absolute', bottom: 0, fontSize: '80%' };
export default {
    tableCaption,
    filterFontStyles,
    fundSourceTableStyle,
    fundUrInvTableStyle,
    halfWidthStyle,
    fullWidthStyle,
    widthStyleFundOpts,
    tableColor,
    cardtitleStyle,
    specimentTitleStyle,
    specimenCornerColor,
    specimenIconStyle,
    tollFreeStyle,
    specimenStyle,
    colStyle,
    cardStyle,
    fundSourceSubheadingStyle,
    cardIconStyle,
    tableHeaderStyle,
    tableFooterStyle,
    checkboxlabelStyle,
    tableIconStyle,
    loadMoreIcon,
    accordionIconStyle,
    pageButtonStyle,
    pageSubheaderTextStyle,
    pageSubheaderStyle,
    pageHeaderStyle,
    stepperStyle,
    containerStyle,
    displayNoneStyle,
    filterHeaderTextStyle,
    clearFilterButtonTextStyle,
    applyFilterButtonStyle,
    applyFilterButtonTextStyle,
    clearFilterButtonStyle,
    accordionInfoStyleFYInv,
    accordionInfoStyle,
    subHeadingStyle,
    orStyle,
    bankAccountLabels,
    radiotext,
    muteText,
    rowStyle,
    rowStyleDollar,
    fundFooter,
    removeLink,
    removeLinkIconStyle,
    offlineMethodWrapper,
    offlineMethodCol1,
    offlineMethodCol2,
    offlineMethodCol3,
    errorRadioStyles,
    printButtonStyle,
};
