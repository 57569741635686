import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IconDelete from '../../../assets/Icon_DeleteBG.svg';
import IconMailRead from '../../../assets/Icon_mailReadBg.svg';
import IconMailUnread from '../../../assets/Icon_MailunReadbg.svg';
import * as MCConstants from './constants';
import { showConfirmDialog, setReadUnreadToMessages } from '../../../shared/Actions/MessageCenterActions';

const ButtonsContainerDiv = styled.div`
    margin-left: 30px;
    width: 100%;
    @media only screen and (max-width: 992px) {
        margin-left: 0px;
    }
`;

const ActionButtonsDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ActionLabelDiv = styled.div`
    color: rgb(0, 74, 152);
    display: inline-block;
    font: 700 15px/14px Segoe UI;
`;

const ImageDiv = styled.img`
    padding: 0px;
    width: 40px;
    height: 40px;
    margin: 0 12px;

    @media only screen and (max-width: 992px) {
        :first-child {
            margin-left: 0px;
        }
    }
`;
const ButtonsDiv = styled.a`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none;
    &:link {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }

    @media only screen and (max-width: 992px) {
        margin-right: 20px;
    }
`;

const activeButtonStyling = {
    filter: 'grayscale(0%)',
    cursor: 'pointer',
};

const inactiveButtonStyling = {
    filter: 'grayscale(150%)',
    cursor: 'unset',
};

function MessageCenterButtonsComponent({ selectAll, enableDelete, enableRead, enableUnread }) {
    // Variable for dynamic styling
    let deleteButtonStyle = selectAll ? activeButtonStyling : inactiveButtonStyling;
    let readButtonStyle = selectAll ? activeButtonStyling : inactiveButtonStyling;
    let unreadButtonStyle = selectAll ? activeButtonStyling : inactiveButtonStyling;

    // Dynamic styling based on "read" and "unread" message selection
    deleteButtonStyle = enableDelete ? activeButtonStyling : inactiveButtonStyling;
    readButtonStyle = enableRead ? activeButtonStyling : inactiveButtonStyling;
    unreadButtonStyle = enableUnread ? activeButtonStyling : inactiveButtonStyling;

    const messageType = useSelector(state => state.messageCenterData.messageType);
    const selectedMessages = useSelector(state => state.messageCenterData.selectedMessages);

    const dispatch = useDispatch();

    // Handler for Delete button click
    function onDeleteClick(e) {
        e.stopPropagation();
        if (enableDelete) {
            dispatch(showConfirmDialog(true));
        }
    }

    // Handler for read button click
    function onReadClick(e) {
        e.stopPropagation();
        if (enableRead) {
            const MessageIds = selectedMessages.map(message => message.skCreatedAt);
            dispatch(
                setReadUnreadToMessages({
                    data: { messageIds: MessageIds, isRead: true },
                    token: localStorage.getItem('token'),
                }),
            );
        }
    }

    // Handler for unread button click
     function onUnreadClick(e) {
        e.stopPropagation();
        if (enableUnread) {
            const MessageIds = selectedMessages.map(message => message.skCreatedAt);
           dispatch(
                setReadUnreadToMessages({
                    data: { messageIds: MessageIds, isRead: false },
                    token: localStorage.getItem('token'),
                }),
            );
        }
    }

    return (
        <ButtonsContainerDiv data-test="buttonsContainer">
            <ActionButtonsDiv data-test="actionButtonsDiv">
                <ButtonsDiv data-test="actionDelete" style={deleteButtonStyle} href="#" onClick={onDeleteClick}>
                    <ImageDiv data-test="deleteImg" src={IconDelete} alt={MCConstants.MESSAGE_CENTER_DELETE} />
                    <ActionLabelDiv>{MCConstants.MESSAGE_CENTER_DELETE}</ActionLabelDiv>
                </ButtonsDiv>
                {messageType === MCConstants.MESSAGE_CENTER_INBOX_VAL && (
                    <>
                        <ButtonsDiv data-test="actionMarkAsRead" style={readButtonStyle} href="#" onClick={onReadClick}>
                            <ImageDiv
                                data-test="readImg"
                                src={IconMailRead}
                                alt={MCConstants.MESSAGE_CENTER_READ_ALT}
                            />
                            <ActionLabelDiv>{MCConstants.MESSAGE_CENTER_MARK_AS_READ}</ActionLabelDiv>
                        </ButtonsDiv>
                        <ButtonsDiv
                            data-test="actionMarkAsUnread"
                            style={unreadButtonStyle}
                            href="#"
                            onClick={onUnreadClick}
                        >
                            <ImageDiv
                                data-test="unreadImg"
                                src={IconMailUnread}
                                alt={MCConstants.MESSAGE_CENTER_UNREAD_ALT}
                            />
                            <ActionLabelDiv>{MCConstants.MESSAGE_CENTER_MARK_AS_UNREAD}</ActionLabelDiv>
                        </ButtonsDiv>
                    </>
                )}
            </ActionButtonsDiv>
        </ButtonsContainerDiv>
    );
}

MessageCenterButtonsComponent.defaultProps = {
    selectAll: false,
    enableDelete: false,
    enableRead: false,
    enableUnread: false,
};
MessageCenterButtonsComponent.propTypes = {
    selectAll: PropTypes.bool,
    enableDelete: PropTypes.bool,
    enableRead: PropTypes.bool,
    enableUnread: PropTypes.bool,
};

export default MessageCenterButtonsComponent;
