import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { addProfileContactInformations, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { setAddress, resetAddressInfo } from 'shared/Actions/AddressAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import AddContactInfoComponent from './AddContactInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData, address } = state;
    const { isError } = profileInformationData;

    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        addressInfo: address,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    addProfileContactInformations,
    clearExistingActions,
    setAddress,
    resetAddressInfo,
    analyzeClickEvent, 
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(AddContactInfoComponent));
