import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { encrypt, checkValidity } from '../../../utils';
import { WInput, CommonButtons } from '../../../common';
import { assignObj, Rules, styles, data } from './MemberValidationConstants';

class MemberValidationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            victoryMemberId: '',
            usaaMemberId: '',
            errorMsg: [],
        };
    }

    checkErrors = e =>
        Rules[e.target.name]
            ? checkValidity({
                  rules: Rules[e.target.name],
                  value: e.target.value,
              })
            : '';

    onChangeVictory = e => {
        const { errorMsg } = this.state;
        const err = this.checkErrors(e);
        errorMsg.victoryMemberId = err;
        errorMsg.usaaMemberId = '';
        this.setState({
            victoryMemberId: e.target.value,
            usaaMemberId: '',
            errorMsg,
        });
    };

    onChangeUsaa = e => {
        const { errorMsg } = this.state;
        const err = this.checkErrors(e);
        errorMsg.victoryMemberId = '';
        errorMsg.usaaMemberId = err;
        this.setState({
            victoryMemberId: '',
            usaaMemberId: e.target.value,
            errorMsg,
        });
    };

    onPrimaryClick = () => {
        let memberId;
        let memberType;
        const { victoryMemberId, usaaMemberId } = this.state;
        const { directMemberID } = this.props;
        if (victoryMemberId !== '') {
            memberId = victoryMemberId;
            memberType = 'VCM';
        } else if (usaaMemberId !== '') {
            memberId = usaaMemberId;
            memberType = 'USAA';
        } else {
            return;
        }
        (async () => {
            const newmemberId = await encrypt(memberId);
            directMemberID({
                memberId: newmemberId,
                memberType,
                flow: 'memberSignUp'
            });
        })();
    };

    render() {
        const { victoryMemberId, usaaMemberId, errorMsg } = this.state;
        const disabledState = (!isEmpty(errorMsg.victoryMemberId) || !isEmpty(errorMsg.usaaMemberId) || !(!isEmpty(victoryMemberId) || !isEmpty(usaaMemberId))  );
        return (
            <>
            <Row>
              <Col md lg xl style={assignObj({ width: '78.5%' })} />
              <Col md lg xl>
                <Row style={assignObj({ marginLeft: 0})}>
                    <WInput
                        label="USAA Member ID"
                        placeholder=""
                        id="usaaMemberId"
                        type="number"
                        name="usaaMemberId"
                        value={usaaMemberId}
                        labelsm={20}
                        valuesm={10}
                        maxlength={9}
                        onChange={this.onChangeUsaa}
                        aria-required={false}
                        inputfieldstyle={assignObj({borderColor: '#CCC' , borderWidth: '1px', borderStyle:'solid' })}
                        errortext={errorMsg.usaaMemberId}
                        infoMsg={data.infoMsg}
                        errorstyle={assignObj({marginTop: 20})}
                    />
                </Row>
                <Row className="justify-content-md-center">or</Row>
                <Row style={assignObj({ marginLeft: 0})}>
                    <WInput
                        label="Victory Member ID"
                        placeholder=""
                        id="victoryMemberId"
                        type="number"
                        name="victoryMemberId"
                        value={victoryMemberId}
                        labelsm={20}
                        valuesm={10}
                        maxlength={9}
                        onChange={this.onChangeVictory}
                        aria-required={false}
                        inputfieldstyle={assignObj({borderColor: '#CCC' , borderWidth: '1px', borderStyle:'solid' })}
                        errortext={errorMsg.victoryMemberId}
                    />
                </Row>
                </Col>
                </Row>
                <Row>
                    <div aria-hidden="true" />
                    <div style={assignObj({width: '91.6%'})}>
                        <CommonButtons backstyle={styles.hide} nextClick={this.onPrimaryClick} disabled={disabledState} blockstyle={styles.commonButtons} />
                    </div>
                </Row>
            </>
        );
    }
}


MemberValidationComponent.propTypes = {
    directMemberID: PropTypes.func,
}

MemberValidationComponent.defaultProps = {
    directMemberID: () => {},
}

export default MemberValidationComponent;
