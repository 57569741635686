import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const OffScreen = styled.span`
    height: 1px;
    width: 1px; 
    position: absolute; 
    overflow: hidden; 
    top: -10px;
`

const OffScreenText = (props) => {
    const { hideTxt, ...restProps} = props;
    return (
        <OffScreen {...restProps}>{hideTxt}</OffScreen>
    )
};

OffScreenText.propTypes = {
    hideTxt: PropTypes.string,
};

OffScreenText.defaultProps = {
    hideTxt: ''
};

export default OffScreenText;