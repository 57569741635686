/**
 * ADP Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to Manage Interested Parties
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Accordion, Card, Form, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Address from '../Address';
import { WBreadCrumb, CommonButtons, WInputCheckBox, WSpinner, WSaveModal, WErrorModal, WInput } from '../../../common';
import Consts from './Consts';
import './ADP.css';
import styles from '../PersonalInfo/styles';

const assignObj = obj => {
    return obj;
};

const Optionaltext = styled.span`
    text-align: left;
    font: Bold 12px/22px benton-sans;
    letter-spacing: 0;
    color: #698aac;
    opacity: 0.5;
    padding-left: 10px;
`;

const SectionHeading = styled.span`
    text-align: left;
    font: 700 22px/35px Yorkten Slab;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    padding-left: 10px;
`;

// const SwitchMargin = styled.div`
// margin-left:80px;
// `;

// const SwitchContainer = styled.div`
// width: 25%,
//     height: 42px,
//     background: #FFFFFF 0% 0% no-repeat padding-box,
//     border: 1px solid #707070,
//     border-radius: 21px,
//     opacity: 1,
//     margin-left: 75px
// `;

const SeasonalWrapper = styled.div`
    padding: 0;
    margin: 0;
    .addressFields .row {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .row {
        padding-bottom: 0;
    }
    label {
        min-width: 360px;
    }
`;

const AccountSection = styled(Row)`
    background: #f4f7f8 0% 0% no-repeat padding-box;
    border: 1px solid #dadee2;
    opacity: 1;
    min-height: 80px;
    align-items: center;
    margin-bottom: 40px;
    .col {
        max-width: 180px;
    }
`;

const AccountInfo = styled(Col)`
    border-right: 1px solid #dadee2;
    min-height: 80px;
    .value {
        margin-right: 10px;
        margin-left: 10px;
    }
`;

const TextKey = styled(Row)`
    margin-top: 15px;
    margin-bottom: 5px;
    justify-content: center;
    font: Bold 14px/17px benton-sans;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;

const TextValue = styled(Row)`
    justify-content: center;
    text-align: left;
    font: Medium 13px/22px benton-sans;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    background: #d2e4ea 0% 0% no-repeat padding-box;
    border: 1px solid #486d90;
    border-radius: 30px;
    opacity: 0.47;
`;
export default class AIP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            onSubmitFlag: false,
            selectedConsent: false,
            invalidDate: false,
            inValidEmail: false,
            swithOff: false,
            switchOn: true,
            back: false,
        };
        this.err = '';
    }

    handleChange = e => {
        if (e.target.id === 'serviceToDate' || e.target.id === 'serviceFromDate') {
            const startDate = document.getElementById('serviceFromDate').value;
            const endDate = document.getElementById('serviceToDate').value;
            if (startDate && endDate && Date.parse(startDate) >= Date.parse(endDate)) {
                this.err = 'To date should be greater than From date';
            } else {
                document.getElementById('serviceFromDate').style.outline = '';
                document.getElementById('serviceToDate').style.outline = '';
                this.err = '';
            }
            this.setState({ invalidDate: !!this.err });
            if (!this.err) {
                e.target.style.outline = '';
            } else {
                e.target.style.outline = '1px solid red';
            }
        } else if (e.target) {
            this.setState({ selectedConsent: !!e.target.checked });
        }
    };

    handleBlur = e => {
        if (e.target.id === 'emailAdd') {
            // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const emailRegex = /^\w+[+.\w-]*@\w+[+.\w-]*\.([a-z]{2,4}|\d+)$/i;
            const email = e.target.value;
            const isValid = emailRegex.test(email.toLowerCase());
            this.setState({ inValidEmail: !isValid });
        }
    };

    switchOnMethod = () => {
        const { switchOn, swithOff } = this.state;
        this.setState({
            switchOn: !switchOn,
            swithOff: !swithOff,
        });
    };

    // radioClick = index => value => {
    //     if(value.regulatoruQ === 'Online') {
    //         this.setState({ selectedPreference: index});
    //     } else if(value.regulatoruQ === 'Paper'){
    //         this.setState({ selectedPreference: ''});
    //     }
    // }
    // Fixed for eslint
    radioClick = () => {
        // if (value.regulatoruQ === 'Online') {
        //     // this.setState({ selectedPreference: index});
        // } else if (value.regulatoruQ === 'Paper') {
        //     //  this.setState({ selectedPreference: ''});
        // }
    };

    generateKey = () => {
        return Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    };

    cardHeaderClick = () => {};

    handleSubmit = () => {};

    getValue = () => {};

    handleBack = () => {
        this.setState({ back: true });
    };

    renderAccountInfo = () => {
        return (
            <AccountSection noGutters>
                {Consts.accountFields.map(fields => (
                    <AccountInfo key={this.generateKey()}>
                        <TextKey noGutters>{fields.key}</TextKey>
                        <TextValue noGutters className="value">
                            {fields.value}
                        </TextValue>
                    </AccountInfo>
                ))}
            </AccountSection>
        );
    };

    renderSeasonalAddress = () => {
        const { invalidDate, inValidEmail } = this.state;
        return (
            <Accordion defaultActiveKey="0" id="seasonal">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h4"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            styles={styles.accordionToggleStyles}
                        >
                            <SectionHeading>{Consts.seasonalHeading}</SectionHeading>
                            <Optionaltext>{Consts.optional}</Optionaltext>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" id="cardBody">
                        <Card.Body>
                            <SeasonalWrapper>
                                <Address
                                    setValue={this.getValue}
                                    states={Consts.states.value}
                                    labelAdd="Address"
                                    required
                                />
                                <Row>
                                    <WInput
                                        label="Email Address"
                                        placeholder="abc@gmail.com"
                                        className=""
                                        id="emailAdd"
                                        type="email"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        required
                                    />
                                    {inValidEmail && <span id="emailAdd-err">{Consts.erremailAdd}</span>}
                                </Row>
                                <Row>
                                    <Form.Group as={Row} className="no-gutters" style={assignObj({ width: '100%' })}>
                                        <Form.Label className="col-sm-2">{Consts.dateOfService}</Form.Label>
                                        <div className="serviseDates">
                                            <Form.Control
                                                type="date"
                                                placeholder="From Date"
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                className="fromDate"
                                                id="serviceFromDate"
                                                required
                                            />
                                            <Form.Control
                                                type="date"
                                                placeholder="To Date"
                                                className="toDate"
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                id="serviceToDate"
                                                required
                                            />
                                        </div>
                                        {invalidDate && <span id="serviceToDate_err">{Consts.errServiceToDate}</span>}
                                    </Form.Group>
                                </Row>
                            </SeasonalWrapper>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    renderPage = () => {
        const { showSaveModal, selectedConsent, back, onSubmitFlag } = this.state;
        return (
            <>
                {(back || onSubmitFlag) && <Redirect to="/interestedParties" />}
                <div className="aip-wrapper">
                    <div className="container">
                        <WBreadCrumb activeCrumb={Consts.activeEditUrl} breadCrumbItems={Consts.editPreferencesUrls} />
                        <h2 className="adp-page-heading">{Consts.pageHeading}</h2>
                        {this.renderAccountInfo()}
                        <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        <div className="esign-cards">{this.renderSeasonalAddress()}</div>
                        <div className="checkwrapper">
                            <Row className="check-content">
                                <WInputCheckBox
                                    checkboxwrapperstyle={assignObj({
                                        position: 'relative',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        marginLeft: '20px',
                                    })}
                                    checkInputStyle={assignObj({
                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                        border: '1px solid #4F799F',
                                        borderRadius: 3,
                                        opacity: 1,
                                    })}
                                    checked={selectedConsent}
                                    value={selectedConsent}
                                    onChange={this.handleChange}
                                    checkboxstyle={assignObj({ position: 'absolute', top: '7px' })}
                                    labelstyle={assignObj({
                                        paddingTop: '3px',
                                        textAlign: 'left',
                                        font: '600 16px/25px benton-sans',
                                        letterSpacing: 0,
                                        color: '#3E607E',
                                        opacity: 1,
                                    })}
                                    label={Consts.applyALL}
                                />
                            </Row>
                        </div>
                    </div>
                    <div className="esign-buttons">
                        <CommonButtons
                            backClick={this.handleBack}
                            submitClick={this.handleBack}
                            cancelClick={this.handleBack}
                            saveClick={this.validateFields}
                            style={assignObj({ padding: 20 })}
                            submit="true"
                            showSave
                        />
                    </div>
                </div>
                <hr />
            </>
        );
    };

    render() {
        const { loading, error, onSubmitFlag } = this.state;
        return (
            <div className="MainContainer">
                {<WSpinner loading={loading} />}
                {error && <WErrorModal show={error} message="Technical error" />}
                {!error && this.renderPage()}
                {onSubmitFlag ? <Redirect to="/" /> : ''}
            </div>
        );
    }
}

AIP.propTypes = {};

AIP.defaultProps = {};
