// Master Registration Action constants
export const GENERAL_INVESTMENT_ACCOUNT = 'General Investment Account';
export const UTMA_ACCOUNT = 'UTMA Account';
export const IRA_ACCOUNT = 'IRA Account';

// Master registration Action Type constants
// Master Registration Account - get list of master registration accounts
export const GET_MASTER_REGISTRATION_ACCOUNT_LIST = 'GET_MASTER_REGISTRATION_ACCOUNT_LIST';
export const GET_EXCHANGE_MASTER_REGISTRATION_ACCOUNT_LIST = 'GET_EXCHANGE_MASTER_REGISTRATION_ACCOUNT_LIST';
export const GET_MASTER_REGISTRATION_ACCOUNT_LIST_START = 'GET_MASTER_REGISTRATION_ACCOUNT_LIST_START';
export const GET_MASTER_REGISTRATION_ACCOUNT_LIST_SUCCESS = 'GET_MASTER_REGISTRATION_ACCOUNT_LIST_SUCCESS';
export const GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE = 'GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE';
export const GET_MASTER_REG_ACCOUNT_LIST_LIQUIDATE = 'GET_MASTER_REG_ACCOUNT_LIST_LIQUIDATE';
export const SET_DISPLAY_NOTIFICATION_SUCCESS = 'SET_DISPLAY_NOTIFICATION_SUCCESS';
export const SET_DISPLAY_NOTIFICATION_FAILURE = 'SET_DISPLAY_NOTIFICATION_FAILURE';
export const CLEAR_MASTER_REG_INFO = 'CLEAR_MASTER_REG_INFO';
