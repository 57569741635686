import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';

const WTable=props=>{
    const {borderless, responsive, size} = props;
    return(   
        <Table {...props} borderless={borderless} 
            responsive={responsive} size={size}/>    
)};

WTable.propTypes={
    borderless:PropTypes.bool,
    responsive:PropTypes.bool,
    size:PropTypes.string,
}
    

WTable.defaultProps={     
   borderless:true,
   responsive:true,
   size:"md"
}

export default WTable;



