import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, forEach } from 'lodash';
import moment from 'moment-timezone';
import { VCMSelectWithoutLabel, VCMDatePickerWithoutLabel, BottomNavWrapper, AccountAddress } from '../../../../../../common';
import { formatDate, formatDateToTwoDigit, isValidDate } from '../../../../../../utils';
import { RMD_FREQUENCY, LOCALE, RMD_MAX_START_DATE } from '../../../../constant';
import TaxOptionsToWithHold from '../../../../Common/TaxSelector/TaxOptionsToWithHold';
import FundSourceRMD from './FundSourceComponentRMD'
import YouSelect from '../YouSelect';
import FieldValue from 'modules/CustomerManagementModule/components/FieldValue';
import { getSessionValues } from 'shared/Helpers/Utils';

const frequencyStyles = {
    input: {
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: '0px',
        width: '20rem'
    },
    inputField: {
        marginTop: '.625rem',
    },
    label: {
        textAlign: 'left',
        fontSize: '14px',
        fontFamily: 'benton-sans',
        letterSpacing: '0px',
        color: '#56565A',
        fontWeight: 'bold',
        maxWidth: '100%',
        paddingTop: '0px',
        paddingBottom: '0px',
    }
}

const Section = styled.div`
    width: 50%;
    font-size: 1rem;
    padding-right: 40px;
`;

const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
`;

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

class TaxWithholdingDepositAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            retrivedState: false,
            disabledNextBtn: true,
            displayDetails: {
                titleTxt: 'Tax Withholding / Deposit Account',
                editTxt: 'Edit',
                pageIndex: props.pageIndex,
                data: [],
            },
            bankDetails: [],
            selectedBank: null,
            frequency: '',
            startDate: formatDate(this.calcWorkingDays(new Date(), 2), 'MM/DD/YYYY'),
            nextBusinessDate: this.calcWorkingDays(new Date(), 2),
            tax: props.tax || {
                fedTaxAmount: '',
                fedTaxPercent: '',
                stateTaxAmount: '',
                stateTaxPercent: '',
                totalReceived: '',
                totalTaxAmount: '',
                distributionAmount: '',
                stateTaxWithHold: '',
            }
        };
        this.fedTaxPercentRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, bankAccountReducerData } = nextProps;
        if (!prevState.retrivedState && savedState) {
            const selectedBank = get(savedState, 'selectedBank[0].details', []);

            const bankDetails = savedState.bankDetails;
            let isBankSelected = false;
            let isFirstSelected = false;
            if (selectedBank) {
                forEach(bankDetails, (bank, index) => {
                    isBankSelected = bank.bankAccountNumber === selectedBank.bankAccountNumber && bank.bankRoutingNumber === selectedBank.bankRoutingNumber;
                    
                    bankDetails[index] = {
                        ...bank,
                        selected: isBankSelected && !isFirstSelected
                    };

                    if (isBankSelected) {
                        isFirstSelected = true;
                    }    
                });
            }

            return {
                ...savedState,
                bankDetails: bankDetails,
                retrivedState: true,
            };
        }
        const returnObj = {};
        const { bankDetails = [], isSuccess: bankDetailsSuccess } = bankAccountReducerData || {};
        if (bankDetails.length && !prevState.bankDetails.length && bankDetailsSuccess) {
            returnObj.bankDetails = bankDetails;
        }

        return Object.keys(returnObj).length ? returnObj : null;
    }

    componentDidMount() {
        // const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();  
        const {accesstoken, vcmid } = params;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { getBankAccountInfo } = this.props;
        const { bankDetails } = this.state;

        if(!bankDetails.length) {
            getBankAccountInfo({ token: accesstoken, vcmid });
        }
    }


    componentWillUnmount() {
        const { saveChildrenState, pageIndex, stateCodeGroup } = this.props;
        // sessionStorage.setItem(STEP_KEY, dataAnalyticsPage || 'Account Selection');
        const { displayDetails, frequency, startDate, tax, depositAccount } = this.state;
        const formattedFreqency = frequency !== '' ? RMD_FREQUENCY.find(item => item.key === frequency).value : '';

        displayDetails.data = [
            {
                field: 'Frequency',
                value: `${formattedFreqency} starting ${startDate}`,
                formattedValue: <>{formattedFreqency} <br />commencing {moment(startDate).format('MMMM DD, YYYY')}</>,
            },
            {
                field: 'Federal Tax %',
                value: `${tax.fedTaxPercent} %`
            },
        ];

        if (stateCodeGroup[0] !== 'group1' && tax.stateTaxWithHold) {
            displayDetails.data.push(
                {
                    field: 'State Tax %',
                    value: `${tax.stateTaxPercent} %`
                }
            );
        }

        if (depositAccount) {
            displayDetails.data.push(...depositAccount);
        }

        this.setState({
            displayDetails
        });

        saveChildrenState(this.state, pageIndex);
    }

    handleFrequencyChange = (event) => {
        this.setState(prevState => {
            const disabledNextBtn = this.shouldDisableNextButton({
                frequency: event.target.value
            });

            return {
                frequency: event.target.value,
                disabledNextBtn: disabledNextBtn
            }
        });
    }

    handleStateTaxWithHold = value => {
        this.setState(() => {
            // TO BE invetigated if we need this validation!!!
            // const disabledNextBtn = this.shouldDisableNextButton({
            //     stateTaxWithHold: value
            // });

            return {
                tax: {
                    ...this.state.tax,
                    stateTaxWithHold: value
                },
                // disabledNextBtn
            }
        });
    }

    setTaxStateValues = taxPayload => {
        const {
            fedTaxAmount,
            fedTaxPercent,
            stateTaxAmount,
            stateTaxPercent,
            totalReceived,
            totalTaxAmount,
            distributionAmount,
            stateTaxWithHold,
        } = taxPayload;

        this.setState({
            tax: {
                fedTaxAmount,
                fedTaxPercent,
                stateTaxAmount,
                stateTaxPercent,
                totalReceived,
                totalTaxAmount,
                distributionAmount,
                stateTaxWithHold,
            }
        });
    };

    calcWorkingDays = (fromDate, days) => {
        let count = 0;
        while (count < days) {
            fromDate.setDate(fromDate.getDate() + 1);
            if (fromDate.getDay() != 0 && fromDate.getDay() != 6) // Skip weekends
                count++;
        }
        return fromDate;
    }

    handleStartDateChange = (event) => {
        this.setState(prevState => {
            
            const nextBusinessDate = this.calcWorkingDays(new Date(), 2);

            const disabledNextBtn = this.shouldDisableNextButton({
                startDate: event.target.value,
                nextBusinessDate
            });

            return {
                startDate: event.target.value,
                nextBusinessDate,
                disabledNextBtn: disabledNextBtn
            }
        });
    }

    setPaymentMethod = (selectedBank) => {
        this.setState(prevState => {
            const disabledNextBtn = this.shouldDisableNextButton({
                selectedBank
            });

            return {
                selectedBank,
                disabledNextBtn: disabledNextBtn
            }
        });
    }

    onSelecteMethodClick = (displayDetails) => {
        this.setState({
            depositAccount: displayDetails
        });
    }

    shouldDisableNextButton = ( stepData ) => {
        const newState = {
            startDate: this.state.startDate,
            nextBusinessDate: this.state.nextBusinessDate,
            frequency: this.state.frequency,
            selectedBank: this.state.selectedBank,
            // stateTaxWithHold: this.state.tax.stateTaxWithHold,
            // stateTaxPercent: this.state.tax.stateTaxPercent,
            ...stepData
        }

        return !(
            isValidDate(newState.startDate) &&
            newState.nextBusinessDate &&
            formatDate(newState.startDate) >= formatDate(newState.nextBusinessDate) && 
            newState.frequency &&
            newState.selectedBank 
            // TO BE invetigated if we need this validation!!!
            // && (!newState.stateTaxWithHold || (newState.stateTaxWithHold && newState.stateTaxPercent))
        );
    }

    onTaxChange = (fedTaxPercent, stateTaxPercent) => {
        this.setState(() => {
            // TO BE invetigated if we need this validation!!!
            // const disabledNextBtn = this.shouldDisableNextButton({
            //     stateTaxPercent
            // });

            return {
                tax: {
                    ...this.state.tax,
                    fedTaxPercent,
                    stateTaxPercent
                },
                // disabledNextBtn
            }
        });
    }

    render() {
        const { navigationObj, pageIndex, 
            fundFrom,
            bankDetailsSectionRef, fundFromRef, 
            answerDetails, accDetails, residenceState, masterRegId
        } = this.props;

        const { 
            disabledNextBtn,
            bankDetails = [],
            frequency,
            startDate,
            tax,
            nextBusinessDate
         } = this.state

        const minStartDate = new Date(nextBusinessDate);
        const minErrTxtStart = nextBusinessDate ? `Invalid start date. Please select a date min of ${formatDateToTwoDigit(
            nextBusinessDate,
            'en-US',
        )}` : "";

        const isOfflineSelected = get(this.state,'selectedBank[0].mode', 'Online') === 'Offline';
        const selectedBankDetails = get(this.state,'selectedBank[0].details', null);

        fundFrom.methods[1].details = [...bankDetails];
        fundFrom.methods[0].details = [{
            "key": "Check",
            "text": "Receive a check",
            "selected": isOfflineSelected
        }];
        const accountDetails = accDetails ? [accDetails] : [];

        return (
            <div>
                <SectionWrapper>
                    <YouSelect pageIndex={pageIndex}
                        pageSubIndex={-1}
                        answerDetails={answerDetails}
                        accountDetails={accountDetails}
                    />
                    <Section>
                        <SectionTitle>Select your distribution frequency and provide your tax withholding</SectionTitle>
                        <SectionWrapper>
                            <Section>
                                <SectionTitle>Frequency</SectionTitle>
                                <VCMSelectWithoutLabel
                                    id="ScheduleinvestVal"
                                    itemlist={RMD_FREQUENCY}
                                    label="Frequency"
                                    onChange={this.handleFrequencyChange}
                                    value={frequency}
                                    valuesm={6}
                                    labelsm={6}
                                    name="RMDFrequency"
                                    role="combobox"
                                    inputstyle={frequencyStyles.input}
                                    labelstyle={frequencyStyles.label}
                                    inputfieldstyle={frequencyStyles.inputField}
                                />
                            </Section>
                            <Section>
                                <SectionTitle>Start Date</SectionTitle>
                                <VCMDatePickerWithoutLabel
                                    id="ScheduleonTheDayVal"
                                    minErrorTxt={minErrTxtStart}
                                    min={formatDate(minStartDate)}
                                    max={formatDate(RMD_MAX_START_DATE)}
                                    onChange={this.handleStartDateChange}
                                    value={formatDate(startDate)}
                                    valuesm={6}
                                    name="RMDStartDate"
                                    role="combobox"
                                    locale={LOCALE}
                                    autoSetMinDate={true}
                                />
                            </Section>
                        </SectionWrapper>
                        <TaxOptionsToWithHold
                            setTaxStateValues={this.setTaxStateValues}
                            residenceState={residenceState}
                            masterRegId={masterRegId}
                            onTaxChange={this.onTaxChange}
                            handleStateTaxWithHold={this.handleStateTaxWithHold}
                            fedTaxPercent={parseFloat(tax.fedTaxPercent)}
                            stateTaxPercent={parseFloat(tax.stateTaxPercent)}
                            stateTaxWithHold={tax.stateTaxWithHold}
                            fedTaxPercentRef={this.fedTaxPercentRef}
                        />

                        <FundSourceRMD
                            savedState={{selectedBank: selectedBankDetails}}
                            setPaymentMethod = {this.setPaymentMethod}
                            ref={bankDetailsSectionRef}
                            forwardRef={fundFromRef}
                            fundFrom={fundFrom}
                            pageIndex={pageIndex}
                            onSelecteMethodClick={this.onSelecteMethodClick}
                            showOptions={isOfflineSelected}
                            checkAddress={<AccountAddress address={accDetails.address} />}
                        />
                        {selectedBankDetails === 'Check' && (
                                <FlexColumnDiv>
                                    <FieldValue
                                        htmlFor="vcm-cms-current-address"
                                        label='Address'
                                        value={<AccountAddress address={accDetails.address} />}
                                        mb='1.7'
                                        labelsm={3}
                                        valuesm={9}
                                    />
                                </FlexColumnDiv>
                        )}

                    </Section>
                </SectionWrapper>

                <BottomNavWrapper
                    {...navigationObj}
                    showBack={true}
                    disabled={disabledNextBtn}
                // dataAnalyticId={RMD_ADD_PLAN_DETAILS_NAV_BUTTON_ID}
                />
            </div>
        );
    }
}

TaxWithholdingDepositAccount.propTypes = {
    pageIndex: PropTypes.number,
    answerDetails: PropTypes.arrayOf(Object),
    accDetails: PropTypes.instanceOf(Object),
    getBankAccountInfo: PropTypes.instanceOf(Object),
    saveChildrenState: PropTypes.instanceOf(Object),
    stateCodeGroup: PropTypes.arrayOf(String),
    navigationObj: PropTypes.instanceOf(Object),
    bankAccountReducerData: PropTypes.instanceOf(Object),
    fundFrom: PropTypes.instanceOf(Object),
    residenceState: PropTypes.string,
    masterRegId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onCancel: PropTypes.func,
};

TaxWithholdingDepositAccount.defaultProps = {
    pageIndex: 0,
    answerDetails: [],
    accDetails: {},
    getBankAccountInfo: {},
    saveChildrenState: {},
    stateCodeGroup: [],
    navigationObj: {},
    bankAccountReducerData: {},
    fundFrom: {},
    residenceState: '',
    masterRegId: 0,
    onCancel: () => { },
};

export default TaxWithholdingDepositAccount;