import React, { Component } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import './pdfViewer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

class PdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
    };
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  componentDidUpdate() {
    const { maximumModalHeight } = this.props;
    const doc = document.getElementsByClassName('PDFDocument');
    if (doc.length) {
      doc[0].setAttribute('style', `max-height:${maximumModalHeight - 150}px`);
    }
  }
  
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  render() {
    const { numPages } = this.state;
    const { file, myDocumentref } = this.props;
    return (
      <Document
        file={file}
        onLoadSuccess={this.onDocumentLoadSuccess}
        className="PDFDocument"
        options={options}
        ref={myDocumentref}
        externalLinkTarget="_blank"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="PDFPage"
            scale={isMobileOnly ? 0.7 : 2}
            renderTextLayer={true}
            renderAnnotationLayer={true}
          />
        ))}
      </Document>
    );
  }
}

PdfViewer.propTypes = {
  file: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  myDocumentref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  maximumModalHeight: PropTypes.number,
};

PdfViewer.defaultProps = {
  file: '',
  myDocumentref: {},
  maximumModalHeight: 0,
};

export default PdfViewer;
