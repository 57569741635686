import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import { Auth } from 'aws-amplify';
// import axios from 'axios';
// import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router';
import IdleTimeOut from 'common/IdleTimeOut';
import routeConstants, { preLoginRoutes } from 'routeConstants';
import MarstoneRedirectionComponent from 'modules/AccountManagementModule/MarstoneRedirection/MarstoneRedirectionComponent';
import UserAccountInformation from 'modules/UserManagementAndSecurityModule/UserDetails/UserAccountInformationContainer';
import AccountRegistration from 'modules/AccountRegistration';
import DocumentCenter from './modules/ReportsDocumentsModule/DocumentCenter/DocumentCenterContainer';
import { routeAndPageInfoMapping } from './utils/AdobeAnalytics/Constants';
import ScrollToTop from './ScrollToTop';
import TermsAndConditionsComponent from './modules/AccountManagementModule/TermsAndConditions';
import AccountTypeComponent from './modules/AccountManagementModule/AccountType';
import PersonalInfoComponent from './modules/AccountManagementModule/PersonalInfo';
import FundSelectionComponent from './modules/AccountManagementModule/FundSelection';
import InvestmentSelectionComponent from './modules/AccountManagementModule/InvestmentSelection';
import CompareFundsComponent from './modules/AccountManagementModule/CompareFunds';
import FundSummaryComponent from './modules/AccountManagementModule/FundSummary';
import PerformanceComponent from './modules/AccountManagementModule/Performance';
import QuickFactsComponent from './modules/AccountManagementModule/QuickFacts';
import SummaryComponent from './modules/AccountManagementModule/Summary';
import PreferencesComponent from './modules/AccountManagementModule/Preferences';
import VerifyComponent from './modules/AccountManagementModule/Verify';
import EsignComponent from './modules/AccountManagementModule/Esign';
import OopsPage from './modules/AccountManagementModule/OopsPage';
import ServerErrorPage from './modules/AccountManagementModule/ServerErrorPage';
import ESA from './modules/AccountManagementModule/ESA';
import Application from './modules/AccountManagementModule/Application';
import PersonalInfoChild from './modules/AccountManagementModule/PersonalInfoChild';
import BeneficiaryInfoChild from './modules/AccountManagementModule/BeneficiaryInfoChild';
import EditGeneralAccountPreferencesComponent from './modules/AccountManagementModule/EditGeneralAccountPreferences';
import GeneralAccountPreferencesComponent from './modules/AccountManagementModule/GeneralAccountPreferences';
import TaxDocuments from './modules/AccountManagementModule/TaxDocuments';
import InterestedParties from './modules/AccountManagementModule/InterestedParties';
import AIP from './modules/AccountManagementModule/AIP';
import RefreshDialog from './modules/AccountManagementModule/RefreshDialog';
import ConfirmAccountOpening from './modules/AccountManagementModule/ConfirmAccountOpening';
import ManageBeneficiariesAccounts from './modules/AccountManagementModule/ManageBeneficiaries/AccountsContainer';
import ManageBeneficiaries from './modules/AccountManagementModule/ManageBeneficiaries';
import AddPrimaryBeneficiary from './modules/AccountManagementModule/AddPrimaryBeneficiary';
import SpecialtyAccountApplicationForms from './modules/AccountManagementModule/SpecialtyAccountApplicationForms';

// paper flow
import AgreementComponent from './modules/AccountManagementModule/AgreementPaperFlow';
import PrivacyComponent from './modules/AccountManagementModule/PrivacyPaperFlow';
import ApplicationComponent from './modules/AccountManagementModule/ApplicationPaperFlow';
import UploadOrMailComponent from './modules/AccountManagementModule/UploadOrMailPaperFlow';

// Customer Management Components
import CMSRoute from './modules/CustomerManagementModule/CMSRoute';
import BasicInfoComponent from './modules/CustomerManagementModule/BasicInformation';
import AddContactInfoComponent from './modules/CustomerManagementModule/ContactInformation/AddOrEditContactInfo';
import AddOrEditAccountAddress from './modules/CustomerManagementModule/ContactInformation/AddOrEditAccountAddress';
import ContactInfoComponent from './modules/CustomerManagementModule/ContactInformation/ManageContactInfo';
import RelationshipContactInfoComponent from './modules/CustomerManagementModule/ContactInformation/RelationshipManageContactInfo';
import RelationshipAddContactInfoComponent from './modules/CustomerManagementModule/ContactInformation/RelationshipAddOrEditContactInfo';
import AddEmailInfoComponent from './modules/CustomerManagementModule/EmailInformation/AddOrEditEmailInfo';
import EmailInfoComponent from './modules/CustomerManagementModule/EmailInformation/ManageEmailInfo';
import RelationshipAddEmailInfoComponent from './modules/CustomerManagementModule/EmailInformation/RelationshipAddOrEditEmailInfo';
import RelationshipEmailInfoComponent from './modules/CustomerManagementModule/EmailInformation/RelationshipManageEmailInfo';
import RelationshipInfoComponent from './modules/CustomerManagementModule/RelationShip/RelationshipInfoAdd/RelationshipAdd';
import RelationshipManage from './modules/CustomerManagementModule/RelationShip/RelationshipInfoManage/RelationshipManage';
import PrimaryInfoComponent from './modules/CustomerManagementModule/PersonalInformation/EditPersonalInfo';
import RelationshipPrimaryInfoComponent from './modules/CustomerManagementModule/PersonalInformation/RelationshipEditPersonalInfo';
import AddPhoneInfoComponent from './modules/CustomerManagementModule/PhoneInformation/AddOrEditPhoneInfo';
import PhoneInfoComponent from './modules/CustomerManagementModule/PhoneInformation/ManagePhoneInfo';
import MilitaryInfoComponent from './modules/CustomerManagementModule/MilitaryInformation/EditMilitaryInfo';
import FinancialInfoComponent from './modules/CustomerManagementModule/FinancialInformation/EditFinancialInfo';
import EmploymentInfoComponent from './modules/CustomerManagementModule/EmploymentInformation/EditEmploymentInfo';
import ProfileAndPreferencesDashboard from './modules/CustomerManagementModule/ProfileAndPreferencesDashboard';
import MarketingAndPrivacyComponent from './modules/CustomerManagementModule/MarketingAndPrivacy';
import DeliverySettings from './modules/CustomerManagementModule/DeliverySettings';
import AccountMessaginComponent from './modules/CustomerManagementModule/AccountMessagingMainScreen';
import GeneralDocumentsComponent from './modules/CustomerManagementModule/GeneralDocuments';
import SecurityFraudComponent from './modules/CustomerManagementModule/Security&Fraud';
import DeviceManagementComponent from './modules/CustomerManagementModule/DeviceManagement';
import ManageRelationPhone from './modules/CustomerManagementModule/RelationShip/RelationshipInfoManage/PhoneInfoManage';
import RelationPhone from './modules/CustomerManagementModule/RelationShip/RelationshipInfoManage/PhoneInfoEdit';

// User Management and Security Management Components
import SecurityPreferencesComponent from './modules/UserManagementAndSecurityModule/SecurityPreferences';
import ChangeSignInMethodOptionsComponent from './modules/UserManagementAndSecurityModule/ChangeSignInMethods';
import ChangeSignInByOTPComponent from './modules/UserManagementAndSecurityModule/ChangeSignInByOTP';
import ChangeSignInBySoftTokenComponent from './modules/UserManagementAndSecurityModule/ChangeSignInBySoftToken';
import ChangeSignInByPushNotificationComponent from './modules/UserManagementAndSecurityModule/ChangeSignInByPushNotification';
import MobileQuickSignInComponent from './modules/UserManagementAndSecurityModule/MobileQuickSignIn';
import ModifySecurityQnsComponent from './modules/UserManagementAndSecurityModule/ModifySecurityQuestions';
import RetrievePasswordComponent from './modules/UserManagementAndSecurityModule/RetrievePassword';
import ReviewPersonalInfo from './modules/UserManagementAndSecurityModule/Screens/ReviewPersonalDetails/ReviewPersonalDetails';
import MemberSignUpComponent from './modules/UserManagementAndSecurityModule/MemberSignUp';

// TODO : THIS HAS TO BE REMOVED. Adding temporarily for testing
import TestingLinksComponent from './modules/TestingLinksModule/TempLinks';

import MemberDobValidationComponent from './modules/UserManagementAndSecurityModule/MemberDobValidation';
import {
    RegisterEmail,
    VerifyEmail,
    RegisterOnlineId,
    RegisterPassword,
    PersonalDetails,
    // TermAndConditions,
    EnterOtp,
    SecurityDetails,
    SignIn,
    // ResetPasswordComponent,
    CreatePasswordComponent,
    OtpAuthentication,
    ConfirmOtp,
    SetupSecurityQuestions,
    ConfirmSecurityQuestions,
    SignInBySoftToken,
    RecoverOnlineId,
    CheckInbox,
    JovSSN,
    JovMobile,
    CreateNewPassword,
    Preferences,
    JovRegisterOnlineId,
    JovRegisterPassword,
    JovTermsAndConditions,
    FTLPersonalDetails,
    OtpIvrSelect,
    FTLManageAddress,
} from './modules/UserManagementAndSecurityModule/Screens';
import TermAndConditions from './common/TermsAndConditions';
import Success from './common/RegistrationConfirmation';

import AccountServices from './modules/AccountOptions/AccountServices';
import InvestmentOrWithdrawalPlans from './modules/AccountOptions/InvestmentAndSWP/InvestmentOrWithdrawalPlans';
import CreateInvestmentOrWithdrawalPlan from './modules/AccountOptions/InvestmentAndSWP/CreateInvestmentOrWithdrawalPlan';
import EditInvestmentOrWithdrawalPlan from './modules/AccountOptions/InvestmentAndSWP/EditInvestmentOrWithdrawalPlan';
// Account Options Components
import CheckbookMain from './modules/AccountOptions/CheckBook';
import DirectedDividends from './modules/AccountOptions/Dividends';
import BankAccounts from './modules/AccountOptions/BankAccounts/BankAccounts';
import AddAccounts from './modules/AccountOptions/BankAccounts/AddAccount';
import RequiredMinimumDistribution from './modules/AccountOptions/InvestmentAndSWP/RequiredMinimumDistribution/RequiredMinimumDistributionContainer';
import AddRequiredMinimumDistribution from './modules/AccountOptions/InvestmentAndSWP/RequiredMinimumDistribution/CreateRMD/CreateRMDContainer';
import RMDDIY from './modules/AccountOptions/InvestmentAndSWP/RequiredMinimumDistribution/RMDComponents/RMDDIY';

import transactionDashboard from './modules/TransactionModule/TransactionDashboard/TransactionDashboard';
// import purchase from './modules/TransactionModule/Purchase/Purchases';
import InvestmentSelection from './modules/TransactionModule/Purchase/InvestmentSelection/InvestmentSelectionComponent';
import FundSourceComponent from './modules/TransactionModule/Purchase/FundSource/FundSourceComponent';
import PurchaseReviewComponent from './modules/TransactionModule/Purchase/ReviewConfirm/PurchaseReviewComponent';
// import ConfirmTransactionComponent from './modules/TransactionModule/Purchase/ConfirmTransaction/ConfirmTransactionComponent';
import ExchangeSuccess from './modules/TransactionModule/Exchange/ExchangeSuccess';
import AmendTransaction from './modules/TransactionModule/Amend';
import AmendProceed from './modules/TransactionModule/Amend/AmendProceed/AmendProceed';
import PurchaseContainer from './modules/TransactionModule/Purchase/PurchaseContainerComponent';
import LiquidationComponent from './modules/TransactionModule/Liquidation/LiquidationAccount';
import ExchangeMainComponent from './modules/TransactionModule/Exchange/ExchangeMainComponent';


import AmendCancel from './modules/TransactionModule/Amend/AmendCancel/AmendCancel';
import { Header, Footer, FAQWidget, PreLoginHeader, Msr } from './common';
import RetirementWidget from './common/Retirement/Retirement';

// Account DashBoard
import GuestViewDashboard from './modules/AccountDashboardModule/GuestView';
import AccountHolder from './modules/AccountDashboardModule/AccountHolderView';
import TransactionHistory from './modules/AccountDashboardModule/AccountHolderView/TransactionHistoryContainer';
import TransactionActivity from './modules/AccountDashboardModule/AccountHolderView/TransactionActivityContainer';
import ManageMarketTransaction from 'modules/AccountDashboardModule/AccountHolderView/ManageMarketTransactionContainer';
// Message Center Components
import MessageCenter from './modules/MessageCenter/MessageCenter/MessageCenterComponent';
import SecureMessageComponent from './modules/MessageCenter/SecureMessage/SecureMessageComponent';

// Calculators Components
import CalculatorsComponent from './modules/CalculatorsModule/CalculatorsComponent';
import RetirementPlanCalculator from './modules/CalculatorsModule/RetirementPlanCalculator';
import CollegeSavingsCalculator from './modules/CalculatorsModule/CollegeSavingsCalculator';
import TraditionalIRACalculator from './modules/CalculatorsModule/TraditionalIRACalculator';
import RetirementNestEggCalculator from './modules/CalculatorsModule/RetirementNestEggCalculator';
import RMDFutureCalculator from './modules/CalculatorsModule/RMDFutureCalculator';
import RMDCurrentCalculator from './modules/CalculatorsModule/RMDCurrentCalculator';

import SaveApplication from './modules/AccountManagementModule/SaveApplication/SaveApplicationContainer';

// Access Forms
import AccessForms from './modules/ReportsDocumentsModule/AccessForms/AccessForms';
import Forms from './modules/ReportsDocumentsModule/Forms/AccessForms';
import PortfolioPlannerPdf from './modules/AccountDashboardModule/AccountHolderView/PortfolioPlannerPdfContainer';
// Analytics
import AdobeAnalytics from './utils/AdobeAnalytics/AdobeAnalytics';

import AEM from './common/AEM';

// MSR Lookup

import MSRLookup from './modules/MSRLookup/MSRLookup';

import { RECURRING_TRANSFER_PLAN, VIEW_LIST_PATH_BY_PLAN } from './modules/AccountOptions/constant';
import PrivateRoute from './PrivateRoute/PrivateRoute';

// Market NAO
import MarketOpenAccountComponent from 'modules/AccountManagementModule/MarketAccountOpen';
import MarketAccountTypeComponent from 'modules/AccountManagementModule/MarketAccountType';
//import MarketPersonalInfo from 'modules/AccountManagementModule/MarketPersonalInfo/MarketPersonalInfo';
import RedirectToBrokerageNAO from 'modules/AccountManagementModule/MarketNAO/RedirectToBrokerageNAO';
import MarketNAOFlow from 'modules/AccountManagementModule/MarketNAO/MarketNAOFlow';
import MarketPersonalInfo from './modules/AccountManagementModule/MarketPersonalInfoComponent';
import ConfirmationFile from 'modules/AccountManagementModule/MarketNAO/ConfirmationFile';
import MSRSSO from 'common/MSRSSO/MSRSSOContainer';
import MsrSsoCallback from 'common/MSRSSO/index';
import UploadPendingDocuments from 'modules/AccountManagementModule/MarketNAO/UploadPendingDocuments';
//
const automaticInvestmentPlan = props => <InvestmentOrWithdrawalPlans {...props} plan={RECURRING_TRANSFER_PLAN.AIP} />;
const systematicWithdrawalPlan = props => <InvestmentOrWithdrawalPlans {...props} plan="systematicWithdrawal" />;
const editInvestmentOrWithdrawalPlan = props => {
    const { location } = props;
    const { plan, edit, details } = location.state || {};
    if (!edit || !Object.keys(details).length) {
        return <Redirect to={VIEW_LIST_PATH_BY_PLAN[plan || RECURRING_TRANSFER_PLAN.AIP]} />;
    }
    return <EditInvestmentOrWithdrawalPlan {...props} plan={plan} />;
};

// const handleLogout = () => {
//     Auth.signOut()
//     .then(() => {
//         console.log('@debug handle logout from routes.js');
//         localStorage.clear();
//         delete axios.defaults.headers.common['x-api-key'];
//     })
//     .catch(() => {
//         // console.log(err)
//     });
// }

const plansAccountselectionRoutes = [
    routeConstants.createAutomaticInvestment,
    routeConstants.createSystematicWithdrawal,
];
const sendSecureMessage = props => <SecureMessageComponent {...props} />;

const transactionHistory = props => <TransactionHistory {...props} />;
const transactionActivity = props => <TransactionActivity {...props} />;
const manageMarketTransaction = props => <ManageMarketTransaction {...props} />


const AppRouter = props => {
    const { location } = props || {};
    const { pathname = '' } = location || {};
    const isNotLoggedIn = preLoginRoutes.some(
        route => route === pathname || pathname.indexOf('accountRegistration') !== -1 || pathname.indexOf('aem') !== -1 ,
    );

    // const history = useHistory();
    // const token = localStorage.getItem('token');
    // const role = localStorage.getItem('role');

    // if (history.action === 'POP' && token && pathname === '/sign-in') {
    //     // handleLogout();
    //     // console.log('@@@@@@');
    //     history.push({
    //         pathname: role === 'Member' ? '/accountholder' : '/accountguestview',
    //     });
    // }
    // // else if (token && pathname === "/sign-in") {
    // //     handleLogout();
    // // }

    const filterPageInfor = routeAndPageInfoMapping.filter(info => info.key.toUpperCase() === pathname.toUpperCase());
    const pageInfo = filterPageInfor.length === 1 ? filterPageInfor[0] : {};
    localStorage.setItem('screenName', pageInfo.screen);
    document.title = pageInfo
        ? `${pageInfo.screen}: Victory Capital Management`
        : 'Dashboard: Victory Capital Management';
    // console.log('@debug for isNotLoggedIn ',isNotLoggedIn);
    return (
        <div className="w-100">
            <ScrollToTop />
            {pathname.indexOf('accountRegistration') === -1 ? <AdobeAnalytics route={props} /> : null}
            {/* {loggedInStatus} */}
            {/* <Container fluid> */}
            {console.log('isNotLoggedIn',isNotLoggedIn)}
            {isNotLoggedIn ? (
                <PreLoginHeader />
            ) : (
                <>
                    <IdleTimeOut />
                    <Header />
                </>
            )}
            <main id="maincontent" className="vcm-content" tabIndex="-1" role="main">
                {' '}
                {/** tabIndex="-1" is asked to be implemented by accessibility team ,please dont remove it */}
                <Switch>
                    <Route exact path="/" component={Msr} />
                    <Route exact path="/msrSsoCallback" component={MsrSsoCallback} />
                    <Route path={routeConstants.msr} component={Msr} />
                    <Route path={routeConstants.oops} component={OopsPage} />
                    <Route path={routeConstants.servererror} component={ServerErrorPage} />
                    <PrivateRoute path={routeConstants.msrlookup} component={MSRLookup} />
                    <PrivateRoute path={routeConstants.terms} component={TermsAndConditionsComponent} />
                    {/* <PrivateRoute path={routeConstants.openAccount} component={OpenAccountComponent} /> */}
                    <PrivateRoute path={routeConstants.accountType} component={AccountTypeComponent} />
                    <PrivateRoute path={routeConstants.personalInfo} component={PersonalInfoComponent} />
                    <PrivateRoute path={routeConstants.saveApplication} component={SaveApplication} />
                    <PrivateRoute
                        path={routeConstants.specialtyAccountApplicationForms}
                        component={SpecialtyAccountApplicationForms}
                    />
                    <PrivateRoute path={routeConstants.requiredMinimumDistribution} component={RequiredMinimumDistribution} />
                    <PrivateRoute path={routeConstants.addRequiredMinimumDistribution} component={AddRequiredMinimumDistribution} />
                    <PrivateRoute path={routeConstants.rmdDiY} component={RMDDIY} />

                    <PrivateRoute path={routeConstants.fundSelection} component={FundSelectionComponent} />
                    <PrivateRoute path={routeConstants.investmentSelection} component={InvestmentSelectionComponent} />
                    <PrivateRoute path={routeConstants.compareFunds} component={CompareFundsComponent} />
                    {/**    <PrivateRoute path="/investmentSelectionList" component={InvestmentSelectionListComponent} /> */}
                    <PrivateRoute path={routeConstants.fundSummary} component={FundSummaryComponent} />
                    <PrivateRoute path={routeConstants.summary} component={SummaryComponent} />
                    <PrivateRoute path={routeConstants.performance} component={PerformanceComponent} />
                    <PrivateRoute path={routeConstants.quickFacts} component={QuickFactsComponent} />
                    <PrivateRoute path={routeConstants.preferences} component={PreferencesComponent} />
                    <PrivateRoute path={routeConstants.verify} component={VerifyComponent} />
                    <PrivateRoute path={routeConstants.esign} component={EsignComponent} />
                    <PrivateRoute path={routeConstants.esa} component={ESA} />
                    <PrivateRoute path={routeConstants.application} component={Application} />
                    <PrivateRoute path={routeConstants.personalInfoChild} component={PersonalInfoChild} />
                    <PrivateRoute path={routeConstants.beneficiaryInfoChild} component={BeneficiaryInfoChild} />
                    <PrivateRoute
                        path={routeConstants.editPreference}
                        component={EditGeneralAccountPreferencesComponent}
                    />
                    <PrivateRoute
                        path={routeConstants.accountPreference}
                        component={GeneralAccountPreferencesComponent}
                    />
                    <PrivateRoute path={routeConstants.taxDocuments} component={TaxDocuments} />
                    <PrivateRoute path={routeConstants.interestedParties} component={InterestedParties} />
                    <PrivateRoute path={routeConstants.addInterestedParty} component={AIP} />
                    <PrivateRoute path={routeConstants.refreshFinance} component={RefreshDialog} />
                    <PrivateRoute path={routeConstants.confirmAccountOpening} component={ConfirmAccountOpening} />
                    <PrivateRoute path={routeConstants.manageBeneficiaries} component={ManageBeneficiaries} />
                    <PrivateRoute
                        path={routeConstants.manageBeneficiariesAccounts}
                        component={ManageBeneficiariesAccounts}
                    />
                    <PrivateRoute
                        path={routeConstants.addPrimaryBeneficiary}
                        component={AddPrimaryBeneficiary}
                        benType="primary"
                    />
                    <PrivateRoute
                        path={routeConstants.addContingentBeneficiary}
                        component={AddPrimaryBeneficiary}
                        benType="contingent"
                    />
                    <PrivateRoute path={routeConstants.agreement} component={AgreementComponent} />
                    <PrivateRoute path={routeConstants.privacy} component={PrivacyComponent} />
                    <PrivateRoute path={routeConstants.paperApplication} component={ApplicationComponent} />
                    <PrivateRoute path={routeConstants.uploadOrMail} component={UploadOrMailComponent} />
                    {/* Customer management PrivateRoute Paths */}
                    <PrivateRoute path={routeConstants.profile} component={ProfileAndPreferencesDashboard} />
                    <PrivateRoute path={routeConstants.basicinformation} component={BasicInfoComponent} />
                    <PrivateRoute
                        path={routeConstants.personalInformation}
                        render={props => <CMSRoute {...props} component={PrimaryInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.contactInfo}
                        render={props => <CMSRoute {...props} component={ContactInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipContactInfo}
                        render={props => <CMSRoute {...props} component={RelationshipContactInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.phoneInfo}
                        render={props => <CMSRoute {...props} component={PhoneInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.financialInfo}
                        render={props => <CMSRoute {...props} component={FinancialInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.empInfo}
                        render={props => <CMSRoute {...props} component={EmploymentInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.militaryInfo}
                        render={props => <CMSRoute {...props} component={MilitaryInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.emailInfo}
                        render={props => <CMSRoute {...props} component={EmailInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.addEmailInfo}
                        render={props => <CMSRoute {...props} component={AddEmailInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.editEmail}
                        render={props => (
                            <CMSRoute {...props} render={cmsprops => <AddEmailInfoComponent {...cmsprops} isEdit />} />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.addPhoneNum}
                        render={props => <CMSRoute {...props} component={AddPhoneInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.editPhone}
                        render={props => (
                            <CMSRoute {...props} render={cmsprops => <AddPhoneInfoComponent {...cmsprops} isEdit />} />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.addContactInfo}
                        render={props => <CMSRoute {...props} component={AddContactInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.editContact}
                        render={props => (
                            <CMSRoute
                                {...props}
                                render={cmsprops => <AddContactInfoComponent {...cmsprops} isEdit />}
                            />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipAddContact}
                        render={props => <CMSRoute {...props} component={RelationshipAddContactInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipEditContact}
                        render={props => (
                            <CMSRoute
                                {...props}
                                render={cmsprops => <RelationshipAddContactInfoComponent {...cmsprops} isEdit />}
                            />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.editAccountAddress}
                        render={props => (
                            <CMSRoute
                                {...props}
                                render={cmsprops => <AddOrEditAccountAddress {...cmsprops} isEdit />}
                            />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.addAccountAddress}
                        render={props => <CMSRoute {...props} component={AddOrEditAccountAddress} />}
                    />
                    <PrivateRoute path={routeConstants.addRelations} component={RelationshipInfoComponent} />
                    {/* <PrivateRoute path={routeConstants.manageRelations} component={ManageRelationComponent} /> */}
                    <PrivateRoute path={routeConstants.manageRelations} component={RelationshipManage} />
                    <PrivateRoute path={routeConstants.marketingAndPrivacy} component={MarketingAndPrivacyComponent} />
                    <PrivateRoute path={routeConstants.deliverySettings} component={DeliverySettings} />
                    <PrivateRoute path={routeConstants.accountmessaging} component={AccountMessaginComponent} />
                    <PrivateRoute path={routeConstants.generaldocuments} component={GeneralDocumentsComponent} />
                    <PrivateRoute path={routeConstants.securityFraudalerts} component={SecurityFraudComponent} />
                    <PrivateRoute path={routeConstants.devicemanagement} component={DeviceManagementComponent} />
                    {/* <PrivateRoute path={routeConstants.editRelations} component={EditRelationInfoComponent} /> */}
                    <PrivateRoute
                        path={routeConstants.relationshipPersonalInfo}
                        render={props => <CMSRoute {...props} component={RelationshipPrimaryInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipemailInfo}
                        render={props => <CMSRoute {...props} component={RelationshipEmailInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipAddEmailInfo}
                        render={props => <CMSRoute {...props} component={RelationshipAddEmailInfoComponent} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationshipeditEmail}
                        render={props => (
                            <CMSRoute
                                {...props}
                                render={cmsprops => <RelationshipAddEmailInfoComponent {...cmsprops} isEdit />}
                            />
                        )}
                    />
                    <PrivateRoute
                        path={routeConstants.manageRelationPhone}
                        render={props => <CMSRoute {...props} component={ManageRelationPhone} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationPhoneAdd}
                        render={props => <CMSRoute {...props} component={RelationPhone} />}
                    />
                    <PrivateRoute
                        path={routeConstants.relationPhoneEdit}
                        render={props => (
                            <CMSRoute {...props} render={cmsprops => <RelationPhone {...cmsprops} isEdit />} />
                        )}
                    />
                    {/* User management and Security PrivateRoute Paths */}
                    <PrivateRoute path={routeConstants.registerEmail} component={RegisterEmail} />
                    <PrivateRoute path={routeConstants.verifyEmail} component={VerifyEmail} />
                    <PrivateRoute path={routeConstants.registerOnlineId} component={RegisterOnlineId} />
                    <PrivateRoute path={routeConstants.registerPassword} component={RegisterPassword} />
                    <PrivateRoute path={routeConstants.personalDetails} component={PersonalDetails} />
                    <PrivateRoute path={routeConstants.termsAndConditions} component={TermAndConditions} />
                    <PrivateRoute path={routeConstants.verifyOtp} component={EnterOtp} />
                    <PrivateRoute path={routeConstants.otpIvrSelect} component={OtpIvrSelect} />
                    <PrivateRoute path={routeConstants.verifySecurityDetails} component={SecurityDetails} />
                    <PrivateRoute path={routeConstants.regSuccess} component={Success} />
                    <Route path={routeConstants.reviewPersonalInfo} exact component={ReviewPersonalInfo} />
                    <Route path={routeConstants.signIn} component={SignIn} />
                    <Route path={routeConstants.msrSso} component={MSRSSO} />
                    <PrivateRoute path={routeConstants.otpAuth} component={OtpAuthentication} />
                    <PrivateRoute path={routeConstants.confirmOtp} component={ConfirmOtp} />
                    <PrivateRoute path={routeConstants.deliveryPreference} component={Preferences} />
                    <PrivateRoute path={routeConstants.confirmSecurityAnswers} component={ConfirmSecurityQuestions} />
                    <PrivateRoute path={routeConstants.signInBySoftToken} component={SignInBySoftToken} />
                    <PrivateRoute path={routeConstants.recoverOnlineId} component={RecoverOnlineId} />
                    <PrivateRoute path={routeConstants.checkInbox} component={CheckInbox} />
                    <PrivateRoute path={routeConstants.jointOwnerVerificationSsn} component={JovSSN} />
                    <PrivateRoute path={routeConstants.jointOwnerVerificationTerms} component={JovTermsAndConditions} />
                    <PrivateRoute path={routeConstants.jointOwnerVerificationMobile} component={JovMobile} />
                    <PrivateRoute
                        path={routeConstants.jointOwnerVerificationOnlineId}
                        component={JovRegisterOnlineId}
                    />
                    <PrivateRoute
                        path={routeConstants.jointOwnerVerificationPassword}
                        component={JovRegisterPassword}
                    />
                    <Route path={routeConstants.createNewPassword} component={CreateNewPassword} />
                    <PrivateRoute path={routeConstants.securityPreferences} component={SecurityPreferencesComponent} />
                    <PrivateRoute path={routeConstants.setupSecurityQuestions} component={SetupSecurityQuestions} />
                    <PrivateRoute
                        exact
                        path={routeConstants.changeSignInMethodOptions}
                        component={ChangeSignInMethodOptionsComponent}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.changeSignInByOTP}
                        component={ChangeSignInByOTPComponent}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.changeSignInBySoftToken}
                        component={ChangeSignInBySoftTokenComponent}
                    />
                    <PrivateRoute path={routeConstants.resetPassword} component={CreatePasswordComponent} />
                    <PrivateRoute
                        exact
                        path={routeConstants.changeSignInByPushNotification}
                        component={ChangeSignInByPushNotificationComponent}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.mobileQuickSignIn}
                        component={MobileQuickSignInComponent}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.modifySecurityQns}
                        component={ModifySecurityQnsComponent}
                    />
                    <PrivateRoute exact path={routeConstants.retrievePassword} component={RetrievePasswordComponent} />
                    <PrivateRoute path={routeConstants.reviewPersonalDetails} component={FTLPersonalDetails} />
                    <PrivateRoute path={routeConstants.manageAddressDetails} component={FTLManageAddress} />
                    <PrivateRoute path={routeConstants.memberSignUp} component={MemberSignUpComponent} />
                    <PrivateRoute path={routeConstants.userDetails} component={UserAccountInformation} />
                    <PrivateRoute path={routeConstants.memberDobValidation} component={MemberDobValidationComponent} />
                    {/* Account Options PrivateRoute Paths */}
                    <PrivateRoute
                        path={routeConstants.specialtyAccountApplicationForms}
                        component={SpecialtyAccountApplicationForms}
                    />
                    <PrivateRoute exact path={routeConstants.accountservices} component={AccountServices} />
                    <PrivateRoute exact path={routeConstants.automaticinvestment} render={automaticInvestmentPlan} />
                    <PrivateRoute
                        exact
                        path={routeConstants.editautomaticinvestment}
                        render={editInvestmentOrWithdrawalPlan}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.editsystematicwithdrawal}
                        render={editInvestmentOrWithdrawalPlan}
                    />
                    <PrivateRoute exact path={routeConstants.systematicwithdrawal} render={systematicWithdrawalPlan} />
                    <PrivateRoute
                        exact
                        path={plansAccountselectionRoutes}
                        component={CreateInvestmentOrWithdrawalPlan}
                    />
                    <PrivateRoute path={routeConstants.checkbook} component={CheckbookMain} />
                    <PrivateRoute path={routeConstants.dividends} component={DirectedDividends} />
                    <PrivateRoute path={routeConstants.bankaccounts} component={BankAccounts} />
                    <PrivateRoute path={routeConstants.addaccounts} component={AddAccounts} />
                    {/* Transaction PrivateRoute Paths */}
                    <PrivateRoute path={routeConstants.transactionDashboard} component={transactionDashboard} />
                    <PrivateRoute path={routeConstants.transactionPurchases} component={PurchaseContainer} />
                    <PrivateRoute path={routeConstants.purchaseInvestmentSelection} component={InvestmentSelection} />
                    <PrivateRoute path={routeConstants.purchaseFundSource} component={FundSourceComponent} />
                    <PrivateRoute path={routeConstants.purchaseReviewConfirm} component={PurchaseReviewComponent} />
                    {/* <PrivateRoute path="/confirmTransaction" component={ConfirmTransactionComponent} /> */}
                    <PrivateRoute path={routeConstants.exchangeSucces} component={ExchangeSuccess} />
                    {/* Removed duplicate code. Please use OtherBankAccount in common folder */}
                    {/* <PrivateRoute path="/otherbankaccount" component={OtherAccountComponent} /> */}
                    <PrivateRoute path={routeConstants.amendTransaction} component={AmendTransaction} />
                    <PrivateRoute path={routeConstants.amendProceed} component={AmendProceed} />
                    <PrivateRoute path={routeConstants.amendCancel} component={AmendCancel} />
                    <PrivateRoute path={routeConstants.liquidation} component={LiquidationComponent} />
                    <PrivateRoute path={routeConstants.exchange} component={ExchangeMainComponent} />
                    {/* Admin Account DashBoard routes */}
                    <PrivateRoute exact path={routeConstants.accountguestview} component={GuestViewDashboard} />
                    <PrivateRoute exact path={routeConstants.accountholder} component={AccountHolder} />
                    
                    <PrivateRoute path={routeConstants.redirectToBrokerageNAO} component={RedirectToBrokerageNAO} />
                    <PrivateRoute exact path={routeConstants.uploadPendingDocuments} component={UploadPendingDocuments} />
                    <PrivateRoute
                        exact
                        path={routeConstants.accountholderTransactions}
                        component={transactionHistory}
                    />
                    <PrivateRoute exact path={routeConstants.manageMarketTransaction} component={manageMarketTransaction} /> 
                    <PrivateRoute exact path={routeConstants.accountholderActivity} component={transactionActivity} />
                    {/* Message Center PrivateRoute Paths */}
                    <PrivateRoute exact path={routeConstants.messageCenter} component={MessageCenter} />
                    <PrivateRoute path={routeConstants.createSecureMessage} render={sendSecureMessage} />
                    {/* Reports and Documents Paths */}
                    <PrivateRoute path={routeConstants.documentCenter} component={DocumentCenter} />
                    {/* <PrivateRoute path="/ESignDocument" component={ESign} /> */}
                    {/* Calculators Paths */}
                    <PrivateRoute exact path={routeConstants.calculators} component={CalculatorsComponent} />
                    <PrivateRoute
                        exact
                        path={routeConstants.retirementPlanCalculator}
                        component={RetirementPlanCalculator}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.collegeSavingsCalculator}
                        component={CollegeSavingsCalculator}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.traditionalIRACalculator}
                        component={TraditionalIRACalculator}
                    />
                    <PrivateRoute
                        exact
                        path={routeConstants.retirementNestEggCalculator}
                        component={RetirementNestEggCalculator}
                    />
                    <PrivateRoute exact path={routeConstants.rmdFutureCalculator} component={RMDFutureCalculator} />
                    <PrivateRoute exact path={routeConstants.rmdCurrentCalculator} component={RMDCurrentCalculator} />
                    <PrivateRoute exact path={routeConstants.testingLinks} component={TestingLinksComponent} />
                    <Route exact path={routeConstants.marstoneToNAO} component={MarstoneRedirectionComponent} />
                    {/* Access Forms */}
                    <PrivateRoute exact path={routeConstants.accessForms} component={AccessForms} />
                    <PrivateRoute exact path={routeConstants.forms} component={Forms} />
                    <PrivateRoute exact path={routeConstants.portfolioPlannerPdf} component={PortfolioPlannerPdf} />
                    <PrivateRoute exact path={routeConstants.FAQs} component={FAQWidget} />
                    <PrivateRoute exact path={routeConstants.retirementplans} component={RetirementWidget} />
                    <Route path={routeConstants.accountRegistration} component={AccountRegistration} />

                    <PrivateRoute path={routeConstants.marketOpenAccount} component={MarketOpenAccountComponent} />
                    <PrivateRoute path={routeConstants.marketAccountType} component={MarketAccountTypeComponent} />
                    <PrivateRoute path={routeConstants.marketPersonalInfo} component={MarketPersonalInfo} />
                    <PrivateRoute exact path={routeConstants.ConfirmationFile} component={ConfirmationFile} />
                    <PrivateRoute exact path={routeConstants.marketNAOFlow} component={MarketNAOFlow} />
                    {/* <PrivateRoute exact path={routeConstants.redirectToBrokerageNAO} component={RedirectToBrokerageNAO}  exact /> */}
                    <PrivateRoute path={routeConstants.marketingAndPrivacy} component={MarketingAndPrivacyComponent} />
                    {/* This PrivateRoute will be removed after validatin the POC */}
                    <PrivateRoute exact path="/aem/:pageid+" component={AEM} />
                </Switch>
                {/* {showFab && <FABModalRoot isLoggedIn={!isNotLoggedIn} />} */}
            </main>
            <Footer module={pageInfo.module} />
            {/* </Container> */}
        </div>
    );
};

export default withRouter(AppRouter);
