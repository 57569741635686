export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_ERROR = 'GET_DOCUMENT_LIST_ERROR';

export const SEARCH_DOCUMENTS = 'SEARCH_DOCUMENTS';
export const SEARCH_DOCUMENTS_LOADER = 'SEARCH_DOCUMENTS_LOADER';
export const SEARCH_DOCUMENTS_SUCCESS = 'SEARCH_DOCUMENTS_SUCCESS';
export const SEARCH_DOCUMENTS_ERROR = 'SEARCH_DOCUMENTS_ERROR';

export const GET_DOCUMENTID = 'GET_DOCUMENTID';
export const GET_DOCUMENTID_LOADER = 'GET_DOCUMENTID_LOADER';
export const GET_DOCUMENTID_SUCCESS = 'GET_DOCUMENTID_SUCCESS';
export const GET_DOCUMENTID_ERROR = 'GET_DOCUMENTID_ERROR';

export const GET_DOCUMENT_PDF_LIST = 'GET_DOCUMENT_PDF_LIST';
export const GET_DOCUMENT_PDF_LIST_LOADER = 'GET_DOCUMENT_PDF_LIST_LOADER';
export const GET_DOCUMENT_PDF_LIST_SUCCESS = 'GET_DOCUMENT_PDF_LIST_SUCCESS';
export const GET_DOCUMENT_PDF_LIST_ERROR = 'GET_DOCUMENT_PDF_LIST_ERROR';

export const GET_SINGLE_DOCUMENT = 'GET_SINGLE_DOCUMENT';
export const GET_SINGLE_DOCUMENT_LOADER = 'GET_SINGLE_DOCUMENT_LOADER';
export const GET_SINGLE_DOCUMENT_SUCCESS = 'GET_SINGLE_DOCUMENT_SUCCESS';
export const GET_SINGLE_DOCUMENT_ERROR = 'GET_SINGLE_DOCUMENT_ERROR';

export const SET_FORMATTED_DASHBOARD_USER_DOCUMENT = 'SET_FORMATTED_DASHBOARD_USER_DOCUMENT';

export const GET_USER_DOCUMENT_LIST = 'GET_USER_DOCUMENT_LIST';
export const GET_USER_DOCUMENT_LIST_LOADER = 'GET_USER_DOCUMENT_LIST_LOADER';
export const GET_USER_DOCUMENT_LIST_SUCCESS = 'GET_USER_DOCUMENT_LIST_SUCCESS';
export const GET_USER_DOCUMENT_LIST_ERROR = 'GET_USER_DOCUMENT_LIST_ERROR';

export const GET_TRANSFER_DOCUMENT_LIST = 'GET_TRANSFER_DOCUMENT_LIST';
export const GET_TRANSFER_DOCUMENT_LIST_LOADER = 'GET_TRANSFER_DOCUMENT_LIST_LOADER';
export const GET_TRANSFER_DOCUMENT_LIST_FAILURE = 'GET_TRANSFER_DOCUMENT_LIST_FAILURE';
export const GET_TRANSFER_DOCUMENT_LIST_SUCCESS = 'GET_TRANSFER_DOCUMENT_LIST_SUCCESS';
export const GET_TRANSFER_DOCUMENT_LIST_ERROR = 'GET_TRANSFER_DOCUMENT_LIST_ERROR';
export const RESET_TRANSFER_DOCUMENT = 'RESET_TRANSFER_DOCUMENT'

export const SET_DOCUMENT_CENTER_SELECTED_YEAR = 'SET_DOCUMENT_CENTER_SELECTED_YEAR';
