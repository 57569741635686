import React from 'react';
import PropTypes from 'prop-types';

import classes from '../Input/Input.module.css';
import styles from '../UserManagementStyles';

const assignObj = obj => obj;

const RegisterEmailForm = (props) => {

    const { changed, blurred, registerEmailFormState } = props;

    const data = {
        config: {
            type: 'email',
            label: 'E-mail',
            placeholder: 'John.murphy@gmail.com'
        },
        optionalMessage: '* All fields are required unless mentioned optional'
    }

    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (registerEmailFormState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        id="e-mail"
        type={data.config.type}
        className={inputClasses.join(' ')}
        value={registerEmailFormState.value}
        onChange={changed}
        aria-label="Email"
        onBlur={blurred} />;

    return (
    <>
        <span style={assignObj({...styles.optionalMessage, marginBottom: 30, marginTop: 11})}>{data.optionalMessage}</span>
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor="e-mail" aria-hidden>{data.config.label}</label>
            {inputElement}
            <p className={classes.errMsg} aria-live="polite" aria-atomic="true">{registerEmailFormState.errMsg}</p>
        </div>
    </>
    );
};

RegisterEmailForm.propTypes = {
    registerEmailFormState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            isEmail: PropTypes.bool,
            maxLength: PropTypes.number,
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func
}

RegisterEmailForm.defaultProps = {
    registerEmailFormState: {
        value: '',
        errMsg: null,
        valid: false,
        touched: false,
        rules: {
            isEmail: true,
            maxLength: 30,
            required: true
        }
    },
    changed: () => { },
    blurred: () => { }
}

export default RegisterEmailForm;