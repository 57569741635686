import React from 'react';
import PropTypes from 'prop-types';
import WButton from '../WButton/WButton';
import WModal from '../WModal/WModal';
import styles from '../CommonStyles';

const assignObj = (obj) => { return obj; };

const WSaveModal= (props) => {
    const {show} = props;
    const saveMsg = 'Your application has been saved. You will find a link to your saved application on your saved items page';
    const continueMsg = 'Do you want to continue the application process now or exit the application?'
    const handleHide = () =>props.modalClick('hide');
    const handleContinue = () => props.modalClick('continue');
    const handleExit = () => props.modalClick('exit');
    if(show) {
        return (
            <WModal 
            style={styles.popupModalStyle}
            closebutton=""
            header="Saved" 
            modalheaderstyle={styles.popupModalHeaderStyle}     
            modalfooterstyle={styles.popupModalFooterStyle}                     
            headertextstyle={styles.popupModalHeaderTextStyle}
            modalbodystyle={styles.popupModalBodyStyle}            
            size="lg" 
            dialogClassName="myModal" 
            show={show} 
            onHide={handleHide} 
            body=
            {
               <div style={styles.popupModalDivStyle}>
               <div style={styles.popupModalBodyTextStyle}>{saveMsg}</div>
               <div style={styles.popupModalBodyTextStyle}>{continueMsg} </div>
               </div>
            } 
            footer={
                <div style={assignObj({marginTop:10})}>
                    <WButton buttontext="CONTINUE" buttonstyle={styles.popupModalButtonStyle} buttontextstyle={styles.popupModalButtonTextStyle} onClick={handleContinue}/>
                    <WButton buttontext="EXIT" buttonstyle={styles.popupModalButtonStyle} buttontextstyle={styles.popupModalButtonTextStyle} onClick={handleExit}/>
                </div>
            }                           
        />
        );
    } 
        return null;
    
} 

WSaveModal.propTypes={
    modalClick: PropTypes.func,
    show:PropTypes.bool
}

WSaveModal.defaultProps={     
    modalClick: () => {},
    show: false
}

export default WSaveModal;



