const data = {
    title: 'One Time Passcode Authentication',
    info: [
        'To protect your information, we require you to authenticate yourself with a temporary registration code sent on your selected mobile device. Please choose where you want us to deliver your registration code.',
    ],
    mobileText: 'Send One Time Passcode to mobile number ',
    emailText: 'Send One Time Passcode to email id',
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Preference', 'Confirmation'],
    formGroup: { marginBottom: 0 },
};

export default data;
