import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone'
import { get, has, isObject } from 'lodash';
import constant from '../AccountDashboardConstants';
import WCardOverlay from '../WCardOverlay/WCardOverlay';
import WLinkCard from '../WLinkCard/WLinkCard';
import saveIcon from '../../../../assets/saveIcon.png';

class MySavedItemsWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           // savedItemsList: []
        }
    }

    componentDidMount() {
        const { getDashboardWidgetSavedItemsList } = this.props
        const params = {
            authToken: localStorage.getItem('token')
        };
        getDashboardWidgetSavedItemsList(params)
        // const savedItems = this.generateSavedItemsJson(dummySavedItems)
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            const { memberDashboardData } = this.props
            const { savedItemsResult,isSuccess,isSavedItemsResultError } = memberDashboardData || {};

           //  const { memberDashboardData: prevMemberDashboardData } = prevProps
           // const {savedItemsResult:prevSavedItemsResult}=prevMemberDashboardData || {}
            if (Object.keys(memberDashboardData).length &&
                Object.keys(savedItemsResult).length &&
               // !isEqual(savedItemsResult,prevSavedItemsResult)  && 
               isSuccess) {
                // console.log(savedItemsResult)
                this.generateSavedItemsJson(savedItemsResult)
            }
            if (Object.keys(memberDashboardData).length && isSavedItemsResultError) {
                this.handleAPIError(savedItemsResult)
            }

        }
    }

    handleAPIError = (error) => {
        const {commonErrorMsg}=constant
        let errorMsg = error
        if (has(error, 'data')) {
            if (has(error.data, 'errorMessages')) {
                const [firstMsg] = get(error.data, 'errorMessages', 'Error')
                errorMsg = firstMsg
            } else if (has(error.data, 'message')) {
                errorMsg = get(error.data, 'message', 'Error')
            }
        }
        if (isObject(errorMsg)) {
            errorMsg = commonErrorMsg
        }
        this.setState({ errorMessage: errorMsg })
    }

    sortFunction = (a, b) => {
        const dateA = new Date(a.updateAt).getTime()
        const dateB = new Date(b.updateAt).getTime()
        return dateA > dateB ? -1 : 1;
    };

    formatDate = (date) => {
        const currentTimeZone = moment().tz(moment.tz.guess()).format('z');
        const formattedDate = moment(new Date(date)).format("MMMM DD, YYYY hh:mm A");
        return `${formattedDate} ${currentTimeZone}`
    }

    formatLinkText = (text) => {
        return `Complete your ${text} Account application`
    }

    generateSavedItemsJson = (data) => {
        const { savedItems } = constant
        if (data && Object.keys(data).length && data.length>0) {
            const sortedData = data.sort(this.sortFunction)
            const formattedJson = sortedData &&
                sortedData.map((v, index) => ({
                    id: index,
                    savedate: this.formatDate(v.updateAt),
                    linktext: this.formatLinkText(v.accountType),
                    attachment: false,
                    link: '/accountType',
                    uuid:v.uuid
                    
                }));
            savedItems.cardLinks = formattedJson
        }
        this.setState({ savedItems })
    }

    render() {
        const { cardOverlayStyles, linkCardStyles } = this.props
        const { assignObj } = constant
        const {savedItems,errorMessage = ''}=this.state   

        return <>
            <WCardOverlay
                myStyles={assignObj({
                    marginTop: '30px',
                    minHeight: '29.1875rem',
                   
                    paddingLeft: '13px',
                    ...cardOverlayStyles
                })}
            >
                <div className="dashboard">
                    <WLinkCard
                        cardData={savedItems}
                        src={saveIcon}
                        width="16px"
                        height="16px"
                        customStyles={assignObj({...linkCardStyles })}
                        errorMessage={errorMessage}
                    />
                </div>
            </WCardOverlay>
        </>
    }

}


MySavedItemsWidget.propTypes = {
    memberDashboardData: PropTypes.instanceOf(Object),
    getDashboardWidgetSavedItemsList: PropTypes.func,
    cardOverlayStyles: PropTypes.instanceOf(Object),
    linkCardStyles: PropTypes.instanceOf(Object)
};

MySavedItemsWidget.defaultProps = {
    memberDashboardData: {},
    getDashboardWidgetSavedItemsList: () => { },
    cardOverlayStyles: {},
    linkCardStyles: {}
};

export default MySavedItemsWidget;
