/**
 * Employment Info Card Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Employment Information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import { Accordian, AccordianTitle, WInput } from '../../../common';
import Address from '../Address';
import checkValidity from '../../../utils/checkValidity';
import Consts from '../PersonalInfo/consts';
import { FieldValue, SelectDropDown } from '../../CustomerManagementModule/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Card } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import '../PersonalInfo/style.css';
import StyledToolTip from '../MarketPersonalInfoCard/StyledToolTip.js'
import helperIcon from '../../../assets/helperIcon.png';
import {HelpText_Employment} from '../PersonalInfo/consts';
const alignSelectAccOpt = {  };

class MarketEmploymentInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empStatus: '',
            empStatusOther: '',
            empClassInfo: '',
            industry: '',
            occupation: '',
            employersName: '',
            primarySourceofIncome: '',
            industryDescription: '',
            employerAddress: {
                addressLine1: '',
                addressLine2: '',
                zip: '',
                state: '',
                city: '',
            },
            errorMsg: {},
            employmentClicked: true,
            HelperTetXForEmployment:false,
            
        };
        this.empAddressPref = React.createRef();
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {};
    }

    componentDidMount() {
        const { localStateData } = this.props;
        if (localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo) {
            const { employmentInfo } = localStateData.OpenAccPageTwo.personalInfo;
            if (employmentInfo) {
                const {
                    empStatus,
                    empStatusOther,
                    empClassInfo,
                    industry,
                    occupation,
                    employersName,
                    primarySourceofIncome,
                    employerAddress,
                } = employmentInfo;
                this.setState({
                    empStatus,
                    empStatusOther,
                    empClassInfo,
                    industry,
                    occupation,
                    employersName,
                    primarySourceofIncome,
                    employerAddress: {
                        addressLine1: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.addressLine1,
                        addressLine2: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.addressLine2,
                        zip: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.zip,
                        state: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.state,
                        city: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.city,
                    },
                });
            }
        }
    }

    checkEmployeementstatus = errorMsg => {
        const { empStatus } = this.state;
        return !!empStatus && !errorMsg.empStatus;
    };


    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        if (eventKey === 'empStatus') {
            const err = Rules[eventKey.toString()]
                ? checkValidity({
                      rules: Rules[eventKey.toString()],
                      value: e.target.value,
                  })
                : '';
            errorMsg[eventKey.toString()] = err;
            this.setState({
                [eventKey]: e.target.value,
                errorMsg,
                empStatusOther: '',
                empClassInfo: '',
                industry: '',
                occupation: '',
                employersName: '',
                primarySourceofIncome: '',
                employerAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    zip: '',
                    state: '',
                    city: '',
                },
            });
        } else if (eventKey === 'industry') {
            const err = Rules.industry
                ? checkValidity({
                      rules: Rules.industry,
                      value: e.target.value,
                  })
                : '';
            errorMsg.industry = err;
            if(e.target.value === 'other_ind'){
                this.setState({ [eventKey]: e.target.value, errorMsg });
            }else{
                this.setState({ [eventKey]: e.target.value, errorMsg, industryDescription: '' });
            }
        } else if (eventKey === 'industryDescription') {
            const err = Rules.industryDescription
                ? checkValidity({
                      rules: Rules.industryDescription,
                      value: e.target.value,
                  })
                : '';
            errorMsg.industry = err;
            this.setState({ [eventKey]: e.target.value, errorMsg });
        } else if (eventKey === 'empClassInfo') {
            const err = Rules.empClassInfo
                ? checkValidity({
                      rules: Rules.empClassInfo,
                      value: e.target.value,
                  })
                : '';
            errorMsg.empClassInfo = err;
            this.setState({ [eventKey]: e.target.value, errorMsg });
        } else {
            this.setState({ [eventKey]: e.target.value, errorMsg });
        }
    };

    cardHeaderClick = e => {
        const { employmentClicked } = this.state;
        e.target.classList.toggle('expand');
        this.setState({ employmentClicked: !employmentClicked });
    };

    getFocusInformation = (errorMsg,toFocus,jointText,index) =>
    {
        if (errorMsg.empStatus) {
            toFocus = `${jointText}empStatus${index}`;
        } else if (errorMsg.primarySourceofIncome) {
            toFocus = `${jointText}primarySourceofIncome${index}`;
        } else if (errorMsg.empClassInfo) {
            toFocus = `${jointText}empClassInfo${index}`;
        } else if (errorMsg.industry) {
            toFocus = `${jointText}industry${index}`;
        } else if (errorMsg.industryDescription) {
            toFocus = `${jointText}industryDescription${index}`;
        }

        return toFocus
    }

    getAddressValidation = (empAddressData) =>
    {
        return (empAddressData === null)?true: (empAddressData?.errorMsg?.lineTwo === null && empAddressData?.errorMsg?.state === "" && empAddressData?.errorMsg?.zipCode === null && empAddressData?.errorMsg?.city === null)
    }

    isValidEmpInfo = (err,errorMsg,otherIndustryErr,empNameError,empAddressError) =>
    {
        return (err === null || err === '') && (errorMsg.empStatus === null || errorMsg.empStatus === "") && (otherIndustryErr === '' || otherIndustryErr === null) && (empNameError === "" || empNameError === null) && (empAddressError === true)
    }

    validateFields = () => {
        const { empStatus, errorMsg, primarySourceofIncome, industry, industryDescription, empClassInfo, employersName } = this.state;
        const notEmployeeArray = ['retired', 'homemaker', 'student', 'unemployed'];
        let valid = true;
        let err = Rules.empStatus
            ? checkValidity({
                  rules: Rules.empStatus,
                  value: empStatus,
              })
            : '';
        errorMsg.empStatus = err;
        if (notEmployeeArray.includes(empStatus)) {
            err = Rules.primarySourceofIncome
                ? checkValidity({
                      rules: Rules.primarySourceofIncome,
                      value: primarySourceofIncome,
                  })
                : '';
            errorMsg.primarySourceofIncome = err;
        } else {
            err = Rules.industry
                ? checkValidity({
                      rules: Rules.industry,
                      value: industry,
                  })
                : '';
            errorMsg.industry = err;
        }

        if (notEmployeeArray.includes(empStatus) == false && industry === 'other_ind') {
            err = Rules.industryDescription
                ? checkValidity({
                      rules: Rules.industryDescription,
                      value: industryDescription,
                  })
                : '';
            errorMsg.industryDescription = err;
        }
        let otherIndustryErr = '';
        if (empStatus === 'other') {
            err = Rules.empClassInfo
                ? checkValidity({
                      rules: Rules.empClassInfo,
                      value: empClassInfo,
                  })
                : '';
            errorMsg.empClassInfo = err;
            // Validate industry
            otherIndustryErr = Rules.industry
                ? checkValidity({
                      rules: Rules.industry,
                      value: industry,
                  })
                : '';
            errorMsg.industry = otherIndustryErr;
        }

        let empNameError
        if (!notEmployeeArray.includes(empStatus)) {
            empNameError = Rules.employersName
                ? checkValidity({
                    rules: Rules.employersName,
                    value: employersName,
                })
                : '';
            errorMsg.employersName = empNameError;
        } else {
            empNameError = ""
            errorMsg.employersName = empNameError
        }
        let empAddressError
        const empAddressData = this.empAddressPref.current && this.empAddressPref.current.getAddress();

        if (!notEmployeeArray.includes(empStatus)) {
            empAddressError = this.getAddressValidation(empAddressData) 
        } else {
            empAddressError = true
        }

        valid = this.isValidEmpInfo(err,errorMsg,otherIndustryErr,empNameError,empAddressError) 
        this.setState({ errorMsg });
        let toFocus = '';
        const { jointText, index } = this.props;
        toFocus = this.getFocusInformation(errorMsg,toFocus,jointText,index) 
        return { valid, toFocus };
    };

    sendDataToParent = (action = 'defaultVal') => {

        const empAddressData = this.empAddressPref.current && this.empAddressPref.current.getAddress();
        if (empAddressData) {
            empAddressData.addressLine1 = (empAddressData.lineOne) || '';
            empAddressData.addressLine2 = (empAddressData.lineTwo) || '';
            empAddressData.city = (empAddressData.city) || '';
            empAddressData.state = (empAddressData.state) || '';
            empAddressData.zip = (empAddressData.zipCode) || '';
        }
        const empAddress = {
            empAddress: empAddressData,
        };

        let valid = true
        let toFocus = ''

        // if save button is clicked dont run validation and directly return true
        if(action == 'save'){
            valid = true;
            this.setState({errorMsg:{}})
            return { ...this.state, ...empAddress, valid, toFocus, errorMsg:{} };
        }else{  
             ({ valid, toFocus } = this.validateFields())
            return { ...this.state, ...empAddress, valid, toFocus };
        }  
    };

  // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues = () =>{
        this.setState({errorMsg:{}})
    }

    renderEmployeeFields = (isUnemployed, industryOp, primarySourceOfIncomeValue) => {
        const {isJoint, index, jointText, savedState } = this.props;
        const {
            empStatus,
            empClassInfo,
            industry,
            occupation,
            employersName,
            errorMsg,
            primarySourceofIncome,
            industryDescription,
            employerAddress,
        } = this.state;
        const savedEmpAddress = employerAddress;

        const savedJointEmpAddress = savedState && savedState.empAddress;

        return (
            <div>
                {empStatus === 'other' && (
                    <Row>
                        <WInput
                            label="Employment Class information"
                            placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                            className=""
                            id={`${jointText}empClassInfo${index}`}
                            name="empClassInfo"
                            type="text"
                            onChange={this.handleChange('empClassInfo')}
                            value={empClassInfo || ''}
                            errortext={errorMsg.empClassInfo}
                            labelsm={4}
                            valuesm={5}
                            required
                        />
                    </Row>
                )}

                {isUnemployed && (
                    <FieldValue
                        as="p"
                        id={`${jointText}primarySourceofIncome${index}Label`}
                        label="Primary Source of Income"
                        value={
                            <SelectDropDown
                                aria-labelledby={`${jointText}primarySourceofIncome${index}Label`}
                                id={`${jointText}primarySourceofIncome${index}`}
                                data-name="Primary Source of Income"
                                value={primarySourceofIncome}
                                onChange={this.handleChange('primarySourceofIncome')}
                                errortext={errorMsg.primarySourceofIncome}
                                itemlist={primarySourceOfIncomeValue}
                                className="empStatusDropdown"
                            />
                        }
                        labelsm={4}
                        valuesm={5}
                        mb={1.5625}
                        noGutters
                        alignSelectAccOpt={alignSelectAccOpt}
                    />
                )}
                {!isUnemployed && (
                    <FieldValue
                        as="p"
                        id={`${jointText}industry${index}Label`}
                        label="Industry"
                        value={
                            <SelectDropDown
                                aria-labelledby={`${jointText}industry${index}Label`}
                                id={`${jointText}industry${index}`}
                                data-name="Industry"
                                value={industry}
                                onChange={this.handleChange('industry')}
                                errortext={errorMsg.industry}
                                itemlist={industryOp}
                            />
                        }
                        labelsm={4}
                        valuesm={5}
                        mb={1.5625}
                        noGutters
                        alignSelectAccOpt={alignSelectAccOpt}
                    />
                )}
                {!isUnemployed && industry === 'other_ind' && (
                    <Row>
                        <WInput
                            label=""
                            placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                            className=""
                            id={`${jointText}industryDescription${index}`}
                            name="industryDescription"
                            type="text"
                            maxlength={30}
                            subtype="characters"
                            onChange={this.handleChange('industryDescription')}
                            value={industryDescription || ''}
                            errortext={errorMsg.industryDescription}
                            labelsm={4}
                            valuesm={5}
                            optional="false"
                            required
                        />
                    </Row>
                )}
                {!isUnemployed && (
                    <>
                        <Row>
                            <WInput
                                label="Occupation"
                                className=""
                                id={`${jointText}occupation${index}`}
                                name="occupation"
                                type="text"
                                subtype="characters"
                                onChange={this.handleChange('occupation')}
                                value={occupation || ''}
                                errortext={errorMsg.occupation}
                                labelsm={4}
                                valuesm={5}
                                optional="true"
                                maxlength={30}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label="Employer's Name"
                                className=""
                                id={`${jointText}employersName${index}`}
                                name="employersName"
                                type="text"
                                onChange={this.handleChange('employersName')}
                                value={employersName || ''}
                                errortext={errorMsg.employersName}
                                labelsm={4}
                                valuesm={5}
                                maxlength={40}
                                aria-required={false}
                            />
                        </Row>
                     
                        <Address
                            savedState={isJoint ? savedJointEmpAddress : savedEmpAddress}
                            ref={this.empAddressPref}
                            labelAdd="Employer's Address"
                            required
                            optional="false"
                            type="employment"
                            jointText={jointText}
                            index={index}
                            isJoint={isJoint}
                            handleValidationsOnBlur = {true}
                        />
                    </>
                )}
            </div>
        );
    };

    render() {
        const emplInfoHeading = 'Employment Information';
        const emplInfoHeadingForJoin = "Employment Information - Joint Owner"
        const { masterLookupStateData, index, jointText } = this.props;
        const { brkg_employment_status: emp, industry: industryMaster, prim_src_income: primarySourceOfIncomeMaster } =
            masterLookupStateData || {};
        const { value: employmentStatus } = emp || {};
        const { value: industryOp } = industryMaster || {};
        const { value: primarySourceOfIncomeValue } = primarySourceOfIncomeMaster || {};
        const emplist = employmentStatus || [];
        const { empStatus, errorMsg } = this.state;
        const notEmployeeArray = ['retired', 'homemaker', 'student', 'unemployed'];
        const isUnemployed = notEmployeeArray.findIndex(item => item === empStatus) > -1;
        //Tooltip functionality for employment
        const { HelperTetXForEmployment } = this.state
        const toggleToolTipForEmployment = () => {
            this.setState({ HelperTetXForEmployment: true })
        }

        const closeToolTipForEmployment = () => {
            this.setState({ HelperTetXForEmployment: false })
        }

        return (
             this.props.verifyFinHider || this.props.verifyMilHider || this.props.verifyJointFinHider || this.props.verifyJointMilHider ? null :
            <>
            <Accordian
                ref={this.accordionRef}
                key={this.props?.jointInfo != "Yes" ?  emplInfoHeading : emplInfoHeadingForJoin}
                accordianPaddingLeft={0}
                titleChild={
                    <AccordianTitle
                        accType={this.props?.jointInfo != "Yes" ?  emplInfoHeading : emplInfoHeadingForJoin}
                        id={this.props?.jointInfo != "Yes" ?  emplInfoHeading : emplInfoHeadingForJoin}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                // titleSeperator
                labelledBy={this.props?.jointInfo != "Yes" ?  emplInfoHeading : emplInfoHeadingForJoin}
                Id={this.props?.jointInfo != "Yes" ?  emplInfoHeading : emplInfoHeadingForJoin}
            >
                <Card>
                    <Card.Body>
                        <img id="Employment" className={this.props?.jointInfo != "Yes" ? "TooltipForEmployment" : "TooltipForJointEmployment"} onMouseOut={closeToolTipForEmployment} src={helperIcon} alt="" />

                        <StyledToolTip
                            id="Employment_Tooltip"
                            placement="top"
                            tooltipOpen={HelperTetXForEmployment}
                            targetID="Employment"
                            handleToggle={toggleToolTipForEmployment}
                            autohide={true}
                            triggerEvt="hover focus"
                            tooltipText={HelpText_Employment}
                            closeTooltip={closeToolTipForEmployment}
                        />
                        <FieldValue
                            as="p"
                            id={`${jointText}empStatus${index}Label`}
                            label="Employment Status"
                            value={
                                <SelectDropDown
                                    aria-labelledby={`${jointText}empStatus${index}Label`}
                                    id={`${jointText}empStatus${index}`}
                                    data-name="Employment Status"
                                    value={empStatus}
                                    onChange={this.handleChange('empStatus')}
                                    errortext={errorMsg.empStatus}
                                    itemlist={emplist}
                                    className="empStatusDropdown"
                                />
                            }
                            labelsm={4}
                            valuesm={5}
                            mb={1.5625}
                            noGutters
                            alignSelectAccOpt={alignSelectAccOpt}
                        />
                        { this.renderEmployeeFields(isUnemployed, industryOp, primarySourceOfIncomeValue)}
                    </Card.Body>
                </Card>
            </Accordian> </>
        );
    }
}

MarketEmploymentInfoCard.propTypes = {
    masterLookupStateData: PropTypes.shape({
        employment_status: PropTypes.any,
        industry: PropTypes.any,
    }),
    getCompositeLookUpData: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
    index: PropTypes.number,
    jointText: PropTypes.string,
};

MarketEmploymentInfoCard.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: null,
    localStateData: {},
    savedState: {},
    isJoint: false,
    index: 0,
    jointText: '',
};

export default MarketEmploymentInfoCard;
