import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions, loginActions } from '../../../../shared/Actions';
import ConfirmOtpComponent from './ConfirmOtpComponent';

export const mapStateToProps = (state /* , props */) => ({
    customerProfile: state.localStateManagementReducerData.customerProfile,
    verifyOTPData: state.loginData.verifyOtp,
    loginState: state.loginData
});

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmOtpComponent));
