const initialState = {
    isloading: false
};

function addBankAccountReducer(state = initialState, action) {
    switch (action.type) {
        case "ADD_BANK_ACCOUNT_LOADER": {
            return { ...state, ...action };
        }
        case "ADD_BANK_ACCOUNT_SUCCESS": {
            return { ...state, ...action, isloading: false };
        }
        case "ADD_BANK_ACCOUNT_ERROR": {
            return { ...state, ...action, isloading: false };
        }
        case "ADD_NEW_BANK_ACCOUNT_DETAILS": {
            return { ...state, ...action };
        }
        case "ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS": {
            return { ...state, ...action, isloading: false };
        }
        case "ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE": {
            return { ...state, ...action, isloading: false };
        }
        case "BANK_ACCOUNT_DETAILS_RESET": {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}

export default addBankAccountReducer;