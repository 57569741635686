
/* eslint-disable react/jsx-no-literals */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { IoIosLock } from 'react-icons/io';
import { isTablet } from 'react-device-detect';
import routeConstants from 'routeConstants';
import ChartCard from './ChartCard';
import './memberMessage.css';
import consts from './constants';

const assignObj = (obj) => { return obj; };

let textObject = {};
export default class MemberMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { page } = this.props;
    switch (page) {
    case 'memberNotfound':
      document.title = 'Pause the process: Pre-registration: VCM';
      break;
    case 'memberfound':
      document.title = 'Already registered: Pre-registration: VCM';
      break;
    default:
      break;
    }
  }

  getTextObject = (page) => {
    const alreadyRendered = 'You are already registered with Victory Capital';
    switch (page) {
      case 'accountLocked':
        textObject = {
          pageHeading: 'Account Locked',
          explanationPara: 'You tried to send the passcode too many times.',
          helpText:
              'To unlock your account, contact a Victory Capital Management Member Service Representative.',
          helpTelephoneNumber: consts.helpTelephoneNumber,
        };
        break;
      case 'memberNotfound':
        textObject = {
          pageHeading: 'USAA Member Match Not Found',
          explanationPara:
              'If you opened an investment account after May 20, visit vcm.com after July 6, 2020 to setup your credentials and make any additional changes to your profile.',
          helpText:
              'If you opened an investment account prior to May 20, please contact Victory Capital Member Service Representative',
          helpTelephoneNumber: consts.helpTelephoneNumber,
        };
        break;
      case 'memberfound':
        textObject = {
          pageHeading: alreadyRendered,
          explanationPara:
              'If you opened an investment account after April 3rd, then visit vcm.com after July 6, 2020 for setting up your credentials and making any additional changes to your profile.',
          helpText:
              'If you opened an investment account prior to April 3rd, then please contact Victory Capital Member Service Representative',
          helpTelephoneNumber: consts.helpTelephoneNumber,
        };
        break;
      case 'identityCheckFail':
        textObject = {
          pageHeading: '',
          explanationPara: 'There was a problem processing your information.',
          helpText:
              'Please contact Victory Capital Member Service Representative.',
          helpTelephoneNumber: consts.helpTelephoneNumber,
        };
        break;
      case 'invalidInfo':
        textObject = {
          pageHeading: '',
          explanationPara: 'Invalid request.',
          helpText: '',
          helpTelephoneNumber: '',
        };
        break;
      default:
        textObject = {
          pageHeading: alreadyRendered,
          explanationPara:
              'If you opened an investment account after May 20, visit vcm.com after July 6, 2020 to setup your credentials and make any additional changes to your profile.',
          helpText:
              'If you opened an investment account prior to May 20, please contact Victory Capital Member Service Representative',
          helpTelephoneNumber: consts.helpTelephoneNumber,
        };
        break;
      }
  }

  memberFound = (page) => (
    <div className="success-title">
      <Container fluid className="noPad">
        <Row className={`memberMsgContainer ${page}`} noGutters>
          <Col xs={12}>
            <h1 className="pageHeading">
              You are already registered with Victory Capital
            </h1>
            <p className="explanationPara">
              <span>
                Beginning later this year, you can access your USAA Mutual
                Funds and 529 Education Savings Plan account information at
              </span>
              <a
                href="https://www.vcm.com/"
                className="inlineLInk"
                target="_blank"
                rel="noopener noreferrer"
                aria-describedby={isTablet ? 'external-link' : null}
              >
                vcm.com
                <span
                  className={isTablet ? 'sr-only hidenTablet' : 'sr-only'}
                  aria-hidden={isTablet ? 'true' : 'false'}
                >
                  External Website. Opens New Window
                </span>
                <span
                  aria-hidden="true"
                  className="icon-newpage small-size"
                />
                <span aria-hidden="true">.</span>
              </a>
            </p>
            <div
              className="pageHeading"
              style={assignObj({ marginTop: 20 })}
            >
              Until later this year, please continue to access your accounts
              on
              <br />
              <a
                href="https://www.usaa.com"
                className="inlineLInk"
                target="_blank"
                rel="noopener noreferrer"
                aria-describedby={isTablet ? 'external-link' : null}
              >
                www.usaa.com
                <span
                  className={isTablet ? 'sr-only hidenTablet' : 'sr-only'}
                  aria-hidden={isTablet ? 'true' : 'false'}
                >
                  External Website. Opens New Window
                </span>
                <span
                  aria-hidden="true"
                  className="icon-newpage large-size"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );

  render() {
    const { page } = this.props;
    this.getTextObject(page);

    if (page === 'memberfound') {
      return this.memberFound(page);
    }
    if (page === 'memberNotfound') {
      return (
        <div className="milestone">
          <Container fluid className="noPad">
            <Row className={`memberMsgContainer ${page}`} noGutters>
              <Col xs={12}>
                <div>
                  <h1 className="pageHeading">
                    <IoIosLock />
                    {' '}
                      SSN Not Verified:
                  </h1>
                  <div style={assignObj({ textAlign: 'center' })}>
                    <div
                      style={assignObj({
                        textAlign: 'left',
                        width: '70%',
                        display: 'inline-block',
                      })}
                    >
                      <p className="explanationPara">
                        We apologize, we’re unable to verify your SSN.
                        If you are an existing USAA Mutual Fund or 529
                        Education Savings Plan Account holder please contact
                        us at 800-235-8396 to speak with a Member Service
                        Representative Monday – Friday from 7:30 am – 8:00 pm CT. 
                      </p>
                      {/* <p className="titleText">So, what happens next?</p>
                      <ul className="ulList">
                        <li>
                          If you opened your investment account after Aug 07,
                          2020, you can come back to vcm.com in the third
                          quarter of 2020 to complete your registration.
                        </li>
                      </ul> */}
                      <p className="titleText">
                        Interested in opening a USAA Mutual Fund
                        or USAA 529 Education Savings Plan account,
                        click here to get started. 

                          <a className="inlineLInk"
                          href={routeConstants.signIn}
                          rel="noopener noreferrer" >{consts.signin}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div className="milestone">
        <Container fluid className="noPad">
          <Row className={`memberMsgContainer ${page}`} noGutters>
            <Col xs={12}>
              <h1 className="pageHeading">
                {page !== 'identityCheckFail' ? (
                  <span>{textObject.pageHeading}</span>
                ) : (
                  <FaTimes focusable="false" />
                )}
              </h1>
              {textObject.explanationPara && (
                <p className="explanationPara" style={assignObj({textAlign: 'center'})}>{textObject.explanationPara}</p>
              )}
              {textObject.helpText && (
                <p className="helpText">{textObject.helpText}</p>
              )}
              {textObject.helpTelephoneNumber && (
                <Row className="detailsWrapper">
                  <Col className="helpNumber" xs={12}>
                    <a
                      href={`tel:${textObject.helpTelephoneNumber}`}
                      className="telephone"
                    >
                      {textObject.helpTelephoneNumber}
                    </a>
                  </Col>
                  <ChartCard />
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

MemberMessage.propTypes = {
  page: PropTypes.string,
};

MemberMessage.defaultProps = {
  page: '',
};
