/**
 * Quick Facts Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to see quick facts for account
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { WRating } from '../../../common';
import styles from './styles';
import './quickFacts.css';

export default class QuickFactsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryFund: {},
            facts: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        const stateVar = state;
        if ( props.selectedFundDetails !== undefined &&
            props.selectedFundDetails !== null &&
            Object.keys(props.selectedFundDetails).length > 0
            ) {
            stateVar.summaryFund = props.selectedFundDetails;
            stateVar.facts = [
                // {
                //     label: `Net Asset Value (Price per share as of ${
                //         state.summaryFund.nav_as_of ? state.summaryFund.nav_as_of : ''
                //     } )`,
                //     value: state.summaryFund.nav_change ? Number(state.summaryFund.nav_change).toFixed(2) : 0,
                // },
                {
                    label: 'Net Expense Ratio',
                    value: `${Number(state.summaryFund.net_expense_ratio).toFixed(2)}%`,
                },
                {
                    label: 'Gross Expense Ratio',
                    value: `${Number(state.summaryFund.gross_exp_ratio).toFixed(2)}%`,
                },
                // {
                //     // label: `Overall Morningstar Rating (As of ${state.summaryFund.morningstarRating.asOfDate})`,
                //     label: `Overall Morningstar Rating (as of ${
                //         state.summaryFund.morningStarRating.morning_star_as_of_date
                //             ? state.summaryFund.morningStarRating.morning_star_as_of_date
                //             : ''
                //     } )`,
                //     value: 'rating',
                //     ratingValue: state.summaryFund.morningStarRating.rating,
                // },
                // {
                //     label: 'Morningstar Category',
                //     value: 'TBD', // state.summaryFund.morningstarCategory,
                // },
                // {
                //     label: 'Funds in Category',
                //     value: state.summaryFund.category,
                // },
            ];
            return state.facts;
        }

        return {};
    }

    render() {
        const { facts } = this.state;
        return (
            <div>
                <div className="containerStyle">
                    {facts.map(fact => {
                        const factsLabel = fact.label.split('(');
                        return (
                            <Row key={fact.value} style={styles.quickStyle} className="quickFactsRow" noGutters>
                                <Col md={5} className="quickFactsLabel">
                                    {factsLabel[0]}
                                    <span>{factsLabel.length > 1 ? `${factsLabel[1].replace(')', '')}` : ''}</span>
                                </Col>
                                <Col md={3} className="quickFactsValue">
                                    {fact.value === 'rating' ? (
                                        <WRating name="rating" readOnly value={Number(fact.ratingValue)} />
                                    ) : (
                                        fact.value
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </div>
        );
    }
}

QuickFactsComponent.propTypes = {
    selectedFundDetails: PropTypes.instanceOf(Object),
};

QuickFactsComponent.defaultProps = {
    selectedFundDetails: {},
};
