import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { OnlineId } from '../Input/OnlineId';
import { Password } from '../Input/Password';
import classes from '../Input/Input.module.css';
import '../Screens/Screens.css';
import data from './constants';
import { WButton } from '../../../common';
import userConstants from '../userConstants';
import { analyzeClickEvent } from '../../../shared/Actions/AnalyticsActions';

export const SignInForm = (props) => {
    const { history, state, signInHandler, blurred, changed, clicked } = props;
    const { form, error, formIsValid } = state;
    const dispatch = useDispatch();

    function analyzeClick(e){
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
            }),
        );
    }

    return (<div>
        <div style={data.styles.main} >
            <h1 style={data.styles.header}>{data.header}</h1>
            <span className="sr-only">{userConstants.conditionText}</span>
            <OnlineId
                onlineIdState={form.onlineId}
                changed={changed}
                blurred={blurred}
                clicked={clicked}
                showForgot
                history={history}
                inputStyle={data.styles.inputStyle} />
            <Password
                passwordState={form.password}
                changed={changed}
                blurred={blurred}
                clicked={clicked}
                showForgot
                history={history}
                formValid={formIsValid}
                signInHandler={signInHandler} />
                {error ? <p className={classes.errMsg} aria-live="polite" >{error}</p> : null}
            <div style={data.styles.termsOfUse}>
                <div style={data.styles.para}>
                    <span style={data.styles.agreeStyle}>{data.agreeText}</span>
                    <a href="https://www.vcm.com/docs/default-source/default-document-library/victory-capital-terms-of-service.pdf" data-analytic-id="UMS_SignIn_TermsofUse" target="_blank" rel = "noopener noreferrer" onClick={analyzeClick} style={data.styles.strong}>{data.termsOfUse}
                        <span className="sr-only">{userConstants.externalWeb}</span><span aria-hidden="true" className="icon-newpage"/>
                    </a>
                </div>
                
            </div>
            <WButton buttontext="Sign In" disabled={!formIsValid} onClick={signInHandler} />
        </div >
    </div>
    );
};

SignInForm.propTypes = {
    state: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    signInHandler: PropTypes.func,
    changed: PropTypes.func,
    blurred: PropTypes.func
}

SignInForm.defaultProps = {
    state: {},
    history: [],
    signInHandler: () => { },
    changed: () => { },
    blurred: () => { }
}

export default SignInForm;
