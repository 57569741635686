import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    accounts: [],
    addAccountResponse: {},

    selectedAccountDetails: {},
    selectedBankDetails: {},
    selectedFundsDetails: [],
    selectedFrequencyDetails: {},
    error:{},
};

function bankToFundReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_BTF_ACCOUNT_LIST: {
            return { ...state, ...action, isLoading: true, isError: false, isSuccess: false };
        }
        case ActionTypes.GET_BTF_ACCOUNT_LIST_LOADER: {
            return { ...state, ...action, isLoading: true, isError: false, isSuccess: false };
        }
        case ActionTypes.GET_BTF_ACCOUNT_LIST_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true };
        }
        case ActionTypes.GET_BTF_ACCOUNT_LIST_FAILURE: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true };
        }
        case ActionTypes.GET_BTF_ACCOUNT_LIST_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true };
        }
        case ActionTypes.ADD_NEW_BTF_ACCOUNT: {
            return { ...state, ...action, isLoading: true, isError: false, isSuccess: false };
        }
        case ActionTypes.ADD_NEW_BTF_ACCOUNT_LOADER: {
            return { ...state, ...action, isLoading: true, isError: false, isSuccess: false };
        }
        case ActionTypes.ADD_NEW_BTF_ACCOUNT_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true };
        }
        case ActionTypes.ADD_NEW_BTF_ACCOUNT_FAILURE: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true };
        }
        case ActionTypes.ADD_NEW_BTF_ACCOUNT_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true };
        }

        case ActionTypes.SAVE_SELECTED_ACCOUNT_DETAILS: {
            return { ...state, ...action, selectedAccountDetails: action.data };
        }
        case ActionTypes.SAVE_SELECTED_BANK_DETAILS: {
            return { ...state, ...action, selectedBankDetails: action.data };
        }
        case ActionTypes.SAVE_SELECTED_FUND_DETAILS: {
            return { ...state, ...action, selectedFundsDetails: action.data };
        }
        case ActionTypes.SAVE_SELECTED_FREQUENCY_DETAILS: {
            return { ...state, ...action, selectedFrequencyDetails: action.data };
        }
        case ActionTypes.REMOVE_SELECTED_DETAILS: {
            return { ...state, ...action, addAccountResponse: {}, selectedAccountDetails: {}, selectedBankDetails: {}, selectedFundsDetails:[], selectedFrequencyDetails: {}, error: {} };
        }
        case ActionTypes.REMOVE_ERROR_DETAILS: {
            return { ...state, ...action, error: {},isError: false};
        }
        default:
            return state;
    }
}

export default bankToFundReducer;
