import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContactCenterFooterRow from './ContactCenterFooterRow';

const S = {};

S.FooterContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
`;

const ContactCenterFooter = ({ data }) => {
    return (
        <div data-test = 'contactcenterfooter'>
            <S.FooterContainer>
                {data.map(({ id, ...rest }) => (
                    <ContactCenterFooterRow id={id} key={id} {...rest} />
                ))}
            </S.FooterContainer>
        </div>
    );
};

ContactCenterFooter.defaultProps = {
    data: [],
};

ContactCenterFooter.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            message: PropTypes.string,
            SvgComponent: PropTypes.element,
        }),
    ),
};

export default ContactCenterFooter;
