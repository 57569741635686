import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Popover, PopoverBody } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BasicCard } from '../../../common';
import { useClickAway } from '../../../hooks';
import { memberNumberCardConstants } from './constants';

const S = {};

S.GridContainer = styled.div`
    display: grid;
    margin: -30px;
    min-height: ${props => props.height};
    grid-column-gap: 1;
    grid-template-areas:
        'topLeft vertDivider topMiddle topRight'
        'horizDivider horizDivider horizDivider horizDivider'
        'bottomHalf bottomHalf bottomHalf bottomHalf';
    grid-template-columns: 264px 1px 1fr 1fr;
    grid-template-rows: 1fr 1px 1fr;
`;

S.TopLeft = styled.div`
    grid-area: topLeft;
    margin: auto auto auto 45px;
    color: #486d90;
    font: 900 22px/1 yorkten-slab-normal;
`;

S.TopMiddle = styled.div`
    grid-area: topMiddle;
    margin: auto auto auto 30px;
    color: #49494a;
    font: 501 22px/1 benton-sans;
`;

S.TopRight = styled.div`
    grid-area: topRight;
    margin: auto 25px auto auto;
    color: #004a98;
    font: 900 16px/20px yorkten-slab-normal;
`;

S.BottomHalf = styled.div`
    grid-area: bottomHalf;
    font: 500 16px/22px benton-sans;
    color: #49494a;
    margin: auto 0px auto 40px;
`;

S.Heading = styled.div`
    color: #486d90;
    font: 900 20px/22px yorkten-slab-normal;
`;

S.VertDivider = styled.div`
    grid-area: vertDivider;
    background-color: #d2d2d2;
    min-height: 100%;
`;

S.HorizDivider = styled.div`
    grid-area: horizDivider;
    background-color: #d2d2d2;
    min-width: 100%;
`;

S.CopyToClipboard = styled(CopyToClipboard)`
    :focus,
    button:focus,
    .btn:focus,
    a:focus > .headerLogo {
        outline: 1px dotted;
        outline: 2px solid -webkit-focus-ring-color;
        outline-offset: 2px;
    }
    cursor: pointer;
`;

const cardProps = {
    height: '120px',
    triSideLength: '35px',
};

const { HEADING, LINK, BODY } = memberNumberCardConstants;

function MemberNumberCard({ className }) {
    const { profileInformationData: { profileInformation = {} } = {} } = useSelector(state => state);
    const { vcmId = null } = profileInformation;
    const [isCopied, setIsCopied] = useState(false);
    const [clipboardContent, setClipboardContent] = useState('');
    const [popoverOpen, setPopoverOpen] = useState(false);
    const clickToCopyRef = useRef(null);

    useEffect(() => {
        setClipboardContent(vcmId);
    }, [isCopied, setClipboardContent, vcmId]);

    useClickAway(clickToCopyRef, () => {
        setPopoverOpen(false);
    });

    const toggle = () => {
        setPopoverOpen(!popoverOpen);

        setTimeout(() => {
            setPopoverOpen(false);
        }, 1000);
    }

    return (
        <BasicCard {...cardProps} className={className}>
            <S.GridContainer height={cardProps.height}>
                <S.TopLeft>{HEADING}</S.TopLeft>
                <S.VertDivider />
                <S.TopMiddle>{vcmId}</S.TopMiddle>
                <S.CopyToClipboard text={vcmId} onCopy={() => setIsCopied(true)}>
                    <S.TopRight id="copy-vcmid-popover" ref={clickToCopyRef}>{LINK}</S.TopRight>
                </S.CopyToClipboard>
                <Popover placement="top" isOpen={popoverOpen} fade target="copy-vcmid-popover" toggle={toggle}>
                    <PopoverBody>Copied!</PopoverBody>
                </Popover>
                <S.HorizDivider />
                <S.BottomHalf>{BODY}</S.BottomHalf>
            </S.GridContainer>
        </BasicCard>
    );
};

MemberNumberCard.defaultProps = {
    className: '',
};

MemberNumberCard.propTypes = {
    className: PropTypes.string,
};

export default MemberNumberCard;
