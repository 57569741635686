import styled from 'styled-components';

const ErrorMsg = styled.small`
    position: relative;
    display: block;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #e60000;
    opacity: 1;
`;

export default ErrorMsg;