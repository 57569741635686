import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ConfirmationMessage } from "modules/CustomerManagementModule/components";

const CANCEL_CONFIRMATION_TITLE = "Please Confirm";
const CANCEL_CONFIRMATION_BODY_TEXT = "Are you sure you want to cancel?";
const CANCEL_CONFIRMATION_PRIMARY_BTN_TXT = "Continue";

export const showCancelConfirmation = ({setShow, setRoute, history}) => (show, route) => {
    if(show){
        setShow(show);
        setRoute(route);
    } else {
        history.push(route);
    }
};

export const onPrimaryClick = ({setShow, route, history}) => () => {
    setShow(false);
    history.push(route);
}

export const onSecondaryClick = (setShow) => () => {
    setShow(false);
}

const withFormCancelDialog = Component => props => {
    const [show, setShow] = useState(false);
    const [route, setRoute] = useState('');

    const history = useHistory();

    return (
        <>
            {show && (
                <ConfirmationMessage
                    modalTitle={CANCEL_CONFIRMATION_TITLE}
                    modalBodyText={CANCEL_CONFIRMATION_BODY_TEXT}
                    primaryButtonText={CANCEL_CONFIRMATION_PRIMARY_BTN_TXT}
                    onPrimaryClick={onPrimaryClick({setShow, route, history})}
                    onSecondaryClick={onSecondaryClick(setShow)}
                />
            )}
            <Component {...props} showCancelConfirmation={showCancelConfirmation({setShow, setRoute, history})} />
        </>
    );
};

export default withFormCancelDialog;
