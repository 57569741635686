import Consts from './Consts';

const getMailingAddress = address => {
    return `${address && address.lineTwo},${address && address.zipCode}, ${address && address.city}, ${address &&
        address.state}`;
};

const getValue = (lookupString, lookupValue, masterLookupStateData) => {
    const lookupList = masterLookupStateData[lookupString.toString()].value;
    const lookupData = lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
    if (lookupData) {
        return lookupData.value;
    }
    return Consts.None;
};
const getJointOwnerInfoCard = (masterLookupStateData, jointOwner, index) => {
    let jointOwnerInfoCard = {};
    if (jointOwner) {
        jointOwnerInfoCard = {
            cardId: `jointPersonalInfo`,
            linkText: Consts.edit,
            cardHeading: `Personal Information - Joint Owner ${index + 1}`,
            fields: [
                {
                    uniqueKey: `relationship${index}`,
                    key: Consts.relation,
                    value: getValue('relationship', jointOwner.relationship, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `prefix${index}`,
                    key: Consts.prefix,
                    value: getValue('prefix', jointOwner.prefix, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `firstName${index}`,
                    key: Consts.fname,
                    value: jointOwner.firstName || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `middleName${index}`,
                    key: Consts.mname,
                    value: jointOwner.middleInitial || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `lastName${index}`,
                    key: Consts.lname,
                    value: jointOwner.lastName || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `suffix${index}`,
                    key: Consts.suffix,
                    value: getValue('suffix', jointOwner.suffix, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `dateOfBirth${index}`,
                    key: Consts.dob,
                    value: jointOwner.dateOfBirth || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `gender${index}`,
                    key: Consts.gender,
                    value: getValue('gender', jointOwner.gender, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `maritalStatus${index}`,
                    key: Consts.maritalStatus,
                    value: getValue('marital_status', jointOwner.maritalStatus, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `citizenship${index}`,
                    key: Consts.citizenship,
                    value: getValue('citizenship', jointOwner.citizenship, masterLookupStateData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `ssn${index}`,
                    key: Consts.ssn,
                    value: (jointOwner.jointSSNData && jointOwner.jointSSNData.maskedFullSSN) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `mailingAddress${index}`,
                    key: Consts.mailingAddressLabel,
                    value: getMailingAddress(jointOwner.jointMailAddressData) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `physicalAddress${index}`,
                    key: Consts.physicalAddressLabel,
                    value:
                        (jointOwner.sameAddress === 'Yes'
                            ? getMailingAddress(jointOwner.jointMailAddressData)
                            : getMailingAddress(jointOwner.jointPhysicalAddressData)) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `primaryPhoneNumber${index}`,
                    key: Consts.primaryPhoneNumber,
                    value: `${jointOwner.jointPhoneDetailsData.primaryPhoneDetails.countryCode} ${jointOwner
                        .jointPhoneDetailsData.primaryPhoneDetails.phoneNumber || Consts.None}`,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `secondaryPhoneNumber${index}`,
                    key: Consts.secondaryPhoneNumber,
                    value: `${
                        jointOwner.jointPhoneDetailsData.secondaryPhoneDetails.phoneNumber
                            ? jointOwner.jointPhoneDetailsData.secondaryPhoneDetails.countryCode
                            : ''
                    } ${jointOwner.jointPhoneDetailsData.secondaryPhoneDetails.phoneNumber || Consts.None}`,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `primaryEmail${index}`,
                    key: Consts.primaryEmail,
                    value: jointOwner.jointEmailAddressData.primaryEmailDetails.email || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: `secondaryEmail${index}`,
                    key: Consts.secondaryEmail,
                    value: jointOwner.jointEmailAddressData.secondaryEmailDetails.email || Consts.None,
                    type: Consts.staticfield,
                },
            ],
        };
    }

    return jointOwnerInfoCard;
};

export default function generateJointOwnerInfoCard(masterLookupStateData, jointPerInfo) {
    let jointPerInfoCards = [];
    if (jointPerInfo && jointPerInfo.length > 0) {
        jointPerInfoCards = jointPerInfo.map((jointOwner, index) => {
            return getJointOwnerInfoCard(masterLookupStateData, jointOwner, index);
        });
    }

    return jointPerInfoCards;
}
