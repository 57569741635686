import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isPageLoading: false,
    pageNumber: 0,
    isMoreDataAvailable: true,
    activities: [
        {
            title: 'February',
            data: [
                {
                    id: '001',
                    fundName: 'USAA Aggressive Growth fund',
                    fundSymbol: 'USAUX',
                    description: 'Purchase',
                    transactionStatus: 'Pending',
                    transactionDate: '2/25/2020',
                    quatity: 100.23,
                    paidPrice: '40.52',
                    value: '4,052.00',
                },
                {
                    id: '002',
                    fundName: 'USAA Science & Technology Adviser',
                    fundSymbol: 'USTCX',
                    description: 'Dividend Reeceived',
                    transactionStatus: 'Confirmed',
                    transactionDate: '2/15/2020',
                    quatity: 0,
                    paidPrice: '0.00',
                    value: '14.67',
                },
                {
                    id: '003',
                    fundName: 'USAA Growth & Income Adviser',
                    fundSymbol: 'USGIX',
                    description: 'Dividend Reinvested',
                    transactionStatus: 'Confirmed',
                    transactionDate: '2/02/2020',
                    quatity: 10,
                    paidPrice: '40.52',
                    value: '16.52',
                },
                {
                    id: '004',
                    fundName: 'USAA Intermediate-Term Bond Adviser',
                    fundSymbol: 'UITBX',
                    description: 'Puchase',
                    transactionStatus: 'Completed',
                    transactionDate: '2/02/2020',
                    quatity: 107,
                    paidPrice: '36.52',
                    value: '243.00',
                },
            ],
        },
        {
            title: 'January',
            data: [
                {
                    id: '001',
                    fundName: 'USAA Intermediate-Term Bond Adviser',
                    fundSymbol: 'UITBX',
                    description: 'Puchase',
                    transactionStatus: 'Completed',
                    transactionDate: '1/20/2020',
                    quatity: 10,
                    paidPrice: '36.52',
                    value: '3,243.00',
                },
                {
                    id: '002',
                    fundName: 'USAA Value Fund',
                    fundSymbol: 'USGJX',
                    description: 'Redemption',
                    transactionStatus: 'Confirmed',
                    transactionDate: '1/17/2020',
                    quatity: 563.441,
                    paidPrice: '16.42',
                    value: '100,00.00',
                },
                {
                    id: '003',
                    fundName: 'USAA World Growth Fund',
                    fundSymbol: 'UIRBX',
                    description: 'Puchase',
                    transactionStatus: 'Completed',
                    transactionDate: '1/02/2020',
                    quatity: 10,
                    paidPrice: '36.52',
                    value: '3,243.00',
                },
            ],
        },
    ],
};

const AccountActivityReducer = (state = initialState, action) => {
    const currentState = state;
    
    switch (action.type) {
        case ActionTypes.SET_ACTIVITIES: {
            if (currentState.activities != null && currentState.activities.length !== 0) {
                currentState.activities = [...currentState.activities, ...action.payload];
            } else currentState.activities = [...action.payload];
            return currentState;
        }
        case ActionTypes.SET_PAGE_LOADING:
            return {
                ...state,
                isPageLoading: action.payload,
            };
        case ActionTypes.SET_MORE_DATA_AVAILABLE:
            return {
                ...state,
                isMoreDataAvailable: action.payload,
            };
        case ActionTypes.INCREMENT_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: state.pageNumber + 1,
            };
        
        default:
            return state;
    }
};

export default AccountActivityReducer;
