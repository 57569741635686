import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FundSelectionComponent from './FundSelectionComponent';
import withAccountManagement from '../AccountManagementHOC';

import {
    accOpeningActions,
    localStateManagementActions,
    addBankAccountAction,
    fundAccountActions,
} from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    localStateData: state.localStateManagementReducerData,
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    addBankAccountData: state.addBankAccountData,
    fundAccountData: state.fundAccountData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...addBankAccountAction,
    ...fundAccountActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(FundSelectionComponent)));
