import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import checkValidity from 'utils/checkValidity';
import bankAccSpecimen from 'assets/bank-account-specimen.png';
import { bankAccountAction } from 'shared/Actions';
import WCard from '../WCard/WCard';
import WInput from '../WInput/WInput';
import WRadio from '../Radio/Radio';
import { checkORConditionWithParameter, getDataFromANDCondition } from 'commonHelper/CommonHelperFunctions';

const SPECIMEN = 'Check leaf specimen';
const ACCOUNT_TYPE = 'Type of Account';
const SERVICE_ERROR = "We do not have sufficient information to verify this bank account. Please try another bank account or send in copy of a voided check through the Message Center.";
const VALIDATION_GIACT_ERROR = "We do not have sufficient information to verify this bank account. Please try another bank account or send in copy of a voided check through the Message Center.";

const assignObj = obj => {
    return obj;
};
const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
// Workaround for styled component issue
const StyledColumn = styled(Col)`
    label {
        max-width: ${({ nomargin: { nomargin } }) => (nomargin ? 'none' : '17.8125')}rem;
    }
    max-width: 631px;
    .row {
        padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
        padding-right: 20px;
        div {
            max-width: 350px;
        }
    }
`;
const StyledColumnOne = styled(Col)`
    max-width: 452px;
    max-height: 388.5px;
    @media (max-width: 1200px) {
        && {
            padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
        }
    }
`;
const StyledRow = styled(Row)`
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.4375')}rem;
    padding-bottom: 40px;
`;

const StyledRowAlert = styled(Row)`
    @media (min-width: 1200px) {
        padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '27' : '27.5')}px;
    }
    padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
    padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
`;

const FlexRow = styled.div`
    input:focus+label::before {
        outline: none !important;
    }
    display: flex;
    align-items: center;
`;
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const radiotext = {
    cololr: '#56565A',
    font: '100 16px/22px benton-sans',
};
const displayNoneStyle = {
    display: 'none',
};
const cardStyle = { display: 'flex', flexDirection: 'column', minHeight: 90, minWidth: 220 };
const specimenCornerColor = { borderLeftColor: 'rgba(139, 193, 5, 1)' };
const specimentTitleStyle = {
    textAlign: 'left',
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 26,
    fontWeight: '600',
    fontFamily: 'yorkten-slab-normal',
    float: 'left',
    borderBottom: '1px solid rgba(139, 193, 5, 1)',
};
const Rules = {
    transitRoutingNumber: { length: 9, isNumeric: true, required: true },
    accountNumber: { minLength: 4, maxLength: 17, isNumeric: true, required: true },
    financialInstitutionName: { maxLength: 30 },
    accountOwner: { maxLength: 30, required: true },
};

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.5')}rem;
    margin-bottom: ${({ nomargin: { nomargin } }) => (nomargin ? '2.5' : 0)}rem;
`;


const errorRadioStyles = { color: 'red', font: ' 700 12px/22px benton-sans', position: 'absolute', top: 30 };

class OtherBankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankAccountType: 'Savings',
            prevTransitRoutingNumber: '',
            prevAccountNumber: '',
            prevAccountOwner: '',
            bankAccountDetails: [
                { label: 'Account Number', name: 'accountNumber' },
                { label: 'Transit Routing Number', name: 'transitRoutingNumber' },
                { label: 'Name of Account Owner', name: 'accountOwner' },
                { label: 'Name of Financial Institution', name: 'financialInstitutionName' },
            ],
            errorMsg: {},
            bankAccountTypeOptions: [
                { value: 'Savings', key: 'Savings', selected: true },
                { value: 'Checking', key: 'Checking' },
            ],
            retrivedState: false,
            isApiSuccess: false,
            alertMsg: false,
        };
        this.disableNext = true;
        this.accountDetails = {};
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { bankAccountInfo, savedState } = nextProps;
        const { verificationData, isSuccess, isError } = bankAccountInfo;
        if (Object.keys(verificationData).length && isSuccess) {
            const { accountVerified, bankName, accountType } = verificationData;
            if (accountVerified && accountVerified === 'false') {
                return {
                    alertMsg: true,
                    isApiSuccess: false,
                };
            }
            const { bankAccountDetails, errorMsg, bankAccountType } = prevState;
            bankAccountDetails[3].value = bankName;
            errorMsg.financialInstitutionName = '';
            return {
                bankAccountType: accountType ? accountType : bankAccountType,
                bankAccountTypeOptions: [
                    { value: 'Savings', key: 'Savings', selected: accountType ? accountType === 'Savings' : bankAccountType === 'Savings' },
                    { value: 'Checking', key: 'Checking', selected: accountType ? accountType === 'Checking' : bankAccountType === 'Checking' },
                ],
                bankAccountDetails,
                errorMsg,
                isApiSuccess: true,
                alertMsg: false,
                showRadioErrorMsg:false
            };
        }
        if (isError) {
            return {
                alertMsg: true,
            };
        }
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }
        return null;
    }

    componentDidMount() {
        this.updateStatus();
        const { retrivedState, bankAccountDetails, bankAccountType } = this.state;
        if (retrivedState) {
            this.accountDetails.accountType = bankAccountType;
            this.accountDetails.bankName = bankAccountDetails[3].value;
        }
    }

    componentDidUpdate() {
        const { clearBankAccDetails, bankAccountInfo } = this.props;
        const { verificationData } = bankAccountInfo;
        if (Object.keys(verificationData).length) {
            this.accountDetails = {
                ...verificationData,
                accountType: verificationData.accountType ? verificationData.accountType : this.state.bankAccountType
            }
            clearBankAccDetails();
        }
        this.updateStatus();
    }

    updateStatus = () => {
        const { setStatus } = this.props;
        const { bankAccountDetails, errorMsg, isApiSuccess } = this.state;

        let isError = false;
        const prevDisableStatus = this.disableNext;
        this.disableNext = false;
        Object.keys(errorMsg).forEach(item => {
            isError = isError || !!errorMsg[item.toString()];
        });
        let isAnyFieldEmpty = false;
        bankAccountDetails.forEach((item, index) => {
            isAnyFieldEmpty = isAnyFieldEmpty || (!item.value);
        });
        if (isAnyFieldEmpty || isError || !isApiSuccess) {
            this.disableNext = true;
        }
        if (prevDisableStatus !== this.disableNext) {
            setStatus();
        }
    };

    handleBankAccountType = e => {
        const { target } = e;
        this.setState(prevState => {
            const {
                bankAccountDetails,
                prevTransitRoutingNumber,
                prevAccountNumber,
                bankAccountType,
                alertMsg,
            } = prevState;
            const transitRoutingNumber = bankAccountDetails[1].value;
            const accountNumber = bankAccountDetails[0].value;
            let accountType = '';
            if (
                transitRoutingNumber &&
                accountNumber &&
                prevTransitRoutingNumber === transitRoutingNumber &&
                prevAccountNumber === accountNumber &&
                !alertMsg
            ) {
                accountType = bankAccountType;
            } else {
                accountType = target.id;
            }

            return {
                bankAccountType: accountType,
                showRadioErrorMsg: false,
                bankAccountTypeOptions: [
                    { value: 'Savings', key: 'Savings', selected: accountType === 'Savings' },
                    { value: 'Checking', key: 'Checking', selected: accountType === 'Checking' },
                ],
            };
        });
    };

    handleBankAccountDetailsChange = index => e => {
        const { target } = e;
        this.setState(prevState => {
            const { bankAccountDetails } = prevState;
            let value = target.value;
            if (index === 0 || index === 1) {
                value = value.replace(/[^0-9]/g, '');
            }
            if (index === 2 || index === 3) {
                value = value.replace(/[^A-Za-z\s]/gi, '');
            }
            bankAccountDetails[+index].value = value;

            return {
                bankAccountDetails,
            };
        });
    };

    validateFields = () => {
        const { bankAccountDetails, bankAccountType, errorMsg,alertMsg } = this.state;
        let radioError = false;
        let valid = false;
        if (bankAccountType !== '') {
            valid = true;
        } else {
            radioError = true;
        }
        let err = '';
        if (bankAccountDetails) {
            bankAccountDetails.forEach(item => {
                if (item.name !== 'financialInstitutionName' && item.name !== 'accountOwner') {
                    err = Rules[item.name]
                        ? checkValidity({
                              rules: Rules[item.name],
                              value: item.value,
                          })
                        : '';
                    errorMsg[item.name] = err ? 'Please enter a valid value' : '';
                }
            });
            if (Object.keys(errorMsg).length!== 0) {
                bankAccountDetails.forEach(item => {
                    if (item.name !== 'financialInstitutionName' && item.name !== 'accountOwner') {
                       if(checkORConditionWithParameter(errorMsg[item.name],null,errorMsg[item.name],'')){
                         valid=getDataFromANDCondition(valid,true)
                       }else{
                           valid=false
                       }
                    }
                });
            }
        }

        valid = getDataFromANDCondition(valid , !alertMsg)
        this.setState({ errorMsg, showRadioErrorMsg: radioError });
        return valid;
    };

    getState = () => {
        const valid = this.validateFields();
        return { ...this.state, valid };
    };

    handleOnlineSectionBlur = e => {
        const { target } = e;
        const { validateBankAccInfo } = this.props;
        let err = '';

        if(target.name == 'accountNumber'){
            if(!target.value){
                err = "Account Number can't be left blank";
            }else if(target.value.length < 4){
                err = 'Account Number must be between 4 to 17 digits';
            }
        }
        if(target.name == 'transitRoutingNumber'){
            if(!target.value){
                err = "Transit Routing Number can't be left blank";
            }else if(target.value.length !== 9){
                err = 'Transit Routing Number must be 9 digits';
            }
        }
        if(target.name == 'accountOwner'){
            if(!target.value){
                err = "Name of Account Owner can't be left blank";
            }
        }
        this.setState(
            prevState => {
                const { errorMsg } = prevState;
                errorMsg[target.name] = err;
                return { errorMsg };
            },
            () => {
                const {
                    /* bankAccountType, */
                    bankAccountDetails,
                    errorMsg,
                    prevTransitRoutingNumber,
                    prevAccountNumber,
                    prevAccountOwner,
                    alertMsg,
                } = this.state;
                const transitRoutingNumber = bankAccountDetails[1].value || '';
                const accountNumber = bankAccountDetails[0].value || '';
                const accountOwner = bankAccountDetails[2].value || '';
                if (
                    !errorMsg.transitRoutingNumber &&
                    !errorMsg.accountNumber &&
                    !errorMsg.accountOwner &&
                    transitRoutingNumber &&
                    accountNumber &&
                    accountOwner
                ) {
                    if (
                        prevTransitRoutingNumber !== transitRoutingNumber ||
                        prevAccountNumber !== accountNumber ||
                        prevAccountOwner !== accountOwner ||
                        alertMsg
                    ) {
                        this.setState({
                            prevAccountNumber: accountNumber,
                            prevAccountOwner: accountOwner,
                            prevTransitRoutingNumber: transitRoutingNumber,
                            isApiSuccess: false,
                        });
                        const { accountType = 'Savings' } = this.state || {};
                        const payload = {
                            bankAccountType: accountType,
                            transitRoutingNumber,
                            bankAccountNumber: accountNumber,
                            accountOwnersName: accountOwner,
                        };

                        validateBankAccInfo(payload);
                    } else {
                        const { accountType = '', bankName = '' } = this.accountDetails || {};
                        this.setState(() => {
                            bankAccountDetails[3].value = bankName;
                            errorMsg.financialInstitutionName = '';
                            return {
                                bankAccountType: accountType,
                                bankAccountDetails,
                                errorMsg,
                                isApiSuccess: true,
                            };
                        });
                    }
                }
            },
        );
    };

    render() {
        const {
            bankAccountType,
            bankAccountDetails,
            errorMsg,
            bankAccountTypeOptions,
            isApiSuccess,
            alertMsg,
            showRadioErrorMsg = false,
        } = this.state;
        const { nomargin, viewOnly, accOpeningReducerData, bankAccountInfo } = this.props;
        const disable = !errorMsg.transitRoutingNumber && !errorMsg.accountNumber && !errorMsg.accountOwner && isApiSuccess && !alertMsg;
        // Workaround for styled component issue
        const marginProps = { nomargin: { nomargin } };
        const radioErrorMsg = 'Please select an Account Type';
        let serverError = SERVICE_ERROR;
        if (
            accOpeningReducerData &&
            accOpeningReducerData.response &&
            accOpeningReducerData.response.accountVerified &&
            'false' === accOpeningReducerData.response.accountVerified
        )
        {
            serverError = VALIDATION_GIACT_ERROR;
        } else if(
            bankAccountInfo &&
            bankAccountInfo.error
        ) {
            serverError = bankAccountInfo.error;
        }
        return (
            <>
                <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                    <Col>
                        {alertMsg && (
                            <StyledAlert {...marginProps} variant="danger">
                                <StyledPara>{serverError}</StyledPara>
                            </StyledAlert>
                        )}
                    </Col>
                </StyledRowAlert>
                <StyledRow {...marginProps} noGutters>
                    <StyledColumn sm={7} {...marginProps}>
                        <Row noGutters style={assignObj({ marginBottom: 20 })}>
                            <StyledLabel column htmlFor="AccountType">
                                {ACCOUNT_TYPE}
                            </StyledLabel>
                            <input type="hidden" name="AccountType" id="AccountType" />
                            <Col sm={8}>
                                <FlexRow role="radiogroup">
                                    {bankAccountTypeOptions.map(item => {
                                        return (
                                            <WRadio
                                                id={item.value}
                                                key={item.key}
                                                name={item.value}
                                                value={item.value}
                                                selected={bankAccountType === item.value}
                                                onClick={this.handleBankAccountType}
                                                radiotext={radiotext}
                                                selected={item.selected}
                                                viewOnly={viewOnly}
                                                className='analytics-form-fields'
                                            />
                                        );
                                    })}
                                </FlexRow>
                                {showRadioErrorMsg && <span style={errorRadioStyles}>{radioErrorMsg}</span>}
                            </Col>
                        </Row>
                        {bankAccountDetails.map((data, index) => {
                            const length = { maxlength: 25 };
                            if (index === 0) {
                                length.maxlength = 17;
                            } else if (index === 1) {
                                length.maxlength = 9;
                            }
                            return viewOnly && ( data.value === undefined || data.value.length == 0 ) ? null : (
                                <WInput
                                    key={index.toString()}
                                    type="text"
                                    controlid={data.name}
                                    label={data.label}
                                    labelsm={0}
                                    valuesm={8}
                                    placeholder={data.label}
                                    value={data.value || ''}
                                    name={data.name}
                                    id={data.name}
                                    onChange={this.handleBankAccountDetailsChange(index)}
                                    onBlur={this.handleOnlineSectionBlur}
                                    errortext={errorMsg[data.name]}
                                    readOnly={index === 3 && disable}
                                    viewOnly={viewOnly}
                                    noGutters
                                    optional='false'
                                    {...length}
                                    inputclassname="analytics-form-fields"
                                />
                                );
                        })}
                    </StyledColumn>
                    {viewOnly ? null :
                    <StyledColumnOne {...marginProps} sm={5}>
                        <FlexCol>
                            <WCard
                                className="specimenCard"
                                buttonstyle={displayNoneStyle}
                                src={bankAccSpecimen}
                                cardstyle={cardStyle}
                                leftcornercard={specimenCornerColor}
                                cardtext="Account number and all owner names must be preprinted and unaltered"
                                cardtitletext={SPECIMEN}
                                cardtitlestyle={specimentTitleStyle}
                                alt={SPECIMEN}
                            />
                        </FlexCol>
                    </StyledColumnOne>
                    }
                </StyledRow>
            </>
        );
    }
}

OtherBankAccount.propTypes = {
    setStatus: PropTypes.func,
    validateBankAccInfo: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    bankAccountInfo: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    nomargin: PropTypes.bool,
    viewOnly: PropTypes.bool
};

OtherBankAccount.defaultProps = {
    setStatus: () => {},
    validateBankAccInfo: () => {},
    clearBankAccDetails: () => {},
    bankAccountInfo: {},
    savedState: {},
    nomargin: false,
    viewOnly: false,
};

const mockValidateBankAccInfo = payload => dispatch => {
    const mockRoutingNumber = '011401533';
    const mockAccountNumber = '9878449888';
    if (payload.routingNumber === mockRoutingNumber && payload.accountNumber === mockAccountNumber) {
        dispatch({
            type: 'VALIDATE_BANK_ACCOUNT_SUCCESS',
            response: {
                accountVerified: 'true',
                bankName: 'WELLS FARGO BANK NA (ARIZONA)',
                accountType: 'Checking',
            },
        });
    } else {
        dispatch({
            type: 'VALIDATE_BANK_ACCOUNT_SUCCESS',
            response: {
                accountVerified: 'false',
            },
        });
    }
};

export const mapStateToProps = state => {
    return {
        bankAccountInfo: state.bankAccountReducerData,
        accOpeningReducerData: state.accOpeningReducerData,
    };
};
export const mapDispatchToProps = {
    validateBankAccInfo: bankAccountAction?.validateBankAccInfo,
    // validateBankAccInfo: mockValidateBankAccInfo,
    clearBankAccDetails: bankAccountAction?.clearBankAccDetails,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OtherBankAccount);
