import { LiveChatActionTypes } from '../ReduxConstants';
import { initialState, resetState } from './LiveChatInitialState';

const {
    GET_LIVECHAT_QUEUE_METRICS_FAILURE,
    GET_LIVECHAT_QUEUE_METRICS_SUCCESS,
    GET_LIVECHAT_QUEUE_WAITTIME_FAILURE,
    GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS,
    GET_LIVECHAT_QUEUES_STATUS_FAILURE,
    GET_LIVECHAT_QUEUES_STATUS_SUCCESS,
    GET_LIVECHAT_QUEUES_STATUS,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES,
    GET_MSR_AVAILABILITY_LOADER,
    GET_MSR_AVAILABILITY_SUCCESS,
    GET_MSR_AVAILABILITY_FAILURE,
    GET_MSR_AVAILABILITY,
    GET_PHONE_QUEUE_WAITTIME_FAILURE,
    GET_PHONE_QUEUE_WAITTIME_SUCCESS,
    GET_PHONE_QUEUE_WAITTIME,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION,
    RESET_FAB,
    RESET_LIVECHAT_QUEUE_STATE,
    RESET_PHONE_QUEUE_WAIT_TIME_FAB,
    RESET_SELECTED_QUERY,
    SET_CHAT_WIDGET_STATUS_FAILURE,
    SET_CHAT_WIDGET_STATUS,
    SET_LIVECHAT_QUEUE,
    SET_MODAL_ID,
    SET_SELECTED_QUERY,
    SET_SHOW_CONFIRM_DIALOG,
    SET_SHOW_MODAL,
    SET_TOGGLED,
    TOGGLE_SPINNER,
} = LiveChatActionTypes;

function chatReducer(state = initialState, action = { payload: undefined }) {
    switch (action.type) {
        // ACTION CASES w/statuses
        case GET_LIVECHAT_QUEUES_STATUS:
        case GET_PHONE_QUEUE_WAITTIME:
        case GET_PHONE_SUPPORT_HOURS_OF_OPERATION:
        case GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION: {
            return {
                ...state,
                isLoading: true,
                isSuccess: false,
                isError: false,
            };
        }
        // FAILURE CASES w/statuses
        case GET_LIVECHAT_QUEUES_STATUS_FAILURE:
        case GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE:
        case GET_PHONE_QUEUE_WAITTIME_FAILURE:
        case GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.error,
            };
        }
        // FAILURE CASES (basic)
        case SET_CHAT_WIDGET_STATUS_FAILURE: {
            return { ...state, error: action.error };
        }
        case GET_LIVECHAT_QUEUE_METRICS_FAILURE: {
            return { ...state, error: action.error, modalId: action.modalId };
        }
        case GET_LIVECHAT_QUEUE_WAITTIME_FAILURE: {
            const { isSpinning } = state;
            if (isSpinning) {
                return {
                    ...state,
                    modalId: 'ns-modal',
                };
            }
            return {
                ...state,
            };
        }
        // SUCCESS CASES
        case GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS: {
            return {
                ...state,
                availabilityText: action.availabilityText,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        case GET_PHONE_QUEUE_WAITTIME_SUCCESS: {
            return {
                ...state,
                phoneQueueWaitTime: action.phoneQueueWaitTime,
                phoneQueueWaitTimeFab: action.phoneQueueWaitTime,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        case RESET_LIVECHAT_QUEUE_STATE: {
            const { queueStatus, oWaiting, oOnQueueUsers, liveChatWaitTime } = action;
            return {...state, queueStatus, oWaiting, oOnQueueUsers, liveChatWaitTime };
        }
        case GET_LIVECHAT_QUEUE_METRICS_SUCCESS: {
            const { queueStatus, oWaiting, oOnQueueUsers } = action;
            return {...state, queueStatus, oWaiting, oOnQueueUsers };
        }
        case GET_LIVECHAT_QUEUES_STATUS_SUCCESS: {
         return { ...state, queuesStatus: action.queuesStatus};
        }
        case GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS: {
            return { ...state, liveChatWaitTime: action.liveChatWaitTime };
        }
        case GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS: {
            const { schedules } = state;
            const { scheduleId, hoursAvailable, dashboardHoursAvailable, startTime, endTime } = action;
            const newSchedule = {
                ...schedules[`${scheduleId}`],
                hoursAvailable,
                isFetch: true,
                dashboardHoursAvailable,
                startTime,
                endTime,
            };
            const newSchedules = {
                ...schedules,
                [scheduleId]: newSchedule,
            };
            return {
                ...state,
                schedules: newSchedules,
                isLoading: true,
                isSuccess: true,
                isError: false,
            };
        }
        // ACTIONS CASES, NO API CALL
        case RESET_FAB: {
            return { ...state, ...resetState };
        }
        case RESET_PHONE_QUEUE_WAIT_TIME_FAB: {
            return { ...state, phoneQueueWaitTimeFab: initialState.phoneQueueWaitTimeFab };
        }
        case RESET_SELECTED_QUERY: {
            return { ...state, selectedQuery: null };
        }
        case SET_CHAT_WIDGET_STATUS: {
            return { ...state, chatWidgetStatus: action.chatWidgetStatus };
        }
        case SET_MODAL_ID: {
            return { ...state, modalId: action.modalId };
        }
        case SET_LIVECHAT_QUEUE: {
            return { ...state, queueName: action.queueName };
        }
        case SET_SELECTED_QUERY: {
            if (action.selectedQuery === state.selectedQuery) {
                return { ...state, queueName: null, selectedQuery: null };
            }
            return { ...state, selectedQuery: action.selectedQuery };
        }
        case SET_SHOW_CONFIRM_DIALOG: {
            const { useCase, isVisible } = action;
            const showConfirmDialog = {
                ...state.showConfirmDialog,
                [useCase]: isVisible,
            };
            return { ...state, showConfirmDialog };
        }
        case SET_TOGGLED: {
            if (state.showModal === false) {
                return { ...state, showModal: true, toggled: true };
            }
            if (action.toggled !== undefined) {
                return { ...state, toggled: action.toggled };
            }
            return { ...state, toggled: !state.toggled };
        }
        case SET_SHOW_MODAL: {
            const showModal = action.showModal !== undefined ? action.showModal : !state.showModal;
            return { ...state, showModal };
        }
        case TOGGLE_SPINNER: {
            const isSpinning = action.isSpinning !== undefined ? action.isSpinning : !state.isSpinning;
            return { ...state, isSpinning };
        }
        // LIVE CHAT AVAILABILITY
        case GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER:
        case GET_MSR_AVAILABILITY_LOADER: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }
        case GET_MSR_AVAILABILITY_FROM_SCHEDULES:
        case GET_MSR_AVAILABILITY: {
            return { ...state, ...action };
        }
        case GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS:
        case GET_MSR_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }
        case GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE:
        case GET_MSR_AVAILABILITY_FAILURE: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        default:
            return state;
    }
}

export default chatReducer;
