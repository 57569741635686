const data = {
    title: 'Preference',
    info: ["Please select your document delivery preference."],
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Preference', 'Confirmation'],
    stepperStyle: { width: '100%', height: 60, paddingRight: 90, paddingLeft: 150 },
    stepperDivStyle: {width: '100%'},
    labelStyle: {
        display: 'block',
        marginBottom: '25px',
        font: '600 14px/17px benton-sans',
        color: '#49494A'
    },
    label: 'Update document delivery preference',
    onlineText: 'Deliver all my documents online at vcm.com',
    postText: 'Do not change my current document delivery preference (By Postal mail)'
  }

  export default data;