import styled from 'styled-components';
import { WTable } from '../../../common';

const data = {
    labelStyle: {
        border: 'none',
        color: '#49494A',
        padding: '25px 0',
        font: '600 16px/22px benton-sans'
    },
    thStyle: {
        border: 'none',
        color: '#49494A',
        padding: '25px 0 0 0',
        font: '800 18px/22px yorkten-slab-normal, sans-serif'
    },
    descStyle: {
        border: 'none',
        color: '#49494A',
        padding: '25px 0',
        font: '500 16px/20px benton-sans',
        paddingLeft: '20px'
    },
    qdescStyle: {
        border: 'none',
        color: '#49494A',
        padding: '20px 0',
        font: '500 16px/20px benton-sans',
        paddingLeft: '20px'
    },
    qlabelStyle: {
        border: 'none',
        color: '#49494A',
        padding: '20px 0',
        font: '600 16px/22px benton-sans'
    },
    th1Style: {
        border: 'none',
        color: '#49494A',
        padding: '0',
        font: '800 18px/22px yorkten-slab-normal, sans-serif'
    },
    StyledTable: styled(WTable)`
        &.table{
            margin-bottom: 0;
        }
    `
}

export default data;