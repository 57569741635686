/**
 * Preferences Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to choose account preferences
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
    WStepper,
    CommonButtons,
    WRadio,
    WSpinner,
    WSaveModal,
    WMessageBar,
    StyledToolTip,
    ConformationModal,
    WBreadCrumb,
} from '../../../common';
import consts from './consts';
import './Preferences.css';
import AccGlobalConstants from '../AccountManagementConstants';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

const StyledWRadio = styled(WRadio)`
    &:checked+label::after {
        background-color: blue;
        border: 1px solid blue;
    }
`

export default class PreferencesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
            cancel: false,
            errorFromPreferencesMasterData: false,
            showSaveModal: false,
            loading: true,
            masterDataLoadedForPref: false,
            selectedDividendCapitalGains: [],
            selectedProspectusReportsRef: [],
            dividendsId: '2',
            prospectusId: '',
            dividendsSelected: true,
            prospectusSelected: true,
            tooltipOpen: false,
            showConfirmCancelModal: false,
        };
        this.dividendsIdSelected = true;
        this.prospectusIdSelected = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { masterLookupStateData, accOpeningData, localStateData } = nextProps;
        const paymentMethod = localStateData.selectedMethod;

        return {
            ...prevState,
            loading: masterLookupStateData.isLoading,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            dividendsId: paymentMethod === 'offline' ? '2' : prevState.dividendsId,
        };
    }

    componentDidMount() {
        const { masterLookupStateData, getCompositeLookUpData, localStateData } = this.props;
        if (localStateData.accountType) {
            this.setState({ accountType: localStateData.accountType });
        }
        if (localStateData.accountPreferences) {
            if (
                localStateData.accountPreferences.dividendCapitalGain &&
                localStateData.accountPreferences.documentDeliveryFormat
            )
                this.dividendsIdSelected = true;
            this.prospectusIdSelected = true;
            this.setState({
                dividendsId: localStateData.accountPreferences.dividendCapitalGain || '2',
                prospectusId: localStateData.accountPreferences.documentDeliveryFormat,
                disableNext: false,
            });
        }
        if (localStateData.masterDataLoadedForPref !== undefined) {
            this.setState({ masterDataLoadedForPref: localStateData.masterDataLoadedForPref });
        }
        // if (localStateData.masterDataLoadedForPref) {
        //     this.setState({
        //         selectedDividendCapitalGains: masterLookupStateData.div_capGain_pref.value,
        //         selectedProspectusReportsRef: masterLookupStateData.stmt_pros_rep.value,
        //         loading: false,
        //     });
        // } else {
        const payload = [];
        const compositePayloadData = ['div_capGain_pref', 'stmt_pros_rep'];

        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
        getCompositeLookUpData(payload);
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
    }

    componentWillUnmount() {
        this.dividendsIdSelected = false;
        this.prospectusIdSelected = false;
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    updateState = prevProps => {
        const { masterLookupStateData, accOpeningData } = this.props;
        // if (masterLookupStateData.div_capGain_pref && masterLookupStateData.div_capGain_pref.value) {
        //     const selectedDividendCapitalGains = masterLookupStateData.div_capGain_pref.value;
        //     this.setState({
        //         selectedDividendCapitalGains,
        //     });
        // }
        // if (masterLookupStateData.stmt_pros_rep && masterLookupStateData.stmt_pros_rep.value) {
        //     const selectedProspectusReportsRef = masterLookupStateData.stmt_pros_rep.value;
        //     this.setState({ selectedProspectusReportsRef });
        // }
        // Loader values
        if (masterLookupStateData.isLoading && accOpeningData.isLoading) {
            this.setState({ loading: true });
        } else if (!masterLookupStateData.isLoading && !accOpeningData.isLoading) {
            this.setState({ loading: false });
        }

        // Flag used for local state management
        if (masterLookupStateData.isSuccess) {
            this.setState({ masterDataLoadedForPref: true });
        }

        // Master data API Error
        if (masterLookupStateData.isError) {
            this.setState({ errorFromPreferencesMasterData: true });
        } else if (!masterLookupStateData.isError) {
            this.setState({ errorFromPreferencesMasterData: false });
        }

        //   Save Function Values Start
        if (
            accOpeningData.PREFERENCE_SAVE_OPENING_ACCT &&
            accOpeningData.PREFERENCE_SAVE_OPENING_ACCT !== prevProps.accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT
        ) {
            // let msg = '';
            if (accOpeningData.PREFERENCE_SAVE_OPENING_ACCT.status) {
                //  msg = accOpeningData.PREFERENCE_SAVE_OPENING_ACCT.status;
                this.setState({ showSaveModal: true, loading: false });
            } else {
                const msg = accOpeningData.PREFERENCE_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        // Error
        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleRadio = (e, key) => {
        if (key === 'dividendsId') {
            this.dividendsIdSelected = true;
            this.setState({ [key]: e.target.id, dividendsSelected: true });
        } else {
            this.prospectusIdSelected = true;
            this.setState({ [key]: e.target.id, prospectusSelected: true });
        }
    };

    checkPreferencesStatus = () => {
        const { dividendsId, prospectusId } = this.state;
        return !!dividendsId && !!prospectusId;
    };

    radioBlur = (e, key) => {
        const { dividendsId, prospectusId } = this.state;
        let { dividendsSelected, prospectusSelected } = this.state;
        if (key === 'dividendsId' && !dividendsId) {
            dividendsSelected = false;
        } else if (key === 'prospectusId' && !prospectusId) {
            prospectusSelected = false;
        }
        this.setState(() => {
            const result = this.checkPreferencesStatus();
            return {
                dividendsSelected,
                prospectusSelected,
                disableNext: !result,
            };
        });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleNext = () => {
        if (this.isValid()) {
            this.setState({ next: true }, () => {
                this.saveToLocal();
            });
        }
    };

    isValid = () => {
        if (this.dividendsIdSelected && this.prospectusIdSelected) {
            return true;
        }
        if (this.dividendsIdSelected === false && this.prospectusIdSelected === false) {
            this.setState({ dividendsSelected: false, prospectusSelected: false });
        } else if (this.prospectusIdSelected === false) {
            this.setState({ prospectusSelected: false });
        } else if (this.dividendsSelected === false) {
            this.setState({ dividendsSelected: false });
        }
        return false;
    };

    saveToLocal = (action = '') => {
        const { manageLocalState, localStateData, saveApplication } = this.props;
        const { dividendsId, prospectusId, masterDataLoadedForPref } = this.state;
        const data = {
            accountPreferences: {
                dividendCapitalGain: dividendsId,
                documentDeliveryFormat: prospectusId,
            },
            masterDataLoadedForPref,
        };
        const payload = {
            accountPreferences: {
                dividendCapitalGain: dividendsId,
                documentDeliveryFormat: prospectusId,
            },
        };
        if (action === 'save') {
            const savePayload = { ...localStateData, OpenAccPageFive: payload, ...data };
            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: localStateData.accountType,
                    payload: savePayload,
                },
            });
        } else {
            manageLocalState({ OpenAccPageFive: payload, ...data });
        }
    };

    handleSave = () => {
        this.setState({ showSaveModal: false }, () => {
            if (this.isValid()) {
                this.saveToLocal('save');
            }
        });
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.saveToLocal();
        this.setState({ back: true });
    };

    toggleToolTip = () => {
        this.setState({ tooltipOpen: true });
    };

    closeTooltip = () => {
        this.setState({ tooltipOpen: false });
    };

    renderContent = fields => {
        const { masterLookupStateData } = this.props;
        const { div_capGain_pref: divCapGainMasterData, stmt_pros_rep: stmtProsMasterData } =
            masterLookupStateData || {};
        const { value: divCapGainMasterDataValue } = divCapGainMasterData || {};
        const { value: stmtProsMasterDataValue } = stmtProsMasterData || {};
        const divCapitalGainList = divCapGainMasterDataValue || [];
        const stmtsProsList = stmtProsMasterDataValue || [];
        const { tooltipOpen } = this.state;
        const infoIcon = 'infoIcon';
        return fields.map(field => {
            const radios = field.uniqueKey === 'dividendsId' ? divCapitalGainList : stmtsProsList;
            return (
                <div key={field.key}>
                    <div className="contentPerf">
                        <Row className="fieldkey" noGutters>
                            <h3 className="fieldlabel">{field.key}</h3>
                            {field.uniqueKey === 'prospectusId' && (
                                <>
                                    <button
                                        className="infoIcon"
                                        id="infoIcon"
                                        type="button"
                                        aria-label="Tool tip for Prospectus"
                                    >
                                        <span style={consts.hiddenStyle}>{infoIcon}</span>
                                    </button>
                                    <StyledToolTip
                                        id="tooltip"
                                        placement="bottom"
                                        tooltipOpen={tooltipOpen}
                                        targetID="infoIcon"
                                        handleToggle={this.toggleToolTip}
                                        autohide={false}
                                        triggerEvt="focus"
                                        tooltipText={field.hintText}
                                        closeTooltip={this.closeTooltip}
                                    />{' '}
                                </>
                            )}
                        </Row>
                        <Row noGutters>
                            <span>{field.radioHeading}</span>
                        </Row>
                        {this.renderRadio(radios, field.uniqueKey)}
                        <Row noGutters>
                            {field.uniqueKey === 'dividendsId' && <div className="dividendHint">{field.hintText}</div>}
                        </Row>
                    </div>
                </div>
            );
        });
    };

    renderRadio = (radio, key) => {
        const value = this.state;
        const { localStateData } = this.props;
        const paymentMethod = localStateData.selectedMethod;
        const disabled = key === 'dividendsId' && paymentMethod !== 'online';
        const { dividendsSelected, prospectusSelected } = this.state;
        const cn = key === 'dividendsId' ? 'radioWrapper' : 'radioWrap';
        return (
            <div className="optionsWrapper">
                <Row noGutters>
                    {radio.map(field => (
                        <div className={cn} key={field.key}>
                            <StyledWRadio
                                radioFor={key}
                                id={field.key}
                                value={field.value}
                                onBlur={assignObj(e => this.radioBlur(e, key))}
                                onClick={assignObj(e => this.handleRadio(e, key))}
                                radiodescstyles={consts.radiodescstyles}
                                radiostyles={consts.radiostyles}
                                radiotext={consts.radiotext}
                                // desc={field.description}
                                selected={value[key.toString()] === field.key}
                                disabled={disabled}
                            />
                        </div>
                    ))}
                </Row>
                <Row noGutters>
                    {key === 'dividendsId' && !dividendsSelected && (
                        <span className="error-msg" aria-live="polite" aria-describedby={key}>
                            {consts.dividendsError}
                        </span>
                    )}
                    {key !== 'dividendsId' && !prospectusSelected && (
                        <span className="error-msg" aria-live="polite" aria-describedby={key}>
                            {consts.prospectusError}
                        </span>
                    )}
                </Row>
            </div>
        );
    };

    renderCard = ({ cardHeading, fields, cardSubHeading }, index) => {
        return (
            <div key={index} className="cardWrapper">
                <Row className="cardrow" noGutters>
                    <Col md={7}>
                        <h2 className="cardheading">{cardHeading}</h2>
                    </Col>
                </Row>

                {cardSubHeading && (
                    <Row className="cardSubheading" noGutters>
                        <Col md={7}>
                            <span>
                                {cardSubHeading.p1}
                                <a href={cardSubHeading.h1} className="inlineLink">
                                    {cardSubHeading.a1}
                                </a>
                                {cardSubHeading.p2}
                            </span>
                        </Col>
                    </Row>
                )}
                <div className="cardContent">{this.renderContent(fields)}</div>
            </div>
        );
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    renderPage = (pageHeading, cards) => {
        const { preferencesMasterDataError, showSaveModal, showAlert, errorMessage, isErrorBar } = this.state;
        const { preferencesMasterDataErrorMsg, commonStyle } = consts;
        if (preferencesMasterDataError) {
            throw new Error(preferencesMasterDataErrorMsg);
        }
        return (
            <>
                <div className="preferencesPage">
                    {showAlert ? (
                        <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                    ) : null}
                    <div className="container">
                        <WBreadCrumb
                            activeCrumb={pageHeading}
                            breadCrumbItems={
                                localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                            }
                        />
                        <h1 className="preferencesHeading">{pageHeading}</h1>
                        <div className="pref-content">
                            <WStepper currentPage={4} pages={AccGlobalConstants.accountOpeningPages} />
                        </div>
                        <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        <div className="perf-card">
                            {cards &&
                                cards.map((card, index) => <div key={card.cardId}>{this.renderCard(card, index)}</div>)}
                        </div>
                    </div>
                    <div className="perf-buttonWrapper">
                        <CommonButtons
                            nextClick={this.handleNext}
                            saveClick={this.handleSave}
                            backClick={this.handleBack}
                            cancelClick={this.handleCancelClick}
                            style={commonStyle}
                            showSave
                            iscancel
                            cancelText="Cancel"
                            cancelStyle={assignObj(cancelButtonStyles)}
                            cancelOffset={assignObj({
                                span: 2,
                                offset: 6,
                            })}
                            //  disabled={disableNext}
                        />
                    </div>
                </div>
            </>
        );
    };

    render() {
        const {
            loading,
            error,
            showSaveModal,
            next,
            cancel,
            back,
            accountType,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
        } = this.state;
        const { accOpeningData } = this.props;
        const { cards, nextPath, backPath, pages } = consts;
        return (
            <div className="MainContainer">
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {loading && <WSpinner loading={loading} />}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}

                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                {!error && this.renderPage(AccGlobalConstants.getPageHeading(accountType), cards, pages)}
                {next ? <Redirect to={nextPath} /> : ''}
                {cancel ? <Redirect to="/" /> : ''}
                {back ? <Redirect to={backPath} /> : ''}
            </div>
        );
    }
}

PreferencesComponent.propTypes = {
    masterLookupStateData: PropTypes.shape({
        div_capGain_pref: PropTypes.instanceOf(Object),
        stmt_pros_rep: PropTypes.instanceOf(Object),
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        isSuccess: PropTypes.bool,
    }),
    getCompositeLookUpData: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    saveApplication: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
};

PreferencesComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    saveApplication: () => {},
    localStateData: {},
    manageLocalState: () => {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    resetShowConfirmModal: () => {},
    history: {},
};
