import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { bankAccountAction, RmdActions } from '../../../../../shared/Actions';
import CreateRMDComponent from './CreateRMDComponent';
import  mockedRmds from '../mockedData/mockedRmds';

export const getEligibleAccounts = () => dispatch => {
    dispatch({
        type: 'GET_RMD_ACCOUNTS_SUCCESS',
        payload: mockedRmds.mockCreateRMDWithPriorBalance
      });
};

export const getRMDTransactions = () => dispatch => {
    dispatch({
        type: 'GET_RMD_TRANSACTIONS_SUCCESS',
        payload: mockedRmds.mockedRMDs
      });
};

export const dismissNotificationError = () => dispatch => {
    dispatch({
        type: 'DISMISS_RMD_NOTIFICATION_FAILURE'
    });
}

export const mapStateToProps = state => {
    return {
        bankAccountReducerData: get(state, 'bankAccountReducerData', []),
        rmdReducerData: get(state, 'rmdReducerData', []),
        dateOfBirth: get(state, 'rmdReducerData.eligibility.dateOfBirth', ''),
        isSuccessfullySaved: get(state, 'rmdReducerData.isSuccess', false) && get(state, 'rmdReducerData.isAdd', false),
        hasError: get(state, 'rmdReducerData.isError', false),
        errorsData: get(state, 'rmdReducerData.error', {}),
        isLoading: get(state, 'bankAccountReducerData.isLoading', false) || get(state, 'rmdReducerData.isLoading', false)
    };
};

const mapDispatchToProps = {
    getBankAccountInfo: bankAccountAction.getBankAccounts,
    // GET accounts for add page
    getEligibleAccounts: RmdActions.getEligibleAccounts,
    // getEligibleAccounts: () => getEligibleAccounts(), //  mock data
    // GET RMDs for listing page
    getRMDTransactions: RmdActions.getRMDTransactions, // () => getRMDTransactions(), //  
    addRMD: RmdActions.addRMDTransaction,
    deleteRMD: RmdActions.deleteRMDTransaction,
    clearFundPlanInfo: RmdActions.clearFundPlanInfo,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    dismissNotificationError,
    resetRmdList: RmdActions.resetRmdList
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateRMDComponent));