import { CommonButtons, ConfirmModal, WSpinner } from 'common'
import BeneficiaryCard from '../../MarketBeneficiaryCard/BeneficiaryCard'
import React, { useEffect, useRef, useState } from 'react'
import { updateMarketNAOInfo, postMarketNAOInfo, clearAPIStatus} from '../../../../shared/Actions/MarketNAOActions';
import { useSelector,useDispatch } from 'react-redux';
import { getRelationshipInformations } from 'shared/Actions/ProfileRelationAction';
import { decrypt } from 'utils';
import encrypt from 'shared/Helpers/Encrypt';
import {ruleVoilationMessage, diableNextButtonStyle} from './consts'
import { getMemberProfileInformations } from 'shared/Actions/ProfileSettingsAction';


const Beneficiaries = (props) => {

const beneficiaryCardRef = useRef()
const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
const beneficiariesStateInitial = marketNAOInfoReducerData.marketNAOInfo
const [state, setState] = useState(beneficiariesStateInitial.beneficiaries)
const dispatch = useDispatch()
const profileRelationshipData = useSelector((state) => state.profileRelationshipData)


const [disableNextButton,setDisableNextButton] = useState(false)

const [communityPropertyStateRuleVoilated, setCommunityPropertyStateRuleVoilated] = useState(false)
const [apiError, setApiError] = useState(false)
const isLoading = marketNAOInfoReducerData.isLoading

useEffect(()=>{
   // get relationships 
  dispatch(
    getRelationshipInformations(decrypt)
  )

  // Get profile information
  dispatch(
    getMemberProfileInformations(decrypt)
  )
    
},[])

const redirectFun = () => {
  dispatch(clearAPIStatus('beneficiaries'))
  props?.showBenificiary ? props.HideBeneficiary(false) :props.clickNext()

}

useEffect(() => {
  if(marketNAOInfoReducerData.isbeneficiariesApiSuccess){
    setTimeout(redirectFun, 1000)
  
  }else if(marketNAOInfoReducerData.isbeneficiariesApiError){
    setApiError(true)
    dispatch(clearAPIStatus('beneficiaries'))
  }
}, [marketNAOInfoReducerData])


const handleExit = () => {
  setApiError(false)
}


const onDisableNextButtonClick = () => {
  const benfeciarydetails = beneficiaryCardRef?.current?.state;
  let findSpouse = false;
  for(let i=0 ; i<benfeciarydetails?.beneficiaryrefsPrimary?.length; i++){
    if(benfeciarydetails?.beneficiaryrefsPrimary[i]?.current?.state?.relationToAccHolder == 'spouse'){
      findSpouse = true;
      break;
    }
  }

  if(findSpouse){
    onNextClick()
  }
  else{
    setCommunityPropertyStateRuleVoilated(true)
  }
}


const beneficiariesPayloadObjectBuilder = async (beneArray) => {

  let returnObj = [];
  for(let i=0;i<beneArray.length;i++)
  {
    let ssnInfo = "";
    if(beneArray[i].beneficiarySSN.includes('X') == true)
    {
        ssnInfo = beneArray[i].beneficiarySSN;
    }
    else
    {

    }
    const objData = {
      beneficiaryType: beneArray[i].beneficiaryType == 'individuals' ? 'individuals' : 'non-individuals',
      firstName: beneArray[i].firstName,
      middleName: beneArray[i].middleName == undefined ? '' : beneArray[i].middleName,
      lastName: beneArray[i].lastName,
      distributionPer: beneArray[i].distributionPercentage,
      relation: beneArray[i].relationToAccHolder,

      maskedDOB: beneArray[i].beneficiaryDOB.includes('X') ? beneArray[i].beneficiaryDOB : "",
      dateOfBirth:( beneArray[i].beneficiaryDOB.includes('X') == false && beneArray[i].beneficiaryDOB != '') ? await encrypt(beneArray[i].beneficiaryDOB) : "",

      maskedSSN: beneArray[i].beneficiarySSN.includes('X') ? beneArray[i].beneficiarySSN : "",
      ssn:( beneArray[i].beneficiarySSN.includes('X') == false && beneArray[i].beneficiarySSN != '') ? await encrypt(beneArray[i].beneficiarySSN) : "",
      
      email: beneArray[i].emailAdd,
      relationshipId: beneArray[i]?.relationshipId  != '' ? beneArray[i].relationshipId : `new-${i}`,
      relationshipId: beneArray[i]?.relationshipId.startsWith("new") ?  'new' : beneArray[i].relationshipId,
    }
    returnObj.push(objData)
  }

  return returnObj;
}

const onNextClick = async() => {
  const savedBeneficiaryData = beneficiaryCardRef.current && beneficiaryCardRef.current.sendDataToParent('next');

  if(savedBeneficiaryData.valid){


    // -------------------------UPDATE GLOBAL REDUX STATE
    const saveToLocalpayload = {
      sectionToBeUpdated:'beneficiaries',
      state: {savedBeneficiaryData}
    }
    
    dispatch(updateMarketNAOInfo(saveToLocalpayload))

    const payload = {
      applicationId: marketNAOInfoReducerData.applicationId ,
      pageNumber: 9,
      source: "web",
      beneficiaries: {
        primary: await beneficiariesPayloadObjectBuilder(savedBeneficiaryData.beneficiaryPrimaryDataArray),
        contingent:await  beneficiariesPayloadObjectBuilder(savedBeneficiaryData.beneficiaryContingentDataArray)
      }
    }

    dispatch(postMarketNAOInfo({
        url: 'beneficiaries',
        payloadData: payload
    }))
    
  }
}

 const VerifyCancel = () => {
    props.HideBeneficiary(false)
  }
 const saveHandler = async () => {
    const savedBeneficiaryData = beneficiaryCardRef.current && beneficiaryCardRef.current.sendDataToParent('next');

    if (savedBeneficiaryData.valid) {


      // -------------------------UPDATE GLOBAL REDUX STATE
      const saveToLocalpayload = {
        sectionToBeUpdated: 'beneficiaries',
        state: { savedBeneficiaryData }
      }

      dispatch(updateMarketNAOInfo(saveToLocalpayload))

      const payload = {
        applicationId: marketNAOInfoReducerData.applicationId,
        pageNumber: 9,
        source: "web",
        beneficiaries: {
          primary: await beneficiariesPayloadObjectBuilder(savedBeneficiaryData.beneficiaryPrimaryDataArray),
          contingent: await beneficiariesPayloadObjectBuilder(savedBeneficiaryData.beneficiaryContingentDataArray)
        }
      }

      dispatch(postMarketNAOInfo({
        url: 'beneficiaries',
        payloadData: payload
      }))
     
     props.HideBeneficiary(false) 
      
    }
  }

  return (
    <div>

      {communityPropertyStateRuleVoilated ? (
          <ConfirmModal
              modalTitle={ruleVoilationMessage.title}
              modalBodyText={ruleVoilationMessage.message}
              primaryButtonText="OK"
              onPrimaryClick={()=>setCommunityPropertyStateRuleVoilated(false)}
          />
        
      ) : null}

      {apiError && (
          <ConfirmModal
              modalTitle={"Error"}
              modalBodyText={marketNAOInfoReducerData.postStatusMsg}
              primaryButtonText="OK"
              onPrimaryClick={handleExit}
          />
        
      )}

      {isLoading ? <WSpinner loading={isLoading} /> : null}


      <BeneficiaryCard
        ref={beneficiaryCardRef}
        savedState={state.savedBeneficiaryData}
        accountType="Traditional IRA"
        familyRelationShipInfo={profileRelationshipData.familyRelationShipInfo}
        savedRelationshipDropDownData={state.savedRelationshipDropDownData}
        setDisableNextButton={setDisableNextButton}
        setCommunityPropertyStateRuleVoilated={setCommunityPropertyStateRuleVoilated}
        communityPropertyStateRuleVoilated={communityPropertyStateRuleVoilated}
      /> 

       {props?.showBenificiary ? <div> <CommonButtons backText="Cancel" backClick={VerifyCancel} nextClick={disableNextButton ? onDisableNextButtonClick :saveHandler}  nextstyle={disableNextButton ? diableNextButtonStyle : {}}  nextText="Save" isShowBtn={true}/></div>
        : <div className='footer'> 
        <CommonButtons 
          backClick={props.clickBack} 
          nextClick={ disableNextButton ? onDisableNextButtonClick : onNextClick} 
          nextstyle={disableNextButton ? diableNextButtonStyle : {}} 
          nextText="Next" 
          isShowBtn={true}
          disabled={false}
          />
          
      </div>}

    </div>
  )
}

export default Beneficiaries