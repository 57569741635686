import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, isValidDate } from 'utils';
import { Col } from 'react-bootstrap';

import classes from '../Input/Input.module.css';
import { VCMDatePickerWithoutLabel, SSNComponent } from '../../../common';
import styles from './styles';

const JovSSNForm = props => {
    const { changed, dobState } = props;
    // const { form } = ssnState;

    const data = {
        ssnConfig: {
            type: 'text',
            label: 'Social Security Number',
            placeholder: 'xxx-xx-1234',
        },
        dobConfig: {
            type: 'date',
            label: 'Date Of Birth',
            placeholder: 'DD/MM/YYYY',
        },
        errorStyle: {
            paddingLeft: 15,
        },
        dobChanged: e => {
            changed(e, 'dob');
        },
        handleSSN: val => {
            // console.log(val)
            const e = {
                target: {
                    value: val,
                    id: 'ssn',
                },
            };
            changed(e);
        },
    };

    let inputElement = null;
    const startDateMin = new Date();
    startDateMin.setFullYear(startDateMin.getFullYear() - 18);

    inputElement = (
        <SSNComponent
            valuesm={12}
            labelsm={12}
            astype="Col"
            labelstyle={styles.labelStyle}
            inputstyle={styles.inputStyle}
            sendfullssn={data.handleSSN}
        />
    );

    return (
        <>
            <div className={classes.Input}>{inputElement}</div>
            <Col sm={6} style={styles.dobStyle}>
                <div className={classes.Input} aria-required="true">
                    <label className={classes.Label} htmlFor="dob">
                        {data.dobConfig.label}
                    </label>
                    <VCMDatePickerWithoutLabel
                        name="dateofBirth"
                        id="dob"
                        max={isValidDate(startDateMin) ? formatDate(startDateMin) : ''}
                        label={data.dobConfig.label}
                        onChange={data.dobChanged}
                        errortext={dobState.errMsg}
                        value={dobState.value}
                        dobValidation
                    />
                </div>
            </Col>
        </>
    );
};

JovSSNForm.propTypes = {
    dobState: PropTypes.instanceOf(Object),
    ssnState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            isEmail: PropTypes.bool,
            maxLength: PropTypes.number,
            required: PropTypes.bool,
        }),
    }),
    changed: PropTypes.func,
};

JovSSNForm.defaultProps = {
    dobState: {},
    ssnState: {
        value: '',
        errMsg: null,
        valid: false,
        touched: false,
        rules: {
            isEmail: true,
            maxLength: 30,
            required: true,
        },
    },
    changed: () => {},
};

export default JovSSNForm;
