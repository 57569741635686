import { connect } from 'react-redux';
import PrivacyComponent from './PrivacyComponent';
import { accOpeningActions, localStateManagementActions } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    accOpeningData: state.accOpeningReducerData,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...accOpeningActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PrivacyComponent);
