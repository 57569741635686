import { accOpeningActions, addressFormatActions, localStateManagementActions } from '../../../shared/Actions';
import Address from './Address';
import { connect } from 'react-redux';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    addressFormatData: state.addressFormatData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...addressFormatActions,
    ...localStateManagementActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Address);
