import React from 'react';
import PropTypes from 'prop-types';
import InputText from '../InputText';
import FieldValue from '../FieldValue';
import PrefilledInput from '../PrefilledInput';

const ADDRESS_LINE1 = 'Address Line 1';
const ADDRESS_LINE2 = 'Address Line 2';
const ZIP_CODE = 'Zip Code';
const CITY = 'City';
const STATE = 'State/Territory';

const PLACEHOLDER_ADDRESS_LINE1 = '<Example: Apt 5, Box 30…>';
const PLACEHOLDER_ADDRESS_LINE2 = '<Example: 123 NW Main St>';

const AddressFields = props => {
    const {
        optional,
        addressLine1,
        addressLine2,
        zipCode,
        city,
        state,
        addressLine2ErrorText,
        zipCodeErrorText,
        onChange,
        onBlur,
        addressLine1Text,
        addressLine2Text,
        zipCodeText,
        cityText,
        stateText,
        addressType,
        groupName,
    } = props;
    const addrLine1Id = `vcm-address-line1-${addressType}`;
    const addrLine2Id = `vcm-address-line2-${addressType}`;
    const zipcodeId = `vcm-address-zipcode-${addressType}`;
    const stateId = `vcm-address-state-${addressType}`;
    const cityId = `vcm-address-city-${addressType}`;
    const ariaDesAddress2 = `${addrLine2Id}-error`;
    const ariaDesZipCode = `${zipcodeId}-error`;

    return (
        <>
            <FieldValue
                label={addressLine1Text}
                htmlFor={addrLine1Id}
                labelsm={3}
                valuesm={5}
                value={
                    <InputText
                        id={addrLine1Id}
                        placeholder={PLACEHOLDER_ADDRESS_LINE1}
                        value={addressLine1}
                        onChange={onChange('addressLine1')}
                        onBlur={onBlur('addressLine1')}
                        maxLength="50"
                        data-name={groupName ? `${groupName} - ${addressLine1Text}` : addressLine1Text}
                        data-form-field
                    />
                }
                optional
                mb={1.875}
            />
            <FieldValue
                label={addressLine2Text}
                htmlFor={addrLine2Id}
                labelsm={3}
                valuesm={5}
                value={
                    <InputText
                        id={addrLine2Id}
                        placeholder={PLACEHOLDER_ADDRESS_LINE2}
                        value={addressLine2}
                        onChange={onChange('addressLine2')}
                        onBlur={onBlur('addressLine2')}
                        maxLength="50"
                        error={!!addressLine2ErrorText}
                        errorTxt={addressLine2ErrorText}
                        aria-describedby={ariaDesAddress2}
                        data-name={groupName ? `${groupName} - ${addressLine2Text}` : addressLine2Text}
                        data-form-field
                    />
                }
                optional={optional}
                mb={1.875}
            />
            <FieldValue
                label={zipCodeText}
                htmlFor={zipcodeId}
                labelsm={3}
                valuesm={5}
                value={
                    <InputText
                        id={zipcodeId}
                        value={zipCode}
                        onChange={onChange('zipCode')}
                        onBlur={onBlur('zipCode')}
                        maxLength="10"
                        error={!!zipCodeErrorText}
                        errorTxt={zipCodeErrorText}
                        aria-describedby={ariaDesZipCode}
                        data-name={groupName ? `${groupName} - ${zipCodeText}` : zipCodeText}
                        data-form-field
                    />
                }
                optional={optional}
                mb={1.875}
            />
            <FieldValue
                as="p"
                label={cityText}
                labelsm={3}
                valuesm={5}
                value={<PrefilledInput id={cityId} value={city || '-'} />}
                optional={optional}
                mb={1.875}
            />
            <FieldValue
                as="p"
                label={stateText}
                labelsm={3}
                valuesm={5}
                value={<PrefilledInput id={stateId} value={state || '-'} />}
                optional={optional}
                mb={1.875}
            />
        </>
    );
};

AddressFields.propTypes = {
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    addressLine2ErrorText: PropTypes.string,
    zipCodeErrorText: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    optional: PropTypes.bool,
    addressLine1Text: PropTypes.string,
    addressLine2Text: PropTypes.string,
    zipCodeText: PropTypes.string,
    cityText: PropTypes.string,
    stateText: PropTypes.string,
    addressType: PropTypes.string,
    groupName: PropTypes.string,
};

AddressFields.defaultProps = {
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '-',
    state: '-',
    addressLine2ErrorText: '',
    zipCodeErrorText: '',
    onChange: () => {},
    onBlur: () => {},
    optional: false,
    addressLine1Text: ADDRESS_LINE1,
    addressLine2Text: ADDRESS_LINE2,
    zipCodeText: ZIP_CODE,
    cityText: CITY,
    stateText: STATE,
    addressType: '',
    groupName: '',
};

export default AddressFields;
