
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
/* import styles from '../CommonStyles' */



const WCheckBoxComponent = props => {
    const { value, subtextvalue } = props;
    return (
        <Form.Group>
            <Form.Check type="checkbox">
                <Form.Check.Input type="checkbox" isValid style={{width: '40px', padding: '0px'}}/>
                <Form.Check.Label>{value}</Form.Check.Label>
                <Form.Control.Feedback type="valid">{subtextvalue}</Form.Control.Feedback>
            </Form.Check>
        </Form.Group>
    )
};

WCheckBoxComponent.propTypes = {
    selected: PropTypes.bool,
    value: PropTypes.string,
    // variant :PropTypes.string.isRequired
    variant: PropTypes.oneOf(['innerSquare', 'checkMark']),
    subtext: PropTypes.bool,
    subtextvalue: PropTypes.string
}

WCheckBoxComponent.defaultProps = {
    selected: false,
    variant: 'innerSquare',
    subtext: false,
    subtextvalue: '',
    value: '',
}

export default WCheckBoxComponent;







 