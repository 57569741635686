import React, { Component } from 'react';
import { NavDropdown, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect, withRouter } from 'react-router-dom';
import logo from '../../assets/VCM_logo.svg';
import WIcon from '../WIcon/WIcon';
import CONSTS from './CONSTS';
import '../CommonStyles';
import { isBackOfficeUser } from 'utils/utils';
import { getSessionValues } from 'shared/Helpers/Utils';
import { LocalStorageHelper } from 'utils/sessionHelper';
import jwt_decode from "jwt-decode";
const {
    StyledNavDropdown,
    StyledLink,
    StyledLinkEnd,
    assignObj,
    skipText,
    styles,
    HolderView,
    AccountServicesRoute,
    DocumentCenterRoute,
    PortfolioPlannerPdfRoute,
    accessFormsRoute,
    Border,
    MSRLOOKUPRoute,
} = CONSTS;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span style={assignObj({ position: 'relative' })}>
            &#x25bc;
            <span className="carret-up" />
        </span>
    </a>
));

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaderEnable: false,
            redirect: false,
            username: 'User Name',
            logOutRoute: {
                pathname: '/msr',
                message: { message: 'Please login to this portal from SF useful links' },
            },
            carretPositions: {},
            showCarret: false,
            showHomePopOver: true,
            showProductsPopOver: false,
            showadviceToolsPopOver: false,
            showAccountServicesPopOver: false,
            showDocumentCenterPopOver: false,
            showPortfolioPlannerPdfPopOver: false,
            showFormsPopOver: false,
            msrlookupPopOver: false,
            msrDtsObj:null,
            isBackOfficeUserEnable:false
        };
        //  this.handleLogOut = this.handleLogOut.bind(this);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        if (!sessionStorage.getItem('msr_dts')) {
            this.handleLogOut();
        } else {
            const { getCustomerProfile,getApplicationConfig } = this.props;
            const params = getSessionValues();
            this.setState({msrDtsObj:params , 
                isBackOfficeUserEnable: !isBackOfficeUser()})
            const { accesstoken = '', vcmid = '' } = params;

            const payLoad = {
                payloadData : {"loginLatitude":"","loginLongitude":"","deviceId":""}};
            
            if(isBackOfficeUser() == false)
            {
                getCustomerProfile(payLoad);
            }
            getApplicationConfig();
        }

        const pathname = this.props?.location?.pathname
        if (pathname == '/documentCenter') {
            this.handleActiveBar();
        }
    }

    handleActiveBar = () =>
    {
        this.setState({
            showDocumentCenterPopOver: true,
            showProductsPopOver: false,
            showadviceToolsPopOver: false,
            showHomePopOver: false,
            showAccountServicesPopOver: false,
            showPortfolioPlannerPdfPopOver:false,
            showFormsPopOver: false,
            msrlookupPopOver: false,
        })
    }


    handleClickOutside = event => {
        const outerOverlay = document.getElementById('outer-overlay-cs');
        const target = event.currentTarget.parentNode.parentNode;
        const {
            history: {
                location: { pathname },
            },
        } = this.props;
        let isHome = true;
        let isAcService = false;
        let isDocumentCenter = false;
        let isForms = false;
        let isPortfolioPlannerPdf = false;
        let isMSRLookup = false;
        if (pathname !== '/accountholder') {
            isHome = false;
        }
        if (pathname === '/accountServices') {
            isAcService = true;
        }
        if (pathname === '/documentCenter') {
            isDocumentCenter = true;
        }
        if (pathname === '/forms') {
            isForms = true;
        }
        if (pathname === '/portfolioPlannerPdf') {
            isPortfolioPlannerPdf = true;
        }
        if (pathname === '/msrlookup') {
            isMSRLookup = true;
        }
        if (target.contains(outerOverlay)) {
            this.setState({
                showProductsPopOver: false,
                showadviceToolsPopOver: false,
                showHomePopOver: isHome,
                showAccountServicesPopOver: isAcService,
                showDocumentCenterPopOver: isDocumentCenter,
                showPortfolioPlannerPdfPopOver: isPortfolioPlannerPdf,
                showFormsPopOver: isForms,
                msrlookupPopOver: isMSRLookup,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { loginState } = this.props;
        if (loginState.getCustomerProfile) {
            const customerData = loginState.getCustomerProfile;
            const { firstName, lastName } = customerData;
            const userName = `${firstName} ${lastName}`;
            localStorage.setItem('userName', userName);
            if (prevState.username !== userName) {
                this.setUserName(userName);
            }
        }
    }

    setUserName = userName => {
        this.setState(state => ({
            ...state,
            username: userName,
        }));
    };

    close = () => {
        this.setState(state => ({
            ...state,
            showSearch: false,
        }));
    };

    // List Items Click Handler and Popup Close on Re-direction Starts

    handleItemClick = e => {
        this.setState(state => ({
            ...state,
            showPopover: false,
            showProductsPopOver: false,
            showadviceToolsPopOver: false,
            msrlookupPopOver: false,
        }));
        this.callAnalytics('Advice & Tools_FAQs');
        setTimeout(() => {
            this.setState(state => ({ ...state, showPopover: true }));
        }, 0);
    };

    callAnalytics = linkName => {
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `Header_${linkName}`,
        });
    };

    handleHeaderIconsClick = linkName => e => {
        e.preventDefault();
        this.callAnalytics(linkName);
        const target = CONSTS.HolderView;
        const { history } = this.props;
        history.push(target);
    };

    handleDropItem = e => {
        const target = e.currentTarget.innerText;
        let targetHit = '';
        switch (target) {
            case CONSTS.PROFILE:
                targetHit = 'basicInformation';
                break;
            case CONSTS.DOCUMENTCENTER:
                targetHit = 'documentcenter';
                break;
            case CONSTS.PREFERENCES:
                targetHit = 'profile';
                break;
            case CONSTS.BANKACCOUNTS:
                targetHit = 'bankaccounts';
                break;
            case CONSTS.MSRLOOKUP:
                targetHit = 'msrlookup';
                break;
            default:
                break;
        }
        this.callAnalytics(target);
        const { history } = this.props;
        history.push(`/${targetHit}`);
    };

    getCarretPosition = el => {
        const elemRect = el.currentTarget.getBoundingClientRect();

        this.setState({ carretPositions: elemRect });
    };
    // List Items Click Handler and Popup Close on Re-direction Ends

    toggleCarret = event => {
        this.setState(
            prevState => {
                return { showCarret: !prevState.showCarret };
            },
            () => {
                console.log(this.state.showCarret);
            },
        );
    };

    togglePopOvers = e => {
        const node = document.getElementById('productsPopOver');
        const node2 = document.getElementById('advicePopOver');
        // const node3 = document.getElementById('accountServices')
        this.callAnalytics(e.target.innerText);
        switch (e.currentTarget.id) {
            case 'home':
                this.setState(
                    ({ showHomePopOver }) => {
                        return {
                            showProductsPopOver: false,
                            showadviceToolsPopOver: false,
                            showAccountServicesPopOver: false,
                            showDocumentCenterPopOver: false,
                            showPortfolioPlannerPdfPopOver:false,
                            showFormsPopOver: false,
                            showHomePopOver: true,
                            msrlookupPopOver: false,
                        };
                    },
                    () => {
                        // console.log(this.state.showProductsPopOver)
                    },
                );
                break;
            case 'products':
                this.setState(
                    ({ showProductsPopOver, showPopover }) => {
                        return {
                            showProductsPopOver: node === undefined ? !showProductsPopOver : true,
                            showadviceToolsPopOver: false,
                            showAccountServicesPopOver: false,
                            showHomePopOver: false,
                            msrlookupPopOver: false,
                        };
                    },
                    () => {
                        // console.log(this.state.showProductsPopOver)
                    },
                );
                break;
            case 'adviceTools':
                this.setState(({ showadviceToolsPopOver }) => {
                    return {
                        showadviceToolsPopOver: node2 === undefined ? !showadviceToolsPopOver : true,
                        showProductsPopOver: false,
                        showAccountServicesPopOver: false,
                        showHomePopOver: false,
                        msrlookupPopOver: false,
                    };
                });
                break;
            case 'accountServices':
                this.setState(({ showAccountServicesPopOver }) => {
                    return {
                        showAccountServicesPopOver: !showAccountServicesPopOver,
                        showProductsPopOver: false,
                        showadviceToolsPopOver: false,
                        showHomePopOver: false,
                        showDocumentCenterPopOver: false,
                        showPortfolioPlannerPdfPopOver:false,
                        showFormsPopOver:false,
                        msrlookupPopOver: false,
                    };
                });
                break;
                case 'documentCenter':
                    this.setState(({ showDocumentCenterPopOver }) => {
                        return {
                            showDocumentCenterPopOver: !showDocumentCenterPopOver,
                            showProductsPopOver: false,
                            showadviceToolsPopOver: false,
                            showHomePopOver: false,
                            showAccountServicesPopOver: false,
                            showPortfolioPlannerPdfPopOver:false,
                            showFormsPopOver: false,
                            msrlookupPopOver: false,
                        };
                    });
                break;
                case 'portfolioPlannerPdf':
                    this.setState(({ showPortfolioPlannerPdfPopOver }) => {
                        return {
                            showPortfolioPlannerPdfPopOver: ! showPortfolioPlannerPdfPopOver,
                            showProductsPopOver: false,
                            showadviceToolsPopOver: false,
                            showHomePopOver: false,
                            showAccountServicesPopOver: false,
                            showFormsPopOver: false,
                            showDocumentCenterPopOver: false,
                            msrlookupPopOver: false,
                        };
                    });
                break;
                case 'accessForms':
                    this.setState(({ showFormsPopOver }) => {
                        return {
                            showFormsPopOver: !showFormsPopOver,
                            showProductsPopOver: false,
                            showadviceToolsPopOver: false,
                            showHomePopOver: false,
                            showAccountServicesPopOver: false,
                            showDocumentCenterPopOver: false,
                            showPortfolioPlannerPdfPopOver:false,
                            msrlookupPopOver: false,
                        };
                    });
                break;
            case 'msrlookup':
                this.setState(({ msrlookupPopOver }) => {
                    return {
                        showAccountServicesPopOver: false,
                        showProductsPopOver: false,
                        showadviceToolsPopOver: false,
                        showHomePopOver: false,
                        msrlookupPopOver: !msrlookupPopOver,
                    };
                });
                break;
            default:
                break;
        }
    };

    handleLogOut = () => {
        this.setState({
            redirect: true,
            logOutRoute: '/',
        });
    };

    render() {
        const {
            redirect,
            isLoaderEnable,
            logOutRoute,
            carretPositions,
            showHomePopOver,
            showDocumentCenterPopOver,
            showPortfolioPlannerPdfPopOver,
            showFormsPopOver,
            showAccountServicesPopOver,
            username = '',
            msrlookupPopOver,
        } = this.state;

        let redirectionResult = '';
        if (redirect) {
            redirectionResult = <Redirect to={logOutRoute} />;
        }

        const token = LocalStorageHelper.getItem('token');
        const decodeToken = jwt_decode(token);
        const customGroupname = decodeToken['custom:groupname'];
        let isAdminDocumentManagerAvailable = false;
        let customGroupArray = customGroupname.split(',');
        if(
            customGroupArray.includes('AdminDocumentManager')
            || customGroupArray.includes('[AdminDocumentManager')
            || customGroupArray.includes('AdminDocumentManager]')
            )
            {
                isAdminDocumentManagerAvailable = true
            }

        return (
            <div>
                <header
                    style={assignObj({
                        backgroundColor: 'rgb(72,109,144)',
                        zIndex: '1051',
                        position: 'relative',
                        width: '100%',
                    })}
                    role="banner"
                    ref={this.wrapperRef}
                >
                    <LoadingOverlay
                        active={isLoaderEnable}
                        spinner
                        text="Signing out..."
                        styles={assignObj({
                            wrapper: {
                                overflow: 'hidden',
                            },
                        })}
                    >
                        {redirectionResult}
                    </LoadingOverlay>

                    <div className="container">
                        <button type="button" aria-hidden="true" className="sr-only" id="hiddenBtn" tabIndex="-1" />
                        <a
                            href="#maincontent"
                            aria-label="skip to main content"
                            id="skipToMain"
                            className="sr-only sr-only-focusable skiptocontent"
                        >
                            {skipText}
                        </a>
                        <Row noGutters className="menu-header">
                            <Col sm={3} md={3} lg={3} xl={3}>
                                <div
                                    className="headerLogo"
                                    onClick={isBackOfficeUser() ? "" : this.handleHeaderIconsClick('VCMLogo')}
                                    tabIndex="0"
                                >
                                    <WIcon src={logo} alt="VCM Logo Home" fontawesomestyle={styles.logo} />
                                </div>
                            </Col>
                            <Col sm={9} md={9} lg={9} xl={9}>
                                <Row
                                    id="upperRow"
                                    className="upperRow"
                                    style={assignObj({ height: '50px', justifyContent: 'flex-end' })}
                                    noGutters
                                    role="navigation"
                                    tabIndex="-1"
                                >
                                    {(isBackOfficeUser() == false) && 
                                    <ul className="header-navlink-main" style={styles.nav} tabIndex="-1">
                                        <li>
                                            <StyledNavDropdown
                                                title={username}
                                                id="user-dropdown"
                                                className="user-dropdown"
                                                onClick={this.getCarretPosition}
                                                role="button"
                                                aria-haspopup="true"
                                                aria-controls="headerUserNameDropdown"
                                            >
                                                <div className="dropdown-menu-items">
                                                    <span
                                                        className="carret-up"
                                                        style={assignObj({
                                                            left:
                                                                carretPositions.width + 120 > 300
                                                                    ? 272
                                                                    : carretPositions.width + 120,
                                                        })}
                                                    />
                                                    <ul
                                                        style={styles.navuser}
                                                        id="headerUserNameDropdown"
                                                        role="menu"
                                                        aria-labelledby="user-dropdown"
                                                    >
                                                        <>
                                                            
                                                            <li role="none">
                                                                <NavDropdown.Item
                                                                    role="menuitem"
                                                                    onClick={this.handleDropItem}
                                                                    className="dropdownItems"
                                                                    tabIndex="-1"
                                                                >
                                                                    {CONSTS.PREFERENCES}
                                                                </NavDropdown.Item>
                                                            </li>
                                                            <li role="none">
                                                                <NavDropdown.Item
                                                                    role="menuitem"
                                                                    onClick={this.handleDropItem}
                                                                    className="dropdownItems"
                                                                    tabIndex="-1"
                                                                >
                                                                    {CONSTS.BANKACCOUNTS}
                                                                </NavDropdown.Item>
                                                            </li>
                                                        </>
                                                    </ul>
                                                </div>
                                            </StyledNavDropdown>
                                        </li>
                                    </ul>
                                    }
                                </Row>
                                <Row
                                    id="lowerRow"
                                    className="lowerRow"
                                    style={assignObj({ height: '50px', justifyContent: 'flex-end', paddingTop: 16 })}
                                    noGutters
                                    role="navigation"
                                    tabIndex="-1"
                                >
                                    <ul
                                        className="header-navlink-main pl-0"
                                        style={styles.nav}
                                        tabIndex="-1"
                                        role="tablist"
                                        aria-orientation="horizontal"
                                    >
                                         {(isBackOfficeUser() == false) && 
                                         <>
                                        <li
                                            id="home"
                                            aria-selected={showHomePopOver}
                                            role="tab"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                        >
                                            <StyledLink to={HolderView}>{CONSTS.HOME}</StyledLink>
                                            {showHomePopOver && <Border />}
                                        </li>

                                        <li
                                            id="accountServices"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                            role="tab"
                                            aria-selected={showAccountServicesPopOver}
                                        >
                                            <StyledLinkEnd to={AccountServicesRoute}>
                                                {CONSTS.AccountServices}{' '}
                                            </StyledLinkEnd>
                                            {showAccountServicesPopOver && <Border />}
                                        </li>
                                        </>
                                        }
                                        {isAdminDocumentManagerAvailable &&
                                        <li
                                            id="portfolioPlannerPdf"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                            role="tab"
                                            aria-selected={showPortfolioPlannerPdfPopOver}
                                        >
                                            <StyledLinkEnd to={PortfolioPlannerPdfRoute}>
                                                {CONSTS.PortfolioPlannerPdf}{' '}
                                            </StyledLinkEnd>
                                            {showPortfolioPlannerPdfPopOver && <Border />}
                                        </li>
                                        }
                                        <li
                                            id="documentCenter"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                            role="tab"
                                            aria-selected={showDocumentCenterPopOver}
                                        >
                                            <StyledLinkEnd to={DocumentCenterRoute}> 
                                                {this.state.isBackOfficeUserEnable ? CONSTS.DOCUMENTCENTER : CONSTS.DOCUMENTMANAGER}
                                                {/* {CONSTS.DOCUMENTCENTER}{' '} */}
                                            </StyledLinkEnd>
                                            {showDocumentCenterPopOver && <Border />}
                                        </li>
                                        
                                        <li
                                            id="accessForms"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                            role="tab"
                                            aria-selected={showFormsPopOver}
                                        >
                                            <StyledLinkEnd to={accessFormsRoute}>
                                                {CONSTS.FORMS}{' '}
                                            </StyledLinkEnd>
                                            {showFormsPopOver && <Border />}
                                        </li>
                                        {/* <li
                                            id="msrlookup"
                                            onClick={this.togglePopOvers}
                                            style={assignObj({ position: 'relative' })}
                                            role="tab"
                                            aria-selected={msrlookupPopOver}
                                        >
                                            <StyledLinkEnd to={MSRLOOKUPRoute}>
                                                {CONSTS.MSRLOOKUP}{' '}
                                            </StyledLinkEnd>
                                            {msrlookupPopOver && <Border />}
                                        </li> */}
                                    </ul>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </header>
            </div>
        );
    }
}

Header.propTypes = {
    history: PropTypes.instanceOf(Object),
    getCustomerProfile: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    clearAppState: PropTypes.func,
};

Header.defaultProps = {
    history: {},
    clearAppState: () => {},
    getCustomerProfile: () => {},
    loginState: {},
};

export default withRouter(Header);
