import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';


export const UsaaId = (props) => {
    const { changed, blurred, clicked, usaaIdState, inputStyle, readonly } = props;

    const data = {
        config: {
            id: "usaaId",
            type: 'text',
            label: 'USAA Member ID'
        },
        change: (event) => changed(event, 'usaaId'),
        blur: () => blurred('usaaId'),
        click: (event) => clicked(event)
    }

    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (usaaIdState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    const errorId = `${data.config.id}_error`;
    const errMsg = readonly ? '' : usaaIdState.errMsg;

    inputElement = <input
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={usaaIdState.value}
        onChange={data.change}
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        aria-required={!readonly}
        aria-describedby={errorId}
        aria-invalid={!!usaaIdState.errMsg}
        readOnly={readonly} />;

    return (
        <div className={classes.Input} style={inputStyle}>
            <label className={classes.Label} htmlFor={data.config.id}>
                {data.config.label}
            </label>
            {inputElement}
            {usaaIdState.errMsg ? <p className={classes.errMsg} aria-live="polite" aria-atomic="true"  id={errorId}>{errMsg}</p> : null}
        </div>
    );
}

UsaaId.propTypes = {
    usaaIdState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            minLength: PropTypes.number,
            maxLength: PropTypes.number,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    inputStyle: PropTypes.instanceOf(Object),
    readonly: PropTypes.bool,
}

UsaaId.defaultProps = {
    usaaIdState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            minLength: 5,
            maxLength: 9,
            isNumeric: true,
            required: false,
        }
    },
    changed: () => { },
    blurred: () => { },
    inputStyle: {},
    readonly: false,
}

export default UsaaId;