import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { WButton } from '../../../common';
import Consts from './Consts';
import './RefreshDialog.css';

const AlertBox = styled.div`
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
    .col {
        flex-grow: 0;
    }
    margin-bottom: 350px;
`;

const AlertWrap = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`;

const AlertHeading = styled(Row)`
    text-align: left;
    font: Bold 18px/22px Benton Sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    line-height: 2;
`;

const Desc = styled(Row)`
    text-align: left;
    font: Regular 16px/20px Benton Sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top: 7px;
    margin-bottom: 28px;
`;

const remindTextStyle = {
    paddingLeft: 30,
    font: 'Norm ExBold 16px/20px Yorkten Slab Norm ExBold',
    letterSpacing: 0,
    color: '#004A98',
    textTransform: 'capitalize',
    opacity: 1,
};

const updateTextStyle = {
    font: 'Norm ExBold 16px/20px Yorkten Slab Norm ExBold',
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
};

const remindButtonStyle = {
    width: 'auto',
    paddingLeft: 10,
    backgroundColor: '#FAFAFA',
};

const updateButtonStyle = {
    marginLeft: 50,
};

const assignObj = obj => {
    return obj;
};

export default class RefreshDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClick = () => {
        // if (value === 'remind') {
        //     // console.log(value);
        // } else if (value === 'update') {
        //   //  console.log(value);
        // }
    };

    render() {
        const { heading, description, remindText, updateText } = Consts;
        return (
            <AlertBox>
                <div className="container">
                    <AlertWrap>
                        <AlertHeading noGutters>{heading}</AlertHeading>
                        <Desc noGutters>{description}</Desc>
                        <Row>
                            <Col sm={8} md={8}>
                                <div style={assignObj({ display: 'flex', flexDirection: 'row' })}>
                                    <WButton
                                        className="remindButton"
                                        buttonstyle={assignObj({ ...remindButtonStyle })}
                                        buttontextstyle={assignObj({ ...remindTextStyle })}
                                        buttontext={remindText}
                                        variant="outline"
                                        onClick={this.handleClick('remind')}
                                    />
                                    <WButton
                                        buttontext={updateText}
                                        buttonstyle={assignObj({ ...updateButtonStyle })}
                                        buttontextstyle={assignObj({ ...updateTextStyle })}
                                        onClick={this.handleClick('update')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </AlertWrap>
                </div>
            </AlertBox>
        );
    }
}

RefreshDialog.propTypes = {};

RefreshDialog.defaultProps = {};
