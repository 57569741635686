import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as PhoneIcon } from '../../../../assets/Icon_call.svg';

const S = {};

S.PhoneNumberText = styled.span`
    top: 281px;
    left: 874px;
    min-width: 168px;
    font-size: 18px;
    font-weight: 800;
    font-family: yorkten-slab-normal;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
    margin-left: 15px;
    margin-bottom: 20px;
    line-height:22px;
`;

S.OpenNowText = styled.div`
    top: 281px;
    left: 874px;
    min-width: 168px;
    font-size: 16px;
    line-height:26px;
    font-weight: 700;
    font-family: benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top:19px;
`;

S.PhoneIcon = styled(PhoneIcon)`
    top: 287px;
    left: 843px;
    width: 16px;
    height: 20px;
    background: transparent url('img/Icon_call.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-bottom: 8px;
`;

S.CurrentWaitTime = styled.span`
    letter-spacing: 0px;
    color: #49494a;
    opacity: 1;
    font-weight: 600;
    font-size: 15px;
`;

S.MinutesText = styled.span`
    letter-spacing: 0px;
    color: #49494a;
    opacity: 1;
    font-size: 15px;
`;

S.CurrentWaitTimeValue = 'Current Phone wait time:';
// S.Minutes = ' XX minutes';

S.opennow = 'Open Now';

// function ContactCenter({ content, waitTimeInfo }) {
function ContactCenter({ content, isAvailable, waitTimeInfo }) {
    const { phoneNumber } = content;
    const tollFreeNumber='8 0 0 2 3 5 8 3 9 6'
    return (
        <div data-test = 'contactcenter'>
            <S.PhoneIcon focusable="false"/>
            <S.PhoneNumberText aria-hidden="true">{phoneNumber}</S.PhoneNumberText>
            <span className="sr-only">{tollFreeNumber}</span>
            {/* <S.OpenNowText>{S.opennow}</S.OpenNowText> */}
            {/* <S.CurrentWaitTime>{S.CurrentWaitTimeValue}</S.CurrentWaitTime> <S.MinutesText>{waitTimeInfo}</S.MinutesText> */}
            {isAvailable &&  waitTimeInfo !== "Unavailable" && (
                <>
                    <S.OpenNowText>{S.opennow}</S.OpenNowText>
                    <S.CurrentWaitTime>{S.CurrentWaitTimeValue}</S.CurrentWaitTime> <S.MinutesText>{waitTimeInfo}</S.MinutesText>
                </>
            )}
        </div>
    );
}


ContactCenter.propTypes = {
    content: PropTypes.instanceOf(Object),
    waitTimeInfo:PropTypes.string,
    isAvailable: PropTypes.bool.isRequired,
};


ContactCenter.defaultProps = {
    content: {},
    waitTimeInfo:'',
};

export default ContactCenter;
