const updateForm = (updatedStateForm, savedData) => {
    let updatedForm = {...updatedStateForm};
    const questions = Object.keys(savedData);
    const fields = Object.keys(updatedForm);
    // console.log(updatedForm);
    // console.log(savedData)
    for (let i = 0; i < questions.length; i += 1) {
        updatedForm = {
            ...updatedForm,
            [fields[Number(i)]]: {
                ...updatedForm[fields[Number(i)]],
                value: savedData[questions[Number(i)]],
            },
            [fields[i + 3]]: {
                ...updatedForm[fields[i + 3]],
                value: questions[Number(i)],
            },
        };
    }
    // console.log(updatedForm)
    return updatedForm;
}

export default updateForm;