import React from 'react';
import PropTypes from 'prop-types';

const guidelinesHeading = 'Format guidelines:';

const Guidelines = ({ state, type }) => {
    let format;
    if (type === 'password'){
        const {upperLower, numeral, symbol, min} = state;
        const minText= 'Be at least 8 characters in length';
        const upperLowerText= 'Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)';
        const numeralText= 'Have at least one numerical character (e.g. 0-9)';
        const symbolText= 'Have at least one special character from this list: ^ $ * . / { } ( ) ? ! @ # % & , > < : ; | _ ~ `';
        format =  [
            { text: minText, valid: min },
            { text: upperLowerText, valid: upperLower },
            { text: numeralText, valid: numeral },
            { text: symbolText, valid: symbol },
        ]
    }else {
        const { min, upperLower, symbol } = state;
        const minText = 'Must be at least 5 characters in length';
        const upperLowerText = 'May contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z) and numerical characters (e.g. 0-9)';
        const symbolText = 'Must not contain special characters (e.g. ~!@#$%^&()_-+=) or spaces';
        format = [
            { text: minText, valid: min },
            { text: upperLowerText, valid: upperLower },
            { text: symbolText, valid: symbol }
        ]
    }
    return(
        <div className="rulesWrapper">
            <span className="rulesHeading">{guidelinesHeading}</span>
            <ul className="rules">
                {format.map(({ text, valid }, index) => {
                const SRtext = text.replace("A-Z", "A to Z").replace("a-z", "a to z").replace("0-9", "0 to 9").replace("e.g.", "example").replace("e.g.", "example");
                return (
                    <li className="displayFlex" key={Number(index)}>
                        <div className="ruleListIcon">
                            <div
                                className={valid ? 'filledCircle' : 'circle'}
                                aria-live="polite"
                            >
                                <span className="sr-only" aria-hidden={!valid}>Satisfied</span>   
                                {/* <input className="sr-only" readOnly value={valid} aria-label={SRtext} aria-live="polite"/> */}
                                <div className="tick" />
                            </div>
                        </div>
                        <div className="ruleListText"><span aria-hidden>{text}</span><span className="sr-only">{SRtext}</span></div>
                    </li>
                )})}
            </ul>
            <span className="sr-only" aria-hidden={!format}>All condition Satisfied</span>
        </div>
    )
}


Guidelines.propTypes = {
    state: PropTypes.instanceOf(Object),
    type: PropTypes.string
}

Guidelines.defaultProps = {
    state: {},
    type: ''
}

export default Guidelines;