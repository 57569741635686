import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    transCompositeData: '',
    isError: false,
    isSuccess: true,
};

function TransactionCompositeDataReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_TRANSACTION_COMPOSITE_DATA_SUCCESS: {
            return {
                ...state,
                ...action,
                isError: false,
                isSuccess: true,
                isLoading:false
            };
        }

        case ActionTypes.GET_TRANSACTION_COMPOSITE_DATA_FAILURE: {
            return {
                ...state,
                transCompositeData: '',
                isError: true,
                isSuccess: false,
                error: action.payload,
            };
        }

        default:
            return state;
    }
}

export default TransactionCompositeDataReducer;
