import  { RECURRING_TRANSFER_PLAN } from 'modules/AccountOptions/constant';
import { formatCurrency, inputCheck } from 'utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { WSwitch, Accordian, AccordianTitle, FeeGatesWarningModal } from 'common';
import './investwithdrawal.css';
import {currencyFormatterFunction, currencyFormatterWithoutDollarSign, removeComma} from 'common/Formatter/CurrencyFormatter';

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const AmountDetailsSection = styled.div`
    display: flex;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    opacity: 1;
    flex-wrap: wrap;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    padding: 1.375rem 2.5625rem 1.5rem 2.5rem;
    @media (max-width: 1200px) {
        padding: 1.375rem 1rem 1.5rem 1rem;
    }
`;

const TitleDiv = styled.label`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;
const UnitSpan = styled.label`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    width: 1.25rem;
    && {
        margin-bottom: 0;
    }
`;

export const AmtInput = styled.input`
    background: ${props => (props.disabled ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: ${props => (props?.error ? '1px solid red' : '1px solid #D2D2D2')};
    opacity: 1;
    height: 3.125rem;
    padding: 0.625rem;
    &&:hover {
        border-color: ${props => (props.disabled ? '#D2D2D2' : 'rgba(72, 109, 144, 1)')};
    }
    && {
        color: ${props => (props.negative ? 'red' : 'rgba(73, 73, 74, 1)')};
    }
`;
const InputWrapper = styled.div`
    max-width: 12.5rem;
    min-width: 2rem;
    margin-left: 0.625rem;
    @media (max-width: 767px) {
        width: 100%;
        float: left;
        width: 50%;
        max-width: none;

    }

`;
export const AmtTopsection = styled(FlexColumnDiv)`
    margin-left: ${props => (props.margin ? '3.75rem' : 0)};
    @media (max-width: 1200px) {
        margin-left: 1rem;
    }
`;


const SharesContainer = styled.div`
    padding: 2.5rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
`;

const ShareTitleSection = styled(FlexRowDiv)`
    align-items: center;
    padding: 1.25rem 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    opacity: 1;
`;

export const ShareBodySection = styled(FlexRowDiv)`
    align-items: center;
    padding: 1.625rem 2.5rem;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    display: flex;
    justify-content: space-between;
`;

const ShareTitle = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    min-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;
const SharebodyText = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const ShareBodyAmtText = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    margin-left: 0.625rem;
    ${({ alignRight }) => alignRight && `
        right: 0;
        padding-right: 2.5rem;
    `}
`;
const ShareBodyUnit = styled.span`
    font-weight: 500;
    margin-left: 1.875rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    width: 1.25rem;
    && {
        margin-bottom: 0;
    }
`;
const ShareBodyLeftAlign = styled.div`
    display:inline-flex;
    align-items: baseline;
`;
export const IndividualShare = styled.div`
    margin-bottom: 1.875rem;
    opacity: 1;
    position: relative;
    outline: ${props => (props.selected ? '2px solid #004A98' : '1px solid #D2D2D2')};
    background: ${props => (props.selected ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    border-radius: 0;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 20px solid transparent;
        border-left: ${props => (props.selected ? '20px solid #004A98' : '20px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
    }
`;
const ErrMsg = styled.small`
    position: absolute;
    display: block;
    margin-top: 0.5rem;
    color: red;
    font-size:12px;
    @media (max-width: 767px) {
        position: static;
    }
`;
const InputRow = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

const TotalAmt = styled.div`
    display: flex;
`;

const TotalAmtLabel = styled.div`
    text-align: left;
    font-size: 16px;
    font-weight: 800;
    font-family: benton-sans;
    letter-spacing: 0px;
    color: rgb(73, 73, 74);
    opacity: 1;
    margin-right: 1rem;
`;

const TotalAmtValue = styled.div`
    text-align: left;
    font-size: 16px;
    font-family: benton-sans;
    letter-spacing: 0px;
    color: rgb(73, 73, 74);
    opacity: 1;
`;

const TotalAmtErr = styled.div`
    color: #E60000;
`;

const unUtilizedPercentage = 'Percentage Unutilized should be zero';
const unUtilizedAmount = 'Amount Unutilized should be zero';

export const InputWithTitle = props => {
    const { title, labelTxt, value, onChange, disabled, errorMsg, onBlur, margin, onKeyDown, negative, id } = props;
    return (
        <AmtTopsection margin={margin}>
            <TitleDiv htmlFor={id} >{title}</TitleDiv>
            <InputRow>
                <UnitSpan htmlFor={id} >{labelTxt}</UnitSpan>
                <InputWrapper>
                    <AmtInput
                        onBlur={onBlur}
                        error={!!errorMsg}
                        disabled={disabled}
                        type="text"
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        negative={negative}
                        aria-required="true"
                        id={id}
                        name={id}
                        aria-invalid={!!errorMsg}
                    />
                    {errorMsg && <ErrMsg aria-live="assertive" aria-atomic="true">{errorMsg}</ErrMsg>}
                </InputWrapper>
            </InputRow>
        </AmtTopsection>
    );
};
InputWithTitle.propTypes = {
    title: PropTypes.string,
    labelTxt: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    onBlur: PropTypes.func,
    margin: PropTypes.bool,
    onKeyDown: PropTypes.func,
    negative: PropTypes.bool,
    id: PropTypes.string,
};

InputWithTitle.defaultProps = {
    title: 'Title',
    labelTxt: 'Label',
    value: 0,
    onChange: () => { },
    disabled: false,
    errorMsg: '',
    onBlur: () => { },
    margin: false,
    onKeyDown: () => { },
    negative: false,
    id: 'calculationSecInputBox'
};

const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    min-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

const ShortFundName = styled.div`
    display: flex;
    padding: 0.5rem;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
    & abbr {
        text-decoration: none !important;
    }
`;

export const ShareInputCard = (props) => {
    const {
        selected,
        fundName,
        shortFundName,
        index,
        onSwitchClick,
        disableShare,
        amtText,
        amountIn,
        percent,
        amt,
        onShareAmtChange,
        inputRef,
        onInputBlur,
        errorMsg,
        minAmt,
        calculatedFundValue,
    } = props;
    const inputId = `ShareInput${index}`;
    return (
        <IndividualShare selected={selected} className="investToWrap">
            <ShareTitleSection  className="investToTickerWrap">
                <ShareNameDiv>
                    <ShortFundName tabIndex="0">
                        <span className="sr-only">Fund code:{shortFundName.split('').join(' ')}</span>
                        <span aria-hidden="true">
                            <abbr title={fundName}>{shortFundName}</abbr>
                        </span>
                    </ShortFundName>
                    <ShareTitle>{fundName}</ShareTitle>
                </ShareNameDiv>
                <WSwitch
                    id={`${fundName.replace(/\s/g, "_")}_${index}`}
                    onSwitchClick={onSwitchClick}
                    disabled={disableShare}
                    checked={selected}
                    name={`${fundName.replace(/\s/g, "")}_Switch`}
                    role="checkbox"
                    tabIndex="0"
                    arialabel={fundName}
                    
                />
            </ShareTitleSection>
            <ShareBodySection disabled={!selected} aria-hidden={(!selected)?'true':'false'}>
                <ShareBodyLeftAlign className="investToAmountWrap">
                    <label id={`amount_${index}`} htmlFor={inputId}>
                        <SharebodyText>{amtText}</SharebodyText>
                        <ShareBodyUnit>{amountIn}</ShareBodyUnit>
                    </label>
                    <InputWrapper>
                        <AmtInput
                            value={amountIn === '%' ? percent || '' : amt || ''}
                            disabled={!selected}
                            type="text"
                            onChange={onShareAmtChange}
                            ref={inputRef}
                            onBlur={onInputBlur}
                            error={!!errorMsg}
                            aria-required
                            id={inputId}
                            name={inputId}
                            aria-invalid={!!errorMsg}
                            aria-describedby={inputId + '_errormap '+ 'min-amount_' + index}
                        />
                        {errorMsg && (
                            <ErrMsg
                                aria-atomic
                                aria-live="assertive"
                                className="analytics-error-field">
                                <span className="sr-only">Error: {errorMsg}</span>
                                <span aria-hidden="true" id={inputId + '_errormap'}>{errorMsg}</span>
                            </ErrMsg>
                        )}
                    </InputWrapper>
                    <ShareBodyAmtText className="minAmountToInvest" id={`min-amount_${index}`}>{minAmt}</ShareBodyAmtText>
                </ShareBodyLeftAlign>
                <ShareBodyAmtText alignRight><span>Balance</span> <span className="balanceAmountInAccount">{formatCurrency(calculatedFundValue)}</span></ShareBodyAmtText>
            </ShareBodySection>
        </IndividualShare>
    );
}

ShareInputCard.propTypes = {
    selected: PropTypes.bool,
    fundName: PropTypes.string,
    index: PropTypes.number,
    onSwitchClick: PropTypes.func,
    disableShare: PropTypes.bool,
    arialabel: PropTypes.string,
    amtText: PropTypes.string,
    amountIn: PropTypes.string,
    percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onShareAmtChange: PropTypes.func,
    inputRef: PropTypes.instanceOf(Object),
    onInputBlur: PropTypes.func,
    errorMsg: PropTypes.string,
    minAmt: PropTypes.string,
    calculatedFundValue: PropTypes.string,
    shortFundName: PropTypes.string,
};

ShareInputCard.defaultProps = {
    selected: false,
    fundName: '',
    index: 0,
    onSwitchClick: () => { },
    disableShare: false,
    arialabel: '',
    amtText: '',
    amountIn: '',
    percent: '',
    amt: '',
    onShareAmtChange: () => { },
    inputRef: null,
    onInputBlur: () => { },
    errorMsg: '',
    minAmt: '',
    calculatedFundValue: '',
    shortFundName: '',
};

class InvestToComponent extends Component {
    static checkNextStatus(data, errorObj) {
        let isError = data.some(item => item.errorMsg);
        isError = isError || !!errorObj.totalErrMsg; // || !!errorObj.remainingErrMsg;
        return !data.some(item => item.amt) || isError;
    }

    constructor(props) {
        super(props);
        const { pageIndex } = props;
        this.state = {
            totalAmt: '',
            amtConsumed: 0,
            amtRemaining: '',
            shares: [],
            retrivedState: false,
            disableNext: !props.isEditMode,
            displayDetails: {
                investTo: {
                    titleTxt: props.investTo.titleTxt || 'Invest To',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [],
                },
            },
            amountIn: '$',
            totalErrMsg: '',
            remainingErrMsg: '',
            isShareErr: false,
            errIndex: null,
            selectedSharesData: [],
            showWarningDialog: false,
            currentItem: {},
        };
        this.inputRefs = [];
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, shares } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }
        if (prevState.shares !== shares) {

            let amtConsumed = 0;
            shares.forEach(item => {
                amtConsumed += parseFloat(removeComma(item.amt)) ? parseFloat(removeComma(item.amt)) * 100 : 0;
            });
            amtConsumed /= 100

            return {
                shares,
                amtConsumed: amtConsumed.toFixed(2),
                totalSharesAmt: amtConsumed.toFixed(2)
            };
        }
        return null;
    }

    componentDidMount() {
        this.updateRefs();
        const index = 0;
        const { shares, fundPlanData, isEditMode,fundSavedState,editFlow} = this.props
        if(isEditMode){
            const { planInfo = {} } = fundPlanData;
            const showWarningDialog = planInfo?.fundRule?.redemptionNoticeFlag || planInfo?.fundRule?.liquidityNoticeFlag;
           
            let currentItem =  shares[index] || {};
           
            if( showWarningDialog ) {
                currentItem.selected = false;
                currentItem['amt'] = currencyFormatterWithoutDollarSign(currentItem.amt)
                shares[index] = currentItem;
                this.setState({ showWarningDialog, currentItem, index, shares});
            } else {
                currentItem['amt'] = currencyFormatterWithoutDollarSign(currentItem.amt)
                this.setState({ showWarningDialog, currentItem, index, shares});
                this.calculteValue();
            }
        }

        if(editFlow == 'editFlow' && fundSavedState.selectedFunds != undefined && fundSavedState.selectedFunds.length == 1)
        {
            this.setState({amtConsumed : fundSavedState.selectedFunds[0].amt})
        }
        
        this.getNewShareDetails(shares);
    }

    componentDidUpdate(prevProps, prevState) {
        const { ammountChanged, shares } = this.props;
        const { amtConsumed } = this.state

        this.updateRefs(prevProps.shares !== shares);

        if (prevState.amtConsumed !== amtConsumed) {
            ammountChanged(amtConsumed);
        }
    }


    // Reset if Total amount is given less than share amt
    resetIfTotalIsLess = (amtvalue, amtConsumed, shares, amountIn) => {
        const newShares = this.resetShares(shares);
        return {
            shares: newShares,
            amtConsumed: amtvalue ? '0.00' : '',
            amtRemaining: amountIn === '%' ? '100.00' : amtvalue.toFixed(2),
            totalPercent: 0,
            errIndex: null,
            remainingErrMsg: '',
            isShareErr: false,
            disableNext: true,
        }
    }

    // calculating remaining value
    getRemainingAmt = (amountIn, amtConsumed, amtvalue) => {
        return amountIn === '%'
            ? (10000 - +amtConsumed * 100) / 100
            : (amtvalue * 100 - +amtConsumed * 100) / 100;
    }

    // Formatting remaining value
    formatRemainingAmt = (newRemainingAmt) => {
        return newRemainingAmt < 0 ? `(${Math.abs(newRemainingAmt).toFixed(2)})` : newRemainingAmt.toFixed(2);
    }

    // To Show Error below remaining amount
    getRemainingAmtError = (amountIn, newRemainingAmt, amtvalue, errIndex) => {
        let currRemErr = '';
        const isRemaingSame = amountIn === '%' ? newRemainingAmt === 100 : newRemainingAmt === amtvalue;
        if (!isRemaingSame && errIndex === null && newRemainingAmt) {
            currRemErr =
                amountIn === '%'
                    ? unUtilizedPercentage
                    : unUtilizedAmount;
        }
        return currRemErr;
    }

    resetShares = (shares = []) => {
        return shares.map(item => {
            const newItem = { ...item };
            newItem.amt = '';
            newItem.percent = '';
            newItem.errorMsg = '';
            newItem.selected = false;
            newItem.isEmptyErr = false;
            return newItem;
        });
    }

    diableOrEnableFlagForNextButton = (disableNext, nextButtonDisableFlagParams) => {
        let nextButtonDisableFlag = nextButtonDisableFlagParams

        if(!disableNext && !nextButtonDisableFlag){
            nextButtonDisableFlag = false
        }
        else{
            nextButtonDisableFlag =  true;
        }

        return nextButtonDisableFlag
    }

    onSwitchClick = index => e => {
        const { plan, getSelectedShares } = this.props;
        e.stopPropagation();
        const { shares, fundPlanData, isEditMode } = this.props;
        const { planInfo = {} } = fundPlanData;
        
        const currentItem =  shares[index];
        const FeeGate = !isEditMode ? currentItem : planInfo;
        const showWarningDialog = FeeGate?.fundRule?.redemptionNoticeFlag || FeeGate?.fundRule?.liquidityNoticeFlag;
        if (currentItem.selected) {
            shares[index] = currentItem;
            getSelectedShares(shares)
            let isAlreadySelected;
            this.setState(
                prevState => {
                    const { shares, totalErrMsg, displayDetails, amountIn } = prevState;
                    let { totalAmt } = prevState;
                    totalAmt = +totalAmt;
                    let { remainingErrMsg, isShareErr, errIndex } = prevState;
                    const { investTo } = displayDetails;
                    const currItem = shares[index];
                    isAlreadySelected = currItem?.selected;
                    currentItem.selected = true;
                    if (isAlreadySelected) {
                        if (currentItem.errorMsg) {
                            isShareErr = false;
                        }
                        currentItem.amt = '';
                        currentItem.percent = '';
                        currentItem.errorMsg = '';
                        currentItem.isEmptyErr = false;
                        currentItem.selected = false;

                        
                        const {
                            newShares,
                            totalPercent,
                            totalSharesAmt,
                            isEmptyErr,
                            displayData,
                            selectedSharesData
                        } = this.getNewShareDetails(shares);
                        investTo.data = displayData;
                        const amtConsumed = this.getConsumedValue(amountIn, totalPercent, totalSharesAmt);
                        const amtRemaining = plan === RECURRING_TRANSFER_PLAN.AIP ? 0 : this.getRemainingAmt(amountIn, amtConsumed, totalAmt);
                        newShares[index]=currentItem;
                        if (errIndex !== null && errIndex !== index) {
                            const errObj = this.getErrorMsg({
                                currentShareAmt: +newShares[+errIndex].amt,
                                currentSharePercent: +newShares[+errIndex].percent,
                                index: errIndex,
                                amtRemaining,
                                totalPercent,
                                shares: newShares,
                                totalAmt,
                            });
                            newShares[+errIndex].errorMsg = errObj.totalErrMsg;
                            newShares[index] = currentItem;
                            isShareErr = !!errObj.totalErrMsg;
                        } else errIndex = null;
    
                        remainingErrMsg = this.getRemainingAmtError(amountIn, amtRemaining, totalAmt, errIndex);
                        let nextButtonDisableFlag = this.validateFundForNextButton();
                        const disableNext = this.constructor.checkNextStatus(newShares, { totalErrMsg, remainingErrMsg });

                        nextButtonDisableFlag = this.diableOrEnableFlagForNextButton(disableNext, nextButtonDisableFlag)

                        getSelectedShares(newShares)
                        return {
                            shares: newShares,
                            amtConsumed: amtConsumed.toFixed(2),
                            amtRemaining: amtRemaining.toFixed(2),
                            disableNext :nextButtonDisableFlag,                            
                            remainingErrMsg,
                            totalPercent,
                            isShareErr,
                            errIndex,
                            isEmptyErr,
                            selectedSharesData,
                            showWarningDialog: false,
                            currentItem, 
                            index,
                        };
                    }
                    let nextButtonDisableFlag2 = this.validateFundForNextButton();
                    const disableNextValue = this.constructor.checkNextStatus(shares, { totalErrMsg, remainingErrMsg });
                    if(disableNextValue == false && nextButtonDisableFlag2 == false)
                    {
                        nextButtonDisableFlag2 = false
                    }
                    else
                    {
                        nextButtonDisableFlag2 =  true;
                    }

                    getSelectedShares(shares)
                    return {
                        shares,
                        disableNext: nextButtonDisableFlag2,
                        showWarningDialog: false,
                    };
                }, this.onSwitchClickCallback(isAlreadySelected, index));

                const {
                    displayData,
                } = this.getNewShareDetails(shares);

               
                this.state.displayDetails.investTo.data = displayData;
                this.setState({ currentItem, index, shares});
        } else if( showWarningDialog) {
                this.setState({ showWarningDialog, currentItem, index});
        } else {
            currentItem.selected = !currentItem.selected;
            shares[index] = currentItem;
            getSelectedShares(shares)
            this.setState({selected: true, showWarningDialog, currentItem, index, shares,disableNext : true},()=>{
                const { checkStatus } = this.props;
                checkStatus();
            });
        }

    }

    onYesFeeClick = () => {
        this.calculteValue();
    }

    // onSwitchClick = index => e => {
    calculteValue = () => {
        const { index } = this.state;
        const { plan, getSelectedShares } = this.props;
        let isAlreadySelected;
        this.setState(
            prevState => {
                const { shares, totalErrMsg, displayDetails, amountIn, currentItem } = prevState;
                let { totalAmt } = prevState;
                totalAmt = +totalAmt;
                let { remainingErrMsg, isShareErr, errIndex } = prevState;
                const { investTo } = displayDetails;
                const currItem = shares[index];
                isAlreadySelected = currItem?.selected;
                currentItem.selected = true;
                if (isAlreadySelected) {
                    if (currentItem.errorMsg) {
                        isShareErr = false;
                    }
                    currentItem.amt = '';
                    currentItem.percent = '';
                    currentItem.errorMsg = '';
                    currentItem.isEmptyErr = false;
                    const {
                        newShares,
                        totalPercent,
                        totalSharesAmt,
                        isEmptyErr,
                        displayData,
                        selectedSharesData
                    } = this.getNewShareDetails(shares);
                    investTo.data = displayData;
                    const amtConsumed = this.getConsumedValue(amountIn, totalPercent, totalSharesAmt);
                    const amtRemaining = plan === RECURRING_TRANSFER_PLAN.AIP ? 0 : this.getRemainingAmt(amountIn, amtConsumed, totalAmt);
					newShares[index]=currentItem;
                    if (errIndex !== null && errIndex !== index) {
                        const errObj = this.getErrorMsg({
                            currentShareAmt: +newShares[+errIndex].amt,
                            currentSharePercent: +newShares[+errIndex].percent,
                            index: errIndex,
                            amtRemaining,
                            totalPercent,
                            shares: newShares,
                            totalAmt,
                        });
                        newShares[+errIndex].errorMsg = errObj.totalErrMsg;
                        newShares[index] = currentItem;
                        isShareErr = !!errObj.totalErrMsg;
                    } else errIndex = null;

                    remainingErrMsg = this.getRemainingAmtError(amountIn, amtRemaining, totalAmt, errIndex);

                    const disableNext = this.constructor.checkNextStatus(newShares, { totalErrMsg, remainingErrMsg });
                    getSelectedShares(newShares)
                    return {
                        shares: newShares,
                        amtConsumed: amtConsumed.toFixed(2),
                        amtRemaining: amtRemaining.toFixed(2),
                        disableNext,
                        remainingErrMsg,
                        totalPercent,
                        isShareErr,
                        errIndex,
                        isEmptyErr,
                        selectedSharesData,
                        showWarningDialog: false,
                    };
                }
                const disableNextValue = this.constructor.checkNextStatus(shares, { totalErrMsg, remainingErrMsg });
                getSelectedShares(shares)
                return {
                    shares,
                    disableNext: disableNextValue,
                    showWarningDialog: false,
                };
            }, this.onSwitchClickCallback(isAlreadySelected, index));
    };

    // Callback method for onswitchclick
    onSwitchClickCallback = (isAlreadySelected, index) => () => {
        const { shares } = this.state;
        let { amtRemaining } = this.state;
        amtRemaining = +amtRemaining;
        if (isAlreadySelected && amtRemaining < 0) {
            const selectedIndex = shares.findIndex(item => {
                return item.selected;
            });
            this.inputRefs[+selectedIndex].current.blur();
        }
        const { checkStatus } = this.props;
        checkStatus();
    }

    // To get new details
    getNewShareDetails = (shares) => {
        let totalPercent = 0;
        const { plan } = this.props;
        let totalSharesAmt = 0;
        const displayData = [];
        const selectedSharesData = [];
        let temp = shares;
        const newShares = temp.map((el ,index)=> {
            
            const newItem = { ...el };
           
            if (shares[index].selected == true && shares[index].amt) {
                selectedSharesData.push({
                    fundNumber: newItem.fundNumber,
                    fundAccountNumber: newItem.fundAccountNumber,
                    amountValue: parseFloat(removeComma(newItem.amt)),
                    dollarAmount: parseFloat(removeComma(newItem.amt)),
                    achFlag: newItem.achFlag,
                    stateTax: newItem.stateTax,
                    fedTax: newItem.fedTax,

                });
                displayData.push(
                    {
                        field: 'Fund Name',
                        value: newItem.fundName,
                        formattedValue: <ShareNameDiv>
                            <ShortFundName><span className="sr-only">Fund code: </span>{newItem.shortFundName}</ShortFundName>
                            <ShareTitle>{newItem.fundName}</ShareTitle>
                        </ShareNameDiv>,
                        fundNumber: newItem.fundNumber,
                        fundAccountNumber: newItem.fundAccountNumber
                    },
                    {
                        field: plan === RECURRING_TRANSFER_PLAN.AIP ? 'Amount Invested' : 'Total Amount',
                        formattedValue: `$ ${newItem.amt}`,
                        value: parseFloat(removeComma(newItem.amt)),
                    },
                );
            }

            totalSharesAmt +=  + parseFloat(removeComma(newItem.amt)) ? parseFloat(removeComma(newItem.amt)) * 100 : 0;
            totalPercent += +newItem.percent ? +newItem.percent * 100 : 0;
            return newItem;
        });
        totalPercent /= 100;
        totalSharesAmt /= 100;

        this.state.displayDetails.investTo.data = displayData;
        return {
            newShares,
            totalPercent,
            totalSharesAmt,
            displayData,
            selectedSharesData
        }
    }

    // Method to update fund amount or percentage
    onShareAmtChange = index => e => {
        const { amountIn } = this.state;
        const amountValue = removeComma(e.target.value)
        const enteredValue =  inputCheck(amountValue);
        if (enteredValue === null || (amountIn === '%' && enteredValue > 100)) return;

        this.setState(prevState => {
            const { shares } = prevState;
            let { totalAmt } = prevState;
            totalAmt = +totalAmt;
            shares[+index].amt = amountIn === '%' ? (totalAmt * enteredValue) / 100 : enteredValue;
            shares[+index].percent = amountIn === '%' ? enteredValue : '';
            return {
                shares
            };
        });
    };

    getComponentState = () => {
        const { state } = this;
        return { ...state };
    }

    updateRefs = (forceUpdate = false) => {
        const { shares } = this.state;
        if ((!this.inputRefs.length && shares.length) || forceUpdate) {
            this.inputRefs = shares.map(() => React.createRef());
        }
    };

    determineErrorMessage = (min, rawValue, max) => {
        let errorMessage = ''
        const { plan } = this.props;
        if (min > rawValue) errorMessage = `Please enter an amount above the ${formatCurrency(min)} minimum.`;
        if (max < rawValue) errorMessage = `The amount exceeds the allowable limit for a ${plan === RECURRING_TRANSFER_PLAN.SWP ? 'fund to bank' : 'bank to fund'} transfer.`;

        return errorMessage;
    }

    // Method to display error if share amt entered is invalid
    onInvalidShareAmt = (rawValue, index, min, max) => {
        this.setState(
            prevState => {
                const { shares, remainingErrMsg, amountIn, totalAmt } = prevState;
                const currShare = shares[+index];
                let totalErrMsg = '';
                if (rawValue) {
                    totalErrMsg = amountIn === '%' ? 'Please enter a valid percentage' : this.determineErrorMessage(min, rawValue, max);
                } else {
                    totalErrMsg = amountIn === '%' ? 'Please enter the percentage' : 'Please enter the amount';
                }
                // Set error message to the corresponding fund
                currShare.errorMsg = totalErrMsg;
                currShare.isEmptyErr = true;

                if (totalErrMsg) {
                    const { analyzeFormFieldError } = this.props;
                    analyzeFormFieldError()
                }

                // check next button status
                const disableNext = this.constructor.checkNextStatus(shares, {
                    totalErrMsg,
                    remainingErrMsg,
                });
                let totalPercent = 0;
                let totalSharesAmt = 0;
                shares.forEach(el => {
                    totalSharesAmt += parseFloat(removeComma(el.amt))  ? parseFloat(removeComma(el.amt)) * 100 : 0;
                    totalPercent += el.percent ? +el.percent * 100 : 0;
                });
                totalPercent /= 100;
                totalSharesAmt /= 100;

                let currentShareObj = shares;
                currentShareObj[index]['amt'] = currencyFormatterWithoutDollarSign(`${currentShareObj[index].amt}`)
                // Set amount consumed
                const amtConsumed = this.getConsumedValue(amountIn, totalPercent, totalSharesAmt);

                // calculating remaining value
                const newRemainingAmt = this.getRemainingAmt(amountIn, amtConsumed, totalAmt);
                return {
                    shares:currentShareObj,
                    disableNext,
                    isShareErr: !!totalErrMsg,
                    // errIndex: index,
                    isEmptyErr: true,
                    remainingErrMsg: '',
                    amtConsumed: amtConsumed.toFixed(2),
                    amtRemaining: newRemainingAmt.toFixed(2),
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    }

    validateFundForNextButton = ()=>
    {
        let nextButtonDisableFlag = false;
        let selectedFundListData = this.state.shares.filter(item=> item.selected == true);
        for(const element of selectedFundListData)
        {
            if(element.amt == '' || element.amt == null)
            {
                nextButtonDisableFlag  = true;
                break;
            }
        }
        return nextButtonDisableFlag;
    }

    // Method to process the entered fund amount or percentage
    onInputBlur = (index, min, max) => (e) => {
        const rawValue = e.target.value || '';
        const enteredNumber = +e.target.value;
        // If not valid display error
        if (!enteredNumber || min > enteredNumber || max < enteredNumber) {
            this.onInvalidShareAmt(rawValue, index, min, max);
        } else {

            let nextButtonDisableFlag = this.validateFundForNextButton();

            this.setState(
                prevState => {
                    const { displayDetails, shares, amountIn, errIndex: prevErrIndex } = prevState;
                    let { totalAmt } = prevState;
                    totalAmt = parseFloat(removeComma( +totalAmt));
                    const { investTo } = displayDetails;
                    // Getting amount and percentage value of the fund
                    const currentShareAmt = +shares[+index].amt;
                    const currentSharePercent = +shares[+index].percent;

                    // Update the input to number
                    shares[+index].amt = currencyFormatterWithoutDollarSign(currentShareAmt);
                    shares[+index].percent = currentSharePercent.toFixed(2);
                    shares[+index].isEmptyErr = false;
                    shares[+index].errorMsg = '';
                    const {
                        newShares,
                        totalPercent,
                        totalSharesAmt,
                        isEmptyErr,
                        displayData,
                        selectedSharesData
                    } = this.getNewShareDetails(shares);

                    investTo.data = displayData;

                    // Set amount consumed
                    const amtConsumed = this.getConsumedValue(amountIn, totalPercent, totalSharesAmt);

                    // calculating remaining value
                    const newRemainingAmt = this.getRemainingAmt(amountIn, amtConsumed, totalAmt);

                    // Remove error shown below Fund
                    if (prevErrIndex !== null) {
                        newShares[+prevErrIndex].errorMsg = '';
                    }

                    // Formatting remaining value
                    const remAmt = this.formatRemainingAmt(newRemainingAmt);

                    // Get error message
                    const errObj = this.getErrorMsg({
                        currentShareAmt,
                        currentSharePercent,
                        index,
                        amtRemaining: 0,
                        totalPercent,
                        shares: newShares,
                        totalAmt,
                    });

                    // Set error message
                    newShares[+index].errorMsg = errObj.totalErrMsg;

                    const disableNext = this.constructor.checkNextStatus(newShares, errObj);
            
                    if(disableNext == false && nextButtonDisableFlag == false)
                    {
                        nextButtonDisableFlag = false
                    }
                    else
                    {
                        nextButtonDisableFlag =  true;
                    }
                    const isShareErr = !!errObj.totalErrMsg || isEmptyErr;

                    return {
                        shares: newShares,
                        amtConsumed: currencyFormatterWithoutDollarSign(amtConsumed),
                        amtRemaining: remAmt,
                        displayDetails,
                        totalPercent,
                        disableNext :nextButtonDisableFlag,
                        remainingErrMsg: isShareErr ? '' : errObj.remainingErrMsg,
                        isShareErr,
                        errIndex: errObj.totalErrMsg ? index : null,
                        isEmptyErr,
                        selectedSharesData
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        }
    };

    // Method to get consumed value
    getConsumedValue = (amountIn, totalPercent, totalSharesAmt) => {
        return amountIn === '%' ? totalPercent : totalSharesAmt;
    }

    // Method to return Error on user entered values
    getErrorMsg = params => {
        const { currentShareAmt, currentSharePercent, index, amtRemaining, shares, totalAmt } = params;
        const { amountIn } = this.state;
        const currShare = shares[+index];
        let totalAmtValue = shares.map(el => +el.amt * 100 || 0).reduce((accVal, curVal) => accVal + curVal);
        totalAmtValue /= 100;
        const monthlyInvestment = +currShare.monthlyInvestment;
        const minPercentage = Math.ceil((monthlyInvestment / totalAmt) * 100);
        let currErrorMsg = '';
        if (amountIn === '%' && currentSharePercent > 100) {
            currErrorMsg = `Please enter a percentage maximum of 100`;
        } else if (currentShareAmt < monthlyInvestment) {
            currErrorMsg =
                amountIn === '%'
                    ? `Please enter a minimum percentage of ${minPercentage.toFixed(2)}%`
                    : `Please enter a minimum amount of $ ${monthlyInvestment.toFixed(2)}`;
        }


        let currRemErr = '';
        if (amtRemaining !== 0) {
            currRemErr = amountIn === '%' ? unUtilizedPercentage : unUtilizedAmount;
        }

        return { totalErrMsg: currErrorMsg, remainingErrMsg: currRemErr };
    };

    // Method to get error message if share amount is greater then total amount
    getErrMsgForMoreShareAmt = (params) => {
        const {
            amountIn,
            totalAmt,
            totalAmtValue,
            currentShareAmt,
            totalPercent,
            currentSharePercent,
            monthlyInvestment,
            minPercentage
        } = params;
        let currErrorMsg = '';
        const leftAmt = ((totalAmt * 100) - ((totalAmtValue * 100) - (currentShareAmt * 100))) / 100;
        const leftPercent = ((10000) - ((totalPercent * 100) - (currentSharePercent * 100))) / 100;
        if (leftAmt > 0) {
            if (leftAmt < monthlyInvestment) {
                currErrorMsg =
                    amountIn === '%'
                        ? `Minimum percentage to be entered is ${minPercentage.toFixed(2)}% but you have only ${leftPercent.toFixed(2)}% left`
                        : `Minimum amount to be entered is $ ${monthlyInvestment.toFixed(2)} but you have only $ ${leftAmt.toFixed(2)} left`;
            } else {
                currErrorMsg =
                    amountIn === '%'
                        ? `Maximum percentage to be entered is $ ${leftPercent.toFixed(2)}%`
                        : `Maximum amount to be entered is $ ${leftAmt.toFixed(2)}`;
            }
        }
        return currErrorMsg;
    }

    onRadioChange = changeEvent => {
        const { target } = changeEvent;
        this.setState(
            prevState => {
                const { shares } = prevState;
                let { totalAmt } = prevState;
                totalAmt = +totalAmt;
                let { disableNext } = prevState;
                const newShares = this.resetShares(shares);
                if (!disableNext) disableNext = true;
                const value = target.id === 'dollar' ? '$' : '%';
                let amtRemaining = '';
                if (totalAmt) {
                    amtRemaining = value === '%' ? '100.00' : totalAmt.toFixed(2)
                }
                return {
                    amountIn: value,
                    shares: newShares,
                    amtConsumed: totalAmt ? '0.00' : '',
                    amtRemaining,
                    totalPercent: 0,
                    errIndex: null,
                    remainingErrMsg: '',
                    isShareErr: false,
                    disableNext,
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    };

    onNoFeeClick = event => {
        event.preventDefault();
        this.setState({showWarningDialog:false});
    }

    onNoRedemption= event => {
        event.preventDefault();
        const { shares, index} = this.state;
        shares[index].selected = false;
        this.setState({ showWarningDialog:false, shares});
    }

    onGateClick=()=>{
        const { isEditMode, plan } = this.props;
        if(!isEditMode && plan !== RECURRING_TRANSFER_PLAN.AIP) {
            this.setState({showWarningDialog:false});
        } else {
            this.calculteValue();
        }
    }

    getLiquidityFlag=(plan,FeeGate,isEdit) => {
        const isFee=FeeGate?.fundRule?.liquidityNoticeFlag
        if(plan === RECURRING_TRANSFER_PLAN.AIP){
            return false
        }
        if(plan !== RECURRING_TRANSFER_PLAN.AIP){
            // Fee
            if(isFee){
                return true
            }
            if(!isFee){
                // Redemption
                if(isEdit){
                    return false
                }
                return true
            }                     
        }
        return false
    }


    render() {
        const {
            amtConsumed,
            shares,
            totalErrMsg,
            amountIn,
            isEmptyErr,
            showWarningDialog,
            currentItem,
        } = this.state;
        const { forwardRef, investTo, plan, fundPlanData, isEditMode,editFlow } = this.props;
        const { planInfo = {} } = fundPlanData;
        const { detailName = '', amtText = '', titles } = investTo || {};
        const {
            column1: column1Title = '',
        } = titles || {};
        const FeeGate = !isEditMode ? currentItem : planInfo;
        const liquidityFlag=this.getLiquidityFlag(plan,FeeGate,isEditMode)

        return (
            <>
                 {showWarningDialog &&
                        <FeeGatesWarningModal
                            isFee={FeeGate?.fundRule?.liquidityNoticeFlag} //FeeGate?.redemptionNoticeFlag || 0}
                            isLiquidate= {liquidityFlag}  // {plan !== RECURRING_TRANSFER_PLAN.AIP && !isEditMode}
                            show={showWarningDialog}
                            fundName={isEditMode?FeeGate?.fundName:currentItem?.fundName}
                            feePercentValue={FeeGate?.fundRule.imposedFee} 
                            onPrimaryClick={FeeGate?.fundRule?.liquidityNoticeFlag?this.onYesFeeClick:this.onGateClick}
                            onSecondaryClick={isEditMode && FeeGate?.fundRule?.liquidityNoticeFlag?this.onNoFeeClick:this.onNoRedemption}
                            />
                 }
                <Accordian
                    ref={forwardRef}
                    key={detailName}
                    titleChild={<AccordianTitle accType={detailName} id='invest-to' accHeaderAs="button" />}
                    titleSeperator
                >
                    <div id={detailName.replace(/\s/g, '')} className="withDrawalForm">
                        <SharesContainer>
                           
                            {shares.map((item, index) => {
                                const disableShare = false;
                                const arialabel = `Select to ${detailName} ${item.fundName}`
                                let maxMonthlyInvestment;

                                const minMonthlyInvestment = item.minMonthlyInvestment ? +item.minMonthlyInvestment : 50;

                            if (plan === RECURRING_TRANSFER_PLAN.SWP) {
                                    maxMonthlyInvestment = item.maxMonthlyInvestment ? +item.maxMonthlyInvestment : 10000;
                                } else {
                                    maxMonthlyInvestment = item.maxMonthlyInvestment ? +item.maxMonthlyInvestment : 50000;
                                }

                                const minAmt = `Min ${formatCurrency(minMonthlyInvestment)}`;
                              
                                return (
                                    <>
                                   
                                    {((editFlow == 'editFlow' ) || (editFlow != 'editFlow' && item.accountStatus == 'O')) && 
                                        <ShareInputCard
                                        key={index.toString()}
                                        selected={!!item.selected}
                                        fundName={item.fundName}
                                        shortFundName={item.shortFundName}
                                        index={index}
                                        onSwitchClick={this.onSwitchClick(index)}
                                        disableShare={disableShare}
                                        arialabel={arialabel}
                                        amtText={amtText}
                                        amountIn={amountIn}
                                        percent={item.percent}
                                        amt={item.amt}
                                        isEmptyErr={isEmptyErr}
                                        itemEmptyErr={item.isEmptyErr}
                                        onShareAmtChange={this.onShareAmtChange(index)}
                                        inputRef={this.inputRefs[+index]}
                                        onInputBlur={this.onInputBlur(index, minMonthlyInvestment, maxMonthlyInvestment)}
                                        errorMsg={item.errorMsg}
                                        minAmt={minAmt}
                                        calculatedFundValue={item.calculatedFundValue}
                                    />
                                    }
                                    
                                    </>
                                );
                            })}
                        </SharesContainer>
                        <AmountDetailsSection>
                            {/* <EnteredAmtSection> */}
                            <TotalAmt className="totalAmountToInvest">
                                <TotalAmtLabel>{column1Title}</TotalAmtLabel>
                                <TotalAmtValue>{currencyFormatterFunction(amtConsumed)}</TotalAmtValue>
                                <TotalAmtErr>{totalErrMsg}</TotalAmtErr>
                            </TotalAmt>
                        </AmountDetailsSection>
                    </div>
                </Accordian>
            </>
        );
    }
}

InvestToComponent.propTypes = {
    shares: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number.isRequired,
    forwardRef: PropTypes.instanceOf(Object),
    investTo: PropTypes.instanceOf(Object),
    checkStatus: PropTypes.func,
    plan: PropTypes.string,
    ammountChanged: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
	fundPlanData: PropTypes.instanceOf(Object),
    isEditMode: PropTypes.bool,
    getSelectedShares: PropTypes.func,
};

InvestToComponent.defaultProps = {
    shares: [],
    savedState: null,
    investTo: {},
    checkStatus: () => { },
    forwardRef: {
        current: null,
    },
    plan: '',
    ammountChanged: () => { },
    analyzeFormFieldError: () => { },
	fundPlanData: {},
    isEditMode: false,
    getSelectedShares: () => {},
};

export default InvestToComponent;
