import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { deleteProfilePhoneInformations, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import CONST from 'modules/CustomerManagementModule/PhoneInformation/constants';
import PhoneInfoComponent from './PhoneInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData } = state;
    const { isError, isSuccess, isDeleteAPICalled } = profileInformationData;
    const notify = isError || isSuccess;
    let message = null;
    if (isSuccess) {
        message = isDeleteAPICalled ? CONST.PHONE_INFO_DELETE_SUCCESS : CONST.PHONE_INFO_SUCCESS;
    }
    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        notify,
        message: {
            message,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    deleteProfilePhoneInformations,
    clearExistingActions,
    analyzeClickEvent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNotification(PhoneInfoComponent)));
