/**
 * Email Address Commponent Card
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to provide multiple call Multiple PhoneNumber Forms
 * * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */


import { Accordian, AccordianTitle } from '../../../common';
import { Card, Alert } from 'react-bootstrap';
import PhoneDetails from './PhoneDetails';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

class PhoneDetailsCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryPhoneDetailsData: {},
            secondaryPhoneDetailsData: {},
            phoneDetailsClicked: true,
        };
        this.primaryPhoneDetailsRef = React.createRef();
        this.secondaryPhoneDetailsRef = React.createRef();
        this.contactAccordion = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const { savedState } = props;
        if (!state.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return state;
    }

    isValidPhoneNumber = phoneDetails => {
        const { phoneNumber, errPhoneNumber, mPhoneNumber, errCountryCode } = phoneDetails;
        return (mPhoneNumber !== '' || phoneNumber.length !== 0) && !errPhoneNumber && !errCountryCode;
    };

    sendDataToParent = (action='defaultVal') => {
        const primaryPhoneDetailsData =
            this.primaryPhoneDetailsRef.current && this.primaryPhoneDetailsRef.current.getPhoneDetails();
        const secondaryPhoneDetailsData =
            this.secondaryPhoneDetailsRef.current && this.secondaryPhoneDetailsRef.current.getPhoneDetails();
        let valid = false;
        let toFocus = '';
        
        let save = action === 'save';
        // if save button is clicked then dont run any validation and show error banner, run if its not save.
        if(!save){
        if (!this.isValidPhoneNumber(primaryPhoneDetailsData)) {
            this.setState({ showAlert: true });
            valid = false;
            const { errCountryCode, countryCodeId, phoneId } = primaryPhoneDetailsData;
            toFocus = errCountryCode ? countryCodeId : phoneId;
        } else if (
            secondaryPhoneDetailsData &&
            secondaryPhoneDetailsData.phoneNumber &&
            secondaryPhoneDetailsData.phoneNumber.length > 0
        ) {
            if (!this.isValidPhoneNumber(secondaryPhoneDetailsData)) {
                this.setState({ showAlert: true });
                valid = false;
                const { errCountryCode, countryCodeId, phoneId } = secondaryPhoneDetailsData;
                toFocus = errCountryCode ? countryCodeId : phoneId;
            } else {
                this.setState({ showAlert: false });
                valid = true;
            }
        } else {
            this.setState({ showAlert: false });
            valid = true;
        }
        }else{
            this.setState({ showAlert: false });
            valid = true;
        }
        return {
            primaryPhoneDetails: primaryPhoneDetailsData,
            secondaryPhoneDetails: secondaryPhoneDetailsData,
            valid,
            toFocus,
        };
    };

    cardHeaderClick = e => {
        const { phoneDetailsClicked } = this.state;
        e.preventDefault();
        e.target.classList.toggle('expand');
        this.setState({ phoneDetailsClicked: !phoneDetailsClicked });
    };

    // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues = () =>{
        this.setState({ showAlert: false });
    }    
    render() {
        const {
            isJoint,
            contactTimeOp,
            primaryPhoneNumber,
            secondaryPhoneNumber,
            primaryContactTime,
            secondaryContactTime,
            primaryIsMobile,
            secondaryIsMobile,
            primaryCountryCode,
            secondaryCountryCode,
            jointText,
            index,
            isBeneficiary,
            isSuccessor,
            mPrimaryPhoneNumber,
            mSecondaryPhoneNumber,
            isPrimaryNumberChanged,
            isSecondaryNumberChanged,
        } = this.props;
        const { primaryPhoneDetailsData, secondaryPhoneDetailsData, showAlert } = this.state;
        let accTypeHeading = isJoint ? `Phone Information - Joint Owner` : `Phone Information`;

        let beneficiaryHeading = 'Beneficiary Phone Information';
        if (isSuccessor) {
            beneficiaryHeading = 'Successor Phone Information';
        }
        accTypeHeading = isBeneficiary || isSuccessor ? beneficiaryHeading : accTypeHeading;
        // const jointText = isJoint ? 'jointOwner' : 'primaryOwner';
        const PHONE_ERROR = ' Please enter at least one primary phone number';
        return (
            <>
                {/* {initialState.isLoading && <WSpinner loading={initialState.isLoading} />} */}
                <Accordian
                    ref={this.contactAccordion}
                    key={accTypeHeading}
                    accordianPaddingLeft={0}
                    titleChild={
                        <AccordianTitle
                            accType={accTypeHeading}
                            id={accTypeHeading}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                            // accIndex={acctypeindex}
                        />
                    }
                    // titleSeperator
                    labelledBy={accTypeHeading}
                    Id={accTypeHeading}
                >
                    <Card>
                        <div>
                            {showAlert && (
                                <StyledAlert
                                    variant="danger"
                                    aria-atomic="true"
                                    aria-live="assertive"
                                    aria-describedby={PHONE_ERROR}
                                >
                                    <StyledPara>{PHONE_ERROR}</StyledPara>
                                </StyledAlert>
                            )}
                            <Card.Body>
                                <PhoneDetails
                                    ref={this.primaryPhoneDetailsRef}
                                    // isLoading={initialState.isLoading}
                                    required
                                    savedState={primaryPhoneDetailsData}
                                    jointText={jointText}
                                    phtype="Primary"
                                    isJoint={isJoint}
                                    contactTimeOp={contactTimeOp}
                                    savedPhoneNumber={primaryPhoneNumber}
                                    savedContactTime={primaryContactTime}
                                    savedCountryCode={primaryCountryCode}
                                    m_phoneNumber={mPrimaryPhoneNumber}
                                    isNumberChanged={isPrimaryNumberChanged}
                                    savedIsMobile={primaryIsMobile}
                                    index={index}
                                />
                                <PhoneDetails
                                    ref={this.secondaryPhoneDetailsRef}
                                    savedState={secondaryPhoneDetailsData}
                                    // isLoading={initialState.isLoading}
                                    jointText={jointText}
                                    phtype="Secondary"
                                    isJoint={isJoint}
                                    contactTimeOp={contactTimeOp}
                                    savedPhoneNumber={secondaryPhoneNumber}
                                    m_phoneNumber={mSecondaryPhoneNumber}
                                    isNumberChanged={isSecondaryNumberChanged}
                                    savedCountryCode={secondaryCountryCode}
                                    savedContactTime={secondaryContactTime}
                                    savedIsMobile={secondaryIsMobile}
                                    index={index}
                                />
                            </Card.Body>
                        </div>
                    </Card>
                </Accordian>
            </>
        );
    }
}

PhoneDetailsCardComponent.propTypes = {
    isJoint: PropTypes.bool,
    contactTimeOp: PropTypes.instanceOf(Array),
    primaryPhoneNumber: PropTypes.string,
    secondaryPhoneNumber: PropTypes.string,
    primaryContactTime: PropTypes.string,
    secondaryContactTime: PropTypes.string,
    primaryIsMobile: PropTypes.bool,
    secondaryIsMobile: PropTypes.bool,
    jointText: PropTypes.string,
    index: PropTypes.number,
    primaryCountryCode: PropTypes.string,
    secondaryCountryCode: PropTypes.string,
    savedState: PropTypes.instanceOf(Object),
    isBeneficiary: PropTypes.bool,
    isSuccessor: PropTypes.bool,
    mPrimaryPhoneNumber: PropTypes.string,
    mSecondaryPhoneNumber: PropTypes.string,
    isPrimaryNumberChanged: PropTypes.bool,
    isSecondaryNumberChanged: PropTypes.bool,
};

PhoneDetailsCardComponent.defaultProps = {
    isJoint: false,
    contactTimeOp: [],
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    primaryContactTime: '',
    secondaryContactTime: '',
    primaryIsMobile: false,
    secondaryIsMobile: false,
    jointText: '',
    index: 100,
    primaryCountryCode: '',
    secondaryCountryCode: '',
    savedState: {},
    isBeneficiary: false,
    isSuccessor: false,
    mPrimaryPhoneNumber: '',
    mSecondaryPhoneNumber: '',
    isPrimaryNumberChanged: false,
    isSecondaryNumberChanged: false,
};

export default PhoneDetailsCardComponent;
