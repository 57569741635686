import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from '../CommonStyles';

const CustomSwitch = styled.div`
    height: 26px;
    width: 44px;
    .custom-switch {
        padding-left: 1.5rem;
        height: 26px;
        width: 44px;
        .custom-control-label {
            position: static;
            color: transparent;
            overflow: hidden;
            text-indent: -9999px;
        }
    }
    .custom-switch .custom-control-label::before {
        left: -0.25rem;
        top: 0;
        width: 50px;
        height: 26px;
        border-radius: 15px;
        cursor: pointer;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem transparent;
        outline: 2px solid #000;
        outline-offset: 5px;
    }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: transparent;
    }

    .custom-control-input:disabled ~ .custom-control-label::before,
    .custom-control-input[disabled] ~ .custom-control-label::before {
        background-color: #9091b2;
    }
    .custom-switch .custom-control-label::after {
        top: calc(0.25rem - 1px);
        left: calc(-0.25rem + 3px);
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 1rem;
        border: 1px solid #757575;
        cursor: pointer;
    }
    .custom-control-label::before {
        background-color: #757575;
        border: 1px solid #757575;
    }
    .custom-control-input:checked ~ .custom-control-label::before,
    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        border-color: #004a98;
        background-color: #004a98;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(24.2px);
    }

    .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
        opacity: 0.5;
    }
    .custom-control-input[disabled] ~ .custom-control-label::before {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .custom-control-input[disabled] ~ .custom-control-label::after {
        cursor: not-allowed;
    }
`;

const onParentClick = e => {
    e.stopPropagation();
};

const WSwitch = props => {
    const {
        id: switchId,
        onSwitchClick,
        errortext,
        errorstyle,
        label: switchLabel,
        disabled,
        checked,
        arialabelledby,
        name,
        onBlur,
        arialabel,
        ariadescribedby,
    } = props;
    const assignObj = obj => {
        return obj;
    };
    const iserror = errortext !== undefined && errortext !== '' && errortext !== null;
    const additionalProps = {};
    if (arialabelledby) additionalProps['aria-labelledby'] = arialabelledby;
    if (arialabel) additionalProps['aria-label'] = arialabel;
    if (ariadescribedby) additionalProps['aria-describedby'] = ariadescribedby;
    return (
        <CustomSwitch onClick={onParentClick} className="customSwitchHandle">
            <Form.Check
                checked={checked}
                disabled={disabled}
                onChange={onSwitchClick}
                type="switch"
                // this is a probleme
                // voiceover will read the switch ID. label should be removed
                id={switchLabel !== '' ? switchLabel : switchId}
                label={switchId}
                name={name}
                // aria-labelledby={arialabelledby}
                // aria-label={arialabel}
                // aria-describedby={ariadescribedby}
                onBlur={onBlur}
                {...additionalProps}
            />
            {iserror ? (
                <Form.Text
                    style={assignObj({ ...styles.errorStyle, ...errorstyle })}
                    role="region"
                    id={assignObj(`${switchId}inputErrorText`)}
                    aria-labelledby={assignObj(`${switchId}inputErrorText`)}
                    aria-live="polite"
                >
                    {errortext}
                </Form.Text>
            ) : null}
        </CustomSwitch>
    );
};

WSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    onSwitchClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    arialabelledby: PropTypes.string,
    name: PropTypes.string,
    errortext: PropTypes.string,
    errorstyle: PropTypes.instanceOf(Object),
    onBlur: PropTypes.func,
    arialabel: PropTypes.string,
    ariadescribedby: PropTypes.string,
};

WSwitch.defaultProps = {
    label: '',
    onSwitchClick: () => {},
    disabled: false,
    checked: false,
    arialabelledby: '',
    name: '',
    errortext: '',
    errorstyle: {},
    onBlur: () => {},
    arialabel: '',
    ariadescribedby: '',
};

export default WSwitch;
