const generalUrls = [
    { url: '/accountholder', name: 'Home' },
    { url: '/accountservices', name: 'Account Services' },
];
const activeUrl = 'Manage Beneficiaries';
const manageBeneficiaryHeading = 'Manage Beneficiaries';
const verifyUpdateBeneficiaryHeading = 'Verify Update to Beneficiaries';
const beneInfo =
    'The following contracts / accounts are available for you to view and update your beneficiaries. If your contracts / beneficiaries are not shown here please';
const contactUsText = 'Contact us';
const contactUsLink = '/';
const primaryBenLabel = 'Primary Beneficiary';
const contingentBenLabel = 'Contingent Beneficiary';
const editText = 'Edit';
const addPrimaryText = 'Add Primary Beneficiary';
const addContingentText = 'Add Contingent Beneficiary';
const updateBenText = 'Update';
const boxModelHeading1 = 'Download the Completed Online Forms';
const boxModelHeading2 = 'To Upload Forms';
const downloadText = 'Download';
const samplPDFLink = 'https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf';
const boxModelPara1 = 'Mutual Fund Designation of Beneficiary -';
const boxModelParaItem1 = 'Traditional, Roth, SEP';
const boxModelParaItem2 = 'and';
const boxModelParaItem3 = 'Simple IRAs';
const boxModelPara2 = 'This is sample content paragraph';
const clickHere = 'Click Here';
const manage = 'Manage';
const distributionPercentageNote = 'Note: Distribution percentage of beneficiary can be set after adding the information.';
const cancel = 'Cancel';
const completeTheForm = 'Complete the Form';
const addAnotherBeneficiary = 'Add Another Beneficiary';
const saveChangesToBeneficiary = 'Save Changes';
const custodianRelationshipList = ['Aunt', 'Brother', 'Child', 'Cousin', 'Foster Child', 'Grandchild', 'Nephew', 'Niece', 'Sister', 'Step Child', 'Uncle '];
const relationToAccountHolderList = ['Spouse', 'Non-Spouse'];
const benefTypeOp = ['Individual', 'Non-individual'];
const dateofBirth = ' Date of Birth';
const addPrimaryBeneficiaryInformation = 'Add Primary Beneficiary Information';
const addContingentBeneficiaryInformation = 'Add Contingent Beneficiary Information';
const editPrimaryBeneficiaryInformation = 'Edit Primary Beneficiary Information';
const editContingentBeneficiaryInformation = 'Edit Contingent Beneficiary Information';
const detailsLabels = {
    beneficiaryName: 'Beneficiary Name',
    distributionPercentage: 'Distribution Percentage',
    totalDistribution: 'Total Distribution Percentage of',
    relationshipToAccountOwner: 'Relationship to Account Owner',
    transferOnDeathBeneficiaries: 'Transfer on Death Beneficiaries',
    invalidDistribution: 'Invalid Value'
};
const noBeneficiaries = 'There are currently no beneficiaries elected for this account, to add a beneficiary, please use the prompts on this screen. If you need any assistance, please contact Victory Member Services';
const deleteBeneficiary = {
    modalTitle: 'Delete Beneficiary',
    label: 'Delete',
    confirmButton: 'Confirm',
    cancelButton: 'Cancel',
    modalBody: 'Are you sure you want to mark the beneficiary',
    modalParagraph: 'This action will affect the distribution for the remaining beneficiaries. We recommend you verify the distribution amounts for the other elected beneficiaries'
};
const instructionsTitle = 'Instructions'
const instructionsBody = 'Naming your beneficiary is an important step to ensure your wishes are followed. You can name multiple primary and contingent beneficiaries and stipulate how distributions should be allocated.'
const PERCENT = '%';
const LOCALE = 'en-US'; // Used for the date picker
const coverdellEsa = 'Coverdell ESA';
export default {
    generalUrls,
    activeUrl,
    coverdellEsa,
    manageBeneficiaryHeading,
    verifyUpdateBeneficiaryHeading,
    beneInfo,
    contactUsText,
    contactUsLink,
    primaryBenLabel,
    contingentBenLabel,
    deleteBeneficiary,
    editText,
    addPrimaryText,
    addContingentText,
    boxModelHeading1,
    boxModelHeading2,
    downloadText,
    samplPDFLink,
    boxModelPara1,
    boxModelPara2,
    boxModelParaItem1,
    boxModelParaItem2,
    boxModelParaItem3,
    clickHere,
    manage,
    distributionPercentageNote,
    noData: 'No beneficiaries designated',
    accountType: 'Account Type',
    registrationOwner: 'Registration Owner',
    show: 'Show',
    hide: 'Hide',
    cancel,
    completeTheForm,
    updateBenText,
    addAnotherBeneficiary,
    saveChangesToBeneficiary,
    custodianRelationshipList,
    relationToAccountHolderList,
    benefTypeOp,
    dateofBirth,
    addPrimaryBeneficiaryInformation,
    addContingentBeneficiaryInformation,
    editPrimaryBeneficiaryInformation,
    editContingentBeneficiaryInformation,
    detailsLabels,
    noBeneficiaries,
    instructionsTitle,
    instructionsBody,
    LOCALE,
    PERCENT
};
