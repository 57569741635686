/**
 * Beneficiary Form Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to add beneficiaries IN 529 Child Flow
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
    WStepper,
    WSpinner,
    WSaveModal,
    WErrorModal,
    SelectInput,
    CommonButtons,
    RadioList,
    WInput,
} from '../../../common';
import BeneficiaryForm from '../BeneficiaryForm';
import PhoneDetails from '../PhoneDetails';
import Consts from './consts';
import styles from '../PersonalInfo/styles';

const PageWrapper = styled.div`
    padding-bottom: 30px;
    position: relative;
    color: rgb(73, 73, 74);
    text-align: left;
    font-family: benton-sans;
`;

const PageHeader = styled.h2`
    padding-top: 40px;
    font-size: 30px;
    color: rgb(79, 121, 159);
    font-weight: 800;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(140, 191, 38);
    font-family: yorkten-slab-normal;
    float: left;
    margin-bottom: 40px;
`;

const CardFooter = styled.p`
    font: 700 14px/17px benton-sans;
    color: #333333de;
    padding-bottom: 50px;
    margin-top: 100px;
`;
const SideHeading = styled.h6`
    font: 700 18px/35px Roboto Slab;
    color: #56565a;
    text-align: left;
`;

const MainHeading = styled.h3`
    margin: 30px 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #707070;
    text-align: left;
    font: 700 22px/35px Roboto Slab;
    color: #56565a;
`;

const SubHeading = styled.p`
    text-align: left;
    font: 300 14px/17px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 0.75;
`;

const assignObj = obj => {
    return obj;
};

const StyledRadio = styled(Row)`
    text-align: left;
    font: Bold 16px/19px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-top: 30px;
    .container-fluid {
        flex-basis: 10%;
        margin-left: 0;
        padding-left: 0;
        margin-right: 1%;
    }
    .radioQ {
        width: 212px;
        margin-right: 20px;
    }
    .container-fluid label {
        flex-basis: unset;
        width: unset;
        margin-bottom: 16px;
        font-weight: normal;
    }
`;

const StyledLink = styled.button`
    text-align: left;
    font: 700 16px/19px benton-sans;
    letter-spacing: 0;
    color: #5d83ae;
    opacity: 1;
    background: none;
    border: none;
`;

export default class BeneficiaryInfoChild extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
            cancel: false,
            error: false,
            showSaveModal: false,
            loading: false,
            pageHeading: '529 Account',
            showOwnerSection: false,
            showBeneficiaryForm: false,
            errorMsg: [],
        };
    }

    redirect = () => {
        const { next, back } = this.state;
        const { localStateData } = this.props;
        let pathName = '';
        const { accountType } = localStateData || {};
        if (next && accountType === '529_college') {
            pathName = '/application';
        } else if (back && accountType === '529_college') {
            pathName = '/personalInfo';
        }
        return {
            pathname: pathName,
        };
    };

    handleNext = () => {
        this.setState({ next: true }, () => {
            this.saveToLocal();
        });
    };

    handleSave = () => {
        this.setState({ showSaveModal: true });
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleClick = () => {
        this.setState({ showBeneficiaryForm: true });
    };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleBlur = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleRadio = values => {
        if (values.ownerRadio === 'yes') {
            this.setState({ showOwnerSection: 'yes' });
        } else {
            this.setState({ showOwnerSection: 'no' });
        }
    };

    saveToLocal = () => {
        const { manageLocalState, localStateData } = this.props;
        const accType = localStateData.accountType;
        if (accType.indexOf('529') > -1) {
            const { next } = this.state;
            let data = {};
            if (next) {
                data = {
                    esaInfo: {
                        signedDocument: false,
                        pageName: 'application2',
                    },
                };
            }
            manageLocalState(data);
        }
    };

    renderBeneficiarySection = () => {
        const { showBeneficiaryForm } = this.state;
        return (
            <>
                <MainHeading>{Consts.beneficiaryHeading}</MainHeading>
                <SideHeading>{Consts.planBeneficiaryText}</SideHeading>
                <SubHeading>{Consts.subtext}</SubHeading>
                <Row>
                    <SelectInput
                        label={Consts.beneficiaryOwner}
                        itemlist={Consts.beneficiaryOwneritems}
                        required
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        labelsm={4}
                        valuesm={5}
                    />
                </Row>
                <StyledLink onClick={this.handleClick}>{Consts.addBeneficiaryLink}</StyledLink>
                {showBeneficiaryForm && <BeneficiaryForm benCount={2} />}
            </>
        );
    };

    renderAccountOwnerSection = () => {
        const { showOwnerSection, dateofBirth, errorMsg } = this.state;
        return (
            <div className="card-body">
                <MainHeading>{Consts.successorHeading}</MainHeading>
                <StyledRadio>
                    <label htmlFor="ownerRadio" className="radioQ">
                        {Consts.successorQuestion}
                    </label>
                    <RadioList
                        items={Consts.successorQuestionValue}
                        field="ownerRadio"
                        setValue={this.handleRadio}
                        selectedValue={showOwnerSection}
                    />
                </StyledRadio>
                {showOwnerSection == 'yes' && (
                    <>
                        <Row>
                            <WInput
                                label={Consts.firstName}
                                placeholder="First Name"
                                className=""
                                id="firstName"
                                type="text"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                required
                                readOnly
                                labelsm={4}
                                valuesm={5}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label={Consts.middleName}
                                placeholder="Middle Initial"
                                optional="true"
                                sublabel={styles.sublabel}
                                id="middleName"
                                type="text"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly
                                labelsm={4}
                                valuesm={5}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label={Consts.lastName}
                                placeholder="Last Name"
                                className=""
                                id="lastName"
                                type="text"
                                required
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly
                                labelsm={4}
                                valuesm={5}
                            />
                        </Row>
                        <PhoneDetails required phtype="Mobile" />
                        <PhoneDetails phtype="Home" />
                        <PhoneDetails phtype="Work" />
                        <Row>
                            <WInput
                                label="Date of Birth"
                                placeholder="Date of Birth"
                                id="dateofBirth"
                                className="dateofBirth dobdate"
                                type="date"
                                value={dateofBirth || ''}
                                required
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                errortext={errorMsg.dateofBirth}
                                labelsm={4}
                                valuesm={5}
                                inputfieldstyle={assignObj({ width: 255 })}
                                inputstyle={assignObj({ marginBottom: 43 })}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label="Email Address"
                                placeholder="abc@gmail.com"
                                className=""
                                id="emailAdd"
                                type="email"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                required
                                labelsm={4}
                                valuesm={5}
                            />
                        </Row>
                        <Row>
                            <SelectInput
                                id="relationship"
                                label={Consts.relation}
                                className="prefix-select"
                                optional="true"
                                sublabel={styles.sublabel}
                                itemlist={Consts.beneficiaryOwneritems}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                labelsm={4}
                                valuesm={5}
                            />
                        </Row>
                    </>
                )}
            </div>
        );
    };

    renderCard = () => (
        <>
            {this.renderBeneficiarySection()}
            {this.renderAccountOwnerSection()}
            <div>
                <CardFooter>{Consts.considertext}</CardFooter>
            </div>
            <CommonButtons
                nextClick={this.handleNext}
                saveClick={this.handleSave}
                backClick={this.handleBack}
                cancelClick={this.handleCancel}
                showSave
            />
        </>
    );

    renderPage = pageHeading => {
        const { error, showSaveModal } = this.state;
        return (
            <>
                <PageWrapper>
                    <div className="container">
                        <PageHeader>{pageHeading}</PageHeader>
                        <WStepper style={Consts.stepperStyle} currentPage={4} pages={Consts.pages} />

                        <div className="personalIfoFormWrapper">{this.renderCard()}</div>
                    </div>
                    <WErrorModal show={error} message="Technical error" />
                    <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                </PageWrapper>
                <hr />
            </>
        );
    };

    render() {
        const { pageHeading, loading, error, showSaveModal, next, cancel, back } = this.state;

        return (
            <div className="MainContainer">
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                {error && <WErrorModal show={error} message="Technical error" />}
                {<WSpinner loading={loading} />}
                {!error && this.renderPage(pageHeading)}
                {next && <Redirect to={Consts.redirect} />}
                {cancel && <Redirect to="/" />}
                {back && <Redirect to="/personalInfoChild" />}
            </div>
        );
    }
}

BeneficiaryInfoChild.propTypes = {
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
};

BeneficiaryInfoChild.defaultProps = {
    localStateData: {},
    manageLocalState: () => {},
};
