import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateProfileRelationship, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import CONST from 'modules/CustomerManagementModule/EmailInformation/constants';
import RelationshipEmailInfoComponent from './RelationshipEmailInfoComponent';

export const mapStateToProps = state => {
    const { profileRelationshipData } = state;
    const { isError, isSuccess, isDeleteAPICalled } = profileRelationshipData;
    const notify = isError || isSuccess;
    let message = null;
    if (isSuccess) {
        message = isDeleteAPICalled ? CONST.EMAIL_DELETE_SUCCESS : CONST.EMAIL_SAVE_SUCCESS;
    }
    return {
        profileRelationshipData,
        notify,
        message: {
            message,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    updateProfileRelationship,
    clearRelationActions,
    analyzeClickEvent
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipEmailInfoComponent)),
);
