import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import AddressToggles from '../AddressToggles';
import SeasonalData from '../SeasonalData';
import KebabMenu from '../KebabMenu';

export const StyledAddressCard = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    margin-left: ${props => props.mL || 0}px;
    height: 100%;
`;

const StyledCardTitle = styled.h2`
    background: #486D90;
    padding: 25px 30px;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0;
`;

const AddressBlock = styled.div`
    padding: 20px 30px 30px 30px;
`;

const StyledAddressLine = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
    margin: 0;
`;

const StyledAddressLine1 = styled.p`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin-bottom: 10px
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    align-items: center;
`;

const AddressCardUSPSVerified = (props) =>{
    const { 
        cardTitle,
        addressLine1,
        addressLine2,
        addressLine3,
        mL,
        mailingAddress,
        physicalAddress,
        onMailingAddressChange,
        onPhysicalAddressChange,
        toggle,
        addressLine4,
        menu,
        seasonalDate,
        menuOptions,
        onMenuOptionClick,
        fromDate,
        toDate,
        idKey
    } = props;
    
    return (
        <StyledAddressCard mL={mL}>
            <StyledCardTitle>{cardTitle}</StyledCardTitle>
            <AddressBlock>
                {<FlexDiv>
                        <StyledAddressLine1>{addressLine1}</StyledAddressLine1>
                        { menu && <KebabMenu menuOptions={menuOptions} onMenuOptionClick={onMenuOptionClick} /> }
                </FlexDiv>
                }
                { addressLine1 && <StyledAddressLine>{addressLine2}</StyledAddressLine>}
                { addressLine2 && <StyledAddressLine>{addressLine3}</StyledAddressLine>}
                { addressLine4 && <StyledAddressLine>{addressLine4}</StyledAddressLine>}
                { toggle && 
                    <AddressToggles
                        mailingAddress={mailingAddress}
                        physicalAddress={physicalAddress}
                        onMailingAddressChange={onMailingAddressChange}
                        onPhysicalAddressChange={onPhysicalAddressChange}
                        idKey={idKey}
                    />
                }
            </AddressBlock>
            { !!seasonalDate && 
                <SeasonalData
                    fromDateValue={fromDate} 
                    toDateValue={toDate}
                />
            }
        </StyledAddressCard>
    );
}

AddressCardUSPSVerified.propTypes = {
    cardTitle: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    addressLine4: PropTypes.string,
    mL: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    mailingAddress: PropTypes.bool,
    physicalAddress: PropTypes.bool,
    onMailingAddressChange: PropTypes.func,
    onPhysicalAddressChange: PropTypes.func,
    toggle: PropTypes.bool,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    menu: PropTypes.bool,
    seasonalDate: PropTypes.bool,
    menuOptions: PropTypes.instanceOf(Object),
    onMenuOptionClick: PropTypes.func,
    idKey: PropTypes.string,
};

AddressCardUSPSVerified.defaultProps = {
    cardTitle: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    mL:0,
    mailingAddress: false,
    physicalAddress: false,
    onMailingAddressChange: ()=>{},
    onPhysicalAddressChange: ()=>{},
    toggle: false,
    fromDate: '',
    toDate: '',
    menu: false,
    seasonalDate: false,
    menuOptions: [],
    onMenuOptionClick: ()=>{},
    idKey: ''
};

export default AddressCardUSPSVerified;