import styled from 'styled-components';

const MessageBox = styled.div`
    background: #f5f5f5 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 25px;
    border: 2px solid #dcdcdc;
    opacity: 1;
    border-left-width: 6px;
    border-left-color: #486d90;
    min-height: auto;
    width: 100%;
`;

export default MessageBox;
