/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the BeneficiaryPayload for personalInfo Component
 * @createdDate [02/04/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

const generateChildBeneficiaryObject = (localStateData, data) => {
    return {
        customerId: localStateData.customerId,
        relation: data.relationshipToCustodian,
        personalInfo: {
            firstName: data.firstName,
            middleInitial: data.middleName,
            lastName: data.lastName,
            ssnTin: data.beneficiarySSN,
            dateOfBirth: data.beneficiaryDOB,
        },
    };
};

const generateBeneficiaryObject = data => {
    return {
        beneficiaryType: data.beneficiaryType,
        relation: data.relationshipToCustodian || data.relationToAccHolder,
        distributionPer: data.distributionPercentage || '100.00',
        firstName: data.firstName,
        middleInitial: data.middleName,
        lastName: data.lastName,
        ssnTin: data.beneficiarySSN,
        dateOfBirth: data.beneficiaryDOB,
        emailAddress: data.emailAdd,
        // isPrimaryBeneficiary: data.primaryBen,
    };
};

export default function generateBeneficiaryPayload(localStateData, data) {
    const primaryBenDetailsArr = [];
    const contingentBenDetailsArr = [];
    let beneficiaryInfo = {};
    // let count = 0;
    if (localStateData.accountType === 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)') {
        for (let i = 0; i < data.beneficiaryPrimaryDataArray.length; i += 1) {
            if (Object.keys(data.beneficiaryPrimaryDataArray[+i]).length !== 0) {
                beneficiaryInfo = {
                    childBeneficiary: generateChildBeneficiaryObject(
                        localStateData,
                        data.beneficiaryPrimaryDataArray[+i],
                    ),
                };
            }
        }
    } else {
        data.beneficiaryPrimaryDataArray.map(item => {
            return primaryBenDetailsArr.push(generateBeneficiaryObject(item));
        });
        data.beneficiaryContingentDataArray.map(item => {
            return contingentBenDetailsArr.push(generateBeneficiaryObject(item));
        });
        // for (let i = 0; i < data.length; i += 1) {
        //     if (Object.keys(data[+i]).length !== 0) {
        //         if(data[+i].primaryBen){
        //             primaryBenDetailsArr.push(this.generateBeneficiaryObject(data[+i]));
        //         }else{
        //             contingentBenDetailsArr.push(this.generateBeneficiaryObject(data[+i]));
        //         }
        //        // count += 1;
        //     }
        // }
        if (data) {
            beneficiaryInfo = {
                // totalBeneficiary: count,
                beneficiaries: {
                    primary: primaryBenDetailsArr,
                    contingent: contingentBenDetailsArr,
                },
            };
        }
    }
    return beneficiaryInfo;
}
