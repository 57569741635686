import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import iconDown from '../../../assets/Icon_Down.png';
import Styles from './Styles';
import ACCESS_FORMS_CONSTANTS from './Constants';

const FormsFooter = props => {
    const { loadMore, showLoadMore } = props;
    const assignObj = obj => obj;
    return (
        showLoadMore && (
            <Row data-testid='show-more-access-form'>
                <Styles.Footer
                    className="mt-4"
                    style={assignObj({ margin: 'auto' })}
                    role="button"
                    tabIndex="0"
                    onClick={loadMore}
                    onKeyPress={loadMore}
                    data-testid='load-more-button'
                >
                    {' '}
                    <span
                        style={assignObj({ fontWeight: '800', fontSize: '16px', color: '#004A98', outline:'none' })}
                        role="button"
                        tabIndex="0"
                    >
                        {ACCESS_FORMS_CONSTANTS.LOAD_MORE}
                    </span>
                    <Styles.ImgSrc className="ml-2" src={iconDown} alt="downarrow" />
                </Styles.Footer>{' '}
            </Row>
        )
    );
};

FormsFooter.propTypes = {
    loadMore: PropTypes.func,
    showLoadMore: PropTypes.bool,
};

FormsFooter.defaultProps = {
    loadMore: () => {},
    showLoadMore: false,
};

export default FormsFooter;
