const bottomLabelStyle = {
    textAlign: "left",
    letterSpacing: 0,
    opacity: 1,
    paddingTop: "12px",
};

const topLabelStyle = {
    color: "rgba(72, 109, 144, 1)",
    font: "800 30px/36px yorkten-slab-normal",
    paddingBottom: 10,
    borderBottom: "2px solid #8BC105",
    display: "inline-block",
    marginBottom: 40,
};

const manageStyle = {
    height: "21px",
    letterSpacing: 0,
    color: "#0000FF",
    opacity: 1,
    backgroundColor: "#F9FBFC",
    border: "none",
    outline: "none",
    paddingTop: "0px",
};

const disableStyle = { display: "none" };

const cardTextStyle = {
    font: "500 16px/24px benton-sans",
    color: "#49494A",
    maxWidth: "80%",
    textAlign: "left",
    letterSpacing: 0,
    opacity: 1,
    marginBottom: 0,
    height: "50px",
};

const cardStyle = {
    margin: "0 0 30px 0",
    minHeight: "275px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    opacity: 1,
    cursor: "pointer",
};

const containerStyle = {
    minHeight: " 50vh",
    position: "relative",
    display: "flex",
};

const manageClickButton = { width: "100%", opacity: 1, marginBottom: "20px" };

const mainContainer = { borderBottom: "2px solid #D2D2D2" };

export default {
    cardStyle,
    bottomLabelStyle,
    topLabelStyle,
    manageStyle,
    disableStyle,
    cardTextStyle,
    containerStyle,
    manageClickButton,
    mainContainer,
};
