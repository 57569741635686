
export const resetTrackDownLoadClick = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.RESET_TRACK_DOWNLOAD_CLICK,
    // });
};

export const resetFormFieldError = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.RESET_TRACK_FORM_FILED_ERROR,
    // });
};

export const resetTrackFormAdandonment = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.RESET_TRACK_FORM_ABANDONMENT,
    // });
};

export const resetApiResponse = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.RESET_API_RESPONSE,
    // });
};

export const linkResponseSuccess = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.API_RESPONSE,
    //     payload:{
    //         isError: false,
    //         backendError: ''
    //     }
    // });
};

export const linkResponseError = (errorMsg) => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.API_RESPONSE,
    //     payload:{
    //         isError: true,
    //         backendError: errorMsg
    //     }
    // });
};

export const analyzeClickEvent = data => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.ANALYZE_CLICK_EVENT,
    //     payload: data,
    // });        
    // if ( !data.waitforapiresponse) {
    //     dispatch(linkResponseSuccess());
    // }
};

export const analyzeDownLoadClickEvent = data => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.ANALYZE_DOWNLOAD_CLICK_EVENT,
    //     payload: data,
    // });
};

export const analyzeFormFieldError = data => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.ANALYZE_FORM_FIELD_ERRORS,
    //     payload: data,
    // });
};

export const analyzeFormAdandonment = data => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.ANALYZE_FORM_ABANDONMENT,
    //     payload: data,
    // });
};

export const resetUpdateAnalyticsPageDetails = () => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.RESET_UPDATE_ANALYTIC_PAGE_DETAILS,
    // });
};

export const updateAnalyticsPageDetails = data => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.UPDATE_ANALYTIC_PAGE_DETAILS,
    //     payload: data,
    // });
};

export const analyticsSetStepName = stepName => dispatch => {
    // dispatch({
    //     type: AnalyticsActions.CHANGE_STEP_NAME,
    //     payload: stepName,
    // });
};
