/**
 * Account Info Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to view Account preference for single Account
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [03/31/2020]
 * @lastModifiedBy Anjana
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Card, Col } from 'react-bootstrap';
import EditIcon from 'assets/edit.png';
import CONSTANTS from './consts';

const CardHeader = styled(Card.Header)`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid rgba(210, 210, 210, 1);
    border-bottom: none;
    opacity: 1;
    min-height: 72px;
    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid rgba(72, 109, 144, 1);
        position: absolute;
        top: -1px;
        left: 0;
    }
`;

const CardBody = styled(Card.Body)`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgba(210, 210, 210, 1);
    color: rgba(73, 73, 74, 1);
    letter-spacing: 0;
    .field-key {
        text-align: left;
        font: 600 16px/22px benton-sans;
        margin-bottom: 5px;
    }
    .field-value {
        text-align: left;
        font: 500 16px/22px benton-sans;
    }
    .field-wrap {
        min-width: 150px;
        width: auto;
        max-width: 206px;
        padding-right: 10px;
        padding-left: 10px;
        border-right: 1px solid rgba(230, 230, 230, 1);
    }
    .field-wrap:first-child{
        padding-left: 0;
    }
    .field-wrap:last-child{
        max-width: calc(100% - 210px);
        padding-left: 50px;
        padding-right: 0;
        border-right: none;
    }
    .field-wrap:last-child .field-value{
        font: 500 16px/22px benton-sans;
    }
    .field-wrap span {
        display: block;
    }
`;

const Cardd = styled(Card)`
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
`;

const HeaderSection = styled(Row)`
    justify-content: space-between;
    .headerText span {
        font: 800 18px/22px yorkten-slab-normal;
        color: rgba(72, 109, 144, 1);
        letter-spacing: 0;
        padding:0 15px;
        border-right: 1px solid rgba(206, 206, 206, 1);
    }
    .headerText span:last-child {
        border-right: none;
        padding-right: 0;
    }
    .headerText span:first-child {
        padding-left: 0;
    }
    button {
        background: none;
        border: none;
        text-align: center;
        font: 600 16px/22px benton-sans;
        letter-spacing: 0;
        color: rgb(0, 74, 152);
        opacity: 1;
        padding: 0;
    }
    .ellipsisMenu {
        right: -10px
    }
`;

const MenuItem = styled.li`
    display: flex;
    cursor: pointer;
`;

const Edit = styled.img`
    margin-right: 0.625rem;
`;


export default class AccountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }


    showOptions = () => {
        const { showMenu } = this.state;
        this.setState({ showMenu: !showMenu });
    };

    handleClickOutside(event) {
        const { showMenu } = this.state;
        const { account } = this.props;
        const { target } = event;
        if (showMenu && (target.name !== account.accName)) {
            this.setState({ showMenu: false });
        }
    }

    renderCol(key, value) {
        let deliveryMethod = ''
        switch (value) {
            case "P":
                deliveryMethod = 'Paper';
                break;
            case "E":
                deliveryMethod = 'Online';
                break;
            case "B":
                deliveryMethod = 'Paper & Online';
                break;
            default:
                break;
        }
        return (
            <Col className="field-wrap">
                <span className="field-key">{key}</span>
                <span className="field-value">{deliveryMethod}</span>
            </Col>
        )
    }

    render() {
        const { editClick, account } = this.props;

        if (Object.keys(account).length === 0) return null;

        const { showMenu } = this.state;
        const menuClass = showMenu ? "ellipsisMenu show" : "ellipsisMenu";
        const optionButtonText = `More Options for ${account.accName}`
        const editAriaLabel = `Edit Delievery Preferences for  ${account.accName}`
        return (
            <Cardd>
                <CardHeader>
                    <HeaderSection noGutters>
                        <div className="headerText">
                            <span style={CONSTANTS.headerFieldStyle}>{account.accName}</span>
                        </div>
                        {account.ownerType === "P" &&
                            <Row noGutters className="positionRelative">
                                <button type="button" onClick={this.showOptions} name={account.accName} className="ellipsisButton" aria-haspopup="true">
                                    <span className="sr-only">{optionButtonText}</span>
                                </button>
                                <ul className={menuClass} role='presentation'>
                                    <MenuItem onClick={editClick}>
                                        <Edit src={EditIcon} alt='edit icon' />
                                        <button
                                            type="button"
                                            className="ellipsisMenuButton"
                                            aria-label={editAriaLabel}
                                        >
                                            {CONSTANTS.linkText}
                                        </button>
                                    </MenuItem>
                                </ul>
                            </Row>}
                    </HeaderSection>
                </CardHeader>

                <CardBody>
                    <Row noGutters>
                        {account.deliveryPreferences.dlvMethStmt ? this.renderCol(CONSTANTS.statements, account.deliveryPreferences.dlvMethStmt) : null}
                        {account.deliveryPreferences.dlvMethConfirms ? this.renderCol(CONSTANTS.confirms, account.deliveryPreferences.dlvMethConfirms) : null}
                        {account.deliveryPreferences.dlvMethLit ? this.renderCol(CONSTANTS.reports, account.deliveryPreferences.dlvMethLit) : null}
                        {account.deliveryPreferences.dlvMethAcctUpdNotify ? this.renderCol(CONSTANTS.correspondence, account.deliveryPreferences.dlvMethAcctUpdNotify) : null}
                        {account.deliveryPreferences.dlvMethProxies ? this.renderCol(CONSTANTS.proxy, account.deliveryPreferences.dlvMethProxies) : null}
                        {account.deliveryPreferences.dlvMethTaxForms ? this.renderCol(CONSTANTS.tax, account.deliveryPreferences.dlvMethTaxForms) : null}
                    </Row>
                </CardBody>
            </Cardd>
        );
    }
}

AccountInfo.propTypes = {
    editClick: PropTypes.func,
    account: PropTypes.instanceOf(Object),
};

AccountInfo.defaultProps = {
    editClick: () => { },
    account: {}
};
