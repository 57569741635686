/**
 * Checkbook
 ********************************
 *
 * @version 1.0.0
 * @author Ramyakrishna K
 * @description Reorder Checkbook are displayed and managed here
 * @createdDate [11/12/2019]
 * ***************************************
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { CommonButtons, Accordian, StyledButton, AccordianTitle, WMessageBar, WBreadCrumb, WSpinner, WErrorModal, PlanInfoCardTitle } from 'common';
import routeConstants from 'routeConstants';
import constants from 'modules/AccountOptions/constant';
import CheckBookIcon from 'assets/icon-personal-check.svg';
import Card from 'react-bootstrap/Card';
import { createBreadCrumbItem } from '../../../../utils';
import {
    BREAD_CRUMB_SCREEN_CHECKBOOK,
    CHECKBOOK_HOME_PAGE_NAME,
    CHECKBOOK_REORDER_PAGE_NAME,
    STEP_KEY,
    CHECKBOOK_CLOSE_BUTTON_ID,
    CHECKBOOK_ORDER_BUTTON_ID,
    ACCOUNT_SERVICE_TYPE_CHECKBOOK,
    ACCOUNT_FLOW_TYPE_CHECKBOOK,
    CHECKBOOK_NAV_BUTTON_ID
} from '../../../../utils/AdobeAnalytics/Constants';
import routesBreadCrumb from '../../../../utils/Constants/routesBreadcrumb';
import CheckbookComponent from '../CheckBookComponent';
import { getSessionValues } from 'shared/Helpers/Utils';


const Heading = styled.h1`
    display: inline-block;
    border-bottom: 0.125rem solid #8CBF26;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.875rem;
    color: #486D90;
    opacity: 1;
    padding-bottom: 0.625rem;
    margin-bottom: 1.875rem;
`;
const CheckbookTitle = styled.div`
    margin-bottom: 1.875rem;
`;
const TitleSub1 = styled.span`
    text-align: left;
    font: 500 1rem/1.5rem benton-sans;
    letter-spacing: 0;
    color: #49494A;
`;
const TitleSub2 = styled.span`
    text-align: left;
    font: 600 1rem/1.5rem benton-sans;
    letter-spacing: 0;
    color: #49494A;
`;
const ContactNumber = styled.span`
    text-align: left;
    font: 600 1.125rem/1.5rem benton-sans;
    letter-spacing: 0;
    color: #004A98;
`;
const CardTitle = styled.div`
    margin-top: 0.9375rem;
    margin-bottom: 3.125rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #C7C7C7;
    border-radius: 0.25rem;
    opacity: 1;
    padding: 3.125rem;
    cursor: pointer;
`;
/* MENU */
const MenuContainer = styled.div`
    position: relative;
`;

const Menu = styled.span`
    position: relative;
    margin-right: 1.75rem;
    margin-top: 0.3125rem;
    &::after {
        content: '\\2807';
        color: #004A98;
        font-size: 27px;
    }
`;
const MenuItem = styled(StyledButton)`
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
`;
const MenuOptions = styled.div`
    margin-left: 0.625rem;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;
export const MenuIcon = styled.span`
    width: 2rem;
    height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
`;
const MenuOptionsPopup = styled(StyledButton)`
    display: flex;
    align-items: center;
    position: absolute;
    width: 11rem;
    height: 3.75rem;
    padding: 0;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 0 1.5625rem rgba(0,0,0,0.16);
    opacity: 1;
    font: 800 16px/20px Yorkten Slab Norm ExBold;
    letter-spacing: 0;
    color: #004A98;
    opacity: 1;
    z-index: 10;
    justify-content: center;
    left: -9.1rem;
    top: 2.25rem;
    padding: 1.25rem 0.25rem;
    &::after {
        content: " ";
        position: absolute;
        right: 0.5625rem;
        top: -0.5rem;
        border-top: none;
        border-right: 0.9375rem solid transparent;
        border-left: 0.9375rem solid transparent;
        border-bottom: 0.9375rem solid white;
    }
`;
/* End MENU */
const Title1 = styled.div`
    text-align: center;
    font: 700 1.25rem/1.875rem benton-sans;
    letter-spacing: 0;
    color: #56565A;
    opacity: 1;
`;
const Title2 = styled.div`
    text-align: center;
    font: 500 0.9375rem/1.75rem Roboto Slab;
    letter-spacing: 0;
    color: #54565B;
`;
const ContactNumberTwo = styled.div`
    text-align: center;
    font: 700 1.125rem/1.75rem Roboto Slab;
    letter-spacing: 0;
    color: #2B8CBE;
`;
const nextStyle = {
    display: 'none',
};
const backStyle = {
    display: 'none',
};
const cancelStyle = {
    display: 'none',
};

/* CheckbookPlanInfoCard */
const StyledAccordianHeading = styled(Accordian)`
    margin-bottom: 3.75rem;
`;
const StyledAccordianCard = styled(Accordian)`
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #486d90;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;
/* End CheckbookPlanInfoCard */

/* CheckbookAccountCardBody */
const FlexDiv = styled.div`
    display: flex;
`;
const StyledCardBody = styled(Card.Body)`
    && {
        padding: 0;
    }
`;
const ShareNameSection = styled(FlexDiv)`
    justify-content: space-between;
    padding: 1.1875rem 2.5rem;
    align-items: center;
`;
const ShareNameNumberContainer = styled(FlexDiv)`
    width: 70%;
    justify-content: space-between;
    align-items: center;
`;
const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;
const ShortFundName = styled(FlexDiv)`
    padding: 0.5rem;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
`;
const StyledHr = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
`;
/* End CheckbookAccountCardBody */

export const CheckbookAccountCardBody = props => {
    const { accountData, children } = props;
    return (
        <StyledCardBody>
            <StyledHr aria-hidden="true"/>
            <ShareNameSection>
                <ShareNameNumberContainer>
                    <ShareNameDiv>
                        <ShortFundName>{accountData.shortFundName}</ShortFundName>
                        <div>{accountData.fundName}</div>
                    </ShareNameDiv>
                </ShareNameNumberContainer>
                {children}
            </ShareNameSection>
        </StyledCardBody>
    );
};
CheckbookAccountCardBody.propTypes = {
    accountData: PropTypes.instanceOf(Object),
    children: PropTypes.node
};
CheckbookAccountCardBody.defaultProps = {
    accountData: {},
};

export const CheckbookPlanInfoCard = (props) => {
    const {
        onMenuClick,
        onCheckbookOrder,
        accountName,
        accountNumber,
        fundsList,
    } = props;

    return (
        <StyledAccordianCard
            titleChild={
                <PlanInfoCardTitle
                    accName={accountName}
                    accNumber={accountNumber}
                />
            }
            Id={accountNumber.toString()}
        >
            <>
                {fundsList.map((accountData, index) => {
                    const ariaExpand = !!accountData.showMenu;
                    return (<CheckbookAccountCardBody
                        key={index}
                        accountData={accountData}
                    >
                        <MenuContainer>
                            <Menu
                                aria-label="Menu"
                                onClick={(e) => onMenuClick(e, index)}
                                aria-expanded={ariaExpand.toString()}
                                role="menu"
                                aria-haspopup="true"
                            />
                            {accountData.showMenu && (
                                <MenuOptionsPopup aria-label="Reorder Checkbook"role="menuitem" onClick={(e) => onCheckbookOrder(e, index)}>
                                    <MenuItem>
                                        <MenuIcon url={CheckBookIcon} />
                                        <MenuOptions>
                                            {constants.checkbook.headingOne}
                                        </MenuOptions>
                                    </MenuItem>
                                </MenuOptionsPopup>
                            )}
                        </MenuContainer>
                    </CheckbookAccountCardBody>)
                })}
            </>
        </StyledAccordianCard>
    )
}
CheckbookPlanInfoCard.propTypes = {
    onMenuClick: PropTypes.func,
    onCheckbookOrder: PropTypes.func,
    accountName: PropTypes.string,
    accountNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fundsList: PropTypes.instanceOf(Object),
}
CheckbookPlanInfoCard.defaultProps = {
    onMenuClick: () => { },
    onCheckbookOrder: () => { },
    accountName: '',
    accountNumber: '',
    fundsList: [],
}
const bradcrumbPaths= [routeConstants.accountholder, routeConstants.accountservices];
class CheckbookMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            openMenuId: null,
            orderCheckbook: false,
            selectedAcc: null,
            notificationMsg: '',
            displayNotification: false,
            showMenu: false,
            showCancelNavBtn: false,
            skipDisableSubmit: true,
            isDropdownOpen: false,
            analitycsDigitalData:{
                accountOptions: {
                    accountservicetype: ACCOUNT_SERVICE_TYPE_CHECKBOOK,
                    accountflowtype: ACCOUNT_FLOW_TYPE_CHECKBOOK,
                    dividendsandpreferrencetype:'',
                    investmentplanamount:''
                }
            },
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const { getCheckBookInfo } = this.props;
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();
        const {accesstoken, vcmid, msrid, fismemberid} = params;
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid,            
        }
        if (getCheckBookInfo) {
            getCheckBookInfo(msrParams);
        }
        window.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillReceiveProps(nextProps) {
        const { isSuccessPostUpdateCheckbook } = nextProps;
        if (isSuccessPostUpdateCheckbook) {
            this.setState(() => {
                return {
                    orderCheckbook: false,
                    displayNotification: true,
                    notificationMsg: { message: `Your request for Checkbook Order is received.` },
                    showCancelNavBtn: false,
                };
            }, this.scrollToTop);
        }
    }

    componentWillUnmount() {
        const { orderCheckbook } = this.state;
        window.removeEventListener('click', this.handleOutsideClick, false);
        sessionStorage.setItem(
            STEP_KEY,
            orderCheckbook
                ? CHECKBOOK_REORDER_PAGE_NAME
                : CHECKBOOK_HOME_PAGE_NAME
        );
    }

    breadCrumbItems = () => {
        const { orderCheckbook } = this.state;
        const index = bradcrumbPaths.findIndex(item=>item==='/checkbook')
        if(index !==-1)  bradcrumbPaths.splice(index, 1)
        if (orderCheckbook) {
            bradcrumbPaths.push(routeConstants.checkbook)
        }
        return bradcrumbPaths.map(route => createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_CHECKBOOK))
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
        });
    };

    handleOutsideClick = () => {
        const { checkBookInfo = [] } = this.props;
        const { isMenuOpen, isDropdownOpen, showMenu } = this.state;

        if (isMenuOpen || isDropdownOpen || showMenu) {
            this.setState(prevState => {
                const { openMenuId } = prevState;
                checkBookInfo[+openMenuId.acctypeindex].accountDetails[+openMenuId.accindex].fundsList[+openMenuId.fundIndex].showMenu = false;
                return {
                    isMenuOpen: false,
                    isDropdownOpen: false,
                    openMenuId: {},
                    showMenu: false
                };
            });
        }
    }

    onSubmitReorder = () => {
        const { selectedAcc } = this.state;
        const { updateCheckbook } = this.props;
        updateCheckbook(selectedAcc);
    }

    onCancelClick = () => {
        this.setState(
            {
                orderCheckbook: false,
                showCancelNavBtn: false,
            },
            this.scrollToTop
        );
    }

    clickBack = () => {
        const { history } = this.props;

        history.push({ pathname: routeConstants.accountservices});
    }

    // FIXIT: this has a setState which happend in the same time with onCheckbookOrderClick
    onMenuClick = (e, acctypeindex, accindex, fundIndex) => {
        e.stopPropagation();
        const { checkBookInfo = [] } = this.props;

        this.setState(prevState => {
            const { openMenuId, isMenuOpen } = prevState;
            const isSameIndex =
                isMenuOpen && openMenuId.acctypeindex === acctypeindex && openMenuId.accindex === accindex && openMenuId.fundIndex === fundIndex;
            checkBookInfo[+acctypeindex].accountDetails[+accindex].fundsList[+fundIndex].showMenu = !checkBookInfo[+acctypeindex].accountDetails[+accindex].fundsList[+fundIndex].showMenu;

            if (isMenuOpen && !isSameIndex) {
                checkBookInfo[+openMenuId.acctypeindex].accountDetails[+openMenuId.accindex].fundsList[+openMenuId.fundIndex].showMenu = false;
            }

            return {
                checkBookInfo,
                isMenuOpen: !(isMenuOpen && isSameIndex),
                openMenuId:
                    isMenuOpen && isSameIndex
                        ? null
                        : {
                            acctypeindex,
                            accindex,
                            fundIndex
                        },
            };
        });
    }

    // FIXIT: this has a setState which happend in the same time with onMenuClick
    onCheckbookOrderClick = (e, accTypeIndex, accIndex, fundIndex) => {
        e.stopPropagation();
        const { checkBookInfo = [], analyzeClickEvent } = this.props;

        analyzeClickEvent({
            link: CHECKBOOK_ORDER_BUTTON_ID,
        });

        this.setState(prevState => {
            const { isMenuOpen, openMenuId } = prevState;
            const isSameIndex = isMenuOpen && openMenuId.accTypeIndex === accTypeIndex && openMenuId.accIndex === accIndex;

            return {
                orderCheckbook: true,
                showCancelNavBtn: true,
                isMenuOpen: !(isMenuOpen && isSameIndex),
                selectedAcc: isMenuOpen && isSameIndex
                    ? null
                    : checkBookInfo[+accTypeIndex].accountDetails[+accIndex].fundsList[+fundIndex],
                displayNotification: false
            };
        }, this.scrollToTop);
    };

    createFundItems = (account) => {
        const funds = get(account, 'fundsList', []);
        return funds.map(foundItem => ({
            showMenu: foundItem.showMenu,
            fundNumber: foundItem.fundNumber,
            fundAccountNumber: foundItem.fundAccountNumber,
            fundName: foundItem.fundName,
            shortFundName: foundItem.shortFundName
        }));
    }

    renderCheckbookData = () => {
        const { checkBookInfo } = this.props;
        return (
            checkBookInfo.map((checkbookItem, acctypeindex) => (
                <StyledAccordianHeading
                    key={checkbookItem.accountType}
                    titleChild={<AccordianTitle accType={checkbookItem.accountType} id={(checkbookItem.accountType).replace(/\s/g, "")}/>}
                    titleSeperator
                    Id={(checkbookItem.accountType+acctypeindex).replace(/\s/g, "")}
                >
                    <div>
                        {checkbookItem.accountDetails && checkbookItem.accountDetails.length && checkbookItem.accountDetails.map((accountItem, accindex) => {
                            return (
                                <CheckbookPlanInfoCard
                                    key={`${accountItem.accountNumber}`}
                                    onMenuClick={(event, fundIndex) => this.onMenuClick( event, acctypeindex, accindex, fundIndex )}
                                    onCheckbookOrder={(event, fundIndex) => this.onCheckbookOrderClick( event, acctypeindex, accindex, fundIndex ) }
                                    accountName={accountItem.accountName}
                                    accountNumber={accountItem.accountNumber}
                                    fundsList={this.createFundItems(accountItem)}
                                />
                            );
                        })}
                    </div>
                </StyledAccordianHeading>
            ))
    )}

    renderCheckbookTitle = () => (
        <CheckbookTitle>
            <TitleSub1>{constants.checkbook.titleSub1}</TitleSub1>
            <ContactNumber>{constants.checkbook.contactNum}</ContactNumber>
            <TitleSub1>{constants.checkbook.titleSub2}</TitleSub1>
            <TitleSub2>{constants.checkbook.titleSub3}</TitleSub2>
            <TitleSub1>{constants.checkbook.titleSub4}</TitleSub1>
        </CheckbookTitle>
    )

    renderCheckbookNoCheck = () => (
        <CardTitle>
            <Title1>{constants.checkbook.title1}</Title1>
            <Title2>{constants.checkbook.title2}</Title2>
            <ContactNumberTwo>{constants.checkbook.contactNum}</ContactNumberTwo>
        </CardTitle>
    )

    selectedBreadCrumb =(url) =>{
        if(url === '/checkbook'){
            const index = bradcrumbPaths.findIndex(item=>item==='/checkbook')
            bradcrumbPaths.splice(index,1)
            this.setState({
                orderCheckbook: false,
                showCancelNavBtn: false,
            })
        }
    }
    render() {
        const { orderCheckbook, selectedAcc, notificationMsg, displayNotification, showCancelNavBtn, skipDisableSubmit, analitycsDigitalData } = this.state;

        const {
            checkBookInfo = [],
            isLoading,
            isError,
            isSuccess,
            isLoadingPostUpdateCheckbook,
            isSuccessPostUpdateCheckbook,
            isErrorPostUpdateCheckbook,
            updateError
        } = this.props;

        const navProps = {
            dataAnalyticId: CHECKBOOK_NAV_BUTTON_ID,
            analyzecustom: analitycsDigitalData
        };
        if (showCancelNavBtn) {
            navProps.submitClick = this.onSubmitReorder;
            navProps.submit = 'true';
            navProps.disabled = !skipDisableSubmit;
            navProps.backstyle = backStyle;
            navProps.arialabelnext = 'Submit click';
            navProps.iscancel = true;
            navProps.arialabelcancel = 'Cancel click'
            navProps.cancelClick = this.onCancelClick;
        } else {
            navProps.backClick = this.clickBack;
            navProps.nextstyle = nextStyle;
            navProps.arialabelback = 'Back click';
            navProps.cancelStyle = cancelStyle;
        }

        const activeCrumb = orderCheckbook ? 'Reorder Checkbook' : routesBreadCrumb.get(routeConstants.checkbook);
        return (
            <div>
                {displayNotification && <WMessageBar onClose={this.onNotificationClose} text={notificationMsg} />}
                {isLoading && <WSpinner loading={isLoading} />}
                {isLoadingPostUpdateCheckbook && <WSpinner loading={isLoadingPostUpdateCheckbook} />}
                {isError && <WErrorModal show={isError} message="Technical error" modalClick={this.clickBack} dataAnalyticId={CHECKBOOK_CLOSE_BUTTON_ID} />}
                {isErrorPostUpdateCheckbook && <WErrorModal show={isErrorPostUpdateCheckbook} message={updateError} modalClick={this.clickBack} dataAnalyticId={CHECKBOOK_CLOSE_BUTTON_ID}/>}
                 <div className="container">
                    <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={this.breadCrumbItems()}  onClick={this.selectedBreadCrumb}/>
                    <Heading>{constants.checkbook.headingOne}</Heading>
                    {isLoading ? <div>{constants.checkbook.loadingText}</div> : null}
                    {isSuccess || isSuccessPostUpdateCheckbook ? <div>
                        { orderCheckbook
                            ? ( <CheckbookComponent accountDetails={selectedAcc}/>)
                            : ( <div>
                                    { checkBookInfo.length
                                        ? ( <>
                                                {this.renderCheckbookTitle()}
                                                {this.renderCheckbookData()}
                                            </>)
                                        : this.renderCheckbookNoCheck()}
                                </div>
                            )}
                        </div> : null}
                    </div>
                <CommonButtons {...navProps} />
            </div>
        );
    }
}

CheckbookMain.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    checkBookInfo: PropTypes.instanceOf(Array),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isLoadingPostUpdateCheckbook: PropTypes.bool,
    isSuccessPostUpdateCheckbook: PropTypes.bool,
    isErrorPostUpdateCheckbook: PropTypes.bool,
    updateError: PropTypes.string,
    getCheckBookInfo: PropTypes.func,
    updateCheckbook: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
};

CheckbookMain.defaultProps = {
    isSuccessPostUpdateCheckbook: false,
    isLoading: false,
    isError: false,
    isSuccess: true,
    checkBookInfo: [],
    isLoadingPostUpdateCheckbook: false,
    isErrorPostUpdateCheckbook: false,
    updateError: '',
    getCheckBookInfo: () => {},
    updateCheckbook: () => {},
    analyzeClickEvent: () => {},
}

export default CheckbookMain;
