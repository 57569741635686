/**
 * Account Type Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component is used for allowing the user to select account type like individual,Joint etc.
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [14/02/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { decrypt } from 'utils';
import {
    WStepper,
    CommonButtons,
    WSaveModal,
    WMessageBar,
    WSpinner,
    WAlertComponent,
    ConformationModal,
    WBreadCrumb,
} from '../../../common';
import consts from './consts';
import ListSec from './ListSec';
// import RenderInput from './inputFor529Flow';
import * as ActionTypes from '../../../shared/ReduxConstants/ServiceActionConstants';
import AccGlobalConstants from '../AccountManagementConstants';
import SpecialtyAccountHeading from './SpecialtyAccountHeading';
import styled from 'styled-components';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];
const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];
const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

const nextRedirect = (accTypeOrSubtypeSelected, accTypeKey) => {
    if (accTypeKey === 'spec_acct') {
        return '/specialtyAccountApplicationForms';
    }
    if (accTypeOrSubtypeSelected === 'Simple IRA' || accTypeOrSubtypeSelected === 'Rollover IRA') {
        return '/specialtyAccountApplicationForms';
    }
    switch (accTypeOrSubtypeSelected) {
        case '529_college':
            return '/esa';
        case 'rollover':
        case '403b':
        case 'simple':
            return '/agreement';
        default:
            return '/personalInfo';
    }
};

const CantSaveAccTypeComp = styled.p`
    color: red;
    text-align: center;
`;

const CantSaveAccTypeMsg = `This account type application cannot be saved.`;

class AccountTypeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accTypeDetails: [],
            accTypeSelected: '',
            showErrorMsg: false,
            loading: false,
            next: false,
            back: false,
            cancel: false,
            showSaveModal: false,
            errorFromMasterDataApi: false,
            error: {},
            showAlert: false,
            errorMessage: '',
            showConfirmCancelModal: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accOpeningData, localStateData } = nextProps;

        return {
            ...prevState,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            loading: accOpeningData.isLoading,
            accTypeSelected: prevState.accTypeSelected ? prevState.accTypeSelected : localStateData.accountType,
        };
    }

    componentDidMount() {
        const {
            getAccountSubTypes,
            localStateData,
            history,
            getApplication,
            uuid,
            getProfileBasicInformations,
            getCustomerProfile,
            getCompositeLookUpData,
        } = this.props;
        const datakey = history.location.state ? history.location.state.datakey : '';
        const applicationPayload = {
            uuid,
            token: localStorage.getItem('token'),
        };
        const role = localStorage.getItem('role');
        getApplication(applicationPayload);

        if (localStateData.masterDataLoadedForAccType !== undefined) {
            this.setState({ masterDataLoadedForAccType: localStateData.masterDataLoadedForAccType });
        }
        if (localStateData.marstoneFlag === true) {
            getProfileBasicInformations(decrypt);
        }
        if (localStateData.marstoneFlag === true && (role === 'Guest' || role === 'Prospect')) {
            getCustomerProfile({});
        }
        // if (localStateData.masterDataLoadedForAccType) {
        //     this.setState({
        //         accTypeDetails: localStateData.accTypeDetails,
        //         accTypeSelected: localStateData.accountType,
        //         loading: false,
        //         disableNext: false,
        //     });
        // } else {
        if (localStateData.accIDSelected) {
            const apikey = localStateData.accIDSelected || datakey;
            const payload = [apikey];
            getAccountSubTypes(payload);
        }

        getCompositeLookUpData(consts.compositePayload);
        // }
    }

    componentDidUpdate(prevProps /* , prevState */) {
        const { accOpeningData, manageLocalState, localStateData, getAccountSubTypes } = this.props;
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
        if (prevProps.accOpeningData.isLoadingApplication !== accOpeningData.isLoadingApplication) {
            manageLocalState({ ...accOpeningData.application });
        }
        if (prevProps.localStateData.accIDSelected !== localStateData.accIDSelected) {
            const apikey = localStateData.accIDSelected;
            const payload = [apikey];
            getAccountSubTypes(payload);
        }
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    updateState = prevProps => {
        const { masterLookupStateData, accOpeningData } = this.props;
        const didUpdateStateData = { ...this.state };
        // Account Sub Types
        if (
            accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES] !==
                prevProps.accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES]
        ) {
            let accTypeDetails = {};
            const accTypeDetailsTemp = accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES];
            if (accTypeDetailsTemp.key !== 'ira') {
                accTypeDetails.key = accTypeDetailsTemp.key;
                accTypeDetails.value = [accTypeDetailsTemp];
            } else {
                accTypeDetails = accTypeDetailsTemp;
            }
            didUpdateStateData.accTypeDetails = accTypeDetails;
        }

        // Loading
        if (masterLookupStateData.isLoading && accOpeningData.isLoading) {
            didUpdateStateData.loading = true;
        } else if (!masterLookupStateData.isLoading && !accOpeningData.isLoading) {
            didUpdateStateData.loading = false;
            didUpdateStateData.masterDataLoadedForAccType = true;
        }
        // Error Handling
        this.handleAPIError(masterLookupStateData, accOpeningData, didUpdateStateData);

        //   Save Function Values Start
        const saveState = this.handleSaveAPI(accOpeningData, prevProps, didUpdateStateData);

        // if (didUpdateStateData !== this.state) {
        this.setState({ ...didUpdateStateData, ...saveState });
        //   / }
    };

    // Refactored for sonar issue
    handleSaveAPI = (accOpeningData, prevProps, didUpdateStateData) => {
        const data = didUpdateStateData;
        if (
            didUpdateStateData &&
            accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT &&
            accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT !== prevProps.accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT
        ) {
            // let msg;
            if (accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.status) {
                // msg = accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.status;
                data.showSaveModal = true;
            } else {
                const msg =
                    accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.message ||
                    accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.errorMessage;
                data.showAlert = true;
                data.isErrorBar = true;
                data.errorMessage = { info: msg };
            }
            data.loading = false;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        return { ...data };
    };

    // Refactored for sonar issue
    handleAPIError = (masterLookupStateData, accOpeningData) => {
        // Master Data Error
        if (masterLookupStateData.isError) {
            this.setState({ errorFromMasterDataApi: true });
        } else if (!masterLookupStateData.isError) {
            this.setState({ errorFromMasterDataApi: false });
        }
        if (accOpeningData.response) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    getAccountTypeCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };

    handleCardClick = (id, key) => e => {
        e.preventDefault();
        const { setSpecialtyApplicationSubtype = null, localStateData, getAccountDefaultNickName } = this.props;
        const { accIDSelected = null } = localStateData;

        this.setState({
            accTypeSelected: id,
            disableNext: false,
            showErrorMsg: false,
        });

        if (accIDSelected === 'spec_acct') {
            setSpecialtyApplicationSubtype(key);
        }

        if (accIDSelected === 'ira') {
            setSpecialtyApplicationSubtype(key);
        }

        const accountNickNamePayload = {
            fisMemberId: localStorage.getItem('fisMemberId'),
            accounts: [
                {
                    accountTypeCode: `${this.getAccountTypeCode(id)}_${this.getSocialCode(id)}`,
                    updatedBy: 'Owner',
                    updatedByChannel: 'W&M',
                },
            ],
        };

        getAccountDefaultNickName(accountNickNamePayload);
    };

    handleNextClick = e => {
        const { accTypeSelected } = this.state;
        const { setCustomerVerificationStatus } = this.props;
        setCustomerVerificationStatus();
        e.preventDefault();
        if (accTypeSelected && accTypeSelected !== '') {
            this.setState({ next: true }, () => {
                this.saveToLocal();
            });
        } else if (!accTypeSelected) {
            this.setState({ showErrorMsg: true });

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleBackClick = e => {
        e.preventDefault();
        this.saveToLocal();
        this.setState({ back: true });
    };

    handleSaveClick = e => {
        e.preventDefault();
        const { accTypeSelected } = this.state;
        if (accTypeSelected && accTypeSelected !== '') {
            this.saveToLocal('save');
        } else if (!accTypeSelected) {
            this.setState({ showErrorMsg: true });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    saveToLocal = (actionType = '') => {
        const { manageLocalState, initialState, saveApplication, localStateData } = this.props;
        const { masterDataLoadedForAccType, accTypeSelected, accTypeDetails } = this.state;
        const data = {
            companyNumber: '591',
            onlineId: initialState.onlineId,
            customerId: initialState.currentPIN,
            accountType: accTypeSelected,
            accTypeDetails,
            masterDataLoadedForAccType,
        };
        const payLoad = {
            companyNumber: '591',
            onlineId: initialState.onlineId,
            customerId: initialState.currentPIN,
            accountType: accTypeSelected,
        };
        if (actionType === 'save') {
            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: accTypeSelected,
                    payload: { ...localStateData, OpenAccPageOne: payLoad, ...data },
                },
            });
        } else {
            manageLocalState({ OpenAccPageOne: payLoad, ...data });
            sessionStorage.setItem('accountTypeSelected', accTypeSelected);
        }
        // console.log(sessionStorage.getItem('accountTypeSelected'))
    };

    render() {
        const { localStateData } = this.props;
        const { errorFromMasterDataApi, error, showErrorMsg } = this.state;

        const { accountOpeningPages, child529AccountOpeningPages, accountTypeBackLabel } = AccGlobalConstants;
        const radioInstruction = 'Please select any one of the options';
        if (errorFromMasterDataApi) {
            throw new Error(error);
        }

        const {
            accTypeDetails = [],
            accTypeSelected,
            showSaveModal,
            next,
            cancel,
            back,
            errorMessage,
            showAlert,
            isErrorBar,
            loading,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
        } = this.state;
        const selectedAccountType = localStateData.accSelected;
        const accTypeDetailsArr = accTypeDetails.value;
        const is529Account = accTypeSelected === '529_college';
        const pagesForStepper = is529Account ? child529AccountOpeningPages : accountOpeningPages;
        const isSpecialtyAccount = accTypeDetails.key === 'spec_acct';

        return (
            <>
                {' '}
                {loading && <WSpinner loading={loading} />}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Application"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                <div>
                    <div style={consts.wrapperStyles} className="accountTypePage">
                        {showAlert && (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        )}
                        <div className="container">
                            <WBreadCrumb
                                activeCrumb={selectedAccountType}
                                breadCrumbItems={
                                    localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                                }
                            />
                            {!isSpecialtyAccount && (
                                <>
                                    <Row noGutters>
                                        <h1 style={consts.pageHeaderStyle} acctype={accTypeDetails.key}>
                                            {selectedAccountType}
                                        </h1>
                                    </Row>
                                    <WStepper style={consts.stepperStyle} currentPage={0} pages={pagesForStepper} />
                                </>
                            )}
                            {isSpecialtyAccount && (
                                <SpecialtyAccountHeading specialtyText="selectedAccountType">
                                    {selectedAccountType}
                                </SpecialtyAccountHeading>
                            )}

                            {/* {showErrorMsg && <Row className="alertWrapper" noGutters>
                                <Col sm={12}>
                                    <Alert variant="danger" className="styledAlert">
                                        <p>{consts.radioError}</p>
                                    </Alert>
                                </Col>
                            </Row>} */}
                            <WAlertComponent
                                show={showErrorMsg}
                                alertmsg={consts.radioError}
                                className="alertWrapper"
                            />
                            <Row style={consts.accTypeRowStyle} noGutters role="radiogroup">
                                <span className="sr-only">{radioInstruction}</span>
                                <ListSec
                                    accTypeDetailsArr={accTypeDetailsArr}
                                    accTypeSelected={accTypeSelected}
                                    accTypeDetailsKey={accTypeDetails.key}
                                    handleCardClick={this.handleCardClick}
                                />
                            </Row>

                            <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        </div>
                        {(accTypeSelected === 'Rollover IRA' || accTypeSelected === 'Simple IRA') && (
                            <CantSaveAccTypeComp>{CantSaveAccTypeMsg}</CantSaveAccTypeComp>
                        )}
                        <CommonButtons
                            cancelClick={this.handleCancelClick}
                            saveClick={this.handleSaveClick}
                            nextClick={this.handleNextClick}
                            backClick={this.handleBackClick}
                            showSave={!isSpecialtyAccount}
                            iscancel={!isSpecialtyAccount}
                            cancelText="Cancel"
                            cancelStyle={assignObj(cancelButtonStyles)}
                            arialabelback={accountTypeBackLabel}
                            cancelOffset={assignObj({
                                span: 2,
                                offset: 6,
                            })}
                            saveDisable={
                                accTypeSelected === 'Rollover IRA' || accTypeSelected === 'Simple IRA' ? true : false
                            }
                        />

                        {next && <Redirect to={nextRedirect(accTypeSelected, accTypeDetails.key)} />}
                        {cancel && <Redirect to="/" />}
                        {back && <Redirect to="/openAccount" />}
                    </div>
                </div>
            </>
        );
    }
}

AccountTypeComponent.propTypes = {
    saveApplication: PropTypes.func,
    getAccountSubTypes: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    clearLocalStateForNAO: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    accOpeningData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    location: PropTypes.shape({
        state: PropTypes.shape({
            accSelected: PropTypes.string,
            accIDSelected: PropTypes.string,
            onlineId: PropTypes.string,
            customerId: PropTypes.string,
        }),
    }),
    history: PropTypes.instanceOf(Object),
    setSpecialtyApplicationSubtype: PropTypes.func,
    resetShowConfirmModal: PropTypes.func,
    getApplication: PropTypes.func,
    uuid: PropTypes.string,
    getProfileBasicInformations: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    getAccountDefaultNickName: PropTypes.func,
    getCompositeLookUpData: PropTypes.instanceOf(Object),
    setCustomerVerificationStatus: PropTypes.func,
};

AccountTypeComponent.defaultProps = {
    initialState: {},
    masterLookupStateData: {},
    saveApplication: () => {},
    getAccountSubTypes: () => {},
    location: {},
    localStateData: {},
    manageLocalState: () => {},
    accOpeningData: {},
    clearLocalStateForNAO: () => {},
    history: {},
    setSpecialtyApplicationSubtype: null,
    resetShowConfirmModal: () => {},
    getApplication: () => {},
    uuid: '',
    getProfileBasicInformations: () => {},
    getCustomerProfile: () => {},
    getAccountDefaultNickName: () => {},
    getCompositeLookUpData: () => {},
    setCustomerVerificationStatus: () => {},
};
export default AccountTypeComponent;
