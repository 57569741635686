import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Container } from 'react-bootstrap';
import { formatDate, isValidDate } from 'utils';
import { 
    BENEFICIARIES_ADD_BUTTON_ID,
    BENEFICIARIES_ADD_ANOTHER_BUTTON_ID,
 } from '../../../utils/AdobeAnalytics/Constants';
import cancelIcon from 'assets/icon-delete-red.svg';
import styled from 'styled-components';
import checkValidity from 'utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import { WInput, VCMSelect, VCMDatePicker, WIcon, SSNComponent, WButton } from '../../../common';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';
import consts from './consts';
import './ManageBeneficiaries.css';


const assignObj = obj => {
    return obj;
};

const NoteTxt = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;

    margin-bottom: 2.5rem;
    padding: 1.625rem 1.875rem;

    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;

    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

class AddBeneficiaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            dob: '',
            distributionPer: '0',
            relationshipType: '1',
            isNew: !props.updatableBeneficiary || !!props.updatableBeneficiary.isNew,
            isPartialSSN: false
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.prepareBeneficiaryObject = this.prepareBeneficiaryObject.bind(this)
        this.ssnBenRef = React.createRef();
    }

    componentDidMount() {
        const { updatableBeneficiary } = this.props;

        if (updatableBeneficiary) {
            const splitSSN = this.formatSocialSecurity(updatableBeneficiary.taxId || '').split('-');
            this.ssnBenRef.current.state.ssnOne = splitSSN[0];
            this.ssnBenRef.current.state.ssnTwo = splitSSN[1];
            this.ssnBenRef.current.state.ssnThree = splitSSN[2];

            this.setState({
                dob: formatDate(updatableBeneficiary.dob, 'MM/DD/YYYY') || '',
                relationshipType: updatableBeneficiary.taxType === 'I' ? '0':'1',
                distributionPer: updatableBeneficiary.distribution_Per || '',
                firstName: updatableBeneficiary.fname || updatableBeneficiary.bene_Name,
                edited: true,
                taxId: updatableBeneficiary.taxId,
                relationToAccHolder: isNaN(updatableBeneficiary.relationship_To_Insured) ? (updatableBeneficiary.relationship_To_Insured === 'Spouse' ? '0' : '1') : updatableBeneficiary.relationship_To_Insured,
                beneficaryType: updatableBeneficiary.beneficaryType,
                nbrSeq: updatableBeneficiary.nbrSeq
            });
        }
    }

    componentWillUnmount() {
        const { handleEditFormClosed } = this.props;
        handleEditFormClosed();
    }

    prepareBeneficiaryObject(addAnother) {
        const { benType } = this.props;

        const fields = [
            'relationshipType',
            'relationToAccHolder',
            'firstName',
            'dob',
            'taxId',
            'taxType',
            'distribution_Per',
            'isNew',
            'edited',
            'beneficaryType',
            'nbrSeq'
        ];
        let stateData = this.state;

        let beneficiary = {}

        fields.forEach(field => {
            if (field === 'relationToAccHolder') {
                const index = Number(stateData[field])
                if (stateData['relationshipType'] === "0") {
                    beneficiary['relationship_To_Insured'] = stateData['relationToAccHolder'] || consts.relationToAccountHolderList[index];                    
                } 
            } else{
                if (field === 'relationshipType') {
                    beneficiary.relationshipType = stateData['relationshipType'] === '0' ? '1' : '0';
                } else {
                    beneficiary[field] = stateData[field];
                }
            }
        })
        beneficiary.bene_Name = stateData.firstName;
        
        beneficiary.distribution_Per = stateData.distributionPer;
        beneficiary.beneficaryType = stateData.beneficaryType || benType;
        beneficiary.taxType = stateData['relationshipType'] === '0' ? 'I' : 'N'

        if (addAnother) {
            fields.forEach(field => {
                if (field !== 'isNew') {
                    this.setState({ [field]: '' })
                }
            });
            this.ssnBenRef.current.state.ssnOne = '';
            this.ssnBenRef.current.state.ssnTwo = '';
            this.ssnBenRef.current.state.ssnThree = '';
        }

        return beneficiary
    }

    handleSubmit() {
        const { handleSubmitNewBeneficiary } = this.props
        const beneficiary = this.prepareBeneficiaryObject(false)
        handleSubmitNewBeneficiary(beneficiary, false)
    }

    handleAddAnotherBeneficiary = () => {
        const { handleSubmitNewBeneficiary } = this.props

        const beneficiary = this.prepareBeneficiaryObject(true)
        handleSubmitNewBeneficiary(beneficiary, true)

    }

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                rules: Rules[eventKey.toString()],
                value: e.target.value,
            })
            : '';

        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg });
    };


    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                rules: Rules[eventKey.toString()],
                value: e.target.value,
            })
            : '';

        errorMsg[eventKey.toString()] = err;

        this.setState({ [eventKey]: e.target.value, errorMsg });
    };

    handleChangeDOB = (e) => {
        this.setState({ dob: e.target.value });
    };

    addShouldBeEnabled = () => {
        const { relationshipType, isPartialSSN } = this.state;
        const stateData = this.state;
        let valid = true;
        const fields = [
            'relationshipType',
            'firstName'
        ];
        if (relationshipType === "0") {
            fields.push('relationToAccHolder')
            fields.push('dob')
        }

        if (isPartialSSN) {
            fields.push('SSN')
        }

        fields.forEach(field => {
            const err = Rules[field.toString()]
                ? checkValidity({
                    rules: Rules[field.toString()],
                    value: stateData[field.toString()],
                })
                : '';
            if (err !== null) valid = false
        });

        return valid;
    };

    handleSSN = val => {
        if (val.length < 9 && val.length > 0) {
            this.setState({ isPartialSSN: true })
        } else {
            this.setState({
                taxId: val,
                isPartialSSN: false
            });
        }
    };

    handleCancel() {
        const { cancelAddBeneficiary } = this.props
        cancelAddBeneficiary();
    }

    formatSocialSecurity(val) {
        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val;
    }

    renderBeneficiaryFields = () => {
        const {
            errorMsg,
            relationshipType,
        } = this.state;
        const { isChild, index, benType } = this.props;
        const childText = isChild ? 'child' : `beneficiary${benType}${index}`;

        return (
            <div className="addBeneDropDownDiv">
                <VCMSelect
                    name="relationshipType"
                    id={assignObj(`${childText}relationshipType`)}
                    itemlist={consts.benefTypeOp}
                    label="Beneficiary Type"
                    onChange={this.handleChange('relationshipType')}
                    onBlur={this.handleBlur('relationshipType')}
                    value={relationshipType}
                    labelsm={4}
                    valuesm={5}
                    errortext={errorMsg.relationshipType}
                    noGutters
                />
            </div>
        )
    };

    renderNameInputs() {
        const { errorMsg, firstName } = this.state
        return (<>
            {<Row>
                <WInput
                    label="Name"
                    placeholder="Name"
                    className=""
                    name="firstName"
                    id="addBeneFirstName"
                    type="text"
                    maxlength="40"
                    onChange={this.handleChange('firstName')}
                    onBlur={this.handleBlur('firstName')}
                    value={firstName || ''}
                    required
                    errortext={errorMsg.firstName}
                    labelsm={4}
                    valuesm={5}
                />
            </Row>} 
        </>)
    }

    render() {
        const {
            errorMsg,
            dob,
            firstName,
            relationshipType,
            relationToAccHolder,
            edited
        } = this.state;
        const { benType, updatableBeneficiary, forwardRef } = this.props;
        const startMaxDate = new Date();
        const shouldEnableAddButton = this.addShouldBeEnabled()
        const ssnLabelValue = relationshipType === "0" ? 'Social Security Number' : 'Social Security Number/Tax Identification Number'
        const addButtonText = (shouldEnableAddButton && !updatableBeneficiary) 
                ? `Add ${firstName}`
                : (updatableBeneficiary && !shouldEnableAddButton) 
                    ? `${consts.saveChangesToBeneficiary}`
                    : (updatableBeneficiary && shouldEnableAddButton) 
                        ? `${consts.updateBenText}`
                        : `${consts.completeTheForm}`
        return (
            <div className="addBeneficiaryForm" ref={forwardRef}>
                <div className="addBeneDropDownDiv">
                    <div className="benSectionHeaderWrapper">
                        <h3 className="beneSectionHeading">{benType === 'P' ? (edited ? consts.editPrimaryBeneficiaryInformation : consts.addPrimaryBeneficiaryInformation) : (edited ? consts.editContingentBeneficiaryInformation : consts.addContingentBeneficiaryInformation)}</h3>
                    </div>
                </div>
                <Container>
                    {this.renderBeneficiaryFields()}
                    {this.renderNameInputs()}

                    <Row>
                        <SSNComponent
                            ref={this.ssnBenRef}
                            savedstate={this.ssnBenRef}
                            sendfullssn={this.handleSSN}
                            optional="true"
                            label={ssnLabelValue}
                        />
                    </Row>
                    {relationshipType === "0" ?
                        <div className="addBeneDropDownDiv">
                            <VCMDatePicker
                                name="beneficiaryDOB"
                                id="addBeneBeneficiaryDOB"
                                max={isValidDate(startMaxDate) ? formatDate(startMaxDate) : ''}
                                label={consts.dateofBirth}
                                onChange={(e) => this.handleChangeDOB(e)}
                                errortext={errorMsg.beneficiaryDOB}
                                value={dob}
                                labelsm={4}
                                valuesm={5}
                                noGutters
                                locale={consts.LOCALE}
                            />
                        </div> : null}
                    {relationshipType === "0" ? <div className="addBeneDropDownDiv">
                        <VCMSelect
                            name="relationToAccHolder"
                            id="relationToAccHolder"
                            itemlist={consts.relationToAccountHolderList}
                            label="Relationship to Account Holder"
                            onChange={this.handleChange('relationToAccHolder')}
                            onBlur={this.handleBlur('relationToAccHolder')}
                            value={relationToAccHolder}
                            labelsm={4}
                            valuesm={5}
                            errortext={errorMsg.relationToAccHolder}
                            noGutters
                        />
                    </div> : null}
                    <div className="addBeneDropDownDiv">
                        <NoteTxt>{consts.distributionPercentageNote}</NoteTxt>
                    </div>
                    <div className="inlineDiv">
                        <button
                            type="button"
                            className="addBeneficiaryCancelBtn"
                            onClick={this.handleCancel}
                            bentype={benType}>
                            <WIcon src={cancelIcon} fontawesomestyle={styles.iconImgStyle} alt="" />{consts.cancel}</button>
                        {shouldEnableAddButton && !updatableBeneficiary 
                        ? <WButton
                            buttontext={consts.addAnotherBeneficiary}
                            onClick={this.handleAddAnotherBeneficiary}
                            dataAnalyticId={BENEFICIARIES_ADD_ANOTHER_BUTTON_ID}
                        /> 
                        : null}
                        <WButton
                            buttontext={addButtonText}
                            onClick={this.handleSubmit}
                            disabled={!shouldEnableAddButton && !updatableBeneficiary}
                            dataAnalyticId={BENEFICIARIES_ADD_BUTTON_ID}
                        />
                    </div>
                </Container>
            </div>

        );
    }
}
AddBeneficiaryForm.propTypes = {
    benType: PropTypes.string,
    cancelAddBeneficiary: PropTypes.func,
    handleSubmitNewBeneficiary: PropTypes.func,
    updatableBeneficiary: PropTypes.object,
    handleEditFormClosed: PropTypes.func,
    forwardRef: PropTypes.instanceOf(Object)
};

AddBeneficiaryForm.defaultProps = {
    savedState: {},
    isChild: false,
    cancelAddBeneficiary: () => { },
    handleSubmitNewBeneficiary: () => { },
    updatableBeneficiary: {},
    handleEditFormClosed: () => { }
};
export default AddBeneficiaryForm;
