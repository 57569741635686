import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../Box';
import ErrorMsg from '../ErrorMsg';
import HintText from '../HintText';

const errorBorder = '2px solid #E60000';

export const StyledBoxForInput = styled(Box)`
    &&& {
        display: block;
        width: 100%;
        padding: 15px 20px;
        appearance: none;
        font-size: inherit;
        line-height: inherit;
        border-radius: 1px;
        color: inherit;
        background: ${props => (props.disabled ? '#FAFAFA' : '#FFFFFF')} 0% 0% no-repeat padding-box;
        border: ${props => (props.error ? errorBorder : '1px solid #D2D2D2')};
        opacity: 1;
        &:hover {
            border: ${props => (props.error ? errorBorder : '1px solid #486d90')};
        }
        &:focus {
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
            border: ${props => (props.error ? errorBorder : '1px solid #004a98')};
        }
    }
`;

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const InputText = React.forwardRef((props, ref) => {
    const { error, errorTxt, instructionText, srInstructionText, id, ...restProps } = props;
    const hintId = `${id}-hint`;
    const errorId = `${id}-error`;

    const describedBy = [];
    if (instructionText) describedBy.push(hintId);
    if (error) describedBy.push(errorId);
    const addtionalProps = {};
    if (describedBy.length) addtionalProps['aria-describedby'] = describedBy.join(' ');

    return (
        <FlexDiv>
            <StyledBoxForInput
                ref={ref}
                as="input"
                type="text"
                id={id}
                error={error}
                aria-invalid={error}
                {...restProps}
                {...addtionalProps}
            />
            {!!instructionText && (
                <HintText id={hintId} text={instructionText} srText={srInstructionText} />
            )}
            {error && (
                <ErrorMsg id={errorId} role="alert" aria-atomic="true" className="analytics-error-field">
                    {errorTxt}
                </ErrorMsg>
            )}
        </FlexDiv>
    );
});

InputText.propTypes = {
    id: PropTypes.string,
    error: PropTypes.bool,
    errorTxt: PropTypes.string,
    instructionText: PropTypes.string,
    srInstructionText: PropTypes.string,
};

InputText.defaultProps = {
    id: '',
    error: false,
    errorTxt: '',
    instructionText: '',
    srInstructionText: '',
};

export default InputText;
