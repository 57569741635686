import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
    setCountryCode,
    setPhoneNumber,
    setIsMobile,
    setPhoneNumberError,
    updateProfileRelationship,
    clearRelationActions
} from 'shared/Actions/ProfileRelationAction';
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import {
    formatPhoneNumberWithoutCountryCode,
    replaceSpecialChar,
} from "utils";
import routeConstants from 'routeConstants';
import { BottomNavBar, FieldValue, InputText, Toggle, InstructionalPanel, PageTitle } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeFormFieldError, analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import {
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const PHONE_NUMBER_ERROR_2 = "Invalid Phone Number";

export const handleCountryCodeChange = (dispatch, action) => (e)=>{
    const { target } = e;
    let { value } = target;
    if(value && value.length > 3) value = value.slice(0, -1);
    dispatch(action(value.replace(/\D/,'')));
}

export const handleCountryCodeBlur = (dispatch, action) => (e) => {
    const { value } = e.target;
    const countryCode = value ? `+${value}` : '';
    dispatch(action(countryCode));
}

export const removeNonNumberValues = (dispatch, action) => (e) => {
    const { value } = e.target;
    dispatch(action(value.replace(/\D/g,'')));
}

export const handleIsMobileToggle = (dispatch, action) => (value) => {
    dispatch(action(value));
}

export const handlePhoneNumberBlur = (dispatch, errAction, phoneAction) => (e) => {
    const { target } = e;
    const { value } = target;
    let error = '';
    let formatedPhone = value;
    if(value.length < 10) {
        error = PHONE_NUMBER_ERROR_2;
    }else {
        formatedPhone = formatPhoneNumberWithoutCountryCode(value);
    }
    if(error.length>0){
        dispatch(analyzeFormFieldError())
    }
    dispatch(errAction(error));
    dispatch(phoneAction(formatedPhone));
}

export const savePhoneInfo = (dispatch, data) => (e) => {
        e.preventDefault();
        const { countryCode='', phoneNumber='', isMobile=false, isPrimary=false, relationshipId='' } = data;
        const phoneData = {
            phoneNumber: replaceSpecialChar(phoneNumber),
            phoneCountryCode: countryCode,
            isMobile,
            isPrimary,
        };
        const payload = {
            role : "Family",
            relationshipId,
            phoneUpdate: [{...phoneData}],
        };
        dispatch(updateProfileRelationship(payload));
}

const PAGE_NAME_PHONE_ADD = 'Add Phone Number';
const PAGE_NAME_PHONE_EDIT = 'Edit Phone Number';
const BREADCRUM_ITEMS_ADD_OR_EDIT = [
    {
        url: '/Profile', 
        name:'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationshipphonenumber_breadcrumb_profile'
    }, 
    {
        url: '/basicinformation', 
        name: 'Basic Information',
        dataAnalyticId:'cms_basicinformation_managerelationshipphonenumber_breadcrumb_basicinfo'
    },
    {
        url: '/ManageRelations',
        name: 'Manage Relationship',
        dataAnalyticId:'cms_basicinformation_managerelationshipphonenumber_breadcrumb_managerelationship'
    },
    { 
        url: '/managerelationphone', 
        name: 'Manage Phone Information',
        dataAnalyticId:'cms_basicinformation_managerelationshipphonenumber_breadcrumb_managephoneinfo'
    }
];
const PHONE_NUMBER = "Phone Number";

const PHONE_NOTE_PRIMARY = "Note: You are making the changes for Primary Phone Number";
const PHONE_NOTE_SECONDARY = "Note: You are making the changes for Secondary Phone Number";

const PHONE_IS_MOBILE = "Is this is your mobile number?";

const COUNTRY_HINT_TEXT = 'Country';
const PHONE_HINT_TEXT = '(000)-000-0000';

export const cancelPhoneInfo = (dispatch, history, isEdit) => () => {
    dispatch(analyzeClickEvent({
        link: `cms_basicinformation_manageRelationshipphone_${isEdit?'edit':'add'}phonenumber_cancel`
    }))
    history.push(routeConstants.manageRelationPhone);
};

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;
const RelationPhone = (props) => {
    const { isEdit } = props;
	const history = useHistory();
    const dispatch = useDispatch();
	
	const familyMemberIndex = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyMemberIndex: familyMemberIndexVal } = profileRelationshipData;
        return familyMemberIndexVal;
    });
    
    const familyRelationShipInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyRelationShipInfo:  familyRelationShipInfoVal } = profileRelationshipData;
        return familyRelationShipInfoVal;
    });
    
    const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
    const { relationshipId='' } = familyMemberInfo;
    
    const phoneInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { relationPhoneInformation } = profileRelationshipData;
        return relationPhoneInformation;
    });
     
    const { countryCode, phoneNumber, isMobile, isPrimary } = phoneInfo;
    
    
    useEffect(()=>{
        if( isEdit && familyMemberIndex === -1 ){
            history.push(routeConstants.basicinformation);
        }
    },[familyMemberIndex, history, isEdit]);
    
    const isError = useSelector(({profileRelationshipData: {isError: apiError}}) => apiError);
    const isSuccess = useSelector(({profileRelationshipData: {isSuccess: apiSuccess}}) => apiSuccess);
    const isLoading = useSelector(({profileRelationshipData: {isLoading: apiLoading}}) => apiLoading);
    
    useEffect(() => {
        if(isSuccess){
            dispatch(analyzeClickEvent({
                link: `cms_basicinformation_managerelationshipphoneInfo_save`
            }))
            history.push(routeConstants.manageRelationPhone);
        }
        if(isError) {
            const errorTxt = getErrorMessage(isError);
            dispatch(analyzeClickEvent({
                link: `cms_basicinformation_managerelationshipphoneInfo_save`
            }))
            dispatch(linkResponseError(errorTxt));
            dispatch(clearRelationActions());
        }
    }, [dispatch, history, isError, isSuccess]);

    useEffect(() => {
        addFormFieldsListner();
        addFormAbandonUnloadListner(isEdit);
        return () => {
            if (!isSuccess) {
                const digitalData = getFormAbandData(isEdit);
                const { fieldlasttouch } = digitalData;
                if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
            }
            removeFormAbandonUnloadListner();
        };
    }, [isEdit, isSuccess])

    const pageName = isEdit ? PAGE_NAME_PHONE_EDIT : PAGE_NAME_PHONE_ADD;

    const statusNotification = withNotification()({
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    });

    primaryBtnProps.onClick = savePhoneInfo(dispatch, {countryCode, phoneNumber, isMobile, isPrimary, relationshipId});
    cancelBtnProps.onClick = cancelPhoneInfo(dispatch, history, isEdit);
    
    return (
        <div>
            {isLoading && (
                <WSpinner loading={isLoading} />
            )}
            {statusNotification}
            <Container>
                <WBreadCrumb
                    breadCrumbItems={BREADCRUM_ITEMS_ADD_OR_EDIT}
                    activeCrumb={pageName}
                />
                <PageTitle title={pageName} showMandatoryTxt />
                    <FieldValue
                        id="vcm-cms-relation-phone-number"
                        label={PHONE_NUMBER}
                        value={
                            <Row role="group" aria-labelledby="vcm-cms-relation-phone-number" data-name={PHONE_NUMBER} >
                                <Col xs={4}>
                                    <InputText
                                        id="vcm-cms-phone-country-code"
                                        onFocus={removeNonNumberValues(dispatch, setCountryCode)}
                                        onChange={handleCountryCodeChange(dispatch, setCountryCode)}
                                        onBlur={handleCountryCodeBlur(dispatch, setCountryCode)}
                                        maxLength={4}
                                        value={countryCode}
                                        instructionText={COUNTRY_HINT_TEXT}
                                        data-form-field
				                        data-group-field
                                    />
                                </Col>
                                <Col>
                                    <InputText
                                        id="vcm-cms-phone-number"
                                        maxLength={15}
                                        onFocus={removeNonNumberValues(dispatch, setPhoneNumber)}
                                        onChange={removeNonNumberValues(dispatch, setPhoneNumber)}
                                        value={phoneNumber}
                                        onBlur={handlePhoneNumberBlur(dispatch, setPhoneNumberError, setPhoneNumber)}
                                        instructionText={PHONE_HINT_TEXT}
                                        data-form-field
				                        data-group-field
                                    />
                                </Col>
                            </Row>
                        }
                        mb={2}
                        labelsm={3}
                        valuesm={5}
                    />
                    <FieldValue
                        id="vcm-cms-phone-is-mobile"
                        label={PHONE_IS_MOBILE}
                        value={
                            <Toggle
                                id="vcm-cms-relation-phone-is-mobile"
                                aria-labelledby="vcm-cms-phone-is-mobile"
                                onChange={handleIsMobileToggle(dispatch, setIsMobile)} 
                                toggleOn={isMobile}
                                data-name={PHONE_IS_MOBILE}
                                data-form-field
                            />
                        }
                        mb={2}
                        labelsm={3}
                        valuesm={5}
                    />
                    <InstructionalPanel>{ isPrimary ? PHONE_NOTE_PRIMARY : PHONE_NOTE_SECONDARY}</InstructionalPanel>
                    </Container>
            <BottomNavBar
                hideBack
                showCancelBtn
                primaryBtnText={CONST.SAVE}
                primaryBtnProps={primaryBtnProps}
                cancelBtnProps={cancelBtnProps}
                hidePrimaryBtn = {hideFlag}
            />
        </div>
    );
}

RelationPhone.propTypes = {
    isEdit: PropTypes.bool,
};

RelationPhone.defaultProps = {
    isEdit: false,
};

export default RelationPhone;