import routeConstants from "routeConstants";
import {
    CARD_BANK_ACCOUNTS_CLICK,
    CARD_DIVIDENDS_CLICK,
    CARD_AIP_CLICK,
    CARD_SWP_CLICK,
    CARD_CHECKBOOK_CLICK,
    CARD_BENEFICIARIES_CLICK
} from "utils/AdobeAnalytics/Constants";

export const SKIP_TO_MAIN = 'skipToMain'; // used to focus the first element on the page
export const AIP = 'AIP';
export const KEY_CODE = {
    ESCAPE: 27,
    TAB: 9
};

export const RECURRING_TRANSFER_PLAN = {
    AIP: 'automaticInvestment',
    SWP: 'systematicWithdrawal',
    RMD: 'requiredMinimumDistribution'
};

export const VIEW_LIST_PATH_BY_PLAN = {
    [RECURRING_TRANSFER_PLAN.AIP]: routeConstants.automaticinvestment,
    [RECURRING_TRANSFER_PLAN.SWP]: routeConstants.systematicWithdrawal
}

export const GET_ACCOUNT_LIST_CONFIG_KEY_BY_PLAN = {
    automaticInvestment: 'getAIPAccountList',
    systematicWithdrawal: 'getSWPAccountList'
};

export const PAY_OPTIONS = {
    BANK: '',
    DIRECT_SWP: 'directedSWP',
    CHECK_ORDER: 'checkOrder'
}

export const WITHDRAWAL_FROM = {
    label: 'Withdrawal From',
    totalDistributionAmount: 'Total Distribution Amount',
    IRSMandated: 'IRS Mandated (Based on prior year balance held at Victory Capital)',
    priorYearEndBalance: `${new Date().getFullYear()-1} Year-end Balance`,
    currentValue: 'Current Value',
    balance: 'Balance'
};

export const APPLY_TO_ALL = 'applyToAll';
export const APPLY_TO_ONE_OR_MORE = 'applyToOneOrMore';
export const APPLY_TO_ONE_OR_MORE_VAL = 'Apply to one or more funds of my choice';
export const DISTRIBUTION_OPTIONS = [{
    key: APPLY_TO_ALL,
    label: 'Apply proportionately across all of my funds'
    }, {
    key: APPLY_TO_ONE_OR_MORE,
    label: 'Apply to one or more funds of my choice'
}];

export const DISTRIBUTION_CONFIRMATION = {
    common: 'Are you sure you want to apply the distribution',
    applyToAll: 'proportionately across all of your funds?',
    applyToOne: 'to one or more funds of your choice?'
}

export const RENEWAL_OPTIONS = [
    {
        key: 'Y',
        label: 'Yes'
    },
    {
        key: 'N',
        label: 'No'
    }
];

const getMaxDate = () => {
    const currentDate = (new Date()).getFullYear();
    return new Date(`${currentDate}-12-31`);
}

export const RMD_MAX_START_DATE = getMaxDate();
export const LOCALE = 'en-US'; // Used for the date picker

export const RMD_FREQUENCY = [
    { key: 'B', value: 'Biweekly' },
    { key: 'M', value: 'Monthly' },
    { key: 'Q', value: 'Quarterly' },
    { key: 'T', value: 'Semi-Annually' },
    { key: 'A', value: 'Annually' },
];

export const RMD_STEPS = [
    { key: 0, text: 'Start' },
    { key: 1, text: 'Select an Account' },
    { key: 2, text: 'Provide Information' },
    { key: 3, text: 'Select a Fund' },
    { key: 4, text: 'Tax Withholding & Deposit Account' },
    { key: 5, text: 'Review & Complete' }
];

const BANK_ACCOUNT_TEXT  = 'Bank Account Type';
export const AIP_TEXT  = 'Automatic Investment Plan';
export const SWP_TEXT = 'Automatic Withdrawal/Exchange Plan';
const RMD_TEXT = 'Required Minimum Distribution';
const CURRENT_VAL_TEXT = 'Current Value';
const ACC_NAME1_TEXT = 'Account Name 1';
const ACC_NAME2_TEXT = 'Account Name 2';
const ACC_NAME3_TEXT = 'Account Name 3';
const ACCOUNT_NUMBER_TEXT = '9787-2345-8976';
const ACCOUNT_NUMBER2_TEXT = '2344-6547-2344';
const ACCOUNT_NUMBER3_TEXT = '4232-7856-1656';
const IRA_ACC_TEXT = 'IRA Account';
const UTMA_ACC_TEXT = 'UTMA Account';
const GENERAL_INV_ACC = 'General Account';
const BROKERAGE_TEXT = 'Brokerage Core';
const SETUP_DATE= '02/11/2020';
const INITIALIZED_DATE = '03/11/2020';
const NEXT_SCHEDULE_DATE = '05/11/2020'
const END_DATE = '11/15/2030';
const START_DATE_TEXT = 'Start Date';
const CREATOIN_DATE_TEXT = 'Creation Date';
const ACC_DESC_TEXT = "There are two parts for a recurring transfer - bank to fund within an IRA. The first is the contribution to the acoount and the second is purchase of the mutual fund(s). If you're making a change, be sure you adjust both parts.";
const BANK_ACC1_TEXT = 'Bank Account 1';
const TOTAL_AMT_TEXT = 'Total Amount';
const DELIVERY_ADD_TEXT = 'Current address on account';
const ACCOUNT_NAME_TEXT = 'Account Name';
const ACCOUNT_NUM_TEXT = 'Account Number';
const FUND_KEY_VALUE = '52 week Min. / Max. Values';
const FUND_KEY_VALUE2 = 'Last NAV (Previous day close)';
const FUND_KEY_VALUE3 = 'Change in NAV';
const FUND_KEY_VALUE4 = 'Min. / Max. Amount w/ Auto Investing';
const FUND_VALUES = '$ 3000 / $ 5000';
const FUND_VALUES2 = '$ 3000 / $ 4000';
const FUND_VALUES3 = ' $ 3000 / $ 4000 w/ $ 0';
const GROWTH_INCOME_TEXT = 'USAA Growth & Income Adviser';
const INTERMEDIATE_TERM_TEXT = 'USAA Intermediate-Term Bond Adviser';
const INCEPTION_DATE_TEXT = '01/01/2001';
const AS_OF_DATE_TEXT = '11/30/2019';
const SCIENCE_TECHNO_TEXT = 'USAA Science & Technology Adviser';
const ORDER_CHECKBOOK_TEXT = 'Reorder Checkbook';
const ELIGIBLE_FUNDS_TEXT = 'Eligible Funds';
const LAST_REQUEST_DATE = 'Last Requested Date';
const SHARES_TEXT_300 = 'USSPX VCM 300 INDEX FUND MEMBER CLASS SHARES';
const SHARES_TEXT_500 = 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES';
const TRANSIT_ROUTING_NUM = 'Transit Routing Number';
const DIVIDEND_TITLE = 'Dividends and Capital Gains Preferences';
const HOLDING= 'Holding';
const WEEKEND_NOTE = 'Note : If the day you selected falls on a weekend or holiday, your transfer will occur the next business day.';
const SCHEDULE_INFO_MESSAGE = "Note: Dates that fall on a weekend or a New York Stock Exchange (NYSE) holiday will be adjusted to the next day on which the NYSE is open.";
const SCHEDULE_WEEKEND_INFO = ''
const NEXT_WITHDRAWAL_DATE = 'Next Withdrawal Date'

const constant = {
    bankAccountList: {
        bankAccountNumberText: ACCOUNT_NUM_TEXT,
        bankRoutingNumberText: TRANSIT_ROUTING_NUM,
        bankAccountSWPText: SWP_TEXT,
        bankAccountAIPText: AIP_TEXT,
        bankAccountTypeText: BANK_ACCOUNT_TEXT,
        yes: 'Yes',
        no: 'No',
        noBankAccounts: 'No bank accounts.'
    },
    pageHeading: 'Account Services',
    passErrorText:'Please enter your Password.',
    cardDetails: [
        {
            cardTitleText: 'Bank Accounts',
            cardText: "Add a bank account to use for funding your investments.",
            url: 'bankaccounts',
            dataAnalyticsId: CARD_BANK_ACCOUNTS_CLICK,
            waitforapiresponse: true
        },
        {
            cardTitleText: AIP_TEXT,
            cardText: "Add or edit an automatic investment plan to achieve your investment goals a little at a time.",
            url: 'automaticinvestment',
            dataAnalyticsId: CARD_AIP_CLICK,
            waitforapiresponse: true
        },
        {
            cardTitleText: SWP_TEXT,
            cardText: "Add or edit an automatic withdrawal/exchange plan to take periodic disbursements from your investment accounts.",
            url: 'systematicwithdrawal',
            dataAnalyticsId: CARD_SWP_CLICK,
            waitforapiresponse: true
        },
        // Commenting for now - Day 2 feature
        {
            cardTitleText: 'Manage Beneficiaries',
            cardText: "Make changes to existing beneficiaries, or add new beneficiaries to your accounts.",
            url: 'manageBeneficiariesAccounts',
            dataAnalyticsId: CARD_BENEFICIARIES_CLICK,
            waitforapiresponse: true
        },
        {
            cardTitleText: DIVIDEND_TITLE,
            cardText: "Manage your preference for how you would like to receive Dividend and Capital Gain distributions.",
            url: 'dividends',
            dataAnalyticsId:CARD_DIVIDENDS_CLICK,
            waitforapiresponse: true
        },
        {
            cardTitleText: 'Checkbook',
            cardText: "Reorder checkbooks for certain types of Mutual Fund Accounts.",
            url: 'checkbook',
            dataAnalyticsId: CARD_CHECKBOOK_CLICK,
            waitforapiresponse: true
        },
        {
            cardTitleText: 'Required Minimum Distribution',
            cardText: "Review your active Required Minimum Distribution (RMD) or learn more about RMDs.",
            url: 'requiredMinimumDistribution',
            waitforapiresponse: false
        }
    ],
    symbols: {
        dollar: '$',
        percent: '%',
    },
    AIP: {
        pageHeading: AIP_TEXT,
        pageHeading1: 'Add Automatic Investment Plan',
        noPlansText: 'No Existing Automatic Investment Plans',
        noPlanDesc: 'Add or edit a Recurring Transfer to achieve your investment goals periodically',
        deleteModalTitle: 'Delete Automatic Plan',
    },
    EDIT_AIP: {
        pageHeading: AIP_TEXT,
        pageHeading1: 'Edit Automatic Investment Plan',
        noPlansText: 'No Existing Automatic Investment Plans',
        noPlanDesc: 'Add or edit a Recurring Transfer to achieve your investment goals periodically',
        deleteModalTitle: 'Delete Automatic Plan',
    },
    SWP: {
        pageHeading: SWP_TEXT,
        pageHeading1: 'Add Automatic Withdrawal/Exchange Plan',
        noPlansText: 'No Existing Automatic Withdrawal/Exchange Plans',
        deleteModalTitle: 'Delete Automatic Plan',
    },
    EDIT_SWP: {
        pageHeading: SWP_TEXT,
        pageHeading1: 'Edit Automatic Withdrawal/Exchange Plan',
        noPlansText: 'No Automatic Withdrawal/Exchange Plans',
        deleteModalTitle: 'Delete Automatic Plan',
    },
    RMD: {
        pageHeading: RMD_TEXT,
        pageHeading1: 'Add Required Minimum Distribution',
        noPlansText: 'No Required Minimum Distribution',
        deleteModalTitle: 'Delete Required Minimum Distribution Plan',
    },
    EDIT_RMD: {
        pageHeading: RMD_TEXT,
        pageHeading1: 'Edit Required Minimum Distribution',
        noPlansText: 'No Required Minimum Distribution',
        deleteModalTitle: 'Delete Required Minimum Distribution Plan',
    },
    frequency: {
        automaticInvestment: {
            A: 'Annually',
            B: 'BiMonthly',
            M: 'Monthly',
            Q: 'Quarterly',
            S: 'Biweekly',
            W: 'Weekly',
            N: 'Non Standard'
        },
        systematicWithdrawal: {
            W: 'Weekly',
            B: 'Biweekly',
            M: 'Monthly',
            P: 'BiMonthly',
            Q: 'Quarterly',
            T: 'Semiannually',
            A: 'Annually',
            N: 'Non Standard'
        },
        requiredMinimumDistribution: {
            W: 'Weekly',
            B: 'Biweekly',
            M: 'Monthly',
            P: 'BiMonthly',
            Q: 'Quarterly',
            T: 'Semiannually',
            A: 'Annually',
            N: 'Non Standard'
        }
    },
    frequencyNoByYear: {
        W: 52,
        B: 26,
        M: 12,
        P: 6,
        Q: 4,
        T: 2,
        A: 1
    },
    requiredfield: '* All fields are required except mentioned optional',
    addText: 'Add',
    skipText: 'Skip',
    buttonText: {
        next: 'Next',
        back: 'Back',
        submit: 'Submit',
        cancel: 'Cancel',
        proceed: 'Proceed'
    },
    menuOptions: ['Edit', 'Delete'],
    instructionsTitle: 'Instructions to Setup and Manage Automatic Mutual Fund Purchases',
    instructionSubheading: 'Setup and Manage Automatic Mutual Fund Purchases',
    instructionPara1:
        'When you make a habit of investing regularly, it can make it easier to achieve your financial goals. Get started in three easy steps',
    instructionPara2:
        'There are no fees to setup an automatic investment  and if your plans change, you can cancel at any time.',
    instructionList: [
        'Choose your VCM Investment account',
        'Enter an amount of $50 or more',
        'Select how often you want to invest',
    ],
    SWPinstructionsTitle: 'Instructions',
    SWPinstructionSubheading: 'Manage Automatic Withdrawal/Exchange Plan',
    SWPinstructionPara1:
        `Setup and manage your Automatic Withdrawal/Exchange Plan`,
    SWPinstructionList: [
        'Choose your Fund',
        `Enter an amount of $50 or more.`,
        'Select frequency of transfer',
    ],
    SWPinstructionPara2:
        `There are no fees to set up an automatic investment and you can change the amount at any time.`,
    deleteModalDeleteText: 'DELETE',
    deleteModalCancelText: 'CANCEL',
    loading: 'Loading',
    accountsAvailableForAIP: [
        {
            accountType: GENERAL_INV_ACC,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: ACC_NAME2_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER2_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: ACC_NAME3_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: IRA_ACC_TEXT,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: UTMA_ACC_TEXT,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
    ],
    accountsAvailableForSWP: [
        {
            accountType: GENERAL_INV_ACC,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: SWP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: ACC_NAME2_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER2_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: SWP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: ACC_NAME3_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: SWP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: IRA_ACC_TEXT,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: SWP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: UTMA_ACC_TEXT,
            accounts: [
                {
                    accName: ACC_NAME1_TEXT,
                    accNumber: {
                        text: ACCOUNT_NUM_TEXT,
                        value: ACCOUNT_NUMBER3_TEXT,
                    },
                    currentVal: {
                        text: CURRENT_VAL_TEXT,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: SWP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
    ],
    accountsAvailableForSWPNew: {
        currentValTxt: CURRENT_VAL_TEXT,
        holdingTxt: 'Holding',
        planNameTxt: SWP_TEXT,
        accountTypeTxt: {
            general: GENERAL_INV_ACC,
            ira: IRA_ACC_TEXT,
            utmaOrUgma: UTMA_ACC_TEXT,
        },
    },
    accountsAvailableForAIPNew: {
        currentValTxt: CURRENT_VAL_TEXT,
        holdingTxt: 'Holding',
        planNameTxt: AIP_TEXT,
        accountTypeTxt: {
            general: GENERAL_INV_ACC,
            ira: IRA_ACC_TEXT,
            utmaOrUgma: UTMA_ACC_TEXT,
        },
    },

    addedAccountsForAIPView: [
        {
            accountType: GENERAL_INV_ACC,
            accounts: [
                {
                    accountName: ACC_NAME1_TEXT,
                    masterRegId: '766733452344',
                    details: [
                        {
                            payeeBankName: BROKERAGE_TEXT,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME1_TEXT,
                            'accountNumber(masterRegId)': '766733452344',
                            share: {
                                fundName: SHARES_TEXT_500,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                        {
                            payeeBankName: `${BROKERAGE_TEXT} - 2`,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME1_TEXT,
                            'accountNumber(masterRegId)': '766733452344',
                            share: {
                                fundName: `${SHARES_TEXT_500} - 2`,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                        {
                            payeeBankName: `${BROKERAGE_TEXT} - 3`,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME1_TEXT,
                            'accountNumber(masterRegId)': '766733452344',
                            share: {
                                fundName: `${SHARES_TEXT_500} - 3`,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        }]
                },
                {
                    accountName: ACC_NAME2_TEXT,
                    'accountNumber(masterRegId)': '213389651234',
                    details: [
                        {
                            payeeBankName: `${BROKERAGE_TEXT} - 11`,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME2_TEXT,
                            'accountNumber(masterRegId)': '213389651234',
                            share: {
                                fundName: `${SHARES_TEXT_500} - 11`,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                        {
                            payeeBankName: `${BROKERAGE_TEXT} - 12`,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME2_TEXT,
                            'accountNumber(masterRegId)': '213389651234',
                            share: {
                                fundName: `${SHARES_TEXT_500} - 12`,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                    ]
                }
            ],
        },
        {
            accountType: IRA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    details: [
                        {
                            payeeBankName: BROKERAGE_TEXT,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME1_TEXT,
                            'accountNumber(masterRegId)': '423278561656',
                            share: {
                                fundName: SHARES_TEXT_500,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                    ]
                }
            ],
        },
        {
            accountType: UTMA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    details: [
                        {
                            payeeBankName: BROKERAGE_TEXT,
                            schedule: 'Q',
                            datePADSetup: SETUP_DATE,
                            datePADInitialized: INITIALIZED_DATE,
                            investedFundAmount: 50,
                            nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                            suspendedFromDate: '',
                            suspendedToDate: '',
                            accountName: ACC_NAME1_TEXT,
                            'accountNumber(masterRegId)': '423278561656',
                            share: {
                                fundName: SHARES_TEXT_500,
                                shortFundName: 'ISP',
                            },
                            endDate: 'Never',
                        },
                    ]
                }
            ],
        },
    ],

    addedAccountsForAIP: [
        {
            accountType: GENERAL_INV_ACC,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    datePADSetup: SETUP_DATE,
                    datePADInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '766733452344',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: 'Never',
                },
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    datePADSetup: SETUP_DATE,
                    datePADInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME2_TEXT,
                    'accountNumber(masterRegId)': '213389651234',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: 'Never',
                },
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    datePADSetup: SETUP_DATE,
                    datePADInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME3_TEXT,
                    'accountNumber(masterRegId)': '890032349875',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: 'Never',
                },
            ],
        },
        {
            accountType: IRA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    datePADSetup: SETUP_DATE,
                    datePADInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: 'Never',
                },
            ],
        },
        {
            accountType: UTMA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    datePADSetup: SETUP_DATE,
                    datePADInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextSchedulePADDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: 'Never',
                },
            ],
        },
    ],
    addedAccountsForSWP: [
        {
            accountType: GENERAL_INV_ACC,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    dateSWPSetup: SETUP_DATE,
                    dateSWPInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextScheduleSWPDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '766733452344',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: END_DATE,
                },
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    dateSWPSetup: SETUP_DATE,
                    dateSWPInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextScheduleSWPDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME2_TEXT,
                    'accountNumber(masterRegId)': '213389651234',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: END_DATE,
                },
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    dateSWPSetup: SETUP_DATE,
                    dateSWPInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextScheduleSWPDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME3_TEXT,
                    'accountNumber(masterRegId)': '890032349875',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: END_DATE,
                },
            ],
        },
        {
            accountType: IRA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    dateSWPSetup: SETUP_DATE,
                    dateSWPInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextScheduleSWPDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: END_DATE,
                },
            ],
        },
        {
            accountType: UTMA_ACC_TEXT,
            // accountDesc:
            //     ACC_DESC_TEXT,
            accounts: [
                {
                    payeeBankNam: BROKERAGE_TEXT,
                    schedule: 'Q',
                    dateSWPSetup: SETUP_DATE,
                    dateSWPInitialized: INITIALIZED_DATE,
                    investedFundAmount: 50,
                    nextScheduleSWPDate: NEXT_SCHEDULE_DATE,
                    suspendedFromDate: '',
                    suspendedToDate: '',
                    accountName: ACC_NAME1_TEXT,
                    'accountNumber(masterRegId)': '423278561656',
                    share: {
                        text: SHARES_TEXT_500,
                        value: '5',
                    },
                    endDate: END_DATE,
                },
            ],
        },
    ],
    addedAccountsForAIPNew: {
        // accountDesc: {
        //     general:
        //         ACC_DESC_TEXT,
        //     ira:
        //         ACC_DESC_TEXT,
        //     utmaOrUgma:
        //         ACC_DESC_TEXT,
        // },
        dateAddedTxt: 'Date added :',
        skipDateTxt: 'Skip Date : ',
        fundSourceTxt: 'Bank Account',
        freqTxt: 'Schedule',
        onTheDayTxt: CREATOIN_DATE_TEXT,
        amtTxt: 'Amount',
        nxtTxt: 'Next Investment',
    },
    addedAccountsForSWPNew: {
        accountDesc: {
            general:
                ACC_DESC_TEXT,
            ira:
                ACC_DESC_TEXT,
            utmaOrUgma:
                ACC_DESC_TEXT,
        },
        dateAddedTxt: 'Date added :',
        skipDateTxt: 'Skip Date : ',
        fundSourceTxt: 'Fund To',
        freqTxt: 'Frequency',
        onTheDayTxt: CREATOIN_DATE_TEXT,
        amtTxt: 'Amount',
        nxtTxt: 'Next Withdrawal',
    },
    PlanDetailsAIPContent: {
        fundFrom: {
            detailName: 'Fund From',
            titleTxt: 'Withdrawal From',
            description: 'Choose how you will fund your account and indicate your investment amount.',
            accverificationmessage:
                'For your account’s security, you will receive a call momentarily to authenticate yourself.',
            methods: [
                {
                    title: '',
                    desc: '',
                    method: 'online',
                    details: [
                        {
                            accName: BANK_ACC1_TEXT,
                            accNumber: 'XXX-Xxx-3838',
                        },
                        {
                            accName: 'Bank Account 2',
                            accNumber: 'XXX-Xxx-5247',
                        },
                        {
                            accName: 'Bank Account 3',
                            accNumber: 'XXX-Xxx-3869',
                        },
                    ],
                },
            ],
        },
        investTo: {
            detailName: 'Invest To',
            amtText: 'Amount',
            minAmtTxt: 'Min $',
            titles: {
                column1: TOTAL_AMT_TEXT,
                column2: 'In',
                column3: 'Amount Utilized',
                column4: 'Amount Unutilized',
                column3Alt: 'Percent Utilized',
                column4Alt: 'Percent Unutilized',
            },
            symbols: {
                dollar: '$',
                percent: '%',
            },
            shares: [
                {
                    fundName: SHARES_TEXT_500,
                    monthlyInvestment: '50',
                },
                {
                    fundName: 'Sample Content 2 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    monthlyInvestment: '50',
                },
                {
                    fundName: 'Sample Content 3 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    monthlyInvestment: '50',
                },
            ],
        },
    },
    PlanDetailsSWPContent: {
        fundFrom: {
            detailName: 'Fund To',
            titleTxt: 'Fund To',
            description: 'Choose how you will receive your fund.',
            orSeperator: '',
            accverificationmessage:
                'For your account’s security, you will receive a call momentarily to authenticate yourself.',
            methods: [
                {
                    title: 'Online Method',
                    desc: '',
                    method: 'online',
                    details: [
                        {
                            accName: BANK_ACC1_TEXT,
                            accNumber: 'XXX-Xxx-3838',
                        },
                        {
                            accName: 'Bank Account 2',
                            accNumber: 'XXX-Xxx-5247',
                        },
                        {
                            accName: 'Bank Account 3',
                            accNumber: 'XXX-Xxx-3869',
                        },
                    ],
                },
                {
                    title: 'Offline Method',
                    desc: '',
                    method: 'offline',
                    details: [
                        {
                            text: 'Check',

                        },
                        {
                            text: 'Directed SWPs',
                        },

                    ],
                },
            ],
        },
        Deliveryaddress: {
            detailName: DELIVERY_ADD_TEXT,
            description: 'Please select and enter the valid address details.',
            addressOptions: [
                { key: 'currentAddress', value: 'Current mailing address' },
                { key: 'newAddress', value: 'Payee and address' },
            ],
            payeeTxt: 'Payee Name',
        },
        investTo: {
            detailName: 'Withdrawal From',
            titleTxt: 'Withdrawal From',
            amtText: 'Amount',
            minAmtTxt: 'Min $',
            maxAmtTxt: 'Max $',
            titles: {
                column1: TOTAL_AMT_TEXT,
                column2: 'In',
                column3: 'Amount Utilized',
                column4: 'Amount Unutilized',
                column3Alt: 'Percent Utilized',
                column4Alt: 'Percent Unutilized',
            },
            symbols: {
                dollar: '$',
                percent: '%',
            },
            shares: [
                {
                    fundName: SHARES_TEXT_500,
                    minMonthlyInvestment: '50',
                    maxMonthlyInvestment: '10000',
                },
                {
                    fundName: 'Sample Content 2 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    minMonthlyInvestment: '50',
                    maxMonthlyInvestment: '10000',
                },
                {
                    fundName: 'Sample Content 3 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    minMonthlyInvestment: '50',
                    maxMonthlyInvestment: '10000',
                },
            ],
            key: 'fund_source',
        },
        offlineFundDetails: [
            {
                key: "Check",
                text: 'Check',
            }
        ],
    },
    PlanDetailsRMDContent: {
        fundFrom: {
            detailName: 'Deposit Account',
            description: '',
            orSeperator: '[or]',
            methods: [
                {
                    title: 'Online Method',
                    desc: '',
                    method: 'online',
                    details: [
                        {
                            accName: "BANK_ACC1_TEXT",
                            accNumber: 'XXX-Xxx-3838',
                        },
                        {
                            accName: 'Bank Account 2',
                            accNumber: 'XXX-Xxx-5247',
                        },
                        {
                            accName: 'Bank Account 3',
                            accNumber: 'XXX-Xxx-3869',
                        },
                    ],
                },
                {
                    title: 'Offline Method',
                    desc: 'Select one of the below offline payment options.',
                    method: 'offline',
                    details: [
                        {
                            text: 'Check',

                        },
                        {
                            text: 'Directed SWPs',
                        },

                    ],
                },
            ],
        },
        Deliveryaddress: {
            detailName: "Current address on account",
            description: 'Please select and enter the valid address details.',
            addressOptions: [
                { key: 'currentAddress', value: 'Current mailing address' },
                { key: 'newAddress', value: 'Payee and address' },
            ],
            payeeTxt: 'Payee Name',
        },
        investTo: {
            detailName: 'Withdrawal From',
            amtText: 'Amount',
            minAmtTxt: 'Min $',
            titles: {
                column1: "Total Amount",
                column2: 'In',
                column3: 'Amount Utilized',
                column4: 'Amount Unutilized',
                column3Alt: 'Percent Utilized',
                column4Alt: 'Percent Unutilized',
            },
            symbols: {
                dollar: '$',
                percent: '%',
            },
            shares: [
                {
                    fundName: "USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES",
                    monthlyInvestment: '50',
                },
                {
                    fundName: 'Sample Content 2 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    monthlyInvestment: '50',
                },
                {
                    fundName: 'Sample Content 3 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                    monthlyInvestment: '50',
                },
            ],
            key: 'fund_source',
        },
        offlineFundDetails: [
            {
                key: "Check",
                text: 'Check',
            },
            {
                key: "DirectTransfer",
                text: 'Directed Transfer',
            },

        ],
    },
    ScheduleAIPContent: {
        accountName: ACC_NAME1_TEXT,
        accountNumber: 'Account Number xxxx-xxxx-xxxx',
        scheduleTxt: 'Schedule',
        invest: {
            text: 'Investment Frequency',
            value: [
                { key: 'S', value: 'Biweekly' },
                { key: 'M', value: 'Monthly' },
                { key: 'Q', value: 'Quarterly' },
                { key: 'A', value: 'Annually' },
            ],
        },
        onTheDayTxt: START_DATE_TEXT,
        note: SCHEDULE_INFO_MESSAGE,
        noteWeekend: SCHEDULE_WEEKEND_INFO,
        nextWithdrawalTxt: NEXT_WITHDRAWAL_DATE,
        ending: {
            text: 'Ending',
            value: [
                { key: 'Custom date', value: 'Custom date' },
                { key: 'Never', value: 'Never' },
            ],
        },
        endDateTxt: 'End Date',
    },
    ScheduleSWPContent: {
        accountName: ACC_NAME1_TEXT,
        accountNumber: 'Account Number xxxx-xxxx-xxxx',
        scheduleTxt: 'Frequency',
        withdrawalFrequency: {
            text: 'Withdrawal Frequency',
            value: [
                { key: 'B', value: 'Biweekly' },
                { key: 'M', value: 'Monthly' },
                { key: 'Q', value: 'Quarterly' },
                { key: 'T', value: 'Semi-Annually' },
                { key: 'A', value: 'Annually' },
            ],
        },
        note: SCHEDULE_INFO_MESSAGE,
        noteWeekend: SCHEDULE_WEEKEND_INFO,
        days: {
            text: 'Date',
            text2: 'and',
            textday1: 'Day 1',
            textday2: 'Day 2',
        },
        beginOn: {
            labeltxt: "Beginning On",
            monthFormat: 'MM',
            yearFormat: 'YYYY',
        },
        endBy: {
            labeltxt: 'Ending By',
            monthFormat: 'MM',
            yearFormat: 'YYYY',
        },

        nextBy: {
            labeltxt: NEXT_WITHDRAWAL_DATE,
            monthFormat: 'MM',
            yearFormat: 'YYYY',
        },

        onTheDayTxt: 'Beginning On',

        endDateTxt: 'Ending By',

        nextWithdrawalTxt: NEXT_WITHDRAWAL_DATE,

        invest: {
            text: 'Withdrawal Frequency',
            value: [
                { key: 'B', value: 'Biweekly' },
                { key: 'M', value: 'Monthly' },
                { key: 'Q', value: 'Quarterly' },
                { key: 'T', value: 'Semi-Annually' },
                { key: 'A', value: 'Annually' },
            ],
        },
    },
    VerifyAIPContent: {
        sections: [
            {
                titleTxt: 'Account Selection',
                editTxt: 'Edit',
                data: [
                    {
                        field: ACCOUNT_NAME_TEXT,
                        value: ACC_NAME1_TEXT,
                    },
                    {
                        field: ACCOUNT_NUM_TEXT,
                        value: 'XXXX-XXXX-XXXX',
                    },
                ],
            },
            {
                titleTxt: 'Withdrawal From',
                editTxt: 'Edit',
                data: [
                    {
                        field: 'Funding Source',
                        value: BANK_ACC1_TEXT,
                    },
                    {
                        field: ACCOUNT_NUM_TEXT,
                        value: 'XXX-XXX-3838',
                    },
                ],
            },
            {
                titleTxt: 'Invest To',
                editTxt: 'Edit',
                data: [
                    {
                        field: ACCOUNT_NAME_TEXT,
                        value: SHARES_TEXT_500,
                    },
                    {
                        field: TOTAL_AMT_TEXT,
                        value: '$ 300',
                    },
                ],
            },
            {
                titleTxt: 'Schedule',
                editTxt: 'Edit',
                note: WEEKEND_NOTE,
                data: [
                    {
                        field: 'Invest',
                        value: 'Quarterly',
                    },
                    {
                        field: 'Date to Invest',
                        value: '15th of every month',
                    },
                    {
                        field: 'End Date',
                        value: '12/15/2019',
                    },
                    {
                        field: 'Next Investment Date',
                        value: '01/15/2020',
                    },
                ],
            },
        ],
    },
    EConsentAIPContent: {
        sectionTitle: 'E-Consent',
        bodyTitle: 'Documents to Review',
        docName: SHARES_TEXT_500,
        downloadTxt: 'Download',
        docDesc: {
            para:
                'This document contains the information provided by you as part of your Recurring Transfer - Bank to Fund plan, including Terms and Conditions. By selecting "Submit", I agree to the documents and terms above and certify that any information I provided is accurate, up-to-date and complete.',
        },
        agreeTxt: 'I, Agree that I have received, read, understood, and agree to the documents linked below.',
    },
    SWPSelectYourVCMMutualFunds: {
        fundList: [
            {
                fundName: 'Aggressive Growth Fund',
                fundNumber: 330,
                initialInvestment: '7000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: FUND_VALUES3 },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: FUND_VALUES2 },
                    { key: 'Risk:', value: 'High' },
                ],
            },
            {
                fundName: 'Capital Growth Fund',
                fundNumber: 331,
                initialInvestment: '6000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: ' $ 1000 / $ 3000 w/ $ 0' },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: FUND_VALUES },
                    { key: 'Risk:', value: 'Low' },
                ],
            },
            {
                fundName: 'Cornerstone Aggressive Fund',
                fundNumber: 336,
                initialInvestment: '5000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: ' $ 3000 / $ 5000 w/ $ 50' },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: FUND_VALUES },
                    { key: 'Risk:', value: 'High' },
                ],
            },
            {
                fundName: 'Cornerstone Equity Fund',
                fundNumber: 337,
                initialInvestment: '4000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: ' $ 2000 / $ 7000 w/ $ 0' },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: '$ 2000 / $ 7000' },
                    { key: 'Risk:', value: 'Medium' },
                ],
            },
        ],
        additionalFundlist: [
            {
                fundName: 'USAA Short-Term Bond Adviser',
                fundNumber: 336,
                initialInvestment: '3000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: ' $ 1000 / $ 3000 w/ $ 0' },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: '$ 3000 / $ 5000' },
                    { key: 'Risk:', value: 'Low' },
                ],
            },
            {
                fundName: GROWTH_INCOME_TEXT,
                fundNumber: 337,
                initialInvestment: '2000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: FUND_VALUES3 },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: FUND_VALUES2 },
                    { key: 'Risk:', value: 'High' },
                ],
            },
            {
                fundName: 'VCM Dummy Fund',
                fundNumber: 1,
                initialInvestment: '1000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: FUND_KEY_VALUE4, value: FUND_VALUES3 },
                    { key: FUND_KEY_VALUE3, value: '14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 13' },
                    { key: FUND_KEY_VALUE, value: FUND_VALUES2 },
                    { key: 'Risk:', value: 'High' },
                ],
            },
        ],
    },
    SWPFundYourInvestment: {
        heading: 'Fund Your Investments',
        description:
            'Choose your funding options below. For monthly investments, the start day of the month must be a business day and at least 1 business day from today. If the day of the month you select is a holiday, the transaction will occur on the next business day. If you are selecting a monthly investment, please read this important notice.',
        tabelHeading: { column1: 'Fund Name', column2: 'Initial Investment', column3: 'Action' },
        removeTxt: 'Remove',
        totalTxt: 'Total',
        details: [
            {
                fundName: 'Aggressive Growth Fund',
                fundNumber: 330,
                initialInvestment: '3000',
                monthlyInvestment: '0',
                risk: 'High',
                symbol: 'UITBX',
                details: [
                    { key: 'Min. / Max. Amount', value: '$ 300 / $ 5,000' },
                    { key: 'NAV in %', value: '↑ 14.3' },
                    { key: FUND_KEY_VALUE2, value: '$ 143' },
                    { key: FUND_KEY_VALUE, value: '$ 3,000 / $ 5,000' },
                ],
            },
        ],
    },
    SWPSelectedFundList: [
        {
            nav: { asOfDate: '08/05/2019', value: '41.14' },
            fundName: INTERMEDIATE_TERM_TEXT,
            morningstarRating: { asOfDate: '06/30/2019', value: '4' },
            monthlyInvestment: '0',
            symbol: 'UITBX',
            initialInvestment: '3000',
            performanceDetails: {
                totalReturns: {
                    monthly: {
                        '5year': '10.50%',
                        '1year': '-1.20%',
                        sinceInception: '8.8%',
                        '10year': '-12.90%',
                        inceptionDate: INCEPTION_DATE_TEXT,
                        ytd: '15.00%',
                        asOfDate: AS_OF_DATE_TEXT,
                    },
                    quarterly: {
                        '5year': '10.50%',
                        '1year': '-1.20%',
                        sinceInception: '8.8%',
                        '10year': '-12.90%',
                        inceptionDate: INCEPTION_DATE_TEXT,
                        ytd: '15.00%',
                        asOfDate: AS_OF_DATE_TEXT,
                    },
                },
            },
            morningstarCategory: 'Large Growth',
            moreInfoPDFs: [
                {
                    s3Loc: 'dummy',
                    value: 'Full Fund Detail and Important Disclosures (PDF)',
                    key: 'fundDetailDisclosures',
                },
                { s3Loc: 'dummy', value: 'Prospectus (PDF)', key: 'prospectus' },
            ],
            fundType: 'individual',
            fundNumber: 330,
            expenseRatio: { expenseRatio_AR: '1.00', expenseRatio_BR: '1.00', expenseRatio: '1.00' },
            risk: 'High',
            SecYields: { SecYields_7: '1.00', SecYields_30: '1.00', SecYields_WoWaivers: '1.00' },
            fundDescription: INTERMEDIATE_TERM_TEXT,
            categoryFunds: '1234',
        },
        {
            nav: { asOfDate: '08/05/2019', value: '30.56' },
            fundName: SCIENCE_TECHNO_TEXT,
            morningstarRating: { asOfDate: '06/30/2019', value: '5' },
            monthlyInvestment: '0',
            symbol: 'USTCX',
            initialInvestment: '3000',
            performanceDetails: {
                totalReturns: {
                    monthly: {
                        '5year': '11.50%',
                        '1year': '5.67%',
                        sinceInception: '6.9%',
                        '10year': '9.35%',
                        inceptionDate: INCEPTION_DATE_TEXT,
                        ytd: '12.00%',
                        asOfDate: AS_OF_DATE_TEXT,
                    },
                    quarterly: {
                        '5year': '11.50%',
                        '1year': '5.67%',
                        sinceInception: '6.9%',
                        '10year': '9.35%',
                        inceptionDate: INCEPTION_DATE_TEXT,
                        ytd: '12.00%',
                        asOfDate: AS_OF_DATE_TEXT,
                    },
                },
            },
            morningstarCategory: 'Large Growth',
            moreInfoPDFs: [
                {
                    s3Loc: 'dummy',
                    value: 'Full Fund Detail and Important Disclosures (PDF)',
                    key: 'fundDetailDisclosures',
                },
                { s3Loc: 'dummy', value: 'Prospectus (PDF)', key: 'prospectus' },
            ],
            fundType: 'individual',
            fundNumber: 331,
            expenseRatio: { expenseRatio_AR: '1.00', expenseRatio_Br: '1.00', expenseRatio: '1.00' },
            risk: 'High',
            SecYields: { SecYields_7: '1.00', SecYields_30: '1.00', SecYields_WoWaivers: '1.00' },
            fundDescription: SCIENCE_TECHNO_TEXT,
            categoryFunds: '567',
        },
    ],
    SWPTaxWithHoldingOptions: {
        heading: 'Tax Withholding Options',
        description: 'Select your tax withholding option below',
        toggleText: {
            on: 'Withhold Taxes',
            off: 'Do not withhold Taxes',
        },
        symbol: {
            dollar: '$',
            percent: '%',
        },
        dropDownList: [
            {
                value: 'Before Taxes',
                key: 'Before Taxes',
            },
            {
                value: 'After Taxes',
                key: 'After Taxes',
            },
        ],
        txt1: 'Is the requested amount',
        txt2: 'Amount Before Taxes',
        txt3: 'Federal Tax',
        txt4: 'State Tax',
        txt5: 'Total Taxes to be withhold',
        txt6: 'Total you will receive',
        txt7: 'Total Withdrawal',
        txt8: 'Amount',
        txt9: 'Amount After Taxes',
        taxFieldInstruction: '[ % ]',
    },
    skipAIP: {
        detailsSection: {
            key: 'planDetails',
            title: 'Skip Recurring Transfer - Bank to Fund',
            note: WEEKEND_NOTE,
            details: [
                {
                    key: ACCOUNT_NAME_TEXT,
                    value: ACC_NAME1_TEXT,
                },
                {
                    key: 'Fund From',
                    value: 'Bank 1',
                },
                {
                    key: 'Invested In',
                    value: SHARES_TEXT_500,
                },
                {
                    key: TOTAL_AMT_TEXT,
                    value: '$ 300',
                },
                {
                    key: 'Invest',
                    value: 'Quarterly',
                },
                {
                    key: START_DATE_TEXT,
                    value: '12/2019',
                },
                {
                    key: 'End Date',
                    value: '12/15/2025',
                },
            ],
        },
        dateSection: {
            key: 'skipDate',
            title: 'Skip the Investment',
            details: [
                {
                    key: 'Skip from date',
                    id: 'skipFromDate',
                },
                {
                    key: 'Skip to date',
                    id: 'skipToDate',
                },
            ],
        },
    },
    skipSWP: {
        detailsSection: {
            key: 'planDetails',
            title: 'Skip Systematic Withdrawal',
            note: WEEKEND_NOTE,
            details: [
                {
                    key: ACCOUNT_NAME_TEXT,
                    value: ACC_NAME1_TEXT,
                },
                {
                    key: 'Fund To',
                    value: 'Bank 1',
                },
                {
                    key: 'Withdrawal From',
                    value: [
                        SHARES_TEXT_500,
                        SHARES_TEXT_300,
                    ],
                },
                {
                    key: TOTAL_AMT_TEXT,
                    value: '$ 5000',
                },
                {
                    key: 'Withdraw',
                    value: 'Twice a Month',
                },
                {
                    key: START_DATE_TEXT,
                    value: '12/2019',
                },
                {
                    key: 'End Date',
                    value: '12/15/2025',
                },
            ],
        },
        dateSection: {
            key: 'skipDate',
            title: 'Skip the Withdrawal',
            details: [
                {
                    key: 'Skip from date',
                    id: 'skipFromDate',
                },
                {
                    key: 'Skip to date',
                    id: 'skipToDate',
                },
            ],
        },
    },
    /* CheckBook */
    checkbook: {
        headingOne: ORDER_CHECKBOOK_TEXT,
        noPriviledgeTitle1: 'You are not set up to write checks.',
        noPriviledgeTitle2: 'To enable check writing, contact a Member Service Representative',
        title1: `Check writing privileges have not been enabled for your account`,
        title2: 'To enable contact Victory Member Services at',
        loadingText: 'Loading',
        contactNum: '(800) 235-8396',
        titleSub1: 'Please contact Victory Capital Management Service Representative at ',
        titleSub2: ' to enable ',
        titleSub3: 'Check Writing Privileges ',
        titleSub4: 'on not listed account.',
        headingTwo: GENERAL_INV_ACC,
        menuOptions: 'Checkbook Request',
        accountNameText: ACCOUNT_NAME_TEXT,
        accountNumberText: ACCOUNT_NUM_TEXT,
        eligibleFundsText: ELIGIBLE_FUNDS_TEXT,
        currentValue: CURRENT_VAL_TEXT,
        holding: HOLDING,
        lastRequestedDate: LAST_REQUEST_DATE,
        /*  fundSharesCard:[
              {
                  id: '1',
                  fundShareText: SHARES_TEXT_500,
                  currentVal: {
                      text: CURRENT_VAL_TEXT,
                      value: '$6000',
                  },
                  holding: {
                      text: 'Holding',
                      value: '$7000',
                  },
                  lastRequestedDate: {
                      text: LAST_REQUEST_DATE,
                      value: '-',
                  },
                  orderCheckbookText: ORDER_CHECKBOOK_TEXT,
              },
              {
                  id: '2',
                  fundShareText: SHARES_TEXT_400,
                  currentVal: {
                      text: CURRENT_VAL_TEXT,
                      value: '$6000',
                  },
                  holding: {
                      text: 'Holding',
                      value: '$7000',
                  },
                  lastRequestedDate: {
                      text: LAST_REQUEST_DATE,
                      value: '12-12-2019',
                  },
                  orderCheckbookText: ORDER_CHECKBOOK_TEXT,
              },
              {
                  id: '3',
                  fundShareText: 'USSPX VCM 300 INDEX FUND MEMBER CLASS SHARES',
                  currentVal: {
                      text: CURRENT_VAL_TEXT,
                      value: '$6000',
                  },
                  holding: {
                      text: 'Holding',
                      value: '$7000',
                  },
                  lastRequestedDate: {
                      text: LAST_REQUEST_DATE,
                      value: '10-27-2019',
                  },
                  orderCheckbookText: ORDER_CHECKBOOK_TEXT,
              },
          ], */
        checkbookSubmission: 'Your Checkbook request successfully submitted.',
        accountDetailsHeading: 'Account Details',
        fundDetailsHeading: 'Fund Details',
        deliveryAddressHeading: DELIVERY_ADD_TEXT,
        fundName: {
            text: 'Fund Name',
        },
        fundAccountNumber: {
            text: 'Fund Account Number',
        },
    },
    /* Dividends And Capital Gains */
    dividends: {
        pageHeading: DIVIDEND_TITLE,
        id: '1',
        reinvest: ' "Reinvest Dividend".',
        reinvestCapitalGainsText: 'Reinvest Capital Gains',
        cash: ' "Receive Cash".',
        direct: '"Direct Dividend", ',
        dividendText: 'Dividends',
        loadingText: 'Loading',
        noAccounts: 'No dividends accounts.',
        capitalGains: 'Capital Gains',
        anotherFundText: 'To enable the investment to another fund, please contact a Member Service Representative',
        anotherFundText2: ' for assistance.',
        applyForAllText: 'Apply this if you would like to update your preferences across all of your funds within this account.',
        contactNumber: '(800) 235-8396',
        instructionsTitle: 'Learn more about Dividends and Capital Gains Preferences',
        instructionList: [
            'If you have elected to have dividends reinvested on a security, we will automatically select the stock option for your account.',
            'If you have elected to have dividends remain in cash, we will automatically select the cash option for your account.',
            'Any changes made to your dividend profile after record date will be effective from the next dividend payment',
        ],
        menuOptions: 'Edit',
        accountType: 'Account Type',
        primaryOwner: 'Primary Owner',
        balance: 'Balance',

        buttonText: {
            next: 'Next',
            back: 'Back',
            submit: 'Submit',
            cancel: 'Cancel',
        },
        accountNumberText: ACCOUNT_NUM_TEXT,
        dividendsAndCapitalGains: 'USSPX VCM 320 INDEX FUND MEMBER CLASS SHARES',
        // radio buttons
        reinvestText: 'Reinvest Dividend',
        paymentMethod01: 'Take home with Check Set Up',
        paymentMethod05: ' Take home via Bank Set Up',
        incomeText: 'Take Income',
        investAnotherfundText: 'Invest to another fund',
        cashTextTwo: ' (Account Sample Content)',
        options: {
            reinvest: 'reinvest',
            income: 'income',
            investAnotherFund: 'investAnotherFund',
            capitalGains: 'capitalGains'
        }
    },
    /* Bank Accounts */
    bankAccount: {
        pageHeading: 'Bank Accounts',
        addText: 'Add',
        addBankHeading: 'Add Bank Account',
        verifydBankHeading: 'Verify Bank Details',
        addBankDescription: 'Add your account by choosing from the popular banks below.',
        addBankSubTitle: 'Popular banks',
        menuOptions: 'Delete',
        deleteModalTitle: 'Delete Bank Account',
        cannotDeleteModalBody: 'Sorry, you cannot delete Bank Account with ',
        cannotDeleteModalBody2: 'as it is used for your current investment',
        deleteModalBody1: 'Are you sure you want to delete Bank Account with ',
        deleteModalBody2: '<Account Name> ',
        deleteModalBody3: 'and ',
        deleteModalBody4: '<Account No> ',
        deleteModalDeleteText: 'Delete',
        deleteModalCancelText: 'Cancel',
        cannotDeleteModalText: 'Ok',
        buttonText: {
            next: 'Next',
            back: 'Back',
            submit: 'Submit',
            cancel: 'Cancel',
            ok: 'Ok',
        },
        staticText: {
            bankAccountNumberText: ACCOUNT_NUM_TEXT,
            bankRoutingNumberText: TRANSIT_ROUTING_NUM,
            systematicWithdrawalPlanText: SWP_TEXT,
            automaticInvestmentPlanText: AIP_TEXT,
        },
        noBankAccountMsg: 'Please contact a Service Respresentative for instructions on how to connect a personal bank account for direct deposits and withdrawals.',
        accountsRetrievedSuccessMsg: 'Your bank account has been added.\n Your bank account will be verified and approved in seven to ten days for withdrawals.',
        otherText: 'Others',
        accountDetails: [
            {
                accName: 'Wells Fargo',
                accNumber: {
                    text: ACCOUNT_NUM_TEXT,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'Yes',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'Yes',
                },
            },
            {
                accName: 'KBC Bank',
                accNumber: {
                    text: ACCOUNT_NUM_TEXT,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'Yes',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'No',
                },
            },
            {
                accName: 'Bank of America',
                accNumber: {
                    text: ACCOUNT_NUM_TEXT,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'No',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'No',
                },
            },
        ],
        typeofAccount: {
            text: 'Type of Account',
            value1: 'Savings',
            value2: 'Checking',
        },
        nameOfFinancialInstitution: {
            text: 'Name of Financial Institution',
            value: '',
        },
        nameOfAccountOwner: {
            text: 'Name Of Account Owner(s)',
            value: '',
        },
        transitRouting: {
            text: TRANSIT_ROUTING_NUM,
            value: '',
        },
        accountNumber: {
            text: ACCOUNT_NUM_TEXT,
            value: '',
        },
        accountOwnerAdd: {
            text: `Account Owner's address`,
            value1: 'Line1',
            value2: 'Line2',
        },
        zipCode: {
            text: 'ZIP Code',
            value: 'ZIP',
        },
        cityState: {
            text: 'City & State',
            value1: 'City',
            value2: 'State',
        },
        specimenData: {
            heading: 'Specimen',
            title: 'Account number and all owner names must be preprinted and unaltered',
        },

        // radio buttons
        bankAccountTypeOptions: [
            {
                id: '1',
                value: 'Savings',
            },
            {
                id: '2',
                value: 'Checking',
            },
        ],
        bankAccountDetails: [
            {
                label: 'Name of Financial Institution',
                name: 'financialInstitutionName',
            },
            {
                label: 'Name of Account Owner(s)',
                name: 'accountOwner',
            },
            {
                label: TRANSIT_ROUTING_NUM,
                name: 'transitRoutingNumber',
            },
            {
                label: ACCOUNT_NUM_TEXT,
                name: 'accountNumber',
            },
            {
                label: 'Account Owner`s address',
                name: 'ownerAddress',
            },
            {
                label: 'ZIP Code',
                name: 'zipCode',
            },
            {
                label: 'City & State',
                name: 'cityState',
            },
        ],
        // progress bar
        progressBarText: 'Gathering your Data',
    },
    MockAPIAccountList: {
        accounts: [
            {
                accountNumber: '378',
                accountName: 'John Smith',
                fundNumber: '030',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '9000',
                currentValue: '4500',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
                count: '13 out of 55 Funds',
            },
            {
                accountNumber: '430',
                fundNumber: '030',
                accountName: 'Jeanette',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '7000',
                currentValue: '6000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
                count: '08 out of 26 Funds',
            },
            {
                accountNumber: '442',
                fundNumber: '030',
                accountName: 'Nick',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '8000',
                currentValue: '5000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
                count: '02 out of 6 Funds',
            },

            {
                accountNumber: '487',
                fundNumber: '030',
                accountName: 'Harry',
                taxID: '123456788',
                accountType: '100',
                ownerType: '7',
                automaticInvestmentPlan: 'Yes',
                holding: '7000',
                currentValue: '6000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
                count: '20 out of 40 Funds',
            },
            {
                accountNumber: '491',
                fundNumber: '030',
                accountName: 'Hellan',
                taxID: '123456788',
                accountType: '102',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '8000',
                currentValue: '5000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
                count: '15 out of 32 Funds',
            },
        ],
    },
    // MockAPIAccountListFormattedOld:[
    //     {
    //       holdingGroupName: 'General Investment Accounts',
    //       accountType: 'Joint',
    //       accounts: [
    //         {
    //           fundNumber: 90,
    //           fundAccountNumber: 90000000047,
    //           accountName: 'Joint Account',
    //           taxID: '123457777',
    //           accountType: '100',
    //           ownerType: '3',
    //           automaticInvestmentPlan: 'N',
    //           holding: '',
    //           masterRegistrationID: 37665341,
    //           currentAccountBalance: '',
    //           accountStatus: '',
    //           availableShares: '',
    //           count: '1 out of 319 Funds',
    //           currentValue: 800,
    //           fundName: '',
    //           shortFundName: '',
    //           fundsList: [
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 3000000145,
    //               accountName: 'Joint Account',
    //               taxID: '123457777',
    //               accountType: '100',
    //               ownerType: '3',
    //               automaticInvestmentPlan: 'Y',
    //               holding: '20509.45',
    //               masterRegistrationID: 37665341,
    //               currentAccountBalance: '20509.45',
    //               accountStatus: 'O',
    //               availableShares: '14252.36',
    //               count: '1 out of 319 Funds',
    //               currentValue: 800,
    //               fundName: 'USAA INTERMDT-TERM BOND FUND SHS',
    //               shortFundName: 'USIBX'
    //             },
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 30000002730,
    //               accountName: 'Joint Account',
    //               taxID: '123457777',
    //               accountType: '100',
    //               ownerType: '3',
    //               automaticInvestmentPlan: 'N',
    //               holding: '3067.71',
    //               masterRegistrationID: 37665341,
    //               currentAccountBalance: '3067.71',
    //               accountStatus: 'O',
    //               availableShares: '3066.50',
    //               count: '1 out of 319 Funds',
    //               currentValue: 800,
    //               fundName: 'USAA 500 INDEX FUND REWARD SHARES',
    //               shortFundName: 'USPRX'
    //             },
    //           ]
    //         },
    //         {
    //           fundNumber: 30,
    //           fundAccountNumber: 3000000208,
    //           accountName: 'Individual Account',
    //           taxID: '123456777',
    //           accountType: '100',
    //           ownerType: '1',
    //           automaticInvestmentPlan: 'N',
    //           holding: '',
    //           masterRegistrationID: 74571365,
    //           currentAccountBalance: '',
    //           accountStatus: '',
    //           availableShares: '',
    //           count: '0 out of 3 Funds',
    //           currentValue: 0,
    //           fundName: '',
    //           shortFundName: '',
    //           fundsList: [
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 3000000184,
    //               accountName: 'Individual Account',
    //               taxID: '123456777',
    //               accountType: '100',
    //               ownerType: '1',
    //               automaticInvestmentPlan: 'N',
    //               holding: '',
    //               masterRegistrationID: 74571365,
    //               currentAccountBalance: '',
    //               accountStatus: '',
    //               availableShares: '',
    //               count: '0 out of 3 Funds',
    //               currentValue: 0,
    //               fundName: '',
    //               shortFundName: ''
    //             },
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 3000000196,
    //               accountName: 'Individual Account',
    //               taxID: '123456777',
    //               accountType: '100',
    //               ownerType: '1',
    //               automaticInvestmentPlan: 'N',
    //               holding: '',
    //               masterRegistrationID: 74571365,
    //               currentAccountBalance: '',
    //               accountStatus: '',
    //               availableShares: '',
    //               count: '0 out of 3 Funds',
    //               currentValue: 0,
    //               fundName: '',
    //               shortFundName: ''
    //             },
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 3000000208,
    //               accountName: 'Individual Account',
    //               taxID: '123456777',
    //               accountType: '100',
    //               ownerType: '1',
    //               automaticInvestmentPlan: 'N',
    //               holding: '',
    //               masterRegistrationID: 74571365,
    //               currentAccountBalance: '',
    //               accountStatus: '',
    //               availableShares: '',
    //               count: '0 out of 3 Funds',
    //               currentValue: 0,
    //               fundName: '',
    //               shortFundName: ''
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       holdingGroupName: '',
    //       accountType: '',
    //       accounts: [
    //         {
    //           fundNumber: 94,
    //           fundAccountNumber: 94000000019,
    //           accountName: '',
    //           taxID: '123457777',
    //           accountType: '200',
    //           ownerType: '12',
    //           automaticInvestmentPlan: 'N',
    //           holding: '',
    //           masterRegistrationID: 26356297,
    //           currentAccountBalance: '',
    //           accountStatus: '',
    //           availableShares: '',
    //           count: '0 out of 33 Funds',
    //           currentValue: 0,
    //           fundName: '',
    //           shortFundName: '',
    //           fundsList: [
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 3000001616,
    //               accountName: '',
    //               taxID: '123457777',
    //               accountType: '200',
    //               ownerType: '12',
    //               automaticInvestmentPlan: 'N',
    //               holding: '3328.69',
    //               masterRegistrationID: 26356297,
    //               currentAccountBalance: '3328.69',
    //               accountStatus: 'O',
    //               availableShares: '3328.69',
    //               count: '0 out of 33 Funds',
    //               currentValue: 0,
    //               fundName: 'USAA SCIENCE & TECHNOLOGY FUND SHS',
    //               shortFundName: 'USSCX'
    //             },
    //             {
    //               fundNumber: 30,
    //               fundAccountNumber: 30000002817,
    //               accountName: '',
    //               taxID: '123457777',
    //               accountType: '200',
    //               ownerType: '12',
    //               automaticInvestmentPlan: 'N',
    //               holding: '0.00',
    //               masterRegistrationID: 26356297,
    //               currentAccountBalance: '0.00',
    //               accountStatus: 'O',
    //               availableShares: '0.00',
    //               count: '0 out of 33 Funds',
    //               currentValue: 0,
    //               fundName: 'USAA GROWTH FUND SHS',
    //               shortFundName: 'USAAX'
    //             },
    //           ]
    //         }]
    //     },
    //     {
    //       holdingGroupName: 'Retirement Accounts',
    //       accountType: 'Roth IRA',
    //       accounts: [
    //         {
    //           fundNumber: 90,
    //           fundAccountNumber: 90000000035,
    //           accountName: 'Roth IRA Account',
    //           taxID: '123457777',
    //           accountType: '215',
    //           ownerType: '14',
    //           automaticInvestmentPlan: 'N',
    //           holding: '',
    //           masterRegistrationID: 44071086,
    //           currentAccountBalance: '',
    //           accountStatus: '',
    //           availableShares: '',
    //           count: '0 out of 7 Funds',
    //           currentValue: 0,
    //           fundName: '',
    //           shortFundName: '',
    //           fundsList: [
    //             {
    //               fundNumber: 33,
    //               fundAccountNumber: 3300000050,
    //               accountName: 'Roth IRA Account',
    //               taxID: '123457777',
    //               accountType: '215',
    //               ownerType: '14',
    //               automaticInvestmentPlan: 'N',
    //               holding: '',
    //               masterRegistrationID: 44071086,
    //               currentAccountBalance: '',
    //               accountStatus: '',
    //               availableShares: '',
    //               count: '0 out of 7 Funds',
    //               currentValue: 0,
    //               fundName: '',
    //               shortFundName: ''
    //             }]
    //         }]
    //     },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 3300000528,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000000656,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000000783,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000002751,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000002775,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 90,
    //     //           fundAccountNumber: 90000000035,
    //     //           accountName: 'Roth IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '215',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 44071086,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 7 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         }
    //     //       ]
    //     //     },
    //     //     {
    //     //       fundNumber: 33,
    //     //       fundAccountNumber: 33000002763,
    //     //       accountName: 'SEP IRA Account',
    //     //       taxID: '123457777',
    //     //       accountType: '203',
    //     //       ownerType: '14',
    //     //       automaticInvestmentPlan: 'N',
    //     //       holding: '',
    //     //       masterRegistrationID: 49186191,
    //     //       currentAccountBalance: '',
    //     //       accountStatus: '',
    //     //       availableShares: '',
    //     //       count: '0 out of 4 Funds',
    //     //       currentValue: 0,
    //     //       fundName: '',
    //     //       shortFundName: '',
    //     //       fundsList: [
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 3300000478,
    //     //           accountName: 'SEP IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '203',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 49186191,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 4 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000000795,
    //     //           accountName: 'SEP IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '203',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 49186191,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 4 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000001431,
    //     //           accountName: 'SEP IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '203',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 49186191,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 4 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         },
    //     //         {
    //     //           fundNumber: 33,
    //     //           fundAccountNumber: 33000002763,
    //     //           accountName: 'SEP IRA Account',
    //     //           taxID: '123457777',
    //     //           accountType: '203',
    //     //           ownerType: '14',
    //     //           automaticInvestmentPlan: 'N',
    //     //           holding: '',
    //     //           masterRegistrationID: 49186191,
    //     //           currentAccountBalance: '',
    //     //           accountStatus: '',
    //     //           availableShares: '',
    //     //           count: '0 out of 4 Funds',
    //     //           currentValue: 0,
    //     //           fundName: '',
    //     //           shortFundName: ''
    //     //         }
    //     //       ]
    //     //     }
    //     //   ]
    //     // },
    //     {
    //       holdingGroupName: 'Investing for Children',
    //       accountType: 'UGMA/UTMA',
    //       accounts: [
    //         {
    //           fundNumber: 94,
    //           fundAccountNumber: 94000000033,
    //           accountName: 'UGMA/UTMA Account',
    //           taxID: '123457777',
    //           accountType: '100',
    //           ownerType: '7',
    //           automaticInvestmentPlan: 'N',
    //           holding: '',
    //           masterRegistrationID: 58407705,
    //           currentAccountBalance: '',
    //           accountStatus: '',
    //           availableShares: '',
    //           count: '0 out of 18 Funds',
    //           currentValue: 0,
    //           fundName: '',
    //           shortFundName: '',
    //           fundsList: [
    //             {
    //               fundNumber: 44,
    //               fundAccountNumber: 4400000014,
    //               accountName: 'UGMA/UTMA Account',
    //               taxID: '123457777',
    //               accountType: '100',
    //               ownerType: '7',
    //               automaticInvestmentPlan: 'N',
    //               holding: '',
    //               masterRegistrationID: 58407705,
    //               currentAccountBalance: '',
    //               accountStatus: '',
    //               availableShares: '',
    //               count: '0 out of 18 Funds',
    //               currentValue: 0,
    //               fundName: '',
    //               shortFundName: ''
    //             },
    //             // {
    //             //   fundNumber: 44,
    //             //   fundAccountNumber: 44000000103,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 44,
    //             //   fundAccountNumber: 44000000115,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 44,
    //             //   fundAccountNumber: 44000000127,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 44,
    //             //   fundAccountNumber: 44000000141,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 44,
    //             //   fundAccountNumber: 44000000382,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 46,
    //             //   fundAccountNumber: 4600000016,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 48,
    //             //   fundAccountNumber: 48000000140,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 49,
    //             //   fundAccountNumber: 49000000561,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 59,
    //             //   fundAccountNumber: 59000000699,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 75,
    //             //   fundAccountNumber: 75000000061,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 79,
    //             //   fundAccountNumber: 79000000121,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 85,
    //             //   fundAccountNumber: 85000000035,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 86,
    //             //   fundAccountNumber: 86000000052,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 88,
    //             //   fundAccountNumber: 88000000068,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 89,
    //             //   fundAccountNumber: 89000000134,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 90,
    //             //   fundAccountNumber: 90000000086,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // },
    //             // {
    //             //   fundNumber: 94,
    //             //   fundAccountNumber: 94000000033,
    //             //   accountName: 'UGMA/UTMA Account',
    //             //   taxID: '123457777',
    //             //   accountType: '100',
    //             //   ownerType: '7',
    //             //   automaticInvestmentPlan: 'N',
    //             //   holding: '',
    //             //   masterRegistrationID: 58407705,
    //             //   currentAccountBalance: '',
    //             //   accountStatus: '',
    //             //   availableShares: '',
    //             //   count: '0 out of 18 Funds',
    //             //   currentValue: 0,
    //             //   fundName: '',
    //             //   shortFundName: ''
    //             // }
    //           ]
    //         }
    //       ]
    //     }
    //   ],
      MockAPIAccountListFormatted:[
           {
              "holdingGroupName":"Retirement Accounts",
              "accountType":"Roth IRA",
              "accounts":[
                 {
                    "fundNumber":88,
                    "fundAccountNumber":88900780843,
                    "accountName":"Roth IRA Account",
                    "accountType":"Roth IRA",
                    "fedTax":"0.0",
                    "stateTax":"0.0",
                    "automaticInvestmentPlan":"N",
                    "holding":"525.11",
                    "masterRegistrationID":500090087,
                    "accountNumber":500090087,
                    "currentAccountBalance":"525.11",
                    "count":"0 out of 4 Funds",
                    "currentValue":0,
                    "fundName":"USAA Target Retirement 2040",
                    "shortFundName":"URFRX",
                    "primaryOwner":"TY SIMPSON JR",
                    "address":{
                       "accountRegistrationLine2":"TY SIMPSON JR",
                       "accountRegistrationline3":"ROTH IRA",
                       "accountRegistrationline4":"9874 GABRIEL DR",
                       "accountRegistrationline5":"BLOOMINGTON IL 617056378",
                       "accountRegistrationline6":"",
                       "accountRegistrationZip":"617056378",
                       "accountRegistrationIdRes":"IL",
                       "accountRegistrationCity":"BLOOMINGTON"
                    },
                    "fundsList":[
                       {
                          "fundNumber":31,
                          "fundAccountNumber":31901654355,
                          "accountName":"Roth IRA Account",
                          "accountType":"Roth IRA",
                          "fedTax":"0.0",
                          "stateTax":"0.0",
                          "automaticInvestmentPlan":"N",
                          "holding":"867.74",
                          "masterRegistrationID":500090087,
                          "accountNumber":500090087,
                          "currentAccountBalance":"867.74",
                          "count":"0 out of 4 Funds",
                          "currentValue":0,
                          "fundName":"USAA Science & Technology Fund",
                          "shortFundName":"USSCX",
                          "primaryOwner":"TY SIMPSON JR",
                          "address":{
                             "accountRegistrationLine2":"TY SIMPSON JR",
                             "accountRegistrationline3":"ROTH IRA",
                             "accountRegistrationline4":"9874 GABRIEL DR",
                             "accountRegistrationline5":"BLOOMINGTON IL 617056378",
                             "accountRegistrationline6":"",
                             "accountRegistrationZip":"617056378",
                             "accountRegistrationIdRes":"IL",
                             "accountRegistrationCity":"BLOOMINGTON"
                          }
                       },
                       {
                          "fundNumber":48,
                          "fundAccountNumber":48000000191,
                          "accountName":"Roth IRA Account",
                          "accountType":"Roth IRA",
                          "fedTax":"0.0",
                          "stateTax":"0.0",
                          "automaticInvestmentPlan":"N",
                          "holding":"0.00",
                          "masterRegistrationID":500090087,
                          "accountNumber":500090087,
                          "currentAccountBalance":"0.00",
                          "count":"0 out of 4 Funds",
                          "currentValue":0,
                          "fundName":"USAA Cornerstone Mod Cons Fund",
                          "shortFundName":"UCMCX",
                          "primaryOwner":"TY SIMPSON JR",
                          "address":{
                             "accountRegistrationLine2":"TY SIMPSON JR",
                             "accountRegistrationline3":"ROTH IRA",
                             "accountRegistrationline4":"9874 GABRIEL DR",
                             "accountRegistrationline5":"BLOOMINGTON IL 617056378",
                             "accountRegistrationline6":"",
                             "accountRegistrationZip":"617056378",
                             "accountRegistrationIdRes":"IL",
                             "accountRegistrationCity":"BLOOMINGTON"
                          }
                       },
                       {
                          "fundNumber":49,
                          "fundAccountNumber":49000000585,
                          "accountName":"Roth IRA Account",
                          "accountType":"Roth IRA",
                          "fedTax":"0.0",
                          "stateTax":"0.0",
                          "automaticInvestmentPlan":"N",
                          "holding":"0.00",
                          "masterRegistrationID":500090087,
                          "accountNumber":500090087,
                          "currentAccountBalance":"0.00",
                          "count":"0 out of 4 Funds",
                          "currentValue":0,
                          "fundName":"USAA Cornerstone Aggressive",
                          "shortFundName":"UCAGX",
                          "primaryOwner":"TY SIMPSON JR",
                          "address":{
                             "accountRegistrationLine2":"TY SIMPSON JR",
                             "accountRegistrationline3":"ROTH IRA",
                             "accountRegistrationline4":"9874 GABRIEL DR",
                             "accountRegistrationline5":"BLOOMINGTON IL 617056378",
                             "accountRegistrationline6":"",
                             "accountRegistrationZip":"617056378",
                             "accountRegistrationIdRes":"IL",
                             "accountRegistrationCity":"BLOOMINGTON"
                          }
                       },
                       {
                          "fundNumber":88,
                          "fundAccountNumber":88900780843,
                          "accountName":"Roth IRA Account",
                          "accountType":"Roth IRA",
                          "fedTax":"0.0",
                          "stateTax":"0.0",
                          "automaticInvestmentPlan":"N",
                          "holding":"525.11",
                          "masterRegistrationID":500090087,
                          "accountNumber":500090087,
                          "currentAccountBalance":"525.11",
                          "count":"0 out of 4 Funds",
                          "currentValue":0,
                          "fundName":"USAA Target Retirement 2040",
                          "shortFundName":"URFRX",
                          "primaryOwner":"TY SIMPSON JR",
                          "address":{
                             "accountRegistrationLine2":"TY SIMPSON JR",
                             "accountRegistrationline3":"ROTH IRA",
                             "accountRegistrationline4":"9874 GABRIEL DR",
                             "accountRegistrationline5":"BLOOMINGTON IL 617056378",
                             "accountRegistrationline6":"",
                             "accountRegistrationZip":"617056378",
                             "accountRegistrationIdRes":"IL",
                             "accountRegistrationCity":"BLOOMINGTON"
                          }
                       }
                    ]
                 }
              ]
           }
        ],
    MockAPIBankList: {
        BankDetails: [
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '1',
                mailUsage: 'P',
                transitRoutingNumber: '123123123',
                bankAccountNumber: '000123123123',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '2',
                mailUsage: 'P',
                transitRoutingNumber: '1231231445',
                bankAccountNumber: '000123123888',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
        ],
    },
    MockAPIBankAccountsDetails: {
        bankDetails:[{
            bankName: "ICICI",
            bankAccountType: "Savings",
            bankAccountNumber: "5678987654",
            bankRoutingNumber: "123456",
            salesforceRecordId: "a2r4F0000006i0BQAQ",
            CreatedDate: "2020-05-30T05:02:04.000+0000",
            SystemModstamp: "2020-07-11T05:47:18.000+0000"
            }],
        rule_l07:{
            lower_limit: 7,
            upper_limit: 15
        }
    },
    MockAPIBankAccountsDetailsEmpty:{
        "bankDetails": [],
        "rule_l07": {
            "lower_limit": 7,
            "upper_limit": 15
        }
    },
    MockAPIBankListPlan: {
        bankDetails: [
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '1',
                mailUsage: 'P',
                transitRoutingNumber: '123123123',
                bankAccountNumber: '000123123123',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '2',
                mailUsage: 'P',
                transitRoutingNumber: '1231231445',
                bankAccountNumber: '000123123888',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
        ],
    },
    MockAPIFundList: {
        "message": "Success",
        "responseCode": 4000,
        "response": "Success",
        "result": [
            {
                "holdingGroupName": "Retirement Accounts",
                "accountType": "Beneficiary IRA ",
                "accounts": [
                    {
                        "accountNumber": 500003238,
                        "masterRegistrationID": 500003238,
                        "accountName": "Beneficiary IRA",
                        "accountType": "Beneficiary IRA ",
                        "holding": "34.29",
                        "currentAccountBalance": "34.29",
                        "accountStatus": "O",
                        "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                        "defaultHoldingGroupName": "Retirement Accounts",
                        "count": "1 out of 6 Funds",
                        "currentValue": 333,
                        "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                        "fedTax": "0.0",
                        "stateTax": "0.0",
                        "costBasis": "average",
                        "address": {
                            "accountregistrationLine1": "",
                            "accountregistrationLine2": "SHARON J HEIM BENEFICIARY OF",
                            "accountRegistrationline3": "LEO HEIM DECD",
                            "accountRegistrationline4": "IRA",
                            "accountRegistrationline5": "318 CANINO RD",
                            "accountRegistrationline6": "HOUSTON TX 77076",
                            "accountRegistrationZip": "77076",
                            "accountRegistrationIdRes": "TX",
                            "accountRegistrationCity": "HOUSTON"
                        },
                        "fundsList": [
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 30,
                                "fundAccountNumber": 30900219246,
                                "fundName": "USAA Intermdt-Term Bond Fund",
                                "shortFundName": "USIBX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "34.29",
                                "currentAccountBalance": "34.29",
                                "automaticInvestmentPlan": "Y",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.0",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            },
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 34,
                                "fundAccountNumber": 34902052052,
                                "fundName": "USAA 500 Index Member Shares",
                                "shortFundName": "USSPX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "8729.21",
                                "currentAccountBalance": "8729.21",
                                "automaticInvestmentPlan": "N",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.1",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            },
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 47,
                                "fundAccountNumber": 47900780405,
                                "fundName": "USAA Cornerstone Moderate Fund",
                                "shortFundName": "USBSX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "6462.71",
                                "currentAccountBalance": "6462.71",
                                "automaticInvestmentPlan": "N",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.1",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            },
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 50,
                                "fundAccountNumber": 50000000157,
                                "fundName": "USAA Precious Mtl & Min Fund",
                                "shortFundName": "USAGX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "11364.43",
                                "currentAccountBalance": "11364.43",
                                "automaticInvestmentPlan": "N",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.0",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            },
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 76,
                                "fundAccountNumber": 76900128758,
                                "fundName": "USAA Value Fund",
                                "shortFundName": "UVALX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "22522.86",
                                "currentAccountBalance": "22522.86",
                                "automaticInvestmentPlan": "N",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.0",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            },
                            {
                                "accountNumber": 500003238,
                                "masterRegistrationID": 500003238,
                                "fundNumber": 88,
                                "fundAccountNumber": 88000004801,
                                "fundName": "USAA Target Retirement 2040",
                                "shortFundName": "URFRX",
                                "accountName": "Beneficiary IRA",
                                "accountType": "Beneficiary IRA ",
                                "holding": "484.52",
                                "currentAccountBalance": "484.52",
                                "automaticInvestmentPlan": "N",
                                "accountStatus": "O",
                                "customerName": "BOKF NA, DBA BANK OF TEXAS CUST",
                                "defaultHoldingGroupName": "Retirement Accounts",
                                "primaryOwner": "SHARON J HEIM BENEFICIARY OF",
                                "fedTax": "0.0",
                                "stateTax": "0.0",
                                "costBasis": "average"
                            }
                        ]
                    }
                ]
            }
        ]
    },

    ScheduleDisplayDetailsAIP: {
        schedule: {
            titleTxt: 'Schedule',
            editTxt: 'Edit',
            note: WEEKEND_NOTE,
            pageIndex: 2,
            data: [
                {
                    field: 'Investment Frequency',
                    value: "",
                },
                {
                    field: 'Start Date',
                    value: "",
                },
                {
                    field: 'Next Investment Date',
                    value: "",
                },
            ],
        },
    },
    ScheduleDisplayDetailsSWP: {
        schedule: {
            titleTxt: 'Frequency',
            editTxt: 'Edit',
            note: WEEKEND_NOTE,
            pageIndex: 2,
            data: [
                {
                    field: 'Withdrawal Frequency',
                    value: "",
                },
                {
                    field: 'Beginning on',
                    value: "",
                },
                {
                    field: 'Ending by',
                    value: "",
                },
                {
                    field: 'Next Withdrawal Date',
                    value: "",
                },
            ],
        },
    },

    InitialScheduleDisplayDetails: {
        schedule: {
            titleTxt: "Schedule",
            editTxt: "Edit",
            note: WEEKEND_NOTE,
            pageIndex:2,
            data: [],
        },
    },
    AccountServicesTitle:"We've improved the way you manage your account specific services!",
    AccountServicesDescription:'Our new account services design makes it quicker and easier than ever to manage your services and preferences by account. Watch the video below to navigate the new and improved design.',
    DashboardLinkText:'Home/Dashboard Link',
};

export default constant;
