import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import CancelBtn from '../CancelBtn';
import PrimaryBtn from '../PrimaryBtn';
import BackBtn from '../BackBtn';

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent || 'flex-start'};
    @media (max-width: 575px) { 
        flex-direction: column;
        min-width: 80%;
    }
`;

const BottomNavBarBox = styled.div`
    display: flex;
    align-items: center;
    background: #fafafa 0% 0% no-repeat padding-box;
    opacity: 1;
    border-color: rgb(210, 210, 210);
    border-style: solid;
    border-width: 1px 0;
    padding: 22px 0;
    button:last-child {
        margin-left: 50px;
    }
    min-height: 90px;
    @media (max-width: 575px) { 
        button:last-child {
            margin-left: 0;
        }
        button:not(:first-child) {
            margin-top: 20px
        }
    }
`;

const BottomNavBar = props => {
    const {
        primaryBtnText,
        primaryBtnProps,
        backBtnProps,
        cancelBtnProps,
        hideBack,
        hidePrimaryBtn,
        showCancelBtn,
    } = props;
    const justifyContent = hideBack ? "flex-end" : "space-between";
    return (
        <BottomNavBarBox>
            <Container>
                <FlexRow justifyContent={justifyContent}>
                    {!hideBack && <BackBtn id="vcm-bottom-nav-section-back" {...backBtnProps} />}
                    <FlexRow>
                        {showCancelBtn && <CancelBtn id="vcm-bottom-nav-section-cancel" {...cancelBtnProps} />}
                        {!hidePrimaryBtn && <PrimaryBtn id="vcm-bottom-nav-section-primary" {...primaryBtnProps}>{primaryBtnText}</PrimaryBtn>}
                    </FlexRow>
                </FlexRow>
            </Container>
        </BottomNavBarBox>
    );
};

BottomNavBar.propTypes = {
    primaryBtnText: PropTypes.string,
    primaryBtnProps: PropTypes.instanceOf(Object),
    backBtnProps: PropTypes.instanceOf(Object),
    cancelBtnProps: PropTypes.instanceOf(Object),
    hideBack: PropTypes.bool,
    hidePrimaryBtn: PropTypes.bool,
    showCancelBtn: PropTypes.bool,
};

BottomNavBar.defaultProps = {
    primaryBtnText: '',
    primaryBtnProps: {},
    backBtnProps: {},
    cancelBtnProps: {},
    hideBack: false,
    hidePrimaryBtn: false,
    showCancelBtn: false,
};

export default BottomNavBar;
