import styled from 'styled-components';

const dummyLink = 'Please reset password.';
const uuidDummy="b23e6328-e84a-4ffe-b117-d6b8502cdae2"

const Block = styled.h3`
    color: #004a98;
    font-size: 15px;
    line-height: 22px;
    opacity: 1;
    font-weight: 700;
    cursor: pointer;
    display:inline-block;
`;
const Paragraph = styled.p`
    color: rgba(73, 73, 74, 1);
    font: 500 15px/20px benton-sans,sans-serif;
    margin-bottom: 0;
    padding-right: 12px;
`;
const Imgsrc = styled.img`
    height: 67px;
    width: 47px;
`;

const PowerText = styled.span`
    font-size: 8px;
    letter-spacing: 2px;
`;

const MarstoneText = styled.span`
    font-family : 'yorkten-slab-normal';
    font-size: 16px;
`;

const Sup = styled.sup`
    font-family: sans-serif;
    font-size: 8px;
    color: lightgrey;
    top: -1.0em;
`

const constant = {
    memberRole: {
        userName: 'ussaonlineid2',
        password: 'Testonline@2'
    },
    guestRole: {
        userName: 'vcmweb3',
        password: 'Vcmwebapp@123'
    },
    login: 'Last sign in',
    good: 'Good',
    AM: 'AM',
    PM: 'PM',
    morning: 'Morning',
    afternoon: 'Afternoon',
    userName: 'Karan',
    comma: ',',
    EST: 'EST',
    evening: 'Evening',
    welcome: 'Welcome',
    dot: '.',
    assignObj: obj => obj,
    documentcenter: 'Document Center',
    messagCenter: 'Message Center',
    marstoneText: "Marstone",
    powerText: "POWERED BY",
    TM: 'TM',
    docJson: [
        { id: 0, date: '01/05/2020', text: 'Congratulations! Your user verification and sign-up is completed ', link: dummyLink, atchmnt: false },
        { id: 1, date: '02/05/2020', text: 'Congratulations! Your user verification and sign-up is completed ', link: dummyLink, atchmnt: true },
        { id: 2, date: '03/05/2020', text: 'Congratulations! Your user verification and sign-up is completed! ', link: dummyLink, atchmnt: true },
        { id: 3, date: '04/05/2020', text: 'Congratulations! Your user verification and sign-up is complete ', link: dummyLink, atchmnt: false },

    ],
    testingtoken:"eyJraWQiOiJ3SXhIRytGT0xPOWx2a3VFQ3V5U2tiMFNGWFwvQlB2aU9DcHZvMW9USXNVUT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1NWVjZTA4NS00ZjIzLTRmZjMtYjhjOC1mNDMxOWFjM2NiMGUiLCJhdWQiOiIybjNpbDJ0a2pnYnQyMjlnaTg5cWExY292MyIsImV2ZW50X2lkIjoiMDk0ZDZkOGEtM2Y4Ni00ODQ3LTg2NzYtOWZmNmY2MzQ1MWJjIiwiZmlzTWVtYmVySWQiOiJVMjgzNjY4MSIsInRva2VuX3VzZSI6ImlkIiwidmNtSUQiOiIyMDAzMDcyNDkzMCIsImF1dGhfdGltZSI6MTU4OTAzNzIzNCwiY2hhbm5lbCI6IndlYiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX3prY1Y3ejRkbCIsImNvZ25pdG86dXNlcm5hbWUiOiJ1c2Fhb25saW5laWQ1IiwiZXhwIjoxNTg5MDQwODM2LCJpYXQiOjE1ODkwMzcyMzZ9.KaWuckpesE1-skqh9ruJHsbKZRlxJs_h9_2ODAYD6Phgs6RzmdsOv_ewquial8euVrfL4NCB5olcbqje3VCjKW0cv8c7kHxGifcdFUmBD_LtGXCUPyAWWn3zAYUY7x2qW2eZXyQrez5Y_n40kvLa-1PoF89ijLm0KeF6KrSYkgpuxkILSIJKUNOCQ2nelb82CZixtEYUzQHbaRC7cAUq5VjvV8ms_8aCWh6aGVQjPq9wfC5ZYXlvkLPhsgHL61BaNLIkJ_atcBlZUSVBomsj3gt7DIA4JwZHxNszegO1At_B0n0d35Cp25BFH2MWhYvnPILdO5hF3Sl1bdsYaPBRQw",
    noMessagesText:'No Messages Available',
    noDocumentsText:'No Documents Available',
    noSavedItemsText:'No Pending Forms Available',
    mySavedItemsTitle:'My Saved Items',
    openPopupText:'Opens a Popup',
    externalWeb :'External Website. Opens New Window',
    dummyMessages: [
        {
            "subject": "Important Notice 1",
            "skCreatedAt": "m-1587547614572",
            "isRead": true,
            "messageType": "non-urgent"
        },
        {
            "subject": "Important Notice 2",
            "skCreatedAt": "m-1587537347492",
            "isRead": false,
            "messageType": "urgent"
        }
    ],
    savedItems : {
        cardId: 1,
        cardHeading: 'My Saved Items',
        cardLinks: [
            // {
            //     id: 0,
            //     linktext: 'Complete your Roth IRA Account application',
            //     link: '#',
            //     savedate: 'Saved Feb 28,2020, 3:40pm EST',
            //     attachment: false,
            // },
            // {
            //     id: 1,
            //     linktext: 'Recommendation from portfolio planner',
            //     link: '#',
            //     savedate: 'March 10,2020, 4:03pm EST',
            //     attachment: true,
            // },
        ],
    },
    savedItemsForDashboard : {
        cardId: 1,
        cardHeading: 'My Saved Items',
        cardLinks: [
            // {
            //     id: 0,
            //     linktext: 'Complete your Roth IRA Account application',
            //     link: '#',
            //     savedate: 'Saved Feb 28,2020, 3:40pm EST',
            //     attachment: false,
            // },
            // {
            //     id: 1,
            //     linktext: 'Recommendation from portfolio planner',
            //     link: '#',
            //     savedate: 'March 10,2020, 4:03pm EST',
            //     attachment: true,
            // },
        ],
    },
    dummySavedItems:[
        {
            "uuid": uuidDummy,
            "accountType": "Roth IRA",
            "createdAt": "Tue Jul 14 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid": uuidDummy,
            "accountType": "Traditional IRA",
            "createdAt": "Tue Jul 15 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid": uuidDummy,
            "accountType": "Joint Account",
            "createdAt": "Tue Jul 16 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid": uuidDummy,
            "accountType": "SIMPLE IRA",
            "createdAt": "Tue Jul 17 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid":uuidDummy,
            "accountType": "GIA Account",
            "createdAt": "Tue Jul 18 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid": uuidDummy,
            "accountType": "SEP IRA",
            "createdAt": "Tue Jul 19 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        },
        {
            "uuid": uuidDummy,
            "accountType": "Child Account",
            "createdAt": "Tue Jul 20 2020 13:32:37 GMT+0000 (Coordinated Universal Time)"
        }
    ],
    Block,
    Paragraph,
    Imgsrc,
    PowerText,
    MarstoneText,
    Sup,
    contactcenter: 'Contact Center',     
    contactCenterPhoneNumber: {
        phoneNumber: '800-235-8396',
    },
    commonErrorMsg:'We are experiencing systems issues.  Please try again.'
}
export const VICTORY_FUNDS ='Victory Funds';

export default constant