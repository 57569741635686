import styled from 'styled-components';

const containerStyle = {
    backgroundColor: '#F9FBFC',
};
const PageHeading = styled.div`
    text-align: left;
    font: Bold 22px/35px Roboto Slab;
    letter-spacing: 0;
    color: #56565a;
`;
const Menu = styled.div`
    position: relative;
    &::after {
        content: '\\2807';
        color: #544a54;
        font-size: 27px;
    }
`;
const MenuOptionsPopup = styled.div`
    position: absolute;
    width: 150px;
    height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000f;
    text-align: left;
    font: Bold 16px/21px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: -150px;
    top: 30px;
`;
const MenuOptions = styled.div`
    text-indent: 25px;
`;
const MenuOptionsBorder = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 6px;
    margin-bottom: 6px;
`;

const AccountDescription = styled.div`
    margin-bottom: 31px;
    text-align: left;
    font: 400 15px/21px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;
const FlexDiv = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
    display: flex;
    justify-content: space-between;
`;
const RwdFlexDiv = styled.div`
    @media (min-width: 768px) {
        display: none;
    }
    display: flex;
    justify-content: space-between;
`;
const AddText = styled.div`
    text-align: right;
    font: 500 16px/22px Benton Sans;
    letter-spacing: 0;
    color: #0000ff;
    opacity: 1;
    cursor: pointer;
`;
const TitleSeperator = styled.hr`
    margin-top: 0px;
    margin-bottom: 30px;
`;
const AccordionTitleBackgroundStyle = styled.div`
    background: #ececec 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 64px;
    cursor: pointer;
`;

const NoPlansAvailable = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: Bold 20px/30px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    height: 144px;
    margin-bottom: 50px;
    vertical-align: middle;
    line-height: 144px;
`;

export default {
    containerStyle,
    PageHeading,
    Menu,
    AccountDescription,
    FlexDiv,
    AddText,
    TitleSeperator,
    AccordionTitleBackgroundStyle,
    MenuOptions,
    MenuOptionsBorder,
    MenuOptionsPopup,
    NoPlansAvailable,
    RwdFlexDiv,
};
