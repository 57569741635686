import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import LoadingOverlay from 'react-loading-overlay';
import routeConstants from 'routeConstants';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import '../Screens.css';
import RecoverOnlineIdForm from '../../RecoverOnlineId/RecoverOnlineIdForm';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WSpinner, Captcha, WMessageBar } from '../../../../common';
import { /* changedHandler, validityHandler, */ inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles';
import {encrypt} from '../../../../utils';
import userConstants from '../../userConstants';

// const assignObj = obj => obj;

class RecoverOnlineId extends Component {
  data = {
    title: "Retrieve your User ID",
    info: ["Enter your registered USAA / Victory member ID to continue"]
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        victoryId: {
          value: '',
          errMsg: null,
          valid: false,
          touched: false,
          label: 'Victory Member ID',
          rules: {
            isNumeric: true,
            maxLength: 9,
            // minLength: 5,
            required: true
          }
        },
        usaaId: {
          value: '',
          errMsg: null,
          valid: false,
          touched: false,
          label: 'USAA Member ID',
          rules: {
            isNumeric: true,
            maxLength: 9,
            required: true
          }
        }
      },
      formIsValid: false,
      cancelled: false,
      validCaptcha: false,
      notifyMsgFlag: false,
      notifyMsg: '',
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { loginState, history,  recoverOnlineIDType, manageLocalState, recoverOnlineID } = nextProps;
    const { form, formIsValid } = state;
    let updatedState = { ...state };
    if (loginState && loginState.recoverOnlineIDType) {
        const {errorCode, result} = loginState.recoverOnlineIDType;
        if (errorCode === 0) {
          const {role, primaryPhoneNumber, uuid, MFA} = result;
          if(role==='Member' || MFA) {
            const otpDetails = {
              primaryNumber: primaryPhoneNumber,
              // primaryNumber: '14694386809',
              phoneType: 'mobile',
              // phoneType: 'work',
              onlineId: uuid,
              functionalityFlag : "recoverCustOnlineId",
              backLink: routeConstants.recoverOnlineId,
              verifyNextLink: routeConstants.checkInbox,
              backLabel: 'Retrieve User Id',
              flow: "Recover User Id",
              vId: form.victoryId.value,
              uId: form.usaaId.value,
              formIsValid,
              role,
            }
            manageLocalState({otpDetails});
            recoverOnlineIDType('');
            history.push({
              pathname: '/otp-ivr-select',
            });
          } else {
            const otpDetails = {
              onlineId: uuid,
              flow: 'Guest',
              vId: form.victoryId.value,
              uId: form.usaaId.value,
              formIsValid,
              role,
            }
            manageLocalState({otpDetails});
            recoverOnlineIDType('');
            recoverOnlineID({uuid, role});
          }
        } else if(errorCode===5008){
          const field = updatedState.type==='usaaId' ? 'USAA Member ID' : 'Victory Member ID';
          const msg = " doesn't match our records, please try again";
          updatedState = { ...state,
            notifyMsgFlag: true,
            notifyMsg: {message: field + msg}
          };
          recoverOnlineIDType('');
        } else if(errorCode===2023){
            recoverOnlineIDType('');
            history.push({
                pathname: routeConstants.signIn,
                message: userConstants.blockedMessage,
                failure: true
            });
        } else {
            updatedState = { ...state,
              notifyMsgFlag: true,
              notifyMsg: {message: result}
            };
            recoverOnlineIDType('');
        }
    }
    if (loginState && loginState.recoverOnlineID) {
      const {errorCode, result} = loginState.recoverOnlineID;
      if(errorCode===0) {
        // const otpDetails = {uuid: result.uuid, flow:'Guest'};
        // manageLocalState({otpDetails});
        recoverOnlineID('');
        history.push(routeConstants.checkInbox);
      } else {
        updatedState = { ...state,
          notifyMsgFlag: true,
          notifyMsg: {message: result}
        };
      }
    }
    return updatedState;
  }

  componentDidMount() {
    const {otpDetails} = this.props;
    const {flow, vId, uId, formIsValid } = otpDetails;
    const {form} = this.state;
    const {victoryId, usaaId} = form;
    sessionStorage.setItem('stepname', 'Recover Online Id'); 
    if(flow) {
      victoryId.value = vId;
      usaaId.value = uId;
      this.setState({form: {victoryId, usaaId}, formIsValid});
    }
  }

  componentWillUnmount() {
    const { analyzeFormAdandonment } = this.props;
    const digitalData = getDigitalDataFormAband();
    analyzeFormAdandonment(digitalData);
  }

  analyticsLastTouch = (event) =>{
    analyticsFormAbandLastTouch(event);
  }

  onBack = () => this.setState((state) => ({ ...state, cancelled: true }));

  onNext = () =>  {
    const { recoverOnlineIDType } = this.props;
    const { form } = this.state;
    const userId = form.victoryId.value || form.usaaId.value;
    const type = form.victoryId.value ? 'vcmId' : 'usaaId';
    this.setState({type});
    (async () => {
      const encryptUserId = await encrypt(userId);
      const payload = {
        userId: encryptUserId,
        type
      };
      recoverOnlineIDType(payload);
    })();
  }

  onChange = (event, fieldId) => {
    inputChangedHandler(event, fieldId, this); }

  onBlur = (fieldId) => { inputValidityHandler(fieldId, this); }
  
  captchaChange = (val) => {
    // const {form} = this.state;
    // const {usaaId, victoryId} = form;
    let validCaptcha = false;
    if(val) {
        validCaptcha = true;
    }
    this.setState({validCaptcha});
  }

  onCaptchaErrored = () => {
    this.setState({
      validCaptcha: false,
    });
  }

  onClose = () => {
    this.setState({notifyMsgFlag: false, notifyMsg: ''});
  }

  render() {
    const { formIsValid, cancelled, validCaptcha, notifyMsg, notifyMsgFlag } = this.state;
    const { loginState } = this.props;
    const {isLoading} = loginState;
    return (
      <div className="container-fluid">
        <WSpinner loading={isLoading} />
        {notifyMsgFlag ? <WMessageBar text={notifyMsg} onClose={this.onClose} /> : null}
        <div className="container">
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={this.data.title}
                info={this.data.info} />
            </Col>
            <Col md lg xl>
              <RecoverOnlineIdForm
                recoverOnlineIdFormState={this.state}
                changed={this.onChange}
                clicked={this.analyticsLastTouch}
                blurred={this.onBlur} />
              <Captcha onCaptchaChange={this.captchaChange} 
                onCaptchaErrored={this.onCaptchaErrored}
                onCaptchaExpired={this.onCaptchaErrored}/>
            </Col>
          </Row>
        </div>
        <CommonButtons 
          backClick={this.onBack} 
          nextClick={this.onNext} 
          disabled={!(formIsValid && validCaptcha)} 
          blockstyle={styles.commonButtons} 
          arialabelback= 'Back to sign in'
          dataAnalyticId="UMS_RecoverOnlineid"
          />
        {cancelled ? <Redirect to='/sign-in' /> : null}
      </div>

    );
  }
}

RecoverOnlineId.propTypes = {
  manageLocalState: PropTypes.func,
  recoverOnlineID: PropTypes.func,
  recoverOnlineIDType: PropTypes.func,
  loginState: PropTypes.instanceOf(Object),
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
  otpDetails: PropTypes.instanceOf(Object),
};

RecoverOnlineId.defaultProps = {
  manageLocalState: () => {},
  recoverOnlineID: () => {},
  recoverOnlineIDType: () => {},
  loginState: {},
  history: [],
  otpDetails: {},
};

export default RecoverOnlineId;