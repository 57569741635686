/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the personal Info Payload
 * @createdDate [02/04/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import generatePersonalDetailsPayload from './GeneratePersonalDetailsPayload';
import generateBeneficiaryPayload from './GenerateBeneficiaryPayload';
import NAOConstants from './consts';

export default function generatePersonalInfoPayload(localStateData, data, nickName) {
    let individualPayload = {};
    let payLoad = {};
    let beneficiaryPayload = {};
    const accType = localStateData.OpenAccPageOne.accountType;
    if (accType.toLowerCase().indexOf('joint') > -1) {
        const { jointPerInfo, perInfo, empInfo, finInfo, milInfo } = data;
        const primaryOwner = {
            perInfo,
            empInfo,
            finInfo,
            milInfo,
            jointPerInfo,
        };
        individualPayload = generatePersonalDetailsPayload(primaryOwner, accType, nickName);
    } else {
        individualPayload = generatePersonalDetailsPayload(data, accType, nickName);
        beneficiaryPayload = data.benInfo ? generateBeneficiaryPayload(localStateData, data.benInfo) : {};
    }
    switch (localStateData.accountType) {
        // TODO :: All the account types should be the account types provided in the 'Default account naming.xls'
        case NAOConstants.ACCT_TYPE_INDIVIDUAL:
        case NAOConstants.ACCT_TYPE_JOINT_TNT_CMN:
        case NAOConstants.ACCT_TYPE_JOINT_TNT_ROS:
        case NAOConstants.ACCT_TYPE_JOINT_TNT_ETY:
            payLoad = individualPayload;
            // payLoad = { ...individualPayload };
            break;
        case NAOConstants.ACCT_TYPE_TRADITIONAL_IRA:
        case NAOConstants.ACCT_TYPE_ROTH_IRA:
        case NAOConstants.ACCT_TYPE_ROLLOVER_IRA:
        case NAOConstants.ACCT_TYPE_SEP_IRA:
        case NAOConstants.ACCT_TYPE_SIMPLE_IRA:
        case NAOConstants.ACCT_TYPE_529:
        case NAOConstants.ACCT_TYPE_403B:
        case NAOConstants.ACCT_TYPE_UGMA_UTMA_TEXT:
            payLoad = { ...individualPayload, ...beneficiaryPayload };
            break;
        default:
            break;
    }
    return payLoad;
}
