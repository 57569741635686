import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import resendIcon from 'assets/icon_resend.svg';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import classes from '../Input/Input.module.css';

export const OtpForm = props => {
    const { changed, blurred, clicked, otpFormState, resendOtpHandler, resendRef, inputRef } = props;

    const data = {
        config: {
            type: 'password',
            label: 'Enter One Time Passcode',
            placeholder: '',
        },
        style: {
            button: {
                float: 'right',
                font: '600 12px/14px benton-sans',
                color: '#004A98',
                padding: '0 0 2px 24px',
                background: `white url(${resendIcon}) no-repeat left bottom`,
                border: 'none',
                marginTop: '10px',
            },
        },
        resendText: 'Resend One Time Passcode',
        resendCLass: ' enterOtp',
        click: (event) => clicked(event)
    };

    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (otpFormState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    useEffect(() => {
        return () => {
             const { analyzeFormAdandonment } = props;
             const digitalData = getDigitalDataFormAband();
             analyzeFormAdandonment(digitalData);
         }
     }, []);

    inputElement = (
        <input
            id="verify-otp"
            type={data.config.type}
            className={`analytics-form-fields ${inputClasses.join(' ')}`}
            value={otpFormState.value}
            onChange={changed}
            onBlur={blurred}
            onClick={clicked ? data.click: ""}
            aria-required="true"
            aria-describedby="verify-otp_error"
            aria-invalid={!!otpFormState.errMsg}
            maxLength={6}
            ref={inputRef}
        />
    );

    return (
        <div className={classes.Input + data.resendCLass}>
            <label className={classes.Label} htmlFor="verify-otp">
                {data.config.label}
            </label>
            {inputElement}
            {otpFormState.errMsg && (
                <p className={classes.errMsg} role="alert" aria-live="polite" aria-atomic="true" id="verify-otp_error">
                    <span className="sr-only">Error:</span>{otpFormState.errMsg}
                </p>
            )}
            <button ref={resendRef} id="resendOtp" type="button" style={data.style.button} onClick={resendOtpHandler}>
                {data.resendText}
            </button>
        </div>
    );
};

OtpForm.propTypes = {
    otpFormState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            isNumeric: PropTypes.bool,
            length: PropTypes.number,
            required: PropTypes.bool,
        }),
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    resendOtpHandler: PropTypes.func,
    resendRef: PropTypes.instanceOf(Object),
    inputRef: PropTypes.instanceOf(Object),
};

OtpForm.defaultProps = {
    otpFormState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            isNumeric: true,
            length: 4,
            required: true,
        },
    },
    changed: () => {},
    blurred: () => {},
    resendOtpHandler: () => {},
    resendRef: {},
    inputRef: {},
};
export default OtpForm;
