import CancelBtn from '../CancelBtn';
import { Container } from 'react-bootstrap';
import { focusToNonFocusableEl } from 'modules/CustomerManagementModule/utils';
import MessageBox from '../MessageBox';
import PrimaryBtn from '../PrimaryBtn';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import './ConformationMessage.css';

const ConfirmationMessageBox = styled(MessageBox)`
    top: 10rem;
    padding: 2.5rem 1.25rem;
    z-index: 10000;
    position: absolute;
`;

const BackDrop = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    @supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
`;
const StyledPara = styled.div`
    margin: auto;
`;
const ConformationTitle = styled.h2`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;
const ConformationBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 1.25rem;
    button:last-of-type {
        margin-left: 3.125rem;
    }
`;

const ESCAPE_KEY_OR_CODE = 'Escape';
const TAB_KEY_OR_CODE = 'Tab';

const onDialogShow = ({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId }) => {
    document.documentElement.style.overflow = 'hidden';
    window.scrollTo(0, 0);
    const prevFocusedEl = previousFocus;
    const preNode = preNodeRef;
    const postNode = postNodeRef;
    prevFocusedEl.current = document.activeElement;
    preNode.current.tabIndex = 0;
    postNode.current.tabIndex = 0;
    // set focus on first input
    focusToNonFocusableEl(titleRef.current);
    setTimeout(() => {
        [].slice.call(document.body.children).forEach(node => {
            if (node.getAttribute('id') !== `vcm-dialog-container-${keyId}`) {
                node.setAttribute('inert', 'true');
            }
        });
    }, 100);
};

const onDialogHide = ({ previousFocus }) => {
    const isOverflowHidden = document.documentElement.style.overflow === 'hidden';
    if (isOverflowHidden) document.documentElement.style.overflow = '';
    [].slice.call(document.body.children).forEach(node => {
        const el = node;
        const isHidden = el.hasAttribute('inert');
        if (isHidden) {
            el.inert = false;
        }
    });
    const prevFocusedEl = previousFocus;
    if (prevFocusedEl && prevFocusedEl.current) {
        setTimeout(() => {
            prevFocusedEl.current.focus();
            prevFocusedEl.current = null;
        }, 100);
    }
};

// Handling onclosing dialog
const onClose = ({ onSecondaryClick }) => e => {
    onSecondaryClick(e);
};

// redirect last tab to first input
const onLastElementTab = ({ firstElRef, onSecondaryClick }) => e => {
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose({ onSecondaryClick })(e);
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && !e.shiftKey) {
        e.preventDefault();
        firstElRef.current.focus();
    }
};
// redirect first shift+tab to last input
const onFirstShiftTab = ({ lastElRef, onSecondaryClick }) => e => {
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose({ onSecondaryClick })(e);
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && e.shiftKey) {
        e.preventDefault();
        lastElRef.current.focus();
    }
};

// If escape pressed when focus on title
const onTitleKeyDown = ({ onSecondaryClick }) => e => {
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose({ onSecondaryClick })(e);
};

const moveFocusToTitle = ({ titleRef }) => () => {
    // set focus on first input
    focusToNonFocusableEl(titleRef.current);
};

const onPrimaryBtnClick = ({ previousFocus, onPrimaryClick }) => e => {
    e.stopPropagation();
    const prevFocusedEl = previousFocus;
    prevFocusedEl.current = null;
    onDialogHide({ previousFocus });
    setTimeout(() => {
        onPrimaryClick(e);
    }, 100);
};

const ConfirmationMessage = props => {
    const titleRef = useRef(null);
    const firstElRef = useRef(null);
    const lastElRef = useRef(null);
    const preNodeRef = useRef(null);
    const postNodeRef = useRef(null);
    const previousFocus = useRef(null);

    const {
        modalTitle,
        modalBodyText,
        modalBodyText2,
        primaryButtonText,
        secondaryButtonText,
        onPrimaryClick,
        onSecondaryClick,
        show,
        keyId,
    } = props;

    useEffect(() => {
        if (show) {
            onDialogShow({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId });
        }
    }, [show, keyId]);

    // to handle scenarios like session timeout
    useEffect(() => {
        return () => {
            onDialogHide({ previousFocus });
        };
    }, []);

    if (!show) {
        onDialogHide({ previousFocus });
    }

    return ReactDOM.createPortal(
        <BackDrop id={`vcm-dialog-container-${keyId}`} onClick={moveFocusToTitle({ titleRef })} show={show}>
            <div ref={preNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
            <ConfirmationMessageBox
                id={`vcm-alert-dialog-${keyId}`}
                role="dialog"
                aria-modal="true"
                aria-labelledby={`vcm-modal-title-${keyId}`}
                aria-describedby={`vcm-modal-title-desc-${keyId}`}
                className="vcm-alert-dialogMobile"
            >
                <Container>
                    {!!modalTitle && (
                        <ConformationTitle
                            ref={titleRef}
                            onKeyDown={onTitleKeyDown({ onSecondaryClick })}
                            id={`vcm-modal-title-${keyId}`}
                            tabIndex="0"
                        >
                            {modalTitle}
                        </ConformationTitle>
                    )}
                    <ConformationBody id={`vcm-modal-title-desc-${keyId}`}  tabIndex="0">
                        {!!modalBodyText && <StyledPara className="vcm-model-titlePara">{modalBodyText}</StyledPara>}
                        {!!modalBodyText2 && <StyledPara> {modalBodyText2} </StyledPara>}
                    </ConformationBody>
                    <FlexDiv className="cancel-BtnAlign">
                        <CancelBtn
                            ref={firstElRef}
                            id={`vcm-modal-secondary-btn-${keyId}`}
                            data-testid={`vcm-modal-secondary-btn-${keyId}`}
                            onClick={onClose({ onSecondaryClick })}
                            onKeyDown={onFirstShiftTab({ lastElRef, onSecondaryClick })}
                            text={secondaryButtonText}
                        />
                        <PrimaryBtn
                            ref={lastElRef}
                            id={`vcm-modal-primary-btn-${keyId}`}
                            data-testid={`vcm-modal-primary-btn-${keyId}`}
                            onClick={onPrimaryBtnClick({ previousFocus, onPrimaryClick })}
                            onKeyDown={onLastElementTab({ firstElRef, onSecondaryClick })}
                        >
                            {primaryButtonText}
                        </PrimaryBtn>
                    </FlexDiv>
                </Container>
            </ConfirmationMessageBox>
            <div ref={postNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
        </BackDrop>,
        document.body,
    );
};

ConfirmationMessage.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    show: PropTypes.bool,
    keyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfirmationMessage.defaultProps = {
    modalLabel: 'Confirmation',
    modalTitle: '',
    modalBodyText: '',
    modalBodyText2: '',
    primaryButtonText: '',
    secondaryButtonText: 'Cancel',
    onPrimaryClick: () => {},
    onSecondaryClick: () => {},
    show: false,
    keyId: 0,
};

export default ConfirmationMessage;
