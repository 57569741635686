import { connect } from 'react-redux';
import MilitaryInfoCard from './MilitaryInfoCard';
import { accOpeningActions, localStateManagementActions } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MilitaryInfoCard);
