import { connect } from 'react-redux';
import get from 'lodash/get';
import { localStateManagementActions, analytics } from 'shared/Actions';
import MemberDobValidation from './MemberDobValidation';


export const mapStateToProps = (state /* , props */) => ({
    dateOfBirth: get(state, 'localStateManagementReducerData.userFields.dateOfBirth', ''),
    pageData: get(state, 'localStateManagementReducerData.pageData', {}),
    otpDetails: get(state, 'localStateManagementReducerData.otpDetails', {}),
});

const mapDispatchToProps = {
  ...localStateManagementActions,
  ...analytics
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDobValidation);