const fontColor = 'rgb(73, 73, 74)';

const summaryParaStyles = {
    marginBottom: 40,
    font: '16px/25px benton-sans',
    color: fontColor,
    fontSize: 16,
};

const summaryBoxStyle = {
    marginTop: 20,
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    width: 219,
};

const summarySubHeadingStyle = { font: '800 22px/45px yorkten-slab-normal', color: fontColor, marginBottom: 10 };

const summaryHeadingStyle = { font: '800 22px/35px yorkten-slab-normal', color: fontColor, marginTop: 18 };

const amount = {
    font: '16px/20px benton-sans',
    color: fontColor,
};

const invest = {
    font: ' 700 16px/22px benton-sans',
    color: fontColor,
};

const flexStyle = { display: 'flex', flexDirection: 'column' };

const flexSpanStyle = {
    display: 'flex',
    maxWidth: 150,
    marginTop: 20,
    font: '500 16px/20px benton-sans',
};

const initialInvestmentStyle = { padding: '20px 0' };

const backButtonStyle = { marginBottom: 42, marginTop: 35 };

const pdfStyle = {
    display: 'flex',
    color: fontColor,
    font: '700 16px/19px benton-sans',
    padding: '25px 0',
    alignItems: 'center',
};

const fontawesomestyle = {
    height: 18,
    width: 15,
    margin: '0 10 0 0',
    color: '#8CBF26',
    position: 'absolute',
    top: 0,
    left: -25,
};

const pdfColStyle1 = { display: 'flex' };

const pdfColStyle2 = { display: 'flex', justifyContent: 'flex-end' };

const mb30 = { marginBottom: 30 };

const riskSpanStyle = {
    fontWeight: 700,
    textAlign: 'left',
    font: ' 600 16px/20px benton-sans',
    letterSpacing: 0,
    color: 'rgba(86, 86, 90)',
    opacity: 1,
};
export default {
    pdfStyle,
    backButtonStyle,
    flexStyle,
    flexSpanStyle,
    initialInvestmentStyle,
    summaryParaStyles,
    summaryBoxStyle,
    summarySubHeadingStyle,
    summaryHeadingStyle,
    amount,
    invest,
    fontawesomestyle,
    pdfColStyle1,
    pdfColStyle2,
    mb30,
    riskSpanStyle,
};
