/* **************************\
Component: Regex
Explanation:
List of Service Action contants used as ActionType
in the action creators and Reducers
============================

\*************************** */

export const LOGIN_SCREEN = 'LOGIN_SCREEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOADER = 'LOGIN_LOADER';
export const RESET_PIN = 'RESET_PIN';
export const RESET_FLOW_LOCAL = 'RESET_FLOW_LOCAL';
export const UPDATE_OTP = 'UPDATE_OTP';
export const SIGNED_SUCCESS = 'SIGNED_SUCCESS';

export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const SET_GUID_TOKEN = 'SET_GUID_TOKEN';
export const SET_ENV = 'SET_ENV';

// Local State Management for Account Management
export const WEB_ACCOUNT_LOCAL_STATE = 'WEB_ACCOUNT_LOCAL_STATE';
export const WEB_ACCOUNT_CLEAR_LOCAL_STATE = 'WEB_ACCOUNT_CLEAR_LOCAL_STATE';
export const WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO = 'WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO';
export const WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO_ACC_TYPE = 'WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO_ACC_TYPE';

// Logout State
export const WEB_ACCOUNT_LOGOUT_STATE = 'WEB_ACCOUNT_LOGOUT_STATE';
export const WEB_ACCOUNT_CLEAR_LOGOUT_STATE = 'WEB_ACCOUNT_CLEAR_LOGOUT_STATE';
export const LOCAL_STATE = 'LOCAL_STATE';

// Account Management
export const LOADING = 'LOADING';

export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';
export const UPLOAD_AVATAR_ERROR = 'UPLOAD_AVATAR_ERROR';
export const UPLOAD_AVATAR_LOADER = 'UPLOAD_AVATAR_LOADER';

export const UPLOAD_W8FORM = 'UPLOAD_W8FORM';
export const UPLOAD_W8FORM_SUCCESS = 'UPLOAD_W8FORM_SUCCESS';
export const UPLOAD_W8FORM_FAILURE = 'UPLOAD_W8FORM_FAILURE';
export const UPLOAD_W8FORM_ERROR = 'UPLOAD_W8FORM_ERROR';
export const UPLOAD_W8FORM_LOADER = 'UPLOAD_W8FORM_LOADER';

export const GET_AVATAR = 'GET_AVATAR';
export const GET_AVATAR_SUCCESS = 'GET_AVATAR_SUCCESS';
export const GET_AVATAR_FAILURE = 'GET_AVATAR_FAILURE';
export const GET_AVATAR_ERROR = 'GET_AVATAR_ERROR';

export const GET_ACCOUNT_TYPES = 'GET_ACCOUNT_TYPES';
export const GET_ACCOUNT_TYPES_SUCCESS = 'GET_ACCOUNT_TYPES_SUCCESS';
export const GET_ACCOUNT_TYPES_FAILURE = 'GET_ACCOUNT_TYPES_FAILURE';
export const GET_ACCOUNT_TYPES_ERROR = 'GET_ACCOUNT_TYPES_ERROR';
export const GET_ACCOUNT_TYPES_LOADER = 'GET_ACCOUNT_TYPES_LOADER';

export const GET_ACCOUNT_SUBTYPES = 'GET_ACCOUNT_SUBTYPES';
export const GET_ACCOUNT_SUBTYPES_SUCCESS = 'GET_ACCOUNT_SUBTYPES_SUCCESS';
export const GET_ACCOUNT_SUBTYPES_FAILURE = 'GET_ACCOUNT_SUBTYPES_FAILURE';
export const GET_ACCOUNT_SUBTYPES_ERROR = 'GET_ACCOUNT_SUBTYPES_ERROR';
export const GET_ACCOUNT_SUBTYPES_LOADER = 'GET_ACCOUNT_SUBTYPES_LOADER';

export const GET_ACCT_TYPE_COMPOSITE_DATA = 'GET_ACCT_TYPE_COMPOSITE_DATA';
export const GET_ACCT_TYPE_COMPOSITE_DATA_SUCCESS = 'GET_ACCT_TYPE_COMPOSITE_DATA_SUCCESS';
export const GET_ACCT_TYPE_COMPOSITE_DATA_FAILURE = 'GET_ACCT_TYPE_COMPOSITE_DATA_FAILURE';
export const GET_ACCT_TYPE_COMPOSITE_DATA_ERROR = 'GET_ACCT_TYPE_COMPOSITE_DATA_ERROR';
export const GET_ACCT_TYPE_COMPOSITE_DATA_LOADER = 'GET_ACCT_TYPE_COMPOSITE_DATA_LOADER';

export const SELECT_ACCT = 'SELECT_ACCT';
export const SELECT_ACCT_TYPE = 'SELECT_ACCT_TYPE';

export const GET_PREFIX = 'GET_PREFIX';
export const GET_PREFIX_SUCCESS = 'GET_PREFIX_SUCCESS';
export const GET_PREFIX_FAILURE = 'GET_PREFIX_FAILURE';
export const GET_PREFIX_ERROR = 'GET_PREFIX_ERROR';

export const GET_RANK = 'GET_RANK';
export const GET_RANK_SUCCESS = 'GET_RANK_SUCCESS';
export const GET_RANK_FAILURE = 'GET_RANK_FAILURE';
export const GET_RANK_ERROR = 'GET_RANK_ERROR';
export const GET_RANK_LOADER = 'GET_RANK_LOADER';

export const GET_PERSONAL_COMPOSITE_DATA = 'GET_PERSONAL_COMPOSITE_DATA';
export const GET_PERSONAL_COMPOSITE_DATA_SUCCESS = 'GET_PERSONAL_COMPOSITE_DATA_SUCCESS';
export const GET_PERSONAL_COMPOSITE_DATA_FAILURE = 'GET_PERSONAL_COMPOSITE_DATA_FAILURE';
export const GET_PERSONAL_COMPOSITE_DATA_LOADER = 'GET_PERSONAL_COMPOSITE_DATA_LOADER';

export const GET_COMPOSITE_DATA = 'GET_COMPOSITE_DATA';
export const GET_COMPOSITE_DATA_SUCCESS = 'GET_COMPOSITE_DATA_SUCCESS';
export const GET_COMPOSITE_DATA_FAILURE = 'GET_COMPOSITE_DATA_FAILURE';
export const GET_COMPOSITE_DATA_ERROR = 'GET_COMPOSITE_DATA_ERROR';
export const GET_COMPOSITE_DATA_LOADER = 'GET_COMPOSITE_DATA_LOADER';

export const GET_FUNDDETAILS = 'GET_FUNDDETAILS';
export const GET_FUNDDETAILS_SUCCESS = 'GET_FUNDDETAILS_SUCCESS';
export const GET_FUNDDETAILS_FAILURE = 'GET_FUNDDETAILS_FAILURE';
export const GET_FUNDDETAILS_ERROR = 'GET_FUNDDETAILS_ERROR';
export const GET_FUNDDETAILS_LOADER = 'GET_FUNDDETAILS_LOADER';

export const GET_FUNDLIST = 'GET_FUNDLIST';
export const GET_FUNDLIST_SUCCESS = 'GET_FUNDLIST_SUCCESS';
export const GET_FUNDLIST_FAILURE = 'GET_FUNDLIST_FAILURE';
export const GET_ACCOUNT_FUNDLIST_ERROR = 'GET_ACCOUNT_FUNDLIST_ERROR';
export const GET_ACCOUNT_FUNDLIST_SUCCESS = 'GET_ACCOUNT_FUNDLIST_SUCCESS';
export const GET_ACCOUNT_FUNDLIST_FAILURE = 'GET_ACCOUNT_FUNDLIST_FAILURE';
export const GET_FUNDLIST_ERROR = 'GET_FUNDLIST_ERROR';
export const GET_FUNDLIST_LOADER = 'GET_FUNDLIST_LOADER';
export const FUND_INVERSMENT = 'FUND_INVERSMENT';

export const SUBMIT_OPENING_ACCT = 'SUBMIT_OPENING_ACCT';
export const SUBMIT_IRA_OPENING_ACCT = 'SUBMIT_IRA_OPENING_ACCT';
export const SUBMIT_JOINT_OPENING_ACCT = 'SUBMIT_JOINT_OPENING_ACCT';
export const SUBMIT_UTMA_OPENING_ACCT = 'SUBMIT_UTMA_OPENING_ACCT';
export const SUBMIT_OPENING_ACCT_SUCCESS = 'SUBMIT_OPENING_ACCT_SUCCESS';
export const SUBMIT_OPENING_ACCT_FAILURE = 'SUBMIT_OPENING_ACCT_FAILURE';
export const SUBMIT_OPENING_ACCT_ERROR = 'SUBMIT_OPENING_ACCT_ERROR';
export const SUBMIT_OPENING_ACCT_LOADER = 'SUBMIT_OPENING_ACCT_LOADER';

export const GET_OPENED_ACCOUNTS_PREFERENCES = 'GET_OPENED_ACCOUNTS_PREFERENCES';
export const GET_OPENED_ACCOUNTS_PREFERENCES_SUCCESS = 'GET_OPENED_ACCOUNTS_PREFERENCES_SUCCESS';
export const GET_OPENED_ACCOUNTS_PREFERENCES_FAILURE = 'GET_OPENED_ACCOUNTS_PREFERENCES_FAILURE';
export const GET_OPENED_ACCOUNTS_PREFERENCES_ERROR = 'GET_OPENED_ACCOUNTS_PREFERENCES_ERROR';
export const GET_OPENED_ACCOUNTS_PREFERENCES_LOADER = 'GET_OPENED_ACCOUNTS_PREFERENCES_LOADER';

export const SAVE_OPENED_ACCOUNTS_PREFERENCE = 'SAVE_OPENED_ACCOUNTS_PREFERENCE';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_SUCCESS = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_SUCCESS';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FAILURE = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FAILURE';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_ERROR = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_ERROR';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_LOADER = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_LOADER';

export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_SUCCESS = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_SUCCESS';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_FAILURE = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_FAILURE';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_ERROR = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_ERROR';
export const SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_LOADER = 'SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL_LOADER';

export const SET_ACCOUNT_PREFERENCES = 'SET_ACCOUNT_PREFERENCES';

// DASHBOARD

export const ACCOUNT_DETAILS_GUEST = 'ACCOUNT_DETAILS_GUEST';

export const RETRIVE_OPENING_ACCT = 'RETRIVE_OPENING_ACCT';
export const RETRIVE_OPENING_ACCT_SUCCESS = 'RETRIVE_OPENING_ACCT_SUCCESS';
export const RETRIVE_OPENING_ACCT_FAILURE = 'RETRIVE_OPENING_ACCT_FAILURE';
export const RETRIVE_OPENING_ACCT_ERROR = 'RETRIVE_OPENING_ACCT_ERROR';
export const RETRIVE_OPENING_ACCT_LOADER = 'RETRIVE_OPENING_ACCT_LOADER';

export const ACCT_TYPE_SAVE_OPENING_ACCT = 'ACCT_TYPE_SAVE_OPENING_ACCT';
export const ACCT_TYPE_SAVE_OPENING_ACCT_SUCCESS = 'ACCT_TYPE_SAVE_OPENING_ACCT_SUCCESS';
export const ACCT_TYPE_SAVE_OPENING_ACCT_FAILURE = 'ACCT_TYPE_SAVE_OPENING_ACCT_FAILURE';
export const ACCT_TYPE_SAVE_OPENING_ACCT_ERROR = 'ACCT_TYPE_SAVE_OPENING_ACCT_ERROR';
export const ACCT_TYPE_SAVE_OPENING_ACCT_LOADER = 'ACCT_TYPE_SAVE_OPENING_ACCT';

export const PERSONAL_INFO_SAVE_OPENING_ACCT = 'PERSONAL_INFO_SAVE_OPENING_ACCT';
export const PERSONAL_INFO_SAVE_OPENING_ACCT_SUCCESS = 'PERSONAL_INFO_SAVE_OPENING_ACCT_SUCCESS';
export const PERSONAL_INFO_SAVE_OPENING_ACCT_FAILURE = 'PERSONAL_INFO_SAVE_OPENING_ACCT_FAILURE';
export const PERSONAL_INFO_SAVE_OPENING_ACCT_ERROR = 'PERSONAL_INFO_SAVE_OPENING_ACCT_ERROR';
export const PERSONAL_INFO_SAVE_OPENING_ACCT_LOADER = 'PERSONAL_INFO_SAVE_OPENING_ACCT_LOADER';

export const INVEST_SELECT_SAVE_OPENING_ACCT = 'INVEST_SELECT_SAVE_OPENING_ACCT';
export const INVEST_SELECT_SAVE_OPENING_ACCT_SUCCESS = 'INVEST_SELECT_SAVE_OPENING_ACCT_SUCCESS';
export const INVEST_SELECT_SAVE_OPENING_ACCT_FAILURE = 'INVEST_SELECT_SAVE_OPENING_ACCT_FAILURE';
export const INVEST_SELECT_SAVE_OPENING_ACCT_ERROR = 'INVEST_SELECT_SAVE_OPENING_ACCT_ERROR';
export const INVEST_SELECT_SAVE_OPENING_ACCT_LOADER = 'INVEST_SELECT_SAVE_OPENING_ACCT_LOADER';

export const PREFERENCE_SAVE_OPENING_ACCT = 'PREFERENCE_SAVE_OPENING_ACCT';
export const PREFERENCE_SAVE_OPENING_ACCT_SUCCESS = 'PREFERENCE_SAVE_OPENING_ACCT_SUCCESS';
export const PREFERENCE_SAVE_OPENING_ACCT_FAILURE = 'PREFERENCE_SAVE_OPENING_ACCT_FAILURE';
export const PREFERENCE_SAVE_OPENING_ACCT_ERROR = 'PREFERENCE_SAVE_OPENING_ACCT_ERROR';
export const PREFERENCE_SAVE_OPENING_ACCT_LOADER = 'PREFERENCE_SAVE_OPENING_ACCT_LOADER';

export const VERIFY_SAVE_OPENING_ACCT = 'VERIFY_SAVE_OPENING_ACCT';
export const VERIFY_SAVE_OPENING_ACCT_SUCCESS = 'VERIFY_SAVE_OPENING_ACCT_SUCCESS';
export const VERIFY_SAVE_OPENING_ACCT_FAILURE = 'VERIFY_SAVE_OPENING_ACCT_FAILURE';
export const VERIFY_SAVE_OPENING_ACCT_ERROR = 'VERIFY_SAVE_OPENING_ACCT_ERROR';
export const VERIFY_SAVE_OPENING_ACCT_LOADER = 'VERIFY_SAVE_OPENING_ACCT_LOADER';

export const ESIGN_SAVE_OPENING_ACCT = 'ESIGN_SAVE_OPENING_ACCT';
export const ESIGN_SAVE_OPENING_ACCT_SUCCESS = 'ESIGN_SAVE_OPENING_ACCT_SUCCESS';
export const ESIGN_SAVE_OPENING_ACCT_FAILURE = 'ESIGN_SAVE_OPENING_ACCT_FAILURE';
export const ESIGN_SAVE_OPENING_ACCT_ERROR = 'ESIGN_SAVE_OPENING_ACCT_ERROR';
export const ESIGN_SAVE_OPENING_ACCT_LOADER = 'ESIGN_SAVE_OPENING_ACCT_LOADER';

export const VERIFIED_EMAIL = 'VERIFIED_EMAIL';
export const VERIFIED_PIN = 'VERIFIED_PIN';
export const VERIFIED_PHONE = 'VERIFIED_PHONE';

export const GET_USER_PRORILE_DATA = 'GET_USER_PRORILE_DATA';
export const GET_EDIT_PROFILE_MANAGE_SUCCESS = 'GET_EDIT_PROFILE_MANAGE_SUCCESS';
export const GET_EDIT_FINANCIAL_INFORMATIONS = 'GET_EDIT_FINANCIAL_INFORMATIONS';
export const GET_EDIT_EMPLOYMENT_INFORMATIONS = 'GET_EDIT_EMPLOYMENT_INFORMATIONS';
export const GET_EDIT_CONTACT_INFORMATIONS = 'GET_EDIT_CONTACT_INFORMATIONS';
export const GET_EDIT_PHONE_INFORMATIONS = 'GET_EDIT_PHONE_INFORMATIONS';

export const ADD_NEW_RELATIONSHIP_DETAILS = 'ADD_NEW_RELATIONSHIP_DETAILS';
export const ADD_PROFILE_OCCUPATION_STATUS = 'ADD_PROFILE_OCCUPATION_STATUS';
export const ADD_PROFILE_MILITARY_INFORMATION = 'ADD_PROFILE_MILITARY_INFORMATION';

export const UPDATE_PRIMARY_MOBILE_NUMBER = 'UPDATE_PRIMARY_MOBILE_NUMBER';

export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_ERROR = 'ADD_BANK_ACCOUNT_ERROR';
export const ADD_BANK_ACCOUNT_LOADER = 'ADD_BANK_ACCOUNT_LOADER';

export const ADD_BANK_ACCOUNT_NAO_TRANS = 'ADD_BANK_ACCOUNT_NAO_TRANS';
export const ADD_BANK_ACCOUNT_NAO_TRANS_SUCCESS = 'ADD_BANK_ACCOUNT_NAO_TRANS_SUCCESS';
export const ADD_BANK_ACCOUNT_NAO_TRANS_ERROR = 'ADD_BANK_ACCOUNT_NAO_TRANS_ERROR';
export const ADD_BANK_ACCOUNT_NAO_TRANS_LOADER = 'ADD_BANK_ACCOUNT_NAO_TRANS_LOADER';
export const ADD_BANK_ACCOUNT_NAO_TRANS_RESET = 'ADD_BANK_ACCOUNT_NAO_TRANS_RESET';

export const GET_STATECITY = 'GET_STATECITY';
export const GET_STATECITY_SUCCESS = 'GET_STATECITY_SUCCESS';
export const GET_STATECITY_FAILURE = 'GET_STATECITY_FAILURE';
export const GET_STATECITY_ERROR = 'GET_STATECITY_ERROR';
export const GET_STATECITY_LOADER = 'GET_STATECITY_LOADER';

export const GET_ADDRESSFORMAT = 'GET_ADDRESSFORMAT';
export const GET_ADDRESSFORMAT_SUCCESS = 'GET_ADDRESSFORMAT_SUCCESS';
export const GET_ADDRESSFORMAT_FAILURE = 'GET_ADDRESSFORMAT_FAILURE';
export const GET_ADDRESSFORMAT_ERROR = 'GET_ADDRESSFORMAT_ERROR';
export const GET_ADDRESSFORMAT_LOADER = 'GET_ADDRESSFORMAT_LOADER';

export const GET_MULTIPLE_ADDRESS_FORMATTED = 'GET_MULTIPLE_ADDRESS_FORMATTED';
export const GET_MULTIPLE_ADDRESS_FORMATTED_SUCCESS = 'GET_MULTIPLE_ADDRESS_FORMATTED_SUCCESS';
export const GET_MULTIPLE_ADDRESS_FORMATTED_ERROR = 'GET_MULTIPLE_ADDRESS_FORMATTED_ERROR';

export const GET_FORMATTED_ADDRESS_SUCCESS = 'GET_FORMATTED_ADDRESS_SUCCESS';
export const GET_FORMATTED_ADDRESS_ERROR = 'GET_FORMATTED_ADDRESS_ERROR';
export const ADDRESS_REMOVE_EXISTING_VALUES = 'ADDRESS_REMOVE_EXISTING_VALUES';

export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA';
export const SET_ADDRESS_LINE1 = 'SET_ADDRESS_LINE1';
export const SET_ADDRESS_LINE2 = 'SET_ADDRESS_LINE2';
export const SET_ZIPCODE = 'SET_ZIPCODE';
export const SET_ADDRESS_LINE2_ERROR = 'SET_ADDRESS_LINE2_ERROR';
export const SET_ZIPCODE_ERROR = 'SET_ZIPCODE_ERROR';
export const SET_CALL_ADDRESS_API = 'SET_CALL_ADDRESS_API';
export const RESET_ADDRESS_INFO = 'RESET_ADDRESS_INFO';
export const SET_CITY = 'SET_CITY';
export const SET_STATE = 'SET_STATE';
export const HIDE_USPS_DIALOG = 'HIDE_USPS_DIALOG';
export const SET_ADDRESS_READY_STATUS = 'SET_ADDRESS_READY_STATUS';

export const POPULATE_PENDING_APPLICATION = 'POPULATE_PENDING_APPLICATION';

// Account Services
export const GET_DIVIDENTS_INFO = 'GET_DIVIDENTS_INFO';
export const GET_BANK_ACCOUNT_INFO = 'GET_BANK_ACCOUNT_INFO';
export const GET_POPULAR_BANK_NAMES = 'GET_POPULAR_BANK_NAMES';
export const DELETE_BANK_ACCOUNT_INFO = 'DELETE_BANK_ACCOUNT_INFO';
export const ADD_BANK_ACCOUNT_INFO = 'ADD_BANK_ACCOUNT_INFO';
export const RESET_UPDATE_FLAG = 'RESET_UPDATE_FLAG';

// Manage Interested Parties
export const ADD_INTERESTED_PARTY = 'ADD_INTERESTED_PARTY';
export const ADD_INTERESTED_PARTY_LOADING = 'ADD_INTERESTED_PARTY_LOADING';
export const ADD_INTERESTED_PARTY_SUCCESS = 'ADD_INTERESTED_PARTY_SUCCESS';
export const ADD_INTERESTED_PARTY_ERROR = 'ADD_INTERESTED_PARTY_ERROR';
export const ADD_INTERESTED_PARTY_FAILURE = 'ADD_INTERESTED_PARTY_FAILURE';

export const GET_INTERESTED_PARTY = 'GET_INTERESTED_PARTY';
export const GET_INTERESTED_PARTY_LOADING = 'GET_INTERESTED_PARTY_LOADING';
export const GET_INTERESTED_PARTY_SUCCESS = 'GET_INTERESTED_PARTY_SUCCESS';
export const GET_INTERESTED_PARTY_ERROR = 'GET_INTERESTED_PARTY_ERROR';
export const GET_INTERESTED_PARTY_FAILURE = 'GET_INTERESTED_PARTY_FAILURE';

export const UPDATE_INTERESTED_PARTY = 'UPDATE_INTERESTED_PARTY';
export const UPDATE_INTERESTED_PARTY_LOADING = 'UPDATE_INTERESTED_PARTY_LOADING';
export const UPDATE_INTERESTED_PARTY_SUCCESS = 'UPDATE_INTERESTED_PARTY_SUCCESS';
export const UPDATE_INTERESTED_PARTY_ERROR = 'UPDATE_INTERESTED_PARTY_ERROR';
export const UPDATE_INTERESTED_PARTY_FAILURE = 'UPDATE_INTERESTED_PARTY_FAILURE';

export const DELETE_INTERESTED_PARTY = 'DELETE_INTERESTED_PARTY';
export const DELETE_INTERESTED_PARTY_LOADING = 'DELETE_INTERESTED_PARTY_LOADING';
export const DELETE_INTERESTED_PARTY_SUCCESS = 'DELETE_INTERESTED_PARTY_SUCCESS';
export const DELETE_INTERESTED_PARTY_ERROR = 'DELETE_INTERESTED_PARTY_ERROR';
export const DELETE_INTERESTED_PARTY_FAILURE = 'DELETE_INTERESTED_PARTY_FAILURE';

export const SAVE_INTERESTED_PARTY_DATA = 'SAVE_INTERESTED_PARTY_DATA';

// Manage Beneficiary
export const GET_BENEFICIARY = 'GET_BENEFICIARY';
export const GET_BENEFICIARY_SUCCESS = 'GET_BENEFICIARY_SUCCESS';
export const GET_BENEFICIARY_LOADING = 'GET_BENEFICIARY_LOADING';
export const GET_BENEFICIARY_FAILURE = 'GET_BENEFICIARY_FAILURE';
export const GET_EDIT_BENEFICIARY = 'GET_EDIT_BENEFICIARY';

export const CLEAR_BENEFICIARY_DATA = 'CLEAR_BENEFICIARY_DATA';
export const SAVE_BENEFICIARY = 'SAVE_BENEFICIARY';
export const SAVE_BENEFICIARY_LOADING = 'SAVE_BENEFICIARY_LOADING';
export const SAVE_BENEFICIARY_SUCCESS = 'SAVE_BENEFICIARY_SUCCESS';
export const SAVE_BENEFICIARY_ERROR = 'SAVE_BENEFICIARY_ERROR';

export const DELETE_BENEFICIARY = 'DELETE_BENEFICIARY';
export const DELETE_BENEFICIARY_LOADING = 'DELETE_BENEFICIARY_LOADING';
export const DELETE_BENEFICIARY_SUCCESS = 'DELETE_BENEFICIARY_SUCCESS';
export const DELETE_BENEFICIARY_ERROR = 'DELETE_BENEFICIARY_ERROR';

// Beneficiary Master Regs
export const GET_BENEFICIARIES_MASTER_REGS = 'GET_BENEFICIARIES_MASTER_REGS';
export const GET_BENEFICIARIES_MASTER_REGS_SUCCESS = 'GET_BENEFICIARIES_MASTER_REGS_SUCCESS';
export const GET_BENEFICIARIES_MASTER_REGS_LOADING = 'GET_BENEFICIARIES_MASTER_REGS_LOADING';
export const GET_BENEFICIARIES_MASTER_REGS_FAILURE = 'GET_BENEFICIARIES_MASTER_REGS_FAILURE';


// Bank Account

export const GET_BANK_ACCOUNT_DETAILS = 'GET_BANK_ACCOUNT_DETAILS';
export const GET_BANK_ACCOUNT_DETAILS_SUCCESS = 'GET_BANK_ACCOUNT_DETAILS_SUCCESS';
export const GET_BANK_ACCOUNT_DETAILS_FAILURE = 'GET_BANK_ACCOUNT_DETAILS_FAILURE';
export const GET_BANK_ACCOUNT_DETAILS_LOADER = 'GET_BANK_ACCOUNT_DETAILS_LOADER';
export const BANK_ACCOUNT_DETAILS_RESET = 'BANK_ACCOUNT_DETAILS_RESET';
export const ADD_BANK_ACCOUNT_CLEAR_ERROR = 'ADD_BANK_ACCOUNT_CLEAR_ERROR';
export const VERIFY_BANK_ACCOUNT_DETAILS_RESET = 'VERIFY_BANK_ACCOUNT_DETAILS_RESET';

export const GET_BANK_ACCOUNT_LIST = 'GET_BANK_ACCOUNT_LIST';
export const GET_BANK_ACCOUNT_LIST_SUCCESS = 'GET_BANK_ACCOUNT_LIST_SUCCESS';
export const GET_BANK_ACCOUNT_LIST_FAILURE = 'GET_BANK_ACCOUNT_LIST_FAILURE';

export const GET_BENEFICIARY_NEW = 'GET_BENEFICIARY_NEW';
export const GET_BENEFICIARY_NEW_FAILURE = 'GET_BENEFICIARY_NEW_FAILURE';
export const GET_BENEFICIARY_NEW_SUCCESS = 'GET_BENEFICIARY_NEW_SUCCESS';
export const CLEAR_MANAGE_BENEFICIARY_DETAILS = 'CLEAR_MANAGE_BENEFICIARY_DETAILS';

export const VALIDATE_BANK_ACCOUNT = 'VALIDATE_BANK_ACCOUNT';
export const VALIDATE_BANK_ACCOUNT_SUCCESS = 'VALIDATE_BANK_ACCOUNT_SUCCESS';
export const VALIDATE_BANK_ACCOUNT_FAILURE = 'VALIDATE_BANK_ACCOUNT_FAILURE';
// NOT NEEDED
// ### START ###
export const VALIDATE_BANK_ACCOUNT_ERROR = 'VALIDATE_BANK_ACCOUNT_ERROR';
export const VALIDATE_BANK_ACCOUNT_LOADER = 'VALIDATE_BANK_ACCOUNT_LOADER';
// ### END ###

export const ADD_BANK_ACCOUNT_DETAILS = 'ADD_BANK_ACCOUNT_DETAILS';
export const ADD_BANK_ACCOUNT_DETAILS_SUCCESS = 'ADD_BANK_ACCOUNT_DETAILS_SUCCESS';
export const ADD_BANK_ACCOUNT_DETAILS_FAILURE = 'ADD_BANK_ACCOUNT_DETAILS_FAILURE';

export const DELETE_BANK_ACCOUNT_DETAILS = 'DELETE_BANK_ACCOUNT_DETAILS';
export const DELETE_BANK_ACCOUNT_DETAILS_SUCCESS = 'DELETE_BANK_ACCOUNT_DETAILS_SUCCESS';
export const DELETE_BANK_ACCOUNT_DETAILS_FAILURE = 'DELETE_BANK_ACCOUNT_DETAILS_FAILURE';

export const ADD_NEW_BANK_ACCOUNT_DETAILS = 'ADD_NEW_BANK_ACCOUNT_DETAILS';
export const ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS = 'ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS';
export const ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE = 'ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE';

// Manage Checkbook
export const GET_CHECKBOOK_INFO = 'GET_CHECKBOOK_INFO';
export const GET_CHECKBOOK_INFO_SUCCESS = 'GET_CHECKBOOK_INFO_SUCCESS';
export const GET_CHECKBOOK_INFO_LOADING = 'GET_CHECKBOOK_LOADING';
export const GET_CHECKBOOK_INFO_FAILURE = 'GET_CHECKBOOK_FAILURE';

export const UPDATE_CHECKBOOK = 'UPDATE_CHECKBOOK';
export const UPDATE_CHECKBOOK_SUCCESS = 'UPDATE_CHECKBOOK_SUCCESS';
export const UPDATE_CHECKBOOK_FAILURE = 'UPDATE_CHECKBOOK_FAILURE';
export const UPDATE_CHECKBOOK_LOADING = 'UPDATE_CHECKBOOK_LOADING';
export const UPDATE_CHECKBOOK_RESET = 'UPDATE_CHECKBOOK_RESET';
// Manage Dividents

export const GET_ACCOUNTS_LIST = 'GET_ACCOUNTS_LIST';
export const GET_ACCOUNTS_LIST_SUCCESS = 'GET_ACCOUNTS_LIST_SUCCESS';
export const GET_ACCOUNTS_LIST_FAILURE = 'GET_ACCOUNTS_LIST_FAILURE';
export const GET_ACCOUNTS_LIST_LOADING = 'GET_ACCOUNTS_LIST_LOADING';
export const CHANGE_FUNDS_OPTIONS = 'CHANGE_FUNDS_OPTIONS';

export const UPDATE_DIVIDENDS = 'UPDATE_DIVIDENDS';
export const UPDATE_DIVIDENDS_SUCCESS = 'UPDATE_DIVIDENDS_SUCCESS';
export const UPDATE_DIVIDENDS_FAILURE = 'UPDATE_DIVIDENDS_FAILURE';
export const UPDATE_DIVIDENDS_LOADING = 'UPDATE_DIVIDENDS_LOADING';

// Automatic Investment Plan
export const AUTO_INVEST_GET_PAD_DETAILS = 'AUTO_INVEST_GET_PAD_DETAILS';
export const AUTO_INVEST_GET_PAD_DETAILS_SUCCESS = 'AUTO_INVEST_GET_PAD_DETAILS_SUCCESS';
export const AUTO_INVEST_GET_PAD_DETAILS_FAILURE = 'AUTO_INVEST_GET_PAD_DETAILS_FAILURE';
export const AUTO_INVEST_PAD_DETAILS_RESET = 'AUTO_INVEST_PAD_DETAILS_RESET';

export const AUTO_INVEST_UPDATE_PAD_DETAILS = 'AUTO_INVEST_UPDATE_PAD_DETAILS';
export const AUTO_INVEST_UPDATE_PAD_DETAILS_SUCCESS = 'AUTO_INVEST_UPDATE_PAD_DETAILS_SUCCESS';
export const AUTO_INVEST_UPDATE_PAD_DETAILS_FAILURE = 'AUTO_INVEST_UPDATE_PAD_DETAILS_FAILURE';

export const AUTO_INVEST_ADD_PAD_DETAILS = 'AUTO_INVEST_ADD_PAD_DETAILS';
export const AUTO_INVEST_ADD_PAD_DETAILS_SUCCESS = 'AUTO_INVEST_ADD_PAD_DETAILS_SUCCESS';
export const AUTO_INVEST_ADD_PAD_DETAILS_FAILURE = 'AUTO_INVEST_ADD_PAD_DETAILS_FAILURE';
export const DISMISS_AUTO_INVEST_ADD_PAD_DETAILS_ERROR = 'DISMISS_AUTO_INVEST_ADD_PAD_DETAILS_ERROR';
export const DISMISS_INVESTMENT_WITHDRAWAL_ERROR = 'DISMISS_INVESTMENT_WITHDRAWAL_ERROR';

export const SKIP_INVEST_WITHDRAW_PLAN = 'SKIP_INVEST_WITHDRAW_PLAN';
export const SKIP_INVEST_WITHDRAW_PLAN_SUCCESS = 'SKIP_INVEST_WITHDRAW_PLAN_SUCCESS';
export const SKIP_INVEST_WITHDRAW_PLAN_FAILURE = 'SKIP_INVEST_WITHDRAW_PLAN_FAILURE';
export const SKIP_INVEST_WITHDRAW_PLAN_LOADER = 'SKIP_INVEST_WITHDRAW_PLAN_LOADER';

export const DELETE_INVEST_WITHDRAW_PLAN = 'DELETE_INVEST_WITHDRAW_PLAN';
export const DELETE_INVEST_WITHDRAW_PLAN_SUCCESS = 'DELETE_INVEST_WITHDRAW_PLAN_SUCCESS';
export const DELETE_INVEST_WITHDRAW_PLAN_FAILURE = 'DELETE_INVEST_WITHDRAW_PLAN_FAILURE';
export const DELETE_INVEST_WITHDRAW_PLAN_LOADER = 'DELETE_INVEST_WITHDRAW_PLAN_LOADER';
export const RESET_DELETE_FLAG = 'RESET_DELETE_FLAG';

export const RESET_EXT_LIST = 'RESET_EXT_LIST';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Systematic Withdrawal Plan
export const SYS_WITHDRAWAL_ADD_SWP_DETAILS = 'SYS_WITHDRAWAL_ADD_SWP_DETAILS';
export const SYS_WITHDRAWAL_ADD_SWP_DETAILS_SUCCESS = 'SYS_WITHDRAWAL_ADD_SWP_DETAILS_SUCCESS';
export const SYS_WITHDRAWAL_ADD_SWP_DETAILS_FAILURE = 'SYS_WITHDRAWAL_ADD_SWP_DETAILS_FAILURE';
export const SYS_WITHDRAWAL_ADD_SWP_DETAILS_RESET = 'SYS_WITHDRAWAL_ADD_SWP_DETAILS_RESET';

export const SYS_WITHDRAWAL_GET_SWP_DETAILS = 'SYS_WITHDRAWAL_GET_SWP_DETAILS';
export const SYS_WITHDRAWAL_GET_SWP_DETAILS_LOADING = 'SYS_WITHDRAWAL_GET_SWP_DETAILS_LOADING';
export const SYS_WITHDRAWAL_GET_SWP_DETAILS_SUCCESS = 'SYS_WITHDRAWAL_GET_SWP_DETAILS_SUCCESS';
export const SYS_WITHDRAWAL_GET_SWP_DETAILS_FAILURE = 'SYS_WITHDRAWAL_GET_SWP_DETAILS_FAILURE';

export const SYS_WITHDRAWAL_UPDATE_SWP_DETAILS = 'SYS_WITHDRAWAL_UPDATE_SWP_DETAILS';
export const SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_SUCCESS = 'SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_SUCCESS';
export const SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_FAILURE = 'SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_FAILURE';

export const SYS_WITHDRAWAL_DELETE_SWP_DETAILS = 'SYS_WITHDRAWAL_DELETE_SWP_DETAILS';
export const SYS_WITHDRAWAL_DELETE_SWP_DETAILS_SUCCESS = 'SYS_WITHDRAWAL_DELETE_SWP_DETAILS_SUCCESS';
export const SYS_WITHDRAWAL_DELETE_SWP_DETAILS_FAILURE = 'SYS_WITHDRAWAL_DELETE_SWP_DETAILS_FAILURE';

export const SYS_WITHDRAWAL_SKIP_SWP_PLAN = 'SYS_WITHDRAWAL_SKIP_SWP_PLAN';
export const SYS_WITHDRAWAL_SKIP_SWP_PLAN_SUCCESS = 'SYS_WITHDRAWAL_SKIP_SWP_PLAN_SUCCESS';
export const SYS_WITHDRAWAL_SKIP_SWP_PLAN_FAILURE = 'SYS_WITHDRAWAL_SKIP_SWP_PLAN_FAILURE';
export const DISMISS_SYS_WITHDRAWAL_ERROR = 'DISMISS_SYS_WITHDRAWAL_ERROR';
export const AUTO_INVEST_UPDATE_SWP_DETAILS = 'AUTO_INVEST_UPDATE_SWP_DETAILS';
export const AUTO_INVEST_UPDATE_SWP_DETAILS_SUCCESS = 'AUTO_INVEST_UPDATE_SWP_DETAILS_SUCCESS';
export const AUTO_INVEST_UPDATE_SWP_DETAILS_FAILURE = 'AUTO_INVEST_UPDATE_SWP_DETAILS_FAILURE';

// Save PAD SWP
export const INVEST_WITHDRAW_SAVE_ITEM = 'INVEST_WITHDRAW_SAVE_ITEM';
export const INVEST_WITHDRAW_SAVE_ACCOUNT = 'INVEST_WITHDRAW_SAVE_ACCOUNT';
export const INVEST_WITHDRAW_SAVE_FUND = 'INVEST_WITHDRAW_SAVE_FUND';
export const INVEST_WITHDRAW_SAVE_SCHEDULE = 'INVEST_WITHDRAW_SAVE_SCHEDULE';
export const INVEST_WITHDRAW_SAVE_TAX = 'INVEST_WITHDRAW_SAVE_TAX';
export const INVEST_WITHDRAW_SAVE_BANK = 'INVEST_WITHDRAW_SAVE_BANK';
export const RESET_INVEST_WITHDRAW_DATA = 'RESET_INVEST_WITHDRAW_DATA';
// export const INVEST_WITHDRAW_SAVE_EDIT_PLAN = 'INVEST_WITHDRAW_SAVE_EDIT_PLAN';

// Liquidation
export const SAVE_LIQUIDATION_DATA = 'SAVE_LIQUIDATION_DATA';

// Purchase
export const GET_ACCOUNT_SELECTION_DATA = 'GET_ACCOUNT_SELECTION_DATA';
export const SAVE_PURCHASE_DATA = 'SAVE_PURCHASE_DATA';
export const SUBMIT_PURCHASE_DATA = 'SUBMIT_PURCHASE_DATA';
export const SUBMIT_PURCHASE_DATA_SUCCESS = 'SUBMIT_PURCHASE_DATA_SUCCESS';
export const SUBMIT_PURCHASE_DATA_ERROR = 'SUBMIT_PURCHASE_DATA_ERROR';
export const SUBMIT_PURCHASE_DATA_LOADING = 'SUBMIT_PURCHASE_DATA_LOADING';
export const SUBMIT_PURCHASE_DATA_FAILURE = 'SUBMIT_PURCHASE_DATA_FAILURE';
export const GET_BANKACCOUNTS = 'GET_BANKACCOUNTS';
export const GET_BANKACCOUNTS_SUCCESS = 'GET_BANKACCOUNTS_SUCCESS';
export const GET_BANKACCOUNTS_FAILURE = 'GET_BANKACCOUNTS_FAILURE';
export const GET_BANKACCOUNTS_ERROR = 'GET_BANKACCOUNTS_ERROR';
export const GET_BANKACCOUNTS_LOADING = 'SUBMIT_PURCHASE_DATA_LOADING';

// Exchange
export const SAVE_EXCHANGE_DATA = 'SAVE_EXCHANGE_DATA';
export const SUBMIT_EXCHANGE_DATA = 'SUBMIT_EXCHANGE_DATA';
export const SUBMIT_EXCHANGE_DATA_SUCCESS = 'SUBMIT_EXCHANGE_DATA_SUCCESS';
export const SUBMIT_EXCHANGE_DATA_ERROR = 'SUBMIT_EXCHANGE_DATA_ERROR';
export const SUBMIT_EXCHANGE_DATA_LOADING = 'SUBMIT_EXCHANGE_DATA_LOADING';
export const SUBMIT_EXCHANGE_DATA_FAILURE = 'SUBMIT_EXCHANGE_DATA_FAILURE';

export const AMEND_METHODS_SUCCESS = 'AMEND_METHODS_SUCCESS';
export const GET_PENDINGTRANSACTION_DATA = 'GET_PENDINGTRANSACTION_DATA';
export const GET_PENDINGTRANSACTION_DATA_SUCCESS = 'GET_PENDINGTRANSACTION_DATA_SUCCESS';
export const GET_PENDINGTRANSACTION_DATA_ERROR = 'GET_PENDINGTRANSACTION_DATA_ERROR';
export const GET_PENDINGTRANSACTION_DATA_LOADING = 'GET_PENDINGTRANSACTION_DATA_LOADING';
export const CANCEL_DATA = 'CANCEL_DATA';
export const CANCEL_DATA_SUCCESS = 'CANCEL_DATA_SUCCESS';
export const CANCEL_DATA_ERROR = 'CANCEL_DATA_ERROR';
export const CANCEL_DATA_LOADING = 'CANCEL_DATA_LOADING';

// Account Details
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
// TODO: Should be removed once after using the new actions
export const GET_ACCOUNT_DETAILS_ERROR = 'GET_ACCOUNT_DETAILS_ERROR';
export const GET_ACCOUNT_DETAILS_LOADING = 'GET_ACCOUNT_DETAILS_LOADING';
// END
export const GET_ACCOUNT_DETAILS_FAILURE = 'GET_ACCOUNT_DETAILS_FAILURE';
export const GET_ACCOUNT_DETAILS_RESET = 'GET_ACCOUNT_DETAILS_RESET';

// money and assest
export const MONEY_ASSEST_DETAILS = 'MONEY_ASSEST_DETAILS';
export const MONEY_ASSEST_LOADING = 'MONEY_ASSEST_LOADING';
export const MONEY_ASSEST_SUCCESS = 'MONEY_ASSEST_SUCCESS';
export const MONEY_ASSEST_FAILURE = 'MONEY_ASSEST_FAILURE';
export const RESET_MOVEMENT_LIST = 'RESET_MOVEMENT_LIST';

// Clear REdux
export const CLEAR_REDUX_DATA = 'CLEAR_REDUX_DATA';

// get rmd accounts for listing page
export const RMD_METHODS_SUCCESS = 'RMD_METHODS_SUCCESS';
export const GET_RMD_TRANSACTIONS_LOADER = 'GET_RMD_TRANSACTIONS_LOADER';
export const GET_RMD_TRANSACTIONS = 'GET_RMD_TRANSACTIONS';
export const GET_RMD_TRANSACTIONS_SUCCESS = 'GET_RMD_TRANSACTIONS_SUCCESS';
export const GET_RMD_TRANSACTIONS_FAILURE = 'GET_RMD_TRANSACTIONS_FAILURE';
export const GET_RMD_TRANSACTIONS_ERROR = 'GET_RMD_TRANSACTIONS_ERROR';
export const GET_RMD_UPDATED_LIST = 'GET_RMD_UPDATED_LIST';

export const GET_RMD_PLAN_DETAILS_RESET = 'GET_RMD_PLAN_DETAILS_RESET';
export const GET_RMD_RESET = 'GET_RMD_RESET';

export const RMD_SET_AGGREGATE_AMT = 'RMD_SET_AGGREGATE_AMT';

// get rmd accounts for add page
export const GET_RMD_ACCOUNTS_LOADER = 'GET_RMD_ACCOUNTS_LOADER';
export const GET_RMD_ACCOUNTS = 'GET_RMD_ACCOUNTS';
export const GET_RMD_ACCOUNTS_SUCCESS = 'GET_RMD_ACCOUNTS_SUCCESS';
export const GET_RMD_ACCOUNTS_FAILURE = 'GET_RMD_ACCOUNTS_FAILURE';
export const GET_RMD_ACCOUNTS_ERROR = 'GET_RMD_ACCOUNTS_ERROR';
// add rmd
export const ADD_RMD_TRANSACTIONS_LOADER = 'ADD_RMD_TRANSACTIONS_LOADER';
export const ADD_RMD_TRANSACTIONS = 'ADD_RMD_TRANSACTIONS';
export const ADD_RMD_TRANSACTIONS_SUCCESS = 'ADD_RMD_TRANSACTIONS_SUCCESS';
export const ADD_RMD_TRANSACTIONS_FAILURE = 'ADD_RMD_TRANSACTIONS_FAILURE';
export const ADD_RMD_TRANSACTIONS_ERROR = 'ADD_RMD_TRANSACTIONS_ERROR';
// delete rmd
export const DELETE_RMD_TRANSACTIONS_LOADER = 'DELETE_RMD_TRANSACTIONS_LOADER';
export const DELETE_RMD_TRANSACTIONS = 'DELETE_RMD_TRANSACTIONS';
export const DELETE_RMD_TRANSACTIONS_SUCCESS = 'DELETE_RMD_TRANSACTIONS_SUCCESS';
export const DELETE_RMD_TRANSACTIONS_FAILURE = 'DELETE_RMD_TRANSACTIONS_FAILURE';
// dismiss rmd notification
export const DISMISS_RMD_NOTIFICATION_FAILURE = 'DISMISS_RMD_NOTIFICATION_FAILURE';
// taxes
export const RMD_GET_STATE_CODE_GROUP_LOADER = 'RMD_GET_STATE_CODE_GROUP_LOADER';
export const RMD_GET_STATE_CODE_GROUP = 'RMD_GET_STATE_CODE_GROUP';
export const RMD_GET_STATE_CODE_GROUP_SUCCESS = 'RMD_GET_STATE_CODE_GROUP_SUCCESS';
export const RMD_GET_STATE_CODE_GROUP_FAILURE = 'RMD_GET_STATE_CODE_GROUP_FAILURE';
export const RMD_GET_STATE_TAXES_FOR_GROUP_LOADER = 'RMD_GET_STATE_TAXES_FOR_GROUP_LOADER';
export const RMD_GET_STATE_TAXES_FOR_GROUP = 'RMD_GET_STATE_TAXES_FOR_GROUP';
export const RMD_GET_STATE_TAXES_FOR_GROUP_SUCCESS = 'RMD_GET_STATE_TAXES_FOR_GROUP_SUCCESS';
export const RMD_GET_STATE_TAXES_FOR_GROUP_FAILURE = 'RMD_GET_STATE_TAXES_FOR_GROUP_FAILURE';

export const RMD_SET_AGGREGATE_AMT_ERR = 'RMD_SET_AGGREGATE_AMT_ERR';
export const RMD_SET_BIRHDAY_FIELDS = 'RMD_SET_BIRHDAY_FIELDS';
export const RMD_SET_ENTER_AGGREGATE_AMT = 'RMD_SET_ENTER_AGGREGATE_AMT';
export const RMD_SET_IS_MR_LEVEL = 'RMD_SET_IS_MR_LEVEL';
export const RMD_SET_PREV_YR_ROLLOVER_TRANSFER_AVAILABLE = 'RMD_SET_PREV_YR_ROLLOVER_TRANSFER_AVAILABLE';
export const RMD_SET_IS_SPOUSE_SOLE_BENEFICIARY = 'RMD_SET_IS_SPOUSE_SOLE_BENEFICIARY';
export const RMD_SET_IS_TRUST_PRIMARY_BENEFICIARY = 'RMD_SET_IS_TRUST_PRIMARY_BENEFICIARY';
export const RMD_SET_IS_SET_UP_BY_OWN = 'RMD_SET_IS_SET_UP_BY_OWN';
export const RMD_SET_SHOW_CHAT_INFO_CARD = 'RMD_SET_SHOW_CHAT_INFO_CARD';
export const RMD_SET_SPOUSE_BIRTHDAY = 'RMD_SET_SPOUSE_BIRTHDAY';
export const RMD_SET_VCM_TO_CALC_DISTRIBUTION = 'RMD_SET_VCM_TO_CALC_DISTRIBUTION';


// Get NEWs and Commentary
// export const GET_NEWS_AND_COMMENTARY = 'GET_NEWS_AND_COMMENTARY';
// export const ERROR_NEWS_AND_COMMENTARY = 'ERROR_NEWS_AND_COMMENTARY';
// export const GET_SAVED_ITEMS = 'GET_SAVED_ITEMS';

// Get enable feature for Brokerage account

export const GET_ENABLED_FEATURE = 'GET_ENABLED_FEATURE';
export const GET_ENABLED_FEATURE_LOADER = 'GET_ENABLED_FEATURE_LOADER';
export const GET_ENABLED_FEATURE_SUCCESS = 'GET_ENABLED_FEATURE_SUCCESS';
export const GET_ENABLED_FEATURE_FAILURE = 'GET_ENABLED_FEATURE_FAILURE';
export const GET_ENABLED_FEATURE_ERROR = 'GET_ENABLED_FEATURE_ERROR';

// Dashboard Account Summary
export const MEMBER_DASHBOARD_ACCOUNT_SUMMARY = 'MEMBER_DASHBOARD_ACCOUNT_SUMMARY';
export const MEMBER_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS = 'MEMBER_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS';
export const MEMBER_DASHBOARD_ACCOUNT_SUMMARY_FAILURE = 'MEMBER_DASHBOARD_ACCOUNT_SUMMARY_FAILURE';
export const MEMBER_DASHBOARD_ACCOUNT_SUMMARY_ERROR = 'MEMBER_DASHBOARD_ACCOUNT_SUMMARY_ERROR';
export const MEMBER_DASHBOARD_ACCOUNT_SUMMARY_LOADER = 'MEMBER_DASHBOARD_ACCOUNT_SUMMARY_LOADER';


// New Dashboard Account Summary
export const MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY = 'MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY';
export const MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS = 'MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS';
export const MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_FAILURE = 'MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_FAILURE';
export const MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_ERROR = 'MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_ERROR';
export const MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_LOADER = 'MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_LOADER';


export const GUEST_DASHBOARD_ACCOUNT_TYPE = 'GUEST_DASHBOARD_ACCOUNT_TYPE';
export const GUEST_DASHBOARD_ACCOUNT_TYPE_SUCCESS = 'GUEST_DASHBOARD_ACCOUNT_TYPE_SUCCESS';
export const GUEST_DASHBOARD_ACCOUNT_TYPE_FAILURE = 'GUEST_DASHBOARD_ACCOUNT_TYPE_FAILURE';
export const GUEST_DASHBOARD_ACCOUNT_TYPE_ERROR = 'GUEST_DASHBOARD_ACCOUNT_TYPE_ERROR';
export const GUEST_DASHBOARD_ACCOUNT_TYPE_LOADER = 'GUEST_DASHBOARD_ACCOUNT_TYPE_LOADER';

export const MEMBER_DASHBOARD_DROPDOWN_LINKS = 'MEMBER_DASHBOARD_DROPDOWN_LINKS';
export const MEMBER_DASHBOARD_DROPDOWN_LOADER = 'MEMBER_DASHBOARD_DROPDOWN_LOADER';
export const MEMBER_DASHBOARD_DROPDOWN_SUCCESS = 'MEMBER_DASHBOARD_DROPDOWN_SUCCESS';
export const MEMBER_DASHBOARD_DROPDOWN_FAILURE = 'MEMBER_DASHBOARD_DROPDOWN_FAILURE';
export const MEMBER_DASHBOARD_DROPDOWN_ERROR = 'MEMBER_DASHBOARD_DROPDOWN_ERROR';

export const MEMBER_DASHBOARD_GET_SAVED_ITEMS = 'MEMBER_DASHBOARD_GET_SAVED_ITEMS';
export const MEMBER_DASHBOARD_GET_SAVED_ITEMS_SUCCESS = 'MEMBER_DASHBOARD_GET_SAVED_ITEMS_SUCCESS';
export const MEMBER_DASHBOARD_GET_SAVED_ITEMS_FAILURE = 'MEMBER_DASHBOARD_GET_SAVED_ITEMS_FAILURE';
export const MEMBER_DASHBOARD_GET_SAVED_ITEMS_ERROR = 'MEMBER_DASHBOARD_GET_SAVED_ITEMS_ERROR';
export const MEMBER_DASHBOARD_GET_SAVED_ITEMS_LOADER = 'MEMBER_DASHBOARD_GET_SAVED_ITEMS_LOADER';

export const SET_MEMBER_DASHBOARD_INITIAL_DATA = 'SET_MEMBER_DASHBOARD_INITIAL_DATA';

// Profile Settings - Error
export const PROFILE_INFORMATIONS_ERROR = 'PROFILE_INFORMATIONS_ERROR';

// Profile Settings - Basic Informations
export const GET_PROFILE_BASIC_INFORMATIONS = 'GET_PROFILE_BASIC_INFORMATIONS';
export const GET_PROFILE_BASIC_INFORMATIONS_SUCCESS = 'GET_PROFILE_BASIC_INFORMATIONS_SUCCESS';

// Profile Settings - Add Contact Informations
export const PROFILE_ADD_CONTACT_INFORMATIONS = 'PROFILE_ADD_CONTACT_INFORMATIONS';
export const PROFILE_ADD_CONTACT_SUCCESS = 'PROFILE_ADD_CONTACT_SUCCESS';
export const PROFILE_ADD_CONTACT_CLEAR = 'PROFILE_ADD_CONTACT_CLEAR';
// Profile Settings - Update Contact Informations
export const PROFILE_UPDATE_CONTACT_INFORMATIONS = 'PROFILE_UPDATE_CONTACT_INFORMATIONS';
export const PROFILE_UPDATE_CONTACT_SUCCESS = 'PROFILE_UPDATE_CONTACT_SUCCESS';

// Profile Settings - Delete Contact Informations
export const PROFILE_DELETE_CONTACT_INFORMATIONS = 'PROFILE_DELETE_CONTACT_INFORMATIONS';
export const PROFILE_DELETE_CONTACT_SUCCESS = 'PROFILE_DELETE_CONTACT_SUCCESS';

// Profile Settings - Add Email Informations
export const PROFILE_ADD_EMAIL_INFORMATIONS = 'PROFILE_ADD_EMAIL_INFORMATIONS';
export const PROFILE_ADD_EMAIL_SUCCESS = 'PROFILE_ADD_EMAIL_SUCCESS';

// Profile Settings - Update Email Informations
export const PROFILE_UPDATE_EMAIL_INFORMATIONS = 'PROFILE_UPDATE_EMAIL_INFORMATIONS';
export const PROFILE_UPDATE_EMAIL_SUCCESS = 'PROFILE_UPDATE_EMAIL_SUCCESS';

// Profile Settings - Verify Email Informations
export const PROFILE_VERIFY_EMAIL_INFORMATIONS = 'PROFILE_VERIFY_EMAIL_INFORMATIONS';
export const PROFILE_VERIFY_EMAIL_SUCCESS = 'PROFILE_VERIFY_EMAIL_SUCCESS';

// Profile Settings - OTP Confirm Email Informations
export const PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS = 'PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS';
export const PROFILE_OTP_CONFIRM_EMAIL_SUCCESS = 'PROFILE_OTP_CONFIRM_EMAIL_SUCCESS';

// Profile Settings - Delete Email Informations
export const PROFILE_DELETE_EMAIL_INFORMATIONS = 'PROFILE_DELETE_EMAIL_INFORMATIONS';
export const PROFILE_DELETE_EMAIL_SUCCESS = 'PROFILE_DELETE_EMAIL_SUCCESS';

// Profile Settings - Update Occupation Informations
export const PROFILE_UPDATE_OCCUPATION_INFORMATIONS = 'PROFILE_UPDATE_OCCUPATION_INFORMATIONS';
export const PROFILE_UPDATE_OCCUPATION_SUCCESS = 'PROFILE_UPDATE_OCCUPATION_SUCCESS';

// Profile Settings - Update Financial Informations
export const PROFILE_UPDATE_FINANCIAL_INFORMATIONS = 'PROFILE_UPDATE_FINANCIAL_INFORMATIONS';
export const PROFILE_UPDATE_FINANCIAL_SUCCESS = 'PROFILE_UPDATE_FINANCIAL_SUCCESS';

// Profile Settings - Update Military Informations
export const PROFILE_UPDATE_MILITARY_INFORMATIONS = 'PROFILE_UPDATE_MILITARY_INFORMATIONS';
export const PROFILE_UPDATE_MILITARY_SUCCESS = 'PROFILE_UPDATE_MILITARY_SUCCESS';

// Profile Settings - Add Relationship Informations
export const PROFILE_ADD_RELATIONSHIP_INFORMATIONS = 'PROFILE_ADD_RELATIONSHIP_INFORMATIONS';
export const PROFILE_ADD_RELATIONSHIP_SUCCESS = 'PROFILE_ADD_RELATIONSHIP_SUCCESS';

// Profile Settings - Update Relationship Informations
export const PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS = 'PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS';
export const PROFILE_UPDATE_RELATIONSHIP_SUCCESS = 'PROFILE_UPDATE_RELATIONSHIP_SUCCESS';

// Profile Settings - Delete Relationship Informations
export const PROFILE_DELETE_RELATIONSHIP_INFORMATIONS = 'PROFILE_DELETE_RELATIONSHIP_INFORMATIONS';
export const PROFILE_DELETE_RELATIONSHIP_SUCCESS = 'PROFILE_DELETE_RELATIONSHIP_SUCCESS';

// Profile Settings - Update Primary Email Informations
export const PROFILE_UPDATE_PRIMARY_EMAIL_INFORMATIONS = 'PROFILE_UPDATE_PRIMARY_EMAIL_INFORMATIONS';
export const PROFILE_UPDATE_PRIMARY_EMAIL_SUCCESS = 'PROFILE_UPDATE_PRIMARY_EMAIL_SUCCESS';

// Profile Settings - Delete Profile Phone Informations
export const PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS = 'PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS';
export const PROFILE_DELETE_PROFILE_PHONE_SUCCESS = 'PROFILE_DELETE_PROFILE_PHONE_SUCCESS';
export const PROFILE_DELETE_PROFILE_PHONE_FAILURE = 'PROFILE_DELETE_PROFILE_PHONE_FAILURE';

// Profile Settings - Get Relationship Informations
export const PROFILE_GET_RELATIONSHIP_INFORMATION = 'PROFILE_GET_RELATIONSHIP_INFORMATION';
export const PROFILE_GET_RELATIONSHIP_SUCCESS = 'PROFILE_GET_RELATIONSHIP_SUCCESS';

// Profile Settings - Update Phone Informations
export const PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS = 'PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS';
export const PROFILE_UPDATE_PROFILE_PHONE_LOADING = 'PROFILE_UPDATE_PROFILE_PHONE_LOADING';
export const PROFILE_UPDATE_PROFILE_PHONE_SUCCESS = 'PROFILE_UPDATE_PROFILE_PHONE_SUCCESS';

// Profile Settings - Update Personal Informations
export const PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS = 'PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS';
export const PROFILE_UPDATE_PROFILE_PERSONAL_SUCCESS = 'PROFILE_UPDATE_PROFILE_PERSONAL_SUCCESS';

// Profile Settings - Update Relation Phone Informations
export const PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS = 'PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS';
export const PROFILE_UPDATE_RELATION_PHONE_SUCCESS = 'PROFILE_UPDATE_RELATION_PHONE_SUCCESS';

// Profile Settings - Update Relation Email Informations
export const PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS = 'PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS';
export const PROFILE_UPDATE_RELATION_EMAIL_SUCCESS = 'PROFILE_UPDATE_RELATION_EMAIL_SUCCESS';

// Profile Settings - Update Relation Address Informations
export const PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS = 'PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS';
export const PROFILE_UPDATE_RELATION_ADDRESS_LOADING = 'PROFILE_UPDATE_RELATION_ADDRESS_LOADING';
export const PROFILE_UPDATE_RELATION_ADDRESS_SUCCESS = 'PROFILE_UPDATE_RELATION_ADDRESS_SUCCESS';
export const PROFILE_UPDATE_RELATION_ADDRESS_FAILURE = 'PROFILE_UPDATE_RELATION_ADDRESS_FAILURE';

// Profile Contact Information - Get Account Level Addresses
export const PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES = 'PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES';
export const PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES_SUCCESS = 'PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES_SUCCESS';
export const PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES = 'PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES';
export const PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES_SUCCESS = 'PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES_SUCCESS';

// Profile Settings - Load Member Preferences
export const GET_MEMBER_PREFERENCES = 'GET_MEMBER_PREFERENCES';
export const GET_MEMBER_PREFERENCES_LOADING = 'GET_MEMBER_PREFERENCES_LOADING';
export const GET_MEMBER_PREFERENCES_SUCCESS = 'GET_MEMBER_PREFERENCES_SUCCESS';
export const GET_MEMBER_PREFERENCES_FAILURE = 'GET_MEMBER_PREFERENCES_FAILURE';

// Profile Settings - Update Member Preferences
export const UPDATE_MEMBER_PREFERENCES = 'UPDATE_MEMBER_PREFERENCES';
export const UPDATE_MEMBER_PREFERENCES_LOADING = 'UPDATE_MEMBER_PREFERENCES_LOADING';
export const UPDATE_MEMBER_PREFERENCES_SUCCESS = 'UPDATE_MEMBER_PREFERENCES_SUCCESS';
export const UPDATE_MEMBER_PREFERENCES_FAILURE = 'UPDATE_MEMBER_PREFERENCES_FAILURE';

export const RESET_API_ERROR_MEMBER_PREFERENCES = 'RESET_API_ERROR_MEMBER_PREFERENCES';

// Profile Settings - Change Member Preferences
export const CHANGE_MEMBER_PREFERENCES = 'CHANGE_MEMBER_PREFERENCES';

// Profile Settings - Remove Existing State Informations
export const PROFILE_REMOVE_EXISTING_VALUES = 'PROFILE_REMOVE_EXISTING_VALUES';

// Profile Financial Remainder
export const PROFILE_FINANCIAL_REMAINDER_CLOSE = 'PROFILE_FINANCIAL_REMAINDER_CLOSE';
// Profile Information empty notification
export const PROFILE_INFORMATION_EMPTY_NOTIFICATION = 'PROFILE_INFORMATION_EMPTY_NOTIFICATION';

// First Time Login - Address Lookup Information
export const PROFILE_ADDRESS_LOOKUP_INFORMATION = 'PROFILE_ADDRESS_LOOKUP_INFORMATION';
export const PROFILE_ADDRESS_LOOKUP_INFORMATION_SUCCESS = 'PROFILE_ADDRESS_LOOKUP_INFORMATION_SUCCESS';

// First Time Login - Set physical and mailing address
export const PROFILE_SET_PHYSICAL_ADDRESS = 'PROFILE_SET_PHYSICAL_ADDRESS';
export const PROFILE_SET_MAILING_ADDRESS = 'PROFILE_SET_MAILING_ADDRESS';

// First Time Login - Set USPS Response for physical and mailing address
export const PROFILE_SET_MAILING_USPS_ADDRESS = 'PROFILE_SET_MAILING_USPS_ADDRESS';
export const PROFILE_SET_PHYSICAL_USPS_ADDRESS = 'PROFILE_SET_PHYSICAL_USPS_ADDRESS';
export const CALL_USPS_SERVICES = 'CALL_USPS_SERVICES';
export const USPS_SERVICES_COMPLETED = 'USPS_SERVICES_COMPLETED';
export const PROFILE_SAVE_NEW_ADDRESS = 'PROFILE_SAVE_NEW_ADDRESS';
export const SHOW_USPS_VERIFIED_ADDRESSES = 'SHOW_USPS_VERIFIED_ADDRESSES';
export const PROFILE_LOCK_ACCOUNT = 'PROFILE_LOCK_ACCOUNT';

// USER MANAGEMENT

export const USER_GET_ACCOUNT_RECOVER_PREFERENCE = 'USER_GET_ACCOUNT_RECOVER_PREFERENCE';
export const USER_SET_ACCOUNT_RECOVER_PREFERENCE = 'USER_SET_ACCOUNT_RECOVER_PREFERENCE';
export const USER_ACTION_GET_ACCOUNT_RECOVER_SUCCESS = 'USER_ACTION_GET_ACCOUNT_RECOVER_SUCCESS';
export const USER_ACTION_GET_ACCOUNT_RECOVER_FAILURE = 'USER_ACTION_GET_ACCOUNT_RECOVER_FAILURE';
export const USER_ACTION_SET_ACCOUNT_RECOVER_SUCCESS = 'USER_ACTION_SET_ACCOUNT_RECOVER_SUCCESS';
export const USER_ACTION_SET_ACCOUNT_RECOVER_FAILURE = 'USER_ACTION_SET_ACCOUNT_RECOVER_FAILURE';
export const USER_GET_QUICK_SIGN_IN = 'USER_GET_QUICK_SIGN_IN';
export const USER_SET_QUICK_SIGN_IN = 'USER_SET_QUICK_SIGN_IN';
export const USER_SET_QUICK_SIGN_IN_SUCCESS = 'USER_SET_QUICK_SIGN_IN_SUCCESS';
export const USER_SET_QUICK_SIGN_IN_FAILURE = 'USER_SET_QUICK_SIGN_IN_FAILURE';
export const USER_GET_QUICK_SIGN_IN_SUCCESS = 'USER_GET_QUICK_SIGN_IN_SUCCESS';
export const USER_GET_QUICK_SIGN_IN_FAILURE = 'USER_GET_QUICK_SIGN_IN_FAILURE';
export const USER_SAVE_SECURITY_QUESTIONS = 'USER_SAVE_SECURITY_QUESTIONS';
export const USER_SAVE_SECURITY_QUESTIONS_SUCCESS = 'USER_SAVE_SECURITY_QUESTIONS_SUCCESS';
export const USER_SAVE_SECURITY_QUESTIONS_FAILURE = 'USER_SAVE_SECURITY_QUESTIONS_FAILURE';
export const USER_SET_SIGN_IN_METHOD = 'USER_SET_SIGN_IN_METHOD';
export const USER_GET_SIGN_IN_METHOD = 'USER_GET_SIGN_IN_METHOD';
export const USER_SET_SIGN_IN_SUCCESS = 'USER_SET_SIGN_IN_SUCCESS';
export const USER_SET_SIGN_IN_FAILURE = 'USER_SET_SIGN_IN_FAILURE';
export const USER_SET_SIGN_IN_CLEAR = 'USER_SET_SIGN_IN_CLEAR';
export const USER_GET_SIGN_IN_SUCCESS = 'USER_GET_SIGN_IN_SUCCESS';
export const USER_GET_SIGN_IN_FAILURE = 'USER_GET_SIGN_IN_FAILURE';
export const USER_GET_SIGN_IN_CLEAR = 'USER_GET_SIGN_IN_CLEAR';
export const USER_GET_SIGN_IN = 'USER_GET_SIGN_IN';
export const USER_SET_SIGN_IN = 'USER_SET_SIGN_IN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_ERROR = 'VALIDATE_EMAIL_ERROR';
export const VALIDATE_EMAIL_CLEAR = 'VALIDATE_EMAIL_CLEAR';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_UP_CLEAR = 'SIGN_UP_CLEAR';
export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';
export const SEND_OTP_CLEAR = 'SEND_OTP_CLEAR';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_CLEAR = 'VERIFY_OTP_CLEAR';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const SEND_EMAIL_LINK = 'SEND_EMAIL_LINK';
export const SEND_EMAIL_LINK_SUCCESS = 'SEND_EMAIL_LINK_SUCCESS';
export const SEND_EMAIL_LINK_FAILURE = 'SEND_EMAIL_LINK_FAILURE';
export const SEND_EMAIL_LINK_CLEAR = 'SEND_EMAIL_LINK_CLEAR';
export const VERIFIED_EMAIL_LINK = 'VERIFIED_EMAIL_LINK';
export const VERIFIED_EMAIL_LINK_FAILURE = 'VERIFIED_EMAIL_LINK_FAILURE';
export const VERIFIED_EMAIL_LINK_SUCCESS = 'VERIFIED_EMAIL_LINK_SUCCESS';
export const VERIFIED_EMAIL_LINK_CLEAR = 'VERIFIED_EMAIL_LINK_CLEAR';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const RECOVER_ONLINE_ID = 'RECOVER_ONLINE_ID';
export const RECOVER_ONLINE_ID_SUCCESS = 'RECOVER_ONLINE_ID_SUCCESS';
export const RECOVER_ONLINE_ID_FAILURE = 'RECOVER_ONLINE_ID_FAILURE';
export const RECOVER_ONLINE_ID_CLEAR = 'RECOVER_ONLINE_ID_CLEAR';

export const RECOVER_ONLINE_ID_TYPE = 'RECOVER_ONLINE_ID_TYPE';
export const RECOVER_ONLINE_ID_TYPE_SUCCESS = 'RECOVER_ONLINE_ID_TYPE_SUCCESS';
export const RECOVER_ONLINE_ID_TYPE_FAILURE = 'RECOVER_ONLINE_ID_TYPE_FAILURE';
export const RECOVER_ONLINE_ID_TYPE_CLEAR = 'RECOVER_ONLINE_ID_TYPE_CLEAR';

export const RECOVER_FLOW_GUEST_SEND_MAIL = 'RECOVER_FLOW_GUEST_SEND_MAIL';
export const RECOVER_FLOW_GUEST_SEND_MAIL_SUCCESS = 'RECOVER_FLOW_GUEST_SEND_MAIL_SUCCESS';
export const RECOVER_FLOW_GUEST_SEND_MAIL_FAILURE = 'RECOVER_FLOW_GUEST_SEND_MAIL_FAILURE';
export const RECOVER_FLOW_GUEST_SEND_MAIL_CLEAR = 'RECOVER_FLOW_GUEST_SEND_MAIL_CLEAR';

export const RECOVER_FLOW_GUEST_EMAIL_VERIFY = 'RECOVER_FLOW_GUEST_EMAIL_VERIFY';
export const RECOVER_FLOW_GUEST_EMAIL_VERIFY_SUCCESS = 'RECOVER_FLOW_GUEST_EMAIL_VERIFY_SUCCESS';
export const RECOVER_FLOW_GUEST_EMAIL_VERIFY_FAILURE = 'RECOVER_FLOW_GUEST_EMAIL_VERIFY_FAILURE';
export const RECOVER_FLOW_GUEST_EMAIL_VERIFY_CLEAR = 'RECOVER_FLOW_GUEST_EMAIL_VERIFY_CLEAR';

export const RESET_FLOW = 'RESET_FLOW';
export const RESET_FLOW_SUCCESS = 'RESET_FLOW_SUCCESS';
export const RESET_FLOW_FAILURE = 'RESET_FLOW_FAILURE';
export const RESET_FLOW_CLEAR = 'RESET_FLOW_CLEAR';

export const RESET_FLOW_GUEST_CLEAR = 'RESET_FLOW_GUEST_CLEAR';
export const RESET_FLOW_GUEST = 'RESET_FLOW_GUEST';
export const RESET_FLOW_GUEST_SUCCESS = 'RESET_FLOW_GUEST_SUCCESS';
export const RESET_FLOW_GUEST_FAILURE = 'RESET_FLOW_GUEST_FAILURE';

export const RECOVER_FLOW_CUSTOMER = 'RECOVER_FLOW_CUSTOMER';
export const RECOVER_FLOW_CUSTOMER_SUCCESS = 'RECOVER_FLOW_CUSTOMER_SUCCESS';
export const RECOVER_FLOW_CUSTOMER_FAILURE = 'RECOVER_FLOW_CUSTOMER_FAILURE';
export const RECOVER_FLOW_CUSTOMER_CLEAR = 'RECOVER_FLOW_CUSTOMER_CLEAR';

export const RECOVER_FLOW_MEMBER_OR_GUEST = 'RECOVER_FLOW_MEMBER_OR_GUEST';
export const RECOVER_FLOW_MEMBER_OR_GUEST_FAILURE = 'RECOVER_FLOW_MEMBER_OR_GUEST_FAILURE';
export const RECOVER_FLOW_MEMBER_OR_GUEST_SUCCESS = 'RECOVER_FLOW_MEMBER_OR_GUEST_SUCCESS';
export const RECOVER_FLOW_MEMBER_OR_GUEST_CLEAR = 'RECOVER_FLOW_MEMBER_OR_GUEST_CLEAR';

export const VALIDATE_SECURITY_QUESTIONS = 'VALIDATE_SECURITY_QUESTIONS';
export const VALIDATE_SECURITY_QUESTIONS_SUCCESS = 'VALIDATE_SECURITY_QUESTIONS_SUCCESS';
export const VALIDATE_SECURITY_QUESTIONS_FAILURE = 'VALIDATE_SECURITY_QUESTIONS_FAILURE';
export const VALIDATE_SECURITY_QUESTIONS_CLEAR = 'VALIDATE_SECURITY_QUESTIONS_CLEAR';

export const USER_SAVE_SECURITY_QUESTIONS_CLEAR = 'USER_SAVE_SECURITY_QUESTIONS_CLEAR';
export const GET_SAVED_SECURITY_QUESTIONS = 'GET_SAVED_SECURITY_QUESTIONS';
export const GET_SAVED_SECURITY_QUESTIONS_CLEAR = 'GET_SAVED_SECURITY_QUESTIONS_CLEAR';
export const GET_SAVED_SECURITY_QUESTIONS_SUCCESS = 'GET_SAVED_SECURITY_QUESTIONS_SUCCESS';
export const GET_SAVED_SECURITY_QUESTIONS_FAILURE = 'GET_SAVED_SECURITY_QUESTIONS_FAILURE';

export const CHANGE_FLOW = 'CHANGE_FLOW';
export const CHANGE_FLOW_CLEAR = 'CHANGE_FLOW_CLEAR';
export const CHANGE_FLOW_SUCCESS = 'CHANGE_FLOW_SUCCESS';
export const CHANGE_FLOW_FAILURE = 'CHANGE_FLOW_FAILURE';

export const RESEND_OTP_CUSTOMERS = 'RESEND_OTP_CUSTOMERS';
export const RESEND_OTP_CUSTOMERS_CLEAR = 'RESEND_OTP_CUSTOMERS_CLEAR';
export const RESEND_OTP_CUSTOMERS_SUCCESS = 'RESEND_OTP_CUSTOMERS_SUCCESS';
export const RESEND_OTP_CUSTOMERS_FAILURE = 'RESEND_OTP_CUSTOMERS_FAILURE';

export const LOGIN_ATTEMPTS = 'LOGIN_ATTEMPTS';
export const LOGIN_ATTEMPTS_CLEAR = 'LOGIN_ATTEMPTS_CLEAR';
export const LOGIN_ATTEMPTS_SUCCESS = 'LOGIN_ATTEMPTS_SUCCESS';
export const LOGIN_ATTEMPTS_FAILURE = 'LOGIN_ATTEMPTS_FAILURE';

export const SIGN_UP_VERFIY_ONLINEID = 'SIGN_UP_VERFIY_ONLINEID';
export const SIGN_UP_VERFIY_ONLINEID_CLEAR = 'SIGN_UP_VERFIY_ONLINEID_CLEAR';
export const SIGN_UP_VERFIY_ONLINEID_SUCCESS = 'SIGN_UP_VERFIY_ONLINEID_SUCCESS';
export const SIGN_UP_VERFIY_ONLINEID_FAILURE = 'SIGN_UP_VERFIY_ONLINEID_FAILURE';

export const USER_SET_DOCUMENT_PREFERENCE_CLEAR = 'USER_SET_DOCUMENT_PREFERENCE_CLEAR';
export const USER_SET_DOCUMENT_PREFERENCE = 'USER_SET_DOCUMENT_PREFERENCE';
export const USER_SET_DOCUMENT_PREFERENCE_SUCCESS = 'USER_SET_DOCUMENT_PREFERENCE_SUCCESS';
export const USER_SET_DOCUMENT_PREFERENCE_FAILURE = 'USER_SET_DOCUMENT_PREFERENCE_FAILURE';

export const GET_CUSTOMER_PROFILE = 'GET_CUSTOMER_PROFILE';
export const GET_CUSTOMER_PROFILE_SUCCESS = 'GET_CUSTOMER_PROFILE_SUCCESS';
export const GET_CUSTOMER_PROFILE_FAILURE = 'GET_CUSTOMER_PROFILE_FAILURE';
export const GET_CUSTOMER_PROFILE_CLEAR = 'GET_CUSTOMER_PROFILE_CLEAR';

export const MEMBER_OR_GUEST_IVR_VERIFY = 'MEMBER_OR_GUEST_IVR_VERIFY';
export const MEMBER_OR_GUEST_IVR_VERIFY_FAILURE = 'MEMBER_OR_GUEST_IVR_VERIFY_FAILURE';
export const MEMBER_OR_GUEST_IVR_VERIFY_SUCCESS = 'MEMBER_OR_GUEST_IVR_VERIFY_SUCCESS';
export const MEMBER_OR_GUEST_IVR_VERIFY_CLEAR = 'MEMBER_OR_GUEST_IVR_VERIFY_CLEAR';

// Message center Settings: actions
export const GET_UNREAD_MESSAGE_COUNT = 'GET_UNREAD_MESSAGE_COUNT';
export const GET_UNREAD_MESSAGE_COUNT_ERROR = 'GET_UNREAD_MESSAGE_COUNT_ERROR';
export const GET_UNREAD_MESSAGE_COUNT_SUCCESS = 'GET_UNREAD_MESSAGE_COUNT_SUCCESS';
export const MESSAGE_ICON_CLICKED = 'MESSAGE_ICON_CLICKED';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_RECEIVED = 'GET_MESSAGES_RECEIVED';
export const GET_MESSAGES_ERRORS = 'GET_MESSAGES_ERRORS';
export const REDIRECT_TO_MESSAGE_CENTER = 'REDIRECT_TO_MESSAGE_CENTER';
export const MESSAGE_LIST_CLOSED = 'MESSAGE_LIST_CLOSED';
export const GET_TOPICS = 'GET_TOPICS';
export const GET_TOPICS_ERROR = 'GET_TOPICS_ERROR';
export const GET_TOPICS_RECEIVED = 'GET_TOPICS_RECEIVED';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';
export const GET_ACCOUNTS_RECEIVED = 'GET_ACCOUNTS_RECEIVED';

// MEMBER SIGN UP

export const DIRECT_MEMBER_ID_CLEAR = 'DIRECT_MEMBER_ID_CLEAR';
export const DIRECT_MEMBER_ID = 'DIRECT_MEMBER_ID';
export const DIRECT_MEMBER_ID_SUCCESS = 'DIRECT_MEMBER_ID_SUCCESS';
export const DIRECT_MEMBER_ID_ERROR = 'DIRECT_MEMBER_ID_ERROR';

export const DIRECT_MEMBER_SEND_EMAIL_OTP_CLEAR = 'DIRECT_MEMBER_SEND_EMAIL_OTP_CLEAR';
export const DIRECT_MEMBER_SEND_EMAIL_OTP = 'DIRECT_MEMBER_SEND_EMAIL_OTP';
export const DIRECT_MEMBER_SEND_EMAIL_OTP_SUCCESS = 'DIRECT_MEMBER_SEND_EMAIL_OTP_SUCCESS';
export const DIRECT_MEMBER_SEND_EMAIL_OTP_ERROR = 'DIRECT_MEMBER_SEND_EMAIL_OTP_ERROR';

export const DIRECT_MEMBER_VERIFY_OTP_CLEAR = 'DIRECT_MEMBER_VERIFY_OTP_CLEAR';
export const DIRECT_MEMBER_VERIFY_OTP = 'DIRECT_MEMBER_VERIFY_OTP';
export const DIRECT_MEMBER_VERIFY_OTP_SUCCESS = 'DIRECT_MEMBER_VERIFY_OTP_SUCCESS';
export const DIRECT_MEMBER_VERIFY_OTP_ERROR = 'DIRECT_MEMBER_VERIFY_OTP_ERROR';

export const DIRECT_MEMBER_SEND_PHONE_OTP_CLEAR = 'DIRECT_MEMBER_SEND_PHONE_OTP_CLEAR';
export const DIRECT_MEMBER_SEND_PHONE_OTP = 'DIRECT_MEMBER_SEND_PHONE_OTP';
export const DIRECT_MEMBER_SEND_PHONE_OTP_SUCCESS = 'DIRECT_MEMBER_SEND_PHONE_OTP_SUCCESS';
export const DIRECT_MEMBER_SEND_PHONE_OTP_ERROR = 'DIRECT_MEMBER_SEND_PHONE_OTP_ERROR';

export const DIRECT_MEMBER_STATUS_VERIFY_CLEAR = 'DIRECT_MEMBER_STATUS_VERIFY_CLEAR';
export const DIRECT_MEMBER_STATUS_VERIFY = 'DIRECT_MEMBER_STATUS_VERIFY';
export const DIRECT_MEMBER_STATUS_VERIFY_SUCCESS = 'DIRECT_MEMBER_STATUS_VERIFY_SUCCESS';
export const DIRECT_MEMBER_STATUS_VERIFY_ERROR = 'DIRECT_MEMBER_STATUS_VERIFY_ERROR';

export const DIRECT_MEMBER_SIGN_UP_CLEAR = 'DIRECT_MEMBER_SIGN_UP_CLEAR';
export const DIRECT_MEMBER_SIGN_UP = 'DIRECT_MEMBER_SIGN_UP';
export const DIRECT_MEMBER_SIGN_UP_SUCCESS = 'DIRECT_MEMBER_SIGN_UP_SUCCESS';
export const DIRECT_MEMBER_SIGN_UP_ERROR = 'DIRECT_MEMBER_SIGN_UP_ERROR';

// Trans Holder*
export const GET_TRANSACTION_HOLDER_LIST = 'GET_TRANSACTION_HOLDER_LIST';
export const GET_TRANSACTION_HOLDER_LOADER = 'GET_TRANSACTION_HOLDER_LOADER';
export const GET_TRANSACTION_HOLDER_RECEIVED = 'GET_TRANSACTION_HOLDER_RECEIVED';
export const GET_TRANSACTION_HOLDER_FAILURE = 'GET_TRANSACTION_HOLDER_FAILURE';
export const GET_TRANSACTION_HOLDER_ERROR = 'GET_TRANSACTION_HOLDER_ERROR';
export const GET_TRANSACTION_HOLDER_SUCCESS = 'GET_TRANSACTION_HOLDER_SUCCESS';
export const GET_TRANSACTION_HOLDER_RESET = 'GET_TRANSACTION_HOLDER_RESET';
export const STORE_ACCOUNTINFO_DATA = 'STORE_ACCOUNTINFO_DATA';

// Update Nick name
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME = 'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START = 'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS =
    'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE =
    'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE';

// Transaction - Purchase actions
export const GET_PURCHASE_ACCOUNT_LIST = 'GET_PURCHASE_ACCOUNT_LIST';
export const GET_PURCHASE_ACCOUNT_LIST_SUCCESS = 'GET_PURCHASE_ACCOUNT_LIST_SUCCESS';
export const GET_PURCHASE_ACCOUNT_LIST_FAILURE = 'GET_PURCHASE_ACCOUNT_LIST_FAILURE';
export const GET_IRA_CONTRIBUTION_VALUES = 'GET_IRA_CONTRIBUTION_VALUES';
export const GET_IRA_CONTRIBUTION_YEAR_VALUES = 'GET_IRA_CONTRIBUTION_YEAR_VALUES';

const AA = 'AccActivity_';
export const SET_ACTIVITIES = `${AA}SetAccActivities`;
export const SET_PAGE_LOADING = `${AA}SetPageLoading`;
export const INCREMENT_PAGE_NUMBER = `${AA}incrementPageNum`;
export const SET_MORE_DATA_AVAILABLE = `${AA}SetMoreDataAvail`;

// Transaction composite data
export const GET_TRANSACTION_COMPOSITE_DATA = 'GET_TRANSACTION_COMPOSITE_DATA';
export const GET_TRANSACTION_COMPOSITE_DATA_SUCCESS = 'GET_TRANSACTION_COMPOSITE_DATA_SUCCESS';
export const GET_TRANSACTION_COMPOSITE_DATA_FAILURE = 'GET_TRANSACTION_COMPOSITE_DATA_FAILURE';

// Validate ACH limits
export const VALIDATE_ACH_LIMIT = 'VALIDATE_ACH_LIMIT';
// Marstone - Dashboard

export const GET_MARSTONE_DATA = 'GET_MARSTONE_DATA';
export const GET_MARSTONE_DATA_SUCCESS = 'GET_MARSTONE_DATA_SUCCESS';
export const GET_MARSTONE_DATA_FAILURE = 'GET_MARSTONE_DATA_FAILURE';

// Account Nick Name - NAO

export const GET_DEFAULT_ACCOUNT_NICK_NAME_LOADER = 'GET_DEFAULT_ACCOUNT_NICK_NAME_LOADER';
export const GET_DEFAULT_ACCOUNT_NICK_NAME = 'GET_DEFAULT_ACCOUNT_NICK_NAME';
export const GET_DEFAULT_ACCOUNT_NICK_NAME_SUCCESS = 'GET_DEFAULT_ACCOUNT_NICK_NAME_SUCCESS';
export const GET_DEFAULT_ACCOUNT_NICK_NAME_FAILURE = 'GET_DEFAULT_ACCOUNT_NICK_NAME_FAILURE';

// Contact center Wait Time

export const GET_WAIT_TIME = 'GET_WAIT_TIME';
export const GET_WAIT_TIME_SUCCESS = 'GET_WAIT_TIME_SUCCESS';
export const GET_WAIT_TIME_FAILURE = 'GET_WAIT_TIME_FAILURE';

// Contact center Operational Hours

export const GET_CONTACT_OPERATIONAL_HOURS = 'GET_CONTACT_OPERATIONAL_HOURS';
export const GET_CONTACT_OPERATIONAL_HOURS_SUCCESS = 'GET_CONTACT_OPERATIONAL_HOURS_SUCCESS';
export const GET_CONTACT_OPERATIONAL_HOURS_FAILURE = 'GET_CONTACT_OPERATIONAL_HOURS_FAILURE';

export const SET_NEXT_PAGE_NUMBER_NAO_SUCCESS = 'SET_NEXT_PAGE_NUMBER_NAO_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_NAO_FAILURE = 'SET_NEXT_PAGE_NUMBER_NAO_FAILURE';

export const RESET_PAGE_NUMBER_NAO_SUCCESS = 'RESET_PAGE_NUMBER_NAO_SUCCESS';
export const RESET_PAGE_NUMBER_NAO_FAILURE = 'RESET_PAGE_NUMBER_NAO_FAILURE';

export const NEXT_PAGE_ERROR = 'Error moving to next page';

// Account opening sort by
export const SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_SUCCESS = 'SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_SUCCESS';
export const SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_FAILURE = 'SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_FAILURE';

// Account opening filter funds
export const GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_SUCCESS =
    'GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_SUCCESS';
export const GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_ERROR =
    'GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_ERROR';

export const SORT_FUND_ERROR = 'There was a error while sorting the funds.';

export const UNABLE_TO_VERIFY_ADDRESS = 'Sorry, we are unable to verify address.';

// MSR

export const MSR_FLOW = 'MSR_FLOW';
export const API_GET_MEMBER_BY_GUID = 'register/profile/view';
export const VERIFY_EMAIL_OTP = 'register/otp/enter';
export const REQUEST_EMAIL_OTP = 'register/otp/email';
export const API_REQUEST_PHONE_OTP = 'register/otp/send';
export const API_VERIFY_PHONE_OTP = 'register/otp/enter';
export const API_IVR_REQUEST_STATUS = 'register/otp/confirm';
export const API_CREATE_MEMBER = 'register/create';

// Account Opening Specialty Application Forms
export const GET_SPECIALTY_APPLICATION_FORMS_SUCCESS = 'GET_SPECIALTY_APPLICATION_FORMS_SUCCESS';
export const GET_SPECIALTY_APPLICATION_FORMS_FAILURE = 'GET_SPECIALTY_APPLICATION_FORMS_FAILURE';
export const GET_SPECIALTY_APPLICATION_FORMS_LOADER = 'GET_SPECIALTY_APPLICATION_FORMS_LOADER';
export const SET_SPECIALTY_APPLICATION_SUBTYPE = 'SET_SPECIALTY_APPLICATION_SUBTYPE';
export const GET_FORMS_BY_CATEGORY = 'GET_FORMS_BY_CATEGORY';

// Manage Account Preferences

export const GET_ACCOUNT_PREFERENCE = 'GET_ACCOUNT_PREFERENCE';
export const GET_ACCOUNT_PREFERENCE_SUCCESS = 'GET_ACCOUNT_PREFERENCE_SUCCESS';
export const GET_ACCOUNT_PREFERENCE_FAILURE = 'GET_ACCOUNT_PREFERENCE_FAILURE';
export const GET_ACCOUNT_PREFERENCE_ERROR = 'GET_ACCOUNT_PREFERENCE_ERROR';
export const GET_ACCOUNT_PREFERENCE_LOADER = 'GET_ACCOUNT_PREFERENCE_LOADER';

export const EDIT_ACCOUNT_PREFERENCE = 'EDIT_ACCOUNT_PREFERENCE';
export const EDIT_ACCOUNT_PREFERENCE_SUCCESS = 'EDIT_ACCOUNT_PREFERENCE_SUCCESS';
export const EDIT_ACCOUNT_PREFERENCE_FAILURE = 'EDIT_ACCOUNT_PREFERENCE_FAILURE';
export const EDIT_ACCOUNT_PREFERENCE_ERROR = 'EDIT_ACCOUNT_PREFERENCE_ERROR';
export const EDIT_ACCOUNT_PREFERENCE_LOADER = 'EDIT_ACCOUNT_PREFERENCE_LOADER';
export const EDIT_ACCOUNT_PREFERENCE_INITIAL_STATE = 'EDIT_ACCOUNT_PREFERENCE_INITIAL_STATE';

// Recurring Transfer Bank To Fund

export const GET_BTF_ACCOUNT_LIST = 'GET_BTF_ACCOUNT_LIST';
export const GET_BTF_ACCOUNT_LIST_SUCCESS = 'GET_BTF_ACCOUNT_LIST_SUCCESS';
export const GET_BTF_ACCOUNT_LIST_FAILURE = 'GET_BTF_ACCOUNT_LIST_FAILURE';
export const GET_BTF_ACCOUNT_LIST_ERROR = 'GET_BTF_ACCOUNT_LIST_ERROR';
export const GET_BTF_ACCOUNT_LIST_LOADER = 'GET_BTF_ACCOUNT_LIST_LOADER';

export const ADD_NEW_BTF_ACCOUNT = 'ADD_NEW_BTF_ACCOUNT';
export const ADD_NEW_BTF_ACCOUNT_SUCCESS = 'ADD_NEW_BTF_ACCOUNT_SUCCESS';
export const ADD_NEW_BTF_ACCOUNT_FAILURE = 'ADD_NEW_BTF_ACCOUNT_FAILURE';
export const ADD_NEW_BTF_ACCOUNT_ERROR = 'ADD_NEW_BTF_ACCOUNT_ERROR';
export const ADD_NEW_BTF_ACCOUNT_LOADER = 'ADD_NEW_BTF_ACCOUNT_LOADER';

export const SAVE_SELECTED_ACCOUNT_DETAILS = 'SAVE_SELECTED_ACCOUNT_DETAILS';
export const SAVE_SELECTED_BANK_DETAILS = 'SAVE_SELECTED_BANK_DETAILS';
export const SAVE_SELECTED_FUND_DETAILS = 'SAVE_SELECTED_FUND_DETAILS';
export const SAVE_SELECTED_FREQUENCY_DETAILS = 'SAVE_SELECTED_FREQUENCY_DETAILS';
export const REMOVE_SELECTED_DETAILS = 'REMOVE_SELECTED_DETAILS';
export const REMOVE_ERROR_DETAILS = 'REMOVE_ERROR_DETAILS';

export const USER_LOGOUT = 'USER_LOGOUT';

// Saved application constants

export const GET_SAVED_APPLICATIONS_LOADER = 'GET_SAVED_APPLICATIONS_LOADER';
export const GET_SAVED_APPLICATIONS = 'GET_SAVED_APPLICATIONS';
export const GET_SAVED_APPLICATIONS_SUCCESS = 'GET_SAVED_APPLICATIONS_SUCCESS';
export const GET_SAVED_APPLICATIONS_ERROR = 'GET_SAVED_APPLICATIONS_ERROR';

export const GET_APPLICATION_LOADER = 'GET_APPLICATION_LOADER';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_ERROR = 'GET_APPLICATION_ERROR';

export const REMOVE_SAVED_ITEM_LOADER = 'REMOVE_SAVED_ITEM_LOADER';
export const REMOVE_SAVED_ITEM = 'REMOVE_SAVED_ITEM';
export const REMOVE_SAVED_ITEM_SUCCESS = 'REMOVE_SAVED_ITEM_SUCCESS';
export const REMOVE_SAVED_ITEM_ERROR = 'REMOVE_SAVED_ITEM_ERROR';
export const REMOVE_RESET_SAVED_LIST = 'REMOVE_RESET_SAVED_LIST';

export const SAVE_APPLICATION_LOADER = 'SAVE_APPLICATION_LOADER';
export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS';
export const SAVE_APPLICATION_ERROR = 'SAVE_APPLICATION_ERROR';

export const DELETE_APPLICATION_LOADER = 'DELETE_APPLICATION_LOADER';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_ERROR = 'DELETE_APPLICATION_ERROR';

export const RESET_SHOW_CONFIRM_MODAL = 'RESET_SHOW_CONFIRM_MODAL';
export const ACCOUNT_OPENING_CLEAR_LOCAL_STATE = 'ACCOUNT_OPENING_CLEAR_LOCAL_STATE';

// Account Opening Marstone Redirection

export const GET_DATA_FOR_MARSTORNE_REDIRECTION_LOADER = 'GET_DATA_FOR_MARSTORNE_REDIRECTION_LOADER';
export const GET_DATA_FOR_MARSTORNE_REDIRECTION = 'GET_DATA_FOR_MARSTORNE_REDIRECTION';
export const GET_DATA_FOR_MARSTORNE_REDIRECTION_SUCCESS = 'GET_DATA_FOR_MARSTORNE_REDIRECTION_SUCCESS';
export const GET_DATA_FOR_MARSTORNE_REDIRECTION_FAILURE = 'GET_DATA_FOR_MARSTORNE_REDIRECTION_FAILURE';

// Verify customer
export const VERIFY_CUSTOMER_DETAILS = 'VERIFY_CUSTOMER_DETAILS';
export const VERIFY_CUSTOMER_DETAILS_LOADER = 'VERIFY_CUSTOMER_DETAILS_LOADER';
export const VERIFY_CUSTOMER_DETAILS_SUCCESS = 'VERIFY_CUSTOMER_DETAILS_SUCCESS';
export const VERIFY_CUSTOMER_DETAILS_FAILURE = 'VERIFY_CUSTOMER_DETAILS_FAILURE';
export const SET_CUSTOMER_VERIFICATION_SUCCESS = 'SET_CUSTOMER_VERIFICATION_SUCCESS';
export const SET_CUSTOMER_VERIFICATION_FAILURE = 'SET_CUSTOMER_VERIFICATION_FAILURE';
export const VERIFY_CUSTOMER_DETAILS_FIRST_JOINT_OWNER_SUCCESS = 'VERIFY_CUSTOMER_DETAILS_FIRST_JOINT_OWNER_SUCCESS';
export const VERIFY_CUSTOMER_DETAILS_SECOND_JOINT_OWNER_SUCCESS = 'VERIFY_CUSTOMER_DETAILS_SECOND_JOINT_OWNER_SUCCESS';
export const CUSTOMER_NOT_VERIFIED_ERROR_MESSAGE =
    'The SSN you have provided does not match the rest of your personal information. Please try again.';
export const VERIFY_CUSTOMER_API_FAILURE_ERROR_MESSAGE =
    'Unable to verify your SSN and rest of your personal information. Please contact MSR for assistance.';

export const COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_LOADER = 'COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_LOADER';
export const COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION = 'COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION';
export const COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_SUCCESS = ' COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_SUCCESS';
export const COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_FAILURE = 'COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_FAILURE';
export const SET_PRIMARY_OWNER_VERIFIED_SUCCESS = 'SET_PRIMARY_OWNER_VERIFIED_SUCCESS';


// Market Constact 

export const GET_MARKET_SAVED_APPLICATIONS_LOADER = 'GET_MARKET_SAVED_APPLICATIONS_LOADER';
export const GET_MARKET_SAVED_APPLICATIONS = 'GET_MARKET_SAVED_APPLICATIONS';
export const GET_MARKET_SAVED_APPLICATIONS_SUCCESS = 'GET_MARKET_SAVED_APPLICATIONS_SUCCESS';
export const GET_MARKET_SAVED_APPLICATIONS_ERROR = 'GET_MARKET_SAVED_APPLICATIONS_ERROR';

export const POST_MARKET_NAO_INFO= 'POST_MARKET_NAO_INFO';
export const POST_MARKET_NAO_INFO_SUCCESS = 'POST_MARKET_NAO_INFO_SUCCESS';
export const POST_MARKET_NAO_INFO_FAILURE = 'POST_MARKET_NAO_INFO_FAILURE';
export const POST_MARKET_NAO_INFO_ERROR = 'POST_MARKET_NAO_INFO_ERROR';
export const POST_MARKET_NAO_INFO_LOADER = 'POST_MARKET_NAO_INFO_LOADER';

export const GET_MARKET_NAO_INFO= 'GET_MARKET_NAO_INFO';
export const GET_MARKET_NAO_INFO_SUCCESS = 'GET_MARKET_NAO_INFO_SUCCESS';
export const GET_MARKET_NAO_INFO_FAILURE = 'GET_MARKET_NAO_INFO_FAILURE';
export const GET_MARKET_NAO_INFO_ERROR = 'GET_MARKET_NAO_INFO_ERROR';
export const GET_MARKET_NAO_INFO_LOADER = 'GET_MARKET_NAO_INFO_LOADER';

export const UPDATE_MARKET_NAO_INFO= 'UPDATE_MARKET_NAO_INFO';
export const SUBMIT_MARKET_NAO_INFO_SUCCESS= 'SUBMIT_MARKET_NAO_INFO_SUCCESS';
export const SUBMIT_MARKET_NAO_INFO_FAILURE= 'SUBMIT_MARKET_NAO_INFO_FAILURE';
export const SUBMIT_MARKET_NAO_INFO_ERROR= 'SUBMIT_MARKET_NAO_INFO_ERROR';
export const SUBMIT_MARKET_NAO_INFO= 'SUBMIT_MARKET_NAO_INFO';
export const SUBMIT_MARKET_NAO_INFO_LOADER = 'SUBMIT_MARKET_NAO_INFO_LOADER';

export const UPLOAD_DOCUMENT_CONFIRMATION= 'UPLOAD_DOCUMENT_CONFIRMATION';
export const UPLOAD_DOCUMENT_CONFIRMATION_SUCCESS= 'UPLOAD_DOCUMENT_CONFIRMATION_SUCCESS';
export const UPLOAD_DOCUMENT_CONFIRMATION_FAILURE= 'UPLOAD_DOCUMENT_CONFIRMATION_FAILURE';
export const UPLOAD_DOCUMENT_CONFIRMATION_ERROR= 'UPLOAD_DOCUMENT_CONFIRMATION_ERROR';
export const UPLOAD_DOCUMENT_CONFIRMATION_INFO= 'UPLOAD_DOCUMENT_CONFIRMATION_INFO';
export const UPLOAD_DOCUMENT_CONFIRMATION_LOADER = 'UPLOAD_DOCUMENT_CONFIRMATION_LOADER';


export const CLEAR_MARKET_NAO_INFO = 'CLEAR_MARKET_NAO_INFO';

export const POST_MARKET_NAO_PERSONAL_INFO= 'POST_MARKET_NAO_PERSONAL_INFO';
export const POST_MARKET_NAO_PERSONAL_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_ADDRESS_INFO= 'POST_MARKET_NAO_PERSONAL_ADDRESS_INFO';
export const POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_CONTACT_INFO= 'POST_MARKET_NAO_PERSONAL_CONTACT_INFO';
export const POST_MARKET_NAO_PERSONAL_CONTACT_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_CONTACT_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_CONTACT_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_CONTACT_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO= 'POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO';
export const POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_MILITRAY_INFO= 'POST_MARKET_NAO_PERSONAL_MILITRAY_INFO';
export const POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO= 'POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO';
export const POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_FAILURE';

export const POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO = 'POST_MARKET_NAO_PERSONAL_TRUSTED__CONTACT_INFO';
export const POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_FAILURE';


export const POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO = 'POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO';
export const POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_SUCCESS = 'POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_SUCCESS';
export const POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE = 'POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE';

export const GET_MARKET_APPLICATIONID_DATA_LOADER = 'GET_MARKET_APPLICATIONID_DATA_LOADER';
export const GET_MARKET_APPLICATIONID_DATA = 'GET_MARKET_APPLICATIONID_DATA';
export const GET_MARKET_APPLICATIONID_DATA_SUCCESS = 'GET_MARKET_APPLICATIONID_DATA_SUCCESS';
export const GET_MARKET_APPLICATIONID_DATA_FAILURE = 'GET_MARKET_APPLICATIONID_DATA_FAILURE';
export const GET_MARKET_APPLICATIONID_DATA_ERROR = 'GET_MARKET_APPLICATIONID_DATA_ERROR';
export const UPDATE_LIST_OF_COMPLETED_PAGES= 'UPDATE_LIST_OF_COMPLETED_PAGES';

export const MARKET_UPDATE_LOCAL_STATE = 'MARKET_UPDATE_LOCAL_STATE';

export const GET_USER_PROFILE_DATA = "GET_USER_PROFILE_DATA";
export const GET_USER_PROFILE_DATA_SUCCESS = 'GET_USER_PROFILE_DATA_SUCCESS';
export const GET_USER_PROFILE_DATA_FAILURE = 'GET_USER_PROFILE_DATA_FAILURE';
export const GET_USER_PROFILE_DATA_ERROR = 'GET_USER_PROFILE_DATA_ERROR';

export const GET_MEMBER_MSR_PREFERENCES = 'GET_MEMBER_MSR_PREFERENCES';
export const GET_MEMBER_MSR_PREFERENCES_LOADING = 'GET_MEMBER_MSR_PREFERENCES_LOADING';
export const GET_MEMBER_MSR_PREFERENCES_SUCCESS = 'GET_MEMBER_MSR_PREFERENCES_SUCCESS';
export const GET_MEMBER_MSR_PREFERENCES_FAILURE = 'GET_MEMBER_MSR_PREFERENCES_FAILURE';


export const POST_KYC_VERIFICATION = 'POST_KYC_VERIFICATION';
export const POST_KYC_VERIFICATION_SUCCESS = 'POST_KYC_VERIFICATION_SUCCESS';
export const POST_KYC_VERIFICATION_FAILURE = 'POST_KYC_VERIFICATION_FAILURE';
export const POST_KYC_VERIFICATION_ERROR = 'POST_KYC_VERIFICATION_ERROR';
export const POST_KYC_VERIFICATION_LOADER = 'POST_KYC_VERIFICATION_LOADER';

export const CLEAR_KYC_VERIFICATION_DATA = 'CLEAR_KYC_VERIFICATION_DATA';
export const CLEAR_BROKERAGE_BANK_ACCOUNT_INFO = 'CLEAR_BROKERAGE_BANK_ACCOUNT_INFO';
export const CLEAR_API_STATUS = 'CLEAR_API_STATUS';

export const GET_ACCOUNT_SERVICE_OPTIONS = 'GET_ACCOUNT_SERVICE_OPTIONS';
export const GET_ACCOUNT_SERVICE_OPTIONS_SUCCESS = 'GET_ACCOUNT_SERVICE_OPTIONS_SUCCESS';
export const GET_ACCOUNT_SERVICE_OPTIONS_FAILURE = 'GET_ACCOUNT_SERVICE_OPTIONS_FAILURE';
export const RESET_ACCOUNT_SERVICE_OPTIONS_LIST = 'RESET_ACCOUNT_SERVICE_OPTIONS_LIST';

export const GET_TRANSACTION_HOLDER_LIST_FOR_MANAGE = 'GET_TRANSACTION_HOLDER_LIST_FOR_MANAGE';
export const GET_TRANSACTION_HOLDER_FAILURE_FOR_MANAGE = 'GET_TRANSACTION_HOLDER_FAILURE_FOR_MANAGE';
export const GET_TRANSACTION_HOLDER_ERROR_FOR_MANAGE = 'GET_TRANSACTION_HOLDER_ERROR_FOR_MANAGE';
export const GET_TRANSACTION_HOLDER_SUCCESS_FOR_MANAGE = 'GET_TRANSACTION_HOLDER_SUCCESS_FOR_MANAGE';

export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FOR_MANAGE = 'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FOR_MANAGE';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START_FOR_MANAGE = 'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START_FOR_MANAGE';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS_FOR_MANAGE =
    'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS_FOR_MANAGE';
export const UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE =
    'UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE';



export const GET_SAML_ASSERTION_LOADER = 'GET_SAML_ASSERTION_LOADER';
export const GET_SAML_ASSERTION = 'GET_SAML_ASSERTION';
export const GET_SAML_ASSERTION_SUCCESS = 'GET_SAML_ASSERTION_SUCCESS';
export const GET_SAML_ASSERTION_FAILURE = 'GET_SAML_ASSERTION_FAILURE';
export const GET_SAML_ASSERTION_ERROR = 'GET_SAML_ASSERTION_ERROR';
export const SET_SAML_ASSERTION_INITIAL_DATA = 'SET_SAML_ASSERTION_INITIAL_DATA';


export const GENERICID_LOOKUP_START = 'GENERICID_LOOKUP_START';
export const GENERICID_LOOKUP_SUCCESS = 'GENERICID_LOOKUP_SUCCESS';
export const GENERICID_LOOKUP_ERROR = 'GENERICID_LOOKUP_ERROR';

export const GET_APPLICATION_CONFIG_LOADER = 'GET_APPLICATION_CONFIG_LOADER';
export const GET_APPLICATION_CONFIG = 'GET_APPLICATION_CONFIG';
export const GET_APPLICATION_CONFIG_SUCCESS = 'GET_APPLICATION_CONFIG_SUCCESS';
export const GET_APPLICATION_CONFIG_ERROR = 'GET_APPLICATION_CONFIG_ERROR';

export const GET_BROKEREAGE_BANK_ACCOUNTS_LIST = 'GET_BROKEREAGE_BANK_ACCOUNTS_LIST';
export const GET_BROKEREAGE_BANK_ACCOUNTS_LIST_SUCCESS = 'GET_BROKEREAGE_BANK_ACCOUNTS_LIST_SUCCESS';
export const GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE = 'GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE';
export const GET_BROKEREAGE_BANK_ACCOUNTS_LIST_ERROR = 'GET_BROKEREAGE_BANK_ACCOUNTS_LIST_ERROR';

export const GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION = 'GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION';
export const GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_SUCCESS = 'GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_SUCCESS';
export const GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_FAILURE = 'GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_FAILURE';
export const GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR = 'GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR';
export const RESET_BROKEREAGE_BANK_ACCOUNTS_LIST = 'RESET_BROKEREAGE_BANK_ACCOUNTS_LIST';

export const GET_SYSTEM_HEALTH = 'GET_SYSTEM_HEALTH';
export const GET_SYSTEM_HEALTH_SUCCESS = 'GET_SYSTEM_HEALTH_SUCCESS';
export const GET_SYSTEM_HEALTH_FAILURE = 'GET_SYSTEM_HEALTH_FAILURE';
export const GET_SYSTEM_HEALTH_ERROR = 'GET_SYSTEM_HEALTH_ERROR';
export const GET_SYSTEM_HEALTH_LOADER = 'GET_SYSTEM_HEALTH_LOADER';
export const CLEAR_SYSTEM_HEALTH = 'CLEAR_SYSTEM_HEALTH';
export const NICKNAME_UPDATED_STATUS = 'NICKNAME_UPDATED_STATUS';

export const DIRECT_VERIFY_MEMBER_ID_CLEAR = 'DIRECT_VERIFY_MEMBER_ID_CLEAR';
export const DIRECT_VERIFY_MEMBER_ID = 'DIRECT_VERIFY_MEMBER_ID';
export const DIRECT_VERIFY_MEMBER_ID_SUCCESS = 'DIRECT_VERIFY_MEMBER_ID_SUCCESS';
export const DIRECT_VERIFY_MEMBER_ID_ERROR = 'DIRECT_VERIFY_MEMBER_ID_ERROR';

export const SUBMIT_KYC_UPLOADED_DOCUMENTS_SUCCESS = 'SUBMIT_KYC_UPLOADED_DOCUMENTS_SUCCESS';
export const SUBMIT_KYC_UPLOADED_DOCUMENTS_FAILURE = 'SUBMIT_KYC_UPLOADED_DOCUMENTS_FAILURE';
export const SUBMIT_KYC_UPLOADED_DOCUMENTS_ERROR = 'SUBMIT_KYC_UPLOADED_DOCUMENTS_ERROR';
export const SUBMIT_KYC_UPLOADED_DOCUMENTS = 'SUBMIT_KYC_UPLOADED_DOCUMENTS';
export const SUBMIT_KYC_UPLOADED_DOCUMENTS_LOADER = 'SUBMIT_KYC_UPLOADED_DOCUMENTS_LOADER';
