/**
 * Liquidation
 ********************************
 *
 * @version 1.0.0
 * @author Vivek
 * @description Liquidation
 * @createdDate [10/01/2020]
 * ***************************************
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import {
    masterRegistrationAccountActions,
    fundAccountActions,
    bankAccountAction,
    liquidationActions,
    purchaseActions,
    tradingWindowActions,
    profileSettingsAction,
    exchangeActions,
    transactionHistoryActions
} from 'shared/Actions';
import {
    WBreadCrumb,
    PageHeading,
    WSpinner,
    WStepper,
    ConformationModal,
    ConfirmModal,
} from '../../../../common';
import constants from '../constant';
import * as TransactionConstants from '../../TransactionConstants';
import ErrorBoundary from '../../Common/ErrorBoundary/errorboundary';

import AccountSelection from '../../../../common/AccountSelection';
import FundSelection from '../FundSelection';
import FundingOption from '../FundingOption';
import VerifyDetails from '../../../../common/VerifyDetails';
import ConfirmationComponent from '../../Common/ConfirmationComponent';
import { encrypt } from 'utils';
import WBlueBanner from '../../../../common/WBlueBanner/WBlueBanner';
import {getTokenWithExpiry, isTokenAvailable} from '../../../../common/Authenticate/session';
import Authenticate from '../../../../common/Authenticate/Authenticate'
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
`;
const Stepper = styled(WStepper)`
    margin-bottom: 40px;
`;

const breadCrumURL = [
    { url: '/accountHolder', name: 'Dashboard' },
    { url: '/accountholder/transactions', name: 'Portfolio' },

];

const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

export class Liquidation extends Component {
           constructor(props) {
               super(props);
               this.state = {
                   pageIndex: 0,
                   childrenState: [],
                   tradeBox: false,
                   displayNotification: false,
                   notificationMsg: '',
                   setMasterRegAccount: false,
                   showCancelLiqModal: false,
                   cancelLiquidation: false,
                   addressValid: true,
                   showMasterRegAddrChgModal: false,
                   showAuthBox: false,
                   liquidationSubmitAPiCount:0
               };
               this.editSection = null;
               this.bottomNav = React.createRef();
               const { getTradingClosureWindow, tradingWindowData } = this.props;
               if (!tradingWindowData.fromDashboard) {
                   getTradingClosureWindow({ token: localStorage.getItem('token') });
               }
           }

           static getFailedPropotinateFundList = (proportianteSelectedFundsList,proportinateFundPassedList) =>{
               let displayFailedPropotinateFundList = [];

                for(let i=0;i<proportianteSelectedFundsList?.length;i++)
                {
                    const filterObj = proportinateFundPassedList?.filter((item)=> item == proportianteSelectedFundsList[i].fundAccountNumber);
                    if(filterObj?.length == 0)
                    {
                        displayFailedPropotinateFundList.push(proportianteSelectedFundsList[i])
                    }
                }

               return displayFailedPropotinateFundList;
           }

           static getDerivedStateFromProps(nextProps, prevState) {
               const { masterRegistrationAccountData, location, liquidationData, purchaseReducerData } = nextProps;
               let setMasterRegAccount = false;
               const {pageIndex} = prevState;
              if (
                   location.state &&
                   location.state.pageIndex !== prevState.pageIndex &&
                   masterRegistrationAccountData.masterRegAccountList.length > 0
               ) {
                   setMasterRegAccount = true;
               }

               let displayFailedPropotinateFundListArray =[];
               let liquidationSubmitAPiCount = 0;

               if(pageIndex == 4 && liquidationData?.page1?.isProportinateSelected && liquidationData?.page1?.proportianteSelectedFundsList?.length > 0)
               {
                    displayFailedPropotinateFundListArray = Liquidation.getFailedPropotinateFundList(liquidationData?.page1?.proportianteSelectedFundsList, liquidationData?.proportinateFundPassedList);
                    liquidationSubmitAPiCount = liquidationData?.liquidationSubmitAPiCount;
               }else{
                    console.log('negetive case for test case')
               }

               return {
                   ...prevState,
                   setMasterRegAccount,
                   showCancelLiqModal: liquidationData.showCancelLiqModal,
                   displayFailedPropotinateFundListArray:displayFailedPropotinateFundListArray,
                   liquidationSubmitAPiCount:liquidationSubmitAPiCount
               };
           }

           componentDidMount() {
               const { setMasterRegAccount } = this.state;
               const { location, masterRegistrationAccountData } = this.props;

               if (
                   setMasterRegAccount &&
                   masterRegistrationAccountData.masterRegAccountList &&
                   location.state.masterId
               ) {
                   const accountData = this.accountClickHandler(
                       masterRegistrationAccountData.masterRegAccountList,
                       location.state.masterId,
                   );
                   this.onSwitchComponent(true, accountData, 0)();
               }
               window.scrollTo({
                   top: 0,
                   left: 0,
                   behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
               });
           }

           componentDidUpdate() {
               const {
                   clearFundAccountList,
                   fundAccountData,
                   clearBankAccDetails,
                   bankAccountReducerData,
               } = this.props;
               const { bankDetails = [] } = bankAccountReducerData;
               const { fundAccountList = [] } = fundAccountData;
               if (fundAccountList.length) {
                   clearFundAccountList();
               }
               if (bankDetails.length) {
                   clearBankAccDetails();
               }
           }

           componentWillUnmount() {
               const {
                   resetPageNumber,
                   clearLiquidationData,
                   clearBankAccDetails,
                   clearMasterRegInfo,
                   clearFundAccountList,
                   clearExchangeData,
               } = this.props;
               resetPageNumber();
               clearLiquidationData();
               clearBankAccDetails();
               clearMasterRegInfo();
               clearFundAccountList();
               clearExchangeData();
           }

           // This Function will mimic the click from View Positions
           accountClickHandler = (data, masterId) => {
               const accountSelectedType = {};
               const displayData = [];
               let accountSelected = null;

               const allAccounts = data.map(item => {
                   const accType = { ...item };
                   const { accounts } = accType;
                   accType.accounts = accounts.map(eachacc => {
                       const acc = { ...eachacc };
                       if (Number(masterId) === Number(eachacc.accountNumber)) {
                           acc.checked = !acc.checked;
                       } else {
                           acc.checked = false;
                       }
                       if (acc.checked) {
                           accountSelected = acc;
                           const accountNameDisplay =
                               !acc.accountName || acc.accountName.trim() === ''
                                   ? `${!acc.accountType ? '' : acc.accountType} Account ${acc.accountNumber}`
                                   : acc.accountName;
                           displayData.push(
                               {
                                   field: TransactionConstants.ACCOUNT_NAME_TEXT,
                                   value: accountNameDisplay,
                               },
                               {
                                   field: TransactionConstants.ACCOUNT_NUMBER_TEXT,
                                   value: acc.accountNumber,
                               },
                               {
                                   field: TransactionConstants.ACCOUNT_TYPE_TEXT,
                                   value: acc.accountType,
                               },
                           );
                           accountSelectedType.holdingGroup = eachacc.holdingGroupName;
                       }
                       return acc;
                   });
                   return accType;
               });

               return {
                   accountsAvailable: allAccounts,
                   accountSelected,
                   displayDetails: {
                       accountSelection: {
                           data: displayData,
                       },
                   },
                   accountSelectedType,
               };
           };

           getTradingInformation = dateString => {
               const dateVal = new Date(dateString);
               let month = '';
               let year = '';
               let day = '';
               let hour = '';
               let minutes = '';
               if (dateString) {
                   month = dateVal.getUTCMonth();
                   day = dateVal.getUTCDate();
                   year = dateVal.getUTCFullYear();
                   hour = dateVal.getUTCHours();
                   minutes = dateVal.getUTCMinutes();
               }

               return {
                   month,
                   year,
                   day,
                   hour,
                   minutes,
               };
           };

           setTradingWarning = () => {
               const { tradingWindowData, setTradingWindowWarning } = this.props;
               const currentLocalTimeStr = new Date().toUTCString();
               const currentTime = Date.parse(currentLocalTimeStr);
               const {
                   warningSpan,
                   marketEndTime,
                   marketStartTime,
                   canTradeToday,
                   tradingEndTimeEST,
                   tradingActualEndTimeEST,
                   tradingDayHour,
                   tradingEndTime,
               } = tradingWindowData;
               let { nextBusinessDay } = tradingWindowData;
               const warningDiff = marketEndTime - currentTime;
               const marketOpenDiff = marketStartTime - currentTime;
               const marketClosureDiff = marketEndTime - currentTime;
               let warningMessage = '';

               const effectiveDate = canTradeToday ? tradingEndTime : nextBusinessDay;

               const { day: currentLocalDay } = this.getTradingInformation(currentLocalTimeStr);
               const { day: marketEndDay } = this.getTradingInformation(tradingEndTime);
               if (canTradeToday && marketEndTime !== '') {
                   if (currentLocalDay !== marketEndDay) {
                       // if the user is in a different time zone and have the local date as next day
                       if (canTradeToday && tradingDayHour) {
                           // During market hours
                           const { month, day, year } = this.getTradingInformation(
                               new Date(marketStartTime).toUTCString(),
                           );
                           nextBusinessDay = `${month + 1}/${day}/${year}`;
                           warningMessage = '';
                           if (marketClosureDiff < warningSpan) {
                               // 30 mins warning message
                               warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                           }
                       } else if (marketOpenDiff > 0 && !tradingDayHour) {
                           // Before market hours
                           const { month, day, year } = this.getTradingInformation(
                               new Date(marketStartTime).toUTCString(),
                           );
                           nextBusinessDay = `${month + 1}/${day}/${year}`;
                           warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from 09:30 AM EST until the market closes at 04:00 PM EST. You are placing an order during market close and will receive the Net Asset Value of today.`;
                       } else {
                           // After market hours
                           warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                       }
                       setTradingWindowWarning(false, warningMessage, nextBusinessDay);
                   } else if (marketOpenDiff > 0) {
                       warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from 09:30 AM EST until the market closes at 04:00 PM EST. You are placing an order during market close and will receive the Net Asset Value of today.`;
                       setTradingWindowWarning(false, warningMessage, effectiveDate);
                   } else if (marketClosureDiff < 0) {
                       warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                       setTradingWindowWarning(false, warningMessage, nextBusinessDay);
                   } else if (marketOpenDiff < 0 && marketClosureDiff > 0) {
                       if (warningDiff < warningSpan) {
                           warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                           setTradingWindowWarning(false, warningMessage, effectiveDate);
                       } else {
                           setTradingWindowWarning(true, '', effectiveDate);
                       }
                   }
               } else {
                   warningMessage = `Market Hours for Mutual Funds: You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                   setTradingWindowWarning(false, warningMessage, nextBusinessDay);
               }
           };

           onCancel = () => {
               const { history } = this.props;
               history.push({
                   pathname: '/transactionDashboard',
                   state: {
                       accLiquidated: false,
                       displayNotification: false,
                   },
               });
           };
           getEncryptedData = async data => {
               return encrypt(data);
           };
           getTypeValueReq = ({ amtType, sellingamt, currentAccountBalance }) => {
               let typeValueReq = 'A';
               if (parseFloat(sellingamt) !== parseFloat(Number(currentAccountBalance).toFixed(2))) {
                   if (amtType === 'dollaramt') {
                       typeValueReq = 'D';
                   } else if (amtType === 'percentamt') {
                       typeValueReq = 'P';
                   } else if (amtType === 'percentamtshare') {
                       typeValueReq = 'S';
                   }
               }
               return typeValueReq;
           };
           getFormattedSellingValue = (value, amtType="") => {
               let appendedZero = '00';
               let fixedDigit = 2;
               if (amtType === 'percentamtshare') {
                   appendedZero = '000';
                   fixedDigit = 3;
               }
               const sellingValue = `${parseFloat(value).toFixed(fixedDigit)}`.split('.');
               let finalSellingValue = `${sellingValue[0]}${appendedZero}`;
               if (sellingValue[1] > 0) {
                   finalSellingValue = `${sellingValue[0]}${sellingValue[1]}`;
               }
               return finalSellingValue;
           };
           getLiquidateAmount = ({ amtType, sellingamt, currentAccountBalance, userinputpercent, availableShares }) => {
               let liquidateAmount = '';
               if (parseFloat(sellingamt) !== parseFloat(Number(currentAccountBalance).toFixed(2))) {
                   if (amtType === 'dollaramt') {
                       liquidateAmount = this.getFormattedSellingValue(sellingamt);
                   } else if (amtType === 'percentamt') {
                       liquidateAmount = this.getFormattedSellingValue(userinputpercent);
                   } else if (amtType === 'percentamtshare') {
                       const calculatedShares = ((Number(userinputpercent) * Number(availableShares)) / 100).toFixed(3);
                       liquidateAmount = this.getFormattedSellingValue(calculatedShares, amtType);
                   }
               }
               return liquidateAmount;
           };
           getAddressValidity = stateData => {
               let validAddress = true;
               const addressExpDate = stateData && stateData.accountSelected && stateData.accountSelected.addrChgExpDte;
               if (
                   addressExpDate === '0' ||
                   addressExpDate === '' ||
                   addressExpDate === null ||
                   addressExpDate === undefined
               ) {
                   this.setState({ addressValid: true, showMasterRegAddrChgModal: false });
               } else {
                   const addressExpYear = addressExpDate.substring(0, 4);
                   const addressExpMonth = addressExpDate.substring(4, 6);
                   const addressExpDay = addressExpDate.substring(6, 8);
                   const addressExpDateFormat = new Date(addressExpYear, addressExpMonth - 1, addressExpDay);
                   const currentDate = new Date();
                   if (addressExpDateFormat >= currentDate) {
                       this.setState({ addressValid: false, showMasterRegAddrChgModal: true });
                       validAddress = false;
                   }
               }
               return validAddress;
           };

           calculateTaxForPropotionateFund = (stateData) =>
           {
                const { savePageState,liquidationData } = this.props;
                let proportianteSelectedFundsListWithTax = []
                if(liquidationData?.page1?.isProportinateSelected)
                {
                    const proportianteSelectedFundsList = liquidationData?.page1?.proportianteSelectedFundsList;
                    for(let i=0;i<proportianteSelectedFundsList?.length;i++)
                    {
                        let fundObj = proportianteSelectedFundsList[i];
                        const percentAmount = (stateData?.fedTaxAmount * (fundObj.percentage / 100));
                        fundObj['fedTaxAmount'] = percentAmount.toFixed(2);
                        if(stateData?.stateTaxWithHeld)
                        {
                            const stateTaxPercentAmount = (stateData?.stateTaxAmount * (fundObj.percentage / 100));
                            fundObj['stateTaxAmount'] = stateTaxPercentAmount.toFixed(2);
                        }
                        proportianteSelectedFundsListWithTax.push(fundObj);
                    }
                    let page1StateData = liquidationData?.page1;
                    page1StateData['proportianteSelectedFundsList'] = proportianteSelectedFundsListWithTax;
                    savePageState({ data: page1StateData, pageIndex:1 });
                }
           }

           onSwitchComponent = (bool, stateData = {}, pageIndex = '') => () => {
               const { savePageState,liquidationData } = this.props;
               let validMasterRegAddress = true;
               if (pageIndex === 0 && bool) {
                   validMasterRegAddress = this.getAddressValidity(stateData);
               }
               if (validMasterRegAddress) {
                   if (bool) {
                       this.saveChildrenState(stateData, pageIndex);
                       if(liquidationData?.page1?.isProportinateSelected && this.isIRATaxWithhold() && pageIndex == 2)
                       {
                            this.calculateTaxForPropotionateFund(stateData);
                       }
                       savePageState({ data: stateData, pageIndex });
                   }
                   this.setState(prevState => {
                       return {
                           pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
                       };
                   });
               }
           };

           cancelAddrChgWarningMessage = () => {
               this.setState({ showMasterRegAddrChgModal: false });
           };

           cancelCostBasisModal = () => {
               this.setState({ showCostBasisWarningMessage: false });
           };

           showModalBasedOnCostBasis = costBasis => {
               if (
                   (costBasis && costBasis.toLowerCase()) === 'identified lot' ||
                   (costBasis && costBasis.toLowerCase()) === 'specified lot'
               ) {
                   this.setState({ showCostBasisWarningMessage: true, selectedFundCostBasis: costBasis });
               } else {
                   this.setState({ showCostBasisWarningMessage: false, selectedFundCostBasis: costBasis });
               }
           };

           saveChildrenState = (data, index) => {
               const { resetPageNumber } = this.props;
               this.setState(prevState => {
                   let { childrenState } = prevState;
                   if (
                       index === 0 &&
                       data.accountSelected &&
                       childrenState.length &&
                       data.accountSelected.accountNumber !== childrenState[0].accountSelected.accountNumber
                   ) {
                       childrenState = [];
                       resetPageNumber();
                   }

                   childrenState[+index] = data;

                   if (index === 1) {
                       childrenState.length = 2;
                   }
                   return {
                       childrenState,
                   };
               });
           };

           checkTradingLimit = stateValue => () => {
               const { getLiquidationTradingLimit } = this.props;
               const { childrenState } = this.state;
               const tradingLimitPayload = {
                   companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                   masterReg: childrenState[0].accountSelected.accountNumber,
                   amount: parseFloat(stateValue.selectedFund.sellingamt),
                   fundAccountNumber: stateValue.selectedFund.fundAccountNumber,
                   accountType: childrenState[0].accountSelected.accountType.trim(),
               };

               getLiquidationTradingLimit({ tradingLimitPayload, token: localStorage.getItem('token') });
           };

           scrollToSection = (index, section) => () => {
               this.editSection = section;
               this.setState(
                   () => {
                       return {
                           pageIndex: index,
                       };
                   },
                   () => {
                       this.editSection = null;
                   },
               );
           };

           getLiquidationPayloadForPropotionate = async (payloadObj,index=0) =>
           {
                const { liquidationData, tradingWindowData } = this.props;
                const date = tradingWindowData.effectiveDate;
                const { accountType, accountTypeCode, socialCode } =
                    liquidationData.page0 && liquidationData.page0.accountSelected;
                const {
                    stateTaxAmount,
                    checkDeliveryAddress,
                    fedTaxAmount,
                    stateCodeGroup = '',
                } = liquidationData.page2;
                const { details, mode } = liquidationData.paymentMethod[0];
                const age =
                liquidationData &&
                liquidationData.page0 &&
                liquidationData.page0.accountSelected &&
                liquidationData.page0.accountSelected.accountOwnerAge;

                return {
                   companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                   fundNumber:payloadObj.fundNumber,
                   fundAccountNumber:payloadObj.fundAccountNumber,
                   liquidityNoticeFlag:payloadObj.liquidityNoticeFlag,
                   accountTypeCode,
                   socialCode,
                   typeValueReq: 'D',
                   liquidateAmount: this.getFormattedSellingValue(payloadObj.sellingamt),
                   taxWithholdingCode: '',
                   taxAmountElect: payloadObj?.fedTaxAmount  ? payloadObj?.fedTaxAmount : "",
                   taxPercentageElect: '',
                   stateTaxWithholdingAmt: payloadObj?.stateTaxAmount ? payloadObj?.stateTaxAmount : "",
                   taxGroup: stateCodeGroup,
                   paymentMethod: mode === 'Offline' ? '01' : '05',
                   accountType: accountType && accountType.trim(),
                   effectiveDate: date?.split(' ')[0],
                   timeSubmitted: new Date(),
                   marketEndTime: tradingWindowData.tradingEndTime,
                   
                   age: `${age && age !== '' ? age : 0}`,
                   addressLine1: mode === 'Offline' ? checkDeliveryAddress.address1 : '',
                   addressLine2: mode === 'Offline' ? checkDeliveryAddress.address2 : '',
                   city: mode === 'Offline' ? checkDeliveryAddress.city : '',
                   state: mode === 'Offline' ? checkDeliveryAddress.State : '',
                   zipCode: mode === 'Offline' ? checkDeliveryAddress.ZipCode : '',
                   fundingSource: {
                       method: mode === 'Online' ? 'existingBank' : '',
                       bankAccount: {
                           accountType: mode === 'Online' ? 'C' : '',
                           financialInstitute: mode === 'Online' ? details.bankName : '',

                           transitRoutingNumber: mode === 'Online' ? details.bankRoutEncNumber : '',
                           bankAccountNumber: mode === 'Online' ? details.bankAccEncNumber : '',
                       },
                   },
                }

           }

           getLiquidationPayload = async () => {
               const { liquidationData, tradingWindowData } = this.props;
               const date = tradingWindowData.effectiveDate;
               const { accountType, accountTypeCode, socialCode } =
                   liquidationData.page0 && liquidationData.page0.accountSelected;
               const {
                   fundNumber,
                   fundAccountNumber,
                   amtType,
                   sellingamt,
                   currentAccountBalance,
                   userinputpercent,
                   availableShares,
                   liquidityNoticeFlag,
               } = liquidationData.page1 && liquidationData.page1.selectedFund;
               const {
                   stateTaxAmount,
                   checkDeliveryAddress,
                   fedTaxAmount,
                   stateCodeGroup = '',
               } = liquidationData.page2;
               const { details, mode } = liquidationData.paymentMethod[0];

               const sellingAmount = `${parseFloat(sellingamt).toFixed(2)}`.split('.');
               const age =
                   liquidationData &&
                   liquidationData.page0 &&
                   liquidationData.page0.accountSelected &&
                   liquidationData.page0.accountSelected.accountOwnerAge;

               // Developer Gaurav Kumar / Ramesh (18-11-2021)
               // Todo need to remove the below lines once KMS encryption issue resolved
               // Implimented alternative approach by holding encrypted value from banklist api call
               // transitRoutingNumber: await this.getEncryptedData(bankAccDetails[0].bankRoutingNumber),
               // bankAccountNumber: await this.getEncryptedData(bankAccDetails[0].bankAccountNumber),

               // bankAccountNumber : bankAccDetails[0].bankAccEncNumber,
               // transitRoutingNumber :  bankAccDetails[0].bankRoutEncNumber

               return {
                   companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                   fundNumber,
                   fundAccountNumber,
                   liquidityNoticeFlag,
                   accountTypeCode,
                   socialCode,
                   typeValueReq: this.getTypeValueReq({ amtType, sellingamt, currentAccountBalance }),
                   liquidateAmount: this.getLiquidateAmount({
                       amtType,
                       sellingamt,
                       currentAccountBalance,
                       userinputpercent,
                       availableShares,
                   }),
                   taxWithholdingCode: '',
                   taxAmountElect: fedTaxAmount && fedTaxAmount.toFixed(2),
                   taxPercentageElect: '',
                   stateTaxWithholdingAmt: `${stateTaxAmount && stateTaxAmount.toFixed(2)}`,
                   taxGroup: stateCodeGroup,
                   paymentMethod: mode === 'Offline' ? '01' : '05',
                   accountType: accountType && accountType.trim(),
                   effectiveDate: date.split(' ')[0],
                   timeSubmitted: new Date(),
                   marketEndTime: tradingWindowData.tradingEndTime,

                   age: `${age && age !== '' ? age : 0}`,
                   addressLine1: mode === 'Offline' ? checkDeliveryAddress.address1 : '',
                   addressLine2: mode === 'Offline' ? checkDeliveryAddress.address2 : '',
                   city: mode === 'Offline' ? checkDeliveryAddress.city : '',
                   state: mode === 'Offline' ? checkDeliveryAddress.State : '',
                   zipCode: mode === 'Offline' ? checkDeliveryAddress.ZipCode : '',
                   fundingSource: {
                       method: mode === 'Online' ? 'existingBank' : '',
                       bankAccount: {
                           accountType: mode === 'Online' ? 'C' : '',
                           financialInstitute: mode === 'Online' ? details.bankName : '',

                           transitRoutingNumber: mode === 'Online' ? details.bankRoutEncNumber : '',
                           bankAccountNumber: mode === 'Online' ? details.bankAccEncNumber : '',
                       },
                   },
               };
           };

           handleSubmit = async () => {
               window.sessionStorage.removeItem('fedTax');
               window.sessionStorage.removeItem('fedTax');
               const { submitLiquidationData,liquidationData } = this.props;

               if (isTokenAvailable() === null) {
                   this.setState({ showAuthBox: true });
               } else {

                    if(liquidationData?.page1?.isProportinateSelected)
                    {
                        for(let i=0;i<liquidationData?.page1?.proportianteSelectedFundsList?.length;i++)
                        {
                            const liquidationPayload = await this.getLiquidationPayloadForPropotionate(liquidationData?.page1?.proportianteSelectedFundsList[i],i);
                            submitLiquidationData({ liquidationPayload, token: localStorage.getItem('token') });
                        }
                    }
                    else
                    {
                        const liquidationPayload = await this.getLiquidationPayload();
                        submitLiquidationData({ liquidationPayload, token: localStorage.getItem('token') });
                    }

                   //submitLiquidationData({ liquidationPayload, token: localStorage.getItem('token') });
                   this.onSwitchComponent(true, this.state, 3)();
                   // invoke the liquidation api here
               }
           };

           isIRA = () => {
               const { liquidationData } = this.props;
               const accountType = liquidationData.page0 && liquidationData.page0.accountSelected.accountType;
               const trimAccountType = accountType.trim();
               return !!(
                   trimAccountType === TransactionConstants.TRADITIONAL_IRA ||
                   trimAccountType === TransactionConstants.ROTH_IRA ||
                   trimAccountType === TransactionConstants.SEP_IRA ||
                   accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
                   trimAccountType === TransactionConstants.ROLLOVER_IRA ||
                   trimAccountType === TransactionConstants.GUARDIAN_IRA ||
                   trimAccountType === TransactionConstants.SIMPLE_IRA ||
                   trimAccountType === TransactionConstants.BENEFICIARY_IRA ||
                   trimAccountType === TransactionConstants.BENEFICIARY_ROTH_IRA ||
                   trimAccountType === TransactionConstants.OPTIONAL_RETIREMENT_IRA ||
                   trimAccountType === TransactionConstants.PENSION_IRA ||
                   trimAccountType === TransactionConstants.ROTH_CONVERSION_IRA ||
                   trimAccountType === TransactionConstants.SIMPLE_IRA_DFI ||
                   trimAccountType === TransactionConstants.SIMPLE_IRA_NON_DFI
               );
           };

           isIRATaxWithhold = () => {
               const { liquidationData } = this.props;
               const accountType = liquidationData?.page0 && liquidationData?.page0?.accountSelected?.accountType.trim();
               const trimAccuntType = accountType?.trim();
               return !!(
                   trimAccuntType === TransactionConstants.TRADITIONAL_IRA ||
                   trimAccuntType === TransactionConstants.SEP_IRA ||
                   trimAccuntType === TransactionConstants.SIMPLE_IRA ||
                   trimAccuntType === TransactionConstants.ROLLOVER_IRA ||
                   trimAccuntType === TransactionConstants.ARCHER_MSA_ACCOUNT_TYPE ||
                   trimAccuntType === TransactionConstants.BENEFICIARY_IRA ||
                   trimAccuntType === TransactionConstants.SIMPLE_IRA_DFI ||
                   trimAccuntType === TransactionConstants.SIMPLE_IRA_NON_DFI ||
                   trimAccuntType === TransactionConstants.ROTH_CONVERSION_IRA
               );
           };

           /**
            * This function populates the notificationMsg if there
            * is any error encountered in the child component.
            *
            * @bool - True (has error) False (has no error)
            */
           handleError = bool => {
               if (bool) {
                   this.setState({
                       displayNotification: true,
                       notificationMsg: { message: TransactionConstants.NOTIFICATION_ERROR_MESSAGE },
                   });
               }
           };

           getAge = () => {
               const { profileInformationData } = this.props;
               let age = 0;
               if (
                   profileInformationData &&
                   profileInformationData.profileInformation &&
                   profileInformationData.profileInformation.dateOfBirth
               ) {
                   const dob = new Date(profileInformationData.profileInformation.dateOfBirth);
                   const ageDiff = Date.now() - dob.getTime();
                   const ageDt = new Date(ageDiff);
                   age = Math.abs(ageDt.getUTCFullYear() - 1970);
               }
               return age;
           };

           getIsPrematureDistribution = () => {
               const { liquidationData } = this.props;
               const ageOnAccount =
                   liquidationData &&
                   liquidationData.page0 &&
                   liquidationData.page0.accountSelected &&
                   liquidationData.page0.accountSelected.accountOwnerAge;
               const age = ageOnAccount && ageOnAccount !== '' ? ageOnAccount : 0;
               return age < 59.5;
           };

           handleCancelLiquidation = () => {
               const { clearLiquidationData } = this.props;
               this.setState({ cancelLiquidation: true });
               clearLiquidationData();
           };

           handleDoNotCancelLiquidation = () => {
               const { setNotToCancelLiquidation } = this.props;
               setNotToCancelLiquidation();
           };


           assignFundWhenOneFundFail = () =>
           {
                const { liquidationData } = this.props;
                const {proportionateFaildFundList} = liquidationData;
                let page1fundListData = liquidationData?.page1?.fundsAvailable;
                let selectedFund = null;
                let selectedFundIndex = 0;
                const fundList = page1fundListData.map((item,index)=>{

                    if(item.fundAccountNumber  == proportionateFaildFundList[0].fundAccountNumber)
                    {
                        selectedFund = proportionateFaildFundList[0];
                        selectedFundIndex = index;
                        return {
                            ...proportionateFaildFundList[0],
                            selected:true
                        }
                    }
                    else
                    {
                        return {
                            ...item,
                            selected:false
                        }
                    }
                })

                return {fundList,selectedFund,selectedFundIndex};
           }

           handleResubmit = () =>
           {
               const { liquidationData,savePageState } = this.props;
               if(liquidationData?.proportionateFaildFundList?.length > 1)
               {
                    this.props.removeSavedStep(1);
               }
               else if(liquidationData?.proportionateFaildFundList?.length == 1)
               {
                    const assignObj = this.assignFundWhenOneFundFail();
                    let stateData = liquidationData?.page1;
                    stateData.fundsAvailable = assignObj.fundList;
                    stateData.selectedFund = assignObj.selectedFund;
                    stateData.touchedFundNo = assignObj?.selectedFund.fundNumber;
                    stateData.touchedIndex = assignObj?.selectedFundIndex;
                    savePageState({ data: stateData, pageIndex:1 });

                    let step2Data = liquidationData.page2;
                    const stateTaxPercent = parseFloat(step2Data.stateTaxPercent);
                    step2Data.fedTaxAmount = (parseFloat(assignObj.selectedFund.sellingamt) * (step2Data.fedTaxPercent/100));
                    step2Data.stateTaxAmount = (parseFloat(assignObj.selectedFund.sellingamt) * (stateTaxPercent/100));
                    savePageState({ data: step2Data, pageIndex:2 });
               }
               this.props.setResubmitFlow();
               this.setState({pageIndex:1 })
           }

           bindComponent() {
               const { pageIndex, childrenState, addressValid, showMasterRegAddrChgModal } = this.state;
               const {
                   getFunds,
                   accOpeningReducerData,
                   getBankAccountInfo,
                   bankAccountReducerData,
                   liquidationData,
                   getAccountList,
                   cancelTradingLimitMessage,
                   getCheckDeliveryAddresss,
                   getStateCodeGroup,
                   validateACHLimit,
                   cancelACHLimit,
                   purchaseReducerData,
                   setNextPageNumber,
                   setPaymentMethod,
                   tradingWindowData,
                   location,
                   masterRegistrationAccountData,
                   setCancelLiquidation,
                   getMemberProfileInformations,
                   setNextPageNumberPositions,
                   getTransHolderList,
                   transactionHolderData,
                   resetLiquidationSubmit,
                   resetGoToNextPageVariable,
                   setProportionateWhenOneFundFail,
                   clearBankAccDetails,
                   removeSavedStep
               } = this.props;

               const [accountSelectionState] = childrenState;
               const { accountSelected } = accountSelectionState || {};

               const navigationObj = {
                   onCancel: this.onCancel,
                   onPrimaryClick: this.onSwitchComponent(true, this.state, pageIndex),
                   onSecondaryClick:
                       pageIndex === 2 ? this.onSwitchComponent(false, {}, 2) : this.onSwitchComponent(false),
                   arialabelback: '',
                   arialabelnext: '',
                   isCancel: true,
                   portalRoot: this.bottomNav,
               };
               const transactionType = 'liquidate';

               switch (pageIndex) {
                   case 1: {
                       return (
                           <ErrorBoundary>
                               <FundSelection
                                   accDetails={accountSelected}
                                   accOpeningReducerData={accOpeningReducerData}
                                   getFunds={getFunds}
                                   navigationObj={navigationObj}
                                   savedState={childrenState[+pageIndex]}
                                   saveChildrenState={this.saveChildrenState}
                                   pageIndex={pageIndex}
                                   editSection={this.editSection}
                                   cancelTradingLimitMessage={cancelTradingLimitMessage}
                                   onSwitchComponent={this.onSwitchComponent}
                                   checkTradingLimit={this.checkTradingLimit}
                                   setTradingWarning={this.setTradingWarning}
                                   handleError={this.handleError}
                                   setNextPageNumber={setNextPageNumber}
                                   location={location}
                                   getIsPrematureDistribution={this.getIsPrematureDistribution}
                                   setCancelLiquidation={setCancelLiquidation}
                                   getMemberProfileInformations={getMemberProfileInformations}
                                   transactionType={transactionType}
                                   setNextPageNumberPositions={setNextPageNumberPositions}
                                   showModalBasedOnCostBasis={this.showModalBasedOnCostBasis}
                                   getTransHolderList={getTransHolderList}
                               />
                           </ErrorBoundary>
                       );
                   }
                   case 2: {
                       return (
                           <ErrorBoundary>
                               <FundingOption
                                   accDetails={accountSelected}
                                   bankAccountInfo={bankAccountReducerData}
                                   getBankAccountInfo={getBankAccountInfo}
                                   navigationObj={navigationObj}
                                   savedState={childrenState[+pageIndex]}
                                   saveChildrenState={this.saveChildrenState}
                                   pageIndex={pageIndex}
                                   editSection={this.editSection}
                                   getCheckDeliveryAddresss={getCheckDeliveryAddresss}
                                   getStateCodeGroup={getStateCodeGroup}
                                   setTradingWarning={this.setTradingWarning}
                                   validateACHLimit={validateACHLimit}
                                   cancelACHLimit={cancelACHLimit}
                                   purchaseData={purchaseReducerData}
                                   onSwitchComponent={this.onSwitchComponent}
                                   isIRA={this.isIRA}
                                   handleError={this.handleError}
                                   setPaymentMethod={setPaymentMethod}
                                   setCancelLiquidation={setCancelLiquidation}
                                   isIRATaxWithhold={this.isIRATaxWithhold}
                                   liquidationDataEdit={liquidationData != null ? liquidationData.page2 : null}
                               />
                           </ErrorBoundary>
                       );
                   }
                   case 3: {
                       const mergeDetails = childrenState
                           .map(item => item.displayDetails)
                           .reduce((acc, curr) => {
                               return { ...acc, ...curr };
                           });
                       const verifyDetails = Array.from(Object.keys(mergeDetails), key => mergeDetails[key.toString()]);
                       verifyDetails[0].data[0].value =
                           transactionHolderData &&
                           transactionHolderData.transactionList &&
                           transactionHolderData.transactionList.accountNickName
                               ? transactionHolderData.transactionList.accountNickName
                               : '';
                       const { tradeBox } = this.state;
                       navigationObj.primaryText = TransactionConstants.SUBMIT_TEXT;
                       return (
                           <ErrorBoundary>
                               <VerifyDetails
                                   verifyDetails={verifyDetails}
                                   transactionType={transactionType}
                                   navigationObj={navigationObj}
                                   scrollToSection={this.scrollToSection}
                                   tradeBox={tradeBox}
                                   setTradingWarning={this.setTradingWarning}
                                   handleSubmit={this.handleSubmit}
                                   isIRA={this.isIRA}
                                   handleError={this.handleError}
                                   tradingWindowData={tradingWindowData}
                                   setCancelLiquidation={setCancelLiquidation}
                                   isProportinateSelected={liquidationData.page1?.isProportinateSelected}
                                   liquidationData={liquidationData}
                                   flowName={"sell"}
                                   resetLiquidationSubmit={resetLiquidationSubmit}
                               />
                           </ErrorBoundary>
                       );
                   }
                   case 4: {
                       const { isLoading, isError } = liquidationData;
                       return (
                           <ErrorBoundary>
                               <ConfirmationComponent
                                   flowName={"sell"}
                                   setTradingWarning={this.setTradingWarning}
                                   isLoading={isLoading}
                                   isError={isError}
                                   fundAccountNumber={
                                       liquidationData.page1 && liquidationData.page1?.selectedFund?.fundAccountNumber
                                   }
                                   handleError={this.handleError}
                                   isProportinateSelected={liquidationData?.page1?.isProportinateSelected}
                                   proportianteSelectedFundsList={liquidationData?.page1?.proportianteSelectedFundsList}
                                   proportinateFundPassedList={liquidationData?.proportinateFundPassedList}
                                   displayFailedPropotinateFundListArray={this.state.displayFailedPropotinateFundListArray}
                                   proportionateSubmitAPICount={this.state.liquidationSubmitAPiCount}
                                   removeSavedStep={removeSavedStep}
                                   handleResubmit={this.handleResubmit}
                                   resetGoToNextPageVariable={resetGoToNextPageVariable}
                                   clearBankAccDetails={clearBankAccDetails}
                                   setProportionateWhenOneFundFail={setProportionateWhenOneFundFail}
                                   isProportionateResubmitFlow={liquidationData?.isProportionateResubmitFlow}
                               />
                           </ErrorBoundary>
                       );
                   }
                   default: {
                       const content = constants.accountsAvailableForLiquidation;
                       return (
                           <ErrorBoundary>
                               <AccountSelection
                                   savedState={childrenState[+pageIndex]}
                                   saveChildrenState={this.saveChildrenState}
                                   navigationObj={navigationObj}
                                   onSwitchComponent={this.onSwitchComponent}
                                   pageIndex={pageIndex}
                                   editSection={this.editSection}
                                   accountDetails={masterRegistrationAccountData?.masterRegAccountList}
                                   getAccountsList={getAccountList}
                                   content={content}
                                   isTransaction
                                   transactionType={transactionType}
                                   handleError={this.handleError}
                                   setCancelLiquidation={setCancelLiquidation}
                                   addressValid={addressValid}
                                   showMasterRegAddrChgModal={showMasterRegAddrChgModal}
                               />
                           </ErrorBoundary>
                       );
                   }
               }
           }

           moveNextPage = () => {
               this.setState({ showAuthBox: false });
           };

           render() {
               const {
                   pageIndex,
                   displayNotification,
                   notificationMsg,
                   showCancelLiqModal,
                   cancelLiquidation,
                   showMasterRegAddrChgModal,
                   showAuthBox,
               } = this.state;
               const { accOpeningReducerData, bankAccountReducerData, tradingWindowData } = this.props;
               const activeCrumb = TransactionConstants.ACTIVE_BREADCRUMB;
               const contentData = constants.liquidation;
               const loading = bankAccountReducerData.isLoading || accOpeningReducerData.isLoading;
               const cancelTransactionText = TransactionConstants.CANCEL_TRANSACTION_DESCRIPTION_GENERAL;

               return (
                   <>
                       {<Authenticate showAuthBox={showAuthBox} moveNextPage={this.moveNextPage} />}
                       {cancelLiquidation ? <Redirect to="/accountholder" /> : ''}

                       {!tradingWindowData.canTrade && (
                           <WBlueBanner text={tradingWindowData.tradingClosureMsg} onClose={this.onNotificationClose} />
                       )}
                       {loading && <WSpinner loading={loading} />}

                       {displayNotification && (
                           <WBlueBanner text={notificationMsg} onClose={this.onNotificationClose} />
                       )}
                       {showCancelLiqModal && (
                           <ConformationModal
                               modalTitle={TransactionConstants.CANCEL_TRANSACTION_TEXT}
                               modalBodyText={cancelTransactionText}
                               primaryButtonText="Yes"
                               secondaryButtonText="No"
                               onPrimaryClick={this.handleCancelLiquidation}
                               onSecondaryClick={this.handleDoNotCancelLiquidation}
                           />
                       )}

                       {showMasterRegAddrChgModal && (
                           <ConfirmModal
                               modalTitle={TransactionConstants.INSTRUCTIONS_TEXT}
                               modalBodyText={TransactionConstants.ADDRESS_CHANGE_TEXT}
                               primaryButtonText={TransactionConstants.OK_TEXT}
                               onPrimaryClick={this.cancelAddrChgWarningMessage}
                           />
                       )}

                       <div>
                           <div className="container">
                               <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={breadCrumURL} />

                               {pageIndex !== 4 && (
                                   <>
                                       <FlexDiv>
                                           <PageHeading>{contentData.pageHeading1}</PageHeading>
                                       </FlexDiv>
                                       <Stepper
                                           style={stepperStyle}
                                           currentPage={pageIndex}
                                           pages={TransactionConstants.STEPS}
                                       />
                                   </>
                               )}
                           </div>
                           {this.bindComponent()}
                           <div ref={this.bottomNav} />
                       </div>
                   </>
               );
           }
       }
Liquidation.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    getFunds: PropTypes.func,
    accOpeningReducerData: PropTypes.instanceOf(Object),
    getBankAccountInfo: PropTypes.func,
    submitLiquidationData: PropTypes.func,
    getAccountList: PropTypes.func,
    clearFundAccountList: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    bankAccountReducerData: PropTypes.instanceOf(Object),
    clearBankAccDetails: PropTypes.func,
    savePageState: PropTypes.func,
    getLiquidationTradingLimit: PropTypes.func,
    cancelTradingLimitMessage: PropTypes.func,
    getCheckDeliveryAddresss: PropTypes.func,
    getStateCodeGroup: PropTypes.func,
    setTradingWindowWarning: PropTypes.func,
    purchaseReducerData: PropTypes.instanceOf(Object),
    validateACHLimit: PropTypes.func,
    cancelACHLimit: PropTypes.func,
    liquidationData: PropTypes.instanceOf(Object),
    setNextPageNumber: PropTypes.instanceOf(Object),
    resetPageNumber: PropTypes.instanceOf(Object),
    tradingWindowData: PropTypes.instanceOf(Object),
    setPaymentMethod: PropTypes.func,
    getTradingClosureWindow: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    masterRegistrationAccountData: PropTypes.shape({
        masterRegAccountList: PropTypes.instanceOf(Object),
    }).isRequired,
    clearLiquidationData: PropTypes.func,
    setCancelLiquidation: PropTypes.func,
    setNotToCancelLiquidation: PropTypes.func,
    clearMasterRegInfo: PropTypes.func,
    getMemberProfileInformations: PropTypes.func,
    profileInformationData: PropTypes.instanceOf(Object),
    clearExchangeData: PropTypes.func,
    setNextPageNumberPositions: PropTypes.func,
    getTransHolderList: PropTypes.func,
    transactionHolderData: PropTypes.instanceOf(Object),
};
Liquidation.defaultProps = {
    getFunds: () => {},
    accOpeningReducerData: {},
    getBankAccountInfo: () => {},
    submitLiquidationData: () => {},
    getAccountList: {},
    fundAccountData: {},
    bankAccountReducerData: {},
    clearFundAccountList: () => {},
    clearBankAccDetails: () => {},
    getCheckDeliveryAddresss: () => {},
    savePageState: () => {},
    getStateCodeGroup: () => {},
    getLiquidationTradingLimit: () => {},
    cancelTradingLimitMessage: () => {},
    setTradingWindowWarning: () => {},
    purchaseReducerData: {},
    validateACHLimit: () => {},
    cancelACHLimit: () => {},
    liquidationData: () => {},
    setNextPageNumber: () => {},
    resetPageNumber: () => {},
    tradingWindowData: {},
    setPaymentMethod: () => {},
    getTradingClosureWindow: () => {},
    location: {},
    clearLiquidationData: () => {},
    setCancelLiquidation: () => {},
    setNotToCancelLiquidation: () => {},
    clearMasterRegInfo: () => {},
    getMemberProfileInformations: () => {},
    profileInformationData: {},
    clearExchangeData: () => {},
    setNextPageNumberPositions: () => {},
    getTransHolderList: () => {},
    transactionHolderData: {},
};
export const mapStateToProps = state => {
    return {
        accOpeningReducerData: state.accOpeningReducerData,
        bankAccountReducerData: state.bankAccountReducerData,
        fundAccountData: state.fundAccountData,
        liquidationData: state.liquidationData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
        purchaseReducerData: state.purchaseData,
        tradingWindowData: state.tradingWindowData,
        profileInformationData: state.profileInformationData,
        transactionHolderData: state.transactionHolderData,
    };
};
export const mapDispatchToProps = {
    getBankAccountInfo: bankAccountAction.getBankAccounts,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    submitLiquidationData: liquidationActions.submitLiquidationData,
    getAccountList: masterRegistrationAccountActions.getAccountsList,
    getFunds: fundAccountActions.getFundAccountsList,
    getLiquidationTradingLimit: liquidationActions.getLiquidationTradingLimit,
    clearFundAccountList: fundAccountActions.clearFundAccountList,
    setDisplayNotification: masterRegistrationAccountActions.setDisplayNotification,
    getCheckDeliveryAddresss: liquidationActions.getCheckDeliveryAddress,
    savePageState: liquidationActions.savePageState,
    getStateCodeGroup: liquidationActions.getStateCodeGroup,
    cancelTradingLimitMessage: liquidationActions.cancelTradingLimitMessage,
    validateACHLimit: purchaseActions.validateACHLimit,
    cancelACHLimit: purchaseActions.cancelACHLimit,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    setTradingWindowWarning: tradingWindowActions.setTradingWindowWarning,
    setNextPageNumber: liquidationActions.setNextPageNumber,
    resetPageNumber: liquidationActions.resetPageNumber,
    setPaymentMethod: liquidationActions.setPaymentMethod,
    clearLiquidationData: liquidationActions.clearLiquidationData,
    setCancelLiquidation: liquidationActions.setCancelLiquidation,
    setNotToCancelLiquidation: liquidationActions.setNotToCancelLiquidation,
    clearMasterRegInfo: masterRegistrationAccountActions.clearMasterRegInfo,
    getMemberProfileInformations: profileSettingsAction.getMemberProfileInformations,
    clearExchangeData: exchangeActions.clearExchangeData,
    setNextPageNumberPositions: liquidationActions.setNextPageNumberPositions,
    getTransHolderList: transactionHistoryActions.getTransHolderList,

    resetLiquidationSubmit: liquidationActions.resetLiquidationSubmit,
    setProportionateWhenOneFundFail: liquidationActions.setProportionateWhenOneFundFail,
    setResubmitFlow: liquidationActions.setResubmitFlow,
    resetGoToNextPageVariable: liquidationActions.resetGoToNextPageVariable,
    removeSavedStep: liquidationActions.removeSavedStep
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Liquidation));
