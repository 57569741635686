import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as MessageActionTypes from '../ReduxConstants/MessageCenterConstants';
import makeApiRequest from '../Network/ApiInterface';
import axios from 'axios';
import Config from 'env/Config';
import envConfig from 'env/EnvConfig';
import { LocalStorageHelper } from 'utils/sessionHelper';

export const sendSecureMessage = payLoad => async dispatch => {
    const env = '';

    dispatch({
        type: MessageActionTypes.SEND_SECURE_MESSAGE,
        payload: { isLoading: true },
    });

    makeApiRequest(
        MessageActionTypes.SEND_SECURE_MESSAGE,
        payLoad,
        env,
        response => {
            if (
                response.status === '201' ||
                response.status === 201 ||
                response.status === 200 ||
                response.status === '200'
            ) {
                dispatch({
                    type: MessageActionTypes.SEND_SECURE_MESSAGE_SUCESS,
                    payload: {
                        sentSecureMessage: {
                            success: true,
                            message: response.data.sentSecureMessage,
                            confirmation: MessageActionTypes.SEND_SECURE_MESSAGE_CONFIRM_MESSAGE,
                        },
                    },
                });
            } else {
                dispatch({
                    type: MessageActionTypes.SEND_SECURE_MESSAGE_ERROR,
                    payload: {
                        sentSecureMessage: {
                            success: false,
                            message: undefined,
                            confirmation: MessageActionTypes.SEND_SECURE_MESSAGE_ERROR_MESSAGE,
                        },
                    },
                });
            }
        },
        () => {
            dispatch({
                type: MessageActionTypes.SEND_SECURE_MESSAGE_ERROR,
                payload: {
                    sentSecureMessage: {
                        success: false,
                        message: undefined,
                        confirmation: MessageActionTypes.SEND_SECURE_MESSAGE_ERROR_MESSAGE,
                    },
                },
            });
        },
    );
};

/**
 *
 * @param {*} data
 */
export const getUnreadMessageCount = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_UNREAD_MESSAGE_COUNT,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.status === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.GET_UNREAD_MESSAGE_COUNT_SUCCESS,
                        payload: {
                            unreadMessages: response.data.count,
                        },
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_UNREAD_MESSAGE_COUNT_ERROR,
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_UNREAD_MESSAGE_COUNT_ERROR,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_UNREAD_MESSAGE_COUNT_ERROR,
                payload: error,
            });
        },
    );
};

export const setAuthToken = token => dispatch => {
    dispatch({
        type: MessageActionTypes.SET_AUTH_TOKEN,
        payload: token,
    });
};

export const setMessageType = messageType => dispatch => {
    dispatch({
        type: MessageActionTypes.SET_MESSAGE_TYPE,
        payload: messageType,
    });
};

export const mesageIconClicked = () => dispatch => {
    dispatch({
        type: ActionTypes.MESSAGE_ICON_CLICKED,
        payload: true,
    });
};

export const closeMessageList = () => dispatch => {
    dispatch({
        type: ActionTypes.MESSAGE_LIST_CLOSED,
        payload: false,
    });
};

export const getTopics = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_TOPICS,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.status === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.GET_TOPICS_RECEIVED,
                        payload: response.data.topics,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_TOPICS_ERROR,
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_TOPICS_ERROR,
                });
            }
        },
        () => {
            dispatch({
                type: ActionTypes.GET_TOPICS_ERROR,
            });
        },
    );
};

export const getAccounts = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_ACCOUNTS,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.status === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNTS_RECEIVED,
                        payload: response.data.accounts.map((item, index) => {
                            return { key: index, value: item };
                        }),
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNTS_ERROR,
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_ACCOUNTS_ERROR,
                });
            }
        },
        () => {
            dispatch({
                type: ActionTypes.GET_ACCOUNTS_ERROR,
            });
        },
    );
};

export const redirectToMessageCenter = data => dispatch => {
    dispatch({
        type: ActionTypes.REDIRECT_TO_MESSAGE_CENTER,
        ...data,
    });
};

/**
 * When user unchecks the selectAll checkbox, selected messages in state will be
 *
 * @param {String} box
 */

export const deleteAllSelectedMessages = box => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.DELETE_ALL_SELECTED_MESSAGES_SUCCESS,
                payload: box,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.DELETE_ALL_SELECTED_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Removes the message from selected list of messages for delete based on user click/preference.
 *
 * @param {Array} selectedMessages
 * @param {Object} message
 */
export const deleteSelectedMessages = (selectedMessages, message) => {
    return async dispatch => {
        try {
            const newSelectedMessages = selectedMessages.filter(item => item.skCreatedAt !== message.skCreatedAt);
            dispatch({
                type: MessageActionTypes.DELETE_SELECTED_MESSAGES_SUCCESS,
                payload: newSelectedMessages,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.DELETE_SELECTED_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * When user selects selectAll checkbox, selected messages in state will be updated
 *
 * @param {Object} messages
 */

export const updateAllSelectedMessages = messages => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.UPDATE_ALL_SELECTED_MESSAGES_SUCCESS,
                payload: messages,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.UPDATE_ALL_SELECTED_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Selected Messages will be updated when the user checks/unchecks the checkboxes associated with messages
 *
 * @param {Array} selectedMessages
 * @param {Object} message
 */

export const updateSelectedMessages = (selectedMessages, message) => {
    return async dispatch => {
        try {
            const newSelectedMessages = [...selectedMessages, message];
            dispatch({
                type: MessageActionTypes.UPDATE_SELECTED_MESSAGES_SUCCESS,
                payload: newSelectedMessages,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.UPDATE_SELECTED_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Sets the selectAll state based on the selectAll checkbox toggle
 *
 * @param {boolean} selectAll
 */

export const setSelectAll = selectAll => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SET_SELECT_ALL_SUCCESS,
                payload: selectAll,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SET_SELECT_ALL_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Sets the confirmation message on success
 *
 * @param {string} mcConfirmationMsg
 */

export const setMcConfirmationMsg = mcConfirmationMsg => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SET_MC_CONFIRMATION_MSG_SUCCESS,
                payload: mcConfirmationMsg,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SET_MC_CONFIRMATION_MSG_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Soft deletes the selected messages
 *
 * @param {Object} MessageIds
 */

export const deleteMessages = payload => {
    return dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SOFT_DELETE_MESSAGE_START,
                payload: { isLoading: true },
            });
            const env = '';
            makeApiRequest(
                MessageActionTypes.DELETE_MC_MESSAGES,
                payload,
                env,
                response => {
                    if (response.status === 200) {
                        const mcConfirmationMsg = MessageActionTypes.MESSAGE_CENTER_CONFIRM_MESSAGE;
                        dispatch({
                            type: MessageActionTypes.SOFT_DELETE_MESSAGE_SUCCESS,
                            payload: { mcConfirmationMsg, isLoading: false },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: MessageActionTypes.SOFT_DELETE_MESSAGE_ERROR,
                        payload: { error, isLoading: false },
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SOFT_DELETE_MESSAGE_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Decides if a confirmation dialog has to be displayed
 *
 * @param {boolean} showConfirmDialog
 */

export const showConfirmDialog = confirmDialog => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SHOW_DELETE_CONFIRM_DIALOG_SUCCESS,
                payload: confirmDialog,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SHOW_DELETE_CONFIRM_DIALOG_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Decides if a success message dialog has to be displayed
 *
 * @param {boolean} showSuccessDialog
 */

export const showSuccessDialog = showSuccessMsgDialog => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SHOW_SUCCESS_MSG_DIALOG_SUCCESS,
                payload: showSuccessMsgDialog,
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SHOW_SUCCESS_MSG_DIALOG_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Decides if a success message dialog has to be displayed
 *
 * @param {boolean} showSuccessDialog
 */

export const setLoginDisplayMessage = () => {
    return async dispatch => {
        dispatch({
            type: MessageActionTypes.SET_LOGIN_DISPLAY_MESSAGE_SUCCESS,
        });
    };
};

/**
 *
 * @param {String} messageType
 * @param {Number} pageSize
 */
export const setPageSize = (messageType, pageSize) => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SET_PAGE_SIZE_SUCCESS,
                payload: { messageType, pageSize },
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SET_PAGE_SIZE_ERROR,
                payload: error,
            });
        }
    };
};

/**
 *
 * @param {String} orderBy
 * @param {String} orderType
 */
export const setOrderByOrderType = (orderBy, orderType) => {
    return async dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.SET_ORDER_BY_ORDER_TYPE_SUCCESS,
                payload: { orderBy, orderType },
            });
        } catch (error) {
            dispatch({
                type: MessageActionTypes.SET_ORDER_BY_ORDER_TYPE_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Gets the messages for message center based on the box type. The following
 * box types are accepted - inbox, sent and deleted
 *
 * @param {String} boxType
 * @param {Object} lastEvaluatedKey
 * @param {Number} pageSize
 * @param {String} orderBy
 * @param {String} orderType
 */

export const getMessageCenterMessages = (
    box = MessageActionTypes.MESSAGE_CENTER_INBOX_VAL,
    LastEvaluatedKey = null,
    pageSize = -1,
    orderBy = MessageActionTypes.MESSAGE_CENTER_DATE_VAL,
    orderType = MessageActionTypes.MESSAGE_CENTER_DESCENDING_VAL,
    token,
    companyNumber = '591',
) => {
    return dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.GET_MC_MESSAGES_START,
                payload: { isLoading: true },
            });
            const env = '';
            const payload = {
                box,
                pageSize,
                orderBy,
                orderType,
                lastEvaluatedKey: {
                    LastEvaluatedKey,
                },
                token,
                companyNumber,
            };
            makeApiRequest(
                MessageActionTypes.GET_MC_MESSAGES,
                payload,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: MessageActionTypes.GET_MC_MESSAGES_SUCCESS,
                            payload: {
                                messages: response.data.messages,
                                messageType: box,
                                unreadMessageCount: response.data.unreadMessageCount.count,
                                lastEvaluatedKey: response.data.lastEvaluatedKey,
                            },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: MessageActionTypes.GET_MC_MESSAGES_ERROR,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.GET_MC_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Marks a list of messages as read or unread. To convert a read message to unread pass (false).
 * To convert an unread message to read pass (true).
 *
 * @param {Array} MessageIds
 * @param {boolean} isRead
 */

export const setReadUnreadToMessages = payload => {
    return dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.READ_MC_MESSAGE_START,
                payload: { isLoading: true },
            });
            const env = '';
            makeApiRequest(
                MessageActionTypes.READ_MC_MESSAGES,
                payload,
                env,
                response => {
                    if (response.status === 200) {
                        dispatch({
                            type: MessageActionTypes.READ_MC_MESSAGE_SUCCESS,
                            payload: { isLoading: false },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: MessageActionTypes.READ_MC_MESSAGE_ERROR,
                        payload: { error, isLoading: false },
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.READ_MC_MESSAGE_ERROR,
                payload: error,
            });
        }
    };
};

/**
 * Gets the messages for dashboard message center widget. The following
 * box types are accepted - inbox, sent and deleted
 *
 * @param {String} authToken
 *
 *
 */

export const getDashboardWidgetMessages = () => {
    return dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_START,
                payload: { isLoading: true },
            });
            const env = '';
            const payLoad = '';
            makeApiRequest(
                MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES,
                payLoad,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_SUCCESS,
                            payload: {
                                messages: response.data.messages,
                                unreadMessageCount: response.data.unreadMessageCount,
                            },
                        });
                    } else {
                        dispatch({
                            type: MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_ERROR,
                            payload: response,
                        });
                    }
                },
                error => {
                    dispatch({
                        type: MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_ERROR,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_ERROR,
                payload: error,
            });
        }
    };
};


export const fileUploadWithProgressBarUpdate = (formData,index,options,successCallBack,catchCallBack) =>
{
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json['fistoken'];
    delete json['fismemberid'];
    delete json['vcmid'];
    delete json['Authorization'];
    delete json['x-api-key'];
    delete json['msrid'];

    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        "Authorization": LocalStorageHelper.getItem('token'),
        "Origin": envConfig.ENV_URL,
        //"Accept": 'application/json, text/plain',
        "X-VCM-API-VERSION":'2023-06-30'
    };
    axios.post(`${Config.API_BASE_URL.uploadSecureMessageFile}${Config.uploadSMCDocument}`,formData,
    options)
    .then((response) => 
    { 
        successCallBack(response,index);
       
    })
    .catch((error)=>{
        catchCallBack(true);
    })
}

export const removeUplodedDocument = payload => {

    return  dispatch => {
        try {
            dispatch({
                type: MessageActionTypes.REMOVE_MC_DOCUMENT_START,
                payload: { isLoading: true },
            });
            const env = '';
            makeApiRequest(
                MessageActionTypes.REMOVE_MC_DOCUMENT,
                payload,
                env,
                 (response => {
                    if (response.status === 200) {
                        dispatch({
                            type: MessageActionTypes.REMOVE_MC_DOCUMENT_SUCCESS,
                            payload: {
                            isLoading: false,
                            data:response.data },
                           // isRead: payload.data.isRead,
                        });
                    }
                }),
                error => {
                    dispatch({
                        type: MessageActionTypes.REMOVE_MC_DOCUMENT_ERROR,
                        payload: { error, isLoading: false },
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.REMOVE_MC_DOCUMENT_ERROR,
                payload: error,
            });
        }
    };
};

export const getUploadFileSession = payload => {

    return dispatch => {
        try {
            const env = '';
            makeApiRequest(
                MessageActionTypes.GET_UPLOAD_SESSION_START,
                payload,
                env,
                 (response => {
                    if (response.status === 200) {
                        dispatch({
                            type: MessageActionTypes.GET_UPLOAD_SESSION_SUCCESS,
                            payload: {
                            isLoading: false,
                            data:response.data },
                           // isRead: payload.data.isRead,
                        });
                    }
                    else
                    {
                        dispatch({
                            type: MessageActionTypes.GET_UPLOAD_SESSION_ERROR,
                            payload: { isLoading: false },
                        });
                    }
                }),
                error => {
                    dispatch({
                        type: MessageActionTypes.GET_UPLOAD_SESSION_ERROR,
                        payload: { error, isLoading: false },
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: MessageActionTypes.GET_UPLOAD_SESSION_ERROR,
                payload: error,
            });
        }
    };
};

export const resetFileDeletedState = () => (dispatch)=> {
    dispatch({
        type: MessageActionTypes.REMOVE_MC_MESSAGE_RESET,
    });
};

export const uploadFileStatus = (payload) => (dispatch)=> {
    dispatch({
        type: MessageActionTypes.UPLOAD_FILE_STATUS,
        payload:payload
    });
};

export const storeUploadedFileDocumentID = (payload) => (dispatch)=> {
    dispatch({
        type: MessageActionTypes.STORE_UPLOADED_FILE_DOCUMENT_ID,
        payload:payload
    });
};

export const resetDeletedFileData = () => (dispatch)=> {
    dispatch({
        type: MessageActionTypes.RESET_DELETED_FILE_INFO,
    });
};