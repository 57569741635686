import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions, localStateManagementActions} from '../../../../shared/Actions';
import VerifyEmail from './VerifyEmail';



export const mapStateToProps = (state /* , props */) => (
    {
        personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
        registerState: state.localStateManagementReducerData.registerState,
        loginState: state.loginData,
    }
  );

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyEmail));