import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rightArrowGreen from '../../../../assets/rightArrow_green.png';

const StyledCard = styled(Card)`
    background-color: '#FFFFFF'
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 0;
    outline: 1px solid #D2D2D2;
    border: 2px solid #fff;
    position: relative;
    border-radius: 0;
    :not(.specimenCard):hover,
    :not(.specimenCard):focus {
        outline:1px solid #3C5A77;
    }
    .leftCornerCard {
        width: 0;
        height: 0;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #D2D2D2;
        position: absolute;
        top: -2px;
        left: -2px;
    }
`;

const S = {
    cardstyle: {
        margin: '10px 0px  10px 0px',
        minHeight: 142,
        maxHeight: '10.142em',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        opacity: 1,
        cursor: 'pointer',
    },
    cardImgStyle: {
        width: '8%',
        paddingBottom: '75px',
        marginLeft: '20px',
    },
    cardtextstyle: {
        font: '500 14px/20px benton-sans',
        color: '#49494A',
        maxWidth: '80%',
        textAlign: 'left',
        letterSpacing: 0,
        opacity: 1,
        marginBottom: 0,
    },
    cardtitlestyle: {
        font: '800 16px/18px yorkten-slab-normal',
        color: '#486D90',
        paddingBottom: '14px',
        borderBottom: '1px solid #D2D2D2',
    },
    cardbodystyle: {
        padding: '28px',
    },
}
export default function GuestCard(props) {
    const { cardtitletext, cardtext,className,onClick,dataaccountid } = props
    return (
        <StyledCard style={S.cardstyle}  className={className} >
            <button type="button" onClick={onClick} id={dataaccountid} name={cardtitletext} tabIndex='-1'>
                <div className="leftCornerCard" />
                <Card.Img src={rightArrowGreen} alt="rightArrowGreen" style={S.cardImgStyle} />
                <Card.Body style={S.cardbodystyle}> 
                    <Card.Title style={S.cardtitlestyle} aria-describedby={`${cardtitletext} Description`} role="button" tabIndex="0" className="guestCardTitle"   >
                        {cardtitletext}
                    </Card.Title>
                    <Card.Text style={S.cardtextstyle} id={`${cardtitletext} Description`}>
                        {cardtext}
                    </Card.Text>
                </Card.Body>
            </button>
        </StyledCard>
    );
}

GuestCard.propTypes = {
    cardtitletext: PropTypes.string,
    cardtext: PropTypes.string,
    className: PropTypes.string,
    dataaccountid:PropTypes.string,
    onClick:PropTypes.func,
};
GuestCard.defaultProps = {
    cardtext: '',
    cardtitletext: '',
    className:'',
    dataaccountid:'',
    onClick:()=>{}
};
