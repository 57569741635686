const callStatus = {
    isError: false,
    isLoading: false,
    isSuccess: false,
};

const schedules = {
    'GENERAL_PHONE_SCHEDULE': {
        serviceId: 'service-hours-1',
        serviceDescription: 'Victory Capital Member Services',
        daysAvailable: 'Monday - Friday',
        isLoading: false,
        startTime: null,
        endTime: null,
        hoursAvailable: null,
        dashboardHoursAvailable: null,
        isFetch: false,
    },
    '403B_PHONE_SCHEDULE': {
        serviceId: 'service-hours-2',
        serviceDescription: '403(b) Support Service',
        daysAvailable: 'Monday - Friday',
        isLoading: false,
        hoursAvailable: null,
        dashboardHoursAvailable: null,
        isFetch: false,
    },
};

const initialState = {
    availabilityText: null,
    callStatus,
    chatWidgetStatus: null,
    error: null,
    isLiveChatAvailable: false,
    isLoggedIn: null,
    isPhoneSupportAvailable: false,
    isSpinning: false,
    liveChatWaitTime: '. . .',
    modalId: 'ss-modal',
    oOnQueueUsers: null,
    oWaiting: null,
    phoneQueueWaitTime: 'Retrieving...',
    phoneQueueWaitTimeFab: 'Retrieving...', // Prevents FAB from interfering with Dashboard
    queueCapacityThreshold: 2,
    queueName: null,
    queuesStatus: null,
    queueStatus: null,
    schedules,
    selectedQuery: null,
    showModal: true,
    showConfirmDialog: {
        whenLeavingLiveChat: false,
    },
    toggled: false,
};

// resetState is a list of state parameters that should be changed or reset
// when the resetFab() action is fired.  Please review how the function is
// used before adding a new value.  The values added do not have to be the
// same as those of initialState.
const resetState = {
    chatWidgetStatus: initialState.chatWidgetStatus,
    isSpinning: initialState.isSpinning,
    liveChatWaitTime: initialState.liveChatWaitTime,
    modalId: initialState.modalId,
    oOnQueueUsers: initialState.oOnQueueUsers,
    oWaiting: initialState.oWaiting,
    phoneQueueWaitTimeFab: initialState.phoneQueueWaitTimeFab,
    queueName: initialState.queueName,
    queuesStatus: initialState.queuesStatus,
    queueStatus: initialState.queueStatus,
    selectedQuery: initialState.selectedQuery,
    showConfirmDialog: {
        whenLeavingLiveChat: initialState.whenLeavingLiveChat,
    },
    showModal: initialState.showModal,
    toggled: initialState.toggled,
};

export { initialState, resetState };
