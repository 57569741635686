import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import WIcon from './WIcon';
import './stepper.css';

const StyledHR = styled.hr`
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: calc(156%) !important;
    width: calc(
      (624%-${(props) => props.length}*33) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    width: calc(141.5%) !important;
    width: calc(
      (566%-${(props) => props.length}*52) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (max-width: 1365px) and (min-width: 1200px) {
    width: calc(141.5%) !important;
    width: calc(
      (566%-${(props) => props.length}*51) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (min-width: 1366px) and (max-width: 1550px) {
    width: calc(135%) !important;
    width: calc(
      (540%-${(props) => props.length}*55) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (min-width: 1551px) {
    width: calc(175%) !important;
    width: calc(
      (700%-${(props) => props.length}*55) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (max-width: 767px) and (min-width: 616px) {
    width: calc(210%) !important;
    width: calc(
      (840%-${(props) => props.length}*31) / (${(props) => props.steps}-1)  !important
    );
  }
  @media only screen and (max-width: 615px) {
    width: calc(156%) !important;
    width: calc(
      (624%-${(props) => props.length}*31) / (${(props) => props.steps}-1)  !important
    );
  }
`;

const borderColor = 'rgba(112, 112, 112, 0.5)';

export const styles = {
  wizardPageSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  wizardGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor,
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
  },
  wizardPageTxt: {
    fontSize: 15,
    color: '#535353',
    textAlign: 'center',
  },
  wizardGridVisited: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor,
    borderWidth: 1,
    backgroundColor: '#58D460',
  },
  wizardGridVisitedTxt: {
    fontSize: 15,
    color: '#535353',
    textAlign: 'center',
  },

  wizardGridCurrent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor,
    borderWidth: 1,
    backgroundColor: '#CDDBFC',
  },
  wizardPageCurrentTxt: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#535353',
    textAlign: 'center',
  },

  wizardTitleTxt: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#535353',
    lineHeight: 22,
    marginHorizontal: 12,
  },
};

const WStepper = (props) => {
  /* ----------- Wizard grid rendering -------------------*/
  const { pages, currentPage, className } = props;
  const pagesArr = [];
  const totalPages = pages;
  let tempViewSkin = styles.wizardGrid;
  let stepMsg = '';
  let step = '';
  const currentStep = 'Current';
  const { length } = totalPages;
  const assignObj = (obj) => obj;
  let stepperColAlign = assignObj({});
  if (length <= 6) {
    stepperColAlign = assignObj({
      justifyContent: 'space-between',
    });
  } else if (length === 7) {
    stepperColAlign = assignObj({
      justifyContent: 'unset',
      flexWrap: 'nowrap',
    });
  } else {
    stepperColAlign = assignObj({ width: '100%', justifyContent: 'unset' });
  }

  let ariaCurrent = '';

  for (let i = 0; i < length; i += 1) {
    step = `Step ${i + 1}`;
    ariaCurrent = null;
    stepMsg = null;
    if (currentPage === i) {
      tempViewSkin = 'stepperDiv activepage';
      ariaCurrent = 'true';
    } else if (i < currentPage) {
      tempViewSkin = 'stepperDiv visitedpage';
      stepMsg = 'Completed';
    } else {
      tempViewSkin = 'stepperDiv tobevistedpage';
    }
    pagesArr.push(
      <li key={i} className="stepperCol" aria-current={ariaCurrent}>
        <div className="stepperList">
          <Col style={assignObj({ padding: 0 })}>
            <div className={tempViewSkin}>
              <div className="circleSpan" aria-hidden="true">
                {tempViewSkin.indexOf('tobevistedpage') === -1 ? (
                  <WIcon
                    className="stepperIcon"
                    icon={faCheck}
                    size="sm"
                    fontawesomestyle={assignObj({
                      height: 18,
                      width: 15,
                      margin: 0,
                    })}
                  />
                ) : (
                  assignObj(`${i + 1}`)
                )}
              </div>
              <span className="stepperText">
                {ariaCurrent && (
                  <span className="sr-only tabOnly">{currentStep}</span>
                )}
                <span className="sr-only">{step}</span>
                {assignObj(`${totalPages[parseInt(i, 10)]}`)}
                {stepMsg && <span className="sr-only">{stepMsg}</span>}
              </span>
              {i === length - 1 ? (
                ''
              ) : (
                // <hr style={assignObj({width:hrWidth})} />
                <StyledHR
                  length={length}
                  steps={length - 1}
                  aria-hidden="true"
                />
              )}
            </div>
          </Col>
        </div>
      </li>,
    );
  }
  return (
    <div className={className}>
      <div className="wizardWrapper">
        <div style={styles.wizardPageSection}>
          <Row style={stepperColAlign} className="stepperComp" id="stepperComp">
            <ul className="col stepperContainer">{pagesArr}</ul>
          </Row>
        </div>
      </div>
    </div>
  );
};

WStepper.propTypes = {
  currentPage: PropTypes.number,
  pages: PropTypes.instanceOf(Array),
  className: PropTypes.string,
};

WStepper.defaultProps = {
  currentPage: 5,
  pages: [],
  className: '',
};

export default React.memo(WStepper);
