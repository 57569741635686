import React from "react";
import PropTypes from "prop-types";
import editIcon from 'assets/EditPencil.svg';
import {
    maskEmail,
} from "utils";
import { FieldValue, PrefilledInput, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from '../constants';

const EmailInfoView = (props) => {
    const { primaryEmail, secondaryEmail, onManageClick, hideManage } = props;
    const maskedEmail = primaryEmail ? maskEmail(primaryEmail) : '';
    const maskedSecondaryEmail = secondaryEmail ? maskEmail(secondaryEmail) : '';
    return(
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.EMAIL_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_EMAIL}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='0.625'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.PRIMARY_EMAIL}
                    value={
                        <PrefilledInput
                            id="vcm-cms-primary-email"
                            value={checkValues(maskedEmail)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.SECONDARY_EMAIL}
                    value={
                        <PrefilledInput
                            id="vcm-cms-secondary-email"
                            value={checkValues(maskedSecondaryEmail)}
                        />
                    }
                    mb='1.75'
                    labelsm={3}
                    valuesm={5}
                />
            </>
        );
}

EmailInfoView.propTypes = {
    onManageClick: PropTypes.func,
    primaryEmail: PropTypes.string,
    secondaryEmail: PropTypes.string,
    hideManage: PropTypes.bool,
};

EmailInfoView.defaultProps = {
    onManageClick: () => {},
    primaryEmail: '',
    secondaryEmail: '',
    hideManage:false,
};

export default EmailInfoView;