import Box from '../Box';
import React from 'react';
import styled from 'styled-components';

const FlexContainer = styled(Box)`
    display: ${props => (props.inline ? 'inline-flex' : 'flex')};
    flex-flow: ${props => props.flexFlow || 'row nowrap'};
    flex-direction: ${props => props.flexDirection || 'row'};
    flex-wrap: ${props => props.flexWrap || 'nowrap'};
    justify-content: ${props => props.justifyContent || 'flex-start'};
    align-items: ${props => props.alignItems || 'stretch'};
    align-content: ${props => props.alignContent || 'stretch'};
`;

const Flex = React.forwardRef((props, ref) => {
    return <FlexContainer ref={ref} {...props} />;
});

export default Flex;
