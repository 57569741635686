import axios from 'axios';
import { getSessionValues } from 'shared/Helpers/Utils';
import Config from 'env/Config';
import { getFisTokenWithExpiry } from '../../common/Authenticate/session';
import { LocalStorageHelper } from 'utils/sessionHelper';

/* **************************\
Function: POST
Explanation:
Call post method from actions with params
in body the post request body should be sent from the actions
in env, the endpoint for the request should be sent
============================
\*************************** */
const successStatusCode = [200, 201, 202, 204];
export function post(action, payload, env, successCallback, errorCallback, config) {
    return axios
        .post(`${env}${action}`, payload, config)
        .then(response => {
            removeAPIVersionHeader()
            if (!successStatusCode.find(statusCode => statusCode === response.status)) {
                errorCallback(response);
            } else {
                successCallback(response);
            }
        })
        .catch(error => {
            removeAPIVersionHeader()
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 401 || error.response.status === 413) {
                    errorCallback(error.response);
                }
            }
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function postMultiPart(action, payload, env, successCallback, errorCallback) {
    return axios
        .post(`${env}${action}`, payload, {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            }
            errorCallback(error);
        });
}

export function postUrlEncoded(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
    const json = instance.defaults.headers.common;
    instance.defaults.headers.common = {
        ...json,
    };
    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['fistoken'];

    return instance
        .post(`${env}${action}`, payload, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        .then(response => successCallback(response))
        .catch(err => {
            if (err.response) {
                errorCallback(err.response);
            }
            errorCallback(err);
        });
}

/**
 * Axios post call with content-type as application/json
 */

export function postApplicationJson(action, payload, env, successCallback, errorCallback) {
    return axios
        .post(`${env}${action}`, payload, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

/**
 * Post with x-api-key
 */

export function postWithAPIKey(action, payload, env, successCallback, errorCallback) {
    return axios
        .post(`${env}${action}`, payload, {
            method: 'POST',
            // headers: {
            //     'x-api-key': Config.metaDataApiKey,
            // },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error && error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

/**
 * Post with x-api-key
 */

// Composite api with header
export function postCompositeApi(action, payload, env, successCallback, errorCallback, config) {
    removeAPIVersionHeader()
    return axios
        .post(`${env}${action}`, payload, {
            headers: {
                "Content-Type": "application/json"}
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error && error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

//

export function postWithXAPIKey(action, payload, env, successCallback, errorCallback, xApiKey) {
    return axios
        .post(`${env}${action}`, payload, {
            method: 'POST',
            headers: {
                'x-api-key': xApiKey,
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(err => {
            if (err && err.response) {
                errorCallback(err.response);
            } else {
                errorCallback(err);
            }
        });
}
/* **************************\
Function: PUT
Explanation:
Call PUT method from actions with params
in body the post request body should be sent from the actions
in endUrl, the endpoint for the request should be sent
============================
\*************************** */

export function put(endUrl, payload, env, successCallback, errorCallback) {
    return axios
        .put(`${env}${endUrl}`, payload)
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            }
            errorCallback(error);
        });
}

/* **************************\
Function: get
Explanation:
Call get method from actions with params
in endUrl, the endpoint for the request should be sent
============================
\*************************** */
// .get(`${Config[`${env}`]}${endUrl}${payload}`)
// .get(`${env}${endUrl}${payload}`)
export function get(endUrl, payload, env, successCallback, errorCallback) {
    // removeAPIVersionHeader()
    const url = payload ? `${env}${endUrl}${payload}` : `${env}${endUrl}`;

    return axios
        .get(url)
        .then(response => {  
            removeAPIVersionHeader()
            successCallback(response)})
        .catch(error => {
            removeAPIVersionHeader()
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}
/*
 **
 * GET with x-api-key
 */

export function getWithAPIKey(endUrl, payload, env, successCallback, errorCallback, options = {}) {
    const url = payload ? `${env}${endUrl}${payload}` : `${env}${endUrl}`;
    return axios
        .get(url, options)
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error && error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

/* **************************\
Function: remove
Explanation:
Call remove method from actions with params
in endUrl, the endpoint for the request should be sent
============================
\*************************** */

export function remove(action, payload = {}, env, successCallback, errorCallback) {
    return axios
        .delete(`${env}${action}`, { data: payload })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                return errorCallback(error.response);
            }
            return error;
        });
}

/* **************************\
Function: setAuthHeaders
Explanation:
call this method from anywhere to set the authtoken
as header in the API calls
============================
\*************************** */

export function setAuthHeaders(jwttoken, xApiKey = Config.devApiKey) {
    if (jwttoken === '') {
        const json = axios.defaults.headers.common;
        delete json.fistoken;
        axios.defaults.headers.common = {
            ...json,
            'x-api-key': xApiKey,
        };
    } else {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json.fistoken;

        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: jwttoken,
            'x-api-key': xApiKey,
        };
    }
}

/* **************************\
Function: setAuthHeadersGuid
Explanation:
call this method from anywhere to set the authtoken
as header in the API calls
============================
\*************************** */

export function setAuthHeadersGuid(guid) {
    const json = axios.defaults.headers.common;
    delete json.fistoken;
    axios.defaults.headers.common = {
        ...json,
        guid,
        'x-api-key': Config.devApiKey,
    };
}

export function setAuthHeadersWithOutGuid() {
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.fistoken;

    axios.defaults.headers.common = {
        ...json,
        'x-api-key': Config.devApiKey,
    };
}

export async function getDocument(url, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    delete instance.defaults.headers.common['fistoken'];
    delete instance.defaults.headers.common['fismemberid'];
    delete instance.defaults.headers.common['vcmid'];
    delete instance.defaults.headers.common['Authorization'];
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['msrid'];

    return instance
        .get(url, {
            headers: { 
                //'service-account': serviceAccount,
                 //Accept: 'image/png',
                 "TOKEN": LocalStorageHelper.getItem('token'),
             },
            responseType: 'blob',
        })
        .then(response => {
            const blob = new Blob([response.data], { type: response.headers['content-type'].split(';')[0] });
            response.data = blob;
            successCallback(response);
            removeAPIVersionHeader();
        })
        .catch(error => {
            removeAPIVersionHeader();
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

/* **************************\
Function: setHeadersForDocumentAPI
Explanation:
call this method from anywhere to include service-account
field in header required and search for documents
============================ */

export function searchDocument(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    // const token = payload.authToken;
    delete instance.defaults.headers.common['fismemberid'];
    delete instance.defaults.headers.common['vcmid'];

    return instance
        .post(`${env}${action}`, payload, {
            method: 'POST'
        })
        .then(response => 
            {
                removeXVCMAuthorizationtHeader();
                removeAPIVersionHeader();
                successCallback(response)
            })
        .catch(error => {
            removeXVCMAuthorizationtHeader();
            removeAPIVersionHeader();
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}


export function getDocumentId(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    const msrInfo = getSessionValues()
    delete instance.defaults.headers.common['fismemberid'];
    delete instance.defaults.headers.common['vcmid'];
    delete instance.defaults.headers.common['x-api-key'];
    const token = payload.tokenType == 'accessToken' ? msrInfo.accesstoken : LocalStorageHelper.getItem('token')
    let payloadInfo = payload
    if(payloadInfo?.tokenType){
        delete payloadInfo.tokenType
    }
    return instance
        .post(`${env}${action}`,  payloadInfo, {
            method: 'POST',
            headers: { 
                Authorization: token,
                 "msrid": '1234'
             }
        },
        
         )
       
        .then(response => 
            {
                removeXVCMAuthorizationtHeader();
                removeAPIVersionHeader();
                successCallback(response)
            })
        .catch(error => {
            removeXVCMAuthorizationtHeader();
            removeAPIVersionHeader();
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}




export function getDocumentListId(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    //const token = payload.authToken;
    const msrInfo = getSessionValues()
    delete instance.defaults.headers.common['fismemberid'];
    delete instance.defaults.headers.common['vcmid'];
    delete instance.defaults.headers.common['x-api-key'];
         const token = payload.tokenType == 'accessToken' ? msrInfo.accesstoken : LocalStorageHelper.getItem('token')
        let payloadInfo = payload
        if(payloadInfo?.tokenType){
        delete payloadInfo.tokenType
        }
    return instance
        .get(`${env}${action}`, {
            method: 'GET',
            headers: {
                Authorization: token,
                msrid: '1234'
            },
            params: {
                ...payload.retrievePayload,
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}


/* **************************\
Function: setHeadersForDocumentAPI
Explanation:
call this method from anywhere to include service-account
field in header required and get/ retrieve the single Document
============================ */

// export function getSingleDocument(action, payload, env, successCallback, errorCallback, serviceAccount) {
//     const instance = axios.create({});
//     const token = payload.authToken;
//     delete instance.defaults.headers.common['x-api-key'];
//     return instance
//         .get(`${env}${action}`, {
//             method: 'GET',
//             headers: {
//                 Authorization: token,
//                 'service-account': serviceAccount,
//                 Accept: 'image/png', // It can be removed once API team confirms
//             },
//             responseType: 'blob',
//             params: {
//                 ...payload.retrievePayload,
//             },
//         })
//         .then(response => {
//             // debugger
//             // const filename=response.headers['content-disposition'].split("filename=")[1]
//             const blob = new Blob([response.data], { type: response.headers['content-type'].split(';')[0] });
//             response.data = blob;
//             successCallback(response);
//         })
//         .catch(error => {
//             if (error.response) {
//                 errorCallback(error.response);
//             } else {
//                 errorCallback(error);
//             }
//         });
// }

export function getAccessForms(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    const token = payload.authToken;
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['fistoken'];

    return instance
        .get(`${env}${action}`, {
            method: 'GET',
            headers: {
                Authorization: token,
                'service-account': serviceAccount,
                Accept: 'image/png', // It can be removed once API team confirms
            },
            params: {
                ...payload.retrievePayload,
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function getFormsByCategory(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    const { category, token } = payload;
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['fistoken'];
    return instance
        .get(`${env}${action}${category}`, {
            method: 'GET',
            headers: {
                Authorization: token,
                'service-account': serviceAccount,
                Accept: 'image/png', // It can be removed once API team confirms
            },
            params: {
                ...payload.retrievePayload,
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

/* ***************************************************************\
Function: getDashboardProfileData
Explanation:
call this method from anywhere to delete x-api-key
field in header required and make get request
===================================================================== */
export function getDashboardProfileData(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
   // delete instance.defaults.headers.common.msrid;
    delete instance.defaults.headers.common.fistoken;
    const { vcmId, authToken, fismemberid ,msrId} = payload;
    return instance
        .get(`${env}${action}`, {
            method: 'GET',
            headers: {
                Authorization: authToken,
                vcmid: vcmId,
                'x-api-key': Config.dashboardPortfolioSummaryKey,
                fismemberid,
                msrid: msrId
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function postDashboardProfileData(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
    const { authToken, vcmid } = payload;
    return instance
        .get(`${env}${action}`, {
            method: 'GET',
            headers: {
                Authorization: authToken,
                vcmid,
                'x-api-key': Config.msrAscensusKey,
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function getResponseWithoutXAPIKEY(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
    const token = payload.authToken;
    delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common['fistoken'];
    return instance
        .get(`${env}${action}`, {
            method: 'GET',
            headers: {
                Authorization: token,
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function getDashboardAccountType(action, payload, env, successCallback, errorCallback, xApiKey) {
    const instance = axios.create({});
    // delete instance.defaults.headers.common['x-api-key'];
    const token = payload.authToken; // localStorage.getItem('token');
    // const apiKey = '8OQxtiv5Pj4PcvNXUJgQ120STF1yBKZF4R2tDUrc'; // x-api-key;
    return instance
        .post(`${env}${action}`, payload.postData, {
            method: 'POST',
            headers: {
                Authorization: token,
                'x-api-key': xApiKey,
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function getWithoutToken(endUrl, payload, env, successCallback, errorCallback) {
    const url = payload ? `${env}${endUrl}${payload}` : `${env}${endUrl}`;
    const instance = axios.create({});
    const json = instance.defaults.headers.common;
    delete json.fistoken;
    instance.defaults.headers.common = {
        ...json,
        'x-api-key': Config.liveChatApiKey,
    };
    delete instance.defaults.headers.common.Authorization;
    return instance
        .get(url)
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

// MSR User Profile Information

export function getMSRUserProfileData(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
    delete instance.defaults.headers.common.msrid;
    delete instance.defaults.headers.common.fismemberid;
    delete instance.defaults.headers.common.fistoken;
    const {vcmid } = payload;
    return instance
        .post(`${env}${action}`, payload.user, {
            method: 'POST',
            headers: {
                Authorization: payload.token,
                vcmid,
                'x-api-key': Config.msrAPIKey,
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

// Basic Information

export function getMSRBasicInformation(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
   delete instance.defaults.headers.common['x-api-key'];
    delete instance.defaults.headers.common.msrid;
    delete instance.defaults.headers.common.fismemberid;
    delete instance.defaults.headers.common.fistoken;
    const { token, vcmid } = payload;
    return instance
        .post(`${env}${action}`, '', {
            method: 'POST',
            headers: {
                Authorization: token,
                vcmid,
                'x-api-key': Config.msrBasicProfileInfoKey,
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

// Member profile information 

export function getMemberProfileInformationsData(action, payload, env, successCallback, errorCallback) {
    const instance = axios.create({});
    // delete instance.defaults.headers.common['x-api-key'];
    // delete instance.defaults.headers.common.msrid;
    // delete instance.defaults.headers.common.fismemberid;
    // delete instance.defaults.headers.common.fistoken;
    let payloadData = null;

    if(payload?.payloadData)
    {
        payloadData=payload?.payloadData;
    }
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    return instance
        .post(`${env}${action}`,payloadData)
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

// Transaction History Positions

export function getTransactionHistory(endUrl, payload, env, successCallback, errorCallback) {
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.fistoken;
    // delete json.fismemberid;
    const url = `${env}${endUrl}`;
    const { token, vcmid, fismemberid, payload: postPayload } = payload;
    const { masterId: masterRegId, companyNumber } = postPayload;
    const cnum = companyNumber.toString();
    return axios
        .post(
            url,
            { masterRegId, companyNumber: cnum },
            {
                method: 'POST',
                headers: {
                    Authorization: token,
                    'x-api-key': Config.msrTransactionKey,
                    vcmid,
                    fismemberid,
                },
            },
        )
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}
export function setFisTokenAuthHeaers() {
    let authToken = getFisTokenWithExpiry();
    let msrid ='';
    let fistoken ='';
    if(authToken !=null && authToken !="")
    {
        msrid = authToken.userName;
        fistoken = authToken.token;
    }
    // let msrid = authToken.userName;
    // let fistoken = authToken.token;
    if (authToken != null) {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json.msrid;
        delete json.fistoken;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            msrid,
            fistoken,
        };
    }
}
// Set MSRAuth Headers

export function setMSRAuthHeaders(jwttoken, vcmid, msrid, xApiKey, fismemberid) {

    if(xApiKey && msrid && fismemberid == 'nofisMemberID')
    {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json.fistoken;
        delete json.fismemberid;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: jwttoken,
            vcmid,
            msrid,
            'x-api-key': xApiKey
        };
    }
    else if (xApiKey && msrid) {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json.fistoken;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: jwttoken,
            vcmid,
            msrid,
            'x-api-key': xApiKey,
            fismemberid,
        };
    } else if (xApiKey && !msrid) {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json.msrid;
        delete json.fismemberid;
        delete json.fistoken;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: jwttoken,
            vcmid,
            'x-api-key': xApiKey,
        };
    }
    else {
        const json = axios.defaults.headers.common;
        delete json.guid;
        delete json['x-api-key'];
        delete json.msrid;
        delete json.fismemberid;
        delete json.fistoken;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: jwttoken,
            vcmid,
        };
    }
}
// Remove all common headers except x-api-key for Look up calls
export function setMSRAPIKey(xApiKey) {
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.msrid;
    delete json.fismemberid;
    delete json.Authorization;
    delete json.vcmid;
    delete json.fistoken;
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        'x-api-key': xApiKey,
    };
}

// Set Auth Headers with out MSR

export function setMSRAuthSpecificHeaders(accesstoken, vcmid, xApikey, fismemberid) {
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.fistoken;
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: accesstoken,
        vcmid,
        'x-api-key': xApikey,
        fismemberid,
    };
}

export function addAPIVersionHeader(versionDate) {
    axios.defaults.headers.common = { ...axios.defaults.headers.common, 'X-VCM-API-VERSION': versionDate }
}

export function removeAPIVersionHeader() {
    const json = axios.defaults.headers.common;
    delete json['X-VCM-API-VERSION'];
}

export function setMSRProfileApiHeader(accesstoken, vcmid, xApikey, msrid)
{
    const json = axios.defaults.headers.common;
    delete json.fismemberid;
    delete json['Content-Type'];
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: accesstoken,
        vcmid,
        'x-api-key': xApikey,
        // 'Content-Type' : 'application/json',
        msrid:msrid
    };
}

export const clearSessionOnLogout = (authToken, lat, long) => {
    return axios.post(
        `${Config.API_BASE_URL.getDefaultAccountNickName}${Config.clearSession}`,
        {
            logoutLatitude: lat,
            logoutLongitude: long,
        },
        {
            // method: 'POST',
            headers: {
                Authorization: authToken,
                'x-api-key': Config.devApiKey,
            },
            // keepalive: true,
        },
    );
};


export const setMSRDocumentHeaders = (accesstoken, xApikey, msrid) =>
{
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.fistoken;
    delete json.vcmid;
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: accesstoken,
        'x-api-key': xApikey,
        msrid: (msrid != undefined && msrid != null && msrid != "") ? msrid :"1234",
        'service-account':Config.serviceAccount,
        'x-vcm-authorization': LocalStorageHelper.getItem('token')
    };
}

export const setMSRTransferDocumentHeaders = (accesstoken, xApikey, msrid) =>
{
    const json = axios.defaults.headers.common;
    delete json.guid;
    delete json.fistoken;
    delete json.vcmid;
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization:  LocalStorageHelper.getItem('token'),
        'x-api-key': xApikey,
        msrid: (msrid != undefined && msrid != null && msrid != "") ? msrid :"1234",
        'service-account':Config.serviceAccount,
    };
}
// function for assigning session value for payload object

export function setSessionToPlaylod(payload) {
    const params = getSessionValues();
    payload.msrid = params.msrid;
    payload.vcmid = params.vcmid;
    payload.fismemberid = params.fismemberid;
    payload.token = params.accesstoken;

    const apiKey = Config.msrTransactionKey;
    setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
}


export function setHeaderForGenericidLookup(token,xApiKey)
{
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: token,
        'x-api-key':xApiKey
    };
}

export function getApplicationConfig(action, payload, env, successCallback, errorCallback, xApiKey) {

    const instance = axios.create({});

    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common.msrid;
    delete instance.defaults.headers.common.vcmid;

    return instance
        .post(`${env}${action}`, payload, {
            method: 'POST',
            headers: {
                'x-api-key':xApiKey
            },
        })
        .then(response => successCallback(response))
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}

export function getFormsById(action, payload, env, successCallback, errorCallback, serviceAccount) {
    const instance = axios.create({});
    const token = payload.authToken;
    delete instance.defaults.headers.common['x-api-key'];
    return instance
        .post(`${env}${action}`, payload.searchPayload, {
            method: 'POST',
            headers: {
                Authorization: token,
                'service-account': serviceAccount,
            },
        })
        .then(response => {
            successCallback(response);
        })
        .catch(error => {
            if (error.response) {
                errorCallback(error.response);
            } else {
                errorCallback(error);
            }
        });
}


export function removeServicesAccountHeader()
{
    const json = axios.defaults.headers.common;
    delete json['service-account'];
}

export function removeXVCMAuthorizationtHeader()
{
    const json = axios.defaults.headers.common;
    delete json['x-vcm-authorization'];
}