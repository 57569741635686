/**
 * Personal Info Child Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to provide personal information for 529 flow
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { WStepper, WSpinner, WSaveModal, WErrorModal, CommonButtons } from '../../../common';
import SummaryCard from '../Verify/SummaryCard';
import Consts from './consts';

const PageWrapper = styled.div`
    background-color: #f9fbfc;
    min-height: 100vh;
    position: relative;
    padding: 60px 10px;
`;

const PageHeader = styled.h2`
    padding-left: 90px;
    padding-top: 30px;
    font-weight: Bold;
    font-family: Benton Sans;
    font-size: 26px/22px;
    color: #56565a;
`;

const ContentWrapper = styled.div`
    height: 60px;
    padding-right: 67px;
    padding-left: 100px;
    padding-top: 10px;
`;

const CardWrapper = styled.div`
    position: relative;
    padding: 60px 80px 0px 100px;
`;

const PersonalInfoContent = styled.div`
    margin: 50px 0 10px;
`;
const CardFooter = styled.p`
    font: 700 14px/17px Benton Sans;
    color: #333333de;
    padding-bottom: 50px;
    padding-top: 16px;
    padding-left: 26px;
`;
export default class PersonalInfoChild extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
            cancel: false,
            error: false,
            showSaveModal: false,
            loading: false,
            pageHeading: '529 Account',
        };
    }

    handleNext = () => {
        this.setState({ next: true });
    };

    handleSave = () => {
        this.setState({ showSaveModal: true });
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleClick = () => {};

    renderCard = ({ cardHeading, fields, cardId, linkText }) => (
        <CardWrapper>
            <SummaryCard
                cardHeading={cardHeading}
                linkText={linkText}
                cardId={cardId}
                fields={fields}
                handleEvent={this.handleEvent}
            />
            <div>
                <CardFooter>{Consts.considertext}</CardFooter>
            </div>
            <CommonButtons
                nextClick={this.handleNext}
                saveClick={this.handleSave}
                backClick={this.handleBack}
                cancelClick={this.handleCancel}
            />
        </CardWrapper>
    );

    renderPage = pageHeading => {
        const { error, showSaveModal } = this.state;
        return (
            <>
                <PageWrapper>
                    <PageHeader>{pageHeading}</PageHeader>
                    <ContentWrapper className="container">
                        <WStepper style={Consts.stepperStyles} currentPage={3} pages={Consts.pages} />
                    </ContentWrapper>
                    <WErrorModal show={error} message="Technical error" />
                    <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                    <PersonalInfoContent>
                        <div>{this.renderCard(Consts.card)}</div>
                    </PersonalInfoContent>
                </PageWrapper>
                <hr />
            </>
        );
    };

    render() {
        const { pageHeading, loading, error, showSaveModal, next, cancel, back } = this.state;
        return (
            <div className="MainContainer">
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                {error && <WErrorModal show={error} message="Technical error" />}
                {<WSpinner loading={loading} />}
                {!error && this.renderPage(pageHeading)}
                {next && <Redirect to="/beneficiaryInfoChild" />}
                {cancel && <Redirect to="/" />}
                {back && <Redirect to={Consts.redirect} />}
            </div>
        );
    }
}
