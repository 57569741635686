/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the beneficiary info details to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import { getFormattedSSN } from 'utils';
import Consts from './Consts';

export default function generateBeneficiaryInfoCard(masterLookupStateData, benInfo, index, type, isMarketNaoFlow=false) {
    let beneficiaryInfoCard = {};
    if (benInfo) {
        let benTypeData = '';
        let relationshipData = '';
        const mask = value => (value ? value.replace(/\d(?=\d{4})/g, 'X') : '');
        if (benInfo.beneficiaryType) {
            const benTypeList = masterLookupStateData['ben_type'.toString()].value;
            benTypeData = benTypeList.find(item => item.key === benInfo.beneficiaryType).value;
        }
        if (benInfo.relationToAccHolder) {
            const relList = masterLookupStateData['rel_to_custd'.toString()].value;
            relationshipData = relList.find(item => item.key === benInfo.relationToAccHolder).value;
        }

        if (benInfo.relationshipToCustodian) {
            const relList = masterLookupStateData['custd_relation'.toString()].value;
            relationshipData = relList.find(item => item.key === benInfo.relationshipToCustodian).value;
        }

        let beneficiarySSN =  mask(benInfo.beneficiarySSN) || Consts.None;
        if(isMarketNaoFlow){
            beneficiarySSN = getFormattedSSN(benInfo.beneficiarySSN)
        }

        console.log('isMarketNaoFlow',isMarketNaoFlow)
        
        // let heading = '';
        // if (benInfo.primaryBen) {
        //     heading = Consts.primary;
        // } else {
        //     heading = "Contingent";
        // }
        const distPercentage = benInfo.distributionPercentage || 100;
        beneficiaryInfoCard = {
            cardId: `${type}benInformation${index}`,
            linkText: Consts.edit,
            cardHeading: `${type} Beneficiary Information - ${index + 1}`,
            fields: [
                {
                    uniqueKey: 'beneficiaryType',
                    key: Consts.benType,
                    value: benTypeData,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'relationshipToAccountHolder',
                    key: Consts.relation,
                    value: relationshipData || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'distributionPercentage',
                    key: Consts.distributionPercentage,
                    value: `${parseFloat(distPercentage).toFixed(2)}%`,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'firstName',
                    key: benInfo.beneficiaryType === 'other_individuals' ? Consts.name : Consts.fname,
                    value: benInfo.firstName,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'middleName',
                    key: Consts.mname,
                    value: benInfo.middleName || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'lastName',
                    key: Consts.lname,
                    value: benInfo.lastName,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'ssn',
                    key: Consts.snn,
                    value: beneficiarySSN,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'bendob',
                    key: Consts.dob,
                    value: benInfo.beneficiaryDOB || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'emailAdd',
                    key: Consts.emAddress,
                    value: benInfo.emailAdd || Consts.None,
                    type: Consts.staticfield,
                },
            ],
        };

        if (benInfo.beneficiaryType === 'other_individuals') {
            beneficiaryInfoCard.fields = beneficiaryInfoCard.fields.filter(
                item =>
                    item.uniqueKey !== 'relationshipToAccountHolder' &&
                    item.uniqueKey !== 'middleName' &&
                    item.uniqueKey !== 'lastName' &&
                    item.uniqueKey !== 'bendob',
            );
        }
    }
    return beneficiaryInfoCard;
}
