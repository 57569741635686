import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import {loginActions} from '../../../shared/Actions';
import SetupSecurityQuestionsForm from './SetupSecurityQuestionsForm';



const mapStateToProps = (state /* , props */) => (
    {
      prefixData : state.loginData.prefix,
      suffixData : state.loginData.suffix,
    }
  );

const mapDispatchToProps = {
     ...loginActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupSecurityQuestionsForm));