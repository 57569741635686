const containerStyle = { backgroundColor: '#ffffff', position: 'relative' };

const bredCrumItmes = [{ name: 'Home', url: '/' },{name: 'Profiles & Preferences', url:'/profile'}, {name:'Account Messaging', url:'/accountmessaging'}];

const disableStyle = { display: 'none' };

const deviceManagement = 'Device Management';

const cardBody = `Push notifications are automatically set up on the device you are using every time you access the VCM mobile app.`

const cardLabel = 'Select the Devices you wish to remove.'

const checkLabel = 'iPhone XR'

const lastUsed = 'last used : 08/08/2019'

const activeName = 'Device Management'

export default {
    containerStyle,
    bredCrumItmes,
    disableStyle,
    deviceManagement,
    cardBody,
    cardLabel,
    checkLabel,
    lastUsed,
    activeName
}