export default {
    emailInfoHeading: 'Email Information',
    addtext: 'Add Another Email Address',
    switchErrorMsg: 'Please select atleast one Emailaddress  as Primary',
    emailErrorMsg: 'Please enter Primary Email Address',
    maxError: 'Only a maximum of 4 joint owners can be added.',
    JTIE: 'Joint Tenants in Entirety',
};

//helper text content PresonalInformationPage
export const HelpText_PhysicalAddress = "Why do we ask for your full legal address?\nA full legal address, including street name, is required to validate your identity and complete your request."
export const HelpText_FirstName = "Why can't I change some information?\nInformation that has been submitted after registration cannot be changed. This ensures that your information stays accurate and consistent."
export const HelpText_Suffix = "What is a suffix?\nA suffix follows a person's last name and provides information about the person such as a person's position in a family or a professional title."
export const HelpText_SSN = "Why is SSN required?\nFederal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. So, when you open an account, we will ask for your name, social security number and other information that will allow us to identify you. We then use a third-party authentication service provider for verification purposes."
export const residentOption=[
    
        { key: 'Resident Alien', value: 'Resident Alien' },
        { key: 'Non-resident Alien', value: 'Non-resident Alien' },
    ]
export const nonResidentError="To continue opening this account, we kindly ask for you to contact one of our Representatives at 1-800-235-8396."
