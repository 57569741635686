import MarketAccountTypeCard from './MarketAccountTypeCard';
import { Col } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';
import './ListSec.css';

const AccListSecWrapper = styled(Col)
`
    text-align: left;
    margin-bottom: 25px;
    :last-child {
        margin-bottom: 0;
    }
`;

const StyledH2 = styled.h2`
    @media (max-width: 767px) {
        margin-top: 44px;
        margin-bottom:30px;
        font-family: Benton-Sans-Medium;
        font-size:18px;
        line-height:24px;
        letter-spacing: 0;
    }
    margin: '0 0 30px';
    text-align: 'left';
    font-weight: '800';
    color: 'rgba(73, 73, 74, 1)';
    font-size:18px;

    font-family: yorkten-slab-normal;
`;

const SpecStyledH2 = styled.h2`
    @media (max-width: 767px) {
        display:none;
    }
    margin: '0 0 30px';
    text-align: 'left';
    font-weight: '800';
    color: 'rgba(73, 73, 74, 1)';
    font-size:18px;

    font-family: yorkten-slab-normal;
`;

const ListSec = memo(({ accTypeDetailsArr = [], accTypeSelected, handleCardClick, accTypeDetailsKey,profileInformationData }) =>
    accTypeDetailsArr.map((data, i) => {
        
       
        const list =
            data.subtypes &&
            data.subtypes.map(item => {
                const cardClassName = accTypeSelected === item.value ? 'selected' : '';
                const isFilteredAccountType = item.key === '403b' && accTypeDetailsKey === 'ira'
				const role=sessionStorage.getItem("role")
                const stateNameValidation=  role =="guest" || role =="Prospect" || profileInformationData?.profileInformation?.addressInformation==undefined || profileInformationData?.profileInformation?.addressInformation==null || profileInformationData?.profileInformation?.addressInformation=="" ? false : profileInformationData?.profileInformation?.addressInformation[0].state==="LA"||   profileInformationData?.profileInformation?.addressInformation[0].state==="PR"  
                return (!isFilteredAccountType && (
                    // item.key !== 'simple' &&
                    // item.key !== 'rollover' && (
                    
                        stateNameValidation?
                        item.key=="joint_brkg_acct"?null:<MarketAccountTypeCard key = { item.key }
                         item = { item }
                         onclick = { handleCardClick(item.value, item.key) }
                         cardClassName = { cardClassName } />  : <MarketAccountTypeCard key = { item.key }
                         item = { item }
                         onclick = { handleCardClick(item.value, item.key) }
                         cardClassName = { cardClassName }
                         />
                     ));
            });

        // show for all but spec_acct.  For spec_acct show only for NON-mobile
        let showH2;
        if (data.key === 'spec_acct') {
            showH2 = <SpecStyledH2 className="mb-4" acctsubtype = { data.key } > { data.value !== 'Others' && data.value } </SpecStyledH2>;
        } else {
            showH2 = <StyledH2 className="mb-4" acctsubtype = { data.key } > { data.value !== 'Others' && data.value } </StyledH2>;
        }
        return (
            <AccListSecWrapper xs = { 12 }
                key = { Number(i) }
                className = "accListAlign" >
                    {showH2}
                    <div style={{width:'100%'}}></div>
                    { list } 
            </AccListSecWrapper>
        );
    }),
);

ListSec.propTypes = {
    accTypeDetailsArr: PropTypes.instanceOf(Array),
    accTypeSelected: PropTypes.string,
    handleCardClick: PropTypes.func,
};

ListSec.defaultProps = {
    accTypeDetailsArr: [],
    accTypeSelected: '',
    handleCardClick: () => {},
};

export default ListSec;