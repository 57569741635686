import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AddressData = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: benton-sans;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;


const AccountAddress = props => {
    const { address } = props;
    
    let registration = <AddressData>{AccountAddress.NO_ADDRESS_FOUND}</AddressData>;
    if (address && Object.keys(address).length) {
        registration = <>
                <AddressData>{address.accountregistrationLine1}</AddressData>
                <AddressData>{address.accountregistrationLine2}</AddressData>
                <AddressData>{address.accountRegistrationline3}</AddressData>
                <AddressData>{address.accountRegistrationline4}</AddressData>
                <AddressData>{address.accountRegistrationline5}</AddressData>
                <AddressData>{address.accountRegistrationline6}</AddressData>
            </>;
    }
    return registration;
};
AccountAddress.NO_ADDRESS_FOUND = 'No adress was provided for this account';

AccountAddress.propTypes = {
    address: PropTypes.instanceOf(Object).isRequired,
};

AccountAddress.defaultProps = {
    address: {},
};

export default AccountAddress;