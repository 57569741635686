/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component returns the selected funds list in congrats page
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import consts from './constants';
import './confirmAccountOpening.css';

const SelectedFundTableDisplayComponent = props => {
    const { selectedFundsList, NAOResponse } = props;
    return (
        <Table className="beneDetailsTable">
            <caption className="sr-only">{consts.seletedFundTableCaption}</caption>
            <thead>
                <tr>
                    <th scope="col">{consts.fundCode}</th>
                    <th scope="col">{consts.fundName}</th>
                    <th scope="col">{consts.fundType}</th>
                    <th scope="col">{consts.fundNumber}</th>
                </tr>
            </thead>
            <tbody>
                {selectedFundsList &&
                    selectedFundsList.map(item => {
                        return (
                            <React.Fragment key={item.fundNumber}>
                                <tr>
                                    <td>{item.symbol}</td>
                                    <td>{item.fundName}</td>
                                    <td>{item.fundType}</td>
                                    <td>
                                        {
                                            NAOResponse.filter(fund => fund.fundNumber === item.fundNumber)[0]
                                                .accountNumber
                                        }
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
            </tbody>
        </Table>
    );
};

SelectedFundTableDisplayComponent.propTypes = {
    selectedFundsList: PropTypes.instanceOf(Object),
    NAOResponse: PropTypes.instanceOf(Array),
};

SelectedFundTableDisplayComponent.defaultProps = {
    selectedFundsList: [],
    NAOResponse: [],
};

export default memo(SelectedFundTableDisplayComponent);
