import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Container, CardDeck, Col } from 'react-bootstrap';
import { ReactComponent as PointOne } from 'assets/pointOne.svg';
import { ReactComponent as PointTwo } from 'assets/pointTwo.svg';
import {
    getAddressLookUpInfo,
    clearExistingActions,
    togglePhysicalAddress,
    toggleMailingAddress,
    callUSPSServices,
    addNewAddress,
} from 'shared/Actions/ProfileSettingsAction';
import { resetAddressInfo } from 'shared/Actions/AddressAction';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { getKeyfromValue, getMetadataValues } from 'modules/CustomerManagementModule/utils';
import {
    InstructionalPanel,
    AddressCardUSPSVerified,
    AddressCard,
    Separator,
    CheckOption,
    PrimaryBtn,
    Address,
    PageTitle,
    RadioGroup,
    FieldValue,
    CancelBtn,
} from 'modules/CustomerManagementModule/components';
import * as CONST from './constants';

const FlexDiv = styled.div`
    display: flex;
    align-items: ${props => props.alignItems || 'stretch '};
    margin-bottom: ${props => props.mB || 0}px;
    margin-top: ${props => props.mT || 0}px;
    justify-content: ${props => props.justifyContent || 'flex-start'};
`;

const FlexWithInnerBtnStyling = styled(FlexDiv)`
    && {
        button {
            width: 120px;
            height: 44px;
        }
        button:last-child {
            margin-left: 50px;
        }
    }

    @media (min-width: 576px) {
        padding-right: 80px;
    }
    @media (min-width: 768px) {
        padding-right: 30px;
    }
    @media (min-width: 992px) {
        padding-right: 50px;
    }
    @media (min-width: 1200px) {
        padding-right: 30px;
    }
`;

const StyledPara = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494a;
    opacity: 1;
    margin-left: 10px;
    margin-bottom: 0;
`;

const StyledAnchor = styled.a`
    text-align: left;
    font: Bold 16px/24px Benton Sans;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
`;

const USPSSuccessText = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.36px;
    color: #0d823c;
    opacity: 1;
`;

const USPSErrorText = styled(USPSSuccessText)`
    margin-top: ${props => (props.marginTop ? '30' : '0')}px;
    color: #e60000;
`;

const StyledCol = styled(Col)`
    padding-bottom: 30px;
`;

const StyledCardDeck = styled(CardDeck)`
    .card {
        margin-bottom: 0;
    }
    @media (min-width: 576px) {
        .card {
            margin-right: 0;
            margin-left: 0;
        }
    }
`;

const AddressSection = styled.div`
    padding: 40px;
    border: 1px solid #d2d2d2;

    &&& input {
        @media (min-width: 576px) {
            width: 255px;
        }
        @media (min-width: 992px) {
            width: 350px;
        }
        @media (min-width: 1200px) {
            width: 445px;
        }
    }
`;

const UspsVerifiedAddressCard = props => {
    const { addressLine2, city, state, zip, addressType } = props;
    const addrStateZip = `${state} - ${zip}`;
    return (
        <StyledCol xs={12} sm={6} lg={6}>
            <AddressCardUSPSVerified
                cardTitle={addressType}
                addressLine1={addressLine2}
                addressLine2={city}
                addressLine3={addrStateZip}
            />
        </StyledCol>
    );
};

UspsVerifiedAddressCard.propTypes = {
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    addressType: PropTypes.string,
};

UspsVerifiedAddressCard.defaultProps = {
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    addressType: '',
};

const PickListAddressCard = props => {
    const { isNewAddress, ...restProps } = props;
    return isNewAddress ? (
        <AddressCardUSPSVerified cardTitle={CONST.NEW_ADDRESS} toggle {...restProps} />
    ) : (
        <AddressCard {...restProps} />
    );
};

PickListAddressCard.propTypes = {
    isNewAddress: PropTypes.bool,
};

PickListAddressCard.defaultProps = {
    isNewAddress: false,
};

const onAddressNotFoundClick = ({ showAddAddressBlock, setShowAddAddressBlock }) => e => {
    e.preventDefault();
    if (!showAddAddressBlock) setShowAddAddressBlock(true);
};

const onMailingAddressChange = ({ index, dispatch }) => () => {
    dispatch(toggleMailingAddress(index));
};

const onPhysicalAddressChange = ({ index, dispatch }) => () => {
    dispatch(togglePhysicalAddress(index));
};

const callUSPS = ({ dispatch, mailingAddress, physicalAddress }) => () => {
    dispatch(callUSPSServices(mailingAddress, physicalAddress));
};

const saveNewAddress = ({ newAddress, addressType, dispatch, setShowAddAddressBlock }) => () => {
    const payload = {
        ...newAddress,
        addressType,
    };
    dispatch(addNewAddress(payload));
    dispatch(resetAddressInfo('address'));
    setShowAddAddressBlock(false);
};

const onAddNewAddressCancelClick = ({ dispatch, setShowAddAddressBlock }) => () => {
    dispatch(resetAddressInfo('address'));
    setShowAddAddressBlock(false);
};

const handleRadioChange = ({ masterLookupStateData, setAddressType }) => e => {
    const { target } = e;
    const { value = '' } = target || {};
    const addressTypeValue = getKeyfromValue(masterLookupStateData, 'address_type', value);
    setAddressType(addressTypeValue);
};

const getErrTxtAndMargin = (uspsVerifiedMailingAddress, uspsVerifiedPhysicalAddress) => {
    let errorTxt = '';
    let marginTop = false;
    if (!uspsVerifiedMailingAddress && !uspsVerifiedPhysicalAddress) {
        errorTxt = CONST.USPS_ERROR;
        marginTop = true;
    } else if (!uspsVerifiedMailingAddress) {
        errorTxt = CONST.USPS_ERROR_MAIL_ADDR;
    } else if (!uspsVerifiedPhysicalAddress) {
        errorTxt = CONST.USPS_ERROR_PHY_ADDR;
    }
    return {
        errorTxt,
        marginTop,
    };
};

const addressLookupToggle = ({ addressLookupInfo, setShowAddAddressBlock }) => () => {
    if (!addressLookupInfo.length) {
        setShowAddAddressBlock(true);
    } else {
        setShowAddAddressBlock(false);
    }
};

const clearFlags = ({ profileSucces, profileError, dispatch }) => () => {
    if (profileSucces || profileError) {
        dispatch(clearExistingActions());
    }
};

const getUSPSDetails = address => {
    const { uspsVerifiedAddress = null, isNewAddress = false } = address || {};
    return { uspsVerifiedAddress, isNewAddress };
};

const getAddressDetails = address => {
    const { addressLine2 = '', city = '', state = '', zip = '' } = address || {};
    return { addressLine2, city, state, zip };
};

const getIsBothAddrSet = (mailingAddress, physicalAddress) => {
    return !(!!mailingAddress && !!physicalAddress);
};

const getIsLookupMailAddr = (uspsVerifiedMailingAddress, isNewMailingAddr) => {
    return !!uspsVerifiedMailingAddress && !isNewMailingAddr;
};

const getIsLookupPhyAddr = (uspsVerifiedPhysicalAddress, isNewPhysicalAddr) => {
    return !!uspsVerifiedPhysicalAddress && !isNewPhysicalAddr;
};

const ManageAddressInfo = props => {
    const { onCheckOptionChange } = props;

    const profileInformationData = useSelector(({ profileInformationData: profileInfo = {} }) => profileInfo);

    const newAddressInfo = useSelector(({ address: addressData = {} }) => addressData);
    const { USPSVerifiedAddress: newAddress, isAddressReady } = newAddressInfo;

    const masterLookupStateData = useSelector(({ masterLookUpData: metaData = {} }) => metaData);
    const addressTypeData = getMetadataValues(masterLookupStateData, 'address_type');

    const {
        addressLookupInfo,
        mailingAddress,
        physicalAddress,
        isError: profileError,
        isSuccess: profileSucces,
        showUSPSVerifiedAddress,
    } = profileInformationData;

    const { uspsVerifiedAddress: uspsVerifiedMailingAddress, isNewAddress: isNewMailingAddr } = getUSPSDetails(
        mailingAddress,
    );
    const { uspsVerifiedAddress: uspsVerifiedPhysicalAddress, isNewAddress: isNewPhysicalAddr } = getUSPSDetails(
        physicalAddress,
    );

    const { addressLine2: mailAddr2, city: mailCity, state: mailState, zip: mailZip } = getAddressDetails(
        uspsVerifiedMailingAddress,
    );
    const { addressLine2: phyAddr2, city: phyCity, state: phyState, zip: phyZip } = getAddressDetails(
        uspsVerifiedPhysicalAddress,
    );

    const dispatch = useDispatch();

    const [showAddAddressBlock, setShowAddAddressBlock] = useState(false);
    const [addressType, setAddressType] = useState('');

    React.useEffect(() => {
        dispatch(getCompositeLookUpData(['address_type']));
        dispatch(getAddressLookUpInfo());
    }, [dispatch]);

    React.useEffect(addressLookupToggle({ addressLookupInfo, setShowAddAddressBlock }), [addressLookupInfo]);

    React.useEffect(clearFlags({ profileSucces, profileError, dispatch }), [dispatch, profileError, profileSucces]);

    const { errorTxt, marginTop } = getErrTxtAndMargin(uspsVerifiedMailingAddress, uspsVerifiedPhysicalAddress);

    const isNewAddressAvailable = addressLookupInfo.some(item => item.isNewAddress);

    const isBothAddrSet = getIsBothAddrSet(mailingAddress, physicalAddress);
    const isLookupMailAddr = getIsLookupMailAddr(uspsVerifiedMailingAddress, isNewMailingAddr);
    const isLookupPhyAddr = getIsLookupPhyAddr(uspsVerifiedPhysicalAddress, isNewPhysicalAddr);

    return (
        <Container>
            {!!addressLookupInfo.length && (
                <>
                    <FlexDiv mB={30} alignItems="center">
                        <PointOne aria-hidden="true" />
                        <StyledPara id="vcm-ftl-step-one">{CONST.STEP_ONE}</StyledPara>
                    </FlexDiv>
                    <StyledCardDeck>
                        {addressLookupInfo.map((addressDetails, index) => {
                            const {
                                addressLine2,
                                city,
                                state,
                                zip,
                                isPhysicalAddress = false,
                                isMailingAddress = false,
                                isNewAddress = false,
                            } = addressDetails;
                            const addressLine3 = `${state} - ${zip}`;
                            return (
                                <StyledCol xs={12} sm={6} lg={6} key={index.toString()}>
                                    <PickListAddressCard
                                        addressLine1={addressLine2}
                                        addressLine2={city}
                                        addressLine3={addressLine3}
                                        physicalAddress={isPhysicalAddress}
                                        mailingAddress={isMailingAddress}
                                        onMailingAddressChange={onMailingAddressChange({ index, dispatch })}
                                        onPhysicalAddressChange={onPhysicalAddressChange({ index, dispatch })}
                                        isNewAddress={isNewAddress}
                                        idKey={index}
                                    />
                                </StyledCol>
                            );
                        })}
                    </StyledCardDeck>
                    <FlexDiv alignItems="center" justifyContent="space-between">
                        <StyledAnchor
                            id="vcm-ftl-add-new-address"
                            href="#"
                            role="button"
                            onClick={onAddressNotFoundClick({ showAddAddressBlock, setShowAddAddressBlock })}
                        >
                            {CONST.NEW_ADDRESS_LINK_TEXT}
                        </StyledAnchor>
                        <PrimaryBtn
                            id="vcm-ftl-address-selection-done"
                            disabled={isBothAddrSet}
                            inlineBtn
                            onClick={callUSPS({ dispatch, mailingAddress, physicalAddress })}
                        >
                            {CONST.DONE}
                        </PrimaryBtn>
                    </FlexDiv>
                </>
            )}
            {showUSPSVerifiedAddress && (
                <>
                    {(isLookupMailAddr || isLookupPhyAddr) && (
                        <>
                            <Separator aria-hidden="true" mT={3.125} mB={3.125} />
                            <FlexDiv alignItems="center" mB={40}>
                                <PointTwo />
                                <StyledPara>{CONST.STEP_TWO}</StyledPara>
                            </FlexDiv>
                        </>
                    )}
                    <StyledCardDeck>
                        {isLookupMailAddr && (
                            <UspsVerifiedAddressCard
                                addressLine2={mailAddr2}
                                city={mailCity}
                                state={mailState}
                                zip={mailZip}
                                addressType="Mailing Address"
                            />
                        )}
                        {isLookupPhyAddr && (
                            <UspsVerifiedAddressCard
                                addressLine2={phyAddr2}
                                city={phyCity}
                                state={phyState}
                                zip={phyZip}
                                addressType="Physical Address"
                            />
                        )}
                    </StyledCardDeck>
                    {!!uspsVerifiedMailingAddress && !!uspsVerifiedPhysicalAddress ? (
                        <>
                            {(!isNewMailingAddr || !isNewPhysicalAddr) && (
                                <USPSSuccessText id="vcm-ftl-usps-success-text" aria-live="polite">{CONST.SUCCESS_MSG}</USPSSuccessText>
                            )}
                            <InstructionalPanel mb={2.5} mbt={1.875}>
                                {CONST.INSTRUCTION_MSG}
                            </InstructionalPanel>
                            <CheckOption id="vcm-ftl-apply-to-all-accounts" text={CONST.CHECK_BOX_TEXT} onChange={onCheckOptionChange} />
                        </>
                    ) : (
                        <USPSErrorText id="vcm-ftl-usps-error-text" marginTop={marginTop} aria-live="polite">
                            {errorTxt}
                        </USPSErrorText>
                    )}
                </>
            )}
            {showAddAddressBlock && (
                <div>
                    {!!addressLookupInfo.length && <Separator aria-hidden="true" mT={3.125} mB={1.875} />}
                    <PageTitle title={isNewAddressAvailable ? CONST.ADD_ANOTHER_ADDRESS : CONST.ADD_ADDRESS} />
                    <AddressSection>
                        <FieldValue
                            as="p"
                            id="vcm-cms-address-type"
                            label={CONST.ADDRESS_TYPE}
                            alignItems="stretch"
                            value={
                                <RadioGroup
                                    labelledBy="vcm-cms-address-type"
                                    vertical
                                    error={!addressType}
                                    errorTxt={CONST.ADDRESS_TYPE_ERROR}
                                    options={addressTypeData}
                                    initialValue={getKeyfromValue(masterLookupStateData, 'address_type', addressType)}
                                    onChange={handleRadioChange({ masterLookupStateData, setAddressType })}
                                    data-name={CONST.ADDRESS_TYPE}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <Address />
                        <FlexWithInnerBtnStyling mT={20} alignItems="center" justifyContent="flex-end">
                            <CancelBtn onClick={onAddNewAddressCancelClick({ dispatch, setShowAddAddressBlock })} />
                            <PrimaryBtn
                                disabled={!isAddressReady}
                                inlineBtn
                                onClick={saveNewAddress({ newAddress, addressType, dispatch, setShowAddAddressBlock })}
                            >
                                {CONST.SAVE}
                            </PrimaryBtn>
                        </FlexWithInnerBtnStyling>
                    </AddressSection>
                </div>
            )}
        </Container>
    );
};

ManageAddressInfo.propTypes = {
    onCheckOptionChange: PropTypes.func,
};

ManageAddressInfo.defaultProps = {
    onCheckOptionChange: () => {},
};

export default ManageAddressInfo;
