import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLastLocation } from 'react-router-last-location';
import { routeAndPageInfoMapping, ACCOUNT_SERVICES } from './Constants';
import {
    resetTrackDownLoadClick,
    resetFormFieldError,
    resetTrackFormAdandonment,
    resetApiResponse,
    resetUpdateAnalyticsPageDetails,
} from '../../shared/Actions/AnalyticsActions';
import envConfig from 'env/EnvConfig';

const AdobeAnalytics = props => {
    const { route, error } = props;
    const dispatch = useDispatch();
    const analytics = useSelector(state => state.analytics);

    const {
        analyzeDownLoadClick,
        analyzeFormFieldError,
        analyzeFormAdandonment,
        analyzeClick,
        apiResponse,
        updateAnalyticPageDetails,
        analyzecustom,
    } = analytics;
    const { location } = route || {};
    const { pathname = '' } = location || {};
    const filterPageInfor = routeAndPageInfoMapping.filter(info => info.key.toUpperCase() === pathname.toUpperCase());
    const pageInfo = filterPageInfor.length === 1 ? filterPageInfor[0] : {};
    const lastLocation = useLastLocation();

    const getStepName = module => {
        switch (module) {
            case 'Account Management': {
                return sessionStorage.getItem('accountSelected');
            }

            case 'Transactions': {
                return sessionStorage.getItem('stepname');
            }

            case 'User Management': {
                return sessionStorage.getItem('stepname');
            }

            case 'Dashboard': {
                return 'Dashboard Home'
            }

            case ACCOUNT_SERVICES: {
                return sessionStorage.getItem('stepname');
            }

            default: {
                return null;
            }
        }
    };

    // eslint-disable-next-line
    const getPageName = useCallback((page, isPrevPage = false) => {
        let step = getStepName(page?.module);
        step = page?.screen?.includes('FAQs') ? 'FAQ' : step
        const stepStr = step ? `|${step}` : '';
        return page ? `${page?.module}${stepStr}|${page?.screen}` : '';
    });

    const getSectionName = useCallback(page => {
        /* const step = getStepName(page?.module);
        let step = getStepName(page?.module);
        step = page?.screen.includes('FAQs') ? 'FAQ' : step
        const stepStr = step ? `${step}` : '';
        return page ? `${stepStr}` : ''; */
        let sectionName = '';
        if (page?.module === 'Customer Management') {
            sectionName = `Profile`;
        } else {
            let step = getStepName(page?.module);
            step = page?.screen?.includes('FAQs') ? 'FAQ' : step
            const stepStr = step ? `${step}` : '';
            sectionName = page ? `${stepStr}` : '';
        }
        return sectionName;
    });

    // const getSectionName = () => {
    //     const stepName = getStepName();
    //     return stepName ? `| ${stepName} | ${pageInfo?.screen}` : pageInfo?.screen;
    // };

    const getPreviousPageDetails = useCallback(() => {
        const { pathname: path = '' } = lastLocation || {};
        const prevPage = routeAndPageInfoMapping.filter(info => info.key.toUpperCase() === path.toUpperCase());
        return prevPage.length > 0 ? prevPage[0] : null;
    }, [lastLocation]);

    // eslint-disable-next-line
    const createAnalyticsData = useCallback(() => {
        let prePage = null;
        if (lastLocation) {
            prePage = getPreviousPageDetails();
        }
        let pageHier = '';
        document.querySelectorAll('.breadcrumb-item').forEach((e, i) => {
            pageHier += i > 0 ? `|${e.innerText}` : e.innerText;
        });

        if (isEmpty(pageHier)) {
            pageHier = `Home`
        }

        return (
            {
                version: '1.0',
                page: {
                    pageInfo: {
                        PageName: getPageName(pageInfo),
                        Section: getSectionName(pageInfo),
                        pageDocumenttitle: pageInfo?.screen,
                        pageURL: window.document.URL,
                        previousPagename: getPageName(prePage, true),
                        pagePercentageview: `${localStorage.getItem('previousPageScrollPercentage')}%`,//`${ranges && ranges.length ? ranges[0].max : 0}%`,
                        pageRef: prePage ? `${window.location.origin}${prePage?.key}` : '',
                        pageType: pageInfo?.module,
                        modulelog: localStorage.getItem('token') ? 'postlogin' : 'prelogin',
                        pageHier,
                        siteError: error ? JSON.stringify(error) : '',
                    },
                    search: {
                        internalSearchresults: '', // TODO: Implement search result
                        internalSearchterm: '', // TODO: Implement serach
                    },
                },
                user: {
                    userInfo: {
                        customerType: localStorage.getItem('token') ? 'customer' : 'prospect',
                        userID: localStorage.getItem('vcmId'),
                        loggedinStatustype: localStorage.getItem('role'),
                        userDetail: localStorage.getItem('firsttimeloginflag') === true ? 'First Time Signin User' : 'Existing User'
                    },
                },
            }
        );
    }, [lastLocation, error, getPageName, getPreviousPageDetails, getSectionName, pageInfo]);

    // eslint-disable-next-line
    const formAdandonment = useCallback(() => {
        if (window.digitalData) {
            window.digitalData.formaband = {
                ...analyzeFormAdandonment?.formaband,
                pageName: getPageName(pageInfo),
            };
        }
        // eslint-disable-next-line no-underscore-dangle
        window._satellite.track('postlogin formaband click');
        dispatch(resetTrackFormAdandonment());
    }, [analyzeFormAdandonment, dispatch, getPageName, pageInfo]);

    const linkClick = useCallback(() => {
        window.digitalData.link = {
            name: `${analyzeClick?.link}`,
            pageName: getPageName(pageInfo),
            error: apiResponse.isError ? apiResponse?.backendError?.toString() : '',
        };

        // eslint-disable-next-line no-underscore-dangle
        window._satellite.track('postlogin link click');
        dispatch(resetApiResponse());
    }, [analyzeClick, apiResponse, dispatch, getPageName, pageInfo]);

    // eslint-disable-next-line
    const updateDigitalDataPageDetails = () => {
        if (updateAnalyticPageDetails?.pageInfo) {
            window.digitalData.page.pageInfo = {
                ...window.digitalData.page.pageInfo,
                ...updateAnalyticPageDetails.pageInfo,
            };
            dispatch(resetUpdateAnalyticsPageDetails());
        }
    };

    useEffect(() => {
        const { pathname: path = '' } = lastLocation || {};
        if (path !== pathname) {
            const prevPagePercentage = localStorage.getItem('currentPageLoginPercentage')
            localStorage.setItem("previousPageScrollPercentage", prevPagePercentage);
            localStorage.setItem("currentPageLoginPercentage", 25);
        }
    }, [lastLocation])

    useEffect(() => {
        window.digitalData = createAnalyticsData();
        //console.log('@debug for click', window.digitalData)
        const adobeScript = document.querySelectorAll(`[src="${envConfig.ADOBE_ANALYTICS}"]`);

        if (analyzecustom && analyzecustom.accountOptions) {
            window.digitalData.page = {
                ...window.digitalData.page,
                accountoptions: { ...analyzecustom.accountOptions }
            }
        }

        if (adobeScript.length === 0) {
            const script = document.createElement('script');
            script.src = envConfig.ADOBE_ANALYTICS;
            script.async = true;
            document.body.appendChild(script);
        }

        if (analyzeDownLoadClick) {
            window.digitalData.link = {
                name: analyzeDownLoadClick.link,
                pageName: getPageName(pageInfo),
            };

            // eslint-disable-next-line no-underscore-dangle
            window._satellite.track('postlogin download click');
            dispatch(resetTrackDownLoadClick());
        }

        if (analyzeFormFieldError) {
            const formFields = document.querySelectorAll('.analytics-error-field');
            if (formFields.length > 0) {
                let formErrors = '';

                formFields.forEach((field, index) => {
                    formErrors += index > 0 ? `|${field.innerText}` : field.innerText;
                });

                window.digitalData.field = {
                    errors: formErrors,
                    pageName: getPageName(pageInfo),
                };

                // eslint-disable-next-line no-underscore-dangle
                window._satellite.track('postlogin field error click');
                dispatch(resetFormFieldError());
            }
        }

        if (analyzeFormAdandonment) {
            formAdandonment();
        }

        if (updateAnalyticPageDetails) {
            updateDigitalDataPageDetails();
        }
        if (apiResponse) {
            linkClick();
        }
    }, [
        createAnalyticsData,
        analyzeDownLoadClick,
        analyzeFormFieldError,
        analyzeFormAdandonment,
        updateAnalyticPageDetails,
        apiResponse,
        analyzeClick,
        getPageName,
        pageInfo,
        formAdandonment,
        linkClick,
        dispatch,
        updateDigitalDataPageDetails,
        analyzecustom,
        lastLocation,        
    ]);

    const handleScroll = useCallback(() => {
        // debugger
        // const { pathname: path = '' } = lastLocation || {};
        // if (path !== pathname) {
        //     setScroll(false)
        // }
        const isBrowser = typeof window !== `undefined`
        if (!isBrowser) return 0;
        const roundScroll = parseInt(((window.scrollY + window.innerHeight) / document.body.offsetHeight) * 100, 10);       
        let lastScrollTop = localStorage.getItem("currentPageLoginPercentage") || '';       
        lastScrollTop = lastScrollTop.replace('%', '')
        if (roundScroll > Number(lastScrollTop)) {
            if (roundScroll >= 0 && roundScroll <= 25) {
                localStorage.setItem("currentPageLoginPercentage", 25);
            } else if (roundScroll >= 26 && roundScroll <= 50) {
                localStorage.setItem("currentPageLoginPercentage", 50);
            } else if (roundScroll >= 51 && roundScroll <= 75) {
                localStorage.setItem("currentPageLoginPercentage", 75);
            } else {
                localStorage.setItem("currentPageLoginPercentage", 100);
            }
            // setScroll(roundScroll)
        }
    }, [lastLocation])

    window.addEventListener('scroll', () => {
        // window.digitalData = createAnalyticsData();
        handleScroll()
    });


    return <></>;
};

AdobeAnalytics.propTypes = {
    route: PropTypes.instanceOf(Object),
    error: PropTypes.instanceOf(Object),
};

AdobeAnalytics.defaultProps = {
    route: {},
    error: null,
};

export default AdobeAnalytics;
