import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { updateProfileRelationship, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import CONST from 'modules/CustomerManagementModule/ContactInformation/constants';
import RelationshipContactInfoComponent from './RelationshipContactInfoComponent';

export const mapStateToProps = state => {
    const { profileRelationshipData, masterLookUpData } = state;
    const { isError, isSuccess, isDeleteAPICalled } = profileRelationshipData;
    const notify = isError || isSuccess;
    let message = null;
    if (isSuccess) {
        message = isDeleteAPICalled ? CONST.CONTACT_INFO_DELETE : CONST.CONTACT_INFO_SUCCESS;
    }
    return {
        masterLookupStateData: masterLookUpData,
        profileRelationshipData,
        notify,
        message: {
            message,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    updateProfileRelationship,
    clearRelationActions,
    analyzeClickEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipContactInfoComponent));
