import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const getReadyStatus = (addressInformation, USPSVerifiedAddress) => {
    
    let ready = false;
    const {
        addressLine1= '',
        addressLine2= '',
        zip= '',
        city= '',
        state= '',
        addressLine2ErrorText= '',
        zipError= '',
    } = addressInformation;
    
    const {
        addressLine1:uspsAddr1= '',
        addressLine2:uspsAddr2= '',
        zip:uspsZip= '',
        city:uspsCity= '',
        state:uspsState= '',
    } = USPSVerifiedAddress;
    
    if( addressLine1 === uspsAddr1 &&
        addressLine2 === uspsAddr2 &&
        zip === uspsZip &&
        city === uspsCity &&
        state === uspsState &&
        !addressLine2ErrorText &&
        !zipError){
        ready = true;
    }
    
    return ready;
};

function addressReducer(addressType = 'address') {
    const initialState = {
        isLoading: false,
        isError: false,
        isSuccess: false,
        showUSPSDialog: false,
        USPSVerifiedAddress: {},
        addressInformation: {
            addressLine1: '',
            addressLine2: '',
            zip: '',
            city: '',
            state: '',
            addressLine2ErrorText: '',
            zipError: '',
        },
        callAddressAPI: false,
        previousZip: '',
        previousAddrLine1: '',
        previousAddrLine2: '',
        previousCity:'',
        previoutState:'',
        isAddressReady: false,
    };
  return (state = initialState, action) => {
    switch (action.type) {
        
        case `${ActionTypes.ADDRESS_REMOVE_EXISTING_VALUES}_${addressType}`:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
            };
        
        case `${ActionTypes.GET_ADDRESSFORMAT_LOADER}_${addressType}`:
        case `${ActionTypes.GET_STATECITY_LOADER}_${addressType}`: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case `${ActionTypes.GET_STATECITY_SUCCESS}_${addressType}`: {
            const { addressInformation } = state;
            const { response } = action;
            const { city, state: stateValue, zip} = response;
            const { addressLine2 } = addressInformation;
            addressInformation.city = city;
            addressInformation.state = stateValue;
            addressInformation.zip = zip;
            const previousZip = zip;
            const previousCity = city;
            const previoutState = stateValue;
            const callAddressAPI = addressLine2 && city && stateValue && zip;
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                addressInformation,
                callAddressAPI,
                previousZip,
                previousCity,
                previoutState
            };
        }
        
        case `${ActionTypes.GET_FORMATTED_ADDRESS_SUCCESS}_${addressType}`: {
            const { addressInformation } = state;
            const { response: formattedAddr } = action;
            const {
                addressLine2:formattedAddrLine2='',
                city:formattedCity='',
                state:formattedState='',
                zip:formattedZip='',
                description:addressError ='',
            } = formattedAddr || {};
            const showUSPSDialog = !addressError && !!formattedAddrLine2 && !!formattedCity && !!formattedState && !!formattedZip;
            const USPSVerifiedAddress = {...formattedAddr};
            const previousZip = formattedZip;
            const previousCity = formattedCity;
            const previoutState = formattedState;
            addressInformation.addressLine2ErrorText = '';
            addressInformation.zipError = '';
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                showUSPSDialog,
                callAddressAPI: false,
                USPSVerifiedAddress,
                previousZip,
                addressInformation,
                previousCity,
                previoutState
            };
        }
        
        case `${ActionTypes.GET_STATECITY_ERROR}_${addressType}`: {
            const { response } = action;
            const { description } = response;
            const { addressInformation } = state;
            addressInformation.zipError = description;
            return {
                ...state,
                isLoading: false,
                isError: true,
                addressInformation,
                previousZip: addressInformation.zip
            };
        }
        
        case `${ActionTypes.GET_FORMATTED_ADDRESS_ERROR}_${addressType}`: {
            const { response } = action;
            const { description ='' } = response || {};
            const { addressInformation } = state;
            addressInformation.addressLine2ErrorText = description;
            return {
                ...state,
                isLoading: false,
                isError: true,
                showUSPSDialog: false,
                addressInformation,
                previousZip: addressInformation.zip,
                callAddressAPI: false,
            };
        }
        
        case `${ActionTypes.SET_ADDRESS_DATA}_${addressType}`: {
            const { addressData = {} } = action;
            const { USPSVerifiedAddress } = state;
            const addressInformation = { addressLine1: '', addressLine2ErrorText: '', zipError: '', ...addressData};
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                showUSPSDialog: false,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_ADDRESS_LINE1}_${addressType}`: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.addressLine1 = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_ADDRESS_LINE2}_${addressType}`: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.addressLine2 = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_ZIPCODE}_${addressType}`: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.zip = action.data;
            if(!addressInformation.zip) {
                addressInformation.state = '';
                addressInformation.city = '';
            }
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_CITY}_${addressType}`: {
            const { addressInformation } = state;
            addressInformation.city = action.data;
            return{
                ...state,
                addressInformation,
            };
        }
        
        case `${ActionTypes.SET_STATE}_${addressType}`: {
            const { addressInformation } = state;
            addressInformation.state = action.data;
            return{
                ...state,
                addressInformation,
            };
        }
        
        case `${ActionTypes.SET_ADDRESS_LINE2_ERROR}_${addressType}`: {
            const { addressInformation, USPSVerifiedAddress} = state;
            addressInformation.addressLine2ErrorText = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_ZIPCODE_ERROR}_${addressType}`: {
            const { addressInformation, USPSVerifiedAddress } = state;
            addressInformation.zipError = action.data;
            const isAddressReady = getReadyStatus(addressInformation, USPSVerifiedAddress);
            return{
                ...state,
                addressInformation,
                isAddressReady,
            };
        }
        
        case `${ActionTypes.SET_CALL_ADDRESS_API}_${addressType}`: {
            const { addressInformation } = state;
            const { addressLine1, addressLine2, city, state: stateValue, zip} = addressInformation;
            const callAddressAPI = addressLine2 && city && stateValue && zip;
            return{
                ...state,
                callAddressAPI,
                previousAddrLine1: addressLine1,
                previousAddrLine2: addressLine2,
            };
        }
        
        case `${ActionTypes.RESET_ADDRESS_INFO}_${addressType}`: {
            return{
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                showUSPSDialog: false,
                USPSVerifiedAddress: {},
                addressInformation: {
                    addressLine1: '',
                    addressLine2: '',
                    zip: '',
                    city: '',
                    state: '',
                    addressLine2ErrorText: '',
                    zipError: '',
                },
                callAddressAPI: false,
                previousZip: '',
                previousAddrLine1: '',
                previousAddrLine2: '',
                isAddressReady: false,
            };
        }
        case `${ActionTypes.HIDE_USPS_DIALOG}_${addressType}`: {
            return{
                ...state,
                showUSPSDialog: false,
            };
        }
        case `${ActionTypes.SET_ADDRESS_READY_STATUS}_${addressType}`: {
            const isAddressReady = action.data;
            return{
                ...state,
                isAddressReady,
            };
        }
        
        default:
            return state;
    }
  };
}

export default addressReducer;