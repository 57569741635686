import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BackButton = ({
  link, backTo, className, onBackClick,
}) => (
  <Link to={link} className={`backLink ${className}`} aria-label={backTo}>
    <Button
      variant="outline-light"
      className="vcmBackButton"
      onClick={onBackClick}
      tabIndex="-1"
      role="presentation"
      aria-hidden
    >
      <span>{backTo}</span>
    </Button>
  </Link>
);
BackButton.propTypes = {
  link: PropTypes.string,
  backTo: PropTypes.string,
  className: PropTypes.string,
  onBackClick: PropTypes.func,
};

BackButton.defaultProps = {
  link: '',
  backTo: '',
  className: '',
  onBackClick: () => {},
};
export default BackButton;
