import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MemberMessage from '../../components/MemberMessage';
import WelcomeWrapper from '../../components/WelcomePage';

class LandingPage extends Component {
  componentDidMount() {
    const { location, getMemberInfo, rejectedInvalidInfo } = this.props;

    if (location.search && location.search.length) {
      const parseParam = location.search.split('?param=');
      let queryString = '';
      if (parseParam.length === 2) {
        [, queryString] = parseParam;
      }

      window.localStorage.setItem('token', queryString);

      const decodedString = atob(queryString).split(' | ');

      if (decodedString.length === 7) {
        const [
          accessKeyId,
          secretAccessKey,
          sessionToken,
          guid,
          hashedssn,
          MFAFlag,
          privateURL,
        ] = decodedString;

        const params = {
          accessKeyId,
          secretAccessKey,
          sessionToken,
          guid,
          hashedssn,
          MFAFlag,
          privateURL,
        };

        getMemberInfo(params);
      } else {
        rejectedInvalidInfo();
      }
    } else {
      rejectedInvalidInfo();
    }
  }

  render() {
    const {
      isMember, isError, user, history, isInvalid,
    } = this.props;

    const renderPage = (error, member, userObj) => {
      if (member) {
        return <MemberMessage page="memberfound" />;
      }
      if (error) {
        window.localStorage.removeItem('token');
        return <MemberMessage page="memberNotfound" />;
      }
      return <WelcomeWrapper user={userObj} history={history} />;
    };
    if (isInvalid) {
      window.localStorage.removeItem('token');
      return <MemberMessage page="invalidInfo" />;
    }

    return <>{renderPage(isError, isMember, user)}</>;
  }
}

LandingPage.propTypes = {
  location: PropTypes.instanceOf(Object),
  getMemberInfo: PropTypes.func,
  rejectedInvalidInfo: PropTypes.func,
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  isMember: PropTypes.bool,
  isError: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isInvalid: PropTypes.bool,
};

LandingPage.defaultProps = {
  location: {},
  getMemberInfo: () => {},
  rejectedInvalidInfo: () => {},
  user: {
    firstname: '',
    lastname: '',
  },
  isMember: false,
  isError: false,
  history: {},
  isInvalid: false,
};

export default LandingPage;
