import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {        
    offers:{    
        primaryMobile: {
            mobileId: "1234",
            mobile: "+1 2236523333",
            isMarketingSelected: true
        },     
        primaryEmailAddress: {
            emailId: "1234",
            email: "neilwins@hotmail.com",
            isMarketingSelected: false
        },
        primaryMailingAddress: {
            addressId: "1234",
            addressLine1: "154",
            addressLine2: "154 hawthrone ave",
            city: "Texas",
            state: "NY",
            zipcode: "11722",
            isMarketingSelected: false
        },   
        callPreference: {
            mobileId: "1234",
            timeZone: "central",
            timePreference: {
            morning: true,
            midDay: false,
            earlyEvening: false,
            night: true
            }
        },
        quietTimePreference: {
            mobileId: "1234",
            quietTime: true,
            timeZone: "central",
            startTime: "18:00",
            endTime: "22:00"
        } 
    }              
};

function marketingAndOffersReducer (state = initialState, action){
    // debugger
      switch(action.type){
          case ActionTypes.LOCAL_STATE: {
              return {
                  ...state,
                  ...action,
              };
          }              
          default:
              return state;
      }
  } 

export default marketingAndOffersReducer;