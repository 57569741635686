import styled from 'styled-components';

import { Col, Row, Card } from 'react-bootstrap';

const Styles = {
    AcHeader: styled.div`
        border-bottom: 2px solid #8bc105;
        padding: 0px 15px 10px 15px;
    `,
    AcActvity: styled.div`
        border-bottom: 1px solid #d2d2d2;
        padding: 15px;
    `,
    CardContainer: styled(Card)`
        border: none;
    `,
    AcServicesStyles: styled.h5`
    font-size: 16px;
    color: #56565A;
    margin: 0;
    padding: 0;
    font-weight: 800;
    line-height: 20px;
    & parent-style:  {
        margin: '15px 0px',
        padding: 0,
    }
    `,
    ServicesStyles: styled(Card.Title)`
    font-size: 16px;
    color: #56565A;
    margin: 0;
    padding: 0;
    font-weight: 800;
    line-height: 20px;
    & parent-style:  {
        margin: '15px 0px',
        padding: 0,
    }
    
    `,
    SpinnerContainer: styled.div`
        margin: auto;
    `,

    FormContainer: styled(Col)`
        padding-right: 1%;
    `,
    FormHeader: styled(Row)`
        //  font-weight: bold;
        border-bottom: 2px solid #8bc105;
        // width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        margin-top: -2px;
        letter-spacing: 0px;
        padding-bottom:10px;
        justify-content: space-between;
        text-align: right;
        // margin-left: 0.1%;
    `,
    FormData: styled(Row)`
        font-size: 15px;
        line-height: 16px;
        margin-top: -2px;
        letter-spacing: 0px;
        padding-top:15px;
        padding-bottom:15px;
        justify-content: space-between;
        text-align: right;
        word-wrap:break-all
    `,

    FormNavHeader: styled.a`
        display:inline-block;
        text-decoration: none;
        color:#464646
    `,

    HeaderTrCol: styled(Col)`
        width: 4%;
        border-top: none;
        margin-top: 2.5%;
    `,

    HeaderFCol: styled(Col)`
        width: 5%;
        border-top: none;
        margin-top: 2.5%;
        margin-left: -9%;
    `,
    HeaderCCol: styled(Col)`
        width: 13%;
        border-top: none;
        padding-left: 10%;
    `,
    HeaderTsCol: styled(Col)`
        width: 11%;
        border-top: none;
        padding-bottom: 1%;
        padding-left: 6%;
    `,

    HeaderNCol: styled(Col)`
        width: 13%;
        border-top: none;
        margin-top: 2.5%;
        padding-left: 6%;
    `,
    HeaderOCol: styled(Col)`
        width: 12%;
        border-top: none;
        padding-right: 3%;
        margin-top: 2.5%;
    `,

    Center: styled.div`
        position: relative;
        top: 50%;
        left: 50%;
    `,
    LoadMore: styled(Row)`
        display: flex;
        margin-left: auto;
        margin-right: auto;
    `,

    ConstantStyle: styled.div`
        font-weight: 600;
        padding-right: 5px;
        color: #004a98;
    `,
    ConstantStyleEdit: styled.div`
    font-weight: 600;
    color: #004a98;
    flex-direction: row;
    display:flex;
    padding-left:8%;
`,
    EditImage: styled.img`
    height:20px;
    padding-right:5%;
    `
};

export default Styles;
