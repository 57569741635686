/**
 * Dashboard Screen Component Styles and Constants
 ********************************
 *
 * @version 1.0.1
 * @author Numaan
 * @description The Highchart consists of  HIghchart component which takes the values in array and crates the graph accordingly.
 * @createdDate [16/03/2020]
 * ***************************************
 * @lastModifiedDate [16/06/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedChanges added tool tip 
 */

 import React, { Component } from 'react';
 import PropTypes from 'prop-types';
 import Highcharts from 'highcharts'; 
 
 class SimpleHighChart extends Component {
     constructor(props) {
         super(props);
         const {mapData}=this.props
         this.state = {
             actualSeriesData:mapData || []
         };
     }
 
     componentDidMount() {
       this.highChartsRender();      
       const elements = document.getElementsByClassName('highcharts-title');
       const requiredElement = elements[0];
       const cln = requiredElement.cloneNode(true);
       document.getElementById(`${this.props.chartTitleCloned}`).appendChild(cln);
     }   
 
     highChartsRender = () => {
         const {actualSeriesData}=this.state
         const {handleChartLoad}=this.props
         Highcharts.chart({
             chart: {
                 type: 'pie',
                 margin: [0, 0, 0, 0],
                  width: '80',
                  height: '80',
                
                 renderTo: this.props.holdingGroupComposition,
                 events:{
                     load:()=>{
                         handleChartLoad()
                     }
                 }
             },
             title: { text: '' },
             colors: this.props.colorsArray,
             series:[
                 {
                     type: 'pie',
                     data: actualSeriesData,
                     animation:false,
                     enableMouseTracking: false
                 },
             ],
             legend: {
                 enabled: false,
                 align: 'left',
                 layout: 'vertical',
                 verticalAlign: 'top',
             },
             accessibility: {
                 point: {
                     valueSuffix: '%'
                 }
             },
             plotOptions: {
                 pie: {
                     shadow: false,
                     borderWidth: 3,
                     borderColor: '#ffffff',
                     center: ['50%', '50%'],
                     size: '100%',
                     name: '',
                     innerSize: 30,
                     animation: false,
                    
                 },
             },
             credits: {
                 enabled: false,
             },
             tooltip: { enabled: false },
         });
     }
     
     render() {
        const {holdingGroupComposition,chartTitleCloned} = this.props;
         return (
            <>
                <div id={holdingGroupComposition} aria-hidden="true" className=""/>
                <div id={chartTitleCloned} aria-hidden="false" className="sr-only"/>
            </>
                 
                       
         );
     }
 }
 
 SimpleHighChart.propTypes = {
     mapData: PropTypes.instanceOf(Object),
     handleChartLoad:PropTypes.func,
 };
 
 SimpleHighChart.defaultProps = {
     mapData: [],
     handleChartLoad:()=>{},
 };
 
 export default SimpleHighChart;
 