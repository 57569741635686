import React, { Component } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import routeConstants from 'routeConstants';
import { decrypt } from 'utils';
import { isEmpty } from 'lodash';
import { ConfirmationMessage } from 'modules/CustomerManagementModule/components';
import { WRadio, WMessageBar, WSpinner } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import MemberIDComponent from '../MemberValidation';
import { data, style, assignObj, consts, moreSignUpAtmts, idDoesNotAuth } from './MemberSignUpconstants';
import { MemberPages, ProspectPages } from '../MemberRegistrationConstants';
import classes from '../Input/Input.module.css';
import { moreSignUpAtmtsTitle } from '../MemberValidation/MemberValidationConstants';


class MemberSignUpComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpIvrSelect: consts.otpIvrSelectYes,
            notificationMsg: {},
            failure: false,
            redirect: false,
            logOutRoute: {},
            retryCnt: 0,
            isShowCongirm: false,
        };
    }

    componentDidMount(){
        const { directMemberID } = this.props;
        directMemberID('');
    }

    static getDerivedStateFromProps(props, state) {
        const { directMemberIDData, manageLocalState, location, setGuidToken, directMemberID, history, isLoading } = props;
        const { retryCnt } = state;
        const localState = {...state};
        if (location.previousState === routeConstants.memberDobValidation) {
            directMemberID('');
            return {
                redirect: true,
                logOutRoute: {
                pathname: routeConstants.memberSignUp,
                }
            };
        }
        if (isLoading) {
            localState.failure = false;
        }
        if (directMemberIDData?.errorCode === 0)  {
            directMemberID('');
            const { result } = directMemberIDData;
            setGuidToken(directMemberIDData.result[0].guid);
            let userFields = result[0];
            const { guid, firstname, lastname, primaryPhone, email, middlename, prefix, suffix, role, primaryPhoneCountryCode, isMobilePrimary, secondaryPhone, secondaryPhoneCountryCode, isMobileSecondary } = result[0];
            (async () => {
                if (!isEmpty(userFields.memberid)){
                    const memberid = await decrypt(userFields.memberid);
                    userFields = {...userFields, memberid};
                } else if (!isEmpty(userFields.vcmid)){
                    const vcmid = await decrypt(userFields.vcmid);
                    userFields = {...userFields, vcmid};
                }
            let localData = {
                personalDetailsState: { uuid: guid, emailAddress: email, firstName: firstname, lastName: lastname, primaryPhone, middleName: middlename, prefix, suffix },
                otpDetails: {
                    primaryNumber: primaryPhone,
                    emailAddress: email,
                    primaryNumberCountryCode: primaryPhoneCountryCode,
                    isMobilePrimary,
                    secondaryNumber: secondaryPhone,
                    secondaryNumberCountryCode: secondaryPhoneCountryCode,
                    isMobileSecondary,
                    phoneType: isMobilePrimary ? 'mobile' : 'work',
                    onlineId: guid,
                    functionalityFlag : "memberSignUpFlow",
                    backLink: routeConstants.userDetails,
                    verifyNextLink: routeConstants.verifyOtp,
                    backLabel: 'User Details',
                    flow: "Verify Otp",
                    role,
                    dob: '',
                  },
                  userFields,
            };

            if (role === consts.member){
                localData = {
                    ...localData,
                    pageData: {
                        stepperPage:MemberPages,
                        isMemberSignUp: true ,
                        backLink: routeConstants.memberSignUp,
                    }
                }
            } else {
                localData = {
                    ...localData,
                    pageData: {
                        stepperPage:ProspectPages,
                        isProspectSignUp: true,
                        backLink: routeConstants.memberSignUp,
                    }
                }
            }

            manageLocalState(localData);
            localState.redirect = true;
            localState.retryCnt = 0;
            if (role === consts.member){
                history.push({
                    pathname: routeConstants.memberDobValidation,
                    message: { message: directMemberIDData.message },
                    failure: false,
                });
            }
            if (role === consts.prospect || role === consts.verifiedProspect){
                history.push({
                    pathname: routeConstants.termsAndConditions,
                    previousState: location.pathname,
                    failure: false,
                });
            }
            return localState;
        })();
           
        }
        if ( directMemberIDData?.errorCode && directMemberIDData?.errorCode !== 0) {
            if (directMemberIDData?.errorCode === 5008) {
                localState.retryCnt = retryCnt + 1;
            } else {
                localState.retryCnt = 0; 
            } 
            if (localState.retryCnt > 2){
                localState.isShowCongirm= true;
            } else {
                localState.notificationMsg = { message: directMemberIDData.result };
                localState.failure= true;
            }
            directMemberID('');
        }
        return localState;
    }

    radioClick = e => {
        this.setState({ [e.target.name]: e.target.id });
        if (e.target.id === consts.otpIvrSelectNo) {
            const { history } = this.props;
            history.push(routeConstants.personalDetails);
        }
    };

    handleClose = () => {
        const { directMemberID } = this.props;
        this.setState( prevState => ({ notificationMsg: {}, failure: !prevState.failure}));
        directMemberID('');
    }

    confirmCancel = () => {
        this.setState({ notificationMsg: {message: idDoesNotAuth}, failure: true, isShowCongirm: false});
    }

    closeTransactionModal = () => {
        const { location } = this.props;
        this.setState({
            isShowCongirm: false,
            redirect: true,
            logOutRoute: {
                pathname: routeConstants.personalDetails,
                previousState: location.pathname,
                failure: false,
            }
        });
    }

    render() {
        const { isLoading } = this.props;
        const { otpIvrSelect, notificationMsg, failure, redirect, logOutRoute, isShowCongirm } = this.state;
        return (
            <div className="container-fluid">
                { isShowCongirm ? <ConfirmationMessage
                    modalTitle={moreSignUpAtmtsTitle}
                    modalBodyText={moreSignUpAtmts}
                    secondaryButtonText="No"
                    onPrimaryClick={this.closeTransactionModal}
                    onSecondaryClick={this.confirmCancel}
                    primaryButtonText="Yes"
                />: '' }

                {redirect && <Redirect to={logOutRoute} />}
                {failure && !isShowCongirm ? <WMessageBar text={notificationMsg} error={failure} onClose={this.handleClose}/> : null}
                <WSpinner loading={isLoading} />
                <div className="container custom-container">
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader
                                sideHeaderBlockStyle={assignObj({ width: '78.5%' })}
                                title={data.title}
                            />
                            <div className="custom-button-container" />
                        </Col>
                        <Col md lg xl>
                            <span className={`${classes.Label} verifyAuthPref1`} aria-hidden="true">{data.verifyLabel}</span>
                            <Form.Group
                                role="radiogroup"
                                style={style.formGroup}
                                aria-describedby="otpivrSelectError"
                                aria-labelledby="otpivrSelectLabel"
                            >
                                <span className="sr-only" id="otpivrSelectLabel">
                                    {data.verifyLabel}
                                </span>
                                <WRadio
                                    id="yes"
                                    value="Yes"
                                    radioFor="otpIvrSelect"
                                    selected={otpIvrSelect === consts.otpIvrSelectYes}
                                    onClick={this.radioClick}
                                    role="radio"
                                    radiotext={style.radioText}
                                    radiostyles={assignObj({minHeight:0})}
                                />
                                <WRadio
                                    id="no"
                                    value="No"
                                    radioFor="otpIvrSelect"
                                    onClick={this.radioClick}
                                    selected={otpIvrSelect === consts.otpIvrSelectNo}
                                    role="radio"
                                    radiotext={style.radioText}
                                    radiostyles={assignObj({minHeight:0})}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {otpIvrSelect === consts.otpIvrSelectYes && <MemberIDComponent />}
                </div>
            </div>
        );
    }
}

MemberSignUpComponent.propTypes = {
    directMemberIDData: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
    isLoading: PropTypes.bool,
    manageLocalState: PropTypes.func,
    directMemberID: PropTypes.func,
    setGuidToken: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }),
}

MemberSignUpComponent.defaultProps = {
    directMemberIDData: {},
    location: {},
    isLoading: false,
    manageLocalState: ()=>{},
    directMemberID: ()=>{},
    setGuidToken: ()=>{},
    history: {}
}


export default MemberSignUpComponent;
