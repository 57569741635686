/**
 * Filter Funds Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to display funds summary for each fund
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WIcon } from '../../../../common';
import styles from './styles';
import './FundSummaryComponent.css';
import leftArrowGreen from '../../../../assets/leftArrow_green.png';
import consts from './consts';
import * as ActionTypes from '../../../../shared/ReduxConstants/ServiceActionConstants';
import InnerHTML from 'dangerously-set-html-content';
import envConfig from 'env/EnvConfig';
import { generatePageUrl, hideDomElements, hidePostLoginFooterDisclosure, loadAEMHtmlData } from 'utils/AEMHTMLHelper';

export default class FundSummaryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: '',
            selectedFundDetails: {},
            isLoading: false,
            content: null,
            renderCount:null,
            firstRenderTime:3000,
            secondRenderTime:6000,
            errorStatus:false
        };
    }

    componentDidMount = async () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { accOpeningData, fundAccountData,history, transactionType } = this.props;
        let selectedFundDetails = null;
        if (Object.keys(accOpeningData[ActionTypes.GET_FUNDDETAILS]).length > 0) {
            let clickedFund = {};
            const accOpeningDatavalues = accOpeningData[ActionTypes.GET_FUNDDETAILS];
            if (fundAccountData.fundAccountList && fundAccountData.fundAccountList.length > 0) {
                clickedFund = fundAccountData.fundAccountList.find(
                    fund => Number(fund.fundNumber) === Number(accOpeningDatavalues.entity_id),
                );
            }
            selectedFundDetails = Object.assign(accOpeningDatavalues, clickedFund);
            this.setState({ selectedFundDetails });
            if (transactionType === 'exchange') {
                history.push({
                    pathname: `/exchange`,
                    fundCode: selectedFundDetails.entity_id ? selectedFundDetails.entity_id : 0,
                    fundSummaryComponent: true,
                });
            } else {
                history.push({
                    pathname: `/transactionPurchases`,
                    fundCode: selectedFundDetails.entity_id ? selectedFundDetails.entity_id : 0,
                    fundSummaryComponent: true,
                });
            }
        }

        const pageURL = generatePageUrl(selectedFundDetails);
        const aemData = await loadAEMHtmlData(`${envConfig.ENV_URL}/products/mutual-funds/mutual-funds-list/${pageURL}`);
        if(aemData?.apiStatus == 200)
        {
            this.setState({renderCount:1, content : aemData.result,isLoading : false})
        }
        else
        {
            this.setState({isLoading : false,errorStatus:true})
        }
        
    };

    componentDidUpdate()
    {
        if(document.getElementsByClassName("fund-buttons")[0] != undefined)
        {
            document.getElementsByClassName("fund-buttons")[0].style.display = "none"  
        }
    }

    componentWillUnmount()
    {
        hidePostLoginFooterDisclosure(false)
    }

    render() {
        const { onSwitchComponent,pageIndex } = this.props;
        if(this.state.content != null && this.state.renderCount == 1){

            // if the api returned the aem page and is loaded, hide all the tag here and style them accordingly
            setTimeout(()=>{
               hideDomElements()
               document.getElementById('aem-page-frame').style.visibility = "visible"
               // update renderCount so that its not run again after initial run
               this.setState({renderCount:null, isLoading: false})
               window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
                });
               hidePostLoginFooterDisclosure(true);
            },this.state.firstRenderTime)

            setTimeout(()=>{
                hideDomElements()
            },this.state.secondRenderTime)

        }

        return (
            <div>
                <div>
                    <div className="container">
                    <button
                            className="backToFundSelection"
                            onClick={onSwitchComponent(false, {}, pageIndex)}
                            type="button"
                            style={styles.headerStyle}
                            aria-label={consts.backTo}
                        >
                            <WIcon src={leftArrowGreen} fontawesomestyle={styles.iconImgStyle} />
                            {consts.backTo}
                    </button>
                    {this.state.errorStatus && 
                        <h3>Page Not Found</h3>
                    }
                    
                    <div id='aem-page-frame'>
                        <InnerHTML html={this.state.content} />
                    </div>
                    </div>
                    <hr className="endOfPageHr" />
                </div>
            </div>
        );
    }
}

FundSummaryComponent.propTypes = {
    onSwitchComponent: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    fundAccountData: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.string,
    transactionType: PropTypes.string,
};

FundSummaryComponent.defaultProps = {
    onSwitchComponent: () => {},
    accOpeningData: {},
    fundAccountData: {},
    history: {},
    pageIndex: '',
    transactionType: '',
};
