/**
 * Dividends and Capital Gains
 ********************************
 *
 * @version 1.0.0
 * @author Ramyakrishna K
 * @description Dividends and Capital Gains are displayed and managed here
 * @createdDate [18/12/2019]
 * ***************************************
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { CommonButtons, WBreadCrumb, Accordian, AccordianTitle, WMessageBar, WSpinner, WErrorModal } from 'common';
import constants from 'modules/AccountOptions/constant';
import {
    DIVIDENDS_INITIAL_PAGE,
    DIVIDENDS_EDIT_BUTTON,
    DIVIDENDS_EDIT_PAGE,
    DIVIDENTS_NAV_BUTTON_ID,
    ACCOUNT_SERVICE_TYPE_DIVIDENDS,
    ACCOUNT_FLOW_TYPE_DIVIDENDS,
    BREAD_CRUMB_SCREEN_DIVIDENDS
} from '../../../../utils/AdobeAnalytics/Constants';
import DividendsAccordionCard from '../DividendsAccordion';
import DividendsMain from '../DividendsMain';
import { getSessionValues } from 'shared/Helpers/Utils';
import routesBreadCrumb from 'utils/Constants/routesBreadcrumb';
import routeConstants from 'routeConstants';
import { createBreadCrumbItem } from 'utils';

const breadCrumbURL = [
    { url: '/accountholder', name: 'Home' },
    { url: './accountservices', name: 'Account Services' },
];
const Heading = styled.h1`
    display: inline-block;
    border-bottom: 0.125rem solid #8cbf26;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.875rem;
    color: #486d90;
    opacity: 1;
    padding-bottom: 0.625rem;
    margin-bottom: 2.5rem;
`;
const nextStyle = {
    display: 'none',
};
const backStyle = {
    display: 'none',
};
const cancelStyle = {
    display: 'none',
};

class DirectedDividends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayNotification: false,
            notificationMsg: '',
            isMenuOpen: false,
            openMenuId: null,
            dividendCard: false,
            selectedAcc: null,
            showSkip: false,
            isEditButtonClicked: false,
            skipDisableSubmit: true,
            showMenu: {},
            selectedAccIndexes: {},
            error: false,
            fundsFromSubmit: null,
            analitycsDigitalData: {
                accountOptions: {
                    accountservicetype: ACCOUNT_SERVICE_TYPE_DIVIDENDS,
                    accountflowtype: ACCOUNT_FLOW_TYPE_DIVIDENDS,
                    dividendsandpreferrencetype: '',
                    investmentplanamount: ''
                }
            }
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.clickBack = this.clickBack.bind(this);
        this.clickSubmit = this.clickSubmit.bind(this);
        this.disableSubmitMethod = this.disableSubmitMethod.bind(this);
        this.clickCancel = this.clickCancel.bind(this);
        this.applyAllClick = this.applyAllClick.bind(this);
        this.getFundsfromSubmit = this.getFundsfromSubmit.bind(this);
        this.breadCrumbItems = this.getBreadCrumbItems();
    }

    componentDidMount() {
        const { getAccountsList } = this.props;
        if (getAccountsList) {
            // const msrInfo = sessionStorage.getItem('msr_dts');
            const params = getSessionValues(); 
           
            const { accesstoken, vcmid, msrid, fismemberid } = params;
            const msrParams = {
                accesstoken,
                vcmid,
                msrid,
                fismemberid,
            };
            getAccountsList(msrParams);
        }

        window.addEventListener('click', this.handleOutsideClick, false);
        document.addEventListener('keydown', this.handleEscapeKey, false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillReceiveProps(nextProps) {
        const { selectedAccIndexes, selectedAcc, isEditButtonClicked } = this.state;
        const { currentFundsCard = [] } = selectedAcc || {};
        const { accTypeIndex, accIndex } = selectedAccIndexes;
        const { changeFundsOptions, isSuccessPostUpdateDividends, isErrorPostUpdateDividends, updatedFunds } = nextProps;

        if (isSuccessPostUpdateDividends) {
            const dividendValues = {
                1: "income",
                2: "reinvest"
            };
            const currentFundsCardClone = cloneDeep(currentFundsCard);
            currentFundsCardClone.forEach(elem => {
                const filteredResponse = updatedFunds.find(updatedFund => `${elem.fundAccountNumber}` === `${updatedFund.fundAccountNumber}`);
                if (filteredResponse) {
                    elem.dividend = dividendValues[filteredResponse.dividend];
                    elem.capitalGains = dividendValues[filteredResponse.capitalGains];
                }
            })
            changeFundsOptions({
                path: `dividendsInfo[${accTypeIndex}].accountDetails[${accIndex}].currentFundsCard`,
                data: currentFundsCardClone
            });
        }

        if ((isSuccessPostUpdateDividends || isErrorPostUpdateDividends) && !isEditButtonClicked) {
            this.setState(
                () => {
                    return {
                        dividendCard: false,
                        displayNotification: true,
                        error: isErrorPostUpdateDividends,
                        notificationMsg: {
                            message: isErrorPostUpdateDividends ? '' : `You have successfully submitted the Dividends and Capital Gains Preferences request.`,
                        },
                        showSkip: false,
                        isEditButtonClicked: true
                    };
                }, this.scrollToTop
            );
        }
    }

    componentWillUnmount() {
        const { resetUpdateFlag } = this.props;

        window.removeEventListener('click', this.handleOutsideClick, false);
        document.removeEventListener('keydown', this.handleEscapeKey, false);
        sessionStorage.removeItem(DIVIDENDS_EDIT_PAGE.STEP_KEY);

        resetUpdateFlag();
    }

    getBreadCrumbItems = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyState = {
            state: {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            },
        };
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: historyState,
        };
        const bradcrumbPaths = [routeConstants.accountholder];
        const breadCrumbItems = bradcrumbPaths.map(route => createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_DIVIDENDS));
        if (masterRegId) {
            breadCrumbItems.splice(1, 1, intermediateRoute);
        }
        return breadCrumbItems;
    };


    assignObj = obj => {
        return obj;
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    editClick = e => {
        e.stopPropagation();
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: DIVIDENDS_EDIT_BUTTON,
        });
        this.setState(
            () => {
                return {
                    dividendCard: true,
                    showSkip: true,
                    skipDisableSubmit: true,
                    isEditButtonClicked: true,
                };
            }, () => {
                this.closeOpenMenu();
                this.scrollToTop();
            }
        );
    };

    onMenuHandler = (acc, accTypeIndex, accIndex) => e => {
        const { accountsAvailable } = this.props;

        e.stopPropagation();
        this.setState(prevState => {
            const { isMenuOpen, openMenuId } = prevState;
            if (!accountsAvailable.length) {
                return;
            }
            const isSameIndex =
                isMenuOpen && openMenuId.accTypeIndex === accTypeIndex && openMenuId.accIndex === accIndex;
            return {
                showMenu: isMenuOpen && !isSameIndex ? {} : { accTypeIndex, accIndex },
                displayNotification: false,
                isMenuOpen: !(isMenuOpen && isSameIndex),
                selectedAcc:
                    isMenuOpen && isSameIndex ? null : accountsAvailable[accTypeIndex].accountDetails[accIndex],
                selectedAccIndexes: isMenuOpen && isSameIndex ? {} : { accTypeIndex, accIndex },
                openMenuId:
                    isMenuOpen && isSameIndex
                        ? null
                        : {
                            accTypeIndex,
                            accIndex,
                        },
            };
        });
    };

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
        });
    };

    handleEscapeKey = e => {
        const { isMenuOpen } = this.state;
        if (e.keyCode === 27 && isMenuOpen) {
            this.closeOpenMenu();
        }
    };

    closeOpenMenu = () => {
        const { accountsAvailable } = this.props;
        this.setState(prevState => {
            if (!accountsAvailable.length) {
                return;
            }
            return {
                showMenu: {},
                isMenuOpen: false,
                openMenuId: {},
                isEditButtonClicked: false,
            };
        });
    };

    applyAllClick = (funds) => {
        const { updateDividends } = this.props;
        let updatableFunds;
        if (funds && funds.length) {
            updatableFunds = funds.map(fund => ({
                'fundNumber': fund.fundNumber,
                'fundAccountNumber': fund.fundAccountNumber,
                'dividend': fund.dividend,
                'capitalGains': fund.capitalGains,
            }));
        };

        updateDividends(updatableFunds);
    }

    getFundsfromSubmit(funds) {
        this.setState(() => {
            return {
                fundsFromSubmit: funds
            }
        })
    }

    updateAnalyticsData = (fundId, optionSelected, optionType) => {
        this.setState((prevState) => ({
            analitycsDigitalData: {
                accountOptions: {
                    ...prevState.analitycsDigitalData.accountOptions,
                    dividendsandpreferrencetype:
                        `${prevState.analitycsDigitalData.accountOptions.dividendsandpreferrencetype}|${fundId}-${optionType}-${optionSelected}`
                }
            }
        }))
    }

    handleOutsideClick() {
        const { isMenuOpen } = this.state;
        if (isMenuOpen) {
            this.closeOpenMenu();
        }
    }

    clickBack() {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyObj = {};
        const backRoute = masterRegId ? routeConstants.accountholderTransactions : routeConstants.accountholder;
        historyObj.pathname = backRoute;
        if (masterRegId) {
            historyObj.state = {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    }

    clickCancel() {
        this.setState(
            () => {
                return {
                    dividendCard: false,
                    showSkip: false,
                };
            }, this.scrollToTop
        );
    }

    clickSubmit() {
        const { fundsFromSubmit } = this.state;
        const { updateDividends } = this.props;
        let updatableFunds;
        if (fundsFromSubmit.length) {
            updatableFunds = fundsFromSubmit.map(fund => ({
                'fundNumber': fund.fundNumber,
                'fundAccountNumber': fund.fundAccountNumber,
                'dividend': fund.dividend,
                'capitalGains': fund.capitalGains,
            }));
        };

        updateDividends(updatableFunds);
    }

    disableSubmitMethod(skip) {
        this.setState({
            skipDisableSubmit: skip,
        });
    }

    render() {
        const {
            dividendCard,
            selectedAcc,
            notificationMsg,
            displayNotification,
            showSkip,
            skipDisableSubmit,
            showMenu,
            selectedAccIndexes,
            error,
            analitycsDigitalData,
        } = this.state;
        if (dividendCard) {
            sessionStorage.setItem(DIVIDENDS_EDIT_PAGE.STEP_KEY, DIVIDENDS_EDIT_PAGE.STEP_NAME);
        } else {
            sessionStorage.setItem(DIVIDENDS_INITIAL_PAGE.STEP_KEY, DIVIDENDS_INITIAL_PAGE.STEP_NAME);
        }
        const { history, accountsAvailable, isSuccessGetAccountList,
            isErrorGetAccountList, isLoadingGetAccountList,
            isLoadingPostUpdateDividends, isErrorPostUpdateDividends,
            isSuccessPostUpdateDividends, analyzeFormAdandonment } = this.props;
        const navProps = {
            dataAnalyticId: DIVIDENTS_NAV_BUTTON_ID,
            analyzecustom: analitycsDigitalData
        };
        if (showSkip) {
            navProps.submitClick = this.clickSubmit;
            navProps.submit = 'true';
            navProps.disabled = skipDisableSubmit;
            navProps.backstyle = backStyle;
            navProps.arialabelnext = 'Submit changes to dividends preferences';
            navProps.iscancel = true;
            navProps.arialabelcancel = 'Cancel changes in dividends preferences'
            navProps.cancelClick = this.clickCancel;
        } else {
            navProps.backClick = this.clickBack;
            navProps.nextstyle = nextStyle;
            navProps.arialabelback = 'Back to account services page';
            navProps.cancelStyle = cancelStyle;
        }
        // not needed for day1
        // const instructions = {
        //     instructionsTitle : constants.dividends.instructionsTitle,
        //     instructionList : constants.dividends.instructionList,
        // };

        return (
            <div>
                {isLoadingGetAccountList && <WSpinner loading={isLoadingGetAccountList} />}
                {displayNotification && <WMessageBar onClose={this.onNotificationClose} text={notificationMsg} error={error} />}
                {isErrorGetAccountList && <WErrorModal show={isErrorGetAccountList} message="Technical error" modalClick={this.clickBack} />}
                <div className="container">
                    <WBreadCrumb
                        activeCrumb={routesBreadCrumb.get(routeConstants.dividends)}
                        breadCrumbItems={this.breadCrumbItems}
                    />
                    <Heading>{constants.dividends.pageHeading}</Heading>
                    {/* not needed for day1 */}
                    {/* {!dividendCard ? (<InstructionAIP {...instructions} /> ) : null} */}

                    {isSuccessGetAccountList && !accountsAvailable.length ? <div>{constants.dividends.noAccounts}</div> : null}
                    {isLoadingGetAccountList ? <div>{constants.dividends.loadingText}</div> : null}

                    {isSuccessGetAccountList ? <div>
                        {dividendCard
                            ? (<DividendsMain
                                selectedAccount={selectedAcc}
                                history={history}
                                skipDisableSubmit={skipDisableSubmit}
                                callBack={this.disableSubmitMethod}
                                selectedAccIndexes={selectedAccIndexes}
                                isLoadingPostUpdateDividends={isLoadingPostUpdateDividends}
                                isSuccessPostUpdateDividends={isSuccessPostUpdateDividends}
                                isErrorPostUpdateDividends={isErrorPostUpdateDividends}
                                applyAllClick={this.applyAllClick}
                                getFundsfromSubmit={this.getFundsfromSubmit}
                                analyzeFormAdandonment={analyzeFormAdandonment}
                                updateAnalyticsData={this.updateAnalyticsData}
                            />)
                            : (<div>
                                {accountsAvailable.map((item, accTypeIndex) => (
                                    <Accordian
                                        key={item.accountType}
                                        titleChild={<AccordianTitle accType={item.accountType} id={(item.accountType).replace(/\s/g, "")} accIndex={accTypeIndex} />}
                                        titleSeperator
                                        Id={(item.accountType+accTypeIndex).replace(/\s/g, "")}
                                    >
                                        <div>
                                            {item.accountDetails.map((eachAcc, accIndex) => (
                                                <DividendsAccordionCard
                                                    key={eachAcc.accNumber}
                                                    accName={eachAcc.accName}
                                                    accNumber={eachAcc.accNumber}
                                                    editClick={this.editClick}
                                                    onMenuHandler={this.onMenuHandler(eachAcc, accTypeIndex, accIndex)}
                                                    showMenu={showMenu}
                                                    selected={eachAcc.selected}
                                                    accTypeIndex={accTypeIndex}
                                                    accIndex={accIndex}
                                                />
                                            ))}
                                        </div>
                                    </Accordian>
                                ))}
                            </div>
                            )}
                        </div> : null }
                </div>
                <CommonButtons {...navProps} />
            </div>
        );
    }
}

DirectedDividends.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    accountsAvailable: PropTypes.instanceOf(Array),
    updatedFunds: PropTypes.instanceOf(Array),
    isLoadingGetAccountList: PropTypes.bool,
    isErrorGetAccountList: PropTypes.bool,
    isSuccessGetAccountList: PropTypes.bool,
    changeFundsOptions: PropTypes.func,
    getAccountsList: PropTypes.func,
    updateDividends: PropTypes.func,
    isLoadingPostUpdateDividends: PropTypes.bool,
    isSuccessPostUpdateDividends: PropTypes.bool,
    isErrorPostUpdateDividends: PropTypes.bool,
    analyzeClickEvent: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
    resetUpdateFlag: PropTypes.func,
};

DirectedDividends.defaultProps = {
    accountsAvailable: [],
    updatedFunds: [],
    isLoadingGetAccountList: true,
    isErrorGetAccountList: false,
    isSuccessGetAccountList: false,
    changeFundsOptions: () => { },
    getAccountsList: () => { },
    updateDividends: () => { },
    isLoadingPostUpdateDividends: false,
    isSuccessPostUpdateDividends: false,
    isErrorPostUpdateDividends: false,
    analyzeClickEvent: () => { },
    analyzeFormAdandonment: () => { },
    resetUpdateFlag: () => { },
}

export default DirectedDividends;
