import makeApiRequest from '../Network/ApiInterface';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import { linkResponseSuccess, linkResponseError } from './AnalyticsActions';
import {decryptValueAPI} from '../../utils/decryptMobile';
import decrypt from '../../utils/decrypt';
import store from '../Store';
import { maskAccountNumber } from 'utils/utils';
// OLD CODE NEED TO BE REMOVED
// ### START ###
const getDecryptedData = async data => {
    let platformIsMobile=false;
    try {
         platformIsMobile = typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
    } catch {
         platformIsMobile = false;
    }

    if(platformIsMobile){
        return decryptValueAPI(data,store.getState());
    }
    
        return decrypt(data);
    
};

const getDecryptedBankDetails = async bankDetails => {
    const decryptedBankDetails = bankDetails;

    const bankList = bankDetails && bankDetails.bankList;
    await Promise.all(
        bankList.map(async (bank, index) => {
            const bankAccNumber = bank.bankAccountNumber;
            const bankRoutingNum = bank.bankRoutingNumber;
            decryptedBankDetails.bankList[parseInt(index, 10)].bankAccEncNumber = bank.bankAccountNumber;
            decryptedBankDetails.bankList[parseInt(index, 10)].bankRoutEncNumber = bank.bankRoutingNumber;
            if (bankAccNumber) {
                const decryptedBankAccNumber = await getDecryptedData(bankAccNumber);
                decryptedBankDetails.bankList[parseInt(index, 10)].bankAccountNumber = await decryptedBankAccNumber;
            }
            if (bankRoutingNum) {
                const decryptedRoutingNumber = await getDecryptedData(bankRoutingNum);
                decryptedBankDetails.bankList[parseInt(index, 10)].bankRoutingNumber = await decryptedRoutingNumber;
            }
        }),
    );

    return decryptedBankDetails;
};

export const getBankAccountInfo = () => dispatch => {
    dispatch({
        type: ActionTypes.GET_BANK_ACCOUNT_INFO,
    });
};

export const deleteBankAccount = (bankAccountInfo, accountNum) => dispatch => {
    const info = JSON.parse(JSON.parse(bankAccountInfo)[0]);
    dispatch({
        type: ActionTypes.DELETE_BANK_ACCOUNT_INFO,
        state: info,
        accountNumber: accountNum,
    });
};

export const addBankAccount = (addAccountInfo, bankAccountInfo) => dispatch => {
    const info = JSON.parse(JSON.parse(bankAccountInfo)[0]);
    dispatch({
        type: ActionTypes.ADD_BANK_ACCOUNT_INFO,
        state: info,
        newAccountInfo: addAccountInfo,
    });
};

// ### END ###

/**
 * Action to get bank account list
 */
export const getBankAccountList = (payload) => dispatch => {
    const env = '';
    // Dispatching action for loader and error
    dispatch({
        type: ActionTypes.GET_BANK_ACCOUNT_LIST,
        response: { isLoading: true, isError: false },
    });
    // Making API Request
    makeApiRequest(
        ActionTypes.GET_BANK_ACCOUNT_LIST,
        payload,
        env,
        response => {
            if (response.data) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: ActionTypes.GET_BANK_ACCOUNT_LIST_SUCCESS,
                    response: response.data,
                });
            } else {
                // DISPATCHING FAILURE ACTION
                dispatch({
                    type: ActionTypes.GET_BANK_ACCOUNT_LIST_FAILURE,
                    response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_BANK_ACCOUNT_LIST_FAILURE,
                response: error,
            });
        },
    );
};

const abbreviateBankName = (name) => {
    if (name && typeof name === 'string') {
      const words = name.split(' ');
      const bankIndex = words.findIndex(word => word.toLowerCase() === 'bank');
  
      let filteredWords;
      if (bankIndex !== -1) {
        filteredWords = words.slice(0, bankIndex + 1);
      } else {
        filteredWords = words;
      }
  
      const abbreviation = filteredWords.map(word => word.charAt(0)).join('');
      return abbreviation;
    } else {
      return '';
    }
  };


export const getDecryptedBankDetailsRedirectFlow = async bankDetails => {
    const decryptedBankDetails = bankDetails;

    const bankList = bankDetails;
    await Promise.all(
        bankList.map(async (bank, index) => {
            const bankAccNumber = bank.bankAccountNumber;
            const bankRoutingNum = bank.bankRoutingNumber;

            if (bankAccNumber) {
                const decryptedBankAccNumber = await getDecryptedData(bankAccNumber);
                decryptedBankDetails[parseInt(index, 10)]['decryptedBankAccountNumber'] = await decryptedBankAccNumber;
            }
            if (bankRoutingNum) {
                const decryptedRoutingNumber = await getDecryptedData(bankRoutingNum);
                decryptedBankDetails[parseInt(index, 10)]['decryptedBankRoutingNumber'] = await decryptedRoutingNumber;
            }
            if (bankAccNumber) {
                const decryptedBankAccNumber = await getDecryptedData(bankAccNumber);
                decryptedBankDetails[parseInt(index, 10)]['maskBankName'] =  `${bank?.lastName} : ${bank?.bankName} : ${maskAccountNumber(decryptedBankAccNumber)}`
            }
        }),
    );
    return decryptedBankDetails;
};

/**
 * Action to get bank account details
 */
export const getBankAccounts = payload => {
    return dispatch => {
        const env = '';
        // Dispatching action for loader and error
        dispatch({
            type: ActionTypes.GET_BANK_ACCOUNT_DETAILS_LOADER,
            response: { isLoading: true, isError: false },
        });
        makeApiRequest(
            ActionTypes.GET_BANKACCOUNTS,
            payload,
            env,
            async response => {
                if (response.data) {
                    const decryptedBankDetails = await getDecryptedBankDetails(response.data);
                    await dispatch({
                        type: ActionTypes.GET_BANK_ACCOUNT_DETAILS_SUCCESS,
                        response: await decryptedBankDetails,
                    });
                    dispatch(linkResponseSuccess());
                } else {
                    dispatch({
                        type: ActionTypes.GET_BANK_ACCOUNT_DETAILS_FAILURE,
                        response,
                    });
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_BANK_ACCOUNT_DETAILS_FAILURE,
                    response: error,
                });
                dispatch(linkResponseError(error));
            },
        );
    };
};

export const validateBankAccInfo = payload => dispatch => {
    // SETTING ENVIRONMENT
    const env = 'MOCKTEST';
    // DISPATCHING ACTION FOR LOADER
    dispatch({
        type: ActionTypes.VALIDATE_BANK_ACCOUNT,
    });
    // MAKE API REQUEST
    makeApiRequest(
        ActionTypes.VALIDATE_BANK_ACCOUNT,
        payload,
        env,
        response => {
            if (response.data) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: ActionTypes.VALIDATE_BANK_ACCOUNT_SUCCESS,
                    response: response.data,
                });
            }
        },
        error => {
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: ActionTypes.VALIDATE_BANK_ACCOUNT_FAILURE,
                response: error.data,
            });
        },
    );
};

export const addBankAcc = payload => dispatch => {
    // SETTING ENVIRONMENT
    const env = ''; // MOCKTEST
    // DISPATCHING ACTION FOR LOADER
    dispatch({
        type: ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS,
    });
    // MAKE API REQUEST
    makeApiRequest(
        ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS,
        payload,
        env,
        response => {
            if (response.data) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS,
                    response: response.data,
                });
                dispatch(linkResponseSuccess());
            }
        },
        error => {
            const { response } = error || {};
            const { data = {}} = response || {};
            const { message = '' } = data || {};
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS_FAILURE,
                response: message,
            });
            dispatch(linkResponseError(message));
        },
    );
};

export const deleteBankAcc = payload => dispatch => {
    // SETTING ENVIRONMENT
    const env = 'MOCKTEST';
    // DISPATCHING ACTION FOR LOADER
    dispatch({
        type: ActionTypes.DELETE_BANK_ACCOUNT_DETAILS,
    });
    // MAKE API REQUEST
    makeApiRequest(
        ActionTypes.DELETE_BANK_ACCOUNT_DETAILS,
        payload,
        env,
        response => {
            if (response.data) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: ActionTypes.DELETE_BANK_ACCOUNT_DETAILS_SUCCESS,
                    response: response.data,
                });
            }
        },
        error => {
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: ActionTypes.DELETE_BANK_ACCOUNT_DETAILS_FAILURE,
                response: error.data,
            });
        },
    );
};

export const addBankAccountNAOTrans = payload => dispatch => {
    // SETTING ENVIRONMENT
    const env = ''; // MOCKTEST
    // DISPATCHING ACTION FOR LOADER
    dispatch({
        type: ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS_LOADER,
    });
    // MAKE API REQUEST
    makeApiRequest(
        ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS,
        payload,
        env,
        response => {
            if (response.data) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS_SUCCESS,
                    response: response.data,
                });
            }
        },
        error => {
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS_ERROR,
                response: error.data,
            });
        },
    );
};

export const getDecryptedBankDetailsNormal = async bankDetails => {
    const decryptedBankDetails = bankDetails;

    const bankList = bankDetails && bankDetails.bankList;
    await Promise.all(
        bankList.map(async (bank, index) => {
            const bankAccNumber = bank.bankAccountNumber;
            const bankRoutingNum = bank.bankRoutingNumber;

            if (bankAccNumber) {
                const decryptedBankAccNumber = await getDecryptedData(bankAccNumber);
                decryptedBankDetails.bankList[parseInt(index, 10)]['decryptedBankAccountNumber'] = await decryptedBankAccNumber;
            }
            if (bankRoutingNum) {
                const decryptedRoutingNumber = await getDecryptedData(bankRoutingNum);
                decryptedBankDetails.bankList[parseInt(index, 10)]['decryptedBankRoutingNumber'] = await decryptedRoutingNumber;
            }
        }),
    );
    return decryptedBankDetails;
};

export const getBrokerageBankAccountList = payload => {
    return async dispatch => {
        const env = '';       
        makeApiRequest(
            ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_LIST,
            payload,
            env,
            async response => {
                if (response.data) {
                    const decryptedBankDetails = await getDecryptedBankDetailsNormal(response.data);
                    // const decryptedBankDetails = await getDecryptedBankDetailsRedirectFlow(response.data);
                    await dispatch({
                        type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_LIST_SUCCESS,
                        response: await decryptedBankDetails,
                    });
                    dispatch(linkResponseSuccess());
                } else {
                    dispatch({
                        type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE,
                        response,
                    });
                    dispatch(linkResponseError(response));
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_LIST_FAILURE,
                    response: error,
                });
                dispatch(linkResponseError(error));
            },
        );
    };
};

export const clearError = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING BANK DETAILST
    dispatch({
        type: ActionTypes.ADD_BANK_ACCOUNT_CLEAR_ERROR,
    });
};
export const clearBankAccDetails = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING BANK DETAILST
    dispatch({
        type: ActionTypes.BANK_ACCOUNT_DETAILS_RESET,
    });
};
export const clearVerifyAccDetails = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING BANK DETAILST
    dispatch({
        type: ActionTypes.VERIFY_BANK_ACCOUNT_DETAILS_RESET,
    });
};

export const clearAddBankAccDetails = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING BANK DETAILST
    dispatch({
        type: ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS_RESET,
    });
};
export const resetBrokerageAccountListState = (index) => dispatch => {
    dispatch({
        type: ActionTypes.RESET_BROKEREAGE_BANK_ACCOUNTS_LIST,
    });
}
