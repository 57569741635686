import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import './memberMessage.css';

const ChartCard = (props) => {
  const { popOverClose } = props;
  const today = new Date().getDay();
  const [day] = useState(today);
  const todayTimimgs = {};
  if (day === 0) {
    todayTimimgs.openNow = 'Closed';
    todayTimimgs.timing = 'Closed';
  } else if (day === 6) {
    todayTimimgs.openNow = 'Open Now';
    todayTimimgs.timing = '9:00am-6:00pm (Eastern)';
  } else {
    todayTimimgs.openNow = 'Open Now';
    todayTimimgs.timing = '8:30am-9:00pm (Eastern)';
  }
  const data = {
    close: 'close',
    hours: 'Hours',
    daysOfWeek: 'Monday – Friday',
    daystoWeek: 'Monday to Friday',
    hoursOfOpt: '8:30am - 9:00pm (Eastern)'
  }

  return (
    <Col className="chartCard" xs={12}>
      {popOverClose && <div className="closeChartIcon">{data.close}</div>}
      <Row className="chartTopSection" noGutters>
        <Col xs={12}>
          <h2 className="chartSectiondHeading">{data.Hours}</h2>
          <div>
            <span className="labelSpan">
              <span aria-hidden>{data.daysOfWeek}</span>
              <span className="sr-only">{data.daystoWeek}</span>
            </span>
            <span className="textSpan">
              <span aria-hidden>{data.hoursOfOpt}</span>
              <span className="sr-only">{data.hoursOfOpt}</span>
            </span>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

ChartCard.propTypes = {
  popOverClose: PropTypes.bool,
};

ChartCard.defaultProps = {
  popOverClose: false,
};

export default ChartCard;
