import React from 'react';
import { Col } from 'react-bootstrap';
import Styles from './Styles';
import { HEADER } from './Constants';
import * as Constants from './Constants';

const assignObj = obj => obj;
const increaseDecreaseText = 'increase/decrease in 1-Day NAV value';
// const endOfYearText = 'Will be available by end of the year';
function TransactionTableHeader() {
    return (
        <>
            {/* <span style={assignObj({ fontSize: '12px', position: 'absolute', top: '25px', right: '25px' })}>
                {endOfYearText}
            </span> */}
            <Styles.FormHeader scope="col" role="columnheader" className="no-show transanction-Table">
                <Col sm={1} style={assignObj({ textAlign: 'left' })}>
                    {HEADER.TICKER}
                </Col>
                <Col sm={2} style={assignObj({ textAlign: 'left' })}>
                    {HEADER.FUND_NAME}
                </Col>
                <Col sm={1} style={assignObj({ textAlign: 'left' })}>
                    {HEADER.ACC_NUMBER}
                </Col>
                <Col sm={1} id={HEADER.NAV}>
                    {HEADER.NAV}
                    <Styles.FormNavHeader href={`#${Constants.INFO.AS_ON}`} aria-label="current value">
                        {HEADER.STAR}
                    </Styles.FormNavHeader>
                </Col>
                <Col sm={1}>{HEADER.ONE_DAY_CH}</Col>
                <div className="sr-only">{increaseDecreaseText}</div>
                <Col sm={1}>{HEADER.TOTAL_SHARE}</Col>
                <Col sm={1}>{HEADER.CURRENT_VALUE}</Col>
                <Col sm={1}>{HEADER.ONE_DAY_ACC_CH}</Col>
                <Col sm={1}>{HEADER.TOTAL_COST}</Col>
                <Col sm={2}>{HEADER.GAIN_OR_LOSS}</Col>
                {/* <Col sm={1}>{HEADER.COST_BASIS}</Col> */}
            </Styles.FormHeader>
            {/* <div>
                <Col sm={12}>
                    <span style={assignObj({ position: 'absolute', top: '-22px', right: '80px', fontSize: '0.8em' })}>
                        {endOfYearText}
                    </span>
                </Col>
            </div> */}
        </>
    );
}

export default TransactionTableHeader;
