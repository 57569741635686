import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions } from '../../../shared/Actions';
import ChangeSignInBySoftToken from './ChangeSignInBySoftTokenComponent';



const mapStateToProps = (state /* , props */) => (
  {
    loginState: state.loginData
  }
);

const mapDispatchToProps = {
  ...loginActions,
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSignInBySoftToken));