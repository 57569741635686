import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { RmdActions } from '../../../../shared/Actions';
import RequiredMinimumDistribution from './RequiredMinimumDistributionComponent';
import mockedRMDs from './mockedData/mockedRmds';

export const getEligibleAccounts = () => dispatch => {
    dispatch({
        type: 'GET_RMD_ACCOUNTS_SUCCESS',
        payload: mockedRMDs
      });
};

export const getRMDTransactions = () => dispatch => {
    dispatch({
        type: 'GET_RMD_TRANSACTIONS_SUCCESS',
        payload: mockedRMDs
      });
};

const mapStateToProps = (state) => ({
    rmdList: get(state, 'rmdReducerData.accountsOpened', []),
    isError: get(state, 'rmdReducerData.isError', false),
    error: get(state, 'rmdReducerData.error', null),
    isDelete: get(state, 'rmdReducerData.isDelete', false),
    isEligible: get(state, 'rmdReducerData.eligibility.isEligible', false),
    eligibilityMessage: get(state, 'rmdReducerData.eligibility.message', ''),
    isLoading: state.rmdReducerData.isLoading,
});

const mapDispatchToProps = {
    ...RmdActions   // uncomment this to use real data
    // getEligibleAccounts: () => getEligibleAccounts(), // RmdActions.getEligibleAccounts, // 
    // getRMDTransactions: () => getRMDTransactions(), // RmdActions.getRMDTransactions, // 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequiredMinimumDistribution));
