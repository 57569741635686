const borderBox = 'border-box'
const Styles = {
    main: {
        background: '#F5F8EC 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 25px rgba(0,0,0,0.16)',
        border: '2px solid #D1E8A4',
        minHeight: 102,
        maxHeight: 128,
        padding: '40px 0',
        // position: 'absolute',
        width: '100%',
        zIndex: 100,
        boxSizing: borderBox
    },
    mainError: {
        background: '#FCF1F2 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 25px rgba(0,0,0,0.16)',
        border: '2px solid #EEA7AD',
        minHeight: 102,
        maxHeight: 128,
        padding: '40px 0',
        // position: 'absolute',
        width: '100%',
        zIndex: 100,
        boxSizing: borderBox
    },
    message: {
        font: '500 16px/20px benton-sans',
        color: '#49494A',
        margin: 0,
        display: 'inline-block',
        paddingLeft: 15
    },
    info: {
        font: '500 16px/20px benton-sans',
        color: '#49494A',
        display: 'inline-block',
        marginTop: 4,
        marginBottom: 0,
        paddingLeft: 15
    },
    container: {
        position: 'relative',
        boxSizing: borderBox
    },
    time: {
        position: 'absolute',
        right: '85px',
        top: 4,
        display: 'inline-block',
        font: '600 14px/20px benton-sans'
    },
    timer1: {
        position: 'absolute',
        top: 11,
        right: 0
    },
    timer2: {
        position: 'absolute',
        top: -4,
        right: 0
    },
    crossAfter: {
        width: '16px',
        transform: 'rotate(45deg)',
        border: '1.5px solid #49494A',
        position: 'absolute',
        left: '15px',
        top: '22px',
        borderRadius: '3px',
        backgroundColor: '#49494A'
    },
    crossBefore: {
        width: '16px',
        height: '0',
        transform: 'rotate(-45deg)',
        border: '1.5px solid #49494A',
        position: 'absolute',
        left: '15px',
        top: '22px',
        borderRadius: '2px',
        backgroundColor: '#49494A'
    },
    closeButton: {
        backgroundColor: 'transparent',
        display: 'inline-block',
        width: '50px',
        height: '50px',
        border: '1.5px solid #49494A',
        borderRadius: '50%',
        position: 'absolute',
        right: 15,
        top: -12
    },
    relative: {
        position: 'relative'
    },
};

export default Styles;