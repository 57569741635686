import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import SkipArrowBlue from 'assets/rightArrowBlue.svg';
import SkipArrowGreen from 'assets/rightArrowGreen.svg';
import styled from 'styled-components';
import { StyledButton } from 'common';

const VerticalSeperator = styled.div`
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1em;
    margin-right: 1em;
`;

const FlexDiv = styled.div`
    display: flex;
`;

const StyledCard = styled(Card)`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    && {
        border: none;
        border-top: 1px solid #d2d2d2;
        border-radius: 0;
    }
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 0;
    }
`;

const ShareNameSection = styled(FlexDiv)`
    justify-content: space-between;
    padding: 1.1875rem 2.5rem;
    align-items: center;
`;
const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    && h4 {
        font-size: 16px;
        color: #004a98;
        font-weight: 600;
    }
`;
const ShareNameNumberContainer = styled(FlexDiv)`
    width: 70%;
    justify-content: space-between;
    align-items: center;
`;
const AccountCardBottomColumn = styled(FlexDiv)`
    flex-direction: column;
    display: flex;
    justify-content: space-between;
`;
const AccountCardHeadingSpan = styled.span`
    margin-bottom: 0.625rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const AccountCardValueSpan = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    @media (max-width: 991px) {
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const SkipButton = styled(StyledButton)`
    display: flex;
    align-items: center;
    padding: 0;
    &:hover {
        span {
            background: url(${SkipArrowBlue}) no-repeat bottom left;
        }
    }
`;
SkipButton.displayName = 'SkipButton';

const RightExtendedVerticalSeperator = styled(VerticalSeperator)`
    margin: 0 2rem;
    @media (max-width: 991px) {
        margin: 0 0.5rem;
    }
`;
const StyledHr = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
`;
const DetailsSec = styled(FlexDiv)`
    padding: 1.25rem 2.5rem;
    align-items: center;
    justify-content: space-between;
`;
const DetailColumns = styled(FlexDiv)``;

const DateText = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;
const DateValue = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

const ShortFundName = styled(FlexDiv)`
    padding: 0.5rem;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
    && abbr[title] {
        text-decoration: none;
    }
`;

const AddedAccountCardBody = props => {
    const { addedDate, details, share, children } = props;
    const { text: addedDateText = '', value: addedDateValue = '' } = addedDate || {};
    const detailsArray = details || [];
    return (
        <StyledCard>
            <StyledCardBody>
                <ShareNameSection className="indexFundMember">
                    <ShareNameNumberContainer className="sharedNumber">
                        <ShareNameDiv>
                            <ShortFundName>
                                <span className="sr-only">{share.shortFundName.split('').join(' ')}</span>
                                <span aria-hidden="true">
                                    <abbr title={share.fundName}>{share.shortFundName}</abbr>
                                </span>
                            </ShortFundName>
                            <h4>{share.fundName}</h4>
                        </ShareNameDiv>
                    </ShareNameNumberContainer>
                    <div>
                        <DateText>{addedDateText}</DateText>
                        <DateValue>{addedDateValue}</DateValue>
                    </div>
                    {children}
                </ShareNameSection>
                <StyledHr aria-hidden="true"/>
                <DetailsSec className="individualDetail">
                    <DetailColumns className="individualTextDetail">
                        {detailsArray.map((item, index) => (
                            <React.Fragment key={index.toString()}>
                                {!!index && <RightExtendedVerticalSeperator />}
                                <AccountCardBottomColumn className="columnHeadertext">
                                    <AccountCardHeadingSpan>{item.text}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>{item.value}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                            </React.Fragment>
                        ))}
                    </DetailColumns>
               
                </DetailsSec>
            </StyledCardBody>
        </StyledCard>
    );
};

AddedAccountCardBody.propTypes = {
    details: PropTypes.instanceOf(Object),
    share: PropTypes.instanceOf(Object),
    addedDate: PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
    }),
    onSkipClick: PropTypes.func,
    skipText: PropTypes.string,
    children: PropTypes.node
};
AddedAccountCardBody.defaultProps = {
    onSkipClick: () => {},
    details: [],
    share: {},
    skipText: 'Skip',
    addedDate: {},
};

export default AddedAccountCardBody;
