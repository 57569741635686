import * as FundAccountActionTypes from '../ReduxConstants/FundAccountConstants';

const {
    GET_AIP_FUND_PLAN_DETAILS,
    GET_SWP_FUND_PLAN_DETAILS,
    GET_FUND_PLAN_DETAILS_SUCCESS,
    GET_FUND_PLAN_DETAILS_FAILURE,
    DISMISS_GET_FUND_PLAN_DETAILS_FAILURE,
    GET_FUND_PLAN_DETAILS_RESET
} = FundAccountActionTypes;

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    planInfo: {},
    error: {},
};

function fundPlanReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_AIP_FUND_PLAN_DETAILS:
        case GET_SWP_FUND_PLAN_DETAILS: {
            return {
                ...state,
                isLoading: true
            };
        }
        case GET_FUND_PLAN_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                planInfo: action.response
            };
        }

        case GET_FUND_PLAN_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.response,
            };
        }

        case DISMISS_GET_FUND_PLAN_DETAILS_FAILURE: {
            return {
                ...state,
                isError: false,
                error: {},
            };
        }

        case GET_FUND_PLAN_DETAILS_RESET: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                planInfo: {}
            };
        }

        default:
            return state;
    }
}

export default fundPlanReducer;
