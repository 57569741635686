export const mockedAIP = {
  "message":"Success",
  "responseCode":4000,
  "response":"Success",
  "result":[
     {
        "accountType":"General Investment Accounts",
        "accounts":[
           {
              "accountName":"Joint Account",
              "masterRegId":40184858,
              "details":[
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/01/2020",
                    "investedFundAmount":"89.00",
                    "nextSchedulePADDate":"11/02/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"014",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"Q",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"07/31/2020",
                    "investedFundAmount":"9999.00",
                    "nextSchedulePADDate":"10/31/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"015",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"S",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/03/2020",
                    "investedFundAmount":"50.05",
                    "nextSchedulePADDate":"08/17/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"016",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"Q",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/30/2020",
                    "investedFundAmount":"312.00",
                    "nextSchedulePADDate":"08/30/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"017",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"09/30/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"09/30/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"018",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/01/2020",
                    "investedFundAmount":"80.00",
                    "nextSchedulePADDate":"09/01/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"019",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"",
                    "schedule":"M",
                    "datePADSetup":"08/04/2020",
                    "datePADInitialized":"08/06/2020",
                    "investedFundAmount":"80.00",
                    "nextSchedulePADDate":"09/06/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"020",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"",
                    "schedule":"S",
                    "datePADSetup":"08/06/2020",
                    "datePADInitialized":"08/07/2020",
                    "investedFundAmount":"200.00",
                    "nextSchedulePADDate":"08/21/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"021",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":30,
                    "fundAccountNumber":30000005434,
                    "payeeBankName":"",
                    "schedule":"M",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/13/2020",
                    "investedFundAmount":"98.00",
                    "nextSchedulePADDate":"08/13/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Joint Account",
                    "masterRegId":40184858,
                    "share":{
                       "fundName":"USAA Intermdt-Term Bond Fund",
                       "shortFundName":"USIBX"
                    },
                    "padId":"022",
                    "endDate":"99/99/1999"
                 }
              ]
           },
           {
              "accountName":"Individual Account",
              "masterRegId":500232261,
              "details":[
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/22/2020",
                    "datePADInitialized":"07/28/2020",
                    "investedFundAmount":"80.00",
                    "nextSchedulePADDate":"08/28/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"003",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"Q",
                    "datePADSetup":"07/22/2020",
                    "datePADInitialized":"07/23/2020",
                    "investedFundAmount":"87.00",
                    "nextSchedulePADDate":"08/23/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"006",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/22/2020",
                    "datePADInitialized":"07/23/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/23/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"007",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/22/2020",
                    "datePADInitialized":"07/23/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/23/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"008",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/22/2020",
                    "datePADInitialized":"07/26/2020",
                    "investedFundAmount":"80.00",
                    "nextSchedulePADDate":"08/26/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"009",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"010",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"011",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"012",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"013",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"014",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"4750.07",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"015",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/28/2020",
                    "investedFundAmount":"110.00",
                    "nextSchedulePADDate":"07/28/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"016",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/28/2020",
                    "investedFundAmount":"110.00",
                    "nextSchedulePADDate":"07/28/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"017",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/27/2020",
                    "datePADInitialized":"07/28/2020",
                    "investedFundAmount":"110.00",
                    "nextSchedulePADDate":"07/28/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"018",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"M",
                    "datePADSetup":"07/29/2020",
                    "datePADInitialized":"08/03/2020",
                    "investedFundAmount":"10000.00",
                    "nextSchedulePADDate":"09/03/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"019",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"020",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"021",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"022",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"023",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"024",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"025",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"026",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"027",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"028",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35903202891,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/15/2020",
                    "investedFundAmount":"50.00",
                    "nextSchedulePADDate":"08/15/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":500232261,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"029",
                    "endDate":"99/99/1999"
                 }
              ]
           },
           {
              "accountName":"Individual Account",
              "masterRegId":17140750,
              "details":[
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"06/22/2021",
                    "investedFundAmount":"1234.00",
                    "nextSchedulePADDate":"06/22/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"004",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"06/22/2021",
                    "investedFundAmount":"1234.00",
                    "nextSchedulePADDate":"06/22/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"005",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"06/22/2021",
                    "investedFundAmount":"1234.00",
                    "nextSchedulePADDate":"06/22/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"006",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"007",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"008",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"009",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"010",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"011",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"012",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"013",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"015",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"016",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"017",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"018",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"019",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"Q",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"06/22/2021",
                    "investedFundAmount":"1234.00",
                    "nextSchedulePADDate":"06/22/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"020",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"021",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"022",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"023",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"024",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"025",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"026",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"027",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/16/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"1000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"028",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/17/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"06/22/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"029",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"HUNTINGTON NATIONAL BANK",
                    "schedule":"A",
                    "datePADSetup":"07/21/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"030",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"WELLS FARGO BANK NA  (MINNESOTA)",
                    "schedule":"A",
                    "datePADSetup":"07/21/2020",
                    "datePADInitialized":"05/29/2022",
                    "investedFundAmount":"3000.00",
                    "nextSchedulePADDate":"05/29/2022",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"031",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"B",
                    "datePADSetup":"07/21/2020",
                    "datePADInitialized":"07/29/2020",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"09/29/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"032",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"",
                    "schedule":"S",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/03/2020",
                    "investedFundAmount":"55.00",
                    "nextSchedulePADDate":"08/17/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"033",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000001428,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"07/31/2020",
                    "datePADInitialized":"08/04/2020",
                    "investedFundAmount":"777.00",
                    "nextSchedulePADDate":"09/04/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":17140750,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"034",
                    "endDate":"99/99/1999"
                 }
              ]
           },
           {
              "accountName":"Individual Account",
              "masterRegId":3384103,
              "details":[
                 {
                    "fundNumber":37,
                    "fundAccountNumber":37000002230,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"S",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/03/2020",
                    "investedFundAmount":"50.03",
                    "nextSchedulePADDate":"08/17/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Individual Account",
                    "masterRegId":3384103,
                    "share":{
                       "fundName":"USAA Growth and Income Fund",
                       "shortFundName":"USGRX"
                    },
                    "padId":"001",
                    "endDate":"99/99/1999"
                 }
              ]
           }
        ]
     },
     {
        "accountType":"Retirement Accounts",
        "accounts":[
           {
              "accountName":"Traditional IRA Account",
              "masterRegId":94788757,
              "details":[
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35000001095,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"A",
                    "datePADSetup":"07/29/2020",
                    "datePADInitialized":"07/31/2020",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"07/31/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Traditional IRA Account",
                    "masterRegId":94788757,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"001",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35000001095,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"07/29/2020",
                    "datePADInitialized":"07/31/2020",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"07/31/2021",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Traditional IRA Account",
                    "masterRegId":94788757,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"002",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35000001095,
                    "payeeBankName":"",
                    "schedule":"M",
                    "datePADSetup":"07/30/2020",
                    "datePADInitialized":"08/03/2020",
                    "investedFundAmount":"50.09",
                    "nextSchedulePADDate":"09/03/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Traditional IRA Account",
                    "masterRegId":94788757,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"003",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":35,
                    "fundAccountNumber":35000001095,
                    "payeeBankName":"",
                    "schedule":"A",
                    "datePADSetup":"08/11/2020",
                    "datePADInitialized":"08/12/2020",
                    "investedFundAmount":"200.00",
                    "nextSchedulePADDate":"08/12/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"Traditional IRA Account",
                    "masterRegId":94788757,
                    "share":{
                       "fundName":"USAA Income Stock Fund",
                       "shortFundName":"USISX"
                    },
                    "padId":"004",
                    "endDate":"99/99/1999"
                 }
              ]
           }
        ]
     },
     {
        "accountType":"Investing for Children",
        "accounts":[
           {
              "accountName":"UGMA/UTMA Account",
              "masterRegId":75630991,
              "details":[
                 {
                    "fundNumber":46,
                    "fundAccountNumber":46000000060,
                    "payeeBankName":"",
                    "schedule":"M",
                    "datePADSetup":"08/10/2020",
                    "datePADInitialized":"08/12/2020",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"08/12/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"UGMA/UTMA Account",
                    "masterRegId":75630991,
                    "share":{
                       "fundName":"USAA Tax Exempt Money Market",
                       "shortFundName":"USEXX"
                    },
                    "padId":"001",
                    "endDate":"99/99/1999"
                 },
                 {
                    "fundNumber":46,
                    "fundAccountNumber":46000000060,
                    "payeeBankName":"WELLS FARGO BANK NA (ARIZONA)",
                    "schedule":"M",
                    "datePADSetup":"08/10/2020",
                    "datePADInitialized":"09/10/2020",
                    "investedFundAmount":"100.00",
                    "nextSchedulePADDate":"09/10/2020",
                    "suspendedFromDate":"",
                    "suspendedToDate":"",
                    "accountName":"UGMA/UTMA Account",
                    "masterRegId":75630991,
                    "share":{
                       "fundName":"USAA Tax Exempt Money Market",
                       "shortFundName":"USEXX"
                    },
                    "padId":"002",
                    "endDate":"99/99/1999"
                 }
              ]
           }
        ]
     }
  ]
}

export const mockedEmptySWP = {"message":"Success","responseCode":4000,"response":"Success","result":[]}

// moked 2 SWPs
export const mockedSWP = {
   "message":"Success",
   "responseCode":4000,
   "response":"Success",
   "result":[
      {
         "accountType":"General Investment Accounts",
         "accounts":[
            {
               "accountName":"Joint Account",
               "masterRegId":500213053,
               "details":[
                  {
                     "masterRegId":500213053,
                     "accountNumber":500213053,
                     "accountName":"Joint Account",
                     "fundNumber":42,
                     "fundAccountNumber":42902373135,
                     "swpId":"001",
                     "dateSWPSetup":"07/07/2020",
                     "dateSWPInitialized":"10/08/2020",
                     "suspendedFromDate":"",
                     "suspendedToDate":"",
                     "schedule":"A",
                     "nextScheduleSWPDate":"10/08/2020",
                     "investedFundAmount":"200.00",
                     "payeeBankName":"Check",
                     "endDate":"00/00/1900",
                     "share":{
                        "fundName":"USAA Money Market Fund",
                        "shortFundName":"USAXX"
                     }
                  },
                  {
                     "masterRegId":500213053,
                     "accountNumber":500213053,
                     "accountName":"Joint Account",
                     "fundNumber":42,
                     "fundAccountNumber":42902373135,
                     "swpId":"002",
                     "dateSWPSetup":"07/08/2020",
                     "dateSWPInitialized":"10/08/2020",
                     "suspendedFromDate":"",
                     "suspendedToDate":"",
                     "schedule":"A",
                     "nextScheduleSWPDate":"10/08/2020",
                     "investedFundAmount":"200.00",
                     "payeeBankName":"Check",
                     "endDate":"00/00/1900",
                     "share":{
                        "fundName":"USAA Money Market Fund",
                        "shortFundName":"USAXX"
                     }
                  }
               ]
            }
         ]
      }
   ]
}