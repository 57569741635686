import * as TransactionActionTypes from '../ReduxConstants/TransactionActionConstants';
import makeApiRequest from '../Network/ApiInterface';
import moment from 'moment-timezone';
// Getting the formatted date in MM/dd/yyyy format
// const getFormattedDate = date => {
//     const year = date.getFullYear();

//     let month = (1 + date.getMonth()).toString();
//     month = month.length > 1 ? month : `0${month}`;

//     let day = date.getDate().toString();
//     day = day.length > 1 ? day : `0${day}`;

//     return `${month}/${day}/${year}`;
// };

const getTradingInformation = dateString => {
    const dateVal = new Date(dateString);
    let month = '';
    let year = '';
    let day = '';
    let hour = '';
    let minutes = '';
    if (dateString) {
        month = dateVal.getUTCMonth();
        day = dateVal.getUTCDate();
        year = dateVal.getUTCFullYear();
        hour = dateVal.getUTCHours();
        minutes = dateVal.getUTCMinutes();
    }

    return {
        month,
        year,
        day,
        hour,
        minutes,
    };
};

/**
 * Gets the payload from response
 */
const getPayloadResonse = response => {
    const {
        canTradeToday,
        tradingStartTime,
        tradingEndTime,
        warningSpanDuration,
        tradingDayHour,
        nextBusinessDay,
        secondBusinessDay,
        thirdBusinessDay,
        aipTradingStartDateKey,
    } = response.data;
    // const tradingStartTimeEST = moment(tradingStartTime);
    // const tradingEndTimeEST = moment(tradingEndTime);
    // tradingStartTimeEST.tz('America/New_York').format('ha z');
    // tradingEndTimeEST.tz('America/New_York').format('ha z');
    const tradingActualEndTime = new Date(tradingEndTime);
    const tradingMins = tradingActualEndTime.getMinutes();
    tradingActualEndTime.setMinutes(tradingMins + 1);

    const tradingStartTimeEST = moment
        .utc(new Date(tradingStartTime))
        .tz('America/New_York')
        .format('hh:mm A z');
    const tradingEndTimeEST = moment
        .utc(new Date(tradingEndTime))
        .tz('America/New_York')
        .format('hh:mm A z');
    const tradingActualEndTimeEST = moment
        .utc(new Date(tradingActualEndTime))
        .tz('America/New_York')
        .format('hh:mm A z');
    const currentLocalTimeStr = new Date().toUTCString();
    const currentLocalTime = Date.parse(currentLocalTimeStr);
    let marketStartTime = '';
    let marketEndTime = '';
    let marketClosureDiff = '';
    let marketOpenDiff = '';
    let canTrade = true;
    let warningMessage = '';
    let effectiveDate = canTradeToday ? tradingEndTime : nextBusinessDay;
    const warningSpan = +warningSpanDuration * 1000 * 60;

    if (canTradeToday) {
        marketStartTime = Date.parse(tradingStartTime);
        // marketStartTime = new Date(tradingStartTime);
        // marketStartTime = new Date(Date.UTC(2020, 5, 10, 2, 5, 0));
        marketEndTime = Date.parse(tradingEndTime);
        // marketEndTime = new Date(tradingEndTime);
        // marketEndTime = new Date(Date.UTC(2020, 5, 10, 8, 50, 0));
        marketOpenDiff = marketStartTime - currentLocalTime;
        marketClosureDiff = marketEndTime - currentLocalTime;

        const { day: currentLocalDay } = getTradingInformation(currentLocalTimeStr);
        const { day: marketEndDay } = getTradingInformation(tradingEndTime);

        if (currentLocalDay !== marketEndDay) {
            // if the user is in a different time zone and have the local date as next day
            if (canTradeToday && tradingDayHour) {
                // During market hours
                const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                effectiveDate = `${month + 1}/${day}/${year}`;
                warningMessage = '';
                canTrade = true;
                if (marketClosureDiff < warningSpan) {
                    // 30 mins warning message
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                }
            } else if (marketOpenDiff > 0 && !tradingDayHour) {
                // Before market hours
                const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                effectiveDate = `${month + 1}/${day}/${year}`;
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
                canTrade = false;
            } else {
                // After market hours
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                canTrade = false;
                effectiveDate = nextBusinessDay;
            }
        } else if (marketOpenDiff > 0) {
            canTrade = false;
            // warningMessage = TransactionActionTypes.TRADING_WINDOW_PENDING_OPEN_MESSAGE;
            warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
        } else if (marketClosureDiff < 0) {
            canTrade = false;
            // warningMessage = `${TransactionActionTypes.TRADING_WINDOW_HOLIDAY_CLOSURE_MESSAGE} (${nextBusinessDay}).`;
            warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
            effectiveDate = nextBusinessDay;
        } else if (marketOpenDiff < 0 && marketClosureDiff > 0) {
            if (marketClosureDiff < warningSpan) {
                canTrade = false;
                // warningMessage = TransactionActionTypes.TRADING_WINDOW_END_OF_DAY_CLOSURE_MESSAGE;
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                // effectiveDate = nextBusinessDay;
            } else {
                canTrade = true;
                warningMessage = '';
            }
        }
    } else {
        canTrade = false;
        // warningMessage = `${TransactionActionTypes.TRADING_WINDOW_HOLIDAY_CLOSURE_MESSAGE} (${nextBusinessDay}).`;
        warningMessage = `Market Hours for Mutual Funds: You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
        effectiveDate = nextBusinessDay;
    }

    return {
        canTrade,
        canTradeToday,
        marketOpenDiff,
        marketClosureDiff,
        marketStartTime,
        marketEndTime,
        warningSpan,
        nextBusinessDay,
        warningMessage,
        effectiveDate,
        tradingStartTimeEST,
        tradingEndTimeEST,
        tradingActualEndTimeEST,
        tradingDayHour,
        tradingStartTime,
        tradingEndTime,
        secondBusinessDay,
        thirdBusinessDay,
        aipTradingStartDateKey,
    };
};

/**
 *
 * Get Trading closure window
 */
export const getTradingClosureWindow = payload => {
    return dispatch => {
        const env = '';
        makeApiRequest(
            TransactionActionTypes.GET_TRADING_CALENDAR,
            payload,
            env,
            response => {
                if (response.data) {
                    dispatch({
                        type: TransactionActionTypes.GET_TRADING_CALENDAR_SUCCESS,
                        payload: getPayloadResonse(response),
                    });
                } else {
                    dispatch({
                        type: TransactionActionTypes.GET_TRADING_CALENDAR_FAILURE,
                        payload: response.data,
                    });
                }
            },

            error => {
                dispatch({
                    type: TransactionActionTypes.GET_TRADING_CALENDAR_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

/**
 * Updates the states for canTrade and warning message
 */
export const setTradingWindowWarning = (canTrade, warningMessage, effectiveDate) => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_TRADING_CLOSURE_STATUS_SUCCESS,
            payload: { canTrade, warningMessage, effectiveDate },
        });
    };
};

/**
 * Updates the states to know if the user is navigating from transaction dashboard
 */
export const setNavigationFromTransactionDashboard = () => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_NAVIGATION_FROM_TRANSACTION_DASHBOARD_SUCCESS,
            payload: true,
        });
    };
};

export const setTradingData = value => dispatch => {
    dispatch({
        type: TransactionActionTypes.SET_TRADINGWINDOW_DATA,
        value,
    });
};
