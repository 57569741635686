const successTitle = 'Thank you for creating your secure Victory Capital login credentials';
const successNew = 'Beginning in the third quarter of 2020, access your USAA Mutual Funds and 529 Education Savings Plan account information here at ';
const successNewSecond = 'Until the third quarter, please continue to access your accounts on ';
const successPara = 'Thank you for reviewing your profile information and setting up your account credentials. We look forward to serve you. Please revisit the portal on Q3 2020 for added functionality. In the meantime, you can continue accessing your USAA Mutual Funds and 529 Education Savings Plan related information on ';
const webUrl = 'www.usaa.com';
const webURLBase = 'https://';
const vcm = 'vcm.com';
const fullStop = '.';
const extWebsiteMsg = 'External Website. Opens New Window';
const signInText = 'Do you want to sign in now?';
const signin = 'Sign In';
const successPostGoLiveTitle = 'You did it! Thank you for completing the registration process. Click below to login to your account. ';


export default {
    successTitle,
    successPostGoLiveTitle,
    successNew,
    successNewSecond,
    successPara,
    webUrl,
    webURLBase,
    vcm,
    fullStop,
    extWebsiteMsg,
    signInText,
    signin,
}