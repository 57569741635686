import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";
import { linkResponseSuccess, linkResponseError } from "./AnalyticsActions";

export const getCheckBookInfo = (payload) => (dispatch) => {
    // SETTING ENVIRONMENT
    const env = '';

  dispatch({
    type: ActionTypes.GET_CHECKBOOK_INFO_LOADING,
  });

  makeApiRequest(
    ActionTypes.GET_CHECKBOOK_INFO,
    payload,
    env,
    response => {
      if (response.data && response.data.result) {
        dispatch({
          type: ActionTypes.GET_CHECKBOOK_INFO_SUCCESS,
          payload: response.data.result
        });
        dispatch(linkResponseSuccess());
      } else {
        dispatch({
          type: ActionTypes.GET_CHECKBOOK_INFO_FAILURE,
        });
        dispatch(linkResponseError(response.data));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.GET_CHECKBOOK_INFO_FAILURE,
        payload: error
      });
      dispatch(linkResponseError(error));
    }
  );
};

export const updateCheckbook = payload => dispatch => {
  const env = '';
  dispatch({
    type: ActionTypes.UPDATE_CHECKBOOK_LOADING
  });
  makeApiRequest(
    ActionTypes.UPDATE_CHECKBOOK,
    payload,
    env,
    response => {
      if (response.data && response.data.result) {
        dispatch({
          type: ActionTypes.UPDATE_CHECKBOOK_SUCCESS,
        });
        dispatch(linkResponseSuccess());
      } else {
        dispatch({
          type: ActionTypes.UPDATE_CHECKBOOK_FAILURE,
          error: response
        });
       dispatch(linkResponseError(response));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.UPDATE_CHECKBOOK_FAILURE,
        error
      });
      dispatch(linkResponseError(error));
    }
  );
};

export const clearReduxData = (stateKey, obj) => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_REDUX_DATA,
    [stateKey]: obj
  });
};
