import React, { Component } from "react";
import PropTypes from 'prop-types';
import styles from '../CommonStyles';
import '../../App.css'

const assignObj = (obj) => { return obj; }

class VCMBasicHeadingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { basiclabelstyle, labelvalue} = this.props;
    return (
      <div style={assignObj({display:'flex', justifyContent:'space-between'})}>
        <h3 style={assignObj({ ...styles.basicLabelStyle, ...basiclabelstyle })}>{labelvalue}</h3>
    </div>
    )
    
  }
}

VCMBasicHeadingComponent.propTypes = {
  basiclabelstyle: PropTypes.instanceOf(Object),
  labelvalue: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }), 
}

VCMBasicHeadingComponent.defaultProps = {
  labelvalue: "Text",
  basiclabelstyle: {},
  history: {}
}

export default VCMBasicHeadingComponent;