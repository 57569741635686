/*
Component:
    VCM Card

Appearance:
    An empty card, with a border, and a triangle in the corner.

Properties:
    triColor: the color of the corner triangle
    triCorner: An integer indicating the corner to place the triangle.
        ■━━━━━━━━━┓     ┏━━━━━━━━━■
        ┃4        ┃     ┃        1┃
        ┃         ┃     ┃         ┃
        ┃         ┃     ┃         ┃
        ┗━━━━━━━━━┛     ┗━━━━━━━━━┛
        ┏━━━━━━━━━┓     ┏━━━━━━━━━┓
        ┃         ┃     ┃         ┃
        ┃         ┃     ┃         ┃
        ┃3        ┃     ┃        2┃
        ■━━━━━━━━━┛     ┗━━━━━━━━━■
        triSideLength: the length of each side of the triangle
        height: the height of the card
        width: the width of the card

README: Right side triangle positions need fixing.
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

const S = {};

S.Card = styled(Card)`
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid #d2d2d2;
    padding: 30px;
    height: ${props => props.height};
    width: ${props => props.width};
    position: relative;
    border-radius: 0px;
`;

S.GreenTriangle = styled.div`
    width: 0px;
    height: 0px;
    position: absolute;
    ${props => props.offset}
    ${props => props.sides[0]}: ${props => props.triSideLength} solid transparent;
    ${props => props.sides[1]}: ${props => props.triSideLength} solid transparent;
    ${props => props.sides[2]}: ${props => props.triSideLength} solid ${props => props.triColor};
`;

const [bl, br, bt, bb] = ['border-left', 'border-right', 'border-top', 'border-bottom'];

const sides = [
    [bb, bl, br],
    [bt, bl, br],
    [bt, br, bl],
    [bb, br, bl],
];

const offset = [
    [`
        top: 0px;
        right: 0px;
    `,
    ],
    [`
        bottom: 0px;
        right: 0px;
    `],
    [`
        bottom: 0px;
        left: 0px;
    `],
    [`
        left: 0px;
        top: 0px;
    `],
];

const BasicCard = ({ children, className, width, height, triColor, triSideLength, triCorner }) => (
    <S.Card
        width={width}
        height={height}
        className={className}
    >
        <S.GreenTriangle
            triSideLength={triSideLength}
            triColor={triColor}
            sides={sides[triCorner - 1]}
            offset={offset[triCorner - 1]}
        />
        {children}
    </S.Card>
);

BasicCard.defaultProps = {
    height: '100%',
    width: '100%',
    triCorner: 4,
    triColor: '#004A98',
    triSideLength: '25px',
    children: null,
    className: '',
};

BasicCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    triColor: PropTypes.string,
    triCorner: PropTypes.number,
    triSideLength: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default BasicCard;
