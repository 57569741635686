import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classes from '../Input/Input.module.css';
import { WInputCheckBox } from '../../../common';

class SecurityDetailsForm extends Component {
    data = {
        config: {
            type: 'text',
            label: 'Which city you were born?',
            placeholder: ''
        },
        checkBoxLabel: 'Remember this device for future'
    }

    constructor(props) {
        super(props);
        this.state = {
            saveDevice: false
        }
    }

    onClickChkBox = (e) => {
        this.setState({ saveDevice: e.target.checked });
    }

    assignObj = (obj) => { return obj; }

    render() {

        const { changed, blurred, securityDetailsFormState } = this.props;
        const { saveDevice } = this.state;

        let inputElement = null;
        const inputClasses = [classes.InputElement];

        if (!securityDetailsFormState.valid && securityDetailsFormState.touched) {
            inputClasses.push(classes.Invalid);
        }

        inputElement = <input
            id='question'
            type={this.data.config.type}
            placeholder={this.data.config.placeholder}
            className={inputClasses.join(' ')}
            value={securityDetailsFormState.value}
            onChange={changed}
            onBlur={blurred} />;

        return (
            <div className={classes.Input}>
                <label className={classes.Label} htmlFor={this.data.config.label}>{this.data.config.label}</label>
                {inputElement}
                <p className={classes.errMsg}>{securityDetailsFormState.errMsg}</p>
                <div className="saveDevice">
                    <div className="manageCheckBox">
                        <WInputCheckBox
                            controlId="saveDevice"
                            checked={saveDevice}
                            onChange={this.onClickChkBox}
                            labelstyle={this.assignObj({
                                textAlign: 'left',
                                font: '500 16px/20px benton-sans'
                            })}
                            checkInputStyle={this.assignObj({
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                border: '1px solid #004A98',
                                height: 25,
                                width: 25,
                                marginRight: 10
                            })}
                            label="Remember this device for future" />
                    </div>
                </div>
            </div>
        );
    }


}

SecurityDetailsForm.propTypes = {
    securityDetailsFormState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func
}

SecurityDetailsForm.defaultProps = {
    securityDetailsFormState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            required: true
        }
    },
    changed: () => { },
    blurred: () => { },
}

export default SecurityDetailsForm;