
/* eslint-disable react/jsx-no-literals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import {
  requestEmailOTP,
  setUpdatedEmail,
  setEmailError,
} from '../../screens/UserAccountInformation/UserAccountInformation.action';
import { validateEmail } from '../../Utils';
import './EmailField.css';
import { setEmailOTP } from '../../Actions/AccountRegistrationActions';
import analytics from '../utils.analytics';

class EmailField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmailClk: false,
    };
    this.refPassCodeInput = React.createRef();
    EmailField.getDerivedStateFromProps = EmailField.getDerivedStateFromProps.bind(
      this,
    );
    this.handleOTPChange = this.handleOTPChange.bind(this);
  }

  componentDidMount() {
    const { currentEmail, setModifiedEmail } = this.props;
    setModifiedEmail(currentEmail);
  }

  static getDerivedStateFromProps(props, state) {
    const { receivedEmailOTP, errorEmail, emailOTPError } = props;
    const { verifyEmailClk } = state;

    if (receivedEmailOTP && verifyEmailClk && isEmpty(errorEmail)) {
      setTimeout(() => {
        document.getElementById('passcode').focus();
      }, 500);
      return { verifyEmailClk: false };
    }
    if (!isEmpty(emailOTPError)) {
      document.getElementById('passcode').focus();
    }

    return { ...state };
  }

  handleEmailChange = (e) => {
    const { value } = e.target;
    const { setModifiedEmail } = this.props;
    setModifiedEmail(value);
  };

  handleEmailBlur = () => {
    const { currentEmail, updatedemail, setEmailFieldError } = this.props;
    let errorMsg = '';
    if (currentEmail !== updatedemail) {
      errorMsg = validateEmail(updatedemail);
    }

    setEmailFieldError(errorMsg);
  };

  handleOTPInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  }

  handleOTPChange = (e) => {
    const {storeOTP} = this.props;
    if (e.target.value.length < 7) {
      storeOTP(e.target.value);
    }
  }


  handleEmailVerify = () => {
    const { requestForEmailOTP, accountInfo, updatedemail } = this.props;

    const errorMsg = validateEmail(updatedemail);
    this.setState({ verifyEmailClk: true });
    if (isEmpty(errorMsg)) {
      analytics.triggerClickTrackEvent(
        'Preregister_verifypersonalinformation_Verify_Email',
      );
      requestForEmailOTP({
        ...accountInfo,
        emailAddress: updatedemail,
      });
    }
    if (!isEmpty(errorMsg)) {
      document.getElementById('emailFld').focus();
    }
  };


  handleResendOTP = (e) => {
    e.preventDefault();
    analytics.triggerClickTrackEvent(
      'Preregister_verifypersonalinformation_Resend_Security_Code',
    );
    this.requestPasscode();
  }

  requestPasscode() {
    const { accountInfo, requestForEmailOTP, updatedemail } = this.props;

    requestForEmailOTP({
      ...accountInfo,
      emailAddress: updatedemail,
      origin: 'resend',
    });
  }
  
  render() {
    const {
      field,
      currentEmail,
      receivedEmailOTP,
      onetimecode,
      emailOTPError,
      updatedemail,
      errorEmail,
      ariaRequired,
      ariaLabelledby,
    } = this.props;
    // const emailOTPError = requestEmailOTPErrormsg || validateEmailOTPErrorMsg;
    if (!isEmpty(errorEmail)) {
      const appErrorStr = errorEmail.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_verifypersonalinformation_error_${appErrorStr}`,
      );
    }
    if (!isEmpty(emailOTPError)) {
      const appErrorStr = emailOTPError.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_verifypersonalinformation_error_${appErrorStr}`,
      );
    }

    return (
      <>
        <div className={field}>
          <Col xs={12} sm={12} className="emailContainer">
            <input
              type="text"
              id="emailFld"
              maxLength="50"
              className={
                errorEmail.length
                  ? 'errorField verifyDetailsText'
                  : 'verifyDetailsText'
              }
              value={updatedemail}
              aria-describedby={errorEmail.length ? 'errorMessage' : null}
              aria-required={ariaRequired ? 'true' : 'false'}
              aria-invalid={!!errorEmail}
              aria-labelledby={ariaLabelledby}
              onChange={this.handleEmailChange}
              onBlur={this.handleEmailBlur}
            />
          </Col>
          {errorEmail && (
            <p
              className="errorText"
              id="errorMessage"
              aria-live="polite"
              aria-atomic="true"
              aria-relevant="all"
              role="alert"
            >
              <span className="sr-only">Error:</span>
              {errorEmail}
            </p>
          )}
          <div className="buttonContainer">
            <button
              onClick={this.handleEmailVerify}
              className="vcmbtn emailFldBtn"
              disabled={
                updatedemail === currentEmail
                || receivedEmailOTP
                || isEmpty(updatedemail)
              }
              type="submit"
            >
              <span className="vcmBtnText">Verify Email</span>
            </button>
          </div>
        </div>

        {receivedEmailOTP && (
          <div className="emailOTPContainer">
            <span className="securityCodeInfo">
              You will be receiving an email from our Security Partner, GIACT,
              which contains a security code you will need to continue. If you
              do not receive the email, verify the email you have entered is
              correct.
            </span>
            <label htmlFor="passcode" className="inputLabel">
              Enter Security Code
            </label>
            <input
              value={onetimecode}
              id="passcode"
              maxLength="6"
              ref={this.refPassCodeInput}
              aria-describedby={emailOTPError.length ? 'errorfield' : null}
              aria-invalid={!!emailOTPError}
              className={
                emailOTPError.length
                  ? 'errorField emailPassCodeField'
                  : 'emailPassCodeField'
              }
              required
              type="password"
              onInput={this.handleOTPInput}
              onChange={this.handleOTPChange}
            />

            {emailOTPError && (
              <p
                id="errorfield"
                className="errorText"
                aria-live="polite"
                aria-atomic="true"
              >
                <span className="sr-only">Error:</span>
                {emailOTPError}
              </p>
            )}

            <Col
              className="passCodeWrapper resendBtnPadding d-sm-block"
              xs={12}
              sm={12}
            >
              <input
                type="button"
                className="resendOtp"
                onClick={this.handleResendOTP}
                value="Resend Security Code"
              />
            </Col>
          </div>
        )}
      </>
    );
  }
}

EmailField.propTypes = {
  currentEmail: PropTypes.string,
  field: PropTypes.string,
  errorEmail: PropTypes.string,
  onetimecode: PropTypes.string,
  requestForEmailOTP: PropTypes.func,
  setModifiedEmail: PropTypes.func,
  storeOTP: PropTypes.func,
  setEmailFieldError: PropTypes.func,
  accountInfo: PropTypes.instanceOf(Object),
  receivedEmailOTP: PropTypes.bool,
  emailOTPError: PropTypes.string,
  updatedemail: PropTypes.string,
  ariaRequired: PropTypes.bool,
  ariaLabelledby: PropTypes.string,
};

EmailField.defaultProps = {
  currentEmail: '',
  field: '',
  errorEmail: '',
  onetimecode: '',
  requestForEmailOTP: () => {},
  setModifiedEmail: () => {},
  storeOTP: () => {},
  setEmailFieldError: () => {},
  accountInfo: {},
  receivedEmailOTP: false,
  emailOTPError: '',
  updatedemail: '',
  ariaRequired: false,
  ariaLabelledby: '',
};

const mapStateToProps = (state) => ({
  accountInfo: get(state, 'AccountRegistration.accountInfo', {}),
  receivedEmailOTP: get(state, 'AccountRegistration.receivedEmailOTP', false),
  errorEmail: get(state, 'AccountRegistration.emailError', ''),
  emailOTPError: get(state, 'AccountRegistration.emailOTPError', ''),
  onetimecode: get(state, 'AccountRegistration.OTP', ''),
});

const mapDispatchToProps = {
  requestForEmailOTP: requestEmailOTP,
  setModifiedEmail: setUpdatedEmail,
  storeOTP: setEmailOTP,
  setEmailFieldError: setEmailError,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailField);
