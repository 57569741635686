const responseData = {
    "accordionData": [
         {
            accordionId: 1,
            accordionTitleText: "Mailing Address",
            accordionText: "1401 S Grand Ave, Los Angeles, CA 90015",
            selected: false,
            cardData:{

            }
         },
         {
            accordionId: 2,
            accordionTitleText: "Email Address",
            accordionText: "paul_charlie@domain.com",
            selected: false,
            cardData:{

            }
            
         },
         {
            accordionId: 3,
            accordionTitleText: "Primary Mobile Number",
            accordionText: "+1 (xxx) xxx - 7890",
            selected: false,
            cardData:{

            }
            
         },
        
    ]
}

export default responseData;


