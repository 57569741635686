import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';
import { VCMSelectWithoutLabel } from '../../../common';

export const Suffix = (props) => {
    const { changed, suffixState, suffix, disable } = props;
    const data = {
        config: {
            id: "suffix",
            label: 'Name Suffix',
            optional: '[ Optional ]',
            options: [
                ...suffixState.options
            ]
        },
        change: event => changed(event, 'suffix')
    };
    if (suffix !== null && suffix !== undefined) {
        data.config.options.push(...suffix.value);
    }

    let inputElement = null;

    inputElement = <VCMSelectWithoutLabel
        id={data.config.id}
        value={suffixState.value}
        onChange={data.change}
        itemlist={data.config.options}
        disabled={disable}/>;

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor={data.config.id}>
                {data.config.label}<span className={classes.optional}>{data.config.optional}</span>
            </label>
            {inputElement}
        </div>
    );
};

Suffix.propTypes = {
    suffix: PropTypes.shape({
        value: PropTypes.instanceOf(Array)
    }),
    suffixState: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        options: PropTypes.instanceOf(Array)
    }),
    disable: PropTypes.bool,
    changed: PropTypes.func
};

Suffix.defaultProps = {
    suffix: null,
    suffixState: {
        value: '',
        valid: true,
        options: []
    },
    disable: false,
    changed: () => { }
};

export default Suffix;