import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Styles from './Styles';
import ACCESS_FORMS_CONSTANTS from './Constants';

const FormsHeader = props => {
    const { getAccessForms } = props;

    const [tags, setTags] = useState([
        {
            id: '1',
            label: 'Frequently Used',
            value: 'Popular',
            selected: true,
        },
        {
            id: '2',
            label: 'All Forms',
            value:'all form',
            selected: false,
        },
    ]);

    const freePDFtext = () => {
        return (
            <Styles.SubTitle>
                {ACCESS_FORMS_CONSTANTS.FREEPDF_1}
                <Styles.Link target='_blank' rel="noopener noreferrer" href={ACCESS_FORMS_CONSTANTS.PRF_URL}>{ACCESS_FORMS_CONSTANTS.PDF}</Styles.Link>
                {ACCESS_FORMS_CONSTANTS.FREEPDF_2}
            </Styles.SubTitle>
        );
    };

    const getKey = (key, index) => {
        return `${key}-${index}`;
    };

    const formsBoxArrow = {        
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 16px',
        border: '.5px solid #49494a',
        background: '0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)',
        borderColor: 'rgba(73, 73, 74, 0.867)',

        ":after": {
            content: ' ',
            position: 'absolute',
            width: '10px',
            height: '10px',
            borderTop: '0px solid #49494a',
            borderRight: '1px solid #49494a',
            borderLeft: '0px solid #49494a',
            borderBottom: '1px solid #49494a',
            top: '100%',
            left: '37%',
            marginLeft: '0px',
            transform: 'rotate(45deg)',
            marginTop: '-5px',
            background: 'white'
        }
    };

    function toggleActive(e) {
        const id = e.target.getAttribute('data-id');

        const newTags = tags.map(tag => {
            const newTag = tag;
            newTag.selected = tag.id === id;
            return newTag;
        });

        getAccessForms({ searchPayload: { tag: `${e.target.getAttribute('data-value')}`}, authToken: localStorage.getItem('token') });
        
        setTags(newTags);
    }

    return (
        <>
            <Row data-testid='form-title'>
                <Styles.FormTitle sm={1}>{ACCESS_FORMS_CONSTANTS.FORM_TITLE}</Styles.FormTitle>
            </Row>
            <Row data-testid='pdf-download-link'>
                <Col sm={12}>{freePDFtext()}</Col>
            </Row>
            <Row>
                {tags.map((element, index) => (
                    <Col sm={2} key={getKey('popular-col', index)}>
                        {!element.selected && (
                            <Styles.BoxLink
                                src="#"
                                key={getKey('popular', index)}
                                variant="link"
                                onClick={toggleActive}
                                data-id={element.id}
                                data-testid={element.id}
                                data-value={element.value}
                            >
                                {element.label}
                            </Styles.BoxLink>
                        )}
                        {element.selected && 
                            <Styles.BoxArrowDown
                                style={ formsBoxArrow }                                
                            >
                                {element.label}
                            </Styles.BoxArrowDown>}
                    </Col>
                ))}
            </Row>
            <Styles.FormHeader data-testid='form-header-cols'>
                <Col sm={2}>{ACCESS_FORMS_CONSTANTS.FORM_ID}</Col>
                <Col sm={3}>{ACCESS_FORMS_CONSTANTS.CATEGORY_NAME}</Col>
                <Col sm={5}>{ACCESS_FORMS_CONSTANTS.FORM_NAME}</Col>
            </Styles.FormHeader>
        </>
    );
};

FormsHeader.propTypes = {
    getAccessForms: PropTypes.func,
};

FormsHeader.defaultProps = {
    getAccessForms: () => {},
};

export default FormsHeader;
