import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    genericidLookupData:null,
    vcmidErrorMessage: '',
    vcmUsaaIdVerificationStatus: false,
    verifiedVcmUsaaIdInfo: {}
};

const setRiverWalkEnabledFlag  = (configArray) =>
{
    let result = false;
    const foundIndex = configArray.findIndex((item) => item.key === 'RIVERWALK_FLAG')
    if (foundIndex > -1) {
        const isReleased = configArray[+foundIndex].value
        if(isReleased == true || isReleased == 'TRUE' )
        {
            result = true;
        }
    }

    //setRiverWalkFlag(result)
    return result;
}

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_LOADER: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_ACTION_GET_ACCOUNT_RECOVER_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_ACTION_GET_ACCOUNT_RECOVER_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_ACTION_SET_ACCOUNT_RECOVER_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_ACTION_SET_ACCOUNT_RECOVER_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_GET_QUICK_SIGN_IN_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_GET_QUICK_SIGN_IN_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_QUICK_SIGN_IN_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_QUICK_SIGN_IN_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_GET_SIGN_IN_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_GET_SIGN_IN_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_SIGN_IN_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_GET_SIGN_IN_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_SIGN_IN_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_SIGN_IN_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SAVE_SECURITY_QUESTIONS_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SAVE_SECURITY_QUESTIONS_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SAVE_SECURITY_QUESTIONS_CLEAR: {
            return { ...state, ...action };
        }

        case ActionTypes.USER_SET_DOCUMENT_PREFERENCE_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_DOCUMENT_PREFERENCE_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.USER_SET_DOCUMENT_PREFERENCE_CLEAR: {
            return { ...state, ...action };
        }

        case ActionTypes.SIGN_IN_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_IN_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_EMAIL_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_EMAIL_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_EMAIL_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_OTP: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_OTP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_OTP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_OTP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFY_OTP: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFY_OTP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFY_OTP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFY_OTP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_EMAIL_LINK: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_EMAIL_LINK_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_EMAIL_LINK_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.SEND_EMAIL_LINK_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFIED_EMAIL_LINK: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFIED_EMAIL_LINK_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFIED_EMAIL_LINK_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.VERIFIED_EMAIL_LINK_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_TYPE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_TYPE_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_TYPE_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_ONLINE_ID_TYPE_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_VERIFY_MEMBER_ID_CLEAR: {
            return { ...state, ...action,
                vcmidErrorMessage: '',
                vcmUsaaIdVerificationStatus: false,
                verifiedVcmUsaaIdInfo: {}
            };
        }
        case ActionTypes.DIRECT_VERIFY_MEMBER_ID: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_VERIFY_MEMBER_ID_SUCCESS: {
            return { ...state, ...action,
                vcmidErrorMessage: '',
                vcmUsaaIdVerificationStatus: true,
                verifiedVcmUsaaIdInfo: action.data
            };
        }
        case ActionTypes.DIRECT_VERIFY_MEMBER_ID_ERROR: {
            return { ...state, ...action,
                vcmidErrorMessage: 'technicalerror',
                vcmUsaaIdVerificationStatus: false,
                verifiedVcmUsaaIdInfo: {}
             };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_CUSTOMER: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_CUSTOMER_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_CUSTOMER_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_CUSTOMER_CLEAR: {
            return { ...state, ...action };
        }

        case ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_CLEAR: {
            return { ...state, ...action };
        }

        case ActionTypes.VALIDATE_SECURITY_QUESTIONS: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_SECURITY_QUESTIONS_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_SECURITY_QUESTIONS_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.VALIDATE_SECURITY_QUESTIONS_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_SAVED_SECURITY_QUESTIONS: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_SAVED_SECURITY_QUESTIONS_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_SAVED_SECURITY_QUESTIONS_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_SAVED_SECURITY_QUESTIONS_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_CUSTOMER_PROFILE: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_CUSTOMER_PROFILE_SUCCESS: {
            return { ...state, ...action, lastLogin: action.getCustomerProfile.lastlogintime };
        }
        case ActionTypes.GET_CUSTOMER_PROFILE_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.GET_CUSTOMER_PROFILE_CLEAR: {
            return { ...state, ...action };
        }

        case ActionTypes.CHANGE_FLOW_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.CHANGE_FLOW_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.CHANGE_FLOW_FAILURE: {
            return { ...state, ...action };
        }

        case ActionTypes.RESEND_OTP_CUSTOMERS_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RESEND_OTP_CUSTOMERS_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RESEND_OTP_CUSTOMERS_FAILURE: {
            return { ...state, ...action };
        }

        case ActionTypes.LOGIN_ATTEMPTS_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.LOGIN_ATTEMPTS_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.LOGIN_ATTEMPTS_FAILURE: {
            return { ...state, ...action };
        }

        case ActionTypes.SIGN_UP_VERFIY_ONLINEID_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP_VERFIY_ONLINEID_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.SIGN_UP_VERFIY_ONLINEID_FAILURE: {
            return { ...state, ...action };
        }

        case ActionTypes.RESET_FLOW_GUEST_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW_GUEST_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.RESET_FLOW_GUEST_FAILURE: {
            return { ...state, ...action };
        }

        case ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY: {
            return { ...state, ...action };
        }
        case ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_FAILURE: {
            return { ...state, ...action };
        }
        case ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_ID_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_ID: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_ID_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_ID_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_VERIFY_OTP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_VERIFY_OTP: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_VERIFY_OTP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_VERIFY_OTP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_STATUS_VERIFY: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SIGN_UP_CLEAR: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SIGN_UP: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SIGN_UP_SUCCESS: {
            return { ...state, ...action };
        }
        case ActionTypes.DIRECT_MEMBER_SIGN_UP_ERROR: {
            return { ...state, ...action };
        }
        case ActionTypes.GENERICID_LOOKUP_SUCCESS: {
            return { 
                ...state, 
                ...action,
                genericidLookupData: action?.response
                 };
        }
        case ActionTypes.GENERICID_LOOKUP_ERROR: {
            return { 
            ...state, ...action,
            genericidLookupData :{
                error:true
            } 
        };
        }
        case ActionTypes.GET_APPLICATION_CONFIG_SUCCESS:{
            let isRiverWalkEnabledLocal = false;
            
            if(action?.data?.response?.data?.result && action?.data?.response?.data?.result?.length > 0)
            {
                isRiverWalkEnabledLocal = setRiverWalkEnabledFlag(action?.data?.response?.data?.result);
            }
            return {
                ...state,
                ...action,
                isLoading:false,
                isSuccess:true,
                isError:false,
                isRiverWalkEnabled:isRiverWalkEnabledLocal
            };
        }
        case ActionTypes.GET_APPLICATION_CONFIG_ERROR:{
            return {
                ...state,
                ...action,
                isLoading:false,
                isSuccess:false,
                isError:true
            };
        }
        default:
            return state;
    }
}

export default loginReducer;
