const accountTypePage = 'Account Type';

export default {
    assignObj: obj => {
        return obj;
    },
    accountOpeningPages: [
        accountTypePage,
        'Personal Info',
        'Fund Selection',
        'Investment Selection',
        'Preferences',
        'Verify'
       
    ],
    marketAccountOpeningPages: [
        accountTypePage,
        'Personal Info',
        'Regulatory',
        'Account Features',
        'Verify'
    ],
    marketAccountOpeningPagesIncFunding: [
        accountTypePage,
        'Personal Info',
        'Regulatory',
        'Account Features',
        'Account Funding',
        'Verify'
        
    ],
    child529AccountOpeningPages: [
        accountTypePage,
        'ESA (Electronic Services Agreement)',
        'Application - Part 1 (Personal & Beneficiary Info)',
        'Personal Information',
        'Beneficiary Information',
        'Application - Part 2 (Choose your Portfolio)',
    ],
    paperFlowPages: ['Account Type', 'Agreement', 'Privacy Info', 'Application', 'Upload/Mail'],
    getPageHeading: accSelected => {
        let pageHeaderText = '';
        switch (accSelected) {
            case 'Individual':
                pageHeaderText = 'Individual Account';
                break;
            case 'Joint Tenants in Common':
            case 'Joint Tenants by Entirety':
            case 'Joint Tenants with Rights of Survivorship':
                pageHeaderText = 'Joint Account';
                break;
            case 'Traditional IRA':
            case 'Roth IRA':
            case 'Rollover IRA':
            case 'SEP IRA':
            case 'Simple IRA':
            case '403b':
                pageHeaderText = 'Retirement Account';
                break;
            case '529 Education Savings Plan':
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                pageHeaderText = 'Child Account';
                break;
            // case '529 College Saving Plan':
            //     pageHeaderText = '529 Education Savings Plan Account';
            //     break;
            default:
                pageHeaderText = 'Individual Account';
                break;
        }

        return pageHeaderText;
    },
    horizontalStyle: {
        display: 'flex',
        flexDirection: 'row',
    },
    optionalText: '[Optional]',
    accountTypeBackLabel: 'Back To Open an Account',
    personalInfoBackLabel: 'Back To Account Type',
    countryCodePhone: '+1',
    phHint: 'XXX-XXX-XXXX',
    CANCEL_APPLICATION_MSG_BODY:
        'You will lose any data you have not saved for this application. If you wish to save this, please click cancel and save your application before you exit. Are you sure you want to proceed?',
    CANCEL_APPLICATION_MSG_TITLE: 'Cancel Application',
    cancelOffset: {
        span: 2,
        offset: 6,
    },
    CUSTOMER_VERIFICATION_MODAL_TITLE: 'Verify Details',
    ACCOUNT_OPENING_NOT_ALLOWED_TITLE: 'Please follow the instructions below',
    ACCOUNT_OPENING_NOT_ALLOWED_ERROR_MESSAGE:
        'This account type is not available in your state, please consider opening a different type of account. Please contact a Member Service Representative (800) 235-8396 if you need further assistance.',
    JOINT_TENANTS_ACCOUNT_TYPE: 'Joint Tenants With Right of Survivorship',
};
