import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import * as Constants from './Constants';
import { WBreadCrumb, WMessageBar, ConformationModal, WSpinner } from '../../../common';
import * as Styles from './Styles';
import WButton from '../../../common/WButton/WButton';
import { getTopics, getAccounts, sendSecureMessage } from '../../../shared/Actions/MessageCenterActions';
import { formatMessageCenterDate, formatTimeAMPM } from '../../../utils/utils';
import Topics from './Topics';
import Accounts from './Accounts';
import Subject from './Subject';
import MessageBody from './MessageBody';
import Attachments from './Attachments';

const breadCrumbValues = [
    { url: '/accountHolder', name: 'Home' },
    { url: Constants.MESSAGE_CENTER_ROUTE, name: 'Message Center' },
];

function SecureMessageComponent(props) {
    const dispatch = useDispatch();
    const topics = useSelector(state => state.messageCenterData.topics);
    const accounts = useSelector(state => state.messageCenterData.accounts);
    const sentSecureMessage = useSelector(state => state.messageCenterData.sentSecureMessage);
    const isLoading = useSelector(state => state.messageCenterData.isLoading);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const history = useHistory();
    const { confirmation } = sentSecureMessage;
    const successMessage = useCallback({ message: confirmation });
    const { location } = props;
    const { state = {} } = location;
    const { replyMessage = {} } = state;
    const isItReplyMessage = replyMessage.topic !== undefined;
    const [showModal, setShowModal] = useState(false);
    const messageBodyText = useRef();

    const inlineStyles = {
        multiSelect: { width: 14, height: 14, marginLeft: '20px' },
        subjectInput: { marginLeft: 5 },
        messageBody: { marginLeft: 5, height: 195 },
        submitButtonDiv: { span: 2, offset: 8 },
        submitButtonLabel: { paddingRight: 0, marginLeft: 22 },
        backButtonStyle: { marginTop: 10 },
    };

    const deafultAccounts = replyMessage?.accounts
        ? replyMessage?.accounts.map((account, index) => {
              return { key: index, value: account };
          })
        : [];

    const formatReplyMessage = message => {
        const { skCreatedAt, messageSecureText } = message;
        const timestamp = skCreatedAt.split('-')[1];
        const DATE = formatMessageCenterDate(timestamp);
        const TIME = formatTimeAMPM(timestamp);

        const lineBreak = '-----------------------------------------------------';
        return `\n\n${lineBreak}\nReceived On ${DATE} ${TIME}\n${lineBreak}\n${messageSecureText}`;
    };

    const initialState = {
        topic: replyMessage.topic ? replyMessage.topic : '',
        accounts: deafultAccounts,
        subject: replyMessage.subject ? `Re: ${replyMessage.subject}` : '',
        messageBody: replyMessage.messageSecureText ? formatReplyMessage(replyMessage) : '',
        attachments: [],
    };

    const [isFormSubmited, setIsFormSubmited] = useState(false);

    const [subject, setSubject] = useState({
        value: initialState.subject,
        error: '',
    });
    const [messageBody, setMessageBody] = useState({
        value: initialState.messageBody,
        error: '',
    });

    const [selectedAccounts, setAccount] = useState({
        value: initialState.accounts,
        error: '',
    });
    const [selectedTopic, setTopic] = useState({
        value: initialState.topic,
        error: '',
    });
    const [attachedFiles, setAttachment] = useState([]);

    const [rejectedFiles, setRejectedfiles] = useState([]);

    const validateFormFields = () => {
        let validForm = true;

        if (!isItReplyMessage && subject.value.length === 0) {
            setSubject({ ...subject, error: Constants.FORM_VALIDATION.SUBJECT });
            validForm = false;
        }

        if (!isItReplyMessage && subject.value.trim().length < 2) {
            setSubject({ ...subject, error: Constants.FORM_VALIDATION.SUBJECT_MIN });
            validForm = false;
        }

        if (!isItReplyMessage && subject.value.length > 50) {
            setSubject({ ...subject, error: Constants.FORM_VALIDATION.SUBJECT_MAX });
            validForm = false;
        }

        if (messageBody.value.trim().length === 0) {
            setMessageBody({ ...messageBody, error: Constants.FORM_VALIDATION.MESSAGE_BODY });
            validForm = false;
        }

        if (messageBody.value.replace(/ /g, '').trim().length <= 4) {
            setMessageBody({ ...messageBody, error: Constants.FORM_VALIDATION.MESSAGE_BODY_MIN });
            validForm = false;
        }

        if (!isItReplyMessage && messageBody.value.trim().length > 500) {
            setMessageBody({ ...messageBody, error: Constants.FORM_VALIDATION.MESSAGE_BODY_MAX });
            validForm = false;
        }

        if (!isItReplyMessage && selectedTopic.value.length === 0) {
            setTopic({ ...selectedTopic, error: Constants.FORM_VALIDATION.TOPIC });
            validForm = false;
        }
        return validForm;
    };

    const createParentMessageId = () => {
        const { parentMessageId } = replyMessage;
        return !parentMessageId ? replyMessage.skCreatedAt : parentMessageId;
    };

    const createPayLoad = () => {
        const formData = new FormData();

        attachedFiles.forEach(file => {
            formData.append(`attachments`, file);
        });

        formData.append('subject', subject.value);
        formData.append('accounts', selectedAccounts.value ? selectedAccounts.value.map(account => account.value) : []);
        formData.append('topic', selectedTopic.value);
        formData.append('messageSecureText', messageBody.value);
        formData.append('skCreatedAt', Date.now());

        if (isItReplyMessage) {
            formData.append('parentMessageId', createParentMessageId());
        }

        return formData;
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    function handleClose() {
        setSubmitButtonClicked(false);
        history.push(Constants.MESSAGE_CENTER_ROUTE);
    }

    function submitSecureMessage() {
        if (validateFormFields()) {
            setIsFormSubmited(true);
            const data = createPayLoad();
            dispatch(sendSecureMessage({ data, token: localStorage.getItem('token') }));
            setSubmitButtonClicked(true);
        }
        scrollToTop();
    }

    function setTopicValue({ target }) {
        setTopic({ value: topics.find(({ key }) => key === target.value).value, error: '' });
    }

    function setAccountValue({ list }) {
        setAccount({
            value: list.filter(item => item.selected === true),
            error: '',
        });
    }

    function setSubjectValue(e) {
        setSubject({ value: e.target.value, error: '' });
    }

    function setMessageBodyValue(e) {
        setMessageBody({ value: e.target.value, error: '' });
    }

    const newState = useCallback({
        topic: selectedTopic.value,
        accounts: selectedAccounts.value,
        subject: subject.value,
        messageBody: messageBody.value,
        attachments: attachedFiles,
    });

    function isFormNotModified() {
        return JSON.stringify(initialState) === JSON.stringify(newState);
    }

    function backClick() {
        const setShows = setShowModal(true);
        if (isFormNotModified()) {
            history.push(Constants.MESSAGE_CENTER_ROUTE);
        }
        return setShows;
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    function handleCloseAndRedirect() {
        history.push(Constants.MESSAGE_CENTER_ROUTE);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        const companyNumber = 591;
        dispatch(getTopics({ token }));
        dispatch(getAccounts({ token, companyNumber }));
    }, [dispatch]);

    useEffect(() => {
        if (isItReplyMessage && messageBodyText.current && isFormNotModified()) {
            messageBodyText.current.focus();
        }
    });

    return (
        <>
            {isLoading && <WSpinner loading={isLoading} />}
            {submitButtonClicked && successMessage.message?.length > 0 && (
                <WMessageBar text={successMessage} onClose={handleClose} />
            )}
            <div className="container">
                <WBreadCrumb
                    activeCrumb={isItReplyMessage ? Constants.CREATE_SECURE_REPLY : Constants.CREATE_SECURE_MESSAGES}
                    breadCrumbItems={breadCrumbValues}
                />
                <Styles.RequiredFiledText>
                    <Col sm={12}>{Constants.ALL_FILED_REQUIRED}</Col>
                </Styles.RequiredFiledText>
                <Styles.CreateMessageHeader>
                    {isItReplyMessage && (
                        <Col sm={1}>
                            <Styles.CreateSecureMessageHeader>
                                {Constants.CREATE_SECURE_REPLY}
                            </Styles.CreateSecureMessageHeader>
                        </Col>
                    )}
                    {!isItReplyMessage && (
                        <Col sm={4}>
                            <Styles.CreateSecureMessageHeader>
                                {Constants.CREATE_SECURE_MESSAGES}
                            </Styles.CreateSecureMessageHeader>
                        </Col>
                    )}
                </Styles.CreateMessageHeader>
                <Row>
                    <Col sm={12}>
                        <Styles.FormContainer className="analytics-form-fields">
                            <Topics
                                topics={topics}
                                setTopicValue={setTopicValue}
                                isDisabled={isItReplyMessage}
                                errorText={selectedTopic.error}
                                selectedTopic={selectedTopic.value}
                            />
                            <Accounts
                                onChange={setAccountValue}
                                isDisabled={isItReplyMessage}
                                accounts={accounts}
                                selectedAccounts={selectedAccounts.value}
                            />
                            <Subject
                                setSubjectValue={setSubjectValue}
                                isDisabled={isItReplyMessage}
                                errorText={subject.error}
                                subject={subject}
                            />
                            <MessageBody
                                setMessageBodyValue={setMessageBodyValue}
                                messageBody={messageBody}
                                errorText={messageBody.error}
                                ref={messageBodyText}
                                isItReplyMessage={isItReplyMessage}
                            />
                            <Attachments
                                attachedFiles={attachedFiles}
                                setAttachment={setAttachment}
                                rejectedFiles={rejectedFiles}
                                setRejectedfiles={setRejectedfiles}
                            />
                        </Styles.FormContainer>
                    </Col>
                </Row>
                <Styles.FooterDiv>
                    <Col sm={2}>
                        <WButton
                            variant="outline"
                            buttontext="Cancel"
                            onClick={backClick}
                            buttonstyle={inlineStyles.backButtonStyle}
                            data-analytic-id="message_center_create_secure_message_cancel"
                        />
                        {showModal && (
                            <ConformationModal
                                modalTitle={Constants.CONFIRMATION_MODAL.TITLE}
                                modalBodyText={Constants.CONFIRMATION_MODAL.BODY}
                                secondaryButtonText={Constants.CONFIRMATION_MODAL.SECONDARY_BUTTON}
                                onPrimaryClick={handleCloseAndRedirect}
                                onSecondaryClick={handleCloseModal}
                                primaryButtonText={Constants.CONFIRMATION_MODAL.PRIMARY_BUTTON}
                            />
                        )}
                    </Col>
                    <Styles.SubmitButtonCol sm={inlineStyles.submitButtonDiv}>
                        <WButton
                            submit="submit"
                            buttontext="Submit"
                            id="create-secure-submit"
                            aria-label="create-secure-submit"
                            buttonstyle={inlineStyles.submitButtonLabel}
                            onClick={submitSecureMessage}
                            disabled={isFormSubmited}
                            data-analytic-id={`message_center_create_secure_message_${selectedTopic.value}_submit`}
                        />
                    </Styles.SubmitButtonCol>
                </Styles.FooterDiv>
            </div>
        </>
    );
}

SecureMessageComponent.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            replyMessage: PropTypes.instanceOf(Object),
        }),
    }),
};

SecureMessageComponent.defaultProps = {
    location: {
        state: {
            replyMessage: {},
        },
    },
};

export default SecureMessageComponent;
