import React, { Component } from 'react';
import  Constant from './CONST';
import { connect } from 'react-redux';
import { analytics } from '../../shared/Actions';
import '../commonCompStyles.css'


const skipDisclosureContentForPages = (currentPage= '') => {
    const listOfPagesToSkipDisclosure = ['/forms','/portfolioPlannerPdf','/documentCenter']
    return listOfPagesToSkipDisclosure.includes(currentPage)
}

const assignObj = obj => {
    return obj;
};
let CONSTS = Constant
class DashboardDisclosure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginDataCounter:0
        };
    }

    render() { 
        return (
            <div>
                {
                !skipDisclosureContentForPages(this.props?.pathname) ? 
                <div className='existing-disclosure-content' data-test="existing-disclosure-content">

             
                    <h2 style={assignObj({ font: '700 16px/24px benton-sans', color: '#49494A', marginBottom :15})}>
                        {CONSTS.PRINCIPLE}aaaa{this.props.pathname}{this.props.name}
                    </h2>
                    <p style={assignObj({ marginBottom: 0, font: '500 16px/24px benton-sans', color: '#49494A' })}>
                        <span>{CONSTS.PARA1}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA18}</span>
                        <span>
                            <abbr title="Exchange-traded Fund" aria-hidden="true">{CONSTS.ABBR3}</abbr>
                        </span>
                        <span>{CONSTS.PARA19}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>
                            <abbr title="Exchange-traded Fund" aria-hidden="true">{CONSTS.ABBR3}</abbr>
                        </span>
                        <span>{CONSTS.PARA12}</span>
                        <span>
                            <abbr title="Victory Capital Advisers" aria-hidden="true">{CONSTS.ABBR4}</abbr>
                        </span>
                        <span>{CONSTS.PARA20}</span>
                        <span>
                            <abbr title="Financial Industry Regulatory Authority" aria-hidden="true">{CONSTS.ABBR2}</abbr>
                        </span>
                        <span>{CONSTS.PARA21}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA13}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA14}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA15}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA16}</span>
                        <span>
                            <abbr title="United Services Automobile Association" aria-hidden="true">{CONSTS.ABBR1}</abbr>
                        </span>
                        <span>{CONSTS.PARA17}</span>
                    </p>
                     <p style={assignObj({ font: '500 16px/24px benton-sans', color: '#49494A' })}>
                        <span>{CONSTS.PARA2}</span> 
                     </p>
                </div>
                :
                <div className='hide-disclosure-content' data-testid="hide-disclosure-content"></div>
                }
            </div>
        )
    }
    
}

const mapStateToProps = state => ({
});

export const mapDispatchToProps = {
    ...analytics,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DashboardDisclosure);
