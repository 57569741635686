import * as TransactionConstants from '../../TransactionConstants';
import classes from '../Classes.module.css';
import { connect } from 'react-redux';
import ErrorBoundary from '../../Common/ErrorBoundary/errorboundary';
import { getToken } from 'utils';
import { MessagePopup } from 'modules/CustomerManagementModule/components';
import PropTypes from 'prop-types';
import { purchaseActions, bankAccountAction } from '../../../../shared/Actions';
import React, { Component } from 'react';
import { ReactComponent as BankIcon } from '../../../../assets/bankNew.svg';
import { ReactComponent as TickMark } from 'assets/blueTick.svg';
import ReactHTMLParser from 'react-html-parser';
import ReInvestComponent from '../../Common/ReInvest/ReInvestComponent';
import { Row, Col, ToggleButton, ButtonGroup, Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
    WButton,
    AccountInfoCard,
    Accordian,
    AccordianTitle,
    ErrorMessageBar,
    WErrorModal,
    VCMSelect,
    WSpinner,
    MaskText,
    ConfirmModal,
    WCard,
    AddBankCardContainer,
    TransactionLimitModal
} from '../../../../common';
// import print from '../../../../assets/print.png';
// import styles from '../InvestmentSelection/styles';
import '../PurchaseStyle.css';

const cancelButtonStylesDesktop = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginTop: '10px',
    marginLeft: '13px',
};

const MandatoryTextDiv = styled.div`
    padding-bottom: 20px;
    font-size: 14px;
`;

const CustomToggleButton = styled(ToggleButton)`
    @media (max-width: 767px) {
        padding: 0;
    }
`;


const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    #cancelButton {
        @media (max-width: 767px) {
            margin: 0 !important;
            padding-right: 0;
        }
    }
    .backButton {
        @media (max-width: 767px) {
            padding-right: 0;
        }
    }
`;

const StyledPADWarningCol = styled(Col)`
    @media (max-width: 767px) {
        padding-left: 0px;
        padding-right: 0px;
    }
    .leftCornerCard {
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: 20px solid #8bc105 !important;
        }
    }
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 19px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;
AccountDetailsFlex.displayName = 'AccountDetailsFlex';

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    .cardHead {
        @media (max-width: 767px) {
            padding: 0;
        }
    }
`;


const FlexColumnDiv1 = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 1rem;
    width: 15rem;
    @media (max-width: 767px) {
        padding-left: 1.25rem;
    }
`;

const FlexRowDiv = styled.div.attrs(({ label, id, selected }) => ({
    'aria-label': label,
    id,
}))`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
`;
const FundFromDesc = styled.h3`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

// const contentJustify = { justifyContent: 'space-between' };
export const BanckAccCard = styled(FlexRowDiv)`
    @media (max-width: 767px) {
        height: auto;
        padding: 30px;
    }
    position: relative;
    cursor: pointer;
    margin-left: ${props => (props.index ? '1.875rem' : '0rem')};
    padding-right: 1px;
    background: ${props =>
        props.selected ? '#FAFAFA 0% 0% no-repeat padding-box' : '#ffffff 0% 0% no-repeat padding-box'};
    border: ${props => (props.selected ? '2px solid #004A98' : '1px solid #d2d2d2')};
    box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
    opacity: 1;
    padding: 25px 0;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0px;
        left: 0px;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')};
        }
    }
    &:hover {
        outline: ${props => (props.selected ? '1px solid #004A98' : '1px solid #3c5a77')};
        @media (max-width: 767px) {
            outline: none;
        }
    }
    &:focus {
        outline: ${props => (props.selected ? '1px solid #004A98' : '5px auto -webkit-focus-ring-color')};
        @media (max-width: 767px) {
            outline: none;
        }
    }
`;

const RowDefine = styled(Row)`
    margin-bottom: 15px;
`;

const AccountNameText = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    margin-top: 3px;
`;
const AccountNumberText = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #49494a;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 0.25rem;
`;

const BankAccDetail = styled(FlexRowDiv).attrs(({ cardId }) => ({ 'aria-describedby': cardId }))`
    justify-content: space-around;
    margin-left: 1.25rem;
    flex-flow: nowrap;
    @media (max-width: 767px) {
        margin-left: 0rem;
        display: block;
    }
    svg {
        @media (max-width: 767px) {
            float: left;
            display: block;
        }
    }
`;
// const FundSourceSubheadingStyle = styled.p`
//     text-align: left;
//     font-family: benton-sans, sans-serif;
//     font-weight: 500;
//     font-size: 14px/22px;
//     font-style: normal;
//     letter-spacing: 0;
//     color: #49494a;
//     opacity: 1;
//     margin-bottom: 40px;
// `;

// const SubHeadingStyle = styled.div`
//     color: #49494a;
//     margin-bottom: 20px;
//     text-align: left;
//     font-family: benton-sans, sans-serif;
//     font-weight: 700;
//     font-style: normal;
//     letter-spacing: 0;
//     opacity: 1;
// `;

const widthStyleFundOpts = { maxWidth: 210, marginRight: 15 };

const IRAContribution = { fontWeight: 'bold', display: 'flex' };
const col1 = '3';

const ButtonStyle = styled.button`
    width: 150px;
    height: 50px;
    opacity: 1;
    text-align: center;
    font: 500 16px/22px Benton Sans;
    letter-spacing: 0;
    border: 1px solid #61285f45;
    &:disabled {
        opacity: 0.5;
    }
`;

const SecondaryButton = styled(ButtonStyle)`
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #544a54;
`;
SecondaryButton.displayName = TransactionConstants.SECONDARY_BUTTON;

const PrimaryButton = styled(ButtonStyle)`
    background: #544a54 0% 0% no-repeat padding-box;
    color: #ffffff;
`;
PrimaryButton.displayName = TransactionConstants.PRIMARY_BUTTON;

const AccountCard = {
    marginTop: '40px',
    marginBottom: '40px',
};

const GreenTick = styled(TickMark)`
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
`;

// const mailigAddress = {
//     whiteSpace: 'pre-wrap',
// };
const backText = TransactionConstants.BACK_TEXT;
const nextText = TransactionConstants.NEXT_TEXT;

export const printText = TransactionConstants.PRINT_TEXT;

export const printStyle = `#offlineFundDetails {
    margin-top: 47px;
    padding: 30px 95px;
    border: 2px solid #8BC105;
    position: relative;
    background-color: #FAFAFA;
}
#offlineFundDetails::before {
    content: "";
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.offlineDetailsHeading {
    margin-bottom: 15px;
    font-size: 22px;
    color: #486D90;
    font-family: "yorkten-slab-normal";
    font-weight: 600;
}
.offlineDetailsSec {
    padding-top: 30px;
    border-top: 1px solid #CECECE;
}
.offlineDetailsSec .row {
    display:flex;
}
.offlineDetailsSec .row:not(.offlineDetailsHint) div {
    width: 50%
}
.offlineDetailsHint {
    margin-top: 40px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 25px 30px 25px 95px;
    font: 600 16px/22px benton-sans;
    position: relative;
}
.offlineDetailsHint::before {
    content: "";
    border-bottom: 25px solid transparent;
    border-left: 25px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.printAction {
    display: none;
}`;

const PADWarning = styled(Row)`
    margin: 20px 0;
`;
const displayNoneStyle = { display: 'none' };
const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const BtnWrap = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    & > :nth-child(2) {
        margin-left: auto;
        margin-right: 50px;
        button {
            margin-bottom: 0 !important;
        }
    }
    @media (max-width: 767px) {
        & > :nth-child(1) {
            order: 3;
        }
        & > :nth-child(2) {
            order: 2;
            margin: 0;
            button {
                margin: 0 !important;
            }
        }
        & > :nth-child(3) {
            order: 1;
            width: 100%;
            button {
                width: 100% !important;
            }
        }
        & > :not(:first-child) {
            margin-bottom: 20px;
        }
        flex-direction: column;
    }
`;

const cardBodyStyle = {
    paddingBottom: 0,
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const assignObj = obj => {
    return obj;
};

const selectPayMethod = TransactionConstants.SELECT_PAYMENT_METHOD;
const notificationMsgFundErr = {
    message: TransactionConstants.NOTIFICATION_ERROR_MESSAGE,
};

// Get previous year
const prevYear = new Date().getFullYear() - 1;

const compositeDataKeys = [
    TransactionConstants.WF_MAILING_ADDRESS,
    `${TransactionConstants.IRA_CONTRIBUTION_LIMIT}${prevYear}`,
];

class FundSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankAccounts: [],
            switchOn: true,
            switchOff: false,
            offlineMethodSelected: false,
            toggleVisibility: false,
            accoCollapse1: true,
            accoCollapse2: true,
            accoCollapse3: true,
            accoCollapse4: true,
            accoCollapse5: true,
            retrivedState: false,
            payMethods: {
                check: false,
                wireTransfer: false,
                newAcc: false,
                bankAcc: false,
            },
            asText: true,
            ReInvestSwitchOn: false,
            ReInvest: true,
            IRAContributionYear: '',
            showErrorModal: false,
            modalAlertMessage: '',
            displayNotification: false,
            transCompositeData: [],
            achAccepted: true,
            achCustMessage: '',
            showConfirmModal: false,
            showAddBankConfirmModal: false,
            checkPurcheaseLimit : false,
            checkPurcheaseLimitPass : false
        };
        this.investToRef = React.createRef();
        this.fundFromRef = React.createRef();
        this.onlineAddBankAccOpenRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            savedState,
            bankAccountReducerData,
            transactionReducerData,
            purchaseData,
            onSwitchComponent,
        } = nextProps;
        const { transCompositeData } = prevState;
        if (purchaseData.goToNextPage) {
            onSwitchComponent(true, prevState, 2)();
        }

        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                payMethods: savedState.payMethods,
                bankAccounts:
                    savedState.bankAccounts.length === bankAccountReducerData.bankDetails.length
                        ? savedState.bankAccounts
                        : bankAccountReducerData.bankDetails,
                transCompositeData: savedState.transCompositeData.length
                    ? savedState.transCompositeData
                    : transactionReducerData,
                achAccepted: purchaseData.achAccepted,
                achCustMessage: purchaseData.custMessage,
                showAddBankConfirmModal: bankAccountReducerData.apiResponse === TransactionConstants.SUCCESS,
            };
        }
        return {
            ...prevState,
            isLoading: bankAccountReducerData.isLoading,
            displayNotification: bankAccountReducerData.isError,
            bankAccounts: bankAccountReducerData.bankDetails,
            transCompositeData: transCompositeData.length ? transCompositeData : transactionReducerData,
            accountNumber: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[1].value,
            accountName: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[0].value,
            achAccepted: purchaseData.achAccepted,
            achCustMessage: purchaseData.custMessage,
            showAddBankConfirmModal: bankAccountReducerData.apiResponse === TransactionConstants.SUCCESS,
            accountType: purchaseData.page0 && purchaseData.page0.displayDetails.accountSelection.data[2].value,
            accountTypeCode:
                purchaseData.page0 &&
                purchaseData.page0.accountSelected &&
                purchaseData.page0.accountSelected.accountTypeCode,
            socialCode:
                purchaseData.page0 &&
                purchaseData.page0.accountSelected &&
                purchaseData.page0.accountSelected.socialCode,
            totalSubAmt: purchaseData.page1 && purchaseData.page1.totalSubAmt,
            monthlyAmount:
                purchaseData.page1 &&
                purchaseData.page1.totalMnlyInv *
                    (12 - parseInt(new Date(purchaseData.page1.selectedAccounts[0].startDate).getMonth())),
            startDatee: purchaseData.page1 && purchaseData.page1.selectedAccounts[0].startDate,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        sessionStorage.setItem(TransactionConstants.STEPNAME_VALUE, TransactionConstants.FUNDSOURCE_VALUE);
        document.getElementById('skipToMain')?.focus();
        document.title = 'Funding source: Buy | Victory Capital';
        const { getTransactionCompositeData, getBankDetails, setTradingWarning, purchaseData} = this.props;
        const { transCompositeData,bankAccounts } = this.state;

        if(!bankAccounts.length)
        {
            getBankDetails({ token: getToken() });
        }
        
        if (!transCompositeData.length) {
            getTransactionCompositeData({ data: { datakey: compositeDataKeys }, token: getToken() });
        }
        this.setState({
            payMethods: {
                check: false,
                wireTransfer: false,
                newAcc: false,
                bankAcc: false,
            },
        });


        if(bankAccounts.length >0 )
        {
            this.setState({
                payMethods: {
                    check: false,
                    wireTransfer: false,
                    newAcc: false,
                    bankAcc: true,
                },
                ReInvest: purchaseData.page2.ReInvest,
                ReInvestSwitchOn: purchaseData.page2.ReInvestSwitchOn,
                switchOn: purchaseData.page2.switchOn,
                switchOff: purchaseData.page2.switchOff,
                IRAContributionYear:purchaseData.page2.IRAContributionYear
            });
        }
        if(this.props.purchaseData !=undefined &&  this.props.purchaseData.page2 !=undefined &&
            this.props.purchaseData.page2.payMethods !=undefined &&  this.props.purchaseData.page2.payMethods.check !=undefined &&  this.props.purchaseData.page2.payMethods.check == true ){
          
          if(this.props.purchaseData.page1.fundOptionSelected == 'sub_mon' || this.props.purchaseData.page1.fundOptionSelected == 'init_mon'){
            this.setState({
                payMethods: {
                    check: false,
                    wireTransfer: false,
                    newAcc: false,
                    bankAcc: false,
                },
                offlineMethodSelected: false,
                
            })
            
          }
          else{
            this.setState({
                payMethods: {
                    check: true,
                    wireTransfer: false,
                    newAcc: false,
                    bankAcc: false,
                }
            })
          }
            
        }
       else if(this.props.purchaseData !=undefined &&  this.props.purchaseData.page2 !=undefined &&
        this.props.purchaseData.page2.payMethods !=undefined && this.props.purchaseData.page2.payMethods.check !=undefined && this.props.purchaseData.page2.payMethods.wireTransfer == true){
           
            if(this.props.purchaseData.page1.fundOptionSelected == 'sub_mon' || this.props.purchaseData.page1.fundOptionSelected == 'init_mon'){

                this.setState({
                    payMethods: {
                        check: false,
                        wireTransfer: true,
                        newAcc: false,
                        bankAcc: false,
                    },
                    offlineMethodSelected: false,
                })
            }
           else{
            this.setState({
                payMethods: {
                    check: false,
                    wireTransfer: true,
                    newAcc: false,
                    bankAcc: false,
                }
            })
           }
           
        }
        
        setTradingWarning();
    }

    switchOnMethod = name => e => {
        e.preventDefault();
        this.analyzeClick(name);
        this.setState(previousState => ({
            ...previousState,
            switchOn: false,
            switchOff: true,
            toggleVisibility: false,
            ReInvest: false,
            offlineMethodSelected: false,
        }));
    };

    switchOffMethod = name => e => {
        e.preventDefault();
        this.analyzeClick(name);
        this.setState(previousState => ({
            ...previousState,
            switchOn: true,
            switchOff: false,
            toggleVisibility: true,
            ReInvest: true,
            offlineMethodSelected: false,
        }));
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    analyzeClick = name => e => {
        const { analyzeClickEvent } = this.props;
        let linkText = name;
        if (e && e.target) {
            linkText = e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id');
        }
        analyzeClickEvent({
            link: linkText,
        });
    };

    onOfflineMethodSelection = payMethod => e => {
        this.analyzeClick(e);
        this.setState(previousState => ({
            ...previousState,
            ReInvest: true,
            switchOn: true,
            switchOff: false,
            toggleVisibility: true,
        }));
        switch (payMethod) {
            case 'check': {
                this.removeBankAccClickHandler(TransactionConstants.CHECK_CLICK_HANDLER);
                break;
            }
            case 'wireTransfer': {
                this.removeBankAccClickHandler(TransactionConstants.WIRETRANSFER_CLICK_HANDLER);
                break;
            }
            default: {
                return null;
            }
        }
    };

    removeBankAccClickHandler = val => {
        try {
            const { bankAccounts } = this.state;
            const { cancelACHLimit } = this.props;
            const accounts = [...bankAccounts];
            const acc1 = accounts.map(acc => {
                acc.selected = false;
                return acc;
            });
            cancelACHLimit();
            if (val === TransactionConstants.CHECK_CLICK_HANDLER) {
                this.setState({
                    bankAccounts: acc1,
                    payMethods: {
                        check: true,
                        wireTransfer: false,
                        newAcc: false,
                        bankAcc: false,
                    },
                    ReInvestSwitchOn: true,
                    offlineMethodSelected: true,
                });
            } else if (val === TransactionConstants.WIRETRANSFER_CLICK_HANDLER) {
                this.setState({
                    bankAccounts: acc1,
                    payMethods: {
                        check: false,
                        wireTransfer: true,
                        newAcc: false,
                        bankAcc: false,
                    },
                    ReInvestSwitchOn: true,
                    offlineMethodSelected: true,
                });
            } else if (val === TransactionConstants.INVESTMENT_SELECTION_TEXT) {
                this.setState({
                    bankAccounts: acc1,
                    payMethods: {
                        check: false,
                        wireTransfer: false,
                        newAcc: false,
                        bankAcc: false,
                    },
                    ReInvestSwitchOn: true,
                    offlineMethodSelected: false,
                });
            }
        } catch (error) {
            this.scrollToTop();
            this.setState({ displayNotification: true });
        }
    };

    yearChangeHandler = e => {
        this.setState({ IRAContributionYear: e.target.value });
    };

    onBankAccClickHandler = (accNumber, salesforceRecordId) => e => {
        e.stopPropagation();
        this.analyzeClick(e);
        const { bankAccounts, accountNumber } = this.state;
        const { purchaseData, validateACHLimit } = this.props;
        const accounts = [...bankAccounts];
        let selectedAccount = {};
        let acc1 = '';
        if (accNumber === '') {
            acc1 = accounts.map(acc => {
                acc.selected = false;
                return acc;
            });
            this.setState({
                bankAccounts: acc1,
                payMethods: {
                    check: false,
                    wireTransfer: false,
                    newAcc: true,
                    bankAcc: false,
                },
                offlineMethodSelected: false,
                errors: {
                    payMethods: '',
                    ReInvest: '',
                   
                },
            });
        } else {
            acc1 = accounts.map(acc => {
                if (acc.bankAccountNumber === accNumber && acc.salesforceRecordId === salesforceRecordId) {
                    acc.selected = true;
                    selectedAccount = acc;
                } else if (acc.bankAccountNumber !== accNumber || acc.salesforceRecordId !== salesforceRecordId) {
                    acc.selected = false;
                }
                return acc;
            });
            this.setState({
                bankAccounts: acc1,
                payMethods: {
                    check: false,
                    wireTransfer: false,
                    newAcc: false,
                    bankAcc: true,
                },
                offlineMethodSelected: false,
            });
            const validateACHPayload = {
                companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                txnType: TransactionConstants.PUR_TEXT,
                masterReg: accountNumber,
                amount: purchaseData.page1 && purchaseData.page1.totalSubAmt,
            };

            if(purchaseData.page1.totalSubAmt >= TransactionConstants.TRX_MSR_LIMIT)
            {
                this.setState({
                    checkPurcheaseLimitPass : true,
                    checkPurcheaseLimit : true
                })
            }  
  
            validateACHLimit({ validateACHPayload, token: getToken() });
        }
    };

    onAddBankAccClickHandler = e => {
        this.analyzeClick(e);
        const { bankAccounts } = this.state;
        const accounts = [...bankAccounts];
        let acc1 = '';
        acc1 = accounts.map(acc => {
            acc.selected = false;
            return acc;
        });
        this.setState({
            bankAccounts: acc1,
            payMethods: {
                check: false,
                wireTransfer: false,
                newAcc: true,
                bankAcc: false,
            },
            offlineMethodSelected: false,
            errors: {
                payMethods: '',
                ReInvest: '',
            },
        });
    };

    getOnlineBankAccountDetails = () => {
        const onlinePaymentDetails = this.onlineAddBankAccOpenRef.current;
        if (onlinePaymentDetails) {
            const cmpState = { ...onlinePaymentDetails.getState() };
            this.setState(() => {
                return { onlineBankPaymentDetails: cmpState };
            });
            return cmpState;
        }

        return {};
    };

    handleAddBankAccount = e => {
        this.analyzeClick(e);
        const onlinePaymentDetails = this.onlineAddBankAccOpenRef.current;
        const { loginData } = this.props;
        const { getCustomerProfile } = loginData;
        let addBankDetails = {};
        if (onlinePaymentDetails) {
            addBankDetails = { ...onlinePaymentDetails.getState() };
            const { addBankAccountNAOTrans } = this.props;
            const addBankPayload = {
                bankAccountType: addBankDetails.otherBankState.bankAccountType,
                bankAccountNumber: addBankDetails.otherBankState.bankAccountDetails[1].value,
                transitRoutingNumber: addBankDetails.otherBankState.bankAccountDetails[0].value,
                // TODO :: Remove financial institute name
                // financialInstituteName: 'WELLS FARGO BANK NA (ARIZONA)',
                accountOwnersName: `${getCustomerProfile.firstName} ${getCustomerProfile.lastName}`,
            };
            this.setState({ selectedBankAccount: '' });
            addBankAccountNAOTrans({ addBankPayload, token: getToken() });
        }
    };

    handleReInvestChange = e => {
        this.setState({ ReInvest: e.target.value });
    };

    getIsIRA = accountType => {
        return !!(
            accountType === TransactionConstants.TRADITIONAL_IRA ||
            accountType === TransactionConstants.ROTH_IRA ||
            accountType.trim() === TransactionConstants.SEP_IRA ||
            accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
            accountType.trim() === TransactionConstants.ROTH_CONVERSION_IRA ||
            accountType.trim() === TransactionConstants.ROLLOVER_IRA
        );
    };

    validateInitialInvestField = e => {
        const { handleIRAContribution, onSwitchComponent, pageIndex } = this.props;
        const { accountType } = this.state;
        if (this.getIsIRA(accountType)) {
            handleIRAContribution(e, this.state);
        } else {
            onSwitchComponent(true, this.state, pageIndex)();
        }
    };

	getIsSimpleIRADFI = accountType => {
        return (accountType.trim() === TransactionConstants.SIMPLE_IRA_DFI);
    };

    getIsSimpleIRANonDFI = accountType => {
        return (accountType.trim() === TransactionConstants.SIMPLE_IRA_NON_DFI);
    };

    getIsSEPIRA = accountType => {
        return (accountType.trim() === TransactionConstants.SEP_IRA);
    };

    validateForm = e => {
        try {
            const { payMethods, IRAContributionYear, accountType } = this.state;
            const { isIRA } = this.props;
            if (!Object.values(payMethods).includes(true) || payMethods.newAcc === true) {
                this.setState({ showErrorModal: true, modalAlertMessage: selectPayMethod });
              
            }else if (  IRAContributionYear === '' && this.getIsSEPIRA(accountType)) {
                this.setState({showErrorModal:false},
                    this.validateInitialInvestField(e)
                );
            }
            else if (  IRAContributionYear === '' && this.getIsSimpleIRADFI(accountType)) {
                this.setState({showErrorModal:false},
                    this.validateInitialInvestField(e)
                );
            }
            else if (  IRAContributionYear === '' && this.getIsSimpleIRANonDFI(accountType)) {
                this.setState({showErrorModal:false},
                    this.validateInitialInvestField(e)
                );
            }
            else if (IRAContributionYear === '' && isIRA()) {
                this.setState({
                    showErrorModal: true,
                    modalAlertMessage: TransactionConstants.SELECT_CONTRIBUTION_YEAR,
                });
            } else {
                this.validateInitialInvestField(e);
            }
        } catch (error) {
            this.scrollToTop();
            this.setState({ displayNotification: true });
        }
    };

    handleErrorModalClick = val => {
        if (val === TransactionConstants.HANDLEERROR_CLOSE || val === TransactionConstants.HANDLEERROR_HIDE) {
            this.setState({ showErrorModal: false, loading: false });
        }
    };

    printDiv = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const mywindow = window.open('', TransactionConstants.WINDOW_OPEN_PRINT);
        mywindow.document.write('<html><head><title>VCM</title>');
        mywindow.document.write('<style>');
        mywindow.document.write(printStyle);
        mywindow.document.write('</style>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(id).innerHTML);
        mywindow.document.write(
            document.getElementById(id).setAttribute(TransactionConstants.ARIA_LABEL, TransactionConstants.PRINT_OPENS),
        );
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();

        return true;
    };

    getPrevYearTaxDeadline = (taxDeadline, accountType) => {
        const taxDeadlineDateFormat = new Date(taxDeadline);
        const currentDate = new Date();
        let contributionValues = TransactionConstants.CURRENT_YEAR_TEXT;
       
        if (currentDate <= taxDeadlineDateFormat && !(accountType.trim() === TransactionConstants.SEP_IRA || 
        	accountType.trim() === TransactionConstants.SIMPLE_IRA_DFI || 
        	accountType.trim() === TransactionConstants.SIMPLE_IRA_NON_DFI)) {
            contributionValues = TransactionConstants.CONTRIBUTION_OPTIONS;
        }
        return contributionValues;
    };

    getSelectDropdown = (values, IRAContributionYear) => {
        return (
            <VCMSelect
                key={IRAContributionYear}
                name="iraContribution"
                id="iracontribution"
                itemlist={values}
                onChange={this.yearChangeHandler}
                value={IRAContributionYear}
                valuesm={12}
                valuemd={12}
                labelsm={0}
                labelmd={12}
                style={widthStyleFundOpts}
                noGutters
                data-test="ira-selectBox"
            />
        );
    };

    redirectSection = () => {
        this.removeBankAccClickHandler(TransactionConstants.INVESTMENT_SELECTION_TEXT);
    };

    handleSuccessConfirmation = () => {
        const { clearAddBankAccDetails, getBankDetails } = this.props;
        this.setState({
            showAddBankConfirmModal: false,
            payMethods: {
                check: false,
                wireTransfer: false,
                newAcc: false,
                bankAcc: false,
            },
        });
        clearAddBankAccDetails();
        getBankDetails();
    };

    handleCheckPurcheseLimit = ()=>
    {
        this.setState({checkPurcheaseLimit : false})        
    }


    render() {
        const {
            bankAccounts,
            payMethods,
            ReInvestSwitchOn,
            switchOn,
            switchOff,
            ReInvest,
            IRAContributionYear,
            showErrorModal,
            modalAlertMessage,
            isLoading,
            transCompositeData,
            accountName,
            accountNumber,
            showConfirmModal,
            achCustMessage,
            onlineBankPaymentDetails,
            showAddBankConfirmModal,
            offlineMethodSelected,
            accountType,
        } = this.state;
        let { displayNotification } = this.state;
        const { newAcc } = payMethods;
        const {
            onSwitchComponent,
            isIRA,
            purchaseData,
            setCancelPurchase,
            bankAccountReducerData,
            clearAddBankAccDetails,
        } = this.props;

        if (
            bankAccountReducerData.apiResponse === TransactionConstants.DUPLICATE_BANK_ERR_MSG ||
            bankAccountReducerData.apiResponse === 'Error'
        ) {
            displayNotification = false;
        }
        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <MessagePopup
                    modalTitle={TransactionConstants.INSTRUCTIONS_TEXT}
                    modalBodyText={ReactHTMLParser(achCustMessage)}
                    secondaryButtonText={TransactionConstants.OK_TEXT}
                    onSecondaryClick={this.redirectSection}
                    show={showConfirmModal}
                />
                {showAddBankConfirmModal && (
                    <ConfirmModal
                        modalTitle="Add Bank Successful"
                        modalBodyText={bankAccountReducerData.successMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                    />
                )}

                {this.state.checkPurcheaseLimit && (
                    <TransactionLimitModal
                        modalTitle={TransactionConstants.VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION}
                        modalBodyText={TransactionConstants.VALIDATE_TEXT_MSR_WARNING_MESSAGE}
                        modalBodyText2={TransactionConstants.VALIDATE_TEXT_MSR_WARNING_MESSAGE2}
                        modalBodyLink1Text={TransactionConstants.VALIDATE_TEXT_MSR_WARNING_MESSAGE_LINK_TEXT}
                        modalBodyLink1Url={TransactionConstants.OVERRIDE_PROCESS_URL}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleCheckPurcheseLimit}
                    />
                )}
                <div
                    id="#currentPageName"
                    name={TransactionConstants.FUNDSOURCE_VALUE}
                    data-test="Fund-source-component"
                >
                    <div className="container">
                        {displayNotification && <ErrorMessageBar text={notificationMsgFundErr} />}
                        <WErrorModal
                            show={showErrorModal}
                            message={modalAlertMessage}
                            modalClick={this.handleErrorModalClick}
                        />

                        <div style={AccountCard} data-test="account-info-card">
                            <AccountInfoCard accountName={accountName} accountNumber={accountNumber} />
                        </div>
                        <Accordian
                            titleChild={
                                <AccordianTitle
                                    accType={TransactionConstants.FUND_YOUR_ACCOUNT_TEXT}
                                    id="fundAcc"
                                    accIndex={0}
                                    className="fundAccAccordianTitle"
                                />
                            }
                            titleSeperator
                            ref={this.fundFromRef}
                            key="123131"
                            labelledBy="fundAcc"
                            Id={TransactionConstants.FUND_YOUR_ACCOUNT_TEXT}
                        >
                            <FlexColumnDiv>
                                <MandatoryTextDiv>{TransactionConstants.MANDATORY_TEXT}</MandatoryTextDiv>

                                <FundFromDesc data-test="fund-from-desc">
                                    {TransactionConstants.FUND_YOUR_ACCOUNT_DESC_TEXT}
                                </FundFromDesc>
                                {purchaseData.page1 &&
                                purchaseData.page1.fundOptionSelected !== 'init_mon' &&
                                purchaseData.page1.fundOptionSelected !== 'sub_mon' ? (
                                    <div className="cardHead mt-2">

                                    </div>
                                ) : (
                                    <PADWarning>
                                        <StyledPADWarningCol xs={12}>
                                            <WCard
                                                buttonstyle={displayNoneStyle}
                                                cardtitletext={TransactionConstants.PAD_PAYMENT_METHOD_WARNING}
                                                cardtextstyle={cardTextStyle}
                                                cardbodystyle={cardBodyStyle}
                                                cardtitlestyle={cardTitleStyle}
                                                linkcard="true"
                                                leftcornercard={cardBorderStyle}
                                                alt=""
                                                src=""
                                                selected
                                            />
                                        </StyledPADWarningCol>
                                    </PADWarning>
                                )}
                                {/* TODO: BankAccounts check to display OR will not be applicable when 'Add Bank Account' feature is implemented */}
                               
                                <div className="cardHead">

                                    <RowDefine className={classes.equalHeightCard}>
                                       
                                        {newAcc ? (
                                            <div data-test="new-bank-display" className="col-12">
                                                <AddBankCardContainer
                                                    ref={this.onlineAddBankAccOpenRef}
                                                    savedState={onlineBankPaymentDetails}
                                                    handleAdd={this.handleAddBankAccount}
                                                    apiResponse={bankAccountReducerData.apiResponse}
                                                    responseMessage={bankAccountReducerData.responseMessage}
                                                    responseCode={bankAccountReducerData.responseCode}
                                                    clearAddBankData={clearAddBankAccDetails}
                                                    renderingComponent="fundSourceBuy"
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {/* -----------------------------ADD BANK---------------------------------- */}
                                        {bankAccounts &&
                                            bankAccounts.map(acc => {
                                                return (
                                                    <ButtonGroup
                                                        className="col-md-6 col-lg-4 col-12"
                                                        aria-labelledby="group_type_account"
                                                        toggle
                                                    >
                                                        <CustomToggleButton
                                                            type="radio"
                                                            variant="outline-light"
                                                            name="radio"
                                                            style={assignObj({
                                                                width: '100%',
                                                                backgroundColor: '#FFFFFF',
                                                                padding: 0,
                                                            })}
                                                            value={
                                                                acc.bankName !== ''
                                                                    ? acc.bankName
                                                                    : acc.bankAccountNumber
                                                            }
                                                            onChange={this.onBankAccClickHandler(
                                                                acc.bankAccountNumber,
                                                                acc.salesforceRecordId,
                                                            )}
                                                            checked={!!acc.selected}
                                                            //  aria-checked={!!acc.selected}
                                                            className="btn btn-light"
                                                        >
                                                            <BanckAccCard
                                                                onClick={this.onBankAccClickHandler(
                                                                    acc.bankAccountNumber,
                                                                    acc.salesforceRecordId,
                                                                )}
                                                                data-analytic-id="Transactions_Buy_FundSource_BankAccountSelection"
                                                                selected={!!acc.selected}
                                                                key={acc.bankAccountNumber + Math.random()}
                                                                data-test="online-bank-acc"
                                                                label={acc.bankName + ' ' + acc.bankAccountNumber}
                                                                id={acc.bankAccountNumber + Math.random()}
                                                            >
                                                                {!!acc.selected && <GreenTick />}
                                                                <BankAccDetail className={classes.bankAccAlign}>
                                                                    <BankIcon />
                                                                    <span className="sr-only">
                                                                        {acc.bankName}
                                                                        <MaskText
                                                                            text={acc.bankAccountNumber?.toString()}
                                                                            startIndex={0}
                                                                            maskLength={6}
                                                                            id={`${acc.bankAccountNumber}_${acc.salesforceRecordId}`}
                                                                        />
                                                                    </span>
                                                                    <FlexColumnDiv1 aria-hidden="true">
                                                                        <AccountNameText>
                                                                            {acc.bankName}
                                                                        </AccountNameText>
                                                                        <AccountNumberText>
                                                                            <MaskText
                                                                                text={acc.bankAccountNumber?.toString()}
                                                                                startIndex={0}
                                                                                maskLength={6}
                                                                                id={`${acc.bankAccountNumber}_${acc.salesforceRecordId}`}
                                                                            />
                                                                        </AccountNumberText>
                                                                    </FlexColumnDiv1>
                                                                </BankAccDetail>
                                                            </BanckAccCard>
                                                        </CustomToggleButton>
                                                    </ButtonGroup>
                                                );
                                            })}
                                    </RowDefine>
                                </div>
                            </FlexColumnDiv>
                        </Accordian>

                        {purchaseData?.page1?.isProportinateSelected == false && purchaseData.page1 && !purchaseData.page1.selectedAccounts[0].fundAccountNumber && (
                            <ErrorBoundary>
                                <ReInvestComponent
                                    forwardRef={this.investToRef}
                                    ReInvestSwitchOn={ReInvestSwitchOn}
                                    offlineMethodSelected={offlineMethodSelected}
                                    switchOnMethod={this.switchOnMethod}
                                    switchOffMethod={this.switchOffMethod}
                                    switchOn={switchOn}
                                    switchOff={switchOff}
                                    ReInvest={ReInvest}
                                    payMethods={payMethods}
                                    id="reinvest"
                                    accIndex={1}
                                    labelledBy="reinvestDividends"
                                />
                            </ErrorBoundary>
                        )}
                        {isIRA() ? (
                            <Accordian
                                titleChild={
                                    <AccordianTitle
                                        accType="Contribution"
                                        id="iraAcc"
                                        accIndex={2}
                                        className="iraAccAccordianTitle"
                                    />
                                }
                                titleSeperator
                                data-test="ira-accordion"
                                labelledBy="iraAcc"
                            >
                                <>
                                    <div className={IRAContribution}>
                                        <Row>
                                            <Col sm={col1}>
                                            <p className="iraLabel">{accountName.trim() === TransactionConstants.SEP_IRA || 
                                                accountName.trim() ===  TransactionConstants.SIMPLE_IRA_DFI || 
                                                accountName.trim() ===  TransactionConstants.SIMPLE_IRA_NON_DFI ? 
                                             TransactionConstants.EMPLOYER_CONTRIBUTIONS : TransactionConstants.CONTRIBUTION_IRA}</p>
                                            </Col>

                                            
                                            {accountName.trim() === TransactionConstants.SEP_IRA || 
                                                accountName.trim() ===  TransactionConstants.SIMPLE_IRA_DFI || 
                                                accountName.trim() ===  TransactionConstants.SIMPLE_IRA_NON_DFI ? 
                                               ( <Col sm={3}>
                                                     <p className='employerContribution' style={{marginTop:'7'}} >{TransactionConstants.EMPLOYER_YEAR_TEXT[0]}</p>
                                                </Col>)
                                                :(
                                                <Col sm={col1} className="iraContributionSelect">
                                                    {transCompositeData && compositeDataKeys[1] in transCompositeData
                                                        ? this.getSelectDropdown(
                                                            this.getPrevYearTaxDeadline(
                                                                transCompositeData[compositeDataKeys[1]].tax_deadline,
                                                                accountType,
                                                            ),
                                                            IRAContributionYear,
                                                        )
                                                        : this.getSelectDropdown(
                                                            TransactionConstants.CURRENT_YEAR_TEXT,
                                                            IRAContributionYear,
                                                        )}
                                                </Col>)
                                            }
                                            
                                        </Row>
                                    </div>
                                </>
                            </Accordian>
                        ) : (
                            ''
                        )}
                    </div>
                  
                    <MainDiv>
                        <BtnWrap>

                            <div>
                                <WButton
                                    variant="back"
                                    buttontext={backText}
                                    onClick={onSwitchComponent(false, {}, 2)}
                                    id="desktopBackButton"
                                    dataAnalyticId="Transactions_Buy_FundSource_BackButton"
                                />
                            </div>
                            <div>
                                <WButton
                                    variant="cancel"
                                    buttontext="Cancel"
                                    id="desktopFundSrcCancelButton"
                                    buttonstyle={assignObj(cancelButtonStylesDesktop)}
                                    onClick={setCancelPurchase}
                                    dataAnalyticId="Transactions_Buy_FundSource_CancelButton"
                                />
                            </div>
                            <div>
                                <WButton
                                    buttontext={nextText}
                                    onClick={this.validateForm}
                                    data-test="next-button"
                                    id="desktopNextButton"
                                    dataAnalyticId="Transactions_Buy_FundSource_NextButton"
                                />
                            </div>
                        </BtnWrap>
                    </MainDiv>
                </div>
            </>
        );
    }
}

FundSource.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    onSwitchComponent: PropTypes.func,
    pageIndex: PropTypes.number,
    bankAccountReducerData: PropTypes.instanceOf(Object),
    transactionReducerData: PropTypes.instanceOf(Object),
    getTransactionCompositeData: PropTypes.func,
    getBankDetails: PropTypes.func,
    purchaseData: PropTypes.instanceOf(Object),
    validateACHLimit: PropTypes.func,
    setTradingWarning: PropTypes.func,
    cancelACHLimit: PropTypes.func,
    isIRA: PropTypes.func,
    setCancelPurchase: PropTypes.func,
    clearAddBankAccDetails: PropTypes.func,
    addBankAccountNAOTrans: PropTypes.func,
    handleIRAContribution: PropTypes.func,
    loginData: PropTypes.instanceOf(Object),
    analyzeClickEvent: PropTypes.func,
};
FundSource.defaultProps = {
    getTransactionCompositeData: () => {},
    onSwitchComponent: () => {},
    transactionReducerData: {},
    bankAccountReducerData: {},
    pageIndex: 2,
    savedState: null,
    getBankDetails: () => {},
    purchaseData: {},
    validateACHLimit: () => {},
    setTradingWarning: () => {},
    cancelACHLimit: () => {},
    isIRA: () => {},
    setCancelPurchase: () => {},
    clearAddBankAccDetails: () => {},
    addBankAccountNAOTrans: () => {},
    handleIRAContribution: () => {},
    loginData: () => {},
    analyzeClickEvent: () => {},
};
export const mapStateToProps = state => {
    return {
        bankAccountReducerData: state.bankAccountReducerData,
        transactionReducerData: state.transactionCompositeDataReducerData,
        purchaseData: state.purchaseData,
    };
};

export const mapDispatchToProps = {
    cancelACHLimit: purchaseActions.cancelACHLimit,
    addBankAccountNAOTrans: bankAccountAction.addBankAccountNAOTrans,
    clearAddBankAccDetails: bankAccountAction.clearAddBankAccDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(FundSource);