import '../MarketNAOStyles.css'
import { Col, Row } from 'react-bootstrap';
import { Accordian, AccordianTitle, WIcon } from 'common';
import editIcon from '../../../../assets/EditPencil.svg';
import React, { useState, useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import currencyFormatter from 'currency-formatter';
import MarketPersonalInfoComponent from 'modules/AccountManagementModule/MarketPersonalInfoComponent';
import useAutoFocus from './useAutoFocus'
import { getFormattedSSN, getRankValueBasedOnKey, getValue,convertToTitleCase,formatCurrencyAndRemoveDecimalPart,formatSSNValue } from 'utils/utils';

const assignObj = obj => {
  return obj;
};

//For Avoiding div repeatations
export const linesReducer = (verifyHeading, verifyValue) => {
  return (
    <div className="card-list-wrapper">
      <div class="row">
        <div class="label-key col-lg-4 col-md-4 col-sm-12 col-12">
          <span data-testid={`line-reducer-header-${verifyHeading}`}>{verifyHeading}</span>
        </div>
        <div class="label-value col-8"><span data-testid={`line-reducer-value-${verifyValue}`}>{verifyValue}</span></div>
      </div>
    </div>
  )
}


export const mailLineReducer=(addressHeading,addressValue)=>{

return(
  <div className="contactwrap">
    <div class="row no-gutters">
      <h3 class="phy-heading">{addressHeading}</h3>
    </div>
    <div class="phy-heading">
      {addressValue}
    </div>
  </div>
  )
}


export const JointPersonalComponentInfo = (props) => {

  const {jointPersonalEdit, setJointPersonalEdit, editDisabler, showEditButton, personalInfoJoint, relationshipMetaData, decryptedVariables, applicationVCMId } = props

  const jointPersonalRef = useRef(null);
  useAutoFocus(jointPersonalEdit,jointPersonalRef);
  
  const showResidentAlienInformation = () => {

    if(personalInfoJoint?.residentStatus == "Resident Alien"){
      return(
        <>
          <div className='residen-alien-status'>
            <div> {linesReducer("Country Of Citizenship", personalInfoJoint?.countryOfCitizenship)} </div> 
            <div> {linesReducer("U.S. Permanent Resident Card Number", personalInfoJoint?.residentAlienInformation?.permanentResidentCardNumber)} </div>
            <div> {linesReducer("Expiration Date", personalInfoJoint?.residentAlienInformation?.permanentResidentExpirationDate)} </div>
            <div> {linesReducer("Passport Number issued by Country of Citizenship", personalInfoJoint?.residentAlienInformation?.passportNumber)} </div>
            <div> {linesReducer("Passport Expiration Date", personalInfoJoint?.residentAlienInformation?.passportExpirationDate)} </div>
          </div>
        </>
      )
    }else{
      console.log('negetive test case for coverage')
      return null
    }
  }


  const showBasicPersonalInformation = () => {

    return(
      <>
        <div className='basic-personal-information'>
          {linesReducer("Relationship to Account Holder", personalInfoJoint.relationship == "" ? "-" : getValue(personalInfoJoint.relationship, relationshipMetaData))}
          {linesReducer("Name", personalInfoJoint.firstName == "" && personalInfoJoint.lastName == "" ? "-" : <span style={{ whiteSpace: "pre-line" }}>{personalInfoJoint.prefix || null}{personalInfoJoint.prefix ? "\u00A0\u00A0\u00A0" : null}{convertToTitleCase(personalInfoJoint.firstName)} {convertToTitleCase(personalInfoJoint.lastName)}</span>)}
          {linesReducer("Date of Birth", applicationVCMId == "" || applicationVCMId == undefined ? personalInfoJoint.maskedDob == "" ? "-" : personalInfoJoint.maskedDob : personalInfoJoint.maskedDob)}
          {linesReducer("Gender", personalInfoJoint.gender)}
          {linesReducer("Marital Status", personalInfoJoint.maritalStatus)}
          {personalInfoJoint.citizenship == "us" ? <div> {linesReducer("Citizenship", "U.S.")}</div> : <div> {linesReducer("Citizenship", personalInfoJoint.citizenship == "" ? "-" : "Non U.S.")}</div>}
          {personalInfoJoint.citizenship == "nonus" && <div>  {linesReducer("Resident Information", personalInfoJoint.residentStatus)} </div> }
        </div>
      </>
    )
  }

  
  return(
    <>
      { jointPersonalEdit ? <MarketPersonalInfoComponent verifyJointPersonalHider={jointPersonalEdit} editJointPersonalValOut={(val)=>setJointPersonalEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={"personalinfo"}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointPersonalRef}>
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Personal Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>



              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointpersonaledit' className="sum-linktext mt-1" onClick={()=>setJointPersonalEdit(!jointPersonalEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>

            </Row>
          }
          titleSeperator
        >

          <div className="sum-card-wrap">
            {showBasicPersonalInformation()}
            {showResidentAlienInformation()}
            {linesReducer("Social Security Number", personalInfoJoint?.withoutMaskedSSN == ("" || undefined) ? personalInfoJoint.maskedSSN != ('' || undefined) ? personalInfoJoint.maskedSSN : "-" : formatSSNValue(personalInfoJoint.withoutMaskedSSN))}
            {personalInfoJoint.citizenship == "nonus" ? <div>{linesReducer("TaxId Issuing Country", personalInfoJoint.taxIdIssuingCountry == "" ? "-" : personalInfoJoint.taxIdIssuingCountry)}</div> : null}
          </div>

        </Accordian>
      }
    </>
  )
}

export const JointContactComponentInfo = (props) => {
  const {jointContactEdit, setJointContactEdit, editDisabler, showEditButton, mailingAddressJoint, physicalAddressJoint} = props
  const jointContactRef = useRef(null);
  useAutoFocus(jointContactEdit,jointContactRef)

  return(
    <>
      { jointContactEdit ? <MarketPersonalInfoComponent verifyJointContactHider={jointContactEdit} editJointContactValOut={(val)=>setJointContactEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={"contactinfo"}
          titleChild={
            <Row Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointContactRef} tabIndex="-1">
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Contact Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>



              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointcontactedit' className="sum-linktext mt-1" onClick={()=>setJointContactEdit(!jointContactEdit)} >
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>


            </Row>
          }
          titleSeperator
        >

          <div className="sum-card-wrap">
            {mailLineReducer("Mailing Address", mailingAddressJoint.addressLine1 == "" && mailingAddressJoint.addressLine2 == "" ? "-" : mailingAddressJoint.addressLine1 == "" ? <span>{mailingAddressJoint.addressLine2},{mailingAddressJoint.city},{mailingAddressJoint.state} {mailingAddressJoint.zipCode}</span> : mailingAddressJoint.addressLine2 == "" ? <span>{mailingAddressJoint.addressLine1},{mailingAddressJoint.city},{mailingAddressJoint.state} {mailingAddressJoint.zipCode}</span> : <span>{mailingAddressJoint.addressLine2},{mailingAddressJoint.addressLine1},  {mailingAddressJoint.city}, {mailingAddressJoint.state} {mailingAddressJoint.zipCode}</span>)}
            {mailLineReducer("Physical Address", physicalAddressJoint.addressLine1 == "" && physicalAddressJoint.addressLine2 == "" ? "-" : physicalAddressJoint.addressLine1 == "" ? <span>{physicalAddressJoint.addressLine2},{physicalAddressJoint.city},{physicalAddressJoint.state} {physicalAddressJoint.zipCode}</span> : physicalAddressJoint.addressLine2 == "" ? <span>  {physicalAddressJoint.addressLine1},{physicalAddressJoint.city},{physicalAddressJoint.state} {physicalAddressJoint.zipCode}</span> : <span> {physicalAddressJoint.addressLine2},{physicalAddressJoint.addressLine1},{physicalAddressJoint.city},{physicalAddressJoint.state} {physicalAddressJoint.zipCode}</span>)}
          </div>
        </Accordian>
      }
    </>
  )
}

export const JointPhoneComponentInfo = (props) => {
  const {jointPhoneEdit, setJointPhoneEdit, editDisabler, showEditButton, phoneInfoJoint} = props
  const jointPhoneRef = useRef(null);
  useAutoFocus(jointPhoneEdit,jointPhoneRef)
  return(
    <>
      { jointPhoneEdit ? <MarketPersonalInfoComponent verifyJointPhoneHider={jointPhoneEdit} editJointPhoneValOut={(val)=>setJointPhoneEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={'PhoneInformation-Joint Owner'}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointPhoneRef} tabIndex="-1">
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Phone Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>


              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointphoneedit'  className="sum-linktext mt-1" onClick={()=>setJointPhoneEdit(!jointPhoneEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>

            </Row>
          }
          titleSeperator
        >

          <div className="sum-card-wrap">
            {phoneInfoJoint.primary.number === "" ? <div>{linesReducer("Primary Phone", phoneInfoJoint.primary.mPhoneNumber === "" ? "-" : <span> {phoneInfoJoint.primary.countryCode} {phoneInfoJoint.primary.mPhoneNumber}</span>)}</div> : <div>{linesReducer("Primary Phone", phoneInfoJoint.primary.number === "" ? "-" : <span> {phoneInfoJoint.primary.countryCode} {phoneInfoJoint.primary.number}</span>)}</div>}
            {phoneInfoJoint.secondary.number === "" ? <div>{linesReducer("Secondary Phone", phoneInfoJoint.secondary.mPhoneNumber === "" ? "-" : <span> {phoneInfoJoint.secondary.countryCode} {phoneInfoJoint.secondary.mPhoneNumber}</span>)}</div> : <div>{linesReducer("Secondary Phone", phoneInfoJoint.secondary.number === "" ? "-" : <span> {phoneInfoJoint.secondary.countryCode} {phoneInfoJoint.secondary.number}</span>)}</div>}
          </div>

        </Accordian>
      }

    </>
  )
}

export const JointEmailComponentInfo = (props) => {
  const {jointEmailEdit, setJointEmailEdit, editDisabler, showEditButton, emailInfoJoint} = props
  const jointEmailRef = useRef(null);
  useAutoFocus(jointEmailEdit,jointEmailRef);

  return(
    <>
      { jointEmailEdit ? <MarketPersonalInfoComponent verifyJointEmailHider={jointEmailEdit} editJointEmailValOut={(val)=>setJointEmailEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={'EmailInformation-Joint Owner'}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointEmailRef} tabIndex="-1">
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Email Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>



              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointemailedit' className="sum-linktext mt-1" onClick={()=>setJointEmailEdit(!jointEmailEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>


            </Row>
          }
          titleSeperator
        >
          <div className="sum-card-wrap">
            {linesReducer("Primary Email", emailInfoJoint?.primaryEmailAddress)}
            {linesReducer("Secondary Email", emailInfoJoint?.secondaryEmailAddress)}
          </div>
        </Accordian>
      }

    </>
  )
}

export const JointEmploymentComponentInfo = (props) => {
  const {jointEmployEdit, setJointEmployEdit, editDisabler, showEditButton, employmentInfoJoint, employmentJointAddress, employmentStatusMetaDataValues, primarySourceMetaDataValues, industryMetaData } = props
  const jointEmployRef = useRef(null);
  useAutoFocus(jointEmployEdit,jointEmployRef)
  const getJointEmploymentAddressInfo = () => {
    return(
      <>
        {employmentJointAddress.addressLine1 === "" && employmentJointAddress.addressLine2 === "" ? null : employmentJointAddress.addressLine1 === undefined && employmentJointAddress.addressLine2 === undefined ? null : <div> {linesReducer("Employer's Address", employmentJointAddress.addressLine1 == "" ? <span> {employmentJointAddress.addressLine2},{employmentJointAddress.city}, {employmentJointAddress.state} {employmentJointAddress.zipCode}</span> : employmentJointAddress.addressLine2 == "" ? <span> {employmentJointAddress.addressLine1},{employmentJointAddress.city}, {employmentJointAddress.state} {employmentJointAddress.zipCode}</span> : <span> {employmentJointAddress.addressLine2},{employmentJointAddress.addressLine1},{employmentJointAddress.city},{employmentJointAddress.state} {employmentJointAddress.zipCode}</span>)}</div>}
      </>
    )
  }

  const getBasicJointPersonalEmpInfo = () => {

    return(
      <>
        {employmentInfoJoint.status ? <div> {linesReducer("Employment Status", employmentInfoJoint?.status ? getValue(employmentInfoJoint.status, employmentStatusMetaDataValues) : '-')}</div> : null}
        {employmentInfoJoint.employmentClassName ? <div>{linesReducer("Employment Class Information", employmentInfoJoint?.employmentClassName)}</div> : null}
        {employmentInfoJoint.industry ? <div> {linesReducer("Industry", employmentInfoJoint?.industry ? getValue(employmentInfoJoint.industry, industryMetaData) : '-')}</div> : null}
        {employmentInfoJoint?.industryDescription ? <div> {linesReducer("Industry Description", employmentInfoJoint.industryDescription)}</div> : null}
        {employmentInfoJoint?.primarySource ? <div>{linesReducer("Primary Source of Income", getValue(employmentInfoJoint.primarySource, primarySourceMetaDataValues))}</div> : null}
        {employmentInfoJoint.occupation ? <div>{linesReducer("Occupation", employmentInfoJoint?.occupation)}</div> : null}
        {employmentInfoJoint.employerName ? <div> {linesReducer("Employer's Name", employmentInfoJoint?.employerName ? convertToTitleCase(employmentInfoJoint.employerName) : '-')}</div> : null}
        
      </>
    )
  }

  const showEmploymentInformation = () => {

    const noEmploynentCalegories = ['retired', 'student', 'unemployed', 'homemaker']    
    const isNoEmplymentCategory = noEmploynentCalegories.includes(employmentInfoJoint?.status)




    const showEmplymentCategoryInformation = () => {

      return(
        <>
          {getBasicJointPersonalEmpInfo()}
          {getJointEmploymentAddressInfo()}
        </>
      )

    }


    return(
      <>
        {isNoEmplymentCategory ? 
          <div className="sum-card-wrap">
            <div> {linesReducer("Employment Status", getValue(employmentInfoJoint.status, employmentStatusMetaDataValues))}</div>
            <div>{linesReducer("Primary Source of Income", employmentInfoJoint?.primarySource ? getValue(employmentInfoJoint.primarySource, primarySourceMetaDataValues) : '-')}</div>
          </div> 
          :
          <div className="sum-card-wrap">
            {showEmplymentCategoryInformation()}
          </div>
        }
      </>
    )
  }


  return(
    <>
    

    { jointEmployEdit ? <MarketPersonalInfoComponent verifyJointEmpHider={jointEmployEdit} editJointEmpValOut={(val)=>setJointEmployEdit(val)} /> :
      <Accordian
        accordianPaddingLeft={0}
        id={'EmploymentInformationSecondary'}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointEmployRef} tabIndex="-1">
            <Col md={11}>
              <AccordianTitle
                accType={'Employment Information-Joint Owner'}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>
            <Col md={1} className="sum-prelink pl-0">
              {editDisabler && showEditButton &&
                <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointemployedit' className="sum-linktext" onClick={()=>setJointEmployEdit(!jointEmployEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>
              }
            </Col>
          </Row>
        }
        titleSeperator
      >


        {showEmploymentInformation()}

      </Accordian>
    }

    </>
  )
}

export const JointFinancialComponentInfo = (props) => {
  const {jointFinancialEdit, setJointFinancialEdit, editDisabler, showEditButton, financialInfoJoint, annualIncomeMetaDataValues, netWorthMetaData, taxFillingStatusMetaData} = props
  const jointFinancialRef = useRef(null);
  useAutoFocus(jointFinancialEdit,jointFinancialRef)

  const tempFinancilaCurrency = getValue(financialInfoJoint?.annualIncome, annualIncomeMetaDataValues)
  const financilaCurrencyJoint = tempFinancilaCurrency?.split("-")
  let AnnualIncomeValue1Joint = '';
  let AnnualIncomeValue2Joint = '';
  if(financilaCurrencyJoint?.length > 1)
  {
    AnnualIncomeValue1Joint = financilaCurrencyJoint[0]
    AnnualIncomeValue2Joint = financilaCurrencyJoint[1]
  }
  else if(financilaCurrencyJoint?.length>0 && financilaCurrencyJoint?.length< 2)
  {
    let annualIncodeAndValue = financilaCurrencyJoint[0];
    annualIncodeAndValue = annualIncodeAndValue?.split('and');
    AnnualIncomeValue1Joint = annualIncodeAndValue[0];
    AnnualIncomeValue2Joint = annualIncodeAndValue[1];
  }

    AnnualIncomeValue1Joint= formatCurrencyAndRemoveDecimalPart(AnnualIncomeValue1Joint)
    AnnualIncomeValue2Joint= formatCurrencyAndRemoveDecimalPart(AnnualIncomeValue2Joint)

  const netWorthJoint = getValue(financialInfoJoint?.netWorth,netWorthMetaData)?.split("-")

  let networthValue1Joint = '';
  let netWorthValue2Joint = '';
  if(netWorthJoint?.length > 1)
  {
    networthValue1Joint = netWorthJoint[0]
    netWorthValue2Joint = netWorthJoint[1]
  }
  else if(netWorthJoint?.length>0 && netWorthJoint?.length< 2)
  {
      let networthAndValue = netWorthJoint[0];
      networthAndValue = networthAndValue?.split('and');
      networthValue1Joint = networthAndValue[0];
      netWorthValue2Joint = networthAndValue[1];
    
  }

    networthValue1Joint= formatCurrencyAndRemoveDecimalPart(networthValue1Joint)
    netWorthValue2Joint= formatCurrencyAndRemoveDecimalPart(netWorthValue2Joint)

  return(
    <>
      { jointFinancialEdit ? <MarketPersonalInfoComponent verifyJointFinHider={jointFinancialEdit} editJointFinValOut={(val)=>setJointFinancialEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={'FinancialInformationPrimary'}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointFinancialRef} tabIndex="-1">
              <Col md={11}>
                <AccordianTitle
                  accType={'Financial Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>
              <Col md={1} className="sum-prelink pl-0">
                {editDisabler && showEditButton &&
                  <Row noGutters>
                    <WIcon
                      alt=""
                      src={editIcon}
                      size={"1px"}
                      className="editIcon"
                      fontawesomestyle={assignObj({ paddingLeft: 0 })}
                    />
                    <button type="button" data-testid='jointfinancialedit' className="sum-linktext" onClick={()=>setJointFinancialEdit(!jointFinancialEdit)}>
                      <span>Edit</span>
                    </button>
                  </Row>
                }
              </Col>
            </Row>
          }
          titleSeperator
        >
          <div className="sum-card-wrap">
          {linesReducer("Annual Income (USD)", financialInfoJoint?.annualIncome ? (financilaCurrencyJoint?.length < 2) ? `${AnnualIncomeValue1Joint} and ${AnnualIncomeValue2Joint}` : `${AnnualIncomeValue1Joint}-${AnnualIncomeValue2Joint}` : '-')}
          {linesReducer("Tax Bracket", financialInfoJoint?.taxBracket ? financialInfoJoint.taxBracket.includes('%') ? `${financialInfoJoint.taxBracket}` : `${financialInfoJoint.taxBracket}%` : '-')}
          {linesReducer("Net Worth (USD)", financialInfoJoint?.netWorth ?  (netWorthJoint?.length < 2) ? `${networthValue1Joint} and ${netWorthValue2Joint}` : `${networthValue1Joint}-${netWorthValue2Joint}` : '-')}
          {linesReducer("Tax Filing Status", financialInfoJoint?.taxFilingStatus ? getValue(financialInfoJoint.taxFilingStatus, taxFillingStatusMetaData) : '-')}
        </div>
        </Accordian>
      }
    </>
  )
}

export const JointMilitaryComponentInfo = (props) => {

  const {jointMilitaryEdit, setJointMilitaryEdit, editDisabler, showEditButton, militaryInformationJoint, militaryBranchMetataData, militaryStatusMetataData, materLookUpData} = props
  const jointMilitaryRef = useRef(null);
  useAutoFocus(jointMilitaryEdit,jointMilitaryRef);


  return(
    <>
      { jointMilitaryEdit ? <MarketPersonalInfoComponent verifyJointMilHider={jointMilitaryEdit} editJointMilValOut={(val)=>setJointMilitaryEdit(val)} /> :
        <Accordian
          accordianPaddingLeft={0}
          id={"personalinfo"}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={jointMilitaryRef} tabIndex="-1">
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Military Information-Joint Owner'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>



              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='jointmilitaryedit' className="sum-linktext mt-1" onClick={()=>setJointMilitaryEdit(!jointMilitaryEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>

            </Row>
          }
          titleSeperator
          >

          <div className="sum-card-wrap">

            {militaryInformationJoint.hasServed === "N" ? <div>

              {linesReducer("Military Status", '-')}
              {linesReducer("Branch of Service", '-')}
              {linesReducer("Rank", '-')}
              {linesReducer("Dates of Service", '-')}
              {linesReducer("Commission Source", '-')}
            </div> : <div>

              {linesReducer("Military Status", militaryInformationJoint.militaryStatus == "" ? "-" : getValue(militaryInformationJoint?.militaryStatus, militaryStatusMetataData))}
              {linesReducer("Branch of Service", militaryInformationJoint.branch == "" ? "-" : getValue(militaryInformationJoint.branch, militaryBranchMetataData))}
              {linesReducer("Rank", militaryInformationJoint.rank == "" ? "-" : getRankValueBasedOnKey(militaryInformationJoint.rank, materLookUpData))}
              {linesReducer("Dates of Service", militaryInformationJoint.startDate && militaryInformationJoint.endDate == "" ? "-" : <span>{militaryInformationJoint.startDate}-{militaryInformationJoint.endDate}</span>)}
              {linesReducer("Commission Source", militaryInformationJoint?.commissionSource ? militaryInformationJoint.commissionSource : '-')}
            </div>}
          </div>


        </Accordian>
      }
    
    </>
  )
}


// Main Function
const VerifyJointPersonal = (props) => {

const {editDisabler, showEditButton} = props
  //States
  const [jointPersonalEdit, setJointPersonalEdit] = useState(false)
  const [jointContactEdit, setJointContactEdit] = useState(false)
  const [jointPhoneEdit, setJointPhoneEdit] = useState(false)
  const [jointEmailEdit,setJointEmailEdit]=useState(false)
  const [jointEmployEdit, setJointEmployEdit] = useState(false)
  const [jointFinancialEdit, setJointFinancialEdit] = useState(false)
  const [jointMilitaryEdit, setJointMilitaryEdit] = useState(false)
  const allJointPersonal= jointPersonalEdit || jointContactEdit || jointPhoneEdit || jointEmailEdit || jointEmployEdit || jointFinancialEdit || jointMilitaryEdit

  //Focus Sections
  const jointPersonalRef = useRef(null);
  const jointContactRef = useRef(null);
  const jointPhoneRef = useRef(null);
  const jointEmailRef = useRef(null);
  const jointEmployRef = useRef(null);
  const jointFinancialRef = useRef(null);
  const jointMilitaryRef = useRef(null);
  // useAutoFocus(jointPersonalEdit,jointPersonalRef)
  // useAutoFocus(jointContactEdit,jointContactRef)
  // useAutoFocus(jointPhoneEdit,jointPhoneRef)
  // useAutoFocus(jointEmailEdit,jointEmailRef)
  // useAutoFocus(jointEmployEdit,jointEmployRef)
  // useAutoFocus(jointFinancialEdit,jointFinancialRef)
  // useAutoFocus(jointMilitaryEdit,jointMilitaryRef)



  useEffect(() => {
   props.setEditDisableVal(!allJointPersonal)
}, [allJointPersonal])

  
  



  useEffect(() => {

    let flag = 0;

      if (phoneInfo.primary.number == "") {

        if (phoneInfo.primary.mPhoneNumber != "") {
          phoneInfo.primary['phoneNumberInfo'] = phoneInfo.primary.mPhoneNumber;
        }
      }
      else {
        phoneInfo.primary['phoneNumberInfo'] = phoneInfo.primary.number;
      }

      if (phoneInfo.secondary.number == "") {

        if (phoneInfo.secondary.mPhoneNumber != "") {
          phoneInfo.secondary['phoneNumberInfo'] = phoneInfo.secondary.mPhoneNumber;
        }
      }
      else {
        phoneInfo.secondary['phoneNumberInfo'] = phoneInfo.secondary.number;
      }

   // }
  })


  //from redux state
  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData.marketNAOInfo);

    //
    const annualIncomeMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.brkg_annual_income?.value)
    const taxFillingStatusMetaData = useSelector(state => state.transactionCompositeDataReducerData?.tax_filling_status?.value)
    const netWorthMetaData = useSelector(state => state.transactionCompositeDataReducerData?.brkg_net_worth?.value)
    const primarySourceMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.prim_src_income?.value)
    const employmentStatusMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.brkg_employment_status?.value)
    const industryMetaData = useSelector(state => state.transactionCompositeDataReducerData?.industry?.value)
    const relationshipMetaData = useSelector(state => state.transactionCompositeDataReducerData?.relationship?.value)
    const militaryStatusMetataData = useSelector(state => state.transactionCompositeDataReducerData?.mil_status?.value)
    const militaryBranchMetataData = useSelector(state => state.transactionCompositeDataReducerData?.mil_serv_branch?.value)
  
    //

  const personalInfoJoint = marketNAOInfoReducerData.personalInfo.secondary
  const mailingAddressJoint = marketNAOInfoReducerData.addressInfo.secondary.mailingAddress
  const physicalAddressJoint = marketNAOInfoReducerData.addressInfo.secondary.physicalAddress
  const phoneInfoJoint = marketNAOInfoReducerData.contactInfo.secondary.phoneInfo
  const emailInfoJoint = marketNAOInfoReducerData.contactInfo.secondary.emailAddresses
  const employmentInfoJoint = marketNAOInfoReducerData.employmentInfo.secondary
  const employmentJointAddress = marketNAOInfoReducerData.employmentInfo.secondary.address
  const financialInfoJoint = marketNAOInfoReducerData.financialInfo.secondary
  const militaryInformationJoint = marketNAOInfoReducerData.militaryInfo.secondary
  const materLookUpData = useSelector(state => state.transactionCompositeDataReducerData);
  const tempFinancilaCurrency =getValue(financialInfoJoint?.annualIncome,annualIncomeMetaDataValues)

  const financilaCurrencyJoint = tempFinancilaCurrency?.split("-")
  let AnnualIncomeValue1Joint = '';
  let AnnualIncomeValue2Joint = '';
  if(financilaCurrencyJoint?.length > 1)
  {
    AnnualIncomeValue1Joint = financilaCurrencyJoint[0]
    AnnualIncomeValue2Joint = financilaCurrencyJoint[1]
  }
  else if(financilaCurrencyJoint?.length>0 && financilaCurrencyJoint?.length< 2)
  {
    let annualIncodeAndValue = financilaCurrencyJoint[0];
    annualIncodeAndValue = annualIncodeAndValue?.split('and');
    AnnualIncomeValue1Joint = annualIncodeAndValue[0];
    AnnualIncomeValue2Joint = annualIncodeAndValue[1];
  }

  const netWorthJoint = getValue(financialInfoJoint?.netWorth,netWorthMetaData)?.split("-")

  let networthValue1Joint = '';
  let netWorthValue2Joint = '';
  if(netWorthJoint?.length > 1)
  {
    networthValue1Joint = netWorthJoint[0]
    netWorthValue2Joint = netWorthJoint[1]
  }
  else if(netWorthJoint?.length>0 && netWorthJoint?.length< 2)
  {
     let networthAndValue = netWorthJoint[0];
     networthAndValue = networthAndValue?.split('and');
     networthValue1Joint = networthAndValue[0];
     netWorthValue2Joint = networthAndValue[1];
    
  }

  const role = sessionStorage.getItem("role")

  let taxBracketData = `${financialInfoJoint?.taxBracket}`;
  taxBracketData = (taxBracketData?.includes('%') == true) ? taxBracketData : `${taxBracketData}%`;

  const applicationVCMId=marketNAOInfoReducerData.applicationVCMId

  const phoneInfo = marketNAOInfoReducerData.contactInfo.secondary.phoneInfo
  const initialDecryptedVar = {
    dob: '',
    ssn: '',
  }
  const [decryptedVariables, setDecryptedVariables] = useState(initialDecryptedVar);
  return (
    <> 
      <JointPersonalComponentInfo
          jointPersonalEdit={jointPersonalEdit}
          setJointPersonalEdit={setJointPersonalEdit}
          editDisabler={editDisabler}
          showEditButton={showEditButton}
          personalInfoJoint={personalInfoJoint}
          relationshipMetaData={relationshipMetaData}
          decryptedVariables={decryptedVariables}
          applicationVCMId={applicationVCMId}
        />

      <JointContactComponentInfo
              jointContactEdit={jointContactEdit}
              setJointContactEdit={setJointContactEdit}
              editDisabler={editDisabler}
              showEditButton={showEditButton}
              mailingAddressJoint={mailingAddressJoint}
              physicalAddressJoint={physicalAddressJoint}
            />


      <JointPhoneComponentInfo
              jointPhoneEdit={jointPhoneEdit}
              setJointPhoneEdit={setJointPhoneEdit}
              editDisabler={editDisabler}
              showEditButton={showEditButton}
              phoneInfoJoint={phoneInfoJoint}
            />

      <JointEmailComponentInfo
              jointEmailEdit={jointEmailEdit}
              setJointEmailEdit={setJointEmailEdit}
              editDisabler={editDisabler}
              showEditButton={showEditButton}
              emailInfoJoint={emailInfoJoint}
            />


        <JointEmploymentComponentInfo
                jointEmployEdit={jointEmployEdit}
                setJointEmployEdit={setJointEmployEdit}
                editDisabler={editDisabler}
                showEditButton={showEditButton}
                employmentInfoJoint={employmentInfoJoint}
                employmentJointAddress={employmentJointAddress}
                employmentStatusMetaDataValues={employmentStatusMetaDataValues}
                primarySourceMetaDataValues={primarySourceMetaDataValues}
                industryMetaData={industryMetaData}
              />
      
      
      
      <JointFinancialComponentInfo
        jointFinancialEdit={jointFinancialEdit}
        setJointFinancialEdit={setJointFinancialEdit}
        editDisabler={editDisabler}
        showEditButton={showEditButton}
        financialInfoJoint={financialInfoJoint}
        annualIncomeMetaDataValues={annualIncomeMetaDataValues}
        taxFillingStatusMetaData={taxFillingStatusMetaData} 
        netWorthMetaData={netWorthMetaData}
      />


    <JointMilitaryComponentInfo
            jointMilitaryEdit={jointMilitaryEdit}
            setJointMilitaryEdit={setJointMilitaryEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            militaryInformationJoint={militaryInformationJoint}
            militaryBranchMetataData={militaryBranchMetataData}
            militaryStatusMetataData={militaryStatusMetataData}
            materLookUpData={materLookUpData}
          />
    </>

  )


}

export default VerifyJointPersonal