/**
 * Verify Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to view the captured  information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason Code Refactoring
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import SummaryCard from './SummaryCard';
import {
    WStepper,
    CommonButtons,
    WSpinner,
    WSaveModal,
    WMessageBar,
    ConformationModal,
    WBreadCrumb,
} from '../../../common';
import './Verify.css';
import Consts from './Consts';
import AccGlobalConstants from '../AccountManagementConstants';
import generatePersonalInfoCard from './GeneratePersonalInfoCard';
import generateJointOwnerInfoCard from './GenerateJointOwnerInfoCard';
import generatePreferencesCard from './GeneratePreferencesCard';
import generateEmploymentInfoCard from './GenerateEmploymentInfoCard';
import generateFinancialInfoCard from './generateFinancialInfoCard';
import generateMilitaryInfoCard from './GenerateMilitaryInfoCard';
import generateFundingInfoCard from './GenerateFundingInfoCard';
import handleBeneficiaryCardsGeneration from './HandleBeneficiaryCardsGeneration';
import generateAccountInfoCard from './GenerateAccountInfoCard';
import generateContributionYearCard from './generateContributionYearCard';
import handleEvent from './HandleEditEvent';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

export default class VerifyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            editTarget: '',
            next: false,
            back: false,
            cancel: false,
            showSaveModal: false,
            loading: false,
            cards: [],
            showConfirmCancelModal: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const { accOpeningData } = nextProps;
        return {
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
        };
    }

    componentDidMount() {
        const { localStateData } = this.props;
        let accType = '';
        if (localStateData.accountType) {
            accType = localStateData.accountType;
        }
        const finalCards = this.generateCards(localStateData, accType);
        this.setState({ cards: finalCards, accountType: accType });
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    updateState = prevProps => {
        const { accOpeningData } = this.props;
        // Loader values
        if (accOpeningData.isLoading) {
            this.setState({ loading: true });
        } else if (!accOpeningData.isLoading) {
            this.setState({ loading: false });
        }

        //   Save Function Values Start
        if (
            accOpeningData.VERIFY_SAVE_OPENING_ACCT &&
            accOpeningData.VERIFY_SAVE_OPENING_ACCT !== prevProps.accOpeningData.VERIFY_SAVE_OPENING_ACCT
        ) {
            // let msg = '';
            if (accOpeningData.VERIFY_SAVE_OPENING_ACCT.status) {
                //  msg = accOpeningData.VERIFY_SAVE_OPENING_ACCT.status;
                this.setState({ showSaveModal: true, loading: false });
            } else {
                const msg = accOpeningData.VERIFY_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        // Error
        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    generateCards = (data, accType) => {
        const allCards = [];
        let accountPreferencesCard = {};
        let fundingInfoCard = {};
        const { masterLookupStateData } = this.props;
        const { jointOwnertext } = Consts;
        // Generate Account InfoCard
        allCards.push(...generateAccountInfoCard(accType, data));

        if (accType && data[accType.toString()]) {
            const {
                perInfo,
                empInfo,
                finInfo,
                milInfo,
                jointPerInfo,
                // jointEmpInfo,
                // jointFinInfo,
                // jointMilInfo,
                benInfo,
                nickName,
            } = data[accType.toString()];

            // Generating PersonalInfo Card
            const perCards = generatePersonalInfoCard(masterLookupStateData, perInfo, nickName);
            for (let i = 0; i < perCards.length; i += 1) {
                allCards.push(perCards[i.toString()]);
            }

            // Generating Employment Card
            const empCard = generateEmploymentInfoCard(masterLookupStateData, empInfo);
            allCards.push(empCard);

            // Generating Financial Info Card
            const financialCard = generateFinancialInfoCard(masterLookupStateData, finInfo);
            allCards.push(financialCard);

            // Generating Military Info Card
            const militaryCard = generateMilitaryInfoCard(masterLookupStateData, milInfo);
            allCards.push(militaryCard);

            if (accType.toLowerCase().indexOf('joint') > -1) {
                // Generating Joint PersonalInfo Card
                const jointPerCards = generateJointOwnerInfoCard(
                    masterLookupStateData,
                    jointPerInfo.jointOwners,
                    jointOwnertext,
                );
                for (let i = 0; i < jointPerCards.length; i += 1) {
                    allCards.push(jointPerCards[i.toString()]);
                }
            }

            // Generating Beneficiary Cards
            allCards.push(...handleBeneficiaryCardsGeneration(masterLookupStateData, accType, benInfo));
        }
        fundingInfoCard = generateFundingInfoCard(masterLookupStateData, data);
        accountPreferencesCard = generatePreferencesCard(masterLookupStateData, data);
        allCards.push(...fundingInfoCard, accountPreferencesCard);

        if (
            accType.trim() === Consts.TRADIONAL_IRA_TEXT ||
            accType.trim() === Consts.ROTH_IRA_TEXT ||
            accType.trim() === Consts.SEP_IRA_TEXT
        ) {
            const contributionYearCard = generateContributionYearCard(masterLookupStateData, data);
            allCards.push(contributionYearCard);
        }

        return allCards;
    };

    handleEvent = cardId => {
        const updatedObject = handleEvent(cardId);
        this.setState({ ...updatedObject });
    };

    renderCard = ({ cardHeading, fields, cardId, linkText }, index) => (
        <SummaryCard
            cardHeading={cardHeading}
            linkText={linkText}
            cardId={cardId}
            fields={fields}
            index={index.toString()}
            handleEvent={this.handleEvent}
            originPage="verify"
        />
    );

    handleNext = () => {
        this.setState({ next: true });
    };

    handleSave = () => {
        const { localStateData, saveApplication } = this.props;
        const savePayload = { ...localStateData };
        saveApplication({
            token: localStorage.getItem('token'),
            saveApplicationPayload: {
                accountType: localStateData.accountType,
                payload: savePayload,
            },
        });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    render() {
        const {
            cards,
            loading,
            showSaveModal,
            editTarget,
            next,
            cancel,
            back,
            edit,
            showAlert = false,
            errorMessage,
            isErrorBar,
            accountType,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
        } = this.state;
        const { accOpeningData } = this.props;
        return (
            <>
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {loading && <WSpinner loading={loading} />}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                <div className="MainContainer">
                    <div className="verify">
                        {showAlert ? (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        ) : null}
                        <div className="container">
                            <WBreadCrumb
                                activeCrumb={accountType}
                                breadCrumbItems={
                                    localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                                }
                            />
                            <h1 className="verify-pageheading">{AccGlobalConstants.getPageHeading(accountType)}</h1>
                            <div className="stepperWrapper">
                                <WStepper currentPage={5} pages={AccGlobalConstants.accountOpeningPages} />
                            </div>
                            <div className="cardWrap">
                                {cards &&
                                    cards.map((card, index) => (
                                        <div key={`${card.cardId}-${Math.random()}`}>
                                            {this.renderCard(card, index)}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        <div className="buttonwrap">
                            <CommonButtons
                                classname="backbutton"
                                nextClick={this.handleNext}
                                saveClick={this.handleSave}
                                backClick={this.handleBack}
                                cancelClick={this.handleCancelClick}
                                showSave
                                iscancel
                                cancelText="Cancel"
                                cancelStyle={assignObj(cancelButtonStyles)}
                                cancelOffset={assignObj({
                                    span: 2,
                                    offset: 6,
                                })}
                            />
                        </div>
                        {next ? <Redirect to={Consts.redirect} /> : ''}
                        {cancel ? <Redirect to="/" /> : ''}
                        {back ? <Redirect to="/preferences" /> : ''}
                        {edit && <Redirect to={editTarget} />}
                    </div>
                </div>
            </>
        );
    }
}

VerifyComponent.propTypes = {
    localStateData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    saveApplication: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
};

VerifyComponent.defaultProps = {
    localStateData: {},
    masterLookupStateData: {},
    saveApplication: () => {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    resetShowConfirmModal: () => {},
    history: {},
};
