const consts = {
    paperFlowHeading: 'Paper',
    applicationHeading: 'Application',
    applicationPara1: 'Download and print the application',
    applicationPara2:
        'By downloading the application you acknowledge that you have received, read, not altered and agree to any relevant VCM Mutual Funds prospectuses and the SIMPLE/SEP IRA Custodial Agreement; and you have received and read the Victory Capital Privacy Notice and Business Continuity and Cybersecurity Summary documents',
    applicationCard1Title: 'SEP/ SIMPLE IRA Agreement',
    applicationCard2Title: 'Detailed Instructions',
    downloadPdfText: 'Download',
    cardtitlestyle: {
        font: '800 30px/25px yorkten-slab-normal',
        marginBottom: 20,
    },
};

export default consts;
