import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isloading: false
};

function getPopularBankReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_POPULAR_BANK_NAMES: {
            return {
                ...state,
                ...action
            };
        }

        default:
            return state;
    }
}

export default getPopularBankReducer;