import React, {useEffect, useState} from 'react'
import queryString from 'query-string';
/** 
@author Rahul Para
@lastModifiedDate [10/21/2021]
@description 
*/
import { Redirect, useHistory, useLocation } from 'react-router';
import { getMarketApplicationIdData, marketUpdateLocal } from 'shared/Actions/MarketNAOActions';
import { useDispatch, useSelector } from 'react-redux';
import { WMessageBar, WSpinner } from 'common';
import { decrypt } from 'utils';
import { transactionCompositeDataAction } from 'shared/Actions';
import { setProfileInformationToLocalStorage } from './HelperFunctions';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import { getMemberProfileInformations } from 'shared/Actions/ProfileSettingsAction';
import { MARKET_NAO_ACCOUNT_TYPES ,MARKET_NAO_PATHS} from '../consts';
import { getEnabledFeature } from 'shared/Actions/MemberDashboardActions';
import { getDecryptedBankDetailsRedirectFlow } from 'shared/Actions/BankAccountAction';
import { currencyFormatterWithoutDollarSign } from 'common/Formatter/CurrencyFormatter';
import { accountIdentification, getValidValue, getValidValueOf } from 'commonHelper/CommonHelperFunctions';


const RedirectToBrokerageNAO = (props) => {


  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [renderCount, setRenderCount] = useState(0)
  const [redirectToMarketNAO, setRedirectToMarketNAO] = useState(false)
  const [redirectPageDetails, setRedirectPageDetails] = useState({pathname:'/accountholder', redirectToPage:2})

  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
  const profileInformationData = useSelector(state => state.profileInformationData);
  const localStateData = useSelector(state => state)
  const masterLookupStateData = useSelector(state => state.transactionCompositeDataReducerData)

  const isLoading = marketNAOInfoReducerData.isLoading
  const [asyncLoading, setAsyncLoading] = useState(false)

  
  const errorText = {
    message: marketNAOInfoReducerData.statusMessage
  }
  const hasFundingStep =accountIdentification(localStateData.memberDashboardData,'BankAccountFunding')
    useEffect(() => {
      
      const queryParams = queryString.parse(location.search);

      const {memberProfileInformation} = profileInformationData;

      if(!memberProfileInformation || Object.keys(memberProfileInformation).length < 0){
        dispatch(getMemberProfileInformations())
      }

      dispatch(getEnabledFeature())

      // applicationid avaliable then 
      if(queryParams.applicationId != undefined){
        dispatch(getMarketApplicationIdData('17f69700-eb55-4e1c-ae70-f9c3850db633'))

      }else if(location.state?.applicationId != undefined && location.state?.applicationId != ''){
        dispatch(getMarketApplicationIdData(location.state.applicationId))
      }else if(location.state?.uuid != undefined && location.state?.uuid != ''){
        dispatch(getMarketApplicationIdData(location.state.uuid))
      }

        const payload = [];
        const compositePayloadData = [
            'brkg_net_worth',
            'tax_filling_status',
            'address_type',
            'country_3_lettercode',
            'prim_src_income',
            'industry',
            'marital_status',
            'brkg_employment_status',
            'relationship',
            'mil_serv_branch',
            'mil_status',
            'mil_rank_01',
            'mil_rank_02',
            'mil_rank_03',
            'mil_rank_04',
            'mil_rank_05',
            'mil_rank_07',
            'mil_rank_08',
            'mil_rank_09',
            'mil_rank_10',
            'mil_rank_11',
            'mil_rank_12',
            'mil_rank_13',
            'ben_type',
            'rel_to_custd',
            'custd_relation',
            'brkg_annual_income',
            'country_list',
            'brkg_relationship',
            'prefix',
            'suffix'
        ];

        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }

        dispatch(transactionCompositeDataAction.getTransactionCompositeData(
          { data: { datakey: payload },
          token: getTokenFromSession() ,
         marketNAOFlow:"marketNaoFlow"}
        ))
        // }

    }, [])


    const redirectFunction = (updatedState) => {
      setRedirectToMarketNAO(true)

      dispatch(marketUpdateLocal(updatedState))
      
      if(updatedState.applicationStatus == 'Submitted'){
        history.push({
          pathname: '/marketNAOFlow',
          state: { 
            redirectToPage: 4, 
          }
        })
      }else if(updatedState.applicationStatus == 'Secondary_econsent_pending'){
        history.replace({
          pathname:getValidValueOf(updatedState.moveToSection.pathname,updatedState.moveToSection.pathname,MARKET_NAO_PATHS.NAO_FLOW),
          state: { 
            redirectToPage:getValidValueOf(hasFundingStep, 5 , 4),
          }
        })
      }
      else{
        if(updatedState.moveToSection.selectedSection == 'PersonalInfo' || updatedState.moveToSection.selectedSection == 'PersonalInfo' ){
          history.push({
            pathname: updatedState.moveToSection.pathname,
            state: {
              pageIndex: updatedState.moveToSection.pageIndex
            }
          })
        }else{
          history.push({
            pathname: updatedState.moveToSection.pathname,
            state: { 
              redirectToPage: 2,
              savedApplicationRedirection:true
            }
          })
        }
      
      }

    }


    useEffect(() => {

      const {memberProfileInformation} = profileInformationData;
      if(marketNAOInfoReducerData.isMarketNAOSuccess && renderCount == 0 && memberProfileInformation && Object.keys(memberProfileInformation).length > 0){
      // if(marketNAOInfoReducerData.isMarketNAOSuccess && renderCount == 0){
        setRenderCount(1)
        setAsyncLoading(true)
        let unmounted = false;
    
        const updatedState = compareResponseAndUpdateState(marketNAOInfoReducerData.marketNAOInfo, marketNAOInfoReducerData.dataFromSavedApplication, masterLookupStateData, memberProfileInformation,localStateData)

        if(!unmounted){
          setTimeout(()=>redirectFunction(updatedState), 6000)
        }
    
        return () =>{
          unmounted = true
        }
      }

    }, [marketNAOInfoReducerData, masterLookupStateData, profileInformationData])
    

  const load =  isLoading || asyncLoading

  return (
  
  
    <>

    {marketNAOInfoReducerData.isMarketNAOError && 
    
    <WMessageBar text={errorText} error={true} /> 

    }
    
    <div className='container'>

      <WSpinner loading={load} /> 

      
      {false && 
      <Redirect to={{
        pathname: redirectPageDetails.pathname,
        state: { redirectToPage: redirectPageDetails.redirectToPage, pageIndex: 0 }
      }} />
      }

    </div>
    </>
    
  )
}


export default RedirectToBrokerageNAO


const getKeyBasedOnValue = (keysValue, findKeyFor, masterLookupStateData) => {

      let key = '';
      let rankFlag = false;

      if(findKeyFor == 'rank'){
          for (let i = 0; i < masterLookupStateData?.mil_rank_01?.value?.length; i++) {
              if (masterLookupStateData?.mil_rank_01?.value[i].value == keysValue) {
                  key = masterLookupStateData?.mil_rank_01?.value[i].key;
                  rankFlag = true;
                  break;
              }
          }

      
          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_03?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_03?.value[i].value == keysValue) {
                  rankFlag = true;
                  key = masterLookupStateData?.mil_rank_03?.value[i].key;
                  break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_04?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_04?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_04?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_06?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_06?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_06?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_07?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_07?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_07?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }


          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_08?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_08?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_08?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }


          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_09?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_09?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_09?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_10?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_10?.value[i].value == keysValue) {
                  key = masterLookupStateData?.mil_rank_10?.value[i].key;
                  rankFlag = true;
                  break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_11?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_11?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_11?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_12?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_12?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_12?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_13?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_13?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_13?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

          if(rankFlag == false)
          {
              for (let i = 0; i < masterLookupStateData?.mil_rank_14?.value?.length; i++) {
                  if (masterLookupStateData?.mil_rank_14?.value[i].value == keysValue) {
                      key = masterLookupStateData?.mil_rank_14?.value[i].key;
                      rankFlag = true;
                      break;
                  }
              }
          }

      }

      if(findKeyFor == 'militaryStatus'){
          for (let i = 0; i < masterLookupStateData?.mil_status?.value?.length; i++) {
          if (masterLookupStateData?.mil_status?.value[i].value ==  keysValue) {
              key = masterLookupStateData?.mil_status?.value[i].key;
              break;
          }
          }
      }

      if(findKeyFor == 'branch'){
          for (let i = 0; i < masterLookupStateData?.mil_serv_branch?.value?.length; i++) {
              if (masterLookupStateData?.mil_serv_branch?.value[i].value == keysValue) {
                  key = masterLookupStateData?.mil_serv_branch?.value[i].key;
                  break;
              }
          }
      }

  return key
}

const getKey = (lookupString, lookupValue, masterLookupStateData) => {
  let lData = lookupString.toString();
  if (lookupValue && lookupValue?.toString().trim() !== '' && lData !="") {
      const lookupList =
          masterLookupStateData &&
          masterLookupStateData[lData] &&
          masterLookupStateData[lData].value;

      if(lookupString === 'country_list'){
        // return the index of the selected item from the country list
        return lookupList.findIndex(item => item.value === lookupValue)
      }
      const lookupData = lookupList?.find(item => item.key === lookupValue || item.value === lookupValue);
      if (lookupData) {
          return lookupData.key;
      }
  }
  return '';
};


const accountFundingBuilder = (selectedArray, allArray, fundLaterOptionSelected) => {

  let newSelectedArray = Array.from(selectedArray)
  let newAllArray = Array.from(allArray)

  newAllArray = getGiActResult(newAllArray)

  let arr1 = Array.from(newSelectedArray)
  let arr2 = Array.from(newAllArray)

  let hash = Object.create(null)

  arr1.forEach(function (a) {
      hash[a.salesforceRecordId] = true;
  });
  arr2.forEach(function (a) {
      hash[a.salesforceRecordId] || arr1.push(a);
  });

  return arr1
}

const getGiActResult = (bankAccountInfoList)=>
{
  let noOfGiactPassBank = [];

  for(let i=0;i<bankAccountInfoList?.length;i++)
  {
      const failedValue = (bankAccountInfoList[i]?.giactStatus) ? bankAccountInfoList[i]?.giactStatus?.toLowerCase() : "";
      if(failedValue !== "failed")
      {
        noOfGiactPassBank.push(bankAccountInfoList[i]);
      }
  }
  // bankAccountInfoList?.forEach((item)=>{
  //   const failedValue = (item?.giactStatus) ? item?.giactStatus?.toLowerCase() : "";
  //   if(failedValue !== "failed")
  //   {
  //     noOfGiactPassBank.push(item);

  //   }
  // })
  return noOfGiactPassBank
  
}

const addAdditionalPropertiesForSelectedList = (selectedAccountList, allAccountsList) => {
  let updatedArray = Array.from(selectedAccountList)

  updatedArray = updatedArray.map((item) => {
    const matchingAccount = allAccountsList.find(innerItem => innerItem.salesforceRecordId === item.salesforceRecordId)

    return {
      ...item, 
      selected: true,
      decryptedBankAccountNumber: matchingAccount?.decryptedBankAccountNumber,
      decryptedBankRoutingNumber: matchingAccount?.decryptedBankRoutingNumber,
      maskBankName: matchingAccount?.maskBankName,
      fundAmount : currencyFormatterWithoutDollarSign(item?.fundAmount)
    }
  })

  return updatedArray
}

const prepareRecurringAccountList = (accountList) =>
{
    let recurringAccountList = []
    if(accountList != undefined && Array.isArray(accountList) && accountList.length > 0)
    {
       recurringAccountList = accountList.map((item)=>{

        return {
          ...item,
          fundAmount : currencyFormatterWithoutDollarSign(item.fundAmount)
        }
       })
    }
    return recurringAccountList
}

const compareResponseAndUpdateState = (initialState, apiResponse, masterLookupStateData, profileInformationData,localStateData) => {

  let modelState = JSON.parse(JSON.stringify(initialState));

  let moveToSection = {
    selectedSection: '',
    selectedSubPage: ''
  }

  const isJointAccount = apiResponse.account?.accountType ? apiResponse.account?.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT : false
  const isIRAAccount = apiResponse.account?.accountType ? apiResponse.account.accountType.toLowerCase().includes('ira') : false
  const hasFundingStep = localStateData.memberDashboardData.isEnableFeatureSuccess && localStateData.memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false

  // Identifying the last saved step for the application
  if(apiResponse?.eConsent != undefined && apiResponse?.eConsent?.documentAcknowledge){
    if(apiResponse?.isPrimary && apiResponse?.kyc_check?.primary?.kycCheckStatus == "PASS"){ //for primary account application
      moveToSection = {
        selectedSection: 'VerifyInfo',
        selectedSubPage: '',
        pathname: MARKET_NAO_PATHS.NAO_FLOW,
        pageIndex: 0
      }
    }else if(!apiResponse?.isPrimary && apiResponse?.kyc_check?.joint?.kycCheckStatus == "PASS"){ //For joint account application
      moveToSection = {
        selectedSection: 'VerifyInfo',
        selectedSubPage: '',
        pathname: MARKET_NAO_PATHS.NAO_FLOW,
        pageIndex: 0
      }
    }else{
      moveToSection = {
        selectedSection: '',
        selectedSubPage: '',
        pathname: MARKET_NAO_PATHS.UPLOAD_PENDING_DOCUMENTS,
        pageIndex: 0
      }
    }
  }
  else if(apiResponse?.accountFunding != undefined){
    moveToSection = {
      selectedSection: 'VerifyInfo',
      selectedSubPage: '',
      pathname: MARKET_NAO_PATHS.NAO_FLOW,
      pageIndex: 0
    }
  }else if(apiResponse?.bankAccountInfo != undefined && apiResponse?.bankAccountInfo?.brokerageBankList){
    moveToSection = {
      selectedSection: 'AccountFunding',
      selectedSubPage: 'accountFunding',
      pathname: MARKET_NAO_PATHS.NAO_FLOW,
      pageIndex: 0
    }
  }else if(apiResponse?.trustedContact != undefined){
    moveToSection = {
      selectedSection: hasFundingStep ? 'AccountFeatures' : 'VerifyInfo',
      selectedSubPage: hasFundingStep ? 'addBankAccount' : '',
      pathname: MARKET_NAO_PATHS.NAO_FLOW,
      pageIndex: 0
    }
  }else if(apiResponse?.beneficiaries != undefined){
    moveToSection = {
      selectedSection: 'AccountFeatures',
      selectedSubPage: 'trustedContact',
      pathname: '/marketNAOFlow',
      pageIndex: 0
    }
  }else if(apiResponse?.brokerDealer != undefined){
    moveToSection = {
      selectedSection: isJointAccount ? apiResponse.brokerDealer?.joint != undefined ? 'AccountFeatures' :'Regulatory'  
                                      : apiResponse.brokerDealer?.primary != undefined ? 'AccountFeatures' :'Regulatory',
      selectedSubPage: isJointAccount ? apiResponse.brokerDealer?.joint != undefined ? '' : apiResponse.brokerDealer?.primary != undefined ? 'joint' :'primary' 
                                      : apiResponse.brokerDealer?.primary != undefined ? isIRAAccount ? 'beneficiaries' : 'trustedContact' :'primary' ,
      pathname: '/marketNAOFlow',
      pageIndex: 0
    }
  }else if(apiResponse?.employmentInfo != undefined){
    moveToSection = {
      selectedSection: isJointAccount ? apiResponse.employmentInfo?.joint != undefined ? 'Regulatory' : 'PersonalInfo' : apiResponse.employmentInfo?.primary != undefined ? 'Regulatory' : '',
      selectedSubPage: '',
      pathname: isJointAccount ? apiResponse.employmentInfo?.joint != undefined ? '/marketNAOFlow' :'/marketPersonalInfo' : apiResponse.employmentInfo?.primary != undefined ? '/marketNAOFlow' :'/marketPersonalInfo',
      pageIndex: isJointAccount ? apiResponse.employmentInfo?.joint != undefined ? 'primary' : apiResponse.personalInfo?.joint != undefined ? 3 : 2
                                :  apiResponse.employmentInfo?.primary != undefined ? 'primary' : 1 , 
    }
  }else if(apiResponse?.personalInfo != undefined){
    moveToSection = {
      selectedSection: 'PersonalInfo',
      selectedSubPage: '',
      pathname: '/marketPersonalInfo',
      pageIndex: isJointAccount ? apiResponse.personalInfo?.joint != undefined ? 3 : 1
                                :  apiResponse.personalInfo?.primary != undefined ? 1 : 0 , 
    }
  }else if(apiResponse?.account != undefined){
    moveToSection = {
      selectedSection: 'PersonalInfo',
      selectedSubPage: '',
      pathname: '/marketPersonalInfo',
      pageIndex: 0 , 
    }
  }else{
    moveToSection = {
      selectedSection: '',
      selectedSubPage: '',
      pathname: '/marketOpenAccount',
      pageIndex: 0 , 
    }
  }

  // Object.keys(apiResponse.account).forEach(key=>modelState.account[key]=apiResponse.account[key])
  apiResponse?.account != undefined && Object.keys(apiResponse.account).forEach(key=>modelState.account[key]=apiResponse.account[key])
  apiResponse?.personalInfo != undefined && Object.keys(apiResponse.personalInfo).forEach(key=>modelState.personalInfo['primary']=apiResponse.personalInfo['primary'])
  apiResponse?.contactInfo != undefined && Object.keys(apiResponse.contactInfo).forEach(key=>modelState.contactInfo['primary']=apiResponse.contactInfo['primary'])
  apiResponse?.address != undefined && Object.keys(apiResponse.address).forEach(key=>modelState.addressInfo['primary']=apiResponse.address['primary'])
  apiResponse?.employmentInfo != undefined && Object.keys(apiResponse.employmentInfo).forEach(key=>modelState.employmentInfo['primary']=apiResponse.employmentInfo['primary'])
  apiResponse?.financialInfo != undefined && Object.keys(apiResponse.financialInfo).forEach(key=>modelState.financialInfo['primary']=apiResponse.financialInfo['primary'])
  apiResponse?.militaryInfo != undefined && Object.keys(apiResponse.militaryInfo).forEach(key=>modelState.militaryInfo['primary']=apiResponse.militaryInfo['primary'])



  apiResponse?.personalInfo?.joint != undefined && Object.keys(apiResponse.personalInfo).forEach(key=>modelState.personalInfo['secondary']=apiResponse.personalInfo['joint'])
  apiResponse?.contactInfo?.joint != undefined && Object.keys(apiResponse.contactInfo).forEach(key=>modelState.contactInfo['secondary']=apiResponse.contactInfo['joint'])
  apiResponse?.address?.joint != undefined && Object.keys(apiResponse.address).forEach(key=>modelState.addressInfo['secondary']=apiResponse.address['joint'])
  apiResponse?.employmentInfo?.joint != undefined && Object.keys(apiResponse.employmentInfo).forEach(key=>modelState.employmentInfo['secondary']=apiResponse.employmentInfo['joint'])
  apiResponse?.financialInfo?.joint != undefined && Object.keys(apiResponse.financialInfo).forEach(key=>modelState.financialInfo['secondary']=apiResponse.financialInfo['joint'])
  apiResponse?.militaryInfo?.joint != undefined && Object.keys(apiResponse.militaryInfo).forEach(key=>modelState.militaryInfo['secondary']=apiResponse.militaryInfo['joint'])
  apiResponse?.riskProfile != undefined && Object.keys(apiResponse.riskProfile).forEach(key=>modelState.riskProfile[key]=apiResponse.riskProfile[key])
  apiResponse?.brokerDealer != undefined && Object.keys(apiResponse.brokerDealer).forEach(key=>modelState.brokerDealer[key]=apiResponse.brokerDealer[key])

  if(apiResponse.beneficiaries != undefined){
    // Object.keys(apiResponse.beneficiaries).forEach(key=>modelState.beneficiaries[key]=apiResponse.beneficiaries[key])

    const savedBeneficiaryData = {
        beneficiaryPrimaryDataArray: apiResponse.beneficiaries?.primary.map(({
                                                                    distributionPer: distributionPercentage,
                                                                    relation: relationToAccHolder,
                                                                    email:emailAdd,
                                                                    ...rest
                                                                  }) => ({
                                                                    distributionPercentage,
                                                                    relationToAccHolder,
                                                                    emailAdd,
                                                                    ...rest
                                                                  }))
        ,
        beneficiaryContingentDataArray: apiResponse.beneficiaries?.contingent.map(({
                                                                    distributionPer: distributionPercentage,
                                                                    relation: relationToAccHolder,
                                                                    email:emailAdd,
                                                                    ...rest
                                                                  }) => ({
                                                                    distributionPercentage,
                                                                    relationToAccHolder,
                                                                    emailAdd,
                                                                    ...rest
                                                                  }))
        ,
        valid: false,
        toFocus: "",
        savedRelationshipDropDownData: []
    }



    if( savedBeneficiaryData?.beneficiaryPrimaryDataArray != undefined &&  savedBeneficiaryData?.beneficiaryPrimaryDataArray != null)
    {
      savedBeneficiaryData.beneficiaryPrimaryDataArray.forEach((item, index) => {

        if(item?.dateOfBirth && item.dateOfBirth != ''){
          (async() => {
            const decryptedDOB = await decrypt(item.dateOfBirth);
            item.beneficiaryDOB = decryptedDOB
          })()
        }else{
          item.beneficiaryDOB = ''
        }
  
  
        if(item?.ssn){
          (async() => {
            const decryptedSSN = await decrypt(item.ssn);
            item.beneficiarySSN = decryptedSSN
            item.ssn = decryptedSSN
  
            item.ssnBenData = {
              "ssnOne": decryptedSSN.slice(0,3),
              "maskedssnOne": "",
              "ssnTwo": decryptedSSN.slice(3,5),
              "maskedssnTwo": "",
              "ssnThree": decryptedSSN.slice(5,9),
              "maskedssnThree": "",
              "fullSSN": decryptedSSN,
              "errorOneText": "",
              "errorTwoText": "",
              "errorThreeText": "",
              "readOnlySsnOne": true,
              "readOnlySsnTwo": true,
              "typeSsnOne": "password",
              "typeSsnTwo": "password",
              "retrievedState": true,
              "isSSNOneError": false,
              "isSSNTwoError": false,
              "isSSNThreeError": false,
              "maskedFullSSN": ""
          }
          })()
        }
  
        item.beneficiaryType = item.beneficiaryType == 'individuals' ? 'individuals' : 'other_individuals'

        if(item.relationshipId == 'new'){
          item.relationshipId = `new-${index}`
        }
  
        return item
      })
  
    }

    
    if( savedBeneficiaryData?.beneficiaryContingentDataArray != undefined &&  savedBeneficiaryData?.beneficiaryContingentDataArray != null)
    {
      savedBeneficiaryData.beneficiaryContingentDataArray.forEach((item,index) => {

        if(item?.dateOfBirth  && item.dateOfBirth != ''){
          (async() => {
            const decryptedDOB = await decrypt(item.dateOfBirth);
            item.beneficiaryDOB = decryptedDOB
          })()
        }else{
          item.beneficiaryDOB = ''
        }
  
  
        if(item?.ssn){
          (async() => {
            const decryptedSSN = await decrypt(item.ssn);
            item.beneficiarySSN = decryptedSSN
            item.ssn = decryptedSSN
  
            item.ssnBenData = {
              "ssnOne": decryptedSSN.slice(0,3),
              "maskedssnOne": "",
              "ssnTwo": decryptedSSN.slice(3,5),
              "maskedssnTwo": "",
              "ssnThree": decryptedSSN.slice(5,9),
              "maskedssnThree": "",
              "fullSSN": decryptedSSN,
              "errorOneText": "",
              "errorTwoText": "",
              "errorThreeText": "",
              "readOnlySsnOne": true,
              "readOnlySsnTwo": true,
              "typeSsnOne": "password",
              "typeSsnTwo": "password",
              "retrievedState": true,
              "isSSNOneError": false,
              "isSSNTwoError": false,
              "isSSNThreeError": false,
              "maskedFullSSN": ""
          }
          })()
        }
  
        item.beneficiaryType = item.beneficiaryType == 'individuals' ? 'individuals' : 'other_individuals'
  
        if(item.relationshipId == 'new'){
          item.relationshipId = `new-${index}`
        }

        
        return item
      })
  
    }

    modelState.beneficiaries.savedBeneficiaryData = savedBeneficiaryData


  }


 
  // if(modelState.contactInfo?.primary != undefined){
  //   modelState.contactInfo.primary.primaryEmailAddress = modelState.contactInfo.primary.maskedPrimaryEmailAddress
  // } 
  



  apiResponse?.trustedContact != undefined && Object.keys(apiResponse.trustedContact).forEach(key=>modelState.trustedContact[key]=apiResponse.trustedContact[key])
  apiResponse?.deliveryPreferences != undefined && Object.keys(apiResponse.deliveryPreferences).forEach(key=>modelState.deliveryPreferences[key]=apiResponse.deliveryPreferences[key])

  modelState.applicationStatus = apiResponse.applicationStatus
  modelState.applicationVCMId = apiResponse.vcmId
  modelState.applicationId = apiResponse.applicationId
  // modelState.jointVcmId = apiResponse.jointVcmId
  modelState.isPrimary = apiResponse.isPrimary
  modelState.isRedirectionFlow = true



  // if partially fill till the specific step and saved

  if(apiResponse.personalInfo == undefined || apiResponse.employmentInfo == undefined ){

    const modifiedProfileInformationData = setProfileInformationToLocalStorage(profileInformationData)

    if(apiResponse.personalInfo == undefined){
      modelState.personalInfo.primary = modifiedProfileInformationData.personalInfo
      modelState.addressInfo.primary = modifiedProfileInformationData.addressInfo
      modelState.contactInfo.primary = modifiedProfileInformationData.contactInfo
    }

    if(apiResponse.employmentInfo == undefined){
      modelState.employmentInfo.primary = modifiedProfileInformationData.employmentInfo
      modelState.financialInfo.primary = modifiedProfileInformationData.financialInfo
      modelState.militaryInfo.primary = modifiedProfileInformationData.militaryInfo
    }
   
  }


  if(modelState?.employmentInfo != undefined){
    if(modelState.employmentInfo?.primary != undefined){
      modelState.employmentInfo.primary.industry = getKey('industry',modelState.employmentInfo.primary.industry ,masterLookupStateData)
      modelState.employmentInfo.primary.status = getKey('brkg_employment_status', modelState.employmentInfo.primary.status,masterLookupStateData)
      modelState.employmentInfo.primary.primarySource = getKey('prim_src_income', modelState.employmentInfo.primary.primarySource,masterLookupStateData)
    }

    if(modelState.employmentInfo?.secondary != undefined){
      modelState.employmentInfo.secondary.industry = getKey('industry',modelState.employmentInfo.secondary.industry ,masterLookupStateData)
      modelState.employmentInfo.secondary.status = getKey('brkg_employment_status',modelState.employmentInfo.secondary.status ,masterLookupStateData)
      modelState.employmentInfo.secondary.primarySource = getKey('prim_src_income',modelState.employmentInfo.secondary.primarySource ,masterLookupStateData)
    } 
    
  }

    
  if(modelState?.financialInfo != undefined){
    if(modelState.financialInfo?.primary != undefined){
      modelState.financialInfo.primary.annualIncome = getKey('brkg_annual_income',modelState.financialInfo?.primary.annualIncome ,masterLookupStateData)
      modelState.financialInfo.primary.netWorth = getKey('brkg_net_worth', modelState.financialInfo?.primary.netWorth,masterLookupStateData)
      modelState.financialInfo.primary.taxFilingStatus = getKey('tax_filling_status', modelState.financialInfo?.primary.taxFilingStatus,masterLookupStateData)
    }

    // if(modelState.employmentInfo?.secondary != undefined){
    //   modelState.employmentInfo.secondary.industry = getKey('industry',modelState.employmentInfo.secondary.industry ,masterLookupStateData)
    //   modelState.employmentInfo.secondary.status = getKey('brkg_employment_status',modelState.employmentInfo.secondary.status ,masterLookupStateData)
    //   modelState.employmentInfo.secondary.primarySource = getKey('prim_src_income',modelState.employmentInfo.secondary.primarySource ,masterLookupStateData)
    // } 
    
  }


  if(modelState?.financialInfo != undefined){
    if(modelState.financialInfo?.primary != undefined){
      modelState.financialInfo.primary.annualIncome = getKey('brkg_annual_income',modelState.financialInfo.primary.annualIncome ,masterLookupStateData)
      modelState.financialInfo.primary.netWorth = getKey('brkg_net_worth', modelState.financialInfo.primary.netWorth,masterLookupStateData)
      modelState.financialInfo.primary.taxFilingStatus = getKey('tax_filling_status', modelState.financialInfo.primary.taxFilingStatus,masterLookupStateData)
      modelState.financialInfo.primary.taxBracket = `${modelState.financialInfo.primary.taxBracket}`;
    }

    if(modelState.financialInfo?.secondary != undefined){
      modelState.financialInfo.secondary.annualIncome = getKey('brkg_annual_income',modelState.financialInfo.secondary.annualIncome ,masterLookupStateData)
      modelState.financialInfo.secondary.netWorth = getKey('brkg_net_worth',modelState.financialInfo.secondary.netWorth ,masterLookupStateData)
      modelState.financialInfo.secondary.taxFilingStatus = getKey('tax_filling_status',modelState.financialInfo.secondary.taxFilingStatus ,masterLookupStateData)
      modelState.financialInfo.secondary.taxBracket = `${modelState.financialInfo.secondary.taxBracket}`;
    } 
    
  }



  // variable interchange within stored values
  if(modelState?.militaryInfo != undefined){
    if(modelState.militaryInfo?.primary != undefined){
      modelState.militaryInfo.primary.hasServed = (modelState.militaryInfo.primary.hasServed == true || modelState.militaryInfo.primary.hasServed == 'Y') ? 'Y': 'N' 

      modelState.militaryInfo.primary.rankInfo = modelState.militaryInfo.primary.rank
      modelState.militaryInfo.primary.militaryStatusInfo = modelState.militaryInfo.primary.militaryStatus
      modelState.militaryInfo.primary.branchInfo = modelState.militaryInfo.primary.branch

      modelState.militaryInfo.primary.rank = getKeyBasedOnValue(modelState.militaryInfo.primary.rank, 'rank',masterLookupStateData)
      modelState.militaryInfo.primary.militaryStatus = getKey('mil_status',modelState.militaryInfo.primary.militaryStatus,masterLookupStateData)
      modelState.militaryInfo.primary.branch = getKey('mil_serv_branch',modelState.militaryInfo?.primary?.branch,masterLookupStateData) 
    }

    if(modelState.militaryInfo?.secondary != undefined){
      modelState.militaryInfo.secondary.hasServed = (modelState.militaryInfo.secondary.hasServed == true || modelState.militaryInfo.secondary.hasServed == 'Y' ) ? 'Y': 'N' 

      modelState.militaryInfo.secondary.rankInfo = modelState.militaryInfo.secondary.rank
      modelState.militaryInfo.secondary.militaryStatusInfo = modelState.militaryInfo.secondary.militaryStatus
      modelState.militaryInfo.secondary.branchInfo = modelState.militaryInfo.secondary.branch

      modelState.militaryInfo.secondary.rank = getKeyBasedOnValue(modelState.militaryInfo.secondary.rank, 'rank',masterLookupStateData)
      modelState.militaryInfo.secondary.militaryStatus = getKey('mil_status',modelState.militaryInfo.secondary.militaryStatus,masterLookupStateData)
      modelState.militaryInfo.secondary.branch = getKey('mil_serv_branch',modelState.militaryInfo?.secondary?.branch,masterLookupStateData)
    } 
    
  }


  // decrypting values
  if(apiResponse?.trustedContact?.dob){
    (async() => {
      const decryptedDOB = await decrypt(apiResponse.trustedContact?.dob);
      modelState.trustedContact.dob = new Date(decryptedDOB)
    })()
  }

  // change this terinary operator
  if(apiResponse?.personalInfo?.primary?.dob != (undefined || '') && apiResponse?.personalInfo?.primary?.dob.length > 20){
    // console.log('DOB 2')
  
    (async() => {
      const decryptedDOB = await decrypt(apiResponse.personalInfo.primary.dob);
      modelState.personalInfo.primary.maskedDob = decryptedDOB
      // modelState.personalInfo.secondary.dob = ''
    })()
  }

  if(apiResponse?.personalInfo?.primary?.ssn != undefined && apiResponse?.personalInfo?.primary?.ssn.length > 20){
    (async() => {
      const decryptedSSN = await decrypt(apiResponse.personalInfo.primary.ssn);
      modelState.personalInfo.primary.withoutMaskedSSN = decryptedSSN
      // modelState.personalInfo.secondary.ssn = ''
    })()
  }


  if(apiResponse?.personalInfo?.joint?.dob){
    (async() => {
      const decryptedDOB = await decrypt(apiResponse.personalInfo.joint.dob);
      modelState.personalInfo.secondary.maskedDob = decryptedDOB
      // modelState.personalInfo.secondary.dob = ''
    })()
  }

  if(apiResponse?.personalInfo?.joint?.ssn){
    (async() => {
      const decryptedSSN = await decrypt(apiResponse.personalInfo.joint.ssn);
      modelState.personalInfo.secondary.withoutMaskedSSN = decryptedSSN

      // modelState.personalInfo.secondary.ssn = ''
    })()
  }

  // storing key or the api response value 
  if(apiResponse?.personalInfo?.joint?.relationship){

   // let relationshipInfo = "";
    // for (let i = 0; i < masterLookupStateData?.relationship?.value?.length; i++) {
    //     if (masterLookupStateData?.relationship?.value[i].value == apiResponse.personalInfo.joint.relationship) {
    //       relationshipInfo = masterLookupStateData?.relationship?.value[i].key;
    //     }
    // }
    modelState.personalInfo.secondary.relationship = getKey('relationship',apiResponse.personalInfo.joint.relationship,masterLookupStateData)
    modelState.personalInfo.secondary.RelationshipJoint = apiResponse.personalInfo.joint.relationship
  }


  if(apiResponse?.brokerDealer && apiResponse?.brokerDealer?.primary?.pep?.countryOfOffice && typeof(apiResponse?.brokerDealer?.primary?.pep?.countryOfOffice) == 'string'){

    modelState.brokerDealer.primary.countryOfOfficeInfo = apiResponse.brokerDealer.primary.pep.countryOfOffice
    modelState.brokerDealer.primary.pep.countryOfOffice = getKey('country_list',apiResponse.brokerDealer.primary.pep.countryOfOffice ,masterLookupStateData)

    if(apiResponse?.brokerDealer?.joint?.pep?.countryOfOffice){

      modelState.brokerDealer.joint.countryOfOfficeInfo = apiResponse.brokerDealer.joint.pep.countryOfOffice
      modelState.brokerDealer.joint.pep.countryOfOffice = getKey('country_list',apiResponse.brokerDealer.joint.pep.countryOfOffice ,masterLookupStateData)

    }

  }

  if(apiResponse?.brokerDealer && apiResponse?.brokerDealer?.primary?.relatedToEmployeeOfAnotherBrokerDealer.relationship && typeof(apiResponse?.brokerDealer?.primary?.relatedToEmployeeOfAnotherBrokerDealer.relationship) == 'string'){

    modelState.brokerDealer.primary.relatedToEmployeeOfAnotherBrokerDealer.relationship = getKey('brkg_relationship',apiResponse.brokerDealer.primary.relatedToEmployeeOfAnotherBrokerDealer.relationship ,masterLookupStateData)

    if(apiResponse?.brokerDealer?.joint?.relatedToEmployeeOfAnotherBrokerDealer.relationship){
      modelState.brokerDealer.joint.relatedToEmployeeOfAnotherBrokerDealer.relationship =  getKey('brkg_relationship',apiResponse.brokerDealer.joint.relatedToEmployeeOfAnotherBrokerDealer.relationship ,masterLookupStateData)
    }

  }
  if(apiResponse?.brokerDealer && apiResponse?.brokerDealer?.primary?.relatedToBrokerDealer.relationship && typeof(apiResponse?.brokerDealer?.primary?.relatedToBrokerDealer.relationship) == 'string'){
    modelState.brokerDealer.primary.relatedToBrokerDealer.relationship = getKey('brkg_relationship',apiResponse.brokerDealer.primary.relatedToBrokerDealer.relationship ,masterLookupStateData)
    if(apiResponse?.brokerDealer?.joint?.relatedToBrokerDealer.relationship){
      modelState.brokerDealer.joint.relatedToBrokerDealer.relationship =  getKey('brkg_relationship',apiResponse.brokerDealer.joint.relatedToBrokerDealer.relationship ,masterLookupStateData)
    }
  }

  if(modelState?.trustedContact?.address != undefined){
    const line1 = modelState.trustedContact.address.addressLine2
    const line2 = modelState.trustedContact.address.addressLine1
    modelState.trustedContact.address.addressLine1 = line1
    modelState.trustedContact.address.addressLine2 = line2
  }
 
  if(apiResponse?.bankAccountInfo != undefined){
    
    (async() => {
      const transformedArr = apiResponse.bankAccountInfo.brokerageBankList && apiResponse.bankAccountInfo.brokerageBankList.length > 0 ? await getDecryptedBankDetailsRedirectFlow(apiResponse.bankAccountInfo.brokerageBankList) : []
      modelState.addBankDetails.allBankList =apiResponse.bankAccountInfo?.existingBankInfo && apiResponse.bankAccountInfo.existingBankInfo.length > 0 ? await getDecryptedBankDetailsRedirectFlow(apiResponse.bankAccountInfo.existingBankInfo) : []
      modelState.addBankDetails.selectedBankInfo = apiResponse.bankAccountInfo.brokerageBankList && apiResponse.bankAccountInfo.brokerageBankList.length > 0 ? getGiActResult(transformedArr) : []
      
      if(apiResponse?.accountFunding != undefined && transformedArr){
        const selectedFundInfo = apiResponse.accountFunding?.selectedBankInfo && apiResponse.accountFunding.selectedBankInfo.length > 0 ? addAdditionalPropertiesForSelectedList(apiResponse.accountFunding.selectedBankInfo, transformedArr) : []

        const fundLaterOptionSelected = apiResponse.accountFunding?.selectedBankInfo && apiResponse.accountFunding.selectedBankInfo.length === 0 ? true : false

        modelState.accountFunding.allAvailableBankAccounts = accountFundingBuilder(selectedFundInfo, transformedArr, fundLaterOptionSelected)
        modelState.accountFunding.selectedBankInfo = selectedFundInfo
      }
     


    })()
    
  } 

  if(apiResponse?.accountFunding != undefined){

    // TODO
   // modelState.accountFunding.selectedBankInfo = apiResponse.accountFunding?.selectedBankInfo && apiResponse.accountFunding.selectedBankInfo.length > 0 ? addAdditionalProperties(apiResponse.accountFunding.selectedBankInfo) : []
    modelState.accountFunding.fundNow = apiResponse.accountFunding.fundNow

    modelState.accountFunding.recurringFundingAccount = apiResponse.accountFunding.recurringFundingAccount
    modelState.accountFunding.recurringFundAccountList = prepareRecurringAccountList(apiResponse.accountFunding.recurringFundAccountList)
    modelState.accountFunding.fundFromExistingAccount = apiResponse.accountFunding.fundFromExistingAccount
    modelState.accountFunding.transferAssets = apiResponse.accountFunding.transferAssets
    modelState.accountFunding.fundAsDirectRollover = apiResponse.accountFunding.fundAsDirectRollover
    modelState.accountFunding.iraContributionYear = getValidValue(apiResponse.accountFunding?.iraContributionYear, '')
  }


  if(apiResponse?.bankAccountInfo != undefined){

    modelState.addBankDetails.addExistingBankSelect = apiResponse?.bankAccountInfo?.brokerageBankList ? apiResponse?.bankAccountInfo?.brokerageBankList.length > 0 ? true : false : false
    modelState.addBankDetails.addBankLaterSelect = apiResponse?.bankAccountInfo?.brokerageBankList ? apiResponse.bankAccountInfo.brokerageBankList.length === 0 ? true : false : false
    // modelState.addBankDetails.addNewBankAccountSelect = apiResponse?.bankAccountInfo?.brokerageBankList ? apiResponse.bankAccountInfo.brokerageBankList.length === 0 ? true : false : false
    // modelState.addBankDetails.selectedBankInfo = apiResponse.bankAccountInfo.brokerageBankList
  } 

  modelState.moveToSection = moveToSection

  return modelState

}