import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { localStateManagementActions, loginActions,memberDashboardActions, analytics, accOpeningActions,profileSettingsAction, msrActions, liveChatActions,masterRegistrationAccountActions } from 'shared/Actions';
import AccountHolder from './AccountHolder';
import { postMarketNAOInfo,updateMarketNAOInfo,clearMarketNAOInfo } from 'shared/Actions/MarketNAOActions';
import {
    getTransHolderList,resetTransactionHolder
} from '../../../shared/Actions/TransactionHistoryActions';

export const mapStateToProps = state => ({
    localStateData: state.localStateManagementReducerData,
    memberDashboardData: state.memberDashboardData,
    memberNewDashboardReducer:state.memberNewDashboardReducer,
    dashboardDocumentData :  state.documentPreferenceData,
    messageCenterData :  state.messageCenterData,
    initialState: state.initialAppData,
    dashBoardLinkData: state.dashBoardLinkReducer,
    masterRegistrationAccountData: state.masterRegistrationAccountData,
    loginState: state.loginData, 
    msrFlag: state?.msrFlowDetails?.isMSRFlow,
    accOpeningData: state.accOpeningReducerData,
    profileInformationData : state.profileInformationData,
    transactionHolderData: state.transactionHolderData,
    marketSavedApplications:state.marketSavedApplications
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...memberDashboardActions,
    ...analytics,
    ...accOpeningActions,
    getProfileBasicInformations: profileSettingsAction.getProfileBasicInformations,
    getMemberProfileInformation: profileSettingsAction.getMemberProfileInformations,
    ...loginActions,
    ...msrActions,
    ...liveChatActions,
    postMarketNAOInfo:postMarketNAOInfo,
    updateMarketNAOInfo:updateMarketNAOInfo,
    clearMarketNAOInfo:clearMarketNAOInfo,
    getTransHolderList,
    resetTransactionHolder,
    getAccountList: masterRegistrationAccountActions.getAccountsList,
    clearMasterRegInfo: masterRegistrationAccountActions.clearMasterRegInfo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHolder));
