// README: documentList: DocumentCenter --> Web App
//         documentRecords: DocumentCenter --> Mobile App
//         DocumentDashboardList: DashboardDocumentCenter --> Web App & Mobile
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as DocumentCenterActionTypes from '../ReduxConstants/DocumentCenterConstants';
import * as DownloadFileActionTypes from '../ReduxConstants/DownloadFileConstants';
import { removeFileExtension, sortDocumentList } from '../Helpers/Utils';
import { removeServicesAccountHeader ,removeXVCMAuthorizationtHeader} from 'shared/Network/Services';
import viewIcon from '../../assets/icon_pdf.png';
import downloadIcon from '../../assets/saveIcon_Blue.png';
import React from 'react';
import { DownloadFile } from 'common';
import { convert_UTC_TO_EST_Timezone } from 'utils/utils';

export const initialState = {
    isFetching: false,
    isLoading: false,
    isError: false,
    isErrorDC: false,
    isSuccess: false,
    documentList: [],
    documentRecords: [],
    dashboardDocumentList: [],
    formattedDocList: [],
    selectedYear: new Date().getFullYear(),
    transferDocumentSuccess: false,
    transferDocumentFailure: false,
    documentListData: [],
    documentListSuccess: false,
    documentListErrorMessage: '',
    documentPdfListData:{},
    documentPdfListSuccess: false,
    documentPdfListErrorMessage:'',
    uploadDocumentSuccessStatus: false,
    uploadDocumentErrorMessage: ''

};

const {
    GET_SINGLE_DOCUMENT_ERROR,
    GET_SINGLE_DOCUMENT_LOADER,
    GET_SINGLE_DOCUMENT_SUCCESS,
    GET_SINGLE_DOCUMENT,
    GET_USER_DOCUMENT_LIST_ERROR,
    GET_USER_DOCUMENT_LIST_LOADER,
    GET_USER_DOCUMENT_LIST_SUCCESS,
    GET_TRANSFER_DOCUMENT_LIST_ERROR,
    GET_TRANSFER_DOCUMENT_LIST_SUCCESS,
    GET_TRANSFER_DOCUMENT_LIST_FAILURE,
    RESET_TRANSFER_DOCUMENT,
    SEARCH_DOCUMENTS_ERROR,
    SEARCH_DOCUMENTS_LOADER,
    SEARCH_DOCUMENTS_SUCCESS,
    SEARCH_DOCUMENTS,
    GET_DOCUMENTID_ERROR,
    GET_DOCUMENTID_SUCCESS,
    GET_DOCUMENT_PDF_LIST_ERROR,
    GET_DOCUMENT_PDF_LIST_SUCCESS,
    SET_FORMATTED_DASHBOARD_USER_DOCUMENT,
    SET_DOCUMENT_CENTER_SELECTED_YEAR,
} = DocumentCenterActionTypes;

const {
    UPDATE_DOCUMENT_META_DATA_SUCCESS,
    UPDATE_DOCUMENT_META_DATA_FAILURE
} = DownloadFileActionTypes;

const getDefaultValue = (strVal, defaultVal='') => {
    return strVal ? strVal : defaultVal
}

const handleViewableInfo = (viewable)=>
{
    const isViewableValue = getDefaultValue(viewable,'');
    if(isViewableValue == true || isViewableValue == 'true')
    {
        return "Yes";
    }
    else if(isViewableValue == false || isViewableValue == 'false')
    {
        return "No";
    }
    else
    {
        return "";
    }
}

function documentPreferenceReducer(state = initialState, action = { payload: undefined }) {
    switch (action.type) {
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action,
            };
        }

        case GET_USER_DOCUMENT_LIST_LOADER: {
            return {
                ...state,
                ...action,
                isFetching: true,
                isErrorDC: false,
                isSuccess: false,
            };
        }
        case GET_USER_DOCUMENT_LIST_SUCCESS: {

            removeServicesAccountHeader();
            const { payload = [] } = action;
            const documentRecords = sortDocumentList(payload, 'descending');
            const documents = payload.map((doc,index) => {
                let DocumentTitle;
                let AccountNameValue;
                const {
                    fileName,
                    accountNumber,
                    accountType,
                    createdDate,
                    usaaReceivedDate,
                    docCategory,
                    nodeId,
                    alternateDocumentId,
                    alternateDocumentLink,
                    documentSource,
                    accountDescriptor,
                    title,
                    usaaFormId,
                    readIndicator,
                    viewable,
                    isDeleted,
                    contentType
                } = doc;

                if (title) {
                    DocumentTitle = removeFileExtension(title);
                } else {
                    DocumentTitle = removeFileExtension(fileName);
                }

                let previewFlag = "";
                if(contentType !=undefined  && contentType !=null && contentType !="" )
                {
                    const convertToLowerCase = contentType.toLowerCase();
                    previewFlag = convertToLowerCase?.includes('pdf') ? "preview" : "download";
                }

                if (accountDescriptor?.length > 0) {
                    AccountNameValue = accountDescriptor;
                }
                // Weird logic because accountType will come in as array string '[]'
                else if (accountNumber?.length > 0 && accountType?.length > 2) {
                    const maskLength = Math.round(accountNumber.length / 2);
                    const x = 'X'.repeat(maskLength);
                    const maskedAccountNumber = x + accountNumber.substring(maskLength);
                    AccountNameValue = `${accountType.substring(1, accountType.length - 1)}-${maskedAccountNumber}`;
                } else {
                    AccountNameValue = '_';
                }

                const { selectedYear } = state;

                let documentDate = createdDate;
                if (selectedYear > 2019) {
                    documentDate = createdDate;
                } else if (usaaReceivedDate !== "") {
                    documentDate = usaaReceivedDate;
                }
                else{
                    documentDate = createdDate;
                }

                let documentCategory = docCategory;

                if (usaaFormId === 'MFMTH' || usaaFormId === 'MFANNCON') {
                    documentCategory = 'Statements';
                } else if (usaaFormId === 'MUTCONF') {
                    documentCategory = 'Confirmation';
                } else if (
                    usaaFormId === 'MUT998' ||
                    usaaFormId === 'C1099R' ||
                    usaaFormId === 'C1099B' ||
                    usaaFormId === 'C5498'
                ) {
                    documentCategory = 'Tax Documents';
                }

                // if (docCategory === 'Legal_Compliance') {
                //     documentCategory = 'General';
                // }
                
                let portfolioPlannerRecommendationFlag = true;
                let accountServicingFlag = true;
                if (selectedYear >= 2020 && docCategory === 'Confirm') {
                    documentCategory = 'Confirmation';
                }

                if(docCategory == 'Payment')
                {
                    documentCategory = 'Transactions';
                }

                if(docCategory == 'Portfolio_Planner_Recommendation')
                {
                    portfolioPlannerRecommendationFlag = false;
                    documentCategory = 'Recommendation';
                }

                if(docCategory == 'General')
                {
                    documentCategory = 'Legal Compliance';
                }

                if(docCategory == 'Account_Servicing')
                { 
                    accountServicingFlag=false;
                    documentCategory = 'Account Services';
                }

                if(portfolioPlannerRecommendationFlag && accountServicingFlag && docCategory && docCategory.includes('_'))
                {
                    
                    documentCategory = docCategory.replace("_", " ")?.replace("_"," ");
                }
                let showIcon = null;

                if(previewFlag == "preview")
                {
                    showIcon = viewIcon;
                }
                else
                {
                    showIcon = downloadIcon;
                }


                return {
                    alternateDocumentLink,
                    fileName,
                    NodeId: nodeId,
                    DocumentTitle,
                    usaaFormId,
                    Account: {
                        AccountNameValue,
                        AccountNameText: 'Account',
                    },
                    DocumentDate: {
                        DocumentDateText: 'Delivery Date',
                        DocumentDateValue: documentDate,
                    },
                    Category: {
                        CategoryText: 'Category',
                        CategoryValue: documentCategory,
                    },
                    Status: {
                        StatusText: 'Status',
                        StatusValue: '',
                    },
                    AlternateDocumentId: alternateDocumentId,
                    DocumentSource: documentSource,
                    readIndicator,
                    accountNumber,
                    viewable: handleViewableInfo(viewable),
                    documentDate: convert_UTC_TO_EST_Timezone(documentDate),
                    isDeleted,
                    documentCategory,
                    accountDescriptor,
                    documentTitle: DocumentTitle,
                    download: [
                        <DownloadFile
                        alternateDocumentId={alternateDocumentId}
                        downloadUrl={alternateDocumentLink}
                        nodeId={nodeId}
                        documentSource={documentSource}
                        docCategory={documentCategory}
                        iconSrc={showIcon}
                        text={DocumentTitle}
                        saveAs={fileName}
                        optionalScreenReaderMsg={DocumentTitle}
                        unread={true}
                        opens={'PDF'}
                        isDocumentCenter={true}
                        linkStyle={{textAlign:"left !important"}}
                        previewFlag={previewFlag}

                    />
                    ],
                };
                
            });

            // Sort by Created date desending order
            const sortedDocuments = documents.slice().sort((a, b) => {
                return new Date(b.DocumentDate.DocumentDateValue) - new Date(a.DocumentDate.DocumentDateValue);
            });

            return {
                ...state,
                documentRecords,
                documentList: sortedDocuments,
                isFetching: false,
                isLoading: false,
                isError: false,
                isErrorDC: false,
                isSuccess: true,
            };
        }

        case GET_TRANSFER_DOCUMENT_LIST_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true, transferDocumentSuccess: true , transferDocumentFailure:false};
        }

        case GET_TRANSFER_DOCUMENT_LIST_FAILURE: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true, transferDocumentSuccess: false, transferDocumentFailure:true, errorMessage:(action?.payload?.data?.Error)? action?.payload?.data?.Error : 'Technical Error'};
        }
        case GET_TRANSFER_DOCUMENT_LIST_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true, transferDocumentSuccess: false, transferDocumentFailure:true, errorMessage:(action?.payload?.data?.Error)? action?.payload?.data?.Error : 'Technical Error' };
        }
        case RESET_TRANSFER_DOCUMENT: {
            return { ...state, transferDocumentSuccess: false, transferDocumentFailure:false};
        }

        case SET_DOCUMENT_CENTER_SELECTED_YEAR: {
            return {
                ...state,
                selectedYear: action.payload,
                documentList: []
            };
        }

        // Used In Dashbard
        case SEARCH_DOCUMENTS_LOADER:
        case GET_SINGLE_DOCUMENT_LOADER: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }

        case SEARCH_DOCUMENTS:
        case GET_SINGLE_DOCUMENT: {
            return {
                ...state,
                ...action,
            };
        }

        case SEARCH_DOCUMENTS_SUCCESS:
        case GET_SINGLE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                ...action,
                dashboardDocumentList: action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }

        case GET_USER_DOCUMENT_LIST_ERROR: {
            removeServicesAccountHeader();
            return {
                ...state,
                ...action,
                documentList: [],
                isFetching: false,
                isLoading: false,
                isError: true,
                isErrorDC: true,
                isSuccess: false,
            };
        }
        case SEARCH_DOCUMENTS_ERROR:
        case GET_SINGLE_DOCUMENT_ERROR: {
            removeServicesAccountHeader()
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        case SET_FORMATTED_DASHBOARD_USER_DOCUMENT: {
            const { data } = action;
            return {
                ...state,
                formattedDocList: data,
            };
        }
        case UPDATE_DOCUMENT_META_DATA_SUCCESS: {
            const { data } = action.res;
            const fileData = data || {};
            const { documentList } = state;
            const updatedDocumentList = documentList.map((value)=> {
                if(value.NodeId === fileData.nodeId) return {...value, readIndicator: fileData.readIndicator}
                return {...value}
            })
            return {
                ...state,
                documentList: updatedDocumentList,
                isError: false,
            };
        }
        
        
        case UPDATE_DOCUMENT_META_DATA_FAILURE: {
            return {
                ...state,
                isError: true,
            }
        }

        case GET_DOCUMENT_PDF_LIST_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true,documentPdfListData: action.payload, documentPdfListSuccess: true,documentPdfListErrorMessage: ''};
        }

        case GET_DOCUMENT_PDF_LIST_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true,documentPdfListData: {}, documentPdfListSuccess: false, documentPdfListErrorMessage: 'error'};
        }

        case GET_DOCUMENTID_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true, documentListData: action.payload, documentListSuccess: true, documentListErrorMessage: ''};
        }

        case GET_DOCUMENTID_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true, documentListSuccess: false, documentListData:{}, documentListErrorMessage: 'error'};
        }



        case ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_SUCCESS: {
            return { ...state, ...action, isLoading: false, isError: false, isSuccess: true, uploadDocumentSuccessStatus: true, uploadDocumentErrorMessage:''};
        }
       
        case ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_ERROR: {

            return { ...state, ...action, isLoading: false, isSuccess: false, isError: true, uploadDocumentSuccessStatus: false, uploadDocumentErrorMessage: 'Error' };
        }

        case ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_LOADER: {
            return {...state,...action,isLoading: true,isError: false,isSuccess: false, uploadDocumentSuccessStatus: false, uploadDocumentErrorMessage:''};
        }



        default:
            return state;
    }
}

export default documentPreferenceReducer;
