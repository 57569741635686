import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
import routeConstants from 'routeConstants';
import {getDeviceRegUserPref } from 'modules/UserManagementAndSecurityModule/DeviceRegistrationHelpers/deviceRegistrationHelpers';
import '../Screens.css';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import OtpForm from '../../OtpForm';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WMessageBar, WStepper, WStepper as UserMngmtStepper, WSpinner } from '../../../../common';
import { changedHandler, validityHandler } from '../../FormUtils';
import data from './constants';
import styles from '../../UserManagementStyles';
import pages from '../JovConstants';
import envConfig from 'env/EnvConfig';

const { stepperColStyle } = styles.stepperStyle;
const assignObj = obj => obj;

export class EnterOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errMsg: null,
            valid: false,
            label: data.OneTimePasscode,
            type: 'number',
            rules: {
                isNumeric: true,
                length: 6,
                required: true,
            },
            cancelled: false,
            error: false,
            notificationMsg: {
                message: data.sentOtp,
                info: '',
            },
            backPath: routeConstants.signIn,
            currentRef: null,
            logOutRoute: {
                pathname: routeConstants.signIn,
            },
            redirect: false,
        };
        this.resendRef = React.createRef();
        this.inputRef = React.createRef();
        this.onNext = this.onNext.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        const { location, otp, otpDetails, manageLocalState } = this.props;
        
        const { message, jov } = location || {};
        const token = localStorage.getItem('token');
        const { verifyNextLink = '', onlineId = '', flow = '' } = otpDetails;
        if (!token && !verifyNextLink) {
            manageLocalState({ otpDetails: {} });
            this.handleLogOut();
        }
        
        if (message) {
            this.setState(state => ({
                currentRef: this.inputRef,
                verifyNextLink,
                onlineId,
                flow,
                notificationMsg: {
                    ...state.notificationMsg,
                    message: data.enterMsg,
                },
            }));
        } else if (jov) {
            this.setState(state => ({
                currentRef: this.inputRef,
                verifyNextLink,
                onlineId,
                flow,
                notificationMsg: {
                    ...state.notificationMsg,
                    message: data.sentOtp,
                    info: data.enterOtp + otp + data.proceed,
                },
            }));
        } else
            this.setState(state => ({
                currentRef: this.inputRef,
                verifyNextLink,
                onlineId,
                flow,
                notificationMsg: {
                    ...state.notificationMsg,
                    message: data.sentOtp,
                    info: '',
                },
            }));
        localStorage.setItem(data.otpVerify, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const {
                verifyOTPData,
                resendOTPCustomersData,
                resendOTPCustomers,
                recoverOnlineIdData,
                directVerifyOTPData,
                directReSendPhoneOTPData,
                directReSendPhoneOTP,
            } = this.props;
            let updatedState = { ...this.state };
            if ((verifyOTPData && verifyOTPData.result) || (directVerifyOTPData && directVerifyOTPData.result)) {
                updatedState = this.verifyOTPDataHelper();
            }
            if (resendOTPCustomersData && resendOTPCustomersData.result) {
                updatedState = this.resendOTPCustomers(resendOTPCustomersData);
                resendOTPCustomers('');
            }
            if (directReSendPhoneOTPData && directReSendPhoneOTPData.result) {
                updatedState = this.resendOTPDirectCustomers(directReSendPhoneOTPData);
                directReSendPhoneOTP('');
            }
            
            if (recoverOnlineIdData && recoverOnlineIdData.result) {
                updatedState = this.recoverOnlineIDHelper(recoverOnlineIdData);
            }
            this.updateStateFn(updatedState);
        }
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    analyticsLastTouch = (event) =>{
        analyticsFormAbandLastTouch(event);
    }

    onChange(event) {
        changedHandler(event, this);
    }

    onBlur() {
        validityHandler(this);
    }

    onBack = () => {
        const { location, otpDetails } = this.props;
        const { jov } = location || {};
        let backPath = routeConstants.signIn;
        if (otpDetails.flow) backPath = routeConstants.otpIvrSelect;
        if (jov) backPath = routeConstants.jointOwnerVerificationMobile;
        localStorage.clear();
        this.setState(state => ({ ...state, cancelled: true, backPath }));
    };

    onNext() {
        const { location, history, otpDetails } = this.props;
        const { jov, multiFactorAuthentication, uuid } = location || {};
        const { value, onlineId } = this.state;
        const { functionalityFlag, flow } = otpDetails;
        if (Number(value)) {
            if (flow) {
                const { verifyOtp, directVerifyPhoneOTP } = this.props;
                if (functionalityFlag === data.memberSignUpFlow) {
                    const payload = {
                        verifyOTP: value || '',
                        requestType: data.PHONE,
                    };
                    directVerifyPhoneOTP(payload);
                } else {
                    let payload = '';
                    if(functionalityFlag === 'recoverCustPwd' || functionalityFlag === 'signIn') {
                        payload = {
                            onlineId: uuid,
                            verifyOTP: value || '',
                            functionalityFlag,
                        };
                    } else {
                        payload = {
                            onlineId,
                            verifyOTP: value || '',
                            functionalityFlag,
                      };
                    }
                    verifyOtp(payload);
                }
            } else if (jov) {
                history.push({
                    pathname: routeConstants.jointOwnerVerificationOnlineId,
                    jov: true,
                });
            } else if (multiFactorAuthentication) {
                const { verifyOtp } = this.props;
                const payload = {
                    onlineId: onlineId || '',
                    verifyOTP: value || '',
                };

                verifyOtp(payload);
            }
        } else {
            this.setState(state => ({
                ...state,
                errMsg: data.wrongOtp,
            }));
        }
    }

    updateStateFn = updatedState => {
        this.setState({ ...updatedState });
    };

    resendOTPCustomers = (resendOTPCustomersData) => {
        const updatedState = this.state;
        const { msgForInvalidPrimNum } = userConstants;
        const { errorCode = '', result } = resendOTPCustomersData;
        if (errorCode === 0) {
            updatedState.notificationMsg = { message: data.resent };
        } else if (errorCode === 2012) {
            updatedState.notificationMsg = msgForInvalidPrimNum(updatedState.flow);
        } else {
            updatedState.notificationMsg = { message: result };
        }
        return updatedState;
    }


    resendOTPDirectCustomers = (resendOTPCustomersData) => {
        const updatedState = this.state;
        const { errorCode = '', result } = resendOTPCustomersData;
        if (errorCode === 0) {
            updatedState.notificationMsg = { message: data.sentOtp };
        } else if (errorCode === 2007 ) {
            updatedState.notificationMsg = {message:data.memberNotVerified};
        } else if (errorCode === 2006 ) {
            updatedState.notificationMsg = {message:data.unableToProcess};
        } else if(errorCode === 2005){
            updatedState.notificationMsg = {message:data.after24Hour};
        } else if(errorCode === 2004){
            updatedState.notificationMsg ={ message: data.afterAnHour};
        } else {
            updatedState.notificationMsg = { message: result };
        }
        return updatedState;
    }


    callDirectVerifyPhoneOtp = (otpDetails, data) => {
        if (otpDetails.functionalityFlag === data.memberSignUpFlow) {
            this.props.directVerifyPhoneOTP('');
        }
    }


    redirectToNextLink = (otpDetails, showDeviceReg, stateData, uuid, showStepper) => {

        const {history} = this.props 
        if(otpDetails.functionalityFlag === 'recoverCustPwd' || (!showDeviceReg && otpDetails.functionalityFlag === 'signIn')) {
            history.push({
                pathname: stateData.verifyNextLink,
                confirmedOTP: true,
                UUID:uuid,
                showStepper,
                otpVerifyCompleted: true
            });
        } else if(otpDetails.flow === 'Recover User Id') {
            history.push({
                pathname: stateData.verifyNextLink,
                confirmedOTP: true,
                //onlineId,
                showStepper,
                otpVerifyCompleted: true
            });
        }    
    }

    handleLogoutAndUpdateState = (updatedStateParams, otpDetails, stateData, result) => {

        const { getLimitedUserProfile } = this.props;

        let updatedState = JSON.parse(JSON.stringify(updatedStateParams));
        
        if(otpDetails.functionalityFlag === 'signIn') {
            // updatedState.notificationMsg = {...stateData.notificationMsg, message:result, info:''};
            getLimitedUserProfile('');
            updatedState.redirect = true;
            updatedState.logOutRoute = {
                 ...stateData.logOutRoute,
                 pathname: routeConstants.signIn,
                 message: {message: result},
                 failure: true
             };
            this.handleLogOut();
        } else {
            getLimitedUserProfile('');
            updatedState.redirect = true;
            updatedState.logOutRoute = {
                ...stateData.logOutRoute,
                pathname: routeConstants.signIn,
                message: {message: userConstants.blockedMessage},
                failure: true
            }
            this.handleLogOut();
        }

        return updatedState
    }

    showDeviceRegStatus = (userPref, customerProfile) => {
        let showDeviceReg = true
        const { c_ , d_ } = customerProfile
        if (userPref === 'DONOT_ASK_ME_AGAIN' || userPref === 'REMIND_ME_LATER' || d_ === 1 || (c_ === 0 || c_ === 1)) {

            showDeviceReg = false;
        }


        return showDeviceReg
    }

    assignTempData = (verifyOTPData, otpDetails, directVerifyOTPData) => {
        let tempData = verifyOTPData;
        if (otpDetails.functionalityFlag === data.memberSignUpFlow) {
            tempData = directVerifyOTPData;
        }

        
        return tempData
    }
  
    isFailedAndMaxAttemptsError=(errorCode,message)=>{

        return(errorCode === 2023 && message === data.Failure || errorCode === 2005 && message === data.Failure)
    }

    isFailedAndOtpExpiredError = (errorCode, message, otpDetails) => {
        return(errorCode === 2027 && message === data.Failure && otpDetails.functionalityFlag === data.memberSignUpFlow)
    }
    isFailedAndWrongOtpError = (errorCode, message, otpDetails) => {
        return(errorCode === 2028 && message === data.Failure && otpDetails.functionalityFlag === data.memberSignUpFlow)
    }
    
    isSuccessAndVerified=(errorCode,message,result)=>{

        return( errorCode === 0 && message === data.success && result.verified === data.true)
    }

    isFailedAndTryAfterAnHourError=(errorCode,message)=>{
       return(errorCode === 2004 && message === data.Failure)
    }

    shouldRedirectToAssensus = (showDeviceReg, otpDetails, Nav) => {
        return (!showDeviceReg && otpDetails.functionalityFlag === 'signIn' && Nav === 'PRE')
    }
    verifyOTPDataHelper = () => {
        const {
            verifyOtp,
            history,
            location,
            verifyOTPData,
            otpDetails,
            recoverOnlineID,
            directVerifyPhoneOTP,
            directVerifyOTPData,
            getLimitedUserProfile,
            encryptionKeyData,
            localStateData,
            customerProfile,
            userid
        } = this.props;
        const { routeData } = localStateData
        const { search } = routeData || {}
        const { Nav = '' } = queryString.parse(search);
        const { c_ , d_ } = customerProfile
        const { showStepper=false, uuid } = location;
        const stateData = this.state;
        let updatedState = { ...stateData };
        const { onlineId } = this.state;
        let tempData = this.assignTempData(verifyOTPData, otpDetails, directVerifyOTPData);
        const val = `${userid}_userPref`;

        const userPref = getDeviceRegUserPref(val);
        let showDeviceReg = this.showDeviceRegStatus(userPref, customerProfile);
        
       
       
        const { errorCode, message, result } = tempData;

        if (this.isSuccessAndVerified=(errorCode,message,result)) {
            verifyOtp('');
            if (otpDetails.functionalityFlag === data.memberSignUpFlow) {
                updatedState.logOutRoute = {
                    pathname: routeConstants.registerOnlineId,
                    confirmedOTP: true,
                    onlineId,
                };
                updatedState.redirect= true;
                return updatedState;
            }
            if (otpDetails.flow === data.RecoverUserId) {
                localStorage.setItem(data.otpVerify, true);
                recoverOnlineID({ uuid: onlineId, c_: otpDetails.role });
            } else {
                localStorage.setItem(data.otpVerify, true);
                if(otpDetails.functionalityFlag === 'signIn' && showDeviceReg) {
                    updatedState.showDeviceRegModal = true;
                }
                if(this.shouldRedirectToAssensus(showDeviceReg, otpDetails, Nav)) {
                    const { result } = encryptionKeyData || {};
                    const {     
                            encodedAesSessionKey = '',
                            cipherText = '',
                            signedToken = '',
                            token = ''
                          } = result || {};
                          window.open(`${envConfig.ASCENSUS_REDIRECTION_URL}?Nav=${Nav}&encPayload=${cipherText}&signedToken=${signedToken}&token=${token}&aesEncKey=${encodedAesSessionKey}`,"_self")
                    return null;
                }

               this.redirectToNextLink(otpDetails, showDeviceReg, stateData, uuid, showStepper)
            }
        } else if (errorCode === 2008 && message === data.Failure) {
            localStorage.setItem(data.otpVerify, false);
            updatedState.notificationMsg = {...stateData.notificationMsg, message:data.otpExpiry, info:''};
            verifyOtp('');
            this.callDirectVerifyPhoneOtp(otpDetails, data)
        } else if (errorCode === 2009 && message === data.Failure) {
            localStorage.setItem(data.otpVerify, false);
            updatedState.notificationMsg = {...stateData.notificationMsg, message:data.wrongOtp, info:''};
            verifyOtp('');
            this.callDirectVerifyPhoneOtp(otpDetails, data)
        } else if(this.isFailedAndMaxAttemptsError(errorCode,message)){
            if (otpDetails.functionalityFlag === data.memberSignUpFlow) {
                localStorage.setItem(data.otpVerify, false);
                updatedState.notificationMsg = {...stateData.notificationMsg, message:data.after1hr, info:''};
                directVerifyPhoneOTP('');
            } else {
                localStorage.setItem(data.otpVerify, false);
                verifyOtp('');
                updatedState =this.handleLogoutAndUpdateState(updatedState, otpDetails, stateData, result)
            }
        } else if(this.isFailedAndTryAfterAnHourError(errorCode,message)){
            localStorage.setItem(data.otpVerify, false);
            verifyOtp('');
            updatedState.notificationMsg ={ message: data.afterAnHour}
        } else if(this.isFailedAndOtpExpiredError(errorCode, message, otpDetails)){
            updatedState.notificationMsg = {...stateData.notificationMsg, message:data.otpExpiry, info:''};
            localStorage.setItem(data.otpVerify, false);
            directVerifyPhoneOTP('');
        } else if(errorCode === 2027 && message === data.Failure){
            updatedState.notificationMsg = {...stateData.notificationMsg, message:data.otpExpiry, info:''};
            localStorage.setItem(data.otpVerify, false);
            verifyOtp('');
        } else if(this.isFailedAndWrongOtpError(errorCode, message, otpDetails)){
            updatedState.notificationMsg = {...stateData.notificationMsg, message:data.wrongOtp, info:''};
            localStorage.setItem(data.otpVerify, false);
            directVerifyPhoneOTP('');
        } else {
            localStorage.setItem(data.otpVerify, false);
            updatedState.notificationMsg = {...stateData.notificationMsg, message: data.invalidOtp, info:''};
            verifyOtp('');
        }
        return updatedState;
    };

    resendHandler = () => {
        const { location, resendOTPCustomers, otpDetails, directReSendPhoneOTP } = this.props;
        const { multiFactorAuthentication, uuid } = location;
        const { onlineId, functionalityFlag, primaryNumber, primaryNumberCountryCode } = otpDetails;

        if (onlineId && multiFactorAuthentication) {
            const params = {
                onlineId: onlineId || '',
            };
            resendOTPCustomers(params);
        } else if (onlineId) {
            if (functionalityFlag === data.memberSignUpFlow) {
                const payload = {
                    requestType: data.OTP,
                    phoneNumber: `${primaryNumberCountryCode}${primaryNumber}`
                }
                directReSendPhoneOTP(payload);
            } else {
                // const params = {
                //     functionalityFlag,
                //     requestType: data.OTP,
                //     onlineId: onlineId || '',
                // };
                let params = '';
                if(functionalityFlag === 'recoverCustPwd' || functionalityFlag === 'signIn') {
                    params = {
                        functionalityFlag,
                        requestType: data.OTP,
                        onlineId: uuid
                    };
                } else {
                    params = {
                            functionalityFlag,
                            requestType: data.OTP,
                            onlineId: onlineId || '',
                        };
                }
                resendOTPCustomers(params);
            }
        }

        this.setState(state => ({
            ...state,
            value: '',
            currentRef: this.resendRef,
        }));
    };

    onClose = () => {
        const { currentRef } = this.state;
        if (currentRef.current) {
            currentRef.current.focus();
        }
        this.setState(state => ({ ...state, notificationMsg: null }));
    };

    recoverOnlineIDHelper = recoverOnlineIdData => {
        const { recoverOnlineID, history } = this.props;
        const { onlineId, verifyNextLink } = this.state;
        const { errorCode, result } = recoverOnlineIdData;
        const updatedState = this.state;
        if (errorCode === 0) {
            recoverOnlineID('');
            history.push({
                pathname: verifyNextLink,
                confirmedOTP: true,
                onlineId,
            });
        } else {
            updatedState.notificationMsg = { message: result.message || '' };
            recoverOnlineID('');
        }
        return updatedState;
    };

    handleLogOut() {
        const token = localStorage.getItem('token');
        if (token) {
            Auth.signOut()
                .then(() => {
                    localStorage.clear();
                    this.setState({
                        redirect: true,
                        logOutRoute: {
                            pathname: routeConstants.signIn,
                        },
                    });
                })
                .catch(() => {
                    
                });
        } else {
            this.setState({
                redirect: true,
                pathname: routeConstants.home,
            });
        }
    }

    renderWStepper = () => {
        const { pageData } = this.props;
        const { stepperPage, isMemberSignUp, isProspectSignUp} = pageData;
        let returnElm = '';
        if(isProspectSignUp){
            returnElm = <div className="memberStepper" ><UserMngmtStepper currentPage={3} pages={stepperPage} /></div>;
        }
        if(isMemberSignUp){
            returnElm = <div className="memberStepper" ><UserMngmtStepper currentPage={4} pages={stepperPage} /></div>;
        }
        return returnElm;     
      }


    render() {
        const { location, isLoading, manageLocalState, pageData, otpDetails } = this.props;
        const { jov = null, message = {}, showStepper } = location;
        const { cancelled, notificationMsg, valid, touched, backPath, redirect, logOutRoute } = this.state;
        const { isMemberSignUp, isProspectSignUp} = pageData;
        const { functionalityFlag } = otpDetails;
        const sideHeadertext = assignObj([message.message, data.infoP2]);
        const analyticId = `UMS_${functionalityFlag}_EnterOtp`;
        let redirectionResult = '';

        if (redirect) {
            manageLocalState({});
            redirectionResult = <Redirect to={logOutRoute} />;
        }

        return (
            <div>
                <WSpinner loading={isLoading} />
                {redirectionResult}
                {notificationMsg ? <WMessageBar text={notificationMsg} onClose={this.onClose} /> : null}
                {(isMemberSignUp || isProspectSignUp) && this.renderWStepper() }
                <div className="container">
                    {jov ? <WStepper className="userWizard" currentPage={3} pages={pages} /> : null}
                    {showStepper ? (
                        <WStepper
                            className="userWizard"
                            currentPage={1}
                            pages={data.pages}
                            stepperColStyle={stepperColStyle}
                        />
                    ) : (
                        ''
                    )}
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader title={data.title} info={sideHeadertext} />
                        </Col>
                        <Col md lg xl>
                            <span style={assignObj({ ...styles.optionalMessage })}>{data.optionalMessage}</span>
                            <Col md={6} lg={6} xl={6}>
                                <OtpForm
                                    id="otpForm"
                                    inputRef={this.inputRef}
                                    resendRef={this.resendRef}
                                    otpFormState={this.state}
                                    changed={this.onChange}
                                    blurred={this.onBlur}
                                    clicked={this.analyticsLastTouch}
                                    resendOtpHandler={this.resendHandler}
                                />
                            </Col>
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.onBack}
                    nextClick={this.onNext}
                    disabled={!(valid && touched)}
                    blockstyle={styles.commonButtons}
                    arialabelback={jov ? data.toMobileNumber : data.toOneTimeVerify}
                    dataAnalyticId={analyticId}
                />
                {cancelled ? <Redirect to={backPath} /> : null}
            </div>
        );
    }
}

EnterOtp.propTypes = {
    verifyOTPData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    verifyOtp: PropTypes.func,
    isLoading: PropTypes.bool,
    location: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object),
    otp: PropTypes.number,
    otpDetails: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    resendOTPCustomers: PropTypes.func,
    resendOTPCustomersData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    directReSendPhoneOTPData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    recoverOnlineID: PropTypes.func,
    recoverOnlineIdData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    directVerifyOTPData:  PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    // directSendPhoneOTP: PropTypes.func,
    directReSendPhoneOTP: PropTypes.func,
    directVerifyPhoneOTP: PropTypes.func,
    pageData: {
        isMemberSignUp: PropTypes.bool,
        isProspectSignUp: PropTypes.bool,
        stepperPage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
      },
};

EnterOtp.defaultProps = {
    verifyOTPData: null,
    verifyOtp: () => {},
    isLoading: false,
    location: {},
    history: {},
    otp: 1234,
    otpDetails: {},
    manageLocalState: () => {},
    resendOTPCustomers: () => {},
    resendOTPCustomersData: null,
    directReSendPhoneOTPData: null,
    recoverOnlineID: () => {},
    recoverOnlineIdData: {},
    directVerifyOTPData: {},
    directReSendPhoneOTP: {},
    // directSendPhoneOTP: () => {},
    directVerifyPhoneOTP: () => {},
    pageData:{
        isMemberSignUp: false,
        isProspectSignUp: false,
        stepperPage: [],
      },
};

export default EnterOtp;