import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
        "transactions": [
            { symbol: "USAUX", fundName: "USAA Agressive Growth Fund", transactionDesc: "Purchase", tradeDate: "01/06/2020", quantity: "60.00", price: "$41.00", value: "$1,052.00", cumulativeShares: "2500.00", transactionStatus: "Pending" },
            { symbol: "USAUX", fundName: "USAA Agressive Growth Fund", transactionDesc: "Liquidation", tradeDate: "01/12/2010", quantity: "60.00", price: "$98.00", value: "$3,458.00", cumulativeShares: "2500.00", transactionStatus: "Confirmed" },
            { symbol: "USAUX", fundName: "USAA Agressive Growth Fund", transactionDesc: "Purchase", tradeDate: "22/02/2020", quantity: "60.00", price: "$40.00", value: "$6,897.00", cumulativeShares: "2500.00", transactionStatus: "Pending" },
            { symbol: "USAUX", fundName: "USAA Agressive Growth Fund", transactionDesc: "Liquidation", tradeDate: "21/04/2002", quantity: "60.00", price: "$56.00", value: "$2,254.00", cumulativeShares: "2500.00", transactionStatus: "Pending" },
            { symbol: "USAUX", fundName: "USAA Agressive Growth Fund", transactionDesc: "Exchange", tradeDate: "14/11/2020", quantity: "60.00", price: "$100.00", value: "$4,356.00", cumulativeShares: "2500.00", transactionStatus: "Confirmed" },
        ],
    isLoading: false,
    isError: false,
    isSuccess: false,
};

function dashboardTransViewReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SAVE_LIQUIDATION_DATA: {
            return {
                ...state,
                ...action
            };
        }
        default:
            return state;
    }
}



export default dashboardTransViewReducer;

