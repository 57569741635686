import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import routeConstants from 'routeConstants';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import checkFormat from 'modules/UserManagementAndSecurityModule/checkFormat';
import Guidelines from 'modules/UserManagementAndSecurityModule/Guidelines';
import { OnlineId } from 'modules/UserManagementAndSecurityModule/Input/OnlineId';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper, WStepper as UserMngmtStepper, WSpinner } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles';
import signUpPages from '../SignUpConstants';
import data from './Constants';
import userConstants from  '../../userConstants';

const {optionalMessage} = userConstants;

const assignObj = (obj) => { return obj; };

class RegisterOnlineId extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cancelled: false,
      backLink: null,
      form: {
        onlineId: {
          value: '',
          errMsg: null,
          context: 'registeronlineid',
          valid: false,
          label: data.id,
          maxLength: 20,
          rules: {
            minLength: 5,
            maxLength: 20,
            pattern: new RegExp('^[a-zA-Z0-9]*$'),
            patternMessage: data.patternMessage,
            required: true
          }
        }
      },
      loading: false,
      formIsValid: false,
      upperLower: false, min: false, symbol: false,
      ...props.registerOnlineIdState,
    };
      this.onBack = this.onBack.bind(this);

      this.onNext = this.onNext.bind(this);
    
      this.onChange = this.onChange.bind(this);
    
      this.onBlur = this.onBlur.bind(this);

  }

  static getDerivedStateFromProps(nextProps, state) {
    const { loginState, history, manageLocalState, signUpVerifyOnlineID } = nextProps;
    const localData = { registerOnlineIdState : {...state} };
    let updatedState = null
    if (loginState && loginState.signUpVerifyOnlineID ) {
      if(loginState.signUpVerifyOnlineID.errorCode === 0){
        manageLocalState(localData);
        history.push(routeConstants.registerPassword);
      }else if(loginState.signUpVerifyOnlineID.errorCode === 403){
        updatedState = {...state, form:{...state.form, onlineId:{...state.form.onlineId, errMsg: 'User ID already exists'}}};
      }else {
        updatedState = {...state, error: loginState.signUpVerifyOnlineID.result};
      }
      signUpVerifyOnlineID('');
    }
    return updatedState;
  }
  
  componentDidMount() {
    const { personalDetailsState , history } = this.props;
    if(!personalDetailsState || !personalDetailsState.uuid) {
        history.push(routeConstants.home);
    }
  }

  componentWillUnmount() {
    const { analyzeFormAdandonment } = this.props;
    const digitalData = getDigitalDataFormAband();
    analyzeFormAdandonment(digitalData);
  }

  onChange(event) {
    checkFormat(event, data.onlineId, this);
    inputChangedHandler(event,data.onlineId, this);
  }

  onBlur() { inputValidityHandler(data.onlineId, this); }

  onBack() {
    const {otpDetails, pageData} = this.props;
    const { isMemberSignUp, isProspectSignUp} = pageData;
    let backLink = null;
    if (isProspectSignUp || isMemberSignUp) {
      if(otpDetails.otpIvrSelect === 'ivr') { backLink = routeConstants.otpIvrSelect; } else { backLink = routeConstants.verifyOtp;}
    }
    
    this.setState((state) => ({ ...state, cancelled: true, backLink }));
    
  }

  onNext() {
    const { form } = this.state;
    const { signUpVerifyOnlineID, pageData } = this.props;
    const { isMemberSignUp, isProspectSignUp} = pageData;
    const {onlineId} = form;
    const {value} = onlineId;
    if (isProspectSignUp || isMemberSignUp) {
      signUpVerifyOnlineID({ "onlineId": value.toLowerCase(), "flow": "Member" });
    } else {
      signUpVerifyOnlineID({ "onlineId": value.toLowerCase() });
    }

  }

  renderWStepper = () => {
    const { pageData } = this.props;
    const { stepperPage, isMemberSignUp, isProspectSignUp} = pageData;
      if(isProspectSignUp){
        return <div className="memberStepper" ><UserMngmtStepper currentPage={4} pages={stepperPage} /></div>;
      }
      if(isMemberSignUp){
        return <div className="memberStepper" ><UserMngmtStepper currentPage={5} pages={stepperPage} /></div>;
      }
    }

  render() {
    const { loginState, pageData } = this.props;
    const {isLoading} = loginState;
    const { isMemberSignUp, isProspectSignUp } = pageData;
    const { cancelled, formIsValid, form, backLink } = this.state;
    let analyticId = 'GuestSignup';
    if(isProspectSignUp){
      analyticId = 'ProspectSignup';
    }
    if(isMemberSignUp){
      analyticId = 'MemberSignup';
    }
    return (
      <div>
        <WSpinner loading={isLoading} />
          { (isMemberSignUp || isProspectSignUp) && this.renderWStepper()}
        <div className="container">
          {/* {jov ? <StatusBar current='4' length='4' /> : <StatusBar current='3' length='3' />} */}
        {!(isMemberSignUp || isProspectSignUp) && <WStepper className="userWizard" currentPage={2} pages={signUpPages} /> }
          
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                headStyle={data.headStyle}
                title={data.title} />
                <Guidelines state={this.state} type={data.onlineId} />
            </Col>
            <Col md lg xl>
              <span style={assignObj({...styles.optionalMessage, marginBottom: 10})}>{optionalMessage}</span>
              <OnlineId
                onlineIdState={form.onlineId}
                changed={this.onChange}
                blurred={this.onBlur}
                preferred />
            </Col>
          </Row>
        </div>
        <CommonButtons backClick={this.onBack} nextClick={this.onNext} disabled={!formIsValid} blockstyle={styles.commonButtonsHalf} arialabelback="Back to terms and conditions" dataAnalyticId={analyticId}/>
        {cancelled && ((isMemberSignUp || isProspectSignUp)? <Redirect to={backLink} /> : <Redirect to={routeConstants.termsAndConditions} />)}
      </div>
    );
  }
}

RegisterOnlineId.propTypes = {
  signUpVerifyOnlineID: PropTypes.func,
  manageLocalState: PropTypes.instanceOf(Object),
  registerOnlineIdState: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
  pageData: PropTypes.shape({
    isMemberSignUp: PropTypes.bool,
    isProspectSignUp: PropTypes.bool,
    stepperPage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
  }),
  otpDetails: PropTypes.shape({
    otpIvrSelect: PropTypes.string,
  }),
  personalDetailsState:  PropTypes.instanceOf(Object),
}

RegisterOnlineId.defaultProps = {
  signUpVerifyOnlineID: () => {},
  manageLocalState: {},
  registerOnlineIdState: {},
  loginState: {},
  history: [],
  pageData:{
    isMemberSignUp: false,
    isProspectSignUp: false,
    stepperPage: [],
  },
  otpDetails: {
    otpIvrSelect: 'otp',
  },
  personalDetailsState:{}
}

export default RegisterOnlineId;