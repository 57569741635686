import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import Styles from './Styles';
import {DownloadFile } from '../../../common';
import ACCESS_FORMS_CONSTANTS from './Constants';
import pdfIcon from '../../../assets/icon_pdf.png';

const FormList = props => {
    const {pdfUrlData,apiresponseStatus} = props;
    const inlineStyle = {
        rating: {
            width: 14,
            height: 14,
        },
        ratingCol: { span: 1, offset: 1 },
        downloadFile: {
            fontWeight: 400,
            fontFamily: 'benton-sans',
            fontSize: 16,
            textAlign: 'left',
            marginTop: 0,
        },
    };

    const getKey = (item, index) => {
        return `${item}-${index}`;
    };

    return (
        <Styles.Forms data-testid="form-list">
            
            <>
            {(pdfUrlData.length > 0 ) &&  
                pdfUrlData.map((item, index) => (
                    <Styles.FormItem key={getKey('form-key', index)} className="form-row">
                        <Col sm={2}>{item.formID}</Col>
                        <Col sm={8}>
                            <Styles.SubTitle>{item.pdfTitle}</Styles.SubTitle>
                            <p>{item.pdfDescription}</p>
                        </Col>
                        <Col sm={2}>
                            <Styles.LinkIconDiv>
                                <DownloadFile
                                    saveAs={item.pdfPath}
                                    linkStyle={inlineStyle.downloadFile}
                                    text={'Download'}
                                    iconSrc={pdfIcon}
                                    downloadUrl={item.pdfPath}
                                />
                            </Styles.LinkIconDiv>
                        </Col>
                    </Styles.FormItem>
                )
                )}
            </>

            <>
                  {(apiresponseStatus == true && pdfUrlData.length == 0 )  &&
                        <Styles.NoRecords>{ACCESS_FORMS_CONSTANTS.NO_RECORDS_FOUND}</Styles.NoRecords>
                  }
            </>
        </Styles.Forms>
    );
};

FormList.propTypes = {
    pdfUrlData: PropTypes.arrayOf(PropTypes.object),
};

FormList.defaultProps = {
    pdfUrlData: [],
};

export default FormList;
