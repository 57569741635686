import React, { useEffect } from "react";
import styled from 'styled-components';
import { Col, Container } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import {
    formatPhoneNumber,
    formatPhoneNumberWithoutCountryCode
} from "utils";
import routeConstants from 'routeConstants';
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import { updateProfileRelationship, setPhoneEditDetails, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { BottomNavBar, PageTitle, CardWithMenu, AddCard, CardDeck } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';

const StyledLine1 = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const BREADCRUM_ITEMS = [
    {
        url: '/Profile', 
        name:'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationshipphoneinfo_breadcrumb_profile'
    }, 
    {
        url: '/basicinformation', 
        name: 'Basic Information',
        dataAnalyticId:'cms_basicinformation_managerelationshipphoneinfo_breadcrumb_basicinfo'
    },
    {
        url: '/ManageRelations', 
        name: 'Manage Relationship',
        dataAnalyticId:'cms_basicinformation_managerelationshipphoneinfo_breadcrumb_managerelationship'
    }
];
const PAGE_NAME = 'Manage Phone Information';
const PRIMARY_PHONE_NUMBER = 'Primary Phone Number';
const SECONDARY_PHONE_NUMBER = 'Secondary Phone Number';

const PHONE_INFO_SUCCESS = "Successfully saved Phone Information";
const PHONE_INFO_DELETE_SUCCESS = "Successfully removed Phone Information";

const menuOptions = [{name:"Edit", icon:editIcon},{name:"Delete", icon:deleteIcon}];

const ADD_PRIMARY_PHONE_TEXT = "Add Primary Phone";

const ADD_SECONDARY_PHONE_TEXT = "Add Secondary Phone";

const editPhone = (dispatch, history, phoneObj={}) => (e) => {
    e.stopPropagation();
    const { phoneNumber='', phoneCountryCode='', relationshipId='', isMobile=false, isPrimary=false } = phoneObj;
    const newObj = {
        phoneNumber: formatPhoneNumberWithoutCountryCode(phoneNumber),
        phoneCountryCode,
        relationshipId,
        isMobile,
        isPrimary
    }
    dispatch(setPhoneEditDetails(newObj));
    history.push(routeConstants.relationPhoneEdit, { newObj });
};

const deletePhone = (dispatch, phoneObj) => (e) => {
    e.stopPropagation();
    const { relationshipId, isPrimary } = phoneObj;
    const payLoad = {
            role : "Family",
            relationshipId,
            phoneUpdate: [{
                phoneNumber: "",
                phoneCountryCode: "",
                phoneType: "",
                isPrimary,
            }],
        }
    dispatch(updateProfileRelationship({...payLoad, isDeleteAPICalled: true}));
};

export const onMenuOptionClick = (history, dispatch, phoneObj) => (menuOption) => (e) => {
    if(menuOption === 'Edit'){
        dispatch(analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipphoneinfo_edit${phoneObj.isPrimary?'primary':'secondary'}phonenumber`
        }));
        editPhone(dispatch, history, phoneObj)(e);
    }else if(menuOption === 'Delete') {
        dispatch(analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipphoneinfo_delete${phoneObj.isPrimary?'primary':'secondary'}phonenumber`
        }));
        deletePhone(dispatch, phoneObj)(e);
    }
}

export const onBackClick = (dispatch, history) => () => {
    dispatch(analyzeClickEvent({
        link: `cms_basicinformation_managerelationshipphonenumber_back`
    }))
    history.push(routeConstants.manageRelations);
}

export const getPhoneDetails = (phoneInfo) => {
    let primaryPhone;
    let secondaryPhone;
    (phoneInfo || []).forEach(item => {
        if(item.isPrimary) {
            primaryPhone = { ...item };
        }else {
            secondaryPhone = { ...item };
        }
    });
    return { primaryPhone, secondaryPhone }
}

export const onAddCardClick = (history, dispatch, phoneDetails) => (e) => {
    e.stopPropagation();
    dispatch(analyzeClickEvent({
        link: `cms_basicinformation_managerelationshipphonenumber_add${phoneDetails.isPrimary?'primary':'secondary'}phonenumber`
    }));
    dispatch(setPhoneEditDetails(phoneDetails));
    history.push(routeConstants.relationPhoneAdd);
}

const getPhoneType = (PhoneObj) => {
    let PhoneType = '';
    if(PhoneObj){
        const { isMobile=false } = PhoneObj;
        PhoneType = isMobile? CONST.MOBILE : CONST.NON_MOBILE;
    }
    return PhoneType;
}

const backBtnProps = {};

const PhoneInfoComponent = () => {
    
	const history = useHistory();
	const dispatch = useDispatch();
	
	const familyMemberIndex = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyMemberIndex: familyMemberIndexVal } = profileRelationshipData;
        return familyMemberIndexVal;
    });
    
    const familyRelationShipInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyRelationShipInfo: familyRelationShipInfoVal } = profileRelationshipData;
        return familyRelationShipInfoVal;
    });
    
    useEffect(()=>{
        if(familyMemberIndex === -1 ){
            history.push(routeConstants.basicinformation);
        }
    },[familyMemberIndex, history])
    
    const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
    
    const { phoneInformation=[], relationshipId='' } = familyMemberInfo;
    
    const { primaryPhone, secondaryPhone} = getPhoneDetails(phoneInformation);
    const primaryPhoneType = getPhoneType(primaryPhone);
    const secondaryPhoneType = getPhoneType(secondaryPhone);
    
    const isError = useSelector(({profileRelationshipData: {isError: apiError}}) => apiError);
    const isSuccess = useSelector(({profileRelationshipData: {isSuccess: apiSuccess}}) => apiSuccess);
    const isLoading = useSelector(({profileRelationshipData: {isLoading: apiLoading}}) => apiLoading);

    const isDeleteAPICalled = useSelector(({profileRelationshipData: {isDeleteAPICalled: deleteAPICalled}}) => deleteAPICalled);

    const notify = isError || isSuccess;
    let message = null;
    if (isSuccess) {
        message = isDeleteAPICalled ? PHONE_INFO_DELETE_SUCCESS : PHONE_INFO_SUCCESS;
    }

    const statusNotification = withNotification()({
        notify,
        message: {
            message,
            info: null,
        },
    });
    
    useEffect(() => {
        if (isError || isSuccess) {
            dispatch(clearRelationActions())
        }
    }, [dispatch, isError, isSuccess]);

    backBtnProps.onClick = onBackClick(dispatch, history);
    backBtnProps['aria-label'] = "Back to Manage relation information";
    
    return (
        <div>
            {isLoading && (
                <WSpinner loading={isLoading} />
            )}
            {statusNotification}
            <Container>
                <WBreadCrumb
                    breadCrumbItems={BREADCRUM_ITEMS}
                    activeCrumb={PAGE_NAME}
                />
                <PageTitle
                    title={PAGE_NAME} 
                />
                <CardDeck data-mt={2.5} data-mb={7} >
                    <Col md={6} lg={4}>
                    {!primaryPhone ? (
                        <AddCard
                            minHeight={11.25}
                            addTxt={ADD_PRIMARY_PHONE_TEXT}  
                            onAddCardClick = {onAddCardClick(history, dispatch, { isPrimary: true })} 
                        />
                    ):(
                        <CardWithMenu 
                            cardTitle = { PRIMARY_PHONE_NUMBER}
                            Line1 ={formatPhoneNumber(primaryPhone.phoneNumber, true, primaryPhone.phoneCountryCode)}
                            extradetail= {<StyledLine1>{primaryPhoneType}</StyledLine1>}
                            // menu
                            menuOptions={menuOptions}
                            onMenuOptionClick={onMenuOptionClick(history, dispatch, { ...primaryPhone, relationshipId})}
                        />
                        )}
                    </Col>
                    <Col md={6} lg={4}>
                    {!secondaryPhone ? (
                        <AddCard
                            minHeight={11.25} 
                            addTxt={ADD_SECONDARY_PHONE_TEXT}  
                            onAddCardClick = {onAddCardClick(history, dispatch, { isPrimary: false })} 
                        />
                    ):(
                        <CardWithMenu 
                            cardTitle = {SECONDARY_PHONE_NUMBER}
                            Line1 ={formatPhoneNumber(secondaryPhone.phoneNumber, true, secondaryPhone.phoneCountryCode)}
                            extradetail= {<StyledLine1>{secondaryPhoneType}</StyledLine1>}
                            // menu
                            menuOptions={menuOptions}
                            onMenuOptionClick={onMenuOptionClick(history, dispatch, { ...secondaryPhone, relationshipId})}
                        />
                        )}
                    </Col>
                </CardDeck>
            </Container>
            <BottomNavBar
                hidePrimaryBtn
                backBtnProps={backBtnProps}
            />
        </div>
    );
}

export default PhoneInfoComponent;
