import { analyticsBreadcrumb } from './AdobeAnalytics/Constants';
import routesBreadCrumb from './Constants/routesBreadcrumb';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { getSessionValues } from 'shared/Helpers/Utils';
import { LocalStorageHelper } from './sessionHelper';
import { removeComma } from 'common/Formatter/CurrencyFormatter';
import currencyFormatter from 'currency-formatter';
import moment from 'moment-timezone';
// supported formats - YYYY-MM-DD, MM-DD-YYYY, YYYY/MM/DD and MM/DD/YYYY
const formatDate = (param, format = 'YYYY-MM-DD') => {
    let date = param;
    if (!(date instanceof Date)) {
        date = new Date(param);
    }
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    const year = date.getFullYear();
    return format
        .replace('MM', month)
        .replace('DD', day)
        .replace('YYYY', year);
};

const formatSSNValue = (val) => {
    if(val){
        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    }else{
        val = ''
    }
 
    return val;
}

const isValidDate = param => {
    if (!param) return false;
    const dateValue = new Date(param);
    return dateValue.getDate().toString() !== 'NaN';
};

const assignObj = obj => obj;

const inputCheck = value => {
    const decimalIndex = value.indexOf('.');
    const valueLength = value.length;
    const isDecimal = decimalIndex !== -1;
    const isDecimalAtLast = decimalIndex === valueLength - 1 && valueLength > 1;
    const numberValue = +value;
    let returnValue = numberValue;
    const isValid = Number.isNaN(numberValue) ? isDecimalAtLast : true;
    if (!isValid || numberValue < -1 || numberValue > 99999999999.99 || (isDecimal && valueLength - decimalIndex > 3))
        return null;
    if (isDecimal || value === '0' || value === '') {
        returnValue = value;
    }
    return returnValue;
};

const formatDateToTwoDigit = date => {
    return date.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
};

const formatMessageCenterDate = inputDate => {
    let date = 0;
    if (inputDate) {
        date = +inputDate;
    }
    if (inputDate && inputDate.length === 10) {
        date *= 1000; // Converting to milliseconds
    }

    let displayDate;
    const messageDate = new Date(date);

    // Get current date and time
    const dateNow = new Date(Date.now());

    const hoursNow = dateNow.getHours();
    const messageHours = messageDate.getHours();

    if (
        dateNow.getDate() === messageDate.getDate() &&
        dateNow.getMonth() === messageDate.getMonth() &&
        dateNow.getYear() === messageDate.getYear()
    ) {
        const hoursDiff = hoursNow - messageHours;
        displayDate = hoursDiff > 0 ? `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago` : 'Just now';
    } else {
        displayDate = messageDate
            .toLocaleDateString()
            .split('/')
            .map(va => va.padStart(2, '0'))
            .join('/');
    }
    return displayDate;
};

const formatTimeAMPM = dateVal => {
    let date = 0;
    if (dateVal) {
        date = +dateVal;
    }
    if (dateVal && dateVal.length === 10) {
        date *= 1000; // Converting to milliseconds
    }
    const inputDate = new Date(date);
    let hours = inputDate.getHours();
    let minutes = inputDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = `0${minutes}`.slice(-2);
    return `${hours}:${minutes} ${ampm}`;
};

const formatPhoneNumber = (phoneNumberString, mask = false, countryCode = '+1') => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const actualPhoneLength = cleaned.length;
    const fL = actualPhoneLength - 6;
    const match = cleaned.match(`^(1|)?(\\d{3})(\\d{3})(\\d{${fL}})$`);
    if (match) {
        const intlCode = match[1] ? '+1 ' : `${countryCode} `;
        const last4Digits = match[4].slice(-4);
        const maskedNumber = last4Digits.padStart(match[4].length, 'X');
        if (mask)
            return [
                intlCode,
                '(',
                match[2].replace(/\d/g, 'X'),
                ') ',
                match[3].replace(/\d/g, 'X'),
                '-',
                maskedNumber,
            ].join('');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

const formatPhoneNumberWithoutCountryCode = phoneNumberString => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const actualPhoneLength = cleaned.length;
    const fL = actualPhoneLength - 6;
    const match = cleaned.match(`^(1|)?(\\d{3})(\\d{3})(\\d{${fL}})$`);
    if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return cleaned;
};

const getRemoveSpecialChars = (value = '', asNumber = false) => {
    const stringValue = `${value}`;
    const cleaned = stringValue.replace(/\D/g, '');
    return asNumber ? parseInt(cleaned, 10) : cleaned;
};

const getPhoneNumberMaxLength = (countryCode = '', withSymbol = false) => {
    let phoneLength = 15;
    const countryCodeString = `${countryCode}`;
    const cleaned = countryCodeString.replace(/\D/g, '');
    const newCountryCode = parseInt(cleaned, 10);
    if (newCountryCode === 1) {
        phoneLength = withSymbol ? 14 : 10;
    }
    return phoneLength;
};
const getToken = async () => {
    try {
        const response = await Auth.currentSession();
        const { idToken } = response;
        return idToken.jwtToken;
    } catch {
        return null;
    }
};
const replaceSpecialChar = value => {
    return value.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '').replace(/\s/g, '');
};

const getFormattedSSN = (ssnString, mask = false) => {
    let val = `${ssnString}`.replace(/\D/g, '');
    let newVal = '';
    newVal += val.substr(0, 3) + '-';
    newVal += val.substr(3, 2) + '-';
    if (mask) newVal = newVal.replace(/\d/g, 'X');
    val = val.substr(5);
    newVal += val;
    return newVal.substring(0, 11);
};

// Getting the formatted date in MM/dd/yyyy format
const getFormattedDate = date => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    return `${month}/${day}/${year}`;
};
const maskEmail = email => {
    return email.replace(/(.{2})(.*)(?=@)/, function(gp1, gp2, gp3) {
        for (let i = 0; i < gp3.length; i++) {
            gp2 += 'x';
        }
        return gp2;
    });
};

// Removes $, from input amount

const formatInputValues = val => {
    return val ? val.replace(/[a-zA-Z,$]/g, '') : 0;
};

const showOnlyLastFourChar = (value = '') => {
    const valToString = (value || '').toString();
    return `XXXX-XXXX-${valToString.substr(valToString.length - 4)}`;
};

const createBreadCrumbItem = (route, analyticsIDButtonPath) => ({
    url: route,
    name: routesBreadCrumb.get(route),
    dataAnalyticId: `${analyticsIDButtonPath}_${analyticsBreadcrumb.get(route)}`,
});

const addCommasToNumberStr = (value = '') => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatCurrency = (value, locale = 'en-US', currency = 'USD') => {
    return Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(parseFloat(value || '0.0'));
};

const getDomainName = () => {
    const names = window.location.hostname.match(/\w*\.\w*$/gi);
    const domainName = names && names.length > 0 ? names[0] : '';
    return domainName;
};

const removeCookies = () => {
    const domainName = getDomainName();
    Cookies.remove('userFirstName', { domain: `.${domainName}` });
    Cookies.remove('unreadMessages', { domain: `.${domainName}` });
    Cookies.remove('isMemberRole', { domain: `.${domainName}` });
    Cookies.remove('isStopCodeShown', { domain: `.${domainName}` });
    Cookies.remove('cookieBanners', { domain: `.${domainName}` });
    if (window.chatOpen) {
        Cookies.remove('chatOpen', { domain: `.${domainName}` });
    }
};

const getFISMemberId = async () => {
    try {
        const response = await Auth.currentSession();
        const { idToken } = response;
        const authToken = idToken.jwtToken;
        const { fisMemberId } = jwt_decode(authToken);
        return fisMemberId;
    } catch {
        return null;
    }
};

const getRole = profileData => {
    const {c_} = profileData || {};
    let role = '';
    if(c_ === 0) {
        role = 'Prospect';
    } else if (c_ === 1 ) {
        role = 'Guest';
    } else if (c_ === 2) {
        role = 'Member';
    } else {
        role = 'unknown';
    }
    return role;
}

//
const validateSSNWithoutNANValidation = (ssnOne,ssnTwo,ssnThree) =>
{
    let valid = false;
    let ssnPartOne = ssnOne.trim();
    let ssnPartTwo = ssnTwo.trim();
    let ssnPartThree = ssnThree.trim();
    let fullSSN =` ${ssnPartOne}-${ssnPartTwo}-${ssnPartThree}`

    if(ssnPartOne == '' && ssnPartTwo == '' && ssnPartThree == '')
    {
        valid = true;
    }
    else if(isValidSSNPartWithoutNANValidation(ssnPartOne,3) && isValidSSNPartWithoutNANValidation(ssnPartTwo,2) && isValidSSNPartWithoutNANValidation(ssnPartThree,4)&& socialSecurityValidation(fullSSN))
    {
        valid = true;
    }

    return valid;
}

function socialSecurityValidation(str) {
    const value = str? str.trim() : ""
    const regexp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
    const sequentialRegex = /123456789|987654321|012345678|876543210|234567890|098765432/;
    const repeatedRegex = /^(.)\1+$/;
    return regexp.test(value) && !sequentialRegex.test(value.replace(/-/g, "")) && !repeatedRegex.test(value.replace(/-/g, ""));
}

const isValidSSNPartWithoutNANValidation = (ssnPart,ssnLength) =>
{
    return (ssnPart !='' && ssnPart.length === ssnLength)
}


const getValue = (key, arr) => {
    const objFound = arr?.find(item => item.key === key)
    if (objFound) {
      return objFound.value
    } else {
      return ''
    }
}

const getRankValueBasedOnKey  = (key,masterLookUpData) =>
{
    let flag =0;
    for(const keys in masterLookUpData)
    {
        if(keys?.startsWith('mil_rank_') == true && flag == 0) 
        {
            const objFound = masterLookUpData[`${keys}`]?.value?.filter(item => item.key === key)
            if (objFound?.length > 0) {
              flag = 1;
              return objFound[0].value
            } 
        }
    }

    if(flag == 0)
    {
        return '';
    }
}

const toLocaleUTCDateString = (dateStr) => {
    const date = new Date(dateStr);
    // Find time zone offset and convert from min => ms.
    const timeDiff = date.getTimezoneOffset() * 60000;
    // Get new date that adds offset (in ms) to date (in ms... relative)
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    // returns a string that shows UTC time.
    return adjustedDate.toLocaleDateString();
}

const isBackOfficeUser = () =>
{
    // const msrInfo = JSON.parse(sessionStorage.getItem('msr_dts'))
    const msrInfo = getSessionValues()
    return (msrInfo?.vcmid == "" && msrInfo?.msrid == "")
}

const isFrontOfficeUser = () =>
{
    // const msrInfo = JSON.parse(sessionStorage.getItem('msr_dts'))
    const msrInfo = getSessionValues();
    return (msrInfo?.vcmid != "" && msrInfo?.msrid != "")
}

const isMiddleOfficeUser = () =>
{
    // const msrInfo = JSON.parse(sessionStorage.getItem('msr_dts'))
    const msrInfo = getSessionValues();
    return (msrInfo?.vcmid != "" && msrInfo?.msrid != "")
}

const splitDateTime = (dateTimeString,splitParameter)=>
{
    const dateTimeValue = dateTimeString?.split(splitParameter);

    if(dateTimeValue?.length > 0)
    {
        return dateTimeValue[0];
    }
    else
    {
        return dateTimeString
    }    
}

const convert_UTC_TO_EST_Timezone = (dateString,)=>
{
    let estTime ="";
    let date = new Date(dateString)

    if(dateString?.length > 0)
    {
        estTime =  date.toLocaleString('en-US', {
            timeZone: 'America/New_York',
          })

        if(estTime.includes(','))
        {
            return  splitDateTime(estTime,',')
        }
        else if(estTime.includes('-'))
        {
            return  splitDateTime(estTime,'-')
        }
        else
        {
            return estTime;
        }
    }
    else
    {
        return ""
    }
}

const maskAccountNumber = (data = '') =>{
    if(data?.length > 4){
        const maskvalue = data.replace(/\d(?=\d{4})/g, 'X');
        return maskvalue
    }
    else{
       return data ? data : '';
    }
}


const validateForUndefinedAndGetValue = (inputValue, defaultValue='') => {
    if(inputValue !== undefined){
        return inputValue
    }else{
        return defaultValue
    }
}

const validateForEmptyAndGetValue = (inputValue, successValue, defaultValue='') => {
    if(inputValue !== ''){
        return successValue
    }else{
        return defaultValue
    }
}

function convertToTitleCase(str) {
    if (!str) {
        return "";
    }

    str = str.toString().toLowerCase();

    return str.replace(/(?:^|\s)\S/g, function (firstLetter) {
        return firstLetter.toUpperCase();
    });
}

const getMemberRoleFlag = () =>
{
    const isMember = (LocalStorageHelper.getItem('role') == 'Member' ) ? true : false;
    return isMember;
}

const validateUndefinedNullAndEmptyValue = (strValue) =>
{
    return (strValue != undefined && strValue!=null && strValue !="") ? true : false;
}

const getDataFromORCondition = (value1,value2)=>
{
    return (value1 || value2);
}


/// 

const calculateTotalAmount = (amount,noOfTimes)=>
{
    const amt= ((parseFloat(removeComma(amount))) * noOfTimes)
    return amt;
}

const isUserGuestOrProspect = (role) => {
    return (role === 'Guest' || role === 'Prospect') 
}

const isRoleGuest = (role) =>
{
    return (role === 'Guest');
}

const isRoleProspect = (role) =>
{
    return (role === 'Prospect');
}

const isRoleMember = (role) =>
{
    return (role === 'Member')
}

const isRoleVerifiedProspect = (role) =>
{
    return (role === 'Verified Prospect');
}


const isRunningLocally = () => {
    return window.location.hostname === "localhost";
}

const isValueNotEmpty = (strVal, successStrVal,defaultVal='') => {
    return strVal !== '' ? successStrVal : defaultVal
}

const hasValidValue = (strVal, successStrVal, defaultValue='') => {
    return strVal ? successStrVal : defaultValue
}

const isValueEmpty = (strVal)=>
{
    const value = hasValidValue(strVal,strVal);
    return value == ""
}

const isValidObjectValue = (objValue, successStrVal, defaultValue='') => {
    if(objValue)
    {
        return  Object.keys(objValue)?.length > 0  ? successStrVal : defaultValue;
    }
    else
    {
        return defaultValue;
    }
}

const getValueFromObject = (objValue,checkValue,successStrVal,falseStrVal) =>
{   
    return  (Object.keys(objValue)?.length > 0 && checkValue)   ? successStrVal : falseStrVal;
}


const isAppRunningInLowerEnvironment = () => {
    const listOfLowerEnv = ['localhost','dev.mysecure.vcm.com','qa.mysecure.vcm.com','stage.mysecure.vcm.com']
    return listOfLowerEnv.includes(window.location.hostname);
}

const isValidGender = (gender)=>
{
    return (gender === 'Male' || gender === 'Female')
}

const getZeroWhenFalse = (value) =>
{

    if(value == undefined || value == null || value =="" || value =='NaN')
    {
        return 0;
    }
    else
    {
        return value;
    }
}

const formatCurrencyAndRemoveDecimalPart = (value) => {
    if (!value) return '';

    const formattedValue = currencyFormatter.format(value, { locale: 'en-US'});

    // Remove ".00" from the formatted value
    return formattedValue.replace(/\.00$/, '');
};


const convert_CST_Timezone = (date="") => {
    if(date)
    {
        const cstTimeZone = 'America/Chicago';
        const formattedDate = moment(new Date(date)).tz(cstTimeZone).format("MMMM DD, YYYY hh:mm A");
        return `${formattedDate} CST`;
    }
    else
    {
        return '';
    }
};


const getDate = (strdate) => {
    let formattedDate  = ''
    if (strdate) {
        const newdate = strdate.split('/');
        formattedDate  = {
            month: parseInt(newdate[0]),
            day: parseInt(newdate[1]),
            year: parseInt(newdate[2])
        }
    }
    return formattedDate 
}


///
export {
    formatDate,
    isValidDate,
    formatSSNValue,
    inputCheck,
    formatDateToTwoDigit,
    formatMessageCenterDate,
    formatTimeAMPM,
    assignObj,
    formatPhoneNumber,
    formatPhoneNumberWithoutCountryCode,
    replaceSpecialChar,
    getFormattedSSN,
    getFormattedDate,
    maskEmail,
    getDomainName,
    getToken,
    formatInputValues,
    showOnlyLastFourChar,
    createBreadCrumbItem,
    addCommasToNumberStr,
    getPhoneNumberMaxLength,
    formatCurrency,
    getRemoveSpecialChars,
    removeCookies,
    getFISMemberId,
    getRole,
    validateSSNWithoutNANValidation,
    getValue,
    getRankValueBasedOnKey,
    toLocaleUTCDateString,
    isBackOfficeUser,
    isFrontOfficeUser,
    isMiddleOfficeUser,
    convert_UTC_TO_EST_Timezone,
    maskAccountNumber,
    validateForUndefinedAndGetValue,
    validateForEmptyAndGetValue,
    getMemberRoleFlag,
    convertToTitleCase,
    validateUndefinedNullAndEmptyValue,
    getDataFromORCondition,
    calculateTotalAmount,
    isUserGuestOrProspect,
    isValueNotEmpty,
    isValueEmpty,
    isValidObjectValue,
    getValueFromObject,
    isValidGender,
    getZeroWhenFalse,
    formatCurrencyAndRemoveDecimalPart,
    getDate,
    isRoleGuest,
    isRoleProspect,
    isRoleMember,
    isRoleVerifiedProspect,
    hasValidValue,
    convert_CST_Timezone
};
