import * as ActionTypes from '../ReduxConstants/TransactionActivityConstants';
import makeApiRequest from '../Network/ApiInterface';

export const getAccountActivities = payload => dispatch => {
    dispatch({
        type: ActionTypes.GET_ACCOUNT_ACTIVITIES,
    });

    makeApiRequest(
        ActionTypes.GET_ACCOUNT_ACTIVITIES,
        payload,
        null,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_ACTIVITIES_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_ACTIVITIES_ERROR,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_ACCOUNT_ACTIVITIES_ERROR,
                payload: error,
            });
        },
    );
};

export const loadMoreActivities = () => dispatch => {
    dispatch({
        type: ActionTypes.LOAD_MORE,
    });
};

export const cancelTransaction = pendingTransaction => dispatch => {
    const payload = {
        companyNumber: pendingTransaction.companyNumber,
        batchNumber: pendingTransaction.batchNumber.toString(),
        sequenceNumber: pendingTransaction.sequenceNumber.toString(),
        establishedDate: pendingTransaction.establishedDate,
    };
    
    dispatch({
        type: ActionTypes.CANCEL_ACTIVITY_TRANSACTION,
    });

    makeApiRequest(
        ActionTypes.CANCEL_ACTIVITY_TRANSACTION,
        payload,
        null,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.CANCEL_ACTIVITY_TRANSACTION_SUCCESS,
                    payload: pendingTransaction,
                });
            } else {
                dispatch({
                    type: ActionTypes.CANCEL_ACTIVITY_TRANSACTION_ERROR,
                    payload: pendingTransaction,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.CANCEL_ACTIVITY_TRANSACTION_ERROR,
                payload: error,
            });
        },
    );
};
