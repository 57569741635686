import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Row, Col } from 'react-bootstrap';


const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #FCF1F2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #EEA7AD;
    opacity: 1;
    border-radius: inherit;   
`;

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const WAlertComponent = props => {
    const { show, alertmsg } = props;
    return (
        <Row noGutters>
            <Col>
                {show && (
                    <StyledAlert variant="danger" {...props}>
                        <StyledPara>{alertmsg}</StyledPara>
                    </StyledAlert>
                )}
            </Col>
        </Row>
    );
};

WAlertComponent.propTypes = {
    alertmsg: PropTypes.string,
    show: PropTypes.bool,
};

WAlertComponent.defaultProps = {
    alertmsg: 'Error',
    show: false,
};

export default WAlertComponent;
