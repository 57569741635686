/**
 * MArketing and Privacy Component Styles and Constants
 ********************************
 *
 * @version 1.0.1
 * @author Sharanya B
 * @description The styles and constants required for the component will be taken from this file
 * @createdDate [06/11/2019]
 * ***************************************
 * @createdDate Sharanya B
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedReason Updated Styles (fonts , spacing between cards) for this page
 */

const pageBgColor = {
    // backgroundColor: '#F9FBFC'
}

const fontStyle ={
    fontFamily: 'Calibri'
}

const downloadLabelMargins = {
    padding: 0,
    marginBottom: 30
}

const submitButtonAlign = {
    paddingTop: 40,
    paddingBottom: 40
}

const greyStyledText ={
    color :"grey"
}

const qnForContactingOffers ={
    fontFamily: 'Calibri', 
    fontSize: 25, 
    color: 'grey'
}

const prdctOfferLabels={
    fontFamily: 'Calibri', 
    fontSize: 17, 
    color: 'grey'
}

const cardSpace = {
    paddingBottom: "30px"
}
const tabHeaderStyles={

        font: 'Bold 18px/24px Benton Sans',
        letterSpacing: 0,
        color: '#56565A',
        marginTop: '-23px',
        marginLeft: '20px'

}

const tabCardStyles ={
    border: "2px solid lightgrey", 
    display: 'flex', 
    background: 'white', 
    fontFamily: 'Calibri', 
    flexWrap: 'wrap'
}

const description ={
    fontFamily: 'Calibri', 
    fontSize: 17, 
    color: 'grey'
}

const descriptiveLabel= {
    color: "grey", fontFamily: 'Calibri'
}

const lblHeaderProducts ={
    padding: 0
}

const hdrTblValues={
    fontWeight: "bold", 
    color: "grey", 
    fontFamily: 'Calibri'
}

const expandLinkStyles ={
    color: "blue", 
    fontFamily: 'Calibri', 
    cursor: 'pointer', 
    float: 'right'
}

const investmentsLabels = {
    fontWeight: "bold", 
    color: "grey", 
    fontFamily: 'Calibri', 
    fontSize: "20px"
}

const typesOfOffers ={
    fontFamily: 'Calibri', 
    fontSize: 25, 
    color: 'grey'
}

const labelStyle = {
    height: '32px',
    textAlign: 'left',
    letterSpacing: 0,
    color: '#56565A',
    opacity: 1,
}

const manageStyle = {
    font: 'Bold 16px/19px Benton Sans',
    letterSpacing: 0,
    color: '#004A98',
    backgroundColor: '#F9FBFC',
    border: 'none',
    outline: 'none'
}

const margin = { marginBottom: '15px' }
const componentName = `Produtc & Services`;
const blockProp= false;
const breadCrumbValues = [{ name: 'Home'}];
const pageName = "Marketing & Offers";
const qnForContactingOffersText = "How should we contact you about offers?"
const prdctOfferLabelsText = "To meet your needs better , we'd like to reach out to you from time to time to let you know about the products and services we have to offer";
const permissionDisplay = "We show you gave VCM permission to make marketing calls to you using an automated dialing system or pre recorded voice message . If you want to change your preferences ."
const mobileTab = "Mobile";
const homeTab = "Home";
const emailTab = "Email";
const addressTab = "Address";
const typesOfOffersText = "What types of offers are you interested in ?";
const typesOfOffers2 = "You can tailor the VCM offers you receive and how you like to get them";
const lblHomePhone = "Home Phone";
const lblMobilePhone = "Mobile Phone";
const lblEmail = "Email";
const lblMailingAddress = "Mailing Address";
const lblInvestmentsLabels = "Investments";
const lblFinancialPlanning = "Financial Planning";
const lblContactUs = " Contact us";
const NA = "NA";
const para1 = "Please note that your selections do not apply to pre-screened offers of credit. To opt out of pre-screened offers of credit please visit https://www.optoutprescreen.com or call to free Phone Number  The phone number and website are operated by major consumer reporting companies."
const para2 = "Your marketing and privacy preferences will also apply to your spouse and children.";
const para3 = "This is sample content paragraph"

export default {
    pageBgColor,
    downloadLabelMargins,
    submitButtonAlign,
    greyStyledText,
    fontStyle,
    qnForContactingOffers,
    prdctOfferLabels,
    cardSpace,
    tabHeaderStyles,
    tabCardStyles,
    description,
    descriptiveLabel,
    lblHeaderProducts,
    hdrTblValues,
    expandLinkStyles,
    investmentsLabels,
    typesOfOffers,
    labelStyle,
    manageStyle,
    para3,para2,para1,NA,lblContactUs,lblFinancialPlanning,lblInvestmentsLabels,lblMailingAddress,lblEmail,
    lblMobilePhone,lblHomePhone,typesOfOffers2,addressTab,emailTab,homeTab,permissionDisplay,mobileTab,
    qnForContactingOffersText,pageName,breadCrumbValues,blockProp,componentName,margin,prdctOfferLabelsText,typesOfOffersText
}