import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    dashBoardLink: {},
    isAscensusRedirection:false
};

function dashBoardLinkReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_LOADER: {
            return { ...state, ...action, isLoading: true };
        }
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_SUCCESS: {
            return { ...state, ...action, isLoading: false, isSuccess: true, };
        }
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_FAILURE: {
            return { ...state, ...action, isLoading: false,isSuccess: false, isError: true, };
        }
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_ERROR: {
            return { ...state, ...action, isLoading: false,isSuccess: false, isError: true, };
        }
        default:
            return state;
    }
}

export default dashBoardLinkReducer;
