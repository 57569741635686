//Imports done here

import '../MarketNAOStyles.css'
import { Col, Row } from 'react-bootstrap';
import { Accordian, CommonButtons, AccordianTitle,WIcon,ConfirmModal,WSpinner} from 'common';
import editIcon from '../../../../assets/EditPencil.svg';
import React, { useState, useEffect,useRef} from 'react';
import { useHistory } from "react-router-dom"; 
import {useSelector,useDispatch} from  "react-redux";
import { VcmAccountInfo } from '../consts'
import handleBeneficiaryCardsGeneration from '../../Verify/HandleBeneficiaryCardsGeneration';
import SummaryCard from '../../Verify/SummaryCard';
import VerifyAccountFeatures from './VerifyAccountFeatures'
import VerifyRegulatoryInfo from './VerifyRegulatoryInfo';
import VerifyPersonalinfo from './VerifyPersonalInfo';
import VerifyJointPersonal from './VerifyJointPersonalInfo';
import { clearKycInfo, postForKycVerification ,clearBankAccountInfo} from 'shared/Actions/MarketNAOActions';
import Beneficiaries from '../AccountFeatures/Beneficiaries';
import { defaultArray, AccountDefault_Popup_Txt } from '../AccountFeatures/consts'
import { MessagePopup } from 'modules/CustomerManagementModule/components';
import useAutoFocus from './useAutoFocus'
import { MARKET_NAO_ACCOUNT_TYPES } from 'modules/AccountManagementModule/consts';
import {resetBrokerageAccountListState} from 'shared/Actions/BankAccountAction';
import { getValidValue, isHaveSpouse } from 'commonHelper/CommonHelperFunctions';
import ConfirmPersonalAndBeneficiaryModel from 'common/ConfirmPersonalAndBeneficiaryModel';

// Exporting default Function
export default function VerifyInfo(props) {
  const assignObj = obj => {
    return obj;
};
 
const bankAccountlaterText = "I will add my bank account later"
const addbankAccountText = "Add Bank Account"
const fundmyBankAccountLaterText = "I will fund my bank account later"
const addFundmyAccount = "Fund my Account"
const bankAccount = "Bank Account"
const instructionmsgText = "An Investment Specialist will reach out to assist you with adding funding instructions"
const initialDepositBank = "Initial Deposit Bank"
const initialDeposit = "Initial Deposit"

 //From Redux state
  const marketNAOInfoReducerDataRoot = useSelector(state => state.marketNAOReducerData);
  const memberDashboardData = useSelector(state => state.memberDashboardData);
  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData.marketNAOInfo);

  const accountInfo=marketNAOInfoReducerData.account.accountType
  const marketRootData = useSelector(state => state.marketNAOReducerData);
  const materLookUpData = useSelector(state => state.transactionCompositeDataReducerData);
  const beneficiariesData = marketNAOInfoReducerData.beneficiaries?.savedBeneficiaryData
  const accountSelected= sessionStorage.getItem("accountSelected")
  //Benificiary  component hide functionality
  const [showBenificiary, setShowBenificiary] = useState(false)

  //edit hide
  const [verifyEditHide, setVerifyEditHide] = useState(false)
  //Default account section popup satate
  const [showMaritialStatusPopup,setShowMaritialStatusPopup] = useState(false);
  const [editPersonalInfoPage,setPersonalInfoPage] = useState(false)
  const [accountDefaultPopUp, setaccountDefaultPopUp] = useState(false)
  const beneficiaryRef = useRef(null); 
  useAutoFocus(showBenificiary,beneficiaryRef)


//EditRoutes and props are here
  const history = useHistory();
  const editPersonalHandler=()=>history.push("/marketPersonalInfo")
  const editRiskBrokerHandler=()=>{return(props.onEditButtonClick('Regulatory','primary'))}
  const editRiskJointBrokerHandler=()=>{return(props.onEditButtonClick('Regulatory','joint'))}
  const editBeneficiariesHandler=()=>{return setShowBenificiary(true)}
  const editTrustedContact=()=>{return(props.onEditButtonClick('AccountFeatures','trustedContact'))}
  const editEdelivery=()=>{return(props.onEditButtonClick('AccountFeatures','edelivery'))}
  let showEditButton = editPersonalInfoPage ? false :true
  let showFooter = true
  const dispatch = useDispatch()

  const initialState = {
    showKycErrorModel: false,
    isLoading: marketRootData.isKycLoader,
  }
  const [state, setState] = useState(initialState)
  const [editDisabler, setEditDisabler] = useState(true)
  const [maritalStatus,setMaritalStatus] = useState('');
  const hasFundingStep = memberDashboardData.isEnableFeatureSuccess && memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false
  const isRecurringFundEnabled = memberDashboardData.isEnableFeatureSuccess && memberDashboardData.getEnabledFeature.features.includes('BankAccountRecurringFunding') ? true : false

  useEffect(() => {
    setEditDisabler(!showBenificiary)
  }, [showBenificiary])

 

  const disableEditBackAndNextButton = () => {
   
    if(marketNAOInfoReducerData?.applicationStatus != undefined ){

        // if the kyc check is completed then disable the edit button, user should only be able o move forward by clciking next
      if (marketNAOInfoReducerData.applicationStatus == 'KYC_Pass' || marketNAOInfoReducerData.applicationStatus == 'KYC_SoftFail') {
          showEditButton = false
      }

      if(marketNAOInfoReducerData.applicationStatus == 'Submitted' ||
        marketNAOInfoReducerData.applicationStatus == 'Secondary_econsent_pending'  ){

        showEditButton = false

      }

      if(marketNAOInfoReducerData.applicationStatus == 'Submitted' || (
          marketNAOInfoReducerData.applicationStatus == 'Secondary_econsent_pending' && marketNAOInfoReducerData.isPrimary)  ){

        showFooter = false
    }
    }
  }

  
  disableEditBackAndNextButton()

  useEffect(() => {
    if(marketRootData.isKycSuccess){
      // move to next screen on 3000 status code with showing confirmation box pop up
      if (marketRootData.kycResponseCode == 3000 || marketRootData.kycResponseCode == 3007) {
        dispatch(clearKycInfo())
        props.onNextClick()
      }
    }else if(marketRootData.isKycError){
      setState(prevState => ({ ...prevState, showKycErrorModel: true }));
    }
  },[marketRootData.isKycSuccess, marketRootData.isKycError])

  const validateMaritialStatus = () => {
    let validFlag = true;
    if(marketRootData?.marketNAOInfo?.personalInfo?.primary?.maritalStatus != "Married") {
      if (isHaveSpouse(marketRootData?.marketNAOInfo?.beneficiaries?.savedBeneficiaryData?.beneficiaryPrimaryDataArray, 'spouse') ||
        isHaveSpouse(marketRootData?.marketNAOInfo?.beneficiaries?.savedBeneficiaryData?.beneficiaryContingentDataArray, 'spouse')) {
        validFlag = false;
      }
    }

    return validFlag;
  }

const onButtonClick = () => {    
      const payload = {
        applicationId: marketRootData.applicationId,
        nao: "brokerage"
      }
  let showMaritialStatus = true;
  if (marketRootData?.marketNAOInfo?.account?.accountType?.includes('IRA')) {
    showMaritialStatus = validateMaritialStatus();
    if (showMaritialStatus == false) {
      setShowMaritialStatusPopup(true)
      setMaritalStatus(marketRootData?.marketNAOInfo?.personalInfo?.primary?.maritalStatus)
    }
    else {
      dispatch(postForKycVerification(payload))
      setShowMaritialStatusPopup(false)
    }
  }
  else {
    dispatch(postForKycVerification(payload))
  }
}

  const handleConfirmation = () => {
    const responseCode = marketRootData.kycResponseCode;
    if(responseCode == 3005){
      dispatch(clearKycInfo())
      // redirect to personal info page
      editPersonalHandler()
    }else if(responseCode == 3000 || responseCode == 3006 || responseCode == 404 || responseCode == 3007){
      dispatch(clearKycInfo())
      // redirect to home
      history.push({
          pathname: '/accountholder',
      });
    }else{
      // move to next page for any response
      dispatch(clearKycInfo())
      props.onNextClick()
    }
}

  //beneficiaries
  const isMarketNaoFlow = true
  const beneficiaryCardData = [...handleBeneficiaryCardsGeneration(materLookUpData, accountInfo, beneficiariesData, isMarketNaoFlow)]

  useEffect(()=>{

    if(marketRootData?.kycResponseCode == 3000)
    {
      handleConfirmation();
    }
  },[marketRootData])

  const renderCard = ({ cardHeading, fields, cardId, linkText }, index) => (
    <SummaryCard
        accountType={accountInfo}
        cardHeading={cardHeading}
        linkText={linkText}
        cardId={cardId}
        fields={fields}
        index={index.toString()}
        onClick={editBeneficiariesHandler}
        originPage="verify"
        marketNAOFlowVerify={true}
        showEditButton={showEditButton}
    />
);


//For Avoiding div repeatations
 const linesReducer=(verifyHeading,verifyValue)=>{return(
  <div className="card-list-wrapper">
            <div class="row">
              <div class="label-key col-lg-4 col-md-4 col-sm-12 col-12">
                <span>{verifyHeading}</span>
              </div>
              <div class="label-value col-8"><span>{verifyValue}</span></div>
            </div>
          </div>
 )}
  //For Avoiding div repeatations
  const DefaultLinesReducer = (verifyHeading, verifyValue) => {
    return (
      <div className="card-list-wrapper">
        <div class="row">
          <div class="label-key col-lg-5 col-md-4 col-sm-12" >
            <span>{verifyHeading}</span>
          </div>
          <div className="DefaultValue col-6 col-lg-6"><span>{verifyValue}</span></div>
        </div>
      </div>
    )
  }

 // SCROLL TO TOP FUNCTION
 const scrollToTop = () => {
  setTimeout(() => window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
  }))
}

useEffect(()=>{
dispatch(resetBrokerageAccountListState());
dispatch(clearBankAccountInfo());
scrollToTop()
  document.title = `Verify Page | Victory Capital`;
},[])

const recurringFundingSection = () => {
  const isRecurringFundingAccount = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding.recurringFundingAccount === 'Yes' ? true : false
  const recurringFundAccountList = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding.recurringFundAccountList

  
  const selectedFundAccountList = isRecurringFundingAccount && recurringFundAccountList.length > 0 ? recurringFundAccountList.filter(item => {
    return item?.selected === true
  }) : []

  let selectedFundListDom = <></>
  if(selectedFundAccountList.length > 0){
    selectedFundListDom =  selectedFundAccountList.map((item,index) => {
        
      return(<>
        <div className="card-list-wrapper">
          <div class="row" key={item.id}>
            <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
              <span>{item.maskBankName? `Recurring Payment Bank ${ index + 1}` :null} </span>
            </div>
            <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{item?.maskBankName || null}</span></div>
          </div>
        </div>
        <div className="card-list-wrapper">
          <div class="row" key={index}>
            <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
              <span>Recurring Payment Amount {index + 1}</span>
            </div>
            <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>${item?.fundAmount}</span></div>
          </div>
        </div>
        
        <div className="card-list-wrapper">
          <div class="row" key={index}>
            <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
              <span>Recurring Payment Frequency {index + 1}</span>
            </div>
            <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{item?.frequency}</span></div>
          </div>
        </div>

        <div className="card-list-wrapper">
            <div class="row" key={index}>
              <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                <span>Recurring Payment Start Date {index + 1}</span>
              </div>
              <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{getValidValue(item?.recurringstartdate, '')}</span></div>
            </div>
          </div>
      </>)
    })
  }

 
  return (
    <>
      {selectedFundListDom}
    </>
  )
}

const transferFields = () => {
  const transferAssets = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding.transferAssets
  const fundAsDirectRollover = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding.fundAsDirectRollover


      return (<>
        { transferAssets ?
          <>
           <div className="card-list-wrapper">
             <div class="row" key="">
               <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                 <span>Initiate Asset Transfer</span>
               </div>
               <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>Yes</span></div>
             </div>
           </div>
  
          </>
         : null
        }
  
        { fundAsDirectRollover ?
          <>
           <div className="card-list-wrapper">
             <div class="row" key="">
               <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                 <span>Initiate Direct Rollover</span>
               </div>
               <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>Yes</span></div>
             </div>
           </div>
  
          </>
         : null
        }
      </>)
    }

    const bankFundingContent = () => {

      const addBankDetails = marketNAOInfoReducerDataRoot.marketNAOInfo.addBankDetails;
      const accountsSelectedForFunding = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding.selectedBankInfo
      const allSelectedAccounts = addBankDetails.selectedBankInfo
  
      const contributionYear = marketNAOInfoReducerDataRoot.marketNAOInfo.accountFunding?.iraContributionYear
      
      if(addBankDetails?.addBankLaterSelect == true)
      {
          return (
            <>  
               <div className="card-list-wrapper">
                <div class="row" key={"addBankAccountLater"}>
                  <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                    <span>{bankAccount}</span>
                  </div>
                  <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{bankAccountlaterText}</span>
                    { ((isRecurringFundEnabled || hasFundingStep) && showEditButton) ? <p className='verifyscreenlinks' onClick={()=>props.onEditButtonClick('AccountFeatures','addBankAccount')}>{addbankAccountText}</p> : null}
                  </div>
                </div>
              </div>
  
              {transferFields()}
            </>
          )
      }
      else if((addBankDetails?.addExistingBankSelect == true || addBankDetails?.addNewBankAccountSelect == true )
        && addBankDetails?.selectedBankInfo?.length > 0 && accountsSelectedForFunding?.length <= 0)
      {
          return (
            <>  
                {
                allSelectedAccounts.map(item => {
                  return(
                    <div className="card-list-wrapper">
                      <div class="row" key={item.id}>
                        <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                          <span>{item.maskBankName ? "Bank Account":null}</span>
                        </div>
                        <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{item.maskBankName || null}</span></div>
                      </div>
                    </div>
                  )
                })
              }  
  
  
              {transferFields()}
            </>
          )
      }
      else if((addBankDetails?.addExistingBankSelect == true || addBankDetails?.addNewBankAccountSelect == true ) 
        && addBankDetails?.selectedBankInfo?.length <= 0)
      {
        return (
          <>  
             <div className="card-list-wrapper">
              <div class="row" key={"addBankAccountLater"}>
                <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                  <span>{bankAccount}</span>
                </div>
                <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6">
                  <p>{instructionmsgText}</p>
                </div>
              </div>
            </div>
  
            {transferFields()}
  
          </>
        )
      }
  
      return (
      <>
        {
          allSelectedAccounts.map(item => {
            return(
              <div className="card-list-wrapper">
                <div class="row" key={item.id}>
                  <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                    <span>{item.maskBankName ? "Bank Account":null}</span>
                  </div>
                  <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{item.maskBankName || null}</span></div>
                </div>
              </div>
            )
          })
        }
        
        {
          accountsSelectedForFunding.length > 0 && accountsSelectedForFunding.map((item,index) => {
            
            return(<>
              <div className="card-list-wrapper">
                <div class="row" key={item.id}>
                  <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                    <span>{item.maskBankName? `Funding Bank ${ index + 1}` :null} </span>
                  </div>
                  <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{item.maskBankName || null}</span></div>
                </div>
              </div>
              <div className="card-list-wrapper">
                <div class="row" key={index}>
                  <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                    <span>{initialDepositBank} {index + 1}</span>
                  </div>
                  <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>${item.fundAmount}</span></div>
                </div>
              </div>
            </>)
          })
        }

      {
        contributionYear && <>
              <div className="card-list-wrapper">
                <div class="row">
                  <div class="label-key  col-sm-12 col-lg-6 col-md-6 col-xl-5" >
                    <span>Contibution Year</span>
                  </div>
                  <div className="DefaultValue col-6 col-lg-6 col-md-6 col-xl-6"><span>{contributionYear == '0' ? 'Current Year' : 'Prior Year'}</span></div>
                </div>
              </div>
            </>
      }
  
        {
          isRecurringFundEnabled ? recurringFundingSection() : null
        }
  
        {transferFields()}
  
      </>
      )
  
    }


  const handleCancelMaritialPopup = () => {
    setShowMaritialStatusPopup(false);
  }

  const editBeneficiariesInfomationHandler = () => {
    setShowBenificiary(true);
    setShowMaritialStatusPopup(false);
    setEditDisabler(false)
    window.scrollTo(0, 2900);
  }

  const editPersonalInfoHandler = () => {
    setPersonalInfoPage(true)
    setShowMaritialStatusPopup(false)
    setEditDisabler(false)
    window.scrollTo(0, 400);
  }

  const closeEditiableScreen = () => {
    setPersonalInfoPage(false);
    setEditDisabler(true);
    showEditButton = true;
  }
//Rendering done here

  return (

  
    <div className='verify'>
      <div className='banner step-banner'>
        <p className='white-text center'>Verify Your Information</p>
      </div>
      {marketRootData.isKycLoader && <WSpinner loading={marketRootData.isKycLoader} /> }
      {state.showKycErrorModel && marketRootData.isKycError && (
        <ConfirmModal
          modalTitle=""
          modalBodyText={marketRootData?.kycResponseData == undefined ? "Error occured while processing your information.": marketRootData?.kycResponseData?.exceptionDetails?.logMessage}
          primaryButtonText="Ok"
          onPrimaryClick={handleConfirmation}
        />
      )}
      
      <>

      {showMaritialStatusPopup && 
      <ConfirmPersonalAndBeneficiaryModel 
          modalTitle="Unable to proceed with online application"
          modalBodyText={`Non-Married individuals cannot select a spouse as a beneficiary. Please review and update either your Marital Status or your Beneficiaries relationship to you in order to proceed with your application.`}
          modalBodyText3={maritalStatus}
          modalBodyText4={"spouse"}
          showRelationship={true}
          editBeneficiariesInfomationHandler={editBeneficiariesInfomationHandler}
          editPersonalInfoHandler={editPersonalInfoHandler}
          handleCancelMaritialPopup={handleCancelMaritialPopup}
          //confirmModelprop={confirmModelprop}

    />}
      <Accordian
        accordianPaddingLeft={0}
        id={VcmAccountInfo}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col  md={10} xl={11}>
          <AccordianTitle
            accType={'VCM Investment Account Information'}
            className="accordionH2Style"
            requireButtonPadding={false}
          /></Col>    
          
          </Row>
        
          
        }
        titleSeperator
      ><div className="sum-card-wrap">
       {linesReducer("Registration",accountInfo == "" ? "-" : accountInfo === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM : accountInfo)}</div></Accordian>


       <VerifyPersonalinfo showEditButton = {showEditButton } editDisabler={editDisabler} setEditDisableVal={(val) => setEditDisabler(val)} setPersonalInfoPage={setPersonalInfoPage} editPersonalInfoPage={editPersonalInfoPage} closeEditiableScreen={closeEditiableScreen} />

      {accountInfo==MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && <VerifyJointPersonal showEditButton = {showEditButton } editDisabler={editDisabler} setEditDisableVal={(val) => setEditDisabler(val)} />}

      {/* Regulatory information for both primary and joint if available */}

        <VerifyRegulatoryInfo editDisabler={editDisabler} setEditDisableVal={(val) => setEditDisabler(val)} showEditButton={showEditButton} onEditRegulatory={editRiskBrokerHandler} primaryOrSecondary='primary' riskRegulatoryTitle="Investor Profile & Risk" brRegulatoryTitle="Broker-Dealer & Politically Exposed Person"
          onNextClick={props.onNextClick} />
      {
          accountInfo === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? 
            <VerifyRegulatoryInfo editDisabler={editDisabler} setEditDisableVal={(val) => setEditDisabler(val)} showEditButton={showEditButton} onEditRegulatory={editRiskJointBrokerHandler} primaryOrSecondary='joint' riskRegulatoryTitle="Investor Profile & Risk - Joint Owner" brRegulatoryTitle="Broker-Dealer & Politically Exposed Person - Joint Owner" 
            onNextClick={props.onNextClick}  /> : <></>

      }
      
       {showBenificiary ? <div><Beneficiaries showBenificiary={showBenificiary} setEditDisabler={(val) => setEditDisabler(val)} HideBeneficiary={(val) => setShowBenificiary(val)}  
        onNextClick={props.onNextClick}
        /></div>
          : <div ref={beneficiaryRef} tabIndex="-1">
       
        {accountInfo.toLowerCase().includes('ira') ? beneficiaryCardData &&
          beneficiaryCardData.map((card, index) => (
              <div key={`${card.cardId}-${Math.random()}`}>
                  {renderCard(card, index)}
              </div>
        )):null}
           </div>}

        

      <VerifyAccountFeatures editDisabler={editDisabler} setEditDisabler={(val) => setEditDisabler(val)} showEditButton = {showEditButton }  onEditTrustedContact={editTrustedContact} onEditEdelivery={editEdelivery} verifyEditHide={verifyEditHide} setVerifyEditHide={(val) => setVerifyEditHide(val)} 
       onNextClick={props.onNextClick} />
      </>
    <MessagePopup show={accountDefaultPopUp} modalBodyText={AccountDefault_Popup_Txt} secondaryButtonText="Ok"  onSecondaryClick={() => setaccountDefaultPopUp(!accountDefaultPopUp)} />
      <Row>
        <Col md={12} xl={12} style={{position: 'relative'}}>
          <hr className='borderforverify'></hr>
            <Row>
            <Col md={10} xl={11}>
          <Accordian
            accordianPaddingLeft={0}
            id={"Account Defaults"}
            titleChild={
              <Row>
                <Col md={12} xl={12}>
                  <AccordianTitle
                    accType={'Account Defaults'}
                    className="accordionH2Style"
                    requireButtonPadding={false}
                  />
                </Col>
              </Row>
            } >
            <div className="sum-card-wrap mt-4" >
              {defaultArray.map((item) => {
                return (
                  DefaultLinesReducer(item.header, item.value)
                )
              })}
            </div>

          </Accordian>
        </Col>
        <Col md={2} xl={1} className="sum-prelink">
          { editDisabler && showEditButton &&
            <Row noGutters>
              <WIcon
                src={editIcon}
                size={"1px"}
                className="editIcon"
                fontawesomestyle={assignObj({ paddingLeft: 0 })}
              />
              <button type="button" className="sum-linktext mt-1" onClick={() => setaccountDefaultPopUp(!accountDefaultPopUp)}>
                <span>Edit</span>
              </button>
            </Row>
          }
        </Col>
            </Row>
        </Col>
        
      </Row>

      {
        hasFundingStep ? 
        <Row >
        <Col md={12} xl={12} style={{ position: 'relative' }}>
          <hr className='borderforverify'></hr>
          <Row>
            <Col md={10} xl={11} xs={9} >
              <Accordian
                accordianPaddingLeft={0}
                id="bank-and-funding-details-section"
                titleChild={
                  <Row>
                    <Col md={12} xl={12} >
                      <AccordianTitle
                        accType={'Banking and Funding'}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                      />
                    </Col>
                  </Row>
                } >
                <div className="sum-card-wrap mt-4" >
                  {bankFundingContent()}
                 
                </div>
              </Accordian>
            </Col>
            <Col md={2} xl={1} className="sum-prelink">
                  {editDisabler && showEditButton && <Row noGutters>
                    <WIcon
                      alt=""
                      src={editIcon}
                      size={"1px"}
                      className="editIcon"
                      fontawesomestyle={assignObj({ paddingLeft: 0 })}
                    />
                    <button type="button" data-testid='trustedcontactedit' className="sum-linktext mt-1" onClick={()=>props.onEditButtonClick('AccountFeatures','addBankAccount')}>
                      <span>Edit</span>
                    </button>
                  </Row>}
            </Col>
          </Row>
        </Col>
      </Row>
      :
      null
      }
      
      {/* Button navigations are here*/}
      {editDisabler ?
        <div className='footer'>
          <CommonButtons backClick={props.onBackClick} showBack={showEditButton} disabled={!showFooter} nextClick={onButtonClick} nextText="Send to Client" isShowBtn={true}  />
        </div> : <div className='footer'>
          <CommonButtons backClick={props.onBackClick} showBack={showEditButton} disabled={!editDisabler} nextClick={onButtonClick} nextText="Send to Client" isShowBtn={true}  />
        </div>
      }
     
    </div>
  )
}
