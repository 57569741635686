import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { FirstName } from '../Input/FirstName';
import { LastName } from '../Input/LastName';
import { MiddleName } from '../Input/MiddleName';
import { Prefix } from '../Input/Prefix';
import { Suffix } from '../Input/Suffix';
import { MobileNoCard } from '../Input/MobileNoCard';
import { EmailId } from '../Input/Email';
import classes from '../Input/Input.module.css';
import { WRadio } from '../../../common';
import './PersonalDetails.css';
// import  PhoneType from '../../CustomerManagementModule/components/PhoneCard/index'

const assignObj = obj => obj;

const style = {
    main: { width: "100%", display: "flex" },
    suffix: { width: "50%", boxSizing: "border-box" },
    prefix: { width: "50%", boxSizing: "border-box", marginRight: '30px' },
    radioText: {font: '600 14px/20px benton-sans , sans-serif', marginBottom: 0},
    formGroup: {display: 'flex'},
};

const data = {
    optionalMessage: '* All fields are required unless mentioned optional',
    verifyLabel: 'Do you have USAA or Victory Member ID?',
    inputCol: {paddingLeft: 0, paddingRight: 14},
    primaryPhoneNoTxt: 'Primary Phone Number'
};



class PersonalDetailsForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            otpIvrSelect:'no'
        }
    }

    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        getCompositeLookUpData(['prefix']);
        getCompositeLookUpData(['suffix']);
    }

    

    render() {
        const { prefixData, suffixData, state, changed, blurred, clicked, emailLink, phoneTypeToggle, radioClick } = this.props;
        const { form } = state;
        const { otpIvrSelect } = this.state;
       // console.log('@debug for form value ', form);
        return (
            <div className="personalDetailsFormDiv">
                <Col md={12} lg={12} xl={12} style={data.inputCol}>
                <span className={`${classes.Label} verifyAuthPref1`} aria-hidden="true">{data.verifyLabel}</span>
                <Form.Group role="radiogroup" style={style.formGroup} aria-describedby="otpivrSelectError" aria-labelledby="otpivrSelectLabel">
                    <span className="sr-only" id="otpivrSelectLabel">{data.verifyLabel}</span>
                    <WRadio
                        id="yes"
                        value="Yes"
                        radioFor="otpIvrSelect"
                        selected={otpIvrSelect==='yes'}
                        onClick={radioClick}
                        role="radio"
                        radiotext={style.radioText}
                        radiostyles={assignObj({minHeight:0})}
                    />
                    <WRadio
                        id="no"
                        value="No"
                        radioFor="otpIvrSelect"
                        onClick={radioClick}
                        selected={otpIvrSelect==='no'}
                        role="radio"
                        radiotext={style.radioText}
                        radiostyles={assignObj({minHeight:0})}
                    />
                </Form.Group>
                </Col>
                <div style={style.prefix}>
                    {(!emailLink || (emailLink && form.prefix.value)) && <Prefix
                        prefix={prefixData}
                        prefixState={form.prefix}
                        changed={changed}
                        disable={emailLink}/>}
                </div>
                <FirstName
                    firstNameState={form.firstName}
                    changed={changed}
                    blurred={blurred}
                    clicked={clicked}
                    disable={emailLink} />
                {(!emailLink || (emailLink && form.middleName.value)) && <MiddleName
                    middleNameState={form.middleName}
                    changed={changed}
                    blurred={blurred}
                    clicked={clicked}
                    disable={emailLink} />}
                <LastName
                    lastNameState={form.lastName}
                    changed={changed}
                    blurred={blurred}
                    clicked={clicked}
                    disable={emailLink} />
                {/* <div style={style.main} > */}
                    <div style={style.suffix}>
                        {(!emailLink || (emailLink && form.suffix.value)) && <Suffix
                            suffix={suffixData}
                            suffixState={form.suffix}
                            changed={changed}
                            disable={emailLink}/>}
                    </div>
                {/* </div> */}
                {/* {(!emailLink || (emailLink && form.mobileNumber.value)) && 
                    <MobileNumber
                    countryCodeState={form.countryCode}
                    mobileNumberState={form.mobileNumber}
                    changed={changed}
                    blurred={blurred}
                    disable={emailLink} 
                    />} */}
                {(!emailLink || (emailLink && form.mobileNumber.value)) && 
                 <MobileNoCard 
                    cardTitle = {data.primaryPhoneNoTxt}
                    changeIsMobile={phoneTypeToggle}
                    countryCodeState={form.countryCode}
                    mobileNumberState={form.mobileNumber}
                    changed={changed}
                    blurred={blurred}
                    clicked={clicked}
                    disable={emailLink}
                   // isSignUp='true'

                 />
                    }
                <EmailId
                    emailIdState={form.emailId}
                    changed={changed}
                    blurred={blurred}
                    clicked={clicked}
                    disable={emailLink} />
            </div>
        );
    }
}

PersonalDetailsForm.propTypes = {
    state: PropTypes.instanceOf(Object),
    prefixData: PropTypes.shape({
        result: PropTypes.shape({
            value: PropTypes.instanceOf(Array)
        })
    }),
    suffixData: PropTypes.shape({
        result: PropTypes.shape({
            value: PropTypes.instanceOf(Array)
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    radioClick: PropTypes.func,
    getCompositeLookUpData: PropTypes.func,
    emailLink: PropTypes.bool,
    phoneTypeToggle: PropTypes.func
}

PersonalDetailsForm.defaultProps = {
    state: {},
    prefixData: null,
    suffixData: null,
    changed: () => { },
    blurred: () => { },
    getCompositeLookUpData: () => { },
    radioClick: () => {},
    emailLink: false,
    phoneTypeToggle: () => {}
}

export default PersonalDetailsForm;
