import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import routeConstants from 'routeConstants';
import CONST from "modules/CustomerManagementModule/ContactInformation/constants";
import { BottomNavBar, InstructionalPanel, PageTitle, FieldValue, RadioGroup, Address } from 'modules/CustomerManagementModule/components';
import {
    getKeyfromValue,
    getValuefromkey,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;
export default class RelationshipAddContactInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };
    }
    
    componentDidMount() {
        const { location, isEdit, getCompositeLookUpData, setAddress } = this.props;
        const { state:locationState } = location;
        const { 
            addressLine1,
            addressLine2,
            addressType,
            city,
            state:stateName,
            zip,
        } = locationState || {};
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
        if(isEdit){
            // From API null values are possible so one level of checking is done.
            const addressLine1Value = addressLine1 || '';
            const addressLine2Value = addressLine2 || '';
            const cityValue = city || '';
            const stateValue = stateName || '';
            const zipValue = zip || '';
            const addressTypeValue = addressType || '';
            // Setting Employer Address to Address component
            setAddress({
                addressLine1: addressLine1Value,
                addressLine2: addressLine2Value,
                zip: zipValue,
                city: cityValue,
                state: stateValue,
                addressType: addressTypeValue,
            },'address');
        }
        addFormAbandonUnloadListner(isEdit);
    }
    
    componentDidUpdate(){
        addFormFieldsListner();
        const { profileRelationshipData, clearRelationActions, history, analyzeClickEvent, linkResponseError, isEdit } = this.props;
        const { isError, isSuccess, message } = profileRelationshipData;
        if(isError){
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_managerelationship_managecontactinfo_${isEdit?'edit':'add'}ContactInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearRelationActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_managerelationship_managecontactinfo_${isEdit?'edit':'add'}ContactInfo_save`, analyzeClickEvent);
            history.push(routeConstants.relationshipContactInfo);
        }
    }
    
    componentWillUnmount() {
        const { profileRelationshipData, resetAddressInfo, isEdit } = this.props;
        const { isSuccess } = profileRelationshipData;
        resetAddressInfo('address');
        if(!isSuccess){
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }
    
    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            address_type:addressType
        } = masterLookupStateData || {};
        const payLoad = [];
        if (!addressType) payLoad.push('address_type');
        return payLoad;
    };
    
    cancelContactInfo = () => {
        const {  history, analyzeClickEvent, isEdit } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managerelationship_managecontactinfo_${isEdit?'edit':'add'}ContactInfo_cancel`
        })
        history.push(routeConstants.relationshipContactInfo);
    }

    updateOrAddAddress = () => {
            const { addressInfo, location, profileRelationshipData={}, updateProfileRelationship } = this.props;
            const { fields } = this.state;
            const { familyRelationShipInfo, familyMemberIndex } = profileRelationshipData || {};
            const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
            const { relationshipId='' } = familyMemberInfo;
            const { addressType:fieldsaddressType } = fields;
            const { state:locationState } = location;
            const { 
                isMailingAddress=false,    
                isPhysicalAddress=false,
                addressType: locationaddressType
            } = locationState || {};
            const addressType = fieldsaddressType || locationaddressType;
            const { addressInformation }= addressInfo;
            const {
                addressLine1,
                addressLine2,
                zip,
                city,
                state,
              } = addressInformation;
            const Payload = {
                    role : "Family",
                    relationshipId,
                    addressUpdate: [
                        {
                            addressType,
                            addressLine1,
                            addressLine2,
                            zip,
                            city,
                            state,
                            isPhysicalAddress,
                            isMailingAddress,
                        }
                    ]
            }
            updateProfileRelationship(Payload);
    };
    
    getMasterDataValues = (masterLookupStateData) => {
        const {
            address_type: addressTypesMaster,
        } = masterLookupStateData || {};
        const { value: addressTypesMasterValue } = addressTypesMaster || {};

        const addressTypeData = addressTypesMasterValue || [];
        return { addressTypeData };
    };
    
    handleRadioChange = (e) => {
        const { fields } = this.state;
        const { target } = e;
        const { value } = target;
        const { masterLookupStateData } = this.props;
        const addressTypeValue = getKeyfromValue(masterLookupStateData, 'address_type',value);
        fields.addressType = addressTypeValue;
        this.setState({
            fields,
        });
    }

    render() {
        const { isEdit, masterLookupStateData, location, profileRelationshipData, addressInfo } = this.props;
        const { state } = location;
        const { 
            addressType,
            isMailingAddress,
            isPhysicalAddress,
        } = state || {};
        const addressTypeError = '';
        const data = this.getMasterDataValues(masterLookupStateData);
        const { isLoading: relationLoading } = profileRelationshipData;
        const { isLoading: addressLoading } = addressInfo;
        const loading = relationLoading || addressLoading;
        let typeOfAddress = CONST.SESSIONAL_ADDRESS;
        if(isMailingAddress) typeOfAddress = CONST.MAILING_ADDRESS;
        if(isPhysicalAddress) typeOfAddress = CONST.PHYSICAL_ADDRESS;

        primaryBtnProps.onClick = this.updateOrAddAddress;
        cancelBtnProps.onClick = this.cancelContactInfo;

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={isEdit ? CONST.BREADCRUM_RELATION_CONTACT_EDIT: CONST.BREADCRUM_RELATION_CONTACT_ADD}
                            activeCrumb={isEdit ? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                        />
                        <PageTitle 
                            title={isEdit? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                            showMandatoryTxt
                        />
                        <FieldValue
                            as="p"
                            id="vcm-cms-address-type"
                            label={CONST.ADDRESS_TYPE}
                            value={
                                <RadioGroup
                                    labelledBy="vcm-cms-address-type"
                                    vertical
                                    error={!!addressTypeError}
                                    errorTxt={CONST.ADDRESS_TYPE_ERROR}
                                    options={data.addressTypeData}
                                    initialValue={getValuefromkey(masterLookupStateData,'address_type',addressType)}
                                    onChange={this.handleRadioChange}
                                    data-name={CONST.ADDRESS_TYPE}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <Address 
                            optional
                        />
                        <InstructionalPanel mb={2.5} mbt={2.5}>
                            <ul>
                                <li>{CONST.MAKING_CHANGE} {typeOfAddress} </li>
                                <li>{CONST.VERIFY_TEXT} </li>
                            </ul>
                        </InstructionalPanel>
                    </Container>
                    <BottomNavBar
                        hideBack
                        showCancelBtn
                        primaryBtnText={CONST.SAVE}
                        primaryBtnProps={primaryBtnProps}
                        cancelBtnProps={cancelBtnProps}
                        hidePrimaryBtn = {hideFlag}
                    />
            </>
        );
    }
}

RelationshipAddContactInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    clearRelationActions: PropTypes.func,
    isEdit: PropTypes.bool,
    masterLookupStateData: PropTypes.instanceOf(Object),
    addressInfo: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    setAddress: PropTypes.func,
    resetAddressInfo: PropTypes.func,
    profileRelationshipData: PropTypes.instanceOf(Object),
    updateProfileRelationship: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    linkResponseError: PropTypes.func,
};

RelationshipAddContactInfoComponent.defaultProps = {
    clearRelationActions: () => {},
    isEdit: false,
    masterLookupStateData: {},
    addressInfo: {},
    getCompositeLookUpData: () => {},
    setAddress: () => {},
    resetAddressInfo: () => {},
    profileRelationshipData: {},
    updateProfileRelationship: () => {},
    analyzeClickEvent: ()=>{},
    linkResponseError: ()=>{},
};
