import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import  WSwitch from '../WSwitch/WSwitch';
import WIcon from '../WIcon/WIcon'; 
import styles from '../CommonStyles';
import riskHigh from '../../assets/riskhigh.PNG';
import riskMedium from '../../assets/riskmedium.PNG';
import riskLow from '../../assets/risklow.PNG';

const AccountCardFlex = styled.div`
    display: flex;
`;
const AccountCardTopSection = styled(AccountCardFlex)`
    justify-content: space-between;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
    opacity: 1;
`;
const AccountCardBottomColumn = styled(AccountCardFlex)`
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 300px;
    padding: 0 4px;
    border-left: 1px solid #d2d2d2;
    &:first-child {
        padding-left: 0;
        border: none;
    }
    &:last-child {
        padding-right: 0;
    }
`;
const AccountCardHeadingSpan = styled.span`
    text-align: left;
    font: 600 16px/19px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
    margin-bottom:8px;
    .riskValue {
        font: 500 14px/17px benton-sans;
        float: right;
        margin-left: 10px;
    }
`;
const AccountCardValueSpan = styled.span`
    text-align: left;
    font: 14px/17px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
    display: flex;
    &.iconCol {
        padding-left:23px;
        position: relative;
    }
`;
const StyledCard = styled(Card)`
    cursor: ${props => props.cursor};
    margin-bottom: 40px;
    outline: ${props => (props.selected ? 'none' : '1px solid #D2D2D2')};
    && {
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border: ${props => (props.selected ? '2px solid #8BC105' : '2px solid #fff')};
        border-radius: 0;
    }
    opacity: 1;
    &:hover {
        border: ${props => (props.selected ? '2px solid #8BC105' : '2px solid #004A98')};
        &::before {
            border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #004A98')};
        }
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -2px;
        left: -2px;
    }
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 1.25rem 2.25rem;
    }
`;

const StyledFundName = styled.label`
    font: 800 18px/45px yorkten-slab-normal;
    margin-bottom: 25px;
    line-height: 1;
    color: #486D90;
    cursor: pointer;
`;

const StyledCardRow = styled(AccountCardFlex)`
    position:relative;
    padding-top: 30px;
    &::after {
        content: '';
        width: calc(100% + 76px);
        height:0;
        border-top: 1px solid #D2D2D2;
        position:absolute;
        top: 0;
        left: -38px;
    }
`;

const boldText = {
    color: '#09609C',
    fontWeight: 'bold'
}
const emptyFunction = () => {console.log('console')};

const InvestAccountCardComponent = (props) => {

    const { accountName, fundNumber, nav, lastNav, weekValuesMin, weekValuesMax, fundAutoInvstTxt,
         fundNavTxt, fundLastNavTxt, fundMinMaxTxt, minInvst, maxInvst, monthlyInvst,
         onSwitchClick, checked, selected, onClick, headerClick, riskVal, fundRiskTxt } = props;
    
    const getAutoInvstFund = '$'+minInvst+' / $'+maxInvst+' / $'+monthlyInvst+' monthly';
    const getFundMinMaxwkVal = '$'+weekValuesMin+' /$'+weekValuesMax;
    const getImgSrc = (risk) => {
        let src = '';
        switch(risk) {
            case "Low" :
                src=riskLow;
                break;
            case "Medium" : 
                src=riskMedium;
                break;
            case "High":
                src=riskHigh;
                break;
            default: src="";
        }
        return src;
    }
    return (
        <StyledCard onClick={!headerClick ? onClick : emptyFunction} selected={selected} cursor={!headerClick ? 'pointer' : 'default'}>
            <StyledCardBody>
                <AccountCardTopSection>
                     <StyledFundName style={boldText} >{accountName}</StyledFundName>
                        {/* <VerticalSeperator /> */}
                        {/* <Switch isChecked={ selected } /> */}
                        <div>
                            <WSwitch 
                                id={fundNumber.toString()}
                                onSwitchClick={onSwitchClick}
                                checked={checked} 
                                />
                        </div>
                </AccountCardTopSection>
             <StyledCardRow>
                <AccountCardFlex>
                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{fundAutoInvstTxt}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{getAutoInvstFund}</AccountCardValueSpan>
                    </AccountCardBottomColumn>

                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{fundNavTxt}</AccountCardHeadingSpan>
                        <AccountCardValueSpan className="iconCol">
                        {nav > 0 ? <WIcon icon={faAngleUp} size="2x" fontawesomestyle={styles.tableIconStyle}/> : 
                                  <WIcon icon={faAngleDown} size="2x" fontawesomestyle={styles.tableIconStyleRed}/>}
                        {nav}
                        </AccountCardValueSpan>
                    </AccountCardBottomColumn>

                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{fundLastNavTxt}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>${lastNav}</AccountCardValueSpan>
                    </AccountCardBottomColumn>

                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{fundMinMaxTxt}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{getFundMinMaxwkVal}</AccountCardValueSpan>
                    </AccountCardBottomColumn>

                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{fundRiskTxt}<span className="riskValue">{riskVal}</span></AccountCardHeadingSpan>
                        <AccountCardValueSpan><WIcon src={getImgSrc(riskVal)}/></AccountCardValueSpan>
                    </AccountCardBottomColumn>
                </AccountCardFlex>
              </StyledCardRow> 
            </StyledCardBody>
        </StyledCard>
    );
};

InvestAccountCardComponent.propTypes = {
    accountName: PropTypes.string.isRequired,
    // autoInvesting: PropTypes.shape({
    //     text: PropTypes.string,
    //     value: PropTypes.string
    // }),
    // nav: PropTypes.shape({
    //     text: PropTypes.string,
    //     value: PropTypes.string
    // }).isRequired,
    // lastNav: PropTypes.shape({
    //     text: PropTypes.string,
    //     value: PropTypes.string
    // }).isRequired,
    // weekValues: PropTypes.shape({
    //     text: PropTypes.string,
    //     value: PropTypes.string
    // }),
    // // onClick: PropTypes.func,
    // selected: PropTypes.bool,
};
InvestAccountCardComponent.defaultProps = {
   // onClick: () => {},
    // selected: false,
    // weekValues: {
    //     text:'',
    //     value: ''
    // }
};

export default InvestAccountCardComponent;
