/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect  } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';

// import { clearAppState } from "../shared/Actions/LocalStateManagementActions";

const getCmp = (props, render, Component) => {
    return render ? render(props) : <Component {...props} />;
};

export const handleLogout = () => {
    localStorage.clear();
    delete axios.defaults.headers.common['x-api-key'];
    Auth.signOut()
    .then(() => {
        console.log('@debug handle logout from private route');
        // localStorage.clear();
        // delete axios.defaults.headers.common['x-api-key'];
    })
    .catch(() => {
        // console.log(err)
    });
    return <Redirect to={{
                    pathname: '/msr',
                }}
            />
}

const getRenderFn = (isAuthenticatedUser, render, Component) => props => {
    return isAuthenticatedUser ? (
        getCmp(props, render, Component)
    ) : (
        handleLogout()      
    );
};


const PrivateRoute = ({ component: Component, render, props, ...rest }) => {
    localStorage.setItem('preLoginFlag', false);
    return (
        <Route {...rest} render={getRenderFn(true, render, Component)} />
    );
};

export default PrivateRoute;
