import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import constants from 'modules/AccountOptions/constant';
import {WSpinner, WRadio, AccountSelectedInfoCard } from 'common';
import { cloneDeep } from 'lodash';
import {
    DIVIDENDS_EDIT_PAGE,
    ACCOUNT_SERVICE_TYPE_DIVIDENDS,
    ACCOUNT_FLOW_TYPE_DIVIDENDS_ALL
} from '../../../../utils/AdobeAnalytics/Constants';
import getDigitalDataFormAband from '../../../../utils/AdobeAnalytics/formAband';
import DividendSwitchComponent from '../DividendSwitchComponent';

const { reinvest: REINVEST, income: INCOME, capitalGains:CAPITALGAINS } = constants.dividends.options;

const radioOptions = [
    {
        key: REINVEST,
        id: REINVEST,
        value: constants.dividends.reinvestText
    },
    {
        key: INCOME,
        id: INCOME,
        value: constants.dividends.incomeText
    },
    {
        key: CAPITALGAINS,
        id: CAPITALGAINS,
        value: constants.dividends.reinvestCapitalGainsText
    }
];

const items = [
    {
        id: '',
        key: REINVEST,
        value: constants.dividends.reinvestText
    },
    {
        id: '',
        key: INCOME,
        value: constants.dividends.incomeText
    },
   
];

const items2 = [
    {
        id: '',
        key: REINVEST,
        value: constants.dividends.reinvestCapitalGainsText
    },
    {
        id: '',
        key: INCOME,
        value: constants.dividends.incomeText
    },
   
];

const DividendCard = styled.div`
    margin-top: 1.875rem;
    margin-bottom: 3.125rem;
`;

const ApplyForAllContaner = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 2.5rem;
    align-items: center;
    border: 1px solid rgba(210, 210, 210, 1);
    background-color: #fff;
    position: relative;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #8BC105;
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
    }
`;

const AccountBodySection = styled.div`
    margin-bottom: 3.125rem;
`;
const StyledCardTwo = styled(Card)`
    margin: 1.875rem 0;
    border: 1px solid rgba(210, 210, 210, 1);
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #D2D2D2;
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
    }
`;
const HorizontalSeparator = styled.div`
    border-bottom: 0.0625rem solid #D2D2D2;
    margin: 1rem 0;
`;
const DirectDividendCard = styled(Card)`
    padding: 1rem 1rem 1rem 3rem;
    margin: 1rem 0;
    box-shadow: 0px 0px 16px #00000029;
    font-weight: bold;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #8BC105;
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
    }
`;
const AccountCardTopSection = styled.div`
    text-align: left;
    font: 600 1rem/1.125rem benton-sans;
    letter-spacing: 0;
    color: #004A98;
    border-bottom: 0.0625rem solid #D2D2D2;
    opacity: 1;
    padding: 1.6875rem 0 1.625rem 1.875rem;
    height: 4.5rem;
`;
const AccountCardBottomSection = styled.div`
    text-align: left;
    padding: 1.25rem 1.875rem 1rem 1.875rem;
`;

const AccountCardMiddleSection = styled(AccountCardBottomSection)`
    border-bottom: 0.0625rem solid #D2D2D2;
`;

const ShortFundName = styled.span`
    display: inline-block;
    padding: 0.5rem;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
`;
const Category = styled.div`
    font: 600 1rem/1.375rem benton-sans;
`;
const RadioGrup = styled.div`
    display: flex;
    height: 100%;
    line-height: 0;
    width: 35%;
    input:focus+label::before {
        outline: none !important;
    }
`;
const ApplyForAllText = styled.div`
    font-weight: bold;
    width: 45%;
`;
const BlueDiv = styled.div`
    color: #004A98;
`;

const radiostyles = {
    minHeight: 0,
    marginBottom: 0
};

class DividendsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            investAnotherFundId: null,
            fundsFromApplyAll: null,
            fundsFromSubmit: null,
            updatedFunds: null,
            applyForAllOption: constants.dividends.options.reinvest,
            analitycsDigitalData:{
                accountOptions: {
                    accountservicetype:ACCOUNT_SERVICE_TYPE_DIVIDENDS,
                    accountflowtype:ACCOUNT_FLOW_TYPE_DIVIDENDS_ALL,
                    dividendsandpreferrencetype:'',
                    investmentplanamount:''
                }
            }
        }
    }

    componentDidMount() {
        const { selectedAccount: { currentFundsCard = [] } = {} } = this.props;
        const currentFundsCardClone = cloneDeep(currentFundsCard);
        currentFundsCardClone.forEach(elem => {
            elem.dividend = 'reinvest';
            elem.capitalGains = 'reinvest';
        });

        this.setState(() => {
            return {
                fundsFromApplyAll: currentFundsCardClone,
                fundsFromSubmit: cloneDeep(currentFundsCard),
                updatedFunds: []
            }
        });
        sessionStorage.setItem(DIVIDENDS_EDIT_PAGE.STEP_KEY, DIVIDENDS_EDIT_PAGE.STEP_NAME);
    }

    componentWillUnmount(){
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    onOptionChange = (fundId, optionSelected, ind, isDividend) => () => {
        const { callBack, getFundsfromSubmit, updateAnalyticsData } = this.props;
        const { fundsFromSubmit, updatedFunds } = this.state;
        const optionType = isDividend ? 'dividend' : 'capitalGains';

        if (optionSelected !== 'investAnotherFund') {
            const updatedOption = fundsFromSubmit[ind];

            fundsFromSubmit[ind][optionType] = optionSelected;

            const item = updatedFunds.find(fund => fund.fundAccountNumber === updatedOption.fundAccountNumber);
            if (item) {
                item[optionType] = optionSelected;
            } else {
                updatedFunds.push(updatedOption);
            }
        }
        updateAnalyticsData(fundId, optionSelected,optionType);

        this.setState({ investAnotherFundId: fundId, fundsFromSubmit: fundsFromSubmit }, () => getFundsfromSubmit(updatedFunds));

        if (optionSelected !== 'investAnotherFund') {
            let isChanaged = true;
            callBack(!isChanaged);
        }
    }

    optionChangeApplyAll = (checkedOption) => () => {
        const { selectedAccount: { currentFundsCard = [] } = {} } = this.props;
        const currentFundsCardClone = cloneDeep(currentFundsCard);
        currentFundsCardClone.map(elem => {
            elem.dividend = checkedOption;
            elem.capitalGains = checkedOption;
        });

        this.setState((prevState) => {
            return {
                fundsFromApplyAll: currentFundsCardClone,
                applyForAllOption: checkedOption,
                analitycsDigitalData: {
                    accountOptions:{
                        ...prevState.analitycsDigitalData.accountOptions,
                        dividendsandpreferrencetype:checkedOption === REINVEST
                            ? radioOptions[0].value
                            : radioOptions[1].value
                    }
                }
            }
        });
    }

    render() {
        const { investAnotherFundId, applyForAllOption, fundsFromSubmit } = this.state;
        const { selectedAccount, isLoadingPostUpdateDividends } = this.props;
        const { address, accName = '', accNumber = '', accType = '', balance = '', currentFundsCard = [] } = selectedAccount || {};
        const fundsToRender = investAnotherFundId ? fundsFromSubmit : currentFundsCard;


        const accDetails = {
            accountName: accName,
            accountNumber: accNumber,
            accountType: accType,
            currentAccountBalance: balance,
            address
        }

        return (
            <div>
                {isLoadingPostUpdateDividends && <WSpinner loading={isLoadingPostUpdateDividends} />}
                <DividendCard>
                    <AccountSelectedInfoCard accDetails={accDetails}/>
                    <ApplyForAllContaner>
                        <ApplyForAllText>
                            <div>{constants.dividends.applyForAllText}</div>
                        </ApplyForAllText>
                        <RadioGrup role='radiogroup'>
                            {radioOptions.map(item => (
                                <WRadio
                                    radioFor={item.key}
                                    key={item.key}
                                    id={item.id}
                                    radioFor='apply-for-all'
                                    value={item.value}
                                    onClick={this.optionChangeApplyAll(item.key)}
                                    radiostyles={radiostyles}
                                    selected={applyForAllOption === item.key}
                                    className='analytics-form-fields'
                                />
                            ))}
                        </RadioGrup>

                        {/* commented for MSR Flow */}
                        {/* <div>
                            <WButton
                                id="apply-fol-all-button"
                                buttontext='Apply'
                                size="sm"
                                nocorner={true}
                                waitforapiresponse={true}
                                dataAnalyticId={DIVIDENDS_EDIT_PAGE_APPLY_BUTTON}
                                onClick={() => applyAllClick(fundsFromApplyAll)}
                                analyzecustom={analitycsDigitalData}
                            />
                        </div> */}
                    </ApplyForAllContaner>

                    <AccountBodySection>
                        <div>
                            {fundsToRender.map((item, index) => (
                                <StyledCardTwo key={`${item.fundName}-${index}`}>
                                    <AccountCardTopSection>
                                        <ShortFundName>{item.shortFundName}</ShortFundName>
                                        <span>{item.fundName}</span>
                                    </AccountCardTopSection>
                                    <AccountCardMiddleSection>
                                        <Category id={item.fundAccountNumber + 'dividend'}>{constants.dividends.dividendText}</Category>
                                        <DividendSwitchComponent
                                            index={index}
                                            onOptionChange={this.onOptionChange}
                                            optionSelected={item.dividend}
                                            radio1Id={item.fundAccountNumber + 'reinvest'}
                                            radio2Id={item.fundAccountNumber + 'income'}
                                            radio3Id={item.fundAccountNumber + 'investAnotherFund'}
                                            isDividend={true}
                                            nameValue={item.fundAccountNumber + 'dividend'}
                                            investAnotherFundId={investAnotherFundId}
                                            itemData={items}
                                        />
                                        {investAnotherFundId === `${item.fundAccountNumber}investAnotherFund` ? <DirectDividendCard>
                                            <BlueDiv>{item.fundName}</BlueDiv>
                                            <HorizontalSeparator></HorizontalSeparator>
                                            <div>{constants.dividends.anotherFundText}</div>
                                            <BlueDiv>{constants.dividends.contactNumber}</BlueDiv>
                                        </DirectDividendCard> : null}
                                    </AccountCardMiddleSection>
                                    <AccountCardBottomSection>
                                        <Category id={item.fundAccountNumber}>{constants.dividends.capitalGains}</Category>
                                        <DividendSwitchComponent
                                            index={index}
                                            onOptionChange={this.onOptionChange}
                                            optionSelected={item.capitalGains}
                                            radio1Id={item.fundAccountNumber + 'reinvestCapitalGains'}
                                            radio2Id={item.fundAccountNumber + 'incomeCapitalGains'}
                                            radio3Id={item.fundAccountNumber + 'investAnotherFundCapitalGains'}
                                            nameValue={item.fundAccountNumber}
                                            investAnotherFundId={investAnotherFundId}
                                            itemData={items2}

                                        />
                                        {investAnotherFundId === `${item.fundAccountNumber}investAnotherFundCapitalGains` ? <DirectDividendCard>
                                            <BlueDiv>{item.fundName}</BlueDiv>
                                            <HorizontalSeparator></HorizontalSeparator>
                                            <div>{constants.dividends.anotherFundText}</div>
                                            <BlueDiv>{constants.dividends.contactNumber}</BlueDiv>
                                        </DirectDividendCard> : null}
                                    </AccountCardBottomSection>
                                </StyledCardTwo>
                            ))}
                        </div>
                    </AccountBodySection>
                </DividendCard>
            </div>
        )
    }
}

DividendsMain.propTypes = {
    selectedAccount: PropTypes.instanceOf(Object),
    callBack: PropTypes.func,
    selectedAccIndexes: PropTypes.object,
    isLoadingPostUpdateDividends: PropTypes.bool,
    getFundsfromSubmit: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
    updateAnalyticsData: PropTypes.func
};

DividendsMain.defaultProps = {
    selectedAccount: {},
    callBack: () => { },
    selectedAccIndexes: {},
    getFundsfromSubmit: () => { },
    isLoadingPostUpdateDividends: false,
    analyzeFormAdandonment: () => {},
    updateAnalyticsData: () => {}
};

export default DividendsMain;