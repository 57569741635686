    /**
 * Esign Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to ive Electronic Consent and download PDF
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [14/02/2020]
 * @lastModifiedBy Lalitha Karibandi
 * @lastModifiedReason added error messages
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import adobePdfIcon from '../../../assets/Group 8288.svg';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SummaryCard from '../Verify/SummaryCard';

import {
    WStepper,
    CommonButtons,
    WInputCheckBox,
    WSpinner,
    WSaveModal,
    WMessageBar,
    ConformationModal,
    WIcon,
    WBreadCrumb,
    //  WSubmitModal,
} from '../../../common';
import './Esign.css';
import { getFormattedDate } from '../../../utils/utils';
import Consts, {
    PERSONAL_CHECK_VALUE,
    WIRETRANSFER_VALUE,
    BANK_VALUE,
    NEW_APPLICATION,
    NO_DOCUMENT_AVAILABLE,
    INDIVIDUALS,
    NON_INDIVIDUALS,
    TRADIONAL_IRA_TEXT,
    ROTH_IRA_TEXT,
    SEP_IRA_TEXT,
} from './Consts';
import AccGlobalConstants from '../AccountManagementConstants';
import EConsentData from '../../../common/EConsent/EConsentData';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];


const adobeReaderIcon = 'Adobe Reader Icon';

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

const Icon = styled.img`
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
`;

export default class EsignComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancel: false,
            back: false,
            loading: false,
            consent: false,
            // onSubmitFlag: false,
            backupWithholding: [],
            //  docs_to_sign: [],
            //  docs_to_view: [],
            selectedRadio: 'Y',
            selectedConsent: 'N',
            masterDataLoadedForEsign: false,
            errorFromEsignMasterData: false,
            showConfirmCancelModal: false,
            selectedTaxWHOption0: 'N',
            selectedTaxWHOption1: 'N',
            taxWHConsentError: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { masterLookupStateData, accOpeningData } = nextProps;
        return {
            ...prevState,
            loading: masterLookupStateData.isLoading || accOpeningData.isLoading,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
        };
    }

    componentDidMount() {
        const esignPageState = {};
        const { getCompositeLookUpData, localStateData } = this.props;
        if (localStateData.userConsent) {
            if (localStateData.userConsent.backupCertFlag && localStateData.userConsent.certifiedYN)
                esignPageState.selectedRadio = localStateData.userConsent.backupCertFlag;
            esignPageState.selectedConsent = localStateData.userConsent.certifiedYN;
            esignPageState.disableSubmit = false;
        }
        const { accountType = '' } = localStateData || {};
        esignPageState.accountType = accountType;

        // if (localStateData.accountType !== undefined) {
        //     this.setState({ accountType: localStateData.accountType });
        // }
        // if (localStateData.backupWithholding) {
        //     esignPageState.backupWithholding = localStateData.backupWithholding
        // }
        // if (localStateData.docsToView) {
        //     esignPageState.docsToView = localStateData.docsToView
        // }
        // if (localStateData.docsToSign) {
        //     esignPageState.docsToSign = localStateData.docsToSign
        // }
        if (localStateData.masterDataLoadedForEsign) {
            esignPageState.masterDataLoadedForEsign = localStateData.masterDataLoadedForEsign;
        }
        // if (localStateData.masterDataLoadedForEsign) {
        //     esignPageState.loading = false;
        //     esignPageState.backupWithholding = localStateData.backupWithholding;
        //     esignPageState.docsToView = localStateData.docsToView;
        // } else {
        // const payload = [];
        const compositePayloadData = ['backup_withholding', 'docs_to_view'];
        // for (let i = 0; i < compositePayloadData.length; i += 1) {
        //     const tempkey = compositePayloadData[parseInt(i, 10)];
        //     if (masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
        //         payload.push(tempkey);
        //     }
        // }
        getCompositeLookUpData(compositePayloadData);
        // }
        this.setState({ ...esignPageState, displayTimeInfo: this.displayTime() });
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    updateState = prevProps => {
        const updateEsignObject = {};
        const { masterLookupStateData, accOpeningData } = this.props;
        if (
            masterLookupStateData.backup_withholding &&
            masterLookupStateData.backup_withholding.value &&
            masterLookupStateData.backup_withholding.value !==
                (prevProps.masterLookupStateData.backup_withholding &&
                    prevProps.masterLookupStateData.backup_withholding.value)
        ) {
            const backupWithholdingData = masterLookupStateData.backup_withholding.value;
            backupWithholdingData[1] = this.formRadioList(backupWithholdingData[1]);
            const backupWithholding = backupWithholdingData.map(obj => ({ ...obj, type: 'radio' }));
            backupWithholding.push(Consts.paratext);
            updateEsignObject.backupWithholding = backupWithholding;
        }

        if (masterLookupStateData && masterLookupStateData.docs_to_view && masterLookupStateData.docs_to_view.value) {
            const docstoviewdata = masterLookupStateData.docs_to_view.value;
            const docstoview = docstoviewdata.map(obj => ({ ...obj, type: 'link' }));
            updateEsignObject.docsToView = docstoview;
        }

        updateEsignObject.masterDataLoadedForEsign = true;
        // Loader
        if (masterLookupStateData.isLoading && accOpeningData.isLoading) {
            updateEsignObject.loading = true;
        } else if (!masterLookupStateData.isLoading && !accOpeningData.isLoading) {
            updateEsignObject.loading = false;
        }

        // Master Data Error
        // this.handleAPIError(masterLookupStateData, accOpeningData);

        // handleSubmitSave API
        // this.handleSaveandSubmitAPI(accOpeningData, prevProps);

        // Error
        // if (accOpeningData.isError && accOpeningData.response !== prevProps.accOpeningData.response) {
        //     this.handleAPIError(masterLookupStateData, accOpeningData);
        // }

        // this.setState({ ...updateEsignObject });
    };

    // Refactored for sonar issue

    handleSaveandSubmitAPI = (accOpeningData, prevProps) => {
        //   Save Function Values Start
        if (
            accOpeningData.ESIGN_SAVE_OPENING_ACCT &&
            accOpeningData.ESIGN_SAVE_OPENING_ACCT !== prevProps.accOpeningData.ESIGN_SAVE_OPENING_ACCT
        ) {
            // let msg = '';
            if (accOpeningData.ESIGN_SAVE_OPENING_ACCT.status) {
                // msg = accOpeningData.ESIGN_SAVE_OPENING_ACCT.status;
                this.setState({ showSaveModal: true, loading: false });
            } else {
                const msg = accOpeningData.ESIGN_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        //   Submit Function Values Start
        if (
            accOpeningData.SUBMIT_OPENING_ACCT &&
            accOpeningData.SUBMIT_OPENING_ACCT !== prevProps.accOpeningData.SUBMIT_OPENING_ACCT
        ) {
            let msg = '';
            if (accOpeningData.SUBMIT_OPENING_ACCT.accountId) {
                msg = accOpeningData.SUBMIT_OPENING_ACCT.accountId;
                this.setState({ showSubmitModal: true, loading: false, newAccountId: msg }, () => {
                    this.saveToLocal();
                });
            } else {
                msg = accOpeningData.SUBMIT_OPENING_ACCT.errorMessage || accOpeningData.SUBMIT_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Submit Function Values end
    };

    // Refactored for sonar issue
    handleAPIError = (masterLookupStateData, accOpeningData) => {
        // Master Data Error
        if (masterLookupStateData.isError) {
            this.setState({ errorFromEsignMasterData: true });
        } else if (!masterLookupStateData.isError) {
            this.setState({ errorFromEsignMasterData: false });
        }
        if (accOpeningData.response) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } else if (accOpeningData.type === 'SUBMIT_OPENING_ACCT_FAILURE') {
            const msg = 'Account Not Submitted';
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    formRadioList = data => {
        if (data && !data.descriptionList) {
            const splitted = data.value && data.value.split(/[:.]/);
            splitted[1] = splitted[1] && splitted[1].replace(/[^a-zA-Z0-9 ]/g, '').trim();
            splitted[2] = splitted[2] && splitted[2].replace(/[^a-zA-Z0-9 ]/g, '').trim();
            return {
                value: `${splitted[0]}:`,
                key: data.key,
                descriptionList: [`${splitted[1]}.`, `${splitted[2]}.`],
            };
        }
        return data;
    };

    handleEvent = (event, value) => {
        switch (event) {
            case 'radioClick': {
                this.setState({ selectedRadio: value });
                break;
            }
            case 'pdf': {
                window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
                break;
            }
            case 'docsToSign': {
                window.open('https://get.adobe.com/reader/');
                break;
            }
            default:
                break;
        }
    };

    handleSave = () => {
        this.setState({ showSaveModal: false }, () => {
            this.saveToLocal('save');
        });
    };

    handleSubmit = () => {
        const { selectedRadio, selectedConsent, selectedTaxWHOption0, selectedTaxWHOption1 } = this.state;
        if (
            selectedConsent === 'Y' &&
            (selectedRadio === 'N' ? selectedTaxWHOption0 === 'Y' || selectedTaxWHOption1 === 'Y' : selectedRadio)
        ) {
            const { submitAccountOpening } = this.props;
            const payload = this.createPayload();
            submitAccountOpening(payload);
            this.setState({ next: true });
        } else if (selectedConsent === 'N') {
            this.setState({ consent: true }, () => {
                document.getElementById('esignCheck').focus();
            });
        } else if (selectedTaxWHOption0 === 'N' && selectedTaxWHOption1 === 'N') {
            this.setState({ taxWHConsentError: true });
        }
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.saveToLocal();
        this.setState({ back: true });
    };

    handleChange = e => {
        const value = e.target.checked ? 'Y' : 'N';
        this.setState({ selectedConsent: value, consent: value !== 'Y' });
    };

    handleTaxConsentChange = index => e => {
        const { selectedTaxWHOption0, selectedTaxWHOption1 } = this.state;
        const value = e.target.checked ? 'Y' : 'N';
        if (index === 0) {
            this.setState({ selectedTaxWHOption0: value });
        }
        if (index === 1) {
            this.setState({ selectedTaxWHOption1: value });
        }
        if (e.target.checked || selectedTaxWHOption0 === 'Y' || selectedTaxWHOption1 === 'Y') {
            this.setState({ taxWHConsentError: false });
        }
    };

    saveToLocal = (action = '') => {
        const { manageLocalState, localStateData, saveApplication } = this.props;
        const {
            selectedRadio,
            selectedConsent,
            masterDataLoadedForEsign,
            backupWithholding,
            docsToSign,
            docsToView,
            newAccountId,
        } = this.state;
        const data = {
            userConsent: {
                backupCertFlag: selectedRadio,
                certifiedYN: selectedConsent,
            },
            backupWithholding,
            docsToSign,
            docsToView,
            masterDataLoadedForEsign,
            newAccountId,
        };
        const payload = {
            userConsent: {
                backupCertFlag: selectedRadio,
                certifiedYN: selectedConsent,
            },
        };
        if (action === 'save') {
            const savePayload = { ...localStateData, OpenAccPageSix: payload, ...data };
            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: localStateData.accountType,
                    payload: savePayload,
                },
            });
        } else {
            manageLocalState({ OpenAccPageSix: payload, ...data });
        }
    };

    paymentMethod = fundType => {
        let modeOfPayment = '';
        if (fundType === 'pers_check') {
            modeOfPayment = PERSONAL_CHECK_VALUE;
        } else if (fundType === 'wire_transfer') {
            modeOfPayment = WIRETRANSFER_VALUE;
        } else if (fundType === 'bank') {
            modeOfPayment = BANK_VALUE;
        }
        return modeOfPayment;
    };

    getMailingAddress = OpenAccPageTwo => {
        return {
            mailingAddressType:
                OpenAccPageTwo.personalInfo.mailingAddress.addressType === 'us'
                    ? 'U.S or U.S Territories'
                    : OpenAccPageTwo.personalInfo.mailingAddress.addressType,
            addressLine1: OpenAccPageTwo.personalInfo.mailingAddress.addressLine1,
            addressLine2: OpenAccPageTwo.personalInfo.mailingAddress.addressLine2,
            zipCode: OpenAccPageTwo.personalInfo.mailingAddress.zip,
            city: OpenAccPageTwo.personalInfo.mailingAddress.city,
            state: OpenAccPageTwo.personalInfo.mailingAddress.state,
        };
    };

    getPersonalInformation = OpenAccPageTwo => {
        const strDate = OpenAccPageTwo.personalInfo.dateOfBirth.replace(/-/g, '/');
        const dob = new Date(strDate);

        return {
            prefix: OpenAccPageTwo.personalInfo.prefix,
            firstName: OpenAccPageTwo.personalInfo.firstName,
            lastName: OpenAccPageTwo.personalInfo.lastName,
            middleInitial: OpenAccPageTwo.personalInfo.middleInitial,
            dob: getFormattedDate(dob),
            gender: OpenAccPageTwo.personalInfo.gender,
            citizenship: OpenAccPageTwo.personalInfo.citizenship === 'us' ? 'U.S.' : 'Non U.S.',
            maritalStatus: OpenAccPageTwo.personalInfo.maritalStatus,
            ssn: OpenAccPageTwo.personalInfo.ssnData.fullSSN,
            w8FormUpload: OpenAccPageTwo.personalInfo.w8FormUpload,
            // originalW8FileArray: OpenAccPageTwo.personalInfo.originalW8FileArray,
            originalW8FileArray: OpenAccPageTwo.personalInfo.fileMetaData,
        };
    };

    getPhysicalAddress = OpenAccPageTwo => {
        return {
            addressLine1: OpenAccPageTwo.personalInfo.physicalAddress.addressLine1,
            addressLine2: OpenAccPageTwo.personalInfo.physicalAddress.addressLine2,
            zipCode: OpenAccPageTwo.personalInfo.physicalAddress.zip,
            city: OpenAccPageTwo.personalInfo.physicalAddress.city,
            state: OpenAccPageTwo.personalInfo.physicalAddress.state,
        };
    };

    getPhoneInformation = OpenAccPageTwo => {
        const {
            phoneNumber: primaryPhoneNumber,
            // contactTime: primaryContactTime,
            isMobile: primaryIsMobile,
            countryCode: primaryCountryCode,
        } = OpenAccPageTwo.personalInfo.contactDetails.primaryPhone;
        const {
            phoneNumber: secondaryPhoneNumber,
            // contactTime: secondaryContactTime,
            isMobile: secondaryIsMobile,
            countryCode: secondaryCountryCode,
        } = OpenAccPageTwo.personalInfo.contactDetails.secondaryPhone;
        return {
            primary: {
                number: primaryPhoneNumber && primaryPhoneNumber.replace(/-/g, ''),
                countryCode: primaryCountryCode,
                // contactTime: primaryContactTime,
                isMobile: primaryIsMobile,
            },
            secondary: {
                number: secondaryPhoneNumber && secondaryPhoneNumber.replace(/-/g, ''),
                countryCode: secondaryCountryCode,
                // contactTime: secondaryContactTime,
                isMobile: secondaryIsMobile,
            },
        };
    };

    getEmploymentInformaiton = OpenAccPageTwo => {
        const { employerAddress } = OpenAccPageTwo.employementInfo;
        return {
            status: this.getValue('employment_status', OpenAccPageTwo.employementInfo.empStatus),
            industry: this.getValue('industry', OpenAccPageTwo.employementInfo.industry),
            primarySource:
                OpenAccPageTwo.employementInfo.primarySourceofIncome !== ''
                    ? this.getValue('prim_src_income', OpenAccPageTwo.employementInfo.primarySourceofIncome)
                    : '',
            occupation: OpenAccPageTwo.employementInfo.occupation,
            employerName: OpenAccPageTwo.employementInfo.employersName,
            address: {
                addressLine1: employerAddress.addressLine1 ? employerAddress.addressLine1 : '',
                addressLine2: employerAddress.addressLine2 ? employerAddress.addressLine2 : '',
                city: employerAddress.city ? employerAddress.city : '',
                state: employerAddress.state ? employerAddress.state : '',
                zipCode: employerAddress.zip ? employerAddress.zip : '',
            },
        };
    };

    getTaxBracket = taxBracket => {
        let updatedTaxBracket = taxBracket.replace('%', '');
        updatedTaxBracket = Math.ceil(parseFloat(updatedTaxBracket));
        return updatedTaxBracket;
    };

    getFinancialInformation = OpenAccPageTwo => {
        const annualIncome = OpenAccPageTwo.financialInfo.annualIncome
            ? this.getValue('annual_income', OpenAccPageTwo.financialInfo.annualIncome)
            : '';
        const taxBracket = OpenAccPageTwo.financialInfo.taxBracket
            ? this.getTaxBracket(OpenAccPageTwo.financialInfo.taxBracket)
            : '';
        const netWorth = OpenAccPageTwo.financialInfo.netWorth
            ? this.getValue('net_worth', OpenAccPageTwo.financialInfo.netWorth)
            : '';
        const taxFilingStatus = OpenAccPageTwo.financialInfo.taxFilingStatus
            ? this.getValue('tax_filling_status', OpenAccPageTwo.financialInfo.taxFilingStatus)
            : '';
        return {
            annualIncome,
            taxBracket,
            netWorth,
            taxFilingStatus,
        };
    };

    getBeneficiaryPayload = beneficiaryInfo => {
        let beneficiaryPayload = [];
        if (beneficiaryInfo.length > 0) {
            beneficiaryPayload = beneficiaryInfo.map(beneficiary => {
                return {
                    beneficiaryType: beneficiary.beneficiaryType === INDIVIDUALS ? INDIVIDUALS : NON_INDIVIDUALS,
                    relation: beneficiary.relation || '',
                    distributionPer: beneficiary.distributionPer || '100.00',
                    firstName: beneficiary.firstName || '',
                    lastName: beneficiary.lastName || '',
                    ssnTin: beneficiary.ssnTin || '',
                    dateOfBirth: beneficiary.dateOfBirth || '',
                    emailAddress: beneficiary.emailAddress || '',
                };
            });
        }
        return beneficiaryPayload;
    };

    getBeneficiaryInformation = (OpenAccPageTwo, accountType) => {
        const beneficiaries = OpenAccPageTwo.beneficiaries ? OpenAccPageTwo.beneficiaries : null;
        let primaryBeneficiary = [];
        let contingentBeneficiary = [];
        if (beneficiaries && accountType !== 'UGMA/UTMA') {
            primaryBeneficiary = OpenAccPageTwo.beneficiaries.primary ? OpenAccPageTwo.beneficiaries.primary : [];
            contingentBeneficiary = OpenAccPageTwo.beneficiaries.contingent
                ? OpenAccPageTwo.beneficiaries.contingent
                : [];
        } else if (accountType === 'UGMA/UTMA') {
            const childBeneficiary = OpenAccPageTwo.childBeneficiary ? OpenAccPageTwo.childBeneficiary : null;
            return {
                primary: [
                    {
                        beneficiaryType: '',
                        relation: childBeneficiary.relation || '',
                        distributionPer: '100.00',
                        firstName: childBeneficiary.personalInfo.firstName || '',
                        lastName: childBeneficiary.personalInfo.lastName || '',
                        middleInitial: childBeneficiary.personalInfo.middleInitial || '',
                        ssnTin: childBeneficiary.personalInfo.ssnTin || '',
                        dateOfBirth: childBeneficiary.personalInfo.dateOfBirth || '',
                        emailAddress: childBeneficiary.personalInfo.emailAddress || '',
                    },
                ],
            };
        }

        // let beneficiaryPayload = [];
        // if (primaryBeneficiary.length > 0) {
        //     beneficiaryPayload = primaryBeneficiary.map(beneficiary => {
        //         return {
        //             beneficiaryType: 'individuals',
        //             relation: beneficiary.relation || '',
        //             distributionPer: '100.00',
        //             firstName: beneficiary.firstName || '',
        //             lastName: beneficiary.lastName || '',
        //             ssnTin: beneficiary.ssnTin || '',
        //             dateOfBirth: beneficiary.dateOfBirth || '',
        //             emailAddress: beneficiary.emailAddress || '',
        //         };
        //     });
        // }
        return {
            primary: this.getBeneficiaryPayload(primaryBeneficiary),
            contingent: this.getBeneficiaryPayload(contingentBeneficiary),
        };
    };

    getRank = (branchKey, rank) => {
        return this.getValue(`mil_rank_${branchKey}`, rank);
    };

    getMilitaryInformation = OpenAccPageTwo => {
        const hasServed = OpenAccPageTwo.militaryInfo.currServingUSM === 'Y';

        return {
            hasServed,
            militaryStatus: hasServed ? this.getValue('mil_status', OpenAccPageTwo.militaryInfo.milityStatus) : '',
            otherMilitaryStatus: OpenAccPageTwo.militaryInfo.militaryOthers || '',
            branch: hasServed ? this.getValue('mil_serv_branch', OpenAccPageTwo.militaryInfo.branchOfService) : '',
            otherBranch: OpenAccPageTwo.militaryInfo.branchOfServiceOthers || '',
            rank:
                hasServed && OpenAccPageTwo.militaryInfo.branchOfService !== 'other'
                    ? this.getRank(OpenAccPageTwo.militaryInfo.branchOfService, OpenAccPageTwo.militaryInfo.rank)
                    : OpenAccPageTwo.militaryInfo.rank || '',
            commissionSource: hasServed ? OpenAccPageTwo.militaryInfo.commissionSource : '',
            startDate: hasServed ? OpenAccPageTwo.militaryInfo.startDate : '',
            endDate: hasServed ? OpenAccPageTwo.militaryInfo.endDate : '',
        };
    };

    getFundingSource = OpenAccPageFour => {
        const { paymentMethod } = OpenAccPageFour.investmentInfo.selectedFundSource;
        const isOnline = paymentMethod === 'online';

        const { selectedFundSource } = OpenAccPageFour.investmentInfo;
        const { fundType } = selectedFundSource.fundSource;
        return {
            method: this.paymentMethod(fundType),
            bankAccount: {
                accountType: isOnline ? selectedFundSource.fundSource.accountType : '',
                institutionName: isOnline ? selectedFundSource.fundSource.institutionName : '',
                accountOwner: 'owners',
                transitRoutingNumber: isOnline ? selectedFundSource.fundSource.transitRoutingNumber : '',
                // transitRoutingNumber: '122105278',
                bankAccountNumber: isOnline ? selectedFundSource.fundSource.bankAccountNumber : '',
            },
        };
    };

    getValue = (lookupString, lookupValue) => {
        if (lookupValue && lookupValue.toString().trim() !== '') {
            const { masterLookupStateData } = this.props;
            const lookupList = masterLookupStateData[lookupString.toString()].value;
            const lookupData = lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
            if (lookupData) {
                return lookupData.value;
            }
        }
        return '';
    };

    getJointOwnerPersonalInfo = jointOwner => {
        return {
            prefix: this.getValue('prefix', jointOwner.prefix),
            firstName: jointOwner.firstName,
            middleInitial: jointOwner.middleInitial,
            lastName: jointOwner.lastName,
            suffix: this.getValue('suffix', jointOwner.suffix),
            dob: jointOwner.dateOfBirth,
            gender: this.getValue('gender', jointOwner.gender),
            maritalStatus: this.getValue('marital_status', jointOwner.maritalStatus),
            citizenship: this.getValue('citizenship', jointOwner.citizenship),
            ssn: jointOwner.fullSSN,
        };
    };

    getJointOwnerContactInfo = (jointMailAddressData, jointPhysicalAddressData, sameAddress) => {
        const isPhysicalAddressSameAsMailingAddress = sameAddress === 'Yes';
        return {
            isPhysicalAddressSame: isPhysicalAddressSameAsMailingAddress,
            physicalAddress: {
                addressLine1: isPhysicalAddressSameAsMailingAddress
                    ? jointMailAddressData.lineOne
                    : jointPhysicalAddressData.lineOne,
                addressLine2: isPhysicalAddressSameAsMailingAddress
                    ? jointMailAddressData.lineTwo
                    : jointPhysicalAddressData.lineTwo,
                zipCode: isPhysicalAddressSameAsMailingAddress
                    ? jointMailAddressData.zipCode
                    : jointPhysicalAddressData.zipCode,
                city: isPhysicalAddressSameAsMailingAddress ? jointMailAddressData.city : jointPhysicalAddressData.city,
                state: isPhysicalAddressSameAsMailingAddress
                    ? jointMailAddressData.state
                    : jointPhysicalAddressData.state,
            },
            mailingAddress: {
                mailingAddressType: this.getValue('mailing_addr_type', jointMailAddressData.mailTypeRadio),
                addressLine1: jointMailAddressData.lineOne,
                addressLine2: jointMailAddressData.lineTwo,
                zipCode: jointMailAddressData.zipCode,
                city: jointMailAddressData.city,
                state: jointMailAddressData.state,
            },
        };
    };

    getJointOwnersInformation = OpenAccPageTwo => {
        let jointPerInfoCards = [];
        const { jointPerInfo } = OpenAccPageTwo;

        if (jointPerInfo && jointPerInfo.jointOwners && jointPerInfo.jointOwners.length > 0) {
            // const { jointOwners } = jointPerInfo;
            jointPerInfoCards = jointPerInfo.jointOwners.map(jointOwner => {
                const {
                    jointMailAddressData,
                    jointPhysicalAddressData,
                    sameAddress,
                    jointPhoneDetailsData,
                    jointEmailAddressData,
                } = jointOwner;
                const { primaryPhoneDetails, secondaryPhoneDetails } = jointPhoneDetailsData;
                const { primaryEmailDetails, secondaryEmailDetails } = jointEmailAddressData;
                return {
                    relation: this.getValue('relationship', jointOwner.relationship),
                    personalInformation: this.getJointOwnerPersonalInfo(jointOwner),
                    contactInformation: this.getJointOwnerContactInfo(
                        jointMailAddressData,
                        jointPhysicalAddressData,
                        sameAddress,
                    ),
                    phoneInformation: {
                        primary: {
                            number: primaryPhoneDetails.phoneNumber,
                            countryCode: primaryPhoneDetails.countryCode,
                            isMobile: primaryPhoneDetails.isMobile,
                        },
                        secondary: {
                            number: secondaryPhoneDetails.phoneNumber,
                            countryCode: secondaryPhoneDetails.countryCode,
                            isMobile: secondaryPhoneDetails.isMobile,
                        },
                    },
                    emailAddresses: {
                        primaryEmailAddress: primaryEmailDetails.email,
                        secondaryEmailAddress: secondaryEmailDetails.email,
                    },
                };
            });
        }
        return jointPerInfoCards;
    };

    pad2 = n => {
        return n < 10 ? `0${n}` : n;
    };

    getTimeSubmittedAt = () => {
        const dateNow = new Date();
        return `${this.pad2(dateNow.getMonth() + 1)}${this.pad2(dateNow.getDate())}${this.pad2(
            dateNow.getHours(),
        )}${this.pad2(dateNow.getMinutes())}`;
    };

    getEconsentDocuments = () => {
        const fundsList = this.getEconsentFundDetails();
        return fundsList.map(item => item.documents);
    };

    createPayload = () => {
        const { localStateData, tradingWindowData, profileInformationData, purchaseReducerData } = this.props;
        const { selectedRadio, selectedConsent } = this.state;
        const { OpenAccPageOne = {}, OpenAccPageTwo = {}, OpenAccPageFour = {}, OpenAccPageFive = {} } = localStateData;

        const isPhysicalSameAsMailingAddress = OpenAccPageTwo.personalInfo.sameAddress === 'Y';

        const { selectedFundsList } = OpenAccPageFour.investmentInfo;
        const { primaryEmail, secondaryEmail } = OpenAccPageTwo.personalInfo.emailAddress;

        const profileInfoData = profileInformationData && profileInformationData.profileInformation;
        const prevPrimEmail = profileInfoData.emailInformation && profileInfoData.emailInformation.primaryEmail;
        const prevSecEmail = profileInfoData.emailInformation && profileInfoData.emailInformation.secondaryEmail;
        const isPrimaryEmailChanged = prevPrimEmail !== (primaryEmail && primaryEmail.email);
        const isSecondaryEmailChanged = prevSecEmail !== (secondaryEmail && secondaryEmail.email);
        const mailingAddress = this.getMailingAddress(OpenAccPageTwo);
        const notifiedIRS = selectedRadio !== 'Y';
        const documentAcknowledge = selectedConsent === 'Y';
        const isJoint = OpenAccPageOne.accountType.toLowerCase().indexOf('joint') > -1;

        let iraContributionYearInfo = '';
        let overrideIRAWarningInfo = false;
        if (
            OpenAccPageOne.accountType.trim() === TRADIONAL_IRA_TEXT ||
            OpenAccPageOne.accountType.trim() === ROTH_IRA_TEXT ||
            OpenAccPageOne.accountType.trim() === SEP_IRA_TEXT
        ) {
            const { iraContributionYear } = OpenAccPageFour.investmentInfo;
            if (iraContributionYear === '0') {
                iraContributionYearInfo = 'currYear';
            } else if (iraContributionYear === '1') {
                iraContributionYearInfo = 'prevYear';
            }
            overrideIRAWarningInfo = purchaseReducerData && purchaseReducerData.overrideIRAWarning;
        }
        return {
            isMember: !!OpenAccPageTwo.memberId,
            companyNumber: OpenAccPageOne.companyNumber,
            accountType: this.getAccountType(OpenAccPageOne.accountType),
            accountTypeCode: this.getAccountTypeCode(OpenAccPageOne.accountType),
            socialCode: this.getSocialCode(OpenAccPageOne.accountType),
            accountNickname: OpenAccPageTwo.accountNickName,
            notifications: {
                primaryEmailAddress: {
                    isChange: isPrimaryEmailChanged,
                    oldEmailAddress: prevPrimEmail,
                },
                secondaryEmailAddress: {
                    isChange: isSecondaryEmailChanged,
                    oldEmailAddress: prevSecEmail,
                },
            },
            source: NEW_APPLICATION, // TO DO :: marstone check to be added
            isSaved: 'false',
            personal: {
                personalInformation: this.getPersonalInformation(OpenAccPageTwo),
                contactInformation: {
                    isPhysicalAddressSame: isPhysicalSameAsMailingAddress,
                    mailingAddress,
                    physicalAddress: isPhysicalSameAsMailingAddress
                        ? mailingAddress
                        : this.getPhysicalAddress(OpenAccPageTwo),
                },
                phoneInformation: this.getPhoneInformation(OpenAccPageTwo),
                emailAddresses: {
                    primaryEmailAddress: primaryEmail && primaryEmail.email,
                    secondaryEmailAddress: secondaryEmail && secondaryEmail.email,
                },
                employmentInformation: this.getEmploymentInformaiton(OpenAccPageTwo),
                financialInformation: this.getFinancialInformation(OpenAccPageTwo),
                militaryInformation: this.getMilitaryInformation(OpenAccPageTwo),
            },
            investmentInformation: {
                fundingSource: this.getFundingSource(OpenAccPageFour),
                fundsList: selectedFundsList,
                effectiveDate: tradingWindowData.effectiveDate,
                iraContributionYear: iraContributionYearInfo,
                overrideIRAWarning: overrideIRAWarningInfo,
            },
            jointOwners: isJoint ? this.getJointOwnersInformation(OpenAccPageTwo) : [],
            beneficiaries: this.getBeneficiaryInformation(
                OpenAccPageTwo,
                this.getAccountType(OpenAccPageOne.accountType),
            ),
            accountPreferences: {
                reinvestEarnings: OpenAccPageFive.accountPreferences.dividendCapitalGain,
                documentDeliveryFormat: OpenAccPageFive.accountPreferences.documentDeliveryFormat,
            },
            userConsent: {
                notifiedIRS,
                documentAcknowledge,
            },
            timeSubmittedAt: this.getTimeSubmittedAt(),
            documents: this.getEconsentDocuments(),
        };
    };

    getAccountType = accountType => {
        switch (accountType) {
            case '529 Education Savings Plan':
                return '529 Education  Savings Plan';
            case 'Joint Tenants With Right of Survivorship':
                return 'Joint Tenants With Rights of Survivorship';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return 'UGMA/UTMA';
            case 'Joint Tenants in Entirety':
                return 'Joint Tenants by Entirety';
            default:
                return accountType;
        }
    };

    getAccountTypeCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '100';
        }
    };

    displayTime = () => {
        const today = new Date();
        const date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        let hours = today.getHours();
        const minutes = today.getMinutes();
        const AmOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const time = `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
            today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`
        } ${AmOrPm}`;
        return `${date}  ${time}`;
    };

    renderCard = ({ cardHeading, cardId, linkText, preLinkText, postLinkText, mainHeading, showBorder }, index) => {
        const { selectedRadio, selectedTaxWHOption0, selectedTaxWHOption1, taxWHConsentError } = this.state;
        // const value = this.state;
        const { masterLookupStateData } = this.props;
        // const { backup_withholding: backupWithholdingMasterData, docs_to_view: docsToViewMasterData } =
        //     masterLookupStateData || {};
        const { backup_withholding: backupWithholdingMasterData } = masterLookupStateData || {};
        const { value: backupWithholdingMasterDataValue } = backupWithholdingMasterData || {};
        // const { value: docsToViewMasterDataValue } = docsToViewMasterData || {};
        const backupList = backupWithholdingMasterDataValue || [];
        // const docList = docsToViewMasterDataValue || [];
        let finalFieldList = [];
        if (backupList.length > 1 && cardId === 'backupWithholding') {
            backupList[1] = this.formRadioList(backupList[1]);
            const backupWithholdingFinal = backupList.map(obj => ({ ...obj, type: 'radio' }));
            backupWithholdingFinal.push(Consts.paratext);
            finalFieldList = backupWithholdingFinal;
        }
        // else if (docList.length > 1) {
        //     finalFieldList = docList.map(obj => ({ ...obj, type: 'link' }));
        // }

        const { assignObj } = Consts;
        return (
            <SummaryCard
                cardHeading={cardHeading}
                linkText={linkText}
                preLinkText={preLinkText}
                postLinkText={postLinkText}
                cardId={cardId}
                fields={finalFieldList}
                // fields={value[cardId.toString()]}
                values={assignObj({ selected: selectedRadio })}
                index={index.toString()}
                handleEvent={this.handleEvent}
                showBorder={showBorder}
                mainHeading={mainHeading}
                page="esign"
                originPage={assignObj(`esign${cardId}`)}
                handleTaxConsentChange={this.handleTaxConsentChange}
                selectedTaxWHOption0={selectedTaxWHOption0}
                selectedTaxWHOption1={selectedTaxWHOption1}
                invokedFrom="esign"
                taxWHConsentError={taxWHConsentError}
                selectedRadio={selectedRadio}
            />
        );
    };

    getEconsentFundDetails = () => {
        const { localStateData } = this.props;
        const { displayTimeInfo } = this.state;
        const { selectedFundsList } = localStateData.OpenAccPageFour && localStateData.OpenAccPageFour.investmentInfo;
        const econsentFundsList = [...selectedFundsList];
        let econsentDocs = [];
        selectedFundsList.map((item, index) => {
            econsentDocs = EConsentData.filter(fund => fund.fundCode === item.fundNumber);
            let documents = econsentDocs[0];
            if (!documents) {
                documents = {};
            }
            econsentFundsList[parseInt(index, 10)].documents = documents;
            econsentFundsList[parseInt(index, 10)].documents.eSignDate = displayTimeInfo;
            econsentFundsList[parseInt(index, 10)].documents.docType = 'pdf';
            econsentFundsList[parseInt(index, 10)].documents.fundName = item.fundName;
            return {};
        });
        return econsentFundsList;
    };

    renderPage = (pageHeading, cards) => {
        const {
            showSaveModal,
            consent,
            selectedConsent,
            errorMessage,
            isErrorBar,
            showAlert = false,
            displayTimeInfo,
            // disableSubmit=true
        } = this.state;
        const {
            stepperStyle,
            checkBoxWrapperStyle,
            checkBoxInputStyle,
            checkBoxStyle,
            labelStyle,
            checkLabel,
            errorText,
            assignObj,
            yes,
        } = Consts;
        const dateTimeLabel = ' MM/DD/YYYY HH:MM:SS';
        const selectedFundsListForEConsent = this.getEconsentFundDetails();
        return (
            <div className="esign-wrapper">
                {showAlert ? (
                    <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                ) : null}
                <div className="container">
                    <WBreadCrumb
                        activeCrumb={pageHeading}
                        breadCrumbItems={localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest}
                    />
                    <h1 className="esign-page-heading">{pageHeading}</h1>
                    <div className="esign-content">
                        <WStepper style={stepperStyle} currentPage={6} pages={AccGlobalConstants.accountOpeningPages} />
                    </div>
                    <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                    {/* <WSubmitModal show={showSubmitModal} modalClick={this.handleSubmitModal} msg={successMsg} /> */}

                    <div className="esign-cards">
                        {cards.map((card, index) => (
                            <div
                                key={card.cardId}
                                className={card.cardId !== 'backupWithholding' ? 'listWrapperDiv' : ''}
                            >
                                {this.renderCard(card, index)}
                            </div>
                        ))}
                    </div>
                    <div className="documents-to-review">
                        {selectedFundsListForEConsent.length > 0 &&
                            selectedFundsListForEConsent.map(fund => (
                                <Row className="document-row" key={`${fund.fundNumber}`} noGutters>
                                    <WIcon
                                        icon={faCheck}
                                        size="sm"
                                        fontawesomestyle={assignObj({
                                            height: 18,
                                            width: 15,
                                            margin: '0 10 0 0',
                                            color: '#8CBF26',
                                        })}
                                    />
                                    <div>
                                        {fund.documents.docUrl && (
                                            <>
                                                <a
                                                    className="fund-doc-desc"
                                                    href={fund.documents.docUrl}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {fund.documents.description}{' '}
                                                </a>
                                                <Icon src={adobePdfIcon} alt={adobeReaderIcon} />
                                            </>
                                        )}
                                        {!fund.documents.docUrl && (
                                            <div className="fund-doc-desc">
                                                {`${NO_DOCUMENT_AVAILABLE} for ${fund.fundName}`}
                                            </div>
                                        )}
                                    </div>
                                </Row>
                            ))}
                    </div>
                    <div className="checkwrapper">
                        <Row className="check-content">
                            <WInputCheckBox
                                id="esignCheck"
                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                checkInputStyle={checkBoxInputStyle}
                                checked={selectedConsent === yes}
                                value={selectedConsent}
                                onChange={this.handleChange}
                                checkboxstyle={checkBoxStyle}
                                labelstyle={labelStyle}
                                label={checkLabel}
                                ariadescribedby="esignCheck_dateTime esignCheck_error"
                                ariainvalid={consent}
                            />
                            <div className="date-time" id="esignCheck_dateTime">
                                <div>{displayTimeInfo}</div>
                                <div className="display-time-format">{dateTimeLabel}</div>
                            </div>
                        </Row>
                    </div>
                    {consent && (
                        <p className="error-check" aria-live="polite" aria-atomic="true" id="esignCheck_error">
                            {errorText}
                        </p>
                    )}
                    <div className="revenue">
                        <span>{this.backUpText}</span>
                    </div>
                    <Row noGutters>
                        <div className="esignHint">{Consts.hintText}</div>
                    </Row>
                </div>
                <div className="esign-buttons">
                    <CommonButtons
                        backClick={this.handleBack}
                        submitClick={this.handleSubmit}
                        cancelClick={this.handleCancelClick}
                        saveClick={this.handleSave}
                        style={assignObj({ padding: 20 })}
                        submit="true"
                        showSave
                        iscancel
                        cancelText="Cancel"
                        cancelStyle={assignObj(cancelButtonStyles)}
                        cancelOffset={assignObj({
                            span: 2,
                            offset: 6,
                        })}
                        // disabled={disableSubmit}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            loading,
            errorFromEsignMasterData,
            cancel,
            back,
            next,
            accountType,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
        } = this.state;
        const { accOpeningData } = this.props;
        if (loading) {
            return <WSpinner loading={loading} />;
        }

        const { cards, errorFromEsignMasterDataMsg, pages } = Consts;
        if (errorFromEsignMasterData) {
            throw new Error(errorFromEsignMasterDataMsg);
        }
        return (
            <div className="MainContainer">
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {/* {loading && <WSpinner loading={loading} />} */}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                {!errorFromEsignMasterData &&
                    this.renderPage(AccGlobalConstants.getPageHeading(accountType), cards, pages)}
                {cancel ? <Redirect to="/" /> : ''}
                {back ? <Redirect to="/verify" /> : ''}
                {next ? <Redirect to="/confirmAccountOpening" /> : ''}
            </div>
        );
    }
}

EsignComponent.propTypes = {
    masterLookupStateData: PropTypes.shape({
        backup_withholding: PropTypes.shape({ value: PropTypes.array }),
        docs_to_sign: PropTypes.shape({ value: PropTypes.array }),
        docs_to_view: PropTypes.shape({ value: PropTypes.array }),
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
    }),
    getCompositeLookUpData: PropTypes.func,
    saveApplication: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    submitAccountOpening: PropTypes.func,
    tradingWindowData: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    purchaseReducerData: PropTypes.instanceOf(Object),
};

EsignComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    saveApplication: () => {},
    manageLocalState: () => {},
    localStateData: {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    submitAccountOpening: () => {},
    tradingWindowData: {},
    resetShowConfirmModal: () => {},
    history: {},
    profileInformationData: {},
    purchaseReducerData: {},
};
