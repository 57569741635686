import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import editIcon from 'assets/EditPencil.svg';
import { FieldValue, PrefilledInput, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from '../constants';

const StyleInfo = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    margin-top: 1.375rem;
    margin-bottom: 1.75rem;
`;

const MilitaryInfoView = (props) => {
    const { militaryStatus, isCurrentlyServing, rank, fromDate, toDate, commissionSource, branchOfService,  onManageClick, hideManage } = props;
    const dateOfService = `${fromDate} - ${toDate}`;
    return(
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.MILITARY_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_MILITARY}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='0.625'
                    hideAction={hideManage}
                />
                {
                    !isCurrentlyServing &&  <StyleInfo>{CONST.CURRENT_MILITARY}</StyleInfo>
                }
                {isCurrentlyServing &&
                <>
                    <FieldValue
                        as="p"
                        label={CONST.MILITARY_STATUS}
                        value={
                            <PrefilledInput
                                id="vcm-cms-militaryStatus"
                                value={checkValues(militaryStatus)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='0.625'
                    />
                    <FieldValue
                        as="p"
                        label={CONST.BRANCH_SERVICE}
                        value={
                            <PrefilledInput
                                id="vcm-cms-branchOfService"
                                value={checkValues(branchOfService)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='0.625'
                    />
                    <FieldValue
                        as="p"
                        label={CONST.RANK}
                        value={
                            <PrefilledInput
                                id="vcm-cms-rank"
                                value={checkValues(rank)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='0.625'
                    />
                    <FieldValue
                        as="p"
                        label={CONST.DATE_SERVICE}
                        value={
                            <PrefilledInput
                                id="vcm-cms-date-service"
                                value={checkValues(dateOfService)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='0.625'
                    />
                    <FieldValue
                        as="p"
                        label={CONST.COMMISSION_SOURCE}
                        value={
                            <PrefilledInput
                                id="vcm-cms-commissionSource"
                                value={checkValues(commissionSource)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='1.75'
                    />
                </>
                }
            </>
        );
}

MilitaryInfoView.propTypes = {
    militaryStatus: PropTypes.string,
    isCurrentlyServing: PropTypes.bool,
    rank: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    commissionSource: PropTypes.string,
    branchOfService: PropTypes.string,
    onManageClick: PropTypes.func,
    hideManage: PropTypes.bool,
}

MilitaryInfoView.defaultProps = {
    militaryStatus: '',
    isCurrentlyServing: false,
    rank: '',
    fromDate: '',
    toDate: '',
    commissionSource: '',
    branchOfService: '',
    onManageClick: () => {},
    hideManage:false,
}


export default MilitaryInfoView;