 const PHONE_INFO = 'Phone Information';
 const PRIMARY_MOBILE = 'Primary Mobile';
 const PRIMARY_PHONE_NUMBER = 'Primary Phone Number';
 const SECONDARY_PHONE_NUMBER = 'Secondary Phone Number';
 const PRIMARY_HOME = 'Primary Home';
 const PRIMARY_WORK = 'Primary Work';
 
 const ACTION_LABEL_PHONE = 'Manage phone information';
 const ACTION_TEXT = 'Manage';
 
 const ACTION_LABEL_PHONE_ADD = 'Add phone';
 const ACTION_TEXT_PHONE_ADD = 'Add';
 const BASICINFO_URL = '/basicinformation';
 const BASIC_INFO = 'Basic Information';
 

const BREADCRUM_ITEMS = [
	{
		url: '/Profile', 
		name:'Profile',
		dataAnalyticId:'cms_basicinformation_managephoneinfo_breadcrumb_profile'
	}, 
	{
		url: BASICINFO_URL, 
		name: BASIC_INFO,
		dataAnalyticId:'cms_basicinformation_managephoneinfo_breadcrumb_basicinfo'
	}
];
const PAGE_NAME = 'Manage Phone Information';
const MOBILE = 'Mobile';
const NON_MOBILE = 'Non Mobile';
const HOME = 'Home';
const WORK = 'Work';
const PHONE_INFO_SUCCESS = "Successfully saved Phone Information";
const PHONE_INFO_DELETE_SUCCESS = "Successfully removed Phone Information";

const PAGE_NAME_PHONE_ADD = 'Add Phone Number';
const PAGE_NAME_PHONE_EDIT = 'Edit Phone Number';
const BREADCRUM_ITEMS_EDIT = [
	{ 
		url: '/Profile', 
		name: 'Profile',
		dataAnalyticId:'cms_basicinformation_editphoneinfo_breadcrumb_profile'
	}, 
	{
    	url: BASICINFO_URL,
		name: BASIC_INFO,
		dataAnalyticId:'cms_basicinformation_editphoneinfo_breadcrumb_basicinfo'
	}, 
	{ 
		url: '/phoneInfo', 
		name: PAGE_NAME,
		dataAnalyticId:'cms_basicinformation_editphoneinfo_breadcrumb_managephoneinfo'
	}
];
const BREADCRUM_ITEMS_ADD = [
	{ 
		url: '/Profile', 
		name: 'Profile',
		dataAnalyticId:'cms_basicinformation_addphoneinfo_breadcrumb_profile'
	}, 
	{
    	url: BASICINFO_URL,
		name: BASIC_INFO,
		dataAnalyticId:'cms_basicinformation_addphoneinfo_breadcrumb_basicinfo'
	}, 
	{ 
		url: '/phoneInfo', 
		name: PAGE_NAME,
		dataAnalyticId:'cms_basicinformation_addphoneinfo_breadcrumb_managephoneinfo'
	}
];
  
  const PHONE_TYPE = "Phone Type";
  const PHONE_NUMBER = "Phone Number";
  const PREFERRED_TIME_TEXT = "Call Time Preference";
  
const NONEONFILE = 'None on file';

const PHONE_NOTE_PRIMARY = "Note: You are making the changes for Primary Phone Number";
const PHONE_NOTE_SECONDARY = "Note: You are making the changes for Secondary Phone Number";

const PHONE_IS_MOBILE = "Is this is your mobile number?";

const PHONE_NUMBER_ERROR_1 = "Please enter a Phone Number";
const PHONE_NUMBER_ERROR_2 = "Invalid Phone Number";
const PHONE_COUNTRY_CODE_ERROR = "Invalid code";
const COUNTRY_HINT_TEXT = 'Country';
const PHONE_HINT_TEXT = '(000)-000-0000';
const AX_MOBILE_INFO = 'Yes, it is my mobile number';
const AX_CONTRY_PHONENUM_TXT = "Country phone number";
const AX_PHONEFORMAT_TXT = "Phone Number format";
const AX_CONTRY_PHONECODE_TXT = "Country phone number code" ;
const AX_CONTRY_CODE_TXT = "Country code";
const SAVE = "Save";
const CANCEL = "Cancel";

  export default {
    PHONE_INFO,
    PRIMARY_MOBILE,
    PRIMARY_HOME,
    PRIMARY_WORK,
    ACTION_LABEL_PHONE,
    ACTION_TEXT,
    BREADCRUM_ITEMS,
    PAGE_NAME,
    MOBILE,
    HOME,
    WORK,
    PHONE_INFO_SUCCESS,
    PHONE_INFO_DELETE_SUCCESS,
    ACTION_LABEL_PHONE_ADD,
    ACTION_TEXT_PHONE_ADD,
  PAGE_NAME_PHONE_ADD,
  PAGE_NAME_PHONE_EDIT,
  BREADCRUM_ITEMS_EDIT,
  BREADCRUM_ITEMS_ADD,
  PHONE_TYPE,
  PHONE_NUMBER,
  PREFERRED_TIME_TEXT,
  NONEONFILE,
  PRIMARY_PHONE_NUMBER,
  SECONDARY_PHONE_NUMBER,
  NON_MOBILE,
  PHONE_NOTE_PRIMARY,
  PHONE_NOTE_SECONDARY,
  PHONE_IS_MOBILE,
  PHONE_NUMBER_ERROR_1,
  PHONE_NUMBER_ERROR_2,
  PHONE_COUNTRY_CODE_ERROR,
  COUNTRY_HINT_TEXT,
  PHONE_HINT_TEXT,
  AX_MOBILE_INFO,
  AX_CONTRY_PHONENUM_TXT,
  AX_PHONEFORMAT_TXT,
  AX_CONTRY_PHONECODE_TXT,
  AX_CONTRY_CODE_TXT,
  SAVE,
  CANCEL
};