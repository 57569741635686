import styled from 'styled-components';
//import close from 'assets/Icon_Accod_Close.svg';
//import open from 'assets/Icon_Accod_Open.svg';
import open from 'assets/accordionadd.svg'
import close from 'assets/accordionclose.svg'

const AccordionIcon = styled.span`
    padding: 0;
    border: none;
    background: none;
    &::before {
        content: '';
        background-image: url(${props => (props.open ? close : open)});
        height: 1.25rem;
        width: 1.25rem;
        display: block;
        left: 0;
        top: 0;
    }
    &.expand {
        &::before {
            content: '';
            background-image: url(${props => (props.open ? close : open)});
        }
    }
`;

export default AccordionIcon;
