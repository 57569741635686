import { CommonButtons, ConfirmModal, WMessageBar, WSpinner } from 'common'
import Attachments from 'modules/MessageCenter/SecureMessage/Attachments'
import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { clearMarketNAOInfo, submitKycUploadedDocuments, submitMarketNAOInfo } from 'shared/Actions/MarketNAOActions'
import { fileUploadWithProgressBarUpdate, getUploadFileSession, removeUplodedDocument, resetFileDeletedState,resetDeletedFileData } from 'shared/Actions/MessageCenterActions'
import { removeServicesAccountHeader } from 'shared/Network/Services'
import { getToken } from 'utils'
import { MARKET_NAO_ACCOUNT_TYPES } from './consts'
import { LocalStorageHelper, SessionStorageHelper } from 'utils/sessionHelper';
import { getSessionValues } from 'shared/Helpers/Utils'

const snapErrorText ='We are experiencing technical difficulties. We are actively working to resolve the issue.';
const UploadPendingDocuments = () => {
  const File_UPLOAD_ERROR_MESSAGE = "File upload failed due to the technical error."

  const initialState = {
    showModel: false,
    documentRequiredMessage: '',
    documentRequired: [],
    noOfTimesBulkApiCalled: 0,
  }

  const [state, setState] = useState(initialState)
  const history = useHistory()
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.messageCenterData?.isLoading);
  const [attachedFiles, setAttachment] = useState([]);
  const [rejectedFiles, setRejectedfiles] = useState([]);
  const [storePercentageOfUploadedFile,setStorePercentageOfUploadedFile] = useState([0,0,0,0,0,0,0,0,0,0]);
  const [countState,setCountState] = useState(0);
  const [checkDeleteFlag,setCheckDeleteFlag] = useState("notDetelted");
  const [countApiCallTime,setCountApiCallTime]= useState(0);
  const [targetNoOfApiCall,setTargetNoOfApiCall] = useState(0);
  const [errorMessageFileUpload,setErrorMessageFileUpload] = useState({message:""})
  const [storeUplodedDocumentId,setStoreUplodedDocumentId] = useState([]);
  const [passedSelectedIndex, setPassedSelectedIndex] = useState(null)
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [loading,setLoading] = useState(false);
  let tagreValueForApiCall=0;
  const marketNAOReducerData = useSelector(state => state.marketNAOReducerData);
  const deletedFileApiResponse = useSelector(state=> state.messageCenterData);
  const role= LocalStorageHelper.getItem("role")
  const getUploadSession = useSelector(state=> state?.messageCenterData?.getUploadSession);
  const [sessionIDForFileUpload,setSessionIDForFileUpload] = useState('');
  const [successCountState,setSuccessCountState] = useState(0);
  const [apiError, setApiError] = useState(false)
  const [errorStatusFileDelete,setErrorStatusFileDelete] = useState(false);

  const updateProgressBarValueAfterDetelted = (attachedFilesSize,selectedIndex)=>
  {  
    const deletedData = attachedFiles[selectedIndex];
    setPassedSelectedIndex(parseInt(selectedIndex));

    dispatch(resetFileDeletedState());
    const deletedAttachments = [...attachedFiles];
    deletedAttachments.splice(selectedIndex, 1);
    setCheckDeleteFlag("deleted");
    let countApiCallTimeTemp = countApiCallTime -1;
    setCountApiCallTime(countApiCallTimeTemp);
    setAttachment(deletedAttachments);
    setRejectedfiles([]);
    removeServicesAccountHeader();
    setLoading(false);
    // if(deletedData?.documentIDObj ==undefined || deletedData?.documentIDObj ==null || deletedData?.documentIDObj =="")
    // {
    //     dispatch(resetFileDeletedState());
    //     let deletedAttachments = [...attachedFiles];
    //     deletedAttachments.splice(selectedIndex, 1);
    //     setCheckDeleteFlag("deleted");
    //     let countApiCallTimeTemp = countApiCallTime -1;
    //     setCountApiCallTime(countApiCallTimeTemp);
    //     setAttachment(deletedAttachments);
    //     setRejectedfiles([]);
    //     setLoading(false);
    // }
    // else
    // {
    //     console.log('Hello world')
    //     const payload = {
    //         "contentType": "vcm:document",
    //         "sessionId":sessionIDForFileUpload,
    //         "vcmDocMetadata": {
    //             "nodeId": deletedData?.documentIDObj?.documentId,
    //             "viewable": "false"
    //         }
    //     }
    //     setLoading(true)
    //     dispatch(removeUplodedDocument({payload , token: getToken()}));
    // }

  }
 
  const uploadFilesWithProgressBar = (attachedFilesInfo,startIValue,targetIvalue)=>
  {
      setSuccessCountState(0)
      const msrInfo = getSessionValues();

      let propertiesJson = {
        "documentSource": "VCM",
        "clientType": "Vcm_Internal",
        "vcmId": msrInfo?.vcmid,
        "tag": "Popular",
        "category": "Client_Provided",
        "entity": "VCM"
      }

      if(attachedFilesInfo.length > 0)
      {
          for(let i=startIValue;i<targetIvalue;i++)
          {
              if(i == startIValue)
              {
                setIsFormSubmited(true);
              }
              const hundreadNumber = 100;
              const options = {
                  onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    let percent = Math.floor( (loaded * hundreadNumber) / total )
                    if( percent < hundreadNumber ){
                      attachedFiles[i].percent=percent;
                      setCountState(prevState=>(
                          prevState+1
                      ));
                    }
                  }
                }

              const formData = new FormData();
              formData.append('file',attachedFilesInfo[i]?.fileData);
              formData.append('metadata', JSON.stringify(propertiesJson));
              
              fileUploadWithProgressBarUpdate(formData,i,options,
              uploadFileWithProgressBarCallBack,
              uploadFileWithProgressBarCatchCallBack);
              if(errorMessageFileUpload == true)
              {
                  break;
              }
          }
      }
  }


  const callUploadFileWithProgressBarFun =()=>
  {
      if(checkDeleteFlag == "notDetelted")
      {
          let startIValue = 0;
          let targetIValue =0;
          if(countApiCallTime == 0)
          {
              startIValue=0;
              targetIValue=attachedFiles.length;
              setCountApiCallTime(attachedFiles.length);
              setTargetNoOfApiCall(attachedFiles.length);
              tagreValueForApiCall=attachedFiles.length;
          }
          else
          {
              startIValue=countApiCallTime;
              targetIValue=targetNoOfApiCall;
              tagreValueForApiCall=targetNoOfApiCall;
          }
          uploadFilesWithProgressBar(attachedFiles,startIValue,targetIValue)
      }
      else
      {
          setCheckDeleteFlag("notDetelted");
      }
  }
  
  function uploadFileWithProgressBarCallBack(response,index)
  {
      if((response.status == 200 || response.status == 201) && Object.keys(response.data)?.length > 0)
      {
          attachedFiles[index].percent = 100;   
          setCountState(prevState=>(
              prevState+1
          ));

          setSuccessCountState(prevState=>(
            prevState+1
          ))

              const responseData = {
                  "documentId":response.data.documentId,
                  // "documentName":response.data.data.fileName,
                  // "documentSource":"alfresco"
              };
              if(responseData?.documentId != null){
                attachedFiles[index]['documentIDObj'] = responseData;
              }
            
         
      }
  }

  function uploadFileWithProgressBarCatchCallBack(status)
  {
      setErrorMessageFileUpload({
          message: File_UPLOAD_ERROR_MESSAGE
      })
      setIsFormSubmited(true);
  }


  const submitClick = () => {

    let applicationId = marketNAOReducerData.applicationId
    
    let uploadedDocumentInfo = [];

    for(let i=0;i<attachedFiles?.length;i++)
    {
        if(attachedFiles[i]?.documentIDObj != undefined && attachedFiles[i]?.documentIDObj != null)
        {
            uploadedDocumentInfo.push(attachedFiles[i].documentIDObj)
        }
    }


    //history.push('/ConfirmationFile')   
    const submitPayload = {
     
      applicationId: applicationId,
      nao: "brokerage",
      savedAttachments: uploadedDocumentInfo
    }

    if(uploadedDocumentInfo.length > 0){
      // // Call submit api
      dispatch(submitKycUploadedDocuments({
        payload: submitPayload
      }))

      setLoading(true)
    }
  }


  const cancelClick = () => {
    // clear local state and redirect
    dispatch(clearMarketNAOInfo())
    history.replace({
      pathname: role === 'Member' ? '/accountholder' : '/accountguestview',
    });
  }

  useEffect(()=>{
  },[countState,storePercentageOfUploadedFile])
  
  useEffect(()=>{
    callUploadFileWithProgressBarFun();
  },[attachedFiles]);





  useEffect(()=>{
    const fourThousand = 4000;
    if(deletedFileApiResponse?.statusCode == fourThousand)
    {
        dispatch(resetFileDeletedState());
        const deletedAttachments = [...attachedFiles];
        deletedAttachments.splice(passedSelectedIndex, 1);
        setCheckDeleteFlag("deleted");
        let countApiCallTimeTemp = countApiCallTime -1;
        setCountApiCallTime(countApiCallTimeTemp);
        setAttachment(deletedAttachments);
        setRejectedfiles([]);
        removeServicesAccountHeader();
        setLoading(false);
    }
    else if(deletedFileApiResponse?.deleteApiErrorStatus != undefined && deletedFileApiResponse?.deleteApiErrorStatus && deletedFileApiResponse?.errorMessage !=null && deletedFileApiResponse?.errorMessage !="")
    {
        dispatch(resetDeletedFileData());
        setErrorStatusFileDelete(true)
        setLoading(false);
    }
    else if(deletedFileApiResponse?.statusCode != null  && deletedFileApiResponse?.statusCode != fourThousand )
    {
        setLoading(false);
    }

},[deletedFileApiResponse])

function handleCloseFileDelete(){
  setErrorStatusFileDelete(false);
}


useEffect(() => {

  if(marketNAOReducerData.isSubmitKycUploadDocSuccess){
  
    if(state.noOfTimesBulkApiCalled == 0){
          history.replace('/ConfirmationFile')

    }else if( state.noOfTimesBulkApiCalled == 1 && marketNAOReducerData.isMarketNAOSubmitSuccess){
      // All accounts except primary joint application
      if(!(marketNAOReducerData.marketNAOInfo.account.accountType == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT) || 
        (marketNAOReducerData.marketNAOInfo.account.accountType == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT &&
        marketNAOReducerData.marketNAOInfo.isPrimary != undefined && marketNAOReducerData.marketNAOInfo.isPrimary == false))
      {

        setLoading(false)
        history.replace('/ConfirmationFile')   
      }
      else if(marketNAOReducerData.marketNAOInfo.account.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && 
       (marketNAOReducerData.marketNAOInfo?.isPrimary === undefined && marketNAOReducerData?.kycResponseData?.primary?.kycActionStatus === "SOFTFAIL_NON_BLOCKING") ||
        (marketNAOReducerData.marketNAOInfo?.isPrimary && marketNAOReducerData?.dataFromSavedApplication?.kyc_check?.primary?.kycActionStatus === "SOFTFAIL_NON_BLOCKING")
      ){
      //  joint account primary user 
        setLoading(false)
        history.replace('/ConfirmationFile')   
      }
      else{
        setState((prevState) => ({
          ...prevState,
          showModel:true
        }))
        setLoading(false)
      }
    }else if(state.noOfTimesBulkApiCalled == 1 && marketNAOReducerData.isMarketNAOError){
      setLoading(false)
      //history.replace('/ConfirmationFile')
    }
   
  }
  else if(marketNAOReducerData.isSubmitKycUploadDocError){
    setApiError(true)
    setLoading(false)
  }

}, [marketNAOReducerData])



useEffect(() => {

  // DFW-2937 - Page is accessable only when applicationid is available, and is unique to every brokerage application 
  if(marketNAOReducerData.applicationId == ''){
    history.replace({
        pathname: role === 'Member' ? '/accountholder' : '/accountguestview',
    });
  }else{
    // when entering for the first time, ther wont be any isPrimary variable so when it is undefined then it means its primary user filling the data/application
    const isPrimary = marketNAOReducerData.marketNAOInfo?.isPrimary != undefined ? marketNAOReducerData.marketNAOInfo?.isPrimary : true
    const fillingApplicationForTheFirstTime = Object.keys(marketNAOReducerData.dataFromSavedApplication).length === 0

    const kycNotAvaliableInSavedData = marketNAOReducerData?.dataFromSavedApplication?.kyc_check == undefined 
    const documentRequiredMessage = fillingApplicationForTheFirstTime ? marketNAOReducerData.kycResponseData?.primary?.documentRequiredMessage : isPrimary ? kycNotAvaliableInSavedData ? marketNAOReducerData?.kycResponseData?.primary?.documentRequiredMessage : marketNAOReducerData.dataFromSavedApplication?.kyc_check?.primary?.documentRequiredMessage :  marketNAOReducerData.dataFromSavedApplication.kyc_check.joint.documentRequiredMessage;

    let documentRequired =[];
   
    if(fillingApplicationForTheFirstTime)
    {
        documentRequired =  createDocumentRequiredList(marketNAOReducerData.kycResponseData?.primary?.documentRequired)
    }
    else if(isPrimary)
    {
        if(kycNotAvaliableInSavedData){
          documentRequired =  createDocumentRequiredList(marketNAOReducerData?.kycResponseData?.primary?.documentRequired)
        }else{
          documentRequired =  createDocumentRequiredList(marketNAOReducerData?.dataFromSavedApplication?.kyc_check?.primary?.documentRequired)
        }
    }
    else 
    {
        documentRequired = createDocumentRequiredList(marketNAOReducerData.dataFromSavedApplication.kyc_check.joint.documentRequired);
    }

    setState((prevState) => ({
      ...prevState,
      documentRequiredMessage,
      documentRequired
    }))
  }
}, [])



const createDocumentRequiredList = (documentRequiredPara) =>
{
    if(documentRequiredPara?.length > 0)
    {
      let documentRequired = []
      let fullDocumentRequiredText ="";
      if(documentRequiredPara !=undefined && Array.isArray(documentRequiredPara))
      {
          fullDocumentRequiredText = documentRequiredPara?.join(' AND ');
      }
     
      if(fullDocumentRequiredText !="")
      {
          let splictedText = fullDocumentRequiredText?.split(" ");
          let combineText = ""
          for(let i=0;i<splictedText?.length;i++)
          {
              if(splictedText[i]?.toUpperCase() == "OR" || splictedText[i]?.toUpperCase() == "AND")
              {
                  documentRequired.push(combineText.trim());
                  documentRequired.push(splictedText[i]);
                  combineText = "";
              }
              else
              { 
                  combineText = `${combineText} ${splictedText[i]}`;
              }
          }
  
          documentRequired.push(combineText.trim())
      }
  
      return documentRequired;
    }
    else
    {
      return [];
    }
    
}


const handleOK = () => {
  setApiError(false)
}

useEffect(()=>{

  //setLoading(true)
  //dispatch(getUploadFileSession());

},[])

// useEffect(()=>{
        
//   if(getUploadSession?.message == "Success")
//   {
//       if(getUploadSession?.result?.sessionId !="")
//       {
//           setLoading(false)
//           setSessionIDForFileUpload(getUploadSession?.result?.sessionId);
//       }
//   }
//   else if(getUploadSession?.error)
//   {
//       setLoading(false)
//   }
// },[getUploadSession])


useEffect(()=>{

  let successUploadDocumentCount = 0
  for(let i=0;i<attachedFiles?.length;i++)
  {
      if(attachedFiles[i]?.documentIDObj != undefined && attachedFiles[i]?.documentIDObj != null)
      {
          successUploadDocumentCount = successUploadDocumentCount +1;
      }
  }

  if(successUploadDocumentCount == attachedFiles?.length && attachedFiles?.length > 0)
  {
      setIsFormSubmited(false)
  }
  else
  {
      setIsFormSubmited(true)
  }
},[successCountState,checkDeleteFlag])


if(marketNAOReducerData.marketNAOInfo.account.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && 
  (marketNAOReducerData.marketNAOInfo?.isPrimary === undefined && marketNAOReducerData?.kycResponseData?.primary?.kycActionStatus === "SOFTFAIL_NON_BLOCKING") ||
   (marketNAOReducerData.marketNAOInfo?.isPrimary && marketNAOReducerData?.dataFromSavedApplication?.kyc_check?.primary?.kycActionStatus === "SOFTFAIL_NON_BLOCKING")){
    //  'SUCCESSSSSS'

   }
  return (
    <div className='container' >
      {(isLoading || loading) && <WSpinner loading={isLoading || loading} />}
      {errorStatusFileDelete  && (
                <WMessageBar text={{message:snapErrorText}} onClose={handleCloseFileDelete} optionalZIndex={9999} />
        )}
      {state.showModel && (
        <ConfirmModal
          modalTitle="Application submitted successfully"
          modalBodyText="Your application is under review, please wait 1-2 business days for your account to be opened. If any additional information is needed, we will reach out to you."
          primaryButtonText="Ok"
          onPrimaryClick={cancelClick}
        />
      )}

 
      {apiError && (
          <ConfirmModal
              modalTitle={"Error"}
              // modalBodyText={marketNAOInfoReducerData.postStatusMsg}
              modalBodyText={"Error while processing."}
              primaryButtonText="OK"
              onPrimaryClick={handleOK}
          />
        
      )}

    <h6 style={{marginTop: "50px", fontFamily: 'benton-sans,sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '16px'}}>{state.documentRequiredMessage}</h6>
    <ul>
      {state.documentRequired?.length > 0 && state.documentRequired?.map(item => 
        <>
          { (item == 'OR' || item == 'AND') ? 
          
          <p style={{marginTop: "20px", fontFamily: 'benton-sans,sans-serif',
         fontWeight: 600,
         fontStyle: 'normal',
         fontSize: '16px'}}>{item}</p>  
     : 
       <li style={{marginTop: "20px", fontFamily: 'benton-sans,sans-serif',
       fontWeight: 600,
       fontStyle: 'normal',
       fontSize: '16px'}}>{item}</li>
     }
        </>
          
      )}
    </ul>

    <Attachments
        attachedFiles={attachedFiles}
        setAttachment={setAttachment}
        rejectedFiles={rejectedFiles}
        setRejectedfiles={setRejectedfiles}
        uploadFilesWithProgressBar={uploadFilesWithProgressBar}
        storePercentageOfUploadedFile={storePercentageOfUploadedFile}
        setStorePercentageOfUploadedFile={setStorePercentageOfUploadedFile}
        setCheckDeleteFlag={setCheckDeleteFlag}
        setCountApiCallTime={setCountApiCallTime}
        countApiCallTime={countApiCallTime}
        setTargetNoOfApiCall={setTargetNoOfApiCall}
        updateProgressBarValueAfterDetelted = {updateProgressBarValueAfterDetelted}
        storeUplodedDocumentId = {storeUplodedDocumentId}
        optional={false}
    />

      <CommonButtons
        submitClick={submitClick}
        submit="true"
        disabled={isFormSubmited}
        iscancel={true}
        cancelClick={cancelClick}
        showBack={false}
        conditionalBackButton={true}
        isShowBtn={true}
      />
    </div>
  )
}


export default UploadPendingDocuments