import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as FundAccountActionTypes from '../ReduxConstants/FundAccountConstants';
import makeApiRequest from '../Network/ApiInterface';
import { linkResponseSuccess, linkResponseError } from './AnalyticsActions';

const {
    GET_FUND_ACCOUNTS_LIST,
    GET_FUND_ACCOUNTS_LIST_START,
    GET_FUND_ACCOUNTS_LIST_SUCCESS,
    GET_FUND_ACCOUNTS_LIST_FAILURE,
    GET_EXISTING_FUND_ACCOUNTS_LIST,
    GET_EXCHANGE_FUND_ACCOUNTS_LIST,
    GET_EXCHANGE_IN_FUND_ACCOUNTS_LIST,
    SORT_FUND_ACCOUNTS_LIST_SUCCESS,
    SORT_FUND_ACCOUNTS_LIST_FAILURE,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_START,
    GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
    GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR,
} = FundAccountActionTypes;

export const getAccountList = data => dispatch => {
    const env = '';
    dispatch({
        type: ActionTypes.GET_ACCOUNT_DETAILS,
    });
    makeApiRequest(
        ActionTypes.GET_ACCOUNT_DETAILS,
        data,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_DETAILS_SUCCESS,
                    response: response.data.result,
                });
                dispatch(linkResponseSuccess());
            } else {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_DETAILS_FAILURE,
                });
                dispatch(linkResponseError(response));
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_ACCOUNT_DETAILS_FAILURE,
                response: error.data,
            });
            dispatch(linkResponseError(error.data));
        },
    );
};

export const getFundAccountById = (payload, planType) => dispatch => {
    // SETTING ENVIRONMENT
    const env = '';
    // DISPATCHING ACTION FOR LOADER
    const dispatchType =
        planType === 'automaticInvestment'
            ? FundAccountActionTypes.GET_AIP_FUND_PLAN_DETAILS
            : FundAccountActionTypes.GET_SWP_FUND_PLAN_DETAILS;
    dispatch({
        type: dispatchType,
    });
    // MAKE API REQUEST
    makeApiRequest(
        dispatchType,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                // DISPATCHING SCUCCESS ACTION
                dispatch({
                    type: FundAccountActionTypes.GET_FUND_PLAN_DETAILS_SUCCESS,
                    response: response.data.result,
                });
                dispatch(linkResponseSuccess());
            } else {
                // DISPATCHING FAILURE ACTION
                dispatch({
                    type: FundAccountActionTypes.GET_FUND_PLAN_DETAILS_FAILURE,
                });
                dispatch(linkResponseError(response));
            }
        },
        error => {
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: FundAccountActionTypes.GET_FUND_PLAN_DETAILS_FAILURE,
                response: error.data,
            });
            dispatch(linkResponseError(error.data));
        },
    );
};

export const clearFundPlanInfo = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING FUND PLAN
    dispatch({
        type: FundAccountActionTypes.GET_FUND_PLAN_DETAILS_RESET,
    });
};

// Removes $, from input amount

const formatInputValues = val => {
    return val ? val.replace(/[a-zA-Z,$]/g, '') : 0;
};

/**
 * Get funds list action used in transaction - purchase, exchange and liquidation
 *
 * @param {Object} payload
 * @param {boolean} getExistingFundsOnly
 * @param {boolean} getExchangeFundAccountList
 * @param {boolean} getExchangeInFundAccountList
 */
export const getFundAccountsList = (
    payload,
    getExistingFundsOnly = false,
    getExchangeFundAccountList = false,
    getExchangeInFundAccountList = false,
) => {
    return async dispatch => {
        const env = '';
        let url = GET_FUND_ACCOUNTS_LIST;
        if (getExistingFundsOnly) {
            url = GET_EXISTING_FUND_ACCOUNTS_LIST;
        }
        if (getExchangeFundAccountList) {
            url = GET_EXCHANGE_FUND_ACCOUNTS_LIST;
        }

        if (getExchangeInFundAccountList) {
            url = GET_EXCHANGE_IN_FUND_ACCOUNTS_LIST;
        }
        dispatch({
            type: GET_FUND_ACCOUNTS_LIST_START,
            payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                url,
                payload,
                env,
                response => {
                    dispatch({
                        type: GET_FUND_ACCOUNTS_LIST_SUCCESS,
                        payload: response.data,
                    });
                },
                error => {
                    if (error.response) {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    } else if (error.request) {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FAILURE,
                            payload: error.request,
                        });
                    } else {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FAILURE,
                            payload: error.message,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: GET_FUND_ACCOUNTS_LIST_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Gets the list of fund list to be sorted based on asc or desc in state value (from reducer)
 *
 * @param {Array} fundList
 */
export const sortFundList = fundList => {
    return async dispatch => {
        try {
            dispatch({
                type: SORT_FUND_ACCOUNTS_LIST_SUCCESS,
                fundList,
            });
        } catch (error) {
            dispatch({
                type: SORT_FUND_ACCOUNTS_LIST_FAILURE,
            });
        }
    };
};

export const clearFundAccountList = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING FUND ACCOUNT LIST
    dispatch({
        type: ActionTypes.GET_ACCOUNT_DETAILS_RESET,
    });
};

// ------------------------- New Account Opening ----------------------------------
/**
 * Get funds list used in New Account Opening - Fund Selection page
 *
 * @param {Object} payload
 */
export const getFundAccountsListForNAO = payload => {
    return async dispatch => {
        const env = '';

        dispatch({
            type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_START,
            payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                GET_FUND_ACCOUNTS_LIST_FOR_NAO,
                payload,
                env,
                response => {
                    dispatch({
                        type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
                        payload: response.data,
                    });
                },
                error => {
                    if (error.response) {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    } else if (error.request) {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
                            payload: error.request,
                        });
                    } else {
                        dispatch({
                            type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
                            payload: error.message,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByRiskCriteriaNAO = (filteredRisk, filteredFundsParam) => {
    let riskFilteredFunds = [];
    const riskFilteredFundsValues = [];
    let filteredFunds = [...filteredFundsParam];
    filteredRisk.forEach(riskItem => {
        riskFilteredFunds = filteredFundsParam.filter(fund => fund.risk === riskItem);
        if (riskFilteredFunds.length > 0) {
            riskFilteredFunds.map(item => riskFilteredFundsValues.push(item));
        }
    });
    if (riskFilteredFundsValues.length > 0) {
        filteredFunds = [...riskFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByMininvCriteriaNAO = (filteredMIL, filteredFundsParam) => {
    let minInvFilteredFunds = [];
    const minInvFilteredFundsValues = [];
    let filteredFunds = [];
    // let filteredFunds = [...filteredFundsParam];
    filteredMIL.forEach(minInvItem => {
        minInvFilteredFunds = filteredFundsParam.filter(
            fund => Math.trunc(formatInputValues(fund.initialInvestment)) === Number(minInvItem),
        );
        if (minInvFilteredFunds.length > 0) {
            minInvFilteredFunds.map(item => minInvFilteredFundsValues.push(item));
        }
    });
    if (minInvFilteredFundsValues.length > 0) {
        filteredFunds = [...minInvFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByFundTypeCriteriaNAO = (filteredFundType, filteredFundsParam) => {
    let fundTypeFilteredFunds = [];
    const fundTypeFilteredFundsValues = [];
    let filteredFunds = [...filteredFundsParam];
    filteredFundType.forEach(fundTypeItem => {
        fundTypeFilteredFunds = filteredFundsParam.filter(fund => fund.fundType === fundTypeItem.replace(' Funds', ''));
        if (fundTypeFilteredFunds.length > 0) {
            fundTypeFilteredFunds.map(item => fundTypeFilteredFundsValues.push(item));
        }
    });
    if (fundTypeFilteredFundsValues.length > 0) {
        filteredFunds = [...fundTypeFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Filters funds list based on filter criteria
 */

export const filterFundsListNAO = payload => {
    const { filteredMIL, filteredRisk, filteredFundType, NAOFundAccountsList } = payload;

    let filteredFunds = [...NAOFundAccountsList];

    // Filter funds by Risk
    if (filteredRisk.length > 0) {
        filteredFunds = filterFundsByRiskCriteriaNAO(filteredRisk, filteredFunds);
    }

    // Filter funds by Minimum initial investment
    if (filteredMIL.length > 0) {
        filteredFunds = filterFundsByMininvCriteriaNAO(filteredMIL, filteredFunds);
    }

    // Filter funds by fund type
    if (filteredFundType.length > 0) {
        filteredFunds = filterFundsByFundTypeCriteriaNAO(filteredFundType, filteredFunds);
    }

    return async dispatch => {
        try {
            dispatch({
                type: GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
                payload: filteredFunds,
            });
        } catch (error) {
            dispatch({
                type: GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR,
                payload: error,
            });
        }
    };
};
