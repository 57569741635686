import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

export const manageLocalState = data => dispatch => {
    // debugger;
    dispatch({
        type: ActionTypes.WEB_ACCOUNT_LOCAL_STATE,
        ...data,
    });
};

// Removes $, from input amount

const formatInputValues = val => {
    return val ? val.replace(/[a-zA-Z,$]/g, '') : 0;
};

/**
 * Sorts the fund list array. Used in New Account Opening - Fund selection page
 *
 * @param {Array} fundList
 */
export const sortFundListLocalState = fundList => {
    return async dispatch => {
        try {
            dispatch({
                type: ActionTypes.SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_SUCCESS,
                fundList,
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.SORT_FUND_ACCOUNTS_LIST_LOCAL_STATE_FAILURE,
            });
        }
    };
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByRiskCriteriaNAO = (filteredRisk, filteredFundsParam) => {
    let riskFilteredFunds = [];
    const riskFilteredFundsValues = [];
    let filteredFunds = [...filteredFundsParam];
    filteredRisk.forEach(riskItem => {
        riskFilteredFunds = filteredFundsParam.filter(fund => fund.risk === riskItem);
        if (riskFilteredFunds.length > 0) {
            riskFilteredFunds.map(item => riskFilteredFundsValues.push(item));
        }
    });
    if (riskFilteredFundsValues.length > 0) {
        filteredFunds = [...riskFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByMininvCriteriaNAO = (filteredMIL, filteredFundsParam) => {
    let minInvFilteredFunds = [];
    const minInvFilteredFundsValues = [];
    // let filteredFunds = [...filteredFundsParam];
    let filteredFunds = [];
    filteredMIL.forEach(minInvItem => {
        minInvFilteredFunds = filteredFundsParam.filter(
            fund => Math.trunc(formatInputValues(fund.initialInvestment)) === Number(minInvItem),
        );
        if (minInvFilteredFunds.length > 0) {
            minInvFilteredFunds.map(item => minInvFilteredFundsValues.push(item));
        }
    });
    if (minInvFilteredFundsValues.length > 0) {
        filteredFunds = [...minInvFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Helper function to filterFUndsListNAO
 */

const filterFundsByFundTypeCriteriaNAO = (filteredFundType, filteredFundsParam) => {
    let fundTypeFilteredFunds = [];
    const fundTypeFilteredFundsValues = [];
    let filteredFunds = [...filteredFundsParam];
    filteredFundType.forEach(fundTypeItem => {
        fundTypeFilteredFunds = filteredFundsParam.filter(fund => fund.fundType === fundTypeItem.replace(' Funds', ''));
        if (fundTypeFilteredFunds.length > 0) {
            fundTypeFilteredFunds.map(item => fundTypeFilteredFundsValues.push(item));
        }
    });
    if (fundTypeFilteredFundsValues.length > 0) {
        filteredFunds = [...fundTypeFilteredFundsValues];
    }
    return filteredFunds;
};

/**
 *
 * Filters funds list based on filter criteria
 */

export const filterFundsListNAOForLocalState = payload => {
    const { filteredMIL, filteredRisk, filteredFundType, NAOFundAccountsList } = payload;
    let filteredFunds = [...NAOFundAccountsList];

    // Filter funds by Risk
    if (filteredRisk.length > 0) {
        filteredFunds = filterFundsByRiskCriteriaNAO(filteredRisk, filteredFunds);
    }

    // Filter funds by Minimum initial investment
    if (filteredMIL.length > 0) {
        filteredFunds = filterFundsByMininvCriteriaNAO(filteredMIL, filteredFunds);
    }

    // Filter funds by fund type
    if (filteredFundType.length > 0) {
        filteredFunds = filterFundsByFundTypeCriteriaNAO(filteredFundType, filteredFunds);
    }

    return async dispatch => {
        try {
            dispatch({
                type: ActionTypes.GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_SUCCESS,
                payload: filteredFunds,
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_LOCAL_STATE_ERROR,
                payload: error,
            });
        }
    };
};

export const clearLocalState = data => dispatch => {
    dispatch({
        type: ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE,
        ...data,
    });
};

export const clearLocalStateForNAO = () => dispatch => {
    dispatch({
        type: ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO,
    });
};

export const clearLocalStateForNAOAccTypeChange = () => dispatch => {
    dispatch({
        type: ActionTypes.WEB_ACCOUNT_CLEAR_LOCAL_STATE_NAO_ACC_TYPE,
    });
};

export const clearAppState = () => dispatch => {
    dispatch({
        type: ActionTypes.USER_LOGOUT,
    });
};
