import React from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';

import classes from './Input.module.css';
import { VCMSelectWithoutLabel } from '../../../common';

// const questionIds = ['question1', 'question2', 'question3']

export const SecurityQuestion = (props) => {
    const { changed, securityQuestionState, label, id, securityQuestion, selectStyle } = props
    const {value} = securityQuestionState[id.toString()]
    const data = {
        config: {
            id,
            type: 'text',
            label,
            options: [
                // ...securityQuestionState[id].options
            ]
        },
        change: e => changed(e, id),
        selectStyle: {
            height: 50
        }
    }
    // console.log(data.config.options)
    // console.log(securityQuestion)
    if (securityQuestion !== null && securityQuestion !== undefined) {
        // console.log(securityQuestion)
        // console.log(data.config.options)
        // const questions = [];
        // Logic for showing unique security questions compared with other 2 questions
        if(id === 'question1'){
            data.config.options.push(...securityQuestion.value.filter(
                question => (
                    question.key !== securityQuestionState.question2.value && question.key !== securityQuestionState.question3.value
                )));
        }else if(id === 'question2'){
            data.config.options.push(...securityQuestion.value.filter(
                question => (
                    question.key !== securityQuestionState.question1.value && question.key !== securityQuestionState.question3.value
                )));
        } else {
            data.config.options.push(...securityQuestion.value.filter(
                question => (
                    question.key !== securityQuestionState.question1.value && question.key !== securityQuestionState.question2.value
                )));
        }

    }

    let selectElement = null;

    selectElement = <VCMSelectWithoutLabel
        // className={classes.InputElement}
        id={data.config.id}
        name={data.config.id}
        value={value}
        onChange={data.change}
        // selectstyle={data.selectStyle}
        itemlist={data.config.options}
        // label={data.config.label}
        // labelrow='true' 
        />

    return (
        <div className={classes.SelectInput} style={selectStyle}>
        <label className={classes.Label} htmlFor={data.config.id}>{data.config.label}</label>
            {selectElement}
        </div>
    );

};

SecurityQuestion.propTypes = {
    selectStyle: PropTypes.instanceOf(Object),
    id: PropTypes.string,
    securityQuestion: PropTypes.shape({
        value: PropTypes.instanceOf(Array)
    }),
    securityQuestionState: PropTypes.instanceOf(Object),
    label: PropTypes.string,
    changed: PropTypes.func
}

SecurityQuestion.defaultProps = {
    selectStyle: {},
    id: 'securityQuestion',
    securityQuestion: null,
    securityQuestionState: {},
    label: 'Question',
    changed: () => { }
}

export default SecurityQuestion;