/**
 * Beneficiary Form Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to add beneficiaries
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { formatDate, isValidDate } from 'utils';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import { WInput, VCMSelect, VCMDatePicker, WIcon, SSNComponent } from '../../../common';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';
import removeIcon from '../../../assets/deleteTrash.svg';
import { getTokenFromSession } from 'shared/Helpers/Utils';

const assignObj = obj => {
    return obj;
};
const distributionExceededMsg = 'Distribution Percentanges must total to 100%';
const removeBeneficiaryText = 'Remove';
const DOBErrorMsg = "Please select Beneficiary's Date of Birth";

class BeneficiaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            masterDataLoadedForBeneficiary: false,
            primaryBen: false,
            distributionExceeded: false,
            distributionErrorShown: false,
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.ssnBenRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, distributionPercentageError, handleDistributionErrorMsg, benType } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
                distributionExceeded: false,
            };
        }
        if (distributionPercentageError) {
            handleDistributionErrorMsg(benType);
            return { distributionExceeded: true };
        }

        return {};
    }

    componentDidMount() {
        // const { masterDataLoadedForBeneficiary } = this.state;
        // if (!masterDataLoadedForBeneficiary) {
        const payload = [];
        const { masterLookupStateData,getCompositeData } = this.props;
        const compositePayloadData = ['ben_type', 'custd_relation', 'rel_to_custd'];
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (this.props && masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
       
        getCompositeData({ data: { datakey: payload }, 
            token: getTokenFromSession(),
            marketNAOFlow:"marketNaoFlow"
         });
        // }
    }

    removeBeneficiary = () => {
        const { index, sendToBeneficiaryParentToRemove, benType } = this.props;
        sendToBeneficiaryParentToRemove(index, benType);
    };

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        let { distributionPercentage } = this.state;

        let err = '';
        const rulesEventKey = eventKey === 'emailAdd' ? 'benEmailAdd' : eventKey;
        err = Rules[rulesEventKey.toString()]
            ? checkValidity({
                  rules: Rules[rulesEventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'distributionPercentage') {
            const { distributionExceeded } = this.state;
            if (eventKey === 'distributionPercentage') {
                
                // if (validDistributionPercentage === null || '') return;
                distributionPercentage = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '0.00'; // validInitialInvestment!=="0" ? Number(validInitialInvestment).toFixed(2):"0.00";
            }
            if (distributionExceeded && e.target.value !== '') {
                this.setState({
                    distributionExceededMsg: errorMsg.distributionPercentage,
                    distributionExceeded: false,
                });
            } else {
                this.setState({
                    distributionExceededMsg: err,
                    distributionExceeded: false,
                });
            }
        }
        this.setState({ errorMsg, distributionPercentage });
    };

    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        const rulesEventKey = eventKey === 'emailAdd' ? 'benEmailAdd' : eventKey;
        err = Rules[rulesEventKey.toString()]
            ? checkValidity({
                  rules: Rules[rulesEventKey.toString()],
                  value: e.target.value,
              })
            : '';

        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'distributionPercentage') {
            const targetValue = e.target.value;
            const values = e.target.value ? e.target.value.split('.') : [];
            const isInvalidInput = targetValue.match(/[!@#$%^&*(),?":{}|<>]/g);
            let { distributionPercentage = '' } = this.state;
            if ((isInvalidInput && isInvalidInput.length > 0) || values.length > 2) {
                distributionPercentage = this.state.distributionPercentage || '';
            } else if (values.length === 2 && values[1].length > 2) {
                distributionPercentage = targetValue.substr(0, targetValue.length - 1);
            } else {
                distributionPercentage = e.target.value;
            }
            e.target.value = distributionPercentage;
        }

        if (eventKey === 'beneficiaryType') {
            this.setState({
                [eventKey]: e.target.value,
                lastName: '',
                beneficiaryDOB: '',
                middleName: '',
                emailAdd: '',
                firstName: '',
                distributionPercentage: '',
                relationToAccHolder: '',
                errorMsg: {},
            });
        } else {
            this.setState({ [eventKey]: e.target.value, errorMsg });
        }
    };

    handleChangeDOB = (eventKey, e) => {
        const { errorMsg } = this.state;
        let err = '';
        if (e.target.value) {
            err = Rules[eventKey.toString()]
                ? checkValidity({
                      rules: Rules[eventKey.toString()],
                      value: e.target.value,
                  })
                : '';
        }
        errorMsg[eventKey.toString()] = err;
        this.setState({ [eventKey]: e.target.value, errorMsg });
    };

    validateFields = () => {
        const { isChild } = this.props;
        const { errorMsg } = this.state;
        const stateData = this.state;
        let valid = false;
        // const totalDistributionPercentage = distribution.reduce((a,b) => a+b,0);
        const childFileds = ['relationshipToCustodian', 'firstName', 'lastName', 'beneficiaryDOB', 'beneficiarySSN'];
        const irafields = [
            'beneficiaryType',
            'relationToAccHolder',
            'distributionPercentage',
            'firstName',
            'lastName',
            stateData.beneficiaryType === 'other_individuals' ? 'beneficiaryOtherDOB' : 'beneficiaryDOB',
            // 'emailAdd',
        ];
        const fields = isChild ? childFileds : irafields;
        fields.forEach(field => {
            const err = Rules[field.toString()]
                ? checkValidity({
                      rules: Rules[field.toString()],
                      value: stateData[field.toString()] === 'Error' ? '' : stateData[field.toString()],
                  })
                : '';

            errorMsg[field.toString()] = err;

            if (!valid) {
                if (typeof err === 'boolean') {
                    valid = err === true;
                } else if (typeof err === 'string') {
                    valid = err !== '';
                } else {
                    valid = err === null;
                }
            }
        });
        if (errorMsg.beneficiaryDOB && isChild) {
            errorMsg.beneficiaryDOB = DOBErrorMsg;
        }

        if (
            !errorMsg.beneficiaryType &&
            !errorMsg.distributionPercentage &&
            !errorMsg.firstName &&
            !errorMsg.beneficiaryDOB &&
            !errorMsg.emailAdd &&
            !isChild
        ) {
            if (
                (stateData.beneficiaryType === 'individuals' && !errorMsg.relationToAccHolder && !errorMsg.lastName) ||
                stateData.beneficiaryType === 'other_individuals'
            ) {
                valid = true;
            }
        } else if (
            isChild &&
            !errorMsg.firstName &&
            !errorMsg.lastName &&
            !errorMsg.relationshipToCustodian &&
            !errorMsg.beneficiarySSN &&
            !errorMsg.beneficiaryDOB
        ) {
            valid = true;
        } else {
            valid = false;
        }
        // if(!valid && !isChild) {
        //     valid = totalDistributionPercentage!==100;
        // }
        // this.setState({ errorMsg, distributionExceeded:(totalDistributionPercentage!==100) });
        let isSSNerror = false;
        if (isChild && errorMsg.beneficiarySSN) {
            isSSNerror = true;
        }
        this.setState({ errorMsg, isSSNerror });
        return valid;
    };

    sendDataToBeneficaryCard = () => {
        const valid = this.validateFields();
        const ssnBenData = this.ssnBenRef.current && this.ssnBenRef.current.getSSN();
        return { ...this.state, valid, beneficiarySSN: ssnBenData.fullSSN, ssnBenData };
    };

    setPrimaryBen = (e, index) => {
        const indVal = e.target.checked ? e.target.name === index : false;
        this.setState({
            primaryBen: indVal,
        });
    };

    handleSSN = val => {
        this.setState({ beneficiarySSN: val });
    };

    renderBeneficiaryFields = (benefTypeOp, relationToAccountHolderList) => {
        const {
            errorMsg,
            beneficiaryType,
            relationToAccHolder,
            distributionPercentage,
            distributionExceeded,
        } = this.state;
        const { isChild, index, benType } = this.props;
        const childText = isChild ? 'child' : `beneficiary${benType}${index}`;
        const arialabelVariable = this.getAriaLabelText(benType, index);
        return (
            !isChild && (
                <>
                    <VCMSelect
                        name="beneficiaryType"
                        id={assignObj(`${childText}BeneficiaryType`)}
                        itemlist={benefTypeOp}
                        label="Beneficiary Type"
                        onChange={this.handleChange('beneficiaryType')}
                        onBlur={this.handleBlur('beneficiaryType')}
                        value={beneficiaryType}
                        labelsm={4}
                        valuesm={5}
                        errortext={errorMsg.beneficiaryType}
                        noGutters
                    />
                    {beneficiaryType && beneficiaryType !== 'other_individuals' && (
                        <VCMSelect
                            name="relationToAccHolder"
                            id={assignObj(`${childText}relationToAccHolder`)}
                            itemlist={relationToAccountHolderList}
                            label="Relationship to Account Holder"
                            onChange={this.handleChange('relationToAccHolder')}
                            onBlur={this.handleBlur('relationToAccHolder')}
                            value={relationToAccHolder}
                            labelsm={4}
                            valuesm={5}
                            errortext={errorMsg.relationToAccHolder}
                            noGutters
                        />
                    )}
                    <Row>
                        <WInput
                            label="Distribution Percentage"
                            placeholder="XXX"
                            className="distributionPercentage"
                            name="distributionPercentage"
                            id={assignObj(`${childText}DistributionPercentage`)}
                            type="text"
                            onChange={this.handleChange('distributionPercentage')}
                            onBlur={this.handleBlur('distributionPercentage')}
                            value={distributionPercentage || ''}
                            required
                            errortext={distributionExceeded ? distributionExceededMsg : errorMsg.distributionPercentage}
                            labelsm={4}
                            valuesm={5}
                            arialabel={assignObj(`${arialabelVariable} Distribution Percentage`)}
                            maxlength={3}
                            subtype="transaction"
                        />
                    </Row>
                </>
            )
        );
    };

    renderChildBeneficiaryFields = (custodianRelationshipList, childText) => {
        const { errorMsg, relationshipToCustodian } = this.state;
        const { isChild } = this.props;
        return (
            isChild && (
                <VCMSelect
                    name="relationshipToCustodian"
                    id={assignObj(`${childText}relationshipToCustodian`)}
                    label="Relationship to Custodian"
                    itemlist={custodianRelationshipList}
                    onChange={this.handleChange('relationshipToCustodian')}
                    onBlur={this.handleBlur('relationshipToCustodian')}
                    value={relationshipToCustodian}
                    valuesm={5}
                    errortext={errorMsg.relationshipToCustodian}
                    labelsm={4}
                    noGutters
                />
            )
        );
    };

    renderRemoveButton = arialabelVariable => {
        const { benType } = this.state;
        const { isChild, isRemoveBtn } = this.props;
        return (
            !isChild &&
            isRemoveBtn && (
                <div className="benBtnWrapper">
                    <button
                        type="button"
                        className="addAnotherFieldBtn rmvBtn"
                        onClick={this.removeBeneficiary}
                        bentype={benType}
                        aria-label={assignObj(`Remove ${arialabelVariable}`)}
                    >
                        <WIcon src={removeIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                        {removeBeneficiaryText}
                    </button>
                </div>
            )
        );
    };

    getAriaLabelText = (benType, index) => {
        let label = '';
        if (benType === 'P') {
            label = `Primary BeneFiciary ${index}`;
        } else if (benType === 'C') {
            label = `Contingent BeneFiciary ${index}`;
        } else {
            label = 'Child Beneficiary';
        }
        return label;
    };

    render() {
        const {
            errorMsg,
            firstName,
            middleName,
            lastName,
            // beneficiarySSN,
            emailAdd,
            beneficiaryDOB,
            beneficiaryType,
            //  primaryBen,
            ssnBenData,
            isSSNerror,
        } = this.state;
        const { masterLookupStateData, isChild, index, benType, isUTMA } = this.props;
        const {
            ben_type: benTypeMaster,
            custd_relation: custodianRelationshipMaster,
            rel_to_custd: relationToAccountHolderMaster,
        } = masterLookupStateData || {};
        const { value: benTypeMasterValue } = benTypeMaster || {};
        const { value: custodianRelationshipMasterValue } = custodianRelationshipMaster || {};
        const { value: relationToAccountHolderMasterValue } = relationToAccountHolderMaster || {};
        const benefTypeOp = benTypeMasterValue || [];
        const custodianRelationshipList = custodianRelationshipMasterValue || [];
        const relationToAccountHolderList = relationToAccountHolderMasterValue || [];
        const dateofBirthLabel = ' Date of Birth';
        // const primaryBenLabel = 'Set Primary Beneficiary';
        const startMaxDate = new Date();
        const childText = isChild ? 'child' : `beneficiary${benType}${index}`;
        const arialabelVariable = this.getAriaLabelText(benType, index);
        return (
            <div className="beneficiaryForm">
                {this.renderBeneficiaryFields(benefTypeOp, relationToAccountHolderList)}
                <Row>
                    <WInput
                        label={beneficiaryType === 'other_individuals' ? 'Name' : 'First Name'}
                        placeholder={beneficiaryType === 'other_individuals' ? 'Name' : 'First Name'}
                        className=""
                        name="firstName"
                        id={assignObj(`${childText}FirstName`)}
                        type="text"
                        onChange={this.handleChange('firstName')}
                        onBlur={this.handleBlur('firstName')}
                        value={firstName || ''}
                        required
                        errortext={errorMsg.firstName}
                        labelsm={4}
                        valuesm={5}
                        maxlength={40}
                        subtype="characters"
                        arialabel={assignObj(`${arialabelVariable} First Name`)}
                    />
                </Row>
                {beneficiaryType !== 'other_individuals' && (
                    <>
                        <Row>
                            <WInput
                                label="Middle Name"
                                placeholder="Middle Name"
                                optional="true"
                                sublabel={styles.sublabel}
                                name="middleName"
                                id={assignObj(`${childText}MiddleName`)}
                                type="text"
                                onChange={this.handleChange('middleName')}
                                onBlur={this.handleBlur('middleName')}
                                value={middleName || ''}
                                errortext={errorMsg.middleName}
                                labelsm={4}
                                valuesm={5}
                                maxlength={40}
                                subtype="characters"
                                arialabel={assignObj(`${arialabelVariable} Middle Name`)}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label="Last Name"
                                placeholder="Last Name"
                                className=""
                                name="lastName"
                                id={assignObj(`${childText}LastName`)}
                                type="text"
                                required
                                onChange={this.handleChange('lastName')}
                                onBlur={this.handleBlur('lastName')}
                                value={lastName || ''}
                                errortext={errorMsg.lastName}
                                labelsm={4}
                                valuesm={5}
                                maxlength={40}
                                subtype="characters"
                                arialabel={assignObj(`${arialabelVariable} Last Name`)}
                            />
                        </Row>
                    </>
                )}
                <Row>
                    <SSNComponent
                        ref={this.ssnBenRef}
                        savedstate={ssnBenData}
                        sendfullssn={this.handleSSN}
                        optional={isUTMA ? 'false' : 'true'}
                        type={childText}
                        isssnerror={isSSNerror}
                    />
                </Row>
                {/* <Row>
                    <WInput
                        label={dateofBirthLabel}
                        placeholder="Date of Birth"
                        className="beneficiaryDOB dobdate"
                        name="beneficiaryDOB"
                        id={assignObj(`${childText}BeneficiaryDOB`)}
                        type="date"
                        value={beneficiaryDOB || ''}
                        required
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        errortext={errorMsg.beneficiaryDOB}
                        labelsm={4}
                        valuesm={5}
                        inputfieldstyle={assignObj({ width: 255 })}
                        inputstyle={assignObj({ marginBottom: 43 })}
                    />
                </Row> */}
                {(isChild || beneficiaryType === 'individuals') && (
                    <VCMDatePicker
                        name="beneficiaryDOB"
                        id={assignObj(`${childText}BeneficiaryDOB`)}
                        max={isValidDate(startMaxDate) ? formatDate(startMaxDate) : ''}
                        label={dateofBirthLabel}
                        onChange={Object.assign(e => this.handleChangeDOB('beneficiaryDOB', e))}
                        onBlur={this.handleBlur('beneficiaryDOB')}
                        errortext={errorMsg.beneficiaryDOB}
                        value={beneficiaryDOB || ''}
                        labelsm={4}
                        valuesm={5}
                        maxErrorTxt="Please enter a valid date"
                        noGutters
                        //  dobValidation={beneficiaryType === 'individuals'}
                        optional={beneficiaryType === 'other_individuals'}
                        DOBErrorMsg={DOBErrorMsg}
                    />
                )}

                {!isChild && (
                    <>
                        <Row>
                            <WInput
                                label="Email"
                                // placeholder="abc@gmail.com"
                                id={assignObj(`${childText}emailAdd`)}
                                name="emailAdd"
                                type="email"
                                onChange={this.handleChange('emailAdd')}
                                onBlur={this.handleBlur('emailAdd')}
                                required
                                value={emailAdd || ''}
                                errortext={errorMsg.emailAdd}
                                labelsm={4}
                                valuesm={5}
                                maxlength={30}
                                optional="true"
                            />
                        </Row>
                    </>
                )}
                {this.renderChildBeneficiaryFields(custodianRelationshipList, childText)}
                {this.renderRemoveButton(arialabelVariable)}
            </div>
        );
    }
}
BeneficiaryForm.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    isChild: PropTypes.bool,
    isRemoveBtn: PropTypes.bool,
    index: PropTypes.string,
    distributionPercentageError: PropTypes.bool,
    handleDistributionErrorMsg: PropTypes.func,
    sendToBeneficiaryParentToRemove: PropTypes.func,
    benType: PropTypes.string,
    isUTMA: PropTypes.bool,
};

BeneficiaryForm.defaultProps = {
    savedState: {},
    masterLookupStateData: {},
    isChild: false,
    isRemoveBtn: true,
    index: '',
    distributionPercentageError: false,
    handleDistributionErrorMsg: () => {},
    sendToBeneficiaryParentToRemove: () => {},
    benType: '',
    isUTMA: false,
};
export default BeneficiaryForm;
