const expenseRatioLabel = 'Expense Ratio';

export default {
    fundKeys: {
        sections: [
            {
                heading: 'Performance',
                fields: [
                    {
                        label: '1 year',
                        subtext: '',
                        uniquekey: 'oneYear',
                    },
                    {
                        label: '5 year',
                        subtext: '',
                        uniquekey: 'fiveYear',
                    },
                    {
                        label: '10 year',
                        subtext: '',
                        uniquekey: 'tenYear',
                    },
                    {
                        label: 'YTD',
                        subtext: '',
                        uniquekey: 'performanceYTD',
                    },
                ],
            },
            {
                heading: 'Performance & Price',
                fields: [
                    {
                        label: 'SInce Inception',
                        subtext: '',
                        uniquekey: 'inception',
                    },
                    {
                        label: 'Inception Date',
                        subtext: '',
                        uniquekey: 'inceptionDate',
                    },
                    {
                        label: 'NAV',
                        subtext: 'As of',
                        uniquekey: 'nav',
                    },
                ],
            },
            {
                heading: 'SEC Yields',
                fields: [
                    {
                        label: '30 Days',
                        subtext: 'As of',
                        uniquekey: 'day30',
                    },
                    {
                        label: '7 Days',
                        subtext: 'As of',
                        uniquekey: 'day7',
                    },
                    {
                        label: '',
                        subtext: '(W/o Waivers)',
                        uniquekey: 'waivers',
                    },
                ],
            },
            {
                heading: 'Expense Ratios',
                fields: [
                    {
                        label: expenseRatioLabel,
                        subtext: '',
                        uniquekey: 'expenseRatio',
                    },
                    {
                        label: expenseRatioLabel,
                        subtext: 'Before Reimbursement',
                        uniquekey: 'expenseRatioBefore',
                    },
                    {
                        label: expenseRatioLabel,
                        subtext: 'After Reimbursement',
                        uniquekey: 'expenseRatioAfter',
                    },
                    {
                        label: 'YTD',
                        subtext: '',
                        uniquekey: 'expenseYTD',
                    },
                ],
            },
            {
                heading: 'Morningstar Ratings',
                fields: [
                    {
                        label: 'Overall',
                        subtext: '',
                        uniquekey: 'rating',
                    },
                    {
                        label: 'Rating Category',
                        subtext: '',
                        uniquekey: 'ratingCategory',
                    },
                    {
                        label: 'Number in Category',
                        subtext: '',
                        uniquekey: 'numberCategory',
                    },
                ],
            },
        ],
    },
    backText: 'Back To Investment Selection',
    pageHeading: 'Fund Comparison',
    fundStyle: { borderBottom: 'none', textAlign: 'right' },
    defaultText: 'default',
    none: 'none',
    odd: 'odd',
    even: 'even',
    technicalError: 'Technical error',
    faStyle: { paddingLeft: 0 },
    nextStyles: { display: 'none' },
    pdfStyle: { color: 'red', margin: '0 8px' },
    captionTable: 'Compare Funds Table',
    tableCaptionStyle: { display: 'none' },
};
