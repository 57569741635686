/**
 * Change Sign In By OTP Component
 *********************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description By using the OTP method to change the Sign in mode
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedBy Manjunath V
 * @lastModifiedReason Modified for Back button functionality to hold the prev screen (ChangeSignInMethodOptions) selected radio button
 *                     option to be selected when prev screen (ChangeSignInMethodOptions) page is loaded and implemented Breadcrumbs
 */

import React, { Component, memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { WRadio, WBreadCrumb, CommonButtons, WInputCheckBox, WSpinner } from 'common';
// import { assignObj } from 'utils';
// import LoadingOverlay from 'react-loading-overlay';
import routeConstants from 'routeConstants';

import SideHeader from '../SideHeader/SideHeader';
import styles from './styles';
import './ChangeSignInByOTP.css';
import userConstants, {getMaskedNumber} from '../userConstants';

const {
    pageHeaderName,
    labelText,
    checkBoxText,
    methodChangeOtp,
    otpTo,
    saveManageCheck,
    saveManage,
    saveDeviceText,
    saveCurrent,
} = userConstants.changeSignInByOTPComponent;
const { breadCrumbValues } = userConstants.changeSignInMethodOptions;
const {
    otp,
    otpViaMobile,
    onlineId,
    test,
    changeSignInMethod,
    mobile,
    preferredOTPDeliveryMethod,
    container,
    changeSignInMethodText,
    w100,
    radioGroup,
    // hidden,
    token,
    trueText,
} = userConstants;

export class ChangeSignInByOTPComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveDevice: false,
            messageText: null,
            selectedOptionOnPrevPage: '',
            selectedOption: otpViaMobile,
            mobileNumber: '',
        };
        this.clickBack = this.clickBack.bind(this);
        this.onClickChkBox = this.onClickChkBox.bind(this);
        this.clickSubmit = this.clickSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState } = nextProps;
        // console.log(loginState)
        let updatedState = { ...state };
        const profile = loginState.getCustomerProfile;
        if (profile && profile.phoneInformation) {
            const phoneNumber = profile.phoneInformation.primaryMobilePhone;
            let maskedNumber = 'not available';
            if(phoneNumber){
                maskedNumber = getMaskedNumber(phoneNumber);
            }
            // console.log(maskedNumber)
            updatedState = { ...state, mobileNumber: maskedNumber };
        }
        return updatedState;
    }

    componentDidMount() {
        const { location, getCustomerProfile, setJWTToken, loginState } = this.props;
        const { state } = location || {};
        const idToken = localStorage.getItem(token);
        if (idToken) {
            setJWTToken(idToken);
        }
        if(!loginState.getCustomerProfile){
            getCustomerProfile({});
        }
        if (state) {
            this.setState({
                selectedOptionOnPrevPage: state.selectedOption,
            });
        }
    }

    onClickChkBox() {
        this.setState(state => ({ saveDevice: !state.saveDevice }));
    }

    handleRadioClick = e => {
        e.preventDefault();
        this.setState({
            selectedOption: e.target.id,
            messageText: {
                message: methodChangeOtp,
            },
        });
    };

    clickBack() {
        const { history } = this.props;
        const { selectedOptionOnPrevPage } = this.state;

        history.push({
            pathname: routeConstants.changeSignInMethodOptions,
            state: {
                selectedOption: selectedOptionOnPrevPage,
            },
        });
    }

    clickSubmit() {
        const { messageText } = this.state;
        const { history, setSignInMethod } = this.props;
        const id = localStorage.getItem(userConstants.userId) || test;

        setSignInMethod({
            [onlineId]: id,
            [changeSignInMethod]: otp, // otp, softtoken,push
            [preferredOTPDeliveryMethod]: mobile, // "email",    // "mobile","securityQuestions",
            // "deviceName": "iPhoneX",
            // "deviceId": "AEDR12345667"
        });

        history.push({
            pathname: routeConstants.securityPreferences,
            message: messageText,
        });
    }

    render() {
        const { selectedOption, saveDevice, mobileNumber } = this.state;
        const { loginState } = this.props;
        return (
            <div>
                <WSpinner loading={loginState.isLoading} />
                {/* <LoadingOverlay
                    active={loginState.isLoading}
                    spinner
                    styles={assignObj({ wrapper: { overflow: hidden } })}
                /> */}
                <div className={container}>
                    <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb={changeSignInMethodText} />
                    <SideHeader title={pageHeaderName} />
                    <Row className={w100}>
                        <Col md={9} lg={9} xl={9}>
                            {' '}
                            <p style={styles.subHeading}> {labelText} </p>
                        </Col>
                    </Row>

                    <Form.Group role={radioGroup}>
                        <WRadio
                            value={otpTo + mobileNumber}
                            id={otpViaMobile}
                            selected={selectedOption === otpViaMobile}
                            onClick={this.handleRadioClick}
                            radiotext={styles.radiotext}
                            radiostyles={styles.radiostyles}
                        />
                    </Form.Group>
                    <div style={styles.checkBox} className={saveDevice ? saveManageCheck : saveManage}>
                        <p style={styles.checkBoxHeading}>{checkBoxText}</p>
                        <WInputCheckBox
                            id={saveDeviceText}
                            // checked={saveDevice}
                            onChange={this.onClickChkBox}
                            labelstyle={styles.labelstyle}
                            // checkInputStyle={styles.checkInputStyle}
                            label={saveCurrent}
                        />
                    </div>
                </div>
                <CommonButtons
                    backClick={this.clickBack}
                    submitClick={this.clickSubmit}
                    disabled={!selectedOption}
                    submit={trueText}
                    arialabelback="Back to change sign in methods"
                />
            </div>
        );
    }
}

ChangeSignInByOTPComponent.propTypes = {
    getCustomerProfile: PropTypes.func,
    setSignInMethod: PropTypes.func,
    setJWTToken: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
};

ChangeSignInByOTPComponent.defaultProps = {
    getCustomerProfile: () => {},
    setSignInMethod: () => {},
    setJWTToken: () => {},
    loginState: {},
    history: [],
    location: {},
};

export default memo(ChangeSignInByOTPComponent);