import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OffScreenText from '../OffScreenText';

const StyledHintText = styled.small`
    display: block;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #56565A;
    opacity: 1;
`;

const HintText = (props) => {
    const { id, text, srText } = props;
    return (
        <StyledHintText id={id}>
            <OffScreenText hideTxt={srText} />
            <span aria-hidden >{text}</span>
        </StyledHintText>
    )
}

HintText.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    srText: PropTypes.string,
};

HintText.defaultProps = {
    id: '',
    text: '',
    srText: '',
};

export default HintText;
