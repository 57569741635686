import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {isEmpty} from 'lodash'
import { analyzeClickEvent } from '../../../../shared/Actions/AnalyticsActions';



const assignObj = obj => obj;
const DButton = ({ item,dbuttonCustomStyle }) => {
    const { name, link='#' } = item;
    const history =  useHistory();
    const dispatch = useDispatch();
    function Order(e){
        const target = e.target.innerText || '';
        const screenName=localStorage.getItem("screenName")
        const linkName=!isEmpty(target) && target.includes('Open') ? `Dashboard_${screenName}_${target}`: `Dashboard_${screenName}_PlaceAnOrder_${target}`
        dispatch(
            analyzeClickEvent({
                link: linkName,
            }),
        );
        if(target === 'Buy'){
            history.push('/transactionPurchases')
        }else if(target === 'Sell'){
            history.push('/liquidation')
        }else if(target === 'Exchange'){
            history.push('/exchange')
        }else if(target === 'Open a New Account'){
            console.log('New account')
            history.push('/openAccount')

        }
        else if(target == "Open a New Brokerage Account")
        {
            console.log('New broker acount');
            history.push('/marketOpenAccount')
        }
    }   

    return (
        <div data-test = 'dbutton' style={dbuttonCustomStyle}>
            <div className="btncorner" />
            <Button className="placeOrderBtn" variant="" size="lg" onClick={Order} style={assignObj({display:'block'})}>
                <Link style={assignObj({ color: '#fff',display:'block' }
                )} to={link} tabIndex="-1" role="presentation">
                    {name}
                </Link>
            </Button>
        </div>
    );
};

DButton.propTypes = {
    item: PropTypes.instanceOf(Object),
    dbuttonCustomStyle:PropTypes.instanceOf(Object)
};
DButton.defaultProps = {
    item: {},
    dbuttonCustomStyle:{}
};

export default DButton;
