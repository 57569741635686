import React, { useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { ConformationModal } from 'common';
import { connect } from "react-redux";
import { localStateManagementActions } from "../../shared/Actions";

const message = 'Your session is about to expire. Please click Cancel to avoid';
const signedOut1 = 'You have been successfully Signed Out';
// const signedOut2 = ' Sign In again to access the application';
const signedOutText = (
    <span>
        <strong className>{signedOut1}</strong>
        {/* {signedOut2} */}
    </span>
);
let timerId;
let intervalId;

const IdleTimeOut = props => {
    const [idle, setIdle] = useState(false);
    const [time, setTime] = useState();
    const idleTimer = React.createRef();

    const handleLogOut = () => {
        const { history, clearAppState } = props;
        localStorage.clear();
        clearAppState()
        clearTimeout(timerId);
        clearInterval(intervalId);
        setIdle(false);
        history.push({
            pathname: '/msr',
            message: { message: signedOutText },
        });
    };

    const functions = {
        handleYes: () => {
            handleLogOut();
        },

        handleNo() {
            // console.log(timerId);
            clearTimeout(timerId);
            clearInterval(intervalId);
            setIdle(false);
        },

        onIdle: () => {
            // console.log('user is idle');
            handleLogOut();
            setTime(120);
            setIdle(true);
            // let timeRemaining = 120;
            // intervalId = setInterval(() => {
            //     console.log('SetInterval call',timeRemaining)
            //     timeRemaining -= 1;
            //     setTime(timeRemaining);
            // }, 1000);
            // timerId = setTimeout(() => {
            //     console.log('SetTimeOut call',timerId)
            //     handleLogOut();
            // }, 1000 * 60 * 2); // 2 minutes -- 60secs x 2 This will be for 2 minutes after Timeout popup
            // // console.log(timerId);
        },

        onAction: () => {
            // console.log('user did something', e);
        },

        onActive: () => {
            // console.log('user is active', e);
        },
    };

    const timerMessage = `Expire in ${time} seconds`;

    const modal = (
        <ConformationModal
            modalTitle={null}
            modalBodyText={message}
            modalBodyText2={timerMessage}
            // primaryButtonText="Sign Out"
            secondaryButtonText="Cancel"
            onPrimaryClick={functions.handleYes}
            onSecondaryClick={functions.handleNo}
        />
        // <WModal
        //     style={styles.popupModalStyle}
        //     closebutton=""
        //     header="Session Timeout"
        //     modalheaderstyle={styles.popupModalHeaderStyle}
        //     modalfooterstyle={styles.popupModalFooterStyle}
        //     headertextstyle={styles.popupModalHeaderTextStyle}
        //     modalbodystyle={styles.popupModalBodyStyle}
        //     size="lg"
        //     dialogClassName="myModal"
        //     show={idle}
        //     onHide={functions.handleNo}
        //     body={
        //         <div style={styles.popupModalDivStyle}>
        //             <div style={styles.popupModalBodyTextStyle}>{message}</div>
        //         </div>
        //     }
        //     footer={
        //         <Row noGutters>
        //             <WButton variant="outline" buttontext="No" onClick={functions.handleNo} buttonstyle={assignObj({margin:' 0 20px'})}/>
        //             <WButton buttontext="Yes" onClick={functions.handleYes} buttonstyle={assignObj({margin:' 0 20px'})}/>
        //         </Row>
        //     }
        // />
    );

    return (
        <div>
            <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={functions.onActive}
                onIdle={functions.onIdle}
                onAction={functions.onAction}
                debounce={250}
                timeout={1000 * 60 * 5} // 13 minutes -- 60secs x 13
            />
            {idle ? modal : null}
        </div>
    );
};

IdleTimeOut.propTypes = {
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
};

IdleTimeOut.defaultProps = {
    history: [],
};

const mapStateToProps = (state) => ({            
    localStateData: state.localStateManagementReducerData    
})

const mapDispatchToProps = {
...localStateManagementActions
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdleTimeOut));
