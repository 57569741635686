import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import styled from 'styled-components';
import { WIcon } from '../../../common';
import consts from './consts';
import './AccountTypeCard.css';

const AccountTypeCard = ({ item, onclick, cardClassName }) => {
    const cardClsName = `detailedCradWrapper ${cardClassName}`;
    const checked = cardClassName === 'selected';
    return (
        <button
            className={cardClsName}
            onClick={onclick}
            type="button"
            data-key={item.key}
            role="radio"
            aria-checked={checked}
        >
            <Row className="headerRow">
                <h3>{item.value}</h3>
                <span>
                    {consts.timeEsitmateText(item.key)}
                    {cardClassName === 'selected' && (
                        <WIcon icon={faCheckCircle} fontawesomestyle={consts.fontawesomeiconcard} />
                    )}
                </span>
            </Row>
            <Row className="descriptionRow">
                <Col className="descriptionCol">{item.description}</Col>
                <Col className="descriptionCol">
                    <span>{consts.detailsInfotext} </span>
                    <ul className="detailsList">
                        {consts.detailsNeededlist.map(val => {
                            return <li key={val.key}>{val.value}</li>;
                        })}
                    </ul>
                </Col>
            </Row>
        </button>
    );
};

AccountTypeCard.propTypes = {
    item: PropTypes.instanceOf(Object),
    onclick: PropTypes.func,
    cardClassName: PropTypes.string,
};

AccountTypeCard.defaultProps = {
    item: {},
    onclick: () => {},
    cardClassName: '',
};
export default AccountTypeCard;
