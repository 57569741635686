import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import TopLeftCornerColor from '../TopLeftCornerColor';
import AddressToggles from '../AddressToggles';

export const StyledAddressCardWithToggle = styled(TopLeftCornerColor)`
    height: 100%;
    padding: 30px;
    margin-left: ${props => props.mL || 0}px;
    
    background: ${props => props.selected ? '#FAFAFA' : '#ffffff'} 0% 0% no-repeat padding-box;
    box-shadow:${props => props.selected ? '0px 0px 16px #EEF5DD' : 'none'};
    outline: ${props => props.selected ? '2px solid #004A98' : 'none'};
`;

const StyledCardTitle = styled.p`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0 0 10px 0;
`;

const StyledAddressLine = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
    margin: 0;
`;

const StyledAddressLine1 = styled(StyledAddressLine)`
    font-weight: 600;
`;

const AddressCard = (props) =>{
    
    const {
        cardTitle,
        addressLine1,
        addressLine2,
        addressLine3,
        mL,
        mailingAddress,
        physicalAddress,
        onMailingAddressChange,
        onPhysicalAddressChange
    } = props;
    
    const isToggleOn = mailingAddress || physicalAddress; 
    
    const cornerColor = isToggleOn ? 'green' : 'grey';
    
    return (
        <StyledAddressCardWithToggle selected={isToggleOn} mL={mL} cornerSize={35} cornerColor={cornerColor} >
            { cardTitle && <StyledCardTitle>{cardTitle}</StyledCardTitle> }
            { addressLine1 && <StyledAddressLine1>{addressLine1}</StyledAddressLine1> }
            { addressLine2 && <StyledAddressLine>{addressLine2}</StyledAddressLine> }
            { addressLine3 && <StyledAddressLine>{addressLine3}</StyledAddressLine> }
            <AddressToggles
                mailingAddress={mailingAddress}
                physicalAddress={physicalAddress}
                onMailingAddressChange={onMailingAddressChange}
                onPhysicalAddressChange={onPhysicalAddressChange}
            />
        </StyledAddressCardWithToggle>
    );
}

AddressCard.propTypes = {
    cardTitle: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    mL: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    mailingAddress: PropTypes.bool,
    physicalAddress: PropTypes.bool,
    onMailingAddressChange: PropTypes.func,
    onPhysicalAddressChange: PropTypes.func,
};

AddressCard.defaultProps = {
    cardTitle: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    mL:0,
    mailingAddress: false,
    physicalAddress: false,
    onMailingAddressChange: ()=>{},
    onPhysicalAddressChange: ()=>{},
};

export default AddressCard;