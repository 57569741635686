import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
// import { assignObj } from 'utils';
// import MaskedFormControl from 'react-bootstrap-maskedinput';
import routeConstants from 'routeConstants';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import SideHeader from '../SideHeader/SideHeader';
import { CommonButtons, WMessageBar, WSpinner, Captcha } from '../../../common';
import data from './constants';
import { inputValidityHandler, inputChangedHandler } from '../FormUtils';
import { OnlineId } from '../Input/OnlineId';
import userConstants from '../userConstants';

export class RetrievePasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                onlineId: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: 'User ID',
                    maxLength: 20,
                    rules: { required: true },
                },
                captcha: {
                    valid: false,
                }
            },
            formIsValid: false,
            validCaptcha: false,
            notifyMsgFlag: false,
            notifyMsg: {
                message: '',
            },
        };
    }
    
    componentDidMount() {
        const { otpDetails } = this.props;
        sessionStorage.setItem('stepname', 'Recover Password'); 
        if(otpDetails && otpDetails.onlineId) {
            const {form} = this.state;
            const {onlineId} = form;
            const onlineIdUpdated = {...onlineId, value: otpDetails.onlineId, valid: true};
            form.onlineId = onlineIdUpdated;
            this.setState({form});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            const { userDetails, recoverPasswordGuest, recoverPasswordGuestData, recoverPasswordMemberOrGuest, history } = this.props;
            
            if (userDetails && userDetails.result) {
                const { result, errorCode } = userDetails;
                if (result.role === 'Member' || result.MFA) {
                    const { uuid } = result;
                    const { form } = this.state;
                    // recoverPasswordCustomer({ onlineId: form.onlineId.value });
                    const localData = { otpDetails: {
                        primaryNumber: result.primaryPhoneNumber,
                        phoneType: result.phoneType,
                        // phoneType: 'work',
                        onlineId: form.onlineId.value,
                        functionalityFlag : "recoverCustPwd",
                        backLink: routeConstants.retrievePassword,
                        verifyNextLink: routeConstants.createNewPassword,
                        backLabel: 'Retrieve Password',
                        flow: 'Reset Password'
                    }};
                    this.saveToLocal(localData);
                    recoverPasswordMemberOrGuest('');
                    history.push({
                        pathname: '/otp-ivr-select',
                        uuid
                    });
                } else if (result.role) {
                    const { form } = this.state;
                    recoverPasswordMemberOrGuest('');
                    recoverPasswordGuest({ onlineId: form.onlineId.value });
                } else if(errorCode===5008) {
                    recoverPasswordMemberOrGuest('');
                    this.updateNotification(data.doesntExistMsg, true);
                } else if(errorCode===2023){
                    recoverPasswordMemberOrGuest('');
                    history.push({
                        pathname: routeConstants.signIn,
                        message: userConstants.blockedMessage,
                        failure: true
                    });
                } else {
                    recoverPasswordMemberOrGuest('');
                    this.updateNotification(result, true);
                }
            } else if(userDetails && userDetails.isAxiosError) {
                this.updateNotification('Enter valid User ID', true);
                recoverPasswordMemberOrGuest('');
            }
            if (recoverPasswordGuestData) {
                this.handleRecoverPasswordGuest(recoverPasswordGuestData);
            }
            /* if (recoverPwdCustomerData) {
                this.handleRecoverPasswordCustomer(recoverPwdCustomerData);
            } */
        }
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
      }

    analyticsLastTouch = (event) =>{
        analyticsFormAbandLastTouch(event);
    }
    
    saveToLocal = (localData) => {
        const { manageLocalState } = this.props;
        manageLocalState(localData);
    }
    
    onChange = (event) => {
        inputChangedHandler(event, event.target.id, this);
    }

    onBlur = (event) => {
        inputValidityHandler(event === 'onlineId' ? 'onlineId' : event.target.id, this);
    }

    onBack = () => {
        const { history } = this.props;
        history.push({
            pathname: '/sign-in',
        });
    }

    handleRecoverPasswordGuest = (res) => {
        const {recoverPasswordGuest, history} = this.props;
        const { result, errorCode } = res;
        if(errorCode === 0){
            recoverPasswordGuest('');
            history.push({
                pathname: '/sign-in',
                message: {message: 'Please click the link sent to your email id to reset the password'}, // check the text with Devashish
            });
            /* this.setState(state => ({
                ...state,
                notifyMsgFlag: true,
                notifyMsg: {
                    message: 'Please click the link sent to your email id to reset the password', 
                },
            })); */
        }else {
            this.updateNotification(result);
            recoverPasswordGuest('');
        }
    }

    updateNotification = (result, failVal=false) => {
        this.setState(state => ({
            ...state,
            notifyMsgFlag: true,
            failure: failVal,
            notifyMsg: {
                message: result,
            },
        }));
    };

    onNext = () => {
        const { form } = this.state;
        const { value } = form.onlineId;
        const { recoverPasswordMemberOrGuest } = this.props;
        const payload = {
            onlineId: value.toLowerCase(),
        };
        recoverPasswordMemberOrGuest(payload);
    };

    onClose = () => {
        this.setState({notifyMsgFlag: false, notifyMsg: ''});
    }

    onCaptchaChange = (value) => {
        const { form } = this.state;
        const {onlineId} = form;
        let {captcha} = form;
        let validForm = false;
        let validCaptcha = false;
        if(value) {
            captcha = {
                valid: true
            };
            validCaptcha = true;
            validForm = onlineId.valid;
        } else if(!value) {
            captcha = {
                valid: false
            };
            validCaptcha = false;
        }
        this.setState({form: {...form,captcha}, formIsValid: validForm, validCaptcha});
    
    };

    onCaptchaErrored = () => {
        this.setState({
            captcha: {
                valid: false
            },
            validCaptcha: false,
            formIsValid: false,
        })
    }

    render() {
        const { form, formIsValid, notifyMsg, notifyMsgFlag, failure, validCaptcha } = this.state;
        const {isLoading} = this.props;
        return (
            <div>
                <WSpinner loading={isLoading} />
                {notifyMsgFlag ? <WMessageBar error={failure} text={notifyMsg} onClose={this.onClose} /> : null}
                <div className="container custom-container">
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader
                                title={data.title}
                                info={data.info}
                                sideHeaderBlockStyle={data.sideHeaderBlockStyle}
                            />
                        </Col>
                        <Col md lg xl>
                            <OnlineId onlineIdState={form.onlineId} changed={this.onChange} blurred={this.onBlur} clicked={this.analyticsLastTouch} />
                            <Captcha onCaptchaChange={this.onCaptchaChange}
                                onCaptchaErrored={this.onCaptchaErrored}
                                onCaptchaExpired={this.onCaptchaErrored} />
                            
                        </Col>
                    </Row>
                </div>
                <div className="custom-button-container">
                <CommonButtons
                    backClick={this.onBack}
                    nextClick={this.onNext}
                    disabled={!(formIsValid && validCaptcha)}
                    arialabelback="Back to Sign In Page"
                    dataAnalyticId="UMS_RecoverPassword"
                />
                </div>
                
            </div>
        );
    }
}

RetrievePasswordComponent.propTypes = {
    isLoading: PropTypes.bool,
    recoverPasswordGuestData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    userDetails: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    recoverPasswordGuest: PropTypes.func,
    recoverPasswordMemberOrGuest: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    otpDetails: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
};

RetrievePasswordComponent.defaultProps = {
    isLoading: false,
    recoverPasswordGuestData: null,
    userDetails: null,
    recoverPasswordGuest: () => {},
    recoverPasswordMemberOrGuest: () => {},
    history: [],
    otpDetails: {},
    manageLocalState: () => {},
};

export default RetrievePasswordComponent;
