import React, { useState } from 'react';
import styled from 'styled-components';

const S = {};

S.HandSpan = styled.span``;

const MaskToggleLabel = styled.span`
    cursor: pointer;
    color: #004a98;
    padding-left: 0.5rem;
`;

const  MaskAccountNumber = ({ text,toggleMode }) => {
    const [showFullText, setShowFullText] = useState(false);


    const handleMouseEnter = () => {
        setShowFullText(true);
    };

    const handleMouseLeave = () => {
        setShowFullText(false);
    };

    function toggleText() {
        setShowFullText(!showFullText);
    }

    const maskedText = showFullText ? text : `*${text.slice(-4)}`;

    return (
        toggleMode ? (
            <div>
                <S.HandSpan  data-testid="mask-text-test-id">
                    {maskedText}
                    <MaskToggleLabel onClick={toggleText}>
                        {showFullText ? 'Hide' : 'Show'}
                    </MaskToggleLabel>
                </S.HandSpan>
            </div>
        ) : (
            <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {maskedText}
            </span>
        )
    );

};

export default MaskAccountNumber;
