/**
 * Manage Beneficaiaries Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to Manage Beneficaiaries
 * @createdDate [03/12/2020]
 * ***************************************
 * @lastModifiedDate [03/17/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import './ManageBeneficiaries.css';
import routeConstants from 'routeConstants';
import AccountInfoCardTitle from '../../AccountOptions/InvestmentAndSWP/RequiredMinimumDistribution/RMDInfoCard/AccountInfoCardTitle';
import {
    BENEFICIARIES_ACCOUNT_SELECT_EDIT,
    BREAD_CRUMB_SCREEN_BENEFICIARIES_HOME,
} from '../../../utils/AdobeAnalytics/Constants';
import consts from './consts';

import { WBreadCrumb, WSpinner,WMessageBar } from '../../../common';
import { createBreadCrumbItem } from '../../../utils';
import routesBreadCrumb from '../../../utils/Constants/routesBreadcrumb';
import styled from 'styled-components';
import { getSessionValues } from 'shared/Helpers/Utils';
const StyledCard = styled.div`
      margin-bottom: 2.5rem;    
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d2d2d2;
      opacity: 1;
      &::before {
          content: '';
          width: 0px;
          height: 0px;
          border-bottom: 35px solid transparent;
          border-left: 35px solid #486d90;
          position: absolute;
          top: -1px;
          left: -1px;
      }
  `;

const RMDBeneInfo = styled.div`
      background: #fafafa 0% 0% no-repeat padding-box;
      border: 1px solid #e6e6e6;
      opacity: 1;
      font-family: benton-sans,sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 0;
      color: #49494a;
      margin-bottom: 1.125rem;
      padding: 1rem;
  `;

const BeneInfo = styled.div`
      background: #fafafa 0% 0% no-repeat padding-box;
      border: 1px solid #e6e6e6;
      opacity: 1;
      font-family: benton-sans,sans-serif;
      font-style: normal;
      font-size: 16px;
      letter-spacing: 0;
      color: #49494a;
      margin-bottom: 3.125rem;
      padding: 1rem;
  `;


const nextStyle = {
    display: 'none',
    beneinfo: {
        textDecoration: 'underline',
        color: 'rgb(0,191,255)',
    },
};

class ManageBeneficiariesAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            beneficiaryAccounts: [],
            beneficiaryAccountsCategories: [],
            //showAccountNumber: {},
            showMenu: false,
            showMenuMasterReg: "",
        };
        this.breadCrumbItems = this.getBreadCrumbItems();
    }

    componentDidMount() {
        const { getBeneficiariesMasterRegs } = this.props;
       // const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues() 

        const { accesstoken, vcmid, msrid, fismemberid } = params;
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid,
        }
        
        getBeneficiariesMasterRegs(msrParams);
        window.addEventListener('click', this.handleOutsideClick, false);
        document.addEventListener('keydown', this.handleEscapeKey, false);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, false);
        document.removeEventListener('keydown', this.handleEscapeKey, false);
    }

    static getDerivedStateFromProps(nextProps) {
        const { manageBeneficiariesMasterRegsData } = nextProps;
        if (
            manageBeneficiariesMasterRegsData &&
            !manageBeneficiariesMasterRegsData.isLoading &&
            manageBeneficiariesMasterRegsData.isSuccess &&
            manageBeneficiariesMasterRegsData.masterRegs
        ) {
            const beneficiaryAccounts = [];
            const beneficiaryAccountsCategories = {};

            let dataMap = new Map();
            let demoBeneficiaryAccounts = [];
            let demoCategoryArray = [];

            for (let key in manageBeneficiariesMasterRegsData.masterRegs) {
                let beneficiaryAccount = manageBeneficiariesMasterRegsData.masterRegs[key];
                if (demoCategoryArray.includes(beneficiaryAccount.category) == false) {
                    demoCategoryArray.push(beneficiaryAccount.category);
                }
            }

            for (let i = 0; i < demoCategoryArray.length; i++) {
                demoBeneficiaryAccounts = [];
                for (let key in manageBeneficiariesMasterRegsData.masterRegs) {
                    let beneficiaryAccount = manageBeneficiariesMasterRegsData.masterRegs[key];
                    if (beneficiaryAccount.category == demoCategoryArray[i]) {
                        demoBeneficiaryAccounts.push(beneficiaryAccount);
                    }

                }
                dataMap.set(demoCategoryArray[i], demoBeneficiaryAccounts);
            }

            for (let i = 0; i < demoCategoryArray.length; i++) {
                let array1 = dataMap.get(demoCategoryArray[i]);
                beneficiaryAccounts[i] = array1;
                beneficiaryAccountsCategories[demoCategoryArray[i]] = {
                    categoryKey: i,
                    category: demoCategoryArray[i]
                }
            }
            return { beneficiaryAccounts, beneficiaryAccountsCategories: Object.values(beneficiaryAccountsCategories) }
        }

        return {};
    }
    //it will go back to accountservices page.
    clickBack = () => {
        const { history } = this.props;
        history.push(routeConstants.accountservices);
    }
    getBreadCrumbItems = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyState = {
            state: {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            },
        };
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: historyState,
        };
        const bradcrumbPaths = [routeConstants.accountholder];
        const breadCrumbItems = bradcrumbPaths.map(route => createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_BENEFICIARIES_HOME));
        if (masterRegId) {
            breadCrumbItems.splice(1, 1, intermediateRoute);
        }
        return breadCrumbItems;
    }

    redirectToBeneficiaries = masterRegId => e => {
        e.preventDefault();
        e.stopPropagation();
        const { history } = this.props;
        history.push({
            pathname: routeConstants.manageBeneficiaries,
            state: {
                masterRegId
            }
        });

        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: BENEFICIARIES_ACCOUNT_SELECT_EDIT,
            waitforapiresponse: true
        });
    };
    onMenuClick = (account) => e => {
        e.stopPropagation();
        const { showMenuMasterReg } = this.state;
        if (showMenuMasterReg
            && showMenuMasterReg.accountNumber
            && showMenuMasterReg.accountNumber === account.accountNumber) {
            // If the same account is clicked, then only update "showMenu"
            this.setState(prevState => ({
                showMenu: !prevState.showMenu
            }));
        } else {
            this.setState({
                showMenu: true,
                showMenuMasterReg: account
            });
        };
    };

    closeOpenMenu = () => {
        this.setState({
            showMenu: false,
            showMenuMasterReg: ""
        });
    };
    handleEscapeKey = e => {
        const { showMenu } = this.state;
        if (e.keyCode === 27 && showMenu) {
            this.closeOpenMenu();
        }
    };

    handleOutsideClick = () => {
        const { showMenu } = this.state;
        if (showMenu) {
            this.closeOpenMenu();
        }
    };
    render() {
        const { beneficiaryAccounts, beneficiaryAccountsCategories, showMenu, showMenuMasterReg } = this.state;
        const { isLoading, isError = false } = this.props.manageBeneficiariesMasterRegsData;
        const { dismissError } = this.props;
        const activeCrumb = routesBreadCrumb.get(routeConstants.manageBeneficiariesAccounts) 
     
        return (
            <div className="beneficiaryWrapper">
                 {isError && (<WMessageBar
                     error={true}
                     onClose={dismissError}
                     text={{ message: "We are experiencing technical difficulties. We are actively working to resolve the issue." }}
                     doNotClose
                 />)}
                <Container>
                    <WBreadCrumb breadCrumbItems={this.breadCrumbItems} activeCrumb={activeCrumb} />
                    <Row noGutters className="beneFiciaryHeaderWrapper">
                        <h1 className="pageHeading">{consts.manageBeneficiaryHeading} </h1>
                    </Row>
                    <div>
                        {isLoading && <WSpinner loading={isLoading} />}
                      
                         <RMDBeneInfo>If you are updating your primary beneficiary for an IRA that has a Required Minimum Distribution (RMD) established, please update your RMD after the beneficiary is changed so it can reflect the correct beneficiary data.</RMDBeneInfo>
                         <BeneInfo>403B and Optional Retirement Plans may only be updated through the <a href="https://investor.vcm.com/content/dam/vcm/forms/IRA%20Beneficiary%20Form.pdf" target="_blank" rel="noopener noreferrer" style={nextStyle.beneinfo}>Beneficiary Designation Form</a> at this time.</BeneInfo>
                        {beneficiaryAccountsCategories.map((categoryObject, categoryKey) => (
                            <div key={`divcat${categoryKey}`}>
                                <div>
                                    <Row noGutters className="headerRow">
                                       
                                        <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                            <h6 className="pageSubHeading">{categoryObject.category}</h6>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={6} xl={6} style={{ textAlign: 'right' }}>

                                           
                                        </Col>
                                    </Row>

                                    {beneficiaryAccounts[categoryKey].map(account => {
                                        if (account.accountType !== consts.coverdellEsa) {
                                            return (
                                                <StyledCard key={`${categoryObject.category}-${account.accountNumber}`} className="accounts-card-container" tabIndex="0">
                                                    {/* add bene data here to loop */}
                                                    <AccountInfoCardTitle

                                                        account={account}
                                                        masterRegId={account.key}
                                                        displayAccordianSymbol={false}
                                                        onMenuClick={this.onMenuClick(account)}
                                                        showMenu={showMenu && showMenuMasterReg === account}
                                                        onEditMenuClick={this.redirectToBeneficiaries(account.key)}
                                                        menuLabel={account.funds[0] && account.funds[0].hasFullAccess ? 'Edit' : 'Vieww'}
                                                    />
                                                </StyledCard>)
                                        }
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        );
    }
}

ManageBeneficiariesAccounts.propTypes = {
    manageBeneficiariesMasterRegsData: PropTypes.instanceOf(Object),
    analyzeClickEvent: PropTypes.func,
    getBeneficiariesMasterRegs: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

ManageBeneficiariesAccounts.defaultProps = {
    manageBeneficiariesMasterRegsData: {},
    analyzeClickEvent: () => { },
    getBeneficiariesMasterRegs: () => { },
};

export default ManageBeneficiariesAccounts;
