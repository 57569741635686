import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';

export const setLoadingPage = value => dispatch => {
    dispatch({
        type: ActionTypes.GET_TRANSACTION_HOLDER_LOADER,
        payload: value,
    });
};

export const getTransHolderList = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_TRANSACTION_HOLDER_LIST,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_TRANSACTION_HOLDER_SUCCESS,
                    payload: {
                        transactionList: response.data,
                    },
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_TRANSACTION_HOLDER_FAILURE,
                    [ActionTypes.GET_TRANSACTION_HOLDER_LIST]: response.data,
                });
            }
        },

        error => {
            dispatch({
                type: ActionTypes.GET_TRANSACTION_HOLDER_ERROR,
                response: error.data,
            });
        },
    );
};


export const getAccountServiceOptions = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS_SUCCESS,
                    response: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS_FAILURE,
                    response: response.data,
                });
            }
        },

        error => {
            dispatch({
                type: ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS_FAILURE,
                response: error.data,
            });
        },
    );
};

export const getTransHolderListForManage = payload => dispatch => {
    const env = '';
    makeApiRequest(
        ActionTypes.GET_TRANSACTION_HOLDER_LIST_FOR_MANAGE,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_TRANSACTION_HOLDER_SUCCESS_FOR_MANAGE,
                    payload: {
                        transactionList: response.data,
                    },
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_TRANSACTION_HOLDER_FAILURE_FOR_MANAGE,
                    [ActionTypes.GET_TRANSACTION_HOLDER_LIST]: response.data,
                });
            }
        },

        error => {
            dispatch({
                type: ActionTypes.GET_TRANSACTION_HOLDER_ERROR_FOR_MANAGE,
                response: error.data,
            });
        },
    );
};
export const resetAccountServiceList = () => dispatch => {
    dispatch({
        type: ActionTypes.RESET_ACCOUNT_SERVICE_OPTIONS_LIST,
    });
};

export const resetTransactionHolder = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING/RESET
    dispatch({
        type: ActionTypes.GET_TRANSACTION_HOLDER_RESET,
    });
};

export const getTransactionActivity = (/* payload */) => (/* dispatch */) => {
    makeApiRequest();
};

export const storeAccountInfoData = (payLoad) => dispatch => {
    // DISPATCHING ACTION FOR CLEARING/RESET
    dispatch({
        type: ActionTypes.STORE_ACCOUNTINFO_DATA,
        payload:payLoad
    });
};

