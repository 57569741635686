/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the generate PersonalDetails Payload
 * @createdDate [02/04/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import { MARKET_NAO_ACCOUNT_TYPES } from "../consts";

// const generateEmailDetailsArray = emailData => {
//     if (emailData) {
//         return (
//             emailData.emailAddress &&
//             emailData.emailAddress.map(item => {
//                 if (item !== null) {
//                     return {
//                         email: item.email,
//                         isPrimary: item.isPrimary,
//                     };
//                 }
//                 return {};
//             })
//         );
//     }
//     return {};
// };

const personalInfoDetails = (data, accType, nickName = '') => {
    let personalData = {};
    let payloadMailingAddress = {};
    let payloadPhysicalAddress = {};
    let personalInformation = {};
    if (data.perInfo) {
        personalData = data.perInfo;
        if (personalData) {
            const mailAddress = personalData.primMailAddress;
            if (mailAddress) {
                payloadMailingAddress = {
                    addressType: mailAddress.mailTypeRadio,
                    addressLine1: mailAddress.lineOne,
                    addressLine2: mailAddress.lineTwo,
                    zip: mailAddress.zipCode,
                    city: mailAddress.city,
                    state: mailAddress.state,
                };
            }
            const physicalAddress = personalData.primPhysicalAddress;
            if (physicalAddress) {
                payloadPhysicalAddress = {
                    addressType: mailAddress.mailTypeRadio,
                    addressLine1: physicalAddress.lineOne,
                    addressLine2: physicalAddress.lineTwo,
                    zip: physicalAddress.zipCode,
                    city: physicalAddress.city,
                    state: physicalAddress.state,
                };
            }
            const { primaryPhoneDetails, secondaryPhoneDetails } = personalData;
            const { primaryEmailDetails, secondaryEmailDetails } = personalData.emailAddressData;

            const isJoint = accType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT
            // const emailInfo = isJoint ? personalData.jointEmailAddressData : personalData.emailAddressData;
            // const emailArray = generateEmailDetailsArray(emailInfo);

            personalInformation = {
                accountNickName: nickName,
                prefix: personalData.prefixLocal ? personalData.prefixLocal : personalData.prefix,
                firstName: personalData.firstName,
                middleInitial: personalData.middleInitial,
                lastName: personalData.lastName,
                suffix: personalData.suffix,
                dateOfBirth: personalData.dateOfBirthLocal ? personalData.dateOfBirthLocal : personalData.dateOfBirth,
                gender: personalData.genderLocal ? personalData.genderLocal : personalData.gender,
                maritalStatus: personalData.maritalLocal ? personalData.maritalLocal : personalData.maritalStatus,
                citizenship: personalData.citizenship,
                countryOfCitizenship: personalData.countryOfCitizenship || '',
                relationToCustodian: personalData.relationToCustodian || '',
                beneAccountType: personalData.beneAccountType || '',
                w8FormUpload: personalData.w8FormUpload,
                originalW8FileArray: personalData.originalW8FileArray,
                fileMetaData: personalData.fileMetaData,
                w8FileData: personalData.w8FileData,
                ssnData: isJoint ? personalData.jointSSN : personalData.ssnData,
                mailingAddress: payloadMailingAddress,
                sameAddress: personalData.sameAddress,
                physicalAddress: payloadPhysicalAddress,
                contactDetails: {
                    primaryPhone: primaryPhoneDetails,
                    secondaryPhone: secondaryPhoneDetails,
                },
                emailAddress: {
                    primaryEmail: primaryEmailDetails,
                    secondaryEmail: secondaryEmailDetails,
                },
            };
        }
    }
    return personalInformation;
};

export default function generatePersonalDetailsPayload(data, accType, nickName) {
    let empData = {};
    let militaryData = {};
    let finData = {};
    let personalInformation = {};
    let employementInfo = {};
    let financialInfo = {};
    let militaryInfo = {};
    let jointPerInfo = {};
    const militaryInfoStatus = {};
    let payloadEmployeeAddress = {};
    let pepQuestion = {};
    personalInformation = personalInfoDetails(data, accType, nickName);
    if (data.empInfo) {
        empData = data.empInfo;
        if (empData) {
            const { empAddress } = empData;
            if (empAddress) {
                payloadEmployeeAddress = {
                    addressLine1: empAddress.lineOne,
                    addressLine2: empAddress.lineTwo,
                    zip: empAddress.zipCode,
                    city: empAddress.cityName,
                    state: empAddress.stateName,
                };
            }
            employementInfo = {
                empStatus: empData.empStatus,
                empStatusOther: empData.empClassInfo,
                industry: empData.industry,
                occupation: empData.occupation,
                employersName: empData.employersName,
                employerAddress: payloadEmployeeAddress,
                industryDescription: empData.industryDescription,
                primarySourceofIncome: empData.primarySourceofIncome,
                empClassInfo: empData.empClassInfo,
            };
        }
    }
    if (data.finInfo) {
        finData = data.finInfo;
        if (finData) {
            financialInfo = {
                primSrcOfIncone: finData.primarySrcOfIncome,
                annualIncome: finData.annualIncome,
                taxBracket: finData.taxBracket,
                netWorth: finData.netWorth,
                taxFilingStatus: finData.taxFilingStatus,
            };
        }
    }

    if (data.milInfo) {
        militaryData = data.milInfo;
        if (militaryData.currServingUSM === 'Y') {
            militaryInfo = {
                currServingUSM: militaryData.currServingUSM,
                milityStatus: militaryData.milityStatus,
                branchOfService: militaryData.branchOfService,
                branchOfServiceOthers: militaryData.branchOfServiceOthers,
                rank: militaryData.rank,
                serviceFromDate: militaryData.serviceFromDate,
                serviceToDate: militaryData.serviceToDate,
                commissionCourse: militaryData.commissionCourse,
                militaryOthers: militaryData.militaryOthers,
            };
        }
    }

    if (data.jointPerInfo) {
        jointPerInfo = data.jointPerInfo;
    }

    if (accType && accType.includes('529')) {
        pepQuestion = {
            isSeniorForeignPoliticalFigure: data.isSeniorForeignPoliticalFigure,
            seniorPoliticalFigureName: data.seniorPoliticalFigureName,
        };
    }
    return {
        onlineId: 'onlineId',
        customerId: 'customerid',
        accountType: accType,
        accountNickName: nickName,
        personalInfo: personalInformation,
        employementInfo,
        financialInfo,
        ...militaryInfoStatus,
        militaryInfo,
        jointPerInfo,
        pepQuestion,
    };
}
