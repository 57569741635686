import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as MessageActionTypes from '../ReduxConstants/MessageCenterConstants';
import Config from 'env/Config';
import { post, get, postMultiPart, remove, setAuthHeaders } from './Services';

const MessageCenterApiEvents = () => {
    ApiEventHub.on(MessageActionTypes.SEND_SECURE_MESSAGE, data => {
        setAuthHeaders(data.payload.token);
        postMultiPart(
            Config.sendSecureMessage,
            data.payload.data,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_TOPICS, data => {
        setAuthHeaders(data.payload.token);
        get(Config.getTopics, undefined, data.endPointURL.MESSAGE_CENTER, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.GET_ACCOUNTS, data => {
        setAuthHeaders(data.payload.token);
        get(
            `${Config.getAccounts}?companyNumber=${data.payload.companyNumber}`,
            undefined,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MessageActionTypes.GET_MC_MESSAGES, data => {
        setAuthHeaders(data.payload.token);
        post(
            `${Config.getMessages}?box=${data.payload.box}&pageSize=${data.payload.pageSize}&orderBy=${data.payload.orderBy}&orderType=${data.payload.orderType}&companyNumber=${data.payload.companyNumber}`,
            data.payload.lastEvaluatedKey,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MessageActionTypes.DELETE_MC_MESSAGES, data => {
        setAuthHeaders(data.payload.token);
        remove(
            Config.deleteMessages,
            data.payload.data,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MessageActionTypes.READ_MC_MESSAGES, data => {
        setAuthHeaders(data.payload.token);
        post(
            Config.setReadUnreadToMessages,
            data.payload.data,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_UNREAD_MESSAGE_COUNT, data => {
        setAuthHeaders(data.payload.token);
        get(
            Config.getUnreadMessageCount,
            data.payload.data,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MessageActionTypes.GET_UPLOAD_SESSION_START, data => {

        //setAuthHeaders(data.payload.token);
        get(
            Config.getUploadSession,
            "",
            data.endPointURL.getUploadSessionBaseUrl,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default MessageCenterApiEvents;
