import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { clearExistingActions, updateProfileMilitaryInformations } from 'shared/Actions/ProfileSettingsAction';
import { analyzeFormFieldError, linkResponseError, analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import MilitaryInfoComponent from './MilitaryInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData } = state;
    const { isError } = profileInformationData;
    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    updateProfileMilitaryInformations,
    clearExistingActions,
    analyzeFormFieldError,
    linkResponseError, 
    analyzeClickEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(MilitaryInfoComponent));
