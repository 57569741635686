import React, { Component } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import WBreadCrumb from '../WBreadCrumb/WBreadCrumb';
import './Retirement.css';
import WSpinner from '../WSpinner/WSpinner';


const breadCrumbValues = [{ name: 'Home', url: '/accountholder' }];

class RetirementWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: "Sorry!!! Cross Browser Issue",
            spinner: false,

        }
        this.el = document.createElement(props.component);
    }

    componentDidMount() {
        this.loadData('https://vcm-qa-aem65.adobecqms.net/products/retirement');

    }


    loadData = (url) => {
        this.setState(state => ({ ...state, spinner: true }));
        fetch(url)
            .then((response) => {
                if (response.ok) {

                    return response.text()
                }
                throw new Error('Error message.');
            })
            .then((data) => {
                let result = data.toString().replace(/\/etc/g, 'https://vcm-qa-aem65.adobecqms.net/etc');
                result = result.replace(/https:\/\/vcm-qa-aem65.adobecqms.net\/etc.clientlibs\/vcm\/clientlibs\/clientlib-base.min.([a-zA-Z0-9]\w+).css/g, 'test');

                this.setState(state => ({ ...state, content: result, spinner: false }));
            })
            .catch((err) => {
                this.setState(state => ({
                    ...state,
                    spinner: false,
                    content: err,
                }));
            });
    }

    render() {
        const { content, spinner } = this.state;
        return (
            <div className="container">
                <WSpinner loading={spinner}/>  
                <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb="Retirement" />
                <vcm-help id="vcm-faq">
                    {' '}
                    <InnerHTML html={content} />{' '}
                </vcm-help>
            </div>
        );
    }
}

export default RetirementWidget;
