import React, { useState } from 'react';
import { StatusMessage } from 'modules/CustomerManagementModule/components';

export const showNotification = ({ setShow, setMessage, setFocusfrom }) => ({ msg={}, nextFocus= null }) => {
    setShow(true);
    setMessage(msg);
    setFocusfrom(nextFocus);
};

export const onNotificationClose = (setShow) => () => {
    setShow(false);
}

const withNotification = Component => props => {
    const { notify, message: msg={}, nextFocus= null } = props;

    const [prevShow, setPrevShow] = useState(false);

    const [show, setShow] = useState(notify);
    const [message, setMessage] = useState(null);
    const [focusfrom, setFocusfrom] = useState(null);

    if (notify !== prevShow) {
        setPrevShow(notify);
        if(notify){
            setShow(notify);
            setMessage(msg);
            setFocusfrom(nextFocus);
        }
    }

    return (
        <>
            {show && (
                <StatusMessage
                    onClose={onNotificationClose(setShow)}
                    text={message}
                    focusfrom={focusfrom}
                />
            )}
            {Component && (
                <Component {...props} showNotification={showNotification({ setShow, setMessage, setFocusfrom })}
            />)}
        </>
    );
};

export default withNotification;
