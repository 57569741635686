/**
 * Server Error Page Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component is displayed when there is an error in application
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { WButton } from '../../../common';
import './ServerErrorPage.css';
import Consts from './Consts';

export default class ServerErrorPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleButtonClick = () => {};

    render() {
        const {
            msg,
            subhead,
            heading,
            errorDesc,
            meanText,
            waitText,
            or,
            home,
            mutual,
            searchText,
            btStyle,
            btTextStyle,
        } = Consts;
        const { errorInfo } = this.props;
        return (
            <div className="MainContainer">
                <Row className="se-wrap">
                    <Col className="se-container">
                        <div className="se-sec1">
                            <p className="se-message">{heading}</p>
                            <div className="se-sec2">
                                <p className="se-desc">{errorInfo.message}</p>
                                <p className="se-desc">{subhead}</p>
                                <p>{errorDesc}</p>
                            </div>
                            <div className="se-desc2">
                                <p>{meanText}</p>
                                <div className="se-sec3">
                                    <WButton
                                        buttontext="Refresh the screen"
                                        variant="outline"
                                        buttonstyle={btStyle}
                                        buttontextstyle={btTextStyle}
                                        onClick={this.handleButtonClick}
                                    />
                                    <span className="se-wait">{or}</span>
                                    <span className="se-wait2">{waitText}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="se-img">
                        <div className="se-img-msg">
                            <p>{msg}</p>
                            <div className="linksAlign">
                                <button type="button" className="se-home" onClick={this.clickEvent}>
                                    {home}
                                </button>
                                <button type="button" className="se-mutual" onClick={this.clickEvent}>
                                    {mutual}
                                </button>
                                <button type="button" className="se-search" onClick={this.clickEvent}>
                                    {searchText}
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        {/* <div className="se-background" >
        <img src={search} alt="Oops!" width="630" height="330" />
        </div> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

ServerErrorPageComponent.propTypes = {
    errorInfo: PropTypes.instanceOf(Object),
};

ServerErrorPageComponent.defaultProps = {
    errorInfo: {},
};
