import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import {Row, Col, Container} from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
    PageHeading
} from "common";
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import routeConstants from "routeConstants";
import plusIcon from "assets/plusIcon.png";
import {maskEmail} from "utils";
import { BottomNavBar, TitleWithAction, CardWithMenu, InstructionalPanel } from 'modules/CustomerManagementModule/components';
import CONST from '../constants';

const FlexDiv = styled(Row)`
    margin-top: ${props => props['data-margintop']}rem;
    margin-Bottom: ${props => props['data-marginbottom']}rem;
`;

const primaryMenuOptions = [{name:"Edit", icon:editIcon, menuOptName:"Edit the primary phone number"}];
const secondaryMenuOptions = [{name:"Edit", icon:editIcon, menuOptName:"Edit the secondary phone number"},{name:"Delete", icon:deleteIcon, menuOptName:"Delete the secondary phone number"}];

const backBtnProps = {};

export default class EmailInfoComponent extends React.Component {
    
    componentDidMount() {
        this.clearFlags();
    }

    componentDidUpdate(){
        this.clearFlags();
    }
    
    clearFlags = ()=> {
        const { profileInformationData, clearExistingActions } = this.props;
        const { isError, isSuccess } = profileInformationData;
        if(isSuccess || isError){
            clearExistingActions();
        }
    }

    cancel = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_manageemailinfo_back`
            
        })
        history.push(routeConstants.basicinformation);
    };

    deleteSecondaryEmail = (e) => {
        e.stopPropagation();
        const { deleteProfileEmailInformations } = this.props;
        const payLoad = {
            secondaryEmail: "",
        };
        deleteProfileEmailInformations(payLoad);
    };

    editEmail = (emailObj) => (e) => {
        e.stopPropagation();
        const { history } = this.props;
        history.push(routeConstants.editEmail, emailObj);
    };
    
    onMenuOptionClick = (emailObj) => (menuOption) => (e) => {
        const { analyzeClickEvent } =  this.props;
        if(menuOption === 'Edit'){
            analyzeClickEvent({
                link: `cms_basicinformation_manageemailinfo_editemailinfo`
            });
            this.editEmail(emailObj)(e);
        }else if(menuOption === 'Delete') {
            analyzeClickEvent({
                link: `cms_basicinformation_manageemailinfo_deleteemailinfo`
            });
            this.deleteSecondaryEmail(e);
        }
    }
    
    onAddClick = () =>{
        const { history, analyzeClickEvent } =  this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_manageemailinfo_addemailinfo`
        })
       history.push(routeConstants.addEmailInfo)
    }

    render() {
        const { profileInformationData } = this.props;
        const { profileInformation, isLoading = false } = profileInformationData;
        const { emailInformation  } = profileInformation || {};
        const { primaryEmail = '', secondaryEmail = '' } = emailInformation || {};
        const maskedPrimaryEmail = primaryEmail ? maskEmail(primaryEmail) : '';
        const maskedSecondaryEmail = secondaryEmail ? maskEmail(secondaryEmail) : '';
        const primaryObj = {email: primaryEmail, isPrimary: true};
        const secondaryObj = {email: secondaryEmail, isPrimary: false};

        backBtnProps.onClick = this.cancel;
        backBtnProps['aria-label'] = "Back to basic information";

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={CONST.BREADCRUM_MANAGE_EMAIL}
                            activeCrumb={CONST.PAGE_NAME_MANAGE_EMAIL}
                        />
                        <TitleWithAction
                           title={<PageHeading>{CONST.PAGE_NAME_MANAGE_EMAIL}</PageHeading>}
                           ariaLabel={CONST.ACTION_LABEL_EMAIL_ADD}
                           onActionClick={this.onAddClick}
                           actionText={CONST.ACTION_TEXT_ADD_EMAIL}
                           actionIcon={plusIcon}
                           hideAction={!!secondaryEmail}
                        />
                        <FlexDiv data-margintop={2.5} data-marginbottom={2.5} >
                            <Col md={6} lg={4}>
                                {!!primaryEmail && <CardWithMenu 
                                    cardTitle = { CONST.PRIMARY_EMAIL }
                                    Line1 ={maskedPrimaryEmail}
                                    extradetail = ''
                                    // menu
                                    menuOptions={primaryMenuOptions}
                                    onMenuOptionClick={this.onMenuOptionClick(primaryObj)}
                                />}
                            </Col>
                            <Col md={6} lg={4}>
                                {!!secondaryEmail && <CardWithMenu 
                                    cardTitle = { CONST.SECONDARY_EMAIL }
                                    Line1 ={maskedSecondaryEmail}
                                    extradetail = ''
                                    // menu
                                    menuOptions={secondaryMenuOptions}
                                    onMenuOptionClick={this.onMenuOptionClick(secondaryObj)}
                                />}
                            </Col>
                        </FlexDiv>
                        <InstructionalPanel mbt={2.67}>{CONST.INSTRUCTION_LINE1} {CONST.INSTRUCTION_LINE2}</InstructionalPanel>
                    </Container>
                    <BottomNavBar hidePrimaryBtn backBtnProps={backBtnProps}  />
            </>
        );
    }
}

EmailInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    profileInformationData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    deleteProfileEmailInformations: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
};

EmailInfoComponent.defaultProps = {
    profileInformationData: {},
    clearExistingActions: ()=>{},
    deleteProfileEmailInformations: ()=>{},
    analyzeClickEvent: ()=>{}
};
