import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import routeConstants from 'routeConstants';

import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WMessageBar, WSpinner } from '../../../../common';
import styles from '../../UserManagementStyles'; 

class CheckInbox extends Component {
    styles = {
        buttonLeft: {
            left: 0
        },
        header: {
            width: "100%"
        },
        buttonStyle: {
            border: 'none',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            font: '600 14px/17px benton-sans',
            color: '#004A98'
        },
        nextStyle: {
            display: 'none'
        },
        blockElement: {
            display: "block",
        },
    }

    
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notificationMsg: null,
            logOutRoute: {
                pathname: '/sign-in',
            },
            redirect: false,
        }
        this.navigationHandler = this.navigationHandler.bind(this);
        this.resendHandler = this.resendHandler.bind(this);
    
    }

    componentDidMount() {
        const {otpDetails, manageLocalState} = this.props;
        const {onlineId, flow} = otpDetails;
        if(!onlineId) {
            manageLocalState({otpDetails:{}});
            this.setState({redirect: true});
        } else {
            this.setState({uuid:onlineId, flow});
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState, recoverOnlineID } = nextProps;
        let updatedState = { ...state };
        if (loginState && loginState.recoverOnlineID && loginState.recoverOnlineID.result) {
            const {errorCode, result} = loginState.recoverOnlineID;
            const focusFrom = "resend-button";
            if(errorCode===0) {
                recoverOnlineID('');
                updatedState = { 
                  ...state,
                notificationMsg: {message :'Your User ID has been resent to your E-mail Id'}, 
                focusFrom
                }
            } else if(errorCode===403) {
                updatedState = { 
                    ...state,
                    notificationMsg: {message: ''}
                };
            } else {
                updatedState = { 
                    ...state,
                    notificationMsg: {message: result}
                };
            }
        }
      return updatedState;
    }
    
    onClose = () => {
        this.setState((state) => ({ ...state, notificationMsg: null }))
    }

    navigationHandler() {
        const { history, otpDetails } = this.props;
        const {flow, otpIvrSelect} = otpDetails;
        const memberBackLink = otpIvrSelect==='otp' ? routeConstants.verifyOtp : routeConstants.otpIvrSelect;
        const backLink = flow==='Guest' ? routeConstants.recoverOnlineId : memberBackLink;
        history.push(backLink);
    }

    resendHandler() {
        const { recoverOnlineID, otpDetails } = this.props;
        const { onlineId, role } = otpDetails;
        recoverOnlineID({uuid:onlineId, role});
        // this.setState((state) => ({ ...state, notificationMsg: {message :'Your User ID has been resent to your E-mail Id'}, focusFrom}))
    }

    render() {
        const checkText = {
            check: 'If you do not see the email in your inbox, Click',
            resend: 'Resend',
            verify: 'User ID.'
        };
        const resendText = <span>{checkText.check}<button id='resend-button' style={this.styles.buttonStyle} type='button' onClick={this.resendHandler} aria-label="Resend Link"><span style={this.styles.blockElement} >{checkText.resend}</span></button>{checkText.verify}</span>

        const data = {
            title: 'Check your Inbox',
            info: [`You can now access your User ID sent to your email id.`,
                resendText]
        }

        const { notificationMsg, focusFrom, redirect, logOutRoute } = this.state;
        const {loginState, otpDetails} = this.props;
        const { flow } = otpDetails;
        const analyticId = `UMS_${flow.replace(/\s+/g,'')}_CheckInbox`;
        return (
            <div className="container-fluid">
            <WSpinner loading={loginState.isLoading} />
            {redirect && <Redirect to={logOutRoute} />}
            {notificationMsg && <WMessageBar text={notificationMsg} onClose={this.onClose} focusfrom={focusFrom} />}
                <div className="container">
                    <Row className="main">
                        <div style={this.styles.header}>
                            <SideHeader
                                title={data.title}
                                info={data.info} />
                        </div>
                    </Row>
                </div>
                <CommonButtons backClick={this.navigationHandler} nextstyle={this.styles.nextStyle} arialabelback="Back to Enter One Time Passcode " blockstyle={styles.commonButtons} dataAnalyticId={analyticId}/>
            </div>

        );
    }
}

CheckInbox.propTypes = {
    history: PropTypes.instanceOf(Object),
    loginState: PropTypes.instanceOf(Object),
    otpDetails: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    recoverOnlineID: PropTypes.func,
}

CheckInbox.defaultProps = {
    history: {},
    recoverOnlineID: () => {},
    loginState: {},
    otpDetails: {},
    manageLocalState: () => {},
}

export default CheckInbox;