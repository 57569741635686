const outlineButtonStyle = {
  borderColor: '#707070',
  backgroundColor: '#FFFFFF',
  color: '#544A54',
  textAlign: 'center',
  fontSize: 16,
  fontFamily: 'benton-sans',
  fontWeight: 600,
  height: 50,
  width: 160,
};

const buttonStyle = {
  // backgroundColor: '#486D90',
  borderRadius: 0,
  border: 'none',
  color: '#FFFFFF',
  textAlign: 'center',
  fontSize: 16,
  fontFamily: 'benton-sans',
  fontWeight: 600,
  height: 50,
  width: 160,
  position: 'relative',
};

const disabledButtonStyle = {
  backgroundColor: '#56565A',
  borderRadius: 0,
  border: 'none',
  color: '#FFFFFF',
  textAlign: 'center',
  font: '800 16px/20px yorkten-slab-normal',
  opacity: 1,
  height: 50,
  width: 160,
};

const backButtonStyle = {
  backgroundColor: 'none',
  border: 'none',
  color: '#004A98',
  fontSize: 16,
  fontWeight: 700,
  paddingLeft: 40,
  paddingTop: 2,
};

const saveButtonStyle = {
  backgroundColor: 'none',
  border: 'none',
  outline: 'none',
  color: '#004A98',
  fontWeight: 700,
  fontSize: 16,
  paddingLeft: 25,
};

const corner = {
  width: '0',
  height: '0',
  // borderBottom: "19px solid transparent",
  // borderRight: "19px solid #8CBF26",
  position: 'absolute',
  top: '0',
  right: '0',
};

const backButtonTextStyle = {
  position: 'relative',
  top: '2px',
};

const modalTitleStyle = {
  color: '#000000',
  fontSize: 26,
  textAlign: 'left',
  marginBottom: 30,
  fontFamily: 'benton-sans',
};

const modalBodyStyle = {
  color: 'rgba(73, 73, 74, 1)',
  fontSize: 13,
  textAlign: 'left',
  fontFamily: 'benton-sans',
  marginTop: 20,
};

const modalHeaderStyle = {
  border: 0,
  marginRight: 50,
  marginTop: 26,
};

const modalFooterStyle = {
  border: 0,
};

const popupModalStyle = {
  width: 600,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
};

const popupModalHeaderStyle = { borderBottom: 'none', marginLeft: '5%' };

const popupModalFooterStyle = { borderTop: 'none', justifyContent: 'center' };

const popupModalHeaderTextStyle = {
  font: '26px/36px benton-sans',
  color: '#000000',
};

const popupModalBodyStyle = { marginLeft: '5%' };

const popupModalBodyTextStyle = {
  font: '15px benton-sans',
  color: 'rgba(73, 73, 74, 1)',
};

const popupModalDivStyle = { width: 500, marginLeft: 35 };

const popupModalButtonTextStyle = {
  color: '#FFFFFF',
  font: 'Medium 14px benton-sans',
};

const popupModalButtonStyle = {
  backgroundColor: '#5D83AE',
  borderColor: '#5D83AE',
  marginLeft: 40,
};

const pdfModalStyle = {
  width: '100%',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  maxWidth: '100%',
  overflow: 'hidden',
};
const mobilePdfModalStyle = {
  width: 420,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  maxWidth: '100%',
  overflow: 'hidden',
};

const tabletPdfModalStyle = {
  width: 720,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  maxWidth: '100%',
  overflow: 'hidden',
};

const spinnerDiv = {
  position: 'fixed',
  height: '100%',
  width: '100%',
  background: 'rgba(0, 0, 0, 0.58)',
  zIndex: 999,
  top: '0px',
  left: '0px',
  paddingTop: 100,
};
export default {
  buttonStyle,
  outlineButtonStyle,
  corner,
  backButtonStyle,
  disabledButtonStyle,
  backButtonTextStyle,
  saveButtonStyle,
  modalBodyStyle,
  modalFooterStyle,
  modalHeaderStyle,
  modalTitleStyle,
  popupModalStyle,
  popupModalButtonStyle,
  popupModalButtonTextStyle,
  popupModalDivStyle,
  popupModalHeaderTextStyle,
  popupModalBodyTextStyle,
  popupModalBodyStyle,
  popupModalFooterStyle,
  popupModalHeaderStyle,
  pdfModalStyle,
  mobilePdfModalStyle,
  tabletPdfModalStyle,
  spinnerDiv,
};
