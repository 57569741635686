import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {purchaseActions } from 'shared/Actions';
import currencyFormatter from 'currency-formatter';
import { Accordian, AccordianTitle, FundAccountCard, WButton, WSpinner } from '../../../../common';
import * as TransactionConstant from '../../TransactionConstants';

const assignObj = obj => {
    return obj;
};

const EmptyAccountsP = styled.p`
    font: 500 16px/22px Benton Sans;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
`;

const BtnWrap = styled.div`
    display:Flex; 
    justify-content: flex-end;
    align-items:center;
    padding:20px 0;
    &>:nth-child(1){
        margin-right:50px;
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:2;
            margin-right:0;
            button{
                margin:0 !important;
            }            
        }
        &>:nth-child(2){
            order:1;
            width:100%;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;


const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};

class purchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountsAvailable: [],

            displayDetails: {
                accountSelection: {
                    data: [],
                },
            },
            accountSelectedType: null,
            accountSelected: null,
            isLoading: false,
            isError: false,
        };
        this.accountSelectionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, masterRegistrationAccountData, addressValid, showMasterRegForeignAddressModal } = nextProps;
        const { accountsAvailable } = prevState;
        if (!prevState.retrivedState && savedState) {
            if (addressValid === false && showMasterRegForeignAddressModal === true) {
                for (let i = 0; i < savedState.accountsAvailable.length; i += 1) {
                    for (let j = 0; j < savedState.accountsAvailable[parseInt(i, 10)].accounts.length; j += 1) {
                        savedState.accountsAvailable[parseInt(i, 10)].accounts[parseInt(j, 10)].checked = false;
                    }
                }
                return {
                    ...savedState,
                    retrivedState: true,
                    accountsAvailable: savedState.accountsAvailable.length
                        ? savedState.accountsAvailable
                        : masterRegistrationAccountData.masterRegAccountList,
                };
            }
            return {
                ...savedState,
                retrivedState: true,
                accountsAvailable: savedState.accountsAvailable.length
                    ? savedState.accountsAvailable
                    : masterRegistrationAccountData.masterRegAccountList,
            };
        }
        if (addressValid === false && showMasterRegForeignAddressModal === true) {
            for (let i = 0; i < accountsAvailable.length; i += 1) {
                for (let j = 0; j < accountsAvailable[parseInt(i, 10)].accounts.length; j += 1) {
                    accountsAvailable[parseInt(i, 10)].accounts[parseInt(j, 10)].checked = false;
                }
            }
            return {
                ...prevState,
                isLoading: masterRegistrationAccountData.isLoading,
                accountsAvailable: accountsAvailable.length
                    ? accountsAvailable
                    : masterRegistrationAccountData.masterRegAccountList,
            };
        }

        return {
            ...prevState,
            isLoading: masterRegistrationAccountData.isLoading,
            accountsAvailable: accountsAvailable.length
                ? accountsAvailable
                : masterRegistrationAccountData.masterRegAccountList,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: TransactionConstant.BEHVIOUR_SMOOTH_VALUE,
        });
        const { editSection, getAccountsList, savedState, transactionType } = this.props;
        const { current } = this.accountSelectionRef;
        const { offsetTop = 0 } = current || {};
        sessionStorage.setItem(TransactionConstant.STEPNAME_VALUE, TransactionConstant.ACCOUNT_SELECTION_TEXT);
        if (!savedState) {
            getAccountsList(
                {
                    companyNumber: TransactionConstant.USAA_FUND_COMPANY_NUMBER.companyNumber,
                    token: localStorage.getItem('token'),
                },
                true,
                transactionType,
            );
        }
        if (editSection === TransactionConstant.ACCOUNT_SELECTION_TEXT) {
            window.scrollTo({
                top: offsetTop,
                behavior: TransactionConstant.BEHVIOUR_SMOOTH_VALUE,
            });
        }
    }

    accountClickHandler = (accounttypeindex, accountindex) => e => {
        e.stopPropagation();
        this.setState(prevState => {
            const { accountsAvailable, displayDetails } = prevState;
            const { accountSelection } = displayDetails;
            let accountSelected = null;
            const accountSelectedType = {};
            const displayData = [];

            const allAccounts = accountsAvailable.map((item, acctypeindex) => {
                const accType = { ...item };
                const { accounts } = accType;
                accType.accounts = accounts.map((eachacc, accindex) => {
                    const acc = { ...eachacc };
                    if (accounttypeindex === acctypeindex && accountindex === accindex) {
                        acc.checked = !acc.checked;
                    } else {
                        acc.checked = false;
                    }
                    if (acc.checked) {
                        accountSelected = acc;
                        const accountNameDisplay =
                            !acc.accountName || acc.accountName.trim() === ''
                                ? `${!acc.accountType ? '' : acc.accountType} Account ${acc.accountNumber}`
                                : acc.accountName;
                        displayData.push(
                            {
                                field: TransactionConstant.ACCOUNT_NAME_TEXT,
                                // value: acc.accountName,
                                value: accountNameDisplay,
                            },
                            {
                                field: TransactionConstant.ACCOUNT_NUMBER_TEXT,
                                value: acc.accountNumber,
                            },
                            {
                                field: TransactionConstant.ACCOUNT_TYPE_TEXT,
                                value: acc.accountType,
                            },
                        );
                    }
                    return acc;
                });
                return accType;
            });
            accountSelection.data = displayData;
            // TODO :: Add account type to accountSelectedType for furture use and set it in state
            accountSelectedType.holdingGroup = accountsAvailable[parseInt(accounttypeindex, 10)].holdingGroupName;
            return {
                accountsAvailable: allAccounts,
                accountSelected,
                displayDetails,
                accountSelectedType,
            };
        });
    };

    bindComponent() {
        const { accountsAvailable, isLoading } = this.state;
        let cardDetails;
        return (
            <>
                {!isLoading && accountsAvailable.length === 0 && (
                    <>
                        <EmptyAccountsP>{TransactionConstant.NO_ACCOUNTS_MESSAGE}</EmptyAccountsP>
                    </>
                )}
                {accountsAvailable.map((item, acctypeindex) => (
                    <Accordian
                        ref={this.accountSelectionRef}
                        key={item.holdingGroupName}
                        aria-controls={item.holdingGroupName}
                        role="radiogroup"
                        titleChild={
                            <AccordianTitle
                                accType={item.holdingGroupName}
                                id={item.holdingGroupName + acctypeindex}
                                accIndex={acctypeindex}
                            />
                        }
                        titleSeperator
                        labelledBy={item.holdingGroupName + acctypeindex}
                        Id={item.holdingGroupName}
                    >
                        <div>
                            { item.accounts.map((eachacc, accindex) => {
                                cardDetails = [];
                                cardDetails.push({
                                    text: TransactionConstant.CURRENT_VAL_TEXT,
                                    value: `${
                                        eachacc.currentAccountBalance
                                            ? currencyFormatter.format(eachacc.currentAccountBalance.toFixed(2), {
                                                  locale: 'en-US',
                                              })
                                            : '0.00'
                                    }`,
                                });
                                cardDetails.push({
                                    text: TransactionConstant.TOTAL_SHARES_TEXT,
                                    value: Number(eachacc.totalShares ? eachacc.totalShares : '0.00').toFixed(2),
                                });
                                cardDetails.push({
                                    text: TransactionConstant.AIP_TEXT,
                                    value:
                                        eachacc.pad === 'N'
                                            ? TransactionConstant.NO_TEXT
                                            : TransactionConstant.YES_TEXT,
                                });
                                return (
                                    <FundAccountCard
                                        key={eachacc.accountNumber}
                                        accountName={
                                            !eachacc.accountName || eachacc.accountName === ''
                                                ? `${!eachacc.accountType ? '' : eachacc.accountType} Account ${
                                                      eachacc.accountNumber
                                                  }`
                                                : eachacc.accountName
                                        }
                                        accountNumber={eachacc.accountNumber}
                                        onClick={this.accountClickHandler(acctypeindex, accindex)}
                                        selected={!!eachacc.checked}
                                        details={cardDetails}
                                        data-test="account-card"
                                        id={item.holdingGroupName}
                                        aria-labelledby={item.holdingGroupName}
                                    />
                                );
                            })}
                        </div>
                    </Accordian>
                ))}
            </>
        );
    }

    render() {
        const { accountSelected, isLoading } = this.state;
        const {
            onSwitchComponent,
            handleError,
            masterRegistrationAccountData,
            pageIndex,
            setCancelPurchase,
        } = this.props;

        if (masterRegistrationAccountData.isError) {
            handleError(true);
        }

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <div className="container">
                    <div id="#currentPageName" pagename="Account Selection" data-test="purchase-component">
                        {this.bindComponent()}
                    </div>
                </div>


<MainDiv>
                    <BtnWrap className="container">
                        <div>
                        <WButton
                            variant="cancel"
                            buttontext="Cancel"
                            id="cancelButton"
                            buttonstyle={assignObj(cancelButtonStyles)}
                            onClick={setCancelPurchase}
                        />
                        </div>
                        <div>
                        <WButton
                                    disabled={!accountSelected}
                                    buttontext={TransactionConstant.NEXT_TEXT}
                                    onClick={onSwitchComponent(true, this.state, pageIndex)}
                                    data-test="next-button"
                                    role="button"
                                />
                        </div>
                    </BtnWrap>
                </MainDiv>

            </>
        );
    }
}

export const mapStateToProps = state => {
    return {
        masterRegistrationAccountData: state.masterRegistrationAccountData,
        purchaseData: state.purchaseData,
    };
};

export const mapDispatchToProps = {
};
purchase.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    onSwitchComponent: PropTypes.func,
    pageIndex: PropTypes.number,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    masterRegistrationAccountData: PropTypes.instanceOf(Object),
    getAccountsList: PropTypes.func,
    handleError: PropTypes.func,
    transactionType: PropTypes.string,
    setCancelPurchase: PropTypes.func,
    addressValid: PropTypes.bool,
    showMasterRegForeignAddressModal: PropTypes.bool,
};
purchase.defaultProps = {
    onSwitchComponent: () => {},
    pageIndex: 0,
    savedState: null,
    editSection: null,
    masterRegistrationAccountData: {},
    getAccountsList: () => {},
    handleError: () => {},
    transactionType: 'purchase',
    setCancelPurchase: () => {},
    addressValid: true,
    showMasterRegForeignAddressModal: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(purchase);
