/**
 * Automatic Investment Plans
 ********************************
 *
 * @version 1.0.0
 * @author Dinesh A
 * @description Automatic Investment Plans and Systematic Withdrawal are displayed and managed here
 * @createdDate [10/12/2019]
 * ***************************************
 */


import { 
    ACCOUNT_SELECTION_PAGE_NAME, 
    VERIFY_PAGE_NAME, 
    AIP_VERIFY_ADD_BUTTON, 
    SWP_VERIFY_ADD_BUTTON,
    ACCOUNT_SERVICE_TYPE_SWP,
    ACCOUNT_FLOW_TYPE_ADD,
    BREAD_CRUMB_SCREEN_AIPADD,
    BREAD_CRUMB_SCREEN_SWPADD,
    ACCOUNT_SERVICE_TYPE_AIP,
    AIP_SWP_STEP_NAME_FREQUENCY, 
    AIP_SWP_STEP_NAME_PLAN_DETAILS, 
    AIP_SWP_STEP_NAME_SELECT_ACCOUNT, 
    AIP_SWP_STEP_NAME_VERIFY, 
    STEP_KEY, 
    AIP_SWP_STEP_NAME_SCHEDULE,
    AIP_NAV_BAR_IDS, SWP_NAV_BAR_IDS
} from '../../../../utils/AdobeAnalytics/Constants';
import CompareFundsComponent from 'modules/AccountManagementModule/CompareFunds';
import constants, { PAY_OPTIONS, GET_ACCOUNT_LIST_CONFIG_KEY_BY_PLAN } from 'modules/AccountOptions/constant';
import { createBreadCrumbItem, getMemberRoleFlag } from 'utils/utils';
import FundSummaryComponent from 'modules/AccountManagementModule/FundSummary';
import { get } from 'lodash';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import PlanDetails from '../PlanDetails';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RECURRING_TRANSFER_PLAN,AIP } from '../../constant';
import routeConstants from 'routeConstants';
import routesBreadCrumb from '../../../../utils/Constants/routesBreadcrumb';
import Schedule from '../Schedule';
import styled from 'styled-components';
import { WBreadCrumb, PageHeading, WSpinner, AccountSelection, VerifyDetails, WStepper, WMessageBar, ConformationModal, ConfirmModal } from 'common';
import { getToken} from 'utils';
import * as TransactionConstants from '../../../TransactionModule/TransactionConstants';
import WStepperMobile from '../../../../common/WStepper/WStepperMobile';
import './CreatePlanComponent.css';
import axios from 'axios'
import { isTokenAvailable } from 'common/Authenticate/session';
import Authenticate from 'common/Authenticate/Authenticate';
import { removeComma } from 'common/Formatter/CurrencyFormatter';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
const SWPPages = ['Account Selection', 'Plan Details', 'Schedule', 'Verify'];
const AIPPages = ['Account Selection', 'Plan Details', 'Schedule', 'Verify'];

const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 2.5rem 0;
`;
const Stepper = styled(WStepper)`
    margin-bottom: 40px;
`;
const cancelButtonStyles = {
    float: "right"
};
const usStatesAlphaCodes = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
'AA', 'AE', 'AP' ];

class CreateInvestmentOrWithdrawalPlan extends Component {
    constructor(props) {
        super(props);
        const plan = this.getPlanFromRoute();
        const { location } = this.props;
        const { accountSelected = null } = location.state;

        this.state = {
            coverdellLoad:false,
            coverdellNotificationMsg:'',
            coverdellFalse:false,
            iraAccepted:true,
            next:false,
            validFlag:true,
            currentSelectedAccount:[],
            flag:false,
            iraContributionWarning: '',
            selectedStartDate:'',
            selectedFrequency:'M',
            isError:false,
            isFormAband: true,
            pageIndex: 0,
            defaultAccountSelected: accountSelected,
            childrenState: [],
            showCompareFundsScreen: false,
            showFundSummaryScreen: false,
            selectedFunds: [],
            fundDetail: {},
            isEdit: false,
            plan,
            analitycsDigitalData:{
                accountOptions: {
                    accountservicetype: plan === RECURRING_TRANSFER_PLAN.AIP 
                        ? ACCOUNT_SERVICE_TYPE_AIP
                        : ACCOUNT_SERVICE_TYPE_SWP,
                    accountflowtype: ACCOUNT_FLOW_TYPE_ADD,
                    dividendsandpreferrencetype:'',
                    investmentplanamount:'' 
                }
            },
            isEditFromVerifyPage: true,
            showAuthBox : false,
            checkFisError :  false
        };
        
        this.editSection = null;
        this.bottomNav = React.createRef();
        this.breadCrumbItems = this.getBreadCrumbItems();
    }

    componentDidMount() {
        const { getBankAccountInfo,getTradingClosureWindow} = this.props;
        getTradingClosureWindow({ token: localStorage.getItem('token') });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { pageIndex } = this.state;
        this.setSessionStorageStepName(pageIndex);

        const params = getSessionValues(); 
        const {accesstoken, vcmid} = params;
        getBankAccountInfo({ token: accesstoken, vcmid });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.purchaseReducerData.iraContributionWarning != ''){
            return{
                ...prevState,
                iraContributionWarning:nextProps.purchaseReducerData.iraContributionWarning,
                load:false,
                iraAccepted:nextProps.purchaseReducerData.isIRAContributionAccepted,
                isError:nextProps.purchaseReducerData.isError
            }
        }else{
            return{
                ...prevState,
                iraContributionWarning:nextProps.purchaseReducerData.iraContributionWarning,
                iraAccepted:nextProps.purchaseReducerData.isIRAContributionAccepted,
                isError:nextProps.purchaseReducerData.isError
            }
        }
    }


    moveNextIndexForLowIra = ()=>{

        this.setState(prevState => {
        
            return {
                ...prevState,
                pageIndex: prevState.pageIndex + 1,
                iraAccepted:false,
                next:false,
            };
        });
     
        this.handleCancel()
    }

    componentDidUpdate(prevProps) {
        const { clearFundAccountList, fundAccountData, clearBankAccDetails, bankAccountReducerData, isSuccessfullySaved} = this.props;
        const { bankDetails = [] } = bankAccountReducerData;
        const { fundAccountList = [] } = fundAccountData;
        const {iraAccepted,iraContributionWarning,pageIndex,next} = this.state

        if(!!(iraAccepted) && iraContributionWarning != '' && pageIndex === 2 && prevProps.purchaseReducerData.isIRAContributionAccepted === true && next === true){
            this.moveNextIndexForLowIra()
        }

        if ( isSuccessfullySaved && prevProps.isSuccessfullySaved !== isSuccessfullySaved ) {
            if (fundAccountList.length) {
                clearFundAccountList();
            }
            if (bankDetails.length) {
                clearBankAccDetails();
            }

            this.onSubmit();
        }
    }

    componentWillUnmount(){
        const { plan } = this.state;
        const { clearFundAccountList, clearBankAccDetails, analyzeFormAdandonment, dismissAIPError, dismissSWPError } = this.props;
        const {isFormAband} = this.state;
        clearFundAccountList();
        clearBankAccDetails();
        if(plan === RECURRING_TRANSFER_PLAN.AIP){
            dismissAIPError();
        }else {
            dismissSWPError();
        }
      
        if( isFormAband ){
            const digitalData = getDigitalDataFormAband();
            analyzeFormAdandonment(digitalData);
        } 
    }

    setSessionStorageStepName = (pageIndex) => {
        const { plan } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;

        switch ( pageIndex){
            case 0 :{
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_SELECT_ACCOUNT); 
                break;
            }
            case 1: {
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_PLAN_DETAILS); 
                break;
            } 
            case 2: {
                if ( isAIPPlan){
                    sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_SCHEDULE); 
                }else{
                    sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_FREQUENCY); 
                }
                break;
            }
            case 3: {
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_VERIFY); 
                break;
            } 
            default :
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_SELECT_ACCOUNT); 
        }
    }
    
    getAnalyticsIdForNavBarButton = (stepID) => {
        const {plan} = this.state;
        const isAIPPlan = (plan === RECURRING_TRANSFER_PLAN.AIP);
        return  isAIPPlan 
            ? AIP_NAV_BAR_IDS.add[Number(stepID)]
            : SWP_NAV_BAR_IDS.add[Number(stepID)];
    }

    getBreadCrumbItems = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyState = {
            state: {
                accountType,
                accountOwnerDesc,
            },
        };
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: {
                state: {
                    ...historyState.state,
                    name: accountName,
                    masterId: masterRegId,
                }
            },
        };
        const { plan } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const bradcrumbPaths = [
            routeConstants.accountholder,
            routeConstants.accountservices,
            isAIPPlan ? routeConstants.automaticinvestment : routeConstants.systematicwithdrawal,
        ];
        const analyticsIDButtonScreen = isAIPPlan ? BREAD_CRUMB_SCREEN_AIPADD : BREAD_CRUMB_SCREEN_SWPADD;
        const breadCrumbItems = bradcrumbPaths.map(route => createBreadCrumbItem(route, analyticsIDButtonScreen));
        if (masterRegId) {
            breadCrumbItems.splice(1, 1, intermediateRoute);
            breadCrumbItems.splice(2, 1, {
                ...breadCrumbItems[2],
                payload: {
                    state: {
                        ...historyState.state,
                        masterRegId,
                        accountName,
                    }                    
                },
            });
        }
        return breadCrumbItems;
    }

    saveChildrenState = (data, index) => {
        this.setState(prevState => {
            const { childrenState } = prevState;
            childrenState[+index] = data;
            let  frequency = prevState.analitycsDigitalData?.accountOptions?.dividendsandpreferrencetype
            if (index === 2){  
                frequency = data.frequencyText
            }
            return {
                childrenState,
                analitycsDigitalData:{
                    accountOptions:{
                        ...prevState.analitycsDigitalData.accountOptions,
                        investmentplanamount: 
                            index === 1 
                                ? data.totalAmount 
                                : prevState.analitycsDigitalData?.accountOptions?.investmentplanamount,            
                        dividendsandpreferrencetype: frequency
                    }
                }
            };
        });
    };

    closeModel = ()=>{
        this.setState({coverdellFalse:false,coverdellNotificationMsg:''})
    }

    fetchData =  async (stateValue) =>{

        const payload = {
            masterRegId:`${stateValue.masterRegistrationID}`,
            // masterRegId:`5695505`,
            companyNumber:'591'    
        }
        
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': `${envConfig.API_KEY}`
        }
        this.setState({ coverdellLoad:true })
        try{
            const response = await axios.post(`${envConfig.API_SUB_DOMAIN}/trance/msr/accountOwner/age`,payload,{headers:headers})
            if(response.data && response.data.account === false && response.data.age != ''){
                // AGE > 18, HARD STOP
                let message = response.data.custMessage
                // let message = 'Account Beneficiary for this Coverdell account is over 18. No contributions can be made into this account.'
                this.setState({ coverdellLoad:false, coverdellFalse:true,coverdellNotificationMsg:message})
            }
            else if(response.data && response.data.account === true && response.data.age != ''){
                this.setState({ coverdellLoad:false })
                //   run validate ira api if ture i,e AGE is < 18
                this.handleIRAContribution(stateValue);
            }else{
                this.setState({coverdellLoad:false, coverdellFalse:true,coverdellNotificationMsg:TransactionConstants.TRANSACTION_STANDARD_ERROR_MESSAGE})
            }
        }

        catch(error){ 
            this.setState({coverdellLoad:false, coverdellFalse:true,coverdellNotificationMsg:TransactionConstants.TRANSACTION_STANDARD_ERROR_MESSAGE})
        }
    }

    onSwitchComponent = (bool,state) => () => {
        const selected = this.state.currentSelectedAccount;

        if(this.state.currentSelectedAccount != undefined && state !=undefined)
        {
            if(this.state.currentSelectedAccount.accountNumber != state.accountSelected.accountNumber )
            {
                this.state.childrenState[1].selectedFunds = [];
            }
        }
        this.setState(prevState => {
            if(prevState.pageIndex === 2 && this.state.plan ===RECURRING_TRANSFER_PLAN.AIP && this.getIsIRA()){
                if(bool){
                    
                    if (selected.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA)) {
                        this.fetchData(selected)
                        return {pageIndex: prevState.pageIndex,next:true}
                    }else{
                        this.handleIRAContribution(selected)
                        return {pageIndex: prevState.pageIndex,next:true}
                    }
                }else{
                    return {pageIndex: prevState.pageIndex - 1}
                }
     
            }else{
                return {
                    pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
                };
            }
        });
    };

    onCancel = () => {
       
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyObj = {};
        const { plan } = this.state;
        const route =
            plan === RECURRING_TRANSFER_PLAN.AIP
                ? routeConstants.automaticinvestment
                : routeConstants.systematicwithdrawal;
        historyObj.pathname = route;
        if (masterRegId)
            historyObj.state = {
                masterRegId,
                accountName,
                accountType,
                accountOwnerDesc,
            };
        history.push(historyObj);
    };

    onSubmit = () => {
        const { history, location, responseMessage } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const { plan } = this.state;
        const historyObj = {};
        historyObj.pathname =
            plan === RECURRING_TRANSFER_PLAN.AIP
                ? routeConstants.automaticinvestment
                : routeConstants.systematicwithdrawal;
        historyObj.state = {
            accAdded: true,
            eventMessage: responseMessage || '',
            stepName: 'Add',
        };
        if (masterRegId) {
            historyObj.state = {
                ...historyObj.state,
                masterRegId,
                accountName,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    };

    scrollToSection = (index, section) => () => {
        this.editSection = section;
        this.setState(
            () => {
                return {
                    pageIndex: index,
                    isEditFromVerifyPage: true,
                };
            },
            () => {
                this.editSection = null;
            },
        );
        const {analyzeClickEvent} = this.props;
        const { plan } = this.state;
        analyzeClickEvent({
            link: `${plan === RECURRING_TRANSFER_PLAN.AIP 
                ? AIP_VERIFY_ADD_BUTTON
                : SWP_VERIFY_ADD_BUTTON }_${section.replace(' ', '').toLowerCase()}`,
        })
    };

    switchToCompareFundsScreen = selectedFunds => {
        this.setState(() => {
            return {
                showCompareFundsScreen: true,
                selectedFunds,
            };
        });
    };

    switchToFundSummaryScreen = fundDetail => {
        this.setState(() => {
            return {
                showFundSummaryScreen: true,
                fundDetail,
            };
        });
    };

    backToPlanDetails = () => {
       this.setState(() => {
            return {
                showCompareFundsScreen: false,
                showFundSummaryScreen: false,
            };
        });
    };

    getFundAccountList = () => {
        const { getAccountList } = this.props;
        const { plan } = this.state;
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId } = state || {};

        getAccountList({ configURLKey: GET_ACCOUNT_LIST_CONFIG_KEY_BY_PLAN[`${plan}`], masterRegId});
    };

    createPlan = () => {

        if( isTokenAvailable() === null)
        {
            this.setState({showAuthBox:true})
        }
        else
        {
           this.moveToNextStep();
        }
    }

    getDateAndFrequency = (val) => {
        if(['A','Q','M','S'].includes(val)){
            this.setState({selectedFrequency:val})
        }else{
            this.setState({selectedStartDate:val})
        }
        
    }

    
    assignPaymentType = (offlineMode) => {
        let paymentType;

        if(offlineMode === PAY_OPTIONS.CHECK_ORDER){
            paymentType = '01'
        } else if(offlineMode === PAY_OPTIONS.DIRECT_SWP) {
            paymentType = '09'
        } else {
            paymentType = '05'
        }

        return paymentType
    }
    

    getPlanDetails = () => {
        const { rmdReducerData } = this.props
        const { plan, childrenState } = this.state;
        const { addbankvisible, addbankState, investFundDetails = {}} = childrenState[1];
        const { investToFundData = {} } = investFundDetails
        const { state = {} } = investToFundData
        const { investToFundNo = '' , investToFundAccNo = '' } = state;

        let selectedSharesDataPayload = [];

        let selectedFundListData = childrenState[1].selectedFunds.filter(item => item?.selected);

        for(const element of selectedFundListData)
        {
            selectedSharesDataPayload.push({
                "achFlag" : element?.achFlag,
                "amountValue": removeComma(element.amt),
                "dollarAmount": removeComma(element.amt),
                "fedTax": element?.fedTax,
                "fundAccountNumber": element?.fundAccountNumber,
                "fundNumber": element?.fundNumber,
                "stateTax": element?.stateTax
            })
        }
        
        let bankDetails;

        if (addbankvisible) {
            const { otherBankState } = addbankState;
            const { bankAccountDetails, bankAccountType } = otherBankState || {};
            const [accountOwner, newBankName] = bankAccountDetails || [];

            const newBankNumbervalue = bankAccountDetails.filter(item => { return item.name === "accountNumber"}).map( item => item.value)[0] ?? ''
            const newBankTransitRoutingNumbervalue = bankAccountDetails.filter(item => { return item.name === "transitRoutingNumber"}).map( item => item.value)[0] ?? ''

            const { value: newBankNamevalue = '' } = newBankName || {};
            const { value: newAccountOwnervalue = '' } = accountOwner || {};

            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                bankDetails = {
                    isNewBank: true,
                    bankAccountType: bankAccountType,
                    bankAccountNumber: newBankNumbervalue,
                    bankRoutingNumber: newBankTransitRoutingNumbervalue,
                    bankName: newBankNamevalue,
                    accountOwnersName: newAccountOwnervalue
                }
            } else {
                bankDetails = {
                    isNewBank: true,
                    accountType: bankAccountType,
                    bankAccountNumber: newBankNumbervalue,
                    transitRoutingNumber: newBankTransitRoutingNumbervalue,
                    payorName: newBankNamevalue,
                    accountOwnersName: newAccountOwnervalue
                }
            }
        } else {
            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                const { bankDetailsSectionState} = childrenState[1];
                const { selectedBank } = bankDetailsSectionState;
                bankDetails = {
                    ...selectedBank
                };
            } else {
                bankDetails = this.getBankDetailsSWP();
            }
        }

        let planDetails = {};
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            const frequencyData = childrenState[2];
            planDetails = {
                ...bankDetails,
                frequency: frequencyData.investVal,
                initialDate: frequencyData.startDate,
                nextInvestmentDate: frequencyData.nextInvestment,
                fundsList: selectedSharesDataPayload
            }
        } else {
            const {offlineMode, taxWithHoldState, deliveryAddress, totalAmount } = childrenState[1];
            const isIraAccount = get(taxWithHoldState, 'isIraAccount', false)
            let originalTaxes;
            let swpWithholdCode;
            let taxDetails
            const fundsList = selectedSharesDataPayload;
            let paymentType = this.assignPaymentType(offlineMode);
            
            if (isIraAccount) {
                const fedTaxPercent = get(taxWithHoldState, 'tax.fedTaxPercent', '0.00');
                const stateTaxPercent = get(taxWithHoldState, 'tax.stateTaxPercent', '0.00');
                originalTaxes = selectedSharesDataPayload.map(item => ({
                    fundAccountNumber: item.fundAccountNumber,
                    fedTax: item.fedTax,
                    stateTax: item.stateTax
                }))
                const taxBasis = get(rmdReducerData, 'groupStateTax.basis', '');
                const stateTax = get(rmdReducerData, 'groupStateTax.stateTax', '');
                const stateTaxPer = (Number(stateTax) * 100) / Number(totalAmount);
                taxDetails = {
                    fedTax: fedTaxPercent,
                    stateTax: taxBasis === 'FWH' ? stateTaxPer.toFixed(2) : stateTaxPercent,
                    groupStateTax: get(rmdReducerData, 'stateCodeGroup[0]', 'group1')
                }

                swpWithholdCode = +taxDetails.stateTax ? '' : 'W'; // '' W - not withholding, ''- withholding tax 
            }

            planDetails = {
                ...bankDetails,
                ...this.getScheduleDataSWP(),
                originalTaxes,
                fundsList,
                isIraAccount,
                ...isIraAccount && taxDetails,
                ...(offlineMode === PAY_OPTIONS.CHECK_ORDER) && { address: {
                    addressLine1: deliveryAddress.accountregistrationLine2,
                    addressLine2: deliveryAddress.accountRegistrationline3,
                    addressLine3: deliveryAddress.accountRegistrationline4,
                    addressLine4: deliveryAddress.accountRegistrationline5,
                    addressLine5: deliveryAddress.accountRegistrationline6,
                    city: deliveryAddress.accountRegistrationCity,
                    zipCode: deliveryAddress.accountRegistrationZip,
                    residenceCode: deliveryAddress.accountRegistrationIdRes,
                } },
                ...(offlineMode === PAY_OPTIONS.DIRECT_SWP) && {
                    toFundNumber: investToFundNo,
                    toFundAccountNumber: investToFundAccNo,

                },
                swpWithholdCode,
                paymentMethod: paymentType,// 05 - Bank, 01- check, 09 - invest another fund
            }
        }

        return planDetails;
    }

    getBankDetailsSWP = () => {
        const { childrenState } = this.state;
        const selectedBank = get(childrenState[1], 'bankDetailsSectionState.selectedBank', {});
        if (selectedBank){
            return {
                bankAccountNumber: selectedBank.bankAccountNumber,
                transitRoutingNumber: selectedBank.bankRoutingNumber,
                accountType: selectedBank.bankAccountType,
                payorName: selectedBank.bankName,
                salesforceRecordId: selectedBank.salesforceRecordId,
            }
        }
        return {}
    }
    
    getScheduleDataSWP = () => {
        const { childrenState } = this.state;
        const frequencyData = childrenState[2];
        const investVal = get(frequencyData, 'investVal', 'M');
        return  {
            frequency: investVal,
            initialPayDay: frequencyData.startDate,
            expirationDate: frequencyData.endDate,
            nextPaymentDate: frequencyData.nextInvestment,
        }
    }

    switchComponent = () => {
        const { pageIndex, showCompareFundsScreen, showFundSummaryScreen, selectedFunds, fundDetail, plan } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const activeCrumb = isAIPPlan  
                ? routesBreadCrumb.get(routeConstants.createAutomaticInvestment) 
                : routesBreadCrumb.get(routeConstants.createSystematicWithdrawal);
        const contentData = isAIPPlan 
            ? constants.AIP 
            : constants.SWP;
        const pages = isAIPPlan 
            ? AIPPages 
            : SWPPages;
        if (showFundSummaryScreen) {
            return <FundSummaryComponent fundDetail={fundDetail} backTo={this.backToPlanDetails} />;
        }

        if (showCompareFundsScreen) {
            return <CompareFundsComponent selectedFunds={selectedFunds} backTo={this.backToPlanDetails} />;
        }

        return (
            <div className="mobile-cont">
                <div className="overRightParentPadding">
                    <WStepperMobile currentPage={pageIndex} pages={pages} />
                </div>
                
                <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={this.breadCrumbItems} />
                <FlexDiv>
                    <PageHeading>{contentData.pageHeading1}</PageHeading>
                </FlexDiv>
                <Stepper style={stepperStyle} currentPage={pageIndex} pages={pages} className="create-plan-stepper desktopStepper" />

                {this.bindComponent()}
            </div>
        );
    };

    
    onNotificationClose = (isClosedByTimer) => {
        const { dismissAIPError, dismissSWPError, analyzeClickEvent } = this.props;
        const { plan, pageIndex } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        this.setSessionStorageStepName(pageIndex);
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            dismissAIPError();
        } else {
            dismissSWPError();
        }
        if ( !isClosedByTimer){
            analyzeClickEvent({
                link: `${isAIPPlan 
                    ? 'accountservices_aip'
                    : 'accountservices_swp'}_notification_error_close`
            });
        } 
    }

    getPlanFromRoute = () => {
        const { location } = this.props;
        const pathname = get(location, 'pathname', '');
        return ( pathname === routeConstants.createAutomaticInvestment) 
            ? RECURRING_TRANSFER_PLAN.AIP 
            : RECURRING_TRANSFER_PLAN.SWP;
    }

    displayError = errorsData => {
        let message = ''

        if (errorsData.result) {
            message = get(errorsData, 'result.exceptionDetails.logMessage', '')
        } else if (errorsData.message === 'Network Error') { // use default error message
            message = ''
        }else{
            message = errorsData.message
        }
        return { message }
    }

    currr = (accountSelectedd)=>{
        this.state.currentSelectedAccount=accountSelectedd
        // this.setState({currentSelectedAccount:accountSelectedd})
    }

    bindComponent() {
        const { pageIndex, childrenState, 
            isEdit, plan, 
            analitycsDigitalData, defaultAccountSelected, isEditFromVerifyPage } = this.state;
        const {
            accOpeningReducerData,
            getFundListData,
            getBankAccountInfo,
            bankAccountReducerData,
            fundAccountData,
            // analyzeFormAdandonment
            rmdReducerData,
            analyzeFormFieldError,
            tradingWindowData
        } = this.props;
        const isAccountServicesFlow = true;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        let { fundAccountList } =  fundAccountData || [];
        const [accountSelectionState] = childrenState;
        const { accountSelected } = accountSelectionState || {};
        const navigationObj = {
            onCancel: this.onCancel,
            onPrimaryClick: this.onSwitchComponent(true),
            onSecondaryClick: this.onSwitchComponent(false),
            portalRoot: this.bottomNav,
            iscancel: true,
            cancelClick: this.onCancel,
            cancelOffset: 2,
            cancelStyle: cancelButtonStyles
        };

        fundAccountList.forEach(fundAccount => {
            fundAccount.accounts.forEach((account, index) => {
                if (!usStatesAlphaCodes.includes(account.address.accountRegistrationIdRes)) {
                    fundAccount.accounts.splice(index, 1)
                }
            })
        });

        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId } = state || {};

        if (masterRegId) {
            let filteredAccounts = null;
            const filteredFundAccountList = fundAccountList.filter(accountType => {
                const accountFiltered = accountType.accounts.filter(
                    account => Number(account.accountNumber) === Number(masterRegId),
                );
                if (accountFiltered.length) filteredAccounts = accountFiltered;
                return accountFiltered.length;
            });
            if (filteredAccounts) filteredFundAccountList[0].accounts = filteredAccounts;
            fundAccountList = filteredFundAccountList;
        }

        this.setSessionStorageStepName(pageIndex);

        const navBarAnalyticsID = this.getAnalyticsIdForNavBarButton(pageIndex);

        this.currr(accountSelected)
        // this.setState.currentSelectedAccount=accountSelected

        switch (pageIndex) {
            case 0: {
                const content = isAIPPlan
                    ? constants.accountsAvailableForAIPNew
                    : constants.accountsAvailableForSWPNew;
                return (
                    <AccountSelection
                        accountSelected={defaultAccountSelected}
                        savedState={childrenState[+pageIndex]}
                        saveChildrenState={this.saveChildrenState}
                        navigationObj={navigationObj}
                        pageIndex={pageIndex}
                        editSection={this.editSection}
                        accountDetails={fundAccountList}
                        content={content}
                        getAccountsList={this.getFundAccountList}
                        onSwitchComponent={this.onSwitchComponent}
                        setCancelLiquidation={this.onCancel}
                        dataAnalyticsPage={isAIPPlan ? `${ACCOUNT_SELECTION_PAGE_NAME}: Add AIP | Victory Capital`: `${ACCOUNT_SELECTION_PAGE_NAME}: Add SWP | Victory Capital`}
                        isAccountServicesFlow = {isAccountServicesFlow}
                        dataAnalyticId={navBarAnalyticsID}
                        flowType={AIP}
                        hasBottomNavCancel
                        hasMask
                    />
                );
            }
            case 1: {
                const planDetailsContent = isAIPPlan 
                    ? constants.PlanDetailsAIPContent 
                    : constants.PlanDetailsSWPContent;
                const planInfo = {planInfo: {...accountSelected}};
                const groupStateTax = get(rmdReducerData, 'groupStateTax', '');
                return ( accountSelected &&
                    <PlanDetails
                        accDetails={accountSelected}
                        accOpeningReducerData={accOpeningReducerData}
                        getFundListData={getFundListData}
                        fundAccountData={fundAccountData}
                        bankAccountInfo={bankAccountReducerData}
                        getBankAccountInfo={getBankAccountInfo}
                        savedState={childrenState[+pageIndex]}
                        saveChildrenState={this.saveChildrenState}
                        navigationObj={navigationObj}
                        pageIndex={pageIndex}
                        editSection={this.editSection}
                        plan={plan}
                        switchToCompareFundsScreen={this.switchToCompareFundsScreen}
                        switchToFundSummaryScreen={this.switchToFundSummaryScreen}
                        contents={planDetailsContent}
                        isEditMode={isEdit}
                        onCancel={this.onCancel}
                        rmdReducerData = {rmdReducerData}
                        navBarAnalyticsID={navBarAnalyticsID}
                        analyzeFormFieldError={analyzeFormFieldError}
                        fundPlanData={planInfo}
                        groupStateTax={groupStateTax}
                        isEditFromVerifyPage={isEditFromVerifyPage}
                    />
                );
            }
            case 2: {
                const ScheduleContents = isAIPPlan 
                    ? constants.ScheduleAIPContent 
                    : constants.ScheduleSWPContent;
                return (
                    <Schedule
                        getDateAndFrequency={this.getDateAndFrequency}                        
                        accDetails={accountSelected}
                        savedState={childrenState[+pageIndex]}
                        saveChildrenState={this.saveChildrenState}
                        navigationObj={navigationObj}
                        pageIndex={pageIndex}
                        editSection={this.editSection}
                        plan={plan}
                        contents={ScheduleContents}
                        onCancel={this.onCancel}
                        isEdit={isEdit}
                        navBarAnalyticsID={navBarAnalyticsID}
                        analyzeFormFieldError={analyzeFormFieldError}
                        tradingWindowApiData = {tradingWindowData}
                    />
                );
            }
            case 3: {
                navigationObj.primaryText = 'Submit';
                navigationObj.analyzecustom = analitycsDigitalData;
                const mergeDetails = childrenState
                    .map(item => item.displayDetails)
                    .reduce((acc, curr) => {
                        return { ...acc, ...curr };
                    });
                const verifyDetails = Array.from(Object.keys(mergeDetails), key => mergeDetails[key.toString()]);
                return (
                    <VerifyDetails
                        verifyDetails={verifyDetails}
                        navigationObj={navigationObj}
                        scrollToSection={this.scrollToSection}
                        handleSubmit={() => this.createPlan(verifyDetails)} 
                        tradingWindowData={{canTrade: true}}
                        setCancelLiquidation={this.onCancel}
                        pageName={`${isEdit?'Edit':'Add'}|${VERIFY_PAGE_NAME}`}
                        waitforapiresponse={true}
                        navBarAnalyticsID={navBarAnalyticsID}
                        hasBottomNavCancel
                        plan={plan}
                        isEdit={isEdit}
                        flow = "addFlow"
                    />
                );
            }
            default: {
                return <React.Fragment></React.Fragment>;
            }
        }
    }

    
    getAccountTypeCode = accountType => {  
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Minor Roth IRA':
                return '215';
            case 'Roth IRA - Conversion':
                return '216';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case 'Simple IRA - Non DFI':
                return '210';
            case 'Simple IRA - DFI':
                return '212';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            case 'Coverdell ESA':
                return '219';
            case 'Archer MSA':
                return '220'
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };



    handleIRAContribution = (stateValue) => {
        const { getIRAContributionValues} = this.props;
        this.setState({flag:true,load:true})

        const startDate= this.state.selectedStartDate;
        let noOfTimes=0
        const planFrequency = this.state.selectedFrequency;


        function getDate(strdate){
            const newdate = strdate.split('/');
            let date = {
                month:parseInt(newdate[0]),
                day: parseInt(newdate[1]),
                year: parseInt(newdate[2])
            };
            return date
        }

        const date = getDate(startDate)

        if(planFrequency === 'A'){
            noOfTimes = 1
        }else if(planFrequency === 'Q'){
            noOfTimes = (Math.floor((12 - date.month)/3) + 1)  //for Jan its 5 not 4
            
        }else if(planFrequency === 'M'){
            noOfTimes = (Math.round(12 - date.month) + 1)
        }else if(planFrequency === 'S'){

            let days = new Date().getFullYear() % 4 == 0 ? 366 : 365;
            let day = Math.floor((new Date(startDate) - new Date(new Date(startDate).getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
            noOfTimes = Math.floor((days-day)/14) + 1
        } 

        let total = 0
        // stateValue.fundsList.forEach(function(fund){
        //     fund.amt? total += (parseFloat(fund.amt) * noOfTimes) : total
        // });
        for(let i=0; i< stateValue.fundsList.length; i++)
        {
            if(stateValue.fundsList[i].amt!= undefined && stateValue.fundsList[i].amt!= null)
            {
                total = total +  ((parseFloat(removeComma(stateValue.fundsList[i].amt))) * noOfTimes)
            }
        }

        const IRAContributionPayload = {
            accountType: stateValue.accountType,
            amount: total,
            accountTypeCode: this.getAccountTypeCode(stateValue.accountType && stateValue.accountType.trim()),
            socialCode: this.getSocialCode(stateValue.accountType && stateValue.accountType.trim()),
            year: date?.year,
            companyNumber: "591",
            dob: "",
            taxId: "",
            masterReg:`${stateValue.masterRegistrationID}`,
            accountSource: "FIS",
            isMember: getMemberRoleFlag()
        };

        const validateIRAPayloadObj = {
            IRAContributionPayload,
            // stateValue,
            token: getToken(),
            isVersion2ApiUrl: true
        };
       getIRAContributionValues(validateIRAPayloadObj)
   
    }
  
    handleProceed = () => {
        const { acceptIRAContributionWarning } = this.props;
        acceptIRAContributionWarning();
        this.setState(prevState => {
            return {
                pageIndex: prevState.pageIndex + 1 
            };
        });

        this.setState({flag:false})
    };


    handleCancel = () => {
        const { cancelIRAContributionWarning } = this.props;
        cancelIRAContributionWarning();
        this.setState({load:false,flag:false,isError:false})
    };

    getIsIRA = () => {
        const { currentSelectedAccount } = this.state;
        if (currentSelectedAccount != 'undefined') {
            const { accountType } = currentSelectedAccount;
            return !!(
                accountType === TransactionConstants.TRADITIONAL_IRA ||
                accountType === TransactionConstants.ROTH_IRA ||
                accountType.trim() === TransactionConstants.SEP_IRA ||
                accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
                accountType === TransactionConstants.ROLLOVER_IRA || 
                accountType === TransactionConstants.ROTH_CONVERSION_IRA
            );
        }
        return false;
    };


    moveNextPage = (actionTaken = '') =>{
        this.setState({showAuthBox: false});

        if(actionTaken != 'closed')
        {
            this.moveToNextStep();
        }
    }

    moveToNextStep = () =>
    {
        const planDetails = this.getPlanDetails();
        const { addPADDetails, addSWP } = this.props;
        const { plan } = this.state;
        this.setState({isFormAband:false})
        if (planDetails) {
            if (plan === RECURRING_TRANSFER_PLAN.AIP) { 
                addPADDetails(planDetails)
            } else { 
                addSWP(planDetails) 
            };
        }
    }

    authBox = () =>{
        this.setState({showAuthBox: true})
    }

    onNotificationForFis = ()=>{
        
        this.setState({
            checkFisError: true
        });
    }

    render() {
        const { fundAccountData, bankAccountReducerData, accOpeningReducerData, isSaving } = this.props;
        const {flag,iraContributionWarning,load,plan,isError,coverdellFalse, coverdellNotificationMsg, coverdellLoad,currentSelectedAccount,showAuthBox } = this.state
        const { isLoading: fundAccountDataLoading } = fundAccountData || {};
        const { isLoading: bankAccountReducerDataLoading } = bankAccountReducerData || {};
        const { isLoading: accOpeningReducerDataLoading } = accOpeningReducerData || {};
        const loading =
            fundAccountDataLoading || bankAccountReducerDataLoading || accOpeningReducerDataLoading || isSaving || load || coverdellLoad;
        const { hasError = false, errorsData = {} } = this.props;
        return (
            <>

                { <Authenticate showAuthBox={showAuthBox}  moveNextPage={this.moveNextPage} onNotificationForFis={this.onNotificationForFis} />}

                {coverdellFalse && (
                    <ConfirmModal
                        modalTitle="NOTE:"
                        modalBodyText={coverdellNotificationMsg}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.closeModel}
                    />
                )}

                {iraContributionWarning != '' && flag && this.getIsIRA() && plan===RECURRING_TRANSFER_PLAN.AIP && !(isError) && (
                    <ConformationModal
                        modalTitle={currentSelectedAccount != undefined && 
                            currentSelectedAccount.accountType && 
                            currentSelectedAccount.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ?'Coverdell Maximum Contribution Warning':'IRA Maximum Contribution Warning'}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={this.handleProceed}
                        onSecondaryClick={this.handleCancel}
                    />
                )}

                {isError && (
                    <ConfirmModal
                        modalTitle={currentSelectedAccount != undefined && 
                            currentSelectedAccount.accountType && 
                            currentSelectedAccount.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ?'Coverdell Maximum Contribution Warning':'IRA Maximum Contribution Warning'}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.handleCancel}
                    />
                )}

                {hasError && (
                    <WMessageBar
                        error={true}
                        onClose={this.onNotificationClose}
                        text={this.displayError(errorsData)} 
                        analyticsPageName="Add | Error Notification"
                        doNotClose
                    />
                )}
                {loading && <WSpinner loading={loading} />}
                <div>
                    <div className="container">{this.switchComponent()}</div>
                    <div ref={this.bottomNav} />
                </div>
            </>
        );
    }
}

CreateInvestmentOrWithdrawalPlan.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getFundListData: PropTypes.func,
    accOpeningReducerData: PropTypes.instanceOf(Object),
    getBankAccountInfo: PropTypes.func,
    getAccountList: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    bankAccountReducerData: PropTypes.instanceOf(Object),
    addPADDetails: PropTypes.func,
    addSWP: PropTypes.func,
    isSuccessfullySaved: PropTypes.bool,
    dismissAIPError: PropTypes.func,
    plan: PropTypes.string,
    dismissSWPError: PropTypes.func,
    clearFundAccountList: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    rmdReducerData: PropTypes.instanceOf(Object),
    responseMessage: PropTypes.string,
    hasError: PropTypes.bool,
    errorsData: PropTypes.instanceOf(Object),
    isSaving: PropTypes.bool,
    analyzeFormFieldError: PropTypes.func,
    getIRAContributionValues: PropTypes.func,
    cancelIRAContributionWarning: PropTypes.func,
    acceptIRAContributionWarning: PropTypes.func,
};

CreateInvestmentOrWithdrawalPlan.defaultProps = {
    plan: '',
    responseMessage: '',
    getFundListData: () => {},
    accOpeningReducerData: {},
    getBankAccountInfo: () => {},
    getAccountList: () => {},
    fundAccountData: {},
    bankAccountReducerData: {},
    addPADDetails: () => {},
    addSWP: () => {},
    isSuccessfullySaved: false,
    dismissAIPError: () => {},
    clearFundAccountList: () => {},
    clearBankAccDetails: () => {},
    dismissSWPError: () => {},
    analyzeClickEvent: () => {},
    analyzeFormAdandonment: () => { },
    rmdReducerData:{},
    hasError: false,
    errorsData: {},
    isSaving: false,
    analyzeFormFieldError: () => {},
    getIRAContributionValues: () => {},
    cancelIRAContributionWarning: () => {},
    acceptIRAContributionWarning: () => {},
};

export default CreateInvestmentOrWithdrawalPlan;
