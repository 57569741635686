import { Accordian, AccordianTitle, CommonButtons, ConformationModal, VCMSelect, VictoryDatePicker, WInput, WInputCheckBox, WRadio } from 'common';
import React from 'react'
import {Alert, Col, Row} from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react'
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { clearAPIStatus, clearBankAccountInfo, postMarketNAOInfo, updateMarketNAOInfo } from 'shared/Actions/MarketNAOActions';
import OverLayComponent from 'common/OverLayComponent/OverLayComponent';
import { removeComma,currencyFormatterWithoutDollarSign} from 'common/Formatter/CurrencyFormatter';
import { inputCheck,getToken} from 'utils';
import {zeroAmountErrorMsg,initialFundAmountErrorMsg,allowedMinimumFundAmount, IRA_CONTRIBUTION_KEY_TEXT, CURRENT_YEAR_TEXT, CONTRIBUTION_OPTIONS, SEP_IRA, WF_MAILING_ADDRESS, IRA_CONTRIBUTION_LIMIT} from "../AccountFeatures/consts";
import {purchaseActions} from 'shared/Actions';
import { SessionStorageHelper } from 'utils/sessionHelper';
import {resetBrokerageAccountListState} from 'shared/Actions/BankAccountAction';
import { FieldValue, SelectDropDown } from 'modules/CustomerManagementModule/components';
import { getDataFromORCondition } from 'utils/utils';
import { getTradingClosureWindow } from 'shared/Actions/TradingWindowActions';
import moment from 'moment';
import { getValidValue } from 'commonHelper/CommonHelperFunctions';
import getTransactionCompositeData from 'shared/Actions/TransactionCompositeDataAction';

const widthStyleFundOpts = { maxWidth: 210, marginRight: 15 };

const StyledRowAlert = styled(Row)`
    @media (min-width: 1200px) {
        padding-right: 27px;
    }
    padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
    padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
`;



const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;


const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.5')}rem;
    margin-bottom: ${({ nomargin: { nomargin } }) => (nomargin ? '2.5' : 0)}rem;
`;


const ErrorMsgComponent=styled.span`
color: rgba(230, 0, 0, 1);
font: 700 12px/22px benton-sans, yorkten-slab-normal, sans-serif;
margin-left: 40px;
marginTop:-10px;
`

const checkBoxWrapperStyle = {
    position: 'relative',
    marginTop: '5px',
    marginBottom: '10px',
    marginLeft: '20px',
}
const checkBoxInputStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #4F799F',
    opacity: 1,
    height: 25,
    width: 25,
    flexShrink: 0,
    marginLeft: 20,
    display: 'inline-block',
}

const checkBoxStyle = { position: 'absolute', top: '10px', display: 'inline' };
const rwdLabelStyle = {
    textAlign: 'left',
    font: '16px/20px benton-sans',
    letterSpacing: 0,
    opacity: 1,
    width: '80%',
    display: 'inline',
    fontWeight: 'normal',
};

const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const radioText = { font: '500 16px/20px benton-sans' };

const fundAccountText1='How would you like to fund your Marketplace Account? (Choose all that apply)'
const nobankAccountText1='You do not have any bank accounts on file. Please click'
const nobankAccountText2='here'
const nobankAccountText3='to add a bank account'
const transferassetInstructionText='Please continue through your new Marketplace application. Upon completion, you will be guided through the asset transfer process'
const rolloverInstructionText='Please continue through your new Marketplace application. Upon completion, an Investment Specialist will reach out to you to assist with your direct rollover'
const recurringContributionsText='Do you also want to set up recurring contributions?'


// Get previous year
const prevYear = new Date().getFullYear() - 1;


const compositeDataKeys = [
    `${IRA_CONTRIBUTION_LIMIT}${prevYear}`,
];


const AccountFundingPage = (props) => {

    const [compState, setCompState] = useState({nextText: "Next", overLay: false})
    const marketNAOReducerData = useSelector(state => state.marketNAOReducerData);
    const addBankDetails = marketNAOReducerData?.marketNAOInfo?.addBankDetails
    const dispatch = useDispatch();
    const initialState = {
        accountsList: marketNAOReducerData.marketNAOInfo.addBankDetails.selectedBankInfo,
        error: false,
        errorMsg: '',
        errorMsgForRecurring: '',
        recurringFundingAccount: marketNAOReducerData.marketNAOInfo.accountFunding.recurringFundingAccount,
        recurringFundAccountList: marketNAOReducerData.marketNAOInfo.accountFunding.recurringFundAccountList && marketNAOReducerData.marketNAOInfo.accountFunding.recurringFundAccountList.length > 0 ? marketNAOReducerData.marketNAOInfo.accountFunding.recurringFundAccountList : marketNAOReducerData.marketNAOInfo.addBankDetails.selectedBankInfo,
        fundFromExistingAccount: marketNAOReducerData.marketNAOInfo.accountFunding.fundFromExistingAccount,
        transferAssets: marketNAOReducerData.marketNAOInfo.accountFunding.transferAssets,
        fundAsDirectRollover: marketNAOReducerData.marketNAOInfo.accountFunding.fundAsDirectRollover,
        initialFundAmountStateErrorMsg:"",
        iraContributionYear: getValidValue(marketNAOReducerData.marketNAOInfo.accountFunding?.iraContributionYear,''),
        showContributionYearWarningModal: false,
        contributionYearErrMsg:'',
        nomargin:true
    }
    const [state, setState] = useState(initialState)
    const iraContribution=useSelector(state => state.purchaseData);
    const [popUp,setPopUp]=useState(false)
    const isIRAAccountSelected = marketNAOReducerData.marketNAOInfo.account.accountType.includes('IRA')
    const memberDashboardData=useSelector(state => state.memberDashboardData);
    const isRecurringFundEnabled = memberDashboardData.isEnableFeatureSuccess && memberDashboardData.getEnabledFeature.features.includes('BankAccountRecurringFunding') ? true : false
    const tradingWindowData = useSelector(state => state.tradingWindowData);
    const transCompositeData = useSelector(state => state.transactionCompositeDataReducerData);

    const accountFundingBuilderForRecurring = () => {
        let selectedAccountList = state.accountsList.reduce(function (array, element) {
            if (element?.selected === true) {
               array.push({ ...element, fundAmount: "", selected: false });
            }
            return array;
        }, []);
        
        return selectedAccountList
    }


    const accFundingListFormation = (allFundAccList, recurrFundAccList=[]) => {
        let selectedArr = recurrFundAccList

        if(recurrFundAccList.length === 0){
            selectedArr = accountFundingBuilderForRecurring()
        }
        else{
            allFundAccList.forEach(item => {
                if(item?.selected){
                    recurrFundAccList.forEach(innerItem => {
                        if(innerItem.salesforceRecordId !== item.salesforceRecordId){
                            if(!selectedArr.some(el => (el.salesforceRecordId === item.salesforceRecordId && el.bankAccountType === item.bankAccountType))){
                                selectedArr.push(item)
                            }
                        }else{
                            if(!selectedArr.some(el => (el.salesforceRecordId === innerItem.salesforceRecordId && el.bankAccountType === innerItem.bankAccountType))){
                                selectedArr.push(innerItem)
                            }
                        }
                 
         
                    })
                }
            
            
            })
        }
        return selectedArr
    }

    useEffect(()=>{
        if( iraContribution?.isIRAContributionSuccess ){
            if(iraContribution?.isIRAContributionAcceptedFlag === true){
                 saveAndMoveNextPage()
                 dispatch(purchaseActions.clearIraContributionValues());
            }else if(iraContribution?.isIRAContributionAcceptedFlag === false){
                setPopUp(true)
                dispatch(purchaseActions.clearIraContributionValues())
            }
        }else if(iraContribution?.isIRAContributionError){
            setPopUp(true) // TODO: set error msg
            dispatch(purchaseActions.clearIraContributionValues())
        }
         // clear validate ira response 
    },[iraContribution])

    // SCROLL TO TOP FUNCTION
    const scrollToTop = () => {
        setTimeout(() => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
        }))
    }
 
    useEffect(() => {
        dispatch(resetBrokerageAccountListState());
        dispatch(clearBankAccountInfo());
        scrollToTop()
        document.title = `Account Funding | Victory Capital`;

        // Call trading api only if the data is unavaiable
        if(isRecurringFundEnabled && tradingWindowData.aipTradingStartDateKey == ''){
            dispatch(getTradingClosureWindow({ token: localStorage.getItem('token') }));
        }
        
        
        if(isIRAAccountSelected && !transCompositeData[`${IRA_CONTRIBUTION_LIMIT}${prevYear}`]){
            dispatch(getTransactionCompositeData({ data: { datakey: compositeDataKeys }, token: getToken() }))
        }

        if(marketNAOReducerData.marketNAOInfo?.accountFunding?.allAvailableBankAccounts?.length > 0){
            // Account available for banks to going to fund

            const accountFeatureSelection = marketNAOReducerData.marketNAOInfo.addBankDetails.selectedBankInfo
            const accountFundingSelected = marketNAOReducerData.marketNAOInfo.accountFunding.allAvailableBankAccounts

            const accountsSelectedInFundingStep = accountFundingSelected.filter(function(fundItem){
                return accountFeatureSelection.some(function(featureItem){
                    return fundItem.salesforceRecordId === featureItem.salesforceRecordId;          
                });
            })
            
            const accountsSelectedInFeaturesPage = accountFeatureSelection.filter(function(featureItem){
                return !accountFundingSelected.some(function(fundItem){
                    return featureItem.salesforceRecordId === fundItem.salesforceRecordId;      
                });
            })
            
            const updatedAccountList = accountsSelectedInFundingStep.concat(accountsSelectedInFeaturesPage)

            // For recurring funding, After user selecting back if goes back to features page and selects mulitple banks  or deselects, those new list should be in line with previos selected list and update accordingly
            const accountFeatureSelectionNewRec = marketNAOReducerData.marketNAOInfo.addBankDetails.selectedBankInfo
            const accountFundingSelectedNewRec = marketNAOReducerData.marketNAOInfo.accountFunding.recurringFundAccountList

            const fundingRec = accountFundingSelectedNewRec.filter(function(fundItem){
                return accountFeatureSelectionNewRec.some(function(featureItem){
                    return fundItem.salesforceRecordId === featureItem.salesforceRecordId;          
                });
            })
            
            const featuresRec = accountFeatureSelectionNewRec.filter(function(featureItem){
                return !accountFundingSelectedNewRec.some(function(fundItem){
                    return featureItem.salesforceRecordId === fundItem.salesforceRecordId;      
                });
            })
            
            const updatedAccountListForRec = fundingRec.concat(featuresRec)

            setState(prevState => ({ ...prevState, accountsList: updatedAccountList, recurringFundAccountList: updatedAccountListForRec })); 
        }else{
            setState(prevState => ({ ...prevState, accountsList: marketNAOReducerData.marketNAOInfo.addBankDetails.selectedBankInfo }));
        } 

    }, [])


    useEffect(() => {
        if(marketNAOReducerData.isaccountFundingApiSuccess){
            const noOfSecondsBeforeTimeOut = 2000;
            setCompState(prevState => ({ ...prevState, nextText: "Saved", overLay: true }));  

            setTimeout(redirectSuccess,noOfSecondsBeforeTimeOut)
        }else if(marketNAOReducerData.isaccountFundingApiError){
            setCompState(prevState => ({ ...prevState, nextText: "Next", overLay: false }));    
            // setApiError(true)
            setState(prevState => ({ ...prevState,  error: true, errorMsg:'API error'}));   

            dispatch(clearAPIStatus('accountFunding'))
          }
    }, [marketNAOReducerData])


    const redirectSuccess = () => {
        dispatch(clearAPIStatus('accountFunding'))
        dispatch(purchaseActions.clearIraContributionValues())
        props.onNextClick()
    }


    const isValidFundAmount = (amount) => {
        return (amount != undefined && amount !== '' && amount !== '0.00' && amount !== '0')  
    }

    const isValidFundFrequency = (frequency) => {
        return (frequency !== undefined && frequency !== '')
    }

    const isValidFundStartDate = (startDate) => {
        return (startDate !== undefined && startDate !== '')
    }
    
    
    const isRecurringSectionValid = () => {
        let isValid = false
        

        const updatedFundListWithErrorMsg = state.recurringFundAccountList.map(item => {

            let innerErrorMsgForAmt = ''
            let innerErrorMsgForFrequency = ''
            let innerErrorMsgForStartDate = ''

            if(item?.selected){
                if(!isValidFundAmount(item?.fundAmount)){
                    innerErrorMsgForAmt = zeroAmountErrorMsg
                }
                
                if(!isValidFundFrequency(item?.frequency)){
                    innerErrorMsgForFrequency = 'select any frequency'

                }

                if(!isValidFundStartDate(item?.recurringstartdate)){
                    innerErrorMsgForStartDate = 'select a date'
                }
            }
        
            return {...item, errorMsgForAmt: innerErrorMsgForAmt, errorMsgForFrequency: innerErrorMsgForFrequency, errorMsgForStartDate: innerErrorMsgForStartDate}
        })

        setState(prevState => ({ ...prevState, recurringFundAccountList: updatedFundListWithErrorMsg}));

        
        const atleastOneBankSelected = updatedFundListWithErrorMsg.some(item => {
            return item?.selected === true 
        })

        if(atleastOneBankSelected){
            const hasAnyBankSelectedWithoutValidAmtOrFrequency = updatedFundListWithErrorMsg.map(item => {
                    
                if(item?.selected === true){
                    if(isValidFundAmount(item?.fundAmount)){
                        return !isValidFundFrequency(item?.frequency)
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            })

            if(!hasAnyBankSelectedWithoutValidAmtOrFrequency.includes(true)){
                isValid = true
            }
        }

        return isValid
    }

    const getUpdatedFundListWithErrorMessage = () => {
        const updatedAccountList = state.accountsList.map(item => {
            let innerErrorMsg = ''

            if(item?.selected && !isValidFundAmount(item?.fundAmount)){
                innerErrorMsg = zeroAmountErrorMsg
            }
        
            return {...item, errorMsg: innerErrorMsg}
        })

        return updatedAccountList

    }
    const hasLessThanMinimumFundAmount = (updatedFundListWithErrorMsg) => {
        //initial fund amount validation, show error msg if less than 250 accumulated funds amount
        let sumOfFundAmount = updatedFundListWithErrorMsg.length > 0 ? updatedFundListWithErrorMsg.reduce((accumulator, item) => {
            if(getDefaultValue(item?.fundAmount) != ''){
                const fundAmountString = item?.fundAmount.replace(/,/g, '');
                return parseFloat(accumulator) + parseFloat(fundAmountString);
            }else{
                return accumulator
            }
        },0) : 0;
        return sumOfFundAmount < allowedMinimumFundAmount;     
    }

    const hasLessThanMinimumContribution = () => {
        let amountErrorMsg = true; 
        const updatedFundListWithErrorMsg = getUpdatedFundListWithErrorMessage(); 
    
        if (hasLessThanMinimumFundAmount(updatedFundListWithErrorMsg)) { 
            amountErrorMsg = false;
            setState(prevState => ({ ...prevState, initialFundAmountStateErrorMsg: initialFundAmountErrorMsg}))
        }
        return amountErrorMsg;
    };
    
    const isContibutionYearNotSelectedForIRAAccount = (isIRAAccountSelected, iraContributionYear) => {
        return(isIRAAccountSelected && iraContributionYear == '')
    }

    const hasAnyBankSelectedWithoutValidAmtFun = (updatedFundListWithErrorMsg) => {
        const hasAnyBankSelectedWithoutValidAmt = updatedFundListWithErrorMsg.map(item => {
                
            if(item?.selected === true){
                return isValidFundAmount(item?.fundAmount)
            }else{
                return true
            }
        })

        return hasAnyBankSelectedWithoutValidAmt
    }

    const nextButtonValidation = () => {
        let isValid = true
        let errorMsg = ''
        let errorMsgForRecurring = ''
        let amountErrorMsg=true

        // when selected 1st option, to fund from existing account
        if(state.fundFromExistingAccount && state.accountsList.length > 0){

            const updatedFundListWithErrorMsg = getUpdatedFundListWithErrorMessage()

            setState(prevState => ({ ...prevState, accountsList: updatedFundListWithErrorMsg}));

            const hasValueSelected = updatedFundListWithErrorMsg.some(item => {
                return item?.selected === true 
            })

            if(hasValueSelected){
                const hasAnyBankSelectedWithoutValidAmt = hasAnyBankSelectedWithoutValidAmtFun(updatedFundListWithErrorMsg)
                
                
                if(hasAnyBankSelectedWithoutValidAmt.includes(false)){
                    isValid = false
                }else{

                    if(state.recurringFundingAccount === 'Yes' && isRecurringFundEnabled){

                        const atleastOneRecurringBankSelected = state.recurringFundAccountList.some(item => {
                            return item?.selected === true 
                        })

                        if(atleastOneRecurringBankSelected){
                            const isRecurringSectionValidd = isRecurringSectionValid()     
        
                            isValid = isRecurringSectionValidd
                        }else{
                            isValid = false
                            errorMsg = 'Please select at least one bank'
                        }
                     
                    }else if(state.recurringFundingAccount === '' && isRecurringFundEnabled){
                        isValid = false
                        errorMsgForRecurring = 'Please select any one option'
                    }else if(isContibutionYearNotSelectedForIRAAccount(isIRAAccountSelected, state.iraContributionYear)){
                        isValid = false
                        setState(prevState => ({ ...prevState, showContributionYearWarningModal: true, contributionYearErrMsg: 'Please select contibution year'}))
                    }else if(isIRAAccountSelected && state.iraContributionYear != ''){                        
                        setState(prevState => ({ ...prevState, showContributionYearWarningModal: false, contributionYearErrMsg: ''}))
                    }

                }
                amountErrorMsg= hasLessThanMinimumContribution()
                
            }else{
                isValid = false
            }
            
        }
        // Atleast one option should be selected
        else if(!(state.fundFromExistingAccount || state.transferAssets || state.fundAsDirectRollover)){

            isValid = false
            errorMsg = 'Please select at least one option'
        }



        return {isValid, errorMsg, errorMsgForRecurring,amountErrorMsg}
    }

    // on next button click if there are internal step, complete them and later switch next section
    const clickNext = () => {
        const {isValid, errorMsg, errorMsgForRecurring,amountErrorMsg} = nextButtonValidation()

        if(isValid && amountErrorMsg){
            setCompState(prevState => ({ ...prevState, nextText: "Saving...", overLay: true })); 
            setState(prevState => ({ ...prevState,  initialFundAmountStateErrorMsg:""}));   

            // If IRA account is selected
            if(isIRAAccountSelected){
                clickNextForIraAccounts()
            }else{
                // For all other accounts
                saveAndMoveNextPage()
            }
        }else{
            setState(prevState => ({ ...prevState,  error: true, errorMsg: errorMsg, errorMsgForRecurring }));   
        }
    };

    const getContributionYearForPayload = () => {
        let contributionYear = new Date().getFullYear()

        if(state.iraContributionYear === '1'){
            contributionYear = new Date().getFullYear() - 1
        }

        return contributionYear
    }
    
    const clickNextForIraAccounts = () => {
        const fundNow = addBankDetails.addBankLaterSelect || addBankDetails.selectedBankInfo.length === 0 ? false : state.fundFromExistingAccount
        
        if(fundNow){
            const accountFundingDataForIraAcc = accountFundingBuilder('')

            const totalFundingAmount = accountFundingDataForIraAcc.length > 0 ? caculateTotalInvestment(accountFundingDataForIraAcc) : 0;

            const iraContributionYrPayload = getContributionYearForPayload()

            const IRAContributionPayload = {
                isMember: true,
                companyNumber: '',
                year: iraContributionYrPayload,
                amount: totalFundingAmount,
                accountType: marketNAOReducerData.marketNAOInfo.account.accountType,
                taxId: "",
                dob: "",
                accountTypeCode: "",
                socialCode: "",
                "accountSource": "Brokerage"
            };
            const validateIRAPayloadObj = {
                IRAContributionPayload,
                stateValue: {},
                token: getToken(),
                isVersion2ApiUrl: true
            };

            dispatch(purchaseActions.getIRAContributionValues(validateIRAPayloadObj))

        }else{
            saveAndMoveNextPage()
        }
    };



    const accountFundingBuilder = (listBuildingFor='') => {

        let accountListCopy = Array.from(state.accountsList);
        let selectedAccountList = []

        selectedAccountList = accountListCopy.filter(item => {
            
            if(item?.selected == true){
                const selectedAccountProto =  {
                    bankAccountNumber:  item.bankAccountNumber,
                    decryptedBankAccountNumber: item.decryptedBankAccountNumber,
                    bankAccountType: item.bankAccountType,
                    bankName: item.bankName,
                    bankRoutingNumber: item.bankRoutingNumber,
                    CreatedDate: item.CreatedDate,
                    salesforceRecordId: item.salesforceRecordId,
                    SystemModstamp: item.SystemModstamp,
                    giactStatus: "",
                    giactStatusReason: "",
                    fundAmount: item.fundAmount
                }

                return selectedAccountProto
            }
        });

        if(listBuildingFor == 'api' && selectedAccountList.length > 0)
        {

            // This line will remove "decryptedBankAccountNumber, decryptedBankRoutingNumber" from each object inside array 
            selectedAccountList = selectedAccountList.map(({decryptedBankAccountNumber,decryptedBankRoutingNumber, ...rest})=>{
                return rest
            })

            selectedAccountList = selectedAccountList.map((item)=>{

                return {
                    ...item,
                    fundAmount: removeComma(item.fundAmount)
                }
            })
        }
        return selectedAccountList.length > 0 ? selectedAccountList : []
    }


    const saveToLocal = () => {
        let accountFundingData = accountFundingBuilder('localSave')
        const fundNow = addBankDetails.addBankLaterSelect || addBankDetails.selectedBankInfo.length === 0 ? false : state.fundFromExistingAccount

          // -------------------------UPDATE GLOBAL REDUX STATE
          const saveToLocalpayload = {
            sectionToBeUpdated: 'accountFunding',
            state: { allAvailableBankAccounts: state.accountsList, 
                selectedBankInfo: accountFundingData, 
                fundNow: fundNow, 
                recurringFundAccountList: state.recurringFundAccountList,
                recurringFundingAccount: state.recurringFundingAccount, 
                fundFromExistingAccount: state.fundFromExistingAccount,
                transferAssets: state.transferAssets,
                fundAsDirectRollover: state.fundAsDirectRollover,
                iraContributionYear: state.iraContributionYear
            }
          }
          dispatch(updateMarketNAOInfo(saveToLocalpayload))
    }
   
    const saveAndMoveNextPage = () => {
        saveToLocal()
        const accountFundingData = accountFundingBuilder('api')
        const fundNow = addBankDetails.addBankLaterSelect || addBankDetails.selectedBankInfo.length === 0 ? false : state.fundFromExistingAccount 

        let recurringList = [];
        if(state.recurringFundingAccount === 'Yes')
        {
            recurringList = state.recurringFundAccountList?.map((item)=>{
                return {
                    ...item,
                    fundAmount: removeComma(item.fundAmount)
                }
            })
        }
        let payload = {
            applicationId: marketNAOReducerData?.applicationId,
            pageNumber: 4,
            source: "web",
            accountFunding: {
                "fundNow": fundNow,
                "selectedBankInfo": accountFundingData,
                "recurringFundAccountList": state.recurringFundingAccount === 'Yes' ? recurringList : [],
                "recurringFundingAccount": state.recurringFundingAccount,
                "fundFromExistingAccount": state.fundFromExistingAccount,
                "fundAsDirectRollover": state.fundAsDirectRollover, 
                "transferAssets": state.transferAssets
            }
        }

        
        if(isIRAAccountSelected && accountFundingData.length > 0){
            payload.accountFunding.iraContributionYear = state.iraContributionYear
        }
        
        dispatch(postMarketNAOInfo({
        url: 'accountFunding',
        payloadData: payload
        }))

        setCompState(prevState => ({ ...prevState, nextText: "Saving...", overLay: true }));

      }
       
    const recurringFundAccOptions = [{key:'Yes', value:"Yes - Let's do it now"}, {key:'No', value:'No - I will do it later'}]

    // on Chnage of radio buttons
    const radioButtonClick = (eventKey,buttonType) =>  e => {
  
        if(eventKey == 'recurringFundingAccount' && buttonType === 'No'){
            setState(prevState => ({ ...prevState, [eventKey]: buttonType, recurringFundAccountList: initialState.accountsList, errorMsgForRecurring:''}));
        }else if(eventKey == 'recurringFundingAccount' && buttonType === 'Yes'){
            setState(prevState => ({ ...prevState, [eventKey]: buttonType, recurringFundAccountList: initialState.accountsList, errorMsgForRecurring:''}));
        }else{
            setState(prevState => ({ ...prevState, [eventKey]: buttonType, errorMsgForRecurring:'' }));
        }
    }

    const onMainCheckBoxChange = (stateVar) => {

        if(stateVar === 'fundFromExistingAccount' && state.fundFromExistingAccount === true ){
            setState(prevState => ({ ...prevState, [stateVar]: !state[stateVar], accountsList: initialState.accountsList, recurringFundingAccount:'', recurringFundAccountList: accFundingListFormation(initialState.accountsList, []), errorMsgForRecurring:'', errorMsg:''}));
        }else{
            setState(prevState => ({ ...prevState, [stateVar]: !state[stateVar], errorMsg:'' }));
        }

    }


    const getDefaultValue = (strVal, defaultVal='') => {
        return strVal ? strVal : defaultVal
    }
    const prepareItem = (item, selectedAccount) => {
        if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType ){
            const isSelected = item?.selected != undefined ? !item.selected : true

            let errorMsg = ''
            let errorMsgForAmt = ''
            let errorMsgForFrequency = ''
            let errorMsgForStartDate = ''

            if(isSelected){
                errorMsg = getDefaultValue(item?.errorMsg)
                errorMsgForAmt = getDefaultValue(item?.errorMsgForAmt)
                errorMsgForFrequency = getDefaultValue(item?.errorMsgForFrequency)
                errorMsgForStartDate = getDefaultValue(item?.errorMsgForStartDate)
            }
           
            return {...item, selected: isSelected, fundAmount: '', frequency:'', recurringstartdate: '', errorMsg, errorMsgForFrequency, errorMsgForAmt, errorMsgForStartDate}
        }

        return item
    }

    const onAccountsSelection = (selectedAccount, accountSelectionType='') => {

        if(accountSelectionType === 'recurring-fund'){

            const newAccountList = state.recurringFundAccountList.map(item => {
                return prepareItem(item, selectedAccount)
            })
        
            setState(prevState => ({ ...prevState, recurringFundAccountList: newAccountList }));
        }else{
            
        const newAccountList = state.accountsList.map(item => {
            if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType ){
                const isSelected = item?.selected != undefined ? !item.selected : true
                let errorMsg = ''

                if(isSelected){
                    errorMsg = getDefaultValue(item?.errorMsg)
                }


                return {...item, selected: isSelected, fundAmount: '', errorMsg:errorMsg }
            }

            return item
        })
        
        setState(prevState => ({ ...prevState, accountsList: newAccountList }));

    }
}

    const handleAmountChange = (e, selectedAccount, amountHandlerFor='') => {
        const targetValue = e.target.value

        const formattedValue = removeComma(targetValue)
        const validInitialInvestment = inputCheck(formattedValue);
        if(amountHandlerFor === 'recurring-fund'){
            const newAccountList = state.recurringFundAccountList.map(item => {
                if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                    let innerErrorMsg = item?.errorMsg ? item?.errorMsg : ''
                    if(validInitialInvestment != '0.00' || validInitialInvestment != '0' || validInitialInvestment != ''){
                        innerErrorMsg = ''
                    }

                    return {...item, fundAmount: validInitialInvestment, errorMsgForAmt: innerErrorMsg}
                }

                return item
            })
        
            setState(prevState => ({ ...prevState, recurringFundAccountList: newAccountList }));
        }else{

        
        const newAccountList = state.accountsList.map(item => {
            if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                let innerErrorMsg = item?.errorMsg ? item?.errorMsg : ''
                if(validInitialInvestment != '0.00' || validInitialInvestment != '0' || validInitialInvestment != ''){
                    innerErrorMsg = ''
                }

                return {...item, fundAmount: validInitialInvestment, errorMsg: innerErrorMsg}
            }

            return item
        })
        
        setState(prevState => ({ ...prevState, accountsList: newAccountList,initialFundAmountStateErrorMsg:'' }));
        }
    }
    //onBlur function decimal numbers added
        const handleFundOptionsBlur =(fundAmount,selectedAccount, fundOptionBlurHandlerFor='')=>{
            const numericValue = currencyFormatterWithoutDollarSign(fundAmount);
                if(fundOptionBlurHandlerFor === 'recurring-fund'){
                    const newAccountList = state.recurringFundAccountList.map(item => {
                        if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                            return {...item, fundAmount: numericValue}
                        }
            
                        return item
                    })
                    setState(prevState => ({ ...prevState, recurringFundAccountList: newAccountList }));
                }else{
                const newAccountList = state.accountsList.map(item => {
                    if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                        return {...item, fundAmount: numericValue}
                    }
        
                    return item
                })
                setState(prevState => ({ ...prevState, accountsList: newAccountList }));
        }
    }

    const frequency=["Annual (once per year)","Semi-annual (every 6 months)","Quarterly (every 3 months)","Bi-monthly (every 2 months)","Monthly","Semi-monthly (twice per month)"]

    
    const getNumberOfTimes = (startDate,frequency) =>{
        let noOfTimes=1
        if(startDate != undefined && startDate !=null)
        {
            function getDate(strdate){
                const date = {
                    month:strdate.getMonth() + 1, //getMonth method start with 0, so adding 1
                    day: strdate.getDate(),
                    year: strdate.getFullYear()
                };
                return date
            }
            const date = getDate(startDate);

            if(frequency === 'Annual (once per year)'){
                noOfTimes = 1
            }else if(frequency === 'Semi-annual (every 6 months)'){

                if(date.month > 6){
                    noOfTimes = 1
                }else{
                    noOfTimes = 2
                }

            }else if(frequency === 'Quarterly (every 3 months)'){
                noOfTimes = (Math.floor((12 - date.month)/3) + 1) //for Jan its 5 not 4

            }else if(frequency === 'Bi-monthly (every 2 months)'){
                noOfTimes = (Math.floor((12 - date.month)/2) + 1) 

            }else if(frequency === 'Monthly'){
                noOfTimes = (Math.round(12 - date.month) + 1)
            }else if(frequency === 'Semi-monthly (twice per month)'){
                let days = new Date().getFullYear() % 4 == 0 ? 366 : 365;
                let day = Math.floor((new Date(startDate) - new Date(new Date(startDate).getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
                noOfTimes = Math.floor((days-day)/14) + 1
            } 
            return noOfTimes;
        }

        return noOfTimes
    }

    const formatInputValues = val => {
        return val ? val.toString().replace(/[a-zA-Z,$]/g, '') : 0;
    };

    const caculateTotalInvestment = (accountsList) => {

        const { recurringFundAccountList } = state;

        let totalInitial = 0;
        let totalMonthly = 0;
        let totalMonthlyAll=0;

        // selected account list for initiak funding 
        accountsList.forEach(item => {
            totalInitial = totalInitial + parseInt(removeComma(item.fundAmount))
        })


        recurringFundAccountList.forEach(item => {
            // Accounts selected for recurring fund
            if(item?.selected){
                let noOfTimes = 0;
                const currentDate = new Date();
                noOfTimes = getNumberOfTimes(currentDate, item.frequency)
                totalMonthlyAll+= Number(formatInputValues(item.fundAmount)) * parseInt(noOfTimes);
                totalMonthly += Number(formatInputValues(item.fundAmount));
            }
        })

        return (totalInitial + totalMonthlyAll)
    };

    const handleFrequencyChange = (e, selectedAccount) => {

        const targetValue = e.target.value

        const newAccountList = state.recurringFundAccountList.map(item => {
            if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                let innerErrorMsg = item?.errorMsg ? item?.errorMsg : ''
                if(targetValue != ''){
                    innerErrorMsg = ''
                }

                return {...item, frequency: frequency[targetValue], errorMsgForFrequency: innerErrorMsg}
            }

            return item
        })
        
        setState(prevState => ({ ...prevState, recurringFundAccountList: newAccountList }));
    }
       
    const isAmountEntered = () => {
        let enable=false
        const accountListData=state.accountsList.some((item) => item.selected)
        const initialFundData=state.accountsList.some((item) => item.fundAmount)
        if(accountListData && initialFundData && parseInt(initialFundData) > 0){
            enable=true
        }
        return enable;
      };


    const handleProceed=()=>{
        setCompState(prevState => ({ ...prevState, nextText: "Saved", overLay: true }));    
        setPopUp(false)
        saveAndMoveNextPage()
    }
    
    const onBackButtonClick = () => {
    saveToLocal()
    props.onBackClick()
    }


    const handleStartDateChange = (dateValue, selectedAccount) => {

        const targetValue = dateValue
        const dateFormatText = 'MM/DD/YYYY';
        const formatedDate = moment(dateValue).format(dateFormatText);


        const newAccountList = state.recurringFundAccountList.map(item => {
            if(item.decryptedBankAccountNumber == selectedAccount.decryptedBankAccountNumber && item.bankAccountType == selectedAccount.bankAccountType){
                let innerErrorMsg = item?.errorMsg ? item?.errorMsg : ''
                if(targetValue != ''){
                    innerErrorMsg = ''
                }

                return {...item, recurringstartdate: formatedDate, errorMsgForStartDate: innerErrorMsg}
            }

            return item
        })
        
        setState(prevState => ({ ...prevState, recurringFundAccountList: newAccountList }));
    }

    const recurringFundingAccountListDom = () => {

        const currentDate = new Date();
        const nextPADStartDate = new Date(tradingWindowData.aipTradingStartDateKey);
        let diffForPADStartDate = (nextPADStartDate - currentDate) / (1000 * 60 * 60 * 24);
    
        if (diffForPADStartDate % 1 >= 0.5) {
            diffForPADStartDate -= 1;
        } 
    
        
        if(state.recurringFundingAccount === 'Yes'){
            return (
            <>
                <div className='recurringFundingAccount-Yes'>
                    <div>
                        <span>Please specify recurring contributions</span> 
                    </div>
                    <br />
                    {
                        state.recurringFundAccountList.map((item,index) => {
                            return (
                                <div className='subfundaccountbox' key={`recurring-funds-account-${item?.maskBankName}-${item?.bankAccountType}`}>
                                    {hasValidMaskBankName(item?.maskBankName) ?
                                        <Row>
                                            <div className='col-lg-3 col-md-3'>
                                                
                                                <WInputCheckBox
                                                id={`check-for-bank-${index}`}
                                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                                checkInputStyle={checkBoxInputStyle}
                                                checked={item?.selected}
                                                // value={item.decryptedBankAccountNumber}
                                                onChange={()=>onAccountsSelection(item, 'recurring-fund')}
                                                checkboxstyle={checkBoxStyle}
                                                labelstyle={rwdLabelStyle}
                                                label={`${item?.maskBankName}`}
                                                ariadescribedby={`check-for-bank-${index}`}
                                                />
                                                
                                            </div>
                                            
                                            <div className='col-lg-1 col-md-1  p-0'>
                                                <span style={{ float: 'left', fontSize: 'normal', marginTop: '13px', fontWeight: 'bold'}}>Recurring amount</span>
                                            </div>
                                            <div className='col-lg-2 col-md-2 mt-3'>
                                                <WInput
                                                    label={"$"}
                                                    id={`check-for-amount-${index}`}
                                                    name="amount"
                                                    type="text"
                                                    onChange={(e)=>handleAmountChange(e,item, 'recurring-fund')}
                                                    value={getDefaultValue(item?.fundAmount)}
                                                    labelmd={1}
                                                    valuemd={9}
                                                    disabled={item?.selected?false:true}
                                                    errortext={getDefaultValue(item?.errorMsgForAmt)}
                                                    onBlur={()=>handleFundOptionsBlur(item?.fundAmount,item, 'recurring-fund')}
                                                />
                                            </div>

                                            <div className='col-lg-3 col-md-3 mt-3'>
                                                <FieldValue
                                                    as="p"
                                                    id="frequency"
                                                    label="Frequency"
                                                    value={
                                                        <SelectDropDown
                                                            aria-labelledby="selectDropDown-suffix"
                                                            id="selectDropDown-frequency"
                                                            itemlist={frequency}
                                                            value={`${frequency.indexOf(
                                                                `${getDefaultValue(item?.frequency)}`,
                                                            )}`}
                                                            className="inputAlign"
                                                            onChange={(e)=>handleFrequencyChange(e,item)}
                                                            onBlur={()=>handleFundOptionsBlur(item?.fundAmount,item, 'recurring-fund')}
                                                            disabled={item?.selected?false:true}
                                                            errortext={getDefaultValue(item?.errorMsgForFrequency)}

                                                        />
                                                    }
                                                    labelsm={5}
                                                    valuesm={7}
                                                    mb={1.5625}
                                                    noGutters
                                                />
                                                
                                            </div>

                                            <div className='col-lg-3 col-md-3 mt-3'>
                                                <VictoryDatePicker
                                                    as="p"
                                                    label={'Start date'}
                                                    id={`reccuringStartDate`}
                                                    value={item?.recurringstartdate}
                                                    onDateChange={(e) => handleStartDateChange(e, item)}
                                                    // onDateBlur={(e) => handleStartDateBlur(e, item)}
                                                    argument1="startDate"
                                                    errorTextForDate={item?.errorMsgForStartDate}
                                                    colWidth="col-sm-8"
                                                    isFilterForPADStartDate
                                                    diffForPADStartDate={diffForPADStartDate}
                                                    minDate={nextPADStartDate}
                                                    required
                                                    datePickerModule="recurringStateDate"
                                                    disabled={item?.selected?false:true}
                                                />
                                            </div>
                                        
                                        </Row>
                                        : <></>
                                    }
                                </div>

                            )
                        })
                    }
                </div>
            </>)

        }else{
            return (<></>)
        }
    }

    // Recurring Funding Accout details
    const recurringFundInfo = () => {

        return (
            <>
                <div className='recurring-investment'>

                    <div className="" id='recurring-investment-title'>
                        {recurringContributionsText} 
                    </div>

                    <div className='col-sm-12 col-md-12 col-12'>
                        {recurringFundAccOptions.map((item,index) => {
                            return (
                                <>
                                <WRadio
                                    radioFor="recurring-funding"
                                    key={`recurring-funding-option-key-${item.key}`}
                                    id={`recurring-funding-option-${item.value}-${index}`}
                                    value={item.value}
                                    radiodescstyles={radiodescstyles}
                                    radiostyles={radiostyles}
                                    radiotext={radioText}
                                    selected={state.recurringFundingAccount == item.key}
                                    ariaLabel="None"
                                    optional="true"
                                    ariaDescribedBy="recurring-funding"
                                    onClick={radioButtonClick('recurringFundingAccount', item.key)}
                                />
                                </>
                            )
                        })}

                        { 
                            <span className='errorMsgInputStyle'>{state.errorMsgForRecurring}</span>
                        }
                        <br />

                        {recurringFundingAccountListDom()}

                    </div>

                </div>
            </>
        )
    }
    
    const hasValidMaskBankName = (bankName) => {
        return (bankName !== "" && bankName !== undefined)
    }

    const existingFundAccountList = () => {

        return (
            <div className='col-sm-12 col-md-12 col-12'>
                {   
                    state.accountsList.length > 0 ? 
                    <>
                    {state.accountsList.map((item,index) => {
                        return (
                            <div className='subfundaccountbanklistbox' key={`existing-fund-account-${item?.maskBankName}-${item?.bankAccountType}`}>
                            <Row>
                                <div className='col-lg-5 col-md-5'>
                                    {hasValidMaskBankName(item?.maskBankName) ?
                                        <WInputCheckBox
                                        id={`check-for-fund-account-${index}`}
                                        checkboxwrapperstyle={checkBoxWrapperStyle}
                                        checkInputStyle={checkBoxInputStyle}
                                        checked={item?.selected}
                                        // value={item.decryptedBankAccountNumber}
                                        onChange={()=>onAccountsSelection(item, '')}
                                        checkboxstyle={checkBoxStyle}
                                        labelstyle={rwdLabelStyle}
                                        label={`${item?.maskBankName}`}
                                        ariadescribedby={`check-for-${item?.decryptedBankAccountNumber}`}
                                        />
                                        :
                                        null
                                    }
                                </div>


                                <div className='col-lg-2 col-md-2  p-0'>
                                    <span style={{ float: 'left', fontSize: 'normal', marginTop: '13px', fontWeight: 'bold'}}>Initial Funding Amount</span>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    {hasValidMaskBankName(item?.maskBankName) ?
                                        <>
                                        <WInput
                                            label={"$"}
                                            id={`amount-${index}`}
                                            name="amount"
                                            type="text"
                                            onChange={(e)=>handleAmountChange(e,item)}
                                            value={getDefaultValue(item?.fundAmount)}
                                            labelmd={1}
                                            valuemd={11}
                                            disabled={item?.selected?false:true}
                                            errortext={getDefaultValue(item?.errorMsg)}
                                            onBlur={()=>handleFundOptionsBlur(item?.fundAmount,item)}
                                        />
                                        </>
                                        :null
                                    }
                                </div>

                            </Row>
                            </div>

                        )
                    })}
                      <div className='errorMessageStyle'>
                    <ErrorMsgComponent>{state.initialFundAmountStateErrorMsg}</ErrorMsgComponent>
                    </div>
                    </>
                    :
                    <>
                        <div className='subfundaccountbox'>
                            <div className='smallitalictext'>
                                {nobankAccountText1} <a href='#' onClick={()=>props.onEditButtonClick('AccountFeatures','addBankAccount')}>{nobankAccountText2}</a> {nobankAccountText3}
                            </div>
                        </div>
                    
                    </>
                }

            </div>
        )
    }
    

    const accountFundingSection = () => {
        return (
            <>
            <div className="account-funding-section">
                <div className="fundFromExistingAccount" id='account-funding title'>
                    {fundAccountText1}
                </div>
            </div>

                <div className='account-funding-section subfundaccountbox mb-2'>
                <WInputCheckBox
                    key={`fund-question-1-fundFromExistingAccount`}
                    id={`fund-question-1-fundFromExistingAccount`}
                    checkboxwrapperstyle={checkBoxWrapperStyle}
                    checkInputStyle={checkBoxInputStyle}
                    checked={state.fundFromExistingAccount}
                    onChange={()=>onMainCheckBoxChange('fundFromExistingAccount')}
                    checkboxstyle={checkBoxStyle}
                    labelstyle={rwdLabelStyle}
                    label={`Use an existing bank account`}
                    ariadescribedby={`check-for-bank`}
                    
                />


                { state.fundFromExistingAccount && existingFundAccountList()}
            </div>

            <div className='account-funding-section transferAssets subfundaccountbox  mb-2'>
                <WInputCheckBox
                    key={`fund-question-2-transferAssets`}
                    id={`fund-question-2-transferAssets`}
                    checkboxwrapperstyle={checkBoxWrapperStyle}
                    checkInputStyle={checkBoxInputStyle}
                    checked={state.transferAssets}
                    onChange={()=>onMainCheckBoxChange('transferAssets')}
                    checkboxstyle={checkBoxStyle}
                    labelstyle={rwdLabelStyle}
                    label={`I want to transfer assets (cash, stocks, ETFs, Funds) to Victory Capital from another broker`}
                    ariadescribedby={`check-for-bank`}
                    
                />

                {
                    state.transferAssets ? 
                    <div className='col-sm-12 col-md-12 col-12'>
                        <div className='subfundaccountbox'>
                            <div className='smallitalictext'>{transferassetInstructionText}</div>
                        </div>
                    </div>
                   

                    : <></>
                } 

            </div>

            <div className='account-funding-section subfundaccountbox  mb-2'>
                <WInputCheckBox
                    key={`fund-question-3-fundAsDirectRollover`}
                    id={`account-funding-option`}
                    checkboxwrapperstyle={checkBoxWrapperStyle}
                    checkInputStyle={checkBoxInputStyle}
                    checked={state.fundAsDirectRollover}
                    onChange={()=>onMainCheckBoxChange('fundAsDirectRollover')}
                    checkboxstyle={checkBoxStyle}
                    labelstyle={rwdLabelStyle}
                    label={`I will fund my account as a direct rollover from a retirement plan or IRA with another provider`}
                    ariadescribedby={`check-for-bank`}
                    
                />

                {
                    state.fundAsDirectRollover ? 
                    <div className='col-sm-12 col-md-12 col-12'>
                        <div className='subfundaccountbox'>
                                <div className='smallitalictext'>{rolloverInstructionText}</div>
                        </div>
                    </div>
                    

                    : <></>
                } 
            </div>

            {<span className='errorMsgInputStyle'>{state.errorMsg}</span>}

            </>
        )
       
    }

    const isFundFromExistingAccountlist =()=>{
        return (state.fundFromExistingAccount && state.accountsList.length > 0 && !isAmountEntered())
    }

    const getExistingAccountFlag = (state,disableNext,atleastOneBankSelectedForInitialFunding) =>{
        if(state.fundFromExistingAccount){
            if(atleastOneBankSelectedForInitialFunding || (state.accountsList.length == 0 && getDataFromORCondition(state.transferAssets,state.fundAsDirectRollover))){
                disableNext = false
            }
        }
        else if(isFundFromExistingAccountlist()){
            disableNext = true
        }
        else if(isAmountEntered() || getDataFromORCondition(state.transferAssets,state.fundAsDirectRollover)){
            disableNext = false
        }
        return disableNext
    }

    const nextButtonDisabled = () => {

        let disableNext = true

        const atleastOneBankSelectedForRecurring = state.recurringFundAccountList.some(item => {
            return item?.selected === true && item.fundAmount != '' && item.frequency != '' && parseFloat(item.fundAmount) > 0 && item?.recurringstartdate
        })

        const atleastOneBankSelectedForInitialFunding = state.accountsList.some(item => {
            return item?.selected == true && item.fundAmount != '' && parseFloat(item.fundAmount) > 0
        })
        
        if(isRecurringFundEnabled == false){
            disableNext = getExistingAccountFlag(state,disableNext,atleastOneBankSelectedForInitialFunding)
        }
        else{
            if(state.fundFromExistingAccount && state.recurringFundingAccount === 'Yes' && state.recurringFundAccountList.length > 0){
                if(atleastOneBankSelectedForInitialFunding){
                    if(atleastOneBankSelectedForRecurring){
                        disableNext = false
                    }
                }
                else if(state.accountsList.length == 0 && state.recurringFundingAccount === '' && getDataFromORCondition(state.transferAssets,state.fundAsDirectRollover)){
                    disableNext = false
                }
            }
            else if(state.fundFromExistingAccount && state.accountsList.length > 0 && !isAmountEntered() && state.recurringFundingAccount === 'Yes'){
                disableNext = true
            }
            else if(state.recurringFundingAccount === 'Yes' && state.recurringFundAccountList.length > 0 && !isAmountEntered()){
                disableNext = true
            }
            else if(state.recurringFundingAccount === 'No' && atleastOneBankSelectedForInitialFunding == true){
                disableNext = false
            }
            else if(isAmountEntered() || (getDataFromORCondition(state.transferAssets,state.fundAsDirectRollover) && state.accountsList.length == 0)){
                disableNext = false
            }
            else if(isAmountEntered() || (getDataFromORCondition(state.transferAssets,state.fundAsDirectRollover) && state.fundFromExistingAccount == false)){
                disableNext = false
            }
        }
        return disableNext
    
    }


    const diableNextButton = nextButtonDisabled()
    const handleIRACancelPopUpClick = () =>{
        setPopUp(false)
        setCompState(prevState => ({ ...prevState, nextText: "Next", overLay: false }));    

    }


    
    const getPrevYearTaxDeadline = (taxDeadline, accountType ) => {
        const taxDeadlineDateFormat = new Date(taxDeadline);
        const currentDate = new Date();
        let contributionValues = CURRENT_YEAR_TEXT;
        if ((currentDate <= taxDeadlineDateFormat) && accountType.trim() !== SEP_IRA) {
            contributionValues = CONTRIBUTION_OPTIONS;
        }
        return contributionValues;
    };


    const yearChangeHandler = e => {
        setState(prevState => ({ ...prevState, iraContributionYear: e.target.value }));  

    };


    const getSelectDropdown = (values, iraContributionYear) => {

        return (
            <VCMSelect
                key={iraContributionYear}
                name="iraContribution"
                id="iracontribution"
                itemlist={values}
                onChange={yearChangeHandler}
                value={iraContributionYear}
                valuesm={12}
                valuemd={12}
                labelsm={0}
                labelmd={12}
                style={widthStyleFundOpts}
                noGutters
                data-test="ira-selectBox"
            />
        );
    };


    const showIRAContributionSection = () => {

        const {iraContributionYear, fundFromExistingAccount, nomargin} = state
        const marginProps = { nomargin: { nomargin } };

        // state.accountsList.length > 0
        const atleastOneBankSelected = state.accountsList.length > 0 ? state.accountsList.some(item => {
            return item?.selected === true 
        }) : false

        return (
            isIRAAccountSelected && fundFromExistingAccount && atleastOneBankSelected ? 
                <>
                    <Accordian
                        accordianPaddingLeft={0}
                        id='account-contribution-year-section'
                        titleChild={

                            <AccordianTitle
                                accType={"Contribution"}
                                className="accordionH2Style"
                                requireButtonPadding={false}
                            />
                        }
                        titleSeperator
                    >
                        <div className='account-funding-contribution-yr'>
                        <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                                    <Col>
                                        {state.showContributionYearWarningModal && (
                                            <StyledAlert
                                                {...marginProps}
                                                variant="danger"
                                                aria-atomic="true"
                                                aria-live="assertive"
                                                aria-describedby={state.contributionYearErrMsg}
                                            >
                                                <StyledPara>{state.contributionYearErrMsg}</StyledPara>
                                            </StyledAlert>
                                        )}
                                    </Col>
                                </StyledRowAlert>
                            <Row>
                        


                                <Col sm={3}>
                                    <p className="iraLabel">Contribution for IRA Account</p>
                                </Col>
                                {/* {accountName === TransactionConstants.SEP_IRA ? 
                                <Col sm={3}>
                                        <p className='employerContribution'>{TransactionConstants.EMPLOYER_YEAR_TEXT[0]}</p>
                                </Col>
                                    : */}
                                    
                                <Col sm={3} className="iraContributionSelect">


                                {transCompositeData && compositeDataKeys[0] in transCompositeData
                                                    ? getSelectDropdown(
                                                        getPrevYearTaxDeadline(
                                                        transCompositeData[compositeDataKeys[0]]?.tax_deadline,
                                                        marketNAOReducerData.marketNAOInfo.account.accountType
                                                        ),
                                                        iraContributionYear,
                                                      )
                                                    : getSelectDropdown(
                                                        CURRENT_YEAR_TEXT,
                                                        iraContributionYear,
                                                      )}

                                </Col>
                                {/* } */}
                            </Row>
                            
                        </div>
                    </Accordian>

                </> 
            : null
        )

    }


  return (
    <div className='AccountFundingPage'>
                 {popUp &&
                      <ConformationModal
                        modalTitle={'IRA Maximum Contribution Warning'}
                        modalBodyText={iraContribution?.iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={()=>handleProceed()}
                        onSecondaryClick={()=>handleIRACancelPopUpClick()}
                    />}

        {
            compState.overLay && <OverLayComponent />
        }
        <Accordian
            accordianPaddingLeft={0}
            id='account-funding-section-id'
            titleChild={

                <AccordianTitle
                    accType={"Fund Account"}
                    className="accordionH2Style"
                    requireButtonPadding={false}
                />
            }
            titleSeperator
        >
        <div className='account-funding'>
           
           {accountFundingSection()}
           <hr/>

           { isRecurringFundEnabled && state.accountsList.length > 0 && state.fundFromExistingAccount ? recurringFundInfo() : null }
           
        </div>
        </Accordian>

        {
            showIRAContributionSection()
        }

        <div className="footer">
            <CommonButtons 
            backClick={onBackButtonClick} 
            nextClick={clickNext} 
            isShowBtn={true} 
            nextText={compState.nextText} 
            disabled={diableNextButton} />
        </div>
    </div>
  )
}

export default AccountFundingPage 
