import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import WInput from '../../../common/WInput/WInput';
import * as Styles from './Styles';
import * as Constants from './Constants';

const TopicsDropDown = props => {
    const { subject, setSubjectValue, errorText, isDisabled } = props;
    const inlineStyles = {
        subjectInput: { marginLeft: 5, fontWeight: 400 },
    };

    return (
        <Styles.FormRow>
            {isDisabled && (
                <>
                    <Col sm={2}>
                        <Styles.Label>{Constants.FORMS.SUBJECT}</Styles.Label>
                    </Col>
                    <Col sm={5}>{subject.value}</Col>
                </>
            )}
            {!isDisabled && (
                <WInput
                    label={Constants.FORMS.SUBJECT}
                    id="subject-text-box"
                    valuesm={10}
                    labelsm={2}
                    inputfieldstyle={inlineStyles.subjectInput}
                    aria-required
                    errortext={errorText}
                    required
                    onChange={setSubjectValue}
                    value={subject.value}
                    maxlength={50}
                    arialabel={Constants.FORMS.SUBJECT}
                    arialabelledby={Constants.FORMS.SUBJECT}
                    disabled={isDisabled}
                />
            )}
        </Styles.FormRow>
    );
};

TopicsDropDown.propTypes = {
    subject: PropTypes.instanceOf(Object),
    setSubjectValue: PropTypes.func,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool,
};

TopicsDropDown.defaultProps = {
    subject: {},
    setSubjectValue: () => {},
    errorText: '',
    isDisabled: false,
};
export default TopicsDropDown;
