import {
    AccountSelectedInfoCard,
    FundSource,
    BottomNavWrapper,
    AddBankAccount,
    AccountAddress,
    MaskText,
} from 'common';
import { Card } from 'react-bootstrap';
import { formatCurrency } from '../../../../utils';
import FundTo from 'modules/AccountOptions/InvestmentAndSWP/FundTo';
import { get, forEach } from 'lodash';
import InvestToOrWithdrawFrom from '../InvestToOrWithdrawFrom';
import PropTypes from 'prop-types';
import React from 'react';
import { RECURRING_TRANSFER_PLAN, PAY_OPTIONS } from 'modules/AccountOptions/constant';
import { STEP_KEY, PLAN_DETAILS_PAGE_NAME } from '../../../../utils/AdobeAnalytics/Constants';
import styled from 'styled-components';
import TaxOptionsToWithHold from '../../Common/TaxSelector/TaxOptionsToWithHold';
import { TAX_TEXT } from '../../Common/TaxSelector/TransactionConstants';
import  './plandetail.css';
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';

const CardInfo = styled(Card)`
    padding: 1rem 1rem 1rem 3rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 16px #000029;
    font-weight: bold;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #8bc105;
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
    }
    @media only screen and (max-width: 767px) {
        box-shadow: 0px 0px 16px #00000029;
    }
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 19px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;
AccountDetailsFlex.displayName = 'AccountDetailsFlex';

// const FlexColumnDiv = styled.div`
//     display: flex;
//     flex-direction: column;
// `;
// const AccordianDesc = styled.div`
//     margin-bottom: 20px;
//     text-align: left;
//     font: 400 16px/22px Benton Sans;
//     letter-spacing: 0;
//     color: #56565a;
//     opacity: 1;
// `;

const SKIP_TO_MAIN = 'skipToMain'; // used to focus the first element on the page
// const DIRECT_SWP_INFO = 'To direct a transfer to another fund, please contact a Member Service Representative (800) 235-8396.';
const IRA_DISCLAIMER =
    'Victory Capital does not provide tax advice. Please contact your tax consultant for any tax related questions.';
const NO_BANK_ACCOUNTS= 'No bank on file.  Please direct investor to add their bank online or submit the "Automatic Investment Plan Form."'
const FUNDING_SOURCE = 'Funding Source';
class PlanDetailsAIP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bankDetailsSuccess: false,
            disabledNextBtn: !props.isEditMode,
            retrivedState: false,
            offlineMode: null,
            addressValue: '',
            bankDetails: [],
            addbankvisible: false,
            accountverificationmsg: false,
            deliveryAddress: null,
            payeeName: '',
            directedSWPState: null,
            totalAmount: 0,
            tax: {},
            selectedFunds:[],
            investToFund: false,
            fundNo:0,
            fundAccNo:0,
            investToFundName:'',
            updateFlag: true
        };

        this.investToRef = React.createRef();
        this.fundFromRef = React.createRef();
        this.bankDetailsSectionRef = React.createRef();
        this.calculationSectionRef = React.createRef();
        this.directedSWP = React.createRef();
        this.taxWithHoldRef = React.createRef();
        this.taxWithHoldFwdRef = React.createRef();
        this.addbankRef = React.createRef();

        this.fedTaxPercentRef = React.createRef();
        this.fedTaxPercentFwdRef = React.createRef();

        this.stateTaxPercentRef = React.createRef();
        this.stateTaxPercentFwdRef = React.createRef();

        this.shareEntered = false;
        this.bankSelected = false;
        this.layoutSettings = {
            automaticInvestment: ['bankCardSec', 'CalcSec'],
            systematicWithdrawal: ['CalcSec', 'bankCardSec'],
        };

        this.checkStatus = this.checkStatus.bind(this);
    }

    static  processBankDetails = (savedState, paymentMode, data) =>{
       
            if(paymentMode === PAY_OPTIONS.BANK && data.length > 0) {
                return {
                    ...savedState,
                    retrivedState: true,
                    selBankAccNumber: data[1].value,
                    selBankName: data[0].value,
                    updateFlag: false
                }
            }
            if(paymentMode === PAY_OPTIONS.DIRECT_SWP && data.length > 0) {
                return {
                    ...savedState,
                    retrivedState: true,
                    investToFundName: data[1].value,
                    fundAccNo: data[0].value,
                    updateFlag: false
                }
            }
            if(paymentMode === PAY_OPTIONS.CHECK_ORDER && data.length > 0) {
                return {
                    ...savedState,
                    retrivedState: true,
                    // investToFundName: data[1].value,
                    // fundAccNo: data[0].value,
                    updateFlag: false
                }
            }
             return {
                ...savedState,
                retrivedState: true,
                updateFlag: false
             }
        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, bankAccountInfo, plan, offlineMode } = nextProps;
        const { updateFlag } = prevState
        if (!prevState.retrivedState && savedState && updateFlag) {
            const { displayDetails, offlineMode: paymentMode } = savedState
            const { fundFrom = {} } = displayDetails;
            const { data = [] } = fundFrom; 
            return PlanDetailsAIP.processBankDetails(savedState, paymentMode, data)
        }
   
        const returnObj = {};
        const { bankDetails = [], isSuccess: bankDetailsSuccess, isError: bankDetailsError, bankDisplayRules = {} } =
            bankAccountInfo || {};
        if (bankDetails.length && prevState.bankDetails && !prevState.bankDetails.length && bankDetailsSuccess) {
            const selectedBank = get(prevState, 'bankDetailsSectionState.selectedBank', {});
            let isBankSelected = false;
            if (selectedBank && Object.keys(selectedBank).length) {
                forEach(bankDetails, (bank, index) => {
                    isBankSelected =
                        (bank.bankAccountNumber === selectedBank.bankAccountNumber &&
                            bank.bankRoutingNumber === selectedBank.bankRoutingNumber) ||
                        (selectedBank.salesforceRecordId &&
                            bank.salesforceRecordId === selectedBank.salesforceRecordId);
                    if (isBankSelected) {
                        bankDetails[index] = {
                            ...bank,
                            selected: isBankSelected,
                        };

                        return false;
                    }
                });
            }
            if (
                !isBankSelected &&
                (!savedState || (savedState && savedState.offlineMode !== PAY_OPTIONS.CHECK_ORDER))
            ) {
                returnObj.disabledNextBtn = true;
                returnObj.bankDetails = {
                    ...returnObj.bankDetails,
                    disableNext: true,
                };
            }

            returnObj.bankDetails = bankDetails;
        }

        if (!bankDetails.length && (bankDetailsSuccess || bankDetailsError) && plan === RECURRING_TRANSFER_PLAN.AIP) {
            returnObj.bankDetails = [
                {
                    bankName: 'Add Bank Account',
                    CreatedDate: new Date().toDateString(),
                },
            ];
            
        }

        if (
            !bankDetails.length &&
            prevState.bankDetailsSectionState &&
            bankDetailsSuccess !== prevState.bankDetailsSuccess &&
            bankDetailsSuccess === true &&
            offlineMode !== PAY_OPTIONS.CHECK_ORDER
        ) {
            returnObj.bankDetailsSectionState = {
                ...returnObj.bankDetailsSectionState,
                selectedBank: null,
                disableNext: true,
            };
            returnObj.disabledNextBtn = true;
        }

        returnObj.bankDisplayRules = bankDisplayRules;
        returnObj.bankDetailsSuccess = bankDetailsSuccess;
        return Object.keys(returnObj).length ? returnObj : null;
    }

    componentDidMount() {
        const {plan, isEditMode,savedState} = this.props;
        setTimeout(() =>
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            }),
        );
        if(plan === RECURRING_TRANSFER_PLAN.AIP) {
            document.title = `Plan Details: ${isEditMode ? 'Edit AIP': 'Add AIP'} | Victory Capital`;
        } else {
            document.title = `Plan Details: ${isEditMode ? 'Edit SWP': 'Add SWP'} | Victory Capital`;
        }
        const { editSection, getBankAccountInfo ,editFlow} = this.props;

        const { bankDetails } = this.state;
        if (editSection === 'Fund From') {
            window.scrollTo({
                top: this.fundFromRef.current.offsetTop,
                behavior: 'smooth',
            });
        } else if (editSection === 'Invest To') {
            window.scrollTo({
                top: this.investToRef.current.offsetTop,
                behavior: 'smooth',
            });
        } else if (editSection === 'Tax Withholding Options') {
            window.scrollTo({
                top: this.taxWithHoldRef.current.offsetTop,
                behavior: 'smooth',
            });
        }

        if (bankDetails && !bankDetails.length) {
            getBankAccountInfo({
                customerId: '123',
            });
        }

        const skipToMain = document.getElementById(SKIP_TO_MAIN);
        if (skipToMain) {
            document.activeElement.blur();
            skipToMain.focus();
        }

        if(savedState != undefined && savedState !=null && editFlow == undefined && savedState.selectedFunds.length == 0)
        {
            this.setState({disabledNextBtn : true})
        }
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex, plan, isEditMode } = this.props;
    
        const { state: calculationSectionState } = this.calculationSectionRef.current || {};
        const displayDataForBnkSec = this.getDisplayDataForBnkSec();
        const displayDataForCalcSec = this.getDisplayDataForCalcSec();

        sessionStorage.setItem(STEP_KEY, `${isEditMode ? 'Edit' : 'Add'}|${PLAN_DETAILS_PAGE_NAME}`);

        let bankDetailsSectionState;
        if(this.bankDetailsSectionRef.current !=undefined && this.bankDetailsSectionRef.current !=null){
            const { state } = this.bankDetailsSectionRef.current || {};
         const { offlineMode } = state;
        
        
        if (plan !== RECURRING_TRANSFER_PLAN.SWP) {
            bankDetailsSectionState = {...state};
        } else {
            const { bankDetailsSection } = state
            bankDetailsSectionState = {...bankDetailsSection}
        }
        

        const SWPData = this.getSWPData();
        if (SWPData.fundFrom) {
            displayDataForBnkSec.fundFrom = SWPData.fundFrom;
        }
        let newBankData;
        if (plan !== RECURRING_TRANSFER_PLAN.SWP) {
                newBankData = this.getNewBankData();
            if (newBankData.fundFromData) {
                displayDataForBnkSec.fundFrom.data = newBankData.fundFromData;
            }
        }

        let displayDetails;
        let investFundDetails;

        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            displayDetails = { ...displayDataForBnkSec, ...displayDataForCalcSec };
        } else {
            let displayDataForTaxWithholdSec = {};

            if (this.isIRAaccount()) {
                displayDataForTaxWithholdSec = this.getDisplayDataForTaxSelector();
            }
            investFundDetails = {investToFundData: {state}};
            displayDetails = { ...displayDataForCalcSec, ...displayDataForBnkSec, ...displayDataForTaxWithholdSec };
        }

        const commonDataToSave = {        
            ...this.state,
            bankDetailsSectionState,
            displayDetails,
            offlineMode,
            investFundDetails,
            ...newBankData,
        };

        let dataToSave = {};
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            dataToSave = {
                ...commonDataToSave,
            };
        } else {
            dataToSave = {
                ...commonDataToSave,
                ...SWPData,
            };
        }
        saveChildrenState(dataToSave, pageIndex);
        }        
    }

    getDisplayDataForBnkSec = () => {
        let displayDataForBnkSec = {};
        if (this.bankDetailsSectionRef.current) {
            const { state } = this.bankDetailsSectionRef.current;
            const { displayDetails } = state || {};
            const { fundFrom } = displayDetails || {};
            const { data } = fundFrom || {};
            if (data && data.length) {
                displayDataForBnkSec = displayDetails;
            }
        }
        return displayDataForBnkSec;
    };

    getDisplayDataForCalcSec = () => {
        let displayDataForCalcSec = {};
        if (this.calculationSectionRef.current) {
            const { state } = this.calculationSectionRef.current;
            const { displayDetails } = state || {};
            const { investTo } = displayDetails || {};
            const { data } = investTo || {};
            if (data && data.length) {
                let tempDisplayDetails = displayDetails;
                for(let i=1;i<tempDisplayDetails?.investTo?.data.length;i=i+2)
                {
                    tempDisplayDetails.investTo.data[i].value = currencyFormatterFunction(tempDisplayDetails?.investTo?.data[i].value)
                    tempDisplayDetails.investTo.data[i].formattedValue='';
                }
                displayDataForCalcSec = displayDetails;
            }
        }
        return displayDataForCalcSec;
    };

    getDisplayDataForTaxSelector = () => {
        const { pageIndex, groupStateTax } = this.props;
        const displayDataForTaxSelector = {
            taxSelector: {
                titleTxt: 'Tax Withholding Options',
                editTxt: 'Edit',
                pageIndex,
                data: [],
            },
        };

        const {
            taxWithHoldState: { tax },
            totalAmount,
        } = this.state;

        const taxBasis = get(groupStateTax, 'basis', '');
        const stateTax = get(groupStateTax, 'stateTax', '');
        const stateTaxPer = (Number(stateTax) * 100) / Number(totalAmount);
        const stateTaxCalculated = taxBasis === 'FWH' ? stateTaxPer.toFixed(2) : tax.stateTaxPercent;

        const taxData = Object.entries(tax).map(item => {
            let value = '';
            if (TAX_TEXT[item[0]].includes('amount') || TAX_TEXT[item[0]].includes('received')) {
                value = formatCurrency(`${item[1]}`);
            }else if(item[0] === 'stateTaxPercent'){
                value = `${stateTaxCalculated}%`;
            } else {
                value = `${item[1]}%`;
            }
            return {
                field: TAX_TEXT[item[0]],
                value,
            };
        });

        displayDataForTaxSelector.taxSelector.data = taxData;

        // if (this.fedTaxPercentRef.current) {
        //     displayDataForTaxSelector.taxSelector.data.push({
        //         field: 'Federal Tax',
        //         value: `${get(this.fedTaxPercentRef.current, 'value', 0)}%`
        //     });
        // }

        // if (this.stateTaxPercentRef.current) {
        //     displayDataForTaxSelector.taxSelector.data.push({
        //         field: 'State Tax',
        //         value: `${get(this.stateTaxPercentRef.current, 'value', 0)}%`
        //     })
        // }

        return displayDataForTaxSelector;
    };

    getSWPData = () => {
        const { pageIndex, plan, accDetails } = this.props;
        const { taxWithHoldState } = this.state;  
        const { state } = this.bankDetailsSectionRef.current || {};
        const { offlineMode, bankDetailsSection = {} ,  investToFundName ='', investToFundAccNo = ''} = state
        const SWPData = {};
        if (plan !== RECURRING_TRANSFER_PLAN.AIP) {
            if (offlineMode === PAY_OPTIONS.CHECK_ORDER) {
                SWPData.deliveryAddress =
                    accDetails.address && Object.keys(accDetails.address).length
                        ? accDetails.address
                        : AccountAddress.EMPTY_ADDRESS;
                const checkOrderDisplayData = [];
                checkOrderDisplayData.push(
                    {
                        field: FUNDING_SOURCE,
                        value: 'Check',
                    },
                    {
                        field: 'Delivery Address',
                        value: <AccountAddress address={accDetails.address} />,
                    },
                );
                const fundFrom = {
                    titleTxt: 'Fund To',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [],
                };
                fundFrom.data = checkOrderDisplayData;
                SWPData.fundFrom = fundFrom;
            } else if (offlineMode === PAY_OPTIONS.DIRECT_SWP) {
                const checkOrderDisplayData = [];
                checkOrderDisplayData.push(
                    {
                        field: 'Name of the fund',
                        value: investToFundName,
                    },
                    {
                        field: 'Fund Account Number',
                        value: investToFundAccNo,
                    },
                );
                const fundFrom = {
                    titleTxt: 'Fund To',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [],
                };
                fundFrom.data = checkOrderDisplayData;
                SWPData.fundFrom = fundFrom;
             
            } else if (offlineMode === PAY_OPTIONS.BANK) {
                const { selectedBank } = bankDetailsSection
                if(selectedBank){
                    const { bankName, bankAccountNumber } = selectedBank
                    const checkOrderDisplayData = [];
                    checkOrderDisplayData.push(
                        {
                            field: FUNDING_SOURCE,
                            value: bankName,
                        },
                        {
                            field: 'Account Number',
                            formattedValue: bankAccountNumber ? (
                                <MaskText text={bankAccountNumber.toString()} startIndex={0} maskLength={6} />
                            ) : (
                                bankAccountNumber
                            ),
                            value: bankAccountNumber,
                        },
                    );
                    const fundFrom = {
                        titleTxt: 'Fund To',
                        editTxt: 'Edit',
                        pageIndex,
                        data: [],
                    };
                    fundFrom.data = checkOrderDisplayData;
                    SWPData.fundFrom = fundFrom;
                }
                
            }

            SWPData.taxWithHoldState = taxWithHoldState;
            if (this.directedSWP.current) {
                SWPData.directedSWPState = { ...this.directedSWP.current.state };
            }
        }
        return SWPData;
    };

    getNewBankData = () => {
        const addbankRef = this.addbankRef.current;
        const newBankData = {};
        if (addbankRef) {
            newBankData.addbankState = { ...addbankRef.getState() };
            const { otherBankState } = newBankData.addbankState;
            const { bankAccountDetails } = otherBankState || {};
            // get only first and last array items
            const [, , , newBankName] = bankAccountDetails || [];
            const newBankNumbervalue =
                bankAccountDetails
                    .filter(item => {
                        return item.name === 'accountNumber';
                    })
                    .map(item => item.value)[0] ?? '';
            const { value: newBankNamevalue = '' } = newBankName || {};
            const newBankDisplayData = [];
            newBankDisplayData.push(
                {
                    field: FUNDING_SOURCE,
                    value: newBankNamevalue || 'Bank Account',
                },
                {
                    field: 'Account Number',
                    formattedValue: newBankNumbervalue ? (
                        <MaskText text={newBankNumbervalue.toString()} startIndex={0} maskLength={6} />
                    ) : (
                        newBankNumbervalue
                    ),
                    value: newBankNumbervalue,
                },
            );
            newBankData.fundFromData = newBankDisplayData;
        }
        return newBankData;
    };

    showOfflineModeOptions = (index, showOptions, checkStatus = false) => {
        let offlineMode = PAY_OPTIONS.BANK;
        const addbankvisible = false;
        if (showOptions) {
            offlineMode = index ? PAY_OPTIONS.DIRECT_SWP : PAY_OPTIONS.CHECK_ORDER;
        }
        this.setState(
            prevState => {
                const { directedSWPState } = prevState;
                if (directedSWPState) {
                    if (offlineMode === PAY_OPTIONS.DIRECT_SWP) {
                        directedSWPState.fundYourInvestmentState = null;
                    } else if (offlineMode === PAY_OPTIONS.CHECK_ORDER) {
                        directedSWPState.selectVcmFundState = null;
                        directedSWPState.selectedFund = null;
                    }
                }
                return {
                    offlineMode,
                    directedSWPState,
                    addbankvisible,
                };
            },
            () => {
                if (checkStatus) {
                    this.checkStatus();
                }
            },
        );
    };

    toggleBank = selectedBank => {
        const { addbankvisible: prevAddBankVisibleState } = this.state;
        const obj = {
            addbankvisible: false,
        };
        if (selectedBank && !selectedBank.bankAccountNumber && !selectedBank.salesforceRecordId) {
            obj.addbankvisible = true;
        }
        this.setState(obj, () => {
            const { addbankvisible } = this.state;
            if (prevAddBankVisibleState !== addbankvisible) {
                this.checkStatus();
            }
        });
    };

    onPayeeNameChange = e => {
        const payeeName = e.target.value;
        if (payeeName.match('^[a-zA-Z ]*$') != null) {
            this.setState({ payeeName });
        }
    };

    onPayeeNameBlur = () => {
        this.setState(prevState => {
            let { payeeName } = prevState;
            payeeName = payeeName
                .split(' ')
                .filter(x => x)
                .join(' ');
            return {
                payeeName,
            };
        });
    };

    updateTotalAmount = amount => {
        this.setState({
            totalAmount: amount,
        });
    };

    setTaxStateValues = taxData => {
        const { stateTaxWithHold, ...rest } = taxData;
        this.setState({
            taxWithHoldState: {
                stateTaxWithHold,
                isIraAccount: this.isIRAaccount(),
                tax: {
                    ...rest,
                },
            },
        });
    };

    isIRAaccount = () => {
        const { accDetails, plan } = this.props;
        const { accountType = '' } = accDetails || {};
        return (
            ((accountType.toUpperCase().indexOf('IRA') !== -1 &&
                accountType.toUpperCase().indexOf('ROTH IRA') === -1) ||
                accountType === '215') &&
            plan !== RECURRING_TRANSFER_PLAN.AIP
        );
    };

    validateFundForNextButton = (shares)=>
    {
        let nextButtonDisableFlag = false;
        let selectedFundListData = shares.filter(item=> item.selected == true);
        for(let i=0;i<selectedFundListData.length;i++)
        {
            if(selectedFundListData[i].amt == '' || selectedFundListData[i].amt == null)
            {
                nextButtonDisableFlag  = true;
                break;
            }
        }
        return nextButtonDisableFlag;
    }

    handleBankSelectionStatus =(bankSelection) =>{
        let disabledNextBtn = true
        if(this.props.savedState != undefined && this.props.savedState != null && this.props.savedState.disabledNextBtn !=undefined && this.props.savedState.selectedFunds != undefined && bankSelection == 'bankSelection')
        {
            const nextButtonState = this.validateFundForNextButton(this.props.savedState.selectedFunds);
            if(nextButtonState == false && this.props.savedState.disabledNextBtn == false)
            {
                disabledNextBtn = this.props.savedState.disabledNextBtn;
            } 
        }
        return disabledNextBtn
    }

    checkStatus = (bankSelection ='') => {
        let disabledNextBtn = false;
        const { offlineMode,bankDetails } = this.state;
        const fundCalculation = this.calculationSectionRef.current;
        const {isEditMode } = this.props;
        const fundSourceOnline = this.bankDetailsSectionRef.current;
        const taxWithhold = this.taxWithHoldRef.current;
        const fundSourceOfflineCheckOrder = offlineMode === PAY_OPTIONS.CHECK_ORDER;
        const fundSourceOfflineDirectedSWP = this.directedSWP.current;
        const addbankRef = this.addbankRef.current;
        // fundCalculation will always be available in both AIP and SWP

        if (fundCalculation) {
            const cmpState = { ...fundCalculation.state };
            if((bankDetails.length >= 1 && bankDetails[0].bankName != 'Add Bank Account' && cmpState.disableNext == false)){
                disabledNextBtn = cmpState.disableNext;
            }
            else if(isEditMode == true && cmpState.disableNext == false){
                disabledNextBtn = cmpState.disableNext;
            }
            else{

                //disabledNextBtn = true;
              disabledNextBtn=  this.handleBankSelectionStatus(bankSelection)
              
            }
         }
        if (taxWithhold) {
            const cmpState = { ...taxWithhold.state };
            disabledNextBtn = disabledNextBtn || cmpState.disableNext;
        }
        const { plan } = this.props;
        if (plan === RECURRING_TRANSFER_PLAN.SWP) {
            if (fundSourceOnline) {
                const cmpState = { ...fundSourceOnline.state };
                disabledNextBtn = disabledNextBtn || cmpState.disableNext;
            }
        } else {
            if (fundSourceOfflineCheckOrder) {
                disabledNextBtn = disabledNextBtn || false;
            }
           
            if (fundSourceOfflineDirectedSWP) {
                const cmpState = { ...fundSourceOfflineDirectedSWP.state };
                disabledNextBtn = disabledNextBtn || cmpState.disableNext;
            }
            if (!fundSourceOfflineCheckOrder && !fundSourceOfflineDirectedSWP && fundSourceOnline) {
                const cmpState = { ...fundSourceOnline.state };
                disabledNextBtn = disabledNextBtn || cmpState.disableNext;
            }
            if (addbankRef) {
                const cmpState = { ...addbankRef.state };
                disabledNextBtn = disabledNextBtn || cmpState.disableNext;
            }
        }

        const { disabledNextBtn: prevStateDisabled } = this.state;
        if (prevStateDisabled !== disabledNextBtn) {
            this.setState(() => {
                return {
                    disabledNextBtn,
                };
            });
        }
    }

    getCheckAddress = () => {
        const { accDetails, plan, isEditMode } = this.props;

        const { offlineMode } = this.state;

        let checkAddress = accDetails.address;

        if (
            isEditMode &&
            plan === RECURRING_TRANSFER_PLAN.SWP &&
            offlineMode === PAY_OPTIONS.CHECK_ORDER &&
            accDetails.specialPayeeAddress &&
            Object.keys(accDetails.specialPayeeAddress).length > 0
        ) {
            checkAddress = accDetails.specialPayeeAddress;
        }

        return checkAddress;
    };

    getSelectedShares = (shares) => {
        this.setState({
            selectedFunds: shares
        })
    }

    // fundToOptionChange = (val) => {
    //     this.setState(() => {
    //         return {
    //             offlineMode: val
    //         }
    //     })
    // }

    getFundNo = (fund, allFunds) => {
        let fundNo = ''
        let fundAccNo = ''
        allFunds.filter(el => el.fundName === fund).map(obj => {
            fundNo = obj.fundNumber
            fundAccNo = obj.fundAccountNumber 
             
        })
        return {fundNo, fundAccNo}
    }

    // handleInvestFundChange = (e) => {
    //     const { selectedFunds } = this.state
    //     const { target } = e;
    //     const { value, textContent } = target;
    //     const fundDetails = this.getFundNo(textContent.substr(6), selectedFunds)
    //     const { fundNo, fundAccNo } = fundDetails
    //     this.setState({
    //         investToFundName: textContent.substr(6),
    //         fundNo,
    //         fundAccNo
    //     })
    // }

    // handleBankAccChange = (e, rule) => {
    //     const { bankDisplayRules } = this.props
    //     const { target, list=[] } = e
    //     const { value } = target
    //     list.filter(acc => acc.key === value).map(el => {   
    //         const bankObj =  {selectedBank:el}
    //          this.setState({
    //              selBankAccNumber: el.bankAccountNumber,
    //              selBankName: el.bankName,
    //              bankDetailsSectionState: bankObj
    //          })
    //     })

    // }

    updateEditBankData = (bankAccountNumber, bankName) => {
        this.setState({
            selBankAccNumber: bankAccountNumber,
            selBankName: bankName
        })
    }

    handlefederalPercentValue = (isEditFromVerifyPage,isEditMode,tax,accDetails)=>{
        return  (isEditFromVerifyPage || isEditMode) && tax ? Number(tax.fedTaxPercent) : Number(accDetails.fedTax) || 0;
    }

    fetchComponent(cmpName) {
        const { bankDetails = [], addbankvisible, addbankState, totalAmount, bankDisplayRules, selectedFunds } = this.state;
        const { pageIndex, savedState, plan, accDetails, contents, isEditMode, analyzeFormFieldError, assocFunds=[], fundPlanData, isEditFromVerifyPage } = this.props;
        const { fundFrom, investTo, offlineFundDetails = [] } = contents || {};

        const { fundsList } = accDetails || {};
        const { bankDetailsSectionState = null, calculationSectionState = null, taxWithHoldState = {} } =
            savedState || {};
        const { stateTaxWithHold = false, tax } = taxWithHoldState;

        const { offlineCards = [] } = bankDetailsSectionState || {};

        const federalPercentValue = this.handlefederalPercentValue(isEditFromVerifyPage,isEditMode,tax,accDetails) 
        const stateTaxPercentValue = (isEditFromVerifyPage || isEditMode) && tax ? Number(tax.stateTaxPercent) : Number(accDetails.stateTax) || 0;
        //  console.log('@log for isEditMode ', isEditMode,bankDetails,fundsList,savedState,selectedFunds)
        if (fundFrom) {
            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                fundFrom.methods[0].details = [...bankDetails];
            } else {
                fundFrom.methods[1].details = [...bankDetails];
                fundFrom.methods[0].details = offlineCards.length ? [...offlineCards] : [...offlineFundDetails];
            }
        }

        if (cmpName === 'bankCardSec') {
            if (plan === RECURRING_TRANSFER_PLAN.SWP) {
                const checkAddress = this.getCheckAddress();
                const [fromFundDetails] = fundsList || [];
                return (
                    <FundTo
                        checkStatus={this.checkStatus}
                        title={fundFrom.detailName}
                        forwardRef={this.fundFromRef}
                        description={fundFrom.description}
                        bankList={fundFrom.methods[1].details}
                        checkAddress={checkAddress}
                        fromFundDetails={fromFundDetails}
                        bankDisplayRules={bankDisplayRules}
                        shares={fundsList}
                        selectedFunds={selectedFunds}
                        fundToOptionChange={this.fundToOptionChange}
                        handleInvestFundChange={this.handleInvestFundChange}
                        ref={this.bankDetailsSectionRef}
                        savedState={savedState}
                        selectedFundsCount={selectedFunds.filter(fund => fund.selected).length}
                        isEditMode={isEditMode}
                        assocFunds={assocFunds}
                        // handleBankAccChange={this.handleBankAccChange}
                        // investToFundName={investToFundName}
                    />
                );
            }
            return (
                <React.Fragment key={cmpName}>
                    {(bankDetails.length >=  1  && bankDetails[0].bankName != 'Add Bank Account'  &&  plan === RECURRING_TRANSFER_PLAN.AIP) ? (
                       <FundSource
                        checkStatus={this.checkStatus}
                        ref={this.bankDetailsSectionRef}
                        forwardRef={this.fundFromRef}
                        bankDisplayRules={bankDisplayRules}
                        fundFrom={fundFrom}
                        plan={plan}
                        pageIndex={pageIndex}
                        savedState={bankDetailsSectionState}
                        showOfflineModeOptions={this.showOfflineModeOptions}
                        toggleBank={this.toggleBank}
                        shouldValidateACHLimit={false}
                        flow = {(this.props.editFlow != undefined && this.props.editFlow !=null) ? this.props.editFlow : null}
                        componentName = {'AIPFLOW'}
                    />

                    ): (
                        <>
                            <CardInfo>{NO_BANK_ACCOUNTS}</CardInfo>
                        </> 
                    )}
                    {addbankvisible && (
                        <AddBankAccount
                            ref={this.addbankRef}
                            savedState={addbankState}
                            pageIndex={pageIndex}
                            checkStatus={this.checkStatus}
                        />
                    )}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment key={cmpName}>
                <InvestToOrWithdrawFrom
                    checkStatus={this.checkStatus}
                    ref={this.calculationSectionRef}
                    investTo={investTo}
                    forwardRef={this.investToRef}
                    pageIndex={pageIndex}
                    savedState={calculationSectionState}
                    shares={fundsList}
                    plan={plan}
                    isEditMode={isEditMode}
                    ammountChanged={this.updateTotalAmount}
                    analyzeFormFieldError={analyzeFormFieldError}
                    fundPlanData={fundPlanData}
                    getSelectedShares={this.getSelectedShares}
                    fundSavedState = {savedState}
                    editFlow = {(this.props.editFlow != undefined && this.props.editFlow != null && this.props.editFlow != ''  ) ? this.props.editFlow  : ''}
                />

                {this.isIRAaccount() && (
                    <TaxOptionsToWithHold
                        setTaxStateValues={this.setTaxStateValues}
                        residenceState={accDetails.address.accountRegistrationIdRes}
                        masterRegId={isEditMode ? accDetails.masterRegistrationID : accDetails.accountNumber}
                        onTaxChange={this.onTaxChange}
                        handleStateTaxWithHold={this.handleStateTaxWithHold}
                        fedTaxPercent={federalPercentValue}
                        stateTaxPercent={stateTaxPercentValue}
                        stateTaxWithHold={stateTaxWithHold}
                        amount={parseFloat(totalAmount)}
                        fedTaxPercentRef={this.fedTaxPercentRef}
                        stateTaxPercentRef={this.stateTaxPercentRef}
                        taxRef={this.taxWithHoldRef}
                        isSWP
                    />
                )}
            </React.Fragment>
        );
    }

    render() {
        const {
            accDetails,
            navigationObj,
            plan,
            // contents,
            isEditMode,
            // onCancel,
            navBarAnalyticsID,
        } = this.props;

        const { disabledNextBtn, offlineMode} = this.state;
         // const { Deliveryaddress } = contents || {};
        // const { detailName = '' } = Deliveryaddress || {};

        // const checkAddress = this.getCheckAddress();
        if (offlineMode === 'directedSWP') this.checkStatus();

        return (
            <>
                <AccountSelectedInfoCard accDetails={accDetails} />

                {this.isIRAaccount() && (
                    <div>
                        <CardInfo>{IRA_DISCLAIMER}</CardInfo>
                    </div>
                )}
                {this.layoutSettings[plan.toString()].map(item => {
                    return this.fetchComponent(item);
                })}
                {/* {offlineMode === PAY_OPTIONS.CHECK_ORDER && (
                    <Accordian
                        accordionTitleAs="h2"
                        key={detailName}
                        titleChild={
                            <AccordianTitle accType={detailName} id={detailName.replace(/\s/g, "")} accHeaderAs="button" />
                        } titleSeperator>
                        <FlexColumnDiv>
                            <FieldValue
                                htmlFor="vcm-cms-current-address"
                                label='Address'
                                value={<AccountAddress address={checkAddress} />}
                                mb='1.7'
                                labelsm={3}
                                valuesm={5}
                            />
                        </FlexColumnDiv>
                    </Accordian>
                )}
                {offlineMode === PAY_OPTIONS.DIRECT_SWP && (
                    <div>
                        <CardInfo>
                            {DIRECT_SWP_INFO}
                        </CardInfo>
                    </div>
                )} */}
                <BottomNavWrapper
                    {...navigationObj}
                    showBack={!isEditMode}
                    disabled={disabledNextBtn}
                    dataAnalyticId={navBarAnalyticsID}
                    arialabelback={isEditMode ? 'Back to Recurring Transfer Fund to Bank' : 'Back to Account Selection'}
                />
            </>
        );
    }
}
PlanDetailsAIP.propTypes = {
    getBankAccountInfo: PropTypes.func,
    bankAccountInfo: PropTypes.instanceOf(Object),
    accDetails: PropTypes.instanceOf(Object),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    savedState: PropTypes.instanceOf(Object),
    saveChildrenState: PropTypes.func,
    pageIndex: PropTypes.number.isRequired,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    plan: PropTypes.string.isRequired,
    switchToCompareFundsScreen: PropTypes.func,
    switchToFundSummaryScreen: PropTypes.func,
    contents: PropTypes.instanceOf(Object),
    isEditMode: PropTypes.bool,
    onCancel: PropTypes.func,
    navBarAnalyticsID: PropTypes.string,
    analyzeFormFieldError: PropTypes.func,
    fundPlanData: PropTypes.instanceOf(Object),
    assocFunds: PropTypes.instanceOf(Object),
    offlineMode: PropTypes.string,
    groupStateTax: PropTypes.instanceOf(Object),
};
PlanDetailsAIP.defaultProps = {
    getBankAccountInfo: () => {},
    bankAccountInfo: {},
    accDetails: {},
    savedState: null,
    saveChildrenState: () => {},
    editSection: null,
    switchToCompareFundsScreen: () => {},
    switchToFundSummaryScreen: () => {},
    contents: {},
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        portalRoot: null,
    },
    isEditMode: false,
    onCancel: () => {},
    navBarAnalyticsID: '',
    analyzeFormFieldError: () => { },
    fundPlanData: {},
    assocFunds: [],
    offlineMode:'',
    groupStateTax: {},
};

export default PlanDetailsAIP;