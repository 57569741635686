
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";

export const getBeneficiaries = payload => (dispatch) => {
    const env = '';
    dispatch({
        type: ActionTypes.GET_BENEFICIARY_LOADING,
    });
    makeApiRequest(
        ActionTypes.GET_BENEFICIARY,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_BENEFICIARY_SUCCESS,
                    payload: {manage_beneficiary: response.data.result }
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_BENEFICIARY_FAILURE,
                });
            }
        },
        error => {
            // console.log(error);
            dispatch({
                type: ActionTypes.GET_BENEFICIARY_FAILURE,
                payload: error
            });
        }
    );
};

export const saveBeneficiaryData = payload => (dispatch) => {
    // SETTING ENVIRONMENT
    const env = '';

    // DISPATCHING ACTION FOR LOADER
    dispatch({
        type: ActionTypes.SAVE_BENEFICIARY_LOADING,
    });

    // MAKE API REQUEST
    makeApiRequest(
        ActionTypes.SAVE_BENEFICIARY,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.SAVE_BENEFICIARY_SUCCESS,
                    payload: response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.SAVE_BENEFICIARY_ERROR
                });
            }
        },
        () => {
            // DISPATCHING FAILURE ACTION
            dispatch({
                type: ActionTypes.SAVE_BENEFICIARY_ERROR,
            });
        }
    );
};

export const editBeneficiaryData = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.GET_EDIT_BENEFICIARY,
        ...data
    });
};

export const deleteBeneficiaryData = (payload) => (dispatch) => {
   // DISPATCHING ACTION FOR LOADER
   dispatch({
       type: ActionTypes.DELETE_BENEFICIARY_LOADING,
   });

   // MAKE API REQUEST
   makeApiRequest(
       ActionTypes.DELETE_BENEFICIARY,
       payload,
       null,
       response => {
           if (response.data) {
               dispatch({
                   type: ActionTypes.DELETE_BENEFICIARY_SUCCESS,
                   payload: response.data
               });
           } else {
               dispatch({
                   type: ActionTypes.DELETE_BENEFICIARY_ERROR
               });
           }
       },
       error => {
           // DISPATCHING FAILURE ACTION
           dispatch({
               type: ActionTypes.DELETE_BENEFICIARY_ERROR,
               payload: error
           });
       }
   );
};

export const clearBeneficiaryData = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.CLEAR_BENEFICIARY_DATA,
        ...data
    });
};