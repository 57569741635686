import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as DownArrowBlue } from 'assets/DownArrowBlue.svg';
import styled from 'styled-components';


const StyledInput = styled.input`
    width: 100%;
    height: 3.125rem;
    // padding: 1rem 2.125rem 1.0625rem 1.25rem;
    padding-left: 1.3em;
    padding-right: 1.7em;
    background: ${props => (props.disabled ? '#fafafa' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: 1px solid ${props => (props.isOpen ? '#486d90' : '#d2d2d2')};
    opacity: 1;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0;
    color: transparent;
    text-shadow: 0 0 0 #56565a;
    text-align: left;
    text-align: -moz-left;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    position: relative;
    &:hover {
        border: 1px solid #486d90;
    }
    &::placeholder {
        color: #56565a;
        font: 500 16px/20px benton-sans;
    }
    &::-moz-placeholder {
        color: #56565a;
        font: 500 14px/20px benton-sans;
        opacity: 1;
        line-height: 3.5em;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        line-height:24px;
    }
`;

const InputIcon = styled.div`
    display: flex;
    position: absolute;
    align-self: center;
    right: 1.25rem;
    //  top: 18px;
`;

const InputDownArrow = styled(DownArrowBlue)`
    width: 0.85rem;
    height: 0.85rem;
`;

const SelectDiv = styled.div`
    display: inline-flex;
    position: relative;
    width: 100%;
`;

const SelectBox = styled.div`
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 3rem;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 0.46875rem solid transparent;
        border-right: 0.46875rem solid transparent;
        border-bottom: 0.625rem solid #ffffff;
        top: -0.625rem;
        right: 1.5rem;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.25rem;
    overflow-y: scroll;
    max-height: 14.0625rem;
    &::-webkit-scrollbar {
        width: 0.375rem;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        background: #486d90 0% 0% no-repeat padding-box;
        border-radius: 0.375rem;
    }
`;

const ItemSpan = styled.span.attrs(props => ({
    id: props.Id,
    tabindex: props.tabIndex,
}))`
    cursor: pointer;
    text-align: left;
    padding: 0.625rem;
    min-height: 2.8125rem;
    margin: 2px;
    height: auto;
    opacity: 1;
    line-height: 1;
    &:focus {
        background: #E6E6E6 0% 0% no-repeat padding-box;
       
    }
`;

const ErrMsg = styled.small`
    display: block;
    margin-top: 0.25rem;
`;

const SelectContainer = styled.div`
    position: relative;
`;

const ERROR_OFFSCREEN_TEXT = 'Error:';

const onDropDownBlur = (onBlur, refs, inputRef) => e => {
    const nextFocusedEl = e.relatedTarget;
    const { current: inputReference } = inputRef;
    const isOptionMenu = refs.current.some(item => item === nextFocusedEl);
    if (nextFocusedEl !== inputReference && !isOptionMenu) {
        onBlur(e);
    }
};

export const VCMSelectWithoutLabel = props => {
    const {
        onChange,
        itemlist,
        id,
        value,
        errortext,
        name,
        onBlur,
        inputclassname,
        disabled,
        arialabel,
        arialabelledby,
        classNames,
        shiftFocus
    } = props;
    let prevSelected = null;
    const newListItems = itemlist.map((item, index) => {
        let newItem;
        if (typeof item !== 'object') {
            let selected = false;
            if (value === index.toString()) {
                selected = true;
                prevSelected = index;
            }
            newItem = {
                value: item,
                key: index.toString(),
                selected,
            };
        } else {
            let selected = false;
            if (value === item.key) {
                selected = true;
                prevSelected = index;
            }
            newItem = { ...item, selected };
        }
        return newItem;
    });
    const [list, setList] = React.useState(newListItems);
    const [listOpen, setListOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(prevSelected);
    const [changed, setChanged] = React.useState(false);

    const refs = React.useRef([]);
    const inputRef = React.useRef(null);

    const selectItem = index => () => {
        let currentSelectedIndex;
        const newList = list.map((item, i) => {
            const newItem = { ...item };
            if (i === index) {
                newItem.selected = true;
                currentSelectedIndex = i;
            } else newItem.selected = false;
            return newItem;
        });
        setList(newList);
        if (selectedIndex === null || list[+selectedIndex].key !== list[+currentSelectedIndex].key) {
            setSelectedIndex(currentSelectedIndex);
            setChanged(true);
        }
        setListOpen(false);
        inputRef.current.focus();
    };

    const toggleList = () => () => {
        if (!disabled) {
            setListOpen(prevListOpen => !prevListOpen);
        }
    };

    React.useEffect(() => {
        if (changed) {
            const target = inputRef.current;
            target.value = list[+selectedIndex].key;
            onChange({ target, list });
            setChanged(false);
        }
    }, [list, onChange, changed, selectedIndex]);

    const close = React.useCallback(
        event => {
            if (listOpen && inputRef.current && !inputRef.current.contains(event.target)) {
                setListOpen(false);
            }
        },
        [listOpen],
    );

    React.useEffect(() => {
        window.addEventListener('click', close);
        return () => window.removeEventListener('click', close);
    }, [close]);

    React.useEffect(() => {
        if (listOpen) {
            const ind = selectedIndex || 0;
            if (refs.current.length > 0) {
                refs.current[+ind].focus();
            }
        }
    }, [listOpen, selectedIndex,id]);

    React.useEffect(() => {
        let prevSelectedd = null;
        const newListItemss = itemlist.map((item, index) => {
            let newItem;
            if (typeof item !== 'object') {
                let selected = false;
                if (value === index.toString()) {
                    selected = true;
                    prevSelectedd = index;
                }
                newItem = {
                    value: item,
                    key: index.toString(),
                    selected,
                };
            } else {
                let selected = false;
                if (value === item.key) {
                    selected = true;
                    prevSelectedd = index;
                }
                newItem = { ...item, selected };
            }
            return newItem;
        });
        setList(newListItemss);
        setSelectedIndex(prevSelectedd);
    }, [itemlist, value]);

    const thirtytwoNumber = 32;
    const SPACEBAR_KEY_CODE = [0, thirtytwoNumber];
    const ENTER_KEY_CODE = 13;
    const DOWN_ARROW_KEY_CODE = 40;
    const UP_ARROW_KEY_CODE = 38;
    const ESCAPE_KEY_CODE = 27;
    const TABB_KEY_CODE = 9;

    const focusListItem = (code, index) => {
        if (code === DOWN_ARROW_KEY_CODE && shiftFocus) {
            (index < list.length - 1) ? refs.current[+index + 1].focus() : refs.current[+index - (list.length - 1)].focus();
        } else if (code === DOWN_ARROW_KEY_CODE) {
            if (index < list.length - 1) {
                refs.current[+index + 1].focus()
            }
        } 
        
        if (code === UP_ARROW_KEY_CODE) {
            if (index > 0) {
                refs.current[+index - 1].focus();
            }
        }
    };

    const toggleHover = (index, mouseState) => () => {
        if (mouseState === 'enter') {
            refs.current[+index].focus();
        }
    };

    const onKeyEvent = index => e => {
        switch (e.keyCode) {
            case ENTER_KEY_CODE:
                selectItem(index)();
                break;

            case DOWN_ARROW_KEY_CODE:
                focusListItem(DOWN_ARROW_KEY_CODE, index);
                break;

            case UP_ARROW_KEY_CODE:
                focusListItem(UP_ARROW_KEY_CODE, index);
                break;

            case ESCAPE_KEY_CODE:
                setListOpen(false);
                break;

            case TABB_KEY_CODE:
                setListOpen(false);
                break;

            default:
                break;
        }
    };

    const toggleListVisibility = () => e => {
        const openDropDown = SPACEBAR_KEY_CODE.includes(e.keyCode) || e.keyCode === ENTER_KEY_CODE;
        if (e.keyCode === ESCAPE_KEY_CODE) {
            e.preventDefault();
            setListOpen(false);
        }

        if (openDropDown) {
            e.preventDefault();
            setListOpen(true);
        }
    };

    const setRef = index => el => {
        refs.current[+index] = el;
    };

    const dummyFn = () => {console.log('')};

    const erorrFieldName = errortext ? `errorInputField` : ``;
    const ariaDescribedBy = `${id}-error`;

    const describedBy = [];
    if (errortext) describedBy.push(ariaDescribedBy);
    const addtionalProps = {};
    if (describedBy.length) addtionalProps['aria-describedby'] = describedBy.join(' ');
    if (arialabel) addtionalProps['aria-label'] = arialabel;

    const errorStyleBorder = errortext ? { borderColor: '#E60000', borderWidth: '2px' } : {};
    return (
        <SelectContainer className={classNames}>
            <SelectDiv>
                <StyledInput
                    className={`analytics-form-fields ${erorrFieldName} ${inputclassname}`}
                    isOpen={listOpen}
                    id={id}
                    name={name}
                    ref={inputRef}
                    placeholder="Select"
                    value={selectedIndex !== null ? list[+selectedIndex].value : ''}
                    onChange={dummyFn}
                    onClick={toggleList()}
                    ontouchstart={toggleList()}
                    onBlur={onDropDownBlur(onBlur, refs, inputRef)}
                    onKeyDown={toggleListVisibility()}
                    role="combobox"
                    aria-expanded={listOpen}
                    aria-multiselect="true"
                    aria-hashpopup="listbox"
                    aria-labelledby={arialabelledby || id+ " distribution-frequency"}
                    autoComplete="off"
                    aria-invalid={!!errortext}
                    aria-describedby={errortext ? 'Topic error message' : null}
                    aria-owns="owned_list"
                    aria-activedescendant="selected_option"
                    style={errorStyleBorder}
                    aria-required="false"
                    disabled={disabled}
                    data-name={name}
                    data-form-field={!disabled}
                    {...addtionalProps}
                />
                <InputIcon>
                    <InputDownArrow onClick={toggleList()} onTouchStart={toggleList()} />
                </InputIcon>
            </SelectDiv>
            {errortext && (
                <ErrMsg
                    tabindex={errortext ? '0' : '-1'}
                    id={ariaDescribedBy}
                    className="errorMsgInputStyle analytics-error-field"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                    {errortext}
                </ErrMsg>
            )}
            {/* role='presentation' onClick={e => e.stopPropagation()} */}
            {listOpen && (
                <SelectBox>
                    <ItemContainer role="listbox" id="owned_list" aria-multiselectable="true">
                        {list.map((item, index) => (
                            <ItemSpan
                                // ref={refs.current[+index]}
                                ref={setRef(index)}
                                tabIndex={'0'}
                                autoFocus
                                aria-atomic="true"
                                aria-label={item.value}
                                role="option"
                                aria-expanded={listOpen}
                                key={item.key || index.toString()}
                                selected={item.selected}
                                onBlur={onDropDownBlur(onBlur, refs, inputRef)}
                                onClick={selectItem(index)}
                                onKeyDown={onKeyEvent(index)}
                                onMouseEnter={toggleHover(index, 'enter')}
                                onMouseLeave={toggleHover(index, 'leave')}
                                Id={item.selected ? 'selected_option' : ''}
                            >
                                {item.value}
                            </ItemSpan>
                        ))}
                    </ItemContainer>
                </SelectBox>
            )}
        </SelectContainer>
    );
};

VCMSelectWithoutLabel.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
    name: PropTypes.string,
    inputclassname: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    arialabel: PropTypes.string,
};

VCMSelectWithoutLabel.defaultProps = {
    id: '',
    onChange: () => {},
    onBlur: () => {},
    itemlist: [],
    value: '',
    errortext: '',
    name: '',
    inputclassname: '',
    required: false,
    disabled: false,
    arialabel: '',
};

const StyledRow = styled(Row)`
    margin-bottom: 1.5625rem;
    align-items: center;
`;

const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

const StyledOptionalSpan = styled.span`
    font: 500 12px/20px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 10px;
`;

const VCMSelect = props => {
    const { label, labelsm, labelmd, valuesm, valuemd, noGutters, optional, transactionsValueStyles, ...rest } = props;
    const { id } = rest;
    const optionalText = ' [Optional]';
    return (
        <StyledRow noGutters={noGutters} className="dropdownRow">
            <StyledLabel column sm={labelsm} md={labelmd} htmlFor={id}>
                {label}
                {optional && <StyledOptionalSpan>{optionalText}</StyledOptionalSpan>}
            </StyledLabel>
            <Col sm={valuesm} md={valuemd} style={transactionsValueStyles}>
                <VCMSelectWithoutLabel {...rest} />
            </Col>
        </StyledRow>
    );
};

VCMSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
    labelsm: PropTypes.number,
    labelmd: PropTypes.number,
    valuesm: PropTypes.number,
    valuemd: PropTypes.number,
    noGutters: PropTypes.bool,
    optional: PropTypes.bool,
    name: PropTypes.string,
    classNames: PropTypes.string,
    disabled: PropTypes.bool,
    shiftFocus: PropTypes.bool,
    transactionsValueStyles: PropTypes.instanceOf(Object),
};

VCMSelect.defaultProps = {
    labelsm: 2,
    labelmd: 2,
    valuesm: 4,
    valuemd: 4,
    id: '',
    label: '',
    onChange: () => {},
    onBlur: () => {},
    value: '',
    errortext: '',
    itemlist: ['item1', 'item2', 'item3', 'item4', 'item5', 'item6'],
    noGutters: false,
    optional: false,
    name: '',
    disabled: false,
    classNames: '',
    shiftFocus: false,
    transactionsValueStyles: {}
};

export default VCMSelect;
