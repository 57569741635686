import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessForms, loadMore } from '../../../shared/Actions/AccessFormsActions';
import { WBreadCrumb, WMessageBar, WSpinner } from '../../../common';
import ACCESS_FORMS_CONSTANTS from './Constants';
import FormsHeader from './FormsHeaders';
import FormList from './FormList';
import FormsFooter from './FormFooter';
import Styles from './Styles';

const breadCrumbValues = [{ url: '/accountholder', name: 'Home' }];
const breadCrumbValuesGuest = [{ url: '/accountguestview', name: 'Home' }];

function AccessForms() {
    const dispatch = useDispatch();
    const accessForms = useSelector(state => state.accessForms);
    function getForms(payload) {
        dispatch(getAccessForms(payload));
    }

    function loadMoreForms() {
        dispatch(loadMore());
    }

    useEffect(() => {
        dispatch(getAccessForms({ searchPayload: { tag: 'popular' }, authToken: localStorage.getItem('token') }));
    }, [dispatch]);

    return (
        <>
            {accessForms.showError && <WMessageBar />}
            <Styles.Container className="container">
                <WBreadCrumb
                    activeCrumb={ACCESS_FORMS_CONSTANTS.ACTIVE_BREAD_CRUM}
                    breadCrumbItems={
                        localStorage.getItem('role') === 'Member' ? breadCrumbValues : breadCrumbValuesGuest
                    }
                />
                {accessForms.showLoading && <WSpinner loading={accessForms.showLoading} />}

                <FormsHeader getAccessForms={getForms} />
                <FormList accessForms={accessForms} />
                <FormsFooter loadMore={loadMoreForms} showLoadMore={accessForms.showLoadMore} />
            </Styles.Container>
        </>
    );
}

export default AccessForms;
