import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    CommonButtons,
    TwoSectionPageTemplate,
    WSpinner,
    WMessageBar,
} from "common";
import {
    formatPhoneNumber,
    getFormattedSSN,
    formatDateToTwoDigit,
    decrypt
} from "utils";
import {
    getProfileBasicInformations,
    clearExistingActions,
} from "shared/Actions/ProfileSettingsAction";
import routeConstants from 'routeConstants';
import PageDescription from "./PageDescription";
import ReviewPersonalInfo from "./ReviewPersonalInfo";
import {
    PAGES,
    MSR_NUMBER,
    KEYS_VAL,
    PAGE_HEADING,
    LINE1_DESCRIPTION,
    LINE2_DESCRIPTION,
    LINE3_DESCRIPTION,
} from "./constants";

const backStyle = { display: 'none'};

// First Time Login - FTL
const FTLPersonalDetails = () => {
    const profileInformationData = useSelector(
        ({ profileInformationData: profileInfo = {} }) => profileInfo
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const [notification, setNotification] = React.useState(false);
    const [isErrorNotification, setIsErrorNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState(null);

    const {
        isSuccess,
        isError,
        profileInformation,
        hasProfileInfo,
        isLoading,
    } = profileInformationData;

    React.useEffect(() => {
        if (!hasProfileInfo) {
            dispatch(getProfileBasicInformations(decrypt));
        }
    }, [dispatch, hasProfileInfo]);

    React.useEffect(() => {
        if (isError) {
            setNotification(true);
            setIsErrorNotification(true);
            setNotificationMsg({ message: null });
        }
        if(isError || isSuccess) dispatch(clearExistingActions());
    }, [dispatch, isError, isSuccess]);

    const pageDescription = (
        <PageDescription
            line1={LINE1_DESCRIPTION}
            line2={LINE2_DESCRIPTION}
            line3={LINE3_DESCRIPTION}
            msr={MSR_NUMBER}
        />
    );

    const {
        memberId = "",
        prefix = "",
        firstName = "",
        middleInitial = "",
        lastName = "",
        suffix = "",
        ssnTin = "",
        dateOfBirth = "",
        emailInformation = {},
        militaryInformation = {},
        phoneInformation = [],
    } = profileInformation;

    const { primaryEmail = "" } = emailInformation || {};
    const { rank = "" } = militaryInformation || {};

    const getPrimary = (data) => {
        return data.find((item) => item.isPrimary) || {};
    };
    
    const getSecondary = (data) => {
        return data.find((item) => !item.isPrimary) || {};
    };
    
    const { phoneNumber:primaryNumber = '' } = getPrimary(phoneInformation);
    const { phoneNumber:secondaryNumber = '' } = getSecondary(phoneInformation);

    const userDetails = {
        memberId,
        prefix,
        firstName,
        middleInitial,
        lastName,
        suffix,
        rank,
        email: primaryEmail,
        ssnTin,
        dateOfBirth,
        primaryNumber,
        secondaryNumber,
    };

    const getFieldValue = React.useCallback(
        (key) => {
            let value = "-";
            const data = userDetails[key.toString()];
            if (data) {
                if (
                    key === "primaryNumber" ||
                    key === "secondaryNumber"
                ) {
                    value = formatPhoneNumber(data, true);
                } else if (key === "ssnTin") {
                    value = getFormattedSSN(data, true);
                } else if (key === "dateOfBirth") {
                    value = formatDateToTwoDigit(new Date(data));
                } else {
                    value = data;
                }
            }

            return value;
        },
        [userDetails]
    );

    const pageInformation = (
        <ReviewPersonalInfo
            personalInfoFields={KEYS_VAL}
            getFieldValue={getFieldValue}
        />
    );
    const currentPageIndex = 3;

    const handleNext = React.useCallback(() => {
        // handle next action
        history.push(routeConstants.manageAddressDetails);
    }, [history]);

    const onNotificationClose = React.useCallback(() => {
        setNotification(false);
    }, [setNotification]);

    return (
        <>
            {isLoading && (
                <WSpinner loading={isLoading} />
            )}
            {notification && (
                <WMessageBar
                    error={isErrorNotification}
                    onClose={onNotificationClose}
                    text={notificationMsg}
                />
            )}
            <TwoSectionPageTemplate
                headingTxt={PAGE_HEADING}
                pageDescription={pageDescription}
                pageDetails={PAGES}
                pageInformation={pageInformation}
                currentPageIndex={currentPageIndex}
            />
            <CommonButtons nextClick={handleNext} backstyle={backStyle} />
        </>
    );
};

export default FTLPersonalDetails;
