import { accOpeningActions, transactionCompositeDataAction } from '../../../shared/Actions';
import BeneficiaryForm from './BeneficiaryForm';
import { connect } from 'react-redux';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    profileInformationData: state.profileInformationData,
    marketNAOReducerData: state.marketNAOReducerData
});

const mapDispatchToProps = {
    ...accOpeningActions,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BeneficiaryForm);
