import React from 'react';
import PropTypes from 'prop-types';
import './RadioInput.css';

const Radio = (props) => {
  const {
    value,
    desc,
    selected,
    radioFor,
    onClick,
    id,
    className,
    onBlur,
    ariadescribedby,
    ariainvalid,
    dataDisabled,
  } = props;
  let radioClass = 'wradio';
  radioClass += selected ? ' selected' : '';
  const onChangeRadio = (e) => {
    onClick(e);
  };
  const onBlurRadio = (e) => {
    if (onBlur) {
      setTimeout(() => onBlur(e), 200);
    }
  };
  return (
    <div className={`radioWrapper ${radioClass}`}>
      <input
        id={id}
        name={radioFor}
        type="radio"
        onChange={onChangeRadio}
        className={className}
        onBlur={onBlurRadio}
        checked={selected}
        aria-describedby={ariadescribedby || null}
        aria-invalid={ariainvalid}
        aria-label={value}
        aria-checked={selected}
        disabled={dataDisabled}
      />
      <label className="radioLabel" htmlFor={id} aria-hidden="true">
        {/* {value} */}
        <span className="radioText">{value}</span>
        <span className={desc ? 'radioWithDesc' : ''}>
          <span className="radioDesc">{desc}</span>
        </span>
      </label>
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  dataDisabled: PropTypes.bool,
  radioFor: PropTypes.string,
  value: PropTypes.string,
  desc: PropTypes.string,
  ariadescribedby: PropTypes.string,
  ariainvalid: PropTypes.bool,
};

Radio.defaultProps = {
  id: '',
  className: '',
  onBlur: null,
  onClick: () => {},
  selected: false,
  dataDisabled: false,
  value: 'Radio Label',
  desc: '',
  radioFor: 'defaultRadio',
  ariadescribedby: '',
  ariainvalid: false,
};

export default Radio;
