export default {
    heading: 'Terms and Conditions',
    desc1: 'This is sample content paragraph ',
            desc2:`This is sample content paragraph`,
            desc3: `This is sample content paragraph`,
            subheading:"Basic Terms",
            list1: `This is sample content paragraph`,
            list2: ' This is sample content paragraph',
            list3: `This is sample content paragraph`,
            listItems: [
               `This is sample content paragraph`,
               `This is sample content paragraph`,
               `This is sample content paragraph`
            ],
    checkBoxLabel:
        'By checking this box, I acknowledge that I have read the preceding terms and conditions and that I understand, assent to, and agree to comply with, those terms and conditions',
    iconStyle: { display: 'inline' },
    faStyle: { height: 18, width: 15, margin: '0 10 0 0', color: '#8CBF26' },
    checkBoxLabelStyle: {
        paddingTop: '3px',
        textAlign: 'left',
        maxWidth: '742px',
        font: '600 16px/22px benton-sans',
        letterSpacing: 0,
        color: '#486D90',
        opacity: 1,
    },
    checkBoxWrapperStyle: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '20px',
    },
    checkInputsStyle: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #4F799F',
        borderRadius: 3,
        opacity: 1,
        height: 30,
        width: 30,
    },
    redirectTo: { pathname: '/openAccount', state: { referrer: 'currentLocation' } },
    commonButtonStyle: { padding: 20 },
    cancelStyle: { display: 'none' },
    radioError: 'Please agree to terms to continue',
};
