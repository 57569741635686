import Consts from './Consts';

export default function generateContributionYearCard(masterLookupStateData, data) {
    let contributionYearCard = {};
    const { staticfield } = Consts;
    const { OpenAccPageFour = {} } = data;
    const { iraContributionYear } = OpenAccPageFour.investmentInfo;
    let iraContributionYearValue = '';
    if (iraContributionYear === '0') {
        iraContributionYearValue = 'Current Year';
    } else if (iraContributionYear === '1') {
        iraContributionYearValue = 'Previous Year';
    }

    if (data) {
        contributionYearCard = {
            cardId: 'iraContributionInfo',
            linkText: Consts.edit,
            cardHeading: Consts.contributionYearLabel,
            fields: [
                {
                    uniqueKey: 'iraContribution',
                    key: Consts.contributionYearKey,
                    value: iraContributionYearValue || Consts.None,
                    type: staticfield,
                },
            ],
        };
    }
    return contributionYearCard;
}
