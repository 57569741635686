/* eslint-disable class-methods-use-this */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import { formatDate, isValidDate, decrypt } from 'utils';
import routeConstants from 'routeConstants';
import { WStepper as UserMngmtStepper } from  'common';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import classes from '../Input/Input.module.css';
import { VCMDatePickerWithoutLabel, Captcha, CommonButtons} from '../../../common';
import { inputChangedHandler } from '../FormUtils';
import styles from './styles';
import SideHeader from '../SideHeader/SideHeader';
import data from './MemberDobValidationConstants';

const assignObj = (obj) => { return obj; }

class MemberDobValidation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        dob: {
          value: props.otpDetails.dob,
          errMsg: null,
          valid: false,
          touched: false,
          rules: {
            required: true,
            dobDate: true
          }
        }
      },
      formIsValid: props.otpDetails.dobVerified,
      dobMatched: false,
      captchaValidated: false,
      cancelled: false,
      redirectBack: ''
    };
    this.target = React.createRef();
    this.onNext = this.onNext.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.onCaptchaErrored = this.onCaptchaErrored.bind(this);
  }

  componentWillUnmount() {
    const { analyzeFormAdandonment } = this.props;
    const digitalData = getDigitalDataFormAband();
    analyzeFormAdandonment(digitalData);
  }

  onCaptchaChange = (val) => {
    // TODO: add action after captcha success

    let validCaptcha = false;
    if(val) {
        validCaptcha = true;
    }
    this.setState({
      captchaValidated: validCaptcha
    });
  };

  onCaptchaErrored = () => {
    this.setState({
      captchaValidated: false
    })
  }

  onBack = () => {
    const { location } = this.props;
    const redirectBack = {
      pathname: routeConstants.memberSignUp,
      previousState: location.pathname
    };
    this.setState({cancelled: true, redirectBack});
}

  onNext() {
    // ToDo: Remote code from while merging code
    // analytics.triggerClickTrackEvent('Preregister_MemberDobValidation_Submit');
    
    const { dateOfBirth, history, pageData, manageLocalState, otpDetails } = this.props;
    const { form, dobMatched, captchaValidated } = this.state;

    if (!dobMatched) { 
      const { dob } = form;
      const { value } = dob;
      const newFormatDate = value.replace(/\//g, '-');
      (async () => {
        const decryptDOB = await decrypt(dateOfBirth);
        if (decryptDOB === newFormatDate) {
          this.setState((state) => ({ dobMatched: true, form: { ...state.form, dob: { ...state.form.dob, valid: true, errMsg: null } } }), () => {
            if (captchaValidated) {
              const updatedPageData = {
                ...pageData,
                backLink: routeConstants.memberDobValidation,
              }
              const updatedOtpDetails = {
                ...otpDetails,
                dob: value,
                dobVerified: true
              }
              manageLocalState({pageData: updatedPageData, otpDetails: updatedOtpDetails});
              history.push({
                pathname: routeConstants.termsAndConditions,
                failure: false,
              });
            }
          });
        } else {
          this.setState((state) => ({ dobMatched: false, form: { ...state.form, dob: { ...state.form.dob, valid: false, errMsg: data.dobValError } } }));
        }
      })();
    }
 }

  onChange = (e, fieldId) => {
    const { value } = e.target;
    if (value !== 'Error') {
      inputChangedHandler(e, fieldId, this)
    } else {
      this.setState((state) => ({ ...state, formIsValid: false, form: { ...state.form, dob: { ...state.form.dob, valid: false } } }))
    }
  }

  render() {
    const { form, formIsValid, captchaValidated, cancelled, redirectBack } = this.state;
    const { pageData } = this.props;
    const { dob } = form;
    const startDateMin = new Date();
    const { stepperPage } = pageData;
    if (cancelled) {
      return <Redirect to={redirectBack} />
    }
    return (
      <div className="milestone">
        <div className="memberStepper" ><UserMngmtStepper currentPage={0} pages={stepperPage} /></div>
          <div className="container-fluid">
            <div className="container custom-container">
              <Row className="main">
                <Col md lg xl>
                  <SideHeader
                    title={data.title}
                    nameTitle={data.subtitle}
                    info={data.info} />
                </Col>
                <Col md lg xl>
                  <Container style={assignObj({ margin: 0 })}>
                    <Row>
                      <Col className="noPad">
                        <label htmlFor="dob" className="inputLabel">
                          {dob.dateOfBirthLabel}
                        </label>
                        <form>

                          <Col sm={12} style={styles.dobStyle}>
                            <div className={classes.Input} aria-required="true">
                              <label className={classes.Label} htmlFor="dob">
                                {data.dobConfig.label}
                              </label>
                              <VCMDatePickerWithoutLabel
                                name="dateofBirth"
                                id="dob"
                                max={isValidDate(startDateMin) ? formatDate(startDateMin) : ''}
                                label={data.dobConfig.label}
                                onChange={Object.assign(e => this.onChange(e, 'dob'))}
                                errortext={dob.errMsg}
                                value={dob.value}
                                dobValidation
                              />
                            </div>
                          </Col>
                          <div className="dateOfBirthCaptcha">
                            <Captcha
                              onCaptchaChange={this.onCaptchaChange}
                              onCaptchaExpired={this.onCaptchaExpired}
                              onCaptchaErrored={this.onCaptchaErrored}
                            />
                          </div>

                        </form>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <CommonButtons backstyle={styles.hide} backClick={this.onBack} nextClick={this.onNext} disabled={!(formIsValid && captchaValidated)} blockstyle={styles.commonButtons} />
            </div>
          </div>
      </div>
    );
  }
}

MemberDobValidation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  dateOfBirth: PropTypes.string,
  otpDetails: PropTypes.shape({
    dob: PropTypes.string,
    dobVerified: PropTypes.bool,
  }),
  pageData: PropTypes.shape({
    stepperPage: PropTypes.array, 
    isMemberSignUp: PropTypes.bool,
    isProspectSignUp: PropTypes.bool,
  }),
  manageLocalState: PropTypes.func,
  location: PropTypes.instanceOf(Object),
};

MemberDobValidation.defaultProps = {
  history: {},
  pageData: {
    stepperPage: [], 
    isMemberSignUp: false,
    isProspectSignUp: false,
  },
  dateOfBirth: '',
  otpDetails: {
    dob: '',
    dobVerified: false
  },
  manageLocalState: () => {},
  location: {},
};

export default MemberDobValidation;
