import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { RmdActions } from 'shared/Actions';
import { get } from 'lodash';
import { ConformationModal, Accordian, AccordianTitle } from '../../../../common';

import {
    FWH_TEXT,
    DAT_TEXT,
    GROUP_2B_TEXT,
    GROUP_3_TEXT,
    GROUP_4_TEXT,
    GROUP_5_TEXT,
    TAX_WITHHOLD_WARNING,
    STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS,
    STATE_TAX_MIN_VALUE_WARNING_MSG,
    GROUP_1_TEXT,
    AMOUNT_LABEL,
    DOLLAR_SYMBOL,
    PERCENT_SYMBOL,
    DIST_AMOUNT_INPUT_NAME,
    FEDERAL_TAX_LABEL,
    FEDERAL_TAX_INPUT_NAME,
    WITHHOLDING_OPTIONS_TEXT,
    RADIO_BUTTON_TYPE,
    DO_NOT_WITHHOLD_TAX_TEXT,
    WITHHOLD_TAX_TEXT,
    STATE_TAX_ON_FEDTAX_TEXT,
    STATE_TAX_LABEL,
    STATE_TAX_INPUT_NAME,
    GROUP5_TAX_WARNING_MSG,
    TOTAL_TAXES_TO_WITHHOLD_LABEL,
    TOTAL_RECEIVED_LABEL,
    TOTAL_WITHDRAWAL,
    TAX_WITHHOLD_TITILE
} from './TransactionConstants';
import {currencyFormatterFunction,currencyFormatterWithoutDollarSign} from '../../../../common/Formatter/CurrencyFormatter';
import { getSessionValues } from 'shared/Helpers/Utils';
import { getDataFromORCondition } from 'utils/utils';
import { checkDecimalValueLengthMoreTheOneAndSingleEquality, checkDecimalValueLengthMoreTheTwo, checkStateCodeGroupAndFedTax, checkStateCodeGroupWithOneValue, getCalculatedGroupStateTax, getDisabledStateForTaxInput, getGroup3DistAmountMinRule, getGroup4DistAmountMinRule, getGroup5DistAmountMinRule, getStateCodeWIthStateTaxWithHold, getStateCodeWithAgeAndTotalTaxValue, getStateCodeWithAgeValue, getStateTaxStateCodeAndAge, getStatusWithTwoAndAndOneEqunityCondition, getValueGretherThen_99_AND_100, handleDecimalTwoValue, handleFedTaxKeyDownValue, handleStateTaxKeyDownValue, isStateTaxGroup, validateStateTaxMinRule,getSubStringValue,getValueBasedOnIndex } from './TaxSelectorHelper';
import { checkSingleEqualityAndReturnValue, getDataFromANDCondition, getValidValue } from 'commonHelper/CommonHelperFunctions';

const StyledInput = styled.input`
    width: 200px;
    height: 48px;
    border-radius: 0px;
    padding-left: 10px;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
    }
`;

const StyledInputFed = styled.input`
    width: 230px;
    height: 48px;
    border-radius: 0px;
    padding-left: 10px;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
    }
`;

const StyledDollar = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin: 0 5px;
`;

const StyledLabel = styled.div`
    line-height: 48px;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    opacity: 1;
    color: #333333;
    padding-left: 0;
`;

const InputRadioText = styled.span`
    margin-left: 10px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
`;

const TaxWarning = styled.p`
    color: #ff0000;
    font-weight: 600;
    font-family: benton-sans, sans-serif;
    font-size: 14px;
`;

const ContainerComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const TaxOptionsRow = styled(FlexRow)`
    margin-bottom: 10px;
`;

const StateTaxRow = styled(FlexRow)`
    margin-top: 25px;
`;

const StyledRadioBtn = styled.input`
    transform: scale(1.5);
    //  border: 1px solid rgba(73, 73, 74, 1);
`;
const buttonstyle = {
    width:"180px"
}

export const handleErrorWhenStateGroup_1 = (stateCodeGroup,fedTax,setIsError,setErrorObj,setTaxValidation,calculateTotalTaxes,stateTaxPercent) =>
{
    if (stateCodeGroup === GROUP_1_TEXT) {
        const taxValidation = fedTax > 99;
        setIsError(taxValidation);
        setErrorObj(TAX_WITHHOLD_WARNING);
        setTaxValidation(taxValidation);
    } else {
        calculateTotalTaxes(stateTaxPercent, fedTax);
    }
}

export const handleStateCodeWhenGroup_3 = (stateCodeGroup,fedTax,federalTaxAmount,setstateTaxWithHold,setStateTaxDoNotWithHold,setDisableRadioBtn,getDefaultGroupStateTax) =>
{
    if (stateCodeGroup === GROUP_3_TEXT) {
        if (fedTax) {
            setstateTaxWithHold(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
        } else {
            // Enabling radio buttons
            setDisableRadioBtn(false);
        }
        getDefaultGroupStateTax(federalTaxAmount);
    }
    if (checkStateCodeGroupAndFedTax(stateCodeGroup,GROUP_4_TEXT,GROUP_5_TEXT,fedTax,0)) {
        setstateTaxWithHold(true);
        setStateTaxDoNotWithHold(false);
        getDefaultGroupStateTax(federalTaxAmount);
    }
}

export const handleGroup3_4_5 = (group4DistAmountMinRule,group3DistAmountMinRule,group5DistAmountMinRule,getDefaultGroupStateTax,setstateTaxWithHold,setStateTaxDoNotWithHold,setDisableRadioBtn,setStateTaxPercent) =>
{
    if (group4DistAmountMinRule) {
        getDefaultGroupStateTax();
        setstateTaxWithHold(true);
        setStateTaxDoNotWithHold(false);
        setDisableRadioBtn(true);
    }
    if (group3DistAmountMinRule) {
        setstateTaxWithHold(true);
        setStateTaxDoNotWithHold(false);
        setDisableRadioBtn(true);
        setStateTaxPercent('30.00');
    }
    if (group5DistAmountMinRule) {
        getDefaultGroupStateTax();
        setstateTaxWithHold(true);
        setStateTaxDoNotWithHold(false);
        setDisableRadioBtn(true);
    }
}

export const isSWPFlowForInput = (isSWP,amount) =>
{
    return (
        isSWP ?
        <FlexRow >
            <StyledLabel >{AMOUNT_LABEL}</StyledLabel>
            <FlexRow>
                <StyledDollar>{DOLLAR_SYMBOL}</StyledDollar>
                <StyledInput
                    data-test="inputAmount"
                    disabled
                    value={currencyFormatterWithoutDollarSign(amount)}
                    name={DIST_AMOUNT_INPUT_NAME}
                />
            </FlexRow>
        </FlexRow>
        : ''
    )
}

export const isSwpFlow = (isSWP,fedTaxAmount) =>
{
    return (
        isSWP ? <>
            <StyledDollar data-test="fedTaxAmount">
                {currencyFormatterFunction(fedTaxAmount.toFixed(2))}
            </StyledDollar>
        </>
            : ''
    )
}

export const isSwpFlowForStateTaxAmount = (isSWP,stateTaxAmount) =>
{
    return (
        isSWP ?
            <>
                <StyledDollar data-test="stateTaxAmount">
                    
                </StyledDollar>
                <StyledDollar data-test="stateTaxAmount">
                    {currencyFormatterFunction(stateTaxAmount.toFixed(2))}
                </StyledDollar>
            </>
            : ''
    )
}
function TaxOptionsToWithHold(props) {

    const {
        isSWP,
        amount,
        fedTaxPercentRef,
        stateTaxPercentRef,
        getStateTaxesForGroup,
        getStateCodeGroup,
        setTaxStateValues,
        setTaxValidation,
        residenceState,
        masterRegId,
        onTaxChange,
        // amount,
        // stateTaxPercentRef,
        // fedTaxPercentRef,
        stateTaxWithHold: stateWithhold,
        taxRef,
        fedTaxPercent: fedPercent,
        stateTaxPercent: statePercent,
    } = props;

    const [fedTaxAmount, setFedTaxAmount] = useState(0.0);
    const [stateTaxAmount, setStateTaxAmount] = useState(0.0);
    const [fedTaxPercent, setFedTaxPercent] = useState(getDataFromORCondition(fedPercent,0));
    const [stateTaxPercent, setStateTaxPercent] = useState(getDataFromORCondition(statePercent,0));
    const [stateTaxWithHold, setstateTaxWithHold] = useState(getDataFromORCondition(stateWithhold,false));
    const [stateTaxDoNotWithHold, setStateTaxDoNotWithHold] = useState(!stateWithhold);
    const [errorObj, setErrorObj] = useState('');
    const [isError, setIsError] = useState(false);
    const [disableRadioBtn, setDisableRadioBtn] = useState(false);
    const [fedTaxDecimalFound, setFedTaxDecimalFound] = useState(false);
    const [stateTaxDecimalFound, setStateTaxDecimalFound] = useState(false);

    const [openConformationDialog, setOpenConformationDialog] = useState(false);
    const [isFedTaxSelected, setIsFedTaxSelected] = useState(false);
    const [prevFedTaxPercent, setPrevFedTaxPercent] = useState(getDataFromORCondition(fedPercent,0));
    const [prevStateTaxPercent, setPrevStateTaxPercent] = useState(getDataFromORCondition(statePercent,0));

    const groupStateTax = useSelector(state => state.rmdReducerData.groupStateTax);
    const stateCodeGroup = useSelector(state => state.rmdReducerData.stateCodeGroup[0]);
    const age = useSelector(state => state.rmdReducerData.age);



    const getStateCode = useCallback(() => {
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues(); 
        const {accesstoken, vcmid, msrid, fismemberid} = params;       
        const stateCodeGroupPayload = {
            companyNumber: '591',
            masterReg: masterRegId,
            accesstoken,
            vcmid,
            msrid,
            fismemberid,
        };
        getStateCodeGroup(stateCodeGroupPayload);

    }, [masterRegId, getStateCodeGroup]);

    useEffect(() => {
        getStateCode()
    }, [getStateCode, residenceState]);

    /**
     * Checks if fed tax is 0 when basis is 'FWH'. If yes, then to avoid Infinity value, stateTax should not be divided by fed tax
     */

    const groupStateTaxPercent = getCalculatedGroupStateTax(groupStateTax,FWH_TEXT,fedTaxAmount,DAT_TEXT,amount);

    const isSetStateTax = isStateTaxGroup(stateCodeGroup,GROUP_2B_TEXT,GROUP_3_TEXT,GROUP_4_TEXT,GROUP_5_TEXT)

    const group3DistAmountMinRule = getGroup3DistAmountMinRule(stateCodeGroup,GROUP_3_TEXT,residenceState,amount);

    const group4DistAmountMinRule = getGroup4DistAmountMinRule(stateCodeGroup,GROUP_4_TEXT,residenceState,amount);

    const group5DistAmountMinRule = getGroup5DistAmountMinRule(stateCodeGroup,GROUP_5_TEXT,residenceState);


    let fedTax = 0;
    let stateTax = 0;

    /**
     * Validates if total tax is <= 99 %
     */
    const calculateTotalTaxes = useCallback(
        (stateTaxPercentValue = stateTaxPercent, fedTaxPercentValue = fedTaxPercent) => {
            const totalTax =
                (checkSingleEqualityAndReturnValue(fedTax,0,fedTaxPercentValue,fedTax)) + (checkSingleEqualityAndReturnValue(stateTax,0,+stateTaxPercentValue,+stateTax));
            const taxValidation = +totalTax > 99;
            setIsError(taxValidation);
            setTaxValidation(taxValidation);
            setErrorObj(TAX_WITHHOLD_WARNING);
        },
        [stateTaxPercent, fedTaxPercent, fedTax, stateTax, setTaxValidation],
    );

    useEffect(() => {
        setFedTaxAmount(fedTaxAmount);
        setStateTaxAmount(stateTaxAmount);
    }, [fedTaxAmount, stateTaxAmount]);

    useEffect(() => {
        if (getDataFromANDCondition(isSetStateTax,stateTaxWithHold)) {
            setStateTaxAmount(parseFloat(groupStateTax.stateTax));
            setStateTaxPercent(groupStateTaxPercent);
            calculateTotalTaxes(groupStateTaxPercent);
        }
        if (getStatusWithTwoAndAndOneEqunityCondition(isSetStateTax,stateTaxWithHold,residenceState === 'VT')) {
            setStateTaxAmount(parseFloat(fedTaxAmount * 30) / 100);
            setStateTaxPercent('30.00');
            calculateTotalTaxes('30.00');
        }
    }, [groupStateTaxPercent, groupStateTax, isSetStateTax, stateTaxWithHold, calculateTotalTaxes, residenceState, fedTaxAmount]);

    useEffect(() => {
        const taxPayload = {
            fedTaxAmount,
            fedTaxPercent,
            stateTaxAmount,
            stateTaxPercent,
            totalReceived: amount.toFixed(2) - (fedTaxAmount + stateTaxAmount).toFixed(2),
            // distributionAmount: amount,
            totalTaxAmount: (fedTaxAmount + stateTaxAmount).toFixed(2),
            stateTaxWithHold,
        };
        setTaxStateValues(taxPayload);
    }, [fedTaxAmount, fedTaxPercent, stateTaxAmount, stateTaxPercent, amount, stateTaxWithHold, setTaxStateValues]);

    /**
     * Gets the state tax requirements
     * Group2b, Group 3, Group 4, Group 5
     */
    const getDefaultGroupStateTax = useCallback((federalTaxAmount = fedTaxAmount) =>{
        const stateTaxPayload = {
            state: residenceState,
            fedWithHolding: `${federalTaxAmount}`,
            distributionAmount: `${amount}`,
        };

        const params = getSessionValues(); 
        const {accesstoken, vcmid, msrid, fismemberid} = params;       

        getStateTaxesForGroup({ stateTaxPayload, accesstoken, vcmid,msrid,fismemberid });

    },[amount, fedTaxAmount, getStateTaxesForGroup, residenceState])

    useEffect(()=>{
        if(isSetStateTax){
            getDefaultGroupStateTax()
        }
    },[amount, isSetStateTax, getDefaultGroupStateTax])

    useEffect(() => {
        handleGroup3_4_5(group4DistAmountMinRule,group3DistAmountMinRule,group5DistAmountMinRule,getDefaultGroupStateTax,setstateTaxWithHold,setStateTaxDoNotWithHold,setDisableRadioBtn,setStateTaxPercent);
    }, [group4DistAmountMinRule, group3DistAmountMinRule, group5DistAmountMinRule, getDefaultGroupStateTax]);

    /**
     * Validate the state tax input from the user.
     * Throw error if input is less than the minimum required state tax percentage
     * Group2b Validation
     */

    useEffect(() => {
        const fedPercentTax = checkSingleEqualityAndReturnValue(fedTax,0,fedTaxPercent,fedTax);
        const federalTaxAmount = (amount * fedPercentTax) / 100;
        setFedTaxAmount(federalTaxAmount);
    }, [amount, fedTax, fedTaxPercent])

    useEffect(() => {
        const taxAmount = checkSingleEqualityAndReturnValue(groupStateTax.basis,FWH_TEXT,fedTaxAmount,amount)
        setStateTaxAmount((taxAmount * stateTaxPercent) / 100);
        calculateTotalTaxes(stateTaxPercent);
    }, [amount, calculateTotalTaxes, fedTax, fedTaxAmount, fedTaxPercent, groupStateTax, stateTax, stateTaxPercent])



    const handleBlur = useCallback(() => {
        if (fedTaxPercent !== prevFedTaxPercent) {
            setOpenConformationDialog(fedTaxPercent !== prevFedTaxPercent)
            onTaxChange(fedTaxPercent, stateTaxPercent);
        } else if (stateTaxPercent !== prevStateTaxPercent) {
            setOpenConformationDialog(stateTaxPercent !== prevStateTaxPercent)
            onTaxChange(fedTaxPercent, stateTaxPercent);
        }
    }, [fedTaxPercent, onTaxChange, prevFedTaxPercent, prevStateTaxPercent, stateTaxPercent])



    /**
     * Captures Federal Tax user input
     */
    function handleFederalTax(e) {
        // e.persist();
        /* To restrict number of digits before and after decimal */
        const decimalValue = e.target.value.split('.');
        
        setFedTaxDecimalFound(handleDecimalTwoValue(decimalValue));
        e.target.value = getSubStringValue(decimalValue,e)

        if (getValueGretherThen_99_AND_100(e,decimalValue)) {
            e.preventDefault();
            return false;
        }
        e.target.value = getValueBasedOnIndex(decimalValue,e)
        /* ----END OF NUMBER OF DIGITs CHECK---- */

        fedTax = getDataFromANDCondition(e?.target?.value,parseFloat(e?.target?.value));
        const federalTaxAmount = (amount * fedTax) / 100;
        setFedTaxAmount(federalTaxAmount);
        setFedTaxPercent(fedTax);
        setIsFedTaxSelected(true);


        handleErrorWhenStateGroup_1(stateCodeGroup,fedTax,setIsError,setErrorObj,setTaxValidation,calculateTotalTaxes,stateTaxPercent);

        if (getStateCodeWithAgeValue(stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,'MS')) {
            getDefaultGroupStateTax(federalTaxAmount);
        }

        if (getStateCodeWIthStateTaxWithHold(stateCodeGroup,GROUP_2B_TEXT,stateTaxWithHold,residenceState,'DC')) {
            // No check for premature distribution required for group2b states other than MS
            getDefaultGroupStateTax(federalTaxAmount);
        }

        handleStateCodeWhenGroup_3(stateCodeGroup,fedTax,federalTaxAmount,setstateTaxWithHold,setStateTaxDoNotWithHold,setDisableRadioBtn,getDefaultGroupStateTax);
        return '';
    }

    /**
     * Captures State Tax user input
     */
    function handleStateTax(e) {
        // e.persist();
        /* To restrict number of digits before and after decimal */
        const decimalValue = e.target.value.split('.');
        setFedTaxDecimalFound(handleDecimalTwoValue(decimalValue));
        e.target.value = getSubStringValue(decimalValue,e)

        if (getValueGretherThen_99_AND_100(e,decimalValue)) {
            e.preventDefault();
            return false;
        }

        e.target.value = getValueBasedOnIndex(decimalValue,e)

        /* ----END OF NUMBER OF DIGITs CHECK---- */

        const taxAmount = checkSingleEqualityAndReturnValue(groupStateTax.basis,FWH_TEXT,fedTaxAmount,amount)
        stateTax = e.target.value;
        setStateTaxAmount((taxAmount * stateTax) / 100);
        setStateTaxPercent(stateTax);
        calculateTotalTaxes(stateTax);

        const totalTaxes = parseFloat(stateTax) + fedTaxPercent;
        if (getStateCodeWithAgeAndTotalTaxValue((stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,'MS',totalTaxes))
        ) {
            validateStateTaxMinRule(setIsError,setErrorObj,setTaxValidation,stateTax,groupStateTaxPercent,stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS,STATE_TAX_MIN_VALUE_WARNING_MSG);
        }

        return '';
    }

    /**
     * Withhold taxes
     */
    function handleStateTaxWithHold() {
        setstateTaxWithHold(true);
        setStateTaxDoNotWithHold(false);
        if (getStateTaxStateCodeAndAge(isSetStateTax,stateCodeGroup,GROUP_2B_TEXT,age,59.5)) {
            getDefaultGroupStateTax();
        }
        props.handleStateTaxWithHold(true)

    }

    /**
     * Do Not Withhold taxes
     */
    function handleStateTaxDoNotWithHold() {
        setStateTaxDoNotWithHold(true);
        setstateTaxWithHold(false);
        setStateTaxAmount(0.0);
        setStateTaxPercent(0);
        setIsError(false);
        setTaxValidation(false);
        setErrorObj('');
        calculateTotalTaxes(stateTaxPercent, fedTaxPercent);
        props.handleStateTaxWithHold(false)
    }

    function handleStateTaxKeyDown(e) {
        /* Logic to prevent user to input more than one decimal point */

        return handleStateTaxKeyDownValue(e,setStateTaxDecimalFound,stateTaxDecimalFound);

    }

    function handleFedTaxKeyDown(e) {
        /* Logic to prevent user to input more than one decimal point */
        return handleFedTaxKeyDownValue(e,setFedTaxDecimalFound,fedTaxDecimalFound);

    }
    function handleScroll(e) {
        e.currentTarget.blur();
    }

    const onConformationUpdate = useCallback(() => {
        setOpenConformationDialog(false);
        setIsFedTaxSelected(false);
        setPrevFedTaxPercent(fedTaxPercent);
        setPrevStateTaxPercent(stateTaxPercent);
    }, [fedTaxPercent, stateTaxPercent])

    const onConformationCancel = useCallback(() => {
        setOpenConformationDialog(false);
        setIsFedTaxSelected(false);
        setFedTaxPercent(prevFedTaxPercent);
        setStateTaxPercent(prevStateTaxPercent);
    }, [prevFedTaxPercent, prevStateTaxPercent])

    const modalBodyText = `You decided to change the federal tax withholding amount from 
    ${getValidValue(isFedTaxSelected,prevFedTaxPercent,prevStateTaxPercent)} 
    to ${getValidValue(isFedTaxSelected,fedTaxPercent,stateTaxPercent)}.
     This change will affect all future transactions.`

    const renderTaxComponent = (
        <ContainerComponent data-test="taxContainerComponent">
            {openConformationDialog && (
                <ConformationModal
                    modalTitle="Please confirm"
                    modalBodyText={modalBodyText}
                    modalBodyText2="Do you want to update the default withholding for your account?"
                    primaryButtonText="Yes, apply the tax withholding updates"
                    secondaryButtonText="No, go back"
                    onPrimaryClick={onConformationUpdate}
                    onSecondaryClick={onConformationCancel}
                    waitforapiresponse={false}
                    buttonstyle={buttonstyle}
                // dataAnalyticId={}
                // analyzecustom={}
                />
            )}

            {isSWPFlowForInput(isSWP,amount)}

            <FlexRow data-test="fedTaxSection">
                <StyledLabel >{FEDERAL_TAX_LABEL} {PERCENT_SYMBOL}</StyledLabel>
                <FlexRow >
                    <StyledInputFed
                        type="number"
                        ref={fedTaxPercentRef}
                        onKeyDown={handleFedTaxKeyDown}
                        data-test="fedTaxPercent"
                        name={FEDERAL_TAX_INPUT_NAME}
                        onChange={handleFederalTax}
                        onBlur={handleBlur}
                        value={fedTaxPercent}
                        maxlength={6}
                        onWheel={handleScroll}
                    />
                    {isSwpFlow(isSWP,fedTaxAmount)}

                </FlexRow>
            </FlexRow>
            {checkStateCodeGroupWithOneValue(stateCodeGroup,GROUP_1_TEXT) ? (
                <>
                    <FlexRow>
                        <StyledLabel>{WITHHOLDING_OPTIONS_TEXT}</StyledLabel>
                    </FlexRow>
                    <TaxOptionsRow data-test="stateTaxWithholdSection">
                        <Col >
                            <StyledRadioBtn
                                type={RADIO_BUTTON_TYPE}
                                disabled={disableRadioBtn}
                                value={DO_NOT_WITHHOLD_TAX_TEXT}
                                checked={stateTaxDoNotWithHold}
                                onChange={handleStateTaxDoNotWithHold}
                                data-test="stateTaxDoNotWithholdTax"
                            />
                            <InputRadioText>{DO_NOT_WITHHOLD_TAX_TEXT}</InputRadioText>
                        </Col>
                        <Col >
                            <StyledRadioBtn
                                type={RADIO_BUTTON_TYPE}
                                disabled={disableRadioBtn}
                                value={WITHHOLD_TAX_TEXT}
                                checked={stateTaxWithHold}
                                onChange={handleStateTaxWithHold}
                                data-test="stateTaxWithholdTax"
                            />
                            <InputRadioText>{WITHHOLD_TAX_TEXT}</InputRadioText>
                        </Col>
                    </TaxOptionsRow>
                </>
            ) : ('')}
            {stateTaxWithHold ? (
                <StateTaxRow data-test="stateTaxSection">
                    <StyledLabel >
                        {checkSingleEqualityAndReturnValue(groupStateTax?.basis, FWH_TEXT,STATE_TAX_ON_FEDTAX_TEXT,STATE_TAX_LABEL)}
                    </StyledLabel>
                    <FlexRow >
                        <StyledInput
                            type="number"
                            ref={stateTaxPercentRef}
                            name={STATE_TAX_INPUT_NAME}
                            disabled={getDisabledStateForTaxInput(stateCodeGroup,GROUP_3_TEXT,GROUP_4_TEXT,fedTaxAmount,group3DistAmountMinRule,group4DistAmountMinRule,GROUP_5_TEXT)}
                            value={stateTaxPercent}
                            onChange={handleStateTax}
                            onKeyDown={handleStateTaxKeyDown}
                            onBlur={handleBlur}
                            data-test="stateTaxPercent"
                            maxlength={6}
                            onWheel={handleScroll}
                        />
                        {isSwpFlowForStateTaxAmount(isSWP,stateTaxAmount)}

                    </FlexRow>
                </StateTaxRow>
            ) : (
                    ''
                )}
            <FlexRow data-test="warningMsgSection">
                <StyledLabel />
                <FlexRow>
                    {isError ? <TaxWarning data-test="warningMsgErrorObj">{errorObj}</TaxWarning> : ''}
                    {stateCodeGroup === GROUP_5_TEXT && stateTaxWithHold ? (
                        <TaxWarning>{GROUP5_TAX_WARNING_MSG}</TaxWarning>
                    ) : ('')}

                </FlexRow>
            </FlexRow>
            {isSWP ?
                <>
                    <FlexRow data-test="totalTaxesSection">
                        <StyledLabel >{TOTAL_TAXES_TO_WITHHOLD_LABEL}</StyledLabel>
                        <FlexRow >
                            <StyledDollar data-test="totalTaxesInputAmount">
                                {currencyFormatterFunction((fedTaxAmount + stateTaxAmount).toFixed(2))}
                            </StyledDollar>
                        </FlexRow>
                    </FlexRow>
                    <FlexRow data-test="totalReceivedSection">
                        <StyledLabel >{TOTAL_RECEIVED_LABEL}</StyledLabel>
                        <FlexRow >
                            <StyledDollar data-test="totalReceivedInputAmount">
                                {currencyFormatterFunction((amount.toFixed(2) - (fedTaxAmount + stateTaxAmount).toFixed(2)).toFixed(2))}
                            </StyledDollar>
                        </FlexRow>
                    </FlexRow>
                    <FlexRow data-test="totalWithdrawalSection">
                        <StyledLabel >{TOTAL_WITHDRAWAL}</StyledLabel>
                        <FlexRow >
                            <StyledDollar data-test="totalwithDrawalAmount">
                                {currencyFormatterFunction(amount.toFixed(2))}
                            </StyledDollar>
                        </FlexRow>
                    </FlexRow>
                </>
                : ''}
        </ContainerComponent>
    )
    return isSWP ? (
        <Accordian
            ref={taxRef}
            key={TAX_WITHHOLD_TITILE}
            titleChild={
                <AccordianTitle
                    accType={TAX_WITHHOLD_TITILE}
                    id={TAX_WITHHOLD_TITILE}
                    accIndex={0}
                />
            }
            titleSeperator
            labelledBy={TAX_WITHHOLD_TITILE}
        >
            {renderTaxComponent}
        </Accordian>
    )
        : renderTaxComponent

}

TaxOptionsToWithHold.propTypes = {
    amount: PropTypes.number,
    getStateCodeGroup: PropTypes.func,
    getStateTaxesForGroup: PropTypes.func,
    setTaxValidation: PropTypes.func,
    setTaxStateValues: PropTypes.func,
    onTaxChange: PropTypes.func,
    handleStateTaxWithHold: PropTypes.func,
    masterRegId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    residenceState: PropTypes.string,
    isSWP: PropTypes.bool,
    stateTaxWithHold: PropTypes.bool,
    fedTaxPercent: PropTypes.number,
    stateTaxPercent: PropTypes.number,
    stateTaxPercentRef: PropTypes.instanceOf(Object),
    fedTaxPercentRef: PropTypes.instanceOf(Object),
    taxRef: PropTypes.instanceOf(Object),

};
TaxOptionsToWithHold.defaultProps = {
    getStateCodeGroup: () => { },
    getStateTaxesForGroup: () => { },
    setTaxValidation: () => { },
    setTaxStateValues: () => { },
    onTaxChange: () => { },
    handleStateTaxWithHold: () => { },
    masterRegId: 0,
    amount: 0,
    residenceState: '',
    isSWP: false,
    stateTaxWithHold: false,
    fedTaxPercent: 0,
    stateTaxPercent: 0,
    stateTaxPercentRef: {},
    fedTaxPercentRef: {},
    taxRef: {},
};

const mapStateToProps = state => ({
    rmdReducerData: get(state, 'rmdReducerData', []),
});

const mapDispatchToProps = {
    getStateTaxesForGroup: RmdActions.getStateTaxesForGroup,
    getStateCodeGroup: RmdActions.getStateCodeGroup,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TaxOptionsToWithHold);
