import styled from 'styled-components';
import TopLeftCornerColor from '../TopLeftCornerColor';

const InstructionalPanel = styled(TopLeftCornerColor)`
    margin-bottom: ${(props) => props.mb || '3.75'}rem;
    margin-top: ${(props) => props.mbt || '0'}rem;
    padding: 1.625rem 1.875rem;

    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

export default InstructionalPanel;