import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import styles from './Styles';

class ErrorMessageBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
        this.timerId = null;
    }

    componentDidMount() {
        // let time = 10;
        // const { text } = this.props;
        // if (text) {
        //     this.timerId = setInterval(() => {
        //         time -= 1;
        //         if (time) {
        //             this.setState(state => ({ ...state, time: `${time}s` }));
        //         } else {
        //             this.closeHandler();
        //         }
        //     }, 1000);
        // }
    }

    componentWillUnmount() {
        // if (this.timerId) clearInterval(this.timerId);
    }

    // closeHandler = () => {
    //     const { onClose } = this.props;
    //     clearInterval(this.timerId);
    //     this.timerId = null;
    //     this.setState(
    //         state => ({ ...state, close: true }),
    //         onClose, // To notify the parent component is notification is closed or not
    //     );
    // };

    render() {
       // const { close, time } = this.state;
        const { text, error } = this.props;
        const snapErrorText='Oh snap! You got an error from backend! Please retry after sometime'
        return text ? (
            <div style={styles.relative}>
                <div style={error ? styles.mainError : styles.main}>
                    <div className="container messageBarDiv" style={styles.container}>
                        <Row>
                            <Col md={11} lg={11} xl={11}>
                                <Row>
                                    <p style={styles.message}>{text.message || snapErrorText}</p>
                                </Row>
                                {text.info ? <Row>
                                    <p style={styles.info}>{text.info}</p>
                                </Row> : null}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

ErrorMessageBar.propTypes = {
    text: PropTypes.shape({
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        info: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    }),
    error: PropTypes.bool,
   // onClose: PropTypes.func,
};

ErrorMessageBar.defaultProps = {
    text: {
        message: null,
        info: null
    },
    error: false,
  //  onClose: () => { },
};

export default ErrorMessageBar;
