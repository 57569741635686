import React, { Component } from 'react';
import styled from 'styled-components';
import { WRadio, PageHeading, WBreadCrumb, CommonButtons } from './../../../../../common';
import ContactCenterWidget from 'modules/AccountDashboardModule/Common/ContactCenter/ContactCenterWidgetContainer';
import accountHolderCardData from 'modules/AccountDashboardModule/AccountHolderView/AccountHolderConstants';
import { Container } from 'react-bootstrap';
import routeConstants from 'routeConstants';

const { footerData } = accountHolderCardData;

const Section = styled.div`
    width: 50%;
    font-size: 1rem;
    padding-right: 40px;
`;
const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;
const RadioGrup = styled.div`
    display: flex;
    flex-direction: column;
`;
const radiostyles = {
    minHeight: 0,
    marginBottom: 0
};
const RMDInfoContainer = styled.div`
    margin-top: 120px;
`;
const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const breadCrumbURL = [
    { url: '/accountholder', name: 'Home' },
    { url: './accountservices', name: 'Account Services' },
    { url: './requiredMinimumDistribution', name: 'Required Minimum Distribution' }
];

class RMDDIY extends Component {
    constructor(props) {
        super(props);
        const { location } = this.props;
        const { accountSelected } = location.state || {};

        this.state = {
            options: [{
                key: 0,
                value: 'Set up a recurring transfer on my terms'
            }, {
                key: 1,
                value: `Redeem from ${accountSelected.accountName}`
            }, {
                key: 2,
                value: 'I prefer to talk to someone'
            }],
            optionSelected: {},
            selectedOption: false,
            accountSelected
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    handleOptionChange = (item) => {
        this.setState({
            optionSelected: item
        });
    }

    nextClick = () => {
        const { optionSelected, accountSelected } = this.state;
        const { history } = this.props;
        if (optionSelected.key === 0) {
            history.push({
                pathname: routeConstants.createSystematicWithdrawal,
                state: {
                    accountSelected
                }
            });
        } else if (optionSelected.key === 1) {
            history.push({
                pathname: routeConstants.liquidation,
                state: {
                    accountSelected
                }
            });
        }
    }

    backClick = () => {
        const { history } = this.props;
        history.push(routeConstants.addRequiredMinimumDistribution);
    }

    render() {
        const { options, optionSelected } = this.state;

        return (
            <>
                <Container>
                    <WBreadCrumb activeCrumb={'RMD DIY'} breadCrumbItems={breadCrumbURL} />
                    <PageHeading>{'RMD DIY'}</PageHeading>

                    <RMDInfoContainer>
                        <SectionWrapper>
                            <Section>
                                <SectionTitle>Guidance</SectionTitle>
                                Consider setting up a recurring fund-to-bank transfer or do a liquidation (sell transaction) to meet your needs for a minimum distribution.
                            </Section>
                            <Section>
                                <SectionTitle>I want to:</SectionTitle>
                                <RadioGrup role='radiogroup'>
                                    {options.map(item => {
                                        return (
                                            <WRadio
                                                id={`${item.key}`}
                                                key={item.key}
                                                name={item.key}
                                                value={`${item.value}`}
                                                radioFor={item.key}
                                                radiostyles={radiostyles}
                                                selected={item.key === optionSelected.key}
                                                onClick={() => this.handleOptionChange(item)}
                                            />
                                        );
                                    })}
                                </RadioGrup>
                                {optionSelected.key === 2 &&
                                    <div>
                                        <ContactCenterWidget footerData={footerData} showCorner={true} />
                                    </div>}
                            </Section>
                        </SectionWrapper>
                    </RMDInfoContainer>
                </Container>
                <CommonButtons backClick={this.backClick} nextClick={this.nextClick} disabled={optionSelected.key === 2 || Object.keys(optionSelected).length == 0} />
            </>
        );
    }
}

export default RMDDIY;