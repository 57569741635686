/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/**
 * Compare Funds Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to display contract information for interested parties
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Card, Col } from 'react-bootstrap';
import consts from './consts';

const CardHeader = styled(Card.Header)``;

const CardBody = styled(Card.Body)`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #5d83ae99;
    opacity: 1;
    max-height: 120px;
`;

const Cardd = styled(Card)`
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
`;

const ContractHeader = styled(Row)`
    justify-content: space-between;
`;

const ContentRow = styled(Row)`
    width: 90%;
`;

const FieldKey = styled.span`
    max-height: 30px;
    padding: 15px 13px 21px 33px;
    text-align: left;
    font: Bold 16px/21px Roboto Slab;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

const Name = styled.span`
    padding: 5px 41px 24px 31px;
    text-align: left;
    font: 500 30px/36px benton-sans;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

const FieldLabel = styled.span`
    text-align: left;
    font: 400 16px/19px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    padding-left: 33px;
    padding-top: 10px;
`;

const ContractNumber = styled.span`
    text-align: left;
    font: 500 16px/35px Roboto Slab;
    letter-spacing: 0;
    color: #54565b;
    opacity: 1;
`;

const Menu = styled.div`
    position: relative;
    &::after {
        content: '\\2807';
        color: #544a54;
        font-size: 27px;
    }
`;
const MenuOptionsPopup = styled.div`
    position: absolute;
    width: 150px;
    height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000f;
    text-align: left;
    font: Bold 16px/21px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: -140px;
    top: 30px;
`;
const MenuOptions = styled.div`
    text-indent: 25px;
`;
const MenuOptionsBorder = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 6px;
    margin-bottom: 6px;
`;

export default class ContractInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: '',
        };
    }

    generateKey = () => {
        return Math.floor(Math.random() * (10000 - 100 + 1)) + 100;
    };

    onMenuClick = index => e => {
        e.stopPropagation();
        this.setState(prevState => {
            return { menuOpen: prevState.menuOpen === index ? '' : index };
        });
    };

    onEditMenuClick = () => {};

    onDeleteMenuClick = () => {};

    render() {
        const { contract } = this.props;
        const { menuOpen } = this.state;
        return (
            <Cardd>
                <CardHeader>
                    <ContractHeader noGutters>
                        <div>
                            <FieldKey>{consts.contractNumberLabel}</FieldKey>
                            <ContractNumber>{contract.contractNumber}</ContractNumber>
                        </div>
                        <Menu onClick={this.onMenuClick(contract.contractNumber)}>
                            {menuOpen === contract.contractNumber && (
                                <MenuOptionsPopup>
                                    <MenuOptions onClick={this.onEditMenuClick}>{consts.menuOptions[0]}</MenuOptions>
                                    <MenuOptionsBorder />
                                    <MenuOptions onClick={this.onDeleteMenuClick}>{consts.menuOptions[1]}</MenuOptions>
                                </MenuOptionsPopup>
                            )}
                        </Menu>
                    </ContractHeader>
                </CardHeader>
                <CardBody>
                    <ContentRow noGutters>
                        <Col>
                            <Row noGutters>
                                <FieldKey>{consts.contractNameLabel}</FieldKey>
                            </Row>
                            <Row noGutters>
                                <Name>{contract.name}</Name>
                            </Row>
                        </Col>
                        <Col>
                            <Row noGutters>
                                <FieldKey>{consts.relationshipLabel}</FieldKey>
                            </Row>
                            <Row noGutters>
                                <FieldLabel>{contract.relationship}</FieldLabel>
                            </Row>
                        </Col>
                        <Col>
                            <Row noGutters>
                                <FieldKey>{consts.numbersTaggedLabel}</FieldKey>
                            </Row>
                            <Row noGutters>
                                <FieldLabel>
                                    {consts.hash}
                                    {contract.accountsTagged}
                                </FieldLabel>
                            </Row>
                        </Col>
                    </ContentRow>
                </CardBody>
            </Cardd>
        );
    }
}

ContractInfo.propTypes = {
    editClick: PropTypes.func,
    headerFields: PropTypes.instanceOf(Object),
    content: PropTypes.instanceOf(Object),
};

ContractInfo.defaultProps = {
    editClick: () => {},
    headerFields: [],
    content: [],
};
