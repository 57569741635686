/**
 * RMD
 ********************************
 *
 * @version 1.0.0
 * @author Andreea P
 * @description RMD plan is displayed and managed here
 * @createdDate [08/07/2020]
 * ***************************************
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

import styled from 'styled-components';
import { WBreadCrumb, PageHeading, WSpinner, VerifyDetails, WStepper, WMessageBar, StyledToolTip } from 'common';
import constants, { RECURRING_TRANSFER_PLAN, PAY_OPTIONS } from 'modules/AccountOptions/constant';
import RMDInfo from '../RMDComponents/RMDInfo';
import RMDAccountSelection from '../RMDComponents/AccountSelection';
import RMDQuestions from '../RMDComponents/RMDQuestions/index';
import WithdrawalFrom from '../RMDComponents/WithdrawalFrom/WithdrawalFrom';
import consts from '../consts'
import TaxWithholdingDepositAccount from '../RMDComponents/TaxWithholdingDepositAccount/TaxWithholdingDepositAccount';
import RMDDownloadForm from '../RMDComponents/RMDDownloadForm/RMDDownloadForm'
import '../RMDComponents/RMDDownloadForm/RMDDownloadForm.css'
import YouSelect from '../RMDComponents/YouSelect';

const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

const RMDRoute = '/requiredminimumdistribution';

const Stepper = styled(WStepper)`
    margin-bottom: 40px;
    margin-top: 40px;
`;
const InfoSection = styled.div`
    margin-bottom: 2rem;
    font-size: 1rem;
`;
const RMDInfoContainer = styled.div`
    width: 50%;
    margin-top: 50px;
`;
const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Section = styled.div`
    width: 50%;
    font-size: 1rem;
    padding-right: 40px;
`;
const cancelButtonStyles = {
    float: "right"
};
const breadCrumbURL = [
    { url: '/accountholder', name: 'Home' },
    { url: './accountservices', name: 'Account Services' },
    { url: './requiredMinimumDistribution', name: 'Required Minimum Distribution' }
];

const RMDPages = {
    downloadFormPages: ['Start', 'Select an Account', 'Provide Information', 'Download the Form'],
    allSteps: ['Start', 'Select an Account', 'Provide Information', 'Select a Fund', 'Tax Withholding & Deposit Account', 'Review & Complete']
}

class CreateRMDPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            childrenState: [],
            selectedFunds: [],
            fundDetail: {},
            isEdit: false,
            plan: RECURRING_TRANSFER_PLAN.RMD,
            showDownloadForm: false,
            RMDPages: RMDPages.allSteps
        };

        this.editSection = null;
        this.rmdBottomNav = React.createRef();

        this.investToRef = React.createRef();
        this.calculationSectionRef = React.createRef();

        this.taxWithHoldRef = React.createRef();

        this.fundFromRef = React.createRef();
        this.bankDetailsSectionRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentDidUpdate(prevProps) {
        const { clearFundPlanInfo, clearBankAccDetails, bankAccountReducerData, isSuccessfullySaved } = this.props;
        const { bankDetails = [] } = bankAccountReducerData;
        const { planInfo = {} } = this.state;
        const { selectedPlanInfo = {} } = this.props.rmdReducerData;

        if (Object.keys(selectedPlanInfo).length && selectedPlanInfo !== planInfo) {
            this.addChildrenState(selectedPlanInfo);
        }

        if (isSuccessfullySaved && prevProps.isSuccessfullySaved !== isSuccessfullySaved) {
            if (Object.keys(planInfo).length) {
                clearFundPlanInfo();
            }

            if (bankDetails.length) {
                clearBankAccDetails();
            }

            this.onSubmit();
        }
    }

    componentWillUnmount() {
        const { clearFundPlanInfo, clearBankAccDetails, resetRmdList } = this.props;
        clearFundPlanInfo();
        clearBankAccDetails();
        resetRmdList();
    }

    addChildrenState = (planInfo) => {
        // Setting isEdit
        const isEdit = true;
        // Setting page index
        const pageIndex = 0;
        // Setting children state
        const {
            bankDetails = {},
            payeeId,
            paymentMethod = '05',
            accountName = '',
            accountType,
            accountNumber,
            priorYearEndBalance,
            address,
            fundsList,
            totalDistributionAmount = 0,
            stateTax,
            fedTax
        } = planInfo;

        let taxData = {};

        // Account Selection Page
        const accountSelection = {};

        accountSelection.accountSelected = {
            accountName: accountName,
            accountNumber: accountNumber,
            accountType: accountType,
            priorYearEndBalance,
            address,
            fundsList,
            totalDistributionAmount
        };

        taxData = {
            stateTaxAmount: stateTax,
            fedTaxAmount: fedTax
        }

        accountSelection.displayDetails = {
            accountSelection: {
                titleTxt: 'Account Selection',
                pageIndex: 0,
                data: [
                    {
                        field: 'Account Name',
                        value: accountName,
                    },
                    {
                        field: 'Account Number',
                        value: accountNumber,
                    },
                ],
            },
        };
        // Plan Details Page
        const planDetails = {};
        let displayDetails = {};
        let selectedBank = null;
        let offlineMode = null;
        let offlineCards = [];
        let showOptions = false;

        if (paymentMethod === "05") {
            const {
                transitRoutingNumber = '',
                bankName = '',
                bankAccountNumber = '',
                bankAccountType = '',
            } = bankDetails;

            displayDetails = {
                fundFrom: {
                    titleTxt: 'Fund From',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [
                        {
                            field: 'Funding Source',
                            value: bankName,
                        },
                        {
                            field: 'Account Number',
                            value: bankAccountNumber,
                        },
                    ],
                },
            };

            selectedBank = {
                bankName,
                bankAccountNumber,
                bankAccountType,
                bankRoutingNumber: transitRoutingNumber,
                payeeId
            }

            offlineMode = null;
        } else if (paymentMethod === "01") {

            offlineMode = PAY_OPTIONS.CHECK_ORDER;
            offlineCards = [{
                "key": "Check",
                "text": "Check",
                "selected": true
            }, {
                "key": "DirectSWP",
                "text": "Directed SWPs",
                "selected": false
            }];

            displayDetails = {
                fundFrom: {
                    titleTxt: 'Fund From',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [],
                },
            };

            showOptions = true;
        }

        planDetails.offlineMode = offlineMode;

        planDetails.bankDetailsSectionState = {
            selectedBank,
            displayDetails,
            disableNext: false,
            offlineCards,
            showOptions,
            retrivedState: true
        };

        if (this.isIRAaccount(accountType)) {
            planDetails.taxWithHoldState = {
                stateTaxWithHold: planInfo.feeWaiveFlag !== 'W',
                displayDetails: {
                    titleTxt: 'Tax Witholding Options',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [
                        {
                            field: "Federal Tax",
                            value: taxData.fedTaxAmount
                        },
                        {
                            field: "State Tax",
                            value: taxData.stateTaxAmount
                        },
                        {
                            field: "Total Taxes to be withhold",
                            value: taxData.totalTaxAmount
                        },
                        {
                            field: "Total you will receive",
                            value: taxData.distributionAmount - taxData.totalTaxAmount
                        },
                        {
                            field: "Total withdrawal",
                            value: taxData.distributionAmount
                        },
                    ]
                }
            }
        }

        planDetails.calculationSectionState = {
            amtConsumed: totalDistributionAmount,
            amtRemaining: 0,
            disableNext: false,
            displayDetails: {
                investTo: {
                    titleTxt: 'Invest To',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [],
                },
            },
            totalAmt: totalDistributionAmount,
        };
        planDetails.displayDetails = {
            ...planDetails.bankDetailsSectionState.displayDetails,
            ...planDetails.calculationSectionState.displayDetails,
        };
        planDetails.disabled = false;
        planDetails.totalAmount = totalDistributionAmount;

        this.setState({
            planInfo,
            isEdit,
            pageIndex,
            childrenState: [accountSelection, planDetails],
        });

    }

    isIRAaccount = (accountType) => {
        const { plan } = this.props;
        return ((accountType.toUpperCase().indexOf('IRA') !== -1 && accountType.toUpperCase().indexOf('ROTH IRA') === -1) || accountType === '215') && plan === RECURRING_TRANSFER_PLAN.SWP
    }

    saveChildrenState = (data, index, resetDataForNextPage = false) => {
        this.setState(prevState => {
            const { childrenState } = prevState;
            childrenState[(+index)] = data;
            return {
                childrenState,
            };
        });
    };

    onSwitchComponent = bool => () => {
        this.setState(prevState => {
            return {
                showDownloadForm: false,
                RMDPages: RMDPages.allSteps,
                pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
            };
        });
    };

    onSwitchToDownloadComponent = () => {
        this.setState({
            showDownloadForm: true,
            RMDPages: RMDPages.downloadFormPages
        })
        this.setState(prevState => {
            return { pageIndex: prevState.pageIndex + 1 }
        })
    }

    onCancel = () => {
        const { history } = this.props;
        history.push(RMDRoute);
    };

    onSubmit = () => {
        const { history, isSuccessfullySaved } = this.props;
        if (isSuccessfullySaved) {
            history.push({
                pathname: RMDRoute,
                state: { accAdded: true, eventMessage: '' },
            });
        }

    };

    scrollToSection = (index, section) => () => {
        this.editSection = section;
        this.setState(
            () => {
                return {
                    pageIndex: index,
                };
            },
            () => {
                this.editSection = null;
            },
        );
    };

    createPlan = () => {
        const planDetails = this.getPlanDetails();
        const { addRMD } = this.props;
        if (planDetails) {
            addRMD(planDetails);
        }
    }

    getPlanDetails = () => {
        const { childrenState } = this.state;
        const [ , selectAccount, questions, fundsData, taxAndDeposit ] = childrenState;
        const { dateOfBirth } = this.props;
        const paymentOption = get(taxAndDeposit, 'selectedBank[0]', '');
        const funds = fundsData.selectedSharesData;
        const answers = questions.answersDetails;
        const masterRegLevelLabel = questions.isMasterRegLevel ? 'Y' : 'N';
        let letValue = 'U';

        if (answers[1].value === 'Yes' && dateOfBirth) {
            let spouseBirthday = new Date(questions.selectedAnswerBirthDate.label);
            let yearsDiff = new Number((spouseBirthday.getTime() - new Date(dateOfBirth).getTime()) / 31536000000).toFixed(0)
            
            if (yearsDiff >= 10) {
                letValue = 'J';
            }
        }

        const formatedFunds = funds.map((item, index) => {
            item.fedTax = taxAndDeposit.tax.fedTaxPercent;
            item.stateTax = taxAndDeposit.tax.stateTaxPercent;
            
            if (questions.isMasterRegLevel) {
                item.priorYearEndBalance = (+fundsData.totalPriorYearBalance).toFixed(2);
            } 

            if ( +fundsData.originalShares[index].priorYearEndBalance ) {
                delete item.priorYearEndBalance;
            } else {
                item.priorYearEndBalance = (+item.priorYearEndBalance).toFixed(2);
            }

            return item;
        })


        return {
            originalTaxes: {
                fedTax: selectAccount.accountSelected.fedTax,
                stateTax: selectAccount.accountSelected.stateTax,
            },
            paymentMethod: taxAndDeposit.depositAccount[0].value === "Check" ? "01" : "05",// 05 - Bank, 01- check
            masterRegLevel: masterRegLevelLabel,
            initialDate: taxAndDeposit.startDate,
            frequency: taxAndDeposit.frequency,
            swpWithholdCode: taxAndDeposit.tax.stateTaxWithHold ? '' : 'W',
            lifeExpectancyTableCode: letValue,
            fundsList: formatedFunds,
            bankDetails: paymentOption.mode === 'Online' ? {
                transitRoutingNumber: paymentOption.details.bankRoutingNumber,
                bankAccountNumber: paymentOption.details.bankAccountNumber,
                accountType: paymentOption.details.bankAccountType,
                payorName: paymentOption.details.bankName,
            } : null            
        };

    }

    toggleToolTip = () => {
        this.setState({ tooltipOpen: true });
    };

    closeTooltip = () => {
        this.setState({ tooltipOpen: false });
    };

    getHintView = () => {
        const { tooltipOpen } = this.state
        return (
            <div>
                <button
                    className="infoIcon"
                    id="infoIcon"
                    type="button"
                    aria-label="Tool tip for Prospectus"></button>
                <StyledToolTip
                    id="tooltip"
                    placement="right"
                    tooltipOpen={tooltipOpen}
                    targetID="infoIcon"
                    handleToggle={this.toggleToolTip}
                    autohide={false}
                    triggerEvt="focus"
                    tooltipText={consts.RMDtooltip}
                    closeTooltip={this.closeTooltip} />

            </div>
        )
    }

    switchComponent = () => {
        const { pageIndex, isEditMode = false, RMDPages } = this.state;

        const contentData = !isEditMode ? constants.RMD : constants.EDIT_RMD;
        const activeCrumb = contentData.pageHeading1;

        return (
            <>
                <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={breadCrumbURL} />
                <PageHeading>{consts.RMDLabel}</PageHeading>

                {this.getHintView()}

                {pageIndex > 0 && pageIndex < 4 ? (
                    <RMDInfoContainer>
                        <InfoSection>
                            You will need to take a distribution from your IRA account before December 31, 2021 according to <a href="">IRS guidelines</a>.
                        </InfoSection>
                    </RMDInfoContainer>
                ) : <></>}

                {pageIndex ? (
                    <Stepper className="rmd-stepper" currentPage={pageIndex} pages={RMDPages} style={stepperStyle} />
                ) : <></>}

                {this.bindComponent()}
            </>
        );
    };

    updateTotalAmount = (amount) => {
        this.setState({
            totalAmount: amount
        })
    }

    onNotificationClose = () => {
        const { dismissNotificationError } = this.props;
        dismissNotificationError();
    }

    resetNextSteps = () => {
        const { pageIndex, childrenState } = this.state;

        this.setState({
            childrenState: childrenState.slice(0, pageIndex + 1)
        });
    }

    bindComponent() {
        const { pageIndex, childrenState, totalAmount, showDownloadForm } = this.state;
        const {
            getBankAccountInfo,
            bankAccountReducerData,
            getEligibleAccounts,
            rmdReducerData
        } = this.props;
        const { accountsOpened = [], stateCodeGroup = [] } = rmdReducerData;
        const { accountSelected } = childrenState[1] || [];
        const { offlineCards } = childrenState[3] || {};
        const anwersSelected = childrenState[2] || {};
        const allSelected = !anwersSelected.isMasterRegLevel;
        const navigationObj = {
            onCancel: this.onCancel,
            onPrimaryClick: this.onSwitchComponent(true),
            onSecondaryClick: this.onSwitchComponent(false),
            onShowDownloadForm: this.onSwitchToDownloadComponent,
            portalRoot: this.rmdBottomNav,
            iscancel: true,
            cancelClick: this.onCancel,
            cancelOffset: 8,
            cancelStyle: cancelButtonStyles
        };
        const planDetailsContent = constants.PlanDetailsRMDContent;
        const youSelectAnswerDetails = isEmpty(childrenState[2]) ? [] : childrenState[2].youSelectDetails;
        
        switch (pageIndex) {
            case 0: {
                navigationObj.primaryText = 'Get Started';
                return (
                    <RMDInfo
                        navigationObj={navigationObj}
                    />
                );
            }
            case 1: {
                return (
                    <RMDAccountSelection
                        savedState={childrenState[1]}
                        resetNextSteps={this.resetNextSteps}
                        accountsOpened={accountsOpened}
                        navigationObj={navigationObj}
                        getEligibleAccounts={getEligibleAccounts}
                        saveChildrenState={this.saveChildrenState}
                        pageIndex={pageIndex}
                    />
                );
            }
            case 2: {
                return (
                    <RMDQuestions
                        navigationObj={navigationObj}
                        resetNextSteps={this.resetNextSteps}
                        accountSelected={accountSelected}
                        savedState={childrenState[2]}
                        saveChildrenState={this.saveChildrenState}
                        pageIndex={pageIndex}
                        savedChildrenData={anwersSelected}
                        RMDCalc={this.getRMDCalcComponent}
                    />
                );
            }
            case 3: {
                if (showDownloadForm) {
                    navigationObj.onPrimaryClick = this.onCancel;
                }
                return (
                    <>
                        {showDownloadForm ? 
                            <RMDDownloadForm
                                navigationObj={navigationObj}
                            /> : <WithdrawalFrom
                                accDetails={accountSelected}
                                pageIndex={pageIndex}
                                savedState={childrenState[3]}
                                saveChildrenState={this.saveChildrenState}
                                shares={accountSelected.fundsList}
                                ref={this.calculationSectionRef}
                                forwardRef={this.investToRef}
                                investTo={planDetailsContent.investTo}
                                ammountChanged={this.updateTotalAmount}
                                allSelected={allSelected}
                                navigationObj={navigationObj}
                                answerDetails={youSelectAnswerDetails}
                            />}
                    </>
                )
            }
            case 4: {
                return (
                    <TaxWithholdingDepositAccount
                        pageIndex={pageIndex}
                        taxWithHoldRef={this.taxWithHoldRef}
                        totalAmount={totalAmount}
                        savedState={childrenState[4]}
                        saveChildrenState={this.saveChildrenState}
                        tax={ {fedTaxPercent: accountSelected.fedTax || 0, stateTaxPercent: accountSelected.stateTax || 0} }
                        fundFromRef={this.fundFromRef}
                        bankDetailsSectionRef={this.bankDetailsSectionRef}
                        fundFrom={planDetailsContent.fundFrom}
                        getBankAccountInfo={getBankAccountInfo}
                        bankAccountReducerData={bankAccountReducerData}
                        shouldValidateACHLimit={false}
                        navigationObj={navigationObj}
                        masterRegId={childrenState[1].accountSelected.masterRegId}
                        residenceState={childrenState[1].accountSelected.address.accountRegistrationIdRes}
                        answerDetails={youSelectAnswerDetails}
                        accDetails={accountSelected}
                        stateCodeGroup={stateCodeGroup}
                    />
                );
            }
            case 5: {
                navigationObj.primaryText = 'Submit';
                const currentState = [...childrenState];
                currentState.shift()
                currentState.splice(1, 1)
                const mergeDetails = currentState.length ? currentState
                    .map(item => item.displayDetails)
                    : [];
                const verifyDetails = Object.keys(mergeDetails).length ? Array.from(Object.keys(mergeDetails), key => mergeDetails[key.toString()]) : [];
                return (
                    <SectionWrapper>
                        <YouSelect pageIndex={pageIndex} pageSubIndex={-1}  />
                        <Section>
                            <VerifyDetails
                                verifyDetails={verifyDetails}
                                navigationObj={navigationObj}
                                scrollToSection={this.scrollToSection}
                                handleSubmit={() => this.createPlan(verifyDetails)}
                                tradingWindowData={{ canTrade: true }}
                                setCancelLiquidation={this.onCancel}
                                hasBottomNavCancel={true}
                            />
                        </Section>

                    </SectionWrapper>
                );
            }
            default: {
                return <></>;
            }
        }
    }

    getFormattedErrorMessage = () => {
        const { errorsData = {} } = this.props;
        let errText = '';

        if (errorsData.errorMessage && typeof errorsData.errorMessage === 'string') {
            errText = errorsData.errorMessage;
        } else if (errorsData.addErrorsList && errorsData.addErrorsList.length) {
            errorsData.addErrorsList.forEach(err => {
                errText = err.message ? `${errText} ${err.message}` : errText;
            });
        }

        return {
            message: errText
        };
    }
    render() {
        const { isLoading } = this.props;
        const { hasError = false } = this.props;

        return (
            <>
                {hasError && (
                    <WMessageBar
                        error={true}
                        onClose={this.onNotificationClose}
                        text={this.getFormattedErrorMessage()}
                    />
                )}
                {isLoading && <WSpinner loading={isLoading} />}

                <div>

                    <div className="container">{this.switchComponent()}</div>
                    <div ref={this.rmdBottomNav} />
                </div>
            </>
        );
    }
}

CreateRMDPlan.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getBankAccountInfo: PropTypes.func,
    bankAccountReducerData: PropTypes.instanceOf(Object),
    rmdReducerData: PropTypes.instanceOf(Object),
    isSuccessfullySaved: PropTypes.bool,
    isSuccessfullyUpdated: PropTypes.bool,
    dismissNotificationError: PropTypes.func,
    clearFundPlanInfo: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
};

CreateRMDPlan.defaultProps = {
    getBankAccountInfo: () => { },
    bankAccountReducerData: {},
    rmdReducerData: {},
    isSuccessfullySaved: false,
    isSuccessfullyUpdated: false,
    dismissNotificationError: () => { },
    clearBankAccDetails: () => { },
    clearFundPlanInfo: () => { },
};

export default CreateRMDPlan;