import { connect } from 'react-redux';
import get from 'lodash/get';
import UserAccountInformation from './UserAccountInformation';
import { loginActions, localStateManagementActions, analytics } from '../../../shared/Actions';

const mapStateToProps = (state) => ({
  userFields: state.localStateManagementReducerData.userFields,
  receivedEmailOTP: get(state, 'loginData.directSendEmailOTP', false),
  isLoading: state.loginData.isLoading,
  pageData: state.localStateManagementReducerData.pageData,
  otpDetails: state.localStateManagementReducerData.otpDetails,
  directVerifyOTP:  get(state, 'loginData.directVerifyOTP', {}),
});

const mapDispatchToProps = {
  directSendEmailOTP: loginActions.directSendEmailOTP,
  validatePassCode: loginActions.directVerifyOTP,
  ...localStateManagementActions,
  ...analytics
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccountInformation);
