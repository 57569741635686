import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import routeConstants from 'routeConstants';

import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper } from '../../../../common';
import { inputValidityHandler, inputChangedHandler } from '../../FormUtils';
import {MobileNumber} from '../../Input/MobileNumber';
import styles from '../../UserManagementStyles'; 
import pages from '../JovConstants';

class JovMobile extends Component {
  data = {
    title: "Joint Owner Verification",
    subtitle: "Hi Paul",
    info: ["Enter the details for User Verification"]
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        countryCode: { value: '', options: [{ key: '+1', value: '+1' }], valid: true, rules: {} },
        mobileNumber: { value: '', errMsg: null, valid: false, label: 'Mobile Number', type: 'mobile',
          rules: {
            pattern: new RegExp('\\d{3}-\\d{3}-\\d{4}'),
            patternMessage: 'Please enter the correct mobile number',
            required: true
          }
        },
      },
      formIsValid: false,
      cancelled: false
      // ...props.jovMobileState
      }
      this.onBack = this.onBack.bind(this);

      this.onNext = this.onNext.bind(this);
  
      this.onChange = this.onChange.bind(this);
  
      this.onBlur = this.onBlur.bind(this);
    }

    

    onBack() {
      this.setState((state) => ({ ...state, cancelled: true }));
    }

    onNext() {
      const { history, manageLocalState } = this.props;
      const { formIsValid } = this.state;
      const data = { jovMobileState: { ...this.state } };
      if (formIsValid) {
        manageLocalState(data);
        history.push({
          pathname: '/verify-otp',
          jov: true
      });
      }
    }

    onChange(e, id) { inputChangedHandler(e, id, this) }

    onBlur(id) { inputValidityHandler(id, this) }

    render() {
      const { formIsValid, cancelled, form } = this.state;
      // console.log(this.state)
      return (
        <div className="container-fluid">
          <div className="container">
            <WStepper className="userWizard" currentPage={2} pages={pages} />
            <Row className="main">
              <Col md lg xl>
                <SideHeader
                  title={this.data.title}
                  nameTitle={this.data.subtitle}
                  info={this.data.info} />
              </Col>
              <Col md lg xl>
                <MobileNumber
                  countryCodeState={form.countryCode}
                  mobileNumberState={form.mobileNumber}
                  changed={this.onChange}
                  blurred={this.onBlur}
                  opt={false} />
              </Col>
            </Row>
          </div>
          <CommonButtons backClick={this.onBack} nextClick={this.onNext} disabled={!formIsValid} blockstyle={styles.commonButtons} arialabelback="Back to terms and Conditions"/>
          {cancelled ? <Redirect to='/jov-terms-and-conditions' /> : null}
        </div>
      );
    }
  }

  JovMobile.propTypes = {
    manageLocalState: PropTypes.func,
    history: PropTypes.instanceOf(Object),
  };

  JovMobile.defaultProps = {
    manageLocalState: () => {},
    history: {},
  };

  export default JovMobile;