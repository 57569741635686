const SELL_AMOUNT = 'Selling Amount';
const ACC_NUMBER = 'Account Number';
const FUNDING_SOURCE = 'Funding Source';
const cancellationData = [
    {
        mainHeading: 'Selected Mutual Funds',
        fields: [
            {
                key: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                heading: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                text: SELL_AMOUNT,
                value: '$ 5,500',
            },
            {
                key: 'USSPX VCM 350 INDEX FUND MEMBER CLASS SHARES',
                heading: 'USSPX VCM 350 INDEX FUND MEMBER CLASS SHARES',
                text: SELL_AMOUNT,
                value: '$ 4,725',
            },
            {
                key: 'USSPX VCM 200 INDEX FUND MEMBER CLASS SHARES',
                heading: 'USSPX VCM 200 INDEX FUND MEMBER CLASS SHARES',
                text: SELL_AMOUNT,
                value: '$ 3,670',
            }
        ]
    },
    {
        mainHeading: 'Account Selection',
        fields: [
            {
                key: 'Account Name',
                text: 'Account Name',
                value: 'Account Name 1'
            },
            {
                key: ACC_NUMBER,
                text: ACC_NUMBER,
                value: 'XXXX-XXXX-XXXX'
            }
        ]
    },
    {
        mainHeading: FUNDING_SOURCE,
        fields: [
            {
                key: FUNDING_SOURCE,
                text: FUNDING_SOURCE,
                value: 'Bank Account 1'
            },
            {
                key: ACC_NUMBER,
                text: ACC_NUMBER,
                value: 'XXX-XXX-3838'
            }
        ]
    },
    {
        mainHeading: 'Tax Accounting Method',
        fields: [
            {
                doNotHoldText: 'Do not withhold Taxes'
            },    
            {       
                key: 'Is the requested amount',
                text: 'Is the requested amount',
                value: 'Before Tax'
            },
            {
                
                key: 'Amount Before Taxes',
                text: 'Amount Before Taxes',
                value: '$ 1,000'
            },
            {
                key: 'Federal Tax',
                text: 'Federal Tax',
                value: '10%'
            },
            {
                key: 'State Tax',
                text: 'State Tax',
                value: '10%'
            },
            {
                key: 'Total Taxes to be withhold',
                text: 'Total Taxes to be withhold',
                value: '$ 200'
            },
            {
                key: 'Total you will receive',
                text: 'Total you will receive',
                value: '$ 800'
            },
            {
                key: 'Total Withdrawal',
                text: 'Total Withdrawal',
                value: '$ 1,000'
            }
        ]
    }
]

const wrapperStyles = {
    backgroundColor: '#FFFFFF',
    minHeight: '80vh',
    position: 'relative',
    color: '#56565A',
    fontFamily: 'Roboto Slab',
};

export default {
    wrapperStyles,
    cancellationData
}