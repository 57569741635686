import { accOpeningActions, localStateManagementActions,transactionCompositeDataAction } from '../../../shared/Actions';
import { connect } from 'react-redux';
import MarketMilitaryInfoCard from './MarketMilitaryInfoCard';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MarketMilitaryInfoCard);
