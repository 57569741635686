import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import { WButton } from './../../../../../common';
import headerImage from './../../../../../assets/account.png';

const InfoSection = styled.div`
    margin-bottom: 2rem;
    font-size: 1rem;
`;

const RMDInfoContainer = styled.div`
    width: 50%;
    margin: 50px 0px;
`;

const RMDSmallBox = styled.div`
    width: 25%;
    margin: 20px 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 5px 5px 5px #F0F0F0;
    font-size: 1rem;
`;

const RMDBiggerBox = styled.div`
    width: 75%;
    margin: 20px 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 5px 5px 5px #F0F0F0;
    font-size: 1rem;
`;

const RMDBoxTitle = styled.h2`
    margin: 10px 0px 20px 20px;
    color: #466D8D;
`;

const RMDBoxImage = styled.div`
//   background-image: url(${headerImage});
    height: 90px;
`;

const RMDBoxBody = styled.div`
    margin: 0px 20px;
`;

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RetirementTick = styled.div`
    width: 120px;
    color: white;
    height: 20px;
    font-size: 14px;
    margin-bottom: 1.875rem;
    opacity: 1;
    position: relative;
    outline: white;
    background: #466D8D 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #8bc10529;
    border: none;
    border-radius: 0;
    padding-left: 5px;
    &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 20px solid #466D8D;
        border-bottom: 20px solid white; 
        position: absolute;
        bottom: 0;
        right: 0;
    }
`

const ButtonSpan = styled.span`
    margin-right: 20px;
`;

const ButtonsContainer = styled.div`
    margin: 20px 20px;
    display: inline-block;
    padding: 5px;
`;

const CustomLink = styled.span`
    color: rgba(0, 74, 152, 1);
    cursor: pointer;
    &:hover {
        color: #40a9ff;
    }
`;

const PATH_CONSTANTS = {
    beneficiary: '/manageBeneficiariesAccounts',
    article: '/article',
    currentYearCalculator: '/rmdCurrentCalculator',
    futureYearCalculator: '/rmdFutureCalculator'
}

class RMDInfo extends Component {
    constructor(props) {
        super(props);
    }

    redirectTo = ( componentName ) => {
        const { history } = this.props;
        history.push({
            pathname: PATH_CONSTANTS[componentName]
        });
    } 

    render() {
        const { navigationObj } = this.props;
        return (
            <>
                <RMDInfoContainer>
                    <InfoSection>
                        You will need to take a distribution from your IRA account before December 31, 2021 according to <a href="https://www.irs.gov/retirement-plans/plan-participant-employee/retirement-topics-required-minimum-distributions-rmds" target="_blank" rel="noopener noreferrer">IRS guidelines</a>.
                </InfoSection>
                    <InfoSection>
                        Before you begin, make sure your <CustomLink onClick={() => this.redirectTo('beneficiary')}>beneficiary designations</CustomLink> are up to date. Beneficiaries impact RMD calculations.
                </InfoSection>

                    <WButton onClick={navigationObj.onPrimaryClick} buttontext="Get Started" />
                </RMDInfoContainer>
                <SectionWrapper>
                    <RMDSmallBox>
                        <RetirementTick>RETIREMENT</RetirementTick>
                        <RMDBoxImage/>
                        <RMDBoxBody>How you take money out of your retirement accounts is one of the largest decisions you have...</RMDBoxBody>
                        <ButtonsContainer>
                            <WButton buttontext="Read article" onClick={() => this.redirectTo('article')}></WButton>
                        </ButtonsContainer>
                    </RMDSmallBox>
                    <RMDBiggerBox>
                        <RetirementTick>RETIREMENT</RetirementTick>
                        <RMDBoxTitle>Required Minimum Distribution Calculators</RMDBoxTitle>
                        <RMDBoxBody>
                            The IRS requires that you withdraw at least a minimum amount - known as a Required Minimum Distribution - from your retirement accounts annually; starting the year you turn age 72 (or 70 1/2 if born before 7/1/1949).
                            Determining how much your are required to whitdraw is an important issue in retirement planning.
                            Use this calculator to create a hypothetical projection of your future Required Minimum Distributions (RMD). This calculator has been updated for the 'SECURE Act of 2019 and CARES Act of 2020'.
                        </RMDBoxBody>
                        <ButtonsContainer>
                            <WButton buttontext="Current Year" onClick={() => this.redirectTo('currentYearCalculator')}></WButton>
                            <ButtonSpan/>
                            <WButton buttontext="Future" onClick={() => this.redirectTo('futureYearCalculator')}></WButton>
                        </ButtonsContainer>
                    </RMDBiggerBox>
                </SectionWrapper>
            </>
        )
    }
}

export default withRouter(RMDInfo);