import { accOpeningActions, localStateManagementActions, tradingWindowActions,transactionCompositeDataAction } from '../../../shared/Actions';
import { connect } from 'react-redux';
import MarketOpenAccountComponent from './MarketOpenAccountComponent';
import withAccountManagement from '../AccountManagementHOC';
import { withRouter } from 'react-router';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    // initialState: state.loginData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    // localStateData: state.localStateManagementReducerData,
    compositeData:state.transactionCompositeDataReducerData,
    marketLocalStateData: state.marketNAOReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...tradingWindowActions,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(MarketOpenAccountComponent)));
