/**
 * Add Accounts
 ********************************
 *
 * @version 1.0.0
 * @author Ramyakrishna K
 * @description Add Accounts are displayed and managed here
 * @createdDate [27/12/2019]
 * ***************************************
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { WBreadCrumb, CommonButtons, WSpinner, OtherBankAccount } from 'common';
import constants from 'modules/AccountOptions/constant';
import routeConstants from 'routeConstants';
import { bankAccountAction, analytics } from 'shared/Actions';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import * as ActionTypes from 'shared/ReduxConstants/ServiceActionConstants';
import {BANKS_ADD_PAGE, ACCOUNT_SERVICE_TYPE_ADD_BANK, ADD_ACCOUNT } from '../../../../utils/AdobeAnalytics/Constants';

const breadCrumbURL = [
    { url: '/accountholder', name: 'Home' },
    { url: './accountservices', name: 'Account Services' },
    { url: './bankaccounts', name: 'Bank Accounts' },
];
const Heading = styled.h1`
    display: inline-block;
    border-bottom: 0.125rem solid #8cbf26;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.875rem;
    color: #486d90;
    opacity: 1;
    padding-bottom: 0.625rem;
    margin-bottom: 1.875rem;
`;

const backStyle = {
    display: 'none',
};

export class AddAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountSelected: null,
            submissionText2: constants.bankAccount.accountsRetrievedSuccessMsg,
            displayProgressBar: false,
            fields: {},
            errors: {},
            otherBankDisplay: true,
            selected: false,
            disableSkip: true,
            retrievedState: false,
            analitycsDigitalData:{
                accountOptions: {
                    accountservicetype:ACCOUNT_SERVICE_TYPE_ADD_BANK,
                    accountflowtype:'',
                    dividendsandpreferrencetype:'',
                    investmentplanamount:''
                }
            }
        };
        this.cancelClick = this.cancelClick.bind(this);
        this.clickSubmit = this.clickSubmit.bind(this);
        this.clickNext = this.clickNext.bind(this);
        this.otherBankRef = React.createRef();
        this.preferredBankState = null;
        this.disableSubmitMethod = this.disableSubmitMethod.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, addBankAccountData, clearBankAccDetails, history } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        if(addBankAccountData && addBankAccountData.type && ActionTypes.ADD_NEW_BANK_ACCOUNT_DETAILS_SUCCESS === addBankAccountData.type) {
            clearBankAccDetails();
            history.push({
                pathname: '/bankaccounts',
                state: {
                    message: prevState.submissionText2,
                    displayProgressBar: !prevState.displayProgressBar,
                },
            });
        }
        return null;
    }

    componentDidMount() {
        sessionStorage.setItem(BANKS_ADD_PAGE.STEP_KEY, BANKS_ADD_PAGE.STEP_NAME);
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        this.setState({});
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    handleChange = e => {
        const { fields } = this.state;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
            errors: {},
        });
    };

    validateForm = () => {
        const { fields } = this.state;
        const error = {};
        let formIsValid = true;

        if (!fields.emailId) {
            formIsValid = false;
            error.emailId = 'Please enter your Email ID.';
        }

        if (typeof fields.emailId !== 'undefined' && !fields.emailId.match(/^\w+[+.\w-]*@\w+[+.\w-]*\.([a-z]{2,4}|\d+)$/i)) {
            formIsValid = false;
            error.emailId = 'Please enter valid Email ID.';
        }

        if (!fields.password) {
            formIsValid = false;
            error.password = constants.passErrorText
        }

        this.setState({
            errors: error,
            accountSelected: null,
        });

        if (formIsValid) {
            this.setState({
                errors: error,
                accountSelected: !null,
            });
        }
        return {
            formIsValid,
        };
    };

    getState = () => {
        const otherBankRef = this.otherBankRef.current;
        let otherBankState = {};
        let preferredBankState = {};
        if (otherBankRef) {
            otherBankState = { ...otherBankRef.state };
        }
        if (this.preferredBankState) {
            preferredBankState = { ...this.preferredBankState };
        }
        return { ...this.state, otherBankState, preferredBankState };
    };

    setStatus = (data = null) => {
        const otherBankRef = this.otherBankRef.current;
        let { disableSkip } = this.state;
        if (otherBankRef) {
            disableSkip = otherBankRef.disableNext;
        }

        this.setState(
            () => {
                return {
                    disableSkip,
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    };

    cancelClick() {
        const { history } = this.props;
        history.push({
            pathname: routeConstants.bankaccounts
        });
    }

    clickSubmit() {
        const {  addBankAcc } = this.props;
        const dataToPost = {};
        for(let key in this.otherBankRef.current.state.bankAccountDetails) {
            const item = this.otherBankRef.current.state.bankAccountDetails[key];
            if(item.name === 'accountNumber') {
                dataToPost['bankAccountNumber'] = item.value;
            }
            if(item.name === 'transitRoutingNumber') {
                dataToPost['transitRoutingNumber'] = item.value;
            }
            if(item.name === 'financialInstitutionName') {
                dataToPost['financialInstituteName'] = item.value;
            }
            if(item.name === 'accountOwner') {
                dataToPost['accountOwnersName'] = item.value;
            }
        }
        dataToPost['bankAccountType'] = this.otherBankRef.current.state.bankAccountType;
        const token = localStorage.getItem('token');
        addBankAcc({token, dataToPost});
        this.setState(
            () => {
                return {
                    displayProgressBar: true,
                    otherBankDisplay: false,
                };
            },
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            },
        );
    }

    clickNext() {
        this.setState(
            () => {
                return {
                    otherBankDisplay: 'viewOnly',
                };
            },
            () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            },
        );
    }

    disableSubmitMethod(skip) {
        this.setState({
            disableSkip: skip,
        });
    }

    render() {
        const { disableSkip, otherBankDisplay, analitycsDigitalData } = this.state;
        const navProps = {};
        navProps.submit = otherBankDisplay === 'viewOnly' ? 'true' : 'false';
        navProps.cancelClick = this.cancelClick;
        navProps.iscancel = true;
        navProps.backstyle = backStyle;
        navProps.submitClick = this.clickSubmit;
        navProps.nextClick = this.clickNext;
        navProps.disabled = disableSkip;
        navProps.arialabelnext = 'Submit click';
        navProps.analyzecustom = analitycsDigitalData;
        navProps.dataAnalyticId = ADD_ACCOUNT;

        const { bankAccountReducerData } = this.props;
        const { isLoading } = bankAccountReducerData || {};
        const loading = isLoading;
        return (
            <div>
                {loading && <WSpinner loading={loading} />}
                <div>
                    <div className="container">
                        <WBreadCrumb activeCrumb="Add Account" breadCrumbItems={breadCrumbURL} />
                        <Heading>{navProps.submit === 'true' ? constants.bankAccount.verifydBankHeading : constants.bankAccount.addBankHeading}</Heading>
                        <OtherBankAccount ref={this.otherBankRef} setStatus={this.setStatus} viewOnly={navProps.submit === 'true'} nomargin />
                    </div>
                    <CommonButtons {...navProps} />
                </div>
            </div>
        );
    }
}

AddAccounts.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    checkStatus: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    bankAccountReducerData: PropTypes.instanceOf(Object),
    addBankAcc: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
};
AddAccounts.defaultProps = {
    checkStatus: () => {},
    savedState: null,
    bankAccountReducerData: {},
    addBankAcc: () => {},
    clearBankAccDetails: () => {},
    analyzeFormAdandonment: () => {},
};
export const mapStateToProps = state => {
    return {
        bankAccountReducerData: state.bankAccountReducerData,
        addBankAccountData: state.addBankAccountData,
        clearBankAccDetails: state.clearBankAccDetails,
    };
};

export const mapDispatchToProps = {
    addBankAcc: bankAccountAction.addBankAcc,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    analyzeFormAdandonment: analytics.analyzeFormAdandonment,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccounts));
