import {
    Accordian,
    AccordianTitle,
    BottomNavWrapper
} from "common";
import Constants, { RECURRING_TRANSFER_PLAN, LOCALE } from "modules/AccountOptions/constant";
import { DatePicker } from 'modules/CustomerManagementModule/components';
import { Form, Col } from 'react-bootstrap';
import { formatDate, isValidDate, formatDateToTwoDigit } from "utils";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { SelectDropDown } from 'modules/CustomerManagementModule/components';

const requiredTxt = '* All fields are required unless mentioned optional';

const NoteWeekend = styled.div`
    margin-top: 10px;
`;
const RequiredDiv = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const NoteTxt = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;

    margin-bottom: 2.5rem;
    padding: 1.625rem 1.875rem;

    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;

    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 19px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;

const NextWithdrawalTxt = styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 50px;
    @media only screen and (max-width: 767px) {
        display:block;
    }
`;


AccountDetailsFlex.displayName = "AccountDetailsFlex";

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledRow = styled.div`
    display:flex;
    align-items: center;
    margin-bottom: 15px;
    @media only screen and (max-width: 767px) {
        display:block;
    }
`;

const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: rgb(73, 73, 74);
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

export const getNextInvestmentDate = (dateObj, plan) => {
    const { startDate = "", frequency = "" } = dateObj || {};
    if (!startDate || !frequency) return "";
    const newDate = new Date(startDate);
    const day = newDate.getDate();
    const startMonth = newDate.getMonth();
    const startYear = newDate.getFullYear();
    let nextInvestment = "";
    switch (frequency) {
        case "M": {
            nextInvestment = new Date(startYear, startMonth + 1, day);
            break;
        }
        case "W": {
            nextInvestment = new Date(startYear, startMonth, day + 7);
            break;
        }
        case "S": {
            nextInvestment = new Date(startYear, startMonth, day + 14);
            break;
        }
        case "Q": {
            nextInvestment = new Date(startYear, startMonth + 3, day);
            break;
        }
        case "T": {
            nextInvestment = new Date(startYear, startMonth + 6, day);
            break;
        }
        case "P": {
            nextInvestment = new Date(startYear, startMonth + 2, day);
            break;
        }
        case "B": {
            if (plan === RECURRING_TRANSFER_PLAN.SWP) {
                nextInvestment = new Date(startYear, startMonth, day + 14);
            } else {
                nextInvestment = new Date(startYear, startMonth + 2, day);
            }
            break;
        }
        case "A": {
            nextInvestment = new Date(startYear + 1, startMonth, day);
            break;
        }
        default:
            break;
    }
    return nextInvestment && formatDateToTwoDigit(nextInvestment);
}

const checkIsDateValid = date => {
    const dateValue = new Date(date);
    return dateValue.getDate().toString() !== 'NaN';
};

class ScheduleAIP extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            investVal: "",
            onTheDayVal: "",
            startDate: "",
            ending: "",
            endDate: "",
            retrivedState: false,
            nextInvestment: "",
            frequencyText: "",
            prevInvestVal: "",
            displayDetails: Constants.InitialScheduleDisplayDetails,
            disableNextButton: this.shouldDisableNextButton({ ...props.savedState })
        };

        this.scheduleRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                prevInvestVal: savedState.investVal,
                retrivedState: true,
            };
        }
        return null;
    }

    componentDidMount() {

        const {plan, isEdit} = this.props;
        // TODO: refactoring needed. review this behavior
        setTimeout(() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        }))
        if(plan === RECURRING_TRANSFER_PLAN.AIP) {
            document.title = `Schedule: ${isEdit ? 'Edit AIP': 'Add AIP'} | Victory Capital`;
        } else {
            document.title = `Schedule: ${isEdit ? 'Edit SWP': 'Add SWP'} | Victory Capital`;
        }
        const { editSection } = this.props;
        if (editSection === "Schedule")
            window.scrollTo({
                top: this.scheduleRef.current.offsetTop,
                behavior: "smooth",
            });
            const skipToMain = document.getElementById('skipToMain');
        if (skipToMain) {
            document.activeElement.blur();
            skipToMain.focus();
        }
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;
        saveChildrenState(this.state, pageIndex);
    }

    setDisplayData = (data) => {
        const {
            frequencyText = "",
            startDate = "",
            endDate = "",
            nextInvestment = "",
        } = data || {};
        const { plan, isEdit } = this.props;
        const displayData = [];

        if (isEdit) {
            displayData.push(
                {
                    field: "Withdrawal Frequency",
                    value: frequencyText,
                },
                {
                    field: "Next Withdrawal Date",
                    value: nextInvestment,
                }
            );
            return displayData
        }



        if (plan !== RECURRING_TRANSFER_PLAN.AIP) {
            displayData.push(
                {
                    field: "Withdrawal Frequency",
                    value: frequencyText,
                },
                {
                    field: isEdit ? "Creation Date" : "Beginning on",
                    value: startDate,
                },
                {
                    field: "Next Withdrawal Date",
                    value: nextInvestment,
                }
            );

            if (!isEdit) {
                displayData.splice(2, 0, {
                    field: "Ending by",
                    value: endDate,
                })
            }


        } else {
            displayData.push(
                {
                    field: "Frequency",
                    value: frequencyText,
                },
                {
                    field: isEdit ? "Creation Date" : "Start Date",
                    value: startDate,
                }
            );
        }

        return displayData


    };

    handleFrequencyChange = (e) => {
        const { target } = e;
        const { plan, isEdit } = this.props;
        this.setState((prevState) => {
            const { startDate, displayDetails, businessDate } = prevState;
            let { endDate } = prevState;
            const { schedule } = displayDetails;
            const investVal = target.value;
            const frequencyText = Constants.frequency[`${plan}`][investVal.toString()];
            const nextInvestment = isEdit ? prevState.nextInvestment : getNextInvestmentDate({
                startDate,
                frequency: investVal,
            }, plan);

            if (
                !isEdit &&
                endDate &&
                nextInvestment &&
                new Date(endDate) < new Date(nextInvestment)
            ) {
                endDate = "";
            }

            schedule.data = this.setDisplayData({
                frequency: investVal,
                frequencyText,
                startDate,
                endDate,
                nextInvestment,
            });

            const disableNextButton = this.shouldDisableNextButton({ investVal, startDate, nextInvestment, businessDate, endDate });
            this.props.getDateAndFrequency(investVal)

            return {
                endDate,
                investVal,
                displayDetails,
                nextInvestment,
                frequencyText,
                disableNextButton
            };
        });
    };

    handleStartDateChange = (date) => {
        const { plan, isEdit,tradingWindowApiData } = this.props;

        this.setState((prevState) => {
            const { investVal, frequencyText, displayDetails } = prevState;
            let { endDate } = prevState;
            const { schedule } = displayDetails;
            const startDate = formatDate(date, 'MM/DD/YYYY');
            const nextInvestment = isEdit ? prevState.nextInvestment : getNextInvestmentDate({
                startDate,
                frequency: investVal,
            }, plan);

            if (
                !isEdit &&
                endDate &&
                nextInvestment &&
                new Date(endDate) < new Date(nextInvestment)
            ) {
                endDate = "";
            }

            schedule.data = this.setDisplayData({
                frequency: investVal,
                frequencyText,
                startDate,
                endDate,
                nextInvestment,
            });
          //  const businessDate = this.calcWorkingDays(new Date(), 5);
            const businessDate = new Date(tradingWindowApiData.aipTradingStartDateKey);
            const disableNextButton = this.shouldDisableNextButton({ investVal, startDate, endDate, nextInvestment, businessDate });
            this.props.getDateAndFrequency(startDate)
            return {
                endDate,
                startDate,
                selectedStartDate: formatDate(date, 'MM/DD/YYYY'),
                displayDetails,
                nextInvestment,
                disableNextButton,
                businessDate
            };
        });
    };

    handleEndingChange = (e) => {
        const { target } = e;
        const { isEdit } = this.props;

        if (!isEdit) {
            this.setState((prevState) => {
                const {
                    startDate,
                    displayDetails,
                    frequencyText,
                    nextInvestment,
                } = prevState;
                let { endDate } = prevState;
                const { schedule } = displayDetails;
                const ending = target.value;
                if (ending === "Never") {
                    endDate = "";
                }
                schedule.data = this.setDisplayData({
                    frequencyText,
                    startDate,
                    endDate,
                    nextInvestment,
                });
                return {
                    endDate,
                    ending,
                    displayDetails,
                };
            });
        }

    };

    handleEndDateChange = (date) => {
        const { isEdit } = this.props;

        if (!isEdit) {
            this.setState((prevState) => {
                const {
                    investVal,
                    startDate,
                    displayDetails,
                    nextInvestment,
                    frequencyText,
                    businessDate
                } = prevState;
                const { schedule } = displayDetails;
                const endDate = formatDate(date, 'MM/DD/YYYY');

                schedule.data = this.setDisplayData({
                    frequencyText,
                    startDate,
                    endDate,
                    nextInvestment,
                });

                const disableNextButton = this.shouldDisableNextButton({ investVal, startDate, nextInvestment, endDate, businessDate });

                return {
                    endDate,
                    displayDetails,
                    disableNextButton
                };
            });
        }
    };

    shouldDisableNextButton = data => {
        const {
            investVal,
            startDate,
            nextInvestment,
            endDate,
            businessDate
        } = data;
        const { plan, isEdit } = this.props;
        let disableNextButton = true;
        if (plan !== RECURRING_TRANSFER_PLAN.AIP) {
            disableNextButton = isEdit ? false : !(
                investVal &&
                isValidDate(startDate) &&
                isValidDate(endDate) &&
                isValidDate(nextInvestment) &&
                formatDate(endDate) >= formatDate(nextInvestment) &&
                (!businessDate || (businessDate && formatDate(startDate) >= formatDate(businessDate)))
            )
        } else {
            disableNextButton = isEdit ? false : !(
                investVal &&
                isValidDate(startDate) &&
                (!businessDate || (businessDate && formatDate(startDate) >= formatDate(businessDate)))
            );
        }
        return disableNextButton;
    }


    calcWorkingDays = (fromDate, days) => {
        let count = 0;
        while (count <= days) {
            fromDate.setDate(fromDate.getDate() + 1);
            if (fromDate.getDay() !== 0 && fromDate.getDay() !== 6) // Skip weekends
                count += 1;
        }
        return fromDate;
    }

    bindComponent(ScheduleContents) {
        const {
            investVal,
            startDate,
            endDate,
            nextInvestment,
            selectedStartDate,
            businessDate
        } = this.state;

        const { plan, isEdit,tradingWindowApiData } = this.props;
        let minStartDate = !isEdit ? new Date(tradingWindowApiData.aipTradingStartDateKey) : '';
        let minEndDate = '';

        const selectedDate = new Date(selectedStartDate);

        if (selectedDate && businessDate) {
            if (formatDate(selectedDate) < formatDate(new Date()) || (formatDate(selectedDate) < formatDate(businessDate))) {
                minStartDate = businessDate;
            }
        }
        if (nextInvestment) {
            minEndDate = new Date(nextInvestment);
        } else {
            minEndDate = new Date();
        }
        const {
            invest: investContent,
            onTheDayTxt = "",
            endDateTxt = "",
            nextWithdrawalTxt = "",
        } = ScheduleContents || {};
        const { value: investContentValue, text: investContentTxt = "" } =
            investContent || {};

        const investContentVal = investContentValue || [];

        console.log('investContentTxt,investContentVal ',investContentTxt,investContentVal,investVal)

        return (
            <>
                <StyledRow>
                    <StyledLabel column sm={4} md={4} lg={4}  tabIndex="0" htmlFor="ScheduleinvestVal">{investContentTxt}</StyledLabel>
                    <Col sm={4} md={3} lg={3}>

                            <SelectDropDown
                                    id="ScheduleinvestVal"
                                    itemlist={investContentVal}
                                    label={investContentTxt}
                                    onChange={this.handleFrequencyChange}
                                    value={investVal}
                                    name="AIPFrequency"
                                    role="combobox"
                                    required
                                    />
                        {/* <VCMSelectWithoutLabel
                            id="ScheduleinvestVal"
                            itemlist={investContentVal}
                            label={investContentTxt}
                            onChange={this.handleFrequencyChange}
                            value={investVal}
                            name="AIPFrequency"
                            role="combobox"
                           

                        /> */}
                    </Col>
                </StyledRow>
                {isEdit ? (
                    <NextWithdrawalTxt>
                        <StyledLabel column sm={4} md={4} lg={4} tabIndex="0">{nextWithdrawalTxt}</StyledLabel>
                        <StyledLabel column sm={4} md={4} lg={4} tabIndex="0">{nextInvestment}</StyledLabel>
                    </NextWithdrawalTxt>
                ) : (
                        <>
                            <StyledRow>
                                <StyledLabel column sm={4} md={4} lg={4} tabIndex="0" htmlFor="ScheduleonTheDayVal">
                                    {onTheDayTxt}
                                </StyledLabel>
                                <Col sm={4} md={3} lg={3}>
                                    <DatePicker
                                        id="ScheduleonTheDayVal"
                                        name="RMDStartDate"
                                        selected={checkIsDateValid(startDate) ? new Date(startDate) : null}
                                        onChange={this.handleStartDateChange}
                                        disabled={isEdit}
                                        required
                                        minDate={minStartDate}
                                        className='analytics-form-fields'
                                        data-name="AIPStartDate"
                                        data-form-field
                                        locale={LOCALE}
                                    />
                                </Col>
                            </StyledRow>

                            {plan !== RECURRING_TRANSFER_PLAN.AIP && (
                                <StyledRow>
                                    <StyledLabel column sm={4} md={4} lg={4} tabIndex="0" htmlFor="SelectEndDateVal">
                                        {endDateTxt}
                                    </StyledLabel>
                                    <Col sm={4} md={3} lg={3}>
                                        <DatePicker
                                            id="SelectEndDateVal"
                                            name="RMDStartDate"
                                            selected={checkIsDateValid(endDate) ? new Date(endDate) : null}
                                            onChange={this.handleEndDateChange}
                                            required
                                            minDate={minEndDate}
                                            data-name="SWPEndDate"
                                            data-form-field
                                            locale={LOCALE}
                                            disabled={isEdit}
                                        />
                                    </Col>
                                </StyledRow>
                            )}
                        </>
                    )
                }
            </>
        );
    }

    render() {
        const { navigationObj, contents, navBarAnalyticsID } = this.props;
        const { disableNextButton } = this.state;
        const { scheduleTxt = "", note, noteWeekend } = contents || {};
        return (
            <>
             <RequiredDiv>{requiredTxt}</RequiredDiv>
                <Accordian
                    ref={this.scheduleRef}
                    key={scheduleTxt}
                    titleChild={
                        <div>
                            <AccordianTitle accType={scheduleTxt} id={scheduleTxt + "_AccordianTitle"} accHeaderAs="button" />
                        </div>}
                    titleSeperator
                    Id={scheduleTxt + "_Accordian"}
                    labelledBy={scheduleTxt + "_Accordian"}
                >
                    <div>
                        <FlexColumnDiv>
                            {this.bindComponent(contents)}
                            {note && <NoteTxt>
                                <div>{note}</div>
                                <NoteWeekend>{noteWeekend}</NoteWeekend>
                            </NoteTxt>}
                        </FlexColumnDiv>
                       
                    </div>
                </Accordian>
               
                <BottomNavWrapper
                    {...navigationObj}
                    showBack
                    disabled={disableNextButton}
                    arialabelback="Back to plan details"
                    dataAnalyticId={navBarAnalyticsID}
                />
            </>
        );
    }
}

ScheduleAIP.propTypes = {
    navigationObj: PropTypes.shape({
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    savedState: PropTypes.instanceOf(Object),
    saveChildrenState: PropTypes.func,
    pageIndex: PropTypes.number.isRequired,
    accDetails: PropTypes.instanceOf(Object),
    editSection: PropTypes.oneOfType([
        PropTypes.instanceOf(Object),
        PropTypes.string,
    ]),
    plan: PropTypes.string.isRequired,
    contents: PropTypes.instanceOf(Object),
    isEdit: PropTypes.bool,
    navBarAnalyticsID: PropTypes.string,
};
ScheduleAIP.defaultProps = {
    savedState: null,
    saveChildrenState: () => { },
    accDetails: {},
    editSection: null,
    contents: {},
    navigationObj: {
        onPrimaryClick: () => { },
        onSecondaryClick: () => { },
        portalRoot: null,
    },
    isEdit: true,
    navBarAnalyticsID: '',
};

export default ScheduleAIP;
