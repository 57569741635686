const ACCESS_FORMS_CONSTANTS = {
    ACTIVE_BREAD_CRUM: "Access Forms",
    FORM_TITLE:'Forms',
    FREEPDF_1:"You'll need the free ",
    FREEPDF_2:" to view these documents.",
    PRF_URL:'https://get.adobe.com/reader/',
    PDF:'Adobe Reader',
    FORM_ID:'Form ID',
    CATEGORY_NAME:'Category Name',
    FORM_NAME:'Form Name',
    SORTBY:'Sort By',
    LOAD_MORE: "Load More",
    NO_RECORDS_FOUND:"No Records Found...",
}


export default ACCESS_FORMS_CONSTANTS;


