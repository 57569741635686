import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {   
    gettingStartedSwitchOn: false,
    gettingStartedSwitchOff: true,

    livingSwitchOn: false,
    livingSwitchOff: true,

    growingSwitchOn: false,
    growingSwitchOff: true,

    eastPlanningSwitchOn: false,
    eastPlanningSwitchOff: true,

    taxCenterSwitchOn: false,
    taxCenterSwitchOff: true,

    understandingInvestSwitchOn: false,
    understandingInvestSwitchOff: true,

    saveBudgetSwitchOn: false,
    saveBudgetSwitchOff: true,

    debtCreditSwitchOn: false,
    debtCreditSwitchOff: true,

    financialSetSwitchOn: false,
    financialSetSwitchOff: true,

    getMarriedSwitchOn: false,
    getMarriedSwitchOff: true,

    becomeParentSwitchOn: false,
    becomeParentSwitchOff: true,

    parentingSwitchOn: false,
    parentingSwitchOff: true,

    youngAdultSwitchOn: false,
    youngAdultSwitchOff: true,

    getDivorceSwitchOn: false,
    getDivorceSwitchOff: true,

    lossOfLovedSwitchOn: false,
    lossOfLovedSwitchOff: true,

    lifeInsuranceSwitchOn: false,
    lifeInsuranceSwitchOff: true,

    healthInsuranceSwitchOn: false,
    healthInsuranceSwitchOff: true,

    joiningMilitarySwitchOn: false,
    joiningMilitarySwitchOff: true,

    deploymentSwitchOn: false,
    deploymentSwitchOff: true,

    pcsSwitchOn: false,
    pcsSwitchOff: true,

    leaveMilitarySwitchOn: false,
    leaveMilitarySwitchOff: true,
};

function accMessagingAdviceReducer (state = initialState, action){
    // debugger
      switch(action.type){
          case ActionTypes.LOCAL_STATE: {
              return {
                  ...state,
                  ...action,
              };
          }        
          default:
              return state;
      }
  } 

export default accMessagingAdviceReducer;