/**
 * Personal Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Personal information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as qa from 'query-string';
import { Redirect } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { decrypt } from 'utils';
import {
    WBreadCrumb,
    WStepper,
    WInput,
    CommonButtons,
    WSaveModal,
    WMessageBar,
    WSpinner,
    ConformationModal,
    ConfirmModal,
} from '../../../common';
import './style.css';
import PersonalInfoCardComponent from '../PersonalInfoCard';
import EmploymentInfoCard from '../EmploymentInfoCard';
import FinancialInfoCard from '../FinancialInfoCard';
import MilitaryInfoCard from '../MilitaryInfoCard';
import BeneficiaryCard from '../BeneficiaryCard/BeneficiaryCard';
import JointPersonalInfoCardComponent from '../PersonalInfoCard/JointPersonalInfoCardContainer';
import styles from './styles';
import consts from './consts';
import AccGlobalConstants from '../AccountManagementConstants';
import generatePersonalInfoPayload from './GeneratePersonalInfoPayload';
import checkValidity from '../../../utils/checkValidity';
import Rules from './Rules';
import envConfig from 'env/EnvConfig';
import { getValidValue } from 'commonHelper/CommonHelperFunctions';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const UTMAStateWithAge18ForNonMinor = ['VA', 'SD', 'OK', 'NV', 'MI', 'ME', 'LA', 'KY', 'DC', 'CA', 'SC'];

const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

class PersonalInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            nickName: '',
            error: {},
            showAlert: false,
            errorMessage: '',
            allSectionsValid: false,
            loading: false,
            showConfirmCancelModal: false,
            primaryOwnerDataVerified: false,
            jointOwner1DataVerified: false,
            jointOwner2DataVerified: false,
            isAccountOpeningAllowed: true,
            showAccountOpeningErrorModal: false,
            isJoint: false,
            jointOwnerVerification: false,
            numberOfJointOwners: 0,
        };
        this.primaryOwnerPerCardRef = React.createRef();
        this.primaryOwnerEmpCardRef = React.createRef();
        this.primaryOwnerFinCardRef = React.createRef();
        this.primaryOwnerMilCardRef = React.createRef();
        this.jointOwnerPerCardRef = React.createRef();
        this.beneficiaryCardRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { initialState } = nextProps;
        const { retrivedUserInfo = false, nickName } = prevState;
        const {
            localStateData,
            manageLocalState,
            accOpeningData,
            profileInformationData,
            clearLocalStateForNAOAccTypeChange,
        } = nextProps;
        const accType = localStateData.accountType || 'Individual';
        const role = localStorage.getItem('role');
        if (
            (role === 'Guest' || role === 'Prospect') &&
            initialState.getCustomerProfile &&
            !retrivedUserInfo &&
            !accOpeningData.isLoadingApplication
        ) {
            if (
                accOpeningData.application &&
                accOpeningData.application[accType.toString()] &&
                !localStateData.OpenAccPageTwo
            ) {
                manageLocalState({
                    ...accOpeningData.application,
                });
            } else if (!localStateData[accType.toString()]) {
                clearLocalStateForNAOAccTypeChange();
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            ...initialState.getCustomerProfile,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            } else if (localStateData.marstoneFlag === true) {
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            firstName: initialState.getCustomerProfile.firstName,
                            lastName: initialState.getCustomerProfile.lastName,
                            middleInitial: initialState.getCustomerProfile.middleInitial,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            }

            return {
                retrivedUserInfo: true,
                loading: initialState.isLoading || accOpeningData.isLoading,
                showConfirmModal: accOpeningData.showConfirmModal,
                confirmMessage: accOpeningData.confirmMessage,
                primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
                jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
                jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
            };
        }

        if (
            role === 'Member' &&
            profileInformationData.profileInformation &&
            !retrivedUserInfo &&
            !accOpeningData.isLoadingApplication
        ) {
            if (
                accOpeningData.application &&
                accOpeningData.application[accType.toString()] &&
                !localStateData.OpenAccPageTwo
            ) {
                manageLocalState({
                    ...accOpeningData.application,
                });
            } else if (!localStateData[accType.toString()]) {
                clearLocalStateForNAOAccTypeChange();
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            ...profileInformationData.profileInformation,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            } else if (localStateData.marstoneFlag === true) {
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            firstName: profileInformationData.profileInformation.firstName,
                            lastName: profileInformationData.profileInformation.lastName,
                            middleInitial: profileInformationData.profileInformation.middleInitial,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            }

            return {
                retrivedUserInfo: true,
                loading: initialState.isLoading || accOpeningData.isLoading,
                showConfirmModal: accOpeningData.showConfirmModal,
                confirmMessage: accOpeningData.confirmMessage,
                primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
                jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
                jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
            };
        }
        return {
            loading: initialState.isLoading || accOpeningData.isLoading,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
            jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
            jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
        };
    }

    componentDidMount() {
        const {
            localStateData,
            profileInformationData,
            getProfileBasicInformations,
            initialState,
            getCustomerProfile,
            accOpeningData,
            getCognitoForCustomerVerification,
        } = this.props;
        const { accountType } = localStateData;
        const accType = accountType;
        const role = localStorage.getItem('role');

        if (accOpeningData && accOpeningData.accountDefaultNickName) {
            this.setState({ nickName: accOpeningData.accountDefaultNickName });
        }

        if (!initialState.getCustomerProfile && (role === 'Guest' || role === 'Prospect')) {
            getCustomerProfile({});
        }
        if (!profileInformationData.profileInformation) {
            getProfileBasicInformations(decrypt);
        }

        // Gets cognito token for customer GIACT verification
        const cognitoPayload = qa.stringify({
            grant_type: 'client_credentials',
            client_id: envConfig.COGNITO_CUSTOMER_GIACT_VERIFICATION_CLIENT_ID,
            client_secret: envConfig.COGNITO_CUSTOMER_GIACT_VERIFICATION_CREDENTIALS,
        });

        getCognitoForCustomerVerification(cognitoPayload);

        if (accType && localStateData[accType.toString()]) {
            const finalStateObj = {};
            const personalData = localStateData[accType.toString()];
            if (personalData.nickName !== undefined) {
                finalStateObj.nickName = personalData.nickName;
            }
           
            this.setState({ ...finalStateObj }, () => {
                this.checkStatus();
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    updateState = prevProps => {
        const { accOpeningData } = this.props;

        // Image Upload Handling
        this.handleuploadImage(accOpeningData, prevProps);

        // W8Form Upload Handling
        this.handleW8FormUpload(accOpeningData, prevProps);

        //   Save Function Values Start
        if (
            accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT &&
            accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT !== prevProps.accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT
        ) {
            if (accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT.status) {
                this.setState({ showSaveModal: true, loading: false, variantForUpload: 'success' });
            } else {
                const msg = accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        // Error
        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleuploadImage = (accOpeningData, prevProps) => {
        if (accOpeningData.UPLOAD_AVATAR && accOpeningData.UPLOAD_AVATAR !== prevProps.accOpeningData.UPLOAD_AVATAR) {
            if (accOpeningData.UPLOAD_AVATAR.imageDetails) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: {
                        message: 'Success',
                        info: 'Image Uploaded',
                    },
                    imageFileUploaded: true,
                    showSaveModal: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                const msg = accOpeningData.UPLOAD_AVATAR.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    imageFileUploaded: false,
                    showSaveModal: false,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    handleW8FormUpload = (accOpeningData, prevProps) => {
        if (accOpeningData.UPLOAD_W8FORM && accOpeningData.UPLOAD_W8FORM !== prevProps.accOpeningData.UPLOAD_W8FORM) {
            if (accOpeningData.UPLOAD_W8FORM.imageDetails) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: {
                        message: 'Success',
                        info: 'Form Uploaded',
                    },
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                const msg = accOpeningData.UPLOAD_W8FORM.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    showSaveModal: false,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    convertBase64ToFile = (base64, index) => {
        const { originalFileArray } = this.state;
        const encodedFile = this.base64ToArrayBuffer(base64);
        const ext =
            originalFileArray &&
            originalFileArray.map((f, i) => {
                if (i === index) {
                    return f.type;
                }
                return {};
            });
        const fileName =
            originalFileArray &&
            originalFileArray.map((f, i) => {
                if (i === index) {
                    return f.fileName;
                }
                return {};
            });
        return new File([encodedFile], fileName, { type: ext });
    };

    base64ToArrayBuffer = base64 => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i += 1) {
            bytes[Number(i)] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    getCardValues = data => {
        const { childArr } = this.state;
        if (childArr) {
            childArr.push(data);
        }
    };

    getJointCardValues = data => {
        const { jointChildArr } = this.state;
        if (jointChildArr) {
            jointChildArr.push(data);
        }
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    uploadProfileImage = imgData => {
        if (imgData !== undefined) {
            const { uploadAavatarImg } = this.props;
            const { imageFileUploaded, originalFileArray } = this.state;
            if (originalFileArray === null || (originalFileArray === undefined && !imageFileUploaded)) {
                const payload = {
                    Body: imgData.personalInfoUploadPh[0].base64String,
                };
                this.setState({
                    personalInfoUploadPh: imgData.personalInfoUploadPh[0].base64String,
                    originalFileArray: [imgData.originalpersonalInfoUploadPh],
                    loading: true,
                });
                uploadAavatarImg(payload);
            }
        }
    };

    checkStatus = () => {
        let disableNext = true;
        let primMilData = {};
        let primEmpData = {};
        let primPerData = {};
        primPerData = this.primaryOwnerPerCardRef.current;
        primEmpData = this.primaryOwnerEmpCardRef.current;
        primMilData = this.primaryOwnerMilCardRef.current;
        if (primPerData) {
            const perState = { ...primPerData.state };
            disableNext = !perState.pageReady;
        }
        if (primEmpData) {
            const empState = { ...primEmpData.state };
            disableNext = disableNext || !empState.ready;
        }
        if (primMilData) {
            const milState = { ...primMilData.state };
            disableNext = disableNext || !milState.ready;
        }
        const { disableNext: prevStateNextDisabled } = this.state;
        if (prevStateNextDisabled !== disableNext) {
            this.setState(() => {
                return {
                    disableNext,
                };
            });
        }
    };

    isJointValidFunction = (accType, toBeSavedData) => {
        const { nickName, originalFileArray, imageFileUploaded } = this.state;
        let jointPerData = {};
        let isJointValid = false;
        let toBeSavedDataTemp = toBeSavedData;
        if (accType.toLowerCase().indexOf('joint') > -1) {
            jointPerData = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.sendDataToParent();
            toBeSavedDataTemp = {
                ...toBeSavedData,
                jointPerInfo: jointPerData,
                nickName,
                originalFileArray,
                imageFileUploaded,
            };
            isJointValid = jointPerData.valid;
        } else {
            isJointValid = true;
        }
        return { isJointValid, toBeSavedData: toBeSavedDataTemp };
    };

    saveToLocalAction = (toBeSavedData, action, allFieldsValid, accType) => {
        const { nickName } = this.state;
        const { manageLocalState, localStateData, saveApplication } = this.props;
        const alreadySavedData = localStateData && localStateData[accType.toString()];
        const finalData = { ...alreadySavedData, ...toBeSavedData };
        const payLoad = generatePersonalInfoPayload(localStateData, finalData, nickName);
        const isJoint = accType.toLowerCase().indexOf('joint') > -1;
        if (action === 'save' && allFieldsValid) {
            const savePayload = { ...localStateData, [accType]: finalData, OpenAccPageTwo: payLoad };
            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: accType,
                    payload: savePayload,
                },
                cleanPayload: isJoint,
            });

            this.setState({ save: true, loading: true });
        } else if (accType.indexOf('529') > -1) {
            const { back } = this.state;
            let data = {};
            if (back) {
                data = {
                    esaInfo: {
                        signedDocument: false,
                        pageName: 'application1',
                    },
                };
            }
            if (action === 'next' && allFieldsValid) {
                this.setState({ next: true });
            }
            manageLocalState({ data, OpenAccPageTwo: payLoad, [accType]: finalData });
        } else {
            if (action === 'next' && allFieldsValid) {
                this.setState({ next: true });
            }
            manageLocalState({ [accType]: finalData, OpenAccPageTwo: payLoad });
        }
    };

    scrollTo = errorSections => {
        const firstErrorSection = errorSections.find(section => !section.valid);

        firstErrorSection.element.accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    /**
     * Validates Physical Address State for Joint Tenants with Right of Survivorship account type. If state is Louisiana or Puerto Rico then Account creation should not be allowed.
     */
    validatePhysicalAddress = (perInfo, jointOwnersInfo) => {
        const perPhysicalAddressState =
            perInfo && perInfo.sameAddress === 'Y'
                ? perInfo.primMailAddress && perInfo.primMailAddress.state
                : perInfo.primPhysicalAddress && perInfo.primPhysicalAddress.state;
        const isJointPhysicalAddressStateValid = [];
        for (let i = 0; i < jointOwnersInfo.jointOwners.length; i += 1) {
            const jointPhysicalAddressState =
                jointOwnersInfo.jointOwners[parseInt(i, 10)] &&
                jointOwnersInfo.jointOwners[parseInt(i, 10)].sameAddress === 'Yes'
                    ? jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData.state
                    : jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData.state;
            if (jointPhysicalAddressState === 'LA' || jointPhysicalAddressState === 'PR') {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = false;
            } else {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = true;
            }
        }
        let isAllJointAccAddressValid = true;
        for (let i = 0; i < isJointPhysicalAddressStateValid.length; i += 1) {
            if (isJointPhysicalAddressStateValid[parseInt(i, 10)] === false) {
                isAllJointAccAddressValid = false;
            }
        }
        return perPhysicalAddressState !== 'LA' && perPhysicalAddressState !== 'PR' && isAllJointAccAddressValid;
    };

    getAge = DOB => {
        const today = new Date();
        const birthDate = new Date(DOB);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age -= 1;
        }

        return age;
    };

    validateBeneficiaryAge = (perInfo, benInfo) => {
        const perPhysicalAddressState =
            perInfo && perInfo.sameAddress === 'Y'
                ? perInfo.primMailAddress && perInfo.primMailAddress.state
                : perInfo.primPhysicalAddress && perInfo.primPhysicalAddress.state;
        const age = this.getAge(benInfo.beneficiaryPrimaryDataArray[0].beneficiaryDOB);
        let isValidAge = true;
        if ((UTMAStateWithAge18ForNonMinor.includes(perPhysicalAddressState) && age >= 18) || age >= 21) {
            isValidAge = false;
        }
        return isValidAge;
    };

    validateCustomerDetails = primPerData => {
        const sameAddress = primPerData && primPerData.sameAddress === 'Y';
        const mailingAddress = primPerData.primMailAddress;

        const addressLine2 = sameAddress
            ? mailingAddress && mailingAddress.lineTwo
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.lineTwo;
        const addressLine1 = sameAddress
            ? mailingAddress && mailingAddress.lineOne
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.lineOne;
        const city = sameAddress
            ? mailingAddress && mailingAddress.city
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.city;
        const state = sameAddress
            ? mailingAddress && mailingAddress.state
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.state;
        const zip = sameAddress
            ? mailingAddress && mailingAddress.zipCode
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.zipCode;


        // DO NOT DELETE - COMMENTED OUT CUSTOMER VERIFICATION API CALL TEMPORARILY UNTIL STRATEGY IS FINALIZED ON HOW TO TEST THIS FUNCTIONALITY

        // verifyCustomerDetails({
        //     customerData: customerDataPayload,
        //     token: accOpeningData.cognitoTokenForGIACT,
        //     customerType: 'primaryOwner',
        // });
    };

    validateJointOwnersDetails = jointOwners => {

        this.setState({ numberOfJointOwners: jointOwners.length });

        for (let i = 0; i < jointOwners.length; i += 1) {
            const sameAddress = jointOwners[parseInt(i, 10)].sameAddress === 'Yes';
            const mailingAddress = jointOwners[parseInt(i, 10)].jointMailAddressData;
            const physicalAddress = jointOwners[parseInt(i, 10)].jointPhysicalAddressData;

            const addressLine2 = sameAddress
                ? mailingAddress && mailingAddress.lineTwo
                : physicalAddress && physicalAddress.lineTwo;
            const addressLine1 = sameAddress
                ? mailingAddress && mailingAddress.lineOne
                : physicalAddress && physicalAddress.lineOne;
            const city = sameAddress ? mailingAddress && mailingAddress.city : physicalAddress && physicalAddress.city;
            const state = sameAddress
                ? mailingAddress && mailingAddress.state
                : physicalAddress && physicalAddress.state;
            const zip = sameAddress
                ? mailingAddress && mailingAddress.zipCode
                : physicalAddress && physicalAddress.zipCode;

            const phoneNumber =
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails.phoneNumber &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails.phoneNumber.replace(/-/g, '');

            const emailAddress =
                jointOwners[parseInt(i, 10)].jointEmailAddressData &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData.primaryEmailDetails &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData.primaryEmailDetails.email;

            

            // DO NOT DELETE - COMMENTED OUT CUSTOMER VERIFICATION API CALL TEMPORARILY UNTIL STRATEGY IS FINALIZED ON HOW TO TEST THIS FUNCTIONALITY
            // verifyCustomerDetails({
            //     customerData: customerDataPayload,
            //     token: accOpeningData.cognitoTokenForGIACT,
            //     customerType: jointOwnerIndex,
            // });
        }
    };


    checkForBeneficiaryDataValid = (beneficiaryData) => {
        return(beneficiaryData !== undefined && beneficiaryData !== null ? beneficiaryData.valid : true)
    }

    checkFieldValid = (primPerData, primEmpData, primFinData, primMilData, isbeneficiaryDataValid) => {
        return(
            primPerData.valid &&
            primEmpData.valid &&
            primFinData.valid &&
            primMilData.valid &&
            isbeneficiaryDataValid
        )
    }

    checkForValidateCustomerDetails = (isAccountOpeningAllowed, primPerData) => {
        if (isAccountOpeningAllowed) {
            this.validateCustomerDetails(primPerData);
        }
    }


    validateJointOwnerData = (isAccountOpeningAllowed, jointPerData, isJoint) => {
        let {jointOwner1DataVerified, jointOwner2DataVerified} = this.state

        if (isAccountOpeningAllowed && jointPerData && jointPerData.jointOwners.length > 0) {
            this.setState({ isJoint: true });
            this.validateJointOwnersDetails(jointPerData.jointOwners);
        }
        if (isAccountOpeningAllowed && isJoint) {
            let jointOwnerVerification = jointOwner1DataVerified;
            if (jointPerData && jointPerData.jointOwners.length > 1) {
                jointOwnerVerification = jointOwner1DataVerified && jointOwner2DataVerified;
            }
            this.setState({ jointOwnerVerification });
        }

    }

    checkAndSaveToLocalIfAlowed = (isAccountOpeningAllowed, toBeSavedData, action, allFieldsValid, accType) => {
        if (isAccountOpeningAllowed) {
            this.saveToLocalAction(toBeSavedData, action, allFieldsValid, accType);
        }
    }
    

    saveToLocal = (action = '') => {
        const { localStateData } = this.props;
        const accType = getValidValue(localStateData?.accountType, 'Individual');

        const {
            nickName,
            originalFileArray,
            imageFileUploaded
        } = this.state;
        if (accType !== undefined) {
            let primMilData = {};
            let primFinData = {};
            let primEmpData = {};
            let primPerData = {};
            let jointPerData = {};
            let toBeSavedData = {};
            let beneficiaryData = {};
            let allFieldsValid = false;
            let isJointValid = false;
            primPerData = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.sendDataToParent();
            primEmpData = this.primaryOwnerEmpCardRef.current && this.primaryOwnerEmpCardRef.current.sendDataToParent();
            primFinData = this.primaryOwnerFinCardRef.current && this.primaryOwnerFinCardRef.current.sendDataToParent();
            primMilData = this.primaryOwnerMilCardRef.current && this.primaryOwnerMilCardRef.current.sendDataToParent();
            beneficiaryData = this.beneficiaryCardRef.current && this.beneficiaryCardRef.current.sendDataToParent();
            jointPerData = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.sendDataToParent();
            toBeSavedData = {
                ...toBeSavedData,
                perInfo: primPerData,
                empInfo: primEmpData,
                finInfo: primFinData,
                milInfo: primMilData,
                benInfo: beneficiaryData,
                jointPerInfo: jointPerData,
                nickName,
                originalFileArray,
                imageFileUploaded,
            };
            const isbeneficiaryDataValid = this.checkForBeneficiaryDataValid(beneficiaryData)
                
            const allIndividualFieldsValid = this.checkFieldValid(primPerData, primEmpData, primFinData, primMilData, isbeneficiaryDataValid)

            const jointDetails = this.isJointValidFunction(accType, toBeSavedData);
            let isAccountOpeningAllowed = true;
            let isJoint = false;

            isJointValid = jointDetails.isJointValid;
            toBeSavedData = jointDetails.toBeSavedData;

            allFieldsValid = isJointValid && allIndividualFieldsValid;
            if (allFieldsValid) {
                this.setState({ disableNext: false });

                /* Validate Physical Address state for Joint Tenants with Right of Survivorship account type */
                if (
                    localStateData.OpenAccPageOne &&
                    localStateData.OpenAccPageOne.accountType === AccGlobalConstants.JOINT_TENANTS_ACCOUNT_TYPE
                ) {
                    isAccountOpeningAllowed = this.validatePhysicalAddress(primPerData, jointPerData);
                    this.setState({ isAccountOpeningAllowed });
                }

                if (localStateData.OpenAccPageOne && localStateData.OpenAccPageOne.accountType.includes('UGMA')) {
                    isAccountOpeningAllowed = this.validateBeneficiaryAge(primPerData, beneficiaryData);
                    const perPhysicalAddressState =
                        primPerData && primPerData.sameAddress === 'Y'
                            ? primPerData.primMailAddress && primPerData.primMailAddress.state
                            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.state;
                    this.setState({ isAccountOpeningAllowed, perPhysicalAddressState });
                }
                this.setState({ showAccountOpeningErrorModal: !isAccountOpeningAllowed });
                /* ---------------------END of Physical Address validation------------------- */
                /* ------------------- Customer verification------------------------ */

                this.checkForValidateCustomerDetails(isAccountOpeningAllowed, primPerData)

                this.validateJointOwnerData(isAccountOpeningAllowed, jointPerData, isJoint)
   
                /* --------------------End of Customer Verification-------------------- */
            } else {
                this.scrollTo([
                    {
                        element: this.primaryOwnerPerCardRef.current,
                        valid: primPerData.valid,
                    },
                    {
                        element: this.primaryOwnerEmpCardRef.current,
                        valid: primEmpData.valid,
                    },
                    {
                        element: this.primaryOwnerFinCardRef.current,
                        valid: primFinData.valid,
                    },
                    {
                        element: this.primaryOwnerMilCardRef.current,
                        valid: primMilData.valid,
                    },
                    {
                        element: this.beneficiaryCardRef.current,
                        valid: isbeneficiaryDataValid,
                    },
                    {
                        element: this.jointOwnerPerCardRef.current,
                        valid: jointPerData && jointPerData.valid,
                    },
                ]);
            }

            this.checkAndSaveToLocalIfAlowed(isAccountOpeningAllowed, toBeSavedData, action, allFieldsValid, accType)

        }
    };

    handleSaveClick = () => {
        this.setState({ showSaveModal: false }, () => {
            this.saveToLocal('save');
        });
    };

    handleNextClick = () => {
        this.saveToLocal('next');
    };

    clearInitialFiles = () => {
        this.setState({ localFile: null, personalInfoUploadPh: undefined, originalFileArray: null });
    };

    handleChange = e => {
        const { errorMsg } = this.state;
        const err = Rules[e.target.name]
            ? checkValidity({
                  rules: Rules[e.target.name],
                  value: e.target.value,
              })
            : '';

        errorMsg[e.target.name.toString()] = err;
        this.setState({ [e.target.name]: e.target.value, errorMsg });
    };

    handleBlur = e => {
        if (e.target.value) {
            this.getCardValues(this.state);
        }
    };

    handleClose = () => {
        this.setState({
            showAlert: false,
            errorMessage: '',
            saveErrorShown: false,
            variantForUpload: '',
            showSaveModal: false,
        });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    redirect = () => {
        const { next, back } = this.state;
        const { localStateData } = this.props;
        let pathName = '';
        const { accountType } = localStateData || {};
        if (next) {
            if (accountType === '529_college') {
                pathName = '/beneficiaryInfoChild';
            } else {
                pathName = '/fundSelection';
            }
        } else if (back) {
            if (accountType === '529_college') {
                pathName = '/application';
            } else {
                pathName = '/accountType';
            }
        }
        return {
            pathname: pathName,
        };
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    handleVerifyCustomerDetailsOk = () => {
        const { setShowCustomerVerificationModal } = this.props;
        setShowCustomerVerificationModal(false);
    };

    handleAccountOpeningAllowed = () => {
        this.setState({ showAccountOpeningErrorModal: false });
    };

    handleBackClick = () => {
        this.setState({ back: true }, () => {
            this.saveToLocal();
        });
    };

    handleDOB = val => {
        this.setState({ primaryDOB: val });
    };

    renderOtherTypeAccounts = () => {
        const { localStateData } = this.props;
        const { accountType } = localStateData || {};
        const savedJointPerInfo =
            accountType &&
            localStateData[accountType.toString()] &&
            localStateData[accountType.toString()].jointPerInfo;
        const savedBeneficiaryData =
            accountType && localStateData[accountType.toString()] && localStateData[accountType.toString()].benInfo;
        return (
            <>
                {localStateData.accountType && localStateData.accountType.toLowerCase().indexOf('joint') > -1 && (
                    <>
                        <JointPersonalInfoCardComponent
                            savedState={savedJointPerInfo}
                            isJoint
                            ref={this.jointOwnerPerCardRef}
                        />
                    </>
                )}
                {localStateData.accIDSelected &&
                    (localStateData.accIDSelected === 'ira' || localStateData.accIDSelected === 'inv_child') && (
                        <BeneficiaryCard
                            ref={this.beneficiaryCardRef}
                            accountSelected={localStateData.accIDSelected}
                            accountType={localStateData.accountType}
                            savedState={savedBeneficiaryData}
                        />
                    )}
            </>
        );
    };

    getKey = (lookupString, lookupValue) => {
        if (lookupValue && lookupValue.toString().trim() !== '') {
            const { masterLookupStateData } = this.props;
            const lookupList =
                masterLookupStateData &&
                masterLookupStateData[lookupString.toString()] &&
                masterLookupStateData[lookupString.toString()].value;
            const lookupData =
                lookupList && lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
            if (lookupData) {
                return lookupData.key;
            }
        }
        return '';
    };

    getSavedFinInfo = financialInformation => {
        let { annualIncome, taxBracket, netWorth, taxFilingStatus } = financialInformation;
        annualIncome = this.getKey('annual_income', annualIncome);
        netWorth = this.getKey('net_worth', netWorth);
        taxFilingStatus = this.getKey('tax_filling_status', taxFilingStatus);
        taxBracket =
            taxBracket &&
            parseFloat(taxBracket)
                .toFixed(2)
                .concat('%');
        return {
            annualIncome,
            taxBracket,
            netWorth,
            taxFilingStatus,
        };
    };

    getSavedEmpInfo = employmentInformation => {
        const {
            employerName,
            addressLine1,
            addressLine2,
            employersCity,
            employersState,
            employersZipcode,
            industryOther,
        } = employmentInformation;
        let { employmentStatus, industry, primarySourceOfIncome } = employmentInformation;
        employmentStatus = this.getKey('employment_status', employmentStatus);
        industry = this.getKey('industry', industry);
        primarySourceOfIncome = this.getKey('prim_src_income', primarySourceOfIncome);
        return {
            empStatus: employmentStatus,
            industry,
            industryDescription: industryOther,
            employersName: employerName,
            employerAddress: {
                addressLine1,
                addressLine2,
                zip: employersZipcode,
                city: employersCity,
                state: employersState,
            },
            primarySourceofIncome: primarySourceOfIncome,
        };
    };

    render() {
        const {
            showSaveModal = false,
            next,
            primaryDOB,
            cancel,
            errorMsg,
            back,
            nickName,
            showAlert,
            errorMessage,
            loading,
            isErrorBar,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
            showAccountOpeningErrorModal,
            perPhysicalAddressState,
        } = this.state;

        const { accountOpeningPages, personalInfoBackLabel } = AccGlobalConstants;
        const { localStateData, accOpeningData, profileInformationData } = this.props;
        const { accountType } = localStateData || {};
        const isUGMA = accountType.includes('UGMA');
        const nonMinorAge = UTMAStateWithAge18ForNonMinor.includes(perPhysicalAddressState) ? 18 : 21;
        const childBeneficiaryErrorMessage = `Child beneficiary for UGMA/UTMA account type cannot be more than or equal to ${nonMinorAge} years of age`;
        const savedPerInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo;
        let savedEmpInfo =
            accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.employementInfo;

        if (!savedEmpInfo && profileInformationData.profileInformation.employmentInformation) {
            const { employmentInformation } = profileInformationData.profileInformation;
            savedEmpInfo = this.getSavedEmpInfo(employmentInformation);
        }

        const savedMilInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.militaryInfo;

        let savedFinInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.financialInfo;

        if (!savedFinInfo && profileInformationData.profileInformation.financialInformation) {
            const { financialInformation } = profileInformationData.profileInformation;
            savedFinInfo = this.getSavedFinInfo(financialInformation);
        }

        return (
            <>
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {loading && <WSpinner loading={loading} />}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                {(accOpeningData.showCustomerVerificationModal ||
                    accOpeningData.showCustomerVerificationModalJoint1 ||
                    accOpeningData.showCustomerVerificationModalJoint2) && (
                    <ConfirmModal
                        modalTitle={AccGlobalConstants.CUSTOMER_VERIFICATION_MODAL_TITLE}
                        modalBodyText={accOpeningData.verifyCustomerDetailsErrMsg}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleVerifyCustomerDetailsOk}
                    />
                )}
                {showAccountOpeningErrorModal && !isUGMA && (
                    <ConfirmModal
                        modalTitle={AccGlobalConstants.ACCOUNT_OPENING_NOT_ALLOWED_TITLE}
                        modalBodyText={AccGlobalConstants.ACCOUNT_OPENING_NOT_ALLOWED_ERROR_MESSAGE}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleAccountOpeningAllowed}
                    />
                )}

                {showAccountOpeningErrorModal && isUGMA && (
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={childBeneficiaryErrorMessage}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleAccountOpeningAllowed}
                    />
                )}
                <div>
                    <div
                        style={assignObj({
                            position: 'relative',
                            color: '#49494A',
                            textAlign: 'left',
                            fontFamily: 'benton-sans',
                        })}
                    >
                        {showAlert ? (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        ) : null}
                        <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        <div className="container">
                            <WBreadCrumb
                                activeCrumb={accountType}
                                breadCrumbItems={
                                    localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                                }
                            />
                            <h1 style={styles.pageHeaderStyle}>{AccGlobalConstants.getPageHeading(accountType)}</h1>
                            <WStepper currentPage={1} pages={accountOpeningPages} />
                            <span style={styles.mandatoryFieldlabel}>{consts.mandatoryText}</span>
                            <div style={assignObj({ marginTop: 60 })} className="personalIfoFormWrapper">
                                <h2 className="personalizeHeader">{consts.person}</h2>
                                <Row>
                                    <WInput
                                        label="Nick Name"
                                        optional="true"
                                        id="nickName"
                                        name="nickName"
                                        type="text"
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        value={nickName}
                                        sublabel={styles.sublabel}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={50}
                                        errortext={errorMsg.nickName}
                                    />
                                </Row>
                                
                                <PersonalInfoCardComponent
                                    sendDOB={this.handleDOB}
                                    savedState={savedPerInfo}
                                    ref={this.primaryOwnerPerCardRef}
                                    checkStatus={this.checkStatus}
                                    personalInfo={profileInformationData}
                                    // W8FileDisable={W8FileDisable}
                                />
                                <EmploymentInfoCard
                                    checkStatus={this.checkStatus}
                                    savedState={savedEmpInfo}
                                    ref={this.primaryOwnerEmpCardRef}
                                />
                                <FinancialInfoCard savedState={savedFinInfo} ref={this.primaryOwnerFinCardRef} />
                                <MilitaryInfoCard
                                    savedState={savedMilInfo}
                                    ref={this.primaryOwnerMilCardRef}
                                    checkStatus={this.checkStatus}
                                    primaryDOB={primaryDOB}
                                />
                                {this.renderOtherTypeAccounts()}
                                {next ? <Redirect to={this.redirect()} /> : ''}
                                {cancel ? <Redirect to="/" /> : ''}
                                {back ? <Redirect to={this.redirect()} /> : ''}
                            </div>
                        </div>
                    </div>
                    <CommonButtons
                        cancelClick={this.handleCancelClick}
                        saveClick={this.handleSaveClick}
                        nextClick={this.handleNextClick}
                        backClick={this.handleBackClick}
                        showSave
                        iscancel
                        cancelText="Cancel"
                        cancelStyle={assignObj(cancelButtonStyles)}
                        arialabelback={personalInfoBackLabel}
                        cancelOffset={assignObj({
                            span: 2,
                            offset: 6,
                        })}
                    />
                </div>
            </>
        );
    }
}

PersonalInfoComponent.propTypes = {
    saveApplication: PropTypes.func,
    uploadAavatarImg: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    accOpeningData: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    getProfileBasicInformations: PropTypes.func,
    setShowCustomerVerificationModal: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCognitoForCustomerVerification: PropTypes.func,
    clearLocalStateForNAOAccTypeChange: PropTypes.func,
};

PersonalInfoComponent.defaultProps = {
    saveApplication: () => {},
    uploadAavatarImg: () => {},
    localStateData: {},
    initialState: {},
    manageLocalState: () => {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    getCustomerProfile: () => {},
    resetShowConfirmModal: () => {},
    history: {},
    profileInformationData: {},
    getProfileBasicInformations: () => {},
    setShowCustomerVerificationModal: () => {},
    masterLookupStateData: {},
    getCognitoForCustomerVerification: () => {},
    clearLocalStateForNAOAccTypeChange: () => {},
};
export default PersonalInfoComponent;
