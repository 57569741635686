import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import {
    getStateCity,
    formatAddrByUSPSService,
    setAddress,
    setAddressLine1,
    setAddressLine2,
    setZipCode,
    setAddressLine2Error,
    setZipCodeError,
    setCallAddressAPI,
    setCityValue,
    setStateValue,
    hideUSPSDialog,
    setAddressReadyStatus,
} from 'shared/Actions/AddressAction';
import useForceUpdate from 'modules/CustomerManagementModule/Hooks';
import { analyzeFormFieldError } from 'shared/Actions/AnalyticsActions';
import AddressFields from '../AddressFields';
import USPSDialog from '../USPSDialog';
import ErrorAlert from '../ErrorAlert';

const AddressBlock = styled.div`
    position: relative;
`;
const EMPTY_ADDRESS_LINE2 = 'Please enter Address Line 2';
const INVALID_ADDRESS_LINE2 = 'Invalid Address LIne 2';
const EMPTY_ZIPCODE = 'Zip code is required';
const INVALID_ZIPCODE = 'Invalid Zip code';
const USPS_ALERT = 'Please click on "USPS Recommendation" address to proceed further';

// Function to return address error upon validation
export const getAddressFieldError = (dispatch, value, optional) => {
    let errorMsg = '';
    const pattern = /^[a-zA-Z0-9 ]*$/;
    if (!value && !optional) {
        errorMsg = EMPTY_ADDRESS_LINE2;
    } else if (!pattern.test(value)) {
        errorMsg = INVALID_ADDRESS_LINE2;
    }
    if(errorMsg.length>0){
       dispatch(analyzeFormFieldError())
    }
    return errorMsg;
};

// Function to return zip error upon validation
export const getZipFieldError = (dispatch, value, optional) => {
    let errorMsg = '';
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (!value && !optional) {
        errorMsg = EMPTY_ZIPCODE;
    } else if (value && !isValidZip) {
        errorMsg = INVALID_ZIPCODE;
    }
    if(errorMsg.length>0){
        dispatch(analyzeFormFieldError())
     }
    return errorMsg;
};

const inputChangeHandlers = {
    'addressLine1': (dispatch, value, addressType)=> dispatch(setAddressLine1(value, addressType)),
    'addressLine2': (dispatch, value, addressType)=>dispatch(setAddressLine2(value, addressType)),
    'zipCode': (dispatch, value, addressType)=>dispatch(setZipCode(value.replace(/[^0-9-]+/g, '',),addressType)),
    'city': (dispatch, value, addressType)=>dispatch(setCityValue(value.replace(/[^a-zA-Z\s]+/g,'').replace(/  +/g, ' '),addressType)),
    'state': (dispatch, value, addressType)=>dispatch(setStateValue(value.replace(/[^a-zA-Z\s]+/g,'').replace(/  +/g, ' '),addressType)),
}
        
export const getInputChangeHandler = (key) => {
    const dummyFn = () => {console.log('dummyFun called')};
    return inputChangeHandlers[`${key}`] || dummyFn;
}

// Setting flag to call Address Format API
const callAddressLookupAPI = (dispatch, addressType) => {
    dispatch(setCallAddressAPI(addressType));
}

// City State APi is called here
const callCityStateLookupAPI = (dispatch, zipPayload, addressType) => {
        dispatch(
            getStateCity({
                zip: zipPayload,
            }, addressType),
        );
}

export const handleAddressLine1Blur = ({ dispatch, value, previousAddrLine1, addressType}) => {
    if (value !== previousAddrLine1) callAddressLookupAPI(dispatch, addressType)
}

export const handleAddressLine2Blur = ({dispatch, value, addressLine2ErrorText, previousAddrLine2, addressType, optional, showUSPSDialog}) => {
    if(!value && showUSPSDialog) dispatch(hideUSPSDialog(addressType));
    const addressFieldError = getAddressFieldError(dispatch, value, optional);
    if(addressLine2ErrorText !== addressFieldError) dispatch(setAddressLine2Error(addressFieldError, addressType));
    if (value && !addressFieldError && previousAddrLine2 !== value) callAddressLookupAPI(dispatch, addressType);
}

export const handleZipCodeBlur = ({dispatch, value, zipError, previousZip,previousCity, previoutState, addressType, optional, showUSPSDialog}) => {
    if(!value && showUSPSDialog) dispatch(hideUSPSDialog(addressType));
    const zipFieldError = getZipFieldError(dispatch, value, optional);
    if(zipError !== zipFieldError) dispatch(setZipCodeError(zipFieldError, addressType));
    if (value && !zipFieldError && value !== previousZip) callCityStateLookupAPI(dispatch, value, addressType);
    if(value && value === previousZip){
        dispatch(setCityValue(previousCity, addressType));
        dispatch(setStateValue(previoutState, addressType));
    }
    
}

export const inputBlurHandlers = {
    'addressLine1': handleAddressLine1Blur,
    'addressLine2': handleAddressLine2Blur,
    'zipCode': handleZipCodeBlur,
}
        
export const getInputBlurHandler = (key) => {
    const dummyFn = () => {console.log('dummyFun called')};
    return inputBlurHandlers[`${key}`] || dummyFn;
}

const Address = (props) => {
    const { optional, addressType, groupName } = props;
    
    const dispatch = useDispatch();
    
    const parentEl = useRef(null);
    // For Smaller device the alignment changes
    const { current } = parentEl;
    const { offsetWidth : parentWidth=0 } = current || {};
    const isSmallDevice = parentWidth < 768;
    
    const forceUpdate = useForceUpdate();
    
    React.useEffect(()=>{
        if(!current) forceUpdate();
    },[current, forceUpdate])

    const addressInfo = useSelector(({ [addressType]: addressData = {} }) => addressData);
    const { 
        addressInformation, 
        showUSPSDialog, 
        USPSVerifiedAddress, 
        callAddressAPI, 
        previousZip, 
        previousAddrLine1,
        previousAddrLine2,
        previousCity,
        previoutState,
        isAddressReady
    } = addressInfo;
    
    const {
        addressLine1= '',
        addressLine2= '',
        zip= '',
        city= '',
        state= '',
        addressLine2ErrorText= '',
        zipError= '', } =
        addressInformation;
        
    React.useEffect(()=>{
        if(optional && !isAddressReady && !addressLine1 && !addressLine2 && !zip && !city && !state){
            dispatch(setAddressReadyStatus(true, addressType));
        }    
    },[dispatch, optional, isAddressReady, addressLine1, addressLine2, zip, city, state, addressType]);
        
    const {
        addressLine1: formattedAddrLine1= '-',
        addressLine2: formattedAddrLine2= '',
        zip: formattedZip= '',
        city:formattedCity= '',
        state:formattedState= '', } =
        USPSVerifiedAddress;
    
    const addressLineOne = `${formattedAddrLine1}, ${formattedAddrLine2}, `.replace('-, ', '');
    const addressLineTwo = `${formattedCity}, ${formattedState} - ${formattedZip}`;
    
    // Address Format API is called here
    React.useEffect(()=>{
        if (addressLine2 && city && state && zip && callAddressAPI) {
            dispatch(
                formatAddrByUSPSService({
                    addressLine1,
                    addressLine2,
                    zip,
                    city,
                    state,
                }, addressType),
            );
        }
    },[ dispatch, addressLine1, addressLine2, city, state, zip, callAddressAPI, addressType])
    

    // Handle input change
    const onInputChange = React.useCallback((fieldName) => (e) => {
        const { target } = e;
        const { value = '' } = target;
        const handleEvent = getInputChangeHandler(fieldName);
        handleEvent(dispatch, value, addressType);
    },[dispatch, addressType])

    // Handle input blur
    const onInputBlur = React.useCallback((fieldName) => (e) => {
        const { target } = e;
        const { value = '' } = target;
        
        const handleEvent = getInputBlurHandler(fieldName);
        handleEvent({
            dispatch,
            value,
            previousAddrLine1,
            previousAddrLine2,
            addressLine2ErrorText,
            zipError,
            previousZip,
            addressType,
            optional,
            showUSPSDialog,
            previoutState,
            previousCity,
        });
    }, [dispatch, optional, previousAddrLine1, previousAddrLine2, previoutState, previousCity, addressLine2ErrorText, zipError, previousZip, addressType, showUSPSDialog])
    
    const setUSPSRecommendedValue = React.useCallback(() => {
        dispatch(setAddress(USPSVerifiedAddress, addressType));
    },[dispatch,USPSVerifiedAddress, addressType])

    return (
        <div ref={parentEl}>
            {showUSPSDialog && <ErrorAlert mB={3.75} mT={3.75} alertMsg={USPS_ALERT} />}
            <AddressBlock>
                <AddressFields
                    addressType={addressType}
                    groupName={groupName}
                    addressLine1={addressLine1}
                    addressLine2={addressLine2}
                    zipCode={zip}
                    city={city}
                    state={state}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    addressLine2ErrorText={addressLine2ErrorText}
                    zipCodeErrorText={zipError}
                    {...props}
                />
                {showUSPSDialog && (
                    <USPSDialog isSmallDevice={isSmallDevice} addressLineOne={addressLineOne} addressLineTwo={addressLineTwo} onVerify={setUSPSRecommendedValue} />
                )}
            </AddressBlock>
        </div>
    );
};

Address.propTypes = {
    optional: PropTypes.bool,
    addressType: PropTypes.string,
    groupName: PropTypes.string
};

Address.defaultProps = {
    optional: false,
    addressType: 'address',
    groupName: ''
};

export default Address;
