/**
 * Application Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component is for displaying Application in 529 flow
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { WStepper, WSpinner, WSaveModal, WErrorModal, WButton, CommonButtons } from '../../../common';
import Consts from './consts';

const PageWrapper = styled.div`
    background-color: #f9fbfc;
    min-height: 100vh;
    position: relative;
    padding: 60px 10px;
`;

const PageHeader = styled.h2`
    padding-left: 90px;
    padding-top: 30px;
    font-weight: Bold;
    font-family: Benton Sans;
    font-size: 26px/22px;
    color: #56565a;
`;

const ContentWrapper = styled.div`
    height: 60px;
    padding-right: 67px;
    padding-left: 100px;
    padding-top: 10px;
`;

const CardSection = styled.div`
    margin: 50px 0 10px;
    padding-bottom: 100px;
`;

const CardRow = styled(Row)`
    margin-right: 0;
`;

const CardWrapper = styled.div`
    position: relative;
    padding: 60px 80px 0px 100px;
`;

const SideHeading = styled.h6`
    font: Bold 22px/35px Roboto Slab;
    color: #56565a;
    text-align: left;
`;

const Hint = styled.span`
    text-align: left;
    font: 400 18px/35px Roboto Slab;
    letter-spacing: 0;
    color: #56565a;
`;

const CardFooter = styled.p`
    font: 700 14px/17px Benton Sans;
    color: #333333de;
    padding-bottom: 30px;
    margin-top: 30px;
`;

const Application1 = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const AgreeContent = styled.label`
    font: 300 14px/17px Benton Sans;
    color: #333333de;
    opacity: 0.75;
    letter-spacing: 0;
    width: 90%;
`;

const HorizontalRule = styled.hr`
    margin-top: 0px;
    border: 1px solid #707070;
    opacity: 0.25;
`;

const Questions = styled.div`
    background: #eceeef 0% 0% no-repeat padding-box;
    border: 1px solid #cfd1d2;
`;

const QuestionsContent = styled.div`
    padding-left: 13px;
    padding-top: 15px;
`;

const Question = styled.div`
    paddding-top: 20px;
`;

const Question1 = styled.p`
    margin: 0;
    padding-bottom: 12px;
    font: 700 14px/17px Benton Sans;
    color: #333333de;
`;

const Answer = styled.p`
    width: 70%;
    font: 300 14px/17px Benton Sans;
    opacity: 0.75;
    color: #333333de;
`;

const Part1Buttons = styled(Row)`
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
`;

const Part2Buttons = styled.div`
    margin-top: 60px;
`;

export default class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
            cancel: false,
            error: false,
            showSaveModal: false,
            loading: false,
            part1: true,
            pageHeading: '529 Account',
        };
    }

    componentDidMount = () => {
        const { localStateData } = this.props;
        if (localStateData.esaInfo) {
            this.updateState(localStateData.esaInfo.pageName);
        }
    };

    updateState = page => {
        if (page === 'application1') {
            this.setState({ part1: true });
        } else if (page === 'application2') {
            this.setState({ part1: false });
        }
    };

    handleNextClick = () => {
        this.setState({ next: true });
    };

    handleSave = () => {
        this.setState({ showSaveModal: true });
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleSubmit = () => {
        this.setState({ submit: true });
    };

    redirect = () => {
        return {
            pathname: '/personalInfochild',
            state: {
                accountType: '529',
            },
        };
    };

    part2Buttons = () => {
        return (
            <Part2Buttons>
                <CommonButtons
                    nextClick={this.handleNext}
                    saveClick={this.handleSave}
                    backClick={this.handleBack}
                    cancelClick={this.handleCancel}
                    submit="true"
                    submitClick={this.handleSubmit}
                    showSave
                />
            </Part2Buttons>
        );
    };

    part1Buttons = () => {
        return (
            <Part1Buttons>
                <Col xs={2}>
                    <WButton buttontext="Get Started" onClick={this.handleNextClick} buttonstyle={Consts.buttonStyle} />
                </Col>
            </Part1Buttons>
        );
    };

    renderDoc = () => {
        const { part1 } = this.state;
        return (
            <Questions>
                <QuestionsContent>
                    <Question>
                        <Question1>{Consts.q1}</Question1>
                        <Answer>{Consts.ans1}</Answer>
                    </Question>
                    <Question>
                        <Question1>{Consts.q2}</Question1>
                        <Answer>{part1 ? Consts.ans2 : Consts.part2ans}</Answer>
                    </Question>
                    <Question>
                        <Question1>{Consts.q3}</Question1>
                        <Answer>{Consts.ans3}</Answer>
                    </Question>
                </QuestionsContent>
            </Questions>
        );
    };

    renderCard = ({ cardHeading, hint }, index) => {
        const { part1 } = this.state;
        return (
            <CardWrapper key={index}>
                <CardRow>
                    <Col md={7}>
                        <SideHeading>
                            {cardHeading}
                            <Hint>{hint}</Hint>
                        </SideHeading>
                    </Col>
                </CardRow>
                <HorizontalRule />
                {this.renderDoc()}
                {part1 && (
                    <Application1>
                        <AgreeContent>{Consts.agreeText}</AgreeContent>
                    </Application1>
                )}
                <div>
                    <CardFooter>{Consts.considertext}</CardFooter>
                </div>
                {part1 && <HorizontalRule />}
                {part1 ? this.part1Buttons() : this.part2Buttons()}
            </CardWrapper>
        );
    };

    renderPage = (pageHeading, card) => {
        const { part1, showSaveModal, error } = this.state;
        const currentPage = part1 ? 2 : 5;
        return (
            <>
                <PageWrapper>
                    <PageHeader>{pageHeading}</PageHeader>
                    <ContentWrapper className="container">
                        <WStepper style={Consts.stepperStyle} currentPage={currentPage} pages={Consts.pages} />
                    </ContentWrapper>
                    <WErrorModal show={error} message="Technical error" />
                    <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                    <CardSection>
                        <div>{this.renderCard(card)}</div>
                    </CardSection>
                </PageWrapper>
                <HorizontalRule />
            </>
        );
    };

    render() {
        const { pageHeading, loading, error, part1, showSaveModal, next, cancel, back, submit } = this.state;
        const card = part1 ? Consts.app1Card : Consts.app2Card;
        return (
            <div className="MainContainer">
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                {error && <WErrorModal show={error} message="Technical error" />}
                {<WSpinner loading={loading} />}
                {!error && this.renderPage(pageHeading, card)}
                {next && <Redirect to={this.redirect()} />}
                {cancel && <Redirect to="/" />}
                {back && <Redirect to="/beneficiaryInfoChild" />}
                {submit && <Redirect to="/" />}
            </div>
        );
    }
}

Application.propTypes = {
    localStateData: PropTypes.instanceOf(Object),
    location: PropTypes.shape({
        state: PropTypes.shape({
            page: PropTypes.string,
        }),
    }),
};

Application.defaultProps = {
    localStateData: {},
    location: {
        page: {
            state: 'application1',
        },
    },
};
