export const GET_FUND_ACCOUNTS_LIST = 'GET_FUND_ACCOUNTS_LIST';

export const GET_FUND_ACCOUNTS_LIST_START = 'GET_FUND_ACCOUNTS_LIST_START';
export const GET_FUND_ACCOUNTS_LIST_SUCCESS = 'GET_FUND_ACCOUNTS_SUCCESS';
export const GET_FUND_ACCOUNTS_LIST_FAILURE = 'GET_FUND_ACCOUNTS_FAILURE';

export const GET_EXISTING_FUND_ACCOUNTS_LIST = 'GET_EXISTING_FUND_ACCOUNTS_LIST';
export const GET_EXCHANGE_FUND_ACCOUNTS_LIST = 'GET_EXCHANGE_FUND_ACCOUNTS_LIST';
export const GET_EXCHANGE_IN_FUND_ACCOUNTS_LIST = 'GET_EXCHANGE_IN_FUND_ACCOUNTS_LIST';

export const SORT_FUND_ACCOUNTS_LIST_SUCCESS = 'SORT_FUND_ACCOUNTS_LIST_SUCCESS';
export const SORT_FUND_ACCOUNTS_LIST_FAILURE = 'SORT_FUND_ACCOUNTS_LIST_FAILURE';

export const GET_FUND_ACCOUNTS_LIST_FOR_NAO = 'GET_FUND_ACCOUNTS_LIST_FOR_NAO';

export const GET_FUND_ACCOUNTS_LIST_FOR_NAO_START = 'GET_FUND_ACCOUNTS_LIST_FOR_NAO_START';
export const GET_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS = 'GET_FUND_ACCOUNTS_FOR_NAO_SUCCESS';
export const GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE = 'GET_FUND_ACCOUNTS_FOR_NAO_FAILURE';

export const GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS = 'GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS';
export const GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR = 'GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR';

export const GET_AIP_FUND_PLAN_DETAILS = 'GET_AIP_FUND_PLAN_DETAILS';
export const GET_SWP_FUND_PLAN_DETAILS = 'GET_SWP_FUND_PLAN_DETAILS';
export const GET_FUND_PLAN_DETAILS_SUCCESS = 'GET_FUND_PLAN_DETAILS_SUCCESS';
export const GET_FUND_PLAN_DETAILS_FAILURE = 'GET_FUND_PLAN_DETAILS_FAILURE';
export const DISMISS_GET_FUND_PLAN_DETAILS_FAILURE = 'DISMISS_GET_FUND_PLAN_DETAILS_FAILURE';
export const GET_FUND_PLAN_DETAILS_RESET = 'GET_FUND_PLAN_DETAILS_RESET';
