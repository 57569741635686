const data = {
    agreeText: 'By clicking "Sign In" I agree to the Victory Capital ',
    termsOfUse: 'Terms of Use',
    errorText: 'Invalid Token',
    header: 'Enter Soft Token',
    styles: {
        onlineIdStyle: {
            font: '500 16px/20px benton-sans',
            color: '#49494A',
            margin: '5px 0 0 0'
        },
        header: {
            font: "800 30px/36px yorkten-slab-normal",
            marginBottom: '30px',
            color: "#486D90",
            display: 'inline-block',
            borderBottom: "2px solid #8CBF26",
            paddingBottom: "10px"
        },
        para: {
            margin: "0px 0 5px 0",
            font: "500 14px/20px benton-sans",
            color: '#56565A'
        },
        termsOfUse: {
            marginBottom: 30,
            marginTop: 4,
            lineHeight: 0
        },
        main: {
            maxWidth: '445px'
        },
        strong: {
            font: "600 14px/17px benton-sans",
            textDecoration: 'underline',
            color: '#004A98',
            border: 'none',
            backgroundColor: 'transparent',
            padding: 0
        }
    },
    onlineIdLabel: 'Online ID',
    onlineId: 'VCM123',
    config: {
        type: 'password',
        label: 'Soft Token',
        placeholder: ''
    },
}

export default data;