import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import {loginActions} from '../../../shared/Actions';
import SignInBySoftTokenFormComponent from './SignInBySoftTokenForm';

// console.log(loginActions)

// const mapStateToProps = (state /* , props */) => (
//     {
//       //count: getCount(state)
//     }
//   );


const mapDispatchToProps = {
     ...loginActions
  };


export default withRouter(connect(
    null,
    mapDispatchToProps
  )(SignInBySoftTokenFormComponent));