import {
  RESOLVED_EMAIL_OTP,
  REJECTED_EMAIL_OTP,
  RESOLVED_VALIDATE_EMAIL_OTP,
  REJECTED_VALIDATE_EMAIL_OTP,
  SET_PHONE_NUMBER,
  SET_PHONE_CTRY_CODE,
  SET_PHONE_IS_MOBILE_PROP,
  SET_RESET_OTP_SUCCESS_FLG,
  SET_UPDATED_EMAIL,
  SET_EMAIL_ERROR
} from 'shared/ReduxConstants/AccountRegistrationConstants';
import {
  REQUEST_EMAIL_OTP,
  VERIFY_EMAIL_OTP
} from 'shared/ReduxConstants/ServiceActionConstants';
import { dispatchLoading, dispatchWithData } from '../../utils.actions';
import accountRegistrationAPICall from '../../service/AccountRegistrationService';




import { errorCodeMessageMapping } from '../../components/constants';

const errorRequestEmailOTP = (result, data) => (dispatch) => {

  let payLoadData = {};
  let errorMsg = errorCodeMessageMapping[result.errorCode] || result.result;
  if (result.errorCode === 2004) {
    errorMsg = 'Please try generating new Security code after an hour.';
  } else if (result.errorCode === 2008) {
    errorMsg = 'Please generate a new Security code.';
  }
  if (data.origin === 'resend') {
    payLoadData = {
      emailOTPError: errorMsg,
      receivedEmailOTP: true,
      emailError: '',
    };
  } else {
    payLoadData = {
      emailOTPError: '',
      emailError: errorMsg,
      receivedEmailOTP: false,
    };
  }
  dispatchWithData(REJECTED_EMAIL_OTP, {
    ...data,
    ...payLoadData,
  })(dispatch);
}

export const requestEmailOTP = (data) => (dispatch) => {
  if (data === '') return;
  dispatchLoading('SSNAuth')(dispatch);
  accountRegistrationAPICall(
    REQUEST_EMAIL_OTP,
    data,
    (response) => {
      const result = response.data;
      let payLoadData = {};
      if (result) {
        if (result.errorCode === 0) {
          dispatchWithData(RESOLVED_EMAIL_OTP, {
            ...data,
            receivedEmailOTP: true,
            emailError: '',
            emailOTPError: '',
            ...payLoadData,
          })(dispatch);
        } else {
          errorRequestEmailOTP(result, data)(dispatch);
        }
      } else {
        payLoadData = {
          emailOTPError: '',
          emailError: errorCodeMessageMapping[2006],
          receivedEmailOTP: false,
        };
        dispatchWithData(REJECTED_EMAIL_OTP, {
          ...data,
          ...payLoadData,
        })(dispatch);
      }
    },
    (error) => {
      if (error.data) {
        dispatchWithData(REJECTED_EMAIL_OTP, {
          ...data,
          emailOTPError: '',
          emailError: error.data.result,
          receivedEmailOTP: false,
        })(dispatch);
      }
    },
  );
};

const errorValidateEmailOTP = (response) => (dispatch) => {

  let errorMsg = 'Incorrect Security code, please try again or request new Security code';
  if (
    response.data.errorCode === 2027
    || response.data.errorCode === 2008
  ) {
    errorMsg = 'Please generate a new Security code.';
  } else if (response.data.errorCode !== 2028) {
    errorMsg = response.data
      ? errorCodeMessageMapping[response.data.errorCode]
        || response.data.result
      : 'Error Occured while validating Email Security Code';
  }
  dispatchWithData(REJECTED_VALIDATE_EMAIL_OTP, {
    emailOTPError: errorMsg,
    isEmailValidated: false,
    validateEmailErrorCode: response.data.errorCode,
  })(dispatch);
}

export const validateEmailOTP = (data) => (dispatch) => {
  if (data === '') return;
  dispatchLoading('SSNAuth')(dispatch);

  accountRegistrationAPICall(
    VERIFY_EMAIL_OTP,
    data,
    (response) => {
      if (
        response.data
        && response.data.errorCode === 0
        && response.data.message === 'Success'
      ) {
        dispatchWithData(RESOLVED_VALIDATE_EMAIL_OTP, {
          ...data,
          emailOTPError: '',
          isEmailValidated: true,
          receivedEmailOTP: false,
          validateEmailErrorCode: '',
        })(dispatch);
      } else {
        errorValidateEmailOTP(response)(dispatch);
      }
    },
    (error) => {
      const errorMsg = error.data
        ? error.data.result
        : 'Error Occured while validating Email Security Code';
      dispatchWithData(REJECTED_VALIDATE_EMAIL_OTP, {
        emailOTPError: errorMsg,
        isEmailValidated: false,
        validateEmailErrorCode: '',
      })(dispatch);
    },
  );


};

export const savePhoneNumber = (data) => (dispatch) => {
  let payload = {};
  switch (data.type) {
  case 'primaryphone':
    payload = {
      primaryPhone: data.phoneNumber,
    };
    break;
  case 'secondaryphone':
    payload = {
      secondaryPhone: data.phoneNumber,
    };
    break;
  default:
    break;
  }
  dispatchWithData(SET_PHONE_NUMBER, payload)(dispatch);
};

export const saveCountryCode = (data) => (dispatch) => {
  let payload = {};
  switch (data.type) {
  case 'primaryphone':
    payload = {
      primaryPhoneCountryCode: data.countryCode,
    };
    break;
  case 'secondaryphone':
    payload = {
      secondaryPhoneCountryCode: data.countryCode,
    };
    break;
  default:
    break;
  }
  dispatchWithData(SET_PHONE_CTRY_CODE, payload)(dispatch);
};

export const saveMobileOptSelection = (data) => (dispatch) => {
  let payload = {};
  switch (data.type) {
  case 'primaryphone':
    payload = {
      isMobilePrimary: data.mobileOpt,
    };
    break;
  case 'secondaryphone':
    payload = {
      isMobileSecondary: data.mobileOpt,
    };
    break;
  default:
    break;
  }
  dispatchWithData(SET_PHONE_IS_MOBILE_PROP, payload)(dispatch);
};

export const resetFlagsForListingPage = () => (dispatch) => {
  dispatchWithData(SET_RESET_OTP_SUCCESS_FLG, {
    OTPSuccess: '',
  })(dispatch);
};

export const setUpdatedEmail = (data) => (dispatch) => {
  dispatchWithData(SET_UPDATED_EMAIL, {
    updatedemail: data,
    emailError: '',
  })(dispatch);
};
export const setEmailError = (data) => (dispatch) => {
  dispatchWithData(SET_EMAIL_ERROR, {
    emailError: data,
  })(dispatch);
};
