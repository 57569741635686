import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import constants from 'modules/AccountOptions/constant';
import { WRadio } from 'common';

const RadioGroup = styled.div`
    input:focus+label::before {
        outline: none !important;
    }
    display: flex;
    flex-direction: row;
    line-height: 1;
    overflow: hidden;
    width: 65%;
`;

const radiostyles = {
    minHeight: 0,
    marginLeft: '5px'
};
const DividendSwitchComponent = props => {
    const { onOptionChange, optionSelected, radio1Id, radio2Id, radio3Id,index, isDividend, nameValue, investAnotherFundId,itemData } = props;
    const { reinvest: REINVEST, income: INCOME, investAnotherFund: ANOTHERFUND } = constants.dividends.options;
  

    let tempData = itemData;
    tempData[0].id = radio1Id;
    tempData[1].id = radio2Id;

    return (
        <>
            <RadioGroup role="radiogroup">
                {/* DFW-2894 Updated key value from capitalgains to reinvest in itemdata */}
                {itemData.map(field => (                 
                    <WRadio
                        key={field.key}
                        id={field.id}
                        radioFor={nameValue}
                        value={field.value}
                        onClick={onOptionChange(field.id, field.key, index, isDividend)}
                        radiostyles={radiostyles}
                        selected={investAnotherFundId === radio3Id ? false : optionSelected === field.key}
                        className='analytics-form-fields'
                    />
                ))}
                <WRadio
                        key={ANOTHERFUND}
                        id={radio3Id}
                        radioFor={nameValue}
                        value={constants.dividends.investAnotherfundText}
                        onClick={onOptionChange(radio3Id, ANOTHERFUND, index, isDividend)}
                        radiostyles={radiostyles}
                        selected={investAnotherFundId === radio3Id || optionSelected === ANOTHERFUND}
                        className='analytics-form-fields'
                    />
            </RadioGroup>
        </>
    )
}
DividendSwitchComponent.propTypes = {
    radio1Id: PropTypes.string,
    radio2Id: PropTypes.string,
    radio3Id: PropTypes.string,
    index: PropTypes.number,
    onOptionChange: PropTypes.func,
    optionSelected: PropTypes.string,
    isDividend: PropTypes.bool,
    nameValue: PropTypes.string,
    investAnotherFundId: PropTypes.string
};
DividendSwitchComponent.defaultProps = {
    index: null,
    onOptionChange: () => {},
    optionSelected: '',
    radio1Id: 'vcm-reinvest',
    radio2Id: 'vcm-income',
    radio3Id: 'vcm-invest-another-fund',
    isDividend: false,
    nameValue: '',
    investAnotherFundId: ''
};
export default DividendSwitchComponent;