import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { WBreadCrumb, WSpinner } from 'common';
import {
    formatPhoneNumberWithoutCountryCode,
    replaceSpecialChar,
    getPhoneNumberMaxLength,
    getRemoveSpecialChars,
} from 'utils';
import routeConstants from 'routeConstants';
import CONST from 'modules/CustomerManagementModule/PhoneInformation/constants';
import {
    PhoneNumber,
    BottomNavBar,
    PageTitle,
    FieldValue,
    Toggle,
    InstructionalPanel,
} from 'modules/CustomerManagementModule/components';
import {
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;

export default class AddPhoneInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        const { location } = this.props;
        const { state } = location;
        const { phoneObj } = state || {};
        const { isMobile = false } = phoneObj || {};
        this.state = {
            fields: {
                isMobile,
            },
            disabled: false,
        };
        this.phoneNumberRef = React.createRef();
    }

    componentDidMount() {
        const { isEdit } = this.props;
        addFormAbandonUnloadListner(isEdit);
    }

    componentDidUpdate() {
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, linkResponseError, analyzeClickEvent, isEdit } = this.props;
        const { isError, isSuccess, message } = profileInformationData;

        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_managephonenumber_${isEdit?'edit':'add'}phoneNumber_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_managephonenumber_${isEdit?'edit':'add'}phoneNumber_save`, analyzeClickEvent);
            history.push(routeConstants.phoneInfo);
        }
    }

    componentWillUnmount() {
        const { profileInformationData, isEdit } = this.props;
        const { isSuccess } = profileInformationData;
        if (!isSuccess) {
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    savePhoneInfo = e => {
        e.preventDefault();
        const { location, isEdit } = this.props;
        const { state } = location;
        const { phoneObj } = state || {};
        const { fields } = this.state;
        const { phoneNumber, countryCode } = this.phoneNumberRef.current;
        const phoneData = {
            phoneNumber: replaceSpecialChar(phoneNumber),
            phoneCountryCode: countryCode,
            isMobile: fields.isMobile,
            isPrimary: isEdit ? phoneObj.isPrimary : false,
        };

        const { updateProfilePhoneInformations } = this.props;
        const payload = {
            phoneUpdate: [{ ...phoneData }],
        };
        updateProfilePhoneInformations(payload);
    };

    onIsMobileChange = value => {
        const { location, isEdit } = this.props;
        const { state } = location;
        const { phoneObj } = state || {};
        const { isMobile = false } = phoneObj || {};
        const { disabled } = this.phoneNumberRef.current;
        this.setState(prevState => {
            const { fields } = prevState;
            fields.isMobile = value;
            let newDisabled = disabled;
            if (isEdit && disabled && isMobile !== value) {
                newDisabled = false;
            }
            return {
                fields,
                disabled: newDisabled,
            };
        });
    };

    cancelPhoneInfo = () => {
        const { history, analyzeClickEvent, isEdit } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_${isEdit?'edit':'add'}PhoneNumber_cancel`
        })
        history.push(routeConstants.phoneInfo);
    };

    getDisabledStatus = disabled => {
        const { location, isEdit } = this.props;
        const { state } = location;
        const { phoneObj } = state || {};
        const { isMobile: oldIsMobile } = phoneObj || {};

        const { fields } = this.state;
        const { isMobile: newIsMobile } = fields;

        let newdisabled = disabled;
        if (isEdit && disabled && oldIsMobile !== newIsMobile) {
            newdisabled = false;
        }
        this.setState({ disabled: newdisabled });
    };

    render() {
        const { profileInformationData, location, isEdit, analyzeFormFieldError } = this.props;
        const { disabled } = this.state;
        const { isLoading: loading } = profileInformationData;
        const { state } = location;
        const { phoneObj } = state || {};
        const { isPrimary = false, phoneNumber, isMobile, phoneCountryCode } = phoneObj || {};
        const pageName = isEdit ? CONST.PAGE_NAME_PHONE_EDIT : CONST.PAGE_NAME_PHONE_ADD;

        const cleanedCountryCode = getRemoveSpecialChars(phoneCountryCode, true);
        const cleanedPhoneNumber = getRemoveSpecialChars(phoneNumber);
        const initialPhoneNumber =
            cleanedCountryCode === 1 && phoneNumber.length === 10
                ? formatPhoneNumberWithoutCountryCode(phoneNumber) || ''
                : cleanedPhoneNumber;
        const initialMaxLength = getPhoneNumberMaxLength(phoneCountryCode, true);

        primaryBtnProps.onClick = this.savePhoneInfo;
        primaryBtnProps.disabled = disabled;
        cancelBtnProps.onClick = this.cancelPhoneInfo;

        return (
            <div>
                {loading && <WSpinner loading={loading} />}
                <Container>
                    <WBreadCrumb breadCrumbItems={isEdit?CONST.BREADCRUM_ITEMS_EDIT:CONST.BREADCRUM_ITEMS_ADD} activeCrumb={pageName} />
                    <PageTitle title={pageName} showMandatoryTxt />
                    <FieldValue
                        as="p"
                        id="vcm-cms-member-phone-number"
                        label={CONST.PHONE_NUMBER}
                        value={
                            <PhoneNumber
                                ref={this.phoneNumberRef}
                                labelledBy="vcm-cms-member-phone-number"
                                checkStatusCB={this.getDisabledStatus}
                                analyzeFormFieldError={analyzeFormFieldError}
                                initialCountryCode={phoneCountryCode}
                                initialPhoneNumber={initialPhoneNumber}
                                initialMaxLength={initialMaxLength}
                                data-name={CONST.PHONE_NUMBER}
                            />
                        }
                        mb={2}
                        labelsm={3}
                        valuesm={6}
                    />
                    <FieldValue
                        id="vcm-cms-phone-is-mobile"
                        label={CONST.PHONE_IS_MOBILE}
                        value={
                            <Toggle
                                id="vcm-cms-member-phone-is-mobile"
                                aria-labelledby="vcm-cms-phone-is-mobile"
                                toggleOn={isMobile}
                                onChange={this.onIsMobileChange}
                                data-name={CONST.PHONE_IS_MOBILE}
                                data-form-field
                            />
                        }
                        mb={2}
                        labelsm={3}
                        valuesm={5}
                    />
                    <InstructionalPanel>
                        {isPrimary ? CONST.PHONE_NOTE_PRIMARY : CONST.PHONE_NOTE_SECONDARY}
                    </InstructionalPanel>
                </Container>
                <BottomNavBar
                    hideBack
                    showCancelBtn
                    primaryBtnText={CONST.SAVE}
                    primaryBtnProps={primaryBtnProps}
                    cancelBtnProps={cancelBtnProps}
                    hidePrimaryBtn = {hideFlag}
                />
            </div>
        );
    }
}

AddPhoneInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    isEdit: PropTypes.bool,
    profileInformationData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    updateProfilePhoneInformations: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
    linkResponseError: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
};

AddPhoneInfoComponent.defaultProps = {
    isEdit: false,
    profileInformationData: {},
    clearExistingActions: () => {},
    updateProfilePhoneInformations: () => {},
    analyzeFormFieldError: () => {},
    linkResponseError: () => {},
    analyzeClickEvent: () => {},
};
