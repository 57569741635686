const data = {
    optionalMessage: 'All fields are required unless mentioned optional',
    title: 'Enter your Personal details / Member ID Verification',
    verifyLabel: 'Do you have USAA or Victory Member ID?',
};
const style = {
    radioText: {font: '600 14px/20px benton-sans , sans-serif'},
    optionalMessage: {font: '500 10px/12px benton-sans, sans-serif', color: '#49494A'},
    formGroup: {display: 'flex', paddingBottom: 0, marginBottom: 0, marginleft: '-20px', width: '97.5%'},
};

const assignObj = obj => obj;
const consts = {
    member: 'Member',
    prospect:'Prospect',
    verifiedProspect: 'Verified Prospect',
    otpIvrSelectYes: 'yes',
    otpIvrSelectNo: 'no',

}
const moreSignUpAtmts = 'You are not a registered member, would you like to sign up as a guest?';
const idDoesNotAuth = 'We are not able to authenticate you, please contact a Member Service Representative (800) 235-8396 if you need further assistance';
export { data, style, assignObj, consts, moreSignUpAtmts, idDoesNotAuth };