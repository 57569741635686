import { cloneDeep } from 'lodash';
import makeApiRequest from '../Network/ApiInterface';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import masterDataConstants from '../ReduxConstants/MasterData';

export const selectAccount = acc => dispatch => {
    dispatch({
        type: ActionTypes.SELECT_ACCT,
        ...acc,
    });
};

export const selectAccTypes = accType => dispatch => {
    dispatch({
        type: ActionTypes.SELECT_ACCT_TYPE,
        ...accType,
    });
};

// POST

// only for mobile native enviornment
const getAccountTypesCompositeData = payload => dispatch => {
    const env = 'MOCK';
    for (let i = 0; i < payload.length; i += 1) {
        const resultKey = payload[+i];
        makeApiRequest(
            ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA,
            { datakey: resultKey },
            env,
            response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_SUCCESS,
                        [resultKey]: response.data.masterdatalist,
                        key: resultKey,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_FAILURE,
                        [resultKey]: response,
                        key: resultKey,
                    });
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_FAILURE,
                    [resultKey]: error.data,
                });
            },
        );
    }
};

// only for mobile native enviornment
export const getAccountTypes = payload => dispatch => {
    const env = 'MOCK';

    if (payload.length > 0) {
        dispatch({
            type: ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_LOADER,
        });
    }

    for (let i = 0; i < payload.length; i += 1) {
        const resultKey = payload[+i];

        makeApiRequest(
            ActionTypes.GET_COMPOSITE_DATA,
            { datakey: resultKey },
            env,
            response => {
                if (response.data) {
                    const tempData = {
                        value: response.data.masterdatalist.value,
                    };

                    if (response.data.masterdatalist && response.data.masterdatalist.value) {
                        const mainTypesArray = response.data.masterdatalist.value;
                        const mainTypesKeys = mainTypesArray.map(a => a.key);
                        getAccountTypesCompositeData(mainTypesKeys)(dispatch);
                    }
                    dispatch({
                        type: ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_SUCCESS,
                        [resultKey]: tempData,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_COMPOSITE_DATA_FAILURE,
                        [resultKey]: response,
                    });
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_COMPOSITE_DATA_FAILURE,
                    response: error.data,
                });
            },
        );
    }
};

export const getCompositeLookUpData = payload => dispatch => {
    const env = 'MOCK';
    if (payload.length > 0) {
        dispatch({
            type: ActionTypes.GET_COMPOSITE_DATA_LOADER,
        });
    }

    for (let i = 0; i < payload.length; i += 1) {
        const resultKey = payload[+i];
        // eslint-disable-next-line
        const defaultConstants = masterDataConstants[resultKey];

        if (!defaultConstants) {
            makeApiRequest(
                ActionTypes.GET_COMPOSITE_DATA,
                { datakey: resultKey },
                env,
                response => {
                    if (response.data) {
                        const tempData = {
                            // value: response.data.masterdatalist.value,
                            value: response.data[0].datavalue.value,
                        };
                        dispatch({
                            type: ActionTypes.GET_COMPOSITE_DATA_SUCCESS,
                            [resultKey]: tempData,
                            key: resultKey,
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.GET_COMPOSITE_DATA_FAILURE,
                            [resultKey]: response,
                            key: resultKey,
                        });
                    }
                },
                error => {
                    dispatch({
                        type: ActionTypes.GET_COMPOSITE_DATA_FAILURE,
                        [resultKey]: error.data,
                    });
                },
            );
        } else {
            dispatch({
                type: ActionTypes.GET_COMPOSITE_DATA_SUCCESS,
                [resultKey]: defaultConstants,
                key: resultKey,
            });
        }
    }
};

export const getRankData = payload => dispatch => {
    const env = 'MOCK';
    dispatch({
        type: ActionTypes.GET_RANK_LOADER,
    });

    const resultKey = payload;
    makeApiRequest(
        ActionTypes.GET_RANK,
        { datakey: payload },
        env,
        response => {
            const tempData = {
                value: response.data.masterdatalist.value,
            };
            dispatch({
                type: ActionTypes.GET_RANK_SUCCESS,
                [resultKey]: tempData,
            });
        },
        error => {
            dispatch({
                type: ActionTypes.GET_RANK_FAILURE,
                response: error.data,
            });
        },
    );
};

export const getFundListData = payload => dispatch => {
    const env = 'FUNDSLIST';
    dispatch({
        type: ActionTypes.GET_FUNDLIST_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_FUNDLIST,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_FUNDLIST_SUCCESS,
                    [ActionTypes.GET_FUNDLIST]: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_FUNDLIST_FAILURE,
                    [ActionTypes.GET_FUNDLIST]: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_ACCOUNT_FUNDLIST_FAILURE,
                response: error.data,
            });
        },
    );
};

const replaceUndefinedOrNull = (key, value) => {
    if (value === null || value === undefined || value === '' || Object.keys(value).length === 0) {
        return undefined;
    }
    return value;
};
const getCleanedPayload = payload => {
    const cleanedObject = JSON.stringify(payload, replaceUndefinedOrNull, 4);
    const finalObject = JSON.stringify(JSON.parse(cleanedObject), replaceUndefinedOrNull, 4);

    return JSON.parse(finalObject);
};

export const saveAccountOpening = (screenName, data) => dispatch => {
    let apiActiontype = '';
    let loaderActionType = '';
    let successActionType = '';
    let failureActionType = '';

    switch (screenName) {
        case 'OpenAccPageOne':
            apiActiontype = ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_FAILURE;
            break;
        case 'OpenAccPageTwo':
            apiActiontype = ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_FAILURE;
            break;
        case 'OpenAccPageThree':
            apiActiontype = ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_FAILURE;
            break;
        case 'OpenAccPageFour':
            apiActiontype = ActionTypes.PREFERENCE_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_FAILURE;
            break;
        case 'OpenAccPageFive':
            apiActiontype = ActionTypes.VERIFY_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.VERIFY_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.VERIFY_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.VERIFY_SAVE_OPENING_ACCT_FAILURE;
            break;

        case 'OpenAccPageSix':
            apiActiontype = ActionTypes.ESIGN_SAVE_OPENING_ACCT;
            loaderActionType = ActionTypes.ESIGN_SAVE_OPENING_ACCT_LOADER;
            successActionType = ActionTypes.ESIGN_SAVE_OPENING_ACCT_SUCCESS;
            failureActionType = ActionTypes.ESIGN_SAVE_OPENING_ACCT_FAILURE;
            break;
        default:
            break;
    }

    const payload = getCleanedPayload(data);

    // console.log(`saveAccountOpening :::>${JSON.stringify(payload)}`);

    const env = 'SAVE_ACC_OPENING';
    dispatch({
        type: loaderActionType,
    });

    makeApiRequest(
        apiActiontype,
        payload,
        env,
        response => {
            dispatch({
                type: successActionType,
                [apiActiontype]: response.data,
            });
        },
        error => {
            dispatch({
                type: failureActionType,
                [apiActiontype]: error.data,
                response: error.data,
            });
        },
    );
};

const getHardcodedSubtypesForSpecialtyApplications = () => ({
    datakey: 'spec_acct',
    datavalue: {
        value: 'For Individuals',
        key: 'spec_acct',
        subtypes: [
            {
                description:
                    'An account for an existing trust where the trustee wishes to invest on behalf of the trust.',
                value: 'Trust',
                key: 'trust',
            },
            {
                description:
                    'An account for an existing estate where the executor wishes to invest on behalf of the estate',
                value: 'Estate',
                key: 'estate',
            },
            {
                description:
                    'An account held in the name of a legal guardian or parent on behalf of a child or other minor under the age of 18-21 (depending on state), or an individual who is incapable of handling their own finances due to a physical or mental disability.',
                value: 'Guardian IRA',
                key: 'guard_ira',
            },
            {
                description:
                    'An account opened by an individual inheriting an IRA or employer-sponsored retirement plan after the original owner dies.',
                value: 'Beneficiary IRA',
                key: 'bene_ira',
            },
            {
                description:
                    'An account that allows participation in a 403(b) plan. A 403(b) plan is a retirement plan for certain employees of public schools, employees of certain tax-exempt organizations and certain others.',
                value: '403B',
                key: '403b',
            },
            {
                description:
                    "The transfer on death designation lets beneficiaries receive assets at the time of the person's death without going through probate.",
                value: 'Transfer on Death',
                key: 'transfer_on_death',
            },
            {
                description:
                    'An account that allows Texas Residents to participate in a retirement plan for certain employees of public schools, employees of certain tax-exempt organizations and certain others.',
                value: 'Optional Retirement Program (ORP)',
                key: 'opt_ret_program',
            },
            {
                description: 'An account that designates assets belonging to both partners of a marriage.',
                value: 'Community Property',
                key: 'com_prop',
            },
            {
                description:
                    'An account for an incapacitated person or older adult with limited capacity that is managed by a court-appointed person',
                value: 'Conservatorship',
                key: 'conserve',
            },
            {
                description:
                    'An account for an incapacitated person or minor that is managed by a court-appointed person',
                value: 'Guardianship',
                key: 'guardian',
            },
            {
                description: '',
                value: 'Pension',
                key: 'pension',
            },
            {
                description: '',
                value: 'Profit Sharing Plan',
                key: 'profit_share',
            },
            {
                description: '',
                value: 'Sole Proprietorship/DBA',
                key: 'sole_prop',
            },
            {
                description: '',
                value: 'Investment Club',
                key: 'invest_club',
            },
            {
                description: '',
                value: 'Broker Dealer',
                key: 'broker',
            },
            {
                description: '',
                value: 'Financial Institution',
                key: 'financial',
            },
            {
                description: '',
                value: 'Business Corporation',
                key: 'biz_corp',
            },
        ],
    },
});

// used by Web team
export const getAccountSubTypes = payload => dispatch => {
    const env = 'MOCK';

    dispatch({
        type: ActionTypes.GET_ACCOUNT_SUBTYPES_LOADER,
    });

    if (payload[0] === 'spec_acct') {
        const data = getHardcodedSubtypesForSpecialtyApplications();

        dispatch({
            type: ActionTypes.GET_ACCOUNT_SUBTYPES_SUCCESS,
            [ActionTypes.GET_ACCOUNT_SUBTYPES]: data.datavalue,
            ...data.datavalue,
        });
    } else {
        makeApiRequest(
            ActionTypes.GET_ACCOUNT_SUBTYPES,
            { datakey: payload },
            env,
            response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNT_SUBTYPES_SUCCESS,
                        [ActionTypes.GET_ACCOUNT_SUBTYPES]: response.data[0].datavalue,
                        ...response.data[0].datavalue,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNT_SUBTYPES_FAILURE,
                        [ActionTypes.GET_ACCOUNT_SUBTYPES]: response,
                    });
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_ACCOUNT_SUBTYPES_FAILURE,
                    response: error.data,
                });
            },
        );
    }
};

export const getSpecialtyApplicationForms = category => dispatch => {
    const env = '';
    const token = localStorage.getItem('token');
    const payload = { token, category };

    const successCallback = ({ status, data: specialtyApplicationForms }) => {
        if (
            Number(status) === 200 &&
            Array.isArray(specialtyApplicationForms) &&
            specialtyApplicationForms.length > 0
        ) {
            dispatch({
                type: ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_SUCCESS,
                specialtyApplicationForms,
                // payload: { data: response.data, searchPayload: payload.searchPayload },
            });
        } else {
            dispatch({ type: ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_FAILURE });
        }
    };

    const errorCallback = error => {
        dispatch({ type: ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_FAILURE, error });
    };

    dispatch({ type: ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_LOADER });

    makeApiRequest(ActionTypes.GET_FORMS_BY_CATEGORY, payload, env, successCallback, errorCallback);
};

export const setSpecialtyApplicationSubtype = specialtyApplicationSubtype => dispatch => {
    dispatch({
        type: ActionTypes.SET_SPECIALTY_APPLICATION_SUBTYPE,
        specialtyApplicationSubtype,
    });
};

export const submitAccountOpening = data => dispatch => {
    // const payload = getCleanedPayload(data);
    // console.log(`submitAccountOpening :::>${JSON.stringify(payload)}`);
    const payload = data;
    const env = 'ACC_OPENING';
    dispatch({
        type: ActionTypes.ESIGN_SAVE_OPENING_ACCT_LOADER,
    });

    let apiActiontype = ActionTypes.SUBMIT_OPENING_ACCT;
    if (payload.regType) {
        switch (payload.regType) {
            case 'Individual Account':
                apiActiontype = ActionTypes.SUBMIT_OPENING_ACCT;
                break;
            case 'Retirement Account':
                apiActiontype = ActionTypes.SUBMIT_IRA_OPENING_ACCT;
                break;
            case 'Joint Account':
                apiActiontype = ActionTypes.SUBMIT_JOINT_OPENING_ACCT;
                break;
            case 'UGMA/UTMA Account':
                apiActiontype = ActionTypes.SUBMIT_UTMA_OPENING_ACCT;
                break;
            case 'Trust Account':
            case 'Estate Account':
                apiActiontype = ActionTypes.SUBMIT_OPENING_ACCT;
                break;
            default:
                apiActiontype = ActionTypes.SUBMIT_OPENING_ACCT;
                break;
        }
    }
    makeApiRequest(
        apiActiontype,
        payload,
        env,
        response => {
            dispatch({
                type: ActionTypes.SUBMIT_OPENING_ACCT_SUCCESS,
                [ActionTypes.SUBMIT_OPENING_ACCT]: response.data,
                ...response.data,
            });
        },
        error => {
            dispatch({
                type: ActionTypes.SUBMIT_OPENING_ACCT_FAILURE,
                [ActionTypes.SUBMIT_OPENING_ACCT]: error.data,
                // response:error,
            });
        },
    );
};

export const getFundDetailsData = payload => dispatch => {
    const env = 'GETFUNDDETAILS';
    dispatch({
        type: ActionTypes.GET_FUNDDETAILS_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_FUNDDETAILS,
        payload,
        env,
        response => {
            if (response.data !== null && response.data !== undefined) {
                const returnData = [];

                response.data.forEach(item => {
                    item.classes.forEach(element => {
                        returnData.push(element);
                    });
                });

                const selectedFundData = returnData.find(
                    element => Number(element.entity_id) === Number(payload.fundNumber),
                );
                if (selectedFundData) {
                    dispatch({
                        type: ActionTypes.GET_FUNDDETAILS_SUCCESS,
                        [ActionTypes.GET_FUNDDETAILS]: selectedFundData,
                        // ...response.data
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_FUNDDETAILS_ERROR,
                        [ActionTypes.GET_FUNDDETAILS]: '',
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_FUNDDETAILS_ERROR,
                    [ActionTypes.GET_FUNDDETAILS]: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_FUNDDETAILS_ERROR,
                [ActionTypes.GET_FUNDDETAILS]: error.data,
            });
        },
    );
};

export const retriveSavedData = data => dispatch => {
    const apiActiontype = ActionTypes.RETRIVE_OPENING_ACCT;
    const successActionType = ActionTypes.RETRIVE_OPENING_ACCT_SUCCESS;
    const failureActionType = ActionTypes.RETRIVE_OPENING_ACCT_FAILURE;
    const loaderActionType = ActionTypes.RETRIVE_OPENING_ACCT_LOADER;

    const payload = {
        ...data,
    };
    const env = 'RETRIVE_ACC_OPENING';
    dispatch({
        type: loaderActionType,
    });

    makeApiRequest(
        ActionTypes.RETRIVE_OPENING_ACCT,
        payload,
        env,
        response => {
            dispatch({
                type: successActionType,
                [apiActiontype]: response.data,
            });
        },
        error => {
            dispatch({
                type: failureActionType,
                [apiActiontype]: error.data,
            });
        },
    );
};

export const populatePendingApplication = data => dispatch => {
    const actionType = ActionTypes.POPULATE_PENDING_APPLICATION;
    dispatch({
        type: actionType,
        isPendingApplication: data,
    });
};

export const uploadW8Form = data => dispatch => {
    const apiActiontype = ActionTypes.UPLOAD_W8FORM;
    const successActionType = ActionTypes.UPLOAD_W8FORM_SUCCESS;
    const failureActionType = ActionTypes.UPLOAD_W8FORM_FAILURE;
    const loaderActionType = ActionTypes.UPLOAD_W8FORM_LOADER;

    const payload = {
        ...data,
    };
    const env = 'UPLOAD_IMAGE';
    dispatch({
        type: loaderActionType,
    });

    makeApiRequest(
        apiActiontype,
        payload,
        env,
        response => {
            dispatch({
                type: successActionType,
                [apiActiontype]: response.data,
            });
        },
        error => {
            dispatch({
                type: failureActionType,
                [apiActiontype]: error,
            });
        },
    );
};

export const uploadAavatarImg = data => dispatch => {
    const apiActiontype = ActionTypes.UPLOAD_AVATAR;
    const successActionType = ActionTypes.UPLOAD_AVATAR_SUCCESS;
    const failureActionType = ActionTypes.UPLOAD_AVATAR_FAILURE;
    const loaderActionType = ActionTypes.UPLOAD_AVATAR_LOADER;

    const payload = {
        ...data,
    };
    const env = 'UPLOAD_IMAGE';
    dispatch({
        type: loaderActionType,
    });

    makeApiRequest(
        apiActiontype,
        payload,
        env,
        response => {
            dispatch({
                type: successActionType,
                [apiActiontype]: response.data,
            });
        },
        error => {
            dispatch({
                type: failureActionType,
                [apiActiontype]: error,
            });
        },
    );
};

export const getAavatarImg = data => dispatch => {
    const apiActiontype = ActionTypes.GET_AVATAR;
    const successActionType = ActionTypes.GET_AVATAR_SUCCESS;
    const failureActionType = ActionTypes.GET_AVATAR_FAILURE;

    const payload = {
        ...data,
    };
    const env = 'GET_AVATAR_IMAGE';
    /*
    dispatch({
        type: loaderActionType
    });
    */

    makeApiRequest(
        apiActiontype,
        payload,
        env,
        response => {
            dispatch({
                type: successActionType,
                [apiActiontype]: response.data,
            });
        },
        error => {
            dispatch({
                type: failureActionType,
                [apiActiontype]: error,
            });
        },
    );
};

export const validateBankAccInfo = payload => dispatch => {
    const env = 'VALIDATE_BANK_ACCOUNT';

    dispatch({
        type: ActionTypes.VALIDATE_BANK_ACCOUNT_LOADER,
    });
    const resultKey = ActionTypes.VALIDATE_BANK_ACCOUNT;
    makeApiRequest(
        ActionTypes.VALIDATE_BANK_ACCOUNT,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.VALIDATE_BANK_ACCOUNT_SUCCESS,
                    [resultKey]: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.VALIDATE_BANK_ACCOUNT_FAILURE,
                    [resultKey]: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.VALIDATE_BANK_ACCOUNT_FAILURE,
                [resultKey]: error.data,
            });
        },
    );
};

// CLEAR REDUX

export const clearReduxKeyData = (stateKey, obj) => dispatch => {
    dispatch({
        type: ActionTypes.CLEAR_REDUX_DATA,
        [stateKey]: obj,
    });
};

/**
 * Sets the next page number in state
 *
 * @param {Object} payload
 */

export const setNextPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: ActionTypes.SET_NEXT_PAGE_NUMBER_NAO_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.SET_NEXT_PAGE_NUMBER_NAO_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Resets the page number to 1 in state
 *
 * @param {Object} payload
 */

export const resetPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: ActionTypes.RESET_PAGE_NUMBER_NAO_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.RESET_PAGE_NUMBER_NAO_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Get the list of saved applications
 *
 * @param {*} payload
 */
export const getSavedApplications = payload => dispatch => {
    dispatch({
        type: ActionTypes.GET_SAVED_APPLICATIONS_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_SAVED_APPLICATIONS,
        payload,
        '',
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_SAVED_APPLICATIONS_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_SAVED_APPLICATIONS_ERROR,
                    payload: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_SAVED_APPLICATIONS_ERROR,
                payload: error.data,
            });
        },
    );
};

/**
 * Get the application selected
 *
 * @param {*} payload
 */
export const getApplication = payload => dispatch => {
    if (payload.uuid !== '') {
        dispatch({
            type: ActionTypes.GET_APPLICATION_LOADER,
        });
        makeApiRequest(
            ActionTypes.GET_APPLICATION,
            payload,
            '',
            response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.GET_APPLICATION_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_APPLICATION_ERROR,
                        payload: response,
                    });
                }
            },
            error => {
                dispatch({
                    type: ActionTypes.GET_APPLICATION_ERROR,
                    payload: error.data,
                });
            },
        );
    }
};

/**
 * Save the application selected
 *
 * @param {*} payload
 */
export const saveApplication = payload => dispatch => {
    const cleanedPayload = cloneDeep(payload);
    const { accountType } = payload.saveApplicationPayload;
    if (payload.cleanPayload) {
        const jointOwners = cleanedPayload.saveApplicationPayload.payload.OpenAccPageTwo.jointPerInfo.jointOwners.map(
            jointOwner => ({
                ...jointOwner,
                jointMailAddressRef: {
                    current: null,
                },
                jointPhysicalAddressRef: {
                    current: null,
                },
                jointPhoneDetailsRef: {
                    current: null,
                },
                jointAddressAccordionRef: {
                    current: null,
                },
                jointPersonalRef: {
                    current: null,
                },
                jointSSNRef: {
                    current: null,
                },
                jointEmailAddressRef: {
                    current: null,
                },
            }),
        );
        cleanedPayload.saveApplicationPayload.payload.OpenAccPageTwo.jointPerInfo.jointOwners = jointOwners;
        cleanedPayload.saveApplicationPayload.payload[accountType.toString()].jointPerInfo.jointOwners = jointOwners;
    }
    dispatch({
        type: ActionTypes.SAVE_APPLICATION_LOADER,
    });

    makeApiRequest(
        ActionTypes.SAVE_APPLICATION,
        cleanedPayload,
        '',
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.SAVE_APPLICATION_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.SAVE_APPLICATION_ERROR,
                    payload: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.SAVE_APPLICATION_ERROR,
                payload: error.data,
            });
        },
    );
};

/**
 * Delete the application selected
 *
 * @param {*} payload
 */
export const deleteApplication = payload => dispatch => {
    dispatch({
        type: ActionTypes.DELETE_APPLICATION_LOADER,
    });
    makeApiRequest(
        ActionTypes.DELETE_APPLICATION,
        payload,
        '',
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.DELETE_APPLICATION_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.DELETE_APPLICATION_ERROR,
                    payload: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.DELETE_APPLICATION_ERROR,
                payload: error.data,
            });
        },
    );
};
/**
 * Resets the page number to 1 in state
 *
 * @param {Object} payload
 */

export const resetShowConfirmModal = () => {
    return async dispatch => {
        dispatch({
            type: ActionTypes.RESET_SHOW_CONFIRM_MODAL,
        });
    };
};

export const clearLocalStateForAccOpening = () => dispatch => {
    dispatch({
        type: ActionTypes.ACCOUNT_OPENING_CLEAR_LOCAL_STATE,
    });
};

export const getDataForMarstoneRedirection = payload => {
    return  dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_LOADER,
        });
        makeApiRequest(
            ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION,
            payload,
            env,
             (response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_FAILURE,
                        payload: response.data,
                    });
                }
            }),

            error => {
                dispatch({
                    type: ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

/**
 * Gets default nick name for account type
 */
export const getAccountDefaultNickName = payload => {
    return  dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_LOADER,
        });
        makeApiRequest(
            ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME,
            payload,
            env,
            (response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_FAILURE,
                        payload: response.data,
                    });
                }
            }),

            error => {
                dispatch({
                    type: ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

/**
 * Verifies customer details
 */
export const verifyCustomerDetails = payload => {
    const { customerType } = payload;
    return  dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.VERIFY_CUSTOMER_DETAILS_LOADER,
        });
        makeApiRequest(
            ActionTypes.VERIFY_CUSTOMER_DETAILS,
            payload,
            env,
             (response => {
                if (response.data) {
                    if (customerType === 'primaryOwner') {
                        dispatch({
                            type: ActionTypes.VERIFY_CUSTOMER_DETAILS_SUCCESS,
                            payload: response.data,
                        });
                    } else if (customerType === 'jointOwner1') {
                        dispatch({
                            type: ActionTypes.VERIFY_CUSTOMER_DETAILS_FIRST_JOINT_OWNER_SUCCESS,
                            payload: response.data,
                        });
                    } else if (customerType === 'jointOwner2') {
                        dispatch({
                            type: ActionTypes.VERIFY_CUSTOMER_DETAILS_SECOND_JOINT_OWNER_SUCCESS,
                            payload: response.data,
                        });
                    }
                } else {
                    dispatch({
                        type: ActionTypes.VERIFY_CUSTOMER_DETAILS_FAILURE,
                        payload: response.data,
                    });
                }
            }),

            error => {
                dispatch({
                    type: ActionTypes.VERIFY_CUSTOMER_DETAILS_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

export const setShowCustomerVerificationModal = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: ActionTypes.SET_CUSTOMER_VERIFICATION_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.SET_CUSTOMER_VERIFICATION_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Gets cognito token for customer details GIACT verification API
 */
export const getCognitoForCustomerVerification = cognitoPayload => {
    return  dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_LOADER,
        });
        makeApiRequest(
            ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION,
            cognitoPayload,
            env,
             (response => {
                if (response.data) {
                    dispatch({
                        type: ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_SUCCESS,
                        payload: response.data.access_token,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_FAILURE,
                        payload: response.data,
                    });
                }
            }),

            error => {
                dispatch({
                    type: ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

export const updateAccountNickname = payload => {
    return  dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START,
            payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME,
                payload,
                env,
                 (response => {
                    dispatch({
                        type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS,
                        payload: response.data,
                    });
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    } else if (error.request) {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE,
                            payload: error.request,
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE,
                            payload: error.message,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE,
                payload: error,
            });
        }
    };
};

export const setCustomerVerificationStatus = () => {
    return async dispatch => {
        dispatch({
            type: ActionTypes.SET_PRIMARY_OWNER_VERIFIED_SUCCESS,
        });
    };
};


export const getMarketSavedApplications = payload => dispatch => {
    // dispatch({
    //     type: ActionTypes.GET_SAVED_APPLICATIONS_LOADER,
    // });
    makeApiRequest(
        ActionTypes.GET_MARKET_SAVED_APPLICATIONS,
        payload,
        '',
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_MARKET_SAVED_APPLICATIONS_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_MARKET_SAVED_APPLICATIONS_ERROR,
                    payload: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_MARKET_SAVED_APPLICATIONS_ERROR,
                payload: error.data,
            });
        },
    );
};

export const removeSavedItemApi = payload => async dispatch => {
    dispatch({
        type: ActionTypes.REMOVE_SAVED_ITEM_LOADER,
    });
    makeApiRequest(
        ActionTypes.REMOVE_SAVED_ITEM,
        payload,
        '',
        async response => {

            if (response.data) {
              
                await dispatch({
                    type: ActionTypes.REMOVE_SAVED_ITEM_SUCCESS,
                    payload: await response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.REMOVE_SAVED_ITEM_ERROR,
                    payload: response,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.REMOVE_SAVED_ITEM_ERROR,
                payload: error.data,
            });
        },
    );
};

export const resentRemoveSavedList = dispatch => {
    return async dispatch => {
        dispatch({
            type: ActionTypes.REMOVE_RESET_SAVED_LIST,
        });
    };
};

export const updateAccountNicknameForManage = payload => {
    return dispatch => {
        const env = '';
        dispatch({
            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START_FOR_MANAGE,
            payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FOR_MANAGE,
                payload,
                env,
                (response => {
                    dispatch({
                        type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS_FOR_MANAGE,
                        payload: response.data,
                    });
                }),
                error => {
                    if (error.response) {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE,
                            payload: error.response.data.errorMessage,
                        });
                    } else if (error.request) {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE,
                            payload: error.request,
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE,
                            payload: error.message,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE,
                payload: error,
            });
        }
    };
};


export const resetNickNameUpdatedStatus = dispatch => {
    return async dispatch => {
        dispatch({
            type: ActionTypes.NICKNAME_UPDATED_STATUS,
        });
    };
};