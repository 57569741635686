import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { Accordian, AccordianTitle } from '../../../../common';
import * as TransactionConstants from '../../TransactionConstants';
import {currencyFormatterFunction,currencyFormatterWithoutDollarSign} from '../../../../common/Formatter/CurrencyFormatter';

const StyledInput = styled.input`
    width: 200px;
    height: 48px;
    border-radius: 0px;
    padding-left: 10px;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
    }
`;

const StyledInputFed = styled.input`
    width: 230px;
    height: 48px;
    border-radius: 0px;
    padding-left: 10px;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
    }
`;

const StyledDollar = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-left: 5px;
`;

const FlexCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: right;
    padding-left: 0;
`;

const AmountFlexCol = styled(Col)`
    display: flex;
    justify-content: right;
`;

const StyledLabel = styled(Col)`
    line-height: 48px;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    opacity: 1;
    color: #333333;
    padding-left: 0;
`;

const InputRadioText = styled.span`
    margin-left: 10px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
`;

const TaxWarning = styled.p`
    color: #ff0000;
    font-weight: 600;
    font-family: benton-sans, sans-serif;
    font-size: 14px;
`;

const ContainerComponent = styled(Container)`
    margin-top: 30px;
    text-align: left;
    margin-left: 20px;
`;

const TaxOptionsRow = styled(Row)`
    margin-bottom: 10px;
`;

const StateTaxRow = styled(Row)`
    margin-top: 25px;
`;

const StyledRadioBtn = styled.input`
    transform: scale(1.5);
    //  border: 1px solid rgba(73, 73, 74, 1);
`;

function TaxOptionsToWithHold(props) {
    const [fedTaxAmount, setFedTaxAmount] = useState(0.0);
    const [stateTaxAmount, setStateTaxAmount] = useState(0.0);
    const [fedTaxPercent, setFedTaxPercent] = useState(10);
    const [stateTaxPercent, setStateTaxPercent] = useState(0);
    const [stateTaxWithHeld, setStateTaxWithHeld] = useState(false);
    const [stateTaxDoNotWithHold, setStateTaxDoNotWithHold] = useState(true);
    const [errorObj, setErrorObj] = useState('');
    const [isError, setIsError] = useState(false);
    const [disableRadioBtn, setDisableRadioBtn] = useState(false);
    const [fedTaxDecimalFound, setFedTaxDecimalFound] = useState(false);
    const [stateTaxDecimalFound, setStateTaxDecimalFound] = useState(false);

    const [count, setCount] = useState(0);
    const [isEditMode, setEditMode] = useState(0)
    const [checkStateTaxGroupForMICT,setCheckStateTaxGroupForMICT] = useState(false);

    const groupStateTax = useSelector(state => state.liquidationData.groupStateTax);
    const {
        stateCodeGroup,
        checkStatus,
        getStateTaxesForGroup,
        setTaxStateValues,
        setTaxValidation,
        age,
        state,
        selectedFund,
        liquidationData,
    } = props;

    const { amount } = props;

    /**
     * Checks if fed tax is 0 when basis is 'FWH'. If yes, then to avoid Infinity value, stateTax should not be divided by fed tax
     */
    function getCalculatedGroupStateTax() {
        let groupStateTaxValue = '';
        if (groupStateTax.basis === TransactionConstants.FWH_TEXT && fedTaxAmount > 0) {
            groupStateTaxValue = ((groupStateTax.stateTax * 100) / fedTaxAmount).toFixed(2);
        } else if (groupStateTax.basis === TransactionConstants.DAT_TEXT) {
            groupStateTaxValue = ((groupStateTax.stateTax * 100) / amount).toFixed(2);
        }
        return groupStateTaxValue;
    }

    const groupStateTaxPercent = getCalculatedGroupStateTax();

    const isSetStateTax =
        stateCodeGroup === TransactionConstants.GROUP_2B_TEXT ||
        stateCodeGroup === TransactionConstants.GROUP_3_TEXT ||
        stateCodeGroup === TransactionConstants.GROUP_4_TEXT ||
        stateCodeGroup === TransactionConstants.GROUP_5_TEXT;

    const group2bAllShareDistAmountMinRule =
        stateCodeGroup === TransactionConstants.GROUP_2B_TEXT &&
        state === 'DC' &&
        selectedFund &&
        selectedFund.amtType === TransactionConstants.FUND_ALL_SHARE_VALUE;

    const group4DistAmountMinRule =
        stateCodeGroup === TransactionConstants.GROUP_4_TEXT && (state === 'AR' || state === 'KS');

    const group3DistAmountMinRule = stateCodeGroup === TransactionConstants.GROUP_3_TEXT && state === 'VT';
    /**added by Madhusmita on 27th Dec 2021 against DFW-305*/
    const group3aDistAmountMinRule =
        stateCodeGroup === TransactionConstants.GROUP_3_TEXT && (state === 'MI' || state === 'CT' || state === 'NC')
    /* End Of change */
     /**added by Gaurav Kumar on 03rd Jan 2022 against DFW-333*/
     const group3aDistTaxPercentRule =
     stateCodeGroup === TransactionConstants.GROUP_3_TEXT && (state === 'MI' || state === 'CT' || state === 'NC');
    /* End Of change */
    
    const group5DistAmountMinRule =
        stateCodeGroup === TransactionConstants.GROUP_5_TEXT && (state === 'NC' || state === 'MI' || state === 'CT')

    let fedTax = 0;
    let stateTax = 0;
    /**
     * Validates if total tax is <= 99 %
     */
    const calculateTotalTaxes = useCallback(
        (stateTaxPercentValue = stateTaxPercent, fedTaxPercentValue = fedTaxPercent) => {
            const totalTax =
                (fedTax === 0 ? fedTaxPercentValue : fedTax) + (stateTax === 0 ? +stateTaxPercentValue : +stateTax);
            const taxValidation = +totalTax > 99;
            if(fedTaxPercent > 0 && fedTaxPercent < 10){
                setIsError(true);
                setErrorObj(TransactionConstants.FEDTAX_WARNING);
                setTaxValidation(true);
            }
            else{
                setIsError(taxValidation);
                setTaxValidation(taxValidation);
                setErrorObj(TransactionConstants.TAX_WITHHOLD_WARNING);
            }
            
        },
        [fedTaxPercent, stateTaxPercent, checkStatus, fedTax, stateTax],
    );


    useEffect(() => {    
        if (liquidationData != null && count == 0 && liquidationData.fedTaxAmount >= 0) {
            setCount(1);
            setFedTaxPercent(liquidationData.fedTaxPercent)
            setFedTaxAmount(liquidationData.fedTaxAmount);
            setStateTaxPercent(liquidationData.stateTaxPercent);
            setStateTaxAmount(liquidationData.stateTaxAmount);

           
            if(liquidationData.stateTaxAmount > 0){
               if(liquidationData.stateTaxWithHeld==true && stateCodeGroup === TransactionConstants.GROUP_3_TEXT)
                {
                    setDisableRadioBtn(true);
                }
                else {
                    setDisableRadioBtn(false);
                }
                setStateTaxWithHeld(liquidationData.stateTaxWithHeld);
                setStateTaxDoNotWithHold(!liquidationData.stateTaxWithHeld);

            }
        }
        else {
          
                setFedTaxAmount(fedTaxAmount);
                setStateTaxAmount(stateTaxAmount);
        }

    }, [fedTaxAmount, stateTaxAmount]);

    useEffect(() => {
        if (isSetStateTax && stateTaxWithHeld) {
            if(props.liquidationData !=undefined &&  props.liquidationData != null )
            {
                setStateTaxAmount(parseFloat(props.liquidationData.stateTaxAmount));
                setStateTaxPercent(props.liquidationData.stateTaxPercent);
                calculateTotalTaxes(props.liquidationData.stateTaxPercent);
            }
            else
            {
                setStateTaxAmount(parseFloat(groupStateTax.stateTax));
                setStateTaxPercent(groupStateTaxPercent);
                calculateTotalTaxes(groupStateTaxPercent);
            }
        }
        if (isSetStateTax && stateTaxWithHeld && state === 'VT') {
            setStateTaxAmount(parseFloat(fedTaxAmount * 30) / 100);
            setStateTaxPercent('30.00');
            calculateTotalTaxes('30.00');
        }

    }, [groupStateTaxPercent, groupStateTax]);

    useEffect(() => {
        const taxPayload = {
            fedTaxAmount,
            fedTaxPercent,
            stateTaxAmount,
            stateTaxPercent,
            totalReceived: amount.toFixed(2) - (fedTaxAmount + stateTaxAmount).toFixed(2),
            distributionAmount: amount,
            totalTaxAmount: (fedTaxAmount + stateTaxAmount).toFixed(2),
            stateTaxWithHeld,
        };  
       setTaxStateValues(taxPayload);
    }, [fedTaxAmount, fedTaxPercent, stateTaxAmount, stateTaxPercent, amount, stateTaxWithHeld]);

    /**
     * Gets the state tax requirements
     * Group2b, Group 3, Group 4, Group 5
     */
    function getDefaultGroupStateTax(federalTaxAmount = fedTaxAmount >= 10) {
        const stateTaxPayload = {
            state,
            fedWithHolding: `${federalTaxAmount}`,
            distributionAmount: `${amount}`,
        };
        getStateTaxesForGroup({ stateTaxPayload, token: localStorage.getItem('token') });
    }

    useEffect(() => {
        if (group2bAllShareDistAmountMinRule) {
            getDefaultGroupStateTax();
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
        }
        else if (group4DistAmountMinRule) {
            getDefaultGroupStateTax();
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
        }
        else if (group3DistAmountMinRule) {
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
            setStateTaxPercent('30.00');
        }
        else if(group3aDistTaxPercentRule )
        {
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
            if(liquidationData !=null &&  isEditMode == 0)
            {
                setEditMode(1)
            }
            else if(liquidationData == null && isEditMode == 0)
            {
                getDefaultGroupStateTax();
              
            }
        }
        /**added by Madhusmita on 27th Dec 2021 against DFW-305*/
        else if (group3aDistAmountMinRule) {
            getDefaultGroupStateTax();
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
        }
        /*End of change*/
        else if (group5DistAmountMinRule) {
            getDefaultGroupStateTax();
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            setDisableRadioBtn(true);
        }

    }, [group4DistAmountMinRule, group3DistAmountMinRule, group3aDistAmountMinRule, group5DistAmountMinRule, group2bAllShareDistAmountMinRule]);

    /**
     * Validate the state tax input from the user.
     * Throw error if input is less than the minimum required state tax percentage
     * Group2b Validation
     */

    useEffect(()=>{
        const event = {
            target:{
                value: `${fedTaxPercent}`
            }
        }
        handleFederalTax(event)
    },[stateCodeGroup])

    function validateStateTaxMinRule(stateTaxValue) {
        if (+stateTaxValue < groupStateTaxPercent || !stateTaxValue) {
            setIsError(true);
            if (
                stateCodeGroup === TransactionConstants.GROUP_2B_TEXT &&
                age < 59.5 &&
                stateTaxWithHeld &&
                state === 'MS'
            ) {
                setErrorObj(
                    `${TransactionConstants.STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS} ${groupStateTaxPercent}%.`,
                );
            } else {
                setErrorObj(`${TransactionConstants.STATE_TAX_MIN_VALUE_WARNING_MSG} ${groupStateTaxPercent}%.`);
            }
            setTaxValidation(true);
        } else {
            setIsError(false);
            setTaxValidation(false);
        }


    }

    /**
     * Captures Federal Tax user input
     */

    const handleTaxValidation = (value,startTaxPercent) => {
        const floatValue = parseFloat(value);
        const totalTaxPercent = startTaxPercent + floatValue;
    
        if (floatValue > 0 && floatValue < 10) {
            setIsError(true);
            setErrorObj(TransactionConstants.FEDTAX_WARNING);
            setTaxValidation(true);
        } else if (totalTaxPercent >= 100) {
            setIsError(true);
            setErrorObj(TransactionConstants.TAX_WITHHOLD_WARNING);
            setTaxValidation(true);
        } else {
            setIsError(false);
            setErrorObj(TransactionConstants.FEDTAX_WARNING);
            setTaxValidation(false);
        }
    };

    const stateCodeGroups = (stateCodeGroup, fedTax, stateTaxPercent) => {

        if (stateCodeGroup === TransactionConstants.GROUP_1_TEXT) {
            const taxValidation = fedTax > 99;
            setIsError(taxValidation);
            setErrorObj(TransactionConstants.TAX_WITHHOLD_WARNING);
            setTaxValidation(taxValidation);
        } else {
            calculateTotalTaxes(stateTaxPercent, fedTax);
        }

    }

    const applyTaxCalculation = (stateCodeGroup, age, stateTaxWithHeld, state, federalTaxAmount) => {

        if (stateCodeGroup === TransactionConstants.GROUP_2B_TEXT && age < 59.5 && stateTaxWithHeld && state === 'MS') {
            getDefaultGroupStateTax(federalTaxAmount);
        }
    };

    const applyDCStateTaxCalculation = (stateCodeGroup, stateTaxWithHeld, state, federalTaxAmount) => {
        if (stateCodeGroup === TransactionConstants.GROUP_2B_TEXT && stateTaxWithHeld && state === 'DC') {
            // No check for premature distribution required for group2b states other than MS
            getDefaultGroupStateTax(federalTaxAmount);
        }
    };
    
    function handleFederalTax(e) {
    
        /* To restrict number of digits before and after decimal */
        const decimalValue = e.target.value.split('.');
        if (decimalValue.length === 2) {
            setFedTaxDecimalFound(true);
        } else {
            setFedTaxDecimalFound(false);
        }
        if (decimalValue && decimalValue.length === 2 && decimalValue[1].length > 2) {
            e.target.value = e.target.value.substr(0, e.target.value.length - 1);
        }
        if (parseFloat(e.target.value) > 100 || decimalValue[1] > 99) {
            e.preventDefault();
            return false;
        }
        if (decimalValue[0].length > 1 && e.target.value.charAt(0) === '0') {
            e.target.value = e.target.value.indexOf('0') === 0 ? e.target.value.substring(1) : e.target.value;
        }

        /* ----END OF NUMBER OF DIGITs CHECK---- */

        fedTax = e.target.value && parseFloat(e.target.value);
        let startTaxPercent = parseFloat(stateTaxPercent);
        const federalTaxAmount = (amount * fedTax) / 100;
        setFedTaxAmount(federalTaxAmount);
        setFedTaxPercent(fedTax);
        window.sessionStorage.setItem('fedTax', fedTax);
       
        stateCodeGroups(stateCodeGroup,fedTax,stateTaxPercent);
        applyTaxCalculation(stateCodeGroup,age,stateTaxWithHeld,state,federalTaxAmount);
        applyDCStateTaxCalculation(stateCodeGroup,stateTaxWithHeld,state,federalTaxAmount);

        if(group3aDistTaxPercentRule)
        {
            if (fedTax >= 10) {
                setStateTaxWithHeld(true);
                setStateTaxDoNotWithHold(false);
                setDisableRadioBtn(true);
            } else {
                // Enabling radio buttons
                setDisableRadioBtn(true);
            }
        }
        else if (
            (stateCodeGroup === TransactionConstants.GROUP_3_TEXT && stateTaxWithHeld && (state === 'MI' || state === 'CT' || state === 'NC')
            ) && (fedTax || fedTax === 0)
        ) {
            if (fedTax) {
            setDisableRadioBtn(true);
            getDefaultGroupStateTax(federalTaxAmount);
            }
            
        }
        else if (stateCodeGroup === TransactionConstants.GROUP_3_TEXT) {
            if (fedTax >= 10) {
                setStateTaxWithHeld(true);
                setStateTaxDoNotWithHold(false);
                setDisableRadioBtn(true);
                
            } else {
                // Enabling radio buttons
                setDisableRadioBtn(false);
            }
            getDefaultGroupStateTax(federalTaxAmount);
        }

        /**added by Madhusmita on 27th Dec 2021 against DFW-305*/
         if (
            (stateCodeGroup === TransactionConstants.GROUP_4_TEXT ||
                stateCodeGroup === TransactionConstants.GROUP_5_TEXT) &&
            (fedTax || fedTax === 0)
        ) {
            setStateTaxWithHeld(true);
            setStateTaxDoNotWithHold(false);
            getDefaultGroupStateTax(federalTaxAmount);
        }
       
        handleTaxValidation(e.target.value, startTaxPercent);
    }

    function handleStateTaxPercentForGroup3aRule(e)
    {
        if(group3aDistTaxPercentRule)
        {
            let baseTaxPercent = parseFloat(groupStateTax.taxPercent);
            if( parseFloat(e.target.value) >= baseTaxPercent )
            { 
                setCheckStateTaxGroupForMICT(false)
            }
            else
            {
                // Reverting back to the api base values
                setStateTaxAmount((amount * baseTaxPercent) / 100);
                setStateTaxPercent(baseTaxPercent);
                window.sessionStorage.setItem('stateTax', baseTaxPercent);
                calculateTotalTaxes(baseTaxPercent);
                setCheckStateTaxGroupForMICT(true);
            }
        }
    }

    /**
     * Captures State Tax user input
     */
    function handleStateTax(e) {
        e.persist();

        /* To restrict number of digits before and after decimal */
        const decimalValue = e.target.value.split('.');
        if (decimalValue.length === 2) {
            setStateTaxDecimalFound(true);
        } else {
            setStateTaxDecimalFound(false);
        }

        if (decimalValue && decimalValue.length === 2 && decimalValue[1].length > 2) {
            e.target.value = e.target.value.substr(0, e.target.value.length - 1);
        }
        if (parseFloat(e.target.value) > 100 || decimalValue[1] > 99) {
            e.preventDefault();
            return false;
        }
        if (decimalValue[0].length > 1 && e.target.value.charAt(0) === '0') {
            e.target.value = e.target.value.indexOf('0') === 0 ? e.target.value.substring(1) : e.target.value;
        }
        /* ----END OF NUMBER OF DIGITs CHECK---- */

        stateTax = e.target.value;
        setStateTaxAmount((amount * stateTax) / 100);
        setStateTaxPercent(stateTax);
        window.sessionStorage.setItem('stateTax', stateTax);
        calculateTotalTaxes(stateTax);
        const totalTaxes = parseFloat(stateTax) + fedTaxPercent;
        if (
            stateCodeGroup === TransactionConstants.GROUP_2B_TEXT &&
            age < 59.5 &&
            stateTaxWithHeld &&
            state === 'MS' &&
            totalTaxes <= 99
        ) {
            validateStateTaxMinRule(stateTax);
        }
        if (
            stateCodeGroup === TransactionConstants.GROUP_2B_TEXT &&
            stateTaxWithHeld &&
            state === 'DC' &&
            totalTaxes <= 99 &&
            selectedFund.amtType === TransactionConstants.FUND_ALL_SHARE_VALUE
        ) {
            validateStateTaxMinRule(stateTax);
        }
    }

    /**
     * Withhold taxes
     */
    function handleStateTaxWithHold() {
        setStateTaxWithHeld(true);
        setStateTaxDoNotWithHold(false);
        setTaxValidation(true);
        if (isSetStateTax || (stateCodeGroup === TransactionConstants.GROUP_2B_TEXT && age < 59.5)) {
            getDefaultGroupStateTax();
        }
       
    }

    /**
     * Do Not Withhold taxes
     */
    function handleStateTaxDoNotWithHold() {

        if(parseFloat(fedTaxPercent) >= 10)
        {
            setIsError(false);
            setErrorObj('');
        }

        setStateTaxDoNotWithHold(true);
        setStateTaxWithHeld(false);
        setStateTaxAmount(0.0);
        setStateTaxPercent(0);
        setIsError(false);
        setTaxValidation(false);
        setErrorObj('');
        calculateTotalTaxes(stateTaxPercent, fedTaxPercent);
    }

    function handleStateTaxKeyDown(e) {
        /* Logic to prevent user to input more than one decimal point */
        if (e.keyCode === 110) {
            setStateTaxDecimalFound(true);
        } else {
            setStateTaxDecimalFound(false);
        }
        if (e.keyCode === 110 && e.target.value === '') {
            setStateTaxDecimalFound(true);
        }
        // check for no negative symbol
        if (
            e.keyCode === 109 ||
            e.keyCode === 107 ||
            e.keyCode === 69 ||
            e.keyCode === 17 ||
            e.keyCode === 91 ||
            e.keyCode === 86 ||
            e.keyCode === 59 ||
            e.keyCode === 61 ||
            e.keyCode === 173 ||
            e.keyCode === 106 ||
            e.keyCode === 111 ||
            e.keyCode === 191 ||
            e.keyCode === 192 ||
            (e.keyCode >= 187 && e.keyCode <= 189) ||
            (e.keyCode >= 48 && e.keyCode <= 57 && e.shiftKey) ||
            (e.keyCode >= 65 && e.keyCode <= 90) ||
            (e.keyCode >= 219 && e.keyCode <= 222) ||
            (stateTaxDecimalFound && e.keyCode === 110)
        ) {
            e.preventDefault();
        }
        return true;
    }

    function handleFedTaxKeyDown(e) {
        /* Logic to prevent user to input more than one decimal point */
        if (e.keyCode === 110) {
            setFedTaxDecimalFound(true);
        } else {
            setFedTaxDecimalFound(false);
        }
        if (e.keyCode === 110 && e.target.value === '') {
            setFedTaxDecimalFound(true);
        }
        // check for no negative symbol
        if (
            e.keyCode === 189 ||
            e.keyCode === 187 ||
            e.keyCode === 109 ||
            e.keyCode === 107 ||
            e.keyCode === 69 ||
            e.keyCode === 17 ||
            e.keyCode === 91 ||
            e.keyCode === 86 ||
            e.keyCode === 38 ||
            e.keyCode === 40 ||
            e.keyCode === 59 ||
            e.keyCode === 61 ||
            e.keyCode === 173 ||
            e.keyCode === 106 ||
            e.keyCode === 111 ||
            e.keyCode === 191 ||
            e.keyCode === 192 ||
            (e.keyCode >= 187 && e.keyCode <= 189) ||
            (e.keyCode >= 48 && e.keyCode <= 57 && e.shiftKey) ||
            (e.keyCode >= 65 && e.keyCode <= 90) ||
            (e.keyCode >= 219 && e.keyCode <= 222) ||
            (fedTaxDecimalFound && e.keyCode === 110)
        ) {
            e.preventDefault();
        }
        return true;
    }
    function handleScroll(e) {
        e.currentTarget.blur();
    }

    return (
        <div data-test="taxContainer">
            <Accordian
                key={TransactionConstants.TAX_WITHHOLD_TITILE}
                titleChild={
                    <AccordianTitle
                        accType={TransactionConstants.TAX_WITHHOLD_TITILE}
                        id={TransactionConstants.TAX_WITHHOLD_TITILE.replace(/ /g, '_')}
                        accIndex={0}
                        className={`titleHeader${TransactionConstants.TAX_WITHHOLD_TITILE.replace(/ /g, '_')}`}
                    />
                }
                titleSeperator
                labelledBy={TransactionConstants.TAX_WITHHOLD_TITILE}
            >
                <ContainerComponent data-test="taxContainerComponent">
                    <Row data-test="distAmountSection">
                        <StyledLabel sm={3}>{TransactionConstants.AMOUNT_LABEL}</StyledLabel>
                        <StyledDollar>{TransactionConstants.DOLLAR_SYMBOL}</StyledDollar>
                        <Col sm={3}>
                            <AmountFlexCol sm={12}>
                                <StyledInput
                                    data-test="inputAmount"
                                    disabled
                                    value={currencyFormatterWithoutDollarSign(amount)}
                                    name={TransactionConstants.DIST_AMOUNT_INPUT_NAME}
                                />
                            </AmountFlexCol>
                        </Col>
                    </Row>
                    <Row data-test="fedTaxSection">
                        <StyledLabel sm={3}>{TransactionConstants.FEDERAL_TAX_LABEL}</StyledLabel>
                        <FlexCol sm={3}>
                            <StyledInputFed
                                type="number"
                                onKeyDown={handleFedTaxKeyDown}
                                data-test="fedTaxPercent"
                                name={TransactionConstants.FEDERAL_TAX_INPUT_NAME}
                                onChange={handleFederalTax}
                                value={fedTaxPercent}
                                maxlength={6}
                                onWheel={handleScroll}
                                className="text-right mr-2"
                            />
                        </FlexCol>
                        <StyledDollar data-test="fedTaxAmount">
                            {currencyFormatterFunction(fedTaxAmount.toFixed(2))}
                        </StyledDollar>
                    </Row>
                    {stateCodeGroup !== TransactionConstants.GROUP_1_TEXT ? (
                        <>
                            <Row>
                                <StyledLabel sm={5}>{TransactionConstants.WITHHOLDING_OPTIONS_TEXT}</StyledLabel>
                            </Row>
                            <TaxOptionsRow data-test="stateTaxWithholdSection" role="radiogroup">
                                <Col sm={3}>
                                    <StyledRadioBtn
                                        type={TransactionConstants.RADIO_BUTTON_TYPE}
                                        disabled={disableRadioBtn}
                                        value={TransactionConstants.DO_NOT_WITHHOLD_TAX_TEXT}
                                        checked={stateTaxDoNotWithHold}
                                        aria-checked={stateTaxDoNotWithHold}
                                        onChange={handleStateTaxDoNotWithHold}
                                        data-test="stateTaxDoNotWithholdTax"
                                        id={TransactionConstants.DO_NOT_WITHHOLD_TAX_TEXT.replace(/ /g, '_')}
                                        tabIndex="0"
                                        name={TransactionConstants.WITHHOLDING_OPTIONS_TEXT}
                                        aria-required
                                    />
                                    <InputRadioText
                                        for={TransactionConstants.DO_NOT_WITHHOLD_TAX_TEXT.replace(/ /g, '_')}
                                    >
                                        {TransactionConstants.DO_NOT_WITHHOLD_TAX_TEXT}
                                    </InputRadioText>
                                </Col>
                                <Col sm={3}>
                                    <StyledRadioBtn
                                        type={TransactionConstants.RADIO_BUTTON_TYPE}
                                        disabled={disableRadioBtn}
                                        value={TransactionConstants.WITHHOLD_TAX_TEXT}
                                        checked={stateTaxWithHeld}
                                        aria-checked={stateTaxWithHeld}
                                        onChange={handleStateTaxWithHold}
                                        data-test="stateTaxWithholdTax"
                                        id={TransactionConstants.WITHHOLD_TAX_TEXT.replace(/ /g, '_')}
                                        tabIndex="0"
                                        name={TransactionConstants.WITHHOLDING_OPTIONS_TEXT}
                                        aria-required
                                    />
                                    <InputRadioText for={TransactionConstants.WITHHOLD_TAX_TEXT.replace(/ /g, '_')}>
                                        {TransactionConstants.WITHHOLD_TAX_TEXT}
                                    </InputRadioText>
                                </Col>
                            </TaxOptionsRow>
                        </>
                    ) : (
                        ''
                    )}
                    {stateTaxWithHeld ? (
                        <StateTaxRow data-test="stateTaxSection">
                            <StyledLabel sm={3}>
                                {groupStateTax.basis === TransactionConstants.FWH_TEXT
                                    ? TransactionConstants.STATE_TAX_ON_FEDTAX_TEXT
                                    : TransactionConstants.STATE_TAX_LABEL}
                            </StyledLabel>
                            <FlexCol sm={3}>
                                <StyledInput
                                    type="number"
                                    name={TransactionConstants.STATE_TAX_INPUT_NAME}
                                    disabled={
                                        (group3aDistAmountMinRule == true ? false : (
                                            (stateCodeGroup === TransactionConstants.GROUP_3_TEXT && fedTaxAmount > 0) ||
                                            (stateCodeGroup === TransactionConstants.GROUP_4_TEXT && fedTaxAmount > 0) ||
                                            group2bAllShareDistAmountMinRule ||
                                            group3DistAmountMinRule ||
                                            group4DistAmountMinRule ||
                                            stateCodeGroup === TransactionConstants.GROUP_5_TEXT ||
                                            stateCodeGroup === TransactionConstants.GROUP_5_TEXT || 
                                            false
    
                                         ))
                                    }
                                    value={stateTaxPercent}
                                    onChange={handleStateTax}
                                    onKeyDown={handleStateTaxKeyDown}
                                    data-test="stateTaxPercent"
                                    maxlength={6}
                                    onWheel={handleScroll}
                                    onBlur={handleStateTaxPercentForGroup3aRule}
                                />

                            </FlexCol>
                            <StyledDollar data-test="stateTaxAmount">
                                {currencyFormatterFunction(stateTaxAmount.toFixed(2))}
                            </StyledDollar>
                        </StateTaxRow>
                    ) : (
                        ''
                    )}
                    <Row data-test="warningMsgSection">
                        <StyledLabel sm={3} />
                        <FlexCol sm={4}>
                            {isError ? <TaxWarning data-test="warningMsgErrorObj">{errorObj}</TaxWarning> : ''}
                            {stateCodeGroup === TransactionConstants.GROUP_5_TEXT && stateTaxWithHeld ? (
                                <TaxWarning>{TransactionConstants.GROUP5_TAX_WARNING_MSG}</TaxWarning>
                            ) : (
                                ''
                            )}
                        </FlexCol>
                    </Row>
                    <Row data-test="totalTaxesSection">
                        <StyledLabel sm={3}>{TransactionConstants.TOTAL_TAXES_TO_WITHHOLD_LABEL}</StyledLabel>
                        <FlexCol sm={3}>
                            <StyledDollar data-test="totalTaxesInputAmount">
                              
                                {currencyFormatterFunction((fedTaxAmount + stateTaxAmount).toFixed(2))}
                            </StyledDollar>
                        </FlexCol>
                    </Row>
                    <Row data-test="totalReceivedSection">
                        <StyledLabel sm={3}>{TransactionConstants.TOTAL_RECEIVED_LABEL}</StyledLabel>
                        <FlexCol sm={3}>
                            <StyledDollar data-test="totalReceivedInputAmount">
                                {currencyFormatterFunction((amount.toFixed(2) - (fedTaxAmount + stateTaxAmount).toFixed(2)).toFixed(2))}
                            </StyledDollar>
                        </FlexCol>
                    </Row>
                    <Row data-test="totalWithdrawalSection">
                        <StyledLabel sm={3}>{TransactionConstants.TOTAL_WITHDRAWAL}</StyledLabel>
                        <FlexCol sm={3}>
                            <StyledDollar data-test="totalwithDrawalAmount">
                                {currencyFormatterFunction(amount.toFixed(2))}
                            </StyledDollar>
                        </FlexCol>
                    </Row>
                </ContainerComponent>
            </Accordian>
        </div>
    );
}

TaxOptionsToWithHold.propTypes = {
    stateCodeGroup: PropTypes.string,
    amount: PropTypes.number,
    checkStatus: PropTypes.func,
    getStateTaxesForGroup: PropTypes.func,
    setTaxValidation: PropTypes.func,
    setTaxStateValues: PropTypes.func,
    age: PropTypes.number,
    state: PropTypes.string,
    selectedFund: PropTypes.instanceOf(Object),
};
TaxOptionsToWithHold.defaultProps = {
    stateCodeGroup: '',
    amount: 0,
    checkStatus: () => { },
    getStateTaxesForGroup: () => { },
    setTaxValidation: () => { },
    setTaxStateValues: () => { },
    age: 0,
    state: '',
    selectedFund: {},
};

export default TaxOptionsToWithHold;
