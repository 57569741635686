import styled from 'styled-components';
import { ReactComponent as DownArrowBlue } from 'assets/DownArrowBlue.svg';
import { Row, Form } from 'react-bootstrap';

export const CheckBoxLableStyle = styled(Form.Check)`
    display: flex;
    padding-left: 25px;
    padding-bottom: 30px;
    height: 35px;
    & input[type='checkbox'] {
        width: 25px;
        height: 25px;
    }
    & input[type='checkbox']:checked:after {
        top: 6px;
        left: 8px;
    }
    & label {
        margin-top: 5px;
    }
    :hover {
        background: #e6e6e6 0% 0% no-repeat padding-box;
    }
`;

export const SelectedItem = styled.div`
    display: flex;
    padding-top: 2px;
    padding-bottom: 2px;

    > .displayValue {
        width : 70%;
    }
`;
export const StyledInput = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0 1.0625rem 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid ${props => (props.isOpen ? '#486d90' : '#d2d2d2')};
    opacity: 1;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0;
    color: #56565a;
    min-height: 3.125rem;
    &:hover {
        border: 1px solid #486d90;
    }
    :only-child:not(:focus):before {
        content: attr(data-placeholder);
    }
`;

export const StyledOptionalSpan = styled.span`
    font: 500 12px/20px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 10px;
`;
export const InputIcon = styled.div`
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1.25rem;
`;

export const InputDownArrow = styled(DownArrowBlue)`
    width: 0.85rem;
    height: 0.85rem;
`;

export const SelectDiv = styled.div`
    display: inline-flex;
    position: relative;
    width: 100%;
`;

export const SelectBox = styled.div`
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 3rem;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 0.46875rem solid transparent;
        border-right: 0.46875rem solid transparent;
        border-bottom: 0.625rem solid #ffffff;
        top: -0.625rem;
        right: 1.5rem;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.25rem;
    overflow-y: scroll;
    max-height: 14.0625rem;
    &::-webkit-scrollbar {
        width: 0.1875rem;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
        background: #486d90 0% 0% no-repeat padding-box;
        border-radius: 0.1875rem;
    }
`;

export const ItemSpan = styled.span`
    cursor: pointer;
    text-align: left;
    padding: 0.625rem;
    height: 2.8125rem;
    background: ${props => (props.selected ? '#E6E6E6' : '#FFFFFF')} 0% 0% no-repeat padding-box;
    opacity: 1;
    &:focus {
        background: ${props => (props.selected ? '#E6E6E6' : '#fafafa')} 0% 0% no-repeat padding-box;
        outline: none;
    }
`;

export const ErrMsg = styled.small`
    position: absolute;
    display: block;
    margin-top: 0.25rem;
    color: red;
`;

export const SelectContainer = styled.div`
    position: relative;
    cursor: pointer;
`;

export const StyledRow = styled(Row)`
    margin-bottom: 1.5625rem;
    align-items: center;
`;

export const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;
