import '../MarketNAOStyles.css'
import { Col, Row } from 'react-bootstrap';
import { Accordian, AccordianTitle,WIcon} from 'common';
import editIcon from '../../../../assets/EditPencil.svg';
import React, { useState, useEffect,useRef} from 'react';
import {useSelector} from  "react-redux";
import {employeeBDLabel, relatedToEmployeeLabel, relatedToEmpAnother, memRelToStock, maintainOtherAccount, foreignAccount, isThisForeignInst, foreignbank, CentralBank, Offshorebanking, Noncooperativecountry, section311, PoliticallyExposedPerson, memofdir, anotherBrokerLabel, personalinfo, EmpofAnotherDealer, vcEmployeeLabel } from '../consts'
import RegulatoryInfo from '../Regulatory/RegulatoryInfo';
import JointRegulatoryInfo from '../Regulatory/JointRegulatoryInfo';
import useAutoFocus from './useAutoFocus'


const VerifyRegulatoryInfo=(props)=>{
      const assignObj = obj => {
    return obj;
};
 
//state
 const [riskEdit, setRiskEdit] = useState(false)
 const [brokerEdit, setBrokerEdit] = useState(false)

   
 //Focus Sections
 const riskRef=useRef(null)
 const brokerRef=useRef(null)

//  commenting the line as for joint account has no risk profile section which was causing blank screen. 
 useAutoFocus(brokerEdit,brokerRef)




const allRegulatory= riskEdit || brokerEdit
useEffect(() => {
  props.setEditDisableVal(!allRegulatory)
}, [allRegulatory])




    //from redux state
    const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData.marketNAOInfo);
    const riskProfile = marketNAOInfoReducerData.riskProfile[props.primaryOrSecondary]
    const brokerDealer=marketNAOInfoReducerData.brokerDealer[props.primaryOrSecondary]
    const relationshipDropDownData = useSelector(state => state.transactionCompositeDataReducerData?.brkg_relationship?.value)
    
//For Avoiding div repeatations
 const linesReducer=(verifyHeading,verifyValue)=>{return(
  <div className="card-list-wrapper">
            <div className="row">
              <div className="label-key col-lg-4 col-md-4 col-sm-12 col-12">
                <span>{verifyHeading}</span>
              </div>
              <div className="label-value col-8" data-testid={`${verifyHeading}`} ><span>{verifyValue ? verifyValue : '-'}</span></div>
            </div>
          </div>
 )}

  const brokerDealerLineReducer = (brokerDealerHeading, brokerDealerValue) => {
    return (
      <div className="card-list-wrapper">
        <div className="row">
          <div className="label-key col-lg-6 col-md-6 col-sm-12 col-12">
            <span>{brokerDealerHeading}</span>
          </div>
          <div className="label-value-broker-dealer col-4 col-lg-4 text-left" data-testid={`${brokerDealerHeading}`} >{brokerDealerValue ? brokerDealerValue : '-'}</div>
        </div>
      </div>
    )
  }
  const vcEmployeeQuestion=brokerDealerLineReducer(vcEmployeeLabel, brokerDealer?.vcEmployee)

  const getValue = (key, arr) => {
    const objFound = arr.find(item => item.key === key)
    if(objFound){
        return objFound.value
    }else{
        return ''
    }
}
  
  return(
    <>
      { riskEdit ? props.primaryOrSecondary==='primary'? <RegulatoryInfo editRiskValIn={riskEdit} editRiskValOut={(val)=>setRiskEdit(val)}  /> : <JointRegulatoryInfo editRiskValIn={riskEdit} editRiskValOut={(val)=>setRiskEdit(val) } /> :
      <>
      { props.primaryOrSecondary === 'primary' ?
      <Accordian
        accordianPaddingLeft={0}
        id={personalinfo}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }} ref={riskRef} tabIndex="-1">
            <Col md={10} xl={11}>
              <AccordianTitle
                accType={props.riskRegulatoryTitle}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>
              
              
          
            <Col md={2} xl={1} className="sum-prelink">
              {props.editDisabler && props.showEditButton  &&<Row noGutters>
                <WIcon
                  alt=""
                  src={editIcon}
                  size={"1px"}
                  className="editIcon"
                  fontawesomestyle={assignObj({ paddingLeft: 0 })}
                />
                <button type="button" className="sum-linktext mt-1" onClick={()=>setRiskEdit(!riskEdit)}>
                  <span>Edit</span>
                </button>
              </Row>}
            </Col>
        
        </Row>
        }
        titleSeperator
      >

        <div className="sum-card-wrap" >
          {linesReducer("Investment Objective",riskProfile?.InvestmentObjective)}
          {linesReducer("Time Horizon",riskProfile?.TimeHorizon)}
          {linesReducer("Risk Exposure",riskProfile?.RiskExposure)}
          {linesReducer("Investment Knowledge",riskProfile?.InvestmentKnowledge)}
          {linesReducer("Investment Goal",riskProfile?.InvestmentGoal)}
          {linesReducer("Source of Funds",riskProfile?.SourceOFFund)}
          {riskProfile.SourceOFFund=="Other"?<div>{linesReducer("Source of Funds Info",riskProfile.sourceOFFundInfo == "" ? "-" : <span> {riskProfile.sourceOFFundInfo}</span>)}</div>:null}
        </div>

      </Accordian>
       : null
      }
      </>
      }

       { brokerEdit ? props.primaryOrSecondary==='primary'?<RegulatoryInfo editBrokerValIn={brokerEdit} editBrokerValOut={(val)=>setBrokerEdit(val) } />  : <JointRegulatoryInfo editBrokerValIn={brokerEdit} editBrokerValOut={(val)=>setBrokerEdit(val)} /> :
      <> <Accordian
          accordianPaddingLeft={0}
          id={'BrokerDealer'}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={brokerRef} tabIndex="-1">
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={props.brRegulatoryTitle}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                /></Col>



              <Col md={2} xl={1} className="sum-prelink">
                {props.editDisabler && props.showEditButton  &&<Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" className="sum-linktext mt-1" onClick={()=>setBrokerEdit(!brokerEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>

            </Row>
          }
          titleSeperator
        >

          <div className="sum-card-wrap">
            {vcEmployeeQuestion}
            {brokerDealerLineReducer(anotherBrokerLabel, brokerDealer?.brokerDealerEmployment)}

            {brokerDealer.brokerDealerEmployment === "Yes" && <div>
              {brokerDealerLineReducer(employeeBDLabel, brokerDealer?.employeeOfThisBrokerDealer)}
              {brokerDealerLineReducer(relatedToEmployeeLabel, brokerDealer?.isRelatedToBrokerDealer)}
              {brokerDealer?.isRelatedToBrokerDealer == "Yes" && <div>
            {brokerDealerLineReducer("Employee's First Name", brokerDealer?.relatedToBrokerDealer.firstname)}
            {brokerDealerLineReducer("Employee's Last Name", brokerDealer?.relatedToBrokerDealer.lastName)}
              {brokerDealerLineReducer("Relationship", brokerDealer?.relatedToBrokerDealer.relationship == "" ? "-" :getValue(brokerDealer.relatedToBrokerDealer.relationship,relationshipDropDownData) )}
           </div>}
           


            {brokerDealerLineReducer(EmpofAnotherDealer, brokerDealer?.isEmployeeOfAnotherBrokerDealer)}
            {brokerDealer?.isEmployeeOfAnotherBrokerDealer === "Yes" && <div>
            {brokerDealerLineReducer("Please enter Broker-Dealer name.", brokerDealer?.EmployeeOfAnotherBrokerDealer.firstname)}
            </div>}

            {brokerDealerLineReducer(relatedToEmpAnother, brokerDealer?.isRelatedToEmployeeOfAnotherBrokerDealer)}
            {brokerDealer?.isRelatedToEmployeeOfAnotherBrokerDealer === "Yes" && <div>
              {brokerDealerLineReducer("Employee's First Name", brokerDealer?.relatedToEmployeeOfAnotherBrokerDealer.firstname)}
              {brokerDealerLineReducer("Employee's Last Name", brokerDealer?.relatedToEmployeeOfAnotherBrokerDealer.lastName)}
              {brokerDealerLineReducer("Relationship", brokerDealer?.relatedToEmployeeOfAnotherBrokerDealer.relationship == "" ? "-" :getValue(brokerDealer?.relatedToEmployeeOfAnotherBrokerDealer.relationship, relationshipDropDownData)) }
              {brokerDealerLineReducer("Please enter name of broker-dealer.", brokerDealer?.relatedToEmployeeOfAnotherBrokerDealer.brokerDealerName)}
            </div>}


            {brokerDealerLineReducer(memRelToStock, brokerDealer?.isMemberEmployeeOfStockExchangeFINRA)}
            {brokerDealer?.isMemberEmployeeOfStockExchangeFINRA === "Yes" && <div>
              {brokerDealerLineReducer("Name Of Affiliation", brokerDealer?.memberEmployeeOfStockExchangeFINRA.nameOfAffiliation)}
            </div>}


            {brokerDealerLineReducer(maintainOtherAccount, brokerDealer?.isOtherBrokerageAccounts)}
            {brokerDealer?.isOtherBrokerageAccounts === "Yes" && <div>
            {brokerDealerLineReducer("Brokerage Name", brokerDealer?.otherBrokerageAccounts.brokerageName)}
            {brokerDealerLineReducer("How many years of investment experience do you have?", brokerDealer?.otherBrokerageAccounts.yearsOfInvestingExperience)}
            </div>}
            </div>}



            {brokerDealerLineReducer(memofdir, brokerDealer?.isDirectorShareholderOfPubliclyTradedCompany)}
            {brokerDealer?.isDirectorShareholderOfPubliclyTradedCompany === "Yes" && <div>
            {brokerDealerLineReducer("What is the company name?", brokerDealer?.directorShareholderOfPubliclyTradedCompany.companyName)}
            </div>}


            {brokerDealer?.directorShareholderOfPubliclyTradedCompany.companyName === "Yes" && <div>
              {brokerDealerLineReducer("Director/Shareholder Of PublioclyTraded CompanyName", brokerDealer?.directorShareholderOfPublioclyTradedCompanyName)}
            </div>}


            {brokerDealerLineReducer(foreignAccount, brokerDealer?.isAccountOpenedForForeignInstitution)}


            {brokerDealer?.isAccountOpenedForForeignInstitution === "Yes" && <div>
              {brokerDealerLineReducer(isThisForeignInst, brokerDealer?.accountOpenedForForeignInstitution.isFinancialInstitution)}

              {brokerDealerLineReducer("Is this a private banking account ?", brokerDealer?.accountOpenedForForeignInstitution.isPrivateBank)}
              {brokerDealerLineReducer(foreignbank, brokerDealer?.accountOpenedForForeignInstitution.isForeignBank)}

              {brokerDealer?.accountOpenedForForeignInstitution.isForeignBank == "Yes" && <div>
                {brokerDealerLineReducer(CentralBank, brokerDealer?.accountOpenedForForeignInstitution.foreignBank.isCentralBank)}
                {brokerDealerLineReducer(Offshorebanking, brokerDealer?.accountOpenedForForeignInstitution.foreignBank.isBankingLicense)}
                {brokerDealerLineReducer(Noncooperativecountry, brokerDealer?.accountOpenedForForeignInstitution.foreignBank.isTerritory)}
                {brokerDealerLineReducer(section311, brokerDealer?.accountOpenedForForeignInstitution.foreignBank.isSubjectToSection311)}
                </div>
              }
              </div>
            }



            {brokerDealerLineReducer(PoliticallyExposedPerson, brokerDealer?.isPep)}


            {brokerDealer?.isPep === "Yes" && <div>
              {brokerDealerLineReducer("First Name", brokerDealer?.pep.firstName)}
              {brokerDealerLineReducer("Last Name", brokerDealer?.pep.lastName)}
              {brokerDealerLineReducer("Office Held", brokerDealer?.pep.officeHeld)}
              {brokerDealerLineReducer("Country of Office", brokerDealer?.countryOfOfficeInfo)}
            </div>}

          </div>

        </Accordian>

        </>
        }

        </>

  )

}

export default VerifyRegulatoryInfo