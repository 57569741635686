import { connect } from 'react-redux';
import BeneficiaryForm from './BeneficiaryForm';
import { accOpeningActions,transactionCompositeDataAction } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
   // initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BeneficiaryForm);
