import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";

export const saveData = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.LOCAL_STATE,
        ...data
    });
};

export const saveOpenedAccountsPreferences = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_LOADER,
        payload
    });
    const env = '';

    makeApiRequest(
        ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE,
        payload,
        env,
        response => {
            if (response.data && response.data.result.success) {
                dispatch({
                    type: ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_SUCCESS,
                    payload: {
                        accounts: response.data.result.accounts
                    }
                });
            } else {
                dispatch({
                    type: ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_FAILURE,
                    payload
                });
            }
        }, error => {
            dispatch({
                type: ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_ERROR,
                payload: error
            });
        }
    );
};

export const saveOpenedAccountsPreferencesForAllAccounts = (payload) => (dispatch) => {
    dispatch({
        type: ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL,
        payload
    });
};

export const getOpenedAccountsPreferences = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_LOADER,
        payload
    });
    const env = '';
    makeApiRequest(
        ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_SUCCESS,
                    payload: { accountsOpened: response.data.result }
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_FAILURE,
                    payload: response
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_ERROR,
                payload: error
            });
        }
    );
};

export const setAccountPreferences = payload => dispatch => {
    dispatch({
        type: ActionTypes.SET_ACCOUNT_PREFERENCES,
        payload
    });
};