import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 30px 0 0 0; 
  width: 450px;
`;

const InputContainer = styled.div`
  padding: 0 20px 0 0;
`;

const Input = styled.input`
  type:number;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  // border: ${props => (props.isError) ? '1px solid red' : '1px solid #D2D2D2'};
  border: 1px solid #D2D2D2;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
`;

const Label = styled.label`
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-benton-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-56565a);
  text-align: left;
  font: normal normal medium 12px/20px Benton Sans;
  letter-spacing: 0px;
  color: #56565A;
  opacity: 1;
`;

const ErrorMessage = styled.label`
  text-align: left;
  font: normal normal medium 12px/20px Benton Sans;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
`;


export const dateInputIds = {
  month: 'inputMonth',
  day: 'inputDay',
  year: 'inputYear',
}
const DateInput = (props) => {
  const { onChange, showErrorForBirthDate} = props;

  return (
    <>
      <DateInputContainer>
        <InputContainer>
          <Input type='number' id={dateInputIds.month} onChange={onChange}/>
          <Label for='inputMonth'>Month</Label>
        </InputContainer>
        <InputContainer>
          <Input type='number' id={dateInputIds.day} onChange={onChange}/>
          <Label for='inputDay'>Day</Label>
        </InputContainer>
        <InputContainer>
          <Input type='number' id={dateInputIds.year} onChange={onChange}/>
          <Label for='inputYear'>Year</Label>
        </InputContainer>
      </DateInputContainer>
      <br/>
      {showErrorForBirthDate && <ErrorMessage>Invalid Date</ErrorMessage>}
  </>)
}

DateInput.propTypes = {
  onChange: PropTypes.func,
}

DateInput.defaultProps = {
  onChange: () => {},
}

export default DateInput
