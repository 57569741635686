import { Row, Container, Col } from 'react-bootstrap';
import { CommonButtons,WSpinner} from 'common';
import { useHistory } from "react-router-dom"; 
import { useSelector,useDispatch } from 'react-redux';
import { clearMarketNAOInfo} from 'shared/Actions/MarketNAOActions';
import React, {useState,useEffect} from 'react'
import { getRole } from 'utils';
import { MARKET_NAO_ACCOUNT_TYPES, primaryAppSubmissionSuccessful,rolloverConfirmationMsgInstructions, addBankProcedureSteps, finalConfirmationMessage, bulkApiSuccessCode, confirmFundingTypeHeading} from './consts';
//import { storeAssistedTransferStatus } from 'shared/Actions/AssistedTransferAction';
//import StarRatingPopup from 'modules/AccountDashboardModule/Common/StarRating/StarRatingPopup';
import { SessionStorageHelper } from 'utils/sessionHelper';
//import { enableHeaderLinks } from 'shared/Actions/LocalStateManagementActions';
import pdfIcon from '../../../assets/icon_pdf.png';
//import MarketPersonalCheckComponent from './MarketPersonalCheckComponent';
import envConfig from 'env/EnvConfig';
//import { triggerClickSatelliteTrack } from 'utils/AdobeAnalytics/AnalyticsUtils';

const congratulationText = 'Confirmation';

const ConfirmationFile=()=>{
    const history = useHistory();
    const dispatch = useDispatch()
    const localStateData = useSelector(state => state);
    const initialState = {
        statusMsg: null,
        isLoading: true,
        statusMessageAndLoaderFunCalled: false,
        disableNext: false,
        additionContent: null,
        nextText:'Ok',
        showtransferAssetMessage: false,
        renderCount: 0,
        showRolloverInstructions: false
    }
    const [confirmationFileState, setConfirmationFileState] = useState(initialState)
   //From Redux State
   const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
   const selectedAccountType = marketNAOInfoReducerData.marketNAOInfo.account.accountType;
   const accountList = ["Individual", "Joint Tenants with Rights of Survivorship", "Traditional IRA", "Roth IRA", "JTWROS", "Rollover IRA"]
   const transferAssets = marketNAOInfoReducerData.marketNAOInfo?.accountFunding?.transferAssets
   //const isStarRatingPopup = useSelector(state => state.dashBoardLinkReducer.isStarRatingPopup);
   const isJointAccount = marketNAOInfoReducerData.marketNAOInfo?.account?.accountType ? (marketNAOInfoReducerData.marketNAOInfo.account.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT || marketNAOInfoReducerData.marketNAOInfo.account.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM) : false

    const getUserRole = () => {
        let role = 'Member'
        // check if role is availble in profile information in local store(redux) else look for login user stored state(loginData)
        if(localStateData.profileInformationData?.hasProfileInfo){
            role = localStateData.profileInformationData?.profileInformation?.role
        }else{
            role = getRole(localStateData && localStateData.loginData.getCustomerProfile);
        }

        return role
    }

    const role = getUserRole()

    const clearStateAndRedirectTo = (redirectTo) => {

        let pathname = ''

        if(redirectTo === 'asset-transfer'){
            pathname = '/transferMarketAccount'

            //dispatch(storeAssistedTransferStatus())

            history.replace({
                pathname: pathname,
                state:{
                    isNewDashboardFeatureEnabled:true
                }
            }); 
        }else{
            pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
            history.replace({
                pathname: pathname
            }); 
        }
    }

    const onProceedOrOkButtonClick = () => {
        if(transferAssets){
            //dispatch(enableHeaderLinks())
        }

        //const redirectPath = confirmationFileState.nextText == 'Proceed' ? 'asset-transfer' : 'home';
        const redirectPath = 'home';
        dispatch(clearMarketNAOInfo())
        clearStateAndRedirectTo(redirectPath)
    }

  
    if(marketNAOInfoReducerData.applicationId == ''){
        history.push({
            pathname: role === 'Member' ? '/accountholder' : '/accountguestview',
        });
    }


    const confirmationMessage = (accountType = "") => {
        const hasFundingStep = localStateData.memberDashboardData.isEnableFeatureSuccess && localStateData.memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false
        const showBankAdditionSteps = hasFundingStep && marketNAOInfoReducerData?.marketNAOInfo?.addBankDetails?.addBankLaterSelect ? true : false
        const renderCount = confirmationFileState.renderCount

        if(accountType == "jointAppPrimaryAcc"){
            return (<span>{primaryAppSubmissionSuccessful}</span>)
        }else if(accountType.includes(MARKET_NAO_ACCOUNT_TYPES.ROLLOVER_IRA)){
            // on open bulk api success call the rating function for user rating and it will be shown in home page
            if(renderCount === 0 && !transferAssets){
               // dispatch(getStarRatingPopup())
            }
            setConfirmationFileState(prevState => ({...prevState, showtransferAssetMessage:true, renderCount:1, showRolloverInstructions: true }));

            return <>
                <span>{statusFinalMessage()}</span><br /><br />
                <span>{rolloverConfirmationMsgInstructions.line1}</span><br /><br />
                <span>{rolloverConfirmationMsgInstructions.line2}</span><br /><br />

                {showBankAdditionSteps ? 
                    <span>
                    {addBankProcedureSteps.paraHeading}<br/><br/>
                        <ol type="1">
                            <li>{addBankProcedureSteps.para1}</li>
                            <li>{addBankProcedureSteps.para2}</li>
                            <li>{addBankProcedureSteps.para3}</li>
                        </ol><br/>
                    </span>
                    : 
                    null
                }



            </>
        }else if(accountList.includes(accountType)){
            // on open bulk api success call the rating function for user rating and it will be shown in home page
            if(renderCount === 0 && !transferAssets && !(isJointAccount)){
               // dispatch(getStarRatingPopup())
            }

            setConfirmationFileState(prevState => ({...prevState, showtransferAssetMessage:true, renderCount:1 }));

            return (<>
            <span>{statusFinalMessage()}</span><br /><br />

            {showBankAdditionSteps ? 
                <span>
                {addBankProcedureSteps.paraHeading}<br/><br/>
                    <ol type="1">
                        <li>{addBankProcedureSteps.para1}</li>
                        <li>{addBankProcedureSteps.para2}</li>
                        <li>{addBankProcedureSteps.para3}</li>
                    </ol><br/>
                </span>
                : 
                null
            }

            </>)
        }else{
            return <span>Success</span>
        }
        
    }


    const statusMessageAndLoader = ()=>{

        let statusMsg = marketNAOInfoReducerData.statusMessage
        let isLoading = marketNAOInfoReducerData.isSuccessLoader
        if( selectedAccountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT){ 
            // For Joint Application SAVED APPLICATION/ REDIRECTION flow
            if(marketNAOInfoReducerData.marketNAOInfo?.applicationStatus != undefined){
            statusMsg = marketNAOInfoReducerData.statusMessage 
            isLoading = marketNAOInfoReducerData.isSuccessLoader
    
            // if kyc status is false then its primary pass, secondary fail and its redirection flow as applicationStatus isnt undefined
            // econsent api is call withut bulk api call
            if(!marketNAOInfoReducerData.isSoftFailStatus && marketNAOInfoReducerData.isPostCallSuccess){
                // when only primary user is filling application and only econsent api then show him the confirmation message
                if(marketNAOInfoReducerData?.marketNAOInfo?.isPrimary && marketNAOInfoReducerData.marketNAOInfo.isPrimary &&
                    // marketNAOInfoReducerData?.dataFromSavedApplication?.kyc_check?.primary?.kycActionStatus == "SOFTFAIL_NON_BLOCKING" && marketNAOInfoReducerData.iseConsentApiSuccess){
                    marketNAOInfoReducerData?.dataFromSavedApplication?.kyc_check?.primary?.kycActionStatus == "SOFTFAIL_NON_BLOCKING"){
                    // 'Primary user softfail non blocking'
                    statusMsg = confirmationMessage('jointAppPrimaryAcc')
                    isLoading = false
                }else if(marketNAOInfoReducerData?.marketNAOInfo?.isPrimary && marketNAOInfoReducerData.marketNAOInfo.isPrimary && marketNAOInfoReducerData.iseConsentApiSuccess){
                    // 'Primary user kyc pass and econsent success'
                    statusMsg = confirmationMessage('jointAppPrimaryAcc')
                    isLoading = marketNAOInfoReducerData.isLoading
                }else if(marketNAOInfoReducerData?.marketNAOInfo?.isPrimary != undefined && marketNAOInfoReducerData.marketNAOInfo.isPrimary == false && marketNAOInfoReducerData.isMarketNAOSubmitSuccess){
                    // 'Joint user open bulk api success'
                    statusMsg = confirmationMessage(selectedAccountType)
                    isLoading = marketNAOInfoReducerData.isSuccessLoader
                } 
            }
            else if(marketNAOInfoReducerData?.marketNAOInfo?.isPrimary && marketNAOInfoReducerData.marketNAOInfo.isPrimary &&
                marketNAOInfoReducerData?.dataFromSavedApplication?.kyc_check?.primary?.kycActionStatus == "SOFTFAIL_NON_BLOCKING"){
                statusMsg = confirmationMessage('jointAppPrimaryAcc')
                isLoading = false
    
            }
            }
            // For Joint Application NORMAL flow
            else if(marketNAOInfoReducerData.marketNAOInfo?.applicationStatus == undefined ){
            isLoading = marketNAOInfoReducerData.isLoading
            // 'JOint account normal flow')
            if(marketNAOInfoReducerData.isPostCallSuccess && (marketNAOInfoReducerData.marketNAOInfo?.isPrimary === undefined &&
                marketNAOInfoReducerData?.kycResponseData?.primary?.kycActionStatus === "SOFTFAIL_NON_BLOCKING") ){
                // 'primary user 1st time filling application softfail non blocking')

                statusMsg = confirmationMessage('jointAppPrimaryAcc')
                isLoading = false
            }else if(marketNAOInfoReducerData.isPostCallSuccess){
                // 'primary user 1st time filling application econcent pass')

                statusMsg = confirmationMessage('jointAppPrimaryAcc')
                isLoading = marketNAOInfoReducerData.isLoading
            }else{
                statusMsg = marketNAOInfoReducerData.postStatusMsg
                isLoading= false
            }

            }
        }
        // All other account expect JOINT
        else if(accountList.includes(selectedAccountType) && marketNAOInfoReducerData.isMarketNAOSubmitSuccess){
            // open bulk api Success
            // "Individual", "Joint Tenants with Rights of Survivorship", "Traditional IRA", "Roth IRA", "Rollover IRA" application
            // 'all other accounts'

            statusMsg = confirmationMessage(selectedAccountType)
            isLoading = marketNAOInfoReducerData.isSuccessLoader
        }

        setConfirmationFileState(prevState => ({...prevState, statusMsg:statusMsg , isLoading }));
        
    }
    

    useEffect(() => {
        if(marketNAOInfoReducerData.transactionRefId != ''){
    
            if((marketNAOInfoReducerData?.marketNAOSubmitErrorCode == bulkApiSuccessCode)){
                statusMessageAndLoader()

                // show survey pop up for all accounts except joint account
                if(transferAssets && !(isJointAccount)){
                   // dispatch(getStarRatingPopup())
                    //dispatch(disableHeaderLinks())
                }
            }else{
                let statusMsg = marketNAOInfoReducerData.statusMessage
                let isLoading = marketNAOInfoReducerData.isSuccessLoader
                setConfirmationFileState(prevState => ({...prevState, statusMsg, isLoading }));
            }
        }
        // else{
        //     // dispatch(disableHeaderLinks())

        //     let statusMsg = confirmationMessage('Individual')
        //     let isLoading = marketNAOInfoReducerData.isSuccessLoader
        //     setConfirmationFileState(prevState => ({...prevState, statusMsg, isLoading }));
        // }
    }, [marketNAOInfoReducerData.transactionRefId])
  
    useEffect(()=>{

        if(selectedAccountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && (marketNAOInfoReducerData.marketNAOInfo?.isPrimary == undefined || marketNAOInfoReducerData.marketNAOInfo?.isPrimary == true ) && !confirmationFileState.statusMessageAndLoaderFunCalled){
            setConfirmationFileState(prevState => ({...prevState, statusMessageAndLoaderFunCalled: true }));
            statusMessageAndLoader()
        }

        // on Bulk API error 
        if(marketNAOInfoReducerData.isMarketNAOSubmitError){
            
            let statusMsg = statusFinalMessage()
            let isLoading = marketNAOInfoReducerData.isSuccessLoader
            let additionContent = null
            if(selectedAccountType.includes(MARKET_NAO_ACCOUNT_TYPES.ROLLOVER_IRA)){
                additionContent =  <><br /><br />
                    <span>{rolloverConfirmationMsgInstructions.line1}</span><br /><br />
                    <span>{rolloverConfirmationMsgInstructions.line2}</span><br /><br />
                </>
            }

            setConfirmationFileState(prevState => ({...prevState, statusMsg, isLoading, additionContent }));
        }else if(marketNAOInfoReducerData.isPostCallError){
            // on e-consent api error
            let statusMsg = marketNAOInfoReducerData.postStatusMsg
            let isLoading = marketNAOInfoReducerData.isLoading

            setConfirmationFileState(prevState => ({...prevState, statusMsg, isLoading }));
        }
    },[marketNAOInfoReducerData, localStateData.memberDashboardData])




    const statusFinalMessage = () => {
        const message = `${finalConfirmationMessage.part1} ${selectedAccountType === 'Individual' ? 'an':  'a'} ${selectedAccountType === 'JTWROS' ? 'Joint Tenants with Rights of Survivorship' : selectedAccountType} account ${finalConfirmationMessage.part2}`

        return message
    }

    
    const callAdobeFunctionForNAORating = (action, rating) => {
        const vcmId =  SessionStorageHelper.getItemDec('vcmId');
        const isoDateInString = new Date().toISOString();
        // Before:- 2023-05-03T10:06:08.557Z
        const timeStamp = isoDateInString.replace(/[^a-zA-Z0-9 ]/g, '')

        window.digitalData.user.userInfo = {
            EncryptUserID: vcmId,
            rating: rating,
            clickAction: action,
            timestamp: timeStamp,
            event: 'NAO Survey'
        };
       // triggerClickSatelliteTrack('NAO Survey');
    }


    const handleCloseRatingPopUp = (clickAction='', rating='') => {
        callAdobeFunctionForNAORating(clickAction, rating)
       // dispatch(closeStarRatingPopup());


        if(transferAssets && !(isJointAccount)){
            setConfirmationFileState(prevState => ({...prevState, nextText: 'Proceed' }));

            //dispatch(enableHeaderLinks())

            // After 10 seconds automatically redirect to asset transfer page
            setTimeout(()=>{
                dispatch(clearMarketNAOInfo())
                clearStateAndRedirectTo('asset-transfer')
            },10000)
        }


    }

    const rolloverInstructions =() =>{
            return(
            <><div style={{margin:'30px 0px'}}><h6>Please review the Direct Rollover Instructions provided below to complete your rollover.</h6></div>
            <div className="dividendHint">
                <Row noGutters >
                    <Col sm={9}>
                        <span style={{fontWeight:"bold",color:"#486D90"}}>Marketplace Direct Rollover Instruction</span>
                    </Col>
                    <Col sm={3}>
                    <a href={`${envConfig.ENV_URL}/assets/disclosures/nao/Marketplace-Direct-Rollover-Instructions.pdf`} target="_blank" rel="noopener noreferrer" style={{textDecoration : "none"}}><img src={pdfIcon}  /><b> Download PDF</b></a>
                    </Col>
                </Row>
            </div>
            <div className="confirmFundingType">
                       <Row noGutters className="headerRowCfmPg">
                           <Col xs={12}>
                               <h1 className="pageSubHeading">{confirmFundingTypeHeading}</h1>
                           </Col>
                       </Row>
                      
                       {/* <Row>
                            <MarketPersonalCheckComponent
                            heading={MARKET_NAO_ACCOUNT_TYPES.ROLLOVER_IRA}
                            />
                       </Row> */}
            </div>
            </>
            )
    }

    return(
        <div>
            {/* <WSpinner loading={(confirmationFileState.isLoading)} /> */}

            {/* {isStarRatingPopup ? 
                <StarRatingPopup
                    show={isStarRatingPopup}
                    handleCloseRatingPopUp={handleCloseRatingPopUp}
                    applicationId = {marketNAOInfoReducerData.applicationId} 
                /> : null
            } */}

            <Container className="confirmationPage">
                <Row noGutters>
                    <Col>
                        <h1 className="pageHeading">{congratulationText}</h1>
                        {
                            <div className="confirmationMsg">
                            <p>Thanks for submitting your application. We are working on verifying your information. Please expect a call back from us. If you need help now, please contact a Representative at 1-800-235-8396.</p>
                            </div>
                        }

                    </Col>
                </Row>
            </Container>
            <CommonButtons 
            nextClick={onProceedOrOkButtonClick} 
            //backText={''}  
            nextText={confirmationFileState.nextText}
            isShowBtn={true}
            showBack={false}
            conditionalBackButton={true}
             />
        </div>
    )
}

export default ConfirmationFile;