
import {
 LOAD_ACCOUNT_REGISTRATION_API,
 RESOLVED_GET_MEMBER_BY_GUID,
 REJECTED_GET_MEMBER_BY_GUID,
 SET_E_SIGNATURE,
 RESET_PRE_REGISTRATION_INFO,
 RESOLVED_GET_PASSCODE,
 RESET_OTP_SUCCESS_FLG,
 REJECTED_GET_PASSCODE,
 OTP_VERIFICATION_SUCCESS,
 OTP_VERIFICATION_FAILED,
 SET_USER_ID,
 REJECTED_CREATE_MEMBER,
 RESOLVED_CREATE_MEMBER,
 REJECTED_EMAIL_OTP,
 RESOLVED_EMAIL_OTP,
 SET_EMAIL_OTP,
 RESOLVED_VALIDATE_EMAIL_OTP,
 REJECTED_VALIDATE_EMAIL_OTP,
 RESOLVED_IVR_REQ_STATUS,
 REJECTED_IVR_REQ_STATUS,
 SET_RESET_WELCOMPAGE_INFO,
 SET_RESET_PASSWORD_INFO,
 INVALID_MEMBER_INFO,
 SET_PHONE_NUMBER,
 SET_PHONE_CTRY_CODE,
 SET_PHONE_IS_MOBILE_PROP,
 RESET_EMAIL_OTP_FLG,
 SET_RESET_OTP_SUCCESS_FLG,
 SET_UPDATED_EMAIL,
 SET_EMAIL_ERROR,
} from '../ReduxConstants/AccountRegistrationConstants';

const initialState = {
 loading: {
   connecting: false,
   processing: false,
   thanks: false,
   welcomePageLoaded: false,
 },
};

const AccountRegistrationReducer = (state = initialState, action) => {
 switch (action.type) {
 case LOAD_ACCOUNT_REGISTRATION_API:
   return { ...state, loading: { ...state.loading, ...action.payload } };
 case RESOLVED_GET_PASSCODE:
   return {
     ...state,
     OTPSuccess: action.payload.OTPSuccess,
     loading: {
       ...state.loading,
       processing: action.payload.isLoading,
     },
     OTCMsg: action.payload.OTCMsg,
      OTP: '',
   };
 case RESOLVED_GET_MEMBER_BY_GUID:
 case REJECTED_GET_MEMBER_BY_GUID:
   return {
     ...state,
     ...action.payload,
     loading: {
       ...state.loading,
       connecting: false,
       welcomePageLoaded: true,
     },
   };
 case SET_RESET_WELCOMPAGE_INFO:
   return {
     ...state,
     welcomePageLoaded: false,
     pageName: '',
   };

 case SET_E_SIGNATURE:
   return {
     ...state,
     user: { ...state.user, ...action.payload },
     receivedEmailOTP: false,
   };
 case RESET_PRE_REGISTRATION_INFO:
   return { ...state, user: {}, accountInfo: {} };
 case REJECTED_GET_PASSCODE:
 case REJECTED_CREATE_MEMBER:
 case RESOLVED_CREATE_MEMBER:
 case RESOLVED_IVR_REQ_STATUS:
 case REJECTED_IVR_REQ_STATUS:
   return {
     ...state,
     ...action.payload,
     loading: { ...state.loading, processing: false },
   };
 case OTP_VERIFICATION_SUCCESS:
 case OTP_VERIFICATION_FAILED:
   return {
     ...state,
     loading: { ...state.loading, processing: false },
     otpValidationSuccess: action.payload.otpValidationSuccess,
     otpValidationErrorMsg: action.payload.otpValidationErrorMsg,
   };
 case SET_USER_ID:
   return {
     ...state,
     user: {
       ...state.user,
       userId: action.payload.userid,
       isUseridValid: action.payload.isUseridValid,
     },
     pwdError: '',
     pwdValidate: '',
   };
 case INVALID_MEMBER_INFO:
 case SET_RESET_OTP_SUCCESS_FLG:
 case SET_UPDATED_EMAIL:
 case SET_EMAIL_ERROR:
 case SET_EMAIL_OTP:
   return {
     ...state,
     ...action.payload,
   };
 case RESET_OTP_SUCCESS_FLG:
 case SET_RESET_PASSWORD_INFO:
 case RESET_EMAIL_OTP_FLG:
   return { ...state, ...action.payload, pwdValidate: '' };
 case RESOLVED_EMAIL_OTP:
 case REJECTED_EMAIL_OTP:
   return {
     ...state,
     loading: { ...state.loading, processing: false },
     receivedEmailOTP: action.payload.receivedEmailOTP,
     emailError: action.payload.emailError,
     emailOTPError: action.payload.emailOTPError,
      OTP: '',
   };
 case RESOLVED_VALIDATE_EMAIL_OTP:
   return {
     ...state,
     loading: { ...state.loading, processing: false },
     user: {
       ...state.user,
       email: action.payload.email,
     },
     emailOTPError: action.payload.emailOTPError,
     isEmailOTPValidated: action.payload.isEmailValidated,
   };
 case REJECTED_VALIDATE_EMAIL_OTP:
   return {
     ...state,
     loading: { ...state.loading, processing: false },
     emailOTPError: action.payload.emailOTPError,
     isEmailOTPValidated: action.payload.isEmailValidated,
     validateEmailErrorCode: action.payload.validateEmailErrorCode,
   };
 case SET_PHONE_NUMBER:
 case SET_PHONE_CTRY_CODE:
 case SET_PHONE_IS_MOBILE_PROP:
   return {
     ...state,
     user: {
       ...state.user,
       ...action.payload,
     },
   };
 default:
   return state;
 }
};

export default AccountRegistrationReducer;
