const congratulationText = 'Congratulations !!!';
const confirmationMsgP1 = 'Your';
const confirmationMsgP2 =
    'Account Opening Form has been submitted successfully and under review. You will receive a notification once it is approved. Your Account Registration Number is';
const confirmFundDetailsHeading = 'Your Fund Account Information';
const errorPage = 'Error Page';
const confirmationErrorMessage =
    'We are not able to process your order at this time, please try again later, or contact an Member Service Representative for assistance.';
const seletedFundTableCaption = 'Selected Funds';
const fundCode = 'Fund Code';
const fundName = 'Fund Name';
const fundType = 'Fund Type';
const fundNumber = 'Fund Account Number';
const confirmFundingTypeHeading = 'Funding Type';
const specimen = 'Specimen';
const displayNoneStyle = { display: 'none' };
const fontColor = 'rgba(73, 73, 74, 1)';
const specimentTitleStyle = {
    font: '800 26px/30px yorkten-slab-normal, sans-serif',
    color: fontColor,
};
const asensusRedirectUrl =
    'https://usaa529.cat.acs529.com/usaatpl/auth/sessionCreate/collectVictorySSOCredentials.cs?encPayload=';
const fundList = [
    {
        fundName: 'USAA Science & Technology Adviser',
        maxInvestment: '4000',
        fundNumber: '54',
        max52W: '4000',
        monthlyInvestment: '0',
        risk: 'High',
        changeInNav: '14.3',
        symbol: 'USTCX',
        initialInvestment: '3000',
        min52W: '3000',
        lastNav: '13',
    },
    {
        fundName: 'USAA Growth & Income Adviser',
        maxInvestment: '3000',
        max52W: '5000',
        monthlyInvestment: '0',
        worthAmount: '4000.56',
        symbol: 'USGIX',
        initialInvestment: '1000',
        min52W: '3000',
        fundNumber: '565',
        risk: 'Low',
        changeInNav: '14.3',
        lastNav: '13',
        totalShares: '25.68',
    },
    {
        fundName: 'USAA Intermediate-Term Bond Adviser',
        maxInvestment: '5000',
        fundNumber: '30',
        max52W: '5000',
        monthlyInvestment: '50',
        risk: 'High',
        changeInNav: '14.3',
        symbol: 'UITBX',
        initialInvestment: '3000',
        min52W: '3000',
        lastNav: '13',
    },
    {
        fundName: 'USAA Short-Term Bond Adviser',
        maxInvestment: '7000',
        fundNumber: '42',
        max52W: '7000',
        monthlyInvestment: '0',
        risk: 'Medium',
        changeInNav: '14.3',
        symbol: 'UASBX',
        initialInvestment: '2000',
        min52W: '2000',
        lastNav: '13',
    },
    {
        fundName: 'USAA Growth & Income Adviser',
        maxInvestment: '3000',
        fundNumber: '33',
        max52W: '5000',
        monthlyInvestment: '0',
        risk: 'Low',
        changeInNav: '14.3',
        symbol: 'USGIX',
        initialInvestment: '1000',
        min52W: '3000',
        lastNav: '13',
    },
];
const printStyle = `
.row {
    display: flex;
}
div[class^="col"] {
    position: relative
}
.textAlignRight button.print {
    display: none;
}
.pageSubHeading {
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
    margin-bottom: 15px;
}
.headerRowCfmPg,
.sectionHeader {
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 40px;
}
.sectionHeader {
    margin-bottom: 30px;
}
.confirmationPage .dividendHint {
    padding: 22px 30px;
    margin-bottom: 54px;
    border: 1px solid rgba(210, 210, 210, 1);
    position: relative;
    font: 600 16px/22px benton-sans;
    color: #49494A;
    opacity: 1;
}
.confirmationPage .confirmFundingType .dividendHint:not(.onlineConfirmationcard) {
    border: 2px solid rgba(140, 191, 38, 1);
    outline: 0;
    background-color: rgba(250, 250, 250, 1);
}
.confirmationPage .confirmFundingType .dividendHint.onlineConfirmationcard {
    background-color: rgba(255, 255, 255, 1);
    padding: 25px 30px 14px;
}
.confirmationPage .confirmFundingType .dividendHint.onlineConfirmationcard p{
    font:  600 16px/22px benton-sans, sans-serif;
}
.confirmationPage .dividendHint::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 35px solid transparent;
    border-left: 35px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}
.confirmationPage .dividendHint.header::before {
    border-left-color: rgba(72, 109, 144, 1);
}
.confirmationPage .itemWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px 0 30px;
    border-left: 1px solid rgba(206, 206, 206, 1);
}
.confirmationPage .itemWrapper:first-child {
    border-left: none;
    max-width: 259px;
    border-right: 1px solid rgba(210, 210, 210, 1);
}
.confirmationPage .itemWrapper:nth-child(2) {
    max-width: 182px;
}
.confirmationPage .itemWrapper .labelItem {
    font: 600 14px/20px benton-sans, sans-serif;
    color: rgba(86, 86, 90, 1);
}
.confirmationPage .itemWrapper .valueItem {
    font: 600 16px/25px benton-sans, sans-serif;
    color: rgba(72, 109, 144, 1);
}
.confirmationPage .beneDetailsTable {
    margin-bottom: 60px;
}
.confirmationPage .dividendHint.fundingTypecard {
    padding: 41px 38px 50px 50px;
}
.confirmationPage .dividendHint.onlineTransferTypecard {
    padding: 41px 54px 0px 50px;
}

.confirmationPage .dividendHint.onlineConfirmationcard {
    max-width: 1013px;
    padding: 25px 93px 24px 95px;
    bottom:0;
    margin: 19px 15px 26px 15px;
}

.onlineTransferLabel {
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: 'rgba(73, 73, 74, 1)';
    opacity: 1;
    margin-bottom: 30px;
}

.onlineTransferValue {
    font: 500 16px/24px benton-sans, sans-serif;
    letter-spacing: 0;
    color:'rgba(73, 73, 74, 1)';
    opacity: 1;
    margin-bottom: 30px;
}

.onlineTransferHintTextStyle {
    text-align: left;
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color:'rgba(73, 73, 74, 1)';
    opacity: 1;
    margin-bottom: 0px;
}
.confirmationPage .specimenCardWrapper {
    padding-left: 20px;
}
.confirmationPage .wireTransferCard .specimenCardWrapper {
     padding-left: 10px;
}
.confirmationPage .specimenCardWrapper.checkSpecimen {
    padding-left: 0;
    max-width: 670px;
}
.confirmationPage .sectionHeading {
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    margin-bottom: 15px;
    color: rgba(72, 109, 144, 1);
}
.confirmationPage .specimenCard .leftCornerCard {
    border-left-color: rgba(139, 193, 5, 1);
}
.confirmationPage .specimenCard {
    padding: 30px 30px 38px;
    margin-bottom: 0;
}

.confirmationPage .wireTransferCard {
    padding: 41px 35px 41px 40px;
}

.confirmationPage .specimenCard .card-body {
    padding: 0;
    padding-bottom: 21px;
}
.confirmationPage .specimenCard .card-title {
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    display: inline-block;
}
.confirmationPage .specimenCard .card-img {
    margin-bottom: 0;
}

.new-line {
    white-space: pre-line;
  }
.confirmationPage .confirmFundingType .dividendHint p {
    font: 500 14px/20px benton-sans;
    margin-bottom: 5px;
}
.confirmationPage .wireTransferCard p {
    margin-bottom: 25px;
}
.confirmationPage .confirmFundingType .dividendHint .boldPara {
    font-weight: 600;
}
.confirmationPage .fundTypeSpecimenWrapper {
    margin: 30px 0;
    align-items: flex-end;
}
`;
export default {
    asensusRedirectUrl,
    congratulationText,
    confirmationMsgP1,
    confirmationMsgP2,
    confirmFundDetailsHeading,
    seletedFundTableCaption,
    fundCode,
    fundName,
    fundType,
    fundNumber,
    errorPage,
    confirmationErrorMessage,
    confirmFundingTypeHeading,
    fundList,
    specimen,
    displayNoneStyle,
    specimentTitleStyle,
    personalCheckMsg1:
        'Please make the check payable to USAA Mutual Funds and include the account number of the mutual fund in the "Memo Section" ',
    personalCheckMsg2: 'Note: We do not accept 3rd party checks, traveler’s checks and money orders..',
    personalCheckMsg3:
        'The transaction will be processed only after receiving the check. Please wait for 3 to 5 business days for completion of your transaction.', // 3-5 changed to 3 to 5 for accessibility
    headerValues: [
        {
            key: 'Account Registration Number',
            value: 'XXXX-XXXX-XXXX',
        },
        {
            key: 'Account Type',
            value: 'Retirement',
        },
        {
            key: 'Account Holder Name',
            value: 'John Doe',
        },
    ],
    isPersonalCheck: 'pers_check',
    isWireTransfer: 'wire_transfer',
    isOnlineTransfer: 'add_bnk',
    personalCheckHeading: 'Personal Check',
    wireTransferHeading: 'Wire Transfer',
    onlineTransferHeading: 'Online Banking',
    wireTransferMsg1: ' The transaction will be processed on confirmation of wire transfer details.',
    wireTransferMsg2: ' Please take Printout and mail to the address given on the right to initiate "Wire Transfer"',
    wireTransferMsg3: 'Please wait for 3 to 5 business days for completion of your transaction.', // 3-5 changed to 3 to 5 for accessibility
    wireTransferMailingAddressHeading: 'Mailing Address',
    wireTransferMailingAddressText:
        ' Mellon Trust of New England \n 1 Boston Place, Boston, Massachusetts 02108 \n ABA # 011001234 ',
    wireTransferCardTextStyle: {
        font: '500 16px/20px benton-sans, sans-serif',
        color: 'fontColor',
        textAlign: 'left',
        margin: 0,
    },
    wireTransferCardBodyStyle: {
        paddingBottom: 0,
    },
    wireTransferTitleStyle: {
        font: '600 16px/20px benton-sans, sans-serif',
        color: 'fontColor',
        textAlign: 'left',
        border: 0,
        marginBottom: 11,
    },
    onlineTransferHintText:
        'This is sample content paragraph',
    bankAccountTypeLabel: 'Funding Source',
    bankAccountDetails: [
        { label: 'Name of Financial Institution', name: 'financialInstitutionName', value: 'WELLS FARGO' },
        { label: 'Name of Account Owner(s)', name: 'accountOwner', value: 'owner' },
        { label: 'Transit Routing Number', name: 'transitRoutingNumber', value: '123456789' },
        { label: 'Account Number', name: 'accountNumber', value: '1234567890' },
    ],
    okText: 'Ok',
    okStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 15,
    },
    okDivStyle: {
        marginBottom: 30,
        background: '#FAFAFA 0% 0% no-repeat padding-box',
        border: '1px solid #EFEDED',
        display: 'flex',
        alignItems: 'center',
        height: 90,
        boxSizing: 'border-box',
    },
    printStyle,
};
