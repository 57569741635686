import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import Config from 'env/Config';
import { post } from './Services';

const InterestedPartiesApiEvents = () => {
    ApiEventHub.on(ActionTypes.ADD_INTERESTED_PARTY, data => {
        post(
            Config.addintrestedparties,
            data.payload,
            data.endPointURL.addintrestedparties,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.UPDATE_INTERESTED_PARTY, data => {
        post(
            Config.updateintrestedparties,
            data.payload,
            data.endPointURL.updateintrestedparties,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_INTERESTED_PARTY, data => {
        post(
            Config.getintrestedparties,
            data.payload,
            data.endPointURL.getintrestedparties,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DELETE_INTERESTED_PARTY, data => {
        post(
            Config.deleteintrestedparties,
            data.payload,
            data.endPointURL.deleteintrestedparties,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default InterestedPartiesApiEvents;
