import React from 'react';
import styled from 'styled-components';
import Box from '../Box';

// To remove the default button style
const StyledButton = styled(Box)`
    padding: 0;
    border: none;
    background: none;
`;

const Button = React.forwardRef((props, ref) => <StyledButton ref={ref} as="button" {...props} />);

export default Button;
