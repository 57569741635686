const defaultColor = 'rgb(73, 73, 74)';

const styles = {
    header: {
        position: 'relative',
        marginBottom: 15,
        font: '700 18px/25px benton-sans, sans-serif',
        color: defaultColor,
        height: 22,
    },
    para1: {
        margin: '0 auto',
        marginBottom: 30,
        font: '500 16px/20px benton-sans, sans-serif',
        color: defaultColor,
        maxWidth: 350,
        height: 39,
    },
    para2: {
        margin: '0 auto',
        marginBottom: 30,
        font: '500 16px/20px benton-sans, sans-serif',
        color: defaultColor,
        maxWidth: 350,
        height: 19,
    },
    hr: {
        borderBottom: '1px solid #D2D2D2',
        margin: '30px 0',
    },
    corner: {
        width: '0',
        height: '0',
        borderBottom: '35px solid transparent',
        borderRight: '35px solid #8BC105',
        position: 'absolute',
        top: '0',
        right: '0',
    },

    leftCorner: {
        width: '0',
        height: '0',
        borderBottom: '35px solid transparent',
        borderLeft: '35px solid #8BC105',
        position: 'absolute',
        top: '0',
        left: '0',
    },

    cardContainer: {
        padding: '25px !important',
    },

    // subHeader: {
    //     padding: '25px',
    // },
};

export default styles;
