import { connect } from "react-redux";
import routeConstants from 'routeConstants';
import { getMemberProfileInformations, clearExistingActions, closeFinancialRemainder, closeEmptyInfoNotification } from "shared/Actions/ProfileSettingsAction";
import { clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import {analyzeClickEvent} from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import BasicInfoComponent from "./BasicInformationComponent";
import CONST from "./constants";

const successMessage = {
    [routeConstants.personalInformation]: CONST.PERSONAL_INFO_SUCCESS,
    [routeConstants.financialInfo]: CONST.FINANCIAL_INFO_SUCCESS,
    [routeConstants.empInfo]: CONST.EMPLOYMENT_INFO_SUCCESS,
    [routeConstants.militaryInfo]: CONST.MILITARY_INFO_SUCCESS,
}

const getSuccessMessage = (props) => {
    const { location } = props;
    const { state } = location || {};
    const { from='' } = state || {};
    return successMessage[`${from}`] || CONST.PERSONAL_INFO_SUCCESS;
}

export const mapStateToProps = (state, props) => {
    const { profileInformationData, profileRelationshipData, memberDashboardData } = state;
    const { isError, isSuccess } = profileInformationData;
    const { isError: isRelationError, isSuccess: isRelationSuccess } = profileRelationshipData;

    const notify = isError || isRelationError || isSuccess || isRelationSuccess;

    let message = null;
    if(isSuccess){
        message = getSuccessMessage(props);
    }

    if(isRelationSuccess){
        message = CONST.RELATIONSHIP_INFO_SUCCESS;
    }

    return{
        profileInformationData,
        profileRelationshipData,
        memberDashboardData,
        notify,
        message: {
            message, info: null
        }
    }
};

const mapDispatchToProps = {
    getMemberProfileInformations,
    clearExistingActions,
    closeFinancialRemainder,
    clearRelationActions,
    analyzeClickEvent,
    closeEmptyInfoNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(BasicInfoComponent));
