import { get } from 'lodash';
import { connect } from 'react-redux';
import OTCDetails from './OneTimeCodeListingPage';
import {
  getPassCode,
  resetOTPFlag,
  resetEmailOTPFlag,
} from './OneTimeListingPage.actions';

const mapStateToProps = (state) => ({
  user: get(state, 'AccountRegistration.user', {}),
  accountInfo: state.AccountRegistration.accountInfo || {},
  OTPSuccess: state.AccountRegistration.OTPSuccess || '',
  OTCMsg: state.AccountRegistration.OTCMsg || '',
  ivrStatus: state.AccountRegistration.ivrStatus || '',
});

const mapDispatchToProps = {
  getVerifyPasscode: getPassCode,
  resetFlg: resetOTPFlag,
  resetEmailOTP: resetEmailOTPFlag,
};

export default connect(mapStateToProps, mapDispatchToProps)(OTCDetails);
