const backTo = 'Back To Investment Selection';
const summary = 'Summary';
const quickFacts = 'Fees';
const performance = 'Performance';

export default {
    backTo,
    summary,
    quickFacts,
    performance,
};
