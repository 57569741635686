/**
 * ADP Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to Edit and Submit Account Delivery Preferences
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [16/04/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason Code Refactoring and added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Accordion, Card, Col } from 'react-bootstrap';
import AccGlobalConstants from '../AccountManagementConstants';
import cancelIcon from 'assets/icon-delete-red.svg';
import styles from '../PersonalInfo/styles';
import {
	WBreadCrumb,
	WSpinner,
	WSaveModal,
	WIcon,
	AccountSelectedInfoCard,
	WRadioList
} from '../../../common';
import CONSTANTS from "./Consts";
import './EditGeneralAccountPreferences.css';
import {
	StyledRadio,
	Hint,
} from './styles'


const { assignObj } = AccGlobalConstants


const ACCOUNT_PREFERENCE_ROUTE = '/accountPreference';

export default class EditGeneralAccountPreferencesComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			dlvMethStmt: 'P',
			dlvMethConfirms: 'P',
			dlvMethLit: 'P',
			dlvMethAcctUpdNotify: 'P',
			dlvMethProxies: 'P',
			dlvMethTaxForms: 'P',
			allDocuments: 'allDocumentsNo',
			errorMsg: {},
			isBusinessUser: false,
		};
		this.err = '';
	}

	componentDidMount() {
		const { history: { push }, accountPreferenceData: { editAccount }, location } = this.props

		if (editAccount && Object.keys(editAccount).length) {
			this.setState({
				...editAccount.deliveryPreferences,
				isBusinessUser: location.state.isBusinessUser
			})
		} else {
			push(ACCOUNT_PREFERENCE_ROUTE)
		}
	}

	handleRadioChange = key => e => {
		let selectedValue = ''
		let value = ''
		if (e.target.id.includes('E')) {
			selectedValue = key
		}
		if (e.target.value.includes('Online')) {
			value = 'E'
		} else {
			value = 'P'
		}
		if (key === "dlvMethStmt" || key === "dlvMethConfirms" || key === "dlvMethAcctUpdNotify") {
			this.setState({ ["dlvMethStmt"]: value, selectedPreference: selectedValue })
			this.setState({ ["dlvMethConfirms"]: value, selectedPreference: selectedValue })
			this.setState({ ["dlvMethAcctUpdNotify"]: value, selectedPreference: selectedValue })
		} else {
			this.setState({ [key]: value, selectedPreference: selectedValue })
		}
	}

	closeTooltip = (e) => {
		e.preventDefault();
		this.setState({ selectedPreference: '' });
	}

	cardHeaderClick = (e) => {
		e.preventDefault();
		e.target.classList.toggle('expand');
		this.setState(prevState => {
			const { deliveryAccordionExpanded } = prevState;
			return {
				deliveryAccordionExpanded: deliveryAccordionExpanded === 'true' ? 'false' : 'true',
			};
		});
	}

	getDeliveryOptionsFromInternalState = () => {
		const { dlvMethStmt, dlvMethConfirms, dlvMethLit, dlvMethAcctUpdNotify, dlvMethProxies, dlvMethTaxForms, groupId } = this.state;
		return [{
			"deliveryPreferences": {
				"groupId": groupId,
				"dlvMethStmt": dlvMethStmt,
				"dlvMethConfirms": dlvMethConfirms,
				"dlvMethLit": dlvMethLit,
				"dlvMethAcctUpdNotify": dlvMethAcctUpdNotify,
				"dlvMethProxies": dlvMethProxies,
				"dlvMethTaxForms": dlvMethTaxForms
			}
		}]
	}

	handleApply = () => {
		const { saveOpenedAccountsPreferences, history: { push } } = this.props;

		saveOpenedAccountsPreferences(this.getDeliveryOptionsFromInternalState())
	}

	handleCancel = () => {
		const { history: { push } } = this.props
		push(ACCOUNT_PREFERENCE_ROUTE)
	}

	renderAccountInfo = () => {
		const { accountPreferenceData: { editAccount } } = this.props;
		return (
			<AccountSelectedInfoCard accDetails={{ accountName: editAccount.accName, accountNumber: editAccount.accNumber, accountType: editAccount.accountType, currentAccountBalance: editAccount.balance, address: editAccount.address }} shouldShowBalance={false} />
		);
	}

	renderDeliveryPreferences = () => {
		const { selectedPreference, isBusinessUser = false } = this.state;
		const data = this.state;
		const radioItems = CONSTANTS.deliveryValues.value.map(item => {
			return {
				...item,
				disabled: isBusinessUser && 'Online' === item.key
			}
		});
		return (
			<Accordion defaultActiveKey="0" id="delivery">
				<Card>
					<Card.Header>
						<Accordion.Toggle
							variant="link"
							eventKey="0"
							className="expand accordionHeader buttonAccordion"
							onClick={this.cardHeaderClick}
							// aria-expanded={deliveryAccordionExpanded}
							aria-controls="deliveryPreferencesCardBody"
							id='deliveryPreferences'
							style={assignObj({ cursor: 'pointer' })}
							tabIndex='0'
						>
							{CONSTANTS.deliveryHeading}
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0" id="deliveryPreferencesCardBody" role="region" aria-labelledby="deliveryPreferences">
						<Card.Body>
							<Row className="rowHeader"><span>{CONSTANTS.deliveryQuestion}</span></Row>
							{CONSTANTS.deliveryPreferences.map(({ key, value }, index) =>
								<StyledRadio key={key.concat(index)} role="radiogroup" aria-labelledby={key}>
									<label htmlFor={key}>
										{value}
									</label>
									<WRadioList
										id={key}
										radioFor={key}
										radioItems={radioItems}
										onChange={this.handleRadioChange(key)}
										selectedValue={data[key.toString()] == 'P' ? key + 'Paper' : key + 'Online'}
										radiostyles={CONSTANTS.radiostyles}
										radioTextStyles={CONSTANTS.radioTextStyles}
									/>
									{(selectedPreference === key) && (
										<Hint>
											<div>
												<span>{CONSTANTS.deliveryHint}</span>
											</div>
											<WIcon
												arialabel={assignObj(`Close Button for Online hint of ${value}`)}
												icon={faTimes}
												// style={iconStyle}
												alt="Close Button"
												fontawesomestyle={CONSTANTS.fontawesomestyle}
												onClick={this.closeTooltip} />
										</Hint>)}
								</StyledRadio>)}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}

	renderPage = () => {
		const { showSaveModal} = this.state;
		const { cancelText } = CONSTANTS;
		const { accountPreferenceData: { editAccount } } = this.props;

		return (
			<div className="editDeliveryPreferencesWrapper">
				<div className="adp-wrapper">
					<div className="container">
						<WBreadCrumb activeCrumb={CONSTANTS.activeEditUrl} breadCrumbItems={CONSTANTS.editPreferencesUrls} />
						<h1 className="adp-page-heading">{editAccount?.accountType}</h1>
						{editAccount && this.renderAccountInfo()}
						<WSaveModal show={showSaveModal} modalClick={this.handleModal} />
						<div className="esign-cards" >
							{this.renderDeliveryPreferences()}
						</div>
						<button
							type="button"
							className="prefCancelBtn"
							onClick={this.handleCancel}
						>
							<WIcon src={cancelIcon} fontawesomestyle={styles.iconImgStyle} alt="" />{cancelText}</button>
					</div>
				</div>
				<div style={CONSTANTS.applyWrapperStyle}>
					<div className="container">
						<Row className="check-content" noGutters style={assignObj({ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' })}>
							<Col xs={2} style={assignObj({ textAlign: 'right', padding: 0 })}>
								
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { accountPreferenceData: { isSuccess, isError, isLoading }, history: { push } } = this.props;
		if (isSuccess) {
			push(ACCOUNT_PREFERENCE_ROUTE)
		}
		return (
			<div className="MainContainer">
				{<WSpinner loading={isLoading} />}
				{!isError && this.renderPage()}
			</div>
		);
	}
}

EditGeneralAccountPreferencesComponent.propTypes = {
	saveOpenedAccountsPreferencesForAllAccounts: PropTypes.func.isRequired,
	saveOpenedAccountsPreferences: PropTypes.func.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	location: PropTypes.instanceOf(Object).isRequired,
};

EditGeneralAccountPreferencesComponent.defaultProps = {};
