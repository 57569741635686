import * as ActionTypes from "../ReduxConstants/RelationConstants";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    personalInformation: {
        firstName:'',
        familyRelationShip:'',
        lastName:'',
        middleName:'',
        preFix:'',
        suFix:'',
        dateOfBirth:'',
        gender:'',
        maritalStatus:'',
        citizen:'',
        countryOfCitizen:'',
        proofOfCitizen:'',
        passportNumber:'',
        passportAuthority:'',
        residentNumber:'',
        expirationDate:'',
        personHaveSSN:'',
        ssn:'',
    },
    relationPhoneInformation: {
        countryCode:'',
        phoneNumber:'',
        isMobile:false,
        secondaryCountryCode:'',
        secondaryPhoneNumber:'',
        secondaryIsMobile:false,
        isPrimary: false,
    },
    relationEmailInformation: {
        primaryEmail:'',
        secondaryEmail:'',
    },
    relationshipInformation: {},
    familyRelationShipInfo : [],
    hasRelationshipInfo: false,
    familyMemberIndex: -1,
    mailingAddressType: '',
    physicalAddressType: '',
    isPhysicalSameAsMailingAddr: true,
    newRelationShipInformation: {
        personalInformation: {},
        phoneInformation: [],
        emailInformation: {
            primaryEmail:'',
            secondaryEmail:'',
        },
        addressInformation: []
    },
    ispersonalinformationadded: false,
    isphoneinformationadded: false,
    isemailinformationadded: false,
    isaddressinformationadded: false,
    isDeleteAPICalled: false,
};

const updateAddress = (familyMemberInformation, addressUpdate) => {
    const familyMemberInfo = familyMemberInformation;
    if(!familyMemberInfo.addressInformation) familyMemberInfo.addressInformation = [];
    addressUpdate.forEach((address)=>{
        const {isMailingAddress} = address;
        const addressIndex = familyMemberInfo.addressInformation.findIndex(item => item.isMailingAddress === isMailingAddress);
        if(addressIndex !== -1){
            if(address.zip){
                familyMemberInfo.addressInformation.splice(addressIndex,1,{...address});
            }else {
                familyMemberInfo.addressInformation.splice(addressIndex,1);
            }
        }else if(address.zip) familyMemberInfo.addressInformation.push({...address});
    });
};

const updatePhone = (familyMemberInformation, phoneUpdate) => {
    const familyMemberInfo = familyMemberInformation;
    if(!familyMemberInfo.phoneInformation) familyMemberInfo.phoneInformation = [];
    phoneUpdate.forEach((phone)=>{
        const {isPrimary} = phone;
        const phoneIndex = familyMemberInfo.phoneInformation.findIndex(item => item.isPrimary === isPrimary);
        if(phoneIndex !== -1){
            if(phone.phoneNumber){
                familyMemberInfo.phoneInformation.splice(phoneIndex,1,{...phone});
            }else {
                familyMemberInfo.phoneInformation.splice(phoneIndex,1);
            }
        }else if(phone.phoneNumber) familyMemberInfo.phoneInformation.push({...phone});
    });
};

const updateEmail = (familyMemberInformation, primaryEmail='default', secondaryEmail='default') => {
    const familyMemberInfo = familyMemberInformation;
    if(!familyMemberInfo.emailInformation) familyMemberInfo.emailInformation = {};
    if(primaryEmail !=='default') familyMemberInfo.emailInformation.primaryEmail = primaryEmail;
    if(secondaryEmail !=='default') familyMemberInfo.emailInformation.secondaryEmail = secondaryEmail;
};

const updatePersonalInfo = (familyMemberInformation, personalInfo) => {
    const familyMemberInfo = familyMemberInformation;
    Object.keys(personalInfo).forEach((key)=>{
        familyMemberInfo[`${key}`] = personalInfo[`${key}`];
    });
};

function initialReducer(state = initialState, action){
    switch(action.type){
        case ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION:
        case ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.RELATION_REMOVE_EXISTING_VALUES: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                isDeleteAPICalled: false,
            };
        }
        case ActionTypes.RELATION_SET_INITIAL_STATE: {
            const { relationshipInformation, familyRelationShipInfo, hasRelationshipInfo} = state;
            return {
                ...initialState,
                relationshipInformation,
                familyRelationShipInfo,
                hasRelationshipInfo,
            };
        }
        case ActionTypes.NEW_RELATION_REMOVE_EXISTING_VALUES: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                newRelationShipInformation: {},
                ispersonalinformationadded: false,
                isphoneinformationadded: false,
                isemailinformationadded: false,
                isaddressinformationadded: false,
            };
        }
        case ActionTypes.PROFILE_RELATIONSHIP_SAVE_PERSONAL_INFORMATION: {
            const { personalInformation } = action;
            const { newRelationShipInformation } = state;
            newRelationShipInformation.personalInformation = personalInformation;
            return {
                ...state,
                isLoading: false,
                isError: false,
                newRelationShipInformation,
                ispersonalinformationadded: true,
            };
        }
        case ActionTypes.PROFILE_RELATIONSHIP_SAVE_PHONE_INFORMATION: {
            const { phoneInformation, isprimary, isdelete } = action;
            const { newRelationShipInformation } = state;
            if(isprimary){
                if(!isdelete){
                    const index = newRelationShipInformation.phoneInformation.findIndex(obj => obj.isPrimary);
                    if(index !== -1){
                        newRelationShipInformation.phoneInformation[`${index}`] = phoneInformation;
                    }else{
                        newRelationShipInformation.phoneInformation.push(phoneInformation);
                    }
                }else{
                    const phone = newRelationShipInformation.phoneInformation.filter(obj => !obj.isPrimary);
                    newRelationShipInformation.phoneInformation = phone;
                }                                
            }else if(!isdelete){
                const index = newRelationShipInformation.phoneInformation.findIndex(obj => !obj.isPrimary);
                if(index !== -1){
                    newRelationShipInformation.phoneInformation[`${index}`] = phoneInformation;
                }else{
                    newRelationShipInformation.phoneInformation.push(phoneInformation);
                }
            }else{
                const phone = newRelationShipInformation.phoneInformation.filter(obj => obj.isPrimary);
                newRelationShipInformation.phoneInformation = phone;
            }   
            return {
                ...state,
                isLoading: false,
                isError: false,
                newRelationShipInformation,
                isphoneinformationadded: (newRelationShipInformation.phoneInformation.length > 0)
            };
        }
        case ActionTypes.PROFILE_RELATIONSHIP_SAVE_EMAIL_INFORMATION: {
            const { emailInformation, isprimary, isdelete } = action;
            const { newRelationShipInformation } = state;
            if(isprimary){                
                newRelationShipInformation.emailInformation.primaryEmail = isdelete ? '' : emailInformation;
            }else{
                newRelationShipInformation.emailInformation.secondaryEmail = isdelete ? '' : emailInformation;
            } 
            return {
                ...state,
                isLoading: false,
                isError: false,
                newRelationShipInformation,
                isemailinformationadded: (newRelationShipInformation.emailInformation.primaryEmail !== '' || newRelationShipInformation.emailInformation.secondaryEmail !== ''),
            };
        }
        case ActionTypes.PROFILE_RELATIONSHIP_SAVE_ADDRESS_INFORMATION: {
            const { addressInformation, isphysical, isdelete } = action;
            const { newRelationShipInformation } = state;
            if(isphysical){
                if(!isdelete){
                    const index = newRelationShipInformation.addressInformation.findIndex(obj => obj.isPhysicalAddress);
                    if(index !== -1){
                        newRelationShipInformation.addressInformation[`${index}`] = addressInformation;
                    }else{
                        newRelationShipInformation.addressInformation.push(addressInformation);
                    } 
                }else{
                    const address = newRelationShipInformation.addressInformation.filter(obj => obj.isMailingAddress);
                    newRelationShipInformation.addressInformation = address;
                }                               
            }else if(!isdelete){
                const index = newRelationShipInformation.addressInformation.findIndex(obj => obj.isMailingAddress);
                if(index !== -1){
                    newRelationShipInformation.addressInformation[`${index}`] = addressInformation;
                }else{
                    newRelationShipInformation.addressInformation.push(addressInformation);
                }
            }else{
                const address = newRelationShipInformation.addressInformation.filter(obj => obj.isPhysicalAddress);
                newRelationShipInformation.addressInformation = address;
            }   
            return {
                ...state,
                isLoading: false,
                isError: false,
                newRelationShipInformation,
                isaddressinformationadded: (newRelationShipInformation.addressInformation.length > 0) 
            };
        }
        case ActionTypes.SET_RELATIONSHIP_FIRST_NAME: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.firstName = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_TO_FAMILY_MEMBER: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.familyRelationShip = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_LAST_NAME: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.lastName = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_MIDDLE_NAME: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.middleName = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PREFIX: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.preFix = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SUFIX: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.suFix = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_DATEOFBIRTH: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.dateOfBirth = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_GENDER: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.gender = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_MARITAL_STATUS: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.maritalStatus = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_US_CITIZEN: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.citizen = value;
            if(value !== 'Non U.S') {
                relationInfo.countryOfCitizen ='';
            }
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_COUNTRY_CITIZENSHIP: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.countryOfCitizen = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SSN: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.ssn = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_TO_FAMILY_MEMBER_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.familyRelatioshipError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_FIRST_NAME_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.firstNameError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_LAST_NAME_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.lastNameError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_DATEOFBIRTH_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.dateOfBirthError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_GENDER_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.genderError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_MARITAL_STATUS_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.maritalStatusError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_CITIZEN_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.citizenShipError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_COUNTRY_CITIZENSHIP_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.citizenShipCountryError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SSN_ERROR: {
            const { value } = action;
            const { personalInformation } = state;
            const relationInfo = {...personalInformation};
            relationInfo.SSNError = value;
            return {
                ...state,
                personalInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PHONE_COUNTRY: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.countryCode = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PHONE_NUMBER: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.phoneNumber = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PHONE_ISMOBILE: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.isMobile = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.secondaryCountryCode = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.secondaryPhoneNumber = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_ISMOBILE: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.secondaryIsMobile = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PHONE_COUNTRY_ERROR: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.phoneCountryError = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PHONE_NUMBER_ERROR: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.phoneNumberError = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY_ERROR: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.SecondaryPhoneCountryError = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER_ERROR: {
            const { value } = action;
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.SecondaryPhoneNumberError = value;
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PRIMARY_EMAIL: {
            const { value } = action;
            const { relationEmailInformation } = state;
            const relationInfo = {...relationEmailInformation};
            relationInfo.primaryEmail = value;
            return {
                ...state,
                relationEmailInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_PRIMARY_EMAIL_ERROR: {
            const { value } = action;
            const { relationEmailInformation } = state;
            const relationInfo = {...relationEmailInformation};
            relationInfo.primaryEmailError = value;
            return {
                ...state,
                relationEmailInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_EMAIL: {
            const { value } = action;
            const { relationEmailInformation } = state;
            const relationInfo = {...relationEmailInformation};
            relationInfo.secondaryEmail = value;
            return {
                ...state,
                relationEmailInformation: relationInfo,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_SECONDARY_EMAILERROR: {
            const { value } = action;
            const { relationEmailInformation } = state;
            const relationInfo = {...relationEmailInformation};
            relationInfo.secondaryEmailError = value;
            return {
                ...state,
                relationEmailInformation: relationInfo,
            };
        }
        case ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS: {
            const { response } = action;
            const { result:relationshipInformation={} } = response || {};
            const { memberAssociationDetails } = relationshipInformation || {};
            const { Family } = memberAssociationDetails || {};
            const familyRelationShipInfo = Family || []; 
            return {
                ...state,
                relationshipInformation,
                familyRelationShipInfo,
                isLoading: false,
                hasRelationshipInfo: true,
            };
        }
        case ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS: {
            const { familyRelationShipInfo } = state;
            const { isDeleteAPICalled, response } = action;
            const {result} = response;
            const { addressUpdate, phoneUpdate, primaryEmail='default', secondaryEmail='default', ...personalInfo } = result;
            const { relationshipId } = personalInfo;
            const newFamilyRelationShipInfo = [...familyRelationShipInfo];
            const familyMemberIndex = newFamilyRelationShipInfo.findIndex((item)=>item.relationshipId === relationshipId);
            
            const familyMemberInfo = familyMemberIndex !== -1 ? newFamilyRelationShipInfo[+familyMemberIndex] : {};
            
            updateEmail(familyMemberInfo, primaryEmail, secondaryEmail);
            
            if(addressUpdate){
                updateAddress(familyMemberInfo, addressUpdate);
            }
            if(phoneUpdate){
                updatePhone(familyMemberInfo, phoneUpdate);
            }
            if(personalInfo){
                updatePersonalInfo(familyMemberInfo, personalInfo);
            }
            
            if(familyMemberIndex === -1) newFamilyRelationShipInfo.push(familyMemberInfo);
            
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                familyRelationShipInfo: newFamilyRelationShipInfo,
                isDeleteAPICalled,
            };
        }
        case ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.response,
            };
        }
        case ActionTypes.SET_FAMILY_MEMBER_INDEX: {
            const { value } = action;
            const familyMemberIndex = value;
            return {
                ...state,
                familyMemberIndex,
            };
        }
        case ActionTypes.SET_RELATIONSHIP_ADDRESS_TYPE: {
            const { value, addressType } = action;
            return {
                ...state,
                [`${addressType}`]: value ,
            };
        }
        case ActionTypes.SET_IS_SAME_AS_MAILING_ADDRESS: {
            const { value } = action;
            return {
                ...state,
                isPhysicalSameAsMailingAddr: value ,
            };
        }
        case ActionTypes.SET_PHONE_EDIT_DETAILS: {
            const { value } = action;
            const {
                phoneNumber='',
                phoneCountryCode='',
                isMobile='',
                isPrimary= false,
            } = value || {};
            const { relationPhoneInformation } = state;
            const relationInfo = {...relationPhoneInformation};
            relationInfo.countryCode = phoneCountryCode;
            relationInfo.phoneNumber = phoneNumber;
            relationInfo.isMobile = isMobile;
            relationInfo.isPrimary = isPrimary;
            
            return {
                ...state,
                relationPhoneInformation: relationInfo,
            };
        }
        default:
            return state;
    }
}

export default initialReducer;