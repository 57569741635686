import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Box from '../Box';
import MandatoryText from '../MandatoryText';

export const PageHeading = styled(Box)`
    font-size: 30px;
    line-height: 36px;
    float: left;
    color: #486D90;
    border-bottom: 2px solid #8BC105;
    font-weight: 800;
    font-family: yorkten-slab-normal,sans-serif;
    padding-bottom: 10px;
`;

const FlexDiv = styled.div`
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const MANDATORY_TEXT = "* All fields are required except mentioned optional";

const PageTitle = (props) =>{
    const { title, showMandatoryTxt } = props;
    return (
        <FlexDiv>
            <PageHeading as="h1">{title}</PageHeading>
            {showMandatoryTxt && <MandatoryText>{MANDATORY_TEXT}</MandatoryText>}
        </FlexDiv>
        )
}

PageTitle.propTypes = {
    title: PropTypes.string,
    showMandatoryTxt: PropTypes.bool,
};

PageTitle.defaultProps = {
    title: '',
    showMandatoryTxt: false
};

export default PageTitle;