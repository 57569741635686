export const replaceWithAmpersand = value => value || '&';

export const checkValues = value => value || 'None On File';

export const getMemberId = (memberId, vcmId) => {
    const memberIdnew = String(memberId).padStart(9, '0');
    const actualMemberId = memberId ? memberIdnew : vcmId;
    return checkValues(actualMemberId);
};

export const getAddressAsLines = param => {
    const { addressLine1 = '', addressLine2 = '', city = '', state = '', zip = '', addressType = '' } = param || {};

    const addrLine = `${replaceWithAmpersand(addressLine1)}, ${replaceWithAmpersand(addressLine2)}`
        .replace(/&+/g, '')
        .replace(/^,|,$/g, '')
        .replace(/,\s*$/, '')
        .trim();
    const stateZip = zip && state ? `${state} - ${zip}` : '';
    const linesArr = [];
    if (addressType) linesArr.push(addressType);
    if (addrLine) linesArr.push(addrLine);
    if (city) linesArr.push(city);
    if (stateZip) {
        linesArr.push(stateZip);
    } else if (state) {
        linesArr.push(state);
    } else if (zip) {
        linesArr.push(zip);
    }
    const addrLines = { line1: '', line2: '', line3: '', line4: '' };
    linesArr.forEach((item, index) => {
        addrLines[`line${index + 1}`] = item;
    });

    return addrLines;
};

export const getMetadataValues = (lookupMetaData, datakey) => {
    const { [datakey]: dataKeyData } = lookupMetaData || {};
    const { value: dataKeyValue } = dataKeyData || {};
    return dataKeyValue || [];
};

export const getKeyfromValue = (lookupMetaData, datakey, value) => {
    if (!value) return null;
    const dataKeyValue = getMetadataValues(lookupMetaData, datakey);
    const selectedValue = dataKeyValue.find(data => data.value === value);
    return selectedValue ? selectedValue.key : null;
};

export const getValuefromkey = (lookupMetaData, datakey, key) => {
    if (!key) return null;
    const dataKeyValue = getMetadataValues(lookupMetaData, datakey);
    const selectedValue = dataKeyValue.find(data => data.key === key);
    return selectedValue ? selectedValue.value : null;
};

export const isValidEmail = emailId => {
    const emailRegex = /^\w+[+.\w-]*@\w+[+.\w-]*\.([a-z]{2,4}|\d+)$/i;
    let isValid = true;
    if (!emailId || !emailRegex.test(emailId)) {
        isValid = false;
    }
    return isValid;
};

export const getElementIdFormat = text => {
    return (text || '')
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .replace(/ {1}/g, '-')
        .replace(/[^A-Za-z0-9-]/g, '');
};

export const focusToNonFocusableEl = el => {
    if (el) {
        el.setAttribute('tabindex', '-1');
        const listner = () => {
            el.removeAttribute('tabindex');
            el.removeEventListener('blur', listner);
        };
        el.addEventListener('blur', listner);
        el.focus();
    }
};

export const getCountryMetaData = countryCodeData => {
    let usIndex;
    const countryCodeArr = countryCodeData || [];
    const isAlreadySwapped = countryCodeArr.some(item => item.value.length > 2);
    if (isAlreadySwapped) return countryCodeArr;
    countryCodeArr.forEach((item, index, myArr) => {
        const countryArr = myArr;
        if (item.value === 'US') usIndex = index;
        countryArr[+index] = {
            key: item.value,
            value: item.key,
        };
    });
    if (usIndex) countryCodeArr.splice(usIndex, 1);
    return countryCodeArr;
};

export const getFullName = (firstName, middleInitial, lastName) => {
    return `${firstName} ${middleInitial || ''} ${lastName}`.replace(/\s\s+/g, ' ');
};

export const hasValue = (obj, key) => {
    return Object.prototype.hasOwnProperty.call(obj, key);
};

export const getFieldValue = (stateObj, apiData, fieldKey) => {
    const { [fieldKey]: apivalue = '' } = apiData || {};
    return hasValue(stateObj, fieldKey) ? stateObj[`${fieldKey}`] : apivalue;
};

export const isDifferent = (stateObj, apiData, fieldKey) => {
    const { [fieldKey]: apivalue = '' } = apiData || {};
    const { [fieldKey]: statevalue = '' } = stateObj || {};
    return apivalue !== statevalue;
};

export const hasValueChanged = (stateObj, apiData, key) => {
    return hasValue(stateObj, key) && isDifferent(stateObj, apiData, key);
};

export const getMaxDate = () => {
    const currDate = new Date();
    const currYear = currDate.getFullYear();
    const maxYear = currYear + 9;
    return new Date(maxYear, 11);
};

export const getAriaDescribedBy = ({ error, errorTxt, errorId, instructionText, hintId }) => {
    const describedBy = [];
    if (instructionText) describedBy.push(hintId);
    if (error && errorTxt) describedBy.push(errorId);
    const addtionalProps = {};
    if (describedBy.length) addtionalProps['aria-describedby'] = describedBy.join(' ');

    return addtionalProps;
};
// ### START ###
// Analytics Utils functions

// CONSTANTS
const FORM_FIELD = 'form-field';
const LAST_TOUCHED = 'last-touched';
const TOUCHED = 'touched';
const LISTENER = 'listener';
const GROUP_FIELD = 'group-field';
const NAME = 'name';

export const getPageHeir = moduleName => {
    let pageHier = '';
    document.querySelectorAll('#breadcrumb li').forEach((e, i) => {
        pageHier += i > 0 ? ` | ${e.innerText}` : e.innerText;
    });
    if (moduleName) pageHier = `${moduleName} | ${pageHier}`;
    return pageHier;
};
export const saveBtnLink = (linkMessage, analyzeClickEvent) => {
    analyzeClickEvent({
        link: `${linkMessage}`,
    });
};

export const getErrorMessage = error => {
    const { message } = error || {};
    const snapErrorText = 'Oh snap! You got an error from backend! Please retry after sometime';
    return message || snapErrorText;
};

export const clearPrevLastTouched = el => {
    document.querySelectorAll(`[data-${LAST_TOUCHED}*="true"]`).forEach(lastFocusedField => {
        if (lastFocusedField && lastFocusedField !== el.target)
            lastFocusedField.setAttribute(`data-${LAST_TOUCHED}`, 'false');
    });
};

export const getKeyboardFocusableElements = element => {
    const parentEl = element || document;
    return [
        ...parentEl.querySelectorAll('a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'),
    ].filter(el => !el.hasAttribute('disabled'));
};
export const formFieldOnFocus = el => {
    if (el) {
        clearPrevLastTouched(el);
        if (el.target.getAttribute(`data-${TOUCHED}`) !== 'true') el.target.setAttribute(`data-${TOUCHED}`, 'true');
        if (el.target.getAttribute(`data-${LAST_TOUCHED}`) !== 'true')
            el.target.setAttribute(`data-${LAST_TOUCHED}`, 'true');
    }
};

export const isValidDateFormat = param => {
    return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(param);
};


export const addFormFieldsListner = () => {
    document.querySelectorAll(`[data-${FORM_FIELD}*="true"]`).forEach(formField => {
        if (formField) {
            const attr = formField.getAttribute(`data-${LISTENER}`);
            if (attr !== 'true') {
                formField.setAttribute(`data-${LISTENER}`, 'true');
                formField.addEventListener('focus', formFieldOnFocus);
            }
        }
    });
};

export const getClosestParentGroup = field => {
    const parentSelector = field.getAttribute('role') === 'radio' ? '[role="radiogroup"]' : '[role="group"]';
    return field.closest(parentSelector);
};

export const getLastTouchedFormField = () => {
    let fieldName = 'none';
    const lastTouchedField = document.querySelector(`[data-${LAST_TOUCHED}*="true"]`);
    if (lastTouchedField) {
        const isGroupField = lastTouchedField.getAttribute(`data-${GROUP_FIELD}`);
        if (isGroupField === 'true') {
            const parentGroupEl = getClosestParentGroup(lastTouchedField);
            if (parentGroupEl) fieldName = parentGroupEl.getAttribute(`data-${NAME}`);
        } else {
            fieldName = lastTouchedField.getAttribute(`data-${NAME}`);
        }
    }

    return fieldName;
};

export const getFormFieldArr = () => {
    const formFieldsArr = [];
    document.querySelectorAll(`[data-${FORM_FIELD}*="true"]`).forEach(formField => {
        if (formField) {
            const isGroupField = formField.getAttribute(`data-${GROUP_FIELD}`);
            if (isGroupField === 'true') {
                const parentGroupEl = getClosestParentGroup(formField);
                if (parentGroupEl && !formFieldsArr.includes(parentGroupEl)) formFieldsArr.push(parentGroupEl);
            } else formFieldsArr.push(formField);
        }
    });
    return formFieldsArr;
};

export const getRoleGroupStatus = formControls => {
    let isFilled;
    formControls.forEach((item, index) => {
        if (item) {
            if (index === 0) {
                isFilled = !!item.value;
            } else {
                isFilled = isFilled && !!item.value;
            }
        }
    });
    return isFilled;
};

export const getRoleRadioGroupStatus = formControls => {
    let isFilled;
    formControls.forEach((item, index) => {
        if (item) {
            if (index === 0) {
                isFilled = item.ariaChecked === 'true';
            } else {
                isFilled = isFilled || item.ariaChecked === 'true';
            }
        }
    });
    return isFilled;
};

export const getGroupedFieldFilledStatus = (field, role, isEdit) => {
    let isFilled;
    const formControls = field.querySelectorAll(`[data-${FORM_FIELD}*="true"]`);
    if (isEdit) {
        formControls.forEach((item, index) => {
            if (item) {
                const touched = item.getAttribute(`data-${TOUCHED}`) === 'true';
                if (index === 0) {
                    isFilled = touched;
                } else {
                    isFilled = isFilled || touched;
                }
            }
        });
    } else if (role === 'group') {
        isFilled = getRoleGroupStatus(formControls);
    } else if (role === 'radiogroup') {
        isFilled = getRoleRadioGroupStatus(formControls);
    }
    return isFilled;
};

export const updateFilledStatus = (isFilled, fieldName, formAbandData) => {
    const abandData = formAbandData;
    if (isFilled) {
        abandData.fieldfilled = abandData.fieldfilled ? `${abandData.fieldfilled} | ${fieldName}` : fieldName;
    } else {
        abandData.fieldunfilled = abandData.fieldunfilled ? `${abandData.fieldunfilled} | ${fieldName}` : fieldName;
    }
};

export const getFormAbandData = (isEdit = false) => {
    const formAbandData = {
        fieldfilled: '',
        fieldunfilled: '',
        fieldlasttouch: 'none',
    };

    formAbandData.fieldlasttouch = getLastTouchedFormField();

    const formFieldsArr = getFormFieldArr();

    formFieldsArr.forEach(field => {
        if (field) {
            const role = field.getAttribute('role');
            const fieldIsGroup = role === 'radiogroup' || role === 'group';
            const fieldName = field.getAttribute(`data-${NAME}`);
            let isFilled = false;
            if (fieldIsGroup) {
                isFilled = getGroupedFieldFilledStatus(field, role, isEdit);
            } else if (field.tagName === 'INPUT' && field.type !== 'checkbox') {
                const isValueAvailable = !!field.value;
                isFilled = isEdit
                    ? field.getAttribute(`data-${TOUCHED}`) === 'true' && isValueAvailable
                    : isValueAvailable;
            } else if (field.type === 'checkbox' || role === 'checkbox') {
                const touched = field.getAttribute(`data-${TOUCHED}`);
                isFilled = touched === 'true';
            }
            updateFilledStatus(isFilled, fieldName, formAbandData);
        }
    });

    formAbandData.fieldfilled = formAbandData.fieldfilled || 'none';
    formAbandData.fieldunfilled = formAbandData.fieldunfilled || 'none';

    // console.log('formAbandData', formAbandData);

    return {
        ...formAbandData,
    };
};

export const trackFormAbandonData = formAbandonData => {
    const { _satellite: satellite, digitalData } = window;
    if (!digitalData) window.digitalData = {};
    window.digitalData.formaband = {
        ...formAbandonData,
        pageName: getPageHeir('Customer Management'),
    };
    const { track = () => {} } = satellite || {};
    track('postlogin formaband click');
};

export const sendFormAbandonData = isEdit => () => {
    const formAbandonData = getFormAbandData(isEdit);
    const { fieldlasttouch } = formAbandonData;
    if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(formAbandonData);
};

let onUnloadCB;
export const addFormAbandonUnloadListner = isEdit => {
    onUnloadCB = sendFormAbandonData(isEdit);
    window.addEventListener('unload', onUnloadCB);
};

export const removeFormAbandonUnloadListner = () => {
    window.removeEventListener('unload', onUnloadCB);
};

// ### END ###
