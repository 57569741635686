/**
 * Investment Selection List Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to filter,compare and select mutual funds and payment method
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added message bars
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Accordion, Col, Row, Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { inputCheck } from 'utils';

import {
    CommonButtons,
    WStepper,
    WSpinner,
    WIcon,
    WSaveModal,
    WMessageBar,
    AddBankCardContainer,
    ConfirmModal,
    ConformationModal,
    WBreadCrumb,
    VCMSelect,
    // WErrorModal,
} from '../../../common';
import './InvestmentSelection.css';
import styles from './styles';
import * as CONST from './consts';
import checkValidity from '../../../utils/checkValidity';
import Rules from './Rules';
import AccGlobalConstants from '../AccountManagementConstants';
import InvestmentSelectionPaymentMethods from './InvestmentSelectionPaymentMethods';
import InvestmentSelectionFundTable from './InvestmentSelectionFundTable';
import { getFormattedDate, getMemberRoleFlag } from '../../../utils/utils';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledRowAlert = styled(Row)`
    @media (min-width: 1200px) {
        padding-right: 27px;
    }
    padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
    padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.5')}rem;
    margin-bottom: ${({ nomargin: { nomargin } }) => (nomargin ? '2.5' : 0)}rem;
`;

const IRAContribution = { fontWeight: 'bold', display: 'flex' };
const widthStyleFundOpts = { maxWidth: 210, marginRight: 15 };

// Get previous year
const prevYear = new Date().getFullYear() - 1;

const compositeDataKeys = [`${CONST.IRA_CONTRIBUTION_KEY_TEXT}${prevYear}`];

export default class InvestmentSelectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            selectedMethod: '',
            selectedBankAcccount: '',
            selectedFundingIdentifier: '',
            loading: false,
            accoCollapse1: true,
            accoCollapse2: true,
            accoCollapse3: true,
            // fundsList:[...ApiReturnedFundsList.map(c=>({...c,checked:false}))],
            displayFundList: [],
            openOnlineDetailsSection: false,
            totalInitialInvestment: '0',
            totalMonthlyInvestment: '0',
            next: false,
            back: false,
            cancel: false,
            showSaveModal: false,
            fundSource: [],
            fundOptions: [],
            errorFromMasterData: false,
            showAlert: false,
            errorMessage: '',
            existingBankAccounts: {},
            selectedFundSource: {},
            showConfirmModal: false,
            showSaveConfirmModal: false,
            showConfirmCancelModal: false,
            modalIRAAlertMessage: '',
            transCompositeData: [],
            IRAContributionYear: '',
            showContributionYearWarningModal: false,
            contributionYearErrMsg: '',
            isIRAContributionAccepted: true,
            iraContributionWarning: '',
            nextBtnClicked: false,
            validFlag: true,
        };
        this.onlineAddBankAccOpenRef = React.createRef();
    }

    /* ---------------------- Component LifeCycle Methods -------------------------- */

    static getDerivedStateFromProps(nextProps, prevState) {
        const { fundSource, fetchedBankDetails, fundOptions, selectedFundsList } = prevState;
        const { transactionReducerData, purchaseReducerData } = nextProps;
        const fundWithMonthlyOption =
            selectedFundsList && selectedFundsList.filter(fund => fund.fundingOption === 'init_mon');
        const hasMonthlyFunds = fundWithMonthlyOption && fundWithMonthlyOption.length > 0;

        let { selectedFundSource } = prevState;
        const { localStateData, accOpeningData, bankAccountsState, masterLookupStateData } = nextProps;
        const selectedFundSourceValue =
            localStateData.OpenAccPageFour &&
            localStateData.OpenAccPageFour.investmentInfo &&
            localStateData.OpenAccPageFour.investmentInfo.selectedFundSource
                ? localStateData.OpenAccPageFour.investmentInfo.selectedFundSource
                : selectedFundSource;

        const selectedContributionYear =
            localStateData.OpenAccPageFour &&
            localStateData.OpenAccPageFour.investmentInfo &&
            localStateData.OpenAccPageFour.investmentInfo.iraContributionYear
                ? localStateData.OpenAccPageFour.investmentInfo.iraContributionYear
                : prevState.IRAContributionYear;

        const propsBankAccountsLength = nextProps.bankAccountsState.bankDetails.length;
        const stateBankAccountsLength = prevState.existingBankAccounts.length;
        let existingBankAccounts = [];
        if (
            (propsBankAccountsLength > 0 && !fetchedBankDetails) ||
            propsBankAccountsLength >= stateBankAccountsLength
        ) {
            let { openOnlineDetailsSection, selectedMethod, selectedBankAccount } = prevState;
            const onLineFundMethods = fundSource.filter(source => source.key.toUpperCase() === 'ONLINE');
            const offLineFundMethods = fundSource.filter(source => source.key.toUpperCase() === 'OFFLINE');
            existingBankAccounts = nextProps.bankAccountsState.bankDetails.map(bankAccount => {
                return {
                    bankDetails: {
                        bankName: bankAccount.bankName,
                        bankAccountNumber: bankAccount.bankAccountNumber,
                        bankAccountType: bankAccount.bankAccountType,
                        transitRoutingNumber: bankAccount.transitRouting || bankAccount.bankRoutingNumber,
                        salesforceRecordId: bankAccount.id || bankAccount.salesforceRecordId,
                    },
                    value: bankAccount.bankAccountNumber,
                    key: bankAccount.id || bankAccount.salesforceRecordId,
                };
            });

            if (onLineFundMethods[0]) {
                // onLineFundMethods[0].subtypes.push(...existingBankAccounts);
                const addBankCard = onLineFundMethods[0].subtypes.slice(0, 1);
                onLineFundMethods[0].subtypes = addBankCard.concat(existingBankAccounts);
                selectedBankAccount = '';
            }

            selectedFundSource = selectedFundSource.paymentMethod ? selectedFundSource : selectedFundSourceValue;
            if (propsBankAccountsLength > 0 && propsBankAccountsLength > stateBankAccountsLength) {
                openOnlineDetailsSection = false;
                selectedMethod = '';
                selectedBankAccount = '';
                selectedFundSource = {};
            }

            return {
                fundSource:
                    fundSource.length === 0
                        ? masterLookupStateData.fund_source.value
                        : [...onLineFundMethods, ...offLineFundMethods],
                fetchedBankDetails: true,
                existingBankAccounts,
                selectedFundSource,
                openOnlineDetailsSection,
                selectedMethod,
                selectedBankAccount,
                showConfirmModal: bankAccountsState.apiResponse === 'Success',
                loading: bankAccountsState.isLoading || accOpeningData.isLoading,
                fundOptions: fundOptions.length === 0 ? masterLookupStateData.fund_options.value : fundOptions,
                showSaveConfirmModal: accOpeningData.showConfirmModal,
                confirmMessage: accOpeningData.confirmMessage,
                hasMonthlyFunds,
                transCompositeData: transactionReducerData,
                IRAContributionYear: prevState.IRAContributionYear
                    ? prevState.IRAContributionYear
                    : selectedContributionYear,
                iraContributionWarning: purchaseReducerData.iraContributionWarning,
                isIRAContributionAccepted: purchaseReducerData.isIRAContributionAccepted,
            };
        }
        return {
            showSaveConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            selectedFundSource: selectedFundSourceValue,
        };
    }

    componentDidMount() {
        // API call
        const {
            localStateData,
            getCompositeLookUpData,
            // bankAccountsState,
            getBankAccounts,
            getTransactionCompositeData,
        } = this.props;
        const {
            masterDataLoadedForInv,
            selectedFundsList = [],
            displayFundList,
            openOnlineDetailsSection,
            selectedMethod,
            selectedBankAcccount,
            selectedFundingIdentifier,
            onlineBankPaymentDetails,
        } = localStateData || {};
        this.setState(
            {
                selectedFundsList,
                displayFundList,
                masterDataLoadedForInv,
                openOnlineDetailsSection,
                selectedMethod,
                selectedBankAcccount,
                selectedFundingIdentifier,
                onlineBankPaymentDetails,
            },
            () => {
                this.caculateTotalInvestment();
            },
        );

        // if (!bankAccountsState.bankDetails.length > 0) {
        getBankAccounts({ token: localStorage.getItem('token') });
        // this.setState({
        //     loading: true,
        // });
        // }

        // if (localStateData.masterDataLoadedForInv) {
        //     this.setState({
        //         fundSource: masterLookupStateData.fund_source.value,
        //         fundOptions: masterLookupStateData.fund_options.value,
        //         // loading: false,
        //     });
        // } else {
        const payload = [];
        const compositePayloadData = CONST.compositePayload;
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            // if (this.props && masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
            payload.push(tempkey);
            // }
        }
        getCompositeLookUpData(payload);
        // }

        getTransactionCompositeData({ data: { datakey: compositeDataKeys }, token: localStorage.getItem('token') });
    }

    componentDidUpdate(prevProps) {
        const { showFundErrorModal, showErrorModal } = this.state;

        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }

        if (showFundErrorModal || showErrorModal) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    /* ----------------------End  Component LifeCycle Methods -------------------------- */

    updateState = prevProps => {
        const { accOpeningData, masterLookupStateData } = this.props;

        // Loader values
        // if (masterLookupStateData.isLoading || accOpeningData.isLoading) {
        //     this.setState({ loading: true });
        // } else if (masterLookupStateData.isSuccess || accOpeningData.isSuccess) {
        //     this.setState({ loading: false });
        // } else {
        //     this.setState({ loading: false });
        // }
        //   Loader Values End

        //   Save Function Values Start
        if (
            accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT &&
            !accOpeningData.isLoading &&
            accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT !== prevProps.accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT
        ) {
            let msg = '';
            if (accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.status) {
                msg = accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.status;
                this.setState({ showSaveModal: true, loading: false });
            } else {
                msg = accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    // loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        // Error
        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            let msg = '';
            msg = accOpeningData.response.errorMessage || accOpeningData.response.message;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                // loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        // MasterData
        this.updateMasterData(masterLookupStateData, prevProps);
    };

    updateMasterData = (masterLookupStateData, prevProps) => {
        let tempFundingSourceListData = [];
        let tempFundOptions = [];
        if (masterLookupStateData !== prevProps.masterLookupStateData) {
            if (masterLookupStateData.fund_source && masterLookupStateData.fund_source.value) {
                tempFundingSourceListData = masterLookupStateData.fund_source.value;
                this.setState({
                    fundSource: tempFundingSourceListData,
                });
            }
            if (masterLookupStateData.fund_options && masterLookupStateData.fund_options.value) {
                tempFundOptions = masterLookupStateData.fund_options.value;
                this.setState({ fundOptions: tempFundOptions });
            }

            // Master data API Error
            if (masterLookupStateData.isError === true) {
                this.setState({ errorFromMasterData: true });
            } else if (masterLookupStateData.isError === false) {
                this.setState({ errorFromMasterData: false });
            }
            //
            this.setState({ masterDataLoadedForInv: true });
        }
    };

    formatInputValues = val => {
        // return val ? val.toString().replace(/[a-zA-Z,$.]/g, '') : 0;
        return val ? val.toString().replace(/[a-zA-Z,$]/g, '') : 0;
    };

    getAge = () => {
        const { localStateData } = this.props;
        let age = 0;
        if (
            localStateData &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.dateOfBirth
        ) {
            const dob = new Date(localStateData.OpenAccPageTwo.personalInfo.dateOfBirth);
            const ageDiff = Date.now() - dob.getTime();
            const ageDt = new Date(ageDiff);
            age = Math.abs(ageDt.getUTCFullYear() - 1970);
        }
        return age;
    };

    getAccountTypeCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };

    handleIRAContribution = (totalInvestment, accountType) => {
        //  e.stopPropagation();
        const { getIRAContributionValues, localStateData } = this.props;
        const { OpenAccPageTwo = {} } = localStateData;
        const dateOfBirth = OpenAccPageTwo.personalInfo && OpenAccPageTwo.personalInfo.dateOfBirth;

        const dob = new Date(dateOfBirth);
        const taxId =
            OpenAccPageTwo.personalInfo &&
            OpenAccPageTwo.personalInfo.ssnData &&
            OpenAccPageTwo.personalInfo.ssnData.fullSSN;
        
        const IRAContributionPayload = {
            companyNumber: '591',
            year: new Date().getFullYear(),
            amount: +totalInvestment,
            accountType: accountType.trim(),
            taxId,
            dob: getFormattedDate(dob),
            accountTypeCode: this.getAccountTypeCode(accountType.trim()),
            socialCode: this.getSocialCode(accountType.trim()),
            accountSource: "FIS",
            isMember: getMemberRoleFlag()
        };

        const validateIRAPayloadObj = {
            IRAContributionPayload,
            stateValue: {},
            token: localStorage.getItem('token'),
            isVersion2ApiUrl: true
        };
        getIRAContributionValues(validateIRAPayloadObj);
    };

    caculateTotalInvestment = () => {
        const { selectedFundsList } = this.state;
        const { localStateData } = this.props;
        const arr = [...selectedFundsList];
        let totalInitial = 0;
        let totalMonthly = 0;
        for (let i = 0; i < arr.length; i += 1) {
            totalInitial += Number(this.formatInputValues(arr[+i].initialInvest));
            if (arr[+i].fundingOption && arr[+i].fundingOption === 'init_mon') {
                totalMonthly += Number(this.formatInputValues(arr[+i].monthlyInvest));
            }
        }
        this.setState({
            totalInitialInvestment: parseFloat(totalInitial).toFixed(2),
            totalMonthlyInvestment: parseFloat(totalMonthly).toFixed(2),
        });
        const totalInvestment = parseFloat(totalInitial).toFixed(2);
        const { accountType } = localStateData.OpenAccPageOne;
        if (accountType.trim() === CONST.TRADIONAL_IRA_TEXT || accountType.trim() === CONST.ROTH_IRA_TEXT) {
            this.handleIRAContribution(totalInvestment, accountType);
        }
    };

    handleFundOptions = (index, eventKey) => e => {
        const { selectedFundsList } = this.state;
        const updatedArray = [...selectedFundsList];
        const findex = updatedArray.findIndex(fund => fund.fundNumber === updatedArray[+index].fundNumber);
        if (eventKey === CONST.initialInvestment || eventKey === CONST.monthlyInvestment) {
            const validInitialInvestment = inputCheck(e.target.value);
            if (validInitialInvestment === null) return;
            updatedArray[+findex][eventKey.toString()] = validInitialInvestment; // validInitialInvestment!=="0" ? Number(validInitialInvestment).toFixed(2):"0.00";
        } else if (eventKey === 'startDate') {
            updatedArray[+findex][eventKey.toString()] = e.target.value;
            updatedArray[+findex][`${eventKey.toString()}_err`] = '';
        } else {
            updatedArray[+findex][eventKey.toString()] = e.target.value;
        }
        this.setState(
            () => {
                return {
                    selectedFundsList: updatedArray,
                };
            },
            () => {
                this.caculateTotalInvestment();
            },
        );
    };

    handleFundOptionsBlur = (index, eventKey) => e => {
        const { selectedFundsList } = this.state;
        const updatedArray = [...selectedFundsList];
        const findex = updatedArray.findIndex(fund => fund.fundNumber === updatedArray[+index].fundNumber);
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        updatedArray[+findex][`${eventKey.toString()}_err`] = err;
        if (eventKey === CONST.initialInvestment || eventKey === CONST.monthlyInvestment) {
            const validInitialInvestment = inputCheck(e.target.value);
            if (validInitialInvestment === null) return;
            updatedArray[+findex][eventKey.toString()] =
                validInitialInvestment !== '0' ? Number(validInitialInvestment) : '0.00';
        }
        this.setState({ selectedFundsList: updatedArray }, () => {
            this.caculateTotalInvestment();
        });
    };

    handleFundRemove = index => e => {
        e.preventDefault();
        const { selectedFundsList, displayFundList } = this.state;
        let { showFundErrorModal = false, modalFundAlertMessage = '' } = this.state;
        const { sortFundListLocalState } = this.props;
        if (selectedFundsList && selectedFundsList.length === 1) {
            showFundErrorModal = true;
            modalFundAlertMessage = CONST.ATLEAST_ONE_FUND_ERROR_MESSAGE;
        }
        const updatedArray = [...selectedFundsList];
        const updateDisplayArray = [...displayFundList];
        const dindex = updateDisplayArray.findIndex(fund => fund.fundNumber === updatedArray[+index].fundNumber);
        updateDisplayArray[+dindex].checked = false;
        updateDisplayArray[+dindex].fundingOption = 'init';
        updateDisplayArray[+dindex].initialInvest = updateDisplayArray[+dindex].initialInvestment;
        updateDisplayArray[+dindex].monthlyInvest = updateDisplayArray[+dindex].minimumAutomaticInvestment;
        const findex = updatedArray.findIndex(fund => fund.fundNumber === updatedArray[+index].fundNumber);
        updatedArray.splice(findex, 1);
        sortFundListLocalState(updateDisplayArray);
        this.setState(
            {
                selectedFundsList: updatedArray,
                showFundErrorModal,
                modalFundAlertMessage,
                displayFundList: updateDisplayArray,
            },
            () => {
                this.caculateTotalInvestment();
            },
        );
    };

    handleOnlineMethod = () => {
        const { openOnlineDetailsSection } = this.state;
        this.setState({ openOnlineDetailsSection: !openOnlineDetailsSection });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        this.setState({ back: true }, this.saveToLocal());
    };

    validateFields = () => {
        const {
            selectedFundsList,
            selectedMethod,
            selectedFundSource,
            errorMsg,
            selectedFundingIdentifier,
            totalInitialInvestment,
            IRAContributionYear,
        } = this.state;

        const { localStateData } = this.props;
        const { accountType } = localStateData;

        const isIRAAccount =
            accountType.trim() === CONST.TRADIONAL_IRA_TEXT ||
            accountType.trim() === CONST.ROTH_IRA_TEXT ||
            accountType.trim() === CONST.SEP_IRA_TEXT;

        // let { onlineBankPaymentDetails } = this.state;
        const onlineBankPaymentDetails = this.getOnlineBankAccountDetails();
        let validFlag = true;
        if (selectedFundsList && selectedFundsList.length === 0) {
            validFlag = false;
            this.setState({ showFundErrorModal: true, modalFundAlertMessage: CONST.ATLEAST_ONE_FUND_ERROR_MESSAGE });
        } else {
            validFlag = true;
            this.setState({ showFundErrorModal: false, modalFundAlertMessage: '' });
        }

        if (!selectedMethod) {
            validFlag = false;
            this.setState({ showErrorModal: true, modalAlertMessage: CONST.ATLEAST_ONE_FUND_METHOD_ERROR_MESSAGE });
        }
        const { paymentMethod} = selectedFundSource;
       

        if (!selectedFundSource.paymentMethod) {
            validFlag = false;
            this.setState({ showErrorModal: true, modalAlertMessage: CONST.FUNDING_SOURCE_ERROR });
        }
        if (
            paymentMethod === CONST.ONLINE_TEXT &&
            selectedFundingIdentifier &&
            selectedFundsList &&
            selectedFundsList.length > 0 &&
            validFlag
        ) {
            validFlag = true;
            this.setState({ showErrorModal: false });
        }

        const returnedObject = this.checkIfSelectedFundsListIsValid(selectedFundsList, validFlag);
        const validFlagFundList = returnedObject.validFlag;

        const validateContributionYear = isIRAAccount
            ? IRAContributionYear === '0' || IRAContributionYear === '1'
            : true;

        if (!validateContributionYear) {
            this.setState({
                showContributionYearWarningModal: true,
                contributionYearErrMsg: CONST.CONTIRBUTION_YEAR_ERROR_MESSAGE,
            });
        }
        if (validateContributionYear) {
            this.setState({ showContributionYearWarningModal: false, contributionYearErrMsg: '' });
        }
        validFlag = validFlag && validFlagFundList && validateContributionYear;
        this.setState(() => {
            return {
                errorMsg,
                selectedFundsList: returnedObject.selectedFundsList,
                onlineBankPaymentDetails,
                validFlag,
            };
        });
        return validFlag;
    };

    validateDate = dateVal => {
        const date = new Date();
        // For two day check
        date.setHours(48);
        date.setMinutes(0);
        date.setSeconds(0);
        return Date.parse(dateVal) >= Date.parse(date);
    };

    checkIfSelectedFundsListIsValid = (selectedFunds, valid) => {
        let validFlag = valid;
        let validInitial = true;
        let validPAD = true;
        let validStartDate = true;
        let selectedFundsList = selectedFunds;
        selectedFundsList.forEach((item, index) => {
            if (item.fundingOption) {
                if (item.fundingOption === CONST.initialandMonthly) {
                    const returnedObject = this.checkIfInitialInvestmentValid(
                        item.initialInvest,
                        item.initialInvestment,
                        selectedFundsList,
                        validFlag,
                        index,
                    );
                    validInitial = returnedObject.validFlag;
                    selectedFundsList = returnedObject.selectedFundsList;
                    if (item.monthlyInvest < item.minimumAutomaticInvestment) {
                        validPAD = false;
                        if (item.monthlyInvest.toString().trim() === '' || item.monthlyInvest === 0) {
                            selectedFundsList[Number(index)].monthlyInvestment_err = CONST.enterValueErrorMessage;
                        } else {
                            selectedFundsList[Number(index)].monthlyInvestment_err = CONST.validErrorMessage;
                        }
                    } else {
                        validPAD = true;
                        selectedFundsList[Number(index)].monthlyInvestment_err = '';
                    }
                    if (!item.startDate) {
                        validStartDate = false;
                        selectedFundsList[Number(index)].startDate_err = CONST.selectValueErrorMessage;
                    } else if (!this.validateDate(item.startDate)) {
                        validStartDate = false;
                        selectedFundsList[Number(index)].startDate_err = CONST.START_DATE_VALIDATION_ERROR_MESSAGE;
                    } else {
                        validStartDate = true;
                        selectedFundsList[Number(index)].startDate_err = '';
                    }
                    validFlag = validInitial && validPAD && validStartDate;
                    selectedFundsList[Number(index)].validFlag = validFlag;
                } else {
                    const returnedObject = this.checkIfInitialInvestmentValid(
                        item.initialInvest,
                        item.initialInvestment,
                        selectedFundsList,
                        validFlag,
                        index,
                    );
                    validFlag = returnedObject.validFlag;
                    selectedFundsList = returnedObject.selectedFundsList;
                    selectedFundsList[Number(index)].validFlag = validFlag;
                }
            } else {
                validFlag = false;
                selectedFundsList[Number(index)].fundingOption_err = CONST.validErrorMessage;
            }
        });

        const invalidFundList = selectedFundsList.filter(fund => fund.validFlag === false);
        validFlag = invalidFundList.length === 0;
        return { validFlag, selectedFundsList };
    };

    checkIfInitialInvestmentValid = (initialInvest, initialInvestment, selectedFunds, valid, index) => {
        let validFlag = valid;
        const selectedFundsList = selectedFunds;
        if (initialInvest < initialInvestment) {
            validFlag = false;
            if (initialInvest.toString().trim() === '' || initialInvest === 0) {
                selectedFundsList[Number(index)].initialInvestment_err = CONST.enterValueErrorMessage;
            } else {
                selectedFundsList[Number(index)].initialInvestment_err = CONST.validErrorMessage;
            }
        } else {
            validFlag = true;
            selectedFundsList[Number(index)].initialInvestment_err = '';
        }
        return { validFlag, selectedFundsList };
    };

    saveToLocal = (action = '') => {
        const {
            displayFundList,
            selectedFundsList,
            openOnlineDetailsSection,
            selectedMethod,
            selectedBankAcccount,
            selectedFundingIdentifier,
            masterDataLoadedForInv,
            totalInitialInvestment,
            totalMonthlyInvestment,
            fundSource,
        } = this.state;
        let { onlineBankPaymentDetails } = this.state;
        // if (!onlineBankPaymentDetails) {
        onlineBankPaymentDetails = this.getOnlineBankAccountDetails();
        // }
        const { manageLocalState } = this.props;
        const data = {
            displayFundList,
            selectedFundsList,
            openOnlineDetailsSection,
            selectedMethod,
            selectedBankAcccount,
            masterDataLoadedForInv,
            totalInitialInvestment,
            totalMonthlyInvestment,
            onlineBankPaymentDetails,
            fundSource,
            selectedFundingIdentifier,
        };
        const openAccPage4Payload = this.generateInvestmentPayload();
        if (action === 'save') {
            const { saveApplication, localStateData } = this.props;
            const savePayload = { ...localStateData, OpenAccPageFour: openAccPage4Payload, ...data };

            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: localStateData.accountType,
                    payload: savePayload,
                },
            });
        } else {
            // manageLocalState({ OpenAccPageThree: openAccPage4Payload, ...data });
            manageLocalState({ OpenAccPageFour: openAccPage4Payload, ...data });
        }
    };

    handleNext = () => {
        this.setState({ nextBtnClicked: true });
        if (this.validateFields()) {
            this.setState({ next: true }, this.saveToLocal());
        }
    };

    generateInvestmentPayload = () => {
        const { selectedFundsList, selectedFundSource, IRAContributionYear } = this.state;
        let { onlineBankPaymentDetails } = this.state;
        // if (!onlineBankPaymentDetails) {
        onlineBankPaymentDetails = this.getOnlineBankAccountDetails();

        //  }
        const { otherBankState } = onlineBankPaymentDetails || {};
        const { bankAccountDetails = [], bankAccountType } = otherBankState || {};
        return {
            investmentInfo: {
                selectedFundSource,
                selectedFundsList,
                iraContributionYear: IRAContributionYear,
            },
        };
    };

    getOnlineBankAccountDetails = () => {
        const onlinePaymentDetails = this.onlineAddBankAccOpenRef.current;
        if (onlinePaymentDetails) {
            const cmpState = { ...onlinePaymentDetails.getState() };
            this.setState(() => {
                return { onlineBankPaymentDetails: cmpState };
            });
            return cmpState;
        }

        return {};
    };

    handleAddBankAccount = () => {
        const onlinePaymentDetails = this.onlineAddBankAccOpenRef.current;
        let addBankDetails = {};
        if (onlinePaymentDetails) {
            addBankDetails = { ...onlinePaymentDetails.getState() };
            const { addBankAccountNAOTrans } = this.props;
            const addBankPayload = {
                bankAccountType: addBankDetails.otherBankState.bankAccountType,
                bankAccountNumber: addBankDetails.otherBankState.bankAccountDetails[0].value,
                transitRoutingNumber: addBankDetails.otherBankState.bankAccountDetails[1].value,
                financialInstituteName: addBankDetails.otherBankState.bankAccountDetails[3].value,
                accountOwnersName: addBankDetails.otherBankState.bankAccountDetails[2].value,
            };
            this.setState({ selectedBankAccount: '' });
            addBankAccountNAOTrans({ addBankPayload, token: localStorage.getItem('token') });
        }
    };

    handleSave = () => {
        this.setState({ showSaveModal: false }, () => {
            if (this.validateFields()) {
                this.saveToLocal('save');
            }
        });
    };

    cardHeaderClick = e => {
        e.preventDefault();
        const eleName = e.target.id;
        const { accoCollapse1, accoCollapse2, accoCollapse3 } = this.state;
        if (eleName === 'fundInvest') {
            this.setState({ accoCollapse1: !accoCollapse1 });
        } else if (eleName === 'fundSource') {
            this.setState({ accoCollapse2: !accoCollapse2 });
        } else if (eleName === 'contributionYear') {
            this.setState({ accoCollapse3: !accoCollapse3 });
        }
    };

    selectFundcard = (srcKey, srcSubKey) => e => {
        e.preventDefault();
        const { existingBankAccounts } = this.state;

        let selectedFundSource = {};
        let bankDetails = [];
        let openOnlineDetailsSection = false;
        let srcSubKeyLocal = srcSubKey;
        if (srcKey === 'online' && srcSubKey === 'add_bnk') {
            openOnlineDetailsSection = true;
        } else if (srcKey === 'online' && srcSubKey !== 'add_bank') {
            bankDetails = existingBankAccounts.filter(bank => bank.bankDetails.salesforceRecordId === srcSubKey);
            selectedFundSource = {
                paymentMethod: srcKey,
                fundSource: {
                    institutionName: bankDetails[0].bankDetails.bankName,
                    bankAccountNumber: bankDetails[0].bankDetails.bankAccountNumber,
                    accountType: bankDetails[0].bankDetails.bankAccountType,
                    transitRoutingNumber: bankDetails[0].bankDetails.transitRoutingNumber,
                    salesforceRecordId: srcSubKey,
                    fundType: 'bank',
                },
            };
            srcSubKeyLocal = bankDetails[0].bankDetails.bankAccountNumber;
        } else {
            selectedFundSource = {
                paymentMethod: srcKey,
                fundSource: {
                    fundType: srcSubKey,
                },
            };
        }
        this.setState({
            openOnlineDetailsSection,
            selectedMethod: srcKey,
            selectedBankAcccount: srcSubKeyLocal,
            selectedFundingIdentifier: srcSubKey,
            showErrorModal: false,
            selectedFundSource,
        });
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    handleSuccessConfirmation = () => {
        const { clearAddBankAccDetails } = this.props;
        this.setState({
            showConfirmModal: false,
            selectedBankAcccount: '',
            selectedFundingIdentifier: '',
            openOnlineDetailsSection: false,
        });
        clearAddBankAccDetails();
    };

    handleSaveSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showSaveConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    yearChangeHandler = e => {
        this.setState({ IRAContributionYear: e.target.value });
        if (e.target.value === '0' || e.target.value === '1') {
            this.setState({ showContributionYearWarningModal: false, contributionYearErrMsg: '' });
        }
    };

    getSelectDropdown = (values, IRAContributionYear) => {
        return (
            <VCMSelect
                key={IRAContributionYear}
                name="iraContribution"
                id="iracontribution"
                itemlist={values}
                onChange={this.yearChangeHandler}
                value={IRAContributionYear}
                valuesm={12}
                labelsm={0}
                // errortext={item.fundingOption_err}
                style={widthStyleFundOpts}
                noGutters
                data-test="ira-selectBox"
            />
        );
    };

    getPrevYearTaxDeadline = taxDeadline => {
        const taxDeadlineDateFormat = new Date(taxDeadline);
        const currentDate = new Date();
        let contributionValues = CONST.CURRENT_YEAR_TEXT;

        if (currentDate <= taxDeadlineDateFormat) {
            contributionValues = CONST.CONTRIBUTION_OPTIONS;
        }
        return contributionValues;
    };

    handleIRAContributionOk = () => {
        const { acceptIRAContributionWarning } = this.props;
        acceptIRAContributionWarning();
        this.handleNext();
    };

    handleIRAContributionCancel = () => {
        this.setState({ nextBtnClicked: false, next: false });
    };

    render() {
        const {
            // disableRemove,
            showSaveModal,
            fundSource,
            openOnlineDetailsSection,
            next,
            back,
            cancel,
            loading,
            accoCollapse1,
            accoCollapse2,
            errorFromMasterData,
            showAlert,
            errorMessage,
            isErrorBar,
            showErrorModal,
            showFundErrorModal,
            modalFundAlertMessage,
            modalAlertMessage,
            selectedFundsList,
            totalInitialInvestment,
            totalMonthlyInvestment,
            fundOptions,
            // errorMsg,
            nomargin = true,
            onlineBankPaymentDetails,
            showConfirmModal,
            showSaveConfirmModal,
            confirmMessage,
            // disableNext = true,
            hasMonthlyFunds,
            // selectedFundSource,
            showConfirmCancelModal,
            selectedFundingIdentifier,
            accoCollapse3,
            transCompositeData,
            IRAContributionYear,
            showContributionYearWarningModal,
            contributionYearErrMsg,
            isIRAContributionAccepted,
            iraContributionWarning,
            nextBtnClicked,
            validFlag,
        } = this.state;

        if (errorFromMasterData) {
            throw new Error(CONST.invSelectMasterDataError);
        }
        const { accountOpeningPages, assignObj, getPageHeading } = AccGlobalConstants;
        const {
            localStateData,
            bankAccountsState,
            clearAddBankAccDetails,
            // validateBankAccInfo,
            // clearVerifyAccDetails,
            accOpeningData,
            purchaseReducerData,
        } = this.props;
        const { accountType } = localStateData || {};
        const pageHeadingText = getPageHeading(accountType);
        const accountTypeBackLabel = 'Back to Fund Selection';
        // Workaround for styled component issue
        const marginProps = { nomargin: { nomargin } };

        return (
            <>
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {purchaseReducerData.isLoading && <WSpinner loading={purchaseReducerData.isLoading} />}
                {loading ? <WSpinner loading={loading} /> : ''}
                {showConfirmModal && (
                    <ConfirmModal
                        modalTitle="Add Bank Successful"
                        modalBodyText={bankAccountsState.successMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                    />
                )}
                {showSaveConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSaveSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                        show={showConfirmCancelModal}
                    />
                )}
                {!isIRAContributionAccepted && nextBtnClicked && validFlag && (
                    <ConformationModal
                        modalTitle={CONST.IRA_MAX_CONTR_WARNING}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={this.handleIRAContributionOk}
                        onSecondaryClick={this.handleIRAContributionCancel}
                    />
                )}
                <div>
                    <div style={styles.containerStyle}>
                        {showAlert ? (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        ) : null}
                        <div className="container investSelContainer">
                            <WBreadCrumb
                                activeCrumb={accountType}
                                breadCrumbItems={
                                    localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                                }
                            />
                            <h1 style={styles.pageHeaderStyle}>{pageHeadingText}</h1>
                            <WStepper style={styles.stepperStyle} currentPage={3} pages={accountOpeningPages} />
                            <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                            {/* ------------------------------Investment Details Section------------------------------------------*/}
                            <Accordion defaultActiveKey="1" id="fundUrInvAcc">
                                <Accordion.Toggle
                                    as="h2"
                                    variant="link"
                                    eventKey="1"
                                    className="accordionHeading"
                                    id="fundInvest"
                                    onClick={this.cardHeaderClick}
                                    aria-expanded={accoCollapse1}
                                    style={assignObj({ cursor: 'pointer' })}
                                >
                                    <WIcon
                                        icon={accoCollapse1 ? faMinusCircle : faPlusCircle}
                                        size="1x"
                                        fontawesomestyle={styles.accordionIconStyle}
                                    />
                                    {CONST.FUND_INVESTMENTS}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <div>
                                        <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                                            <Col>
                                                {showFundErrorModal && (
                                                    <StyledAlert {...marginProps} variant="danger">
                                                        <StyledPara>{modalFundAlertMessage}</StyledPara>
                                                    </StyledAlert>
                                                )}
                                            </Col>
                                        </StyledRowAlert>
                                        <div style={styles.accordionInfoStyleFYInv}>{CONST.CHOOSE_BELOW}</div>
                                        <InvestmentSelectionFundTable
                                            selectedFundsList={selectedFundsList}
                                            fundOptions={fundOptions}
                                            totalInitialInvestment={totalInitialInvestment}
                                            totalMonthlyInvestment={totalMonthlyInvestment}
                                            handleFundOptions={this.handleFundOptions}
                                            handleFundOptionsBlur={this.handleFundOptionsBlur}
                                            handleFundRemove={this.handleFundRemove}
                                            // disableRemove={disableRemove}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>

                            {/* ------------------------------Fund Your Account Online or Offline------------------------------------------*/}
                            <div>
                                <Accordion defaultActiveKey="0" id="fundSrcOptions">
                                    <Accordion.Toggle
                                        as="h2"
                                        variant="link"
                                        eventKey="0"
                                        className="accordionHeading"
                                        id="fundSource"
                                        onClick={this.cardHeaderClick}
                                        aria-expanded={accoCollapse2}
                                        style={assignObj({ cursor: 'pointer' })}
                                    >
                                        <WIcon
                                            icon={accoCollapse2 ? faMinusCircle : faPlusCircle}
                                            size="1x"
                                            fontawesomestyle={styles.accordionIconStyle}
                                        />{' '}
                                        {CONST.FUND_YOUR_ACCOUNT}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div>
                                            <div style={styles.accordionInfoStyle}>{CONST.CHOOSE_ACCOUNT}</div>
                                            <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                                                <Col>
                                                    {showErrorModal && (
                                                        <StyledAlert {...marginProps} variant="danger">
                                                            <StyledPara>{modalAlertMessage}</StyledPara>
                                                        </StyledAlert>
                                                    )}
                                                </Col>
                                            </StyledRowAlert>
                                            <InvestmentSelectionPaymentMethods
                                                fundSource={fundSource}
                                                selectedFundSrcMethod={selectedFundingIdentifier}
                                                selectFundcard={this.selectFundcard}
                                                hasMonthlyFunds={hasMonthlyFunds}
                                                // selectedFundSource={selectedFundSource}
                                            />
                                            <div style={styles.addBankAccountSpace} />
                                            {/* /* ------------------------------Online Account Details Section------------------------------------------*/}
                                            {openOnlineDetailsSection && (
                                                <AddBankCardContainer
                                                    ref={this.onlineAddBankAccOpenRef}
                                                    savedState={onlineBankPaymentDetails}
                                                    handleAdd={this.handleAddBankAccount}
                                                    apiResponse={bankAccountsState.apiResponse}
                                                    responseMessage={bankAccountsState.responseMessage}
                                                    responseCode={bankAccountsState.responseCode}
                                                    clearAddBankData={clearAddBankAccDetails}
                                                    // validateBankAccInfo={validateBankAccInfo}
                                                    // bankAccountInfo={bankAccountsState}
                                                    // clearVerifyAccDetails={clearVerifyAccDetails}
                                                />
                                            )}
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                            {/* ------------------------------End Fund Your Account Online or Offline------------------------------------------*/}
                            {(accountType.trim() === CONST.TRADIONAL_IRA_TEXT ||
                                accountType.trim() === CONST.ROTH_IRA_TEXT ||
                                accountType.trim() === CONST.SEP_IRA_TEXT) && (
                                <div>
                                    <Accordion defaultActiveKey="0" id="contributionYearOptions">
                                        <Accordion.Toggle
                                            as="h2"
                                            variant="link"
                                            eventKey="0"
                                            className="accordionHeading"
                                            id="contributionYear"
                                            onClick={this.cardHeaderClick}
                                            aria-expanded={accoCollapse3}
                                            style={assignObj({ cursor: 'pointer' })}
                                        >
                                            <WIcon
                                                icon={accoCollapse3 ? faMinusCircle : faPlusCircle}
                                                size="1x"
                                                fontawesomestyle={styles.accordionIconStyle}
                                            />{' '}
                                            {CONST.CONTRIBUTION_TEXT}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <div>
                                                <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                                                    <Col>
                                                        {showContributionYearWarningModal && (
                                                            <StyledAlert {...marginProps} variant="danger">
                                                                <StyledPara>{contributionYearErrMsg}</StyledPara>
                                                            </StyledAlert>
                                                        )}
                                                    </Col>
                                                </StyledRowAlert>
                                                <div className={IRAContribution}>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <p
                                                                className="iraLabel"
                                                                style={assignObj({
                                                                    fontWeight: '700px',
                                                                    fontSize: '16px',
                                                                })}
                                                            >
                                                                {CONST.CONTRIBUTION_IRA}
                                                            </p>
                                                        </Col>
                                                        <Col sm={3}>
                                                            {transCompositeData &&
                                                            compositeDataKeys[0] in transCompositeData
                                                                ? this.getSelectDropdown(
                                                                      this.getPrevYearTaxDeadline(
                                                                          transCompositeData[compositeDataKeys[0]]
                                                                              .tax_deadline,
                                                                      ),
                                                                      IRAContributionYear,
                                                                  )
                                                                : this.getSelectDropdown(
                                                                      CONST.CURRENT_YEAR_TEXT,
                                                                      IRAContributionYear,
                                                                  )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>
                            )}
                        </div>
                        <CommonButtons
                            nextClick={this.handleNext}
                            saveClick={this.handleSave}
                            backClick={this.handleBack}
                            cancelClick={this.handleCancelClick}
                            arialabelback={accountTypeBackLabel}
                            showSave
                            iscancel
                            cancelText="Cancel"
                            cancelStyle={assignObj(cancelButtonStyles)}
                            cancelOffset={assignObj({
                                span: 2,
                                offset: 6,
                            })}
                            // disabled={disableNext}
                        />
                        {accountType.trim() === CONST.TRADIONAL_IRA_TEXT ||
                        accountType.trim() === CONST.ROTH_IRA_TEXT ||
                        accountType.trim() === CONST.SEP_IRA_TEXT
                            ? next &&
                              isIRAContributionAccepted && (
                                  <Redirect push to={assignObj({ pathname: '/preferences' })} />
                              )
                            : next && <Redirect push to={assignObj({ pathname: '/preferences' })} />}

                        {cancel ? <Redirect push to="/" /> : ''}
                        {back ? <Redirect push to="/fundSelection" /> : ''}
                    </div>
                </div>
            </>
        );
    }
}

InvestmentSelectionComponent.propTypes = {
    accOpeningData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    saveApplication: PropTypes.func,
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    getBankAccounts: PropTypes.func,
    bankAccountsState: PropTypes.instanceOf(Object),
    sortFundListLocalState: PropTypes.instanceOf(Object),
    addBankAccountNAOTrans: PropTypes.func,
    clearAddBankAccDetails: PropTypes.func,
    validateBankAccInfo: PropTypes.func,
    clearVerifyAccDetails: PropTypes.func,
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    getTransactionCompositeData: PropTypes.func,
    transactionReducerData: PropTypes.instanceOf(Object),
    purchaseReducerData: PropTypes.instanceOf(Object),
    getIRAContributionValues: PropTypes.func,
    acceptIRAContributionWarning: PropTypes.func,
    cancelIRAContributionWarning: PropTypes.func,
};

InvestmentSelectionComponent.defaultProps = {
    accOpeningData: {},
    localStateData: {},
    masterLookupStateData: {},
    bankAccountsState: {},
    getCompositeLookUpData: () => {},
    saveApplication: () => {},
    manageLocalState: () => {},
    clearLocalStateForNAO: () => {},
    getBankAccounts: () => {},
    sortFundListLocalState: () => {},
    addBankAccountNAOTrans: () => {},
    clearAddBankAccDetails: () => {},
    validateBankAccInfo: () => {},
    resetShowConfirmModal: () => {},
    clearVerifyAccDetails: () => {},
    getTransactionCompositeData: () => {},
    history: {},
    transactionReducerData: {},
    purchaseReducerData: {},
    getIRAContributionValues: () => {},
    acceptIRAContributionWarning: () => {},
    cancelIRAContributionWarning: () => {},
};
