import { analyzeClickEvent } from '../../shared/Actions/AnalyticsActions';
import { getDocument, downloadCompleted } from '../../shared/Actions/DownloadFileAction';
import Image from 'react-bootstrap/Image';
import PrintPdf from 'common/TermsAndConditions/PrintPdf';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import WAlertComponent from '../WAlert/WAlertComponent';
import { Button } from 'react-bootstrap';
//import { Save, SaveAltSharp } from '@material-ui/icons';
import {WSpinner} from '../../common';

const S = {};

S.AddSection = styled.a.attrs(() => ({
    href: '#',
}))`
    position: relative;
    display: ${props => props.contentStyle};
    align-items: center;
    z-index: 0;
`;

S.Icon = styled(Image)`
    margin-right: 0.3125rem;
    height: 1.4rem;
    margin-left: 1.25rem;
`;

S.HeadingLink = styled.span`
    all: unset;
    text-align: left;
    font-style: normal;
    margin-top: 2px;
    text-transform: capitalize;
    color: #004a98;
    font: 500 16px / 24px benton-sans;
    cursor: pointer;
    :hover {
        color: #004a98;
        text-decoration: underline;
    }
    :focus {
        outline: 1px solid black;
    }
`;

S.Alert = styled(WAlertComponent)`
    /* position: absolute; */
    /* white-space: nowrap; */
    /* overflow: hidden; */
    padding: 5px;
    margin-bottom: 5px;
    display: -ms-inline-grid;
    > p {
        font-weight: 500;
        font-size: 14px;
        display: -ms-flexbox;
    }
    cursor: pointer;
`;

S.Button = styled(Button)`
  &.btn-light {
      background-color: #486d90;
  }
  &.btn:disabled {
    background-color: #486d90;
    opacity: 1;
    cursor: default;
  }
`;

const downloadFileFromUrl = ({ downloadUrl, saveAs, setWasClicked, dispatch }) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = saveAs;
    link.setAttribute('target', '_blank');
    link.click();
    setWasClicked(false);
    dispatch(downloadCompleted());
};

let wasClickedStatus = false;
let downloadFileName = "";
const downloadFile = ({
    setloading,
    downloadUrl,
    saveAs,
    setWasClicked,
    dispatch,
    setShowDownloadError,
    downloadUrlRequiresAuth,
    setDownloadFileURL,
    isDocumentCenter,
    text,
    buttontext,
    alternateDocumentId,
    docCategory,
    documentSource,
    nodeId,
    unread,
    optionalScreenReaderMsg
}) => e => {
    e.preventDefault();
    setWasClicked(true);
    setShowDownloadError(false);
    wasClickedStatus = true;
    downloadFileName=optionalScreenReaderMsg;
    const isAlternateDocumentLink = Boolean(downloadUrl);
    if (isAlternateDocumentLink && !downloadUrlRequiresAuth && isDocumentCenter == false) {
        if (!isDocumentCenter || text === 'View' || buttontext === 'View') {
            downloadFileFromUrl({ downloadUrl, saveAs, setWasClicked, dispatch });
        } else {
            setDownloadFileURL(downloadUrl);
        }
    } else {
        setloading(true);
        dispatch(
            getDocument({
                alternateDocumentId,
                docCategory,
                documentSource,
                downloadUrl,
                nodeId,
                token: localStorage.getItem('token'),
                unread,
            }),
        );
   }

    dispatch(
        analyzeClickEvent({
            link: saveAs,
        }),
    );
    e.stopPropagation();
};

const documentOpenInNewTab = (downloadedFile,saveAs ) =>
{
    wasClickedStatus = false;
    window.open(URL.createObjectURL(downloadedFile, saveAs));
}

const setDocumentUrl = (setloading,setDownloadFileURL,downloadedFile,diffFlag = false) =>
{
    if(diffFlag == true)
    {
        setloading(false);
        setDownloadFileURL(downloadedFile);
        wasClickedStatus = false;
    }
    else
    {
        setloading(false);
        setDownloadFileURL(downloadedFile);
    }
    
}

const handlePreviewOrDownload = (downloadedFile,saveAs,preview) =>
{
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(downloadedFile, saveAs);
     } else {

         if(preview == "preview" && wasClickedStatus)
         {
            documentOpenInNewTab(downloadedFile,saveAs)
         }
         else if(preview=="download" &&  wasClickedStatus)
         {
             wasClickedStatus = false;
             let downloadTag = document.createElement("a");
             downloadTag.href = URL.createObjectURL(downloadedFile, saveAs);
             downloadTag.download = `${downloadFileName}`;
             downloadTag.click();
         }
         else if(wasClickedStatus)
         {
            documentOpenInNewTab(downloadedFile,saveAs)
         }
     }
}
const handleDocumentOpening = (messageComponentIndentifier,downloadedFile,showDownloadError,saveAs,isDocumentCenter,preview,setloading,setDownloadFileURL,setWasClicked,buttontext,text,wasClicked,dispatch,previewFlag,optionalScreenReaderMsg) =>
{
    if(messageComponentIndentifier == "messageCenter")
    {
        if (Boolean(downloadedFile) === true && !showDownloadError) {
            if (!isDocumentCenter) {
                handlePreviewOrDownload(downloadedFile,saveAs,preview);
            } else if (text !== 'View' && wasClickedStatus) {
                setDocumentUrl(setloading,setDownloadFileURL,downloadedFile,true)
            } else if (buttontext !== 'View' && wasClickedStatus) {
                setDocumentUrl(setloading,setDownloadFileURL,downloadedFile,true)
            }
            setloading(false);
            setWasClicked(false);
            dispatch(downloadCompleted());
        }
    }
    else
    {
        if (Boolean(downloadedFile) === true && wasClicked && !showDownloadError) {
            if (isDocumentCenter  && previewFlag != "preview") {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(downloadedFile, saveAs);
                } else {
                    let downloadTag = document.createElement("a");
                    downloadTag.href = URL.createObjectURL(downloadedFile, saveAs);
                    downloadTag.download = `${optionalScreenReaderMsg}`;
                    downloadTag.click();
                }
            } else if (text !== 'View') {
                setDocumentUrl(setloading,setDownloadFileURL,downloadedFile,false)
            } else if (buttontext !== 'View') {
                setDocumentUrl(setloading,setDownloadFileURL,downloadedFile,false)
            }
            setloading(false);
            setWasClicked(false);
            dispatch(downloadCompleted());
        }
    }
}

const showErrorOrDownloadFile = ({
    setloading,
    dispatch,
    downloadedFile,
    saveAs,
    showDownloadError,
    showError,
    wasClicked,
    isDocumentCenter,
    text,
    buttontext,
    setShowDownloadError,
    setDownloadFileURL,
    setWasClicked,
    messageComponentIndentifier,
    loading,
    optionalScreenReaderMsg,
    previewFlag,
    preview,


}) => {
    if (showError) {
        setloading(false);
        setShowDownloadError(true);
    }
    handleDocumentOpening(messageComponentIndentifier,downloadedFile,showDownloadError,saveAs,isDocumentCenter,preview,setloading,setDownloadFileURL,setWasClicked,buttontext,text,wasClicked,dispatch, previewFlag,optionalScreenReaderMsg);
    // if(messageComponentIndentifier == "messageCenter")
    // {
    //     if (Boolean(downloadedFile) === true && !showDownloadError) {
           
    //         if (!isDocumentCenter) {
    
    //             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //                window.navigator.msSaveOrOpenBlob(downloadedFile, saveAs);
    //             } else {
    //                window.open(URL.createObjectURL(downloadedFile, saveAs));
    //             }
    //         } else if (text !== 'View' && wasClickedStatus == true) {
    //             setloading(false);
    //             setDownloadFileURL(downloadedFile);
    //             wasClickedStatus = false;
    //         } else if (buttontext !== 'View' && wasClickedStatus == true) {
    //             setloading(false);
    //             setDownloadFileURL(downloadedFile);
    //             wasClickedStatus = false;
    //         }
    //         setloading(false);
    //         setWasClicked(false);
    //         dispatch(downloadCompleted());
    //     }
    // }
    // else
    // {
    //     if (Boolean(downloadedFile) === true && wasClicked && !showDownloadError) {
    //         if (isDocumentCenter  && previewFlag != "preview") {
    //             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //                 window.navigator.msSaveOrOpenBlob(downloadedFile, saveAs);
    //             } else {
    //                 let downloadTag = document.createElement("a");
    //                 downloadTag.href = URL.createObjectURL(downloadedFile, saveAs);
    //                 downloadTag.download = `${optionalScreenReaderMsg}`;
    //                 downloadTag.click();
    //             }
    //         } else if (isDocumentCenter == true && previewFlag == "preview") {
    //             window.open(URL.createObjectURL(downloadedFile, saveAs));
    //         } else if (text !== 'View') {
    //             setloading(false);
    //             setDownloadFileURL(downloadedFile);
    //         } else if (buttontext !== 'View') {
    //             setloading(false);
    //             setDownloadFileURL(downloadedFile);
    //         }
    //         setloading(false);
    //         setWasClicked(false);
    //         dispatch(downloadCompleted());
    //     }
    // }
};

const OPENS_PDF_IN_MODAL_MSG = `opens a PDF in modal window`;

function DownloadFile(props) {
    const {
        nodeId,
        text,
        buttontext,
        iconSrc,
        saveAs,
        linkStyle,
        iconStyle,
        downloadUrl,
        docCategory,
        documentSource,
        alternateDocumentId,
        downloadUrlRequiresAuth,
        optionalScreenReaderMsg,
        contentStyle,
        isDocumentCenter,
        unread,
        opens,
        messageComponentIndentifier,
        previewFlag
    } = props;

    const dispatch = useDispatch();
   
    const downloadedFileData = useSelector(state => state.downloadedFileData);
    const [wasClicked, setWasClicked] = useState(false);
    const { downloadedFile, showError,isLoading } = downloadedFileData;
    const [loading, setloading] = useState(isLoading)
    const [showDownloadError, setShowDownloadError] = useState(showError);
    const [downloadFileURL, setDownloadFileURL] = useState(null);
    const DOWNLOAD_ERROR_MSG = `Sorry! Unable to download document ${saveAs}`;
    const DISPLAY_PDF_IN_TAB_MSG = `Opens a ${opens} in new window`;

    const opensInNewTab = !isDocumentCenter || text === 'View';
    function handleKeyPress(e) {
        if (e.charCode === 13) {
            downloadFile({
                setloading,
                downloadUrl,
                saveAs,
                setWasClicked,
                dispatch,
                setShowDownloadError,
                downloadUrlRequiresAuth,
                setDownloadFileURL,
                isDocumentCenter,
                text,
                buttontext,
                alternateDocumentId,
                docCategory,
                documentSource,
                nodeId,
                unread,
            })(e);
        }
    }

    function handleHide() {
        setDownloadFileURL(null);
    }

    useEffect(() => {
           
            showErrorOrDownloadFile({
                setloading,
                dispatch,
                downloadedFile,
                saveAs,
                showDownloadError,
                showError,
                wasClicked,
                isDocumentCenter,
                text,
                buttontext,
                setShowDownloadError,
                setDownloadFileURL,
                setWasClicked,
                messageComponentIndentifier,
                loading,
                optionalScreenReaderMsg,
                previewFlag
            });
       
    }, [dispatch, downloadedFile, saveAs, showDownloadError, showError, wasClicked, isDocumentCenter, text, buttontext]);

    const DOWNLOAD_SR_TEXT = `${text}${buttontext}${optionalScreenReaderMsg} ${
        opensInNewTab ? DISPLAY_PDF_IN_TAB_MSG : OPENS_PDF_IN_MODAL_MSG
    }`;
    return (
        <>
            {showDownloadError &&  wasClicked &&  (
                <S.Alert
                    show={showDownloadError && wasClicked}
                    alertmsg={DOWNLOAD_ERROR_MSG}
                    className="alertWrapper"
                    autoFocus
                />
            )}
            {loading && 
                <WSpinner  
                aria-busy="true"
                aria-live="polite"
                aria-label="Please wait while we load message(s)"
                loading={loading} />
            }

            {downloadFileURL && isDocumentCenter && (
                <PrintPdf
                    file={downloadFileURL}
                    show
                    handleHide={handleHide}
                    saveAs={saveAs}
                    isDocumentCenter
                    maximumModalHeight={window.innerHeight}
                    title={optionalScreenReaderMsg}
                />
            )}
            {!(showDownloadError && wasClicked) && (
                <S.AddSection
                    role={opensInNewTab ? null : 'button'}
                    contentStyle={contentStyle}
                    onClick={downloadFile({
                        setloading,
                        downloadUrl,
                        saveAs,
                        setWasClicked,
                        dispatch,
                        setShowDownloadError,
                        downloadUrlRequiresAuth,
                        setDownloadFileURL,
                        isDocumentCenter,
                        text,
                        buttontext,
                        alternateDocumentId,
                        docCategory,
                        documentSource,
                        nodeId,
                        unread,
                    })}
                    onKeyPress={handleKeyPress}
                    data-testid="vcm-download-link-or-button"
                >
                    {' '}
                    {iconSrc?.length > 0 && <S.Icon src={iconSrc} alt="" className="ml-0" style={iconStyle} />}

                    {buttontext ? 
                    <S.Button className="viewbuttonstyle"> 
                        <span className="viewbuttonText" id={buttontext}>
                            {buttontext}
                        </span>
                    </S.Button> : 
                    <S.HeadingLink style={linkStyle}>
                        <span aria-hidden="true">{text}</span>
                        <span className="sr-only">
                            {DOWNLOAD_SR_TEXT}
                        </span>
                    </S.HeadingLink>}
                </S.AddSection>
            )}
        </>
    );
}

DownloadFile.defaultProps = {
    text: 'Download',
    saveAs: 'sample.pdf',
    linkStyle: {},
    iconStyle: {},
    iconSrc: '',
    downloadUrl: '',
    nodeId: '',
    docCategory: '',
    documentSource: '',
    alternateDocumentId: '',
    downloadUrlRequiresAuth: false,
    optionalScreenReaderMsg: null,
    contentStyle: 'flex',
    isDocumentCenter: false,
    unread: false,
    opens: 'PDF',
};

DownloadFile.propTypes = {
    nodeId: PropTypes.string,
    iconSrc: PropTypes.string,
    text: PropTypes.string,
    saveAs: PropTypes.string,
    linkStyle: PropTypes.instanceOf(Object),
    iconStyle: PropTypes.instanceOf(Object),
    downloadUrl: PropTypes.string,
    docCategory: PropTypes.string,
    documentSource: PropTypes.string,
    alternateDocumentId: PropTypes.string,
    downloadUrlRequiresAuth: PropTypes.bool,
    optionalScreenReaderMsg: PropTypes.string,
    contentStyle: PropTypes.string,
    isDocumentCenter: PropTypes.bool,
    unread: PropTypes.bool,
    opens: PropTypes.string,
};

export default React.memo(DownloadFile);