/**
 * Filter Funds Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to filter funds
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { WMultipleCheckbox } from '../../../common';
import styles from './styles';

export default class FilterFundsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterMinimumInvestmentList: [],
            filterRiskList: [],
            filterFundTypeList: [],
        };
    }

    static getDerivedStateFromProps(props) {
        const { minimumInvestmentList, riskList, fundTypeList } = props;
        if (props.clearCheckedFilters) {
            for (let i = 0; i < minimumInvestmentList.length; i += 1) {
                minimumInvestmentList[parseInt(i, 10)].checked = false;
            }
            for (let j = 0; j < riskList.length; j += 1) {
                riskList[parseInt(j, 10)].checked = false;
            }
            for (let k = 0; k < fundTypeList.length; k += 1) {
                fundTypeList[parseInt(k, 10)].checked = false;
            }
            props.parent();
            return { filterMinimumInvestmentList: [], filterRiskList: [], filterFundTypeList: [] };
        }
        return {};
    }

    onMILClick = e => {
        const { minimumInvestmentList, onFilterMinimumInvestmentList, minimumInvestmentListSel } = this.props;
        let { filterMinimumInvestmentList } = this.state;
        filterMinimumInvestmentList = minimumInvestmentListSel;
        for (let i = 0; i < minimumInvestmentList.length; i += 1) {
            if (minimumInvestmentList[parseInt(i, 10)].value === e.target.value) {
                minimumInvestmentList[parseInt(i, 10)].checked = e.target.checked;
            }
        }

        if (e.target.checked) {
            filterMinimumInvestmentList.push(e.target.value);
        } else {
            const index = filterMinimumInvestmentList.indexOf(e.target.value);
            if (index > -1) {
                filterMinimumInvestmentList.splice(index, 1);
            }
        }
        onFilterMinimumInvestmentList(filterMinimumInvestmentList);
    };

    onRiskClick = e => {
        const { riskList, onFilterRiskList, riskListSel } = this.props;
        let { filterRiskList } = this.state;
        filterRiskList = riskListSel;
        for (let i = 0; i < riskList.length; i += 1) {
            if (riskList[parseInt(i, 10)].value === e.target.value) {
                riskList[parseInt(i, 10)].checked = e.target.checked;
            }
        }
        if (e.target.checked) {
            filterRiskList.push(e.target.value);
        } else {
            const index = filterRiskList.indexOf(e.target.value);
            if (index > -1) {
                filterRiskList.splice(index);
            }
        }
        onFilterRiskList(filterRiskList);
    };

    onFundTypeClick = e => {
        const { fundTypeList, onFilterFundTypeList, fundTypeListSel } = this.props;
        let { filterFundTypeList } = this.state;
        filterFundTypeList = fundTypeListSel;
        for (let i = 0; i < fundTypeList.length; i += 1) {
            if (fundTypeList[parseInt(i, 10)].value === e.target.value) {
                fundTypeList[parseInt(i, 10)].checked = e.target.checked;
            }
        }
        if (e.target.checked) {
            filterFundTypeList.push(e.target.value);
        } else {
            const index = filterFundTypeList.indexOf(e.target.value);
            if (index > -1) {
                filterFundTypeList.splice(index);
            }
        }
        onFilterFundTypeList(filterFundTypeList);
    };

    render() {
        const { clearCheckedFilters, minimumInvestmentList, riskList, fundTypeList } = this.props;
        const minimumInvestment = 'Minimum Investment';
        const risk = 'Risk';
        const fundType = 'Fund Type';
        return (
            <div className="container-fluid" style={styles.filterContainerStyle}>
                <Row>
                    <Col>
                        <p style={styles.filterHeadingText}>{minimumInvestment}</p>
                        <WMultipleCheckbox
                            clearchecked={clearCheckedFilters}
                            data={minimumInvestmentList}
                            onChange={this.onMILClick}
                        />
                    </Col>
                    <Col>
                        <p style={styles.filterHeadingText}>{risk}</p>
                        <WMultipleCheckbox
                            clearchecked={clearCheckedFilters}
                            data={riskList}
                            info="true"
                            onChange={this.onRiskClick}
                        />
                    </Col>
                    <Col>
                        <p style={styles.filterHeadingText}>{fundType}</p>
                        <WMultipleCheckbox
                            clearchecked={clearCheckedFilters}
                            data={fundTypeList}
                            onChange={this.onFundTypeClick}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

FilterFundsComponent.propTypes = {
    clearCheckedFilters: PropTypes.bool,
    minimumInvestmentList: PropTypes.instanceOf(Array),
    riskList: PropTypes.instanceOf(Array),
    fundTypeList: PropTypes.instanceOf(Array),
    onFilterMinimumInvestmentList: PropTypes.func,
    onFilterFundTypeList: PropTypes.func,
    onFilterRiskList: PropTypes.func,
    parent: PropTypes.func,
    minimumInvestmentListSel: PropTypes.instanceOf(Array),
    riskListSel: PropTypes.instanceOf(Array),
    fundTypeListSel: PropTypes.instanceOf(Array),
};

FilterFundsComponent.defaultProps = {
    clearCheckedFilters: false,
    minimumInvestmentList: [],
    riskList: [],
    fundTypeList: [],
    onFilterMinimumInvestmentList: () => {},
    onFilterFundTypeList: () => {},
    onFilterRiskList: () => {},
    parent: () => {},
    minimumInvestmentListSel: [],
    riskListSel: [],
    fundTypeListSel: [],
};
