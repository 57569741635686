import React from 'react';
// import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import data from './styles';

const {StyledTable} = data;

const ConfirmSecurityQuestionsForm = (props) => {
    const { formattedData } = props;
    return (<>
        <StyledTable Caption="Security Questions" borderless={false} size='md' role="presentation" >
            {formattedData.map(info => {
                return (
                    <tbody key={info.id} className='securityAnswers' >
                        {info.title ? <>
                            <tr>
                                <th style={info.id === 'question1' ? data.th1Style : data.thStyle} colSpan="2">{info.title}</th>
                            </tr>
                            <tr>
                                <td style={data.qlabelStyle}>{info.label}</td>
                                <td style={data.qdescStyle}>{info.desc}</td>
                            </tr>
                        </>
                            : <tr>
                                <td style={data.labelStyle}>{info.label}</td>
                                <td style={data.descStyle}>{info.desc}</td>
                            </tr>}
                    </tbody>
                )
            })}
        </StyledTable> </> )
}

ConfirmSecurityQuestionsForm.propTypes = {
    formattedData: PropTypes.instanceOf(Array)
}

ConfirmSecurityQuestionsForm.defaultProps = {
    formattedData: [],
}

export default ConfirmSecurityQuestionsForm;
