import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MessageCenterActionsComponent from './MessageCenterActionsComponent';
import MessageCenterMessagesComponent from './MessageCenterMessagesComponent';
import MessageListComponent from './MessageListComponent';
import {
    getMessageCenterMessages,
    deleteMessages,
    showConfirmDialog,
    showSuccessDialog,
    setLoginDisplayMessage,
} from '../../../shared/Actions/MessageCenterActions';
import * as MCConstants from './constants';
import { WMessageBar, ConformationModal, WBreadCrumb, WSpinner } from '../../../common';
import { USAA_FUND_COMPANY_NUMBER } from 'common/Constants';

const breadCrumbValues = [{ url: '/accountHolder', name: 'Home' }];

const MessageCenterContainer = styled(Container)`
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
`;

const UnreadMessageSinceLastLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    font-family: benton-sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
`;

const MessageCenter = () => {
    // This is a dispatch function for actions
    const dispatch = useDispatch();
    const mcConfirmationMsg = useSelector(state => state.messageCenterData.mcConfirmationMsg);
    const lastLoginTime = useSelector(state => state.loginData.lastLogin);
    const showSuccessMsgDialog = useSelector(state => state.messageCenterData.showSuccessMsgDialog);
    const showMsgConfirmDialog = useSelector(state => state.messageCenterData.showMsgConfirmDialog);
    const selectedMessages = useSelector(state => state.messageCenterData.selectedMessages);
    const messages = useSelector(state => state.messageCenterData.messages);
    const unreadMessages = useSelector(state => state.messageCenterData.unreadMessages);
    const messageType = useSelector(state => state.messageCenterData.messageType);
    const isLoading = useSelector(state => state.messageCenterData.isLoading);
    const isLoadingMessages = useSelector(state => state.messageCenterData.isLoadingMessages);
    const pageSize = useSelector(state => state.messageCenterData.pageSize);
    const orderBy = useSelector(state => state.messageCenterData.orderBy);
    const orderType = useSelector(state => state.messageCenterData.orderType);
    const showLastLogin = useSelector(state => state.messageCenterData.showLoginMessage);
    const [loading, setLoading] = useState(isLoading);
    const token = localStorage.getItem('token');

    const history = useHistory();
    useEffect(() => {
        if (!loading) {
            dispatch(
                getMessageCenterMessages(
                    messageType,
                    null,
                    pageSize,
                    orderBy,
                    orderType,
                    token,
                    USAA_FUND_COMPANY_NUMBER.companyNumber,
                ),
            );
        }
    }, [dispatch, loading, messageType, pageSize, orderBy, orderType, token]);

    useEffect(() => {
        if (isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isLoading]);

    useEffect(() => {
        setInterval(() => {
            dispatch(setLoginDisplayMessage());
        }, MCConstants.MESSAGE_CENTER_LAST_LOGIN_DISPLAY_TIMER);
    }, []);

     function handleDelete() {
        const messageIds = selectedMessages.map(message => message.skCreatedAt);
         dispatch(deleteMessages({ data: { messageIds }, token: localStorage.getItem('token') }));
        if (messageType === MCConstants.MESSAGE_CENTER_SENT_VAL) {
            history.goBack();
        }
    }

    function handleCancel() {
        dispatch(showConfirmDialog(false));
        dispatch(showSuccessDialog(false));
        history.push({
            pathname: '/messageCenter',
        });
    }

    function handleClose() {
        dispatch(showSuccessDialog(false));
    }

    function getLoginTimeInfo(date) {
        const dateVal = date.split(', ')[0];
        const timeVal = date.split(', ')[1];
        return {
            day: dateVal.split('/')[0],
            month: dateVal.split('/')[1],
            year: dateVal.split('/')[2],
            hour: timeVal.split(':')[0],
            min: timeVal.split(':')[1],
            sec: timeVal.split(':')[2],
        };
    }

    function getFormattedLoginTime(UTCLastLogin) {
        const month = UTCLastLogin.getMonth();
        const date = UTCLastLogin.getDate();
        const year = UTCLastLogin.getFullYear();
        const mins = UTCLastLogin.getMinutes();
        const hours = UTCLastLogin.getHours();
        const seconds = UTCLastLogin.getSeconds();
        return `${month + 1}/${date}/${year} ${hours}:${mins}:${seconds}`;
    }

    function getLastLoginMessage() {
        // convert last login to UTC time
        const loginTimeInfo = getLoginTimeInfo(lastLoginTime);
        const { day, month, year, hour, min, sec } = loginTimeInfo;
        const UTCLastLogin = new Date(Date.UTC(year, month - 1, day, hour, min, sec));
        let msgCountLL = 0;
        let msgCreatedTime = '';
        let msgCreatedUTC = '';

        // Code to test by changing epoch time
        // let newMessages = [...messages];
        // newMessages = newMessages.map(newMsg => {
        //     return { ...newMsg, skCreatedAt: 'wm-1698554345' };
        // });

        if (unreadMessages && unreadMessages !== 0) {
            messages.map(message => {
                msgCreatedTime = +message.skCreatedAt.split('-')[1];
                if (msgCreatedTime) {
                    msgCreatedTime = +msgCreatedTime;
                }
                if (msgCreatedTime && msgCreatedTime.toString().length === 10) {
                    msgCreatedTime *= 1000; // Converting to milliseconds
                }

                msgCreatedUTC = new Date(msgCreatedTime);
                if (UTCLastLogin < msgCreatedUTC && !message.isRead) {
                    msgCountLL += 1;
                }
                return {};
            });
            if (msgCountLL > 5) {
                return `${MCConstants.MESSAGE_CENTER_MORE_THAN_FIVE_MESSAGE} (${getFormattedLoginTime(UTCLastLogin)})`;
            }
            return `You have ${msgCountLL === 0 ? 'no' : msgCountLL} new message${ (msgCountLL > 1 || msgCountLL < 1) ? 's' : ''} since your last login (${getFormattedLoginTime(UTCLastLogin)})`;
        }
        return `${MCConstants.MESSAGE_CENTER_NO_NEW_MESSAGE} (${getFormattedLoginTime(UTCLastLogin)})`;
    }

    return (
        <>
            {isLoadingMessages && <WSpinner loading={isLoadingMessages} />}
            {/* eslint-disable-next-line */}
            {showSuccessMsgDialog && <WMessageBar text={{ message: `${mcConfirmationMsg}` }} onClose={handleClose} />}
            <MessageCenterContainer data-test="container">
                {showMsgConfirmDialog && (
                    <ConformationModal
                        modalTitle={MCConstants.MESSAGE_CENTER_DELETE_MODAL_TITLE}
                        modalBodyText={MCConstants.MESSAGE_CENTER_DELETE_MODAL_BODY_TEXT}
                        primaryButtonText={MCConstants.MESSAGE_CENTER_DELETE}
                        secondaryButtonText={MCConstants.MESSAGE_CENTER_CANCEL}
                        onPrimaryClick={handleDelete}
                        onSecondaryClick={handleCancel}
                    />
                )}
                <WBreadCrumb
                    activeCrumb={MCConstants.MESSAGE_CENTER_MESSAGE_CENTER}
                    breadCrumbItems={breadCrumbValues}
                />
                <MessageCenterActionsComponent />
                {messageType === MCConstants.MESSAGE_CENTER_INBOX_VAL && showLastLogin && (
                    <UnreadMessageSinceLastLogin>{getLastLoginMessage()}</UnreadMessageSinceLastLogin>
                )}
                <MessageCenterMessagesComponent />
            </MessageCenterContainer>
            <MessageListComponent />
        </>
    );
};

export default MessageCenter;
