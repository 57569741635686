/**
 * generateEmploymentInfoCard Method Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the employment info card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generateEmploymentInfoCard(masterLookupStateData, empInfo, heading) {
    let employeeInfoCard = {};
    const { empJoint, empStatus, empInfoH, staticfield } = Consts;
    let empStatusData = '';
    let empIndustryData = '';
    if (empInfo) {
        let empformatAddress = Consts.None;
        const { lineOne = '', lineTwo = '', cityName = '', stateName = '', zipCode = '' } = empInfo.empAddress || {};
        const empLineOne = lineOne === '' ? '' : `${lineOne}, `;
        empformatAddress = `${empLineOne}${lineTwo}, ${cityName}, ${stateName}-${zipCode}`;

        const empStatusList = masterLookupStateData['employment_status'.toString()].value;
        empStatusData = empInfo.empStatus && empStatusList.find(item => item.key === empInfo.empStatus).value;

        const empIndustryList = masterLookupStateData['industry'.toString()].value;
        empIndustryData = empInfo.industry && empIndustryList.find(item => item.key === empInfo.industry).value;

        employeeInfoCard = {
            cardId: heading !== undefined ? 'jointEmpInfo' : 'empInfo',
            linkText: 'Edit',
            cardHeading: heading !== undefined ? empJoint : empInfoH,
            fields: [
                {
                    uniqueKey: 'employmentStatus',
                    key: empStatus,
                    value: empStatusData,
                    type: staticfield,
                },
                {
                    uniqueKey: 'industry',
                    key: Consts.industry,
                    value: empIndustryData || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'occupation',
                    key: Consts.occ,
                    value: empInfo.occupation || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'employerName',
                    key: Consts.empName,
                    value: empInfo.employersName || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'employerAddress',
                    key: Consts.empAddress,
                    value: empformatAddress === ', , -' ? Consts.None : empformatAddress || Consts.None,
                    type: staticfield,
                },
            ],
        };
    }

    if (empStatusData === 'Other') {
        const employeeClassInfo = {
            uniqueKey: 'employeeClassInfo',
            key: Consts.empClassInfo,
            value: empInfo.empClassInfo || Consts.None,
            type: staticfield,
        };
        employeeInfoCard.fields.splice(1, 0, employeeClassInfo);
    }
    const notEmployedArray = ['Retired', 'Homemaker', 'Student', 'Unemployed'];
    if (notEmployedArray.includes(empStatusData)) {
        const primSourceList = masterLookupStateData['prim_src_income'.toString()].value;
        const primSourceData =
            empInfo.primarySourceofIncome &&
            primSourceList.find(item => item.key === empInfo.primarySourceofIncome).value;
        const employeeClassInfo = {
            uniqueKey: 'primarySourceOfIncome',
            key: Consts.primarySourceOfIncome,
            value: primSourceData || Consts.None,
            type: staticfield,
        };

        employeeInfoCard.fields.splice(1, 0, employeeClassInfo);
        employeeInfoCard.fields.length = 2;
    }
    return employeeInfoCard;
}
