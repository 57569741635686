import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

const StyledRow = styled(Row)`
    margin-bottom: 30px;
`;

const StyledField = styled(Col)`
    padding: 4px 9px;
    max-width: 50%;
    word-break: break-word;
    margin: auto;
`;

const FieldName = styled(StyledField)`
    text-align: right;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const FieldValue = styled(StyledField)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const FieldValueRow = (props) => {
    const { field, value } = props;
    const fieldWithColon = `${field} :`;
    return (
        <StyledRow>
            <FieldName>{fieldWithColon}</FieldName>
            <FieldValue>{value}</FieldValue>
        </StyledRow>
    );
};

FieldValueRow.propTypes = {
    field: PropTypes.string,
    value: PropTypes.string,
};

FieldValueRow.defaultProps = {
    field: "",
    value: "",
};

export default memo(FieldValueRow);
