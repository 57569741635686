import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FundSummaryComponent from './FundSummaryComponent';
import { accOpeningActions, localStateManagementActions } from '../../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundSummaryComponent));
