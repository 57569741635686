import { 
    bankAccountAction, 
    fundAccountActions, 
    addAutomaticInvestmentActions, 
    addSystematicWithdrawalActions,
    analytics,
    purchaseActions 
} from '../../../../shared/Actions';
import { connect } from 'react-redux';
import EditInvestmentOrWithdrawalPlan from './EditPlanComponent';
import { get } from 'lodash';
import { withRouter } from 'react-router';

export const dismissUpdateError = () => dispatch => {
    dispatch({
        type: 'DISMISS_GET_FUND_PLAN_DETAILS_FAILURE'
    });
}

export const mapStateToProps = state => {
    let errorsData = {};

    if(Object.keys(get(state, 'systematicWithdrawalData.error', {})).length) errorsData = get(state, 'systematicWithdrawalData.error');
    else if(Object.keys(get(state, 'automaticInvestmentData.error', {})).length) errorsData = get(state, 'automaticInvestmentData.error');
    else if(Object.keys(get(state, 'fundPlanData.error', {})).length) errorsData = get(state, 'fundPlanData.error');
    else if(Object.keys(get(state, 'fundAccountData.error', {})).length) errorsData = get(state, 'fundAccountData.error');

    return {
        bankAccountReducerData: get(state, 'bankAccountReducerData', []),
        fundPlanData: get(state, 'fundPlanData', []),
        fundAccountData: get(state, 'fundAccountData', []),
        rmdReducerData: get(state, 'rmdReducerData', {}),
        // isSuccessfullySaved: get(state, 'automaticInvestmentData.isSuccess', false) && get(state, 'automaticInvestmentData.isUpdate', false),
        isSuccessfullySaved: (
            (get(state, 'automaticInvestmentData.isSuccess', false) && get(state, 'automaticInvestmentData.isUpdate', false)) ||
            (get(state, 'systematicWithdrawalData.isSuccess', false) && get(state, 'systematicWithdrawalData.isUpdate', false))
        ),
        hasError: get(state, 'systematicWithdrawalData.isError', false) || get(state, 'automaticInvestmentData.isError', false) || get(state, 'fundPlanData.isError', false) || get(state, 'fundAccountData.isError', false),
        errorsData,
        isSaving: (
            get(state, 'fundPlanData.isLoading', false) ||
            get(state, 'automaticInvestmentData.isLoading', false) ||
            get(state, 'systematicWithdrawalData.isLoading', false)
        ),
        purchaseReducerData: state.purchaseData,
    };
};

const mapDispatchToProps = {
    getBankAccountInfo: bankAccountAction.getBankAccounts,
    getAccountList: fundAccountActions.getAccountList,
    clearFundPlanInfo: fundAccountActions.clearFundPlanInfo,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    updatePADDetails: addAutomaticInvestmentActions.updatePADDetails,
    updateSWPDetails: addSystematicWithdrawalActions.updateSWPDetails,
    dismissUpdateError,
    getFundAccountById: fundAccountActions.getFundAccountById,
    analyzeClickEvent: analytics.analyzeClickEvent,
    analyzeFormAdandonment: analytics.analyzeFormAdandonment,
    getIRAContributionValues: purchaseActions.getIRAContributionValues,
    acceptIRAContributionWarning: purchaseActions.acceptIRAContributionWarning,
    cancelIRAContributionWarning: purchaseActions.cancelIRAContributionWarning,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditInvestmentOrWithdrawalPlan));