import get from 'lodash/get';
import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import { linkResponseSuccess, linkResponseError } from "./AnalyticsActions";

export const getPADDetails = (payload) => dispatch => {
  const env = "";
  dispatch({
    type: ActionTypes.AUTO_INVEST_GET_PAD_DETAILS
  });
  makeApiRequest(
    ActionTypes.AUTO_INVEST_GET_PAD_DETAILS,
    payload,
    env,
    response => {
      if (response.data && response.data.result) {
        dispatch({
          type: ActionTypes.AUTO_INVEST_GET_PAD_DETAILS_SUCCESS,
          response: response.data.result
        });
        dispatch(linkResponseSuccess());
      } else {
        dispatch({
          type: ActionTypes.AUTO_INVEST_GET_PAD_DETAILS_FAILURE,
          response: response.data
        });
        dispatch(linkResponseError(response));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.AUTO_INVEST_GET_PAD_DETAILS_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(error.data));
    }
  );
};

export const updatePADDetails = payload => dispatch => {
  const env = '';
  dispatch({
    type: ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS
  });
  makeApiRequest(
    ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS,
    payload,
    env,
    response => {
      if (response.data) {
        dispatch({
          type: ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS_SUCCESS,
        });
        dispatch(linkResponseSuccess());
      }
      else{
        dispatch({
          type: ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS_FAILURE,
        });
        dispatch(linkResponseError(response));
      }
    },
    error => {
      const errorMsg = get(error, 'response.data', { msg: { response: 'unknown error' } }); // will display the default error message
      dispatch({
        type: ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS_FAILURE,
        response: errorMsg 
      });
      dispatch(linkResponseError(errorMsg));
    }
  );
};

// const parseError = (error) => {
//   const parsedError = JSON.parse(error);
//   if (!error || !parsedError || (parsedError && !parsedError.messages)) {
//     return {
//       msg: 'Unknown error!'
//     };
//   }

//   const errorMessage = '';
//   parsedError.messages.forEach(err => {
//     errorMessage = `${errorMessage} ${err.msg}`;
//   });

//   return {
//     msg: errorMessage
//   };

// };

export const addPADDetails = payload => dispatch => {
    // SETTING ENVIRONMENT
    const env = '';
    // DISPATCHING ACTION FOR LOADER
    dispatch({
      type: ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS
    });
    // MAKE API REQUEST
    makeApiRequest(
      ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS,
      payload,
      env,
      response => {
        if (response.data && response.data.result && response.data.result.success) {
          // DISPATCHING SCUCCESS ACTION
          dispatch({
            type: ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS_SUCCESS,
            response: response.data.result
          });
        dispatch(linkResponseSuccess());
      } else {
          dispatch({
            type: ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS_FAILURE,
            response: response.data.result
          });
        dispatch(linkResponseError(response.data.result.message));
      }
      },
      error => {
        // DISPATCHING FAILURE ACTION
        dispatch({
          type: ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS_FAILURE,
          response: error.response
        });
        dispatch(linkResponseError(error.response));
      }
    );
};

const replaceUndefinedOrNull = (key, value) => {
  if (value === null || value === undefined || value === "") {
    return undefined;
  }
  return value;
};

const getCleanedPayload = payload => {
  const cleanedObject = JSON.stringify(payload, replaceUndefinedOrNull, 4);
  return JSON.parse(cleanedObject);
};

export const skipAutoInvestPlan = data => dispatch => {
  const payload = getCleanedPayload(data);
  const env = "MOCKTEST";
  dispatch({
    type: ActionTypes.SKIP_INVEST_WITHDRAW_PLAN
  });
  makeApiRequest(
    ActionTypes.SKIP_INVEST_WITHDRAW_PLAN,
    payload,
    env,
    response => {
      dispatch({
        type: ActionTypes.SKIP_INVEST_WITHDRAW_PLAN_SUCCESS,
        response: response.data
      });
    },
    error => {
      dispatch({
        type: ActionTypes.SKIP_INVEST_WITHDRAW_PLAN_FAILURE,
        response: error.data
      });
    }
  );
};

export const deleteAutoInvestPlan = payload => dispatch => {
  dispatch({
    type: ActionTypes.DELETE_INVEST_WITHDRAW_PLAN
  });
  makeApiRequest(
    ActionTypes.DELETE_INVEST_WITHDRAW_PLAN,
    payload,
    null,
    response => {
      if (response.data && response.data.result) {
        dispatch({
          type: ActionTypes.DELETE_INVEST_WITHDRAW_PLAN_SUCCESS,
          response: response.data
        });
        dispatch(linkResponseSuccess());
      } 
      else{
        dispatch({
          type: ActionTypes.DELETE_INVEST_WITHDRAW_PLAN_FAILURE,
          response: response.data
        });
        dispatch(linkResponseError(response.data));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.DELETE_INVEST_WITHDRAW_PLAN_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(error));
    }
  );
};

export const resetDeleteFlag = () => dispatch => {
  dispatch({
    type: ActionTypes.RESET_DELETE_FLAG
  });
};

export const clearError = () => dispatch =>{
  dispatch({
    type: ActionTypes.CLEAR_ERROR
  });
};

export const clearReduxKeyData = () => dispatch => {
  dispatch({
    type: ActionTypes.RESET_EXT_LIST
  });
};

export const dismissError = () => dispatch => {
  dispatch({
      type: ActionTypes.DISMISS_INVESTMENT_WITHDRAWAL_ERROR
  });
};
