/**
 * Employment Info Card Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Employment Information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Accordion } from 'react-bootstrap';
import Consts from '../PersonalInfo/consts';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import { WInput, VCMSelect } from '../../../common';
import Address from '../Address';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';

class EmploymentInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empStatus: '',
            empStatusOther: '',
            empClassInfo: '',
            industry: '',
            occupation: '',
            employersName: '',
            primarySourceofIncome: '',
            employerAddress: {
                addressLine1: '',
                addressLine2: '',
                zip: '',
                state: '',
                city: '',
            },
            errorMsg: [],
        };
        this.empAddressPref = React.createRef();
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {};
    }

    componentDidMount() {
        const { localStateData } = this.props;
        if (localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo) {
            const { employmentInfo } = localStateData.OpenAccPageTwo.personalInfo;
            if (employmentInfo) {
                const {
                    empStatus,
                    empStatusOther,
                    empClassInfo,
                    industry,
                    occupation,
                    employersName,
                    primarySourceofIncome,
                    employerAddress,
                } = employmentInfo;
                this.setState({
                    empStatus,
                    empStatusOther,
                    empClassInfo,
                    industry,
                    occupation,
                    employersName,
                    primarySourceofIncome,
                    employerAddress: {
                        addressLine1: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.addressLine1,
                        addressLine2: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.addressLine2,
                        zip: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.zip,
                        state: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.state,
                        city: JSON.stringify(employerAddress) === '{}' ? '' : employerAddress.city,
                    },
                });
            }
        }
    }

    checkEmployeementstatus = errorMsg => {
        const { empStatus } = this.state;
        return !!empStatus && !errorMsg.empStatus;
    };

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        const err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState(() => {
            const result = this.checkEmployeementstatus(errorMsg);
            return {
                errorMsg,
                ready: result,
            };
        });
    };

    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        if (eventKey === 'empStatus') {
            const err = Rules[eventKey.toString()]
                ? checkValidity({
                      rules: Rules[eventKey.toString()],
                      value: e.target.value,
                  })
                : '';
            errorMsg[eventKey.toString()] = err;
            this.setState({
                [eventKey]: e.target.value,
                errorMsg,
                empStatusOther: '',
                industry: '',
                occupation: '',
                employersName: '',
                primarySourceofIncome: '',
                employerAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    zip: '',
                    state: '',
                    city: '',
                },
            });
        } else if (eventKey === 'industry') {
            const err = Rules.industry
                ? checkValidity({
                      rules: Rules.industry,
                      value: e.target.value,
                  })
                : '';
            errorMsg.industry = err;
            this.setState({ [eventKey]: e.target.value, errorMsg });
        } else {
            this.setState({ [eventKey]: e.target.value, errorMsg });
        }
    };

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    validateFields = () => {
        const { empStatus, errorMsg, primarySourceofIncome, industry } = this.state;
        const notEmployeeArray = ['retired', 'homemaker', 'student', 'unemployed'];
        let valid = true;
        let err = Rules.empStatus
            ? checkValidity({
                  rules: Rules.empStatus,
                  value: empStatus,
              })
            : '';
        errorMsg.empStatus = err;
        if (notEmployeeArray.includes(empStatus)) {
            err = Rules.primarySourceofIncome
                ? checkValidity({
                      rules: Rules.primarySourceofIncome,
                      value: primarySourceofIncome,
                  })
                : '';
            errorMsg.primarySourceofIncome = err;
        } else {
            err = Rules.industry
                ? checkValidity({
                      rules: Rules.industry,
                      value: industry,
                  })
                : '';
            errorMsg.industry = err;
        }
        valid = err === null || err === '';
        this.setState({ errorMsg });
        return valid;
    };

    sendDataToParent = () => {
        const valid = this.validateFields();
        const empAddressData = this.empAddressPref.current && this.empAddressPref.current.getAddress();
        const empAddress = {
            empAddress: empAddressData,
        };

        // if (!valid) {
        //     this.accordionRef.current.click();
        // }
        return { ...this.state, ...empAddress, valid };
    };

    renderEmployeeFields = (isUnemployed, industryOp, primarySourceOfIncomeValue) => {
        const { localStateData, isJoint } = this.props;
        const {
            empStatus,
            empClassInfo,
            industry,
            occupation,
            employersName,
            errorMsg,
            primarySourceofIncome,
            industryDescription,
            employerAddress,
        } = this.state;
        const { accountType } = localStateData || {};
        // const savedEmpAddress =
        //     accountType &&
        //     localStateData &&
        //     localStateData.OpenAccPageTwo &&
        //     localStateData.OpenAccPageTwo.employementInfo &&
        //     localStateData.OpenAccPageTwo.employementInfo.employerAddress;
        const savedEmpAddress = employerAddress;
        const savedJointEmpAddress =
            accountType &&
            localStateData[accountType.toString()] &&
            localStateData[accountType.toString()].jointEmpInfo &&
            localStateData[accountType.toString()].jointEmpInfo.empAddress;

        return (
            <div>
                {empStatus === 'other' && (
                    <Row>
                        <WInput
                            label="Employment Class information"
                            placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                            className=""
                            optional="true"
                            id="empClassInfo"
                            name="empClassInfo"
                            type="text"
                            onChange={this.handleChange('empClassInfo')}
                            onBlur={this.handleBlur('empClassInfo')}
                            value={empClassInfo || ''}
                            errortext={errorMsg.empClassInfo}
                            labelsm={4}
                            valuesm={5}
                        />
                    </Row>
                )}

                {isUnemployed && (
                    <VCMSelect
                        id="primarySourceofIncome"
                        name="primarySourceofIncome"
                        label="Primary Source of Income"
                        itemlist={primarySourceOfIncomeValue}
                        onChange={this.handleChange('primarySourceofIncome')}
                        value={primarySourceofIncome}
                        labelsm={4}
                        valuesm={5}
                        errortext={errorMsg.primarySourceofIncome}
                        noGutters
                        // optional
                    />
                )}
                {!isUnemployed && (
                    <VCMSelect
                        id="industry"
                        name="industry"
                        label="Industry"
                        itemlist={industryOp}
                        onChange={this.handleChange('industry')}
                        value={industry}
                        labelsm={4}
                        valuesm={5}
                        errortext={errorMsg.industry}
                        noGutters
                        // optional
                    />
                )}
                {!isUnemployed && industry === 'other_ind' && (
                    <Row>
                        <WInput
                            label=""
                            className=""
                            id="industryDescription"
                            name="industryDescription"
                            type="text"
                            onChange={this.handleChange('industryDescription')}
                            onBlur={this.handleBlur('industryDescription')}
                            value={industryDescription || ''}
                            errortext={errorMsg.industryDescription}
                            labelsm={4}
                            valuesm={5}
                            optional="true"
                        />
                    </Row>
                )}
                {!isUnemployed && (
                    <>
                        <Row>
                            <WInput
                                label="Occupation"
                                className=""
                                id="occupation"
                                name="occupation"
                                type="text"
                                onChange={this.handleChange('occupation')}
                                onBlur={this.handleBlur('occupation')}
                                value={occupation || ''}
                                errortext={errorMsg.occupation}
                                labelsm={4}
                                valuesm={5}
                                optional="true"
                                maxlength={30}
                            />
                        </Row>
                        <Row>
                            <WInput
                                label="Employer's Name"
                                className=""
                                id="employersName"
                                name="employersName"
                                type="text"
                                onChange={this.handleChange('employersName')}
                                onBlur={this.handleBlur('employersName')}
                                value={employersName || ''}
                                errortext={errorMsg.employersName}
                                labelsm={4}
                                valuesm={5}
                                optional="true"
                                maxlength={40}
                            />
                        </Row>
                        {/* <Address
                            savedState={isJoint ? savedJointEmpAddress : savedEmpAddress}
                            ref={this.empAddressPref}
                            // states={Consts.states.value}
                            labelAdd="Employer's Address"
                            isRequired={false}
                            labelAdd1="Employer's Address Line 1"
                            labelAdd2="Employer's Address Line 2"
                            optional="true"
                            type="employer"
                            isJoint={isJoint}
                        /> */}
                        <Address
                            savedState={isJoint ? savedJointEmpAddress : savedEmpAddress}
                            ref={this.empAddressPref}
                            labelAdd="Employer's Address"
                            required
                            optional="true"
                            type="mailing"
                            // mailRadio
                        />
                    </>
                )}
            </div>
        );
    };

    render() {
        const emplInfoHeading = 'Employment Information';
        const { masterLookupStateData, isJoint } = this.props;
        const { employment_status: emp, industry: industryMaster, prim_src_income: primarySourceOfIncomeMaster } =
            masterLookupStateData || {};
        const { value: employmentStatus } = emp || {};
        const { value: industryOp } = industryMaster || {};
        const { value: primarySourceOfIncomeValue } = primarySourceOfIncomeMaster || {};
        const emplist = employmentStatus || [];
        const { empStatus, errorMsg } = this.state;
        const accTypeHeading = isJoint ? 'Joint Owner' : '';
        const notEmployeeArray = ['retired', 'homemaker', 'student', 'unemployed'];
        const isUnemployed = notEmployeeArray.findIndex(item => item === empStatus) > -1;

        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            ref={this.accordionRef}
                            style={styles.accordionToggleStyle}
                        >
                            {accTypeHeading} {emplInfoHeading}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <VCMSelect
                                id="empStatus"
                                name="empStatus"
                                label="Employment Status"
                                itemlist={emplist}
                                onChange={this.handleChange('empStatus')}
                                onBlur={this.handleBlur('empStatus')}
                                value={empStatus}
                                valuesm={5}
                                errortext={errorMsg.empStatus}
                                noGutters
                                required
                            />
                            {empStatus &&
                                this.renderEmployeeFields(isUnemployed, industryOp, primarySourceOfIncomeValue)}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}

EmploymentInfoCard.propTypes = {
    masterLookupStateData: PropTypes.shape({
        employment_status: PropTypes.any,
        industry: PropTypes.any,
    }),
    getCompositeLookUpData: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
};

EmploymentInfoCard.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: null,
    localStateData: {},
    savedState: {},
    isJoint: false,
};

export default EmploymentInfoCard;
