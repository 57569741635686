import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { clearExistingActions, deleteProfileEmailInformations } from 'shared/Actions/ProfileSettingsAction';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import CONST from '../constants';
import EmailInfoComponent from './EmailInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData } = state;
    const { isError, isSuccess, isDeleteAPICalled } = profileInformationData;
    const notify = isError || isSuccess;
    let message = null;
    if (isSuccess) {
        message = isDeleteAPICalled ? CONST.EMAIL_DELETE_SUCCESS : CONST.EMAIL_SAVE_SUCCESS;
    }

    return {
        profileInformationData,
        notify,
        message: {
            message,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    deleteProfileEmailInformations,
    clearExistingActions,
    analyzeClickEvent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNotification(EmailInfoComponent)));
