import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
};


function manageBeneficiariesMasterRegsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_BENEFICIARIES_MASTER_REGS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.GET_BENEFICIARIES_MASTER_REGS_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        case ActionTypes.GET_BENEFICIARIES_MASTER_REGS_LOADING: {
            return {
                ...action.payload,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.GET_BENEFICIARIES_MASTER_REGS_FAILURE:{
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        default:
            return state;
    }
}

export default manageBeneficiariesMasterRegsReducer;
