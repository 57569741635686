import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import * as Styles from './Styles';
import * as Constants from './Constants';

const AccountsDropDown = props => {
    const { accounts, onChange, isDisabled, selectedAccounts } = props;
    const inlineStyles = {
        multiSelect: { width: 14, height: 14, marginLeft: '20px' },
    };

    const getDefaultAccount = () => {
        return accounts.length === 1 ? accounts[0].key : undefined;
    };

    const getKey = (key, index) => {
        return `${key}-${index}`;
    };

    function getMaskedAccounts(account) {
        const splitStr = account.split(' - ');
        const accountName = splitStr[0];
        const accountNumber = splitStr[1];
        const last5AccDigits = accountNumber.slice(-5);
        const firstPartAccount = accountNumber.slice(0, accountNumber.length - 5);
        const maskText = 'X'.repeat(firstPartAccount.length);

        return `${accountName} - ${maskText}${last5AccDigits}`;
    }

    return (
        <Styles.FormRow>
            <Col sm={12}>
                
                {!isDisabled && (
                    <Styles.MultiSelectDropDownRow
                        id="accounts-drop-down"
                        label={Constants.FORMS.ACCOUNTS}
                        value={getDefaultAccount()}
                        itemlist={accounts}
                        valuesm={5}
                        optional
                        optionalText="[optional]"
                        removeIconStyle={inlineStyles.multiSelect}
                        onChange={onChange}
                        formatDisplay={getMaskedAccounts}
                    />
                )}
                {isDisabled && (
                    <Row>
                        <Col sm={2}>
                            <Styles.Label>{Constants.FORMS.ACCOUNTS}</Styles.Label>
                        </Col>
                        <Col sm={5}>
                            {selectedAccounts.map((account, index) => {
                                return <div key={getKey(account.value, index)}>{getMaskedAccounts(account.value)}</div>;
                            })}
                        </Col>
                    </Row>
                )}
            </Col>
        </Styles.FormRow>
    );
};

AccountsDropDown.propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedAccounts: PropTypes.arrayOf(PropTypes.object),
};

AccountsDropDown.defaultProps = {
    accounts: [],
    onChange: () => {},
    isDisabled: false,
    selectedAccounts: [],
};

export default React.memo(AccountsDropDown);
