import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    contactData: {
        status: "Open Now",
        phoneNumber: {
            type: "Toll-Free",
            number: "800-253-8794"
        },
        services: [{
                service: "VCM's Member Services",
                workingDays: "Monday - Friday",
                workingTime: "7.30am - 8.00pm CT"
            },
            {
                service: "403B Support Services",
                workingDays: "Weekdays",
                workingTime: "8.00am - 6.00pm CT"
            }
        ]
    },

    waitTime: {},
    operationalHours: {},
};

function ContactCenterReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.LOCAL_STATE:
        {
            return {
                ...state,
                ...action,
            };
        }

    case ActionTypes.GET_WAIT_TIME:
        {
            return { ...state, ...action, isLoading: true, };
        }    
    case ActionTypes.GET_WAIT_TIME_SUCCESS:
        {
            return { ...state, ...action, isLoading: false, isSuccess: true};
        }
    case ActionTypes.GET_WAIT_TIME_FAILURE:
        {
            return { ...state, ...action, isLoading: false, isError: true };
        }
    case ActionTypes.GET_CONTACT_OPERATIONAL_HOURS:
        {
            return { ...state, ...action, isLoading: true, };
        }        
    case ActionTypes.GET_CONTACT_OPERATIONAL_HOURS_SUCCESS:
        {
            return { ...state, ...action, isLoading: false, isSuccess: true};
        }
    case ActionTypes.GET_CONTACT_OPERATIONAL_HOURS_FAILURE:
        {
            return { ...state, ...action, isLoading: false, isError: true };
        }
        // case ActionTypes.SET_CONTACT_DATA: {
        //     const data = action.payload;
        //     return {
        //         ...state,
        //         data
        //     };
        // }
        //     case ActionTypes.SET_PAGE_LOADING: {
        //         const loading = action.payload;
        //         return {
        //             ...state,
        //             loading
        //         };
        //     }
    default:
        return state;
    }
};
export default ContactCenterReducer;
