import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { documentPreferenceActions,analytics } from '../../../../shared/Actions';
import DocumentCenter from './DocumentCenter';

const mapStateToProps = (state) => ({
        dashboardDocumentData :  state.documentPreferenceData,
    
})

const mapDispatchToProps = {
    ...documentPreferenceActions,...analytics,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCenter);