import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions } from '../../../shared/Actions';
import MemberValidationComponent from './MemberValidationComponent';

export const mapStateToProps = (state /* , props */) => ({
    loginState: state.loginData,
});

const mapDispatchToProps = {
    ...loginActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberValidationComponent));
