import DatePicker from 'react-datepicker';
import { Form, Col, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import WButton from '../WButton/WButton';
import './style.css';
import './victoryDateRange.css';

const dateFormatText = 'MM/DD/YYYY';

const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    // font-size: 14px/17px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: rgb(73, 73, 74);
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;
const buttonStyle = { marginTop: 30, height: 40 };

class VictoryDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterStartDateErrorMsg: null,
            filterEndDateErrorMsg: null,
        };
    }

    handleChange = (datevalue, id) => {
        const { onFilterDateChange } = this.props;
        const err = '';
        if (datevalue) {
            this.setState({ [`${id}ErrorMsg`]: err });
            onFilterDateChange(datevalue, id);
        }
    };

    handleOnBlur = event => {
        if (!event.target.value) {
            const err = `Please select a valid date`;
            this.setState({ [`${event.target.id}ErrorMsg`]: err });
        } else {
            this.setState({ [`${event.target.id}ErrorMsg`]: '' });
        }
    };

    render() {
        const {
            filterStartDate,
            filterEndDate,
            onApplyFilter,
            applyFilterDisabled,
            startDateLabel,
            endDateLabel,
            dateRangeText,
        } = this.props;
        const { filterStartDateErrorMsg, filterEndDateErrorMsg } = this.state;
        return (
            <>
                <Row>
                    <Col xs={4} className="dateRangeLabel">
                        <StyledLabel htmlFor="filterStartDate">{startDateLabel}</StyledLabel>
                        <DatePicker
                            id="filterStartDate"
                            ariaDescribedBy="Start-Date-hint tranActivityStartDate-error"
                            className={
                                filterStartDateErrorMsg
                                    ? 'analytics-form-fields errorInputField'
                                    : 'analytics-form-fields'
                            }
                            aria-invalid={!!filterStartDateErrorMsg}
                            selected={filterStartDate ? new Date(filterStartDate) : null}
                            onChange={Object.assign(date => this.handleChange(date, 'filterStartDate'))}
                            onBlur={Object.assign(date => this.handleOnBlur(date, 'filterStartDate'))}
                            selectsStart
                            startDate={filterStartDate ? new Date(filterStartDate) : null}
                            endDate={filterEndDate ? new Date(filterEndDate) : null}
                            filterDate={Object.assign(date => {
                                return moment() > date;
                            })}
                            for-id={dateRangeText}
                            preventOpenOnFocus
                        />
                        <small id="Start-Date-hint">{dateFormatText}</small>
                        <br />
                        {filterStartDateErrorMsg && (
                            <small
                                className="errorMsgInputStyle analytics-error-field"
                                id="tranActivityStartDate-error"
                                aria-live="assertive"
                                aria-atomic="true"
                                aria-invalid={!!filterStartDateErrorMsg}
                                aria-describedby="sdate_error"
                            >
                                <span className="sr-only" aria-hidden="false">
                                    Error:
                                </span>
                                {filterStartDateErrorMsg}
                            </small>
                        )}
                    </Col>
                    <Col xs={4} className="dateRangeLabel labelMargin">
                        <StyledLabel htmlFor="filterEndDate">{endDateLabel}</StyledLabel>
                        <DatePicker
                            id="filterEndDate"
                            ariaDescribedBy="tranActivityEndDate-hint"
                            className={
                                filterEndDateErrorMsg
                                    ? 'analytics-form-fields errorInputField'
                                    : 'analytics-form-fields'
                            }
                            aria-describedby="filterEndDate tranActivityEndDate-error"
                            aria-invalid={!!filterEndDateErrorMsg}
                            selected={filterEndDate ? new Date(filterEndDate) : null}
                            onChange={Object.assign(date => this.handleChange(date, 'filterEndDate'))}
                            onBlur={Object.assign(date => this.handleOnBlur(date, 'filterEndDate'))}
                            selectsEnd
                            startDate={filterStartDate ? new Date(filterStartDate) : null}
                            endDate={filterEndDate ? new Date(filterEndDate) : null}
                            minDate={filterStartDate ? new Date(filterStartDate) : null}
                            filterDate={Object.assign(date => {
                                return moment() > date;
                            })}
                            for-id={dateRangeText}
                            preventOpenOnFocus
                        />
                        <small id="tranActivityEndDate-hint">{dateFormatText}</small>
                        <br />
                        {filterEndDateErrorMsg && (
                            <small
                                className="errorMsgInputStyle analytics-error-field"
                                id="tranActivityEndDate-error"
                                aria-live="assertive"
                                aria-atomic="true"
                                aria-describedby="enddate_error"
                            >
                                {filterEndDateErrorMsg}
                            </small>
                        )}
                    </Col>
                    <Col xs={4} className="dateRangeLabel">
                        <WButton
                            className="filterButton"
                            role="button"
                            buttontext="Apply Filter"
                            onClick={onApplyFilter}
                            ref={this.textInput}
                            id="tradeButton"
                            buttonstyle={buttonStyle}
                            disabled={applyFilterDisabled}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

VictoryDateRange.propTypes = {
    onFilterDateChange: PropTypes.func,
    onApplyFilter: PropTypes.func,
    filterStartDate: PropTypes.string,
    filterEndDate: PropTypes.string,
    applyFilterDisabled: PropTypes.bool,
    endDateLabel: PropTypes.string,
    startDateLabel: PropTypes.string,
    dateRangeText: PropTypes.string,
};

VictoryDateRange.defaultProps = {
    onFilterDateChange: () => {},
    onApplyFilter: () => {},
    filterStartDate: null,
    filterEndDate: null,
    applyFilterDisabled: true,
    endDateLabel: 'End Date',
    startDateLabel: 'Start Date',
    dateRangeText: 'Date Range',
};

export default VictoryDateRange;
