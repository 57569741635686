import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BottomNavWrapper, WRadio, MaskText } from './../../../../../common';
import { formatCurrency } from './../../../../../utils';
import ContactCenterWidget from 'modules/AccountDashboardModule/Common/ContactCenter/ContactCenterWidgetContainer';
import accountHolderCardData from 'modules/AccountDashboardModule/AccountHolderView/AccountHolderConstants';
import { SectionWrapper, Section } from './commonCSS';
import YouSelect from './YouSelect';
import { getSessionValues } from 'shared/Helpers/Utils';
import MaskAccountNumber from 'common/MaskAccountNumber/MaskAccountNumber';

const { footerData } = accountHolderCardData;

const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const RadioGrup = styled.div`
    display: flex;
    flex-direction: column;
`;

const radiostyles = {
    minHeight: 0,
    marginBottom: 0
};

const Heading = styled.h2`
    display: inline-block;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.2rem;
    color: #486D90;
    opacity: 1;
    padding-bottom: 0.625rem;
    margin-bottom: 1.875rem;
    margin-top: 20px;
`;
const CALCULATE_RMD_EXTERNAL = 'Calculate your RMD on an external account';
class RMDAccountSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            retrivedState: false,
            accountSelected: {},
            displayDetails: {
                titleTxt: 'Account',
                editTxt: 'Edit',
                pageIndex: props.pageIndex,
                data: [],
            },
            disabledNextBtn: true,
            noAccountSelected: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }
        return null;
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { getEligibleAccounts } = this.props;
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues(); 
        const {accesstoken, vcmid, msrid, fismemberid} = params;        
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid
        }
        getEligibleAccounts(msrParams);
    }


    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;
        const { accountSelected } = this.state;
        // sessionStorage.setItem(STEP_KEY, dataAnalyticsPage || 'Account Selection');

        if (accountSelected) {
            saveChildrenState(this.state, pageIndex);
        }
    }

    handleCalculateRMDExternal = () => {
        window.open('/calculators', "_blank");
    }

    getRMDCalcComponent = () => {
        return (
            <Heading
                style={{ cursor: "pointer" }}
                href={null}
                onClick={this.handleCalculateRMDExternal}>
                {CALCULATE_RMD_EXTERNAL}
            </Heading>
        );
    }

    handleOptionChange = (item) => {
        const { displayDetails, accountSelected } = this.state;
        const { resetNextSteps } = this.props;

        if (item.accountNumber !== accountSelected.accountNumber) {
            const accountNameDisplay =
                !item.accountName || item.accountName.trim() === ''
                    ? `${!item.accountType ? '' : item.accountType} Account ${item.accountNumber}`
                    : item.accountName;

            displayDetails.data = [
                {
                    field: 'Name',
                    value: accountNameDisplay,
                },
                {
                    field: 'Number',
                    formattedValue: item.accountNumber && (
                        <MaskText text={item.accountNumber.toString()} startIndex={0} maskLength={item.accountNumber ? item.accountNumber.toString().length - 4 : 0} />
                    ),
                    value: item.accountNumber.toString(),
                },
                {
                    field: 'Type',
                    // TODO: Remove the hardcoded account type
                    value: item.accountType ? item.accountType : 'Individual',
                },
            ];

            resetNextSteps();
            
            this.setState({
                accountSelected: item,
                displayDetails,
                disabledNextBtn: false,
                noAccountSelected: false
            });
        }
    }

    handleNoAccountSelected = () => {
        this.setState({
            noAccountSelected: true,
            disabledNextBtn: true,
            accountSelected: {}
        });
    }

    render() {
        const { accountsOpened, navigationObj, pageIndex } = this.props;
        
        const { accountSelected, disabledNextBtn, noAccountSelected } = this.state;
        return (
            <div>
                <SectionWrapper>
                    <YouSelect pageIndex={pageIndex} pageSubIndex={-1}  />
                    <Section>
                        <SectionTitle>Which account you like to take money from?</SectionTitle>
                        <RadioGrup role='radiogroup'>
                            {accountsOpened.map(item => {
                                const maskLength = item.accountNumber ? (item.accountNumber.toString().length - 4) : 0;
                                const radioValue = <>{item.accountName} - <MaskAccountNumber text={item.accountNumber ? item.accountNumber.toString() : 0}/> [{formatCurrency(item.currentAccountBalance)}]</>;
                                return (
                                    <WRadio
                                        id={`${item.accountNumber}`}
                                        key={item.accountNumber}
                                        name={item.accountNumber}
                                        value={radioValue}
                                        radiostyles={radiostyles}
                                        selected={item.accountNumber === accountSelected.accountNumber}
                                        onClick={() => this.handleOptionChange(item)}
                                    />
                                );
                            })}
                            <WRadio
                                id={'noAccount'}
                                key={'noAccount'}
                                name={`I don't see my account listed here`}
                                value={`I don't see my account listed here`}
                                radioFor={'noAccount'}
                                radiostyles={radiostyles}
                                selected={noAccountSelected === true}
                                onClick={this.handleNoAccountSelected}
                            />
                        </RadioGrup>
                        {noAccountSelected &&
                            <div>
                                <ContactCenterWidget footerData={footerData} showCorner={true} />
                            </div>}
                    </Section>
                </SectionWrapper>
                <BottomNavWrapper
                    {...navigationObj}
                    showBack={true}
                    disabled={disabledNextBtn}
                // dataAnalyticId={RMD_ADD_PLAN_DETAILS_NAV_BUTTON_ID}
                />
            </div>
        );
    }
}

RMDAccountSelection.propTypes = {
    getEligibleAccounts: PropTypes.func,
    pageIndex:PropTypes.number,
    onCancel: PropTypes.func,
    navigationObj:PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        onShowDownloadForm: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
};

RMDAccountSelection.defaultProps = {
    getEligibleAccounts: () => { },
    pageIndex: 0,
    onCancel: () => { },
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        onShowDownloadForm: () => {},
        portalRoot: null,
    },
};

export default RMDAccountSelection;