import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import styles from '../CommonStyles';


const assignObj = (obj) => { return obj; };

const WModal=props=>{
    const {show, onHide, dialogClassName, bodystyle, headertextstyle, modalfooterstyle, bodytitlestyle, modalheaderstyle, header, closebutton, bodytitle, body, footertextstyle, footer} = props;
    const modalClassName=`wModalCommon ${dialogClassName}`;
    return (
    <div className="container-fluid">
        <Modal show={show} onHide={onHide} {...props}  dialogClassName={modalClassName} centered aria-labelledby="dialogHeading">  
                    <Modal.Header style={assignObj({...styles.modalHeaderStyle,...modalheaderstyle})} closeButton={closebutton}> 
                        <Modal.Title id="dialogHeading"><h2 style={assignObj({...styles.modalTitleStyle,...headertextstyle})} >{header}</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title><span style={assignObj({...styles.modalTitleStyle,...bodytitlestyle})} >{bodytitle}</span></Modal.Title>
                             <span style={assignObj({...styles.modalBodyStyle,...bodystyle})}>{body}</span>
                    </Modal.Body>
                    <Modal.Footer style={assignObj({...styles.modalFooterStyle,...modalfooterstyle})}>
                        <span style={assignObj({...styles.modalTitleStyle,...footertextstyle})}>{footer}</span>
                    </Modal.Footer>
        </Modal>       
    </div>
)};

WModal.propTypes={   
    bodytitle:PropTypes.string,
    body:PropTypes.instanceOf(Object),    
    bodystyle:PropTypes.instanceOf(Object),
    headertextstyle:PropTypes.instanceOf(Object),
    bodytitlestyle:PropTypes.instanceOf(Object),
    footertextstyle:PropTypes.instanceOf(Object),
    modalfooterstyle:PropTypes.instanceOf(Object),
    modalheaderstyle:PropTypes.instanceOf(Object),
    show:PropTypes.bool,
    onHide:PropTypes.func,
    dialogClassName:PropTypes.string,
    closebutton:PropTypes.string,
    header:PropTypes.string,
    footer:PropTypes.instanceOf(Object),
}

WModal.defaultProps={ 
    body:{},
    show:false, 
    bodytitle:'',   
    bodystyle:{},
    headertextstyle:{},
    bodytitlestyle:{},
    footertextstyle:{},
    modalfooterstyle:{},
    modalheaderstyle:{},
    onHide:PropTypes.func,
    dialogClassName:'',
    closebutton:'',
    header:"header",
    footer:{},
}

export default WModal;



