import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';
import {  VCMSelectWithoutLabel } from '../../../common';

export const Prefix = (props) => {
    const { changed, prefixState, prefix, disable } = props
    const data = {
        config: {
            id: "prefix",
            label: 'Salutation',
            optional: '[ Optional ]',
            options: [
                ...prefixState.options
            ]
        },
        change: event => changed(event, 'prefix')
    };
    
    if (prefix !== null && prefix !== undefined) {
        data.config.options.push(...prefix.value);
    }

    let inputElement = null;

    inputElement = <VCMSelectWithoutLabel
        // className={classes.InputElement}
        id={data.config.id}
        value={prefixState.value}
        onChange={data.change}
        itemlist={data.config.options}
        disabled={disable} />;

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor={data.config.id}>
                {data.config.label}<span className={classes.optional}>{data.config.optional}</span>
            </label>
            {inputElement}
        </div>
    );
};

Prefix.propTypes = {
    prefix: PropTypes.shape({
        value: PropTypes.instanceOf(Array)
    }),
    prefixState: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        options: PropTypes.instanceOf(Array)
    }),
    disable: PropTypes.bool,
    changed: PropTypes.func
};

Prefix.defaultProps = {
    prefix: null,
    prefixState: {
        value: '',
        valid: true,
        options: []
    },
    disable: false,
    changed: () => { }
};

export default Prefix;