import { analytics } from '../../shared/Actions';
import CommonDisclosure from './CommonDisclosure';
import { connect } from 'react-redux';
import  Constant from './CONST';
import DisclosureData from '../Disclosure/disclosureData';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import '../commonCompStyles.css';
import envConfig from 'env/EnvConfig';

const StyledP = styled.p`
    font: 500 16px/24px benton-sans;
    color: rgb(73, 73, 74);
    > a {
        color: rgb(0, 74, 152);
        text-decoration: underline;
    }
`;

const BoldSpan = styled.span`
    letter-spacing: 0;
    text-align: left;
    font: 700 1rem/1.5rem benton-sans;
`;

const assignObj = obj => {
    return obj;
};
let CONSTS = Constant
let disclosureData= DisclosureData
class FundsDisclosure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginDataCounter:0,
            loginFundDataCounter:0
        };
    }

    // Analytics Implementation Start
    callAnalytics = linkName => {
        const { analyzeDownLoadClickEvent } = this.props;
        analyzeDownLoadClickEvent({
            link: `Footer_Disclaimer_${linkName}(Download)`,
        });
    };

    handleFooterDisclaimerClick = e => {
        localStorage.setItem('isPreloginRedirection', 'true');
        window.supressRefresh = true;
        this.callAnalytics(e.target.text);
    };

    render() {

        const { fundCode, path } = this.props;

        let disclosureDataValues = [];
        if (fundCode) {
            disclosureDataValues =
                disclosureData && disclosureData.filter(fund => Number(fund.fundCode) === Number(fundCode));
        }

        let disclosureConstData = {};
        if (disclosureDataValues[0]) {
            const { [disclosureDataValues[0].disclosure]: disclosureConst } = CONSTS;
            disclosureConstData = disclosureConst;
        }

        return (
            <>
                {disclosureDataValues[0] && disclosureDataValues[0].disclosure ? (
                    <div>
                        {/* <h2 style={assignObj({ font: '700 16px/24px benton-sans', color: 'rgb(73, 73, 74)', marginBottom: 15 })}>
                {dasboardPrinciple}
            </h2> */}

                        {(disclosureDataValues[0].disclosure === 'USAAIndexFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAGrowthFundGroupDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAGlobalManagedVFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAIncomeStockFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAScienceTechFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAASmallCapStockFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAExtendedMarketIndexDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAANasdaqIndexFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAHighIncomeFundGroupDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAShortTermBondDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAATargetRetirementFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAACaliforniaBondFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAANewYorkBondFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAVirginiaBondFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAATaxExemptFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAGlobalEquityIncomeFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAATargetManagedAllocationFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAACornerstoneFundGroupDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAEmergingMarketsFundDisclosure' ||
                            disclosureDataValues[0].disclosure ===
                                'USAAInternationalSustainableWorldFundGroupDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAPreciousMetalsFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAGovSecuritiesFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAAGrowthTaxStrategyFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryFloatingRateFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryHighIncomeMuncipalBondFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryIncoreFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryIncoreInvestmentGradeFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryIntegrityMidCapValueFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryIntegritySmallMidCapValueFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryMarketNeutralIncomeFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryRSMidCapGrowthFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryRSPartnersFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryRSSmallCapEquityFundDisclosure' ||
                            disclosureDataValues[0].disclosure === 'USAATreasuryMoneyMarketDisclosure' ||
                            disclosureDataValues[0].disclosure === 'VictoryIntegrityDiscoveryFundDisclosure') && (
                            <>
                                <StyledP>
                                    <span>{disclosureConstData.principle}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para1a}</BoldSpan>
                                    <span>{disclosureConstData.para1b}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para2a}</BoldSpan>
                                    <a
                                        href={assignObj(`${envConfig.ENV_URL}/prospectus`)}
                                        target="_self"
                                        rel="noopener noreferrer"
                                        onClick={this.handleFooterDisclaimerClick}
                                        style={assignObj({ display: 'inline-block' })}
                                    >
                                        <BoldSpan>{disclosureConstData.vcmLink}</BoldSpan>
                                    </a>
                                    <BoldSpan>{disclosureConstData.para2b}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para3a}</BoldSpan>
                                    <span>{disclosureConstData.para3b}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para4}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para5}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para6}</BoldSpan>
                                </StyledP>
                            </>
                        )}

                        {disclosureDataValues[0].disclosure === 'USAAMoneyMarketFundDisclosure' && (
                            <>
                                <StyledP>
                                    <span>{disclosureConstData.principle}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para1a}</BoldSpan>
                                    <span>{disclosureConstData.para1b}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para2a}</BoldSpan>
                                    <a
                                        href={assignObj(`${envConfig.ENV_URL}/prospectus`)}
                                        target="_self"
                                        rel="noopener noreferrer"
                                        onClick={this.handleFooterDisclaimerClick}
                                        style={assignObj({ display: 'inline-block' })}
                                    >
                                        <BoldSpan>{disclosureConstData.vcmLink}</BoldSpan>
                                    </a>
                                    <BoldSpan>{disclosureConstData.para2b}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para3}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para4a}</BoldSpan>
                                    <span>{disclosureConstData.para4b}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para5}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para6}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para7}</BoldSpan>
                                </StyledP>
                            </>
                        )}
                        {disclosureDataValues[0].disclosure === 'USAATaxExemptMoneyMarketFundDisclosure' && (
                            <>
                                <StyledP>
                                    <span>{disclosureConstData.principle}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para1a}</BoldSpan>
                                    <span>{disclosureConstData.para1b}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para2a}</BoldSpan>
                                    <a
                                        href={assignObj(`${envConfig.ENV_URL}/prospectus`)}
                                        target="_self"
                                        rel="noopener noreferrer"
                                        onClick={this.handleFooterDisclaimerClick}
                                        style={assignObj({ display: 'inline-block' })}
                                    >
                                        <BoldSpan>{disclosureConstData.vcmLink}</BoldSpan>
                                    </a>
                                    <BoldSpan>{disclosureConstData.para2b}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para3}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para4}</BoldSpan>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para3b}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para5}</span>
                                </StyledP>
                                <StyledP>
                                    <span>{disclosureConstData.para6}</span>
                                </StyledP>
                                <StyledP>
                                    <BoldSpan>{disclosureConstData.para7}</BoldSpan>
                                </StyledP>
                            </>
                        )}
                    </div>
                ) : (
                    <>
                    <CommonDisclosure data-test="commonDisclaimer" path={path} />
                    </>
                    
                )}
            </>
        );
    }
}

FundsDisclosure.propTypes = {
    fundCode: PropTypes.string,
    path: PropTypes.string,
};

FundsDisclosure.defaultProps = {
    fundCode: '',
    path: '',
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
    ...analytics,
};

export default connect(mapStateToProps, mapDispatchToProps, null)(FundsDisclosure);
