import envConfig from 'env/EnvConfig';
import React from 'react';

const post = "post";
const target = "_blank";
const hidden = "hidden";
const data = "data";

class MSAuthRedirect extends React.Component {

    componentDidMount() {
        document.forms[0].submit();
    }

    render() {
        const redirectUrl =envConfig.MARSTONE_API
        const payload = JSON.stringify({
            token: localStorage.getItem('token')
        });
        const enc = Buffer.from(payload).toString("base64");
        return (
            <div data-test="marstone">
                <form method={post} action={redirectUrl} target={target}>
                    <input type={hidden} name={data} value={enc} />
                </form>
            </div>
        )
    }
}

export default MSAuthRedirect;