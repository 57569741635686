import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Row, Col } from 'react-bootstrap';
// import { assignObj } from 'utils';
import userConstants, { getMaskedNumber } from 'modules/UserManagementAndSecurityModule/userConstants';
// import routeConstants from 'routeConstants';
// import LoadingOverlay from 'react-loading-overlay';

import '../Screens.css';
import OtpForm from '../../OtpForm';
import SideHeader from '../../SideHeader/SideHeader';
import { WStepper, CommonButtons, WMessageBar, WSpinner } from '../../../../common';
import { changedHandler, validityHandler } from '../../FormUtils';
import data from './constants';
import styles from '../../UserManagementStyles';
import classes from '../../UserManagementStyles.module.css';

const { stepperColStyle } = styles.stepperStyle;
export class ConfirmOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errMsg: null,
            valid: false,
            label: 'One Time Passcode',
            mobileNumer: '',
            type: 'number',
            rules: {
                isNumeric: true,
                length: 6,
                required: true,
            },
            cancelled: false,
            error: false,
            notificationMsg: null,
            redirect: false,
            showPopover: true,
            logOutRoute: {
                pathname: '/sign-in',
                // message: { message: 'You have been successfully logged out' },
            }
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { history, verifyOTPData, verifyOtp, loginState, sendOtp, manageLocalState, getCustomerProfile } = props;
        const updatedState = {...state};
        if (verifyOTPData) {
            const { errorCode, result } = verifyOTPData;
            if (errorCode === 0) {
                history.push('./setup-security-questions');
            } else if (errorCode === 2009) {
                updatedState.errMsg = data.wrongOtp;
            } else {
                updatedState.errMsg = result
            }
            verifyOtp('');
        }

        if (loginState.sendOtp) {
            const { errorCode, result } = loginState.sendOtp;
                if (errorCode === 0) {
                    updatedState.notificationMsg = { message: data.resent }
            } else if (errorCode === 2005){
                updatedState.notificationMsg = {message: userConstants.after24hr};
                localStorage.clear();
                updatedState.redirect =  true;
                updatedState.logOutRoute =  {
                    pathname: '/sign-in',
                    message: { message: userConstants.after24hr },
                    failure: true,
                }
            } else if (errorCode === 2004){
                updatedState.notificationMsg = {message: userConstants.after1hr};
                localStorage.clear();
                updatedState.redirect = true;
                updatedState.logOutRoute =  {
                    pathname: '/sign-in',
                    message: { message: userConstants.after1hr },
                    failure: true,
                }
            } else {
                updatedState.errMsg = result
            }
            sendOtp('');
        }
        if (loginState.getCustomerProfile) {
            const customerData = { customerProfile: { ...loginState.getCustomerProfile } };
            const {primaryMobilePhone} = loginState.getCustomerProfile.phoneInformation;
            const maskedNumber = getMaskedNumber(primaryMobilePhone);
            updatedState.mobileNumer = maskedNumber;
            getCustomerProfile('');
            manageLocalState(customerData);
        }

        return updatedState;
    }

    componentDidMount() {
        const { setJWTToken, getCustomerProfile, customerProfile } = this.props;
        const token = localStorage.getItem('token');
        if(token){
            setJWTToken(token);
        } else {
            this.handleLogOut();
        }
        if(!customerProfile){
            getCustomerProfile({})
        }else {
            const {primaryMobilePhone} = customerProfile.phoneInformation;
            const maskedNumber = getMaskedNumber(primaryMobilePhone);
            this.setState({mobileNumer: maskedNumber})
        }
    }

    onNext = () => {
        const { verifyOtp, customerProfile } = this.props;
        const {value} = this.state;
        if (customerProfile) {
            // console.log(customerProfile)
            const onlineId = customerProfile.Online_Id;
            const otpData = {
                onlineId,
                verifyOTP: value,
            };
            verifyOtp(otpData);
        }
    };

  

    onChange(event) {
        changedHandler(event, this);
    }

    onBlur() {
        validityHandler(this);
    }

    onBack = () => {
        this.setState(state => ({ ...state, cancelled: true }));
    };

    resendOtpHandler = () => {
        const { sendOtp, customerProfile } = this.props;
        if (customerProfile) {
            const { phoneInformation } = customerProfile;
            const onlineId = customerProfile.Online_Id;
            const { primaryMobilePhone } = phoneInformation;
            const phoneNumber = primaryMobilePhone.split(/[\s()-]+/).join('')
            const otpData = {
                phoneNumber,
                requestType: 'OTP',
                onlineId,
            };
            sendOtp(otpData);
        }
        // this.setState(state => ({
        //     ...state,
        //     notificationMsg: { message: data.resent },
        // }));
    };

    onClose = () => {
        this.setState(state => ({ ...state, notificationMsg: null }));
    };

    handleLogOut() {
       //  const { location } = this.props;
       //  const {logOutRoute} =this.state;
       //  const {pathname} = logOutRoute;
        const token = localStorage.getItem('token');
        if (token) {
            Auth.signOut()
                .then(() => {
                    // console.log(data);
                    localStorage.clear();
                    this.setState({
                        redirect: true,
                        logOutRoute: {
                            pathname: '/sign-in',
                            // message: { message: '' },
                        },
                    });
                })
                .catch(() => {
                    // console.log(err)
                });
            // else {
            //     this.setState({redirect: false})
            // }
        } else {
            // console.log('no token');
            this.setState({
                redirect: true,
                pathname: '/',
            });
        }
    }

    render() {
        const { cancelled, valid, touched, notificationMsg, focusfrom, mobileNumer, redirect,logOutRoute } = this.state;
        const {loginState} = this.props;
        let redirectionResult = '';
        if (redirect) {
            redirectionResult = <Redirect to={logOutRoute} />;
        }
        return (
            
            <div className="container-fluid">
                {redirectionResult}
                <WSpinner loading={loginState.isLoading} />
                {/* <LoadingOverlay active={loginState.isLoading} spinner styles={assignObj({ wrapper: { overflow: userConstants.hidden } })} /> */}
                {notificationMsg ? <WMessageBar text={notificationMsg} onClose={this.onClose} focusfrom={focusfrom} /> : null}
                
                <div className="container">
              
                    {/* {error ? <div className="Message">{data.wrongOtp + data.enterOtp + otp + data.proceed}</div> :
            <div className="Message">{data.unknownLogin + data.enterOtp + otp + data.proceed}</div>} */}
                    <div style={data.stepperDivStyle}>
                        <WStepper
                            className="userWizard"
                            currentPage={1}
                            pages={data.pages}
                            stepperColStyle={stepperColStyle}
                        />
                    </div>
                    <Row className="main confirm-otp">
                        <Col md lg xl>
                            <SideHeader title={data.title} info={data.getInfo(mobileNumer)} />
                        </Col>
                        <Col md lg xl>
                            <span className={classes.optionalMessage}>{data.optionalMessage}</span>
                            <Col md={6} lg={6} xl={6} style={data.inputCol}>
                                <OtpForm
                                    id="otpForm"
                                    otpFormState={this.state}
                                    changed={this.onChange}
                                    blurred={this.onBlur}
                                    resendOtpHandler={this.resendOtpHandler}
                                />
                            </Col>
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.onBack}
                    nextClick={this.onNext}
                    disabled={!(valid && touched)}
                    blockstyle={styles.commonButtons}
                    arialabelback="Back to One Time Passcode Authentication"
                />
                {cancelled ? <Redirect to="/otp-auth" /> : null}
            </div>
        );
    }
}

ConfirmOtp.propTypes = {
    sendOtp: PropTypes.func,
    verifyOtp: PropTypes.func,
    setJWTToken: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    customerProfile: PropTypes.instanceOf(Object),
    loginState: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    verifyOTPData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    // location: PropTypes.instanceOf(Object),
};

ConfirmOtp.defaultProps = {
    sendOtp: () => {},
    verifyOtp: () => {},
    setJWTToken: () => {},
    getCustomerProfile: () => {},
    customerProfile: null,
    loginState: {},
    manageLocalState: () => {},
    verifyOTPData: null,
    history: [],
    // location: {},
};

export default ConfirmOtp;
