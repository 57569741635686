import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import constant from '../AccountDashboardConstants';
import SideHeader from '../SideHeaderMain/SideHeader';
import WCardOverlay from '../WCardOverlay/WCardOverlay';
import iconContactCenter from '../../../../assets/icon_contact_center.png';
import ContactCenterFooter from './ContactCenterFooter';
// import WidgetSpinner from '../WidgetSpinner/WidgetSpinner';
import ContactCenter from './ContactCenter';

class ContactCenterWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    // componentDidMount() {
    //     const { getPhoneQueueWaitTime,getLiveChatAvailability} = this.props
    //     const token = localStorage.getItem('token');
    //     getPhoneQueueWaitTime({ data: 'CHAT_PHONE', token });
    //     getLiveChatAvailability({ data: 'MSR_AVAILABILITY', token }) // get MSR Availability
    // }


    componentDidUpdate(prevProps) {
        const { getPhoneQueueWaitTime, isPhoneSupportAvailable: isAvailable } = this.props;
        const { isPhoneSupportAvailable: prevIsAvailable } = prevProps;
        const token = localStorage.getItem('token');

        if (this.props !== prevProps) {

            if (isAvailable && isAvailable !== prevIsAvailable) {
                getPhoneQueueWaitTime({ data: 'CHAT_PHONE', token });
            }
            
            const { waitTimeInfo } = this.props
            const { isError: liveChatError } = waitTimeInfo || {}
            if (Object.keys(waitTimeInfo).length && liveChatError) {
                this.handleAPIError(waitTimeInfo);
            }
        }
    }

    handleAPIError = (error) => {
        const {commonErrorMsg}=constant
        let errorMsg = error
        const { data } = error
        if (error && data) {
            const { errorMessages, message } = data
            if (!isEmpty(errorMessages)) {
                const [firstMsg] = errorMessages
                errorMsg = firstMsg
            } else if (!isEmpty(message)) {
                errorMsg = message
            }
        }
        if (isObject(errorMsg)) {
            errorMsg = commonErrorMsg
        }
        this.setState({ errorMessage: errorMsg })
    }


    render() {
        // const { waitTimeInfo,operationalHoursInfo,footerData,cardOverlayStyles } = this.props
        const { isPhoneSupportAvailable, waitTimeInfo, operationalHoursInfo, footerData, cardOverlayStyles, showCorner } = this.props
        const { phoneQueueWaitTime, isLiveChatAvailable } = waitTimeInfo || {};
        const { GENERAL_PHONE_SCHEDULE } = operationalHoursInfo || {};
        const { assignObj, contactcenter, contactCenterPhoneNumber } = constant
        const { errorMessage = '' } = this.state
        const [data1] = footerData
        if (isLiveChatAvailable) {
            data1.show = isLiveChatAvailable
        }
        return <>
            <WCardOverlay
                className='ac-holder-wcardoverlay'
                myStyles={assignObj({
                    marginTop: '1.875rem',
                    minHeight: '29.1875rem', ...cardOverlayStyles
                })}
                showCorner={showCorner}
            >
                <div className="contact-center-act">
                    <SideHeader
                        title={contactcenter}
                        imgSrc={iconContactCenter}
                        width="1.25rem"
                        height="1.3125rem"
                        id="contactCenter"
                    />
                    {/* {!GENERAL_PHONE_SCHEDULE && !isFetch && <WidgetSpinner />} */}
                    <div 
                    // role="region" 
                    aria-labelledby="contactCenter">
                        <div>
                            <ContactCenterFooter data={footerData} />
                        </div>
                        {/* <ContactCenter content={contactCenterPhoneNumber} waitTimeInfo={phoneQueueWaitTime} /> */}
                        {/* <ContactCenter content={contactCenterPhoneNumber} waitTimeInfo={phoneQueueWaitTime} isAvailable={isPhoneSupportAvailable} />
                        <div style={assignObj({ marginTop: 14 })}>
                            <ContactContent hours={GENERAL_PHONE_SCHEDULE} />
                        </div> */}
                    </div>
                    {!isEmpty(errorMessage) &&
                        <div style={assignObj({ color: 'red', font: '500 1rem/1.5rem benton-sans,sans-serif', })}>{errorMessage}</div>
                    }
                </div>
            </WCardOverlay>
        </>
    }

}

// ContactCenterWidget.propTypes = {
//     waitTimeInfo: PropTypes.instanceOf(Object),
//     operationalHoursInfo: PropTypes.instanceOf(Object),
//     footerData: PropTypes.instanceOf(Object),
//     getLiveChatAvailability: PropTypes.func,
//     getPhoneQueueWaitTime: PropTypes.func,
//     cardOverlayStyles:PropTypes.instanceOf(Object),
// };

// ContactCenterWidget.defaultProps = {
//     waitTimeInfo: {},
//     operationalHoursInfo: {},
//     getLiveChatAvailability: () => {},
//     getPhoneQueueWaitTime: () => { },
//     footerData:[],
//     cardOverlayStyles:{}
// };

ContactCenterWidget.propTypes = {
    showCorner: PropTypes.bool,
    getMsrAvailabilityFromSchedules: PropTypes.func.isRequired,
    getMsrAvailability: PropTypes.func.isRequired,
    getPhoneQueueWaitTime: PropTypes.func.isRequired,
    waitTimeInfo: PropTypes.instanceOf(Object),
    operationalHoursInfo: PropTypes.instanceOf(Object),
    footerData: PropTypes.instanceOf(Object),
    isPhoneSupportAvailable: PropTypes.bool,
    cardOverlayStyles: PropTypes.instanceOf(Object),
};

ContactCenterWidget.defaultProps = {
    showCorner: false,
    waitTimeInfo: {},
    operationalHoursInfo: {},
    isPhoneSupportAvailable: false,
    footerData: [],
    cardOverlayStyles: {},
};

export default ContactCenterWidget;
