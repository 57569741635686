import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as RelationActionTypes from '../ReduxConstants/RelationConstants';
import Config from 'env/Config';
import { post, getMSRBasicInformation, setSessionToPlaylod, getMemberProfileInformationsData, setMSRProfileApiHeader, removeAPIVersionHeader } from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';

const CustomerManagementApiEvents = () => {
    // Temporary
    const RELATIONSHIP_GET = RelationActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION || ActionTypes.PROFILE_GET_RELATIONSHIP_INFORMATION;
    const RELATIONSHIP_UPDATE = RelationActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION || ActionTypes.PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS;
    
    ApiEventHub.on(ActionTypes.GET_PROFILE_BASIC_INFORMATIONS, data => {

        data.payload = {
            msrid : "",
            vcmid : "",
            fismemberid :"",
            token :""
        }
        setSessionToPlaylod(data.payload)
        getMSRBasicInformation(
            Config.msrProfileInfo,
            data.payload,
            data.endPointURL.msrBasicProfileInfo,
            data.successCallBack,
            data.errorCallback,            
        );

        
    });

    // USER Profile Data 

    ApiEventHub.on(ActionTypes.GET_USER_PROFILE_DATA, data => {

        data.payload = {
            msrid : getSessionValues().msrid,
            vcmid : getSessionValues().vcmid,
            fismemberid :getSessionValues().fismemberid,
            token :getSessionValues().accesstoken
        }
        const apiKey = Config.msrTransactionKey;  


        setMSRProfileApiHeader(getSessionValues().accesstoken, 
        getSessionValues().vcmid,apiKey,getSessionValues().msrid,
        ) 
        removeAPIVersionHeader()
        getMemberProfileInformationsData(
            Config.getProfile,
            data.payload,
            data.endPointURL.BasePath,
            data.successCallBack,
            data.errorCallback,            
        );

        
    });

    ApiEventHub.on(ActionTypes.PROFILE_ADD_CONTACT_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        let updatedPayload=data.payload
        if( data.payload.isFamilyAndFriends ){
            delete updatedPayload.isFamilyAndFriends
            post(
                Config.updateProfile_v1,
                updatedPayload,
                data.endPointURL.customerManagementEndPoints,
                data.successCallBack,
                data.errorCallback,
                options,
            );
        }else{
             delete updatedPayload.isFamilyAndFriends
             delete updatedPayload.source
            post(
                Config.updateProfile,
                updatedPayload,
                data.endPointURL.customerManagementEndPoints,
                data.successCallBack,
                data.errorCallback,
                options,
            );
        }
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_CONTACT_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_DELETE_CONTACT_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_ADD_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });
    
    ApiEventHub.on(ActionTypes.PROFILE_VERIFY_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.verifyEmail,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });
    
    ApiEventHub.on(ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.otpConfirmEmail,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_DELETE_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_OCCUPATION_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_FINANCIAL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_MILITARY_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(RELATIONSHIP_GET, data => {
        const apiKey = Config.msrTransactionKey;  

        setMSRProfileApiHeader(getSessionValues().accesstoken, 
        getSessionValues().vcmid,apiKey,getSessionValues().msrid,
        ) 

        post(
            Config.getAssociation,
            '',
            data.endPointURL.BasePath,
            data.successCallBack,
            data.errorCallback,
            // options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_ADD_RELATIONSHIP_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(RELATIONSHIP_UPDATE, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_DELETE_RELATIONSHIP_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateProfile,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAssociation,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });
    
    ApiEventHub.on(ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.profileAddressLookUp,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.getAccountLevelAddress,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES, data => {
        const options = {
            headers: { 'x-api-key': Config.customerManagementApiKey },
        };
        post(
            Config.updateAccountLevelAddress,
            data.payload,
            data.endPointURL.customerManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });
};

export default CustomerManagementApiEvents;
