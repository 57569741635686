import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

function decryptData(data, decrypt){
    return new Promise(res =>{
        decrypt(data.value).then(result=>{
            res({ ...data, value: result, success: true });
        }).catch(()=>{
            res({ success: false });
        });
    });
}

function callKMSService(data, results, decrypt){
    data.forEach((item)=>{
        if(item.value) results.push(decryptData(item, decrypt));
    });
}

function updateValueAndReturnError(decryptedValues, updateObj){
    const Obj = updateObj;
    let error = false;
    decryptedValues.forEach((item) => {
        if(item.success) {
            Obj[item.name] = item.value;
        }else error = true;
    });
    return error;
}

async function decryptProfileData(decrypt, data, dispatch){
    const { result:profileInformation } = data;
    const { militaryInformation, dateOfBirth, ssnTin, vcmId, memberId } = profileInformation || {};
    const { branchOfService, rank, militaryStatus } = militaryInformation || {};
    let errorInDecryption = false;
    const militaryResults = [];
    const personalResults = [];

    callKMSService(
        [
            { name: 'dateOfBirth', value: dateOfBirth },
            { name: 'ssnTin', value: ssnTin },
            { name: 'vcmId', value: vcmId },
            { name: 'memberId', value: memberId },
        ],
        personalResults,
        decrypt,
    );
    callKMSService(
        [
            { name: 'branchOfService', value: branchOfService },
            { name: 'rank', value: rank },
            { name: 'militaryStatus', value: militaryStatus },
        ],
        militaryResults,
        decrypt,
    );

    const decryptedPersonalValues = await Promise.all(personalResults);
    errorInDecryption = updateValueAndReturnError(decryptedPersonalValues, profileInformation);

    const decryptedMilitaryValues = await Promise.all(militaryResults);
    errorInDecryption = errorInDecryption || updateValueAndReturnError(decryptedMilitaryValues, militaryInformation);

    dispatch({
        type: ActionTypes.GET_PROFILE_BASIC_INFORMATIONS_SUCCESS,
        response: data,
    });

    if(errorInDecryption){
        dispatch({
            type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
            response: 'Error happened during decrypting sensitive profile information',
        });
    }
}
// PROFILE BASIC INFORMATIONS ACTIONS

export const getProfileBasicInformations = (decrypt, payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.GET_PROFILE_BASIC_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.GET_PROFILE_BASIC_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                if(decrypt){
                    decryptProfileData(decrypt, data, dispatch);
                }else {
                    dispatch({
                        type: ActionTypes.GET_PROFILE_BASIC_INFORMATIONS_SUCCESS,
                        response: data || {},
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: error.data,
            });
        }
    );
};


// USER profile information 


export const getMemberProfileInformations = (decrypt, payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.GET_USER_PROFILE_DATA,
    });
    makeApiRequest(
        ActionTypes.GET_USER_PROFILE_DATA,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                if(decrypt){
                    decryptProfileData(decrypt, data, dispatch);
                }else {
                    dispatch({
                        type: ActionTypes.GET_USER_PROFILE_DATA_SUCCESS,
                        response: data || {},
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_USER_PROFILE_DATA_FAILURE,
                    response: data,
                });
            }
        },
        (error) => {
            dispatch({
                type: ActionTypes.GET_USER_PROFILE_DATA_ERROR,
                response: error.data,
            });
        }
    );
};

export const updatePersonalInformation = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: error.data,
            });
        }
    );
};

// PROFILE CONTACT INFORMATIONS ACTIONS

export const addProfileContactInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_ADD_CONTACT_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_ADD_CONTACT_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_ADD_CONTACT_SUCCESS,
                    response: data,
                    isSameAddress:
                        payload?.isMailingAddress && payload?.isPhysicalAddress,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: error.data,
            });
        }
    );
};

export const updateProfileContactInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_CONTACT_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_CONTACT_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_CONTACT_SUCCESS,
                    response: data,
                });
            } else if(responseCode === '6003'){
                dispatch({
                    type: ActionTypes.PROFILE_LOCK_ACCOUNT,
                    response: data,
                });
            }else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            const { responseCode } = data;
            if(responseCode === '6003'){
                dispatch({
                    type: ActionTypes.PROFILE_LOCK_ACCOUNT,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        }
    );
};

export const deleteProfileContactInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_DELETE_CONTACT_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_DELETE_CONTACT_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_DELETE_CONTACT_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE PHONE INFORMATIONS ACTIONS

export const deleteProfilePhoneInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_DELETE_PROFILE_PHONE_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

export const updateProfilePhoneInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE EMAIL INFORMATIONS ACTIONS

export const addProfileEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_ADD_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_ADD_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_ADD_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

export const updateProfileEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

export const verifyProfileEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_VERIFY_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_VERIFY_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_VERIFY_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data.result,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data.result,
            });
        }
    );
};

export const otpConfirmProfileEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data.result,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data.result,
            });
        }
    );
};

export const deleteProfileEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_DELETE_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_DELETE_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_DELETE_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE OCCUPATION INFORMATIONS ACTIONS

export const updateProfileOccupationInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_OCCUPATION_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_OCCUPATION_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_OCCUPATION_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE FINANCIAL INFORMATIONS ACTIONS

export const updateProfileFinancialInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_FINANCIAL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_FINANCIAL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_FINANCIAL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE MILITARY INFORMATIONS ACTIONS
async function decryptMilitaryData(decrypt, data, dispatch){
    const { result:militaryInformation } = data || {};
    const { branchOfService, rank, militaryStatus } = militaryInformation || {};
    let errorInDecryption = false;
    const results = [];

    callKMSService(
        [
            { name: 'branchOfService', value: branchOfService },
            { name: 'rank', value: rank },
            { name: 'militaryStatus', value: militaryStatus },
        ],
        results,
        decrypt,
    );

    const decryptedValues = await Promise.all(results);
    errorInDecryption = updateValueAndReturnError(decryptedValues, militaryInformation);

    dispatch({
        type: ActionTypes.PROFILE_UPDATE_MILITARY_SUCCESS,
        response: data,
    });

    if(errorInDecryption){
        dispatch({
            type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
            response: 'Error happened during decrypting sensitive profile information',
        });
    }
}

export const updateProfileMilitaryInformations = (payload, decrypt) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_MILITARY_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_MILITARY_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                if(decrypt){
                    decryptMilitaryData(decrypt, data, dispatch);
                }else {
                    dispatch({
                        type: ActionTypes.PROFILE_UPDATE_MILITARY_SUCCESS,
                        response: data,
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// PROFILE RELATIONSHIP INFORMATIONS ACTIONS

export const addProfileRelationshipInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_ADD_RELATIONSHIP_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_ADD_RELATIONSHIP_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_ADD_RELATIONSHIP_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// GET PROFILE RELATIONSHIP INFORMATION

export const getRelationshipInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_GET_RELATIONSHIP_INFORMATION,
    });
    makeApiRequest(
        ActionTypes.PROFILE_GET_RELATIONSHIP_INFORMATION,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_GET_RELATIONSHIP_SUCCESS,
                    response: data || {},
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

export const updateProfileRelationship = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_RELATIONSHIP_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

export const deleteProfileRelationshipInformations = (payload) => (
    dispatch
) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_DELETE_RELATIONSHIP_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_DELETE_RELATIONSHIP_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_DELETE_RELATIONSHIP_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// RELATION PHONE INFORMATIONS ACTIONS

export const updateRelationPhoneInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_RELATION_PHONE_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// RELATION EMAIL INFORMATIONS ACTIONS

export const updateRelationEmailInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// RELATION ADDRESS INFORMATIONS ACTIONS

export const updateRelationAddressInformations = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_SUCCESS,
                    response: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// ADDRESS LOOKUP DATA

export const getAddressLookUpInfo = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION,
    });
    makeApiRequest(
        ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION_SUCCESS,
                    response: data || {},
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// GET ACCOUNT LEVEL ADDRESSES

export const getAccountLevelAddresses = (payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES,
    });
    makeApiRequest(
        ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES_SUCCESS,
                    response: data || {},
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// UPDATE ACCOUNT LEVEL ADDRESSES

export const updateAccountLevelAddresses = (payload) => (dispatch) => {
    const { accountInformation, addressInformation, addressAction } = payload;
    const {
        accountNumber='',
        statementGroupId='',
        primaryOwner='',
        fundNumber='', 
        fundAccountNumber='', 
        companyNumber='',
        accountHoldingGroup='',
        accountTypeCode='',
    } = accountInformation;
    const { 
        addressLine1='',
        addressLine2='',
        city='',
        state='',
        zip='',
        isPhysicalAddress='',
        isMailingAddress='',
        isSeasonalAddress='',
        addressId='',
        dateValidFrom,
        dateValidTo
    } = addressInformation;
    
    const jsondata = {
            addressLine1,
            addressLine2,
            city,
            state,
            zip,
            isPhysicalAddress,
            isMailingAddress,
            isSeasonalAddress,
            addressId,
            accountNumber,
            statementGroupId,
            fundNumber,
            fundAccountNumber,
            primaryOwner,
            companyNumber,
            addressAction,
            accountHoldingGroup,
            accountTypeCode
    };
    if(isSeasonalAddress){
        jsondata.dateValidFrom = dateValidFrom;
        jsondata.dateValidTo = dateValidTo;
    }
    
    const reqPayload = {
        addressUpdate: [{
            ...jsondata
        }],
    };
    const env = "";
    dispatch({
        type: ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES,
    });
    makeApiRequest(
        ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES,
        reqPayload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                dispatch({
                    type: ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES_SUCCESS,
                    response: data,
                    payload,
                });
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_INFORMATIONS_ERROR,
                response: data,
            });
        }
    );
};

// SET PHYSICAL ADDRESS

export const togglePhysicalAddress = (index) => (dispatch) => {
    dispatch({
        type: ActionTypes.PROFILE_SET_PHYSICAL_ADDRESS,
        index, 
    });
};

// SET MAILING ADDRESS

export const toggleMailingAddress = (index) => (dispatch) => {
    dispatch({
        type: ActionTypes.PROFILE_SET_MAILING_ADDRESS,
        index,
    });
};

// CALL USPS API FOR VERIFYING ADDRESS

export const callUSPSServices = (mailingAddress, physicalAddress) => (dispatch) => {
    const env= '';
    const { 
        addressLine1: mailingAddr1,
        addressLine2: mailingAddr2,
        zip:mailingZip,
        city:mailingCity,
        state:mailingState,
        uspsVerifiedAddress: uspsVerifiedMailingAddress = null,
        index: mailingIndex,
    } = mailingAddress;
    const mailingPayload = {
        addressLine1: mailingAddr1 || '',
        addressLine2: mailingAddr2,
        zip: mailingZip,
        city: mailingCity,
        state: mailingState,
    };
    
    const {
        addressLine1: physicalAddr1,
        addressLine2: physicalAddr2,
        zip:physicalZip,
        city:physicalCity,
        state:physicalState,
        uspsVerifiedAddress: uspsVerifiedPhysicalAddress = null,
        index: physicalIndex,
    } = physicalAddress;
    const physicalPayload = {
        addressLine1: physicalAddr1 || '',
        addressLine2: physicalAddr2,
        zip: physicalZip,
        city: physicalCity,
        state: physicalState,
    };
    
    if(!uspsVerifiedMailingAddress || !uspsVerifiedPhysicalAddress){
        
        const payload = {
            addresses:[]
        };
        
        if(!uspsVerifiedMailingAddress) payload.addresses.push({...mailingPayload, addressType: "0"});
        if(!uspsVerifiedPhysicalAddress) payload.addresses.push({...physicalPayload, addressType: "1"});
        if(mailingIndex === physicalIndex){
            payload.addresses = [{...mailingPayload, addressType: "2"}];
        }
        
        dispatch({
            type: ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED,
        });
    
        makeApiRequest(
            ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED,
            payload,
            env,
            response => {
                const { data } = response;
                const { responseCode } = data;
                if (responseCode === '6000') {
                    dispatch({
                        type: ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED_SUCCESS,
                        response: response.data,
                    });
                }else {
                    dispatch({
                        type: ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED_ERROR,
                        response: data,
                    });
                }
            },
            error => {
                const { response } = error || {};
                const { data = {} } = response || {};
                dispatch({
                    type: ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED_ERROR,
                    response: data,
                });
            },
        );
    } else {
        dispatch({
            type: ActionTypes.SHOW_USPS_VERIFIED_ADDRESSES,
        });
    }
};

// ADD NEW ADDRESS TO EXISTING LIST

export const addNewAddress = (newAddress) => (dispatch) => {
  dispatch({
        type: ActionTypes.PROFILE_SAVE_NEW_ADDRESS,
        newAddress,
    });
};


export const closeFinancialRemainder = () => (dispatch) => {
  dispatch({
        type: ActionTypes.PROFILE_FINANCIAL_REMAINDER_CLOSE,
    });
};

export const closeEmptyInfoNotification = () => (dispatch) => {
    dispatch({
          type: ActionTypes.PROFILE_INFORMATION_EMPTY_NOTIFICATION,
      });
  };

// CLEAR EXISTING ACTION TYPES

export const clearExistingActions = () => (dispatch) => {
    dispatch({
        type: ActionTypes.PROFILE_REMOVE_EXISTING_VALUES,
    });
};

export const clearContactDetails = () => (dispatch) => {
    dispatch({
        type: ActionTypes.PROFILE_ADD_CONTACT_CLEAR,
    });
};
