import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledButton } from 'common';
import Separator from '../Separator';

export const StyledDiv = styled.div`
    margin: 0 0 ${props => props.mb}rem 0;
`;
const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ActionSection = styled(StyledButton)`
    display: flex;
    align-self: center;
`;

const ActionText = styled.span`
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;

const ActionImg = styled.img`
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
`;

const TitleWithAction = (props) => {
    const { title, ariaLabel, onActionClick, actionText, actionIcon, actionIconAlt, mb, sectionTitle, hideAction } = props;
    return (
        <StyledDiv mb={mb}>
        <FlexDiv>
            {title}
            { !hideAction && (
            <ActionSection aria-label={ariaLabel} onClick={onActionClick}>
                <ActionImg src={actionIcon} alt={actionIconAlt} />
                <ActionText>{actionText}</ActionText>
            </ActionSection>
            )}
        </FlexDiv>
        {sectionTitle && <Separator mT={0.125} aria-hidden="true" />}
        </StyledDiv>
    );
};

TitleWithAction.propTypes = {
    title: PropTypes.instanceOf(Object),
    ariaLabel: PropTypes.string,
    onActionClick: PropTypes.func,
    actionText: PropTypes.string,
    actionIcon: PropTypes.string,
    actionIconAlt: PropTypes.string,
    mb: PropTypes.oneOfType([ PropTypes.string, PropTypes.number,]),
    sectionTitle: PropTypes.bool,
    hideAction: PropTypes.bool,
};

TitleWithAction.defaultProps = {
    title: null,
    ariaLabel: '',
    onActionClick: () => {},
    actionText: '',
    actionIcon: '',
    actionIconAlt: '',
    mb: 0,
    sectionTitle: false,
    hideAction: false,
};

export default TitleWithAction;
