import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { setAddress, resetAddressInfo } from 'shared/Actions/AddressAction';
import { updateProfileRelationship, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import RelationshipAddContactInfoComponent from './RelationshipAddContactInfoComponent';

export const mapStateToProps = state => {
    const { profileRelationshipData, masterLookUpData, address } = state;
    const { isError } = profileRelationshipData;
    return {
        masterLookupStateData: masterLookUpData,
        addressInfo: address,
        profileRelationshipData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    setAddress,
    resetAddressInfo,
    updateProfileRelationship,
    clearRelationActions,
    analyzeClickEvent, 
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipAddContactInfoComponent));
