
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    menu: [
        {
            key: '1',
            title: 'Order ID - LIQ201820111',
            data: {
                count: 5,
                Dateadded: '28/11/2019',
                TransactionType: 'Liquidation',
                OrderStatus: 'Pending',
                totalSHares: "2452",
                worth: "5400",
                "selectedAccountData": {
                    "accountType": "IRA",
                    "accountName": "1",
                    "accountNumber": "xxxx-xxxx-xxxx",
                    "currentValue": "5602",
                    "holdingValue": "1500",
                    "AutomaticInvestmentPlan": "yes"
                },
                "selectedFundData": {
                    "fundName": "USSPX VCM 1100 INDEX FUND MEMBER CLASS SHARES",
                    "fundNumber": "",
                    "fundingOption": "",
                    "initialInvestment": "",
                    "monthlyInvestment": "",
                    "startDate": "",
                    "count": "",
                    "total": "",
                    "funds": [
                        {
                            "fundName": 'USSPX VCM 1100 INDEX FUND MEMBER CLASS SHARES',
                            'FundNumber': '20',
                            "totalShares": '2452',
                            "worthAmount": '2400',
                            'allSharesSelected': false,
                            'dollarSelected': true,
                            'percentageSelected': false,
                            'dollarValue': '2100',
                            'percentageValue': '',
                            "sellingAmount": "2100",
                            'isSelected': true,
                            'TypeValueReq':'D'
                        },
                    ]
                },
                "selectedFundWithdrawalData": {
                    "checkSelectedOrder": false,
                    "bankAccountNo": "XXX-XXX-3839",
                    "bankAccountName": "Bank Account 1",
                    "taxWithHoldingOption": "Withhold Taxes",
                    "requestedAmountType": 'Before Taxes',
                    "PaymentMethod": '05',
                    "amountBeforeTaxes": '1000',
                    "amountAfterTaxes": '',
                    "federalTaxInPerc": '10',
                    "federalTaxInDollars": '100',
                    "stateTaxInPerc": '10',
                    "stateTaxInDollars": '100',
                    "totalTaxToBeWithHold": '200',
                    "totalYouWillReceive": '800',
                    "totalWithdrawal": '1000',
                },
                "selectedFundSourceData": {
                    "paymentMode": "NetBanking",
                    "fundSourceType": "",
                    "totalInvestment": "",
                    "bankAccountName": "",
                    "bankAccountNumber": "XXX-XXX-3839",
                },
                "currentSecurities": {
                    "reinvest": false
                },
                "contribution": {
                    "contribution": ""
                },
                "estimated": {
                    "estimatedFee": "",
                    "estimatedNetTradeAmt": "",
                    "effectiveDate": ""
                }

            }
        },
        {
            key: '2',
            title: 'Order ID - PUR201820112',
            data: {
                count: 5,
                Dateadded: '28/11/2019',
                TransactionType: 'Purchase',
                OrderStatus: 'Pending',
                totalSHares: "2452",
                worth: "5400",
                "selectedAccountData": {
                    "accountType": "IRA",
                    "accountName": "2",
                    "accountNumber": "222222222222",
                    "currentValue": "5602",
                    "holdingValue": "1500",
                    "AutomaticInvestmentPlan": "yes"
                },
                "selectedFundData": {
                    "fundName": "USAA Science & Technology Adviser",
                    "fundNumber": "331",
                    "fundingOption": "Initial Investment",
                    "initialInvestment": "4500",
                    "monthlyInvestment": "",
                    "startDate": "",
                    "count": "",
                    "total": "4500",
                    "funds": [
                        {
                            "fundName": 'USAA Science & Technology Adviser',
                            'FundNumber':'',
                            "totalShares": '2452',
                            "worthAmount": '5400',
                            'allSharesSelected': false,
                            'dollarSelected': false,
                            'percentageSelected': false,
                            'dollarValue': '',
                            'percentageValue': '',
                            "sellingAmount": "",
                            'isSelected': false,
                            'TypeValueReq':''
                        },
                    ]
                },
                "selectedFundWithdrawalData": {
                    "checkSelectedOrder": false,
                    "bankAccountNo": '',
                    "bankAccountName": '',
                    "taxWithHoldingOption": "Withhold Taxes",
                    "requestedAmountType": 'Before Taxes',
                    "amountBeforeTaxes": '1000',
                    "amountAfterTaxes": '',
                    "federalTaxInPerc": '10',
                    "federalTaxInDollars": '100',
                    "stateTaxInPerc": '10',
                    "stateTaxInDollars": '100',
                    "totalTaxToBeWithHold": '200',
                    "totalYouWillReceive": '800',
                    "totalWithdrawal": '1000',
                },
                "selectedFundSourceData": {
                    "paymentMode": "Wire Transfer",
                    "fundSourceType": "Offline",
                    "totalInvestment": "4500",
                    "bankAccountName": "",
                    "bankAccountNumber": "",
                },
                "currentSecurities": {
                    "reinvest": true
                },
                "contribution": {
                    "contribution": "Current Year"
                },
                "estimated": {
                    "estimatedFee": "",
                    "estimatedNetTradeAmt": "",
                    "effectiveDate": ""
                }

            }
        },
        {
            key: '3',
            title: 'Order ID - PUR201820113',
            data: {
                count: 5,
                Dateadded: '28/11/2019',
                TransactionType: 'Purchase',
                OrderStatus: 'Pending',
                totalSHares: "2452",
                worth: "5400",
                "selectedAccountData": {
                    "accountType": "UTMA",
                    "accountName": "3",
                    "accountNumber": "333333333333",
                    "currentValue": "5603",
                    "holdingValue": "1300",
                    "AutomaticInvestmentPlan": "yes"
                },
                "selectedFundData": {
                    "fundName": "USAA Science & Technology Adviser",
                    "fundNumber": "331",
                    "fundingOption": "Initial Investment",
                    "initialInvestment": "5000",
                    "monthlyInvestment": "",
                    "startDate": "",
                    "count": "",
                    "total": "5000",
                    "funds": [
                        {
                            "fundName": 'USAA Science & Technology Adviser',
                            'FundNumber':'',
                            "totalShares": '2452',
                            "worthAmount": '5400',
                            'allSharesSelected': false,
                            'dollarSelected': false,
                            'percentageSelected': false,
                            'dollarValue': '',
                            'percentageValue': '',
                            "sellingAmount": "",
                            'isSelected': false,
                            'TypeValueReq':''
                        },
                    ]
                },
                "selectedFundWithdrawalData": {
                    "checkSelectedOrder": false,
                    "bankAccountNo": '',
                    "bankAccountName": '',
                    "taxWithHoldingOption": '',
                    "requestedAmountType": 'Before Taxes',
                    "amountBeforeTaxes": '',
                    "amountAfterTaxes": '',
                    "federalTaxInPerc": '',
                    "federalTaxInDollars": '',
                    "stateTaxInPerc": '',
                    "stateTaxInDollars": '',
                    "totalTaxToBeWithHold": '',
                    "totalYouWillReceive": '',
                    "totalWithdrawal": '',
                },
                "selectedFundSourceData": {
                    "paymentMode": "NetBanking",
                    "fundSourceType": "Online",
                    "totalInvestment": "5000",
                    "bankAccountName": "Bank Account 1",
                    "bankAccountNumber": "XXX-XXX-3838",
                },
                "currentSecurities": {
                    "reinvest": false
                },
                "contribution": {
                    "contribution": ""
                },
                "estimated": {
                    "estimatedFee": "",
                    "estimatedNetTradeAmt": "",
                    "effectiveDate": ""
                }

            }
        },
        {
            key: '4',
            title: 'Order ID - EXC201820114',
            data: {
                count: 5,
                Dateadded: '28/11/2019',
                TransactionType: 'Exchange',
                OrderStatus: 'Pending',
                totalSHares: "2462",
                worth: "5400",
                "selectedAccountData": {
                    "accountType": "IRA",
                    "accountName": "1",
                    "accountNumber": "xxxx-xxxx-xxxx",
                    "currentValue": "5604",
                    "holdingValue": "1500",
                    "AutomaticInvestmentPlan": "yes"
                },
                "selectedFundData": {
                    "fundName": 'USAA Science & Technology Adviser',
                    "fundNumber": "331",
                    "fundingOption": "Initial Investment",
                    "initialInvestment": "5000",
                    "monthlyInvestment": "",
                    "startDate": "",
                    "count": "",
                    "total": "",
                    "funds": [
                        {
                            "fundName": 'USSPX VCM 1200 INDEX FUND MEMBER CLASS SHARES',
                            'FundNumber':'',
                            "totalShares": '2462',
                            "worthAmount": '5400',
                            'allSharesSelected': false,
                            'dollarSelected': true,
                            'percentageSelected': false,
                            'dollarValue': '2500',
                            'percentageValue': '',
                            "sellingAmount": "2500",
                            'isSelected': true,
                            'TypeValueReq':''
                        },
                    ]
                },
                "selectedFundWithdrawalData": {
                    "checkSelectedOrder": false,
                    "bankAccountNo": '',
                    "bankAccountName": '',
                    "taxWithHoldingOption": '',
                    "requestedAmountType": 'Before Taxes',
                    "amountBeforeTaxes": '',
                    "amountAfterTaxes": '',
                    "federalTaxInPerc": '',
                    "federalTaxInDollars": '',
                    "stateTaxInPerc": '',
                    "stateTaxInDollars": '',
                    "totalTaxToBeWithHold": '',
                    "totalYouWillReceive": '',
                    "totalWithdrawal": '',
                },
                "selectedFundSourceData": {
                    "paymentMode": "",
                    "fundSourceType": "",
                    "totalInvestment": "",
                    "bankAccountName": "",
                    "bankAccountNumber": "",
                },
                "currentSecurities": {
                    "reinvest": false
                },
                "contribution": {
                    "contribution": ""
                },
                "estimated": {
                    "estimatedFee": "",
                    "estimatedNetTradeAmt": "",
                    "effectiveDate": ""
                }

            }
        },
        {
            key: '5',
            title: 'Order ID - EXC201820115',
            data: {
                count: 5,
                Dateadded: '28/11/2019',
                TransactionType: 'Exchange',
                OrderStatus: 'Pending',
                totalSHares: "2462",
                worth: "2400",
                "selectedAccountData": {
                    "accountType": "IRA",
                    "accountName": "1",
                    "accountNumber": "xxxx-xxxx-xxxx",
                    "currentValue": "5605",
                    "holdingValue": "1500",
                    "AutomaticInvestmentPlan": "yes"
                },
                "selectedFundData": {
                    "fundName": "USAA Science & Technology Adviser",
                    "fundNumber": "331",
                    "fundingOption": "Initial Investment",
                    "initialInvestment": "5000",
                    "monthlyInvestment": "",
                    "startDate": "",
                    "count": "",
                    "total": "",
                    "funds": [
                        {
                            "fundName": 'USSPX VCM 1100 INDEX FUND MEMBER CLASS SHARES',
                            'FundNumber':'',
                            "totalShares": '2462',
                            "worthAmount": '2400',
                            'allSharesSelected': false,
                            'dollarSelected': true,
                            'percentageSelected': false,
                            'dollarValue': '2100',
                            'percentageValue': '',
                            "sellingAmount": "2100",
                            'isSelected': true,
                            'TypeValueReq':''
                        },
                    ]
                },
                "selectedFundWithdrawalData": {
                    "checkSelectedOrder": false,
                    "bankAccountNo": '',
                    "bankAccountName": '',
                    "taxWithHoldingOption": '',
                    "requestedAmountType": 'Before Taxes',
                    "amountBeforeTaxes": '',
                    "amountAfterTaxes": '',
                    "federalTaxInPerc": '',
                    "federalTaxInDollars": '',
                    "stateTaxInPerc": '',
                    "stateTaxInDollars": '',
                    "totalTaxToBeWithHold": '',
                    "totalYouWillReceive": '',
                    "totalWithdrawal": '',
                },
                "selectedFundSourceData": {
                    "paymentMode": "In-Order",
                    "fundSourceType": "",
                    "totalInvestment": "",
                    "bankAccountName": "",
                    "bankAccountNumber": "",
                },
                "currentSecurities": {
                    "reinvest": false
                },
                "contribution": {
                    "contribution": ""
                },
                "estimated": {
                    "estimatedFee": "",
                    "estimatedNetTradeAmt": "",
                    "effectiveDate": ""
                }

            }
        },



    ]
};


function amendReducerData(state = initialState, action) {
    switch (action.type) {
        /* case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action
            };
        } */
        case ActionTypes.AMEND_METHODS_SUCCESS: {
            return {
                ...state,
                ...action
            };
        }
        case ActionTypes.GET_PENDINGTRANSACTION_DATA: {
            return {
                ...state,
                ...action
            };
        }
        
        case ActionTypes.GET_PENDINGTRANSACTION_DATA_LOADING: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.GET_PENDINGTRANSACTION_DATA_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        case ActionTypes.GET_PENDINGTRANSACTION_DATA_ERROR: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        default:
            return state;
    }
}

export default amendReducerData;