import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledPara = styled.p`
    opacity: 1;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
`;

const ContactNumber = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
`;

const PageDescription = (props) => {
    const { line1, line2, line3, msr } = props;
    return (
        <StyledPara>
            {line1}
            <br />
            {line2}
            <ContactNumber>{msr}</ContactNumber>
            <br />
            {line3}
        </StyledPara>
    );
};

PageDescription.propTypes = {
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    msr: PropTypes.string,
};

PageDescription.defaultProps = {
    line1: "",
    line2: "",
    line3: "",
    msr: "",
};

export default memo(PageDescription);