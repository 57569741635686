import routeConstants from 'routeConstants';

const accountManagement = 'Account Management';
const customerManagement = 'Customer Management';
const userManagement = 'User Management';
export const ACCOUNT_SERVICES = 'Account Services';
const messageCenter = 'Message Center';


export const STEP_KEY = "stepname";
// Account Services
export const ACCOUNT_SERVICES_INITIAL_PAGE_NAME = "Account Services|Home";

// Bank Accounts
export const ADD_ACCOUNT = "Add Account";
export const BANK_ACCOUNTS = "Bank Accounts";
export const BANKS_INITIAL_PAGE = {STEP_KEY, STEP_NAME:ADD_ACCOUNT};
export const BANKS_ADD_PAGE = {STEP_KEY, STEP_NAME:BANK_ACCOUNTS};
export const ADD_BUTTON = "Bank_Accounts_Add_Button";
export const SUBMIT_ADD_BANK = "Add_Bank_Submit_Button";
export const ACCOUNT_SERVICE_TYPE_ADD_BANK = 'addBankAccount';
export const CARD_BANK_ACCOUNTS_CLICK = "CardBankAccounts";
// Dividends
export const DIVIDENDS_INITIAL_PAGE_NAME = 'Home';
export const DIVIDENDS_EDIT_PAGE_NAME = 'Edit';
export const DIVIDENDS_INITIAL_PAGE = {STEP_KEY, STEP_NAME:DIVIDENDS_INITIAL_PAGE_NAME};
export const DIVIDENDS_EDIT_PAGE = {STEP_KEY, STEP_NAME:DIVIDENDS_EDIT_PAGE_NAME};
export const DIVIDENDS_EDIT_BUTTON = "accountservices_dividends_home_edit";
export const DIVIDENDS_EDIT_PAGE_APPLY_BUTTON = "accountservices_dividends_edit_all_apply";
export const DIVIDENDS_EDIT_PAGE_SUBMIT_BUTTON = "accountservices_dividends_edit_submit";
export const DIVIDENDS_EDIT_PAGE_CANCEL_BUTTON = "accountservices_dividends_edit_cancel";
export const CARD_DIVIDENDS_CLICK = "accountservices_home_dividendscard";
export const DIVIDENTS_NAV_BUTTON_ID = "accountservices_dividends_edit";
export const ACCOUNT_SERVICE_TYPE_DIVIDENDS = "dividends";
export const ACCOUNT_FLOW_TYPE_DIVIDENDS_ALL = "edit|all";
export const ACCOUNT_FLOW_TYPE_DIVIDENDS = "edit";
// AIP
export const AIP_INITIAL_PAGE_NAME = 'Home';
export const AIP_DELETE_PAGE_NAME = 'Delete';
export const CARD_AIP_CLICK = "accountservices_home_aip";
export const PLAN_DETAILS_PAGE_NAME = 'PlanDetails';
export const SCHEDULE_PAGE_NAME = 'Schedule';
export const AIP_HOME_NAV_BUTTON_ID = "accountservices_aip_home";
export const AIP_EDIT_ACCOUNT_SELECTION_NAV_BUTTON_ID = "accountservices_aip_edit_accountselection";
export const AIP_ADD_ACCOUNT_SELECTION_NAV_BUTTON_ID = "accountservices_aip_add_accountselection";
export const AIP_EDIT_PLAN_DETAILS_NAV_BUTTON_ID = "accountservices_aip_edit_plandetails";
export const AIP_ADD_PLAN_DETAILS_NAV_BUTTON_ID = "accountservices_aip_add_plandetails";
export const AIP_EDIT_SCHEDULE_NAV_BUTTON_ID = "accountservices_aip_edit_schedule";
export const AIP_NAV_BUTTON_ID = "accountservices_aip";
export const AIP_ADD_BUTTON = "accountservices_aip_home_add";
export const AIP_EDIT_BUTTON = "accountservices_aip_home_edit";
export const AIP_DELETE_BUTTON = "accountservices_aip_home_delete";
export const AIP_VERIFY_EDIT_BUTTON = "accountservices_aipedit_verifyedit";
export const AIP_VERIFY_ADD_BUTTON = "accountservices_aipadd_verifyedit";
export const AIP_ADD_PAGE_NAME= "Add";
export const ACCOUNT_SERVICE_TYPE_AIP = "AIP";
export const ACCOUNT_FLOW_TYPE_AIP_DELETE = "delete";


export const SWP_NAV_BAR_IDS = {
    edit : ['accountservices_swp_edit_plandetails',
        'accountservices_swp_edit_schedule',
        'accountservices_swp_edit_verify'],
    add : ['accountservices_swp_add_accountselection',
        'accountservices_swp_add_plandetails',
        'accountservices_swp_add_schedule',
        'accountservices_swp_add_verify'],
}


export const AIP_NAV_BAR_IDS = {
    edit : ['accountservices_aip_edit_plandetails',
            'accountservices_aip_edit_schedule',
            'accountservices_aip_edit_verify'],
    add :  ['accountservices_aip_add_accountselection',
            'accountservices_aip_add_plandetails',
            'accountservices_aip_add_schedule',
            'accountservices_aip_add_verify']
}


// Common for AIP/SWP
export const ACCOUNT_SELECTION_PAGE_NAME = 'AccountSelection';
export const VERIFY_PAGE_NAME = 'Verify';
export const ACCOUNT_FLOW_TYPE_ADD = "add";
export const ACCOUNT_FLOW_TYPE_EDIT = "edit";
export const AIP_SWP_STEP_NAME_FREQUENCY = 'Frequency';
export const AIP_SWP_STEP_NAME_PLAN_DETAILS = 'Plan Details';
export const AIP_SWP_STEP_NAME_SCHEDULE = 'Schedule';
export const AIP_SWP_STEP_NAME_SELECT_ACCOUNT = 'Account Selection';
export const AIP_SWP_STEP_NAME_VERIFY = 'Verify';

// SWP
export const CARD_SWP_CLICK = "accountservices_home_swp";
export const SWP_INITIAL_PAGE_NAME = 'Home';
export const SWP_HOME_NAV_BUTTON_ID = "accountservices_swp_home";
export const SWP_ADD_BUTTON = "accountservices_swp_home_add";
export const SWP_EDIT_BUTTON = "accountservices_swp_home_edit";
export const SWP_DELETE_BUTTON = "accountservices_swp_home_delete";
export const SWP_DELETE_PAGE_NAME = 'Delete';
export const ACCOUNT_SERVICE_TYPE_SWP = "SWP";
export const ACCOUNT_FLOW_TYPE_SWP_DELETE = "delete";
export const SWP_ADD_PAGE_NAME= "Add";
export const SWP_NAV_BUTTON_ID = "accountservices_swp";
export const SWP_ADD_ACCOUNT_SELECTION_NAV_BUTTON_ID = "accountservices_swp_add_accountselection";
export const SWP_VERIFY_ADD_BUTTON = "accountservices_swpadd_verifyedit";
export const SWP_VERIFY_EDIT_BUTTON = "accountservices_swpedit_verifyedit";

// CheckBook
export const CARD_CHECKBOOK_CLICK = "accountservices_home_checkbook";
export const CHECKBOOK_HOME_PAGE_NAME = "checkbook home"; 
export const CHECKBOOK_REORDER_PAGE_NAME = "checkbook reorder"; 
export const CHECKBOOK_ORDER_BUTTON_ID = 'accountservices_checkbook_order'
export const CHECKBOOK_CLOSE_BUTTON_ID = 'accountservices_checkbook_modal_close'
export const ACCOUNT_SERVICE_TYPE_CHECKBOOK = 'checkbook' 
export const ACCOUNT_FLOW_TYPE_CHECKBOOK = 'reorder checkbook' 
export const CHECKBOOK_NAV_BUTTON_ID = 'accountservices_checkbook'


// Breadcrumbs
export const BREAD_CRUMB_SCREEN_AIP = 'accountservices_aip' 
export const BREAD_CRUMB_SCREEN_AIPADD = 'accountservices_aipadd' 
export const BREAD_CRUMB_SCREEN_AIPEDIT = 'accountservices_aipedit' 
export const BREAD_CRUMB_SCREEN_SWP = 'accountservices_swp' 
export const BREAD_CRUMB_SCREEN_SWPADD = 'accountservices_swpadd' 
export const BREAD_CRUMB_SCREEN_SWPEDIT = 'accountservices_swpedit' 
export const BREAD_CRUMB_SCREEN_CHECKBOOK = "accountservices_checkbook"
export const BREAD_CRUMB_SCREEN_BENEFICIARIES_HOME = "accountservices_beneficiaries_home"
export const BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_HOME = "accountservices_beneficiariesedit_home"
export const BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_CHANGES = "accountservices_beneficiariesedit_changes"
export const BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_VERIFY = "accountservices_beneficiariesedit_verify"
export const BREAD_CRUMB_SCREEN_BANK_ACCOUNTS = 'accountservices_bankaccounts'
export const BREAD_CRUMB_SCREEN_DIVIDENDS = 'accountservices_dividends'


// Manage Beneficiaries
export const CARD_BENEFICIARIES_CLICK = "accountservices_home_beneficiaries";
export const BENEFICIARIES_HOME_PAGE_NAME = "Beneficiaries home"
export const BENEFICIARIES_EDIT_PAGE_NAME = "Beneficiaries edit"
export const BENEFICIARIES_ACCOUNT_SELECTED_LINK = "accountservices_managebeneficiaries_selectaccount"; 
export const BENEFICIARIES_MANAGE_BUTTON_ID = 'accountservices_managebeneficiaries_manage'
export const BENEFICIARIES_DELETE_BUTTON_ID = 'accountservices_managebeneficiaries_edit'
export const BENEFICIARIES_DELETE_BUTTON_ID_PRIMARY = 'accountservices_managebeneficiaries_primarybeneficiary_delete'
export const BENEFICIARIES_DELETE_BUTTON_ID_CONTINGENT = 'accountservices_managebeneficiaries_contingentbeneficiary_delete'
export const BENEFICIARIES_VERIFY_EDIT_BUTTON_PRIMARY = 'accountservices_managebeneficiaries_verify_primarybeneficiary_edit'
export const BENEFICIARIES_VERIFY_EDIT_BUTTON_CONTINGENT = 'accountservices_managebeneficiaries_verify_contingentbeneficiary_edit'
export const BENEFICIARIES_ACCOUNT_SELECT_EDIT = 'accountservices_managebeneficiaries_editAccount';
export const BENEFICIARIES_EDIT_BUTTON_ID = 'accountservices_managebeneficiaries_edit'
export const BENEFICIARIES_SHOW_HIDE_ACCOUNT_NUMBER_CLICK = 'accountservices_managebeneficiaries_account_number'
export const BENEFICIARIES_ADD_PRIMARY_BENEFICIARY_BUTTON_ID = 'accountservices_managebeneficiaries_addprimarybeneficiaries'
export const BENEFICIARIES_ADD_CONTINGENT_BENEFICIARY_BUTTON_ID = 'accountservices_managebeneficiaries_addcontingentbeneficiaries'
export const BENEFICIARIES_DISTRIBUTE_EQUALLY_BUTTON_ID = 'accountservices_managebeneficiaries_distributeequally'
export const BENEFICIARIES_ADD_BUTTON_ID = 'accountservices_managebeneficiaries_addbeneficiary'
export const BENEFICIARIES_ADD_ANOTHER_BUTTON_ID = 'accountservices_managebeneficiaries_addanotherbeneficiary'
export const BENEFICIARIES_ADD_CANCEL_BUTTON_ID = 'accountservices_managebeneficiaries_addcancel'
export const BENEFICIARIES_EDIT_NAV_BUTTON_ID = 'accountservices_managebeneficiaries_edit_home'
export const BENEFICIARIES_EDIT_VERIFY_VERIFY_NAV_BUTTON_ID = 'accountservices_managebeneficiaries_editverify_verify'
export const BENEFICIARIES_EDIT_VERIFY_CONFIRM_NAV_BUTTON_ID = 'accountservices_managebeneficiaries_editverify_confirm'
export const analyticsBreadcrumb = new Map([
    [routeConstants.accountholder, 'breadccrumb_accountholder_home'],
    [routeConstants.accountservices, 'breadccrumb_accountservice_home'],
    [routeConstants.automaticinvestment, 'breadccrumb_aip_home'],
    [routeConstants.editautomaticinvestment, 'breadccrumb_aip_edit'],
    [routeConstants.createAutomaticInvestment, 'breadccrumb_aip_add'],
    [routeConstants.systematicwithdrawal, 'breadccrumb_swp_home'],
    [routeConstants.editsystematicwithdrawal, 'breadccrumb_swp_edit'],
    [routeConstants.createSystematicWithdrawal, 'breadccrumb_swp_add'],
    [routeConstants.checkbook, 'breadccrumb_checkbook_home'],
])

export const digitalData = {
    version: '1.0',
    page: {
        pageInfo: {
            PageName: '',
            Section: '',
            pageDocumenttitle: '',
            pageURL: '',
            previousPagename: '',
            pagePercentageview: '',
            pageRef: '',
            pageType: '',
            environment: '',
            pageHier: 'Home->Portfolio',
            siteError: 'Error Page',
        },
        search: {
            internalSearchresults: '',
            internalSearchterm: '',
        },
    },
    user: {
        userInfo: {
            customerType: 'customer',
            userID: '1234',
            loggedinStatustype: 'Known Device',
            userType: 'VCM',
        },
    },
};

export const routeAndPageInfoMapping = [
    {
        key: routeConstants.home,
        module: 'Landing Page',
        screen: 'Home Page',
    },
    {
        key: routeConstants.terms,
        module: accountManagement,
        screen: 'Terms and Conditions',
    },
    { key: routeConstants.openAccount, module: accountManagement, screen: 'Open Account' },
    { key: routeConstants.accountType, module: accountManagement, screen: 'Account Type' },
    { key: routeConstants.investmentSelection, module: accountManagement, screen: 'Investment Selection' },
    { key: routeConstants.personalInfo, module: accountManagement, screen: 'Personal Info' },
    { key: routeConstants.fundSelection, module: accountManagement, screen: 'Fund Selection' },

    { key: routeConstants.compareFunds, module: accountManagement, screen: 'Compare Funds' },

    { key: routeConstants.fundSummary, module: accountManagement, screen: 'Fund Summary' },
    { key: routeConstants.summary, module: accountManagement, screen: 'Summary' },
    { key: routeConstants.performance, module: accountManagement, screen: 'Performance' },
    { key: routeConstants.quickFacts, module: accountManagement, screen: 'Quick Facts' },
    { key: routeConstants.preferences, module: accountManagement, screen: 'Preferences' },
    { key: routeConstants.verify, module: accountManagement, screen: 'Verify' },
    { key: routeConstants.esign, module: accountManagement, screen: 'Esign' },
    { key: routeConstants.servererror, module: accountManagement, screen: 'Server Error' },
    { key: routeConstants.oops, module: accountManagement, screen: 'Error Page' },
    { key: routeConstants.esa, module: accountManagement, screen: 'ESA' },
    { key: routeConstants.application, module: accountManagement, screen: 'Application' },
    { key: routeConstants.personalInfoChild, module: accountManagement, screen: 'Child Personal Info' },
    { key: routeConstants.beneficiaryInfoChild, module: accountManagement, screen: 'Child Beneficiary Info' },
    { key: routeConstants.editPreference, module: accountManagement, screen: 'Edit Preference' },
    { key: routeConstants.accountPreference, module: accountManagement, screen: 'Account Preference' },
    { key: routeConstants.taxDocuments, module: accountManagement, screen: 'Tax Documents' },
    { key: routeConstants.interestedParties, module: accountManagement, screen: 'Interested Parties' },
    { key: routeConstants.addInterestedParty, module: accountManagement, screen: 'Add Interested Party' },
    { key: routeConstants.refreshFinance, module: accountManagement, screen: 'Refresh Finance' },
    { key: routeConstants.confirmAccountOpening, module: accountManagement, screen: 'Confirm Account Opening' },

    { key: routeConstants.manageBeneficiaries, module: accountManagement, screen: 'Manage Beneficiaries' },
    { key: routeConstants.manageBeneficiariesAccounts, module: accountManagement, screen: 'Manage Beneficiaries' },
    { key: routeConstants.addPrimaryBeneficiary, module: accountManagement, screen: 'Add Primary Beneficiary' },
    { key: routeConstants.addContingentBeneficiary, module: accountManagement, screen: 'Add Contingent Beneficiary' },
    { key: routeConstants.agreement, module: accountManagement, screen: 'Agreement' },
    { key: routeConstants.privacy, module: accountManagement, screen: 'Privacy' },
    { key: routeConstants.paperApplication, module: accountManagement, screen: 'Paper Application' },
    { key: routeConstants.uploadOrMail, module: accountManagement, screen: 'Upload Or Mail' },

    { key: routeConstants.profile, module: customerManagement, screen: 'Profile & Preferences Dashboard' },
    { key: routeConstants.basicinformation, module: customerManagement, screen: 'Basic Information: Profile' },
    { key: routeConstants.personalInformation, module: customerManagement, screen: 'Manage Personal Information: Profile' },
    { key: routeConstants.contactInfo, module: customerManagement, screen: 'Manage Contact Information: Profile' },
    { key: routeConstants.addContactInfo, module: customerManagement, screen: 'Add Contact Information: Profile' },
    { key: routeConstants.phoneInfo, module: customerManagement, screen: 'Manage Phone Information: Profile' },
    { key: routeConstants.financialInfo, module: customerManagement, screen: 'Manage Financial Information: Profile' },
    { key: routeConstants.empInfo, module: customerManagement, screen: 'Manage Employee Information: Profile' },
    { key: routeConstants.militaryInfo, module: customerManagement, screen: 'Manage Military Information: Profile' },
    { key: routeConstants.emailInfo, module: customerManagement, screen: 'Manage Email Information: Profile' },    
    { key: routeConstants.addEmailInfo, module: customerManagement, screen: 'Add Email Information: Profile' },
    { key: routeConstants.relationshipeditEmail, module: customerManagement, screen: 'Relationship Edit Email Information: Profile' },
    { key: routeConstants.relationshipEditContact, module: customerManagement, screen: 'Relationship Edit Contact Information: Profile' },
    { key: routeConstants.relationshipAddContact, module: customerManagement, screen: 'Relationship Add Contact Information: Profile' },
    { key: routeConstants.relationshipPersonalInfo, module: customerManagement, screen: 'Manage Relationship Information: Profile' },
    { key: routeConstants.relationshipContactInfo, module: customerManagement, screen: 'Manage Relationship Contact Information: Profile' },
    { key: routeConstants.relationshipemailInfo, module: customerManagement, screen: 'Manage Relationship Email Information: Profile' },
    { key: routeConstants.relationshipAddEmailInfo, module: customerManagement, screen: 'Relationship Add Email Information: Profile' },
    { key: routeConstants.relationPhoneAdd, module: customerManagement, screen: 'Relationship Add Phone Number: Profile' },
    { key: routeConstants.relationPhoneEdit, module: customerManagement, screen: 'Relationship Edit Phone Number: Profile' },
    { key: routeConstants.manageRelationPhone, module: customerManagement, screen: 'Manage Relationship Phone Information: Profile' },
    { key: routeConstants.editAccountAddress, module: customerManagement, screen: 'Edit Contact Information: Profile' },
    { key: routeConstants.addPhoneNum, module: customerManagement, screen: 'Add Phone Number: Profile' },
    { key: routeConstants.addRelations, module: customerManagement, screen: 'Add Relationship Information: Profile' },
    { key: routeConstants.editEmail, module: customerManagement, screen: 'Edit Email Information: Profile' },
    { key: routeConstants.editPhone, module: customerManagement, screen: 'Edit Phone Number: Profile' },
    { key: routeConstants.editContact, module: customerManagement, screen: 'Edit Contact Information: Profile' },
    { key: routeConstants.addAccountAddress, module: customerManagement, screen: 'Add Contact Information: Profile' },
    { key: routeConstants.manageRelations, module: customerManagement, screen: 'Manage Relationship: Profile' },
    
    { key: routeConstants.marketingAndPrivacy, module: accountManagement, screen: 'Marketing And Privacy' },
    { key: routeConstants.deliverySettings, module: accountManagement, screen: 'Delivery Settings' },
    { key: routeConstants.accountmessaging, module: accountManagement, screen: 'Account Messaging' },
    { key: routeConstants.generaldocuments, module: accountManagement, screen: 'General Documents' },
    { key: routeConstants.securityFraudalerts, module: accountManagement, screen: 'Security Fraud Alerts' },
    { key: routeConstants.devicemanagement, module: accountManagement, screen: 'Device Management' },

    { key: routeConstants.registerEmail, module: userManagement, screen: 'Register Email' },
    { key: routeConstants.verifyEmail, module: userManagement, screen: 'Verify Email' },
    { key: routeConstants.registerOnlineId, module: userManagement, screen: 'Register Online Id' },
    { key: routeConstants.registerPassword, module: userManagement, screen: 'Register Password' },
    { key: routeConstants.regSuccess, module: userManagement, screen: 'Registeration Success' },
    { key: routeConstants.personalDetails, module: userManagement, screen: 'Personal Details' },
    { key: routeConstants.termsAndConditions, module: userManagement, screen: 'Terms And Conditions' },
    { key: routeConstants.verifyOtp, module: userManagement, screen: 'Verify Otp' },
    { key: routeConstants.otpIvrSelect, module: userManagement, screen: 'Choose Security Code Method' },
    { key: routeConstants.verifySecurityDetails, module: userManagement, screen: 'Verify Security Details' },
    { key: routeConstants.signIn, module: userManagement, screen: 'Sign In' },
    { key: routeConstants.otpAuth, module: userManagement, screen: 'OTP Auth' },
    { key: routeConstants.confirmOtp, module: userManagement, screen: 'Confirm OTP' },
    { key: routeConstants.setupSecurityQuestions, module: userManagement, screen: 'Setup Security Questions' },
    { key: routeConstants.deliveryPreference, module: userManagement, screen: 'Delivery Preference' },
    { key: routeConstants.confirmSecurityAnswers, module: userManagement, screen: 'Confirm Security Answers' },
    { key: routeConstants.signInBySoftToken, module: userManagement, screen: 'Sign In By Soft Token' },
    { key: routeConstants.recoverOnlineId, module: userManagement, screen: 'Recover Online Id' },
    { key: routeConstants.checkInbox, module: userManagement, screen: 'Check Inbox' },
    { key: routeConstants.jointOwnerVerificationSsn, module: userManagement, screen: 'Joint Owner Verification SSN' },
    {
        key: routeConstants.jointOwnerVerificationTerms,
        module: userManagement,
        screen: 'Joint Owner Verification Terms',
    },
    {
        key: routeConstants.jointOwnerVerificationMobile,
        module: userManagement,
        screen: 'Joint Owner Verification Mobile',
    },
    {
        key: routeConstants.jointOwnerVerificationOnlineId,
        module: userManagement,
        screen: 'Joint Owner Verification Online Id',
    },
    {
        key: routeConstants.jointOwnerVerificationPassword,
        module: userManagement,
        screen: 'Joint Owner Verification Password',
    },
    { key: routeConstants.createNewPassword, module: userManagement, screen: 'Create New Password' },
    { key: routeConstants.securityPreferences, module: userManagement, screen: 'Security Preferences' },
    { key: routeConstants.changeSignInMethodOptions, module: userManagement, screen: 'Change SignIn Method Options' },
    { key: routeConstants.changeSignInBySoftToken, module: userManagement, screen: 'Change SignIn By SoftToken' },
    { key: routeConstants.resetPassword, module: userManagement, screen: 'Reset Password' },
    {
        key: routeConstants.changeSignInByPushNotification,
        module: userManagement,
        screen: 'Change SignIn By Push Notification',
    },
    { key: routeConstants.mobileQuickSignIn, module: userManagement, screen: 'Mobile Quick SignIn' },
    { key: routeConstants.modifySecurityQns, module: userManagement, screen: 'Modify Security Questions' },
    { key: routeConstants.retrievePassword, module: userManagement, screen: 'Retrieve Password' },
    {
        key: routeConstants.reviewPersonalDetails,
        module: userManagement,
        screen: 'First Time Login Review Personal Details',
    },
    {
        key: routeConstants.manageAddressDetails,
        module: userManagement,
        screen: 'First Time Login Manage Address Details',
    },

    { key: routeConstants.accountservices, module: ACCOUNT_SERVICES, screen: 'Account Services' },
    { key: routeConstants.automaticinvestment, module: ACCOUNT_SERVICES, screen: 'AIP' },
    { key: routeConstants.editautomaticinvestment, module: ACCOUNT_SERVICES, screen: 'AIP' },
    { key: routeConstants.createAutomaticInvestment, module: ACCOUNT_SERVICES, screen: 'AIP' },
    { key: routeConstants.systematicwithdrawal, module: ACCOUNT_SERVICES, screen: 'SWP' },
    { key: routeConstants.editsystematicwithdrawal, module: ACCOUNT_SERVICES, screen: 'SWP' },
    { key: routeConstants.createSystematicWithdrawal, module: ACCOUNT_SERVICES, screen: 'SWP' },
    { key: routeConstants.checkbook, module: ACCOUNT_SERVICES, screen: 'Check Book' },
    { key: routeConstants.dividends, module: ACCOUNT_SERVICES, screen: 'Dividends' },
    { key: routeConstants.bankaccounts, module: ACCOUNT_SERVICES, screen: BANK_ACCOUNTS },
    { key: routeConstants.addaccounts, module: ACCOUNT_SERVICES, screen: ADD_ACCOUNT },
    { key: routeConstants.requiredMinimumDistribution, module: ACCOUNT_SERVICES, screen: 'Required Minimum Distribution' },
    { key: routeConstants.addRequiredMinimumDistribution, module: ACCOUNT_SERVICES, screen: 'Add Required Minimum Distribution' },
    { key: routeConstants.rmdDiY, module: ACCOUNT_SERVICES, screen: 'RMD DIY' },
    
    { key: routeConstants.transactionDashboard, module: 'Transactions', screen: 'Transaction Dashboard' },
    { key: routeConstants.transactionPurchases, module: 'Transactions', screen: 'Transaction Purchases' },
    {
        key: routeConstants.purchaseInvestmentSelection,
        module: 'Transactions',
        screen: 'Purchase Investment Selection',
    },
    { key: routeConstants.purchaseFundSource, module: 'Transactions', screen: 'Purchase Fund Source' },
    { key: routeConstants.purchaseReviewConfirm, module: 'Transactions', screen: 'Purchase Review Confirm' },
    { key: routeConstants.exchangeSucces, module: 'Transactions', screen: 'Exchange Succes' },
    { key: routeConstants.amendTransaction, module: 'Transactions', screen: 'Amend Transaction' },
    { key: routeConstants.amendProceed, module: 'Transactions', screen: 'Amend Proceed' },
    { key: routeConstants.amendCancel, module: 'Transactions', screen: 'Amend Cancel' },
    { key: routeConstants.liquidation, module: 'Transactions', screen: 'Liquidation' },
    { key: routeConstants.exchange, module: 'Transactions', screen: 'Exchange' },

    { key: routeConstants.accountguestview, module: 'Dashboard', screen: 'Guest User' },
    { key: routeConstants.accountholder, module: 'Dashboard', screen: 'Account Holder' },
    { key: routeConstants.accountholderTransactions, module: 'Dashboard', screen: 'Accountholder Transactions' },
    { key: routeConstants.accountholderActivity, module: 'Dashboard', screen: 'Accountholder Activity' },

    { key: routeConstants.messageCenter, module: messageCenter, screen: 'Inbox' },
    { key: routeConstants.createSecureMessage, module: messageCenter, screen: 'Create Secure Message' },

    { key: routeConstants.documentCenter, module: 'Document Center', screen: 'Document Center' },

    // { key: routeConstants.calculators, module: 'Calculators', screen: 'Calculators' },
    // { key: routeConstants.retirementPlanCalculator, module: 'Calculators', screen: 'Retirement Plan Calculator' },
    // { key: routeConstants.collegeSavingsCalculator, module: 'Calculators', screen: 'College Savings Calculator' },
    // { key: routeConstants.traditionalIRACalculator, module: 'Calculators', screen: 'Traditional IRA Calculator' },
    // {
    //     key: routeConstants.retirementNestEggCalculator,
    //     module: 'Calculators',
    //     screen: 'Retirement Nest Egg Calculator',
    // },
    // { key: routeConstants.rmdFutureCalculator, module: 'Calculators', screen: 'Rmd Future Calculator' },

    // { key: routeConstants.rmdCurrentCalculator, module: 'Calculators', screen: 'Rmd Current Calculator' },

    { key: routeConstants.accessForms, module: 'Access Forms', screen: 'Access Forms' },
    { key: routeConstants.FAQs, module: 'Dashboard', screen: 'FAQs' },
    { key: routeConstants.retirementplans, module: 'Retirement Plans', screen: 'Retirement Plans' },
    { key: routeConstants.accountRegistration, module: 'Account Registration', screen: 'account Registration' },
    { key: routeConstants.memberSignUp, module: userManagement, screen: 'VCM / USSA Member Sigup' },
    { key: routeConstants.memberDobValidation, module: userManagement, screen: 'DOB Validation' },
    { key: routeConstants.userDetails, module: userManagement, screen: 'Verify Personal Identity' },
    { key: `${routeConstants.accountRegistration}/`, module: userManagement, screen: 'VCM Registration' },
    { key: routeConstants.accountRegistrationUserDetails, module: userManagement, screen: 'Verify Personal Identity: Registration' },
    { key: routeConstants.accountRegistrationTC, module: userManagement, screen: 'Terms and conditions: Registration' },
    { key: routeConstants.accountRegistrationOTL, module: userManagement, screen: 'Choose OTP Method: Registration' },
    { key: routeConstants.accountRegistrationOTV, module: userManagement, screen: 'Enter Passcode: Registration' },
    { key: routeConstants.accountRegistrationUserId, module: userManagement, screen: 'Create User ID: Registration' },
    { key: routeConstants.accountRegistrationPass, module: userManagement, screen: 'Create Password: Registration' },
    { key: routeConstants.accountRegistrationSuccess, module: userManagement, screen: 'Successfully Registered: Registration' },
    { key: routeConstants.accountRegistrationSignOut, module: userManagement, screen: 'Session Expired: Registration' },

    // MSR Lookup
    { key: routeConstants.msrlookup, module: 'MSR Lookup', screen: 'MSR Lookup' },
    { key: routeConstants.uploadPendingDocuments, module: accountManagement, screen: 'Upload pending documents'},
];  

