//import { env } from "env";

let platformEnv = {};
platformEnv = window.location.hostname === 'localhost' ? process.env : window.env;
let isPassiveRegion = window.location.hostname.startsWith('passive.')

const envConfig = {
    ENV : platformEnv.REACT_APP_ENV, 
    AWS_PROJECT_REGION : platformEnv.REACT_APP_AWS_PROJECT_REGION, 
    AWS_COGNITO_REGION : platformEnv.REACT_APP_AWS_COGNITO_REGION, 
    AWS_USER_POOLS_ID : platformEnv.REACT_APP_AWS_USER_POOLS_ID, 
    AWS_USER_POOLS_WEB_CLIENT_ID : platformEnv.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID, 
    AWS_IDENTITY_POOL_ID: platformEnv.REACT_APP_AWS_IDENTITY_POOL_ID, 
    AWS_KMS_KEY: platformEnv.REACT_APP_AWS_KMS_KEY, 
    AWS_KMS_ACCESS_KEY: platformEnv.REACT_APP_AWS_KMS_ACCESS_KEY, 
    AWS_KMS_SECRET_ACCESS_KEY: platformEnv.REACT_APP_AWS_KMS_SECRET_ACCESS_KEY, 
    ENV_URL : platformEnv.REACT_APP_ENV_URL, 
    HOST : isPassiveRegion ? platformEnv.REACT_APP_PASSIVE_HOST : platformEnv.REACT_APP_HOST,
    PATH : platformEnv.REACT_APP_PATH, 

    ASCENSUS_API : platformEnv.REACT_APP_ASCENSUS_API, 
    ASCENSUS_APIGATEWAY : platformEnv.REACT_APP_ASCENSUS_APIGATEWAY, 
    MARSTONE_API : platformEnv.REACT_APP_MARSTONE_API, 
    PRELOGIN_HEADER_AUTH : platformEnv.REACT_APP_PRELOGIN_HEADER_AUTH, 
    USPS_API_KEY : platformEnv.REACT_APP_USPS_API_KEY, 
    DISABLE_FAB : platformEnv.REACT_APP_DISABLE_FAB, 
    ADOBE_ANALYTICS : platformEnv.REACT_APP_ADOBE_ANALYTICS, 
    ADOBE_ANALYTICS_ENCY_SALT : platformEnv.REACT_APP_ADOBE_ANALYTICS_ENCY_SALT, 
    FAQ_URL: platformEnv.REACT_APP_FAQ_URL, 
    RECAPTCHA_SITE_KEY : platformEnv.REACT_APP_RECAPTCHA_SITE_KEY, 
    TERMS_PDF_PRIVACY : platformEnv.REACT_APP_TERMS_PDF_PRIVACY, 
    TERMS_PDF_SERVICE : platformEnv.REACT_APP_TERMS_PDF_SERVICE, 
    TERMS_PDF_AGREEMENT : platformEnv.REACT_APP_TERMS_PDF_AGREEMENT, 

    MARSTONE_MONEY_MARKET_FUND_URL : platformEnv.REACT_APP_MARSTONE_MONEY_MARKET_FUND_URL, 
    COGNITO_CUSTOMER_GIACT_VERIFICATION : platformEnv.REACT_APP_COGNITO_CUSTOMER_GIACT_VERIFICATION, 
    COGNITO_CUSTOMER_GIACT_VERIFICATION_CLIENT_ID : platformEnv.REACT_APP_COGNITO_CUSTOMER_GIACT_VERIFICATION_CLIENT_ID, 
    COGNITO_CUSTOMER_GIACT_VERIFICATION_CREDENTIALS : platformEnv.REACT_APP_COGNITO_CUSTOMER_GIACT_VERIFICATION_CREDENTIALS, 
    ASCENSUS_REDIRECTION_URL : platformEnv.REACT_APP_ASCENSUS_REDIRECTION_URL, 
    GENESYS_COBROWSE_DEPLOYMENT_KEY : platformEnv.REACT_APP_GENESYS_COBROWSE_DEPLOYMENT_KEY, 
    GENESYS_LIVECHAT_DEPLOYMENT_KEY : platformEnv.REACT_APP_GENESYS_LIVECHAT_DEPLOYMENT_KEY, 
    GENESYS_LOG_LEVEL : platformEnv.REACT_APP_GENESYS_LOG_LEVEL, 
    GENESYS_ORG_GUID : platformEnv.REACT_APP_GENESYS_ORG_GUID, 
    GENESYS_ORG_ID : platformEnv.REACT_APP_GENESYS_ORG_ID, 
    GENESYS_ORG_NAME : platformEnv.REACT_APP_GENESYS_ORG_NAME, 
    GENESYS_COBROWSE_URL_WITH_DEPLOYMENT_KEY : platformEnv.REACT_APP_GENESYS_COBROWSE_URL_WITH_DEPLOYMENT_KEY, 
    LOGGER_API : platformEnv.REACT_APP_LOGGER_API, 

    API_SUB_DOMAIN : isPassiveRegion ? platformEnv.REACT_APP_PASSIVE_API_SUB_DOMAIN : platformEnv.REACT_APP_API_SUB_DOMAIN, 
    API_SUB_DOMAIN_MSR_MEM : platformEnv.REACT_APP_API_SUB_DOMAIN_MSR_MEM, 
    API_SUB_DOMAIN_MSR : platformEnv.REACT_APP_API_SUB_DOMAIN_MSR, 
    ACCOUNT_MAP_SUB_DOMAIN_API : isPassiveRegion ? platformEnv.REACT_APP_PASSIVE_ACCOUNT_MAP_SUB_DOMAIN_API : platformEnv.REACT_APP_ACCOUNT_MAP_SUB_DOMAIN_API, 

    API_KEY : platformEnv.REACT_APP_API_KEY, 
    ASCENSUS_API_KEY : platformEnv.REACT_APP_ASCENSUS_API_KEY, 
    METADATA_API_KEY : platformEnv.REACT_APP_METADATA_API_KEY, 
    CUSTOMER_PROFILE_API_KEY : platformEnv.REACT_APP_CUSTOMER_PROFILE_API_KEY, 
    TRANSACTION_API_KEY : platformEnv.REACT_APP_TRANSACTION_API_KEY, 
    ACCOUNT_OPTIONS_API_KEY : platformEnv.REACT_APP_ACCOUNT_OPTIONS_API_KEY, 
    MEMBER_PROFILE_API : platformEnv.REACT_APP_MEMBER_PROFILE_API, 
    STATEMENT_API_KEY : platformEnv.REACT_APP_STATEMENT_API_KEY, 
    MEMBER_PREFERENCES_KEY : platformEnv.REACT_APP_MEMBER_PREFERENCES_KEY, 
    SECURITY_API_KEY : platformEnv.REACT_APP_SECURITY_API_KEY, 
    LOGGER_API_KEY : platformEnv.REACT_APP_LOGGER_API_KEY, 
    CUSTOMER_MANAGEMENT_API_KEY : platformEnv.REACT_APP_CUSTOMER_MANAGEMENT_API_KEY, 
    ACCOUNT_MAP_API_KEY : platformEnv.REACT_APP_ACCOUNT_MAP_API_KEY, 
    VERIFY_CUSTOMER_API_KEY : platformEnv.REACT_APP_VERIFY_CUSTOMER_API_KEY, 
    LIVE_CHAT_API_KEY : platformEnv.REACT_APP_LIVE_CHAT_API_KEY, 
    DASHBOARD_PORTFOLIO_SUMMARY_API_KEY: platformEnv.REACT_APP_DASHBOARD_PORTFOLIO_SUMMARY_API_KEY, 

    TRANSACTION_LIMIT_OVERRIDE_PROCESS_URL: platformEnv.REACT_APP_TRANSACTION_LIMIT_OVERRIDE_PROCESS_URL, 
    TERMS_PDF_TRUSTED_CONTACT_PERSON_DISCLOSER: platformEnv.REACT_APP_TERMS_PDF_TRUSTED_CONTACT_PERSON_DISCLOSER, 
    TERMS_PDF_USA_PATRIOT_ACT: platformEnv.REACT_APP_TERMS_PDF_USA_PATRIOT_ACT, 

    MARKET_ACCOUNT_TYPE_DETAILS_DOCURL: platformEnv.REACT_APP_MARKET_ACCOUNT_TYPE_DETAILS_DOCURL, 
    NETX360PLUS_SSO: platformEnv.REACT_APP_NETX360PLUS_SSO, 

    REDIRECTURLSIGNIN_SSO: platformEnv.REACT_APP_REDIRECTURLSIGNIN_SSO,
    REDIRECTPASSIVEURLSIGNIN_SSO: platformEnv.REACT_APP_REDIRECTPASSIVEURLSIGNIN_SSO, 
    REDIRECTURLSIGNOUT_SSO: platformEnv.REACT_APP_REDIRECTURLSIGNOUT_SSO, 
    AWS_COGNITO_REGION_SSO : platformEnv.REACT_APP_AWS_COGNITO_REGION_SSO, 
    AWS_USER_POOLS_ID_SSO : platformEnv.REACT_APP_AWS_USER_POOLS_ID_SSO, 
    AWS_USER_POOLS_WEB_CLIENT_ID_SSO : platformEnv.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_SSO, 
    AWS_IDENTITY_POOL_ID_SSO: platformEnv.REACT_APP_AWS_IDENTITY_POOL_ID_SSO, 

    OAUTH_DOMAIN_SSO: platformEnv.REACT_APP_OAUTH_DOMAIN_SSO, 
    AWS_SCOPE_SSO : platformEnv.REACT_APP_AWS_SCOPE_SSO, 
    FEDERATED_SIGNIN_PROVIDER_SSO : platformEnv.REACT_APP_FEDERATED_SIGNIN_PROVIDER_SSO, 

    GOOGLE_CAPTCHA_TOGGLE: platformEnv.REACT_APP_GOOGLE_CAPTCHA_TOGGLE,

    FUND_FACT_HOST: platformEnv.REACT_APP_FUND_FACT_HOST,  

    GET_DOCUMENT_API_URL: platformEnv.REACT_APP_GET_DOCUMENT_API_URL

}


export default envConfig;





  // CREACT_APP_GOOGLE_CAPTCHA_TOGGLE = 'on'
    // CREACT_APP_FUND_FACT_HOST='https://qa.vcm.com'
    // CREACT_APP_NETX360PLUS_SSO = 'https://xat-www2.netx360.inautix.com/websso/authn?productcode=GEN_SIGNED_AUTH_REQ_360PLUS_VCM&RelayState=VCMID:'


