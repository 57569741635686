import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  storeESignature,
  resetWelcomPageFlag,
} from './TermsAndConitions.Action';
import TermsAndCondition from './TermsAndCondition';

const mapStateToProps = (state) => {
  const { user, accountInfo } = get(state, 'AccountRegistration', {});
  return {
    firstname: get(user, 'firstname', ''),
    lastname: get(user, 'lastname', ''),
    accountInfo,
  };
};

const mapDispatchToProps = {
  storeESign: storeESignature,
  resetFlag: resetWelcomPageFlag,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
