import React,{ Component }  from 'react';
import PropTypes from 'prop-types';
import VictoryMemberId from '../Input/VcmMemberId';
import UsaaMemberId from '../Input/UsaaMemberId';

const orSpanStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    fontWeight: '700',
}

const orText = 'Or';

class RecoverOnlineIdForm extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { changed, blurred, clicked, recoverOnlineIdFormState } = this.props;
        const { victoryId, usaaId } = recoverOnlineIdFormState.form;
        return (
            <>
            <UsaaMemberId usaaIdState={usaaId} changed={changed} blurred={blurred} clicked={clicked} readonly={!!victoryId.value} />
            <span style={orSpanStyle}>{orText}</span>
            <VictoryMemberId victoryIdState={victoryId} changed={changed} blurred={blurred} clicked={clicked} readonly={!!usaaId.value} />
            
            </>
        );
    }
    
};

RecoverOnlineIdForm.propTypes = {
    recoverOnlineIdFormState: PropTypes.shape({
        form: PropTypes.shape({victoryId: PropTypes.shape({
            value: PropTypes.string,
            errMsg: PropTypes.string,
            valid: PropTypes.bool,
            touched: PropTypes.bool,
            rules: PropTypes.shape({
                isNumeric: PropTypes.bool,
                maxLength: PropTypes.number,
                required: PropTypes.bool
            })
        }),
        usaaId: PropTypes.shape({
            value: PropTypes.string,
            errMsg: PropTypes.string,
            valid: PropTypes.bool,
            touched: PropTypes.bool,
            rules: PropTypes.shape({
                isNumeric: PropTypes.bool,
                maxLength: PropTypes.number,
                required: PropTypes.bool
            })
        })})
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func
}

RecoverOnlineIdForm.defaultProps = {
    recoverOnlineIdFormState: {
        form:{victoryId: {
            value: '',
            errMsg: null,
            valid: false,
            touched: false,
            rules: {
                isNumeric: true,
                maxLength: 9,
                required: false
            }
        },
        usaaId: {
            value: '',
            errMsg: null,
            valid: false,
            touched: false,
            rules: {
                isNumeric: true,
                maxLength: 9,
                required: false
            }
        }}
    },
    changed: () => { },
    blurred: () => { }
}

export default RecoverOnlineIdForm;