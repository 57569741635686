import React from 'react';
import PropTypes from 'prop-types';
import { NOTIFICATION_ERROR_MESSAGE } from '../../TransactionConstants';

const errorTxt = NOTIFICATION_ERROR_MESSAGE;
// const wrapStyle = { whiteSpace: 'pre-wrap' };

/**
 * ErrorBoundary class to catch and display errors.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // this.state = { error: null, errorInfo: null };
        this.state = { errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            // error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        // const { errorInfo, error } = this.state;
        const { errorInfo } = this.state;
        const { children } = this.props;

        if (errorInfo) {
            // Error path
            return (
                <div className="container">
                    <h2>{errorTxt}</h2>
                    {/* Commented following code so as to not to display the stack trace to users */}
                    {/* <details style={wrapStyle}>
                        {error && error.toString()}
                        <br aria-hidden="true" />
                        {errorInfo.componentStack}
                    </details> */}
                </div>
            );
        }
        // Normally, just render children
        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.instanceOf(Object),
};

ErrorBoundary.defaultProps = {
    children: () => {},
};

export default ErrorBoundary;
