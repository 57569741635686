import * as MasterRegActionConstants from '../ReduxConstants/MasterRegistrationActionConstants';
import makeApiRequest from '../Network/ApiInterface';

/**
 * This function formats the response as required to display in the UI with holdingGroupName
 * as the heading and array of accounts belonging to each holdingGroupName
 *
 * @param {Array} data
 */
export const formatAccountListResponseForUI = data => {
    // Gets the unique list of holding group names
    const formattedResponse = [];
    if (data.length) {
        const holdingGroups = [...new Set(data.map(account => account.holdingGroupName))].filter(
            item => item !== undefined,
        );
        holdingGroups.sort();
        if (holdingGroups.length === 0) {
            holdingGroups.push(MasterRegActionConstants.GENERAL_INVESTMENT_ACCOUNT);
        }

        let accounts = [];
        holdingGroups.forEach(groupName => {
            accounts =
                groupName === holdingGroups[0]
                    ? data.filter(item => !item.holdingGroupName || item.holdingGroupName === groupName)
                    : data.filter(item => item.holdingGroupName === groupName);
            if (accounts.length) {
                formattedResponse.push({
                    holdingGroupName: groupName,
                    accounts,
                });
            }
        });
    }
    return formattedResponse;
};


/**
 * Gets the account list information to be displayed in Account Selection page
 */
export const getAccountsList = (payload, UIFormat, transactionType) => {
    let transactionActionType;
    switch (transactionType) {
        case 'purchase':
            transactionActionType = MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST;
            break;
        case 'liquidate':
            transactionActionType = MasterRegActionConstants.GET_MASTER_REG_ACCOUNT_LIST_LIQUIDATE;
            break;
        case 'exchange':
            transactionActionType = MasterRegActionConstants.GET_EXCHANGE_MASTER_REGISTRATION_ACCOUNT_LIST;
            break;
        default:
            transactionActionType = MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST;
    }

    return  dispatch => {
        const env = '';
        dispatch({
            type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_START,
            payload: { isLoading: true, isError: false },
        });
        try {
            makeApiRequest(
                transactionActionType,
                payload,
                env,
                response => {
                    // returns the response with/without modification for UI
                    let responsePayload = [];
                    if (response.data.length) {
                        responsePayload = UIFormat ? formatAccountListResponseForUI(response.data) : response.data;
                    }
                    dispatch({
                        type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_SUCCESS,
                        payload: responsePayload,
                    });
                },
                error => {
                    if (error.response) {
                        dispatch({
                            type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE,
                            payload: error.response.data.errorMessage,
                        });
                    } else if (error.request) {
                        dispatch({
                            type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE,
                            payload: error.request,
                        });
                    } else {
                        dispatch({
                            type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE,
                            payload: error.message,
                        });
                    }
                },
            );
        } catch (error) {
            dispatch({
                type: MasterRegActionConstants.GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE,
                payload: error,
            });
        }
    };
};

export const clearMasterRegInfo = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING FUND PLAN
    dispatch({
        type: MasterRegActionConstants.CLEAR_MASTER_REG_INFO,
    });
};

export const setDisplayNotification = () => dispatch => {
  
};
