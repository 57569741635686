
import React, { Component } from 'react';
import styled from 'styled-components';
import { BottomNavWrapper, DownloadFile } from '../../../../../../common';
import ContactCenterWidget from 'modules/AccountDashboardModule/Common/ContactCenter/ContactCenterWidgetContainer';
import accountHolderCardData from 'modules/AccountDashboardModule/AccountHolderView/AccountHolderConstants';
import './RMDDownloadForm.css'

const { footerData } = accountHolderCardData;

const Section = styled.div`
    width: 50%;
    font-size: 1rem;
    padding-right: 40px;
`;

const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

class RMDDownloadForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {
        const { navigationObj } = this.props;
        return (
            <div>
                <SectionWrapper>
                    <Section>
                        <SectionTitle>You selected:</SectionTitle>
                    </Section>
                    <Section>
                        Since your beneficiary is a trust, you will need to use our Required Minimum Distribution (RMD) guide and form to set up Required Minimum Distributions from your Mutual Fund IRA.
                        <div className="downloadcontainer">
                            <ul className="info-list">
                                <li><div>1</div> <p> <DownloadFile nodeId={'99461'} text={'Download the RMD Form (form #99461)'} saveAs={'rmdForm.pdf'} downloadUrlRequiresAuth/> </p> </li>
                                <li><div>2</div> Fill it out. You will need <a target="_blank" rel="noopener noreferrer" href="https://get.adobe.com/uk/reader/otherversions/">Adobe Acrobat</a> to view the file.</li>
                                <li><div>3</div> Save the completed form to your device.</li>
                                <li><div>4</div> Use the <a href="/messageCenter">Message Center</a> to upload the completed form and send it to us. We will let you know when we receive it and keep you informed through the process</li>
                            </ul>
                        </div>
                        <ContactCenterWidget footerData={footerData} showCorner={true} />
                    </Section>
                </SectionWrapper>

                <BottomNavWrapper
                    {...navigationObj}
                    showBack={true}
                    disabled={false}
                    nextText="Done"
                // dataAnalyticId={RMD_ADD_PLAN_DETAILS_NAV_BUTTON_ID}
                />
            </div>
        );
    }
}

export default RMDDownloadForm;