import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import routeConstants from 'routeConstants';
// import LoadingOverlay from 'react-loading-overlay';

import '../Screens.css';
import RegisterEmailForm from '../../RegisterEmail/RegisterEmailForm';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper, WSpinner } from '../../../../common';
import { changedHandler, validityHandler } from '../../FormUtils';
import data from './Constants';
import styles from '../../UserManagementStyles';
import signUpPages from '../SignUpConstants';

const assignObj = (obj) => { return obj; };
const {stepperColStyle, stepperTextStyle} = styles.stepperStyle;

class RegisterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      errMsg: null,
      valid: false,
      label: 'E-mail',
      emailExists: false,
      loading: false,
      rules: {
        isEmail: true,
        maxLength: 30,
        required: true
      },
      ...props.registerState
    }
    this.onNext = this.onNext.bind(this);

    this.onChange = this.onChange.bind(this);

    this.onBlur = this.onBlur.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { loginState, history, manageLocalState, verifyEmail } = nextProps;
    const localData = { registerState: { ...state } };
    let updatedState = null;
    // console.log(loginState)
    if (loginState && loginState.verifyEmail ) {
      // console.log(loginState)
      if(loginState.verifyEmail.errorCode === 0){
        manageLocalState(localData);
        history.push('/personal-details')
      }else if(loginState.verifyEmail.errorCode === 5002 || loginState.verifyEmail.errorCode === 5001){
        updatedState = {...state, errMsg: 'E-mail address already exists'}
      }else {
        updatedState = {...state, errMsg: loginState.verifyEmail.result}
      }
      verifyEmail('');
    }
    return updatedState;
  }

  

  onNext() {
    const { verifyEmail } = this.props;
    const { value } = this.state;
    verifyEmail({emailAddress: value})
    // if (value !== 'venu@gmail.com') {
    //   if (valid ) {
    //     manageLocalState(localData);
    //     history.push('./personal-details');
    //   }
    // } else {
    //   this.setState((state) => ({ ...state, emailExists: true, errMsg: 'E-mail address already exists', valid: false }))
    // }

  }

  onChange(event) { changedHandler(event, this); }

  onBlur() { validityHandler(this); }

  render() {
    const { valid, cancelled, emailExists } = this.state;
    const {loginState} = this.props;

    return (
      <div className="container-fluid">
        <WSpinner loading={loginState.isLoading} />
        {/* <LoadingOverlay active={loginState.isLoading} spinner
        styles={assignObj({ wrapper: { overflow: 'hidden' } })} /> */}
        <div className="container">
          {/* <StatusBar length={3} current={1} /> */}
          <WStepper className="userWizard" currentPage={0} pages={signUpPages} stepperColStyle={stepperColStyle} stepperTextStyle={stepperTextStyle}/>
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={data.title} />
            </Col>
            <Col md lg xl>
              <RegisterEmailForm
                registerEmailFormState={this.state}
                changed={this.onChange}
                blurred={this.onBlur} />
              {emailExists ?
                <div>
                  <p style={data.style.para}>{data.member}<Link to='/sign-in'><button id="SignIn" type='button' style={data.style.button}>{data.signIn}</button></Link></p>
                  <p style={data.style.paraMiddle}>{data.or}</p>
                  <p style={data.style.paraEnd}>{data.use}<Link to='/recover-online-id'><button id="forgot" type='button' style={data.style.button}>{data.forgotOnlineId}</button></Link></p>
                </div> : null}
            </Col>
          </Row>
        </div>
        <CommonButtons backstyle={styles.hide} nextClick={this.onNext} disabled={!(valid)} blockstyle={assignObj({marginTop: 74})}/>
        {cancelled ? <Redirect to='/sign-in' /> : null}
      </div>
    );
  }
}

RegisterEmail.propTypes = {
  loginState: PropTypes.instanceOf(Object),
  verifyEmail: PropTypes.func,
  manageLocalState: PropTypes.instanceOf(Object),
  registerState: PropTypes.instanceOf(Object),
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
}

RegisterEmail.defaultProps = {
  loginState: {},
  verifyEmail: () => {},
  manageLocalState: {},
  registerState: {},
  history: []
}

export default RegisterEmail;