import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
    CommonButtons,
    TwoSectionPageTemplate,
    WSpinner,
    WMessageBar,
} from "common";
import { formatPhoneNumber } from "utils";
import { verifyEmailLink } from "shared/Actions/LoginActions";
import { manageLocalState } from "shared/Actions/LocalStateManagementActions";
// import routeConstants from 'routeConstants';
import PageDescription from "../FTLPersonalDetails/PageDescription";
import ReviewPersonalInfo from "../FTLPersonalDetails/ReviewPersonalInfo";
import {
    PAGES_SIGNUP,
    MSR_NUMBER,

    PAGE_HEADING,
    LINE1_DESCRIPTION,
    LINE2_DESCRIPTION,
    LINE3_DESCRIPTION,
    KEYS_VAL_SIGNUP
} from "../FTLPersonalDetails/constants";

// First Time Login - FTL
const ReviewPersonalDetails = (props) => {
    const loginStateData = useSelector(
        (state) => {
            const { loginData } = state;
            const { memberVerify } = loginData || {};
        return  memberVerify
        }
    );

    const loginState = useSelector(
        (state) => {
            const { loginData } = state;
        return  loginData
        }
    );
    const { isLoading } = loginState;
    const { location } = props;
    const { uuid } = queryString.parse(location.search);    
    const dispatch = useDispatch();
    const history = useHistory();
    const assignObj = obj => {return obj;};

    const [notification, setNotification] = React.useState(false);
    const [isErrorNotification, setIsErrorNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState(null);

    const { result } = loginStateData || {};
    const { 
        firstName = "",
        lastName = "",
        phoneNumber = "",
        emailAddress = "",
        middleName = "",
        prefix = "",
        suffix = ""
      } = result || {};
    
    const localData = {personalDetailsState: { uuid, emailAddress, firstName, lastName, prefix, suffix, middleName, phoneNumber}};

    React.useEffect(() => {
            dispatch(verifyEmailLink({uuid}));
    }, [uuid]);
    
    React.useEffect(() => {
        dispatch(manageLocalState({dataFlag:true}));
    }, []);

    
    React.useEffect(() => {
        if(loginStateData && loginStateData.errorCode === 0) {
            setNotification(true);
            setNotificationMsg({ message: assignObj(`Your email id has been successfully verified.`)});
            dispatch(manageLocalState(localData));
        } else if(loginStateData && loginStateData.errorCode === 2011){
            setIsErrorNotification(true);
            setNotification(true);
            // setNotificationMsg({ message: assignObj(`${loginStateData.result}`)});
            history.push({
                pathname: '/sign-in',
                message: {message: loginStateData.result},
                failure: true,
            })
        } else if(loginStateData && loginStateData.errorCode === 5003){
            setIsErrorNotification(true);
            setNotification(true);
            // setNotificationMsg({ message: assignObj(`${loginStateData.result.message}`)});
            history.push({
                pathname: '/sign-in',
                message: {message: loginStateData.result},
                failure: true,
            });
        } else if(loginStateData && loginStateData.errorCode === 5002){
            setIsErrorNotification(true);
            // setNotificationMsg({ message: assignObj(`${loginStateData.result.message}`)});
            history.push({
                pathname: '/sign-in',
                message: {message: 'This email address is already in use. If you have already created your online credentials, you can access your account by signing in.'}, /* Member already verified */
                failure: true,
            })
        } else if(loginStateData){
            setNotification(true);
            setNotificationMsg({ message: assignObj(`${loginStateData.result}`)});
        }
    }, [dispatch, loginStateData]);

    
    // console.log('loginStateData',loginStateData);

    // React.useEffect(() => {
    //     if (isError) {
    //         setNotification(true);
    //         setIsErrorNotification(true);
    //         setNotificationMsg({ message: null });
    //         clearExistingActions();
    //     }
    // }, [isError]);

    const pageDescription = (
        <PageDescription
            line1={LINE1_DESCRIPTION}
            line2={LINE2_DESCRIPTION}
            line3={LINE3_DESCRIPTION}
            msr={MSR_NUMBER}
        />
    );

    const userDetails = {
        prefix,
        firstName,
        middleName,
        lastName,
        suffix,
        phoneNumber,
        emailAddress,
    };

    const getFieldValue = React.useCallback(
        (key) => {
            let value = "-";
            const data = userDetails[key.toString()];
            if (data) {
                if (
                    key === "phoneNumber" 
                ) {
                    value = formatPhoneNumber(data, true);
                } else {
                    value = data;
                }
            }

            return value;
        },
        [userDetails]
    );

    const pageInformation = (
        <ReviewPersonalInfo
            personalInfoFields={KEYS_VAL_SIGNUP}
            getFieldValue={getFieldValue}
        />
    );
    const currentPageIndex = 0;

    const handleNext = React.useCallback(() => {
        // handle next action
        history.push('./termsAndConditions');
    }, []);

    const handleBack = React.useCallback(() => {
        // handle back action
        history.push('./sign-in');
    }, []);

    const onNotificationClose = React.useCallback(() => {
        setNotification(false);
    }, [setNotification]);
    return (
        <>
            {isLoading && (
                <WSpinner loading={isLoading} />
            )}
            {notification && (
                <WMessageBar
                    error={isErrorNotification}
                    onClose={onNotificationClose}
                    text={notificationMsg}
                />
            )}
            <TwoSectionPageTemplate
                headingTxt={PAGE_HEADING}
                pageDescription={pageDescription}
                pageDetails={PAGES_SIGNUP}
                pageInformation={pageInformation}
                currentPageIndex={currentPageIndex}
            />
            <CommonButtons 
                nextClick={handleNext} backClick={handleBack} dataAnalyticId="UMS_Signup_ReviewPersonalDetails"
                />
        </>
    );
};

export default ReviewPersonalDetails;

ReviewPersonalDetails.propTypes = {
    location: PropTypes.instanceOf(Object),
}
ReviewPersonalDetails.defaultProps = {
    location: {}
}
