import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import envConfig from 'env/EnvConfig';

const HOME = 'Home';
const PRODUCTS = 'Products';
const OURPRODUCTS = 'Our Products';
const ADVICE = 'Advice & Tools';
const AccountServices = 'Account Services';
const PROFILE = 'Profile';
const PortfolioPlannerPdf = "Portfolio Planner PDF's";
const DOCUMENTCENTER = 'Document Center';
const DOCUMENTMANAGER = 'Document Manager';
const FORMS = 'Forms';
const PREFERENCES = 'Preferences';
const CLIENTPROFILE = '[Client] Profile';
const BANKACCOUNTS = 'Bank Accounts';
const SETTINGS = 'Settings';
const LOGOUT = 'Sign Out';
const skipText = 'Skip To Main Content';
const Member = 'Member';
const Guest = 'Guest';
const FAQs = '/FAQs';
const HolderView = '/accountholder';
const GuestView = '/accountguestview';
const accountPreference = 'accountPreference';
const AccountServicesRoute = '/accountServices';
const DocumentCenterRoute = '/documentCenter';
const PortfolioPlannerPdfRoute = '/portfolioPlannerPdf';
const accessFormsRoute = '/forms';
const Close = 'Close';
const Search = 'Search:';
const recentSearch = 'Recent Searches';
const SITLinks = 'SIT Links';
const MSRLOOKUP = 'MSR Lookup';
const MSRLOOKUPRoute = '/msrlookup';

const styles = {
    nav: {
        listStyleType: 'none',
        display: 'flex',
        margin: '0',
    },
    navuser: {
        listStyleType: 'none',
        display: 'flex',
        flexDirection:'column',
        margin: '0',
        paddingLeft:0,
    },
    navLink: {
        color: '#FFFFFF',
        margin: '0 24px',
        font: '700 18px/22px yorkten-slab-normal',
        textDecoration: 'none',
    },
    navLinkEnd: {
        color: '#FFFFFF',
        margin: '0 0 0 24px',
        paddingRight: 0,
        font: '700 18px/22px yorkten-slab-normal',
        textDecoration: 'none',
    },
    logo: {
        position: 'absolute',
        left: 0,
    },
    iconStyles: {
        lineHeight: 'unset',
        color: 'white',
    },
    badgeStyles: {
        backgroundColor: 'white',
        color: 'rgb(72, 109, 144)',
        display: 'flex',
        height: '13px',
        width: '13px',
        borderRadius: '14em',
        marginTop: '13px',
        marginLeft: '32px',
        fontSize: '0.5em',
        alignItems: 'center',
        position: 'absolute',
    },
};

const StyledNavDropdown = styled(NavDropdown)`
    padding-top: 25px;
    > a {
        padding: 0;
        color: #ffffff;
        font: 500 14px/22px benton-sans;
        letter-spacing: 0.42px;
    }
    > a:hover {
        color: #ffffff;
    }
`;

const Border = styled.div`
    // border-bottom: 3px solid rgba(139, 193, 5, 1);
    // position: relative;
    // top: 5px;
    // margin-left: 48px;
    // width: 50px;
    position: absolute;
    border-bottom: 3px solid #FFFFFF;
    bottom: 2px;
    margin-left: 48px;
    left: 0;
    right: 0;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 15px !important;
        margin: 0 5px !important;
        margin-left: 0px;
        bottom:0;
    }

`;

const StyledLink = styled(Link)`
    color: #ffffff;
    margin-left: 48px;
    font: 700 18px/26px yorkten-slab-normal;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #ffffff;
    }
    @media only screen and (max-width: 992px) {
        margin: 0 12px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 15px !important;
        margin: 0 5px !important;
    }
`;

const StyledHeaderButton = styled.button`
    font: 600 16px/20px benton-sans, sans-serif;
    background-color:transparent;
    border:none;
    color:#FFF;
`

const StyledPopoverLink = styled(Link)`
    font: 600 16px/20px benton-sans, sans-serif;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
    &:hover {
        text-decoration: none;
        color: #004a98;
    }
`;

const StyledPopoverExLink = styled.a`
    font: 600 16px/20px benton-sans, sans-serif;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
    &:hover {
        text-decoration: none;
        color: #004a98;
    }
`;

const StyledPopoverProdHeaderLink = styled.a`
    font: 700 18px/18px yorkten-slab-normal, sans-serif;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
    &:hover {
        text-decoration: none;
        color: #004a98;
    }
`;

const StyledLinkEnd = styled(Link)`
    color: #ffffff;
    margin: 0 0 0 43px;
    font: 700 18px/26px yorkten-slab-normal;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #ffffff;
    }
    @media only screen and (max-width: 992px) {
        margin-left: 0 0 0 12px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 15px !important;
        margin: 0 0 0 5px;
    }
`;
const assignObj = obj => {
    return obj;
};

const logOutRoute = {
    pathname: '/sign-in',
    message: { message: 'You have been successfully signed out' },
};

const adviceToolsLinks = [
    {
        id: 0,
        link: `${envConfig.ENV_URL}/advice-tools/investor-learning-center`,
        linkText: 'Investor Learning Center',
        showArrow: true,
    },
    // { id: 1, link: '#', linkText: 'All About Mutual Funds', showArrow: false },
    // { id: 2, link: '#', linkText: 'All About ETFs', showArrow: false },
    // { id: 3, link: '#', linkText: 'All About Education Savings Plans', showArrow: false },
    // { id: 4, link: '#', linkText: 'Planning for Retirement', showArrow: false },
    {
        id: 1,
        link: `${envConfig.ENV_URL}/advice-tools/military-financial-readiness`,
        linkText: 'Military Financial Readiness',
        showArrow: true,
    },
    // { id: 6, link: '#', linkText: 'Investment Planning Tools', showArrow: false },
    // { id: 7, link: '#', linkText: 'Investment Goals', showArrow: false },
    { id: 2, link: FAQs, linkText: 'FAQs', showArrow: true },
];

const productsLinks = [
    /* { id: 0, link: `${envConfig.ENV_URL}/products`, linkText: 'Our Products', showArrow: true }, */
    {
        id: 1,
        link: `${envConfig.ENV_URL}/products/mutual-funds`,
        linkText: 'Mutual Funds',
        showArrow: false,
    },
    {
        id: 2,
        link: `${envConfig.ENV_URL}/products/usaa-529-college-savings-plan`,
        linkText: 'USAA 529 Educational Savings Plan',
        showArrow: false,
    },
    {
        id: 3,
        link: `${envConfig.ENV_URL}/products/victoryshares-etfs`,
        linkText: 'VictoryShares® ETFs',
        showArrow: false,
    },
    { id: 4, link: `${envConfig.ENV_URL}/products/retirement`, linkText: 'Retirement', showArrow: false },
];

const accountServicesLinks = [
    { id: 0, link: '#', linkText: 'Account Services', showArrow: true },
    { id: 1, link: '#', linkText: 'Systematic Investment Plan', showArrow: false },
    { id: 2, link: '#', linkText: 'Systematic Withdrawal Plan', showArrow: false },
    { id: 3, link: '#', linkText: 'Required Minimum Distribution', showArrow: false },
    { id: 4, link: '#', linkText: 'Dividend and Capital Gains', showArrow: false },
    { id: 5, link: '#', linkText: 'Manage Beneficiaries', showArrow: false },
    { id: 6, link: '#', linkText: 'Checkbook', showArrow: false },
];


export default {
    HOME,
    StyledPopoverProdHeaderLink,
    OURPRODUCTS,
    PRODUCTS,
    ADVICE,
    AccountServices,
    PROFILE,
    SETTINGS,
    LOGOUT,
    styles,
    StyledNavDropdown,
    StyledLink,
    StyledLinkEnd,
    assignObj,
    logOutRoute,
    skipText,
    adviceToolsLinks,
    productsLinks,
    accountServicesLinks,
    Member,
    Guest,
    DOCUMENTCENTER,
    PortfolioPlannerPdf,
    FORMS,
    PREFERENCES,
    CLIENTPROFILE,
    StyledPopoverLink,
    StyledPopoverExLink,
    FAQs,
    HolderView,
    GuestView,
    accountPreference,
    AccountServicesRoute,
    DocumentCenterRoute,
    PortfolioPlannerPdfRoute,
    accessFormsRoute,
    Close,
    Search,
    recentSearch,
    Border,
    SITLinks,
    BANKACCOUNTS,
    StyledHeaderButton,
    MSRLOOKUP,
    MSRLOOKUPRoute,
    DOCUMENTMANAGER
};
