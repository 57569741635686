const containerStyle = { marginTop: 20, marginBottom: 50 };


const tableHeadingStyle = {
    font: '700 16px/24px benton-sans',
    color: 'rgba(73, 73, 74, 1)',
    justifyContent: 'baseline',
    borderBottom: '2px solid rgba(139, 193, 5, 1)',
    padding: '15px 0 13px',
    alignItems: 'center',
};

const tableHeaderSpan = {
    display: 'block',
    font: '700 16px/24px benton-sans',
    color: 'rgba(73, 73, 74)',
    // font: ' 500 12px/20px benton-sans',
    letterSpacing: 0,
   float:'right',
    // color: 'rgba(86, 86, 90)',
    opacity: 1,
};
const tableStyle = { color: 'rgba(73, 73, 74, 1)', fontSize: 16, marginBottom: 0, fontFamily: 'benton-sans' };

const backButtonStyle = { marginBottom: 42, marginTop: 42 };

const colLeft = {
    width: '60%',
    padding: '18px 20px',
    paddingRight: 0,
    border: 'none',
    minHeight: 60,
    textAlign: 'left',
    font: '600 16px/22px benton-sans',
    letterSpacing: 0,
    color: 'rgba(73, 73, 74)',
    opacity: 1,
};

const colRight = {
    width: '40%',
    padding: '18px 20px',
    paddingLeft: 0,
    border: 'none',
    minHeight: 60,
    font: '500 14px/20px benton-sans',
};

const redIconSpan = { display: 'flex' };

const redIconStyle = { color: 'red', margin: '0' };

const tableCaption = { display: 'none' };

export default {
    containerStyle,
    tableHeadingStyle,
    tableStyle,
    backButtonStyle,
    colLeft,
    colRight,
    tableHeaderSpan,
    redIconSpan,
    redIconStyle,
    tableCaption,
};
