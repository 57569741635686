import styled from 'styled-components';

const Separator = styled.hr`
    border-top: 1px solid #d2d2d2;
    opacity: 1;
    margin-top: ${props => props.mT || 0}rem;
    margin-bottom: ${props => props.mB || 0}rem;
    margin-left: ${props => props.mL || 0}rem;
    margin-right: ${props => props.mR || 0}rem;
    list-style:none;
`;

export default Separator;