import { Alert, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import './WBlueBanner.css';

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #dcdcdc;
    opacity: 1;
    border-radius: inherit;
    min-height: 102px;
    max-height: 128px;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-left: 6px solid rgba(72, 109, 144, 1);
    @media (max-width: 767px) {
        max-height: none;
    }
`;

const StyledPara = styled.div`
    margin-bottom: 0;
    text-align: left;
    font-family: roboto-slab, benton-sans, sans-serif;
    font-weight: 700;
    font-size: 29px;
    line-height: 26px;
    letter-spacing: 0;
    color: #070707;
    opacity: 1;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
`;

const StyledSpan = styled.span`
    @media (max-width: 767px) {
        font-weight: 400;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    display: flex;
    align-items: center;
`;

const StyledCrossAfter = styled.div`
    width: 16px;
    transform: rotate(45deg);
    border: 1.5px solid rgb(73, 73, 74);
    position: absolute;
    left: 15px;
    top: 22px;
    border-radius: 3px;
    background-color: rgb(73, 73, 74);
    @media (max-width: 767px) {
        left: 8px;
        top: 15px;
    }
`;
const StyledCrossBefore = styled.div`
    width: 16px;
    height: 0;
    transform: rotate(-45deg);
    border: 1.5px solid rgb(73, 73, 74);
    position: absolute;
    left: 15px;
    top: 22px;
    border-radius: 2px;
    background-color: rgb(73, 73, 74);
    @media (max-width: 767px) {
        left: 8px;
        top: 15px;
    }
`;
const StyledCloseButton = styled.button`
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1.5px solid rgb(73, 73, 74);
    border-radius: 50%;
    position: relative;
    @media (max-width: 767px) {
        width: 35px;
        height: 35px;
        padding:0px;
    }
`;

const WBlueBanner = props => {
    const {  text } = props;
    const [close, setClose] = useState(false);

    function closeHandler() {
        setClose(true);
    }

    return close === true ? null : (
        <>
            <StyledAlert role="alertdialog" variant="danger" aria-live="polite">
                <StyledPara className="container">
                    <Row noGutters className="w-100 d-flex align-items-center mobile-banner-view">
                        <Col md={11} lg={11} xl={11} className="p-0 banner-alert-msg">
                            <Row noGutters>
                                <StyledSpan>{text.message}</StyledSpan>
                            </Row>
                        </Col>
                        <Col md={1} lg={1} xl={1} className="p-0 d-flex justify-content-end stop-code-alert-btn">
                            <StyledCloseButton
                                type="button"
                                aria-label="Close status message"
                                id="statusMsgClose"
                                onClick={closeHandler}
                            >
                                <div>
                                    <StyledCrossAfter />
                                    <StyledCrossBefore />
                                </div>
                            </StyledCloseButton>
                        </Col>
                    </Row>
                </StyledPara>
            </StyledAlert>
        </>
    );
};

WBlueBanner.propTypes = {
    onClose: PropTypes.func,
    text: PropTypes.string,
};

WBlueBanner.defaultProps = {
    onClose: () => {},
    text: '',
};

export default WBlueBanner;
