import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { WBreadCrumb, WSpinner, VCMSelect } from 'common';
import {
    formatDateToTwoDigit,
    isValidDate,
    encrypt,
    decrypt
} from "utils";
import {
    getKeyfromValue,
    getCountryMetaData,
    getMetadataValues,
    getFullName,
    getFieldValue,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from "modules/CustomerManagementModule/utils";
import routeConstants from 'routeConstants';
import { BottomNavBar, PageTitle, FieldValue, InstructionalPanel, PrefilledInput, RadioGroup, SSNtext } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/PersonalInformation/constants';

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;
export default class RelationshipPrimaryInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiReqBody: {},
        };
        this.countryData = [];
    }

    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
        addFormAbandonUnloadListner(true);
    }

    componentDidUpdate() {
        addFormFieldsListner();
        const { clearRelationActions, history, location, profileRelationshipData, analyzeClickEvent, linkResponseError } = this.props;
        const { isError, isSuccess, message } = profileRelationshipData;
        const { pathname } = location;
        if (isSuccess) {
            saveBtnLink('cms_basicinformation_manageRelationshipInfo_save', analyzeClickEvent);
            history.push(routeConstants.manageRelations,{from: pathname});
        }
        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink('cms_basicinformation_manageRelationshipInfo_save', analyzeClickEvent);
            linkResponseError(errorTxt);
            clearRelationActions();
        }
    }

    componentWillUnmount(){
        const { profileRelationshipData } = this.props;
        const { isSuccess } = profileRelationshipData;
        if(!isSuccess){
            const digitalData = getFormAbandData(true);
            const { fieldlasttouch } = digitalData;
            if (fieldlasttouch !== 'none') trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            marital_status: maritalStatus,
            prefix,
            suffix,
            relationship,
            gender,
            citizenship,
            country_2_lettercode: country,
            citizenship_proof: citizenshipProof,
        } = masterLookupStateData || {};
        const payLoad = [];
        if (!maritalStatus) payLoad.push('marital_status');
        if (!prefix) payLoad.push('prefix');
        if (!suffix) payLoad.push('suffix');
        if (!relationship) payLoad.push('relationship');
        if (!gender) payLoad.push('gender');
        if (!citizenship) payLoad.push('citizenship');
        if (!country) payLoad.push('country_2_lettercode');
        if(!citizenshipProof) payLoad.push('citizenship_proof');
        return payLoad;
    };

    handleChange = (fieldName) => (e) => {
        const { apiReqBody } = this.state;
        apiReqBody[fieldName.toString()] = e.list.find((item) => item.key === e.target.value).value;
        this.setState({
            apiReqBody
        });
    };
    
    handleSSNChange = (value) => {
        const { apiReqBody } = this.state;
        apiReqBody.ssnTin = value;
        this.setState({
          apiReqBody,
        });
    }

    handleRadioChange = (fieldName)=> (e) => {
        const { apiReqBody } = this.state;
        apiReqBody[fieldName.toString()] = e.target.value;
        this.setState({
            apiReqBody,
        });
    }

    submitPrimaryInfo = () => {
        const { profileRelationshipData, updateProfileRelationship } = this.props;
        const { familyRelationShipInfo = [], familyMemberIndex } = profileRelationshipData;
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex];
        const { relationshipId } = familyMemberInfo || {};
        const { apiReqBody={} } = this.state;
        const { ssnTin='' } = apiReqBody;
        (async () => {
            if(ssnTin){
                const ssnTinNew = await encrypt(ssnTin);
                apiReqBody.ssnTin = ssnTinNew;
            }
            const payLoad = {
                role : "Family",
                relationshipId,
                ...apiReqBody
            };
            updateProfileRelationship(payLoad, decrypt);
        })();
    }

    getMasterDataValues = (masterLookupStateData) => {
        const genderData = getMetadataValues(masterLookupStateData, 'gender');
        const mStatusData = getMetadataValues(masterLookupStateData, 'marital_status');
        const prefixData = getMetadataValues(masterLookupStateData, 'prefix');
        const suffixData = getMetadataValues(masterLookupStateData, 'suffix');
        const citizenshipData = getMetadataValues(masterLookupStateData, 'citizenship');
        const countryCodeData = getMetadataValues(masterLookupStateData, 'country_2_lettercode');
        const relationToCustomerData = getMetadataValues(masterLookupStateData, 'relationship');
        const citizenShipProofData = getMetadataValues(masterLookupStateData, 'citizenship_proof');
        
        return { genderData, mStatusData, prefixData, suffixData, citizenshipData, countryCodeData, relationToCustomerData, citizenShipProofData };
    };
    
    cancelPrimaryInfo = () => {
        const {  history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_manageRelationshipInfo_cancel`
        })
        history.push(routeConstants.manageRelations);
    }

    getChangedValue = (isChanged, newValue, actualValue) => {
        return isChanged ? newValue : actualValue;
    }
    
    isAnythingChanged = () => {
        const { profileRelationshipData } = this.props;
        let isChanged = false;
        const { apiReqBody={} } = this.state;
        const { familyRelationShipInfo = [], familyMemberIndex } = profileRelationshipData;
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex];
        const {
            citizenship = '',
            maritalStatus = '',
            prefix ='',
            suffix = '',
            countryOfCitizenship = '',
            familyMembersRelationshipToYou ='',            
            ssnTin = '',           
        } = familyMemberInfo || {};
        const {
            familyMembersRelationshipToYou: familyMembersRelationshipToYouChanged = '',
            prefix: prefixChanged = '',
            suffix: suffixChanged = '',
            maritalStatus: maritalStatusChanged = '',
            citizenship: citizenshipChanged = '',
            countryOfCitizenship: countryOfCitizenshipChanged = '',            
            ssnTin: ssnTinChanged = '',
        } = apiReqBody;
        
        
        const isFamilyMembersRelationshipToYouChanged = this.checkFieldChange("familyMembersRelationshipToYou", familyMembersRelationshipToYou, familyMembersRelationshipToYouChanged);
        const isSuffixChanged = this.checkFieldChange("suffix", suffix, suffixChanged);
        const isPrefixChanged = this.checkFieldChange("prefix", prefix, prefixChanged);
        const isMaritalStatusChanged = this.checkFieldChange("maritalStatus", maritalStatus, maritalStatusChanged);
        const isCitizenshipChanged = this.checkFieldChange("citizenship", citizenship, citizenshipChanged);
        const isSsnTinChanged = this.checkFieldChange("ssnTin", ssnTin, ssnTinChanged);
        const isCountryOfCitizenshipChanged = this.checkFieldChange("countryOfCitizenship", countryOfCitizenship, countryOfCitizenshipChanged);
        
        
        const validCitizenship = this.getChangedValue(isCitizenshipChanged,citizenshipChanged,citizenship);
        const validSSN = this.getChangedValue(isSsnTinChanged,ssnTinChanged,ssnTin);
        const validCountryOfCitizenship = this.getChangedValue(isCountryOfCitizenshipChanged,countryOfCitizenshipChanged,countryOfCitizenship);
           
                
        const validFieldsCitizenasUS =  (validCitizenship === 'U.S');
        const validFieldsCitizenasNonUs =  ((validCitizenship === 'Non U.S') && validCountryOfCitizenship);
        const checkValidSSN = (isSsnTinChanged && validSSN) ? (validSSN && (validSSN.length>=9)) : true;

        if((validFieldsCitizenasUS || validFieldsCitizenasNonUs) && checkValidSSN){
            isChanged = (isFamilyMembersRelationshipToYouChanged || isSuffixChanged || isPrefixChanged || isMaritalStatusChanged || 
        isCitizenshipChanged || isSsnTinChanged || isCountryOfCitizenshipChanged);
        }
        return isChanged;
    }
    
    checkFieldChange = (ownProperty, actualValue, changedValue) => {
        const { apiReqBody={} } = this.state;
        return Object.prototype.hasOwnProperty.call(apiReqBody, ownProperty) && actualValue !== changedValue;
    }

    render() {
        const { apiReqBody } = this.state;
        const { masterLookupStateData, profileRelationshipData } = this.props;
        const {
            mStatusData,
            prefixData,
            suffixData,
            citizenshipData,
            countryCodeData,
            relationToCustomerData,
        } = this.getMasterDataValues(masterLookupStateData);
        const { isLoading } = profileRelationshipData;
            const { familyRelationShipInfo = [], familyMemberIndex } = profileRelationshipData;
            const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex];
        const {
            firstName = '',
            lastName = '',
            citizenship = '',
            middleIntial = '',
            gender = '',
            maritalStatus = '',
            prefix ='',
            suffix = '',
            dateOfBirth = '',
            countryOfCitizenship = '',
            familyMembersRelationshipToYou ='',
            ssnTin = ''
        } = familyMemberInfo || {};
        

        const fullName = getFullName(firstName, middleIntial, lastName);        
        
        const tel = `tel:${CONST.MSR_NUMBER}`;

        const citizenshipValue = getFieldValue(apiReqBody, familyMemberInfo, 'citizenship'); 
        const maritalStatusValue = getFieldValue(apiReqBody, familyMemberInfo, 'maritalStatus');            

        const formattedDate = isValidDate(dateOfBirth) ? formatDateToTwoDigit(new Date(dateOfBirth)) : '-';
        
        const { countryOfCitizenship: countryOfCitizenshipAPIValue } = apiReqBody;
        const countryError = citizenshipValue === 'Non U.S' && !countryOfCitizenship && !countryOfCitizenshipAPIValue;
        const maritalStatusError = !maritalStatusValue;

        primaryBtnProps.onClick = this.submitPrimaryInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelPrimaryInfo;

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                    <Container>
                        <WBreadCrumb breadCrumbItems={CONST.RELATIONSHIP_BREADCRUMB_VALUE} activeCrumb={CONST.RELATIONSHIP_PAGE_NAME} />
                        <PageTitle title={CONST.RELATIONSHIP_PAGE_NAME} showMandatoryTxt />
                        <VCMSelect
							id="vcm-cms-relationship-family-relation-to-you"
                            label={CONST.FAMILY_RELATION}
                            name={CONST.FAMILY_RELATION}
							itemlist={relationToCustomerData}
							onChange={this.handleChange('familyMembersRelationshipToYou')}
                            value={getKeyfromValue(masterLookupStateData, 'relationship',familyMembersRelationshipToYou)}
							labelsm={3}
                            valuesm={5}
                            required
						/>
                        <FieldValue
                            as="p"
                            label={CONST.NAME}
                            value={
                                <PrefilledInput
                                    id="vcm-cms-relation-fullname"
                                    value={fullName}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <InstructionalPanel mb={1.5625}>
                            {CONST.RELATIONSHIP_NOTE_TEXT}
                            <a href={tel}>{CONST.MSR_NUMBER}</a>
                        </InstructionalPanel>
                        <VCMSelect
                            id='vcm-cms-relation-prefix'
                            label={CONST.PREFIX}
                            name={CONST.PREFIX}
                            itemlist={prefixData}
                            onChange={this.handleChange('prefix')}
                            value={getKeyfromValue(masterLookupStateData,'prefix',prefix)}
                            labelsm={3}
                            valuesm={5}
                            optional
                        />
                        <VCMSelect
                            id='vcm-cms-relation-suffix'
                            label={CONST.SUFFIX}
                            name={CONST.SUFFIX}
                            itemlist={suffixData}
                            onChange={this.handleChange('suffix')}
                            value={getKeyfromValue(masterLookupStateData,'suffix',suffix)}
                            labelsm={3}
                            valuesm={5}
                            optional
                        />
                        <FieldValue
                            as="p"
                            label={CONST.DOB}
                            value={
                                <PrefilledInput
                                    id="vcm-cms-relation-dob"
                                    value={formattedDate}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <FieldValue
                            as="p"
                            label={CONST.GENDER}
                            value={
                                <PrefilledInput
                                    id="vcm-cms-relation-gender"
                                    value={gender}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        <FieldValue
                            as="p"
                            id="vcm-cms-relation-ssn"
                            label={CONST.SSN}
                            value={
                            <SSNtext
                                labelledBy="vcm-cms-relation-ssn"
                                initialValue={ssnTin}
                                onBlur={this.handleSSNChange}
                                optional
                                data-name={CONST.SSN}
                            />
                            }
                            optional
                            labelsm={3}
                            valuesm={4}
                            mb={1.5625}
                        />
                        <VCMSelect
                            id='vcm-cms-relation-marital-status'
                            label={CONST.MARITAL_STATUS}
                            name={CONST.MARITAL_STATUS}
                            itemlist={mStatusData}
                            onChange={this.handleChange('maritalStatus')}
                            value={getKeyfromValue(masterLookupStateData,'marital_status',maritalStatus)}
                            labelsm={3}
                            valuesm={5}
                            errortext={maritalStatusError ? CONST.MARITAL_STATUS_ERROR : ''}
                            required
                        />
                        <FieldValue
                            as="p"
                            id="vcm-cms-relation-citizenship"
                            label={CONST.CITIZENSHIP}
                            value={
                                <RadioGroup
                                    labelledBy="citizenship"
                                    errorTxt={CONST.GENDER_ERROR}
                                    options={citizenshipData}
                                    onChange={this.handleRadioChange('citizenship')}
                                    initialValue={citizenship}
                                    data-name={CONST.CITIZENSHIP}
                                    aria-required
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb={1.5625}
                        />
                        {citizenshipValue === 'Non U.S' &&
							<>
								<VCMSelect
                                    id='vcm-cms-relation-country-of-citizenship'
                                    label={CONST.COUNTRY}
                                    name={CONST.COUNTRY}
                                    itemlist={getCountryMetaData(countryCodeData)}
                                    onChange={this.handleChange('countryOfCitizenship')}
                                    value={getKeyfromValue(masterLookupStateData, 'country_2_lettercode',countryOfCitizenship)}
                                    labelsm={3}
                                    valuesm={5}
                                    errortext={countryError ? CONST.COUNTRY_ERROR : ''}
                                    required
                                />																								
							</>
						}                        						
                    </Container>
                    <BottomNavBar
                        hideBack
                        showCancelBtn
                        primaryBtnText={CONST.SAVE}
                        primaryBtnProps={primaryBtnProps}
                        cancelBtnProps={cancelBtnProps}
                        hidePrimaryBtn = {hideFlag}
                    />
            </>
        );
    }
}

RelationshipPrimaryInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getCompositeLookUpData: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    profileRelationshipData: PropTypes.instanceOf(Object),
    clearRelationActions: PropTypes.func,
    updateProfileRelationship: PropTypes.func,
    analyzeClickEvent: PropTypes.func, 
    linkResponseError: PropTypes.func
};

RelationshipPrimaryInfoComponent.defaultProps = {
    masterLookupStateData: {},
    profileRelationshipData: {},
    getCompositeLookUpData: () => {},
    clearRelationActions: () => {},
    updateProfileRelationship: () => {},
    analyzeClickEvent: () => {}, 
    linkResponseError: () => {}
};
