import TransactionApiEvents from './TransactionApiEvents';
import AccountOptionsApiEvents from './AccountOptionsApiEvents';
import InterestedPartiesApiEvents from './InterestedPartiesApiEvents';
import CustomerManagementApiEvents from './CustomerManagementApiEvents';
import UserManagementApiEvents from './UserManagementApiEvents';
import MessageCenterApiEvents from './MessageCenterApiEvents';
import DashBoardApiEvents from './DashBoardApiEvents';
import DocumentCenterApiEvents from './DocumentCenterApiEvents';
import AccountOpeningApiEvents from './AccountOpeningApiEvents';
import AccountSettingsApiEvents from './AccountSettingsApiEvents';
import VendorApiEvents from './VendorApiEvents';
import ProfileAndPreferencesEvents from './ProfileAndPreferencesEvents';
import SWPEvents from './SWPEvents';
import MarketNAOApiEvents from './MarketNAOApiEvents'
const ApiEventRegistration = () => {
    TransactionApiEvents();
    AccountOptionsApiEvents();
    InterestedPartiesApiEvents();
    CustomerManagementApiEvents();
    UserManagementApiEvents();
    MessageCenterApiEvents();
    DashBoardApiEvents();
    DocumentCenterApiEvents();
    AccountOpeningApiEvents();
    AccountSettingsApiEvents();
    VendorApiEvents();
    ProfileAndPreferencesEvents();
    SWPEvents();
    MarketNAOApiEvents();
};

export default ApiEventRegistration;
