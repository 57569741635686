const lableStyle = {
    textAlign: 'center',
    font: 'Regular 40px/25px Benton Sans',
    letterSpacing: 0,
    color: '#4C7092',
    opacity: 1,
    fontSize: '-webkit-xxx-large',
    padding: '125px 400px',
    marginBottom: '-75px'
}

const buttnStyle = {
    width: '328px',
    height: '56px',
    background: '#4A9462 0% 0% no-repeat padding-box',
    borderRadius: '38px',
    opacity: 1
}

const mainScreenbutton = {
    minHeight: '100%',
    borderLeft: '1px dashed',
    position: 'absolute',
    right: '-10%',
    top: 0,
    zIndex: -1,
    width: '60%',
    transform: 'skewX(-16deg)',
    backgroundColor: '#fff'
}

const divStyle = { minHeight: '100%', position: 'absolute',left: 0, top: 0,zIndex: -2,backgroundColor:'#F9FBFC',width: '100%'}
const labStyle = {textAlign: 'center', marginBottom: '10px'}
const containerStyle ={  minHeight: ' 100vh', position: 'relative', zIndex: 2 }
const contStyle = { minHeight: ' 100vh', zIndex: 2 }

export default {
    lableStyle,
    buttnStyle,
    mainScreenbutton,
    divStyle,
    labStyle,
    containerStyle,
    contStyle
}