const getFieldInfo = (isEmptyField, field) => { 
  return {
    fieldfilled: isEmptyField ? '': `|${field.id}`, 
    fieldunfilled: isEmptyField ? `|${field.id}` : '',
  }
}

const getDigitalDataFormAband = () => {
  const formFields = document.querySelectorAll('.analytics-form-fields');
  const fieldLastClick = document.querySelectorAll('.fieldLastClick');

  const formAbandInfo = {fieldfilled:'',fieldunfilled:''};
  let fieldlasttouch='';

  fieldLastClick.forEach((item) => {
    fieldlasttouch = item.id;
  });

  formFields.forEach((field) => {
    let fieldInfo = {}
    if ( field.type === 'radio'){
      fieldInfo = getFieldInfo( field.checked !== true, field );
    }
    //CMS Toggle button implementation
    else if(field.hasAttribute('aria-pressed')){
      fieldInfo = getFieldInfo(field.getAttribute('aria-pressed') !== 'true', field );
    }
    //CMS Radio button implementation
    else if(field.hasAttribute('aria-checked')){
      fieldInfo = getFieldInfo(field.getAttribute('aria-checked') !== 'true', field );
    }
    else{
      fieldInfo = getFieldInfo( field.value.trim().length === 0, field );
    }
    formAbandInfo.fieldfilled += fieldInfo.fieldfilled;
    formAbandInfo.fieldunfilled += fieldInfo.fieldunfilled;
  })
  formAbandInfo.fieldfilled = (formAbandInfo.fieldfilled !== '') ? formAbandInfo.fieldfilled : '_none';
  formAbandInfo.fieldunfilled = (formAbandInfo.fieldunfilled !== '') ? formAbandInfo.fieldunfilled : '_none';
  fieldlasttouch = (fieldlasttouch !== '') ? fieldlasttouch : 'none';
 // const scrollPosition=getScrollPosition()

  return {
    formaband : {
      fieldfilled: formAbandInfo.fieldfilled.substr(1),
      fieldunfilled: formAbandInfo.fieldunfilled.substr(1),
      fieldlasttouch
    },
   // scrollPosition
  };
}

export default getDigitalDataFormAband;

export const analyticsFormAbandLastTouchRemove = () => {
  const formFields = document.querySelectorAll('.fieldLastClick');
  formFields.forEach((field) => {
      const element = document.getElementById(field.id);
      element.className = element.className.replace(/\b fieldLastClick\b/g, "");
  });
}
 
export const analyticsFormAbandLastTouch = (e) => {
  const findElement = document.getElementById(e.target.id);
  analyticsFormAbandLastTouchRemove();
  const arr = findElement.className.split(" ");
  if(arr.indexOf('fieldLastClick') === -1) { 
      findElement.className+=' fieldLastClick' 
  };
};