import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';
import styled from 'styled-components';
import AccordianIcon from './AccordionIcon';

const assignObj = obj => obj;           


const StyledSpan = styled.span`
    border: 2px solid ${props => props.c};
    background-color: ${props => props.r};
    cursor: pointer;
    display: flex;
    width: auto;
    right: 0;
    position: absolute;
    minwidth: 107;
    justifycontent: flex-end;
    padding: 0px;
    align-items: center;
    max-height: 30px;

    @media (max-width: 767px) {
        position: unset;
    }
`;
const NewStyledSpan = styled.span`
    background-color: ${props => props.r};
    cursor: pointer;
`;

class AccordionTitleContent extends Component {
    static Title = props => {
        const { accType } = props;
        // const style = {assignObj({ display: 'flex', flexDirection: 'row' })}
        return (
        //     <div 
        //   //  role="cell"
        //     >
        <span
        style={assignObj({
            color: '#000',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 700,
            cursor: 'pointer',
            display: 'flex',
        })}
    >
        <AccordianIcon role="presentation" tabIndex="-1" {...accType} />
        <span style={assignObj({ paddingLeft: 6, textAlign: 'left' })}> {accType.holdingGroup}</span>
    </span>
            // </div>
        );
    };

    static Balance = props => {
        const { accType } = props;
        const balance = `${currencyFormatter.format(accType.balance, { locale: 'en-US' })}`;
        return (
            <StyledSpan c={accType.c} r={accType.r}>
            <span
                    style={assignObj({
                        font: '700 16px/22px benton-sans,sans-serif',
                        textAlign: 'right',
                        padding: '6px 10px 5px 11px',
                        wordBreak: 'break-all',
                    })}
                >
               {balance}
           </span>
       </StyledSpan>
         
        );
    };

    static NewBalance = props => {
        const { accType, precentVal, forMob } = props;
        const balance = `${currencyFormatter.format(accType.balance, { locale: 'en-US' })}`;
        return (
            <div role="cell" className="d-flex flex-column text-right">
                <NewStyledSpan c={accType.c}  className="">
                    {accType.holdingGroup !== "Closed Accounts" &&  <span style={{fontSize:'20px'}} className="mobBalance">{balance}</span>}
                </NewStyledSpan>
                <span>{forMob ? precentVal:''}</span>
            </div>
        );
    };

    constructor(props) {
        super(props);
        this.state = {};
    }
    // const { accType } = props;
    // const balance = `${currencyFormatter.format(accType.balance, { locale: 'en-US' })}`;

    render() {
        return (
            <Row className="w-100" data-test="AccordionTitleContent" noGutters>
                <AccordionTitleContent.Title {...this.props} />
                <AccordionTitleContent.Balance {...this.props} />
            </Row>
        );
    }
}

AccordionTitleContent.propTypes = {
    accType: PropTypes.instanceOf(Object),
};

AccordionTitleContent.defaultProps = {
    accType: {},
};

export default AccordionTitleContent;
