import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './style.css';

const maskedDobInputStyles = {
    height: '50px',
    width: '100%',
    paddingLeft: '20px',
    backgroundColor: '#fafafa',
    border: '1px solid #d2d2d2',
};

const dateFormatText = 'MM/DD/YYYY';
const ERROR_CONST = 'Error:';
const optionalText = ' [Optional]';
class VictoryDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: null,
        };
       
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { errortext, datePickerModule, ariaLabelledBy, id, inputAriaDescBy } = nextProps;
        if (datePickerModule === 'fundSelectionBuy' && document.getElementById(id)) {
            document.getElementById(id).setAttribute('aria-describedby', inputAriaDescBy);
            document.getElementById(id).setAttribute('aria-labelledby', ariaLabelledBy);
        }
        if (prevState.errortext !== errortext) {
            return {
                ...prevState,
                errorMsg: prevState.errorMsg ? prevState.errorMsg : errortext,
            };
        }
        return {};
    }

    componentDidMount() {
        const { datePickerModule, ariaLabelledBy, ariaDescribedBy, id, ariaLabel } = this.props;
        if (
            datePickerModule === 'fundSelectionNAO' ||
            datePickerModule === 'dobNAO' ||
            datePickerModule === 'fundSelectionBuy'
        ) {
            document.getElementById(id).setAttribute('aria-describedby', ariaDescribedBy);
            document.getElementById(id).setAttribute('aria-labelledby', ariaLabelledBy);
        }
        if (ariaLabel) {
            document.getElementById(id).setAttribute('aria-label', ariaLabel);
        }
    }

    handleChange = date => {
        const { onDateChange, argument1, argument2, dobValidation, DOBErrorMsg } = this.props;
        let err = '';

        if (dobValidation) {
            if (!date || date >= moment()) {
                this.setState({ errorMsg: `Please enter valid date of birth` });
            } else {
                onDateChange(date, argument1, argument2);
                const sDate = moment(date, dateFormatText);
                const eDate = moment();
                const dateDiff = eDate.diff(sDate, 'years', true);
                if (dateDiff < 18) {
                    err = DOBErrorMsg;
                } else {
                    err = '';
                }
                this.setState({ errorMsg: err });
            }
        } else if (date) {
            onDateChange(date, argument1, argument2);
            err = '';
            this.setState({ errorMsg: err });
        } else {
            err = 'Please enter valid Date';
            this.setState({ errorMsg: err });
        }
    };

    render() {
        const {
            value,
            id,
            label,
            onDateBlur,
            argument1,
            argument2,
            disabled,
            colWidth,
            isFilterForTransaction,
            maskedDateOfBirth,
            isFilterForPADStartDate,
            diffForPADStartDate,
            isChildBeneficiary,
            ariaDescribedBy,
            minDate,
            maxDate,
            optional,
        } = this.props;
        const { errorMsg } = this.state;
        const dateClass = errorMsg ? 'analytics-form-fields errorInputField' : 'analytics-form-fields';
        let filterDateVal = date => {
            return moment() > date;
        };

        if (isChildBeneficiary) {
            filterDateVal = date => {
                return moment().subtract(1, 'days') > date;
            };
        }

        if (isFilterForTransaction) {
            filterDateVal = date => {
                return moment().add(2, 'days') < date;
            };
        }

        if (isFilterForPADStartDate) {
            filterDateVal = date => {
                return moment().add(diffForPADStartDate, 'days') < date;
            };
        }

        return (
            <>
                <div data-alignitems="center" data-flexdirection="row" className="datePickerRow  row no-gutters">
                    {label && (
                        <label htmlFor={id} className="font-weight-bold sc-cEvuZC kWHiIG formLabel form-label col-form-label col-sm-4">
                            {label}
                          {optional === true ? (
                        <span className="font-weight-normal d-inline" style={{color:'#56565a', fontSize:'12px'}}>&nbsp; {optionalText}</span>
                    ) : null}   
                    
                        </label>
                    )}
                    <div className={colWidth}>
                        {maskedDateOfBirth ? (
                            <div>
                                <input
                                    id={id}
                                    style={maskedDobInputStyles}
                                    value={value}
                                    onChange={this.handleChange}
                                    onBlur={Object.assign(date => onDateBlur(date, argument1, argument2))}
                                    filterDate={Object.assign(filterDateVal)}
                                    required
                                    readOnly={disabled}
                                    tabIndex={disabled ? -1 : 0}
                                />
                            </div>
                        ) : (
                            <DatePicker
                                id={id}
                                className={dateClass}
                                selected={value && new Date(value)}
                                onChange={this.handleChange}
                                onBlur={Object.assign(date => onDateBlur(date, argument1, argument2))}
                                filterDate={Object.assign(filterDateVal)}
                                preventOpenOnFocus
                                required
                                readOnly={disabled}
                                tabIndex={disabled ? -1 : 0}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        )}
                        <small id={ariaDescribedBy || 'StartDateHint'} className="font-weight-bold">
                            {dateFormatText}
                        </small>
                        <br />
                        {errorMsg && (
                            <small
                                className="sc-hjRWVT hefnRP errorMsgInputStyle analytics-error-field"
                                aria-live="assertive"
                                aria-atomic="true"
                                id="Date-error"
                                /* aria-describedby={errorMsg} */
                            >
                                <small className="sr-only">{ERROR_CONST}</small>
                                {errorMsg}
                            </small>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

VictoryDatePicker.propTypes = {
    onDateChange: PropTypes.func,
    onDateBlur: PropTypes.func,
    value: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    optional:PropTypes.bool,
    errortext: PropTypes.string,
    colWidth: PropTypes.string,
    argument2: PropTypes.string,
    argument1: PropTypes.string,
    dobValidation: PropTypes.bool,
    disabled: PropTypes.bool,
    DOBErrorMsg: PropTypes.string,
    isFilterForTransaction: PropTypes.bool,
    maskedDateOfBirth: PropTypes.bool,
    isFilterForPADStartDate: PropTypes.bool,
    isChildBeneficiary: PropTypes.bool,
    diffForPADStartDate: PropTypes.number,
    datePickerModule: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    ariaLabel: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    inputAriaDescBy: PropTypes.string,
};

VictoryDatePicker.defaultProps = {
    onDateChange: () => {},
    onDateBlur: () => {},
    value: null,
    id: '',
    label: null,
    optional: false,
    errortext: null,
    argument2: null,
    argument1: null,
    dobValidation: false,
    disabled: false,
    DOBErrorMsg: 'You must be 18 years or older to open an account',
    colWidth: 'col-sm-5',
    isFilterForTransaction: false,
    maskedDateOfBirth: false,
    isFilterForPADStartDate: false,
    isChildBeneficiary: false,
    diffForPADStartDate: 0,
    datePickerModule: null,
    ariaDescribedBy: null,
    ariaLabelledBy: null,
    ariaLabel: '',
    minDate: new Date('1900-01-01'),
    maxDate: new Date('2999-12-31'),
    inputAriaDescBy: null,
};

export default VictoryDatePicker;
