import React from 'react';
import PropTypes from 'prop-types';
import {ContainsSpecialChar} from '../userConstants';
import classes from './Input.module.css';

export const ConfirmPassword = (props) => {
    const { changed, blurred, confirmPasswordState, label, id } = props
    const data = {
        config: {
            id,
            type: 'password',
            label
        },
        change: (event) => changed(event, id),
        blur: () => blurred(id),
        keyPress:(e) => {
            const key = String.fromCharCode(
              !e.charCode ? e.which : e.charCode,
            );
            if (
              !ContainsSpecialChar(key)
              && !/[A-Za-z0-9]/.test(key)
            ) {
              e.preventDefault();
              return false;
            }
            return true;
        },
        onPaste: (e) => { e.preventDefault() }
    }

    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const errorId = `${data.config.id}_error`;

    if (confirmPasswordState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={confirmPasswordState.value}
        onChange={data.change}
        onBlur={data.blur}
        onKeyPress={data.keyPress}
        aria-required="true"
        aria-describedby={errorId}
        aria-invalid={!!confirmPasswordState.errMsg}
        onPaste={data.onPaste} />;

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor={data.config.id}>{data.config.label}</label>
            {inputElement}
            {confirmPasswordState.errMsg ? <p className={classes.errMsg} aria-live="polite" aria-atomic="true" role="alert" id={errorId}><span className="sr-only">Error:</span>{confirmPasswordState.errMsg}</p> : null}
        </div>
    );
};

ConfirmPassword.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    confirmPasswordState: PropTypes.instanceOf(Object),
    changed: PropTypes.func,
    blurred: PropTypes.func
}

ConfirmPassword.defaultProps = {
    label: 'Confirm Password',
    id: 'confirmPassword',
    confirmPasswordState: {},
    changed: PropTypes.func,
    blurred: PropTypes.func
}

export default ConfirmPassword;