import { BottomNavSection } from '../BottomNavWrapper';
import EditIcon from 'assets/edit.png';
import { MaskText } from '../../common';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { STEP_KEY } from '../../utils/AdobeAnalytics/Constants';
import styled from 'styled-components';
import StyledButton from '../StyledComponents';
import { TRADE_TYPE_TEXT, SELL_TEXT } from '../Constants';
import WCard from '../WCard/WCard';
import WButton from '../WButton/WButton';
import { get, isEmpty } from 'lodash';
import MaskAccountNumber from 'common/MaskAccountNumber/MaskAccountNumber';
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';


const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};


const fontColor = 'rgb(73, 73, 74)';
const VerifySection = styled.div``;

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 767px) {
        display:block;
    }
`;

const TitleTxt = styled.h3`
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-size: 22px;
    color: #486d90;
    text-align:left;
`;

const EditSection = styled(StyledButton)`
    display: flex;
    align-self: center;
`;

const EditDiv = styled.div`
    cursor: pointer;
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-left: 0.313rem;
`;
EditDiv.displayName = 'EditDiv';

const SectionBody = styled(FlexColumnDiv)`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-bottom: 1rem;
`;

const Field = styled.div`
    flex: 0 0 15em;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 767px) {
        margin-bottom: .5rem;
    }
`;
const Value = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 1.5rem;
`;
const SectionTitle = styled(FlexRowDiv)`
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767px) {
        /* display:block; */
        /* overflow: hidden;
        clear: both; */
        display: flex;
        flex-direction: row;
    }
`;

const Heading = styled.h4`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 37px;
    letter-spacing: 0px;
    color: #486d90;
    opacity: 1;
    margin-bottom: 1.25rem;
`;

const Separator = styled.hr`
    border: 1px solid #d2d2d2;
    opacity: 1;
    margin-top: 0.125rem;
`;
const EditImage = styled.img`
    margin-left: auto;
`;

const NoteTxt = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    margin-bottom: 2.5rem;
    padding: 1.625rem 1.875rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

const TradingWindowWarning = styled(Row)`
    margin: 20px 0;
`;

const displayNoneStyle = { display: 'none' };

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: fontColor,
    textAlign: 'left',
    margin: 0,
};

const cardBodyStyle = {
    paddingBottom: 0,
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: fontColor,
    textAlign: 'left',
    marginBottom: 11,
};


const VerifyAIP = props => {
    const {
        navigationObj,
        verifyDetails,
        scrollToSection,
        tradingWindowData,
        handleSubmit,
        setCancelLiquidation,
        transactionType,
        pageName,
        navBarAnalyticsID,
        isProportinateSelected,
        liquidationData={},
        flowName=''
    } = props;
    const [disableNextBtn, setDisableNextBtn] = useState(false);

    function handleSubmitClick(e) {
       

        if (e.detail === 1) {
            handleSubmit(e);
        }
    }

    navigationObj.onPrimaryClick = handleSubmitClick;

    function simulateEditButtonClick(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById('editButton').click();
        }
    }

    const saveToLocal = () => {
        const { isEnabledPrivacy, isEnabledTerms, isEnabledAgreement, signedName, currentDate, isTermsChkDisabled, isAgreeChkDisabled, isPrivacyChkDisabled, isValidESign } = this.state;
        const { personalDetailsState, manageLocalState } = this.props;
        const localData = {
            personalDetailsState: {
                ...personalDetailsState,
                signedName,
                isEnabledTerms,
                isEnabledAgreement,
                isEnabledPrivacy,
                isTermsChkDisabled,
                isAgreeChkDisabled,
                isPrivacyChkDisabled,
                currentDate,
                isValidESign,
            }
        };
        manageLocalState(localData);
    }



    useEffect(() => {
        if(flowName == 'sell')
        {
            props.resetLiquidationSubmit()
        }
    }, [])
    
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        sessionStorage.setItem(STEP_KEY, pageName || 'Review & Confirm');
        if (transactionType === 'liquidate') {
            document.title = 'Review & Confirm : Sell | Victory Capital';
        }
        document.getElementById('skipToMain')?.focus();

        // Simulate edit button click event on 'enter' key
        const editButton = document.getElementById('editButton');
        editButton?.addEventListener('keyup', simulateEditButtonClick);
    }, [pageName]);

    const proportianteSelectedFundsList = liquidationData?.page1?.proportianteSelectedFundsList;
    return (
        <div>
            <div className="container">
                {transactionType === 'liquidate' && (
                    <VerifySection>
                        <SectionTitle>
                            <TitleTxt>{TRADE_TYPE_TEXT}</TitleTxt>
                        </SectionTitle>
                        <Separator aria-hidden="true" />
                        <SectionBody>
                            <FlexRowDiv key="tradeType-liq">
                                <Field>{TRADE_TYPE_TEXT}</Field>
                                <Value>{SELL_TEXT}</Value>
                            </FlexRowDiv>
                        </SectionBody>
                    </VerifySection>
                )}

                {verifyDetails.map(item => {
                    return (
                        <VerifySection key={item.titleTxt + item.pageIndex}>
                            <SectionTitle>
                                <TitleTxt className="recurringTransferSectionHdng">{item.titleTxt != undefined ? item.titleTxt : 'Account Selection'}</TitleTxt>
                                {item.titleTxt != undefined && item.titleTxt != 'Account Selection' ? (
                                    <EditSection
                                        onClick={scrollToSection(
                                            item.pageIndex, item.titleTxt)}
                                        aria-label={`Edit ${item.titleTxt}`}
                                        tabIndex="0"
                                        id="editButton"
                                        className="recurringTransferHdngEdit"
                                    >
                                        <EditImage src={EditIcon} alt="" />
                                        <EditDiv>{item.editTxt}</EditDiv>
                                    </EditSection>
                                ) : ('')
                                }

                                {(props.flow !=undefined && props.flow == "addFlow" && item.titleTxt == 'Account Selection') ?  
                                (
                                    <EditSection
                                    onClick={scrollToSection(
                                        item.pageIndex, item.titleTxt)}
                                    aria-label={`Edit ${item.titleTxt}`}
                                    tabIndex="0"
                                    id="editButton"
                                    className="recurringTransferHdngEdit"
                                >
                                    <EditImage src={EditIcon} alt="" />
                                    <EditDiv>{item.editTxt}</EditDiv>
                                </EditSection>
                                ) : ("") 
                                }
                            </SectionTitle>
                            <Separator aria-hidden="true" />
                            <SectionBody>
                                {isProportinateSelected == false && item.heading && <Heading>{item.heading}</Heading>}
                                {item.titleTxt === 'Account Selection' || !item.titleTxt
                                    ? item.data.map((itemData, index) => (
                                        <FlexRowDiv key={itemData.field + index.toString()}>
                                            <Field>{itemData.field}</Field>
                                            <Value>
                                                {itemData.field === 'Account Number' ? (
                                                     <>{itemData.value}</>
                                                ) : (
                                                    itemData.formattedValue || itemData.value
                                                )}
                                            </Value>
                                        </FlexRowDiv>
                                    ))
                                    : <>
                                        {(isProportinateSelected && item.titleTxt == "Fund Selection") ? <>
                                        {proportianteSelectedFundsList && proportianteSelectedFundsList.map((dataInfo,index)=>(
                                                    <>
                                                    {item.heading && <Heading>{dataInfo.fundName}</Heading>}
                                                    <FlexRowDiv>
                                                    <Field>Fund Account Number</Field>
                                                    <Value>{dataInfo.fundAccountNumber}</Value>
                                                    </FlexRowDiv>
                                                    <FlexRowDiv>
                                                    <Field>Current Value</Field>
                                                    <Value>{dataInfo.currentAccountBalance}</Value>
                                                    </FlexRowDiv>
                                                    <FlexRowDiv>
                                                    <Field>Selling Amount</Field>
                                                    <Value>{dataInfo.sellingamt}</Value>
                                                    </FlexRowDiv>
                                                    <FlexRowDiv>
                                                    {dataInfo?.fedTaxAmount && 
                                                        <>
                                                        <Field>Federal Tax Amount</Field>
                                                        <Value>{dataInfo?.fedTaxAmount}</Value>
                                                        </>
                                                    }                                            
                                                    </FlexRowDiv>
                                                    <FlexRowDiv>
                                                    {dataInfo?.stateTaxAmount && 
                                                        <>
                                                        <Field>State Tax Amount</Field>
                                                        <Value>{dataInfo?.stateTaxAmount}</Value>
                                                        </>
                                                    }
                                                    </FlexRowDiv>
                                                </>

                                                ))}
                                         </> : <>
                                         {item.data.map((data, index) => ( 
                                            <FlexRowDiv key={data.field + index.toString()}>
                                            <Field>
                                                {data.field}
                                                {data.optionalLabel}
                                            </Field>
                                            <Value>{data.formattedValue || data.value}</Value>
                                        </FlexRowDiv>

                                         ))}
                                         </>}
                                    </>
                                    
                                    
                                    }

                                {/* {item.data.map((data, index) => (
                                <FlexRowDiv key={data.field + index.toString()}>
                                    <Field>{data.field}</Field>
                                    <Value>{data.formattedValue || data.value}</Value>
                                </FlexRowDiv>
                            ))} */}
                            </SectionBody>
                            {item.note && <NoteTxt>{item.note}</NoteTxt>}
                        </VerifySection>
                    );
                })}
                {!tradingWindowData.canTrade && (
                    <TradingWindowWarning>
                        <Col xs={12}>
                            <WCard
                                buttonstyle={displayNoneStyle}
                                cardtitletext={
                                    tradingWindowData.tradingClosureMsg
                                        ? tradingWindowData.tradingClosureMsg.message
                                        : ''
                                }
                                cardtextstyle={cardTextStyle}
                                cardbodystyle={cardBodyStyle}
                                cardtitlestyle={cardTitleStyle}
                                linkcard="true"
                                leftcornercard={cardBorderStyle}
                                alt=""
                                src=""
                                selected
                                className="rwdCard"
                            />
                        </Col>
                    </TradingWindowWarning>
                )}
            </div>
           
            <BottomNavSection
                {...navigationObj}
                showBack
                iscancel
                arialabelback={`${transactionType === 'liquidate'
                        ? 'Back to Deposit Account'
                        : navBarAnalyticsID === 'accountservices_aip_add_verify'
                            ? 'Back to Schedule'
                            : 'Back to edit frequency'
                    }`}
                dataAnalyticId={navBarAnalyticsID}
                disabled={disableNextBtn}
                cancelClickHandler={transactionType === 'liquidate' && setCancelLiquidation}
            />
        </div>
    );
};

VerifyAIP.propTypes = {
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    verifyDetails: PropTypes.instanceOf(Object),
    scrollToSection: PropTypes.func,
    tradeBox: PropTypes.bool,
    handleSubmit: PropTypes.func,
    tradingWindowData: PropTypes.instanceOf(Object),
    setCancelLiquidation: PropTypes.func,
    transactionType: PropTypes.string,
    pageName: PropTypes.string,
    dataAnalyticId: PropTypes.string,
    navBarAnalyticsID: PropTypes.string,
};
VerifyAIP.defaultProps = {
    verifyDetails: [],
    scrollToSection: () => { },
    navigationObj: {
        onCancel: () => { },
        onPrimaryClick: () => { },
        onSecondaryClick: () => { },
        portalRoot: null,
    },
    tradeBox: false,
    handleSubmit: () => { },
    tradingWindowData: {},
    setCancelLiquidation: () => { },
    transactionType: '',
    pageName: '',
    dataAnalyticId: '',
    navBarAnalyticsID: '',
};

export default VerifyAIP;
