const accTypeDetails = {
    key: 'ira',
    value: [{
        key: 'ind',
        value: 'For Individuals',
        subtypes: [{
                key: 'trad',
                value: 'Traditional IRA',
                description: 'This is sample content paragraph',
            },
            {
                key: 'roth',
                value: 'Roth IRA',
                description: 'This is sample content paragraph',
            },
            {
                key: 'rollover',
                value: 'Rollover IRA',
                description: 'This is sample content paragraph',
            },
        ],
    }, ],
};

const wrapperStyles = {
    position: 'relative',
};

const yorktenFontFamily = 'yorkten-slab-normal';

const accSubTypeHeading = {
    margin: '0 0 30px',
    textAlign: 'left',
    fontWeight: '800',
    fontSize: '18px',
    color: 'rgba(73, 73, 74, 1)',
    fontFamily: yorktenFontFamily,
};

const pageHeaderStyle = {
    paddingTop: 40,
    fontSize: 30,
    color: '#4F799F',
    fontWeight: '800',
    paddingBottom: 10,
    borderBottom: '2px solid #8CBF26',
    fontFamily: yorktenFontFamily,
    float: 'left',
    marginBottom: 40,
};

const accSubTypeList = {
    listStyleType: 'none',
    paddingLeft: '0',
    color: 'rgba(86, 86, 90, 1)',
    marginBottom: 0,
};
const accTypeColStyle = { textAlign: 'left', marginBottom: 20 };
const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 'normal',
    marginTop: 8,
};
const personalizeHeading = 'Personalize';
const nickName = {
    label: 'Nickname Your Account (optional)',
    placeholder: 'Nick name',
    className: 'optional',
    id: 'nickName',
};
const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150 };
const radiostyles = { color: 'rgba(73, 73, 74, 1)', fontSize: 16, marginBottom: 25 };

const pageSubheaderStyle = {
    paddingTop: 40,
    fontSize: 22,
    fontFamily: yorktenFontFamily,
    fontWeight: '800',
    color: '#486D90',
    paddingBottom: 15,
    borderBottom: '1px solid #CECECE',
    marginBottom: 30,
};

const radiotext = {
    fontWeight: '600',
};

const radioError = 'Please select an account type to proceed';
const errorStyles = { color: 'red', paddingTop: 10, paddingLeft: 15 };
const radiobtnstyles = { top: 0 };
const timeEsitmateText = type => {
    switch (type) {
        // case 'rollover':
        case '403b':
        case 'simple':
            return '';
        default:
            return 'Average completion time 5-10 minutes';
    }
};
const timeEsitmateTextLT767 = type => {
    // all types.
    /*
    let _type = type.toLowerCase();
    switch (_type) {
        case '403b':
            return 'Average completion time 5-10 mins';
        default:
            return ''; // should I return null?
    }
     */

    return 'Average completion time 5-10 mins';

};



const detailsInfotext = 'Please make sure you have the details below before proceeding.';
const detailsNeededlist = [
    { key: '1', value: 'Personal information for any account owners' },
    { key: '2', value: 'Verified banking information (Acct and Routing Numbers)' },
];

const customizedStatments = {
    indyAcct: `Your personal information including SSN, employment, and financial information`,
    jointAcct: `Personal information for any account owners`,
    ugmaUtma529: `Personal information for yourself and any beneficiaries`,
    iRAs: `Personal information for yourself and any beneficiaries`,
    specAccts: `Personal information for any account owners`,
}

const fontawesomeiconcard = {
    height: 20,
    width: 20,
    color: 'rgba(139, 193, 5, 1)',
    margin: 0,
};

const compositePayload = [
    'brkg_annual_income',
    'brkg_net_worth',
    'tax_filling_status',
    'brkg_employment_status',
    'industry',
    'prim_src_income',
    'country_3_lettercode',
    'address_type',
];

export const breadCrumbNames = {
    BREAD_CRUMB_1_NAME: 'home',
    BREAD_CRUMB_2_NAME: 'Open/Transfer Account',
};
export const Note_Text = 'Note: Victory Capital collects information in accordance with the'
export default {
    wrapperStyles,
    accSubTypeHeading,
    accSubTypeList,
    accTypeDetails,
    stepperStyle,
    radiodescstyles,
    accTypeColStyle,
    radiostyles,
    personalizeHeading,
    nickName,
    pageHeaderStyle,
    pageSubheaderStyle,
    radiobtnstyles,
    radiotext,
    errorStyles,
    timeEsitmateText,
    timeEsitmateTextLT767,
    detailsInfotext,
    detailsNeededlist,
    radioError,
    fontawesomeiconcard,
    compositePayload,
    customizedStatments,
};