import makeApiRequest from '../Network/ApiInterface';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

export const getStateCity = payload => dispatch => {
    const env = 'GETSTATECITY';
    dispatch({
        type: ActionTypes.GET_STATECITY_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_STATECITY,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_STATECITY_SUCCESS,
                    [ActionTypes.GET_STATECITY]: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_STATECITY_ERROR,
                    [ActionTypes.GET_STATECITY]: response.data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.GET_STATECITY_ERROR,
                [ActionTypes.GET_STATECITY]: data,
            });
        },
    );
};

export const getAddressFormat = payload => dispatch => {
    const env = 'GETADDRESSFORMAT';
    dispatch({
        type: ActionTypes.GET_ADDRESSFORMAT_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_ADDRESSFORMAT,
        payload,
        env,
        response => {
            const { data } = response;
            if (data) {
                if (data.zip4) data.zip = `${data.zip}-${data.zip4}`;
                dispatch({
                    type: ActionTypes.GET_ADDRESSFORMAT_SUCCESS,
                    [ActionTypes.GET_ADDRESSFORMAT]: data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_ADDRESSFORMAT_ERROR,
                    [ActionTypes.GET_ADDRESSFORMAT]: response.data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.GET_ADDRESSFORMAT_ERROR,
                [ActionTypes.GET_ADDRESSFORMAT]: { description: ActionTypes.UNABLE_TO_VERIFY_ADDRESS },
                response: data,
            });
        },
    );
};

export const setAddress = payload => dispatch => {
    dispatch({
        type: ActionTypes.SET_ADDRESS_DATA,
        addressData: payload,
    });
};

export const setAddressLine1 = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_ADDRESS_LINE1,
        data
    });
};

export const setAddressLine2 = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_ADDRESS_LINE2,
        data
    });
};

export const setZipCode = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_ZIPCODE,
        data
    });
};

export const setAddressLine2Error = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_ADDRESS_LINE2_ERROR,
        data
    });
};

export const setZipCodeError = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_ZIPCODE_ERROR,
        data
    });
};

export const setCallAddressAPI = () => dispatch => {
    dispatch({
        type: ActionTypes.SET_CALL_ADDRESS_API,
    });
};

export const resetAddressInfo = () => dispatch => {
    dispatch({
        type: ActionTypes.RESET_ADDRESS_INFO,
    });
};

export const setCityValue = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_CITY,
        data
    });
};

export const setStateValue = (data) => dispatch => {
    dispatch({
        type: ActionTypes.SET_STATE,
        data
    });
};

export const formatAddrByUSPSService = payload => dispatch => {
    const env = '';
    dispatch({
        type: ActionTypes.GET_ADDRESSFORMAT_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_ADDRESSFORMAT,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_FORMATTED_ADDRESS_SUCCESS,
                    response: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_FORMATTED_ADDRESS_ERROR,
                    response: response.data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.GET_FORMATTED_ADDRESS_ERROR,
                response: data,
            });
        },
    );
};

export const clearActions = () => (dispatch) => {
    dispatch({
        type: ActionTypes.ADDRESS_REMOVE_EXISTING_VALUES,
    });
};
