import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { localStateManagementActions, msrActions } from '../../shared/Actions';
import Msr from './Msr';

const mapStateToProps = (state) => ({
    localStateData: state.localStateManagementReducerData,
    redirectFlag : state.msrFlowDetails,    
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...msrActions,     
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Msr));
