import { get } from "lodash";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
  existingPlanList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  isSkip: false,
  isDelete:false,
  isAdd:false,
  isUpdate:false,
  error: {}
};

function initialReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS:
    case ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS:
    case ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN:
    case ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS:
    case ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        existingPlanList:action.response
      };
    }
    case ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_RESET: {
      return {
        ...initialState
      };
    }

    case ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN_SUCCESS:
      {
        return {
          ...state,
          isLoading: true,
          isSkip: true
        };
      }
    case ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isAdd:true,
          responseMessage: '', // action.response
        };
      }
    case ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isUpdate:true,
        };
      }
    case ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isDelete:true,
      };
    }
    case ActionTypes.RESET_DELETE_FLAG: {
      return {
        ...state,
        isDelete: false,
      };
    }
    case ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_FAILURE:
    case ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_FAILURE:
    case ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN_FAILURE:
    case ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_FAILURE:
    case ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS_FAILURE: {
      return {
        ...state,
        error: get(action.response, 'data.response', action.response),
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    case ActionTypes.DISMISS_SYS_WITHDRAWAL_ERROR: {
      return {
        ...state,
        error: {},
        isLoading: false,
        isError: false
      };
    }
    default:
      return state;
  }
}

export default initialReducer;
