import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {loginActions, localStateManagementActions, analytics} from 'shared/Actions';

import RecoverOnlineId from './RecoverOnlineId';

export const mapStateToProps = (state) => ({
    loginState: state.loginData,
    otpDetails: state.localStateManagementReducerData.otpDetails,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...loginActions,
    ...analytics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecoverOnlineId));
