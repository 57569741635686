import React, {Component} from 'react';
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import styled from 'styled-components';
import {
    WBreadCrumb,
    VCMDatePicker, 
    WSpinner,
} from "common";
import { formatDate, isValidDate, showOnlyLastFourChar } from 'utils';
import routeConstants from 'routeConstants';
import { AccountDetailsCard, BottomNavBar, PageTitle, Address, InstructionalPanel } from 'modules/CustomerManagementModule/components';
import {
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';
import CONST from "../constants";

const StyledDiv = styled.div`
    margin-top: 1.875rem;
`;

const primaryBtnProps = {};
const cancelBtnProps = {};

class AddSeasonalInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonalStartDate: '',
            seasonalEndDate: '',
            showAccNumber: false,
        };
    }
    
    componentDidMount() {
        const { location, isEdit, setAddress } = this.props;
        const { state:locationState } = location;
        const {
            addressInformation,
            addrIndex
        } = locationState || {};
        
        if(isEdit){
            const { 
                addressLine1,
                addressLine2,
                city,
                state:stateName,
                zip,
                dateValidFrom = '',
                dateValidTo = '',
            } = addressInformation[+addrIndex] || {};
            // From API null values are possible so one level of checking is done.
            const addressLine1Value = addressLine1 || '';
            const addressLine2Value = addressLine2 || '';
            const cityValue = city || '';
            const stateValue = stateName || '';
            const zipValue = zip || '';
            // Setting Employer Address to Address component
            setAddress({
                addressLine1: addressLine1Value,
                addressLine2: addressLine2Value,
                zip: zipValue,
                city: cityValue,
                state: stateValue,
            },'address');
            this.setState({
                seasonalStartDate: dateValidFrom,
                seasonalEndDate: dateValidTo,
            })
        }
        addFormAbandonUnloadListner(isEdit);
    }
    
    componentDidUpdate(){
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, analyzeClickEvent, linkResponseError, isEdit } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        if(isError){
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_managecontactinfo_${isEdit?'Edit':'Add'}contactInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_managecontactinfo_${isEdit?'Edit':'Add'}contactInfo_save`, analyzeClickEvent);
            history.push(routeConstants.contactInfo);
        }
    }
    
    componentWillUnmount() {
        const { resetAddressInfo, isEdit, profileInformationData } = this.props;
        const { isSuccess } = profileInformationData;
        resetAddressInfo('address');
        if(!isSuccess){
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    updateOrAddAddress = () => {
        const { addressInfo, location, isEdit, updateAccountLevelAddresses } = this.props;
        const { seasonalStartDate, seasonalEndDate } = this.state;
            const { state:locationState } = location;
            const { 
                accountInformation,
                addressInformation: accountAddrData = [],
                isMailingAddress,
                isPhysicalAddress,
                isSeasonalAddress,
                addrIndex,
            } = locationState || {};
            
            const addressData = accountAddrData[+addrIndex] || {};
            
            const { addressInformation }= addressInfo;
            const {
                addressLine1,
                addressLine2,
                zip,
                city,
                state,
              } = addressInformation;
              const sendingData = {
                  accountInformation,
                  addressInformation: {
                      ...addressData,
                      addressLine1,
                      addressLine2,
                      city,
                      state,
                      zip,
                      isPhysicalAddress,
                      isMailingAddress,
                      isSeasonalAddress,
                      dateValidFrom: seasonalStartDate,
                      dateValidTo: seasonalEndDate
                  },
                addressAction: isEdit ? 'Edit' : 'Add',
            };
            updateAccountLevelAddresses(sendingData);
    }
    
    onDateChagne = (type) => (e) => {
        const { value } = e.target;
        this.setState({
                [type]: value,
            });
    }
    
    toggleAccNumberView = () => {
        const { analyzeClickEvent, isEdit } = this.props;
        const { showAccNumber } = this.state;
        this.setState(prevState => {
            return {
                showAccNumber: !prevState.showAccNumber,
            }
        });
        analyzeClickEvent({
            link: `cms_basicinformation_managecontactinfo_${isEdit?'Edit':'Add'}contactinfo_${!showAccNumber? CONST.HIDE : CONST.SHOW}`
        });
    }
    
    cancelContactInfo = () => {
        const {  history, analyzeClickEvent, isEdit } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managecontactinfo_${isEdit?'Edit':'Add'}contactInfo_cancel`
        })
        history.push(routeConstants.contactInfo);
    }

    isAnythingChanged = () => {
        const { addressInfo } = this.props; 
        // const { seasonalStartDate, seasonalEndDate } = this.state;         
            
        const { addressInformation } = addressInfo;
        const {
            addressLine2,
            zip,
            addressLine2ErrorText,
            zipError,
            } = addressInformation;
        return ((!addressLine2ErrorText) && (!zipError) && addressLine2 && zip); 
    }

    render(){
        const {
            showAccNumber
        } = this.state;
        const { seasonalStartDate, seasonalEndDate } = this.state;
        const { isEdit, location, profileInformationData, addressInfo } = this.props;
        const { state:locationState } = location;
        const { 
            accountInformation,
            isSeasonalAddress,
            isMailingAddress,
            isPhysicalAddress
        } = locationState || {};
        const { accountName, accountNumber, accountType, primaryOwner } = accountInformation || {};
        let typeOfAddress = CONST.SESSIONAL_ADDRESS;
        if(isMailingAddress) typeOfAddress = CONST.MAILING_ADDRESS;
        if(isPhysicalAddress) typeOfAddress = CONST.PHYSICAL_ADDRESS;

        let accountNumberMasked = showOnlyLastFourChar(accountNumber);
        if(showAccNumber){
            accountNumberMasked = accountNumber;
        }
        
        const { isLoading: profileLoading } = profileInformationData;
        const { isLoading:addrLoading } = addressInfo;
        const loading = profileLoading || addrLoading;
        
        primaryBtnProps.onClick = this.updateOrAddAddress;
        cancelBtnProps.onClick = this.cancelContactInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();

        return(
            <>
                {loading && <WSpinner loading={loading} />}
                <Container>
                    <WBreadCrumb
                        breadCrumbItems={isEdit ? CONST.BREADCRUM_CONTACT_EDIT : CONST.BREADCRUM_CONTACT_ADD}
                        activeCrumb={isEdit ? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                    />
                    <PageTitle className="mb-5"
                        title={isEdit ? CONST.PAGE_NAME_CONTACT_EDIT :CONST.PAGE_NAME_CONTACT_ADD}
                        showMandatoryTxt
                    />
                    <AccountDetailsCard
                        accountName={accountName}
                        accountNumber={accountNumberMasked}
                        toggleAccNumberView={this.toggleAccNumberView}
                        showAccNumber={showAccNumber}
                        accountType={accountType}
                        primaryOwner={primaryOwner}
                    />
                    <Address />
                    { isSeasonalAddress && (
                    <StyledDiv>
                        <VCMDatePicker
                            name={CONST.STARTDATE_LABEL}
                            id="vcm-cms-seasonal-start-date"
                            value={seasonalStartDate}
                            labelsm={3}
                            valuesm={5}
                            label={CONST.STARTDATE_LABEL}
                            onChange={this.onDateChagne('seasonalStartDate')}
                            min={formatDate(new Date())}
                            minErrorTxt="Invalid Date"
                        />
                        <VCMDatePicker
                            name={CONST.ENDDATE_LABEL}
                            id="vcm-cms-seasonal-end-date"
                            value={seasonalEndDate}
                            labelsm={3}
                            valuesm={5}
                            label={CONST.ENDDATE_LABEL}
                            onChange={this.onDateChagne('seasonalEndDate')}
                            min={ isValidDate(seasonalStartDate) ? formatDate(seasonalStartDate): '1900-01-01'}
                            minErrorTxt="Invalid Date"
                        />
                    </StyledDiv>
                    )}
                    <InstructionalPanel className="my-5" mb={2.5}>
                        <ul className="m-0">
                            <li>{CONST.MAKING_CHANGE} {typeOfAddress} </li>
                            <li>{CONST.VERIFY_TEXT} </li>
                        </ul>
                    </InstructionalPanel>
                </Container>
                <BottomNavBar
                    hideBack
                    showCancelBtn
                    primaryBtnText={CONST.SAVE}
                    primaryBtnProps={primaryBtnProps}
                    cancelBtnProps={cancelBtnProps}
                />
            </> 
        )
    }
}

AddSeasonalInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    isEdit: PropTypes.bool,
    profileInformationData: PropTypes.instanceOf(Object),
    setAddress: PropTypes.func,
    clearExistingActions: PropTypes.func,
    resetAddressInfo: PropTypes.func,
    updateAccountLevelAddresses: PropTypes.func,
    addressInfo:PropTypes.instanceOf(Object),
    analyzeClickEvent: PropTypes.func,
    linkResponseError: PropTypes.func,
}

AddSeasonalInfoComponent.defaultProps = {
    isEdit: false,
    profileInformationData: {},
    setAddress: () => {},
    clearExistingActions: () => {},
    resetAddressInfo: () => {},
    updateAccountLevelAddresses: () => {},
    addressInfo: {},
    analyzeClickEvent: () => {},
    linkResponseError: () => {},
}

export default AddSeasonalInfoComponent