import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ImgSrc = styled.img`
    height: 30px;
    width: 30px;
`;

const LinkText = styled.span`
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    margin-left: 10px;
    vertical-align: middle;
    font-family: 'benton-sans';
`;
const assignObj = obj => obj;

export default function BackNavigation({ text, url, icon, pageFrom, payLoad }) {
    return (
        <div data-test="backnav">
            <ImgSrc src={icon} alt="arrow" />{' '}
            <LinkText>
                <Link
                    style={assignObj({ color: '#004A98', textDecoration: 'none' })}
                    to={assignObj({ pathname: url, name: pageFrom, ...payLoad })}
                >
                    {text}
                </Link>
            </LinkText>
        </div>
    );
}

BackNavigation.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.string,
    pageFrom: PropTypes.string,
    payLoad: PropTypes.instanceOf(Object),
};
BackNavigation.defaultProps = {
    text: '',
    url: '',
    icon: '',
    pageFrom: '',
    payLoad: {},
};
