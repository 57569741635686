import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckBox from "../CheckBox";

const FlexRowDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const EConsentAgreeSec = styled(FlexRowDiv)`
    justify-content: space-between;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 1.75rem 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: ${(props => props.mB || 0)}rem;
`;

const AgreeTxt = styled.label`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    margin:0;
`;

const TimeStamp = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    white-space: pre;
    margin:0;
`;

const CheckOption = (props) => {
    const { text, mB, dateTimeStamp, ...restProps } = props;
    return (
        <EConsentAgreeSec mB={mB}>
            <FlexRowDiv>
                <CheckBox {...restProps} id="vcm-check-option-text" />
                <AgreeTxt htmlFor="vcm-check-option-text">{text}</AgreeTxt>
            </FlexRowDiv>
             { dateTimeStamp && <TimeStamp>{dateTimeStamp}</TimeStamp> }
        </EConsentAgreeSec>
        )
}

CheckOption.propTypes = {
    text: PropTypes.string,
    dateTimeStamp: PropTypes.string,
    mB: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

CheckOption.defaultProps = {
    text: '',
    dateTimeStamp: '',
    mB:0,
};

export default CheckOption;