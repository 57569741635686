import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CommonButtons, WBreadCrumb, WSpinner } from '../../../common';
import MailToAddressCard from './MailToAddressCard';
import MemberNumberCard from './MemberNumberCard';
import FormDownloadCard from './FormDownloadCard';
import routeConstants from '../../../routeConstants';
import { searchDocuments } from '../../../shared/Actions/DocumentCenterActions';
import {
    steps,
    formIds,
    breadCrumbText,
    TITLE_TEXT,
    GEN_INSTRUCTIONS,
    NO_RECORDS_FOUND,
    ARIA_BACK_BUTTON_LABEL,
} from './constants';

const S = {};

S.Section = styled.div`
    display: flex;
    flex-flow: column nowrap;
    font-family: 'benton-sans';
    color: #49494a;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
`;

S.Container = styled(S.Section)`
    & .row {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 15px;
    }
    & [class*='col-'] {
        padding-left: 0px;
        padding-right: 0px;
    }
    margin-bottom: 100px;
`;

S.Title = styled(S.Section)`
    height: 46px;
    color: #486d90;
    font: Bold 22px/45px yorkten-slab-normal;
`;

S.Description = styled(S.Section)`
    font: SemiBold 16px/25px Benton Sans;
    margin-bottom: 10px;
`;

S.StepSection = styled(S.Section)``;

S.StepNumber = styled.span`
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 30px;
    width: 30px;
    border: 2px solid #49494a;
    border-radius: 50%;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 4.25px;
`;

S.StepTitle = styled.span`
    margin-left: 12px;
    font: 800 18px/22px yorkten-slab-normal;
`;

S.StepTitleRow1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0px 15px 0px;
`;

S.StepTitleRow2 = styled(S.StepTitleRow1)``;

S.StepTitleRow3 = styled(S.StepTitleRow1)`
    margin-top: 30px;
`;

S.StepBodyRow1 = styled.span`
    line-height: 20px;
    margin-bottom: 5px;
`;

S.StepBodyRow2 = styled(S.StepBodyRow1)`
    margin: 7px 0px 12px 0px;
`;

S.Divider = styled.div`
    border-top: 1px solid #d2d2d2;
    margin-bottom: 15px;
`;

S.MailToAddressCard = styled(MailToAddressCard)`
    margin-top: 5px;
`;

S.ErrorStyle = styled.span`
    color: #f00000;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0px;
`;

function SpecialtyAccountApplicationForms() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { specialtyApplicationSubtype: appType } = useSelector(state => state.accOpeningReducerData);
    const { dashboardDocumentList: specialtyForms, isLoading } = useSelector(state => state.documentPreferenceData);
    const { accountType = '' } = useSelector(state => state.localStateManagementReducerData);
    const { localStateManagementReducerData: { customerProfile = {} } ={} } = useSelector(state => state);
    const { role = '' } = customerProfile;
    const { openAccount, accountholder, accountType: backLocation, accountguestview } = routeConstants;
    const { BREAD_CRUMB_1_NAME, BREAD_CRUMB_2_NAME } = breadCrumbText;
    const { S1, S2, S3 } = steps;
    const isMember = role && role === 'Member';
    const homeBreadCrumbUrl = isMember ? accountholder : accountguestview;
    const breadCrumbValues = [
        {
            url: homeBreadCrumbUrl,
            name: BREAD_CRUMB_1_NAME,
        },
        {
            url: openAccount,
            name: BREAD_CRUMB_2_NAME,
        },
    ];

    document.title = 'Specialty Account: Victory Capital Management';

    useLayoutEffect(() => {
        const authToken = localStorage.getItem('token');
        const payload = {
            searchPayload: {
                contentType: 'cm:content',
                vcmDocMetadata: {
                    formId: formIds[appType].formId,
                    templateInstance: 'Template',
                },
            },
            authToken,
        };
        dispatch(searchDocuments(payload));
    }, [dispatch, appType]);

    const specialtyFormsComponent =
        specialtyForms.length === 0 ? (
            <S.ErrorStyle>{NO_RECORDS_FOUND}</S.ErrorStyle>
        ) : (
            specialtyForms.map(form => <FormDownloadCard key={form.nodeId} {...form} />)
        );

    return (
        <>
            <S.Container className="container">
                <WBreadCrumb activeCrumb={accountType} breadCrumbItems={breadCrumbValues} />
                {isLoading && <WSpinner loading />}
                <S.Title>
                    {accountType}
                    {TITLE_TEXT}
                </S.Title>
                <S.Divider />
                <S.Description>{GEN_INSTRUCTIONS}</S.Description>
                <S.StepSection>
                    <S.StepTitleRow1>
                        <S.StepNumber>{S1.NUMBER}</S.StepNumber>
                        <S.StepTitle>{S1.HEADING}</S.StepTitle>
                    </S.StepTitleRow1>
                    <S.Divider />
                    <S.StepBodyRow1>{S1.INSTRUCTIONS}</S.StepBodyRow1>
                    {!isLoading && specialtyFormsComponent}
                </S.StepSection>
                <S.StepSection>
                    <S.StepTitleRow2>
                        <S.StepNumber>{S2.NUMBER}</S.StepNumber>
                        <S.StepTitle>{S2.HEADING}</S.StepTitle>
                    </S.StepTitleRow2>
                    <S.Divider />
                    <S.StepBodyRow2>{S2.INSTRUCTIONS}</S.StepBodyRow2>
                    <MemberNumberCard />
                </S.StepSection>
                <S.StepSection>
                    <S.StepTitleRow3>
                        <S.StepNumber>{S3.NUMBER}</S.StepNumber>
                        <S.StepTitle>{S3.HEADING}</S.StepTitle>
                    </S.StepTitleRow3>
                    <S.Divider />
                    <S.MailToAddressCard appType={appType} />
                </S.StepSection>
            </S.Container>
            <CommonButtons
                backClick={() => history.push(backLocation)}
                nextstyle={{ display: 'none' }}
                arialabelback={ARIA_BACK_BUTTON_LABEL}
            />
        </>
    );
}

export default SpecialtyAccountApplicationForms;
