/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the child beneficiary info details to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generateChildBeneFiciaryCard(masterLookupStateData, benInfo) {
    let beneficiaryInfoCard = {};
    if (benInfo) {
        const mask = value => (value ? value.replace(/\d(?=\d{4})/g, 'X') : '');
        let relationshipData = '';
        if (benInfo.relationshipToCustodian) {
            const relList = masterLookupStateData['custd_relation'.toString()].value;
            relationshipData = relList.find(item => item.key === benInfo.relationshipToCustodian).value;
        }
        beneficiaryInfoCard = {
            cardId: `benInformationChild`,
            linkText: Consts.edit,
            cardHeading: `Beneficiary Information - Child`,
            fields: [
                {
                    uniqueKey: 'relationshipToCustodian',
                    key: Consts.relationToCustodian,
                    value: relationshipData,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'firstName',
                    key: Consts.fname,
                    value: benInfo.firstName,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'middleName',
                    key: Consts.mname,
                    value: benInfo.middleName || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'lastName',
                    key: Consts.lname,
                    value: benInfo.lastName,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'ssn',
                    key: Consts.snn,
                    value: mask(benInfo.beneficiarySSN) || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'bendob',
                    key: Consts.dob,
                    value: benInfo.beneficiaryDOB,
                    type: Consts.staticfield,
                },
            ],
        };
    }
    return beneficiaryInfoCard;
}
