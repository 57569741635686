export const INFO = {
    TRANSACTION: 'Transaction',
    ACTION_BREAD_CRUMBS: 'Transaction History',
    BREADCRUMB_VALUES: [{ name: 'Home', url: '/accountholder' }],
    PAGENAME: "Joe's Retirment fund ",
    TOTAL_AMOUNT: '$ 5,500.00',
    CURR_VALUE: 'Current Value:',
    ACC_NUMBER: 'Registration Number',
    ACC_TYPE: 'Account Type',
    REG: 'Registration',
    MANAGE_SERVICES: 'Manage Services',
    MANAGE_PREFERENCES: 'Manage Preferences',
    VICTORY_FUNDS: 'Victory Funds',
    V_AC_ACTIVITY: 'Account Activity',
    STAR: '*',
    AS_ON_VALUE_TEXT: 'Value as of market close on',
    AS_ON: 'Value_as_of_market_close_on',
    BACK_TO_PORT: 'Back to My Portfolio',
    URL: '/accountholder',
    ACC_SERVICES: 'Account Service',
    LOAD_MORE: 'Load More',
    ACCOUNTSERVICES: {
        CARDID: 1,
        CARDHEADING: 'Account Services',
        CARDLINKS: [
            { id: 1, linktext: 'Systematic Investment Plan', link: '#' },
            { id: 2, linktext: 'Systematic Withdrawal Plan', link: '#' },
            { id: 3, linktext: 'Required Minimum Distribution', link: '#' },
            { id: 4, linktext: 'Dividends and Capital Gains', link: '#' },
            { id: 5, linktext: 'Manage Beneficiaries', link: '#' },
            { id: 6, linktext: 'Interested Parties', link: '#' },
            { id: 7, linktext: 'Checkbook', link: '#' },
        ],
    },
    MANAGE_PREFERENCES_KEY_MESSAGE :[
        {key :'Automatic Investing' , message : "Display a message with instructions to set up automatic investing in NXI along with a link to Launch NXI and a link to return to account overview page."},
        {key :'Automatic Withdrawal' , message : "Display a message with instructions to set up automatic withdrawal in NXI along with a link to Launch NXI and a link to return to account overview page."},
        {key :'Bank Accounts' , message : "Display a message with instructions to manage bank accounts in NXI along with a link to Launch NXI and a link to return to account overview page."},
        {key :'Dividends and Capital Gains' , message : "Display a message with instructions to set up dividend and capital gain preferences in NXI along with a link to Launch NXI and a link to return to account overview page."},
        {key :'Beneficiaries' , message : "Display a message with instructions to speak to a Representative since managing beneficiaries cannot be done online and a link to return to account overview page."},
        {key :'Required Minimum Distributions' , message : "Display a message with instructions to speak to a Representative since setting up RMDs cannot be done online and a link to return to account overview page."},
        {key :'Document Preferences' , message : "Display a message with instructions to speak to a Representative since delivery preferences cannot be updated online and a link to return to account overview page.In addition, display the current delivery method within the message."},
        {key :'Phone/Email' , message : ""},
        {key :'Trusted Contact' , message : "Display a message with instructions to speak to a Representative since managing trusted contacts cannot be done online and a link to return to account overview page."},
        {key :'Mailing Address' , message : "Display a message showing the current mailing address & address type for the account along with a ‘Edit’ button besides it and a link to return to account overview page."}
    ]
};
export const OpenaDynamaicAdvisor={link:"/marketOpenAccount",name:"Open a New Dynamic Advisor Account"}
export const ACC = {
    CARDID: 1,
    CARDHEADING: 'Account Services',
    CARDLINKS: [
        { id: 1, linktext: 'Systematic Investment Plan', link: '#' },
        { id: 2, linktext: 'Systematic Withdrawal Plan', link: '#' },
        { id: 3, linktext: 'Required Minimum Distribution', link: '#' },
        { id: 4, linktext: 'Dividends and Capital Gains', link: '#' },
        { id: 5, linktext: 'Manage Beneficiaries', link: '#' },
        { id: 6, linktext: 'Interested Parties', link: '#' },
        { id: 7, linktext: 'Checkbook', link: '#' },
    ],
    CANCEL_POPUP: {
        TITLE: 'Cancel pending transaction',
        BODY: 'This action cannot be undone. Are you sure you want to cancel this transaction?',
        SECONDARY_BUTTON: 'No',
        PRIMARY_BUTTON: 'Yes',
        OK_BUTTON: 'Ok',
    },
    NO_RECORDS_FOUND: 'No Records Found...',
};

export const HEADER = {
    TICKER: 'Ticker',
    FUND_NAME: 'Fund Name',
    ACC_NUMBER: 'Account Number',
    CURRENT_VALUE: 'Current Value',
    TOTAL_SHARE: 'Total Shares',
    TOTAL_COST: 'Total Cost',
    GAIN_OR_LOSS: 'Estimated Gain/Loss',
    COST_BASIS: 'Cost Basis',
    AVAIL_SHARE: 'Available Shares',
    NAV: 'NAV',
    STAR: '*',
    ONE_DAY_CH: '1-Day NAV Change',
    ONE_DAY_ACC_CH: '1-Day Account Change',
    PRODUCTID: 'PRODUCT ID',
    RISKLEVEL: 'RISK Level',
    DATE:'Update Date',
    FILE_NAME: 'File Name',
    LAST_UPLOADED_DATE: 'Last Uploaded Date'

};

export const closedApplicationPopUpMessage = "In preparation for the upcoming tax season, we have retained viewing of your closed accounts to provide online access to retrieve upcoming tax forms and historical statements. Your account will be removed from your dashboard at the end of the tax reporting period. If your account was recently closed and you would like to reopen the account, please contact one of our live U.S. based Investment Specialists.";
export const closedEducationApplicationPopUpMessage = "In preparation for the upcoming tax season, we have retained viewing of your closed accounts to provide online access to retrieve upcoming tax forms and historical statements. Your account will be removed from your dashboard at the end of the tax reporting period.";

export const CANCEL_TRANSACTION_DESCRIPTION = 'Are you sure you want to cancel';
export const PLACE_A_TRADE = 'Place a Trade';
export const EDIT_TEXT = 'Edit';
export const CANCEL_TRANSACTION_PROMPT_TEXT =
    'Due to market hours it is possible that this order has already executed and has not yet been reported. Your order can not be canceled at this time';
export const CANCEL_IN_FISTA = 'Cancel in FISTA';
export const CANCEL_IN_FISTA_TITLE = 'Cancel pending transaction';
export const CANCEL_IN_FISTA_MESSAGE = 'Please login to FISTA to cancel this transaction';
export const ADDRESS_TYPE= 'Address Type';
export const addressType = [
    { key: 'U.S. or U.S. Territories', value: 'U.S. or U.S. Territories' },
    { key: 'APO (Army or Air Force Post Office)', value: 'APO (Army or Air Force Post Office)' },
    { key: 'FPO (Fleet Post Office)', value: 'FPO (Fleet Post Office)' },
    { key: 'DPO (Diplomatic Post Office)', value: 'DPO (Diplomatic Post Office)' }
]
export const IsmailingSame = 'Is your physical address the same as your mailing address? ';
export const IsmailingSameOption = [
    {key: 'Yes',value: 'Yes',selected: false},
    {key: 'No',value: 'No',selected: false},
]
export const ApplyRegistrationtext = 'Do you want to apply to all registrations? ';
export const ApplyRegistrationOption = [
    {key: 'Yes',value: 'Yes',selected: false},
    {key: 'No',value: 'No',selected: false},
]
export const ApiSuccessMessage = "Address updated successfully";
export const ApiSuccessErrorMessage = "We are experiencing technical difficulties. We are actively working to resolve the issue.";
export const ADDRESS_TYPE_ERROR = 'Please select Address Type';
export const unavailableText="Temporarily Unavailable";