import React, { useEffect, useState } from 'react';
import {  Row, Container } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { WButton, WInputCheckBox, WIcon } from '../../../../common';
import * as TransactionConstants from '../../TransactionConstants';
import envConfig from 'env/EnvConfig';
// import EConsentData from '../../../../common/EConsent/EConsentData';

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};


const CheckWrapper = Styled.div`
    background: #f0f4f7 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font: 500 16px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #3e607e;
    margin-bottom: 25px;
    .checkBoxWrapper{
        .form-group{
            @media (max-width: 767px){
                align-items: normal!important;
            }
        }
        
    }
`;

const FundDocDescription = Styled.a`
    @media (max-width: 767px){
        font-size: 16px;
    }
    margin-left: 10px;
    text-decoration:none;
    color: rgb(0, 74, 152);
`;

const FileNameSpan = Styled.span`
    margin-right: 10px;
`;

const CheckContentCommonStyles = Styled.div`
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 35px;
    flex-wrap: nowrap;
    display: inline-flex;
    padding-left: 20px;
    letter-spacing: 0;
    opacity: 1;

    .checkBoxWrapper input[type='checkbox']:checked:after {
        width: 8px;
        height: 14px;
        top: 2px;
        left: 8px;
    }
`;

const DesktopCheckContent = Styled(CheckContentCommonStyles)`
    @media (max-width: 767px){
        display: none;
    }    
    width: 100%;
`;

const checkBoxWrapperStyle = {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
};
const checkBoxInputStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #4F799F',
    opacity: 1,
    height: 25,
    width: 25,
    flexShrink: 0,
    marginLeft: 20,
    display: 'inline-block',
};

const buttonstyle = { display: 'block' };

const assignObj = obj => {
    return obj;
};

const checkBoxStyle = { position: 'absolute', top: '10px', display: 'inline' };
const labelStyle = {
    paddingTop: '3px',
    textAlign: 'left',
    font: '600 16px/25px benton-sans',
    letterSpacing: 0,
    color: '#3E607E',
    opacity: 1,
    width: '80%',
    display: 'inline',
};

export const displayTime = () => {
    const today = new Date();
    const date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    let hours = today.getHours();
    const minutes = today.getMinutes();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const time = `${hours}:${minutes}:${today.getSeconds()} ${AmOrPm}`;
    return `${date}  ${time}`;
};

const SectionTitle = Styled.div`
    display: inline-block; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

`;

const TitleTxt = Styled.h2`
    @media (max-width: 767px){
        text-align: left;
        margin-top: 1.5rem;
    }
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;

const DocumentsTitleTxt = Styled.h3`
    @media (max-width: 767px){
        margin-top: 1rem;
    }
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
`;

const ReviewDetails = Styled.p`
    font-size: 16px;
    margin: 10px 0;
    color: #49494A;
`;

const AdobeReaderIconSpan = Styled.span`
    @media (max-width: 767px){
        width: 20px !important;
        height: 20px !important;
    }
`;


const MainDiv = Styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const Separator = Styled.hr`
    opacity: 1;
    margin-top: 0.125rem;
`;

const BtnWrap = Styled(Container)`
    display:flex; 
    justify-content: space-between;
    align-items:center;
    padding:20px 0;
    &>:nth-child(2){ 
        margin-left: auto;
        margin-right: 50px;
        button{
            margin-bottom: 0 !important;
        }
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:3;
        }
        &>:nth-child(2){
            order:2;
            margin: 0;
            button{
                margin:0 !important;
            } 
        }
        &>:nth-child(3){
            order:1;
            width:100%;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;
function EConsentComponent(props) {
    const {
        onSwitchComponent,
        submitData,
        selectedConsent,
        setSelectedConsent,
        selectedFund,
        resetEConsent,
        setConsentTime,
        setCancelPurchase,
        setCancelExchange,
        transactionType,
    } = props;
    const [EConsentData, setEConsentData] = useState(null);
    const displayTimeInfo = displayTime();
    useEffect(() => {
        resetEConsent();
        setConsentTime(displayTimeInfo);
        const skipToMain = document.getElementById('skipToMain');
        if (skipToMain) {
            skipToMain.focus();
        }
        if (transactionType === 'exchange') {
            document.title = 'Prospectus Review: Exchange | Victory Capital';
        } else if (transactionType === 'purchase') {
            document.title = 'Prospectus Review: Buy | Victory Capital';
        }
        setEConsentDataInfo();
    }, []);

    function handleConsentChange() {
        // Dispatch action here
        setSelectedConsent(!selectedConsent);
    }

  const setEConsentDataInfo = async () =>{
        if(envConfig.ENV == "SIT" || envConfig.ENV == 'PROD')
        {
            console.log('if call')
            const  EConsentDataInfo =await import('../../../../common/EConsent/EConsent_SIT_PROD_Data');
            setEConsentData(EConsentDataInfo);
        }
        else
        {
            console.log('else call')
            const  EConsentDataInfo =await import('../../../../common/EConsent/EConsentDataLowerEnv');
            setEConsentData(EConsentDataInfo);
        }
    }

    const disableNext = !selectedConsent;

    const econsentDocumentLink = EConsentData && EConsentData?.default?.filter(item => item.fundCode === selectedFund.fundNumber);
    let docUrl = '';
    let description = '';
    let fileName = '';
    if (econsentDocumentLink?.length > 0) {
        docUrl = econsentDocumentLink[0].docUrl;
        description = econsentDocumentLink[0].description;
        fileName = econsentDocumentLink[0].fileName;
    }

    return (
        <div>
            <div className="container" data-test="EConsentContainer">
                <div id="documents-review-section" data-test="documentsReviewSection">
                    <SectionTitle>
                        <TitleTxt data-test="titleText">{TransactionConstants.DOCUMENTS_TO_REVIEW_TEXT}</TitleTxt>
                        <div>
                            {TransactionConstants.ADOBE_READER_TEXT_1}
                            <a
                                data-test="adobeDownloadLink"
                                href={TransactionConstants.ADOBE_READER_DOWNLOAD_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontSize: '16px',
                                    color: 'rgb(0, 74, 152)',
                                    textDecoration: 'underline',
                                    marginRight: '10px',
                                }}
                            >
                                {TransactionConstants.ADOBE_READER_LINK}
                                <AdobeReaderIconSpan aria-hidden="true" className="icon-newpage small-size" />
                            </a>
                            {TransactionConstants.ADOBE_READER_TEXT_2}
                        </div>
                    </SectionTitle>
                    <Separator aria-hidden="true" />
                </div>
                <div id="prospectus-documents-section" data-test="fundProspectusSection">
                    <SectionTitle>
                        <DocumentsTitleTxt data-test="fundProspectusTitle">
                            {TransactionConstants.PROSPECTUS_TEXT}
                        </DocumentsTitleTxt>
                    </SectionTitle>
                    <Separator aria-hidden="true" />
                    <ReviewDetails data-test="fundPropectusDescription">
                        {TransactionConstants.PROSPECTUS_REVIEW_DETAILS_TEXT}
                    </ReviewDetails>

                    <CheckContentCommonStyles>
                        <Row noGutters>
                            <WIcon
                                icon={faCheck}
                                size="sm"
                                fontawesomestyle={assignObj({
                                    height: 18,
                                    width: 15,
                                    margin: '0 10 0 0',
                                    color: '#8CBF26',
                                })}
                            />
                            <div>
                                {description && (
                                    <FundDocDescription href={docUrl || ''} target="_blank" rel="noopener noreferrer">
                                        <FileNameSpan>{description}</FileNameSpan>
                                        <span className="sr-only">{TransactionConstants.EXTERNAL_LINK_TEXT}</span>
                                        <AdobeReaderIconSpan aria-hidden="true" className="icon-newpage small-size" />
                                    </FundDocDescription>
                                )}
                                {(!description || description.trim() === '') && (
                                    <div>
                                        {TransactionConstants.PROSPECTUS_NOT_AVAILABLE} {selectedFund.fundName}
                                    </div>
                                )}
                            </div>
                        </Row>
                    </CheckContentCommonStyles>
                </div>

                <CheckWrapper>
                    <DesktopCheckContent>
                        <WInputCheckBox
                            id="esignCheck"
                            checkboxwrapperstyle={checkBoxWrapperStyle}
                            checkInputStyle={checkBoxInputStyle}
                            checked={selectedConsent}
                            value={`${selectedConsent}`}
                            onChange={handleConsentChange}
                            checkboxstyle={checkBoxStyle}
                            labelstyle={labelStyle}
                            label={TransactionConstants.CHECK_LABEL}
                            ariadescribedby="esignCheck_dateTime esignCheck_error"
                            ariainvalid={selectedConsent}
                            data-test="esignCheck"
                        />
                      
                    </DesktopCheckContent>
                </CheckWrapper>
            </div>

            <MainDiv>
                <BtnWrap>
                    <div>
                        <WButton
                            variant="back"
                            buttontext={TransactionConstants.BACK_TEXT}
                            onClick={onSwitchComponent(false)}
                            buttonstyle={buttonstyle}
                            role="link"
                            data-test="backButton"
                        />
                    </div>
                    <div>
                        <WButton
                            variant="cancel"
                            buttontext="Cancel"
                            id="cancelButton"
                            buttonstyle={assignObj(cancelButtonStyles)}
                            onClick={transactionType === 'exchange' ? setCancelExchange : setCancelPurchase}
                        />
                    </div>
                    <div>
                        <WButton
                            buttontext={TransactionConstants.SUBMIT_TEXT}
                            onClick={submitData}
                            data-test="submitButton"
                            disabled={disableNext}
                            buttonstyle={buttonstyle}
                            role="link"
                        />
                    </div>
                </BtnWrap>
            </MainDiv>
        </div>
    );
}

EConsentComponent.propTypes = {
    onSwitchComponent: PropTypes.func,
    submitData: PropTypes.func,
    selectedConsent: PropTypes.bool,
    setSelectedConsent: PropTypes.func,
    selectedFund: PropTypes.instanceOf(Object),
    resetEConsent: PropTypes.func,
    setConsentTime: PropTypes.func,
    setCancelPurchase: PropTypes.func,
    setCancelExchange: PropTypes.func,
    transactionType: PropTypes.string,
};

EConsentComponent.defaultProps = {
    onSwitchComponent: () => {},
    submitData: () => {},
    selectedConsent: false,
    setSelectedConsent: () => {},
    selectedFund: {},
    resetEConsent: () => {},
    setConsentTime: () => {},
    setCancelPurchase: () => {},
    setCancelExchange: () => {},
    transactionType: 'purchase',
};

export default EConsentComponent;
