/**
 * Email Address Commponent Card
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to provide multiple call Multiple PhoneNumber Forms
 * * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */


import { Accordian, AccordianTitle } from '../../../common';
import { Card, Alert } from 'react-bootstrap';
import PhoneDetails from './PhoneDetails';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import EmailDetails from '../../AccountManagementModule/MarketEmail/MarketEmailDetails';


const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

const EMAIL_ERROR = 'Please enter your primary email address';

class MarketPhoneDetailsCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryPhoneDetailsData: {},
            secondaryPhoneDetailsData: {},
            primaryEmailDetailsData: {},
            secondaryEmailDetailsData: {},
            phoneDetailsClicked: true,
            showEmailAlert : false
        };
        this.primaryPhoneDetailsRef = React.createRef();
        this.secondaryPhoneDetailsRef = React.createRef();
        this.primaryEmailDetailsRef = React.createRef();
        this.secondaryEmailDetailsRef = React.createRef();
        this.contactAccordion = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const { savedState } = props;
        if (!state.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return state;
    }

    isValidPhoneNumber = phoneDetails => {
        const { phoneNumber, errPhoneNumber, mPhoneNumber, errCountryCode } = phoneDetails;
        return (phoneNumber.length !== 0 || mPhoneNumber.length !=0) && !errPhoneNumber && !errCountryCode;

    };

    isValidPrimaryEmailAddress = emailDetails => {
        const { email, errEmail } = emailDetails;
        return email.length !== 0 && !errEmail;
    };

    sendEmailDataToParent = (action = 'defaultVal') => {
        const primaryEmailDetailsData =
            this.primaryEmailDetailsRef.current && this.primaryEmailDetailsRef.current.getEmailDetails();
        const secondaryEmailDetailsData =
            this.secondaryEmailDetailsRef.current && this.secondaryEmailDetailsRef.current.getEmailDetails();
    
        let valid = false;
        let toFocus = '';
        let save = action === 'save'

        // if save button is clicked dont perform any validation and show error banner
        if(!save){
        if (!this.isValidPrimaryEmailAddress(primaryEmailDetailsData)) {
            this.setState({ showEmailAlert: true });
            valid = false;
            toFocus = primaryEmailDetailsData.emailId;
        } else if (
            secondaryEmailDetailsData &&
            secondaryEmailDetailsData.email &&
            secondaryEmailDetailsData.email.length > 0
        ) {
            if (!this.isValidPrimaryEmailAddress(secondaryEmailDetailsData)) {
               // this.setState({ showEmailAlert: true });
                valid = false;
                toFocus = secondaryEmailDetailsData.emailId;
            } else {
                this.setState({ showEmailAlert: false });
                valid = true;
            }
        } else {
            this.setState({ showEmailAlert: false });
            valid = true;
        }
        }else{
            this.setState({ showEmailAlert: false });
            valid = true;
        }
        return {
            primaryEmailDetails: primaryEmailDetailsData,
            secondaryEmailDetails: secondaryEmailDetailsData,
            valid,
            toFocus,
        };
    };
    

    sendDataToParent = (action='defaultVal') => {
        const primaryPhoneDetailsData =
            this.primaryPhoneDetailsRef.current && this.primaryPhoneDetailsRef.current.getPhoneDetails();
        const secondaryPhoneDetailsData =
            this.secondaryPhoneDetailsRef.current && this.secondaryPhoneDetailsRef.current.getPhoneDetails();

        let valid = false;
        let toFocus = '';
        
        let save = action === 'save';
        // if save button is clicked then dont run any validation and show error banner, run if its not save.
        if(!save){
        if (!this.isValidPhoneNumber(primaryPhoneDetailsData)) {
            this.setState({ showAlert: true });
            valid = false;
            const { errCountryCode, countryCodeId, phoneId } = primaryPhoneDetailsData;
            toFocus = errCountryCode ? countryCodeId : phoneId;
        }
        // else if(true)
        // {

        // }
         else if (
            secondaryPhoneDetailsData &&
            secondaryPhoneDetailsData.phoneNumber &&
            secondaryPhoneDetailsData.phoneNumber.length > 0
        ) {
            if (!this.isValidPhoneNumber(secondaryPhoneDetailsData)) {
                this.setState({ showAlert: true });
                valid = false;
                const { errCountryCode, countryCodeId, phoneId } = secondaryPhoneDetailsData;
                toFocus = errCountryCode ? countryCodeId : phoneId;
            } else {
                this.setState({ showAlert: false });
                valid = true;
            }
        } else {
            this.setState({ showAlert: false });
            valid = true;
        }
        }else{
            this.setState({ showAlert: false });
            valid = true;
        }

        // For Email 
        
        
        return {
            primaryPhoneDetails: primaryPhoneDetailsData,
            secondaryPhoneDetails: secondaryPhoneDetailsData,
            valid,
            toFocus,
        };
    };

    cardHeaderClick = e => {
        const { phoneDetailsClicked } = this.state;
        e.preventDefault();
        e.target.classList.toggle('expand');
        this.setState({ phoneDetailsClicked: !phoneDetailsClicked });
    };

    // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues = () =>{
        this.setState({ showAlert: false ,showEmailAlert:false});
    }  

    handdlePhoneDetails = ()=>
    {
        const {handleStateChange} = this.props;
        handleStateChange(this.primaryPhoneDetailsData);
    }
    

    handleEmailDetails = ()=>
    {
        const {handleStateChange} = this.props;
        handleStateChange(this.primaryEmailDetailsRef);
    }

    render() {
        const {
            isJoint,
            contactTimeOp,
            primaryPhoneNumber,
            secondaryPhoneNumber,
            primaryContactTime,
            secondaryContactTime,
            primaryIsMobile,
            secondaryIsMobile,
            primaryCountryCode,
            secondaryCountryCode,
            jointText,
            index,
            isSuccessor,
            mPrimaryPhoneNumber,
            mSecondaryPhoneNumber,
            isPrimaryNumberChanged,
            isSecondaryNumberChanged,
            primaryEmailAddress,
            secondaryEmailAddress,
            isReadOnly,
            successorFormEmail,
            hasSecondaryEmailAddress,
            hasSecondaryPhoneNumber
        } = this.props;
        const { primaryPhoneDetailsData, secondaryPhoneDetailsData, primaryEmailDetailsData, secondaryEmailDetailsData, showAlert,showEmailAlert } = this.state;

        let accTypeHeading = this.props?.jointInfo !="Yes" ? `Contact Information` : `Contact Information - Joint Owner`;

        let beneficiaryHeading = 'Beneficiary Phone Information';
        if (isSuccessor) {
            beneficiaryHeading = 'Successor Phone Information';
        }
        //accTypeHeading = isBeneficiary || isSuccessor ? beneficiaryHeading : accTypeHeading;
        // const jointText = isJoint ? 'jointOwner' : 'primaryOwner';
        const PHONE_ERROR = ' Please enter at least one primary phone number';
        const role = sessionStorage.getItem('role');
         const verifyPersonalHider=this.props.verifyPersonalHider
         const verifyContactHider=this.props.verifyContactHider
          const verifyPhoneHider=this.props.verifyPhoneHider
           const verifyEmailHider=this.props.verifyEmailHider
             const verifyJointPersonalHider=this.props.verifyJointPersonalHider
              const verifyJointContactHider=this.props.verifyJointContactHider
              const verifyJointPhoneHider=this.props.verifyJointPhoneHider
              const verifyJointEmailHider=this.props.verifyJointEmailHider
        return (
            <>
                {/* {initialState.isLoading && <WSpinner loading={initialState.isLoading} />} */}
                <Accordian
                    ref={this.contactAccordion}
                    key={accTypeHeading}
                    accordianPaddingLeft={0}
                    titleChild={
                      verifyPersonalHider ||verifyContactHider || verifyJointPersonalHider || verifyJointContactHider  ? null :  <AccordianTitle
                            accType={ verifyPhoneHider || verifyJointPhoneHider ? "Phone Information" :null || verifyEmailHider || verifyJointEmailHider? "Email Information" : null || verifyPhoneHider || verifyEmailHider ? null :accTypeHeading}
                            id={accTypeHeading}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                            // accIndex={acctypeindex}
                        />
                    }
                    // titleSeperator
                    labelledBy={accTypeHeading}
                    Id={accTypeHeading}
                >
                    <Card>
                        <div>
                            {(role != 'Member' && showAlert == true) && (
                                <StyledAlert
                                    variant="danger"
                                    aria-atomic="true"
                                    aria-live="assertive"
                                    aria-describedby={PHONE_ERROR}
                                >
                                    <StyledPara>{PHONE_ERROR}</StyledPara>
                                </StyledAlert>
                            )}

                            {(this.props?.jointInfo == 'Yes' &&  role == "Member" && showAlert == true) && (
                                <StyledAlert
                                    variant="danger"
                                    aria-atomic="true"
                                    aria-live="assertive"
                                    aria-describedby={PHONE_ERROR}
                                >
                                    <StyledPara>{PHONE_ERROR}</StyledPara>
                                </StyledAlert>
                            )}

                            <Card.Body>
                                <PhoneDetails
                                    ref={this.primaryPhoneDetailsRef}
                                    // isLoading={initialState.isLoading}
                                    required
                                    savedState={primaryPhoneDetailsData}
                                    jointText={jointText}
                                    phtype="Primary"
                                    isJoint={isJoint}
                                    contactTimeOp={contactTimeOp}
                                    savedPhoneNumber={primaryPhoneNumber}
                                    savedContactTime={primaryContactTime}
                                    savedCountryCode={primaryCountryCode}
                                    m_phoneNumber={mPrimaryPhoneNumber}
                                    isNumberChanged={isPrimaryNumberChanged}
                                    savedIsMobile={primaryIsMobile}
                                    index={index}
                                    handdlePhoneDetails = {this.handdlePhoneDetails}
                                    flowFlag = {"MarketNAO"}
                                    jointInfo={this.props?.jointInfo == "Yes" ? "Yes" : null}
                                    marketNAOInfo = {this.props?.jointInfo == "Yes" ? this.props?.marketNAOInfo : null}
                                    verifyPersonalHider={verifyPersonalHider}
                                    verifyContactHider={verifyContactHider}
                                    verifyEmailHider={verifyEmailHider}
                                    verifyJointPersonalHider={verifyJointPersonalHider}
                                    verifyJointContactHider={verifyJointContactHider}
                                    verifyJointEmailHider={verifyJointEmailHider}
                                    isReadOnly={isReadOnly && this.props.jointInfo !="Yes"}
                                    marketJointPhoneLabel={this.props?.marketJointPhoneLabel}
                                />
                                <PhoneDetails
                                    ref={this.secondaryPhoneDetailsRef}
                                    savedState={secondaryPhoneDetailsData}
                                    // isLoading={initialState.isLoading}
                                    jointText={jointText}
                                    phtype="Secondary"
                                    isJoint={isJoint}
                                    contactTimeOp={contactTimeOp}
                                    savedPhoneNumber={secondaryPhoneNumber}
                                    m_phoneNumber={mSecondaryPhoneNumber}
                                    isNumberChanged={isSecondaryNumberChanged}
                                    savedCountryCode={secondaryCountryCode}
                                    savedContactTime={secondaryContactTime}
                                    savedIsMobile={secondaryIsMobile}
                                    index={index}
                                    handdlePhoneDetails = {this.handdlePhoneDetails}
                                    flowFlag = {"MarketNAO"}
                                    verifyPersonalHider={verifyPersonalHider}
                                    verifyContactHider={verifyContactHider}
                                    verifyEmailHider={verifyEmailHider}
                                    verifyJointPersonalHider={verifyJointPersonalHider}
                                    verifyJointContactHider={verifyJointContactHider}
                                    verifyJointEmailHider={verifyJointEmailHider}
                                    isReadOnly={hasSecondaryPhoneNumber && isReadOnly && this.props.jointInfo !="Yes"}
                                    marketJointPhoneLabel={this.props?.marketJointPhoneLabel}
                                />

                                {showEmailAlert && (
                                <StyledAlert
                                    variant="danger"
                                    aria-atomic="true"
                                    aria-live="assertive"
                                    aria-describedby={EMAIL_ERROR}
                                >
                                    <StyledPara>{EMAIL_ERROR}</StyledPara>
                                </StyledAlert>
                            )}
                            <EmailDetails
                                ref={this.primaryEmailDetailsRef}
                                // isLoading={initialState.isLoading}
                                required={!successorFormEmail}
                                savedState={primaryEmailDetailsData}
                                emailType="Primary"
                                isJoint={isJoint}
                                savedEmailAddress={primaryEmailAddress}
                                index={index}
                                type="primary"
                                isReadOnly={isReadOnly && this.props.jointInfo !="Yes"}
                                tabindexVal={0}
                                flowFlag = {"MarketNAO"}
                                handleEmailDetails = {this.handleEmailDetails}
                                jointInfo={this.props?.jointInfo == "Yes" ? "Yes" : null}
                                marketNAOInfo = {this.props?.jointInfo == "Yes" ? this.props?.marketNAOInfo : null}
                                verifyPersonalHider={verifyPersonalHider}
                                 verifyContactHider={verifyContactHider}
                                 verifyPhoneHider={verifyPhoneHider}
                                  verifyJointPersonalHider={verifyJointPersonalHider}
                                  verifyJointContactHider={verifyJointContactHider}
                                  verifyJointPhoneHider={verifyJointPhoneHider}
                         
                            />

                            <EmailDetails
                                ref={this.secondaryEmailDetailsRef}
                                savedState={secondaryEmailDetailsData}
                                // isLoading={initialState.isLoading}
                                emailType="Secondary"
                                isJoint={isJoint}
                                savedEmailAddress={secondaryEmailAddress}
                                index={index}
                                type="secondary"
                                isReadOnly={isReadOnly && this.props.jointInfo !="Yes" && hasSecondaryEmailAddress}
                                flowFlag = {"MarketNAO"}
                                handleEmailDetails = {this.handleEmailDetails}
                                verifyPersonalHider={verifyPersonalHider}
                                 verifyContactHider={verifyContactHider}
                                verifyPhoneHider={verifyPhoneHider}
                                    verifyJointPersonalHider={verifyJointPersonalHider}
                                    verifyJointContactHider={verifyJointContactHider}
                                    verifyJointPhoneHider={verifyJointPhoneHider}                            />
                            </Card.Body>
                        </div>
                    </Card>
                </Accordian>
            </>
        );
    }
}

MarketPhoneDetailsCardComponent.propTypes = {
    isJoint: PropTypes.bool,
    contactTimeOp: PropTypes.instanceOf(Array),
    primaryPhoneNumber: PropTypes.string,
    secondaryPhoneNumber: PropTypes.string,
    primaryContactTime: PropTypes.string,
    secondaryContactTime: PropTypes.string,
    primaryIsMobile: PropTypes.bool,
    secondaryIsMobile: PropTypes.bool,
    jointText: PropTypes.string,
    index: PropTypes.number,
    primaryCountryCode: PropTypes.string,
    secondaryCountryCode: PropTypes.string,
    savedState: PropTypes.instanceOf(Object),
    isSuccessor: PropTypes.bool,
    mPrimaryPhoneNumber: PropTypes.string,
    mSecondaryPhoneNumber: PropTypes.string,
    isPrimaryNumberChanged: PropTypes.bool,
    isSecondaryNumberChanged: PropTypes.bool,
};

MarketPhoneDetailsCardComponent.defaultProps = {
    isJoint: false,
    contactTimeOp: [],
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    primaryContactTime: '',
    secondaryContactTime: '',
    primaryIsMobile: false,
    secondaryIsMobile: false,
    jointText: '',
    index: 100,
    primaryCountryCode: '',
    secondaryCountryCode: '',
    savedState: {},
    isSuccessor: false,
    mPrimaryPhoneNumber: '',
    mSecondaryPhoneNumber: '',
    isPrimaryNumberChanged: false,
    isSecondaryNumberChanged: false,
};

export default MarketPhoneDetailsCardComponent;
