const fontColor = 'rgba(73, 73, 74, 1)';

export default {
    ADDRESS_VALID_ERROR: 'Sorry, we are unable to verify address.',
    USPS_ALERT_MESSAGE: 'Please select on USPS Recommendation address to proceed further.',
    MAIL_ADDRESS: 'Mailing Address Type',
    PHYSICAL_ADDRESS: 'Physical Address Type',
    ADDRESS_TYPE: "Address Type",
    ADDRESS2_HINT: 'Please enter Address Line 1',
    ADDRESS_HINT: 'Please enter Address Line 2',
    ADDRESS_ERROR: 'Please enter Address information',
    ZIP: 'Please enter Zipcode',
    CITY: 'City & State',
    SELECT: 'Select',
    CITY_HINT: 'Please enter City',
    STATE_HINT: 'Please enter State',
    MAIL_ADDRESS_HINT: 'Please select an AddressType',
    uspsCardStyle: {
        padding: '36px 30px 39px',
    },
    uspsCardSubText:
        'We have slightly modified the address entered. Please select USPS Recommended format to ensure accurate delivery',
    uspsCardHeaderText: 'USPS Recommendation',
    uspsCardHeaderTextStyle: {
        textAlign: 'left',
        font: '700 18px/22px benton-sans',
        letterSpacing: 0,
        color: fontColor,
        opacity: 1,
    },
    uspsCardSubTextStyle: {
        textAlign: 'left',
        font: '500 16px/20px benton-sans',
        letterSpacing: 0,
        color: fontColor,
        opacity: 1,
        margin: 0,
    },
    uspsButtonText: 'Use this address',
    uspsCardTextStyle: {
        textAlign: 'left',
        font: '600  18px/22px benton-sans',
        letterSpacing: 0,
        color: fontColor,
        opacity: 1,
        textTransform: 'uppercase',
        marginTop: 22,
        marginBottom: 38,
        width: '100%',
    },
    uspsErrorMessage: 'Please click on "USPS Recommendation" address to proceed further.',
    uspsVerifyingAddressText: 'We are verifying your address...',
    uspsVerifyingAddressTitle: 'One moment, please.',
    uspsAddresMismatchError: 'Please select a valid address for ',
    uspsStateNameError:"Joint Tenants with Rights of Survivorship accounts are not recognized in Louisiana or Puerto Rico. Please enter another address or select a different account type."
};
