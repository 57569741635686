import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { StyledButton } from 'common';
import addIcon from 'assets/add.svg';

export const ContentWrap = styled.div`
    border:1px solid #ddd;
    min-height: ${props => props.minHeight || 0}rem;
    font: 1.125em yorkten-slab-extra-bold;
    color: #486D90;
    height: 100%;
`;

const AddCardLink = styled(StyledButton)`
    color:#004A98;
`;

const AddCard = props => {
    const {actionIconAlt, addTxt, onAddCardClick, minHeight } = props; 
    return (
        <ContentWrap minHeight={minHeight}  className="d-flex justify-content-center align-items-center">
            <AddCardLink onClick={onAddCardClick}> 
                <img src={addIcon} className="mr-2" alt={actionIconAlt} />{addTxt}
            </AddCardLink>
        </ContentWrap>
    )
}

AddCard.propTypes = {
    addTxt: PropTypes.string,
    minHeight: PropTypes.oneOfType([ PropTypes.string, PropTypes.number,]),
    onAddCardClick: PropTypes.func,
    actionIconAlt: PropTypes.string,
};

AddCard.defaultProps = {
    actionIconAlt: '',
    addTxt: '',
    minHeight: 0,
    onAddCardClick: ()=>{},
};

export default AddCard;