import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import Styles from './Styles';
import WCardDashboardOverlay from '../Common/WCardDashboardOverlay/WCardDashboardOverlay';
import SideHeader from '../Common/SideHeaderMain/SideHeader';
import {  Col, Row, Nav, Button, Collapse, Tab} from 'react-bootstrap';
import iconAllocation from '../../../assets/icon_allocation.png';
import traingleicon from '../../../assets/alert.png';
import styles from './accountstyle';
import infoIcon from '../../../assets/info.jpg';
import piggyIcon from '../../../assets/piggy.jpg';
import RightArrowIcon from '../../../assets/RightArrow.png';
import DButton from '../Common/DButton/DButton';
import currencyFormatter from 'currency-formatter';
import SimpleHighChart from './SimpleHighChart';
import HighChartForOverview from './HighChartForOverview';
import HighChartErrorBoundary from './HighChartErrorBoundary';
import AccordionContent from '../Common/AccordionContent/AccordionContent';
import { isEmpty } from 'lodash';
import AccordionTitleContent from '../Common/AccordionTitleContent/AccordionTitleContent';
import { Redirect } from 'react-router-dom';
import { manageLocalState } from '../../../shared/Actions/LocalStateManagementActions';
import { useDispatch } from 'react-redux';
import guestCardData from '../GuestView/GuestViewDashboardConstants';
import StyledToolTip from './StyledToolTip';
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';
import routeConstants from 'routeConstants';
import moment from 'moment';
import call from '../../../assets/call.png';
import Inbox from '../../../assets/inbox.png';
import styled from 'styled-components';
import { analyzeClickEvent } from '../../../shared/Actions/AnalyticsActions';
import { getMarketSavedApplications } from 'shared/Actions/AccOpeningActions';
import { getToken } from 'utils';
import envConfig from 'env/EnvConfig';
import {unavailableText} from "./Constants"
  const SavedLink = styled(Link)`
  &:focus {
      outline: 2px solid #000000;
      outline-offset: 0px;
  }
`


export const diableLink = {
    opacity:0.6, color:'gray',pointer:'none'
}


export const assignObj = obj => obj;

//if the data get undefined this method will return text
export const responceData=(responceData)=>{
    if(responceData){
        return responceData.replace('<p>','').replace('</p>','')
    }else{
        return unavailableText
    }
}



    
export const formattedPhoneNumber = (phoneNumber) =>
{
    if(phoneNumber.includes('+1') == true)
    {
        const splitPhoneNumber = phoneNumber.split('+1');
        const withoutPlusOnePhoneNumber = splitPhoneNumber[1].trim();
        let finalPhoneNumber = '';
        for(let i=0;i<withoutPlusOnePhoneNumber.length;i++)
        {
            if( i == 3 || i == 6)
            {
                finalPhoneNumber = finalPhoneNumber + `.${withoutPlusOnePhoneNumber[i]}`;
            }
            else
            {
                finalPhoneNumber = finalPhoneNumber + `${withoutPlusOnePhoneNumber[i]}`;
            }
        }
        return finalPhoneNumber;
    }
    else
    {
        const trimPhoneNumber = phoneNumber.trim();
        let finalPhoneNumber = '';
        for(let i=0;i<trimPhoneNumber.length;i++)
        {
            if( i == 3 || i == 6)
            {
                finalPhoneNumber = finalPhoneNumber + `.${trimPhoneNumber[i]}`;
            }
            else
            {
                finalPhoneNumber = finalPhoneNumber + `${trimPhoneNumber[i]}`;
            }
        }
        return finalPhoneNumber;
    }
}

export const WealthinformationComponent = (props) => {

    const {isHNWDataObj, wealthInformationData, dashbordContentData} = props

    return(
        <>
            {(isHNWDataObj?.isHNWMember == true &&
                ((wealthInformationData?.wealthManagerInformation?.firstName !=null &&  wealthInformationData?.wealthManagerInformation?.firstName !="") || 
                (wealthInformationData?.wealthManagerInformation?.lastName != null && wealthInformationData?.wealthManagerInformation?.lastName != ""))) && 
                        <>
                        <div className='righttopleftheader' style={{  backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.HNW_WealthAdvisor_bgImage_Desktop + ")" }}>
                           
                        </div>

                        <div className='tabrighttopleftheader' style={{  backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.HNW_WealthAdvisor_bgImage_Tab + ")" }}>
                           
                        </div>
                       
                        <div className='righttopheader'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <p className='text-white text-right mb-0 righttopheadertext'>
                                    Your Wealth Manager is: <span style={{marginLeft:'20px', fontWeight: 'bold'}}>{wealthInformationData?.wealthManagerInformation?.firstName} {wealthInformationData?.wealthManagerInformation?.lastName}
                                    {wealthInformationData?.wealthManagerInformation?.professionalDesignation && 
                                    <>
                                    , {wealthInformationData?.wealthManagerInformation?.professionalDesignation}
                                    </>

                                    }</span><br/>
                                    {wealthInformationData?.wealthManagerInformation?.email && 
                                    <>
                                    <a href={`mailto:${wealthInformationData?.wealthManagerInformation?.email}`} style={{color: '#fff',textDecoration:"none"}}>  <img src={Inbox} style={{height:'17px',marginRight: '10px'}}/> {wealthInformationData?.wealthManagerInformation?.email}</a>
                                    </>
                                    }

                                    {wealthInformationData?.wealthManagerInformation?.phoneNumber && 
                                     <span className='resPhoneNumber' style={{marginLeft:'20px'}}>
                                        <a href={`tel:${formattedPhoneNumber(wealthInformationData?.wealthManagerInformation?.phoneNumber)}`} style={{color: '#fff',textDecoration:"none"}}><img src={call} style={{height:'17px',marginRight: '10px'}}/> {formattedPhoneNumber(wealthInformationData?.wealthManagerInformation?.phoneNumber)}</a>
                                    </span>
                                    }
                                   
                                </p>
                            </div>
                        </div>
                        </>
                        
                        }
        </>
    )
}

export const VictoryFundsTabComponent = (props) => {
    
    const {systemHealth, mutualFundAccountSuccessResponse, unavailableAPIErrorResponse, noAccountView, testGraphData, fundSimpleChart, tooltipOpen, toggleToolTip, closeTooltip, tabKey } = props

    return(
       <>
            {((props.roleInfo != "Member" && systemHealth.fis != 'up') || (props.roleInfo == 'Member' && (mutualFundAccountSuccessResponse == false)))  ?
                <>
                    {unavailableAPIErrorResponse('Victory Funds')}
                </> :
                <>
                    {((props.roleInfo != "Member") || props.dashboardApiResponseData?.isDashbordError == true)
                    ? (
                        <>
                        {noAccountView('victory funds')}
                        </>
                    ) : (
                    <div className='APIAvailable'>
                    
                    {!isEmpty(props.mfaccountsData) ? (
                        <div className="accountBox">
                            <>
                            <div class="row">
                                <div className="col-12 col-md-7 col-lg-7  balancetext">
                                <div className='row'>
                                    <div className='col-xl-3  col-lg-3  col-md-3 col-3 smallGraph' style={{height:'70px'}}>
                                    <div className="imageComponent1" style={{width:"100%", height:'70px'}}>
                                    {props.dashboardApiResponseData?.accountSummary !=null && 
                                    <SimpleHighChart
                                        mapData={testGraphData}
                                        totalAmount={currencyFormatter.format(100, {
                                            locale: 'en-US',
                                        })}
                                        handleChartLoad={props.onChartLoad}
                                        holdingGroupComposition = {'simpleHoldingGroupCompositionFunds'}
                                        chartTitleCloned = {'simpleChartTitleClonedFunds'}
                                        colorsArray = {fundSimpleChart}
                                    />

                                    }
                                    
                                    </div>
                                    </div>

                                    <div className='col-xl-9 col-lg-9 col-md-9 pl-0 col-9'>
                                    <div className="amount newamount" style={styles.pullLeftMargin}>
                                        <p className="mb-0 amountnew" style={styles.amountnew}>
                                                {
                                                    props.graphPlottingValues.length > 0
                                                    ? currencyFormatterFunction(props.dashboardApiResponseData?.accountSummary?.accountDetails.mutualFund.total)
                                                    : ''
                                                }
                                        </p>
                                        <span className="titleText titletextnew" style={styles.titleTxtnew}>
                                                {
                                                    props.graphPlottingValues.length > 0
                                                    ? props.NewAmountTitle + props.latestAggregatedBalanceDate + '*'
                                                : ''
                                                }
                                        </span>
                                    </div>
                                    </div>

                                </div>
                                
                                    
                                </div>

                                
                                <div className="clear" style={styles.clear} />
                            
                                <div className="col-xl-5 col-md-5 col-lg-5 col-sm-12 fundsName text-right">
                                    <h2 className='accountsName' style={styles.AccountsName}>Victory Funds Accounts<sup><img id="infoIcon" onMouseOut={closeTooltip} src={infoIcon} alt="" style={{cursor:"pointer"}} /></sup></h2>
                                
                                    <StyledToolTip
                                        id="Fundtooltip"
                                        placement="top"
                                        tooltipOpen={tooltipOpen}
                                        targetID="infoIcon"
                                        handleToggle={toggleToolTip}
                                        autohide={true}
                                        triggerEvt="hover focus"
                                        tooltipText={'Explainer text for what this account type title means'}
                                        closeTooltip={closeTooltip}
                                        closeTabKey={tabKey}
                                    />
                            </div>
                                    
                            </div>
                
                            <div className="rowgroup mt-2 mb-5 mobaccountslistBox" > 
                                <div
                                    id="holdingGroupDetails_desc"
                                    style={assignObj({ display: 'none' })}
                                >
                                    {props.holdingGroupDescription}
                                </div>
                                <div
                                    className="holding-group-main"
                                    style={assignObj({
                                        font: '500 14px/22px benton-sans,sans-serif',
                                        color: 'rgb(73, 73, 74)',
                                    })}
                                >
                                    
                                    <div role="rowgroup">
                                        
                                        {props.mfaccountsData.length > 0 &&
                                            props.mfaccountsData.map((item, index) => {
                                                const accordionContentId = `accordionContentRegion${index}`;
                                                const accordionId = `accordionTitle${index}`;
                                                return (                                                                    
                                                    <div style={{marginTop:'20px'}} key={index}>
                                                            
                                                        <Col>
                                                            <Row>
                                                                <Col md={8} sm={12}>
                                                                    <Button
                                                                        onClick={props.handleAccordionClick(
                                                                            index,
                                                                            item.open,
                                                                            'mf-account'
                                                                        )}
                                                                        aria-controls={
                                                                            accordionContentId
                                                                        }
                                                                        aria-expanded={item.open}
                                                                        variant="h3"
                                                                        id={accordionId}
                                                                        className="d-flex justify-content-between w-100 px-0  mb-2 mt-2"
                                                                        role="row"
                                                                        style={{lineHeight:'normal'}}
                                                                    >

                                                                        <AccordionTitleContent.Title
                                                                            accType={assignObj({
                                                                                holdingGroup:
                                                                                    item.groupName,
                                                                                balance:
                                                                                    item.groupTotal,
                                                                                c: item.c,
                                                                                r: item.r,
                                                                                open: item.open,
                                                                            })}
                                                                            additionalStyles={{color:'black',fontWeight:700,fontSize:'20px'}}

                                                                        />
                                                                    {   <AccordionTitleContent.NewBalance
                                                                            accType={assignObj({
                                                                                holdingGroup:
                                                                                    item.groupName,
                                                                                balance:
                                                                                    item.groupTotal,
                                                                                c: item.c,
                                                                                r: item.r,
                                                                                open: item.open,
                                                                            })}
                                                                            
                                                                        
                                                                        />}

                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                            <Collapse
                                                                in={item.open}
                                                                className="holding-sub-group w-100"
                                                            >
                                                                <div id={accordionContentId}>                                                                           
                                                                <AccordionContent
                                                                isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}

                                                                showModel={props.showModel}
                                                                handleShow={props.handleShow}
                                                                accountDetailsData={props.customerProfile}
                                                                investement={item.accounts}
                                                                getGeneratedSAMLAssertion={
                                                                    props.onSingleSignOnClick
                                                                }
                                                                getDashBoardAccordianLinks={
                                                                    props.getDashBoardAccordianLinks
                                                                }
                                                            
                                                                history={props.history}
                                                                handleAscensusClick={
                                                                    props.handleAscensusClick
                                                                }
                                                                onClickPopupRedirecton={props.onClickPopupRedirecton}
                                                                isFundsTab = {true}
                                                                getPostionData = {props.getPostionData}

                                                            />
                                                                </div>
                                                            </Collapse>
                                                        </Col>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center tabFooterbox">
                                    <ul className='smallLeftboxText'>
                                        <li>Victory Funds Accounts Docs Link</li>
                                        <li>Victory Funds Accounts Links 2</li>
                                        <li>Other Victory Funds Accounts Links</li>
                                    </ul>
                                </div>
                            </div>

                            </>

                    
                        </div>
                        ) 
                        : 
                        noAccountView('victory funds')
                        } 
                
                    </div>
                    )}
                </>

            }
       </>
    )
}


const MarketplaceAccountComponent = (props) => {
    const {systemHealth, brokerageAccountSuccessResponse, unavailableAPIErrorResponse, noAccountView, testGraphData, marketSimpleChart, tooltipOpenForMarket, toggleToolTipForMarket, closeTooltip, tabKey } = props

    return(
        <>
            {((props.roleInfo != "Member" && systemHealth.pershing != 'up') || (props.roleInfo == 'Member' && ( brokerageAccountSuccessResponse == false)))  ? 
                <>

                    {unavailableAPIErrorResponse('Marketplace')}
                
                </>:
                <>
                    {((props.roleInfo != "Member") || props.dashboardApiResponseData?.isDashbordError == true)
                    ? (
                        <>
                        {noAccountView('marketplace')}
                        </>
                    ) : (
                    
                    <div className='APIAvailable'>
                        {!props.isError && !isEmpty(props.braccountsData) ? (

                        <div className="accountBox">
                            
                            {/* Market funds lists starts here */}
                                    <>
                                    <div class="row">
                                    <div className="col-12 col-md-7 col-lg-7 col-sm-12  balancetext">
                                        <div className='row'>
                                            <div className='col-xl-3  col-lg-3  col-md-3 col-3 smallGraph' style={{height:'70px'}}>
                                            <div className="imageComponent1" style={{width:"100%", height:'70px'}}>
                                            {props.dashboardApiResponseData?.accountSummary !=null && 
                                                <SimpleHighChart
                                                mapData={testGraphData}
                                                totalAmount={currencyFormatter.format(100, {
                                                    locale: 'en-US',
                                                })}
                                                handleChartLoad={props.onChartLoad}
                                                holdingGroupComposition = {'simpleHoldingGroupCompositionMarkets'}
                                                chartTitleCloned = {'simpleChartTitleClonedMarkets'}
                                                colorsArray = {marketSimpleChart}
                                            />
                                            }
                                                
                                            </div>
                                            </div>
                                            <div className='col-xl-9 col-lg-9 col-md-9 pl-0 col-9'>
                                            <div className="amount newamount" style={styles.pullLeftMargin}>
                                                <p className="mb-0 amountnew" style={styles.amountnew}>
                                                    {!props.isError 
                                                        ? currencyFormatterFunction(props.dashboardApiResponseData?.accountSummary?.accountDetails.brokerage.total)
                                                        : ''}
                                                </p>
                                                <span className="titleText titletextnew" style={styles.titleTxtnew}>
                                                    {!props.isError
                                                        ? props.NewAmountTitle + props.latestAggregatedBalanceDate + '*'
                                                        : ''}
                                                </span>
                                            </div>
                                            </div>
                                            </div>
                                            <div className="clear" style={styles.clear} />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-12  fundsName text-right">
                                                                            <h2 className='accountsName' style={styles.AccountsName}>Marketplace Accounts<sup><img id="infoIcon" onMouseOut={closeTooltip} src={infoIcon} alt="" style={{ cursor: "pointer" }} /></sup></h2>
                                            {/* <div onClick={() => props.placeATradeonSingleSignOnClick({NEW_ACCOUNT: "newaccount"})}> */}
                                            <div>
                                                                                <Link className='cardLink mcardLink' to={routeConstants.marketOpenAccount} style={{ color: '#004A98', fontSize: '14px', fontWeight: '600' }}><img src={piggyIcon} className="piggyIcon mr-1" />Open a new Marketplace account <img src={RightArrowIcon} style={{ width: '10px', height: 'auto' }} className="ml-2" /> </Link>
                                            </div>
                                            <StyledToolTip
                                        id="Markettooltip"
                                        placement="top"
                                        tooltipOpen={tooltipOpenForMarket}
                                        targetID="infoIcon"
                                        handleToggle={toggleToolTipForMarket}
                                        autohide={true}
                                        triggerEvt="hover focus"
                                        tooltipText={'Explainer text for what this account type title means'}
                                        closeTooltip={closeTooltip}
                                        closeTabKey={tabKey}
                                    />
                                        </div>
                                    </div>
                                    
                                    <div className="rowgroup mt-2 mb-5 mobaccountslistBox" > 
                            
                                        <div
                                            id="holdingGroupDetails_desc"
                                            style={assignObj({ display: 'none' })}
                                        >
                                            {props.holdingGroupDescription}
                                        </div>
                                        <div
                                            className="holding-group-main"
                                            style={assignObj({
                                                font: '500 14px/22px benton-sans,sans-serif',
                                                color: 'rgb(73, 73, 74)',
                                            })}
                                        >
                                            <div role="rowgroup">
                                                {props.braccountsData.length > 0 &&
                                                    props.braccountsData.map((item, index) => {
                                                        const accordionContentId = `accordionContentRegion${index}`;
                                                        const accordionId = `accordionTitle${index}`;
                                                        return (                                                                    
                                                            <div style={{marginTop:'20px'}} key={index}>
                                                                
                                                                <Col className="">
                                                                    <Row>
                                                                        <Col md={8} sm={12}>
                                                                            <Button
                                                                                onClick={props.handleAccordionClick(
                                                                                    index,
                                                                                    item.open,
                                                                                    'br-account'
                                                                                )}
                                                                                aria-controls={
                                                                                    accordionContentId
                                                                                }
                                                                                aria-expanded={item.open}
                                                                                variant="h3"
                                                                                // as="h3"
                                                                                id={accordionId}
                                                                                // tabIndex="-1"
                                                                                className="d-flex justify-content-between w-100 px-0"
                                                                                role="row"
                                                                            >

                                                                                <AccordionTitleContent.Title
                                                                                    accType={assignObj({
                                                                                        holdingGroup:
                                                                                            item.groupName,
                                                                                        balance:
                                                                                            item.groupTotal,
                                                                                        c: item.c,
                                                                                        r: item.r,
                                                                                        open: item.open,
                                                                                    })}
                                                                                    additionalStyles={{color:'black',fontWeight:700,fontSize:'18px'}}

                                                                                />
                                                                            {   <AccordionTitleContent.NewBalance
                                                                                    accType={assignObj({
                                                                                        holdingGroup:
                                                                                            item.groupName,
                                                                                        balance:
                                                                                            item.groupTotal,
                                                                                        c: item.c,
                                                                                        r: item.r,
                                                                                        open: item.open,
                                                                                    })}
                                                                                
                                                                                />}

                                                                            </Button>
                                                                        </Col>
                                                                    </Row>

                                                                    <Collapse
                                                                        in={item.open}
                                                                        className="holding-sub-group w-100"
                                                                    >
                                                                        <div id={accordionContentId}>                                                                           
                                                                        <AccordionContent
                                                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}

                                                                        showModel={props.showModel}
                                                                        handleShow={props.handleShow}
                                                                        accountDetailsData={props.customerProfile}
                                                                        investement={item.accounts}
                                                                                holdingGroup={item.groupName}
                                                                                isNewBrokerageTab={true}
                                                                                placeATradeonSingleSignOnClick={props.placeATradeonSingleSignOnClick}
                                                                        getGeneratedSAMLAssertion={
                                                                            props.onSingleSignOnClick
                                                                        }
                                                                                handleApplicationClosedPopUp={props.handleApplicationClosedPopUp}
                                                                        getDashBoardAccordianLinks={
                                                                            props.getDashBoardAccordianLinks
                                                                        }
                                                                    
                                                                        history={props.history}
                                                                        handleAscensusClick={
                                                                            props.handleAscensusClick
                                                                        }
                                                                        onClickPopupRedirecton={props.onClickPopupRedirecton}
                                                                        isMarketTab = {true}
                                                                        accountActivityHider="accountActivityHider"
                                                                    />
                                                                        </div>
                                                                    </Collapse>
                                                                </Col>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-4">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center tabFooterbox">
                                            <ul className='smallLeftboxText'>
                                                <li>Victory Funds Accounts Docs Link</li>
                                                <li>Victory Funds Accounts Links 2</li>
                                                <li>Other Victory Funds Accounts Links</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </>
                                
                        </div>
                        )
                        :
                        noAccountView('marketplace') 
                    } 
                    </div>
                    )}
                </>
            }
        </>
    )
}



const EducationAccountComponent = (props) => {

    const {systemHealth, educationAccountSuccessResponse, unavailableAPIErrorResponse, noAccountView, testGraphData, educationSimpleChart, tooltipOpenForEducation, toggleToolTipForEducation, closeTooltip } = props

    return(
        <>
            {((props.roleInfo != "Member" && systemHealth.ascensus != 'up') || (props.roleInfo == 'Member' && (educationAccountSuccessResponse == false)))  ? 
                <>
                    {unavailableAPIErrorResponse('Education')}
            
                </>:
                <>
                {((props.roleInfo != "Member") || props.dashboardApiResponseData?.isDashbordError == true)
                ? (
                    <>
                    {noAccountView('education')}
                    </>
                ) : (
                <div className='APIAvailable'>
                {!props.isError && !isEmpty(props.childrenAccountsData) ? (

                <div className="accountBox">
                
                            {/* Education funds lists starts here */}
                            <>

                            <div class="row">
                                <div className="col-12 col-md-7 col-lg-7 col-sm-12  balancetext" >
                                    <div className='row'>
                                        <div className='col-xl-3  col-lg-3  col-md-3 col-3 smallGraph' style={{height:'70px'}}>
                                        <div className="imageComponent1" style={{width:"100%", height:'70px'}}>
                                        {props.dashboardApiResponseData?.accountSummary !=null && 
                                                <SimpleHighChart
                                                    mapData={testGraphData}
                                                    totalAmount={currencyFormatter.format(100, {
                                                        locale: 'en-US',
                                                    })}
                                                    handleChartLoad={props.onChartLoad}

                                                    holdingGroupComposition = {'simpleHoldingGroupCompositionEducation'}
                                                    chartTitleCloned = {'simpleChartTitleClonedEducation'}
                                                    colorsArray = {educationSimpleChart}
                                                />
                                        }
                                            </div>
                                        </div>
                                        <div className='col-xl-9 col-lg-9 col-md-9 pl-0 col-9'>
                                        <div className="amount newamount" style={styles.pullLeftMargin}>
                                            <p className="mb-0 amountnew" style={styles.amountnew}>
                                                {!props.isError
                                                // && props.graphPlottingValues.length > 0
                                                    ? currencyFormatterFunction(props.dashboardApiResponseData?.accountSummary?.accountDetails.children.total)
                                                    : ''}
                                            </p>
                                            <span className="titleText titletextnew" style={styles.titleTxtnew}>
                                                {!props.isError 
                                                //&& props.graphPlottingValues.length > 0
                                                    ? props.NewAmountTitle +  props.latestAggregatedBalanceDate + '*'
                                                    : ''}
                                            </span>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="clear" style={styles.clear} />
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-sm-12  fundsName text-right">
                                        <h2 className='accountsName' style={styles.AccountsName}>Education Accounts<sup><img id="infoIcon" onMouseOut={closeTooltip} src={infoIcon} alt="" style={{cursor:"pointer"}}/></sup></h2>
                                    
                                        <StyledToolTip
                                            id="educationTooltip"
                                            placement="top"
                                            tooltipOpen={tooltipOpenForEducation}
                                            targetID="infoIcon"
                                            handleToggle={toggleToolTipForEducation}
                                            autohide={false}
                                            triggerEvt="hover focus"
                                            tooltipText={'Explainer text for what this account type title means'}
                                            
                                        />
                                        
                                        </div>
                            </div>

                            <div className="rowgroup mt-2 mb-5 mobaccountslistBox" > 
                                <div
                                    id="holdingGroupDetails_desc"
                                    style={assignObj({ display: 'none' })}
                                >
                                    {props.holdingGroupDescription}
                                </div>
                                <div
                                    className="holding-group-main"
                                    style={assignObj({
                                        font: '500 14px/22px benton-sans,sans-serif',
                                        color: 'rgb(73, 73, 74)',
                                    })}
                                >
                                    <div role="rowgroup">
                                        {props.childrenAccountsData.length > 0 &&
                                            props.childrenAccountsData.map((item, index) => {
                                                const accordionContentId = `accordionContentRegion${index}`;
                                                const accordionId = `accordionTitle${index}`;
                                                return (                                                                    
                                                    <div style={{marginTop:'20px'}}>
                                                        <Col className="">
                                                            <Row>
                                                                <Col md={8} sm={12}>
                                                                    <Button
                                                                        onClick={props.handleAccordionClick(
                                                                            index,
                                                                            item.open,
                                                                            'ch-account'
                                                                        )}
                                                                        aria-controls={
                                                                            accordionContentId
                                                                        }
                                                                        aria-expanded={item.open}
                                                                        variant="h3"
                                                                        id={accordionId}
                                                                        className="d-flex justify-content-between w-100 px-0"
                                                                        role="row"
                                                                    >

                                                                        <AccordionTitleContent.Title
                                                                            accType={assignObj({
                                                                                holdingGroup:
                                                                                    item.groupName,
                                                                                balance:
                                                                                    item.groupTotal,
                                                                                c: item.c,
                                                                                r: item.r,
                                                                                open: item.open,
                                                                            })}
                                                                            additionalStyles={{color:'black',fontWeight:700,fontSize:'18px'}}

                                                                        />
                                                                        {   <AccordionTitleContent.NewBalance
                                                                            accType={assignObj({
                                                                                holdingGroup:
                                                                                    item.groupName,
                                                                                balance:
                                                                                    item.groupTotal,
                                                                                c: item.c,
                                                                                r: item.r,
                                                                                open: item.open,
                                                                            })}
                                                                            
                                                                        />}

                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                            <Collapse
                                                                in={item.open}
                                                                className="holding-sub-group w-100"
                                                            >
                                                                <div id={accordionContentId}>                                                                           
                                                                <AccordionContent
                                                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}
                                                                showModel={props.showModel}
                                                                handleShow={props.handleShow}
                                                                accountDetailsData={props.customerProfile}
                                                                // amount={item.groupTotal}
                                                                investement={item.accounts}
                                                                getGeneratedSAMLAssertion={
                                                                    props.onSingleSignOnClick
                                                                }
                                                                getDashBoardAccordianLinks={
                                                                    props.getDashBoardAccordianLinks
                                                                }
                                                                
                                                                history={props.history}
                                                                handleAscensusClick={
                                                                    props.handleAscensusClick
                                                                }
                                                                onClickPopupRedirecton={props.onClickPopupRedirecton}
                                                                isEducationTab = {true}
                                                                        holdingGroup={item.groupName}
                                                                dashboardOpenAnAccount={props.dashboardOpenAnAccount}
                                                                        handleEducationApplicationClosedPopUp={props.handleEducationApplicationClosedPopUp}
                                                            />
                                                                </div>
                                                            </Collapse>
                                                        </Col>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center tabFooterbox">
                                    <ul className='smallLeftboxText'>
                                        <li>Education Accounts Docs Link</li>
                                        <li>Education Accounts Links 2</li>
                                        <li>Other Education Accounts Links</li>
                                    </ul>
                                </div>
                            </div>
                            </>
                            
                        
                </div>
                )
                :
                noAccountView('education')
                } 
                </div>
                
                
            )}
                </>

            }

        </>
    )
}



const DynamicAdvisorComponent = (props) => {

    const {systemHealth, dynamicAdvisorAccountSuccessResponse, unavailableAPIErrorResponse, noAccountView, testGraphData, dynamicadvisorSimpleChart, tooltipOpenForMarket, toggleToolTipForMarket, closeTooltip, tabKey, } = props

    return(<>
        {((props.roleInfo != "Member" && systemHealth.pershing != 'up') || (props.roleInfo == 'Member' && (dynamicAdvisorAccountSuccessResponse == false)))  ? 
        <>
            {unavailableAPIErrorResponse('Dynamic Advisor')}
        </> :
        <>
            {((props.roleInfo != "Member") || props.dashboardApiResponseData?.isDashbordError == true)
            ? (
                <>
                {noAccountView('dynamic advisor')}
                </>
            ) : (          
            <div className='APIAvailable'>
                {!props.isError && !isEmpty(props.daAccountsData) ? (

                <div className="accountBox">
                    
                    {/* Market funds lists starts here */}
                    <div class="row">
                        <div className="col-12 col-md-7 col-lg-7 col-sm-12  balancetext">
                            <div className='row'>
                                <div className='col-xl-3  col-lg-3  col-md-3 col-3 smallGraph' style={{height:'70px'}}>
                                <div className="imageComponent1" style={{width:"100%", height:'70px'}}>
                                {props.dashboardApiResponseData?.accountSummary !=null && 
                                    <SimpleHighChart
                                    mapData={testGraphData}
                                    totalAmount={currencyFormatter.format(100, {
                                        locale: 'en-US',
                                    })}
                                    handleChartLoad={props.onChartLoad}
                                    holdingGroupComposition = {'simpleHoldingGroupCompositionDynamicAdvisor'}
                                    chartTitleCloned = {'simpleChartTitleClonedDynamicAdvisor'}
                                    colorsArray = {dynamicadvisorSimpleChart}
                                />
                                }
                                    
                                </div>
                                </div>
                                <div className='col-xl-9 col-lg-9 col-md-9 pl-0 col-9'>
                                <div className="amount newamount" style={styles.pullLeftMargin}>
                                    <p className="mb-0 amountnew" style={styles.amountnew}>
                                        {!props.isError 
                                            ? currencyFormatterFunction(props.dashboardApiResponseData?.accountSummary?.accountDetails.dynamicAdvisor.total)
                                            : ''}
                                    </p>
                                    <span className="titleText titletextnew" style={styles.titleTxtnew}>
                                        {!props.isError
                                            ? props.NewAmountTitle + props.latestAggregatedBalanceDate + '*'
                                            : ''}
                                    </span>
                                </div>
                                </div>
                                </div>
                                <div className="clear" style={styles.clear} />
                            </div>
                        <div className="col-md-5 col-lg-5 col-sm-12  fundsName text-right">
                            <h2 className='accountsName' style={styles.dynamicName}>Dynamic Advisor Accounts<sup><img id="infoIcon" onMouseOut={closeTooltip} src={infoIcon} alt="" style={{ cursor: "pointer" }} /></sup></h2>
                            
                            <div></div>
                            <StyledToolTip
                            id="Markettooltip"
                            placement="top"
                            tooltipOpen={tooltipOpenForMarket}
                            targetID="infoIcon"
                            handleToggle={toggleToolTipForMarket}
                            autohide={true}
                            triggerEvt="hover focus"
                            tooltipText={'Explainer text for what this account type title means'}
                            closeTooltip={closeTooltip}
                            closeTabKey={tabKey}
                            />
                        </div>
                    </div>
                    
                    <div className="rowgroup mt-2 mb-5 mobaccountslistBox" > 
            
                        <div
                            id="holdingGroupDetails_desc"
                            style={assignObj({ display: 'none' })}
                        >
                            {props.holdingGroupDescription}
                        </div>
                        <div
                            className="holding-group-main"
                            style={assignObj({
                                font: '500 14px/22px benton-sans,sans-serif',
                                color: 'rgb(73, 73, 74)',
                            })}
                        >
                            <div role="rowgroup">
                                {props.daAccountsData.length > 0 &&
                                    props.daAccountsData.map((item, index) => {
                                        const accordionContentId = `accordionContentRegion${index}`;
                                        const accordionId = `accordionTitle${index}`;
                                        return (                                                                    
                                            <div style={{marginTop:'20px'}} key={index}>
                                                
                                                <Col className="">
                                                    <Row>
                                                        <Col md={8} sm={12}>
                                                            <Button
                                                                onClick={props.handleAccordionClick(
                                                                    index,
                                                                    item.open,
                                                                    'da-account'
                                                                )}
                                                                aria-controls={
                                                                    accordionContentId
                                                                }
                                                                aria-expanded={item.open}
                                                                variant="h3"
                                                                // as="h3"
                                                                id={accordionId}
                                                                // tabIndex="-1"
                                                                className="d-flex justify-content-between w-100 px-0"
                                                                role="row"
                                                            >

                                                                <AccordionTitleContent.Title
                                                                    accType={assignObj({
                                                                        holdingGroup:
                                                                            item.groupName,
                                                                        balance:
                                                                            item.groupTotal,
                                                                        c: item.c,
                                                                        r: item.r,
                                                                        open: item.open,
                                                                    })}
                                                                    additionalStyles={{color:'black',fontWeight:700,fontSize:'18px'}}

                                                                />
                                                            {   <AccordionTitleContent.NewBalance
                                                                    accType={assignObj({
                                                                        holdingGroup:
                                                                            item.groupName,
                                                                        balance:
                                                                            item.groupTotal,
                                                                        c: item.c,
                                                                        r: item.r,
                                                                        open: item.open,
                                                                    })}
                                                                    
                                                                />}

                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Collapse
                                                        in={item.open}
                                                        className="holding-sub-group w-100"
                                                    >
                                                        <div id={accordionContentId}>                                                                           
                                                        <AccordionContent
                                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}

                                                        showModel={props.showModel}
                                                        handleShow={props.handleShow}
                                                        accountDetailsData={props.customerProfile}
                                                        investement={item.accounts}
                                                        getGeneratedSAMLAssertion={
                                                            props.onSingleSignOnClick
                                                        }
                                                        getDashBoardAccordianLinks={
                                                            props.getDashBoardAccordianLinks
                                                        }
                                                    
                                                        history={props.history}
                                                        handleAscensusClick={
                                                            props.handleAscensusClick
                                                        }
                                                        onClickPopupRedirecton={props.onClickPopupRedirecton}
                                                        isMarketTab = {true}


                                                        placeATradeonSingleSignOnClick={props.placeATradeonSingleSignOnClick}
                                                        PlaceTradeHider="PlaceTradeHider"
                                                        accountActivityHider="accountActivityHider"
                                                    />
                                                        </div>
                                                    </Collapse>
                                                </Col>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center tabFooterbox">
                            <ul className='smallLeftboxText'>
                                <li>Victory Funds Accounts Docs Link</li>
                                <li>Victory Funds Accounts Links 2</li>
                                <li>Other Victory Funds Accounts Links</li>
                            </ul>
                        </div>
                    </div>
                       
                </div>
                )
                :
                noAccountView('dynamic advisor') 
            } 
            </div>
            )}
        </>
}
    </>)

}




export const OverviewTabComponent = (props) => {

    const {dashbordContentData, savedItemsForDashbord, handleRemoveSavedListItem, fundDownStatus, testGraphData,
    handleSwitchTabs, colorArray, accountDetails, displayTabs, checkSystemHealthAndAccount, savedItemsList, isHNWDataObj, wealthInformationData,
    noAccountView, dynamicAdvisorAccountSuccessResponse, mutualFundAccountSuccessResponse, systemHealth, brokerageAccountSuccessResponse, educationAccountSuccessResponse} = props
    
    return(
        <>
            {
                (props.roleInfo != "Member") ? <>
                <Tab.Pane eventKey="overview">
                    <div className='row'>
                        <div className="col-sm-12 col-lg-7 chartMain leftContentbox">
                            <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.left?.title)}` }}>
                            </h3>
                            <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.left?.description)}` }}>
                            </p>
                                
                            <div class='bottomLinkSection'>
                                <ul className='smallLeftboxText1' style={{cursor:"pointer"}}>
                                    <li>Victory Funds Accounts</li>
                                    <li>Marketplace Accounts</li>
                                    <li>Education Accounts</li>
                                    {/* <li>Dynamic Advisor Accounts</li> */}
                                </ul> 
                            </div>
                                
                        </div>
                    

                        { (savedItemsForDashbord.cardLinks == undefined || savedItemsForDashbord?.cardLinks?.length == 0) &&
                            <>
                            <div class="col-sm-12 col-lg-5 rightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Desktop + ")" }} >
                                <div class='row'>
                                    <div class='col-sm-6 col-lg-12'>
                                    <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.title)}` }}>
                                    </h4>
                                    <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.description)}` }}>
                                    </p>
                                    </div>
                                    <div className=' col-sm-6 col-lg-12'>
                                        <div className='launchbtn' style={{position:'relative'}} >
                                        <div className="btncorner" />
                                            <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                            {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                            </a> 
                                    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 


                            <div class="col-sm-12 col-lg-5 rightBox tabrightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Tab + ")" }} >
                                <div class='row'>
                                    <div class='col-sm-6 col-lg-12'>
                                    <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.title)}` }}>
                                    </h4>
                                    <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.description)}` }}>
                                    </p>
                                    </div>
                                    <div className=' col-sm-6 col-lg-12'>
                                        <div className='launchbtn' style={{position:'relative'}} >
                                        <div className="btncorner" />
                                            <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                            {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                            </a> 
                                    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 

                            </>
                        }


                        {(Array.isArray(savedItemsForDashbord) == false && savedItemsForDashbord != null && savedItemsForDashbord?.cardLinks.length > 0) ?
                            (
                        
                            <>
                            <div class="col-sm-12 col-lg-5 rightBox savedrightBox" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Desktop + ")" }}>
                            <h4 className='rightTitle'>
                                    {` Your Saved Applications    (${savedItemsForDashbord.cardLinks?.length})`}
                            </h4>
                            <div style={{width: '100%',float: 'left'}}>
                            <div className='col-lg-12 col-md-6 p-0' style={{float:'left'}}>
                                {savedItemsForDashbord.cardLinks.map((item,index) =>(
                                    <>
                                    <p class="featureText savedtext text-white mt-3">

                                    {(index < 3) && 
                                        <>
                                    <SavedLink style={Styles.link}
                                        data-test="test-link"
                                        to={assignObj({
                                            pathname: item.isBrokerage ? '/redirectToBrokerageNAO': item.link,
                                            state: {
                                            applicationId: item.uuid
                                            },
                                        })}
                                        tabIndex="0"
                                    >
                                        {item.linktextValue}
                                    </SavedLink><br/>
                                    <p className="savedDateguest  mb-2">Saved {item.updatedAt}</p>
                                    <span className='guestdismisstext' style={{cursor:"pointer"}} onClick={()=>handleRemoveSavedListItem(item.uuid,index,item)}>dismiss</span>
                                    </>}
                    
                                    </p>
                                
                                </>
                                ))}
                                </div>
                                </div>
                                </div>
                            </> 
                            ):('')
                        } 


                        <div class="col-sm-12 col-lg-5 rightBox tabrightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Tab + ")" }} >
                            <div class='row'>
                                <div class='col-sm-6 col-lg-12'>
                                <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.title)}` }}>
                                </h4>
                                <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.description)}` }}>
                                </p>
                                </div>
                                <div className=' col-sm-6 col-lg-12'>
                                    <div className='launchbtn' style={{position:'relative'}} >
                                    <div className="btncorner" />
                                        <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                        {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                        </a> 
                                    
                                    </div>
                                    
                                </div>
                            </div>
                        </div> 
                    </div>
                
                </Tab.Pane>

                </> : <>
                <Tab.Pane eventKey="overview">
                    {props.dashboardApiResponseData?.isDashbordError == false ? (
                        <div class="row">
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 chartMain my-auto">
                        <div className='NewDashboardGraph'>
                            <HighChartErrorBoundary>
                                {props.dashboardApiResponseData?.accountSummary !=null && 
                                
                                <HighChartForOverview
                                fundDownStatus = {fundDownStatus}
                                mapData={testGraphData} 
                                totalAmount={currencyFormatter.format(
                                    parseFloat(props.dashboardApiResponseData?.totalAmount), {
                                    locale: 'en-US', 
                                })}
                                handleChartLoad={props.onChartLoad}
                                handleSwitchTabs={handleSwitchTabs}
                                colors = {colorArray} 
                                dateValue = {moment(new Date(accountDetails?.asofDate)).format('LL')}
                            />
                                }
                                
                            </HighChartErrorBoundary>
                        </div>
                        </div>
                        <div class="col-sm-12 col-lg-7 col-md-7  overviewRightbox" style={{marginTop: '100px'}}>

                        

                        {displayTabs.map((tabs)=>(
                                <>

                                    {
                                        tabs.tab == "victory funds" && 
                                            checkSystemHealthAndAccount(tabs,systemHealth?.fis,mutualFundAccountSuccessResponse,accountDetails?.mutualFund?.total,accountDetails?.mutualFund?.accounts)
                                            
                                    }

                                    {
                                        tabs.tab == "marketplace" && 
                                    
                                            checkSystemHealthAndAccount(tabs,systemHealth?.pershing,brokerageAccountSuccessResponse,accountDetails?.brokerage?.total,accountDetails?.brokerage?.accounts)
                                            
                                    }

                                    {
                                        tabs.tab == "education" && 
                                            checkSystemHealthAndAccount(tabs,systemHealth?.ascensus,educationAccountSuccessResponse,accountDetails?.children?.total,accountDetails?.children?.accounts)
                                    }

                                    {
                                        tabs.tab == "dynamic advisor" && 
                                            checkSystemHealthAndAccount(tabs,systemHealth?.pershing,dynamicAdvisorAccountSuccessResponse,accountDetails?.dynamicAdvisor?.total,accountDetails?.dynamicAdvisor?.accounts)
                                    }
                                </>
                            ))
                            }

                        <div class="row mt-5 insidebox">
                            <div className="col-lg-11 col-md-11 col-11">
                                {(Array.isArray(savedItemsList) == false && savedItemsList != null && savedItemsList?.cardLinks.length > 0) && !props.chartLoading ?
                                    (
                                        <>
                                        <h3 className='savedTitle'> {` Your Saved Applications    (${savedItemsForDashbord.cardLinks?.length})`}</h3>
                                        {savedItemsList.cardLinks.map((item,index) =>(
                                            <>
                                            {(index < 3) && 
                                            <>
                                            <p class="savedTitlelink mb-0">
                                                <SavedLink style={Styles.link}
                                                data-test="test-link"
                                                to={assignObj({
                                                    pathname: item.isBrokerage ? '/redirectToBrokerageNAO': item.link,
                                                    state: {
                                                    applicationId: item.uuid
                                                    },
                                                })}
                                                tabIndex="0"
                                            >
                                                {item.linktextValue}
                                            </SavedLink>
                                            <span className='dismisstext' style={{cursor:"pointer"}} onClick={()=>{handleRemoveSavedListItem(item.uuid,index,item)}}>Dismiss</span>
                                                
                                            </p>
                                            <p className="savedDate  mb-2">Saved {item.updatedAt}</p>
                                            </>
                                            }


                                            </>
                                        ))}
                                        </> 
                                    ):('')
                                } 
                                
                            </div>
                        </div>
                        {(isHNWDataObj?.isHNWMember == true &&
                        ((wealthInformationData?.wealthManagerInformation?.firstName !=null &&  wealthInformationData?.wealthManagerInformation?.firstName !="") || 
                        (wealthInformationData?.wealthManagerInformation?.lastName != null && wealthInformationData?.wealthManagerInformation?.lastName != ""))) && 
                        <div className='righttopheader rightmobtopheader'>
                            <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                                <p className='text-white  mb-0 righttopheadertext'>
                                    Your Wealth Manager is:<br/> <span style={{ fontSize: '14px',fontWeight: 'bold'}}>{wealthInformationData?.wealthManagerInformation?.firstName} {wealthInformationData?.wealthManagerInformation?.lastName}
                                    {wealthInformationData?.wealthManagerInformation?.professionalDesignation && 
                                    <>
                                    , {wealthInformationData?.wealthManagerInformation?.professionalDesignation}
                                    </>

                                    }</span><br/>
                                    {wealthInformationData?.wealthManagerInformation?.email && 
                                    <>
                                    <a href={`mailto:${wealthInformationData?.wealthManagerInformation?.email}`} style={{color: '#fff',textDecoration:"none"}}>  <img src={Inbox} style={{height:'17px',marginRight: '10px'}}/> {wealthInformationData?.wealthManagerInformation?.email}</a>
                                    </>
                                    }
                                    <br/>
                                    {wealthInformationData?.wealthManagerInformation?.phoneNumber && 
                                    <span className='resPhoneNumber'>
                                        <a href={`tel:${formattedPhoneNumber(wealthInformationData?.wealthManagerInformation?.phoneNumber)}`} style={{color: '#fff',textDecoration:"none"}}><img src={call} style={{height:'17px',marginRight: '10px'}}/> {formattedPhoneNumber(wealthInformationData?.wealthManagerInformation?.phoneNumber)}</a>
                                    </span>
                                    }
                            
                                </p>
                            </div>
                        </div>
        
                        }



                        </div>
                    </div>
                    ) : 
                    noAccountView('overview')
                    }
                    
                    </Tab.Pane>

                </>
            }
        </>
    )
}


const NewDashboard = (props) => {

    const [activeTab, setActiveTab] = useState("overview")
    const [redirectToNAO, setRedirectToNAO] = useState(false)
    const [displayTabs,setDisplayTab] = useState([]);
    const [tooltipOpen, setToolTipOpen]= useState(false);
    const [redirectToMarketNAO, setRedirectToMarketNAO]= useState(false);
    const [tooltipOpenForEducation, setTooltipOpenForEducation]= useState(false);
    const [tooltipOpenForMarket, setTooltipOpenForMarket]= useState(false);
    
    const initialState = {
        hover: false
    }
    const [state, setState] = useState(initialState)

    const assignObj = obj => obj;
    
    const accountDetails = props.dashboardApiResponseData?.accountSummary?.accountDetails
    const dispatch = useDispatch();
    const {systemHealth,isNewDashboardFeatureEnabledForDynamicAdvisor} = props;

    let mutualFundAccountSuccessResponse = accountDetails?.mutualFund?.isSuccess
    let brokerageAccountSuccessResponse = accountDetails?.brokerage?.isSuccess
    let educationAccountSuccessResponse = accountDetails?.children?.isSuccess
    let dynamicAdvisorAccountSuccessResponse = accountDetails?.dynamicAdvisor?.isSuccess

    if(props.roleInfo != 'Member'){
        mutualFundAccountSuccessResponse = systemHealth?.fis == 'up' ? true : false
        brokerageAccountSuccessResponse =  systemHealth?.pershing == 'up' ? true : false
        educationAccountSuccessResponse = systemHealth?.ascensus == 'up' ? true : false
        dynamicAdvisorAccountSuccessResponse = systemHealth?.pershing == 'up' ? true : false
    }

    let colorArray = [];
    let testGraphData = [];
    let fundSimpleChart = [];
    let marketSimpleChart = [];
    let educationSimpleChart = [];
    let dynamicadvisorSimpleChart = [];

    let fundFlag = false;
    let educationFlag = false;
    let merketFlag = false;
    let dynamicadvisorFlag = false;
    let fundDownStatus = true

    const tabNumber = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
    }



    useEffect(() => {
       
        props.loadDashboardContent()

        dispatch(getMarketSavedApplications({token: getToken()}))
       
    }, [])

    useEffect(() => {
        getSortedOrderForTab()
    }, [props.dashboardApiResponseData?.isNewDashboardSuccess])


    const dynamicAdvisorTab = (isNewDashboardFeatureEnabledForDynamicAdvisor, tempDisplayTabs, dynamicAdvisorPrice) => {

        return isNewDashboardFeatureEnabledForDynamicAdvisor ?
            tempDisplayTabs.push({ "tab": "dynamic advisor", "step": "fifth", "tabName": "Dynamic Advisor", "bullet": "dynamicadvisorbullet", "price": dynamicAdvisorPrice })
            :
            <></>
    }

    const isTab = (tab, number) => {
        return tab != undefined && tab != "" && tab != null && tab == number
    }

 
    
       
    // Sort the tabs based on the priority
    const getSortedOrderForTab = () => {
        let tempDisplayTabs = [];
        let fundTabDetails = accountDetails?.mutualFund?.priority;
        let marketTabDetails = accountDetails?.brokerage?.priority;
        let education = accountDetails?.children?.priority;
        let dynamicAdvisor = accountDetails?.dynamicAdvisor?.priority;

        let fundTabPrice = accountDetails?.mutualFund?.total;
        let marketTabPrice = accountDetails?.brokerage?.total;
        let educationPrice = accountDetails?.children?.total;
        let dynamicAdvisorPrice = accountDetails?.dynamicAdvisor?.total;

        if (isTab(fundTabDetails, tabNumber.one))
        {
            tempDisplayTabs.push({ "tab":"victory funds","step":"second","tabName":"Victory Funds","bullet":"fundsbullet","price":fundTabPrice})
        }
        else if (isTab(marketTabDetails, tabNumber.one))
        {
            tempDisplayTabs.push({"tab":"marketplace","step":"fourth","tabName":"Marketplace","bullet":"marketbullet","price":marketTabPrice})
        }
        else if (isTab(education, tabNumber.one))
        {
            tempDisplayTabs.push({"tab":"education","step":"third","tabName":"Education","bullet":"educationbullet","price":educationPrice})
        }
        else if (isTab(dynamicAdvisor, tabNumber.one))
        {

            dynamicAdvisorTab(isNewDashboardFeatureEnabledForDynamicAdvisor, tempDisplayTabs, dynamicAdvisorPrice)
        }
       

        // 

        if (isTab(fundTabDetails, tabNumber.two))
        {
            tempDisplayTabs.push({ "tab":"victory funds","step":"second","tabName":"Victory Funds","bullet":"fundsbullet","price":fundTabPrice})
        }
        else if (isTab(marketTabDetails, tabNumber.two))
        {
            tempDisplayTabs.push({"tab":"marketplace","step":"fourth","tabName":"Marketplace","bullet":"marketbullet","price":marketTabPrice})
        }
        else if (isTab(education, tabNumber.two))
        {
            tempDisplayTabs.push({"tab":"education","step":"third","tabName":"Education","bullet":"educationbullet","price":educationPrice})
        } 
        else if (isTab(dynamicAdvisor, tabNumber.two))
        {
            dynamicAdvisorTab(isNewDashboardFeatureEnabledForDynamicAdvisor, tempDisplayTabs, dynamicAdvisorPrice)
        }
       

        ///
        if (isTab(fundTabDetails, tabNumber.three))
        {
            tempDisplayTabs.push({ "tab":"victory funds","step":"second","tabName":"Victory Funds","bullet":"fundsbullet","price":fundTabPrice})
        }
        else if (isTab(marketTabDetails, tabNumber.three))
        {
            tempDisplayTabs.push({"tab":"marketplace","step":"fourth","tabName":"Marketplace","bullet":"marketbullet","price":marketTabPrice})
        }
        else if (isTab(education, tabNumber.three))
        {
            tempDisplayTabs.push({"tab":"education","step":"third","tabName":"Education","bullet":"educationbullet","price":educationPrice})
        }
        else if (isTab(dynamicAdvisor, tabNumber.three))
        {
            dynamicAdvisorTab(isNewDashboardFeatureEnabledForDynamicAdvisor, tempDisplayTabs, dynamicAdvisorPrice)
        }

         ///
        if (isTab(fundTabDetails, tabNumber.four)){
            tempDisplayTabs.push({ "tab":"victory funds","step":"second","tabName":"Victory Funds","bullet":"fundsbullet","price":fundTabPrice})
        }
        else if (isTab(marketTabDetails, tabNumber.four))
        {
            tempDisplayTabs.push({"tab":"marketplace","step":"fourth","tabName":"Marketplace","bullet":"marketbullet","price":marketTabPrice})
        }
        else if (isTab(education, tabNumber.four))
        {
            tempDisplayTabs.push({"tab":"education","step":"third","tabName":"Education","bullet":"educationbullet","price":educationPrice})
        }
        else if (isTab(dynamicAdvisor, tabNumber.four)){
            dynamicAdvisorTab(isNewDashboardFeatureEnabledForDynamicAdvisor, tempDisplayTabs, dynamicAdvisorPrice)
        }




        setDisplayTab(tempDisplayTabs);
    }

    const handleLinksClick = (e) => {
        const target = e.target.innerText || '';
        const screenName = localStorage.getItem("screenName")
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_My Saved Items_${target}`,
            }),
        );
    }

    const dropdownClickhandler = (e) =>{
        e.preventDefault();
        const openDropParent= document.getElementById('mobiletabbar');
        if (openDropParent.classList.contains('is-open')) {
            openDropParent.classList.remove('is-open')
          } else {
            openDropParent.classList.add('is-open')
         }
        
    }

    // Show the tabs to switch between 
    const showDashboardTabs = () =>{
        let staticTabInfo=[]

        if(displayTabs.length == 0 && props.dashboardApiResponseData?.accountSummary !=null)
        {
            getSortedOrderForTab();
        }
        else if((props.dashboardApiResponseData?.isDashbordError == true && displayTabs.length == 0) || (props.roleInfo != "Member" && displayTabs.length == 0) )
        {
                 staticTabInfo = [
                {
                    "tab": "victory funds",
                    "step": "second",
                    "tabName": "Victory Funds",
                    "bullet": "fundsbullet",
                    "price": "7006471.63"
                },
                {
                    "tab": "marketplace",
                    "step": "fourth",
                    "tabName": "Marketplace",
                    "bullet": "marketbullet",
                    "price": "00.00"
                },
                {
                    "tab": "education",
                    "step": "third",
                    "tabName": "Education",
                    "bullet": "educationbullet",
                    "price": "00.00"
                }
            ]
            if(isNewDashboardFeatureEnabledForDynamicAdvisor){
                staticTabInfo.push( 
                    {
                    "tab": "dynamic advisor",
                    "step": "fifth",
                    "tabName": "Dynamic Advisor",
                    "bullet": "dynamicadvisorbullet",
                    "price": "0.00"
                })
            }

            setDisplayTab(staticTabInfo)
        }

        const {systemHealth} = props;
        return (
            <>
            <Nav variant="pills">
                {
                !props.chartLoading &&
                
                <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first" data-testid="overiew-tab-1">Overview</Nav.Link>
                </Nav.Item>
                }
                {displayTabs.map((tabs,index)=>(
                    <Nav.Item className='tabbox'>

                    <Nav.Link eventKey={`${tabs.tab}`} className={`tablink ${tabs.step}`} data-testid={`${tabs.tab}-tab-selected`} >
                    {tabs.tabName}
                    <br/>
                  {(tabs.tab == "marketplace" && 
                   (brokerageAccountSuccessResponse == false ) ) && 
                    <><img src={traingleicon} style={{height: '20px'}} /> </>
                  }

                {(tabs.tab == "victory funds"  && 
                   (mutualFundAccountSuccessResponse == false )) && 
                    <><img src={traingleicon} style={{height: '20px'}} /> </>
                  }

                {(tabs.tab == "education" &&
                 (educationAccountSuccessResponse == false  ) ) && 
                    <><img src={traingleicon} style={{height: '20px'}} /> </>
                  }

                {(tabs.tab == "dynamic advisor" &&
                 (dynamicAdvisorAccountSuccessResponse == false ) ) && 
                    <><img src={traingleicon} style={{height: '20px'}} /> </>
                  }
                                          
                    </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
                <div className="mobnav" id="mobiletabbar">
                    <a href="#" id="mobnav-toggle" onClick={dropdownClickhandler} className="mobnav__option mobnav__option--current overviewtext" style={{marginLeft:'10px'}}>
                    <span id="innertext1" className='nametext'>Overview</span> <span className="mobnav__chevron">›</span>
                    </a>
                    <ul className="mobnav__list" style={{left:'10px'}}>
                        <li onClick={dropdownClickhandler}>
                            <a href="#" onClick={()=>handleSwitchTabs("overview")} className="mobnav__option overviewtext">Overview</a>
                        </li>
                        <li onClick={dropdownClickhandler}>
                            <a href="#" onClick={()=>handleSwitchTabs("victory funds")} className="mobnav__option fundstext">Victory Funds</a>
                        </li>
                        <li onClick={dropdownClickhandler}>
                            <a href="#" onClick={() => handleSwitchTabs("marketplace")} className="mobnav__option marketstext">Marketplace</a>
                        </li>
                        <li onClick={dropdownClickhandler}>
                            <a href="#" onClick={()=>handleSwitchTabs("education")} className="mobnav__option educationtext">Education</a>
                        </li>
                        <li onClick={dropdownClickhandler}>
                            <a href="#" onClick={()=>handleSwitchTabs("education")} className="mobnav__option dynamicadvisortext">Dynamic Advisor</a>
                        </li>
                    </ul>  
                </div>
            </>
        )
    }


    const closeAllToolTip = () =>{
        setToolTipOpen(false)
        setTooltipOpenForEducation(false)
        setTooltipOpenForMarket(false)
    }

    const  toggleToolTip = () => {
        setToolTipOpen(true)
    };

    const  toggleToolTipForEducation = () => {
        setTooltipOpenForEducation(true)
    };

    const  toggleToolTipForMarket = () => {
        setTooltipOpenForMarket(true)
    };

    const closeTooltip = () => {
        closeAllToolTip();
        document.getElementById('infoIcon').focus();
    };

    const tabKey = (e) => {
        if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab'){
            e.preventDefault();
            closeTooltip();
        }
    }

    const handleSwitchTabs = (key) => {

        setActiveTab(key)
        closeAllToolTip();

        document.getElementById("innertext1").innerHTML=`${key}`
        const innertext1 = document.getElementById("innertext1").innerHTML

        const  innertext2=document.getElementById('mobnav-toggle')
        innertext2.classList.remove('overviewtext')
        innertext2.classList.remove('fundstext')
        innertext2.classList.remove('marketstext')
        innertext2.classList.remove('educationtext')
        innertext2.classList.remove('dynamicadvisortext')


        if (innertext1 == 'victory funds') {
           innertext2.classList.add('fundstext')
        } 
        else if (innertext1 == 'overview') {
           innertext2.classList.add('overviewtext')
        } 
        else if (innertext1 == 'marketplace') {
           innertext2.classList.add('marketstext')
        } 
        else if (innertext1 == 'dynamic advisor') {
            innertext2.classList.add('dynamicadvisortext')
         } 
        else {
            innertext2.classList.add('educationtext')
        } 
    }


    
    // When there is an api error 
    const unavailableAPIErrorResponse = (selectedAccType) =>{
        return (
           <div class="row">
                <div class="col-sm-12 col-md-8 offset-md-2 text-center fundsBox">
                    <img src={traingleicon} />
                    <h1 className='fundsTitle mt-4 mb-4' >Temporarily unable to display</h1><h1 className='fundsTitle mt-4 mb-4'>{selectedAccType} information</h1>
                    <p className='featureText text-left' data-testid={`unavailble-text-${selectedAccType}`}> {selectedAccType} information is temporarily unavailable at this time. Please try again later. If you need immediate assistance, please contact one of our U.S.-based investment specialists at 1-800-235-8396 Monday through Friday 7:30 am-8:00 pm CT.</p>
                    
                </div>
            </div> 

        )
    }

    const toggleHover = () => {
        setState(prevState => ({
            hover: !prevState.hover,
        }));
    }

    
    const toggleHoveronMouseEnterOrLeave = (accountTypeHealth) => {
        if(accountTypeHealth === 'down'){
            toggleHover()
        }
    }

    const styleObjForSystemHealthDown = (systemHealthOfType) => {
        return systemHealthOfType === 'down' ? diableLink : {}
    }

    
    // when there is no accounts then show the guest tab view for user to choose a account
    const noAccountView = (tabSelected) => {
        const {dashbordContentData} = props;

        if(tabSelected == "overview"){
            return(<div class="row">
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.left?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.left?.description)}` }}>
                    </p>
                    
                    <div class='bottomLinkSection' data-testid="overview-tab-screen">
                        <ul className='smallLeftboxText1' style={{cursor:"pointer"}}>
                            <li onMouseEnter={()=> toggleHoveronMouseEnterOrLeave(systemHealth.fis)} onMouseLeave={()=> toggleHoveronMouseEnterOrLeave(systemHealth.fis)} style={styleObjForSystemHealthDown(systemHealth.fis)} onClick={()=>handleSwitchTabs("victory funds")} >Victory Funds Accounts</li>
                            <li onMouseEnter={()=> toggleHoveronMouseEnterOrLeave(systemHealth.pershing)} onMouseLeave={()=> toggleHoveronMouseEnterOrLeave(systemHealth.pershing)} style={styleObjForSystemHealthDown(systemHealth.pershing)} onClick={()=>handleSwitchTabs("marketplace")}>Marketplace Accounts</li>
                            <li onMouseEnter={()=> toggleHoveronMouseEnterOrLeave(systemHealth.ascensus)} onMouseLeave={()=> toggleHoveronMouseEnterOrLeave(systemHealth.ascensus)} style={styleObjForSystemHealthDown(systemHealth.ascensus)} onClick={()=>handleSwitchTabs("education")}>Education Accounts</li>
                            {isNewDashboardFeatureEnabledForDynamicAdvisor ?
                                <li onMouseEnter={()=> toggleHoveronMouseEnterOrLeave(systemHealth.pershing)} onMouseLeave={()=> toggleHoveronMouseEnterOrLeave(systemHealth.pershing)} style={styleObjForSystemHealthDown(systemHealth.pershing)} onClick={()=>handleSwitchTabs("dynamic advisor")}>Dynamic Advisor Accounts</li>
                                :
                            <></>
                            }
                        </ul>
                        { state.hover &&
                            <div className='unavailable-message'>
                                <p style={{color: 'rgba(230, 0, 0, 1)', font: ' 700 12px/22px benton-sans'}}>{guestCardData.unavailableMessage}</p>
                            </div>
                        }
                    </div>
                </div>
                { (savedItemsList.cardLinks == undefined || savedItemsList?.cardLinks?.length == 0) &&
                <>
                <div class="col-sm-12 col-lg-5 rightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Desktop + ")",backgroundColor: '#486d90' }} >
                    <div class='row'>
                        <div class='col-sm-6 col-lg-12'>
                        <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.title)}` }}>
                        </h4>
                        <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.description)}` }}>
                        </p>
                        </div>
                        <div className=' col-sm-6 col-lg-12'>
                            <div className='launchbtn' style={{position:'relative'}} >
                            <div className="btncorner" />
                                <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                </a> 
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-sm-12 col-lg-5 rightBox tabrightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Tab + ")" }} >
                    <div class='row'>
                        <div class='col-sm-6 col-lg-12'>
                        <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.title)}` }}>
                        </h4>
                        <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.overview?.right?.description)}` }}>
                        </p>
                        </div>
                        <div className=' col-sm-6 col-lg-12'>
                            <div className='launchbtn' style={{position:'relative'}} >
                            <div className="btncorner" />
                                <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                </a> 
                            </div>
                        </div>
                    </div>
                </div> 
                </>
                }
                    {savedItemsList != null && (Array.isArray(savedItemsList) == false && savedItemsList?.cardLinks.length > 0) ?
                        (
                        <>
                        <div class="col-sm-12 col-lg-5 rightBox savedrightBox" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Desktop + ")",backgroundColor: '#486d90'}}>
                            <h4 className='rightTitle'>
                                    {` Your Saved Applications    (${savedItemsForDashbord.cardLinks?.length})`}
                            </h4>
                        
                            {savedItemsList.cardLinks.map((item,index) =>(
                                    <div style={{width: '100%',float: 'left'}}>
                                <div className='col-lg-12 col-md-6 p-0' style={{float:'left'}}>
                                    {(index < 3) && 
                                    <>
                                    <p class="featureText savedtext text-white mt-3">
                                <SavedLink style={Styles.link}
                                    data-test="test-link"
                                    to={assignObj({
                                        pathname: item.isBrokerage ? '/redirectToBrokerageNAO': item.link,
                                        state: {
                                        uuid: item.uuid
                                        }
                                    })}
                                    onClick={handleLinksClick}
                                    tabIndex="0"
                                >
                                    {item.linktextValue}
                                </SavedLink><br/>
                                <p className="savedDateguest  mb-2">Saved {item.updatedAt}</p>
                                <span className='guestdismisstext' style={{cursor:"pointer"}} onClick={()=>handleRemoveSavedListItem(item.uuid,index,item)}>dismiss</span>
                                </p> 
                                </>
                                }
                                </div>
                                </div>
                            ))}
                                </div>
                        </> 
                        ):('')
                    } 
            </div>)
        }
        else if(tabSelected == "victory funds"){
            return (
                <>
                <div class="row guestbanner guestbanner1 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Desktop + ")" }}>
                    <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                            <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.funds?.title)}` }}>
                            </h3>
                            <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.funds?.description)}` }}></p>
                        </div>
                        <div class="col-sm-12 col-lg-5  text-center">
                            
                    </div>

                  </div>

                 {/* Mobile guest Funds section */}


                <div class="row guestbanner guestbanner1 mobguestbanner1 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Mobile + ")" }}>
                    <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                            <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.title.replace('<p>','').replace('</p>','')}` }}>
                            </h3>
                            <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.description.replace('<p>','').replace('</p>','')}` }}></p>
                        </div>
                        <div class="col-sm-12 col-lg-5  text-center">
                            
                    </div>
                    
                </div>

                 {/* Tab guest Funds section */}

            <div class="row guestbanner guestbanner1 tabguestbanner1 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                        <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.title.replace('<p>','').replace('</p>','')}` }}>
                        </h3>
                        <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.description.replace('<p>','').replace('</p>','')}` }}></p>
                    </div>
                    <div class="col-sm-12 col-lg-5  text-center">
                        
                </div>
            
            </div>
                </>
            )
        }else if(tabSelected == "education"){
            return (
                <>
                <div class="row guestbanner guestbanner3 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Desktop + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.description)}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                        <div class="col-sm-4 col-lg-4 leftbtnBox">
                       
                        </div>
                </div>
                </div>

                {/* Mobile guest Education section */}

                <div class="row guestbanner guestbanner3 mobguestbanner3 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.description)}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
               
                </div>


                {/* Tab guest Education section */}

                <div class="row guestbanner guestbanner3 tabguestbanner3 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.education?.description)}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
               
                </div>

                </>
            )
        }else if(tabSelected == "marketplace"){
            return (
                <>
                <div class="row guestbanner guestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Desktop + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.description)}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-4 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}}className=''>
                        <DButton id="guest-mrkt-openacc"  item={props.openBrokerageAccount}  ></DButton>
                    </div>
                    </div>       
                </div>
                </div>  


                {/* Mobile guest Markets section */}

                <div class="row guestbanner guestbanner2 mobguestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.description)}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                        
                            <DButton id="mob-guest-mrkt-openacc" item={props.openBrokerageAccount} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Tab guest Markets section */}

                <div class="row guestbanner guestbanner2 tabguestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.markets?.description)}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                            <DButton id="tab-guest-mrkt-openacc" item={props.openBrokerageAccount} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                </>
            )
        }
        else if(tabSelected == "dynamic advisor"){
            return (
                <>
                <div class="row guestbanner guestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.bgURL_Desktop + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.description)}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-4 col-lg-5 leftbtnBox">
                    <div  style={{position:'relative'}}className=''>
                    </div>
                    </div>       
                </div>
                </div>  


                {/* Mobile guest Markets section */}

                <div class="row guestbanner guestbanner2 mobguestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-12 col-lg-5 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Tab guest Markets section */}

                <div class="row guestbanner guestbanner2 tabguestbanner2 mr-0" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.title)}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${responceData(dashbordContentData?.dashboard_marketing_content?.dynamicAdvisor?.description)}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                            <DButton id="tab-guest-mrkt-openacc" item={props.openBrokerageAccount} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                </>
            )
        }else{
            return <div></div>
        }
    }


    const assignGraphData = (amount,type,dataAvailable = true) =>
    {
        return {
            "y": parseFloat(amount),
            "name":type ,
            "formattedGroupTotal": `$${amount}`,
            "dataLabels": {
                "enabled": false,
                "distance": -140,
                "style": {
                    "font": "500 14px/22px benton-sans,sans-seriff"
                }
            },
            "dataAvailable": dataAvailable,
            "dataUnavailableMessage": ' balance temporarily unavailable',
        }
    }

    const assignColorForSimpleChart = (index,type)=>
    {
        let tempSimpleChartColorArray = [];
        for(let i=0;i<testGraphData.length;i++)
        {
            if(i==index)
            {
                if(type == 'Victory Funds')
                {
                    tempSimpleChartColorArray[i] = '#7A2C7B';
                }
                else if(type == 'Education')
                {
                    tempSimpleChartColorArray[i] = '#00ADEE';
                }
                else if(type == 'Marketplace')
                {
                    tempSimpleChartColorArray[i] = '#8BC13F';
                }
                else if(type == 'Dynamic Advisor')
                {
                    tempSimpleChartColorArray[i] = '#CF481E';
                }
                
            }
            else
            {
                tempSimpleChartColorArray[i]='#D2D2D2';
            }
        }
        return tempSimpleChartColorArray;
    }


    // Account list to select in overview tab
    const overviewTab = (tabs,total) => {

        return  ( <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11" onClick={()=>handleSwitchTabs(`${tabs.tab}`)}>
                    <span className={`bullets ${tabs.bullet}`}></span> 
                    <a href="#!"  className='overviewfundname'>{tabs.tabName} Accounts </a>
                    <span className='overviewAmount' style={{float: 'right'}}>{
                        currencyFormatterFunction(total)
                        }
                    </span>
                </div>)
    }


   // Error msg in overview tab when health is down
   const errorWarningMsg = (tabs,accName) => {
    return  (  
        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12">
            <span className={`bullets ${tabs.bullet}`}></span> 
            <a href="#!"  className='overviewfundname'>{tabs.tabName} Accounts </a>
            <span className='overviewAmount' style={{float: 'right', textAlign: 'right',cursor: 'pointer'}}>
                <span className='errorbalance'>Balance Unavailable</span>
                <span><img src={traingleicon} className="trianglebullet" /></span>
            </span>
        </div>
   )
}

const refreshPageMsg = (tabs,total) => {
    return  (  
   
        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12">
                <span className={`bullets ${tabs.bullet}`}></span> 
                <a href="#!"  className='overviewfundname'>{tabs.tabName} Accounts </a>
                <span className='overviewAmount refreshtext' style={{float: 'right',cursor: 'pointer'}} onClick={() => {window.location.reload();}}>
                    Refresh Page <i className="fa fa-repeat refreshbtn"></i>
                </span>
        </div>
    )
}

    const handleRemoveSavedListItem = (uuid,index,accountType) =>
    {
        const {removeSavedListItem} = props;
        removeSavedListItem(uuid,index,accountType);
    }


    const {savedItemsList,isHNWDataObj,wealthInformationData, dashbordContentData,savedItemsForDashbord}= props;

    const showTotalBalance = (responseFlag)=>{
        if((responseFlag == true)){
            return true
        }
        return false
       
    }

    const showRefreshMessage = (systemHealthFlag) => {
        if (typeof systemHealthFlag === 'string' && systemHealthFlag.toLowerCase() == 'up') {
            return true;
        }
        return false;
    }

    const showWarningMessage = (systemHealthFlag)=>{

        if (typeof systemHealthFlag === 'string' && systemHealthFlag.toLowerCase() == 'down') {
            return true
        }
        return false
    }

    const checkSystemHealthAndAccount = (tabs,systemHealthFlag,responseFlag,totalBalance,accounts)=>{
    

        if (showTotalBalance(responseFlag) && accounts?.length > 0){
            return overviewTab(tabs,totalBalance)

        }
        else if(showRefreshMessage(systemHealthFlag) && (responseFlag == false)){
            return  refreshPageMsg(tabs)
        }
        else if(showWarningMessage(systemHealthFlag)  && (responseFlag == false)){
            return errorWarningMsg(tabs)
        }
        else{
            return <></>
        }
    }


    const getAverageOfAllFundTypes = () => {
        const mutualFundTotal = parseFloat(props.dashboardApiResponseData?.accountSummary?.accountDetails?.mutualFund.total) || 0;
        const brokerageTotal = parseFloat(props.dashboardApiResponseData?.accountSummary?.accountDetails?.brokerage.total) || 0;
        const childrenTotal = parseFloat(props.dashboardApiResponseData?.accountSummary?.accountDetails?.children.total) || 0;
        const dynamicAdvisorTotal = parseFloat(props.dashboardApiResponseData?.accountSummary?.accountDetails?.dynamicAdvisor.total) || 0;
    
        const total = mutualFundTotal + brokerageTotal + childrenTotal + dynamicAdvisorTotal;
        const totalNumberOfFunds = 4

        return total/totalNumberOfFunds

    }

        
    const hasAccountsAndTotalNotZero = (accounts, total) => {
        if(accounts !== undefined && total !== undefined){
            return accounts.length > 0 && parseInt(total) > 0
        }
    }



    const setDoughNutChartData = () => {
        if(fundFlag)
        {
           const index = testGraphData.findIndex(item => item.name == "Victory Funds");
           fundSimpleChart = assignColorForSimpleChart(index,'Victory Funds');
        }
 
        if(merketFlag)
        {
           const index = testGraphData.findIndex(item => item.name == "Marketplace");
           marketSimpleChart = assignColorForSimpleChart(index,'Marketplace');
        }
 
        if(educationFlag)
        {
           const index = testGraphData.findIndex(item => item.name == "Education");
           educationSimpleChart = assignColorForSimpleChart(index,'Education');
        }
        if(dynamicadvisorFlag)
        {
           const index = testGraphData.findIndex(item => item.name == "Dynamic Advisor");
           dynamicadvisorSimpleChart = assignColorForSimpleChart(index,'Dynamic Advisor');
        }
    }

    const assignMutualFundAccountGraphData = () => {
        const average = getAverageOfAllFundTypes()

        if(mutualFundAccountSuccessResponse)
        {
            if(hasAccountsAndTotalNotZero(props.dashboardApiResponseData?.accountSummary?.accountDetails?.mutualFund?.accounts, props.dashboardApiResponseData?.accountSummary?.accountDetails?.mutualFund?.total)){
                testGraphData.push(assignGraphData(props.dashboardApiResponseData?.accountSummary?.accountDetails?.mutualFund.total,"Victory Funds"))
                colorArray.push('#7A2C7B');
                fundFlag = true;
            }
        }
        else{
            testGraphData.push(assignGraphData(average,"Victory Funds",false))
            colorArray.push('#D2D2D2');
            fundFlag = true;
            fundDownStatus = false
        }
    }

    const assignBrokerageAccountGraphData = () => {
        const average = getAverageOfAllFundTypes()

        if(brokerageAccountSuccessResponse)
        {
            if(hasAccountsAndTotalNotZero(props.dashboardApiResponseData?.accountSummary?.accountDetails?.brokerage?.accounts, props.dashboardApiResponseData?.accountSummary?.accountDetails?.brokerage?.total)){
                testGraphData.push(assignGraphData(props.dashboardApiResponseData?.accountSummary?.accountDetails?.brokerage.total,"Marketplace"));
                colorArray.push('#21847E')
                merketFlag = true;
            }
        }
        else{
            testGraphData.push(assignGraphData(average,"Marketplace",false));
            colorArray.push('#D2D2D2')
            merketFlag = true;
            fundDownStatus = false
        }
    }

    const assignEducationAccountGraphData = () => {
        const average = getAverageOfAllFundTypes()

        if(educationAccountSuccessResponse)
        {
            if(hasAccountsAndTotalNotZero(props.dashboardApiResponseData?.accountSummary?.accountDetails?.children?.accounts, props.dashboardApiResponseData?.accountSummary?.accountDetails?.children?.total)){
                testGraphData.push(assignGraphData(props.dashboardApiResponseData?.accountSummary?.accountDetails?.children.total,"Education"));
                colorArray.push('#0578CE');
                educationFlag = true;
            }
        }
        else{
            testGraphData.push(assignGraphData(average,"Education",false));
            colorArray.push('#D2D2D2');
            educationFlag = true;
            fundDownStatus = false
        }
    }

    const assignDynamicFundAccountGraphData = () => {

        if(props.isNewDashboardFeatureEnabledForDynamicAdvisor){
            const average = getAverageOfAllFundTypes()

            if(dynamicAdvisorAccountSuccessResponse) 
            {
                if(hasAccountsAndTotalNotZero(props.dashboardApiResponseData?.accountSummary?.accountDetails?.dynamicAdvisor?.accounts?.length, props.dashboardApiResponseData?.accountSummary?.accountDetails?.dynamicAdvisor?.total)){
                    testGraphData.push(assignGraphData(props.dashboardApiResponseData?.accountSummary?.accountDetails?.dynamicAdvisor.total,"Dynamic Advisor"));
                    colorArray.push('#CF481E')
                    dynamicadvisorFlag = true;
                }
            }
            else{
                testGraphData.push(assignGraphData(average,"Dynamic Advisor",false));
                colorArray.push('#D2D2D2')
                dynamicadvisorFlag = true;
                fundDownStatus = false
            }
        }
    }
    
    

    const assignGraphDataFn = () => {

        if(testGraphData.length == 0)
        {
            assignMutualFundAccountGraphData()

            assignBrokerageAccountGraphData()

            assignEducationAccountGraphData()

            assignDynamicFundAccountGraphData()
        }
    
        if(testGraphData.length > 0)
        {
            setDoughNutChartData()
        }
    }




    // function to assign the data(Colours and fund amout inormation) to the doughnut chart in dashboard
    assignGraphDataFn()



  return (
   
        <div className="col-12 col-md-12 col-lg-12 mt-5 rwdDashboardWrap">

        {redirectToNAO && <Redirect to='/openAccount' />}

        {redirectToMarketNAO && <Redirect to="/marketOpenAccount" />}
        


        <WCardDashboardOverlay className="portfolioChart ac-holder-wcardoverlay">
            <div className="tab-wrapper">
                <div className='container-fluid' >
                    <div  className='topHeader'>
                        <div className="lefttopHeader">
                        <div className='mainTitle' style={{paddingRight: '0px'}}>
                                <SideHeader
                                    title={props.pageName}
                                    imgSrc={iconAllocation}
                                    width="20px"
                                    height="20px"
                                    id="myPortfolio"
                                    className="mb-0 mb-lg-3"
                                    noBorderBottom={true}
                                />
                        </div>
                        </div>
                        
                        {/* Wealth information */}

                        
                        <WealthinformationComponent 
                            isHNWDataObj={isHNWDataObj}
                            wealthInformationData={wealthInformationData}
                            dashbordContentData={dashbordContentData}
                            noAccountView={noAccountView}
                        />
                        
                        
                    </div>
                    <div className="row memberTabsection">
                    <div className="col-sm-12 tabsection">

                        <Tab.Container activeKey={activeTab} onSelect={handleSwitchTabs}>
                        <Row>
                            <div className="col-xl-2 col-sm-12 tableftColumn tabmenusection">
                            {showDashboardTabs()}
                            
                            </div>
                            <div className= {activeTab == "overview" ? "col-xl-10 col-sm-12 tabRightColumn" : "col-xl-10 col-sm-12 pr-0 tabRightColumn"}>
                            <Tab.Content>

                            {/* passing the properties in the overview component */}
                                <OverviewTabComponent
                                    roleInfo={props.roleInfo}
                                    dashbordContentData={dashbordContentData}
                                    savedItemsForDashbord={savedItemsForDashbord}
                                    handleRemoveSavedListItem={handleRemoveSavedListItem}
                                    dashboardApiResponseData={props.dashboardApiResponseData}
                                    fundDownStatus={fundDownStatus}
                                    testGraphData={testGraphData}
                                    onChartLoad={props.onChartLoad}
                                    handleSwitchTabs={handleSwitchTabs}
                                    colorArray={colorArray}
                                    accountDetails={accountDetails}
                                    displayTabs={displayTabs}
                                    checkSystemHealthAndAccount={checkSystemHealthAndAccount}
                                    savedItemsList={savedItemsList}
                                    chartLoading={props.chartLoading}
                                    isHNWDataObj={isHNWDataObj}
                                    wealthInformationData={wealthInformationData}
                                    noAccountView={noAccountView}
                                    systemHealth={systemHealth}
                                    mutualFundAccountSuccessResponse={mutualFundAccountSuccessResponse}
                                    brokerageAccountSuccessResponse={brokerageAccountSuccessResponse}
                                    educationAccountSuccessResponse={educationAccountSuccessResponse}
                                    dynamicAdvisorAccountSuccessResponse={dynamicAdvisorAccountSuccessResponse}

                                />




                                <Tab.Pane eventKey="victory funds">
                                    <VictoryFundsTabComponent 
                                      roleInfo={props.roleInfo}
                                      systemHealth={systemHealth}
                                      mutualFundAccountSuccessResponse={mutualFundAccountSuccessResponse}
                                      unavailableAPIErrorResponse={unavailableAPIErrorResponse}
                                      dashboardApiResponseData={props.dashboardApiResponseData}
                                      noAccountView={noAccountView}
                                      isError={props.isError}
                                      mfaccountsData={props.mfaccountsData}
                                      testGraphData={testGraphData}
                                      onChartLoad={props.onChartLoad}
                                      fundSimpleChart={fundSimpleChart}
                                      latestAggregatedBalanceDate={props.latestAggregatedBalanceDate}
                                      NewAmountTitle={props.NewAmountTitle}
                                      tooltipOpen={tooltipOpen}
                                      toggleToolTip={toggleToolTip}
                                      closeTooltip={closeTooltip}
                                      closeTabKey={tabKey}
                                      holdingGroupDescription={props.holdingGroupDescription}
                                      handleAccordionClick={props.handleAccordionClick}
                                      isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}
                                      showModel={props.showModel}
                                      handleShow={props.handleShow}
                                      customerProfile={props.customerProfile}
                                      onSingleSignOnClick={props.onSingleSignOnClick}
                                      getDashBoardAccordianLinks={props.getDashBoardAccordianLinks}
                                      history={props.history}
                                      handleAscensusClick={props.handleAscensusClick}
                                      onClickPopupRedirecton={props.onClickPopupRedirecton}
                                      getPostionData={props.getPostionData}
                                      graphPlottingValues={props.graphPlottingValues}
                                    />
                                </Tab.Pane>


                                <Tab.Pane eventKey="marketplace">
                                    <MarketplaceAccountComponent
                                        roleInfo={props.roleInfo}
                                        systemHealth={systemHealth}
                                        brokerageAccountSuccessResponse={brokerageAccountSuccessResponse}
                                        unavailableAPIErrorResponse={unavailableAPIErrorResponse}
                                        dashboardApiResponseData={props.dashboardApiResponseData}
                                        noAccountView={noAccountView}
                                        isError={props.isError}
                                        braccountsData={props.braccountsData}
                                        testGraphData={testGraphData}
                                        onChartLoad={props.onChartLoad}
                                        marketSimpleChart={marketSimpleChart}
                                        latestAggregatedBalanceDate={props.latestAggregatedBalanceDate}
                                        NewAmountTitle={props.NewAmountTitle}
                                        tooltipOpenForMarket={tooltipOpenForMarket}
                                        toggleToolTipForMarket={toggleToolTipForMarket}
                                        closeTooltip={closeTooltip}
                                        closeTabKey={tabKey}
                                        holdingGroupDescription={props.holdingGroupDescription}
                                        handleAccordionClick={props.handleAccordionClick}
                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}
                                        showModel={props.showModel}
                                        handleShow={props.handleShow}
                                        customerProfile={props.customerProfile}
                                        onSingleSignOnClick={props.onSingleSignOnClick}
                                        placeATradeonSingleSignOnClick={props.placeATradeonSingleSignOnClick}
                                        handleApplicationClosedPopUp={props.handleApplicationClosedPopUp}
                                        getDashBoardAccordianLinks={props.getDashBoardAccordianLinks}
                                        history={props.history}
                                        handleAscensusClick={props.handleAscensusClick}
                                        onClickPopupRedirecton={props.onClickPopupRedirecton}
                                    />


                                </Tab.Pane>
                


                                <Tab.Pane eventKey="education">
                                    <EducationAccountComponent 
                                        roleInfo={props.roleInfo}
                                        systemHealth={systemHealth}
                                        educationAccountSuccessResponse={educationAccountSuccessResponse}
                                        unavailableAPIErrorResponse={unavailableAPIErrorResponse}
                                        dashboardApiResponseData={props.dashboardApiResponseData}
                                        noAccountView={noAccountView}
                                        isError={props.isError}
                                        childrenAccountsData={props.childrenAccountsData}
                                        testGraphData={testGraphData}
                                        onChartLoad={props.onChartLoad}
                                        educationSimpleChart={educationSimpleChart}
                                        latestAggregatedBalanceDate={props.latestAggregatedBalanceDate}
                                        NewAmountTitle={props.NewAmountTitle}
                                        tooltipOpenForEducation={tooltipOpenForEducation}
                                        toggleToolTipForEducation={toggleToolTipForEducation}
                                        closeTooltip={closeTooltip}
                                        closeTabKey={tabKey}
                                        holdingGroupDescription={props.holdingGroupDescription}
                                        handleAccordionClick={props.handleAccordionClick}
                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}
                                        showModel={props.showModel}
                                        handleShow={props.handleShow}
                                        customerProfile={props.customerProfile}
                                        onSingleSignOnClick={props.onSingleSignOnClick}
                                        getDashBoardAccordianLinks={props.getDashBoardAccordianLinks}
                                        history={props.history}
                                        handleAscensusClick={props.handleAscensusClick}
                                        onClickPopupRedirecton={props.onClickPopupRedirecton}
                                        dashboardOpenAnAccount={props.dashboardOpenAnAccount}
                                        handleEducationApplicationClosedPopUp={props.handleEducationApplicationClosedPopUp}
                                    /> 
                               
                                </Tab.Pane>



                                <Tab.Pane eventKey="dynamic advisor">
                                    <DynamicAdvisorComponent 
                                        roleInfo={props.roleInfo}
                                        systemHealth={props.systemHealth}
                                        dynamicAdvisorAccountSuccessResponse={dynamicAdvisorAccountSuccessResponse}
                                        unavailableAPIErrorResponse={unavailableAPIErrorResponse}
                                        dashboardApiResponseData={props.dashboardApiResponseData}
                                        noAccountView={noAccountView}
                                        isError={props.isError}
                                        daAccountsData={props.daAccountsData}
                                        testGraphData={testGraphData}
                                        onChartLoad={props.onChartLoad}
                                        dynamicadvisorSimpleChart={dynamicadvisorSimpleChart}
                                        latestAggregatedBalanceDate={props.latestAggregatedBalanceDate}
                                        NewAmountTitle={props.NewAmountTitle}
                                        tooltipOpenForMarket={tooltipOpenForMarket}
                                        toggleToolTipForMarket={toggleToolTipForMarket}
                                        closeTooltip={closeTooltip}
                                        tabKey={tabKey}
                                        holdingGroupDescription={props.holdingGroupDescription}
                                        handleAccordionClick={props.handleAccordionClick}
                                        onSingleSignOnClick={props.onSingleSignOnClick}
                                        isNewDashboardFeatureEnabled={props.isNewDashboardFeatureEnabled}
                                        showModel={props.showModel}
                                        handleShow={props.handleShow}
                                        customerProfile={props.customerProfile}
                                        getDashBoardAccordianLinks={props.getDashBoardAccordianLinks}
                                        history={props.history}
                                        handleAscensusClick={props.handleAscensusClick}
                                        onClickPopupRedirecton={props.onClickPopupRedirecton}
                                        placeATradeonSingleSignOnClick={props.placeATradeonSingleSignOnClick}
                                    
                                    />

                                </Tab.Pane>
                            </Tab.Content>
                            </div>
                        </Row>
                        </Tab.Container>
                    </div>
                    </div>
                </div>
            </div> 
        </WCardDashboardOverlay> 
        
    </div>      
   
  )
}

export default NewDashboard