const pageHeading = 'Document Preferences';
const subheading = 'This is sample content paragraph';
const deliveryPreference = 'Delivery Preference'
const seasonalAddress = 'Seasonal Address'
const statements = 'Statements'
const confirms = 'Confirms'
const reports = 'Reports'
const correspondence = 'Correspondence'
const proxy = 'Proxy'
const tax = 'Tax'

// needed in unit tests
const accounts = [
    {
        "accountType": "Traditional IRA",
        "accounts": [
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-1111",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "No",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            },
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-1112",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "Yes",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            }
        ]
    },
    {
        "accountType": "Roth IRA",
        "accounts": [
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-2222",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "No",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            },
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-2223",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "Yes",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            },
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-2224",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "No",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            }
        ]
    },
    {
        "accountType": "Individual Mutual Fund Account",
        "accountDesc": "Transfer on Death Beneficiaries",
        "accounts": [
            {
                "accName": "Sample Content",
                "accNumber": "7667-3345-3333",
                "deliveryPreference": "Paper",
                "SeasonalAddress": "No",
                "deliveryPreferences": {
                    'statements': 'statementsPaper',
                    'confirmations': 'confirmationsPaper',
                    'reports': 'reportsPaper',
                    'correspondence': 'correspondencePaper',
                },
                "taxDocuments": {
                    'taxDocuments': 'taxDocumentsPaper'
                }
            }
        ]
    }
];
const eifen = '-';
const linkText = 'Edit';
const separator = '|';
const generalPreferencesUrls = [
    { url: '/accountHolder', name: 'Home' },
    { url: '/profile', name: 'Profile and Preferences' },
];
const activeGeneralUrl = 'Document Preferences';
const accordionHeaderStyle = {
    position: 'relative',
    marginBottom: 30,
    fontSize: 22,
    color: '#486D90',
    fontFamily: 'yorkten-slab-normal',
    fontWeight: 800,
    borderBottom: '1px solid #CECECE',
    paddingBottom: 15,
    paddingLeft: 32,
};

const deliveryValues = {
    key: 'DocReceptionQ',
    value: [
        { key: "Online", value: "Online" },
        { key: "Paper", value: "Paper" }
    ]
};
const radiostyles = {
    minHeight: 0,
    marginBottom: 0
};

const para1 =
    'You can change the method on how you prefer to receive Victory Capital documents.';
const para2 = 'If you would like to change your account delivery preference for all of your accounts, please select Online or Paper, and hit submit.';
const para3='You can also select a delivery preference for a particular account by selecting the edit option for the account.'
const applyAllText = 'How would you like to receive all VCM documents?';

const infoText = 'Selecting the Online or Paper will set the preference for all the documents under all the accounts below. For setting individual document preference under each account click on the edit';

export default {
    infoText,
    applyAllText,
    pageHeading,
    accordionHeaderStyle,
    subheading,
    accounts,
    eifen,
    activeGeneralUrl,
    linkText,
    separator,
    generalPreferencesUrls,
    radiostyles,
    deliveryValues,
    para1,
    para2,
    para3,
    deliveryPreference,
    seasonalAddress,
    statements,
    reports,
    correspondence,
    proxy,
    tax,
    confirms,
    headerFieldStyle: { display: 'inline-block', float: 'left' }
};
