import '../MarketNAOStyles.css'
import { Col, Row } from 'react-bootstrap';
import { Accordian, AccordianTitle, WIcon } from 'common';
import editIcon from '../../../../assets/EditPencil.svg';
import React, { useState, useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import currencyFormatter from 'currency-formatter';
import MarketPersonalInfoComponent from 'modules/AccountManagementModule/MarketPersonalInfoComponent';
import useAutoFocus from './useAutoFocus'
import { getFormattedSSN, getRankValueBasedOnKey, getRole, getValue, formatCurrencyAndRemoveDecimalPart, convertToTitleCase } from 'utils/utils';
import { isValidValue } from 'commonHelper/CommonHelperFunctions';

const assignObj = obj => {
  return obj;
}
  //For Avoiding div repeatations
  const linesReducer = (verifyHeading, verifyValue) => {
    return (
      <div className="card-list-wrapper">
        <div class="row">
          <div class="label-key col-lg-4 col-md-4 col-sm-12 col-12">
            <span data-testid={`line-reducer-header-${verifyHeading}`}>{verifyHeading}</span>
          </div>
          <div class="label-value col-8" data-testid={`line-reducer-value-${verifyValue}`}><span>{verifyValue}</span></div>
        </div>
      </div>
    )
  }
  


  const mailLineReducer = (addressHeading, addressValue) => {

    return (
      <div className="contactwrap">
        <div class="row no-gutters">
          <h3 class="phy-heading" data-testid={`mail-line-reducer-header-${addressHeading}`}>{addressHeading}</h3>
        </div>
        <div class="phy-value">
          {addressValue}
        </div>
      </div>
    )
  }



export const PersonalComponentInfo = (props) => {
  const {personalEdit, setPersonalEdit, editDisabler, showEditButton, personalInfo, role,closeEditiableScreen,editPersonalInfoPage,handlePersonalInfoComponentEditMode,unmaskedformattedSSN } = props;
  const personalPrefix = personalInfo?.prefix ? personalInfo?.prefix +"\u00A0" : null

  const personalinfoRef = useRef(null);
  useAutoFocus(personalEdit,personalinfoRef)
 
  return(
      <>
        {personalEdit ? <MarketPersonalInfoComponent verifyPersonalHider={personalEdit} editPersonalValOut={(val)=>handlePersonalInfoComponentEditMode(val)} closeEditiableScreen={closeEditiableScreen} editPersonalInfoPage={editPersonalInfoPage} /> :
          <Accordian
            accordianPaddingLeft={0}
            id={"personalInformationPrimary"}
            titleChild={
              <Row style={{ display: "flex", justifyContent: "space-between" }} ref={personalinfoRef} tabIndex="-1" >
                <Col md={10} xl={11}>
                  <AccordianTitle
                    accType={'Personal Information-Primary'}
                    className="accordionH2Style"
                    requireButtonPadding={false}
                  /></Col>



                <Col md={2} xl={1} className="sum-prelink">
                  {editDisabler && showEditButton  && <Row noGutters>
                    <WIcon
                      alt=""
                      src={editIcon}
                      size={"1px"}
                      className="editIcon"
                      fontawesomestyle={assignObj({ paddingLeft: 0 })}
                    />
                    <button type="button" className="sum-linktext mt-1" onClick={()=>setPersonalEdit(!personalEdit)}>
                      <span>Edit</span>
                    </button>
                  </Row>}
                </Col>

              </Row>
            }
            titleSeperator
          >
            <div className="sum-card-wrap">
              {linesReducer("Name", personalInfo?.firstName == "" && personalInfo?.lastName == "" ? "-" : <span data-testid="personal-prefix" style={{ whiteSpace: "pre-line" }}>{personalPrefix} {personalInfo?.firstName} {personalInfo?.lastName}</span>)}
              {linesReducer("Date of Birth", personalInfo?.maskedDob)}
              {linesReducer("Gender", personalInfo?.gender)}
              {linesReducer("Marital Status", personalInfo?.maritalStatus)}
              {personalInfo?.citizenship == "us" ? <div> {linesReducer("Citizenship", personalInfo?.citizenship == "" ? "-" : "U.S.")}</div> : <div> {linesReducer("Citizenship", personalInfo?.citizenship == "" ? "-" : "Non U.S.")}</div>}
              {personalInfo?.citizenship == "nonus" && <div>  {linesReducer("Resident Information", personalInfo?.residentStatus)} </div> }
              {personalInfo?.residentStatus =="Resident Alien" && <div>  {linesReducer("Country Of Citizenship", personalInfo?.countryOfCitizenship)} </div> }
              {personalInfo?.residentStatus =="Resident Alien" && <div>  {linesReducer("U.S. Permanent Resident Card Number", personalInfo?.residentAlienInformation?.permanentResidentCardNumber)} </div> }
              {personalInfo?.residentStatus =="Resident Alien" && <div>  {linesReducer("Expiration Date", personalInfo?.residentAlienInformation?.permanentResidentExpirationDate)} </div> }
              {personalInfo?.residentStatus =="Resident Alien" && <div> {linesReducer("Passport Number issued by Country of Citizenship", personalInfo?.residentAlienInformation?.passportNumber)} </div> }
              {personalInfo?.residentStatus =="Resident Alien" && <div>  {linesReducer("Passport Expiration Date", personalInfo?.residentAlienInformation?.passportExpirationDate)} </div> }
              {linesReducer("Social Security Number",unmaskedformattedSSN)}    
        </div>

      </Accordian>}
      </>
    )
  }

export const ContactComponentInfo = (props) => {

    const {contactEdit, setContactEdit, editDisabler, showEditButton, mailingAddress, physicalAddress} = props
    
    const contactinfoRef = useRef(null);
    useAutoFocus(contactEdit,contactinfoRef )
  
    return(
      <>
         { contactEdit ? <MarketPersonalInfoComponent verifyContactHider={contactEdit} editContactValOut={(val)=>{setContactEdit(val);}} /> :<Accordian
        accordianPaddingLeft={0}
        id={"contactinfo"}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={contactinfoRef} tabIndex="-1">
            <Col md={10} xl={11}>
              <AccordianTitle
                accType={'Contact Information'}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>



            <Col md={2} xl={1} className="sum-prelink">
              {editDisabler && showEditButton && <Row noGutters>
                <WIcon
                  alt=""
                  src={editIcon}
                  size={"1px"}
                  className="editIcon"
                  fontawesomestyle={assignObj({ paddingLeft: 0 })}
                />
                <button type="button" className="sum-linktext mt-1" onClick={()=>setContactEdit(!contactEdit)} >
                  <span>Edit</span>
                </button>
              </Row>}
            </Col>


          </Row>
        }
        titleSeperator
      >

        <div className="sum-card-wrap">
          {mailLineReducer("Mailing Address", mailingAddress?.addressLine1 == "" && mailingAddress?.addressLine2 == "" ? "-" : mailingAddress?.addressLine1 == "" ? <span>{mailingAddress?.addressLine2},{mailingAddress?.city},{mailingAddress?.state} {mailingAddress?.zipCode}</span> : mailingAddress?.addressLine2 == "" ? <span>{mailingAddress?.addressLine1},{mailingAddress?.city},{mailingAddress?.state} {mailingAddress?.zipCode}</span> : <span>{mailingAddress?.addressLine2},{mailingAddress?.addressLine1},  {mailingAddress?.city}, {mailingAddress?.state} {mailingAddress?.zipCode}</span>)}


          {mailLineReducer("Physical Address", physicalAddress?.addressLine1 == "" && physicalAddress?.addressLine2 == "" ? "-" : physicalAddress?.addressLine1 == "" ? <span>{physicalAddress?.addressLine2},{physicalAddress?.city},{physicalAddress?.state} {physicalAddress?.zipCode}</span> : physicalAddress?.addressLine2 == "" ? <span>  {physicalAddress?.addressLine1},{physicalAddress?.city},{physicalAddress?.state} {physicalAddress?.zipCode}</span> : <span> {physicalAddress?.addressLine2},{physicalAddress?.addressLine1},{physicalAddress?.city},{physicalAddress?.state} {physicalAddress?.zipCode}</span>)}
        </div>

      </Accordian>}
      </>
    )
  }

export const PhoneComponentInfo = (props) => {
  
    const {phoneEdit, setPhoneEdit, editDisabler, showEditButton, phoneInfo} = props
   
    const phoneinfoRef = useRef(null);
    useAutoFocus(phoneEdit,phoneinfoRef)
    
  return(
    <>
       { phoneEdit ? <MarketPersonalInfoComponent verifyPhoneHider={phoneEdit} editPhoneValOut={(val)=>{setPhoneEdit(val);}} /> :<Accordian
        accordianPaddingLeft={0}
        id={'PhoneInformation'}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={phoneinfoRef} tabIndex="-1">
            <Col md={10} xl={11}>
              <AccordianTitle
                accType={'Phone Information'}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>


            <Col md={2} xl={1} className="sum-prelink">
              {editDisabler && showEditButton && <Row noGutters>
                <WIcon
                  alt=""
                  src={editIcon}
                  size={"1px"}
                  className="editIcon"
                  fontawesomestyle={assignObj({ paddingLeft: 0 })}
                />
                <button type="button" className="sum-linktext mt-1" onClick={()=>setPhoneEdit(!phoneEdit)}>
                  <span>Edit</span>
                </button>
              </Row>}
            </Col>

          </Row>
        }
        titleSeperator
      >

        <div className="sum-card-wrap">
          {phoneInfo?.primary?.number === "" ? <div>{linesReducer("Primary Phone", phoneInfo?.primary?.mPhoneNumber === "" ? "-" : <span> {phoneInfo?.primary?.countryCode} {phoneInfo?.primary?.mPhoneNumber}</span>)}</div> : <div>{linesReducer("Primary Phone", phoneInfo?.primary?.number === "" ? "-" : <span> {phoneInfo?.primary?.countryCode} {phoneInfo?.primary?.number}</span>)}</div>}
          {phoneInfo?.secondary?.number === "" ? <div>{linesReducer("Secondary Phone", phoneInfo?.secondary?.mPhoneNumber === "" ? "-" : <span> {phoneInfo?.secondary?.countryCode} {phoneInfo?.secondary?.mPhoneNumber}</span>)}</div> : <div>{linesReducer("Secondary Phone", phoneInfo?.secondary?.number === "" ? "-" : <span> {phoneInfo?.secondary?.countryCode} {phoneInfo?.secondary?.number}</span>)}</div>}
        </div>

      </Accordian>}
    </>
  )
  }

export const EmailComponentInfo = (props) => {
    const {emailEdit, setEmailEdit, editDisabler, showEditButton, emailInfo } = props
     
    const emailinfoRef = useRef(null);
    useAutoFocus(emailEdit,emailinfoRef)
     
    return(
      <>
      { emailEdit ? <MarketPersonalInfoComponent verifyEmailHider={emailEdit} editEmailValOut={(val)=>{setEmailEdit(val);}} /> :<Accordian
        accordianPaddingLeft={0}
        id={'EmailInformation'}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={emailinfoRef} tabIndex="-1">
            <Col md={10} xl={11}>
              <AccordianTitle
                accType={'Email Information'}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>

            <Col md={2} xl={1} className="sum-prelink">
              {editDisabler && showEditButton && <Row noGutters>
                <WIcon
                  alt=""
                  src={editIcon}
                  size={"1px"}
                  className="editIcon"
                  fontawesomestyle={assignObj({ paddingLeft: 0 })}
                />
                <button type="button" className="sum-linktext mt-1" onClick={()=>setEmailEdit(!emailEdit)}>
                  <span>Edit</span>
                </button>
              </Row>}
            </Col>


          </Row>
        }
        titleSeperator
      >
        <div className="sum-card-wrap">
          {linesReducer("Primary Email", emailInfo?.primaryEmailAddress == "" ? emailInfo?.maskedPrimaryEmailAddress == "" ? "-" : <span>{emailInfo?.maskedPrimaryEmailAddress}</span>  : <span>{emailInfo?.primaryEmailAddress}</span>)}
          {linesReducer("Secondary Email", emailInfo?.secondaryEmailAddress == "" ? emailInfo?.maskedSecondaryEmailAddress == "" ? "-" : <span>{emailInfo?.maskedSecondaryEmailAddress}</span> : <span>{emailInfo?.secondaryEmailAddress}</span>)}
        </div>
      </Accordian>}
      </>
    )
  }

export const EmploymentComponentInfo = (props) => {
    const {employEdit, setEmployEdit, editDisabler, showEditButton, employmentInfo, employmentAddress, employmentStatusMetaDataValues, primarySourceMetaDataValues, industryMetaData} = props
  
    const employinfoRef = useRef(null);
    useAutoFocus(employEdit,employinfoRef)

    const getEmploymentAddressInfo = () => {
  
      return(
        <>
          {employmentAddress?.addressLine1 === "" && employmentAddress?.addressLine2 === "" ? null : employmentAddress?.addressLine1 === undefined && employmentAddress?.addressLine2 === undefined ? null : <div> {linesReducer("Employer's Address", employmentAddress?.addressLine1 == "" ? <span> {employmentAddress?.addressLine2},{employmentAddress?.city}, {employmentAddress?.state} {employmentAddress?.zipCode}</span> : employmentAddress?.addressLine2 == "" ? <span> {employmentAddress?.addressLine1},{employmentAddress?.city}, {employmentAddress?.state} {employmentAddress?.zipCode}</span> : <span> {employmentAddress?.addressLine2},{employmentAddress?.addressLine1},{employmentAddress.city},{employmentAddress?.state} {employmentAddress?.zipCode}</span>)}</div>}
        </>
      )
    }
  
    const getEmploymentInformation = () => {
  
      const noEmploynentCalegories = ['retired', 'student', 'unemployed', 'homemaker']    
      const isNoEmplymentCategory = noEmploynentCalegories.includes(employmentInfo?.status)

    
  
      return(
        <>
          {isNoEmplymentCategory ? 
            <div className="sum-card-wrap">
              <div>{linesReducer("Employment Status", getValue(employmentInfo?.status, employmentStatusMetaDataValues))}
              </div>

              {isValidValue(employmentInfo?.primarySource) ? <div> {linesReducer("Primary Source of Income",getValue(employmentInfo?.primarySource, primarySourceMetaDataValues))}</div> : null}
            </div> 
            :
            <div className="sum-card-wrap">    
              {isValidValue(employmentInfo?.status) ? <div> {linesReducer("Employment Status", <span>{getValue(employmentInfo?.status, employmentStatusMetaDataValues)}</span>)}</div> : null}
              {isValidValue(employmentInfo?.employmentClassName) ? <div> {linesReducer("Employment Class Information",<span>{employmentInfo.employmentClassName}</span>)}</div> : null}
              {isValidValue(employmentInfo?.industry) ? <div> {linesReducer("Industry",<span>{getValue(employmentInfo?.industry,industryMetaData)}</span>)}</div> : null}
              {isValidValue(employmentInfo?.industryDescription) ? <div> {linesReducer("Industry Description",<span>{employmentInfo?.industryDescription}</span>)}</div> : null}
              {isValidValue(employmentInfo?.primarySource) ? <div> {linesReducer("Primary Source of Income", <span>{getValue(employmentInfo?.primarySource,primarySourceMetaDataValues)}</span>)}</div> : null}
              {isValidValue(employmentInfo?.occupation) ? <div> {linesReducer("Occupation", <span>{employmentInfo.occupation}</span>)}</div> : null}
              {isValidValue(employmentInfo?.employerName) ? <div> {linesReducer("Employer's Name", <span>{convertToTitleCase(employmentInfo.employerName)}</span>)}</div> : null}
              {getEmploymentAddressInfo()}
            </div>  
          }
        </>
      )
    }
  
    return (
      <>
        { employEdit ? <MarketPersonalInfoComponent verifyEmpHider={employEdit} editEmpValOut={(val)=>setEmployEdit(val)} /> :
        
        <Accordian
          accordianPaddingLeft={0}
          id={'EmploymentInformationPrimary'}
          titleChild={
            <Row style={{ display: "flex", justifyContent: "space-between" }} ref={employinfoRef}>
              <Col md={10} xl={11}>
                <AccordianTitle
                  accType={'Employment Information-Primary'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                />
              </Col>          
              <Col md={2} xl={1} className="sum-prelink">
                {editDisabler && showEditButton && <Row noGutters>
                  <WIcon
                    alt=""
                    src={editIcon}
                    size={"1px"}
                    className="editIcon"
                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                  />
                  <button type="button" data-testid='employedit' className="sum-linktext mt-1" onClick={()=>setEmployEdit(!employEdit)}>
                    <span>Edit</span>
                  </button>
                </Row>}
              </Col>
            </Row>
          }
          titleSeperator
        >
          {getEmploymentInformation()}
         
        </Accordian>
        }
      </>
    )
  
  }

export const FinancialComponentInfo = (props) =>{
    const {financialEdit, setFinancialEdit, showEditButton, editDisabler, financialInfo, annualIncomeMetaDataValues, taxFillingStatusMetaData, netWorthMetaData} = props
    
    const financialinfoRef = useRef(null);
    useAutoFocus(financialEdit,financialinfoRef)
     

    const tempFinancilaCurrency = getValue(financialInfo?.annualIncome, annualIncomeMetaDataValues)
    const financilaCurrency = tempFinancilaCurrency?.split("-")

    let AnnualIncomeValue1 = '';
    let AnnualIncomeValue2 = '';
  
    if(financilaCurrency?.length > 1)
    {
      AnnualIncomeValue1 = financilaCurrency[0]
      AnnualIncomeValue2 = financilaCurrency[1]
    }
    else if(financilaCurrency?.length>0 && financilaCurrency?.length< 2)
    {
      let annualIncodeAndValue = financilaCurrency[0];
      annualIncodeAndValue = annualIncodeAndValue?.split('and');
      AnnualIncomeValue1 = annualIncodeAndValue[0];
      AnnualIncomeValue2 = annualIncodeAndValue[1];
    }
  
    AnnualIncomeValue1= formatCurrencyAndRemoveDecimalPart(AnnualIncomeValue1)
    AnnualIncomeValue2= formatCurrencyAndRemoveDecimalPart(AnnualIncomeValue2)
  
    const netWorth = getValue(financialInfo?.netWorth, netWorthMetaData)?.split("-")
    let networthValue1 = '';
    let netWorthValue2 = '';
    if(netWorth?.length > 1)
    {
      networthValue1 = netWorth[0]
      netWorthValue2 = netWorth[1]
    }
    else if(netWorth?.length>0 && netWorth?.length< 2)
    {
      let networthAndValue = netWorth[0];
      networthAndValue = networthAndValue?.split('and');
      networthValue1 = networthAndValue[0];
      netWorthValue2 = networthAndValue[1];
    }
  
  
    networthValue1= formatCurrencyAndRemoveDecimalPart(networthValue1)
    netWorthValue2= formatCurrencyAndRemoveDecimalPart(netWorthValue2)

    let taxBracketData = `${financialInfo?.taxBracket}`;
    taxBracketData = (taxBracketData?.includes('%') == true) ? taxBracketData : `${taxBracketData}%`;

    
    return(
    <>
    { financialEdit ? <MarketPersonalInfoComponent verifyFinHider={financialEdit} editFinValOut={(val)=>{setFinancialEdit(val);}} /> :
    <Accordian
      accordianPaddingLeft={0}
      id={'FinancialInformationPrimary'}
      titleChild={
        <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={financialinfoRef} tabIndex="-1">
          <Col md={10} xl={11}>
            <AccordianTitle
              accType={'Financial Information-Primary'}
              className="accordionH2Style"
              requireButtonPadding={false}
            /></Col>


          <Col md={2} xl={1} className="sum-prelink">
            {editDisabler && showEditButton && <Row noGutters>
              <WIcon
                alt=""
                src={editIcon}
                size={"1px"}
                className="editIcon"
                fontawesomestyle={assignObj({ paddingLeft: 0 })}
              />
              <button type="button" className="sum-linktext mt-1" onClick={()=>setFinancialEdit(!financialEdit)}>
                <span>Edit</span>
              </button>
            </Row>}
          </Col>


        </Row>
      }
      titleSeperator
    >

      <div className="sum-card-wrap">
      {linesReducer("Annual Income (USD)", financialInfo?.annualIncome == "" || financialInfo?.annualIncome == null || financialInfo?.annualIncome == undefined ? "-" : (financilaCurrency?.length < 2) ? <span>{`${currencyFormatter.format(AnnualIncomeValue1,{ locale: 'en-US' })} and ${AnnualIncomeValue2}`}</span> : <span>{ currencyFormatter.format(AnnualIncomeValue1, { locale: 'en-US' })}{"-"}{currencyFormatter.format(AnnualIncomeValue2, { locale: 'en-US' })}</span>)}
      
        {linesReducer("Tax Bracket", financialInfo?.taxBracket == "" || financialInfo?.taxBracket==null || financialInfo?.taxBracket==undefined ? "-" : <span> {taxBracketData}</span>)}
        {linesReducer("Net Worth (USD)", isValidValue(financialInfo?.netWorth) ? (netWorth?.length < 2) ? <span>{`${networthValue1} and ${netWorthValue2}`}</span> : <span>{networthValue1}{"-"}{netWorthValue2}</span> : '')}
        {linesReducer("Tax Filing Status", financialInfo?.taxFilingStatus == "" || financialInfo?.taxBracket == null || financialInfo?.taxBracket == undefined ? "-" : <span> {getValue(financialInfo?.taxFilingStatus,taxFillingStatusMetaData)}</span>)}
        </div>
    </Accordian>
    }
    </>
    )
  }

export const MilitaryComponentInfo = (props) => {
    const {militaryEdit, setMilitaryEdit, militaryInformation, showEditButton, editDisabler, militaryStatusMetaData, branchSourceMetaDataValues, materLookUpData} = props
    
    const militaryinfoRef = useRef(null);
    useAutoFocus(militaryEdit,militaryinfoRef)

    return (
      <>
        { militaryEdit ? <MarketPersonalInfoComponent verifyMilHider={militaryEdit} editMilValOut={(val)=>{setMilitaryEdit(val);}} /> :
        <Accordian
        accordianPaddingLeft={0}
        id={"personalinfo"}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }}  ref={militaryinfoRef} tabIndex="-1">
            <Col md={10} xl={11}>
              <AccordianTitle
                accType={'Military Information'}
                className="accordionH2Style"
                requireButtonPadding={false}
              /></Col>



            <Col md={2} xl={1} className="sum-prelink">
              {editDisabler && showEditButton && <Row noGutters>
                <WIcon
                  alt=""
                  src={editIcon}
                  size={"1px"}
                  className="editIcon"
                  fontawesomestyle={assignObj({ paddingLeft: 0 })}
                />
                <button type="button" className="sum-linktext mt-1" onClick={()=>setMilitaryEdit(!militaryEdit)}>
                  <span>Edit</span>
                </button>
              </Row>}
            </Col>

          </Row>
        }
        titleSeperator
      >

        <div className="sum-card-wrap">
           
          {militaryInformation?.hasServed === "N" ? <div>
      
            {linesReducer("Military Status", '-')}
            {linesReducer("Branch of Service", '-')}
            {linesReducer("Rank", '-')}
            {linesReducer("Dates of Service", '-')}
            {linesReducer("Commission Source", '-')}
            </div> :  <div>
            {linesReducer("Military Status", isValidValue(militaryInformation?.militaryStatus) ? getValue(militaryInformation?.militaryStatus,militaryStatusMetaData) : '-') }
                  {linesReducer("Branch of Service", isValidValue(militaryInformation?.branch) ? getValue(militaryInformation?.branch,branchSourceMetaDataValues) : '-')}
                  {linesReducer("Rank", isValidValue(militaryInformation?.rank) ? getRankValueBasedOnKey(militaryInformation?.rank,materLookUpData) : '-') }
                  {linesReducer("Dates of Service", militaryInformation?.startDate && militaryInformation?.endDate ? `${militaryInformation?.startDate-militaryInformation?.endDate}}`: '-')}
                  {linesReducer("Commission Source", isValidValue(militaryInformation?.commissionSource) ? militaryInformation?.commissionSource : '-')}
            </div>}

          </div>
        </Accordian>
        }
      </>
    )
  }



  const VerifyPersonalinfo = (props) => {

    //Hooks and Conditions for Displaying data from array using Key
    const [contactEdit, setContactEdit] = useState(false)
    const [phoneEdit, setPhoneEdit] = useState(false)
    const [emailEdit,setEmailEdit]=useState(false)
    const [employEdit, setEmployEdit] = useState(false)
    const [financialEdit, setFinancialEdit] = useState(false)
    const [militaryEdit, setMilitaryEdit] = useState(false)
    const {editDisabler, showEditButton,closeEditiableScreen,editPersonalInfoPage} = props
    const [personalEdit, setPersonalEdit] = useState(editPersonalInfoPage)
  
    const allPersonal= personalEdit || contactEdit || phoneEdit || emailEdit || employEdit || financialEdit || militaryEdit
    useEffect(() => {
        props.setEditDisableVal(!allPersonal)
    },[allPersonal])
  
    //from redux state
    const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData?.marketNAOInfo);

    const annualIncomeMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.brkg_annual_income?.value)
    const taxFillingStatusMetaData = useSelector(state => state.transactionCompositeDataReducerData?.tax_filling_status?.value)
    const netWorthMetaData = useSelector(state => state.transactionCompositeDataReducerData?.brkg_net_worth?.value)
    const primarySourceMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.prim_src_income?.value)
    const employmentStatusMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.brkg_employment_status?.value)
    const industryMetaData = useSelector(state => state.transactionCompositeDataReducerData?.industry?.value)
  
    const branchSourceMetaDataValues = useSelector(state => state.transactionCompositeDataReducerData?.mil_serv_branch?.value)
    const militaryStatusMetaData = useSelector(state => state.transactionCompositeDataReducerData?.mil_status?.value)
  
    const personalInfo = marketNAOInfoReducerData?.personalInfo?.primary
    const mailingAddress = marketNAOInfoReducerData?.addressInfo?.primary?.mailingAddress
    const physicalAddress = marketNAOInfoReducerData?.addressInfo?.primary?.physicalAddress
    const employmentInfo = marketNAOInfoReducerData?.employmentInfo?.primary
    const employmentAddress = marketNAOInfoReducerData?.employmentInfo?.primary?.address
    const financialInfo = marketNAOInfoReducerData?.financialInfo?.primary
    const phoneInfo = marketNAOInfoReducerData?.contactInfo?.primary?.phoneInfo
    const emailInfo = marketNAOInfoReducerData?.contactInfo?.primary?.emailAddresses
    const militaryInformation = marketNAOInfoReducerData?.militaryInfo?.primary
    
    const materLookUpData = useSelector(state => state.transactionCompositeDataReducerData);
    const loginData = useSelector(state => state.loginData);

    useEffect(() => {
  
      let flag = 0;
        if (phoneInfo?.primary?.number == "") {
  
          if (phoneInfo.primary.mPhoneNumber != "") {
            phoneInfo.primary['phoneNumberInfo'] = phoneInfo.primary.mPhoneNumber;
          }
        }
        else {
          phoneInfo.primary['phoneNumberInfo'] = phoneInfo.primary.number;
        }
  
        if (phoneInfo.secondary.number == "") {
  
          if (phoneInfo?.secondary?.mPhoneNumber != "") {
            phoneInfo.secondary['phoneNumberInfo'] = phoneInfo.secondary.mPhoneNumber;
          }
        }
        else {
          phoneInfo.secondary['phoneNumberInfo'] = phoneInfo.secondary.number;
        }
  
    })
  
    const role = getRole(loginData && loginData.getCustomerProfile);
  
  
  
    let unmaskedformattedSSN =  personalInfo?.withoutMaskedSSN == ("" || undefined) ? 
                                  personalInfo?.maskedSSN != ('' || undefined) ? personalInfo.maskedSSN : "-" 
                                : getFormattedSSN(personalInfo.withoutMaskedSSN)
                      
    if(role == 'Member'){
      // if member then display masked ssn
      unmaskedformattedSSN = personalInfo?.maskedSSN ? personalInfo.maskedSSN : ''
    }
  
    useEffect(() => {
      setPersonalEdit(editPersonalInfoPage)
    }, [editPersonalInfoPage]);
    
    const handlePersonalInfoComponentEditMode = (value) => {
      const { setPersonalInfoPage } = props;
      setPersonalInfoPage(false);
      setPersonalEdit(value);
    }
  
  
  
    return (
      <>
  
          <PersonalComponentInfo
            personalEdit={personalEdit}
            setPersonalEdit={setPersonalEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            personalInfo={personalInfo}
            role={role}
            unmaskedformattedSSN={unmaskedformattedSSN}
            closeEditiableScreen={closeEditiableScreen}
            editPersonalInfoPage={editPersonalInfoPage}
            handlePersonalInfoComponentEditMode={handlePersonalInfoComponentEditMode}
          />
         
         <ContactComponentInfo
            contactEdit={contactEdit}
            setContactEdit={setContactEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            mailingAddress={mailingAddress}
            physicalAddress={physicalAddress}
          />
  
          <PhoneComponentInfo
            phoneEdit={phoneEdit}
            setPhoneEdit={setPhoneEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            phoneInfo={phoneInfo}
          />
          
  
          <EmailComponentInfo
            emailEdit={emailEdit}
            setEmailEdit={setEmailEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            emailInfo={emailInfo}
          />
  
          <EmploymentComponentInfo
            employEdit={employEdit}
            setEmployEdit={setEmployEdit}
            editDisabler={editDisabler}
            showEditButton={showEditButton}
            employmentInfo={employmentInfo}
            employmentAddress={employmentAddress}
            employmentStatusMetaDataValues={employmentStatusMetaDataValues}
            primarySourceMetaDataValues={primarySourceMetaDataValues}
            industryMetaData={industryMetaData}
          />
  
  
        <FinancialComponentInfo
            financialEdit={financialEdit}
            setFinancialEdit={setFinancialEdit}
            editDisabler={props.editDisabler}
            showEditButton={props.showEditButton}
            financialInfo={financialInfo}
            annualIncomeMetaDataValues={annualIncomeMetaDataValues}
            taxFillingStatusMetaData={taxFillingStatusMetaData}
            netWorthMetaData={netWorthMetaData}
        />
       
        <MilitaryComponentInfo 
            militaryEdit={militaryEdit}
            setMilitaryEdit={setMilitaryEdit}
            militaryInformation={militaryInformation}
            editDisabler={props.editDisabler}
            showEditButton={props.showEditButton}
            militaryStatusMetaData={militaryStatusMetaData}
            branchSourceMetaDataValues={branchSourceMetaDataValues} 
            materLookUpData={materLookUpData}
        />
  
      </>
  
    )
  }
export default VerifyPersonalinfo

