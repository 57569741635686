import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import WButton from '../WButton/WButton';
import { Col, Row } from 'react-bootstrap';
import WIcon from 'common/WIcon/WIcon';
import editIcon from '../../assets/EditPencil.svg';

const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const ModalDialog = styled.div`
    @media (max-width: 767px) {
        padding: 10px;
        width: 90%;
        left: 5%;
    }
    left: 16%;
    top: 18%;
    z-index: 999;
    width: 67%;
    padding: 40px 120px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
`;
const ConformationModalTitle = styled.div`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;

const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    margin-top: 20px;
`;


class ConfirmPersonalAndBeneficiaryModel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.firstElRef = React.createRef();
        this.lastElRef = React.createRef();
    }
    assignObj = obj => {
        return obj;
      };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';
    }

    componentWillUnmount() {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }

    // redirect last tab to first input
    onLastElementTab = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.firstElRef.current.focus();
        }
    };

    render() {
        const { modalTitle, modalBodyText, modalBodyText2, primaryButtonText, onPrimaryClick,modalBodyText3,modalBodyText4,showRelationship,editPersonalInfoHandler,editBeneficiariesInfomationHandler,cancelStyle,handleCancelMaritialPopup  } = this.props;
        return (
            <ModalStyle tabIndex={0}>
                <ModalDialog role="dialog" aria-modal="true" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle id="VCM-Modal-title"> {modalTitle} </ConformationModalTitle>
                    <ConformationModalBody> {modalBodyText} </ConformationModalBody>
                    {modalBodyText2 ? <ConformationModalBody> {modalBodyText2} </ConformationModalBody> : null}
                    {showRelationship && (
                        <div>
                            <Row className='mt-3 mb-2'>
                                <Col md={6}>
                                    <b>Marital Status</b> <span className='ml-4'>{modalBodyText3}</span>
                                </Col>
                                <Col md={3} xl={2} className="sum-prelink p-0">
                                    <Row noGutters>
                                        <WIcon
                                            alt=""
                                            src={editIcon}
                                            size={"1px"}
                                            className="editIcon"
                                            fontawesomestyle={this.assignObj({ paddingLeft: 0 })}
                                        />
                                        <button type="button" data-testid='trustedcontactedit' className="sum-linktext" onClick={editPersonalInfoHandler}>
                                            <span>Edit</span>
                                        </button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <b>Relationship to Account Holder</b> <span className='ml-4'>{modalBodyText4}</span>
                                </Col>
                                <Col md={3} xl={2} className="sum-prelink p-0">
                                    <Row noGutters>
                                        <WIcon
                                            alt=""
                                            src={editIcon}
                                            size={"1px"}
                                            className="editIcon"
                                            fontawesomestyle={this.assignObj({ paddingLeft: 0 })}
                                        />
                                        <button type="button" data-testid='trustedcontactedit' className="sum-linktext" onClick={editBeneficiariesInfomationHandler}>
                                            <span>Edit</span>
                                        </button>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
                <FlexDiv>
                    

                            <WButton
                                role="button"
                                id="cancelButton"
                                variant="cancel"
                                buttontext='Cancel'
                                onClick={handleCancelMaritialPopup}
                                buttonstyle={this.assignObj({ float: 'right', display: 'block', ...cancelStyle })}
                                // arialabelforbtn={arialabelcancel}
                                // dataAnalyticId={`${dataAnalyticId}_cancel`}
                                className="rwdCancelBtn"
                                haspopup="true"
                            />
                    </FlexDiv>
                </ModalDialog>
            </ModalStyle>
        );
    }
}

ConfirmPersonalAndBeneficiaryModel.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    cancelStyle: PropTypes.instanceOf(Object),
};

ConfirmPersonalAndBeneficiaryModel.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    modalBodyText2: null,
    onPrimaryClick: () => {},
    primaryButtonText: '',
    cancelStyle: {},
};

export default ConfirmPersonalAndBeneficiaryModel;
