import React, { Component } from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WIcon from '../WIcon/WIcon'
import WSwitch from '../WSwitch/WSwitch';
import styles from '../CommonStyles';
import menuIcon from '../../assets/Kebab_menu_main.svg';
import './WManageCard.css';
import editIcon from '../../assets/EditPencil.svg';
import deleteIcon from '../../assets/deleteTrash.svg';
import StyledButton from '../StyledComponents';

const assignObj = (obj) => { return obj; };
const binder = ' - ';
const Delete = 'Remove';
const Edit = 'Edit';
const float = {float:'right'};
const iconStyle= {
  width:'14px',
  height:'19px'
}

class WManageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCard: false,
    };
  }

  iconClick = () => {
    const { menuCard } = this.state;
    this.setState({
      menuCard: !menuCard
    })
  }
  
  deleteEvent = (e) => {
    const { deleteEvent } = this.props;
    this.setState({
      menuCard: false
    });
    deleteEvent(e);
  }

  render() {
    const { mainText, textLine, city, state, zipcode, bottomLine, switch2, leftcornercard, bottomLine2
      , clickEvent, switchId, primaryChecked, onSwitchClick, phySwitchId, physicalChecked, onPhySwitchClick, hideRemove  } = this.props;
    const { menuCard} = this.state;
    return (
      <div>
        <div style={assignObj({ ...styles.contCard , margin: '15px 30px 15px 0px' , border: '1px solid rgba(210, 210, 210, 1)'})}>
          <div style={assignObj({ ...styles.contactInnerDiv })}>
            <div style={assignObj({ ...styles.mangeCornerCard, ...leftcornercard})} className="leftCornerCard" />
            <div style={assignObj({ fontWeight: 800 , color: 'rgba(73, 73, 74, 1)', fontSize: '18px', lineHeight: '22px', marginTop: '5px', marginLeft: '5px'})}>{mainText}</div>
            <button style={assignObj({ ...styles.menuIconStyle, margin: '-23px 6px 0px -6px' })} aria-haspopup="true" aria-expanded={menuCard} type="button" aria-label="" onClick={this.iconClick}><div style={assignObj({display:'inline-block', width: '5px', height: '5px'})} ><WIcon src={menuIcon} alt="" fontawesomestyle={iconStyle} /></div></button>
            {menuCard ?
              <div id="tooltipM" style={assignObj({height: hideRemove ? '50px' : '100px'})} className="bottom">
                <div className="tooltip-arrow" />
                <div className="tooltip-label">
                <button style={assignObj({ ...styles.editStyle}/* , {marginRight: '20px'} */)} type="button" aria-label="" onClick={clickEvent}><div style={assignObj({display:'inline-block'})} ><WIcon src={editIcon} alt="" fontawesomestyle={iconStyle} onClick={this.editClick} /></div> {Edit}</button>
                  </div>
                {!hideRemove && (
                  <>
                    <hr style={assignObj({ ...styles.menuLine })} aria-hidden="true" />
                    <div className="tooltip-label">
                      <button style={assignObj({ ...styles.deleteStyle })} type="button" aria-label="" onClick={this.deleteEvent}><div style={assignObj({display:'inline-block'})} ><WIcon src={deleteIcon} alt="" fontawesomestyle={iconStyle} /></div> {Delete}</button>
                    </div>
                  </>
                )}                 
              </div> 
              : ''}
            
            <div style={assignObj({marginLeft: '5px', fontWeight: 500, marginTop: '5px'})}>{textLine}</div>
            <div style={assignObj({marginLeft: '5px'})}>{city}</div>
            {state && zipcode ?
              <div style={assignObj({marginLeft: '5px'})}>{state}{binder}{zipcode}</div> : ''}
            </div>
          <hr style={assignObj({ width: '290px', height: '0px',border: '1px solid #D2D2D2', marginTop: '25px' })} aria-hidden="true"/>
          <div style={assignObj({ width: '290px', margin: '15px 30px 5px 30px'})}>
              <label style={assignObj({...styles.bottomLine1})}  title="" htmlFor={switchId}>{bottomLine}</label>
              <div style={float}>
              <WSwitch 
                id= {switchId}
                checked={primaryChecked}
                onSwitchClick={onSwitchClick}
              />
              </div>
          </div>
            {switch2 ?
            <div style={assignObj({width: '290px', margin: '5px 30px 30px 30px'})}>
            <label style={styles.bottomLine1} title="" htmlFor={switchId}>{bottomLine2}</label>
            <div style={float}>
              <WSwitch 
                id= {phySwitchId}
                checked={physicalChecked}
                onSwitchClick={onPhySwitchClick}
              />
              </div>
          </div>
               : ''}
          
          </div>
      </div>
    )
  }
}


WManageCard.propTypes = {
  mainText: PropTypes.string,
  textLine: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
  bottomLine: PropTypes.string,
  bottomLine2: PropTypes.string,
  switch2: PropTypes.bool,
  leftcornercard: PropTypes.instanceOf(Object),
  deleteEvent: PropTypes.func,
  clickEvent: PropTypes.func,
  hideRemove: PropTypes.bool,
  disable:PropTypes.bool,
  switchId:PropTypes.string,
  primaryChecked: PropTypes.bool,
  onSwitchClick: PropTypes.func,
  phySwitchId: PropTypes.string,
  physicalChecked: PropTypes.bool,
  onPhySwitchClick: PropTypes.func
}

WManageCard.defaultProps = {
  deleteEvent: () => {},
  clickEvent: () => {},
  onSwitchClick: () => {},
  mainText: "Title",
  textLine: "Text",
  bottomLine: 'Text',
  switch2: false,
  disable: false,
  hideRemove: false,
  city: '',
  state: '',
  zipcode: '',
  bottomLine2: '',
  switchId: '',
  phySwitchId:'',
  leftcornercard: {},
  primaryChecked: false,
  physicalChecked: false,
  onPhySwitchClick: () => {}
}

export default WManageCard
