import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import WButton from '../WButton/WButton';
import styled from 'styled-components';
const assignObj = obj => {
    return obj;
};

const AlignBtnRWD = styled(Col)`
    display: flex;
    justify-content: center;
    @media (min-width: 768px) {
        justify-content: left;
    }
`;

const main = {
    marginBottom: 30,
    marginTop: 3,
    background: '#FAFAFA 0% 0% no-repeat padding-box',
    display: 'flex',
    alignItems: 'center',
    height: 90,
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(210, 210, 210, 1)',
};
const hr = { marginBottom: 0, marginTop: 15, border: '1px solid #707070', opacity: '0.2' };
const CommonButtons = props => {
    const {
        backClick,
        backstyle,
        disabled,
        submit,
        submitClick,
        nextClick,
        nextstyle,
        saveClick,
        savestyle,
        showSave,
        blockstyle,
        submitText,
        nextText,
        backText,
        saveText,
        buttonWrapperStyle,
        arialabelback,
        arialabelnext,
        arialabelcancel,
        cancelStyle,
        cancelClick,
        cancelText,
        iscancel,
        analyzecustom,
        dataAnalyticId,
        cancelOffset,
        saveDisable,
        isShowBtn,
        showBack,
        conditionalBackButton,
        dataTestId=''
    } = props;
    return (
        <div style={blockstyle} className="rwdBtnWrapper mobileMarginTop">
            {showSave ? (
                <div className="container d-none d-md-block">
                    <Row
                        style={assignObj({
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 15,
                        })}
                    >
                        <Col xs={2} style={assignObj({ textAlign: 'right' })}>
                            <WButton
                                variant="save"
                                buttontext={saveText}
                                id="saveButton"
                                onClick={saveClick}
                                buttonstyle={assignObj({ ...savestyle })}
                                dataAnalyticId={`${dataAnalyticId}_save`}
                                waitforapiresponse
                                analyzecustom={analyzecustom}
                                disabled={saveDisable}
                            />
                        </Col>
                    </Row>
                </div>
            ) : null}
            <hr style={hr} aria-hidden="true" tabIndex="-1" />
            <div style={main} className="mobileNext desktopHideBtn">
                <div className="container">
                    <Row
                        className="mobileRow"
                        style={assignObj({
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: iscancel ? 'flex-end' : 'space-between',
                            
                            ...buttonWrapperStyle,
                        })}
                    >

                       
                            <AlignBtnRWD xs={12} md={8}  className="backbtnMobile">
                                {(conditionalBackButton == undefined || conditionalBackButton == null || conditionalBackButton == false) && 
                                <WButton
                                    role="button"
                                    id="backButton"
                                    variant="back"
                                    buttontext={backText}
                                    onClick={backClick}
                                    buttonstyle={assignObj({ float: 'left', display: 'block', ...backstyle })}
                                    aria-label={arialabelback}
                                    dataAnalyticId={`${dataAnalyticId}_back`}
                                    className="rwdBackBtn"
                                />
                            }
                        </AlignBtnRWD>
                       
                        {iscancel ? (
                            <Col xs={cancelOffset || 2}>
                                <WButton
                                    role="button"
                                    id="cancelButton"
                                    variant="cancel"
                                    buttontext={cancelText}
                                    onClick={cancelClick}
                                    buttonstyle={assignObj({ float: 'left', display: 'block', ...cancelStyle })}
                                    aria-label={arialabelcancel}
                                    dataAnalyticId={`${dataAnalyticId}_cancel`}
                                    className="rwdCancelBtn"

                                />
                            </Col>
                        ) : null}
                        {/* Commented For MSR Flow */}
                        {isShowBtn && <Col xs={2}>
                            <WButton
                                role="button"
                                id="nextButton"
                                disabled={disabled}
                                aria-disabled={disabled}
                                data-testid={dataTestId}
                                buttontext={submit === 'true' ? submitText : nextText}
                                onClick={submit === 'true' ? submitClick : nextClick}
                                analyzecustom={submit === 'true' ? analyzecustom : null}
                                waitforapiresponse={submit === 'true' ? true : false}
                                dataAnalyticId={
                                    submit === 'true' ? `${dataAnalyticId}_submit` : `${dataAnalyticId}_next`
                                }
                                aria-label={arialabelnext}
                                buttonstyle={assignObj({
                                    float: 'left',
                                    width: '100%',
                                    display: 'block',
                                    ...nextstyle,
                                })}
                            />
                        </Col>}
                    </Row>
                </div>
            </div>
        </div>
    );
};
CommonButtons.propTypes = {
    backClick: PropTypes.func,
    submitClick: PropTypes.func,
    nextClick: PropTypes.func,
    saveClick: PropTypes.func,
    cancelClick: PropTypes.func,
    savestyle: PropTypes.instanceOf(Object),
    backstyle: PropTypes.instanceOf(Object),
    nextstyle: PropTypes.instanceOf(Object),
    blockstyle: PropTypes.instanceOf(Object),
    cancelStyle: PropTypes.instanceOf(Object),
    submit: PropTypes.string,
    disabled: PropTypes.bool,
    showSave: PropTypes.bool,
    submitText: PropTypes.string,
    nextText: PropTypes.string,
    saveText: PropTypes.string,
    backText: PropTypes.string,
    cancelText: PropTypes.string,
    buttonWrapperStyle: PropTypes.instanceOf(Object),
    arialabelback: PropTypes.string,
    arialabelnext: PropTypes.string,
    arialabelcancel: PropTypes.string,
    ariadisable: PropTypes.bool,
    iscancel: PropTypes.bool,
    analyzecustom: PropTypes.instanceOf(Object),
    dataAnalyticId: PropTypes.string,
    cancelOffset: PropTypes.instanceOf(Object),
    isShowBtn: PropTypes.string,
};
CommonButtons.defaultProps = {
    backClick: null,
    submitClick: null,
    nextClick: null,
    saveClick: null,
    savestyle: {},
    backstyle: {},
    nextstyle: {},
    blockstyle: {},
    submit: 'false',
    disabled: false,
    showSave: false,
    submitText: 'Submit',
    nextText: 'Next',
    saveText: 'Save',
    backText: 'Back',
    cancelText: 'Cancel',
    cancelStyle: {},
    cancelClick: null,
    buttonWrapperStyle: {},
    arialabelback: '',
    arialabelnext: '',
    arialabelcancel: '',
    ariadisable: true,
    iscancel: false,
    analyzecustom: null,
    dataAnalyticId: '',
    cancelOffset: null,
    isShowBtn: false,
    dataTestId: ''
};
// To display save button this component should be given showSave as prop.
// 'disabled' prop which is given to this component is used to disable only the NEXT or SUBMIT button.
export default CommonButtons;