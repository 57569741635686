export const RETIREMENT_PLANNER_CALCULATOR = 'Retirement Planner Calculator';
export const COLLEGE_SAVINGS_CALCULATOR = 'College Savings Calculator';
export const TRADITIONAL_IRA_CALCULATOR = 'Traditional IRA Calculator';
export const RETIREMENT_NEST_EGG_CALCULATOR = 'Retirement Nestegg Calculator';
export const RMD_FUTURE_CALCULATOR = 'RMD Future Calculator';
export const RMD_CURRENT_CALCULATOR = 'RMD Current Calculator';
export const CALCULATORS = 'Calculators';
export const SHARE_LABEL = 'Share:';
export const RELATED_ARTICLES = 'Related Articles:';
export const CALCULATOR_DESCRIPTION =
    'Do you know what it takes to work towards a secure retirement? Use this calculator to help you create your retirement plan. View your retirement savings balance and your withdrawals for each year until the end of your retirement. Social Security is calculated on a sliding scale based on your income. Including a non-working spouse in your plan increases your Social Security benefits up to, but not over, the maximum.';

export const BREAD_CRUMB_VALUES = [
    { url: '/', name: 'Home' },
    { url: '/', name: 'Member' },
    { url: '/', name: 'Advise & Tools' },
];
