import React from 'react';
import PropTypes from 'prop-types';

const assignObj = obj => obj;

const styles = {
    head: {
        position: "relative",
        marginBottom: "30px",
        display: 'inline-block',
        borderBottom: "2px solid #8BC105"
    },
    title: {
        font: '800 30px/36px  "yorkten-slab-normal"',
        color: '#486D90',
        marginBottom: 10
    },
    subtitle: {
        font: '600 24px/28px "benton-sans"',
        color: '#56565A'
    },
    nametitle: {
        font: '600 24px/28px "benton-sans"',
        color: '#56565A',
        display: 'block',
        marginBottom: '.5rem',
    },
    info: {
        font: '500 14px/22px "benton-sans"',
        color: '#49494A',
        marginBottom: 0
    }
};
const SideHeader = (props) => {
    const { info, title, subtitle, sideHeaderBlockStyle, headStyle, nameTitle } = props
    return (
        <div style={sideHeaderBlockStyle}>
            <div style={assignObj({...styles.head, ...headStyle})}>
                <h2 style={styles.title} className="sideHeaderH1tag">{title}</h2>
            </div>
            {subtitle ? <h2 style={styles.subtitle}>{subtitle}</h2> : null}
            {nameTitle && <span style={styles.nametitle}>{nameTitle}</span>}
            {info ? info.map((para, index) => (
                <p style={styles.info} key={Number(index + 1)}>{para}</p>
            )) : null}
        </div>
    );
};

SideHeader.propTypes = {
    info: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sideHeaderBlockStyle: PropTypes.instanceOf(Object),
    headStyle: PropTypes.instanceOf(Object),
    nameTitle: PropTypes.string,
}

SideHeader.defaultProps = {
    info: null,
    title: 'Title',
    subtitle: null,
    sideHeaderBlockStyle: {},
    headStyle: {},
    nameTitle: '',
}
export default SideHeader;