import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
    setCountryCode,
    setPhoneNumber,
    setIsMobile,
    setSecondaryCountryCode,
    setSecondaryPhoneNumber,
    setSecondaryIsMobile,
    setPhoneNumberError,
    setSecondaryPhoneNumberError
} from 'shared/Actions/ProfileRelationAction';
import {
    formatPhoneNumberWithoutCountryCode
} from "utils";
import { PhoneCard, SectionTitleWithSeparator } from "modules/CustomerManagementModule/components";
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';

const PHONE_NUMBER_ERROR_2 = "Invalid Phone Number";

export const handleCountryCodeChange = (dispatch, action) => (e)=>{
    const { target } = e;
    let { value } = target;
    if(value && value.length > 3) value = value.slice(0, -1);
    dispatch(action(value.replace(/\D/,'')));
}

export const handleCountryCodeBlur = (dispatch, action) => (e) => {
    const { value } = e.target;
    const countryCode = value ? `+${value}` : '';
    dispatch(action(countryCode));
}

export const removeNonNumberValues = (dispatch, action) => (e) => {
    const { value } = e.target;
    dispatch(action(value.replace(/\D/g,'')));
}

export const handleIsMobileToggle = (dispatch, action) => (value) => {
    dispatch(action(value));
}

export const handlePhoneNumberBlur = (dispatch, action, phoneAction) => (e) => {
    const { target } = e;
    const { value } = target;
    let error = '';
    let formatedPhone = value;
    if(value && value.length < 10) {
        error = PHONE_NUMBER_ERROR_2;
    }else {
		formatedPhone = formatPhoneNumberWithoutCountryCode(value);
    }
    dispatch(action(error));
    dispatch(phoneAction(formatedPhone));
}

const PhoneCardComponent = () => {
	
	const phoneInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { relationPhoneInformation } = profileRelationshipData;
        return relationPhoneInformation;
    });
     
    const { countryCode, phoneNumber, secondaryCountryCode, secondaryPhoneNumber, secondaryIsMobile, phoneNumberError, SecondaryPhoneNumberError } = phoneInfo;
    const dispatch = useDispatch();
    
	return (
		<>
			<SectionTitleWithSeparator titleTxt={CONST.PHONE_NUMBER_TITLE} optional />
			<Row>
				<Col xs md={8}>
					<PhoneCard 
						cardTitle={CONST.PRIMARY_PHONE_TITLE} 
						countryCodeValue={countryCode}
						phoneNumberValue={phoneNumber}
						phoneNumErrValue={phoneNumberError}
						onPhoneNumberError={handlePhoneNumberBlur(dispatch, setPhoneNumberError, setPhoneNumber)}
						changeIsMobile={handleIsMobileToggle(dispatch, setIsMobile)}
						changeCountryCode={handleCountryCodeChange(dispatch, setCountryCode)}
						onCountryCodeBlur={handleCountryCodeBlur(dispatch, setCountryCode)}
						onCountryCodeFocus={removeNonNumberValues(dispatch, setCountryCode)}
						onPhoneNumberFocus={removeNonNumberValues(dispatch, setPhoneNumber)}
						changePhoneNumber={removeNonNumberValues(dispatch, setPhoneNumber)}
					/>
					<PhoneCard 
						cardTitle={CONST.SECONDARY_PHONE_TITLE} 
						countryCodeValue={secondaryCountryCode}
						phoneNumberValue={secondaryPhoneNumber}
						isMobileValue={secondaryIsMobile}
						phoneNumErrValue={SecondaryPhoneNumberError}
						onPhoneNumberError={handlePhoneNumberBlur(dispatch, setSecondaryPhoneNumberError, setSecondaryPhoneNumber)}
						changeIsMobile={handleIsMobileToggle(dispatch, setSecondaryIsMobile)}
						changeCountryCode={handleCountryCodeChange(dispatch, setSecondaryCountryCode)}
						onCountryCodeBlur={handleCountryCodeBlur(dispatch, setSecondaryCountryCode)}
						onCountryCodeFocus={removeNonNumberValues(dispatch, setSecondaryCountryCode)}
						onPhoneNumberFocus={removeNonNumberValues(dispatch, setSecondaryPhoneNumber)}
						changePhoneNumber={removeNonNumberValues(dispatch, setSecondaryPhoneNumber)}
					/>
				</Col>
			</Row>
		</>
	)
}

export default PhoneCardComponent