import React, { Component } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { WBreadCrumb, WButton } from '../../../../common'
import constAC from './constAC'
import '../../../../App.css';
import VCMBasicHeadingComponent from '../../../../common/BasicInfo/VcmBasicHeadingComponent';

const tabStyle = { marginLeft: '15px', width: '97%' };
const alertText = 'Online trading will cease 30 minutes prior to fund close <Display fund close time as lesser of Rule C01 and C02 in HH:MM format>. Trade submitted after online cutoff time will be priced as of next business day.';
const TRANS_DASHBOARDURL = '/transactionDashboard';
const breadCrumbURL = [
    { url: TRANS_DASHBOARDURL, name: 'Home' },
    { url: TRANS_DASHBOARDURL, name: 'Portfolio' },
    { url: TRANS_DASHBOARDURL, name: 'Transactions' }
];

const btnStyleNext = {
    width: '160px',
    float: 'right'
}

const alertInsideCard = { padding: '25px', width: '1100px', textAlign: 'left', letterSpacing: '0.32px', color: '#333333DE', opacity: '1', font: '600 16px benton-sans, sans-serif'};

const divFooterStyle = {padding: '2.5em 0em'};

const doNotHoldTextDivStyle = {font: 'bold 18px benton-sans, sans-serif', padding: '15px 0px 25px 0px'};

const fieldHeadStyle = {
    height: '21px',
    textAlign: 'left',
    letterSpacing: '0px',
    opacity: 1,
    marginBottom: '10px',
    marginLeft: '15px',
    font: '600 16px benton-sans, sans-serif',
    color: '#004A98'
}

const Alert = styled.div`
position: relative;
background: #ffffff 0% 0% no-repeat padding-box;
border: 1px solid #d2d2d2;
opacity: 1;
&::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #8BC105;
    position: absolute;
    top: -1px;
    left: -1px;
}
`
const flexStyle = {
    display: 'flex'
}

const fundDivStyle = {
    padding: '25px', 
    background: '#FAFAFA'
}

const trStyleShade = {
    display: 'flex',
    padding: '20px',
    background: '#FAFAFA'
}

const trStyleWhite = {
    display: 'flex',
    padding: '20px',
    background: '#FFF'
}

const FONTSTYLE = '16px benton-sans, sans-serif';

const tdFundMainStyle = {
    font: FONTSTYLE, 
    flex: 0.8 ,
    color: '#49494A'
}

const tdFundSubStyle = {
    font: FONTSTYLE, flex: 4 , color: '#49494A', fontWeight: 600
}

const tdMainStyle = {
    font: FONTSTYLE, 
    flex: 0.8 ,
    color: '#49494A', 
    fontWeight: 600
}

const tdSubStyle = {
    font: FONTSTYLE, 
    flex: 1.6 , 
    color: '#49494A'
}

const trBorderBottom = {
    display: 'flex',
    padding: '20px',
    background: '#FFF',
    borderBottom: '1px solid #E6E6E6' 
}

const headingTitleStyle = {
    color: '#486d90',
    fontFamily: 'yorkten-slab-normal, sans-serif',
    fontWeight: '800',
    fontStyle: 'normal',
    fontSize: '22px',
    letterSpacing: '0',
    paddingBottom: '15px',
    borderBottom: '2px solid #E6E6E6'
}

class AmendCancel extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            availableData: {},
            active: false,
            block: false,
            buttonSubmit: 'Submit',
            disabled: false,
            type: 'button',
            variantNext: 'light',
            variantCancel: 'outline',
            buttonCan: 'Cancel'
        }
    }

    componentDidMount(){
        const {location} = this.props;
        try{
            if(location.state){
                const {cancellationData} = location.state;
                this.setState({
                    availableData: cancellationData
                })
            }
        }catch(err){
            // console.log(err);
        }
    }

    cancelHandler = () => {
        const { history } = this.props;
        if (history) {
            history.push({
                pathname: '/amendTransaction'
            });
        } 
    }

    submitHandler = () => {
        const { history } = this.props;
        if (history) {
            history.push({
                pathname: '/amendTransaction',
                state: {
                    notification: true
                }
            });
        }
    }

    render() {
        const { active, block, buttonSubmit, disabled, type, variantNext, variantCancel, buttonCan } = this.state;
        const {availableData} = this.state; 
        const activeBreadCrumb = 'Cancellation';
        const mainHeading = 'Cancellation';
        const sellingAmountText = `Selling Amount`;
        const dollarSymbol = ' $ ';
        const percentageSymbol = ' % ';
        const selectedFundsHeadingText = 'Selected Mutual Funds';
        const accountSelectionText = 'Account Selection';
        const fundingSourceText = 'Funding Source';
        const taxAccountingText = 'Tax Accounting Method';
        const requestedAmountText = 'Is the requested amount';
        const amountBeforeText = 'Amount Before Taxes';
        const federalTaxText = 'Federal Tax';
        const stateTaxText = 'State Tax';
        const totalTaxWithholdText = 'Total Taxes to be withhold';
        const totalTaxReceiveText = 'Total you will receive';
        const totalWithdrawalText = 'Total Withdrawal';
        const accountNameText = 'Account Name';
        const accountNumberText = 'Account Number';
        // const docInformation = availableData.map(data => {
        // let x = false;
        // const rowData = data.fields.map((fieldVal) => {
        //     {x ? x = false: x = true}    
        //     return (
        //         <table style={tabStyle} key={fieldVal.key}>
        //             {fieldVal.doNotHoldText ? (<div style={doNotHoldTextDivStyle}>{fieldVal.doNotHoldText}</div>) : (
        //                 <div style={{backgroundColor: (x) ? '#FAFAFA' : '#FFFFFF', padding: '15px'}} key="">
                        
        //                 {fieldVal.heading ? (<div style={fieldHeadStyle}>{fieldVal.heading}</div>) : '' }
        //                 <tr style={{display: 'flex'}}>
        //                     <td style={{font: FONTSTYLE, flex: 0.8 ,color: '#49494A', fontWeight: (fieldVal.heading ? '' : 601)}}>{fieldVal.text}</td>
        //                     <td style={{font: FONTSTYLE, flex: (fieldVal.heading ? 4 : 1.6) , color: '#49494A',  fontWeight: (fieldVal.heading ? 601 : '')}}>{fieldVal.value}</td>
        //                 </tr>
        //             </div>
        //             )}
        //         </table>
        //         )
        //     })
        //     return (
        //     <div>
        //     <h4 style={headingTitleStyle} key={data.mainHeading}>{data.mainHeading}</h4>
        //     <hr/>
        //     {rowData}
        //     <br/>
        //     </div>)
        // })

        return (
            <div>
                <div style={constAC.wrapperStyles}>
                    <div className="container">
                        <WBreadCrumb activeCrumb={activeBreadCrumb} breadCrumbItems={breadCrumbURL}/>
                        <VCMBasicHeadingComponent labelvalue={mainHeading}/>
                        <br/>
                        <div>
                            <h4 style={headingTitleStyle}>{selectedFundsHeadingText}</h4>
                        </div>
                        <br/>
                        { availableData.data &&
                            availableData.data.selectedFundData.funds.map(item => (
                                <div style={fundDivStyle}>
                                    <div>
                                        <div style={fieldHeadStyle}>{item.fundName}</div>
                                        <table style={tabStyle}>
                                            <tbody>
                                            <tr style={flexStyle}>
                                            <td style={tdFundMainStyle}>{sellingAmountText}</td>
                                            <td style={tdFundSubStyle}>{dollarSymbol}{item.sellingAmount}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                              
                            ))
                        }
                        <br/>
                        <br/>
                        <div>
                            <h4 style={headingTitleStyle}>{accountSelectionText}</h4>
                        </div>
                        <br/>
                        {
                            availableData.data && (
                                <div>
                                    <table style={tabStyle}>
                                        <tbody>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{accountNameText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedAccountData.accountName}</td>
                                        </tr>
                                        <tr style={trBorderBottom}>
                                        <td style={tdMainStyle}>{accountNumberText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedAccountData.accountNumber}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>     
                            )
                        }
                        <br/>
                        <br/>
                        <div>
                            <h4 style={headingTitleStyle}>{fundingSourceText}</h4>
                        </div>
                        <br/>
                        {
                            availableData.data && (
                                <div>
                                    <table style={tabStyle}>
                                        <tbody>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{fundingSourceText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedAccountData.accountName}</td>
                                        </tr>
                                        <tr style={trBorderBottom}>
                                        <td style={tdMainStyle}>{accountNumberText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedAccountData.accountNumber}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>     
                            )
                        }
                        <br/>
                        <br/>
                        <div>
                            <h4 style={headingTitleStyle}>{taxAccountingText}</h4>
                        </div>
                        {
                            availableData.data && (
                                <div>
                                    <div style={doNotHoldTextDivStyle}>{availableData.data.selectedFundWithdrawalData.taxWithHoldingOption}</div>
                                    <table style={tabStyle}>
                                        <tbody>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{requestedAmountText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedFundWithdrawalData.requestedAmountType}</td>
                                        </tr>
                                        <tr style={trStyleWhite}>
                                        <td style={tdMainStyle}>{amountBeforeText}</td>
                                        <td style={tdSubStyle}>{dollarSymbol} {availableData.data.selectedFundWithdrawalData.amountBeforeTaxes}</td>
                                        </tr>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{federalTaxText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedFundWithdrawalData.federalTaxInPerc} {percentageSymbol}</td>
                                        </tr>
                                        <tr style={trStyleWhite}>
                                        <td style={tdMainStyle}>{stateTaxText}</td>
                                        <td style={tdSubStyle}>{availableData.data.selectedFundWithdrawalData.stateTaxInPerc} {percentageSymbol}</td>
                                        </tr>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{totalTaxWithholdText}</td>
                                        <td style={tdSubStyle}>{dollarSymbol} {availableData.data.selectedFundWithdrawalData.totalTaxToBeWithHold}</td>
                                        </tr>
                                        <tr style={trStyleWhite}>
                                        <td style={tdMainStyle}>{totalTaxReceiveText}</td>
                                        <td style={tdSubStyle}>{dollarSymbol} {availableData.data.selectedFundWithdrawalData.totalYouWillReceive}</td>
                                        </tr>
                                        <tr style={trStyleShade}>
                                        <td style={tdMainStyle}>{totalWithdrawalText}</td>
                                        <td style={tdSubStyle}>{dollarSymbol} {availableData.data.selectedFundWithdrawalData.totalWithdrawal}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>     
                            )
                        }
                        <br/>
                        <br/>
                        <Alert>
                            <div style={alertInsideCard}>
                                 <p>{alertText}</p>
                            </div>
                        </Alert>
                        <br/>
                        <hr/>
                        <div style={divFooterStyle}>
                            <WButton
                                active={active}
                                block={block}
                                buttontext={buttonCan}
                                disabled={disabled}
                                type={type}
                                variant={variantCancel}
                                onClick={this.cancelHandler}
                            />
                            <WButton
                                active={active}
                                block={block}
                                buttonstyle={btnStyleNext}
                                buttontext={buttonSubmit}
                                disabled={disabled}
                                type={type}
                                variant={variantNext}
                                onClick={this.submitHandler}
                            />
                       </div>
                   </div>
                </div>
            </div>
        )
    }
}

AmendCancel.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.instanceOf(Object).isRequired
}

export default AmendCancel
