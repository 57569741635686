/**
 * Manage Beneficaiaries Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to Manage Beneficaiaries
 * @createdDate [03/12/2020]
 * ***************************************
 * @lastModifiedDate [03/17/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Table } from 'react-bootstrap';
import EditIcon from 'assets/edit.png';
import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
import { forEach, filter, cloneDeep, isEmpty, floor } from 'lodash';
import routeConstants from 'routeConstants';
import { getFormattedSSN, createBreadCrumbItem } from 'utils';
import { 
    STEP_KEY, 
    BENEFICIARIES_EDIT_PAGE_NAME, 
    BENEFICIARIES_DELETE_BUTTON_ID,
    BENEFICIARIES_EDIT_BUTTON_ID,
    BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_HOME,
    BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_VERIFY,
    BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_CHANGES,
    BENEFICIARIES_ADD_PRIMARY_BENEFICIARY_BUTTON_ID,
    BENEFICIARIES_ADD_CONTINGENT_BENEFICIARY_BUTTON_ID,
    BENEFICIARIES_DISTRIBUTE_EQUALLY_BUTTON_ID,
    BENEFICIARIES_EDIT_NAV_BUTTON_ID,
    BENEFICIARIES_EDIT_VERIFY_VERIFY_NAV_BUTTON_ID,
    BENEFICIARIES_EDIT_VERIFY_CONFIRM_NAV_BUTTON_ID,
} from '../../../utils/AdobeAnalytics/Constants';
import routesBreadCrumb from '../../../utils/Constants/routesBreadcrumb'
import {
    AccountSelectedInfoCard,
    ConformationModal,
    WButton,
    WBreadCrumb,
    WSpinner,
    CommonButtons,
    WMessageBar
} from '../../../common';
import consts from './consts';
import './ManageBeneficiaries.css';
import StyledButton from '../../../common/StyledComponents';
import {  StyledCard } from './ManageBeneficiariesHeaderComponent'
import AddBeneficiaryForm from './AddBeneficiaryForm'
import { getSessionValues } from 'shared/Helpers/Utils';
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';

const nextStyle = {
    display: 'none',
};
const assignObj = obj => {
    return obj;
};

const PRIMARY = 'primary_Bene';
const CONTINGENT = 'contingent_Bene';
const NEW = 'New';
const DELETE = 'Deleted';
const INACTIVE = 'I';

const VerifyPage = styled.div``;
const VerifySection = styled.div``;
const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const SectionTitle = styled(FlexRowDiv)`
    justify-content: space-between;
    align-items: center;
`;


const TitleTxt = styled.div`
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;



const EditSection = styled(StyledButton)`
    display: flex;
    align-self: center;
`;

const EditDiv = styled.div`
    cursor: pointer;
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-left: 0.313rem;
`;
EditDiv.displayName = 'EditDiv';

const Separator = styled.hr`
    border: 1px solid #d2d2d2;
    opacity: 1;
    margin-top: 0.125rem;
`;
const EditImage = styled.img`
    margin-left: auto;
`;

const SectionBody = styled(FlexColumnDiv)`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
`;

const Field = styled.div`
    flex: 0 0 15em;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
`;
const Value = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
`;
const RedValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: red;
    opacity: 1;
    margin-bottom: 2.5rem;
`;

const StyledInput = styled.input`
    width: 200px;
    height: 48px;
    border-radius: 0px;
    padding-left: 10px;
    background: white;
    && {
        border: 1px solid rgb(210, 210, 210);
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            border-color: rgba(0, 74, 152, 1);
        }
        &:disabled {
            color: black;
        }
    }
`;

const StyledPercent = styled.span`
    position: absolute;
    right: 10px;
    bottom: 13px;
`;

const StyledError = styled.span`
    position: absolute;
    font-size: 14px;
    color: #ff0000;
    top: 50px;
`;

const bradcrumbPaths = [
    routeConstants.accountholder, 
    routeConstants.accountservices,
    routeConstants.manageBeneficiariesAccounts
]    

const initialState = {
        showMenu: '',
        addPrimaryBen: false,
        addContingentBen: false,
        isEditingBeneficiary: false,
        showAccountNumber: false,
        beneficiaryFlag: {},
        currentlyHandledBeneficiary: null, // used for Add and Delete
        distributionError: false,
        distributionErrorIndex: null,
        updatableBeneficiary: null,
        distributionCategory: '',
        showVerifyChanges: false,
        displayNotification: false,
        notificationMsg: '',
        openConformationDialog: false,
        deleteModalBody: '',
        verifyData: false,
        selectedIndex: -1,
        modifiedBeneficiary: {},
        retrivedState: false,
    }

class ManageBeneficiaries extends Component {
    constructor(props) {
        super(props);
        const { location } = props;
        const { masterRegId } = location.state || {};
        this.state = {
            masterRegId,
            beneficiaryData: [],
            ...initialState
        };
        this.handleSubmitNewBeneficiary = this.handleSubmitNewBeneficiary.bind(this);
        this.handleEditFormClosed = this.handleEditFormClosed.bind(this);

        this.addBeneficiaryFormRef = React.createRef();
        this.addBeneRef = React.createRef();
    }

    
    componentDidMount() {
        const { getBeneficiaries, location } = this.props;
        
        if (undefined === location || undefined === location.state || undefined === location.state.masterRegId) {
            const { history } = this.props;
            history.push({
                pathname: routeConstants.manageBeneficiariesAccounts
            });
            return;
        }
        
        const { masterRegId } = location.state;
        const params = getSessionValues() 

        const {accesstoken, vcmid, msrid, fismemberid} = params;
        const reqParams = {
            masterRegId,
            accesstoken,
            vcmid,
            msrid,
            fismemberid,
        }
        getBeneficiaries(reqParams);
    }

    static getDerivedStateFromProps(props, prevState){
        const { isSuccessAddBeneficiary, getBeneficiaries, originalBeneficiaryData } = props;
        const { masterRegId } = prevState;
        

        if (isSuccessAddBeneficiary) {
            getBeneficiaries({ masterRegId });
            return {
                ...initialState,
            }
        } 
        if (!isEmpty(originalBeneficiaryData) ) {
            return {
                beneficiaryData: [cloneDeep(originalBeneficiaryData)],
                retrivedState: true,
            };
        }
        return null
    }

    componentWillUnmount(){
        sessionStorage.setItem(STEP_KEY, BENEFICIARIES_EDIT_PAGE_NAME);
    }

    getBreadCrumbHomeItems = () => {
        return bradcrumbPaths.map( route => (
            createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_HOME)
        ))
    }

    getBreadCrumbChangesItems = () => {
        return bradcrumbPaths.map( route => (
            createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_CHANGES)
        ))
    }

    getBreadCrumbVerifyItems = () => {
        return bradcrumbPaths.map( route => (
            createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_BENEFICIARIES_EDIT_VERIFY)
        ))
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    showOptions = e => {
        const { showMenu } = this.state;
        const temp = showMenu === e.target.name ? '' : e.target.name;
        this.setState({ showMenu: temp });
    };

    deleteBeneClick = (item, beneType) => {
        const currentlyHandledBeneficiary = {
            nbrSeq: item.nbrSeq,
            bene_Name: item.bene_Name,
            type: beneType
        }

        this.setState({
            showMenu: '',
            currentlyHandledBeneficiary,
            openConformationDialog: true,
            deleteModalBody: {
                heading: `${consts.deleteBeneficiary.modalBody} ${item.bene_Name} as deleted?`,
                paragraph: consts.deleteBeneficiary.modalParagraph
            }
        });

        const { analyzeClickEvent } = this.props;
        analyzeClickEvent( {
            link: BENEFICIARIES_DELETE_BUTTON_ID
        })
    };

    editBeneClick = (item, e) => {
        e.preventDefault();
        this.setState({ 
            isEditingBeneficiary: true,
            updatableBeneficiary: item,
            showMenu: ''
        }, () => {
            window.scrollTo({
                top: this.addBeneficiaryFormRef.current.offsetTop,
                behavior: 'smooth',
            });
        });
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent( {
            link: BENEFICIARIES_EDIT_BUTTON_ID
        })
    };

    onConformationDelete = () => {
        const { currentlyHandledBeneficiary: beneficiary } = this.state;
        const { beneficiaryData } = this.state;

        // Update the beneficiary state to "Inactive"
        const beneficiaries = this.getCurrentBeneficiaries(beneficiary.type);
        beneficiaries.forEach(item => {
            if (item.nbrSeq === beneficiary.nbrSeq) {
                item.beneStat = INACTIVE;
                item.distribution_Per = "0";
            }
        });

        this.setState({
            beneficiaryData,
            openConformationDialog: false,
            showVerifyChanges: true
        });
    }

    addPrimaryBeneficiary = e => {
        e.preventDefault();
        this.setState({ addPrimaryBen: true });
    };

    addContingentBeneficiary = e => {
        e.preventDefault();
        this.setState({ addContingentBen: true });
    };

    handleCancel = e => {
        window.scrollTo({
            top: this.addBeneficiaryFormRef.current.offsetTop,
            behavior: 'smooth',
        });

        const { addContingentBen, addPrimaryBen, isEditingBeneficiary } = this.state
        if (addContingentBen) this.setState({ addContingentBen: false });
        if (addPrimaryBen) this.setState({ addPrimaryBen: false });
        if (isEditingBeneficiary) {
            this.setState({ updatableBeneficiary: null });
            this.setState({ isEditingBeneficiary: false });
        }
    }

    handleSubmitNewBeneficiary(beneficiary, addAnother) {
        const { addContingentBen, addPrimaryBen, beneficiaryData, selectedIndex, isEditingBeneficiary, updatableBeneficiary } = this.state;

        window.scrollTo({
            top: this.addBeneficiaryFormRef.current.offsetTop,
            behavior: 'smooth',
        });

        if (addPrimaryBen) {
            beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['primary_Bene'].push(beneficiary);
            this.setState({
                beneficiaryData: beneficiaryData
            })
            if (!addAnother) this.setState({ addPrimaryBen: false });
        } else if (addContingentBen) {
            beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['contingent_Bene'].push(beneficiary);
            this.setState({
                beneficiaryData: beneficiaryData
            })
            if (!addAnother) this.setState({ addContingentBen: false });
        } else if (isEditingBeneficiary) {
            if (beneficiary.beneficaryType === 'P') {
                const index = beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['primary_Bene'].indexOf(updatableBeneficiary)
                if (index !== -1) {
                    beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['primary_Bene'].splice(index, 1);
                    beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['primary_Bene'].push(beneficiary);
                }
            } else {
                const index = beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['contingent_Bene'].indexOf(updatableBeneficiary)
                if (index !== -1) {
                    beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['contingent_Bene'].splice(index, 1);
                    beneficiaryData[0]['funds'][selectedIndex]['beneficiaries']['contingent_Bene'].push(beneficiary);
                }
            }

            this.setState({
                beneficiaryData: beneficiaryData
            });
        }

        const addedBeneficiary = {
            "taxType": beneficiary.beneficiaryType === "0" ? "individuals" : "non-individuals",
            "taxId": beneficiary.taxId,
            "beneficaryType": beneficiary.beneficaryType ? beneficiary.beneficaryType : getValidValueOf(addPrimaryBen, "P", "S"),
            "relationship": beneficiary.beneficiaryType === "0" ? getValidValueOf(beneficiary.relationship_To_Insured === "0", "S", "N") : '',
            "distribution_Per": "0",
            "dob": beneficiary.dob
        }

        if (beneficiary.beneficiaryType === '0') {
            addedBeneficiary.name = beneficiary.firstName;
        } else {
            addedBeneficiary.fname = beneficiary.firstName;
            addedBeneficiary.name = `${beneficiary.firstName}`;
        }

        if (isEditingBeneficiary) {
            addedBeneficiary.edited = beneficiary.edited;
        }

        this.setState({
            currentlyHandledBeneficiary: addedBeneficiary,
            isEditingBeneficiary: false,
            showVerifyChanges: beneficiary.edited ? true : false,
        });
    }

    handleEditFormClosed() {
        this.setState({
            updatableBeneficiary: null,
            isEditingBeneficiary: false,
            showMenu: ''       
        });
    }


    clickBack = () => {
        const { verifyData } = this.state;
        const { clearBeneficiaryData } = this.props;
        if (verifyData) {
            this.setState({
                verifyData: false
            });
        } else {
            clearBeneficiaryData()
            const { history } = this.props;
            history.push(routeConstants.manageBeneficiariesAccounts);
        }
    };


    renderFlag = (item, index) => {
        const {
            selectedIndex,
            showManage
        } = this.state;

        if (index === selectedIndex && showManage) {
            if (item.beneStat === INACTIVE) {
                return <span className='delete-beneficiary-flag'>{DELETE}</span>;
            } else if (item.isNew) {
                return <span className='new-beneficiary-flag'>{NEW}</span>;
            }
        } else {
            return null;
        }
    }

    getCurrentBeneficiaries = (beneType) => {
        const { selectedIndex, beneficiaryData } = this.state;
        if (beneType) {
            return beneficiaryData[0]['funds'][selectedIndex]["beneficiaries"][beneType];
        }
        return beneficiaryData[0]['funds'][selectedIndex]["beneficiaries"];
    }

    getBeneficiariesDistributionSum = (arr) => {
        return arr.reduce(function (accumulator, currentValue) {
            const distribution = parseFloat(currentValue.distribution_Per) || 0;
            return parseFloat(accumulator) + Number(distribution.toFixed(2))
        }, 0);
    }

    distributeEqually = (beneType) => {
        const { beneficiaryData } = this.state;

        const beneficiaries = this.getCurrentBeneficiaries(beneType);
        const filteredBeneficiaries = beneficiaries.filter(item => item.beneStat !== INACTIVE);

        const nbOfBeneficiaries = filteredBeneficiaries.length;

        filteredBeneficiaries.forEach((item, index) => {
            if (index === nbOfBeneficiaries - 1) {
                item.distribution_Per = (100 - (nbOfBeneficiaries - 1) * Math.floor(100 / nbOfBeneficiaries));
            } else {
                item.distribution_Per = Math.floor(100 / nbOfBeneficiaries);
            }
            item.edited = true;
        });

        this.setState({
            beneficiaryData,
            distributionCategory: beneType,
            showVerifyChanges: true,
            distributionError: false,
            distributionErrorIndex: null,
        });
    }

    handleDistributionChange = (e, beneType, itemIndex) => {
        const { beneficiaryData } = this.state;
        const beneficiaries = this.getCurrentBeneficiaries(beneType);

        const input = e.target.value;

        let re = /^\d*(\.\d{0,2})?$/;
        if (re.test(input)) {
            // valid number

            beneficiaries[itemIndex].distribution_Per = input;
            const sum = this.getBeneficiariesDistributionSum(beneficiaries);

            if (Number(sum.toFixed(2)) > 100 || input.slice(-1) === '.' || input.charAt(0) === '.') {
                // if the sum of the distribution is greater than 100
                // or the first/last character of the distribution is '.' show an error message
                this.setState({
                    distributionError: true,
                    distributionErrorIndex: itemIndex,
                    distributionCategory: beneType
                });
            } else {
                beneficiaries[itemIndex].distribution_Per = floor(input, 2);
                beneficiaries[itemIndex].edited = true;

                this.setState({
                    beneficiaryData: beneficiaryData,
                    distributionCategory: beneType,
                    distributionError: false,
                    distributionErrorIndex: null,
                    showVerifyChanges: true
                })
            }
        } else {
            // invalid number
            this.setState({
                showVerifyChanges: false
            });
        }
    }

    renderTotalDistribution = (beneType) => {
        const { primaryBenLabel, contingentBenLabel, detailsLabels: { totalDistribution } } = consts;
        const beneficiaries = this.getCurrentBeneficiaries(beneType);

        if (beneficiaries && beneficiaries.length) {
            const sum = this.getBeneficiariesDistributionSum(beneficiaries);
            const formattedSum = `${parseFloat(sum).toFixed(2)}%`;
            const beneficiaryType = beneType === PRIMARY ? primaryBenLabel : contingentBenLabel;

            return (
                <div className="benFooterText">
                    <span>{`${totalDistribution} ${beneficiaryType} `}</span><span className="right">{formattedSum}</span>
                </div>
            )
        } else {
            return null;
        }
    }

    renderNoData = () => (
        <p className="no-beneficiary-data">{consts.noData}</p>
    );

    /**
     * 
     * @param {*} code 
     */
    getRelationShipLabel(code) {
        let codeNumber = Number(code);
        if(!isNaN(codeNumber)) {
            switch(codeNumber) {
                case 0:
                    return 'Spouse';
                case 1:
                    return 'Non-spouse';
                default:
                    return 'Not Applicable';
            }
        } else {
            return code ? code : 'Not Applicable';
        }
    }

    getBenDetailstable = (data, accType, beneType, index) => {
        const { showMenu, showManage, selectedIndex, distributionError, distributionErrorIndex, distributionCategory } = this.state;
        const { beneficiaryName, distributionPercentage, relationshipToAccountOwner, transferOnDeathBeneficiaries } = consts.detailsLabels;

        const options = 'options button';

        const menuClass = (item, itemIndex) => {
            return showMenu === `${item.key}_${item.bene_Name}_${item.beneficaryType}_${accType}_${itemIndex}`
                ? 'ellipsisMenu show'
                : 'ellipsisMenu';
        }

        const inputHidden = (item, index) => (
            showManage
            && index == selectedIndex
            && item.beneStat === INACTIVE
        );        
        if (!data || data.length === 0) {
            return this.renderNoData();
        } else {
            return data.map((item, itemIndex) => (
                <Table className="beneDetailsTable" key={`${item.bene_Name}`}>
                    <caption className="sr-only">{getValidValueOf(beneType === PRIMARY, consts.primaryBenLabel, consts.contingentBenLabel)}</caption>
                    <thead>
                        <tr>
                            <th scope="col">
                                {getValidValueOf(accType === 'Individual', transferOnDeathBeneficiaries, beneficiaryName)}
                                {this.renderFlag(item, index)}
                            </th>
                            <th scope="col">{relationshipToAccountOwner}</th>
                            {showManage && index === selectedIndex ? <th scope="col"></th> : null}
                            {inputHidden(item, index) ? null : <th scope="col">{distributionPercentage}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="name">{item.bene_Name}</td>
                            <td>{item.taxType === 'N' ? 'Not Applicable' : this.getRelationShipLabel(item.relationship_To_Insured)}</td>
                            {showManage && index === selectedIndex && item.beneStat !== "I"
                                ? this.getElipsisMenu(item, accType, itemIndex, options, menuClass(item, itemIndex), beneType)
                                : null}

                            {!inputHidden(item, index) ? <td className="distribution-percentage_cell">
                                <div style={{ position: 'relative' }}>
                                    {distributionError && distributionErrorIndex === itemIndex && distributionCategory === beneType && index === selectedIndex ?
                                        (
                                            <StyledError>
                                                {consts.detailsLabels.invalidDistribution}
                                            </StyledError>
                                        ) : null}
                                    <StyledInput
                                        type='text'
                                        name="distribution-percentage"
                                        disabled={!(showManage && index === selectedIndex)}
                                        maxLength={6}
                                        placeholder='0.00'
                                        value={getValidValueOf(item.distribution_Per, item.distribution_Per, '')}
                                        onChange={(e) => this.handleDistributionChange(e, beneType, itemIndex)}
                                    />
                                    <StyledPercent>
                                        {consts.PERCENT}
                                    </StyledPercent>

                                </div>

                            </td> : null}
                        </tr>
                    </tbody>
                </Table>
            ))
        }
    };

    shouldShowDistributeButton = (beneficiaries) => {
        if (!beneficiaries || beneficiaries.length < 1) {
            return false
        } else {
            let shouldShow = false
            beneficiaries.map((beneficiary) => {
                if (beneficiary.beneStat !== 'I') {
                    shouldShow = true
                }
            })
            return shouldShow
        }
    }

    getManageView = (beneType) => {
        const { addPrimaryBen, addContingentBen, isEditingBeneficiary, updatableBeneficiary } = this.state;
        const shouldShowForm = (beneType === CONTINGENT && addContingentBen) || (beneType === PRIMARY && addPrimaryBen) || isEditingBeneficiary
        let beneficiaryType = '';

        if (shouldShowForm && isEditingBeneficiary) {
            beneficiaryType = updatableBeneficiary.beneficaryType
            beneType = updatableBeneficiary.beneficaryType === 'P' ? PRIMARY : CONTINGENT
        }
        else if (shouldShowForm && !isEditingBeneficiary) {
            beneficiaryType = (beneType === CONTINGENT && addContingentBen) ? 'S' : 'P'
        }
 
        const beneficiaries = this.getCurrentBeneficiaries(beneType);

        const shouldShowDistributeButton = this.shouldShowDistributeButton(beneficiaries)
        const shouldShowAddButton = (beneficiaries.length < 100)
       
        return (<div className="beneficiaryWrapper accordion">
            {shouldShowForm 
            ? <AddBeneficiaryForm benType={beneficiaryType}
                ref={this.addBeneRef}
                forwardRef={this.addBeneficiaryFormRef}
                cancelAddBeneficiary={this.handleCancel}
                handleSubmitNewBeneficiary={this.handleSubmitNewBeneficiary}
                updatableBeneficiary={updatableBeneficiary}
                handleEditFormClosed={this.handleEditFormClosed} 
            /> 
            : <div className="ben-header-container">
                {shouldShowAddButton && <WButton
                    onClick={beneType === CONTINGENT ? this.addContingentBeneficiary : this.addPrimaryBeneficiary}
                    variant="outline"
                    className="linkButton add addBeneficiary"
                    aria-label={beneType === CONTINGENT ? consts.addContingentText : consts.addPrimaryText}
                    buttontext={beneType === CONTINGENT ? consts.addContingentText : consts.addPrimaryText}
                    dataAnalyticId={beneType === CONTINGENT 
                        ? BENEFICIARIES_ADD_CONTINGENT_BENEFICIARY_BUTTON_ID 
                        : BENEFICIARIES_ADD_PRIMARY_BENEFICIARY_BUTTON_ID}
                />}
                {shouldShowDistributeButton ? (
                    <WButton
                        buttontext='Distribute Equally'
                        variant="outline"
                        className="distribute-equally"
                        onClick={() => this.distributeEqually(beneType)}
                        dataAnalyticId={BENEFICIARIES_DISTRIBUTE_EQUALLY_BUTTON_ID}
                    />) : null}
                    </div>
                }
                {this.renderTotalDistribution(beneType)}
            </div>
        );
    }

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    getElipsisMenu = (item, accType, index, options, menuClass, beneType) => {
        return (
            <td className="positionRelative floatLeft">
                <button
                    onClick={this.showOptions}
                    type="button"
                    name={assignObj(`${item.key}_${item.bene_Name}_${item.beneficaryType}_${accType}_${index}`)}
                    className="ellipsisButton"
                >
                    <span className="sr-only">{options}</span>
                </button>
                <ul className={menuClass}>
                    <li>
                        <button
                            type="button"
                            className="ellipsisMenuButton"
                            onClick={(e) => this.editBeneClick(item, e)}>{consts.editText}</button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="ellipsisMenuButton"
                            onClick={() => this.deleteBeneClick(item, beneType)}
                        >
                            {consts.deleteBeneficiary.label}
                        </button>
                    </li>
                </ul>
            </td>
        )
    };

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
            notificationMsg: '',
            notificationType: '',
        });
    };

    onConformationCancel = () => {
        this.setState(() => {
            return {
                openConformationDialog: false,
                currentlyHandledBeneficiary: null
            };
        });
    }

    handleVerifyChanges = () => {
        const beneficiaries = this.getCurrentBeneficiaries();

        let hasDistributionErrors = false;
        Object.values(beneficiaries).forEach(data => {
            if (!hasDistributionErrors && data.length) {
                const sum = this.getBeneficiariesDistributionSum(data);
                if (sum && Number(sum.toFixed(2)) < 100) {
                    hasDistributionErrors = true;
                }
            }
        });

        if (hasDistributionErrors) {
            this.setState({
                displayNotification: true,
                notificationMsg: { message: "Total distribution should be 100%" },
                notificationType: 'error',
            })
        } else {
            this.setState({
                verifyData: true
            });
        }
    }

    renderVerifyPage = () => {
        const { beneficiaryData, selectedIndex } = this.state;
        const { isLoadingAddBeneficiary } = this.props;
        const categConst = {
            primary_Bene: 'Primary Beneficiaries',
            contingent_Bene: 'Contingent Beneficiaries'
        };

        this.state.modifiedBeneficiary = {};
        if (!beneficiaryData) {
            return null;
        }

        forEach(beneficiaryData[0].funds[selectedIndex].beneficiaries, (allCategBene, categ) => {
            this.state.modifiedBeneficiary[categ] = filter(allCategBene, bene => (bene.isNew === true || bene.beneStat === 'I' || bene.edited === true));
        });

        this.scrollToTop();

        return (
            <VerifyPage>
                {isLoadingAddBeneficiary && <WSpinner loading={isLoadingAddBeneficiary} />}
                <AccountSelectedInfoCard 
                    accDetails={{
                        accountName: beneficiaryData[0].accountName, 
                        accountNumber: beneficiaryData[0].accountNumber, 
                        accountType: beneficiaryData[0].accountType, 
                        currentAccountBalance: beneficiaryData[0].balance, 
                        address: beneficiaryData[0].address
                        }}
                        shouldShowBalance={false} />
                <div className="primaryBeneWrapper">
                    <div className="benSectionHeaderWrapper">
                        <h3 className="beneSectionHeading">{beneficiaryData[0].category}</h3>
                    </div>
                </div>
                <div className="beneficiaryCardBody">
                    {Object.keys(this.state.modifiedBeneficiary).map(beneCateg => (
                        !!this.state.modifiedBeneficiary[beneCateg].length && (
                            <VerifySection key={beneCateg}>
                                <SectionTitle>
                                    <TitleTxt>{categConst[beneCateg]}</TitleTxt>
                                    <EditSection aria-label='Edit'>
                                        <EditImage src={EditIcon} alt="" />
                                        <EditDiv onClick={this.clickBack}>
                                            Edit
                                        </EditDiv>
                                    </EditSection>
                                </SectionTitle>
                                <Separator aria-hidden="true" />
                                <SectionBody>
                                    {this.state.modifiedBeneficiary[beneCateg].map((data, index) => (
                                        <FlexColumnDiv key={`${beneCateg} - ${index.toString()}`}>
                                            <FlexRowDiv>
                                                <Field>Name</Field>
                                                <Value>{data.bene_Name}</Value>
                                            </FlexRowDiv>
                                            <FlexRowDiv>
                                                <Field>Social Security Number</Field>
                                                <Value>{getFormattedSSN(data.taxId, true)}</Value>
                                            </FlexRowDiv>
                                            <FlexRowDiv>
                                                <Field>Date of Birth</Field>
                                                <Value>{data.dob}</Value>
                                            </FlexRowDiv>
                                            <FlexRowDiv>
                                                <Field>Beneficiary Type</Field>
                                                <Value>{data.beneficaryType === 'P' ? 'Primary' : 'Contingent'}</Value>
                                            </FlexRowDiv>
                                            <FlexRowDiv>
                                                <Field>Relationship to Account Owner</Field>
                                                <Value>{this.getRelationShipLabel(data.relationship_To_Insured)}</Value>
                                            </FlexRowDiv>
                                            <FlexRowDiv>
                                                <Field>Distribution Percentage</Field>
                                                <Value>{assignObj(`${parseFloat(data.distribution_Per).toFixed(2)}%`)}</Value>
                                            </FlexRowDiv>
                                            {data.beneStat === "I" && (
                                                <FlexRowDiv>
                                                    <Field>Status</Field>
                                                    <RedValue>Deleted</RedValue>
                                                </FlexRowDiv>
                                            )}
                                            <Separator aria-hidden="true" />
                                        </FlexColumnDiv>
                                    ))}
                                </SectionBody>
                            </VerifySection>
                        )
                    ))}
                </div>
            </VerifyPage>
        );

    }

    updateBeneficiaries = () => {
        const { beneficiaryData, selectedIndex, modifiedBeneficiary } = this.state;
        const { saveBeneficiaryData } = this.props;

        saveBeneficiaryData({
            ...beneficiaryData[0].funds[selectedIndex],
            beneficiaries: modifiedBeneficiary
        });
    }

    renderCommonButtons(verifyData,showManage,showVerifyChanges) {
    
        if (showManage && showVerifyChanges) {
            return (
                <CommonButtons 
                    backClick={this.clickBack} 
                    nextClick={verifyData ? this.updateBeneficiaries : this.handleVerifyChanges} 
                    nextText={verifyData ? 'Confirm Changes' : 'Verify Changes'} 
                    dataAnalyticId={verifyData 
                        ? BENEFICIARIES_EDIT_VERIFY_CONFIRM_NAV_BUTTON_ID
                        : BENEFICIARIES_EDIT_VERIFY_VERIFY_NAV_BUTTON_ID
                    }
                />
            );
        } else {
            return (
                <CommonButtons 
                    backClick={this.clickBack} 
                    nextstyle={nextStyle} 
                    dataAnalyticId={BENEFICIARIES_EDIT_NAV_BUTTON_ID}
                />
            );
        }
    }
    


    renderManageViewSecondary(showManage, selectedIndex, index, updatableBeneficiary) {
        if (showManage && index === selectedIndex) {
            if (!updatableBeneficiary || (updatableBeneficiary && updatableBeneficiary.beneficaryType === 'S')) {
                return this.getManageView(CONTINGENT);
            }
        }
    
        return null;
    }
    
    renderManagePrimary(showManage, selectedIndex, index, updatableBeneficiary) {
        if (showManage && index === selectedIndex) {
            if (!updatableBeneficiary || (updatableBeneficiary && updatableBeneficiary.beneficaryType === 'P')) {
                return this.getManageView(PRIMARY);
            }
        }
    
        return null;
    }
    
    

    render() {
        const { beneficiaryData, notificationType, openConformationDialog, deleteModalBody, notificationMsg, displayNotification, verifyData, updatableBeneficiary } = this.state;
        const { isLoading, isErrorAddBeneficiary, isErrorDeleteBeneficiary } = this.props.manageBeneficiaryData;
        const { deleteBeneficiary } = consts;
        const isErrorNotification = notificationType === 'error';
        const activeCrumb = routesBreadCrumb.get(routeConstants.manageBeneficiaries) 
        const activeCrumbVerify = routesBreadCrumb.get(routeConstants.manageBeneficiariesVerify) 
        
        return (
            <div className="beneficiaryWrapper">
                {displayNotification && (
                    <WMessageBar
                        error={isErrorNotification}
                        onClose={this.onNotificationClose}
                        text={notificationMsg}
                    />
                )}
                {isErrorAddBeneficiary && (
                    <WMessageBar
                        error={isErrorAddBeneficiary}
                        onClose={this.onNotificationClose}
                    />
                )}
                {isErrorDeleteBeneficiary && (
                    <WMessageBar
                        error={isErrorDeleteBeneficiary}
                        onClose={this.onNotificationClose}
                    />
                )}
                {openConformationDialog && (
                    <ConformationModal
                        modalTitle={deleteBeneficiary.modalTitle}
                        modalBodyText={deleteModalBody.heading}
                        modalBodyText2={deleteModalBody.paragraph}
                        primaryButtonText={deleteBeneficiary.confirmButton}
                        secondaryButtonText={deleteBeneficiary.cancelButton}
                        onPrimaryClick={this.onConformationDelete}
                        onSecondaryClick={this.onConformationCancel}
                    />
                )}
                { (!verifyData) 
            ? (<Container>
                    {this.state.showManage
                        ? <WBreadCrumb breadCrumbItems={this.getBreadCrumbChangesItems()} activeCrumb={activeCrumb} />
                        : <WBreadCrumb breadCrumbItems={this.getBreadCrumbHomeItems()} activeCrumb={activeCrumb} />
                    }
                    <Row noGutters className="beneFiciaryHeaderWrapper">
                        <h1 className="pageHeading">{consts.manageBeneficiaryHeading} </h1>
                    </Row>
                    {this.state.showManage && (
                        <div>
                            <h3>{consts.instructionsTitle}</h3>
                            <p>{consts.instructionsBody}</p>
                        </div>
                    )}
                    <div>
                        {isLoading && <WSpinner loading={isLoading} />}
                        {beneficiaryData.map((data, index) => {
                            return (
                                <React.Fragment key={`${data.account_Number}_account_card`}>
                                    <AccountSelectedInfoCard 
                                        key={`${data.account_Number}_account_card`}
                                        accDetails={{
                                            accountName: data.accountName, 
                                            accountNumber: data.accountNumber, 
                                            accountType: data.accountType, 
                                            currentAccountBalance: data.balance, 
                                            address: data.address}}
                                            shouldShowBalance={false} />
                                    <StyledCard key={`${data.account_Number}_card`}>
                                        <div key={`${data.account_Number}_beneficiaryCardBody`} className="beneficiaryCardBody">
                                        {data && data.funds && data.funds.length ?
                                            data.funds.map((fundData, index) => {
                                                return (
                                                    <Accordion defaultActiveKey="0" key={`${data.account_Type}_${fundData.fundAccountNumber}_accordion`}>

                                                        <div key={`${fundData.fundAccountNumber}_header`}>
                                                            <div className="positionRelative">
                                                                <Accordion.Toggle
                                                                    as="h2"
                                                                    variant="link"
                                                                    eventKey="0"
                                                                    className="expand accordionHeader beneficiaryAccordionHeader"
                                                                    onClick={this.cardHeaderClick}
                                                                >
                                                                    {fundData.fundName}
                                                                </Accordion.Toggle>
                                                            </div>

                                                            <Accordion.Collapse eventKey="0">
                                                                <div className="beneficiaryCardBody">
                                                                    <div className="primaryBeneWrapper">
                                                                        <div className="benSectionHeaderWrapper">
                                                                            <h3 className="beneSectionHeading">{consts.primaryBenLabel}</h3>
                                                                        </div>
                                                                        <div className="benSectionTableWrapper">
                                                                            {this.getBenDetailstable(
                                                                                fundData.beneficiaries.primary_Bene,
                                                                                data.account_Type,
                                                                                PRIMARY,
                                                                                index
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {this.renderManagePrimary(this.state.showManage, this.state.selectedIndex, index, updatableBeneficiary)}
                                                                    </div>
                                                                    {data.account_Type !== 'Individual' && (
                                                                        <div className="primaryBeneWrapper">
                                                                            <div className="benSectionHeaderWrapper">
                                                                                <h3 className="beneSectionHeading">
                                                                                    {consts.contingentBenLabel}
                                                                                    {data.contingent_Bene &&
                                                                                        data.contingent_Bene.length > 0 && (
                                                                                            <span className="lastModified">
                                                                                                {assignObj(
                                                                                                    `(Last updated on ${data.last_modified})`,
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                </h3>
                                                                            </div>

                                                                            <div className="benSectionTableWrapper">
                                                                                {this.getBenDetailstable(
                                                                                    fundData.beneficiaries.contingent_Bene,
                                                                                    data.account_Type,
                                                                                    CONTINGENT,
                                                                                    index
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div>

                                                                        {this.renderManageViewSecondary(this.state.showManage, this.state.selectedIndex, index, updatableBeneficiary)}

                                                                    
                                                                    </div>
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    </Accordion>
                                                )
                                            }) : <div>{consts.noBeneficiaries}</div>

                                        }
                                    </div>
                                </StyledCard>
                                </React.Fragment>
                            );
                        })}

                    </div>
                </Container>
            ) : (
                        <Container>
                            <WBreadCrumb breadCrumbItems={this.getBreadCrumbVerifyItems()} activeCrumb={activeCrumbVerify} />
                            <Row noGutters className="beneFiciaryHeaderWrapper">
                                <h1 className="pageHeading">{consts.verifyUpdateBeneficiaryHeading} </h1>
                            </Row>
                            <div>
                                {this.renderVerifyPage()}
                            </div>
                        </Container>
                )}
            <hr />
                {this.renderCommonButtons(verifyData,this.state.showManage,this.state.showVerifyChanges)}
        </div>);
    }
}

ManageBeneficiaries.propTypes = {
    manageBeneficiaryData: PropTypes.instanceOf(Object),
    saveBeneficiaryData: PropTypes.func,
    deleteBeneficiaryData: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    getBeneficiaries: PropTypes.func,
    isSuccessAddBeneficiary: PropTypes.bool,
    originalBeneficiaryData: PropTypes.instanceOf(Object),
};

ManageBeneficiaries.defaultProps = {
    manageBeneficiaryData: {},
    saveBeneficiaryData: () => { },
    deleteBeneficiaryData: () => { },
    analyzeClickEvent: () => { },
    getBeneficiaries: () => { },
    isSuccessAddBeneficiary: false,
    originalBeneficiaryData: {},
};

export default ManageBeneficiaries;
