import * as ActionTypes from '../ReduxConstants/MasterRegistrationActionConstants';


const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    masterRegAccountList: [],
    error: '',
};

function MasterRegistrationAccountReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.GET_MASTER_REGISTRATION_ACCOUNT_LIST_START: {
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isError: action.payload.isError,
                isSuccess: false,
            };
        }
        case ActionTypes.GET_MASTER_REGISTRATION_ACCOUNT_LIST_SUCCESS: {
            return {
                ...state,
                masterRegAccountList: action.payload,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        case ActionTypes.GET_MASTER_REGISTRATION_ACCOUNT_LIST_FAILURE: {
            return {
                ...state,
              
                isLoading: false,
               isSuccess: false,
                isSuccess: true,
            };
        }
        case ActionTypes.CLEAR_MASTER_REG_INFO: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
}

export default MasterRegistrationAccountReducer;
