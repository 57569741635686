import React, { Component } from 'react';
import PropTypes from 'prop-types';
import APIReturnedStatusCodeErrorPage from '../../modules/AccountManagementModule/ServerErrorPage/APIReturnedStatusCodeErrorPage';
import ServerErrorPage from '../../modules/AccountManagementModule/ServerErrorPage/ServerErrorPageComponent';

class ErrorBoundary extends Component {
    data = {
        title: 'Something went wrong',
        errorStyle: {
            width: '700px',
            margin: '100px auto',
            textAlign: 'center',
        },
        errorInfo: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        // console.log(JSON.parse((error.message)))
        // const errorObject=JSON.parse(error.message)
        let errorObject = {};
        // Update state so the next render will show the fallback UI.
        if (error.message.indexOf('data') > -1 || error.message.indexOf('status') > -1) {
            errorObject = JSON.parse(error.message);
        } else {
            errorObject = error;
        }
        return { hasError: true, errorInfo: errorObject };
    }

    // componentDidCatch(error, info) {
    //     log the error to our server with loglevel
    //     this.setState({ hasError: true, error, info });
    // }
    
    setAdobeAnalyticError = error => {
        const errorDetails = error ? error.toString() : '';
        if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
            window.digitalData.page.pageInfo.siteError = errorDetails;
        }
    };

    render() {
        const { hasError, errorInfo } = this.state;
        const { children } = this.props;
        if (hasError) {            
            window.addEventListener('error', this.onError);
            this.setAdobeAnalyticError(errorInfo);
            // custom fallback UI
            if (errorInfo.status) {
                return <APIReturnedStatusCodeErrorPage errorInfo={errorInfo} />;
            }
            return <ServerErrorPage errorInfo={errorInfo} />;
        }
        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element,
};

ErrorBoundary.defaultProps = {
    children: '',
};

export default ErrorBoundary;
