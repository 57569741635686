import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    accountSummary: {},
    dashBoardLink: {},
    savedItemsResult: {},
    isSavedItemsResultError:false,
    getEnabledFeature:{},
    isEnableFeatureSuccess:false,
    hyperAssertionLink:{},
    isHyperSmalSuccess:false,
    isHyperSmalError:false,
    isSamlSuccess:false, 
    samlAssertionLink:{},
    systemHealth:{}
};

function memberDashboardReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_LOADER:
        case ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_LOADER:
        case ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_LOADER: {
            return { ...state, ...action, isLoading: true,isError: false,isSuccess:false, isSavedItemsResultError:false,};
        }

        case ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_SUCCESS:
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_SUCCESS:
        case ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isError: false,isSuccess:true, isSavedItemsResultError:false,};
        }
        
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_FAILURE:
        case ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_FAILURE: {
            return { ...state, ...action, isLoading: false,isSuccess:false, isError: true ,isSavedItemsResultError:false,};
        }

        case ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_FAILURE:
        case ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_ERROR:{
            return { ...state, ...action, isLoading: false,isSuccess:false, isError: true,isSavedItemsResultError:true, };
        }

       
        case ActionTypes.MEMBER_DASHBOARD_DROPDOWN_ERROR:
        case ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess:false,isError: true,isSavedItemsResultError:false, };
        }
        case ActionTypes.GET_ENABLED_FEATURE_SUCCESS: {
            return { ...state, ...action, isLoading: false , isEnableFeatureSuccess:true};
        }

        case ActionTypes.GET_ENABLED_FEATURE_FAILURE: {
            return { ...state, ...action, isLoading: false,  isEnableFeatureSuccess:false }
        }

        case ActionTypes.GET_ENABLED_FEATURE_ERROR: {
            return { ...state, ...action, isLoading: false, isEnableFeatureSuccess:true};
        }

        
        case ActionTypes.GET_SAML_ASSERTION_LOADER: {
            return { ...state, ...action, isLoading: true,samlAssertionLink:{}, isSamlSuccess:false, isError: false};
        }

        case ActionTypes.GET_SAML_ASSERTION_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isError: false, isSamlSuccess:true };
        }

        case ActionTypes.GET_SAML_ASSERTION_FAILURE: {
            return { ...state, ...action, isLoading: false, isError: true, isSamlSuccess:false }
        }

        case ActionTypes.GET_SAML_ASSERTION_ERROR: {
            return { ...state, ...action, isLoading: false, isError: true, isSamlSuccess:false };
        }

        case ActionTypes.SET_SAML_ASSERTION_INITIAL_DATA: {
            return { ...state, ...action, isLoading: false, isError: false, isSamlSuccess:false, samlAssertionLink:{}};
        }

        case ActionTypes.SET_MEMBER_DASHBOARD_INITIAL_DATA: {
            return { accountSummary: {}, dashBoardLink: {}, savedItemsResult: {},getEnabledFeature:{}, isLoading: false, isSuccess:false,isError: false,isSavedItemsResultError:false,isEnableFeatureSuccess:false };
        }

        case ActionTypes.GET_SYSTEM_HEALTH_SUCCESS: {
            return { ...state, isLoading: false , systemHealth: action.payload.result, isSystemHealthSuccess: true, isSystemHealthError: false};
        }

        case ActionTypes.GET_SYSTEM_HEALTH_FAILURE: {
            return { ...state, ...action, isLoading: false,  systemHealth: action.payload, isSystemHealthSuccess: false, isSystemHealthError: true}
        }

        case ActionTypes.GET_SYSTEM_HEALTH_ERROR: {
            return { ...state, ...action, isLoading: false,  systemHealth: action.payload, isSystemHealthSuccess: false, isSystemHealthError: true};
        }

        case ActionTypes.CLEAR_SYSTEM_HEALTH: {
            return { ...state, isLoading: false, systemHealth:{}, isSystemHealthSuccess: false, isSystemHealthError: false }
        }
        
        default:
            return state;
    }
}

export default memberDashboardReducer;
