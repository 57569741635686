import { 
    bankAccountAction, 
    fundAccountActions, 
    addAutomaticInvestmentActions, 
    addSystematicWithdrawalActions, 
    localStateManagementActions,
    analytics,    
    purchaseActions,
    tradingWindowActions
} from '../../../../shared/Actions';
import { connect } from 'react-redux';
import constants from '../../constant';
import CreateInvestmentOrWithdrawalPlan from './CreatePlanComponent';
import { get } from 'lodash';
import { withRouter } from 'react-router';

export const setAccountListMockData = () => dispatch => {
    dispatch({
        type: 'GET_ACCOUNT_DETAILS_SUCCESS',
        response: constants.MockAPIFundList.result
    });
};

export const setBankListMockData = () => dispatch => {
    dispatch({
        type: 'GET_BANK_ACCOUNT_DETAILS_SUCCESS',
        response: constants.MockAPIBankAccountsDetailsEmpty,
    });
};

export const setFundListMockData = () => dispatch => {
    dispatch({
        type: 'GET_FUNDLIST_SUCCESS',
        GET_FUNDLIST: constants.MockAPIFundList.result,
    });
};


export const mapStateToProps = state => {
    let errorsData = {};

    if(Object.keys(get(state, 'systematicWithdrawalData.error', {})).length) errorsData = get(state, 'systematicWithdrawalData.error');
    else if(Object.keys(get(state, 'automaticInvestmentData.error', {})).length) errorsData = get(state, 'automaticInvestmentData.error');
    else if(Object.keys(get(state, 'fundPlanData.error', {})).length) errorsData = get(state, 'fundPlanData.error');

    return {
        bankAccountReducerData: get(state, 'bankAccountReducerData', []),
        rmdReducerData: get(state, 'rmdReducerData', {}),
        fundAccountData: get(state, 'fundAccountData', []),
        isSuccessfullySaved: (
            (get(state, 'automaticInvestmentData.isSuccess', false) && get(state, 'automaticInvestmentData.isAdd', false)) ||
            (get(state, 'systematicWithdrawalData.isSuccess', false) && get(state, 'systematicWithdrawalData.isAdd', false))
        ),
        responseMessage: (
            get(state, 'automaticInvestmentData.responseMessage', '') ||
            get(state, 'systematicWithdrawalData.responseMessage', '')
        ),
        hasError: get(state, 'automaticInvestmentData.isError', false) || get(state, 'systematicWithdrawalData.isError', false) || get(state, 'fundPlanData.isError', false),
        errorsData,
        isSaving: get(state, 'automaticInvestmentData.isLoading', false) || get(state, 'systematicWithdrawalData.isLoading', false),
        localStateData: state.localStateManagementReducerData,
        purchaseReducerData: state.purchaseData,
        tradingWindowData: state.tradingWindowData,
    };
};

const mapDispatchToProps = {
    getBankAccountInfo: bankAccountAction.getBankAccounts, // () => setBankListMockData(), //   
    getAccountList:  fundAccountActions.getAccountList, // () => { setAccountListMockData(); setFundListMockData()}, // 
    clearFundAccountList: fundAccountActions.clearFundAccountList,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    addPADDetails: addAutomaticInvestmentActions.addPADDetails,
    dismissAIPError: addAutomaticInvestmentActions.dismissError,
    dismissSWPError: addSystematicWithdrawalActions.dismissError,
    addSWP: addSystematicWithdrawalActions.addSWPDetails,
    analyzeClickEvent: analytics.analyzeClickEvent,
    analyzeFormAdandonment: analytics.analyzeFormAdandonment,
    analyzeFormFieldError: analytics.analyzeFormFieldError,
    setBankListMockData,
    setFundListMockData,
    setAccountListMockData,
    manageLocalState:localStateManagementActions.manageLocalState,

    getIRAContributionValues: purchaseActions.getIRAContributionValues,
    acceptIRAContributionWarning: purchaseActions.acceptIRAContributionWarning,
    cancelIRAContributionWarning: purchaseActions.cancelIRAContributionWarning,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateInvestmentOrWithdrawalPlan));