import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as AnalyticsActions from '../../shared/Actions/AnalyticsActions';
import CancelIcon from '../../assets/cancel.svg';
import WButton from '../WButton/WButton';
import StyledButton from '../StyledComponents';
import './ConformationModal.css';


const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const ModalDialog = styled.div`
    left: 16%;
    top: 18%;
    z-index: 999;
    width: 67%;
    padding: 40px 120px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
`;
const CancelButtonStyle = styled.span`
    font: 800 16px/20px yorkten-slab-normal;
    letterspacing: 0;
    color: #004a98;
    opacity: 1;
`;
const ConformationModalTitle = styled.div`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;
const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 20px;
`;
const CancelSection = styled(StyledButton)`
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 154px;
    height: 50px;
    align-self: center;
    border : 1px solid #000000;
`;
const CancelImage = styled.img`
    margin-left: 15px;
    margin-right: 10px;
`;

class ConformationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.firstElRef = React.createRef();
        this.lastElRef = React.createRef();
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';

        // set focus on first input
        this.firstElRef.current.focus();
    }

    componentWillUnmount() {
     
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }

    onTitleElRef = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.titleElRef.current.style.outline = 'none';
            this.firstElRef.current.focus();
        }
    };


    // redirect last tab to first input
    onLastElementTab = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.firstElRef.current.focus();
        }
    };

    // redirect first shift+tab to last input
    onFirstShiftTab = e => {
        if (e.which === 9 && e.shiftKey) {
            e.preventDefault();
            this.lastElRef.current.focus();
        }
    };

    analyzeEvent = e => {
        const { onSecondaryClick, analyzeClickEvent } = this.props;
        onSecondaryClick();
        analyzeClickEvent({
            link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
        });
    };

    render() {
        const {
            modalTitle,
            modalBodyText,
            modalBodyText2,
            primaryButtonText,
            secondaryButtonText,
            onPrimaryClick,
            dataAnalyticId,
            waitforapiresponse,
            analyzecustom,
        } = this.props;

        return (
            <ModalStyle >
                <ModalDialog tabIndex={0} className="modalDialog" role="dialog" aria-modal="true" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle id="VCM-Modal-title"> {modalTitle} </ConformationModalTitle>
                    <ConformationModalBody> {modalBodyText} </ConformationModalBody>
                    {modalBodyText2 ? <ConformationModalBody> {modalBodyText2} </ConformationModalBody> : null}
                    <FlexDiv >
                        <CancelSection
                            ref={this.firstElRef}
                            onClick={this.analyzeEvent}
                            onKeyDown={this.onFirstShiftTab}
                            data-analytic-id={dataAnalyticId?`${dataAnalyticId}_confirmation-modal_cancel`:`confirmation-modal-cancel`}
                        >
                            <CancelImage src={CancelIcon} alt="" />
                            <CancelButtonStyle>{secondaryButtonText}</CancelButtonStyle>
                        </CancelSection>
                        <WButton
                            ref={this.lastElRef}
                            id="confirmation-modal-primary-btn"
                            buttontext={primaryButtonText || 'Delete'}
                            size="sm"
                            onClick={onPrimaryClick}
                            onKeyDown={this.onLastElementTab}
                            dataAnalyticId={dataAnalyticId?`${dataAnalyticId}_confirmation-modal_confirm`:`confirmation-modal-confirm`}
                            waitforapiresponse={waitforapiresponse}
                            analyzecustom={analyzecustom}
                        />
                    </FlexDiv>
                </ModalDialog>
            </ModalStyle>
        );
    }
}

ConformationModal.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    waitforapiresponse: PropTypes.bool,
    dataAnalyticId: PropTypes.string,
    analyzecustom: PropTypes.instanceOf(Object),
};

ConformationModal.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    modalBodyText2: null,
    secondaryButtonText: 'SECONDARY',
    onPrimaryClick: () => {},
    onSecondaryClick: () => {},
    primaryButtonText: '',
    analyzeClickEvent: () => {},
    waitforapiresponse: false,
    dataAnalyticId: '',
    analyzecustom: null
};

const mapDispatchToProps = {
    ...AnalyticsActions,
};

export default connect(null, mapDispatchToProps)(ConformationModal);
