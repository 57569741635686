/* *Component Name : VCMCardDeckComponent
 * Author : Sharanya Bothumanchi
 * Modified Date: 13/11/2019
 * 
*/

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../CommonStyles';
import '../../App.css'
/* import WButton from '../WButton/WButton'; */


/** Component is created to display multiple cards as a deck
 * Componet has following properties
 * label - Display the label
 * cardDeck - Display card in a dec format
 * when the component is used, it requires following props
 * topLabelStyle - Css Style to display label
 * topLabelValue - Name of the label
 * items - card items to display
 * bottomLabelStyle - Css Style to display label
 * bottomLabelStyle - Name of the label
*/

const assignObj = (obj) => { return obj; };
const alertCallNumber = 'For security reasons, not all the information can be updated online.'
const VCMCardDeckComponent = (props) => {
  const {cardText} = props;
  return(
  <div style= {assignObj({ background: '#FFFFFF 0% 0% no-repeat padding-box', border: '1px solid #D2D2D2', margin:'40px 0'})}>
       <div style={styles.leftCornerStyle}/>
      {cardText ?
    <div style={assignObj({ margin: '23px',font: 'Medium 16px/22px Benton Sans',fontWeight: '700', fontSize: '16px', lineHeight: '22px'})}>{cardText}</div>
  : <div style={assignObj({ margin: '23px',font: 'Medium 16px/22px Benton Sans',fontWeight: '700', fontSize: '16px', lineHeight: '22px'})}>{alertCallNumber}</div> }
  </div>
)}

VCMCardDeckComponent.propTypes = {
  bottomLabelStyle: PropTypes.instanceOf(Object),
  cardText: PropTypes.string,
  // topLabelStyle: PropTypes.any,
  // bottomLabelValue: PropTypes.string,
  // topLabelValue: PropTypes.string,
}

VCMCardDeckComponent.defaultProps = {
  // cardtitletext: "Title",
  // cardtext: "Text",
  bottomLabelStyle: {},
  cardText: ''
}

export default VCMCardDeckComponent;