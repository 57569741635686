import { connect } from 'react-redux';
import  Constant from './CONST';
import { documentPreferenceActions, analytics, accessForms } from '../../shared/Actions';
import { isEmpty, isEqual } from 'lodash';
import React, { Component } from 'react';
import routeConstants from '../../routeConstants'
import styled from 'styled-components';
import '../commonCompStyles.css';
import envConfig from 'env/EnvConfig';

const StyledP = styled.p`
    font: 500 1rem/1.5rem benton-sans;
    color: rgb(73, 73, 74);
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 1.562rem;
    > a {
        color: rgb(0, 74, 152);
        text-decoration: underline;
    }
`;

const BoldSpan = styled.span`
    letter-spacing: 0;
    text-align: left;
    font: 700 1rem/1.5rem benton-sans;
`;

const StyledLi = styled.li`
    margin-left: -20px;
`;

const assignObj = obj => {
    return obj;
};
let CONSTS = Constant
class CommonDisclosure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerDocList: [],
            getDocFooterCall: false,
            loginDataCounter:0
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            const { getDocFooterCall } = prevState;
            const { footerDocumentData, accessFormsData } = this.props;
            const { formsById, isError: isAccessFormError } = accessFormsData || {};
            const { isSuccess, isError, documentReceived } = footerDocumentData || {};
            // debugger
            const { footerDocumentData: prevFooterDocumentData, accessFormsData: prevAccesssFormData } = prevProps;
            const { formsById: prevFormsById } = prevAccesssFormData || {};
            const { documentReceived: prevDocumentReceived } = prevFooterDocumentData || {};

            if (formsById !=undefined && formsById !=null && Object.keys(formsById)?.length && !isEqual(formsById, prevFormsById) && !isEmpty(formsById)) {
                this.setState({ footerDocList: formsById });
            }
            if (
                Object.keys(footerDocumentData).length &&
                isError &&
                !isEqual(footerDocumentData, prevFooterDocumentData)
            ) {
                // this.handleAPIError(payload)
                this.setState({ getDocFooterCall: false });
            }
            if (Object.keys(prevAccesssFormData).length && isAccessFormError) {
                // this.handleAPIError(payload)
                this.setState({ getDocFooterCall: false });
            }
            if (
                Object.keys(footerDocumentData).length &&
                isSuccess &&
                !isEqual(documentReceived, prevDocumentReceived)
            ) {
                this.convertBlobToFile(documentReceived, prevState.selectedFileName, getDocFooterCall);
            }
        }
    }

    // Analytics Implementation Start
    callAnalytics = linkName => {
        const { analyzeDownLoadClickEvent } = this.props;
        analyzeDownLoadClickEvent({
            link: `Footer_Disclaimer_${linkName}(Download)`,
        });
    };

    handleFooterDisclaimerClick = (e) => {
        localStorage.setItem('isPreloginRedirection', 'true');
        window.supressRefresh = true
        this.callAnalytics(e.target.text)
    }

    handleFooterPrescriptionClick = (e) => {
        e.preventDefault()
        localStorage.setItem('isPreloginRedirection', 'true');
        window.supressRefresh = true
        const { footerDocList: docJson } = this.state
        if (!isEmpty(docJson)) {
            const { retrieveSingleDocument } = this.props;
            const { alternateDocumentId, alternateDocumentLink, fileName, nodeId, documentSource } = docJson[+0];
            const isAlternateDocumentLink = Boolean(alternateDocumentLink);
            if (isAlternateDocumentLink) {
                window.open(alternateDocumentLink);
            } else {
                let productId = '';
                const source = documentSource.toLowerCase() || 'alfresco';
                const docId = alternateDocumentId || nodeId;
                const payload = {
                    token: localStorage.getItem('token'),
                    nodeId: docId,
                    productId,
                    downloadUrl: alternateDocumentLink,
                    documentSource: source,
                    isFooterDispatch: true,
                };
                retrieveSingleDocument(payload);
            }
            this.setState({ selectedFileName: fileName, getDocFooterCall: true });
            this.callAnalytics(e.target.text);
        } else {
            window.open('https://vcm.com/assets/fund-docs/529/529_Plan_Description.pdf', '_blank');
        }
    };

    convertBlobToFile = (data, selectedFileName, open) => {
        if (open) {
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(data, selectedFileName);
            }
            window.open(URL.createObjectURL(data));
            this.setState({ getDocFooterCall: false });
        }
    };

    render() {
        const { dasboardDisclosure, 
                additionalDisclosure,
            additionalDashboardDisclosure
        } = CONSTS
        const { dasboardPrinciple,
            dashboardPara1,
            dashboardPara4,
            usaaaAbbr,
            usaaFullForm,
            dashboardPara41,
            dashboardPara42,
            dashboardPara43,
            fdicAbbr,
            fdicFullForm,
            bankGuaranteeText,
            loseValueText,
            insuredText,
            notText,
            dashboardNewPara1,
            vcmLink,
            dashboardNewPara2,
            dashboardPara61,
            dashboardPara62,
            dashboardPara63,
            dashboardPara71
        } = dasboardDisclosure
        const AdditionalDisclosureRoutes = ["/messageCenter/createSecureMessages"];
        const dashboardPaths = [routeConstants.accountholder, routeConstants.accountguestview]
        const { path } = this.props;

        return (
            <div>
                {AdditionalDisclosureRoutes.includes(path) && (
                    <>
                        <StyledP>
                            <BoldSpan>{additionalDisclosure.sentence1Part1}</BoldSpan>
                            <br />
                            <BoldSpan>{additionalDisclosure.sentence2Part2}</BoldSpan>
                        </StyledP>

                        <ul>
                            <StyledLi>
                                <BoldSpan>{additionalDisclosure.bullet1}</BoldSpan>
                            </StyledLi>
                            <StyledLi>
                                <BoldSpan>{additionalDisclosure.bullet2}</BoldSpan>
                            </StyledLi>
                        </ul>
                        <StyledP>
                            <BoldSpan>{additionalDisclosure.sentence3Part1}</BoldSpan>
                            <br />
                            <BoldSpan>{additionalDisclosure.sentence4Part2}</BoldSpan>
                            <br />
                            <BoldSpan>{additionalDisclosure.sentence5Part3}</BoldSpan>
                        </StyledP>
                    </>
                )}
                <h2
                    style={assignObj({
                        font: '700 1rem/1.5rem benton-sans',
                        color: 'rgb(73, 73, 74)',
                        marginBottom: 15,
                    })}
                >
                    {dasboardPrinciple}
                </h2>
                <StyledP>
                    <span>{dashboardPara1}</span>
                </StyledP>
                <StyledP>
                    <BoldSpan>{dashboardNewPara1}</BoldSpan>
                    <a
                        href={assignObj(`${envConfig.ENV_URL}/prospectus`)}
                        target="_self"
                        rel="noopener noreferrer"
                        onClick={this.handleFooterDisclaimerClick}
                        style={assignObj({ display: 'inline-block' })}
                    >
                        <BoldSpan>{vcmLink}</BoldSpan>
                    </a>
                    <BoldSpan>{dashboardNewPara2}</BoldSpan>
                </StyledP>
                <StyledP>
                    <BoldSpan>{dashboardPara4}</BoldSpan>
                    <BoldSpan>
                        <abbr title={usaaFullForm}>{usaaaAbbr}</abbr>
                    </BoldSpan>
                    <BoldSpan>{dashboardPara41}</BoldSpan>
                    <a
                        href={`${envConfig.ENV_URL}/assets/fund-docs/529/529_Plan_Description.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleFooterDisclaimerClick}
                        // onClick={this.handleFooterPrescriptionClick}
                        // style={assignObj({ display: 'block', float: 'left' })}
                    >
                        <BoldSpan>
                            {dashboardPara42}
                            <span className="sr-only">{CONSTS.planDescription}</span>
                            <span aria-hidden="true" className="icon-newpage" style={assignObj({ marginRight: 10 })} />
                        </BoldSpan>
                    </a>
                    <BoldSpan>{dashboardPara43}</BoldSpan>
                </StyledP>
                <StyledP>
                    <span>{dashboardPara61}</span>
                    <span>
                        <abbr title={usaaFullForm}>{usaaaAbbr}</abbr>
                    </span>
                    <span>{dashboardPara62}</span>
                    <span>
                        <abbr title={usaaFullForm}>{usaaaAbbr}</abbr>
                    </span>
                    <span>{dashboardPara63}</span>
                </StyledP>                
                {
                    dashboardPaths.includes(path) &&
                    (
                        <>
                            <StyledP>
                                <BoldSpan>{additionalDashboardDisclosure.part1} 
                                    <span>
                                        <abbr title={additionalDashboardDisclosure.llcFullForm}>{additionalDashboardDisclosure.llcAbbr}</abbr>
                                    </span>
                                    {additionalDashboardDisclosure.part2}
                                    <span>
                                        <abbr title={fdicFullForm}>{fdicAbbr}</abbr>
                                    </span>
                                    {additionalDashboardDisclosure.part3}
                                </BoldSpan>
                                <br /><br />
                                <span>{additionalDashboardDisclosure.part4}</span>
                            </StyledP>
                        </>
                    )
                }

                    <StyledP>
                    <span>{dashboardPara71}</span>
                    </StyledP>  
                <ul
                    id="dashboardDisclaimerList"
                    style={assignObj({ ...CONSTS.styles.unorderedList, font: '700 1rem/1.5rem benton-sans' })}
                >
                    <li>
                        {notText}
                        <span>
                            <abbr title={fdicFullForm}>{fdicAbbr}</abbr>
                        </span>
                        {insuredText}
                    </li>
                    <li>{loseValueText}</li>
                    <li>{bankGuaranteeText}</li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    footerDocumentData: state.documentPreferenceData,
    accessFormsData: state.accessForms,
});

const mapDispatchToProps = {
    ...documentPreferenceActions,
    ...analytics,
    ...accessForms,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CommonDisclosure);
