import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WButton } from 'common';

const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const ModalDialog = styled.div`
    left: 16%;
    top: 11%;
    width: 67%;
    padding: 40px 100px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.16);
    border: 2px solid #D2D2D2;
    opacity: 1;
`;
const OkButtonStyle = {
    marginTop: '25px',
};
const ConformationModalTitle = styled.div`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;
const ConformationModalBody = styled.span`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
/* const ConformationModalBody2 = styled.span`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
`; */

class CannotDeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.firstElRef = React.createRef();
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';
        // set focus on first input
        this.firstElRef.current.focus();
    }

    componentWillUnmount() {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }
    
    onLastElementTab = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.firstElRef.current.focus();
        }
    };
    
    render() {
        const {
            modalTitle,
            modalBodyText,
            // modalBodyText2,modalBodyText3,modalBodyText4,modalBodyText5,
            onSecondaryClick,
        } = this.props;
        return (
            <ModalStyle>
                <ModalDialog role="dialog" aria-modal="true" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle id="VCM-Modal-title"> {modalTitle} </ConformationModalTitle>         
                    <div>
                        <ConformationModalBody> {modalBodyText} </ConformationModalBody>
                      {/*  <ConformationModalBody2>{modalBodyText2}</ConformationModalBody2>
                        <ConformationModalBody> {modalBodyText3} </ConformationModalBody>
                        <ConformationModalBody2>{modalBodyText4}</ConformationModalBody2>
                        <ConformationModalBody> {modalBodyText5} </ConformationModalBody> */}
                    </div>
                    <WButton
                        ref={this.firstElRef}
                        buttontext="Ok"
                        size="sm"
                        onClick={onSecondaryClick}
                        buttonstyle={OkButtonStyle}
                        onKeyDown={this.onLastElementTab}
                    />
                </ModalDialog>
            </ModalStyle>
        );
    }
}

CannotDeleteModal.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    /* modalBodyText2: PropTypes.string,
    modalBodyText3: PropTypes.string,
    modalBodyText4: PropTypes.string,
    modalBodyText5: PropTypes.string, */
    onSecondaryClick: PropTypes.func,
};

CannotDeleteModal.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    onSecondaryClick: ()=>{},
   /* modalBodyText2: 'BODY TEXT',
    modalBodyText3: 'BODY TEXT',
    modalBodyText4: 'BODY TEXT',
    modalBodyText5: 'BODY TEXT', */
};

export default CannotDeleteModal;
