import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setFirstName, setLastName, setMiddleName, setFamilyRelationShip, setprefix, setsufix, setDateOfBirth,
setGender, setMaritalStatus, setCitizen, setSSN, setCountryOfCitizen, 
setFamilyRelationError,setFirstNameError,setLastNameError,
setDateOfBirthError,setGenderError,setMaritalStatusError, setCitizenShipError, setCitizenShipCountryError,
 setSSNError } from 'shared/Actions/ProfileRelationAction';
import { VCMSelect, VCMDatePicker } from "common";
import { FieldValue, InputText, RadioGroup, SectionTitleWithSeparator, SSNtext, MandatoryText } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { getMetadataValues, getCountryMetaData } from 'modules/CustomerManagementModule/utils';
import { formatDate } from 'utils';
import { analyzeFormFieldError } from 'shared/Actions/AnalyticsActions';

const AddRelationshipWrap = styled.div`
	margin: 1.5em 0 2.5em;
`;

const handleInputChange = (dispatch, action) => (e) => {
	const { target } = e;
    const { value } = target;
    const newValue = value.replace(/[^A-Za-z\s]/gi, '').replace(/  +/g, ' ');
    dispatch(action(newValue));
}

const handleBlurErrorMsg = (dispatch, action, errmsg) => (e) => {
	const { target } = e;
    const { value } = target;
    const error = (!value) ? errmsg : '';
	dispatch(action(error));
    if(error){
        dispatch(analyzeFormFieldError());
    }
}

const handleRadioBlur = (dispatch, action, errmsg) => (e) => {
	const { target } = e;
    const { ariaChecked } = target;
    const error = ariaChecked === 'false' ? errmsg : '';
    dispatch(action(error));
}

const handleDropDownChange = (dispatch, action, actionErr) => (e) => {
	const { list } = e;
	const { value = '' }  = list.find((item) => item.key === e.target.value);
	dispatch(action(value));
	const error = '';
	if(value && actionErr) dispatch(actionErr(error));
}

const handleRadioChange = (dispatch, action, actionErr) => (e) => {
	const { target } = e;
    const { value } = target;
	dispatch(action(value));
	const error = '';
	if(value && actionErr) dispatch(actionErr(error));
}

export const setRSsn = (dispatch, action) => (value)=>{
	dispatch(setSSN(value));
	if(value) {dispatch(action(''))} else {dispatch(action(CONST.SSN_ERROR))}
}

const getMasterDataValues = (masterLookupStateData) => {
    const genderData = getMetadataValues(masterLookupStateData, 'gender');
    const mStatusData = getMetadataValues(masterLookupStateData, 'marital_status');
    const prefixData = getMetadataValues(masterLookupStateData, 'prefix');
    const suffixData = getMetadataValues(masterLookupStateData, 'suffix');
    const citizenshipData = getMetadataValues(masterLookupStateData, 'citizenship');
    const countryCodeData = getMetadataValues(masterLookupStateData, 'country_2_lettercode');
    const relationToCustomerData = getMetadataValues(masterLookupStateData, 'relationship');
    const citizenShipProofData = getMetadataValues(masterLookupStateData, 'citizenship_proof');
    return { genderData, mStatusData, prefixData, suffixData, citizenshipData, countryCodeData, relationToCustomerData, citizenShipProofData };
};

const RestrictCurrentDate = () => {
	const currDate = new Date();
	const currYear = currDate.getUTCFullYear();
	const CurrMonth = currDate.getUTCMonth();
	const currDay = currDate.getUTCDate();
	const prevDay = currDay -1;
	return new Date(currYear, CurrMonth, prevDay);
}

const PersonalInfoAdd = () => {
	
	const dispatch = useDispatch();
	
	const personalInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { personalInformation } = profileRelationshipData;
        return personalInformation;
	});
    
    const { 
		firstName,
		lastName,
		middleName,
		gender,
		citizen,
		maritalStatusError,
		familyRelatioshipError,
		firstNameError,
		lastNameError,
		dateOfBirthError,
		genderError,
		citizenShipError,
		citizenShipCountryError,
		// SSNError,
	} = personalInfo;
    const masterLookupStateData = useSelector(({ masterLookUpData: metaData }) => metaData || {});
	
	const { 
		countryCodeData,
		relationToCustomerData,
		prefixData,
		suffixData,
		genderData,
		mStatusData,
		citizenshipData,
	} = getMasterDataValues(masterLookupStateData);
  return(
		<>
			<MandatoryText>{CONST.MANDATORY_TEXT}</MandatoryText>
			<SectionTitleWithSeparator titleTxt={CONST.ADD_RELATIONSHIP_TITLE} />
					<AddRelationshipWrap>
						<VCMSelect
							id="vcm-cms-relation-relationship-to-you"
							label={CONST.FAMILY_RELATION}
							name={CONST.FAMILY_RELATION}
							itemlist={relationToCustomerData}
							onChange={handleDropDownChange(dispatch, setFamilyRelationShip, setFamilyRelationError)}
							onBlur={handleBlurErrorMsg(dispatch,setFamilyRelationError,CONST.FAMILY_RELATION_TO_YOU_ERROR)}
							errortext={familyRelatioshipError}
							labelsm={3}
							valuesm={5}
							required
						/>
						<FieldValue
							htmlFor="vcm-cms-relation-firstname"
							label={CONST.FIRSTNAME}
							value={
								<InputText
										id="vcm-cms-relation-firstname"
										value={firstName}
										onChange={handleInputChange(dispatch, setFirstName)}
										onBlur={handleBlurErrorMsg(dispatch,setFirstNameError,CONST.FIRST_NAME_ERROR)}
										error={!!firstNameError}
										errorTxt={firstNameError}
										data-name={CONST.FIRSTNAME}
										data-form-field
										aria-required
								/>
							}
							labelsm={3}
							valuesm={5}
							mb={1.5625}
						/>
						<FieldValue
							htmlFor="vcm-cms-relation-lastname"
							label={CONST.LASTNAME}
							value={
								<InputText
										id="vcm-cms-relation-lastname"
										value={lastName}
										onChange={handleInputChange(dispatch, setLastName)}
										onBlur={handleBlurErrorMsg(dispatch,setLastNameError,CONST.LAST_NAME_ERROR)}
										error={!!lastNameError}
										errorTxt={lastNameError}
										data-name={CONST.LASTNAME}
										data-form-field
										aria-required
								/>
							}
							labelsm={3}
							valuesm={5}
							mb={1.5625}
						/>
						<FieldValue
							htmlFor="vcm-cms-relation-middlename"
							label={CONST.MIDDLENAME}
							value={
								<InputText
										id="vcm-cms-relation-middlename"
										onChange={handleInputChange(dispatch, setMiddleName)}
										value={middleName}
										data-name={CONST.MIDDLENAME}
										data-form-field
								/>
							}
							optional
							labelsm={3}
							valuesm={5}
							mb={1.5625}
						/>
						<VCMSelect
							id='vcm-cms-relation-prefix'
							label={CONST.PREFIX}
							name={CONST.PREFIX}
							itemlist={prefixData}
							onChange={handleDropDownChange(dispatch, setprefix, null)}
							labelsm={3}
							valuesm={5}
							optional
						/>
						<VCMSelect
							id='vcm-cms-relation-sufix'
							label={CONST.SUFIX}
							name={CONST.SUFIX}
							itemlist={suffixData}
							onChange={handleDropDownChange(dispatch, setsufix, null)}
							labelsm={3}
							valuesm={5}
							optional
						/>
						<VCMDatePicker
							id="vcm-cms-relation-date-of-birth"
							labelsm={3}
							valuesm={5}
							label={CONST.DATEOFBIRTH}
							name={CONST.DATEOFBIRTH}
							onChange={handleRadioChange(dispatch, setDateOfBirth, setDateOfBirthError)}
                            onBlur={handleBlurErrorMsg(dispatch,setDateOfBirthError,CONST.DATEOFBIRTH_ERROR)}
                            max={formatDate(RestrictCurrentDate())}
                            maxErrorTxt={CONST.INVALID_DATE_ERROR}
							errortext={dateOfBirthError}
							required
						/>
						<FieldValue
							as="p"
							id="vcm-cms-relation-gender"
							label={CONST.GENDER}
							value={
								<RadioGroup
									labelledBy="vcm-cms-relation-gender"
									options={genderData}
									initialValue={gender || ''}
									onChange={handleRadioChange(dispatch, setGender, setGenderError)}
									onBlur={handleRadioBlur(dispatch,setGenderError,CONST.GENDER_ERROR)}
									error={!!genderError}
									errorTxt={genderError}
									data-name={CONST.GENDER}
									aria-required
								/>
							}
							labelsm={3}
							valuesm={5}
							mb={1.5625}
						/>
						<FieldValue
							as="p"
							id="vcm-cms-relation-ssn"
							label={CONST.SSN}
							value={
							<SSNtext
								labelledBy="vcm-cms-relation-ssn"
								onBlur={setRSsn(dispatch, setSSNError)}
								optional
								data-name={CONST.SSN}
							/>
							}
							optional
							labelsm={3}
							valuesm={4}
							mb={1.5625}
						/>
						<VCMSelect
							id='vcm-cms-relation-marital-status'
							label={CONST.MARITALSTATUS}
							name={CONST.MARITALSTATUS}
							itemlist={mStatusData}
							onChange={handleDropDownChange(dispatch, setMaritalStatus, setMaritalStatusError)}
							onBlur={handleBlurErrorMsg(dispatch,setMaritalStatusError,CONST.MARITAL_STATUS_ERROR)}
							errortext={maritalStatusError}
							labelsm={3}
							valuesm={5}
							required
						/>
						<FieldValue
							as="p"
							id="vcm-cms-relation-citizenship"
							label={CONST.USCITIZEN}
							value={
								<RadioGroup
									labelledBy="vcm-cms-relation-citizenship"
									options={citizenshipData}
									initialValue={citizen || ''}
									onChange={handleRadioChange(dispatch, setCitizen, setCitizenShipError)}
									onBlur={handleRadioBlur(dispatch,setCitizenShipError,CONST.CITIZENSHIP_ERROR)}
									error={!!citizenShipError}
									errorTxt={citizenShipError}
									data-name={CONST.USCITIZEN}
									aria-required
								/>
							}
							labelsm={3}
							valuesm={5}
							mb={1.5625}
						/>
						{citizen === 'Non U.S' && 
							<>
                                <VCMSelect
                                    id='vcm-cms-relation-country-of-citizenship'
									label={CONST.COUNTRY_OF_CITIZEN}
									name={CONST.COUNTRY_OF_CITIZEN}
                                    itemlist={getCountryMetaData(countryCodeData)}
									onChange={handleDropDownChange(dispatch, setCountryOfCitizen, setCitizenShipCountryError)}
									onBlur={handleBlurErrorMsg(dispatch,setCitizenShipCountryError,CONST.COUNTRY_ERROR)}
									errortext={citizenShipCountryError}
                                    labelsm={3}
									valuesm={5}
									required
                                />																								
							</>
						}						
					</AddRelationshipWrap>
		</>
  )
}

export default PersonalInfoAdd