import React from "react";
import PropTypes from "prop-types";
import { Container } from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import routeConstants from 'routeConstants';
import { decrypt } from 'utils';
import { StatusMessage, InstructionalPanel, OffScreenText, ConfirmationMessage } from '../components';
import CONST from "./constants";
import PersonalInfoView from "../PersonalInformation/PersonalInfoView/PersonalInfoView";
import ContactInfoView from "../ContactInformation/ContactInfoView/ContactInfoView";
import EmailInfoView from "../EmailInformation/EmailInfoView/EmailInfoView";
import FinancialInfoView from "../FinancialInformation/FinancialInfoView/FinancialInfoView";
import EmploymentInfoView from "../EmploymentInformation/EmploymentInfoView/EmploymentInfoView";
import MilitaryInfoView from "../MilitaryInformation/MilitaryInfoView/MilitaryInfoView";
import PhoneInfoView from "../PhoneInformation/PhoneInfoView/PhoneInfoView";
import RelationshipInfoView from "../RelationShip/RelationshipInfoView/RelationshipView";
import { getSessionValues } from "shared/Helpers/Utils";

class BasicInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { getMemberProfileInformations, profileInformationData } = this.props;
        const { hasProfileInfo } = profileInformationData;
        if(!hasProfileInfo){
            // const msrInfo = sessionStorage.getItem("msr_dts");
            const params = getSessionValues();    
            const {accesstoken, vcmid } = params;        
            const msrParams = {
                accesstoken,
                vcmid,                
            }
            getMemberProfileInformations(decrypt, msrParams);            
        }
    }
    
    componentDidUpdate(){
        const { profileInformationData, clearExistingActions, profileRelationshipData, clearRelationActions } = this.props;
        const { isError, isSuccess } = profileInformationData;
        const { isError: isRelationError, isSuccess: isRelationSuccess } = profileRelationshipData;
        if(isSuccess || isError){
            clearExistingActions();
        }
        
        if(isRelationError || isRelationSuccess){
            clearRelationActions();
        }
    }

    findPrimaryphone = (phone) => {
       return (phone || []).find((data) => data.isPrimary);
    }
    
    findSecondaryphone = (phone) => {
       return (phone || []).find((data) => !data.isPrimary);
    }
    
    findMailingAddresstype = (address) => {
        return (address || []).find((data) => data.isMailingAddress);
    }
    
    findPhysicalAddresstype = (address) => {
        return (address || []).find((data) => data.isPhysicalAddress);
    }
    
    findViewOnlyStopCode = (accounts) => {
        return (accounts || []).some((data) => data.stopCode === 9 || data.stopCode === '9');
    }
    
    onManageClick = (route) => () => {
        const { history, analyzeClickEvent } =  this.props;
        const pageName = route.split('/').join('');
        analyzeClickEvent({
            link: `cms_basicinformation_${pageName}Manage`
        })
        history.push(route)
    }
    
    onConformationUpdate = () => {
        this.closeDialog();
        const { history } =  this.props;
        history.push(routeConstants.financialInfo)
    };
    
    closeDialog = () => {
        const { closeFinancialRemainder } = this.props;
        closeFinancialRemainder();
    };

    getNotificationMsg = ({isAddressEmpty, isPhoneEmpty,  isEmailEmpty, isFinancialEmpty, isEmploymentEmpty, isMilitaryEmpty}) => {
        let notifyFor = '';
        if(isAddressEmpty) {
            notifyFor = 'Contact';
        }
        if(isPhoneEmpty) {
            notifyFor = `${notifyFor} Phone`;
        }
        if(isEmailEmpty) {
            notifyFor = `${notifyFor} Email`;
        }
        if(isFinancialEmpty){
            notifyFor = `${notifyFor} Financial`;
        }
        if(isEmploymentEmpty){
            notifyFor = `${notifyFor} Employment`;
        }
        if(isMilitaryEmpty){
            notifyFor = `${notifyFor} Military`;
        }

        let emptyInfos = notifyFor.trim();
        const splittedValue = emptyInfos.split(' ');
        if(splittedValue.length > 1){
            const lastValue = splittedValue.splice(splittedValue.length -1, 1);
            emptyInfos = `${splittedValue.join(', ')} and ${lastValue}`;
        }

        return {
            message: `${CONST.EMPTY_INFO_NOTIFICATION_1} ${emptyInfos} ${CONST.EMPTY_INFO_NOTIFICATION_2}`,
            info: null
        }
    }
    
    onNotificationClose = () => {
        const { closeEmptyInfoNotification } = this.props;
        closeEmptyInfoNotification();
    }

    render() {
        const { profileInformationData, profileRelationshipData, memberDashboardData={} } = this.props;
        const {
            profileInformation,
            showFinancialReminder, 
            isFinancialEmpty = false,
            isEmploymentEmpty = false,
            isMilitaryEmpty = false,
            isAddressEmpty = false,
            isPhoneEmpty = false,
            isEmailEmpty = false,
            isLoading: memberLoading,
        } = profileInformationData;
        const {
            firstName = '',
            middleInitial = '',
            lastName = '',
            citizenship = '',
            gender = '',
            maritalStatus = '',
            memberId = '',
            prefix = '',
            ssnTin = '',
            suffix = '',
            vcmId = '',
            dateOfBirth = '',
            countryOfCitizenship = '',
            emailInformation = {},
            financialInformation = {},
            addressInformation = [],
            militaryInformation = {},
            phoneInformation = [],
            employmentInformation = {},
        } = profileInformation || {};
         const { primaryEmail = '', secondaryEmail = '' } = emailInformation || {};
         const { annualIncome = '', taxBracket= '', netWorth= '', taxFilingStatus= '' } = financialInformation || {};
         const { employmentStatus = '', industry= '', occupation= '', employerName= '', addressLine1='', addressLine2= '', employersCity= '', employersState= '', primarySourceOfIncome='', employersZipcode='' } = employmentInformation || {};
         const { militaryStatus = '', isCurrentlyServing = false, rank = '', fromDate = '', toDate = '', commissionSource = '', branchOfService = '' } = militaryInformation || {};
         const { accountSummary={} } = memberDashboardData || {};
         const { accountDetails={} } = accountSummary || {};
         const viewOnly = true;
        const { isLoading: relationLoading } = profileRelationshipData;
        const loading = memberLoading || relationLoading;

        const showEmptyNotification = isFinancialEmpty || isEmploymentEmpty || isMilitaryEmpty || isAddressEmpty || isPhoneEmpty || isEmailEmpty;

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                {(showEmptyNotification) && (
                            <StatusMessage
                                onClose={this.onNotificationClose}
                                text={this.getNotificationMsg({
                                    isAddressEmpty,
                                    isPhoneEmpty, 
                                    isEmailEmpty,
                                    isFinancialEmpty,
                                    isEmploymentEmpty,
                                    isMilitaryEmpty
                                })}
                            />
                        )}
                {showFinancialReminder && (
                    <ConfirmationMessage
                        modalTitle={CONST.FINANCIAL_REMAINDER_MODAL_TITLE}
                        modalBodyText={CONST.FINANCIAL_REMAINDER_MODAL_TEXT}
                        primaryButtonText={CONST.FINANCIAL_REMAINDER_MODAL_PRIMARY_BTN}
                        secondaryButtonText={CONST.FINANCIAL_REMAINDER_MODAL_SECONDARY_BTN}
                        onPrimaryClick={this.onConformationUpdate}
                        onSecondaryClick={this.closeDialog}
                    />
                )}
                    <Container>
                        <WBreadCrumb
                            key=""
                            breadCrumbItems={CONST.BREADCRUM_ITEMS}
                            activeCrumb={CONST.PAGE_TITLE}
                        />
                        <OffScreenText
                            as="h1"
                            hideTxt={CONST.PAGE_TITLE}
                            tabIndex="-1"
                        />
                        <PersonalInfoView 
                            firstName={firstName}
                            middleInitial={middleInitial}
                            lastName={lastName}
                            citizenship={citizenship}
                            gender={gender}
                            maritalStatus={maritalStatus}
                            memberId={memberId}
                            prefix={prefix}
                            ssnTin={ssnTin}
                            suffix={suffix}
                            vcmId={vcmId}
                            dateOfBirth={dateOfBirth}
                            countryOfCitizenship={countryOfCitizenship}
                            onManageClick={this.onManageClick(routeConstants.personalInformation)}
                            hideManage={viewOnly}
                        />
                        <ContactInfoView 
                            mailingAddress={this.findMailingAddresstype(addressInformation)}
                            physicalAddress={this.findPhysicalAddresstype(addressInformation)}
                            onManageClick={this.onManageClick(routeConstants.contactInfo)}
                            hideManage={viewOnly}
                        />
                        <PhoneInfoView
                            primaryPhone = {this.findPrimaryphone(phoneInformation)}
                            SecondaryPhone = {this.findSecondaryphone(phoneInformation)}
                            onManageClick={this.onManageClick(routeConstants.phoneInfo)}
                            hideManage={viewOnly}
                        />
                        <EmailInfoView
                            primaryEmail={primaryEmail}
                            secondaryEmail={secondaryEmail}
                            onManageClick={this.onManageClick(routeConstants.emailInfo)}
                            hideManage={viewOnly} 
                        />
                        <FinancialInfoView
                            annualIncome={annualIncome}
                            taxBracket={taxBracket}
                            netWorth={netWorth}
                            taxFilingStatus={taxFilingStatus}
                            onManageClick={this.onManageClick(routeConstants.financialInfo)}
                            hideManage={viewOnly}
                        />
                        <EmploymentInfoView
                            employmentStatus={employmentStatus}
                            industry={industry}
                            occupation={occupation}
                            employerName={employerName}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            employersCity={employersCity}
                            employersState={employersState}
                            employersZipcode={employersZipcode}
                            primarySourceOfIncome={primarySourceOfIncome}
                            onManageClick={this.onManageClick(routeConstants.empInfo)}
                            hideManage={viewOnly}
                        />
                        <MilitaryInfoView
                            militaryStatus={militaryStatus}
                            isCurrentlyServing={isCurrentlyServing}
                            rank={rank}
                            fromDate={fromDate}
                            toDate={toDate}
                            commissionSource={commissionSource}
                            branchOfService={branchOfService}
                            onManageClick={this.onManageClick(routeConstants.militaryInfo)}
                            hideManage={viewOnly}
                        />                        
                        <RelationshipInfoView
                            onManageClick={this.onManageClick(routeConstants.addRelations)}
                            hideAdd={viewOnly}
                        />
                        {/*  <SocialMediaInformation /> */}
                        <InstructionalPanel mb={1.5625} mbt={1.25}>
                          {CONST.SECURITY_REASONS}
                        </InstructionalPanel>
                    </Container>
            </>
        );
    }
}

BasicInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    getMemberProfileInformations: PropTypes.func,
    profileInformationData: PropTypes.instanceOf(Object),
    profileRelationshipData: PropTypes.instanceOf(Object),
    memberDashboardData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    clearRelationActions: PropTypes.func,
    closeFinancialRemainder: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    closeEmptyInfoNotification: PropTypes.func,
};

BasicInfoComponent.defaultProps = {
    getMemberProfileInformations: () => {},
    profileInformationData: {},
    profileRelationshipData: {},
    memberDashboardData: {},
    clearExistingActions: () => {},
    clearRelationActions: () => {},
    closeFinancialRemainder: () => {},
    analyzeClickEvent: () => {},
    closeEmptyInfoNotification: () => {},
};

export default BasicInfoComponent;
