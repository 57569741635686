import React from 'react';
import { Container, Row, Col ,Tab, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { isEmpty} from 'lodash';
import styled from 'styled-components';
import { WSpinner } from '../../../common';
import mail from '../../../assets/mail-icon.png';
import MessageCenterWidget from '../Common/MessageCenterWidget';
import poweredByMarstone from '../../../assets/PoweredByMarstone-white.svg';
import WCardDashboardOverlay from '../Common/WCardDashboardOverlay/WCardDashboardOverlay';
import guestCardData from './GuestViewDashboardConstants';
import WCardOverlay from '../Common/WCardOverlay/WCardOverlay';
import '../Common/Styles.css';
import IconFinancialResources from '../../../assets/Icon_financial_resources.png';
import iconOffers from '../../../assets/icon_offers.png';
import iconAllocation from '../../../assets/icon_allocation.png';
import SideHeader from '../Common/SideHeaderMain/SideHeader';
import DocumentCenter from '../Common/DocumentCenter';
import MySavedItemsWidget from '../Common/MySavedItemsWidget';
import Offers from '../Common/Offers/Offers';
import Styles from './GuestViewStyles';
import MSAuthRedirect from '../Common/Marstone/Marstone';
import constant from '../Common/AccountDashboardConstants';
import ContactCenterWidget from '../Common/ContactCenter';
import GuestCard from '../Common/GuestCard/GuestCard';
import routeConstants from '../../../routeConstants';
import traingleicon from '../../../assets/alert.png';
import DButton from '../Common/DButton/DButton';
import envConfig from 'env/EnvConfig';

const StyledP = styled.p`
    font: 500 15px/22px benton-sans, sans-serif;
    margin-bottom: 0px;
    color:#49494A;
`
const StyledP1 = styled.p`
    font: 600 15px/19px benton-sans, sans-serif;
    margin-bottom: 10px;
    margin-top:14px;
    color:#56565A;
`
const StyledP2 = styled.p`
    font: 700 18px/22px benton-sans, sans-serif;
    margin-bottom: 16.5px;
    color:#56565A
`
const SavedLink = styled(Link)`
  &:focus {
      outline: 2px solid #000000;
      outline-offset: 0px;
  }
`

class GuestViewDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            card: guestCardData,
            marstoneClick: false,
            saveDateShow: 'true',
            dashbordContentData:[],
            systemHealth:{
                fis:"Up",
                pershing:"Up",
                ascensus:"Up"
            },
            redirectToMarketNAO:false,
            savedItemsForDashbord:[],
            savedItemsListrendercount:0,
            savedItemModal:false,
            isLoading : false,
            renderCountSavedItem: 0,
            profileStatusSaveCount:0
        };
}


    componentDidMount() {
        const { 
            clearLocalStateForAccOpening, 
            clearLocalStateForNAO, 
            loginState, 
            getCustomerProfile, 
            setJWTToken ,
            clearMarketNAOInfo    } = this.props;

        const { getCustomerProfile: customerProfile } = loginState || {};
        const token = localStorage.getItem('token');
        if (isEmpty(customerProfile) && !isEmpty(token)) {
            setJWTToken(token);
            const payLoad = {};
            getCustomerProfile(payLoad);
        }
        clearLocalStateForAccOpening();
        clearLocalStateForNAO();
        clearMarketNAOInfo();
       
        this.loadDashboardContent();
        // make api call to get the cofig details to load banner information
        this.props.getApplicationConfig()
    
        this.props.getEnabledFeature()
        ///
       
        clearLocalStateForNAO();
        clearLocalStateForAccOpening();      
    }

    dropdownClickhandler = (e) =>{
        e.preventDefault();
        const openDropParent= document.getElementById('mobiletabbar');
        if (openDropParent.classList.contains('is-open')) {
            openDropParent.classList.remove('is-open')
          } else {
            openDropParent.classList.add('is-open')
         }
        
    }

    cardClickHandler = (key, value) => e => {
        const { manageLocalState, history } = this.props;
        e.preventDefault();
        e.stopPropagation();
        const { analyzeClickEvent } = this.props;
        const screenName = localStorage.getItem('screenName');
        analyzeClickEvent({
            link: `Dashboard_${screenName}_Open an Account_${key}`,
        });
        const data = {
            accSelected: key,
            accIDSelected: value,
            masterDataLoadedForOpenAcc: true,
        };
        manageLocalState(data);
        const { accountType: accountTypeRoute, transferAccount } = routeConstants;
        if (value === 'trans_acct') {
            history.push(transferAccount);
        } else {
            history.push(accountTypeRoute);
        }
    };  

    // navigateTo = (path, key) => {
    //     const navigateUrl = path;
    //     const { history } = this.props;
    //     history.push({
    //         pathname: navigateUrl,
    //         state: { datakey: key },
    //     });
    // };

    marstone = e => {
        this.setState(state => ({
            ...state,
            marstoneClick: true,
        }));
        const { analyzeClickEvent } = this.props;
        const linkName = e.target.innerText || '';
        const screenName = localStorage.getItem('screenName');
        analyzeClickEvent({
            link: `Dashboard_${screenName}_Resources and Tools_${linkName}`,
        });
        setTimeout(() => {
            this.setState(state => ({
                ...state,
                marstoneClick: false,
            }));
        }, 0);
    };

    sendAnalyticsData = (path, name) => {

        let prefix = "Banner_";
        const { analyzeClickEvent } = this.props;
        let linkName = name || '';
        linkName = prefix.concat(linkName.replace(/ /g,"_"));
  
        analyzeClickEvent({
            link: linkName,
        });
    }


    showDashboardTabs = () =>{
        let tabsShow = 'brokerage';
        const {systemHealth} = this.state;
        if(tabsShow == 'default'){
            return(
            <>
            <Nav variant="pills">
                <Nav.Item className='tabbox'>
                <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                </Nav.Item>

                <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="funds" className="tablink second">Funds
                    {(systemHealth.fis == "Down" ) && 
                        <><br /><img src={traingleicon}className="triangleIcon" style={{width:'20px',height:'auto'}} /> </>
                    }
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className='tabbox'>
                <Nav.Link eventKey="markets" className="tablink fourth">Marketplace
                {(systemHealth.pershing == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                </Nav.Link>
                </Nav.Item>
                <Nav.Item className='tabbox'>
                <Nav.Link eventKey="education" className="tablink third">Education
                {(systemHealth.ascensus == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                </Nav.Link>
                </Nav.Item>
            </Nav>
            
            <div className="mobnav" id="mobiletabbar">
                    <a href="#" id="mobnav-toggle1" onClick={this.dropdownClickhandler} className="mobnav__option mobnav__option--current overviewtext">
                    <span id="innertext1" className='nametext'>Overview</span> <span className="mobnav__chevron">›</span>
                    </a>
                    <ul className="mobnav__list">
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" className="mobnav__option overviewtext">Overview</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("funds")} className="mobnav__option fundstext">Funds</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("markets")} className="mobnav__option marketstext">Marketplace</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("education")} className="mobnav__option educationtext">Education</a>
                        </li>
                    </ul>  
                </div>

            </>
            )
        }
        else if(tabsShow == 'brokerage'){
            return(
                <>
                <Nav variant="pills">
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="funds" className="tablink second">Funds
                    {(systemHealth.fis == "Down" ) && 
                        <><br /><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                  
                    </Nav.Item>
                    
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="markets" className="tablink fourth">Marketplace 
                    {(systemHealth.pershing == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>

                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="education" className="tablink third">Education
                    {(systemHealth.ascensus == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                  
                    </Nav.Item>
                </Nav>
               

                <div className="mobnav" id="mobiletabbar">
                    <a href="#" id="mobnav-toggle" onClick={this.dropdownClickhandler} className="mobnav__option mobnav__option--current overviewtext">
                    <span id="innertext" className='nametext'>Overview</span> <span className="mobnav__chevron">›</span>
                    </a>
                    <ul className="mobnav__list">
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("overview")} className="mobnav__option overviewtext">overview</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("funds")} className="mobnav__option fundstext">Funds</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("markets")} className="mobnav__option marketstext">Marketplace</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("education")} className="mobnav__option educationtext">Education</a>
                        </li>
                    </ul>  
                </div>
                </>
                )
        }
        else if(tabsShow == 'funds'){
            return(
                <>
                <Nav variant="pills">
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="funds" className="tablink second">Funds
                    {(systemHealth.fis == "Down" ) && 
                        <><br /><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                  
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="education" className="tablink third">Education
                    {(systemHealth.ascensus == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                   
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="markets" className="tablink fourth">Marketplace
                    {(systemHealth.ascensus == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                    
                    </Nav.Item>
                </Nav>
                <div className="mobnav" id="mobiletabbar">
                    <a href="#" id="mobnav-toggle2" onClick={this.dropdownClickhandler} className="mobnav__option mobnav__option--current fundstext">
                    <span id="innertext2" className='nametext'>Funds</span> <span className="mobnav__chevron">›</span>
                    </a>
                    <ul className="mobnav__list">
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" className="mobnav__option overviewtext">Overview</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("funds")} className="mobnav__option fundstext">Funds</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("education")} className="mobnav__option educationtext">Education</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("markets")} className="mobnav__option marketstext">Marketplace</a>
                        </li>
                        
                    </ul>  
                </div>
                </>
                )
        }
        else{
            return(
                <>
                <Nav variant="pills">
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="funds" className="tablink second">Funds
                    {(systemHealth.fis == "Down" ) && 
                        <><br /><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                   
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="markets" className="tablink fourth">Marketplace
                    {(systemHealth.pershing == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                   
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="education" className="tablink third">Education
                    {(systemHealth.ascensus == "Down" ) && 
                        <><img src={traingleicon} style={{height: '20px'}} /> </>
                    }
                    </Nav.Link>
                   
                    </Nav.Item>
                </Nav>
                
                <div className="mobnav" id="mobiletabbar">
                    <a href="#" id="mobnav-toggle4" onClick={this.dropdownClickhandler} className="mobnav__option mobnav__option--current educationtext">
                    <span id="innertext4" className='nametext'>Education</span> <span className="mobnav__chevron">›</span>
                    </a>
                    <ul className="mobnav__list">
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" className="mobnav__option overviewtext">Overview</a>
                        </li>
                        <li onClick={this.dropdownClickhandler }>
                            <a href="#" onClick={()=>this.handleSwitchTabs("funds")} className="mobnav__option fundstext">Funds</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("markets")} className="mobnav__option marketstext">Marketplace</a>
                        </li>
                        <li onClick={this.dropdownClickhandler}>
                            <a href="#" onClick={()=>this.handleSwitchTabs("education")} className="mobnav__option educationtext">Education</a>
                        </li>
                        
                        
                    </ul>  
                </div>
                
                </>
                )
        }
    }

    handleSwitchTabs = (key) => {
        this.setState({activeTab:key.toLowerCase()})
        
        // converting first letter to uppercase
        // const capitalized = key.charAt(0).toUpperCase() + str.slice(1);
        const innertext= document.getElementById("innertext").innerHTML=`${key.toLowerCase()}`

        const  innertext1=document.getElementById('mobnav-toggle')
        innertext1.classList.remove('overviewtext')
        innertext1.classList.remove('marketstext')
        innertext1.classList.remove('educationtext')
        innertext1.classList.remove('fundstext')
       
         if (innertext == 'funds') {
           innertext1.classList.add('fundstext')
        } 
        else if (innertext == 'overview') {
            innertext1.classList.add('overviewtext')
        } 
        else if (innertext == 'markets') {
            innertext1.classList.add('marketstext')
        } 
        else {
            innertext1.classList.add('educationtext')
        } 

        

    }

    isHyperFeatureAvailable = () => {
        // config(hyper) + features(hyper) = show hyper else marstone 

        // check where the congif api has the portolio provider value
        const portfolioPlannerProvider = this.props.loginState?.data?.response?.data?.result.find(obj => { return obj.key === "REACT_APP_PORTFOLIO_PLANNER_PROVIDER"})

        const isHyperFeatureEnabledInConfig = portfolioPlannerProvider != undefined ? portfolioPlannerProvider.value == "HYPER" : false

        // check if the Hyper feature is enable, value coming from the api response array and in cofiguration api
        const isHyperFeatureEnabled =         
            this.props.memberDashboardData.getEnabledFeature?.features != undefined ?
            this.props.memberDashboardData.getEnabledFeature?.features.some(item => item === "Hyper") && isHyperFeatureEnabledInConfig  : false

    
        return isHyperFeatureEnabled

    }
    portfolioPlanner = e => {
        this.props.history.push({
            pathname: '/portfolioPlannerPage'
        })

    }


    redirectPageTo = (accType) =>{
  

        if(accType.toLowerCase().includes('529') || accType.toLowerCase().includes('minors')){
            this.props.manageLocalState(this.accountInfoMapping1(accType));
            this.setState({redirectToNAO:true})
        }else{
            this.props.manageLocalState(this.accountInfoMapping(accType));
            this.setState({redirectToNAO:true})
        }

    }

    accountInfoMapping = (accType) => {
        return {
            accSelected: this.getAccountInformation(accType).accSelected,
            accIDSelected: this.getAccountInformation(accType).accIDSelected,
            OpenAccPageOne: {
                companyNumber: '591',
                accountType: this.getAccountInformation(accType).accSelected,
            },
            companyNumber: '591',
            accountType: this.getAccountInformation(accType).accSelected,
            masterDataLoadedForOpenAcc: true,
            masterDataLoadedForAccType: true,
        };
    };


    accountInfoMapping1 = (accType) => {
        return {
            accSelected: accType.toLowerCase().includes('529') ? 'Investing for Children':'Investing for Children',
            accIDSelected: 'inv_child',
            masterDataLoadedForOpenAcc: true,
            OpenAccPageOne: {
                companyNumber: '591',
                accountType: accType.toLowerCase().includes('529')? '529 Education Savings Plan':'Uniform Gifts/Transfers to Minors(UGMA/UTMA)',
            },
            companyNumber: '591',
            accountType: accType.toLowerCase().includes('529')? '529 Education Savings Plan':'Uniform Gifts/Transfers to Minors(UGMA/UTMA)',
            masterDataLoadedForAccType: true,
        };
    };

    
    // getAccountInformation = accType => {
    //     let accSelected = '';
    //     let accIDSelected = '';
    //     if (accType === 'Individual' || accType.toLowerCase().includes('joint') || accType.toLowerCase().includes('general')) {
    //         accSelected = 'General Investment Account';
    //         accIDSelected = 'gen_inv_acct';
    //     } else if (
    //         accType.trim() === 'Traditional IRA' ||
    //         accType.trim() === 'Roth IRA' ||
    //         accType.trim() === 'SEP IRA' ||
    //         accType.toLowerCase().includes('individual')
    //     ) {
    //         accSelected = 'Individual Retirement Account';
    //         accIDSelected = 'ira';
    //     } else if (
    //         accType.toLowerCase().includes('utma') ||
    //         accType.toLowerCase().includes('ugma') ||
    //         accType.toLowerCase().includes('529')
    //     ) {
    //         accSelected = 'Investing for Children';
    //         accIDSelected = 'inv_child';
    //     } else if (accType.trim() === 'Trust'||
    //         accType.toLowerCase().includes('speciality')
    //     ) {
    //         accSelected = 'Specialty Account';
    //         accIDSelected = 'spec_acct';
    //     } else if (accType.trim() === 'Money Market' || accType.trim() === 'Tax Advantaged Money Market') {
    //         setIsMoneyMartketAccount(true);
    //     }
    //     return { accSelected, accIDSelected };
    // };

    
    // When there is an api error 
    unavailableAPIErrorResponse = () =>{
        return (
           <div class="row">
                <div class="col-sm-12 col-md-8 offset-md-2 text-center fundsBox">
                    <img src={traingleicon} />
                    <h1 className='fundsTitle mt-4 mb-4'>API Unavailable Header</h1>
                    <p className='featureText text-left'>This is sample content paragraph</p>
                    {/* <DButton item={props.notifyMe} /> */}
                </div>
            </div> 

        )
    }


    selectAccountType = (acctype) => {
        const {dashbordContentData} = this.state;
        if(acctype == 'funds'){
            return(
                <>
                <div class="row guestbanner guestbanner1" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Desktop + ")" }}>
                    <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                            <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.title.replace('<p>','').replace('</p>','')}` }}>
                            </h3>
                            <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.description.replace('<p>','').replace('</p>','')}` }}></p>
                        </div>
                        <div class="col-sm-12 col-lg-5  text-center">
                            
                    </div>
                    <div class="row col-xl-12 col-lg-12  col-sm-12  leftButtonbar">
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className='general-acc-link'>
                            <DButton item={{name:guestCardData.general,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.general)} />
                        </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className='individual-acc-link'>
                            <DButton item={{name:guestCardData.individual,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.individual)} />
                            
                        </div>
                        </div>
                    </div>
                    <div class="row col-xl-12 col-lg-12  col-sm-12 leftButtonbar">
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className='minor-link'>
                            <DButton item={{name:guestCardData.investingforminors,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.investingforminors)} />
                        </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className='speciality-link' >
                            <DButton item={{name:guestCardData.specialityAccounts,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.specialityAccounts)} />
                        </div>
                        </div>
                    </div>
                </div>

                {/* Mobile guest Funds section */}

                <div class="row guestbanner guestbanner1 mobguestbanner1" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                        <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.title.replace('<p>','').replace('</p>','')}` }}>
                        </h3>
                        <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.description.replace('<p>','').replace('</p>','')}` }}></p>
                    </div>
                    <div class="col-sm-12 col-lg-5  text-center">
                        
                </div>
                <div class="row col-xl-12 col-lg-12  col-sm-12  leftButtonbar">
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='general-acc-link'>
                        <DButton item={{name:guestCardData.general,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.general)} />
                    </div>
                    </div>
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='individual-acc-link'>
                        <DButton item={{name:guestCardData.individual,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.individual)} />
                        
                    </div>
                    </div>
                </div>
                <div class="row col-xl-12 col-lg-12  col-sm-12 leftButtonbar">
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='minor-link'>
                        <DButton item={{name:guestCardData.investingforminors,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.investingforminors)} />
                    </div>
                    </div>
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='speciality-link' >
                        <DButton item={{name:guestCardData.specialityAccounts,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.specialityAccounts)} />
                    </div>
                    </div>
                </div>
            </div>

             {/* Tab guest Funds section */}

            <div class="row guestbanner guestbanner1 tabguestbanner1" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.funds?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                        <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.title.replace('<p>','').replace('</p>','')}` }}>
                        </h3>
                        <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.funds?.description.replace('<p>','').replace('</p>','')}` }}></p>
                    </div>
                    <div class="col-sm-12 col-lg-5  text-center">
                        
                </div>
                <div class="row col-xl-12 col-lg-12  col-sm-12  leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='general-acc-link'>
                        <DButton item={{name:guestCardData.general,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.general)} />
                    </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='individual-acc-link'>
                        <DButton item={{name:guestCardData.individual,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.individual)} />
                        
                    </div>
                    </div>
                </div>
                <div class="row col-xl-12 col-lg-12  col-sm-12 leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='minor-link'>
                        <DButton item={{name:guestCardData.investingforminors,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.investingforminors)} />
                    </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className='speciality-link' >
                        <DButton item={{name:guestCardData.specialityAccounts,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.specialityAccounts)} />
                    </div>
                    </div>
                </div>
            </div>

            </>
            )
        }else if(acctype == 'markets'){
            return(
                <>
                <div class="row guestbanner guestbanner2" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Desktop + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                        
                            <DButton item={{name:guestCardData.openMarketAccount, redirectClick:true}} onClick={this.onMarketAccountClick} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Mobile guest Markets section */}

                <div class="row guestbanner guestbanner2 mobguestbanner2" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-12 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                        
                            <DButton item={{name:guestCardData.openMarketAccount, redirectClick:true}} onClick={this.onMarketAccountClick} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Tab guest Markets section */}

                <div class="row guestbanner guestbanner2 tabguestbanner2" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.markets?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.markets?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                   
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                    <div class="col-sm-6 col-lg-4 leftbtnBox">
                    <div  style={{position:'relative'}} className=''>
                        <div className="btncorner" />
                        <div >
                        
                            <DButton item={{name:guestCardData.openMarketAccount, redirectClick:true}} onClick={this.onMarketAccountClick} ></DButton>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                </>
            )
        }else if(acctype == 'education'){
            return(
                <>
                <div class="row guestbanner guestbanner3" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Desktop + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className=''>
                            <DButton item={{name:guestCardData.open529Account,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.open529Account)} />
                        </div>
                        </div>
                </div>
                </div>

                {/* Mobile guest Education section */}

                <div class="row guestbanner guestbanner3 mobguestbanner3" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Mobile + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                        <div class="col-sm-12 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className=''>
                            <DButton item={{name:guestCardData.open529Account,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.open529Account)} />
                        </div>
                        </div>
                </div>
                </div>


                {/* Tab guest Education section */}

                <div class="row guestbanner guestbanner3 tabguestbanner3" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.education?.bgURL_Tab + ")" }}>
                <div class="col-sm-12 col-lg-7 chartMain leftContentbox">
                    <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.title.replace('<p>','').replace('</p>','')}` }}>
                    </h3>
                    <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}}  dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.education?.description.replace('<p>','').replace('</p>','')}` }}>
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5  text-center">
                    
                </div>
        
                <div class="row col-xl-12 col-lg-12 col-sm-12 leftButtonbar">
                        <div class="col-sm-6 col-lg-4 leftbtnBox">
                        <div  style={{position:'relative'}} className=''>
                            <DButton item={{name:guestCardData.open529Account,redirectClick:true}} onClick={() => this.redirectPageTo(guestCardData.open529Account)} />
                        </div>
                        </div>
                </div>
                </div>

                </>
            )
        }
    }



    onMarketAccountClick = () => {
        this.setState({redirectToMarketNAO:true})
    }

    render() {
        const { card, marstoneClick,dashbordContentData ,redirectToNAO, systemHealth,
            savedItemsForDashbord,} = this.state;
        const { accountTypes, dashboardDocumentData, loginState } = this.props;
        const { getCustomerProfile: customerProfile } = loginState || {};
        // const { vcmId } = customerProfile || {};
        const { s_ = null, c_ = 1} = customerProfile || {}; 
        const { Block, Paragraph, Imgsrc, Imgsrchyperguest,PowerText, MarstoneText, Sup, marstoneText, powerText, TM, externalWeb } = constant;
        const { resourcesTools, pageconsts, features, assignObj, footerData } = card;
        const { isLoading: dashboardDocumentDataLoading } = dashboardDocumentData || {}
        const isNewDashboardFeatureEnabled = 
            this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
            this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");

        const isHyperFeatureEnabled = this.isHyperFeatureAvailable()
        return (
            <div className="containerBg">
                <WSpinner loading={dashboardDocumentDataLoading} />
                {marstoneClick && <MSAuthRedirect />}
                <Container className="custom-pt-4 dashboard  GuestDashboard-mobile">  
                    <div className="row dasboardCard">
                        { !isNewDashboardFeatureEnabled &&    
                            <div className=" margin-top-open-account-guest col-12 col-lg-8 ">
                                    <WCardOverlay className="ac-holder-wcardoverlay">                               
                                        <div>
                                            <SideHeader
                                                title={pageconsts.guestPortfolioHeading}
                                                imgSrc={iconAllocation}
                                                width="19px"
                                                height="19px"
                                                id="guestAllocation"
                                            />
                                        </div>
                                        <div>
                                            <h3 style={Styles.subTitleHeading}>{pageconsts.guestPortfolioSubTitleHeading}</h3>
                                            <p data-test="guestDBInfoTextSub" className="guestDBInfoText" style={Styles.subtitle}>
                                                {pageconsts.guestPortfolioSubTitle}
                                            </p>
                                        </div>
                                        <Row className="acctypecards">
                                            {accountTypes &&
                                                accountTypes.length > 0 &&
                                                accountTypes.map(item => {
                                                    return (
                                                        <Col md={6} lg={6} key={item.key} className="guestSubCard">
                                                            <GuestCard
                                                                className={
                                                                    item.key === 'gen_inv_acct'
                                                                        ? 'rightArrow cardset'
                                                                        : 'rightArrow'
                                                                }
                                                                dataaccountid={item.key}
                                                                cardtitletext={item.value}
                                                                cardtext={item.description}
                                                                onClick={this.cardClickHandler}
                                                            />
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                        {/* {isReferenceDataError && isEmpty(accountTypes) && (
                                            <span className="errorDashboardStyle">{commonErrorMsg}</span>
                                        )} */}
                                    </WCardOverlay>
                            </div>
                        }
                        {/* tabs in guestview */}
                        { isNewDashboardFeatureEnabled &&   
                            <div className="col-12 col-md-12 col-lg-12 rwdDashboardWrap">
                            <WCardDashboardOverlay className="portfolioChart ac-holder-wcardoverlay">
                                <div className="tab-wrapper">
                                    <div className='container-fluid' >
                                        <div className='row m-0 topHeader'>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className='mainTitle'>
                                                    <SideHeader
                                                            title="My Portfolio"
                                                            imgSrc={iconAllocation}
                                                            width="20px"
                                                            height="20px"
                                                            id="myPortfolio"
                                                            className="mb-0 mb-lg-3"
                                                            noBorderBottom={true}
                                                    />
                                                </div>
                                                    
                                            </div>
                                        </div>
                                        <div className="row guesttabsection">
                                        <div className="col-sm-12 ">

                                            <Tab.Container activeKey={this.state.activeTab} onSelect={this.handleSwitchTabs}>
                                            <Row>
                                                <div className="col-xl-2 col-sm-12 tableftColumn tabmenusection">
                                                {this.showDashboardTabs()}
                                                
                                                </div>
                                                <div className="col-xl-10 col-sm-12 tabRightColumn GuestRightBox">
                                                <Tab.Content>

                                                    <Tab.Pane eventKey="overview">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-lg-7 chartMain leftContentbox">
                                                                <h3 className='leftTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.left?.title.replace('<p>','').replace('</p>','')}` }}>
                                                                </h3>
                                                                <p className='featureText mt-4' style={{'overflowWrap': 'anywhere'}} dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.left?.description.replace('<p>','').replace('</p>','')}` }}>
                                                                </p>
                                                                
                                                                <div class='bottomLinkSection'>
                                                                    <ul className='smallLeftboxText1' style={{cursor:"pointer"}}>
                                                                        <li onClick={()=>this.handleSwitchTabs("funds")} >Funds Accounts</li>
                                                                        <li onClick={()=>this.handleSwitchTabs("markets")}>Marketplace Accounts</li>
                                                                        <li onClick={()=>this.handleSwitchTabs("education")}>Education Accounts</li>
                                                                    </ul> 
                                                                </div>
                                                                
                                                            </div>

                                                            { (savedItemsForDashbord.cardLinks == undefined || savedItemsForDashbord?.cardLinks?.length == 0) &&
                                                            <>
                                                            <div class="col-sm-12 col-lg-5 rightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Desktop + ")" }} >
                                                                <div class='row'>
                                                                    <div class='col-sm-6 col-lg-12'>
                                                                    <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.right?.title.replace('<p>','').replace('</p>','')}` }}>
                                                                    </h4>
                                                                    <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.right?.description.replace('<p>','').replace('</p>','')}` }}>
                                                                    </p>
                                                                    </div>
                                                                    <div className=' col-sm-6 col-lg-12'>
                                                                        <div className='launchbtn' style={{position:'relative'}} >
                                                                        <div className="btncorner" />
                                                                            <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                                                            {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                                                            </a> 
                                                                       
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div> 


                                                            <div class="col-sm-12 col-lg-5 rightBox tabrightBox text-center" style={{ backgroundImage: "url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.overview?.right?.bgURL_Tab + ")" }} >
                                                                <div class='row'>
                                                                    <div class='col-sm-6 col-lg-12'>
                                                                    <h4 className='rightTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.right?.title.replace('<p>','').replace('</p>','')}` }}>
                                                                    </h4>
                                                                    <p className="featureText righttextnew text-white mt-3" style={{ 'wordBreak' : 'break-all'}} dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.overview?.right?.description.replace('<p>','').replace('</p>','')}` }}>
                                                                    </p>
                                                                    </div>
                                                                    <div className=' col-sm-6 col-lg-12'>
                                                                        <div className='launchbtn' style={{position:'relative'}} >
                                                                        <div className="btncorner" />
                                                                            <a className="placeOrderBtnNew" style={{"display": "flex","justify-content": "center", "alignItems": "center"}} href={dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonLink}>
                                                                            {dashbordContentData?.dashboard_marketing_content?.overview?.right?.buttonText}
                                                                            </a> 
                                                                       
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            </>
                                                            }

                                                                {(Array.isArray(savedItemsForDashbord) == false && savedItemsForDashbord != null && savedItemsForDashbord?.cardLinks.length > 0) ?
                                                                    (
                                                                    <>
                                                                    <div class="col-sm-12 col-lg-5 rightBox savedrightBox">
                                                                     <h4 className='rightTitle'>
                                                                     {` Your Saved Applications    (${savedItemsForDashbord.cardLinks?.length})`}
                                                                     </h4>
                                                                     <div style={{width: '100%',float: 'left'}}>
                                                                        {savedItemsForDashbord.cardLinks.map((item,index) =>(
                                                                            // <div  style={{width:'47%', float:'left', marginRight:'2.5%'}}>
                                                                            <div className='col-lg-12 col-md-6 p-0' style={{float:'left'}}>
                                                                            <p class="featureText savedtext text-white mt-3">
                                                                            {/* <a href="#" style={{fontSize:'12px', fontWeight:'700',textDecoration:'underline'}}>
                                                                                {item.linktext} 
                                                                            </a> */}

                                                                               {(index < 3) && 
                                                                                <>
                                                                            <SavedLink style={Styles.link}
                                                                                data-test="test-link"
                                                                                to={assignObj({
                                                                                    pathname: item.link,
                                                                                    state: {
                                                                                    uuid: item.uuid
                                                                                    },
                                                                                })}
                                                                                onClick={this.handleLinksClick}
                                                                                tabIndex="0"
                                                                            >
                                                                                {item.linktextValue}
                                                                            </SavedLink><br/>
                                                                            <span className='guestdismisstext' style={{cursor:"pointer"}} onClick={()=>this.removeSavedListItem(item.uuid,index,item.accountText)}>dismiss</span>
                                                                            </>}
                                                            
                                                                            </p>
                                                                            </div>
                                                                        ))}
                                                                        </div>
                                                                         </div>
                                                                    </> 
                                                                    ):('')
                                                                } 
                                                            
                                                                
                                                           
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="funds">
                                                    { systemHealth.fis != "Up" ? this.unavailableAPIErrorResponse() :
                                                        this.selectAccountType("funds")
                                                    }
                                                        
                                                            
                                                       
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="markets">
                                                    { systemHealth.pershing != "Up" ? this.unavailableAPIErrorResponse() :
                                                        this.selectAccountType("markets")
                                                       
                                                    }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="education">
                                                    { systemHealth.ascensus != "Up" ? this.unavailableAPIErrorResponse() :
                                                        this.selectAccountType("education")
                                                    }
                                                    </Tab.Pane>

                                                    
                                                </Tab.Content>
                                                </div>
                                            </Row>
                                            </Tab.Container>
                                        </div>
                                        </div>
                                    </div>
                                </div> 
                            </WCardDashboardOverlay> 
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
                                <div class="row mt-4">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                        <div className='blogbannerBox blog1banner' style={{ backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[0]?.bgURL_Desktop + ")" }}>
                                            {/* <h4 className='blogTitle'>How to invest in a volatile market</h4> */}
                                            <a href={dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[0]?.redirectLink} target={'_blank'} rel="noopener noreferrer" > 
                                             <h4 className='blogTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[0]?.title.replace('<p>','').replace('</p>','')}` }}></h4>
                                             </a>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                        <div className='blogbannerBox blog2banner' style={{ backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[1]?.bgURL_Desktop + ")" }}>
                                            {/* <h4 className='blogTitle'>Do bear markets create opportunity?</h4> */}
                                            <a href={dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[1]?.redirectLink} target={'_blank'} rel="noopener noreferrer" >
                                                 <h4 className='blogTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[1]?.title.replace('<p>','').replace('</p>','')}`}}></h4>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                        <div className='blogbannerBox blog3banner' style={{ backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(" +  envConfig.ENV_URL + dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[2]?.bgURL_Desktop + ")" }}>
                                            {/* <h4 className='blogTitle'>The benefit of automating your<br/> investment plan</h4> */}
                                            <a href={dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[2]?.redirectLink} target={'_blank'} rel="noopener noreferrer" >
                                            <h4 className='blogTitle' dangerouslySetInnerHTML={{ __html: `${dashbordContentData?.dashboard_marketing_content?.marketing_photos_bottom[2]?.title.replace('<p>','').replace('</p>','')}`}}></h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>  
                        }
                        
                   

                        { (c_ === 1 || c_ === 0) &&
                        <div className={`col-12 col-md-6 col-lg-4 ${isNewDashboardFeatureEnabled ? 'mt-5':''} margin-top-saved-items-guest`}>
                         { 
                          (s_=== 0 || s_=== null) &&
                                <>
                                <WCardOverlay>
                                <div>
                                    <SideHeader
                                        title={pageconsts.messageCenter}
                                        imgSrc={mail}
                                        width="20px"
                                        height="21px"
                                        id="offers"
                                        headStyles={assignObj({ marginBottom: 0 })}
                                    />
                                    <div
                                        aria-labelledby="activate your message center"
                                        className="offerZone"
                                    >
                                        <div className='activate-smc'>
                                            <StyledP className="featureText mt-3">{pageconsts.callVictoryTxt}</StyledP>
                                            <StyledP>{pageconsts.twofactorAuthenticationTxt}</StyledP>
                                            <StyledP1>{pageconsts.forUSCustomersTxt}</StyledP1>
                                            <StyledP2>{pageconsts.forUSCustomersPhoneTxt}</StyledP2>
                                            <a href={`${envConfig.ENV_URL}/contacttollfree`} rel="noopener noreferrer" target="_self" data-test="TollFreeNumbers">
                                               {pageconsts.tollfreeTxt}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </WCardOverlay>
                            </>
                         }
                         { (s_=== 1) && <MessageCenterWidget />}
                        </div>     
                        } 
                        
                        {!isNewDashboardFeatureEnabled &&
                        <div className={`col-12 col-md-6 col-lg-4 ${(c_=== 1||c_===0) ? 'mt-5':''} guestViewDocCard margin-top-saved-items-guest`}>
                            <DocumentCenter />
                        </div>
                        }
                        <div className="col-12 col-md-6 col-lg-4 mt-5  margin-top-saved-items-guest">
                            <WCardOverlay>
                                <div className="dashboard">
                                    <SideHeader
                                        title={pageconsts.resourcestools}
                                        imgSrc={IconFinancialResources}
                                        width="18px"
                                        height="18px"
                                        id="resourcesAndTools"
                                        // headStyles={assignObj({ marginBottom: 9 })}
                                    />
                                    <div
                                        // role="region"
                                        aria-labelledby="resourcesAndTools"
                                        className="dataScroll"
                                    >
                                        <div
                                            className="pt-3 pr-3 pb-2 pl-3 portfolioPlannerWrapper"
                                            style={assignObj({ background: '#F5F5F5 0% 0% no-repeat padding-box' })}
                                            data-test="wcombo"
                                        >
                                            <Row>
                                                
                                                <Col md={9} lg={9} className={`${isHyperFeatureEnabled ? 'tools-resource-text': 'pl-2 tools-resource-text'}`}>
                                                    <Block
                                                        style={assignObj({ font: '800 15px/22px yorkten-slab-normal' })}
                                                        onClick={ isHyperFeatureEnabled ? this.portfolioPlanner: this.marstone}
                                                    >
                                                        <span className="anchorSpan">
                                                            <a
                                                                href="about:blank"
                                                                className="inlineLInk linkTestClr"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={assignObj(e => e.preventDefault())}
                                                            >
                                                                <span>
                                                                    {pageconsts.headerLine}
                                                                    <span className="sr-only" data-test="externalWeb">
                                                                        {externalWeb}
                                                                    </span>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="icon-mutual"
                                                                        style={assignObj({ display: 'inline-table' })}
                                                                    >
                                                                        <i className="icon-newpage" />
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </span>
                                                    </Block>
                                                    <div>
                                                        <Paragraph>{pageconsts.portPlanner}</Paragraph>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {isHyperFeatureEnabled ? 
                                                <></> :
                                            <Row
                                                style={assignObj({
                                                    background: 'rgb(73, 73, 74)',
                                                    height: '20px',
                                                })}
                                            >
                                                <div className="ml-3" style={assignObj({ color: '#fff' })}>
                                                    <img src={poweredByMarstone} alt="Powered by Marstone" /> 
                                                </div>
                                            </Row>
                                            }
                                        </div>
                                        <div className="offerZone resourceZone">
                                            {resourcesTools &&
                                                resourcesTools.map(item => {
                                                    return (
                                                        <Offers
                                                            item={item}
                                                            key={item.id}
                                                            widgetName={pageconsts.resourcestools}
                                                            borderStyle={assignObj({
                                                                border: 'none',
                                                                padding: '20px 0px 0px 0px',
                                                            })}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </WCardOverlay>
                        </div>
                        {!isNewDashboardFeatureEnabled &&
                        <div className="col-12 col-lg-4 mt-5 margin-top-saved-items-guest">
                            <MySavedItemsWidget />
                        </div>
                        }
                        <div className="col-12 col-md-6 col-lg-4 mt-5  margin-top-saved-items-guest">
                            <ContactCenterWidget footerData={footerData} />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-5  margin-top-saved-items-guest">
                            <WCardOverlay>
                                <div>
                                    <SideHeader
                                        title={pageconsts.features}
                                        imgSrc={iconOffers}
                                        width="20px"
                                        height="21px"
                                        id="offers"
                                        headStyles={assignObj({ marginBottom: 0 })}
                                    />
                                    <div
                                        aria-labelledby="offers"
                                        className="offerZone"
                                    >
                                        {features &&
                                            features.map(item => {
                                                return (
                                                    <Offers
                                                        item={item}
                                                        key={item.id}
                                                        widgetName={pageconsts.features}
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </WCardOverlay>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

GuestViewDashboard.propTypes = {
    history: PropTypes.instanceOf(Object),
    accountTypes: PropTypes.instanceOf(Object),
  //  localStateData: PropTypes.instanceOf(Object),
    analyzeClickEvent: PropTypes.func,
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    clearLocalStateForAccOpening: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    setJWTToken: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    dashboardDocumentData:PropTypes.instanceOf(Object),
};

GuestViewDashboard.defaultProps = {
    history: {},
    accountTypes: {},
   // localStateData: {},
    analyzeClickEvent: () => { },
    manageLocalState: () => { },
    clearLocalStateForNAO: () => { },
    clearLocalStateForAccOpening: () => { },
    dashboardDocumentData:{},
    loginState:{},
    getCustomerProfile:{},
    setJWTToken:()=>{},
    localStateData: PropTypes.instanceOf(Object),
};
export default GuestViewDashboard;
