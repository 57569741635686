 const EMAIL_INFO = 'Email Information';
 const ACTION_TEXT = 'Manage';
 const ACTION_LABEL_EMAIL = 'Manage email information';
 const PRIMARY_EMAIL = 'Primary Email';
 const SECONDARY_EMAIL = 'Secondary Email';
 
 const EMAIL_SAVE_SUCCESS = "Email Information Saved successfully";
 const EMAIL_DELETE_SUCCESS = "Email Information Deleted successfully";
 const BASICINFORMATION_URL = '/basicinformation';
 const BASICINFORMATION = 'Basic Information';
 const BREADCRUM_MANAGE_EMAIL = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_manageemailinfo_breadcrumb_profile'        
    }, 
    { 
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_manageemailinfo_breadcrumb_basicinfo'
    }
];
 const PAGE_NAME_MANAGE_EMAIL = 'Manage Email Information';
 const INSTRUCTION_LINE1 = `You must have at least once email address on file.`;
 const INSTRUCTION_LINE2 = `When You delete an email address, you'll no longer receive notifications or alert to the email.`;
 const SECONDARY_EMAIL_NOTE = `Note: You are making the changes for Secondary Email`;
 const PRIMARY_EMAIL_NOTE = `Note: You are making the changes for Primary Email`;
 
 const ACTION_TEXT_ADD_EMAIL = 'Add';
 const ACTION_TEXT_RESEND_SECURITY_CODE = 'Resend Security Code';
 const ACTION_LABEL_EMAIL_ADD = 'Add email';
 const EMAIL_ID = 'Email Id';
 
 const BREADCRUM_EDIT_EMAIL = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_editemailinfo_breadcrumb_profile'
    }, 
    {
        url: BASICINFORMATION_URL,
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_editemailinfo_breadcrumb_basicinfo'
    }, 
    { 
        url: '/emailInfo', 
        name: PAGE_NAME_MANAGE_EMAIL,
        dataAnalyticId:'cms_basicinformation_editemailinfo_breadcrumb_manageemailinfo'

    }
];
 const BREADCRUM_ADD_EMAIL = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_addemailinfo_breadcrumb_profile'
    }, 
    {
        url: BASICINFORMATION_URL,
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_addemailinfo_breadcrumb_basicinfo'
    }, 
    { 
        url: '/emailInfo', 
        name: PAGE_NAME_MANAGE_EMAIL,
        dataAnalyticId:'cms_basicinformation_addemailinfo_breadcrumb_manageemailinfo'

    }
];
 const PAGE_NAME_ADD_EMAIL = 'Add Email Information';
 const PAGE_NAME_EDIT_EMAIL = 'Edit Email Information';
 const NOF = 'None on file'; 
 const SECURITY_CODE = 'Enter Security Code';
 const ENTER_SECURITY_CODE = 'You will be receiving an email from our Security Partner, GIACT, which contains a security code you will need to continue.  If you do not receive the email, verify the email you have entered is correct.';
 const SAVE = 'Save';
 const VERIFY_EMAIL = 'Verify Email';
 const RESEND_CODE = 'Resend Security Code';
 const RELATIONSHIP_BREADCRUMB_VALUE = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_profile'
    },
    {
        url: BASICINFORMATION_URL,
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_basicinfo'
    },
    {
        url: '/ManageRelations',
        name: 'Manage Relationship',
        dataAnalyticId:'cms_basicinformation_breadcrumb_managerelationship'
    },
];

const RELATIONSHIP_ADD_EMAIL_BREADCRUMB_VALUE = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_profile'
    },
    {
        url: BASICINFORMATION_URL,
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_basicinfo'
    },
    {
        url: '/ManageRelations',
        name: 'Manage Relationship',
        dataAnalyticId:'cms_basicinformation_breadcrumb_managerelationship'
    },
    {
        url: '/relationshipemailInfo',
        name: 'Relationship Email Info',
        dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_relationshipemailInfo'
    }
];

const ADD_PRIMARY_EMAIL_TEXT = 'Add Primary Email';

const ADD_SECONDARY_EMAIL_TEXT = 'Add Secondary Email';
const AX_EDITEMAILID_TXT = "Edit email id";
const CANCEL = "Cancel";

const EMAIL_ERROR_EMPTY = "Please enter Email Address";
const EMAIL_ERROR_INVALID = "Please enter valid Email Address";

const OTP_ERROR_EMPTY = "Please enter otp";

const CANCEL_CONFIRMATION_MODAL_TITLE = "Please Confirm";
const CANCEL_CONFIRMATION_MODAL_PRIMARY_BTN = "Go Back";
const CANCEL_CONFIRMATION_MODAL_SECONDARY_BTN = "Continue";
const CANCEL_CONFIRMATION_MODAL_TEXT = "Are you you want to cancel this changes.";
 
 export default {
     OTP_ERROR_EMPTY,
     EMAIL_ERROR_EMPTY,
     EMAIL_ERROR_INVALID,
     ADD_PRIMARY_EMAIL_TEXT,
     ADD_SECONDARY_EMAIL_TEXT,
     EMAIL_INFO,
     ACTION_TEXT,
     ACTION_LABEL_EMAIL,
     PRIMARY_EMAIL,
     EMAIL_SAVE_SUCCESS,
     BREADCRUM_MANAGE_EMAIL,
     PAGE_NAME_MANAGE_EMAIL,
     INSTRUCTION_LINE1,
     INSTRUCTION_LINE2,
     ACTION_TEXT_ADD_EMAIL,
     ACTION_LABEL_EMAIL_ADD,
     BREADCRUM_EDIT_EMAIL,
     BREADCRUM_ADD_EMAIL,
     PAGE_NAME_ADD_EMAIL,
     PAGE_NAME_EDIT_EMAIL,
     NOF,
     SECONDARY_EMAIL,
     EMAIL_ID,
     EMAIL_DELETE_SUCCESS,
     SECURITY_CODE,
     ENTER_SECURITY_CODE,
     SAVE,
     VERIFY_EMAIL,
     RESEND_CODE,
     RELATIONSHIP_BREADCRUMB_VALUE,
     RELATIONSHIP_ADD_EMAIL_BREADCRUMB_VALUE,
     ACTION_TEXT_RESEND_SECURITY_CODE,
     AX_EDITEMAILID_TXT,
     PRIMARY_EMAIL_NOTE,
     SECONDARY_EMAIL_NOTE,
	 CANCEL,
	 CANCEL_CONFIRMATION_MODAL_TITLE,
     CANCEL_CONFIRMATION_MODAL_PRIMARY_BTN,
     CANCEL_CONFIRMATION_MODAL_SECONDARY_BTN,
     CANCEL_CONFIRMATION_MODAL_TEXT,
 }