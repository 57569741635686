// const phoneLength = 10;
const Rules = {
    nickName: { alphaNumeric: true },
    SSN: {
        length: 9,
        isNumeric: true,
        required: true,
        requiredMsg: 'Please enter your Social Security Number',
        lengthMsg: 'SSN should be 9 digits',
    },
    memberSSN: {
        length: 4,
        isNumeric: true,
        required: true,
        requiredMsg: 'Please enter your Social Security Number',
        lengthMsg: 'SSN should be 9 digits',
    },
    ssnOne: { required: true, length: 3 },
    ssnTwo: { required: true, length: 2 },
    ssnThree: { required: true, length: 4 },
    beneficiarySSN: { required: true, requiredMsg: 'SSN is required for child beneficiary' },
    // ssnOne:{required:true,requiredMsg:'Please enter area number of your Social Security Number',length: 3},
    // ssnTwo:{required:true,requiredMsg:'Please enter group number of your Social Security Number',length: 2},
    // ssnThree:{required:true,requiredMsg:'Please enter serial number of your Social Security Number',length: 4},
    beneAccountType: { required: true, requiredMsg: 'Please enter an account type.' },
    beneUTMAState: { required: true, requiredMsg: 'Please select UGMA/UTMA state.' },
    firstName: { required: true, requiredMsg: 'Please enter first name.' },
    residentCardNumber:{required: true, requiredMsg: 'Please enter resident card number.'},
    ownerResidentExpiry:{required: true, requiredMsg: 'Please enter expiration date.'},
    isAdd: { required: true, requiredMsg: 'Please select value.' },
    name: { required: true, requiredMsg: 'Please enter name.' },
    nameIndividual: {
        required: true,
        requiredMsg: 'Please enter the first and last name.',
        pattern: /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/,
        patternMessage: 'Please enter the first and last name.',
    },
    middleInitial: { maxLength: 40 },
    middleName: { required: false, maxLength: 40 },
    lastName: { required: true, requiredMsg: 'Please enter last name.' },
    gender: { required: true, requiredMsg: 'Please select gender.' },
    citizenship: { required: true, requiredMsg: 'Please select citizenship.' },
    sameAddress: { required: true, requiredMsg: 'Please select if physical address is same as mailing address.' },
    // phoneNumber1:{"length": phoneLength, "isNumeric": true},
    phoneType: { required: true },
    // contactTime:{required:true},
    city: { required: true },
    lineTwo: { required: true },
    // state:{required:true},
    lineOne: { maxLength: 50 },
    address: { required: true, requiredMsg: 'Please enter address line 1.' },
    lineOnePhysical: { noPoBox: true, maxLength: 50  },
    lineTwoPhysical: { required: true, noPoBox: true, maxLength: 50  },
    zipCode: { required: true, minLength: 5, isValidZip: true, requiredMsg: 'zip code is required.' },
    emailAdd: {
        isEmail: true,
        required: true,
        maxLength: 50,
        requiredMsg: 'Primary email address is mandatory. It cannot be left blank.',
    },
    benEmailAdd: { isEmail: true, maxLength: 30 },
    countryCode: { required: true, minLength: 2, maxLength: 4, requiredMsg: 'Please enter a valid country code.' },
    email: { isEmail: true, maxLength: 50 },
    foreignPostalCode: { required: true, maxLength: 10 },
    seniorPoliticalFigure: { required: true },
    currServingUSM: { required: true, requiredMsg: 'Please select if you are serving or not in military.' },
    mailTypeRadio: { required: true },
    isSeniorForeignPoliticalFigure: { required: true },
    seniorPoliticalFigureName: { required: true },
    empClassInfo: { isChar: true, required: true, requiredMsg: 'Please enter employee class information.' },
    industry: { required: true, requiredMsg: 'Please select an industry.' },
    industryDescription: { required: true, requiredMsg: 'Please enter industry description.' },
    occupation: { isChar: true },
    employersName: { isChar: false, required: true, requiredMsg: "Please enter employer's Name." },
    commissionCourse: { isChar: true },
    militaryOthers: { required: true, requiredMsg: 'Please enter military status.' },
    countryOfCitizenship: { required: true, requiredMsg: 'Please select a country of citizenship.' },
    country: { required: true },
    branchOfServiceOthers: { required: true, requiredMsg: 'Please enter branch of service.' },
    empStatus: { required: true, requiredMsg: 'Please select employment status.' },
    relationship: { required: true, requiredMsg: 'Please select relationship to account holder.' },
    relationshipToCustodian: { required: true, requiredMsg: 'Please select Relationship to custodian.' },
    relationToCustodian: { required: true, requiredMsg: 'Please select Relationship to custodian.' },
    dateofBirth: { required: true, requiredMsg: 'Please enter date of birth.' }, // dobDate:true, requiredMsg:'Please enter Date of Birth'},
    dateOfBirth: { required: true, requiredMsg: 'Please enter date of birth.' }, // dobDate:true, requiredMsg:'Please enter Date of Birth'},
    ownerDateOfBirth: { required: true, dobDate: true, requiredMsg: 'Please enter date of birth.' },
    jointOwnerDateOfBirth: { required: true, dobDate: true, requiredMsg: 'Please enter date of birth.' },
    dob: { required: true, requiredMsg: 'Please enter beneficiary date of birth.' },
    beneficiaryDOB: {
        required: true,
        requiredMsg: "Please select beneficiary's date of birth.",
    },
    successorDOB: {
        required: true,
        requiredMsg: 'Please enter date of birth.',
    },
    maritalStatus: { required: true, requiredMsg: 'Please select marital status.' },
    maritalLocal: { required: true, requiredMsg: 'Please select marital status.' },
    beneficiaryType: { required: true, requiredMsg: 'Please select a value.' },
    relationshipType: { required: true, requiredMsg: 'Please select a value.' },
    relationToAccHolder: { required: true, requiredMsg: 'Please select a value.' },
    distributionPercentage: {
        required: true,
        requiredMsg: 'Distribution % cannot be zero. Please enter a value.',
        percentRule: true,
    },
    termsAndConditions: { required: true },
    countryCodePhone: { required: true },
    phoneNumber: { minLength: 10, maxLength: 15, isNumeric: true },
    optionalLineOne: { alphaNumeric: true },
    optionalLineTwo: { alphaNumeric: true },
    optionalZipCode: { minLength: 5 },
    optionalCity: { isChar: true },
    optionalState: { isChar: true },
    primarySourceofIncome: { required: true, requiredMsg: 'Please select primary source of income.' },
    beneName: {
        P: 'Please enter the name of the primary beneficiary.',
        S: 'Please enter the name of the contingent beneficiary.',
    },

    taxBracket :[
        "10%",
        "12%",
        "22%",
        "24%",
        "32%",
        "35%",
        "37%"]
};

export default Rules;
