import { addSystematicWithdrawalActions, addAutomaticInvestmentActions, analytics ,bankAccountAction,memberDashboardActions} from 'shared/Actions';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { InvestmentOrWithdrawalPlans } from './Component';
import { mockedAIP, mockedSWP } from './mock';
import { withRouter } from 'react-router';

export const getPADDetailsMock = () => dispatch => {
    dispatch({
        type: 'AUTO_INVEST_GET_PAD_DETAILS_SUCCESS',
        response: mockedAIP.result
      });
   dispatch(analytics.linkResponseSuccess());
};

export const getSWPDetailsMock = () => dispatch => {
    dispatch({
        type: 'SYS_WITHDRAWAL_GET_SWP_DETAILS_SUCCESS',
        response: mockedSWP.result
      });
   dispatch(analytics.linkResponseSuccess());
};

export const mapStateToProps = (state) => {
    const storeSWPData = state.systematicWithdrawalData;
    const storeAIPData = state.automaticInvestmentData;

    return {
        bankAccountReducerData: get(state, 'bankAccountReducerData', []),
        automaticInvestmentData: storeAIPData || {},
        withdrawalPlanList:  storeSWPData ? get(storeSWPData, 'existingPlanList', []) : [],
        swpIsLoading: storeSWPData ? get(storeSWPData, 'isLoading', false) : false,
        swpIsDelete: storeSWPData ? get(storeSWPData, 'isDelete', false) : false,
        swpIsError: storeSWPData ? get(storeSWPData, 'isError', false) : false,
        swpMsgError: storeSWPData ? get(storeSWPData, 'error', null) : null,
        memberDashboardData: state.memberDashboardData,
        memberNewDashboardReducer : state.memberNewDashboardReducer,
    };
};

export const mapDispatchToProps = {
    getBankAccountInfo: bankAccountAction.getBankAccounts,
    getSWPDetails: addSystematicWithdrawalActions.getSWPDetails, //  () => getSWPDetailsMock() , //
    getPADDetails: addAutomaticInvestmentActions.getPADDetails, // () => getPADDetailsMock() , //
    clearAIPPlanList: addAutomaticInvestmentActions.clearReduxKeyData,
    deleteAutoInvestPlan: addAutomaticInvestmentActions.deleteAutoInvestPlan,
    deleteSystematicWidthdrawalPlan: addSystematicWithdrawalActions.deleteSWP,
    resetAIPDeleteFlag: addAutomaticInvestmentActions.resetDeleteFlag,
    clearSWPPlanList: addSystematicWithdrawalActions.clearSWPPlanList,
    analyzeClickEvent: analytics.analyzeClickEvent,
    ...memberDashboardActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestmentOrWithdrawalPlans));