import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    updateSelectedMessages,
    deleteSelectedMessages,
    setReadUnreadToMessages,
} from 'shared/Actions/MessageCenterActions';
import WInputCheckBox from '../../../common/WInputCheckBox/WInputCheckBox';
import IconMessageTag from '../../../assets/Icon_MessageTag.svg';
import IconUrgentMessageTag from '../../../assets/icon-Alert-Red.svg';
import IconReply from '../../../assets/icon_resend.svg';
import * as MCConstants from './constants';
import { formatMessageCenterDate, formatTimeAMPM } from '../../../utils/utils';
import IconAttachment from '../../../assets/Icon_Attachment.svg';
import { DownloadFile } from '../../../common';

const MessagesContainerDiv = styled.div`
    min-height: 80px;
    padding: 20px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eeeeee;
    font-family: benton-sans, sans-serif;
    opacity: 1;
    :first-child {
        margin-top: 20px;
    }
`;

const MessageContentDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
`;

const CheckboxDiv = styled.div`
    width: 2.5%;
    margin: 0px;
    padding: 0px;
    .checkBoxWrapper input[type='checkbox']:checked:after {
        width: 7px;
        height: 14px;
        top: 3px;
        left: 8px;
    }
    @media only screen and (max-width: 768px) {
        width: 4.5%;
    }
    @media only screen and (max-width: 576px) {
        width: 7.5%;
    }
`;

const ReadSubjectDiv = styled.div`
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

const UnReadSubjectDiv = styled.div`
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const MessageBodyDiv = styled.div`
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #49494a;
    opacity: 1;
    padding-top: 5px;
`;

const MessageReplyDiv = styled.div`
    margin-top: 10px;
    cursor: pointer;
    width: fit-content;
`;

const ReplyImg = styled.img`
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`;

const AccountDiv = styled.div`
    border: 1px solid #d2d2d2;
    width: fit-content;
    padding: 0px 5px;
    opacity: 1;
    margin: 5px 5px 0px 0px;
    font: 400 12px/22px benton-sans;
`;

const AccountGroupDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;
`;

const MessageCategoryDiv = styled.div`
    width: 12%;
    height: 35px;
    opacity: 1;
    color: #56565a;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const MessageTagImageDiv = styled.img`
    width: 14px;
    height: 14px;
    margin: 5px;
`;

const AttachmentImg = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 15px;
`;

const AttachmentsList = styled.ul`
    font-weight: 500;
    font-size: 14px;
    font-family: benton-sans, sans-serif;
    letter-spacing: 0px;
    opacity: 1;
    padding-left: 15px;
`;

const TimeContentDiv = styled.div`
    padding: 0px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const DateContentDiv = styled.div`
    padding: 0px;
`;

const checkInputStyle = {
    width: '25px',
    height: '25px',
    position: 'relative',
    top: '5px',
};

const checkBoxWrapperStyle = {
    marginRight: '0px',
    width: '0%',
    padding: '0px',
    margin: '0px',
};

const labelStyle = {
    color: 'rgb(0,74,152)',
    display: 'inline-block',
    font: '600 16px/10px benton-sans',
};

const openMessageStyle = {
    backgroundColor: '#F9FBFC',
    whiteSpace: 'pre-wrap',
};

const closeMessageStyle = {
    backgroundColor: 'transparent',
};

/**
 * MessageComponent function is responsible for displaying each message.
 *
 * @param {Object} props
 */
function MessageComponent(props) {
    // Get selected messages from state
    const selectedMessages = useSelector(state => state.messageCenterData.selectedMessages);
    const messageType = useSelector(state => state.messageCenterData.messageType);
    const selectAll = useSelector(state => state.messageCenterData.selectAll);
    const isLoading = useSelector(state => state.messageCenterData.isLoading);

    const timeMarginDeletedItems768 = messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL ? '14%' : '0%';
    const timeMarginDeletedItems576 = messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL ? '17%' : '0%';

    const s = {};
    s.downloadFile = {
        color: '#56565a',
        textDecoration: 'underline',
        fontWeight: 500,
        fontSize: 14,
        fontFamily: 'benton-sans, sans-serif',
    };

    const keys = (value, index) => {
        return `${value}-${index}`;
    };

    const TimeDiv = styled.div`
        width: 10%;
        font-size: 12px;
        position: relative;
        color: #49494a;
        top: -7px;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 5px;
        span {
            font-size: 12px;
        }
        @media only screen and (max-width: 768px) {
            margin: 15px 0 0 ${timeMarginDeletedItems768};
        }
        @media only screen and (max-width: 576px) {
            margin: 15px 0 0 ${timeMarginDeletedItems576};
            width: 20%;
        }
    `;

    const messageDivDeletedItems576 = messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL ? '83%' : '100%';
    const MessageDiv = styled.div`
        width: 48%;
        text-align: left;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            width: 86%;
        }
        @media only screen and (max-width: 576px) {
            width: ${messageDivDeletedItems576};
        }
    `;

    const history = useHistory();

    // Destructuring props
    const { message, toggleMessage, handleExpandMessage } = props;
    let { subject } = message;
    const { canReply } = message;
    let messageBody = message.messageSecureText;
    const isRead = messageType === MCConstants.MESSAGE_CENTER_INBOX_VAL ? message.isRead : true;
    const account = message.accounts || null;
    const visibilityValue = !isRead ? 'visible' : 'hidden';
    const messageBackground = toggleMessage ? openMessageStyle : closeMessageStyle;

    const BulletDiv = styled.div`
        background-color: #004a98;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        opacity: 1;
        margin: 0 10px;
        visibility: ${visibilityValue};
    `;

    // EPOCH timestamp formatting
    const { skCreatedAt } = message;
    const timestamp = skCreatedAt.split('-')[1];
    const DATE = formatMessageCenterDate(timestamp);
    const TIME = formatTimeAMPM(timestamp);

    // State variable for message selection
    const [selected, setSelected] = useState();

    // Dispatch actions
    const dispatch = useDispatch();

    // Set the selected value of selectAll initially from state
    useEffect(() => {
        setSelected(selectAll);
    }, [selectAll]);

    // When selected messages is updated
    useEffect(() => {
        if (selectedMessages.length === 0) {
            setSelected(false);
        }
    }, [selectedMessages]);

    // On Message toggle select
    function onMessageSelect() {
        setSelected(!selected);
        if (!selected) {
            dispatch(updateSelectedMessages(selectedMessages, message));
        } else {
            dispatch(deleteSelectedMessages(selectedMessages, message));
        }
    }

    async function handleMessageToggle() {
        // handleExpandMessage(message.skCreatedAt, toggleMessage);
        if (!isRead && !toggleMessage) {
            await dispatch(
                setReadUnreadToMessages({
                    data: { messageIds: [message.skCreatedAt], isRead: true },
                    token: localStorage.getItem('token'),
                }),
            );
        }
        if (!isLoading) {
            handleExpandMessage(message.skCreatedAt, toggleMessage);
        }
    }

    function replyMessage() {
        if (messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL) {
            history.push({
                pathname: '/messageCenter/createSecureMessages',
                state: {
                    replyMessage: message,
                },
            });
        }
    }

    let accountMod = account ? account.slice(0, account.length) : null;
    if (!toggleMessage) {
        if (subject && subject.length > 50) {
            subject = message.subject.substr(0, 50);
            subject += MCConstants.MESSAGE_CENTER_ELLIPSIS;
        }
        if (messageBody && messageBody.length > 60) {
            messageBody = message.messageSecureText.substr(0, 60);
            messageBody += MCConstants.MESSAGE_CENTER_ELLIPSIS;
        }
        if (account.length > 3) {
            accountMod = account.slice(0, 3);
        }
    }

    return (
        // <MessagesContainerDiv data-test="messagesContainer" style={alignMessageForDelete}>
        <MessagesContainerDiv data-test="messagesContainer" style={messageBackground}>
            <MessageContentDiv className="container">
                {messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL && (
                    <>
                        <CheckboxDiv data-test="checkboxDiv">
                            <WInputCheckBox
                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                checkInputStyle={checkInputStyle}
                                checked={selected}
                                value={message.skCreatedAt}
                                onChange={onMessageSelect}
                                checkboxstyle={MCConstants.MESSAGE_CENTER_CHECKBOXSTYLE}
                                labelstyle={labelStyle}
                                label=""
                            />
                        </CheckboxDiv>
                        <BulletDiv />
                    </>
                )}

                <MessageDiv data-test="messages" onClick={handleMessageToggle}>
                    {!isRead ? (
                        <ReadSubjectDiv data-test="readSubject">
                            {message.numberOfAttachments > 0 && <AttachmentImg src={IconAttachment} alt="attachment" />}
                            {subject}
                        </ReadSubjectDiv>
                    ) : (
                        <UnReadSubjectDiv data-test="unReadSubject">
                            {message.numberOfAttachments > 0 && <AttachmentImg src={IconAttachment} alt="attachment" />}
                            {subject}
                        </UnReadSubjectDiv>
                    )}
                    <AccountGroupDiv>
                        {accountMod.length > 0 &&
                            accountMod.map(accountVal => (
                                <AccountDiv key={accountVal + Math.random()} data-test="account">
                                    {accountVal}
                                </AccountDiv>
                            ))}
                        {account.length > accountMod.length && <div>{MCConstants.MESSAGE_CENTER_ELLIPSIS}</div>}
                    </AccountGroupDiv>
                    <MessageBodyDiv data-test="messageBody">{messageBody}</MessageBodyDiv>
                    {messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL && canReply && (
                        <MessageReplyDiv onClick={replyMessage}>
                            <ReplyImg src={IconReply} alt="Reply Message" />
                        </MessageReplyDiv>
                    )}

                    {toggleMessage && message.numberOfAttachments > 0 && (
                        <>
                            <hr />
                            <AttachmentsList>
                                {message.attachments.map((attachment, index) => (
                                    <li>
                                        <DownloadFile
                                            key={keys('Download-file-', index)}
                                            saveAs={attachment.name}
                                            downloadUrl={attachment.link}
                                            downloadUrlRequiresAuth={true}
                                            text={attachment.name}
                                            linkStyle={s.downloadFile}
                                            documentSource='alfresco'
                                        />
                                    </li>
                                ))}
                            </AttachmentsList>
                        </>
                    )}
                </MessageDiv>

                <MessageCategoryDiv data-test="messageCategory">
                    {message.messageCategory && message.messageCategory.trim() !== '' && (
                        <MessageTagImageDiv src={IconMessageTag} alt="Message tag" />
                    )}
                    {message.messageCategory}
                </MessageCategoryDiv>

                <MessageCategoryDiv data-test="messageType">
                    {message.messageType && message.messageType.trim() !== '' && (
                        <MessageTagImageDiv
                            src={
                                message.messageType.toLowerCase().includes(MCConstants.MESSAGE_CENTER_URGENT_MESSAGE)
                                    ? IconUrgentMessageTag
                                    : IconMessageTag
                            }
                            alt={MCConstants.MESSAGE_CENTER_MESSAGE_TAG_ALT}
                        />
                    )}
                    {message.messageType}
                </MessageCategoryDiv>

                <TimeDiv data-test="time">
                    <TimeContentDiv>{TIME}</TimeContentDiv>
                    <DateContentDiv>
                        <span>{DATE}</span>
                    </DateContentDiv>
                </TimeDiv>
            </MessageContentDiv>
        </MessagesContainerDiv>
    );
}

MessageComponent.defaultProps = {
    message: {},
    toggleMessage: false,
    handleExpandMessage: () => {},
};

// Checking for proptypes. Message is required
MessageComponent.propTypes = {
    message: PropTypes.shape({
        skCreatedAt: PropTypes.string,
        isRead: PropTypes.bool,
        messageType: PropTypes.string,
        messageCategory: PropTypes.string,
        topic: PropTypes.string,
        accounts: PropTypes.array,
        subject: PropTypes.string,
        messageText: PropTypes.string,
        messageSecureText: PropTypes.string,
        attachments: PropTypes.array,
        numberOfAttachments: PropTypes.number,
        canReply: PropTypes.bool,
    }),
    toggleMessage: PropTypes.bool,
    handleExpandMessage: PropTypes.func,
};
export default MessageComponent;
