import * as AccessFormConstants from '../ReduxConstants/AccessFormConstants';

const initialState = {
    forms: [],
    accessForms: [],
    showLoadMore: false,
    showLoading: false,
    showError: false,
};

const getAccessForms = (state = initialState, action) => {
    const pageSize = 8;

    const createIntialPage = payload => {
        const mappedData = payload.data
            .filter(({ vcmId = "" }) => vcmId.length === 0)
            .map(form => {
                return {
                    formid: form.formId,
                    fileName: form.fileName,
                    fileDescription: form.description,
                    docCategory: form.docCategory,
                    contentType: form.contentType,
                    tag: form.tag || '',
                    nodeId: form.nodeId,
                    alternateDocumentId: form.alternateDocumentId,
                    documentSource: form.documentSource,
                    alternateDocumentLink: form.alternateDocumentLink,
                    title: form.title,
                };
        });
        const tagFillter = payload.searchPayload.tag.toLowerCase();
        let forms = [];

        if (tagFillter !== 'all form') {
            forms = mappedData.filter(form => form.tag.toLowerCase() === payload.searchPayload.tag.toLowerCase());
        } else {
            forms = mappedData;
        }

        const aForms = forms?.length > pageSize ? forms.slice(0, pageSize) : forms;
        return {
            forms: aForms,
            accessForms: forms,
            showLoadMore: aForms.length !== forms.length,
            showLoading: false,
            showError: false,
        };
    };

    const loadMore = () => {
        const { forms, accessForms } = state;
        const currentPage = parseInt(forms?.length / pageSize, 10);
        const loadedForms = accessForms.slice(0, (currentPage + 1) * pageSize);

        return {
            forms: loadedForms,
            accessForms,
            showLoadMore: loadedForms.length !== accessForms.length,
            showLoading: false,
            showError: false,
        };
    };

    switch (action.type) {
        case AccessFormConstants.GET_ACCESS_FORMS_SUCCESS:
            return { ...state, ...createIntialPage(action.payload) };
        case AccessFormConstants.LOAD_MORE:
            return { ...state, ...loadMore() };
        case AccessFormConstants.GET_ACCESS_FORMS_ERROR:
            return { ...state, showLoading: false, accessForms: [], forms: [], showError: true };
        case AccessFormConstants.GET_ACCESS_FORMS:
            return { ...state, showLoading: true };
        default:
            return state;
    }
};

export default getAccessForms;
