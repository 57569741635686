import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as TickMark } from 'assets/blueTick.svg';
import StyledButton from '../StyledComponents';

const VerticalSeperator = styled.div`
    border-left: 1px solid #d2d2d2;
    margin: 0 1.25rem;
`;
const BottomSecSeperator = styled(VerticalSeperator)`
    margin: 0 1.875rem;
`;
const DashedHr = styled.hr.attrs(() => ({ 'aria-hidden': true }))`
    border-top: 1px solid #d2d2d2;
    margin: 0;
`;
const Flex = styled.div`
    display: flex;
`;

const AccountCardBottomSection = styled(Flex)`
    padding: 1.5rem 2.5rem;
`;
const AccountCardTopSection = styled(Flex)`
    justify-content: space-between;
    padding: 1.25rem 1.5625rem 1.2rem 2.5rem;
`;
const AccountCardBottomColumn = styled(Flex)`
    flex-direction: column;
`;
const AccountCardHeadingSpan = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;
const AccountCardValueSpan = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledCard = styled(StyledButton)`
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 1.875rem;
    border: ${props => (props.selected ? '2px solid #004A98' : '1px solid #D2D2D2')};
    && {
        background: ${props => (props.selected ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
        box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
        border-radius: 0;
    }
    opacity: 1;
    &:hover {
        outline: ${props => (props.selected ? '2px solid #004A98' : '1px solid #3c5a77')};
    }
    &:focus {
        outline: ${props => (props.selected ? '2px solid #004A98' : '5px auto -webkit-focus-ring-color')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
    }
`;
const StyledCardBody = styled(Card.Body)`
    && {
        padding: 0;
    }
`;

const AccountName = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    align-self: center;
`;

const AccountNumber = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    align-self: center;
`;


const AccountCardComponent = props => {
    const { accountName, accountNumber, details, onClick, selected } = props;
    // const currentValueLabel = details[0].text + details[0].value;
    // const totalSharesLabel = details[1].text + details[1].value;
    // const automaticInvestmentLabel =  details[2] ? (details[2].text + details[2].value) : '';
    const label = `Account Name ${accountName} Account number ${accountNumber}`;
    const cardId = `id_${accountName}_${accountNumber}`;
    return (
        <StyledCard onClick={onClick} role="radio" selected={selected} aria-label={label} aria-checked={selected} id={cardId}>
            <StyledCardBody aria-describedby={cardId}  >
                <AccountCardTopSection   >
                    <Flex >
                        {/* <CardProfileSymbol src={AccountImg} alt="" /> */}
                        <AccountName  >{accountName}</AccountName>
                    </Flex>
                    {selected && <TickMark />}
                </AccountCardTopSection>

                <DashedHr />

                <AccountCardBottomSection>
                    {details.map((item, index) => {
                        return (
                            <React.Fragment key={index.toString()}>
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan >{item.text}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan  >{item.value}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                                {index !== details.length - 1 && <BottomSecSeperator />}
                            </React.Fragment>
                        );
                    })}
                </AccountCardBottomSection>
            </StyledCardBody>
        </StyledCard>
    );
};

AccountCardComponent.propTypes = {
    accountName: PropTypes.string,
    accountNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    details: PropTypes.instanceOf(Object),
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};
AccountCardComponent.defaultProps = {
    onClick: () => {},
    selected: false,
    details: [],
    accountName: '',
    accountNumber: '',
};

export default AccountCardComponent;
