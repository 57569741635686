import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';

export const EmailId = (props) => {
    const { changed, blurred, clicked, emailIdState, disable } = props;
    const data = {
       config: {
            id: 'emailId',
            type: 'email',
            label: 'E-mail'
        },
        change: e => changed(e, 'emailId'),
        blur: () => {
            if(!disable)
            blurred('emailId')
        },
        click: (event) => clicked(event)
    };

    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const errorId = `${data.config.id}_error`;

    if (!emailIdState.valid && emailIdState.touched) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={emailIdState.value}
        aria-describedby={errorId}
        aria-invalid={!!emailIdState.errMsg}
        aria-required="true" 
        onChange={data.change}
        aria-label="Email"
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        readOnly={disable} />;

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor="e-mail" aria-hidden>{data.config.label}</label>
            {inputElement}
            <p className={classes.errMsg} aria-live="polite" aria-atomic="true" id={errorId} >{emailIdState.errMsg}</p>
        </div>
    );

};

EmailId.propTypes = {
    emailIdState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            isEmail: PropTypes.bool,
            maxLength: PropTypes.number,
            required: PropTypes.bool
        })
    }),
    disable: PropTypes.bool,
    changed: PropTypes.func,
    blurred: PropTypes.func
};

EmailId.defaultProps = {
    emailIdState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            isEmail: true,
            maxLength: 30,
            required: true
        }
    },
    disable: false,
    changed: () => { },
    blurred: () => { }
};

export default EmailId;