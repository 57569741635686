import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import * as Styles from './Styles';
import * as Constants from './Constants';

const TopicsDropDown = props => {
    const { topics, setTopicValue, errorText, isDisabled, selectedTopic } = props;
    const sortTopics = () => {
        return topics.sort((item1, item2) => {
            const topic1 = item1.value.toUpperCase();
            const topic2 = item2.value.toUpperCase();
            if (topic1 < topic2) {
                return -1;
            }
            if (topic1 > topic2) {
                return 1;
            }
            return 0;
        });
    };

    return (
        <Styles.FormRow>
            <Col sm={12}>
                {isDisabled && (
                    <Row>
                        <Col sm={2}>
                            <Styles.Label>{Constants.FORMS.TOPIC}</Styles.Label>
                        </Col>
                        <Col sm={5}>{selectedTopic}</Col>
                    </Row>
                )}
                {!isDisabled && (
                    <Styles.DropDownRow
                        id="topics-drop-down"
                        className="row"
                        label={Constants.FORMS.TOPIC}
                        itemlist={sortTopics()}
                        valuesm={5}
                        errortext={errorText}
                        onChange={setTopicValue}
                    />
                )}
            </Col>
        </Styles.FormRow>
    );
};

TopicsDropDown.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.object),
    setTopicValue: PropTypes.func,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool,
    selectedTopic: PropTypes.string,
};

TopicsDropDown.defaultProps = {
    topics: [],
    setTopicValue: () => {},
    errorText: '',
    isDisabled: false,
    selectedTopic: '',
};
export default React.memo(TopicsDropDown);
