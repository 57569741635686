/**
 * Email Address Details Card
 ****************************************
 */
import AccGlobalConstants from '../AccountManagementConstants';
import checkValidity from '../../../utils/checkValidity';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Form, Alert } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import styled from 'styled-components';
import styles from '../PersonalInfo/styles';

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

const EMAIL_ERROR = 'Please enter your primary email address';
const ERROR_OFFSCREEN_TEXT = 'Error: ';
class MarketEmailDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errEmail: '',
            isKeyDown: false,
            showAlert: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { savedEmailAddress } = props;
        const { email, isKeyDown, errEmail } = state;
        if (email === '' && !isKeyDown && errEmail === '') {
            return {
                ...state,
                email: savedEmailAddress,
            };
        }
        return state;
    }

    isValidPrimaryEmailAddress = () => {
        const { email } = this.state;
        return email.length !== 0;
    };

    handleEmailAddressBlur = () => e => {
        const { required } = this.props;
        let { showAlert } = this.state;
        let errEmail = Rules[e.target.name.toString()]
            ? checkValidity({
                  rules: Rules[e.target.name.toString()],
                  value: e.target.value,
              })
            : '';
        if (required) {
            const {marketNAOInfo} = this.props;
            const emailInfo = e.target.value;
            if(this.props?.jointInfo == "Yes")
            {
                
                if(marketNAOInfo.contactInfo.primary.emailAddresses.maskedPrimaryEmailAddress == "" && marketNAOInfo.contactInfo.primary.emailAddresses.primaryEmailAddress == emailInfo )
                {
                    errEmail = `The joint account owner's email address can not be the same as your email address.`;
                }
            }
        }
        this.setState({
            errEmail,
            isKeyDown: false,
            email: e.target.value,
            showAlert,
        });
    };

    handleEmailAddressChange = () => e => {
        this.setState({ [e.target.name]: e.target.value, isKeyDown: true });
        if(this.props?.flowFlag == 'MarketNAO')
        {
           const {handleEmailDetails} = this.props;
           handleEmailDetails();
        }
    };

    getEmailDetails = () => {
        const { email, required } = this.state;
        const { isJoint, type, index } = this.props;
        const jointText = isJoint ? 'jointOwner' : '';
        const emailId = `${jointText}${type}email${index}`;
        if (required) {
            const validPrimaryEmail = this.isValidPrimaryEmailAddress(email);
            this.setState({ showAlert: !validPrimaryEmail });
        }
        return { ...this.state, emailId };
    };

    render() {
        const { emailType, required, index, isJoint, type, isReadOnly, tabindexVal } = this.props;
        const { email, errEmail, showAlert } = this.state;
        const { assignObj } = AccGlobalConstants;
        const jointText = isJoint ? 'jointOwner' : '';
        const EMAIL_INFORMATION_LABEL = ' Email Information';
        const EMAIL_LABEL = ' Email';
       const verifyPersonalHider=this.props.verifyPersonalHider
         const verifyContactHider=this.props.verifyContactHider
          const verifyPhoneHider=this.props.verifyPhoneHider
            const verifyJointPersonalHider=this.props.verifyJointPersonalHider
              const verifyJointContactHider=this.props.verifyJointContactHider
              const verifyJointPhoneHider=this.props.verifyJointPhoneHider

 
        return (
          verifyPersonalHider || verifyContactHider || verifyPhoneHider || verifyJointPersonalHider || verifyJointContactHider || verifyJointPhoneHider ? null :   <div className="phoneWrapper">
                {required && showAlert && (
                    <StyledAlert
                        variant="danger"
                        aria-atomic="true"
                        aria-live="assertive"
                        aria-describedby={EMAIL_ERROR}
                    >
                        <StyledPara>{EMAIL_ERROR}</StyledPara>
                    </StyledAlert>
                )}
                <h3 style={styles.mobileHeaderStyle} className="primaryPhoneAlign">
                    {emailType}
                    {EMAIL_INFORMATION_LABEL}
                </h3>

                {/* -------------------------start-------------------------------- */}
                <div className="fieldWrapperDiv">
                    <Form.Group as={Row} className="no-gutters emailGroup" style={styles.labelStyle}>
                        <Form.Text
                            type="label col-sm-4"
                            className="label"
                            style={styles.labelTextStyle}
                            htmlFor={`${jointText}${type}email${index}`}
                            id={`${jointText}${type}email-label${index}`}
                            aria-label={emailType + EMAIL_LABEL}
                        >
                            {emailType}
                            {EMAIL_LABEL}
                        </Form.Text>
                        <div className="emailWrapper col-sm-6">
                            <div className="inputDiv">
                                <Form.Control
                                    type="text"
                                    onChange={this.handleEmailAddressChange()}
                                    onBlur={this.handleEmailAddressBlur()}
                                    required={required}
                                    id={`${jointText}${type}email${index}`}
                                    name="email"
                                    onInput={assignObj(e => {
                                        e.target.value = e.target.value.replace(/\s/g, '');
                                    })}
                                    value={email || ''}
                                    isInvalid={!!errEmail}
                                    maxLength={50}
                                    data-tab-index-changed="false"
                                    aria-required={required}
                                    aria-describedby={assignObj(`${emailType}emailAddress_error${index}`)}
                                    // aria-label={assignObj(`${emailType} email address`)}
                                    aria-labelledby={`${jointText}${type}email-label${index}`}
                                    readOnly={isReadOnly}
                                    tabIndex={tabindexVal ? -1 : 0}
                                />
                                <Form.Text
                                    type="invalid"
                                    className="errorMsgInputStyle"
                                    id={assignObj(`${emailType}emailAddress_error${index}`)}
                                    aria-atomic="true"
                                    aria-live="assertive"
                                >
                                    {errEmail && <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>}
                                    {errEmail}
                                </Form.Text>
                            </div>
                        </div>
                    </Form.Group>
                </div>
                {/* -------------------------end---------------------------------- */}
            </div>
        );
    }
}

MarketEmailDetails.propTypes = {
    required: PropTypes.bool,
    emailType: PropTypes.string,
    savedEmailAddress: PropTypes.string,
    isJoint: PropTypes.bool,
    index: PropTypes.number,
    type: PropTypes.string,
    isReadOnly: PropTypes.bool,
    tabindexVal: PropTypes.bool
};

MarketEmailDetails.defaultProps = {
    required: false,
    emailType: '',
    savedEmailAddress: '',
    isJoint: 'false',
    index: 100,
    type: '',
    isReadOnly: false,
    tabindexVal: 0,
};

export default MarketEmailDetails;
