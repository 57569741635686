import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import logo from '../../assets/VCM_logo.svg';
import WIcon from '../WIcon/WIcon';
import '../CommonStyles';
import CONSTS from '../Header/CONSTS';

const {    
    assignObj,
    skipText,
    styles,    
} = CONSTS;

class HeaderWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {};        
    }

    render() {
        return (
            <div>
                <header
                    style={assignObj({ backgroundColor: 'rgb(72,109,144)', zIndex: '1051', position: 'relative' })}
                    role="banner"
                >
                    <div className="container">
                        <button type="button" aria-hidden="true" className="sr-only" id="hiddenBtn" tabIndex="-1" />
                        <a
                            href="#maincontent"
                            aria-label="skip to main content"
                            id="skipToMain"
                            className="sr-only sr-only-focusable skiptocontent"
                        >
                            {skipText}
                        </a>
                        <Row noGutters className="menu-header">
                            <Col sm={4} md={4} lg={4} xl={4}>
                                <Link to="/">
                                    <div className="headerLogo">
                                        <WIcon src={logo} alt="VCM Logo Home" fontawesomestyle={styles.logo} />
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </header>
            </div>
        );
    }
}

HeaderWidget.propTypes = {};

HeaderWidget.defaultProps = {};

export default withRouter(HeaderWidget);
