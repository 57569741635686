/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';

const fontawesomeIconStyles = {
  height: 20,
  width: 20,
  margin: '20px 20px 10px 20px',
  lineHeight: 'unset',
};

const WIcon = (props) => {
  const assignObj = (obj) => obj;
  const {
    src, icon, size, style,
  } = props;
  const wrapperstyle = assignObj({ ...style, cursor: 'pointer' });
  return (
    <div {...props} style={wrapperstyle}>
      {!src ? (
        <FontAwesomeIcon
          icon={icon}
          size={size}
          style={assignObj({ fontawesomeIconStyles })}
        />
      ) : (
        <Image src={src} {...props} style={assignObj(fontawesomeIconStyles)} />
      )}
    </div>
  );
};

WIcon.propTypes = {
  icon: PropTypes.PropTypes.instanceOf(Object),
  size: PropTypes.string,
  src: PropTypes.string,
  fontawesomestyle: PropTypes.PropTypes.instanceOf(Object),
  style: PropTypes.PropTypes.instanceOf(Object),
};

WIcon.defaultProps = {
  icon: faUserCircle,
  size: '2x',
  fontawesomestyle: null,
  src: undefined,
  style: {},
};

export default WIcon;
