import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Button from '../Button';

export const StyledButton = styled(Button)`
    background: ${props => props.disabled ? '#D2D2D2' : props.bgColor } 0% 0% no-repeat padding-box;
    width: 160px;
    height: ${props => props.inlineBtn ? 44 : 50}px;
    position: relative;
    
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: ${props => props.disabled ? '#49494A' : '#FFFFFF' };
    opacity: 1;

    &:hover {
        background: ${props => props.disabled ? '#D2D2D2' : '#004A98' } 0% 0% no-repeat padding-box;
    }
`;

const BtnCorner = styled.span`
    width: 0px;
    height: 0px;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #8CBF26;
    position: absolute;
    top: 0px;
    right: 0px;
    ${StyledButton}:hover & {
        borderRight: 20px solid #8BC105;
    }
`;


const PrimaryBtn = React.forwardRef((props, ref) => {
    const { disabled, children, inlineBtn } = props;
    const bgColor = inlineBtn ? '#4F799F' : '#486D90';
    return (
        <StyledButton ref={ref} {...props} bgColor={bgColor} >
            { children }
            {(!disabled && !inlineBtn ) && <BtnCorner />}
        </StyledButton>
        )
});

PrimaryBtn.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
    inlineBtn: PropTypes.bool,
};

PrimaryBtn.defaultProps = {
    disabled: false,
    children: 'Button',
    inlineBtn: false,
};

export default PrimaryBtn;