import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import './WSpinner.css';
import styles from './inputs/CommonStyles';
import loader from '../../../assets/loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const WSpinner = ({ connecting, processing, thanks }) => {
  let classFieldName = 'thanks';
  let textField = 'Thank you!';
  if (connecting) {
    classFieldName = 'connecting';
    textField = 'Connecting to Victory Capital Management…';
  }
  if (processing) {
    classFieldName = 'processing';
    textField = 'Please wait while we process your information…';
  }

  return (
    <div className="background accountReg">
      {(connecting || processing || thanks) && (
        <div className={classFieldName}>
          <div
            key={1}
            style={styles.spinnerDiv}
            className="vcmSpinner"
            aria-busy="true"
            aria-live="polite"
            role="alert"
          >
            <Lottie
              options={defaultOptions}
              height={140}
              width={50}
              ariaRole="presentation"
              ariaLabel=""
              tabindex="-1"
            />
            <p className="connectingText">{textField}</p>
          </div>
        </div>
      )}
    </div>
  );
};

WSpinner.propTypes = {
  connecting: PropTypes.bool,
  processing: PropTypes.bool,
  thanks: PropTypes.bool,
};

WSpinner.defaultProps = {
  connecting: true,
  processing: false,
  thanks: false,
};

export default WSpinner;
