/**
 * Dashboard Screen Component Styles and Constants
 ********************************
 *
 * @version 1.0.1
 * @author Numaan
 * @description The Highchart consists of  HIghchart component which takes the values in array and crates the graph accordingly.
 * @createdDate [16/03/2020]
 * ***************************************
 * @lastModifiedDate [16/06/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedChanges added tool tip 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

const userConfig = {
    tooltip: {
        pointFormat:
            "<span style='color:{point.color}'>\u25CF</span> {point.formattedGroupTotal}:<b>{point.percentage:.2f}% </b>",
    },
};

class HighChart extends Component {
    constructor(props) {
        super(props);
        const {mapData}=this.props
        this.state = {
            actualSeriesData:mapData || []
        };
    }

    componentDidMount() {
      this.highChartsRender();
    }   

    highChartsRender = () => {
        const {actualSeriesData}=this.state
        const {handleChartLoad}=this.props
        Highcharts.chart({
            chart: {
                type: 'pie',
                renderTo: 'holdingGroupComposition',
                events:{
                    load:function (){
                        handleChartLoad()
                    }
                }
            },
            title: { text: '' },
            colors: ['#00ADEE', '#7A2C7B', '#0D823C', '#9D6915', '#56565A', '#145A8F'],
            series:[
                {
                    type: 'pie',
                    data: actualSeriesData,
                    animation:false
                },
            ],
            legend: {
                enabled: false,
                align: 'left',
                layout: 'vertical',
                verticalAlign: 'middle',
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    borderWidth: 3,
                    borderColor: '#ffffff',
                    center: ['50%', '50%'],
                    size: '80%',
                    name: '',
                    innerSize: 170,
                    animation: false,
                },
            },
            credits: {
                enabled: false,
            },
            ...userConfig,            
        });
    }
    
    render() {
        return (
            <div id="holdingGroupComposition" className="highCharts"/>
        );
    }
}

HighChart.propTypes = {
    mapData: PropTypes.instanceOf(Object),
    handleChartLoad:PropTypes.func
};

HighChart.defaultProps = {
    mapData: [],
    handleChartLoad:()=>{}
};

export default HighChart;

