import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { localStateManagementActions, loginActions } from 'shared/Actions';

import ConfirmSecurityQuestions from './ConfirmSecurityQuestions';

export const mapStateToProps = state => ({
    loginState: state.loginData,
    savedData: state.localStateManagementReducerData.setupSecurityQuestionsState,
    preferenceData: state.localStateManagementReducerData.preferenceState,
    customerProfile: state.localStateManagementReducerData.customerProfile,
});

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmSecurityQuestions));
