import React from 'react';
import PropTypes from 'prop-types';
import { Card, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import linkStyle from '../WCardList/Styles';
import { analyzeClickEvent } from '../../../../shared/Actions/AnalyticsActions';
import constant from '../AccountDashboardConstants'


const Offers = ({ item,widgetName,borderStyle }) => {
    const { link, Desc, linkText } = item;
    const dispatch = useDispatch();
    const {assignObj,
       // externalWeb
    }=constant
    function Order(){ 
        const screenName=localStorage.getItem("screenName")
        window.supressRefresh=true
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_${widgetName}_${linkText}`,
            }),
        );
    }
    // function externalSiteHtml (){
    //     return  <><span className="sr-only" data-test="externalWeb">{externalWeb}</span> {/* <span aria-hidden="true" className="icon-newpage"/> */} </>
    // }
    return (
        <div data-test="offers" className="activity">
            <Card className="ml-0 mr-0 border-0">
                <ListGroup variant="flush" style={assignObj({...linkStyle.borderStyle,...borderStyle})}>
                    <ListGroup.Item className="text-left p-0" style={linkStyle.listgroup}>
                        <h3 className="rtHeading">
                            <Card.Link data-test="link" style={linkStyle.link} href={link} target="_self" rel="noopener noreferrer" onClick={Order}>
                                {linkText}
                            </Card.Link>
                        </h3>
                        <div>
                            <p style={linkStyle.linkDescription}>{Desc}</p>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        </div>
    );
};

export default Offers;

Offers.propTypes = {
    item: PropTypes.instanceOf(Object),
    widgetName:PropTypes.string,
    borderStyle:PropTypes.instanceOf(Object),
};
Offers.defaultProps = {
    item: {},
    widgetName:'',
    borderStyle:{}
};
    