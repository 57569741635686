const disclosureData = [
    // {
    //     fundCode: '56',
    //     symbol: 'USAXX',
    //     fundName: 'USAA Money Market',
    //     disclosure: 'USAAIndexFundDisclosure',
    //     fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Money Market Fund DM FS.pdf',
    // },
    {
        fundCode: '48',
        symbol: 'UCMCX',
        fundName: 'USAA Cornerstone Moderately Conservative',
        fundFactPdf:
            '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Moderately Conservative Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            'An asset allocation Fund with an investment objective to seek current income with a secondary focus on capital appreciation. The fund will invest in equity securities, bonds, money market instruments, and other instruments, including derivatives. The Fund will have a target asset class allocation of approximately 40% equity securities and 60% fixed-income securities.',
    },
    {
        fundCode: '56',
        symbol: 'USEMX',
        fundName: 'USAA Emerging Markets',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Emerging Markets Fund DM FS.pdf',
        disclosure: 'USAAEmergingMarketsFundDisclosure',
        summaryText:
            "The fund's investment objective is capital appreciation. Under normal market conditions, at least 80% of the fund's assets will be invested in equity securities of emerging market companies.",
    },
    {
        fundCode: '73',
        symbol: 'USMIX',
        fundName: 'USAA Extended Market Index',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Extended Market Index Fund DM FS.pdf',
        disclosure: 'USAAExtendedMarketIndexDisclosure',
        summaryText:
            "The fund seeks to match, before fees and expenses, the performance of all small- and mid-cap stocks as measured by the Wilshire 4500 Completion Index. The Fund's principal investment strategy is, under normal market conditions, to invest at least 80% of its assets in securities or other financial instruments of companies that are components of, or have economic characteristics similar to, the securities included in the Index. This strategy may be changed upon 60 days' written notice to shareholders.",
    },
    {
        fundCode: '75',
        symbol: 'UGEIX',
        fundName: 'USAA Global Equity Income',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Global Equity Income Fund DM FS.pdf',
        disclosure: 'USAAGlobalEquityIncomeFundDisclosure',
        summaryText:
            "The fund's objective seeks total return with an emphasis on current income by normally investing at least 80% of its assets in equity securities. This 80% policy may be changed upon at least 60 days' written notice to shareholders. The fund's equity securities may include common stocks, depositary receipts, real estate investment trusts (REITs), other investment companies, including exchange-traded funds (ETFs), master limited partnerships (MLPs), securities convertible into common stocks, and securities that carry the right to buy common stocks. The fund will invest primarily in global equity securities with an emphasis on companies that the fund's management believes have attractive dividend policies and/or those with the potential to grow their dividends. Under normal circumstances, (a) the Fund's investments will provide exposure to investments that are economically tied to at least five different countries, and (b) the Fund expects to invest at least 30% of its net assets in securities of issuers outside of the United States.",
    },
    {
        fundCode: '94',
        symbol: 'UGMVX',
        fundName: 'USAA Global Managed Volatility',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Global Managed Volatility Fund DM FS.pdf',
        disclosure: 'USAAGlobalManagedVFundDisclosure',
        summaryText:
            'The Fund seeks to attain long-term capital appreciation while attempting to reduce volatility during unfavorable market conditions. The fund combines a portfolio of domestic and foreign equity securities, including emerging markets securities, with the use of alternative investment strategies to provide growth with lower volatility.',
    },
    {
        fundCode: '58',
        symbol: 'USGNX',
        fundName: 'USAA Government Securities',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Government Securities Fund DM FS.pdf',
        disclosure: 'USAAGovSecuritiesFundDisclosure',
        summaryText:
            "The Fund's investment objective is to provide investors a high level of current income consistent with preservation of principal. The principal investment strategy is to normally invest at least 80% of its assets in government securities, U.S. Treasury bills, notes, and bonds; Treasury Inflation Protected Securities (TIPS); mortgage-backed securities (MBS) backed by the Government National Mortgage Association (Ginnie Mae), the Federal National Mortgage Association (Fannie Mae), and the Federal Home Loan Mortgage Corporation (Freddie Mac); U.S. government agency collateralized mortgage obligations; securities issued by U.S. government agencies and instrumentalities; and repurchase agreements collateralized by such investments. The securities issued by U.S. government agencies and instrumentalities are supported by the credit of the issuing agency, instrumentality or corporation (which are neither issued nor guaranteed by the U.S. Treasury), including but not limited to Fannie Mae, Freddie Mac, Farmer Mac, Federal Farm Credit Bank, Federal Home Loan Bank, Private Export Funding Corp (Pefco), and the Small Business Administration. The Fund has a target average maturity of 5-10 years. This 80% policy may be changed upon at least 60 days' notice to shareholders.",
    },
    {
        fundCode: '37',
        symbol: 'USGRX',
        fundName: 'USAA Growth and Income',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Growth and Income Fund DM FS.pdf',
        disclosure: 'USAAGrowthFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is capital growth with current income as a secondary objective. The fund invests primarily in equity securities that show the best potential for total return through a combination of capital growth and income. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '53',
        symbol: 'USBLX',
        fundName: 'USAA Growth and Tax Strategy',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Growth and Tax Strategy Fund DM FS.pdf',
        disclosure: 'USAAGrowthTaxStrategyFundDisclosure',
        summaryText:
            "The fund's investment objective is a conservative balance for the investor between income, the majority of which is exempt from federal income tax, and the potential for long-term growth of capital to preserve purchasing power. Using preset target ranges, the fund invests a majority of its assets in tax-exempt bonds and money market instruments (50% - 70%) and the remainder in blue chip stocks (30% - 50%).",
    },
    {
        fundCode: '41',
        symbol: 'USAAX',
        fundName: 'USAA Growth',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Growth Fund DM FS.pdf',
        disclosure: 'USAAGrowthFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is long-term growth of capital. The fund invests primarily in a diversified portfolio of equity securities selected for their growth potential. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '80',
        symbol: 'USHYX',
        fundName: 'USAA High Income',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA High Income Fund DM FS.pdf',
        disclosure: 'USAAHighIncomeFundGroupDisclosure',
        summaryText:
            "The fund's objective is to provide an attractive total return primarily through high current income and secondarily through capital appreciation. The Fund's principal investment strategy is to primarily invest its assets in a broad range of U.S. dollar-denominated high-yield securities, including bonds (often referred to as 'junk' bonds), convertible securities, leveraged loans, or preferred stocks, with an emphasis on non-investment-grade debt securities. Although the fund will invest primarily in U.S. securities, it may invest without limit in dollar-denominated foreign securities and to a limited extent in non-dollar-denominated foreign securities, including in each case emerging markets securities.",
    },
    {
        fundCode: '40',
        symbol: 'USAIX',
        fundName: 'USAA Income',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Income Fund DM FS.pdf',
        disclosure: 'USAAHighIncomeFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is maximum current income without undue risk to principal. The fund invests primarily in U.S. dollar-denominated debt securities, including, among others, obligations of U.S., state, and local governments, their agencies and instrumentalities, mortgage- and asset-backed securities, corporate debt securities, and repurchase agreements that have been selected for their high yields relative to the risk involved. The fund will invest primarily in investment-grade securities but also may invest up to 10% of its net assets in below-investment-grade securities, which are sometimes referred to as high-yield or 'junk' bonds. The fund may invest up to 20% of its assets in foreign securities, including non-dollar-denominated securities and emerging-markets securities.",
    },
    {
        fundCode: '35',
        symbol: 'USISX',
        fundName: 'USAA Income Stock',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Income Stock Fund DM FS.pdf',
        disclosure: 'USAAIncomeStockFundDisclosure',
        summaryText:
            "The fund's investment objective is current income with the prospect of increasing dividend income and the potential for capital appreciation. The fund normally invests at least 80% of its assets in common stocks, with at least 65% of the fund's assets normally invested in common stocks of companies that pay dividends. This 80% policy may be changed upon at least 60 days' written notice to shareholders. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '30',
        symbol: 'USIBX',
        fundName: 'USAA Intermediate-Term Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Intermediate-Term Bond Fund DM FS.pdf',
        disclosure: 'USAAHighIncomeFundGroupDisclosure',
        summaryText:
            "The fund's objective is high current income without undue risk to principal. Normally, at least 80% of the Fund's assets will be invested in a broad range of debt securities with a dollar-weighted average portfolio maturity between three to 10 years. Up to 65% of the Fund's assets may be invested in corporate bonds. The debt securities in which the fund may investment include, among others, obligations of U.S., state, and local governments, their agencies and instrumentalities; mortgage- and asset-backed securities; corporate debt securities; repurchase agreements; and other securities believed to have debt-like characteristics. The fund will investment primarily in investment-grade securities, but also may invest up to 10% of its net assets in below-investment-grade securities, which are sometimes referred to as high-yield or 'junk' bonds. This 80% policy may be changed upon at least 60 days' written notice to shareholders.",
    },
    {
        fundCode: '52',
        symbol: 'USIFX',
        fundName: 'USAA International',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA International Fund DM FS.pdf',
        disclosure: 'USAAInternationalSustainableWorldFundGroupDisclosure',
        summaryText:
            "The Fund's investment objective is capital appreciation. The Fund normally invests at least 80% of its assets in equity securities of foreign companies, including those in emerging markets.",
    },
    {
        fundCode: '42',
        symbol: 'USAXX',
        fundName: 'USAA Money Market',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Money Market Fund DM FS.pdf',
        disclosure: 'USAAMoneyMarketFundDisclosure',
        summaryText:
            "The fund's investment objective is the highest income consistent with preservation of capital and the maintenance of liquidity. The fund invests in high-quality, U.S. dollar-denominated, short-term debt securities of domestic and foreign issuers that have been determined to present minimal credit risk and comply with strict Securities and Exchange Commission (SEC) guidelines applicable to money market funds. The fund restricts its investments to instruments that meet certain maturity and quality requirements under the federal securities laws applicable to money market funds. Generally, such investments will be limited to a security with a remaining maturity of 397 calendar days or less that is determined to present minimal credit risk; issued by a money market fund; or issued or guaranteed by the U.S. government or any agency or instrumentality thereof.",
    },
    {
        fundCode: '74',
        symbol: 'USNQX',
        fundName: 'USAA Nasdaq 100 Index',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Nasdaq 100 Index Fund DM FS.pdf',
        disclosure: 'USAANasdaqIndexFundDisclosure',
        summaryText:
            "The fund seeks to match, before fees and expenses, the performance of the stocks composing the Nasdaq-100 Index. Under normal market conditions, at least 80% of the fund's assets will be invested in the common stocks of companies composing the Nasdaq-100 Index. The Nasdaq-100 Index represents 100 of the largest nonfinancial stocks traded on The Nasdaq Stock Market.",
    },
    {
        fundCode: '62',
        symbol: 'USNYX',
        fundName: 'USAA New York Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA New York Bond Fund DM FS.pdf',
        disclosure: 'USAANewYorkBondFundDisclosure',
        summaryText:
            "The investment seeks to provide a high level of current interest income that is exempt from federal income tax and New York State and New York City personal income taxes.  The fund invests primarily in long-term investment-grade securities issued by the state of New York, its political subdivisions, municipalities and public authorities, instrumentalities, and by other government entities. At least 80% of the fund's net assets will consist of New York tax exempt securities. The fund's dollar-weighted average portfolio maturity is not restricted, but is expected to be greater than 10 years.",
    },
    {
        fundCode: '50',
        symbol: 'USAGX',
        fundName: 'USAA Precious Metals and Minerals',
        fundFactPdf:
            '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Precious Metals and Minerals Fund DM FS.pdf',
        disclosure: 'USAAPreciousMetalsFundDisclosure',
        summaryText:
            "The fund's investment objective is long-term capital appreciation and to protect the purchasing power of your capital against inflation. Normally at least 80% of the fund's assets will be invested in equity securities of domestic and foreign companies (including those located in emerging markets) principally engaged in the exploration, mining, or processing of gold and other precious metals and minerals, such as platinum, silver, and diamonds. This 80% policy may be changed upon at least 60 days' written notice to shareholders.",
    },
    {
        fundCode: '31',
        symbol: 'USSCX',
        fundName: 'USAA Science and Technology',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Science and Technology Fund DM FS.pdf',
        disclosure: 'USAAScienceTechFundDisclosure',
        summaryText:
            "The fund's investment objective is long-term capital appreciation. Normally at least 80% of the Fund's assets will be invested in equity securities of companies expected to benefit from the development and use of scientific and technological advances and improvements. This 80% policy may be changed upon at least 60 days' written notice to shareholders. The fund may invest up to 50% of its assets in foreign securities purchased in either foreign or U.S markets.",
    },
    {
        fundCode: '36',
        symbol: 'USSBX',
        fundName: 'USAA Short-Term Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Short-Term Bond Fund DM FS.pdf',
        disclosure: 'USAAShortTermBondDisclosure',
        summaryText:
            "The fund's investment objective is high current income consistent with preservation of principal. Normally, at least 80% of the fund's assets will be invested in a broad range of investment-grade debt securities with a dollar-weighted average portfolio maturity of three years or less. The debt securities in which the fund may invest include, among others, obligations of U.S., state, and local governments, their agencies and instrumentalities; mortgage- and asset-backed securities; corporate debt securities; repurchase agreements; and other securities believed to have debt-like characteristics. Although the fund will invest primarily in investment-grade securities, the fund also may invest up to 10% of its net assets in below-investment-grade securities, which sometimes are referred to as high-yield or 'junk' bonds. This 80% policy may be changed upon at least 60 days' written notice to shareholders.",
    },
    {
        fundCode: '81',
        symbol: 'USCAX',
        fundName: 'USAA Small Cap Stock',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Small Cap Stock Fund DM FS.pdf',
        disclosure: 'USAASmallCapStockFundDisclosure',
        summaryText:
            "The fund's investment objective is long-term growth of capital. Normally at least 80% of the Fund's assets will be invested in equity securities of companies with small market capitalizations. This 80% policy may be changed upon at least 60 days' written notice. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '86',
        symbol: 'URTNX',
        fundName: 'USAA Target Retirement 2020',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement 2020 Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            "The Target Retirement 2020 Fund's investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds consistent with its current asset allocation as depicted in the lifestyle transition path.",
    },
    {
        fundCode: '87',
        symbol: 'URTRX',
        fundName: 'USAA Target Retirement 2030',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement 2030 Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            "The Target Retirement 2030 Fund's investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds consistent with its current asset allocation as depicted in the lifestyle transition path.",
    },
    {
        fundCode: '88',
        symbol: 'URFRX',
        fundName: 'USAA Target Retirement 2040',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement 2040 Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            'The Target Retirement 2040 Fund’s investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds consistent with its current asset allocation as depicted in the lifestyle transition path.',
    },
    {
        fundCode: '89',
        symbol: 'URFFX',
        fundName: 'USAA Target Retirement 2050',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement 2050 Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            "The Target Retirement 2050 Fund's investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds consistent with its current asset allocation as depicted in the lifestyle transition path.",
    },
    {
        fundCode: '90',
        symbol: 'URSIX',
        fundName: 'USAA Target Retirement 2060',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement 2060 Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            "The Target Retirement 2060 Fund's investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds consistent with its current asset allocation as depicted in the lifestyle transition path.",
    },
    {
        fundCode: '85',
        symbol: 'URINX',
        fundName: 'USAA Target Retirement Income',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Target Retirement Income Fund DM FS.pdf',
        disclosure: 'USAATargetRetirementFundDisclosure',
        summaryText:
            "The Target Retirement Income Fund's investment objective is to provide capital appreciation and current income consistent with its current investment allocation. The Fund attempts to achieve its objective by investing in a diversified portfolio of affiliated mutual funds in a manner consistent with its current asset allocation as depicted in the lifestyle transition path of approximately 35% equity/alternative securities and 65% fixed-income securities.",
    },
    {
        fundCode: '44',
        symbol: 'USATX',
        fundName: 'USAA Tax Exempt Intermediate-Term',
        fundFactPdf:
            '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Tax Exempt Intermediate-Term Fund DM FS.pdf',
        disclosure: 'USAATaxExemptFundDisclosure',
        summaryText:
            "The fund's investment objective is interest income that is exempt from federal income tax. The fund invests principally in investment-grade, tax-exempt securities with an average dollar-weighted portfolio maturity of between three and ten years.",
    },
    {
        fundCode: '43',
        symbol: 'USTEX',
        fundName: 'USAA Tax Exempt Long-Term',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Tax Exempt Long-Term Fund DM FS.pdf',
        disclosure: 'USAATaxExemptFundDisclosure',
        summaryText:
            "The fund's investment objective is interest income that is exempt from federal income tax. The fund invests principally in investment-grade, tax-exempt securities with an average dollar-weighted portfolio maturity of ten years or more.",
    },
    {
        fundCode: '46',
        symbol: 'USEXX',
        fundName: 'USAA Tax Exempt Money Market',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Tax Exempt Money Market Fund DM FS.pdf',
        disclosure: 'USAATaxExemptMoneyMarketFundDisclosure',
        summaryText:
            "The fund's investment objective is interest income that is exempt from federal income tax and a further objective of preserving capital and maintaining liquidity. The fund invests principally in high-quality, tax-exempt securities with maturities of 397 days or less.",
    },
    {
        fundCode: '45',
        symbol: 'USSTX',
        fundName: 'USAA Tax Exempt Short-Term',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Tax Exempt Short-Term Fund DM FS.pdf',
        disclosure: 'USAATaxExemptFundDisclosure',
        summaryText:
            "The fund's investment objective is interest income that is exempt from federal income tax. The fund invests principally in investment-grade, tax-exempt securities with an average dollar-weighted portfolio maturity of three years or less.",
    },
    {
        fundCode: '59',
        symbol: 'UATXX',
        fundName: 'USAA Treasury Money Market',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Treasury Money Market DM FS.pdf',
        disclosure: 'USAATreasuryMoneyMarketDisclosure',
        summaryText:
            "The fund's investment objective is to provide investors maximum current income while maintaining the highest degree of safety and liquidity. The fund normally invests at least 80% of its assets in U.S. government securities with maturities of 397 days or less, which consist of U.S. Treasury bills, notes, and bonds; repurchase agreements collateralized by such obligations; and other obligations of the U.S. Treasury. The 80% policy may be changed upon at least 60 days' written notice to shareholders. The Fund intends to qualify as a government money market fund and is required to invest at least 99.5% of its total assets in cash, U.S. government securities, and/or repurchase agreements that are fully collateralized by cash or government securities. Government securities include any securities issued or guaranteed as to principal and interest by the United States and its agencies or instrumentalities.",
    },
    {
        fundCode: '77',
        symbol: 'UUSTX',
        fundName: 'USAA Ultra Short-Term Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Ultra Short-Term Bond Fund DM FS.pdf',
        disclosure: 'USAAHighIncomeFundGroupDisclosure',
        summaryText:
            "The fund seeks to provide high current income consistent with preservation of principal. The fund's principal investment strategy is to normally invest at least 80% of the fund's assets in investment-grade debt securities that have a dollar-weighted average portfolio maturity of 18 months (one and a half years) or less.",
    },
    {
        fundCode: '76',
        symbol: 'UVALX',
        fundName: 'USAA Value',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Value Fund DM FS.pdf',
        disclosure: 'USAAGrowthFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is long-term growth of capital. The fund invests primarily in equity securities of companies that are considered to be undervalued. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '64',
        symbol: 'USVAX',
        fundName: 'USAA Virginia Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Virginia Bond Fund DM FS.pdf',
        disclosure: 'USAAVirginiaBondFundDisclosure',
        summaryText:
            "The fund's investment objective is to provide a high level of current interest income that is exempt from federal and Virginia state income taxes. The fund invests principally in long-term, investment-grade Virginia tax-exempt securities.",
    },
    {
        fundCode: '54',
        symbol: 'USAWX',
        fundName: 'USAA World Growth',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA World Growth Fund DM FS.pdf',
        disclosure: 'USAAInternationalSustainableWorldFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is capital appreciation. The fund invests primarily in equity securities of both foreign (including emerging markets) and domestic issuers.",
    },
    {
        fundCode: '34',
        symbol: 'USSPX',
        fundName: 'USAA 500 Index Fund Member',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA 500 Index Fund DM FS.pdf',
        disclosure: 'USAAIndexFundDisclosure',
        summaryText:
            'The Fund seeks to match the performance, before fees and expenses, of the stocks composing the Victory US Large Cap 500 Index. Under normal market conditions, at least 80% of the fund’s assets will be invested in the common stocks of companies composing the Index, which is a market-cap weighted index consisting of the 500 largest companies within the Wilshire 5000 Total Market IndexSM.',
    },
    {
        fundCode: '33',
        symbol: 'USPRX',
        fundName: 'USAA 500 Index Fund Reward',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA 500 Index Fund DM FS.pdf',
        disclosure: 'USAAIndexFundDisclosure',
        summaryText:
            'The Fund seeks to match the performance, before fees and expenses, of the stocks composing the Victory US Large Cap 500 Index. Under normal market conditions, at least 80% of the fund’s assets will be invested in the common stocks of companies composing the Index, which is a market-cap weighted index consisting of the 500 largest companies within the Wilshire 5000 Total Market IndexSM.',
    },
    {
        fundCode: '38',
        symbol: 'USAUX',
        fundName: 'USAA Aggressive Growth',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Aggressive Growth Fund DM FS.pdf',
        disclosure: 'USAAGrowthFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is capital appreciation. The fund invests primarily in equity securities of large capitalization companies that are selected for their growth potential. Although the fund will invest primarily in U.S. securities, it may invest up to 20% of its total assets in foreign securities including securities issued in emerging markets.",
    },
    {
        fundCode: '60',
        symbol: 'USCBX',
        fundName: 'USAA California Bond',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA California Bond Fund DM FS.pdf',
        disclosure: 'USAACaliforniaBondFundDisclosure',
        summaryText:
            "The fund's investment objective is a high level of current interest income that is exempt from federal and California state income taxes. The fund invests principally in long-term, investment-grade California tax-exempt securities.",
    },
    {
        fundCode: '72',
        symbol: 'USCGX',
        fundName: 'USAA Capital Growth',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Capital Growth Fund DM FS.pdf',
        disclosure: 'USAAGrowthFundGroupDisclosure',
        summaryText:
            "The fund's objective is capital appreciation. The Fund's principal investment strategy is to primarily invest its assets in U.S. and foreign equity securities that are believed to be the most attractive in the global marketplace. The Fund may invest up to 100% of its assets in foreign securities.",
    },
    {
        fundCode: '49',
        symbol: 'UCAGX',
        fundName: 'USAA Cornerstone Aggressive',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Aggressive Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            "The Fund seeks capital appreciation over the long term. The Fund also considers the potential for current income. The Fund invests in stocks, bonds, money market instruments, and other instruments including derivatives. It has a target asset class allocation of approximately 80% equity securities and 20% fixed-income securities. The active management of the Fund's asset allocation may involve the extensive use of equity and fixed-income ETFs. The Fund may also invest in non-U.S. securities, below-investment-grade bonds and REITs.",
    },
    {
        fundCode: '78',
        symbol: 'USCCX',
        fundName: 'USAA Cornerstone Conservative',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Conservative Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is current income but the Fund also considers the potential for capital appreciation. The fund invests in a selection of affiliated mutual funds and ETFs consisting of a target asset class allocation of approximately 20% equity securities and 80% fixed-income securities.",
    },
    {
        fundCode: '79',
        symbol: 'UCEQX',
        fundName: 'USAA Cornerstone Equity',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Equity Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is capital appreciation over the long term. The Fund will normally invest at least 80% of the Fund's assets in a selection of affiliated mutual funds and ETFs that invest primarily in equity securities. The underlying affiliated funds consist of a long-term target asset allocation in equity securities. This is often referred to as a fund-of-funds investment strategy. This 80% policy may be changed upon at least 60 days' written notice to shareholders.",
    },
    {
        fundCode: '47',
        symbol: 'USBSX',
        fundName: 'USAA Cornerstone Moderate',
        fundFactPdf: '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Moderate Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is high total return. The Fund will invest in stocks, bonds, money market instruments and other instruments. The fund may invest in foreign securities, junk bonds, ETFs, REITs, futures and options and other types of derivatives. The Fund will have a target asset class allocation of approximately 50% equity securities and 50% fixed-income securities.",
    },
    {
        fundCode: '51',
        symbol: 'USCRX',
        fundName: 'USAA Cornerstone Moderately Aggressive',
        fundFactPdf:
            '/content/dam/vcm/usaamf/factsheet/member-factsheet/USAA Cornerstone Moderately Aggressive Fund DM FS.pdf',
        disclosure: 'USAACornerstoneFundGroupDisclosure',
        summaryText:
            "The fund's investment objective is capital appreciation with a secondary focus on current income. The Fund will invest in stocks, bonds, money market instruments, and other instruments. The fund may invest in foreign securities, junk bonds, ETFs, REITs, futures and options and other types of derivatives. The Fund will have a target asset class allocation of approximately 60% equity securities and 40% fixed-income securities.",
    },

    {
        fundName: 'Victory Floating Rate',
        symbol: 'RSFMX',
        fundCode: '200',
        fundFactPdf: '',
        disclosure: 'VictoryFloatingRateFundDisclosure',
        summaryText:
            'The Fund’s management team seeks to provide higher current income than most short-term investments available to retail investors, while also providing some protection from rising interest rates and inflation by investing primarily in floating rate bank loans. Loans have adjustable rates, are secured by liens on assets, and are senior to bonds and other unsecured claims. The Fund may also hold select bonds and other floating rate investments of domestic or foreign issuers. The Fund’s holdings are typically U.S.-based and below investment grade. The Fund’s benchmark is the S&P/LSTA Leveraged Loan Index.',
    },
    {
        fundName: 'Victory High Income Municpal Bond',
        symbol: 'RHMMX',
        fundCode: '201',
        fundFactPdf: '',
        disclosure: 'VictoryHighIncomeMuncipalBondFundDisclosure',
        summaryText:
            'The Fund’s management team invests primarily in municipal bonds in an effort to maximize current income exempt from federal income taxes with a secondary objective of capital appreciation. The Fund invests primarily in bonds at the lower end of the investment-grade ratings spectrum and may also invest in below investment grade or unrated bonds. The Fund’s primary benchmark is the Bloomberg Barclays Municipal Bond Index. Its secondary benchmark is the Bloomberg Barclays High Yield Municipal Bond Index.',
    },
    {
        fundName: 'Victory INCORE for Income',
        symbol: 'VFFMX',
        fundCode: '202',
        fundFactPdf: '',
        disclosure: 'VictoryIncoreFundDisclosure',
        summaryText:
            'The Victory Fund for Income fund seeks to provide a high level of current income, consistent with the preservation of investors’ capital, by investing primarily in securities issued by the U.S. Government and its agencies or instrumentalities. Our performance goal is to outperform the Bloomberg Barclays 1-5 Year U.S. Government Bond Index over a full market cycle with less risk.',
    },
    {
        fundName: 'Victory INCORE Investment Grade Convertibles',
        symbol: 'SBFMX',
        fundCode: '203',
        fundFactPdf: '',
        disclosure: 'VictoryIncoreInvestmentGradeFundDisclosure',
        summaryText:
            'The Victory Investment Grade Convertible fund’s approach is centered on bottom up research and has remained consistent over the last 25 years.  The objective is to provide a high level of current income together with long-term capital appreciation and achieve superior risk-adjusted returns over full market cycles utilizing high quality convertible securities.  We seek to invest in convertible securities which possess: 1)strong underlying equity potential, 2)solid fixed income fundamentals, and 3)compelling convertible characteristics.  Our performance goal is to outperform the ICE BofAML Investment Grade US Convertible 5% Constrained Index (VX5C).',
    },
    {
        fundName: 'Victory Integrity Mid-Cap Value',
        symbol: 'MMIJX',
        fundCode: '205',
        fundFactPdf: '',
        disclosure: 'VictoryIntegrityMidCapValueFundDisclosure',
        summaryText:
            'The Victory Integrity Mid-Cap Value Fund’s management team seeks to achieve capital appreciation in the Fund by investing in stocks of medium sized companies (according to their market capitalization) that the team believes are undervalued, yet poised to outperform. The Fund generally invests in companies with market capitalizations within the range of the companies of the Russell Midcap® Index.',
    },
    {
        fundName: 'Victory Integrity Small/Mid-Cap Value',
        symbol: 'MMMSX',
        fundCode: '206',
        fundFactPdf: '',
        disclosure: 'VictoryIntegritySmallMidCapValueFundDisclosure',
        summaryText:
            "The Victory Integrity Small/Mid-Cap Value Fund’s management team seeks to achieve capital appreciation in the Fund by investing in stocks of small and medium sized companies (according to their market capitalization) that the team believes are undervalued, yet poised to outperform. The Fund generally invests in companies with market capitalizations within the range of the companies in the Russell 2500™ Index.",
    },
    {
        fundName: 'Victory Market Neutral Income',
        symbol: 'CBHMX',
        fundCode: '207',
        fundFactPdf: '',
        disclosure: 'VictoryMarketNeutralIncomeFundDisclosure',
        summaryText: 'The Fund seeks to achieve high current income.',
    },
    {
        fundName: 'Victory RS Mid Cap Growth',
        symbol: 'RMOMX',
        fundCode: '208',
        fundFactPdf: '',
        disclosure: 'VictoryRSMidCapGrowthFundDisclosure',
        summaryText:
            'The RS Growth Team seeks to deliver long-term capital growth through a disciplined, research-intensive approach that discovers and invests in dynamic, mid-cap companies exhibiting sustainable earnings growth potential that can grow to large-cap companies.',
    },
    {
        fundName: 'Victory RS Partners',
        symbol: 'RSPMX',
        fundCode: '209',
        fundFactPdf: '',
        disclosure: 'VictoryRSPartnersFundDisclosure',
        summaryText:
            'The Victory RS Partners Fund seeks to produce superior long-term risk adjusted returns by investing in durable small capitalization companies that are led by strong management teams and are attractively valued.',
    },
    {
        fundName: 'Victory RS Small Cap Equity',
        symbol: 'RSMMX',
        fundCode: '210',
        fundFactPdf: '',
        disclosure: 'VictoryRSSmallCapEquityFundDisclosure',
        summaryText:
            'The RS Growth Team seeks to deliver long-term capital growth through a disciplined, research-intensive approach that discovers and invests in dynamic, small-cap companies exhibiting sustainable earnings growth potential that can grow to mid- and large-cap companies.',
    },
    {
        fundName: 'Victory Integrity Discovery Fund',
        symbol: 'MMMMX',
        fundCode: '204',
        fundFactPdf: '',
        disclosure: 'VictoryIntegrityDiscoveryFundDisclosure',
        summaryText:
            'The Victory Integrity Discovery Fund’s management team seeks capital appreciation in the Fund by investing in a diversified portfolio of micro-cap companies that the management team feels are undervalued, but poised to outperform. Micro-cap companies are companies with market capitalizations lower than the largest company in the bottom 75% (based on index weightings) of the Russell 2000® Index.',
    },
];

export default disclosureData;
