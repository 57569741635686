const styles = {
    pullLeft: {
        float: 'left',
    },
    pullLeftMargin: {
        float: 'left',
        marginTop:'18px'
    },
    pullRight: {
        float: 'right',
    },
    AccountsName:{
        font: `800 1.375rem / 1.5rem yorkten-slab-normal`,
        color: 'rgb(72, 109, 144)',
    },
    dynamicName:{
        font: `800 1.375rem / 1.5rem yorkten-slab-normal`,
        color: '#CF481E',
    },
    clear: {
        clear: 'both',
        display: 'table',
    },
    amount: {
        font: `700 30px/22px benton-sans,sans-serif`
    },
    amountnew: {
        font: `700 24px/22px benton-sans,sans-serif`
    },
    negMarginTop: {
        marginTop: '20px',
    },
    titleTxt: {
        font: `500 0.875rem/0.625rem benton-sans,sans-serif`,
        color: '#4949A',
    },
    titleTxtnew: {
        font: `500 0.8rem/0.8rem benton-sans,sans-serif`,
        color: 'rgb(73, 73, 74)',
    },
    balanceStyles:{
        display:'flex',
        alignItems:'center'
    },
    openAccountBTnColStyle:{
        top:-17
    },
    balanceTileStyle:{
        maxHeight:'3.571em'
    }
};

export default styles;
