import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Separator from '../Separator';

const FlexDiv = styled.div`
    margin: 1.25rem;
`;

const DateSeprator = styled.span`
    margin: 0 0.5em;
`
const fromDateLabel = 'From: ';
const toDateLabel = 'To: ';
const separatorString = '|';

const SeasonalData = props => {
    const {fromDateValue, toDateValue} = props;
    return (
        <>
            <Separator />
            <FlexDiv className="dateAlign" data-mt={0.9375} data-mb={0.9375}>
                {fromDateLabel}{fromDateValue}<DateSeprator>{separatorString}</DateSeprator>{toDateLabel}{toDateValue}
            </FlexDiv>
        </>
    )
}

SeasonalData.propTypes = {
    fromDateValue: PropTypes.string,
    toDateValue: PropTypes.string,
};

SeasonalData.defaultProps = {
    fromDateValue: '',
    toDateValue: ''
};

export default SeasonalData;