import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import ApiEventHub from './ApiEventHub';
import { addAPIVersionHeader, get, setMSRAuthHeaders } from './Services';
import Config from 'env/Config';

const SWPEvents = () => {
  ApiEventHub.on(ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_LOADING, data => {
    const xApikey = Config.msrTransactionKey;
    const {accesstoken, vcmid, msrid, fismemberid = "" } = data.payload.msrParams;
    setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid);
    addAPIVersionHeader('2023-10-06')
    get(
          Config.msrSWPTransactions,
          '',
          data.endPointURL.getMsrAccountOptions,
          data.successCallBack,
          data.errorCallback,
      );
    });
};

export default SWPEvents;
