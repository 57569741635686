import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import styles from '../CommonStyles';
import './WFileUploader.css';

const assignObj = obj => {
    return obj;
};

export default class WFileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = { base64filesArr: [], uploadedFilesArr: [] };
        this.Input = this.Input.bind(this);
        this.prevFile = null;
    }

    componentDidMount = () => {
        // const { existingFiles = [] } = this.props;
        // const { uploadedFilesArr = [] } = this.state;
        // console.log(uploadedFilesArr)
        // if (existingFiles.length >= 0) {
        //     this.setState({ uploadedFilesArr: existingFiles,base64filesArr:[] });
        // }
    };

    static getDerivedStateFromProps = (nextProps, prevState) => {
        //     const {existingFiles} = nextProps;
        //     const {uploadedFilesArr=[]}=prevState
        //     if (existingFiles.length>0) {
        //         this.prevFile=existingFiles[+0]
        //         return {
        //             uploadedFilesArr:existingFiles
        //         };
        //     }
        //     return {}
        const inputElem = document.getElementsByClassName('inputFileBrowse')[0];
        const labelelem = document.getElementsByClassName('browseLabel')[0];
        if (inputElem) {
            inputElem.addEventListener('focus', () => {
                labelelem.classList.add('focused');
            });
            inputElem.addEventListener('blur', () => {
                labelelem.classList.remove('focused');
            });
        }
        return {};
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        // const { existingFiles = [] } = nextProps;
        const { uploadedFilesArr = [] /*prevFile = []  , prevFileLoaded = false */ } = nextState;
        /* let fileUpdate = false; */
        // if (uploadedFilesArr && uploadedFilesArr.length > 0 && existingFiles && existingFiles.length > 0) {
        //     for (let i = 0; i < uploadedFilesArr.length; i += 1) {
        //         if (uploadedFilesArr[+i].name === existingFiles[0].name) {
        //             fileUpdate = true;
        //         }
        //     }
        //     if (fileUpdate) {
        //         return false;
        //     }
        // }
        // if (uploadedFilesArr && uploadedFilesArr.length > 0 && existingFiles && existingFiles.length > 0 && uploadedFilesArr[0].name === existingFiles[0].name) {
        //     return false;
        // }

        if (this.prevFile !== null && this.prevFile === uploadedFilesArr[0]) {
            return false;
        }

        return true;
    };

    // Convert file to base64
    toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.replace('data:', '').replace(/^.*;base64,/, '');
                if (encoded.length % 4 > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {
        const { uploadedFilesArr, base64filesArr } = this.state;
        const { existingFiles, clearInitialFiles, senduploads, name } = this.props;
        if (status === 'done') {
            const fileAlreadyInUploadedArray =
                uploadedFilesArr && uploadedFilesArr.findIndex(item => item.name === file.name) > -1;
            const fileAlreadyInExistingArray =
                existingFiles && existingFiles.findIndex(item => item.name === file.name) > -1;
            if (
                // (this.prevFile!==null && this.prevFile.name!==existingFiles[0].name ) ||
                !fileAlreadyInUploadedArray ||
                !fileAlreadyInExistingArray
            ) {
                this.prevFile = file;
                uploadedFilesArr.push(this.prevFile);
                const encodedFile = this.toBase64(file);
                encodedFile.then(data => {
                    const base64Object = {
                        base64String: data,
                        fileName: meta.name,
                    };
                    base64filesArr.push(base64Object);
                    if (base64filesArr.length > 0) {
                        const original = `original${name}`;
                        senduploads({
                            [name]: base64filesArr,
                            [original]: file,
                        });
                    }
                });
            }
        }
        if (status === 'removed') {
            const index = uploadedFilesArr.findIndex(item => item.name === meta.name);
            const base64Index = base64filesArr.findIndex(item => item.fileName === meta.name);
            // const index =
            //     uploadedFilesArr &&
            //     uploadedFilesArr.map((f, i) => {
            //         if (f.name === meta.name) {
            //             return i;
            //         }
            //         return null;
            //     });
            if (index > -1 && base64Index > -1) {
                uploadedFilesArr.splice(index, 1);
                base64filesArr.splice(base64Index, 1);
                this.setState({ uploadedFilesArr, base64filesArr });
                clearInitialFiles();
            }
        }
    };

    handleChange = (e, onFiles) => {
        this.getFilesFromEvent(e).then(chosenFiles => {
            onFiles(chosenFiles);
        });
    };

    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject));
            });
        });
    };

    Input = ({ accept, onFiles, files }) => {
        const { id, name, describedby } = this.props;
        // debugger
        // var unique = [...new Set(files.map(p => p.meta.name))];
        // files.filter((v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i);
        //  const uniqueFiles = Array.from(new Set(files.map(JSON.stringify))).map(JSON.parse);
        const uniques = Object.values(
            files.reduce((a, c) => {
                a[c.meta] = c;
                return a;
            }, {}),
        );
        const text = uniques.length > 0 ? '' : 'Browse';
        return (
            <label
                style={styles.uploadLabelStyle}
                htmlFor={id}
                className="browseLabel"
                role="button"
                aria-describedby={describedby}
            >
                {text}
                <input
                    className="sr-only inputFileBrowse"
                    type="file"
                    id={id}
                    name={name}
                    accept={accept}
                    onChange={assignObj(e => this.handleChange(e, onFiles))}
                />
            </label>
        );
    };

    render() {
        const { accept, dropZoneStyle, previewStyle, minSizeBytes, maxSizeBytes, existingFiles, id, name } = this.props;
        let fileR = null;
        if (existingFiles !== undefined && existingFiles !== null && existingFiles.length > 0) {
            if (existingFiles[0] !== null && existingFiles[0] !== undefined) {
                fileR = existingFiles[+0];
            }
        }
        return (
            <div className="container dropZoneContainer" style={assignObj({ padding: 0, margin: 0 })}>
                <Dropzone
                    id={id}
                    name={name}
                    onChangeStatus={this.handleChangeStatus}
                    InputComponent={this.Input}
                    accept={accept}
                    minSizeBytes={minSizeBytes}
                    maxSizeBytes={maxSizeBytes}
                    getFilesFromEvent={this.getFilesFromEvent}
                    styles={assignObj({
                        dropzone: assignObj({ ...styles.dropZoneStyle, ...dropZoneStyle }),
                        preview: assignObj({ ...styles.previewStyle, ...previewStyle }),
                    })}
                    initialFiles={fileR !== null && fileR !== undefined ? assignObj([fileR]) : undefined}
                />
            </div>
        );
    }
}

WFileUploader.defaultProps = {
    accept: 'image/*,audio/*,video/*',
    senduploads: null,
    dropZoneStyle: {},
    previewStyle: {},
    id: 'fileInput',
    maxSizeBytes: 1e7, // 10MB
    minSizeBytes: 0,
    clearInitialFiles: () => {},
    // initialFiles: new File(['Dummy1', 'dummy2'], 'dummy.text'),
    existingFiles: [],
    name: '',
    describedby: '',
};

WFileUploader.propTypes = {
    accept: PropTypes.string,
    id: PropTypes.string,
    senduploads: PropTypes.func,
    dropZoneStyle: PropTypes.instanceOf(Object),
    previewStyle: PropTypes.instanceOf(Object),
    maxSizeBytes: PropTypes.number,
    minSizeBytes: PropTypes.number,
    // initialFiles: PropTypes.instanceOf(File),
    clearInitialFiles: PropTypes.func,
    existingFiles: PropTypes.instanceOf(Array),
    name: PropTypes.string,
    describedby: PropTypes.string,
};
