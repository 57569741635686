/* eslint-disable import/prefer-default-export */
import {
  RESET_PRE_REGISTRATION_INFO,
  OTP_VERIFICATION_FAILED,
  OTP_VERIFICATION_SUCCESS,
  SET_EMAIL_OTP,
} from 'shared/ReduxConstants/AccountRegistrationConstants';

import {
  API_VERIFY_PHONE_OTP,
} from 'shared/ReduxConstants/ServiceActionConstants';


import accountRegistrationAPICall from '../service/AccountRegistrationService';
import { errorCodeMessageMapping } from '../components/constants';
import { dispatchLoading } from '../utils.actions';

const dispatchWithData = (type, payload) => (dispatch) => dispatch({
  type,
  payload,
});

const dispatchValidatePasscode = (errorMsg) => (dispatch) => {
  dispatchWithData(OTP_VERIFICATION_FAILED, {
    otpValidationErrorMsg: errorMsg,
    otpValidationSuccess: false,
    validatePasscode: false,
  })(dispatch);
}

const getErrorMsg = (response) => {
  let errorMsg = 'Incorrect passcode, please try again or request a new passcode';
  if (response.data.errorCode === 2027) {
    errorMsg = 'Please generate a new one time passcode.';
  } else if (response.data.errorCode !== 2028) {
    errorMsg = response.data
      ? errorCodeMessageMapping[response.data.errorCode]
        || response.data.result
      : 'Error Occured while validating One Time passcode';
  }
  return errorMsg;
}

export const validatePasscode = (data) => (dispatch) => {
  if (data === '') return;
  dispatchLoading('SSNAuth')(dispatch);

  accountRegistrationAPICall(
    API_VERIFY_PHONE_OTP,
    data,
    (response) => {
      if (response.status === 200 && response.data) {
        if (response.data.errorCode !== 0) {
          const errorMsg = getErrorMsg(response);
          dispatchValidatePasscode(errorMsg)(dispatch);
        } else if (response.data.errorCode === 0) {
          dispatchWithData(OTP_VERIFICATION_SUCCESS, {
            otpValidationErrorMsg: '',
            otpValidationSuccess: true,
            validatePasscode: false,
          })(dispatch);
        }
      }
    },
    (error) => {
      const errorMsg = error.data
        ? error.data.result
        : 'Error Occured while Request Security Code';
        dispatchValidatePasscode(errorMsg)(dispatch);
    },
  );
};

export const resetPreRegistrationInfo = () => (dispatch) => {
  dispatch({
    type: RESET_PRE_REGISTRATION_INFO,
  });
};

export const setEmailOTP = (data) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_OTP,
    payload: {
      OTP: data,
      emailOTPError: '',
      emailError: '',
    },
  });
};
