export const questionType = {
  radio:'radio',
  birthDate: 'birthDate'
}

const linkBeneficiaries = {
  linkLabel: `I'm not sure. View my beneficiaries.`,
  linkRoute: '/beneficiaries',
}

const endAnswer = {
  question: "",
  type: "",
  linkInfo: null,
  answers:[]
}

export const endAnswerRedirect = {
  selectAFund: {
    route: 'selectAFound',
    info: {
     allFounds: false,
    }
  },
  selectAllFounds: {
    route: 'selectAFound',
    info: {
     allFounds: true,
    }
  },
  downloadTheForm: {
    route: 'downloadTheForm',
    info: {
    }
  },
  rmdDIY: {
    route: '/rmdDIY',
    info:{
    }
  }
}

const chatAnswer = {
  label: "I am not sure. I prefer to talk to someone.",
  onSelect: {
    showChat: true
  }
}

export const MasterRegLevelAnswer = 'Let me choose the fund.';


const selectFundQuestion = {
  question: 'Which funds should we use to withdraw money?',
  type: questionType.radio,
  linkInfo: null,
  answers: [{
        id: 'a1',
        label: 'All funds, proportionately.',
        ...endAnswer,
        selectedAnswerLiteral: 'Withdrawal from all funds.',
        redirectTo: endAnswerRedirect.selectAllFounds,
        isMasterRegLevel: false,
      },
      {
        id: 'a2',
        label: MasterRegLevelAnswer,
        selectedAnswerLiteral: 'Withdrawal from a fund.',
        ...endAnswer,
        redirectTo: endAnswerRedirect.selectAFund,
        isMasterRegLevel: true,
      },
      {
        id: 'a3',
        ...chatAnswer,
        ...endAnswer,
      }
  ]
}

export const rmdAnswerQuestions = {
  id:'root',
  question: "Would you like us to calculate your distribution ?",
  type: questionType.radio,
  answers:[
    {
      id:'a1',
      label: "Yes, then let me review it.",
      question: "Is your spouse your sole primary beneficiary?",
      selectedAnswerLiteral: "Let Victory Capital calculate the distribution",
      type: questionType.radio,
      linkInfo:{
        ...linkBeneficiaries
      },
      answers:[
        {
          id:'a11',
          label: "Yes",
          question: "What is your spouse's birthday?",
          selectedAnswerLiteral: 'Spouse is the sole primary beneficiary',
          type: questionType.birthDate,
          answers:[{
            id: 'a111',
            label: '',
            selectedAnswerLiteral: 'Selected Birth date: ',
            ...selectFundQuestion
          }],
        },
        {
          id:'a12',
          label: "No",
          question: "Is your primary beneficiary a trust?",
          selectedAnswerLiteral:`Spouse is not the sole primary beneficiary`,
          type: questionType.radio,
          linkInfo: {
            ...linkBeneficiaries
          },
          answers:[ 
            {
              id:'a1',
              label: "Yes",
              selectedAnswerLiteral:`Primary beneficiary is a trust`,
              question:"Are you married?",
              type: questionType.radio,
              answers:[{
                  id:'a1',
                  label: "Yes",
                  selectedAnswerLiteral:`Married`,
                  ...endAnswer,
                  redirectTo: endAnswerRedirect.downloadTheForm,
                },
                {
                  id:'a2',
                  label: "No",
                  selectedAnswerLiteral:`Not Married`,
                  ...selectFundQuestion,
                }
            ]},
            {
              id:'a2',
              label: "No",
              selectedAnswerLiteral:`Primary beneficiary is not a trust`,
              ...selectFundQuestion
            }
          ]   
        }
      ],
    },
    {
      id:'a2',
      label: "No, I want to do it myself.",
      ...endAnswer,
      redirectTo: endAnswerRedirect.rmdDIY
    },
    {
      id:'a3',
      ...chatAnswer,
      ...endAnswer
    }
  ]
}