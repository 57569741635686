import React from "react";
import PropTypes from "prop-types";
import { Col, Container } from 'react-bootstrap';
import {
    maskEmail,
} from "utils";
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import routeConstants from "routeConstants";
import CONST from 'modules/CustomerManagementModule/EmailInformation/constants';
import { BottomNavBar, PageTitle, CardWithMenu, AddCard, CardDeck } from 'modules/CustomerManagementModule/components';

const MenuOptions = [{name:"Edit", icon:editIcon},{name:"Delete", icon:deleteIcon}];

const backBtnProps = {};

export default class RelationshipEmailInfoComponent extends React.Component {
    
    componentDidMount() {
        this.clearFlags();
    }

    componentDidUpdate(){
       this.clearFlags();
    }
    
    clearFlags = ()=> {
        const { profileRelationshipData, clearRelationActions } = this.props;
        const { isError, isSuccess } = profileRelationshipData;
        if(isSuccess || isError){
            clearRelationActions();
        }
    }

    cancel = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipemailinfo_back`
        })
        history.push(routeConstants.manageRelations);
    };

    deleteCard = (emailObj) => (e) => {
        e.stopPropagation();
        const { updateProfileRelationship } = this.props;
        const { isPrimary=false, mailId='' } = emailObj || {};
        const primarypayLoad = {
            role : "Family",
            relationshipId:mailId,
            primaryEmail: "",
        };
        const secondarypayLoad = {
            role : "Family",
            relationshipId:mailId,
            secondaryEmail: "",
        };
        const deleteId = isPrimary ? primarypayLoad : secondarypayLoad;
        updateProfileRelationship({ ...deleteId, isDeleteAPICalled: true});
    };

    editEmail = (emailObj) => (e) => {
        e.stopPropagation();
        const { history } = this.props;
        history.push(routeConstants.relationshipeditEmail, emailObj);
    };
    
    onMenuOptionClick = (emailObj, addrIndex) => (menuOption) => (e) => {
        const { analyzeClickEvent } = this.props;
        let addrTypeName = '';
        if(addrIndex === 0){
            addrTypeName = 'Primaryemail';
        }else{
            addrTypeName = 'Secondaryemail';
        }
        if(menuOption === 'Edit'){
            analyzeClickEvent({
                link: `cms_basicinformation_managerelationshipemailinfo_${addrTypeName}_editbutton`
            });
            this.editEmail(emailObj)(e);
        }else if(menuOption === 'Delete') {
            analyzeClickEvent({
                link: `cms_basicinformation_managerelationshipemailinfo_${addrTypeName}_deletebutton`
            });
            this.deleteCard(emailObj)(e);
        }
    }
    
    onAddCardClick = (emailDetails) => (e) => {
        const { analyzeClickEvent } = this.props;
        e.stopPropagation();
        const { history } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipemailinfo_Add${emailDetails.isPrimary?'primary':'secondary'}email`
        });
        history.push(routeConstants.relationshipAddEmailInfo, { ...emailDetails } );
    }

    render() {
        const { profileRelationshipData } = this.props;
        const { familyRelationShipInfo = [], familyMemberIndex } = profileRelationshipData;
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
        const { emailInformation = {}, relationshipId='' } = familyMemberInfo;
        
        const { primaryEmail = '', secondaryEmail = '' } = emailInformation || {};
        const isPrimaryEmailAvailable = primaryEmail ? !!primaryEmail : false;
        const isSecondaryEmailAvailable = secondaryEmail? !!secondaryEmail : false;
        const maskedPrimaryEmail = primaryEmail ? maskEmail(primaryEmail) : '';
        const maskedSecondaryEmail = secondaryEmail ? maskEmail(secondaryEmail) : '';
        const primaryObj = {email: primaryEmail, isPrimary: true, mailId:relationshipId};
        const secondaryObj = {email: secondaryEmail, isPrimary: false, mailId:relationshipId};
        const loading = profileRelationshipData.isLoading;

        backBtnProps.onClick = this.cancel;
        backBtnProps['aria-label'] = "Back to Manage Relation information";

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={CONST.RELATIONSHIP_BREADCRUMB_VALUE} 
                            activeCrumb={CONST.PAGE_NAME_MANAGE_EMAIL}
                        />
                        <PageTitle
                            title={CONST.PAGE_NAME_MANAGE_EMAIL} 
                        />
                        <CardDeck data-mt={2.5} data-mb={7} >
                            <Col md={6} lg={4}>
                                {isPrimaryEmailAvailable ? <CardWithMenu 
                                    cardTitle = { CONST.PRIMARY_EMAIL }
                                    Line1 ={maskedPrimaryEmail}
                                    extradetail = ''
                                    // menu
                                    menuOptions={MenuOptions}
                                    onMenuOptionClick={this.onMenuOptionClick(primaryObj,0)}
                                />: (
                                <AddCard
                                    minHeight={11.25}
                                    addTxt={CONST.ADD_PRIMARY_EMAIL_TEXT}  
                                    onAddCardClick = {this.onAddCardClick({ isPrimary: true })} 
                                />
                                )}
                            </Col>
                            <Col md={6} lg={4}>
                                {isSecondaryEmailAvailable ? <CardWithMenu 
                                    cardTitle = { CONST.SECONDARY_EMAIL }
                                    Line1 ={maskedSecondaryEmail}
                                    extradetail = ''
                                    // menu
                                    menuOptions={MenuOptions}
                                    onMenuOptionClick={this.onMenuOptionClick(secondaryObj,1)}
                                />: (
                                <AddCard
                                    minHeight={11.25}
                                    addTxt={CONST.ADD_SECONDARY_EMAIL_TEXT}  
                                    onAddCardClick = {this.onAddCardClick({ isPrimary: false })} 
                                />
                                )}
                            </Col>
                        </CardDeck>
                    </Container>
                    <BottomNavBar
                        hidePrimaryBtn
                        backBtnProps={backBtnProps}
                    />
            </>
        );
    }
}

RelationshipEmailInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    profileRelationshipData: PropTypes.instanceOf(Object),
    clearRelationActions: PropTypes.func,
    updateProfileRelationship: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
};

RelationshipEmailInfoComponent.defaultProps = {
    profileRelationshipData: {},
    clearRelationActions: ()=>{},
    updateProfileRelationship: ()=>{},
    analyzeClickEvent: ()=>{},
};
