/**
 * ESA Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to provide Employment Service Agreement
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { WStepper, WSpinner, WSaveModal, WErrorModal, WButton } from '../../../common';
import Consts from './consts';

const PageWrapper = styled.div`
    background-color: #f9fbfc;
    min-height: 100vh;
    position: relative;
    padding: 60px 10px;
`;

const PageHeader = styled.h2`
    padding-left: 90px;
    padding-top: 30px;
    font-weight: Bold;
    font-family: Benton Sans;
    font-size: 26px/22px;
    color: #56565a;
`;

const ContentWrapper = styled.div`
    height: 60px;
    padding-right: 67px;
    padding-left: 100px;
    padding-top: 10px;
`;

const CardWrapper = styled.div`
    position: relative;
    padding: 60px 80px 0px 100px;
`;

const CardRow = styled(Row)`
    margin-right: 0;
    border-bottom: 1px solid #707070;
`;

const SideHeading = styled.h6`
    font: 700 18px/35px Roboto Slab;
    color: #56565a;
    text-align: left;
`;
const CardSubHeading = styled(Row)`
    font: 400 16px/22px Benton Sans;
    color: #56565a;
    margin-bottom: 15px;
`;
const CardHeadingText = styled(Col)`
    font: 200 14px/17px Benton Sans;
    color: #333333de;
    letter-spacing: 0;
`;
const ImportantText = styled.span`
    font: 700 14px/17px Benton Sans;
    color: #333333de;
    opacity: 0.75;
    letterspacing: 0;
`;
const ReadMoreText = styled.span`
    font: 200 14px/17px Benton Sans;
    color: #333333de;
    letterspacing: 0;
`;

const HorizontalRule = styled.hr`
    margin-top: 0px;
    border: 1px solid #707070;
    opacity: 0.25;
`;
const ReadNote = styled.div`
    margin-top: 37px;
    margin-bottom: 42px;
`;
const ReadNoteText = styled.label`
    font: 700 14px/17px Benton Sans;
    color: #333333de;
    opacity: 0.75;
    letterspacing: 0;
`;
const AgreeText = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;
const ButtonWrapper = styled(Row)`
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
`;
const DocumentSection = styled.div`
    background: #eceeef 0% 0% no-repeat padding-box;
    border: 1px solid #cfd1d2;
`;
const DocumentHeader = styled.div`
    margin-top: 12px;
    margin-left: 16px;
    margin-right: 13px;
    border-bottom: 1px solid;
    border-color: #333333de;
`;
const DocumentHeading = styled.h5`
    font: 600 15px/18px Benton Sans;
    color: #333333de;
    opacity: 1;
`;
const DocumentSubtext = styled.p`
    font: 200 14px/17px Benton Sans;
    color: #333333de;
    opacity: 0.75;
`;
const DocumentBody = styled.div`
    float: right;
    font: 400 14px/17px Benton Sans;
    color: #5d83ae;
    letter-spacing: 0;
    margin-top: 25px;
    margin-right: 30px;
`;
const StyledButton = styled.button`
    background: none;
    border: none;
    color: #5d83ae;
`;
const DocumentBodyWrap = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    padding-left: 12px;
    border: 1px solid #c4c4c4;
    margin: 44px 34px 13px 34px;
`;
const DocSection1 = styled.h5`
    margin: 10px 24px 10px 0px;
    font: 700 16px/19px Benton Sans;
    color: #333333de;
`;
const DocSection1text = styled.p`
    font: 400 14px/17px Benton Sans;
    color: #333333de;
    opacity: 0.75;
`;
const DocumentHeading2 = styled.h5`
font:font:700 14px/17px Benton Sans;color:#333333DE
`;
const FooterWrap = styled.div`
    padding-top: 13px;
    padding-bottom: 22px;
`;
const ESACard = styled.div`
    margin: 50px 0 10px;
    padding-bottom: 100px;
`;
export default class ESAComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
            cancel: false,
            error: false,
            showSaveModal: false,
            loading: false,
            pageHeading: '529 Account',
            cards: [
                {
                    cardId: 'esa',
                    linkText: '',
                    cardHeading: 'ESA (Electronic Services Agreement)',
                    cardSubHeading:
                        'You must complete the Electronic Services Agreement before you will be able to complete any financial transactions in your Investment account(s) on vcm.com or on the VCM Self-Service Telephone System.',
                },
            ],
        };
    }

    handleNextClick = () => {
        this.setState({ next: true }, () => {
            this.saveToLocal();
        });
    };

    saveToLocal = () => {
        const { manageLocalState } = this.props;
        const { signedDoc } = this.state;
        const data = {
            esaInfo: {
                signedDocument: signedDoc,
                pageName: 'application1',
            },
        };
        manageLocalState(data);
    };

    handleSave = () => {
        this.setState({ showSaveModal: true });
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleClick = () => {};

    handleDocClick = () => {
        this.setState({ signedDoc: true });
        window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
    };

    renderDoc = () => {
        const or = '|';
        return (
            <DocumentSection>
                <DocumentHeader>
                    <DocumentHeading>{Consts.contentHeading}</DocumentHeading>
                    <DocumentSubtext>{Consts.contentSubtext}</DocumentSubtext>
                </DocumentHeader>
                <DocumentBody>
                    <StyledButton onClick={this.handleDocClick}>{Consts.saveText}</StyledButton> {or}{' '}
                    <StyledButton onClick={this.handleDocClick}>{Consts.printText}</StyledButton>
                </DocumentBody>
                <DocumentBodyWrap>
                    <DocSection1>{Consts.vcmText}</DocSection1>
                    <DocSection1text>{Consts.readText}</DocSection1text>
                    <DocumentHeading2>{Consts.imcoText}</DocumentHeading2>
                    <DocSection1text>{Consts.contactText}</DocSection1text>
                </DocumentBodyWrap>
                <FooterWrap>
                    <WButton
                        buttontext="Sign this Document"
                        buttonstyle={Consts.buttonStyle}
                        buttontextstyle={Consts.buttontextstyle}
                        onClick={this.handleDocClick}
                    />
                </FooterWrap>
            </DocumentSection>
        );
    };

    renderCard = ({ cardHeading, cardSubHeading }, index) => {
        return (
            <CardWrapper key={index}>
                <CardRow>
                    <Col md={7}>
                        <SideHeading>{cardHeading}</SideHeading>
                    </Col>
                </CardRow>
                <HorizontalRule />
                {cardSubHeading && (
                    <CardSubHeading>
                        <CardHeadingText>
                            <span>{cardSubHeading}</span>
                        </CardHeadingText>
                    </CardSubHeading>
                )}
                <div>
                    <ImportantText>{Consts.importantText}</ImportantText>
                    <ReadMoreText>{Consts.readMoreText}</ReadMoreText>
                </div>
                <ReadNote>
                    <ReadNoteText>{Consts.readNote}</ReadNoteText>
                </ReadNote>
                {this.renderDoc()}
                <AgreeText>
                    <ReadNoteText>{Consts.agreeText}</ReadNoteText>
                </AgreeText>
                <HorizontalRule />
                <ButtonWrapper>
                    <Col xs={2}>
                        <WButton
                            variant="outline"
                            buttontext="Cancel"
                            onClick={this.handleClick}
                            buttonstyle={Consts.buttonstyle2}
                        />
                    </Col>
                    <Col xs={2}>
                        <WButton
                            buttontext="I Agree"
                            onClick={this.handleNextClick}
                            buttonstyle={Consts.buttonstyle2}
                        />
                    </Col>
                </ButtonWrapper>
            </CardWrapper>
        );
    };

    renderPage = (pageHeading, cards, { error, showSaveModal }) => (
        <>
            <PageWrapper>
                <PageHeader>{pageHeading}</PageHeader>
                <ContentWrapper className="container">
                    <WStepper style={Consts.stepperStyle} currentPage={1} pages={Consts.pages} />
                </ContentWrapper>
                <WErrorModal show={error} message="Technical error" />
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                <ESACard>
                    <div>{this.renderCard(cards[0])}</div>
                </ESACard>
            </PageWrapper>
            <hr />
        </>
    );

    render() {
        const { pageHeading, cards, loading, error, showSaveModal, next, cancel } = this.state;
        return (
            <div className="MainContainer">
                <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                {error && <WErrorModal show={error} message="Technical error" />}
                {<WSpinner loading={loading} />}
                {!error && this.renderPage(pageHeading, cards, this.state)}
                {next ? <Redirect to={Consts.redirect} /> : ''}
                {cancel ? <Redirect to="/accountType" /> : ''}
            </div>
        );
    }
}

ESAComponent.propTypes = {
    manageLocalState: PropTypes.func,
};

ESAComponent.defaultProps = {
    manageLocalState: () => {},
};
