import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
// import LoadingOverlay from 'react-loading-overlay';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
// import routeConstants from 'routeConstants';

import Guidelines from 'modules/UserManagementAndSecurityModule/Guidelines';
import checkFormat from 'modules/UserManagementAndSecurityModule/checkFormat';
import { ConfirmPassword } from 'modules/UserManagementAndSecurityModule/Input/ConfirmPassword';
import { Password } from 'modules/UserManagementAndSecurityModule/Input/Password';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper, WSpinner } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles';
import data from './Constants';
import pages from '../JovConstants';



class RegisterLoginCredentials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      upperLower: false, min: false, symbol: false, numeral: false,
      cancelled: false,
      formIsValid: false,
      form: {
        password: {
          value: '',
          errMsg: null,
          valid: false,
          label: 'Password',
          rules: {
            // minLength: 8,
            maxLength: 16,
            pattern: userConstants.passwordRegex,
            patternMessage: userConstants.passwordMessage,
            required: true
          }
        },
        confirmPassword: {
          value: '',
          errMsg: null,
          valid: false,
          label: 'Confirm Password',
          rules: {
            equal: true,
            equalMessage: 'Both passwords do not match',
            required: true
          }
        }
      },
      loading: false
    };
    
    this.onBack = this.onBack.bind(this);
  
    this.onNext = this.onNext.bind(this);
  
    this.onChange = this.onChange.bind(this);
  
    this.onBlur = this.onBlur.bind(this);

  }

  onChange(event, fieldId) {
    checkFormat(event, fieldId, this);
    inputChangedHandler(event, fieldId, this);
  }

  onBlur(fieldId) { inputValidityHandler(fieldId, this); }

  onBack() {
    this.setState((state) => ({ ...state, cancelled: true }));
  }



  onNext() {
    const { formIsValid } = this.state;
    const { history } = this.props;
    if (formIsValid) {


      history.push({
        pathname: '/sign-in',
        message: { message: <span>{data.your}<strong>{data.id}</strong>{data.created}</span> }
      })
    }
  }

  render() {
    const { form, cancelled, formIsValid, loading } = this.state;

    return (
      <div>
        <WSpinner loading={loading} />
      
        <div className="container">
          <WStepper className="userWizard" currentPage={5} pages={pages} />
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={data.title} />
                <Guidelines state={this.state} type="password" />
            </Col>
            <Col md lg xl>
              <Password
                passwordState={form.password}
                changed={this.onChange}
                blurred={this.onBlur}
                showStrength />
              <ConfirmPassword
                confirmPasswordState={form.confirmPassword}
                changed={this.onChange}
                blurred={this.onBlur} />
            </Col>
          </Row>
        </div>
        <CommonButtons backClick={this.onBack} submit="true" submitClick={this.onNext} disabled={!formIsValid} blockstyle={styles.commonButtonsHalf} arialabelback="Back to Create Online ID" />
        {cancelled ? <Redirect to='/jov-register-online-id' /> : null}
      </div>
    );
  }
}

RegisterLoginCredentials.propTypes = {
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
}

RegisterLoginCredentials.defaultProps = {
  history: []
}

export default RegisterLoginCredentials;