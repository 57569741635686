import { isEmpty } from 'lodash';
import {
  RESOLVED_GET_MEMBER_BY_GUID,
  REJECTED_GET_MEMBER_BY_GUID,
  INVALID_MEMBER_INFO,
} from 'shared/ReduxConstants/AccountRegistrationConstants';

import {
  API_GET_MEMBER_BY_GUID,
} from 'shared/ReduxConstants/ServiceActionConstants';

import { dispatchLoading, dispatchWithData } from '../../utils.actions';
import accountRegistrationAPICall from '../../service/AccountRegistrationService';

const preRegMemberNotFound = 'Preregistration|Welcome|Member not found';

const dispatchSuccess = (isError, isMember, data, result) => (dispatch) => {
  if (isMember) {
    dispatchWithData(RESOLVED_GET_MEMBER_BY_GUID, {
      accountInfo: {},
      user: {},
      pageName: 'Preregistration|Welcome|Member already register',
      isMember: true,
      isError: false,
    })(dispatch);
  } else if (!isMember && !isError) {
    dispatchWithData(RESOLVED_GET_MEMBER_BY_GUID, {
      accountInfo: { ...data },
      isMember: false,
      isError: false,
      user: { ...result },
      pageName:
        'Preregistration|Welcome|Member found but not registered',
    })(dispatch);
  } else if (isError) {
    dispatchWithData(RESOLVED_GET_MEMBER_BY_GUID, {
      accountInfo: {},
      isError: true,
      isMember: false,
      user: {},
      pageName: preRegMemberNotFound,
    })(dispatch);
  }
}

const dispatchRejected = (data) => (dispatch) => {
  dispatchWithData(REJECTED_GET_MEMBER_BY_GUID, {
    accountInfo: { ...data },
    isError: true,
    isMember: false,
    user: {},
    pageName: preRegMemberNotFound,
  })(dispatch);
}

export const getMemberInfoByGuid = (data) => (dispatch) => {
  if (isEmpty(data)) return;
    dispatchLoading('WelcomePage')(dispatch);

    accountRegistrationAPICall(
      API_GET_MEMBER_BY_GUID,
      data,
      (response) => {
        if (response.status === 200 && response.data) {
          if (response.data.errorCode === 2020) {
            dispatchSuccess(false, true, {}, {})(dispatch);
          } else if (response.data.errorCode === 2021
              || response.data.errorCode === 2022) {
            dispatchSuccess(true, false, {}, {})(dispatch);
          } else {
            const result = response.data.result[0];
            if (isEmpty(result.email)) {
              result.email = '';
            }
            dispatchSuccess(false, false, data, result)(dispatch);
          }
        } else {
          dispatchRejected(data)(dispatch)
        }
      },
      () => {
        dispatchRejected({})(dispatch)
      },
    );
};

export const rejectedGetMemberByGUID = () => (dispatch) => {
  dispatchWithData(REJECTED_GET_MEMBER_BY_GUID, { isError: true })(dispatch);
};

export const invalidInfo = () => (dispatch) => {
  dispatchWithData(INVALID_MEMBER_INFO, { isInvalid: true })(dispatch);
};
