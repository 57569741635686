import React, { Component } from 'react';
import InnerHTML from 'dangerously-set-html-content';
// import './PreLoginFooter.css';
import './footer.css';
import './socialmedia.css';
import axios from 'axios';
import envConfig from 'env/EnvConfig';

class FooterWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
        };
    }

    componentDidMount() {
        // this.loadData('https://vcm-qa-aem65.adobecqms.net/vcm-experience-fragments/vcm-footer/master.plain');
        this.loadData(`${envConfig.ENV_URL}/vcm-experience-fragments/vcm-footer/master.html`);
    }

    loadData = url => {
        delete axios.defaults.headers.common['x-api-key'];
        axios
            .get(url, { headers: { Authorization: `${envConfig.PRELOGIN_HEADER_AUTH}` }, timeout: 40000 })
            .then(response => {
                // console.log(url + " -> " + response.ok);
                if (response) {
                    return response.data;
                }
                throw new Error('Error message.');
            })
            .then(data => {
                // console.log("data: ", data);
                let result = data.toString();
                // var result = data.toString().replace(/https:\/\/vcm-dev-aem65.adobecqms.net/g, 'https://vcm-qa-aem65.adobecqms.net');
                // result = result.replace(/\/etc/g, 'https://vcm-qa-aem65.adobecqms.net/etc');
                // result = result.replace(/\/content/g, 'https://vcm-qa-aem65.adobecqms.net/content');
                // result = result.replace(/\/libs/g, 'https://vcm-qa-aem65.adobecqms.net/libs');
                // result = result.replace(/https:\/\/vcm-qa-aem65.adobecqms.net\/etc.clientlibs\/vcm\/clientlibs\/clientlib-base.min.([a-zA-Z0-9]\w+).js/g,'/clientlib-base.min.js');
                // result = result.replace(/https:\/\/vcm-qa-aem65.adobecqms.net\/etc.clientlibs\/vcm\/clientlibs\/clientlib-base.min.([a-zA-Z0-9]\w+).css/g,'');
                // result = result.replace(/https:\/\/vcm-qa-aem65.adobecqms.net\/etc.clientlibs\/vcm\/clientlibs\/clientlib-dependencies.min.([a-zA-Z0-9]\w+).css/g,'');
                //   console.log("result: ", result);
                result = result.replace(/<script[^>]*>/gi, ' <!-- ');
                result = result.replace(/<\/script>/gi, ' --> ');
                // https://qa.investor.vcm.com/vcm-experience-fragments/vcm-faq-details/master.html
                result = result.replace(/href="\//g, `href="${envConfig.ENV_URL}/`);
                result = result.replace(/\/etc/g, `${envConfig.ENV_URL}/etc`);
                result = result.replace(/\/content/g, `${envConfig.ENV_URL}/content`);
                this.setState({ content: result });
            })
            .catch(err => {
                console.log('failed to load footer', url, err.message);
            });
    };

    render() {
        //   result = "Hello World";

        return (
            <vcm-footer id="vcm-foot" role="contentinfo">
                {' '}
                <InnerHTML html={this.state.content} />{' '}
            </vcm-footer>
        );
    }
}

export default FooterWidget;
