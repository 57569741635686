import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './Input.module.css';
import './tooltip.css';
import PasswordStrength from '../PasswordStrength/PasswordStrength';
import userConstants, {ContainsSpecialChar} from '../userConstants';

export class Password extends Component {
    style = {
        para: {
            verticalAlign: 'top',
            display: 'inline-block',
            position: 'absolute',
            right: 0,
            font: '600 12px/14px benton-sans',
            color: '#004A98',
            margin: 0,
            paddingTop: 5,
        },
        error: {
            maxWidth: '75%'
        }
    };

    render() {
        const { changed, blurred, clicked, passwordState, showForgot, showStrength, label, formValid, signInHandler } = this.props;

        const data = {
            config: {
                id: 'password',
                type: 'password',
                label: label || 'Password',
                placeholder: '',
            },
            change: event => changed(event, 'password'),
            blur: () => blurred('password'),
            click: (event) => clicked(event),
            keyPress:(e) => {
                const key = String.fromCharCode(
                  !e.charCode ? e.which : e.charCode,
                );
                if(formValid && (e.which===13 || e.charCode===13)) {
                    signInHandler();
                }
                if (
                  !ContainsSpecialChar(key)
                  && !/[A-Za-z0-9]/.test(key)
                ) {
                  e.preventDefault();
                  return false;
                }
                return true;
            },
            passwordText: userConstants.passwordText,
        };

        let inputElement = null;
        const inputClasses = [classes.InputElement];
        const errorId = `${data.config.id}_error`;

        if (passwordState.errMsg) {
            inputClasses.push(classes.Invalid);
        }

        inputElement = (
            <input
                id={data.config.id}
                type={data.config.type}
                placeholder={data.config.placeholder}
                className={`analytics-form-fields ${inputClasses.join(' ')}`}
                value={passwordState.value}
                onChange={data.change}
                onBlur={data.blur}
                onClick={clicked ? data.click: ""}
                onKeyPress={data.keyPress}
                aria-required="true"
                aria-describedby={errorId}
                aria-invalid={!!passwordState.errMsg}
                maxLength={50}
            />
        );

        return (
            <div className={classes.Input}>
                <label className={classes.Label} htmlFor={data.config.id}>
                    {data.config.label}
                </label>
                {inputElement}
                {showStrength ? (
                    <PasswordStrength len={passwordState.value.length} valid={passwordState.valid} />
                ) : null}
                {passwordState.errMsg && (
                    <p className={classes.errMsg} style={showForgot ? this.style.error : null} aria-live="polite" role="alert" aria-atomic="true" id={errorId}>
                        <span className="sr-only">Error:</span>{passwordState.errMsg}
                    </p>
                )}
                {showForgot && (
                    <Link role="link" to="/retrieve-password" className={classes.forgot} id="forgotPassword">
                        {data.passwordText}
                    </Link>
                )}
            </div>
        );
    }
}

Password.propTypes = {
    passwordState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            minLength: PropTypes.number,
            maxLength: PropTypes.number,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            required: PropTypes.bool,
        }),
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    label: PropTypes.string,
    showForgot: PropTypes.bool,
    showStrength: PropTypes.bool,
    formValid: PropTypes.bool,
    signInHandler: PropTypes.func,
};

Password.defaultProps = {
    passwordState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            minLength: 5,
            maxLength: 20,
            pattern: '^[a-zA-Z0-9]*$',
            patternMessage: 'Should contain only Alphabets and Numerics',
            required: true,
        },
    },
    changed: () => {},
    blurred: () => {},
    label: '',
    showForgot: false,
    showStrength: false,
    formValid: false,
    signInHandler: () => {},
};

export default Password;
