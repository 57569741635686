const importantText = 'IMPORTANT:';
const readMoreText =
    'Read the document below, print and save it for your records. It includes legally required disclosures/information. It will not be mailed to you.';
const readNote = 'You must read, scroll and click the button to agree to the document below.';
const agreeText =
    'By clicking “ I Agree,” I acknowledge that I am making this agreement in my individual capacity solely for my person, non-business use and that I agree to the terms and conditions of the VCM Electronic Service Agreement. This is my electronic signature.';
const contentHeading = 'VCM Electronic Service Agreement';
const contentSubtext =
    'The VCM Investment Management Company (IMCO) Electronic Services Agreement allows you to complete financial transactions, obtain real-time quotes and other information in your investment accounts.';
const saveText = 'Save Document';
const printText = 'Print version';
const vcmText = 'VCM Investment Management Company (IMCO) Electronic Service Agreement';
const readText =
    'YOU MUST READ AND SIGN THIS AGREEMENT BEFORE USING THE ELECTRONIC SERVICE OF VCM INVESTMENT MANAGEMENT. THIS AGREEMENT IS BETWEEN YOU AND VCM IMCO';
const imcoText = 'VCM IMCO ELECTRONIC SERVICES';
const contactText =
    'You may use your telephone or personal computer to enter orders for securities transactions through VCM IMCO Electronic Services. VCM IMCO Electronics, you to obtain account information, quotes etc. Sample Content, The actual content will need to be pulled from the content document. Sample Content , The actual content will need to be pulled from the content document. Sample Content, The actual content will need to be pulled from the content document. Sample Content, The actual content will need to be pulled from the content document. The actual content will need to be pulled from the content document. Sample Content, The actual content will need to be pulled from the content document. The actual content will need to be pulled from the content document. Sample Content, The actual content will need to be pulled from the content document. The actual content will need to be pulled from the content document. Sample Content, The actual content will need to be pulled from the content document.';
const pages = [
    'Account Type',
    'ESA (Electronic Services Agreement)',
    'Application - Part 1 (Personal & Beneficiary Info)',
    'Personal Information',
    'Beneficiary Information',
    'Application - Part 2 (Choose your Portfolio)',
];
const stepperStyle = {
    width: 1107,
    height: 60,
    paddingRight: 90,
    paddingLeft: 150,
};
const redirect = {
    pathname: '/application',
    state: { page: 'application1' },
};
const buttonstyle2 = { width: '100%' };
const buttontextstyle = { color: '#FFFFFF', font: '500 16px/22px Benton Sans', textAlign: 'center', opacity: 1 };
const buttonStyle = {
    width: '238px',
    height: '50px',
    margin: '0 auto',
    display: 'block',
    borderColor: '#5D83AE',
    background: '#5D83AE 0% 0% no-repeat padding-box',
};
export default {
    pages,
    buttonstyle2,
    buttontextstyle,
    redirect,
    stepperStyle,
    buttonStyle,
    importantText,
    readMoreText,
    readNote,
    agreeText,
    contentHeading,
    contentSubtext,
    saveText,
    printText,
    vcmText,
    readText,
    imcoText,
    contactText,
};
