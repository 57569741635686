import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import queryString from 'query-string';
import msrconstants from './constants';
import { SessionStorageHelper } from 'utils/sessionHelper';
import CryptoJS from 'crypto-js';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';

const configuration = {
    aws_project_region: envConfig.AWS_PROJECT_REGION,    
    aws_cognito_region: envConfig.AWS_COGNITO_REGION,
    aws_user_pools_id: envConfig.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: envConfig.AWS_USER_POOLS_WEB_CLIENT_ID,
    identityPoolId:envConfig.AWS_IDENTITY_POOL_ID,
    oauth: {},
};

/* const assignObj = obj => {
    return obj;
}; */

Amplify.configure(configuration);

const assignObj = obj => obj;
class Msr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: false 
        };
    }

    componentDidMount() {
        sessionStorage.clear();
        document.title = "MSR | Victory Capital Management";
        const {location: {search}, getMSRStatus } = this.props;        
        if(search && search.includes('queryString')){
            const data = window.location.search.substr(13);
            const buff = Buffer.from(data, 'base64');
            const params = buff.toString('ascii');
            const queryParams = queryString.parse(params);
            const {accesstoken = "", vcmid = "", msrid = "", fismemberid="" } = queryParams
            if (accesstoken && vcmid && msrid && fismemberid ) {
                this.setState(state => ({
                    ...state,
                    authorized: true,
                }))               

                const encySalt = envConfig.ADOBE_ANALYTICS_ENCY_SALT;
                const encLoad = CryptoJS.AES.encrypt(JSON.stringify(queryParams), encySalt).toString();

                SessionStorageHelper.setMSRInfoData('msr_dts',encLoad)
                //sessionStorage.setItem('msr_dts', JSON.stringify(queryParams));
                getMSRStatus();                                 
            }            
        }
        else{
            sessionStorage.clear();
        }        
    }



    // componentDidMount() {
    //     document.title = "MSR | Victory Capital Management";
    //     // Uncomment below hard coded check to test locally

    //     // document.cookie = "accesstoken=eyJraWQiOiJ3SXhIRytGT0xPOWx2a3VFQ3V5U2tiMFNGWFwvQlB2aU9DcHZvMW9USXNVUT0iLCJhbGciOiJSUzI1NiJ9.eyJ1c2FhTWVtYmVySWQiOiIxMTQwNjk4MSIsInN1YiI6ImY0MDg5ZjA2LTg4YWUtNGQzNi04ZGM5LTVhMGM1ZDEyOTU1YyIsImZpc01lbWJlcklkIjoiVTExNDA2OTgxIiwiY2hhbm5lbCI6IndlYiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX3prY1Y3ejRkbCIsImNvZ25pdG86dXNlcm5hbWUiOiJ1c3Nhb25saW5laWQzIiwiYXVkIjoiMm4zaWwydGtqZ2J0MjI5Z2k4OXFhMWNvdjMiLCJldmVudF9pZCI6IjVhY2MxNWE5LTk3ZmQtNDdmZi05NGI4LTMzNWNkMDY5ZTIxZCIsInRva2VuX3VzZSI6ImlkIiwidmNtSUQiOiIyMDAzMDcyNDkxMiIsImF1dGhfdGltZSI6MTU5NDYzNDg1NywiZXhwIjoxNTk0NjM4NDU3LCJpYXQiOjE1OTQ2MzQ4NTd9.Td24gG84nvAyW7gyBBsYJ2qarH3YEkccEaGg8cQgm48a7-aGLZza4GpivEDkGZXbqPBwLzDrLNiiYsS06J69oc5QwUQFreejnC5p4IQ9Ya57HFeqdk3VbkfK1dYX_5h89fcPwkhFAgoyRmOfIWGZ4cnQ3hC0igg-DLHbjIZKBZtkqohT5Nq0lQGxUyVFQDPmLFrrsVw5Css6Lhs3DPwT6VtBN8vZbbJB8NWmPqN_rXvKSX1LZDZDOkGBpkPFt4-wIKqY8bVNDRlgGcAMz7FtB5DNXXDavtU9u-cU5-F-uB9HfAC_aCDgoPKPNdtwMoeb2qC5skH2fJQRvQnhHDovcg";
    //     // document.cookie = "vcmid=202000001";
    //     // document.cookie = "msrid=20030725084";


    //     const token = this.getCookieValue('accesstoken');
    //     const vcmid = this.getCookieValue('vcmid');
    //     const msrid = this.getCookieValue('msrid');

    //     if (token && vcmid && msrid) {
    //         this.setState(state => ({
    //             ...state,
    //             authorized: true,
    //         }))
    //         const { getMSRStatus, getCustomerProfile } = this.props;
    //         const params = {
    //             accesstoken: token,
    //             vcmid,
    //             msrid,
    //         };
    //         localStorage.setItem('msr_dts', JSON.stringify(params));
    //         getMSRStatus();
    //         const payLoad = {};
    //         getCustomerProfile(payLoad);            
    //     }else{
    //         localStorage.clear();
    //     }
    // }

    componentDidUpdate() {
        const {
            redirectFlag: { isMSRFlow },
            history
        } = this.props;       

        if (isMSRFlow) {    
            // const applicationId =  JSON.parse(sessionStorage.getItem('msr_dts')).applicationId;
            const applicationId =  getSessionValues()?.applicationId;
            if(applicationId == "" || applicationId == undefined || applicationId== null)
            {
                history.push('/accountholder');
            }       
            else
            {
                history.push({
                    pathname:'/redirectToBrokerageNAO',
                    state:{
                        applicationId: applicationId
                    }
                });
            } 
        }
    }

    // getCookieValue = name => {
    //     // eslint-disable-next-line prefer-template
    //     const result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
    //     return result ? result.pop() : ""       
    // };

    render() {
        const {unauthorized} = msrconstants;
        const {authorized} = this.state;

        return(
            <div className="container">
                {!authorized && (<div className="mt-5 text-center" style={assignObj({color:'red'})}>
                    {unauthorized}
                </div>)}                
            </div>
        )
    }
}

Msr.defaultProps = {    
    history: {},
    getMSRStatus: () => { },    
    redirectFlag : {},    
    location: {},
}

Msr.propTypes = {    
    history : PropTypes.instanceOf(Object),
    getMSRStatus: PropTypes.func,    
    redirectFlag: PropTypes.instanceOf(Object),    
    location: PropTypes.instanceOf(Object),
}

export default Msr;