import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isMSRFlow : false
};

function MsrReducer(state = initialState, action) {

    switch (action.type) {
        
        case ActionTypes.MSR_FLOW : {
            return { ...state, isMSRFlow: true};
        }       

        default:
            return state;
    }
}

export default MsrReducer;
