import React, { Component } from 'react';
import MSRLookupTable from './MSRLookupTable';
import WBreadCrumb from '../../common/WBreadCrumb/WBreadCrumb';

const breadCrumb = [{ name: 'Home', url: '/accountholder' }];
class MSRLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lookupData : [],
        };
    }

    render() {
        const {lookupData} = this.state;
        return (
            <div className="container">
                <WBreadCrumb
                    breadCrumbItems={breadCrumb}
                    activeCrumb="MSR Lookup"
                    
                />
                <MSRLookupTable tableData={lookupData} />
            </div>
        );
    }
}

export default MSRLookup;
