/**
 * Preferences
 ********************************
 * @version 1.0.1
 * @author Venu Sugguna
 * @description This page let's the user:
 * Set delivery preference of documents.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import routeConstants from 'routeConstants';

import SideHeader from '../../SideHeader/SideHeader';
import { WStepper, CommonButtons, WRadio } from '../../../../common';
import data from './constants';
import styles from '../../UserManagementStyles'; 

const {stepperColStyle} = styles.stepperStyle;

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: true,
      post: false,
      logOutRoute: {
        pathname: '/sign-in',
        // message: { message: 'You have been successfully logged out' },
      },
      redirect:false,
      ...props.preferenceState
    }
    this.onBack = this.onBack.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  componentDidMount(){
    const token = localStorage.getItem('token');
    if(!token){
      // this.handleLogOut();
    } 
  }



  onBack() {
    const { history, manageLocalState } = this.props;
    const prefData = { preferenceState: { ...this.state } };
    const path = 'setup-security-questions';
    manageLocalState(prefData);
    history.push(path);
  }

  onNext() {
    const { history, manageLocalState } = this.props;
    const prefData = { preferenceState: { ...this.state } };
    manageLocalState(prefData);
    history.push('./confirm-security-questions');
  }

  clickOnline = () => {
    this.setState((state) => ({ ...state, online: true, post: false }))
  };

  clickPost = () => {
    this.setState((state) => ({ ...state, online: false, post: true }))
  };

  handleLogOut() {
    const token = localStorage.getItem('token');
    if (token) {
        Auth.signOut()
            .then(() => {
                // console.log(data);
                localStorage.clear();
                this.setState({
                    redirect: true,
                    logOutRoute: {
                        pathname: '/sign-in',
                        // message: { message: '' },
                    },
                });
            })
            .catch(() => {
                // console.log(err)
            });
        // else {
        //     this.setState({redirect: false})
        // }
    } else {
        // console.log('no token');
        this.setState({
            redirect: true,
            pathname: '/',
        });
    }
}

  render() {
    const { online, post, redirect, logOutRoute } = this.state;
    let redirectionResult = '';
    if (redirect) {
        redirectionResult = <Redirect to={logOutRoute} />;
    }
    return (
      <div className="container-fluid">
          {redirectionResult}
        <div className="container">
          <div style={data.stepperDivStyle}>
            <WStepper className="userWizard" currentPage={3} pages={data.pages} stepperColStyle={stepperColStyle} />
          </div>
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={data.title}
                info={data.info} />
            </Col>
            <Col md lg xl>
              <div>
                <Form.Group role="group" aria-labelledby="deliveryPreference">
                  <span style={data.labelStyle} id="deliveryPreference">{data.label}</span>
                  <WRadio id='onlineRadio' value={data.onlineText} selected={online} onClick={this.clickOnline} />
                  <WRadio id='postRadio' value={data.postText} selected={post} onClick={this.clickPost} />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
        <CommonButtons backClick={this.onBack} nextClick={this.onNext} blockstyle={styles.commonButtons} arialabelback="Back to Setup Security Questions" />
      </div>
    );
  }
}

Preferences.propTypes = {
  preferenceState: PropTypes.instanceOf(Object),
  manageLocalState: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)])
}

Preferences.defaultProps = {
  preferenceState: {},
  manageLocalState: () => { },
  history: [],
}

export default Preferences;