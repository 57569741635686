/**
 * Terms And Conditions Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to view terms and conditions
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CommonButtons, WInputCheckBox, WSpinner, WIcon } from '../../../common';
import './TermsAndConditions.css';
import Consts from './Consts';

export default class TermsAndConditionsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            loading: true,
            termsAndConditionsConsent: false,
            termsSelected: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);
        const { localStateData } = this.props;
        if (localStateData.termsAndConditionsConsent !== undefined) {
            this.setState({ termsAndConditionsConsent: localStateData.termsAndConditionsConsent });
        }
    }

    handleNext = () => {
        const { termsAndConditionsConsent } = this.state;
        if (termsAndConditionsConsent) {
            this.setState({ next: true });
            this.saveToLocal();
        } else if (!termsAndConditionsConsent) {
            this.setState({ termsSelected: false });
        }
    };

    saveToLocal = () => {
        const { manageLocalState } = this.props;
        const { termsAndConditionsConsent } = this.state;
        const data = {
            onlineId: 'aaru',
            customerId: '123456',
            accountType: 'Individual',
            termsAndConditionsConsent,
        };
        manageLocalState(data);
    };

    handleChange = e => {
        if (e.target.checked) {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: true });
        } else {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: false });
        }
    };

    displayTime = () => {
        const today = new Date();
        const date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        let hours = today.getHours();
        const minutes = today.getMinutes();
        const AmOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const time = `${hours}:${minutes}:${today.getSeconds()} ${AmOrPm}`;
        return `${date}  ${time}`;
    };

    render() {
        const {
            heading,
            desc1,
            desc2,
            desc3,
            subheading,
            listItems,
            list1,
            list2,
            list3,
            checkBoxLabel,
            iconStyle,
            faStyle,
            checkBoxLabelStyle,
            checkBoxWrapperStyle,
            checkInputsStyle,
            commonButtonStyle,
            cancelStyle,
            redirectTo,
        } = Consts;
        const { loading, next, termsAndConditionsConsent, termsSelected } = this.state;
        return (
            <>
                <WSpinner loading={loading} />
                <div>
                    <div className="terms-wrapper">
                        <div className="container">
                            <Row noGutters>
                                {' '}
                                <h1 className="terms-heading">{heading}</h1>{' '}
                            </Row>
                            <div className="terms-content">
                                <p className="terms-desc">
                                    <span>{desc1}</span>
                                    <span className="boldText">{desc2}</span>
                                    <span>{desc3}</span>
                                </p>
                                <div className="list-terms">
                                    <h5 className="terms-subheading">{subheading}</h5>
                                    <div className="terms-points">
                                        <div className="term-list" key="stactic">
                                            <WIcon
                                                icon={faCheck}
                                                style={iconStyle}
                                                size="sm"
                                                fontawesomestyle={faStyle}
                                            />
                                            <span>{list1}</span>
                                            <span className="boldText2">{list2}</span>
                                            <span>{list3}</span>
                                        </div>
                                        {listItems.map(item => (
                                            <div key={item} className="term-list">
                                                <WIcon
                                                    icon={faCheck}
                                                    style={iconStyle}
                                                    size="sm"
                                                    fontawesomestyle={faStyle}
                                                />
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="check-terms">
                                    <Row className="check-content" noGutters>
                                        <WInputCheckBox
                                            checked={termsAndConditionsConsent}
                                            onChange={this.handleChange}
                                            labelstyle={checkBoxLabelStyle}
                                            checkboxwrapperstyle={checkBoxWrapperStyle}
                                            checkInputStyle={checkInputsStyle}
                                            label={checkBoxLabel}
                                        />
                                        <span className="date-time">{this.displayTime()}</span>
                                    </Row>
                                </div>
                                {!termsSelected && <span className="error-msg">{Consts.radioError}</span>}
                            </div>
                        </div>
                        <CommonButtons
                            nextClick={this.handleNext}
                            showSave
                            style={commonButtonStyle}
                            cancelstyle={cancelStyle}
                            savestyle={cancelStyle}
                            backstyle={cancelStyle}
                        />
                        {next ? <Redirect to={redirectTo} /> : ''}
                    </div>
                </div>
            </>
        );
    }
}

TermsAndConditionsComponent.propTypes = {
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
};

TermsAndConditionsComponent.defaultProps = {
    localStateData: {},
    manageLocalState: () => {},
};
