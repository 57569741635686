import React from 'react';
import PropTypes from 'prop-types';

import '../Screens/Screens.css';
import data from './constants';
import classes from '../Input/Input.module.css';
import { WButton } from '../../../common';

const SignInBySoftTokenForm = (props) => {
    const { state, signInHandler, blurred, changed } = props;
    const { errMsg, error, valid, value } = state

    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (errMsg) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        type={data.config.type}
        placeholder={data.config.placeholder}
        className={inputClasses.join(' ')}
        value={value}
        onChange={changed}
        onBlur={blurred} />;

    return (
        <div style={data.styles.main}>
            <h1 style={data.styles.header}>{data.header}</h1>
            <div className={classes.Input}>
                <label className={classes.Label} htmlFor={data.onlineIdLabel}>{data.onlineIdLabel}</label>
                <p style={data.styles.onlineIdStyle}>{data.onlineId}</p>
            </div>
            <div className={classes.Input}>
                <label className={classes.Label} htmlFor={data.config.label}>{data.config.label}</label>
                {inputElement}
                {errMsg ? <p className={classes.errMsg}>{errMsg}</p> : null}
            </div>
            {error ? <p className={classes.errMsg}>{data.errorText}</p> : null}
            <div style={data.styles.termsOfUse}>
                <p style={data.styles.para}>{data.agreeText}
                <button type="button" style={data.styles.strong}>{data.termsOfUse}</button></p>
            </div>
            <WButton buttontext="Sign In" disabled={!valid} onClick={signInHandler} />
        </div>
    );
};

SignInBySoftTokenForm.propTypes = {
    state: PropTypes.instanceOf(Object),
    signInHandler: PropTypes.func,
    changed: PropTypes.func,
    blurred: PropTypes.func
}

SignInBySoftTokenForm.defaultProps = {
    state: {},
    signInHandler: () => { },
    changed: () => { },
    blurred: () => { }
}

export default SignInBySoftTokenForm;
