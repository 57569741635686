/**
 * Confirm Account Opening Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component is used o show the user confirmation message and details of the account opened.
 * @createdDate [19/03/2020]
 * ***************************************
 * @lastModifiedDate [01/04/2020]
 * @lastModifiedBy Anjana
 * @lastModifiedReason VD update
 */

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { WButton, WSpinner } from '../../../common';
import AccGlobalConstants from '../AccountManagementConstants';
import consts from './constants';
import './confirmAccountOpening.css';
import PersonalCheckComponent from './PersonalCheckComponent';
import WireTransferComponent from './WireTransferComponent';
import OnlineTransferComponent from './OnlineTransferComponent';
import SelectedFundTableDisplayComponent from './SelectedFundTableDisplayComponent';

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ErrorP = styled.p`
    display: flex;
    justify-content: space-between;
    color: #e60000;
    margin-top: 20px;
`;

const PageHeading = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal,sans-serif
    letter-spacing: 0;
    color: #486D90;
    border-bottom: 2px solid #8BC105;
    padding-bottom: 10px;
    float: left;
    font-weight: 800;
    font-size: 30px;
`;

class ConfirmAccountOpening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is529Account: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accOpeningData, localStateData } = nextProps;
        let masterRegId = '';
        if (accOpeningData.SUBMIT_OPENING_ACCT && accOpeningData.SUBMIT_OPENING_ACCT.length > 0) {
            masterRegId = accOpeningData.SUBMIT_OPENING_ACCT[0].masterRegId;
        }

        return {
            ...prevState,
            isLoading: accOpeningData.isLoading,
            isError: accOpeningData.isError,
            is529Account: localStateData.accountType === '529 Education Savings Plan',
            NAOResponse: accOpeningData.SUBMIT_OPENING_ACCT,
            masterRegId,
        };
    }

    componentDidMount() {
        const { localStateData, accOpeningData, deleteApplication, history, clearLocalStateForNAO } = this.props;

        // on back button click on the confirmation page
        // takes to account holder page
        window.addEventListener('popstate', e => {
            history.push({
                pathname: '/accountholder',
                state: {},
            });
            clearLocalStateForNAO();
        });

        let {
            accountType = '',
            accountHolderName = '',
            selectedFundsList = consts.fundList,
            selectedBankAcccount,
            onlineBankPaymentDetails,
            headerValues,
            newAccountId = '',
        } = this.state;
        const { masterRegId } = this.state;
        if (localStateData.newAccountId !== undefined) {
            newAccountId = localStateData.newAccountId;
        }
        if (
            accOpeningData.SUBMIT_OPENING_ACCT &&
            accOpeningData.SUBMIT_OPENING_ACCT.length > 0 &&
            accOpeningData.uuid
        ) {
            deleteApplication({ uuid: accOpeningData.uuid, token: localStorage.getItem('token') });
        }
        if (localStateData.accountType) {
            accountType = localStateData.accountType;
            if (localStateData[accountType.toString()].perInfo) {
                const personalData = localStateData[accountType.toString()].perInfo;
                accountHolderName = `${personalData.firstName} ${personalData.lastName}`;
                headerValues = this.generateHeaderValues(masterRegId, accountType, accountHolderName);
            }
        }
        if (localStateData.selectedFundsList !== undefined) {
            selectedFundsList = localStateData.selectedFundsList;
        }
        if (localStateData.selectedBankAcccount !== undefined) {
            selectedBankAcccount = localStateData.selectedBankAcccount;
        }
        if (localStateData.onlineBankPaymentDetails) {
            onlineBankPaymentDetails = this.getBankDetails();
        }
        this.setState(
            {
                accountType,
                headerValues,
                accountHolderName,
                selectedFundsList,
                selectedBankAcccount,
                onlineBankPaymentDetails,
                newAccountId,
            },
            () => {
                this.displaySelectedBankAccount(selectedBankAcccount);
            },
        );
    }

    componentDidUpdate(prevProps) {
        const { accOpeningData, localStateData } = prevProps;
        const { isError } = this.state;
        if (localStateData.accountType === '529 Education Savings Plan' && !isError) {
            this.prepareAsensusredirect(accOpeningData);
        }
    }

    handlePrint = () => {
        // e.preventDefault();
        // e.stopPropagation();
        // const mywindow = window.open('', 'PRINT');
        // mywindow.document.write('<html><head><title>VCM</title>');
        // mywindow.document.write('<style>');
        // mywindow.document.write(consts.printStyle);
        // mywindow.document.write('</style>');
        // mywindow.document.write('</head><body> <div class="confirmationPage">');
        // mywindow.document.write(document.getElementById(id).innerHTML);
        // mywindow.document.write('</div></body></html>');
        // mywindow.document.close(); // necessary for IE >= 10
        // mywindow.focus(); // necessary for IE >= 10*/
        // mywindow.print();
        // mywindow.close();

        // return true;
        window.print();
    };

    okClick = () => {
        const { clearLocalStateForNAO, clearBankAccDetails } = this.props;
        clearLocalStateForNAO();
        clearBankAccDetails();
        this.setState({ okClicked: true });
    };

    generateHeaderValues = (regNo, acctType, accountHolderName) => {
        return [
            {
                key: 'Account Registration Number',
                value: regNo || 'XXXX-XXXX-XXXX',
            },
            {
                key: 'Account Type',
                value: AccGlobalConstants.getPageHeading(acctType).replace('Account', '') || 'Retirement',
            },
            {
                key: 'Account Holder Name',
                value: accountHolderName || 'John Doe',
            },
        ];
    };

    displaySelectedBankAccount = selectedBankAcccount => {
        let { displayPersonalCheckSection, displayWireTransferSection, displayOnlineTransferSection } = this.state;
        const { isPersonalCheck, isWireTransfer } = consts;
        if (selectedBankAcccount === isPersonalCheck) {
            displayPersonalCheckSection = true;
        } else if (selectedBankAcccount === isWireTransfer) {
            displayWireTransferSection = true;
        } else {
            displayOnlineTransferSection = true;
        }
        this.setState({ displayPersonalCheckSection, displayWireTransferSection, displayOnlineTransferSection });
    };

    prepareAsensusredirect = data => {
        const resultOne = JSON.parse(JSON.stringify(data));
        const { result } = resultOne;

        const urrl = `${consts.asensusRedirectUrl}
            ${result.cipherText}
            &signedToken=
            ${result.signedToken} 
            &token= 
            ${result.token} 
            &Nav= 
            ${result.NAV}
            &aesEncKey=
            ${result.encodedAesSessionKey}`;

        // User will redirect after 3 sec
        window.location.href = urrl;
    };

    getBankDetails = () => {
        const { localStateData } = this.props;
        const { fundSource } = localStateData.OpenAccPageFour.investmentInfo.selectedFundSource;
        const { personalInfo } = localStateData.OpenAccPageTwo;
        return {
            otherBankState: {
                bankAccountType: fundSource.accountType,
                bankAccountDetails: [
                    {
                        label: 'Name of Financial Institution',
                        name: 'financialInstitutionName',
                        value: fundSource.institutionName,
                    },
                    {
                        label: 'Name of Account Owner(s)',
                        name: 'accountOwner',
                        value: `${personalInfo.firstName} ${personalInfo.lastName}`,
                    },
                    {
                        label: 'Transit Routing Number',
                        name: 'transitRoutingNumber',
                        value: fundSource.transitRoutingNumber,
                    },
                    { label: 'Account Number', name: 'accountNumber', value: fundSource.bankAccountNumber },
                ],
            },
        };
    };

    render() {
        const {
            accountType,
            selectedFundsList,
            displayPersonalCheckSection,
            displayWireTransferSection,
            displayOnlineTransferSection,
            onlineBankPaymentDetails,
            okClicked,
            headerValues,
            isLoading,
            isError,
            NAOResponse,
            masterRegId,
            is529Account,
        } = this.state;
        const {
            confirmFundingTypeHeading,
            personalCheckHeading,
            wireTransferHeading,
            onlineTransferHeading,
            okText,
            okStyle,
            okDivStyle,
            confirmationMsgP1,
            confirmationMsgP2,
            confirmationErrorMessage,
            errorPage,
        } = consts;
        return (
            <div>
                {isLoading && <WSpinner loading={isLoading} />}
                {isError && (
                    <Container className="confirmationPage">
                        <FlexDiv>
                            <PageHeading data-test="errorHeading">{errorPage}</PageHeading>
                        </FlexDiv>
                        <Row>
                            <Col>
                                <ErrorP data-test="errorMessage">{confirmationErrorMessage}</ErrorP>
                            </Col>
                        </Row>
                    </Container>
                )}
                {!isError && is529Account && (
                    <Container className="confirmationPage">
                        <Row noGutters>
                            <Col>
                                <h1 className="pageHeading">{consts.congratulationText}</h1>
                                <p className="confirmationMsg">
                                    {confirmationMsgP1}
                                    <span className="strongSpan">
                                        {AccGlobalConstants.getPageHeading(accountType).replace('Account', '')}
                                    </span>
                                    {confirmationMsgP2}
                                    <span className="strongSpan">{masterRegId}</span>
                                </p>
                            </Col>
                        </Row>
                        <div id="printContent">
                            <div className="confirmFundAccInfo">
                                <Row noGutters className="headerRowCfmPg">
                                    <Col xs={11}>
                                        <h2 className="pageSubHeading">{consts.confirmFundDetailsHeading}</h2>
                                    </Col>
                                    <Col xs={1} className="textAlignRight">
                                        <WButton
                                            variant="outline"
                                            className="linkButton print"
                                            onClick={AccGlobalConstants.assignObj(e => {
                                                this.handlePrint(e, 'printContent');
                                            })}
                                            aria-label="Print"
                                            buttontext="Print"
                                            aria-haspopup
                                        />
                                    </Col>
                                </Row>
                                <Row noGutters className="dividendHint header">
                                    {headerValues &&
                                        headerValues.map(item => {
                                            return (
                                                <Col className="itemWrapper" key={item.key}>
                                                    <span className="labelItem">{item.key}</span>
                                                    <span className="valueItem">{item.value}</span>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>
                            <div className="confirmFundingType">
                                <Row noGutters className="headerRowCfmPg">
                                    <Col xs={12}>
                                        <h2 className="pageSubHeading">{confirmFundingTypeHeading}</h2>
                                    </Col>
                                </Row>
                                {displayPersonalCheckSection && (
                                    <PersonalCheckComponent heading={personalCheckHeading} />
                                )}
                                {displayWireTransferSection && <WireTransferComponent heading={wireTransferHeading} />}
                                {displayOnlineTransferSection && (
                                    <OnlineTransferComponent
                                        heading={onlineTransferHeading}
                                        paymentDetails={onlineBankPaymentDetails}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                )}
                {!isError && !is529Account && (
                    <Container className="confirmationPage">
                        <Row noGutters>
                            <Col>
                                <h1 className="pageHeading">{consts.congratulationText}</h1>
                                <p className="confirmationMsg">
                                    {confirmationMsgP1}
                                    <span className="strongSpan">
                                        {AccGlobalConstants.getPageHeading(accountType).replace('Account', '')}
                                    </span>
                                    {confirmationMsgP2}
                                    <span className="strongSpan">{masterRegId}</span>
                                </p>
                            </Col>
                        </Row>
                        <div id="printContent">
                            <div className="confirmFundAccInfo">
                                <Row noGutters className="headerRowCfmPg">
                                    <Col xs={11}>
                                        <h2 className="pageSubHeading">{consts.confirmFundDetailsHeading}</h2>
                                    </Col>
                                    <Col xs={1} className="textAlignRight">
                                        <WButton
                                            variant="outline"
                                            className="linkButton print"
                                            onClick={AccGlobalConstants.assignObj(e => {
                                                this.handlePrint(e, 'printContent');
                                            })}
                                            aria-label="Print"
                                            buttontext="Print"
                                            aria-haspopup
                                        />
                                    </Col>
                                </Row>
                                <Row noGutters className="dividendHint header">
                                    {headerValues &&
                                        headerValues.map(item => {
                                            return (
                                                <Col className="itemWrapper" key={item.key}>
                                                    <span className="labelItem">{item.key}</span>
                                                    <span className="valueItem">{item.value}</span>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>
                            <SelectedFundTableDisplayComponent
                                selectedFundsList={selectedFundsList}
                                NAOResponse={NAOResponse}
                            />
                            <div className="confirmFundingType">
                                <Row noGutters className="headerRowCfmPg">
                                    <Col xs={12}>
                                        <h2 className="pageSubHeading">{confirmFundingTypeHeading}</h2>
                                    </Col>
                                </Row>
                                {displayPersonalCheckSection && (
                                    <PersonalCheckComponent heading={personalCheckHeading} />
                                )}
                                {displayWireTransferSection && <WireTransferComponent heading={wireTransferHeading} />}
                                {displayOnlineTransferSection && (
                                    <OnlineTransferComponent
                                        heading={onlineTransferHeading}
                                        paymentDetails={onlineBankPaymentDetails}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                )}
                {okClicked ? (
                    <Redirect to={localStorage.getItem('role') === 'Member' ? '/accountholder' : '/accountguestview'} />
                ) : (
                    ''
                )}
                <div style={okDivStyle} className="confirmPageBtnWrapper">
                    <div className="container">
                        <Row style={okStyle}>
                            <Col xs={2}>
                                <WButton buttontext={okText} onClick={this.okClick} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

ConfirmAccountOpening.propTypes = {
    localStateData: PropTypes.instanceOf(Object),
    clearLocalStateForNAO: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    clearBankAccDetails: PropTypes.func,
    deleteApplication: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

ConfirmAccountOpening.defaultProps = {
    localStateData: {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    clearBankAccDetails: () => {},
    deleteApplication: () => {},
    history: {},
};
export default ConfirmAccountOpening;
