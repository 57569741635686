import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import PropTypes from 'prop-types';

const Box = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
`;

const Input = React.forwardRef((props, ref) => (
    <Box ref={ref} as="input" css={css(({ components: { input } }) => input)} {...props} />
));

const Button = React.forwardRef((props, ref) => (
    <Box ref={ref} as="button" css={css(({ components: { button } }) => button)} {...props} />
));

const Label = React.forwardRef((props, ref) => (
    <Box ref={ref} as="label" css={css(({ components: { label } }) => label)} {...props} />
));

const PageHeading = props => <Box as="h1" className='box-heading' css={css(({ components: { pageHeading } }) => pageHeading)} {...props} />;

const BtnTxt = styled.text`
    margin: 0;
    padding: 0;
    font: 700 16px/20px yorkten-slab-normal;
`;
const BtnCorner = styled.div({
    width: '0',
    height: '0',
    borderBottom: '19px solid transparent',
    borderRight: '19px solid #8BC105',
    position: 'absolute',
    top: '0',
    right: '0',
});
const VCMButton = props => {
    const { disabled, buttontext } = props;
    return (
        <Button
            {...props}
            css={css(({ components: { vcmBtn, vcmBtnDisabled } }) => (disabled ? vcmBtnDisabled : vcmBtn))}
        >
            <BtnTxt>{buttontext}</BtnTxt>
            {!disabled && <BtnCorner />}
        </Button>
    );
};
VCMButton.propTypes = {
    disabled: PropTypes.bool,
    buttontext: PropTypes.string,
};

VCMButton.defaultProps = {
    disabled: false,
    buttontext: '',
};

export { Input, Button, Label, PageHeading, VCMButton };
