/**
 * Security Preferences Component
 ********************************
 * 
 * @version 1.0.1
 * @author Manjunath V
 * @description 
 * @createdDate [10/12/2019]
 * ***************************************
 * @createdDate Manjunath V
 * @lastModifiedDate 
 * @lastModifiedReason 
 */



import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { WButton, WBreadCrumb, CommonButtons } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import styles from './regulatoryStyles';

const breadCrumbValues = [{ name: 'Home', url: '/' },
{ name: 'Profiles & Preferences', url: '/Profile' },
{ name: 'Security Preferences', url: '/SecurityPreferences' }
];
const pageName = "Mobile Quick Sign In";
const quickSignInLabel = "You have enabled the Quick Sign in method in your VCM app . To enable again use your VCM Mobile App.";

export default class MobileQuickSignInComponent extends Component {

  constructor(props) {
    super(props);
    const { loginState } = props;
    const quickSignIn = loginState.data ? loginState.data.data.mobileQuickSignIn : true;
    this.state = {
      switchOn: quickSignIn
    };
    this.clickBack = this.clickBack.bind(this);
  }

  // static getDerivedStateFromProps(props) {
  // }

  componentDidMount() {
    const { getQuickSignIn } = this.props;
    getQuickSignIn({
      "onlineId": "test"
    });
  }

  assignObj = (obj) => { return obj; }

  switchMethod = () => {
    this.setState({
      switchOn: false
    })
    const { setQuickSignIn } = this.props;
    setQuickSignIn({
      "onlineId": "test",
      "quickSign": false
    })
  }

  clickBack() {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: '/SecurityPreferences'
      });
    }
  }

  render() {
    const { switchOn } = this.state;
    // const { loginState } = this.props;

    return (
      <div>
        <div className="container">
          <WBreadCrumb
            breadCrumbItems={breadCrumbValues}
            activeCrumb='Mobile Quick Sign In'
          />
          <SideHeader
            title={pageName}
          />
          <div id="quickSignInLabel" className="w-100" style={styles.subHeading}>
            <label htmlFor="quickSignInLabel"> {quickSignInLabel}
            </label> </div>
          <div className="w-75 mobile-quick-sign-in" style={styles.switchMargin}>
            <div style={styles.switchContainer}>
              <WButton
                id="enable-button"
                variant='outline'
                buttonstyle={switchOn ? styles.buttonStyle : styles.buttonStyleDisable}
                buttontext='Enabled'
                buttontextstyle={switchOn ? styles.TextOnStyle : styles.TextOffStyle}
                onClick={this.switchMethod}
              />
              <WButton
                id="disable-button"
                variant='outline'
                buttonstyle={switchOn ? styles.buttonStyleDisable : styles.buttonStyle}
                buttontext='Disable'
                buttontextstyle={switchOn ? styles.TextOffStyle : styles.TextOnStyle}
                onClick={this.switchMethod}
              />
            </div>
          </div>
        </div>
        <CommonButtons backClick={this.clickBack} nextstyle={this.assignObj(styles.nextStyle)} />

      </div>

    )
  }
}


MobileQuickSignInComponent.propTypes = {
  getQuickSignIn: PropTypes.func,
  setQuickSignIn: PropTypes.func,
  loginState: PropTypes.instanceOf(Object),
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
}

MobileQuickSignInComponent.defaultProps = {
  getQuickSignIn: () => { },
  setQuickSignIn: () => { },
  loginState: {},
  history: []
}