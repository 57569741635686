import React,{useState} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import constant from '../AccountDashboardConstants';
import { setToggled } from '../../../../shared/Actions/LiveChatActions';
import { analyzeClickEvent } from '../../../../shared/Actions/AnalyticsActions';

const S = {};

S.StyledRowContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding-top: 20px;
    padding-bottom:20px;     
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
        padding:10px 0px 10px 0px;
    }  
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        padding:10px 0px 10px 0px;
    }   
`;

// @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
//     padding-top: 10px;
//     padding-bottom: 10px;
// }

S.TopRowDivider = styled.div`
    min-width: 335px;
    opacity: 1;
    width: 100%;
    margin: 20px 0px 20px 0px;
`;

S.StyledText = styled.span`
    top: 734px;
    left: 878px;
    width: 253px;
    line-height: 22px;
    font-size: 18px;
    text-align: left;
    font-weight: 800;
    font-family: yorkten-slab-normal;
    margin-left: 15px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;   
`;

// @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
//     height: auto;
// }

S.SvgContainer = styled.div`
    background: transparent url('img/Subtraction 1.png') 0% 0% no-repeat padding-box;
    opacity: 1;
`;

S.StyledLink = styled(Link)`
    color: #004a98;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        outline: 2px solid #000;
        outline-offset: 0px;
    }
`;

S.StyledButton = styled.button`
    border:none;
    background-color:#FFFFFF;
    padding:0px;
    color: #004a98;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;

S.StyledChatLink = styled.div`
    color: #004a98;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;
const chat = 'Live Chat';

const ContactCenterFooterRow = ({ message, SvgComponent, link, show }) => {
    const dispatch = useDispatch();
    const { assignObj, openPopupText } = constant
    const screenName = localStorage.getItem("screenName")
    const [ areaExpandStatus, setAreaExpandStatus ] = useState(false);
    function toggle() {
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_Contact Center_Live Chat`,
            }),
        );
        setAreaExpandStatus(!areaExpandStatus);
        dispatch(setToggled());
        dispatch({ type: 'SET_MODAL_ID', modalId: 'ic-modal' });
    }

    function sendSecureMessage() {
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_Contact Center_Send us a Secure Message`,
            }),
        );
    }

    return (
        <div data-test="contactcenterfooterrow">
            {show && <S.StyledRowContainer className="contact-chat" style={assignObj({ borderBottom: '1px solid #D2D2D2' })}>
                <S.SvgContainer>{SvgComponent}</S.SvgContainer>
                <S.StyledText>
                    {message === chat && (
                        <S.StyledButton onClick={toggle}
                            // aria-haspopup="dialog"
                            aria-expanded={areaExpandStatus}
                            data-test="chatbutton">
                            {message}
                            <span className="sr-only">{openPopupText}</span>
                        </S.StyledButton>
                    )}
                    {message !== chat && <S.StyledLink to={link} onClick={sendSecureMessage} >{message}</S.StyledLink>}
                </S.StyledText>
            </S.StyledRowContainer>}
        </div>
    );
};

ContactCenterFooterRow.defaultProps = {
    SvgComponent: undefined,
    message: '',
    link: '',
    show: false,
};

ContactCenterFooterRow.propTypes = {
    SvgComponent: PropTypes.element,
    message: PropTypes.string,
    link: PropTypes.string,
    show: PropTypes.bool
};

export default ContactCenterFooterRow;
