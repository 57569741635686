import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import styles from '../inputs/CommonStyles';
import WModal from './WModal';
import WButton from '../inputs/WButton/WButton';
import { resetPreRegistrationInfo } from '../../Actions/AccountRegistrationActions';

const assignObj = (obj) => obj;
const message = 'Your session has Timedout. Please login again.';

const TimeOutModal = (props) => {
    const { show } = props;
    const history = useHistory();

    const handleOk = () => {
        const { resetInfo } = props;
        // setApiConfig({});
        // setUser({});
        // setUserDetails({});
        resetInfo();

        history.push({
            pathname: '/accountRegistration/Signout',
        });
        props.modalClick();
    };

    if (show) {
        return (
            <WModal
                style={styles.popupModalStyle}
                closebutton=""
                header="Session Timeout"
                modalheaderstyle={styles.popupModalHeaderStyle}
                modalfooterstyle={styles.popupModalFooterStyle}
                headertextstyle={styles.popupModalHeaderTextStyle}
                modalbodystyle={styles.popupModalBodyStyle}
                size="lg"
                dialogClassName="myModal"
                show={show}
        body={(
                    <div style={styles.popupModalDivStyle}>
                        <div style={styles.popupModalBodyTextStyle}>{message}</div>
                    </div>
        )}
        footer={(
                    <Row
                        style={assignObj({
                            justifyContent: 'space-around',
                            marginTop: 10,
                            width: '100%',
                        })}
                        noGutters
                    >
                        <WButton buttontext="Ok" onClick={handleOk} />
                    </Row>
        )}
            />
        );
    }
    return null;
};

TimeOutModal.propTypes = {
    modalClick: PropTypes.func,
    show: PropTypes.bool,
    resetInfo: PropTypes.func,
    // message: PropTypes.string
};

TimeOutModal.defaultProps = {
    modalClick: () => {},
    show: false,
    resetInfo: () => {},
    // message: "Error Message"
};

const mapDispatchToProps = {
    resetInfo: resetPreRegistrationInfo,
};

export default connect(null, mapDispatchToProps)(TimeOutModal);
