import styled from 'styled-components';
import expand from 'assets/icon-accod-small-close-hover.svg';
import collapse from 'assets/icon-accod-open-small-hover.svg';

const AccordianSymbol = styled.button.attrs(props =>({'aria-expanded':true}))`
    
    padding: 0;
    border: none;
    background: none;
    &::before {
        content: '';
        background: url(${props => (props.collapse ? collapse : expand)}) no-repeat;
        height: 1.25rem;
        width: 1.25rem;
        display: inline-block;
        left: 0;
        top: 0;
        margin-right: 1.25rem;
    }
    &.expand {
        &::before {
            content: '';
            background: url(${props => (props.collapse ? expand : collapse)}) no-repeat;
        }
        .attrs;
    }
    font-size: 22px;
    color: rgb(0, 74, 152);
    font-family: 'yorkten-slab-normal';
    font-weight: 800;
    padding-left: ${props => (props.requireButtonPadding ? 0.9375 : 0)}rem;
    cursor: pointer;
    line-height: 45px;
    margin-bottom: 0px;
    @media (max-width: 767px){
        font-size: 20px;
        &::before {
            margin-right: 0.625rem !important;
            top: 3px;
            position: relative;
        }
    }
`;

export default AccordianSymbol;
