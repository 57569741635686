import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import {loginActions, localStateManagementActions} from '../../../../shared/Actions';
import OtpAuthenticationComponent from './OtpAuthenticationComponent';

export const mapStateToProps = (state /* , props */) => (
    {
      loginState: state.loginData,
      otpAuthState: state.localStateManagementReducerData.otpAuthState,
      loggedUserDetails: state.loginData.getCustomerProfile,
      customerProfile: state.localStateManagementReducerData.customerProfile,
      localdata: state.localStateManagementReducerData
    }
  );


const mapDispatchToProps = {
     ...loginActions,
     ...localStateManagementActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(OtpAuthenticationComponent));