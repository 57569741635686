import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

const VerticalSeparator = styled.div`
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    @media (max-width: 991px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
`;
const StyledCard = styled(Card)`
    margin: 2.5rem 0;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #486D90;
        position: absolute;
        }
`;
const FlexDiv = styled.div`
    display: flex;
    padding: 1.60625rem 2.5rem 1.64375rem 2.5rem;
`;
const AccountCardFlex = styled.div`
    display: flex;
`;
const AccountCardTopSection = styled(AccountCardFlex)`
    justify-content: space-between;
    text-align: left;
    font: 800 18px/22px yorkten-slab-normal;
    letter-spacing: 0;
    color: #486D90;
    border-bottom: 0.0625rem solid #D2D2D2;
    opacity: 1;
    padding: 1.25rem 0 1.375rem 2.5rem;
    align-items: baseline;
`;
const AccountCardBottomSection = styled.div`
    text-align: left;
    letter-spacing: 0;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 30%;
`;
const AccountCardHeadingSpan = styled.div`
    margin-bottom: 0.625rem;
    text-align: left;
    font: 600 16px/22px benton-sans;
    color: #49494A;
    opacity: 1;
`;
const AccountCardValueSpan = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
`;

const BankAccountsComponent = props => {
    const { accName, accNumber, transitRoutingNumber, bankAccountType } = props;
    const { text: accNumberText = '', value: accNumberValue = '' } = accNumber || {};
    const { text: transitRoutingText = '', value: transitRoutingValue = '' } = transitRoutingNumber || {};
    const { text: bankAccountTypeText = '', value: bankAccountTypeValue = '' } = bankAccountType || {};

    return (
        <StyledCard>
            <AccountCardTopSection>
                <div>{accName}</div>
            </AccountCardTopSection>
            <FlexDiv>
                <AccountCardBottomSection>
                    <AccountCardHeadingSpan>{accNumberText}</AccountCardHeadingSpan>
                    <AccountCardValueSpan>{accNumberValue}</AccountCardValueSpan>
                </AccountCardBottomSection>
                <VerticalSeparator />
                <AccountCardBottomSection>
                    <AccountCardHeadingSpan>{transitRoutingText}</AccountCardHeadingSpan>
                    <AccountCardValueSpan>{transitRoutingValue}</AccountCardValueSpan>
                </AccountCardBottomSection>
                <VerticalSeparator />
                <AccountCardBottomSection>
                    <AccountCardHeadingSpan>{bankAccountTypeText}</AccountCardHeadingSpan>
                    <AccountCardValueSpan>{bankAccountTypeValue}</AccountCardValueSpan>
                </AccountCardBottomSection>
            </FlexDiv>
        </StyledCard>
    )
}

BankAccountsComponent.propTypes = {
    accName: PropTypes.string,
    accNumber: PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
    }),
    transitRoutingNumber: PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
    }),
};

BankAccountsComponent.defaultProps = {
    accName: "",
    accNumber: {},
    transitRoutingNumber: {},
};

export default BankAccountsComponent;