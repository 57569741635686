/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-bootstrap';
import VCMCheckbox from 'modules/AccountRegistration/components/inputs/VCMCheckbox';
import { get, isEmpty } from 'lodash';
import { Redirect } from 'react-router';
import iconPDf from 'assets/icon-pdf.png';
import WStepper from 'modules/AccountRegistration/components/WStepper';
import consts from 'modules/AccountRegistration/components/constants';
import 'modules/AccountRegistration/AccountRegistration.css';
import { WStepper as UserMngmtStepper, CommonButtons } from  'common';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import { localStateManagementActions, analytics} from 'shared/Actions';
import routeConstants from "routeConstants";
import signUpPages from 'modules/UserManagementAndSecurityModule/Screens/SignUpConstants';
import './TermsAndCondition.css';
import PrintPdf from './PrintPdf';
import envConfig from 'env/EnvConfig';

const VictoryCapitalOnlineAgreement = {
  url: envConfig.TERMS_PDF_AGREEMENT,
  withCredentials: true
}

const VictoryCapitalPrivacyStatement = {
  url: envConfig.TERMS_PDF_PRIVACY,
  withCredentials: true
}

const VictoryCapitalTermsOfService = {
  url: envConfig.TERMS_PDF_SERVICE,
  withCredentials: true
}

class TermsAndCondition extends React.Component {

  data = {
    displayNone:{display: 'none'},
    emptyStyle: {}
  }

  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      pdfFile: null,
      showPdf: false,
      type: '',
      isEnabledTerms: false,
      isEnabledAgreement: false,
      isEnabledPrivacy: false,
      isTermsChkDisabled: true,
      isAgreeChkDisabled: true,
      isPrivacyChkDisabled: true,
      errorMsg: '',
      isValidESign: false,
      signedName: '',
      currentDate: `${date.getMonth()
        + 1}/${date.getDate()}/${date.getFullYear()}`,
      ...props.personalDetailsState

    };
    this.handleHide = this.handleHide.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
    this.handleChangeTerms = this.handleChangeTerms.bind(this);
    this.handleChangeAgreement = this.handleChangeAgreement.bind(this)
    this.GIACTCustomerVerification = this.GIACTCustomerVerification.bind(this);
    this.agreeRef = React.createRef(this);
    this.privacyRef = React.createRef(this);
    this.termsRef = React.createRef(this);
  }

  componentDidMount() {
    const { personalDetailsState} = this.props;
    const { uuid } = personalDetailsState;
    if(!uuid)
    document.title = 'Terms and conditions: Pre-registration: VCM';
    // resetFlag();
  }

  
componentWillUnmount() {
  const { analyzeFormAdandonment } = this.props;
  const digitalData = getDigitalDataFormAband();
  analyzeFormAdandonment(digitalData);
}

  onBlurInput = (e) => {
    const { personalDetailsState } = this.props;
    const {firstName: firstname, lastName: lastname } = personalDetailsState;
    const txtValue = e.target.value;

    if (
      e.target.value !== ''
      && txtValue.toLowerCase()
      !== `${firstname.toLowerCase()} ${lastname.toLowerCase()}`
    ) {
      this.setState({ errorMsg: `First & Last name not matching with ${firstname} ${lastname}`, isValidESign: false });
    }
    if (
      txtValue.toLowerCase()
      === `${firstname.toLowerCase()} ${lastname.toLowerCase()}`
    ) {
      this.setState({ signedName: txtValue, isValidESign: true });
    }
  }

  handleHide = (pdfType) => {
    this.setState({ showPdf: false });
    switch (pdfType) {
      case 'VCOnlineAgree':
        this.agreeRef.current.focus();
        break;
      case 'VCPrivacy':
        this.privacyRef.current.focus();
        break;
      case 'VCTerms':
        this.termsRef.current.focus();
        break;
      default:
        break;
    }
  }
  
  saveToLocal = () => {
    const { isEnabledPrivacy, isEnabledTerms, isEnabledAgreement, signedName, currentDate,isTermsChkDisabled, isAgreeChkDisabled, isPrivacyChkDisabled, isValidESign } = this.state;
    const { personalDetailsState, manageLocalState } = this.props;
    const localData = {
        personalDetailsState:  {
          ...personalDetailsState,
          signedName,
          isEnabledTerms,
          isEnabledAgreement,
          isEnabledPrivacy,
          isTermsChkDisabled,
          isAgreeChkDisabled,
          isPrivacyChkDisabled,
          currentDate,
          isValidESign,
      }
    }; 
    manageLocalState(localData);
  }
  
  handleBack = () => {
      const { history, pageData = {}  } = this.props;
      this.saveToLocal();
      if (!isEmpty(pageData.backLink)){
         history.push(pageData.backLink)
      } else {
        history.push('/sign-in');
      }
  };

  GIACTCustomerVerification = () => {
    const { isEnabledPrivacy, isEnabledTerms, isEnabledAgreement, isValidESign} = this.state;
    const { history, personalDetailsState, pageData = {} } = this.props;
    const { uuid } = personalDetailsState;
    if (
      isEnabledPrivacy
      && isEnabledTerms
      && isEnabledAgreement
      && isValidESign
    ) {
      if(uuid) {
        this.saveToLocal();

        if (!isEmpty(pageData)){
            history.push(routeConstants.userDetails)
        } else {
          history.push(routeConstants.registerOnlineId);
        }
      }
      
    }
  }

  handleButtonClick(pdfType) {
    let file;
    let {
      isTermsChkDisabled,
      isPrivacyChkDisabled,
      isAgreeChkDisabled,
    } = this.state;
    // setType(pdfType);
    switch (pdfType) {
    case 'VCOnlineAgree':
      file = VictoryCapitalOnlineAgreement;
      isAgreeChkDisabled = false;
      break;
    case 'VCPrivacy':
      file = VictoryCapitalPrivacyStatement;
      isPrivacyChkDisabled = false;
      break;
    case 'VCTerms':
      file = VictoryCapitalTermsOfService;
      isTermsChkDisabled = false;
      break;
    default:
      break;
    }
    this.setState({
      type: pdfType,
      pdfFile: file,
      showPdf: true,
      isAgreeChkDisabled,
      isPrivacyChkDisabled,
      isTermsChkDisabled,
    });
  }


  /* handleButtonClick = (pdfType) => {
    let file;
    let { isTermsChkDisabled, isPrivacyChkDisabled, isAgreeChkDisabled } = this.state;
    // setType(pdfType);
    switch (pdfType) {
      case 'VCOnlineAgree':
        analytics.triggerClickTrackEvent(
          'Preregister_termsandconditions_Victory_Capital_Online_Agreement',
        );
        file = VictoryCapitalOnlineAgreement;
        isAgreeChkDisabled = false;
        break;
      case 'VCPrivacy':
        analytics.triggerClickTrackEvent(
          'Preregister_termsandconditions_Victory_Capital_Privacy_Notice',
        );
        file = VictoryCapitalPrivacyStatement;
        isPrivacyChkDisabled = false;
        break;
      case 'VCTerms':
        analytics.triggerClickTrackEvent(
          'Preregister_termsandconditions_Victory_Capital_Terms_of_Service',
        );
        file = VictoryCapitalTermsOfService;
        isTermsChkDisabled = false;
        break;
      default:
        break;
    }
    this.setState({
      type: pdfType,
      pdfFile: file,
      showPdf: true,
      isAgreeChkDisabled,
      isPrivacyChkDisabled,
      isTermsChkDisabled
    });
  }; */

  handleChangePrivacy(newValue) {
    this.setState({ isEnabledPrivacy: newValue });
  };

  handleChangeTerms(newValue) {
    this.setState({ isEnabledTerms: newValue });
  };

  handleChangeAgreement(newValue) {
    this.setState({ isEnabledAgreement: newValue });
  }

  renderWStepper = () => {
    const { pageData = {} } = this.props;
    const { stepperPage, isMemberSignUp=false, isProspectSignUp=false} = pageData;
      if(isProspectSignUp){
        return <UserMngmtStepper currentPage={0} pages={stepperPage} />;
      }
      if(isMemberSignUp){
        return <UserMngmtStepper currentPage={1} pages={stepperPage} />;
      }
  }

  render() {
    const { pdfFile,
      showPdf,
      type,
      isPrivacyChkDisabled,
      isAgreeChkDisabled,
      isTermsChkDisabled,
      isEnabledTerms,
      isEnabledPrivacy,
      isEnabledAgreement,
      currentDate,
      errorMsg,
      signedName } = this.state;
    const { termsAndConditionsPDF, pages } = consts;
    const { personalDetailsState, pageData } = this.props;
    const {uuid='',firstName: firstname, lastName: lastname} = personalDetailsState;
    const {isMemberSignUp=false, isProspectSignUp=false} = pageData;
    const memberSignUpFlow = isMemberSignUp || isProspectSignUp;
    
    let wrapperClassName = "milestone";
    
    if(uuid) {
      wrapperClassName += " userManagemntTerms";
    }
  
    const path = window.location.pathname;
    const isValid = isEnabledPrivacy
      && isEnabledTerms
      && isEnabledAgreement
      && signedName.toLowerCase()
        === `${firstname.toLowerCase()} ${lastname.toLowerCase()}`;
    
    if (path===routeConstants.termsAndConditions && !uuid){
      return <Redirect to={routeConstants.signIn} push />;
    }

    return (
      <div id={uuid? "account-registration" : 'termsMileStone'} >
      <div className={wrapperClassName} >
        <PrintPdf
          file={pdfFile}
          show={showPdf}
          handleHide={this.handleHide}
          type={type}
          maximumModalHeight={window.innerHeight}
        />
        <div className="pad contentWrapper">
        { memberSignUpFlow && this.renderWStepper() }
          <Container fluid>  
            {!memberSignUpFlow && (uuid ? <UserMngmtStepper currentPage={1} pages={signUpPages} /> : <WStepper currentPage={0} pages={pages} />) 
            }
            <Row className="headingRow" noGutters>
              <Col>
                <h1 className="pageHeading pageHeader2">{consts.termsCondition}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="vcmBoxContainer">
                  <span className="madatoryText">{consts.mandatoryText}</span>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangePrivacy}
                      value={isEnabledPrivacy}
                      name="Privacy"
                      isDisabled={isPrivacyChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name1}
                      labelledby="disclaimer_privacy"
                      dataRef={this.privacyRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        onClick={() => {
                          // analytics.triggerClickTrackEvent(
                          //   'Preregister_termsandconditions_Victory_Capital_Privacy_Notice',
                          // );
                          this.handleButtonClick('VCPrivacy');
                        }}
                      >
                        {termsAndConditionsPDF.name1}
                        <span className="hidden-accessible">Opens a popup</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img
                        src={iconPDf}
                        alt=""
                        focusable="false"
                        className="pdfImage"
                      />
                      <br aria-hidden="true" />
                      <span id="disclaimer_privacy" className="tc_disclaimer">
                        By checking this box, you agree that you have reviewed our
                        Privacy Policy and you consent to the collection, use and
                        disclosure of your personal data as set out in our Privacy
                        Policy.
                  </span>
                    </p>
                  </div>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangeTerms}
                      value={isEnabledTerms}
                      name="Terms"
                      isDisabled={isTermsChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name2}
                      labelledby="disclaimer_terms"
                      dataRef={this.termsRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        onClick={() => {
                          // analytics.triggerClickTrackEvent(
                          //   'Preregister_termsandconditions_Victory_Capital_Terms_of_Service',
                          // );
                          this.handleButtonClick('VCTerms');
                        }}
                      >
                        {termsAndConditionsPDF.name2}
                        <span className="hidden-accessible">Opens a popup</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img
                        src={iconPDf}
                        alt=""
                        focusable="false"
                        className="pdfImage"
                      />
                      <br aria-hidden="true" />
                      <span id="disclaimer_terms" className="tc_disclaimer">
                        By checking this box, you agree that you have reviewed and
                        agreed to the Terms of Service contained herein to use our
                        site, including any legal or regulatory restrictions.
                  </span>
                    </p>
                  </div>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangeAgreement}
                      value={isEnabledAgreement}
                      name="Agreement"
                      isDisabled={isAgreeChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name3}
                      labelledby="disclaimer_agreement"
                      dataRef={this.agreeRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        onClick={() => {
                          // analytics.triggerClickTrackEvent(
                          //   'Preregister_termsandconditions_Victory_Capital_Online_Agreement',
                          // );
                          this.handleButtonClick('VCOnlineAgree');
                        }}
                      >
                        {termsAndConditionsPDF.name3}
                        <span className="hidden-accessible">Opens a popup</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img
                        src={iconPDf}
                        alt=""
                        focusable="false"
                        className="pdfImage"
                      />
                      <br aria-hidden="true" />
                      <span id="disclaimer_agreement" className="tc_disclaimer">
                        By checking this box you confirm your agreement to sign
                        this document electronically and acknowledge that your
                        electronic signature shall have the same force and effect
                        as a physical signature
                  </span>
                      <br aria-hidden="true" />
                      <br aria-hidden="true" />
                      <span className="privacyPolicySpan">
                        Note: You can access Privacy Policy, Terms of Service, and signed copy of Online Agreement any time from
                        {' '}
                      </span>
                      <a
                        href="https://www.vcm.com/"
                        className="inlineLInk linkCls privacyPolicyLink"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.vcm.com
                      </a>
                      <span>.</span>
                </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="signContanier" noGutters>
              <Col  md lg xl>
                <div>
                  <label htmlFor="vcm_name" className="inputLabel">
                    Type your name here
              </label>
                </div>
                <div>
                  <input
                    type="text"
                    id="vcm_name"
                    value={signedName}
                    required
                    aria-required
                    className={errorMsg.length ? 'errorField analytics-form-fields' : 'analytics-form-fields'}
                    aria-describedby="errorMessage vcm_name_desc"
                    aria-invalid={!!errorMsg.length}
                    aria-required
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({
                        signedName: e.target.value,
                        errorMsg: ''
                      });
                    }}
                    onBlur={(e) => this.onBlurInput(e)}
                  />
                </div>
                <p aria-hidden="true">{`/${firstname} ${lastname}/`}</p>
                <p
                  className="errorText"
                  id="errorMessage"
                  // aria-live="polite"
                  // aria-atomic="true"
                  // aria-relevant="all"
                  role="alert"
                >
                  {errorMsg}
                </p>
                {/* <div
              className="errorMsg sr-only"
              aria-live="polite"
              aria-atomic="true"
            >
              One Error found in the page
              {apperror}
            </div> */}
                <label className="announceLabel">
                  <span aria-hidden="true">
                    To accept Terms and Conditions, please type your name exactly
                    the way it&apos;s shown above
              </span>
                  <span className="sr-only" id="vcm_name_desc">
                    To accept Terms and Conditions, please type your name exactly
                    as
                {' '}
                    {` ${firstname} ${lastname}`}
                  </span>
                </label>
              </Col>
              <Col lg={3} md={5} className="dateComp">
                <Row>
                  <Col lg={12} md={12}>
                    <div className="dateLblContainer">
                      <label htmlFor="vcm_date" className="inputLabel m-0">
                        Date
                      </label>
                    </div>
                  </Col>

                  <Col lg={12} md={12}>
                    <div className="dateTxtContainer">
                      <input
                        type="text"
                        id="vcm_date"
                        value={`${currentDate}`}
                        readOnly
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                {!uuid && <Row>
                  <Col lg={12} md={12}>
                    <div className="btnWrapper" style={{ marginTop: 10 }}>
                      <div aria-hidden="true" />
                      <button
                        className="vcmbtn vcmTermsBtn"
                        type="submit"
                        disabled={!isValid}
                        onClick={this.GIACTCustomerVerification}
                      >
                        <span className="vcmBtnText">
                          {!isValid ? consts.termsCondition : consts.continueP}
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>}
              </Col>
            </Row>
          </Container>
        </div>
        {uuid && <CommonButtons
            nextClick={this.GIACTCustomerVerification}
            // style={commonButtonStyle}
            backClick={this.handleBack}
            backstyle={memberSignUpFlow ? this.data.emptyStyle : this.data.displayNone}
            disabled={!isValid}
            arialabelback="Back to Personal Details"
            dataAnalyticId="UMS_Signup_TermsandConditions"
        />}
      </div>
      </div>
    );
  }
}



TermsAndCondition.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  // storeESign: PropTypes.func,
  // resetFlag: PropTypes.func,
  personalDetailsState: PropTypes.instanceOf(Object),
  pageData: PropTypes.instanceOf(Object),
  manageLocalState: PropTypes.func,
};

TermsAndCondition.defaultProps = {
  firstname: '',
  lastname: '',
  history: {},
  // storeESign: () => {},
  // resetFlag: () => {},
  personalDetailsState: {},
  pageData: {},
  manageLocalState: () => {},
};

const mapStateToProps = (state) => ({
    firstname: get(state, 'localStateManagementReducerData.personalDetailsState.firstName', ''),
    lastname: get(state, 'localStateManagementReducerData.personalDetailsState.lastname', ''),
    personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    pageData: state.localStateManagementReducerData.pageData,
  });

const mapDispatchToProps = {
  // storeESign: storeESignature,
  // resetFlag: resetWelcomPageFlag,
  ...localStateManagementActions,
  ...analytics
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);