const summaryHeadingStyle = {
    font: '800 30px/36px yorkten-slab-normal',
    color: 'rgba(72, 109, 144, 1)',
    fontSize: 30,
    float: 'left',
    paddingBottom: 5,
    borderBottom: '2px solid rgba(140, 191, 38, 1)',
    marginBottom: 40,
};

const headerStyle = {
    color: 'rgba(0, 74, 152, 1)',
    marginTop: 37,
    marginBottom: 47,
    font: '800 16px/20px yorkten-slab-normal',
    background: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: 42,
    position: 'relative',
    height: 32,
    textTransform: 'capitalize',
    opacity: 1,
};

const navStyle = {
    width: 'fit-content',
    border: 'none',
    paddingBottom: '20px',
};

const navLinkStyle = { marginRight: 0, paddingRight: 0 };

const iconImgStyle = { position: 'absolute', top: 0, left: 0, width: 32, height: 32 };

export default {
    navStyle,
    navLinkStyle,
    headerStyle,
    summaryHeadingStyle,
    iconImgStyle,
};
