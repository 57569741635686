const pageHeading = 'Delivery Settings';
const columnHeading = 'Get Notified By ';
const messageColumnHeading = 'Urgent Messages';
const notificationsColumnHeading = 'Notifications';
const messageColumnSubtext = 'Notify me of security issues, profile updates and specified account activity, and any other messages that I have set up as urgent.';
const notificationsSubtext = 'Notify me of new documents, saved application forms, account updates, and any other messages I have set up.';
const readMoreText = 'Read more';
const emailText = 'Email';
const pushText = 'Push Notification';
const pushSubtext = 'Explain Push Notification';
const messageText = 'Text Message';
const messageSubtext = 'Explain Text Message';
const editText = 'Edit';
const callTimeText = 'Call Time Preference';
const callTimeDescription1 = 'What is your preferred availability?';
const optional = 'optional';
const callTimeDescription2 = 'This selection will apply to all of the phone numbers listed on your profile.';
const callTimeDescription3 = 'If a preferred time to call you is not selected, we will continue to call you during VCM business hours and as permitted by law.';
const quietTimeText = 'Quiet Time';
const quietDescription1 = 'Quiet Time tells us when not to disturb you - we will delay text/SMS messages during your selected timeframe. However, situations that require immediate attention will still trigger a text/SMS messages.';
const quietDescription2 = 'Central Time (America/Chicago) is set by default. Time zone will update when you change the Start time or End Time.';
const startText = 'Start Time';
const endText = 'End Time';
const timeZone = 'Time Zone';
const selectText = 'Select';
const disclaimer1 = 'During the plot phase, certain limited alerts and notification related to specific programs and products will take priority above the preferences set in inbox.';
const disclaimer2 = 'Message categorization is provided as a tool but should not be relied on to determine which message need to be reviewed. All messages provided in inbox should be reviewed.';
const disclaimer3 = 'Text message frequency depends on your service selections. To cancel text message services at any time text ';
const disclaimerbold1 = 'STOP ';
const disclaimerto = 'to ';
const disclaimerbold2 = 'MyVCM (XXXXXX) ';
const disclaimer4 = ' from your mobile device. For more information, text ';
const disclaimerbold3 = ' HELP ';
const disclaimerbold4 = ' MyVCM (XXXXXX).';
const disclaimer5 = ' Message and Data Rates May Apply.';
const disclaimer6 = 'For VCM text messaging support call ';
const disclaimerLink1 = '1-877-632-3002.';
const disclaimer7 = 'Support mobile carriers include AT&T, Sprint, T-Mobile, U.S. Cellular,Verizon Wireless - and more. View the entire list of ';
const disclaimerLink2 = 'Supported Mobile Carriers';
const breadcrumbData = [
    { url: '/accountHolder', name: 'Home' },
    { url: '/profile', name: 'Profiles & Preferences' }
];

const timeZoneOptions = [
    {key:'eastern',value:'Eastern'},
    {key:'central',value:'Central'},
    {key:'mountain',value:'Mountain'},
    {key:'pacific',value:'Pacific'},
];

const timeZoneAbbr = {
    EST: 'Eastern',
    EDT: 'Eastern',
    CST: 'Central',
    CDT: 'Central',
    MST: 'Mountain',
    MDT: 'Mountain',
    PST: 'Pacific',
    PDT: 'Pacific',
};

const preferredTime = [
    {key: 'morning' ,value:'Morning (8am - 10am)'},
    {key: 'mid-day' ,value:'Mid-Day (10am - 4pm)'},
    {key: 'early evening' ,value:'Early Evening (4pm - 6pm)'},
    {key: 'night' ,value:'Night (6pm - 9pm)'}
];

const activePage = 'Delivery Settings';

const emailData = 'neilwins@hotmail.com';

const deviceData = 'iPhone XR';

const phoneNumberData = 'Register number';

const emailSelections ={
    urgentMessages: true,
    notifications: false
};

const pushNotificationSelection ={
    urgentMessages: true,
    notifications: true
};

const messageSelections ={
    urgentMessages: false,
    notifications: false
};

const defaultValues = {
    phoneNumber: '000000000',
    phoneCountryCode: '+1',
    email: '--@--',
    timeZone: 'Central'
};

const fallbackServiceHours = {
    general: [{
        days: '',
        hours: ''
    }],
    custom: [{
        days: '',
        hours: ''
    }]
};

/* styles */

const select = {
    width: 160,
    height: 50,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #D2D2D2',
    textIndent: '5px'

};

const hintIconStyle = { height: 24,width:24,display:'inline'};
const hintIconSmallStyle = { width: 18, height:18}
const notificationIconStyle = { height: 24,width:20};

const mailIconStyle = { height: 16,width:25};
const pushNotificationStyle = { height: 25,width:20};

const iconStyle= { display: 'inline'};
const messageIconStyle = { height: 16,width:23,color: '#8BC105',margin:0};
const phoneIconStyle = { color: '#8BC105',display:'inline',margin:0,height: 25,width:25};
const messageIconSvgStyle = {margin: 0 };
const checkInputStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
border: '1px solid #56565A',
opacity: 1,
marginRight:14,
height: 25,
width: 25
};
const  checkboxwrapperstyle = {position: 'relative'};
const checkboxstyle ={ position: 'absolute', top: '7px' };
const labelstyle = {
    textAlign: 'left',
font: '600 14px/20px benton-sans',
letterSpacing: 0.28,
color: '#56565A',
opacity: 1,
marginRight:0
};
const selectArrow = {
    position: 'absolute',
    right: '16px',
    top: '16px',
    width: '10px',
    height: '10px',
    border: 'solid #4F799F',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};
const outlineButtonStyle = {
    backgroundColor: '#FFFFFF',
    color: '#544A54',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'benton-sans',
    fontWeight: 600,
    height: 50, width: 160,
    border: '1px solid #61285F45'
}

const notificationUrgent = { 
    emailUrgent : "urgentMessagePreference.preferEmail",
    pushUrgent: "urgentMessagePreference.preferPushChannel",
    messageUrgent: "urgentMessagePreference.preferTextMessage",
}

const notificationPref = {
    emailNotification:"notificationPreference.preferEmail",
    pushNotification:"notificationPreference.preferPushChannel",
    messageNotification:"notificationPreference.preferTextMessage",
}

const quietTime = {
    selected: 'quiteTimePreference.selected',
    zone: 'quiteTimePreference.timeZone',
    timeStart: 'quiteTimePreference.startTime',
    timeEnd:'quiteTimePreference.endTime'
}

const callTime = {
    zone: 'callTimePreference.timeZone',
    time: "callTimePreference.time",
}

const fmt = 'HH:mm';
const fmt12 = 'HH:mm A';

const vcmMemberServiceSupportTitle = `VCM's Member Services`;
const vcm4038ServiceSupportTitle = `403B support Service`;

export default {disclaimer1,disclaimer2,disclaimer3,disclaimer4,disclaimer5,disclaimer6,disclaimer7,
    disclaimerbold1,disclaimerbold2,disclaimerbold3,disclaimerbold4,disclaimerto,disclaimerLink1,
    disclaimerLink2,emailSelections,selectText,
    outlineButtonStyle,selectArrow,checkboxstyle,labelstyle,checkboxwrapperstyle,checkInputStyle,
    preferredTime,select,timeZone,optional,messageIconSvgStyle,messageIconStyle,phoneIconStyle,
    pushNotificationSelection,messageSelections,pageHeading,hintIconStyle,hintIconSmallStyle,
    iconStyle,notificationIconStyle,pushNotificationStyle,mailIconStyle,activePage,columnHeading,
    messageColumnHeading,notificationsColumnHeading,messageColumnSubtext,
    notificationsSubtext,readMoreText,emailText,pushText,pushSubtext,messageText,messageSubtext,
    editText,callTimeText,callTimeDescription1,timeZoneOptions,callTimeDescription2,
    callTimeDescription3,quietTimeText,emailData,deviceData,
    phoneNumberData,quietDescription1,quietDescription2,startText,endText,breadcrumbData, 
    notificationUrgent, notificationPref, quietTime, callTime, fmt, fmt12,
    vcmMemberServiceSupportTitle, vcm4038ServiceSupportTitle, defaultValues, timeZoneAbbr, fallbackServiceHours};