import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";


export const getEligibleAccounts = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.GET_RMD_ACCOUNTS,
    });
    const env = '';
    makeApiRequest(
        ActionTypes.GET_RMD_ACCOUNTS,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_RMD_ACCOUNTS_SUCCESS,
                    payload: response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_RMD_ACCOUNTS_FAILURE,
                    payload: response
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_RMD_ACCOUNTS_FAILURE,
                payload: error
            });
        }
    );
};

export const getRMDTransactions = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.GET_RMD_TRANSACTIONS,
    });
    const env = '';
    makeApiRequest(
        ActionTypes.GET_RMD_TRANSACTIONS,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_RMD_TRANSACTIONS_SUCCESS,
                    payload: response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_RMD_TRANSACTIONS_FAILURE,
                    payload: response
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_RMD_TRANSACTIONS_FAILURE,
                payload: error
            });
        }
    );
};


export const addRMDTransaction = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.ADD_RMD_TRANSACTIONS,
    });
    const env = '';
    makeApiRequest(
        ActionTypes.ADD_RMD_TRANSACTIONS,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.ADD_RMD_TRANSACTIONS_SUCCESS,
                    payload: response
                });
            } else {
                dispatch({
                    type: ActionTypes.ADD_RMD_TRANSACTIONS_FAILURE,
                    payload: response
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.ADD_RMD_TRANSACTIONS_FAILURE,
                payload: error
            });
        }
    );
};

export const deleteRMDTransaction = payload => (dispatch) => {
    dispatch({
        type: ActionTypes.DELETE_RMD_TRANSACTIONS,
    });
    const env = '';
    makeApiRequest(
        ActionTypes.DELETE_RMD_TRANSACTIONS,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.DELETE_RMD_TRANSACTIONS_SUCCESS,
                    payload: response
                });
            } else {
                dispatch({
                    type: ActionTypes.DELETE_RMD_TRANSACTIONS_FAILURE,
                    payload: response
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.DELETE_RMD_TRANSACTIONS_FAILURE,
                payload: error
            });
        }
    );
};
export const updateRmdList = (payload) => dispatch => {
    dispatch({
        type: ActionTypes.GET_RMD_UPDATED_LIST,
        accNumber: payload.accountNumber
    });
};

export const resetRmdList = () => dispatch => {
    dispatch({
        type: ActionTypes.GET_RMD_RESET,
    });
};


export const clearFundPlanInfo = () => dispatch => {
    // DISPATCHING ACTION FOR CLEARING FUND PLAN 
    dispatch({
        type: ActionTypes.GET_RMD_PLAN_DETAILS_RESET,
    });
};

export const getStateCodeGroup = payload => {
    return dispatch => {
        try {
            const env = '';
            dispatch({
                type: ActionTypes.RMD_GET_STATE_CODE_GROUP_LOADER,
                payload: { isLoading: true, isError: false },
            });
            makeApiRequest(
                ActionTypes.RMD_GET_STATE_CODE_GROUP,
                payload,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.RMD_GET_STATE_CODE_GROUP_SUCCESS,
                            payload: {
                                taxGroup: response.data.taxGroup,
                                age: response.data.age,
                                state: response.data.address[0].state,
                                customerName: response.data.address[0].customerName,
                            },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: ActionTypes.RMD_GET_STATE_CODE_GROUP_FAILURE,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.RMD_GET_STATE_CODE_GROUP_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Get state taxes based on group
 */
export const getStateTaxesForGroup = payload => {
    return dispatch => {
        try {
            const env = '';
            dispatch({
                type: ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_LOADER,
                payload: { isLoading: true, isError: false },
            });
            makeApiRequest(
                ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP,
                payload,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_SUCCESS,
                            payload: {
                                stateTaxDetails: response.data,
                                distributionAmount: payload.stateTaxPayload.distributionAmount,
                                fedWithHolding: payload.stateTaxPayload.fedWithHolding,
                            },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_FAILURE,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_FAILURE,
                payload: error,
            });
        }
    };
};


export const setAggregateAmt = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_AGGREGATE_AMT,
        aggregateAmt: data,
    });
}

export const setAggregateAmtErr = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_AGGREGATE_AMT_ERR,
        aggregateAmtErr: data,
    });
}

export const setBirthDateFields = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_BIRHDAY_FIELDS,
        birthDateFields: data,
    });
}

export const setIsAggregateAmt = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_ENTER_AGGREGATE_AMT,
        enterAggregateAmt: data,
    });
}

export const setIsMRLevel = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_IS_MR_LEVEL,
        isMRLevel: data,
    });
}

export const setIsPrevYrRollOverOrTransfer = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_PREV_YR_ROLLOVER_TRANSFER_AVAILABLE,
        prevYrRolloverOrTransferAvailable: data,
    });
}

export const setIsSpouseSoleBeneficiary = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_IS_SPOUSE_SOLE_BENEFICIARY,
        isSpouseSoleBeneficiary: data,
    });
}

export const setIsTrustPrimaryBeneficiary = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_IS_TRUST_PRIMARY_BENEFICIARY,
        isTrustPrimaryBeneficiary: data,
    });
}

export const setSetUpByOwn = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_IS_SET_UP_BY_OWN,
        setUpByOwn: data,
    });
}

export const setShowChatInfoCard = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_SHOW_CHAT_INFO_CARD,
        showChatInfoCard: data,
    });
}

export const setSpouseBday = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_SPOUSE_BIRTHDAY,
        spouseBday: data,
    });
}

export const setVcmToCalcDistribution = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.RMD_SET_VCM_TO_CALC_DISTRIBUTION,
        vcmToCalcDistribution: data,
    });
}