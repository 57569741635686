import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ConfirmAccountOpening from './ConfirmAccountOpening';
import { accOpeningActions, localStateManagementActions, bankAccountAction } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    accOpeningData: state.accOpeningReducerData,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...accOpeningActions,
    ...bankAccountAction,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ConfirmAccountOpening),
);
