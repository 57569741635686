import styled from 'styled-components';

const MandatoryText = styled.p`
    text-align: right;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0;
`;

export default MandatoryText;