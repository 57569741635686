/**
 * ADP Component
 ****************************************
 * 
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to configure Delivery Settings
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { TimePicker } from 'antd';
import moment from 'moment-timezone';
import 'antd/dist/antd.css';
import {Row,Col,Table,Form} from  'react-bootstrap';
import { Redirect } from 'react-router-dom';
import notificationIcon from '../../../assets/notification.png';
import mailIcon from '../../../assets/mail.png';
import hint from '../../../assets/icon_Alert.png';
import iconAlertRed from '../../../assets/icon-Alert-Red.svg'
import textMessage from '../../../assets/Icon_TextMessage.png'
import editIcon from '../../../assets/EditIcon.png'
import phoneIcon from '../../../assets/icon_CallPrep.png'
import silentIcon from '../../../assets/noun_silent.png'
import { assignObj, formatPhoneNumber, decrypt } from '../../../utils';
import {
  WBreadCrumb,
    CommonButtons,
    WSpinner,
    WSwitch,
    WIcon,
    WInputCheckBox,
    WMessageBar,
} from '../../../common';
import Consts from "./constants";
// import SupportServicesCard from '../../ChatCobrowsingModule/SupportServicesModal/SupportServicesCard';
import { getSessionValues } from 'shared/Helpers/Utils';


const DeliverySettingsTable = styled(Table)`
&& {
    margin-bottom:35px;
    background: #fafafa;
    position: relative;
}
.borderGreen {
    border-bottom: 3px solid #8BC105;
    width: 100%;
    position: absolute;
}
tbody tr {
    min-height: 60px;
}
&& th {
    vertical-align: top;
    width: 33%;
    border-right: 8px solid #ffffff;
    border-top: none;
    border-bottom: none;
    
    &:last-child {
        border-right: none;
    }
    .wicon-component-id img {
        width: auto !important;
        height:auto !important;
    }
}
&& td {
    vertical-align: middle;
    border-top:none;
    border-right: 8px solid #ffffff;
    padding: 30px 0.75rem;
    &:last-child {
        border-right: none;
    }
    .notificationGroup {
        a {
            text-decoration:none;
        }
        padding-left:55px;
        span {
            width:70%;
        }
        button {
            width: 30%;
        }
    }
    .registerNumber {
        button {
            width:100%;
            text-align: left;
            img {
                height: 18px;
                width: 18px;
                margin-right: 10px;
                margin-top: -3px;
            }
            span {
                color: #004A98;
                display: inline-block;
                text-align: left;
                font: 600 18px/22px benton-sans;
                -webkit-letter-spacing: 0;
                -moz-letter-spacing: 0;
                -ms-letter-spacing: 0;
                letter-spacing: 0;
                opacity: 1;
                padding-top: 30px;
                padding-bottom: 10px;
                width: auto;
            }
        }
        
    }
}
`;



const PageHeading = styled.h1`
margin-top:30px;
margin-bottom: 40px;
padding-bottom: 10px;
display: inline-block;
border-bottom: 2px solid #8BC105;
text-align: left;
font: 700 30px/45px yorkten-slab-normal;
letter-spacing: 0;
color: #486D90;
opacity: 1;
`;

const RowHeading = styled.span`
text-align: left;
font: 600 20px/23px benton-sans;
letter-spacing: 0;
color: #56565A;
opacity: 1;
padding-left:15px;
padding-top:35px;
padding-bottom:35px;
`;

const SwitchWrapper = styled.td`
div:first-child {
    padding-left:33%;
}
`;

const ChannelText = styled.span`
margin-left:14px;
`;

const MessageType = styled.div`
text-align: left;
font: 600 18px/23px benton-sans;
letter-spacing: 0;
color: #56565A;
opacity: 1;

span {
    margin-left:17px;
    font: 600 22px/27px benton-sans;
}
p {
    max-width:300px;
    margin-bottom:0px;
    padding-top:10px;
    padding-bottom:10px;
    text-align: left;
    font: 400 12px/20px benton-sans;
    color: #56565A;
    opacity: 1;
}
button{
    text-align: left;
    font: 600 12px/14px benton-sans;
    color: #004A98;
    opacity: 1;
    border:none;
    background:none;
    text-decoration:underline;
    padding:0;
}
`;

const TableHeader = styled.th`
height: 129px;
&& {
padding-top:30px;
padding-left:30px;
vertical-align:center;
}
`;

const NotifyHeading = styled.span`
margin: 35px 0px 52px 0px;
text-align: left;
font: 600 22px/27px benton-sans;
letter-spacing: 0;
color: #56565A;
opacity: 1;
`;

const ChannelValue = styled.span`
display:inline-block;
text-align: left;
font: 600 18px/22px benton-sans;
letter-spacing: 0;
color: #707070;
opacity: 1;
padding-top:30px;
padding-bottom:10px;
`;

const EditLink = styled.button`
text-align: right;
text-decoration: underline;
font: 400 16px/19px benton-sans;
letter-spacing: 0;
color: #0000FF;
opacity: 1;
background:none;
border:none;
display:inline-block;
`;

const CallPreference = styled.div`
margin-bottom:40px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid rgba(183, 183, 183, 0.4);
opacity: 1;
color: #486D90;
.cardHeader {
    padding: 30px 40px;
    border-bottom: 1px solid rgba(183, 183, 183, 0.4);
    position: relative;

    .leftCornerCard {
        width: 0;
        height: 0;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #486D90;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}
select::-ms-expand {
    display: none;
}
`;

const CallTimeHeading = styled.h2`
text-align: left;
font: 900 20px/22px yorkten-slab-normal;
letter-spacing: 0;
color: #486D90;
opacity: 1;
padding:0 0 0 20px;
display:inline-block;
margin-bottom:0;
`;

const CallTimeDescription = styled.div`
text-align: left;
padding:30px 0 30px 40px;
.callTimeDescHead {
    
}
span, h3 {
    font: 400 16px/24px benton-sans;
    letter-spacing: 0.3px;
    color: #49494A;
    padding-bottom:10px;
    display:block;
    margin:0;
    padding:0;
}
h3 {
    text-align: left;
font: 600 16px/22px benton-sans;
letter-spacing: 0;
padding-bottom:10px;
display:block;
}
`;

const TimeZone = styled(Row)`
    justify-content: flex-start;
    margin: 0;
    padding: 0px 0 30px 40px;
    .col {
        max-width:160px;
    }
    input {

    }
`;

const TimeLabel = styled(Col)`
    padding-top:15px;
    text-align: left;
    font: 600 16px/22px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
    && {
        max-width:130px;
        padding: 15px 0;
    }
    span.timeLight{
        font-weight: 400;
    }
`;

const QuietTimeDescription = styled.div`
text-align: left;
margin: 0px;
padding: 30px 0 0px 40px;
span {
    font: 500 16px/20px benton-sans;
    letter-spacing: 0.3px;
    color: #49494A;
    padding-bottom:30px;
    display:block;
    margin:0;
}`;

const QuietTimeHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const QuietSwitch = styled.div`
margin; 0;
`;

const PreferedTime = styled(Row)`
&& {
    margin: 0;
    padding: 0px 0 30px 40px;
}
.col {
    padding: 0;
}
.checkBoxWrapper input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    top: 4px;
    left: 8px;
}
`;

const SupportAvailability = styled.div`
&& {
    margin:0;
    padding:30px 0 30px 40px;
}`;

const TimePickerConainer = styled(Row)`
    justify-content:flex-start;
    margin: 0;
    padding: 0px 0 30px 25px;
    vertical-align:middle;
    .col:first-child {
        padding-left:40px;
        margin-right: 26px;
    }
    .col{
        max-width:160px;
    }
    span {
        display:block;
        padding-top:15px;
        text-align: left;
        font: 600 14px/17px benton-sans;
        letter-spacing: 0;
        color: #333333DE;
        opacity: 1;    
        && {
            max-width:130px;
            padding: 15px 0;
        }
    }
    .col:nth-child(3) {
        margin-left:120px;
    }

    span.anticon-clock-circle,
    span.anticon-close-circle {
        display: inline;
    }

    span.ant-picker-suffix, 
    span.ant-picker-clear {
        padding-top: 0;
    }

    .ant-picker{
        padding: 1rem;
        width: 160px;
        height: 50px;
    }

    .time-container{
        display: flex;
        margin-right: 2rem;
    }

    .time-container .col{
        padding-left: 20px;
    }
`;

const Disclaimer = styled.div`
margin-bottom:60px;
text-align: left;
font: 400 16px/19px benton-sans;
letter-spacing: 0;
color: #49494A;
.disclaimerBold {
    font: 600 20px/19px benton-sans;
}
button {
    text-decoration: underline;
    font: 700 16px/19px benton-sans;
    color: #004A98;
}
`;

const ChannelContainer = styled.span`
    display: flex;
    justify-content: space-between;
`;

const ChannelLabel = styled.span`
    padding-left: 15px;
`;

const deliverySubmitErrorMsg = {
    message:"Submit error", 
    info:'Please select preferences for Urgent Messages and Notifications'
}
const successMessage = {
    message: "Delivery Preferences were updated succesfully",
};
const errorMessage ={
    message: "Technical error",
};

export default class DeliverySettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deliveryMethodIsSelected: true,
            onSubmitFlag: false,
            timeZone: props.callTimeZone
        };
        this.err = '';        
    }

    componentDidMount = () => {
        const { getMemberMSRPreferences, getMemberProfileInformations } = this.props;
        const { timeZoneAbbr } = Consts;
        const computedTimezone = moment.tz(moment.tz.guess()).format('z');
        // const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();  
        const {accesstoken, vcmid, msrid, fismemberid} = params;        
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid,
        }
        getMemberProfileInformations(decrypt, msrParams);
        getMemberMSRPreferences(msrParams);
        if (timeZoneAbbr[computedTimezone] && timeZoneAbbr[computedTimezone]) {
            this.setState({
                timeZone: timeZoneAbbr[computedTimezone]
            });
        }
    }

    emailClick = (e) => {
        e.stopPropagation();
        const { history, emailAddress: email } = this.props;
        const emailObj = {
            isPrimary: true,
            cardId: 'delivery',
            email
        };

        history.push(`/editEmail` , emailObj);
    }

    notificationClick = (deviceData) => e => {
        e.stopPropagation();
        const { history } = this.props;
        history.push(`/devicemanagement` , deviceData);
    }

    onPhoneNumberClick = (e) => {
        e.stopPropagation();
        const { history } = this.props;

        const phoneNumber = get(this.props, 'phoneNumber');
        const phoneCountryCode = get(this.props, 'phoneCountryCode') || Consts.defaultValues.phoneCountryCode;

        const phoneObj = {
            isMobile: false,
            isPrimary: true,
            phoneNumber,
            phoneCountryCode
        };

        // If there is no data or the phone number is the default one then redirect to Add New Phone page
        if (!phoneNumber || phoneNumber === Consts.defaultValues.phoneNumber) {
            history.push(`/addPhoneNum`, { phoneObj });
        } else {
            history.push('/editPhone', { phoneObj });
        }
    }

    handleCancel = () => {
      this.setState({back: true});
    }

    getTimezone = () => {
        const { timeZone } = this.state;
        const { callTimeZone } = this.props;

        return timeZone && timeZone !== callTimeZone ? timeZone : callTimeZone;
    };

    handleChangeTime = (propPath) => (time) => {
        const { changeMemberPreferences } = this.props;
        const newTime =  time 
         ? `${moment(time, Consts.fmt).format(Consts.fmt)}:00.000Z`
         : `${moment(time, Consts.fmt).format(Consts.fmt)}`;
        changeMemberPreferences({
            path: `deliveryPreferences.${propPath}`,
            data: newTime,
        })

        if ( propPath === Consts.quietTime.timeStart || propPath === Consts.quietTime.timeEnd){
            const zone = this.getTimezone();
            if ( zone === ''){
                changeMemberPreferences({
                    path: `deliveryPreferences.${Consts.quietTime.zone}`, 
                    data: Consts.defaultValues.timeZone
                });
            }
        }
    }

    handleChange = (propPath) => (e) => {
        const { changeMemberPreferences} = this.props;
        if(propPath === Consts.callTime.zone || propPath === Consts.quietTime.zone) {
            // Update the timeZone for call and quiet time preference
            this.setState({
                timeZone: e.target.value
            });

            changeMemberPreferences({
                path: `deliveryPreferences.${Consts.callTime.zone}`,
                data: e.target.value
            });

            changeMemberPreferences({
                path: `deliveryPreferences.${Consts.quietTime.zone}`,
                data: e.target.value
            });
        } else {
            changeMemberPreferences({
                path: `deliveryPreferences.${propPath}`,
                data: e.target.value
            })
        }
    }

    configureQuietTime = () => {
        const { changeMemberPreferences } = this.props;
        const zone = this.getTimezone();
        const updatedZone = (zone === "") ? Consts.timeZoneOptions[1].value : zone;
        const resetTime = (updatedZone === "");
        changeMemberPreferences({
            path: `deliveryPreferences.${Consts.quietTime.zone}`, 
            data: updatedZone
        });
        if ( resetTime){
            changeMemberPreferences({
                path: `deliveryPreferences.${Consts.quietTime.timeStart}`,
                data: null 
            })
            changeMemberPreferences({
                path: `deliveryPreferences.${Consts.quietTime.timeEnd}`,
                data: null 
            })
        }

    }

    configureSettings = (propPath) => () => {
        const { changeMemberPreferences,  storeData} = this.props;
        if ( propPath === Consts.quietTime.selected ){
            this.configureQuietTime();
        }else{
            changeMemberPreferences({
                path: `deliveryPreferences.${propPath}`, 
                data: !get(storeData, propPath, false)
            });
        }
    }

    handleSubmit = () =>{
        const { saveData, emailUrgent, emailNotification, messageUrgent, messageNotification } = this.props;
        if ( (emailUrgent || messageUrgent) && (emailNotification || messageNotification)){
            this.setState({onSubmitFlag:true})
            saveData();
        } else{
            this.setState({deliveryMethodIsSelected:false})
        }
    }

    backClick = () => {
        this.setState({backFlag:true});
    }

    renderDisclaimer = () => {
        return (<Disclaimer >
            <p>{Consts.disclaimer1}</p>
            <p>{Consts.disclaimer2}</p>
            <p>{Consts.disclaimer3}<span className='disclaimerBold'><b>{Consts.disclaimerbold1}</b></span>{Consts.disclaimerto}<b>{Consts.disclaimerbold2}</b>{Consts.disclaimer4}
            <b>{Consts.disclaimerbold3}</b>{Consts.disclaimerto}<span className='disclaimerBold'><b>{Consts.disclaimerbold4}</b></span>{Consts.disclaimer5}</p>
            <p>{Consts.disclaimer6}<EditLink>{Consts.disclaimerLink1}</EditLink></p>
            <p>{Consts.disclaimer7}<EditLink>{Consts.disclaimerLink2}</EditLink></p>
        </Disclaimer>);
    }

    renderDeliveryTable = () => {
        const { emailAddress, emailUrgent,emailNotification,
            messageUrgent,messageNotification, phoneNumber, phoneCountryCode } = this.props;

        return (
            <DeliverySettingsTable>
                <thead>
                    <tr>
                <TableHeader scope="col"><NotifyHeading>{Consts.columnHeading}</NotifyHeading></TableHeader>
                <TableHeader scope="col">
                    <MessageType>
                        <WIcon src={iconAlertRed} style={Consts.iconStyle} fontawesomestyle={Consts.hintIconStyle} />
                        <span>{Consts.messageColumnHeading}</span>
                        <p>{Consts.messageColumnSubtext}</p>
                    </MessageType>
                </TableHeader>
                <TableHeader scope="col">
                    <MessageType>
                        <WIcon style={Consts.iconStyle} src={notificationIcon} fontawesomestyle={Consts.notificationIconStyle} />
                        <span>{Consts.notificationsColumnHeading}</span>
                        <p>{Consts.notificationsSubtext}</p>
                    </MessageType>
                </TableHeader>                       
                    </tr>
                </thead>
                <div className="borderGreen" />
                <tbody>
                    <tr>
                        <td>
                            <RowHeading>
                                <ChannelContainer>
                                    <ChannelLabel>
                                        <WIcon style={Consts.iconStyle} src={mailIcon} fontawesomestyle={Consts.mailIconStyle} />
                                        <ChannelText>{Consts.emailText}</ChannelText>
                                    </ChannelLabel>
                                    <EditLink onClick={this.emailClick} >
                                        <img alt='edit' src={editIcon} />
                                    </EditLink>
                                </ChannelContainer>
                                <div className="notificationGroup">
                                    <ChannelValue>{emailAddress}</ChannelValue>
                                </div>
                            </RowHeading>
                        </td>
                        <SwitchWrapper>
                            <WSwitch
                                id="emailUrgent"
                                onSwitchClick={this.configureSettings(Consts.notificationUrgent.emailUrgent)}
                                checked={emailUrgent}
                                disabled
                            />
                        </SwitchWrapper>
                        <SwitchWrapper>
                            <WSwitch
                                id="emailNotification"
                                onSwitchClick={this.configureSettings(Consts.notificationPref.emailNotification)}
                                checked={emailNotification}
                                disabled
                            />
                        </SwitchWrapper>
                    </tr>

                    <tr>
                        <td>
                            <RowHeading>
                                <WIcon style={Consts.iconStyle} src={textMessage} />
                                <ChannelText>{Consts.messageText}</ChannelText>
                                <div className="notificationGroup registerNumber">
                                    <EditLink onClick={this.onPhoneNumberClick}>
                                        <img alt="info" src={hint} />
                                        <span>{formatPhoneNumber(phoneNumber, false, phoneCountryCode)}</span>
                                    </EditLink>
                                </div>
                            </RowHeading>
                        </td>
                        <SwitchWrapper>
                            <WSwitch
                                id="messageUrgent"
                                onSwitchClick={this.configureSettings(Consts.notificationUrgent.messageUrgent)}
                                checked={messageUrgent}
                            />
                        </SwitchWrapper>
                        <SwitchWrapper>
                            <WSwitch
                                id="messageNotification"
                                onSwitchClick={this.configureSettings(Consts.notificationPref.messageNotification)}
                                checked={messageNotification}
                            />
                        </SwitchWrapper>
                    </tr>
                </tbody>
            </DeliverySettingsTable>
        );
    }

    renderCallPreference = () => {
        const {
            callTimeOption,
            liveChatReducerData: {
                isSuccess,
                schedules
            }
        } = this.props;
        const timezone = this.getTimezone();

        let serviceHours;

        if(isSuccess && schedules) {
            // If the service hours from the liveChatReducerData have been retrieved
            const {
                'GENERAL_PHONE_SCHEDULE': {
                    daysAvailable: generalDaysAvailable,
                    hoursAvailable: generalHoursAvailable
                },
                '403B_PHONE_SCHEDULE': {
                    daysAvailable: customDaysAvailable,
                    hoursAvailable: customHoursAvailable
                }
            } = schedules;

            serviceHours = {
                general: [{
                    days: generalDaysAvailable,
                    hours: generalHoursAvailable || ''
                }],
                custom: [{
                    days: customDaysAvailable,
                    hours: customHoursAvailable || ''
                }]
            };
        
        } else {
            serviceHours = Consts.fallbackServiceHours;
        }

        return (
            <CallPreference>
                <div className="cardHeader">
                    <div className="leftCornerCard" />
                    <span><WIcon src={phoneIcon} style={Consts.iconStyle} fontawesomeiconstyles={Consts.messageIconSvgStyle} fontawesomestyle={Consts.phoneIconStyle} alt="phone icon" isze="2x" /></span>
                    <CallTimeHeading >{Consts.callTimeText}</CallTimeHeading>
                </div>
                <CallTimeDescription>
                    <h3 className="callTimeDescHead">{Consts.callTimeDescription1}</h3>
                    <span>{Consts.callTimeDescription2}</span>
                    <span>{Consts.callTimeDescription3}</span>
                </CallTimeDescription>
                <TimeZone>
                    <TimeLabel><span>{Consts.timeZone}</span></TimeLabel>
                    <Col>
                        <div style={Consts.selectArrow} />
                        <Form.Control
                            as="select"
                            onChange={this.handleChange(Consts.callTime.zone)}
                            id="timeZone"
                            value={timezone}
                            style={Consts.select}
                        >
                            <option value="">{Consts.selectText}</option>
                            {Consts.timeZoneOptions.map(item =>
                                <option key={item.key}>
                                    {item.value}
                                </option>
                            )}
                        </Form.Control>
                    </Col>
                </TimeZone>
                <PreferedTime>
                    {
                        Consts.preferredTime.map(time =>
                            <Col key={time.key}>
                                <WInputCheckBox
                                    checkboxwrapperstyle={Consts.checkboxwrapperstyle}
                                    checkInputStyle={Consts.checkInputStyle}
                                    checked={callTimeOption === time.key}
                                    value={time.value}
                                    onChange={this.handleChange(Consts.callTime.time)}
                                    checkboxstyle={Consts.checkboxstyle}
                                    labelstyle={Consts.labelstyle}
                                    label={time.value}
                                />
                            </Col>
                        )
                    }
                </PreferedTime>
                <SupportAvailability>
                    {/* <table>
                        <tr>
                            <td>
                                <SupportServicesCard
                                    serviceHours={serviceHours.general}
                                    titleText={Consts.vcmMemberServiceSupportTitle}
                                    hasIconNearTitle
                                />
                            </td>
                            <td>
                                <SupportServicesCard
                                    serviceHours={serviceHours.custom}
                                    titleText={Consts.vcm4038ServiceSupportTitle}
                                    hasIconNearTitle
                                />
                            </td>
                        </tr>
                    </table> */}
                </SupportAvailability>
            </CallPreference>
        );
    }

    
    renderQuietTime = () => {
        const { quietTimeSelected, quietStartTime, quietEndTime } = this.props;
        const timezone = this.getTimezone();
        return (
          <CallPreference >
              <div className="cardHeader">
              <div className="leftCornerCard" />
              <QuietTimeHeader>
                  <div >
              <span><WIcon src={silentIcon} style={Consts.iconStyle} fontawesomeiconstyles={Consts.messageIconSvgStyle} fontawesomestyle={Consts.phoneIconStyle} alt="phone icon" isze="2x" /></span>
              <CallTimeHeading >{Consts.quietTimeText}</CallTimeHeading>
              </div>
              <QuietSwitch>
              <WSwitch
                      id="quietTimeSelected"
                      onSwitchClick={this.configureSettings(Consts.quietTime.selected)}
                      checked={quietTimeSelected}
                    />
              </QuietSwitch>
              </QuietTimeHeader>
              </div>
              <QuietTimeDescription>
                  <span>{Consts.quietDescription1}</span>
                  <span>{Consts.quietDescription2}</span>
              </QuietTimeDescription>
              <TimeZone>
              <TimeLabel><span>{Consts.timeZone}</span></TimeLabel>
                <Col>
                    <div style={Consts.selectArrow} />
                    <Form.Control
                        as="select"
                        onChange={this.handleChange(Consts.quietTime.zone)}
                        id="timeZone"
                        value={timezone}
                        style={Consts.select}
                    >
                        <option value="">{Consts.selectText}</option>
                        {Consts.timeZoneOptions.map(item => 
                        <option  key={item.key}>{item.value}</option>)}
                    </Form.Control>
                </Col>
              </TimeZone>
            <Form>
                <TimePickerConainer noGutters>
                    <div className="time-container">
                        <Col md={6}>
                            <span>{Consts.startText}</span> 
                        </Col>
                        <Col md={1}>
                            <TimePicker 
                                onChange={this.handleChangeTime(Consts.quietTime.timeStart)} 
                                id="quietStartTime"
                                value={quietStartTime === 'Invalid date' ? null : moment(quietStartTime, Consts.fmt)}
                                use12Hours 
                                format="h:mm a" 
                            />
                        </Col>
                    </div>
                    <div className="time-container">
                        <Col md={4}>
                            <span>{Consts.endText}</span>
                        </Col>
                        <Col md={1}>
                            <TimePicker 
                                onChange={this.handleChangeTime(Consts.quietTime.timeEnd)} 
                                id="quietEndTime"
                                value={quietEndTime === 'Invalid date' ? null : moment(quietEndTime, Consts.fmt)}
                                use12Hours 
                                format="h:mm a" 
                            />
                        </Col>
                    </div>
              </TimePickerConainer>
            </Form>
          </CallPreference>
        );
    }

    handleCloseSaveNotification = () => {
        this.setState({onSubmitFlag:false});
    }
     
    onCloseModal = () => {
        const { resetAPIError } = this.props 
        this.setState({onSubmitFlag: false})
        resetAPIError();
    }

    closeDeliveryError = () => {
        this.setState({deliveryMethodIsSelected:true})
    }

    render() {
        const {onSubmitFlag, backFlag, deliveryMethodIsSelected} = this.state;
        const {isLoading, isError} = this.props;
        return (
            <div className="MainContainer">
                {!isLoading && !isError && onSubmitFlag && <WMessageBar
                    text={successMessage} onClose={this.handleCloseSaveNotification} />}
                {isLoading && <WSpinner loading={isLoading} />}
                {!deliveryMethodIsSelected && <WMessageBar error={!deliveryMethodIsSelected} 
                    text={deliverySubmitErrorMsg} onClose={this.closeDeliveryError} />}
                {isError && <WMessageBar error={isError} 
                    text={errorMessage} onClose={this.onCloseModal} />}
                {<div className="container">
                    <WBreadCrumb breadCrumbItems={Consts.breadcrumbData} activeCrumb={Consts.activePage} />
                    <PageHeading>{Consts.pageHeading}</PageHeading>
                    {this.renderDeliveryTable()}
                    {this.renderCallPreference()}
                    {this.renderQuietTime()}
                </div>}
                {backFlag && <Redirect to="/profile" />}
                <CommonButtons
                    style={assignObj({ padding: 20 })}
                    submit="true"
                    submitClick={this.handleSubmit}
                    backText="Back"
                    backClick={this.backClick}
                />      
            </div>
        );
    }
}

DeliverySettings.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
      }),
    getMemberPreferences: PropTypes.func,
    changeMemberPreferences: PropTypes.func,
    getMemberProfileInformations: PropTypes.func,
    saveData: PropTypes.func,
    emailUrgent: PropTypes.bool,
    emailNotification: PropTypes.bool,
    pushUrgent: PropTypes.bool,
    pushNotification: PropTypes.bool,
    messageUrgent:PropTypes.bool,
    messageNotification:PropTypes.bool, 
    emailAddress: PropTypes.string, 
    phoneCountryCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    callTimeZone: PropTypes.string,
    callTimeOption: PropTypes.string,
    quietTimeZone: PropTypes.string,
    quietStartTime: PropTypes.string,
    quietEndTime: PropTypes.string,
    storeData: PropTypes.instanceOf(Object),
    quietTimeSelected: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    liveChatReducerData: PropTypes.instanceOf(Object),
    resetAPIError: PropTypes.func
};

DeliverySettings.defaultProps = {
    history: {},
    getMemberPreferences: () => {},
    changeMemberPreferences: () => {},
    getMemberProfileInformations: () => {},
    saveData: () => {},
    emailUrgent: false,
    emailNotification: false,
    pushUrgent: false,
    pushNotification: false,
    messageUrgent:false,
    messageNotification:false, 
    emailAddress: '-------', 
    phoneNumber: '',
    phoneCountryCode: '',
    callTimeZone: "",
    callTimeOption: "",
    quietTimeZone: "",
    quietStartTime: "",
    quietEndTime: "",
    storeData: {},
    quietTimeSelected: false,
    isLoading: true,
    isError: false,
    liveChatReducerData:{},
    resetAPIError: () => {}
};
