/**
 * Modify Security Questions Component
 * -----------------------------------
 * 
 * @version 1.0.1
 * @author Manjunath V
 * @description This page contains security questions
 * @createdDate [06/11/2019]
 * @lastModifiedDate [20/11/2019]
 */

const data = {
   questions: [
       { id: 'question1', label: 'Question 1', answerId: 'answer1', question: "Which is your Favourite City?", answer: 'Milk', answerLabel: 'Answer 1' },
       { id: 'question2', label: 'Question 2', answerId: 'answer2', question: "Which is your Favourite State?", answer: 'Dal', answerLabel: 'Answer 2' },
       { id: 'question3', label: 'Question 3', answerId: 'answer3', question: "Which is your Favourite Country?", answer: 'Manali', answerLabel: 'Answer 3' },
   ],
   labelStyle: {
       display: 'block',
       marginBottom: '25px',
       font: '600 14px/17px benton-sans',
       color: '#49494A'
   },
   label: 'Update document delivery preference',
   onlineText: 'Deliver all my documents online at vcm.com',
   postText: 'Do not change my current document delivery preference (By Postal mail)',
   nextStyle: {display: 'none'},
   optionalMessage: '* All fields are required unless mentioned optional'
}

export default data;


