// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { WMessageBar } from '../../../common';

const targetPage = 'Target Page';

const assignObj = (obj) => { return obj; }

class ExchangeSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationText: null
    }
  }

  componentDidMount() {
    const orderId = 'XXXXXX1234';
    const text = `The Exchange Transaction Order ID ${orderId} has been successfully submitted`;
    if (orderId) {
      this.setState({
        notificationText: { message: text }
      })
    }
  }

  render() {
    const { notificationText } = this.state;
    return (
      <div className="MainContainer">
         <div style={assignObj({ backgroundColor: '#F9FBFC', position: 'relative' })}>
         <WMessageBar text={notificationText} />
        <div style={assignObj({
          height: '31px',
          textAlign: 'center',
          font: 'Norm ExBold 26px/45px Yorkten Slab Norm ExBold',
          letterSpacing: 0,
          color: '#56565A',
          opacity: 1,
          margin: '200px 200px 200px',
        })}>{targetPage}</div>
        <hr />
         </div>
      </div>
    )
  }
}

ExchangeSuccess.propTypes = {
  // orderId:PropTypes.string.isRequired
};

export default ExchangeSuccess;