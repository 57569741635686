
/* eslint-disable react/jsx-no-literals */

/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import WStepper from '../../components/WStepper';
import consts from '../../components/constants';
import './UserAccountInformation.css';
import { validatePhoneNumber } from '../../Utils';
import analytics from '../../components/utils.analytics';
import EmailField from '../../components/EmailField';
import PhoneField from '../../components/PhoneField';
import MemberInfo from '../../components/MemberInfo';

const { pages } = consts;


const assignObj = (obj) => { return obj; }

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoneError: false,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    const { history, accountInfo } = this.props;
    
    window.scriptLoaded = false;
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
    }
  }

  validateEmail = (updatedemail, setEmailFieldError, ) => {
    let isValid = true;
    if (isEmpty(updatedemail)) {
      if (document.getElementById('emailFld')) {
        document.getElementById('emailFld').focus();
      }
      isValid = false;
      setEmailFieldError(
          'Your email address is not valid  - Please enter a valid email address',
      );
    }
    return isValid;
  }


  handleButtonClick() {
    const {
      validatePassCode,
      accountInfo,
      passcode,
      userFields,
      receivedEmailOTP,
      history,
      emailOTPError,
      resetFlagsForListingPage,
      updatedemail,
      setEmailFieldError,
    } = this.props;
    
    let isValid = true;

    const {
      primaryPhoneCountryCode,
      primaryPhone,
      secondaryPhone,
      secondaryPhoneCountryCode,
    } = userFields;

    isValid = this.validateEmail(updatedemail, setEmailFieldError);

    if (isValid) {
      isValid = validatePhoneNumber(primaryPhoneCountryCode, primaryPhone)
      if (!isValid) {
        this.setState({ showPhoneError: true }, () => {
          document.getElementById('primaryphone').focus();
          setTimeout(() => {
            this.setState({ showPhoneError: false });
          }, 0);
        });
      }
    }
    if (!isEmpty(secondaryPhone) && isValid) {
      isValid = validatePhoneNumber (secondaryPhoneCountryCode,secondaryPhone);
      if (!isValid) {
        this.setState({ showPhoneError: true }, () => {
          document.getElementById('secondaryphone').focus();
          setTimeout(() => {
            this.setState({ showPhoneError: false });
          }, 0);
        });
      }
    }

    if (emailOTPError === 'Please generate a new Security Code.') {
      document.getElementById('passcode').focus();
      return;
    }
    if (isValid) {
      analytics.triggerClickTrackEvent(
        'Preregister_verifypersonalinformation_Continue',
      );
      window.scriptLoaded = false;

      if (receivedEmailOTP) {
        validatePassCode({
          ...accountInfo,
          verifyOTP: passcode,
          requestType: 'EMAIL',
          email: updatedemail,
        });
      } else {
        resetFlagsForListingPage();
        history.push('/accountRegistration/onetimecode');
      }
    }
  }

  isButtonDisabled() {
    const { updatedemail } = this.props;

    const { userFields, receivedEmailOTP, passcode } = this.props;
    if (!receivedEmailOTP) {
      return updatedemail !== userFields.email;
    }
    return passcode.length < 6;
  }

  render() {
    const { userFields, isEmailOTPValidated, updatedemail } = this.props;
    const { showPhoneError } = this.state;

    if (isEmailOTPValidated) {
      return <Redirect to="/accountRegistration/onetimecode" push />;
    }

    return (
      <div className="milestone userInfo">
        <div className="pad contentWrapper">
          <Container fluid>
            <WStepper currentPage={1} pages={pages} />
            <Row>
              <Col className="descWrapper" xs={12} sm={6}>
                <h1 className="pageHeader2">{consts.verifyInfo}</h1>
                <p className="userdetails-para">
                  Please verify your identity based on the information
                  presented.
                </p>
                <p>
                  Note: Your primary phone must be a US-based phone number for
                  our Security Code to be delivered to you to help validate your
                  identity.
                </p>
              </Col>
              <Col xs={12} sm={6}>
                <Container
                  style={assignObj({ margin: 0 })}
                  className="verifyDetailsContainer"
                >
                  <MemberInfo userFields={userFields} />
                  <div className="verifyEmailRow mb-4">
                    <p className="fieldTitle" id="email-address">
                      Primary Email Address
                    </p>
                    <Row>
                      <Col xs={12} sm={12}>
                        <EmailField
                          currentEmail={userFields.email}
                          field="email"
                          updatedemail={updatedemail}
                          ariaRequired
                          ariaLabelledby="email-address"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="verifyPhoneRow eleCardWrapComponent mb-4"
                    role="group"
                    aria-labelledby="primary-phone-number"
                  >
                    <Row>
                      <Col xs={12} sm={12}>
                        <p className="fieldTitle" id="primary-phone-number">
                          Primary Phone Number
                        </p>
                      </Col>
                      <Col xs={12} sm={12}>
                        <PhoneField
                          phoneNumber={userFields.primaryPhone}
                          type="primaryphone"
                          showPhoneError={showPhoneError}
                          mobileOpt={userFields.isMobilePrimary}
                          countryCode={userFields.primaryPhoneCountryCode}
                          ariaRequired
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="verifyPhoneRow eleCardWrapComponent mb-4"
                    role="group"
                    aria-labelledby="secondary-phone-number"
                  >
                    <p className="fieldTitle" id="secondary-phone-number">
                      Secondary Phone Number [Optional]
                    </p>
                    <Row>
                      <Col xs={12} sm={12}>
                        <PhoneField
                          phoneNumber={userFields.secondaryPhone}
                          type="secondaryphone"
                          showPhoneError={showPhoneError}
                          mobileOpt={userFields.isMobileSecondary}
                          countryCode={userFields.secondaryPhoneCountryCode}
                          isOptional
                        />
                      </Col>
                    </Row>
                  </div>
                </Container>
                <Row
                  style={assignObj({ marginTop: 50 })}
                  noGutters
                  className="btnContainer"
                >
                  <Col className="noPad" style={assignObj({ textAlign: 'center' })}>
                    {/* <Link to="/onetimecode" > */}
                    <button
                      className="vcmbtn"
                      type="submit"
                      onClick={this.handleButtonClick}
                      disabled={this.isButtonDisabled()}
                    >
                      <span className="vcmBtnText">{consts.correctInfo}</span>
                    </button>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

UserDetails.propTypes = {
  userFields: PropTypes.shape({
    primaryPhone: PropTypes.string,
    secondaryPhone: PropTypes.string,
    email: PropTypes.string,
    isMobilePrimary: PropTypes.bool,
    isMobileSecondary: PropTypes.bool,
    primaryPhoneCountryCode: PropTypes.string,
    secondaryPhoneCountryCode: PropTypes.string,
  }),
  accountInfo: PropTypes.instanceOf(Object),
  receivedEmailOTP: PropTypes.bool,
  passcode: PropTypes.string,
  validatePassCode: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isEmailOTPValidated: PropTypes.bool,
  emailOTPError: PropTypes.string,
  resetFlagsForListingPage: PropTypes.func,
  setEmailFieldError: PropTypes.func,
  updatedemail: PropTypes.string,
};

UserDetails.defaultProps = {
  userFields: {
    primaryPhone: '',
    secondaryPhone: '',
    email: '',
    isMobilePrimary: false,
    isMobileSecondary: false,
    primaryPhoneCountryCode: '',
    secondaryPhoneCountryCode: '',
  },
  accountInfo: {},
  receivedEmailOTP: false,
  passcode: '',
  validatePassCode: () => {},
  history: {},
  isEmailOTPValidated: false,
  emailOTPError: '',
  resetFlagsForListingPage: () => {},
  setEmailFieldError: () => {},
  updatedemail: '',
};

export default UserDetails;
