const consts = {
    paperFlowHeading: 'Paper',
    privacyHeading: 'Privacy Notice and Business Continuity and Cybersecurity Summary',
    privacyPara1: 'Review our Privacy Notice and Business Continuity and Cybersecurity Summary',
    privacyPara2:
        'Read about our commitment to protecting your personal information and how we will continue to serve members during a crisis',
    privacyCard1Title: 'Victory Capital Privacy Notice',
    privacyCard1Text: 'Victory Capital encrypts sensitive information to ensure it remains confidential',
    privacyCard2Title: 'Victory Capital Business Continuity and Cybersecurity Summary',
    downloadPdfText: 'Download',
    cardtitlestyle: {
        font: '800 30px/25px yorkten-slab-normal',
        marginBottom: 20,
    },
};

export default consts;
