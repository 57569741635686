import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import WCard from '../WCard/WCard';
import checkSpecimen from '../../assets/check-specimen.png';
import * as PersonalCheckConstants from '../Constants';

const PersonalCheckDiv = styled.div`
    padding: 41px 35px 41px 40px;
    margin-bottom: 54px;
    border: 2px solid rgba(139, 193, 5, 1);
    position: relative;
    font: 600 16px/22px 'benton-sans', sans-serif;
    color: #49494a;
    opacity: 1;
`;

const LeftCardCornerStyle = styled.div`
    width: 0;
    height: 0;
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8bc105;
    position: absolute;
    top: -2px;
    left: -2px;
`;

const SectionHeaderRow = styled(Row)`
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 30px;
`;

const FundTypeSpecimentWrapperRow = styled(Row)`
    margin: 30px 0;
    align-items: flex-start;
`;
const CheckSpecimenDiv = styled.div`
    padding-left: 0;
    max-width: 670px;
`;
const SpecimenCardWrapperDiv = styled.div`
    padding-left: 20px;
`;
const SectionHeadingH3 = styled.h3`
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    margin-bottom: 15px;
    color: rgba(72, 109, 144, 1);
`;

const wireTransferCardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const wireTransferCardBodyStyle = {
    paddingBottom: 0,
};

const wireTransferTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const specimenTitleStyle = {
    font: '800 26px/30px yorkten-slab-normal, sans-serif',
    color: 'rgba(73, 73, 74, 1)',
    borderBottom: '2px solid rgba(139, 193, 5, 1)',
    width: '150px',
};

const specimenCardStyle = {
    flexDirection: 'column-reverse',
};

const mailingAddressCardStyle = {
    padding: '30px 30px 30px 20px',
    whiteSpace: 'pre-line',
};

const RegularPara = styled.p`
    font-weight: 400;
`;

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const displayNoneStyle = { display: 'none' };

const {
    PERSONAL_CHECK_MSG1,
    PERSONAL_CHECK_MSG2,
    PERSONAL_CHECK_MSG3,
    SPECIMEN,
    WIRE_TRANSFER_MAILING_ADDRESS_HEADING,
    WIRE_TRANSFER_MAILING_ADDRESS_TEXT,
    HEADING_TEXT,
} = PersonalCheckConstants;

const PersonalCheckComponent = props => {
    const { heading, mailingAddress } = props;

    return (
        <PersonalCheckDiv>
            <LeftCardCornerStyle />
            <SectionHeaderRow>
                <Col xs={12}>
                    <SectionHeadingH3>{heading}</SectionHeadingH3>
                </Col>
            </SectionHeaderRow>
            <Row>
                <Col xs={12}>
                    <RegularPara>{PERSONAL_CHECK_MSG1}{PERSONAL_CHECK_MSG2}</RegularPara>
                    {/* <BoldParaP></BoldParaP> */}
                </Col>
            </Row>
            <FundTypeSpecimentWrapperRow>
                <Col xs={8}>
                    <CheckSpecimenDiv>
                        <WCard
                            alt="Sample for personal check"
                            buttonstyle={displayNoneStyle}
                            src={checkSpecimen}
                            cardtitletext={SPECIMEN}
                            cardtitlestyle={specimenTitleStyle}
                            cardstyle={specimenCardStyle}
                            linkcard="true"
                            leftcornercard={cardBorderStyle}
                        />
                    </CheckSpecimenDiv>
                </Col>
                <Col xs={4}>
                    <SpecimenCardWrapperDiv>
                        <WCard
                            buttonstyle={displayNoneStyle}
                            cardtitletext={WIRE_TRANSFER_MAILING_ADDRESS_HEADING}
                            cardtext={mailingAddress}
                            cardtextstyle={wireTransferCardTextStyle}
                            cardbodystyle={wireTransferCardBodyStyle}
                            cardtitlestyle={wireTransferTitleStyle}
                            cardstyle={mailingAddressCardStyle}
                            linkcard="true"
                            leftcornercard={cardBorderStyle}
                            alt=""
                            src=""
                        />
                    </SpecimenCardWrapperDiv>
                </Col>
            </FundTypeSpecimentWrapperRow>
            <Row>
                <Col xs={12}>
                    <WCard
                        buttonstyle={displayNoneStyle}
                        cardtitletext={PERSONAL_CHECK_MSG3}
                        cardtextstyle={wireTransferCardTextStyle}
                        cardbodystyle={wireTransferCardBodyStyle}
                        cardtitlestyle={wireTransferTitleStyle}
                        linkcard="true"
                        leftcornercard={cardBorderStyle}
                        alt=""
                        src=""
                    />
                </Col>
            </Row>
        </PersonalCheckDiv>
    );
};

PersonalCheckComponent.propTypes = {
    heading: PropTypes.string,
    mailingAddress: PropTypes.string,
};

PersonalCheckComponent.defaultProps = {
    heading: HEADING_TEXT,
    mailingAddress: WIRE_TRANSFER_MAILING_ADDRESS_TEXT,
};

export default PersonalCheckComponent;
