/**
 * Financial Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Financial information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import { Accordian, AccordianTitle } from '../../../common';
import checkValidity from '../../../utils/checkValidity';
import currencyFormatter from 'currency-formatter';
import { FieldValue, SelectDropDown } from '../../CustomerManagementModule/components';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Row, Card } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import '../PersonalInfo/style.css';
import { annualIncomeErrorMsg,netWorthErrorMsg,taxBracketErrorMsg,taxFilingErrorMsg} from '../MarketNAO/consts';
import { netWorthHelpText } from '../MarketNAO/AccountFeatures/consts';
import StyledToolTip from '../MarketPersonalInfoCard/StyledToolTip';
import helperIcon from '../../../assets/helperIcon.png';
const assignObj = obj => {
    return obj;
};
const alignSelectAccOpt = {};
class MarketFinancialInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            incomeErrorMessage:"",
            netWorthErrorMessage:"",
            clicked: true,
            HelperTextForNetworth: false,
            taxBracketErrorMessage:"",
            taxFilingErrorMessage:""
        };

        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;

        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {};
    }

    handleBlur = e => {
        const { errorMsg } = this.state;
        let err = '';
        if (e.target.value === '') {
            err = Rules[e.target.name]
                ? checkValidity({
                      rules: Rules[e.target.name],
                      value: e.target.value,
                  })
                : '';
        }
        errorMsg[e.target.name] = err;
        this.setState({ errorMsg });
    };

    handleChange = eventKey => e => {
        let flag = 0;
        if (eventKey === 'annualIncome') {
            const { masterLookupStateData } = this.props;
            const { brkg_annual_income: annualIncomeMaster } = masterLookupStateData || {};
            const { value: annualIncomeValue } = annualIncomeMaster || {};
            const updatedArray = annualIncomeValue || [];
            const findex = updatedArray.findIndex(item => item.key === e.target.value);
            const decimalTaxBracket = `${parseFloat(updatedArray[+findex].taxbracket)}`;
            this.setState({ taxBracket: decimalTaxBracket.concat('%') });
        }
        else if(eventKey ==='taxBracket')
        {
            const decimalTaxBracket = `${parseFloat(Rules.taxBracket[e.target.value])}`;
            this.setState({ taxBracket: decimalTaxBracket.concat('%') });
            flag =1;
        }

        if(flag == 0)
        {
            this.setState({ [eventKey]: e.target.value });
        }
    };


    cardHeaderClick = e => {
        const { clicked } = this.state;
        e.target.classList.toggle('expand');
        this.setState({ clicked: !clicked });
    };

    sendDataToParent = () => {

        if(this.validateAnnualandNetWorth() == false)
        {
            return { ...this.state, ...{ valid: false } };
        }
        else
        {
            return { ...this.state, ...{ valid: true } };
        }
    };

    convertToCurrencyNumer = annualIncomeOp => {
        return annualIncomeOp.map(item => {
            const newItem = { ...item };

            const [minValue, maxValue] = item.value.split(/[-]/);
            let tempMinMaxValue;
            if (minValue !== undefined && maxValue !== undefined) {
                tempMinMaxValue = `${currencyFormatter.format(Number(minValue.replace('$', '')), {
                    locale: 'en-US',
                })}-${currencyFormatter.format(Number(maxValue.replace('$', '')), {
                    locale: 'en-US',
                })}`;
            } else if (minValue !== undefined && maxValue === undefined) {
                tempMinMaxValue = `${currencyFormatter.format(Number(minValue.match(/\d+/)[0]), {
                    locale: 'en-US',
                })} and above`;
            }
            tempMinMaxValue = tempMinMaxValue.replaceAll('.00', '');
            newItem.value = tempMinMaxValue;
            return newItem;
        });
    };

    validateAnnualandNetWorth = (action ="default",eventKey,e)=>
    {
        let annualIncome = "";
        let netWorth = "";
        let incomeErrorMessage = "";
        let netWorthErrorMessage = "";
        let taxBracketErrorMessage="";
        let taxFilingErrorMessage="";
        let taxBracket="";
        let taxfilingStatus="";
        let validFlag =true;
        if(action == 'default')
        {
            annualIncome = this.state.annualIncome;
            netWorth = this.state.netWorth;
            taxBracket = this.state.taxBracket;
            taxfilingStatus=this.state.taxFilingStatus;
        }
        else
        { 
            if(eventKey == 'annualIncome')
            {
                annualIncome = e.target.value;
                netWorth = this.state.netWorth
            }
            else
            {
                annualIncome = this.state.annualIncome;
                netWorth = e.target.value;
            }
        }

        if(annualIncome == undefined || annualIncome == null || annualIncome == "" || annualIncome == 0)
        {
            validFlag=false;
            incomeErrorMessage=annualIncomeErrorMsg;
        }
        else
        {
            incomeErrorMessage = "";
        }

        if(netWorth == undefined || netWorth == null || netWorth == "" || netWorth == 0)
        {
            validFlag=false;
            netWorthErrorMessage=netWorthErrorMsg;
        }
        else
        {
            netWorthErrorMessage="";
        }
        if (taxBracket == undefined || taxBracket == null || taxBracket == "" || taxBracket == 0 || taxBracket == "0%") {
            validFlag = false;
            taxBracketErrorMessage = taxBracketErrorMsg;
        }
        else {
            taxBracketErrorMessage = "";
        }

        if (taxfilingStatus == undefined || taxfilingStatus == null || taxfilingStatus == "" || taxfilingStatus == 0) {
            validFlag = false;
            taxFilingErrorMessage = taxFilingErrorMsg;
        }
        else {
            taxFilingErrorMessage = "";
        }

        this.setState({
            netWorthErrorMessage:netWorthErrorMessage,
            incomeErrorMessage:incomeErrorMessage,
            taxBracketErrorMessage: taxBracketErrorMessage,
            taxFilingErrorMessage:taxFilingErrorMessage,
        })

        return validFlag;
    }


    render() {
        const financialInfoText = 'Financial Information';
        const financialInfoTextJoint = 'Financial Information - Joint Owner';
        const annualText = 'Annual Income ($)';
        const taxBracketText = 'Tax Bracket ($)';
        // const selectText = 'Select';
        const { taxBracket, taxFilingStatus, annualIncome, netWorth,incomeErrorMessage,netWorthErrorMessage,taxFilingErrorMessage,taxBracketErrorMessage } = this.state;
        const { masterLookupStateData, isJoint, index, jointText } = this.props;
        const {
            brkg_annual_income: annualIncomeMaster,
            brkg_net_worth: netWorthMaster,
            tax_filling_status: taxFillingStatusMaster,
        } = masterLookupStateData || {};

        const { value: annualIncomeValue } = annualIncomeMaster || {};
        const { value: netWorthValue } = netWorthMaster || {};
        const { value: taxFillingStatusValue } = taxFillingStatusMaster || {};
        const annualIncomeOp = annualIncomeValue || [];
        const netWorthOp = netWorthValue || [];
        const formattedNetWorthOp = this.convertToCurrencyNumer(netWorthOp);
        const taxFillingStatusOp = taxFillingStatusValue || [];
        const formattedAnnualIncomeOp = this.convertToCurrencyNumer(annualIncomeOp);

        const { HelperTextForNetworth } = this.state;
        const toggleToolTipForNetworth = () => {
            this.setState({ HelperTextForNetworth: true })
        }
        const closeToolTipForNetworth = () => {
            this.setState({ HelperTextForNetworth: false })
        }
        
        return (
             this.props.verifyEmpHider|| this.props.verifyMilHider || this.props.verifyJointMilHider || this.props.verifyJointEmpHider? null :
             <>
            <Accordian
                ref={this.accordionRef}
                key={this.props?.jointInfo != "Yes" ?  financialInfoText : financialInfoTextJoint}
                accordianPaddingLeft={0}
                titleChild={
                    <AccordianTitle
                        accType={this.props?.jointInfo != "Yes" ?  financialInfoText : financialInfoTextJoint}
                        id={this.props?.jointInfo != "Yes" ?  financialInfoText : financialInfoTextJoint}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                // titleSeperator
                labelledBy={this.props?.jointInfo != "Yes" ?  financialInfoText : financialInfoTextJoint}
                Id={this.props?.jointInfo != "Yes" ?  financialInfoText : financialInfoTextJoint}
            >
                <Card>
                    <Card.Body className="show">
                        <Row>
                            <div className="container-fluid" style={assignObj({ padding: 0 })}>
                                <FieldValue
                                    as="p"
                                    id={`${jointText}annualIncome${index}Label`}
                                    label={annualText}
                                    value={
                                        <SelectDropDown
                                            aria-labelledby={`${jointText}annualIncome${index}Label`}
                                            id={`${jointText}annualIncome${index}`}
                                            data-name={annualText}
                                            value={annualIncome}
                                            onChange={this.handleChange('annualIncome')}
                                            errortext={incomeErrorMessage}
                                            itemlist={formattedAnnualIncomeOp}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    alignSelectAccOpt={alignSelectAccOpt}
                                />
                            </div>
                        </Row>
                        <Row>
                        <div className="container-fluid" style={assignObj({ padding: 0 })}>
                                <FieldValue
                                    as="p"
                                    id={`${jointText}taxBracket${index}Label`}
                                    label={taxBracketText}
                                    value={
                                        <SelectDropDown
                                            aria-labelledby={`${jointText}taxBracket${index}Label`}
                                            id={`${jointText}taxBracket${index}`}
                                            data-name={taxBracketText}
                                            value={`${Rules.taxBracket.indexOf(
                                                `${taxBracket}`
                                            )}`}
                                            onChange={this.handleChange('taxBracket')}
                                            errortext={taxBracketErrorMessage}
                                            itemlist={Rules.taxBracket}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    alignSelectAccOpt={alignSelectAccOpt}
                                />
                            </div>
                            
                        </Row>
                        <FieldValue
                            as="p"
                            id={`${jointText}netWorth${index}Label`}
                            label="Net Worth ($)"
                            value={
                                <SelectDropDown
                                    aria-labelledby={`${jointText}netWorth${index}Label`}
                                    id={`${jointText}netWorth${index}`}
                                    data-name="Net Worth ($)"
                                    value={netWorth}
                                    onChange={this.handleChange('netWorth')}
                                    errortext={netWorthErrorMessage}
                                    itemlist={formattedNetWorthOp}
                                />
                            }
                            labelsm={4}
                            valuesm={5}
                            mb={1.5625}
                            
                            noGutters
                            alignSelectAccOpt={alignSelectAccOpt}
                        />
                           <img id="networth" className="Tooltip" onMouseOut={closeToolTipForNetworth} src={helperIcon} alt="" />
                                <StyledToolTip
                                    id="networth_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTextForNetworth}
                                    targetID="networth"
                                    handleToggle={toggleToolTipForNetworth}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={netWorthHelpText}
                                    closeTooltip={closeToolTipForNetworth}
                                />
                         <div className='networthmargin'>
                        <FieldValue
                            as="p"
                            id={`${jointText}taxFilingStatus${index}Label`}
                            label="Tax Filing Status"
                            value={
                                <SelectDropDown
                                    aria-labelledby={`${jointText}taxFilingStatus${index}Label`}
                                    id={`${jointText}taxFilingStatus${index}`}
                                    data-name="Tax Filing Status"
                                    value={taxFilingStatus}
                                    onChange={this.handleChange('taxFilingStatus')}
                                    errortext={taxFilingErrorMessage}
                                    itemlist={taxFillingStatusOp}
                                />
                            }
                            labelsm={4}
                            valuesm={5}
                            mb={1.5625}
                            noGutters
                            alignSelectAccOpt={alignSelectAccOpt}
                        />
                        </div>
                    </Card.Body>
                </Card>
            </Accordian></>
        );
    }
}

MarketFinancialInfoCard.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
    index: PropTypes.number,
    jointText: PropTypes.string,
};

MarketFinancialInfoCard.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    savedState: {},
    isJoint: false,
    index: 0,
    jointText: '',
};

export default MarketFinancialInfoCard;
