const accordionToggleStyle = {
    cursor: 'pointer',
};

const labelStyle = {
    marginBottom: 25,
    alignItems: 'center',
};

const uploadlabelTextStyle = {
    marginBottom: 0,
    font: '600 16px/19px benton-sans',
    color: '#49494A',
};

const labelTextStyle = {
    font: '600 16px/19px benton-sans',
    color: '#49494A',
    display: 'flex',
    alignItems: 'center',
};

const pageHeaderStyle = {
    paddingTop: 40,
    fontSize: 30,
    color: 'rgba(72, 109, 144, 1)',
    fontWeight: '800',
    paddingBottom: 10,
    borderBottom: '2px solid #8CBF26',
    fontFamily: 'yorkten-slab-normal',
    float: 'left',
    marginBottom: 40,
};

const mandatoryFieldlabel = {
    font: '100 16px/22px benton-sans',
    color: '#49494A',
    marginTop: 40,
    float: 'right',
    marginBottom: 15,
};

const sublabel = {
    font: '500 12px/14px benton-sans',
    color: '#56565A',
    opacity: 1,
    marginLeft: 10,
};

const mobileHeaderStyle = {
    font: '800 18px/22px yorkten-slab-normal',
    marginBottom: 30,
};

const tooltipfont = {
    font: '100 14px/18px benton-sans',
};

const addFieldButton = {
    color: 'rgba(0, 74, 152, 1)',
    font: '600 16px/22px benton-sans',
    background: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: 42,
    position: 'relative',
};

const iconImgStyle = { position: 'absolute', top: 0, left: 0, width: 20, height: 20 };

const select = {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    height: '48px',
    borderRadius: '0',
    border: '1px solid #D2D2D2',
};

const emailSelectStyles = {
    position: 'absolute',
};

const selectArrow = {
    position: 'absolute',
    right: '20px',
    top: '16px',
    width: '10px',
    height: '10px',
    border: 'solid #4F799F',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

const pageButtonStyle = { color: '#004A98', border: 'none', height: 'unset', width: 'unset', padding: '0' };
const menuButtonStyle = { color: '#004A98', border: 'none', height: 'unset', width: 'unset' };

const horizontalStyle = { display: 'flex', flexDirection: 'row' };

export default {
    addFieldButton,
    accordionToggleStyle,
    emailSelectStyles,
    horizontalStyle,
    iconImgStyle,
    labelStyle,
    labelTextStyle,
    mandatoryFieldlabel,
    menuButtonStyle,
    mobileHeaderStyle,
    pageButtonStyle,
    pageHeaderStyle,
    select,
    selectArrow,
    sublabel,
    tooltipfont,
    uploadlabelTextStyle,
};
