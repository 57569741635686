import { connect } from "react-redux";
import { memberDashboardActions } from '../../../../shared/Actions';
import MySavedItemsWidget from './MySavedItemsWidget';

const mapStateToProps = (state) => ({
       memberDashboardData: state.memberDashboardData,    
})

const mapDispatchToProps = {
    ...memberDashboardActions
}

export default connect(mapStateToProps, mapDispatchToProps)(MySavedItemsWidget);