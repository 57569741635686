import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FooterLinks from '../FooterLinks/FooterLinks';
import DashboardDisclosure from './DashboardDisclosure';
import FundsDisclosure from './FundsDisclosure';
import ExistingDisclosure from './ExistingDisclosure';
import SpecialtyAccountDisclaimer from './SpecialtyAccountDisclaimer';
import routeConstants from '../../routeConstants';
import '../commonCompStyles.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getFooterDisclosure = (module, pathname,location) => {
       
        switch (module) {
            case 'Transactions':
                return <FundsDisclosure fundCode={location.fundCode} path={pathname} />;
            // case 'Message Center':
            //     return null;
            // case 'Access Forms':
            //     return <SecureMsgAccessFormDisclosure data-test="SecureMsgAccessFormDisclamer" path={pathname}/>;
            // case 'Document Center':
            //     return <CustomDisclosure data-test="DocumentCenterDisclaimer" path={pathname}/>;
            // case 'Dashboard':
            //     return <CommonDisclosure data-test="dashboardDisclaimer"/>;
            // case 'Profile':
            //     return <ProfileDisclosure data-test="ProfileDisclosure"/>;
            // case 'Account Services':
            //     return <AccountServicesDisclosure data-test="AccountServicesDisclosure" path={pathname}/>
           // case 'Message Center':
            //case 'Access Forms':
           // case 'Document Center':
           case 'Dashboard':
            //case 'Profile':
            //case 'Account Services':
                return <DashboardDisclosure data-test="DashboardDisclaimer" path={pathname} />;
            //case 'Account Management':
               // const { deliverySettings, accountPreference, editPreference, editEmail } = routeConstants;
              //  const accountManagementPaths = [deliverySettings, accountPreference, editPreference, editEmail];
              //  if (accountManagementPaths.includes(pathname)) {
                //    return <CommonDisclosure data-test="commonDisclaimer" path={pathname} />;
                    // return <AccountServicesDisclosure data-test="AccountServicesDisclosure" path={pathname}/>;
               // }
               // if (pathname === '/openAccount') {
                //    return <CustomDisclosure data-test="NAODisclaimer" path={pathname} />;
                //}
               // if (pathname === '/esa' || pathname === '/application' || pathname === '/personalInfo') {
                   // return <CustomDisclosure data-test="529Disclaimer" path={pathname} module={module} />;
               // }
            default: {

                let component = <ExistingDisclosure data-test="existingDisclosure" pathname={pathname} />;
                const { specialtyAccountApplicationForms } = routeConstants;
                if (pathname === specialtyAccountApplicationForms) {
                    component = <SpecialtyAccountDisclaimer data-test="specialityAccountsDisclaimer" />;
                }
                return component;
            }
        }
    };

    render() {
        const { module = '', location ,hideFooter} = this.props;
        const { pathname } = location;

            return (
                <footer
                id="postLoginFooter"
                className="mt-5"
                data-test="footerTest"
                role="contentinfo"
                aria-hidden={hideFooter}
            >
                <div className="container" id="fund-disclosure-footer">
                     {this.getFooterDisclosure(module, pathname,location)} 
                   {/*  {location.fundSummaryComponent ? (
                        <FundsDisclosure fundCode={location.fundCode} path={pathname} />
                    ) : (
                        <DashboardDisclosure data-test="DashboardDisclaimer" path={pathname} />
                    )}
                    {/* <CommonDisclosure data-test="commonDisclaimer" path={pathname} /> */}
                    {/* {module!=='Dashboard' && module!=='AEM' &&  <ExistingDisclosure/>} */} 
                </div>
                <FooterLinks />

            
            </footer>
        );
    }
}

Footer.propTypes = {
    module: PropTypes.string,
    location: PropTypes.any,
};

Footer.defaultProps = {
    module: '',
    location: {},
};

export default withRouter(Footer);
