import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatPhoneNumberWithoutCountryCode, getPhoneNumberMaxLength, getRemoveSpecialChars } from 'utils';
import InputText from '../InputText';

const COUNTRY_CODE = 'Country code';
const COUNTRY_CODE_HINT_TEXT = 'Country';

const PHONE_NUMBER = 'Phone Number';
const PHONE_NUMBER_HINT_TEXT = '(000)-000-0000';
const PHONE_NUMBER_HINT_TEXT_LABEL = 'Phone Number format: (000)-000-0000';

const PHONE_COUNTRY_CODE_ERROR = 'Please enter country code';
const PHONE_NUMBER_ERROR_1 = 'Please enter a Phone Number';
const PHONE_NUMBER_ERROR_2 = 'Invalid Phone Number';

const handleCountryCodeFocus = ({ countryCode, setCountryCode }) => () => {
    setCountryCode(countryCode.replace(/\D/g, ''));
};

const handleCountryCodeChange = ({ setCountryCode }) => e => {
    let { value } = e.target;
    if (value.length > 3) value = value.slice(0, -1);
    setCountryCode(value.replace(/\D/g, ''));
};

const getPhoneNumberValue = (cleanedCountryCode, cleanedPhoneNumber, phoneNumber) => {
    return cleanedCountryCode === 1 && cleanedPhoneNumber.length === 10
        ? formatPhoneNumberWithoutCountryCode(phoneNumber)
        : getRemoveSpecialChars(phoneNumber, false);
};

const handleCountryCodeBlur = ({
    countryCode,
    setCountryCode,
    setCountryCodeError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    setPhoneNumberError,
    setPhoneNumberLength,
    analyzeFormFieldError,
}) => e => {
    const { value } = e.target;
    let countryCodeError = '';
    const cleanedPhoneNumber = phoneNumber ? getRemoveSpecialChars(phoneNumber, false) : '';
    const cleanedCountryCode = getRemoveSpecialChars(value, true);

    if (!value) {
        countryCodeError = PHONE_COUNTRY_CODE_ERROR;
    } else {
        setPhoneNumberLength(getPhoneNumberMaxLength(value, true));
        setPhoneNumber(getPhoneNumberValue(cleanedCountryCode, cleanedPhoneNumber, phoneNumber));
        setCountryCode(`+${countryCode}`);
    }

    setCountryCodeError(countryCodeError);

    let errorInPhoneNumber = false;
    if (
        !phoneNumberError &&
        cleanedCountryCode === 1 &&
        cleanedPhoneNumber.length &&
        cleanedPhoneNumber.length !== 10
    ) {
        setPhoneNumberError(PHONE_NUMBER_ERROR_2);
        errorInPhoneNumber = true;
    } else if (cleanedCountryCode !== 1 && cleanedPhoneNumber.length >= 10) {
        setPhoneNumberError('');
    }

    if (countryCodeError || errorInPhoneNumber) {
        analyzeFormFieldError();
    }
};

const handlePhoneNumberFocus = ({ countryCode, phoneNumber, setPhoneNumber, setPhoneNumberLength }) => () => {
    const maxLength = getPhoneNumberMaxLength(countryCode, false);
    setPhoneNumber(phoneNumber.replace(/\D/g, ''));
    setPhoneNumberLength(maxLength);
};

const handlePhoneNumberChange = ({ setPhoneNumber }) => e => {
    const { value } = e.target;
    setPhoneNumber(value.replace(/\D/g, ''));
};

const handlePhoneNumberBlur = ({
    countryCode,
    setPhoneNumberLength,
    setPhoneNumber,
    setPhoneNumberError,
    analyzeFormFieldError,
}) => e => {
    const { value } = e.target;
    let phoneNumberError = '';
    const cleanedCountryCode = getRemoveSpecialChars(countryCode, true);
    const maxLength = getPhoneNumberMaxLength(countryCode, true);
    const cleanedPhoneNumber = getRemoveSpecialChars(value, false);
    if (!value) {
        phoneNumberError = PHONE_NUMBER_ERROR_1;
    } else if (value.length < 10 || (cleanedCountryCode === 1 && cleanedPhoneNumber.length > 10)) {
        phoneNumberError = PHONE_NUMBER_ERROR_2;
    } else {
        setPhoneNumberLength(maxLength);
        const phoneNumber = cleanedCountryCode === 1 ? formatPhoneNumberWithoutCountryCode(value) : value;
        setPhoneNumber(phoneNumber);
    }

    setPhoneNumberError(phoneNumberError);

    if (phoneNumberError) {
        analyzeFormFieldError();
    }
};

const PhoneNumber = React.forwardRef((props, fwdRef) => {
    const {
        labelledBy,
        initialCountryCode,
        initialPhoneNumber,
        initialMaxLength,
        optional,
        analyticsLastClick,
        analyzeFormFieldError,
        checkStatusCB,
        keyId,
        ...restProps
    } = props;

    const [countryCode, setCountryCode] = useState('+1');
    const [countryCodeError, setCountryCodeError] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [phoneNumberLength, setPhoneNumberLength] = useState('14');

    const invalidPhone = useRef(null);

    useEffect(() => {
        if (initialCountryCode) setCountryCode(initialCountryCode);
        if (initialPhoneNumber) setPhoneNumber(initialPhoneNumber);
        if (initialMaxLength) setPhoneNumberLength(initialMaxLength);
    }, [initialCountryCode, initialPhoneNumber, initialMaxLength]);

    useEffect(() => {
        const isNotEmpty = !optional && phoneNumber && countryCode;
        const isNotHavError = !countryCodeError && !phoneNumberError;
        const isNotSame = initialCountryCode !== countryCode || initialPhoneNumber !== phoneNumber;
        const disabled = !(isNotEmpty && isNotHavError && isNotSame);
        const phoneRef = fwdRef || {};
        phoneRef.current = {
            countryCode,
            countryCodeError,
            phoneNumber,
            phoneNumberError,
            disabled,
            valid: isNotEmpty && isNotHavError,
        };
        if (invalidPhone.current !== disabled) {
            invalidPhone.current = disabled;
            checkStatusCB(disabled);
        }
    }, [
        fwdRef,
        checkStatusCB,
        countryCode,
        countryCodeError,
        phoneNumber,
        phoneNumberError,
        initialCountryCode,
        initialPhoneNumber,
        optional,
    ]);

    return (
        <Row role="group" aria-labelledby={labelledBy} {...restProps}>
            <Col xs={4} sm={4}>
                <InputText
                    id={`vcm-cms-phone-country-code-${keyId}`}
                    onFocus={handleCountryCodeFocus({ countryCode, setCountryCode })}
                    onChange={handleCountryCodeChange({ setCountryCode })}
                    onBlur={handleCountryCodeBlur({
                        countryCode,
                        setCountryCode,
                        setCountryCodeError,
                        phoneNumber,
                        setPhoneNumber,
                        phoneNumberError,
                        setPhoneNumberError,
                        setPhoneNumberLength,
                        analyzeFormFieldError,
                    })}
                    onClick={analyticsLastClick}
                    maxLength={4}
                    value={countryCode}
                    error={!!countryCodeError}
                    errorTxt={countryCodeError}
                    className="analytics-form-fields"
                    required={!optional}
                    aria-label={COUNTRY_CODE}
                    instructionText={COUNTRY_CODE_HINT_TEXT}
                    srInstructionText={COUNTRY_CODE}
                    data-form-field
                    data-group-field
                />
            </Col>
            <Col xs sm={6}>
                <InputText
                    id={`vcm-cms-phone-number-${keyId}`}
                    maxLength={phoneNumberLength}
                    onFocus={handlePhoneNumberFocus({ countryCode, phoneNumber, setPhoneNumber, setPhoneNumberLength })}
                    onChange={handlePhoneNumberChange({ setPhoneNumber })}
                    onBlur={handlePhoneNumberBlur({
                        countryCode,
                        setPhoneNumberLength,
                        setPhoneNumber,
                        setPhoneNumberError,
                        analyzeFormFieldError,
                    })}
                    onClick={analyticsLastClick}
                    value={phoneNumber}
                    error={!!phoneNumberError}
                    errorTxt={phoneNumberError}
                    className="analytics-form-fields"
                    required={!optional}
                    aria-label={PHONE_NUMBER}
                    instructionText={PHONE_NUMBER_HINT_TEXT}
                    srInstructionText={PHONE_NUMBER_HINT_TEXT_LABEL}
                    data-form-field
                    data-group-field
                />
            </Col>
        </Row>
    );
});

PhoneNumber.propTypes = {
    labelledBy: PropTypes.string,
    initialCountryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialPhoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialMaxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    optional: PropTypes.bool,
    analyticsLastClick: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
    checkStatusCB: PropTypes.func,
    keyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PhoneNumber.defaultProps = {
    labelledBy: '',
    initialCountryCode: '',
    initialPhoneNumber: '',
    initialMaxLength: '',
    optional: false,
    analyticsLastClick: () => {},
    analyzeFormFieldError: () => {},
    checkStatusCB: () => {},
    keyId: 0,
};

export default PhoneNumber;
