import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
import routeConstants from 'routeConstants';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import '../Screens.css';
import PersonalDetailsForm from '../../PersonalDetails';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WSpinner, WMessageBar } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles'; 
// import signUpPages from '../SignUpConstants';
import classes from '../../Input/Input.module.css';

// const {stepperColStyle, stepperTextStyle} = styles.stepperStyle;
const assignObj = obj => {return obj;};

class PersonalDetails extends Component {

  data = {
    title: 'Enter your Personal details / Member ID Verification',
    optionalMessage: 'All fields are required unless mentioned optional',
    // optionalMessage: 'All fields are mandatory except mentioned optional *',
    optMsgStyles: {marginBottom: 30, marginTop: 11, justifyContent: 'flex-start'}
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        firstName: { value: '', errMsg: null, valid: false, label: 'First Name',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            // pattern: userConstants.nameRegex,
            patternMessage: userConstants.alphabetMessage,
            required: true
          }
        },
        middleName: { value: '', errMsg: null, valid: true, label: 'Middle Name',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            // pattern: userConstants.nameRegex,
            patternMessage: userConstants.alphabetMessage
          }
        },
        lastName: { value: '', errMsg: null, valid: false, label: 'Last Name',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            // pattern: userConstants.nameRegex,
            patternMessage: userConstants.alphabetMessage,
            required: true
          }
        },
        suffix: { value: '', options: [{ key: 'select', value: 'Select' }], valid: true, rules: {}, label: 'Prefix', displayValue: '' },
        prefix: { value: '', options: [{ key: 'select', value: 'Select' }], valid: true, rules: {}, label: 'Suffix', displayValue: '' },
        countryCode: { value: '', options: [{ key: '+1', value: '+1' }], valid: true, rules: {} },
        mobileNumber: { value: '', errMsg: null, valid: true, label: 'Mobile Number', type: 'mobile',
          rules: {
            pattern: new RegExp('\\d{3}-\\d{3}-\\d{4}'),
            patternMessage: 'Please enter the correct mobile number',
          }
        },
        emailId: { value: '', errMsg: null, valid: false, label: 'E-mail', emailExists: false, loading: false, type:'email',
          rules: {
            isEmail: true,
            maxLength: 50,
            required: true
          },
          
        }
      },
      cancelled: false,
      isMobile:false,
      formIsValid: false,
      error: null,
      emailLink: false,
      notificationMsg: '',
      notificationError: false,
      focusfrom: '',
      ...props.personalDetailsState
    };
        
    this.onBack = this.onBack.bind(this);
  
    this.onNext = this.onNext.bind(this);
  
    this.onChange = this.onChange.bind(this);
  
    this.onBlur = this.onBlur.bind(this);

  }

  static getDerivedStateFromProps(nextProps, state) {
    const { loginState, history, manageLocalState, sendEmailLink, verifyEmail, } = nextProps;
    const {form, isMobile} = state;
    // const { uuid } = queryString.parse(location.search);
    let updatedState = null;
    // let redirect;
    // let logOutRoute;
    // if (loginState && loginState.memberVerify ) {
    //   console.log('@debug', loginState);
    //   if(loginState.memberVerify.errorCode === 0){
    //     const {result} = loginState.memberVerify;
    //     const {firstName,lastName,phoneNumber, emailAddress, middleName, prefix, suffix  } = result;
    //     const emailLink = true;
    //     let formIsValid = false;
    //     if(firstName) {
    //       form.firstName.value = firstName;
    //       form.firstName.valid = true;
    //     }
    //     if(lastName) {
    //       form.lastName.value = lastName;
    //       form.lastName.valid = true;
    //     }
    //     if(emailAddress) {
    //       form.emailId.value = emailAddress;
    //       form.emailId.valid = true;
    //     }
    //     if(phoneNumber) {
    //       form.mobileNumber.value = getFormattedValue(phoneNumber);
    //     }
    //     if(middleName) {
    //       form.middleName.value = middleName;
    //     }
    //     if(prefix) {
    //       form.prefix.value = prefix;
    //     }
    //     if(suffix) {
    //       form.suffix.value = suffix;
    //     }
    //     if(emailAddress && firstName && lastName) {
    //       formIsValid = true;
    //     }
    //     const localData = {
    //         personalDetailsState: {uuid, emailAddress, firstName, lastName, form, formIsValid, emailLink}
    //     };
    //     const notificationMsg = {message: assignObj(`Your email id ${emailAddress} has been successfully verified.`)};
    //     manageLocalState(localData);
    //     updatedState = {form, formIsValid, emailLink, notificationMsg, focusfrom: "nextButton"};
    //   } else if(loginState.memberVerify.errorCode === 2011) {
    //     updatedState = {notificationMsg: loginState.memberVerify.result, notificationError: true};
    //   }else if(loginState.memberVerify.errorCode === 5003) {
    //     redirect =  true;
    //     logOutRoute =  {
    //         pathname: '/sign-in',
    //         message: {message: loginState.memberVerify.result},
    //         failure: true,
    //     };
    //     updatedState = {redirect, logOutRoute};
    //   }else if(loginState.memberVerify.errorCode === 5002) {
    //     redirect =  true;
    //     logOutRoute =  {
    //         pathname: '/sign-in',
    //         message: {message: 'Member already verified'}
    //     };
    //     updatedState = {redirect, logOutRoute};
    //   } else {
    //     updatedState = {notificationMsg: {message: loginState.memberVerify.message}, notificationError: true};
    //   }
    //   verifyEmailLink('');
    // }
    if (loginState && loginState.verifyEmail ) {
      // console.log(loginState)
      if(loginState.verifyEmail.errorCode === 0){
        const payload = {
          "emailAddress": form.emailId.value,
          "firstName": form.firstName.value,
          "lastName": form.lastName.value,
          "phoneNumber": form.mobileNumber.value.replace(/-/g, ""),
          "middleName": form.middleName.value,
          // "prefix": form.prefix.displayValue,
          // "suffix": form.suffix.displayValue
          "prefix": form.prefix.value,
          "suffix": form.suffix.value,
          "isMobile":isMobile,
          "countryCode":form.countryCode.options[0].value
        };
        sendEmailLink(payload);
      }else if(loginState.verifyEmail.errorCode === 5002 || loginState.verifyEmail.errorCode === 5001){
        form.emailId.errMsg = 'E-mail address already exists';
        updatedState = {...state, ...form};
      }else {
        form.emailId.errMsg = loginState.verifyEmail.result;
        updatedState = {...state, ...form};
      }
      verifyEmail('');
    }
    if (loginState && loginState.signUp ) {
      // console.log(loginState)
      if(loginState.signUp.errorCode === 0){
        const localData = { personalDetailsState : {...state, uuidEmailSent: loginState.signUp.result} };
        manageLocalState(localData);
        sendEmailLink('');
        history.push('/verify-email');
      }else if(loginState.signUp.errorCode === 5002){
        sendEmailLink('');
        updatedState = {...state, error: 'E-mail address already exists'};
      }else {
        sendEmailLink('');
        updatedState = {...state, error: loginState.signUp.result};
      }
    }
    return updatedState;
  }
  
  componentDidMount() {
      const { location, verifyEmailLink } = this.props;
      const { uuid } = queryString.parse(location.search);
      if(uuid) {
        verifyEmailLink({uuid});
      }
      
  }

  componentWillUnmount() {
    const { analyzeFormAdandonment } = this.props;
    const digitalData = getDigitalDataFormAband();
    analyzeFormAdandonment(digitalData);
  }

  analyticsLastTouch = (event) =>{
      analyticsFormAbandLastTouch(event);
  }

  onChange(event, fieldId) { inputChangedHandler(event, fieldId, this); }

  onBlur(fieldId) { inputValidityHandler(fieldId, this); }

  onBack() {
    const { history } = this.props;
    history.push(routeConstants.signIn);
  }

  onNext() {
    const { verifyEmail, history, personalDetailsState } = this.props;
    const {form} = this.state;
    const { uuid } = personalDetailsState;
    if(!uuid) {
      verifyEmail({emailAddress: form.emailId.value});
    } else {
      history.push('./termsAndConditions');
    }
   
  }

  radioClick = (e) => {
    const { history } = this.props;
    this.setState({[e.target.name]: e.target.id});
    if(e.target.id === 'yes'){
      history.push('./memberSignUp');
    }
    
};

phoneTypeToggle = (val) => {
  this.setState({isMobile:val})
}

  render() {
    const { formIsValid, error, emailLink, notificationMsg, notificationError, focusfrom, redirect, logOutRoute } = this.state;
    const {loginState} = this.props;
    return (
      <div className="container-fluid">
        {redirect && <Redirect to={logOutRoute} />}
        {notificationMsg && <WMessageBar text={notificationMsg} onClose={this.onClose} error={notificationError} focusfrom={focusfrom} />}
        <div className="container custom-container">
        {/* registerState.value ? null : <Redirect to={routeConstants.registerEmail}/> */}
          {/* <StatusBar length='3' current='2'/> */}
          <WSpinner loading={loginState.isLoading} />
          {/* <WStepper className="userWizard" currentPage={0} pages={signUpPages} stepperColStyle={stepperColStyle} stepperTextStyle={stepperTextStyle} /> */}
          <Row className="main personalDetailsMainDiv">
            <Col md lg xl>
              <SideHeader
                title={this.data.title} />
              <span style={assignObj({...styles.optionalMessage, ...this.data.optMsgStyles})}>{this.data.optionalMessage}</span>
            </Col>
            <Col md lg xl>
            {/* <span style={assignObj({...styles.optionalMessage, marginBottom: 30, marginTop: 11})}>{this.data.optionalMessage}</span> */}
              <PersonalDetailsForm state={this.state} radioClick={this.radioClick} changed={this.onChange} blurred={this.onBlur} clicked={this.analyticsLastTouch} emailLink={emailLink} phoneTypeToggle={this.phoneTypeToggle} />
                {error ? <p className={classes.errMsg}>{error}</p> : null}
            </Col>
          </Row>
        </div>
        <div className="custom-button-container">
          <CommonButtons backClick={this.onBack} /* backstyle={styles.hide} */ 
            nextClick={this.onNext}
            disabled={!formIsValid}
            blockstyle={styles.commonButtonsHalf}
            arialabelback="Back to Sign In"
            dataAnalyticId="UMS_Signup_PersonalDetails"
            />
          {/* {cancelled ? <Redirect to='/register-email' /> : null} */}
        </div>
        
      </div>
    );
  }
}

PersonalDetails.propTypes = {
  loginState: PropTypes.instanceOf(Object),
  sendEmailLink: PropTypes.func,
  verifyEmailLink: PropTypes.func,
  verifyEmail: PropTypes.func,
  manageLocalState: PropTypes.instanceOf(Object),
  personalDetailsState: PropTypes.instanceOf(Object),
  // registerState: PropTypes.instanceOf(Object),
  history: PropTypes.shape([]),
  location: PropTypes.instanceOf(Object),
};

PersonalDetails.defaultProps = {
  loginState: {},
  sendEmailLink: () => {},
  verifyEmailLink: () => {},
  verifyEmail: () => {},
  manageLocalState: {},
  personalDetailsState: {},
  // registerState: {},
  history: [],
  location: {search: ""}
};

export default PersonalDetails;