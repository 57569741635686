import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isDashbordError: false,
    isNewDashboardSuccess: false,
    newAccountSummary: {},
    totalAmount: 0
};

function memberNewDashboardReducer(state = initialState, action) {
    switch (action.type) {
       
        case ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isDashbordError: false,isNewDashboardSuccess:true,
                totalAmount: action.accountSummary?.accountDetails?.totalAmount
            };
        }
    
        case ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_FAILURE: {
            return { ...state, ...action, isLoading: false,isNewDashboardSuccess:false, isDashbordError: true};
        }

        case ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_LOADER: {
            return { ...state, ...action, isLoading: true,isNewDashboardSuccess:false, isDashbordError: false};
        }

        case ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_ERROR: {
            return { ...state, ...action, isLoading: false, isNewDashboardSuccess:false,isDashbordError: true};
        }

        case ActionTypes.SET_MEMBER_DASHBOARD_INITIAL_DATA: {
            return { newAccountSummary: {}, isLoading: false, isNewDashboardSuccess:false,isDashbordError: false };
        }

        default:
            return state;
    }
}

export default memberNewDashboardReducer;
