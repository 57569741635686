/**
 * Dashboard Screen Component Styles and Constants
 ********************************
 *
 * @version 1.0.1
 * @author Numaan
 * @description The Highchart consists of  HIghchart component which takes the values in array and crates the graph accordingly.
 * @createdDate [16/03/2020]
 * ***************************************
 * @lastModifiedDate [16/06/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedChanges added tool tip 
 */

 import React, { Component } from 'react';
 import PropTypes from 'prop-types';
 import Highcharts from 'highcharts';
import { toLower } from 'lodash';
 
const userConfig = {
    tooltip: {
        formatter: function() {
            if (this.point.dataAvailable) {
                return '<span style="color:' + this.point.color + '">\u25CF</span>' + this.point.name + this.point.formattedGroupTotal + ':<b>' + this.point.percentage.toFixed(0) + '% </b>';
            } else {
                return this.point.name + this.point.dataUnavailableMessage;
            }
        }
    }
};
 

 const testData =  {
    "y": 120945,
    "name": "Funds",
    "formattedGroupTotal": "$120945.81",
    "dataLabels": {
        "enabled": false,
        "distance": -140,
        "style": {
            "font": "500 14px/22px benton-sans,sans-seriff"
        }
    }
}

 
 const chartTitleHTML = (totalAmount,dateValue) => (`<div style="font-size:16px;line-height:22px;font-family:benton-sans;"><p style="margin: 0; padding: 0; text-align: center;"></p><p style="margin-top:4px;margin-bottom:0px; padding: 0; text-align: center; font-size: 24px; color: #333; font-weight: 700;"><b>${totalAmount}</b></p><p style = "margin: 0; padding: 0;; text-align: center; font-size: 12px; line-height:15px;color: #333333; font-weight: 700;">Value as of ${dateValue}*</p></div>`);
 
 const chartTitleHTMLForDown = (totalAmount,dateValue) => (`<div style="font-size:16px;line-height:22px;font-family:benton-sans;"><p style="margin: 0; padding: 0; text-align: center;"></p><p style="margin-top:4px;margin-bottom:0px; padding: 0; text-align: center; font-size: 24px; color: #333; font-weight: 700;"><b>${totalAmount}</b><br/><span style="color:#f00505; font-size:12px;">Total value unavailable</span></p><p style = "margin: 0; padding: 0;; text-align: center; font-size: 12px; line-height:15px;color: #333333; font-weight: 700;">Value as of ${dateValue}*</p></div>`);
 class HighChartForOverview extends Component {
     constructor(props) {
         super(props);
         const {mapData}=this.props
         this.state = {
             actualSeriesData:mapData || []
         };
     }
 
     componentDidMount() {
       //  this.createArrayOfMapData();
       this.highChartsRender();      
       const elements = document.getElementsByClassName('highcharts-title');
       const requiredElement = elements[0];
         // var titleText = requiredElement.innerText;
       const cln = requiredElement.cloneNode(true);
       document.getElementById("chartTitleCloned").appendChild(cln);
     }   
 
     highChartsRender = () => {
         const {actualSeriesData}=this.state;
         const {handleChartLoad,totalAmount, handleSwitchTabs}=this.props
         // const {commonData}=accountHolderCardData
         // const {totalPortfolio,percentage}=commonData
         Highcharts.chart({
             chart: {
                 type: 'pie',
                 renderTo: 'holdingGroupComposition',
                 width:'330',
                 height:'330',
                 events:{
                     load:()=>{
                         handleChartLoad()
                     },  
                 }
             },
             title: {
                 text: (this.props.fundDownStatus) ? chartTitleHTML(totalAmount,this.props.dateValue) : chartTitleHTMLForDown(totalAmount,this.props.dateValue)  ,
                 verticalAlign: 'middle',
                 useHTML:true,
                 // align: 'center',    
                 // verticalAlign: 'middle',
                 // fontWeight: 700,
                 // fontSize: '16px',
                 // fontFamily:'benton-sans,sans-seriff',
                 // y: 25
             },
             // title: { text: '' },
             colors: this.props.colors,
             series:[
                 {
                     type: 'pie',
                     data:  actualSeriesData,
                     animation:false,
                     // dataLabels: { distance: -130 }
                     point:{
                        events:{
                            click: function (event) {
                                handleSwitchTabs(toLower(event.point.name))
                            }
                        }
                    },
                    enableMouseTracking:  this.props.downErrorStaus ? false :  true    
                 },
             ],
             legend: {
                 enabled: false,
                 align: 'left',
                 layout: 'vertical',
                 verticalAlign: 'middle',
             },
             accessibility: {
                 point: {
                     valueSuffix: '%'
                 }
             },
             plotOptions: {
                 pie: {
                     shadow: false,
                     borderWidth: 3,
                     borderColor: '#ffffff',
                     center: ['50%', '50%'],
                     size: '100%',
                     name: '',
                     innerSize: '70%',
                     animation: false,
                 },
             },
             credits: {
                 enabled: false,
             },
             ...userConfig,            
         }); 
     }
     
     render() {
         return (
             <div>
                 <div id="holdingGroupComposition" aria-hidden="true" className="highCharts"/>
                 <div id="chartTitleCloned" aria-hidden="false" className="sr-only"/>
             </div>            
         );
     }
 }
 
 HighChartForOverview.propTypes = {
     mapData: PropTypes.instanceOf(Object),
     handleChartLoad:PropTypes.func,
     handleSwitchTabs:PropTypes.func,
     totalAmount:PropTypes.string,
 };
 
 HighChartForOverview.defaultProps = {
     mapData: [],
     handleChartLoad:()=>{},
     handleSwitchTabs:()=>{},
     totalAmount:'0.00'
 };
 
 export default HighChartForOverview;
 
 /* reference
 {
     y: 1,
     name: 'General',
     dataLabels:{ enabled:false}
 },
 {
     y: 2,
     name: 'Retirement',
     dataLabels:{ enabled:false}
 },
 {
     y: 3,
     name: 'Educational',
     dataLabels:{ enabled:false}
 }
 */
 