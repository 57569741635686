

/* eslint-disable react/jsx-no-literals */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { LiveMessage, LiveAnnouncer } from 'react-aria-live';

import get from 'lodash/get';
import { isEmpty } from 'lodash';
import WStepper from '../components/WStepper';
import consts from '../components/constants';
import './OneTimeCodeVerify.css';
import analytics from '../components/utils.analytics';
import { validatePasscode } from '../Actions/AccountRegistrationActions';
import BackButton from '../components/inputs/BackButton';
import {
  getPassCode,
  resetOTPFlag,
} from './OneTimeListingPage/OneTimeListingPage.actions';
import { getMaskedPhoneFormat, getOTPErrorMsg } from '../Utils';

const { pages } = consts;
const errorMessageToggleToResend = [
  'Please generate a new one time passcode',
  'Incorrect passcode, please try again or request a new passcode',
];

const assignObj = (obj) => obj;
class OneTimeCodeVerifyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onetimecode: '',
      errorMsg: '',
      resendStatus: false,
    };

    this.refPassCodeInput = React.createRef();
    OneTimeCodeVerifyPage.getDerivedStateFromProps = OneTimeCodeVerifyPage.getDerivedStateFromProps.bind(
      this,
    );
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.verifyPasscode = this.verifyPasscode.bind(this);
    this.requestPasscode = this.requestPasscode.bind(this);
  }

  componentDidMount() {
    const { history, accountInfo } = this.props;
    
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      OTPSuccess, OTCMsg, resetFlg, otpValidationErrorMsg,
    } = nextProps;
    if (OTPSuccess === 'success') {
      resetFlg();
      return { resendStatus: false, errorMsg: '' };
    }
    if (OTPSuccess === 'failed') {
      resetFlg();
      if (this.refPassCodeInput.current) {
        this.refPassCodeInput.current.focus();
      } else {
        document.getElementById('passcode').focus();
      }
      return { resendStatus: true, errorMsg: getOTPErrorMsg(OTCMsg) };
    }
    if (!isEmpty(otpValidationErrorMsg)) {
      if (this.refPassCodeInput.current) {
        this.refPassCodeInput.current.focus();
      } else {
        document.getElementById('passcode').focus();
      }
      if (errorMessageToggleToResend.indexOf(otpValidationErrorMsg) !== -1) {
        return { resendStatus: true };
      }
    }
    return state;
  }

  handleButtonClick = () => {
    analytics.triggerClickTrackEvent('Preregister_verifypasscode_Continue');
  };
  
  handleBackButtonClick() {
    const { resetFlg } = this.props;
    resetFlg();
    analytics.triggerClickTrackEvent(
      'Preregister_verifypasscode_Back_to_Passcode_Delivery_Preferences',
    );
    window.scriptLoaded = false;
  }

  verifyPasscode() {
    const { validatePassCode, accountInfo } = this.props;

    const { onetimecode } = this.state;

    validatePassCode({
      ...accountInfo,
      verifyOTP: onetimecode,
      requestType: 'PHONE',
    });
  }

  requestPasscode() {
    const { accountInfo, location, getVerifyPasscode } = this.props;
    const phoneNumber = get(location, 'state.phoneNumber', '');
    const requestType = get(location, 'state.requestType', '');
    this.setState({ errorMsg: '' });
    getVerifyPasscode({
      ...accountInfo,
      phoneNumber,
      requestType,
    });
  }

  render() {
    const { onetimecode, resendStatus, errorMsg } = this.state;
    const {
      otpValidationErrorMsg,
      otpValidationSuccess,
      resetFlg,
      primaryPhone,
      primaryPhoneCountryCode,
    } = this.props;
    const appError = otpValidationErrorMsg.length
      ? otpValidationErrorMsg
      : errorMsg;

    if (otpValidationSuccess) {
      resetFlg();
      window.scriptLoaded = false;
      return <Redirect to="/accountRegistration/enterUserId" />;
    }

    if (!isEmpty(appError)) {
      const appErrorStr = appError.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_verifypasscode_error_${appErrorStr}`,
      );
    }

    return (
      <div className="milestone">
        {/* <AppProgress step={4} /> */}
        <div className="pad contentWrapper OTPVerify">
          <Container fluid>
            <WStepper currentPage={2} pages={pages} />
            <Row>
              <Col className="descWrapper flexColBackOpt" xs={12} sm={6}>
                <div>
                  <h1 className="descWrapperHeading">
                    {consts.passcodeHeading}
                  </h1>
                  <p>
                    {`${consts.passcodeParag} ${getMaskedPhoneFormat(
                      primaryPhone,
                      primaryPhoneCountryCode,
                    )} `}
                  </p>
                  <span className="passcodeSubParag">
                    {consts.passcodeSubParag}
                  </span>
                </div>
                {/* <BackButton
              link="/onetimecode"
              backTo="Back to Passcode Delivery Preferences"
              className="d-none d-sm-block"
            /> */}
              </Col>
              <Col xs={12} sm={6} className="RHSWrapper">
                <Container style={assignObj({ margin: 0 })}>
                  <Row>
                    <Col className="noPad passcodePadding" xs={12} sm={10}>
                      <p style={assignObj({ marginBottom: '16px' })} id="passcodeTitle">
                        Enter One Time Passcode
                      </p>
                      <form
                        onSubmit={assignObj((e) => {
                          e.preventDefault();
                          this.verifyPasscode();
                        })}
                      >
                        <div className="inputWrapper passCode">
                          <div className="passCodeWrapper">
                            <input
                              value={onetimecode}
                              id="passcode"
                              aria-labelledby="passcodeTitle"
                              maxLength="6"
                              ref={this.refPassCodeInput}
                              aria-describedby={
                                appError.length ? 'errorfield' : null
                              }
                              aria-invalid={!!appError}
                              className={appError.length ? 'errorField' : ''}
                              required
                              aria-required="true"
                              type="password"
                              onInput={assignObj((e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  '',
                                );
                              })}
                              onChange={assignObj((e) => {
                                if (e.target.value.length < 7) {
                                  this.setState({
                                    onetimecode: e.target.value,
                                  });
                                  if (!isEmpty(appError)) {
                                    resetFlg();
                                  }
                                }
                              })}
                            />
                            {appError && (
                              <>
                                <span className="sr-only">Error:</span>
                              <p id="errorfield" className="errorText">
                                {appError}
                              </p>
                              </>
                            )}
                            <div className="d-block ">
                              {resendStatus ? (
                                <div className="passCodeSent">
                                  <input
                                    type="button"
                                    className="resendOtp"
                                    onClick={assignObj((e) => {
                                      e.preventDefault();
                                      this.setState({ onetimecode: '' });
                                      this.requestPasscode();
                                    })}
                                    value="Resend One Time Passcode"
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="passCodeSent">
                                    <p>{consts.passcodeSent}</p>
                                    <input
                                      type="button"
                                      className="resendOtp"
                                      onClick={assignObj((e) => {
                                        e.preventDefault();
                                        this.setState({ resendStatus: true });
                                        resetFlg();
                                        analytics.triggerClickTrackEvent(
                                          'Preregister_verifypasscode_I_did_not_receive_the_passcode',
                                        );
                                      })}
                                      value="I did not receive One Time Passcode"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <LiveAnnouncer>
                            <LiveMessage
                              message={appError}
                              aria-live="polite"
                              clearOnUnmount="true"
                            />
                          </LiveAnnouncer>
                        </div>
                        <div className="btnWrapper">
                          <button
                            className="vcmbtn sameWidthButton"
                            type="submit"
                            disabled={onetimecode.length < 6}
                            onClick={this.handleButtonClick}
                          >
                            <span className="vcmBtnText">
                              {consts.passcodeSubmit}
                            </span>
                          </button>
                        </div>
                      </form>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col className="descWrapper" xs={12} sm={6}>
                <BackButton
                  link="/accountRegistration/onetimecode"
                  backTo="Back to One Time Passcode Delivery Preferences"
                  className="d-none d-sm-block"
                  onClick={this.handleBackButtonClick}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

OneTimeCodeVerifyPage.propTypes = {
  accountInfo: PropTypes.instanceOf(Object),
  validatePassCode: PropTypes.func,
  otpValidationSuccess: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  OTPSuccess: PropTypes.string,
  OTCMsg: PropTypes.string,
  otpValidationErrorMsg: PropTypes.string,
  primaryPhone: PropTypes.string,
  primaryPhoneCountryCode: PropTypes.string,
  location: PropTypes.instanceOf(Object),
  resetFlg: PropTypes.func,
  getVerifyPasscode: PropTypes.func,
};

OneTimeCodeVerifyPage.defaultProps = {
  validatePassCode: () => {},
  accountInfo: {},
  otpValidationSuccess: false,
  history: {},
  otpValidationErrorMsg: '',
  getVerifyPasscode: () => {},
  location: {},
  OTPSuccess: '',
  OTCMsg: '',
  primaryPhone: '',
  primaryPhoneCountryCode: '',
  resetFlg: () => {},
};

const mapStateToProps = (state) =>  {
  const { user } = get(state, 'AccountRegistration', {});
  return {
    otpValidationSuccess: get(
      state,
      'AccountRegistration.otpValidationSuccess',
      false,
    ),
    accountInfo: get(state, 'AccountRegistration.accountInfo', {}),
    otpValidationErrorMsg: get(
      state,
      'AccountRegistration.otpValidationErrorMsg',
      '',
    ),
    OTPSuccess: get(state, 'AccountRegistration.OTPSuccess', ''),
    OTCMsg: get(state, 'AccountRegistration.OTCMsg', ''),
    primaryPhone: get(user, 'primaryPhone', ''),
    primaryPhoneCountryCode: get(state, 'primaryPhoneCountryCode', ''),
  };
};

const mapDispatchToProps = {
  validatePassCode: validatePasscode,
  getVerifyPasscode: getPassCode,
  resetFlg: resetOTPFlag,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OneTimeCodeVerifyPage);
