import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SecondaryBtn from '../SecondaryBtn';

export const StyledSpan = styled.span`
    margin-left: 10px;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: ${props => props.small ? '14' : '16'}px;
    line-height: ${props => props.small ? '17' : '20'}px;
    letter-spacing: 0px;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;

const CtaWithIcon = React.forwardRef((props, ref) => {
    const { text, icon, small, ...restProps } = props;
    return (
        <SecondaryBtn ref={ref} {...restProps}>
            {icon}
            <StyledSpan small={small} >{text}</StyledSpan>
        </SecondaryBtn>
    );
});

CtaWithIcon.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.node,
    small: PropTypes.bool
};

CtaWithIcon.defaultProps = {
    text: '',
    icon: '',
    small: false
};

export default CtaWithIcon;
