/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component handles the logic to generate Beneficiary Cards
 * @createdDate [22/04/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import generateChildBeneFiciaryCard from './generateChildBeneFiciaryCard';
import generateBeneficiaryInfoCard from './GenerateBeneficiaryInfoCard';

export default function handleBeneficiaryCardsGeneration(masterLookupStateData, accType, benInfo, isMarketNaoFlow=false) {
    const allBenCards = [];

    const generateBenPCards = (data, i) => {
        let beneficiaryCard = null;
        if (accType === 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)') {
            beneficiaryCard = generateChildBeneFiciaryCard(masterLookupStateData, data, i);
        } else {
            beneficiaryCard = generateBeneficiaryInfoCard(masterLookupStateData, data, i, 'Primary', isMarketNaoFlow);
        }
        allBenCards.push(beneficiaryCard);
    };

    const generateBenCCards = (data, i) => {
        let contingentBeneficiaryCard = null;
        contingentBeneficiaryCard = generateBeneficiaryInfoCard(
            masterLookupStateData,
            benInfo.beneficiaryContingentDataArray[+i],
            i,
            'Contingent',
            isMarketNaoFlow
        );
        allBenCards.push(contingentBeneficiaryCard);
    };

    const itrateInfo = (data, info) => {
        const { length } = data;
        const generateCards = info === 'P' ? generateBenPCards : generateBenCCards;
        for (let i = 0; i < length; i += 1) {
            if (Object.keys(data[+i]).length !== 0) {
                generateCards(data[+i], i);
            }
        }
    };

    if (benInfo) {
        if (benInfo.beneficiaryPrimaryDataArray) {
            itrateInfo(benInfo.beneficiaryPrimaryDataArray, 'P');
        }
        if (benInfo.beneficiaryContingentDataArray) {
            itrateInfo(benInfo.beneficiaryContingentDataArray, 'C');
        }
    }
    return allBenCards;
}
