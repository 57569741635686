import { connect } from 'react-redux';
import {
    getTransHolderListForManage,
    setLoadingPage,
    resetTransactionHolder,
    getAccountServiceOptions
} from '../../../shared/Actions/TransactionHistoryActions';
import {
    transactionCompositeDataAction
} from '../../../shared/Actions';
import ManageMarketTransaction from './ManageMarketTransaction';
import {
   memberDashboardActions,
   
} from '../../../shared/Actions';
import { addProfileContactInformations, clearContactDetails } from 'shared/Actions/ProfileSettingsAction';
/**
 * Map State to Props - Maps transactionHolderData from redux state
 * @param {Object} state
 */
const mapStateToProps = state => ({
    transactionHolderData: state.transactionHolderData,
    memberDashboardData: state.memberDashboardData,
    profileInformationData:state.profileInformationData
});

/**
 * Map dispatch to props - For all actions from Transaction history actions
 */
const mapDispatchToProps = {
    // getTransHolderList,
    // setLoadingPage,
    // resetTransactionHolder,
    ...memberDashboardActions,
    // updateAccountLevelAddresses
    getTransHolderListForManage,
    setLoadingPage,
    resetTransactionHolder,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData,
    getAccountServiceOptions,
    addProfileContactInformations,
    clearContactDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMarketTransaction);
