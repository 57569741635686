import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import OtpFormComponent from './OtpFormComponent';
import {loginActions, analytics} from '../../../shared/Actions';



export const mapStateToProps = state => ({
      loginState: state.loginData,
});


const mapDispatchToProps = {
     ...loginActions,
     ...analytics
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(OtpFormComponent));