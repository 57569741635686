/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import PropTypes from 'prop-types';
import './VCMCheckbox.css';

const VCMCheckbox = (props) => {
  const {
    value,
    name,
    onChange,
    isDisabled,
    ariaLabel,
    dataRef,
    labelledby,
    required,
  } = props;
  const handleChangeHandler = (event) => {
    // Here, we invoke the callback with the new value
    onChange(event.target.checked);
  };

  return (
    <label className={`checkboxcontainer ${isDisabled ? 'disabled' : ''}`}>
      <input
        type="checkbox"
        id={`vcmcheck-${name}`}
        name={name}
        className="analytics-form-fields"
        checked={value}
        onChange={handleChangeHandler}
        disabled={isDisabled}
        aria-describedby={labelledby}
        aria-required={required}
        required={required}
        ref={dataRef}
      />
      <span className="sr-only">{ariaLabel}</span>
      <span className="checkmark" />
    </label>
  );
};

const objPropsTypes = PropTypes.object;
VCMCheckbox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  labelledby: PropTypes.string,
  dataRef: objPropsTypes,
  required: PropTypes.bool,
};

VCMCheckbox.defaultProps = {
  onChange: () => {},
  value: false,
  name: 'chk',
  isDisabled: true,
  ariaLabel: '',
  labelledby: '',
  dataRef: null,
  required: false,
};

export default VCMCheckbox;
