export const LOAD_ACCOUNT_REGISTRATION_API = 'LOAD_ACCOUNT_REGISTRATION_API';
export const RESOLVED_GET_MEMBER_BY_GUID = 'RESOLVED_GET_MEMBER_BY_GUID';
export const REJECTED_GET_MEMBER_BY_GUID = 'REJECTED_GET_MEMBER_BY_GUID';
export const INVALID_MEMBER_INFO = 'INVALID_MEMBER_INFO';
export const SET_E_SIGNATURE = 'SET_E_SIGNATURE';
export const RESET_PRE_REGISTRATION_INFO = 'RESET_PRE_REGISTRATION_INFO';
export const RESOLVED_GET_PASSCODE = 'RESOLVED_GET_PASSCODE';
export const REJECTED_GET_PASSCODE = 'REJECTED_GET_PASSCODE';
export const RESOLVED_IVR_REQ_STATUS = 'RESOLVED_IVR_REQ_STATUS';
export const REJECTED_IVR_REQ_STATUS = 'REJECTED_IVR_REQ_STATUS';
export const RESET_OTP_SUCCESS_FLG = 'RESET_OTP_SUCCESS_FLG';
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED';
export const RESOLVED_CREATE_MEMBER = 'RESOLVED_CREATE_MEMBER';
export const REJECTED_CREATE_MEMBER = 'REJECTED_CREATE_MEMBER';
export const SET_USER_ID = 'SET_USER_ID';
export const REJECTED_EMAIL_OTP = 'REJECTED_EMAIL_OTP';
export const RESOLVED_EMAIL_OTP = 'RESOLVED_EMAIL_OTP';
export const REJECTED_VALIDATE_EMAIL_OTP = 'REJECTED_VALIDATE_EMAIL_OTP';
export const RESOLVED_VALIDATE_EMAIL_OTP = 'RESOLVED_VALIDATE_EMAIL_OTP';
export const RESET_EMAIL_OTP_FLG = 'RESET_EMAIL_OTP_FLG';

export const SET_EMAIL_OTP = 'SET_EMAIL_OTP';

export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_PHONE_CTRY_CODE = 'SET_PHONE_CTRY_CODE';
export const SET_PHONE_IS_MOBILE_PROP = 'SET_PHONE_IS_MOBILE_PROP';

export const SET_RESET_WELCOMPAGE_INFO = 'SET_RESET_WELCOMPAGE_INFO';
export const SET_RESET_PASSWORD_INFO = 'SET_RESET_PASSWORD_INFO';
export const SET_RESET_OTP_SUCCESS_FLG = 'SET_RESET_OTP_SUCCESS_FLG';

export const SET_UPDATED_EMAIL = 'SET_UPDATED_EMAIL';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';

