import * as AnalyticsActions from '../../shared/Actions/AnalyticsActions';
import CancelIcon from '../../assets/icon-delete-red.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import StyledButton from '../StyledComponents';
import WButton from '../WButton/WButton';
import './ConformationModal.css';

const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    div.modalDialog {
        @media (max-width: 767px) {
            padding: 25px !important;
        }
    }
`;
const ModalDialog = styled.div`
    
    top: 15%;
    left: 10%;
    right: 10%;
    z-index: 9999;
    width: 80%;
    padding: 40px 175px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
    border-color: rgb(72, 109, 144);
    border-style: solid;
    border-left-width: 8px;
`;
const CancelButtonStyle = styled.span`
    font: 800 16px/20px yorkten-slab-normal;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    @media (max-width: 767px) {
        text-align: center;
    }
`;
const ConformationModalTitle = styled.h2`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
    @media (max-width: 767px) {
        font: 700 20px/24px benton-sans;
        padding-bottom: 12px;
    }
`;
const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    @media (max-width: 767px) {
        font: 16px/24px benton-sans;
    }
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 20px;
    @media (max-width: 767px) {
        /* margin-top: 40px; */
        flex-direction: column;
    }
    #confirmation-modal-primary-btn {
        @media (max-width: 767px) {
            width: 100% !important;
            order: 1;
            margin-bottom: 15px;
        }
    }
`;
const CancelSection = styled(StyledButton)`
    /* margin-right: 50px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    // width: 154px;
    height: 50px;
    align-self: center;
    // border: 1px solid #000000;
    margin-right: 50px;
    @media (max-width: 767px) {
        text-align: center;
        display: inline-block;
        margin-right: 0;
        width: 100%;
        height: 44px;
        border: 1px solid #707070;
        order: 2;
    }
`;
const CancelImage = styled.img`
    margin-left: 15px;
    margin-right: 10px;
    @media (max-width: 767px) {
        margin-left: 0px;
        height: 20px;
    }
`;

class ConformationModalFixedWidth extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.titleElRef = React.createRef();
        this.firstElRef = React.createRef();
        this.lastElRef = React.createRef();
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';

        // set focus on first input
        /* this.titleElRef.current.style.outline = '2px solid #000'; */
        this.titleElRef.current.focus();
    }

    componentWillUnmount() {
        this.titleElRef.current.style.outline = 'none';
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }

    // redirect last tab to first input
    onTitleElRef = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.titleElRef.current.style.outline = 'none';
            this.firstElRef.current.focus();
        }
    };

    // redirect last tab to first  button //input
    onLastElementTab = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.firstElRef.current.focus();
        }
    };

    // redirect first shift+tab to last button //input
    onFirstShiftTab = e => {
        if (e.which === 9 && e.shiftKey) {
            e.preventDefault();
            this.lastElRef.current.focus();
        }
    };

    analyzeEvent = e => {
        const { onSecondaryClick, analyzeClickEvent } = this.props;
        onSecondaryClick();
        analyzeClickEvent({
            link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
        });
    };

    render() {
        const {
            modalTitle,
            modalBodyText,
            modalBodyText2,
            primaryButtonText,
            secondaryButtonText,
            onPrimaryClick,
            dataAnalyticId,
            waitforapiresponse,
            hasPrimaryClick,
            analyzecustom,
            buttonstyle,
        } = this.props;

        return (
           
            <ModalStyle>
                <ModalDialog tabIndex="0" className="modalDialog" aria-modal="true" role="alertdialog" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle tabIndex="0" id="VCM-Modal-title">
                        {' '}
                        {modalTitle}{' '}
                    </ConformationModalTitle>
                    <div ref={this.titleElRef} onKeyDown={this.onTitleElRef} tabIndex="0">
                        <ConformationModalBody> {modalBodyText} </ConformationModalBody>
                        {modalBodyText2 ? <ConformationModalBody> {modalBodyText2} </ConformationModalBody> : null}
                    </div>
                    <FlexDiv>
                        <CancelSection
                            ref={this.firstElRef}
                            onClick={this.analyzeEvent}
                            onKeyDown={this.onFirstShiftTab}
                            data-analytic-id={dataAnalyticId ? `${dataAnalyticId}_cancel` : `confirmation-modal-cancel`}
                        >
                            <CancelImage src={CancelIcon} alt="" />
                            <CancelButtonStyle>{secondaryButtonText}</CancelButtonStyle>
                        </CancelSection>
                        {hasPrimaryClick && (
                            <WButton
                                ref={this.lastElRef}
                                id="confirmation-modal-primary-btn"
                                buttontext={primaryButtonText || 'Delete'}
                                size="sm"
                                onClick={onPrimaryClick}
                                onKeyDown={this.onLastElementTab}
                                dataAnalyticId={
                                    dataAnalyticId ? `${dataAnalyticId}_confirm` : `confirmation-modal-confirm`
                                }
                                buttonstyle={buttonstyle}
                                waitforapiresponse={waitforapiresponse}
                                analyzecustom={analyzecustom}
                            />
                        )}
                    </FlexDiv>
                </ModalDialog>
            </ModalStyle>
            
        );
    }
}

ConformationModalFixedWidth.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    waitforapiresponse: PropTypes.bool,
    hasPrimaryClick: PropTypes.bool,
    dataAnalyticId: PropTypes.string,
    analyzecustom: PropTypes.instanceOf(Object),
    buttonstyle: PropTypes.instanceOf(Object),
};

ConformationModalFixedWidth.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    modalBodyText2: null,
    secondaryButtonText: 'SECONDARY',
    onPrimaryClick: () => {},
    onSecondaryClick: () => {},
    primaryButtonText: '',
    analyzeClickEvent: () => {},
    waitforapiresponse: false,
    dataAnalyticId: '',
    analyzecustom: null,
    buttonstyle: {},
    hasPrimaryClick: true,
};

const mapDispatchToProps = {
    ...AnalyticsActions,
};

export default connect(null, mapDispatchToProps)(ConformationModalFixedWidth);
