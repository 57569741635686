/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import './FAQ.css';
import consts from './constants';
import analytics from './utils.analytics';

const assignObj = (obj) => obj;
consts.AccordionContent.map((data, index) => ({
  ...data,
  id: `accordion-${index}`,
}));

/* eslint-disable react/no-danger */
const renderContent = (description) => {
  if (!description.header) {
    return <div>{description}</div>;
  }
  return (
    <div>
      {/* <div
        dangerouslySetInnerHTML={assignObj({
          __html: description.header,
        })}
      /> */}
      <div>{description.header}</div>
      <ul>
        {description.list.map((content, index) => (
          <li key={Number(index)}>{content}</li>
        ))}
      </ul>
      {/* <div
        dangerouslySetInnerHTML={assignObj({
          __html: description.footer,
        })}
      /> */}
      <div>{description.footer}</div>
    </div>
  );
};
const FAQ = () => {
  const [selectedAccordionIndex, toggleAccordion] = useState(null);
  const handleToggle = (index) => {
    if (index === selectedAccordionIndex) {
      toggleAccordion(null);
    } else {
      analytics.triggerClickTrackEvent(
        `Preregister_Faq_${consts.AccordionContent[`${index}`].title}`,
      );
      toggleAccordion(index);
    }
  };

  const handleKeyToggle = (e, index) => {
    if (e.keyCode === 13) handleToggle(index);
  };

  return (
    <Container fluid className="noPad" role="list">
      {consts.AccordionContent.map(({ title, description, id }, index) => (
        <Row
          className="listDescWrapper"
          noGutters
          key={assignObj(`${id}-${Number(index)}`)}
          role="listitem"
          aria-expanded={
            selectedAccordionIndex !== null && selectedAccordionIndex === index
          }
        >
          <Col md={12} className="listDescCol">
            <h3 className="listHeader">
              <span
                className="listDesc"
                role="button"
                tabIndex="0"
                aria-expanded={
                  selectedAccordionIndex !== null
                  && selectedAccordionIndex === index
                }
                onClick={assignObj(() => handleToggle(index))}
                onKeyDown={assignObj((e) => handleKeyToggle(e, index))}
              >
                {title}
              </span>
            </h3>
            {selectedAccordionIndex !== null && selectedAccordionIndex === index
              ? renderContent(description)
              : null}
          </Col>
          <Col tabIndex="-1" aria-hidden="true">
            {selectedAccordionIndex !== null
            && selectedAccordionIndex === index ? (
              <button
                type="button"
                tabIndex="-1"
                aria-hidden="true"
                onClick={assignObj(() => handleToggle(index))}
                onKeyDown={assignObj((e) => handleKeyToggle(e, index))}
              >
                <FaChevronCircleUp color="#486D90" focusable="false" />
              </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  aria-hidden="true"
                  onClick={assignObj(() => handleToggle(index))}
                  onKeyDown={assignObj((e) => handleKeyToggle(e, index))}
                >
                  <FaChevronCircleDown color="#486D90" focusable="false" />
                </button>
              )}
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default FAQ;
