import {connect} from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { updateProfileOccupationInformations, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { setAddress } from 'shared/Actions/AddressAction';
import { linkResponseError, analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import EmploymentInfoComponent from './EmploymentInfoComponent';

export const mapStateToProps = (state) => {
    const { profileInformationData, masterLookUpData, address } = state;
    const { isError } = profileInformationData;
    return {
		masterLookupStateData: masterLookUpData,
		profileInformationData,
        employerAddressInfo: address,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    }
}

const mapDispatchToProps = {
    getCompositeLookUpData,
    updateProfileOccupationInformations,
    clearExistingActions,
    setEmployerAddress: setAddress,
    linkResponseError, 
    analyzeClickEvent
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNotification(EmploymentInfoComponent));