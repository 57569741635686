/**
 * Personal Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Personal information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason added VD styles
 */

import * as qa from 'query-string';
import AccGlobalConstants from '../AccountManagementConstants';
import {
    Accordian,
    AccordianTitle,
    WBreadCrumb,
    WStepper,
    WInput,
    CommonButtons,
    WSaveModal,
    WMessageBar,
    WSpinner,
    ConformationModal,
    ConfirmModal,
    WRadio,
} from '../../../common';
import BeneficiaryCard from '../BeneficiaryCard/BeneficiaryCard';
import checkValidity from '../../../utils/checkValidity';
import consts from './consts';
import {  getToken, getRole } from 'utils';
import MarketEmploymentInfoCard from '../MarketEmploymentInfoCard';
import MarketFinancialInfoCard from '../MarketFinancialInfoCard';
import generatePersonalInfoPayload from './GeneratePersonalInfoPayload';
import JointPersonalInfoCardComponent from '../PersonalInfoCard/JointPersonalInfoCardContainer';
import MarketMilitaryInfoCard from '../MarketMilitaryInfoCard';
import MarketPersonalInfoCardComponent from '../MarketPersonalInfoCard';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Card } from 'react-bootstrap';
import Rules from './Rules';
import styles from './styles';
import WStepperMobile from '../../../common/WStepper/WStepperMobile';
import '../MarketPersonalInfoComponent/mstyle.css';
import { ConfirmationMessage, MessagePopup } from 'modules/CustomerManagementModule/components';
import encrypt from 'shared/Helpers/Encrypt';
import moment from 'moment-timezone';
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts';
import { getRankValueBasedOnKey, getValue } from 'utils/utils';
import envConfig from 'env/EnvConfig';

const labelTextStyle = {
    font: '600 16px/19px benton-sans',
    color: 'rgb(73, 73, 74)',
    display: 'flex',
    alignItems: 'center',
};

const pageHeaderStyle = {
    paddingTop: 40,
    fontSize: 30,
    color: 'rgba(72, 109, 144, 1)',
    fontWeight: '800',
    paddingBottom: 10,
    borderBottom: '2px solid #8CBF26',
    fontFamily: 'yorkten-slab-normal',
    float: 'left',
    marginBottom: 40,
};
const mandatoryFieldlabel = {
    font: '100 16px/22px benton-sans',
    color: 'rgb(73, 73, 74)',
    marginTop: 10,
    float: 'right',
    marginBottom: 15,
};

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/marketAccountType', name: 'Open a Marketplace Account' },
];



const ERROR_OFFSCREEN_TEXT = 'Error: ';

const pages529 = [
    'Account Type',
    'Application - Personal and Beneficiary Information',
    'Personal Information',
    'Beneficiary Information',
    'Application - Choose your Portfolio',
];

const UTMAStateWithAge18ForNonMinor = ['VA', 'SD', 'OK', 'NV', 'MI', 'ME', 'LA', 'KY', 'DC', 'CA', 'SC'];

const dateFormatText = 'MM/DD/YYYY';
const assignObj = obj => {
    return obj;
};



const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const radioText = { font: '500 16px/20px benton-sans' };

class MarketPersonalInfoComponent extends Component {
    constructor(props) {
        super(props);
        const address=this.props.marketLocalStateData?.marketNAOInfo?.addressInfo
        this.state = {
            moveNextScreen: false,
            load: false,
            apiError: false,
            apiErrorMessage: false,
            temperarilyDisabled:false,
            showNonResidentialPopUp: false,
            errorMsg: {},
            nickName: '',
            error: {},
            showAlert: false,
            errorMessage: '',
            allSectionsValid: false,
            loading: false,
            showConfirmCancelModal: false,
            primaryOwnerDataVerified: false,
            jointOwner1DataVerified: false,
            jointOwner2DataVerified: false,
            isAccountOpeningAllowed: true,
            showAccountOpeningErrorModal: false,
            isJoint: false,
            jointOwnerVerification: false,
            isSeniorForeignPoliticalFigure: '',
            seniorPoliticalFigureName: '',
            numberOfJointOwners: 0,
            isUGMABeneAgeCheck: true,
            isPOBOXCheckValid: true,
            isValidMilitaryStartDate: true,
            isNextDisbled:false,
            pageIndex : 0,
            personalInformationData: [],
            personalInfoJointData : [],
            ssnDataInfoForJoint : [],
            addressInfoForJoint : [],
            primaryPhoneForJoint : [],
            secondaryPhoneForJoint : [],
            primaryEmailForJoint : [],
            secondaryEmailForJoint: [],
            empFinAndMilInformationData: [],
            sameAddressInfoData:[{
                primaryIsPhysicalAddressSame: (this.isSameAddress(address?.primary?.mailingAddress,address?.primary?.
                    physicalAddress) == true) ?  "Yes" : "No"
            },
            {
                primaryIsPhysicalAddressSame:(this.isSameAddress(address?.secondary?.mailingAddress,address?.secondary?.physicalAddress) == true) ?  "Yes" : "No"
            }]        
        };
        this.primaryOwnerPerCardRef = React.createRef();
        this.primaryOwnerEmpCardRef = React.createRef();
        this.primaryOwnerFinCardRef = React.createRef();
        this.primaryOwnerMilCardRef = React.createRef();
        this.primaryOwnerEmpCardForJointRef = React.createRef();
        this.primaryOwnerFinCardForJointRef = React.createRef();
        this.primaryOwnerMilCardForJointRef = React.createRef();
        this.jointOwnerPerCardRef = React.createRef();
        this.beneficiaryCardRef = React.createRef();
        this.accordionRef = React.createRef();

        this.state.pageIndex = this.props?.history?.location?.state?.pageIndex ? this.props.history.location.state.pageIndex : 0
    
        //Verify Edit Page Index Setter
     if (this.props?.verifyEmpHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO
     }
     if (this.props?.verifyFinHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO
     }
     if (this.props?.verifyMilHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO
     }
     if (this.props?.verifyJointPersonalHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.PERSONAL_INFO_JOINT
     }
     if (this.props?.verifyJointContactHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.PERSONAL_INFO_JOINT
     }
     if (this.props?.verifyJointPhoneHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.PERSONAL_INFO_JOINT
     }
     if (this.props?.verifyJointEmailHider){
        this.state.pageIndex = consts.MARKET_NAO_STEPS.PERSONAL_INFO_JOINT
     }
     if (this.props?.verifyJointEmpHider){
        this.state.pageIndex =  consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO_JOINT
     }
     if (this.props?.verifyJointFinHider){
        this.state.pageIndex =  consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO_JOINT
     }
     if (this.props?.verifyJointMilHider){
        this.state.pageIndex =  consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO_JOINT
     }
            
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            retrivedUserInfo = false,
            nickName,
            isSeniorForeignPoliticalFigure,
            seniorPoliticalFigureName,
        } = prevState;
        const {
            localStateData,
            manageLocalState,
            accOpeningData,
            profileInformationData,
            clearLocalStateForNAOAccTypeChange,
            initialState,
        } = nextProps;
        const { OpenAccPageTwo } = localStateData;
        const accType = localStateData.accountType || 'Individual';
        const role = getRole(initialState && initialState.getCustomerProfile);

        if (
            (role === 'Guest' || role === 'Prospect') &&
            initialState.getCustomerProfile &&
            !retrivedUserInfo &&
            !accOpeningData.isLoadingApplication
        ) {
            if (
                accOpeningData.application &&
                accOpeningData.application[accType.toString()] &&
                !localStateData.OpenAccPageTwo
            ) {
                manageLocalState({
                    ...accOpeningData.application,
                });
            } else if (!localStateData[accType.toString()]) {
                clearLocalStateForNAOAccTypeChange();
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            ...localStateData.getCustomerProfile,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            } else if (localStateData.marstoneFlag === true) {
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            firstName: localStateData.getCustomerProfile.firstName,
                            lastName: localStateData.getCustomerProfile.lastName,
                            middleInitial: localStateData.getCustomerProfile.middleInitial,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            }

            return {
                retrivedUserInfo: true,
                loading: initialState.isLoading || accOpeningData.isLoading,
                showConfirmModal: accOpeningData.showConfirmModal,
                confirmMessage: accOpeningData.confirmMessage,
                primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
                jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
                jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
                isSeniorForeignPoliticalFigure:
                    isSeniorForeignPoliticalFigure ||
                    (OpenAccPageTwo &&
                        OpenAccPageTwo.pepQuestion &&
                        OpenAccPageTwo.pepQuestion.isSeniorForeignPoliticalFigure),
                seniorPoliticalFigureName:
                    seniorPoliticalFigureName ||
                    (OpenAccPageTwo &&
                        OpenAccPageTwo.pepQuestion &&
                        OpenAccPageTwo.pepQuestion.seniorPoliticalFigureName),
            };
        }

        if (
            role === 'Member' &&
            profileInformationData.profileInformation &&
            !retrivedUserInfo &&
            !accOpeningData.isLoadingApplication
        ) {
            if (
                accOpeningData.application &&
                accOpeningData.application[accType.toString()] &&
                !localStateData.OpenAccPageTwo
            ) {
                manageLocalState({
                    ...accOpeningData.application,
                });
            } else if (!localStateData[accType.toString()]) {
                clearLocalStateForNAOAccTypeChange();
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            ...profileInformationData.profileInformation,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            } else if (localStateData.marstoneFlag === true) {
                manageLocalState({
                    [accType]: {
                        perInfo: {
                            firstName: profileInformationData.profileInformation.firstName,
                            lastName: profileInformationData.profileInformation.lastName,
                            middleInitial: profileInformationData.profileInformation.middleInitial,
                            nickName,
                            readOnly: true,
                            retrievedState: false,
                        },
                    },
                });
            }

            return {
                retrivedUserInfo: true,
                loading: initialState.isLoading || accOpeningData.isLoading,
                showConfirmModal: accOpeningData.showConfirmModal,
                confirmMessage: accOpeningData.confirmMessage,
                primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
                jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
                jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
                isSeniorForeignPoliticalFigure:
                    isSeniorForeignPoliticalFigure ||
                    (OpenAccPageTwo &&
                        OpenAccPageTwo.pepQuestion &&
                        OpenAccPageTwo.pepQuestion.isSeniorForeignPoliticalFigure),
                seniorPoliticalFigureName:
                    seniorPoliticalFigureName ||
                    (OpenAccPageTwo &&
                        OpenAccPageTwo.pepQuestion &&
                        OpenAccPageTwo.pepQuestion.seniorPoliticalFigureName) ||
                    '',
            };
        }

        return {
            loading: initialState.isLoading || accOpeningData.isLoading,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            primaryOwnerDataVerified: accOpeningData.primaryOwnerVerified,
            jointOwner1DataVerified: accOpeningData.jointOwner1Verified,
            jointOwner2DataVerified: accOpeningData.jointOwner2Verified,
            isSeniorForeignPoliticalFigure:
                isSeniorForeignPoliticalFigure ||
                (OpenAccPageTwo &&
                    OpenAccPageTwo.pepQuestion &&
                    OpenAccPageTwo.pepQuestion.isSeniorForeignPoliticalFigure),
            seniorPoliticalFigureName,
        };
    }

    componentDidMount() {
        document.title = `Personal Info: Open Account | Victory Capital`;
        sessionStorage.setItem('isRefresh', true);
        const {
            localStateData,
            profileInformationData,
            getMemberProfileInformation,
            initialState,
            getCustomerProfile,
            accOpeningData,
            getCognitoForCustomerVerification,
            history,
        } = this.props;

        const { accountType } = localStateData;
        const accType = accountType;
        const role = sessionStorage.getItem('role');
        if (accOpeningData && accOpeningData.accountDefaultNickName) {
            this.setState({ nickName: accOpeningData.accountDefaultNickName });
        }

        if (!initialState.getCustomerProfile && (role === 'Guest' || role === 'Prospect')) {
            getCustomerProfile({});
        }
        if (!profileInformationData.profileInformation) {

            getMemberProfileInformation();
        }

        // Gets cognito token for customer GIACT verification
        const cognitoPayload = qa.stringify({
            grant_type: 'client_credentials',
            client_id: envConfig.COGNITO_CUSTOMER_GIACT_VERIFICATION_CLIENT_ID,
            client_secret: envConfig.COGNITO_CUSTOMER_GIACT_VERIFICATION_CREDENTIALS,
        });

        getCognitoForCustomerVerification(cognitoPayload);

        if (accType && localStateData[accType.toString()]) {
            const finalStateObj = {};
            const personalData = localStateData[accType.toString()];
            const personalInfoCardData = localStateData[accType.toString()].perInfo;
            if (personalData.nickName !== undefined) {
                finalStateObj.nickName = personalData.nickName;
            }
           
            this.setState({ ...finalStateObj }, () => {
                this.checkStatus();
            });

            if(history.location.state?.pageIndex){
                this.setState({pageIndex:history.location.state.pageIndex})
            }

        }

        if(history.location.state?.pageIndex){
            this.setState({pageIndex : history.location.state.pageIndex})
        }
    }

    clearApiStatusResponse = (list) => {
        let arr = []
        arr = list
        arr.forEach(item => {
            this.props.clearAPIStatus(item)
        })
    } 


    componentDidUpdate(prevProps,prevState) {

        if(prevState.pageIndex != this.state.pageIndex)
        {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: 'smooth',
            });
        }

        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }

        if(this.state.pageIndex == 0)
        {
            let personalDetails = {};

            if(this.primaryOwnerPerCardRef?.current?.state)
            {
                personalDetails['personalInformation'] = this.primaryOwnerPerCardRef?.current?.state;
            }

            if(this.primaryOwnerPerCardRef?.current?.ssnRef?.current?.state)
            {
                personalDetails['ssnInfo'] = this.primaryOwnerPerCardRef?.current?.ssnRef?.current?.state;
            }

            if(this.primaryOwnerPerCardRef?.current?.perPhysicalAddressPref?.current?.state)
            {
                personalDetails['physicalAddressInfo'] = this.primaryOwnerPerCardRef?.current?.perPhysicalAddressPref?.current?.state;
            }

            if(this.primaryOwnerPerCardRef?.current?.perMailingAddressPref?.current?.state)
            {
                personalDetails['mailinglAddressInfo'] = this.primaryOwnerPerCardRef?.current?.perMailingAddressPref?.current?.state;
            }

            if(this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.primaryEmailDetailsRef?.current?.state)
            {
                const emailAddressInfo = {
                    'primaryEmailAddress' : this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.primaryEmailDetailsRef?.current?.state,
                    'secondaryEmailAddress': this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.secondaryEmailDetailsRef?.current?.state,
                }
                personalDetails['emailInfo'] = emailAddressInfo;
            }

            if(this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.primaryEmailDetailsRef?.current?.state)
            {
                const phoneAddressInfo = {
                    'primaryPhoneAddress' : this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.primaryPhoneDetailsRef?.current?.state,
                    'secondaryPhoneAddress': this.primaryOwnerPerCardRef?.current?.perContactDetailsPref?.current?.secondaryPhoneDetailsRef?.current?.state,
                }
                personalDetails['phoneInfo'] = phoneAddressInfo;
            }
            this.state.personalInformationData[0] = personalDetails; 

        }
    
        
        const {marketLocalStateData} = this.props
        if(this.state.moveNextScreen){

            // Primary Type
            if(this.state.pageIndex == 0){
                if(marketLocalStateData.ispersonalInfoApiSuccess && marketLocalStateData.isaddressApiSuccess && marketLocalStateData.iscontactInfoApiSuccess){
                    this.onSwitchComponent(true)
                    this.setState({moveNextScreen: false, load: false})
                    this.clearApiStatusResponse(['personalInfo', 'address', 'contactInfo'])

                }else if(marketLocalStateData.ispersonalInfoApiError || marketLocalStateData.isaddressApiError || marketLocalStateData.iscontactInfoApiError){
                    this.setState({apiError: true, moveNextScreen: false, apiErrorMessage: marketLocalStateData.postStatusMsg, load: false})
                    this.clearApiStatusResponse(['personalInfo', 'address', 'contactInfo'])
                }
    
    
            }else if(this.state.pageIndex == 1){
                if(marketLocalStateData.isemploymentInfoApiSuccess && marketLocalStateData.isfinancialInfoApiSuccess && marketLocalStateData.ismilitaryInfoApiSuccess){
                    if(marketLocalStateData.marketNAOInfo.account.accountType == consts.ACCOUNTTYPES.JOINT){
                        this.onSwitchComponent(true)
                        this.setState({load: false})
                        this.clearApiStatusResponse(['employmentInfo', 'financialInfo', 'militaryInfo'])
                    }else{
                        this.setState({next:true, load: false})
                        this.clearApiStatusResponse(['employmentInfo', 'financialInfo', 'militaryInfo'])
                    }
                  
                }else if(marketLocalStateData.isemploymentInfoApiError || marketLocalStateData.isfinancialInfoApiError || marketLocalStateData.ismilitaryInfoApiError){
                    this.setState({apiError: true, moveNextScreen: false,  apiErrorMessage: marketLocalStateData.postStatusMsg, load: false})
                    this.clearApiStatusResponse(['employmentInfo', 'financialInfo', 'militaryInfo'])
                }
            
            }

            // Joint Account Type Conditions
            else if(this.state.pageIndex == 2){

                if(marketLocalStateData.ispersonalInfoApiSuccess && marketLocalStateData.isaddressApiSuccess && marketLocalStateData.iscontactInfoApiSuccess){
                    this.onSwitchComponent(true)
                    this.setState({moveNextScreen: false, load: false})
                    this.clearApiStatusResponse(['personalInfo', 'address', 'contactInfo'])
                }else if(marketLocalStateData.ispersonalInfoApiError || marketLocalStateData.isaddressApiError || marketLocalStateData.iscontactInfoApiError){
                    this.setState({apiError: true, moveNextScreen: false, load: false, apiErrorMessage: marketLocalStateData.postStatusMsg})
                    this.clearApiStatusResponse(['personalInfo', 'address', 'contactInfo'])
                }
    
    
            }else if(this.state.pageIndex == 3){
                
                if(marketLocalStateData.isemploymentInfoApiSuccess && marketLocalStateData.isfinancialInfoApiSuccess && marketLocalStateData.ismilitaryInfoApiSuccess){
                    this.onSwitchComponent(true)
                    this.setState({next:true, load: false})
                    this.clearApiStatusResponse(['employmentInfo', 'financialInfo', 'militaryInfo'])
                }else if(marketLocalStateData.isemploymentInfoApiError || marketLocalStateData.isfinancialInfoApiError || marketLocalStateData.ismilitaryInfoApiError){
                    this.setState({apiError: true, moveNextScreen: false, load: false, apiErrorMessage: marketLocalStateData.postStatusMsg})
                    this.clearApiStatusResponse(['employmentInfo', 'financialInfo', 'militaryInfo'])
                }
            
            }

        }

    }

    componentWillUnmount() {
        sessionStorage.setItem('isRefresh', false);
    }

    handlePersonalStateData = (data,type)=>
    {
        this.setState({})
    }

    handleSuccessConfirmation = () => {
       
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' },()=>{
           const saveButton = document.querySelector(".rwdBtnWrapper #saveButton")
           if (saveButton) {
              saveButton.focus();
            }
        });
        resetShowConfirmModal();
        
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history, initialState } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = getRole(initialState && initialState.getCustomerProfile);
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    // DUPLICATE METHOD, CHECK, TEST AND CONFIRM WHICH ONE TO USE

    handleExitNew = () => {
        this.setState({apiError: false,  apiErrorMessage: ''})
    }

    updateState = prevProps => {
        const { accOpeningData } = this.props;

        // Image Upload Handling
        this.handleuploadImage(accOpeningData, prevProps);

        // W8Form Upload Handling
        this.handleW8FormUpload(accOpeningData, prevProps);

        //   Save Function Values Start
        if (
            accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT &&
            accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT !== prevProps.accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT
        ) {
            if (accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT.status) {
                this.setState({ showSaveModal: true, loading: false, variantForUpload: 'success' });
            } else {
                const msg = accOpeningData.PERSONAL_INFO_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
        //   Save Function Values end

        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleuploadImage = (accOpeningData, prevProps) => {
        if (accOpeningData.UPLOAD_AVATAR && accOpeningData.UPLOAD_AVATAR !== prevProps.accOpeningData.UPLOAD_AVATAR) {
            if (accOpeningData.UPLOAD_AVATAR.imageDetails) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: {
                        message: 'Success',
                        info: 'Image Uploaded',
                    },
                    imageFileUploaded: true,
                    showSaveModal: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                const msg = accOpeningData.UPLOAD_AVATAR.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    imageFileUploaded: false,
                    showSaveModal: false,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    handleW8FormUpload = (accOpeningData, prevProps) => {
        if (accOpeningData.UPLOAD_W8FORM && accOpeningData.UPLOAD_W8FORM !== prevProps.accOpeningData.UPLOAD_W8FORM) {
            if (accOpeningData.UPLOAD_W8FORM.imageDetails) {
                this.setState({
                    showAlert: true,
                    loading: false,
                    errorMessage: {
                        message: 'Success',
                        info: 'Form Uploaded',
                    },
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                const msg = accOpeningData.UPLOAD_W8FORM.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    showSaveModal: false,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    convertBase64ToFile = (base64, index) => {
        const { originalFileArray } = this.state;
        const encodedFile = this.base64ToArrayBuffer(base64);
        const ext =
            originalFileArray &&
            originalFileArray.map((f, i) => {
                if (i === index) {
                    return f.type;
                }
                return {};
            });
        const fileName =
            originalFileArray &&
            originalFileArray.map((f, i) => {
                if (i === index) {
                    return f.fileName;
                }
                return {};
            });
        return new File([encodedFile], fileName, { type: ext });
    };

    base64ToArrayBuffer = base64 => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i += 1) {
            bytes[Number(i)] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    getCardValues = data => {
        const { childArr } = this.state;
        if (childArr) {
            childArr.push(data);
        }
    };

    getJointCardValues = data => {
        const { jointChildArr } = this.state;
        if (jointChildArr) {
            jointChildArr.push(data);
        }
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    uploadProfileImage = imgData => {
        if (imgData !== undefined) {
            const { uploadAavatarImg } = this.props;
            const { imageFileUploaded, originalFileArray } = this.state;
            if (originalFileArray === null || (originalFileArray === undefined && !imageFileUploaded)) {
                const payload = {
                    Body: imgData.personalInfoUploadPh[0].base64String,
                };
                this.setState({
                    personalInfoUploadPh: imgData.personalInfoUploadPh[0].base64String,
                    originalFileArray: [imgData.originalpersonalInfoUploadPh],
                    loading: true,
                });
                uploadAavatarImg(payload);
            }
        }
    };


    validatePhoneInformation = () =>
    {
        let validFlag = true;
        const {marketNAOInfo} = this.props.marketLocalStateData;
        const {pageIndex} = this.state;
        if(pageIndex == 0)
        {
            const {personalInformationData} = this.state;
            const phoneAddressObj = personalInformationData[0]?.phoneInfo?.primaryPhoneAddress;
            const phoneValid = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.perContactDetailsPref.current.sendDataToParent();

            if(phoneValid?.valid)
            {
                if((phoneAddressObj?.primaryPhoneDetails?.phoneNumber != "" && phoneAddressObj?.primaryPhoneDetails?.phoneNumber?.length < 10 ) &&  phoneValid.valid== false  )
                {
                         validFlag = false;
                }
            }
            else
            {
                if(phoneValid.valid== false ||  phoneAddressObj?.primaryPhoneDetails?.phoneNumber == "" || phoneAddressObj?.primaryPhoneDetails?.phoneNumber?.length < 10) 
                {
                    validFlag = false;
                }
            }
        }
        else if(pageIndex == 2)
        {
            const phoneValid = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.perContactDetailsPref.current.sendDataToParent();
            if(phoneValid?.valid)
            {
                if((phoneValid?.primaryPhoneDetails?.phoneNumber != "" && phoneValid?.primaryPhoneDetails?.phoneNumber?.length < 10) && phoneValid.valid== false  )
                {
                         validFlag = false;
                }
                else if(phoneValid.primaryPhoneDetails.phoneNumber != "" && phoneValid.primaryPhoneDetails.phoneNumber?.length < 10)
                {
                    validFlag = false;
                }
                else if(marketNAOInfo.contactInfo.primary.phoneInfo.primary.number == phoneValid?.primaryPhoneDetails?.phoneNumber )
                {
                    validFlag = false;
                }
            }
            else
            {
                if(phoneValid.valid== false ||  phoneValid?.primaryPhoneDetails?.phoneNumber == "" || phoneValid?.primaryPhoneDetails?.phoneNumber?.length < 10) 
                {
                    validFlag = false;
                }
                else if(marketNAOInfo.contactInfo.primary.phoneInfo.primary.number == phoneValid?.primaryPhoneDetails?.phoneNumber )
                {
                    
                    validFlag = false;
                }
            }
        }
           
        return validFlag;
    }

    validateEmailInformation = () =>
    {
        const {pageIndex} = this.state;
        let validFlag = true;
        const {marketNAOInfo} = this.props.marketLocalStateData;
        if(pageIndex == 0)
        {
            const {personalInformationData} = this.state;
        const emailAddressObj = personalInformationData[0]?.emailInfo?.primaryEmailAddress;
        const allValidInfoEmail = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.perContactDetailsPref.current.sendEmailDataToParent();
            if(
                allValidInfoEmail.valid== false || emailAddressObj.email == undefined || emailAddressObj.email == "" || emailAddressObj.email == null && (emailAddressObj.errEmail != null || emailAddressObj.errEmail !="")
                 )
                {
                    validFlag = false;
                }
        }
        else if(pageIndex == 2)
        {
            const allValidInfoEmail = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.perContactDetailsPref.current.sendEmailDataToParent();
            if(
                allValidInfoEmail.valid== false || allValidInfoEmail.primaryEmailDetails.email == undefined || allValidInfoEmail.primaryEmailDetails.email == "" || allValidInfoEmail.primaryEmailDetails.email == null && (allValidInfoEmail.primaryEmailDetails.errEmail != null || allValidInfoEmail.primaryEmailDetails.errEmail !="")
                 )
                {
                    validFlag = false;
                }
                else if(marketNAOInfo.contactInfo.primary?.emailAddresses.primaryEmailAddress == allValidInfoEmail.primaryEmailDetails.email)
                {
                   
                    validFlag = false;
                }
        }
    
        return validFlag;
    }

    validateMailingAddressInformation = () =>
    {
        const {sameAddressInfoData} = this.state;
        let validFlag = true;
        const {pageIndex} = this.state;
        
        if(pageIndex == 0)
        {
            if(sameAddressInfoData[0].primaryIsPhysicalAddressSame == 'No')
            {
                const mailingAddressObj = this.primaryOwnerPerCardRef.current.perMailingAddressPref.current.state;
                if(
                    mailingAddressObj.lineTwo == undefined || mailingAddressObj.lineTwo == "" || mailingAddressObj.lineTwo == null || 
                    mailingAddressObj.city == undefined || mailingAddressObj.city == "" || mailingAddressObj.city == null ||
                    mailingAddressObj.zipCode == undefined || mailingAddressObj.zipCode == "" || mailingAddressObj.zipCode == null || 
                    mailingAddressObj.state == undefined || mailingAddressObj.state == "" || mailingAddressObj.state == null || 
                    mailingAddressObj.mailTypeRadio == undefined || mailingAddressObj.mailTypeRadio == "" || mailingAddressObj.mailTypeRadio == null 
                     )
                    {
                        validFlag = false;
                        return validFlag;
                    }
                    else
                    {
                        return true;
                    }
            }
            else
            {
                return true;
            }
        }
        else if(pageIndex == 2)
        {
            if(sameAddressInfoData[1].primaryIsPhysicalAddressSame == 'No')
            {
                const mailingAddressObj =  this.jointOwnerPerCardRef.current.perMailingAddressPref.current.state;
                if(
                    mailingAddressObj.lineTwo == undefined || mailingAddressObj.lineTwo == "" || mailingAddressObj.lineTwo == null || 
                    mailingAddressObj.city == undefined || mailingAddressObj.city == "" || mailingAddressObj.city == null ||
                    mailingAddressObj.zipCode == undefined || mailingAddressObj.zipCode == "" || mailingAddressObj.zipCode == null || 
                    mailingAddressObj.state == undefined || mailingAddressObj.state == "" || mailingAddressObj.state == null || 
                    mailingAddressObj.mailTypeRadio == undefined || mailingAddressObj.mailTypeRadio == "" || mailingAddressObj.mailTypeRadio == null 
                     )
                    {
                        validFlag = false;
                        return validFlag;
                    }
                    else
                    {
                        return true;
                    }
            }
            else
            {
                return true;
            }
        }

    }


    validatePhysicalAddressInformation = () =>
    {
        let validFlag = true;
        const {pageIndex} = this.state;
        const accountTypeSelected= sessionStorage.getItem("accountTypeSelected")
        if(pageIndex == 0)
        {
            const physicalAddressObj = this.primaryOwnerPerCardRef.current.perPhysicalAddressPref.current.state;
            const stateNameValidation=accountTypeSelected== MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT? physicalAddressObj.state == "LA" || physicalAddressObj.state == "PR"  : null
            if(
                physicalAddressObj.lineTwo == undefined || physicalAddressObj.lineTwo == "" || physicalAddressObj.lineTwo == null || 
                physicalAddressObj.city == undefined || physicalAddressObj.city == "" || physicalAddressObj.city == null ||
                physicalAddressObj.zipCode == undefined || physicalAddressObj.zipCode == "" || physicalAddressObj.zipCode == null || !/^\d{5}(-\d{4})?$/.test(physicalAddressObj.zipCode) || 
                physicalAddressObj.zipCode.length > 10 || physicalAddressObj.zipCode.match(/[a-zA-Z!@$%^*(),.?";:{}_+#&|<>=~`\[\]'\\/]/g) ||
                physicalAddressObj.state == undefined || physicalAddressObj.state == "" || physicalAddressObj.state == null || stateNameValidation  ||
                physicalAddressObj.mailTypeRadio == undefined || physicalAddressObj.mailTypeRadio == "" || physicalAddressObj.mailTypeRadio == null 
                 )
                {
                    validFlag = false;
                    window.scrollTo(0,1600);
                }
        }
        else if(pageIndex == 2)
        {
            const physicalAddressObj =  this.jointOwnerPerCardRef.current.perPhysicalAddressPref.current.state;
            if(
                physicalAddressObj.lineTwo == undefined || physicalAddressObj.lineTwo == "" || physicalAddressObj.lineTwo == null || 
                physicalAddressObj.city == undefined || physicalAddressObj.city == "" || physicalAddressObj.city == null ||
                physicalAddressObj.zipCode == undefined || physicalAddressObj.zipCode == "" || physicalAddressObj.zipCode == null || 
                physicalAddressObj.state == undefined || physicalAddressObj.state == "" || physicalAddressObj.state == null || 
                physicalAddressObj.mailTypeRadio == undefined || physicalAddressObj.mailTypeRadio == "" || physicalAddressObj.mailTypeRadio == null 
                 )
                {
                    validFlag = false;
                }
        }
    
        return validFlag;

    }

    validateSSN = ()=>
    {
        const {pageIndex} = this.state;
        let validFlag = true;
        if(pageIndex == 0)
        {
            const {personalInformationData} = this.state;
            const ssnInfoObj = personalInformationData[0].ssnInfo;
            if(
                ssnInfoObj.ssnOne == undefined || ssnInfoObj.ssnOne == "" || ssnInfoObj.ssnOne == null || ssnInfoObj.ssnOne.length !=3 ||
                ssnInfoObj.ssnTwo == undefined || ssnInfoObj.ssnTwo == "" || ssnInfoObj.ssnTwo == null || ssnInfoObj.ssnTwo.length !=2 ||
                ssnInfoObj.ssnThree == undefined || ssnInfoObj.ssnThree == "" || ssnInfoObj.ssnThree == null || ssnInfoObj.ssnThree.length !=4
                 )
                {
                    validFlag = false;
                }
        }
        else if(pageIndex == 2)
        {
            const ssnInfoObj = this.jointOwnerPerCardRef.current.ssnRef.current.state;
            if(
                ssnInfoObj.ssnOne == undefined || ssnInfoObj.ssnOne == "" || ssnInfoObj.ssnOne == null || ssnInfoObj.ssnOne.length !=3 ||
                ssnInfoObj.ssnTwo == undefined || ssnInfoObj.ssnTwo == "" || ssnInfoObj.ssnTwo == null || ssnInfoObj.ssnTwo.length !=2 ||
                ssnInfoObj.ssnThree == undefined || ssnInfoObj.ssnThree == "" || ssnInfoObj.ssnThree == null || ssnInfoObj.ssnThree.length !=4
                 )
                {
                    validFlag = false;
                }
        }    
        
        return validFlag;
    }

    validatePersonalInformation =  () =>
    {
        let validFlag = true;
        const {pageIndex} = this.state;
        if(pageIndex == 0)
        {
            const personalInfo = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.sendDataToParent('next');
            if(
                personalInfo.valid == false || 
                personalInfo.citizenship == undefined || personalInfo.citizenship == "" || personalInfo.citizenship == null || 
                personalInfo.firstName == undefined || personalInfo.firstName == "" || personalInfo.firstName == null || 
                personalInfo.lastName == undefined || personalInfo.lastName == "" || personalInfo.lastName == null || 
                personalInfo.maritalLocal == undefined || personalInfo.maritalLocal == "" || personalInfo.maritalLocal == null || 
                personalInfo.ownerDateOfBirth == undefined || personalInfo.ownerDateOfBirth == "" ||  personalInfo.ownerDateOfBirth == null ||
                personalInfo.gender == undefined || personalInfo.gender == "" || personalInfo.gender == null 
                 )
                {
                    validFlag = false;
                }
        }
        else if(pageIndex == 2)
        {
            const personalInfo = this.jointOwnerPerCardRef.current.sendDataToParent('next');
            if(
                personalInfo.valid == false || 
                personalInfo.citizenship == undefined || personalInfo.citizenship == "" || personalInfo.citizenship == null || 
                personalInfo.firstName == undefined || personalInfo.firstName == "" || personalInfo.firstName == null || 
                personalInfo.lastName == undefined || personalInfo.lastName == "" || personalInfo.lastName == null || 
                personalInfo.maritalLocal == undefined || personalInfo.maritalLocal == "" || personalInfo.maritalLocal == null || 
                personalInfo.ownerDateOfBirth == undefined || personalInfo.ownerDateOfBirth == "" ||  personalInfo.ownerDateOfBirth == null ||
                personalInfo.gender == undefined || personalInfo.gender == "" || personalInfo.gender == null 
                 )
                {
                    validFlag = false;
                }
        }

        return validFlag;
    } 

    validateEmploymentFinancialAndMilitaryInformation = ()=>
    {
        const {pageIndex} = this.state;
        if (pageIndex == 1)
        {   let isEmpOptDatavalid
            const empData  = this.primaryOwnerEmpCardRef.current && this.primaryOwnerEmpCardRef.current.sendDataToParent();
            isEmpOptDatavalid = empData.valid
            if(empData?.empAddress !=undefined && empData?.empAddress !=null)
            {
                if((empData?.empAddress?.addressLine2 != undefined && empData?.empAddress?.addressLine2 != null && empData?.empAddress?.addressLine2 != "") || (empData?.empAddress?.zipCode != undefined && empData?.empAddress?.zipCode != null && empData?.empAddress?.zipCode != ""))
                {
                    isEmpOptDatavalid = empData?.empAddress?.valid;
                }
            }
            const finData = this.primaryOwnerFinCardRef.current && this.primaryOwnerFinCardRef.current.sendDataToParent();
            const MilData = this.primaryOwnerMilCardRef.current && this.primaryOwnerMilCardRef.current.sendDataToParent();
            const validFalg = (isEmpOptDatavalid && empData.valid && finData.valid && MilData.valid )
            return validFalg;
        }
        else if(pageIndex == 3)
        {let isEmpOptDatavalid
            const empData  = this.primaryOwnerEmpCardForJointRef.current && this.primaryOwnerEmpCardForJointRef.current.sendDataToParent();
            isEmpOptDatavalid = empData.valid
            if(empData?.empAddress !=undefined && empData?.empAddress !=null)
            {
                if((empData?.empAddress?.addressLine2 != undefined && empData?.empAddress?.addressLine2 != null && empData?.empAddress?.addressLine2 != "") || (empData?.empAddress?.zipCode != undefined && empData?.empAddress?.zipCode != null && empData?.empAddress?.zipCode != ""))
                { 
                    isEmpOptDatavalid = empData?.empAddress?.valid;
                }
            }
            const finData = this.primaryOwnerFinCardForJointRef.current && this.primaryOwnerFinCardForJointRef.current.sendDataToParent();
            const MilData = this.primaryOwnerMilCardForJointRef.current && this.primaryOwnerMilCardForJointRef.current.sendDataToParent();
            const validFalg = (isEmpOptDatavalid && empData.valid && finData.valid && MilData.valid )
            return validFalg;
        }
        
    }
 
    maskDOB = dob => {
        let maskedMember = dob.substring(0, dob.length - 4).replace(/[0-9]/g, "X");
        maskedMember = maskedMember.replace('-', '/');
        maskedMember = maskedMember.replace('-', '/');
        const lastMember = dob.substring(dob.length - 4);
        return maskedMember + lastMember;
    };
      
    getMaritalStatusDescription = marital => {
        let maritalStatus = marital;

        switch (marital) {
            case 'D':
                maritalStatus = 'Divorced';
                break;
            case 'M':
                maritalStatus = 'Married';
                break;
            case 'S':
                maritalStatus = 'Single';
                break;
            case 'SE':
                maritalStatus = 'Separated';
                break;
            case 'W':
                maritalStatus = 'Widowed';
                break;
            default:
                break;
        }
        return maritalStatus;
    };


    createPersonalInfoPayloadForJoint = async () =>
    {

        const personalInfo = this.jointOwnerPerCardRef.current.sendDataToParent();
        const ssnDataInfo = this.jointOwnerPerCardRef.current.ssnRef.current.state;

        const {updateMarketNAOInfo,masterLookupStateData,initialState} = this.props;
        const role = getRole(initialState && initialState?.getCustomerProfile)
        let relationshipInfo = "";
        for (let i = 0; i < masterLookupStateData?.relationship?.value?.length; i++) {
            if (masterLookupStateData?.relationship?.value[i].key == personalInfo.relationship) {
                relationshipInfo = masterLookupStateData?.relationship?.value[i].value;
            }
        }


        let personalInfoJointObj = {
            citizenship:personalInfo.citizenship,
            dob:  await encrypt(personalInfo.ownerDateOfBirth),
            firstName: personalInfo.firstName,
            gender: personalInfo.gender,
            lastName: personalInfo.lastName,
            maritalStatus: this.getMaritalStatusDescription(personalInfo.maritalLocal),
            maskedDob: "",
            maskedSSN: "",
            middleInitial: personalInfo.middleInitial,
            prefix: personalInfo?.prefixLocal !="" ? personalInfo.prefixLocal : personalInfo.prefix,
            ssn: await encrypt(`${ssnDataInfo.ssnOne}${ssnDataInfo.ssnTwo}${ssnDataInfo.ssnThree}`),
            suffix: personalInfo.suffix,
            relationship: relationshipInfo,
            taxIdIssuingCountry: personalInfo.taxID,
            countryOfCitizenship: personalInfo.countryOfCitizenship.length <= 3 ? this.getCountryCode(personalInfo.countryOfCitizenship, 'value') : personalInfo.countryOfCitizenship,
            countryOfCitizenshipCode: personalInfo.countryOfCitizenship.length > 3 ? this.getCountryCode(personalInfo.countryOfCitizenship, 'key') : personalInfo.countryOfCitizenship,
            isResidentAlien: personalInfo.resident.toLowerCase() == 'resident alien' ? true : false,
            residentStatus: personalInfo.resident,
            residentAlienInformation:{}
        }


          const residentInfo  = {
            residentAlienInformation: {
                permanentResidentCardNumber: personalInfo.residentCardNumber,
                permanentResidentExpirationDate: personalInfo.ownerResidentExpiry,
                passportNumber: personalInfo.passportNumber,
                passportExpirationDate: personalInfo.ownerPassportExpiry,
            }
        }

        if(personalInfo.resident.toLowerCase() == 'resident alien'){
            personalInfoJointObj = {...personalInfoJointObj, ...residentInfo}
        }
        
        let perDataInfoNew = {
            ...this.props.marketLocalStateData?.marketNAOInfo?.personalInfo?.primary
        };
        
        delete  perDataInfoNew?.withoutMaskedSSN;
        if(role != 'Member'){
            perDataInfoNew.maskedDob = "" 
            
        }

        const payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                personalInfo: {
                primary: perDataInfoNew,
                joint:personalInfoJointObj
            }
        }
        await this.props.postMarketNAOInfo({
            url: 'personalInfo',
            payloadData: payload
        });

       
        personalInfoJointObj['withoutMaskedSSN'] = `${ssnDataInfo.ssnOne}${ssnDataInfo.ssnTwo}${ssnDataInfo.ssnThree}`;
        personalInfoJointObj.maskedDob = personalInfo.ownerDateOfBirth;
        personalInfoJointObj.relationship = await personalInfo.relationship;
        const saveToLocalpayload = {
            sectionToBeUpdated:'personalInfo',
            state: personalInfoJointObj,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    }

    createPersonalInfoPayloadAndSave = async () =>
    {
        const personalInfo = this.primaryOwnerPerCardRef.current.state;
        const ssnDataInfo = this.primaryOwnerPerCardRef.current.ssnRef.current.state;
        
        const {updateMarketNAOInfo,masterLookupStateData,marketLocalStateData,initialState} = this.props;
        const role = getRole(initialState && initialState?.getCustomerProfile)
        let personalInfoPrimaryObj = {
            citizenship:personalInfo.citizenship,
            dob: role == 'Member' ? "" : personalInfo?.ownerDateOfBirth && personalInfo.ownerDateOfBirth.includes('XX') ?
                                        "" : await encrypt(personalInfo.ownerDateOfBirth ),

            firstName: personalInfo.firstName,
            gender: personalInfo.gender,
            lastName: personalInfo.lastName,
            maritalStatus:this.getMaritalStatusDescription(personalInfo.maritalLocal),
            maskedDob: role == 'Member' ? personalInfo.ownerDateOfBirth : personalInfo?.ownerDateOfBirth && 
            personalInfo.ownerDateOfBirth.includes('XX') ? personalInfo.ownerDateOfBirth : "",

            maskedSSN: role == 'Member' ? ssnDataInfo.maskedFullSSN : ssnDataInfo?.maskedFullSSN && 
                                        ssnDataInfo.ssnOne.includes('XX') ? ssnDataInfo.maskedFullSSN : "",

            middleInitial: personalInfo.middleInitial,
            prefix: personalInfo?.prefixLocal !="" ? personalInfo.prefixLocal : personalInfo.prefix,
            ssn: role == 'Member' ? "" : ssnDataInfo?.ssnOne && ssnDataInfo.ssnOne.includes('XX') ? "" :
                                         await encrypt(`${ssnDataInfo.ssnOne}${ssnDataInfo.ssnTwo}${ssnDataInfo.ssnThree}`) ,
            suffix: personalInfo.suffix,
            taxIdIssuingCountry: personalInfo.taxID,
            countryOfCitizenship: personalInfo.countryOfCitizenship.length <= 3 ? this.getCountryCode(personalInfo.countryOfCitizenship, 'value') : personalInfo.countryOfCitizenship,
            countryOfCitizenshipCode: personalInfo.countryOfCitizenship.length > 3 ? this.getCountryCode(personalInfo.countryOfCitizenship, 'key') : personalInfo.countryOfCitizenship,
            isResidentAlien: personalInfo.resident.toLowerCase() == 'resident alien' ? true : false,
            residentStatus: personalInfo.resident,
            residentAlienInformation:{}
        }

        const residentInfo  = {
            residentAlienInformation: {
                permanentResidentCardNumber: personalInfo.residentCardNumber,
                permanentResidentExpirationDate: personalInfo.ownerResidentExpiry,
                passportNumber: personalInfo.passportNumber,
                passportExpirationDate: personalInfo.ownerPassportExpiry,
            }
        }

        if(personalInfo.resident.toLowerCase() == 'resident alien'){
            personalInfoPrimaryObj = {...personalInfoPrimaryObj, ...residentInfo}
        }


        let payload={}
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if(
            (this.props.verifyPersonalHider || this.props.verifyContactHider || this.props.verifyPhoneHider || 
            this.props.verifyEmailHider || this.props.verifyJointPersonalHider || this.props.verifyJointContactHider || 
            this.props.verifyJointPhoneHider || this.props.verifyJointEmailHider || this.props.verifyEmpHider || this.props.verifyFinHider
             || this.props.verifyMilHider ) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
            let secondryObj = await {...this.props.marketLocalStateData?.marketNAOInfo?.personalInfo?.secondary};
            delete secondryObj.withoutMaskedSSN;
            secondryObj.maskedDob = "";
            secondryObj['relationship'] = getValue(secondryObj.relationship,masterLookupStateData?.relationship?.value)
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                personalInfo: {
                primary:personalInfoPrimaryObj,
                joint:secondryObj
            }
             }
        }
        else
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                personalInfo: {
                primary:personalInfoPrimaryObj
            }
        }
        }
        
        await this.props.postMarketNAOInfo({
            url: 'personalInfo',
            payloadData: payload
        });

       
        personalInfoPrimaryObj['withoutMaskedSSN'] = `${ssnDataInfo.ssnOne}${ssnDataInfo.ssnTwo}${ssnDataInfo.ssnThree}`;
        personalInfoPrimaryObj.maskedDob = personalInfo.ownerDateOfBirth;
        const saveToLocalpayload = {
            sectionToBeUpdated:'personalInfo',
            state: personalInfoPrimaryObj,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
        
    }

    createAddressInfoPayloadAndSaveForJoint  = () =>
    {
        const mailingAddressObj =  this.jointOwnerPerCardRef.current?.perMailingAddressPref?.current?.state;
        const physicalAddressObj =  this.jointOwnerPerCardRef.current.perPhysicalAddressPref.current.state;

        const {updateMarketNAOInfo} = this.props;
        let addressInfo = {
            mailingAddress: (this.state.sameAddressInfoData[1].primaryIsPhysicalAddressSame == "Y" || this.state.sameAddressInfoData[1].primaryIsPhysicalAddressSame == "Yes") ? {
                addressLine1: physicalAddressObj.lineOne,
                addressLine2: physicalAddressObj.lineTwo,
                city: physicalAddressObj.city,
                mailingAddressType: physicalAddressObj.mailTypeRadio,
                state: physicalAddressObj.state,
                zipCode: physicalAddressObj.zipCode

            }:{
                addressLine1: mailingAddressObj?.lineOne,
                addressLine2: mailingAddressObj?.lineTwo,
                city: mailingAddressObj?.city,
                mailingAddressType: mailingAddressObj?.mailTypeRadio,
                state:  mailingAddressObj?.state,
                zipCode: mailingAddressObj?.zipCode,
            },
            physicalAddress:{
                addressLine1: physicalAddressObj.lineOne,
                addressLine2: physicalAddressObj.lineTwo,
                city: physicalAddressObj.city,
                mailingAddressType: physicalAddressObj.mailTypeRadio,
                state:  physicalAddressObj.state,
                zipCode: physicalAddressObj.zipCode
            },

            isPhysicalAddressSame: this.state.sameAddressInfoData[1].primaryIsPhysicalAddressSame
            
        }

        const payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                address: {
                 primary:this.props.marketLocalStateData.marketNAOInfo.addressInfo.primary,
                 joint:addressInfo
            }
        }
        this.props.postMarketNAOInfo({
            url: 'address',
            payloadData: payload
        });

        addressInfo.mailingAddress['isMailingAddress'] = true;
        addressInfo.mailingAddress['isPhysicalAddress'] = false;
        addressInfo.physicalAddress['isMailingAddress'] = false;
        addressInfo.physicalAddress['isPhysicalAddress'] = true;
        const saveToLocalpayload = {
            sectionToBeUpdated:'addressInfo',
            state: addressInfo,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
        
    }

    createAddressInfoPayloadAndSave = () =>
    {
        const mailingAddressObj = this.primaryOwnerPerCardRef.current.perMailingAddressPref?.current?.state;
        const physicalAddressObj = this.primaryOwnerPerCardRef.current.perPhysicalAddressPref?.current?.state;
        const {updateMarketNAOInfo,marketLocalStateData} = this.props;
        let addressInfo = {
            mailingAddress: (this.state.sameAddressInfoData[0].primaryIsPhysicalAddressSame == "Y" || this.state.sameAddressInfoData[0].primaryIsPhysicalAddressSame == "Yes") ? {
                addressLine1: physicalAddressObj.lineOne,
                addressLine2: physicalAddressObj.lineTwo,
                city: physicalAddressObj.city,
                mailingAddressType: physicalAddressObj.mailTypeRadio,
                state: physicalAddressObj.state,
                zipCode: physicalAddressObj.zipCode

            }:{
                addressLine1: mailingAddressObj.lineOne,
                addressLine2: mailingAddressObj.lineTwo,
                city: mailingAddressObj.city,
                mailingAddressType: mailingAddressObj.mailTypeRadio,
                state:  mailingAddressObj.state,
                zipCode: mailingAddressObj.zipCode,
            },
            physicalAddress:{
                addressLine1: physicalAddressObj.lineOne,
                addressLine2: physicalAddressObj.lineTwo,
                city: physicalAddressObj.city,
                mailingAddressType: physicalAddressObj.mailTypeRadio,
                state:  physicalAddressObj.state,
                zipCode: physicalAddressObj.zipCode
            },

            isPhysicalAddressSame: this.state.sameAddressInfoData[0].primaryIsPhysicalAddressSame
            
        }

        let payload={}
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if((this.props.verifyPersonalHider || this.props.verifyContactHider || this.props.verifyPhoneHider || this.props.verifyEmailHider ) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
          payload = {
              applicationId: this.props.marketLocalStateData.applicationId,
              pageNumber: 2,
              source: "web",
              address: {
              primary:addressInfo,
              joint:this.props.marketLocalStateData?.marketNAOInfo?.addressInfo?.secondary
              }
          }
        }
        else
        {
          payload = {
              applicationId: this.props.marketLocalStateData.applicationId,
              pageNumber: 2,
              source: "web",
              address: {
              primary:addressInfo
                }
            }
          
        }

        this.props.postMarketNAOInfo({
        url: 'address',
        payloadData: payload
        });

        addressInfo.mailingAddress['isMailingAddress'] = true;
        addressInfo.mailingAddress['isPhysicalAddress'] = false;
        addressInfo.physicalAddress['isMailingAddress'] = false;
        addressInfo.physicalAddress['isPhysicalAddress'] = true;
        const saveToLocalpayload = {
            sectionToBeUpdated:'addressInfo',
            state: addressInfo,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
        
    }

    maskEmail = email => {
        return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
            for (let i = 0; i < gp3.length; i+=1) {
                gp2 += 'x';
            }
            return gp2;
        });
    };

    createContactInfoPayloadAndSaveForJoint = async () =>
    {
        const phoneInfo = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.perContactDetailsPref.current.sendDataToParent();
        const emailInfo = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.perContactDetailsPref.current.sendEmailDataToParent();
        const {updateMarketNAOInfo} = this.props;
        const contactInfo = {
            emailAddresses: {
                maskedPrimaryEmailAddress: "",
                maskedSecondaryEmailAddress: "",
                primaryEmailAddress:  emailInfo.primaryEmailDetails.email,
                secondaryEmailAddress: emailInfo.secondaryEmailDetails.email
            },
            phoneInfo:{
                primary:{
                    countryCode: phoneInfo.primaryPhoneDetails.countryCode,
                    isMobile: phoneInfo.primaryPhoneDetails.isMobile,
                    number:  typeof(phoneInfo.primaryPhoneDetails.phoneNumber) == 'string' &&  phoneInfo.primaryPhoneDetails.phoneNumber != "" && !(phoneInfo.primaryPhoneDetails.phoneNumber.includes('XXX')) ? phoneInfo.primaryPhoneDetails.phoneNumber : '',
                    mPhoneNumber:phoneInfo.primaryPhoneDetails.mPhoneNumber,
                },
                secondary:{
                    countryCode: phoneInfo.secondaryPhoneDetails.countryCode,
                    isMobile: phoneInfo.secondaryPhoneDetails.isMobile,
                    number: typeof(phoneInfo.secondaryPhoneDetails.phoneNumber) == 'string' && phoneInfo.secondaryPhoneDetails.phoneNumber != "" && !(phoneInfo.secondaryPhoneDetails.phoneNumber.includes('XXX')) ? phoneInfo.secondaryPhoneDetails.phoneNumber : '',
                    mPhoneNumber: phoneInfo.secondaryPhoneDetails.mPhoneNumber
                }
            }

        }

        const localStoragePrimaryData = this.props.marketLocalStateData.marketNAOInfo.contactInfo.primary;

        const primaryContactInfo =  {
            emailAddresses: {
                maskedPrimaryEmailAddress: localStoragePrimaryData.emailAddresses.maskedPrimaryEmailAddress,
                maskedSecondaryEmailAddress: localStoragePrimaryData.emailAddresses.maskedSecondaryEmailAddress,
                primaryEmailAddress:  localStoragePrimaryData.emailAddresses.primaryEmailAddress,
                secondaryEmailAddress:  localStoragePrimaryData.emailAddresses.secondaryEmailAddress
            },
            phoneInfo:localStoragePrimaryData.phoneInfo
        }

        const payload = {
            applicationId: this.props.marketLocalStateData.applicationId,
            pageNumber: 2,
            source: "web",
            contactInfo: {
            primary:primaryContactInfo,
            joint:contactInfo
            }
        }
        await this.props.postMarketNAOInfo({
            url: 'contactInfo',
            payloadData: payload
        });

       
        contactInfo.emailAddresses.primaryEmailAddress = emailInfo.primaryEmailDetails.email;
        contactInfo.emailAddresses.secondaryEmailAddress = emailInfo.secondaryEmailDetails.email;
        contactInfo.phoneInfo.primary.number = phoneInfo.primaryPhoneDetails.phoneNumber;
        contactInfo.phoneInfo.secondary.number = phoneInfo.secondaryPhoneDetails.phoneNumber;

        const saveToLocalpayload = {
            sectionToBeUpdated:'contactInfo',
            state: contactInfo,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    }

    

    createContactInfoPayloadAndSave = async () =>
    {
        const phoneInfo = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.perContactDetailsPref.current.sendDataToParent();
        const emailInfo = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.perContactDetailsPref.current.sendEmailDataToParent();

        const profileInfoData = this.props?.profileInformationData?.memberProfileInformation?.emailInformation;
       
        const {updateMarketNAOInfo,marketLocalStateData} = this.props;
    

        const contactInfo = {
            emailAddresses: {
                primaryEmailAddress:  emailInfo?.primaryEmailDetails?.email ?  
                    profileInfoData?.m_primaryEmail == emailInfo?.primaryEmailDetails?.email ? '' : emailInfo?.primaryEmailDetails?.email :"",
                secondaryEmailAddress: emailInfo?.secondaryEmailDetails?.email ?  
                    profileInfoData?.m_secondaryEmail == emailInfo?.secondaryEmailDetails?.email ? '' : emailInfo?.secondaryEmailDetails?.email : "",
                maskedPrimaryEmailAddress:  (emailInfo?.primaryEmailDetails?.email &&
                    profileInfoData?.m_primaryEmail == emailInfo?.primaryEmailDetails?.email) ? emailInfo?.primaryEmailDetails?.email : '' ,
                maskedSecondaryEmailAddress: (emailInfo?.secondaryEmailDetails?.email &&  
                    profileInfoData?.m_secondaryEmail == emailInfo?.secondaryEmailDetails?.email) ? emailInfo?.secondaryEmailDetails?.email : '',
            },

            phoneInfo:{
                primary:{
                    countryCode: phoneInfo.primaryPhoneDetails.countryCode,
                    isMobile: phoneInfo.primaryPhoneDetails.isMobile,
                    number: typeof(phoneInfo?.primaryPhoneDetails?.phoneNumber) == 'string' && 
                            phoneInfo.primaryPhoneDetails.phoneNumber != "" && 
                            !(phoneInfo.primaryPhoneDetails.phoneNumber.includes('XXX'))  ? phoneInfo.primaryPhoneDetails.phoneNumber : '',
                    mPhoneNumber: phoneInfo.primaryPhoneDetails.phoneNumber == "" ? phoneInfo.primaryPhoneDetails.mPhoneNumber: "",
                },
                secondary:{
                    countryCode: phoneInfo.secondaryPhoneDetails.countryCode,
                    isMobile: phoneInfo.secondaryPhoneDetails.isMobile,
                    number: typeof(phoneInfo?.secondaryPhoneDetails?.phoneNumber) == 'string' && phoneInfo.secondaryPhoneDetails.phoneNumber != "" && !(phoneInfo.secondaryPhoneDetails.phoneNumber.includes('XXX'))  ? phoneInfo.secondaryPhoneDetails.phoneNumber : '',
                    mPhoneNumber: phoneInfo.secondaryPhoneDetails.phoneNumber == "" ?  phoneInfo.secondaryPhoneDetails.mPhoneNumber : ""
                }
            }

        }

        let payload ={};
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if((this.props.verifyPersonalHider == true || this.props.verifyContactHider == true || this.props.verifyPhoneHider == true || this.props.verifyEmailHider == true) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                contactInfo: {
                primary:contactInfo,
                joint:this.props.marketLocalStateData.marketNAOInfo.contactInfo.secondary
                }
            }
        }
        else
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                contactInfo: {
                primary:contactInfo
                }
        }
        }

        await this.props.postMarketNAOInfo({
            url: 'contactInfo',
            payloadData: payload
        });

        contactInfo.phoneInfo.primary.number = phoneInfo.primaryPhoneDetails.phoneNumber;
        contactInfo.phoneInfo.secondary.number = phoneInfo.secondaryPhoneDetails.phoneNumber;

        const saveToLocalpayload = {
            sectionToBeUpdated:'contactInfo',
            state: contactInfo,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    }

    createEmploymentPayloadAndSave = async ()=>
    {

        const {updateMarketNAOInfo,masterLookupStateData,marketLocalStateData} = this.props;
        const empInfo  = this.primaryOwnerEmpCardRef.current && this.primaryOwnerEmpCardRef.current.sendDataToParent();
        let empStatus = "";
        const notEmployeeArray = ['retired', 'homemaker', 'student', 'unemployed'];
        const addressInfo = (empInfo?.empAddress !=null && empInfo?.empAddress !=undefined && empInfo?.empAddress !="" ) ? empInfo?.empAddress : empInfo?.employerAddress;
        let zipCode = '';
        if(addressInfo?.zipCode != undefined)
        {
            zipCode = addressInfo?.zipCode
        }
        else if(addressInfo?.zip != undefined)
        {
            zipCode = addressInfo?.zip;
        }
        let empInfoData = {
                address:{
                    addressLine1: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.addressLine1,
                    addressLine2: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.addressLine2,
                    city: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.city,
                    state:(addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.state,
                    zipCode: (addressInfo ==  undefined || addressInfo == null) ? "" : zipCode
                },
                employerName: notEmployeeArray.includes(empInfo.empStatus) == false ? empInfo.employersName:"",
                industry: notEmployeeArray.includes(empInfo.empStatus) == false ? getValue(empInfo?.industry,masterLookupStateData?.industry?.value) : "",
                industryDescription: empInfo.industryDescription,
                occupation: empInfo.occupation,
                otherEmployment: empStatus.toLowerCase() === 'other' ? empInfo?.empClassInfo : empInfo.empStatusOther,
                primarySource: getValue(empInfo?.primarySourceofIncome,masterLookupStateData?.prim_src_income?.value),
                employmentClassName:empInfo?.empClassInfo,
                status: getValue(empInfo?.empStatus,masterLookupStateData?.brkg_employment_status?.value)
        }
     
        let payload={};
        
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if((this.props.verifyEmpHider || this.props.verifyMilHider  || this.props.verifyFinHider) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {

            const employmentInfoJointData = this.props.marketLocalStateData?.marketNAOInfo?.employmentInfo?.secondary;
            const employmentInfoForJointObj = {
                address:{
                    addressLine1: (employmentInfoJointData.address ==  undefined || employmentInfoJointData.address == null) ? "" : employmentInfoJointData.address?.addressLine1,
                    addressLine2: (employmentInfoJointData.address ==  undefined || employmentInfoJointData.address == null) ? "" : employmentInfoJointData.address?.addressLine2,
                    city: (employmentInfoJointData.address ==  undefined || employmentInfoJointData.address == null) ? "" : employmentInfoJointData.address?.city,
                    state:(employmentInfoJointData.address ==  undefined || employmentInfoJointData.address == null) ? "" : employmentInfoJointData.address?.state,
                    zipCode: (employmentInfoJointData.address ==  undefined || employmentInfoJointData.address == null) ? "" : employmentInfoJointData.address?.zipCode
                },
                employerName:employmentInfoJointData.employerName,
                industry: getValue(employmentInfoJointData?.industry,masterLookupStateData?.industry?.value),
                industryDescription: employmentInfoJointData.industryDescription,
                occupation: employmentInfoJointData.occupation,
                otherEmployment: getValue(employmentInfoJointData?.status,masterLookupStateData?.brkg_employment_status?.value)?.toLowerCase() === 'other' ? employmentInfoJointData?.empClassInfo : employmentInfoJointData.empStatusOther,
                primarySource: getValue(employmentInfoJointData?.primarySource,masterLookupStateData?.prim_src_income?.value),
                employmentClassName:employmentInfoJointData?.employmentClassName,
                status: getValue(employmentInfoJointData?.status,masterLookupStateData?.brkg_employment_status?.value)
        }

            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                employmentInfo: {
                primary:empInfoData,
                joint:employmentInfoForJointObj
                }
            }
        }
        else
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                employmentInfo: {
                primary:empInfoData
                }
            }
        }
       await this.props.postMarketNAOInfo({
            url: 'employmentInfo',
            payloadData: payload
        });

        empInfoData.status = empInfo.empStatus;
        empInfoData.primarySource = empInfo.primarySourceofIncome;
        empInfoData.industry = empInfo.industry;

        const saveToLocalpayload = {
            sectionToBeUpdated:'employmentInfo',
            state: empInfoData,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    
    }

    createEmploymentPayloadAndSaveForJoint = async ()=>
    {
        const {updateMarketNAOInfo,masterLookupStateData} = this.props;
        const empInfo  = this.primaryOwnerEmpCardForJointRef.current && this.primaryOwnerEmpCardForJointRef.current.sendDataToParent();
        let primaryInfoData = this.props.marketLocalStateData.marketNAOInfo.employmentInfo.primary;

        let empStatus = "";
        let industryInfo = "";
        let primarySourceIncome = "";

        let empStatusPri = "";
        let industryInfoPri = "";
        let primarySourceIncomePri = "";
        for (let i = 0; i < masterLookupStateData?.brkg_employment_status?.value?.length; i++) {
            if (masterLookupStateData?.brkg_employment_status?.value[i].key == empInfo.empStatus) {
                empStatus = masterLookupStateData?.brkg_employment_status?.value[i].value;
            }
        }

        for (let i = 0; i < masterLookupStateData?.industry?.value?.length; i++) {
            if (masterLookupStateData?.industry?.value[i].key ==empInfo.industry) {
              industryInfo = masterLookupStateData?.industry?.value[i].value;
            }
        }

          for (let i = 0; i < masterLookupStateData?.prim_src_income?.value?.length; i++) {
            if (masterLookupStateData?.prim_src_income?.value[i].key == empInfo.primarySourceofIncome) {
                primarySourceIncome = masterLookupStateData?.prim_src_income?.value[i].value;
            }
          }

        // Primary info

         for (let i = 0; i < masterLookupStateData?.brkg_employment_status?.value?.length; i++) {
            if (masterLookupStateData?.brkg_employment_status?.value[i].key == primaryInfoData.status) {
                empStatusPri = masterLookupStateData?.brkg_employment_status?.value[i].value;
            }
        }

        for (let i = 0; i < masterLookupStateData?.industry?.value?.length; i++) {
            if (masterLookupStateData?.industry?.value[i].key ==primaryInfoData.industry) {
                industryInfoPri = masterLookupStateData?.industry?.value[i].value;
            }
        }

          for (let i = 0; i < masterLookupStateData?.prim_src_income?.value?.length; i++) {
            if (masterLookupStateData?.prim_src_income?.value[i].key == primaryInfoData.primarySource) {
                primarySourceIncomePri = masterLookupStateData?.prim_src_income?.value[i].value;
            }
          }



          const empInfoPrimaryData = {
                    status: empStatusPri,
                    otherEmployment: primaryInfoData.otherEmployment,
                    industry: industryInfoPri,
                    industryDescription: primaryInfoData.industryDescription,
                    primarySource: primarySourceIncomePri,
                    occupation: primaryInfoData.occupation,
                    employerName: primaryInfoData.employerName,
                    employmentClassName: primaryInfoData.employmentClassName,
                    address: {
                        addressLine1: primaryInfoData.address.addressLine1,
                        addressLine2: primaryInfoData.address.addressLine2,
                        city: primaryInfoData.address.city,
                        state: primaryInfoData.address.state,
                        zipCode: primaryInfoData.address.zipCode
                    },
          }
        
        const addressInfo = (empInfo?.empAddress !=null && empInfo?.empAddress !=undefined && empInfo?.empAddress !="" ) ? empInfo?.empAddress : empInfo?.employerAddress;

        let zipCode = '';
        if(addressInfo?.zipCode != undefined)
        {
            zipCode = addressInfo?.zipCode
        }
        else if(addressInfo?.zip != undefined)
        {
            zipCode = addressInfo?.zip;
        }

        let empInfoData = {
                address:{
                    addressLine1: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.addressLine1,
                    addressLine2: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.addressLine2,
                    city: (addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.city,
                    state:(addressInfo ==  undefined || addressInfo == null) ? "" : addressInfo.state,
                    zipCode: (addressInfo ==  undefined || addressInfo == null) ? "" : zipCode
                },
                employerName:empInfo.employersName,
                industry: industryInfo,
                industryDescription: empInfo.industryDescription,
                occupation: empInfo.occupation,
                otherEmployment: empStatus.toLowerCase() === 'other' ? empInfo?.empClassInfo : empInfo.empStatusOther,
                primarySource:primarySourceIncome,
                employmentClassName:empInfo?.empClassInfo,
                status: empStatus
        }
        const payload = {
            applicationId: this.props.marketLocalStateData.applicationId,
            pageNumber: 2,
            source: "web",
            employmentInfo: {
            primary: empInfoPrimaryData,
            joint:empInfoData
            }
        }
        await  this.props.postMarketNAOInfo({
            url: 'employmentInfo',
            payloadData: payload
        });

        empInfoData.status = await empInfo.empStatus;
        empInfoData.primarySource =await  empInfo.primarySourceofIncome;
        empInfoData.industry = await empInfo.industry;

        const saveToLocalpayload = {
            sectionToBeUpdated:'employmentInfo',
            state: empInfoData,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        updateMarketNAOInfo(saveToLocalpayload);
    }

    createFinancialPayloadAndSave = async()=>
    {
        const {updateMarketNAOInfo,masterLookupStateData,marketLocalStateData} = this.props;
        const finInfo = this.primaryOwnerFinCardRef.current && this.primaryOwnerFinCardRef.current.sendDataToParent();
   
    
        let finInfoData = {
            annualIncome: getValue(finInfo?.annualIncome,masterLookupStateData?.brkg_annual_income?.value),
            netWorth:  getValue(finInfo?.netWorth,masterLookupStateData?.brkg_net_worth?.value),
            taxBracket: finInfo.taxBracket,
            taxFilingStatus: getValue(finInfo?.taxFilingStatus,masterLookupStateData?.tax_filling_status?.value)
        }

        let taxBracketPrimary = finInfo?.taxBracket;
        
        let finInfoDataForApiPayload = {
            annualIncome: getValue(finInfo?.annualIncome,masterLookupStateData?.brkg_annual_income?.value),
            netWorth:  getValue(finInfo?.netWorth,masterLookupStateData?.brkg_net_worth?.value),
            taxBracket: (taxBracketPrimary !=undefined && taxBracketPrimary !=null && taxBracketPrimary !=0 && taxBracketPrimary !="" ) ? taxBracketPrimary?.replace('%','') : "",
            taxFilingStatus: getValue(finInfo?.taxFilingStatus,masterLookupStateData?.tax_filling_status?.value)
        }

        let payload={};
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if((this.props.verifyFinHider || this.props.verifyMilHider || this.props.verifyEmpHider) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
            let finInfoDataForSecondary = {
                annualIncome: getValue(this.props.marketLocalStateData?.marketNAOInfo?.financialInfo?.secondary?.annualIncome,masterLookupStateData?.brkg_annual_income?.value),
                netWorth: getValue(this.props.marketLocalStateData?.marketNAOInfo?.financialInfo?.secondary?.netWorth,masterLookupStateData?.brkg_net_worth?.value),
                taxBracket: (taxBracketPrimary !=undefined && taxBracketPrimary !=null && taxBracketPrimary !=0 && taxBracketPrimary !="" ) ? taxBracketPrimary?.replace('%','') : "",
                taxFilingStatus: getValue(this.props.marketLocalStateData?.marketNAOInfo?.financialInfo?.secondary?.taxFilingStatus,masterLookupStateData?.tax_filling_status?.value)
            }
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                financialInfo: {
                primary:finInfoDataForApiPayload,
                joint:finInfoDataForSecondary
                }
            }
        }
        else
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                financialInfo: {
                primary:finInfoDataForApiPayload
                }
            }
        }

       await this.props.postMarketNAOInfo({
            url: 'financialInfo',
            payloadData: payload
        });

        finInfoData.netWorth = await finInfo.netWorth;
        finInfoData.taxFilingStatus = await finInfo.taxFilingStatus;
        finInfoData.annualIncome = await  finInfo.annualIncome;

        const saveToLocalpayload = {
            sectionToBeUpdated:'financialInfo',
            state: finInfoData,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);

    }

    createFinancialPayloadAndSaveForJoint = async()=>
    {
        const {updateMarketNAOInfo,masterLookupStateData} = this.props;
        const finInfo = this.primaryOwnerFinCardForJointRef.current && this.primaryOwnerFinCardForJointRef.current.sendDataToParent();
        let finPrimaryInfo = this.props.marketLocalStateData.marketNAOInfo.financialInfo.primary;
        let newWorthInfo = '';
        let taxFilingStatusInfo = '';
        let annualIncomeInfo = "";

        let newWorthInfoPri = '';
        let taxFilingStatusInfoPri = '';
        let annualIncomeInfoPri = "";

        for (let i = 0; i < masterLookupStateData?.brkg_annual_income?.value?.length; i++) {
            if (masterLookupStateData?.brkg_annual_income?.value[i].key == finInfo?.annualIncome) {
                annualIncomeInfo = masterLookupStateData?.brkg_annual_income?.value[i].value;
            }
          }

        for (let i = 0; i < masterLookupStateData?.brkg_net_worth?.value?.length; i++) {
            if (masterLookupStateData?.brkg_net_worth?.value[i].key == finInfo.netWorth) {
                newWorthInfo = masterLookupStateData?.brkg_net_worth?.value[i].value;
            }
          }
        
        for (let i = 0; i < masterLookupStateData?.tax_filling_status?.value?.length; i++) {
        if (masterLookupStateData?.tax_filling_status?.value[i].key == finInfo.taxFilingStatus) {
            taxFilingStatusInfo = masterLookupStateData?.tax_filling_status?.value[i].value;
        }
        }

          // primary info

          for (let i = 0; i < masterLookupStateData?.brkg_net_worth?.value?.length; i++) {
            if (masterLookupStateData?.brkg_net_worth?.value[i].key == finPrimaryInfo.netWorth) {
                newWorthInfoPri = masterLookupStateData?.brkg_net_worth?.value[i].value;
            }
          }
        
        for (let i = 0; i < masterLookupStateData?.tax_filling_status?.value?.length; i++) {
        if (masterLookupStateData?.tax_filling_status?.value[i].key == finPrimaryInfo.taxFilingStatus) {
            taxFilingStatusInfoPri = masterLookupStateData?.tax_filling_status?.value[i].value;
        }
        }

        for (let i = 0; i < masterLookupStateData?.brkg_annual_income?.value?.length; i++) {
            if (masterLookupStateData?.brkg_annual_income?.value[i].key == finPrimaryInfo.annualIncome) {
                annualIncomeInfoPri = masterLookupStateData?.brkg_annual_income?.value[i].value;
            }
          }

       

        let taxBracketForPrimary = finPrimaryInfo.taxBracket;
        const financialInfoDataPrimaryApiPayload = {
            annualIncome: annualIncomeInfoPri,
            taxBracket: taxBracketForPrimary !=null ? taxBracketForPrimary?.replace('%',''):"",
            netWorth: newWorthInfoPri,
            taxFilingStatus: taxFilingStatusInfoPri
        }

    

        let finInfoData = {
            annualIncome:  annualIncomeInfo,
            netWorth: newWorthInfo,
            taxBracket:  finInfo.taxBracket,
            taxFilingStatus:  taxFilingStatusInfo
        }

        let taxBracketForJoint = `${finInfo.taxBracket}`;
        let finInfoDataApiPayload = {
            annualIncome:  annualIncomeInfo,
            netWorth: newWorthInfo,
            taxBracket:  taxBracketForJoint !="0" ? taxBracketForJoint?.replace('%',''):"",
            taxFilingStatus:  taxFilingStatusInfo
        }

        const payload = {
            applicationId: this.props.marketLocalStateData.applicationId,
            pageNumber: 2,
            source: "web",
            financialInfo: {
            primary: financialInfoDataPrimaryApiPayload,
            joint:finInfoDataApiPayload
            }
        }
        await this.props.postMarketNAOInfo({
            url: 'financialInfo',
            payloadData: payload
        });

        finInfoData.netWorth = await  finInfo.netWorth;
        finInfoData.taxFilingStatus =await finInfo.taxFilingStatus;
        finInfoData.annualIncome =await finInfo.annualIncome;
        const saveToLocalpayload = {
            sectionToBeUpdated:'financialInfo',
            state: finInfoData,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        
        updateMarketNAOInfo(saveToLocalpayload);

    }


    createMilitaryPayloadAndSave = async () =>
    {
        const MilInfo = this.primaryOwnerMilCardRef.current && this.primaryOwnerMilCardRef.current.sendDataToParent();
        const {updateMarketNAOInfo,masterLookupStateData,marketLocalStateData} = this.props;
    
        let milInfoData = {
            branch: MilInfo.currServingUSM == "Y" ?  getValue(MilInfo.branchOfService,masterLookupStateData?.mil_serv_branch?.value):"",
            commissionSource: MilInfo.currServingUSM == "Y" ?  MilInfo.commissionCourse : "",
            startDate: MilInfo.currServingUSM == "Y" ? MilInfo.serviceFromDate !="" ?
            moment(MilInfo.serviceFromDate).format(dateFormatText) :"" : "",
            endDate:  MilInfo.currServingUSM == "Y" ? MilInfo.serviceToDate !="" ?
             moment(MilInfo.serviceToDate).format(dateFormatText)  : "" : "",
            hasServed: MilInfo.currServingUSM == "Y" ? true : false,
            militaryStatus: MilInfo.currServingUSM == "Y" ? getValue(MilInfo.milityStatus, masterLookupStateData?.mil_status?.value) : "",
            rank: MilInfo.currServingUSM == "Y" ? getRankValueBasedOnKey(MilInfo.rank,masterLookupStateData)  : "",
            otherBranch:"",
            otherMilitaryStatus:""
        }


        let payload={};
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        if((this.props.verifyEmpHider || this.props.verifyFinHider || this.props.verifyMilHider) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
            const militaryInfoSecondaryObj = this.props.marketLocalStateData?.marketNAOInfo?.militaryInfo?.secondary;
            let milInfoDataSecondaryPayload = {
                branch: (militaryInfoSecondaryObj.hasServed == "Y") ? await getValue(militaryInfoSecondaryObj?.branch,masterLookupStateData?.mil_serv_branch?.value) : "",
                commissionSource: (militaryInfoSecondaryObj.hasServed == "Y") ? await militaryInfoSecondaryObj.commissionSource:"",
                startDate: (militaryInfoSecondaryObj.hasServed == "Y") ?  await militaryInfoSecondaryObj.startDate : "",
                endDate: (militaryInfoSecondaryObj.hasServed == "Y") ? await  militaryInfoSecondaryObj.endDate :"" ,
                hasServed: await (militaryInfoSecondaryObj.hasServed == "Y") ? true : false,
                militaryStatus: (militaryInfoSecondaryObj.hasServed == "Y") ? await getValue(militaryInfoSecondaryObj?.militaryStatus, masterLookupStateData?.mil_status?.value) : "",
                rank: (militaryInfoSecondaryObj.hasServed == "Y") ? await getRankValueBasedOnKey(militaryInfoSecondaryObj?.rank,masterLookupStateData):"" ,
                otherBranch:"",
                otherMilitaryStatus:""
            }

            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                militaryInfo: {
                primary:milInfoData,
                joint:milInfoDataSecondaryPayload
                }
            }
        }
        else
        {
            payload = {
                applicationId: this.props.marketLocalStateData.applicationId,
                pageNumber: 2,
                source: "web",
                militaryInfo: {
                primary:milInfoData
                }
            }
        }

       await  this.props.postMarketNAOInfo({
            url: 'militaryInfo',
            payloadData: payload
        });

        milInfoData.branch =  MilInfo.currServingUSM == "Y" ?  MilInfo.branchOfService :"";
        milInfoData.militaryStatus = MilInfo.currServingUSM == "Y" ? MilInfo.milityStatus : "";
        milInfoData.hasServed = MilInfo.currServingUSM;
        milInfoData.rank = MilInfo.currServingUSM == "Y" ? MilInfo.rank : "";
        const saveToLocalpayload = {
            sectionToBeUpdated:'militaryInfo',
            state: milInfoData,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    }

    
    createMilitaryPayloadAndSaveForJoint = async () =>
    {
        const MilInfo = this.primaryOwnerMilCardForJointRef.current && this.primaryOwnerMilCardForJointRef.current.sendDataToParent();
        const {updateMarketNAOInfo,masterLookupStateData} = this.props;

        let militryInfoPri = this.props.marketLocalStateData.marketNAOInfo.militaryInfo.primary;
        let militryStatusInfo = '';
        let branchInfo = '';
        let rankValue = ''

        let militryStatusInfoPri = '';
        let branchInfoPri = '';
        let rankValuePri = '';
        let rankFlag = false;
        let rankFlagPri = false;

        for (let i = 0; i < masterLookupStateData?.mil_rank_01?.value?.length; i++) {
            if (masterLookupStateData?.mil_rank_01?.value[i].key == MilInfo.rank) {
                rankValue = masterLookupStateData?.mil_rank_01?.value[i].value;
                rankFlag = true;
            }
          }

       
          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_03?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_03?.value[i].key == MilInfo.rank) {
                  rankFlag = true;
                  rankValue = masterLookupStateData?.mil_rank_03?.value[i].value;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_04?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_04?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_04?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_06?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_06?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_06?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_07?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_07?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_07?.value[i].value;
                    rankFlag = true;
                }
              }
          }


          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_08?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_08?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_08?.value[i].value;
                    rankFlag = true;
                }
              }
          }


          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_09?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_09?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_09?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_10?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_10?.value[i].key == MilInfo.rank) {
                  rankValue = masterLookupStateData?.mil_rank_10?.value[i].value;
                  rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_11?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_11?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_11?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_12?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_12?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_12?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_13?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_13?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_13?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          if(rankFlag == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_14?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_14?.value[i].key == MilInfo.rank) {
                    rankValue = masterLookupStateData?.mil_rank_14?.value[i].value;
                    rankFlag = true;
                }
              }
          }

          for (let i = 0; i < masterLookupStateData?.mil_status?.value?.length; i++) {
            if (masterLookupStateData?.mil_status?.value[i].key ==  MilInfo.milityStatus) {
                militryStatusInfo = masterLookupStateData?.mil_status?.value[i].value;
            }
            }
    
            for (let i = 0; i < masterLookupStateData?.mil_serv_branch?.value?.length; i++) {
                if (masterLookupStateData?.mil_serv_branch?.value[i].key == MilInfo.branchOfService) {
                    branchInfo = masterLookupStateData?.mil_serv_branch?.value[i].value;
            }
            }


          /// Primary info 

          for (let i = 0; i < masterLookupStateData?.mil_rank_01?.value?.length; i++) {
            if (masterLookupStateData?.mil_rank_01?.value[i].key == militryInfoPri.rank) {
                rankValuePri = masterLookupStateData?.mil_rank_01?.value[i].value;
                rankFlagPri = true;
            }
          }

       
          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_03?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_03?.value[i].key == militryInfoPri.rank) {
                  rankFlagPri = true;
                  rankValuePri = masterLookupStateData?.mil_rank_03?.value[i].value;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_04?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_04?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_04?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_06?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_06?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_06?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_07?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_07?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_07?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }


          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_08?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_08?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_08?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }


          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_09?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_09?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_09?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_10?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_10?.value[i].key == militryInfoPri.rank) {
                  rankValuePri = masterLookupStateData?.mil_rank_10?.value[i].value;
                  rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_11?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_11?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_11?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_12?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_12?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_12?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_13?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_13?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_13?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          if(rankFlagPri == false)
          {
            for (let i = 0; i < masterLookupStateData?.mil_rank_14?.value?.length; i++) {
                if (masterLookupStateData?.mil_rank_14?.value[i].key == militryInfoPri.rank) {
                    rankValuePri = masterLookupStateData?.mil_rank_14?.value[i].value;
                    rankFlagPri = true;
                }
              }
          }

          for (let i = 0; i < masterLookupStateData?.mil_status?.value?.length; i++) {
            if (masterLookupStateData?.mil_status?.value[i].key ==  militryInfoPri.militaryStatus) {
                militryStatusInfoPri = masterLookupStateData?.mil_status?.value[i].value;
            }
            }
    
            for (let i = 0; i < masterLookupStateData?.mil_serv_branch?.value?.length; i++) {
                if (masterLookupStateData?.mil_serv_branch?.value[i].key == militryInfoPri.branch) {
                    branchInfoPri = masterLookupStateData?.mil_serv_branch?.value[i].value;
                }
              }

          let milInfoData = {
            branch: MilInfo.currServingUSM == "Y" ?  branchInfo:"",
            commissionSource: MilInfo.currServingUSM == "Y" ?  MilInfo.commissionCourse : "",
            startDate: MilInfo.currServingUSM == "Y" ?  MilInfo.serviceFromDate : "",
            endDate:  MilInfo.currServingUSM == "Y" ? MilInfo.serviceToDate : "",
            hasServed: MilInfo.currServingUSM == "Y" ? true : false,
            militaryStatus: MilInfo.currServingUSM == "Y" ? militryStatusInfo : "",
            rank: MilInfo.currServingUSM == "Y" ? rankValue : "",
            otherBranch:"",
            otherMilitaryStatus:""
        }

        const militryInfoDataprimary = {
            hasServed: militryInfoPri.hasServed == "Y" ? true : false,
            militaryStatus: militryStatusInfoPri,
            otherMilitaryStatus: militryInfoPri.otherMilitaryStatus,
            branch: branchInfoPri,
            otherBranch: militryInfoPri.otherBranch,
            rank: rankValuePri,
            commissionSource: militryInfoPri.commissionSource,
            startDate: militryInfoPri.startDate,
            endDate: militryInfoPri.endDate
        }

        const payload = {
            applicationId: this.props.marketLocalStateData.applicationId,
            pageNumber: 2,
            source: "web",
            militaryInfo: {
            primary: militryInfoDataprimary,
            joint:milInfoData
            }
        }

        await this.props.postMarketNAOInfo({
            url: 'militaryInfo',
            payloadData: payload
        });

        
        milInfoData.branch =  MilInfo.currServingUSM == "Y" ?  MilInfo.branchOfService :"";
        milInfoData.militaryStatus = MilInfo.currServingUSM == "Y" ? MilInfo.milityStatus : "";
        milInfoData.hasServed = MilInfo.currServingUSM;
        milInfoData.rank = MilInfo.currServingUSM == "Y" ? MilInfo.rank : "";

        const saveToLocalpayload = {
            sectionToBeUpdated:'militaryInfo',
            state: milInfoData,
            isPrimary:false,
            isSecondary: true,
            isPersonalInfo : true
        }
        
        updateMarketNAOInfo(saveToLocalpayload);
    }

    saveOnNextClick = async () =>
    {
        const {pageIndex} = this.state;
        if(pageIndex== 0)
        {
            this.createPersonalInfoPayloadAndSave();
            this.createAddressInfoPayloadAndSave();
            this.createContactInfoPayloadAndSave();
        }
        else if(pageIndex == 1)
        {
            this.createEmploymentPayloadAndSave();
            this.createFinancialPayloadAndSave();
            this.createMilitaryPayloadAndSave();
            return true;
        }
        else if(pageIndex == 2)
        {
            this.createPersonalInfoPayloadForJoint();
            this.createAddressInfoPayloadAndSaveForJoint();
            this.createContactInfoPayloadAndSaveForJoint();
        }
        else if(pageIndex == 3)
        {
             this.createEmploymentPayloadAndSaveForJoint();
             this.createFinancialPayloadAndSaveForJoint();
             this.createMilitaryPayloadAndSaveForJoint();
        }
    }

    checkStatusForNextButton = ()=>
    {
        const {pageIndex} = this.state;
        let validFlag = true;
        if(pageIndex == 0)
        {
              const personalInformationFlag  = this.validatePersonalInformation();
              const physicalAddressFlag = this.validatePhysicalAddressInformation();
              const mailingAddressFlag = this.validateMailingAddressInformation();
              const phoneFlag = this.validatePhoneInformation();
              const emailFlag = this.validateEmailInformation();
              validFlag = (personalInformationFlag  && physicalAddressFlag && mailingAddressFlag && phoneFlag && emailFlag);
              if(validFlag == true)
              {
                  this.saveOnNextClick();
              }
             
              return validFlag;
        }
        else if(pageIndex == 1)
        {
            if(this.validateEmploymentFinancialAndMilitaryInformation() == true)
            {
                return this.saveOnNextClick();
            }
            else
            {
                return false;
            }
        }
        else if(pageIndex == 2)
        {
            const personalInformationFlag  = this.validatePersonalInformation();
            const physicalAddressFlag = this.validatePhysicalAddressInformation();
            const mailingAddressFlag = this.validateMailingAddressInformation();
            const phoneFlag = this.validatePhoneInformation();
            const emailFlag = this.validateEmailInformation();
            validFlag = (personalInformationFlag  && physicalAddressFlag && mailingAddressFlag && phoneFlag && emailFlag);
            if(validFlag == true)
            {
                this.saveOnNextClick();
            }
           
            return validFlag;
        }
        else if(pageIndex == 3)
        {
            if(this.validateEmploymentFinancialAndMilitaryInformation() == true)
            {
                this.saveOnNextClick();
                return true;
            }
            else
            {
                return false;
            }
        }
    }


    checkStatus = () => {
        let disableNext = true;
        let primMilData = {};
        let primEmpData = {};
        let primPerData = {};
        primPerData = this.primaryOwnerPerCardRef.current;
        primEmpData = this.primaryOwnerEmpCardRef.current;
        primMilData = this.primaryOwnerMilCardRef.current;

        if (primPerData) {
            const perState = { ...primPerData.state };
            disableNext = !perState.pageReady;
        }
        if (primEmpData) {
            const empState = { ...primEmpData.state };
            disableNext = disableNext || !empState.ready;
        }
        if (primMilData) {
            const milState = { ...primMilData.state };
            disableNext = disableNext || !milState.ready;
        }
        const { disableNext: prevStateNextDisabled } = this.state;
        if (prevStateNextDisabled !== disableNext) {
            this.setState(() => {
                return {
                    disableNext,
                };
            });
        }
    };

    isJointValidFunction = (accType, toBeSavedData,action) => {
        const { nickName, originalFileArray, imageFileUploaded } = this.state;
        let jointPerData = {};
        let isJointValid = false;
        let toBeSavedDataTemp = toBeSavedData;
        if (accType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT) {
            jointPerData = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.sendDataToParent(action);
            toBeSavedDataTemp = {
                ...toBeSavedData,
                jointPerInfo: jointPerData,
                nickName,
                originalFileArray,
                imageFileUploaded,
            };
            isJointValid = jointPerData.valid;
        } else {
            isJointValid = true;
        }
        return { isJointValid, toBeSavedData: toBeSavedDataTemp };
    };

    saveToLocalAction = (toBeSavedData, action, allFieldsValid, accType) => {
        const { nickName } = this.state;
        const { manageLocalState, localStateData, saveApplication } = this.props;
        const alreadySavedData = localStateData && localStateData[accType.toString()];
        const finalData = { ...alreadySavedData, ...toBeSavedData };

        const payLoad = generatePersonalInfoPayload(localStateData, finalData, nickName);
        const isJoint = accType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT;
        if (action === 'save') {
            const savePayload = { ...localStateData, [accType]: finalData, OpenAccPageTwo: payLoad };
            saveApplication({
                token: getToken(),
                saveApplicationPayload: {
                    accountType: accType,
                    payload: savePayload,
                },
                cleanPayload: isJoint,
            });

            this.setState({ save: true, loading: true });
        } else if (accType.indexOf('529') > -1) {
            const { back } = this.state;
            let data = {};
            if (back) {
                data = {
                    esaInfo: {
                        signedDocument: false,
                        pageName: 'application1',
                    },
                };
            }
            if (action === 'next' && allFieldsValid) {
                this.setState({ next: true });
            }
            manageLocalState({ data, OpenAccPageTwo: payLoad, [accType]: finalData });
        } else {
            if (action === 'next' && allFieldsValid) {
                this.setState({ next: true });
            }
            manageLocalState({ [accType]: finalData, OpenAccPageTwo: payLoad });
        }
        // TODO :: This has to be removed. Added for testing
    };

    scrollTo = errorSections => {
        const firstErrorSection = errorSections.find(section => !section.valid && section.element);
        firstErrorSection.element.accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (firstErrorSection.component) {
            const focusfirstErrorSection = document.getElementById(firstErrorSection.component);
            if (focusfirstErrorSection) focusfirstErrorSection.focus();
        }
    };

    /**
     * Validates Physical Address State for Joint Tenants with Right of Survivorship account type. If state is Louisiana or Puerto Rico then Account creation should not be allowed.
     */
    validatePhysicalAddress = (perInfo, jointOwnersInfo) => {
        const perPhysicalAddressState =
            perInfo &&
            (perInfo.sameAddress === 'Y' || (perInfo.sameAddress && perInfo.sameAddress.toLowerCase() === 'yes'))
                ? perInfo.primMailAddress && perInfo.primMailAddress.state
                : perInfo.primPhysicalAddress && perInfo.primPhysicalAddress.state;
        const isJointPhysicalAddressStateValid = [];
        for (let i = 0; i < jointOwnersInfo.jointOwners.length; i += 1) {
            const jointPhysicalAddressState =
                jointOwnersInfo.jointOwners[parseInt(i, 10)] &&
                jointOwnersInfo.jointOwners[parseInt(i, 10)].sameAddress === 'Yes'
                    ? jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData.state
                    : jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData.state;
            if (jointPhysicalAddressState === 'LA' || jointPhysicalAddressState === 'PR') {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = false;
            } else {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = true;
            }
        }
        let isAllJointAccAddressValid = true;
        for (let i = 0; i < isJointPhysicalAddressStateValid.length; i += 1) {
            if (isJointPhysicalAddressStateValid[parseInt(i, 10)] === false) {
                isAllJointAccAddressValid = false;
            }
        }
        return perPhysicalAddressState !== 'LA' && perPhysicalAddressState !== 'PR' && isAllJointAccAddressValid;
    };

    /**
     * Validates Physical Address State for Joint Tenants in entirety account type. If state is Ohio then Account creation should not be allowed.
     */
    validatePhysicalAddressJTIE = (perInfo, jointOwnersInfo) => {
        const perPhysicalAddressState =
            perInfo &&
            (perInfo.sameAddress === 'Y' || (perInfo.sameAddress && perInfo.sameAddress.toLowerCase() === 'yes'))
                ? perInfo.primMailAddress && perInfo.primMailAddress.state
                : perInfo.primPhysicalAddress && perInfo.primPhysicalAddress.state;
        const isJointPhysicalAddressStateValid = [];
        for (let i = 0; i < jointOwnersInfo.jointOwners.length; i += 1) {
            const jointPhysicalAddressState =
                jointOwnersInfo.jointOwners[parseInt(i, 10)] &&
                jointOwnersInfo.jointOwners[parseInt(i, 10)].sameAddress === 'Yes'
                    ? jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointMailAddressData.state
                    : jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData &&
                      jointOwnersInfo.jointOwners[parseInt(i, 10)].jointPhysicalAddressData.state;
            if (jointPhysicalAddressState === 'OH') {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = false;
            } else {
                isJointPhysicalAddressStateValid[parseInt(i, 10)] = true;
            }
        }
        let isAllJointAccAddressValid = true;
        for (let i = 0; i < isJointPhysicalAddressStateValid.length; i += 1) {
            if (isJointPhysicalAddressStateValid[parseInt(i, 10)] === false) {
                isAllJointAccAddressValid = false;
            }
        }
        return perPhysicalAddressState !== 'OH' && isAllJointAccAddressValid;
    };

    getAge = DOB => {
        const today = new Date();
        const birthDate = new Date(DOB);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age -= 1;
        }

        return age;
    };

    validateBeneficiaryAge = (perInfo, benInfo) => {
        const perPhysicalAddressState =
            (perInfo && perInfo.sameAddress === 'Y') ||
            (perInfo && perInfo.sameAddress && perInfo.sameAddress.toLowerCase() === 'yes')
                ? perInfo.primMailAddress && perInfo.primMailAddress.state
                : perInfo.primPhysicalAddress && perInfo.primPhysicalAddress.state;
        const age = this.getAge(benInfo.beneficiaryPrimaryDataArray[0].beneficiaryDOB);
        let isValidAge = true;
        if ((UTMAStateWithAge18ForNonMinor.includes(perPhysicalAddressState) && age >= 18) || age >= 21) {
            isValidAge = false;
        }
        return isValidAge;
    };

    validateCustomerDetails = primPerData => {
        const sameAddress = primPerData && primPerData.sameAddress === 'Y';
        const mailingAddress = primPerData.primMailAddress;

        const addressLine2 = sameAddress
            ? mailingAddress && mailingAddress.lineTwo
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.lineTwo;
        const addressLine1 = sameAddress
            ? mailingAddress && mailingAddress.lineOne
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.lineOne;
        const city = sameAddress
            ? mailingAddress && mailingAddress.city
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.city;
        const state = sameAddress
            ? mailingAddress && mailingAddress.state
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.state;
        const zip = sameAddress
            ? mailingAddress && mailingAddress.zipCode
            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.zipCode;

        const customerDataPayload = {
            firstName: primPerData.firstName,
            lastName: primPerData.lastName,
            phoneNumber:
                primPerData.primaryPhoneDetails && primPerData.primaryPhoneDetails.phoneNumber.replace(/-/g, ''),
            ssnTin: primPerData.ssnData && primPerData.ssnData.fullSSN,
            emailAddress:
                primPerData.emailAddressData &&
                primPerData.emailAddressData.primaryEmailDetails &&
                primPerData.emailAddressData.primaryEmailDetails.email,
            addressLine2,
            addressLine1,
            city,
            state,
            dateOfBirth: primPerData.dateOfBirth,
            zip,
        };

        // DO NOT DELETE - COMMENTED OUT CUSTOMER VERIFICATION API CALL TEMPORARILY UNTIL STRATEGY IS FINALIZED ON HOW TO TEST THIS FUNCTIONALITY
    };

    validateJointOwnersDetails = jointOwners => {


        this.setState({ numberOfJointOwners: jointOwners.length });

        for (let i = 0; i < jointOwners.length; i += 1) {
            const sameAddress = jointOwners[parseInt(i, 10)].sameAddress === 'Yes';
            const mailingAddress = jointOwners[parseInt(i, 10)].jointMailAddressData;
            const physicalAddress = jointOwners[parseInt(i, 10)].jointPhysicalAddressData;

            const addressLine2 = sameAddress
                ? mailingAddress && mailingAddress.lineTwo
                : physicalAddress && physicalAddress.lineTwo;
            const addressLine1 = sameAddress
                ? mailingAddress && mailingAddress.lineOne
                : physicalAddress && physicalAddress.lineOne;
            const city = sameAddress ? mailingAddress && mailingAddress.city : physicalAddress && physicalAddress.city;
            const state = sameAddress
                ? mailingAddress && mailingAddress.state
                : physicalAddress && physicalAddress.state;
            const zip = sameAddress
                ? mailingAddress && mailingAddress.zipCode
                : physicalAddress && physicalAddress.zipCode;

            const phoneNumber =
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails.phoneNumber &&
                jointOwners[parseInt(i, 10)].jointPhoneDetailsData.primaryPhoneDetails.phoneNumber.replace(/-/g, '');

            const emailAddress =
                jointOwners[parseInt(i, 10)].jointEmailAddressData &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData.primaryEmailDetails &&
                jointOwners[parseInt(i, 10)].jointEmailAddressData.emailAddressData.primaryEmailDetails.email;

           

            const customerDataPayload = {
                firstName: jointOwners[parseInt(i, 10)].firstName,
                lastName: jointOwners[parseInt(i, 10)].lastName,
                phoneNumber,
                ssnTin: jointOwners[parseInt(i, 10)].ssnData && jointOwners[parseInt(i, 10)].ssnData.fullSSN,
                emailAddress,
                addressLine2,
                addressLine1,
                city,
                state,
                dateOfBirth: jointOwners[parseInt(i, 10)].dateOfBirth,
                zip,
            };

            // DO NOT DELETE - COMMENTED OUT CUSTOMER VERIFICATION API CALL TEMPORARILY UNTIL STRATEGY IS FINALIZED ON HOW TO TEST THIS FUNCTIONALITY
        }
    };

    getIsValidMilitaryStartDate = (personalData, militaryData) => {
        const formatedDate = militaryData.serviceFromDate;
        const primaryDOBData = personalData.dateOfBirth;
        const searchPharse = /XX/gi;
        const primaryDOB = primaryDOBData.replace(searchPharse, '01');
        const fromDateYear = new Date(formatedDate); 
        const dateofBirthYear = new Date(primaryDOB);
        // To calculate the time difference of two dates
        const differenceInTime = fromDateYear.getTime() - dateofBirthYear.getTime();
        // To calculate the no. of days between two dates
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        // To calculate the no. of years between two dates
        const differenceInyears = differenceInDays / 365;
        return differenceInyears > 16;
    };

    saveToLocal = (action = 'defaultVal') => {
        const { localStateData, initialState } = this.props;
        const accType = localStateData.accountType || 'Individual';
        const role = getRole(initialState && initialState.getCustomerProfile);
        const {
            nickName,
            originalFileArray,
            imageFileUploaded,
            jointOwner1DataVerified,
            jointOwner2DataVerified,
            isSeniorForeignPoliticalFigure,
            seniorPoliticalFigureName,
        } = this.state;
        if (accType !== undefined) {
            let primMilData = {};
            let primFinData = {};
            let primEmpData = {};
            let primPerData = {};
            let jointPerData = {};
            let toBeSavedData = {};
            let beneficiaryData = {};
            let allFieldsValid = false;
            let isJointValid = false;
            primPerData = this.primaryOwnerPerCardRef.current && this.primaryOwnerPerCardRef.current.sendDataToParent(action);
            primEmpData = this.primaryOwnerEmpCardRef.current && this.primaryOwnerEmpCardRef.current.sendDataToParent();
            primFinData = this.primaryOwnerFinCardRef.current && this.primaryOwnerFinCardRef.current.sendDataToParent();
            primMilData = this.primaryOwnerMilCardRef.current && this.primaryOwnerMilCardRef.current.sendDataToParent();
            // send action which could be "save or "next" depending on the button click"
            beneficiaryData = this.beneficiaryCardRef.current && this.beneficiaryCardRef.current.sendDataToParent(action);
            primPerData.dateOfBirth =
                !primPerData.dateOfBirth || primPerData.dateOfBirth === ''
                    ? primPerData.dateOfBirthLocal
                    : primPerData.dateOfBirth;
                   // send action which could be "save or "next" depending on the button click"
            jointPerData = this.jointOwnerPerCardRef.current && this.jointOwnerPerCardRef.current.sendDataToParent(action);
            toBeSavedData = {
                ...toBeSavedData,
                perInfo: primPerData,
                empInfo: primEmpData,
                finInfo: primFinData,
                milInfo: primMilData,
                benInfo: beneficiaryData,
                jointPerInfo: jointPerData,
                nickName,
                originalFileArray,
                imageFileUploaded,
                isSeniorForeignPoliticalFigure,
                seniorPoliticalFigureName,
            };
            const { currServingUSM, serviceFromDate } = primMilData;
            const isValidMilitaryStartDate =
                currServingUSM === 'Y' && serviceFromDate !== ''
                    ? this.getIsValidMilitaryStartDate(primPerData, primMilData)
                    : true;
            const isbeneficiaryDataValid =
                beneficiaryData !== undefined && beneficiaryData !== null ? beneficiaryData.valid : true;
            let isRegQuestionValid = true;
            let toFocus = '';
            if (accType.includes('529')) {
                if (
                    (isSeniorForeignPoliticalFigure === 'Y' && seniorPoliticalFigureName.length > 0) ||
                    isSeniorForeignPoliticalFigure === 'N'
                ) {
                    isRegQuestionValid = true;
                    this.setState({
                        errorMsg: {
                            seniorPoliticalFigureName: '',
                            isSeniorForeignPoliticalFigure: '',
                        },
                    });
                } else if (!isSeniorForeignPoliticalFigure) {
                    isRegQuestionValid = false;
                    toFocus = 'YisPoliticalFigure';
                    this.setState({
                        errorMsg: {
                            seniorPoliticalFigureName: '',
                            isSeniorForeignPoliticalFigure: consts.REG_QUE_ERROR,
                        },
                    });
                } else {
                    isRegQuestionValid = false;
                    toFocus = 'seniorPoliticalFigureName';
                    this.setState({
                        errorMsg: {
                            seniorPoliticalFigureName: consts.POLITICAL_FIG_ERROR,
                            isSeniorForeignPoliticalFigure: '',
                        },
                    });
                }
            }

            const allIndividualFieldsValid =
                primPerData.valid &&
                primEmpData.valid &&
                primFinData.valid &&
                primMilData.valid &&
                isbeneficiaryDataValid &&
                isRegQuestionValid;

            const jointDetails = this.isJointValidFunction(accType, toBeSavedData, action);
            let isAccountOpeningAllowed = true;
            let isJoint = false;
            let jointOwnerVerification = false;

            isJointValid = jointDetails.isJointValid;
            toBeSavedData = jointDetails.toBeSavedData;

            allFieldsValid = isJointValid && allIndividualFieldsValid;
            let isUGMABeneAgeCheck = true;

            if (allFieldsValid) {
                this.setState({ disableNext: false });

                /* Validate Physical Address state for Joint Tenants with Right of Survivorship account type */
                if (
                    localStateData.OpenAccPageOne &&
                    localStateData.OpenAccPageOne.accountType === AccGlobalConstants.JOINT_TENANTS_ACCOUNT_TYPE
                ) {
                    isAccountOpeningAllowed = this.validatePhysicalAddress(primPerData, jointPerData);
                    this.setState({ isAccountOpeningAllowed });
                }
                /* Validate Physical Address state for Joint Tenants in entirety account type */
                if (
                    localStateData.OpenAccPageOne &&
                    localStateData.OpenAccPageOne.accountType === AccGlobalConstants.JOINT_TENANTS_IN_ENTIRETY
                ) {
                    isAccountOpeningAllowed = this.validatePhysicalAddressJTIE(primPerData, jointPerData);
                    this.setState({ isAccountOpeningAllowed });
                }

                let perPhysicalAddressState = '';
                if (localStateData.OpenAccPageOne && localStateData.OpenAccPageOne.accountType.includes('UGMA')) {
                    isUGMABeneAgeCheck = this.validateBeneficiaryAge(primPerData, beneficiaryData);
                    if (!isUGMABeneAgeCheck) {
                        isAccountOpeningAllowed = false;
                    }

                    perPhysicalAddressState =
                        (primPerData && primPerData.sameAddress === 'Y') ||
                        (primPerData && primPerData.sameAddress && primPerData.sameAddress.toLowerCase() === 'yes')
                            ? primPerData.primMailAddress && primPerData.primMailAddress.state
                            : primPerData.primPhysicalAddress && primPerData.primPhysicalAddress.state;
                }

                if (!isValidMilitaryStartDate) {
                    isAccountOpeningAllowed = false;
                }

                this.setState({
                    showAccountOpeningErrorModal: !isAccountOpeningAllowed,
                    isValidMilitaryStartDate,
                    isUGMABeneAgeCheck,
                    perPhysicalAddressState,
                });
                /* ---------------------END of Physical Address validation------------------- */
                /* ------------------- Customer verification------------------------ */
                if (role !== 'Member') {
                    if (isAccountOpeningAllowed) {
                        this.validateCustomerDetails(primPerData);
                    }
                }
                if (isAccountOpeningAllowed && jointPerData && jointPerData.jointOwners.length > 0) {
                    isJoint = true;
                    this.setState({ isJoint });
                    this.validateJointOwnersDetails(jointPerData.jointOwners);
                }
                if (isAccountOpeningAllowed && isJoint) {
                    jointOwnerVerification = jointOwner1DataVerified;
                    if (jointPerData && jointPerData.jointOwners.length > 1) {
                        jointOwnerVerification = jointOwner1DataVerified && jointOwner2DataVerified;
                    }
                    this.setState({ jointOwnerVerification });
                }

                /* --------------------End of Customer Verification-------------------- */
            } else {
                this.scrollTo([
                    {
                        element: this.primaryOwnerPerCardRef.current,
                        valid: primPerData.valid,
                        component: primPerData ? primPerData.toFocus : null,
                    },
                    {
                        element: this.primaryOwnerEmpCardRef.current,
                        valid: primEmpData.valid,
                        component: primEmpData ? primEmpData.toFocus : null,
                    },
                    {
                        element: this.primaryOwnerFinCardRef.current,
                        valid: primFinData.valid,
                    },
                    {
                        element: this.primaryOwnerMilCardRef.current,
                        valid: primMilData.valid,
                    },
                    {
                        element: this.beneficiaryCardRef.current,
                        valid: isbeneficiaryDataValid,
                        component: beneficiaryData ? beneficiaryData.toFocus : null,
                    },
                    {
                        element: this.jointOwnerPerCardRef.current,
                        valid: jointPerData && jointPerData.valid,
                        component: jointPerData ? jointPerData.toFocus : null,
                    },
                    {
                        element: this,
                        valid: isRegQuestionValid,
                        component: toFocus || null,
                    },
                ]);
            }
            // NO GIACT verification for Members - Primary Owner
        }
    };

    onSwitchComponent = bool => {
        const {pageIndex} = this.state;
        if(pageIndex >= 0 && pageIndex < 3){ 
            if(bool){
                this.setState({pageIndex : pageIndex +1})
            }else{
                this.setState({pageIndex : pageIndex -1})
            }
        } else if(pageIndex == 3){ 
            if(bool){
                this.setState({
                    next:true
                })
            }else{
                this.setState({pageIndex : pageIndex -1})
            }
        }
        else
        {
            this.setState({pageIndex:pageIndex})
        }
    };

    handleSaveClick = () => {
        this.saveToLocal('save');
    };
 handleVerifySave = () => {
    const {verifyPersonalHider,verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider,verifyEmpHider,verifyFinHider,verifyMilHider,verifyJointEmpHider,verifyJointFinHider,verifyJointMilHider,editPersonalValOut,editContactValOut,editPhoneValOut,editEmailValOut,editJointPersonalValOut,editJointContactValOut,editJointPhoneValOut,editJointEmailValOut,editEmpValOut,editFinValOut,editMilValOut,editJointEmpValOut,editJointFinValOut,editJointMilValOut} = this.props   
        

    verifyPersonalHider && this.checkStatusForNextButton() && editPersonalValOut(!verifyPersonalHider)
    verifyContactHider && this.checkStatusForNextButton() && editContactValOut(!verifyContactHider)
    verifyPhoneHider && this.checkStatusForNextButton() && editPhoneValOut(!verifyPhoneHider)
    verifyEmailHider && this.checkStatusForNextButton() && editEmailValOut(!verifyEmailHider)
    verifyJointPersonalHider && this.checkStatusForNextButton() && editJointPersonalValOut(!verifyJointPersonalHider)
    verifyJointContactHider && this.checkStatusForNextButton() && editJointContactValOut(!verifyJointContactHider)
    verifyJointPhoneHider && this.checkStatusForNextButton() && editJointPhoneValOut(!verifyJointPhoneHider)
    verifyJointEmailHider && this.checkStatusForNextButton() && editJointEmailValOut(!verifyJointEmailHider )
    verifyEmpHider  && this.checkStatusForNextButton() && editEmpValOut(!verifyEmpHider )
    verifyFinHider && this.checkStatusForNextButton() && editFinValOut(!verifyFinHider )
    verifyMilHider && this.checkStatusForNextButton() && editMilValOut(!verifyMilHider)
    verifyJointEmpHider && this.checkStatusForNextButton() && editJointEmpValOut(!verifyJointEmpHider)
    verifyJointFinHider && this.checkStatusForNextButton() && editJointFinValOut(!verifyJointFinHider)
    verifyJointMilHider && this.checkStatusForNextButton() && editJointMilValOut(!verifyJointMilHider)
    }

    handleVerifyCancel=()=>{ 

    const {verifyPersonalHider,verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider,verifyEmpHider,verifyFinHider,verifyMilHider,verifyJointEmpHider,verifyJointFinHider,verifyJointMilHider,editPersonalValOut,editContactValOut,editPhoneValOut,editEmailValOut,editJointPersonalValOut,editJointContactValOut,editJointPhoneValOut,editJointEmailValOut,editEmpValOut,editFinValOut,editMilValOut,editJointEmpValOut,editJointFinValOut,editJointMilValOut} = this.props   

    verifyPersonalHider && editPersonalValOut(!verifyPersonalHider)
    verifyContactHider && editContactValOut(!verifyContactHider)
    verifyPhoneHider && editPhoneValOut(!verifyPhoneHider)
    verifyEmailHider && editEmailValOut(!verifyEmailHider)
    verifyJointPersonalHider && editJointPersonalValOut(!verifyJointPersonalHider)
    verifyJointContactHider && editJointContactValOut(!verifyJointContactHider)
    verifyJointPhoneHider && editJointPhoneValOut(!verifyJointPhoneHider)
    verifyJointEmailHider && editJointEmailValOut(!verifyJointEmailHider)
    verifyEmpHider  && editEmpValOut(!verifyEmpHider)
    verifyFinHider  && editFinValOut(!verifyFinHider)
    verifyMilHider  && editMilValOut(!verifyMilHider)
    verifyJointEmpHider  && editJointEmpValOut(!verifyJointEmpHider)
    verifyJointFinHider  && editJointFinValOut(!verifyJointFinHider)
    verifyJointMilHider  && editJointMilValOut(!verifyJointMilHider)

    }

    handleCloseModal = () => {
        this.setState({showNonResidentialPopUp: false})
    }

    handleNextDisable=(value, disableTo='')=>{

        if(disableTo == 'showNonResidentPopUp'){
            // next button will be clickable and on clicking will show popup
            // this functionality is different from diable a button so assigning valuee in different variable
            this.setState({temperarilyDisabled: value});
        }else{
            this.setState({isNextDisbled: value});

        }
    }

    handleDisabledNextClick = () => {
        this.setState({showNonResidentialPopUp: true});
    }

    handleNextClick = () => {
       const {pageIndex} = this.state;
        const { accountType } = this.props.marketLocalStateData.marketNAOInfo.account || {};

        if(this.checkStatusForNextButton())
        {
            this.setState({moveNextScreen: true, load:true})
        }

    };

    clearInitialFiles = () => {
        this.setState({ localFile: null, personalInfoUploadPh: undefined, originalFileArray: null });
    };

    handleChange = e => {
        const { errorMsg } = this.state;
        const err = Rules[e.target.name]
            ? checkValidity({
                  rules: Rules[e.target.name],
                  value: e.target.value,
              })
            : '';

        errorMsg[e.target.name.toString()] = err;
        this.setState({ [e.target.name]: e.target.value, errorMsg });
    };

    handleBlur = e => {
        if (e.target.value) {
            this.getCardValues(this.state);
        }
    };

    handleClose = () => {
        this.setState({
            showAlert: false,
            errorMessage: '',
            saveErrorShown: false,
            variantForUpload: '',
            showSaveModal: false,
        });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    redirect = () => {
        const { next, back } = this.state;
        const { localStateData } = this.props;
        let pathName = '';
        const { accountType } = localStateData || {};
        if (next) {
            if (accountType && accountType.includes('529')) {
                pathName = '/beneficiaryInfoChild';
            } else {
                pathName = '/marketNAOFlow';
            }
        } else if (back) {
            if (accountType && accountType.includes('529')) {
                pathName = '/application';
            } else {
                pathName = '/marketAccountType';
            }
        }
        return {
            pathname: pathName,
        };
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    handleVerifyCustomerDetailsOk = () => {
        const { setShowCustomerVerificationModal } = this.props;
        setShowCustomerVerificationModal(false);
    };

    handleAccountOpeningAllowed = () => {
        this.setState({
            showAccountOpeningErrorModal: false,
            isValidMilitaryStartDate: true,
            isAccountOpeningAllowed: true,
            isUGMABeneAgeCheck: true
        });
    };

    handleBackClick = () => {
        const {pageIndex} = this.state;
        if(pageIndex == 0){
            this.setState({back:true})
          }
          else
          {
            this.setState({pageIndex : pageIndex - 1})
          }
    };

    handleDOB = val => {
        this.setState({ primaryDOB: val });
    };

    cardHeaderClick = e => {
        e.preventDefault();
        e.target.classList.toggle('expand');
    };

    handleRadioBlur = eventKey => e => {
        e.persist();
        const { errorMsg, isSeniorForeignPoliticalFigure } = this.state;
        if (!isSeniorForeignPoliticalFigure) {
            errorMsg[eventKey.toString()] = consts.REG_QUE_ERROR;
        }

        this.setState({ errorMsg });
    };

    handleRadioChange = e => {
        const targetVal = e.target.value.split('');
        const { errorMsg } = this.state;
        errorMsg.isSeniorForeignPoliticalFigure = '';
        this.setState({ errorMsg, isSeniorForeignPoliticalFigure: targetVal && targetVal[0].toString().toUpperCase() });
    };

    getValue = data => {
        this.setState({
            ...data,
            seniorPoliticalFigureName: '',
            errorMsg: {
                seniorPoliticalFigureName: '',
                isSeniorForeignPoliticalFigure: '',
            },
        });
    };

    handleRegChange = eventKey => e => {
        this.setState({
            [eventKey]: e.target.value,
        });
    };

    handleBlurRegQuestion = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';

        errorMsg[eventKey.toString()] = err && err.trim().length > 0 ? 'Please enter Senior Political Figure Name' : '';
        this.setState({ errorMsg });
    };

    handleSameAddress = (address,accountTye = "default") =>
    {
        if(accountTye == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)
        {
            this.state.sameAddressInfoData[1].primaryIsPhysicalAddressSame = address;
        }
        else
        {
            this.state.sameAddressInfoData[0].primaryIsPhysicalAddressSame = address;
        }
        
        
        this.setState({})
    }
    renderOtherTypeAccounts = () => {
        const { localStateData } = this.props;
        const { isSeniorForeignPoliticalFigure, errorMsg, seniorPoliticalFigureName } = this.state;
        const { accountType } = localStateData || {};
        const savedJointPerInfo =
            accountType &&
            localStateData[accountType.toString()] &&
            localStateData[accountType.toString()].jointPerInfo;
        const isErrorMsg = errorMsg.isSeniorForeignPoliticalFigure;

        const savedBeneficiaryData =
            accountType && localStateData[accountType.toString()] && localStateData[accountType.toString()].benInfo;
        return (
            <>
                {localStateData.accountType && localStateData.accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && (
                    <>
                        <JointPersonalInfoCardComponent
                            savedState={savedJointPerInfo}
                            isJoint
                            ref={this.jointOwnerPerCardRef}
                        />
                    </>
                )}
                {localStateData.accIDSelected &&
                    (localStateData.accIDSelected === 'ira' || localStateData.accIDSelected === 'inv_child') &&
                    localStateData.accountType &&
                    !localStateData.accountType.includes('529') && (
                        <BeneficiaryCard
                            ref={this.beneficiaryCardRef}
                            accountSelected={localStateData.accIDSelected}
                            accountType={localStateData.accountType}
                            savedState={savedBeneficiaryData}
                        />
                    )}
                {localStateData.accountType && localStateData.accountType.includes('529') && (
                    <Accordian
                        ref={this.accordionRef}
                        key={consts.REGULATORY_QUESTION}
                        accordianPaddingLeft={0}
                        titleChild={
                            <AccordianTitle
                                accType={consts.REGULATORY_QUESTION}
                                id={consts.REGULATORY_QUESTION}
                                className="accordionH2Style"
                                requireButtonPadding={false}
                            />
                        }
                        // titleSeperator
                        labelledBy={consts.REGULATORY_QUESTION}
                        Id={consts.REGULATORY_QUESTION}
                    >
                        <Card>
                          
                            <Card.Body>
                                <Row>
                                    <span className="pepQuestionLabel">{consts.PEP_QUESTION_LABEL}</span>
                                </Row>
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`foreignPoliticalFigure`)}
                                >
                                    <span
                                        className="label"
                                        style={labelTextStyle}
                                        id={assignObj(`foreignPoliticalFigure`)}
                                    >
                                        {consts.PEP_QUESTION}
                                    </span>
                                    <div className="foreignPoliticalFigure">
                                        <div className="pepQuestionRadio" role="radiogroup">
                                            {consts.sameAddress.value &&
                                                consts.sameAddress.value.length > 0 &&
                                                consts.sameAddress.value.map(field => (
                                                    <WRadio
                                                        radioFor="politicalFigure"
                                                        key={field.key}
                                                        id={`${field.key}isPoliticalFigure`}
                                                        value={field.value}
                                                        onBlur={assignObj(
                                                            this.handleRadioBlur('isSeniorForeignPoliticalFigure'),
                                                        )}
                                                        onClick={this.handleRadioChange}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={
                                                            !!(
                                                                isSeniorForeignPoliticalFigure &&
                                                                isSeniorForeignPoliticalFigure.toString() === field.key
                                                            )
                                                        }
                                                        ariaRequired="true"
                                                        ariaDescribedBy="isSeniorForeignPoliticalFigureError"
                                                    />
                                                ))}
                                        </div>
                                        <div>
                                            {isErrorMsg && (
                                                <div
                                                    className="errorMsgInputStyle errorSameAddressDiv"
                                                    id="isSeniorForeignPoliticalFigureError"
                                                    aria-live="assertive"
                                                    aria-atomic="true"
                                                >
                                                    <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                                                    <div id="errIsSeniorForeignPoliticalFigure">
                                                        {errorMsg.isSeniorForeignPoliticalFigure}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Row>
                                {(isSeniorForeignPoliticalFigure === 'Y' ||
                                    isSeniorForeignPoliticalFigure === 'Yes') && (
                                    <WInput
                                        label={consts.SENIOR_POLITICAL_FIG_NAME}
                                        placeholder={consts.PEP_PLACEHOLDER_TEXT}
                                        inputclassname="seniorPoliticalFigName"
                                        id="seniorPoliticalFigureName"
                                        name="seniorPoliticalFigureName"
                                        type="text"
                                        tabIndex="0"
                                        subtype="characters"
                                        maxlength={50}
                                        onChange={this.handleRegChange('seniorPoliticalFigureName')}
                                        onBlur={this.handleBlurRegQuestion('seniorPoliticalFigureName')}
                                        required
                                        role={isErrorMsg ? 'alert' : ''}
                                        aria-describedby={isErrorMsg ? 'error occur' : ''}
                                        value={seniorPoliticalFigureName || ''}
                                        errortext={errorMsg.seniorPoliticalFigureName}
                                        aria-invalid={isErrorMsg ? 'true' : 'false'}
                                        labelsm={4}
                                        valuesm={5}
                                        optional="false"
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Accordian>
                )}
            </>
        );
    };

    getKey = (lookupString, lookupValue) => {
        if (lookupValue && lookupValue.toString().trim() !== '') {
            const { masterLookupStateData } = this.props;
            const lookupList =
                masterLookupStateData &&
                masterLookupStateData[lookupString.toString()] &&
                masterLookupStateData[lookupString.toString()].value;
            const lookupData =
                lookupList && lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
            if (lookupData) {
                return lookupData.key;
            }
        }
        return '';
    };

    getSavedFinInfo = financialInformation => {
        let { annualIncome, taxBracket, netWorth, taxFilingStatus } = financialInformation;
        annualIncome = this.getKey('brkg_annual_income', annualIncome);
        netWorth = this.getKey('brkg_net_worth', netWorth);
        taxFilingStatus = this.getKey('tax_filling_status', taxFilingStatus);
        taxBracket =
            taxBracket &&
            parseFloat(taxBracket)
                .toFixed(0)
                .concat('%');
       
              
        return {
            annualIncome,
            taxBracket,
            netWorth,
            taxFilingStatus,
        };
    };

    getSavedEmpInfo = employmentInformation => {
        const {
            employerName,
            addressLine1,
            addressLine2,
            employersCity,
            employersState,
            employersZipcode,
            industryOther,
            occupation,
            empClassInfo
        } = employmentInformation;
        let { employmentStatus, industry, primarySourceOfIncome } = employmentInformation;
        employmentStatus = this.getKey('brkg_employment_status', employmentStatus);
        if (employmentStatus === '') {
            return null;
        }
        industry = this.getKey('industry', industry);
        primarySourceOfIncome = this.getKey('prim_src_income', primarySourceOfIncome);
        return {
            empStatus: employmentStatus,
            industry,
            industryDescription: industryOther,
            occupation,
            employersName: employerName,
            empClassInfo,
            employerAddress: {
                addressLine1,
                addressLine2,
                zip: employersZipcode,
                city: employersCity,
                state: employersState,
            },
            primarySourceofIncome: primarySourceOfIncome,
        };
    };

    // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues = () =>{

        this.setState({errorMsg:{
        }
        })

        this.primaryOwnerPerCardRef.current.onSaveResetStateValues()
        this.primaryOwnerEmpCardRef.current.onSaveResetStateValues()

        // check if Joint owner form is displaying/available to make the call to joint component method, 
        if(this.jointOwnerPerCardRef.current){
            this.jointOwnerPerCardRef.current.onSaveResetStateValues()
        }
    }


    getCountryCode= (value, getKeyOrValue) => {
        const { masterLookupStateData } = this.props;
        const {
            country_3_lettercode: countryMaster,
        } = masterLookupStateData || {};
        const { value: countryMasterValue } = countryMaster || {};
        const countryOp = countryMasterValue || [];
        const selectedCountry = countryOp.find(item => item.value == value || item.key == value)

        if(selectedCountry != undefined){
            if( getKeyOrValue == 'value'){
                return selectedCountry.value
            }else{
                return selectedCountry.key
            }

        }else{
            return ''
        }
    }



    renderEmploymentFinancialAndMilitary = (accountTypePara="default") =>
    {
        const {pageIndex} = this.state;
        const {
            perPhysicalAddressState
        } = this.state;
        const {verifyEmpHider,verifyFinHider,verifyMilHider,verifyJointEmpHider,verifyJointFinHider,verifyJointMilHider}=this.props

        const {profileInformationData,marketLocalStateData } = this.props;
        const {employmentInfo,financialInfo,militaryInfo,personalInfo} = marketLocalStateData.marketNAOInfo;
     
        let savedEmpInfo = null;
        if(pageIndex == 3)
        {
            if (employmentInfo.secondary) {
                const employmentInformationMarket = {
                    addressLine1: employmentInfo.secondary.address.addressLine1,
                    addressLine2: employmentInfo.secondary.address.addressLine2,
                    employersCity: employmentInfo.secondary.address.city,
                    employersState: employmentInfo.secondary.address.state,
                    employersZipcode: employmentInfo.secondary.address.zipCode,
                    employerName: employmentInfo.secondary.employerName,
                    employmentStatus: employmentInfo.secondary.status,
                    industry:  employmentInfo.secondary.industry,
                    occupation:employmentInfo.secondary.occupation,
                    industryOther: employmentInfo.secondary.industryDescription,
                    primarySourceOfIncome: employmentInfo.secondary.primarySource,
                    empClassInfo:employmentInfo.secondary.employmentClassName
                }
                savedEmpInfo = this.getSavedEmpInfo(employmentInformationMarket);
            }
        }
        else
        {
            if (employmentInfo.primary) {
                const employmentInformationMarket = {
                    addressLine1: employmentInfo.primary.address.addressLine1,
                    addressLine2: employmentInfo.primary.address.addressLine2,
                    employersCity: employmentInfo.primary.address.city,
                    employersState: employmentInfo.primary.address.state,
                    employersZipcode: employmentInfo.primary.address.zipCode,
                    employerName: employmentInfo.primary.employerName,
                    employmentStatus: employmentInfo.primary.status,
                    industry:  employmentInfo.primary.industry,
                    occupation:employmentInfo.primary.occupation,
                    industryOther: employmentInfo.primary.industryDescription,
                    primarySourceOfIncome: employmentInfo.primary.primarySource,
                    empClassInfo:employmentInfo.primary.employmentClassName
                }
                savedEmpInfo = this.getSavedEmpInfo(employmentInformationMarket);
            }
        }

        let savedFinInfo = null;

        if(pageIndex == 3)
        {
            if (financialInfo.secondary) {
                const financialInformationMarket = {
                    annualIncome:financialInfo.secondary.annualIncome,
                    netWorth: financialInfo.secondary.netWorth,
                    taxBracket: financialInfo.secondary.taxBracket,
                    taxFilingStatus: financialInfo.secondary.taxFilingStatus,
                }
                savedFinInfo = this.getSavedFinInfo(financialInformationMarket);
            }
           
        }
        else
        {
            if (financialInfo.primary) {
                const financialInformationMarket = {
                    annualIncome:financialInfo.primary.annualIncome,
                    netWorth: financialInfo.primary.netWorth,
                    taxBracket: financialInfo.primary.taxBracket,
                    taxFilingStatus: financialInfo.primary.taxFilingStatus,
                }
       
                savedFinInfo = this.getSavedFinInfo(financialInformationMarket);
            }
           
        }

        let savedMilInfo = null;

        if(pageIndex == 3)
        {
              savedMilInfo = {
                commissionCourse: militaryInfo.secondary.commissionSource,
                serviceFromDate: militaryInfo.secondary.startDate,
                isCurrentlyServing: militaryInfo.secondary.militaryServed,
                branchOfService: militaryInfo.secondary.branch,
                milityStatus: militaryInfo.secondary.militaryStatus,
                serviceToDate: militaryInfo.secondary.endDate,
                serviceFromDatepickerValue: militaryInfo.secondary.startDate,
                serviceToDatepickerValue: militaryInfo.secondary.endDate,
                rank:militaryInfo.secondary.rank,
                currServingUSM:militaryInfo.secondary.hasServed
            }
        }
        else
        {
             savedMilInfo = {
                commissionCourse: militaryInfo.primary.commissionSource,
                isCurrentlyServing: militaryInfo.primary.militaryServed,
                branchOfService: militaryInfo.primary.branch,
                milityStatus: militaryInfo.primary.militaryStatus,
                serviceToDate: militaryInfo.primary.endDate,
                serviceFromDate: militaryInfo.primary.startDate,
                serviceFromDatepickerValue: militaryInfo.primary.startDate,
                serviceToDatepickerValue: militaryInfo.primary.endDate,
                rank:militaryInfo.primary.rank,
                currServingUSM:militaryInfo.primary.hasServed
            }
        }

         const {marketNAOInfo} = this.props.marketLocalStateData;

        let jointDOB = "";
        if(accountTypePara == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)
        {
            jointDOB = marketNAOInfo?.personalInfo?.secondary?.maskedDob
        }

        // Code for Joint account 

        if(accountTypePara =="default")
        {
            return(
                <>
                <MarketEmploymentInfoCard
                    checkStatus={this.checkStatus}
                    savedState={savedEmpInfo}
                    ref={this.primaryOwnerEmpCardRef}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    verifyFinHider={verifyFinHider}
                    verifyMilHider={verifyMilHider}
                />
    
                 <MarketFinancialInfoCard 
                 savedState={savedFinInfo} 
                 ref={this.primaryOwnerFinCardRef}
                 handlePersonalStateData = {this.handlePersonalStateData}
                 verifyEmpHider={verifyEmpHider}
                 verifyMilHider={verifyMilHider}
                 />
    
                 <MarketMilitaryInfoCard
                    savedState={savedMilInfo}
                    ref={this.primaryOwnerMilCardRef}
                    checkStatus={this.checkStatus}
                    primaryDOB={profileInformationData.profileInformation?.m_dateOfBirth || personalInfo.primary.maskedDob}
                    handlePersonalStateData = {this.handlePersonalStateData}
                     verifyEmpHider={verifyEmpHider}
                    verifyFinHider={verifyFinHider}
                />
                 </>
              
            )
        }
        else if(accountTypePara == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)
        {
            return(
                <>
                <MarketEmploymentInfoCard
                    checkStatus={this.checkStatus}
                    savedState={savedEmpInfo}
                    ref={this.primaryOwnerEmpCardForJointRef}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    jointInfo={"Yes"}
                     verifyJointFinHider={verifyJointFinHider}
                    verifyJointMilHider={verifyJointMilHider}
                />
    
                 <MarketFinancialInfoCard 
                 savedState={savedFinInfo} 
                 ref={this.primaryOwnerFinCardForJointRef}
                 handlePersonalStateData = {this.handlePersonalStateData}
                 jointInfo={"Yes"}
                 verifyJointEmpHider={verifyJointEmpHider}
                 verifyJointMilHider={verifyJointMilHider}
                 />
    
                 <MarketMilitaryInfoCard
                    savedState={savedMilInfo}
                    ref={this.primaryOwnerMilCardForJointRef}
                    checkStatus={this.checkStatus}
                    primaryDOB={profileInformationData?.profileInformation?.m_dateOfBirth || jointDOB}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    jointInfo={"Yes"}
                    verifyJointEmpHider={verifyJointEmpHider}
                    verifyJointFinHider={verifyJointFinHider}
                />
                 </>
              
            )
        }
    }
 
    bindComponentForJointAccount = () =>
    {
        const {pageIndex} = this.state;
         const {verifyPersonalHider,verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider}=this.props

        const { localStateData, profileInformationData } = this.props;
        const { accountType } = localStateData || {};
        const savedPerInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo;
        let savedEmpInfo =
            accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.employementInfo;

        if (!savedEmpInfo && profileInformationData?.profileInformation?.employmentInformation) {
            const { employmentInformation } = profileInformationData?.profileInformation || {};
            savedEmpInfo = this.getSavedEmpInfo(employmentInformation);
        }


        let savedFinInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.financialInfo;

        if (!savedFinInfo && profileInformationData?.profileInformation?.financialInformation) {
            const { financialInformation } = profileInformationData?.profileInformation || {};
            savedFinInfo = this.getSavedFinInfo(financialInformation);
        }

        const {marketNAOInfo} = this.props.marketLocalStateData;
        const personalInfomationLocalStateData = {
            profileInformation: {
                addressInformation : [
                    marketNAOInfo.addressInfo.primary.physicalAddress,
                    marketNAOInfo.addressInfo.primary.mailingAddress, 
                    ],
                sameAddressInfo: [{
                    isPhysicalAddressSame:marketNAOInfo.addressInfo.primary.isPhysicalAddressSame
                }],
                citizenship: marketNAOInfo.personalInfo.primary.citizenship,
                countryOfCitizenship :  marketNAOInfo.personalInfo.primary.countryOfCitizenship,
                emailInformation : marketNAOInfo.contactInfo.primary.emailAddresses,
                employmentInformation: marketNAOInfo.employmentInfo,
                firstName: marketNAOInfo.personalInfo.primary.firstName,
                gender:marketNAOInfo.personalInfo.primary.gender,
                lastName:marketNAOInfo.personalInfo.primary.lastName,
                dateOfBirth:marketNAOInfo.personalInfo.primary.maskedDob,
                ssn:  marketNAOInfo.personalInfo?.primary?.maskedSSN?.includes('X') ? marketNAOInfo.personalInfo?.primary?.maskedSSN?.replace('-','').replace('-','') : 
                marketNAOInfo.personalInfo?.primary?.withoutMaskedSSN,
                maritalStatus: marketNAOInfo.personalInfo.primary.maritalStatus,
                middleInitial: marketNAOInfo.personalInfo.primary.middleInitial,
                militaryInformation: marketNAOInfo.militaryInfo,
                phoneInformation: [marketNAOInfo.contactInfo.primary.phoneInfo],
                prefix: marketNAOInfo.personalInfo.primary.prefix,
                suffix : marketNAOInfo.personalInfo.primary.suffix,


                residentStatus: marketNAOInfo.personalInfo.primary?.residentStatus,
                resident:marketNAOInfo.personalInfo.primary?.residentStatus,
                residentCardNumber:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.permanentResidentCardNumber,
                passportNumber:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.passportNumber,
                ownerResidentExpiry:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.permanentResidentExpirationDate,
                ownerPassportExpiry:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.passportExpirationDate,
            }
        } 

        const personalInfomationLocalStateDataForJoint = {
            profileInformation: {
                addressInformation : [
                    marketNAOInfo.addressInfo.secondary.physicalAddress,
                    marketNAOInfo.addressInfo.secondary.mailingAddress, 
                    ],
                sameAddressInfo: [{
                    isPhysicalAddressSame:marketNAOInfo.addressInfo.secondary.isPhysicalAddressSame
                }],
                citizenship: marketNAOInfo.personalInfo.secondary.citizenship,
                emailInformation : marketNAOInfo.contactInfo.secondary.emailAddresses,
                employmentInformation:  marketNAOInfo.employmentInfo,
                firstName:  marketNAOInfo.personalInfo.secondary.firstName,
                gender:  marketNAOInfo.personalInfo.secondary.gender,
                lastName:  marketNAOInfo.personalInfo.secondary.lastName,
                dateOfBirth: marketNAOInfo.personalInfo.secondary.maskedDob,
                ssn:  marketNAOInfo.personalInfo?.secondary?.maskedSSN?.includes('X') ? marketNAOInfo.personalInfo?.secondary?.maskedSSN?.replace('-','').replace('-','') : 
                marketNAOInfo.personalInfo?.secondary?.withoutMaskedSSN,
                maritalStatus:  marketNAOInfo.personalInfo.secondary.maritalStatus,
                middleInitial: marketNAOInfo.personalInfo.secondary.middleInitial,
                militaryInformation:  marketNAOInfo.militaryInfo,
                phoneInformation:  [marketNAOInfo.contactInfo.secondary.phoneInfo],
                prefix:  marketNAOInfo.personalInfo.secondary.prefix,
                suffix : marketNAOInfo.personalInfo.secondary.suffix,
                relationship : marketNAOInfo.personalInfo.secondary.relationship,
                residentStatus: marketNAOInfo.personalInfo.secondary?.residentStatus,
                countryOfCitizenship: marketNAOInfo.personalInfo.secondary?.countryOfCitizenship,
                resident:marketNAOInfo.personalInfo.secondary?.residentStatus,
                residentCardNumber:marketNAOInfo.personalInfo.secondary?.residentAlienInformation?.permanentResidentCardNumber,
                passportNumber:marketNAOInfo.personalInfo.secondary?.residentAlienInformation?.passportNumber,
                ownerResidentExpiry:marketNAOInfo.personalInfo.secondary?.residentAlienInformation?.permanentResidentExpirationDate,
                ownerPassportExpiry:marketNAOInfo.personalInfo.secondary?.residentAlienInformation?.passportExpirationDate,
            }
        } 

        switch(pageIndex)
        {
            case consts.MARKET_NAO_STEPS.PERSONAL_INFO:{
                return (
                    <MarketPersonalInfoCardComponent
                    sendDOB={this.handleDOB}
                    savedState={savedPerInfo}
                    ref={this.primaryOwnerPerCardRef}
                    checkStatus={this.checkStatus}
                    personalInfo={personalInfomationLocalStateData}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    handleSameAddress = {this.handleSameAddress}
                    sameAddressInformation = {this.state.sameAddressInfoData}
                    verifyPersonalHider={verifyPersonalHider}
                    verifyContactHider={verifyContactHider}
                    verifyPhoneHider={verifyPhoneHider}
                    verifyEmailHider={verifyEmailHider}
                    pageIndex={pageIndex}
                    handleNextDisable={this.handleNextDisable}
                />
                )
            }
            case consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO:{
                return(
                   this.renderEmploymentFinancialAndMilitary()
                )
            }
           case consts.MARKET_NAO_STEPS.PERSONAL_INFO_JOINT:{
            return(
                <MarketPersonalInfoCardComponent
                    sendDOB={this.handleDOB}
                    savedState={savedPerInfo}
                    ref={this.jointOwnerPerCardRef}
                    checkStatus={this.checkStatus}
                    personalInfo={personalInfomationLocalStateDataForJoint}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    handleSameAddress = {this.handleSameAddress}
                    sameAddressInformation = {this.state.sameAddressInfoData}
                    jointInfo={"Yes"}
                    marketNAOInfo={marketNAOInfo}
                    verifyJointPersonalHider={verifyJointPersonalHider}
                    verifyJointContactHider={verifyJointContactHider}
                    verifyJointPhoneHider={verifyJointPhoneHider}
                    verifyJointEmailHider={verifyJointEmailHider}
                    handleNextDisable={this.handleNextDisable}
            />
              
            )
           }
           case consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO_JOINT:{
            return(
                this.renderEmploymentFinancialAndMilitary(MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)
             )
           }
           default:{
            return (
                <MarketPersonalInfoCardComponent
                sendDOB={this.handleDOB}
                savedState={savedPerInfo}
                ref={this.primaryOwnerPerCardRef}
                checkStatus={this.checkStatus}
                personalInfo={profileInformationData}
                handlePersonalStateData = {this.handlePersonalStateData}
                verifyJointPersonalHider={verifyJointPersonalHider}
                verifyJointContactHider={verifyJointContactHider}
                verifyJointPhoneHider={verifyJointPhoneHider}
                verifyJointEmailHider={verifyJointEmailHider}
                verifyPersonalHider={verifyPersonalHider}
                verifyContactHider={verifyContactHider}
                verifyPhoneHider={verifyPhoneHider}
                verifyEmailHider={verifyEmailHider}
                handleNextDisable={this.handleNextDisable}
            />
            )
           }
        }
    }   


    bindComponent = ()=>
    {
        const {pageIndex} = this.state;
        const {verifyPersonalHider,verifyContactHider,verifyPhoneHider,verifyEmailHider}=this.props

        const { localStateData, profileInformationData } = this.props;
        const { accountType } = localStateData || {};
       
        const savedPerInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo;
        let savedEmpInfo =
            accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.employementInfo;

        if (!savedEmpInfo && profileInformationData?.profileInformation?.employmentInformation) {
            const { employmentInformation } = profileInformationData?.profileInformation || {};
            savedEmpInfo = this.getSavedEmpInfo(employmentInformation);
        }

        let savedFinInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.financialInfo;

        if (!savedFinInfo && profileInformationData?.profileInformation?.financialInformation) {
            const { financialInformation } = profileInformationData?.profileInformation || {};
            savedFinInfo = this.getSavedFinInfo(financialInformation);
        }

        const {marketNAOInfo} = this.props.marketLocalStateData;
        const personalInfomationLocalStateData = {
            profileInformation: {
                addressInformation : [
                    marketNAOInfo.addressInfo.primary.physicalAddress,
                    marketNAOInfo.addressInfo.primary.mailingAddress, 
                    ],
                sameAddressInfo: [{
                    isPhysicalAddressSame:marketNAOInfo.addressInfo.primary.isPhysicalAddressSame
                }],
                citizenship: marketNAOInfo.personalInfo.primary.citizenship,
                emailInformation : marketNAOInfo.contactInfo.primary.emailAddresses,
                employmentInformation: marketNAOInfo.employmentInfo,
                firstName: marketNAOInfo.personalInfo.primary.firstName,
                gender:marketNAOInfo.personalInfo.primary.gender,
                lastName:marketNAOInfo.personalInfo.primary.lastName,
                dateOfBirth:marketNAOInfo.personalInfo.primary.maskedDob,
                ssn: (marketNAOInfo.personalInfo?.primary?.maskedSSN?.includes("XX")==true) ? marketNAOInfo.personalInfo?.primary?.maskedSSN?.replace('-','').replace('-','') : marketNAOInfo.personalInfo?.primary?.withoutMaskedSSN?.replace('-','').replace('-',''),
                maritalStatus: marketNAOInfo.personalInfo.primary.maritalStatus,
                middleInitial: marketNAOInfo.personalInfo.primary.middleInitial,
                militaryInformation: marketNAOInfo.militaryInfo,
                phoneInformation: [marketNAOInfo.contactInfo.primary.phoneInfo],
                prefix: marketNAOInfo.personalInfo.primary.prefix,
                suffix : marketNAOInfo.personalInfo.primary.suffix,

                residentStatus: marketNAOInfo.personalInfo.primary?.residentStatus,
                countryOfCitizenship :  marketNAOInfo.personalInfo.primary?.countryOfCitizenship,
                resident:marketNAOInfo.personalInfo.primary?.residentStatus,
                residentCardNumber:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.permanentResidentCardNumber,
                passportNumber:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.passportNumber,
                ownerResidentExpiry:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.permanentResidentExpirationDate,
                ownerPassportExpiry:marketNAOInfo.personalInfo.primary?.residentAlienInformation?.passportExpirationDate,
            }
        } 

        switch(pageIndex)
        {
            case consts.MARKET_NAO_STEPS.PERSONAL_INFO:{
              
                return (
                    <MarketPersonalInfoCardComponent
                    sendDOB={this.handleDOB}
                    savedState={savedPerInfo}
                    ref={this.primaryOwnerPerCardRef}
                    checkStatus={this.checkStatus}
                    personalInfo={personalInfomationLocalStateData}
                    handlePersonalStateData = {this.handlePersonalStateData}
                    handleSameAddress = {this.handleSameAddress}
                    sameAddressInformation = {this.state.sameAddressInfoData}
                    verifyPersonalHider={verifyPersonalHider}
                    verifyContactHider={verifyContactHider}
                    verifyPhoneHider={verifyPhoneHider}
                    verifyEmailHider={verifyEmailHider}
                    handleNextDisable={this.handleNextDisable}
                />
                )
            }
            case consts.MARKET_NAO_STEPS.EMP_FIN_MIL_INFO:{
                return(
                   this.renderEmploymentFinancialAndMilitary()
                )
            }
           default:{
            return (
                <MarketPersonalInfoCardComponent
                sendDOB={this.handleDOB}
                savedState={savedPerInfo}
                ref={this.primaryOwnerPerCardRef}
                checkStatus={this.checkStatus}
                personalInfo={personalInfomationLocalStateData}
                handlePersonalStateData = {this.handlePersonalStateData}
                verifyPersonalHider={verifyPersonalHider}
                verifyContactHider={verifyContactHider}
                verifyPhoneHider={verifyPhoneHider}
                verifyEmailHider={verifyEmailHider}
            />
            )
           }
        }

    }

    isSameAddress = (savedPerMailAddress, savedPhysicalAddress) => {
        if (savedPerMailAddress && savedPhysicalAddress) {
            const AddressLine1 = (savedPerMailAddress.addressLine1 == undefined || savedPerMailAddress.addressLine1 == "" || savedPerMailAddress.addressLine1 === savedPhysicalAddress.addressLine1 );
            const AddressLine2 = ( savedPerMailAddress.addressLine2 == undefined|| savedPerMailAddress.addressLine2 == "" ||savedPerMailAddress.addressLine2 === savedPhysicalAddress.addressLine2 );
            const city = (savedPerMailAddress.city ==undefined || savedPerMailAddress.city =="" || 
            savedPerMailAddress.city === savedPhysicalAddress.city );
            const state = (savedPerMailAddress.state ==undefined ||savedPerMailAddress.state =="" || 
            savedPerMailAddress.state === savedPhysicalAddress.state );
            const zip = (savedPerMailAddress.zipCode == undefined || savedPerMailAddress.zipCode == "" ||
            savedPerMailAddress.zipCode === savedPhysicalAddress.zipCode);
            return (AddressLine1 && AddressLine2 && zip && city && state);
        }
        return false
    };

    render() {
        const {
            showSaveModal = false,
            next,
            back,
            showAlert,
            errorMessage,
            loading,
            isErrorBar,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
            showAccountOpeningErrorModal,
            isValidMilitaryStartDate,
            perPhysicalAddressState,
            isUGMABeneAgeCheck,
            isNextDisbled,
            apiError,
            apiErrorMessage
        } = this.state;
			 const verifyAllPageHider= this.props.verifyPersonalHider|| this.props.verifyContactHider || this.props.verifyPhoneHider || this.props.verifyEmailHider || this.props.verifyJointPersonalHider || this.props.verifyJointContactHider || this.props.verifyJointPhoneHider || this.props.verifyJointEmailHider || this.props.verifyEmpHider || this.props.verifyFinHider || this.props.verifyMilHider || this.props.verifyJointEmpHider || this.props.verifyJointFinHider || this.props.verifyJointMilHider  
        const { personalInfoBackLabel,marketAccountOpeningPages,marketAccountOpeningPagesIncFunding } = AccGlobalConstants;
        const { localStateData, accOpeningData, profileInformationData,marketLocalStateData } = this.props;
        const { accountType } = marketLocalStateData?.marketNAOInfo?.account || {};
        const isUGMA = accountType && accountType.includes('UGMA');
     
        const nonMinorAge = UTMAStateWithAge18ForNonMinor.includes(perPhysicalAddressState) ? 18 : 21;
        const childBeneficiaryErrorMessage = `Child beneficiary for UGMA/UTMA account type cannot be more than or equal to ${nonMinorAge} years of age`;
       
        let savedEmpInfo = null;
        if (profileInformationData?.profileInformation?.employmentInformation) {
            const { employmentInformation } = profileInformationData.profileInformation;
            savedEmpInfo = this.getSavedEmpInfo(employmentInformation);
        }


        let savedFinInfo = accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.financialInfo;

        if (!savedFinInfo && profileInformationData?.profileInformation?.financialInformation) {
            const { financialInformation } = profileInformationData?.profileInformation || {};
            savedFinInfo = this.getSavedFinInfo(financialInformation);
        }
        const hasFundingStep = this.props.memberDashboardData.isEnableFeatureSuccess && this.props.memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false
        const pagesForStepper = hasFundingStep ? marketAccountOpeningPagesIncFunding : marketAccountOpeningPages;

        const isLoading = accOpeningData.isLoadingApplication ||  this.state.load

        return (
            <>
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {loading && <WSpinner loading={loading} />}



                {isLoading && <WSpinner loading={isLoading} />}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                        show={showConfirmModal}
                    />
                )}

                {apiError && (
                    <ConfirmModal
                    modalTitle={"Error"}
                    modalBodyText={apiErrorMessage}
                    primaryButtonText="OK"
                    onPrimaryClick={this.handleExitNew}
                />
                )}
                {
                    this.state.showNonResidentialPopUp ? 
                        <MessagePopup 
                        show={this.state.showNonResidentialPopUp} 
                        modalBodyText={consts.nonResidentError} 
                        secondaryButtonText="Ok"   
                        onSecondaryClick={this.handleCloseModal}  
                        primaryButtonText="" 
                        />

                        
                    :''
                }
                {showConfirmCancelModal && (
                    <ConfirmationMessage
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                        show={showConfirmCancelModal}
                    />
                )}
                {(accOpeningData.showCustomerVerificationModal ||
                    accOpeningData.showCustomerVerificationModalJoint1 ||
                    accOpeningData.showCustomerVerificationModalJoint2) && (
                    <ConfirmModal
                        modalTitle={AccGlobalConstants.CUSTOMER_VERIFICATION_MODAL_TITLE}
                        modalBodyText={accOpeningData.verifyCustomerDetailsErrMsg}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleVerifyCustomerDetailsOk}
                    />
                )}

                {showAccountOpeningErrorModal && !isUGMA && isValidMilitaryStartDate && (
                    <ConfirmModal
                        modalTitle={AccGlobalConstants.ACCOUNT_OPENING_NOT_ALLOWED_TITLE}
                        modalBodyText={AccGlobalConstants.ACCOUNT_OPENING_NOT_ALLOWED_ERROR_MESSAGE}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleAccountOpeningAllowed}
                    />
                )}

                {showAccountOpeningErrorModal && !isValidMilitaryStartDate && (
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={consts.MIL_DATE_ERROR}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleAccountOpeningAllowed}
                    />
                )}

                {showAccountOpeningErrorModal && isUGMA && !isUGMABeneAgeCheck && (
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={childBeneficiaryErrorMessage}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleAccountOpeningAllowed}
                    />
                )}

                <div className="indivitualAccTypePage">
                    <div
                        style={assignObj({
                            position: 'relative',
                            color: 'rgb(73, 73, 74)',
                            textAlign: 'left',
                            fontFamily: 'benton-sans',
                        })}
                    >
                        {showAlert ? (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        ) : null}
                        {
                         (accountType && accountType.includes('529')) &&
                        <div className="mobile-stepper mobile-stepinfo-container">
                            <h1 className="currentStep">
                                <span className="sr-only">Step 3 of 5 Personal Information</span>
                                <span aria-hidden="true">
                                    Step 3 of 5
                                    <span class="currentPage">
                                        Personal Information
                                    </span>
                                </span>
                            </h1>
                        </div>
                        }
                        <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        {accountType && !accountType.includes('529') ? (
                           verifyAllPageHider ?null:  <WStepperMobile currentPage={1} pages={pagesForStepper} />
                        ) : (
                            ''
                        )}
                        <div className="container mobile-cont accMngtContainer">
                           {verifyAllPageHider ?null:  <WBreadCrumb
                                activeCrumb={`${accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM : accountType}`}
                                breadCrumbItems={breadCrumURL}
                            />}

                          {verifyAllPageHider ?null:   <h2 style={pageHeaderStyle} className="retirementHeadading">
                                {`${accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM : accountType} Account`}
                            </h2>}

                            {accountType && !accountType.includes('529') && (
                               verifyAllPageHider ?null:  <WStepper className="desktopStepper" currentPage={1} pages={pagesForStepper} />
                            )}
                            {accountType && accountType.includes('529') && (
                               verifyAllPageHider ?null:  <WStepper className="desktopStepper" currentPage={2} pages={pages529} />
                            )}

                        {verifyAllPageHider ?null:<> <div className='banner' style={{backgroundColor: '#486D90',padding:"10px"}}>
                                <h6 style={{color:'white'}}>Step {this.state.pageIndex + 1} of {(accountType== MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)  ? 4 : 2}</h6>
                                <p style={{ color: 'white' }} className="mb-0">{(accountType === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT && this.state.pageIndex == consts.jointPersonalPageIndex || this.state.pageIndex == consts.jointPersonalEmploymentIndex) ? consts.jointHeaderNoteText : consts.individualHeaderNoteText}</p>
                            </div>
                            <span style={mandatoryFieldlabel} className="paraAln">
                                {consts.mandatoryText}
                            </span></>}
                            <div style={assignObj({ marginTop: 60 })} className="marketpersonalIfoFormWrapper">
                               
                                {(accountType == consts.ACCOUNTTYPES.JOINT) ? 
                                this.bindComponentForJointAccount() : 
                                this.bindComponent()}
                                {next ? <Redirect to={this.redirect()} /> : ''}
                           
                                {back ? <Redirect to={this.redirect()} /> : ''}
                            </div>
                        </div>
                    </div>
                    {verifyAllPageHider ?<CommonButtons
                        backClick={this.handleVerifyCancel}
                        nextstyle={this.state.temperarilyDisabled ? styles.disabledNextStyle : {}}
                        nextClick={this.state.temperarilyDisabled ? this.handleDisabledNextClick :this.handleVerifySave}
                        disabled={ isNextDisbled}
                        nextText="Save"
                        backText="Cancel"
                        isShowBtn={true}

                    />  :
                    <CommonButtons
                        nextClick={this.state.temperarilyDisabled ? this.handleDisabledNextClick : this.handleNextClick}
                        nextstyle={this.state.temperarilyDisabled ? styles.disabledNextStyle : {}}
                        disabled={ isNextDisbled}
                        backClick={this.handleBackClick}
                        arialabelback={personalInfoBackLabel}
                        cancelOffset={assignObj({
                            span: 2,
                            offset: 6,
                        })}
                        isShowBtn={true}
                    />}
                </div>
            </>
        );
    }
}

MarketPersonalInfoComponent.propTypes = {
    saveApplication: PropTypes.func,
    uploadAavatarImg: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    accOpeningData: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    getProfileBasicInformations: PropTypes.func,
    verifyCustomerDetails: PropTypes.func,
    setShowCustomerVerificationModal: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCognitoForCustomerVerification: PropTypes.func,
    clearLocalStateForNAOAccTypeChange: PropTypes.func,
};

MarketPersonalInfoComponent.defaultProps = {
    saveApplication: () => {},
    uploadAavatarImg: () => {},
    localStateData: {},
    initialState: {},
    manageLocalState: () => {},
    clearLocalStateForNAO: () => {},
    accOpeningData: {},
    getCustomerProfile: () => {},
    resetShowConfirmModal: () => {},
    history: {},
    profileInformationData: {},
    getProfileBasicInformations: () => {},
    verifyCustomerDetails: () => {},
    setShowCustomerVerificationModal: () => {},
    masterLookupStateData: {},
    getCognitoForCustomerVerification: () => {},
    clearLocalStateForNAOAccTypeChange: () => {},
};
export default MarketPersonalInfoComponent;
