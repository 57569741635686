import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions, localStateManagementActions, liveChatActions, analytics } from '../../../../shared/Actions';
import SignIn from './SignIn';

// console.log(loginActions)

export const mapStateToProps = state => ({
    liveChatReducerData: state.liveChatReducerData,
    loginState: state.loginData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...liveChatActions,
    ...loginActions,
    ...localStateManagementActions,
    ...analytics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
