const apiCallbackList = [];
const ApiEventHub = {
    trigger(eventName, data) {
        const apiEvents = apiCallbackList.filter(api => api.key?.toUpperCase() === eventName?.toUpperCase());
        apiEvents.forEach(apiEvent => {
            apiEvent.callBack(data);
        });
    },
    on(eventName, callBack) {
        apiCallbackList.push({
            key: eventName,
            callBack,
        });
    },
};

export default ApiEventHub;
