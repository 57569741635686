import React from 'react';
import styled from 'styled-components';
import {StyledButton} from 'common';
import closeIcon from 'assets/closeIcon.svg';

const Flex = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
`;
const CriteriaDiv = styled.div.attrs(props => ({
    tabIndex: 0,
}))`
    background: #004A98 0% 0% no-repeat padding-box;
    border-radius: 0.25rem;
    width: 9.0625‬rem;
    height: 2.125‬rem;
    display: flex;
    align-items: center;
    margin: 0 1rem 0.5rem 0;
    &:focus {
        outline: 3px solid blue;
    }
`
const CancelButton = styled.div`
    display: flex;
    padding: 0.5rem 1.125rem 0.5rem 1.5625rem;
    opacity: 1;
    text-align: center;
    letter-spacing: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    cursor: default;
`;
const ImgButton = styled(StyledButton)`
    margin-left: 0.625rem;
    &:focus: {
        outline: 1px solid blue;
    }
`;

const Icon = styled.img`
    width: 1.13625rem;
    height: 1.13625rem;
    cursor: pointer;
`;

const ShowFilterCriteria = props => {
    const {filterList, removeFilterCriteriaHandler} = props;
     
    return (
        <>
            <hr/>
            <Flex>
            { filterList.map((item,index) => (
                <CriteriaDiv key={index} aria-label={item}>
                    <CancelButton>
                        <span>{item}</span>
                        <ImgButton tabIndex='0' onClick={removeFilterCriteriaHandler(item)}>
                            <Icon src={closeIcon} alt={`${item} close Icon`}/>
                        </ImgButton>
                    </CancelButton>
                </CriteriaDiv>  
            ))}
            </Flex>
        </>
    )
}

export default ShowFilterCriteria;