import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getAddressAsLines } from 'modules/CustomerManagementModule/utils';

const StyledLine1 = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #49494A;
    margin: 0;
`;

const StyledLine2 = styled(StyledLine1)`
    font-weight: 500;
`;

const StyledEmptyValue = styled(StyledLine1)`
    font-weight: 500;
    line-height: 24px;
`;

const NONE_ON_FILE = 'None On File';

const AddressView = (props) => {
    const { addressLine1, addressLine2, city, state, zip } = props;
    
    let content;
    if(addressLine1 || addressLine2 || city || state || zip){
        const { line1, line2, line3, line4 } = getAddressAsLines(props);
        content = <>
                    {line1 && <StyledLine1>{line1}</StyledLine1>}
                    {line2 && <StyledLine2>{line2}</StyledLine2>}
                    {line3 && <StyledLine2>{line3}</StyledLine2>}
                    {line4 && <StyledLine2>{line4}</StyledLine2>}
                </>
    }else{
        content = <StyledEmptyValue>{NONE_ON_FILE}</StyledEmptyValue>
    }
    return content;
};

export default AddressView;

AddressView.propTypes = {
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
}

AddressView.defaultProps = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
}
