import { connect } from 'react-redux';
import { updateProfilePhoneInformations, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { analyzeFormFieldError, analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import AddPhoneInfoComponent from './AddPhoneInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData } = state;
    const { isError } = profileInformationData;
    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    updateProfilePhoneInformations,
    clearExistingActions,
    analyzeFormFieldError,
    analyzeClickEvent,
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(AddPhoneInfoComponent));
