/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import styled from 'styled-components';
import { analyzeClickEvent } from '../../shared/Actions/AnalyticsActions';
import styles from '../CommonStyles';
import '../../App.css';
// import { Link } from 'react-bootstrap/lib/Navbar';

const StyledBreadCrumb = styled(Breadcrumb)`
    a {
        font: 600 12px/14px benton-sans;
        color: #004a98;
    }

    a:hover {
        text-decoration: none;
    }

    .breadcrumb li {
        line-height: 20px;
        :not(.active) {
            text-decoration: underline;
        }
    }

    .breadcrumb {
        background: none;
        padding: 17px 0 0;
        margin-bottom: 40px;
        align-items: center;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: '';
        width: 0.31rem;
        height: 0.31rem;
        border-right: 1px solid #56565a;
        border-top: 1px solid #56565a;
        transform: rotate(45deg);
        margin-right: 0.7rem;
        margin-top:0.5rem;
        padding-right: 0;
        color: #56565a;
        position: relative;
        align-self: center;
    }
`;

const linkStyle = {
    color: '#004A98',
    opacity: 1,
    backgroundColor: 'white',
    border: 'none',
    font: '600 12px/14px benton-sans',
    textDecoration: 'underline',
};

const activeCrumbStyle = {
    textDecoration: 'none',
    color: '#56565A',
    font: '600 12px/20px benton-sans',
};

const assignObj = obj => obj;

const WBreadCrumb = props => {
    const { breadCrumbItems, activeCrumb, paramsRequired, onClick } = props;
    const dispatch = useDispatch();
    const analyzeClick = (e, url) => {
        if(onClick && typeof onClick === "function") onClick(url);
        
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
            }),
        );
    };
    const breadCrumLinkId = name => {
        return `bread-crum-link-${name}`;
    };

    return (
        <div id="breadcrumb">
            <StyledBreadCrumb aria-label="Breadcrumb">
                {breadCrumbItems.map((data, index) => {
                    const addHyphen = (data.name).replace(/\s+/g, '-').toLowerCase();
                    return (
                        <BreadcrumbItem
                            id={breadCrumLinkId(addHyphen)}
                            onClick={(e)=>analyzeClick(e,data.url)}
                            key={Number(index)}
                            style={styles.title}
                            data-analytic-id={data.dataAnalyticId}
                        >
                            {/* <a href={data.url} >{data.name}</a> */}

                            {paramsRequired ? (
                                <Link
                                    style={linkStyle}
                                    to={assignObj({
                                        pathname: data.url,
                                        name: data.name,
                                        ...data.payload,
                                    })}
                                >
                                    {data.name}
                                </Link>
                            ) : (
                                <Link
                                    to={assignObj({
                                        pathname: data.url,
                                        ...data.payload,
                                    })}
                                    style={linkStyle}
                                >
                                    {data.name}
                                </Link>
                            )}

                            {/* {redirect ? <Redirect to={data.url} /> : ''} */}
                        </BreadcrumbItem>
                    );
                })}
                <BreadcrumbItem active>
                    
                    <span style={activeCrumbStyle}>{activeCrumb}</span>
                </BreadcrumbItem>
            </StyledBreadCrumb>
        </div>
    );
};

WBreadCrumb.propTypes = {
    breadCrumbItems: PropTypes.instanceOf(Object),
    activeCrumb: PropTypes.string,
    paramsRequired: PropTypes.bool,
    dataAnalyticId:PropTypes.string,
    onClick: PropTypes.func
};

WBreadCrumb.defaultProps = {
    breadCrumbItems: [],
    activeCrumb: 'Test',
    paramsRequired: false,
    dataAnalyticId:"",
    onClick: () => {}
};

export default WBreadCrumb;
