import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

class AEM extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            htmlData: '',
            isLoading: true,
            template: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let pageid = this.props.match.params.pageid;
        console.log(pageid);
        //pageid = 'advice-tools/faq';
        //https://vcm-dev-aem65.adobecqms.net/member/advice-tools/faq.html
        //pageid = 'products/mutual-funds';
        //pageid = 'about-victory/corporate-responsibility';
        console.log(pageid);
        //fetch('https://cors-anywhere.herokuapp.com/http://qa.vcm.com/member/products.headless.html', {
        //fetch('https://cors-anywhere.herokuapp.com/http://qa.vcm.com/member/about-victory.headless.html', {
        //http://qa.vcm.com/member/about-victory.headless.html
        //http://qa.vcm.com/member/products.headless.html
        fetch('https://z5ij5kanck.execute-api.us-east-2.amazonaws.com/dev/htmltojson', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pageid: pageid,
            }),
        })
            .then(response => response.json())
            .then(htmlData => {
                console.log(htmlData);
                if (this._isMounted) {
                    this.setState({
                        htmlData: {
                            ...htmlData,
                        },
                    });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    console.log('error');
                    console.log(error);
                    this.setState({
                        htmlData: {
                            template: 'Something bad happened ' + error,
                        },
                    });
                }
                console.log('error');
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.htmlData === '') {
            return <div>Loading....</div>;
        } else {
            console.log(this.state.htmlData.template);
        }
        console.log(this.state.htmlData.template);
        return (
            <div>
                <Helmet>
                    {this.state.htmlData.link.map((url, index) => {
                        return <link type="text/css" href={url} key={`style-${index}`} />;
                    })}
                    {this.state.htmlData.script.map((url, index) => {
                        return <script type="text/javascript" key={`script-${index}`} src={url}></script>;
                    })}
                </Helmet>
                <div>{ReactHtmlParser(this.state.htmlData.template)}</div>
                {/* <div dangerouslySetInnerHTML={{ __html: this.state.htmlData.template }} /> */}
            </div>
        );
    }
}

export default withRouter(AEM);
