import React,{useState,useEffect} from 'react'
//import MarketPersonalInfo from '../MarketPersonalInfo/MarketPersonalInfo'
import AccountFeatures from './AccountFeatures/AccountFeatures'
import EContest from './EContest'
import VerifyInfo from './MarketVerifyPage/VerifyInfo'
import { WBreadCrumb, WStepper } from 'common';
import AccGlobalConstants from '../AccountManagementConstants';
import consts from '../MarketAccountType/consts';
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"; 
import MarketPersonalInfo from '../MarketPersonalInfoComponent/MarketPersonalInfoComponent';
import { useSelector } from 'react-redux';
import Regulatory from './Regulatory/Regulatory'
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts'
import AccountFundingPage from './AccountFunding/AccountFundingPage';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/marketOpenAccount', name: 'Open a Marketplace Account' },
  ];
  const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/marketOpenAccount', name: 'Open a Marketplace Account' },
  ];
const MarketNAOFlow = () => {

  const history = useHistory();

  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
  const memberDashboardData = useSelector(state => state.memberDashboardData);
  const accountTypeSelected = marketNAOInfoReducerData.marketNAOInfo.account.accountType
    const [pageIndex, setPageIndex] = useState(history.location.state?.redirectToPage != undefined ? history.location.state.redirectToPage : 2)
    const [isButtonType, setisButtonType] = useState('next')
    const location = useLocation()
    const activeAccountSelected = accountTypeSelected
     const [isEdited, setIsEdited] = useState(false) 
     const [isEditedIRA, setIsEditedIRA] = useState(false) 
     const[IsEditedJointReg,setIsEditedJointReg]= useState(false) 
     const accountSelected= sessionStorage.getItem("accountSelected")
     const [subAHPageIndex, setAHsubPageIndex] = useState('')
     const isRedirectionFlow = history.location.state?.redirectToPage != undefined ? true : false
     const [regulatoryPageIndex, setRegulatoryPageIndex] = useState(0)
     const hasFundingStep = memberDashboardData.isEnableFeatureSuccess && memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false

    useEffect(() => {
        //isEdited ?null:null 
    }, [isEdited])

       useEffect(() => {
      //  isEditedIRA?null:null 
    }, [isEditedIRA])
           useEffect(() => {
       // setIsEditedJointReg?null:null 
    }, [IsEditedJointReg])
    // Switch between components
    const onSwitchComponent = bool => {
      const totalNoOfSteps = hasFundingStep ? 7 : 6

      if(pageIndex >= 0 && pageIndex < totalNoOfSteps){ 
        // bool true for next and false for back
        if(bool){
            setisButtonType('next')
           setPageIndex(pageIndex + 1);
        }else{
            setisButtonType('back')
           setPageIndex(pageIndex - 1);
        }
        } else{
         const pageIndexValue = pageIndex; 
         setPageIndex(pageIndexValue);
      }
    };

    // on next button click
    const onNextClick = () => {
      onSwitchComponent(true)
    }

    // on back button click
    const onBackClick = () => {
        onSwitchComponent(false)

    }

  const onSubmit=()=>{
    history.replace("/ConfirmationFile")
  }


  useEffect(() => {  
    const selectedSection = marketNAOInfoReducerData?.marketNAOInfo?.moveToSection != undefined ? marketNAOInfoReducerData?.marketNAOInfo?.moveToSection.selectedSection : ''
    const selectedSubPage = marketNAOInfoReducerData?.marketNAOInfo?.moveToSection != undefined ? marketNAOInfoReducerData?.marketNAOInfo?.moveToSection.selectedSubPage : ''
    let modelState = JSON.parse(JSON.stringify(marketNAOInfoReducerData.marketNAOInfo));

    if( marketNAOInfoReducerData?.marketNAOInfo?.moveToSection != undefined && location.state?.savedApplicationRedirection != undefined && location.state.savedApplicationRedirection == true){

      if(selectedSection == 'Regulatory' && selectedSubPage == 'joint'){
        setRegulatoryPageIndex(1)

      }else{
        onEditButtonClick(selectedSection,selectedSubPage)
      }

      modelState.moveToSection = undefined
      // dispatch(marketUpdateLocal(modelState))
    }
  }, [])

  const onEditButtonClick = (selectedSection,subPage) => {

    if(selectedSection == 'Regulatory'){
         setPageIndex(2);
      if(subPage == 'primary'){
        setIsEditedJointReg(false)

      }else{

        setIsEditedJointReg(true)
      }

    }else if(selectedSection == 'AccountFeatures'){
      setPageIndex(3);
      setAHsubPageIndex(subPage); 
    }else if(selectedSection == 'AccountFunding'){
      setPageIndex(4);
    }else if(selectedSection == 'VerifyInfo'){
      setPageIndex(hasFundingStep ? 5 : 4);
    }else{
      // setPageIndex(2);
    }


  }

    // loop through components/Steps 
    const bindComponent = () => {
      // const page = 4
      if(hasFundingStep){
      switch (pageIndex) {
              case 0: {
                return (
                  <></> );
              }
              case 1: {
                return (
                  <MarketPersonalInfo isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} onEdited={isEdited} />
                );
              }
              case 2: {
                return (
                  <Regulatory regulatoryPageIndex={regulatoryPageIndex} isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} onEditedJointReg={IsEditedJointReg} onEditJointReg={(x)=>setIsEditedJointReg(x)}/>
                )
              } 
              case 3: {
                return (
                    <AccountFeatures isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} subAHPageIndex={subAHPageIndex} setAHsubPageIndex={setAHsubPageIndex}  isIRAAccountSelected={accountTypeSelected.includes('IRA')} onEdited={isEdited} onEditedIRA={isEditedIRA}  onEditIRA={(x)=>setIsEditedIRA(x)}  onEdit={(x)=>setIsEdited(x)} />
                )
              } 
              case 4: {
                return (
                    <AccountFundingPage onNextClick={onNextClick} onBackClick={onBackClick} onEditButtonClick={onEditButtonClick} />
                )
              } 
              case 5: {
                return (
                    <VerifyInfo isRedirectionFlow={isRedirectionFlow} isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} setAHsubPageIndex={setAHsubPageIndex} rootPageIndex={pageIndex} EditRoute={(pageIndex)=>setPageIndex(pageIndex)} onEditJointReg={(x)=>setIsEditedJointReg(x)} onEdit={(x)=>setIsEdited(x)} onEditIRA={(x)=>setIsEditedIRA(x)} onEditButtonClick={onEditButtonClick} />
                )
              } 

             
              default: {
                  return <React.Fragment></React.Fragment>;
              }
          
            }
        }else{

      
          switch (pageIndex) {
                  case 0: {
                    return (
                      <></> );
                  }
                  case 1: {
                    return (
                      <MarketPersonalInfo isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} onEdited={isEdited} />
                    );
                  }
                  case 2: {
                    return (
                      <Regulatory regulatoryPageIndex={regulatoryPageIndex} isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} onEditedJointReg={IsEditedJointReg} onEdited={isEdited} onEditJointReg={(x)=>setIsEditedJointReg(x)}/>
                    )
                  } 
                  case 3: {
                    return (
                        <AccountFeatures isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} subAHPageIndex={subAHPageIndex} setAHsubPageIndex={setAHsubPageIndex} isIRAAccountSelected={accountTypeSelected.includes('IRA')} onEdited={isEdited} onEditedIRA={isEditedIRA}  onEditIRA={(x)=>setIsEditedIRA(x)}  onEdit={setIsEdited} />
                    )
                  } 
                  case 4: {
                    return (
                        <VerifyInfo isRedirectionFlow={isRedirectionFlow} isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick}  setAHsubPageIndex={setAHsubPageIndex} rootPageIndex={pageIndex} EditRoute={(pageIndex)=>setPageIndex(pageIndex)} onEditJointReg={(x)=>setIsEditedJointReg(x)} onEdit={(x)=>setIsEdited(x)} onEditIRA={(x)=>setIsEditedIRA(x)} onEditButtonClick={onEditButtonClick} />
                    )
                  } 
    
                
                  default: {
                      return <React.Fragment></React.Fragment>;
                  }
              }
            }
    }
      
      
    


  return (
    <div className='container MarketNAOFlow'>
    
    <WBreadCrumb 
        activeCrumb={activeAccountSelected === MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT_FULL_FORM : activeAccountSelected}
        
        // breadCrumbItems={role === 'Member' ? breadCrumURL : breadCrumbURLGuest}
        breadCrumbItems={true ? breadCrumURL : breadCrumbURLGuest}
    />

    <WStepper className="desktopStepper" style={consts.stepperStyle} currentPage={pageIndex} pages={ hasFundingStep ? AccGlobalConstants.marketAccountOpeningPagesIncFunding : AccGlobalConstants.marketAccountOpeningPages} />
        {bindComponent()}
    </div>
  )
}

export default MarketNAOFlow
