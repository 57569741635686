import styled from 'styled-components';

// Component to remove the default button style
const StyledButton = styled.button`
    padding: 0;
    border: none;
    background: none;
`;

export default StyledButton;
