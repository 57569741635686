import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import Config from 'env/Config';
import { post, get, setAuthHeaders, getWithAPIKey, remove, postWithXAPIKey, postUrlEncoded, setSessionToPlaylod } from './Services';

const AccountOpeningApiEvents = () => {
    ApiEventHub.on(ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.PREFERENCE_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.VERIFY_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.ESIGN_SAVE_OPENING_ACCT, data => {
        post(Config.saveappdata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.SUBMIT_OPENING_ACCT, data => {
        post(
            Config.openaccountInd,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SUBMIT_IRA_OPENING_ACCT, data => {
        post(Config.openaccountIRA, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.SUBMIT_JOINT_OPENING_ACCT, data => {
        post(Config.openaccountJoint, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.SUBMIT_UTMA_OPENING_ACCT, data => {
        post(Config.openaccountUTMA, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.RETRIVE_OPENING_ACCT, data => {
        post(Config.getsaveddata, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.GET_FUNDLIST, data => {
        post(Config.getfunds, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.GET_FUNDDETAILS, data => {
        const options = {
            headers: {
                'x-api-key': Config.loggerApiKey,
            },
            transformRequest: (data1, headers) => {
                // eslint-disable-next-line no-param-reassign
                delete headers.common.Authorization;
                delete headers.common.vcmid;                
            },
        };

        getWithAPIKey(
            Config.getfunddetails,
            undefined,
            data.endPointURL.BasePath,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.VALIDATE_BANK_ACCOUNT, data => {
        post(
            Config.bankAccVerification,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.ADD_BANK_ACCOUNT, data => {
        post(Config.validateBankAccount, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.GET_STATECITY, data => {
        const options = {
            headers: { 'x-api-key': Config.uspsApiKey },
        };
        post(
            Config.cityStateLookUp,
            data.payload,
            data.endPointURL.USPS,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.GET_ADDRESSFORMAT, data => {
        const options = {
            headers: { 'x-api-key': Config.uspsApiKey },
        };
        post(
            Config.addressLookUp,
            data.payload,
            data.endPointURL.USPS,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED, data => {
        const options = {
            headers: { 'x-api-key': Config.uspsApiKey },
        };
        post(
            Config.multipleAddressLookUp,
            data.payload,
            data.endPointURL.USPS,
            data.successCallBack,
            data.errorCallback,
            options,
        );
    });

    ApiEventHub.on(ActionTypes.UPLOAD_AVATAR, data => {
        post(Config.uploadImage, data.payload, data.endPointURL.UPLOAD_IMAGE, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.UPLOAD_W8FORM, data => {
        post(Config.uploadImage, data.payload, data.endPointURL.UPLOAD_IMAGE, data.successCallBack, data.errorCallback);
    });
    ApiEventHub.on(ActionTypes.GET_AVATAR, data => {
        get(
            Config.retrieveImage,
            data.payload,
            data.endPointURL.RETRIVE_IMAGE,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_SAVED_APPLICATIONS, data => {
        setAuthHeaders(data.payload.token);
        get(
            // `${Config.getFundAccountsListForNAO}?companyNumber=${data.payload.companyNumber}&accountType=${data.payload.accountType}&state=${data.payload.state}&socialCode=${data.payload.socialCode}&accountTypeCode=${data.payload.accountTypeCode}`,
            Config.savedApplicationDismiss,
            undefined,
            data.endPointURL.SAVED_APPLICATIONS,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_APPLICATION, data => {
        setAuthHeaders(data.payload.token);
        get(
            `${data.payload.uuid}`,
            // Config.getSavedApplications,
            undefined,
            data.endPointURL.SAVED_APPLICATIONS,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SAVE_APPLICATION, data => {
        setAuthHeaders(data.payload.token);
        post(
            `${Config.saveApplication}`,
            data.payload.saveApplicationPayload,
            data.endPointURL.SAVED_APPLICATIONS,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DELETE_APPLICATION, data => {
        setAuthHeaders(data.payload.token);
        remove(
            `${data.payload.uuid}`,
            undefined,
            data.endPointURL.SAVED_APPLICATIONS,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION, data => {
        setAuthHeaders(data.payload.token);
        get(
            `${Config.getMarstoneRedirectionData}/${data.payload.sessionId}`,
            undefined,
            data.endPointURL.getMarstoneRedirectionData,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME, data => {
        const payLoad = {
            msrid:"",
            vcmid:"",
            fismemberid:"",
            token:""
        }
        setSessionToPlaylod(payLoad)
        postWithXAPIKey(
            `${Config.getDefaultAccountNickName}`,
            data.payload,
            data.endPointURL.getDefaultAccountNickName,
            data.successCallBack,
            data.errorCallback,
            Config.accountDefaultNickNameApiKey,
        );
    });

    ApiEventHub.on(ActionTypes.VERIFY_CUSTOMER_DETAILS, data => {
        setAuthHeaders(data.payload.token);
        postWithXAPIKey(
            Config.validateCustomerInfo,
            data.payload.customerData,
            data.endPointURL.validateCustomerInfo,
            data.successCallBack,
            data.errorCallback,
            Config.verifyCustomerApiKey,
        );
    });

    ApiEventHub.on(ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION, data => {
        postUrlEncoded(
            `${Config.getCognitoForGIACTVerification}`,
            data.payload,
            `${data.endPointURL.getCognitoForGIACTVerification}`,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.REMOVE_SAVED_ITEM, data => {
        let payload = {
            msrid: "",
            vcmid: "",
            fismemberid: "",
            token: "",
        }
        setSessionToPlaylod(payload)
        post(
            `${Config.savedApplicationDismiss}`,
            data.payload,
            data.endPointURL.SAVED_APPLICATIONS,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default AccountOpeningApiEvents;
