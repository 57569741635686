import React, { useState, useEffect } from "react";
import { Modal, Button, Form,} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from 'axios'
import { setTokenWithExpiry } from './session';
import { encrypt } from "utils";
import { WSpinner } from '../../common';
import envConfig from "env/EnvConfig";
const StyledButton = {
  background: '#486D90 0% 0% no-repeat padding-box',
  width: '160px',
  height: '50px',
  position: 'relative',
  textAlign: 'center',
  fontFamily: 'yorkten-slab-normal, sans-serif',
  fontWeight: '800',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
}


const cancelButton = {
  background: '#D2D2D2 0% 0% no-repeat padding-box',
  width: '160px',
  height: '50px',
  position: 'relative',
  textAlign: 'center',
  fontFamily: 'yorkten-slab-normal, sans-serif',
  fontWeight: '800',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0px',
  color: '#49494A',
  opacity: '1',
  marginRight: '20px'
}



async function encryptFisPassword(password) {
  return encrypt(password);
}

export default function Authenticate({ showAuthBox, moveNextPage }) {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState({ isError: true, errorMsg: '' });

  //   const paramsLocal={accesstoken:'', vcmid:'', fismemberid:'',msrid:'' }
  //   const { accesstoken, vcmid, fismemberid,msrid } = paramsLocal;

  //   useEffect(() => {
  //     const msrInfo = sessionStorage.getItem('msr_dts');
  //     const params = JSON.parse(msrInfo);
  //     paramsLocal = params;

  //   }, [])

  const handleClose = () => {
    setUsername('')
    setPassword('')
    setShow(false)
    setError(prevState => ({ ...prevState, isError: false, errorMsg: '' }));
    moveNextPage('closed')
  }

  const handleShow = () => setShow(true);

  useEffect(() => {
    showAuthBox && handleShow()
  }, [showAuthBox])

  const serviceCall = async () => {
    try {
      setLoading(true);
      const payload = {
        userName: username,
        password: await encryptFisPassword(password)
      }
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': `${envConfig.TRANSACTION_API_KEY}`,
      }
      const response = await axios.post(`${envConfig.API_SUB_DOMAIN_MSR}/trance/msr/authtoken`, payload, { headers: headers })

      if (response && response.data && response.data.message === 'Success' && response.data.result) {
        let resResult = response.data.result;
        const key = 'msr_auth'
        setTokenWithExpiry(key, resResult, 3600000) //60k, valid for 1min 
        setLoading(false);
        setShow(false)
        moveNextPage()
      } else {
        setLoading(false);
        setShow(true)
        setError(prevState => ({ ...prevState, isError: true, errorMsg: "Error: Invalid MSR credential" }));

      }

    }
    catch (error) {
      setLoading(false);
      setShow(true)
      setError(prevState => ({ ...prevState, isError: true, errorMsg: "Error: Invalid MSR credential" }));
    }
  }



  const onLoginFormSubmit = (e) => {
    e.preventDefault();

    serviceCall()

    if (error.isError) {

      setError(prevState => ({
        ...prevState,
        errorMsg: ''
      }));

    } else {
      setUsername('')
      setPassword('')
      setError(prevState => ({ ...prevState, isError: false, errorMsg: '' }));
      handleClose();
    }

  };


  return (
    <>
      {isLoading && (
        <WSpinner
          aria-busy="true"
          aria-live="polite"
          aria-label="Please wait while we load more data"
          loading={isLoading}
        />
      )}

      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Please enter your FISTA account details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={onLoginFormSubmit}>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>


            {error.isError ? <span style={{ display: 'block', color: 'red', marginBottom: '20px' }}>{error.errorMsg}</span> : null}

            <Button variant="secondary" onClick={handleClose} style={cancelButton} >Close</Button>
            <Button variant="primary" type="submit" style={StyledButton}>Submit</Button>

          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
