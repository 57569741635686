import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PortfolioPlannerPdf from './PortfolioPlannerPdf';
import { documentPreferenceActions,analytics ,downloadFileActions } from 'shared/Actions';
import * as DocumentCenterAction from '../../../shared/Actions/DocumentCenterActions';

const mapStateToProps = state => ({
    dashboardDocumentData :  state.documentPreferenceData,
    downloadedFileData: state.downloadedFileData,
    documentPreferenceData: state.documentPreferenceData,
});

const mapDispatchToProps = {
    ...documentPreferenceActions,
    ...analytics,
    ...downloadFileActions,
    ...DocumentCenterAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioPlannerPdf));
