import Config from 'env/Config';
import throwIfMissing from '../Helpers/HandleResponse';

import ApiEventHub from './ApiEventHub';
/* **************************\
Function: makeRequest
Explanation:
Making Request for the given type
Type will identify the end url based on the env
will have success and failure callback to handle the respective actions
\*************************** */

// MockAxios.mockData(); // Commenting this code as other api's are not getting called in other modules.
const makeApiRequest = function makeApiRequest(
    type = throwIfMissing('Action Type'),
    payload,
    env,
    successCallBack = throwIfMissing('Success Callback'),
    errorCallback = throwIfMissing('Error Callback'),
) {
    const endPointURL = Config.API_BASE_URL;
    ApiEventHub.trigger(type, {
        payload,
        endPointURL,
        successCallBack,
        errorCallback,
    });
};

export default makeApiRequest;
