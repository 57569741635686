import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import { loginActions, localStateManagementActions, analytics} from '../../../../shared/Actions';
import PersonalDetails from './PersonalDetails';



export const mapStateToProps = (state /* , props */) => (
    {
      loginState: state.loginData,
      personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
      registerState: state.localStateManagementReducerData.registerState
    }
  );

const mapDispatchToProps = {
     ...localStateManagementActions,
     ...loginActions,
     ...analytics
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails));