import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
    WBreadCrumb,
    VCMSelect,
    VCMDatePicker,
    WSpinner,
} from 'common';
import { formatDate, isValidDate, encrypt, decrypt } from 'utils';
import routeConstants from 'routeConstants';
import { BottomNavBar, InstructionalPanel, FieldValue, InputText, PageTitle, Toggle } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/MilitaryInformation/constants';
import {
    getKeyfromValue,
    getMetadataValues,
    getFieldValue,
    hasValueChanged,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const StyledHeading = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin-bottom: 40px;
`;

const primaryBtnProps = {};
const cancelBtnProps = {};

export default class MilitaryInfoComponent extends Component {
    constructor(props) {
        super(props);
        
        const { profileInformationData } = props;
        const { profileInformation } = profileInformationData || {};
        const { militaryInformation } = profileInformation || {};
        const { isCurrentlyServing = false} = militaryInformation || {};
        
        this.state = {
            isCurrentlyServing,
            apiFields: {},
        };
    }

    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        const payLoad = this.getLookUpPayload();
        if(payLoad.length) getCompositeLookUpData(payLoad);
        addFormAbandonUnloadListner(true);
    }

    componentDidUpdate() {
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, location, analyzeClickEvent, linkResponseError } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        const { pathname } = location;
        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_managemilitaryInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_managemilitaryInfo_save`, analyzeClickEvent);
            history.push(routeConstants.basicinformation, {from: pathname});
        }
    }

    componentWillUnmount(){
        const { profileInformationData } = this.props;
        const { isSuccess } = profileInformationData;
        if(!isSuccess){
            const digitalData = getFormAbandData(true);
            const { fieldlasttouch } = digitalData;
            if (fieldlasttouch !== 'none') trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            mil_serv_branch: militarybranch,
            mil_status: militaryStatus,
            mil_rank_01: militaryRankList1,
            mil_rank_02: militaryRankList2,
            mil_rank_03: militaryRankList3,
            mil_rank_04: militaryRankList4,
            mil_rank_05: militaryRankList5,
        } = masterLookupStateData;
  
        const payLoad = [];
        if (!militarybranch) payLoad.push('mil_serv_branch');
        if (!militaryStatus) payLoad.push('mil_status');
        if (!militaryRankList1) payLoad.push('mil_rank_01');
        if (!militaryRankList2) payLoad.push('mil_rank_02');
        if (!militaryRankList3) payLoad.push('mil_rank_03');
        if (!militaryRankList4) payLoad.push('mil_rank_04');
        if (!militaryRankList5) payLoad.push('mil_rank_05');
  
        return payLoad;
    };

    submitMilitaryInfo = () => {
        const { apiFields={} } = this.state;
        const { updateProfileMilitaryInformations, profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { militaryInformation } = profileInformation || {};       
        const isCurrentlyServing = getFieldValue(apiFields, militaryInformation, 'isCurrentlyServing');
        const { 
            branchOfService='',
            militaryStatus='',
            rank='',
         } = apiFields;
        (async () => {
            const reqPayload = {...apiFields};
        if(!isCurrentlyServing){
            reqPayload.militaryStatus = '';
            reqPayload.otherMilitaryStatus = '';
            reqPayload.branchOfService = '';
            reqPayload.otherMilitaryBranchOfService = '';
            reqPayload.rank = '';
            reqPayload.fromDate = '';
            reqPayload.toDate = '';
            reqPayload.commissionSource = '';
            updateProfileMilitaryInformations(reqPayload, decrypt);
        }else {
            const reqPayloadMilServing = {...apiFields};
            if(branchOfService){
                const branchOfServicesnew = await encrypt(branchOfService);
                reqPayloadMilServing.branchOfService = branchOfServicesnew;
            }
            if(militaryStatus){
                const militaryStatusnew = await encrypt(militaryStatus);                
                reqPayloadMilServing.militaryStatus = militaryStatusnew;
            }
            if(rank){
                const ranksnew = await encrypt(rank);
                reqPayloadMilServing.rank = ranksnew;
            }        
            updateProfileMilitaryInformations(reqPayloadMilServing, decrypt);
        }
        })();
    }

    handleChange = (fieldName) => (e) => {
        const { apiFields } = this.state;
        const fieldVal = e.list.find((val) => val.key === e.target.value);
        apiFields[fieldName.toString()] = fieldVal.value;
        if(fieldName === 'branchOfService' && fieldVal.value === 'Other'){
            apiFields.rank='';
        }
        this.setState({
            apiFields,
        });
    };

    handleInputChange = (fieldName) => (e) => {
        const { target } = e;
        const { value } = target;
        const { apiFields } = this.state;

        apiFields[fieldName.toString()] = value.replace(/[^A-Za-z\s]/gi, '').replace(/  +/g, ' ');
        this.setState({
            apiFields,
        });
    };

    handleDateChange = (fieldName) => (e) => {
        const { target } = e;
        const { value } = target || {};
        const { apiFields } = this.state;

        apiFields[fieldName.toString()] = value;
        this.setState({
            apiFields,
        });
    };

    onSwitchClick = () => {
        this.setState((prevState) => {
            const { apiFields, isCurrentlyServing } = prevState;
            apiFields.isCurrentlyServing = !isCurrentlyServing;
            return {
                isCurrentlyServing: !isCurrentlyServing,
                apiFields,
            }
        });
    };

    getRankData = (rankDataKey) => {
        const { masterLookupStateData } = this.props;
        const {[rankDataKey]:rankData} = masterLookupStateData || {};
        const { value: rankDataValues } = rankData || {};
        return rankDataValues || [];
    };
    
    getRankDataKey = (branchOfService) => {
        const { masterLookupStateData } = this.props;
        const militaryBranchKey = getKeyfromValue(masterLookupStateData, 'mil_serv_branch',branchOfService);
        return `mil_rank_${militaryBranchKey}`;
    };
    
    getMasterDataValues = () => {
        const { masterLookupStateData } = this.props;
        const branchData = getMetadataValues(masterLookupStateData, 'mil_serv_branch');
        const militaryStatusData = getMetadataValues(masterLookupStateData, 'mil_status');
        return { branchData, militaryStatusData };
    }

    validateServiceDate = (apiFields, militaryInformation, isFromDateChanged, isToDateChanged) => {
        const serviceToDate = getFieldValue(apiFields, militaryInformation, 'toDate');
        const serviceFromDate = getFieldValue(apiFields, militaryInformation, 'fromDate');
        
        const fromDateFormatted = new Date(serviceFromDate);
        const toDateFormatted = new Date(serviceToDate);

        let isServiceDateValid = true;
        const isFromDateError = serviceToDate === 'Error';
        const isToDateError = serviceFromDate === 'Error';
        
        if(isFromDateError || isToDateError) isServiceDateValid = false;
        
        if( isFromDateChanged && serviceToDate &&
            fromDateFormatted > toDateFormatted
        ){
            isServiceDateValid = false;
        }
        
        if( isToDateChanged && serviceFromDate &&
            toDateFormatted < fromDateFormatted
        ){
            isServiceDateValid = false;
        }
        return isServiceDateValid;
    }
    
    isAnythingChanged = () => {
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { militaryInformation } = profileInformation || {};    
        const { apiFields } = this.state;
        
        const isCurrentlyServingValue = getFieldValue(apiFields, militaryInformation, 'isCurrentlyServing');
        const isServingChanged = hasValueChanged(apiFields, militaryInformation, "isCurrentlyServing");
        if(!isCurrentlyServingValue) return isServingChanged;

        const isMilitaryStatusChanged = hasValueChanged(apiFields, militaryInformation, "militaryStatus");
        const isRankChanged = hasValueChanged(apiFields, militaryInformation, "rank");
        const isFromDateChanged = hasValueChanged(apiFields, militaryInformation, "fromDate");
        const isToDateChanged = hasValueChanged(apiFields, militaryInformation, "toDate");
        const isCommissionSourceChanged = hasValueChanged(apiFields, militaryInformation, "commissionSource");
        const isBranchOfServiceChanged = hasValueChanged(apiFields, militaryInformation, "branchOfService");
        const isOtherMilitaryStatusValue = getFieldValue(apiFields, militaryInformation, 'otherMilitaryStatus');
        const isOtherBranchOfServiceValue = getFieldValue(apiFields, militaryInformation, 'otherMilitaryBranchOfService');
        
        const isServiceDateValid = this.validateServiceDate(apiFields, militaryInformation, isFromDateChanged, isToDateChanged);        
        return ((isServingChanged || isMilitaryStatusChanged || isRankChanged || isFromDateChanged
            || isToDateChanged || isCommissionSourceChanged || isBranchOfServiceChanged || isOtherMilitaryStatusValue || isOtherBranchOfServiceValue) && isServiceDateValid
        );
    }
    
    cancelMilitaryInfo = () => {
      const {  history, analyzeClickEvent } = this.props;
      analyzeClickEvent({
        link: `cms_basicinformation_managemilitaryInfo_cancel`
    })
      history.push(routeConstants.basicinformation);
    }

    checkStatus = (apiFields, militaryInformation, fieldKey) => {
        const checkValue =  getFieldValue(apiFields, militaryInformation, fieldKey);
        return checkValue === 'Other';
    }

    render() {
        const {
            apiFields,
            isCurrentlyServing,
        } = this.state;

        const { profileInformationData, masterLookupStateData } = this.props;
        const { profileInformation, isLoading } = profileInformationData;
        const { militaryInformation } = profileInformation || {};
        const { militaryStatus='', rank='', branchOfService='' } = militaryInformation || {};
        const loading = isLoading;

        const commissionSourceValue = getFieldValue(apiFields, militaryInformation, 'commissionSource');
        const otherMilitaryStatusValue = getFieldValue(apiFields, militaryInformation, 'otherMilitaryStatus');
        const branchOfServiceValue = getFieldValue(apiFields, militaryInformation, 'branchOfService');
        const otherBranchOfServiceValue = getFieldValue(apiFields, militaryInformation, 'otherMilitaryBranchOfService');
        const serviceFromDate = getFieldValue(apiFields, militaryInformation, 'fromDate');
        const serviceToDate = getFieldValue(apiFields, militaryInformation, 'toDate');
        
        const { branchData, militaryStatusData} = this.getMasterDataValues();
        const rankData = this.getRankData(this.getRankDataKey(branchOfServiceValue));
        const showOtherMilitaryStatus = this.checkStatus(apiFields, militaryInformation, 'militaryStatus');
        const showOtherMilitaryBranch = this.checkStatus(apiFields, militaryInformation, 'branchOfService');
        // console.log('showOtherMilitaryStatus',showOtherMilitaryStatus);

        primaryBtnProps.onClick = this.submitMilitaryInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelMilitaryInfo;

        return (
            <div>
                {loading && <WSpinner loading={loading} />}
                <Container>
                    <WBreadCrumb
                        breadCrumbItems={CONST.bredCrumItmes}
                        activeCrumb={CONST.breadCrumName}
                    />
                    <PageTitle 
                            title={CONST.ACTION_LABEL_MILITARY}
                            showMandatoryTxt
                        />
                    <StyledHeading>{CONST.topHeading}</StyledHeading>
                    <FieldValue
                        id="vcm-cms-military-serving"
                        label={CONST.line}
                        value={
                            <Toggle
                                id="vcm-cms-is-currently-serving-military"
                                aria-labelledby="vcm-cms-military-serving"
                                onChange={this.onSwitchClick}
                                toggleOn={isCurrentlyServing}
                                data-name={CONST.line}
                                data-form-field
                            />
                        }
                        labelsm={3}
                        valuesm={6}
                        optional
                    />
                    {isCurrentlyServing && (
                        <div>
                            <VCMSelect
                                id="vcm-cms-military-status"
                                name="Military Status"
                                label="Military Status"
                                itemlist={militaryStatusData}
                                onChange={this.handleChange('militaryStatus')}
                                value={getKeyfromValue(masterLookupStateData, 'mil_status', militaryStatus)}
                                labelsm={3}
                                valuesm={6}
                                optional
                            />
                            {showOtherMilitaryStatus && (
                                <FieldValue
                                    htmlFor="vcm-cms-other-military-status"
                                    label=""
                                    value={
                                        <InputText
                                            id="vcm-cms-other-military-status"
                                            onChange={this.handleInputChange('otherMilitaryStatus')}
                                            value={otherMilitaryStatusValue}
                                            data-name={CONST.OTHER_MILITARY_STATUS}
                                            maxLength={50}
                                            data-form-field
                                        />
                                    }
                                    labelsm={3}
                                    valuesm={6}
                                    mb='1.6875'
                                />
                            )}
                            <VCMSelect
                                id="vcm-cms-branch-of-service"
                                name="Branch of Service"
                                label="Branch of Service"
                                itemlist={branchData}
                                onChange={this.handleChange('branchOfService')}
                                value={getKeyfromValue(masterLookupStateData,'mil_serv_branch',branchOfService)}
                                labelsm={3}
                                valuesm={6}
                                optional
                            />
                            {showOtherMilitaryBranch && (
                                <FieldValue
                                    htmlFor="vcm-cms-other-branchOfService"
                                    label=""
                                    value={
                                        <InputText
                                            id="vcm-cms-other-branchOfService"
                                            onChange={this.handleInputChange('otherMilitaryBranchOfService')}
                                            value={otherBranchOfServiceValue}
                                            data-name={CONST.OTHER_BRANCH_SERVICE}
                                            maxLength={50}
                                            data-form-field
                                        />
                                    }
                                    labelsm={3}
                                    valuesm={6}
                                    mb='1.6875'
                                />
                            )}
                            {!showOtherMilitaryBranch && (
                                <VCMSelect
                                    id="vcm-cms-rank"
                                    name="Rank"
                                    label="Rank"
                                    itemlist={rankData}
                                    onChange={this.handleChange('rank')}
                                    value={getKeyfromValue(masterLookupStateData, this.getRankDataKey(branchOfService), rank)}
                                    labelsm={3}
                                    valuesm={6}
                                    optional
                                />
                            )}
                            
                            <FieldValue
                                as="p"
                                id="vcm-cms-military-service-date"
                                label={CONST.dateService}
                                value={
                                    <Row role="group" data-name={CONST.dateService} aria-labelledby="vcm-cms-military-service-date">
                                        <Col sm={6}>
                                            <VCMDatePicker
                                                alignItems="stretch"
                                                flexDirection="column"
                                                name="fromDate"
                                                id="vcm-cms-service-from-date"
                                                label="From"
                                                onChange={this.handleDateChange('fromDate')}
                                                value={serviceFromDate}
                                                labelsm
                                                valuesm
                                                max={formatDate(new Date())}
                                                maxErrorTxt="Invalid Date"
                                                groupField
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <VCMDatePicker
                                                alignItems="stretch"
                                                flexDirection="column"
                                                name="toDate"
                                                id="vcm-cms-service-to-date"
                                                label="To"
                                                onChange={this.handleDateChange('toDate')}
                                                value={serviceToDate}
                                                labelsm
                                                valuesm
                                                min={ isValidDate(serviceFromDate) ? formatDate(serviceFromDate): '1900-01-01'}
                                                minErrorTxt="Invalid Date"
                                                groupField
                                            />
                                        </Col>
                                    </Row>
                                }
                                labelsm={3}
                                valuesm={6}
                                optional
                            />
                            <FieldValue
                                htmlFor="vcm-cms-commission-source"
                                label={CONST.COMMISSION_SOURCE}
                                value={
                                    <InputText
                                        id="vcm-cms-commission-source"
                                        onChange={this.handleInputChange('commissionSource')}
                                        value={commissionSourceValue}
                                        maxLength={30}
                                        data-name={CONST.COMMISSION_SOURCE}
                                        data-form-field
                                    />
                                }
                                labelsm={3}
                                valuesm={6}
                                optional
                            />
                        </div>
                    )}
                    <InstructionalPanel mb={1.5625} mbt={2.5}>
                      {CONST.line2}
                    </InstructionalPanel>
                </Container>
                <BottomNavBar
                    hideBack
                    showCancelBtn
                    primaryBtnText={CONST.SAVE}
                    primaryBtnProps={primaryBtnProps}
                    cancelBtnProps={cancelBtnProps}
                />
            </div>
        );
    }
}

MilitaryInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getCompositeLookUpData: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    updateProfileMilitaryInformations: PropTypes.func,
    clearExistingActions: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
	linkResponseError: PropTypes.func,
};

MilitaryInfoComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    profileInformationData: {},
    updateProfileMilitaryInformations: () => {},
    clearExistingActions: () => {},
    analyzeClickEvent: () => {},
    linkResponseError: () => {},
};
