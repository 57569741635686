import * as TransactionConstants from '../../TransactionConstants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    RadioGroup,
    FieldValue,
    InputText,
    ErrorMsg,
    OffScreenText,
} from 'modules/CustomerManagementModule/components';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import "./fundSelection.css";
import { addCommasToNumberStr } from 'utils';
const {
    DOLLAR_SYMBOL,
    FUND_WORTH_TEXT,
    FUND_SELECTION_TEXT,
    AVAILABLE_SHARES_TEXT,
    FUND_LIST_TEXT,
    PROPORTIONATE_TITLE,
    PROPORTIONATE_SELL,
    SELLERRAMOUNT90
} = TransactionConstants;

export const borderStyle = '1px solid #d2d2d2';

export const CardContainer = styled.div`
    @media (max-width: 767px){
        &::before {
            border-bottom: 20px solid transparent !important;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')} !important;
        }
        margin-top: 30px;
    }
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
    margin-top: 1.875rem;
    border: ${props => (props.selected ? '2px solid #004A98' : borderStyle)};
    background: ${props =>
        props.selected ? '#fafafa 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'};
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    &:hover {
        outline: ${props => (props.selected ? 'none' : '1px solid #3c5a77')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0px;
        left: 0px;
    }
    > input[type='radio'] {
        position: absolute;
        width: 100%;
        height: 98%;
        left: 0;
        top: 0.5px;
        z-index: -1;
    }

    &:focus-within {
        // outline: 2px solid #000000;
        outline-offset: 0px;
    }
`;
const CardHead = styled.div`
    @media (max-width: 767px){
        padding: 22px 21px 11px 21px;
    }
    text-align: left;
    font: Bold 13px/15px Benton Sans;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    padding: 1.25rem 2.4375rem;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
`;

const CardBodyDetails = styled.div`
    @media (max-width: 767px){
        display: block;
        padding: 20px 21px;
    }
    display: flex;
    border-right: ${borderStyle};
    display: flex;
    padding: 1.875rem;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    align-items: center;
    padding-right: 1rem;
`;

export const CardDetailItem = styled.div`
    @media (max-width: 767px){
        margin-left: 0px;
        margin-bottom: ${props => props.mB}px;
    }
    margin-left: ${props => props.mL || '0'}px;
`;

const CardDetailItemTitle = styled.div`
    @media (max-width: 767px){
        margin-bottom: 10px;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;

const CardDetailItemValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding: 1.188rem 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;

AccountDetailsFlex.displayName = 'AccountDetailsFlex';


const UpperCardBody = styled.div`
    @media (max-width: 767px){
        padding: 25px 21px;
    }
    border-top: ${borderStyle};
    opacity: 1;
    padding: 34px 23px 29px;
    color: #49494a;
    border-bottom: ${borderStyle};
`;

const UpperBodyValue = styled.div`
    @media (max-width: 767px){
        margin-bottom: 25px;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
`;
const TickMarkDiv = styled.div`
    margin-right: 10px;
    display: inline-block;
`;

const sellingOptions = [
    { key: 'dollaramt', value: 'By Amount' },
];

const SELLING_OPTIONS = 'Selling Option';

const StyledCol = styled(Col)`
    @media (max-width: 767px){
        padding: 25px 21px;
    }
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 0;
`;

export const StyledRow = styled(Row)`
    margin-bottom: ${props => props['data-mb'] || 0}rem;
    margin-top: ${props => props['data-mt'] || 0}rem;
    align-items: ${props => props['data-alignitems'] || 'stretch'};
`;

const StyledAmountRow = styled(StyledRow)`
    @media (max-width: 767px){
        padding-left: 15px;
        padding-bottom: 25px;
    }
`;

const StyledAmountCol = styled(Col)`
    @media (max-width: 767px){
        width: 137px;
    }
`;

const StyledAmountInputCol = styled(Col)`
    @media (max-width: 767px){
        margin-left: 20px;
    }
`;

const InputWrapper = styled.div`
    width: ${props => props['data-width']}px;
    display: inline-block;
    margin-right: 15px;
`;

const StyledSpan = styled.span`
    margin-left: 15px;
`;

const StyledRadioGroup = styled(RadioGroup)`
    margin-left: 1rem;
`;
const AMOUNT_LABEL = 'Enter Amount:';
const AMOUNT_SYMBOL = '$';

export const ByAmount = props => {
    const { onInputChange, onInputBlur, value, errorMsg, notificationMsgId, inputId } = props;
    let describedBy = null;
    if (notificationMsgId) describedBy = `${notificationMsgId}`;
    if (!errorMsg && !!Number(value)) describedBy += ' vcm-by-amount-description';

    return (
        <StyledAmountRow data-alignitems="center" className="no-gutters">
            <StyledAmountCol id="vcm-by-amount" sm={3}>
                <span>{AMOUNT_LABEL}</span>
                <StyledSpan>{AMOUNT_SYMBOL}</StyledSpan>
            </StyledAmountCol>
            <StyledAmountInputCol>
                <InputWrapper data-width="130">
                    <InputText
                        aria-labelledby="vcm-by-amount"
                        onChange={onInputChange}
                        data-name={AMOUNT_LABEL}
                        value={value}
                        onBlur={onInputBlur}
                        error={!!errorMsg}
                        required
                        aria-describedby={describedBy}
                        id={inputId}
                    />
                </InputWrapper>
            </StyledAmountInputCol>
        </StyledAmountRow>
    );
};



ByAmount.propTypes = {
    onInputChange: PropTypes.func,
    onInputBlur: PropTypes.func,
    value: PropTypes.string,
    errorMsg: PropTypes.string,
    notificationMsgId: PropTypes.string,
    inputId: PropTypes.string,
};

ByAmount.defaultProps = {
    onInputChange: () => { },
    onInputBlur: () => { },
    value: '',
    errorMsg: '',
    notificationMsgId: '',
    inputId: '',
};
class Proportionate extends Component {

    constructor(props) {
        super(props);
        const { pageIndex } = this.props;
        const fundHeading = FUND_SELECTION_TEXT;
        this.state = {
            fundsAvailable: [],
            selectedFund: null,
            retrivedState: false,
            displayDetails: {
                fundSelection: {
                    titleTxt: fundHeading,
                    editTxt: 'Edit',
                    heading: fundHeading,
                    pageIndex,
                    data: [],
                },
            },
            amtType: '',
            userinputamt: '',
            userinputpercent: '',
            disableNext: true,
            isLoading: false,
            isError: false,
            isTradingAllowed: true,
            setFundClickSelection: false,
            isPrematureDistribution: false,
            selectedFundCostBasis: '',
            showWarningDialog: false,
            touchedIndex: 0,
            touchedFundName: 'fundName',
            touchedFundPercentValue: '0%',
            touchedFundNo: '',
            touchedFundAccNum: '',
            isFee: false,
            touchedLiquidationNoticeFeeResponse: false,
            selectedOption: 'proportionate',
            showByAmount: true,
            userEnteredAmount: '',
        };
        this.fundSelectionRef = React.createRef();
        this.dollarTextInput = React.createRef();
    }


    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        });
    };
    
    getCardHeader = () => {
        return (
            <>
                <CardHead id=''>
                    {PROPORTIONATE_TITLE}
                    <br />
                </CardHead>
            </>
        );
    };

    getCardMiddleSection = () => {
        return (
            <UpperCardBody>
                <UpperBodyValue>
                    {PROPORTIONATE_SELL}
                </UpperBodyValue>
            </UpperCardBody>
        );
    };
   
    
    getAccordianBody = ({
        isPrematureDistribution,
        isLoading,
        currentPageNumber,
        transactionType,
        nullValue,
    })=>{
        const { liquidationData, value, fundsAvailable,selected,totalBalance,totalShare,onAmtChange,onAmountBlur,inputValue,errorMsgAmt,index, amtTypeValue } = this.props
        const { userEnteredAmount, selectedOption } = this.state;
     
        const inputId = `vcm-selling-input-${index}`;
        const radioGroupId = `vcm-selling-options-${index}`;
        return (
            <>
                <legend className="sr-only">{FUND_LIST_TEXT}</legend>
                {transactionType != 'exchange' &&
                <CardContainer key="proportionate"
                    id="proportionate-card-container" selected={selected} onClick={this.props.onFundSelection}>
                    <input
                        type="radio"
                        value="proportionate"
                        defaultChecked={!!selected}
                        aria-label="Proportionate"
                        name={FUND_LIST_TEXT}
                    />
                    <div role="group" >
                    {this.getCardHeader({cardId: 'proportionate-card' })}
                        {this.getCardMiddleSection({item: { fundName: 'Proportionate' },})}
                        <Row>
                            <Col sm={4}>
                                <CardBodyDetails>
                                    <CardDetailItem mB={25}>
                                        <CardDetailItemTitle>{AVAILABLE_SHARES_TEXT}</CardDetailItemTitle>
                                        <CardDetailItemValue>
                                            {addCommasToNumberStr(totalShare)}
                                        </CardDetailItemValue>
                                    </CardDetailItem>
                                    <CardDetailItem mL={20}>
                                        <CardDetailItemTitle>{FUND_WORTH_TEXT}</CardDetailItemTitle>
                                        <CardDetailItemValue>
                                            {DOLLAR_SYMBOL}{addCommasToNumberStr(Number(totalBalance).toFixed(2))}
                                        </CardDetailItemValue>
                                    </CardDetailItem>
                                </CardBodyDetails>
                            </Col>
                            <StyledCol className="selling-options">
                                <FieldValue
                                    as="p"
                                    id={radioGroupId}
                                    label={SELLING_OPTIONS}
                                    value={
                                        <StyledRadioGroup
                                            labelledBy={radioGroupId}
                                            options={sellingOptions}
                                            onChange={this.handleOptionChange}
                                            data-name={SELLING_OPTIONS}
                                            initialValue={amtTypeValue}
                                            flexWrap="wrap"
                                            mR={0.7}
                                            mL={0}
                                            mT={1}
                                            disabled={!selected}
                                            rwdRadioGroupDisplayStyle='block'
                                        />
                                    }
                                    labelsm={2}
                                    valuesm
                                    mb={1.5625}
                                    alignItems="stretch"
                                    noGutters
                                />
                                {((selected && this.state.showByAmount) || errorMsgAmt || inputValue) &&
                                    <ByAmount
                                        onInputChange={onAmtChange}
                                        onInputBlur={onAmountBlur}
                                        value={inputValue}
                                        errorMsg={errorMsgAmt}
                                        notificationMsgId=''
                                        inputId={inputId}
                                    />
                                }
                                <Row className="no-gutters">
                                    <Col className="offset-sm-3">
                                        {errorMsgAmt && (
                                            <ErrorMsg aria-live="assertive" aria-atomic>
                                                <OffScreenText />
                                                <span>{errorMsgAmt}</span>
                                            </ErrorMsg>
                                        )}
                                    </Col>
                                </Row>

                            </StyledCol>
                        </Row>
                    </div>
                </CardContainer>
                }
            </>
        );
    };

    render() {
        const {
            fundsAvailable,
            isLoading,
            isPrematureDistribution,
        } = this.state;
        const {
            navigationObj,
            liquidationData,
            exchangeData,
            transactionType,
            onSwitchComponent,
            fundAccountData,
            handleError,
            onAmtChange,
            onAmountBlur
            
        } = this.props;

        navigationObj.onPrimaryClick = onSwitchComponent(true, this.state, 1);

        const currentPageNumber =
            transactionType === 'exchange'
                ? exchangeData.currentPageNumber_exchangeOut
                : liquidationData.currentPageNumber;

        

        const nullValue = null;
        if (fundAccountData.isError) {
            handleError(true);
        }
        navigationObj.arialabelback = 'Back to Account Selection';

       
        return (
            <>
                <div className="container">
                    {this.getAccordianBody({
                        isPrematureDistribution,
                        isLoading,
                        currentPageNumber,
                        transactionType,
                        nullValue,
                        onAmtChange,
                        onAmountBlur
                    })}

                </div>


            </>
        );
    }
}


const mapDispatchToProps = {};

export const mapStateToProps = state => {
    return {
        fundListData: state.fundAccountData,
        liquidationData: state.liquidationData,
        fundAccountData: state.fundAccountData,
        exchangeData: state.exchangeData,
        accOpeningData: state.accOpeningReducerData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
    };
};

Proportionate.propTypes = {
    getFunds: PropTypes.func.isRequired,
    saveChildrenState: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    liquidationData: PropTypes.instanceOf(Object),
    fundListData: PropTypes.instanceOf(Object),
    fundAccountData: PropTypes.instanceOf(Object),
    exchangeData: PropTypes.instanceOf(Object),
    transactionType: PropTypes.string,
    cancelTradingLimitMessage: PropTypes.func,
    onSwitchComponent: PropTypes.func,
    checkTradingLimit: PropTypes.func,
    setTradingWarning: PropTypes.func,
    handleError: PropTypes.func,
    setNextPageNumber: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    getIsPrematureDistribution: PropTypes.func,
    getMemberProfileInformations: PropTypes.func,
    setCancelLiquidation: PropTypes.func,
    setCancelExchange: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    setNextPageNumberPositions: PropTypes.func,
};

Proportionate.defaultProps = {
    saveChildrenState: () => { },
    savedState: null,
    pageIndex: 0,
    editSection: null,
    navigationObj: {
        onCancel: () => { },
        onPrimaryClick: () => { },
        onSecondaryClick: () => { },
        portalRoot: null,
    },
    fundListData: {},
    liquidationData: {},
    fundAccountData: {},
    transactionType: null,
    cancelTradingLimitMessage: () => { },
    onSwitchComponent: () => { },
    checkTradingLimit: () => { },
    setTradingWarning: () => { },
    handleError: () => { },
    setNextPageNumber: () => { },
    location: {},
    getIsPrematureDistribution: () => { },
    getMemberProfileInformations: () => { },
    setCancelLiquidation: () => { },
    setCancelExchange: () => { },
    accOpeningData: {},
    setNextPageNumberPositions: () => { },
};

export default connect(mapStateToProps, mapDispatchToProps)(Proportionate);
