import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
    AccordianTitle,
    Accordian
} from "common";
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import routeConstants from "routeConstants";
import CONST from 'modules/CustomerManagementModule/ContactInformation/constants';
import { replaceWithAmpersand, getAddressAsLines } from 'modules/CustomerManagementModule/utils';
import { BottomNavBar, CardWithMenu, PageTitle, InstructionalPanel, AddCard, AddressCardUSPSVerified } from 'modules/CustomerManagementModule/components';

const PanelWrap = styled(InstructionalPanel)`
    font: 1em benton-sans;
    margin-bottom:2rem;
    &::before { 
        border-bottom: ${props => props.cornerSize || 32}px solid transparent;
        border-left: ${props => props.cornerSize || 32}px solid #486D90;
    }
`;

const HeaderWrap = styled.div`
    border-bottom: 1px solid #d2d2d2;
    padding: 18px 30px;
    font: 1.125em yorkten-slab-extra-bold;
    color:#004A98;
    text-transform: capitalize;
`;

const BodyWrap = styled.div`
    margin: 0 1.875em 1.875em;
    .row{
        > div{
            margin-top: 1.875em;
        }
    }
`;

const DividerBar = styled.span`
    margin-right: 1.5em;
    Padding-right: 1.5em;
    border-right:1px solid #ccc;
`

const StyledLine = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const menuOptions = [{name:"Edit", icon:editIcon}];
const seasonalMenuOptions = [{name:"Edit", icon:editIcon},{name:"Delete", icon:deleteIcon}];

const backBtnProps = {};

export default class ContactInfoComponent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    componentDidMount() {
        const { getCompositeLookUpData, getAccountLevelAddresses, profileInformationData } = this.props;
        const { hasAccountLevelAddress } = profileInformationData;
        this.clearFlags();
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
        if(!hasAccountLevelAddress) getAccountLevelAddresses();
    }

    componentDidUpdate(){
        this.clearFlags();
    }
    
    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            address_type:addressType
        } = masterLookupStateData || {};
        const payLoad = [];
        if (!addressType) payLoad.push('address_type');
        return payLoad;
    };
    
    clearFlags = ()=> {
        const { profileInformationData, clearExistingActions } = this.props;
        const { isError, isSuccess } = profileInformationData;
        if(isSuccess || isError){
            clearExistingActions();
        }
    }

    editAddress = (addressData, addressCategory, addrIndex='-1') => (e) => {
        e.stopPropagation();
        const { history, showNotification } = this.props;
        const msg = {
            message: CONST.FOREIGN_ADDRESS_UPDATE_MSG,
            info: null,
        };
        if(addressCategory === 'profile'){
            const { isForeignAddress } = addressData;
            if(isForeignAddress){
                showNotification({msg});
            }else {
                history.push(routeConstants.editContact, addressData);
            }
        }else if(addressCategory === 'account'){
            const { addressInformation } = addressData;
            const { isSeasonalAddress, isMailingAddress, isPhysicalAddress, isForeignAddress } = addressInformation[+addrIndex];
            if(isForeignAddress){
                showNotification({msg});
            }else{
                history.push(routeConstants.editAccountAddress, { ...addressData, addrIndex, isSeasonalAddress, isMailingAddress, isPhysicalAddress});
            }
        }
    };

    cancel = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managecontactInfo_back`
        })
        history.push(routeConstants.basicinformation);
    };

    deleteAddress = (data, addrIndex) => (e) => {
        // Delete is only for seasonal address
        e.stopPropagation();
        const { updateAccountLevelAddresses } = this.props;
        const { accountInformation, addressInformation } = data;
        const addressData = addressInformation[+addrIndex];
        const sendingData = {
                  accountInformation,
                  addressInformation: {
                      ...addressData,
                  },
                addressAction: 'Delete',
            };
            updateAccountLevelAddresses(sendingData);
    };
    
    findMailingAddresstype = (address) => {
        return (address || []).find((data) => data.isMailingAddress);
    }
    
    findPhysicalAddresstype = (address) => {
        return (address || []).find((data) => data.isPhysicalAddress);
    }

    onMenuOptionClick = (addObj, addressCategory, addrIndex) => (menuOption) => (e) => {
        let accGroupNameFirstChar='';
        let addrTypeName='';
        
        const { accountInformation } = addObj || {};
        const { accountHoldingGroup = 'Profile Contact Information'} = accountInformation || {};
        const splitAccGroupName = accountHoldingGroup.split(' ');
        splitAccGroupName.forEach(element => {
            accGroupNameFirstChar+=element.charAt(0);
        });
        if(addrIndex === 0){
            addrTypeName = 'physicalAddress';
        }else if(addrIndex === 1){
            addrTypeName = 'MailingAddress';
        }else{
            addrTypeName = 'SeasonalAddress';
        }        
        const { analyzeClickEvent } = this.props;
        if(menuOption === 'Edit'){
            analyzeClickEvent({
                link: `cms_basicinformation_managecontactinfo_${accGroupNameFirstChar}_${addrTypeName}_edit`
            });
            this.editAddress(addObj, addressCategory, addrIndex)(e);
        }else if(menuOption === 'Delete') {
            analyzeClickEvent({
                link: `cms_basicinformation_managecontactinfo_${accGroupNameFirstChar}_${addrTypeName}_delete`
            });
            this.deleteAddress(addObj, addrIndex)(e);
        }
    }

    onAddCardClick = (route, accDetails) => (e) => {
        e.stopPropagation();
        let accGroupNameFirstChar = '';
        const { history, analyzeClickEvent } = this.props;
        const { accountInformation } = accDetails || {}
        const { accountHoldingGroup = '' } = accountInformation || {}
        const splitAccGroupName = accountHoldingGroup.split(' ');
        splitAccGroupName.forEach(element => {
            accGroupNameFirstChar+=element.charAt(0);
        });
        analyzeClickEvent({
            link: `cms_basicinformation_managecontactinfo_${accGroupNameFirstChar}_SeasonalAddress_Add`
        })
        history.push(route, { ...accDetails, isSeasonalAddress: true, isMailingAddress: false, isPhysicalAddress: false } );
    }

    render() {
        const { profileInformationData } = this.props;
        const loading = profileInformationData.isLoading;
        
        const { profileInformation, groupedAccountDetails = [] } = profileInformationData;
        const { addressInformation: addressInformationObj } = profileInformation || {};
        
        const mailingAddress = this.findMailingAddresstype(addressInformationObj);
        const physicalAddress = this.findPhysicalAddresstype(addressInformationObj);
        
        const { line1: phyLine1, line2:phyLine2, line3:phyLine3, line4:phyLine4 } = getAddressAsLines(physicalAddress);
        const { line1:mailLine1, line2:mailLine2, line3:mailLine3, line4:mailLine4 } = getAddressAsLines(mailingAddress);

        backBtnProps.onClick = this.cancel;
        backBtnProps['aria-label'] = "Back To Basic Information";
        
        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={CONST.BREADCRUM_MANAGE_CONTACT}
                            activeCrumb={CONST.PAGE_NAME_CONTACT_MANAGE}
                        />
                        <PageTitle
                            title={CONST.PAGE_NAME_CONTACT_MANAGE} 
                        />
                        <Accordian
                            labelledBy={CONST.PROFILE_CONTACT_INFO_ID}
                            titleChild={<AccordianTitle accType={CONST.PROFILE_CONTACT_INFO} id={CONST.PROFILE_CONTACT_INFO_ID} dataAnalyticId = "cms_basicinformation_profilecontactinformation" accIndex={0} />}
                            titleSeperator
                        >
                            <Row>
                                {!!physicalAddress && <Col md={6} lg={4}><CardWithMenu
                                    cardTitle = { CONST.PHYSICAL_ADDRESS }
                                    Line1 ={phyLine1}
                                    extradetail= {
                                        <>
                                            <StyledLine>{phyLine2}</StyledLine>
                                            <StyledLine>{phyLine3}</StyledLine>
                                            {phyLine4 && <StyledLine>{phyLine4}</StyledLine>}
                                        </>
                                        }
                                    menu
                                    menuOptions={menuOptions}
                                    pT='1.25'
                                    onMenuOptionClick={this.onMenuOptionClick(physicalAddress, 'profile', 0)}
                                /></Col>
                                }
                                {!!mailingAddress && <Col md={6} lg={4}><CardWithMenu
                                    cardTitle = { CONST.MAILING_ADDRESS }
                                    Line1 ={mailLine1}
                                    extradetail= {
                                        <>
                                            <StyledLine>{mailLine2}</StyledLine>
                                            <StyledLine>{mailLine3}</StyledLine>
                                            {mailLine4 && <StyledLine>{mailLine4}</StyledLine>}
                                        </>
                                        }
                                    menu
                                    menuOptions={menuOptions}
                                    pT='1.25'
                                    onMenuOptionClick={this.onMenuOptionClick(mailingAddress, 'profile', 1)}
                                /></Col>
                                }
                            </Row>
                        </Accordian>
                        { groupedAccountDetails.map((accountHoldingGroup, holdingGrpIndex)=>{
                            const { accountHoldingGroup: groupName, accounts } = accountHoldingGroup;
                            const accordianID = `vcm-cms-${groupName.replace(/\s+/g, '-')}`;
                            const removeSpace = `${groupName.replace(/\s+/g, '')}`;
                            return (
                                <Accordian
                                    key={holdingGrpIndex.toString()}
                                    labelledBy={accordianID}
                                    titleChild={<AccordianTitle accType={groupName} id={accordianID} dataAnalyticId = {`cms_basicinformation_${removeSpace}`} accIndex={1} />}
                                    titleSeperator
                                >
                                    <>
                                        {accounts.map((eachacc, accountIndex)=>{
                                            const { accountInformation, addressInformation} = eachacc;
                                            const { accountName, accountNumber, } = accountInformation;
                                            const isSeasonalAddressPresent = addressInformation.some(element => element.isSeasonalAddress);
                                            const accNum = `${CONST.ACCOUNT_NUMBER} - ${accountNumber}`;
                                            return (
                                                <PanelWrap key={accountIndex.toString()} cornerColor='blue' className="p-0">
                                                    <HeaderWrap><DividerBar>{accountName}</DividerBar>{accNum}</HeaderWrap>
                                                    <BodyWrap>
                                                        <Row>
                                                            {
                                                                addressInformation.map((address, addrIndex)=>{
                                                                    const { 
                                                                        addressLine1,
                                                                        addressLine2,
                                                                        city,
                                                                        state,
                                                                        zip,
                                                                        isPhysicalAddress,
                                                                        isSeasonalAddress,
                                                                        dateValidFrom= '',
                                                                        dateValidTo='',
                                                                        isAddressEditable=false,
                                                                    } = address;
                                                                    const Line1 = `${replaceWithAmpersand(addressLine1)}, ${replaceWithAmpersand(addressLine2)}`.replace(/&+/g, '').replace(/^,|,$/g, '');
                                                                    const stateZip = `${state} - ${zip}`;
                                                                    const idKey = `${accountNumber}-${addrIndex}`;
                                                                    return (
                                                                        <React.Fragment key={addrIndex.toString()}>
                                                                            {!isSeasonalAddress ? 
                                                                                (<Col md={6} lg={4}>
                                                                                    <CardWithMenu 
                                                                                        cardTitle = { isPhysicalAddress ? CONST.PHYSICAL_ADDRESS : CONST.MAILING_ADDRESS }
                                                                                        Line1 ={Line1}
                                                                                        extradetail= {
                                                                                            <>
                                                                                                <StyledLine>{city}</StyledLine>
                                                                                                <StyledLine>{stateZip}</StyledLine>
                                                                                            </>
                                                                                            }
                                                                                        menu={isAddressEditable}
                                                                                        menuOptions={menuOptions}
                                                                                        pT='1.25'
                                                                                        onMenuOptionClick={this.onMenuOptionClick(eachacc, 'account', addrIndex)}
                                                                                    />
                                                                                </Col>) : 
                                                                                (<Col md={6} lg={4}>
                                                                                    <AddressCardUSPSVerified 
                                                                                        seasonalDate
                                                                                        cardTitle={CONST.SEASONAL_TEXT}
                                                                                        addressLine1={Line1}
                                                                                        addressLine2 ={city}
                                                                                        addressLine3={stateZip}
                                                                                        menu
                                                                                        menuOptions={seasonalMenuOptions}
                                                                                        onMenuOptionClick={this.onMenuOptionClick(eachacc, 'account', addrIndex)} 
                                                                                        fromDate={dateValidFrom} 
                                                                                        toDate={dateValidTo}
                                                                                        idKey={idKey}
                                                                                    />
                                                                                </Col>)
                                                                            }
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                !isSeasonalAddressPresent && (
                                                                    <Col md={6} lg={4}>
                                                                        <AddCard
                                                                            addTxt={CONST.ADD_SEASONAL_TEXT}  
                                                                            onAddCardClick = {this.onAddCardClick(routeConstants.addAccountAddress, eachacc)} 
                                                                    /></Col>
                                                                )
                                                            }
                                                        </Row>
                                                    </BodyWrap>
                                                </PanelWrap>
                                            );
                                        })}
                                    </>
                                </Accordian>
                            )
                        })}
                    </Container>
                    <BottomNavBar
                        hidePrimaryBtn
                        backBtnProps={backBtnProps}
                    />
            </>
        );
    }
}

ContactInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    clearExistingActions: PropTypes.func,
    profileInformationData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    getAccountLevelAddresses: PropTypes.func,
    updateAccountLevelAddresses: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    showNotification: PropTypes.func,
};

ContactInfoComponent.defaultProps = {
    getCompositeLookUpData: () => {},
    clearExistingActions: () => {},
    profileInformationData: {},
    masterLookupStateData: {},
    getAccountLevelAddresses: () => {},
    updateAccountLevelAddresses: () => {},
    analyzeClickEvent: ()=>{},
    showNotification: () => {},
};
