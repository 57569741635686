const WarningConstant={
    gateHeader:'Redemption Gate Notice',
    feeHeader:'Liquidity Fee Notice',
    para1:'The',
    para2:'you are ',
    para2a:'redeeming ',
    para2b:'purchasing ',
    para2c:'has imposed a fee of ',
    perecentSymbol:'%',
    para3:' on the redemption of shares.',
    para3a:' The fees will be deducted from the amount of your request and reflected upon your confirmation.',
    para4:'For further information, please see ',
    para5:'Form N-CR',
    para6:'or contact a Member Service Representative (800) 235-8396.',
    para7:'Would you like to proceed?',
    para8:'has implemented a temporary restriction (gate) on redemptions. Redemptions from this fund are not allowed at this time.',
    yesText:'Yes',
    noText:'No',
    okText:'Ok',
    para9:'Please select a different fund.'
}

export default  WarningConstant