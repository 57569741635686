/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to loop through the investment payment methods
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import React from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { WTable, VCMSelect, WInput, WIcon, VCMDatePicker } from '../../../common';
import './InvestmentSelection.css';
import styles from './styles';
import * as CONST from './consts';
import AccGlobalConstants from '../AccountManagementConstants';

/*  lopping offline and online methods */
const InvestmentSelectionFundTable = props => {
    const {
        selectedFundsList,
        fundOptions,
        handleFundOptions,
        handleFundOptionsBlur,
        totalInitialInvestment,
        totalMonthlyInvestment,
        handleFundRemove,
        disableRemove,
    } = props;
    const { assignObj } = AccGlobalConstants;
    const defaultFundOption = 'init';

    const formatInputValues = val => {
        return val ? val.toString().replace(/[a-zA-Z,$]/g, '') : 0;
    };

    return (
        <div>
            <WTable hover bordered style={styles.tableColor} className="fundInvestTable">
                <caption style={styles.tableCaption}>{CONST.FUND_INVESTMENT_TABLE}</caption>
                <thead style={styles.tableHeaderStyle}>
                    <tr>
                        <th className="table_fundName" scope="col">
                            {CONST.FUND_NAME}
                        </th>
                        <th className="table_fundOptions" scope="col">
                            {CONST.FUND_OPTIONS}
                        </th>
                        <th className="table_invVal" scope="col">
                            {CONST.INIT_INVESTMENT}
                        </th>
                        <th className="table_invVal" scope="col">
                            {CONST.MONTHLY_INVESTMENT}
                        </th>
                        <th>{CONST.START_DATE}</th>
                        <th>{CONST.ACTION}</th>
                    </tr>
                </thead>
                <tbody style={styles.fundUrInvTableStyle}>
                    {selectedFundsList &&
                        selectedFundsList.map((item, index) => (
                            <tr key={item.fundNumber}>
                                <td className="fundUrInvAccCol1">{item.fundName}</td>
                                <td>
                                    <VCMSelect
                                        name="fundingOption"
                                        id={assignObj(`fundingOption${index}`)}
                                        itemlist={fundOptions}
                                        onChange={handleFundOptions(index, 'fundingOption')}
                                        //   onBlur={handleFundOptionsBlur(index, 'fundingOption')}
                                        value={
                                            selectedFundsList[parseInt(index, 10)].fundingOption
                                                ? selectedFundsList[parseInt(index, 10)].fundingOption
                                                : defaultFundOption
                                        }
                                        valuesm={12}
                                        labelsm={0}
                                        //    errortext={item.fundingOption_err}
                                        noGutters
                                    />
                                </td>
                                <td>
                                    <div style={styles.rowStyleDollar} className="dollar initialInv">
                                        <div>
                                            <WInput
                                                id={assignObj(`initialInvestment${index}`)}
                                                label="initialInvestment"
                                                labelstyle={styles.tableCaption}
                                                labelsm={0}
                                                valuesm={12}
                                                placeholder=""
                                                value={
                                                    item.initialInvest
                                                        ? formatInputValues(item.initialInvest).toString()
                                                        : ''
                                                }
                                                name="initialInvestment"
                                                inputstyle={assignObj({
                                                    marginBottom: 0,
                                                })}
                                                type="text"
                                                maxlength={10}
                                                // pattern="\d*"
                                                onChange={handleFundOptions(index, 'initialInvest')}
                                                onBlur={handleFundOptionsBlur(index, 'initialInvest')}
                                                errortext={item.initialInvestment_err}
                                            />
                                            <span className="maxValplaceholder">{item.initialInvestment}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {item.fundingOption === CONST.intAndMonthInv && (
                                        <div style={styles.rowStyleDollar} className="dollar monthlyInv">
                                            <div>
                                                <WInput
                                                    id={assignObj(`monthlyInvestment${index}`)}
                                                    name="monthlyInvestment"
                                                    label=""
                                                    labelsm={0}
                                                    valuesm={12}
                                                    placeholder=""
                                                    value={
                                                        item.monthlyInvest
                                                            ? formatInputValues(item.monthlyInvest).toString()
                                                            : ''
                                                    }
                                                    inputstyle={assignObj({
                                                        marginBottom: 0,
                                                    })}
                                                    type="text"
                                                    maxlength={10}
                                                    onChange={handleFundOptions(index, 'monthlyInvest')}
                                                    onBlur={handleFundOptionsBlur(index, 'monthlyInvest')}
                                                    errortext={item.monthlyInvestment_err}
                                                />
                                                <span
                                                    className="maxValplaceholder"
                                                    style={{ whiteSpace: 'nowrap', fontSize: '11px' }}
                                                >
                                                    {item.minimumAutomaticInvestment}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {item.fundingOption === CONST.intAndMonthInv && (
                                        <div style={styles.rowStyle} className="tableDate">
                                            <div style={styles.fullWidthStyle}>
                                                <VCMDatePicker
                                                    name="startDate"
                                                    id={assignObj(`startDate${index}`)}
                                                    onChange={handleFundOptions(index, 'startDate')}
                                                    onBlur={handleFundOptionsBlur(index, 'startDate')}
                                                    min={`${new Date(Date.now() + 48 * 3600 * 1000)}`}
                                                    minErrorTxt={item.startDate_err || CONST.validDateErrorMessage}
                                                    errortext={item.startDate_err}
                                                    value={item.startDate || ''}
                                                    labelsm={0}
                                                    valuesm={12}
                                                    noGutters
                                                />
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td className="removeBtnWrpr">
                                    <button
                                        type="button"
                                        key={assignObj(`${item.fundName}-remove`)}
                                        style={styles.removeLink}
                                        onClick={handleFundRemove(index)}
                                        disabled={disableRemove}
                                    >
                                        <WIcon
                                            icon={faTrashAlt}
                                            fontawesomestyle={styles.removeLinkIconStyle}
                                            size="1x"
                                        />
                                        {CONST.REMOVE}
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
                <tfoot style={styles.tableFooterStyle}>
                    <tr>
                        <td colSpan="2">
                            <div style={styles.fundFooter}>{CONST.TOTAL}</div>
                        </td>
                        <td>
                            <div
                                style={assignObj({
                                    ...styles.fundFooter,
                                    paddingLeft: 20,
                                })}
                                className="dollar"
                            >
                                {totalInitialInvestment}
                            </div>
                        </td>
                        <td colSpan="3">
                            <div
                                style={assignObj({
                                    ...styles.fundFooter,
                                    paddingLeft: 20,
                                })}
                                className="dollar"
                            >
                                {totalMonthlyInvestment}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </WTable>
        </div>
    );
    /* end table for funding options */
};

InvestmentSelectionFundTable.propTypes = {
    selectedFundsList: PropTypes.instanceOf(Object),
    fundOptions: PropTypes.instanceOf(Object),
    handleFundOptions: PropTypes.func,
    handleFundOptionsBlur: PropTypes.func,
    totalInitialInvestment: PropTypes.string,
    totalMonthlyInvestment: PropTypes.string,
    handleFundRemove: PropTypes.func,
    disableRemove: PropTypes.bool,
};

InvestmentSelectionFundTable.defaultProps = {
    selectedFundsList: [],
    fundOptions: {},
    handleFundOptions: () => {},
    handleFundOptionsBlur: () => {},
    handleFundRemove: () => {},
    totalInitialInvestment: '0',
    totalMonthlyInvestment: '0',
    disableRemove: false,
};

export default InvestmentSelectionFundTable;
