import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as BankIcon } from 'assets/bankNew.svg';
import { ReactComponent as TickMark } from 'assets/greenTick.svg';
import WireIcon from 'assets/wiretransfer.png';
import WSpinner from '../../../../../../common/WSpinner/WSpinner';
import WCard from '../../../../../../common/WCard/WCard';
import MaskText from '../../../../../../common/MaskText/MaskText';
import StyledButton from '../../../../../../common/StyledComponents';
import * as Constants from '../../../../../../common/Constants';


const {LIQ_ADDRESS_CHANGE_TEXT } = Constants;

const displayNoneStyle = { display: 'none' };

const WireTransfer = styled.img`
    padding-right: 0.625rem;
`;

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const cardBodyStyle = {
    paddingBottom: 0,
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -30px;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const FundFromDesc = styled.div`
    margin-bottom: 2.5rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const AccountNameText = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: ${props => (props.bankAccDisplayRule === 'disabled' ? '#808080' : '#486d90')};
    opacity: 1;
`;
const AccountNumberText = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${props => (props.bankAccDisplayRule === 'disabled' ? '#808080' : '#49494a')};
    opacity: 1;
    margin-top: 0.25rem;
`;
const AccountErrorMessage = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #ff0000;
    opacity: 1;
    margin-left: 15px;
`;
const BankAccDetail = styled(FlexRowDiv).attrs(({ cardId }) => ({ 'aria-describedby': cardId }))`
    margin: 2rem 1.25rem;
`;
const BankcardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const BanckAccCard = styled(StyledButton)`
    display: flex;
    position: relative;
    cursor: ${props => (props.bankAccDisplayRule === 'disabled' ? 'default !important' : 'pointer')};
    width: 45%;
    height: 100px;
    margin: 1.25rem 0rem;
    border: ${props => (!props.selected ? '2px solid #fff' : '2px solid #8BC105')};
    box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
    outline: ${props => (props.selected ? '0' : '1px solid #D2D2D2')};
    background: ${props =>
        props.selected ? '#FAFAFA 0% 0% no-repeat padding-box' : '#ffffff 0% 0% no-repeat padding-box'};
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -1px;
        left: -1px;
    }
    &:hover {
        outline: ${props => (props.selected ? '2px solid #8BC105' : '1px solid #3c5a77')};
    }
    &:focus {
        outline: ${props => (props.selected ? '2px solid #8BC105' : '5px auto -webkit-focus-ring-color')};
    }
`;

const GreenTick = styled(TickMark)`
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
`;

const NameNumberWrapper = styled(FlexColumnDiv)`
    margin-left: ${props => (props.marginL ? '1.25rem' : 0)};
`;
const TobeVerified = styled.div`
    text-align: left;
    font: Medium 14px/20px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

const VerifyAlert = styled.div`
    background: #e60000 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
`;
const VerifyAlertText = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    color: #ffffff;
    opacity: 1;
    padding-left: 8px;
    padding-bottom: 2px;
`;
const VerifyDiv = styled.div`
    display: flex;
    margin-top: 5px;
`;

const BankAccountDetail = props => {
    const { details, method, bankAccDisplayRule } = props;
    const { text1 = '', text2 = '' } = details;
    const tobeVerifiedText = 'To be verified';
    return (
        <BankAccDetail method={method}>
            {method === 'online' && text2 && <BankIcon />}
            {method === 'offline' && <WireTransfer src={WireIcon} />}
            <NameNumberWrapper marginL={text2}>
                <AccountNameText bankAccDisplayRule={bankAccDisplayRule}>{text1}</AccountNameText>
                {method !== 'offline' && text2?.toString().trim() && (
                    <AccountNumberText bankAccDisplayRule={bankAccDisplayRule}>
                        <MaskText text={text2?.toString()} startIndex={0} maskLength={text2 ? text2.toString().length - 4 : 0} />
                        {bankAccDisplayRule === 'disabled' ? (
                            <AccountErrorMessage>{tobeVerifiedText}</AccountErrorMessage>
                        ) : (
                            ''
                        )}
                    </AccountNumberText>
                )}
            </NameNumberWrapper>
        </BankAccDetail>
    );
};
BankAccountDetail.propTypes = {
    details: PropTypes.instanceOf(Object),
    method: PropTypes.string,
    bankAccDisplayRule: PropTypes.string,
};
BankAccountDetail.defaultProps = {
    details: {},
    method: '',
    bankAccDisplayRule: '',
};

const getBankDisplayRules = (bankDisplayRules, createdDate) => {
    const currentDate = new Date();
    const accCreatedDate = new Date(createdDate);
    const diffDays = Math.ceil((currentDate - accCreatedDate) / (1000 * 60 * 60 * 24));
    let displayRule = '';
    if (diffDays < bankDisplayRules.lower_limit) {
        displayRule = 'disabled';
    }
    return displayRule;
};

const MethodCard = props => {
    const { cardDetails, onCardClick, method, cursorStyle, tobeVerifiedflag, bankDisplayRules } = props;
    const tobeVerifiedText = 'To be verified';
    const tobeVerifiedAlert = '!';
    let details;
    let bankAccDisplayRule = '';
    return (
        <>
            <BankcardWrapper>
                {cardDetails.map((acc, index) => {
                    details = {};
                    if (method === 'offline') {
                        details.text1 = acc.text;
                    } else {
                        details.text1 = acc.bankName || `Bank Account ${index + 1}`;
                        details.text2 = acc.bankAccountNumber || ' ';
                        bankAccDisplayRule = getBankDisplayRules(bankDisplayRules, acc.CreatedDate);
                    }
                    return (
                        <BanckAccCard
                            onClick={onCardClick(index, bankAccDisplayRule)}
                            selected={!!acc.selected}
                            key={details.text1 + details.text2 + index}
                            cursorStyle={cursorStyle}
                            marginReq={index < cardDetails.length - 1}
                            aria-label={details.text1}
                            aria-selected={!!acc.selected}
                            aria-hidden={acc.selected}
                            bankAccDisplayRule={bankAccDisplayRule}
                        >
                            {!!acc.selected && <GreenTick />}
                            <BankAccountDetail
                                method={method}
                                details={details}
                                cardId={acc.bankAccountNumber}
                                bankAccDisplayRule={bankAccDisplayRule}
                            />
                        </BanckAccCard>
                    );
                })}
            </BankcardWrapper>
            {method === 'online' && tobeVerifiedflag && (
                <VerifyDiv>
                    <VerifyAlert>
                        <VerifyAlertText>{tobeVerifiedAlert}</VerifyAlertText>
                    </VerifyAlert>
                    <TobeVerified>{tobeVerifiedText}</TobeVerified>
                </VerifyDiv>
            )}
        </>
    );
};
MethodCard.propTypes = {
    method: PropTypes.string,
    cardDetails: PropTypes.instanceOf(Object),
    onCardClick: PropTypes.func,
    cursorStyle: PropTypes.string,
    tobeVerifiedflag: PropTypes.bool,
    //  bankAccDisplayRule: PropTypes.string,
    bankDisplayRules: PropTypes.instanceOf(Object),
};
MethodCard.defaultProps = {
    method: '',
    cardDetails: [],
    onCardClick: () => {},
    cursorStyle: '',
    tobeVerifiedflag: false,
    //  bankAccDisplayRule: '',
    bankDisplayRules: {},
};


const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding-top: 20px;
`;

const FundMethod = props => {
    const { cardDetails, onCardClick, method, cursorStyle, bankDisplayRules } = props;
    return (
        <>
            <MethodCard
                bankDisplayRules={bankDisplayRules}
                method={method}
                cardDetails={cardDetails}
                onCardClick={onCardClick}
                cursorStyle={cursorStyle}
            />
        </>
    );
};
FundMethod.propTypes = {
    desc: PropTypes.string,
    method: PropTypes.string,
    cardDetails: PropTypes.instanceOf(Object),
    onCardClick: PropTypes.func,
    cursorStyle: PropTypes.string,
    bankDisplayRules: PropTypes.instanceOf(Object),
};
FundMethod.defaultProps = {
    desc: '',
    method: '',
    cardDetails: [],
    onCardClick: () => {},
    cursorStyle: '',
    bankDisplayRules: {},
};

class BankCardSec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            retrivedState: false,
            selectedBank: null,
            showOptions: props.showOptions,
            bankAccounts: [],
            offlineCards: [],
            displayDetails: {
            },
            bankDisplayRules: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, fundFrom, bankDisplayRules } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }

        if (!prevState.bankAccounts.length) {
            if (fundFrom.methods[1].details.length) {
                return {
                    bankAccounts: fundFrom.methods[1].details,
                    offlineCards: fundFrom.methods[0].details,
                    bankDisplayRules,
                };
            }
        }

        return {
            ...prevState,
        };
    }

    assignPaymentMethodDetails = (selectedBank) => {

        return selectedBank ? [{ mode: 'Online', details: selectedBank }] : null;
    }

    getMaskAccountNumberLength = (acc) => {
        return acc.bankAccountNumber ? acc.bankAccountNumber.toString().length-4 : 0
    }

    onBankAccclick = (index, disabled) => e => {
        const { onSelecteMethodClick } = this.props
        if (disabled !== 'disabled') {
            e.stopPropagation();
            const { showOptions } = this.state;
            this.setState(
                prevState => {
                    const { bankAccounts, displayDetails, offlineCards } = prevState;
                    let { selectedBank } = prevState;
                    const displayData = [];
                    selectedBank = null;
                    const bnkAcc = bankAccounts.map((eachacc, accindex) => {
                        const acc = { ...eachacc };
                        if (index === accindex) {
                            acc.selected = !acc.selected;
                        } else {
                            acc.selected = false;
                        }
                        if (acc.selected) {
                            selectedBank = acc;
                            displayData.push(
                                {
                                    field: 'Deposit Account',
                                    value: acc.bankName || `Bank Account ${index + 1}`,
                                },
                                {
                                    field: 'Account Number',
                                    formattedValue: acc.bankAccountNumber ? (
                                        <MaskText
                                            text={acc.bankAccountNumber.toString()}
                                            startIndex={0}
                                            maskLength={this.getMaskAccountNumberLength(acc)}
                                        />
                                    ) : (
                                        acc.bankAccountNumber
                                    ),
                                    value: acc.bankAccountNumber,
                                },
                            );
                        }
                        return acc;
                    });
                    onSelecteMethodClick(displayData)
                    const newState = {
                        bankAccounts: bnkAcc,
                        selectedBank,
                        displayDetails,
                    };
                    if (showOptions) {
                        // Resetting showOptions
                        newState.showOptions = false;
                        // Resetting offline cards selection
                        newState.offlineCards = offlineCards.map(item => {
                            const acc = { ...item };
                            acc.selected = false;
                            return acc;
                        });
                    }
                    return newState;
                },
                () => {
                    const {
                        toggleBank,
                        showOfflineModeOptions,
                        setPaymentMethod,
                    } = this.props;
                    const { selectedBank } = this.state;
                    if (showOptions) {
                        showOfflineModeOptions(index, !showOptions, showOptions);
                    }
                    toggleBank(selectedBank);
                    const paymentMethodDetails = this.assignPaymentMethodDetails(selectedBank);
                    setPaymentMethod(paymentMethodDetails);
                },
            );
        }
        return '';
    };

    onOflineWayClick = index => e => {
        e.stopPropagation();
        const { selectedBank } = this.state;
        const { fundFrom, onSelecteMethodClick } = this.props;
        this.setState(
            prevState => {
                const { offlineCards, bankAccounts } = prevState;
                const check = fundFrom.methods[0].details;
                const offlineCardsData = offlineCards.length > 0 ? offlineCards : check;
                let { showOptions } = prevState;
                showOptions = false;
                const offCards = offlineCardsData.map((eachacc, accindex) => {
                    const acc = { ...eachacc };
                    if (index === accindex) {
                        acc.selected = !acc.selected;
                    } else {
                        acc.selected = false;
                    }
                    if (acc.selected) {
                        showOptions = true;
                    }
                    return acc;
                });
                const newState = { offlineCards: offCards, showOptions };

                newState.selectedBank = showOptions ? 'Check' : null;

                // Resetting bank acc selected
                if (selectedBank) {
                    newState.bankAccounts = bankAccounts.map(item => {
                        const acc = { ...item };
                        acc.selected = false;
                        return acc;
                    });
                }
                return newState;
            },
            () => {
                const { showOfflineModeOptions, setPaymentMethod, checkAddress } = this.props;
                const { showOptions, selectedBank } = this.state;
                showOfflineModeOptions(index, showOptions, true);
                const paymentMethodDetails = selectedBank ? [{ mode: 'Offline', details: selectedBank }] : null;
                setPaymentMethod(paymentMethodDetails);
                const displayData = [];
                displayData.push(
                    {
                        field: 'Deposit To',
                        value: selectedBank,
                    },
                    {
                        field: 'Delivery Address',
                        value: checkAddress,
                    },
                );
                onSelecteMethodClick(displayData)
            },
        );
    };

    getComponentState() {
        const { state = {} } = this || {};
        return { ...state };
    }

    redirectSection = () => {
        const { bankAccounts } = this.state;
        const accounts = [...bankAccounts];
        let initialBankAccounts = '';
        initialBankAccounts = accounts.map(acc => {
            acc.selected = false;
            return acc;
        });
        this.setState({
            bankAccounts: initialBankAccounts,
            selectedBank: null,
        });
    };

    render() {
        const { fundFrom, checkAddressValid = true } = this.props;
        const {
            bankAccounts,
            offlineCards,
            selectedBank,
            showOptions,
            bankDisplayRules,
            isLoading,
        } = this.state;
        const checkDetails = offlineCards.length === 0 ? fundFrom.methods[0].details : offlineCards;
        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <SectionTitle>How would you like to receive your distribution?</SectionTitle>
                    <FlexColumnDiv>
                        <FundFromDesc>{fundFrom.description}</FundFromDesc>
                        {fundFrom.methods.map((item, index) => {
                            let details = item.method === 'online' ? bankAccounts : offlineCards;
                            if (bankAccounts.length < 1 && item.method === 'offline') {
                                details = checkDetails;
                            }
                            const onCardClick = item.method === 'online' ? this.onBankAccclick : this.onOflineWayClick;
                            let cursorStyle = 'pointer';
                            if (
                                (selectedBank && item.method === 'offline') ||
                                (showOptions && item.method === 'online')
                            ) {
                                cursorStyle = 'default';
                            } /* else if (showOptions && item.method === 'online') {
                                cursorStyle = 'default';
                            } */
                            return (
                                <React.Fragment key={item.method}>
                                    {!checkAddressValid && item.method === 'offline' ? (
                                        <WCard
                                            buttonstyle={displayNoneStyle}
                                            cardtitletext={LIQ_ADDRESS_CHANGE_TEXT}
                                            cardtextstyle={cardTextStyle}
                                            cardbodystyle={cardBodyStyle}
                                            cardtitlestyle={cardTitleStyle}
                                            linkcard="true"
                                            leftcornercard={cardBorderStyle}
                                            alt=""
                                            src=""
                                            selected
                                        />
                                    ) : (
                                        <FundMethod
                                            title={item.title}
                                            method={item.method}
                                            cardDetails={details}
                                            onCardClick={onCardClick}
                                            cursorStyle={cursorStyle}
                                            desc={item.desc || ''}
                                            bankDisplayRules={bankDisplayRules}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </FlexColumnDiv>
            </>
        );
    }
}

BankCardSec.propTypes = {
    fundFrom: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    showOfflineModeOptions: PropTypes.func,
    toggleBank: PropTypes.func,
    forwardRef: PropTypes.instanceOf(Object).isRequired,
    bankDisplayRules: PropTypes.instanceOf(Object),
    totalSubmitAmount: PropTypes.number,
    accountNumber: PropTypes.string,
    setPaymentMethod: PropTypes.func,
    checkAddressValid: PropTypes.bool,
};

BankCardSec.defaultProps = {
    fundFrom: {},
    savedState: null,
    showOfflineModeOptions: () => {},
    toggleBank: () => {},
    bankDisplayRules: [],
    totalSubmitAmount: 0,
    accountNumber: '',
    setPaymentMethod: () => {},
    checkAddressValid: true,
};

export default BankCardSec;
