import PropTypes from 'prop-types';
import React from 'react';
import './stepperMobile.css';
//import { useMediaQuery } from 'react-responsive';

/* const currentPage = "Account Selection"; */

const WStepperMobile = props => {
    // Gets the list of page names for the workflow and the current page.
    const { pages, currentPage } = props;

    // Incrementing -> For page number value and not index value
    const currDisplayPageNo = currentPage + 1;

    // Total pages
    const totalPages = pages.length;

    // Display message construction
    const currentStep = `Step ${currDisplayPageNo} of ${totalPages}`;

    // Retrieving the current page name
    const currentPageName = pages[parseInt(currentPage, 10)];

    const getValMob = `${currentStep} ${currentPageName}`

    return (
        <div className="mobile-stepper container">
            <div className="stepperMobileContainer">
                <h1 className="currentStep">
                    <div className="sr-only">{getValMob}</div>
                    <div aria-hidden="true">
                        <span>{currentStep}</span>
                        <span class="currentPage">
                            {currentPageName}
                        </span>
                    </div>
                </h1>
            </div>
        </div>
    );
};
/* const WStepperMobile = () => (
    
); */

WStepperMobile.propTypes = {
    currentPage: PropTypes.number,
    pages: PropTypes.instanceOf(Array),
};

WStepperMobile.defaultProps = {
    currentPage: 5,
    pages: [],
};
export default WStepperMobile;
