import React from "react";
import PropTypes from "prop-types";
import editIcon from 'assets/EditPencil.svg';
import { AddressView, FieldValue, TitleWithAction, SectionTitle } from 'modules/CustomerManagementModule/components';
import CONST from '../constants';

const ContactInfoView = (props) => {
    const { onManageClick, mailingAddress, physicalAddress, hideManage } = props;
    
    return (
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.CONTACT_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_CONTACT}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='1.45'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.MAILING_ADD}
                    alignItems="stretch"
                    value={
                        <AddressView
                            {...mailingAddress}
                        />
                    }
                    mb='1.7'
                    labelsm={3}
                    valuesm={5}
                />
                <FieldValue
                    as="p"
                    label={CONST.PHYSICAL_ADD}
                    alignItems="stretch"
                    value={
                        <AddressView
                            {...physicalAddress}
                        />
                    }
                    mb='2.5'
                    labelsm={3}
                    valuesm={5}
                />
            </>
        );
}

ContactInfoView.propTypes = {
    onManageClick: PropTypes.func,
    mailingAddress: PropTypes.instanceOf(Object),
    physicalAddress: PropTypes.instanceOf(Object),
    hideManage: PropTypes.bool,
};

ContactInfoView.defaultProps = {
    onManageClick: () => {},
    mailingAddress: {},
    physicalAddress: {},
    hideManage:false,
};

export default ContactInfoView;