import { MARKET_NAO_ACCOUNT_TYPES } from 'modules/AccountManagementModule/consts'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import JointRegulatoryInfo from './JointRegulatoryInfo'
import RegulatoryInfo from './RegulatoryInfo'

const Regulatory = (props) => {

  const [pageIndex, setPageIndex] = useState(0)
  const [isButtonType, setisButtonType] = useState('')
  const [back, setBack] = useState(false)

  useEffect(() => {
    setPageIndex(props.regulatoryPageIndex)
  }, [props.regulatoryPageIndex])

  useEffect(() => {

    if(props.onEditedJointReg)
    {
      setPageIndex(1)
    }
    props.onEditJointReg(false)

  }, [props.onEdited])

  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
  const accountType = marketNAOInfoReducerData.marketNAOInfo.account.accountType

  const isJointAccount = marketNAOInfoReducerData.marketNAOInfo.account.accountType.includes(MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)
  const backPageIndex = marketNAOInfoReducerData.marketNAOInfo.trustedContact.regulatoryPageIndex

  const onSwitchComponent = bool => {

    if (pageIndex >= 0 && pageIndex < 6) {

      // bool true for next and false for back
      if (bool) {
        setisButtonType('next')
        setPageIndex(pageIndex + 1);
      } else {
        setisButtonType('back')
        setPageIndex(pageIndex - 1);
      }
    } else {
      const pageIndexValue = pageIndex;
      setPageIndex(pageIndexValue);
    }
  };

  // on next button click
  const onNextClick = () => {
    if (pageIndex < 1 && isJointAccount) {
      onSwitchComponent(true)
    } else {
      props.onNextClick()
    }
  }

  useEffect(() => {
    if (backPageIndex == 1) {
      setisButtonType('back')
      setPageIndex(1);
    }
  }, [backPageIndex])

  // on back button click
  const onBackClick = () => {
    if (pageIndex == 0) {
      setBack(true)
      marketNAOInfoReducerData.marketNAOInfo.trustedContact.regulatoryPageIndex = 0
    } else {
      onSwitchComponent(false)
    }
  }





  const bindComponent = () => {
    switch (pageIndex) {
      case 0: {
        return (
          <RegulatoryInfo isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} />
        );
      }
      case 1: {
        return (
          <JointRegulatoryInfo isButtonType={isButtonType} onNextClick={onNextClick} onBackClick={onBackClick} rootPageIndex={pageIndex} />
        )
      }

      default: {
        return <React.Fragment></React.Fragment>;
      }
    }
  }





  return (
    <div className='regulatory-step'>

      {back && <Redirect to={{
        pathname: '/marketPersonalInfo',
        state: {
          pageIndex:
            accountType == MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT ? 3 : 1
        }
      }} />}

      {bindComponent()}
    </div>
  )
}



export default Regulatory