export default {
    pageHeading: 'Individual Account',
    dividendsError: 'Please select Dividends/Capital Gains',
    prospectusError: 'Please select Prospectus and Reports',
    cards: [
        {
            cardId: 'accountPreferences',
            linkText: '',
            cardHeading: 'General Account Preferences',
            cardSubHeading: {
                p1: 'You can change your preferences on ',
                a1: 'vcm.com',
                h1: 'https://vcm.com',
                p2: 'or by calling a representative',
            },
            fields: [
                {
                    key: 'Reinvest Dividends and Capital Gains',
                    hintText:
                        'Either Reinvest them into purchasing some other securities Or transfer the amount to the bank account which was used during account opening',
                    radioHeading: 'Do you want to Reinvest Dividends and Capital Gains?',
                    uniqueKey: 'dividendsId',
                },
                {
                    key: 'Statements Prospectus and Reports',
                    hintText:
                        'Choose your preferred method for delivery of Statement, Prospectuses, and Reports related to this account. Online documents will be available in your Message Center.',
                    radioHeading: 'Desired format for delivery of documents',
                    uniqueKey: 'prospectusId',
                },
            ],
        },
    ],
    radiotext: {
        font: '500 16px/20px benton-sans',
    },
    radiodescstyles: {
        color: 'rgba(86, 86, 90, 1)',
        fontSize: 14,
        fontWeight: 400,
    },
    radiostyles: { color: 'rgba(73, 73, 74, 1)', fontSize: 16, minHeight: 'unset' },
    technicalError: 'Technical error',
    commonStyle: { padding: 20 },
    nextPath: { pathname: '/verify', state: { referrer: 'currentLocation' } },
    backPath: '/investmentSelection',
    pages: ['Account Type', 'Personal Info', 'Investment Selection', 'Preferences', 'Verify', 'E-Consent'],
    preferencesMasterDataErrorMsg: 'Error in master Data of preferences',
    testSelectedProspectusRef: [
        { value: 'Online', key: 'online' },
        { value: 'Paper', key: 'paper' },
    ],
    hiddenStyle: { display: 'none' },
};
