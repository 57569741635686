import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { updateProfileRelationship, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import RelationshipPrimaryInfoComponent from './RelationshipPrimaryInfoComponent';

export const mapStateToProps = (state) => {
    const { profileRelationshipData, masterLookUpData } = state;
    const { isError } = profileRelationshipData;
    return {
        masterLookupStateData: masterLookUpData,
        profileRelationshipData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    }
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    updateProfileRelationship,
    clearRelationActions,
    analyzeClickEvent, 
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipPrimaryInfoComponent));
