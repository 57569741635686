/**
 * Military Info Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Military information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import { Accordian, AccordianTitle, WInput, WRadio } from '../../../common';
import checkValidity from '../../../utils/checkValidity';
import Consts from '../PersonalInfo/consts';
import DatePicker from 'react-datepicker';
import { FieldValue, SelectDropDown } from '../../CustomerManagementModule/components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Form, Card, Col } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import styles from '../PersonalInfo/styles';
import '../PersonalInfo/style.css';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import StyledToolTip from '../MarketPersonalInfoCard/StyledToolTip.js'
import helperIcon from '../../../assets/helperIcon.png';
import { HelpeText_Military } from '../PersonalInfo/consts'
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';

const assignObj = obj => {
    return obj;
};
const militaryInfo = 'Military Information';
const militaryInfoForJoint = 'Military Information - Joint Owner';
const confirmtext = 'Are you currently serving, or have you ever served in the U.S. Military?';
const dateOfService = 'Date of Service';
const jointLabelText='Is the joint owner currently serving, or has the joint owner ever served in the U.S. Military?'
const selectDropDownStyles = { };
const subLabel = {
    font: '500 12px/20px benton-sans',
    color: '#56565A',
    opacity: 1,
    marginLeft: 10,
    position: 'relative',
    top: -1,
};
const optionalText = '[Optional]';
const dateFormatText = 'MM/DD/YYYY';
const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const radioText = { font: '500 16px/20px benton-sans' };
class MarketMilitaryInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: {},
            currServingUSM: 'N',
            commissionCourse: '',
            milityStatus: '',
            branchOfService: '',
            rank: '',
            serviceFromDate: '',
            serviceFromDatepickerValue: null,
            serviceToDate: '',
            serviceToDatepickerValue: null,
            militaryOthers: '',
            branchOfServiceOthers: '',
            clicked: true,
            HelperTetXForMilitary:false,
        };
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {
            serviceToDate: prevState.serviceToDate,
            serviceFromDate: prevState.serviceFromDate,
            serviceFromDatepickerValue: prevState.serviceFromDate,
            serviceToDatepickerValue: prevState.serviceToDate,
        };
    }

    componentDidMount() {
        const { masterLookupStateData,getCompositeData } = this.props;
        const payload = [];
        const compositePayloadData = [
            'mil_serv_branch',
            'mil_status',
            'mil_rank_01',
            'mil_rank_02',
            'mil_rank_03',
            'mil_rank_04',
            'mil_rank_05',
            'mil_rank_07',
            'mil_rank_08',
            'mil_rank_09',
            'mil_rank_10',
            'mil_rank_11',
            'mil_rank_12',
            'mil_rank_13',
        ];

        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }

        getCompositeData({ data: { datakey: payload }, token: getTokenFromSession() });
    }

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        if (eventKey === 'serviceFromDate') {
            const todayDate = new Date();
            todayDate.setHours(0);
            todayDate.setMinutes(0);
            todayDate.setSeconds(0);
            if (Date.parse(e.target.value) === Date.parse(todayDate)) {
                err = ' Please enter a valid date';
            } else {
                err = '';
            }
        }
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg });
    };

    handleChange = eventKey => e => {
        const { masterLookupStateData, getCompositeLookUpData } = this.props;
        if (eventKey === 'branchOfService' && e.target.value.toLowerCase() !== 'other' && e.target.value !== '06') {
            const bosRank = `mil_rank_${e.target.value}`;
            const payload = [];
            if (!masterLookupStateData[bosRank.toString()]) {
                payload.push(bosRank);
            }
            getCompositeLookUpData(payload);
        }

        this.setState({
            [eventKey]: e.target.value,
        });
    };

    handleDateChange = (dateInputValue, eventkey) => {
        const { errorMsg, serviceFromDate, serviceToDate } = this.state;
        const { primaryDOB } = this.props;
        let err = '';
        if (dateInputValue) {
            const formatedDate = moment(dateInputValue).format(dateFormatText);
            if (
                eventkey === 'serviceFromDate' &&
                primaryDOB &&
                primaryDOB !== '' &&
                formatedDate !== 'Error' &&
                formatedDate !== '' &&
                formatedDate
            ) {
                const eDate = moment(serviceToDate, dateFormatText);
                const dateDiff = eDate.diff(formatedDate, 'days', true);
                const todayDate = new Date();
                todayDate.setHours(0);
                todayDate.setMinutes(0);
                todayDate.setSeconds(0);

                if (Date.parse(formatedDate) >= Date.parse(todayDate)) {
                    err = ' Please enter a valid date';
                    }
                else {
                    if (eDate && dateDiff < 1) {
                        errorMsg['serviceToDate'] = 'To date should be greater than From date.';
                    } else
                        errorMsg['serviceToDate'] = ''
                }
                errorMsg[eventkey.toString()] = err;
            }
            if (eventkey === 'serviceToDate' && primaryDOB && primaryDOB !== '') {
                const sDate = moment(serviceFromDate, dateFormatText);
                const eDate = moment(formatedDate, dateFormatText);
                const dateDiff = eDate.diff(sDate, 'days', true);
                if (dateDiff < 1) {
                    err = 'To date should be greater than From date.';
                } else {
                    err = '';
                    !sDate._i && (errorMsg['serviceFromDate'] = '')
                }
                errorMsg[eventkey.toString()] = err;
            }
            //Checked with primaryDOB, If guest user only enters From date and clicks next,it generates error in getIsValidMilitaryStartDate() of PersonalInfoComponent as DOB is empty.
            //So if DOB is empty, clearning from date and asking 1st to enter DOB <- MSB
            this.setState({ errorMsg, [eventkey]: primaryDOB !== '' ? formatedDate : '', [`${eventkey}pickerValue`]: dateInputValue });
        } else {
            this.setState({ errorMsg, [`${eventkey}`]: '' });
        }
    };

    cardHeaderClick = e => {
        const { clicked } = this.state;
        e.target.classList.toggle('expand');
        this.setState({ clicked: !clicked });
    };

    handleRadioBlur = eventKey => e => {
        e.persist();
        const { errorMsg, currServingUSM } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: currServingUSM,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        if(currServingUSM === 'N'){
        errorMsg['serviceFromDate'] = ''
        errorMsg['serviceToDate'] = ''
        }
        this.setState(() => {
            return {
                errorMsg,
            };
        });
    };

    handleChangeRadio = e => {
        const targetVal = e.target.value.split('');
        this.setState({ currServingUSM: targetVal && targetVal[0].toString().toUpperCase() });
    };

    getValue = data => {
        this.setState(data);
    };

    validateEnteredDate = (eDate, errorMsgParams, dateDiff) => {
        let errorMsg = errorMsgParams

        if (eDate._i === "") {
            errorMsg['serviceToDate'] = 'Please enter To date.'
        } else {
            if (eDate && dateDiff < 1) {
                errorMsg['serviceToDate'] = 'To date should be greater than From date.';
            }
            else
            {
                errorMsg['serviceToDate'] = ''
            }
               
        }

        return errorMsg
    }

    validateIfDateNotEntered = (eventkey, primaryDOB, serviceFromDate, dateFormatText, errorMsgParams) => {

        let errorMsg = errorMsgParams
        if (eventkey === 'serviceToDate' && primaryDOB && primaryDOB !== '') {
            const sDate = moment(serviceFromDate, dateFormatText);
            if (sDate._i === "")
                errorMsg['serviceFromDate'] = 'Please enter From date.'
        }

        return errorMsg
    }

    
    handleDateBlur = (dateInputValue, eventkey) => {
        const { serviceFromDate, serviceToDate } = this.state;
        let { errorMsg } = this.state;
        const { primaryDOB } = this.props;
        let err = '';
        if (!primaryDOB){
                errorMsg[eventkey.toString()] = 'Enter DOB first in Personal Info'
                this.setState({ errorMsg });
        }
        else{
        if (Date.parse(dateInputValue)) {
            const formatedDate = moment(dateInputValue).format(dateFormatText);
            if (
                eventkey === 'serviceFromDate' &&
                primaryDOB &&
                primaryDOB !== '' &&
                formatedDate !== 'Error' &&
                formatedDate !== '' &&
                formatedDate
            ) {
                const eDate = moment(serviceToDate, dateFormatText);
                const dateDiff = eDate.diff(formatedDate, 'days', true);
                const todayDate = new Date();
                todayDate.setHours(0);
                todayDate.setMinutes(0);
                todayDate.setSeconds(0);

                if (Date.parse(formatedDate) >= Date.parse(todayDate)) {
                    err = ' Please enter a valid date';
                } else {
                    const searchPharse = /XX/gi;
                    const primaryDOBReplaced = primaryDOB.replace(searchPharse, '01');
                    const fromDateYear = new Date(formatedDate); // .getFullYear();
                    const dateofBirthYear = new Date(primaryDOBReplaced); // .getFullYear();
                    // To calculate the time difference of two dates
                    const differenceInTime = fromDateYear.getTime() - dateofBirthYear.getTime();
                    // To calculate the no. of days between two dates
                    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                    // To calculate the no. of years between two dates
                    const differenceInyears = differenceInDays / 365;
                    const isValid = differenceInyears >= 17;
                    if (!isValid) {
                        err = 'From date should be greater than or equal to 17 years from your Date of Birth';
                    }
                    else {
                        errorMsg = this.validateEnteredDate(eDate, errorMsg, dateDiff)
                    }
                }
                errorMsg[eventkey.toString()] = err;
            }

            errorMsg = this.validateIfDateNotEntered(eventkey, primaryDOB, serviceFromDate, dateFormatText, errorMsg)
        
            this.setState({ errorMsg, [eventkey]: primaryDOB !== '' ? formatedDate : '', [`${eventkey}pickerValue`]: dateInputValue });
        }
        else {
            if ((eventkey === 'serviceFromDate' && serviceToDate) || (eventkey === 'serviceToDate' && serviceFromDate)) {
                err = 'Please enter service date.'
                errorMsg[eventkey.toString()] = err;
            }
            else {
                err = ''
                errorMsg['serviceToDate'] = err;
                errorMsg['serviceFromDate'] = err;
            }
            this.setState({ errorMsg, [`${eventkey}`]: '' });
        }
    }
    };
    validateFields = () => {
        const {
            currServingUSM,
            errorMsg,
            milityStatus,
            militaryOthers,
            branchOfService,
            branchOfServiceOthers,
        } = this.state;
        errorMsg.currServingUSM = (!currServingUSM).toString();
        const valid = errorMsg.currServingUSM !== 'true' && !errorMsg.serviceFromDate && !errorMsg.serviceToDate;
        let finalValidStatus = true;
        let milStatusValid = true;
        let branchOfServiceValid = true;
        if (currServingUSM && currServingUSM.toLowerCase() === 'y') {
            if (milityStatus === 'other') {
                if (militaryOthers === '' || militaryOthers === undefined || militaryOthers === null) {
                    milStatusValid = false;
                    errorMsg.militaryOthers = 'Please enter military status.';
                } else {
                    milStatusValid = true;
                    errorMsg.militaryOthers = '';
                }
            }
            if (branchOfService === 'other' || branchOfService === '06') {
                if (
                    branchOfServiceOthers === '' ||
                    branchOfServiceOthers === undefined ||
                    branchOfServiceOthers === null
                ) {
                    branchOfServiceValid = false;
                    errorMsg.branchOfServiceOthers = 'Please enter branch of service.';
                } else {
                    branchOfServiceValid = true;
                    errorMsg.branchOfServiceOthers = '';
                }
            }
        }
        finalValidStatus = valid && milStatusValid && branchOfServiceValid;
        this.setState({ errorMsg });
        return finalValidStatus;
    };

    sendDataToParent = () => {
        const valid = this.validateFields();
        return { ...this.state, valid };
    };

    getCorrectFormatValue  = (dateValue) =>
    {
        return (dateValue && dateValue !="Invalid date");
    }

    render() {  
        const {
            currServingUSM,
            commissionCourse,
            milityStatus,
            branchOfService,
            rank,
            serviceFromDate,
            serviceFromDatepickerValue,
            serviceToDate,
            serviceToDatepickerValue,
            errorMsg,
            militaryOthers,
            branchOfServiceOthers
        } = this.state;
        const { masterLookupStateData, isJoint, index, jointText } = this.props;
        const bosRank = branchOfService !== 'other' ? `mil_rank_${branchOfService}` : '';
        const { mil_serv_branch: milBranchMaster, mil_status: militaryStatusMasterData } = masterLookupStateData || {};
        const { value: milBranchValue } = milBranchMaster || {};
        const { value: militaryStatusMasterDataValue } = militaryStatusMasterData || {};
        const { value: rankValue } = masterLookupStateData[bosRank.toString()] || {};
        const milServBranch = milBranchValue || [];
        const militaryStatusList = militaryStatusMasterDataValue || [];
        const rankOp = rankValue || [];
        const serviceDateError = errorMsg.serviceToDate || errorMsg.serviceFromDate ? 'serviceDateError' : '';
        const toText = 'To';
        const fromText = 'From';

        //Tooltip functionality for Military
        const { HelperTetXForMilitary } = this.state
        const toggleToolTipForMilitary = () => {
            this.setState({ HelperTetXForMilitary: true })
        }

        const closeToolTipForMilitary = () => {
            this.setState({ HelperTetXForMilitary: false })
        }

        const militaryInfoText = getValidValueOf(this.props?.jointInfo != "Yes", militaryInfo, militaryInfoForJoint)
        return (
             this.props.verifyEmpHider|| this.props.verifyFinHider ||  this.props.verifyJointEmpHider || this.props.verifyJointFinHider  ? null :
             <>            <Accordian
                ref={this.accordionRef}
                key={militaryInfoText}
                accordianPaddingLeft={0}
                titleChild={
                    <AccordianTitle
                        accType={militaryInfoText}
                        id={militaryInfoText}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                // titleSeperator
                labelledBy={militaryInfoText}
                Id={militaryInfoText}
            >
                <Card>
                    <Card.Body>
                        <img id="Military" className={getValidValueOf(this.props?.jointInfo != "Yes","TooltipForMilitary", "TooltipForJointMilitary" )} onMouseOut={closeToolTipForMilitary} src={helperIcon} alt="" />

                        <StyledToolTip
                            id="Military_Tootip"
                            placement="top"
                            tooltipOpen={HelperTetXForMilitary}
                            targetID="Military"
                            handleToggle={toggleToolTipForMilitary}
                            autohide={true}
                            triggerEvt="hover focus"
                            tooltipText={HelpeText_Military}
                            closeTooltip={closeToolTipForMilitary}
                        />
                        <Row
                            className="Slabel"
                            role="group"
                            aria-labelledby={assignObj(`${jointText}currServingUSM${index}`)}
                        >
                            <span
                                className="label col-sm-4"
                                style={styles.labelTextStyle}
                                id={assignObj(`${jointText}currServingUSM${index}`)}
                            >
                                {this.props?.jointInfo != "Yes" ? confirmtext : jointLabelText}
                            </span>
                            <Col className="radioBtncol">
                                <Row role="radiogroup" className="radioBtnrow">
                                    {Consts.sameAddress.value &&
                                        Consts.sameAddress.value.length > 0 &&
                                        Consts.sameAddress.value.map(field => (
                                            <WRadio
                                                radioFor={isJoint ? `currServingUSM${index}` : 'currServingUSM'}
                                                key={`${jointText}currServingUSM${field.key}${index}`}
                                                id={`${jointText}currServingUSM${field.key}${index}`}
                                                value={field.value}
                                                onBlur={assignObj(this.handleRadioBlur('currServingUSM'))}
                                                onClick={this.handleChangeRadio}
                                                radiodescstyles={radiodescstyles}
                                                radiostyles={radiostyles}
                                                radiotext={radioText}
                                                selected={!!(currServingUSM && currServingUSM.toString() === field.key)}
                                                ariaRequired="true"
                                            />
                                        ))}
                                </Row>
                            </Col>
                        </Row>
                        {currServingUSM === 'Y' && (
                            <>
                                <FieldValue
                                    as="p"
                                    id={`${jointText}milityStatus${index}Label`}
                                    label="Military Status"
                                    value={
                                        <SelectDropDown
                                            aria-labelledby={`${jointText}milityStatus${index}Label`}
                                            id={`${jointText}milityStatus${index}`}
                                            data-name="Military Status"
                                            value={milityStatus}
                                            onChange={this.handleChange('milityStatus')}
                                            errortext={errorMsg.milityStatus}
                                            itemlist={militaryStatusList}
                                            style={selectDropDownStyles}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    optional
                                    noGutters
                                />
                                {milityStatus === 'other' && (
                                    <Row>
                                        <WInput
                                            label=""
                                            placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                                            className=""
                                            id={assignObj(`${jointText}militaryOthers${index}`)}
                                            name="militaryOthers"
                                            type="text"
                                            onChange={this.handleChange('militaryOthers')}
                                            onBlur={this.handleBlur('militaryOthers')}
                                            required
                                            value={militaryOthers || ''}
                                            errortext={errorMsg.militaryOthers}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="false"
                                        />
                                    </Row>
                                )}

                                <FieldValue
                                    as="p"
                                    id={`${jointText}branchOfService${index}Label`}
                                    label="Branch of Service"
                                    value={
                                        <SelectDropDown
                                            aria-labelledby={`${jointText}branchOfService${index}Label`}
                                            id={`${jointText}branchOfService${index}`}
                                            data-name="Branch of Service"
                                            value={branchOfService}
                                            onChange={this.handleChange('branchOfService')}
                                            errortext={errorMsg.branchOfService}
                                            itemlist={milServBranch}
                                            style={selectDropDownStyles}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    optional
                                />
                                
                                {branchOfService === '06' && (
                                    <Row>
                                        <WInput
                                            label=""
                                            placeholder={Consts.OTHER_PLACEHOLDER_TEXT}
                                            className=""
                                            id={assignObj(`${jointText}branchOfServiceOthers${index}`)}
                                            name="branchOfServiceOthers"
                                            type="text"
                                            onChange={this.handleChange('branchOfServiceOthers')}
                                            onBlur={this.handleBlur('branchOfServiceOthers')}
                                            required
                                            value={branchOfServiceOthers || ''}
                                            errortext={errorMsg.branchOfServiceOthers}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="false"
                                        />
                                    </Row>
                                )}
                                {branchOfService !== '06' && (
                                    <FieldValue
                                        as="p"
                                        id={`${jointText}rank${index}Label`}
                                        label="Rank"
                                        value={
                                            <SelectDropDown
                                                aria-labelledby={`${jointText}rank${index}Label`}
                                                id={`${jointText}rank${index}`}
                                                data-name="Branch of Service"
                                                value={rank}
                                                onChange={this.handleChange('rank')}
                                                errortext={errorMsg.rank}
                                                itemlist={rankOp}
                                                style={selectDropDownStyles}
                                            />
                                        }
                                        labelsm={4}
                                        valuesm={5}
                                        mb={1.5625}
                                        noGutters
                                        optional
                                    />
                                )}
                                {branchOfService === 'other' && (
                                    <Row>
                                        <WInput
                                            label="Rank"
                                            placeholder="Rank"
                                            className=""
                                            id={assignObj(`${jointText}rank${index}`)}
                                            name="rank"
                                            type="text"
                                            onChange={this.handleChange('rank')}
                                            onBlur={this.handleBlur('rank')}
                                            required
                                            value={rank || ''}
                                            errortext={errorMsg.rank}
                                            labelsm={4}
                                            valuesm={5}
                                            optional="false"
                                        />
                                    </Row>
                                )}
                                <Row>
                                    <Form.Group
                                        as={Row}
                                        className="no-gutters"
                                        style={assignObj({
                                            width: '100%',
                                            alignItems: 'center',
                                            marginBottom: 25,
                                        })}
                                    >
                                        <span
                                            className="label dateofServiceLabel col-sm-4"
                                            style={styles.labelTextStyle}
                                            id="Date-of-service"
                                            
                                        >
                                            {dateOfService}
                                            <span style={subLabel}>{optionalText}</span>
                                        </span>
                                        <div className={assignObj(`serviseDates col-sm-5 ${serviceDateError}`)}>
                                            <div
                                                data-alignitems="center"
                                                data-flexdirection="row"
                                                className="sc-fHxwqH fYPSWj datePickerRow fromDate row no-gutters"
                                            >
                                                <label
                                                    htmlFor={fromText}
                                                    className=" form-label col-form-label col-sm-12"
                                                    id={assignObj(`${jointText}from-Date${index}`)}
                                                    
                                                >
                                                    {fromText}
                                                </label>
                                                <div className="col-sm-12">
                                                    <DatePicker
                                                        tabIndex="0"
                                                        name="serviceFromDate"
                                                        id={assignObj(`${jointText}serviceFromDate${index}`)}
                                                        selected={
                                                            this.getCorrectFormatValue(serviceFromDatepickerValue) &&
                                                            new Date(serviceFromDatepickerValue)
                                                        }
                                                        onChange={date =>
                                                            this.handleDateChange(date, 'serviceFromDate')
                                                        }
                                                        onBlur={date =>
                                                            this.handleDateBlur(serviceFromDate, 'serviceFromDate')
                                                        }
                                                        onSelect={date =>
                                                            this.handleDateBlur(date, 'serviceFromDate')
                                                        }
                                                        className={
                                                            errorMsg.serviceFromDate
                                                                ? 'analytics-form-fields errorInputField'
                                                                : 'analytics-form-fields'
                                                        }
                                                        selectsStart
                                                        preventOpenOnFocus
                                                        startDate={
                                                            this.getCorrectFormatValue(serviceFromDatepickerValue) &&
                                                            new Date(serviceFromDatepickerValue)
                                                        }
                                                        endDate={
                                                            this.getCorrectFormatValue(serviceToDatepickerValue) &&
                                                            new Date(serviceToDatepickerValue)
                                                        }
                                                        filterDate={date => {
                                                            return moment() > date;
                                                        }}
                                                        ariaLabelledBy={
                                                            'from-Date Date-of-service from-Date0 serviceFromDate-hint0'
                                                        }
                                                        aria-describedby={assignObj(
                                                            `${jointText}serviceFromDate-hint${index}`,
                                                        )}
                                                    />
                                                    <small id={assignObj(`${jointText}serviceFromDate-hint${index}`)}>
                                                        {dateFormatText}
                                                    </small>
                                                    <br />
                                                    {errorMsg.serviceFromDate && (
                                                        <small
                                                            className="errorMsgInputStyle analytics-error-field"
                                                            aria-live="polite"
                                                            aria-atomic="true"
                                                            id={assignObj(`${jointText}serviceFromDate-error${index}`)}
                                                        >
                                                            {errorMsg.serviceFromDate}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                data-alignitems="center"
                                                data-flexdirection="row"
                                                className="sc-fHxwqH fYPSWj datePickerRow fromDate row no-gutters"
                                            >
                                                <label
                                                    htmlFor={toText}
                                                    className="form-label col-form-label col-12"
                                                    id={assignObj(`${jointText}to-Date${index}`)}
                                                >
                                                    {toText}
                                                </label>
                                                <div className="col-sm-12">
                                                    <DatePicker
                                                        name="serviceToDate"
                                                        id={assignObj(`${jointText}serviceToDate${index}`)}
                                                        tabIndex="0"
                                                        selected={
                                                            this.getCorrectFormatValue(serviceToDatepickerValue) &&
                                                            new Date(serviceToDatepickerValue)
                                                        }
                                                        onChange={date => this.handleDateChange(date, 'serviceToDate')}
                                                        onBlur={date =>
                                                            this.handleDateBlur(serviceToDate, 'serviceToDate')
                                                        }
                                                        onSelect={date =>
                                                            this.handleDateBlur(date, 'serviceToDate')
                                                        }
                                                        className={
                                                            errorMsg.serviceToDate
                                                                ? 'analytics-form-fields errorInputField'
                                                                : 'analytics-form-fields'
                                                        }
                                                        selectsEnd
                                                        preventOpenOnFocus
                                                        startDate={
                                                            this.getCorrectFormatValue(serviceFromDatepickerValue) &&
                                                            new Date(serviceFromDatepickerValue)
                                                        }
                                                        endDate={
                                                            this.getCorrectFormatValue(serviceToDatepickerValue) &&
                                                            new Date(serviceToDatepickerValue)
                                                        }
                                                        minDate={
                                                            this.getCorrectFormatValue(serviceFromDatepickerValue) &&
                                                            new Date(serviceFromDatepickerValue)
                                                        }
                                                        ariaLabelledBy="to-Date Date-of-service to-Date0 serviceToDate-hint0"
                                                        aria-describedby="serviceToDate-hint"
                                                    />
                                                    <small id={assignObj(`${jointText}serviceToDate-hint${index}`)}>
                                                        {dateFormatText}
                                                    </small>
                                                    <br />
                                                    {errorMsg.serviceToDate && (
                                                        <small
                                                            className="analytics-error-field"
                                                            aria-live="polite"
                                                            aria-atomic="true"
                                                            id={assignObj(`${jointText}serviceFromDate-error${index}`)}
                                                            style={{
                                                                position: 'absolute',
                                                                color: 'rgba(230, 0, 0, 1)',
                                                                font:
                                                                    '700 12px/22px benton-sans, yorkten-slab-normal, sans-serif',
                                                            }}
                                                        >
                                                            {errorMsg.serviceToDate}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <WInput
                                        label="Commission Source"
                                        className=""
                                        id={assignObj(`${jointText}commissionCourse${index}`)}
                                        name="commissionCourse"
                                        type="text"
                                        subtype="characters"
                                        onChange={this.handleChange('commissionCourse')}
                                        onBlur={this.handleBlur('commissionCourse')}
                                        required={false}
                                        aria-required={false}
                                        value={commissionCourse || ''}
                                        errortext={errorMsg.commissionCourse}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={30}
                                        optional="true"
                                    />
                                </Row>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Accordian>
              </>
        );
    }
}

MarketMilitaryInfoCard.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    primaryDOB: PropTypes.string,
    jointText: PropTypes.string,
    index: PropTypes.number,
};

MarketMilitaryInfoCard.defaultProps = {
    getCompositeLookUpData: () => {},
    masterLookupStateData: {},
    savedState: {},
    isJoint: false,
    primaryDOB: '',
    jointText: '',
    index: 0,
};

export default MarketMilitaryInfoCard;
