const Styles = {
  main: {
    background: '#F5F8EC 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 25px rgba(0,0,0,0.16)',
    border: '2px solid #D1E8A4',
    minHeight: 102,
    maxHeight: 128,
    padding: '40px 0px',
    width: '100%',
    zIndex: 100,
    boxSizing: 'border-box',
  },
  message: {
    font: '600 18px/26px benton-sans',
    color: '#49494A',
    margin: 0,
    display: 'inline-block',
    paddingLeft: 15, /* ,
          height: 19 */
  },
  info: {
    font: '500 18px/26px benton-sans',
    color: '#49494A',
    display: 'inline-block',
    marginTop: 10,
    marginBottom: 0,
    paddingLeft: 15,
    height: 19,
  },
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    marginTop: 20,
    marginBottom: 20,
    borderTop: '1px solid',
    borderBottom: '1px solid',
    padding: 20,
  },
  revealButton: {
    marginLeft: 1.5,
    padding: 0,
    color: 'rgb(0, 74, 152)',
    font: '600 18px/26px benton-sans',
    background: 'transparent',
    border: 'none',
    borderBottom: '1px solid',
  },
  margin: {
    margin: '0',
    justifyContent: 'center',
  },
};

export default Styles;
