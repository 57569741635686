import React from 'react';
import { ReactComponent as ChatBubbleIcon } from '../../../assets/chat_bubble.svg';
import envConfig from 'env/EnvConfig';

const guestCardData = {
    savedItems: {
        cardId: 1,
        cardHeading: 'My Saved Items',
        cardLinks: [
            {
                id: 0,
                linktext: 'Complete your Roth IRA Account application (saved 28 Feb 2020)',
                link: '#',
                savedate: 'Saved Feb 28,2020, 3:40pm EST',
                attachment: false,
            },
            {
                id: 1,
                linktext: 'Recommendation from portfolio planner',
                link: '#',
                savedate: 'March 10,2020, 4:03pm EST',
                attachment: true,
            },
        ],
    },

    resourcesTools: [
        {
            id: 1,
            link: `${envConfig.ENV_URL}/products`,
            linkText: 'Learn About Our Products',
            Desc:
                'Choose from an array of products, including Mutual Funds, ETFs, Retirement Products, and a 529 savings plan. Which is right for you?',
        },
        {
            id: 2,
            link: `${envConfig.ENV_URL}/advice-tools/investor-learning-center`,
            linkText: 'Achieve Your Goals',
            Desc:
                'Interested in learning more about investment basics or how to invest to achieve specific goals, such as savings for college or retirement? Learn more',
        },
    ],

    features: [
        {
            id: 0,
            link: `${envConfig.ENV_URL}/products/mutual-funds`,
            linkText: 'USAA® Mutual Funds',
            Desc: 'Learn about the wide variety of USAA Mutual Funds that are available to help you meet your goals.',
        },
        {
            id: 1,
            link: `${envConfig.ENV_URL}/products/usaa-529-college-savings-plan`,
            linkText: 'USAA® 529 Education Savings Plan',
            Desc: 'Save for future education expenses by investing in a tax-advantaged USAA 529 Savings Plan.',
        },
        {
            id: 2,
            link:`${envConfig.ENV_URL}/products/retirement`,
            linkText: 'Planning for a Secure Retirement',
            Desc:'Victory Capital makes retirement planning simple and accessible, offering a full range of solutions.',
        },
    ],

    pageconsts: {
        myportfolio: 'My Portfolio',
        guestPortfolioHeading: 'Open an Account',
        guestPortfolioSubTitleHeading: 'Invest in your future.',
        guestPortfolioSubTitle:
            'We offer account types for just about any situation. Explore the wide variety of USAA Mutual Funds that are available to help you meet your investment needs. Or the USAA 529 to begin tax advantaged saving for the rising cost of education. Open an account today.',
        guestPortfolioSubTitleOld:
            'We offer account types for just about any situation. Explore the wide variety of USAA Mutual Funds that are available to help you meet your investment needs. Or the USAA 529 to begin tax advantaged saving for the rising cost of education. Open an account today.',
        contactcenter: 'Contact Center',
        subtitle:
            'Opening an account will allow you to invest in Victory funds, monitor their performances and associate them with life goals.',
        generalInvAcc: "An account that doesn't have the restrictions - or tax benifits - of a retirement account.",
        generalInvAccTitle: 'General Investment Account',
        retirementAcc:
            'An individual retirement account(IRA) allows you to save money for retirement with some tax-benefits.',
        retirementAccTitle: 'IRA (Retirement Account)',
        InforChildTitle: 'Investing for Children',
        InforChild: 'An account that allows yoou to save for education or invest on behalf of a minor.',
        specialtyAccTitle: 'Specialty Account',
        specialtyAcc:
            'Contact a service representative to open a specialty account such as trust, estate, or SIMPLE IRA.',
        resourcestools: 'Resources and Tools',
        headerLine: 'Portfolio Planner',
        portPlanner:
            'Building an investment portfolio to support your investment goals is as easy as answering a few simple questions. Get started now.',
        features: 'Features',
    },

    disableStyle: { display: 'none' },
    cardstyle: {
        margin: '10px 0px  10px 0px',
        minHeight: '10.142em',
        maxHeight:'10.142em',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        opacity: 1,
        cursor: 'pointer',
    },
    cardImgStyle: { 
        width: '8%',
        paddingBottom: '75px',
        marginLeft: '20px',
    },
    cardtextstyle: {
        font: '500 14px/20px benton-sans',
        color: '#49494A',
        maxWidth: '80%',
        textAlign: 'left',
        letterSpacing: 0,
        opacity: 1,
        marginBottom: 0,
    },
    cardtitlestyle: {
        font: '800 16px/18px yorkten-slab-normal',
        color: '#486D90',
        paddingBottom: '14px',
        borderBottom: '1px solid #D2D2D2',
    },
    cardbodystyle: {
        padding: '28px',
    },
    footerData :[
        { id: 'ic-modal', SvgComponent: <ChatBubbleIcon />, message: 'Live Chat', link: '/accountguestview',show:false },
    ],
    assignObj: obj => obj,

    accountTypeKey: 'acct_type',
    general: 'General Investment',
    individual: 'Individual Retirement',
    investingforminors: 'Investing for Minors',
    specialityAccounts: 'Specialty Accounts',
    open529Account: 'Open a 529 Account',
    openMarketAccount:'Open a Marketplace Account',
    overviewTitle: 'Open an Account, High-Level \r\n Pitch for Overview Tab',
    fundsTitle: 'Open an Account, High-Level \r\n Pitch for Funds Tab',
    marketTitle:'Open an Account, High-Level \r\n Pitch for Markets Tab',
    educationTitle:'Open an Account, High-Level \r\n Pitch for Education Tab',
    REMOVE_SAVED_ITEM_MESSAGE: 'Are you sure you want to delete the saved application ?',
    REMOVE_SAVED_ITEM_HEDING: 'Delete Saved Application'

};

export default guestCardData;
