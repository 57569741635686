
import React from 'react';
import PropTypes from 'prop-types';
/* import {Form} from  'react-bootstrap'; */
import styles from '../CommonStyles';

const assignObj = (obj) => { return obj; };

const WRadioList = props => {
    const { radiostyles,radioFor, onChange,radioItems,radioTextStyles,selectedValue } = props;
    return (<>
        {radioItems.map((item)=>{
            const{key,value,disabled}=item
            const uniqueKey= `${radioFor}${key}`
            const selected= uniqueKey === selectedValue;
            let radioClass = 'wradio';
            radioClass += selected ? ' selected' : '';
           return <div className="container-fluid" key={uniqueKey} id={radioFor} role="group"> 
           <span className={radioClass} style={styles.paddingZeroStyle} id={radioFor}>
                <input id={uniqueKey} name={radioFor} type="radio" onChange={onChange} value={uniqueKey} checked={selected} aria-checked={selected} disabled={Boolean(disabled)}/>
                <label style={assignObj({ ...styles.radioStyles, ...radiostyles })} htmlFor={uniqueKey}>
                        <span style={assignObj({ ...styles.radioText, ...radioTextStyles })}>{value}</span>
                </label>
            </span>
            </div>
        })}
        </>
    )
};


WRadioList.propTypes = {
    radioFor:PropTypes.string,
    radiostyles: PropTypes.instanceOf(Object),
    radioTextStyles: PropTypes.instanceOf(Object),
    onChange: PropTypes.func,
    radioItems:PropTypes.instanceOf(Object),
    selectedValue:PropTypes.string,
}

WRadioList.defaultProps = {
    radioFor: 'defaultRadioGroup',
    radiostyles: {},
    radioTextStyles:{},
    onChange: () => {},
    radioItems:
    [
        {   
            key:'online',
            value:'Online'
        },
        {
            key:'paper',
            value:'Paper'
        },
        
    ],
    selectedValue:'',
}

export default WRadioList;







