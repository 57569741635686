
export const RESET_TRACK_DOWNLOAD_CLICK = 'RESET_TRACK_DOWNLOAD_CLICK';
export const ANALYZE_DOWNLOAD_CLICK_EVENT = 'ANALYZE_DOWNLOAD_CLICK_EVENT';

export const RESET_TRACK_FORM_FILED_ERROR = 'RESET_TRACK_FORM_FILED_ERROR';
export const ANALYZE_FORM_FIELD_ERRORS = 'ANALYZE_FORM_FIELD_ERRORS';

export const RESET_TRACK_FORM_ABANDONMENT = 'RESET_TRACK_FORM_ABANDONMENT';
export const ANALYZE_FORM_ABANDONMENT = 'ANALYZE_FORM_ABANDONMENT';

export const RESET_API_RESPONSE = 'RESET_API_RESPONSE';
export const API_RESPONSE = 'API_RESPONSE';
export const ANALYZE_CLICK_EVENT = 'ANALYZE_CLICK_EVENT';

export const RESET_UPDATE_ANALYTIC_PAGE_DETAILS = 'RESET_UPDATE_ANALYTIC_PAGE_DETAILS';
export const UPDATE_ANALYTIC_PAGE_DETAILS = 'UPDATE_ANALYTIC_PAGE_DETAILS';
