import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {WMessageBar, WSpinner } from '../../../common';
import FormsHeader from './FormsHeaders';
import FormList from './FormList';
import Styles from './Styles';
import axios from 'axios';
import envConfig from 'env/EnvConfig';

function AccessForms() {
    const dispatch = useDispatch();
    
    const [pdfUrlData,setPdfUrlData] = useState([]);
    const [viewableData,setViewableData] = useState([]);
    const [victoryfundspdfUrlData,setVictoryfundspdfUrlData] = useState([]);
    const [marketplacepdfUrlData,setMarketplacepdfUrlData] = useState([]);
    const [educationpdfUrlData,setEducationpdfUrlData] = useState([]);
    const [msrfundbeanpdfUrlData,setMsrfundbeanpdfUrlData] = useState([]);
    const [robopdfUrlData,setRobopdfUrlData] = useState([]);
    const [apiresponseStatus,setApiresponseStatus] = useState([]);
    const [selectedTab,setSelectedTab] = useState('All');
    const [apiResponseData,setApiResponseData] = useState([]);
    const [selectedCategory,setSelectedCategory] = useState('All');
    const [selectedTabTitle,setSelectedTabTitle] = useState("All");
    const [isLoading,setIsLoading] = useState(true);
    const [searchItem,setSearchItem] = useState('');
    const [searchInternalVal, setSearchInternalVal] = useState('');
    const [internalfilterData,setInternalFilterData] = useState([]);
    const [formCount,setFormCount] = useState({
        victoryFundCount:1,
        marketplaceCount:1,
        educationCount:1,
        internal:1,
        roboCount:0
    });

    const [filterData,setFilterData] = useState([]);

    const [tags, setTags] = useState([
        {
            id: '1',
            label: 'All',
            value:'All',
            title:"All",
            selected: true,
            count:1
        },
        {
            id: '2',
            label: 'Victory Funds',
            value: 'victory-fund',
            title:"Victory Funds",
            selected: false,
            count:1
        },
        {
            id: '3',
            label: 'Education',
            value: 'education',
            title:"Education",
            selected: false,
            count:1
        },
        {
            id: '4',
            label: 'Marketplace',
            value: 'marketplace',
            title:"Marketplace",
            selected: false,
            count:1
        },
        {
            id: '5',
            label: 'Internal',
            value: 'msr-forms',
            title:"Internal",
            selected: false,
            count:1
        },
        {
            id: '6',
            label: 'Robo',
            value: 'robo',
            title:"Robo",
            selected: false,
            count:1
        },
    ]);


    useEffect(() => {
        document.title = 'Forms | Victory Capital';
        loadPdfContent();
    }, [dispatch]);

    
    const loadPdfContent = () => {
        const url = `${envConfig.ENV_URL}/content/vcm/forms.userforms_private.json`;
        const instance = axios.create({});
        delete instance.defaults.headers.common['x-api-key'];
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['msrid'];
        delete instance.defaults.headers.common['fismemberid'];
        delete instance.defaults.headers.common['vcmid'];
        instance
            .get(url, {
                // headers: {
                //     Authorization: `${envConfig.PRELOGIN_HEADER_AUTH}`,
                // },
            })
            .then(response => {
                if (Object.keys(response.data).length > 0) {
                    setApiResponseData(response.data)
                    if (Object.keys(response.data).length > 0) {

                        // victory funds
                        const victoryfundspdfresponseData = response.data.victoryFund.accountApplication.map(item => ({
                            fundType:item.fund,
                            category: item.category,
                            formID: item.formID,
                            pdfTitle: item.pdfTitle,
                            pdfDescription: item.pdfDescription,
                            pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                        }))
                        .concat(
                            response.data.victoryFund.service.map(item => ({
                                fundType:item.fund,
                                category: item.category,
                                formID: item.formID,
                                pdfTitle: item.pdfTitle,
                                pdfDescription: item.pdfDescription,
                                pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                            }))
                        );

                        // marketplace
                        const marketplacepdfresponseData = response.data.brokerage.accountApplication.map(item => ({
                            fundType:item.fund,
                            category: item.category,
                            formID: item.formID,
                            pdfTitle: item.pdfTitle,
                            pdfDescription: item.pdfDescription,
                            pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                        }))
                        .concat(
                            response.data.brokerage.service.map(item => ({
                                fundType:item.fund,
                                category: item.category,
                                formID: item.formID,
                                pdfTitle: item.pdfTitle,
                                pdfDescription: item.pdfDescription,
                                pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                            }))
                        );

                        // education
                        const educationpdfresponseData = response.data.educationSavings.accountApplication.map(item => ({
                            fundType:item.fund,
                            category: item.category,
                            formID: item.formID,
                            pdfTitle: item.pdfTitle,
                            pdfDescription: item.pdfDescription,
                            pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                        }))
                        .concat(
                            response.data.educationSavings.service.map(item => ({
                                fundType:item.fund,
                                category: item.category,
                                formID: item.formID,
                                pdfTitle: item.pdfTitle,
                                pdfDescription: item.pdfDescription,
                                pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                            }))
                        );
                        // msrfundbean
                        const msrfundbeanpdfresponseData = response.data.msrFundBean.accountApplication.map(item => ({
                            fundType:item.fund,
                            category: item.category,
                            formID: item.formID,
                            pdfTitle: item.pdfTitle,
                            pdfDescription: item.pdfDescription,
                            pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                        }))
                        .concat(
                            response.data.msrFundBean.service.map(item => ({
                                fundType:item.fund,
                                category: item.category,
                                formID: item.formID,
                                pdfTitle: item.pdfTitle,
                                pdfDescription: item.pdfDescription,
                                pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                            }))
                        );

                        // robo
                        const robopdfresponseData = response.data.robo.accountApplication.map(item => ({
                            fundType:item.fund,
                            category: item.category,
                            formID: item.formID,
                            pdfTitle: item.pdfTitle,
                            pdfDescription: item.pdfDescription,
                            pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                        }))
                        .concat(
                            response.data.robo.service.map(item => ({
                                fundType:item.fund,
                                category: item.category,
                                formID: item.formID,
                                pdfTitle: item.pdfTitle,
                                pdfDescription: item.pdfDescription,
                                pdfPath: `${envConfig.ENV_URL}${item.pdfPath}`,
                            }))
                        );


                        setPdfUrlData([...victoryfundspdfresponseData,...marketplacepdfresponseData,...educationpdfresponseData, ...msrfundbeanpdfresponseData, ...robopdfresponseData]);
                        
                        
                        setFundCount('victory-fund',victoryfundspdfresponseData,tags);
                        setFundCount('education',educationpdfresponseData,tags);
                        setFundCount('marketplace',marketplacepdfresponseData,tags);
                        setFundCount('msr-forms',msrfundbeanpdfresponseData,tags);
                        setFundCount('robo',robopdfresponseData,tags);



                        setApiresponseStatus(true);
                        setIsLoading(false);
                    }
                }
            })
            
            .catch((error) => {});
        ;
    };

    const setFundCount = (key,fundList,tabTags)=>
    {
        const filterData = tabTags?.filter((item)=> item.value == key)
        if(filterData?.length > 0)
        {
            filterData[0].count = fundList?.length;
        }

    }

    const handleselectedTab= (selectedTab,selectedTabTitle)=>{
        setSelectedTab(selectedTab)

        setSelectedTabTitle(selectedTabTitle)
    }
    const hadleFormTypeFilter =()=>{
      
        let filterData = []
        const searchInternalItem = selectedTab === "msr-forms"? searchInternalVal : searchItem
        
        const lowercaseSearchInternalItem = searchInternalItem.toLowerCase().trim(); // Convert search input to lowercase
        
        if(lowercaseSearchInternalItem != ''){

            if(selectedTab == 'All' && selectedCategory == 'All'){
                filterData = pdfUrlData?.filter((item)=> (item.formID?.toLowerCase().includes(lowercaseSearchInternalItem) || item.pdfTitle?.toLowerCase().includes(lowercaseSearchInternalItem)) )
            }else if(selectedTab == 'All' && selectedCategory != 'All' ){
                filterData = pdfUrlData?.filter((item)=> item.category == selectedCategory 
                    && (item.formID?.toLowerCase().includes(lowercaseSearchInternalItem) || item.pdfTitle?.toLowerCase().includes(lowercaseSearchInternalItem)))
            }else if(selectedTab != 'All' && selectedCategory == 'All'){
                filterData = pdfUrlData?.filter((item)=> item.fundType == selectedTab 
                    && (item.formID?.toLowerCase().includes(lowercaseSearchInternalItem) || item.pdfTitle?.toLowerCase().includes(lowercaseSearchInternalItem)))
            }
            else{
                filterData = pdfUrlData?.filter((item)=> item.fundType == selectedTab 
                    && item.category == selectedCategory 
                    && (item.formID?.toLowerCase().includes(lowercaseSearchInternalItem) || item.pdfTitle?.toLowerCase().includes(lowercaseSearchInternalItem)))
            }

        }else if(lowercaseSearchInternalItem === ''){
            if(selectedTab == 'All' && selectedCategory == 'All'){
                filterData = pdfUrlData
            }else if(selectedTab == 'All' && selectedCategory != 'All' ){
                filterData = pdfUrlData?.filter((item)=> item.category == selectedCategory)
            }else if(selectedTab != 'All' && selectedCategory == 'All'){
                filterData = pdfUrlData?.filter((item)=> item.fundType == selectedTab)
            }
            else{
                filterData = pdfUrlData?.filter((item)=> item.fundType == selectedTab && item.category == selectedCategory)
            }
        }


        selectedTab === "msr-forms"? setInternalFilterData(filterData) : setFilterData(filterData)
    }



    useEffect(() => {
      selectedTab!=="msr-forms" ? setSearchInternalVal("") : setSearchItem("")
      hadleFormTypeFilter()
    }, [selectedTab,searchInternalVal, selectedCategory, searchItem,pdfUrlData])

    const selectedInternalTab = selectedTab === "msr-forms"? internalfilterData : filterData

    return (
        <>
            <Styles.Container className="container mt-3">
                
                {isLoading && <WSpinner loading={isLoading} /> } 
                <FormsHeader hadleFormTypeFilter={hadleFormTypeFilter} setSearchItem={setSearchItem} setSearchInternalVal={setSearchInternalVal}
                 pdfUrlData={selectedInternalTab} apiresponseStatus={apiresponseStatus} 
                 handleselectedTab={handleselectedTab} selectedTab={selectedTab} 
                 selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} 
                 selectedTabTitle={selectedTabTitle} 
                 formCount={formCount} 
                 parentTags={tags}
                 
                 />
                
                <FormList  pdfUrlData={selectedInternalTab} apiresponseStatus={apiresponseStatus} selectedTab={selectedTab} />
              
              

            </Styles.Container>
        </>
    );
}

export default AccessForms;
