import currencyFormatter from 'currency-formatter';
import { isMobile } from 'react-device-detect';
import PropTypes, { instanceOf } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
    Flex,
    TopLeftCornerColor,
    RadioGroup,
    PrimaryBtn,
    Label,
    SelectDropDown,
    FieldValue,
    PrefilledInput,
} from 'modules/CustomerManagementModule/components';
import constants from 'modules/AccountOptions/constant';
import { MaskText } from 'common';

const CardContainer = styled(TopLeftCornerColor)`
    margin-bottom: 2.5rem;
`;

const padding = css`
    padding: ${props => props.PadTopBtm}rem 3.125rem;
`;

const labelMargin = css`
    > p {
        margin-bottom: 0.75rem;
    }
`;
const getBorder = value => {
    return (value || '').split(' ');
};

const borderStyle = css`
    ${props =>
        props.border
            ? getBorder(props.border)
                  .map(side => `border-${side}: 1px solid #d2d2d2`)
                  .toString()
                  .replace(',', ';')
            : null};
`;

const TitleContainer = styled(Flex)`
    ${padding};
    ${borderStyle};

    @media (max-width: 767px){
       display:block!important;
       padding-left:20px;
       padding-right:20px;
    }
    .tickerFundNameWrap{
     @media (max-width: 767px){
        align-items: flex-start!important;
        } 
    }
    button{
        @media (max-width: 767px){
            width: 100%;
            margin-left: 0!important;
            margin-top:16px;
         } 
    }
`;

const SectionContainer = styled(Flex)`
    ${padding};
    ${borderStyle};
    ${labelMargin}
    @media (max-width: 767px){
        padding-left:20px;
       padding-right:20px;
     }
     .dividendRadioGroup{
        @media (max-width: 767px){
            padding-left:20px;
           padding-right:20px;
         }
     }
     &.dividendRadioGroupWrap{
         .dividendRadioGroup{
            @media (max-width: 767px){
                display:block!important;
                padding-left:0;
            }
            div{
                @media (max-width: 767px){
                    margin-left:0;
                    padding: 12px 0;
                } 
            }
         }
     }
`;

const Ticker = styled.span`
    padding: 0.375rem 0.75rem;
    background: #004a98 0% 0% no-repeat padding-box;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
`;

const FundName = styled.h2`
    margin: 0 0 0 1rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
`;

const BtnSection = styled.div`
    button {
        min-width: 7.5rem;
    }
    button:last-child {
        margin-left: 1.25rem;
    }
`;

const TransferToContainer = styled.div`
    ${padding};
    ${borderStyle};
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;
        @media (max-width: 767px){
        padding: 20px;
    }
`;

const MANAGE = 'Manage';
const SAVE = 'Save';
const CANCEL = 'Cancel';
const DIVIDEND = 'Dividend';
const CAPITAL_GAINS = 'Capital Gains';
const FROM = 'From:';
const TO = 'To:';

const { income: INCOME, investAnotherFund: INVEST_ANOTHER, reinvest: REINVEST } = constants.dividends.options;

const DIVIDEND_OPTIONS = [
    {
        key: REINVEST,
        value: 'Reinvest Dividend',
    },
    {
        key: INCOME,
        value: 'Take Income',
    },
    {
        key: INVEST_ANOTHER,
        value: 'Invest to another fund',
    },
];
const CAPITAL_GAINS_OPTIONS = [
    {
        key: REINVEST,
        value: 'Reinvest Capital Gains',
    },
    {
        key: INCOME,
        value: 'Take Income',
    },
    {
        key: INVEST_ANOTHER,
        value: 'Invest to another fund',
    },
];

const StyledSpan = styled.span`
text-align: left;
font-family: benton-sans, sans-serif;
font-weight: 600;
font-style: normal;
line-height: 28px;
font-size: 16px;
letter-spacing: 0px;
color: #004a98;
`;


const DropdownSelectedVal = props => {
    const { spanValue } = props;
        return <StyledSpan>{spanValue}</StyledSpan>
}
DropdownSelectedVal.propTypes = {
    spanValue: PropTypes.string
}

DropdownSelectedVal.defaultProps = {
    spanValue: ''
}

const StyledSpan1 = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 28px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #004a98;
    opacity: 1;
    display: block;
  `;

const StyledSpan2 = styled.span`
    text-align: center;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #49494a;
    opacity: 0.75;
    `;

const StyledSpan3 = styled.span`
    border-bottom: 1px solid #d7d7d7d7;
    width: 100%;
    `;

const CustomFundNames = props => {
const { details } = props;
const { fundAccountNumber = '', balance = '', value = '', currentAccountBalance = '' } = details;
const Balance = `Balance: ${currencyFormatter.format(balance || currentAccountBalance, { locale: 'en-US' })}`
return (
    <StyledSpan3>
        <StyledSpan1>{value}</StyledSpan1>
        <StyledSpan1>
        {fundAccountNumber ?
            <MaskText text={fundAccountNumber.toString()} startIndex={0} maskLength={7} />:
            {fundAccountNumber}
        }
        </StyledSpan1>
        <StyledSpan2>{Balance}</StyledSpan2>
    </StyledSpan3>
 )
}

CustomFundNames.propTypes = {
    details: PropTypes.instanceOf(Object)
}

CustomFundNames.defaultProps = {
    details: {}
}

const TitleSection = props => {
    const { isManage, ticker, fundName, handleManage, onCancel, onSave, disableSave } = props;
    const manageLabel = `${MANAGE} ${fundName}`;
    const saveLabel = `${SAVE} ${fundName}`;
    const cancelLabel = `${CANCEL} ${fundName}`;
    // console.log('@log for disableSave ',disableSave)
    const fundNameTxt = fundName.indexOf('USAA') !== -1 ? fundName.replace(/USAA/i,'U S A A') : fundName;
    return (
        <TitleContainer PadTopBtm={1} alignItems="center" justifyContent="space-between" border="bottom">
            <Flex alignItems="center" className="tickerFundNameWrap">
                <Ticker aria-hidden>
                    <span className="sr-only">{ticker.split('').join(' ')}</span>
                    {ticker}
                </Ticker>
                <FundName><span aria-hidden="true">{fundName}</span><span className="sr-only">{fundNameTxt}</span></FundName>
            </Flex>
            <BtnSection>
                {isManage ? (
                    <>
                        <PrimaryBtn disabled={disableSave} inlineBtn aria-label={saveLabel} onClick={onSave}>
                            {SAVE}
                        </PrimaryBtn>
                        <PrimaryBtn inlineBtn aria-label={cancelLabel} onClick={onCancel}>
                            {CANCEL}
                        </PrimaryBtn>
                    </>
                ) : (
                    <PrimaryBtn inlineBtn aria-label={manageLabel} onClick={handleManage}>
                        {MANAGE}
                    </PrimaryBtn>
                )}
            </BtnSection>
        </TitleContainer>
    );
};

TitleSection.propTypes = {
    isManage: PropTypes.bool,
    ticker: PropTypes.string,
    fundName: PropTypes.string,
    handleManage: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    disableSave: PropTypes.bool
};

TitleSection.defaultProps = {
    isManage: false,
    ticker: '',
    fundName: '',
    handleManage: () => {},
    onCancel: () => {},
    onSave: () => {},
    disableSave: true
};

const onRadioChange = ({ onOptionChange, index, isDividend }) => e => {
    const { target, options } = e;
    const { value } = target;
    const { key: selectedKey } = options.find(item => item.value === value);
    onOptionChange(selectedKey, index, isDividend);
};

const Section = props => {
    const { isManage, id, onChange, options, selectedValue, label, border } = props;
    return (
        <SectionContainer PadTopBtm={2} flexDirection="column" border={border} className="dividendRadioGroupWrap">
            <Label as="p" id={id}>
                {label}
            </Label>
            <RadioGroup
                labelledBy={id}
                options={options}
                onChange={onChange}
                initialValue={selectedValue}
                disabled={!isManage}
                className="dividendRadioGroup"
            />
        </SectionContainer>
    );
};

Section.propTypes = {
    isManage: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    options: instanceOf(Object),
    selectedValue: PropTypes.string,
    border: PropTypes.string,
    label: PropTypes.string,
};

Section.defaultProps = {
    isManage: false,
    id: '',
    onChange: () => {},
    options: [],
    selectedValue: '',
    border: '',
    label: '',
};


export const TransferToAnotherFund = props => {
    const { fromFund, toFundOptions, id, handleInvestFundChange, name, value ='' } = props;
    const labelId = `${id}-label`;
    const dropDownId = `${id}-drop-down`;
    const displaySelValBold =  true;
    const CustomOpt = true
    return (
        <div>
            <FieldValue label={FROM} as="p" labelsm={1} valuesm value={<PrefilledInput value={fromFund} />} />
            <FieldValue
                label={TO}
                as="p"
                labelsm={1}
                valuesm={5}
                id={labelId}
                value={<SelectDropDown CustomOpt={CustomOpt} CustomOption={CustomFundNames} 
                            value={value} name={name} displaySelValBold={displaySelValBold} 
                            CustomSelectedVal={DropdownSelectedVal} onChange={handleInvestFundChange} 
                            aria-labelledby={labelId} id={dropDownId} itemlist={toFundOptions}
                            
                        />}
            />
        </div>
    );
};

TransferToAnotherFund.propTypes = {
    fromFund: PropTypes.string,
    toFundOptions: PropTypes.instanceOf(Object),
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    handleInvestFundChange: PropTypes.func
};

TransferToAnotherFund.defaultProps = {
    fromFund: '',
    toFundOptions: [],
    id: '',
    name:'', 
    value:'',
    handleInvestFundChange: () => {}
};

const getOptionValue = ({ isManage, dividend, capitalGains }) => {
    const isDividendIncome = isManage && dividend === INCOME;
    const isCPIncome = isManage && capitalGains === INCOME;
    const isDividendReinvest = isManage && dividend === INVEST_ANOTHER;
    const isCPReinvest = isManage && capitalGains === INVEST_ANOTHER;
    return { isDividendIncome, isCPIncome, isDividendReinvest, isCPReinvest };
};

const getCPBorder = ({ isDividendIncome, isCPIncome }) => {
    let cpBorder = '';
    if (isDividendIncome && isCPIncome) cpBorder = 'top bottom';
    else if (isDividendIncome) cpBorder = 'top';
    else if (isCPIncome) cpBorder = 'bottom';
    return cpBorder;
};

const getFundNo = (fund, allFunds) => {
    let fundNo = ''
    let fundAccNo = ''
    allFunds.filter(el => el.fundAccountNumber === +fund).map(obj => {
        fundNo = obj.fundNumber
        fundAccNo = obj.fundAccountNumber
     return null
    })
    return {fundNo, fundAccNo}
}

// const getFundName = (fundno, funds) => {
//     return funds.filter(fund => fund.fundNumber === +fundno).map(el => {
//         return isMobile ? : `${el.shortFundName} ${el.fundName}`
//     })

// }

const getKey = (fundAccountNumber, toFundOptionsRef) => {
    return toFundOptionsRef.current.filter(el => el.fundAccountNumber === +fundAccountNumber).map(el => el)
}
const getFundDetails = (details, allFunds,handleInvestFundChange,toFundOptionsRef, e) => {
    const { target } = e;
    const { name, value } = target;
    const fundNo = getFundNo(value, allFunds)
    handleInvestFundChange(fundNo, name)
}

const ManageCard = props => {
    const {
        isManage,
        details,
        index,
        handleManage,
        onCancel,
        onSave,
        onOptionChange,
        allFunds,
        renderBanksList,
        disableSave,
        handleInvestFundChange,
        fundPrefernceData
        
    } = props;

    const { shortFundName: ticker, fundName, capitalGains, dividend, isEligibleToIncome, fundAccountNumber } = details;

    const dividendId = `vcm-account-services-dividend-${index}`;
    const capitalGainsId = `vcm-account-services-capital-gains-${index}`;
    const dividendsTransferToAnotherFundId = `vcm-account-services-dividends-transfer-to-another-fund-${index}`;
    const capitalGainsTransferToAnotherFundId = `vcm-account-services-capital-gains-transfer-to-another-fund-${index}`;

    const totalFunds = allFunds.length;
    const accountNoMobileFormat = (value = '') => {
        const valToString = (value || '').toString();
        return `XXX${valToString.substr(valToString.length - 4)}`;
    };
    const dividendOptionsRef = useRef(DIVIDEND_OPTIONS);
    const capitalGainsOptionsRef = useRef(CAPITAL_GAINS_OPTIONS);
    const toFundOptionsRef = useRef(
        allFunds
            .filter(fund => fund.fundAccountNumber !== fundAccountNumber)
            .map((fund) => {
                return isMobile ? { key: fund.fundAccountNumber, value: `${accountNoMobileFormat(fund.fundAccountNumber)}-${fund.fundName}`, ...fund }:
                { key: fund.fundAccountNumber, value: `${fund.shortFundName} ${fund.fundName}`, ...fund }
            }),
    );
    const [ dividendFundVal, setDividendFundVal ] = useState('')
    const [ capGainFundVal, setCapGainFundVal ] = useState('')
    const updateDivFundInvData = (toFundAccountNumber) => {
        // const divFundName = getFundName(toFundNumber, allFunds)
        const divFundKey = getKey(toFundAccountNumber, toFundOptionsRef)
        // console.log('@log for divFundKey ', divFundKey)
        if(divFundKey.length > 0) {
            setDividendFundVal(divFundKey[0].key)
        }
        
    }

    const updateCGFundInvData = (toFundAccountNumber) => {
        // const cgFundName = getFundName(toFundNumber, allFunds)
        const cgFundKey = getKey(toFundAccountNumber, toFundOptionsRef)
        // console.log('@log for cgFundKey ', cgFundKey)
        if(cgFundKey.length > 0) {
            setCapGainFundVal(cgFundKey[0].key)
        }
        
    }

    useEffect(() => {
        const disableOptionsFn = item => {
            let disabled = false;
            if (item.key === INCOME && !isEligibleToIncome) disabled = true;
            if (item.key === INVEST_ANOTHER && totalFunds < 2) disabled = true;
            return { ...item, disabled };
        };
        dividendOptionsRef.current = DIVIDEND_OPTIONS.map(disableOptionsFn);
        capitalGainsOptionsRef.current = CAPITAL_GAINS_OPTIONS.map(disableOptionsFn);
    }, [isEligibleToIncome, totalFunds, fundAccountNumber]);

    useEffect(() => {
        const { toFundList: fundPrefData = [] } = fundPrefernceData;
        fundPrefData.map(data => {
            const { toFundAccountNumber } = data
            if(data.dividendsFlag) {
                updateDivFundInvData(toFundAccountNumber)
            } else {
                updateCGFundInvData(toFundAccountNumber)
            }
          return null
        })

    },[fundPrefernceData])
    const { value: dividendValue = '' } = DIVIDEND_OPTIONS.find(item => item.key === dividend) || {};
    const { value: capitalGainsValue = '' } = CAPITAL_GAINS_OPTIONS.find(item => item.key === capitalGains) || {};

    const fundNameWithTicker = `${ticker} ${fundName}`;

    const { isDividendIncome, isCPIncome, isDividendReinvest, isCPReinvest } = getOptionValue({
        isManage,
        dividend,
        capitalGains,
    });
    function investToFundOnChange(e) {
        getFundDetails(details, allFunds,handleInvestFundChange,toFundOptionsRef,e)
    }

    const cpBorder = getCPBorder({ isDividendIncome, isCPIncome });
    return (
        <CardContainer cornerSize={35} cornerColor="grey">
            <TitleSection
                onSave={onSave}
                onCancel={onCancel}
                isManage={isManage}
                ticker={ticker}
                fundName={fundName}
                handleManage={handleManage}
                disableSave={disableSave}
            />
            <Section
                isManage={isManage}
                id={dividendId}
                onChange={onRadioChange({ onOptionChange, index, isDividend: true })}
                selectedValue={dividendValue}
                options={dividendOptionsRef.current}
                border="bottom"
                label={DIVIDEND}
            />
            {isDividendReinvest && (
                <TransferToContainer border="bottom" PadTopBtm={2} className="dividendTransferWrap">
                    <TransferToAnotherFund
                        id={dividendsTransferToAnotherFundId}
                        fromFund={fundNameWithTicker}
                        toFundOptions={toFundOptionsRef.current}
                        handleInvestFundChange={investToFundOnChange}
                        name={DIVIDEND}
                        value={dividendFundVal}
                    />
                </TransferToContainer>
            )}
            {isDividendIncome && renderBanksList({type: 'dividend', index})}
            <Section
                id={capitalGainsId}
                isManage={isManage}
                onChange={onRadioChange({ onOptionChange, index, isDividend: false })}
                selectedValue={capitalGainsValue}
                options={capitalGainsOptionsRef.current}
                label={CAPITAL_GAINS}
                border={cpBorder}
            />
            {isCPReinvest && (
                <TransferToContainer border="top" PadTopBtm={2}  className="dividendTransferWrap">
                    <TransferToAnotherFund
                        id={capitalGainsTransferToAnotherFundId}
                        fromFund={fundNameWithTicker}
                        toFundOptions={toFundOptionsRef.current}
                        handleInvestFundChange={investToFundOnChange}
                        name={CAPITAL_GAINS}
                        value={capGainFundVal}
                    />
                </TransferToContainer>
            )}
            {isCPIncome && renderBanksList({type: 'capitalGains', index})}
        </CardContainer>
    );
};

ManageCard.propTypes = {
    isManage: PropTypes.bool,
    details: PropTypes.instanceOf(Object),
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleManage: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onOptionChange: PropTypes.func,
    allFunds: PropTypes.instanceOf(Object),
    renderBanksList: PropTypes.func,
    handleInvestFundChange: PropTypes.func,
    disableSave: PropTypes.bool,
    fundPrefernceData: PropTypes.instanceOf(Object)
};

ManageCard.defaultProps = {
    isManage: false,
    details: {},
    index: 0,
    handleManage: () => {},
    onCancel: () => {},
    onSave: () => {},
    onOptionChange: () => {},
    allFunds: [],
    renderBanksList: () => {},
    handleInvestFundChange: () => {},
    disableSave: true,
    fundPrefernceData: []
};

export default ManageCard;