/** Personal  Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Personal information details
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import { Accordian, AccordianTitle, WInput, SSNComponent, VictoryDatePicker, WRadio } from '../../../common';
import Address from '../Address';
import MailingAddressComponent from '../MaililingAddress';
import Consts from '../PersonalInfo/consts';
import checkValidity from '../../../utils/checkValidity';
import { FieldValue, SelectDropDown } from '../../CustomerManagementModule/components';
import { getRole, isValidDate } from 'utils';
import moment from 'moment-timezone';
import MarketContactDetailsCardComponent from '../MarketPhoneDetails/MarketPhoneDetailsCardComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Card } from 'react-bootstrap';
import Rules from '../PersonalInfo/Rules';
import '../MarketPersonalInfoComponent/mstyle.css';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import StyledToolTip from '../MarketPersonalInfoCard/StyledToolTip';
import helperIcon from '../../../assets/helperIcon.png';
import { HelpText_PhysicalAddress, HelpText_FirstName, HelpText_Suffix, HelpText_SSN,residentOption,nonResidentError } from './Consts'

import { MessagePopup } from 'modules/CustomerManagementModule/components';
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts';
const labelTextStyle = {
    font: '600 16px/19px benton-sans',
    color: 'rgb(73, 73, 74)',
    display: 'flex',
    alignItems: 'center',
};
const sublabel = {
    font: '500 12px/14px benton-sans',
    color: '#56565A',
    opacity: 1,
    marginLeft: 10,
};

const assignObj = obj => {
    return obj;
};
const citizenshipLabel = 'Citizenship';
const genderLabel = 'Gender';
const sameAddressRadioLabel = 'Is your mailing address the same as your physical address?';
const sameAddressRadioError = 'Please indicate if the physical address is same as mailing address';
const dateOfBirthLabel = 'Date of Birth';
const physicalAddress = 'Physical Address';
const physicalAddressForJoint = 'Physical Address - Joint Owner';
const ERROR_OFFSCREEN_TEXT = 'Error: ';
const MailingAddress = 'Mailing Address';
const MailingAddressForJoint = 'Mailing Address - Joint Owner';
const jointSameAddressRadioLabel= "Is the joint owner's mailing address the same as their physical address?";

const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const alignSelectAccOpt = { };

const radioText = { font: '500 16px/20px benton-sans' };
const dateFormatText = 'MM/DD/YYYY';

class MarketPersonalInfoCardComponent extends Component {
    constructor(props) {
        super(props);

        const profileCitizenship =
            (props.personalInfo.profileInformation.citizenship !== '' &&
            props.personalInfo.profileInformation.citizenship === 'Non U.S.')
                ? 'nonus'
                : 'us';
        const { initialState, localStateData } = this.props;
        const role = getRole(initialState && initialState.getCustomerProfile);
        this.state = {
            errorMsg: {},
            fullSSN: '',
            isSSNerror: false,
            tooltipOpen: false,
            showMenu: false,
            loading: true,
            perDetailsHeadreClick: true,
            contactDetailsHeadreClick: true,
            citizenship: profileCitizenship || 'us', // By default, U.S should be selected
            resident: '',
            retrievedState: false,
            prefixLocal: '',
            dateOfBirthLocal: '',
            genderLocal: '',
            maritalLocal: localStateData && localStateData.accountType === Consts.JTIE ? 'Married' : '',
            sameAddress: '',
            taxID : 'United States of America',
            relationship:"",
            HelperIcon: false,
            HelperIconForSuffix: false,
            HelperIconForSSN: false,
            HelperTetForPhysical: false,
            residentCardNumber:'',
            passportNumber:'',
            ownerResidentExpiry:'',
            ownerPassportExpiry:'',
            countryOfCitizenshipValue:'',
            isNonResident:"",
            handleOpen:false,
            showNonResidentPopUp: false,
            addressTypeInfo: ""
        };
        this.perMailAdrressPref = React.createRef();
        this.perPhysicalAddressPref = React.createRef();
        this.perMailingAddressPref = React.createRef();
        this.perContactDetailsPref = React.createRef();
        this.phoneDetailsRef = React.createRef();
        this.addressAccordion = React.createRef();
        this.personalRef = React.createRef();
        this.ssnRef = React.createRef();
        this.emailAddressRef = React.createRef();
        this.checkPersonalInfoParentNextStatus = this.checkPersonalInfoParentNextStatus.bind(this);
        this.checkPersonalInfoParentChildNextStatus = this.checkPersonalInfoParentChildNextStatus.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, masterLookupStateData, localStateData,personalInfo } = nextProps;
        const { genderLocal, maritalLocal, prefixLocal, dateOfBirthLocal } = prevState;
        const { OpenAccPageTwo, accountType = '' } = localStateData;
        const isLocalStateData = localStateData && localStateData[accountType.toString()];
        let prefixLocalState = isLocalStateData ? personalInfo.profileInformation.prefix : '';
        let dateOfBirthLocalState = personalInfo.profileInformation.dob;
        let genderLocalState = isLocalStateData ? personalInfo.profileInformation.gender : '';
        let maritalLocalState = isLocalStateData ? personalInfo.profileInformation.maritalStatus : '';

        const openAccPageTwoPersonalInfo = OpenAccPageTwo && OpenAccPageTwo.personalInfo;

        if (genderLocal) {
            genderLocalState = genderLocal;
        }

        if (dateOfBirthLocal) {
            dateOfBirthLocalState = dateOfBirthLocal;
        }

        if (maritalLocal) {
            maritalLocalState = maritalLocal;
        }

        if (prefixLocal) {
            prefixLocalState = prefixLocal;
        }

        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
                citizenship:personalInfo.profileInformation.citizenship,
                sameAddress:personalInfo.profileInformation.addressInformation
            };
        }
        if (masterLookupStateData.isLoading) {
            return { loading: true };
        }
        if (!masterLookupStateData.isLoading && prevState.loading) {

            return {
                loading: false,
            };
        }

        // Error Handling
        if (masterLookupStateData.isError) {
            return { errorFromMasterDataApi: true };
        }

        return {};
    }

    componentDidMount() {
        const { masterLookupStateData, getCompositeData, localStateData,personalInfo,profileInformationData } = this.props;
        const { accountType } = localStateData;
        const accType = accountType;
            const {
                firstName,
                lastName,
                gender,
                suffix,
                dateOfBirth,
                maritalStatus,
                prefix,
                middleInitial,
                relationship
            } =  personalInfo.profileInformation;

                 const isPrimaryMember=  this.props?.jointInfo !="Yes"

            const profileCitizenship =
            this.props.personalInfo.profileInformation.citizenship !== '' &&
            (this.props.personalInfo.profileInformation.citizenship === 'Non U.S' ||
            this.props.personalInfo.profileInformation.citizenship === 'Non U.S.' || 
            this.props.personalInfo.profileInformation.citizenship == "nonus")
                ? 'nonus'
                : 'us'; 

           const residentInfo = personalInfo.profileInformation?.residentStatus != undefined ? personalInfo.profileInformation.residentStatus : 
                isPrimaryMember? profileInformationData.memberProfileInformation?.residentStatus != undefined ? 
                profileInformationData.memberProfileInformation?.residentStatus : '':'';
           
            this.setState({
                firstName,
                lastName,
                genderLocal: gender,
                gender:gender,
                maritalLocal: maritalStatus,
                suffix,
                ownerDateOfBirth: dateOfBirth,
                maritalStatus,
                citizenship:profileCitizenship,
                prefix,
                middleInitial,
                relationship,

                resident: residentInfo,

                residentCardNumber: personalInfo.profileInformation?.residentCardNumber != undefined ? 
                                        personalInfo.profileInformation.residentCardNumber : 
                                           isPrimaryMember? profileInformationData.memberProfileInformation?.residentAlienInformation?.permanentResidentCardNumber != undefined ? 
                                            profileInformationData.memberProfileInformation?.residentAlienInformation?.permanentResidentCardNumber : '':'',


                passportNumber: personalInfo.profileInformation?.passportNumber != undefined ? 
                    personalInfo.profileInformation.passportNumber : 
                    isPrimaryMember?profileInformationData.memberProfileInformation?.residentAlienInformation?.passportNumber != undefined ? 
                    profileInformationData.memberProfileInformation?.residentAlienInformation?.passportNumber : '':'',


                ownerPassportExpiry: personalInfo.profileInformation?.ownerPassportExpiry != undefined ? 
                    personalInfo.profileInformation.ownerPassportExpiry : 
                   isPrimaryMember? profileInformationData.memberProfileInformation?.residentAlienInformation?.passportExpirationDate != undefined ? 
                   isValidDate(profileInformationData.memberProfileInformation?.residentAlienInformation?.passportExpirationDate) ? profileInformationData.memberProfileInformation?.residentAlienInformation?.passportExpirationDate : '' : '':'',


                ownerResidentExpiry: personalInfo.profileInformation?.ownerResidentExpiry != undefined ? 
                    personalInfo.profileInformation.ownerResidentExpiry : 
                        isPrimaryMember?profileInformationData.memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate != undefined ? 
                        isValidDate(profileInformationData.memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate) ? profileInformationData.memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate : '' : '':'',


                countryOfCitizenship: personalInfo.profileInformation?.countryOfCitizenship != undefined ? 
                    personalInfo.profileInformation.countryOfCitizenship : 
                        isPrimaryMember?profileInformationData.memberProfileInformation?.countryOfCitizenship != undefined ? 
                        profileInformationData.memberProfileInformation?.countryOfCitizenship : '':'',  

                addressTypeInfo: personalInfo.profileInformation?.addressInformation[0]?.mailingAddressType        
            });


            if(residentInfo.toLowerCase() === 'non-resident alien'){
                this.props.handleNextDisable(true, 'showNonResidentPopUp')
            }

            if(this.props?.jointInfo =="Yes")
            {
                this.setState(
                    {
                        readOnly: false,
                    },
                    () => {
                        const { errorMSg } = this.state;
                        this.checkPersonalInfoParentChildNextStatus(errorMSg);
                    },
                );
            }
            else
            {
                this.setState(
                    {
                        readOnly: true,
                    },
                    () => {
                        const { errorMSg } = this.state;
                        this.checkPersonalInfoParentChildNextStatus(errorMSg);
                    },
                );
            }
        
       
        if (accType && localStateData.OpenAccPageTwo) {
            const personalData = localStateData.OpenAccPageTwo.personalInfo;
            if (personalData.originalW8FileArray !== undefined) {
                this.setState({ originalW8FileArrayFromMount: personalData.originalW8FileArray });
            }
        }
      
        const payload = [];
        const compositePayloadData = [
            'citizenship',
            'marital_status',
            'prefix',
            'suffix',
            'relationship',
            'gender',
            'contact_time',
        ];
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }

        getCompositeData({ data: { datakey: payload },
            token: getTokenFromSession() ,
           marketNAOFlow:"marketNaoFlow"});
    }

    // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues = () =>{

        this.setState({errorMsg:{}
        })

        this.setState({isSSNerror:false})
        this.phoneDetailsRef.current.onSaveResetStateValues()
        this.emailAddressRef.current.onSaveResetStateValues()
    }

    get accordionRef() {
        const emailRef = this.emailAddressRef;

        const {
            isPersonalInfoFeildsValid,
            isPhoneValid,
            isEmailValid,
            isperMailAddressDataValid,
            isSameAddress,
        } = this.checkValidSections();
        const errorSections = [
            {
                element: this.personalRef.current,
                valid: isPersonalInfoFeildsValid,
            },
            {
                element: this.addressAccordion.current,
                valid: isperMailAddressDataValid,
            },
            {
                element: this.phoneDetailsRef.current.contactAccordion.current,
                valid: isPhoneValid,
            },
            {
                element: emailRef.current.emailAccordion.current,
                valid: isEmailValid,
            },
            {
                element: this.addressAccordion.current,
                valid: isSameAddress,
            },
        ];

        return {
            current: {
                scrollIntoView: options => {
                    const firstErrorSection = errorSections.find(section => !section.valid);
                    if (firstErrorSection?.element) {
                        firstErrorSection.element.scrollIntoView(options);
                    }
                },
            },
        };
    }

    handleDateBlur = (eventKey) => {
        const { errorMsg, ownerDateOfBirth } = this.state;
        let err = '';
        const formatedDate = moment(ownerDateOfBirth).format(dateFormatText);

        const eventId = eventKey.toString() === 'dateOfBirthLocal' ? 'ownerDateOfBirth' : eventKey.toString();
        err = Rules[eventId]
            ? checkValidity({
                  rules: Rules[eventId],
                  value: formatedDate,
              })
            : '';
        errorMsg[eventId] = err;
        this.setState(
            () => {
                const result = this.checkPersonalInfoParentNextStatus(errorMsg);
                return {
                    errorMsg,
                    ready: result,
                };
            },
            () => {
                this.checkPersonalInfoParentChildNextStatus();
            },
        );
    };

    getMaritalStatusDescription = marital => {
        let maritalStatus = marital;

        switch (marital) {
            case 'D':
                maritalStatus = 'Divorced';
                break;
            case 'M':
                maritalStatus = 'Married';
                break;
            case 'S':
                maritalStatus = 'Single';
                break;
            case 'SE':
                maritalStatus = 'Separated';
                break;
            case 'W':
                maritalStatus = 'Widowed';
                break;
            default:
                break;
        }
        return maritalStatus;
    };

  

    handleCloseModal=()=>{
     this.setState({showNonResidentPopUp:false})
    }

    readOnlyMode=(field)=>{
     const{initialState,profileInformationData} =this.props  
     const role = getRole(initialState && initialState.getCustomerProfile);   
     const isPrimaryMember= role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo !="Yes"
     const {memberProfileInformation} = profileInformationData
                   
     if(field=="resident"){           
        return( isPrimaryMember && memberProfileInformation?.residentStatus != undefined && memberProfileInformation?.residentStatus != null  && memberProfileInformation?.residentStatus
     )}
     else if (field=="residentCardNumber"){
       return(isPrimaryMember && memberProfileInformation?.residentAlienInformation?.permanentResidentCardNumber != undefined && memberProfileInformation?.residentAlienInformation?.permanentResidentCardNumber != null  && memberProfileInformation?.residentAlienInformation?.permanentResidentCardNumber
     )}
    else if (field=="ownerResidentExpiry"){
       return(isPrimaryMember &&  memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate != undefined && memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate != null &&
            memberProfileInformation?.residentAlienInformation?.permanentResidentExpirationDate && 
            isValidDate(memberProfileInformation.residentAlienInformation.permanentResidentExpirationDate)
     )}
    else if (field=="passportNumber"){
      return( isPrimaryMember &&  memberProfileInformation?.residentAlienInformation?.passportNumber != undefined &&  memberProfileInformation?.residentAlienInformation?.passportNumber != null  && memberProfileInformation?.residentAlienInformation?.passportNumber
    )} 
    else if (field=="ownerPassportExpiry"){
      return(  isPrimaryMember && memberProfileInformation?.residentAlienInformation?.passportExpirationDate != undefined && memberProfileInformation?.residentAlienInformation?.passportExpirationDate != null &&
        memberProfileInformation?.residentAlienInformation?.passportExpirationDate && 
        isValidDate(memberProfileInformation.residentAlienInformation.passportExpirationDate)
    )}
    else if(field=="countryOfCitizenship"){
    return(  isPrimaryMember && memberProfileInformation?.countryOfCitizenship != undefined && memberProfileInformation?.countryOfCitizenship != null &&
        memberProfileInformation?.countryOfCitizenship && 
        memberProfileInformation?.residentStatus !== null )
    

    }

    }

   

    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';

        if(e.target.value !=undefined && e.target.value !=null && e.target.value !="")
        {
            err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        }
        
        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'dateOfBirthLocal') {
            const { sendDOB } = this.props;
            sendDOB(e.target.value);
            this.setState({ [eventKey]: e.target.value, ownerDateOfBirth: e.target.value });
        }
        if (eventKey === 'maritalLocal') {
            errorMsg['maritalStatus'.toString()] = err;
            this.setState({
                [eventKey]: this.getMaritalStatusDescription(e.target.value),
                errorMsg,
                maritalStatus: e.target.value,
            });
        }  
        
        this.setState({ [eventKey]: e.target.value, errorMsg });
    };

    handleDateChange = (dateValue, eventKey) => {
        const { errorMsg } = this.state;
        let err = '';

        const formatedDate = moment(dateValue).format(dateFormatText);
        const eventId = eventKey.toString() === 'dateOfBirthLocal' ? 'ownerDateOfBirth' : eventKey.toString();
        err = Rules[eventId]
            ? checkValidity({
                  rules: Rules[eventId],
                  value: formatedDate,
              })
            : '';
        errorMsg[eventId] = err;
        if (eventKey === 'dateOfBirthLocal') {
            errorMsg.ownerDateOfBirth = err;
            const { sendDOB } = this.props;
            sendDOB(formatedDate);
            this.setState({ [eventKey]: formatedDate, dateOfBirth: formatedDate, ownerDateOfBirth: formatedDate });
        }else if(eventKey === "ownerResidentExpiry" || eventKey === "ownerPassportExpiry" ){
             this.setState({ [eventKey]:formatedDate,errorMsg})
        } else {
            this.setState({ [eventKey]: formatedDate, errorMsg, dateOfBirth: formatedDate, ownerDateOfBirth: formatedDate });
        }
    };

    cardHeaderClick = e => {
        e.preventDefault();
        e.target.classList.toggle('expand');
        const { perDetailsHeadreClick } = this.state;
        this.setState({ perDetailsHeadreClick: !perDetailsHeadreClick });
    };

    cardHeadersClick = e => {
        e.preventDefault();
        e.target.classList.toggle('expand');
        const { contactDetailsHeadreClick } = this.state;
        this.setState({ contactDetailsHeadreClick: !contactDetailsHeadreClick });
    };

    clearInitialFiles = () => {
        this.setState({
            localForm: null,
            w8FormUpload: undefined,
            originalW8FileArray: null,
            originalW8FileArrayFromMount: null,
        });
    };

    onDrop = files => {
        const fileProperties = ['lastModified', 'lastModifiedDate', 'name', 'size', 'type', 'webkitRelativePath'];
        const fileBlob = files;
        const newFile = {};
        fileProperties.forEach(key => {
            newFile[key.toString()] = fileBlob[key.toString()];
        });

        return newFile;
    };

    uploadW8File = fileData => {
        if (fileData !== undefined) {
            const { originalW8FileArray, W8FileUploaded } = this.state;
            if (originalW8FileArray === null || (originalW8FileArray === undefined && !W8FileUploaded)) {
                const fileMetaData = this.onDrop(fileData.originalw8FormUpload);
                this.setState({
                    w8FormUpload: fileData.w8FormUpload[0].base64String,
                    originalW8FileArray: [fileData.originalw8FormUpload],
                    fileMetaData,
                    w8FileData: fileData.originalw8FormUpload,
                });
            }
        }
    };

    toggleTooltip = () => {
        const { tooltipOpen } = this.state;
        this.setState({ tooltipOpen: !tooltipOpen });
    };

    checkEachFieldStatus = finalFields => {
        let error = {};
        let valid = false;
        const stateData = this.state;
        const invalidFiled = [];
        const { localStateData, initialState } = this.props;
        const { accountType } = localStateData;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedSSNData } = savedData;
        const role = getRole(initialState && initialState.getCustomerProfile);
        finalFields.forEach(field => {
            let errorField = '';
            switch (field) {
                case 'ownerDateOfBirth':
                case 'maritalStatus':
                case 'firstName':
                case 'lastName':
                      case 'ownerResidentExpiry' :  
                case 'ownerPassportExpiry' :
                    case 'countryOfCitizenship':
                    case 'residentCardNumber':  
               case 'passportNumber'  : 
                case 'relationship':
                    const rule = Rules[field.toString()];
                    errorField = rule
                        ? checkValidity({
                              rules: rule,
                              value: stateData[field.toString()] === 'Error' ? '' : stateData[field.toString()],
                          })
                        : '';      
                    break;
                case 'SSN':
                    let ssnStateData = `${stateData?.fullSSN}`;
                    let ssnSavedDate = `${savedSSNData?.fullSSN}`;
                    if (ssnStateData?.includes('XX') == true || ssnSavedDate?.includes('XX') == true) {
                        errorField = Rules.memberSSN
                            ? checkValidity({
                                  rules: Rules.memberSSN,
                                  value:
                                      stateData.fullSSN ||
                                      (savedSSNData &&
                                          savedSSNData.fullSSN
                                              .split('')
                                              .slice(5, 9)
                                              .join()
                                              .replace(/,/g, '')) ||
                                      '',
                              })
                            : '';
                    } else {            
                        errorField = Rules[field.toString()]
                            ? checkValidity({
                                  rules: Rules[field.toString()],
                                  value: stateData.fullSSN || (savedSSNData && savedSSNData.fullSSN) || '',
                              })
                            : '';
                        if (errorField !== null) {
                            this.setState({ isSSNerror: true });
                        } else {
                            this.setState({ isSSNerror: false });
                        }  
                    }
                    break;
                case 'gender':
                case 'resident':
                case 'citizenship':
                    errorField = stateData[field.toString()] ? !stateData[field.toString()] : true;
                    break;
                default:
                    break;
            }

            if (typeof errorField === 'boolean') {
                valid = errorField === true;
                invalidFiled.push(valid);
            } else if (typeof errorField === 'string') {
                valid = errorField !== '';
                invalidFiled.push(valid);
            }
            error = { ...error, [field]: errorField };
        });

        valid = !invalidFiled.some(filed => filed === true);
        return { error, valid };
    };

    fieldsValid = () => {
        const { citizenship,resident } = this.state;
        let { errorMsg } = this.state;
        const { localStateData } = this.props;

        let fields = [];
        if(this.props?.jointInfo == "Yes")
        {
             fields = [
                'ownerDateOfBirth',
                'ownerPassportExpiry',
                'passportNumber',
                'gender',
                'citizenship',
                'SSN',
                'sameAddress',
                'firstName',
                'lastName',
                'relationship'
            ];
        }
        else
        {
             fields = [
                'ownerDateOfBirth',
                'gender',
                'ownerPassportExpiry',
                'passportNumber',
                'citizenship',
                'SSN',
                'sameAddress',
                'firstName',
                'lastName'
            ];
        }
        if (localStateData && localStateData.accountType !== Consts.JTIE) {
            fields.push('maritalStatus');
        }
        if (citizenship === 'nonus' && resident=='Resident Alien' ) {
            fields.push('countryOfCitizenship');
            fields.push('residentCardNumber');
            fields.push('ownerResidentExpiry');
        }
        if (citizenship === 'nonus' ) {
            fields.push( 'resident');

        }

        const status = this.checkEachFieldStatus(fields);
        errorMsg = { ...errorMsg, ...status.error };
        this.setState({ errorMsg });
        return { valid: status.valid, errorMsg };
    };

    getChildData = (action = 'defaultVal') => {
        const phoneDetailsData = this.phoneDetailsRef.current && this.phoneDetailsRef.current.sendDataToParent();
        const perMailAddressData = this.perMailAdrressPref.current && this.perMailAdrressPref.current.getAddress();
        const perPhysicalAddressData =
            this.perPhysicalAddressPref.current && this.perPhysicalAddressPref.current.getAddress();
        const perMailingAddressData =
            this.perMailingAddressPref.current && this.perMailingAddressPref.current.getAddress();
        const emailAddressData = this.emailAddressRef.current && this.emailAddressRef.current.sendDataToParent();
        let ssnData = this.ssnRef.current && this.ssnRef.current.getSSN();
        
        // removing the error messages/boolean values and sending the modified data to the payload   
        if(action === 'save'){
            this.ssnRef.current.onSaveResetStateValues()
            if(this.ssnRef.current && ssnData?.isSSNOneError || ssnData?.isSSNTwoError || ssnData?.isSSNThreeError  ){
                ssnData = {
                    ...ssnData,
                    isSSNOneError:false,
                    isSSNThreeError:false,
                    isSSNTwoError:false,
                }
            }
        }
       
        
        return {
            phoneDetailsData,
            perMailAddressData,
            perPhysicalAddressData,
            perMailingAddressData,
            emailAddressData,
            ssnData,
        };
    };

    openAccrodions = (validPerInfo, validPhoneInfo, validEmailInfo, validAddressInfo) => {
        if (!validPerInfo) {
            this.personalRef.current.click();
        }

        if (!validPhoneInfo) {
            this.phoneDetailsRef.current.contactAccordion.current.click();
        }

        if (!validEmailInfo) {
            this.emailAddressRef.current.emailAccordion.current.click();
        }

        if (!validAddressInfo) {
            this.addressAccordion.current.click();
        }
    };

    checkValidSections = (action = 'defaultVal') => {
        const childData = this.getChildData(action);
        let { sameAddress } = this.state;
        const { phoneDetailsData, ssnData } = childData;
        const { valid: isPersonalInfoFeildsValid, errorMsg } = this.fieldsValid();
        if (sameAddress.toLowerCase() === 'no') {
            sameAddress = 'N';
        } else if (sameAddress.toLowerCase() === 'yes') {
            sameAddress = 'Y';
        }
        const isSameAddress = sameAddress === 'Y' || sameAddress === 'N';
        if (!isSameAddress && (sameAddress !== 'Y' || sameAddress !== 'N')) {
            errorMsg.sameAddress = sameAddressRadioError;
            this.setState({
                errorMsg,
            });
        } else {
            errorMsg.sameAddress = '';
        }
        let toFocus = '';
        if (!isPersonalInfoFeildsValid) {
            if (errorMsg.ownerDateOfBirth) {
                toFocus = 'primaryownerResidentExpiry';
            }else if (errorMsg.resident) {
                toFocus = 'primaryOwnerResident';
            }  else if (errorMsg.gender) {
                toFocus = 'Male';
            } else if (errorMsg.maritalStatus) {
                toFocus = 'primaryOwnerMarital';
            } else if (errorMsg.SSN) {
                toFocus = 'primaryOwnerssnOne';
            }
        } 
        return {
            isPersonalInfoFeildsValid,
            ssnData,
            phoneDetailsData,
            isSameAddress,
            toFocus,
        };
    };

    sendDataToParent = (action = 'defaultVal') => {
        const {
            isPersonalInfoFeildsValid,
            ssnData,
            isSameAddress,
            toFocus,
        } = this.checkValidSections(action);
        const isPersonalInfoCardValid =
            isPersonalInfoFeildsValid
        const tempAddress = {
            SSN: ssnData && ssnData.fullSSN,
            ssnData,
        };

        return { ...this.state, ...tempAddress, valid: isPersonalInfoCardValid, toFocus };
    };

    handleSSN = val => {
        this.setState({ fullSSN: val });
    };

    handleRadioBlurOnTab = eventKey => e => {
        e.persist();
        const { errorMsg } = this.state;
        const stateData = { ...this.state };
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: stateData[eventKey.toString()],
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg });
    };

    handleGenderBlur = eventKey => e => {
        e.persist();
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg, [eventKey.toString()]: e.target.value });
    };

    handleRadioBlur = eventKey => e => {
        e.persist();
        
        const { errorMsg } = this.state;
        const stateData = { ...this.state };
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: stateData[eventKey.toString()],
              })
            : '';
        errorMsg[eventKey.toString()] = err;

        this.setState({ errorMsg, [eventKey]: e.target.value });
        const {handlePersonalStateData,handleSameAddress} = this.props;
        handlePersonalStateData();
        
        if(this.props?.jointInfo == "Yes")
        {
            handleSameAddress(e.target.value,MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT);
        }
        else
        {
            handleSameAddress(e.target.value);
        }
    };

    handleCitizenshipRadioBlur = (eventKey, valKey) => e => {
        e.persist();
        const { errorMsg } = this.state;
        const stateData = { ...this.state };
        const{handleNextDisable}=this.props
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: stateData[eventKey.toString()],
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg, [eventKey]: valKey });
        // if a user selects non-resident alien option then show the pop up
        if(eventKey == 'resident' && valKey.toLowerCase() == 'non-resident alien'){
            this.setState({showNonResidentPopUp: true})
            this.props.handleNextDisable(true, 'showNonResidentPopUp')
        }else if(eventKey == 'resident' && valKey.toLowerCase() == 'resident alien'){
            this.props.handleNextDisable(false, 'showNonResidentPopUp')
        }
        if(eventKey == 'resident' && valKey.toLowerCase() == 'resident alien' || valKey==""){
            this.props.handleNextDisable(false, 'showNonResidentPopUp')
          
        }
        if(eventKey == 'citizenship' && valKey.toLowerCase() == 'us'){
           handleNextDisable(false)
            this.setState({resident: ""})
        }
    };

    resetResident=()=>{
        const {citizenship,resident,residentCardNumber,passportNumber,ownerResidentExpiry,ownerPassportExpiry,countryOfCitizenship}=this.state
        if(resident===""){return}

         if(!residentCardNumber &&
            !passportNumber &&
            !ownerResidentExpiry &&
            !ownerPassportExpiry &&
            !countryOfCitizenship){return}
    

        if (citizenship==="us"){
            this.setState({resident:"",
             residentCardNumber:'',
            passportNumber:'',
            ownerResidentExpiry:'',
            ownerPassportExpiry:'',
            countryOfCitizenship:''})
        }

        if (resident === 'Non-resident Alien'){
            this.setState({   residentCardNumber:'',
            passportNumber:'',
            ownerResidentExpiry:'',
            ownerPassportExpiry:'',
            countryOfCitizenship:''})
        }
     
    }

    getAddressType = addType => {
        const { masterLookupStateData } = this.props;
        const { address_type: mail } = masterLookupStateData || {};
        const { value: mailingAddr } = mail || {};
        const mailingAddrType = mailingAddr || [];
        const keyofItem = mailingAddrType.filter(item => (item.value === addType || item.key === addType));
        return keyofItem.length > 0 && keyofItem[0].key || null;
    };

    isSameAddress = (savedPerMailAddress, savedPhysicalAddress) => {
        if (savedPerMailAddress && savedPhysicalAddress) {
            const isSameAddressType = savedPerMailAddress.addressType === savedPhysicalAddress.addressType;
            const isSameAddrLine1 = savedPerMailAddress.addressLine1 === savedPhysicalAddress.addressLine1;
            const isSameAddrLine2 = savedPerMailAddress.addressLine2 === savedPhysicalAddress.addressLine2;
            const isSameZip = savedPerMailAddress.zip === savedPhysicalAddress.zip;
            const isSameCity = savedPerMailAddress.city === savedPhysicalAddress.city;
            const isSameState = savedPerMailAddress.state === savedPhysicalAddress.state;

            return isSameAddressType && isSameAddrLine1 && isSameAddrLine2 && isSameZip && isSameCity && isSameState;
        }
    };

    getIsSameAddress = () => {
        const { personalInfo } = this.props;
        let savedPerMailAddress = {};
        let savedPhysicalAddress = {};
        if (personalInfo.profileInformation.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const mailAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address?.isMailingAddress);
            if (mailAddressInfo.length > 0) {
                savedPerMailAddress = {
                    addressType: this.getAddressType(mailAddressInfo[0].addressType),
                    addressLine1: mailAddressInfo[0].addressLine1,
                    addressLine2: mailAddressInfo[0].addressLine2,
                    zip: mailAddressInfo[0].zip,
                    city: mailAddressInfo[0].city,
                    state: mailAddressInfo[0].state,
                };
            }
        }

        if (personalInfo.profileInformation.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const physicalAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address?.isPhysicalAddress);

            if (physicalAddressInfo.length > 0) {
                savedPhysicalAddress = {
                    addressType: this.getAddressType(physicalAddressInfo[0].addressType),
                    addressLine1: physicalAddressInfo[0].addressLine1,
                    addressLine2: physicalAddressInfo[0].addressLine2,
                    zip: physicalAddressInfo[0].zip,
                    city: physicalAddressInfo[0].city,
                    state: physicalAddressInfo[0].state,
                };
            }
        }

        return this.isSameAddress(savedPerMailAddress, savedPhysicalAddress) ? 'Y' : 'N';
    };

    getAlreadySavedData = accountType => {
        const { localStateData, personalInfo } = this.props;
        let savedPerMailAddress = personalInfo.profileInformation.addressInformation.mailingAddress;

        if (!savedPerMailAddress && personalInfo.profileInformation.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const mailAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address?.isMailingAddress);
            if (mailAddressInfo.length > 0) {
                const addressType = this.getAddressType(mailAddressInfo[0]?.mailingAddressType);
                savedPerMailAddress = {
                    addressType,
                    addressLine1: mailAddressInfo[0].addressLine1,
                    addressLine2: mailAddressInfo[0].addressLine2,
                    zip: mailAddressInfo[0].zipCode,
                    city: mailAddressInfo[0].city,
                    state: mailAddressInfo[0].state,
                };
            }
        }

        let savedPhysicalAddress =  personalInfo.profileInformation.addressInformation.physicalAddress;

        if (!savedPhysicalAddress && personalInfo.profileInformation.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const physicalAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address?.isPhysicalAddress);
            if (physicalAddressInfo.length > 0) {
                savedPhysicalAddress = {
                    addressType: this.getAddressType(physicalAddressInfo[0]?.mailingAddressType),
                    addressLine1: physicalAddressInfo[0].addressLine1,
                    addressLine2: physicalAddressInfo[0].addressLine2,
                    zip: physicalAddressInfo[0].zipCode,
                    city: physicalAddressInfo[0].city,
                    state: physicalAddressInfo[0].state,
                };
            }
        }

        const savedSameAddress = this.isSameAddress(savedPerMailAddress, savedPhysicalAddress);

        // Phone Details
        const savedPersonalInfoDetails =
            accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo;
        let savedSSNData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.ssnData;
        if (!savedSSNData && personalInfo.profileInformation.ssn) {
            const { ssn } = personalInfo.profileInformation;
            savedSSNData = {
                ssnOne: ssn
                    .split('')
                    .slice(0, 3)
                    .join()
                    .replace(/,/g, ''),
                m_ssnTinOne: 'XXX',
                ssnTwo: ssn
                    .split('')
                    .slice(3, 5)
                    .join()
                    .replace(/,/g, ''),
                m_ssnTinTwo: 'XX',
                ssnThree: ssn
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, ''),
                m_ssnTinThree: ssn
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, ''),
                fullSSN: ssn,
                errorOneText: '',
                errorTwoText: '',
                errorThreeText: '',
                readOnlySsnOne: true,
                readOnlySsnTwo: true,
                typeSsnOne: 'password',
                typeSsnTwo: 'password',
                retrievedState: true,
                isSSNOneError: false,
                isSSNTwoError: false,
                isSSNThreeError: false,
                maskedssnOne: 'XXX',
                maskedssnTwo: 'XX',
                maskedssnThree: `${ssn
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, '')}`,
                maskedFullSSN: `XXX-XX-${ssn
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, '')}`,
            };
        }

        const savedEmailAddressData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.emailAddress;

        return {
            savedPerMailAddress,
            savedPhysicalAddress,
            savedPersonalInfoDetails,
            savedSSNData,
            savedEmailAddressData,
            savedSameAddress,
        };
    };

    getMasterDataValues = () => {
        const { transactionCompositeData } = this.props;
        /** Master Data State handling Start */
        const {
            citizenship: citizenshipMaster,
            marital_status: maritalStatusMaster,
            prefix: prefixMaster,
            suffix: suffixMaster,
            relationship: relationshipMaster,
            gender: genderMaster,
            contact_time: contactTimeMaster,
            country_3_lettercode: countryMaster,
        } = transactionCompositeData || {};
        const { value: citizenshipMasterValue } = citizenshipMaster || {};
        const { value: maritalStatusMasterValue } = maritalStatusMaster || {};
        const { value: prefixMasterValue } = prefixMaster || {};
        const { value: suffixMasterValue } = suffixMaster || {};
        const { value: relationshipMasterValue } = relationshipMaster || {};
        const { value: genderMasterValue } = genderMaster || {};
        const { value: countryMasterValue } = countryMaster || {};
        const { value: contactTimeMasterDataValue } = contactTimeMaster || {};
        const contactTimeOp = contactTimeMasterDataValue || [];
        const suffixOp = suffixMasterValue || [];
        const citizenshipOp = citizenshipMasterValue || [];
        const maritalStatus = maritalStatusMasterValue || [];
        const prefixOp = prefixMasterValue || [];
        const relationshipOp = relationshipMasterValue || [];
        const genderOp = genderMasterValue || [];
        const countryOp = countryMasterValue || [];
        /** Master Data State Handling End */
        return {
            contactTimeOp,
            suffixOp,
            citizenshipOp,
            maritalStatus,
            prefixOp,
            relationshipOp,
            genderOp,
            countryOp,
        };
    };

    getMaritalStatus = () => {
        const { localStateData } = this.props;
        if (localStateData && localStateData.accountType === Consts.JTIE) {
            return 'M';
        }
        const { maritalStatus } = this.state;
        if (maritalStatus === 'Separated') {
            return 'SE';
        }
        return maritalStatus && maritalStatus.split('')[0].toUpperCase();
    };

    formatPhoneNumber = phoneNumberInitial => {
        let formattedPhoneNumber = phoneNumberInitial;
        if (formattedPhoneNumber && formattedPhoneNumber.toString().trim().length > 0) {
            formattedPhoneNumber = formattedPhoneNumber.replace(/[-\s()]/g, '');
            if (formattedPhoneNumber.length === 10) {
                const stateCode = formattedPhoneNumber.substr(0, 3);
                const areaCode = formattedPhoneNumber.substr(3, 3);
                const phoneNumber = formattedPhoneNumber.substr(6, 4);
                formattedPhoneNumber = `${stateCode}-${areaCode}-${phoneNumber}`;
            } else {
                formattedPhoneNumber = '';
            }
        }
        return formattedPhoneNumber;
    };

    getCountryMetaData = countryCodeData => {
        let usIndex;
        const countryCodeArr = countryCodeData || [];
        const isAlreadySwapped = countryCodeArr.some(item => item.value.length > 3);
        if (isAlreadySwapped) return countryCodeArr;
        countryCodeArr.forEach((item, index, myArr) => {
            const countryArr = myArr;
            if (item.value === 'USA') usIndex = index;
            countryArr[+index] = {
                key: item.value,
                value: item.key,
            };
        });
        if (usIndex) countryCodeArr.splice(usIndex, 1);
        return countryCodeArr;
    };

    getKey = (lookupString, lookupValue) => {
        if (lookupValue && lookupValue.toString().trim() !== '') {
            const { masterLookupStateData } = this.props;

            const lookupList =
                masterLookupStateData &&
                masterLookupStateData[lookupString.trim().toString()] &&
                masterLookupStateData[lookupString.trim().toString()].value;

            const lookupData =
                lookupList && lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
            if (lookupData) {
                return lookupData.key;
            }
        }
        return '';
    }; 

    handleStateChange = (type)=>
    {
        const {handlePersonalStateData} = this.props;
        handlePersonalStateData();
        this.setState({});
    }

    checkPersonalInfoParentChildNextStatus() {
        const { errorMsg } = this.state;
        let disableNext = true;
        const perMailAddressData = this.perMailAdrressPref.current;
        const perPhysicalAddressData = this.perPhysicalAddressPref.current;
        const perMailingAddressData = this.perMailingAddressPref.current;
        const phoneDetailsData = this.phoneDetailsRef.current;
        const emailAddressData = this.emailAddressRef.current;
        const { sameAddress } = this.state;
        // Check personalinfocard status
        disableNext = !this.checkPersonalInfoParentNextStatus(errorMsg);

        if (perMailAddressData) {
            const mailingAddState = { ...perMailAddressData.state };
            disableNext = disableNext || !mailingAddState.ready;
        }

        if (perMailingAddressData) {
            const mailingAddState = { ...perMailingAddressData.state };
            disableNext = disableNext || !mailingAddState.ready;
        }


        if (sameAddress && perPhysicalAddressData) {
            const physicalAddState = { ...perPhysicalAddressData.state };
            disableNext = disableNext || !physicalAddState.ready;
        }
        if (phoneDetailsData) {
            const phoneDetailState = { ...phoneDetailsData.state };
            disableNext = disableNext || !phoneDetailState.ready;
        }
        if (emailAddressData) {
            const emailAddState = { ...emailAddressData.state };
            disableNext = disableNext || !emailAddState.ready;
        }
        const { disableNext: prevStateNextDisabled } = this.state;
        if (prevStateNextDisabled !== disableNext) {
            this.setState(
                () => {
                    return {
                        pageReady: !disableNext,
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        }
    }

    checkPersonalInfoParentNextStatus() {
        const {
            firstName,
            lastName,
            ownerDateOfBirth,
            residentCardNumber,
            passportNumber,
            ownerResidentExpiry,
            ownerPassportExpiry,
            gender,
            maritalStatus,
            citizenship,
            resident,
            fullSSN,
            sameAddress,
        } = this.state;

        // debugger
        return (
            !!firstName &&
            !!lastName &&
            !!residentCardNumber &&
            !!passportNumber &&
            !!ownerResidentExpiry &&
            !!ownerPassportExpiry &&
            !!ownerDateOfBirth &&
            !!gender &&
            !!maritalStatus &&
            !!citizenship &&
            !!resident &&
            !!fullSSN &&
            !!sameAddress
        );
    }

    renderSuffix = suffixOp => {
        const { suffix = '' } = this.state;
        return (
            <>
                    {(this.props?.jointInfo !="Yes" ) ? 
                   
                    <WInput
                        label="Name Suffix"
                        optional="true"
                        inputclassname="suffix-select prefilled"
                        name="suffix"
                        aria-labelledby="primaryOwnersuffix"
                        id={assignObj(`primaryOwnersuffix`)}
                        value={suffix || ''}
                        type="text"
                        onChange={this.handleChange('suffix')}
                        readOnly={this.props?.jointInfo =="Yes" ? false : this.state.readOnly }
                        labelsm={4}
                        valuesm={5}
                        inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                    />
                     
                    : 

                    <FieldValue
                    as="p"
                    id={`jointOwnerSuffixLabel`}
                    label="Suffix"
                    value={
                        <SelectDropDown
                            aria-labelledby={`jointOwnerSuffixLabel`}
                           
                            id={`jointOwnerSuffix`}
                            data-name="Suffix"
                            value={suffix}
                            onChange={this.handleChange('suffix')}
                            itemlist={suffixOp}
                            className="inputAlign"
                        />
                    }

                    labelsm={4}
                    valuesm={5}
                    mb={1.5625}
                    noGutters
                    optional
                />
                }
           
            </>
        );
    };

    renderMailingAddressAccordion = accountType => {
        const { verifyPersonalHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointPhoneHider,verifyJointEmailHider } = this.props;
        const { errorMsg } = this.state;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedPerMailAddress } = savedData;

        const {sameAddressInformation} = this.props;
        let sameAddress ="";
        if(sameAddressInformation != undefined)
        {
            if(this.props?.jointInfo == "Yes")
            {
                 sameAddress = sameAddressInformation[1].primaryIsPhysicalAddressSame == "Yes" ? "Y":"N";
            }
            else
            {
                 sameAddress = sameAddressInformation[0].primaryIsPhysicalAddressSame == "Yes" ? "Y":"N";
            }
        }

        let showSameAddrErr = false;

        if (errorMsg.sameAddress === '' || !errorMsg.sameAddress) {
            showSameAddrErr = false;
        } else if (sameAddress === 'Y' || sameAddress === 'N') {
            showSameAddrErr = false;
            delete errorMsg.sameAddress;
        } else {
            showSameAddrErr = true;
        }

        const addressType = sameAddress === 'N'?'mailing':'physical';

        if(sameAddress == 'Y')
        {
            return('')
        }
        else
        {
            return(
                <Accordian
                ref={this.addressAccordion}
                key={this.props?.jointInfo != "Yes"  ? MailingAddress : MailingAddressForJoint}
                accordianPaddingLeft={0}
                titleChild={
                   verifyPersonalHider || verifyPhoneHider || verifyEmailHider || verifyJointPersonalHider || verifyJointPhoneHider || verifyJointEmailHider ? null : <AccordianTitle
                        accType={this.props?.jointInfo != "Yes"  ? MailingAddress : MailingAddressForJoint}
                        id={this.props?.jointInfo != "Yes"  ? MailingAddress : MailingAddressForJoint}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                // titleSeperator
                labelledBy={this.props?.jointInfo != "Yes"  ? MailingAddress : MailingAddressForJoint}
                Id={this.props?.jointInfo != "Yes"  ? MailingAddress : MailingAddressForJoint}
            >
                <Card>
                    <Card.Body>
                        <MailingAddressComponent
                            savedState={savedPerMailAddress}
                            ref={this.perMailingAddressPref}
                            labelAdd="Mailing Address"
                            mailRadio
                            required
                            type={addressType}
                            checkStatus={this.checkPersonalInfoParentChildNextStatus}
                            handleStateChange = {this.handleStateChange}
                             verifyPersonalHider={verifyPersonalHider}
                            verifyPhoneHider={verifyPhoneHider}
                            verifyEmailHider={verifyEmailHider}
                            verifyJointPersonalHider={verifyJointPersonalHider}
                            verifyJointPhoneHider={verifyJointPhoneHider}
                            verifyJointEmailHider={verifyJointEmailHider}
                            jointInfo={this.props?.jointInfo}             
                            handleValidationsOnBlur = {true}
                        /> 
                    
                    </Card.Body>
                </Card>
            </Accordian>
            )
        }
    }

    renderPhysicalAddressAccordion = accountType => {
        const { initialState, pageIndex, handleNextDisable } = this.props;
        const { errorMsg } = this.state;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedPhysicalAddress } = savedData;

        const {sameAddressInformation,verifyPersonalHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointPhoneHider,verifyJointEmailHider} = this.props;

        let sameAddress ="";
        if(sameAddressInformation != undefined)
        {
            if(this.props?.jointInfo == "Yes")
            {
                 sameAddress = sameAddressInformation[1]?.primaryIsPhysicalAddressSame == "Yes" ? "Y":"N";
            }
            else
            {
                 sameAddress = sameAddressInformation[0]?.primaryIsPhysicalAddressSame == "Yes" ? "Y":"N";
            }
        }
  
        let showSameAddrErr = false;

        if (errorMsg.sameAddress === '' || !errorMsg.sameAddress) {
            showSameAddrErr = false;
        } else if (sameAddress === 'Y' || sameAddress === 'N') {
            showSameAddrErr = false;
            delete errorMsg.sameAddress;
        } else {
            showSameAddrErr = true;
        }
        //================================== Tooltipfunction for PhysicalAddress
        const { HelperTetForPhysical } = this.state;

        const toggleToolTipForPhysical = () => {
            this.setState({ HelperTetForPhysical: true })
        }

        const closeToolTipForPhysical = () => {
            this.setState({ HelperTetForPhysical: false })
        }
        //======================================================================

        return (
            <Accordian
                ref={this.addressAccordion}
                key={this.props?.jointInfo != "Yes"  ? physicalAddress : physicalAddressForJoint}
                accordianPaddingLeft={0}
                titleChild={
                    verifyPersonalHider || verifyPhoneHider || verifyEmailHider || verifyJointPersonalHider || verifyJointPhoneHider || verifyJointEmailHider ? null: <AccordianTitle
                        accType={this.props?.jointInfo != "Yes"  ? physicalAddress : physicalAddressForJoint}
                        id={this.props?.jointInfo != "Yes"  ? physicalAddress : physicalAddressForJoint}
                        className="accordionH2Style"
                        requireButtonPadding={false}
                    />
                }
                // titleSeperator
                labelledBy={this.props?.jointInfo != "Yes"  ? physicalAddress : physicalAddressForJoint}
                Id={this.props?.jointInfo != "Yes"  ? physicalAddress : physicalAddressForJoint}
            >
                <Card>
                    <Card.Body>
                        
                         {verifyPersonalHider || verifyPhoneHider || verifyEmailHider || verifyJointPersonalHider || verifyJointPhoneHider || verifyJointEmailHider ? null :<div>
                        <img id="physicalAddress" className={this.props?.jointInfo != "Yes" ? "TooltipForPersonalPhysical" : "TooltipForJointPersonalPhysical"} onMouseOut={closeToolTipForPhysical} src={helperIcon} alt="" />

                        <StyledToolTip
                            id="physicalAddress_Tooltip"
                            placement="top"
                            tooltipOpen={HelperTetForPhysical} 
                            targetID="physicalAddress"
                            handleToggle={toggleToolTipForPhysical}
                            autohide={true}
                            triggerEvt="hover focus"
                            tooltipText={HelpText_PhysicalAddress}
                            closeTooltip={closeToolTipForPhysical}
                        /></div>}

                            <Address
                                savedState={savedPhysicalAddress}
                                ref={this.perPhysicalAddressPref}
                                labelAdd="Physical Address"
                                required
                                labelAdd1="Physical Address Line 1"
                                labelAdd2="Physical Address Line 2"
                                type="physical"
                                physicalRadio= {true}
                                checkStatus={this.checkPersonalInfoParentChildNextStatus}
                                handleStateChange = {this.handleStateChange}
                                typeData={'physical'}
                                verifyPersonalHider={verifyPersonalHider}
                                verifyPhoneHider={verifyPhoneHider}
                                verifyEmailHider={verifyEmailHider}
                                verifyJointPersonalHider={verifyJointPersonalHider}
                                verifyJointPhoneHider={verifyJointPhoneHider}
                                verifyJointEmailHider={verifyJointEmailHider}
                                jointInfo={this.props?.jointInfo}
                                pageIndex={pageIndex}
                                handleNextDisable={handleNextDisable}
                                handleValidationsOnBlur = {true}
                                handleAddressTypeInfo={this.handleAddressTypeInfo}
                                addressTypeInfo={this.state.addressTypeInfo}
                                allowPoBoxText={true}
                                flowName={"MarketNAO"}
                            />
                    
                         { verifyPersonalHider || verifyPhoneHider || verifyEmailHider  || verifyJointPersonalHider || verifyJointPhoneHider || verifyJointEmailHider? null:
                        <Row
                            className="Slabel"
                            role="group"
                            aria-labelledby={assignObj(`primaryOwnerisPhysicalAddressSameasMailingAddress`)}
                            noGutters
                        >
                            <span
                                className="label col-sm-4"
                                style={labelTextStyle}
                                id={assignObj(`primaryOwnerisPhysicalAddressSameasMailingAddress`)}
                            >
                                {this.props?.jointInfo != "Yes" ? sameAddressRadioLabel : jointSameAddressRadioLabel}
                            </span>
                            <div className="sameAddressSpan">
                                <div className="sameAddressDiv" role="radiogroup">
                                
                                    {Consts.sameAddress.value &&
                                        Consts.sameAddress.value.length > 0 &&
                                        Consts.sameAddress.value.map(field => (
                                            <WRadio
                                                radioFor="sameAddress"
                                                key={field.key}
                                                id={`sameAddress${field.key}`}
                                                value={field.value}
                                                onBlur={assignObj(this.handleRadioBlurOnTab('sameAddress'))}
                                                onClick={assignObj(this.handleRadioBlur('sameAddress'))}
                                                radiodescstyles={radiodescstyles}
                                                radiostyles={radiostyles}
                                                radiotext={radioText}
                                                selected={!!(sameAddress && sameAddress.toString() === field.key)}
                                                ariaDescribedBy="sameAddressError"
                                                ariaRequired="true"
                                            />
                                        ))}
                                </div>
                                {showSameAddrErr && (
                                    <div
                                        className="errorMsgInputStyle errorSameAddressDiv"
                                        role="region"
                                        id="sameAddressError"
                                        aria-live="assertive"
                                        aria-atomic="true"
                                    >
                                        <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                                        <div id="errSameAddress" aria-live="assertive" aria-atomic="true">
                                            {errorMsg.sameAddress}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Row>}
                    </Card.Body>
                </Card>
            </Accordian>
        );
    };

    renderSSNComponent = savedSSNData => {
        const { isSSNerror = false, citizenship } = this.state;
        const { initialState } = this.props;
        const role = getRole(initialState && initialState.getCustomerProfile);
        let ssnLabel = 'Social Security Number / Tax ID';
        const {verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider}=this.props
        if (citizenship === 'us' || citizenship === 'U.S' || citizenship === 'U.S.') {
            ssnLabel = 'Social Security Number / Tax ID';
        }
        return (
            <Row>
                <SSNComponent
                    ref={this.ssnRef}
                    // if it is member only then take the data from the previously saved data else take the empty values for guest and prospect
                    savedstate={savedSSNData}
                    sendfullssn={this.handleSSN}
                    sendSsnError={this.handleSnnError}
                    isssnerror={isSSNerror}
                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                    readonly={role !== 'Guest' && role !== 'Prospect'}
                    ssnLabel={ssnLabel}
                    disabledFirstSnn={role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo != "Yes"}
                    disabledTwoSnn = {role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo != "Yes"}
                    disabledThirdSnn = {role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo != "Yes"}
                    valuesm={5}
                    verifyContactHider={verifyContactHider}
                    verifyPhoneHider={verifyPhoneHider}
                    verifyEmailHider={verifyEmailHider}
                    verifyJointContactHider={verifyJointContactHider}
                    verifyJointPhoneHider={verifyJointPhoneHider}
                    verifyJointEmailHider={verifyJointEmailHider}    
                    handleValidationsOnBlur = {false}
                    showSsn={!(role == 'Member' && this.props?.jointInfo == undefined)}
                />
            </Row>
        );
    };
    handleAddressTypeInfo = (addressTypeInfo) => {
        this.setState({ addressTypeInfo: addressTypeInfo })
    }
    render() {

        const { localStateData, initialState, personalInfo } = this.props;
        const role = getRole(initialState && initialState.getCustomerProfile);

        const perInfoHeading = ' Personal Information';
        const perInfoHeadingForJoint = ' Personal Information - Joint Owner';
        const {
            gender,
            citizenship,
            ownerDateOfBirth,
             ownerResidentExpiry,
            ownerPassportExpiry,
            residentCardNumber,
            passportNumber,
              resident,
            prefix,
            errorMsg,
            errorFromMasterData = false,
            firstName = '',
            lastName = '',
            middleInitial = '',
            readOnly = false,
            countryOfCitizenship,
            relationship,
            HelperIcon,
            HelperIconForSuffix,
            HelperIconForSSN,
        } = this.state;
         const currentDate = new Date();
         const {verifyPersonalHider,verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider}=this.props

        const masterDataValues = this.getMasterDataValues();
        const {
            contactTimeOp,
            suffixOp,
            citizenshipOp,
            maritalStatus,
            prefixOp,
            genderOp,
            countryOp,
            relationshipOp
        } = masterDataValues;
        const { accountType } = localStateData;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedPersonalInfoDetails, savedSSNData } = savedData;
        if (errorFromMasterData) {
            throw new Error('Error From Master Data in Personal Info Card');
        }

        let primaryEmailAddress = '';
        let secondaryEmailAddress = '';
        let isSecondaryEmailEditable = true;
        let primaryEmailInitial = '';
        let secondaryEmailInitial = '';
      
        if (personalInfo && personalInfo.profileInformation) {
            const { profileInformation } = personalInfo;
            if (profileInformation && profileInformation.emailInformation) {
                primaryEmailInitial = profileInformation.emailInformation.primaryEmailAddress || profileInformation.emailInformation.maskedPrimaryEmailAddress || '';
                secondaryEmailInitial = profileInformation.emailInformation.secondaryEmailAddress || profileInformation.emailInformation.maskedSecondaryEmailAddress || '';
                isSecondaryEmailEditable = secondaryEmailInitial === '';
            }
            primaryEmailAddress = primaryEmailInitial;
            secondaryEmailAddress = secondaryEmailInitial;
        }

        let emailAddress = {};
        if (savedPersonalInfoDetails && savedPersonalInfoDetails.emailAddress) {
            emailAddress = savedPersonalInfoDetails.emailAddress;
            primaryEmailAddress = emailAddress.primaryEmail.email;
            secondaryEmailAddress = emailAddress.secondaryEmail.email;
            if (true) {
                secondaryEmailInitial =
                    (personalInfo.profileInformation &&
                        personalInfo.profileInformation.emailInformation &&
                        personalInfo.profileInformation.emailInformation.secondaryEmail) ||
                    '';
                isSecondaryEmailEditable = secondaryEmailInitial === '';
            } else {
                isSecondaryEmailEditable = true;
            }
        }

        let primaryPhoneNumber = '';
        let secondaryPhoneNumber = '';
        let primaryMPhoneNumber = '';
        let secondaryMPhoneNumber = '';
        let primaryContactTime = '';
        let secondaryContactTime = '';
        let primaryCountryCode = '+1';
        let secondaryCountryCode = '+1';
        let isPrimaryNumberChanged = false;
        let isSecondaryNumberChanged = false;
        let primaryIsMobile = false;
        let secondaryIsMobile = false;
        let hasSecondaryPhoneNumber = false;
        let hasSecondaryEmailAddress = false;

        if ((role === 'Guest' || role === 'Prospect') && initialState && initialState.getCustomerProfile) {
            const { getCustomerProfile } = initialState;
            primaryPhoneNumber = getCustomerProfile.primaryPhone || '';
            secondaryPhoneNumber = getCustomerProfile.secondaryPhone || '';
            primaryMPhoneNumber = getCustomerProfile.primaryPhone || '';
            secondaryMPhoneNumber = getCustomerProfile.secondaryPhone || '';
            primaryCountryCode = getCustomerProfile.primaryPhoneCountryCode || '+1';
            secondaryCountryCode = getCustomerProfile.secondaryPhoneCountryCode || '+1';
            primaryIsMobile = getCustomerProfile.isMobilePrimary || false;
            secondaryIsMobile = getCustomerProfile.isMobileSecondary || false;
        }
        if (personalInfo && personalInfo.profileInformation) {
            const { profileInformation } = personalInfo;
            const { phoneInformation } = profileInformation;
            if (profileInformation && profileInformation.phoneInformation) {
                primaryPhoneNumber = phoneInformation.length > 0 ? phoneInformation[0].primary.number : '';
                secondaryPhoneNumber = phoneInformation.length > 0 ? phoneInformation[0].secondary.number : '';
                primaryMPhoneNumber = phoneInformation.length > 0 ? phoneInformation[0].primary.mPhoneNumber : '';
                secondaryMPhoneNumber = phoneInformation.length > 0 ? phoneInformation[0].secondary.mPhoneNumber : '';
                primaryCountryCode = phoneInformation.length > 0 ? phoneInformation[0].primary.countryCode : '+1';
                secondaryCountryCode = phoneInformation.length > 0 ? phoneInformation[0].secondary.countryCode : '+1';
                primaryIsMobile = phoneInformation.length > 0 ? phoneInformation[0].primary.isMobile : false;
                secondaryIsMobile = phoneInformation.length > 0 ? phoneInformation[0].secondary.isMobile : false;
            }

            // check for secondary email and phone number in profile api, if existing then dont allow user to edit 
            if( this.props.profileInformationData.memberProfileInformation?.phoneInformation && this.props.profileInformationData.memberProfileInformation?.phoneInformation.length > 1){
                hasSecondaryPhoneNumber = this.props.profileInformationData.memberProfileInformation?.phoneInformation[1]?.phoneNumber ? true : false
            }
            
            if(this.props.profileInformationData?.memberProfileInformation?.emailInformation){
                hasSecondaryEmailAddress = this.props.profileInformationData.memberProfileInformation?.emailInformation?.secondaryEmail ? true : false
            }
        }

        let showGenderErr = false;
        if (!errorMsg.gender) {
            showGenderErr = false;
        } else if (gender === 'Male' || gender === 'Female') {
            showGenderErr = false;
        } else {
            showGenderErr = true;
        }

        let showResidentErr = false;
        if (!errorMsg.resident) {
            showResidentErr = false;
        }  
        else {
            showResidentErr = true;
        }
      //===============================Tooltipfunction for FirstName
        const toggleToolTip=()=>{
            this.setState({
                HelperIcon: true})
        }

        const closeToolTip=()=>{
            this.setState({ HelperIcon: false })
        }
       //===============================Tooltipfunction for Suffix
        const toggleToolTipForSuffix=()=>{
            this.setState({ HelperIconForSuffix:true })
        }

        const closeToolTipForSuffix = () => {
            this.setState({ HelperIconForSuffix: false })
        }
        //================================== Tooltipfunction for SSN

        const toggleToolTipForSSN = () => {
            this.setState({ HelperIconForSSN: true })
        }

        const closeToolTipForSSN = () => {
            this.setState({ HelperIconForSSN: false })
        }

        return (
            <>
                <Accordian
                    ref={this.personalRef}
                    key={this.props?.jointInfo != "Yes" ? perInfoHeading : perInfoHeadingForJoint
                        }
                    accordianPaddingLeft={0}
                    titleChild={
                      verifyContactHider || verifyPhoneHider || verifyEmailHider || verifyJointContactHider || verifyJointPhoneHider || verifyJointEmailHider ? null:  <AccordianTitle
                            accType={this.props?.jointInfo != "Yes" ? perInfoHeading : perInfoHeadingForJoint}
                            id={this.props?.jointInfo != "Yes" ? perInfoHeading : perInfoHeadingForJoint}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                        />
                    }
                    // titleSeperator
                    labelledBy={this.props?.jointInfo != "Yes" ? perInfoHeading : perInfoHeadingForJoint}
                    Id={this.props?.jointInfo != "Yes" ? perInfoHeading : perInfoHeadingForJoint}
                >
                    <Card>
                        <Card.Body>
                           { verifyContactHider || verifyPhoneHider || verifyEmailHider || verifyJointContactHider || verifyJointPhoneHider || verifyJointEmailHider  ? "":
                            (this.props?.jointInfo == "Yes") &&  
                           
                             <FieldValue
                             as="p"
                             id={`jointrelationshipLabel`}
                             label="Relationship to Account Holder"
                             value={
                                 <SelectDropDown
                                     aria-labelledby={`jointrelationshipLabel`}
                                     id={`jointrelationship`}
                                     data-name="Military Status"
                                     value={relationship}
                                     onChange={this.handleChange('relationship')}
                                     errortext={errorMsg.relationship}
                                     itemlist={relationshipOp}
                                     className="inputAlign"
                                 />
                             }
                             labelsm={4}
                             valuesm={5}
                             mb={1.5625}
                             noGutters
                             alignSelectAccOpt = {alignSelectAccOpt}
                         />
                       
                         }
                           { verifyContactHider || verifyPhoneHider || verifyEmailHider || verifyJointContactHider || verifyJointPhoneHider || verifyJointEmailHider  ? null:
                            <>
                            <FieldValue
                                as="p"
                                id="primaryOwnerPrefixLabel"
                                label="Salutation"
                                value={
                                    <SelectDropDown
                                        aria-labelledby="primaryOwnerPrefixLabel"
                                        id="selectDropDown-primaryOwnerPrefix"
                                        data-name="Salutation"
                                        value={prefix}
                                        onChange={this.handleChange('prefixLocal')}
                                        errortext={errorMsg.prefix}
                                        itemlist={prefixOp}
                                        className="inputAlign"
                                    />
                                }
                                labelsm={4}
                                valuesm={5}
                                optional
                                mb={1.5625}
                                noGutters
                                alignSelectAccOpt = {alignSelectAccOpt}
                            />
                            
                            
                                <WInput
                                    label="First Name"
                                    arialabel="First Name"
                                    aria-required
                                    required
                                    inputclassname={readOnly ? 'prefilled' : ''}
                                    id={assignObj(`primaryOwnerFirstName`)}
                                    name="firstName"
                                    type="text"
                                    onChange={this.handleChange('firstName')}
                                    value={firstName || ''}
                                    readOnly={false}
                                    labelsm={4}
                                    valuesm={5}
                                    maxlength={40}
                                    errortext={errorMsg.firstName}
                                    inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                />
                            <img id="helperIcon" className='Tooltip' onMouseOut={closeToolTip} src={helperIcon} alt="" />

                            <StyledToolTip
                                id="Fundtooltip_Tooltip"
                                placement="top"
                                tooltipOpen={HelperIcon}
                                targetID="helperIcon"
                                handleToggle={toggleToolTip}
                                autohide={true}
                                triggerEvt="hover focus"
                                tooltipText={HelpText_FirstName}
                                closeTooltip={closeToolTip}
                            />
                           
                           
                                <WInput
                                    label="Middle Name"
                                    inputclassname={readOnly ? 'prefilled' : ''}
                                    optional="true"
                                    sublabel={sublabel}
                                    name="middleInitial"
                                    id={assignObj(`primaryOwnerMiddleInitial`)}
                                    type="text"
                                    onChange={this.handleChange('middleInitial')}
                                    errortext={errorMsg.middleInitial}
                                    readOnly={false}
                                    value={middleInitial || ''}
                                    labelsm={4}
                                    valuesm={5}
                                    maxlength={40}
                                    aria-required={false}
                                    required={false}
                                    inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                />
                            
                                <WInput
                                    aria-required
                                    label="Last Name"
                                    arialabel="Last Name"
                                    inputclassname={readOnly ? 'prefilled' : ''}
                                    name="lastName"
                                    id={assignObj(`primaryOwnerLastName`)}
                                    value={lastName || ''}
                                    type="text"
                                    required
                                    onChange={this.handleChange('lastName')}
                                    readOnly={false}
                                    labelsm={4}
                                    valuesm={5}
                                    maxlength={40}
                                    errortext={errorMsg.lastName}
                                    inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                />
                           
                            {this.renderSuffix(suffixOp)} <img id="helperIcon1" className='Tooltip' onMouseOut={closeToolTipForSuffix} src={helperIcon} alt="" />

                            <StyledToolTip
                                id="Suffixid_Tooltip"
                                placement="top"
                                tooltipOpen={HelperIconForSuffix}
                                targetID="helperIcon1"
                                handleToggle={toggleToolTipForSuffix}
                                autohide={true}
                                triggerEvt="hover focus"
                                tooltipText={HelpText_Suffix}
                                closeTooltip={closeToolTipForSuffix}
                            />
                            <VictoryDatePicker
                                id={this.props?.jointInfo !="Yes" ? assignObj(`primaryOwnerDateofBirth`) : assignObj(`secondaryOwnerDateofBirth`)}
                                value={ownerDateOfBirth && ownerDateOfBirth.replace(/-/g, '/')}
                                onDateChange={this.handleDateChange}
                                argument1="dateOfBirthLocal"
                                label={dateOfBirthLabel}
                                errortext={errorMsg.ownerDateOfBirth}
                                dobValidation
                                DOBErrorMsg={Consts.DOBErrorMsg}
                                disabled={(role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo !="Yes")}
                                maskedDateOfBirth={role === 'Member' && this.props?.jointInfo !="Yes"}
                                datePickerModule="dobNAO"
                                ariaLabelledBy={this.props?.jointInfo !="Yes" ? "primaryOwnerDateofBirth" : "secondaryOwnerDateofBirth"} 
                                ariaDescribedBy="PersonalInfostartDate"
                            />
                            <Row className="Slabel no-gutters" role="group" aria-labelledby={assignObj(`primaryOwnergender`)}>
                                <span
                                    className="label alignRadioBtn col-sm-4"
                                    id={assignObj(`primaryOwnergender`)}
                                    style={labelTextStyle}
                                >
                                    {genderLabel}
                                </span>
                                
                                <span className="col-sm-5 marketradioalign">
                                {genderOp &&
                                        genderOp.length > 0 &&
                                        genderOp.map(field => (
                                                <WRadio
                                                    radioFor="gender"
                                                    key={field.key}
                                                    id={field.key}
                                                    value={field.value}
                                                    onBlur={assignObj(this.handleGenderBlur('gender'))}
                                                    onClick={assignObj(this.handleGenderBlur('gender'))}
                                                    radiodescstyles={radiodescstyles}
                                                    radiostyles={radiostyles}
                                                    radiotext={radioText}
                                                    selected={!!(gender && gender.toString() === field.key)}
                                                    ariaRequired="true"
                                                    ariaDescribedBy="genderError"
                                                />
                                        ))}
                                    
                                    {showGenderErr && (
                                        <div
                                            className="errorMsgInputStyle errorSameAddressDiv"
                                            id="genderError"
                                            aria-atomic="true"
                                            aria-live="assertive"
                                            style={{width: '100%', float:'left'}}
                                        >
                                            <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                                            <div id="errGender">Please select a gender</div>
                                        </div>
                                    )}
                                    </span>
                                                           
                            </Row>
                            <FieldValue
                                as="p"
                                id="primaryOwnerMaritalLabel"
                                label="Marital Status"
                                value={
                                    <SelectDropDown
                                        aria-labelledby="primaryOwnerMaritalLabel"
                                        id="selectDropDown-primaryOwnerMarital"
                                        data-name="Marital Status"
                                        value={this.getMaritalStatus() || ''}
                                        onChange={this.handleChange('maritalLocal')}
                                        errortext={errorMsg.maritalStatus}
                                        itemlist={maritalStatus}
                                        disabled={false}
                                        className="inputAlign"
                                    />
                                }
                                labelsm={4}
                                valuesm={5}
                                mb={1.5625}
                                noGutters
                                alignSelectAccOpt = {alignSelectAccOpt}
                            />
                            <Row className="Slabel no-gutters" role="group" aria-labelledby={assignObj(`primaryOwnercitizenship`)}>
                                <span
                                    className="label col-sm-4"
                                    id={assignObj(`primaryOwnercitizenship`)}
                                    style={labelTextStyle}
                                >
                                    {citizenshipLabel}
                                </span>
                                <span className='col-sm-5 marketradioalign'>
                                {citizenshipOp &&
                                    citizenshipOp.length > 0 &&
                                    citizenshipOp.map(field => (
                                        <WRadio
                                            radioFor="citizenship"
                                            key={field.key}
                                            id={field.key}
                                            value={field.value}
                                            onBlur={assignObj(this.handleRadioBlurOnTab('citizenship'))}
                                            onClick={assignObj(
                                                this.handleCitizenshipRadioBlur('citizenship', field.key),
                                            )}
                                            radiodescstyles={radiodescstyles}
                                            radiostyles={radiostyles}
                                            radiotext={radioText}
                                            selected={!!(citizenship && citizenship.toString() === field.key)}
                                            ariaRequired="true"
                                            disabled={role !== 'Guest' && role !== 'Prospect' && this.props?.jointInfo !="Yes"}
                                            valuesm={5}
                                        />
                                    ))}
                                    </span>
                            </Row>

                                {citizenship === 'us'&& this.resetResident()}
                                 {resident === 'Non-resident Alien' && this.resetResident()}
                                {/* {citizenship === 'us' &&  
                       
                                 (

                                
                                        <WInput
                                        aria-required
                                        label="Country of Citizenship"
                                        arialabel="Country of Citizenship"
                                        inputclassname={readOnly ? 'prefilled' : ''}
                                        name="taxID"
                                        id={assignObj(`primaryOwnerTaxId`)}
                                        value={this.state.taxID}
                                        type="text"
                                        required
                                        readOnly={true}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={40}
                                        inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                    />
                                    
                                )} */}

                           { citizenship === 'nonus' && (
                                    <Row className="Slabel no-gutters" role="group" aria-labelledby={assignObj(`primaryOwnercitizenship`)}>
                                <span
                                    className="label col-sm-4"
                                    id={assignObj(`primaryOwnerResident`)}
                                    style={labelTextStyle}
                                >
                                    {"Resident Information"}
                                </span>
                                <span className='col-sm-8 marketradioalign'>
                                {residentOption &&
                                    residentOption.length > 0 &&
                                    residentOption.map(field => (
                                        <WRadio
                                            radioFor="residentOption"
                                            key={field.key}
                                            id={field.key}
                                            value={field.value}
                                            onBlur={assignObj(this.handleRadioBlurOnTab('resident'))}
                                            onClick={assignObj(
                                                this.handleCitizenshipRadioBlur('resident', field.key) 
                                            ) }
                                            radiodescstyles={radiodescstyles}
                                            radiostyles={radiostyles}
                                            radiotext={radioText}
                                            selected={!!(resident && resident.toString().toLowerCase() === field.key.toLowerCase())}
                                            ariaRequired="true"
                                            disabled={this.readOnlyMode("resident")}
                                            valuesm={5}
                                        
                                        />
                                    ))}
                                    {showResidentErr && (
                                        <div
                                            className="errorMsgInputStyle errorSameAddressDiv"
                                            id="genderError"
                                            aria-atomic="true"
                                            aria-live="assertive"
                                            style={{width: '100%', float:'left'}}
                                        >
                                            <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                                            <div id="errGender">Please select a value</div>
                                        </div>
                                    )}
                                    </span>
                            </Row>

                                )
                            }

                               { citizenship === 'nonus' && resident === 'Resident Alien' && ( <> <FieldValue
                                    as="p"
                                    id="vcm-cms-country-of-citizenship-label"
                                    label="Country of Citizenship"
                                    value={
                                    
                                        <SelectDropDown
                                            aria-labelledby="vcm-cms-country-of-citizenship-label"
                                            id="vcm-cms-country-of-citizenship"
                                            data-name="Country of Citizenship"
                                            value={this.getKey('country_3_lettercode',countryOfCitizenship)}
                                            onChange={this.handleChange('countryOfCitizenship')}
                                            errortext={errorMsg.countryOfCitizenship}
                                            disabled={this.readOnlyMode("countryOfCitizenship")}
                                            itemlist={this.getCountryMetaData(countryOp)}
                                        />
                                    
                                       
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    alignSelectAccOpt = {alignSelectAccOpt}
                                />
                                

                                   <WInput
                                    aria-required
                                    label="U.S. Permanent Resident Card Number"
                                    arialabel="residentCardNumber"
                                    inputclassname={readOnly ? 'prefilled' : ''}
                                    name="residentCardNumber"
                                    id={assignObj(`residentCardNumber`)}
                                    value={residentCardNumber || ''}
                                    type="text"
                                    required
                                    onChange={this.handleChange('residentCardNumber')}
                                    readOnly={this.readOnlyMode("residentCardNumber")}
                                    labelsm={4}
                                    valuesm={5}
                                    maxlength={13}
                                    errortext={errorMsg.residentCardNumber}
                                    inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                />
                             <VictoryDatePicker
                                id={this.props?.jointInfo !="Yes" ? assignObj(`primaryownerResidentExpiry`) : assignObj(`secondaryownerResidentExpiry`)}
                                value={ownerResidentExpiry && ownerResidentExpiry.replace(/-/g, '/')}
                                onDateChange={this.handleDateChange}
                                onDateBlur={this.handleDateBlur}
                                argument1="ownerResidentExpiry"
                                isFilterForPADStartDate
                                label={"Expiration Date"}
                                errortext={errorMsg.ownerResidentExpiry}
                                dobValidation={false}
                                DOBErrorMsg={Consts.DOBErrorMsg}
                                disabled={this.readOnlyMode("ownerResidentExpiry")}
                                datePickerModule="fundSelectionNAO"
                                minDate={currentDate}
                                maxDate
                                ariaLabelledBy={this.props?.jointInfo !="Yes" ? "primaryownerResidentExpiry" : "secondaryownerResidentExpiry"} 
                                ariaDescribedBy="PersonalInfoExpiryDate"
                            />
                                   <WInput
                                    aria-required
                                    label="Passport Number issued by Country of Citizenship"
                                    arialabel="passportNumber"
                                    inputclassname={readOnly ? 'prefilled' : ''}
                                    name="passportNumber"
                                    id={assignObj(`passportNumber`)}
                                    value={passportNumber ||  ''}
                                    type="text"
                                    required
                                    onChange={this.handleChange('passportNumber')}
                                    readOnly={this.readOnlyMode("passportNumber")}
                                    labelsm={4}
                                    valuesm={5}
                                    maxlength={40}
                                    minDate={currentDate}
                                    errortext={errorMsg.passportNumber}
                                    inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                    optional="true"
                                />

                                <VictoryDatePicker
                                id={this.props?.jointInfo !="Yes" ? assignObj(`primaryOwnerPassportExpiry`) : assignObj(`secondaryOwnerPassportExpiry`)}
                                value={ownerPassportExpiry && ownerPassportExpiry.replace(/-/g, '/')}
                                onDateChange={this.handleDateChange}
                                onDateBlur={this.handleDateBlur}
                                argument1="ownerPassportExpiry"
                                 isFilterForPADStartDate
                                label={"Passport Expiry Date"}
                                dobValidation={false}
                                errortext={errorMsg.ownerPassportExpiry}
                                DOBErrorMsg={Consts.DOBErrorMsg}
                                disabled={this.readOnlyMode("ownerPassportExpiry")}
                                datePickerModule="fundSelectionNAO"
                                ariaLabelledBy={this.props?.jointInfo !="Yes" ? "primaryownerPassportExpiry" : "secondaryownerPassportExpiry"} 
                                ariaDescribedBy="PersonalInfostartDate"
                                optional={true}
                            />

                              </> )}

                            
                             {
                                this.state.showNonResidentPopUp ? 
                                    <MessagePopup show={this.state.showNonResidentPopUp} modalBodyText={nonResidentError} secondaryButtonText="Ok"   onSecondaryClick={this.handleCloseModal}  primaryButtonText="" />
                                :''
                             }
                            
                            </>
                          }
                          
                            
                            
                            {this.renderSSNComponent(savedSSNData)} {verifyContactHider || verifyPhoneHider || verifyEmailHider || verifyJointContactHider || verifyJointPhoneHider || verifyJointEmailHider?null:<div><img id="SsnHelpericon" className='Tooltip' onMouseOut={closeToolTipForSSN} src={helperIcon} alt=""  />

                            <StyledToolTip
                                id="SsnHelpericon_Tooltip"
                                placement="top"
                                tooltipOpen={HelperIconForSSN}
                                targetID="SsnHelpericon"
                                handleToggle={toggleToolTipForSSN}
                                autohide={true}
                                triggerEvt="hover focus"
                                tooltipText={HelpText_SSN}
                                closeTooltip={closeToolTipForSSN}
                            /> </div>}
                            
                        </Card.Body>
                    </Card>
                </Accordian>
                {this.renderPhysicalAddressAccordion(accountType)}
                {this.renderMailingAddressAccordion(accountType)}

                <MarketContactDetailsCardComponent
                    ref={this.perContactDetailsPref}
                    primaryPhoneNumber={primaryPhoneNumber}
                    isPrimaryNumberChanged={isPrimaryNumberChanged}
                    isSecondaryNumberChanged={isSecondaryNumberChanged}
                    secondaryPhoneNumber={secondaryPhoneNumber}
                    hasSecondaryPhoneNumber = {hasSecondaryPhoneNumber}
                    mPrimaryPhoneNumber={primaryMPhoneNumber}
                    mSecondaryPhoneNumber={secondaryMPhoneNumber}
                    primaryContactTime={primaryContactTime}
                    secondaryContactTime={secondaryContactTime}
                    primaryIsMobile={primaryIsMobile}
                    secondaryIsMobile={secondaryIsMobile}
                    primaryCountryCode={primaryCountryCode}
                    secondaryCountryCode={secondaryCountryCode}
                    contactTimeOp={contactTimeOp}
                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                    handleStateChange = {this.handleStateChange}
                     verifyPersonalHider={verifyPersonalHider}
                    verifyContactHider={verifyContactHider}
                    verifyPhoneHider={verifyPhoneHider}
                    verifyEmailHider={verifyEmailHider}
                    verifyJointPersonalHider={verifyJointPersonalHider}
                    verifyJointContactHider={verifyJointContactHider}
                    verifyJointPhoneHider={verifyJointPhoneHider}
                    verifyJointEmailHider={verifyJointEmailHider}
                    primaryEmailAddress={primaryEmailAddress}
                    secondaryEmailAddress={secondaryEmailAddress}
                    hasSecondaryEmailAddress={hasSecondaryEmailAddress}
                    isSecondaryEmailEditable={isSecondaryEmailEditable}
                    isReadOnly={role !== 'Guest' && role !== 'Prospect'}
                    jointInfo={this.props?.jointInfo == "Yes" ? "Yes" : null}
                    marketNAOInfo = {this.props?.jointInfo == "Yes" ? this.props?.marketNAOInfo : null}
                    marketJointPhoneLabel={this.props?.jointInfo == "Yes" ? "Yes" : "No"}
                />
        
            </>
        );
    }
}

MarketPersonalInfoCardComponent.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeData: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    // isJoint: PropTypes.bool,
    checkStatus: PropTypes.func,
    sendDOB: PropTypes.func,
    personalInfo: PropTypes.instanceOf(Object),
};

MarketPersonalInfoCardComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeData: () => {},
    localStateData: {},
    savedState: undefined,
    initialState: {},
    checkStatus: () => {},
    sendDOB: () => {},
    personalInfo: {},
};

export default MarketPersonalInfoCardComponent;
