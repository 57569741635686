const data = {
    title: "Choose One Time Passcode Method",
    getInfo: number => [`You have identified ${number} to receive a One Time Passcode.  This step is a feature that adds an extra layer of security by delivering a code that you can use to verify your account.  If this phone number cannot receive a text message, please use the Voice option to receive an automated phone call`],
    verifyLabel: 'One Time Passcode Delivery Preference',
    wrongOtp: 'Incorrect One Time Passcode, please try again or request new Security Code',
    unknownLogin: 'We have sent One Time Passcode to your mobile',
    resent: 'As requested, we have sent you a new One Time Passcode.',
    enterOtp: 'Enter One Time Passcode: ',
    proceed: ' to proceed.',
    inputCol: {paddingLeft: 0, paddingRight: 14},
    optionalMessage: '* All fields are required unless mentioned optional',
    formGroup: {display: 'flex'},
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Personal Details', 'Manage Address'],
    ivrFailMessage: 'Voice Verification failed',
    ivrFailMessageMemberSignup: 'One Time Passcode validation failed, please try again',
    otpVerify: 'otpVerify',
    otp: 'otp',
    directRegistrationTitle: 'Choose One Time Passcode (OTP) Method',
    directRegistrationVerifyLabel: 'One Time Passcode Delivery Preference',
    apiError: 'Unable to process the request. Please try after sometime.'
  };

  export default data;