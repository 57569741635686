import React from "react";
import PropTypes from 'prop-types';
import editIcon from 'assets/EditPencil.svg';
import { FieldValue, PrefilledInput, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from '../constants';

const FinancialInfoView = (props) => {
    const { annualIncome,taxBracket, netWorth, taxFilingStatus, onManageClick, hideManage } = props;
    return(
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.FINANCIAL_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_FINANCIAL}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='0.625'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.ANNUAL_INCOME}
                    value={
                        <PrefilledInput
                            id="vcm-cms-primary-annualIncome"
                            value={checkValues(annualIncome)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.TAX_BRACKET}
                    value={
                        <PrefilledInput
                            id="vcm-cms-primary-taxBracket"
                            value={taxBracket ? `${checkValues(taxBracket)}%` : checkValues(taxBracket)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.NET_WORTH}
                    value={
                        <PrefilledInput
                            id="vcm-cms-primary-networth"
                            value={checkValues(netWorth)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.TAX_STATUS}
                    value={
                        <PrefilledInput
                            id="vcm-cms-primary-taxstatus"
                            value={checkValues(taxFilingStatus)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='1.75'
                />
            </>
        );
}

FinancialInfoView.propTypes = {
    annualIncome: PropTypes.string,
    taxBracket: PropTypes.string,
    netWorth: PropTypes.string,
    taxFilingStatus: PropTypes.string,
    onManageClick: PropTypes.func,
    hideManage: PropTypes.bool,
}

FinancialInfoView.defaultProps = {
    annualIncome: '',
    taxBracket: '',
    netWorth: '',
    taxFilingStatus: '',
    onManageClick: () => {},
    hideManage:false,
}

export default FinancialInfoView;