import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions} from '../../../../shared/Actions';
import JovRegisterOnlineId from './JovRegisterOnlineId';



const mapStateToProps = (state /* , props */) => (
    {
        JovRegisterOnlineIdState: state.localStateManagementReducerData.JovRegisterOnlineIdState,
        personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
        registerState: state.localStateManagementReducerData.registerState,
        jovSsnState: state.localStateManagementReducerData.jovSsnState
    }
  );

const mapDispatchToProps = {
     ...localStateManagementActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(JovRegisterOnlineId));