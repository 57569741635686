import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Row, Col } from 'react-bootstrap';
import { Accordian, AccordianTitle } from '../../../../common';
import * as TransactionConstants from '../../TransactionConstants';

const FlexDirect = styled.div`
    display: flex;
    flex-direction: row;
`;
const InputFieldradio = styled.input.attrs(({ idLabel, disabled, tabIndex }) => ({
    id: idLabel,
    'aria-disabled': disabled,
    'aria-label': idLabel,
    role: 'radio',
    'aria-checked': 'false',
    tabindex: tabIndex,
    type: 'radio',
}))`
    height: 20px;
    width: 20px;
    cursor: pointer;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #49494a;
    opacity: 1;
    &:focus {
        outline: 2px solid #004a98;
    }
`;
const InputRadioText = styled.span.attrs(({ forTxt }) => ({ htmlFor: forTxt }))`
    margin-right: 80px;
    margin-left: 10px;
    text-align: left;
    font: 600 16px/22px benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    font-size: 16px/18px;
`;

const FundSourceSubheadingStyle = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-size: 16px/18px;
    font-style: bold;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 30px;
`;
// const subHeadingStyle = {
//     textAlign: 'left',
//     font: 'Medium 16px/22px Benton Sans',
//     letterSpacing: 0,
//     color: '#49494A',
//     opacity: 1,
//     marginBottom:30
// }

class ReInvestComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchOn: true,
            switchOff: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ReInvestSwitchOn !== state.switchOff) {
            return {
                switchOn: !props.ReInvestSwitchOn,
                switchOff: props.ReInvestSwitchOn,
            };
        }

        return null;
    }

    render() {
        const {
            forwardRef,
            switchOnMethod,
            switchOffMethod,
            switchOn,
            switchOff,
            payMethods,
            id,
            accIndex,
            labelledBy,
        } = this.props;
        const { check, wireTransfer } = payMethods;
        return (
            <div data-test="reinvest-component">
                <Accordian
                    titleChild={
                        <AccordianTitle accType="Dividends and Capital Gains Preferences" id={id} accIndex={accIndex} />
                    }
                    titleSeperator
                    ref={forwardRef}
                    key="adasi"
                    data-test="dividends-accordian"
                    labelledBy={labelledBy}
                >
                    <div role="group" aria-labelledby="ReinvestDividends">
                        <FundSourceSubheadingStyle>{TransactionConstants.REINVEST_DIVIDENTS}</FundSourceSubheadingStyle>
                        {/* <div style={subHeadingStyle} data-test="fund-source-subhead">
                            {TransactionConstants.REINVEST_DIVIDENTS_SUBTEXT}
                        </div> */}

                        <FlexDirect data-test="radio-buttons-div">
                            <InputFieldradio
                                tabIndex={0}
                                idLabel="yes-reinvest"
                                data-test="off-switch"
                                disabled={check || wireTransfer}
                                type="radio"
                                checked={switchOn}
                                name="account type"
                                value={TransactionConstants.YES_REINVEST}
                                onClick={switchOffMethod(TransactionConstants.YES_REINVEST)}
                                onFocus={switchOffMethod(TransactionConstants.YES_REINVEST)}
                            />
                            <InputRadioText forTxt="yes-reinvest">{TransactionConstants.YES_REINVEST}</InputRadioText>

                            <InputFieldradio
                                tabIndex={0}
                                idLabel="no-reinvest"
                                data-test="on-switch"
                                disabled={check || wireTransfer}
                                type="radio"
                                checked={switchOff}
                                name="account type"
                                value={TransactionConstants.NO_REINVEST}
                                onClick={switchOnMethod(TransactionConstants.NO_REINVEST)}
                                onFocus={switchOnMethod(TransactionConstants.NO_REINVEST)}
                            />
                            <InputRadioText forTxt="no-reinvest">{TransactionConstants.NO_REINVEST}</InputRadioText>
                        </FlexDirect>
                        {/* {check || wireTransfer ? (
                            <div id="reInvestDividends">
                                <Row>
                                    <Col md={12}>
                                        <Row noGutters>
                                            <Col md={12} className="dividendsInsideText">
                                                {TransactionConstants.REINVEST_DIVIDENTS}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            ''
                        )} */}
                    </div>
                </Accordian>
            </div>
        );
    }
}
ReInvestComponent.propTypes = {
    switchOnMethod: PropTypes.func,
    switchOffMethod: PropTypes.func,
    switchOn: PropTypes.bool,
    switchOff: PropTypes.bool,
    forwardRef: PropTypes.instanceOf(Object).isRequired,
    payMethods: PropTypes.instanceOf(Object),
    id: PropTypes.string,
    accIndex: PropTypes.number,
    labelledBy: PropTypes.string,
    ReInvestSwitchOn: PropTypes.bool,
};

ReInvestComponent.defaultProps = {
    switchOnMethod: () => {},
    switchOffMethod: () => {},
    switchOn: true,
    switchOff: false,
    payMethods: {},
    id: 'id',
    accIndex: 0,
    labelledBy: 'label',
    ReInvestSwitchOn: false,
};

export default ReInvestComponent;
