import { LOAD_ACCOUNT_REGISTRATION_API } from 'shared/ReduxConstants/AccountRegistrationConstants';

export const dispatchLoading = (page) => (dispatch) => {
  let loading = {
    connecting: false,
    processing: false,
    thanks: false,
    welcomePageLoaded: false,
  };
  switch (page) {
  case 'WelcomePage':
    loading = { ...loading, connecting: true };
    break;
  case 'SSNAuth':
  case 'OTCDetails':
    loading = { ...loading, processing: true };
    break;
  default:
    loading = { ...loading };
    break;
  }
  return dispatch({
    type: LOAD_ACCOUNT_REGISTRATION_API,
    payload: {
      ...loading,
    },
  });
};

export const dispatchWithData = (type, payload) => (dispatch) => dispatch({
  type,
  payload,
});
