import * as TransactionActionConstants from '../ReduxConstants/TransactionActionConstants';

const {
    GET_TRADING_CALENDAR_SUCCESS,
    SET_TRADING_CLOSURE_STATUS_SUCCESS,
    TRADING_WINDOW_END_OF_DAY_CLOSURE_MESSAGE,
    SET_NAVIGATION_FROM_TRANSACTION_DASHBOARD_SUCCESS,
    SET_TRADINGWINDOW_DATA,
} = TransactionActionConstants;

const initialState = {
    canTrade: true,
    canTradeToday: true,
    marketOpenDiff: 0,
    marketClosureDiff: 0,
    marketStartTime: '',
    marketEndTime: '',
    warningSpan: 0,
    nextBusinessDay: '',
    fromDashboard: false,
    tradingClosureMsg: {
        message: TRADING_WINDOW_END_OF_DAY_CLOSURE_MESSAGE,
    },
    effectiveDate: '',
    showTradingWindow: true,
    secondBusinessDay: '',
    thirdBusinessDay: '',
    aipTradingStartDateKey:'',
};

function tradingWindowReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRADING_CALENDAR_SUCCESS: {
            return {
                ...state,
                canTrade: action.payload.canTrade,
                canTradeToday: action.payload.canTradeToday,
                marketOpenDiff: action.payload.marketOpenDiff,
                marketClosureDiff: action.payload.marketClosureDiff,
                marketStartTime: action.payload.marketStartTime,
                marketEndTime: action.payload.marketEndTime,
                warningSpan: action.payload.warningSpan,
                nextBusinessDay: action.payload.nextBusinessDay,
                effectiveDate: action.payload.effectiveDate,
                tradingStartTimeEST: action.payload.tradingStartTimeEST,
                tradingEndTimeEST: action.payload.tradingEndTimeEST,
                tradingActualEndTimeEST: action.payload.tradingActualEndTimeEST,
                tradingDayHour: action.payload.tradingDayHour,
                tradingStartTime: action.payload.tradingStartTime,
                tradingEndTime: action.payload.tradingEndTime,
                tradingClosureMsg: {
                    message: action.payload.warningMessage,
                },
                secondBusinessDay: action.payload.secondBusinessDay,
                thirdBusinessDay: action.payload.thirdBusinessDay,
                aipTradingStartDateKey: action.payload.aipTradingStartDateKey
            };
        }

        case SET_TRADING_CLOSURE_STATUS_SUCCESS: {
            return {
                ...state,
                canTrade: action.payload.canTrade,
                effectiveDate: action.payload.effectiveDate,
                tradingClosureMsg: { message: action.payload.warningMessage },
            };
        }

        case SET_NAVIGATION_FROM_TRANSACTION_DASHBOARD_SUCCESS: {
            return {
                ...state,
                fromDashboard: true,
            };
        }

        case SET_TRADINGWINDOW_DATA: {
            const { value } = action;
            return {
                ...state,
                showTradingWindow: value,
            };
        }

        default:
            return state;
    }
}

export default tradingWindowReducer;
