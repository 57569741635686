const data = {
    title: "Reset Password",
    your: "Your ",
    id: "Online ID",
    created: " is successfully created.",
    guidelinesHeading: 'Format guidelines:',
    minText: 'Be at least 8 characters in length',
    upperLowerText: 'Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)',
    numeralText: 'Have at least one numerical character (e.g. 0-9)',
    symbolText: 'Have at least one special character (e.g. ~!@#$%^&*()_-+=) or space',
    dictionaryText: 'Not spell a word or series of words that can be found in a standard dictionary',
    noStartEndNumberText: 'Not spell a word with a number added to the beginning and the end',
    personalText: 'Not be based on any personal information (e.g. user id, pet, birthday, etc.)',
  
  }
  
  export default data;