import styled from 'styled-components';

import { Col, Row } from 'react-bootstrap';

const fontSize = {
    regular: 400,
    medium: 500,
    extraBold: 800,
    bold: 700,
};
const Styles = {
    NoRecords: styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-top: 5px;
    `,
    FormTitle: styled(Col)`
        color: rgba(72, 109, 144, 1);
        font: 800 30px/36px yorkten-slab-normal;
        border-bottom: 2px solid #8bc105;
        height: fit-content;
        padding-bottom: 10px;
    `,
    Container: styled.div`
        & .row {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 15px;
        }
        & [class*='col-'] {
            padding-left: 0px;
            padding-right: 0px;
        }
    `,
    Link: styled.a`
        color: #004a98;
        text-decoration: underline;
        cursor: pointer;
        opacity: 1;
        font-weight: ${fontSize.medium};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 19px;
    `,
    SubTitle: styled.div`
        font-weight: ${fontSize.regular};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 26px;
    `,
    FormHeader: styled(Row)`
        margin-top: 25px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 2px solid #8bc105;
        font-weight: bold;
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 24px;
        color: #49494a;
        padding-bottom: 15px;
    `,
    FormItem: styled(Row)`
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: ${fontSize.regular};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 20px;
    `,
    LinkIconDiv: styled.div`
        display: flex;

        & a {
            margin-right: 5px;
        }
    `,
    Rating: styled(Col)`
        text-align: right;
        display: flex;
        padding-left: 15px !important;
        & small {
            margin-left: 15px !important;
        }
    `,
    SortBy: styled(Col)`
        color: #004a98;
        font-weight: ${fontSize.extraBold};
        font-family: Yorkten Slab;
        font-size: 16px;
        line-height: 20px;
        display: flex !important;
        padding-left: 15px;
    `,

    BoxLink: styled.div`
        color: #004a98;
        text-decoration: none;
        cursor: pointer;
        opacity: 1;
        font-weight: ${fontSize.medium};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        height: 58px;
        width: 80%;
        padding-top: 18px;
        cursor: pointer;
    `,
    BoxArrowDown: styled.div`
        border: 1px solid #49494add;
        box-sizing: border-box;
        box-shadow: 0px 0px 16px #00000029;
        text-align: center;
        height: 58px;
        width: 80%;
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #49494a;
        opacity: 1;
        font-weight: ${fontSize.medium};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 22px;
        padding-top: 15px;

        :after {
            content: ' ';
            position: absolute;
            width: 10px;
            height: 10px;
            border-top: 0px solid #49494add;
            border-right: 1px solid #49494add;
            border-left: 0px solid #49494add;
            border-bottom: 1px solid #49494add;
            top: 100%;
            left: 37%;
            margin-left: 0px;
            transform: rotate(45deg);
            margin-top: -5px;
            background: white;
        }
    `,

    Forms: styled.div`
        .form-row:nth-child(even) {
            background: #fafafa 0% 0% no-repeat padding-box;
        }
    `,
    ImgSrc: styled.img`
        height: 20px;
        width: 20px;
    `,
    Footer: styled.div`
    cursor: pointer;
    `,
};

export default Styles;
