import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from '../../../common/CommonStyles';
import { Tooltip } from 'reactstrap';

const StyleTooltip = styled(Tooltip)`
    .bs-tooltip-auto[x-placement^='bottom'] .arrow,
    .bs-tooltip-bottom .arrow {
        top: 5px;
    }
    .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
        border-bottom-color: transparent;
        border-width: 0;
        border-top: 1px solid #dededf;
        border-left: 1px solid #dededf;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        z-index: 1;
        box-shadow: -2px -2px 25px #000000;
        background-color: white;
    }
    .bs-tooltip-auto {
        position: absolute;
        will-change: transform;
        top: 0px;
        left: 0px;
        transform: translate3d(318px, 889px, 0px);
    }
    .tooltip-inner {
        min-width: 266px;
        max-width: 325px;
        padding: 10px 40px 10px 30px;
        color: #212529;
        background-color: #fffefe;
        border: 1px solid #dededf;
        box-shadow: 0px 0px 25px #000000;
        border-radius: 0;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        &::before {
            content: '';
            width: 0px;
            height: 0px;
            border-bottom: 25px solid transparent;
            border-left: 25px solid rgba(139, 193, 5, 1);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .tooltip.show {
        opacity: 1;
    }
`;

const StyledToolTip = props => {
    const {
        placement,
        tooltipOpen,
        targetID,
        triggerEvt,
        tooltipText,
        handleToggle,
        closeTooltip,
        closeTabKey,
        autohide,
        id,
        tooltipTitle
    } = props;
    const assignObj = obj => {
        return obj;
    };
    return (
        <StyleTooltip
            placement={placement}
            isOpen={tooltipOpen}
            target={targetID}
            toggle={handleToggle}
            autohide={autohide}
            trigger={triggerEvt}
        >
            <h2 className="sr-only" tabIndex="-1">{tooltipTitle}</h2>
            {!autohide && (
                <div data-testid="wicon-component-id">
                    <FontAwesomeIcon
                        tabIndex="0"
                        icon={faTimes}
                        size="1x"
                        id={id}
                        style={assignObj({ ...styles.fontawesomeIconStyles, ...styles.tooltipFontawesomestyle })}
                        onClick={closeTooltip}
                        onKeyDown={closeTabKey}
                        role="button"
                        aria-hidden={null}
                        aria-label={`${tooltipText} close`}
                    />
                </div>
            )}
            <span>{tooltipText}</span>
        </StyleTooltip>
    );
};

StyledToolTip.propTypes = {
    placement: PropTypes.string,
    tooltipOpen: PropTypes.bool,
    autohide: PropTypes.bool,
    targetID: PropTypes.string,
    triggerEvt: PropTypes.string,
    tooltipText: PropTypes.string,
    handleToggle: PropTypes.func,
    closeTooltip: PropTypes.func,
    id: PropTypes.string,
    tooltipTitle: PropTypes.string,
    onKeyDown: PropTypes.func,
};

StyledToolTip.defaultProps = {
    placement: 'bottom',
    triggerEvt: 'focus',
    tooltipText: '',
    targetID: '',
    autohide: false,
    tooltipOpen: false,
    handleToggle: () => {},
    closeTooltip: () => {},
    id: '',
    tooltipTitle: '',
    onKeyDown: () => {},
};

export default StyledToolTip;
