/* eslint-disable react/jsx-no-literals */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isTablet } from 'react-device-detect';

const assignObj = (obj) => obj;

const data = {
  vcm: 'Victory Capital means Victory Capital Management',
  subtitle: 'the investment adviser of the'
}

const LandingContent = ({ addExtraPading }) => (
  <div
    className={`${addExtraPading ? 'extraPadding' : ''} pad`}
    id="main-disclosure"
  >
    <div className="welcomePageDisclosure col-lg-7 col-md-12">
      <p className="listDesc">
        Consider the investment objectives, risks, charges and expenses of the
        funds carefully before investing. Download a
        {' '}
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/inet/wc/investments-mutual-funds-prospectus-reports"
            target="_blank"
            rel="noopener noreferrer"
            className="inlineLInk"
            aria-describedby={isTablet ? 'external-link' : null}
          >
            prospectus or summary prospectus
            <span
              className={isTablet ? 'sr-only hidenTablet' : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              External Website. Opens New Window
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
          </a>
        </span>
        {' '}
        containing this and other important information. Read it carefully
        before investing.
      </p>
      <div className="investmentInvokCont">
        <br aria-hidden="true" />
        <span style={assignObj({ fontWeight: 'bold' })}>
          Investing involves risk, including possible loss of principal.
        </span>
        <br aria-hidden="true" />
        <ol className="orderList" role="presentation">
          <li>
            <span className="anchorSpan">
              <a
                href="https://ir.vcm.com/news/news-details/2020/Victory-Capital-Ranks-7th-Over-Five-Years-in-Barrons-Top-Fund-Families-of-2019/default.aspx"
                target="_blank"
                rel="noopener noreferrer"
                className="inlineLInk"
                aria-describedby={isTablet ? 'external-link' : null}
              >
                Victory Capital is highly rated by Barron’s. Read more about it.
                <span
                  className={isTablet ? 'sr-only hidenTablet' : 'sr-only'}
                  aria-hidden={isTablet ? 'true' : 'false'}
                >
                  External Website. Opens New Window
                </span>
                <span aria-hidden="true" className="icon-newpage small-size" />
              </a>
            </span>
          </li>
          <li aria-hidden="true" />
          <li>
            <p>
              Morningstar, 9/30/2019. Together, the expenses of the
              <abbr title="United Services Automobile Association">
                &nbsp;USAA
              </abbr>
              {' '}
              Mutual Funds are “average” in their respective categories.
              Individual funds may have higher than average or lower than
              average expenses compared to other funds in the
              category.&nbsp;Please see the fund’s prospectus or summary
              prospectus for current expenses.
            </p>
            <br aria-hidden="true" />
          </li>
        </ol>
        {data.vcm}
        {' '}
        <abbr title="incorporated">Inc.,</abbr>
        {' '}
        {data.subtitle}
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        Mutual
        Funds and
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        529
        Education Savings Plan (Plan).
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        Mutual
        Funds and the Plan are distributed by Victory Capital Services,
        {' '}
        <abbr title="incorporated">Inc.,</abbr>
        {' '}
        member
        {' '}
        <abbr title="Financial Industry Regulatory Authority">FINRA</abbr>
        , an
        affiliate of Victory Capital.
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        is not
        affiliated with Victory Capital or VCS.
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        and the
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        logos
        are registered trademarks and the
        {' '}
        <abbr title="United Services Automobile Association">USAA</abbr>
        {' '}
        Mutual
        Funds and the Plan logos are trademarks of United Services Automobile
        Association and are being used by Victory Capital and its affiliates
        under license.
      </div>
    </div>
    <p className="defaultFooter1 m-0">
      {data.vcm}
      {' '}
      <abbr title="incorporated">Inc.,</abbr>
      {' '}
      {data.subtitle}
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds.
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds are distributed by Victory Capital Services,
      {' '}
      <abbr title="incorporated">Inc.,</abbr>
      {' '}
      member
      {' '}
      <abbr title="Financial Industry Regulatory Authority">FINRA</abbr>
      , an
      affiliate of Victory Capital. Victory Capital and its affiliates are not
      affiliated with United Services Automobile Association or its affiliates.
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      and the
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      logo are
      registered trademarks and the
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds logo is a trademark of United Services Automobile Association and is
      being used by Victory Capital and its affiliates under license.
    </p>
    <p className="defaultFooter2">
      {data.vcm}
      {' '}
      <abbr title="incorporated">Inc.,</abbr>
      {' '}
      {data.subtitle}
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds and
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      529
      Education Savings Plan (Plan).
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds and the Plan are distributed by Victory Capital Services,
      {' '}
      <abbr title="incorporated">Inc.,</abbr>
      {' '}
      member
      {' '}
      <abbr title="Financial Industry Regulatory Authority">FINRA</abbr>
      , an
      affiliate of Victory Capital.
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      is not
      affiliated with Victory Capital or VCS.
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      and the
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      logos are
      registered trademarks and the
      {' '}
      <abbr title="United Services Automobile Association">USAA</abbr>
      {' '}
      Mutual
      Funds and the Plan logos are trademarks of United Services Automobile
      Association and are being used by Victory Capital and its affiliates under
      license.
    </p>
  </div>
);

LandingContent.propTypes = {
  addExtraPading: PropTypes.bool,
};

LandingContent.defaultProps = {
  addExtraPading: false,
};

export default memo(LandingContent);
