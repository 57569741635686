import React from "react";
import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import { WBreadCrumb } from "common";
import { InstructionalPanel, LinkHighlight, OffScreenText, CardDeck, PageTitle, NavigationCard } from '../components';
import constant from "./constants";

const tel = `tel:${constant.MSR_NUMBER}`;

const StyledCol = styled(Col)`
    padding-bottom: 30px;
`;

const ProfileAndPreferencesDashboard = () => {
    return (
        <Container>
            <WBreadCrumb
                breadCrumbItems={constant.breadCrumbValues}
                activeCrumb={constant.PAGE_HEADING}
            />
            <PageTitle title={constant.PAGE_HEADING} />
            <CardDeck>
                {constant.cardData.map((data, index) => (
                    <StyledCol xs={12} sm={6} lg={4} key={index.toString()}>
                        <NavigationCard {...data} />
                    </StyledCol>
                ))}
            </CardDeck>
            <InstructionalPanel mb={1.5625}>
                {constant.NAME_NOTE_TEXT1}
                <LinkHighlight href={tel}>
                    <OffScreenText hideTxt={constant.PHONE_HINT} />{constant.MSR_NUMBER}
                </LinkHighlight>
                {constant.NAME_NOTE_TEXT2}
            </InstructionalPanel>
        </Container>
    );
};

export default ProfileAndPreferencesDashboard;
