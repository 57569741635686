import { MaskText } from 'common';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
text-align: left;
font-family: benton-sans, sans-serif;
font-weight: 600;
font-style: normal;
line-height: 28px;
font-size: 16px;
letter-spacing: 0px;
color: #004a98;
opacity: 1;
display: block;
`;

const StyledSpan1 = styled.span`
text-align: center;
font-family: benton-sans, sans-serif;
font-weight: 500;
font-style: normal;
line-height: 17px;
font-size: 14px;
letter-spacing: 0px;
color: #49494a;
opacity: 0.75;
`;

const StyledSpan2 = styled.span`
border-bottom: 1px solid #d7d7d7d7;
width: 100%;
`;

const CustomOption = props => {
const { details } = props;
const { bankName = '', bankAccountNumber = '' } = details;
return (
    <StyledSpan2>
        <StyledSpan>{bankName}</StyledSpan>
        <StyledSpan1>
            {bankAccountNumber ?
                <MaskText text={bankAccountNumber.toString()} startIndex={0} maskLength={6} />:
                bankAccountNumber
            }
       </StyledSpan1>
    </StyledSpan2>
)
}

CustomOption.propTypes = {
    details: PropTypes.instanceOf(Object)
}

CustomOption.defaultProps = {
    details: {}
}
export default CustomOption