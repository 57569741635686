import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { WBreadCrumb, WSpinner, VCMSelect } from 'common';
import routeConstants from 'routeConstants';
import { BottomNavBar, InstructionalPanel, PageTitle, FieldValue, PrefilledInput } from 'modules/CustomerManagementModule/components';
import {
    getKeyfromValue,
    getMetadataValues,
    hasValueChanged,
    checkValues,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';
import CONST from '../constants';

const primaryBtnProps = {};
const cancelBtnProps = {};
      
export default class FinancialInfoComponent extends Component {
	constructor(props) {
	super(props);
	this.state = {
		apiFields : {},
	}
}

componentDidMount() {
	const { getCompositeLookUpData } = this.props;
	const payload = this.getLookUpPayload();
    if(payload.length) getCompositeLookUpData(payload);
    addFormAbandonUnloadListner(true);
}

componentDidUpdate(){
    addFormFieldsListner();
	const { profileInformationData, clearExistingActions, history, location, linkResponseError, analyzeClickEvent } = this.props;
	const { isError, isSuccess, message } = profileInformationData;
	const { pathname } = location;
	if(isError){
        const errorTxt = getErrorMessage(message);
        saveBtnLink(`cms_basicinformation_manageFinancialInfo_save`, analyzeClickEvent);
        linkResponseError(errorTxt);
		clearExistingActions();
	}
	if (isSuccess) {
        saveBtnLink(`cms_basicinformation_manageFinancialInfo_save`, analyzeClickEvent);
		history.push(routeConstants.basicinformation, {from: pathname});
	}
}

componentWillUnmount(){
    const { profileInformationData } = this.props;
    const { isSuccess } = profileInformationData;
    if(!isSuccess){
        const digitalData = getFormAbandData(true);
	    const { fieldlasttouch } = digitalData;
        if (fieldlasttouch !== 'none') trackFormAbandonData(digitalData);
    }
    removeFormAbandonUnloadListner();
}

getLookUpPayload = () => {
	const { masterLookupStateData } = this.props;
	const {
		annual_income: annualIncome,
		net_worth: netWorth,
		tax_filling_status: taxFilingStatus,
	} = masterLookupStateData;

	const payLoad = [];
	if (!annualIncome) payLoad.push('annual_income');
	if (!netWorth) payLoad.push('net_worth');
	if (!taxFilingStatus) payLoad.push('tax_filling_status');

	return payLoad;
};

handleChange = (fieldName) => (e) => {
	const { apiFields } = this.state;
	const fieldVal = e.list.find(val => val.key === e.target.value);
	const { value = '', taxbracket ='' } = fieldVal || {};
	apiFields[fieldName.toString()] = value;
	if(fieldName === 'annualIncome'){
	apiFields.taxBracket = taxbracket;
	}
	this.setState({
	apiFields,
	});
}
  
getTaxBracket = (taxBracketValue) => {
    const { apiFields } = this.state;
    const { taxBracket = ''} = apiFields;
    const newtaxBracketValue = taxBracketValue || 0;
    return `${taxBracket || newtaxBracketValue}%`;
}

submitFinancialInfo = (e) => {
    e.preventDefault();
    const {apiFields} = this.state;
    const {updateProfileFinancialInformations} = this.props;
    updateProfileFinancialInformations(apiFields);
}
  
cancelFinancialInfo = () => {
    const {  history, analyzeClickEvent } = this.props;
    analyzeClickEvent({
        link: `cms_basicinformation_manageFinancialInfo_cancel`
    })
    history.push(routeConstants.basicinformation);
}
  
isAnythingChanged = () => {
    const { profileInformationData } = this.props;
    const { profileInformation } = profileInformationData;
    const { financialInformation } = profileInformation || {};
    const { apiFields } = this.state;

    const isAnnualIncomeChanged = hasValueChanged(apiFields, financialInformation, "annualIncome");
    const isTaxBracketChanged = hasValueChanged(apiFields, financialInformation, "taxBracket");
    const isNetWorthChanged = hasValueChanged(apiFields, financialInformation, "netWorth");
    const isTaxFilingStatusChanged = hasValueChanged(apiFields, financialInformation, "taxFilingStatus");

    return (isAnnualIncomeChanged || isTaxBracketChanged || isNetWorthChanged || isTaxFilingStatusChanged);
}

getMasterDataValues = () => {
	const { masterLookupStateData } = this.props;
	const annualIncomeData = getMetadataValues(masterLookupStateData, 'annual_income');
	const netWorthData = getMetadataValues(masterLookupStateData, 'net_worth');
	const taxFillingStatusData = getMetadataValues(masterLookupStateData, 'tax_filling_status');

	return {
		annualIncomeData,
		netWorthData,
		taxFillingStatusData
	}
}

render() {
    const { masterLookupStateData, profileInformationData } = this.props;
    const { profileInformation, isLoading } = profileInformationData;
    const { financialInformation } = profileInformation || {};
    const { annualIncome= '', taxBracket= '', netWorth= '', taxFilingStatus= ''} = financialInformation || {};

    const { annualIncomeData, netWorthData, taxFillingStatusData } = this.getMasterDataValues();

    const loading = isLoading;
	const newTaxBracket = this.getTaxBracket(taxBracket);

	primaryBtnProps.onClick = this.submitFinancialInfo;
	primaryBtnProps.disabled = !this.isAnythingChanged();
	cancelBtnProps.onClick = this.cancelFinancialInfo;
		
    return (
      <>
        {loading && <WSpinner loading={loading} />}
          	<Container>
            <WBreadCrumb
				breadCrumbItems={CONST.bredCrumItmes}
				activeCrumb={CONST.ACTION_LABEL_FINANCIAL}
            />
            <PageTitle 
				  title={CONST.ACTION_LABEL_FINANCIAL}
				  showMandatoryTxt
            />
            <VCMSelect
                id="vcm-cms-annual-income"
                name={CONST.ANNUAL_INCOME_USD}
                label={CONST.ANNUAL_INCOME_USD}
                itemlist={annualIncomeData}
                onChange={this.handleChange('annualIncome')}
                value={getKeyfromValue(masterLookupStateData, 'annual_income',annualIncome)}
                labelsm={3}
                valuesm={4}
				optional
            />
            <FieldValue
                as="p"
                label={CONST.TAX_BRACKET_PERCENTAGE}
                value={
                    <PrefilledInput
                        id="vcm-cms-taxbracket"
                        value={checkValues(newTaxBracket)}
                    />
                }
                labelsm={3}
                valuesm={5}
                mb='0.625'
                optional
            />
            <VCMSelect
                id="vcm-cms-net-worth"
                name={CONST.NET_WORTH_USD}
                label={CONST.NET_WORTH_USD}
                itemlist={netWorthData}
                onChange={this.handleChange('netWorth')}
                value={getKeyfromValue(masterLookupStateData, 'net_worth',netWorth)}
                labelsm={3}
                valuesm={4}
				optional
            />
            <VCMSelect
                id="vcm-cms-tax-filing-status"
                name={CONST.TAX_STATUS}
                label={CONST.TAX_STATUS}
                itemlist={taxFillingStatusData}
                onChange={this.handleChange('taxFilingStatus')}
                value={getKeyfromValue(masterLookupStateData, 'tax_filling_status',taxFilingStatus)}
                labelsm={3}
                valuesm={4}
				optional
            />
            <InstructionalPanel mb={1.5625}>
                {CONST.NOTE_TEXT_2}
            </InstructionalPanel>
          </Container>
		<BottomNavBar
			hideBack
			showCancelBtn
			primaryBtnText={CONST.SAVE}
			primaryBtnProps={primaryBtnProps}
			cancelBtnProps={cancelBtnProps}
        />
      </>
    )
  }
}

FinancialInfoComponent.propTypes = {
	history: PropTypes.instanceOf(Object).isRequired,
	location: PropTypes.instanceOf(Object).isRequired,
	getCompositeLookUpData: PropTypes.func,
	masterLookupStateData: PropTypes.instanceOf(Object),
	profileInformationData: PropTypes.instanceOf(Object),
	updateProfileFinancialInformations: PropTypes.func,
	clearExistingActions: PropTypes.func,
	analyzeClickEvent: PropTypes.func,
	linkResponseError: PropTypes.func,
};

FinancialInfoComponent.defaultProps = {
	getCompositeLookUpData: () => { },
	masterLookupStateData: {},
	profileInformationData:{},
	updateProfileFinancialInformations: () => {},
	clearExistingActions: () => {},
	analyzeClickEvent: () => {},
    linkResponseError: () => {}
}


