import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const StyledBtn = styled(Button)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
`;

const SecondaryBtn = React.forwardRef((props, ref) => {
    return (
        <StyledBtn ref={ref} {...props} />
    )
});

export default SecondaryBtn;
