export const INDIVIDUAL_ACCOUNT = 'Individual Account';
export const CHOOSE_FUNDS = 'Select your Mutual Fund';
export const SELECT_FUNDS = 'Select up to 10 funds from the list below. Use the filter to narrow the list';
export const FUND_NAME = 'Fund Name';
export const MIN_TEXT = 'Minimum';
export const MIN_AUTO = 'Min w/ Auto Investing';
export const RISK = 'Risk';
export const WITH = 'w/';
export const MONTHLY = 'monthly';
export const NO_FUNS = 'No Funds';
export const LOAD = 'Load More';
export const DASH = '-';
export const FUND_YOUR = 'Fund Your Account';
export const CHOOSE_ACCOUNT = 'Choose how to fund your account.';
export const HINT_TEXT = 'This is sample content paragraph';
export const HINT_SUBTEXT =
    'This is sample content paragraph';
export const CONTACT = 'Contact MSR';
export const CALL_TOLL = 'Call toll-free';
export const PHONE = '(855) 756-7520';
export const OR = '[or]';
export const ADD_BANK = 'Add Bank Account';
export const ADD_BANK_SUB_TEXT = '* All fields are required except mentioned optional';
export const ACCOUNT_TYPE = 'Type of Account';
export const SPECIMEN = 'Specimen';
export const FUND_INVESTMENTS = 'Fund Your Investments';
export const CHOOSE_BELOW =
    'Choose your funding options below. For monthly investments; the start day of the month must be a business day and at least 1 business day from today. If the day of the month you select is a holiday; the transaction will occur on the next business day. If you are selecting a monthly investment; please read this important notice';
export const FUND_OPTIONS = 'Funding Options';
export const INIT_INVESTMENT = 'Initial Investment';
export const MONTHLY_INVESTMENT = 'Monthly Investment';
export const START_DATE = 'Monthly Investment Start Date';
export const ACTION = 'Action';
export const DOLLAR = '$';
export const REMOVE = 'Remove';
export const MIN1 = 'Minimum $3000';
export const MIN2 = 'Minimum $500';
export const TOTAL = 'Total';

export const OF = 'of';
export const ITEMS_SELECTED = 'Items selected';

export const ATLEAST_ONE_FUND_ERROR_MESSAGE = 'At least one fund should be selected';
export const ATLEAST_ONE_FUND_METHOD_ERROR_MESSAGE = 'Select your Funding Method';

export const FUND_INVESTMENT_TABLE = 'FUND INVESTMENT TABLE';

export const compositePayload = ['filter_min_inv', 'filter_fund_type', 'filter_risk', 'fund_source', 'fund_options'];

export const invSelectMasterDataError = 'Error in master Data of Investment Selection List';

export const AlertHeadingText = 'Oh snap! You got an error!';

export const alertContent = ' Backend returned error try after sometime or try another combination';

export const compareAlertMsg = 'Please select minimum 2 or maximum 4 funds to compare';

export const radioErrorMsg = 'Please select an Account Type';

export const fundsDetailsList = [
    {
        key: 'Min. / Max. Amount w/ Auto Investing',
        value: '$ 300 / $ 5,000 w/ $ 300 monthly',
    },
    {
        key: 'Change in NAV',
        value: '14.3',
    },
    {
        key: 'Last NAV (Previous day close)',
        value: '$ 143',
    },
    {
        key: '52 week Min. / Max. Values',
        value: '$ 3,000 / $ 5,000',
    },
    {
        key: 'Risk:',
        value: 'Medium',
    },
];

export const fundsDetailsList1 = [
    {
        fundName: 'USAA Intermediate-Term Adviser',
        fundNumber: '330',
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
    {
        fundName: 'Aggressive Growth Fund',
        fundNumber: '331',
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
    {
        fundName: 'USAA Growth & Income Adviser',
        fundNumber: '332',
        symbol: 'UITBX',
        minInvestment: '300',
        maxInvestment: '5000',
        initialInvestment: '3000',
        monthlyInvestment: '300',
        changeInNav: '14.3',
        lastNav: '143',
        min52W: '3000',
        max52W: '5000',
        risk: 'Medium',
    },
];

export const offlineFundingDetails = [
    {
        key: 'USAA Fund Name',
        value: 'Aggressive Growth Fund',
    },
    {
        key: 'USAA Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
    {
        key: 'Name',
        value: 'William',
    },
    {
        key: 'USAA Mutual Fund Account Number',
        value: 'xxxx-xxxx-xxxx',
    },
    {
        key: 'Mailing to This Address',
        value: ['Mellon Trust of New England', '1 Boston Place', 'Boston, Massachusetts 02108', 'ABA # 011001234'],
    },
];

export const dateFormat = 'MM/DD/YYYY';

export const offlineFundingDetailsHintWT =
    'The transaction will be processed on confirmation of wire transfer details. Please wait for 3-5 business days for completion of your transaction.';

export const offlineFundingDetailsHintCheck =
    'The transaction will be processed only after receiving the check. Please wait for 3-5 business days for completion of your transaction.';

export const printText = 'Print';

export const printStyle = `    #offlineFundDetails {
    margin-top: 47px;
    padding: 30px 95px;
    border: 2px solid #8BC105;
    position: relative;
    background-color: #FAFAFA;
}
#offlineFundDetails::before {
    content: "";
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.offlineDetailsHeading {
    margin-bottom: 15px;
    font-size: 22px;
    color: #486D90;
    font-family: "yorkten-slab-normal";
    font-weight: 600;
}
.offlineDetailsSec {
    padding-top: 30px;
    border-top: 1px solid #CECECE;
}
.offlineDetailsSec .row {
    display:flex;
}
.offlineDetailsSec .row:not(.offlineDetailsHint) div {
    width: 50%
}
.offlineDetailsHint {
    margin-top: 40px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 25px 30px 25px 95px;
    font: 600 16px/22px benton-sans;
    position: relative;
}
.offlineDetailsHint::before {
    content: "";
    border-bottom: 25px solid transparent;
    border-left: 25px solid #8BC105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.printAction {
    display: none;
}`;

export const checkOfflineDetails =
    'Please make the check payable to USAA Mutual Funds and include the account number of the mutual fund in the memo section. Note: We do not accept 3rd party checks, traveler’s checks and money orders..';

export const fundDetailsMockList = [
    {
        nav: { asOfDate: '08/05/2019', value: '41.14' },
        fundName: 'USAA Intermediate-Term Bond Adviser',
        morningstarRating: { asOfDate: '06/30/2019', value: '4' },
        monthlyInvestment: '0',
        symbol: 'UITBX',
        initialInvestment: '3000',
        performanceDetails: {
            totalReturns: {
                monthly: {
                    '5year': '10.50%',
                    '1year': '-1.20%',
                    sinceInception: '8.8%',
                    '10year': '-12.90%',
                    inceptionDate: '01/01/2001',
                    ytd: '16.00%',
                    asOfDate: '09/30/2019',
                },
                quarterly: {
                    '5year': '10.50%',
                    '1year': '-1.20%',
                    sinceInception: '8.8%',
                    '10year': '-12.90%',
                    inceptionDate: '01/02/2001',
                    ytd: '15.00%',
                    asOfDate: '11/30/2019',
                },
            },
        },
        morningstarCategory: 'Large Growth',
        moreInfoPDFs: [
            {
                s3Loc: 'dummy',
                value: 'Full Fund Detail and Important Disclosures (PDF)',
                key: 'fundDetailDisclosures',
            },
            { s3Loc: 'dummy', value: 'Prospectus (PDF)', key: 'prospectus' },
        ],
        fundType: 'individual',
        fundNumber: 330,
        expenseRatio: { expenseRatio_AR: '1.00', expenseRatio_BR: '1.00', expenseRatio: '1.00' },
        risk: 'High',
        SecYields: { SecYields_7: '1.00', SecYields_30: '1.00', SecYields_WoWaivers: '1.00' },
        fundDescription: 'USAA Intermediate-Term Bond Adviser Description',
        categoryFunds: '1234',
    },
    {
        nav: { asOfDate: '08/05/2019', value: '30.56' },
        fundName: 'USAA Science & Technology Adviser',
        morningstarRating: { asOfDate: '06/30/2019', value: '5' },
        monthlyInvestment: '0',
        symbol: 'USTCX',
        initialInvestment: '3000',
        performanceDetails: {
            totalReturns: {
                monthly: {
                    '5year': '11.50%',
                    '1year': '5.67%',
                    sinceInception: '6.9%',
                    '10year': '9.35%',
                    inceptionDate: '01/03/2001',
                    ytd: '12.00%',
                    asOfDate: '10/30/2019',
                },
                quarterly: {
                    '5year': '11.50%',
                    '1year': '5.67%',
                    sinceInception: '6.9%',
                    '10year': '9.35%',
                    inceptionDate: '01/04/2001',
                    ytd: '12.00%',
                    asOfDate: '05/30/2019',
                },
            },
        },
        morningstarCategory: 'Large Growth',
        moreInfoPDFs: [
            {
                s3Loc: 'dummy',
                value: 'Full Fund Detail and Important Disclosures (PDF)',
                key: 'fundDetailDisclosures',
            },
            { s3Loc: 'dummy', value: 'Prospectus (PDF)', key: 'prospectus' },
        ],
        fundType: 'individual',
        fundNumber: 331,
        expenseRatio: { expenseRatio_AR: '1.00', expenseRatio_Br: '1.00', expenseRatio: '1.00' },
        risk: 'High',
        SecYields: { SecYields_7: '1.00', SecYields_30: '1.00', SecYields_WoWaivers: '1.00' },
        fundDescription: 'USAA Science & Technology Adviser Fund Description',
        categoryFunds: '567',
    },
];

export const fundList = [
    {
        initialInvestment: '3000',
        fundName: 'USAA Term Bond Adviser',
        fundNumber: '330',
        monthlyInvestment: '0',
        risk: 'High',
        symbol: 'UITBX',
    },
    {
        initialInvestment: '3000',
        fundName: 'USAA Science Adviser',
        fundNumber: '331',
        monthlyInvestment: '0',
        risk: 'High',
        symbol: 'USTCX',
    },
    {
        initialInvestment: '2000',
        fundName: 'USAA Short-Term Bond Adviser',
        fundNumber: '336',
        monthlyInvestment: '0',
        risk: 'Medium',
        symbol: 'UASBX',
    },
];
