import * as TransactionConstants from '../../TransactionConstants';
import {
    AccountInfoCard,
    FundSource,
    Accordian,
    AccordianTitle,
    AddBankAccount,
    WSpinner,
    Address
} from 'common';
import {BottomNavSection} from '../../Common/BottomNavWrapper';
import { connect } from 'react-redux';
import { getToken } from 'utils';
import { liquidationActions } from '../../../../shared/Actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import TaxAccounting from '../../Common/TaxAccounting';
import TaxWithHoldingOptions from '../TaxWithHoldingOptions/TaxOptionsToWithHold';
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 19px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;
AccountDetailsFlex.displayName = 'AccountDetailsFlex';

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    .row {
        margin: 0px 0px;
    }
`;
const AccVerificationBox = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
    margin: 1rem 0px 3.75rem;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
    }
`;
const AccVerificationMessage = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin: 2.1875rem 5.9375rem 2.1875rem;
`;

class FundingOption extends Component {
    constructor(props) {
        super(props);
        const { pageIndex } = this.props;
        this.state = {
            offlineMode: null,
            retrivedState: false,
            disabled: true,
            bankDetails: [],
            addbankvisible: false,
            accountverificationmsg: false,
            bankDisplayRules: [],
            checkDeliveryAddress: [],
            stateCodeGroup: '',
            taxValidity: false,
            distributionAmount: '',
            fedTaxAmount: '',
            fedTaxPercent: '',
            stateTaxAmount: '',
            stateTaxPercent: '',
            totalReceived: '',
            totalTaxAmount: '',
            taxValidation: false,
            displayDetails: {
                fundSource: {
                    titleTxt: 'Fund Source',
                    editTxt: 'Edit',
                    heading: 'Fund Source',
                    pageIndex,
                    data: [],
                },
            },
            checkAddressValid: true,
        };
        this.bankDetailsSectionRef = React.createRef();
        this.deliveryAddressRef = React.createRef();
        this.taxWithHoldRef = React.createRef();
        this.fundFromRef = React.createRef();
        this.taxWithHoldFwdRef = React.createRef();
        this.addbankRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, bankAccountInfo, liquidationData } = nextProps;
        const { bankDetails, bankDisplayRules, stateCodeGroup } = prevState;
        const { taxValidation } = liquidationData;
        const accountSelected = liquidationData && liquidationData.page0 ? liquidationData.page0.accountSelected : {};

        const customerNameLineBreak = accountSelected.accountRegistrationLine1 !== '' ? '\n' : '';
        const addressLine1Break = accountSelected.addressLine2 !== '' ? '\n' : '';
        const addressLine2Break = accountSelected.city !== '' ? '\n' : '';
        const addressLine3Break = accountSelected.state !== '' ? '\n' : '';

        const splitCustomerName =
            accountSelected.accountRegistrationLine1 && accountSelected.accountRegistrationLine1.split('<br/>');
        const formatCustomerName =
            splitCustomerName && splitCustomerName.length > 1 ? splitCustomerName.join('\n') : splitCustomerName;

        const address = accountSelected
            ? `${formatCustomerName}${customerNameLineBreak}${accountSelected.addressLine2 &&
                  accountSelected.addressLine2.trim()}${addressLine1Break}${accountSelected.city &&
                  accountSelected.city.trim()}${addressLine2Break}${accountSelected.state &&
                  accountSelected.state.trim()}${addressLine3Break}${accountSelected.zip && accountSelected.zip.trim()}`
            : '';

        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                bankDetails: savedState.bankDetails.length ? savedState.bankDetails : bankAccountInfo.bankDetails,
                bankDisplayRules: savedState.bankDisplayRules.length
                    ? savedState.bankDisplayRules
                    : bankAccountInfo.bankDisplayRules,
                stateCodeGroup: savedState.stateCodeGroup || liquidationData.stateCodeGroup[0],
                taxValidation,
            };
        }
        return {
            ...prevState,
            isLoading: bankAccountInfo.isLoading || liquidationData.isLoading,
            bankDetails: bankDetails.length ? bankDetails : bankAccountInfo.bankDetails,
            bankDisplayRules: bankDisplayRules.length ? bankDisplayRules : bankAccountInfo.bankDisplayRules,
            checkDeliveryAddress: address,
            stateCodeGroup: stateCodeGroup || liquidationData.stateCodeGroup[0],
            // TODO :: Revert to previous line. Hardcoding group1 till API returns taxGroup
            // stateCodeGroup: 'group5',
            taxValidation,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const {
            editSection,
            getBankAccountInfo,
            getStateCodeGroup,
            setTradingWarning,
            liquidationData,
            accDetails,
        } = this.props;
        const { bankDetails } = this.state;
        const { current } = this.fundFromRef;
        const { offsetTop = 0 } = current || {};

        document.title = 'Deposit Account : Sell | Victory Capital';

        if (editSection === 'Fund From') {
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        } else if (editSection === 'Tax Withholding Options') {
            window.scrollTo({
                top: offsetTop,
                behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
            });
        }
    
        if (!bankDetails.length) {
            getBankAccountInfo({ token: getToken() });
        }

        const stateCodeGroupPayload = {
            companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
            masterReg: liquidationData.page0 && liquidationData.page0.accountSelected.accountNumber,
        };
     
        getStateCodeGroup(stateCodeGroupPayload);

        setTradingWarning();
        sessionStorage.setItem('stepname', 'Funding Option');

        const addressExpDate = accDetails.addrChgExpDte;
        if (
            addressExpDate === '0' ||
            addressExpDate === '' ||
            addressExpDate === null ||
            addressExpDate === undefined
        ) {
            this.setState({ checkAddressValid: true });
        } else {
            const addressExpYear = addressExpDate.substring(0, 4);
            const addressExpMonth = addressExpDate.substring(4, 6);
            const addressExpDay = addressExpDate.substring(6, 8);
            const addressExpDateFormat = new Date(addressExpYear, addressExpMonth - 1, addressExpDay);
            const currentDate = new Date();
            if (addressExpDateFormat >= currentDate) {
                this.setState({ checkAddressValid: false });
            }
        }
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex, isIRATaxWithhold } = this.props;
        const {
            distributionAmount,
            stateTaxAmount,
            fedTaxAmount,
            stateTaxPercent,
            fedTaxPercent,
            totalReceived,
            totalTaxAmount,
        } = this.state;

        const distributionTempValue = `$${parseFloat(distributionAmount).toFixed(2)}`;

        const distributionAmountValue =
            distributionAmount !== '' && distributionAmount !== null
                ? distributionTempValue
                : TransactionConstants.NOT_ON_FILE_TEXT;
        const fedTaxPercentTempValue = `${parseFloat(fedTaxPercent).toFixed(2)}% - ${currencyFormatterFunction(parseFloat(fedTaxAmount).toFixed(
            2,
        ))}`;
        const fedTaxPercentValue =
            fedTaxPercent !== '' && fedTaxPercent !== null
                ? fedTaxPercentTempValue
                : TransactionConstants.NOT_ON_FILE_TEXT;
        const stateTaxTempPercent = `${parseFloat(stateTaxPercent).toFixed(2)}% - ${ currencyFormatterFunction(parseFloat(stateTaxAmount).toFixed(
            2,
        ))}`;
        const stateTaxPercentValue =
            stateTaxPercent !== '' && stateTaxPercent !== null
                ? stateTaxTempPercent
                : TransactionConstants.NOT_ON_FILE_TEXT;
        const totalTaxTempAmount = `$${parseFloat(totalTaxAmount).toFixed(2)}`;
        const totalTaxAmountValue =
            totalTaxAmount !== '' && totalTaxAmount !== null
                ? totalTaxTempAmount
                : TransactionConstants.NOT_ON_FILE_TEXT;
        const totalTempReceived = `$${parseFloat(totalReceived).toFixed(2)}`;
        const totalReceivedValue =
            totalReceived !== '' && totalReceived !== null ? totalTempReceived : TransactionConstants.NOT_ON_FILE_TEXT;

        const deliveryAddressRef = this.deliveryAddressRef.current;
        const taxWithHoldRef = this.taxWithHoldRef.current;
        const bankDetailsSectionState =
            this.bankDetailsSectionRef.current && this.bankDetailsSectionRef.current.getComponentState();
        const addbankRef = this.addbankRef.current;
        let dispDataFortaxWithHold = {};
        let displayDataForBnkSec = {};
        if (bankDetailsSectionState) {
            displayDataForBnkSec = this.bankDisplayData(bankDetailsSectionState);
        }

        const childState = {};

        if (deliveryAddressRef) {
            childState.deliveryAddress = { ...deliveryAddressRef.state };
            const checkDetails = this.pushCheckDetails(childState.deliveryAddress.deliveryAddress);
            displayDataForBnkSec = {
                fundFrom: {
                    titleTxt: 'Deposit To',
                    editTxt: 'Edit',
                    pageIndex: 2,
                    data: checkDetails,
                },
            };
        }
        if (taxWithHoldRef) {
            childState.taxWithHold = { ...taxWithHoldRef.state };
            const { taxWithholdOptions } = childState.taxWithHold.displayDetails || {};
            const { data: taxWithholdOptionsData } = taxWithholdOptions || {};
            if (taxWithholdOptionsData && taxWithholdOptionsData.length) {
                dispDataFortaxWithHold = childState.taxWithHold.displayDetails;
            }
        }
        // TODO: Implement this logic only for IRA Account when proper test data is given
        const taxWithholdData = {
            taxDetails: {
                titleTxt: 'Tax Withholding Options',
                editTxt: 'Edit',
                pageIndex: 2,
                data: [
                    { field: 'Amount Before Taxes', value: currencyFormatterFunction(distributionAmountValue) },
                    {
                        field: 'Federal Tax',
                        value: fedTaxPercentValue,
                    },
                    {
                        field: 'State Tax',
                        value: stateTaxPercentValue,
                    },
                    { field: 'Total taxes to be withheld', value: currencyFormatterFunction(totalTaxAmountValue) },
                    { field: 'Total you will receive', value: currencyFormatterFunction(totalReceivedValue) },
                    { field: 'Total withdrawal', value: currencyFormatterFunction(distributionAmountValue) },
                ],
            },
        };

        const newBankData = {};
        if (addbankRef) {
            newBankData.addbankState = { ...addbankRef.getState() };
            const { otherBankState } = newBankData.addbankState || {};
            const { bankAccountDetails } = otherBankState || {};
            const [newBankName, , , newBankNumber] = bankAccountDetails || [];
            const { value: newBankNameValue } = newBankName || {};
            const { value: newBankNumberValue } = newBankNumber || {};

            const newBankDisplayData = this.pushBankDetails(newBankNameValue, newBankNumberValue);
            displayDataForBnkSec.fundFrom.data = newBankDisplayData;
        }
        const displayDetails = isIRATaxWithhold()
            ? { ...displayDataForBnkSec, ...taxWithholdData }
            : { ...displayDataForBnkSec }; // ...dispDataForFundTo,
        saveChildrenState(
            { ...this.state, ...childState, bankDetailsSectionState, displayDetails, ...newBankData },
            pageIndex,
        );
    }

    bankDisplayData = bankDetailsSectionState => {
        const tempData = bankDetailsSectionState.displayDetails;
        const { fundFrom } = tempData || {};
        const { data } = fundFrom || {};
        let dataForBnkSec = {};
        if (data && data.length) {
            tempData.fundFrom.titleTxt = 'Deposit To';
            tempData.fundFrom.data[0].field = 'Bank Name';
            dataForBnkSec = tempData;
        }
        return dataForBnkSec;
    };

    pushBankDetails = (newBankNameValue, newBankNumberValue) => {
        const newBankDisplayData = [];
        newBankDisplayData.push(
            {
                field: 'Bank Name',
                value: newBankNameValue,
            },
            {
                field: 'Account Number',
                value: newBankNumberValue,
            },
        );

        return newBankDisplayData;
    };

    pushCheckDetails = checkDeliveryAddress => {
        const newCheckDisplayData = [];
        newCheckDisplayData.push(
            {
                field: 'Deposit Type',
                value: 'Check',
            },
            {
                field: 'Check Delivery Address',
                value: checkDeliveryAddress,
            },
        );

        return newCheckDisplayData;
    };

    showOfflineModeOptions = (index, showOptions, checkStatus = false) => {
        let offlineMode = null;
        const addbankvisible = false;
        if (showOptions) {
            offlineMode = 'checkOrder';
        }
        this.setState(
            () => {
                return {
                    offlineMode,
                    addbankvisible,
                };
            },
            () => {
                if (checkStatus) {
                    this.checkStatus();
                }
            },
        );
    };

    disableNextForACH = () => {
        this.setState({
            disabled: true,
        });
    };

    checkStatus = () => {
        let disabled = true;
        const { offlineMode } = this.state;
        const { setFundSourceValidation } = this.props;
        const fundSourceOnline = this.bankDetailsSectionRef.current;
        const deliveryAddressRef = this.deliveryAddressRef.current;
        const addbankRef = this.addbankRef.current;

        if (deliveryAddressRef) {
            disabled = disabled || deliveryAddressRef.isError;
        } else if (!deliveryAddressRef && fundSourceOnline && fundSourceOnline.state.disableNext !== undefined) {
            const cmpState = { ...fundSourceOnline.state };
            disabled = cmpState.disableNext;
        } else if (addbankRef) {
            const cmpState = { ...addbankRef.state };
            disabled = disabled || cmpState.disableNext;
        }

        if (offlineMode === TransactionConstants.CHECK_ORDER_VALUE) {
            disabled = false;
        }
        this.setState({ disabled: false });
        setFundSourceValidation(disabled);
    };

    toggleBank = selectedBank => {
        const { addbankvisible: prevAddBankVisibleState } = this.state;
        const obj = {
            addbankvisible: false,
            accountverificationmsg: false,
        };
        if (selectedBank) {
            if (!selectedBank.bankAccountNumber) {
                obj.addbankvisible = true;
            } else if (selectedBank.isNotVerified) {
                obj.accountverificationmsg = true;
            }
        }
        this.setState({ disabled: false });
        this.setState(obj, () => {
            const { addbankvisible } = this.state;
            if (prevAddBankVisibleState !== addbankvisible) {
                this.checkStatus();
            }
        });
    };

    setTaxStateValues = taxPayload => {
        const {
            fedTaxAmount,
            fedTaxPercent,
            stateTaxAmount,
            stateTaxPercent,
            totalReceived,
            totalTaxAmount,
            distributionAmount,
            stateTaxWithHeld,
        } = taxPayload;

        this.setState({
            fedTaxAmount,
            fedTaxPercent,
            stateTaxAmount,
            stateTaxPercent,
            totalReceived,
            totalTaxAmount,
            distributionAmount,
            stateTaxWithHeld,
        });
    };

    handleNextButtonDisable = ()=>
    {
        this.setState({disabled : false});
    }

    render() {
        const {
            navigationObj,
            savedState,
            pageIndex,
            liquidationData,
            getCheckDeliveryAddresss,
            getStateTaxesForGroup,
            validateACHLimit,
            cancelACHLimit,
            purchaseData,
            onSwitchComponent,
            bankAccountInfo,
            handleError,
            setTaxValidation,
            setPaymentMethod,
            setCancelLiquidation,
            isIRATaxWithhold,
            isIRA
        } = this.props;

        navigationObj.onPrimaryClick = onSwitchComponent(true, this.state, 2);
        const accountName = liquidationData.page0 && liquidationData.page0.accountSelected.accountName;
        const accountNumber = liquidationData.page0 && liquidationData.page0.accountSelected.accountNumber;

        const {
            disabled,
            offlineMode,
            addbankvisible,
            accountverificationmsg,
            bankDetails,
            addbankState,
            isLoading,
            bankDisplayRules,
            checkDeliveryAddress,
            stateCodeGroup,
            checkAddressValid,
            stateTaxWithHeld,
            stateTaxPercent,
        } = this.state;
        const { Deliveryaddress, fundFrom } = TransactionConstants.FUND_SOURCE_PAYMENT_METHOD_DETAILS;
        fundFrom.methods[1].details = [...bankDetails];
        const { accverificationmessage } = fundFrom;
        const { detailName } = Deliveryaddress;
        navigationObj.arialabelback = 'Back to Fund Selections';
        navigationObj.arialabelnext = 'Navigate to Review and Confirm';

        const { bankDetailsSectionState = null, deliveryAddress = null } = savedState || {};
        const { taxValidation, fundSourceValidation } = liquidationData;

        const stateTaxStatus = stateTaxWithHeld && (stateTaxPercent === '' || stateTaxPercent === 'NaN');

        const disabledNextBtn = taxValidation || stateTaxStatus || fundSourceValidation || disabled;
        const accountType =
            liquidationData.page0 &&
            liquidationData.page0.displayDetails &&
            liquidationData.page0.displayDetails.accountSelection &&
            liquidationData.page0.displayDetails.accountSelection.data[2] &&
            liquidationData.page0.displayDetails.accountSelection.data[2].value;

        if (bankAccountInfo.isError || liquidationData.isError) {
            handleError(true);
        }
        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <div className="container">
                    <AccountInfoCard accountName={accountName} accountNumber={accountNumber} />
                    {liquidationData && liquidationData.page1 && liquidationData.page1.selectedFund && (
                        <FundSource
                            ref={this.bankDetailsSectionRef}
                            forwardRef={this.fundFromRef}
                            fundFrom={fundFrom}
                            plan=""
                            pageIndex={pageIndex}
                            savedState={bankDetailsSectionState}
                            showOfflineModeOptions={this.showOfflineModeOptions}
                            checkStatus={this.checkStatus}
                            toggleBank={this.toggleBank}
                            toggleVerifyMessage={this.toggleVerifyMessage}
                            toggleRemoveMessage={this.toggleRemoveMessage}
                            bankDisplayRules={bankDisplayRules}
                            getCheckDeliveryAddresss={getCheckDeliveryAddresss}
                            validateACHLimit={validateACHLimit}
                            cancelACHLimit={cancelACHLimit}
                            accountNumber={accountNumber}
                            totalSubmitAmount={liquidationData.page1 && +liquidationData.page1.selectedFund.sellingamt}
                            validateACHLimitResponseData={purchaseData}
                            disableNextForACH={this.disableNextForACH}
                            setPaymentMethod={setPaymentMethod}
                            checkAddressValid={checkAddressValid}
                            liquidationDataEdit = {liquidationData.page2 !=null ? liquidationData.paymentMethod : null}
                            liquidationData = {liquidationData}
                            handleNextButtonDisable = {this.handleNextButtonDisable}
                        />
                    )}
                    <>
                        {accountverificationmsg && (
                            <AccVerificationBox>
                                <AccVerificationMessage>{accverificationmessage}</AccVerificationMessage>
                            </AccVerificationBox>
                        )}
                    </>
                    {addbankvisible && (
                        <AddBankAccount
                            ref={this.addbankRef}
                            savedState={addbankState}
                            pageIndex={pageIndex}
                            checkStatus={this.checkStatus}
                            note
                        />
                    )}

                    {offlineMode === 'checkOrder' && Object.keys(checkDeliveryAddress).length > 0 && (
                        <Accordian
                            key={detailName}
                            labelledBy={detailName}
                            titleChild={<AccordianTitle accType={detailName} id={detailName} accIndex={0} className="checkDeliveryAddress" />}
                            titleSeperator
                        >
                            <FlexColumnDiv>
                              
                                <Address
                                    ref={this.deliveryAddressRef}
                                    required
                                    savedState={deliveryAddress}
                                    checkDeliveryAddress={checkDeliveryAddress}
                                    onStatusChange={this.checkStatus}
                                />
                            </FlexColumnDiv>
                        </Accordian>
                    )}
                    {!isIRA() &&
                        accountType && accountType.trim() !== TransactionConstants.ARCHER_MSA_ACCOUNT_TYPE &&
                        !liquidationData.page1.selectedFund.fundName
                            .toLowerCase()
                            .includes(TransactionConstants.MONEY_MARKET_TEXT) && (
                            <TaxAccounting
                                costBasis={liquidationData.page1 && liquidationData.page1.selectedFund.costBasis}
                            />
                        )}
                    {isIRATaxWithhold() &&
                        liquidationData &&
                        liquidationData.page1 &&
                        liquidationData.page1.selectedFund && (
                            <TaxWithHoldingOptions
                                checkStatus={this.checkStatus}
                                pageIndex={pageIndex}
                                editSection={this.editSection}
                                stateCodeGroup={stateCodeGroup}
                                amount={liquidationData.page1 && +liquidationData.page1.selectedFund.sellingamt}
                                getStateTaxesForGroup={getStateTaxesForGroup}
                                setTaxStateValues={this.setTaxStateValues}
                                setTaxValidation={setTaxValidation}
                                age={+liquidationData.age}
                                state={liquidationData.stateForStateTax}
                                selectedFund={liquidationData.page1 && liquidationData.page1.selectedFund}
                                liquidationData =  {(liquidationData.page2 !=undefined && liquidationData.page2 !=null) ? liquidationData.page2 : null}
                            />
                        )}
                </div>

                <BottomNavSection {...navigationObj} showBack iscancel disabled={disabledNextBtn}
                    cancelClickHandler={setCancelLiquidation} />
            </>
        );
    }
}

FundingOption.propTypes = {
    saveChildrenState: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    accDetails: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    getBankAccountInfo: PropTypes.func,
    bankAccountInfo: PropTypes.instanceOf(Object),
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
        arialabelback: PropTypes.string,
        arialabelnext: PropTypes.string,
    }),
    getCheckDeliveryAddresss: PropTypes.func,
    liquidationData: PropTypes.instanceOf(Object),
    purchaseData: PropTypes.instanceOf(Object),
    getStateCodeGroup: PropTypes.func,
    setTradingWarning: PropTypes.func,
    getStateTaxesForGroup: PropTypes.func,
    validateACHLimit: PropTypes.func,
    cancelACHLimit: PropTypes.func,
    onSwitchComponent: PropTypes.func,
    isIRA: PropTypes.func,
    handleError: PropTypes.func,
    setTaxValidation: PropTypes.func,
    setFundSourceValidation: PropTypes.func,
    setPaymentMethod: PropTypes.func,
    setCancelLiquidation: PropTypes.func,
    isIRATaxWithhold: PropTypes.func,
};

FundingOption.defaultProps = {
    getBankAccountInfo: () => {},
    bankAccountInfo: {},
    saveChildrenState: () => {},
    savedState: null,
    accDetails: {},
    pageIndex: 0,
    editSection: null,
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        portalRoot: null,
        arialabelback: '',
        arialabelnext: '',
    },
    getCheckDeliveryAddresss: () => {},
    liquidationData: {},
    purchaseData: {},
    getStateCodeGroup: () => {},
    setTradingWarning: () => {},
    getStateTaxesForGroup: () => {},
    validateACHLimit: () => {},
    cancelACHLimit: () => {},
    onSwitchComponent: () => {},
    isIRA: () => {},
    handleError: () => {},
    setTaxValidation: () => {},
    setFundSourceValidation: () => {},
    setPaymentMethod: () => {},
    setCancelLiquidation: () => {},
    isIRATaxWithhold: () => {},
};

export const mapStateToProps = state => {
    return {
        liquidationData: state.liquidationData,
    };
};

export const mapDispatchToProps = {
    getStateTaxesForGroup: liquidationActions.getStateTaxesForGroup,
    setTaxValidation: liquidationActions.setTaxValidation,
    setFundSourceValidation: liquidationActions.setFundSourceValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingOption);
