import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Row, Col } from 'react-bootstrap';


const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #DCDCDC;
    opacity: 1;
    border-radius: inherit;
    min-height: 102px;
    max-height: 128px;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display:flex;
    align-items:center;
    border-left:6px solid rgba(72, 109, 144, 1);
`;

const StyledPara = styled.div`
    margin-bottom: 0;
    text-align: left;
    font-family: roboto-slab,benton-sans,sans-serif;
    font-weight: 700;
    font-size: 29px;
    line-height: 26px;
    letter-spacing: 0;
    color: #070707;
    opacity: 1;
    padding-left:15px;
    padding-right:15px;
    display:flex;
`;

const StyledSpan = styled.span`
    text-align: left;
    font-family: benton-sans,sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    display: flex;
    align-items:center;
`

// const StyledHeaderSpan = styled.span`
//     text-align: left;
//     font-family: benton-sans,sans-serif;
//     font-weight: 700;
//     font-size: 18px;
//     line-height: 22px;
//     letter-spacing: 0;
//     color: rgba(73, 73, 74, 1);
//     opacity: 1;
//     margin-bottom:5px;
//     display: flex;
//     align-items:center;
// `

const StyledCrossAfter = styled.div`
        width: 16px;
        transform: rotate(45deg);
        border: 1.5px solid #49494A;
        position: absolute;
        left: 15px;
        top: 22px;
        border-radius: 3px;
        background-color: #49494A;
`
const StyledCrossBefore = styled.div`
        width: 16px;
        height: 0;
        transform: rotate(-45deg);
        border: 1.5px solid #49494A;
        position: absolute;
        left: 15px;
        top: 22px;
        border-radius: 2px;
        background-color: #49494A;
`
const StyledCloseButton = styled.button`
        background-color: transparent;
        display: flex;
        align-items:center;
        width: 50px;
        height: 50px;
        border: 1.5px solid #49494A;
        border-radius: 50%;
        position: relative;
`


const StopCodeAlert = props => {
    const [display, setDisplay] = React.useState(false)
    const { show, apiErrorMsg, onClick } = props;
    React.useEffect(() => {
        setDisplay(show);
    }, [show]);
   // const alertmsg = "Please contact a Member Service Representative at 1-800-235-8396 for assistance with your account as we may be unable to process your transaction”    "
   // const msg="Please contact a Member Service Representative at 1-800-235-8396.  Your account is locked"
    return (
        <div data-test="stopCodeAlert">
            {display && (
                <StyledAlert role="alert" variant="danger" aria-live="polite">
                    <StyledPara className="container">
                        <Row noGutters className="w-100 d-flex align-items-center">
                            <Col md={11} lg={11} xl={11} className="p-0">
                                {/* <Row noGutters><StyledHeaderSpan> {alertmsg} </StyledHeaderSpan></Row> */}
                                <Row noGutters><StyledSpan>{apiErrorMsg}</StyledSpan></Row>
                            </Col>
                            <Col md={1} lg={1} xl={1} className="p-0 d-flex justify-content-end">
                                <StyledCloseButton
                                    type="button"
                                    aria-label="Close Stop Code Error Message"
                                    id="stopCodeErrorMsgClose"
                                    onClick={onClick}
                                >
                                    <div>
                                        <StyledCrossAfter />
                                        <StyledCrossBefore />
                                    </div>
                                </StyledCloseButton>
                            </Col>
                        </Row>
                    </StyledPara>
                </StyledAlert>
            )}
        </div>
    );
};

StopCodeAlert.propTypes = {
    show: PropTypes.bool,
    apiErrorMsg: PropTypes.string,
    onClick: PropTypes.func
};

StopCodeAlert.defaultProps = {
    show: false,
    apiErrorMsg: 'Error',
    onClick: () => { }
};

export default StopCodeAlert;
