const card = {
    cardId: 'childPersonal',
    linkText: 'Edit',
    cardHeading: 'Personal Information - Primary',
    fields: [
        {
            uniqueKey: 'name',
            key: 'Name',
            value: 'Mike Mcconnel',
            type: 'staticfield',
        },
        {
            uniqueKey: 'ssnTin',
            key: 'Social Security Number',
            value: '000-000-1234',
            type: 'staticfield',
        },
        {
            uniqueKey: 'mailAddress',
            key: 'Mailing Address',
            value: '287 Hillcrest Lane',
            type: 'staticfield',
        },
        {
            uniqueKey: 'physicalAddress',
            key: 'Physical Address',
            value: 'Same as Mailing',
            type: 'staticfield',
        },
        {
            uniqueKey: 'phoneNumber',
            key: 'Home Telephone',
            value: '(555) 555-6789',
            type: 'staticfield',
        },
        {
            uniqueKey: 'phoneNumber2',
            key: 'Work Telephone',
            value: '(555) 555-6789  (Optional)',
            type: 'staticfield',
        },
        {
            uniqueKey: 'emailAddress',
            key: 'Primary Email Address',
            value: 'John@gmail.com',
            type: 'staticfield',
        },
        {
            uniqueKey: 'citizenship',
            key: 'Citizenship',
            value: 'On file',
            type: 'staticfield',
        },
        {
            uniqueKey: 'regulatoryQ',
            key: `Are you a senior foreign political figure, a family member or
            associate of a senior foreign political figure?`,
            hintText: 'Why we need this?',
            value: [
                { key: 'Y', value: 'Yes' },
                { key: 'N', value: 'No' },
            ],
            type: 'radioList',
        },
    ],
};
const beneficiaryHeading = 'Beneficiary Information';
const subtext = 'Choose the person whose college education you are saving for.';
const beneficiaryOwner = 'Who will be the beneficiary of this plan?';
const planBeneficiaryText = 'Plan Beneficiary';
const considertext =
    "Consider the investment objectives, risks, charges and expenses of the VCM 529 Education Savings Plan (Plan) carefully before investing. Download a Plan Description and Participation Agreement (PDF) containing this and other information about the Plan from VCM Investment Management Company, Underwriter and Distributor, Read it carefully before investing. If you or the beneficiary are not residents of the state of Nevada, consider before investing whether your or the designated beneficiary's home state offers any state tax or other state benefits such as financial aid, scholarship funds, and protection from creditors that are only available for investments in such state's qualified tuition program Please consult your tax advisor.";
const beneficiaryOwneritems = [
    {
        key: 'client',
        value: 'Client',
    },
    {
        key: 'other',
        value: 'Other',
    },
];
const addBeneficiaryLink = '+ Add a Beneficiary';
const successorHeading = 'Successor Account Owner Information';
const successorQuestion =
    'Do you want to name someone who can become the owner or successor of the account if you become unable to continue?';
const successorQuestionValue = [
    {
        key: 'yes',
        value: 'Yes',
    },
    {
        key: 'no',
        value: 'No',
    },
];
const firstName = 'First Name';
const middleName = 'Middle Initial';
const lastName = 'Last Name';
const dob = 'Date of Birth';
const relation = 'Relationship to Account Owner';
const pages = [
    'Account Type',
    'ESA (Electronic Services Agreement)',
    'Application - Part 1 (Personal & Beneficiary Info)',
    'Personal Information',
    'Beneficiary Information',
    'Application - Part 2 (Choose your Portfolio)',
];
const redirect = {
    pathname: '/application',
};
const stepperStyle = {
    width: '1107px',
    height: '60px',
    paddingRight: '90px',
    paddingLeft: '150px',
};
export default {
    stepperStyle,
    pages,
    redirect,
    firstName,
    middleName,
    lastName,
    relation,
    dob,
    successorQuestion,
    successorQuestionValue,
    addBeneficiaryLink,
    successorHeading,
    card,
    beneficiaryOwneritems,
    considertext,
    beneficiaryHeading,
    planBeneficiaryText,
    subtext,
    beneficiaryOwner,
};
