import checkValidity from '../../utils/checkValidity';
import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Rules from '../../modules/AccountManagementModule/PersonalInfo/Rules';
import styled from 'styled-components';
import styles from '../CommonStyles';
import HintText from 'modules/CustomerManagementModule/components/HintText';
import { getDataFromORCondition } from 'utils/utils';
import { checkSingleEqualityAndReturnValue, getDataFromThreeOrCondition, getValidValueOf } from 'commonHelper/CommonHelperFunctions';

const assignObj = obj => {
    return obj;
};

const S = {};
const firstSSN = 'First three digits';
const secondSSN = 'Next two digits';
const thirdSSN = 'Last four digits';

S.Row = styled(Row)`
    flex-wrap: unset;
    .dashBoxes {
        align-items: center;
        justify-content: center;
        max-width: 30px;
        min-width: 5px;
        display: flex;
        height: 50px;
    }
    .ssn1Box {
        // max-width: 93px;
        max-width: 30%;
    }
    .ssn2Box {
        // min-width: 56px;
        max-width: 22%;
    }
    .ssn3Box {
        // min-width: 100px;
        max-width: 31%;
    }
`;

S.SsnInput = styled(Form.Control)`
    :read-only {
        background-color: #ffffff !important;
    }
    background-image: none !important;
`;

const AriaRequired = 'aria-required';
const errorBorderColor = 'rgba(230, 0, 0, 1)';

class SSNComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssnOne: '',
            maskedssnOne: '',
            ssnTwo: '',
            maskedssnTwo: '',
            ssnThree: '',
            maskedssnThree: '',
            fullSSN: '',
            errorOneText: props.errorOneText,
            errorTwoText: props.errorTwoText,
            errorThreeText: props.errorThreeText,
            readOnlySsnOne: true,
            readOnlySsnTwo: true,
            typeSsnOne: 'password',
            typeSsnTwo: 'password',
        };
        this.ssnOneRef = React.createRef();
        this.ssnTwoRef = React.createRef();
        this.ssnThreeRef = React.createRef();
        this.handleSSNOneChange = this.handleSSNOneChange.bind(this);
        this.handleSSNTwoChange = this.handleSSNTwoChange.bind(this);
        this.handleSSNThreeChange = this.handleSSNThreeChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedstate, isssnerror, errorOneText, errorTwoText, errorThreeText } = nextProps;
        // console.log('saved states',savedstate)
        if (!prevState.retrievedState && savedstate) {
            return {
                ...savedstate,
                retrievedState: true,
            };
        }

        if (isssnerror && !prevState.retrievedErrorState) {
            return {
                isSSNOneError: true,
                isSSNTwoError: true,
                isSSNThreeError: true,
                errorOneText,
                errorTwoText,
                errorThreeText,
                retrievedErrorState: true,
            };
        }

        return {};
    }

    componentDidMount() {
        const { optional, index, type, isJoint, showSsn= false } = this.props;
        const jointText = isJoint ? 'jointOwner' : type;
        const ssnOneId = `${jointText}ssnOne${index}`;
        const ssnTwoId = `${jointText}ssnTwo${index}`;
        const ssnThreeId = `${jointText}ssnThree${index}`;
        if (optional === 'false') {
            document.getElementById(ssnOneId).setAttribute(AriaRequired, 'true');
            document.getElementById(ssnTwoId).setAttribute(AriaRequired, 'true');
            document.getElementById(ssnThreeId).setAttribute(AriaRequired, 'true');
        }

        if(showSsn){
            this.setState({ typeSsnOne: 'text', typeSsnTwo: 'text'})
        }
    }

    updateSsnFromBeneRelationApi(){
        this.setState({
            ssnOne: '11',
            ssnTwo: '111',
            ssnThree: '1111',
        })
    }
    componentDidUpdate(){
        // console.log('hi')
    }
    handleSSNOneChange = e => {
        const { value, name } = e.target;
        //  const inputType = value === '' ? 'number' : 'password';
        const isReadOnly = value === '';
        const requiredDigitalValue = value.replace(/\D/g, '');
        const maskedSSNOne = this.maskValue(requiredDigitalValue);
        this.setState({
            [name]: requiredDigitalValue,
            maskedssnOne: maskedSSNOne,
            readOnlySsnOne: isReadOnly,
            //  typeSsnOne: inputType,
        });
        if (requiredDigitalValue.length === 3) {
            this.ssnTwoRef.current.focus();
        }
    };

   // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
    onSaveResetStateValues(){
        this.setState({         
            isSSNOneError: false,
            isSSNTwoError: false,
            isSSNThreeError: false });
    }

    handleSSNTwoChange = e => {
        const { value, name } = e.target;
        const isReadOnly = value === '';
        const requiredDigitalValue = value.replace(/\D/g, '');
        this.setState({
            [name]: requiredDigitalValue,
            maskedssnTwo: this.maskValue(requiredDigitalValue),
            readOnlySsnTwo: isReadOnly,
            //   typeSsnTwo: inputType,
        });
        if (requiredDigitalValue.length === 2) {
            this.ssnThreeRef.current.focus();
        }
    };

    handleSSNThreeChange = e => {
        const { value, name } = e.target;
        const requiredDigitalValue = value.replace(/\D/g, '');
        this.setState({
            [name]: requiredDigitalValue,
            maskedssnThree: e.target.value,
        });
    };

    handleBlur = e => {
        const { value, name } = e.target;
        const type = name === 'ssnOne' ? this.state.typeSsnOne : this.state.typeSsnTwo;
        const readOnly = name === 'ssnOne' ? 'readOnlySsnOne' : 'readOnlySsnTwo';
        const { sendfullssn, optional } = this.props;
        const { ssnOne, ssnTwo, ssnThree } = this.state;
        const eventId = e.target.name;
        const checkValidity1 =
            optional && optional === 'false' ? true : ssnOne !== '' || ssnTwo !== '' || ssnThree !== '';

        if (!value) {
            this.setState({
                [type]: 'number',
                [readOnly]: true,
            });
        } else {
            this.setState({ [readOnly]: true });
        }

        const handleBlurEvent =  (this.props?.handleValidationsOnBlur == undefined || this.props?.handleValidationsOnBlur == null) ? true : this.props?.handleValidationsOnBlur;
        if (checkValidity1 && handleBlurEvent) {
            let err = '';
            err = Rules[eventId.toString()]
                ? checkValidity({
                      rules: Rules[eventId.toString()],
                      value: e.target.value,
                      label: 'SSN',
                  })
                : '';
            if (err !== null && err !== '') {
                switch (eventId) {
                    case 'ssnOne':
                        this.setState({ isSSNOneError: true, errorOneText: err });
                        break;
                    case 'ssnTwo':
                        this.setState({ isSSNTwoError: true, errorTwoText: err });
                        break;
                    case 'ssnThree':
                        this.setState({ isSSNThreeError: true, errorThreeText: err });
                        break;
                    default:
                        break;
                }
            } else {
                switch (eventId) {
                    case 'ssnOne':
                        this.setState({ isSSNOneError: false, errorOneText: '' });
                        break;
                    case 'ssnTwo':
                        this.setState({ isSSNTwoError: false, errorTwoText: '' });
                        break;
                    case 'ssnThree':
                        this.setState({ isSSNThreeError: false, errorThreeText: '' });
                        break;
                    default:
                        break;
                }
            }
        } else {
            this.setState({ isSSNThreeError: false, isSSNOneError: false, isSSNTwoError: false });
        }

        const fullSSN = ssnOne + ssnTwo + ssnThree;
        sendfullssn(fullSSN);
    };

    handleSelect = e => {
        const { name } = e.target;
        const readOnly = name === 'ssnOne' ? 'readOnlySsnOne' : 'readOnlySsnTwo';
        this.setState({ [readOnly]: false });
    };

    maskValue = v => {
        let r = v.replace(/-/g, '');
        // r = r.replace(/^0/, '');
        r = r.replace(new RegExp('[0-9]', 'g'), 'X');
        return r;
    };

    getSSN = () => {
        const { ssnOne, ssnThree, ssnTwo, maskedssnOne, maskedssnTwo, maskedssnThree } = this.state;
        const fullSSN = ssnOne + ssnTwo + ssnThree;
        const maskedFullSSN = `${maskedssnOne}-${maskedssnTwo}-${maskedssnThree}`;
        return { ...this.state, fullSSN, maskedFullSSN };
    };

    verifyHandler = (verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) =>
    {
        return (verifyContactHider || verifyPhoneHider || verifyEmailHider || verifyJointContactHider || verifyJointPhoneHider || verifyJointEmailHider)
    }

    render() {
        const {
            ssnOne,
            ssnTwo,
            ssnThree,
            isSSNOneError,
            isSSNTwoError,
            isSSNThreeError,
            errorOneText,
            errorTwoText,
            errorThreeText,
            readOnlySsnOne,
            readOnlySsnTwo,
            typeSsnOne,
            typeSsnTwo,
        } = this.state;
        const { label } = this.props;
        const optionalText = '[Optional]';
        const dash = '-';
        const {
            labelsm,
            labelmd,
            labelstyle,
            valuesm,
            valuemd,
            inputstyle,
            optional,
            sublabel,
            isJoint,
            type,
            astype,
            ssnLabel,
            index,
            otherLabelInfo,
            disabledFirstSnn,
            disabledTwoSnn,
            disabledThirdSnn,
            taxId,
            showSsn = false
        } = this.props;

        const showSsnFontStyles = showSsn ? { fontWeight: 700 } : {};

        const errorOneStyleBorder = (getDataFromORCondition(isSSNOneError , this.props?.isBrokerageBenSsnError) ) ? { borderColor: errorBorderColor } : {};
        const errorTwoStyleBorder = ( getDataFromORCondition(isSSNTwoError , this.props?.isBrokerageBenSsnError )) ? { borderColor: errorBorderColor } : {};
        const errorThreeStyleBorder = (getDataFromORCondition(isSSNThreeError , this.props?.isBrokerageBenSsnError) ) ? { borderColor: errorBorderColor } : {};
        const jointText = getValidValueOf(isJoint , 'jointOwner' , type);
        const ssnError = errorOneText || errorTwoText || errorThreeText;
        let readOnlySsnOneValue = checkSingleEqualityAndReturnValue(optional,'false',false,readOnlySsnOne);
        let readOnlySsnTwoValue = checkSingleEqualityAndReturnValue(optional,'false',false,readOnlySsnTwo);
        const {verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider}=this.props
        if (otherLabelInfo) {
            readOnlySsnOneValue = false;
            readOnlySsnTwoValue = false;
        }

        return (
            <div className="container-fluid" style={this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:styles.paddingZeroStyle}>
                <Form.Group
                    as={checkSingleEqualityAndReturnValue(astype,'Col',Col,Row)}
                    noGutters
                    style={assignObj({ marginBottom: 25, alignItems: 'center', ...inputstyle })}
                >
                    <Form.Label
                        column
                        sm={labelsm}
                        md={labelmd}
                        htmlFor={assignObj(this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null :`${jointText}SSN${index}`)}
                        style={assignObj(this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:{ ...styles.inputLabelText, ...labelstyle })}
                        className="ssnLabel formLabel"
                        id={assignObj(`${jointText}SSN_label${index}`)}
                    >
                        <span className="sr-only">
                            {otherLabelInfo}
                        </span>
                        {ssnLabel || label}
                        {optional === 'true' ? (
                            <span style={assignObj({ ...styles.subLabel, ...sublabel })}>{optionalText}</span>
                        ) : null}
                    </Form.Label>
                    <Col sm={valuesm} md={valuemd} style={this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:styles.ssnHorizontalStyle} id={assignObj(`${jointText}SSN${index}`)}>
                        <S.Row
                            role="group"
                            noGutters
                            style={assignObj({ width: '100%' })}
                            aria-labelledby={assignObj(`${jointText}SSN_label${index}`)}
                        >
                            <Col style={styles.zeroPadding} md={3} lg={3} xl={3} className="ssn1Box">
                               {this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null :  <span className="sr-only">
                                    {firstSSN}
                                </span>}
                                <S.SsnInput
                                    name="ssnOne"
                                    ref={this.ssnOneRef}
                                    id={assignObj(`${jointText}ssnOne${index}`)}
                                    as="input"
                                    type={typeSsnOne}
                                    onChange={this.handleSSNOneChange}
                                    onSelect={this.handleSelect}
                                    value={ssnOne}                        
                                    style={assignObj(this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:{ ...errorOneStyleBorder, ...styles.inputfieldstyle,...showSsnFontStyles })}
                                    maxLength={3}
                                    aria-describedby={`${jointText}ErrSSN${index}`}
                                    onBlur={this.handleBlur}
                                    aria-labelledby={`${jointText}SSN_label${index} other-info first-ssn`}
                                    disabled={disabledFirstSnn}
                                    aria-invalid={isSSNOneError}
                                />
                            </Col>
                            <Col className="dashBoxes" aria-hidden>
                             {this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null: dash }
                            </Col>
                            <Col md={2} lg={2} xl={2} className="ssn2Box ssn2BoxSize">
                              {this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null:  <span className="sr-only">
                                    {secondSSN}
                                </span>}
                                <S.SsnInput
                                    name="ssnTwo"
                                    ref={this.ssnTwoRef}
                                    id={assignObj(`${jointText}ssnTwo${index}`)}
                                    as="input"
                                    type={typeSsnTwo}
                                    onChange={this.handleSSNTwoChange}
                                    onSelect={this.handleSelect}
                                    value={ssnTwo}
                                    style={assignObj(this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:{
                                        ...errorTwoStyleBorder,
                                        ...styles.inputfieldstyle,
                                        ...showSsnFontStyles
                                    })}
                                    maxLength={2}
                                    aria-describedby={`${jointText}ErrSSN${index}`}
                                    onBlur={this.handleBlur}
                                    aria-labelledby={`${jointText}SSN_label${index} second-ssn`}
                                    disabled={disabledTwoSnn}
                                    aria-invalid={isSSNTwoError}
                                />
                            </Col>
                            <Col className="dashBoxes" aria-hidden style={{padding: '0 10px'}}>
                              {this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null: dash }
                            </Col>
                            <Col md={4} lg={4} xl={4} className="ssn3Box">
                                {this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? null:<span className="sr-only">
                                    {thirdSSN}
                                </span>}
                                <Form.Control
                                    name="ssnThree"
                                    ref={this.ssnThreeRef}
                                    id={assignObj(`${jointText}ssnThree${index}`)}
                                    as="input"
                                    type="text"
                                    onChange={this.handleSSNThreeChange}
                                    value={ssnThree}
                                    onBlur={this.handleBlur}
                                    style={assignObj(this.verifyHandler(verifyContactHider,verifyPhoneHider,verifyEmailHider,verifyJointContactHider,verifyJointPhoneHider,verifyJointEmailHider) ? {display:'none'}:{
                                        ...errorThreeStyleBorder,
                                        ...styles.inputfieldstyle,
                                    })}
                                    aria-describedby={`${jointText}ErrSSN${index}`}
                                    maxLength={4}
                                    aria-labelledby={`${jointText}SSN_label${index} third-ssn`}
                                    disabled={disabledThirdSnn}
                                    aria-invalid={isSSNThreeError}
                                />
                               
                            </Col>
                        
                            {! getDataFromThreeOrCondition(isSSNOneError , isSSNTwoError , isSSNThreeError)  &&            
                                <HintText style={styles.errorSSNStyle} id='existing-taxid' text={taxId} srText={taxId} />                        
                            }

                            {getDataFromThreeOrCondition(isSSNOneError , isSSNTwoError , isSSNThreeError) && (
                                <Form.Text
                                    style={styles.errorSSNStyle}
                                    aria-live="assertive"
                                    aria-atomic="true"
                                    id={`${jointText}ErrSSN${index}`}
                                    aria-labelledby={assignObj(`${ssnError}SSN_label`)}
                                    aria-describedby={ssnError}
                                    className="analytics-error-field"
                                >
                                    <small className="sr-only">Error:</small>
                                    {ssnError}
                                </Form.Text>
                            )}
    {this.props?.isBrokerageBenSsnError && ! getDataFromThreeOrCondition(isSSNOneError , isSSNTwoError , isSSNThreeError) && (
                                <Form.Text
                                    style={styles.errorSSNStyle}
                                    aria-live="assertive"
                                    aria-atomic="true"
                                    id={`${jointText}ErrSSN${index}`}
                                    aria-labelledby={assignObj(`${ssnError}SSN_label`)}
                                    aria-describedby={ssnError}
                                    className="analytics-error-field"
                                >
                                    <small className="sr-only">Error:</small>
                                    Please enter a valid social.
                                </Form.Text>
                            )}
                        </S.Row>
                    </Col>
                </Form.Group>
            </div>
        );
    }
}

SSNComponent.propTypes = {
    type: PropTypes.string,
    labelstyle: PropTypes.instanceOf(Object),
    inputstyle: PropTypes.instanceOf(Object),
    valuesm: PropTypes.number,
    valuemd: PropTypes.number,
    labelsm: PropTypes.number,
    labelmd: PropTypes.number,
    index: PropTypes.string,
    sublabel: PropTypes.instanceOf(Object),
    savedstate: PropTypes.instanceOf(Object),
    isssnerror: PropTypes.bool,
    sendfullssn: PropTypes.func,
    optional: PropTypes.string,
    isJoint: PropTypes.bool,
    astype: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    ssnLabel: PropTypes.string,
    errorOneText: PropTypes.string,
    errorTwoText: PropTypes.string,
    errorThreeText: PropTypes.string,
    otherLabelInfo: PropTypes.string,
};

SSNComponent.defaultProps = {
    labelsm: 4,
    labelmd: 4,
    valuesm: 5,
    valuemd: 5,
    labelstyle: {},
    inputstyle: {},
    sublabel: {},
    savedstate: {},
    isssnerror: false,
    sendfullssn: () => {},
    optional: 'false',
    isJoint: false,
    type: 'primaryOwner',
    astype: '',
    label: 'Social Security Number',
    disabled: false,
    ssnLabel: 'Social Security Number',
    errorOneText: 'Please enter first 3 digits of SSN',
    errorTwoText: 'Please enter 4,5 digit of SSN',
    errorThreeText: 'Please enter last 4 digits of SSN',
    index: '',
    otherLabelInfo: '',
};

export default SSNComponent;
