/* eslint-disable no-debugger */
import Amplify, { Auth } from 'aws-amplify';
import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import WSpinner from '../WSpinner/WSpinner';
import { getToken } from 'utils';
import CryptoJS from 'crypto-js';
import { SessionStorageHelper } from 'utils/sessionHelper';
import { getSessionValues, setMSRInfoValue } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
class MSRSSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinner: true,
        };
    }

    componentDidMount() {

        console.log('Hello World 2')
        sessionStorage.setItem("isRefresh",true)
        document.title = 'Victory Capital Management';
        localStorage.clear();
        const {
            location: { search },
            history,
            genericidLookup
        } = this.props;
        
        const queryParams = queryString.parse(search);

        const {vcmid = '', msrid = '', applicationid='', landingpage='', fismemberid='', usaaid='', query='',xQuery='' } = queryParams

        const encySalt = envConfig.ADOBE_ANALYTICS_ENCY_SALT;
        
        // const msrPayload = {
        //     accesstoken: query,
        //     fismemberid: fismemberid ? CryptoJS.AES.encrypt(fismemberid, encySalt).toString() : '' ,
        //     msrid: msrid ? CryptoJS.AES.encrypt(msrid, encySalt).toString() : '',
        //     usaaid:usaaid ? CryptoJS.AES.encrypt(usaaid, encySalt).toString() : '',
        //     vcmid: vcmid ? CryptoJS.AES.encrypt(vcmid, encySalt).toString() : '',
        //     applicationId:applicationid,
        //     landingpage: landingpage
        // }

        const msrPayload = {
            accesstoken: query,
            fismemberid: fismemberid ? fismemberid : '' ,
            msrid: msrid ? msrid : '',
            usaaid:usaaid ? usaaid : '',
            vcmid: vcmid ? vcmid : '',
            applicationId:applicationid,
            landingpage: landingpage
        }


        localStorage.setItem('token',xQuery);
        setMSRInfoValue(msrPayload);
        // const encLoad = CryptoJS.AES.encrypt(JSON.stringify(msrPayload), encySalt).toString();

        // SessionStorageHelper.setMSRInfoData('msr_dts',encLoad)
        //sessionStorage.setItem('msr_dts', encLoad);
        //sessionStorage.setItem('msr_dts_new', encLoad);

        const payload = {
                requestPayload :{
                "id": vcmid,
                "idType": "VCM"
            }
            ,
            headers: {
                accessToken: query
            }
        }
        genericidLookup(payload)
        this.setState({})
    
    }

    updateRedirectUrlToHistory = (storageData) =>
    {
        const msrData =  storageData;
        const isDocumentCenterText = msrData?.landingpage?.toLowerCase();
        const {history} = this.props;
        if(msrData?.applicationId)
        {
            console.log('5')

            this.props.history.push({
                pathname:'/redirectToBrokerageNAO',
                state:{
                    applicationId: msrData.applicationId
                }
            });
        }  
        else if(msrData?.vcmid == "" || isDocumentCenterText == "documentcenter")
        {
            this.props.history.push('/documentCenter');
        }     
        else
        {
            this.props.history.push('/accountholder');
        } 

    }


    async componentDidUpdate(prevState) {

        if(this.props !== prevState){
            if(this.props.loginData?.genericidLookupData !=null){

                let storageData = getSessionValues();
                storageData['fismemberid'] = this.props.loginData?.genericidLookupData?.result?.fisid
                storageData['usaaid'] =  this.props.loginData?.genericidLookupData?.result?.usaaid;
                
                setMSRInfoValue(storageData)


                /// 
                this.updateRedirectUrlToHistory(storageData);
            }
        }
    }


    render() {
        const {spinner} = this.state;
        return (
            <div className="container">              
                <WSpinner loading={spinner}/>   
            </div>
        );
    }
}

MSRSSO.defaultProps = {
    history: {},
    location: {},
};

MSRSSO.propTypes = {
    history: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
};

export default MSRSSO;