import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Container } from "react-bootstrap";
import resendIcon from 'assets/icon-resend.svg';
import {
    WBreadCrumb,
    WSpinner,
    StyledButton,
    ConformationModal
} from "common";
import routeConstants from "routeConstants";
import CONST from "modules/CustomerManagementModule/EmailInformation/constants";
import { BottomNavBar, PageTitle, FieldValue, InputText, InstructionalPanel } from 'modules/CustomerManagementModule/components';
import {
    isValidEmail,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const hideFlag = true;

const StyledResend = styled.span`
    margin-top: 10px;
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #004A98;
    text-transform: capitalize;
`;

const ActionImg = styled.img`
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
`;

const StyledDiv = styled(StyledButton)`
    position: absolute;
    right: 14px;
`;

const primaryBtnProps = {};
const cancelBtnProps = {};

export default class AddEmailInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            enableOtp: false,
            enableConfirmation: false
        };
    }

    componentDidMount() {
        const { isEdit, location } = this.props;
        const { state } = location || {};
        const { email } = state || {};
        if (isEdit) {
            const stateObj = {
                fields: {
                    emailId: email,
                },
            };
            this.setState(stateObj);
        }
        addFormAbandonUnloadListner(isEdit);
    }

    componentDidUpdate(){
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, analyzeClickEvent, linkResponseError, isEdit } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        if(isError){
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_manageemailinfo_${isEdit?'edit':'add'}emeilinfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_manageemailinfo_${isEdit?'edit':'add'}emeilinfo_save`, analyzeClickEvent);
            history.push(routeConstants.emailInfo);
        }
    }

    componentWillUnmount(){
        const { profileInformationData, isEdit } = this.props;
        const { isSuccess } = profileInformationData;
        if(!isSuccess) {
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    updateOrAddEmailInfo = (e) => {
        e.preventDefault();
        const { fields } = this.state;
        const { verifyOTP='', emailId='' } = fields;
        const {
            profileInformationData,
            isEdit,
            addProfileEmailInformations,
            updateProfileEmailInformations,
            location,
            otpConfirmProfileEmailInformations,
        } = this.props;
        const { state } = location || {};
        const { isPrimary=false } = state || {};
        const { profileInformation } = profileInformationData;
        const { emailInformation } = profileInformation || {};
        const { primaryEmail='' } = emailInformation || {};
        let payLoad;
        let otpConfirmPayLoad;
        const savePrimary = isEdit
            ? isPrimary : !primaryEmail;
        if (savePrimary) {
            payLoad = {
                primaryEmail: emailId,
            };
            otpConfirmPayLoad = {
                primaryEmail: emailId,
                verifyOTP,
            }
        } else {
            payLoad = {
                secondaryEmail: emailId,
            };
        }
        if (isEdit) {
            if(isPrimary) { otpConfirmProfileEmailInformations(otpConfirmPayLoad); } else { updateProfileEmailInformations(payLoad); }
        } else {
            addProfileEmailInformations(payLoad);
        }
    };

    handleChange = (e) => {
        const { fields } = this.state;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
            errors: {},
        });
    };

    validateEmail = () => {
        const { fields, errors } = this.state;
        const { emailId='' } = fields;
        errors.emailId = '';
        if(!emailId) {
            errors.emailId = CONST.EMAIL_ERROR_EMPTY;
        }else if(!isValidEmail(emailId)) {
            errors.emailId = CONST.EMAIL_ERROR_INVALID;
        }
        this.setState({
            errors,
        });
        if(errors.emailId.length > 0){
            const { analyzeFormFieldError } = this.props;
            analyzeFormFieldError()
        }
    };
    
    handleOTPBlur = () => {
        const { fields, errors } = this.state;
        const { verifyOTP='' } = fields;
        errors.verifyOTP = '';
        if(!verifyOTP){
            errors.verifyOTP = CONST.OTP_ERROR_EMPTY;
        }
        
        this.setState({
            errors
        });

        if(errors.verifyOTP.length > 0){
            const { analyzeFormFieldError } = this.props;
            analyzeFormFieldError()
        }
    }
    
    validateOtp = () => {
        const { fields } = this.state;
        const { verifyProfileEmailInformations } = this.props;
        const { emailId='' } = fields;
        this.setState({ enableOtp: true });
        const payLoad = {
                primaryEmail: emailId,
            };
        verifyProfileEmailInformations(payLoad);
    }
    
    cancelEmailInfo = () => {
        const {  history, analyzeClickEvent, isEdit } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_${isEdit?'edit':'add'}emailinfo_cancel`
        })
        history.push(routeConstants.emailInfo);
    }
    
    onConformationUpdate = () => {
        this.closeDialog();
        const { history } =  this.props;
        history.push(routeConstants.emailInfo);
    };
    
    closeDialog = () => {
        this.setState({ enableConfirmation: false });
    };
    
    isAnythingChanged = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { email='' } = state || {};
        const { fields, errors } = this.state;
        const { emailId='' } = fields;
        const { emailId: emailErr, verifyOTP: otpErr } = errors;
        const isEmailChanged = Object.prototype.hasOwnProperty.call(fields, "emailId") && emailId !== email;
        return !emailErr && !otpErr && isEmailChanged;
    }

    render() {
        const {
            fields,
            errors,
            enableOtp,
            enableConfirmation
        } = this.state;
        const { emailId:emailError = '', verifyOTP: otpError = '' } = errors;
        const { emailId:emailValue = '', verifyOTP='' } = fields;
        const { profileInformationData, isEdit } = this.props;
        const loading = profileInformationData.isLoading;
        const { location } = this.props;
        const { state } = location || {};
        const { isPrimary } = state || {};
        const emailId = "emailId";
        let primaryOnClick = this.updateOrAddEmailInfo;
        let primaryText = CONST.SAVE;
        if(isPrimary && !enableOtp){
            primaryOnClick = this.validateOtp;
            primaryText = CONST.VERIFY_EMAIL;
        }

        primaryBtnProps.onClick = primaryOnClick;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelEmailInfo;

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={isEdit? CONST.BREADCRUM_EDIT_EMAIL : CONST.BREADCRUM_ADD_EMAIL}
                            activeCrumb={isEdit? CONST.PAGE_NAME_EDIT_EMAIL :CONST.PAGE_NAME_ADD_EMAIL}
                        />
                        {enableConfirmation && (
                            <ConformationModal
                                modalTitle={CONST.CANCEL_CONFIRMATION_MODAL_TITLE}
                                modalBodyText={CONST.CANCEL_CONFIRMATION_MODAL_TEXT}
                                primaryButtonText={CONST.CANCEL_CONFIRMATION_MODAL_PRIMARY_BTN}
                                secondaryButtonText={CONST.CANCEL_CONFIRMATION_MODAL_SECONDARY_BTN}
                                onPrimaryClick={this.onConformationUpdate}
                                onSecondaryClick={this.closeDialog}
                            />
                        )}
                        <PageTitle
                            title={isEdit? CONST.PAGE_NAME_EDIT_EMAIL :CONST.PAGE_NAME_ADD_EMAIL}
                            showMandatoryTxt
                        />
                        <FieldValue
                            htmlFor={emailId}
                            label={CONST.EMAIL_ID}
                            value={
                                <InputText
                                    id={emailId}
                                    name={emailId}
                                    value={emailValue}
                                    onChange={this.handleChange}
                                    onBlur={this.validateEmail}
                                    maxLength={50}
                                    error={!!emailError}
                                    errorTxt={emailError}
                                    aria-label={CONST.AX_EDITEMAILID_TXT}
                                    aria-describedby={`${emailId}-error`}
                                    aria-required
                                    data-name={CONST.EMAIL_ID}
                                    data-form-field
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                        />
                        { isPrimary && enableOtp && 
                            <>
                            <InstructionalPanel mbt={2.67}>{CONST.ENTER_SECURITY_CODE}</InstructionalPanel>
                            <FieldValue
                                htmlFor="vcm-cms-security"
                                label={CONST.SECURITY_CODE}
                                value={
                                    <>
                                        <InputText
                                            id="vcm-cms-security"
                                            name="verifyOTP"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOTPBlur}
                                            value={verifyOTP}
                                            maxLength={8}
                                            error={!!otpError}
                                            errorTxt={otpError}
                                            aria-describedby="vcm-cms-security-error"
                                            aria-required
                                            data-name={CONST.SECURITY_CODE}
                                            data-form-field
                                        />
                                        <StyledDiv aria-label={CONST.ACTION_TEXT_RESEND_SECURITY_CODE} onClick={this.validateOtp}>
                                            <ActionImg src={resendIcon} alt={CONST.RESEND_CODE} />
                                            <StyledResend>{CONST.RESEND_CODE}</StyledResend>
                                        </StyledDiv>
                                    </>
                                }
                                labelsm={3}
                                valuesm={5}
                            />
                            </>
                        }
                        <InstructionalPanel mbt={2.67}>{ isPrimary ? CONST.PRIMARY_EMAIL_NOTE : CONST.SECONDARY_EMAIL_NOTE }</InstructionalPanel>
                    </Container>
                    <BottomNavBar
                        hideBack
                        showCancelBtn
                        primaryBtnText={primaryText}
                        primaryBtnProps={primaryBtnProps}
                        cancelBtnProps={cancelBtnProps}
                        hidePrimaryBtn = {hideFlag}
                    />
            </>
        );
    }
}

AddEmailInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    isEdit: PropTypes.bool,
    profileInformationData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    addProfileEmailInformations: PropTypes.func,
    updateProfileEmailInformations: PropTypes.func,
    otpConfirmProfileEmailInformations: PropTypes.func,
    verifyProfileEmailInformations: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    linkResponseError: PropTypes.func,
};
AddEmailInfoComponent.defaultProps = {
    isEdit: false,
    profileInformationData: {},
    clearExistingActions: () => {},
    addProfileEmailInformations: () => {},
    updateProfileEmailInformations: () => {},
    otpConfirmProfileEmailInformations: () => {},
    verifyProfileEmailInformations: () => {},
    analyzeFormFieldError: () => {},
    analyzeClickEvent: ()=>{},
    linkResponseError: ()=>{},
};
