/**
 * Filter Funds Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to display funds summary for each fund
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Nav, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WIcon, CommonButtons } from '../../../common';
import styles from './styles';
import SummaryComponent from '../Summary/SummaryComponent';
import QuickFactsComponent from '../QuickFacts/QuickFactsComponent';
import PerformanceComponent from '../Performance/PerformanceComponent';
import './FundSummaryComponent.css';
import leftArrowGreen from '../../../assets/leftArrow_green.png';
import consts from './consts';

export default class FundSummaryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: '',
            selectedFundDetails: {},
        };
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { location, fundDetail } = this.props;
        const { state: historyState } = location;
        const { detail } = historyState || {};
        const selectedFundDetails = Object.keys(fundDetail).length ? fundDetail : detail;
        if (location.state) {
            this.setState({ selectedFundDetails });
        }
    };

    handleSelect = eventKey => {
        this.setState({ selectedValue: eventKey });
    };

    backToinvSel = () => {
        const { backTo } = this.props;
        if (backTo) {
            backTo();
        } else {
            this.setState({ goBackToInvestmetSelection: true });
        }
    };

    render() {
        const { selectedFundDetails, selectedValue, goBackToInvestmetSelection } = this.state;
        return (
            <div>
                <div>
                    <div className="container">
                        <button onClick={this.backToinvSel} type="button" style={styles.headerStyle}>
                            <WIcon src={leftArrowGreen} fontawesomestyle={styles.iconImgStyle} />
                            {consts.backTo}
                        </button>

                        <Row noGutters>
                            <h1 style={styles.summaryHeadingStyle}> {selectedFundDetails.fundName}</h1>
                        </Row>
                        <div>
                            <Nav
                                variant="tabs"
                                defaultActiveKey="summary"
                                onSelect={this.handleSelect}
                                style={styles.navStyle}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="summary" href="#quickFacts" className="tabLinkStyle">
                                        {consts.summary}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="quickFacts" href="#performance" className="tabLinkStyle">
                                        {consts.quickFacts}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        href="#summary"
                                        eventKey="performance"
                                        className="tabLinkStyle"
                                        style={styles.navLinkStyle}
                                    >
                                        {consts.performance}{' '}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        {selectedValue === 'summary' && <SummaryComponent selectedFundDetails={selectedFundDetails} />}
                        {selectedValue === 'quickFacts' && (
                            <QuickFactsComponent selectedFundDetails={selectedFundDetails} />
                        )}
                        {selectedValue === 'performance' && (
                            <PerformanceComponent selectedFundDetails={selectedFundDetails} />
                        )}
                        {selectedValue !== 'summary' &&
                            selectedValue !== 'quickFacts' &&
                            selectedValue !== 'performance' && (
                                <SummaryComponent selectedFundDetails={selectedFundDetails} />
                            )}
                    </div>
                    {goBackToInvestmetSelection ? <Redirect push to="/fundSelection" /> : ''}
                    <CommonButtons backClick={this.backToinvSel} nextstyle={styles.nextstyle} />
                </div>
            </div>
        );
    }
}

FundSummaryComponent.propTypes = {
    // history: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
    fundDetail: PropTypes.instanceOf(Object),
    backTo: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
};

FundSummaryComponent.defaultProps = {
    //  history: {},
    location: {},
    fundDetail: {},
    backTo: null,
};
