import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';

export const SecurityAnswer = (props) => {
    const { changed, blurred, securityAnswerState, id, answerStyle, label, title } = props
    const data = {
        config: {
            id,
            label,
            type: 'text'
        },
        change: e => changed(e, id),
        blur: () => blurred(id)
    }

    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const errorId = `${data.config.id}_error`;

    if (!securityAnswerState.valid && securityAnswerState.touched) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        title={title}
        id={data.config.id}
        type={data.config.type}
        className={inputClasses.join(' ')}
        value={securityAnswerState.value}
        onChange={data.change}
        onBlur={data.blur}    
        aria-describedby={errorId}
        aria-invalid={!!securityAnswerState.errMsg}
        aria-labelledby={data.config.label}
        aria-required="true" 
    />;

    return (
        <div className={classes.Input} style={answerStyle}>
            <label className={classes.Label} htmlFor={data.config.id} title={title}>{data.config.label}</label>
            {inputElement}
            {securityAnswerState.errMsg ? <p className={classes.errMsg} aria-live="polite" aria-atomic="true" id={errorId}>{securityAnswerState.errMsg}</p> : null}
        </div>
    );

};

SecurityAnswer.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    answerStyle: PropTypes.instanceOf(Object),
    id: PropTypes.string,
    securityAnswerState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            maxLength: PropTypes.number,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func
}

SecurityAnswer.defaultProps = {
    label: '',
    title: '',
    answerStyle: {},
    id: 'securityAnswer',
    securityAnswerState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            maxLength: 30,
            pattern: '^[a-zA-Z]*$',
            patternMessage: 'Should contain only Alphabets',
            required: true
        }
    },
    changed: () => { },
    blurred: () => { }
}

export default SecurityAnswer;