const app1Card = {
    cardId: 'app1',
    linkText: '',
    cardHeading: 'Application - Part 1 ',
    hint: '(Personal & Beneficiary Info)',
};
const app2Card = {
    cardId: 'app2',
    linkText: '',
    cardHeading: 'Application - Part 2 ',
    hint: '(Choose your Portfolio)',
};
const pages = [
    'Account Type',
    'ESA (Electronic Services Agreement)',
    'Application - Part 1 (Personal & Beneficiary Info)',
    'Personal Information',
    'Beneficiary Information',
    'Application - Part 2 (Choose your Portfolio)',
];
const stepperStyle = {
    width: '1107px',
    height: '60px',
    paddingRight: '90px',
    paddingLeft: '150px',
};
const buttonStyle = { width: '100%' };
const q1 = 'How long will it take?';
const ans1 = 'About 5 to 10 minutes.';
const q2 = 'What you will need:';
const part2ans = 'Bank account information to fund your initial investment.';
const ans2 =
    'Ensure all information under your Personal Profile is current . The Social Security number of the person you are opening an account for. Ensure the Citizenship of the person you are opening an account for, is entered in your VCM Personal Profile.';
const q3 = 'What to expect?';
const ans3 =
    "For Part 2, you'll be sent to secure website built exclusively for VCM members to choose your portfolio options.";
const agreeText =
    'Note: f you are interested in establishing Trust as the account owner, you can apply via email or VCM investment professional at 002928825 Monday-Friday, 7:30.m-8p.m CT.and Sm.-5p.m CT n Saturday';
const considertext =
    "Consider the investment objectives, risks, charges and expenses of the VCM 529 Education Savings Plan (Plan) carefully before investing. Download a Plan Description and Participation Agreement (PDF) containing this and other information about the Plan from VCM Investment Management Company, Underwriter and Distributor, Read it carefully before investing. If you or the beneficiary are not residents of the state of Nevada, consider before investing whether your or the designated beneficiary's home state offers any state tax or other state benefits such as financial aid, scholarship funds, and protection from creditors that are only available for investments in such state's qualified tuition program Please consult your tax advisor.";
export default {
    buttonStyle,
    pages,
    stepperStyle,
    app1Card,
    app2Card,
    ans1,
    q1,
    q2,
    q3,
    ans2,
    ans3,
    agreeText,
    considertext,
    part2ans,
};
