import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import AccordianSymbol from '../AccordionSymbol';

// const AccountTypeTitleStyle = styled.h2`
//     font-size: 22px;
//     color: rgb(0, 74, 152);
//     font-family: 'yorkten-slab-normal';
//     font-weight: 800;
//     padding-left: 0.9375rem;
//     cursor: pointer;
//     line-height: 45px;
//     margin-bottom: 0px;
// `;
const AccountTypeAccordianTitle = props => {
    const { accType, id, dataAnalyticId,accHeaderAs, requireButtonPadding } = props;
    const dispatch = useDispatch();

    const analyzeClick = (e) => {
        dispatch(analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id')
            })
        )
    }

    return (
        <h2  /* aria-level="3" */ role="heading" aria-level={window.innerWidth > 767 ? '1' : '3'} >
        <AccordianSymbol
            aria-controls={id.replace(/\s/g, '')}
            id={id}
            onClick={e => analyzeClick(e)}
            data-analytic-id={dataAnalyticId}
            as={accHeaderAs}
            requireButtonPadding={requireButtonPadding}
            className="accordianStyle"
        >
            {accType}
        </AccordianSymbol>
    </h2>
    );
};

AccountTypeAccordianTitle.propTypes = {
    accType: PropTypes.string.isRequired,
    id: PropTypes.string,
    dataAnalyticId: PropTypes.string,
    accHeaderAs: PropTypes.string,
    className: PropTypes.string,
    requireButtonPadding: PropTypes.bool,
};

AccountTypeAccordianTitle.defaultProps = {
    id: '',
    dataAnalyticId: '',
    accHeaderAs: 'button',
    className: '',
    requireButtonPadding: true,
};

export default AccountTypeAccordianTitle;
