/**
 * Oops Page Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component is to display Error Message during application failure
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import oops from '../../../assets/oops.png';
import { Header, Footer, WButton } from '../../../common';
import './OopsPage.css';
import Consts from './Consts';

export default class OopsPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleButtonClick = () => {};

    render() {
        const { heading, desc, buttontext, buttonStyle } = Consts;
        return (
            <div className="MainContainer">
                <Header />
                <Row className="oops-wrap">
                    <Col className="oops-content">
                        <div className="oops-layout">
                            <p className="oops-heading">{heading}</p>
                            <div className="oops-page">
                                <p className="oops-desc">{desc}</p>
                                <WButton
                                    buttontext="Contact Us"
                                    buttonstyle={buttontext}
                                    buttontextstyle={buttonStyle}
                                    onClick={this.handleButtonClick}
                                    variant="outline"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col className="oops-img">
                        <img src={oops} alt="Oops!" />
                    </Col>
                </Row>
                <Footer />
            </div>
        );
    }
}
