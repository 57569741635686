import styled from 'styled-components';
import { CardDeck } from 'react-bootstrap';

const StyledCardDeck = styled(CardDeck)`
    margin-top: ${props => props['data-mt'] || 0}rem;
    margin-bottom: ${props => props['data-mb'] || 0}rem;
    .card {
        margin-bottom: 0;
    }
    @media (min-width: 576px) {
        .card {
            margin-right: 0;
            margin-left: 0;
        }
    }
`;

export default StyledCardDeck;