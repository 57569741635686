import React from 'react';

const styles = {
  linkStyle: {
    color: '#004A98',
    font: '600 14px/17px benton-sans'
  }
}
const site = 'vcm.com';

const vcm = <a href="https://www.vcm.com" style={styles.linkStyle} className="inlineLink">{site}</a>
const infoText = 'Please confirm your selections. If you would like to make any changes, use the back button to go to the previous page. Once you complete this process, you may make any future changes by accessing your profile at ';
const data = {
    title: 'Confirm Security Answers',
    info: [<span>{infoText}{vcm}</span>],
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Preference', 'Confirmation'],
    stepperStyle: { width: '100%', height: 60, paddingRight: 90, paddingLeft: 150 },
    stepperDivStyle: {width: '100%'},
    welcome: 'Welcome ',
    signedAs: ' to Victory Capital. You have successfully logged as ',
    setup: 'Set up your Security Preferences Now',
    commonButtons: {marginTop: 25}
  }

export default data;