import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import TopLeftCornerColor from '../TopLeftCornerColor';
import PrimaryBtn from '../PrimaryBtn';

export const USPSModal = styled(TopLeftCornerColor)`
    padding: 40px 30px;
    padding-bottom: 30px;
    margin: auto;
    max-width: 540px;
    height: ${props => props.isSmallDevice ? 'auto': '100%'};
    width: ${props => props.isSmallDevice ? 'auto': '350px'};
    position: ${props => props.isSmallDevice ? 'relative': 'absolute'};
    right: ${props => props.isSmallDevice ? 'auto': '0'};
    bottom: ${props => props.isSmallDevice ? 'auto': '0'};
    @media (max-width: 991px) { 
        position: relative;
        right: auto;
        bottom: auto;
        height: auto;
        width: auto;
    }
    @media (min-width: 992px) and (max-width: 1199px) { 
        width: ${props => props.isSmallDevice ? 'auto': '320px'};
    }
    overflow-y: scroll;
`;
const USPSTitle = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const USPSMessage = styled.p`
    margin-top: 1.25rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const USPSAddress = styled.div`
    margin-top: 0.9375rem;
`;

const AddressLine = styled(USPSTitle)`
    font-weight: 600;
    margin: 0px;
`;

const USPSVerifyBtn = styled(PrimaryBtn)`
    margin-top: 1.875rem;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 30px;
    && {
        button {
            width: 100%;
            max-width: 350px;
        }
    }
`;

const USPS_RECOMMENDATION_TEXT = 'USPS Recommendation';
const USPS_MESSAGE = 'We have slightly modified the address entered. Please select USPS Recommended format to ensure accurate delivery';
const USPS_BTN_TEXT = 'Use this address';
const VERIFY_BTN_ARIA_LABEL = "Use USPS Recommended Address";

const USPSDialog = (props) => {
    const { addressLineOne, addressLineTwo, onVerify, isSmallDevice } = props;
    const verifyBtn = useRef(null);

    useEffect(()=>{
        if(verifyBtn.current){
            verifyBtn.current.focus();
        }
    },[addressLineOne, addressLineTwo])
    
    return (
        <USPSModal isSmallDevice={isSmallDevice} >
            <USPSTitle>{USPS_RECOMMENDATION_TEXT}</USPSTitle>
            <USPSMessage>{USPS_MESSAGE}</USPSMessage>
            <USPSAddress>
                <AddressLine>{addressLineOne}</AddressLine>
                <AddressLine>{addressLineTwo}</AddressLine>
            </USPSAddress>
            <FlexDiv>
                <USPSVerifyBtn ref={verifyBtn} aria-label={VERIFY_BTN_ARIA_LABEL} onClick={onVerify} >{USPS_BTN_TEXT}</USPSVerifyBtn>
            </FlexDiv>
        </USPSModal>
        )
}

USPSDialog.propTypes = {
    isSmallDevice: PropTypes.bool,
    addressLineOne: PropTypes.string,
    addressLineTwo: PropTypes.string,
    onVerify: PropTypes.func,
};

USPSDialog.defaultProps = {
    isSmallDevice: false,
    addressLineOne: '',
    addressLineTwo: '',
    onVerify: () => {},
};

export default USPSDialog;