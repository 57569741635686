import {
    accOpeningActions,
    localStateManagementActions,
    addBankAccountAction,
    bankAccountAction,
    transactionCompositeDataAction,
    purchaseActions,
} from '../../../shared/Actions';
import { connect } from 'react-redux';
import InvestmentSelectionComponent from './InvestmentSelectionComponent';
import withAccountManagement from '../AccountManagementHOC';
import { withRouter } from 'react-router';

export const mapStateToProps = (state /* , props */) => ({
    localStateData: state.localStateManagementReducerData,
    accOpeningData: state.accOpeningReducerData,
    initialState: state.loginData,
    masterLookupStateData: state.masterLookUpData,
    addBankAccountData: state.addBankAccountData,
    bankAccountsState: state.bankAccountReducerData,
    transactionReducerData: state.transactionCompositeDataReducerData,
    purchaseReducerData: state.purchaseData,
    tradingWindowData: state.tradingWindowData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...addBankAccountAction,
    ...bankAccountAction,
    getTransactionCompositeData: transactionCompositeDataAction.getTransactionCompositeData,
    getIRAContributionValues: purchaseActions.getIRAContributionValues,
    acceptIRAContributionWarning: purchaseActions.acceptIRAContributionWarning,
    cancelIRAContributionWarning: purchaseActions.cancelIRAContributionWarning,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(InvestmentSelectionComponent)),
);
