import { connect } from 'react-redux';
import { get } from 'lodash';
import Password from './CreatePassword';
import { validatePassword, resetPasswordError } from './CreatePassword.actions';

const mapStateToProps = (state) => {
  const user = get(state, 'AccountRegistration.user', {});
  return {
    userId: get(user, 'userId', ''),
    eSignInitial: get(user, 'eSign.sign', ''),
    eSignDate: get(user, 'eSign.date', ''),
    accountInfo: get(state, 'AccountRegistration.accountInfo', {}),
    email: get(user, 'email', ''),
    apperror: get(state, 'AccountRegistration.pwdError', ''),
    primaryPhone: get(user, 'primaryPhone', ''),
    primaryPhoneCountryCode: get(user, 'primaryPhoneCountryCode', ''),
    isMobilePrimary: get(user, 'isMobilePrimary', false),
    secondaryPhone: get(user, 'secondaryPhone', ''),
    secondaryPhoneCountryCode: get(user, 'secondaryPhoneCountryCode', ''),
    isMobileSecondary: get(user, 'isMobileSecondary', ''),
    pwdValidate: get(state, 'AccountRegistration.pwdValidate', false),
  };
};

const mapDispatchToProps = {
  verifyPwd: validatePassword,
  resetPwdError: resetPasswordError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
