import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const S = {};

S.SpecialtyTitle = styled.h1 `
    display: inline-block;
    border-bottom: 2px solid #8cbf26;
    padding-bottom: 10px;
    font: 800 30px/35px yorkten-slab-normal;
    margin-bottom: 30px;
    color: #486d90;
`;

const SpecialtyAccountHeading = ({ children }) => {
    return ( 
        <Row className="mobileRow" >
            <Col>
                <S.SpecialtyTitle > { children } </S.SpecialtyTitle> 
            </Col>
        </Row >
    );
};

SpecialtyAccountHeading.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SpecialtyAccountHeading;