/**
 * Compare Funds Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to compare different funds upto 4
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import scoreDownIcon from '../../../assets/scoreDownIcon.svg';
import { CommonButtons, WRating, WErrorModal, WIcon } from '../../../common';
import Goback from '../../../assets/leftArrow_green.png';
import './CompareFunds.css';
import Consts from './Consts';

const assignObj = obj => {
    return obj;
};

const PageHeading = styled.h1`
    margin-bottom: 30px;
    margin-top: 40px;
    float: left;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 800 30px/45px yorkten-slab-normal;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
`;

const StyledDate = styled.span`
    text-align: left;
    font: 600 14px/35px benton-sans;
    letter-spacing: 0;
    color: #acacac;
    opacity: 1;
`;

const PdfContent = styled.span`
    display: flex;
    justify-content: center;
`;

export default class CompareFundsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.fundKeys = Consts.fundKeys;
        this.handleback = this.handleback.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { location, selectedFunds } = this.props;
        const { state: historyState } = location;
        const { detail } = historyState || {};
        const selectedFundsList = selectedFunds.length ? selectedFunds : detail;
        if (location.state) {
            const structureResponse = this.formFundsTemplate(selectedFundsList);
            this.setState({ selectedFunds: structureResponse });
        }
    };

    getSubtext = (key, val) => {
        let subText = '';
        const { selectedFunds } = this.state;
        if (selectedFunds) {
            const asOf = `(As of ${selectedFunds[0].navDate})`;
            switch (key) {
                case 'nav':
                    subText = <StyledDate>{asOf}</StyledDate>;
                    break;
                case 'day30':
                    subText = <StyledDate>{asOf}</StyledDate>;
                    break;
                case 'day7':
                    subText = <StyledDate>{asOf}</StyledDate>;
                    break;
                default:
                    subText = <span className="fund-subtext">{val}</span>;
                    break;
            }
            return subText;
        }
        return val;
    };

    getAbbrevation = label => {
        let abbrText = '';
        const { selectedFunds } = this.state;
        if (selectedFunds) {
            switch (label) {
                case 'NAV':
                    abbrText = 'Net Asset Value ';
                    break;
                case 'YTD':
                    abbrText = 'Year To Date';
                    break;
                default:
                    abbrText = '';
                    break;
            }
            return abbrText;
        }
        return abbrText;
    };

    formFundsTemplate = fundData => {
        if (fundData) {
            return fundData.map(funds => {
                if (funds) {
                    const performanceDetails = funds.performanceDetails.totalReturns.monthly;
                    const secFields = funds.SecYields;
                    const expense = funds.expenseRatio;
                    return {
                        fundName: funds.fundName,
                        oneYear: performanceDetails['1year'],
                        fiveYear: performanceDetails['5year'],
                        tenYear: performanceDetails['10year'],
                        performanceYTD: performanceDetails.ytd,
                        inception: performanceDetails.sinceInception,
                        inceptionDate: performanceDetails.inceptionDate,
                        nav: funds.nav.value,
                        day30: secFields.SecYields_30,
                        day7: secFields.SecYields_7,
                        waivers: secFields.SecYields_WoWaivers,
                        expenseRatio: expense.expenseRatio,
                        expenseRatioBefore: expense.expenseRatio_BR,
                        expenseRatioAfter: expense.expenseRatio_AR,
                        expenseYTD: performanceDetails.ytd,
                        rating: funds.morningstarRating.value,
                        ratingCategory: funds.morningstarCategory,
                        numberCategory: funds.categoryFunds,
                        navDate: funds.nav.asOfDate,
                        secDate: performanceDetails.asOfDate,
                    };
                }
                return {};
            });
        }
        return {};
    };

    handleback = () => {
        const { history, backTo } = this.props;
        if (backTo) {
            backTo();
        } else {
            history.goBack();
        }
    };

    renderData = value => {
        const decrease = 'Decreased By';
        if (typeof value === 'string' && value.startsWith('-')) {
            let newValue = value.replace('-', '');
            newValue = `(${newValue})`;
            return (
                <>
                    <PdfContent>
                        <WIcon size="1x" src={scoreDownIcon} arialabel={decrease} fontawesomestyle={Consts.pdfStyle} />
                        {newValue}
                    </PdfContent>
                </>
            );
        }
        if (value) {
            return value;
        }
        return '_';
    };

    renderTableHeaders = funds => {
        return (
            <thead>
                <tr>
                    <th colSpan="1" className="tableheader">
                        {' '}
                    </th>
                    {funds &&
                        funds.map(fund => (
                            <th key={fund.fundName} className="fund-heading">
                                {fund.fundName}
                            </th>
                        ))}
                </tr>
            </thead>
        );
    };

    renderTableBody = ({ sections }, funds) => {
        const { defaultText, none, fundStyle } = Consts;
        return sections.map(section => {
            return (
                <tbody key={section.heading}>
                    <>
                        <tr className="default-row">
                            <td colSpan="1" className="default-keys">
                                {section.heading}
                            </td>
                            {funds &&
                                funds.map(fund => (
                                    <td key={fund.fundName} className="default-key-item" style={fundStyle} />
                                ))}
                        </tr>
                        {section.fields.map((field, idx) => {
                            const borderVal = idx === section.fields.length - 1 ? defaultText : none;
                            const rowkey = field.uniquekey + idx;
                            const abbreviation = this.getAbbrevation(field.label);
                            return (
                                <tr key={rowkey} style={assignObj({ borderBottom: borderVal })}>
                                    <td colSpan="1" className="fund-key-item">
                                        {abbreviation !== '' ? (
                                            <abbr title={abbreviation}>{field.label}</abbr>
                                        ) : (
                                            <span>{field.label}</span>
                                        )}
                                        {field.subtext && <div>{this.getSubtext(field.uniquekey, field.subtext)}</div>}
                                    </td>
                                    {this.renderFunds(funds, field.uniquekey, borderVal, idx)}
                                </tr>
                            );
                        })}
                    </>
                </tbody>
            );
        });
    };

    renderFunds = (funds, uniqueKey, borderVal, idx) => {
        const { odd, even } = Consts;
        return (
            funds &&
            funds.map((fund, index) => {
                const elementKey = fund[uniqueKey.toString()] + index;
                if (uniqueKey !== 'rating') {
                    const cn = `not-rating ${idx % 2 !== 0 ? odd : even}`;
                    return (
                        <td
                            key={elementKey}
                            className={cn}
                            style={assignObj({
                                borderBottom: borderVal,
                                textAlign: 'center',
                                borderTop: 'none',
                            })}
                        >
                            {fund[uniqueKey.toString()] && this.renderData(uniqueKey)}
                        </td>
                    );
                }
                if (uniqueKey === 'rating') {
                    const cn = `rating ${idx % 2 !== 0 ? odd : even}`;
                    return (
                        <td
                            key={elementKey}
                            className={cn}
                            style={assignObj({
                                borderBottom: borderVal,
                                textAlign: 'center',
                                borderTop: 'none',
                            })}
                        >
                            <WRating name={uniqueKey} readOnly max={5} value={Number(fund[uniqueKey.toString()])} />
                        </td>
                    );
                }
                return '';
            })
        );
    };

    renderTable = () => {
        const { selectedFunds } = this.state;
        return (
            <Table>
                <caption style={Consts.tableCaptionStyle}>{Consts.captionTable}</caption>
                {selectedFunds && this.renderTableHeaders(selectedFunds)}
                {selectedFunds && this.renderTableBody(this.fundKeys, selectedFunds)}
            </Table>
        );
    };

    renderPage = () => (
        <div className="container-fluid compare-funds-wrapper">
            <div className="container">
                <div className="fund-container" role="navigation">
                    <WIcon src={Goback} className="backIcon" fontawesomestyle={Consts.faStyle} />
                    <button className="backto" type="button" onClick={this.handleback}>
                        {Consts.backText}
                    </button>
                </div>
                <PageHeading>{Consts.pageHeading}</PageHeading>
                {this.renderTable()}
                <CommonButtons backClick={this.handleback} nextstyle={assignObj({ display: 'none' })} />
            </div>
        </div>
    );

    render() {
        const { error } = this.state;
        return (
            <div>
                {error && <WErrorModal show={error} message={Consts.technicalError} />}
                {!error && this.renderPage()}
            </div>
        );
    }
}

CompareFundsComponent.propTypes = {
    accOpeningData: PropTypes.shape({}),
    history: PropTypes.shape({
        goBack: PropTypes.func,
    }),
    masterLookupStateData: PropTypes.shape({
        isError: PropTypes.bool,
    }),
    location: PropTypes.shape({
        state: PropTypes.shape({
            detail: PropTypes.instanceOf(Array),
        }),
    }),
    selectedFunds: PropTypes.instanceOf(Object),
    backTo: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
};

CompareFundsComponent.defaultProps = {
    accOpeningData: {},
    history: {
        goback: () => {},
    },
    masterLookupStateData: {},
    location: {
        state: {
            detail: [],
        },
    },
    selectedFunds: [],
    backTo: null,
};
