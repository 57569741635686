import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import MessageBox from 'modules/CustomerManagementModule/components/MessageBox';
import CloseIcon from 'assets/Icon_Close_Message.svg';

const FlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledPara = styled.p`
    color: #49494a;
    margin-bottom: 0;
`;

const StyledMessage = styled(StyledPara)`
    font: 600 16px/20px benton-sans;
`;

const StyledInfo = styled(StyledPara)`
    font: 500 16px/20px benton-sans;
    margin-top: 10px;
`;

const CloseButton = styled.button`
    padding: 0;
    border: none;
    background: transparent;
    position: relative;
    width: 3.125rem;
    height: 3.125rem;
    background: url(${CloseIcon}) no-repeat bottom left;
`;
CloseButton.displayName = 'CloseButton';

const StatusMessageBox = styled(MessageBox)`
    position: relative;
    padding: 25px 0;
`;

const ENTER_KEY = 'Enter';
const SPACEBAR_KEY = ' ';

class StatusMessage extends Component {
    constructor(props) {
        super(props);
        this.closeBtnRef = React.createRef();
    }

    componentDidMount() {
        this.closeBtnRef.current.focus();
    }

    componentDidUpdate() {
        this.closeBtnRef.current.focus();
    }

    closeHandler = () => {
        const { onClose, focusfrom } = this.props;
        onClose();
        if (focusfrom) {
            document.getElementById(focusfrom).focus();
        }
    };

    onKeyEvent = e => {
        const { focusfrom } = this.props;
        if(e.key === ENTER_KEY || e.key === SPACEBAR_KEY){
            e.stopPropagation();
            e.preventDefault();
            this.closeHandler();
            if (!focusfrom && document.getElementById('skipToMain')) {
                document.getElementById('skipToMain').focus();
            }
        }
    };

    render() {
        const { text } = this.props;
        const { message, info } = text;
        const snapErrorText = 'Oh snap! You got an error from backend! Please retry after sometime';

        return (
            <StatusMessageBox>
                <Container>
                    <FlexRow>
                        <FlexColumn role="alert" aria-live="polite">
                            <StyledMessage>{message || snapErrorText}</StyledMessage>
                            {!!info && <StyledInfo>{info}</StyledInfo>}
                        </FlexColumn>
                        <FlexRow>
                            <CloseButton
                                ref={this.closeBtnRef}
                                aria-label="Close status message"
                                onClick={this.closeHandler}
                                onKeyDown={this.onKeyEvent}
                            />
                        </FlexRow>
                    </FlexRow>
                </Container>
            </StatusMessageBox>
        );
    }
}

StatusMessage.propTypes = {
    text: PropTypes.shape({
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    onClose: PropTypes.func,
    focusfrom: PropTypes.string, // if alert showed on the same page, the element which called this alert should be passed
};

StatusMessage.defaultProps = {
    text: {
        message: null,
        info: null,
    },
    onClose: () => {},
    focusfrom: '',
};

export default StatusMessage;