import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { analytics } from '../../shared/Actions';
import Constant from './CONST';
import '../commonCompStyles.css';
import envConfig from 'env/EnvConfig';

const StyledP = styled.p`
    font: 500 16px/24px benton-sans;
    color: #49494a;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 1.562rem;
    > a {
        color: rgb(0, 74, 152);
        text-decoration: underline;
    }
`;
const BoldSpan = styled.span`
    letter-spacing: 0;
    text-align: left;
    font: 700 1rem/1.5rem benton-sans;
`;
const assignObj = obj => {
    return obj;
};

let CONSTS = Constant

class DashboardDisclosure extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            loginDataCounter:0
        };
    }

    handleFooterDisclaimerClick = e => {
        const { analyzeDownLoadClickEvent } = this.props;
        analyzeDownLoadClickEvent({
            link: `Footer_Disclaimer_${e.target.text}(Download)`,
        });
    };

    render() {
        const { dasboardDisclosure, additionalDashboardDisclosure } = CONSTS;
        const {
            dasboardPrinciple,
            dashboardPara1,
            dashboardPara4,
            usaaaAbbr,
            usaaFullForm,
            dashboardPara41,
            dashboardPara42,
            dashboardPara43,
            fdicAbbr,
            fdicFullForm,
            bankGuaranteeText,
            loseValueText,
            insuredText,
            notText,
            dashboardNewPara1,
            dashboardNewPara2,
            dashboardPara61,
            dashboardPara62,
            dashboardPara63,
            dashboardPara71,
            vcmLink,
        } = dasboardDisclosure;
        const AdditionalDisclosureRoutes = ['/accountholder'];
        const { path } = this.props;
        return (
            <div>
                <h2 style={assignObj({ font: '700 16px/24px benton-sans', color: '#49494A', marginBottom: 15 })}>
                    {dasboardPrinciple}
                </h2>
                <StyledP>
                    <span>{dashboardPara1}</span>
                </StyledP>
                <StyledP>
                    <BoldSpan>{dashboardNewPara1}</BoldSpan>

                    <a
                        href={assignObj(`${envConfig.ENV_URL}/prospectus`)}
                        target="_self"
                        rel="noopener noreferrer"
                        onClick={this.handleFooterDisclaimerClick}
                        style={assignObj({ display: 'inline-block' })}
                    >
                        {' '}
                        <BoldSpan>{vcmLink}</BoldSpan>
                    </a>

                    <BoldSpan>{dashboardNewPara2}</BoldSpan>
                </StyledP>
                <StyledP>
                    <BoldSpan>
                        {dashboardPara4}

                        {usaaaAbbr}
                        {dashboardPara41}
                        <a
                            href="https://vcm.com/assets/fund-docs/529/529_Plan_Description.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={this.handleFooterDisclaimerClick}
                        >
                            {dashboardPara42}
                            <span className="sr-only" data-test="externalWeb">
                                {CONSTS.externalWeb}
                            </span>
                            <span aria-hidden="true" className="icon-newpage" />
                        </a>
                        {dashboardPara43}
                    </BoldSpan>
                </StyledP>
                <StyledP>
                    <span>{dashboardPara61}</span>
                    <span>
                        <abbr title={usaaFullForm}>{usaaaAbbr}</abbr>
                        {dashboardPara62}
                        <abbr title={usaaFullForm}>{usaaaAbbr}</abbr>
                        {dashboardPara63}
                    </span>
                </StyledP>
                {AdditionalDisclosureRoutes.includes(path) && (
                    <StyledP>
                        <BoldSpan>
                            {additionalDashboardDisclosure.part1}
                            <span>
                                <abbr title={additionalDashboardDisclosure.llcFullForm}>
                                    {additionalDashboardDisclosure.llcAbbr}
                                </abbr>
                            </span>
                            {additionalDashboardDisclosure.part2}
                            <span>
                                <abbr title={fdicFullForm}>{fdicAbbr}</abbr>
                            </span>
                            {additionalDashboardDisclosure.part3}
                        </BoldSpan>
                        <br />
                        <br />
                        <span>{additionalDashboardDisclosure.part4}</span>
                    </StyledP>
                )}

                        <StyledP>
                        <span>{dashboardPara71}</span>
                    </StyledP>

                <ul
                    id="dashboardDisclaimerList"
                    style={assignObj({ ...CONSTS.styles.unorderedList, font: '700 16px/24px benton-sans' })}
                >
                    <li>
                        {notText}
                        <span>
                            <abbr title={fdicFullForm}>{fdicAbbr}</abbr>
                        </span>
                        {insuredText}
                    </li>
                    <li>{loseValueText}</li>
                    <li>{bankGuaranteeText}</li>
                </ul>
              
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginData:state?.loginData
});

export const mapDispatchToProps = {
    ...analytics,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DashboardDisclosure);
