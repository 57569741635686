
/**
 * Phone Details Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide phone numbers
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

 import AccGlobalConstants from '../AccountManagementConstants';
 import checkValidity from '../../../utils/checkValidity';
 import consts from '../PersonalInfo/consts';
 import PropTypes from 'prop-types';
 import React, { Component } from 'react';
 import { Row, Form } from 'react-bootstrap';
 import Rules from '../PersonalInfo/Rules';
 import styles from '../PersonalInfo/styles';
 import { Toggle } from '../../CustomerManagementModule/components';
 
 const ERROR_OFFSCREEN_TEXT = 'Error: ';
 
 class PhoneDetails extends Component {
     constructor(props) {
         super(props);
         this.state = {
             phoneNumber: '',
             mPhoneNumber: '',
             contactTime: '',
             countryCode: '',
             countryCodeTemp: '',
             isMobile: false,
             errPhoneNumber: '',
             errCountryCode: '',
             isKeyDown: false,
             retrievedState: false,
         };
     }
 
     static getDerivedStateFromProps(props, state) {
         let { savedPhoneNumber, savedIsMobile, savedCountryCode } = props;
         const { isNumberChanged, m_phoneNumber: mPhoneNumber } = props;
         const { phoneNumber, isKeyDown, errPhoneNumber, countryCode, errCountryCode, retrievedState, isMobile } = state;
 
         if (retrievedState) {

            if(props?.flowFlag != 'MarketNAO')
            {
                savedPhoneNumber = phoneNumber;
            }
           
            savedCountryCode = countryCode;
             savedIsMobile = isMobile;
         } else {
              savedPhoneNumber = mPhoneNumber !== '' && !isNumberChanged ? '' : savedPhoneNumber;
         }
         if (phoneNumber === '' && !isKeyDown && errPhoneNumber === '') {
             return {
                 ...state,
                 phoneNumber: savedPhoneNumber,
                 isMobile: savedIsMobile,
                errCountryCode: '',
                countryCode: savedCountryCode || '+1',
                 maskedPhoneNumber: savedPhoneNumber && savedPhoneNumber,
                 retrievedState: true,
                 mPhoneNumber,
             };
         }
         if (countryCode === '' && !isKeyDown && errCountryCode === '') {
             return {
                 ...state,
                 countryCode: savedCountryCode || '+1',
                 isMobile: savedIsMobile,
                 maskedPhoneNumber: savedPhoneNumber && savedPhoneNumber,
                 retrievedState: true,
                 mPhoneNumber,
             };
         }
         if ((phoneNumber === ''|| phoneNumber?.length < 10) && isNumberChanged !== '') {
            return {
                ...state,
            }
         }
         if (phoneNumber === '-') {
             return {
                 ...state,
                 phoneNumber: '',
                 maskedPhoneNumber: '',
                 retrievedState: true,
                 mPhoneNumber,
             };
         }
         
         return state;
     }
 
     handleChange = eventKey => e => {
         this.setState({ [eventKey]: e.target.value });
     };
 
     handlePhoneNumberBlur = () => e => {
         const { required } = this.props;
         const { countryCode } = this.state;
         const phoneNumber = e.target.value;
         let errCountryCode = '';
         let errPhoneNumber = Rules[e.target.name.toString()]
             ? checkValidity({
                   rules: Rules[e.target.name.toString()],
                   value: e.target.value,
               })
             : '';
 
        const trimedCountryCode = countryCode.toString().trim();
        const trimedPhoneNumber = phoneNumber.toString().trim();

         if (required) {
             
             if (trimedCountryCode === '' && (trimedCountryCode.length < 2 || trimedPhoneNumber !== '') ) {
                errCountryCode = 'Please enter country code';
             }
 
             if (
                 trimedCountryCode === '+1' &&
                 trimedPhoneNumber?.length > 0 &&
                 trimedPhoneNumber.length !== 10
             ) {
                 errPhoneNumber = 'Please enter a valid phone number';
             }
         } else if (
            (trimedCountryCode === '' || trimedCountryCode === '+') && trimedPhoneNumber.length > 9) {
                errCountryCode = 'Please enter country code';
        } else if (
             trimedCountryCode === '+1' &&
             trimedPhoneNumber !== '' &&
             trimedPhoneNumber?.length > 0 &&
             trimedPhoneNumber.length !== 10
         ) {
             errPhoneNumber = 'Please enter a valid phone number';
         }

         const {marketNAOInfo} = this.props;
    
         if(this.props?.jointInfo == "Yes")
         {
            if(marketNAOInfo.contactInfo.primary.phoneInfo.primary.number == phoneNumber )
            {
                errPhoneNumber = `The joint account owner's phone number can not be the same as your phone number.`;
            }
         }  
 
         this.setState({
             errPhoneNumber,
             isKeyDown: false,
             phoneNumber: e.target.value,
             errCountryCode,
             maskedPhoneNumber: e.target.value,
         });
     };
 
     handlePhoneNumberChange = () => e => {
         const targetValue = e.target.value;
         const { phoneNumber, countryCode } = this.state;
         let phoneNumberLen = 10;
         if (countryCode !== '+1' && countryCode !== '1' && countryCode !== '001') {
             phoneNumberLen = 15;
         }
         const isInvalidInput =
             targetValue.match(/[a-zA-Z!@$%^*(),.?";:{}_+#&|<>=~`\[\]'\\/]/g) || targetValue.length > phoneNumberLen;
         if (isInvalidInput && isInvalidInput.length > 0) {
             this.setState({ [e.target.name]: phoneNumber, isKeyDown: true, maskedPhoneNumber: phoneNumber });
         } else {
             let inputValue = targetValue;
             const targetLength = targetValue && targetValue.length;
             if (phoneNumber.length > targetLength) {
                 inputValue = phoneNumber.substring(0, targetLength);
             }
             this.setState({ [e.target.name]: inputValue, isKeyDown: true, maskedPhoneNumber: inputValue });
         }

         if(this.props?.flowFlag == 'MarketNAO')
         {
            const {handdlePhoneDetails} = this.props;
            handdlePhoneDetails();
         }
     };
 
     handlePhoneNumberFocus = () => e => {
         const targetValue = e.target.value;
         const { phoneNumber } = this.state;
         let inputValue = targetValue;
         const targetLength = targetValue && targetValue.length;
         inputValue = phoneNumber.substring(0, targetLength);
         this.setState({ [e.target.name]: inputValue, isKeyDown: true, maskedPhoneNumber: inputValue });
     };
 
     maskPhone = v => {
         let r = v.replace(/-/g, '');
         // r = r.replace(/^0/, '');
         if (r.length > 10) {
             return r;
         }
         if (r.length > 6) {
             // 6..10 digits. Format as 4+4
             r = r.replace(/^(\w\w\w)(\w{3})(\w{0,4}).*/, '$1-$2-$3');
         } else if (r.length > 2) {
             // 3..5 digits. Add (0XX..)
             r = r.replace(new RegExp('[0-9]', 'g'), 'X').replace(/^(\w\w\w)(\w{0,3})/, '$1-$2');
         } else {
             // 0..2 digits. Just add (0XX
             r = r.replace(new RegExp('[0-9]', 'g'), 'X').replace(/^(\w*)/, '$1');
         }
         return r;
     };
 
     /*  ******************************************************
      * formatPhoneText
      * returns a string that is in XXX-XXX-XXXX format
      ****************************************************** */
     formatPhoneText = item => {
         let value = item.replace(/\D/g, '');
         if (value.length > 3 && value.length <= 6) value = `${value.slice(0, 3)}-${value.slice(3)}`;
         else if (value.length > 6) value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
         return value;
     };
 
     formatPhone = v => {
         let r = v.replace(/\D/g, '');
         // r = r.replace(/^0/, '');
         if (r.length > 10) {
             // 11+ digits. Format as 5+4.
             // r = r.replace(/^(\d\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3");
             r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '$1-$2-$3');
             // return r;
         } else if (r.length > 5) {
             // 6..10 digits. Format as 4+4
             r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '$1-$2-$3');
         } else if (r.length > 2) {
             // 3..5 digits. Add (0XX..)
             r = r.replace(/^(\d\d\d)(\d{0,3})/, '$1-$2');
         } else {
             // 0..2 digits. Just add (0XX
             r = r.replace(/^(\d*)/, '$1');
         }
         return r;
     };
 
     handlePhoneDetails = isMobile => () => {
         this.setState({ isMobile: !isMobile });
     };
 
    handleCountryCodeFocus = () => e => {
            let countryCode  = e.target.value;
            countryCode = countryCode.replace(/\D/g, '');
            this.setState({countryCode});
    };

     handleCountryCodeChange = () => e => {
         const targetValue = e.target.value && e.target.value.substring(1);
         const { countryCode, countryCodeTemp, phoneNumber } = this.state;
         const isInvalidInput = targetValue.match(/[a-zA-Z!@$%^*(),.?";:{}_+#&|<>=~`\[\]'\\/]/g);
 
         if (isInvalidInput && isInvalidInput.length > 0) {
             this.setState({ [e.target.name]: countryCode, isKeyDown: true });
         } else {
            const firstChar = e.target.value && e.target.value.toString().charAt(0)
            if(parseInt(firstChar,10) === 0){
                return false;
            }

            const phoneNumberTemp = countryCodeTemp !== '' || countryCodeTemp !== e.target.value ? '': phoneNumber;
             this.setState({
                 [e.target.name]: e.target.value,
                 isKeyDown: true,
                 phoneNumber: phoneNumberTemp
             });
         }
     };
 
     handleCountryCodeBlur = () => e => {
         const { required, m_phoneNumber: mPhoneNumber,phtype } = this.props;
         const { phoneNumber, countryCodeTemp, maskedPhoneNumber } = this.state;
         const countryCodeFormat = /^(\+?\d{1,3}|\d{1,4})$/gm;
        let countryCode = e.target.value;
        let maskedPhoneNum = '';

        if (countryCode.length > 0) {
            countryCode = countryCode.toString().charAt(0) === '+' ? countryCode : `+${countryCode}`;
        }
         const match = countryCode.match(countryCodeFormat);
         let errPhoneNumber = '';
         let errCountryCode = Rules[e.target.name.toString()]
             ? checkValidity({
                   rules: Rules[e.target.name.toString()],
                  value: countryCode,
               })
             : '';
 
         if (required) {
             const value = e.target.value.replace(/\D/g, '');
             if (value.length === 0) {
                 errCountryCode = 'Please enter country code';
             } else if (countryCode.toString().trim() === '' || countryCode.toString().trim().length < 2) {
                 errCountryCode = 'Please enter country code';
             } else if (match === null) {
                 errCountryCode = 'Please use format +xxx';
             }

             if(phtype == "Primary")
             {
                if (mPhoneNumber === '' && phoneNumber.toString().trim() === '' && countryCode.toString().trim() !== '') {
                     errPhoneNumber = 'Please enter a phone number';
                 }
                 if (
                     mPhoneNumber === '' &&
                     countryCode.toString().trim() === '+1' &&
                     phoneNumber.toString().trim().length > 0 &&
                     phoneNumber.toString().trim().length !== 10
                 ) {
                     errPhoneNumber = 'Please enter a valid phone number';
                 }
             }
 
          
         }
          else if (
             countryCode.toString().trim() === '+1' &&
             phoneNumber.toString().trim().length > 0 &&
             phoneNumber.toString().trim().length !== 10 && phtype == "Primary"
         ) {
             errPhoneNumber = 'Please enter a valid phone number';
         }

         const phoneNum = countryCodeTemp !== '' && countryCodeTemp !== countryCode ? '': phoneNumber;
         if (phoneNum === '' && phtype == "Primary"){
              errPhoneNumber = 'Please enter a valid phone number';
         } else if(phoneNum === '' && phtype == "Secondary"){
            maskedPhoneNum = '';
         }else {
            maskedPhoneNum = maskedPhoneNumber;
         }
         
 
         this.setState({
             errCountryCode,
             countryCode,
             errPhoneNumber,
             phoneNumber: phoneNum,
             countryCodeTemp: countryCode,
             retrievedState: false,
             maskedPhoneNumber: maskedPhoneNum,
         });
     };
 
     numberTypeInput = e => {
         const isValid =
             (e.keyCode > 47 && e.keyCode < 58) ||
             (e.keyCode > 95 && e.keyCode < 106) ||
             e.keyCode === 8 ||
             e.keyCode === 9;
         if (!isValid) {
             e.preventDefault();
         }
     };
 
     countryCodeInput = e => {
         const isValid =
             (e.keyCode > 47 && e.keyCode < 58) ||
             (e.keyCode > 95 && e.keyCode < 106) ||
             e.keyCode === 8 ||
             e.keyCode === 9 ||
             e.keyCode === 37 ||
             e.keyCode === 39 ||
             e.keyCode === 107 
       
        if (!isValid || (e.target.value === "" && e.keyCode === 48)) {
             e.preventDefault();
         }
     };
 
     getPhoneDetails = () => {
         const { phoneNumber } = this.state;
         const { jointText, phtype, index } = this.props;
         const phoneId = `${jointText}${phtype}PhoneNumber${index}`;
         const countryCodeId = `${jointText}${phtype}CountryCode${index}`;
         if (phoneNumber === '') {
             return { ...this.state, phoneId, countryCodeId };
         }
         return { ...this.state, phoneId, countryCodeId };
     };
 
     render() {
         const { phtype, required, jointText, index, m_phoneNumber: mPhoneNumber, isReadOnly = false,marketJointPhoneLabel  } = this.props;
         const { isMobile, errPhoneNumber, countryCode, errCountryCode, maskedPhoneNumber } = this.state;
         const PHONE_INFORMATION_LABEL = ' Phone Information';
         const PHONE_LABEL = ' Phone';
         const { assignObj } = AccGlobalConstants;
         const phoneNumberIDLabel = `${jointText}${phtype}PhoneInformation`;
                    const verifyPersonalHider=this.props.verifyPersonalHider
         const verifyContactHider=this.props.verifyContactHider
           const verifyEmailHider=this.props.verifyEmailHider
            const verifyJointPersonalHider=this.props.verifyJointPersonalHider
              const verifyJointContactHider=this.props.verifyJointContactHider
               const verifyJointEmailHider=this.props.verifyJointEmailHider
               const phoneLabel = marketJointPhoneLabel == "Yes" ? consts.jointPersonalPhoneLabel : consts.setAsMobileLabel

         return (
             verifyPersonalHider || verifyContactHider || verifyEmailHider || verifyJointPersonalHider || verifyJointContactHider || verifyJointEmailHider   ? null :
             <div className="phoneWrapper">
                 <h3 style={styles.mobileHeaderStyle} className="primaryPhoneAlign">
                     {phtype}
                     {PHONE_INFORMATION_LABEL}
                 </h3>
 
                 {/* -------------------------start-------------------------------- */}
                 <div className="fieldWrapperDiv" role="group" aria-labelledby={phoneNumberIDLabel}>
                     <Form.Group as={Row} className="no-gutters phoneNumberGroup" style={styles.labelStyle}>
                         <span className="label col-sm-4 phoneNumbertext" aria-label={phtype + PHONE_LABEL} style={styles.labelTextStyle} id={phoneNumberIDLabel}>
                             {phtype}
                             {PHONE_LABEL}
                         </span>
                         <div className="PhNumberWrapper col-sm-6">
                             <div className="selectDiv">
                                 <Form.Control
                                     as="input"
                                     type="text"
                                     onFocus={this.handleCountryCodeFocus()}
                                     onChange={this.handleCountryCodeChange()}
                                     onBlur={this.handleCountryCodeBlur()}
                                     required={phtype === 'Primary'}
                                     aria-required={phtype === 'Primary'}
                                     isInvalid={!!errCountryCode}
                                     name="countryCode"
                                     onKeyDown={this.countryCodeInput}
                                     maxLength={3}
                                     value={countryCode}
                                     disabled = {isReadOnly}
                                     aria-label={`Country code for ${phtype} Phone`}
                                     aria-invalid={!!errCountryCode}
                                     aria-describedby={assignObj(`${phtype}countryCode_error${index}`)}
                                     id={assignObj(`${jointText}${phtype}CountryCode${index}`)}
                                     autoComplete="off"
                                 />
                                 <Form.Text
                                     type="invalid"
                                     className="errorMsgInputStyle"
                                     id={assignObj(`${phtype}countryCode_error${index}`)}
                                     aria-atomic="true"
                                     aria-live="assertive"
                                 >
                                     {errCountryCode && <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>}
                                     {errCountryCode}
                                 </Form.Text>
                             </div>
                             <div className="inputDiv">
                                 <Form.Control
                                     type="text"
                                     onChange={this.handlePhoneNumberChange()}
                                     onBlur={this.handlePhoneNumberBlur()}
                                     onFocus={this.handlePhoneNumberFocus()}
                                     required={required}
                                     name="phoneNumber"
                                     value={maskedPhoneNumber || ''}
                                     isInvalid={!!errPhoneNumber}
                                     maxLength={countryCode === '+1' ? 10 : 15}
                                     minLength={10}
                                     onKeyDown={this.numberTypeInput}
                                     aria-required={required}
                                     aria-label={`Number for ${phtype} Phone`}
                                     aria-invalid={!!errPhoneNumber}
                                     aria-describedby={`${phtype}phoneNumber_hint${index} ${phtype}phoneNumber_error${index}`}
                                     id={assignObj(`${jointText}${phtype}PhoneNumber${index}`)}
                                     disabled={isReadOnly}
                                 />
                                 <Form.Text
                                     type="invalid"
                                     className="errorMsgInputStyle"
                                     id={assignObj(`${phtype}phoneNumber_error${index}`)}
                                     aria-atomic="true"
                                     aria-live="assertive"
                                 >
                                     {errPhoneNumber && <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>}
                                     {errPhoneNumber}
                                 </Form.Text>
                                 <span className="hintText" id={assignObj(`${phtype}phoneNumber_hint${index}`)}>
                                     {mPhoneNumber}
                                 </span>
                             </div>
                         </div>
                     </Form.Group>
                     <Row className="primarySwitchRow no-gutters">
                         <span
                             className="label col-sm-4 phoneNumbertext"
                             style={styles.labelTextStyle}
                             id={assignObj(`${jointText}${phtype}IsMobileLabel${index}`)}
                         >
                             {phoneLabel}
                         </span>
                         <Toggle
                             id={assignObj(`${jointText}${phtype}IsMobile${index}`)}
                             aria-labelledby={assignObj(`${jointText}${phtype}IsMobileLabel${index}`)}
                             toggleOn={isMobile}
                             onChange={this.handlePhoneDetails(isMobile)}
                         />
                     </Row>
                 </div>
                 {/* -------------------------end---------------------------------- */}
             </div>
         );
     }
 }
 
 PhoneDetails.propTypes = {
     required: PropTypes.bool,
     phtype: PropTypes.string,
     savedPhoneNumber: PropTypes.string,
     savedCountryCode: PropTypes.string,
     savedIsMobile: PropTypes.bool,
     jointText: PropTypes.string,
     index: PropTypes.number,
     m_phoneNumber: PropTypes.string,
     isNumberChanged: PropTypes.bool,
 };
 
 PhoneDetails.defaultProps = {
     required: false,
     phtype: '',
     savedPhoneNumber: '',
     // savedContactTime: '',
     savedIsMobile: false,
     jointText: '',
     index: 100,
     savedCountryCode: '',
     m_phoneNumber: '',
     isNumberChanged: false,
 };
 
 export default PhoneDetails;
 