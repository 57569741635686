const styles = {
    header: {
        // position: "relative",
        margin: '0 5px 15px',
        font: "700 18px/25px benton-sans",
        color: "#49494A",
        textAlign: 'center',
        maxWidth: 310
    },
    para1: {
        margin: '0 auto',
        marginBottom: 30,
        font: '500 16px/20px benton-sans',
        color: '#49494A',
        maxWidth: 350,
        height: 39
    },
    para2: {
        margin: '0 auto',
        marginBottom: 30,
        font: '500 16px/20px benton-sans',
        color: '#49494A',
        maxWidth: 350,
        textAlign: 'center'
    },
    hr: {
        borderBottom: '1px solid #D2D2D2',
        margin: '30px 0'
    },
    corner: {
        width: "0",
        height: "0",
        borderBottom: "35px solid transparent",
        borderRight: "35px solid #8BC105",
        position: "absolute",
        top: "0",
        right: "0"
    }
}

export default styles;