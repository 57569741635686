import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
};

function masterDataLookUpDataReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_REDUX_DATA:
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false,
            };
        case ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_LOADER:
        case ActionTypes.GET_COMPOSITE_DATA_LOADER:
        case ActionTypes.GET_RANK_LOADER: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }
        case ActionTypes.GET_COMPOSITE_DATA_SUCCESS:
        case ActionTypes.GET_PERSONAL_COMPOSITE_DATA_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_COMPOSITE_DATA_FAILURE: {
            return {
                ...state,
                ...action,
                isError: true,
                isLoading: false,
                isSuccess: false,
            };
        }

        case ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA_SUCCESS:
            if (state.acct_type && action.key) {
                const tempAcctType = state.acct_type.value;
                const payloadKey = action.key;
                for (let i = 0; i < tempAcctType.length; i += 1) {
                    const tempObj = tempAcctType[+i];
                    if (!state[`${payloadKey}`] && tempObj.key === payloadKey && !tempObj.subTypes) {
                        tempObj.subTypes = action[`${payloadKey}`];
                    }
                }
            }
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };

        case ActionTypes.GET_COMPOSITE_DATA_ERROR: // case ActionTypes.GET_PERSONAL_COMPOSITE_DATA_ERROR:
        {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }

        case ActionTypes.GET_RANK_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_RANK_ERROR: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        default:
            return state;
    }
}
export default masterDataLookUpDataReducer;
