import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    RETIREMENT_PLANNER_CALCULATOR,
    COLLEGE_SAVINGS_CALCULATOR,
    CALCULATORS,
    TRADITIONAL_IRA_CALCULATOR,
    RETIREMENT_NEST_EGG_CALCULATOR,
    RMD_FUTURE_CALCULATOR,
    RMD_CURRENT_CALCULATOR,
} from './Constants';

const CalculatorDiv = styled.div`
    width: 90%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
`;

function CalculatorsComponent() {
    return (
        <div className="container">
            <CalculatorDiv>
                <h2>{CALCULATORS}</h2>
                <Link to="/retirementPlanCalculator">{RETIREMENT_PLANNER_CALCULATOR}</Link>
                <Link to="/collegeSavingsCalculator">{COLLEGE_SAVINGS_CALCULATOR}</Link>
                <Link to="/traditionalIRACalculator">{TRADITIONAL_IRA_CALCULATOR}</Link>
                <Link to="/retirementNestEggCalculator">{RETIREMENT_NEST_EGG_CALCULATOR}</Link>
                <Link to="/rmdFutureCalculator">{RMD_FUTURE_CALCULATOR}</Link>
                <Link to="/rmdCurrentCalculator">{RMD_CURRENT_CALCULATOR}</Link>
            </CalculatorDiv>
        </div>
    );
}

export default CalculatorsComponent;
