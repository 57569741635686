import {
    SAVE_PAGE_STATE_FAILURE,
    SAVE_PAGE_STATE_SUCCESS,
    GET_LIQUIDATION_TRADING_LIMIT_START,
    GET_LIQUIDATION_TRADING_LIMIT_SUCCESS,
    GET_LIQUIDATION_TRADING_LIMIT_FAILURE,
    CANCEL_TRADING_LIMIT_MESSAGE_FAILURE,
    CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS,
    SET_LIQ_NEXT_PAGE_NUMBER_FAILURE,
    SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_FAILURE,
    NEXT_PAGE_ERROR,
    SET_TAX_VALIDATION_SUCCESS,
    SET_TAX_VALIDATION_FAILURE,
    SET_FUND_SOURCE_VALIDATION_SUCCESS,
    SET_FUND_SOURCE_VALIDATION_FAILURE,
    SUBMIT_LIQUIDATION_DATA_LOADING,
    SUBMIT_LIQUIDATION_DATA_SUCCESS,
    SUBMIT_LIQUIDATION_DATA_ERROR,
    GET_STATE_CODE_GROUP_LOADER,
    GET_STATE_CODE_GROUP_FAILURE,
    GET_STATE_CODE_GROUP_SUCCESS,
    GET_STATE_TAXES_FOR_GROUP_LOADER,
    GET_STATE_TAXES_FOR_GROUP_SUCCESS,
    GET_STATE_TAXES_FOR_GROUP_FAILURE,
    SET_PAYMENT_METHOD_FAILURE,
    SET_PAYMENT_METHOD_SUCCESS,
    CLEAR_LIQUIDATION_DATA,
    SET_CANCEL_LIQUIDATION,
    SET_NOT_TO_CANCEL_LIQUIDATION,
    SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE,

    RESET_LIQUIDATION_SUBMIT,
    RESET_GO_TO_NEXT_PAGE,
    SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL,
    IS_RESUBMIT_FLOW,
    REMOVE_STEP_DATA
} from '../ReduxConstants/TransactionActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    accountListForLiquidation: [],
    fundList: [],
    error: '',
    paymentMethod: [],
    tradingMessage: '',
    isTradingAllowed: true,
    goToNextPage: false,
    deliveryAddress: [],
    stateCodeGroup: [],
    groupStateTax: {},
    currentPageNumber: 1,
    taxValidation: false,
    fundSourceValidation: true,
    age: 0,
    stateForStateTax: '',
    showCancelLiqModal: false,
    customerName: '',
    cancelSelectedAcc: {},
    address: [],
    proportinateFundPassedList:[],
    proportionateFaildFundList:[],
    isProportionateResubmitFlow:false
};

let proportinateFundPassedList = [];
let liquidationSubmitAPiCount = 0;

function liquidationReducer(state = initialState, action) {
    switch (action.type) {
        case SUBMIT_LIQUIDATION_DATA_LOADING: {
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }
        case SUBMIT_LIQUIDATION_DATA_SUCCESS: {
            proportinateFundPassedList.push(action.payload.fundAccountNumber)
            liquidationSubmitAPiCount = liquidationSubmitAPiCount +1;
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                liquidateFundResponse: action.payload,
                proportinateFundPassedList:proportinateFundPassedList,
                liquidationSubmitAPiCount: liquidationSubmitAPiCount
            };
        }
        case SUBMIT_LIQUIDATION_DATA_ERROR: {
            liquidationSubmitAPiCount = liquidationSubmitAPiCount +1;
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                error: action.payload,
                liquidationSubmitAPiCount: liquidationSubmitAPiCount
            };
        }

        
        case RESET_LIQUIDATION_SUBMIT:{
            proportinateFundPassedList = [];
            liquidationSubmitAPiCount = 0;
            return {
                ...state
            }
        }

        case IS_RESUBMIT_FLOW: {
            return{
                ...state,
                isProportionateResubmitFlow:true
            }
        }
        case RESET_GO_TO_NEXT_PAGE:{

            return{
                ...state,
                goToNextPage:false,

            }
        }

        case SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL: {

            return {
                ...state,
                proportionateFaildFundList: action?.payload
            }
        }

        case REMOVE_STEP_DATA: {
            delete state[`page${action.payload}`];
            state.page2.distributionAmount="";
            state.page2.fedTaxAmount=0;
            state.page2.fedTaxPercent=0;
            state.page2.stateTaxAmount=0;
            state.page2.stateTaxPercent=0;
            state.page2.stateTaxWithHeld=false;
            state.page2.taxValidation=false;
            state.page2.taxValidity=false;
            state.page2.totalTaxAmount="0.0"            
            return {
                ...state
            }
        }
        // Sets the page state into redux state
        case SAVE_PAGE_STATE_SUCCESS: {
            return {
                ...state,
                isError: false,
                [`page${action.payload.pageIndex}`]: action.payload.data,
                goToNextPage: false,
            };
        }
        case GET_LIQUIDATION_TRADING_LIMIT_FAILURE:
        case SAVE_PAGE_STATE_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                error: action.payload,
                goToNextPage: false,
            };
        }
        // Get Liquidation Trading Limit
        case GET_LIQUIDATION_TRADING_LIMIT_START: {
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        }
        case GET_LIQUIDATION_TRADING_LIMIT_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                isTradingAllowed: action.payload.isSuccess,
                tradingMessage: action.payload.message,
                goToNextPage: true,
            };
        }

        case CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                isTradingAllowed: true,
                tradingMessage: '',
                goToNextPage: false,
            };
        }

        case CANCEL_TRADING_LIMIT_MESSAGE_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                isTradingAllowed: true,
                tradingMessage: '',
                error: action.payload,
                goToNextPage: false,
            };
        }
        // Get delivery address for check
        case GET_STATE_TAXES_FOR_GROUP_LOADER:
        case GET_STATE_CODE_GROUP_LOADER: {
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isError: action.payload.isError,
            };
        }
        // Sets selected payment menthod in state
        case SET_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                paymentMethod: action.payload,
            };
        }
        case SET_PAYMENT_METHOD_FAILURE: {
            return {
                ...state,
                paymentMethod: [],
                error: action.payload,
            };
        }
        // Get State Code Group
        case GET_STATE_CODE_GROUP_SUCCESS: {
            return {
                ...state,
                stateCodeGroup: action.payload.taxGroup,
                age: action.payload.age,
                stateForStateTax: action.payload.state,
                customerName: action.payload.customerName,
                address: action.payload.address,
                isError: false,
                isLoading: false,
            };
        }
        case GET_STATE_CODE_GROUP_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                stateCodeGroup: [],
            };
        }

        // Get State Taxes based on groups

        case GET_STATE_TAXES_FOR_GROUP_SUCCESS: {
            let stateTaxAmount;
            if (action.payload.stateTaxDetails.basis === 'FWH') {
                stateTaxAmount =
                    (parseFloat(action.payload.fedWithHolding) *
                        parseFloat(action.payload.stateTaxDetails.taxPercent)) /
                    100;
            } else {
                stateTaxAmount =
                    (parseFloat(action.payload.distributionAmount) *
                        parseFloat(action.payload.stateTaxDetails.taxPercent)) /
                    100;
            }
            const stateTaxDetails = {
                isWarning: action.payload.stateTaxDetails.isWarning,
                stateTax: stateTaxAmount,
                taxPercent: action.payload.stateTaxDetails.taxPercent,
                basis: action.payload.stateTaxDetails.basis,
            };
            return {
                ...state,
                groupStateTax: stateTaxDetails,
                isError: false,
                isLoading: false,
            };
        }
        case GET_STATE_TAXES_FOR_GROUP_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                groupStateTax: {},
            };
        }
        case SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS: {
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1,
            };
        }
        case SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE:
        case SET_LIQ_NEXT_PAGE_NUMBER_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
            };
        }

        case RESET_PAGE_NUMBER_SUCCESS: {
            return {
                ...state,
                currentPageNumber: 1,
            };
        }

        case RESET_PAGE_NUMBER_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
                currentPageNumber: 1,
            };
        }

        case SET_TAX_VALIDATION_SUCCESS: {
            return {
                ...state,
                taxValidation: action.payload,
            };
        }

        case SET_TAX_VALIDATION_FAILURE: {
            return {
                ...state,
                isError: true,
                taxValidation: action.payload,
            };
        }

        case SET_FUND_SOURCE_VALIDATION_SUCCESS: {
            return {
                ...state,
                fundSourceValidation: action.payload,
            };
        }

        case SET_FUND_SOURCE_VALIDATION_FAILURE: {
            return {
                ...state,
                isError: true,
                fundSourceValidation: action.payload,
            };
        }

        case CLEAR_LIQUIDATION_DATA: {
            return {
                ...initialState,
            };
        }

        case SET_CANCEL_LIQUIDATION: {
            return {
                ...state,
                showCancelLiqModal: true,
                cancelSelectedAcc: action.payload,
            };
        }

        case SET_NOT_TO_CANCEL_LIQUIDATION: {
            return {
                ...state,
                showCancelLiqModal: false,
                cancelSelectedAcc: {},
            };
        }

        case SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS: {
            return {
                ...state,
                currentPageNumber: action.payload,
            };
        }

        default:
            return state;
    }
}

export default liquidationReducer;
