import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';

export const getAccountDetails = () => (dispatch) => {
    dispatch({
        type: ActionTypes.ACCOUNT_DETAILS_GUEST, 
    });
};

export const getAccountTypes = (data) => (dispatch) => {
    const env = '';
    const payload = data;
    dispatch({
        type: ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_LOADER,
    });
    makeApiRequest(
        ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_SUCCESS,
                    accountSummary: response.data[0].datavalue.value,
                });
            } 
        },
        error => {
            dispatch({
                type: ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_ERROR,
                error: error.data,
            });
        },
    );
};

export default getAccountDetails;