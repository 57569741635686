const PERSONAL_INFO_SUCCESS = 'Successfully updated Personal Information.';
const EMPLOYMENT_INFO_SUCCESS = 'Successfully updated Employment Information.';
const MILITARY_INFO_SUCCESS = 'Successfully updated Military Information.';
const FINANCIAL_INFO_SUCCESS = 'Successfully updated Financial Information.';
const SECURITY_REASONS = 'For security reasons, not all the information can be updated online';
const RELATIONSHIP_INFO_SUCCESS = 'Successfully updated Relationship Information.';
const PAGE_TITLE = 'Basic Information';
const BREADCRUM_ITEMS = [{ url: '/Profile', name: 'Profile', dataAnalyticId:'CMS_basicinformation_breadcrumb_profile' }];
const FINANCIAL_REMAINDER_MODAL_TITLE = "Update Financial Information";
const FINANCIAL_REMAINDER_MODAL_PRIMARY_BTN = "Update";
const FINANCIAL_REMAINDER_MODAL_SECONDARY_BTN = "Later";
const FINANCIAL_REMAINDER_MODAL_TEXT = "In order to best serve you, it’s important that we have updated information. Please help us by updating your financial information on this page";
const EMPTY_INFO_NOTIFICATION_1 = "To help us better serve your needs, please help us by completing your";
const EMPTY_INFO_NOTIFICATION_2 = "information as soon as possible";

export default {
    EMPTY_INFO_NOTIFICATION_1,
    EMPTY_INFO_NOTIFICATION_2,
    PERSONAL_INFO_SUCCESS,
    SECURITY_REASONS,
    PAGE_TITLE,
    BREADCRUM_ITEMS,
    EMPLOYMENT_INFO_SUCCESS,
    MILITARY_INFO_SUCCESS,
    FINANCIAL_INFO_SUCCESS,
    FINANCIAL_REMAINDER_MODAL_TITLE,
    FINANCIAL_REMAINDER_MODAL_PRIMARY_BTN,
    FINANCIAL_REMAINDER_MODAL_SECONDARY_BTN,
    FINANCIAL_REMAINDER_MODAL_TEXT,
    RELATIONSHIP_INFO_SUCCESS,
};
