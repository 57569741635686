
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    "list_manage_interested_parties": [
        {
            "key": "1",
            "account_Type": "Traditional IRA",
            "account_Name": "Sample Content",
            "account_Number": "56654654",
            "accumulated_Value": "23152",
            "interestedParty": [
                {
                    "key": "1",
                    "fname": "Bob",
                    "mname": "",
                    "lname": "John",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Financial Advisor",
                    "email": "Bob@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '3'
                },
                {
                    "key": "2",
                    "fname": "David",
                    "mname": "",
                    "lname": "M",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Financial Advisor",
                    "email": "David@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '3'
                }
            ]
        },
        {
            "key": "2",
            "account_Type": "Roth IRA",
            "account_Name": "Sample Content",
            "account_Number": "56654654",
            "accumulated_Value": "23152",
            "interestedParty": [
                {
                    "key": "1",
                    "fname": "Helen",
                    "mname": "",
                    "lname": "Smith",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Cousin",
                    "email": "Helen@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '1'
                },
                {
                    "key": "2",
                    "fname": "Bob",
                    "mname": "",
                    "lname": "John",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Financial Advisor",
                    "email": "John@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '3'
                }
            ]
        },
        {
            "key": "3",
            "account_Type": "Individual Mutual Fund Account",
            "account_Name": "Sample Content",
            "account_Number": "56654654",
            "accumulated_Value": "23152",
            "interestedParty": [
                {
                    "key": "1",
                    "fname": "Robert",
                    "mname": "",
                    "lname": "Smith",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Cousin",
                    "email": "Helen@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '1'
                },
                {
                    "key": "2",
                    "fname": "Bob",
                    "mname": "",
                    "lname": "John",
                    "contract_Number": "123456789",
                    "relationship_To_Account_holder": "Financial Advisor",
                    "email": "John@gmail.com",
                    "company": "Sample Content",
                    "addressLine1": "",
                    "addressLine2": "",
                    "zipCode": "64155",
                    "city": "Kansas",
                    "state": "MO",
                    "startDate": "",
                    "endDate": "",
                    "accounts_Tagged": '3'
                }
            ]
        }
    ],
    "data": [
        {
            "key": "1",
            "fname": "Bob",
            "mname": "",
            "lname": "John",
            "email": "Mary@gmail.com",
            "company": "",
            "addressLine1": "287, Hillcrest Lane",
            "addressLine2": "",
            "zipCode": "64155",
            "city": "Kansas",
            "state": "MO",
            "accounts_Tagged": '3',
            "interestedParties": [
                {
                    "key": "1",
                    "accountType": "Traditional IRA",
                    "accountName": "Sample Content",
                    "accountNumber": "56654654",
                    "startDate": "05/05/2015",
                    "endDate": "05/05/2020",
                },
                {
                    "key": "2",
                    "accountType": "Roth IRA",
                    "accountName": "Sample Content",
                    "accountNumber": "56988584",
                    "startDate": "05/05/2015",
                    "endDate": "05/05/2020",
                },
                {
                    "key": "3",
                    "accountType": "Individual",
                    "accountName": "Sample Content",
                    "accountNumber": "56654322",
                    "startDate": "05/05/2015",
                    "endDate": "05/05/2020",
                }
            ]
        },
        {
            "key": "2",
            "fname": "Helen",
            "mname": "",
            "lname": "Smith",
            "email": "Smith@gmail.com",
            "company": "",
            "addressLine1": "287, Hillcrest Lane",
            "addressLine2": "",
            "zipCode": "64155",
            "city": "Kansas",
            "state": "MO",
            "accounts_Tagged": '1',
            "interestedParties": [
                {
                    "key": "1",
                    "accountType": "Roth IRA",
                    "accountName": "Sample Content",
                    "accountNumber": "56654654",
                    "startDate": "05/05/2015",
                    "endDate": "05/05/2020",
                }
            ]
        }
    ],
    isLoading: false,
    isError: false,
    isSuccess: false,
    savedInterestedPartyData: {},
    isEdit: false,
    isNew: false,
    isShowMsg: false,
    notificationMsg: ""
};


function manageInterestedPartiesReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SAVE_INTERESTED_PARTY_DATA: {
            return {
                ...state,
                ...action
            };
        }
        case ActionTypes.ADD_INTERESTED_PARTY:
        case ActionTypes.UPDATE_INTERESTED_PARTY:
        case ActionTypes.GET_INTERESTED_PARTY:
        case ActionTypes.DELETE_INTERESTED_PARTY: {
            return {
                ...state,
                ...action
            };
        }
        case ActionTypes.ADD_INTERESTED_PARTY_LOADING:
        case ActionTypes.GET_INTERESTED_PARTY_LOADING:
        case ActionTypes.UPDATE_INTERESTED_PARTY_LOADING:
        case ActionTypes.DELETE_INTERESTED_PARTY_LOADING: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.ADD_INTERESTED_PARTY_SUCCESS:
        case ActionTypes.GET_INTERESTED_PARTY_SUCCESS:
        case ActionTypes.UPDATE_INTERESTED_PARTY_SUCCESS:
        case ActionTypes.DELETE_INTERESTED_PARTY_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        case ActionTypes.ADD_INTERESTED_PARTY_ERROR:
        case ActionTypes.GET_INTERESTED_PARTY_ERROR:
        case ActionTypes.UPDATE_INTERESTED_PARTY_ERROR:
        case ActionTypes.DELETE_INTERESTED_PARTY_ERROR: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        case ActionTypes.ADD_INTERESTED_PARTY_FAILURE:
        case ActionTypes.GET_INTERESTED_PARTY_FAILURE:
        case ActionTypes.UPDATE_INTERESTED_PARTY_FAILURE:
        case ActionTypes.DELETE_INTERESTED_PARTY_FAILURE: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        default:
            return state;
    }
}

export default manageInterestedPartiesReducer;