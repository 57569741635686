export const GET_LIVECHAT_QUEUE_METRICS = 'GET_LIVECHAT_QUEUE_METRICS' ;
export const GET_LIVECHAT_QUEUE_METRICS_FAILURE = 'GET_LIVECHAT_QUEUE_METRICS_FAILURE' ;
export const GET_LIVECHAT_QUEUE_METRICS_SUCCESS = 'GET_LIVECHAT_QUEUE_METRICS_SUCCESS' ;
export const GET_LIVECHAT_QUEUE_WAITTIME = 'GET_LIVECHAT_QUEUE_WAITTIME';
export const GET_LIVECHAT_QUEUE_WAITTIME_FAILURE = 'GET_LIVECHAT_QUEUE_WAITTIME_FAILURE';
export const GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS = 'GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS';
export const GET_LIVECHAT_QUEUES_STATUS = 'GET_LIVECHAT_QUEUES_STATUS';
export const GET_LIVECHAT_QUEUES_STATUS_FAILURE = 'GET_LIVECHAT_QUEUES_STATUS_FAILURE';
export const GET_LIVECHAT_QUEUES_STATUS_SUCCESS = 'GET_LIVECHAT_QUEUES_STATUS_SUCCESS';
export const GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION = 'GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION';
export const GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE = 'GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE';
export const GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS = 'GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS';
export const GET_MSR_AVAILABILITY_FROM_SCHEDULES = 'GET_MSR_AVAILABILITY_FROM_SCHEDULES';
export const GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER = 'GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER';
export const GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS = 'GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS';
export const GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE = 'GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE';
export const GET_MSR_AVAILABILITY='GET_MSR_AVAILABILITY';
export const GET_MSR_AVAILABILITY_LOADER = 'GET_MSR_AVAILABILITY_LOADER';
export const GET_MSR_AVAILABILITY_SUCCESS = 'GET_MSR_AVAILABILITY_SUCCESS';
export const GET_MSR_AVAILABILITY_FAILURE = 'GET_MSR_AVAILABILITY_FAILURE';
export const GET_PHONE_QUEUE_WAITTIME = 'GET_PHONE_QUEUE_WAITTIME';
export const GET_PHONE_QUEUE_WAITTIME_FAILURE = 'GET_PHONE_QUEUE_WAITTIME_FAILURE';
export const GET_PHONE_QUEUE_WAITTIME_SUCCESS = 'GET_PHONE_QUEUE_WAITTIME_SUCCESS';
export const GET_PHONE_SUPPORT_HOURS_OF_OPERATION = 'GET_PHONE_SUPPORT_HOURS_OF_OPERATION';
export const GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE = 'GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE';
export const GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS = 'GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS';
export const RESET_FAB = 'RESET_FAB';
export const RESET_LIVECHAT_QUEUE_STATE = 'RESET_LIVECHAT_QUEUE_STATE';
export const RESET_PHONE_QUEUE_WAIT_TIME_FAB = 'RESET_PHONE_QUEUE_WAIT_TIME_FAB';
export const RESET_SELECTED_QUERY = 'RESET_SELECTED_QUERY';
export const SET_CHAT_WIDGET_STATUS = 'SET_CHAT_WIDGET_STATUS';
export const SET_CHAT_WIDGET_STATUSZ_FAILURE = 'SET_CHAT_WIDGET_STATUSZ_FAILURE';
export const SET_ISHIDDEN = 'SET_ISHIDDEN';
export const SET_ISLOGGEDIN = 'SET_ISLOGGEDIN';
export const SET_LIVECHAT_QUEUE = 'SET_LIVECHAT_QUEUE';
export const SET_MODAL_ID = 'SET_MODAL_ID';
export const SET_SELECTED_QUERY = 'SET_SELECTED_QUERY';
export const SET_SHOW_CONFIRM_DIALOG = 'SET_SHOW_CONFIRM_DIALOG';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_TOGGLED = 'SET_TOGGLED';
export const TOGGLE_SPINNER = 'TOGGLE_SPINNER';
export const SET_CHAT_WIDGET_STATUS_FAILURE = 'SET_CHAT_WIDGET_STATUS_FAILURE';