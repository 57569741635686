import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import FieldValueRow from "./FieldValueRow";

const StyledHr = styled.hr`
    margin-bottom: 30px;
    border: none;
    &:after {
        content: "";
        height: 1px;
        width: 100%;
        background: linear-gradient(
            270deg,
            rgba(139, 137, 137, 0),
            #c2c2c2,
            hsla(0, 0%, 50.2%, 0)
        );
        display: block;
    }
`;

const ReviewPersonalInfo = (props) => {
    const { personalInfoFields, getFieldValue } = props;
    return (
        <Container>
            {personalInfoFields.map(({ key, description }, index) => {
                if (description === "-") {
                    return (
                        <StyledHr key={index.toString()} aria-hidden="true" />
                    );
                }
                const value = getFieldValue(
                    key
                );
                return (
                    <FieldValueRow
                        key={index.toString()}
                        field={description}
                        value={value}
                    />
                );
            })}
        </Container>
    );
};

ReviewPersonalInfo.propTypes = {
    personalInfoFields: PropTypes.instanceOf(Object),
    getFieldValue: PropTypes.func,
};

ReviewPersonalInfo.defaultProps = {
    personalInfoFields: [],
    getFieldValue: () => {},
};

export default ReviewPersonalInfo;