import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { accOpeningActions, loginActions } from '../../../shared/Actions';
import ModifySecurityQnsComponent from './ModifySecurityQnsComponent';



const mapStateToProps = (state /* , props */) => (
  {
    loginState: state.loginData,
    questionsData: state.masterLookUpData.security_ques
  }
);

const mapDispatchToProps = {
  ...loginActions,
  ...accOpeningActions
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifySecurityQnsComponent));