import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import routeConstants from 'routeConstants';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import Guidelines from 'modules/UserManagementAndSecurityModule/Guidelines';
import checkFormat from 'modules/UserManagementAndSecurityModule/checkFormat';
import { ConfirmPassword } from 'modules/UserManagementAndSecurityModule/Input/ConfirmPassword';
import { Password } from 'modules/UserManagementAndSecurityModule/Input/Password';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper, WStepper as UserMngmtStepper, WSpinner } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles';
import signUpPages from '../SignUpConstants';
import data from './Constants';
import userConstants from '../../userConstants';
import { encrypt } from '../../../../utils';

const { optionalMessage } = userConstants;
const assignObj = (obj) => { return obj; };

export class RegisterLoginCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upperLower: false,
            min: false,
            symbol: false,
            numeral: false,
            cancelled: false,
            formIsValid: false,
            form: {
                password: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: data.lblPassword,
                    rules: {
                        // minLength: 8,
                        maxLength: 50,
                        pattern: userConstants.passwordRegex,
                        patternMessage: userConstants.passwordMessage,
                        required: true,
                    },
                },
                confirmPassword: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: data.lblConfirm,
                    rules: {
                        equal: true,
                        equalMessage: data.msgBothPassword,
                        required: true,
                    },
                },
            },
            loading: false,
            logOutRoute: {},
            redirect: false
        };

        this.onBack = this.onBack.bind(this);

        this.onNext = this.onNext.bind(this);

        this.onChange = this.onChange.bind(this);

        this.onBlur = this.onBlur.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState, manageLocalState, personalDetailsState, directSignUpData } = nextProps;
        let updatedState = null;
        let tempMemberData = null;
        if(loginState && loginState.createUser){
            tempMemberData = loginState.createUser;
        } else if(directSignUpData && directSignUpData.errorCode === 0) {
            tempMemberData = directSignUpData;
        }
        if (tempMemberData) {         
            if(loginState && loginState.createUser&&loginState.createUser.errorCode === 0) {
                const { result } = loginState.createUser;
                const { vcmId, userId } = result;
                const localData = {
                    personalDetailsState:  {
                    ...personalDetailsState,
                    vcmId,
                    userId
                    }
                }; 
                manageLocalState(localData);
                updatedState = {
                    ...state,
                    logOutRoute: {
                        pathname: routeConstants.regSuccess,
                        vcmIdEnc: vcmId,
                        preReg: false,
                    },
                    redirect: true
                }
            } else if(directSignUpData && directSignUpData.errorCode === 0) {
                const messageFromApi = directSignUpData?.result?.message || directSignUpData?.message || '';
                const localData = {
                    personalDetailsState: {
                        ...personalDetailsState,
                        message: messageFromApi,
                    },
                };
                manageLocalState(localData);
                updatedState = {
                  ...state,
                  logOutRoute: {
                    pathname: routeConstants.regSuccess,
                    preReg: false,
                    message: messageFromApi,
                },
                redirect: true
                }
            } else if (tempMemberData.errorCode === 403) {
                updatedState = {
                    ...state,
                    form: {
                        ...state.form,
                        confirmPassword: { ...state.form.confirmPassword, errMsg: data.errIdExists },
                    },
                };
            } else {
                updatedState = {
                    ...state,
                    form: {
                        ...state.form,
                        confirmPassword: { ...state.form.confirmPassword, errMsg: tempMemberData.result },
                    },
                };
            }
        }
        return updatedState;
    }

    componentDidMount() {
        const { personalDetailsState, history } = this.props;
        if (!personalDetailsState || !personalDetailsState.uuid) {
            history.push(routeConstants.home);
        }
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
      }

    onChange(event, fieldId) {
        checkFormat(event, fieldId, this);
        inputChangedHandler(event, fieldId, this);
    }

    onBlur(fieldId) {
        inputValidityHandler(fieldId, this);
    }

    onBack() {
        this.setState(state => ({ ...state, cancelled: true }));
    }

    onNext() {
        const { form } = this.state;
        const { personalDetailsState, registerState, registerOnlineIdState, signUp, otpDetails, directSignUp } = this.props;
        const { uuid, signedName:eSignInitial, currentDate: eSignDate, currentDate } = personalDetailsState;
        const { functionalityFlag } = otpDetails;
        const onlineId = registerOnlineIdState.form.onlineId.value.toLowerCase();
        const passCode = form.password.value;
        (async () => {
            const password = await encrypt(passCode);
            if (functionalityFlag === data.memberSignUpFlow) {
                const { primaryNumber:primaryPhone, primaryNumberCountryCode:primaryPhoneCountryCode, emailAddress, 
                    isMobilePrimary, isMobileSecondary, role,
                    secondaryNumber: secondaryPhone,
                    secondaryNumberCountryCode:secondaryPhoneCountryCode,
                 } = otpDetails;
                const payload = {
                    onlineId,
                    password,
                    emailAddress,
                    eSignInitial,
                    eSignDate,
                    primaryPhoneCountryCode,
                    primaryPhone,
                    isMobilePrimary,
                    secondaryPhoneCountryCode,
                    secondaryPhone,
                    isMobileSecondary,
                    guid: uuid,
                    role,
                };
                directSignUp(payload)
            } else {
            signUp({
                uuid,
                [userConstants.emailAddress]: registerState.value,
                onlineId,
                password,
                eSignInitial,
                eSignDate: currentDate,
            });
         }
        })();
    }

    
  renderWStepper = () => {
    const { pageData } = this.props;
    const { stepperPage, isMemberSignUp, isProspectSignUp} = pageData;
    let stepper = '';
      if(isProspectSignUp){
        stepper = <div className="memberStepper" ><UserMngmtStepper currentPage={5} pages={stepperPage} /></div>;
      }
      if(isMemberSignUp){
        stepper = <div className="memberStepper" ><UserMngmtStepper currentPage={6} pages={stepperPage} /></div>;
      }
      return stepper;
  }

    render() {
        const { location, loginState, pageData } = this.props;
        const { jov } = location || {};
        const { form, cancelled, formIsValid, redirect, logOutRoute } = this.state;
        const { isMemberSignUp, isProspectSignUp} = pageData;

        return (
            <div>
                <WSpinner loading={loginState.isLoading} />
                {redirect && <Redirect to={logOutRoute} />}
                {(isMemberSignUp || isProspectSignUp) && this.renderWStepper()}
                <div className="container">
                {!(isMemberSignUp || isProspectSignUp) && <WStepper
                        className="userWizard"
                        currentPage={3}
                        pages={signUpPages}
                    />}
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader headStyle={data.headStyle} title={data.title} />
                            <Guidelines state={this.state} type="password" />
                        </Col>
                        <Col md lg xl>
                            <span style={assignObj({ ...styles.optionalMessage, marginBottom: 10 })}>
                                {optionalMessage}
                            </span>
                            <Password
                                passwordState={form.password}
                                changed={this.onChange}
                                blurred={this.onBlur}
                                showStrength
                            />
                            <ConfirmPassword
                                confirmPasswordState={form.confirmPassword}
                                changed={this.onChange}
                                blurred={this.onBlur}
                            />
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.onBack}
                    submit="true"
                    submitClick={this.onNext}
                    disabled={!formIsValid}
                    blockstyle={styles.commonButtonsHalf}
                    arialabelback={data.lblBackOnline}
                />
                {cancelled ? <Redirect to={jov ? routeConstants.verifyOtp : routeConstants.registerOnlineId} /> : null}
            </div>
        );
    }
}

RegisterLoginCredentials.propTypes = {
    signUp: PropTypes.func,
    manageLocalState: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    personalDetailsState: PropTypes.instanceOf(Object),
    registerState: PropTypes.instanceOf(Object),
    registerOnlineIdState: PropTypes.instanceOf(Object),
    loginState: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    pageData: PropTypes.shape({
        isMemberSignUp: PropTypes.bool,
        isProspectSignUp: PropTypes.bool,
        stepperPage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
      }),
    otpDetails: {
        functionalityFlag: PropTypes.string
    },
    directSignUp: PropTypes.func,
    directSignUpData: PropTypes.instanceOf(Object),
};

RegisterLoginCredentials.defaultProps = {
    signUp: () => {},
    manageLocalState: () => {},
    location: {},
    personalDetailsState: {},
    registerState: {},
    registerOnlineIdState: {},
    loginState: {},
    history: [],
    pageData:{
        isMemberSignUp: false,
        isProspectSignUp: false,
        stepperPage: [],
      },
    otpDetails: {
        functionalityFlag:'',
    },
    directSignUp: () => {},
    directSignUpData: {}
};

export default RegisterLoginCredentials;