import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CommonButtons, TwoSectionPageTemplate, WSpinner, WMessageBar } from 'common';
import { updateProfileContactInformations, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { clearAppState } from 'shared/Actions/LocalStateManagementActions';
import routeConstants from 'routeConstants';
import { formatDate, isValidDate, encrypt } from 'utils';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
import { PAGES, PAGE_HEADING, LINE1_DESCRIPTION } from './constants';
import ManageAddressInfo from './ManageAddressInfo';

const StyledPara = styled.p`
    opacity: 1;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494a;
`;

const backStyle = { display: 'none' };

const onCheckOptionChange = setApplyToAllAccounts => e => {
    const { target } = e;
    setApplyToAllAccounts(target.checked);
};

const handleNext = ({
    uspsVerifiedMailingAddress,
    uspsVerifiedPhysicalAddress,
    dispatch,
    profileInformation,
    applyToAllAccounts,
    mailAddrType,
    phyAddrType,
}) => () => {
    // handle next action

    const {
        firstName = '',
        lastName = '',
        ssnTin = '',
        dateOfBirth = '',
        emailInformation = {},
        phoneInformation = [],
    } = profileInformation;

    const getPrimary = data => {
        return data.find(item => item.isPrimary) || {};
    };

    const { phoneNumber = '' } = getPrimary(phoneInformation);
    const { primaryEmail: emailAddress = '' } = emailInformation;

    const {
        addressLine1: mailAddr1 = '',
        addressLine2: mailAddr2 = '',
        city: mailCity = '',
        state: mailState = '',
        zip: mailZip = '',
    } = uspsVerifiedMailingAddress || {};
    const {
        addressLine1: phyAddr1 = '',
        addressLine2: phyAddr2 = '',
        city: phyCity = '',
        state: phyState = '',
        zip: phyZip = '',
    } = uspsVerifiedPhysicalAddress || {};
    (async () => {
        const payload = {
            firstTimeLogin: true,
            firstName,
            lastName,
            phoneNumber,
            ssnTin,
            dateOfBirth: isValidDate(dateOfBirth) ? formatDate(dateOfBirth, 'MM-DD-YYYY') : dateOfBirth,
            emailAddress,
            applyToAllAccounts,
            addressUpdate: [
                {
                    addressLine1: mailAddr1,
                    addressLine2: mailAddr2,
                    city: mailCity,
                    state: mailState,
                    zip: mailZip,
                    isPhysicalAddress: false,
                    isMailingAddress: true,
                    addressType: mailAddrType,
                },
                {
                    addressLine1: phyAddr1,
                    addressLine2: phyAddr2,
                    city: phyCity,
                    state: phyState,
                    zip: phyZip,
                    isPhysicalAddress: true,
                    isMailingAddress: false,
                    addressType: phyAddrType,
                },
            ],
        };
        if(payload.dateOfBirth){
            const encryptedDOB = await encrypt(payload.dateOfBirth);
            payload.dateOfBirth = encryptedDOB;
        }
        if(payload.ssnTin){
            const encryptedSsnTIN = await encrypt(payload.ssnTin);
            payload.ssnTin = encryptedSsnTIN;
        }

        dispatch(updateProfileContactInformations(payload));
    })();
};

// First Time Login - FTL
const FTLManageAddress = () => {
    const [notification, setNotification] = React.useState(false);
    const [isErrorNotification, setIsErrorNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState(null);

    const dispatch = useDispatch();

    const [applyToAllAccounts, setApplyToAllAccounts] = useState(false);

    const profileInformationData = useSelector(({ profileInformationData: profileInfo = {} }) => profileInfo);

    const newAddressInfo = useSelector(({ addressFormatData: addressData = {} }) => addressData);
    const masterLookupStateData = useSelector(({ masterLookUpData: metaData = {} }) => metaData);

    const {
        isLoading: profileLoading,
        mailingAddress,
        physicalAddress,
        showUSPSVerifiedAddress,
        profileInformation,
        isAccountLocked,
        isSuccess,
        isError,
        isFTLSuccess,
    } = profileInformationData;

    const { isLoading: newAddressInfoLoading } = newAddressInfo;

    const { isLoading: metaDataLoading } = masterLookupStateData;

    const { uspsVerifiedAddress: uspsVerifiedMailingAddress = null, addressType: mailAddrType = '' } =
        mailingAddress || {};
    const { uspsVerifiedAddress: uspsVerifiedPhysicalAddress = null, addressType: phyAddrType = '' } =
        physicalAddress || {};

    const isLoading = profileLoading || newAddressInfoLoading || metaDataLoading;

    const history = useHistory();

    React.useEffect(() => {
        if (isFTLSuccess) {
            dispatch(clearExistingActions());
            localStorage.setItem('isAddressUpdated', true);
            history.push(routeConstants.accountholder);
        }
    }, [dispatch, isFTLSuccess, history]);

    React.useEffect(() => {
        if (isAccountLocked) {
            localStorage.clear();
            dispatch(clearAppState());
            localStorage.setItem('isAddressUpdated', false);
            history.push({
                pathname: routeConstants.signIn,
                failure: true,
                message: userConstants.blockedMessage,
            });
        }
    }, [dispatch, isAccountLocked, history]);

    React.useEffect(() => {
        if (isError) {
            setNotification(true);
            setIsErrorNotification(true);
            setNotificationMsg({ message: null });
        }
        if (isError || isSuccess) dispatch(clearExistingActions());
    }, [dispatch, isError, isSuccess]);

    const currentPageIndex = 4;

    const pageDescription = <StyledPara>{LINE1_DESCRIPTION}</StyledPara>;

    const pageInformation = <ManageAddressInfo onCheckOptionChange={onCheckOptionChange(setApplyToAllAccounts)} />;

    const disabled = !(showUSPSVerifiedAddress && !!uspsVerifiedMailingAddress && !!uspsVerifiedPhysicalAddress);

    const onNotificationClose = React.useCallback(() => {
        setNotification(false);
    }, [setNotification]);

    return (
        <>
            {isLoading && <WSpinner loading={isLoading} />}
            {notification && (
                <WMessageBar
                    error={isErrorNotification}
                    onClose={onNotificationClose}
                    text={notificationMsg}
                    aria-live="polite"
                />
            )}
            <TwoSectionPageTemplate
                headingTxt={PAGE_HEADING}
                pageDescription={pageDescription}
                pageDetails={PAGES}
                pageInformation={pageInformation}
                currentPageIndex={currentPageIndex}
                mdForSectionOne={4}
            />
            <CommonButtons
                disabled={disabled}
                nextText="Submit"
                nextClick={handleNext({
                    uspsVerifiedMailingAddress,
                    uspsVerifiedPhysicalAddress,
                    dispatch,
                    profileInformation,
                    applyToAllAccounts,
                    mailAddrType,
                    phyAddrType,
                })}
                backstyle={backStyle}
            />
        </>
    );
};

export default FTLManageAddress;
