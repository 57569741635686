const components = {
    input: {
        display: 'block',
        width: '100%',
        p: 2,
        appearance: 'none',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        border: 'thin',
        borderRadius: 4,
        color: 'inherit',
        bg: 'background',
    },
    button: {
        borderRadius: 0,
        border: 'none',
        textAlign: 'center',
        height: 'buttonHeight',
        width: 'buttonWidth',
        display: 'inline-block',
        px: '.5rem',
        py: '.25rem',
        cursor: 'pointer',
        opacity: 1,
    },
    label: {
        width: '100%',
        display: 'flex',
    },
    pageHeading: {
        fontSize: 'large',
        float: 'left',
        color: 'blues.0',
        borderBottom: 'headingUnderline',
        fontWeight: '7',
        fontFamily: 'yorktenSlab',
        pb: '10px',
    },
    vcmBtnDisabled: {
        bg: 'greys.0',
        color: 'whites.1',
        cursor: 'default',
    },
    vcmBtn: {
        bg: 'blues.1',
        color: 'white',
        position: 'relative',
    },
};

export default components;
