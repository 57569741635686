
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";

const getBeneficiariesMasterRegs = (payload) => (dispatch) => {
    const env = '';
    dispatch({
        type: ActionTypes.GET_BENEFICIARIES_MASTER_REGS_LOADING,
    });
    makeApiRequest(
        ActionTypes.GET_BENEFICIARIES_MASTER_REGS,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_BENEFICIARIES_MASTER_REGS_SUCCESS,
                    payload: {masterRegs: response.data.result }
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_BENEFICIARIES_MASTER_REGS_FAILURE,
                });
            }
        },
        error => {
            // console.log(error);
            dispatch({
                type: ActionTypes.GET_BENEFICIARIES_MASTER_REGS_FAILURE,
                payload: error
            });
        }
    );
};

export default getBeneficiariesMasterRegs;

