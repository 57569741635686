import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    funds: [
        {
            "fundName": "USAA Aggressive Growth fund",
            "fundSymbol": "USAUX",
            "navValue": "42.20",
            "navDate": "10/31/2019",
            "navPercentage": "0.XX%",
            "navYesterdayValue": "30",
            "currentValue": "108,851.70",
            "totalCost": "107,766",
            "totalShares": "2560",
            "availableShares": "2000",
            "overallGainLoss": "$1085.00",
            "costBasis": "ACB"
        },
        {
            "fundName": "USAA Science & Technology Adviser",
            "fundSymbol": "USTCX",
            "navValue": "40",
            "navDate": "10/31/2019",
            "navPercentage": "0.XX%",
            "navYesterdayValue": "40",
            "currentValue": "108,851.70",
            "totalCost": "107,766",
            "totalShares": "2560",
            "availableShares": "2000",
            "overallGainLoss": "$1085.00",
            "costBasis": "ACB"
        },
        {
            "fundName": "USAA Growth & Income Adviser",
            "fundSymbol": "USGIX",
            "navValue": "20",
            "navDate": "10/31/2019",
            "navPercentage": "0.XX%",
            "navYesterdayValue": "40",
            "currentValue": "108,851.70",
            "totalCost": "107,766",
            "totalShares": "2560",
            "availableShares": "2000",
            "overallGainLoss": "$1011.00",
            "costBasis": "ACB"

        },
        {
            "fundName": "USAA Intermediate-Term Bond Adviser",
            "fundSymbol": "UITBX",
            "navValue": "70",
            "navDate": "10/31/2019",
            "navPercentage": "0.XX%",
            "navYesterdayValue": "40",
            "currentValue": "108,851.70",
            "totalCost": "107,766",
            "totalShares": "2560",
            "availableShares": "2000",
            "overallGainLoss": "$1085.00",
            "costBasis": "ACB"

        },
        {
            "fundName": "USAA Short-Term Bond Adviser",
            "fundSymbol": "UASBX",
            "navValue": "55",
            "navDate": "10/31/2019",
            "navPercentage": "0.XX%",
            "navYesterdayValue": "40",
            "currentValue": "$108,851.70",
            "totalCost": "$107,766",
            "totalShares": "2560",
            "availableShares": "2000",
            "overallGainLoss": "$1085.00",
            "costBasis": "ACB"
        }
    ]
};

function accPositionsReducer(state = initialState, action) {
    // debugger
    switch (action.type) {
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action,
            };
        }
        default:
            return state;
    }
}

export default accPositionsReducer;