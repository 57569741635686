import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { liveChatActions } from '../../../../shared/Actions';
import ContactCenterWidget from './ContactCenterWidget';

// export const mapStateToProps = (state) => ({
//     waitTimeInfo: state.liveChatReducerData,
//     operationalHoursInfo: state.liveChatReducerData.schedules,
// });

export const mapStateToProps = (state) => ({
    waitTimeInfo: state.liveChatReducerData,
    operationalHoursInfo: state.liveChatReducerData.schedules,
    isPhoneSupportAvailable: state.liveChatReducerData.isPhoneSupportAvailable,
});

const mapDispatchToProps = {
    ...liveChatActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactCenterWidget));
