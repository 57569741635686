import React from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import WIcon from '../WIcon/WIcon';
import styles from '../CommonStyles';

const StyleTooltip = styled(Tooltip)`
    .bs-tooltip-auto[x-placement^='bottom'] .arrow,
    .bs-tooltip-bottom .arrow {
        top: 5px;
    }
    .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
        border-bottom-color: transparent;
        border-width: 0;
        border-top: 1px solid #dededf;
        border-left: 1px solid #dededf;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        z-index: 1;
        box-shadow: -2px -2px 25px #0000001a;
        background-color: white;
    }
    .tooltip-inner {
        max-width: 266px;
        padding: 10px 40px 10px 30px;
        color: #212529;
        background-color: #fffefe;
        border: 1px solid #dededf;
        box-shadow: 0px 0px 25px #0000001a;
        border-radius: 0;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        &::before {
            content: '';
            width: 0px;
            height: 0px;
            border-bottom: 25px solid transparent;
            border-left: 25px solid rgba(139, 193, 5, 1);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .tooltip.show {
        opacity: 1;
    }
`;

const StyledToolTip = props => {
    const { placement, tooltipOpen, targetID, triggerEvt, tooltipText, handleToggle, closeTooltip, autohide } = props;
    return (
        <StyleTooltip
            // tabIndex="0"
            placement={placement}
            isOpen={tooltipOpen}
            target={targetID}
            toggle={handleToggle}
            autohide={autohide}
            trigger={triggerEvt}
            // aria-label={tooltipText}
            // role="tooltip"
        >
            <span>{tooltipText}</span>
            {!autohide && (
                <WIcon
                    icon={faTimes}
                    fontawesomestyle={styles.tooltipFontawesomestyle}
                    onClick={closeTooltip}
                    size="1x"
                />
            )}
        </StyleTooltip>
    );
};

StyledToolTip.propTypes = {
    placement: PropTypes.string,
    tooltipOpen: PropTypes.bool,
    autohide: PropTypes.bool,
    targetID: PropTypes.string,
    triggerEvt: PropTypes.string,
    tooltipText: PropTypes.string,
    handleToggle: PropTypes.func,
    closeTooltip: PropTypes.func,
};

StyledToolTip.defaultProps = {
    placement: 'bottom',
    triggerEvt: 'focus',
    tooltipText: '',
    targetID: '',
    autohide: false,
    tooltipOpen: false,
    handleToggle: () => {},
    closeTooltip: () => {},
};

export default StyledToolTip;

/* 
    <StyledToolTip 
        placement="bottom"
        tooltipOpen={tooltipOpen}
        targetID="infoIcon"
        handleToggle={this.toggleToolTip}
        autohide={false}
        triggerEvt="focus"
        tooltipText={field.hintText}
        closeTooltip={this.closeTooltip}
    />  
*/
