/**
 * constants for Mock Web Api response data
 * ----------------------------------------
 * 
 * @version 1.0.1
 * @author Manjunath V
 * @description This page contains the mock web api data which in turns to bind the data dynamically
 * @createdDate [06/11/2019]
 * @lastModifiedDate [20/11/2019]
 * @lastModifiedReason Updated card dummy text
 */


const cardData = [
    {
        cardId: 1,
        cardTitleText: "Change Sign In Credentials",
        cardLink: "reset-password",
        //  cardSubtitleText: "Manage your security preferences Sample Content"
    },
    {
        cardId: 2,
        cardTitleText: "Register This Device",
        cardLink: {pathname:'register-device', search:'?flow=securityPref'},
        //  cardSubtitleText: "Manage your security preferences Sample Content"
    },
    {
        cardId: 3,
        cardTitleText: "Manage Your Devices",
        cardLink: {pathname:'deregister-device'},
        //  cardSubtitleText: "Manage your security preferences Sample Content"
    }
    // {
    //     cardId: 2,
    //     cardTitleText: "Modify Security Questions",
    //     cardLink: "modify-security-qns",
    //     //  cardSubtitleText: "Manage your security preferences Sample Content"
    // },
    // {
    //     cardId: 3,
    //     cardTitleText: "Choose Sign In Methods",
    //     cardLink: "ChangeSignInMethodOptions",
    //     //  cardSubtitleText: "Manage your security preferences Sample Content"
    // }
    // ,
    //  {
    //     cardId: 4,
    //     cardTitleText: "Mobile Quick Sign In",
    //     cardLink: "mobile-quick-sign-in",
    //     // cardSubtitleText: "Manage your security preferences Sample Content"
    // } 
]


export default cardData;