 const PERSONAL_INFO = 'Personal Information';
 const NAME = 'Name';
 const PREFIX = 'Salutation';
 const SUFFIX = 'Name Suffix';
 const VCM_ID = 'VCM ID';
 const MEMBER_ID = 'Member ID';
 const SOCIAL_SECURITY_NUMBER = 'Social Security Number';
 const DOB = 'Date of birth';
 const GENDER = 'Gender';
 const MARITAL_STATUS = 'Marital Status';
 const CITIZENSHIP = 'Citizenship';
 const COUNTRY = 'Country';
 const PHONE_HINT = "Dial";
 
 const ACTION_LABEL = 'Manage personal information';
 const ACTION_TEXT = 'Manage';
 
 const BREADCRUMB_VALUE = [
    {   
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managepersonalinfo_breadcrumb_profile'
    },
    {
        url: '/basicinformation',
        name: 'Basic Information',
        dataAnalyticId:'cms_basicinformation_managepersonalinfo_breadcrumb_basicinfo'
    },
];
const RELATIONSHIP_BREADCRUMB_VALUE = [
    { 
        url: '/Profile', 
        name: 'Profile', 
        dataAnalyticId:'cms_basicinformation_managerelationshipinfo_breadcrumb_profile' 
    },
    {
        url: '/basicinformation',
        name: 'Basic Information',
        dataAnalyticId:'cms_basicinformation_managerelationshipinfo_breadcrumb_basicinfo'
    },
    {
        url: '/ManageRelations',
        name: 'Manage Relationship',
        dataAnalyticId:'cms_basicinformation_managerelationshipinfo_breadcrumb_managerelationship'
    },
];
const PAGE_NAME = 'Manage Personal Information';
const RELATIONSHIP_PAGE_NAME = 'Manage Relationship Information';
const RELATIOSHIP_MAIN_PAGE_NAME = 'Manage Relationship';
const NOTE_TEXT_2 = 'For security reasons, not all the information can be updated online.';

const NAME_NOTE_TEXT1 = 'For security reasons, not all the information can be updated online. If you need to change your Name, SSN, Date of Birth, or Citizenship, please call Victory Member Services at ';
const RELATIONSHIP_NOTE_TEXT =
    'For security reasons, not all the information can be updated online. If you need to change your Name, Date of Birth, or Gender, please call Victory Member Services at ';
const MSR_NUMBER = '+1 (800) 235-8396';
const CITIZENSHIP_NOTE_TEXT = "If you need to change your Citizenship please call Victory Member Services at ";
const COUNTRY_ERROR = "Please select a country";
const GENDER_ERROR = "Please select a gender";
const MARITAL_STATUS_ERROR = "Please select a marital status";
const NONEONFILE = 'None on file';
const FAMILY_RELATION = 'Family Member\'s relationship to you';
const PROOF_OF_CITIZEN = 'Proof of Citizenship or Residency';
const PASSPORT_NUMBER = 'Passport Number';
const PASSPORT_AUTHORITY = 'Passport Issuing Authority';
const RESIDENT_CARD_NUMBER = 'Permanent Resident Card Number';
const PASSPORT_EXPIRATION_DATE= 'Expiration Date';
const ADDED_PERSON_HAVE_SSN = 'Does the person you are adding have a Social Security number?';
const ADDED_PERSON_HAVE_SSN_DATA = [{value: 'Yes',key:'Yes'},{value:'No',key:'No'}];
const SSN = 'Social Security Number';
const SAVE = "Save";
const CANCEL = "Cancel";
const INVALID_DATE_ERROR = "Invalid Date";
 
 
  export default {
    PERSONAL_INFO,
    NAME,
    PREFIX,
    SUFFIX,
    VCM_ID,
    MEMBER_ID,
    SOCIAL_SECURITY_NUMBER,
    DOB,
    GENDER,
    MARITAL_STATUS,
    CITIZENSHIP,
    COUNTRY,
    ACTION_LABEL,
    ACTION_TEXT,
    BREADCRUMB_VALUE,
    PAGE_NAME,
    NOTE_TEXT_2,
    NAME_NOTE_TEXT1,
    RELATIONSHIP_NOTE_TEXT,
    MSR_NUMBER,
    CITIZENSHIP_NOTE_TEXT,
    COUNTRY_ERROR,
    GENDER_ERROR,
    MARITAL_STATUS_ERROR,
    NONEONFILE,
    RELATIONSHIP_PAGE_NAME,
    RELATIONSHIP_BREADCRUMB_VALUE,
    RELATIOSHIP_MAIN_PAGE_NAME,
    FAMILY_RELATION,
    PROOF_OF_CITIZEN,
    PASSPORT_NUMBER,
    PASSPORT_AUTHORITY,
    RESIDENT_CARD_NUMBER,
    PASSPORT_EXPIRATION_DATE,
    ADDED_PERSON_HAVE_SSN,
    ADDED_PERSON_HAVE_SSN_DATA,
    SSN,
    PHONE_HINT,
    SAVE,
	CANCEL,
	INVALID_DATE_ERROR
  };