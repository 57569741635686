import * as TransactionConstants from '../TransactionConstants';
import ConfirmationComp from '../Common/ConfirmationComponent';
import { connect } from 'react-redux';
import EConsent from '../Common/EConsent/EConsentComponent';
import EConsentData from '../../../common/EConsent/EConsentData';
import { encrypt, getToken } from 'utils';
import ErrorBoundary from '../Common/ErrorBoundary/errorboundary';
import FundSourceComp from './FundSource/FundSourceComponent';
import FundSummary from '../Common/FundSummary';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import styles from './styles';
import { WBreadCrumb, PageHeading, WSpinner, ConformationModal, ConfirmModal, InfoCardButton } from '../../../common';
import { ConfirmationMessage } from 'modules/CustomerManagementModule/components';
import WStepper from '../../../common/WStepper/WStepper';
import WStepperMobile from '../../../common/WStepper/WStepperMobile';

import {
    purchaseActions,
    fundAccountActions,
    accOpeningActions,
    bankAccountAction,
    masterRegistrationAccountActions,
    transactionCompositeDataAction,
    tradingWindowActions,
    loginActions,
    transactionHistoryActions,
    logoutRouteActions,
    analytics,
} from '../../../shared/Actions';

import PurchasesComp from './PurchaseSelection/Purchases';

import InvestmentSelectionComp from './InvestmentSelection/InvestmentSelectionComponent';

import ReviewConfirmComp from './ReviewConfirm/PurchaseReviewComponent';

import WBlueBanner from '../../../common/WBlueBanner/WBlueBanner';
import StopCodeAlert from '../../AccountDashboardModule/Common/StopCodeAlert/StopCodeAlert';
import Authenticate from 'common/Authenticate/Authenticate';
import {isTokenAvailable } from 'common/Authenticate/session';
import { getDate, getMemberRoleFlag } from 'utils/utils';
import { getValidValue, getValidValueOf } from 'commonHelper/CommonHelperFunctions';

// Stepper -> UI workflow steps 1,2,3
const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

const Stepper = styled(WStepper)`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const primaryBtnText = 'Open/Transfer an Account';
const nextBtnText = 'Next';
const disabledFooterNxt = true;
const displayFooterBtns = true;

// List of pages in the workflow. The names should be moved to constant file
const pages = ['Account Selection', 'Investment Selection', 'Funding Source', 'Review & Confirm', 'Prospectus Review'];

const subsequentPurPages = ['Account Selection', 'Investment Selection', 'Funding Source', 'Review & Confirm'];
const breadCrumURL = [
    { url: '/accountholder', name: 'Dashboard' },
    { url: '/accountholder/transactions', name: 'Portfolio' },
];

/**
 * PurchaseContainer component. Based on the pageIndex the relevant components are
 * invoked in the workflow.
 *
 */
class PurchaseContainer extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            pageIndex: 0,
            childrenState: [],
            displayNotification: false,
            notificationMsg: {},
            iraContributionWarning: '',
            isIRAContributionAccepted: true,
            setMasterRegAccount: false,
            showCancelPurchaseModal: false,
            cancelPurchase: false,
            showMasterRegForeignAddressModal: false,
            addressValid: true,
            noAccountsToDisplay: false,
            showAuthBox: false,
            displayFailedPropotinateFundListArray:[],
            pruchaseSubmitAPiCount:0
        };
        this.editSection = null;
        this.bottomNav1 = React.createRef();

        const { getTradingClosureWindow, tradingWindowData } = this.props;
        if (!tradingWindowData.fromDashboard) {
            getTradingClosureWindow({ token: getToken() });
        }
    }

    static getFailedPropotinateFundList = (isProportinateSelected,proportianteSelectedFundsList,proportinateFundPassedList) =>
    {
        let displayFailedPropotinateFundList = [];
        if(isProportinateSelected)
        {
            for(let i=0;i<proportianteSelectedFundsList?.length;i++)
            {
                const filterObj = proportinateFundPassedList?.filter((item)=> item == proportianteSelectedFundsList[i].fundAccountNumber);
                if(filterObj?.length == 0)
                {
                    displayFailedPropotinateFundList.push(proportianteSelectedFundsList[i])
                }
            }

        }
        return displayFailedPropotinateFundList;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { purchaseReducerData, masterRegistrationAccountData, location } = nextProps;
        const {pageIndex} = prevState;
        let setMasterRegAccount = false;
        if (
            location.state &&
            location.state.pageIndex !== prevState.pageIndex &&
            masterRegistrationAccountData.masterRegAccountList.length > 0
        ) {
            setMasterRegAccount = true;
        }
        let displayFailedPropotinateFundListArray =[];
        let pruchaseSubmitAPiCount = 0;
        if(pageIndex == 5 && purchaseReducerData?.page1?.isProportinateSelected && purchaseReducerData?.page1?.proportianteSelectedFundsList?.length > 0)
        {
                displayFailedPropotinateFundListArray = PurchaseContainer.getFailedPropotinateFundList(purchaseReducerData?.page1?.isProportinateSelected,
                purchaseReducerData?.page1?.proportianteSelectedFundsList,purchaseReducerData?.proportinateFundPassedList);
                pruchaseSubmitAPiCount = purchaseReducerData?.pruchaseSubmitAPiCount;
        }
        return {
            ...prevState,
            displayFailedPropotinateFundListArray:displayFailedPropotinateFundListArray,
            pruchaseSubmitAPiCount:pruchaseSubmitAPiCount,
            iraContributionWarning: purchaseReducerData.iraContributionWarning,
            isIRAContributionAccepted: purchaseReducerData.isIRAContributionAccepted,
            showCancelPurchaseModal: purchaseReducerData.showCancelPurchaseModal,
            isLoading: purchaseReducerData.isLoading,
            setMasterRegAccount
        };
    }

    componentDidMount() {
        const { setMasterRegAccount } = this.state;
        const { location, masterRegistrationAccountData, getCustomerProfile } = this.props;
        sessionStorage.setItem('isRefresh', true);
        if (setMasterRegAccount && masterRegistrationAccountData.masterRegAccountList && location.state.masterId) {
            const accountData = this.accountClickHandler(
                masterRegistrationAccountData.masterRegAccountList,
                location.state.masterId,
            );

            if (accountData.accountSelected !== null) {
                this.onSwitchComponent(true, accountData, 0)();
            }
        }

        const payLoad = {
            payloadData: { loginLatitude: '', loginLongitude: '', deviceId: '' },
        };
        getCustomerProfile(payLoad);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
        });
    }

    componentWillUnmount() {
        const {
            resetPageNumber,
            clearPurchaseData,
            clearBankAccDetails,
            clearMasterRegInfo,
            clearFundAccountList,
        } = this.props;
        sessionStorage.setItem('isRefresh', false);
        resetPageNumber();
        clearPurchaseData();
        clearBankAccDetails();
        clearMasterRegInfo();
        clearFundAccountList();
    }


    // This Function will mimic the click from View Positions
    accountClickHandler = (data, masterId) => {
        const accountSelectedType = {};
        const displayData = [];
        let accountSelected = null;

        const allAccounts = data.map(item => {
            const accType = { ...item };
            const { accounts } = accType;
            accType.accounts = accounts.map(eachacc => {
                const acc = { ...eachacc };
                if (Number(masterId) === Number(eachacc.accountNumber)) {
                    acc.checked = !acc.checked;
                } else {
                    acc.checked = false;
                }
                if (acc.checked) {
                    accountSelected = acc;
                    const accountNameDisplay =
                        !acc.accountName || acc.accountName.trim() === ''
                            ? `${!acc.accountType ? '' : acc.accountType} Account ${acc.accountNumber}`
                            : acc.accountName;
                    displayData.push(
                        {
                            field: TransactionConstants.ACCOUNT_NAME_TEXT,
                            value: accountNameDisplay,
                        },
                        {
                            field: TransactionConstants.ACCOUNT_NUMBER_TEXT,
                            value: acc.accountNumber,
                        },
                        {
                            field: TransactionConstants.ACCOUNT_TYPE_TEXT,
                            value: acc.accountType,
                        },
                    );
                    accountSelectedType.holdingGroup = eachacc.holdingGroupName;
                }
                return acc;
            });
            return accType;
        });

        return {
            accountsAvailable: allAccounts,
            accountSelected,
            displayDetails: {
                accountSelection: {
                    data: displayData,
                },
            },
            accountSelectedType,
        };
    };

    getTradingInformation = dateString => {
        const dateVal = new Date(dateString);
        let month = '';
        let year = '';
        let day = '';
        let hour = '';
        let minutes = '';
        if (dateString) {
            month = dateVal.getUTCMonth();
            day = dateVal.getUTCDate();
            year = dateVal.getUTCFullYear();
            hour = dateVal.getUTCHours();
            minutes = dateVal.getUTCMinutes();
        }

        return {
            month,
            year,
            day,
            hour,
            minutes,
        };
    };

    setTradingWarning = () => {
        const { tradingWindowData, setTradingWindowWarning } = this.props;
        const currentLocalTimeStr = new Date().toUTCString();
        const currentTime = Date.parse(currentLocalTimeStr);
        const {
            warningSpan,
            marketEndTime,
            marketStartTime,
            canTradeToday,
            tradingStartTimeEST,
            tradingEndTimeEST,
            tradingActualEndTimeEST,
            tradingDayHour,
            tradingEndTime,
        } = tradingWindowData;
        let { nextBusinessDay } = tradingWindowData;
        const warningDiff = marketEndTime - currentTime;
        const marketOpenDiff = marketStartTime - currentTime;
        const marketClosureDiff = marketEndTime - currentTime;
        let warningMessage = '';

        const effectiveDate = canTradeToday ? tradingEndTime : nextBusinessDay;

        const { day: currentLocalDay } = this.getTradingInformation(currentLocalTimeStr);
        const { day: marketEndDay } = this.getTradingInformation(tradingEndTime);
        if (canTradeToday && marketEndTime !== '') {
            if (currentLocalDay !== marketEndDay) {
                // if the user is in a different time zone and have the local date as next day
                if (canTradeToday && tradingDayHour) {
                    // During market hours
                    const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                    nextBusinessDay = `${month + 1}/${day}/${year}`;
                    warningMessage = '';
                    if (marketClosureDiff < warningSpan) {
                        // 30 mins warning message
                        warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                    }
                } else if (marketOpenDiff > 0 && !tradingDayHour) {
                    // Before market hours
                    const { month, day, year } = this.getTradingInformation(new Date(marketStartTime).toUTCString());
                    nextBusinessDay = `${month + 1}/${day}/${year}`;
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
                } else {
                    // After market hours
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                }
                setTradingWindowWarning(false, warningMessage, nextBusinessDay);
            } else if (marketOpenDiff > 0) {
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open from ${tradingStartTimeEST} until the market closes at ${tradingActualEndTimeEST}. You are placing an order during market close and will receive the Net Asset Value of today.`;
                setTradingWindowWarning(false, warningMessage, effectiveDate);
            } else if (marketClosureDiff < 0) {
                warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
                setTradingWindowWarning(false, warningMessage, nextBusinessDay);
            } else if (marketOpenDiff < 0 && marketClosureDiff > 0) {
                if (warningDiff < warningSpan) {
                    warningMessage = `Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at ${tradingActualEndTimeEST}. You must place your order prior to ${tradingEndTimeEST} to be sure your order gets today’s Net Asset Value.`;
                    setTradingWindowWarning(false, warningMessage, effectiveDate);
                } else {
                    setTradingWindowWarning(true, '', effectiveDate);
                }
            }
        } else {
            warningMessage = `Market Hours for Mutual Funds: You are placing an order after market close and will receive the Net Asset Value of next business day ${nextBusinessDay}`;
            setTradingWindowWarning(false, warningMessage, nextBusinessDay);
        }
    };

    /**
     * This funciton sets the state object in the childrenState array
     * where the array Index maps to the page index.
     *
     *  @data - Holds the state object
     *  @index - The page index
     *
     */
    saveChildrenState = (data, index) => {
        const { resetPageNumber } = this.props;
        this.setState(prevState => {
            let { childrenState } = prevState;
            if (
                index === 0 &&
                data.accountSelected &&
                childrenState.length &&
                data.accountSelected.accountNumber !== childrenState[0].accountSelected.accountNumber
            ) {
                childrenState = [];
                resetPageNumber();
            }

            if (index === 1) {
                childrenState.length = 1;
            }

            childrenState[+index] = data;
            return {
                childrenState,
            };
        });
    };

    /**
     * This function populates the notificationMsg if there
     * is any error encountered in the child component.
     *
     * @bool - True (has error) False (has no error)
     */
    handleError = bool => {
        if (bool) {
            this.setState({
                displayNotification: true,
                notificationMsg: { message: TransactionConstants.NOTIFICATION_ERROR_MESSAGE },
            });
        }
    };

    /**
     * This component is called when 'Next' or 'back' is clicked. It sets the relevant
     * page index.
     *
     * @bool - (True - for next page) (False - for prev page)
     */
    onSwitchComponent = (bool, stateData = {}, pageIndex = '') => () => {
        const { savePageState } = this.props;

        let validMasterRegAddress = true;
        if (pageIndex === 0 && bool) {
            validMasterRegAddress = !(
                stateData &&
                stateData.accountSelected &&
                stateData.accountSelected.isForeignAddress === 'Y'
            );
            this.setState({
                showMasterRegForeignAddressModal: !validMasterRegAddress,
                addressValid: validMasterRegAddress,
            });
        }
        if (validMasterRegAddress) {
            if (bool) {
                this.saveChildrenState(stateData, pageIndex);
                savePageState({ data: stateData, pageIndex });
            }

            if (!bool && pageIndex === 2 && this.props.purchaseReducerData != undefined) {
                const { clearBankAccDetails } = this.props;
                if (this.props.purchaseReducerData.page2 == undefined) {
                    clearBankAccDetails();
                    this.saveChildrenState(stateData, 2);
                    savePageState({ data: stateData, pageIndex: 2 });
                }
            }

            if (pageIndex === 21) {
                // Handle fund Summary
                this.setState(prevState => {
                    return {
                        pageIndex: 21,
                        displayNotification: false,
                        notificationMsg: {},
                        prevPageIndex: prevState.pageIndex,
                    };
                });
            } else {
                this.setState(prevState => {
                    // Handle fund Summary
                    if (prevState.pageIndex === 21 && pageIndex !== '') {
                        return {
                            pageIndex: 1,
                            displayNotification: false,
                            notificationMsg: {},
                            masterRegSetStage: true,
                        };
                    }
                    return {
                        pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
                        displayNotification: false,
                        notificationMsg: {},
                        masterRegSetStage: true,
                    };
                });
            }
        }
    };

    cancelForeignAddressWarningMessage = () => {
        this.setState({ showMasterRegForeignAddressModal: false });
    };

    handleIRAContribution = (e, stateValue) => {
        let totalAmt = 0;
        e.stopPropagation();
        const { getIRAContributionValues } = this.props;

        const IRAContributionYear = stateValue && stateValue.IRAContributionYear;
        
        const selectedDate = getDate(stateValue?.startDatee);  
        
        let year = new Date().getFullYear();
        if (IRAContributionYear === '1') {
            year = new Date().getFullYear() - 1;
        }

        if (Number.isNaN(stateValue.monthlyAmount)) {
            totalAmt = stateValue.totalSubAmt;
        } 
        else if(selectedDate?.year !=  new Date().getFullYear()){
            totalAmt= stateValue?.totalSubAmt;
        }
        else {
            totalAmt = stateValue?.totalSubAmt + stateValue?.monthlyAmount;
        }

        const IRAContributionPayload = {
            companyNumber: '591',
            year,
            amount: totalAmt,
            accountType: stateValue.accountType.trim(),
            masterReg: stateValue.accountNumber,
            accountTypeCode: stateValue.accountTypeCode,
            socialCode: stateValue.socialCode,
            accountSource: 'FIS',
            isMember: getMemberRoleFlag(),
        };

        const validateIRAPayloadObj = {
            IRAContributionPayload,
            stateValue,
            token: getToken(),
            isVersion2ApiUrl: true,
        };
        getIRAContributionValues(validateIRAPayloadObj);
    };

    handleOk = () => {
        const { purchaseReducerData, acceptIRAContributionWarning } = this.props;
        this.onSwitchComponent(true, purchaseReducerData.fundSourceData, 2)();
        acceptIRAContributionWarning();
    };

    handleCancel = () => {
        const { cancelIRAContributionWarning } = this.props;
        cancelIRAContributionWarning();
    };

    handleCancelPurchase = () => {
        const { clearPurchaseData } = this.props;
        this.setState({ cancelPurchase: true });
        clearPurchaseData();
    };

    handleDoNotCancelPurchase = () => {
        const { setNotToCancelPurchase } = this.props;
        setNotToCancelPurchase();
    };

    /**
     * Navigates to home page and show the success message in message bar.
     */
    navToNextPage = () => () => {
        this.setState({
            pageIndex: 5,
            childrenState: [],
        });
    };

    analyzeClick = e => {
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
        });
    };

    scrollToSection = (index, section) => e => {
        this.analyzeClick(e);
        this.editSection = section;
        this.setState(
            () => {
                return {
                    pageIndex: index,
                };
            },
            () => {
                this.editSection = null;
            },
        );
    };

    isIRA = () => {
        const { purchaseReducerData } = this.props;
        const { accountType } = purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected;

        const trimAccountType = accountType.trim();

        return (
            trimAccountType === TransactionConstants.TRADITIONAL_IRA ||
            trimAccountType.toLowerCase().includes(TransactionConstants.ROTH) ||
            trimAccountType === TransactionConstants.ROTH_IRA ||
            trimAccountType === TransactionConstants.SEP_IRA ||
            trimAccountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
            trimAccountType === TransactionConstants.ROLLOVER_IRA ||
            trimAccountType === TransactionConstants.GUARDIAN_IRA ||
            trimAccountType === TransactionConstants.SIMPLE_IRA ||
            trimAccountType === TransactionConstants.BENEFICIARY_IRA ||
            trimAccountType === TransactionConstants.OPTIONAL_RETIREMENT_IRA ||
            trimAccountType === TransactionConstants.PENSION_IRA ||
            trimAccountType === TransactionConstants.SIMPLE_IRA_DFI ||
            trimAccountType === TransactionConstants.SIMPLE_IRA_NON_DFI ||
            trimAccountType === TransactionConstants.ROTH_CONVERSION_IRA
        );
    };

    getIsNotCoverdellIRA = () => {
        const { purchaseReducerData } = this.props;
        const { accountType } = purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected;
        return !!(
            accountType === TransactionConstants.TRADITIONAL_IRA ||
            accountType === TransactionConstants.ROTH_IRA ||
            accountType.trim() === TransactionConstants.ROTH_CONVERSION_IRA ||
            accountType.trim() === TransactionConstants.ROLLOVER_IRA
        );
    };

    getIsCoverdellIRA = () => {
        const { purchaseReducerData } = this.props;
        const { accountType } = purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected;
        return (
            accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
            accountType.trim() === TransactionConstants.SEP_IRA
        );
    };

    isSubmitPurchase = (fundAccountNumber, modeOfPayment) => {
        if (fundAccountNumber && (modeOfPayment === '03' || modeOfPayment === '01')) {
            return false;
        }
        return true;
    };

    getEncryptedData = async data => {
        return encrypt(data);
    };

    paymentMethod = param => {
        let modeOfPayment = '';
        if (param.check) {
            modeOfPayment = TransactionConstants.PERSONAL_CHECK_VALUE;
        } else if (param.wireTransfer) {
            modeOfPayment = TransactionConstants.WIRETRANSFER_VALUE;
        } else if (param.newAcc) {
            modeOfPayment = TransactionConstants.BANK_VALUE;
        } else if (param.bankAcc) {
            modeOfPayment = TransactionConstants.BANK_VALUE;
        }
        return modeOfPayment;
    };

    fundOptionSelected = methodOfPayment => {
        const { purchaseReducerData } = this.props;
        let fundOptions =
            purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0].fundOptionSelected
                ? purchaseReducerData.page1.selectedAccounts[0].fundOptionSelected
                : '';
        if (
            methodOfPayment === TransactionConstants.WIRETRANSFER_CLICK_HANDLER ||
            methodOfPayment === TransactionConstants.CHECK_CLICK_HANDLER
        ) {
            if (
                purchaseReducerData.page1 &&
                purchaseReducerData.page1.fundOptionSelected.includes(TransactionConstants.INIT_TEXT)
            ) {
                fundOptions = TransactionConstants.INIT_TEXT;
            } else if (
                purchaseReducerData.page1 &&
                purchaseReducerData.page1.fundOptionSelected.includes(TransactionConstants.SUB_TEXT)
            ) {
                fundOptions = TransactionConstants.SUB_TEXT;
            }
        }
        return fundOptions;
    };

    iraContributionVal = () => {
        const { purchaseReducerData } = this.props;

        let iraContributionresult = '';
        const [currentYear, previousYear] = TransactionConstants.CONTRIBUTION_OPTIONS_VALUE;

        if (purchaseReducerData.page2 && purchaseReducerData.page2.IRAContributionYear === '0') {
            iraContributionresult = currentYear;
        } else if (purchaseReducerData.page2 && purchaseReducerData.page2.IRAContributionYear === '1') {
            iraContributionresult = previousYear;
        }
        return iraContributionresult;
    };

    getPurchasePayload = async submitPayload => {
        const submitPayloadWithBankAcct = submitPayload;
        const { purchaseReducerData } = this.props;
        const bankAccDetails =
            purchaseReducerData.page2 && purchaseReducerData.page2.bankAccounts.filter(acc => acc.selected === true);
        if (bankAccDetails.length > 0) {
            submitPayloadWithBankAcct.fundingSource.bankAccount = {
                accountType: bankAccDetails[0].bankAccountType === TransactionConstants.SAVINGS_TEXT ? 'S' : 'C',
                financialInstitute: bankAccDetails[0].bankName,
                // need to get confirmation from API team. Should it be userId (vcmmemberservice)
                accountOwner: 'owners',
                // Developer Gaurav Kumar / Ramesh (18-11-2021)
                // Todo need to remove the below lines once KMS encryption issue resolved
                // Implimented alternative approach by holding encrypted value from banklist api call
                // transitRoutingNumber: await this.getEncryptedData(bankAccDetails[0].bankRoutingNumber),
                // bankAccountNumber: await this.getEncryptedData(bankAccDetails[0].bankAccountNumber),

                bankAccountNumber: bankAccDetails[0].bankAccEncNumber,
                transitRoutingNumber: bankAccDetails[0].bankRoutEncNumber,
            };
        }
        const selectedFund = purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0];
        if (!selectedFund.fundAccountNumber) {
            let econsentDocumentLink = EConsentData.filter(item => item.fundCode === selectedFund.fundNumber);
            if (econsentDocumentLink.length === 0) {
                econsentDocumentLink = [
                    {
                        docUrl: '',
                        formId: '',
                        description: '',
                        fileName: '',
                        fundCode: '',
                    },
                ];
            }
            const { docUrl, formId, description, fileName, fundCode } = econsentDocumentLink[0];
            submitPayloadWithBankAcct.document = {
                docType: 'pdf',
                docUrl,
                formId,
                description,
                fileName,
                fundCode,
                eSignDate: purchaseReducerData.eConsentTime,
            };
        }

        return submitPayloadWithBankAcct;
    };

    /**
     *
     * @param {Object} e
     */
    submitData = async e => {
        e.stopPropagation();
        const { submitPurchaseData, purchaseReducerData, tradingWindowData, resetEConsent } = this.props;
        this.setTradingWarning();
        const { ReInvest } = purchaseReducerData.page2;
        const { fundNumber, liquidityNoticeFlag } =
            purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0];
        const { accountNumber, accountTypeCode, socialCode } =
            purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected;
        const { companyNumber } = TransactionConstants.USAA_FUND_COMPANY_NUMBER;
        let { fundAccountNumber='', startDate='' } =
            purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0];
        let { accountType='' } = purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected;

        const methodOfPayment = this.paymentMethod(purchaseReducerData.page2 && purchaseReducerData.page2.payMethods);

        if (isTokenAvailable() === null) {

            // if there is no token then show the token box for authentication
            this.setState({ showAuthBox: true });
        } else {
            // Run the else if you have the token in local storage
            if (this.isSubmitPurchase(fundAccountNumber, methodOfPayment)) {
                startDate = getValidValue(startDate, '');
                accountType = getValidValue(accountType, '');
                fundAccountNumber = getValidValue(fundAccountNumber, '');
                if(purchaseReducerData.page1.isProportinateSelected)
                {   
                    for(let i=0;i<purchaseReducerData.page1?.proportianteSelectedFundsList?.length;i++)
                    {
                        const submitPayload = {
                            companyNumber,
                            accountNumber,
                            accountType,
                            fundNumber:`${purchaseReducerData.page1?.proportianteSelectedFundsList[i]?.fundNumber}`,
                            liquidityNoticeFlag,
                            accountTypeCode,
                            socialCode,
                            fundAccountNumber :`${purchaseReducerData.page1?.proportianteSelectedFundsList[i]?.fundAccountNumber}`,
                            newAccountFlag: 'N',
                            fundingOption: this.fundOptionSelected(methodOfPayment),
                            purchaseAmount: `${purchaseReducerData.page1 && purchaseReducerData.page1?.proportianteSelectedFundsList[i]?.initialInvest}`,
                            totalMnlyInv: `${0}`,
                            startDateNew: `${purchaseReducerData.page1 && purchaseReducerData.page1.startDate}`,
                            startDate,
                            effectiveDate: `${tradingWindowData.effectiveDate}`.split(' ')[0],
                            timeSubmitted: new Date(),
                            marketEndTime: tradingWindowData.tradingEndTime,
                            iraContributionYear: this.iraContributionVal(),
                            fundingSource: {
                                method: methodOfPayment,
                            },
                            reinvestEarnings: getValidValueOf(ReInvest, 'Y','N'),
                            overrideIRAWarning: purchaseReducerData.overrideIRAWarning,
                        };
                        submitPurchaseData({
                            purchasePayload: await this.getPurchasePayload(submitPayload),
                            token: getToken(),
                        });
                    }
                }
                else
                {
                    const submitPayload = {
                        companyNumber,
                        accountNumber,
                        accountType,
                        fundNumber,
                        liquidityNoticeFlag,
                        accountTypeCode,
                        socialCode,
                        fundAccountNumber,
                        newAccountFlag: getValidValueOf(fundAccountNumber, 'N', 'Y'),
                        fundingOption: this.fundOptionSelected(methodOfPayment),
                        purchaseAmount: `${getValidValue(purchaseReducerData?.page1?.totalSubAmt, '')}`,
                        totalMnlyInv: `${getValidValue(purchaseReducerData?.page1?.totalMnlyInv, '')}`,
                        startDateNew: `${getValidValue(purchaseReducerData?.page1?.startDate, '')}`,
                        startDate,
                        effectiveDate: `${tradingWindowData.effectiveDate}`.split(' ')[0],
                        timeSubmitted: new Date(),
                        marketEndTime: tradingWindowData.tradingEndTime,
                        iraContributionYear: this.iraContributionVal(),
                        fundingSource: {
                            method: methodOfPayment,
                        },
                        reinvestEarnings: getValidValueOf(ReInvest, 'Y', 'N'),
                        overrideIRAWarning: purchaseReducerData.overrideIRAWarning,
                    };
                    submitPurchaseData({
                        purchasePayload: await this.getPurchasePayload(submitPayload),
                        token: getToken(),
                    });
                }
            }
            resetEConsent();
            this.navToNextPage()();
        }
    };

    setNoAccountFlag = val => {
        this.setState({
            noAccountsToDisplay: val,
        });
    };

    navigateToNAO = () => {
        const { history, manageLogoutState, localStateData } = this.props;
        const {
            routeData: { search },
        } = localStateData;
        if (search !== '') {
            manageLogoutState({
                pathname: '',
                search,
                flow: 'prelogin',
                isTokenValid: true,
                message: '',
                routeParams: {},
            });
        }
        history.push('/openAccount');
    };

    setNoAccAvailFlag = val => {
        this.setState({
            showFundNotAvailable: val,
        });
    };

    handleCloseBanner = () => {
        this.setState({ showFundNotAvailable: false });
    };

    handleResubmit = () =>
    {
        this.props.setResubmitFlow();
        this.setState({pageIndex:1})
    }
    /**
     * This function is invoked from the render method.
     *
     * Returns the component based on the page index from react state.
     */

    bindComponent() {
        const {
            pageIndex,
            prevPageIndex,
            childrenState,
            showMasterRegForeignAddressModal,
            addressValid,
            noAccountsToDisplay,
        } = this.state;
        const {
            purchaseReducerData,
            getFunds,
            localStateData,
            accOpeningData,
            getAccFundsData,
            submitPurchaseData,
            getTransactionCompositeData,
            getAccountList,
            getBankDetails,
            validateACHLimit,
            transactionReducerData,
            setNextPageNumber,
            tradingWindowData,
            location,
            setSelectedConsent,
            resetEConsent,
            setConsentTime,
            setCancelPurchase,
            loginData,
            accOpeningReducerData,
            getFundDetails,
            setNextPageNumberPositions,
            fundAccountData,
            getTransHolderList,
            logoutRouteData,
            analyzeClickEvent,
            analyzeFormFieldError,
            resetPurchaseSubmit,
            resetGoToNextPageVariable,
            clearBankAccDetails,
            setProportionateWhenOneFundFail
        } = this.props;
        const [accountSelectionState] = childrenState;
        const { accountSelected, accountSelectedType } = accountSelectionState || {};
        const navigationObj = {
            portalRoot: this.bottomNav1,
        };

        const transactionType = 'purchase';
        // pageIndex takes the value 0 = initial page (buy), 1 - Investment Selection, 2 - Fund Selection, 3 - Review and confirmation
        switch (pageIndex) {
            case 1: {
                return (
                    <ErrorBoundary>
                        <InvestmentSelectionComp
                            accDetails={accountSelected}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={pageIndex}
                            editSection={this.editSection}
                            getFunds={getFunds}
                            purchaseReducerData={purchaseReducerData}
                            getCompositeData={getTransactionCompositeData}
                            localStateData={localStateData}
                            accOpeningData={accOpeningData}
                            masterLookupStateData={transactionReducerData}
                            getAccFundsData={getAccFundsData}
                            handleError={this.handleError}
                            setTradingWarning={this.setTradingWarning}
                            setNextPageNumber={setNextPageNumber}
                            location={location}
                            setCancelPurchase={setCancelPurchase}
                            getFundDetails={getFundDetails}
                            setNextPageNumberPositions={setNextPageNumberPositions}
                            tradingWindowData={tradingWindowData}
                            getTransHolderList={getTransHolderList}
                            logoutRouteData={logoutRouteData}
                            analyzeClickEvent={analyzeClickEvent}
                            analyzeFormFieldError={analyzeFormFieldError}
                            setNoAccAvailFlag={this.setNoAccAvailFlag}
                        />
                    </ErrorBoundary>
                );
            }
            case 2: {
                return (
                    <ErrorBoundary>
                        <FundSourceComp
                            accDetails={accountSelected}
                            accSelectedType={accountSelectedType}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={pageIndex}
                            getTransactionCompositeData={getTransactionCompositeData}
                            handleError={this.handleError}
                            getBankDetails={getBankDetails}
                            validateACHLimit={validateACHLimit}
                            setTradingWarning={this.setTradingWarning}
                            isIRA={this.isIRA}
                            loginData={loginData}
                            setCancelPurchase={setCancelPurchase}
                            handleIRAContribution={this.handleIRAContribution}
                            analyzeClickEvent={analyzeClickEvent}
                        />
                    </ErrorBoundary>
                );
            }
            case 21: {
                return (
                    <ErrorBoundary>
                        <FundSummary
                            navigationObj={navigationObj}
                            accDetails={accountSelected}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            pageIndex={prevPageIndex}
                            accOpeningData={accOpeningReducerData}
                            transactionType={transactionType}
                            fundAccountData={fundAccountData}
                            analyzeClickEvent={analyzeClickEvent}
                        />
                    </ErrorBoundary>
                );
            }
            case 3: {
                const paymentType = purchaseReducerData.page2 && purchaseReducerData.page2.payMethods;
                return (
                    <ErrorBoundary>
                        <ReviewConfirmComp
                            authBox={this.authBox}
                            childrenState={childrenState}
                            savedState={childrenState[+pageIndex]}
                            saveChildrenState={this.saveChildrenState}
                            onSwitchComponent={this.onSwitchComponent}
                            navToNextPage={this.navToNextPage}
                            pageIndex={pageIndex}
                            scrollToSection={this.scrollToSection}
                            submitPurchaseData={submitPurchaseData}
                            handleError={this.handleError}
                            setTradingWarning={this.setTradingWarning}
                            isIRA={this.isIRA}
                            tradingWindowData={tradingWindowData}
                            paymentType={paymentType}
                            submitData={this.submitData}
                            setCancelPurchase={setCancelPurchase}
                            resetPurchaseSubmit={resetPurchaseSubmit}
                        />
                    </ErrorBoundary>
                );
            }
            case 4: {
                return (
                    <ErrorBoundary>
                        <EConsent
                            onSwitchComponent={this.onSwitchComponent}
                            submitData={this.submitData}
                            selectedConsent={purchaseReducerData.selectedConsent}
                            setSelectedConsent={setSelectedConsent}
                            selectedFund={purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0]}
                            resetEConsent={resetEConsent}
                            setConsentTime={setConsentTime}
                            setCancelPurchase={setCancelPurchase}
                            transactionType={transactionType}
                            analyzeClickEvent={analyzeClickEvent}
                        />
                    </ErrorBoundary>
                );
            }
            case 5: {
                const { isLoading, isError, fundAccountNumber } = purchaseReducerData;
                const paymentType = purchaseReducerData.page2 && purchaseReducerData.page2.payMethods;
                const selectedFund = purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0];
                const masterRegId =
                    purchaseReducerData.page0 && purchaseReducerData.page0.accountSelected.accountNumber;
                const mailingAddress = transactionReducerData ? transactionReducerData.wf_mailing_address.value : '';
                return (
                    <ErrorBoundary>
                        <ConfirmationComp
                            setTradingWarning={this.setTradingWarning}
                            isLoading={isLoading}
                            isError={isError}
                            fundAccountNumber={fundAccountNumber}
                            paymentType={paymentType}
                            selectedFund={selectedFund}
                            mailingAddress={mailingAddress}
                            showFundTypeInfo
                            loginData={loginData}
                            isPurchases
                            isIRA={this.isIRA()}
                            masterRegId={masterRegId}
                            transactionType={transactionType}
                            analyzeClickEvent={analyzeClickEvent}
                            isProportinateSelected={purchaseReducerData?.page1?.isProportinateSelected}
                            proportianteSelectedFundsList={purchaseReducerData?.page1?.proportianteSelectedFundsList}
                            proportinateFundPassedList={purchaseReducerData?.proportinateFundPassedList}
                            displayFailedPropotinateFundListArray={this.state.displayFailedPropotinateFundListArray}
                            proportionateSubmitAPICount={this.state.pruchaseSubmitAPiCount}
                            handleResubmit={this.handleResubmit}
                            resetGoToNextPageVariable={resetGoToNextPageVariable}
                            clearBankAccDetails={clearBankAccDetails}
                            setProportionateWhenOneFundFail={setProportionateWhenOneFundFail}
                            isProportionateResubmitFlow={purchaseReducerData?.isProportionateResubmitFlow}
                        />
                    </ErrorBoundary>
                );
            }

            default: {
                return !noAccountsToDisplay ? (
                    <PurchasesComp
                        savedState={childrenState[+pageIndex]}
                        saveChildrenState={this.saveChildrenState}
                        onSwitchComponent={this.onSwitchComponent}
                        pageIndex={pageIndex}
                        getAccountsList={getAccountList}
                        getFunds={getFunds}
                        navigationObj={navigationObj}
                        handleError={this.handleError}
                        setTradingWarning={this.setTradingWarning}
                        transactionType={transactionType}
                        setCancelPurchase={setCancelPurchase}
                        addressValid={addressValid}
                        showMasterRegForeignAddressModal={showMasterRegForeignAddressModal}
                        analyzeClickEvent={analyzeClickEvent}
                        setNoAccountFlag={this.setNoAccountFlag}
                    />
                ) : (
                    <InfoCardButton
                        displayText={TransactionConstants.OPEN_ACC}
                        primaryBtnText={primaryBtnText}
                        nextBtnText={nextBtnText}
                        displayFooterBtns={displayFooterBtns}
                        disabledFooterNxt={disabledFooterNxt}
                        onClickBtn={this.navigateToNAO}
                    />
                );
            }
        }
    }

    authBox = () => {
        this.setState({ showAuthBox: true });
    };

    moveNextPage = () => {
        this.setState({ showAuthBox: false });
    };

    render() {
        const {
            pageIndex,
            displayNotification,
            notificationMsg,
            iraContributionWarning,
            isIRAContributionAccepted,
            isLoading,
            showCancelPurchaseModal,
            cancelPurchase,
            showMasterRegForeignAddressModal,
            noAccountsToDisplay,
            showFundNotAvailable,
            showAuthBox,
        } = this.state;
        const { tradingWindowData, purchaseReducerData } = this.props;
        const activeCrumb = 'Buy';
        const pageHeading = 'Buy';
        const cancelTransactionText = TransactionConstants.CANCEL_TRANSACTION_DESCRIPTION_GENERAL;
        const isInitialPurchase =
            !!purchaseReducerData &&
            purchaseReducerData.page1 &&
            purchaseReducerData.page1.fundOptionSelected &&
            purchaseReducerData.page1.fundOptionSelected.includes(TransactionConstants.INIT_TEXT);
        const fundNotAvailMsg = (
            <p>
                <span>{TransactionConstants.FUND_NOT_AVAIL}</span>
            </p>
        );
        return (
            <div>
                <StopCodeAlert
                    show={showFundNotAvailable}
                    apiErrorMsg={fundNotAvailMsg}
                    onClick={this.handleCloseBanner}
                />

                {<Authenticate showAuthBox={showAuthBox} moveNextPage={this.moveNextPage} />}

                {!tradingWindowData.canTrade && (
                    <WBlueBanner text={tradingWindowData.tradingClosureMsg} onClose={this.onNotificationClose} />
                )}

                {displayNotification && <WBlueBanner text={notificationMsg} onClose={this.onNotificationClose} />}

                <WSpinner loading={isLoading} />
                {!isIRAContributionAccepted && this.getIsNotCoverdellIRA() && (
                    <ConformationModal
                        modalTitle={TransactionConstants.IRA_MAX_CONTR_WARNING}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={this.handleOk}
                        onSecondaryClick={this.handleCancel}
                    />
                )}

                {!isIRAContributionAccepted && this.getIsCoverdellIRA() && (
                    <ConformationModal
                        modalTitle={TransactionConstants.IRA_MAX_CONTR_MESSAGE}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={this.handleOk}
                        onSecondaryClick={this.handleCancel}
                    />
                )}

                <ConfirmationMessage
                    modalTitle={TransactionConstants.CANCEL_TRANSACTION_TEXT}
                    modalBodyText={cancelTransactionText}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    onPrimaryClick={this.handleCancelPurchase}
                    onSecondaryClick={this.handleDoNotCancelPurchase}
                    show={showCancelPurchaseModal}
                />

                {cancelPurchase ? <Redirect to="/accountholder" /> : ''}

                {showMasterRegForeignAddressModal && (
                    <ConfirmModal
                        modalTitle={TransactionConstants.INSTRUCTIONS_TEXT}
                        modalBodyText={TransactionConstants.FOREIGN_ADDRESS_TEXT}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.cancelForeignAddressWarningMessage}
                    />
                )}

                <div id="#currentPageParentName" parentpagename="Purchase">
                    {pageIndex !== 5 && pageIndex !== 21 && !noAccountsToDisplay && (
                        <>
                            <WStepperMobile
                                currentPage={pageIndex}
                                aria-label={pageIndex}
                                tabIndex="0"
                                pages={isInitialPurchase ? pages : subsequentPurPages}
                                transactionType="Buy - "
                            />
                        </>
                    )}
                    <div className="container mobile-cont">
                        <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={breadCrumURL} />
                        {pageIndex !== 5 && pageIndex !== 21 && (
                            <>
                                <styles.FlexDiv>
                                    <PageHeading>{pageHeading}</PageHeading>
                                </styles.FlexDiv>
                                <styles.RwdFlexDiv>
                                    <PageHeading aria-hidden="true">{pageHeading}</PageHeading>
                                </styles.RwdFlexDiv>
                                {!noAccountsToDisplay && (
                                    <Stepper
                                        className="desktopStepper"
                                        style={stepperStyle}
                                        currentPage={pageIndex}
                                        aria-label={pageIndex}
                                        tabIndex="0"
                                        pages={isInitialPurchase ? pages : subsequentPurPages}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    {this.bindComponent()}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        purchaseReducerData: state.purchaseData,
        localStateData: state.localStateManagementReducerData,
        masterLookupStateData: state.masterLookUpData,
        accOpeningData: state.accOpeningReducerData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
        transactionReducerData: state.transactionCompositeDataReducerData,
        tradingWindowData: state.tradingWindowData,
        loginData: state.loginData,
        accOpeningReducerData: state.accOpeningReducerData,
        fundAccountData: state.fundAccountData,
        logoutRouteData: state.logoutRouteData,
    };
};
export const mapDispatchToProps = {
    getFunds: fundAccountActions.getFundAccountsList,
    getAccountList: masterRegistrationAccountActions.getAccountsList,
    getAccFundsData: accOpeningActions.getFundListData,
    getFundDetails: accOpeningActions.getFundDetailsData,
    submitPurchaseData: purchaseActions.submitPurchaseData,
    getBankDetails: bankAccountAction.getBankAccounts,
    clearBankAccDetails: bankAccountAction.clearBankAccDetails,
    addBankAccount: accOpeningActions.validateBankAccInfo,
    getTransactionCompositeData: transactionCompositeDataAction.getTransactionCompositeData,
    savePageState: purchaseActions.savePageState,
    getIRAContributionValues: purchaseActions.getIRAContributionValues,
    cancelIRAContributionWarning: purchaseActions.cancelIRAContributionWarning,
    acceptIRAContributionWarning: purchaseActions.acceptIRAContributionWarning,
    validateACHLimit: purchaseActions.validateACHLimit,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    setTradingWindowWarning: tradingWindowActions.setTradingWindowWarning,
    setNextPageNumber: purchaseActions.setNextPageNumber,
    resetPageNumber: purchaseActions.resetPageNumber,
    setSelectedConsent: purchaseActions.setSelectedConsent,
    setSelectedProspectus: purchaseActions.setSelectedProspectus,
    setSelectedDisclosure: purchaseActions.setSelectedDisclosure,
    resetEConsent: purchaseActions.resetEConsent,
    setConsentTime: purchaseActions.setConsentTime,
    clearPurchaseData: purchaseActions.clearPurchaseData,
    setCancelPurchase: purchaseActions.setCancelPurchase,
    setNotToCancelPurchase: purchaseActions.setNotToCancelPurchase,
    getCustomerProfile: loginActions.getCustomerProfile,
    clearMasterRegInfo: masterRegistrationAccountActions.clearMasterRegInfo,
    clearFundAccountList: fundAccountActions.clearFundAccountList,
    setNextPageNumberPositions: purchaseActions.setNextPageNumberPositions,
    getTransHolderList: transactionHistoryActions.getTransHolderList,
    clearLogoutState: logoutRouteActions.clearLogoutState,
    manageLogoutState: logoutRouteActions.manageLogoutState,
    analyzeClickEvent: analytics.analyzeClickEvent,
    analyzeFormFieldError: analytics.analyzeFormFieldError,
    resetPurchaseSubmit:purchaseActions.resetPurchaseSubmit,
    resetGoToNextPageVariable:purchaseActions.resetGoToNextPageVariable,
    setProportionateWhenOneFundFail:purchaseActions.setProportionateWhenOneFundFail,
    setResubmitFlow:purchaseActions.setResubmitFlow
};

PurchaseContainer.propTypes = {
    getAccFundsData: PropTypes.func,
    getBankDetails: PropTypes.func,
    getAccountList: PropTypes.func,
    getFunds: PropTypes.func,
    getTransactionCompositeData: PropTypes.func,
    purchaseReducerData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    submitPurchaseData: PropTypes.instanceOf(Object),
    accOpeningData: PropTypes.instanceOf(Object),
    savePageState: PropTypes.func,
    getIRAContributionValues: PropTypes.func,
    cancelIRAContributionWarning: PropTypes.func,
    acceptIRAContributionWarning: PropTypes.func,
    validateACHLimit: PropTypes.func,
    setTradingWindowWarning: PropTypes.func,
    transactionReducerData: PropTypes.instanceOf(Object),
    setNextPageNumber: PropTypes.instanceOf(Object),
    resetPageNumber: PropTypes.instanceOf(Object),
    tradingWindowData: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
    masterRegistrationAccountData: PropTypes.shape({
        masterRegAccountList: PropTypes.instanceOf(Object),
    }).isRequired,
    getTradingClosureWindow: PropTypes.instanceOf(Object),
    setSelectedConsent: PropTypes.func,
    resetEConsent: PropTypes.func,
    setConsentTime: PropTypes.func,
    clearPurchaseData: PropTypes.func,
    setCancelPurchase: PropTypes.func,
    setNotToCancelPurchase: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    loginData: PropTypes.instanceOf(Object),
    clearMasterRegInfo: PropTypes.func,
    clearFundAccountList: PropTypes.func,
    accOpeningReducerData: PropTypes.instanceOf(Object),
    getFundDetails: PropTypes.func,
    setNextPageNumberPositions: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    getTransHolderList: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    manageLogoutState: PropTypes.func,
    logoutRouteData: PropTypes.instanceOf(Object),
};
PurchaseContainer.defaultProps = {
    getAccFundsData: () => {},
    getBankDetails: () => {},
    getAccountList: () => {},
    getFunds: () => {},
    savePageState: () => {},
    getTransactionCompositeData: () => {},
    purchaseReducerData: {},
    localStateData: {},
    submitPurchaseData: {},
    accOpeningData: {},
    getIRAContributionValues: () => {},
    cancelIRAContributionWarning: () => {},
    acceptIRAContributionWarning: () => {},
    validateACHLimit: () => {},
    setTradingWindowWarning: () => {},
    transactionReducerData: () => {},
    setNextPageNumber: () => {},
    resetPageNumber: () => {},
    tradingWindowData: {},
    location: {},
    getTradingClosureWindow: () => {},
    setSelectedConsent: () => {},
    resetEConsent: () => {},
    setConsentTime: () => {},
    clearPurchaseData: () => {},
    setCancelPurchase: () => {},
    setNotToCancelPurchase: () => {},
    clearBankAccDetails: () => {},
    getCustomerProfile: () => {},
    loginData: {},
    clearMasterRegInfo: () => {},
    clearFundAccountList: () => {},
    accOpeningReducerData: null,
    getFundDetails: () => {},
    setNextPageNumberPositions: () => {},
    fundAccountData: {},
    getTransHolderList: () => {},
    analyzeClickEvent: () => {},
    analyzeFormFieldError: () => {},
    history: {},
    manageLogoutState: () => {},
    logoutRouteData: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseContainer);
