import React, { useCallback } from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import SortIndicator from '../../assets/sor-indicator.svg';
// import SortIndicatorUpArrow from '../../assets/sort-indicator-up-arrow.svg';

const S = {
    rectangle: {
        position: 'relative',
        top: '3px',
        left: '0',
        width: '55px',
        height: '26px',
        background: '#004A98 0% 0% no-repeat padding-box',
        opacity: '1',
        color: '#ffffff',
        fontSize: '12px',
    },
    p: {
        top: '5px',
        left: 'calc(50% - 21px)',
        width: '42px',
        height: '14px',
        textAlign: 'center',
        fontSize: '12px',
        letterSpacing: '0',
        color: '#FFFFFF',
        opacity: '1',
        margin: 0,
        position: 'absolute',
    },
    th: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '1em',
        lineHeight: '22px',
        padding: '12px 12px 12px 0',
        borderTop: 'none',
    },
    td: {
        fontSize: '0.875em',
        textAlign: 'right',
        paddingLeft: '0px',
        color: '#49494A',
    },
    arrowStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignContent: ' space-evenly',
    },
    basis1: {
        flexBasis: '30%',
    },
    basis2: {
        flexBasis: '70%',
    },
};

const E = {
    Status: styled.div`
        display: flex;
    `,
    CancelButton: styled.div`
        display: flex;
        color: #004a98;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        font-family: yorkten-slab-normal, sans-serif;
        cursor: pointer;
        padding-left: 5px;
        margin-left: 7px;
        margin-right: 10px;
        margin-top: -4px;

        :hover {
            :nth-child(odd) {
                background: #f7f7f7;
            }
        }

        :hover {
            :nth-child(even) {
                background: white;
            }
        }
    `,
    NoRecords: styled.div`
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        margin-top: 5px;
    `,
};

const assignObj = obj => obj;
const NO_RECORDS_FOUND = 'No Records Found';
const MSRLookupTable = props => {
    const { th, td } = S;
    const { tableData } = props;    
    const data = React.useMemo(() => [...tableData], [tableData]);
    const sortByDate = useCallback((a, b) => {
        if (!a.values.effectiveDate || !b.values.effectiveDate) {
            return Number.MIN_VALUE;
        }
        return new Date(Date.parse(a.values.effectiveDate)) - new Date(Date.parse(b.values.effectiveDate));
    }, []);

    const sortBy = React.useMemo(() => sortByDate, [sortByDate]);

    

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date Time',
                accessor: 'date', // accessor is the "key" in the data,                
                width: '6.5%',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                
                Header: 'Transaction Ref Id',
                accessor: 'refid',
                width: '18.5%',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Business Process',
                accessor: 'tranDesc',
                width: '12.5%',
                disableFilters: true,            
                
            },
            {
                Header: 'Sub Process',                
                accessor: 'subprocess',
                width: '14.5%',
                sort: 'desc',                
                sortType: sortBy,
            },
            {
                
                Header: 'Detailed Message',
                accessor: 'message',
                width: '10.5%',
                disableSortBy: true,
                disableFilters: true,
                
            },
            {
                Header: 'Additional Details',
                accessor: 'details',
                width: '12.5%',
                canSort: true,
                disableFilters: true,                
            },
        ],
        [sortBy],
    );
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

    return (
        <>
            <table
                {...getTableProps()}
                className="table table-striped mb-0"
                style={assignObj({ borderBottom: '1px solid #d2d2d2' })}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={assignObj({ color: '#004A98', ...th })}
                                    sortable={i === 3 ? 'true' : 'false'}
                                >
                                    {column.render('Header')}
                                    {/* <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <img src={SortIndicatorUpArrow} alt="sort_indicator" />
                                            ) : (
                                                <img src={SortIndicatorUpArrow} alt="sort_indicator" />
                                            )
                                        ) : column.disableSortBy ? (
                                            ''
                                        ) : (
                                            <img src={SortIndicator} alt="sort_indicator" />
                                        )}
                                    </span> */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.length > 0 &&
                        rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={td}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {rows.length === 0 && <E.NoRecords>{NO_RECORDS_FOUND}</E.NoRecords>}
        </>
    );
};

MSRLookupTable.defaultProps = {
    tableData: [],    
};

MSRLookupTable.propTypes = {
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            ticker: PropTypes.string,
            fundName: PropTypes.string,
            trandesc: PropTypes.string,
            establisheddate: PropTypes.string,
            quantity: PropTypes.string,
            price: PropTypes.string,
            value: PropTypes.string,
            status: PropTypes.string,
            companynumber: PropTypes.string,
            batchnumber: PropTypes.string,
            sequencenumber: PropTypes.string,
        }),
    ),
    
};

export default MSRLookupTable;
