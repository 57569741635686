// Transaction action constants
export const SAVE_PAGE_STATE_SUCCESS = 'SAVE_PAGE_STATE_SUCCESS';
export const SAVE_PAGE_STATE_FAILURE = 'SAVE_PAGE_STATE_FAILURE';

export const GET_IRA_CONTRIBUTION_VALUES_SUCCESS = 'GET_IRA_CONTRIBUTION_VALUES_SUCCESS';
export const GET_IRA_CONTRIBUTION_VALUES_FAILURE = 'GET_IRA_CONTRIBUTION_VALUES_FAILURE';
export const GET_IRA_CONTRIBUTION_VALUES_START = 'GET_IRA_CONTRIBUTION_VALUES_START';
export const GET_IRA_CONTRIBUTION_VALUES_CLEAR = 'GET_IRA_CONTRIBUTION_VALUES_CLEAR';

export const CANCEL_IRA_CONTRIBUTION_WARNING_SUCCESS = 'CANCEL_IRA_CONTRIBUTION_WARNING_SUCCESS';
export const CANCEL_IRA_CONTRIBUTION_WARNING_FAILURE = 'CANCEL_IRA_CONTRIBUTION_WARNING_FAILURE';

export const CANCEL_ACH_LIMIT_SUCCESS = 'CANCEL_ACH_LIMIT_SUCCESS';
export const CANCEL_ACH_LIMIT_FAILURE = 'CANCEL_ACH_LIMIT_FAILURE';

export const ACCEPT_IRA_CONTRIBUTION_WARNING_SUCCESS = 'ACCEPT_IRA_CONTRIBUTION_WARNING_SUCCESS';
export const ACCEPT_IRA_CONTRIBUTION_WARNING_FAILURE = 'ACCEPT_IRA_CONTRIBUTION_WARNING_FAILURE';

export const VALIDATE_ACH_LIMIT_SUCCESS = 'VALIDATE_ACH_LIMIT_SUCCESS';
export const VALIDATE_ACH_LIMIT_FAILURE = 'VALIDATE_ACH_LIMIT_FAILURE';
export const VALIDATE_ACH_LIMIT_START = 'VALIDATE_ACH_LIMIT_START';

export const SET_TRADING_CLOSURE_STATUS_SUCCESS = 'SET_TRADING_CLOSURE_STATUS_SUCCESS';
export const SET_NAVIGATION_FROM_TRANSACTION_DASHBOARD_SUCCESS = 'SET_NAVIGATION_FROM_TRANSACTION_DASHBOARD_SUCCESS';

export const GET_LIQUIDATION_TRADING_LIMIT = 'GET_LIQUIDATION_TRADING_LIMIT';
export const GET_LIQUIDATION_TRADING_LIMIT_START = 'GET_LIQUIDATION_TRADING_LIMIT_START';
export const GET_LIQUIDATION_TRADING_LIMIT_SUCCESS = 'GET_LIQUIDATION_TRADING_LIMIT_SUCCESS';
export const GET_LIQUIDATION_TRADING_LIMIT_FAILURE = 'GET_LIQUIDATION_TRADING_LIMIT_FAILURE';

export const CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS = 'CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS';
export const CANCEL_TRADING_LIMIT_MESSAGE_FAILURE = 'CANCEL_TRADING_LIMIT_MESSAGE_FAILURE';

export const SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS = 'SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS';
export const SET_LIQ_NEXT_PAGE_NUMBER_FAILURE = 'SET_LIQ_NEXT_PAGE_NUMBER_FAILURE';

export const SET_NEXT_PAGE_NUMBER_SUCCESS = 'SET_NEXT_PAGE_NUMBER_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_FAILURE = 'SET_NEXT_PAGE_NUMBER_FAILURE';

export const RESET_PAGE_NUMBER_SUCCESS = 'RESET_PAGE_NUMBER_SUCCESS';
export const RESET_PAGE_NUMBER_FAILURE = 'RESET_PAGE_NUMBER_FAILURE';

export const SET_NEXT_PAGE_NUMBER_PURCHASE_SUCCESS = 'SET_NEXT_PAGE_NUMBER_PURCHASE_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_PURCHASE_FAILURE = 'SET_NEXT_PAGE_NUMBER_PURCHASE_FAILURE';

export const RESET_PAGE_NUMBER_PURCHASE_SUCCESS = 'RESET_PAGE_NUMBER_PURCHASE_SUCCESS';
export const RESET_PAGE_NUMBER_PURCHASE_FAILURE = 'RESET_PAGE_NUMBER_PURCHASE_FAILURE';

export const SET_TAX_VALIDATION_SUCCESS = 'SET_TAX_VALIDATION_SUCCESS';
export const SET_TAX_VALIDATION_FAILURE = 'SET_TAX_VALIDATION_FAILURE';

export const SET_SELECTED_CONSENT_PURCHASE_SUCCESS = 'SET_SELECTED_CONSENT_PURCHASE_SUCCESS';
export const SET_SELECTED_CONSENT_EXCHANGE_SUCCESS = 'SET_SELECTED_CONSENT_EXCHANGE_SUCCESS';

export const RESET_ECONSENT_PURCHASE_SUCCESS = 'RESET_ECONSENT_PURCHASE_SUCCESS';
export const RESET_ECONSENT_EXCHANGE_SUCCESS = 'RESET_ECONSENT_EXCHANGE_SUCCESS';

export const SET_ECONSENT_NAME_PURCHASE_SUCCESS = 'SET_ECONSENT_NAME_PURCHASE_SUCCESS';
export const SET_ECONSENT_NAME_EXCHANGE_SUCCESS = 'SET_ECONSENT_NAME_EXCHANGE_SUCCESS';

export const SET_ECONSENT_TIME_PURCHASE_SUCCESS = 'SET_ECONSENT_TIME_PURCHASE_SUCCESS';
export const SET_ECONSENT_TIME_EXCHANGE_SUCCESS = 'SET_ECONSENT_TIME_EXCHANGE_SUCCESS';

export const SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS = 'SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS';
export const SET_PROSPECTUS_CONSENT_EXCHANGE_SUCCESS = 'SET_PROSPECTUS_CONSENT_EXCHANGE_SUCCESS';

export const SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS = 'SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS';
export const SET_DISCLOSURE_CONSENT_EXCHANGE_SUCCESS = 'SET_DISCLOSURE_CONSENT_EXCHANGE_SUCCESS';

export const SET_FUND_SOURCE_VALIDATION_SUCCESS = 'SET_FUND_SOURCE_VALIDATION_SUCCESS';
export const SET_FUND_SOURCE_VALIDATION_FAILURE = 'SET_FUND_SOURCE_VALIDATION_FAILURE';

export const CLEAR_PURCHASE_DATA = 'CLEAR_PURCHASE_DATA';
export const SET_CANCEL_PURCHASE = 'SET_CANCEL_PURCHASE';
export const SET_NOT_TO_CANCEL_PURCHASE = 'SET_NOT_TO_CANCEL_PURCHASE';

export const CLEAR_EXCHANGE_DATA = 'CLEAR_EXCHANGE_DATA';
export const SET_CANCEL_EXCHANGE = 'SET_CANCEL_EXCHANGE';
export const SET_NOT_TO_CANCEL_EXCHANGE = 'SET_NOT_TO_CANCEL_EXCHANGE';

// Liquidation
export const SAVE_LIQUIDATION_DATA = 'SAVE_LIQUIDATION_DATA';

export const SUBMIT_LIQUIDATION_DATA = 'SUBMIT_LIQUIDATION_DATA';
export const SUBMIT_LIQUIDATION_DATA_SUCCESS = 'SUBMIT_LIQUIDATION_DATA_SUCCESS';
export const SUBMIT_LIQUIDATION_DATA_ERROR = 'SUBMIT_LIQUIDATION_DATA_ERROR';
export const SUBMIT_LIQUIDATION_DATA_LOADING = 'SUBMIT_LIQUIDATION_DATA_LOADING';
export const SUBMIT_LIQUIDATION_DATA_FAILURE = 'SUBMIT_LIQUIDATION_DATA_FAILURE';

export const GET_ACCOUNT_LIST_FOR_LIQUIDATION = 'GET_ACCOUNT_LIST_FOR_LIQUIDATION';
export const GET_ACCOUNT_LIST_FOR_LIQUIDATION_SUCCESS = 'GET_ACCOUNT_LIST_FOR_LIQUIDATION_SUCCESS';
export const GET_ACCOUNT_LIST_FOR_LIQUIDATION_FAILURE = 'GET_ACCOUNT_LIST_FOR_LIQUIDATION_FAILURE';

export const GET_FUND_LIST_FOR_LIQUIDATION = 'GET_FUND_LIST_FOR_LIQUIDATION';
export const GET_FUND_LIST_FOR_LIQUIDATION_SUCCESS = 'GET_FUND_LIST_FOR_LIQUIDATION_SUCCESS';
export const GET_FUND_LIST_FOR_LIQUIDATION_FAILURE = 'GET_FUND_LIST_FOR_LIQUIDATION_FAILURE';

export const SET_PAYMENT_METHOD_SUCCESS = 'SET_PAYMENT_METHOD_SUCCESS';
export const SET_PAYMENT_METHOD_FAILURE = 'SET_PAYMENT_METHOD_FAILURE';

export const GET_CHECK_DELIVERY_ADDRESS = 'GET_CHECK_DELIVERY_ADDRESS';
export const GET_CHECK_DELIVERY_ADDRESS_SUCCESS = 'GET_CHECK_DELIVERY_ADDRESS_SUCCESS';
export const GET_CHECK_DELIVERY_ADDRESS_FAILURE = 'GET_CHECK_DELIVERY_ADDRESS_FAILURE';
export const GET_CHECK_DELIVERY_ADDRESS_LOADER = 'GET_CHECK_DELIVERY_ADDRESS_LOADER';

export const GET_STATE_CODE_GROUP_LOADER = 'GET_STATE_CODE_GROUP_LOADER';
export const GET_STATE_CODE_GROUP = 'GET_STATE_CODE_GROUP';
export const GET_STATE_CODE_GROUP_SUCCESS = 'GET_STATE_CODE_GROUP_SUCCESS';
export const GET_STATE_CODE_GROUP_FAILURE = 'GET_STATE_CODE_GROUP_FAILURE';

export const GET_STATE_TAXES_FOR_GROUP = 'GET_STATE_TAXES_FOR_GROUP';
export const GET_STATE_TAXES_FOR_GROUP_SUCCESS = 'GET_STATE_TAXES_FOR_GROUP_SUCCESS';
export const GET_STATE_TAXES_FOR_GROUP_FAILURE = 'GET_STATE_TAXES_FOR_GROUP_FAILURE';
export const GET_STATE_TAXES_FOR_GROUP_LOADER = 'GET_STATE_TAXES_FOR_GROUP_LOADER';

export const GET_TRADING_CALENDAR = 'GET_TRADING_CALENDAR';
export const GET_TRADING_CALENDAR_SUCCESS = 'GET_TRADING_CALENDAR_SUCCESS';
export const GET_TRADING_CALENDAR_FAILURE = 'GET_TRADING_CALENDAR_FAILURE';
export const GET_TRADING_CALENDAR_START = 'GET_TRADING_CALENDAR_START';

export const TRADING_WINDOW_END_OF_DAY_CLOSURE_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You must place your order prior to 3:59 pm ET to be sure your order gets today’s Net Asset Value.';

export const TRADING_WINDOW_HOLIDAY_CLOSURE_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You are placing an order after market close and will receive the Net Asset Value of next business day';

export const TRADING_WINDOW_PENDING_OPEN_MESSAGE =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open from 9:30 am ET until the market closes at 4:00 pm ET. You are placing an order during market close and will receive the Net Asset Value of today.';

export const IRA_CONTRIBUTION_WARNING_MESSAGE =
    'Currently, we are unable to check the IRA Contribution levels. Please check with your MSR.';
export const ACH_CONTRIBUTION_WARNING_MESSAGE =
    'Currently, we are unable to check the ACH limit. Please check with your MSR or Please choose different payment method';
export const NEXT_PAGE_ERROR = 'Error moving to next page';

export const LOCALE_TIMEZONE_INFO = 'America/New_York';
export const LOCALE_LANG_INFO = 'en-US';

export const CLEAR_LIQUIDATION_DATA = 'CLEAR_LIQUIDATION_DATA';
export const SET_CANCEL_LIQUIDATION = 'SET_CANCEL_LIQUIDATION';
export const SET_NOT_TO_CANCEL_LIQUIDATION = 'SET_NOT_TO_CANCEL_LIQUIDATION';

export const SET_TRADINGWINDOW_DATA = 'SET_TRADINGWINDOW_DATA';

export const SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_SUCCESS = 'SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_FAILURE = 'SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_FAILURE';

export const SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS = 'SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE = 'SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE';

export const SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS = 'SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS';
export const SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE = 'SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE';

export const SAVE_PAGE_STATE_SUCCESS_NAO = 'SAVE_PAGE_STATE_SUCCESS_NAO';
export const SAVE_PAGE_STATE_FAILURE_NAO = 'SAVE_PAGE_STATE_FAILURE_NAO';



export const GET_IRA_CONTRIBUTION_YEAR_VALUES_SUCCESS = 'GET_IRA_CONTRIBUTION_YEAR_VALUES_SUCCESS';
export const GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE = 'GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE';
export const GET_IRA_CONTRIBUTION_YEAR_VALUES_START = 'GET_IRA_CONTRIBUTION_YEAR_VALUES_START';
export const RESET_PURCHASE_SUBMIT = "RESET_PURCHASE_SUBMIT";
export const RESET_GO_TO_NEXT_PAGE = "RESET_GO_TO_NEXT_PAGE";
export const REMOVE_STEP_DATA = "REMOVE_STEP_DATA";
export const SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL = "SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL";
export const IS_RESUBMIT_FLOW = "IS_RESUBMIT_FLOW";

export const RESET_LIQUIDATION_SUBMIT = "RESET_LIQUIDATION_SUBMIT";