const constant = {
    cardData: [
        {
            cardHeading: 'Profile',
            cardText:
                'Make updates to your Victory Capital profile including address, phone number, and email address. Keep financial information and employment details updated so that Victory Capital can better serve your needs.',
            cardLink: 'basicInformation',
        },
        {
            cardHeading: 'Delivery Settings',
            cardText: 'Choose how you would like to receive alerts and notifications related to your accounts',
            cardLink: 'DeliverySettings',
        },
        {
            cardHeading: 'Document Preferences',
            cardText: 'Choose how you would like to receive documents and correspondence related to your accounts.',
            cardLink: 'accountPreference',
        },
        {
            cardHeading: 'Security Preferences',
            cardText: 'Manage your log-in methods, change your password, and manage your devices.',
            cardLink: 'SecurityPreferences',
        },
        // {
        //     cardHeading: 'Marketing And Privacy',
        //     cardText:
        //         'Let us know how best to contact you with our latest offers, and read about how we use your information to provide the best service and experience.',
        //     cardLink: 'MarketingAndPrivacy',
        // },
    ],
    breadCrumbValues: [{ name: 'Home', url: '/accountHolder', dataAnalyticId:'CMS_profileandpreferences_breadcrumb_Home' }],
    PAGE_HEADING: 'Profiles & Preferences',
    NAME_NOTE_TEXT1:
        'For security reasons, not all information can be updated online.  Please call Victory Member Services at ',
    NAME_NOTE_TEXT2: ' for more details',
    MSR_NUMBER: '+1 (800) 235-8396',
    PHONE_HINT: 'Dial',
};

export default constant;
