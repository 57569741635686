import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import routeConstants from 'routeConstants';

import { WButton } from '../../../common';
import styles from './Styles';

export const RegisterCard = (props) => {
    const data = {
        // titleUsaa: 'Are you an existing USAA member?',
        infoUsaa: 'Existing USAA account holders to come through logging in to USAA',
        registerHandlerUsaa: () => {
            props.history.push('/Usaa');
        },
        buttonTextUsaa: 'Go to USAA',
        title: 'New to Victory or have a Victory / USAA Member ID with no access to online?',
        info: 'It’s easy to open your investment account.',
        registerHandler: () => {
            sessionStorage.setItem('stepname', 'Sign Up'); 
            props.history.push(routeConstants.personalDetails);
        },
        buttonText: 'Sign Up'
    }

    return (
        <div className="vcm-register">
            {/* <div style={styles.corner} />
            <div >
                <p style={styles.header}>{data.titleUsaa}</p>
            </div> 
            <p style={styles.para1}>{data.infoUsaa}</p>
            <WButton id="UsaaBtn" buttontext={data.buttonTextUsaa} onClick={data.registerHandlerUsaa} />
            <hr style={styles.hr} aria-hidden="true" /> */}
            <div style={styles.corner} />
            <div >
                <p style={styles.header}>{data.title}</p>
            </div>
            <p style={styles.para2}>{data.info}</p>
            <WButton id="SignUpBtn" data-analytic-id="UMS_SignIn_Sign Up" buttontext={data.buttonText} onClick={data.registerHandler} />
        </div>
    )
}

RegisterCard.propTypes = {
    history: PropTypes.shape([])
}

RegisterCard.defaultProps = {
    history: []
}
export default withRouter(RegisterCard);