import { LiveChatActionTypes } from '../ReduxConstants';
import makeApiRequest from '../Network/ApiInterface';

const {
    GET_LIVECHAT_QUEUE_METRICS_FAILURE,
    GET_LIVECHAT_QUEUE_METRICS_SUCCESS,
    GET_LIVECHAT_QUEUE_METRICS,
    GET_LIVECHAT_QUEUE_WAITTIME_FAILURE,
    GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS,
    GET_LIVECHAT_QUEUE_WAITTIME,
    GET_LIVECHAT_QUEUES_STATUS_FAILURE,
    GET_LIVECHAT_QUEUES_STATUS_SUCCESS,
    GET_LIVECHAT_QUEUES_STATUS,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
    GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS,
    GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE,
    GET_MSR_AVAILABILITY,
    GET_MSR_AVAILABILITY_LOADER,
    GET_MSR_AVAILABILITY_SUCCESS,
    GET_MSR_AVAILABILITY_FAILURE,
    GET_PHONE_QUEUE_WAITTIME_FAILURE,
    GET_PHONE_QUEUE_WAITTIME_SUCCESS,
    GET_PHONE_QUEUE_WAITTIME,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
    GET_PHONE_SUPPORT_HOURS_OF_OPERATION,
    RESET_FAB,
    RESET_LIVECHAT_QUEUE_STATE,
    RESET_PHONE_QUEUE_WAIT_TIME_FAB,
    RESET_SELECTED_QUERY,
    SET_CHAT_WIDGET_STATUS_FAILURE,
    SET_CHAT_WIDGET_STATUS,
    SET_LIVECHAT_QUEUE,
    SET_MODAL_ID,
    SET_SELECTED_QUERY,
    SET_SHOW_MODAL,
    SET_TOGGLED,
    TOGGLE_SPINNER,
} = LiveChatActionTypes;

const env = '';

const getCTHours = (datestring, showMeridiem = false, showTimeZone = false) => {
    let meridiem = 'AM';
    let hours = Number(datestring.slice(11, 13));
    const tz = 'CT';

    if (hours >= 12 && hours < 24) {
        hours %= 12;
        meridiem = 'PM';
    }
    if (hours === 0 || hours === 24) {
        hours = 12;
    }
    const mins = datestring.slice(14, 16);
    return `${hours}:${mins}${showMeridiem ? ` ${meridiem}` : ''}${showTimeZone ? ` ${tz}` : ''}`;
};





export const getLiveChatSupportHoursOfOperation = queueId => dispatch => {
    const successCallback = ({ status, data: hoursOfOperation }) => {
        if (Number(status) === 200 && hoursOfOperation && queueId) {
            const hoursRangeForDisplayText = `${getCTHours(hoursOfOperation.start, true)} - ${getCTHours(
                hoursOfOperation.end,
                true,
                true,
            )}`;
            const availabilityText = `VCM chat representatives are available online Monday through Friday ${hoursRangeForDisplayText} except on New York Stock Exchange holidays.`;

            dispatch({
                type: GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
                availabilityText,
            });
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE, error });
    };

    dispatch({ type: GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION });

    try {
        makeApiRequest(GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION, queueId, env, successCallback, errorCallback);
    } catch (error) {
        dispatch({ type: GET_LIVECHAT_SUPPORT_HOURS_OF_OPERATION_FAILURE, error });
    }
};

export const getPhoneSupportHoursOfOperation = scheduleId => dispatch => {
    const successCallback = ({ status, data: hoursOfOperation }) => {
        if (Number(status) === 200 && hoursOfOperation && scheduleId) {
            const hoursAvailable = `${getCTHours(hoursOfOperation.start, true)} - ${getCTHours(
                hoursOfOperation.end,
                true,
                true,
            )}`;
            const dashboardHoursAvailable = `${getCTHours(hoursOfOperation.start)} - ${getCTHours(
                hoursOfOperation.end,
            )}`;

            setTimeout(() => {
                dispatch({
                    type: GET_PHONE_SUPPORT_HOURS_OF_OPERATION_SUCCESS,
                    scheduleId,
                    hoursAvailable,
                    dashboardHoursAvailable,
                    startTime: hoursOfOperation.start,
                    endTime: hoursOfOperation.end,
                });
            }, 1000);
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_PHONE_SUPPORT_HOURS_OF_OPERATION_FAILURE, error });
    };

    dispatch({ type: GET_PHONE_SUPPORT_HOURS_OF_OPERATION, scheduleId });

    try {
        makeApiRequest(GET_PHONE_SUPPORT_HOURS_OF_OPERATION, scheduleId, env, successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};

const getQueueData = (queuesArray, selectedQueue) => {
    // JEH: THIS NEEDS TO BE FIXED AT THE BACKEND IN THE FUTURE
    // Back-end developer declined to set this mapping at the back-end when asked.
    // If CHAT_OTHERS has its own id in the future, the following needs to be
    // deleted, sQ needs to be replaced with selectedQueue, and a new mapping
    // needs to be created at the API gateway.
    const sQ = selectedQueue === 'CHAT_OTHERS' ? 'CHAT_MUTUAL_FUND' : selectedQueue;
    const queueMetrics = {};
    queuesArray
        .filter(queue => sQ === queue.group.queueId)
        .forEach(metric => {
            queueMetrics[String(metric.data[0].metric)] = Number(metric.data[0].stats.count);
        });
    return queueMetrics;
};

const getQueueStatus = ({ oWaiting = 0, oOnQueueUsers = 0 }, threshold) => {
    let queueStatus = 'closed';
    if (oOnQueueUsers) {
        const customersToMsrsRatio = oWaiting / oOnQueueUsers;
        queueStatus = customersToMsrsRatio < threshold ? 'open' : 'closed';
    }

    return queueStatus;
};

export const getLiveChatQueueMetrics = (selectedQueue = '', payload) => (dispatch, getState) => {
    // oOnQueueUsers: liveChatAgentCount (number of active agents)
    // oWaiting: liveChatQueueCount (customers in line)
    const { queueCapacityThreshold: threshold } = getState().liveChatReducerData;

    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            const { oWaiting = 0, oOnQueueUsers = 0 } = getQueueData(data.results, selectedQueue);
            const queueStatus = getQueueStatus({ oWaiting, oOnQueueUsers }, threshold);
            dispatch({
                type: GET_LIVECHAT_QUEUE_METRICS_SUCCESS,
                oOnQueueUsers,
                oWaiting: oWaiting + 1,
                queueStatus,
            });
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_LIVECHAT_QUEUE_METRICS_FAILURE, error, modalId: 'ns-modal' });
    };

    dispatch({ type: GET_LIVECHAT_QUEUE_METRICS });

    try {
        makeApiRequest(GET_LIVECHAT_QUEUE_METRICS, payload, env, successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};

const getQueuesStatus = ({ totalOWaiting = 0, totalOOnQueueUsers = 0 }, threshold) => {
    let queuesStatus = 'closed';
    if (totalOOnQueueUsers) {
        const customersToMsrsRatio = totalOWaiting / totalOOnQueueUsers;
        queuesStatus = customersToMsrsRatio < threshold ? 'open' : 'closed';
    }

    return queuesStatus;
};

export const getLiveChatQueuesStatus = payload => (dispatch, getState) => {
    // oOnQueueUsers: liveChatAgentCount (number of active agents)
    // oWaiting: liveChatQueueCount (customers in line)
    const { queueCapacityThreshold: threshold } = getState().liveChatReducerData;

    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            const queuesStatus = getQueuesStatus(data, threshold);

            dispatch({
                type: GET_LIVECHAT_QUEUES_STATUS_SUCCESS,
                queuesStatus,
            });
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_LIVECHAT_QUEUES_STATUS_FAILURE, error, modalId: 'ns-modal' });
    };

    dispatch({ type: GET_LIVECHAT_QUEUES_STATUS });

    try {
        makeApiRequest(GET_LIVECHAT_QUEUES_STATUS, payload, env, successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};

const getWaitTimeString = (estimatedWaitTimeSeconds) => {
    let waitTimeString;
    let estimatedWaitTimeMinutes;

    const seconds = Number(estimatedWaitTimeSeconds);

    if (seconds === 0) {
        waitTimeString = '0 minutes';
    } else if (seconds > 1 && seconds < 90) {
        waitTimeString = '1 minute';
    } else if (seconds >= 90) {
        estimatedWaitTimeMinutes = Math.round(seconds / 60);
        waitTimeString = `${estimatedWaitTimeMinutes} minutes`;
    } else {
        waitTimeString = 'Unavailable';
    }

    return waitTimeString;
};

export const getLiveChatQueueWaitTime = queueName => dispatch => {
    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            const { estimatedWaitTimeSeconds } = data.results[0];
            const liveChatWaitTime = getWaitTimeString(estimatedWaitTimeSeconds);

            dispatch({ type: GET_LIVECHAT_QUEUE_WAITTIME_SUCCESS, liveChatWaitTime });
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_LIVECHAT_QUEUE_WAITTIME_FAILURE, error });
    };

    try {
        makeApiRequest(GET_LIVECHAT_QUEUE_WAITTIME, queueName, env, successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};

export const getPhoneQueueWaitTime = queueName => dispatch => {
    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            const { estimatedWaitTimeSeconds } = data.results[0];
            const phoneQueueWaitTime = getWaitTimeString(estimatedWaitTimeSeconds);

            dispatch({ type: GET_PHONE_QUEUE_WAITTIME_SUCCESS, phoneQueueWaitTime });
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_PHONE_QUEUE_WAITTIME_FAILURE, error });
    };

    dispatch({ type: GET_PHONE_QUEUE_WAITTIME });

    try {
        makeApiRequest(GET_PHONE_QUEUE_WAITTIME, queueName, env, successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};

export const getMsrAvailability = (serviceType, schedule, token) => async dispatch => {
    // serviceType: "chat" or "phone"
    const payload = { data: schedule, token };

    if (serviceType !== 'phone' && serviceType !== 'chat') {
        throw new Error('Must provide serviceType "chat" or "phone" as first arguement to getMsrAvailability()');
    }

    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            if (serviceType === 'chat') {
                const { status: isLiveChatAvailable } = data;
                dispatch({ type: GET_MSR_AVAILABILITY_SUCCESS, isLiveChatAvailable });
            } else if (serviceType === 'phone') {
                const { status: isPhoneSupportAvailable } = data;
                dispatch({ type: GET_MSR_AVAILABILITY_SUCCESS, isPhoneSupportAvailable });
            }
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_MSR_AVAILABILITY_FAILURE, error });
    };

    try {
        dispatch({ type: GET_MSR_AVAILABILITY_LOADER });

        makeApiRequest(GET_MSR_AVAILABILITY, payload, env, successCallback, errorCallback);
    } catch (error) {
        dispatch({ type: GET_MSR_AVAILABILITY_FAILURE, error });
    }
};

export const getMsrAvailabilityFromSchedules = (serviceType, scheduleGroup, token) => dispatch => {
    // serviceType: "chat" or "phone"
    const payload = { data: scheduleGroup, token };

    if (serviceType !== 'phone' && serviceType !== 'chat') {
        throw new Error(
            'Must provide serviceType "chat" or "phone" as first arguement to getMsrAvailabilityFromSchedules()',
        );
    }

    const successCallback = ({ status, data }) => {
        if (Number(status) === 200 && data) {
            if (serviceType === 'chat') {
                const { status: isLiveChatAvailable } = data;
                dispatch({ type: GET_MSR_AVAILABILITY_FROM_SCHEDULES_SUCCESS, isLiveChatAvailable });
            } else if (serviceType === 'phone') {
                const { status: isPhoneSupportAvailable } = data;
                dispatch({ type: GET_MSR_AVAILABILITY_SUCCESS, isPhoneSupportAvailable });
            }
        }
    };

    const errorCallback = error => {
        dispatch({ type: GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE, error });
    };

    try {
        dispatch({ type: GET_MSR_AVAILABILITY_FROM_SCHEDULES_LOADER });

        makeApiRequest(GET_MSR_AVAILABILITY_FROM_SCHEDULES, payload, env, successCallback, errorCallback);
    } catch (error) {
        dispatch({ type: GET_MSR_AVAILABILITY_FROM_SCHEDULES_FAILURE, error });
    }
};

export const resetFab = () => dispatch => {
    dispatch({ type: RESET_FAB });
};

export const resetSelectedQuery = () => dispatch => {
    dispatch({ type: RESET_SELECTED_QUERY });
};

export const resetLiveChatQueueState = (
    oWaiting = null,
    oOnQueueUsers = null,
    queueStatus = null,
    liveChatWaitTime = null,
) => async dispatch => {
    await Promise.all([
        dispatch({
            type: RESET_LIVECHAT_QUEUE_STATE,
            oWaiting,
            oOnQueueUsers,
            queueStatus,
            liveChatWaitTime,
        }),
    ]);
};

export const resetPhoneQueueWaitTimeFab = () => dispatch => {
    dispatch({ type: RESET_PHONE_QUEUE_WAIT_TIME_FAB });
};

export const setChatWidgetStatus = chatWidgetStatus => dispatch => {
    if (['started', 'connected', 'ended', null].includes(chatWidgetStatus)) {
        dispatch({ type: SET_CHAT_WIDGET_STATUS, chatWidgetStatus });
    } else {
        const error = `Failed to dispatch chatWidgetStatus to LiveChatReducer.
            Parameter 's' having value ${chatWidgetStatus} passed
            to action setChatWidgetStatus().`;
        dispatch({ type: SET_CHAT_WIDGET_STATUS_FAILURE, error });
    }
};

export const setShowModal = showModal => dispatch => {
    dispatch({ type: SET_SHOW_MODAL, showModal });
};

export const setSelectedQuery = selectedQuery => dispatch => {
    dispatch({ type: SET_SELECTED_QUERY, selectedQuery });
};

export const setLiveChatQueue = queueName => dispatch => {
    dispatch({ type: SET_LIVECHAT_QUEUE, queueName });
};

export const setModalId = modalId => dispatch => {
    dispatch({ type: SET_MODAL_ID, modalId });
};

export const setToggled = toggled => dispatch => {
    dispatch({ type: SET_TOGGLED, toggled });
};

export const toggleSpinner = isSpinning => dispatch => {
    dispatch({ type: TOGGLE_SPINNER, isSpinning });
};
