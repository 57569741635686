/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component returns the wire transfer offline  payment method
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { WCard } from '../../../common';
import consts from './constants';
import './confirmAccountOpening.css';

const WireTransferComponent = props => {
    const { heading } = props;
    const {
        wireTransferMsg1,
        wireTransferMsg2,
        wireTransferMsg3,
        wireTransferTitleStyle,
        wireTransferCardTextStyle,
        wireTransferMailingAddressHeading,
        displayNoneStyle,
        wireTransferMailingAddressText,
        wireTransferCardBodyStyle,
    } = consts;
    return (
        <div className="dividendHint fundingTypecard wireTransferCard">
            <Row noGutters className="sectionHeader">
                <Col xs={12}>
                    <h3 className="sectionHeading">{heading}</h3>
                </Col>
            </Row>
            <Row noGutters>
                <Col xs={5}>
                    <p>{wireTransferMsg1}</p>
                    <p className="boldPara">{wireTransferMsg2}</p>
                    <p className="boldPara">{wireTransferMsg3}</p>
                </Col>
                <Col xs={1} />
                <Col xs={5}>
                    <div className="specimenCardWrapper">
                        <WCard
                            className="specimenCard wireTransferCard new-line"
                            buttonstyle={displayNoneStyle}
                            cardtitletext={wireTransferMailingAddressHeading}
                            cardtext={wireTransferMailingAddressText}
                            cardtextstyle={wireTransferCardTextStyle}
                            cardbodystyle={wireTransferCardBodyStyle}
                            cardtitlestyle={wireTransferTitleStyle}
                            linkcard="true"
                            alt=""
                            src=""
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

WireTransferComponent.propTypes = {
    heading: PropTypes.string,
};

WireTransferComponent.defaultProps = {
    heading: 'heading',
};

export default memo(WireTransferComponent);
