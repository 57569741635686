import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { focusToNonFocusableEl } from 'modules/CustomerManagementModule/utils';
import RadioGroup from '../RadioGroup';
import PrimaryBtn from '../PrimaryBtn';
// import CancelBtn from '../CancelBtn';
import BottomNavBar from '../BottomNavBar';
import './MessagePopup.css';

const Cornerdesign = styled.div`
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8bc105;
    border-right: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    @media(max-width:767px){
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
    }
`
const ConfirmationMessageBox = styled.div`
    padding:${props => (props.deviceReg ? '3.5rem 0rem 0rem' : '2.5rem 4rem 2rem')} ;
    z-index: 10000;
    position: absolute;
    width: 60%;
    transform: translate(-50%, -50%);
    left: 50%;
    top:50% !important;
    background: #fff;
    @media(max-width:991px){
        width: 80%;
    }
    @media(max-width:767px){
        width: 90%;
        padding: 2.5rem 0rem 0rem;
        scroll-behavior: smooth;
        overflow-y: scroll;
        max-height: 26rem;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #486d90;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #ececec;
            border-radius: 5px;
        }
    }
`;

const BackDrop = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    @supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
`;
const StyledPara = styled.div`
    margin: auto;
`;

const StyledPara2 = styled.div`
    margin: auto;
    font: 800 18px/22px benton-sans;
    margin-top:24px;
`;

const StyledPara3 = styled.div`
    margin: auto;
    font: 500 16px/24px benton-sans;
    margin-top:30px;
`;

const ConformationTitle = styled.h2`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;
const ConformationTitleH1 = styled.h1`
    font: 800 30px / 36px yorkten-slab-normal;    
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    margin-bottom: 30px;
    color: rgb(72, 109, 144);
    display: inline-block;
    border-bottom: 2px solid rgb(140, 191, 38);
    padding-bottom: 10px;
`;
const ConformationBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: ${props => (props.deviceReg ? '' : '1.25rem')};
    @media(max-width:767px){
        button {
            margin-left: auto;
            width: 100%;
        }
    }
`;

const Closeicon = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background: #fff;    
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#486D90;
`
const ESCAPE_KEY_OR_CODE = 'Escape';
const TAB_KEY_OR_CODE = 'Tab';

const onDialogShow = ({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId }) => {
    document.documentElement.style.overflow = 'hidden';
    // window.scrollTo(0, 0);
    const prevFocusedEl = previousFocus;
    const preNode = preNodeRef;
    const postNode = postNodeRef;
    prevFocusedEl.current = document.activeElement;
    preNode.current.tabIndex = 0;
    postNode.current.tabIndex = 0;
    // set focus on first input
    // focusToNonFocusableEl(titleRef.current);
    setTimeout(() => {
        [].slice.call(document.body.children).forEach(node => {
            if (node.getAttribute('id') !== `vcm-dialog-container-${keyId}`) {
                node.setAttribute('inert', 'true');
            }
        });
    }, 100);
};

const onDialogHide = ({ previousFocus, deviceReg }) => {
    const isOverflowHidden = document.documentElement.style.overflow === 'hidden';
    if (isOverflowHidden) document.documentElement.style.overflow = '';
    [].slice.call(document.body.children).forEach(node => {
        const el = node;
        const isHidden = el.hasAttribute('inert');
        if (isHidden) {
            el.inert = false;
        }
    });
    // const prevFocusedEl = previousFocus;
    // if (prevFocusedEl && prevFocusedEl.current) {
    //     setTimeout(() => {
    //         (!deviceReg? prevFocusedEl.current.focus(): null);
    //         prevFocusedEl.current = null;
    //     }, 100);
    // }
};

// Handling onclosing dialog
const onClose = ({ onSecondaryClick, onPrimaryClick }) => e => {
    onSecondaryClick ? onSecondaryClick(e) : onPrimaryClick(e);
};


// redirect first shift+tab to last input
const onFirstShiftTab = ({ lastElRef, onSecondaryClick }) => e => {
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose({ onSecondaryClick })(e);
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && e.shiftKey) {
        e.preventDefault();
        lastElRef.current.focus();
    }
};

// If escape pressed when focus on title
const onTitleKeyDown = ({ onSecondaryClick }) => e => {
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose({ onSecondaryClick })(e);
};

const moveFocusToTitle = ({ titleRef }) => () => {
    // set focus on first input
    focusToNonFocusableEl(titleRef.current);
};
const popupsubmitBtnTxt = 'Submit'
const verticalAlign =  true;
const boldText = true;

const MessagePopup = props => {
    const titleRef = useRef(null);
    const firstElRef = useRef(null);
    const lastElRef = useRef(null);
    const preNodeRef = useRef(null);
    const postNodeRef = useRef(null);
    const previousFocus = useRef(null);

    const {
        modalLabel,
        modalTitle,
        modalBodyText,
        modalBodyText2,
        primaryButtonText,
        secondaryButtonText,
        onPrimaryClick,
        onSecondaryClick,
        show,
        keyId,
        justify,
        deviceReg,
        deviceDeReg,
        handleUserPref,
        regDeviceOptions,
        primaryBtnProps,
        cancelBtnProps,
        initialValue,
        dataArray,
        modalBodyText3,
        modalBodyText4,
        modalBodyText5,
        textBold
    } = props;

    useEffect(() => {
        if (show) {
            onDialogShow({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId });
        }
    }, [show, keyId]);

    // to handle scenarios like session timeout
    useEffect(() => {
        return () => {
            onDialogHide({ previousFocus, deviceReg });
        };
    }, []);

    if (!show) {
        onDialogHide({ previousFocus, deviceReg });
    }
    
    return ReactDOM.createPortal(
        <BackDrop id={`vcm-dialog-container-${keyId}`} onClick={moveFocusToTitle({ titleRef })} show={show}>
            <div ref={preNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
            <ConfirmationMessageBox
                id={`vcm-alert-dialog-${keyId}`}
                role="dialog"
                aria-modal="true"
                aria-label={modalLabel}
                className="position-relative"
                deviceReg={deviceReg}
            >   
                <Cornerdesign/>
                {(!deviceReg|| deviceDeReg) && <Closeicon
                    type="button"
                    onClick={onClose({ onSecondaryClick })}
                    onKeyDown={onFirstShiftTab({ lastElRef, onSecondaryClick })}
                    ref={titleRef}
                >
                    <span style={{fontSize: '1.5rem', lineHeight: '0.8'}}>×</span>
                    <span style={{fontSize: 'x-small', fontWeight: 'bold'}}>Close</span>
                </Closeicon>
                }
                
                        {deviceReg ? (
                           <>
                           <div className={`mx-4 ${deviceDeReg? 'mb-4':''} `}>
                                <ConformationTitleH1
                                    onKeyDown={onTitleKeyDown({ onSecondaryClick })}
                                    id={`vcm-modal-title-${keyId}`}
                                >
                                    {modalTitle}
                                </ConformationTitleH1>
                                <ConformationBody id={`vcm-modal-title-desc-${keyId}`}>
                                    {!deviceDeReg && <><RadioGroup
                                        labelledBy="vcm-deviceregistration-options"
                                        options={regDeviceOptions}
                                        onChange={handleUserPref}
                                        initialValue={initialValue}
                                        vertical={verticalAlign}
                                        className="fundToRadioWrapper"
                                        boldText={boldText}
                                    />
                                    <StyledPara3>{modalBodyText3}</StyledPara3>
                                    <StyledPara3>{modalBodyText4}</StyledPara3>
                                    <StyledPara3>{modalBodyText5}</StyledPara3>
                                    </>
                                    }
                                    
                                    {deviceDeReg && !!modalBodyText && <StyledPara className="vcm-model-titlePara">{modalBodyText}</StyledPara>}
                                    {deviceDeReg && (dataArray.length > 0) && dataArray.map(el => {
                                        return (<StyledPara2 key={el.nickName+Math.random()}> {el.nickName} </StyledPara2>)
                                     })
                                   }
                                </ConformationBody>
                            </div>
                            <div className='popupfooter'>
                                <BottomNavBar 
                                    showCancelBtn={deviceDeReg ? true: false}
                                    hideBack={verticalAlign}
                                    primaryBtnText={popupsubmitBtnTxt}
                                    primaryBtnProps={primaryBtnProps}
                                    cancelBtnProps={cancelBtnProps}
                                />
                            </div>
                             </>
                            ) :
                    
                    <Container>
                        {!!modalTitle && (
                          
                            <ConformationTitle
                                onKeyDown={onTitleKeyDown({ onSecondaryClick })}
                                id={`vcm-modal-title-${keyId}`}
                            >
                                {modalTitle}
                            </ConformationTitle>
                        )}
                    
                    <ConformationBody id={`vcm-modal-title-desc-${keyId}`}>
                        {!!modalBodyText && <StyledPara className={`vcm-model-titlePara ${textBold}`}>{modalBodyText}</StyledPara>}
                        {!!modalBodyText2 && <StyledPara className={`mt-3 ${textBold}`}> {modalBodyText2} </StyledPara>}
                    </ConformationBody>
                    <FlexDiv className={`cancel-BtnAlign d-flex ${justify}`}>
                        <PrimaryBtn
                            ref={lastElRef}
                            id={`vcm-modal-primary-btn-${keyId}`}
                            data-testid={`vcm-modal-primary-btn-${keyId}`}
                            onClick={onClose({ onSecondaryClick })}
                            onKeyDown={onFirstShiftTab({ lastElRef, onSecondaryClick })}
                        >
                            {secondaryButtonText}
                        </PrimaryBtn>
                        {primaryButtonText === '' ? '' : 
                        <PrimaryBtn
                            ref={lastElRef}
                            id={`vcm-modal-primary-btn-${keyId}`}
                            data-testid={`vcm-modal-primary-btn-${keyId}`}
                            onClick={onClose({ onPrimaryClick })}
                            onKeyDown={onFirstShiftTab({ firstElRef, onPrimaryClick })}
                        >
                            {primaryButtonText}
                        </PrimaryBtn>}
                    </FlexDiv>
                </Container>
                }
            </ConfirmationMessageBox>
            <div ref={postNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
        </BackDrop>,
        document.body,
    );
};

MessagePopup.propTypes = {
    modalLabel: PropTypes.string,
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    modalBodyText3: PropTypes.string,
    modalBodyText4: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    show: PropTypes.bool,
    justify: PropTypes.string,
    textBold: PropTypes.string,
    keyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deviceReg: PropTypes.bool,
    deviceDeReg: PropTypes.bool,
    dataArray: PropTypes.instanceOf(Array)
};

MessagePopup.defaultProps = {
    modalLabel: 'Confirmation',
    modalTitle: '',
    modalBodyText: '',
    modalBodyText2: '',
    modalBodyText3: '',
    modalBodyText4: '',
    primaryButtonText: '',
    secondaryButtonText: 'Cancel',
    onPrimaryClick: () => {},
    onSecondaryClick: () => {},
    show: false,
    keyId: 0,
    justify: 'justifyRightStyle',
    textBold: 'normal',
    deviceReg: false,
    deviceDeReg: false,
    dataArray: []
};

export default MessagePopup;
