const EConsentData = [
    {
        formId: '98008',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAAG/index.html?open=summary',
        fundCode: '38',
        fileName: 'Aggressive Growth Fund.pdf',
        description: 'Summary Prospectus for USAA Aggressive Growth Fund',
    },
    {
        formId: '98009',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAG/index.html?open=summary',
        fundCode: '41',
        fileName: 'Growth Fund.pdf',
        description: 'Summary Prospectus for USAA Growth Fund',
    },
    {
        formId: '98010',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAGI/index.html?open=summary',
        fundCode: '37',
        fileName: 'Growth & Income Fund.pdf',
        description: 'Summary Prospectus for USAA Growth & Income Fund',
    },
    {
        formId: '98011',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAI/index.html?open=summary',
        fundCode: '40',
        fileName: 'Income Fund.pdf',
        description: 'Summary Prospectus for USAA Income Fund',
    },
    {
        formId: '98012',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAIS/index.html?open=summary',
        fundCode: '35',
        fileName: 'Income Stock Fund.pdf',
        description: 'Summary Prospectus for USAA Income Stock Fund',
    },
    {
        formId: '98013',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAASTB/index.html?open=summary',
        fundCode: '36',
        fileName: 'Short-Term Bond Fund.pdf',
        description: 'Summary Prospectus for USAA Short-Term Bond Fund',
    },
    {
        formId: '98014',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAMM/index.html?open=summary',
        fundCode: '42',
        fileName: 'Money Market Fund.pdf',
        description: 'Summary Prospectus for USAA Money Market Fund',
    },
    {
        formId: '98016',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAST/index.html?open=summary',
        fundCode: '31',
        fileName: 'Science & Technology Fund.pdf',
        description: 'Summary Prospectus for USAA Science & Technology Fund',
    },
    {
        formId: '98017',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACG/index.html?open=summary',
        fundCode: '72',
        fileName: 'Capital Growth Fund.pdf',
        description: 'Summary Prospectus for USAA Capital Growth Fund',
    },
    {
        formId: '98018',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAHI/index.html?open=summary',
        fundCode: '80',
        fileName: 'High Income Fund.pdf',
        description: 'Summary Prospectus for USAA High Income Fund',
    },
    {
        formId: '98019',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAITB/index.html?open=summary',
        fundCode: '30',
        fileName: 'Intermediate-Term Bond Fund.pdf',
        description: 'Summary Prospectus for USAA Intermediate-Term Bond Fund',
    },
    {
        formId: '98020',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAASCS/index.html?open=summary',
        fundCode: '81',
        fileName: 'Small Cap Stock Fund.pdf',
        description: 'Summary Prospectus for USAA Small Cap Stock Fund',
    },
    {
        formId: '98021',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAV/index.html?open=summary',
        fundCode: '76',
        fileName: 'Value Fund.pdf',
        description: 'Summary Prospectus for USAA Value Fund',
    },
    {
        formId: '98025',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAEMI/index.html?open=summary',
        fundCode: '73',
        fileName: 'Extended Market Index Fund.pdf',
        description: 'Summary Prospectus for USAA Extended Market Index Fund',
    },
    {
        formId: '98027',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAUSTB/index.html?open=summary',
        fundCode: '77',
        fileName: 'Ultra Short-Term Bond Fund.pdf',
        description: 'Summary Prospectus for USAA Ultra Short-Term Bond Fund',
    },
    {
        formId: '98029',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATRI/index.html?open=summary',
        fundCode: '85',
        fileName: 'Target Retirement Income Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement Income Fund',
    },
    {
        formId: '98030',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATR2020/index.html?open=summary',
        fundCode: '86',
        fileName: 'Target Retirement 2020 Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement 2020 Fund',
    },
    {
        formId: '98031',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATR2030/index.html?open=summary',
        fundCode: '87',
        fileName: 'Target Retirement 2030 Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement 2030 Fund',
    },
    {
        formId: '98032',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATR2040/index.html?open=summary',
        fundCode: '88',
        fileName: 'Target Retirement 2040 Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement 2040 Fund',
    },
    {
        formId: '98033',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATR2050/index.html?open=summary',
        fundCode: '89',
        fileName: 'Target Retirement 2050 Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement 2050 Fund',
    },
    {
        formId: '98034',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATR2060/index.html?open=summary',
        fundCode: '90',
        fileName: 'Target Retirement 2060 Fund.pdf',
        description: 'Summary Prospectus for USAA Target Retirement 2060 Fund',
    },
    {
        formId: '98035',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAGMV/index.html?open=summary',
        fundCode: '94',
        fileName: 'Global Managed Volatility Fund.pdf',
        description: 'Summary Prospectus for USAA Global Managed Volatility Fund',
    },
    {
        formId: '98036',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATELT/index.html?open=summary',
        fundCode: '43',
        fileName: 'Tax Exempt Long-Term Fund.pdf',
        description: 'Summary Prospectus for USAA Tax Exempt Long-Term Fund',
    },
    {
        formId: '98037',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATEIT/index.html?open=summary',
        fundCode: '44',
        fileName: 'Tax Exempt Intermediate-Term Fund.pdf',
        description: 'Summary Prospectus for USAA Tax Exempt Intermediate-Term Fund',
    },
    {
        formId: '98038',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATEST/index.html?open=summary',
        fundCode: '45',
        fileName: 'Tax Exempt Short-Term Fund.pdf',
        description: 'Summary Prospectus for USAA Tax Exempt Short-Term Fund',
    },
    {
        formId: '98039',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATEMM/index.html?open=summary',
        fundCode: '46',
        fileName: 'Tax Exempt Money Market Fund.pdf',
        description: 'Summary Prospectus for USAA Tax Exempt Money Market Fund',
    },
    {
        formId: '98040',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACB/index.html?open=summary',
        fundCode: '60',
        fileName: 'California Bond Fund.pdf',
        description: 'Summary Prospectus for USAA California Bond Fund',
    },
    {
        formId: '98042',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAANYB/index.html?open=summary',
        fundCode: '62',
        fileName: 'New York Bond Fund.pdf',
        description: 'Summary Prospectus for USAA New York Bond Fund',
    },
    {
        formId: '98044',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAVB/index.html?open=summary',
        fundCode: '64',
        fileName: 'Virginia Bond Fund.pdf',
        description: 'Summary Prospectus for USAA Virginia Bond Fund',
    },
    {
        formId: '98346',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAGEI/index.html?open=summary',
        fundCode: '75',
        fileName: 'Global Equity Income Fund.pdf',
        description: 'Summary Prospectus for USAA Global Equity Income Fund',
    },
    {
        formId: '98046',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACMC/index.html?open=summary',
        fundCode: '48',
        fileName: 'Cornerstone Moderately Conservative Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Moderately Conservative Fund',
    },
    {
        formId: '98047',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACA/index.html?open=summary',
        fundCode: '49',
        fileName: 'Cornerstone Aggressive Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Aggressive Fund',
    },
    {
        formId: '98053',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACE/index.html?open=summary',
        fundCode: '79',
        fileName: 'Cornerstone Equity Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Equity Fund',
    },
    {
        formId: '98048',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACM/index.html?open=summary',
        fundCode: '47',
        fileName: 'Cornerstone Moderate Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Moderate Fund',
    },
    {
        formId: '98049',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACMA/index.html?open=summary',
        fundCode: '51',
        fileName: 'Cornerstone Moderately Aggressive Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Moderately Aggressive Fund',
    },
    {
        formId: '98050',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAACC/index.html?open=summary',
        fundCode: '78',
        fileName: 'Cornerstone Conservative Fund.pdf',
        description: 'Summary Prospectus for USAA Cornerstone Conservative Fund',
    },
    {
        formId: '98051',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAGTS/index.html?open=summary',
        fundCode: '53',
        fileName: 'Growth and Tax Strategy Fund.pdf',
        description: 'Summary Prospectus for USAA Growth and Tax Strategy Fund',
    },
    {
        formId: '98052',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAEM/index.html?open=summary',
        fundCode: '56',
        fileName: 'Emerging Markets Fund.pdf',
        description: 'Summary Prospectus for USAA Emerging Markets Fund',
    },
    {
        formId: '98054',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAPMM/index.html?open=summary',
        fundCode: '50',
        fileName: 'Precious Metals and Minerals Fund.pdf',
        description: 'Summary Prospectus for USAA Precious Metals and Minerals Fund',
    },
    {
        formId: '98055',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAINTL/index.html?open=summary',
        fundCode: '52',
        fileName: 'International Fund.pdf',
        description: 'Summary Prospectus for USAA International Fund',
    },
    {
        formId: '98056',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAWG/index.html?open=summary',
        fundCode: '54',
        fileName: 'World Growth Fund.pdf',
        description: 'Summary Prospectus for USAA World Growth Fund',
    },
    {
        formId: '98057',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAGS/index.html?open=summary',
        fundCode: '58',
        fileName: 'Government Securities Fund.pdf',
        description: 'Summary Prospectus for USAA Government Securities Fund',
    },
    {
        formId: '98058',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAATMMT/index.html?open=summary',
        fundCode: '59',
        fileName: 'Treasury Money Market Trust.pdf',
        description: 'Summary Prospectus for USAA Treasury Money Market Trust',
    },
    {
        formId: '98059',
        docUrl: 'https://VCM.onlineprospectus.net/VCM/USAAMA/index.html?open=summary',
        fundCode: '96',
        fileName: 'Managed Allocation Fund.pdf',
        description: 'Summary Prospectus for USAA Managed Allocation Fund',
    },
];

export default EConsentData;
