import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WBreadCrumb, VCMSelect, WSpinner } from 'common';
import routeConstants from 'routeConstants';
import {
    BottomNavBar,
    InstructionalPanel,
    Address,
    InputText,
    FieldValue,
    PageTitle,
} from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/EmploymentInformation/constants';
import {
    getKeyfromValue,
    getMetadataValues,
    getFieldValue,
    hasValueChanged,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const Description = styled.p`
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 1.5625rem;
    color: #56565a;
    letter-spacing: 0;
    opacity: 1;
`;

const primaryBtnProps = {};
const cancelBtnProps = {};

export default class EmploymentInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
            apiFields: {},
        };
    }

    componentDidMount() {
        const { getCompositeLookUpData, profileInformationData, setEmployerAddress } = this.props;
        const payload = this.getLookUpPayload();
        if (payload.length) getCompositeLookUpData(payload);

        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        const { addressLine1 = '', addressLine2 = '', employersCity = '', employersState = '', employersZipcode = '' } =
            employmentInformation || {};

        addFormAbandonUnloadListner(true);

        // From API null values are possible so one level of checking is done.
        const addressLine1Value = addressLine1 || '';
        const addressLine2Value = addressLine2 || '';
        const city = employersCity || '';
        const state = employersState || '';
        const zip = employersZipcode || '';
        // Setting Employer Address to Address component
        setEmployerAddress(
            {
                addressLine1: addressLine1Value,
                addressLine2: addressLine2Value,
                zip,
                city,
                state,
            },
            'address',
        );
    }

    componentDidUpdate() {
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, location, linkResponseError, analyzeClickEvent } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        const { pathname } = location;
        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_manageFinancialInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_manageFinancialInfo_save`, analyzeClickEvent);
            history.push(routeConstants.basicinformation, { from: pathname });
        }
    }

    componentWillUnmount() {
        const { profileInformationData } = this.props;
        const { isSuccess } = profileInformationData;
        if(!isSuccess){
            const digitalData = getFormAbandData(true);
            const { fieldlasttouch } = digitalData;
            if (fieldlasttouch !== 'none') trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const { employment_status: empStatus, industry, prim_src_income: primarySrcIncome } = masterLookupStateData;

        const payLoad = [];
        if (!empStatus) payLoad.push('employment_status');
        if (!industry) payLoad.push('industry');
        if (!primarySrcIncome) payLoad.push('prim_src_income');

        return payLoad;
    };

    submitEmploymentInfo = e => {
        e.preventDefault();
        const { apiFields } = this.state;
        const { updateProfileOccupationInformations, employerAddressInfo } = this.props;
        const isEmployerDetailsNotReq = this.getIsEmpInfoReq();
        let payload;
        if (isEmployerDetailsNotReq) {
            payload = {
                ...apiFields,
                industry: '',
                industryOther: '',
                occupation: '',
                employerName: '',
                addressLine1: '',
                addressLine2: '',
                employersCity: '',
                employersState: '',
                employersZipcode: '',
            };
        } else {
            const { addressInformation } = employerAddressInfo;

            const { addressLine1, addressLine2, zip, city, state } = addressInformation || {};

            payload = {
                ...apiFields,
                addressLine1,
                addressLine2,
                employersCity: city,
                employersState: state,
                employersZipcode: zip,
                primarySourceOfIncome: '',
            };
        }
        updateProfileOccupationInformations(payload);
    };

    handleDropDownChange = fieldName => e => {
        const { target, list = [] } = e;
        const { value } = target;
        const { apiFields, errorMsg } = this.state;
        const fieldVal = list.find(val => val.key === value);
        const isEmployerDetailsNotReq = this.getIsEmpInfoReq();
        if (!isEmployerDetailsNotReq) {
            apiFields.primarySourceOfIncome = '';
            errorMsg.primarySourceOfIncome = '';
        }
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        apiFields[fieldName.toString()] = fieldVal.value;
        errorMsg[`${fieldName}`] = getFieldValue(apiFields, employmentInformation, fieldName)
            ? ''
            : CONST.ERROR_MESSAGES[`${fieldName}`];
        this.setState({
            apiFields,
            errorMsg,
        });
    };

    handleBlur = fieldName => () => {
        const { errorMsg, apiFields } = this.state;
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        errorMsg[`${fieldName}`] = getFieldValue(apiFields, employmentInformation, fieldName)
            ? ''
            : CONST.ERROR_MESSAGES[`${fieldName}`];
        this.setState({ errorMsg });
    };

    handleInputChange = fieldName => e => {
        const { target } = e;
        const { value } = target;
        const { apiFields } = this.state;
        apiFields[fieldName.toString()] = value.replace(/[^A-Za-z\s]/gi, '').replace(/  +/g, ' ');
        this.setState({
            apiFields,
        });
    };

    getIsEmpInfoReq = () => {
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        const { apiFields } = this.state;
        const currentEmpStatus = getFieldValue(apiFields, employmentInformation, 'employmentStatus');
        const empClassForNoEmployer = ['Retired', 'Unemployed', 'Homemaker', 'Student'];
        return empClassForNoEmployer.includes(currentEmpStatus);
    };

    hasAddressChanged = () => {
        const { profileInformationData, employerAddressInfo } = this.props;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        const { addressLine1 = '', addressLine2 = '', employersCity = '', employersState = '', employersZipcode = '' } =
            employmentInformation || {};
        const { addressInformation } = employerAddressInfo;
        const {
            addressLine1: addressLine1Changed,
            addressLine2: addressLine2Changed,
            zip,
            city,
            state,
        } = addressInformation;

        const isAddressLine1Changed = addressLine1 !== addressLine1Changed;
        const isAddressLine2Changed = addressLine2 !== addressLine2Changed;
        const isZipChanged = employersZipcode !== zip;
        const isCityChanged = employersCity !== city;
        const isStateChanged = employersState !== state;

        return (
            isAddressLine1Changed ||
            isAddressLine2Changed ||
            isAddressLine2Changed ||
            isZipChanged ||
            isCityChanged ||
            isStateChanged
        );
    };

    statusOnEmpDetailsPresent = (apiFields, employmentInformation) => {
        const isIndustryChanged = hasValueChanged(apiFields, employmentInformation, 'industry');
        const isOccupationChanged = hasValueChanged(apiFields, employmentInformation, 'occupation');
        const isEmployerName = hasValueChanged(apiFields, employmentInformation, 'employerName');

        const isIndustryOtherChanged = hasValueChanged(apiFields, employmentInformation, 'industryOther');
        const isIndustryOtherNeed = this.isOtherIndustry(apiFields, employmentInformation);
        const otherEmpStatusChanged = isIndustryOtherNeed ? isIndustryOtherChanged : false;

        return (
            isIndustryChanged ||
            isOccupationChanged ||
            isEmployerName ||
            this.hasAddressChanged() ||
            otherEmpStatusChanged
        );
    };

    statusOnEmpDetailsNotPresent = (apiFields, employmentInformation) => {
        return hasValueChanged(apiFields, employmentInformation, 'primarySourceOfIncome');
    };

    isAnythingChanged = () => {
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};
        const { apiFields } = this.state;

        const isEmployerDetailsNotReq = this.getIsEmpInfoReq();

        const isEmpStatusChanged = hasValueChanged(apiFields, employmentInformation, 'employmentStatus');
        const isEmpStatusOtherChanged = hasValueChanged(apiFields, employmentInformation, 'otherEmployment');

        const isEmpStatusOtherNeed = this.isOtherEmpStatus(apiFields, employmentInformation);
        const otherEmpStatusChanged = isEmpStatusOtherNeed ? isEmpStatusOtherChanged : false;
        const isEmpStatusPresent = getFieldValue(apiFields, employmentInformation, 'employmentStatus');
        if (!isEmpStatusPresent) return false;

        let otherFieldsStatus;
        let industryOrPrimarySourcePresent;

        if (isEmployerDetailsNotReq) {
            industryOrPrimarySourcePresent = getFieldValue(apiFields, employmentInformation, 'primarySourceOfIncome');
            if (!industryOrPrimarySourcePresent) return false;
            otherFieldsStatus = this.statusOnEmpDetailsNotPresent(apiFields, employmentInformation);
        } else {
            industryOrPrimarySourcePresent = getFieldValue(apiFields, employmentInformation, 'industry');
            if (!industryOrPrimarySourcePresent) return false;
            otherFieldsStatus = this.statusOnEmpDetailsPresent(apiFields, employmentInformation);
        }

        return isEmpStatusChanged || otherFieldsStatus || otherEmpStatusChanged;
    };

    getMasterDataValues = () => {
        const { masterLookupStateData } = this.props;
        const empListValues = getMetadataValues(masterLookupStateData, 'employment_status');
        const industryValues = getMetadataValues(masterLookupStateData, 'industry');
        const primaryIncomeValues = getMetadataValues(masterLookupStateData, 'prim_src_income');

        return {
            empListValues,
            industryValues,
            primaryIncomeValues,
        };
    };

    isOtherIndustry = (apiFields, employmentInformation) => {
        const industryValue = getFieldValue(apiFields, employmentInformation, 'industry');
        return industryValue === 'Other Industry';
    };

    isOtherEmpStatus = (apiFields, employmentInformation) => {
        const empStatusValue = getFieldValue(apiFields, employmentInformation, 'employmentStatus');
        return empStatusValue === 'Other';
    };

    cancelEmploymentInfo = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_manageemploymentInfo_cancel`
        })
        history.push(routeConstants.basicinformation);
    };

    render() {
        const { apiFields, errorMsg } = this.state;
        const {
            employmentStatus: empStatusError,
            primarySourceOfIncome: primaySourceErrorText,
            industry: industryErrorText,
        } = errorMsg;
        const { profileInformationData, masterLookupStateData } = this.props;
        const { empListValues, industryValues, primaryIncomeValues } = this.getMasterDataValues();
        const loading = profileInformationData.isLoading;
        const { profileInformation } = profileInformationData;
        const { employmentInformation } = profileInformation || {};

        const { employmentStatus = '', primarySourceOfIncome = '', industry = '' } = employmentInformation || {};

        const isEmployerDetailsNotReq = this.getIsEmpInfoReq();

        const occupationValue = getFieldValue(apiFields, employmentInformation, 'occupation');
        const employerNameValue = getFieldValue(apiFields, employmentInformation, 'employerName');
        const industryOtherValue = getFieldValue(apiFields, employmentInformation, 'industryOther');
        const otherEmploymentValue = getFieldValue(apiFields, employmentInformation, 'otherEmployment');

        const employmentStatusInitialValue = getKeyfromValue(
            masterLookupStateData,
            'employment_status',
            employmentStatus,
        );
        const primarySourceInitialValue = getKeyfromValue(
            masterLookupStateData,
            'prim_src_income',
            primarySourceOfIncome,
        );
        const industryInitialValue = getKeyfromValue(masterLookupStateData, 'industry', industry);

        const showOtherIndustry = this.isOtherIndustry(apiFields, employmentInformation);
        const showOtherEmpStatus = this.isOtherEmpStatus(apiFields, employmentInformation);

        primaryBtnProps.onClick = this.submitEmploymentInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelEmploymentInfo;

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                <Container>
                    <WBreadCrumb breadCrumbItems={CONST.bredCrumItmes} activeCrumb={CONST.componentname} />
                    <PageTitle title={CONST.componentname} showMandatoryTxt />
                    <Description>{CONST.topHeading}</Description>
                    <VCMSelect
                        id="vcm-cms-employment-status"
                        name={CONST.EMPLOYMENT_STATUS}
                        label={CONST.EMPLOYMENT_STATUS}
                        itemlist={empListValues}
                        onChange={this.handleDropDownChange('employmentStatus')}
                        onBlur={this.handleBlur('employmentStatus')}
                        value={employmentStatusInitialValue}
                        labelsm={3}
                        valuesm={5}
                        errortext={empStatusError}
                        required
                    />
                    {showOtherEmpStatus && (
                        <FieldValue
                            htmlFor="vcm-cms-other-employment-status"
                            label=""
                            value={
                                <InputText
                                    id="vcm-cms-other-employment-status"
                                    onChange={this.handleInputChange('otherEmployment')}
                                    value={otherEmploymentValue || ''}
                                    aria-required
                                    data-name={CONST.OTHER_EMPLOYMENT}
                                    data-form-field
                                    maxLength={30}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb="1.6875"
                        />
                    )}
                    {isEmployerDetailsNotReq ? (
                        <>
                            <VCMSelect
                                id="vcm-cms-primary-source-of-income"
                                name={CONST.PRIMARY_SOURCE_OF_INCOME}
                                label={CONST.PRIMARY_SOURCE_OF_INCOME}
                                itemlist={primaryIncomeValues}
                                onChange={this.handleDropDownChange('primarySourceOfIncome')}
                                onBlur={this.handleBlur('primarySourceOfIncome')}
                                value={primarySourceInitialValue}
                                labelsm={3}
                                valuesm={5}
                                errortext={primaySourceErrorText}
                                required
                            />
                        </>
                    ) : (
                        <>
                            <VCMSelect
                                id="vcm-cms-industry"
                                name={CONST.INDUSTRY}
                                label={CONST.INDUSTRY}
                                itemlist={industryValues}
                                onChange={this.handleDropDownChange('industry')}
                                onBlur={this.handleBlur('industry')}
                                value={industryInitialValue}
                                labelsm={3}
                                valuesm={5}
                                errortext={industryErrorText}
                                required
                            />
                            {showOtherIndustry && (
                                <FieldValue
                                    htmlFor="vcm-cms-other-industry"
                                    label=""
                                    value={
                                        <InputText
                                            id="vcm-cms-other-industry"
                                            onChange={this.handleInputChange('industryOther')}
                                            value={industryOtherValue || ''}
                                            aria-required
                                            data-name={CONST.OTHER_INDUSTRY}
                                            data-form-field
                                            maxLength={30}
                                        />
                                    }
                                    labelsm={3}
                                    valuesm={5}
                                    mb="1.6875"
                                />
                            )}
                            <FieldValue
                                htmlFor="vcm-cms-occupation"
                                label={CONST.OCCUPATION}
                                value={
                                    <InputText
                                        id="vcm-cms-occupation"
                                        onChange={this.handleInputChange('occupation')}
                                        value={occupationValue || ''}
                                        data-name={CONST.OCCUPATION}
                                        data-form-field
                                        maxLength={30}
                                    />
                                }
                                labelsm={3}
                                valuesm={5}
                                mb="1.75"
                                optional
                            />
                            <FieldValue
                                htmlFor="vcm-cms-employerName"
                                label={CONST.EMPLOYER_NAME}
                                value={
                                    <InputText
                                        id="vcm-cms-employerName"
                                        value={employerNameValue || ''}
                                        onChange={this.handleInputChange('employerName')}
                                        optional
                                        data-name={CONST.EMPLOYER_NAME}
                                        data-form-field
                                        maxLength={30}
                                    />
                                }
                                labelsm={3}
                                valuesm={5}
                                mb="1.75"
                                optional
                            />
                            <Address
                                optional
                                addressLine1Text={CONST.EMPLOYER_ADDRESS_LINE1}
                                addressLine2Text={CONST.EMPLOYER_ADDRESS_LINE2}
                            />
                        </>
                    )}
                    <InstructionalPanel mb={1.5625} mbt={2.5}>
                        {CONST.line2}
                    </InstructionalPanel>
                </Container>
                <BottomNavBar
                    hideBack
                    showCancelBtn
                    primaryBtnText={CONST.SAVE}
                    primaryBtnProps={primaryBtnProps}
                    cancelBtnProps={cancelBtnProps}
                />
            </>
        );
    }
}

EmploymentInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getCompositeLookUpData: PropTypes.func,
    updateProfileOccupationInformations: PropTypes.func,
    profileInformationData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    clearExistingActions: PropTypes.func,
    setEmployerAddress: PropTypes.func,
    employerAddressInfo: PropTypes.instanceOf(Object),
    analyzeClickEvent: PropTypes.func,
	linkResponseError: PropTypes.func,
};

EmploymentInfoComponent.defaultProps = {
    getCompositeLookUpData: () => {},
    masterLookupStateData: {},
    updateProfileOccupationInformations: () => {},
    profileInformationData: {},
    clearExistingActions: () => {},
    setEmployerAddress: () => {},
    employerAddressInfo: {},
    analyzeClickEvent: () => {},
    linkResponseError: () => {},
};
