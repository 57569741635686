/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the military  info details card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generateMilitaryInfoCard(masterLookupStateData, milInfo, heading) {
    let militaryInfoCard = {};
    if (milInfo) {
        let milStatusData = '';
        let milBranchData = '';
        let milRankData = '';

        const milityStatusList = masterLookupStateData['mil_status'.toString()].value;
        milStatusData = milInfo.milityStatus && milityStatusList.find(item => item.key === milInfo.milityStatus).value;
        const branchOfService = milInfo && milInfo.branchOfService;
        const milBranchList = masterLookupStateData['mil_serv_branch'.toString()].value;
        milBranchData =
            milInfo.branchOfService && milBranchList.find(item => item.key === milInfo.branchOfService).value;

        const milRankList =
            branchOfService !== '06' && branchOfService !== ''
                ? masterLookupStateData[`mil_rank_${milInfo.branchOfService}`].value
                : '';

        milRankData =
            branchOfService !== '06'
                ? milInfo.rank && milRankList.find(item => item.key === milInfo.rank).value
                : milInfo.rank;

        militaryInfoCard = {
            cardId: heading !== undefined ? 'jointMilitaryInfo' : 'militaryInfo',
            linkText: Consts.edit,
            cardHeading: heading !== undefined ? Consts.milInfoJoint : Consts.milInfoH,
            fields: [
                {
                    uniqueKey: 'militaryStatus',
                    key: Consts.milStatus,
                    value: milStatusData === 'Other' ? milInfo.militaryOthers : milStatusData || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'branchOfService',
                    key: Consts.bos,
                    value: milBranchData === 'Other' ? milInfo.branchOfServiceOthers : milBranchData || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'rank',
                    key: Consts.rank,
                    value: milRankData || Consts.None,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'serviceDate',
                    key: Consts.dos,
                    value: `${milInfo.serviceFromDate || Consts.None}-${milInfo.serviceToDate || Consts.None}`,
                    type: Consts.staticfield,
                },
                {
                    uniqueKey: 'commissionSource',
                    key: Consts.commsrc,
                    value: milInfo.commissionCourse || Consts.None,
                    type: Consts.staticfield,
                },
            ],
        };
        if (milBranchData === 'Other') {
            militaryInfoCard.fields.splice(2, 1);
        }
    }

    return militaryInfoCard;
}
