import { isEmpty } from 'lodash';

const FormatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '+1 ';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
export default FormatPhoneNumber;

// eslint-disable-next-line
const formatSpecialChar = /[\`!@#$%^&*()_\[\]{};:|,.<>\/?]/;

export function ContainsSpecialChar(string) {
  if (string.indexOf('~') !== -1) return true;
  if (formatSpecialChar.test(string)) {
    return true;
  }
  return false;
}

const formatSpecialUserIdChar = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/;
export function ContainsSpecialUserIdChar(string) {
  if (string.indexOf('~') !== -1) return true;
  if (string.indexOf('\\') !== -1) return true;
  if (formatSpecialUserIdChar.test(string)) {
    return true;
  }
  return false;
}
const upper = /[A-Z]/;
const lower = /[a-z]/;
export function ContainsEitherUpperOrLower(string) {
  return upper.test(string) || lower.test(string);
}
// eslint-disable-next-line
const formatUpperLowerNumber = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

export function ContainsUpperLowerNumber(string) {
  if (formatUpperLowerNumber.test(string)) {
    return true;
  }
  return false;
}

const formatUpperLower = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
export function ContainsUpperLower(string) {
  if (formatUpperLower.test(string)) {
    return true;
  }
  return false;
}

const formatNumeric = /\d{1}/;

export function ContainsNumeric(string) {
  if (formatNumeric.test(string)) {
    return true;
  }
  return false;
}

export function ContainsNumericAtEnds(string) {
  let returnVal = false;
  if (
    formatNumeric.test(string.charAt(0))
    || formatNumeric.test(string.charAt(string.length - 1))
  ) {
    returnVal = false;
  } else if (string.length > 0) {
    returnVal = true;
  }
  return returnVal;
}
export function validateEmail(value) {
  const pattern = /^\w+[+.\w-]*@\w+[+.\w-]*\.([a-z]{2,4}|\d+)$/i;
  const isValid = pattern.test(value);
  if (!isValid) {
    return 'Your email address is not valid  - Please enter a valid email address';
  }
  return '';
}

export function validatePhone(value) {
  const pattern = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\\.]{0,1}[0-9]{3}[-\s\\.]{0,1}[0-9]{4}$/;
  const digits = value.replace(/\D/g, '');
  if (!pattern.test(digits)) {
    return 'Please enter a valid phone number';
  }
  return '';
}

export const getFormattedValue = (value) => {
  let formattedValue;
  if (value.length <= 3) {
    formattedValue = value;
  } else if (value.length > 3 && value.length < 7) {
    formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
  } else {
    formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
      6,
    )}`;
  }
  return formattedValue;
};

export const getMaskedValue = (value, numberOfDigits, maskedCharacter) => {
  const { length } = value;
  return `${value
    .substring(0, length - numberOfDigits)
    .replace(/\d/g, maskedCharacter)}${value.substring(
    length - numberOfDigits,
  )}`;
};

export const getMaskedPhoneFormat = (phoneNumber, countryCode) => {
  const { length } = phoneNumber;

  let formatPhone = `XXX-XXX-${phoneNumber.substring(length - 4)}`;
  if (!isEmpty(countryCode) && countryCode !== '1') {
    formatPhone = getMaskedValue(phoneNumber, 4, 'x');
  }
  return formatPhone;
};

export const getOTPErrorMsg = (OTCMsg) => {
  let errorMsg = OTCMsg;
  if (OTCMsg.toLowerCase() === 'error while verifying your device') {
    errorMsg = 'Unable to Process your request at this time. Please try again.';
  }
  return errorMsg;
};


export const validatePhoneNumber = (countryCode, phoneNumber) => {
  let isValid = true;
  isValid = isEmpty(countryCode) || countryCode === '1'
      ? FormatPhoneNumber(phoneNumber) !== null
      : true;
  return isValid;
}