import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mailToCardConstants, addresses } from './constants';
import { BasicCard } from '../../../common';

const S = {};

S.CardContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    min-height: 100%;
`;

S.Heading = styled.div`
    color: #486d90;
    font: 900 20px/22px yorkten-slab-normal;
`;

S.Divider = styled.span`
    height: 0px;
    border: 1px solid #d2d2d2;
    margin-top: 15px;
    margin-bottom: 15px;
`;

S.AddresseeText = styled.span`
    color: #49494a;
    font: 550 16px/24px benton-sans;
`;

S.AddressText = styled.span`
    color: #49494a;
    font: 400 16px/24px benton-sans;
`;

const cardProps = {
    height: '179px',
    width: '350px',
    triSideLength: '35px',
}

function MailToAddressCard({ className, appType }) {
    const { HEADING } = mailToCardConstants;
    const { ADDRESSEE, STREET_NUMBER, CITY_STATE_ZIP } = appType === '403b' ? addresses[appType] : addresses.other;

    return (
        <BasicCard {...cardProps} className={className} >
            <S.CardContent>
                <S.Heading>{HEADING}</S.Heading>
                <S.Divider />
                <S.AddresseeText>{ADDRESSEE}</S.AddresseeText>
                <S.AddressText>{STREET_NUMBER}</S.AddressText>
                <S.AddressText>{CITY_STATE_ZIP}</S.AddressText>
            </S.CardContent>
        </BasicCard>
    );
};

MailToAddressCard.defaultProps = {
    className: '',
};

MailToAddressCard.propTypes = {
    className: PropTypes.string,
    appType: PropTypes.string.isRequired,
};

export default MailToAddressCard;
