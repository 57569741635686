import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
// import routeConstants from 'routeConstants';

import '../Screens.css';
import JovSSNForm from '../../JovSSN/JovSSNForm';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles'; 
import pages from '../JovConstants';

class JovSSN extends Component {
  data = {
    title: "Joint Owner Verification",
    subtitle: "Hi Paul",
    info: ["Enter the details for User Verification"]
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        // ssnOne: {
        //     value: '', errMsg: null, valid: false, label: 'SSN',
        //     rules: { required: true, isNumeric: true,
        //         pattern: new RegExp('^[0-9]{3}$'),
        //         patternMessage: 'Should be 9 digits', }
        // },
        // ssnTwo: {
        //     value: '', errMsg: null, valid: false, label: 'SSN',
        //     rules: { required: true, isNumeric: true,
        //         pattern: new RegExp('^[0-9]{2}$'),
        //         patternMessage: 'Should be 9 digits', }
        // },
        // ssnThree: {
        //     value: '', errMsg: null, valid: false, label: 'SSN',
        //     rules: { required: true, isNumeric: true,
        //         pattern: new RegExp('^[0-9]{4}$'),
        //         patternMessage: 'Should be 9 digits', }
        // },
        
        ssn: {
          value: '',
          errMsg: null,
          valid: false,
          label: 'SSN',
          rules: {
            required: true,
            length: 9,
          }
        },
        dob: {
          value: '',
          errMsg: null,
          valid: false,
          touched: false,
          rules: {
            required: true,
            dobDate:true
          }
        }
      },
      formIsValid: false,
      ...props.jovSsnState
    };
    this.ssnOneRef = React.createRef();
    this.ssnTwoRef = React.createRef();
    this.ssnThreeRef = React.createRef();
  
    this.onNext = this.onNext.bind(this);
  
    this.onChange = this.onChange.bind(this);
  
    this.onBlur = this.onBlur.bind(this);


  }


  onNext() {
    const { history, manageLocalState } = this.props;
    const { formIsValid } = this.state;
    const data = { jovSsnState: { ...this.state } };
    if (formIsValid) {
      manageLocalState(data);
      history.push('/jov-terms-and-conditions');
    }
  }

  onChange(e, fieldId) { 
    const {value} = e.target;
    if(fieldId === 'dob'){
      if(value !== 'Error'){
        // console.log(value)
        inputChangedHandler(e, fieldId, this) 
      }else {
        this.setState((state) => ({...state, formIsValid: false, form:{...state.form, dob:{...state.form.dob, valid: false}}}))
      }
    }else{
      inputChangedHandler(e, e.target.id, this) 
    }
  }

  onBlur(e) { inputValidityHandler(e.target.id, this) }

  render() {
    const { formIsValid, form } = this.state;
    // console.log(this.state)
    return (
      <div className="container-fluid">
        <div className="container">
          <WStepper className="userWizard" currentPage={0} pages={pages} />
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={this.data.title}
                nameTitle={this.data.subtitle}
                info={this.data.info} />
            </Col>
            <Col md lg xl>
              {/* <Col md={assignObj({ span: 8, offset: 4 })} 
              lg={assignObj({ span: 8, offset: 4 })} 
              xl={assignObj({ span: 8, offset: 4 })}
              style={assignObj({paddingRight: 0})}> */}
                <JovSSNForm
                  ssnState={this.state}
                  // ssnOneRef={this.ssnOneRef}
                  // ssnTwoRef={this.ssnTwoRef}
                  // ssnThreeRef={this.ssnThreeRef}
                  dobState={form.dob}
                  changed={this.onChange}
                  blurred={this.onBlur} />
              {/* </Col> */}
            </Col>
          </Row>
        </div>
        <CommonButtons backstyle={styles.hide} nextClick={this.onNext} disabled={!formIsValid} blockstyle={styles.commonButtons}/>
        {/* {cancelled ? <Redirect to='/sign-in' /> : null} */}
      </div>
    );
  }
}

JovSSN.propTypes = {
  manageLocalState: PropTypes.instanceOf(Object),
  jovSsnState: PropTypes.instanceOf(Object),
  history: PropTypes.shape([])
}

JovSSN.defaultProps = {
  manageLocalState: {},
  jovSsnState: {},
  history: []
}

export default JovSSN;