import { Col, Row, Accordion } from 'react-bootstrap';
import consts from './Consts';
import editIcon from '../../../assets/pencil.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import Image from 'react-bootstrap/Image';
// import infoLogo from '../../../assets/hint.png';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StyledToolTip from '../Preferences/StyledToolTip';
import { WRadio, RadioList, WInput, WIcon, WInputCheckBox } from '../../../common';
import './SummaryCard.css';

const assignObj = obj => {
    return obj;
};

class SummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regulatoruQ: false,
            ariaExpanded: true,
            tooltipOpen: false,
        };
        this.clickEvent = this.clickEvent.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
    }


    // if its market nao flow then it will be receiving variable for identification and only then set it to true/assigned valie else always false
    static getDerivedStateFromProps(nextProps) {
        const { marketNAOFlowVerify= false } = nextProps;
        return {
            marketNAOFlowVerify : marketNAOFlowVerify
        };
    }

    componentDidUpdate() {
        const { tooltipOpen } = this.state;
      
        if (tooltipOpen) {
            if (document.getElementById('naoEsignTooltip')) {
                document.getElementById('naoEsignTooltip').focus();
                document.getElementById('naoEsignTooltip').addEventListener('keypress', e => {
                    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 27) {
                        e.preventDefault();
                        this.closeTooltip();
                        document.getElementById('infoIcon').focus();
                    }
                });
            }
            const toolTipElement = document.getElementsByClassName('tooltip show bs-tooltip-auto')[0];
            if (toolTipElement) {
                toolTipElement.setAttribute('x-placement', 'bottom');
            }
        }
    }

    clickEvent = () => {
        const { handleEvent, cardId } = this.props;
        handleEvent(cardId);
    };

    handleRadio = e => {
        const { handleEvent } = this.props;
        handleEvent('radioClick', e.target.id);
    };

    handlePdf = () => {
        const { handleEvent } = this.props;
        handleEvent('pdf');
    };

    handleRadioList = value => {
        if (value.regulatoruQ === 'Y') {
            this.setState({ regulatoruQ: 'Y' });
        } else {
            this.setState({ regulatoruQ: 'N' });
        }
    };

    toggleToolTip = () => {
        const { tooltipOpen } = this.state;
        this.setState({ tooltipOpen: !tooltipOpen }, () => {
            if(tooltipOpen){
                document.getElementById('infoIcon').focus();
            }
        });
    };

    closeTooltip = () => {
        this.setState({ tooltipOpen: false });
        document.getElementById('infoIcon').focus();
    };

    tabKey = (e) => {
        if((e.shiftKey && e.key === 'Tab') || e.key === 'Tab'){
            e.preventDefault();
            this.setState({ tooltipOpen: false });
            document.getElementById('infoIcon').focus();
        }
     }

    renderLink = (isEsign, preLinkText, postLinkText, linkText, cardHeading,showEditButton) => {
        const editAriaLabel = `Edit ${cardHeading}`;

        if(this.props.accountType === 'SEP IRA' && cardHeading ==='Contribution'){

        }else if(this.state.marketNAOFlowVerify){
            return(
                <Col md={1} className="sum-prelink">
                    {showEditButton && <Row noGutters>
                    <WIcon
                        alt=""
                        src={editIcon}
                        className="editIcon"
                        fontawesomestyle={assignObj({ paddingLeft: 0 })}
                    />
                    <button type="button" className="sum-linktext" onClick={this.props.onClick} aria-label={editAriaLabel}>
                        {linkText}
                    </button>
                </Row> }
                
            </Col>
            )
        }
        else{
            return isEsign ? (
                <Col md={5} className="esign-prelink">
                    {preLinkText}
                    <button type="button" className="sum-linktext" onClick={this.clickEvent}>
                        {linkText}
                    </button>
                    {postLinkText}
                </Col>
            ) : (
                <Col md={1} className="sum-prelink">
                    <Row noGutters>
                        <WIcon
                            alt=""
                            src={editIcon}
                            className="editIcon"
                            fontawesomestyle={assignObj({ paddingLeft: 0 })}
                        />
                        <button type="button" className="sum-linktext" onClick={this.clickEvent} aria-label={editAriaLabel}>
                            {linkText}
                        </button>
                    </Row>
                </Col>
            );
        }
        
        
    };

    renderField = (field, values, index, cardId) => {
        const { regulatoruQ } = this.state;
        const {
            handleTaxConsentChange,
            selectedTaxWHOption0,
            selectedTaxWHOption1,
            invokedFrom,
            taxWHConsentError,
            selectedRadio,
        } = this.props;
        const linkstyles = { paddingTop: '20px', paddingBottom: '15px' };
        switch (field.type) {
            case 'staticfield':
                return (
                    <Row key={index} className={cardId === 'childPersonal' ? 'per-child' : ''}>
                        <Col xs={12} sm={12} lg={4} md={4}  className="label-key">
                            <span>{field.key || ''}</span>
                        </Col>
                        <Col xs={8} className="label-value">
                            <span>{field.value || ''}</span>
                        </Col>
                    </Row>
                );
            case 'heading':
                return (
                    <Row noGutters key={index}>
                        <h3 className="summary-heading">{field.value}</h3>
                    </Row>
                );
            case 'list': {
                return (
                    field &&
                    field.value.map(item => (
                        <Row noGutters key={item} className="summary-list">
                            {item}
                        </Row>
                    ))
                );
                // return element;
            }
            case 'radio': {
                const checkBoxWrapperStyle = {
                    position: 'relative',
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: '20px',
                };
                const checkBoxInputStyle = {
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid #4F799F',
                    borderRadius: 3,
                    opacity: 1,
                    height: 30,
                    width: 30,
                    flexShrink: 0,
                };
                const checkBoxStyle = { position: 'absolute', top: '7px' };
                const labelStyle = {
                    paddingTop: '3px',
                    textAlign: 'left',
                    letterSpacing: 0,
                    opacity: 1,
                };
                let displayWHConsentOptions = true;
                if (invokedFrom === 'esign') {
                    displayWHConsentOptions = selectedRadio === 'N';
                }
                return (
                    <div className="summary-radio">
                        <WRadio
                            id={field.key}
                            value={field.value}
                            onClick={this.handleRadio}
                            selected={values.selected === field.key}
                            role="radio"
                            ariaLabel={field.value}
                            radiodescstyles={{ display: 'inline' }}
                        />
                        {field.descriptionList && displayWHConsentOptions && (
                            <div className="summary-radio-desc">
                                {field.descriptionList.map((item, taxWHindex) => (
                                    <Row
                                        key={item}
                                        className={invokedFrom !== 'esign' ? 'radio-dec-item' : 'radio-dec-item-esign'}
                                    >
                                        {invokedFrom === 'esign' ? (
                                            <WInputCheckBox
                                                id={`esignTax${taxWHindex}`}
                                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                                checkInputStyle={checkBoxInputStyle}
                                                checked={
                                                    taxWHindex === 0
                                                        ? selectedTaxWHOption0 === 'Y'
                                                        : selectedTaxWHOption1 === 'Y'
                                                }
                                                value={taxWHindex === 0 ? selectedTaxWHOption0 : selectedTaxWHOption1}
                                                onChange={handleTaxConsentChange(taxWHindex)}
                                                checkboxstyle={checkBoxStyle}
                                                labelstyle={labelStyle}
                                                label={item}
                                                ariadescribedby="esignTaxCheck esignTaxCheck_error"
                                            />
                                        ) : (
                                            <>
                                                <WIcon
                                                    icon={faCheck}
                                                    size="2x"
                                                    fontawesomestyle={assignObj({
                                                        height: 18,
                                                        width: 15,
                                                        margin: '0 10 0 0',
                                                        color: '#8CBF26',
                                                    })}
                                                />
                                                <span>{item}</span>
                                            </>
                                        )}
                                    </Row>
                                ))}
                                {invokedFrom === 'esign' && taxWHConsentError && displayWHConsentOptions && (
                                    <p
                                        className="error-check"
                                        aria-live="polite"
                                        aria-atomic="true"
                                        id="esignCheck_error"
                                    >
                                        {consts.taxWHConsentError}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                );
            }
            case 'paragraph': {
                return (
                    <Row className="summary-para" noGutters>
                        {invokedFrom === 'esign' ? (
                            <ul
                                style={assignObj({
                                    display: 'inline',
                                    textAlign: 'justify',
                                    paddingLeft: '0',
                                    paddingTop: '40px',
                                })}
                            >
                                {consts.summaryParaText1}
                                <li style={assignObj({ display: 'inline' })}>{consts.summaryParaText2}</li>
                                <li style={assignObj({ display: 'inline' })}>
                                    {consts.summaryParaText3}
                                    <ul
                                        style={assignObj({
                                            display: 'inline',
                                            paddingLeft: '5px',
                                        })}
                                    >
                                        <li style={assignObj({ display: 'inline' })}>{consts.summaryParaText4}</li>
                                        <li style={assignObj({ display: 'inline' })}>{consts.summaryParaText5}</li>
                                        <li style={assignObj({ display: 'inline' })}>{consts.summaryParaText6}</li>
                                    </ul>
                                </li>
                                <li style={assignObj({ display: 'inline' })}>{consts.summaryParaText7}</li>
                            </ul>
                        ) : (
                            <p className="summary-para-wrap">{field.value}</p>
                        )}
                    </Row>
                );
            }
            case 'radioList': {
                return (
                    <>
                        <Row className="Slabel" noGutters>
                            <label htmlFor="regulatoruQ" className="radio-label">
                                {field.key}
                            </label>
                            <RadioList
                                items={field.value}
                                field="regulatoruQ"
                                setValue={this.handleRadioList}
                                selectedValue={regulatoruQ}
                            />
                        </Row>
                        {regulatoruQ === 'Y' && (
                            <Row noGutters className="per-child">
                                <WInput
                                    label="Senior Foreign Political Figure Name"
                                    placeholder="Enter Senior Foreign Political Figure Name"
                                    className=""
                                    id="seniorPoliticalFigure"
                                    type="text"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    required
                                />
                            </Row>
                        )}
                    </>
                );
            }
            case 'link': {
                return (
                    <div style={linkstyles}>
                        <Row className="document-heading" noGutters>
                            <Row noGutters>
                                <WIcon
                                    icon={faCheck}
                                    size="sm"
                                    fontawesomestyle={assignObj({
                                        height: 18,
                                        width: 15,
                                        margin: '0 10 0 0',
                                        color: '#8CBF26',
                                    })}
                                />
                                <div>{field.value}</div>
                            </Row>
                            {/* <Row noGutters>
                                <WIcon
                                    src={PDFIcon}
                                    className="pdfIcon"
                                    fontawesomestyle={assignObj({ paddingLeft: 0 })}
                                />
                                <button
                                    type="button"
                                    className="summary-link"
                                    onClick={this.handlePdf}
                                    aria-label={assignObj(`${consts.downloadAriaLabel}${field.value}`)}
                                >
                                    {downloadText}
                                </button>
                            </Row> */}
                        </Row>
                        {field.description && (
                            <div className="link-sum-desc">
                                <span>{field.description}</span>
                            </div>
                        )}
                    </div>
                );
            }
            case 'contactInfo': {
                return (
                    <div>
                        <Row noGutters className="contactwrap">
                            <div className="mailAddress">
                                <Row noGutters>
                                    <h3 className="phy-heading">{field.mailingAddressLabel}</h3>
                                </Row>
                                <Row noGutters>
                                    <span className="contactAddress">{field.mailingAddress}</span>
                                </Row>
                            </div>
                            <div className="phyadd2">
                                <Row noGutters>
                                    <h3 className="phy-heading">{field.physicalAddressLabel}</h3>
                                </Row>
                                <Row noGutters>
                                    <span className="contactAddress">{field.physicalAddress}</span>
                                </Row>
                            </div>
                        </Row>
                    </div>
                );
            }

            // case 'contactInfo': {
            //     return (
            //         <div>
            //             <p className="contactHint">{field.hintText1}</p>
            //             <p className="contactHint">{field.hintText2}</p>
            //             <Row noGutters className="contactwrap">
            //                 <Col className="phy-add">
            //                     <Row noGutters>
            //                         <WRadio
            //                             id="mailingAddress"
            //                             value={field.mailingAddressLabel}
            //                             onClick={this.handleRadio}
            //                             radiotext={assignObj({
            //                                 textAlign: 'left',
            //                                 font: 'Bold 16px/24px benton-sans',
            //                                 letterSpacing: 0,
            //                                 color: '#56565A',
            //                                 opacity: 1,
            //                             })}
            //                         />
            //                         <span className="contactAddress">{field.mailingAddress}</span>
            //                     </Row>
            //                     <div className="phyadd2">
            //                         <Row noGutters>
            //                             <div className="phy-heading">{field.physicalAddressLabel}</div>
            //                         </Row>
            //                         <Row noGutters>
            //                             <span className="contactAddress2">{field.physicalAddress}</span>
            //                         </Row>
            //                     </div>
            //                 </Col>
            //                 <Col className="usps">
            //                     <WRadio
            //                         id="physicalAddress"
            //                         value={field.`usps`Adresslabel}
            //                         onClick={this.handleRadio}
            //                         selected
            //                         radiotext={assignObj({
            //                             textAlign: 'left',
            //                             font: 'Bold 16px/24px benton-sans',
            //                             letterSpacing: 0,
            //                             color: '#56565A',
            //                             opacity: 1,
            //                         })}
            //                     />
            //                     <span className="contactAddress">{field.mailingAddress}</span>
            //                 </Col>
            //             </Row>
            //         </div>
            //     );
            // }
            default:
                return null;
        }
    };

    cardHeaderClick = e => {
        const { ariaExpanded } = this.state;
        this.setState({ ariaExpanded: !ariaExpanded });
        e.currentTarget.classList.toggle('expand');
    };

    renderReviewFields = (heading, fields, fieldStyle, values, index, cardId) => {
        let final = null;
        const reviewFields = (
            <ul className="sum-card-wrap">
                {fields &&
                    fields.map((field, idx) => {
                        const id = `${field.uniqueKey}${idx}`;
                        // console.log("da"+originPage)
                        return (
                            <li key={id} style={fieldStyle} className="card-list-wrapper">
                                {this.renderField(field, values, index, cardId)}
                            </li>
                        );
                    })}
            </ul>
        );
        const otherFields = (
            <div className="sum-card-wrap">
                <div role="radiogroup" aria-labelledby="summaryHeadingEsign">
                    {fields &&
                        fields.map((field, idx) => {
                            const id = `${field.uniqueKey}${idx}`;
                            //  console.log("da"+originPage)
                            if (idx === 0 || idx === 1) {
                                return (
                                    <div key={id} style={fieldStyle} className="card-list-wrapper">
                                        {this.renderField(field, values, index, cardId)}
                                    </div>
                                );
                            }
                        })}
                </div>

                {fields &&
                    fields.map((field, idx) => {
                        const id = `${field.uniqueKey}${idx}`;
                        //  console.log("da"+originPage)
                        if (idx !== 0 && idx !== 1) {
                            return (
                                <div key={id} style={fieldStyle} className="card-list-wrapper">
                                    {this.renderField(field, values, index, cardId)}
                                </div>
                            );
                        }
                    })}
            </div>
        );
        if (heading === 'Documents to Review') {
            final = reviewFields;
        } else {
            final = otherFields;
        }
        return final;
    };

    render() {
        const { tooltipOpen, ariaExpanded } = this.state;
        const {
            cardHeading,
            cardId,
            fields,
            index,
            linkText,
            showBorder,
            values,
            page,
            preLinkText,
            postLinkText,
            cardSubHeading,
            mainHeading,
            originPage,
            showEditButton = ""
        } = this.props;
        const fieldStyle = showBorder
            ? { backgroundColor: '#FFFFFF', marginLeft: 0 }
            : { backgroundColor: '#FFFFFF', paddingLeft: '0px' };
        const isEsign = page === 'esign';
        const infoIcon = 'Info Icon';
        const customclassName = !mainHeading ? 'main-docs' : 'sum-heading';
        return originPage === 'verify' ? (
            <Accordion key={index} className="summary-content" defaultActiveKey="0" >
                <Accordion.Toggle
                    aria-labelledby="expand or collapsable"
                    as="div"
                    variant="link"
                    eventKey="0"
                    aria-expanded={ariaExpanded}
                    className="accordionHeader expand"
                    onClick={this.cardHeaderClick}
                    style={assignObj({ cursor: 'pointer' })}
                >
                    <Row className="sum-content-wrap" noGutters>
                        <h2 className={customclassName} aria-label={cardHeading} aria-level="3">
                            {cardHeading}
                        </h2>
                        {linkText  && this.renderLink(isEsign, preLinkText, postLinkText, linkText, cardHeading,showEditButton)}
                    </Row>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0" aria-expanded="false">
                    <div className="sum-card-wrap">
                        {fields &&
                            fields.map((field, idx) => {
                                const id = `${field.uniqueKey}${idx}`;
                                return (
                                    <div key={id} style={fieldStyle} className="card-list-wrapper">
                                        {this.renderField(field, values, index, cardId)}
                                    </div>
                                );
                            })}
                    </div>
                </Accordion.Collapse>
            </Accordion>
        ) : (
            <div key={index} className="summary-content" role="radiogroup">
                <Row className={!linkText ? 'main-content-wrap' : 'sum-content-wrap'} noGutters>
                    {originPage === 'esigndocsToView' ? (
                        <h3 className={customclassName} aria-describedby="infoIcon">{cardHeading}</h3>
                    ) : (
                        <h3 className={customclassName} id="summaryHeadingEsign" aria-describedby="infoIcon">
                            {cardHeading}
                        </h3>
                    )}
                    {mainHeading && !linkText && (
                        <>
                            <button
                                className="infoIcon"
                                id="infoIcon"
                                type="button"
                                aria-label="Know more about Taxpayer Identification Number/Backup withholding certification Opens a tooltip"
                            >
                                <span style={consts.hiddenStyle}>{infoIcon}</span>
                            </button>
                            <StyledToolTip
                                id="naoEsignTooltip"
                                placement="bottom"
                                tooltipOpen={tooltipOpen}
                                targetID="infoIcon"
                                handleToggle={this.toggleToolTip}
                                autohide={false}
                                triggerEvt="click"
                                tooltipText={consts.taxPayerTooltipHintText}
                                closeTooltip={this.closeTooltip}
                                closeTabKey = {this.tabKey}
                                tooltipTitle = {consts.toolTipTitle}
                            />{' '}
                        </>
                        // <Image
                        //     style={assignObj({ width: 30, height: 30, marginTop: 8 })}
                        //     src={infoLogo}
                        //     alt="Info"
                        //     role="tooltip"
                        //     tabindex="0"
                        //     aria-label="Know about Taxpayer Identification Number/Backup withholding certification"
                        // />
                    )}
                    {linkText && this.renderLink(isEsign, preLinkText, postLinkText, linkText, cardHeading)}
                </Row>
                {mainHeading && <hr className="sum-ruler" aria-hidden="true" />}
                {cardSubHeading && (
                    <Row className="sum-sh">
                        <Col md={7}>
                            <span>{cardSubHeading}</span>
                        </Col>
                    </Row>
                )}
                {this.renderReviewFields(cardHeading, fields, fieldStyle, values, index, cardId)}
            </div>
        );
    }
}

SummaryCard.propTypes = {
    cardHeading: PropTypes.string,
    cardId: PropTypes.string,
    cardSubHeading: PropTypes.string,
    fields: PropTypes.instanceOf(Array),
    handleEvent: PropTypes.func,
    index: PropTypes.string,
    linkText: PropTypes.string,
    mainHeading: PropTypes.bool,
    preLinkText: PropTypes.string,
    postLinkText: PropTypes.string,
    page: PropTypes.string,
    showBorder: PropTypes.bool,
    values: PropTypes.shape({}),
    originPage: PropTypes.string,
    handleTaxConsentChange: PropTypes.func,
    selectedTaxWHOption0: PropTypes.string,
    selectedTaxWHOption1: PropTypes.string,
    invokedFrom: PropTypes.string,
    taxWHConsentError: PropTypes.bool,
    selectedRadio: PropTypes.string,
};

SummaryCard.defaultProps = {
    cardHeading: '',
    cardId: '',
    cardSubHeading: '',
    fields: [],
    handleEvent: () => {},
    index: '',
    linkText: '',
    mainHeading: true,
    page: '',
    preLinkText: '',
    postLinkText: '',
    showBorder: false,
    values: null,
    originPage: '',
    handleTaxConsentChange: () => {},
    selectedTaxWHOption0: '',
    selectedTaxWHOption1: '',
    invokedFrom: '',
    taxWHConsentError: false,
    selectedRadio: '',
};

export default SummaryCard;