import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';
import styles from '../CommonStyles';

const CustomWInputControlCol = styled(Col)`
    select {
        height: 48px;
        border: 1px solid #d2d2d2;
        width: 100%;
        padding: 6px 12px;
        color: rgba(73, 73, 74, 1);
        -webkit-appearance: none;
        position: relative;
        &.prefilled:disabled {
            background-color: transparent;
            opacity: 1;
            border: none;
            padding-left: 0;
        }
        &:disabled {
            background-color: rgba(250, 250, 250, 1);
            border-color: #d2d2d2;
        }
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            outline: none;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
            border-color: rgba(0, 74, 152, 1);
        }
        &:disabled:focus,
        &:disabled:hover {
            border-color: #d2d2d2;
        }
    }
`;

const assignObj = obj => {
    return obj;
};

const SelectInput = props => {
    const {
        id,
        label,
        itemlist,
        selectstyle,
        labelstyle,
        labelrow,
        optional,
        errortext,
        labelstyletext,
        labelsm,
        valuesm,
        sublabel,
        disabled,
        onChange,
        className,
        arialabel,
        arialabelledby,
        required,
        onBlur,
        name,
        value,
        ariaLabel,
    } = props;
    const defVal = 'Select';
    const optionalText = '[ Optional ]';
    const iserror = errortext !== undefined && errortext !== '' && errortext !== null;
    const errorStyle = iserror ? { borderColor: 'red' } : {};
    const errorSelectStyle = iserror ? { display: 'flex', flexDirection: 'column', justifyContent: 'center' } : {};
    const itemArr = [];
    return (
        <div className="container-fluid" style={styles.paddingZeroStyle}>
            {labelrow === 'true' ? (
                <Form.Group as={Row} noGutters id={id} style={assignObj({ marginBottom: 0 })}>
                    <Form.Label htmlFor={id} style={assignObj({ ...styles.selectLabel, ...labelstyle })}>
                        {label}
                        {optional === 'true' ? <span style={styles.subLabel}>{optionalText}</span> : null}
                    </Form.Label>
                    {/* { !disabled ? <div style={styles.selectArrowSpecial} /> : '' } */}
                    <Form.Control
                        as="select"
                        style={assignObj({ ...styles.select, ...selectstyle })}
                        onChange={onChange}
                        className={className}
                        aria-label={ariaLabel}
                    >
                        {itemlist &&
                            itemlist.map((item, index) => {
                                if (!itemArr.includes(item.value)) {
                                    itemArr.push(item.value);
                                    return (
                                        <option key={Number(index)} value={item.value}>
                                            {item.value}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                    </Form.Control>
                </Form.Group>
            ) : (
                <Form.Group as={Row} noGutters style={assignObj({ ...styles.inputLabel, ...labelstyle })}>
                    <Form.Label
                        column
                        sm={labelsm}
                        htmlFor={id}
                        style={assignObj({ ...styles.inputLabelText, ...labelstyletext })}
                    >
                        {label}
                        {optional === 'true' ? (
                            <span style={assignObj({ ...styles.subLabel, ...sublabel })}>{optionalText}</span>
                        ) : null}
                    </Form.Label>
                    <CustomWInputControlCol sm={valuesm} style={errorSelectStyle}>
                        {!disabled ? <div style={styles.selectArrow} /> : ''}
                        <Form.Control
                            as="select"
                            id={id}
                            name={name}
                            style={assignObj({ ...selectstyle, ...errorStyle })}
                            aria-labelledby={arialabelledby}
                            aria-label={arialabel}
                            aria-required={required}
                            aria-controls={assignObj(`${id}selectInputErrorText`)}
                            onChange={onChange}
                            onBlur={onBlur}
                            className={className}
                            value={value}
                        >
                            <option value="">{defVal}</option>
                            {itemlist &&
                                itemlist.map((item, index) => {
                                    return (
                                        <option key={Number(index)} value={item.key}>
                                            {item.value}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                        {iserror && (
                            <Form.Text
                            className='errorMsgInputStyle'
                               //  style={styles.errorStyle}
                                role="region"
                                id={assignObj(`${id}selectInputErrorText`)}
                                aria-labelledby={assignObj(`${id}selectInputErrorText`)}
                                aria-live="polite"
                            >
                                {errortext}
                            </Form.Text>
                        )}
                    </CustomWInputControlCol>
                </Form.Group>
            )}
        </div>
    );
};

SelectInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    arialabel: PropTypes.string,
    arialabelledby: PropTypes.string,
    required: PropTypes.bool,
    itemlist: PropTypes.instanceOf(Array),
    selectstyle: PropTypes.instanceOf(Object),
    labelrow: PropTypes.string,
    labelstyle: PropTypes.instanceOf(Object),
    optional: PropTypes.string,
    errortext: PropTypes.string,
    labelstyletext: PropTypes.instanceOf(Object),
    sublabel: PropTypes.instanceOf(Object),
    valuesm: PropTypes.number,
    labelsm: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    value:PropTypes.string,
    ariaLabel: PropTypes.string,
};

SelectInput.defaultProps = {
    arialabel: '',
    arialabelledby: '',
    required: false,
    label: 'Label',
    id: '',
    itemlist: [],
    selectstyle: {},
    labelrow: 'false',
    labelstyle: {},
    optional: 'false',
    errortext: '',
    labelstyletext: {},
    labelsm: 2,
    valuesm: 4,
    sublabel: {},
    disabled: false,
    onChange: () => {},
    onBlur: () => {},
    className: '',
    name:'',
    value:'',
    ariaLabel: '',
};

export default SelectInput;
