import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import WButton  from '../WButton/WButton';
import './InfoCardStyles.css'
// import { BottomNavBar } from 'modules/CustomerManagementModule/components';

const AccountSection = styled(Row)`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: relative;
    width: auto;
    height: auto;
    min-height: 110px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
    opacity: 1;
    margin-bottom: 410px;
    justify-content: center;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8cbf26;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: 20px solid #8cbf26;
        }
    }
    .row span:nth-child(1) {
        height: 40px;
        width: 40px;
        display: inline;
    }
    .row span:nth-child(2) {
        border-right: 1px solid #d2d2d2;
        padding-right: 17.5px;
        padding-left: 20px;
    }
    > .row {
        align-items: center;
    }
    .row span:nth-child(4) {
        padding-left: 19.5px;
    }
`;

const RwdAccountDetailsDiv = styled.div`
        display: block;
        padding: 30px;
        @media (max-width: 767.98px) {
            padding: 0px 30px 30px 30px;
        }
`;

const RwdMobileAccountDetailsWrap = styled.div`
        display: block;
        padding:30px;
        width: 780px;
        font: normal normal medium 16px/20px Benton Sans;
        letter-spacing: 0px;
        color: #49494A;
        > span:first-child {
            margin-right: 15px;
        }
`;

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
`;

const BtnWrap = styled.div`
    display:Flex; 
    justify-content: flex-end;
    align-items:center;
    padding:20px 0;
    &>:nth-child(1){
        margin-right:50px;
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:2;
            margin-right:0;
            button{
                margin:0 !important;
            }            
        }
        &>:nth-child(2){
            order:1;
            width:100%;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;

const buttonStyle = {
    width:'230px'
}

const assignObj = obj => {
    return obj;
};

const InfoCardButton = props => {
    const { accountInfoStyles, nextBtnText, displayText, primaryBtnText, displayFooterBtns,
         disabledFooterNxt, onClickBtn } = props;
    return (
        <>
        <div className="container mt-28"> 
            <AccountSection noGutters style={accountInfoStyles}>
                <Row className="rowStyle">
                    <RwdMobileAccountDetailsWrap>
                        <span>{displayText}</span>
                        <span className="sr-only">{displayText}</span>
                    </RwdMobileAccountDetailsWrap>
                    <RwdAccountDetailsDiv>
                        {/* <BottomNavBar hideBack={hideBack} primaryBtnText={primaryBtnText} /> */}
                        <WButton
                            buttontext={primaryBtnText}
                            onClick={onClickBtn}
                            dataAnalyticId="Transactions_Buy_NoAccounts_OpenAccountButton"
                            buttonstyle={assignObj(buttonStyle)}
                        />
                    </RwdAccountDetailsDiv>
                </Row>
            </AccountSection>
        </div>
        {displayFooterBtns ?
         <MainDiv>
            <BtnWrap className="container">
                <div>
                    <WButton
                        variant="cancel"
                        buttontext="Cancel"
                        id="mobileBuyPurchaseCancelButton"
                        //  buttonstyle={assignObj(cancelButtonStylesRwd)}
                        //  onClick={setCancelPurchase}
                        dataAnalyticId="Transactions_Buy_AccountSelection_CancelButton"
                    />
                </div>
                <div>
                    <WButton
                        disabled={disabledFooterNxt}
                        buttontext={nextBtnText}
                        // onClick={onSwitchComponent(true, this.state, pageIndex)}
                        data-test="next-button"
                        dataAnalyticId="Transactions_Buy_AccountSelection_NextButton"
                    />
                </div>
            </BtnWrap>
         </MainDiv>: null
        }
     </>
    );
}

InfoCardButton.propTypes = {
    displayText: PropTypes.string,
    primaryBtnText: PropTypes.string,
    accountInfoStyles: PropTypes.instanceOf(Object),
    displayFooterBtns: PropTypes.bool,
    disabledFooterNxt: PropTypes.bool,
    nextBtnText: PropTypes.string,
    onClickBtn: PropTypes.func
};

InfoCardButton.defaultProps = {
    displayText: '',
    primaryBtnText:'Open an Account',
    accountInfoStyles: {},
    displayFooterBtns: false,
    disabledFooterNxt: false,
    nextBtnText: 'Next',
    onClickBtn: () => {}
};

export default InfoCardButton