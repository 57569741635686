import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { StyledButton } from 'common';
import IconAccount from 'assets/icon-account-info-big.svg';
import EditIcon from 'assets/edit.png';
import constants from 'modules/AccountOptions/constant';

const VerticalSeparatorTop = styled.div`
    border-left: 1px solid rgba(210, 210, 210, 1);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
    margin-top: 0.3438rem;
    margin-bottom: 0.2813rem;
`;
const StyledCard = styled(Card)`
    margin: 1.875rem 0;
    border: 0.0625rem solid #D2D2D2;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #486D90;
        position: absolute;
    }
`;
const AccountCardFlex = styled.div`
    display: flex;
`;
const AccountCardSection = styled(AccountCardFlex)`
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font: 800 1.125em/1.375em yorkten-slab-normal;
    letter-spacing: 0;
    color: #486D90;
    border-bottom: 0.0625rem solid #D2D2D2;
    opacity: 1;
    padding: 1.25rem 0 1.375rem 2.5rem;
    &:last-of-type {
        border-bottom: none;
    }
`;
const CardSymbol = styled.img`
    margin-right: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
`;
const EditImage = styled.img`
    margin-right: 0.625rem;
`;
/* MENU */
const MenuContainer = styled.div`
    position: relative;
`;
const Menu = styled.span`
    position: relative;
    margin-right: 1.75rem;
    margin-top: 0.3125rem;
    &::after {
        content: '\\2807';
        color: #004A98;
        font-size: 27px;
    }
`;
const MenuOptionsPopup = styled(StyledButton)`
    position: absolute;
    width: 7.75rem;
    height: 3.75rem;
    padding: 0;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 0 1.5625rem rgba(0,0,0,0.16);
    opacity: 1;
    font: 800 16px/20px;
    letter-spacing: 0;
    color: #004A98;
    opacity: 1;
    z-index: 10;
    justify-content: center;
    left: -5.8125rem;
    top: 2.25rem;
    padding: 1.25rem;
    &::after {
        content: " ";
        position: absolute;
        right: 0.5625rem;
        top: -0.5rem;
        border-top: none;
        border-right: 0.9375rem solid transparent;
        border-left: 0.9375rem solid transparent;
        border-bottom: 0.9375rem solid white;
    }
`;
const AccNameNumber = styled.div`
    padding: 0.25rem 0;
    line-height: 1.375rem;
`;
const AccNumberText = styled.span`
    margin-right: 0.5rem;
`;

const DividendsAccordionCard = props => {
    const { accName, accNumber, editClick, onMenuHandler, showMenu, accTypeIndex, accIndex } = props;
    let shouldShowMenu;

    if ( showMenu && Object.keys(showMenu).length) {
        shouldShowMenu = showMenu.accTypeIndex === accTypeIndex && showMenu.accIndex === accIndex;
    }

    return (
        <StyledCard>
            <AccountCardSection>
                <AccountCardFlex>
                    <div aria-hidden="true">
                        <CardSymbol src={IconAccount} alt="" />
                    </div>
                    <AccNameNumber>{accName}</AccNameNumber>
                </AccountCardFlex>
                <MenuContainer>
                    <Menu aria-label={"More options for " + accName + " " + accNumber} onClick={onMenuHandler} role="menu" aria-expanded={shouldShowMenu ? true : false}/>
                    {
                        shouldShowMenu && (
                            <MenuOptionsPopup aria-label="Edit Dividend" onClick={editClick} role="menuitem">
                                <EditImage src={EditIcon} alt="edit icon" />
                                {constants.dividends.menuOptions}
                            </MenuOptionsPopup>
                        )
                    }
                </MenuContainer>
            </AccountCardSection>
        </StyledCard>
    )
}

DividendsAccordionCard.propTypes = {
    accName: PropTypes.string,
    accNumber: PropTypes.string,
    editClick: PropTypes.func,
    onMenuHandler: PropTypes.func,
    showMenu: PropTypes.instanceOf(Object),
    accTypeIndex: PropTypes.number,
    accIndex:PropTypes.number
};

DividendsAccordionCard.defaultProps = {
    accName: "",
    accNumber: '',
    editClick: () => {},
    onMenuHandler: () => {},
    showMenu: {},
    accTypeIndex: null,
    accIndex: null
};

export default DividendsAccordionCard;