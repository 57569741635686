import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const StyledPageWrapper = styled.div`
    min-height: 80vh;
    position: relative;
    padding: 0 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    .card:first-child {
        border: none;
        margin-top: 1px;
    }
    .accordion:last-child > .card {
        margin-bottom: 20px;
    }
`;

const StyledPageHeading = styled.h1`
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: inline-block;
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    text-align: left;
    font: 800 30px/36px yorkten-slab-normal;
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
`;

// const SubHeading = styled.div`
// text-align: left;
// font: 500 16px/20px benton-sans;
// letter-spacing: 0;
// color: rgba(73, 73, 74, 1);
// margin-top:18px;
// margin-bottom:40px;
// `;

const StyledSubPara = styled.div`
    text-align: left;
    font: 500 16px/17px benton-sans;
    color: rgba(73, 73, 74, 1);
    margin-top: 20px;
    margin-bottom: 20px;
    span {
        display: block;
        margin-top: 5px;
    }
`;
const StyledCard = styled(Card)`
    margin-bottom: 30px;
    > .card-header {
        border: none;
        padding: 0;
        background-color: #ffffff;
    }
`;

const StyledOptionalText = styled.span`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: rgba(86, 86, 90, 1);
    opacity: 1;
    padding-left: 10px;
    padding-top: 14px;
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 0;
    }
    > .card {
        border: none;
        padding: 0;
        margin-bottom: 30px;
    }
    > .card .card-header,
    > .card .card-body {
        padding: 25px 39px;
    }
`;
const StyledDocReceptionQ = styled.div`
    border: 1px solid rgba(210, 210, 210, 1);
    min-height: 88px;
    padding: 20px 30px 18px 31px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid rgba(139, 193, 5, 1);
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .radioListDocQWrapper {
        display: flex;
        flex-basis: 70%;
        align-items: center;
    }
    .radioListDocQWrapper > label {
        width: 523px;
        flex-shrink: 0;
        font: 600 16px/22px benton-sans;
        color: rgba(73, 73, 74, 1);
        margin-bottom: 0px;
    }
    .radioListDocQWrapper > label span {
        font: 500 12px/20px benton-sans;
        color: rgba(86, 86, 90, 1);
        margin-left: 5px;
    }
    .container-fluid {
        flex-basis: 14%;
        margin-right: 25px;
    }
`;

export {
    StyledPageWrapper,
    StyledPageHeading,
    StyledSubPara,
    StyledCard,
    StyledOptionalText,
    StyledCardBody,
    StyledDocReceptionQ,
};
