/**
 * UploadOrMail Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description UploadOrMail for paper flow
 * @createdDate [27/03/2020]
 * ***************************************
 * @lastModifiedDate [27/03/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason
 */

import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { CommonButtons, WStepper } from '../../../common';
import AccGlobalConstants from '../AccountManagementConstants';
import consts from '../AgreementPaperFlow/consts';
import '../AgreementPaperFlow/agreementPaperFlow.css';
import UploadComponent from '../UploadComponent/UploadComponent';

class UploadOrMailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: false,
            back: false,
        };
    }

    // componentDidMount() {

    // }

    handlePdf = () => {
        window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleSubmit = () => {
        this.setState({ submit: true });
    };

    render() {
        const { back, submit } = this.state;
        const { paperFlowHeading } = consts;

        return (
            <div>
                <Container className="paperFlowPage uploadOrMailPage">
                    <Row noGutters>
                        <Col>
                            <h1 className="pageHeading">{paperFlowHeading}</h1>
                            <WStepper pages={AccGlobalConstants.paperFlowPages} currentPage={4} />
                        </Col>
                    </Row>
                    <UploadComponent flow="paperFlow" />
                </Container>
                <CommonButtons backClick={this.handleBack} submitClick={this.handleSubmit} submit="true" showSave />
                {back && <Redirect to="/paperApplication" />}
                {submit && <Redirect to="/" />}
            </div>
        );
    }
}

export default UploadOrMailComponent;
