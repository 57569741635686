import * as AnalyticsActions from '../ReduxConstants/AnalyticsConstants';

const initialState = {
    analyzeClick: null,
    analyzeDownLoadClick: null,
    analyzeFormFieldError: null,
    analyzeFormAdandonment: null,
    apiResponse: null,
    analyzeCustom: null,
};

function appState(state = initialState, action) {
    switch (action.type) {
        case AnalyticsActions.RESET_API_RESPONSE: {
            return {
                ...state,
                apiResponse: null,
                analyzeClick: null,
                analyzeCustom: null,
            };
        }
        case AnalyticsActions.API_RESPONSE: {
            return {
                ...state,
                apiResponse: action.payload,
            };
        }
        case AnalyticsActions.RESET_TRACK_DOWNLOAD_CLICK: {
            return {
                ...state,
                analyzeDownLoadClick: null,
            };
        }
        case AnalyticsActions.RESET_TRACK_FORM_FILED_ERROR: {
            return {
                ...state,
                analyzeFormFieldError: null,
            };
        }
        case AnalyticsActions.RESET_TRACK_FORM_ABANDONMENT: {
            return {
                ...state,
                analyzeFormAdandonment: null,
            };
        }
        case AnalyticsActions.RESET_UPDATE_ANALYTIC_PAGE_DETAILS: {
            return {
                ...state,
                updateAnalyticPageDetails: null,
            };
        }

        case AnalyticsActions.ANALYZE_CLICK_EVENT: {
            return {
                ...state,
                analyzeClick: { link: action.payload.link },
                analyzeCustom: action.payload.analyzecustom  
            };
        }
        case AnalyticsActions.ANALYZE_DOWNLOAD_CLICK_EVENT: {
            return {
                ...state,
                analyzeDownLoadClick: { ...action.payload },
            };
        }
        case AnalyticsActions.ANALYZE_FORM_FIELD_ERRORS: {
            return {
                ...state,
                analyzeFormFieldError: { ...action.payload },
            };
        }
        case AnalyticsActions.ANALYZE_FORM_ABANDONMENT: {
            return {
                ...state,
                analyzeFormAdandonment: { ...action.payload },
            };
        }

        case AnalyticsActions.UPDATE_ANALYTIC_PAGE_DETAILS: {
            return {
                ...state,
                updateAnalyticPageDetails: { ...action.payload },
            };
        }

        default:
            return state;
    }
}

export default appState;
