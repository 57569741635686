import React, { Component } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import $ from 'jquery';
// import { isEmpty } from 'lodash'
import WBreadCrumb from '../WBreadCrumb/WBreadCrumb';
import './FAQs.css';
import WSpinner from '../WSpinner/WSpinner';
import envConfig from 'env/EnvConfig';

class FAQWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            spinner: false,
            breadCrumbValues: [],
        };
    }

    componentDidMount() {
        const role = localStorage.getItem('role');
        let userRole = false;
        userRole = role === 'Member' || false;
        const breadCrumbs = { name: 'Home', url: `${userRole ? '/accountholder' : '/accountguestview'}`,dataAnalyticId:"FAQ_BREADCRUMB_HOME" };
        this.setState(state => ({
            ...state,
            breadCrumbValues: [...state.breadCrumbValues, breadCrumbs],
        }));

        this.loadData(`${envConfig.ENV_URL}/vcm-experience-fragments/vcm-faq-details/master.html`);
       // this.loadData(`https://qa.vcm.com/vcm-experience-fragments/vcm-faq-details/master.html`);
    }

    loadData = url => {
        this.setState(state => ({ ...state, spinner: true }));
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `${envConfig.PRELOGIN_HEADER_AUTH}`,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                throw new Error('Error message.');
            })
            .then(data => {
                let result = data.toString();
                result = result.replace(/<script[^>]*>/gi, ' <!-- ');
                result = result.replace(/<\/script>/gi, ' --> ');
                result = result.replace(/\/etc/g, `${envConfig.ENV_URL}/etc`);
                result = result.replace(/\/content/g, `${envConfig.ENV_URL}/content`);
                this.setState(state => ({ ...state, content: result }));
                setTimeout(() => {
                    let accordian, segregation, seeall, element, faqList;
                    let jsonText = JSON.parse($('.json-data').text());
                    //$.getJSON('js/FaqDetailsComponent.json', function(data) {

                    jsonText.forEach((faq, i) => {
                        let listElements =
                            "<p><a href='#' id='refId" + i + "' class='groupNameCheck'>" + faq.groupName + '</a></p>';
                        $(listElements).appendTo('#showallfaq .card-body');
                        segregation =
                            "<div class='acc-segregation' data-group='refId" +
                            i +
                            "'><h3 class='blueHdng-greenBorder'>" +
                            faq.groupName +
                            '</h3><div></div>';
                        $(segregation).appendTo('.accordionContainer');
                        faqList = faq.faqList;
                        faqList.forEach((ques, index) => {
                            if (index == 0) {
                                accordian =
                                    "<div class='card first-card'><div class='card-header collapsed' data-toggle='collapse' href='#collapse" +
                                    i +
                                    index +
                                    "' aria-expanded='false'><div class='card-link'>" +
                                    ques.question +
                                    "</div><div class='accordionIconMain accordionPlusIcon'></div></div><div id='collapse" +
                                    i +
                                    index +
                                    "' class='collapse' data-parent='#accordion' ><div class='card-body'>" +
                                    ques.answer +
                                    '</div></div></div></div>';
                            } else {
                                accordian =
                                    "<div class='card'><div class='card-header collapsed' data-toggle='collapse' href='#collapse" +
                                    i +
                                    index +
                                    "' aria-expanded='false'><div class='card-link'>" +
                                    ques.question +
                                    "</div><div class='accordionIconMain accordionPlusIcon'></div></div><div id='collapse" +
                                    i +
                                    index +
                                    "' class='collapse' data-parent='#accordion' ><div class='card-body'>" +
                                    ques.answer +
                                    '</div></div></div></div>';
                            }
                            //accordian = "<div class='card'><div class='card-header collapsed' data-toggle='collapse' href='#collapse" + i + index + "' aria-expanded='false'><div class='card-link'>" + ques.question + "</div><div class='accordionIconMain accordionPlusIcon'></div></div><div id='collapse" + i + index + "' class='collapse' data-parent='#accordion' ><div class='card-body'>" + ques.answer + "</div></div></div></div>"
                            element = $($('.acc-segregation')[i]);
                            $(accordian).appendTo(element);
                        });
                        if ($(window).width() < 768) {
                            if (faqList.length > 4) {
                                seeall = '<p class="seeall"><a href="#" class="loadMore">See all</a></p>';
                                $(seeall).appendTo(element);

                                // closeall = '<p class="closeall"><a href="#" class="loadLess">Close all</a></p>';
                                // $(closeall).appendTo(element);
                            }
                        }
                    });

                    $('.loadMore').each(function (e) {
                        $(this)
                            .parents('.acc-segregation')
                            .find('.closeall')
                            .hide();
                        $(this)
                            .parents('.acc-segregation')
                            .find('.card')
                            .hide();
                        $(this)
                            .parents('.acc-segregation')
                            .find('.card')
                            .slice(0, 4)
                            .show();
                    });

                    $('.loadMore').on('click', function (e) {
                        $(this)
                            .parents('.acc-segregation')
                            .find('.closeall')
                            .show();
                        $(this)
                            .parents('.acc-segregation')
                            .find('.card:hidden')
                            .slideDown();
                        $(this)
                            .parents('.acc-segregation')
                            .find('.seeall')
                            .hide();
                    });

                    // $(".loadLess").on("click", function(e){
                    // $(this).parents('.acc-segregation').find('.seeall').show();
                    // $(this).parents('.acc-segregation').find('.card:hidden').slice(0, 4).show();
                    // $(this).parents('.acc-segregation').find('.closeall').hide();
                    // });

                    $(document).one('click', 'a.groupNameCheck', function (e) {
                        $('<p><span><a href="#" id="bcktoshow">Show all FAQ</a></span></p>').insertBefore(
                            '#showallfaq p:first-child',
                        );
                    });

                    $(document).on('click', 'a.groupNameCheck', function (e) {
                        let tmpName = $(this).attr('id');
                        $('a.groupNameCheck')
                            .parent()
                            .show();
                        $(this)
                            .parent()
                            .hide();
                        $("#bcktoshow").parents('p').show();
                        let chu = $(this).text();
                        $('#showing').html(chu);
                        $('div.acc-segregation').hide();

                        $('#showallfaq').removeClass('show');
                        $('.card-header.acc-title').attr('aria-expanded', 'false');
                        $('.card-header.acc-title').addClass('collapsed');

                        $('div.acc-segregation[data-group=' + tmpName + ']')
                            .show()
                            .attr('style', 'margin-top: 25px !important;margin-bottom: 20px');
                        //  $(".blueHdng-greenBorder").attr("style", "margin-left: 8px !important");

                        $('div.acc-segregation[data-group=' + tmpName + '] .blueHdng-greenBorder').hide();
                        //$('.card-link .blueHdng-greenBorder').replaceWith(head);
                        $('#bcktoshow').click(function () {
                            //  var prevName = $(this).attr('id');
                            // location.reload();
                            $('a.groupNameCheck')
                                .parent()
                                .show();
                            $(this)
                                .parent()
                                .parent()
                                .hide();
                            $('#showallfaq').removeClass('show');
                            $('#showing').html('Showing all FAQ');
                            $('.card-header.acc-title').attr('aria-expanded', 'false');
                            $('.card-header.acc-title').addClass('collapsed');
                            //  $(`${prevName}`).removeAttr('style')
                            $('div.acc-segregation').removeAttr('style')
                            $('div.acc-segregation .blueHdng-greenBorder').show();
                           // $('#bcktoshow').hide();
                        });
                        // To ensure only 4 categories are loaded on page load event.
                        //  $('.acc-segregation').hide().slice(0, 4).show();

                        if ($('.faqdetails').length) {
                            $('.faqdetails').parents('.content-wrapper').addClass('faq-wrapper');
                        }
                    });
                    this.setState(state => ({ ...state, spinner: false }));
                }, 3000);
            })
            .catch(err => {
                this.setState(state => ({
                    ...state,
                    spinner: false,
                    content: err || 'Sorry!!! Cross Browser Issue',
                }));
            });
    };

    render() {
        const { content, spinner, breadCrumbValues } = this.state;
        return (
            <div className="container">
                <WSpinner loading={spinner} />

                <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb="FAQs" />
                <vcm-help id="vcm-faq">
                    {' '}
                    <InnerHTML html={content} />{' '}
                </vcm-help>
            </div>
        );
    }
}

export default FAQWidget;
