/**
 * Security Preferences Component
 ********************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description This page displays list of security preferences available for the user to update / modify their own sign in methods
 * @createdDate [06/11/2019]
 * ***************************************
 * @createdDate Manjunath V
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedReason Updated Styles (fonts , spacing between cards) for this page and added Breadcrumbs
 */

import React, { Component } from 'react';
import { CardDeck, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { WCard, WBreadCrumb, WMessageBar, CommonButtons } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import cardData from './cardData';
import constants from './constants';
import userStyles from '../UserManagementStyles';

const { breadCrumbValues, pageName, fontAwesomeStyle, cardTitleStyle, cardColor, cardStyleDisabled} = constants;
const { cardBodyStyle, hide} = userStyles;

export default class SecurityPreferencesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardDeckData: cardData,
            notificationMsg: null
        };
        // This binding is necessary to make `this` work in the callback

        this.bindCardForSecurityPref = this.bindCardForSecurityPref.bind(this);
        this.clickBack = this.clickBack.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const { message } = location || {};

        // if (this.props.location.state !== undefined) {
        if (message) {
            this.setState({
                notificationMsg: message
            });
        }
    }

    clickHandler = (e) => {
        sessionStorage.setItem('stepname', e.currentTarget.innerText); 
    }

    bindCardForSecurityPref() {
        const { cardDeckData } = this.state;
        const elements = [];

        cardDeckData.map((item) => {
            elements.push(
                <Col key={item.cardId} md={4} lg={4} xl={4}>
                 {item.cardTitleText === 'Choose Sign In Methods' ?               
                    <WCard
                        variant='outline'
                        // className="rightArrow"
                        fontawesomestyle={fontAwesomeStyle}
                        cardtitletext={item.cardTitleText}
                        leftcornercard={cardColor}
                        cardtitlestyle={cardTitleStyle}
                        cardstyle={cardStyleDisabled}
                        cardbodystyle={cardBodyStyle}
                        buttonstyle={hide}
                        linkcard="true"
                       
                    />
                :
                    <WCard
                        variant='outline'
                        // className="rightArrow"
                        fontawesomestyle={fontAwesomeStyle}
                        cardtitletext={item.cardTitleText}
                        leftcornercard={cardColor}
                        cardtitlestyle={cardTitleStyle}
                        cardstyle={cardStyleDisabled}
                        cardbodystyle={cardBodyStyle}
                        buttonstyle={hide}
                        linkcard="true"
                    />
                // </Link>
                }
                </Col>
            );
            return 0;
        });
        return (elements);
    }

    clickBack() {
        const { history } = this.props;
        if (history) {
            history.push("profile");
        }
    }

    renderNotificationMsg() {
        const { notificationMsg } = this.state;
        return (
            <WMessageBar text={notificationMsg} />
        )
    }

    render() {
        const { notificationMsg } = this.state;

        return (<div>
            {notificationMsg ? <WMessageBar text={notificationMsg} /> : null}
            <div className="container">
                <WBreadCrumb
                    breadCrumbItems={breadCrumbValues}
                    activeCrumb='Security Preferences'
                />
                <SideHeader title={pageName} />
                <CardDeck>
                    {this.bindCardForSecurityPref()}
                </CardDeck>
            </div>
            <CommonButtons backClick={this.clickBack} nextstyle={hide} arialabelback="Back to profile and preferences" />
        </div>);
    }
}

SecurityPreferencesComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    location: PropTypes.shape({
        state: PropTypes.shape({
            message: PropTypes.shape({
                messageText: PropTypes.string,
                info: PropTypes.string
            })
        }),
    }),
};

SecurityPreferencesComponent.defaultProps = {
    history: {},
    location: {},
};
