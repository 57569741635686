import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { localStateManagementActions, msrActions,loginActions } from '../../shared/Actions';
import MSRSSO from './MSRSSO';

const mapStateToProps = (state) => ({
    localStateData: state.localStateManagementReducerData,
    redirectFlag : state.msrFlowDetails,    
    loginData: state.loginData
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...msrActions,     
    ...loginActions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MSRSSO));
