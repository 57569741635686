import React from 'react';
import styled from 'styled-components';
import { WButton } from './../../../../../common';
import headerImage from './../../../../../assets/RMDImage.jpg';
import LeftArrow from './../../../../../assets/rightArrow_green.png';
import consts from '../consts';

const RMDSmallBox = styled.div`
    width: 25%;
    margin: 20px 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 5px 5px 5px #F0F0F0;
    font-size: 1rem;
    position: relative;
`;

const RMDBiggerBox = styled.div`
    width: 75%;
    margin: 20px 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 5px 5px 5px #F0F0F0;
    font-size: 1rem;
`;

const RMDBoxTitle = styled.h2`
    margin: 40px 0px 20px 20px;
    color: #466D8D;
`;

const RMDSmallBoxTitle = styled.h2`
    margin: 0.6rem;
    color: white;
    position: absolute;
    top: 1.2rem;
    left: 0.6rem;
    font-size: calc(0.75em + 1vmin);
`;

const RMDBoxImage = styled.img` 
    height: 9rem;
    width: 100%;
`;

const RMDBoxBody = styled.div`
    margin: 0px 20px;
`;

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RetirementContainer = styled.div`
    width: 100px;
    color: white;
    height: 20px;
    font-size: 14px;
    margin-bottom: 50px;
    opacity: 1;
    position: absolute;
    outline: white;
    background: #466D8D padding-box;
    box-shadow: 0px 0px 15px #8bc10529;
    border: none;
    border-radius: 0;
    padding-left: 5px;
`
const RetirementEnd = styled.div`
    width:20px;
    content: '';
    width: 0px;
    height: 0px;
    border-left: 20px solid #466D8D;
    border-bottom: 20px solid transparent; 
    position: absolute;
    bottom: 0;
    right: 0;
    left: 100px;
`

const ButtonSpan = styled.span`
    margin-right: 20px;
`;

const ButtonsContainer = styled.div`
    margin: 1.2rem 1.2rem;
    display: inline-block;
    padding: 5px;
`;

const ReadArticleButton = styled(ButtonsContainer)`
    width: 100%;
`;

const SeeAllRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem;
`;

const SeeAllCol = styled.div`
    font-weight: 700;
    font-size: 16px;
    font-family: benton-sans;
    color: #004a98;
    margin-right: 1rem;
`;

const buttonstyle = {
    width: '80%'
};

const RMDInfoFooter = (props) => {
    return (
        <>
            <SectionWrapper>
                <RMDSmallBox>
                    <RetirementContainer>RETIREMENT<RetirementEnd /></RetirementContainer>
                    <RMDBoxImage src={headerImage} alt="" />
                    <RMDSmallBoxTitle>What is a Required Minimum Distribution (RMD)?</RMDSmallBoxTitle>
                    <RMDBoxBody>Tax rules establish a Required Minimum Distribution, or RMD, that savers must take out of retirement accounts starting the year after they reach either age 70 ½ or age 72.</RMDBoxBody>
                    <ReadArticleButton>
                        <a href={consts.PATH_CONSTANTS.article}>
                            <WButton buttonstyle={buttonstyle} buttontext="Read article" id="read-article" role="link"></WButton>
                        </a>
                    </ReadArticleButton>
                </RMDSmallBox>
                <RMDBiggerBox>
                    <RetirementContainer>RETIREMENT<RetirementEnd /></RetirementContainer>
                    <RMDBoxTitle>Required Minimum Distribution Calculators</RMDBoxTitle>
                    <RMDBoxBody>
                        The IRS requires that you withdraw at least a minimum amount - known as a Required Minimum Distribution - from your retirement accounts annually; starting the year you turn age 72 (or 70 1/2 if born before 7/1/1949).
                        Determining how much you are required to withdraw is an important issue in retirement planning.
                        Use this calculator to create a hypothetical projection of your future Required Minimum Distributions (RMD). This calculator has been updated for the 'SECURE Act of 2019 and CARES Act of 2020'.
                    </RMDBoxBody>
                    <RMDBoxBody>
                        The interactive Required Minimum Distribution Calculators are made available to you as self-help tools for your independent use and are not intended to provide investment advice. Victory Capital cannot and does not guarantee their applicability or accuracy in regards to your individual circumstances. All examples are hypothetical and are for illustrative purposes. Victory Capital encourages you to seek personalized tax advice from qualified professionals.
                    </RMDBoxBody>
                    {/* <ButtonsContainer>
                        <WButton buttontext="Current Year" id="current-year" role="link" onClick={() => redirectTo('currentYearCalculator')}></WButton>
                        <ButtonSpan />
                        <WButton buttontext="Future" id="future" role="link" onClick={() => redirectTo('futureYearCalculator')}></WButton>
                    </ButtonsContainer> */}
                    
                    <ButtonsContainer>
                        <WButton buttontext="Current Year" id="current-year" role="link" ></WButton>
                        <ButtonSpan />
                        <WButton buttontext="Future" id="future" role="link"></WButton>
                    </ButtonsContainer>
                </RMDBiggerBox>
            </SectionWrapper>
            <a href={consts.PATH_CONSTANTS.retirementPlanCalculator}>
                <SeeAllRow>
                    <SeeAllCol>See All Retirement</SeeAllCol>
                    <div>
                        <img src={LeftArrow} alt="arrow" />
                    </div>
                </SeeAllRow>
            </a>
        </>
    )
}

export default RMDInfoFooter;