import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import RemoveButton from 'assets/btnRemove.svg';
import * as Styles from './WMultiSelectDropDownStyle';
import WIcon from '../WIcon/WIcon';

const VCMMultiSelect = props => {
    // eslint-disable-next-line react/prop-types
    const { onChange, itemlist, id, value, errortext, name, removeIconStyle, formatDisplay } = props;
    let prevSelected = null;
    const newListItems = itemlist.map((item, index) => {
        let newItem;
        if (typeof item !== 'object') {
            let selected = false;
            if (value === index.toString()) {
                selected = true;
                prevSelected = index;
            }
            newItem = {
                value: item,
                key: index.toString(),
                selected,
            };
        } else {
            let selected = false;
            if (value === item.key) {
                selected = true;
                prevSelected = index;
            }
            newItem = { ...item, selected };
        }
        return newItem;
    });
    const [list, setList] = React.useState(newListItems);
    const [listOpen, setListOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(prevSelected);
    const [changed, setChanged] = React.useState(false);
    const toggleContainerRef = React.useRef(null);
    // const refs = React.useRef(newListItems.map(() => React.createRef()));
    const refs = React.useRef([]);
    const inputRef = React.useRef(null);

    const selectItem = index => () => {
        let currentSelectedIndex;
        const newList = list.map((item, i) => {
            const newItem = { ...item };
            if (i === index) {
                newItem.selected = !item.selected;
                currentSelectedIndex = i;
            } else newItem.selected = item.selected;
            return newItem;
        });
        setList(newList);
        if (selectedIndex === null || list[+selectedIndex].key !== list[+currentSelectedIndex].key) {
            setSelectedIndex(currentSelectedIndex);
        }
        setChanged(true);
        setListOpen(true);
        inputRef.current.focus();

        const selectedItems = newList.filter(item => item.selected === true);
        inputRef.current.dataset.placeholder = selectedItems.length === 0 ? 'Select' : '';
    };

    const toggleList = () => () => {
        setListOpen(prevListOpen => !prevListOpen);
    };

    const close = React.useCallback(
        event => {
            if (listOpen && toggleContainerRef.current && !toggleContainerRef.current.contains(event.target)) {
                setListOpen(false);
            }
        },
        [listOpen],
    );

    React.useEffect(() => {
        if (changed) {
            const target = inputRef.current;
            target.value = list[+selectedIndex].key;
            onChange({ target, list });
            setChanged(false);
        }
    }, [list, onChange, changed, selectedIndex]);

    React.useEffect(() => {
        window.addEventListener('click', close);
        return () => window.removeEventListener('click', close);
    }, [close]);

    React.useEffect(() => {
        if (listOpen) {
            const ind = selectedIndex || 0;
            if (refs.current.length > 0) {
                refs.current[+ind].focus();
            }
        }
    }, [listOpen, selectedIndex]);

    React.useEffect(() => {
        let prevSelectedd = null;
        const newListItemss = itemlist.map((item, index) => {
            let newItem;
            if (typeof item !== 'object') {
                let selected = false;
                if (value === index.toString()) {
                    selected = true;
                    prevSelectedd = index;
                }
                newItem = {
                    value: item,
                    key: index.toString(),
                    selected,
                };
            } else {
                let selected = false;
                if (value === item.key) {
                    selected = true;
                    prevSelectedd = index;
                }
                newItem = { ...item, selected };
            }
            return newItem;
        });
        setList(newListItemss);
        setSelectedIndex(prevSelectedd);
    }, [itemlist, value]);

    const SPACEBAR_KEY_CODE = [0, 32];
    const ENTER_KEY_CODE = 13;
    const DOWN_ARROW_KEY_CODE = 40;
    const UP_ARROW_KEY_CODE = 38;
    const ESCAPE_KEY_CODE = 27;

    const focusListItem = (code, index) => {
        if (code === DOWN_ARROW_KEY_CODE) {
            if (index < list.length - 1) {
                refs.current[+index + 1].focus();
            }
        } else if (code === UP_ARROW_KEY_CODE) {
            if (index > 0) {
                refs.current[+index - 1].focus();
            }
        }
    };

    const toggleHover = (index, mouseState) => () => {
        if (mouseState === 'enter') {
            refs.current[+index].focus();
        }
    };

    const onKeyEvent = index => e => {
        e.preventDefault();
        switch (e.keyCode) {
            case ENTER_KEY_CODE:
                selectItem(index)();
                break;

            case DOWN_ARROW_KEY_CODE:
                focusListItem(DOWN_ARROW_KEY_CODE, index);
                break;

            case UP_ARROW_KEY_CODE:
                focusListItem(UP_ARROW_KEY_CODE, index);
                break;

            case ESCAPE_KEY_CODE:
                setListOpen(false);
                break;

            default:
                break;
        }
    };

    const toggleListVisibility = () => e => {
        const openDropDown = SPACEBAR_KEY_CODE.includes(e.keyCode) || e.keyCode === ENTER_KEY_CODE;
        if (e.keyCode === ESCAPE_KEY_CODE) {
            e.preventDefault();
            setListOpen(false);
        }

        if (openDropDown) {
            e.preventDefault();
        }
    };

    const setRef = index => el => {
        refs.current[+index] = el;
    };

    const erorrFieldName = errortext ? 'errorInputField' : '';

    const disaplySelectedValue = selectedValue => {
        const trimedValue = selectedValue.length > 30 ? `${selectedValue.substring(0, 20)}...` : selectedValue;
        return formatDisplay ? formatDisplay(selectedValue) : trimedValue;
    };

    const getKey = (key, index) => {
        return `${key}-${index}`;
    };

    function getSelectedItemHtml(item, index) {
        if (item.selected) {
            inputRef.current.dataset.placeholder = '';

            return (
                <Styles.SelectedItem key={item.key}>
                    <div key={getKey('select-value-key', index)} className="displayValue">
                        {disaplySelectedValue(item.value)}
                    </div>

                    <WIcon
                        src={RemoveButton}
                        data-testid={getKey('remove-account', item.key)}
                        id={getKey('remove-account-id', item.key)}
                        alt="Remove "
                        fontawesomestyle={Object.assign(removeIconStyle)}
                        onClick={selectItem(index)}
                        key={getKey('remove-account-icon', index)}
                    />
                </Styles.SelectedItem>
            );
        }

        return <></>;
    }

    return (
        <Styles.SelectContainer>
            <Styles.SelectDiv>
                <Styles.StyledInput
                    className={`${erorrFieldName} analytics-form-fields`}
                    isOpen={listOpen}
                    id={id}
                    name={name}
                    ref={inputRef}
                    data-placeholder="Select"
                    value={selectedIndex !== null ? list[+selectedIndex].value : ''}
                    onClick={toggleList()}
                    readOnly
                    onKeyDown={toggleListVisibility()}
                >
                    {list.map((item, index) => {
                        return getSelectedItemHtml(item, index);
                    })}
                    <Styles.InputIcon>
                        <Styles.InputDownArrow />
                    </Styles.InputIcon>
                </Styles.StyledInput>
            </Styles.SelectDiv>
            {errortext && <Styles.ErrMsg className="analytics-error-field">{errortext}</Styles.ErrMsg>}
            {/* role='presentation' onClick={e => e.stopPropagation()} */}
            {listOpen && (
                <Styles.SelectBox ref={toggleContainerRef}>
                    <Styles.ItemContainer>
                        {list.map((item, index) => (
                            <Styles.CheckBoxLableStyle
                                key={getKey('check-box-remove-account', item.key)}
                                type="checkbox"
                                // eslint-disable-next-line react/jsx-no-literals
                                id={`topic-CheckBox-${item.value}`}
                                // eslint-disable-next-line react/jsx-no-literals
                                label={`${disaplySelectedValue(item.value)}`}
                                ref={setRef(index)}
                                tabIndex="0"
                                role="presentation"
                                defaultChecked={item.selected}
                                selected={!!item.selected}
                                onClick={selectItem(index)}
                                onKeyDown={onKeyEvent(index)}
                                onMouseEnter={toggleHover(index, 'enter')}
                                onMouseLeave={toggleHover(index, 'leave')}
                                className='analytics-form-fields'
                            />
                        ))}
                    </Styles.ItemContainer>
                </Styles.SelectBox>
            )}
        </Styles.SelectContainer>
    );
};

VCMMultiSelect.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
};

VCMMultiSelect.defaultProps = {
    id: '',
    onChange: () => {},
    itemlist: [],
    value: '',
    errortext: '',
};

const VcmMultiSelectDropDownWithLabel = props => {
    const { label, labelsm, valuesm, labelStyle, optional, optionalText, ...rest } = props;
    const { id } = rest;
    return (
        <Styles.StyledRow>
            <Styles.StyledLabel column sm={labelsm} style={labelStyle} htmlFor={id}>
                {label}
                {optional && <Styles.StyledOptionalSpan>{optionalText}</Styles.StyledOptionalSpan>}
            </Styles.StyledLabel>
            <Col sm={valuesm}>
                <VCMMultiSelect {...rest} />
            </Col>
        </Styles.StyledRow>
    );
};

VcmMultiSelectDropDownWithLabel.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    itemlist: PropTypes.instanceOf(Object),
    value: PropTypes.string,
    errortext: PropTypes.string,
    labelsm: PropTypes.number,
    valuesm: PropTypes.number,
    containerStyle: PropTypes.instanceOf(Object),
    optional: PropTypes.bool,
    optionalText: PropTypes.string,
    labelStyle: PropTypes.instanceOf(Object),
    removeIconStyle: PropTypes.instanceOf(Object),
    name: PropTypes.string,
    formatDisplay: PropTypes.func,
};

VcmMultiSelectDropDownWithLabel.defaultProps = {
    labelsm: 2,
    valuesm: 4,
    id: '',
    label: '',
    onChange: () => {},
    value: '',
    errortext: '',
    itemlist: ['item1', 'item2', 'item3', 'item4', 'item5', 'item6'],
    containerStyle: {},
    removeIconStyle: {},
    optional: false,
    labelStyle: {},
    optionalText: 'optional',
    name: 'PropTypes.string',
    formatDisplay: null,
};

export default VcmMultiSelectDropDownWithLabel;
