import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Row, Col, Form } from 'react-bootstrap';
// import routeConstants from 'routeConstants';
// import LoadingOverlay from 'react-loading-overlay';
// import { assignObj } from 'utils';
import userConstants, { getMaskedNumber } from 'modules/UserManagementAndSecurityModule/userConstants';

import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { WRadio, WStepper, CommonButtons, WMessageBar, WSpinner } from '../../../../common';
import data from './constants';
import styles from '../../UserManagementStyles';

const { stepperColStyle } = styles.stepperStyle;

export class OtpAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationMsg: null,
            error: false,
            cancelled: false,
            email: false,
            mobile: true,
            mobileNumer: '',
            redirect: false,
            logOutRoute: {
                pathname: '/sign-in',
                // message: { message: 'You have been successfully logged out' },
            },
            ...props.otpAuthState,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { history, loginState, sendOtp, manageLocalState, getCustomerProfile, location, customerProfile } = props;
        const { multiFactorAuthentication } = location || {};
        const updatedState = {...state};

        if (loginState.sendOtp) {
            const { errorCode, result } = loginState.sendOtp;
                if (errorCode === 0) { 
                    if(multiFactorAuthentication && customerProfile){
                        const onlineId = customerProfile.Online_Id;
                        history.push({
                            pathname: '/verify-otp',
                            multiFactorAuthentication: true,
                            onlineId
                        });
                    } else {
                        history.push('./confirm-otp');
                    }
            }else if (errorCode === 2005){
                updatedState.notificationMsg = {message: userConstants.after24hr};
                localStorage.clear();
                updatedState.redirect =  true;
                updatedState.logOutRoute =  {
                    pathname: '/sign-in',
                    message: { message: userConstants.after24hr },
                    failure: true,
                }
            }else if (errorCode === 2004){
                updatedState.notificationMsg = {message: userConstants.after1hr};
                localStorage.clear();
                updatedState.redirect = true;
                updatedState.logOutRoute =  {
                    pathname: '/sign-in',
                    message: { message: userConstants.after1hr },
                    failure: true,
                }
            }
             else {
                updatedState.notificationMsg = {message: result}
            }
            sendOtp('');
        }
        if (loginState.getCustomerProfile) {
            const customerData = { customerProfile: { ...loginState.getCustomerProfile } };
            const {primaryMobilePhone=''} = loginState.getCustomerProfile.phoneInformation;
            const maskedNumber = getMaskedNumber(primaryMobilePhone || '');
            updatedState.mobileNumer = maskedNumber;
            getCustomerProfile('');
            manageLocalState(customerData);
        }

        return updatedState;
    }

    componentDidMount() {
        const { setJWTToken, getCustomerProfile, customerProfile } = this.props;
        // console.log('@debug for local data ',localdata);
        const token = localStorage.getItem('token');
        if(token){
            setJWTToken(token);
            if(!customerProfile){
                getCustomerProfile({})
            }else {
                const {primaryMobilePhone=''} = customerProfile.phoneInformation;
                const maskedNumber = getMaskedNumber(primaryMobilePhone || '');
                this.setState({mobileNumer: maskedNumber})
            }
        } else {
            this.handleLogOut();
        }
    }

    clickMobile = () => {
        this.setState(state => ({ ...state, email: false, mobile: true }));
    };

    // onBack = () => this.setState((state) => ({ ...state, cancelled: true }));

    onNext = () => {
        const { customerProfile, sendOtp } = this.props;
        // console.log(loggedUserDetails)
        if (customerProfile) {
            const { phoneInformation } = customerProfile;
            const onlineId = customerProfile.Online_Id;
            const { primaryMobilePhone = '' } = phoneInformation;
            const primaryMobile = primaryMobilePhone || '';
            const phoneNumber = primaryMobile.split(/[\s()-]+/).join('')
            const otpData = {
                phoneNumber,
                requestType: 'OTP',
                onlineId,
            };
            sendOtp(otpData);
           
            // history.push('./confirm-otp');
        }
    };

    onClose = () => {
        this.setState(state => ({ ...state, notificationMsg: null }));
    };

    handleLogOut = () => {
       // const { location } = this.props;
       // const {logOutRoute} =this.state;
       // const {pathname} = logOutRoute;
        const token = localStorage.getItem('token');
        if (token) {
            Auth.signOut()
                .then(() => {
                    // console.log(data);
                    localStorage.clear();
                    this.setState({
                        redirect: true,
                        logOutRoute: {
                            pathname: '/sign-in',
                            // message: { message: '' },
                        },
                    });
                })
                .catch(() => {
                    // console.log(err)
                });
            // else {
            //     this.setState({redirect: false})
            // }
        } else {
            // console.log('no token');
            this.setState({
                redirect: true,
                pathname: '/',
            });
        }
    }

    render() {
        const { mobile, mobileNumer, notificationMsg, error, redirect,logOutRoute } = this.state;
        const {loginState, location} = this.props;
        const { multiFactorAuthentication } = location || {};
        let redirectionResult = '';
        if (redirect) {
            redirectionResult = <Redirect to={logOutRoute} />;
        }
        return (
            <div className="container-fluid">
                {redirectionResult}
                <WSpinner loading={loginState.isLoading} />
                {/* <LoadingOverlay active={loginState.isLoading} spinner styles={assignObj({ wrapper: { overflow: userConstants.hidden } })} /> */}
                {notificationMsg ? <WMessageBar text={notificationMsg} onClose={this.onClose} error={error} /> : null}
                <div className="container">
                   { multiFactorAuthentication ? '' :<WStepper
                        className="userWizard"
                        currentPage={0}
                        pages={data.pages}
                        stepperColStyle={stepperColStyle}
                   /> }
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader title={data.title} info={data.info} />
                        </Col>
                        <Col md lg xl>
                            <Form.Group role="radiogroup" style={data.formGroup}>
                                <WRadio
                                    id="mobileRadio"
                                    value={data.mobileText + mobileNumer}
                                    selected={mobile}
                                    onClick={this.clickMobile}
                                    role="radio"
                                    radiotext={styles.radioText}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <CommonButtons backstyle={styles.hide} nextClick={this.onNext} blockstyle={styles.commonButtons}  arialabelback="Back to Sign in"  />
                {/* {cancelled ? <Redirect to='/sign-in' /> : null} */}
            </div>
        );
    }
}

OtpAuthentication.propTypes = {
    sendOtp: PropTypes.func,
    setJWTToken: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    customerProfile: PropTypes.instanceOf(Object),
    loginState: PropTypes.instanceOf(Object),
    otpAuthState: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
};

OtpAuthentication.defaultProps = {
    sendOtp: () => {},
    setJWTToken: () => {},
    getCustomerProfile: () => {},
    customerProfile: null,
    loginState: {},
    otpAuthState: {},
    manageLocalState: () => {},
    history: [],
    location: {}
};

export default OtpAuthentication;
