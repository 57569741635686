import React, {useState, useEffect } from 'react';
import { Accordian, CommonButtons, AccordianTitle, WInput,  WRadio, WSpinner, ConfirmModal } from 'common';
import { FieldValue, SelectDropDown } from '../../../CustomerManagementModule/components';
import '../MarketNAOStyles.css';

import { Row, Card } from 'react-bootstrap';
import styles from '../../PersonalInfo/styles';
import { riskLabel, employeeBDLabel, relatedToEmployeeLabel, EmployeeAnotherBorker, relatedToEmpAnother, memRelToStock, maintainOtherAccount,regulatoryOptional, foreignAccount, isThisForeignInst, foreignbank, CentralBank, Offshorebanking, Noncooperativecountry, section311, PoliticallyExposedPerson, memofdir, anotherBrokerLabel, radioOptions, brokerDealer, investorProfile, mandatoryFieldlabel, mandatoryText, HelpText_RegulatoryHeading, HelpText_InvestorProfileRisk, HelpText_RelatedBrokerDealer, HelptText_BrokerDealerPolitical, HelptText_AnotherBrokerDealer, HelptText_BrokerDealerCompany, investment_Objective, vcEmployeeText, vcEmployeeLabel } from '../consts'
import {postMarketNAOInfo, updateMarketNAOInfo, clearAPIStatus} from '../../../../shared/Actions/MarketNAOActions'
import {getTransactionCompositeData} from '../../../../shared/Actions/TransactionCompositeDataAction'
import { useSelector,useDispatch } from 'react-redux';
import { getToken } from 'utils';
import StyledToolTip from '../../MarketPersonalInfoCard/StyledToolTip.js'
import helperIcon from '../../../../assets/helperIcon.png'
import { MARKET_NAO_ACCOUNT_TYPES } from 'modules/AccountManagementModule/consts';
import consts from '../../MarketPersonalInfoComponent/consts';
import { handleErrorForForeignInstitution, handleErrorWhenBrokerDealerEmployment, handleErrorWhenBrokerEmploymentChildSection, handleMainStateError,handleRadioButtonWhenSelectNo } from './RegulatoryStepCommonHelper';


const assignObj = obj => {
    return obj;
};


const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const inputNumOnly = val => {
        return val ? val.toString().replace(/[a-zA-Z!@$%^*(),.?";:{}_+#&|<>=~`\[\]'\\/]/g, '') : 0;
    };

const alignSelectAccOpt = {  };
const radioText = { font: '500 16px/20px benton-sans' };

//Main Function
export default function RegulatoryInfo(props) {

    //From Redux state
    const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
    const transactionCompositeData= useSelector(state => state.transactionCompositeDataReducerData);
    const generalInvestments=[transactionCompositeData?.general_investment_knowledge?.value?.map(o => o.value)]
    const timeHorizon=[transactionCompositeData?.time_horizon?.value?.map(o => o.value)]
    const sourceOFFund=[transactionCompositeData?.source_of_funds?.value?.map(o => o.value)]
    const riskValueOptions=[transactionCompositeData?.risk_exposure?.value]
    const investmentObjective=[transactionCompositeData?.investment_objective?.value?.map(o => o.value)]
    const investmentGoal=[transactionCompositeData?.investment_goal?.value?.map(o => o.value)]
     const politicalCountryOfOffice = [transactionCompositeData?.country_list?.value.map(o => o.value)];
    const dispatch = useDispatch();
    const regulatoryStateInitial = marketNAOInfoReducerData.marketNAOInfo
    const [state, setStateVariables] = useState({...regulatoryStateInitial.brokerDealer.primary,...regulatoryStateInitial.riskProfile.primary});
    const [formErrors, setFormErrors] = useState({})
    const accountType = marketNAOInfoReducerData.marketNAOInfo.account.accountType
    const isJointAccount =  marketNAOInfoReducerData.marketNAOInfo.account.accountType.includes(MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)

       const verifyRiskHider=props.editRiskValIn
    const verifyBrokerHider=props.editBrokerValIn
     
    const relationshipDropDownData = useSelector(state => state.transactionCompositeDataReducerData?.brkg_relationship?.value)

    const [apiError, setApiError] = useState(false)
    const isLoading = marketNAOInfoReducerData.isLoading
    
    useEffect(() => {
        if(marketNAOInfoReducerData.isriskProfileApiSuccess && marketNAOInfoReducerData.isbrokerDealerApiSuccess){
            dispatch(clearAPIStatus('riskProfile'))
            dispatch(clearAPIStatus('brokerDealer'))
            verifyRiskHider && props.editRiskValOut(!verifyRiskHider)
            verifyBrokerHider && props.editBrokerValOut(!verifyBrokerHider)

            if(props?.editBrokerValIn != true && props?.editRiskValIn != true)
            {
                props.onNextClick()
            }
        }else if(marketNAOInfoReducerData.isriskProfileApiError || marketNAOInfoReducerData.isbrokerDealerApiError){
            setApiError(true)
            dispatch(clearAPIStatus('riskProfile'))
            dispatch(clearAPIStatus('brokerDealer'))
          }
    }, [marketNAOInfoReducerData])

        
    const handleExit = () => {
        setApiError(false)
    }


    
 // SCROLL TO TOP FUNCTION
 const scrollToTop = () => {
    setTimeout(() => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    }))
  }
  useEffect(()=>{
 {if(verifyRiskHider || verifyBrokerHider){return} 
 else{
    document.title = `Regulatory Page | Victory Capital`;
    return scrollToTop()
      }}
  },[])



    // on every change in dropdown of elements
    const handleChangeDropDown = eventKey => e => {
        const targetValue = e.target.value;
        if (eventKey == 'investmentObjective') {
            setStateVariables(prevState => ({
                ...prevState,
                investmentObjective: [targetValue],
            }));
        } else if (eventKey == 'timeHorizon') {
            setStateVariables(prevState => ({ ...prevState, timeHorizon:[targetValue] }));
        } else if (eventKey == 'investmentGoal') {
            setStateVariables(prevState => ({ ...prevState, investmentGoal:[targetValue] }));
        } else if (eventKey == 'investmentKnowledge') {
            setStateVariables(prevState => ({
                ...prevState,
                investmentKnowledge: [targetValue],
            }));
        } else if (eventKey == 'sourceOFFund') {
            setStateVariables(prevState => ({
                ...prevState,
                sourceOFFund: [targetValue],
            }));
        }else if(eventKey =='countryOfOffice')
        {
            setStateVariables(prevState => ({
                ...prevState,
                ['pep']: {...state['pep'], [eventKey]: targetValue} }));
        }
        else if(eventKey == 'relatedToEmployeeOfAnotherBrokerDealer'){
            setStateVariables(prevState => ({
                ...prevState,
                relatedToEmployeeOfAnotherBrokerDealer: {...state['relatedToEmployeeOfAnotherBrokerDealer'], relationship: targetValue}

            }));
        }
        else if(eventKey == 'relatedToBrokerDealer'){
            setStateVariables(prevState => ({
                ...prevState,
                relatedToBrokerDealer: {...state['relatedToBrokerDealer'], relationship: targetValue}

            }));
        }
         else {
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
        }
    };


    const onInputChange3 = (rootObj, eventKey) => (e) =>{

        const targetValue = e.target.value

        if(rootObj !== null){
            setStateVariables(prevState => ({ 
                ...prevState,
                [rootObj]: {...state[rootObj], [eventKey]: targetValue} }));

        }else{
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
        }
    }

    // on Chnage of radio buttons
    const radioButtonClick = (eventKey,buttonType,parentObj='') =>  e => {
        const targetValue = e.target.value

        if(parentObj == ''){

        
        if(eventKey == 'riskExposure'){
            setStateVariables(prevState => ({ ...prevState, riskExposure: targetValue }));
        }
        else if (eventKey == vcEmployeeText) {
            setStateVariables(prevState => ({ ...prevState, vcEmployee: targetValue }));
        }
        else if (eventKey == 'brokerDealerEmployment') {
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'',targetValue,'','','brokerDealerEmployment'));
        }
        else if (eventKey == 'isRelatedToBrokerDealer') {

            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isRelatedToBrokerDealer',targetValue,'relatedToBrokerDealer',{
                firstname: "",
                lastName: "",
                relationship: "",
                employee: "",
            }));

        }
        else if (eventKey == 'isEmployeeOfAnotherBrokerDealer') {
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isEmployeeOfAnotherBrokerDealer',targetValue,'EmployeeOfAnotherBrokerDealer',{
                firstname: "",
                lastName: "",
            }));
        }

        
        else if (eventKey == 'isRelatedToEmployeeOfAnotherBrokerDealer') {
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isRelatedToEmployeeOfAnotherBrokerDealer',targetValue,'relatedToEmployeeOfAnotherBrokerDealer',{
                firstname: "",
                lastName: "",
                relationship: "",
                brokerDealerName: "",
            }));

        }

        
        

        else if (eventKey == 'isMemberEmployeeOfStockExchangeFINRA') {
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isMemberEmployeeOfStockExchangeFINRA',targetValue,'memberEmployeeOfStockExchangeFINRA',{
                nameOfAffiliation: "",
            }));
           
        }

        
        else if (eventKey == 'isOtherBrokerageAccounts') {
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isOtherBrokerageAccounts',targetValue,'otherBrokerageAccounts',{
                brokerageName: "",
                yearsOfInvestingExperience: "",
            }));

        }

    
        else if (eventKey == 'isDirectorShareholderOfPubliclyTradedCompany') {

            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isDirectorShareholderOfPubliclyTradedCompany',targetValue,'directorShareholderOfPubliclyTradedCompany',{
                companyName: "",
            }));

        }

        else if (eventKey == 'isAccountOpenedForForeignInstitution') {
            
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'isAccountOpenedForForeignInstitution',buttonType,'accountOpenedForForeignInstitution',{
                isFinancialInstitution:'',isPrivateBank:'', isForeignBank:'',
                foreignBank: {
                    isCentralBank:'', isBankingLicense:'',isTerritory:'',isSubjectToSection311:''
                }
            }));

        }
        else if (eventKey == 'isForeignBank') {

            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'',"",'',"",eventKey));


        }


        else if (eventKey == 'isCentralBank') {
            setStateVariables(prevState => ({ ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    foreignBank: {
                        ...state.accountOpenedForForeignInstitution.foreignBank,
                        isCentralBank:targetValue
                    }
                }
            }));
        }

        else if (eventKey == 'isBankingLicense') {
            setStateVariables(prevState => ({ ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    foreignBank: {
                        ...state.accountOpenedForForeignInstitution.foreignBank,
                        isBankingLicense:targetValue
                    }
                }
            }));
        }

        else if (eventKey == 'isTerritory') {
            setStateVariables(prevState => ({ ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    foreignBank: {
                        ...state.accountOpenedForForeignInstitution.foreignBank,
                        isTerritory:targetValue
                    }
                }
            }));
        }

        else if (eventKey == 'isSubjectToSection311') {
            setStateVariables(prevState => ({ ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    foreignBank: {
                        ...state.accountOpenedForForeignInstitution.foreignBank,
                        isSubjectToSection311:targetValue
                    }
                }
            }));
        }


        else if(eventKey == 'isPep'){
            setStateVariables(handleRadioButtonWhenSelectNo(state,state,buttonType,'',targetValue,'','','isPep'));
            
        }
        else{
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
        }

        }else{
            setStateVariables(prevState => ({ ...prevState, [parentObj] :{...state[parentObj],[eventKey]:targetValue} }));
        }
    }


    const getValue = (key, arr) => {
        const objFound = arr.find(item => item.key === key)
        if(objFound){
            return objFound.value
        }else{
            return ''
        }
    }
    useEffect(() => {
        
    //RiskApicall
    dispatch(getTransactionCompositeData({ data: { datakey:["risk_exposure"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["time_horizon"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["general_investment_knowledge"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["source_of_funds"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["investment_objective"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["investment_goal"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["country_list"] }, token: getToken() }))
    dispatch(getTransactionCompositeData({ data: { datakey:["brkg_relationship"] }, token: getToken() }))


     if(Object.keys(formErrors).length == 0 && state.onNext){

        state.onNext = false

        setStateVariables(prevState => ({ ...prevState, onNext: false }));    

        let jointBrokerDealerPayloadState = JSON.parse(JSON.stringify(marketNAOInfoReducerData.marketNAOInfo.brokerDealer.joint));
         jointBrokerDealerPayloadState.pep.countryOfOffice = politicalCountryOfOffice[0][parseInt(marketNAOInfoReducerData.marketNAOInfo.brokerDealer.joint.pep.countryOfOffice)]

        let brokerDealerPayloadState = JSON.parse(JSON.stringify(state));
        brokerDealerPayloadState.pep['countryOfOffice'] = politicalCountryOfOffice[0][parseInt(state.pep.countryOfOffice)]

        if(state.relatedToEmployeeOfAnotherBrokerDealer.relationship){
            brokerDealerPayloadState.relatedToEmployeeOfAnotherBrokerDealer.relationship = getValue(state.relatedToEmployeeOfAnotherBrokerDealer.relationship, relationshipDropDownData )
        }
        
        let brokerDealerPayload = {};

        if((verifyRiskHider == true || verifyBrokerHider == true) && (accountType == consts.ACCOUNTTYPES.JOINT))
        {
            brokerDealerPayload = {
                applicationId: marketNAOInfoReducerData.applicationId ,
                pageNumber: 6,
                source: "web",
                brokerDealer: {
                    primary: brokerDealerPayloadState,
                    joint:jointBrokerDealerPayloadState
                }
            }
        }
        else
        {
            brokerDealerPayload = {
                applicationId: marketNAOInfoReducerData.applicationId ,
                pageNumber: 6,
                source: "web",
                brokerDealer: {
                    primary: brokerDealerPayloadState
                }
            }
        }
          
        dispatch(postMarketNAOInfo({
            url: 'brokerDealer',
            payloadData: brokerDealerPayload
        }))

       

        // RIsk info
        const riskpayloadState = {
            InvestmentObjective: state.investmentObjective != '' ? investmentObjective[0][parseInt(state.investmentObjective)] : '',
            TimeHorizon: state.timeHorizon != '' ? timeHorizon[0][parseInt(state.timeHorizon)] : '',
            RiskExposure: state.riskExposure,
            InvestmentKnowledge: state.investmentKnowledge != '' ? generalInvestments[0][parseInt(state.investmentKnowledge)] : '',
            InvestmentGoal: state.investmentGoal != '' ? investmentGoal[0][parseInt(state.investmentGoal)] : '',
            SourceOFFund: state.sourceOFFund != '' ? sourceOFFund[0][parseInt(state.sourceOFFund)] : '',
            sourceOFFundInfo: state.sourceOFFundInfo
        }

        const payload2 = {
            applicationId: marketNAOInfoReducerData.applicationId ,
            pageNumber: 6,
            source: "web",
            riskProfile: {
                primary: riskpayloadState
            }
        }
          
        dispatch(postMarketNAOInfo({
            url: 'riskProfile',
            payloadData: payload2
        }))




        let tempState = state;
        tempState['countryOfOfficeInfo'] = politicalCountryOfOffice[0][parseInt(state.pep.countryOfOffice)]

  


        
        // -------------------------UPDATE GLOBAL REDUX STATE
        const saveToLocalpayload = {
            sectionToBeUpdated:'brokerDealer',
            state: state,
            isPrimary:true,
            isSecondary: false
        }
        
        dispatch(updateMarketNAOInfo(saveToLocalpayload))
        
            const saveToLocalpayload2 = {
            sectionToBeUpdated:'riskProfile',
            state: riskpayloadState,
            isPrimary:true,
            isSecondary: false
        }
        
        dispatch(updateMarketNAOInfo(saveToLocalpayload2))

        }
    }, [formErrors])
    

    // Hnadle validation
    const handleValidation = (state) => {
        let errors={}
         // if it is old application && from saved application we have added the undefined condition
         // First 


        // end
        errors = handleMainStateError(state,errors);
        // Brokerdealer employment child section

        errors = handleErrorWhenBrokerDealerEmployment(state,errors)

        // handleErrorWhenBrokerEmploymentChildSection function start

            // Related to Brokerdealer employment child section
       
         // Employee Of Another Brokerdealer employment child section
        

       

        // Related to Employee Of Another Brokerdealer employment child section

        errors = handleErrorWhenBrokerEmploymentChildSection(state,errors);
       

        // // Account being opened for foreign Institution foreignbank child section
        errors = handleErrorForForeignInstitution(state,errors);
        // Member/Employee Of StockExchangeFINRA child section
        if(state.isMemberEmployeeOfStockExchangeFINRA === 'Yes'){
            if(state.memberEmployeeOfStockExchangeFINRA.nameOfAffiliation === ''){
                errors.memberEmployeeOfStockExchangeFINRA = "Mandatory field"
            }
        }

        if(state.isDirectorShareholderOfPubliclyTradedCompany === ''){
            errors.isDirectorShareholderOfPubliclyTradedCompany = "Mandatory field"
        }

        // Direct shareholder  Of publicly trade company child section
        if(state.isDirectorShareholderOfPubliclyTradedCompany === 'Yes'){
            if(state.directorShareholderOfPubliclyTradedCompany.companyName === ''){
                errors.directorShareholderOfPubliclyTradedCompany = "Mandatory field"
            }
        }

        if(state.isAccountOpenedForForeignInstitution === ''){
            errors.isAccountOpenedForForeignInstitution = "Mandatory field"
        }

        if(state.isPep === ''){
            errors.isPep = "Mandatory field"
        }
        // Politicaly Exposed Person child section
        if(state.isPep === 'Yes'){
            if(state.pep.firstName === ''){
                errors.pep = "Mandatory field"
            }
            if(state.pep.lastName === ''){
                errors.pep = "Mandatory field"
            }
            if(state.pep.officeHeld === ''){
                errors.pep = "Mandatory field"
            }
            if(state.pep.countryOfOffice === ''){
                errors.pep = "Mandatory field"
            }
        }

        if(state.sourceOFFund == '16')
        {
            if(state.sourceOFFundInfo == undefined || state.sourceOFFundInfo == null || state.sourceOFFundInfo == '')
            {
                errors.sourceOFFundInfo = "Mandatory field"
            }
        }
        return errors
    }


    // on next button click if there are internal step, complete them and later switch next section
    const clickNext = () => {

        const isValid = false
        if(isValid){
            props.onNextClick()
        }else{
            setFormErrors(handleValidation(state))
            collectJointInfo()
            setStateVariables(prevState => ({ ...prevState, onNext: true }));    
          
        }
    };

         //on Save
    const clickSave=()=>{
                const isValid = false
        if(isValid){
           verifyRiskHider && props.editRiskValOut(!verifyRiskHider)
           verifyBrokerHider && props.editBrokerValOut(!verifyBrokerHider)
        }else{
            setFormErrors(handleValidation(state))
            collectJointInfo()
            setStateVariables(prevState => ({ ...prevState, onNext: true }));    
    }
}
//on cancel
  const clickCancel=()=>{
          verifyRiskHider && props.editRiskValOut(!verifyRiskHider)
           verifyBrokerHider && props.editBrokerValOut(!verifyBrokerHider)
  }

 
    // on back click
    const clickBack = () => {
        setStateVariables(prevState => ({ ...prevState, back: true }));
    
    };


    const collectJointInfo = () => {
        setStateVariables(prevState => ({ ...prevState, shouldCollectInfo: true }));
    }



    //Rendering done here

    //Tooltip functionality for Investor
    const [HelperTetxRegulatory, setHelperTetxRegulatory] = useState({
        HelperTetxForInvestor :false,
        HelperTetxForAnotherBrokerDealer:false,
        HelperTetxForRelatedBrokerDealer:false,
        HelperTetxForRisk:false,
        HelperTetxForCompany:false,
        HelperTetxForPolitical:false,
        investmentObjectiveh: false,
})

//=========================================================================================
  //Tooltip functionality for investor
    const toggleToolTipForInvestor = () => {
        setHelperTetxRegulatory({ HelperTetxForInvestor:true })
    }
    const closeToolTipForInvestor = () => {
        setHelperTetxRegulatory({ HelperTetxForInvestor: false })
    }
      //Tooltip functionality for Another broker dealer
    const toggleToolTipForAnotherBrokerDealer = () => {
        setHelperTetxRegulatory({ HelperTetxForAnotherBrokerDealer: true })
    }

    const closeToolTipForAnotherBrokerDealer = () => {
        setHelperTetxRegulatory({ HelperTetxForAnotherBrokerDealer: false })
    }

      //Tooltip functionality for Related to broker dealer
    const toggleToolTipForRelatedBrokerDealer = () => {
        setHelperTetxRegulatory({ HelperTetxForRelatedBrokerDealer: true })
    }

    const closeToolTipForRelatedBrokerDealer = () => {
        setHelperTetxRegulatory({ HelperTetxForRelatedBrokerDealer: false })
    }
      //Tooltip functionality for Risk
    const toggleToolTipForRisk = () => {
        setHelperTetxRegulatory({ HelperTetxForRisk: true })
    }

    const closeToolTipForRisk = () => {
        setHelperTetxRegulatory({ HelperTetxForRisk: false })
    }
    //Tooltip functionality for company
    const toggleToolTipForCompany = () => {
        setHelperTetxRegulatory({ HelperTetxForCompany: true })

    }

    const closeToolTipForCompany = () => {
        setHelperTetxRegulatory({ HelperTetxForCompany: false })
    }

    //Tooltip functionality for Political
    const toggleToolTipForPolitical = () => {
        setHelperTetxRegulatory({ HelperTetxForPolitical: true })

    }
    const closeToolTipForPolitical = () => {
        setHelperTetxRegulatory({ HelperTetxForPolitical: false })
    }
    //investment objective functionality
    const toggleToolTipForInvestmentObjective = () => {
        setHelperTetxRegulatory({ investmentObjectiveh: true })
    }
    const closeToolTipForInvestmentObjective = () => {
        setHelperTetxRegulatory({ investmentObjectiveh: false })
    }    

    return (
        <div>
         
            {isLoading && <WSpinner loading={isLoading} /> }


            {apiError && (
                <ConfirmModal
                    modalTitle={"Error"}
                    modalBodyText={marketNAOInfoReducerData.postStatusMsg}
                    primaryButtonText="OK"
                    onPrimaryClick={handleExit}
                />
                
            )}

            {verifyRiskHider || verifyBrokerHider ? null :
            <div className="banner step-banner">
                <h6 className="white-text center">Step 1 of {isJointAccount ? '2' : '1'}</h6>
                <p className='white-text center'>Please verify and complete your regulatory information</p>
            </div>}
           {verifyRiskHider || verifyBrokerHider  ? null : <div className='madatorytextstyle' >
            <span style={mandatoryFieldlabel}>
                {mandatoryText}
            </span>
            </div>}
            <div>
               { verifyBrokerHider  ? null :   <Accordian
                    accordianPaddingLeft={0}
                    id={investorProfile}
                    titleChild={
                        <AccordianTitle
                            accType={'Investor Profile & Risk'}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                        />
                    }
                    titleSeperator
                >
                    <div className="RegulatoryInfo first-section">
                        <Card>
                            <Card.Body>
                                <img id="InvestorProfile" className='TooltipForInvestorProfileandRiskR' onMouseOut={closeToolTipForInvestor} src={helperIcon} alt="" />
                                <StyledToolTip
                                    id="InvestorProfile_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.HelperTetxForInvestor}
                                    targetID="InvestorProfile"
                                    handleToggle={toggleToolTipForInvestor}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={HelpText_RegulatoryHeading}
                                    closeTooltip={closeToolTipForInvestor}
                                />


                                <FieldValue
                                    as="p"
                                    id="investmentObjective"
                                    label="Investment Objective"
                                    value={
                                        <SelectDropDown
                                            aria-labelledby="selectDropDown-investmentObjective"
                                            id="selectDropDown-investmentObjective"
                                            data-name="Salutation"
                                            itemlist={investmentObjective[0]}
                                            value={ `${state.investmentObjective}`}
                                            className="inputAlign selectRegulatory"
                                            errortext={formErrors.investmentObjective}
                                            onChange={handleChangeDropDown('investmentObjective')}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    alignSelectAccOpt={alignSelectAccOpt}
                                />
                                <img id="investmentObjective_Ind_Tooltip" className='TooltipForInvestmentObjective' onMouseOut={closeToolTipForInvestmentObjective} src={helperIcon} alt="" />
                                <StyledToolTip
                                    id="investmentObjective_Ind_Tooltip_help"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.investmentObjectiveh}
                                    targetID="investmentObjective_Ind_Tooltip"
                                    handleToggle={toggleToolTipForInvestmentObjective}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={investment_Objective}
                                    closeTooltip={closeToolTipForInvestmentObjective}
                                />
                                <FieldValue
                                    as="p"
                                    id="timeHorizon"
                                    label="Time Horizon"
                                    value={
                                        <SelectDropDown
                                            aria-labelledby="timeHorizon"
                                            id="selectDropDown-timeHorizon"
                                            data-name="Salutation"
                                            itemlist={timeHorizon[0]}
                                            value={`${state.timeHorizon}`}
                                            className="inputAlign"
                                            errortext={formErrors.timeHorizon}
                                            onChange={handleChangeDropDown('timeHorizon')}
                                        />
                                    }
                                    labelsm={4}
                                    valuesm={5}
                                    mb={1.5625}
                                    noGutters
                                    alignSelectAccOpt={alignSelectAccOpt}
                                />
                                <Row className="Slabel no-gutters" role="group"   aria-labelledby={assignObj(`riskExposure`)}>
                                    <span
                                        className="mobileAddressLabelCol col-sm-4 "
                                        id={assignObj(`riskExposure`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {riskLabel} 
                                       
                                    </span>
                                    <div>
                                        {riskValueOptions[0]?.map(item => {
                                            return (
                                                <WRadio
                                                    radioFor="riskExposure"
                                                    key={item.key}
                                                    id={item.value}
                                                    value={item.value}
                                                    radiodescstyles={radiodescstyles}
                                                    radiostyles={radiostyles}
                                                    radiotext={radioText}
                                                    selected={state.riskExposure == item.value}
                                                    ariaLabel="None"
                                                    optional="true"
                                                    ariaDescribedBy="riskExposure"
                                                    onClick={radioButtonClick('riskExposure', item)}
                                                />
                                           )
                                        })}
                                        <span className="errorMsgInputStyle">{formErrors.riskExposure}</span>
                                    </div>
                                </Row>
                                <img id="InvestorProfileRisk" className='TooltipForInvestorProfileRisk' onMouseOut={closeToolTipForRisk} src={helperIcon} alt="" />

                                <StyledToolTip
                                    id="InvestorProfileRisk_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.HelperTetxForRisk}
                                    targetID="InvestorProfileRisk"
                                    handleToggle={toggleToolTipForRisk}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={HelpText_InvestorProfileRisk}
                                    closeTooltip={closeToolTipForRisk}
                                />
                                <div>
                                    <FieldValue
                                        as="p"
                                        id="InvestmentKnowledge"
                                        label="Investment Knowledge"
                                        value={
                                            <SelectDropDown
                                                aria-labelledby="InvestmentKnowledge"
                                                id="selectDropDown-InvestmentKnowledge"
                                                data-name="InvestmentKnowledge"
                                                itemlist={generalInvestments[0]}
                                                value={ `${state.investmentKnowledge}`}
                                                errortext={formErrors.investmentKnowledge}
                                                onChange={handleChangeDropDown('investmentKnowledge')}
                                                className="inputAlign"
                                            />
                                        }
                                        labelsm={4}
                                        valuesm={5}
                                        mb={1.5625}
                                        noGutters
                                        alignSelectAccOpt={alignSelectAccOpt}
                                    />
                                </div>

                                <div>
                                    <FieldValue
                                        as="p"
                                        id="investmentGoal"
                                        label="Investment Goal"
                                        value={
                                            <SelectDropDown
                                                aria-labelledby="primaryOwnerPrefixLabel"
                                                id="selectDropDown-primaryOwnerPrefix"
                                                data-name="Salutation"
                                                itemlist={investmentGoal[0]}
                                                value={ `${state.investmentGoal}`}
                                                errortext={formErrors.investmentGoal}
                                                onChange={handleChangeDropDown('investmentGoal')}
                                                className="inputAlign"
                                            />
                                        }
                                        labelsm={4}
                                        valuesm={5}
                                        mb={1.5625}
                                        noGutters
                                        alignSelectAccOpt={alignSelectAccOpt}
                                    />
                                </div>

                                <div>
                                    <FieldValue
                                        as="p"
                                        id="SourceofFunds"
                                        label="Source of Funds"
                                        value={
                                            <SelectDropDown
                                                aria-labelledby="SourceofFunds"
                                                id="selectDropDown-SourceofFunds"
                                                data-name="sourceOFFund"
                                                itemlist={sourceOFFund[0]}
                                                value={`${state.sourceOFFund}`}
                                                errortext={formErrors.sourceOFFund}
                                                onChange={handleChangeDropDown('sourceOFFund')}
                                                className="inputAlign"
                                            />
                                        }
                                        labelsm={4}
                                        valuesm={5}
                                        mb={1.5625}
                                        noGutters
                                        alignSelectAccOpt={alignSelectAccOpt}
                                    />
                                </div>
                
                                {
                                    state.sourceOFFund == '16' && 
                                    <div>
                                         <WInput
                                            label="Source of Funds information"
                                            placeholder={'Source of Funds information'}
                                            className=""
                                            id={`input-sourceOFFundInfo`}
                                            name="sourceOFFundInfo"
                                            type="text"
                                            onChange={onInputChange3(null,'sourceOFFundInfo')}
                                            value={state.sourceOFFundInfo || ''}
                                            labelsm={4}
                                            valuesm={5}
                                            maxlength={50}
                                            required
                                            errortext={formErrors.sourceOFFundInfo}
                                        />
                                    
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Accordian>}

             {verifyRiskHider  ? null :   <Accordian
                    accordianPaddingLeft={0}
                    id={brokerDealer}
                    titleChild={
                        <AccordianTitle
                            accType={'Broker-Dealer & Politically Exposed Person'}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                        />
                    }
                    titleSeperator
                >
                    <div className="RegulatoryInfo second-section">
                        <Card>
                            <Card.Body>
                            <Row className="Slabel" role="group" aria-labelledby={assignObj(`hasOtherAccount`)}>
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`hasOtherAccount`)}
                                        style={styles.labelTextStyle}
                                    >
                                       1. {vcEmployeeLabel}
                                    </span>
                                    <div style={{ flexBasis: '20%',marginLeft:"3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor={vcEmployeeText}
                                                        key={item}
                                                        id={`${vcEmployeeText}-${item}`}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state?.vcEmployee == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy={vcEmployeeText}
                                                        onClick={radioButtonClick(vcEmployeeText, item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors?.vcEmployee}</span>
                                    </div>
                                </Row>
                                <hr/>
                                <Row className="Slabel" role="group" aria-labelledby={assignObj(`hasOtherAccount`)}>
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`hasOtherAccount`)}
                                        style={styles.labelTextStyle}
                                    >
                                       2. {anotherBrokerLabel}
                                    </span>
                                    <div style={{ flexBasis: '20%',marginLeft:"3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="hasOtherAccount"
                                                        key={item}
                                                        id={item}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.brokerDealerEmployment == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="brokerDealerEmployment"
                                                        onClick={radioButtonClick('brokerDealerEmployment', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.brokerDealerEmployment}</span>
                                    </div>
                                </Row>
                                <img id="AnotherBrokerDealer" className='TooltipRegulatory' onMouseOut={closeToolTipForAnotherBrokerDealer} src={helperIcon} alt="" />

                                <StyledToolTip
                                    id="AnotherBrokerDealer_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.HelperTetxForAnotherBrokerDealer}
                                    targetID="AnotherBrokerDealer"
                                    handleToggle={toggleToolTipForAnotherBrokerDealer}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={HelptText_AnotherBrokerDealer}
                                    closeTooltip={closeToolTipForAnotherBrokerDealer}
                                />
                                {state.brokerDealerEmployment == 'Yes' && (<div>
                                    <div className="childboxsection">
                                        <Row className="Slabel" role="group" aria-labelledby={assignObj(`empOfThisBD`)}>
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`employeeOfThisBrokerDealer`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {employeeBDLabel}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="employeeOfThisBrokerDealer"
                                                                key={item + state.employeeOfThisBrokerDealer}
                                                                id={item + state.employeeOfThisBrokerDealer + 'employeeOfThisBrokerDealer'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.employeeOfThisBrokerDealer == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="employeeOfThisBrokerDealer"
                                                                onClick={radioButtonClick('employeeOfThisBrokerDealer', item)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.employeeOfThisBrokerDealer}</span>
                                            </div>
                                        </Row>
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`relatedToEmp`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isRelatedToBrokerDealer`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {relatedToEmployeeLabel}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="isRelatedToBrokerDealer"
                                                                key={item + state.isRelatedToBrokerDealer}
                                                                id={item + state.isRelatedToBrokerDealer + 'isRelatedToBrokerDealer'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.isRelatedToBrokerDealer == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isRelatedToBrokerDealer"
                                                                onClick={radioButtonClick('isRelatedToBrokerDealer', item)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.isRelatedToBrokerDealer}</span>
                                            </div>
                                        </Row>
                                        <img id="RelatedBrokerDealer" className='TooltipRelatedBrokerDealer' onMouseOut={closeToolTipForRelatedBrokerDealer} src={helperIcon} alt="" />

                                        <StyledToolTip
                                            id="RelatedBrokerDealer_Tooltip"
                                            placement="top"
                                            tooltipOpen={HelperTetxRegulatory.HelperTetxForRelatedBrokerDealer}
                                            targetID="RelatedBrokerDealer"
                                            handleToggle={toggleToolTipForRelatedBrokerDealer}
                                            autohide={true}
                                            triggerEvt="hover focus"
                                            tooltipText={HelpText_RelatedBrokerDealer}
                                            closeTooltip={closeToolTipForRelatedBrokerDealer}
                                        />
                                        {state.isRelatedToBrokerDealer == 'Yes' && (
                                            <div className="childboxsection">
                                                <Row>
                                                    <WInput
                                                        label="Employee's First Name"
                                                        arialabel="empFirstName"
                                                        aria-required={false}
                                                        required={false}
                                                        inputclassname=""
                                                        id={assignObj(`empFirstName`)}
                                                        name="empFirstName"
                                                        type="text"
                                                        value={state.relatedToBrokerDealer.firstname}
                                                        labelsm={{span:4,offset:2}}
                                                        valuesm={5}
                                                        maxlength={40}
                                                        errortext={formErrors.relatedToBrokerDealer}
                                                        inputfieldstyle={assignObj({ paddingLeft: '15px' })}                                                        
                                                        sublabel={styles.sublabel}
                                                        onChange={onInputChange3('relatedToBrokerDealer','firstname')}

                                                    />
                                                </Row>
                                                <Row>
                                                    <WInput
                                                        label="Employee's Last Name"
                                                        arialabel="empLastName"
                                                        aria-required
                                                        required
                                                        inputclassname=""
                                                        id={assignObj(`primaryOwnerFirstName`)}
                                                        name="empLastName"
                                                        type="text"
                                                        value={state.relatedToBrokerDealer.lastName}
                                                        labelsm={{span:4,offset:2}}
                                                        valuesm={5}
                                                        maxlength={40}
                                                        errortext={formErrors.relatedToBrokerDealer}
                                                        sublabel={styles.sublabel}
                                                        inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                        onChange={onInputChange3('relatedToBrokerDealer','lastName')}

                                                    />
                                                </Row>
                            
                                                <FieldValue
                                                        as="p"
                                                        id="empRelationship"
                                                        label="Relationship"
                                                        value={
                                                            <SelectDropDown
                                                                aria-labelledby="selectDropDown-relationship"
                                                                id="selectDropDown-relationship"
                                                                data-name="Salutation"
                                                                itemlist={relationshipDropDownData}
                                                                value={`${state.relatedToBrokerDealer.relationship}`}
                                                                className="inputAlign"
                                                                errortext={formErrors.relatedToBrokerDealer}
                                                                onChange={handleChangeDropDown('relatedToBrokerDealer')}
                                                            />
                                                        }
                                                        labelsm={{span:4,offset:2}}
                                                        valuesm={5}
                                                        mb={1.5625}
                                                        noGutters
                                                        alignSelectAccOpt={alignSelectAccOpt}
                                                    />
                
                                            </div>
                                        )}
                                    </div>
                                
                                <div className="childboxsection">
                                <Row className="Slabel" role="group" aria-labelledby={assignObj(`isEmployeeOfAnotherBrokerDealer`)}>
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isEmployeeOfAnotherBrokerDealer`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {EmployeeAnotherBorker}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isEmployeeOfAnotherBrokerDealer"
                                                        key={item + state.isEmployeeOfAnotherBrokerDealer}
                                                        id={item + state.isEmployeeOfAnotherBrokerDealer + 'isEmployeeOfAnotherBrokerDealer'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isEmployeeOfAnotherBrokerDealer == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isEmployeeOfAnotherBrokerDealer"
                                                        onClick={radioButtonClick('isEmployeeOfAnotherBrokerDealer', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isEmployeeOfAnotherBrokerDealer}</span>
                                    </div>
                                </Row>
                                </div>
                                {state.isEmployeeOfAnotherBrokerDealer == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="Please enter Broker-Dealer name."
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`bdName`)}
                                                name="bdName"
                                                type="text"
                                                value={state.EmployeeOfAnotherBrokerDealer.firstname}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.EmployeeOfAnotherBrokerDealer}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                sublabel={styles.sublabel}
                                                onChange={onInputChange3('EmployeeOfAnotherBrokerDealer','firstname')}
                                                
                                            />
                                        </Row>
                                        
                                    </div>
                                )}
                                <div className="childboxsection">
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`relatedToEmpOfOtherBd`)}
                                >
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isRelatedToEmployeeOfAnotherBrokerDealer`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {relatedToEmpAnother}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isRelatedToEmployeeOfAnotherBrokerDealer"
                                                        key={item + state.isRelatedToEmployeeOfAnotherBrokerDealer}
                                                        id={item + state.isRelatedToEmployeeOfAnotherBrokerDealer + 'isRelatedToEmployeeOfAnotherBrokerDealer'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isRelatedToEmployeeOfAnotherBrokerDealer == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isRelatedToEmployeeOfAnotherBrokerDealer"
                                                        onClick={radioButtonClick('isRelatedToEmployeeOfAnotherBrokerDealer', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isRelatedToEmployeeOfAnotherBrokerDealer}</span>
                                    </div>
                                </Row>
                                </div>
                                {state.isRelatedToEmployeeOfAnotherBrokerDealer == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="Employee's First Name"
                                                arialabel="otherBdEmpFirstName"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`otherBdEmpFirstName`)}
                                                name="otherBdEmpFirstName"
                                                type="text"
                                                value={state.relatedToEmployeeOfAnotherBrokerDealer.firstname}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.relatedToEmployeeOfAnotherBrokerDealer}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('relatedToEmployeeOfAnotherBrokerDealer','firstname')}

                                            />
                                        </Row>
                                        <Row>
                                            <WInput
                                                label="Employee's Last Name"
                                                arialabel="otherBdEmpLastName"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`otherBdEmpLastName`)}
                                                name="otherBdEmpLastName"
                                                type="text"
                                                value={state.relatedToEmployeeOfAnotherBrokerDealer.lastName}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.relatedToEmployeeOfAnotherBrokerDealer}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('relatedToEmployeeOfAnotherBrokerDealer','lastName')}

                                            />
                                        </Row>
                                        <FieldValue
                                                as="p"
                                                id="empRelationshipselectDropDown"
                                                label="Relationship to employee"
                                                value={
                                                    <SelectDropDown
                                                        aria-labelledby="selectDropDown-relationshipEmployee"
                                                        id="selectDropDown-relationshipEmployee"
                                                        data-name="Salutation"
                                                        itemlist={relationshipDropDownData}
                                                        value={`${state.relatedToEmployeeOfAnotherBrokerDealer.relationship}`}
                                                        className="inputAlign"
                                                        errortext={formErrors.relatedToEmployeeOfAnotherBrokerDealer}
                                                        onChange={handleChangeDropDown('relatedToEmployeeOfAnotherBrokerDealer')}
                                                    />
                                                }
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                mb={1.5625}
                                                noGutters
                                                alignSelectAccOpt={alignSelectAccOpt}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                               
                                            />
                                        <Row>
                                            <WInput
                                                label="Please enter name of broker-dealer."
                                                arialabel="otherBrName"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`otherBrName`)}
                                                name="otherBrName"
                                                type="text"
                                                value={state.relatedToEmployeeOfAnotherBrokerDealer.brokerDealerName}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.relatedToEmployeeOfAnotherBrokerDealer}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('relatedToEmployeeOfAnotherBrokerDealer','brokerDealerName')}

                                            />
                                        </Row>
                                    </div>
                                )}
                                 <div className="childboxsection">
                                <Row className="Slabel" role="group" aria-labelledby={assignObj(`memberEmployeeOfStockExchangeFINRA`)}>
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`memberEmployeeOfStockExchangeFINRA`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {memRelToStock}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isMemberEmployeeOfStockExchangeFINRA"
                                                        key={state.isMemberEmployeeOfStockExchangeFINRA + item}
                                                        id={state.isMemberEmployeeOfStockExchangeFINRA + item + 'isMemberEmployeeOfStockExchangeFINRA'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isMemberEmployeeOfStockExchangeFINRA == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isMemberEmployeeOfStockExchangeFINRA"
                                                        onClick={radioButtonClick('isMemberEmployeeOfStockExchangeFINRA', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isMemberEmployeeOfStockExchangeFINRA}</span>
                                    </div>
                                </Row>
                                </div>
                                {state.isMemberEmployeeOfStockExchangeFINRA == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="Name of Affiliation"
                                                arialabel="nameOfAffiliation"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`nameOfAffiliation`)}
                                                name="Name of affiliation "
                                                type="text"
                                                value={state.memberEmployeeOfStockExchangeFINRA.nameOfAffiliation}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                errortext={formErrors.memberEmployeeOfStockExchangeFINRA}
                                                onChange={onInputChange3('memberEmployeeOfStockExchangeFINRA','nameOfAffiliation')}
                                                
                                            />
                                        </Row>
                                    </div>
                                )}
                                 <div className="childboxsection">
                                <Row className="Slabel" role="group" aria-labelledby={assignObj(`isOtherBrokerageAccounts`)}>
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isOtherBrokerageAccounts`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {maintainOtherAccount} {regulatoryOptional}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="maintainingOtherBR"
                                                        key={state.isOtherBrokerageAccounts + item}
                                                        id={state.isOtherBrokerageAccounts + item + 'isOtherBrokerageAccounts'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isOtherBrokerageAccounts == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isOtherBrokerageAccounts"
                                                        onClick={radioButtonClick('isOtherBrokerageAccounts', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Row>
                                </div>
                                {state.isOtherBrokerageAccounts == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="Brokerage Name"
                                                arialabel="brokerageName"
                                                optional="true"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`brokerageName`)}
                                                name="Brokerage name "
                                                type="text"
                                                value={state.otherBrokerageAccounts.brokerageName}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={40}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('otherBrokerageAccounts','brokerageName')}

                                            />
                                        </Row>
                                        <Row>
                                            <WInput
                                                label="How many years of investment experience do you have?"
                                                arialabel="yearsOfInvestingExperience"
                                                optional="true"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`yearsOfInvestingExperience`)}
                                                name="yearsOfInvestingExperience"
                                                type="text"
                                                value={state.otherBrokerageAccounts.yearsOfInvestingExperience? inputNumOnly(state.otherBrokerageAccounts.yearsOfInvestingExperience).toString()
                                                        : ''}
                                                labelsm={{span:4,offset:2}}
                                                valuesm={5}
                                                maxlength={3}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('otherBrokerageAccounts','yearsOfInvestingExperience')}
                                                
                                            />
                                        </Row>
                                    </div>
                                )}
                                </div>)}
                                <div style={{ marginTop:`${state.brokerDealerEmployment == 'Yes'? " ":"-35px"}`}}> < hr/></div>
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`isDirectorShareholderOfPubliclyTradedCompany`)}
                                >
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isDirectorShareholderOfPubliclyTradedCompany`)}
                                        style={styles.labelTextStyle}
                                    >
                                       3. {memofdir}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isDirectorShareholderOfPubliclyTradedCompany"
                                                        key={state.isDirectorShareholderOfPubliclyTradedCompany + item}
                                                        id={state.isDirectorShareholderOfPubliclyTradedCompany + item + 'isDirectorShareholderOfPubliclyTradedCompany'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isDirectorShareholderOfPubliclyTradedCompany == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isDirectorShareholderOfPubliclyTradedCompany"
                                                        onClick={radioButtonClick('isDirectorShareholderOfPubliclyTradedCompany', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isDirectorShareholderOfPubliclyTradedCompany}</span>
                                    </div>
                                </Row>
                                <img id="BrokerDealerCompany" className='TooltipRegulatoryForCompany' onMouseOut={closeToolTipForCompany} src={helperIcon} alt="" />

                                <StyledToolTip
                                    id="BrokerDealerCompany_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.HelperTetxForCompany}
                                    targetID="BrokerDealerCompany"
                                    handleToggle={toggleToolTipForCompany}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={HelptText_BrokerDealerCompany}
                                    closeTooltip={closeToolTipForCompany}
                                />
                                {state.isDirectorShareholderOfPubliclyTradedCompany == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="What is the company name?"
                                                arialabel="companyName"
                                                aria-required={false}
                                                required={false}
                                                inputclassname=""
                                                id={assignObj(`directorShareholderOfPublioclyTradedCompanyName`)}
                                                name="Company Name"
                                                type="text"
                                                value={state.directorShareholderOfPubliclyTradedCompany.companyName}
                                                labelsm={{span:4,offset:1}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.directorShareholderOfPubliclyTradedCompany}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('directorShareholderOfPubliclyTradedCompany','companyName')}
                                                
                                            />
                                        </Row>
                                        
                                    </div>
                                )}<hr/>
                                
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`isAccountOpenedForForeignInstitution`)}
                                >
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isAccountOpenedForForeignInstitution`)}
                                        style={styles.labelTextStyle}
                                    >
                                        4. {foreignAccount}
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isAccountOpenedForForeignInstitution"
                                                        key={state.isAccountOpenedForForeignInstitution + item}
                                                        id={state.isAccountOpenedForForeignInstitution + item + 'isAccountOpenedForForeignInstitution'}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isAccountOpenedForForeignInstitution == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isAccountOpenedForForeignInstitution"
                                                        onClick={radioButtonClick('isAccountOpenedForForeignInstitution', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isAccountOpenedForForeignInstitution}</span>
                                    </div>
                                </Row>
                                {state.isAccountOpenedForForeignInstitution == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`isFinancialInstitution`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isFinancialInstitution`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {isThisForeignInst}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="isFinancialInstitution"
                                                                key={state.accountOpenedForForeignInstitution.isFinancialInstitution + item}
                                                                id={state.accountOpenedForForeignInstitution.isFinancialInstitution + item + 'isFinancialInstitution'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.isFinancialInstitution == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isFinancialInstitution"
                                                                onClick={radioButtonClick('isFinancialInstitution', item, 'accountOpenedForForeignInstitution')}

                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>

                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`isPrivateBank`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isPrivateBank`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {'Is this a private banking account ?'}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="isPrivateBank"
                                                                key={state.accountOpenedForForeignInstitution.isPrivateBank + item}
                                                                id={state.accountOpenedForForeignInstitution.isPrivateBank + item + 'isPrivateBank'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.isPrivateBank == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isPrivateBank"
                                                                onClick={radioButtonClick('isPrivateBank', item, 'accountOpenedForForeignInstitution')}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>

                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`isForeignBank`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isForeignBank`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {foreignbank}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="isForeignBank"
                                                                key={state.accountOpenedForForeignInstitution.isForeignBank + item}
                                                                id={state.accountOpenedForForeignInstitution.isForeignBank + item + 'isForeignBank'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.isForeignBank == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isForeignBank"
                                                                onClick={radioButtonClick('isForeignBank', item)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>

                                        { state.accountOpenedForForeignInstitution.isForeignBank == 'Yes' &&
                                        <>
                                         <div className=" childboxsection subchild">
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`centralBank`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`centralBank`)}
                                                style={styles.labelTextStyle}
                                            >
                                                    {CentralBank}
                                            </span>
                                                <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="centralBank"
                                                                key={state.accountOpenedForForeignInstitution.foreignBank.isCentralBank + item}
                                                                id={state.accountOpenedForForeignInstitution.foreignBank.isCentralBank + item + 'isCentralBank'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.foreignBank.isCentralBank == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isCentralBank"
                                                                onClick={radioButtonClick('isCentralBank', item)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`isBankingLicense`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isBankingLicense`)}
                                                style={styles.labelTextStyle}
                                            >
                                                    {Offshorebanking}
                                            </span>
                                                <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="isBankingLicense"
                                                                key={state.accountOpenedForForeignInstitution.foreignBank.isBankingLicense + item}
                                                                id={state.accountOpenedForForeignInstitution.foreignBank.isBankingLicense + item + 'isBankingLicense'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.foreignBank.isBankingLicense == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isBankingLicense"
                                                                onClick={radioButtonClick(
                                                                    'isBankingLicense',
                                                                    item                                                                )}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`isNonCooperativeCountry`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`isNonCooperativeCountry`)}
                                                style={styles.labelTextStyle}
                                            >
                                                    {Noncooperativecountry}
                                            </span>
                                            <div style={{ flexBasis: '20%', marginLeft: "3%"}}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="territory"
                                                                key={state.territory + item}
                                                                id={state.territory + item + 'territory'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.foreignBank.isTerritory == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="territory"
                                                                onClick={radioButtonClick(
                                                                    'isTerritory',
                                                                    item
                                                                )}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>
                                        <Row
                                            className="Slabel"
                                            role="group"
                                            aria-labelledby={assignObj(`subjectToSection311`)}
                                        >
                                            <span
                                                className="label1 alignRadioBtn"
                                                id={assignObj(`subjectToSection311`)}
                                                style={styles.labelTextStyle}
                                            >
                                                    {section311}
                                            </span>
                                                <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                                <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                                    {radioOptions.map(item => {
                                                        return (
                                                            <WRadio
                                                                radioFor="subjectToSection311"
                                                                key={state.accountOpenedForForeignInstitution.foreignBank.isSubjectToSection311 + item}
                                                                id={state.accountOpenedForForeignInstitution.foreignBank.isSubjectToSection311 + item + 'isSubjectToSection311'}
                                                                value={item}
                                                                radiodescstyles={radiodescstyles}
                                                                radiostyles={radiostyles}
                                                                radiotext={radioText}
                                                                selected={state.accountOpenedForForeignInstitution.foreignBank.isSubjectToSection311 == item}
                                                                ariaLabel="None"
                                                                ariaDescribedBy="isSubjectToSection311"
                                                                onClick={radioButtonClick('isSubjectToSection311', item)}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <span className="errorMsgInputStyle">{formErrors.accountOpenedForForeignInstitution}</span>
                                            </div>
                                        </Row>
                                        </div>
                                        </>
                                        }
                                    </div>
                                )}<hr/>
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`isPep`)}
                                >
                                    <span
                                        className="label1 alignRadioBtn"
                                        id={assignObj(`isPep`)}
                                        style={styles.labelTextStyle}
                                    >
                                       5. {PoliticallyExposedPerson} 
                                    </span>
                                    <div style={{ flexBasis: '20%', marginLeft: "3%" }}>
                                        <div className="d-flex flex-column flex-md-row justify-content-between radioBtnSpace">
                                            {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="isPep"
                                                        key={`${item}-isPep`}
                                                        id={`${item}-isPep`}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.isPep == item}
                                                        ariaLabel="None"
                                                        ariaDescribedBy="isPep"
                                                        onClick={radioButtonClick('isPep', item)}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <span className="errorMsgInputStyle">{formErrors.isPep}</span>
                                    </div>
                                </Row>
                                <img id="BrokerDealerPolitical" className='TooltipRegulatoryForPolitical' onMouseOut={closeToolTipForPolitical} src={helperIcon} alt="" />

                                <StyledToolTip
                                    id="BrokerDealerPolitical_Tooltip"
                                    placement="top"
                                    tooltipOpen={HelperTetxRegulatory.HelperTetxForPolitical}
                                    targetID="BrokerDealerPolitical"
                                    handleToggle={toggleToolTipForPolitical}
                                    autohide={true}
                                    triggerEvt="hover focus"
                                    tooltipText={HelptText_BrokerDealerPolitical}
                                    closeTooltip={closeToolTipForPolitical}
                                />
                                {state.isPep == 'Yes' && (
                                    <div className="childboxsection">
                                        <Row>
                                            <WInput
                                                label="First Name"
                                                arialabel="politicalFirstName"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`politicalFirstName`)}
                                                name="First Name"
                                                type="text"
                                                value={state.pep.firstName}
                                                labelsm={{span:4,offset:1}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.pep}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('pep','firstName')}
                                            />
                                        </Row>
                                        <Row>
                                            <WInput
                                                label="Last Name"
                                                arialabel="politicalLastName"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`politicalLastName`)}
                                                name="Last Name"
                                                type="text"
                                                value={state.pep.lastName}
                                                labelsm={{span:4,offset:1}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.pep}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('pep','lastName')}

                                            />
                                        </Row>
                                        <Row>
                                            <WInput
                                                label="Office Held"
                                                arialabel="politicalOfficeHeld"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`politicalOfficeHeld`)}
                                                name="Office Held"
                                                type="text"
                                                value={state.pep.officeHeld}
                                                labelsm={{span:4,offset:1}}
                                                valuesm={5}
                                                maxlength={40}
                                                errortext={formErrors.pep}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange3('pep','officeHeld')}
                                               
                                            />
                                           
                                        </Row>
                                     
                                        <FieldValue
                                            as="p"
                                            id="politicalCountryOfOffice"
                                            label="Country of Office"
                                            value={
                                                <SelectDropDown
                                                    aria-labelledby="primaryOwnerPrefixLabel"
                                                    id="selectDropDown-primaryOwnerPrefix"
                                                    data-name="politicalCountryOfOffice"
                                                    itemlist={politicalCountryOfOffice[0]}
                                                    value={`${state.pep.countryOfOffice}`}
                                                    errortext={formErrors.pep}
                                                    onChange={handleChangeDropDown('countryOfOffice')}
                                                    className="inputAlign"
                                                />
                                            }
                                            labelsm={{span:4,offset:1}}
                                            valuesm={5}
                                            mb={1.5625}
                                            noGutters
                                            alignSelectAccOpt={alignSelectAccOpt}
                                        />
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </Accordian>}
            </div>

            {verifyRiskHider || verifyBrokerHider  ? <CommonButtons backClick={clickCancel} backText="Cancel"  nextClick={clickSave} nextText="Save" isShowBtn={true} disabled={false} /> :
            <div className="footer">
                <CommonButtons backClick={props.onBackClick} nextClick={clickNext} nextText="Next"  isShowBtn={true} disabled={false} />
            </div>}
        </div>
    );
}
