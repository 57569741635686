import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import * as WireTransferConstants from '../Constants';

const WireTransferDiv = styled.div`
    padding: 41px 35px 41px 40px;
    margin-bottom: 54px;
    border: 2px solid rgba(139, 193, 5, 1);
    position: relative;
    font: 600 16px/22px 'benton-sans', sans-serif;
    color: #49494a;
    opacity: 1;
`;

const LeftCardCornerStyle = styled.div`
    width: 0;
    height: 0;
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8bc105;
    position: absolute;
    top: -2px;
    left: -2px;
`;

const SectionHeaderRow = styled(Row)`
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 30px;
`;

const SectionHeadingH3 = styled.h3`
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    margin-bottom: 15px;
    color: rgba(72, 109, 144, 1);
`;

const BoldParaP = styled.p`
    font-weight: 600;
`;

const RegularPara = styled.p`
    font-weight: 400;
`;

const MailingAddressP = styled.p`
    font-weight: 400;
    white-space: pre-line;
`;

const StyledRow = styled(Row)`
    margin-top: 10px;
`;

const {
    WIRE_TRANSFER_MSG1,
    WIRE_TRANSFER_MSG3,
    WIRE_TRANSFER_MAILING_ADDRESS_TEXT,
    HEADING_TEXT,
    USAA_WIRE_TRANSFER_INSTRUCTIONS_HEADING,
    DOMESTIC_TEXT,
    ABA_NUMBER,
    ATTENTION_TEXT,
    USAA_FUND_NAME_TEXT,
    ACCOUNT_NAME_TEXT,
    USAA_ACCOUNT_NUMBER_TEXT,
    BENEFIT_OF_TEXT,
    NAME_TEXT,
    USAA_MUTUAL_FUND_ACCOUNT_NUMBER,
    ABA_TEXT,
    USAA_ACCOUNT_NAME,
    USAA_ACCOUNT_NUMBER,
} = WireTransferConstants;

const WireTransferComponent = props => {
    const { heading, fundAccountNumber, fundName, firstName, lastName } = props;
    const fullName = `${firstName} ${lastName}`;

    return (
        <WireTransferDiv>
            <LeftCardCornerStyle />
            <SectionHeaderRow>
                <Col xs={12}>
                    <SectionHeadingH3>{heading}</SectionHeadingH3>
                </Col>
            </SectionHeaderRow>
            <Row>
                <RegularPara>{WIRE_TRANSFER_MSG1}</RegularPara>
            </Row>
            <StyledRow>
                <BoldParaP>{USAA_WIRE_TRANSFER_INSTRUCTIONS_HEADING}</BoldParaP>
            </StyledRow>
            <Row>
                <BoldParaP>{DOMESTIC_TEXT}</BoldParaP>
            </Row>
            <Row>
                <MailingAddressP>{WIRE_TRANSFER_MAILING_ADDRESS_TEXT}</MailingAddressP>
            </Row>
            <Row>
                <BoldParaP>{ABA_TEXT}</BoldParaP> &nbsp;
                <RegularPara>{ABA_NUMBER}</RegularPara>
            </Row>
            <StyledRow>
                <BoldParaP>{ATTENTION_TEXT}</BoldParaP>
            </StyledRow>
            <Row>
                <BoldParaP>{USAA_FUND_NAME_TEXT}</BoldParaP> &nbsp;
                <RegularPara>{fundName}</RegularPara>
            </Row>
            <Row>
                <BoldParaP>{ACCOUNT_NAME_TEXT} </BoldParaP> &nbsp;
                <RegularPara>{USAA_ACCOUNT_NAME}</RegularPara>
            </Row>
            <Row>
                <BoldParaP>{USAA_ACCOUNT_NUMBER_TEXT}</BoldParaP> &nbsp;
                <RegularPara>{USAA_ACCOUNT_NUMBER}</RegularPara>
            </Row>
            <StyledRow>
                <BoldParaP>{BENEFIT_OF_TEXT}</BoldParaP>
            </StyledRow>
            <Row>
                <BoldParaP>{NAME_TEXT}</BoldParaP> &nbsp;
                <RegularPara>{fullName} </RegularPara>
            </Row>
            <Row>
                <BoldParaP>{USAA_MUTUAL_FUND_ACCOUNT_NUMBER}</BoldParaP> &nbsp;
                <RegularPara>{fundAccountNumber}</RegularPara>
            </Row>
            <StyledRow>
                <RegularPara>{WIRE_TRANSFER_MSG3}</RegularPara>
            </StyledRow>
        </WireTransferDiv>
    );
};

WireTransferComponent.propTypes = {
    heading: PropTypes.string,
    fundAccountNumber: PropTypes.number,
    fundName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

WireTransferComponent.defaultProps = {
    heading: HEADING_TEXT,
    fundAccountNumber: 0,
    fundName: '',
    firstName: '',
    lastName: '',
};

export default WireTransferComponent;
