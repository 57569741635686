const Styles = {
    heading: {
        color: 'rgb(72, 109, 144)',
        font: '800 1.375rem / 1.5rem yorkten-slab-normal, sans-serif',
        fontWeight: 'bold',
        borderBottom: '2px solid #28a745',
        backgroundColor: '#fff',
        padding: '.75rem 0',
    },

    cardContainer: {
        border: 'none',
        marginRight: 0,
        marginLeft: 0,
    },

    wh: {
        width: '16px',
    },
};

export default Styles;
