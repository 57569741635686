import * as AnalyticsActions from '../../shared/Actions/AnalyticsActions';
import CancelIcon from '../../assets/btn_cancel_red.png';
import { connect } from 'react-redux';
import { focusToNonFocusableEl } from 'modules/CustomerManagementModule/utils';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import StyledButton from '../StyledComponents';
import styled from 'styled-components';
import '../ConformationModal/ConformationModal.css';
import WarningConstant from './constants';

const ModalStyle = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    overflow-y: auto;
    @supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
`;
const ModalDialog = styled.div`
    left: 16%;
    top: 18%;
    z-index: 999;
    width: 67%;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
`;
const CancelButtonStyle = styled.span`
    font: 800 1rem/1.25rem yorkten-slab-normal, benton-sans;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;
const ConformationModalTitle = styled.h2`
    text-align: left;
    font: 800 1.875rem/2.25rem yorkten-slab-normal, benton-sans;
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
    opacity: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(140, 191, 38);
    width: fit-content;
    margin-bottom: 28px;
    &:before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #8bc105;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 1rem/1.5rem benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 10px;
`;

const ConformationModalBodyHeader = styled.div`
    text-align: left;
    font: 700 1rem/1.1875rem benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 30px;
`;

const ContainerDiv = styled.div`
    padding: 50px 30px 0px 40px;
    @media (max-width: 768px) {
        padding: 0px;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    background: 0% 0% no-repeat padding-box padding-box rgb(250, 250, 250);
    border-top: 1px solid rgb(239, 237, 237);
    padding-bottom: 20px;
    padding-top: 20px;
    @media (max-width: 768px) {
        padding-bottom: 0px;
    }
`;
const CancelSection = styled(StyledButton)`
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 154px;
    height: 42px;
    align-self: center;
    justify-content: flex-end;
    &:focus {
        border: 1px solid #000000;
    }
`;
const CancelImage = styled.img`
    margin-left: 15px;
    margin-right: 10px;
`;

const StyledA = styled.a`
    color: #004a98;
    text-decoration: underline;
    font-weight: 700;
`;

const PrimaryButton = styled(StyledButton)`
    background: #486d90 0% 0% no-repeat border-box;
    min-width: 160px;
    min-height: 50px;
    position: relative;
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    &:hover {
        background: #004a98 0% 0% no-repeat border-box;
    }
`;

const BtnCorner = styled.span`
    width: 0px;
    height: 0px;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #8cbf26;
    position: absolute;
    top: 0px;
    right: 0px;
    ${StyledButton}:hover & {
        borderright: 20px solid #8bc105;
    }
`;

const ESCAPE_KEY_OR_CODE = 'Escape';
const TAB_KEY_OR_CODE = 'Tab';

const onDialogShow = ({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId }) => {
    document.documentElement.style.overflow = 'hidden';
    // window.scrollTo(0, 0);
    const prevFocusedEl = previousFocus;
    const preNode = preNodeRef;
    const postNode = postNodeRef;
    prevFocusedEl.current = document.activeElement;
    preNode.current.tabIndex = 0;
    postNode.current.tabIndex = 0;
    // set focus on first input
    /// focusToNonFocusableEl(titleRef.current);
    // moveFocusToTitle(titleRef)
    titleRef.current.style.outline = '2px solid #000';
    titleRef.current.focus();
    setTimeout(() => {
        [].slice.call(document.body.children).forEach(node => {
            if (node.getAttribute('id') !== `vcm-dialog-container-${keyId}`) {
                node.setAttribute('inert', 'true');
            }
        });
    }, 100);
};

const onDialogHide = ({ previousFocus }) => {
    const isOverflowHidden = document.documentElement.style.overflow === 'hidden';
    if (isOverflowHidden) document.documentElement.style.overflow = '';
    [].slice.call(document.body.children).forEach(node => {
        const el = node;
        const isHidden = el.hasAttribute('inert');
        if (isHidden) {
            el.inert = false;
        }
    });
    const prevFocusedEl = previousFocus;
    if (prevFocusedEl && prevFocusedEl.current) {
        prevFocusedEl.current.focus();
        prevFocusedEl.current = null;
    }
};

// Handling onclosing dialog
const onClose = onSecondaryClick => e => {
    // console.log("onClose")
    onSecondaryClick(e);
};

// redirect last tab to first input
const onTitleRefKeyDown = (titleRef, nextRef, fun) => e => {
    // console.log("onTitleRefKeyDown")
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && !e.shiftKey && titleRef && nextRef) {
        e.preventDefault();
        titleRef.current.style.outline = 'none';
        nextRef.current.focus();
    } else if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) {
        fun(e);
    }
};

// redirect last tab to first input
const onLastElementTab = (firstElRef, onSecondaryClick) => e => {
    // console.log("onLastElementTab")
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose(onSecondaryClick)(e);
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && !e.shiftKey && firstElRef) {
        e.preventDefault();
        firstElRef.current.focus();
    }
};
// redirect first shift+tab to last input
const onFirstShiftTab = (lastElRef, onSecondaryClick) => e => {
    //  console.log("onFirstShiftTab")
    if (e.key === ESCAPE_KEY_OR_CODE || e.code === ESCAPE_KEY_OR_CODE) onClose(onSecondaryClick)(e);
    if ((e.key === TAB_KEY_OR_CODE || e.code === TAB_KEY_OR_CODE) && e.shiftKey && lastElRef) {
        e.preventDefault();
        lastElRef.current.focus();
    }
};

const moveFocusToTitle = ({ titleRef }) => () => {
    // set focus on first input
    focusToNonFocusableEl(titleRef.current);
    // console.log("moveFocusToTitle")
    // titleRef.current.style.outline = '2px solid #000';
    // titleRef.current.focus();
};

const onPrimaryBtnClick = ({ previousFocus, onPrimaryClick }) => e => {
    //  console.log("onPrimaryBtnClick")
    const prevFocusedEl = previousFocus;
    prevFocusedEl.current = null;
    onDialogHide({ previousFocus });
    setTimeout(() => {
        onPrimaryClick(e);
    }, 100);
};



const FeeGatesWarningModal = props => {
    const titleRef = useRef(null);
    const firstElRef = useRef(null);
    const lastElRef = useRef(null);
    const preNodeRef = useRef(null);
    const postNodeRef = useRef(null);
    const previousFocus = useRef(null);
    const {
        isFee,
        feePercentValue,
        fundName,
        isLiquidate,
        dataAnalyticId,
        keyId,
        show,
        onPrimaryClick,
        onSecondaryClick,
    } = props;

    const {
        gateHeader,
        yesText,
        noText,
        okText,
        feeHeader,
        para1,
        para2,
        para2a,
        para2b,
        para2c,
        para3,
        para3a,
        para4,
        para5,
        para6,
        para7,
        para8,
        para9,
        perecentSymbol,
    } = WarningConstant;

    const liquidationPara = isLiquidate
        ? `${para2a}${para2c}${feePercentValue}${perecentSymbol} ${para3}${para3a}`
        : `${para2b}${para2c}${feePercentValue}${perecentSymbol} ${para3}`;
    //const liquidationPara=isLiquidate?`${para2a}${para2c}${feePercentValue}${perecentSymbol} ${para3}${para3a}`:`${para2b}${para2c}${feePercentValue}${perecentSymbol} ${para3}${para3a}`
    const feeParagraph = `${para1} ${fundName} ${para2}${liquidationPara}`;
    const gateParagraph = `${para1} ${fundName} ${para8}`;

    useEffect(() => {
        if (show) {
            onDialogShow({ previousFocus, preNodeRef, postNodeRef, titleRef, keyId });
        }
    }, [show, keyId, titleRef]);

    // to handle scenarios like session timeout
    useEffect(() => {
        return () => {
            onDialogHide({ previousFocus });
        };
    }, []);

    if (!show) {
        onDialogHide({ previousFocus });
        // titleRef.current.style.outline = 'none';
    }

    return ReactDOM.createPortal(
        <ModalStyle id={`vcm-dialog-container-${keyId}`} onClick={moveFocusToTitle({ titleRef })} show={show}>
            <div ref={preNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
            <ModalDialog
                id={`vcm-alert-dialog-${keyId}`}
                role="dialog"
                className="modalDialog"
                aria-modal="true"
                aria-labelledby={`vcm-modal-title-${keyId}`}
                aria-describedby={`vcm-modal-title-desc-${keyId}`}
            >
                <ContainerDiv>
                    <ConformationModalTitle id={`vcm-modal-title-${keyId}`}>
                        {isFee ? feeHeader : gateHeader}{' '}
                    </ConformationModalTitle>
                    <div
                        id={`vcm-modal-title-desc-${keyId}`}
                        ref={titleRef}
                        onKeyDown={
                            isFee
                                ? onTitleRefKeyDown(titleRef, firstElRef, onSecondaryClick)
                                : onTitleRefKeyDown(titleRef, lastElRef, onPrimaryClick)
                        }
                    >
                        <ConformationModalBodyHeader>{fundName}</ConformationModalBodyHeader>
                        <ConformationModalBody> {isFee ? feeParagraph : gateParagraph} </ConformationModalBody>
                        <ConformationModalBody>
                            {para4}
                            <StyledA tabindex="0" href="#">
                                {' '}
                                {para5}{' '}
                            </StyledA>
                            {para6}
                        </ConformationModalBody>
                        {(isFee || (!isFee && !isLiquidate)) && (
                            <ConformationModalBody> {para7} </ConformationModalBody>
                        )}
                        {!isFee && isLiquidate && <ConformationModalBody> {para9} </ConformationModalBody>}
                    </div>
                </ContainerDiv>
                <FlexDiv className="feeGatesWarningDiv" style={{ paddingRight: '30px' }}>
                    {(isFee || (!isFee && !isLiquidate)) && (
                        <CancelSection
                            id={`vcm-modal-secondary-btn-${keyId}`}
                            ref={firstElRef}
                            onClick={onClose(onSecondaryClick)}
                            onKeyDown={onFirstShiftTab(lastElRef, onSecondaryClick)}
                            data-analytic-id={dataAnalyticId ? `${dataAnalyticId}_cancel` : `confirmation-modal-cancel`}
                        >
                            <CancelImage src={CancelIcon} alt="" />
                            <CancelButtonStyle>{noText}</CancelButtonStyle>
                        </CancelSection>
                    )}

                    <PrimaryButton
                        ref={lastElRef}
                        id={`vcm-modal-primary-btn-${keyId}`}
                        onClick={onPrimaryBtnClick({ previousFocus, onPrimaryClick })}
                        onKeyDown={
                            isFee
                                ? onLastElementTab(firstElRef, onSecondaryClick)
                                : onLastElementTab(lastElRef, onPrimaryClick)
                        }
                        dataAnalyticId={dataAnalyticId ? `${dataAnalyticId}_confirm` : `confirmation-modal-confirm`}
                    >
                        {(isFee || (!isFee && !isLiquidate)) && yesText}
                        {!isFee && isLiquidate && okText}
                        <BtnCorner />
                    </PrimaryButton>
                </FlexDiv>
            </ModalDialog>
            <div ref={postNodeRef} onFocus={moveFocusToTitle({ titleRef })} />
        </ModalStyle>,
        document.body,
    );
};

FeeGatesWarningModal.propTypes = {
    fundName: PropTypes.string,
    isFee: PropTypes.bool,
    isLiquidate: PropTypes.bool,
    feePercentValue: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    show: PropTypes.bool,
    dataAnalyticId: PropTypes.string,
    analyzeClickEvent: PropTypes.func,
    keyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FeeGatesWarningModal.defaultProps = {
    fundName: 'USAA Money Market Fund',
    isFee: true,
    isLiquidate: false,
    feePercentValue: '2%',
    show: false,
    onPrimaryClick: () => {},
    onSecondaryClick: () => {},
    analyzeClickEvent: () => {},
    dataAnalyticId: '',
    keyId: 0,
};

const mapDispatchToProps = {
    ...AnalyticsActions,
};

export default connect(null, mapDispatchToProps)(FeeGatesWarningModal);
