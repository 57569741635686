import arrowDown from '../../assets/redArrowDown.png';
import { Card, ToggleButton } from 'react-bootstrap';
import greenArrowUp from 'assets/greenArrowUp.svg';
import highRisk from '../../assets/highRisk.svg';
import IconMessageTag from 'assets/Icon_MessageTag.svg';
import mediumRisk from '../../assets/mediumRisk.svg';
import {
    PERCENTAGE_SYMBOL,
    MONEY_MARKET,
    // INCREASE,
    // DECREASE,
    INCREASE_IN_PERCENTAGE_TEXT,
    DECREASE_IN_PERCENTAGE_TEXT,
    DOLLAR,
    OPEN_PARENTHESES,
    CLOSE_PARENTHESES,
} from '../Constants';
import PropTypes from 'prop-types';
// import { faAngleUp,
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { ReactComponent as TickMark } from 'assets/blueTick.svg';
import riskLevel1 from '../../assets/Level1.png';
import riskLevel2 from '../../assets/Level2.png';
import riskLevel3 from '../../assets/Level3.png';
import riskLevel4 from '../../assets/Level4.png';
import riskLevel5 from '../../assets/Level5.png';
import riskLevel6 from '../../assets/Level6.png';
import riskLevel7 from '../../assets/Level7.png';
import rwdGreenArrowUp from 'assets/rwd-green-up-arrow.svg';
import rwdRedArrowDown from 'assets/rwd-red-down-arrow.svg';
import styled from 'styled-components';
import styles from '../CommonStyles';
import WIcon from '../WIcon/WIcon';

const colorStyle = '2px solid #004A98';

const RwdTickMark = styled(TickMark)`
    @media (max-width: 767px) {
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
    }
    @media (min-width: 768px) {
        display: none;
    }
`;

const DesktopTickMark = styled(TickMark)`
    @media (max-width: 767px) {
        display: none;
    }
`;

const DesktopWIcon = styled(WIcon)`
    @media (max-width: 767px) {
        display: none;
    }
`;

const RwdWIcon = styled(WIcon)`
    @media (min-width: 768px) {
        display: none;
    }
`;

const StyledChangeInNavDiv = styled.div`
    @media (max-width: 767px) {
        margin-left: 10px;
    }
`;

const AccountCardFlex = styled.div`
    @media (max-width: 767px) {
        display: block;
    }
    display: flex;
`;
const AccountCardTopSection = styled(AccountCardFlex)`
    justify-content: space-between;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
    opacity: 1;
`;
const AccountCardBottomColumn = styled(AccountCardFlex)`
    @media (max-width: 767px) {
        border: none;
        padding: 10px 0;
    }
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 300px;
    padding: 0 15px;
    border-left: 1px solid #d2d2d2;
    &:first-child {
        padding-left: 0;
        border: none;
    }
    &:last-child {
        padding-right: 0;
        padding-bottom: 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 180px;
    }
`;
const AccountCardHeadingSpan = styled.span`
    text-align: left;
    font: 600 16px/22px benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 8px;
    .riskValue {
        font: 500 14px/22px benton-sans;
        margin-left: 20px;
        @media (max-width: 767px) {
            margin-left: 10px;
        }
    }
    @media (max-width: 767px) {
        display: inline-block;
    }
    .riskChart {
        @media (max-width: 767px) {
            height: 20px;
        }
    }
`;
const AccountCardValueSpan = styled.span`
    text-align: right;
    font: 14px/17px benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    display: flex;
    &.iconCol {
        padding-left: 20px;
        position: relative;
    }
    @media (max-width: 767px) {
        font: 16px/20px benton-sans;
        margin-bottom: 5px;
    }
    .riskValue {
        @media (max-width: 767px) {
            font: 500 12px/14px benton-sans;
            margin-left: 10px !important;
        }
    }
`;
const RiskAccountCardValueSpan = styled(AccountCardValueSpan)`
    @media (max-width: 767px) {
        display: inline;
    }
`;

const StyledCard = styled.div.attrs(({ selected }) => ({}))`
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 1.875rem;
    border: 0;
    outline: ${props => (props.selected ? colorStyle : '1px solid #D2D2D2')};
    && {
        background: ${props => (props.selected ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
        box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
        border-radius: 0;
    }
    opacity: 1;
    &:hover {
        outline: ${props => (props.selected ? colorStyle : '1px solid #3c5a77')};
    }
    &:focus {
        outline: ${props => (props.selected ? colorStyle : '5px auto -webkit-focus-ring-color')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')};
        }
    }
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 1.25rem 2.25rem;
        @media (max-width: 767px) {
            padding: 1.5rem 1.25rem;
        }
    }
`;

const StyledFundName = styled.button.attrs(({ Id }) => ({ 'aria-describedby': Id }))`
    @media (max-width: 767px) {
        text-align: left;
    }
    font: 800 18px/45px yorkten-slab-normal;
    margin-bottom: 25px;
    line-height: 2;
    // color: #004a98;
    cursor: pointer;
    border: 0;
    background: none;
`;
const StyledCardRow = styled(AccountCardFlex).attrs(({ Id }) => ({ id: Id }))`
    @media (max-width: 767px) {
        padding-top: 5px;
    }
    position: relative;
    padding-top: 29px;
    &::after {
        @media (max-width: 767px) {
            width: calc(100% + 2rem);
            left: -1rem;
        }
        content: '';
        width: calc(100% + 76px);
        height: 0;
        border-top: 1px solid #d2d2d2;
        position: absolute;
        top: 0;
        left: -38px;
    }
`;

const StyledSpanExistingFund = styled.div`
    @media (max-width: 767px) {
        display: block;
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
        padding-top: 0;
    }
    display: inline;
    font: 400 12px/20px benton-sans;
    margin-left: 20px;
    padding: 7px;
    color: #49494a;
`;
// const CardSymbol = styled.div`
//     width: 35px;
//     height: 35px;
//     border: 1px solid #BBB3B3;
//     opacity: 1;
//     text-align: center;
//     font-size: 35px;
//     color: #BBB3B3;
//     margin-right: 10px;
//     font-weight: normal;
// `;

const boldText = {
    color: '#56565a',
    fontWeight: 'bold',
};
// const  getFundData = () => {
//     console.log('inside get fund data');
// }
// const switchClass = [styles.slider];
const applyDecimal = val => Number(val).toFixed(2);

// const HRDiv = styled.div`
//     border: 1px solid #000000;
//     margin-right: 10px;
//     width: 5px;
//     margin-top: 7px;
//     height: 1px;
// `;

const ImageTagDiv = styled.img`
    width: 14px;
    height: 14px;
    margin: 5px;
`;

const ShowHideButtons = styled.a`
    font: 600 14px/16px benton-sans;
    letter-spacing: 0;
    border: none;
    cursor: pointer;
    color: #004a98 !important;
    background: transparent;
    padding: 0;
    float: center;
    @media (max-width: 767px) {
        margin-bottom: 1.25rem;
    }
`;
const CustomDiv = styled.div`
    > label > input[type='radio']{
        height: 185px;
        position: absolute;
        width: 100%;
        left:0;
        top:0.5px;
        z-index: -1;
    }   
    label  button{
        margin: 0;
        padding: 0;
    }
    label{
        padding: 0;
        margin: 0;
        @media (max-width: 767px){
        text-align: left;
        }
    }
 
}`;

const addCommasToNumberStr = (value = '') => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const assignObj = obj => {
    return obj;
};

const getTextColor = nav => {
    if (nav === 0) {
        return assignObj({ color: '#4949A' });
    }
    if (nav > 0) {
        return assignObj({ color: '#0D823C' });
    }
    return assignObj({ color: '#E60A17' });
};

const TransactionInvestAccountCardComponent = props => {
    // const { accountName, autoInvesting, nav, lastNav, weekValues, selected, onSwitchClick, fundNumber, onClick } = props;
    // const { text: minMaxTxt, value: minMaxVal } = autoInvesting;
    // const { text: NavTxt, value: NavVal } = nav;
    // const { text: lastNavTxt, value: lastNavVal } = lastNav;
    // const { text: weekMinMaxTxt, value: weekMinMaxVal } = weekValues;

    const {
        accountName,
        fundNumber,
        nav,
        lastNav,
        weekValuesMin,
        weekValuesMax,
        fundAutoInvstTxt,
        fundNavTxt,
        fundLastNavTxt,
        fundMinMaxTxt,
        minInvst,
        maxInvst,
        monthlyInvst,
        selected,
        onClick,
        riskVal,
        fundRiskTxt,
        id,
        existingFund,
        isRewardShares,
        fundName,
        handleFundSummary,
        minimumTxt,
        changeInNavPercentage,
        transactionType,
        navChangeTxt,
        //  minMaxTxtExchange,
    } = props;

    const textColor = getTextColor(+nav);
    // console.log('@debug for selected ',selected);
    // const getAutoInvstFund = '$ '+ applyDecimal(minInvst) +' / $ '+ applyDecimal(maxInvst)+' / $ '+ applyDecimal(monthlyInvst)+' monthly';
    const minimumInvestment = `$${addCommasToNumberStr(Math.trunc(minInvst))}`;
    const getAutoInvstFund = `$${addCommasToNumberStr(Math.trunc(maxInvst))} with $${addCommasToNumberStr(
        Math.trunc(monthlyInvst),
    )} monthly`;

    const isWeekValuesMinAvailable = weekValuesMin !== '-';
    const isWeekValuesMaxAvailable = weekValuesMax !== '-';

    const getFundMinMaxwkVal = `${isWeekValuesMinAvailable ? '$' : ''}${
        isWeekValuesMinAvailable ? applyDecimal(weekValuesMin) : weekValuesMin
    } - ${isWeekValuesMaxAvailable ? '$' : ''}${
        isWeekValuesMaxAvailable ? applyDecimal(weekValuesMax) : weekValuesMax
    }`;

    const getImgSrc = risk => {
        let src = '';
        switch (risk) {
            case 'Low':
                src = mediumRisk;
                break;
            case 'Medium':
                src = mediumRisk;
                break;
            case 'High':
                src = highRisk;
                break;
            case 'Preservation of Capital':
                src = riskLevel1;
                break;
            case 'Conservative':
                src = riskLevel2;
                break;
            case 'Moderately Conservative':
                src = riskLevel3;
                break;
            case 'Moderate':
                src = riskLevel4;
                break;
            case 'Moderately Aggressive':
                src = riskLevel5;
                break;
            case 'Aggressive':
                src = riskLevel6;
                break;
            case 'Very Aggressive':
                src = riskLevel7;
                break;
            default:
                src = '';
        }
        return src;
    };
    // const label = `Fund Name ${fundName}`;
    const fundLastNavValue = Number(lastNav).toFixed(2);
    const fundRiskTxtLabel = `${fundRiskTxt} ${riskVal}`;
    const cardId = `id_${accountName}_${fundNumber}_${fundName}`;
    // let increaseValue = '';
    // if (!fundName.toLowerCase().includes(MONEY_MARKET) && +nav > 0) {
    //     increaseValue = INCREASE;
    // } else if (!fundName.toLowerCase().includes(MONEY_MARKET)) {
    //     increaseValue = DECREASE;
    // }
    const navChangeLabel = `${Number(nav).toFixed(2)}%`;
    const viewDetails = 'View fund details';

    return (
        !isRewardShares && (
            <StyledCard
                onClick={onClick}
                data-analytic-id="Transactions_Buy_FundAccountSelection"
                selected={selected}
                Id={id}
            >
                <StyledCardBody tabIndex="-1" aria-describedby={cardId} id={fundNumber.toString()}>
                    {selected && <RwdTickMark />}
                    <AccountCardTopSection>
                        <CustomDiv>
                            <ToggleButton
                                key="1"
                                type="radio"
                                variant="secondary"
                                name="radio"
                                style={assignObj({
                                    width: '100%',
                                    backgroundColor: '#FFFFFF',
                                    boder: 0,
                                })}
                                value={accountName}
                                // onChange={onClick}
                                checked={!!selected}
                                // aria-checked={!!selected}
                                className="btn btn-outline-light"
                            >
                                <div className="sr-only">
                                    <span>{accountName}</span>
                                </div>
                                <StyledFundName Id={id} style={boldText}  tabIndex="-1" role="presentation">
                                    <span aria-hidden="true">{accountName}</span>
                                    {existingFund !== '' && (
                                        <StyledSpanExistingFund>
                                            {' '}
                                            <ImageTagDiv src={IconMessageTag} alt="" />
                                            {existingFund}
                                        </StyledSpanExistingFund>
                                    )}
                                </StyledFundName>
                            </ToggleButton>
                            <div>
                                <ShowHideButtons
                                    aria-label="View fund details"
                                    onClick={Object.assign(e => handleFundSummary(fundNumber.toString(), e))}
                                    data-analytic-id="Transactions_Buy_ViewFundDetails"
                                    href="#"
                                    role="link"
                                >
                                    {viewDetails}
                                </ShowHideButtons>
                            </div>
                        </CustomDiv>
                        {/* <VerticalSeperator /> */}
                        {/* <Switch isChecked={ selected } /> */}
                        {/* <div>
                            <WSwitch 
                                id={fundNumber.toString()}
                                onSwitchClick={onSwitchClick}
                                checked={checked} 
                                />
                        </div> */}
                        {selected && <DesktopTickMark />}
                    </AccountCardTopSection>
                    <StyledCardRow Id={id}>
                        <AccountCardFlex>
                            {transactionType !== 'exchange' && (
                                <>
                                    <AccountCardBottomColumn>
                                        <AccountCardHeadingSpan aria-label={minimumTxt}>
                                            {minimumTxt}
                                        </AccountCardHeadingSpan>
                                        <AccountCardValueSpan aria-label={minimumInvestment}>
                                            {minimumInvestment}
                                        </AccountCardValueSpan>
                                    </AccountCardBottomColumn>

                                    <AccountCardBottomColumn>
                                        <AccountCardHeadingSpan aria-label={fundAutoInvstTxt}>
                                            {fundAutoInvstTxt}
                                        </AccountCardHeadingSpan>
                                        <AccountCardValueSpan aria-label={getAutoInvstFund}>
                                            {getAutoInvstFund}
                                        </AccountCardValueSpan>
                                    </AccountCardBottomColumn>
                                </>
                            )}
                            <AccountCardBottomColumn>
                                <AccountCardHeadingSpan aria-label={fundLastNavTxt}>
                                    {fundLastNavTxt}
                                </AccountCardHeadingSpan>
                                <AccountCardValueSpan aria-label={fundLastNavValue}>
                                    {DOLLAR}
                                    {fundLastNavValue}
                                </AccountCardValueSpan>
                            </AccountCardBottomColumn>
                            <AccountCardBottomColumn>
                                {transactionType !== 'exchange' ? (
                                    <AccountCardHeadingSpan aria-label={fundNavTxt}>
                                        {fundNavTxt}
                                    </AccountCardHeadingSpan>
                                ) : (
                                    <AccountCardHeadingSpan aria-label={navChangeTxt}>
                                        {existingFund !== '' ? navChangeTxt : fundNavTxt}
                                    </AccountCardHeadingSpan>
                                )}
                                <AccountCardValueSpan className="iconCol" aria-label={navChangeLabel}>
                                    {!fundName.toLowerCase().includes(MONEY_MARKET) ? (
                                        <>
                                            {+nav === 0 && <span />}
                                            {+nav > 0 && (
                                                <>
                                                    <span className="sr-only">{INCREASE_IN_PERCENTAGE_TEXT}</span>
                                                    <DesktopWIcon
                                                        alt=""
                                                        src={greenArrowUp}
                                                        size="2x"
                                                        fontawesomestyle={styles.tableIconStyle}
                                                    />
                                                    <RwdWIcon
                                                        alt=""
                                                        src={rwdGreenArrowUp}
                                                        size="2x"
                                                        fontawesomestyle={styles.tableIconStyle}
                                                    />
                                                </>
                                            )}
                                            {+nav < 0 && (
                                                <>
                                                    <span className="sr-only">{DECREASE_IN_PERCENTAGE_TEXT}</span>
                                                    <DesktopWIcon
                                                        alt=""
                                                        // src={faAngleDown}
                                                        src={arrowDown}
                                                        size="2x"
                                                        fontawesomestyle={styles.tableIconStyleRed}
                                                    />
                                                    <RwdWIcon
                                                        alt=""
                                                        src={rwdRedArrowDown}
                                                        size="2x"
                                                        fontawesomestyle={styles.tableIconStyleRed}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <span />
                                    )}
                                    {!fundName.toLowerCase().includes(MONEY_MARKET) ? (
                                        <StyledChangeInNavDiv style={textColor}>
                                            {DOLLAR}
                                            {Math.abs(nav).toFixed(2)} {OPEN_PARENTHESES}
                                            {changeInNavPercentage
                                                ? Math.abs(changeInNavPercentage).toFixed(2)
                                                : '0.00'}
                                            {PERCENTAGE_SYMBOL}
                                            {CLOSE_PARENTHESES}
                                        </StyledChangeInNavDiv>
                                    ) : (
                                        <div>
                                            {DOLLAR}
                                            {Math.abs(nav).toFixed(2)} {OPEN_PARENTHESES}
                                            {changeInNavPercentage
                                                ? Math.abs(changeInNavPercentage).toFixed(2)
                                                : '0.00'}
                                            {PERCENTAGE_SYMBOL}
                                            {CLOSE_PARENTHESES}
                                        </div>
                                    )}
                                </AccountCardValueSpan>
                            </AccountCardBottomColumn>

                            <AccountCardBottomColumn>
                                <AccountCardHeadingSpan aria-label={fundMinMaxTxt}>
                                    {fundMinMaxTxt}
                                </AccountCardHeadingSpan>

                                <AccountCardValueSpan aria-label={getFundMinMaxwkVal}>
                                    {getFundMinMaxwkVal}
                                </AccountCardValueSpan>
                            </AccountCardBottomColumn>

                            <AccountCardBottomColumn aria-label={fundRiskTxtLabel}>
                                <AccountCardHeadingSpan>
                                    {fundRiskTxt}&nbsp;&nbsp;
                                    {getImgSrc(riskVal) !== '' ? (
                                        <WIcon
                                            className="riskChart"
                                            src={getImgSrc(riskVal)}
                                            style={assignObj({ display: 'inline-block' })}
                                        />
                                    ) : (
                                        'N/A'
                                    )}
                                </AccountCardHeadingSpan>

                                <RiskAccountCardValueSpan>
                                    <span
                                        className="riskValue"
                                        style={assignObj({
                                            marginLeft: '27px',
                                            width: '85px',
                                            overflowWrap: 'break-word',
                                            wordWrap: 'break-word',
                                            fontSize: '12px',
                                        })}
                                    >
                                        {riskVal}
                                    </span>
                                </RiskAccountCardValueSpan>
                            </AccountCardBottomColumn>
                        </AccountCardFlex>
                    </StyledCardRow>
                </StyledCardBody>
            </StyledCard>
        )
    );
};

TransactionInvestAccountCardComponent.propTypes = {
    // accountName: PropTypes.string.isRequired,
    // autoInvesting: PropTypes.shape({
    //      text: PropTypes.string,
    //      value: PropTypes.string
    //  }),
    //  nav: PropTypes.shape({
    //      text: PropTypes.string,
    //      value: PropTypes.string
    //  }).isRequired,
    //  lastNav: PropTypes.shape({
    //      text: PropTypes.string,
    //      value: PropTypes.string
    //  }).isRequired,
    //  weekValues: PropTypes.shape({
    //      text: PropTypes.string,
    //      value: PropTypes.string
    //  }),
    //  onClick: PropTypes.func,
    //  selected: PropTypes.bool,

    accountName: PropTypes.string,
    fundNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nav: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastNav: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    weekValuesMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    weekValuesMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fundAutoInvstTxt: PropTypes.string,
    fundNavTxt: PropTypes.string,
    fundLastNavTxt: PropTypes.string,
    fundMinMaxTxt: PropTypes.string,
    minInvst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxInvst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyInvst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
    onClick: () => {},
    riskVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fundRiskTxt: PropTypes.string,
    id: PropTypes.string,
    existingFund: PropTypes.string,
    isRewardShares: PropTypes.bool,
    fundName: PropTypes.string,
    handleFundSummary: () => {},
    minimumTxt: PropTypes.string,
    changeInNavPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transactionType: PropTypes.string,
    navChangeTxt: PropTypes.string,
    // minMaxTxtExchange: PropTypes.string,
};
TransactionInvestAccountCardComponent.defaultProps = {
    accountName: '',
    fundNumber: '',
    nav: '',
    lastNav: '',
    weekValuesMin: '',
    weekValuesMax: '',
    fundAutoInvstTxt: '',
    fundNavTxt: '',
    fundLastNavTxt: '',
    fundMinMaxTxt: '',
    minInvst: '',
    maxInvst: '',
    monthlyInvst: '',
    selected: false,
    onClick: () => {},
    riskVal: '',
    fundRiskTxt: '',
    id: '',
    existingFund: '',
    isRewardShares: false,
    fundName: '',
    handleFundSummary: () => {},
    minimumTxt: '',
    changeInNavPercentage: '',
    transactionType: '',
    navChangeTxt: '',
    // minMaxTxtExchange: '',
};

export default TransactionInvestAccountCardComponent;
