/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { isTablet } from 'react-device-detect';

const externalWeb = 'External Website. Opens New Window';

const externalLnkCss = 'external-link';

const srOnlyCss = 'sr-only hidenTablet';

const helpTelephoneNumber = '1-800-235-8396';
const AccordionContent = [
  {
    title:
      'Why did USAA select Victory Capital to acquire the USAA Asset Management Company, which includes its Mutual Fund and ETF businesses and the USAA 529 Education Savings Plan?',
    description: (
      <span>
        Today’s environment has changed the way USAA delivers on its mission for
        investment products. Fierce competition and increased industry
        consolidation among investment firms are resulting in more specialized
        companies that have the size and scale to provide competitive, low-cost
        and innovative products and services.
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        To continue delivering on its mission of providing highly competitive
        products and services for the military community, USAA determined the
        right path forward was to form strategic relationships with trusted
        financial services firms so it could better serve members.
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        USAA chose Victory Capital because of our specialized investment
        expertise and broad array of products at competitive costs.
        Additionally, Victory Capital shares USAA’s commitment to providing
        exceptional service to military members.
      </span>
    ),
  },
  {
    title: 'What benefits does Victory Capital offer?',
    description: {
      header:
        'Victory Capital will offer members expert specialized investment teams and competitive choices, including:​ ',
      list: [
        'An enhanced online experience, including an improved website with investment tools and calculators​ ',
        'A dedicated service and support contact center for USAA Members, personalized portfolio reviews and streamlined account statements ​ ',
        'Expanded investment capabilities, a competitive performance track record and exclusive access to the USAA 529 Education Savings Plan​ ',
        'Commitment to offering members an exceptional level of service and a diverse array of USAA Mutual Funds at competitive costs relative to the industry​ ',
      ],
      footer: '',
    },
  },
  {
    title:
      'When do we expect the full technology transition to conclude with Victory Capital?',
    description: {
      header:
        'The full transition from USAA’s web platforms to those of Victory Capital will take place later this year. Victory Capital will also be introducing important enhancements to your online experience at that time, including:​ ',
      list: [
        'Streamlined account statements​ ',
        'New website to access your account(s)',
      ],
      footer: (
        <span>
          In the meantime, members can continue to access their account(s) on
          <span className="anchorSpan">
            <a
              href="https://www.usaa.com/"
              className="inlineLInk noMarginR"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby={isTablet ? externalLnkCss : null}
            >
              {' '}
              USAA.com
              <span
                className={isTablet ? srOnlyCss : 'sr-only'}
                aria-hidden={isTablet ? 'true' : 'false'}
              >
                {externalWeb}
              </span>
              <span aria-hidden="true" className="icon-newpage small-size" />
            </a>
          </span>
          .
        </span>
      ),
    },
  },
  {
    title:
      'What steps do you need to take to access your account(s) on Victory Capital’s website after the technology transition?',
    description: (
      <span>
        To facilitate access to your accounts after the technology transition,
        you should pre-register now on the Victory Capital website.
        <br aria-hidden="true" />
        Pre-registering will make it easy to access your account(s) on the new
        platform and ensure the security of your information. Throughout this
        process, USAA and Victory Capital will keep you closely informed about
        what other actions you may need to take. In the meantime, you can
        continue to access your account(s) on
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/"
            className="inlineLInk noMarginR"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby={isTablet ? externalLnkCss : null}
          >
            USAA.com
            <span
              className={isTablet ? srOnlyCss : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              {externalWeb}
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
          </a>
        </span>
        .
      </span>
    ),
  },
  {
    title: 'Where can I find more details on the transition?',
    description: (
      <span>
        Please continue to visit
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/"
            className="inlineLInk"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby={isTablet ? externalLnkCss : null}
          >
            {' '}
            USAA.com
            <span
              className={isTablet ? srOnlyCss : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              {externalWeb}
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
            {' '}
          </a>
        </span>
        for updates, and check your email for next steps. If you have not
        provided USAA with an up-to-date email address, phone number or other
        contact information, please update your profile on the My Profile &
        Preferences page on
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/"
            className="inlineLInk"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby={isTablet ? externalLnkCss : null}
          >
            {' '}
            USAA.com
            <span
              className={isTablet ? srOnlyCss : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              {externalWeb}
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
            {' '}
          </a>
        </span>
        or call
        <span className="anchorSpan">
          <a href={`tel:+${helpTelephoneNumber}`} className="noMarginR inlineLInk">
            {helpTelephoneNumber}
          </a>
        </span>
        .
      </span>
    ),
  },
  {
    title:
      'What changes can I expect once the technology transition is  complete?',
    description:
      'Once the technology transition is complete, you can expect personalized portfolio views and streamlined account statements, among other enhancements. You will also continue to have access to Education Savings Planning assistance and general investment guidance through Victory Capital’s Member Service Representatives. ',
  },
  {
    title:
      "What is the phone number for Victory Capital's Member Contact Center?​",
    description: (
      <span>
        To contact a Victory Capital Member Service Representative, call
        <span className="anchorSpan">
          <a className="inlineLInk noMarginR" href={`tel:+${helpTelephoneNumber}`}>
            {' '}
            {helpTelephoneNumber}
          </a>
        </span>
        .
      </span>
    ),
  },
  {
    title:
      'Do I still have online access to my Mutual Funds and USAA 529 Education Savings Plan?​',
    description: (
      <span>
        Yes, members still have access to their account(s) through
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/"
            className="inlineLInk noMarginR"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby={isTablet ? externalLnkCss : null}
          >
            {' '}
            USAA.com
            <span
              className={isTablet ? srOnlyCss : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              {externalWeb}
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
          </a>
        </span>
        . Victory Capital will be transitioning account access to its website
        and introducing enhanced account statements later this year.
      </span>
    ),
  },
  {
    title: 'Why do I need a new login for Victory Capital?',
    description: (
      <span>
        Information regarding your USAA Mutual Funds and USAA 529 College
        Savings Plan will be accessible at www.vcm.com after the completion of
        the technology transition.
        <br aria-hidden="true" />
        To access your information, you will need to create a new account with
        Victory Capital, if you have not already done so. In addition, creating
        a new login ensures your information is secure.
      </span>
    ),
  },
  {
    title:
      'Where do I access my account(s) after the transition to Victory Capital?​',
    description: (
      <span>
        To access your account information after completion of the technology
        transition later this year, please visit www.vcm.com to login to your
        account. To contact a Victory Capital Member Service Representative,
        call
        <span className="anchorSpan">
          <a href={`tel: +${helpTelephoneNumber}`} className="inlineLInk noMarginR">
            {' '}
            {helpTelephoneNumber}
          </a>
        </span>
        . In the meantime, you can continue to access your account(s) on
        <span className="anchorSpan">
          <a
            href="https://www.usaa.com/"
            className="inlineLInk noMarginR"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby={isTablet ? externalLnkCss : null}
          >
            {' '}
            USAA.com
            <span
              className={isTablet ? srOnlyCss : 'sr-only'}
              aria-hidden={isTablet ? 'true' : 'false'}
            >
              {externalWeb}
            </span>
            <span aria-hidden="true" className="icon-newpage small-size" />
          </a>
        </span>
        .
      </span>
    ),
  },
];

const pages = [
  'Review Terms & Conditions',
  'Verify Identity',
  'Verify One-time Passcode',
  'Create User ID',
  'Create Password',
];

const stepperStyle = {
  width: '1107px',
  height: '60px',
  paddingRight: '90px',
  paddingLeft: '150px',
};
const SSNPageTitle = 'We need to verify your SSN';
const SSNPagePara = 'Your security is important to us. Please provide you social security number for identity verification purposes.';
const onlineTitle = 'Create Your New Victory Capital User ID';
const format = 'Format guidelines:';
const preferredId = 'Preferred User ID';
const enterId = 'Enter A New User ID';
const password = 'Password';
const continueP = 'Continue';
const termsCondition = 'Review Terms & Conditions';
const whatsChanging = 'What’s Changing?';
const requiredTitle = 'Required Minimum Distribution (RMD)';
const termsPara = 'Fulfilling your Required Minimum Distribution in accordance with the IRS rules becomes easier with Victory Capital. For your convenience, we will transfer your existing withdrawal plans from USAA to your account with Victory Capital.  On our website you can set up and manage your RMD related withdrawal plans. You can also use our online calculator which is updated with the IRS requirements every year.  You can add information for all your retirement accounts, including those that may not be at Victory Capital, and make changes to your withdrawal plans from Victory Capital accounts accordingly. ';
const profileInfo = 'Profile & Account Information';
const tremsPara2 = 'Beginning Q3 2020, you may update your account information in the profile section on vcm.com.';
const vcm = 'vcm.com';
const para3 = 'By checking this box, I acknowledge that I have read the preceding terms and condition and that I understand, assent to, and agree to comply with, those terms and conditions.';
const passwordBtn = 'Enter A New Password';
const successTitle = 'Thank you for creating your secure Victory Capital login credentials';
const successNew = 'Beginning later this year, you can access your USAA Mutual Funds and 529 Education Savings Plan account information at ';
const successNewSecond = 'Until later this year, please continue to access your accounts on ';
const successPara = 'Thank you for reviewing your profile information and setting up your account credentials. We look forward to serve you. Please revisit the portal on Q3 2020 for added functionality. In the meantime, you can continue accessing your USAA Mutual Funds and 529 Education Savings Plan related information on ';
const webUrl = 'www.usaa.com';
const webURLBase = 'https://';
const fullStop = '.';
const verifyInfo = 'Verify Your Identity';
const verifyInfoPara = 'Please verify your identity based on the information to the right.';
const correctInfo = 'My identity is verified';
const needUpdate = 'If you need to update your information,';
const nedUpdate2 = 'go to the My Profile & Preferences page on USAA.com';
const needUpdate3 = `or call a Victory Capital Member Service Representative at
      ${helpTelephoneNumber} revisit`;
const needUpdate4 = `on or after 26 May 2020 with the logon credentials created as
      part of this login setup process.`;
const passcodeVerify = 'Choose One Time Passcode (OTP) Method';
const passcodePara = 'To protect your information, we require you to authenticate yourself with a one time passcode sent to you. Please select your delivery preference for this authentication.';
const preference = 'One Time Passcode Delivery Preference';
const deliverPreference = 'Preferred Phone Number';
const passcodebottom = 'If you need to update your information,';
const passcodeBottom2 = 'go to the My Profile & Preferences page on USAA.com';
const passCodeBottom3 = `or call a Victory Capital Member Service
      Representative at ${helpTelephoneNumber}.`;

const welcome = 'Welcome,';
const preRegister = 'Pre-register your accounts today.';
const para2 = `Your USAA Mutual Funds and USAA 529 Educational Savings accounts are
transitioning to Victory Capital.`;
const benifits = 'Victory Capital: What you can expect';
const para4 = 'Timely and relevant educational content and helpful online investment tools, resources and calculators.';
const para5 = 'Dedicated service and support center for USAA Members featuring personalized portfolio reviews, general investment guidance and many other investment related services.';
const para6 = 'Exclusive access to the USAA 529 Education Savings Plan along with college financial planning services.';
const para7 = 'Committed to serving the military community with free investment advice and highly specialized investment products and services. ';
const questions = 'Frequently Asked Questions';
const verifySSN = 'We need to verify your SSN';
const ssn = 'Social Security Number';
const memberSupport = 'Call for member support.';
const passcodeHeading = 'Enter the One Time Passcode';
const passcodeParag = 'We have sent One Time Passcode to your registered Mobile number.';
const passcodeSubParag = 'This One Time Passcode is valid for 10 mins.';
const passcode = 'Passcode';
const digits = '(6 digits)';
const passcodeSubmit = 'Submit';
const mandatoryText = 'Please review the documents and check the boxes to proceed';
const signInText = 'Do you want to sign in now?';
const signin = 'Sign In';
const successPostGoLiveTitle = 'You did it! Thank you for completing the registration process. Click below to login to your account. ';
const termsAndConditionsPDF = {
  name1: 'Victory Capital Privacy Notice',
  name2: 'Victory Capital Terms of Service',
  name3: 'Victory Capital Online Agreement',
};

export const digitalData = {
  version: '1.0',
  page: {
    pageInfo: {
      PageName: '',
      Section: 'pre-registration',
      pageDocumenttitle: '',
      pageURL: '',
      previousPagename: '',
      pagePercentageview: '',
      pageRef: '',
      pageType: 'pre-registration',
      pageHier: '',
      siteError: '',
      modulelog: 'Preregistration',
    },
  },
  user: {
    userInfo: {
      customerType: 'prospect',
    },
  },
};
const userDetailsTitle = 'Preregistration|Verify Personal Information';
export const pageDocument = [
  {
    key: 'default',
    pageName: 'Preregistration|Welcome Page',
    previousPage: '',
    pageDocumenttitle: 'Welcome Page'
  },
  {
    key: 'termsandconditions',
    pageName: 'Preregistration|Terms and Conditions',
    previousPage: 'Preregistration|Welcome Page',
    pageDocumenttitle: 'Terms and Conditions'

  },
  {
    key: 'userdetails',
    pageName: userDetailsTitle,
    previousPage: 'Preregistration|Terms and Conditions',
    pageDocumenttitle: 'Verify Personal Information'
  },
  {
    key: 'onetimecode',
    pageName: 'Preregistration|Generate Passcode',
    previousPage: userDetailsTitle,
    pageDocumenttitle: 'Generate Passcode',
  },
  {
    key: 'onetimecodeverify',
    pageName: 'Preregistration|Verify Passcode',
    previousPage: 'Preregistration|Generate Passcode',
    pageDocumenttitle: 'Verify Passcode',
  },
  {
    key: 'enterUserId',
    pageName: 'Preregistration|Create User ID',
    previousPage: userDetailsTitle,
    pageDocumenttitle: 'Create User ID',
  },
  {
    key: 'enterPassword',
    pageName: 'Preregistration|Create Password',
    previousPage: 'Preregistration|Create User ID',
    pageDocumenttitle: 'Create Password'
  },
  {
    key: 'Success',
    pageName: 'Preregistration|Success',
    previousPage: 'Preregistration|Create Password',
    pageDocumenttitle: 'Success'
  },
  {
    key: 'Signout',
    pageName: 'Preregistration|Sign Out',
    previousPage: 'Preregistration|Verify Passcode',
    pageDocumenttitle: 'Sign Out - Your session has Timedout'
  }
];


export const errorCodeMessageMapping = {
  2004: 'Please try generating new one time passcode after an hour.',
  2005: 'We can not process your request at this time. Please try again after 24 hours.',
  2006: 'Unable to Process your request at this time. Please try again.',
  2007: 'Member Not verified',
  2008: 'Please generate a new one time passcode.',
  2009: 'Error while verifying the status',
  2015: 'Incorrect passcode, please try again or request a new passcode',
  2020: 'Member Already Registered',
  2021: 'Error fetching member Info',
  2022: 'USAA Member match not found',
  2023: "User doesn't exist.",
  2025: 'User ID already exists',
  2026: 'OTP not verified or member already registered',
  2027: 'Exceeded maximum OTP attempts.',
  2028: 'Wrong OTP',
  5002: 'Email ID is already taken, please re-try with different e-mail Id',
};

const guideSatisfied = 'Guideline satisfied';
const createPassword = 'Create Your Password';
const downloadPDF = 'Download PDF';
const printPDF = 'Print PDF';
const close = 'Close';
const opensPop = 'Opens a popup';


export default {
  AccordionContent,
  pages,
  stepperStyle,
  onlineTitle,
  format,
  preferredId,
  enterId,
  password,
  continueP,
  termsCondition,
  whatsChanging,
  requiredTitle,
  termsPara,
  profileInfo,
  tremsPara2,
  vcm,
  para3,
  passwordBtn,
  successPara,
  successNewSecond,
  successNew,
  webUrl,
  webURLBase,
  fullStop,
  successTitle,
  verifyInfo,
  verifyInfoPara,
  correctInfo,
  needUpdate,
  nedUpdate2,
  needUpdate3,
  needUpdate4,
  passcodeVerify,
  passcodePara,
  preference,
  deliverPreference,
  passcodebottom,
  passcodeBottom2,
  passCodeBottom3,
  welcome,
  para2,
  para4,
  para5,
  para6,
  para7,
  questions,
  preRegister,
  benifits,
  helpTelephoneNumber,
  verifySSN,
  ssn,
  memberSupport,
  passcodeHeading,
  passcodeParag,
  passcodeSubParag,
  passcode,
  digits,
  passcodeSubmit,
  SSNPageTitle,
  SSNPagePara,
  mandatoryText,
  termsAndConditionsPDF,
  signin,
  signInText,
  successPostGoLiveTitle,
  guideSatisfied,
  createPassword,
  downloadPDF,
  printPDF,
  close,
  opensPop
};
