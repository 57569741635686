const assignObj = obj => obj;

const Rules = {
    victoryMemberId: {
        maxLength: 9,
        isNumeric: true,
        required: true,
        requiredMsg: 'Please enter your Victory MemberID',
        lengthMsg: 'Victory MemberID should be less than 9 digits',
    },
    usaaMemberId: {
        maxLength: 9,
        isNumeric: true,
        required: true,
        requiredMsg: 'Please enter your USAA MemberID',
        lengthMsg: 'USAA MemberID should be less than 9 digits',
    }
};

const styles = {
    hide: {
        display: 'none'
    },
    commonButtons: { marginTop: 100 },
}

const data = {
    infoMsg: 'Enter your 9-digit Member ID (ex: if your member id is 123456, enter 000123456)'
}

const moreSignUpAtmtsTitle = 'Member Validation';

export { assignObj, Rules, styles, data, moreSignUpAtmtsTitle };