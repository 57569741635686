import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import {accOpeningActions, localStateManagementActions, loginActions} from '../../../../shared/Actions';
import SetupSecurityQuestions from './SetupSecurityQuestions';



export const mapStateToProps = (state /* , props */) => (
    {
      questionsData : state.masterLookUpData.security_ques,
      setupSecurityQuestionsState: state.localStateManagementReducerData.setupSecurityQuestionsState,
      customerProfile: state.localStateManagementReducerData.customerProfile,
      loginState: state.loginData,
    }
  );

const mapDispatchToProps = {
     ...accOpeningActions,
     ...localStateManagementActions,
     ...loginActions,
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupSecurityQuestions));