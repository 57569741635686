import { set } from "lodash";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    dividendsInfo: [],
};

function dividentsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                isLoadingGetAccountList: false,
                isErrorGetAccountList: false,
                isSuccessGetAccountList: false
            };
        }
        case ActionTypes.GET_ACCOUNTS_LIST_SUCCESS: {
            return {
                ...state,
                dividendsInfo: action.payload,
                isLoadingGetAccountList: false,
                isErrorGetAccountList: false,
                isSuccessGetAccountList: true
            };
        }
        case ActionTypes.GET_ACCOUNTS_LIST_LOADING: {
            return {
                ...state,
                isLoadingGetAccountList: true,
                isErrorGetAccountList: false,
                isSuccessGetAccountList: false
            };
        }
        case ActionTypes.GET_ACCOUNTS_LIST_FAILURE: {
            return {
                ...state,
                error: action.response,
                isSuccessGetAccountList: false,
                isLoadingGetAccountList: false,
                isErrorGetAccountList: true
            };
        }

        case ActionTypes.UPDATE_DIVIDENDS_LOADING: {
            return {
                ...state,
                isLoadingPostUpdateDividends: true,
                isSuccessPostUpdateDividends: false,
                isErrorPostUpdateDividends: false
            };
        }
        case ActionTypes.UPDATE_DIVIDENDS_SUCCESS: {
            return {
                ...state,
                updatedFunds: action.payload,
                isLoadingPostUpdateDividends: false,
                isSuccessPostUpdateDividends: true,
                isErrorPostUpdateDividends: false,
            };
        }
        case ActionTypes.UPDATE_DIVIDENDS_FAILURE: {
            return {
                ...state,
                isLoadingPostUpdateDividends: false,
                isSuccessPostUpdateDividends: false,
                isErrorPostUpdateDividends: true,
            };
        }
        case ActionTypes.CHANGE_FUNDS_OPTIONS: {
            const newState = { ...state };
            set(newState, action.payload.path, action.payload.data);
            return {
                ...newState
            };
        }
        case ActionTypes.RESET_UPDATE_FLAG: {
            return {
                ...state,
                isSuccessPostUpdateDividends: false,
                isErrorPostUpdateDividends: false,
            };
        }

        default:
            return state;
    }
}

export default dividentsReducer;