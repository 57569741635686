export const handleMainStateError = (state,errors) =>
{
    if (state?.vcEmployee === '' || state?.vcEmployee === undefined) {
        errors.vcEmployee = "Mandatory field"
    }
    if(state.investmentObjective === ''){
        errors.investmentObjective = "Mandatory field"
    }
    if(state.timeHorizon === ''){
        errors.timeHorizon = "Mandatory field"
    }
    if(state.riskExposure === ''){
        errors.riskExposure = "Mandatory field"
    }
    if(state.investmentKnowledge === ''){
        errors.investmentKnowledge = "Mandatory field"
    }
    if(state.investmentGoal === ''){
        errors.investmentGoal = "Mandatory field"
    }
    if(state.sourceOFFund === ''){
        errors.sourceOFFund = "Mandatory field"
    }
    

    if(state.brokerDealerEmployment === ''){
        errors.brokerDealerEmployment = "Mandatory field"
    }

    return errors;
}

export const handleErrorWhenBrokerDealerEmployment = (state,errors)=>
{
    if(state.brokerDealerEmployment == 'Yes'){
        if(state.employeeOfThisBrokerDealer === ''){
            errors.employeeOfThisBrokerDealer = "Mandatory field"
        }
        if(state.isRelatedToBrokerDealer === ''){
            errors.isRelatedToBrokerDealer = "Mandatory field"
        }
        if(state.isEmployeeOfAnotherBrokerDealer === ''){
            errors.isEmployeeOfAnotherBrokerDealer = "Mandatory field"
        }
        
        if(state.isRelatedToEmployeeOfAnotherBrokerDealer === ''){
           errors.isRelatedToEmployeeOfAnotherBrokerDealer = "Mandatory field"
       }
       if(state.isMemberEmployeeOfStockExchangeFINRA === ''){
        errors.isMemberEmployeeOfStockExchangeFINRA = "Mandatory field"
       }

    }else{
        console.log('negetive case for test coverage')
    }
    return errors;
}

export const handleErrorWhenBrokerEmploymentChildSection = (state,errors) =>
{
    if(state.isRelatedToBrokerDealer === 'Yes'){
        if(state.relatedToBrokerDealer === ''){
            errors.relatedToBrokerDealer = "Mandatory field"
        }
        if(state.relatedToBrokerDealer.lastName === ''){
            errors.relatedToBrokerDealer = "Mandatory field"
        }
        if(state.relatedToBrokerDealer.relationship === ''){
            errors.relatedToBrokerDealer = "Mandatory field"
        }
    }


    // Employee Of Another Brokerdealer employment child section
    if(state.isEmployeeOfAnotherBrokerDealer === 'Yes'){
        if(state.EmployeeOfAnotherBrokerDealer.firstname === ''){
            errors.EmployeeOfAnotherBrokerDealer = "Mandatory field"
        }
    }



    // Related to Employee Of Another Brokerdealer employment child section

    if (state.isRelatedToEmployeeOfAnotherBrokerDealer === 'Yes') {
        const { relatedToEmployeeOfAnotherBrokerDealer } = state;
        const { firstname, lastName, relationship, brokerDealerName } = relatedToEmployeeOfAnotherBrokerDealer;
    
        if (!firstname || !lastName || !relationship || !brokerDealerName) {
            errors.relatedToEmployeeOfAnotherBrokerDealer = "Mandatory field";
        }else{
            console.log('negetive case for test coverage')
        }
    }else{
        console.log('negetive case for test coverage')
    }

    return errors;
}


export const handleErrorForForeignInstitution = (state,errors) =>{
    const { accountOpenedForForeignInstitution } = state;

    // Account being opened for foreign Institution child section
    if (state.isAccountOpenedForForeignInstitution === 'Yes') {
        const { isFinancialInstitution, isPrivateBank, isForeignBank } = accountOpenedForForeignInstitution;
        

        if (!isFinancialInstitution || !isPrivateBank || !isForeignBank) {
            console.log('negetive case for test coverage 1',!isForeignBank)
        
            errors.accountOpenedForForeignInstitution = "Mandatory field";
        }else{
            console.log('negetive case for test coverage 2')

        }

        // Account being opened for foreign Institution foreignbank child section
        if (isForeignBank === 'Yes') {
            const { foreignBank } = accountOpenedForForeignInstitution;
            const { isCentralBank, isBankingLicense, isTerritory, isSubjectToSection311 } = foreignBank;

            if (!isCentralBank || !isBankingLicense || !isTerritory || !isSubjectToSection311) {
                errors.accountOpenedForForeignInstitution = "Mandatory field";
            }else{
                console.log('negetive case for test coverage 3')
            }
        }else{
            console.log('negetive case for test coverage 4')

        }
    }else{
        console.log('negetive case for test coverage')
    }
    
    return errors;
}

export const handleRadioButtonWhenSelectNo = (prevState,state,buttonType,changeParameter,changeValue,defaultParameter,defaultObject,eventKey="") =>
{
    if(eventKey == 'brokerDealerEmployment')
    {
        if(buttonType == 'No'){
            return { ...prevState, brokerDealerEmployment: changeValue,
                employeeOfThisBrokerDealer:'',
                isRelatedToBrokerDealer:'',
                relatedToBrokerDealer: {
                    firstname: "",
                    lastName: "",
                    relationship: "",
                    employee: "",
                },
                isEmployeeOfAnotherBrokerDealer:"",
                EmployeeOfAnotherBrokerDealer: {
                    firstname: "",
                    lastName: "",
                },
                isRelatedToEmployeeOfAnotherBrokerDealer:"",
                relatedToEmployeeOfAnotherBrokerDealer: {
                    firstname: "",
                    lastName: "",
                    relationship: "",
                    brokerDealerName: "",
                },
                isMemberEmployeeOfStockExchangeFINRA:"",
                memberEmployeeOfStockExchangeFINRA: {
                    nameOfAffiliation: "",
                },
                isOtherBrokerageAccounts:"",
                otherBrokerageAccounts: {
                    brokerageName: "",
                    yearsOfInvestingExperience: "",
                }
            };
        }else{
            return { ...prevState, brokerDealerEmployment: changeValue };
        }
    }
    else if(eventKey == 'isForeignBank') 
    {
        if(buttonType == 'No'){
            return { ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    isForeignBank: 'No',
                    foreignBank: {
                        isCentralBank:'', isBankingLicense:'',isTerritory:'',isSubjectToSection311:''
                    }
                }
            };
        }else{

           return { ...prevState,
                accountOpenedForForeignInstitution:{
                    ...state.accountOpenedForForeignInstitution,
                    isForeignBank: 'Yes',
                }
            };

        }
    }
    else if(eventKey == "isPep")
    {  
        if(buttonType == 'No'){
            return { ...prevState,isPep:changeValue, pep: {...state.pep, firstName:'',lastName:'',officeHeld:'',countryOfOffice:'' }};

        }else{
            return { ...prevState, [eventKey]: changeValue };
        }
    }
    else
    {
        if(buttonType == 'No'){
            return { ...prevState, [changeParameter.toString()]: changeValue,
                [defaultParameter.toString()]: defaultObject
            };
        }else{

           return { ...prevState, [changeParameter.toString()]: changeValue };
        }
    }
}
