const data = {
    minText: 'Must be at least 5 characters',
    upperLowerText: 'May contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z) and numerical characters (e.g. 0-9)',
    symbolText: 'Must not contain special characters ~!@#$%^&*()_-+= or spaces',
    headStyle: {marginBottom: 28},
    onlineId: 'onlineId',
    title: "Create User ID",
    your: "Your ",
    id: "User ID",
    created: " is successfully created.",
    patternMessage: 'The User ID does not meet the length and format requirements.',
}

export default data;