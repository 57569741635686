const fieldData = [
	{
		uniqueKey: 'annualIncome',
		key: 'Annual Income',
		value: '',
		type: 'select'
	},
	{
		uniqueKey: 'taxBracket',
		key: 'Tax Bracket',
		value: '',
		type: 'staticfield'
	},
	{
		uniqueKey: 'netWorth',
		key: 'Net Worth',
		value: '',
		type: 'select'
	},
	{
		uniqueKey: 'taxFillingStatus',
		key: 'Tax Filing Status',
		value: '',
		type: 'select'
	}
	];
const bredCrumItmes = [
	{ 
		url: '/', 
		name: 'Profile',
		dataAnalyticId:'cms_basicinformation_managefinancialinfo_breadcrumb_profile'
	}, 
	{ 
		url: '/basicinformation', 
		name: 'Basic Information',
		dataAnalyticId:'cms_basicinformation_managefinancialinfo_breadcrumb_basicinfo'
	}
];
const FINANCIAL_INFO ='Financial Information';
const ANNUAL_INCOME = 'Annual Income';
const ANNUAL_INCOME_USD = 'Annual Income ($)';
const TAX_BRACKET = 'Tax Bracket';
const TAX_BRACKET_PERCENTAGE = 'Tax Bracket (%)';
const NET_WORTH = 'Net Worth';
const NET_WORTH_USD = 'Net Worth ($)';
const TAX_STATUS = 'Tax Filing Status';
const ACTION_TEXT = 'Manage';
const ACTION_LABEL_FINANCIAL = 'Manage Financial Information';
const NOTE_TEXT_2 = 'This information is collected on behalf of each of the VCM companies, and will be used for business purpose, including identification, verification of eligibility, and to market their products and services.';
const NOF = 'None on file';
const SAVE = "Save";
const CANCEL = "Cancel";


export default {
	// disableStyle,
	fieldData,
	bredCrumItmes,
	FINANCIAL_INFO,
	ACTION_LABEL_FINANCIAL,
	ANNUAL_INCOME,
	TAX_BRACKET,
	NET_WORTH,
	TAX_STATUS,
	ACTION_TEXT,
	NOTE_TEXT_2,
	NOF,
	ANNUAL_INCOME_USD,
	TAX_BRACKET_PERCENTAGE,
	NET_WORTH_USD,
	SAVE,
	CANCEL
};