import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone'
import { get, has, isObject } from 'lodash';
import constant from '../AccountDashboardConstants';
import SideHeader from '../SideHeaderMain/SideHeader';
import WCardOverlay from '../WCardOverlay/WCardOverlay';
import iconMailWhite from '../../../../assets/icon_mail_white.png';
import MessageCenterList from '../MessageCenterList/MessageCenterList';

class MessageCenterWidget extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            docJson: []
        }
    }

    componentDidMount() {
        const { getDashboardWidgetMessages } = this.props
        //    const {dummyMessages}=constant
        // const {dashboardMessages,dashboardUnreadMessageCount}=messageCenterData || {}
        // if (!dashboardUnreadMessageCount && !Object.keys(dashboardMessages).length) {
        getDashboardWidgetMessages()
        //   // }else{
        //         this.generateDocJson(dashboardMessages)
        //     }
        // this.generateDocJson(dummyMessages)
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            const { messageCenterData } = this.props
            const { dashboardMessages } = messageCenterData || {};
            if (Object.keys(messageCenterData).length &&
                Object.keys(dashboardMessages).length &&
                dashboardMessages !== prevProps.messageCenterData.dashboardMessages) {
                //  console.log(dashboardMessages)
                this.generateDocJson(dashboardMessages)
            }
            if (Object.keys(messageCenterData).length &&
                messageCenterData.isError && messageCenterData.payload !== prevProps.messageCenterData.payload) {
                this.handleAPIError(messageCenterData.payload)
            }

        }
    }

    handleAPIError = (error) => {
        const {commonErrorMsg}=constant
        let errorMsg = error
        if (has(error, 'data')) {
            if (has(error.data, 'errorMessages')) {
                const [firstMsg] = get(error.data, 'errorMessages', 'Error')
                errorMsg = firstMsg
            } else if (has(error.data, 'message')) {
                errorMsg = get(error.data, 'message', 'Error')
            }
        }
        if (isObject(errorMsg)) {
            errorMsg = commonErrorMsg
        }
        this.setState({ errorMessage: errorMsg })
    }

    sortFunction = (a, b) => {
        const dateA = new Date(Number(a.createdAt)).getTime()
        const dateB = new Date(Number(b.createdAt)).getTime()
        return dateA > dateB ? -1 : 1;
    };

    convertTimestampToDate = (ts) => {
        if (ts) {
            let timeStamp = ts
            if (ts.length === 10) {
                timeStamp = ts * 1000
            } else if (ts.length === 13) {
                timeStamp = ts
            }
            return moment(new Date(Number(timeStamp))).format('MM/DD/YYYY')
        }
        return 'InvalidDate';
    }

    generateDocJson = (data) => {
        // const sortedData= data && data.sort(this.sortFunction)
        const docJson = data &&
            data.filter((v, index) => (index < 4))
                .map((v, index) => ({
                    id: index,
                    date: this.convertTimestampToDate(v.createdAt),
                    text: v.subject,
                    showAlertIcon: v.messageType === 'urgent',
                    isRead: v.isRead
                }));
        this.setState({ docJson })
    }

    handleMessageClick = (e) => {
        // Analytics Implementation
        const { analyzeClickEvent } = this.props
        const linkName=e.target.innerText || ''       
        const screenName=localStorage.getItem("screenName")        
        analyzeClickEvent({
            link: `Dashboard_${screenName}_MessageCenter_${linkName}`
        })
        this.setState({ redirectToMessageCenter: true })
    }

    render() {
        const { messageCenterData } = this.props
        const { assignObj, messagCenter, noMessagesText } = constant
        const { dashboardUnreadMessageCount } = messageCenterData || {};
        const { docJson, redirectToMessageCenter = false, errorMessage = '' } = this.state
        const NoMessages = docJson && docJson.length === 0 ? noMessagesText : ''
        return <><WCardOverlay
            myStyles={assignObj({ marginTop: '30px', minHeight: '29.1875rem'})}
        >
            <div>
                <SideHeader
                    title={messagCenter}
                    imgSrc={iconMailWhite}
                    alerts={dashboardUnreadMessageCount}
                    width="20px"
                    height="16px"
                    // alt="messagecenter"
                    id="messageCenter"
                    headStyles={assignObj({ marginBottom: 0 })}
                />
                <div 
                // role="region" 
                aria-labelledby="messageCenter" className="ach-message-center-main">
                    <div className="actualmessages">
                        <div role="list" className="list-items">
                            {docJson &&
                                docJson
                                    .map(item => {
                                        return (
                                            <MessageCenterList
                                                key={item.id}
                                                date={item.date}
                                                text={item.text}
                                                showAlertIcon={item.showAlertIcon}
                                                handleTextClick={this.handleMessageClick}
                                                isRead={item.isRead}
                                            />
                                        );
                                    })}
                        </div>
                        {errorMessage !== '' ?
                            <div style={assignObj({ color: 'red', padding: '15px 0px 0px 15px', font: '500 16px/24px benton-sans,sans-serif', })}>{errorMessage}</div> :
                            <div style={assignObj({ padding: '15px 0px 0px 15px', font: '500 16px/24px benton-sans,sans-serif', })}>{NoMessages}</div>
                        }
                    </div>
                </div>
            </div>
        </WCardOverlay>
            {redirectToMessageCenter && <Redirect to='/messageCenter' />}
        </>
    }

}


MessageCenterWidget.propTypes = {
    messageCenterData: PropTypes.instanceOf(Object),
    getDashboardWidgetMessages: PropTypes.func,
    analyzeClickEvent:PropTypes.func,
};

MessageCenterWidget.defaultProps = {
    messageCenterData: {},
    getDashboardWidgetMessages: () => { },
    analyzeClickEvent:()=>{}
};

export default MessageCenterWidget;
