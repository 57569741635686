import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as MessageActionTypes from '../ReduxConstants/MessageCenterConstants';

const initialState = {
    unreadMessages: 0,
    messages: [],
    selectedMessages: [],
    error: '',
    messageListIsOpen: false,
    topics: [],
    accounts: [],
    selectAll: false,
    mcConfirmationMsg: null,
    showMsgConfirmDialog: false,
    showSuccessMsgDialog: false,
    messageType: MessageActionTypes.MESSAGE_CENTER_INBOX_VAL,
    isLoading: false,
    sentSecureMessage: {
        success: false,
        message: undefined,
        confirmation: '',
    },
    lastEvaluatedKey: null,
    messagesPerPage: -1,
    orderBy: MessageActionTypes.MESSAGE_CENTER_DATE_VAL,
    orderType: MessageActionTypes.MESSAGE_CENTER_DESCENDING_VAL,
    isLoadingMessages: false,
    dashboardMessages: [],
    dashboardUnreadMessageCount: 0,
    isSuccess: false,
    isError: false,
    token: null,
    showLoginMessage: true,
};

function messageCenterReducer(state = initialState, action) {
    switch (action.type) {
        case MessageActionTypes.SET_AUTH_TOKEN:
            return { ...state, token: action.payload };
        case MessageActionTypes.SET_MESSAGE_TYPE:
            return { ...state, messageType: action.payload };
        case MessageActionTypes.SEND_SECURE_MESSAGE_ERROR:
            return { ...state, isLoading: false, sentSecureMessage: action.payload.sentSecureMessage };
        case MessageActionTypes.SEND_SECURE_MESSAGE:
            return { ...state, isLoading: true };
        case MessageActionTypes.SEND_SECURE_MESSAGE_SUCESS:
            return {
                ...state,
                sentSecureMessage: action.payload.sentSecureMessage,
                messageType: MessageActionTypes.MESSAGE_CENTER_INBOX_VAL,
                isLoading: false,
            };
        case ActionTypes.GET_UNREAD_MESSAGE_COUNT_SUCCESS:
            return { ...state, unreadMessages: action.payload.unreadMessages };
        case ActionTypes.MESSAGE_LIST_CLOSED:
            return { ...state, messageListIsOpen: action.payload };
        case ActionTypes.MESSAGE_ICON_CLICKED:
            return { ...state, messageListIsOpen: action.payload };
        case ActionTypes.GET_MESSAGES_RECEIVED:
            return {
                ...state,
                selectAll: false,
                selectedMessages: [],
                messages: action.payload.messages,
                messageType: MessageActionTypes.MESSAGE_CENTER_INBOX_VAL,
            };
        case ActionTypes.GET_TOPICS_RECEIVED:
            return { ...state, topics: action.payload, messageType: MessageActionTypes.MESSAGE_CENTER_INBOX_VAL };
        case ActionTypes.GET_ACCOUNTS_RECEIVED:
            return { ...state, accounts: action.payload };
        // Message center - Get messages based on message type as per current API contract
        case MessageActionTypes.GET_MC_MESSAGES_START:
            return {
                ...state,
                isLoadingMessages: action.payload.isLoading,
                error: false,
            };
        case MessageActionTypes.GET_MC_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload.messages,
                messageType: action.payload.messageType,
                selectedMessages: [],
                selectAll: false,
                showMsgConfirmDialog: false,
                unreadMessages: action.payload.unreadMessageCount,
                hasMoreMessagesToLoad: !!action.payload.lastEvaluatedKey,
                messagesPerPage: state.messagesPerPage === -1 ? action.payload.messages.length : state.messagesPerPage,
                isLoadingMessages: false,
            };
        case MessageActionTypes.GET_MC_MESSAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoadingMessages: false,
            };
        case MessageActionTypes.SET_LOGIN_DISPLAY_MESSAGE_SUCCESS:
            return {
                ...state,
                showLoginMessage: false,
            };
        case MessageActionTypes.SET_PAGE_SIZE_SUCCESS:
            return {
                ...state,
                pageSize: action.payload.pageSize,
                messageType: action.payload.messageType,
            };
        case MessageActionTypes.SET_PAGE_SIZE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.SET_ORDER_BY_ORDER_TYPE_SUCCESS:
            return {
                ...state,
                orderBy: action.payload.orderBy,
                orderType: action.payload.orderType,
            };
        case MessageActionTypes.SET_ORDER_BY_ORDER_TYPE_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case MessageActionTypes.DELETE_ALL_SELECTED_MESSAGES_SUCCESS:
            return {
                ...state,
                selectedMessages: [],
                selectAll: false,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: false,
                messageType: action.payload,
                lastEvaluatedKey: null,
                pageSize: state.messageType === action.payload ? state.pageSize : -1,
                orderBy:
                    state.messageType === action.payload ? state.orderBy : MessageActionTypes.MESSAGE_CENTER_DATE_VAL,
                orderType:
                    state.messageType === action.payload
                        ? state.orderType
                        : MessageActionTypes.MESSAGE_CENTER_DESCENDING_VAL,
            };
        case MessageActionTypes.DELETE_ALL_SELECTED_MESSAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                selectAll: false,
            };
        case MessageActionTypes.DELETE_SELECTED_MESSAGES_SUCCESS:
            return {
                ...state,
                selectedMessages: action.payload,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: false,
            };
        case MessageActionTypes.DELETE_SELECTED_MESSAGES_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.UPDATE_ALL_SELECTED_MESSAGES_SUCCESS:
            return {
                ...state,
                selectedMessages: action.payload,
                selectAll: true,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: false,
            };
        case MessageActionTypes.UPDATE_ALL_SELECTED_MESSAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                selectAll: true,
            };
        case MessageActionTypes.UPDATE_SELECTED_MESSAGES_SUCCESS:
            return {
                ...state,
                selectedMessages: action.payload,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: false,
            };
        case MessageActionTypes.UPDATE_SELECTED_MESSAGES_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.SET_SELECT_ALL_SUCCESS:
            return {
                ...state,
                selectAll: action.payload,
            };
        case MessageActionTypes.SET_SELECT_ALL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.SET_MC_CONFIRMATION_MSG_SUCCESS:
            return {
                ...state,
                mcConfirmationMsg: action.payload,
            };
        case MessageActionTypes.SET_MC_CONFIRMATION_MSG_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.SOFT_DELETE_MESSAGE_START:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.SOFT_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                selectedMessages: [],
                selectAll: false,
                mcConfirmationMsg: action.payload.mcConfirmationMsg,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: true,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.SOFT_DELETE_MESSAGE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.READ_MC_MESSAGE_START:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.READ_MC_MESSAGE_SUCCESS:
            return {
                ...state,
                selectedMessages: [],
                selectAll: false,
                showMsgConfirmDialog: false,
                showSuccessMsgDialog: false,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.READ_MC_MESSAGE_ERROR:
            return {
                ...state,
                error: action.payload.isLoading,
                isLoading: action.payload.isLoading,
                lastEvaluatedKey: null,
            };
        case MessageActionTypes.SHOW_DELETE_CONFIRM_DIALOG_SUCCESS:
            return {
                ...state,
                showMsgConfirmDialog: action.payload,
            };
        case MessageActionTypes.SHOW_DELETE_CONFIRM_DIALOG_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.SHOW_SUCCESS_MSG_DIALOG_SUCCESS:
            return {
                ...state,
                showSuccessMsgDialog: action.payload,
                showMsgConfirmDialog: false,
            };
        case MessageActionTypes.SHOW_SUCCESS_MSG_DIALOG_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES:
            return {
                ...state,
                ...action,
                isLoading: action.payload.isLoading,
                isSuccess: false,
                isError: false,
            };
        case MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_SUCCESS:
            return {
                ...state,
                ...action,
                dashboardMessages: action.payload.messages,
                dashboardUnreadMessageCount: action.payload.unreadMessageCount,
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        case MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES_ERROR:
            return {
                ...state,
                ...action,
                isLoading: false,
                isSuccess: false,
                isError: true,
            };
        default:
            return state;
    }
}

export default messageCenterReducer;
