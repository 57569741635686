import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import TermsAndConditionsComponent from './TermsAndConditionsComponent';
import { localStateManagementActions} from "../../../../shared/Actions";



export const mapStateToProps = (state,/* , props */) => (
    {
      localStateData:state.localStateManagementReducerData,
      personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    }
  );


const mapDispatchToProps = {
  ...localStateManagementActions,
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(TermsAndConditionsComponent));
