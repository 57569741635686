import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const documents = [
    {
        formid: '1001',
        fileName: 'Statement-March-2020.pdf',
        accountDescriptor:'Roth ',
        creator: 'admin',
        accountnumber: '12345',
        docType: '',
        usaareceiveddate: 'Fri Jul 27 00: 00: 00 UTC 2018',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Mar 27 00: 08: 05 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Statement',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },

    {
        formid: '1002',
        fileName: 'Statement-Feb-2020.pdf',
        creator: 'admin',
        accountnumber: '',
        docType: '',
        usaareceiveddate: 'Fri Jul 27 00: 00: 00 UTC 2018',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Feb 21 00: 08: 05 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Statement',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1003',
        fileName: 'Statement-Jan-2020.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Fri Jul 27 00: 00: 00 UTC 2018',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Thu Jan 23 00: 08: 05 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Statement',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1004',
        fileName: 'Statement-Dec-2019.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Fri Jul 27 00: 00: 00 UTC 2018',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Dec 18 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Statement',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1005',
        fileName: 'TradeConfirmation-02022020.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Sun Feb 02 00: 00: 00 UTC 2020',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Sun Feb 02 00: 00: 00 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Trade_Confirmation',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1006',
        fileName: 'TradeConfirmation-01152020.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Sun Feb 02 00: 00: 00 UTC 2020',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Jan 15 00: 00: 00 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Trade_Confirmation',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1007',
        fileName: 'TradeConfirmation-01102020.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Sun Feb 02 00: 00: 00 UTC 2020',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Jan 10 00: 00: 00 UTC 2020',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Portfolio_Planner_Recommendation',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '1008',
        fileName: 'TradeConfirmation-03152019.pdf',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXX12345',
        docType: '',
        usaareceiveddate: 'Sun Feb 02 00: 00: 00 UTC 2020',
        usaamemberid: '020011136',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Mar 15 00: 00: 00 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Portfolio_Planner_Recommendation',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '301',
        fileName: '1099-B',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Sat Jul 28 00: 08: 05 UTC 2018',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '302',
        fileName: '1099-DIV',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Sat Mar 23 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '303',
        fileName: '1099-INT',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Tue Mar 19 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '304',
        fileName: '1099-R',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Mon Feb 18 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '305',
        fileName: '1099-MISC',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Jun 19 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '306',
        fileName: '1099-OID',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Sat Jul 20 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '307',
        fileName: 'FROM 5498',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Jul 12 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '308',
        fileName: 'FORM 5498-ESA',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Mon Nov 18 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '310',
        fileName: 'FORM 1042-S',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Mon May 13 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Tax_Document',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '401',
        fileName: 'Victory Integrity Discovery Fund',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Jan 9 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Prospectus',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '402',
        fileName: 'Victory RS Small Cap Growth Fund',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Aug 7 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Prospectus',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '403',
        fileName: 'Victory Trivalent International Small-Cap Fund',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Tue Oct 15 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Privacy_Notice',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '404',
        fileName: 'Vicotry INCORE Fund for Income',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Dec 11 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Terms_and_Condition',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '405',
        fileName: 'Victory Sophus Emerging Markets Fund',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Wed Jan 28 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Online_Service_Agreement',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '501',
        fileName: 'Year_End_Summary',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Mar 8 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Account_Maintenance_Letter',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '502',
        fileName: 'Q4_portfolio_distributions',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri May 10 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Account_Maintenance_Letter',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '503',
        fileName: 'Q3_Gains_Losses',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Fri Jul 26 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Account_Maintenance_Letter',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
    {
        formid: '504',
        fileName: 'May 2013 Simple IRA Contributions',
        creator: 'admin',
        accountnumber: 'ROTH IRA - XXXX12345',
        docType: '',
        usaareceiveddate: '',
        usaamemberid: '',
        expirationdate: '',
        usaadocumentcategory: 'MUTCONF',
        destdetails: '',
        esignrequired: '',
        createdDate: 'Sat Jul 28 00: 08: 05 UTC 2019',
        viewable: '',
        returnable: '',
        vcmTemplate: '',
        vcmid: '101',
        docCategory: 'Account_Maintenance_Letter',
        Signeddate: '',
        nodeId: 'c5dddbb5-2945-40e4-be9d-926b525ca804',
        contentType: 'application/pdf',
        dctmid: '0901118eb573f997',
        entity: '',
    },
];

const axiosMockAdapter = () => {
    const adapter = new MockAdapter(axios);

    return {
        mock: (apiCall, /* payload */) => {
            adapter
                .onPost(
                    'https://dev.api.vcm.com/documentcenter/document-api/search-document/document-center-search',
                )
                .reply(200, {
                    data: documents,
                });

            apiCall();
            adapter.restore();
        },
    };
};

export default axiosMockAdapter;
