import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container} from 'react-bootstrap';
import styled from 'styled-components';
import currencyFormatter from 'currency-formatter';
import { STEP_KEY } from '../../utils/AdobeAnalytics/Constants';
import { MaskText } from 'common';
import MaskAccountNumber from 'common/MaskAccountNumber/MaskAccountNumber';
import Accordian from '../Accordian';
import AccordianTitle from '../AccordianTitle';
import WButton from '../WButton/WButton';
import FundAccountCard from '../FundAccountCard';
import * as AccountSelectionConstants from '../Constants';
import WSpinner from '../WSpinner/WSpinner';
import {currencyFormatterFunction} from 'common/Formatter/CurrencyFormatter'

const assignObj = obj => {
    return obj;
};

const EmptyAccountsP = styled.p`
    font: 500 16px/22px Benton Sans;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledAccordianHeading = styled(Accordian)`
    margin-bottom: 3.75rem;
`;

const HiddenText = styled.div`
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;
const BtnWrap = styled(Container)`
    display: Flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;
    & > :nth-child(1) {
        margin-right: 50px;
    }
    @media (max-width: 767px) {
        & > :nth-child(1) {
            order: 2;
            margin-right: 0;
            button {
                margin: 0 !important;
            }
        }
        & > :nth-child(2) {
            order: 1;
            width: 100%;
            button {
                width: 100% !important;
            }
        }
        & > :not(:first-child) {
            margin-bottom: 20px;
        }
        flex-direction: column;
    }
`;

const MainDiv = styled.div`
    @media (max-width: 767px) {
        height: auto;
    }
    @media (min-width: 768px) {
        height: 90px;
    }
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;
const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};

/**
 * Account selection component - Displays the list of master registration account for a user
 *
 * Currently the payload is {companyNumber = '591'}
 */
class AccountSelectionAIP extends React.Component {
    constructor(props) {
        super(props);
        const { pageIndex } = this.props;
        this.state = {
            accountsAvailable: [],
            accountSelected: null,
            retrivedState: false,
            isLoading: false,
            isError: false,
            displayDetails: {
                accountSelection: {
                    titleTxt: 'Account Selection',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [],
                },
            },
        };
        this.accountSelectionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            savedState,
            masterRegistrationAccountData,
            accountDetails,
            addressValid,
            showMasterRegAddrChgModal,
        } = nextProps;

        const { accountsAvailable } = prevState;
        if (!prevState.retrivedState && savedState) {
            if (addressValid === false && showMasterRegAddrChgModal === true) {
                for (let i = 0; i < savedState.accountsAvailable.length; i += 1) {
                    for (let j = 0; j < savedState.accountsAvailable[parseInt(i, 10)].accounts.length; j += 1) {
                        savedState.accountsAvailable[parseInt(i, 10)].accounts[parseInt(j, 10)].checked = false;
                    }
                }
                return {
                    ...savedState,
                    retrivedState: true,
                    originalAccountDetails: accountDetails,
                    accountsAvailable: savedState.accountsAvailable.length
                        ? savedState.accountsAvailable
                        : accountDetails,
                    accountSelected: savedState.accountSelected.checked === false,
                };
            }
            return {
                ...savedState,
                retrivedState: true,
                originalAccountDetails: accountDetails,
                accountsAvailable: savedState.accountsAvailable.length ? savedState.accountsAvailable : accountDetails,
            };
        }

        if (addressValid === false && showMasterRegAddrChgModal === true) {
            for (let i = 0; i < accountsAvailable.length; i += 1) {
                for (let j = 0; j < accountsAvailable[parseInt(i, 10)].accounts.length; j += 1) {
                    accountsAvailable[parseInt(i, 10)].accounts[parseInt(j, 10)].checked = false;
                }
            }
            return {
                ...prevState,
                isLoading: masterRegistrationAccountData.isLoading,
                originalAccountDetails: accountDetails,
                accountsAvailable,
                accountSelected: prevState.accountSelected.checked === false,
            };
        }
        return {
            ...prevState,
            isLoading: masterRegistrationAccountData.isLoading,
            originalAccountDetails: accountDetails,
            accountsAvailable:
                prevState.originalAccountDetails === accountDetails && accountsAvailable.length
                    ? accountsAvailable
                    : accountDetails,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const {
            savedState,
            accountDetails,
            getAccountsList,
            transactionType,
        } = this.props;

        if (!savedState) {
            getAccountsList(
                {
                    companyNumber: AccountSelectionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                    token: localStorage.getItem('token'),
                },
                true,
                transactionType,
            );
        }
      
        if (!savedState) {
            this.setState({
                accountsAvailable: accountDetails,
            });
        }
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex, dataAnalyticsPage } = this.props;
        const { accountSelected } = this.state;
        sessionStorage.setItem(STEP_KEY, dataAnalyticsPage || 'Account Selection');

        if (accountSelected) {
            saveChildrenState(this.state, pageIndex);
        }
    }

    /**
     * Click handler when the user selects an account
     */
    onAccClick = (accounttypeindex, accountindex) => e => {
        e.stopPropagation();
        this.setState(prevState => {
            const { accountsAvailable, displayDetails } = prevState;
            const { accountSelection } = displayDetails;

            let accountSelected = null;
            const accountSelectedType = {};
            const displayData = [];
            const allAccounts = accountsAvailable.map((item, acctypeindex) => {
                const accType = { ...item };
                const { accounts } = accType;
                accType.accounts = accounts.map((eachacc, accindex) => {
                    const acc = { ...eachacc };
                    if (accounttypeindex === acctypeindex && accountindex === accindex) {
                        acc.checked = !acc.checked;
                    } else {
                        acc.checked = false;
                    }
                    if (acc.checked) {
                        accountSelected = acc;
                        const accountNameDisplay =
                            !acc.accountName || acc.accountName.trim() === ''
                                ? `${!acc.accountType ? '' : acc.accountType} Account ${acc.accountNumber}`
                                : acc.accountName;
                        accountSelected.accountType = acc.accountType;
                        displayData.push(
                            {
                                field: AccountSelectionConstants.ACCOUNT_NAME,
                                value: accountNameDisplay,
                            },
                            {
                                field: AccountSelectionConstants.ACCOUNT_NUMBER,
                                formattedValue: acc.accountNumber && (
                                    // <MaskText text={acc.accountNumber.toString()} startIndex={0} maskLength={6} />
                                    <MaskAccountNumber text={acc.accountNumber.toString()}/>
                                ),
                                value: acc.accountNumber.toString(),
                            },
                            {
                                field: AccountSelectionConstants.ACCOUNT_TYPE_TEXT,
                                // TODO: Remove the hardcoded account type
                                value: acc.accountType ? acc.accountType : 'Individual',
                            },
                        );
                    }
                    return acc;
                });
                return accType;
            });
            accountSelection.data = displayData;
            // TODO :: Add account type to accountSelectedType for furture use and set it in state
            accountSelectedType.holdingGroup = accountsAvailable[parseInt(accounttypeindex, 10)].holdingGroupName;
            return {
                accountsAvailable: allAccounts,
                accountSelected,
                displayDetails,
                accountSelectedType,
            };
        });
    };

    /**
     * Formatting the master reg account details for display
     */
    getCardDetails = (eachacc, isTransaction, content) => {
        const cardDetails = [];
        const currentValue = eachacc.currentAccountBalance ? Number(+eachacc.currentAccountBalance).toFixed(2) : '0.00';
        const holding = eachacc.currentAccountBalance ? Number(+eachacc.totalShares).toFixed(2) : '0.00';
        if (isTransaction) {
            cardDetails.push(
                {
                    text: content.currentValTxt,
                    value: `${currencyFormatter.format(currentValue, {
                        locale: 'en-US',
                    })}`,
                },
                {
                    text: content.holdingTxt,
                    value: holding,
                },
                {
                    text: content.planNameTxt,
                    value: eachacc.pad === 'N' ? 'No' : 'Yes',
                },
            );
        } else {
            const currentValue = eachacc.currentValue ? Number(+eachacc.currentValue).toFixed(2) : '0.00';
            cardDetails.push(
                {
                    text: content.currentValTxt,
                    value: `${currencyFormatterFunction(currentValue)}`,
                },
                {
                    text: content.planNameTxt,
                    value: eachacc.count,
                },
            );
        }
        return cardDetails;
    };

    render() {
        const { accountsAvailable, accountSelected, isLoading } = this.state;
        const {
            content,
            isTransaction,
            onSwitchComponent,
            pageIndex,
            masterRegistrationAccountData,
            handleError,
            setCancelLiquidation,
            setCancelExchange,
            transactionType,
            dataAnalyticId,
            hasBottomNavCancel,
            navigationObj,
            flowType
        } = this.props;
        let cardDetails;
        if (masterRegistrationAccountData.isError) {
            handleError(true);
        }

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <HiddenText>{AccountSelectionConstants.NO_ACCOUNT_SELECTED_ERROR_TEXT}</HiddenText>
                {!isLoading && accountsAvailable.length === 0 && (
                    <>
                        <EmptyAccountsP>{AccountSelectionConstants.NO_ACCOUNTS_MESSAGE}</EmptyAccountsP>
                    </>
                )}

                <div className="container">
                    {accountsAvailable.map((item, acctypeindex) => (
                        <StyledAccordianHeading
                            ref={this.accountSelectionRef}
                            key={`${item.holdingGroupName}-${acctypeindex}`}
                            titleChild={
                                <AccordianTitle
                                    accType={item.holdingGroupName}
                                    id={item.holdingGroupName}
                                    accIndex={acctypeindex}
                                />
                            }
                            titleSeperator
                            labelledBy={item.accountType}
                        >
                            <div>
                                {(flowType !=undefined && flowType == AccountSelectionConstants.AIP ) ? (
                                    <>
                                    {item.accounts.map((eachacc, accindex) => {
                                    cardDetails = this.getCardDetails(eachacc, isTransaction, content);
                                    return (
                                        <>
                                        {eachacc.accountStatus == 'O' && (
                                        <FundAccountCard
                                            key={`${eachacc.accountNumber}-${accindex}`}
                                            accountName={
                                                !eachacc.accountName || eachacc.accountName === ''
                                                    ? `${!eachacc.accountType ? '' : eachacc.accountType} Account ${
                                                          eachacc.accountNumber
                                                      }`
                                                    : eachacc.accountName
                                            }
                                            accountNumber={eachacc.accountNumber}
                                            details={cardDetails}
                                            onClick={this.onAccClick(acctypeindex, accindex)}
                                            selected={!!eachacc.checked}
                                        />
                                        )}
                                        </>
                                    );
                                })}
                                    </>
                                 ) : (

                                    <>
                                  {item.accounts.map((eachacc, accindex) => {
                                    cardDetails = this.getCardDetails(eachacc, isTransaction, content);
                                    return (
                                        <>
                                       
                                        <FundAccountCard
                                            key={`${eachacc.accountNumber}-${accindex}`}
                                            accountName={
                                                !eachacc.accountName || eachacc.accountName === ''
                                                    ? `${!eachacc.accountType ? '' : eachacc.accountType} Account ${
                                                          eachacc.accountNumber
                                                      }`
                                                    : eachacc.accountName
                                            }
                                            accountNumber={eachacc.accountNumber}
                                            details={cardDetails}
                                            onClick={this.onAccClick(acctypeindex, accindex)}
                                            selected={!!eachacc.checked}
                                        />
                                        
                                        </>
                                    );
                                })}
                                    </>

                                )}

                            </div>
                        </StyledAccordianHeading>
                    ))}
                </div>


                <MainDiv>
                    <BtnWrap>

                        {hasBottomNavCancel && (
                            <div>
                                <WButton
                                    id="desktopCancelButton"
                                    variant="cancel"
                                    buttontext="Cancel"
                                    onClick={navigationObj.cancelClick}
                                    buttonstyle={assignObj({
                                        float: 'left',
                                        display: 'block',
                                        ...navigationObj.cancelStyle,
                                    })}
                                    dataAnalyticId={`${dataAnalyticId}_cancel`}
                                />
                            </div>
                        )}
                        {!hasBottomNavCancel && (
                            <div>
                                <WButton
                                    variant="cancel"
                                    buttontext="Cancel"
                                    id="cancelButton"
                                    buttonstyle={assignObj(cancelButtonStyles)}
                                    onClick={transactionType === 'exchange' ? setCancelExchange : setCancelLiquidation}
                                    dataAnalyticId={`${dataAnalyticId}_cancel`}
                                    waitforapiresponse={true}
                                />
                            </div>
                        )}
                        <div style={assignObj({
                                marginRight: '20px',
                            })}>
                            <WButton
                                className="nextBt"
                                disabled={!accountSelected}
                                buttontext={AccountSelectionConstants.NEXT_TEXT}
                                onClick={onSwitchComponent(true, this.state, pageIndex)}
                                data-test="next-button"
                                dataAnalyticId={`${dataAnalyticId}_next`}
                            />
                        </div>
                    </BtnWrap>
                </MainDiv>
            </>
        );
    }
}

export const mapStateToProps = state => {
    return {
        masterRegistrationAccountData: state.masterRegistrationAccountData,
    };
};
AccountSelectionAIP.propTypes = {
    saveChildrenState: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
        arialabelback: PropTypes.string,
        arialabelnext: PropTypes.string,
    }),
    pageIndex: PropTypes.number,
    transactionType: PropTypes.string,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    accountDetails: PropTypes.instanceOf(Object),
    content: PropTypes.instanceOf(Object),
    isTransaction: PropTypes.bool,
    masterRegistrationAccountData: PropTypes.instanceOf(Object),
    getAccountsList: PropTypes.func,
    onSwitchComponent: PropTypes.func,
    handleError: PropTypes.func,
    setCancelLiquidation: PropTypes.func,
    setCancelExchange: PropTypes.func,
    dataAnalyticId: PropTypes.string,
    dataAnalyticsPage: PropTypes.string,
    addressValid: PropTypes.bool,
    showMasterRegAddrChgModal: PropTypes.bool,
};
AccountSelectionAIP.defaultProps = {
    saveChildrenState: () => {},
    savedState: null,
    editSection: null,
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        portalRoot: null,
        arialabelback: '',
        arialabelnext: '',
    },
    pageIndex: 0,
    transactionType: 'liquidate',
    accountDetails: [],
    content: {},
    isTransaction: false,
    masterRegistrationAccountData: {},
    getAccountsList: () => {},
    onSwitchComponent: () => {},
    handleError: () => {},
    setCancelLiquidation: () => {},
    setCancelExchange: () => {},
    dataAnalyticId: '',
    dataAnalyticsPage: '',
    addressValid: true,
    showMasterRegAddrChgModal: false,
};

export default connect(mapStateToProps)(AccountSelectionAIP);
