/**
 * Account Type Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component is used for allowing the user to select account type like individual,Joint etc.
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [14/02/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason added VD styles
 */


import * as ActionTypes from '../../../shared/ReduxConstants/ServiceActionConstants'; 
import AccGlobalConstants from '../AccountManagementConstants';
import consts from './consts';
import { getToken, getRole } from 'utils';
import ListSec from './ListSec';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import SpecialtyAccountHeading from './SpecialtyAccountHeading';
import {
    WStepper,
    CommonButtons,
    WSaveModal,
    WMessageBar,
    WSpinner,
    WAlertComponent,
    WBreadCrumb,
    ConfirmModal,
} from '../../../common';
import WStepperMobile from '../../../common/WStepper/WStepperMobile';
import { ConfirmationMessage } from 'modules/CustomerManagementModule/components';
import { getTokenFromSession } from 'shared/Helpers/Utils';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/marketOpenAccount', name: 'Open a Marketplace Account' },
];

const assignObj = obj => {
    return obj;
};

const nextRedirect = (accTypeOrSubtypeSelected, accTypeKey) => {

    if (accTypeKey === 'spec_acct') {
        return '/specialtyAccountApplicationForms';
    }
    if (accTypeOrSubtypeSelected === 'Simple IRA' ) {
        return '/specialtyAccountApplicationForms';
    }
    switch (accTypeOrSubtypeSelected) {
        case 'rollover':
        case '403b':
        case 'simple':
            return '/agreement';
        default:
            return '/marketPersonalInfo';
    }
};

class MarketAccountTypeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accTypeDetails: [],
            accTypeSelected: '',
            showErrorMsg: false,
            loading: false,
            next: false,
            back: false,
            cancel: false,
            showSaveModal: false,
            errorFromMasterDataApi: false,
            error: {},
            showAlert: false,
            errorMessage: '',
            showConfirmCancelModal: false,
            profileStatusSaveCount: 0,
            selectedAccountType:'',
            renderCount: 0,
            isApiFailed: false
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accOpeningData,marketLocalStateData } = nextProps;

        return {
            ...prevState,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
            loading: accOpeningData.isLoading,
            accTypeSelected: prevState.accTypeSelected ? prevState.accTypeSelected : marketLocalStateData?.marketNAOInfo?.account?.accountType,


        };
    }

    componentDidMount() {
        document.title = `Account Type: Open Account | Victory Capital`;
        sessionStorage.setItem("isRefresh",true);
        const {
            getAccountSubTypes,
            localStateData,
            history,
            getCustomerProfile,
            getTradingClosureWindow,
            setAccountNickName,
            manageLocalState,
            resetProfileBasicInformations,
            getCompositeData,
            getMemberProfileInformation,
            marketLocalStateData
        } = this.props;

        const role = sessionStorage.getItem("role");
        const datakey = history.location.state ? history.location.state.datakey : '';
        
        getTradingClosureWindow({ token: getToken() });

        if (localStateData.masterDataLoadedForAccType !== undefined) {
            this.setState({ masterDataLoadedForAccType: localStateData.masterDataLoadedForAccType });
        }

        if (role === 'Member') {
            getMemberProfileInformation()
        } else {
            getMemberProfileInformation()
            const payLoad = {
                payloadData : {"loginLatitude":"","loginLongitude":"","deviceId":""}};
            getCustomerProfile(payLoad);
            resetProfileBasicInformations();
        }
        if (localStateData.marstoneFlag === true) {
            setAccountNickName(localStateData.OpenAccPageTwo.accountNickName);
            if (localStateData.accIDSelected === 'spec_acct') {
                manageLocalState({ accountTypeKey: 'trust' });
            }
        }
            
            const marketNAOSavedReducerAccountType = this.props?.marketLocalStateData?.marketNAOInfo?.account?.accountType

            let subAccTypeFlag = 0;
        

            if (localStateData.accIDSelected) {
                const apikey = localStateData.accIDSelected || datakey;
                const payload = [apikey];
                getAccountSubTypes(payload);
                subAccTypeFlag=1;
                this.setState({
                    selectedAccountType:localStateData.accSelected
                })
            }

            if(subAccTypeFlag == 0)
            {
                if(marketNAOSavedReducerAccountType != ''){
                
                    const apikey = marketNAOSavedReducerAccountType.includes('IRA') ?'ira_brkg_acct' : 'gen_inv_brkg_acct_v1' || datakey;
                    const payload = [apikey];
                    getAccountSubTypes(payload);

                    this.setState({
                        selectedAccountType : marketNAOSavedReducerAccountType.includes('IRA') ? 'Individual Retirement Account' : 'General Investment Account'
                    })
    
                }
            }
           
            getCompositeData({ data: { datakey: consts.compositePayload },
                 token: getTokenFromSession() ,
                marketNAOFlow:"marketNaoFlow"});
    }

    redirect = (accSelected) => {

    }

    setProfileInformationToLocalStorage = () =>
    {
        if(this.state.profileStatusSaveCount == 0 || this.state.profileStatusSaveCount == 1)
        {

        
        const {memberProfileInformation} = this.props.profileInformationData;
        const {updateMarketNAOInfo} = this.props;
        let personalInfoPrimaryObj = {
            citizenship:memberProfileInformation?.citizenship,
            countryOfCitizenship: memberProfileInformation?.countryOfCitizenship,
            countryOfCitizenshipCode: memberProfileInformation?.citizenship,
            dob: memberProfileInformation?.role == 'Member' ? memberProfileInformation?.m_dateOfBirth : "",
            firstName: memberProfileInformation?.firstName,
            gender: memberProfileInformation?.gender,
            lastName: memberProfileInformation?.lastName,
            maritalStatus: memberProfileInformation?.maritalStatus,
            maskedDob: memberProfileInformation?.role == 'Member' ? memberProfileInformation?.m_dateOfBirth : "",
            maskedSSN:memberProfileInformation?.role == 'Member' ?  memberProfileInformation?.m_ssnTin:"",
            middleInitial: memberProfileInformation?.middleInitial,
            prefix: memberProfileInformation?.prefix,
            ssn:memberProfileInformation?.role == 'Member' ?  memberProfileInformation?.m_ssnTin:"",
            suffix: memberProfileInformation?.suffix,
            taxIdIssuingCountry: ""
        }

        const saveToLocalPersonalInfoPayload = {
            sectionToBeUpdated:'personalInfo',
            state: personalInfoPrimaryObj,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalPersonalInfoPayload);

        if(memberProfileInformation?.addressInformation.length > 0)
        {
            let mailingAddress = null;
            let physicalAddress = null;
            if(memberProfileInformation?.addressInformation[0]?.isPhysicalAddress == true)
            {
                  physicalAddress = {
                    addressLine1: memberProfileInformation?.addressInformation[0].addressLine1,
                    addressLine2: memberProfileInformation?.addressInformation[0].addressLine2,
                    city: memberProfileInformation?.addressInformation[0].city,
                    mailingAddressType: memberProfileInformation?.addressInformation[0].addressType,
                    state:  memberProfileInformation?.addressInformation[0].state,
                    zipCode: memberProfileInformation?.addressInformation[0].zip,
                    isMailingAddress:false,
                    isPhysicalAddress: true
                }
            }

            if(memberProfileInformation?.addressInformation[1]?.isMailingAddress == true)
            {
                 mailingAddress = {
                    addressLine1: memberProfileInformation?.addressInformation[1].addressLine1,
                    addressLine2: memberProfileInformation?.addressInformation[1].addressLine2,
                    city: memberProfileInformation?.addressInformation[1].city,
                    mailingAddressType: memberProfileInformation?.addressInformation[1].addressType,
                    state:  memberProfileInformation?.addressInformation[1].state,
                    zipCode: memberProfileInformation?.addressInformation[1].zip,
                    isMailingAddress:true,
                    isPhysicalAddress: false
                }
            }

            const addressInfo = {
                physicalAddress :physicalAddress,
                mailingAddress : mailingAddress,
                isPhysicalAddressSame:"Yes"
            }

            const saveToLocalAddressPayload = {
                sectionToBeUpdated:'addressInfo',
                state: addressInfo,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalAddressPayload);

        }

        if(memberProfileInformation?.emailInformation)
        {
            const contactInfo = {
                emailAddresses: {
                    maskedPrimaryEmailAddress: "",
                    maskedSecondaryEmailAddress: "",
                    primaryEmailAddress: memberProfileInformation.emailInformation?.primaryEmail,
                    secondaryEmailAddress:memberProfileInformation.emailInformation?.secondaryEmail
                },
                phoneInfo:{
                    primary:{
                        countryCode: memberProfileInformation.phoneInformation[0]?.phoneCountryCode,
                        isMobile: memberProfileInformation.phoneInformation[0]?.isMobile,
                        number: memberProfileInformation.phoneInformation[0]?.phoneNumber,
                        mPhoneNumber:"",

                    },
                    secondary:{
                        countryCode: memberProfileInformation.phoneInformation[1]?.phoneCountryCode,
                        isMobile: memberProfileInformation.phoneInformation[1]?.isMobile,
                        number: memberProfileInformation.phoneInformation[1]?.phoneNumber,
                        mPhoneNumber:"",
                    }
                }
    
            }

            const saveToLocalContactPayload = {
                sectionToBeUpdated:'contactInfo',
                state: contactInfo,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalContactPayload);
    
        }
        if(memberProfileInformation?.employmentInformation)
        {
            const empInfoData = {
                address:{
                    addressLine1: memberProfileInformation?.employmentInformation?.addressLine1,
                    addressLine2: memberProfileInformation?.employmentInformation?.addressLine2,
                    city: memberProfileInformation?.employmentInformation?.employersCity,
                    state: memberProfileInformation?.employmentInformation?.employersState,
                    zipCode: memberProfileInformation?.employmentInformation?.employersZipcode,
                },
                employerName:memberProfileInformation?.employmentInformation?.employerName,
                industry: memberProfileInformation?.employmentInformation?.industry,
                industryDescription: memberProfileInformation?.employmentInformation?.industryDescription,
                occupation: memberProfileInformation?.employmentInformation?.occupation,
                otherEmployment: memberProfileInformation?.employmentInformation?.empStatusOther,
                primarySource:memberProfileInformation?.employmentInformation?.primarySourceOfIncome,
                status: memberProfileInformation?.employmentInformation?.employmentStatus
              }

            const saveToLocalEmploymentPayload = {
                sectionToBeUpdated:'employmentInfo',
                state: empInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalEmploymentPayload);
        }

        if(memberProfileInformation?.financialInformation)
        {
            const finInfoData = {
                annualIncome: memberProfileInformation?.financialInformation?.annualIncome,
                netWorth: memberProfileInformation?.financialInformation?.netWorth,
                taxBracket: memberProfileInformation?.financialInformation?.taxBracket,
                taxFilingStatus: memberProfileInformation?.financialInformation?.taxFilingStatus
            }

            const saveToLocalFinancialPayload = {
                sectionToBeUpdated:'financialInfo',
                state: finInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalFinancialPayload);
        }


        if(memberProfileInformation?.militaryInformation)
        {
            const milInfoData = {
                branch: memberProfileInformation?.militaryInformation?.branchOfService,
                commissionSource: memberProfileInformation?.militaryInformation?.commissionCourse,
                startDate: memberProfileInformation?.militaryInformation?.fromDate,
                endDate:  memberProfileInformation?.militaryInformation?.toDate,
                hasServed: memberProfileInformation?.militaryInformation?.currServingUSM == true ? "Y":"N",
                militaryStatus: memberProfileInformation?.militaryInformation?.milityStatus,
                rank: memberProfileInformation?.militaryInformation?.rank,
                otherBranch:"",
                otherMilitaryStatus:""
            }

            const saveToLocalMilitaryPayload = {
                sectionToBeUpdated:'militaryInfo',
                state: milInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalMilitaryPayload);
        }

    }

    }


    componentDidUpdate(prevProps /* , prevState */) {
        const { accOpeningData, manageLocalState, localStateData, getAccountSubTypes, profileInformationData } = this.props;
        if (this.props !== prevProps) {
           this.updateState(prevProps);
        }
        if (prevProps.accOpeningData.isLoadingApplication !== accOpeningData.isLoadingApplication) {
            manageLocalState({ ...accOpeningData.application });
        }
        if (prevProps.localStateData.accIDSelected !== localStateData.accIDSelected) {
            const apikey = localStateData.accIDSelected;
            const payload = [apikey];
            getAccountSubTypes(payload);
        }

        const {memberProfileInformation} = this.props.profileInformationData;
        const {marketLocalStateData} = this.props;
    
        if(memberProfileInformation != undefined && memberProfileInformation != null
         && Object.keys(memberProfileInformation).length > 0 && this.state.profileStatusSaveCount == 0 && 
        marketLocalStateData?.marketNAOInfo?.personalInfo?.primary?.firstName == "")
        {
            this.setState({
                profileStatusSaveCount:this.state.profileStatusSaveCount + 1
            })
            this.setProfileInformationToLocalStorage();
        }

        // Check if there is any post api call errors, else move next page
        if(this.props.marketLocalStateData.isaccountApiError && this.state.renderCount == 1){
            this.state.renderCount = 2
            this.setState({isApiFailed: true})
            this.props.clearAPIStatus('account')

        }else if(this.props.marketLocalStateData.isaccountApiSuccess  && this.state.renderCount == 1 && profileInformationData != undefined && profileInformationData != null){
            
            // if we get any information from profile api then assign and move next else Just move to next page 
            if(memberProfileInformation && Object.keys(memberProfileInformation)?.length > 0 )
            {
                this.setProfileInformationToLocalStorage();

                this.setState({ next: true }, () => {
                    this.saveToLocal();
                });
            }else{
                this.setState({ next: true }, () => {
                    this.saveToLocal();
                });
            }
          
        }else if(this.props.marketLocalStateData.isaccountApiSuccess  && this.state.renderCount == 1){
            this.setState({ next: true }, () => {
                this.saveToLocal();
            });

        }else if(this.props.marketLocalStateData.marketNAOInfo.isRedirectionFlow  && this.state.renderCount == 1){
            this.setState({ next: true }, () => {
                this.saveToLocal();
            });
        }

    }

    componentWillUnmount() {
        sessionStorage.setItem("isRefresh",false);
    }

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    updateState = prevProps => {
        const { masterLookupStateData, accOpeningData } = this.props;
        const didUpdateStateData = { ...this.state };
        // Account Sub Types
        if (
            accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES] !==
                prevProps.accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES]
        ) {
            let accTypeDetails = {};
            const accTypeDetailsTemp = accOpeningData[ActionTypes.GET_ACCOUNT_SUBTYPES];
            if (accTypeDetailsTemp.key !== 'ira_brkg_acct') {
                accTypeDetails.key = accTypeDetailsTemp.key;
                accTypeDetails.value = [accTypeDetailsTemp];
            } else {
                accTypeDetails = accTypeDetailsTemp;
            }
            didUpdateStateData.accTypeDetails = accTypeDetails;
        }

        // Loading
        if (masterLookupStateData.isLoading && accOpeningData.isLoading) {
            didUpdateStateData.loading = true;
        } else if (!masterLookupStateData.isLoading && !accOpeningData.isLoading) {
            didUpdateStateData.loading = false;
            didUpdateStateData.masterDataLoadedForAccType = true;
        }
        // Error Handling
        this.handleAPIError(masterLookupStateData, accOpeningData, didUpdateStateData);

        //   Save Function Values Start
        const saveState = this.handleSaveAPI(accOpeningData, prevProps, didUpdateStateData);

        this.setState({ ...didUpdateStateData, ...saveState });
    };

    // Refactored for sonar issue
    handleSaveAPI = (accOpeningData, prevProps, didUpdateStateData) => {
        const data = didUpdateStateData;
        if (
            didUpdateStateData &&
            accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT &&
            accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT !== prevProps.accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT
        ) {
            if (accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.status) {
                data.showSaveModal = true;
            } else {
                const msg =
                    accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.message ||
                    accOpeningData.ACCT_TYPE_SAVE_OPENING_ACCT.errorMessage;
                data.showAlert = true;
                data.isErrorBar = true;
                data.errorMessage = { info: msg };
            }
            data.loading = false;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        return { ...data };
    };

    // Refactored for sonar issue
    handleAPIError = (masterLookupStateData, accOpeningData) => {
        // Master Data Error
        if (masterLookupStateData.isError) {
            this.setState({ errorFromMasterDataApi: true });
        } else if (!masterLookupStateData.isError) {
            this.setState({ errorFromMasterDataApi: false });
        }
        if (accOpeningData.response) {
            const { message = '', errorMessage = '' } = accOpeningData.response;
            const msg = message || errorMessage;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    getAccountTypeCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };

    handleCardClick = (id, key) => e => {
        e.preventDefault();
        const { screenType } = this.props;
        const isMobile = screenType === 'xs' || screenType === 'sm';
        
        const {
            localStateData,
            manageLocalState,
        } = this.props;
        const { accIDSelected = null } = localStateData;
        if(isMobile && accIDSelected === 'spec_acct') return;

        if (localStateData.marstoneFlag === true && localStateData.accountType === 'Joint Account') {
            manageLocalState({
                accountType: id,
                OpenAccPageTwo: { ...localStateData.OpenAccPageTwo, accountType: id },
                [id]: { ...localStateData[localStateData.accountType] },
                [localStateData.accountType]: {},
            });
        }

        if (localStateData.marstoneFlag === true && localStateData.accountType === 'Money Market') {
            manageLocalState({
                accountType: id,
                OpenAccPageTwo: { ...localStateData.OpenAccPageTwo, accountType: id },
                [id]: { ...localStateData[localStateData.accountType] },
                [localStateData.accountType]: {},
            });
        }

        this.setState({
            accTypeSelected: id,
            disableNext: false,
            showErrorMsg: false,
        });
        if (accIDSelected === 'spec_acct') {
            manageLocalState({
                accountTypeKey: key,
            });
        }

        if (accIDSelected === 'ira_brkg_acct') {
            manageLocalState({
                accountTypeKey: key,
            });
        }
    };

    handleNextClick = e => {
        const { accTypeSelected } = this.state;
        const { setCustomerVerificationStatus, postMarketNAOInfo, marketLocalStateData } = this.props;
        const {updateMarketNAOInfo} = this.props;

        setCustomerVerificationStatus();
        e.preventDefault();
        if (accTypeSelected && accTypeSelected !== '') {

            // API call for accountid
                   // TODO
            // if its redirection flow then dont call accounts api
            if(accTypeSelected != marketLocalStateData.marketNAOInfo.account.accountType){

                // Check if account type selected is same
                if(!marketLocalStateData.marketNAOInfo.isRedirectionFlow ){

                    // Cjheck if not not redirecton flow and make API call for accountid
                    const payload = {
                        pageNumber: 1,
                        source: "web",
                        account: {
                        accountType : accTypeSelected,
                        userID: "",
                        marketingRefCode: "",
                        investmentAdvisorID: ""
                        }
                    }

                    postMarketNAOInfo({
                        url: 'account',
                        payloadData: payload
                    })
        
                    const saveToLocalpayload = {
                        sectionToBeUpdated:'account',
                        state: {
                            accountType : accTypeSelected,
                            userID: "0000",
                            marketingRefCode: "0000",
                            investmentAdvisorID: "0000"
                        },
                    }
        
                    updateMarketNAOInfo(saveToLocalpayload)
                    this.setState({renderCount: 1})
                }  
           
            }

            else{
                this.setState({renderCount: 1})
            }

        } else if (!accTypeSelected) {
            this.setState({ showErrorMsg: true },() =>{
                const alertWrapperEl =  document.querySelector('.alertWrapper');
                alertWrapperEl.tabIndex = "-1";
                alertWrapperEl.removeAttribute("role");
                document.getElementsByClassName("alertWrapper")[0].focus();
            });
            
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleBackClick = e => {
        e.preventDefault();
        this.saveToLocal();
        this.setState({ back: true });
    };

    handleSaveClick = e => {
        e.preventDefault();
        const { accTypeSelected } = this.state;
        if (accTypeSelected && accTypeSelected !== '') {
            this.saveToLocal('save');
        } else if (!accTypeSelected) {
            this.setState({ showErrorMsg: true });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history, initialState } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = getRole(initialState && initialState.getCustomerProfile);
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };


    handleExitNew = () => {
        this.setState({isApiFailed: false})
    }


    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    saveToLocal = (actionType = '') => {
        const { manageLocalState, initialState, saveApplication, localStateData } = this.props;
        const { masterDataLoadedForAccType, accTypeSelected, accTypeDetails } = this.state;
        const data = {
            companyNumber: '591',
            onlineId: initialState.onlineId,
            customerId: initialState.currentPIN,
            accountType: accTypeSelected,
            accTypeDetails,
            masterDataLoadedForAccType,
        };
        const payLoad = {
            companyNumber: '591',
            onlineId: initialState.onlineId,
            customerId: initialState.currentPIN,
            accountType: accTypeSelected,
        };
        if (actionType === 'save') {
            saveApplication({
                token: getToken(),
                saveApplicationPayload: {
                    accountType: accTypeSelected,
                    payload: { ...localStateData, OpenAccPageOne: payLoad, ...data },
                },
            });
        } else {
            manageLocalState({ OpenAccPageOne: payLoad, ...data });
            sessionStorage.setItem('accountTypeSelected', accTypeSelected);
        }
    };

    render() {
        const { errorFromMasterDataApi, error, showErrorMsg } = this.state;

        const { marketAccountOpeningPages, accountTypeBackLabel, marketAccountOpeningPagesIncFunding } = AccGlobalConstants;
        const radioInstruction = 'Please select any one of the options';
        if (errorFromMasterDataApi) {
            throw new Error(error);
        }

        const {
            accTypeDetails = [],
            accTypeSelected,
            showSaveModal,
            next,
            cancel,
            back,
            errorMessage,
            showAlert,
            isErrorBar,
            loading,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
            selectedAccountType
        } = this.state;
        const accTypeDetailsArr = accTypeDetails.value;
        const hasFundingStep = this.props.memberDashboardData.isEnableFeatureSuccess && this.props?.memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false
        const pagesForStepper = hasFundingStep ? marketAccountOpeningPagesIncFunding : marketAccountOpeningPages;
        const isSpecialtyAccount = accTypeDetails.key === 'spec_acct';

        const { screenType,profileInformationData } = this.props;
        const isMobile = screenType === 'xs' || screenType === 'sm';
        
        const isLoading = loading || this.props.marketLocalStateData.isLoading


        return (
            <>
                {' '}
                {isLoading && <WSpinner loading={isLoading} />}
                {showConfirmModal && (
                   
                    <ConfirmationMessage
                         modalTitle="Save Application"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                        show={showConfirmModal}
                    />
                )}
                {showConfirmCancelModal && (
                   
                    <ConfirmationMessage
                    modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                    modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                    primaryButtonText="Ok"
                    onPrimaryClick={this.handleExit}
                    secondaryButtonText="Cancel"
                    onSecondaryClick={this.handleCancelNo}
                    show={showConfirmCancelModal}
                    />
                )}

                {this.state.isApiFailed && (
                    <ConfirmModal
                        modalTitle={"Error"}
                        modalBodyText={this.props.marketLocalStateData.postStatusMsg}
                        primaryButtonText="OK"
                        onPrimaryClick={this.handleExitNew}
                    />
                  
                )}


                <div>
                    <div style={consts.wrapperStyles} className="accountTypePage">
                        {showAlert && (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        )}
                        
                        <div className="container mobile-cont accMngtContainer">
                            {!isSpecialtyAccount ? (
                            <WStepperMobile currentPage={0} pages={pagesForStepper}
                            />
                            ) : (
                                ''
                            )}
                            <WBreadCrumb 
                                activeCrumb={selectedAccountType}
                                breadCrumbItems={ breadCrumURL}
                            />
                            {!isSpecialtyAccount && (
                                <>
                                    <div className="mobileRow container accMgntHeading" noGutters>
                                        <h1 role={window.innerWidth > 767 ? 'heading' : 'presentation'} aria-level={window.innerWidth > 767 ? '1' : '2'} style={consts.pageHeaderStyle} acctype={accTypeDetails.key}>
                                        </h1>
                                    </div>
                                    <WStepper className="desktopStepper" style={consts.stepperStyle} currentPage={0} pages={pagesForStepper} />
                                </>
                            )}
                            {isSpecialtyAccount && (
                                <SpecialtyAccountHeading specialtyText="selectedAccountType">
                                    {selectedAccountType}
                                </SpecialtyAccountHeading>
                            )}

                            <WAlertComponent
                                show={showErrorMsg}
                                alertmsg={consts.radioError}
                                className="alertWrapper"
                            />
                            <Row className="mobileRow" role="radiogroup" aria-labelledby="instructionMessage01">
                                <span className="sr-only" id="instructionMessage01">{radioInstruction}</span>
                          
                                <ListSec
                                    accTypeDetailsArr={accTypeDetailsArr}
                                    accTypeSelected={accTypeSelected}
                                    accTypeDetailsKey={accTypeDetails.key}
                                    handleCardClick={this.handleCardClick}
                                    profileInformationData={profileInformationData}
                                />
                            </Row>

                            <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                        </div>
                        <CommonButtons
                            nextClick={this.handleNextClick}
                            backClick={this.handleBackClick}
                           
                            arialabelback={accountTypeBackLabel}
                            cancelOffset={assignObj({
                                span: 2,
                                offset: 6,
                            })}
                            saveDisable={
                                accTypeSelected === 'Simple IRA' ? true : false
                            }
                            hideNext={ isSpecialtyAccount && isMobile}
                            isShowBtn={true}
                            disabled={false}
                        />

                        {next && <Redirect to={{pathname: nextRedirect(accTypeSelected, accTypeDetails.key) ,state:{accTypeSelected:accTypeSelected}}} />}
                        {cancel && <Redirect to="/" />}
                        {back && <Redirect to="/marketOpenAccount" />}
                    </div>
                </div>
            </>
        );
    }
}

MarketAccountTypeComponent.propTypes = {
    saveApplication: PropTypes.func,
    getAccountSubTypes: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    clearLocalStateForNAO: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    accOpeningData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    location: PropTypes.shape({
        state: PropTypes.shape({
            accSelected: PropTypes.string,
            accIDSelected: PropTypes.string,
            onlineId: PropTypes.string,
            customerId: PropTypes.string,
        }),
    }),
    history: PropTypes.instanceOf(Object),
    resetShowConfirmModal: PropTypes.func,
    uuid: PropTypes.string,
    getProfileBasicInformations: PropTypes.func,
    resetProfileBasicInformations: PropTypes.func,
    getCustomerProfile: PropTypes.func,
    setCustomerVerificationStatus: PropTypes.func,
    getTradingClosureWindow: PropTypes.func,
    setAccountNickName: PropTypes.func,
    screenType: PropTypes.string,
};

MarketAccountTypeComponent.defaultProps = {
    initialState: {},
    masterLookupStateData: {},
    saveApplication: () => {},
    getAccountSubTypes: () => {},
    location: {},
    localStateData: {},
    manageLocalState: () => {},
    accOpeningData: {},
    clearLocalStateForNAO: () => {},
    history: {},
    resetShowConfirmModal: () => {},
    uuid: '',
    getProfileBasicInformations: () => {},
    resetProfileBasicInformations: () => {},
    getCustomerProfile: () => {},
    setCustomerVerificationStatus: () => {},
    getTradingClosureWindow: () => {},
    setAccountNickName: () => {},
    screenType: '',
};
export default MarketAccountTypeComponent
