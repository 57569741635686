
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../CommonStyles';
import WIcon from '../WIcon/WIcon';
import './WInputCheckBox.css';

const assignObj = (obj) => { return obj; };


const WInputCheckBox = props => {
    const {  onChange, checked, label, sublabel, info, labelclick, labelstyle, value, checkInputStyle, sublabelstyle,id, ariainvalid, ariadescribedby, checkboxBackgroungColor} = props;
    return (
        <div className="container-fluid checkBoxWrapper" style={styles.paddingZeroStyle}>
            <Form.Group style={assignObj({ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 0 })}>
                <Form.Control id={id} as="input" type="checkbox" onChange={onChange} checked={checked} value={value} style={checkInputStyle} aria-describedby={ariadescribedby} aria-invalid={ariainvalid} className={checkboxBackgroungColor ? "backgroungBlur" : "rounded-0"} />
                <Form.Label onClick={labelclick}  htmlFor={id} style={assignObj({ ...styles.checkBoxLabelStyle, ...labelstyle })}>
                    {label}
                </Form.Label>
                {info === "true" ? <WIcon icon={faInfoCircle} fontawesomestyle={assignObj({ margin: 0 })} /> : ''}
            </Form.Group>
            {sublabel ?
                <Form.Text style={assignObj({ ...styles.checkBoxSubLabelStyle, ...sublabelstyle })}>
                    {sublabel}
                </Form.Text> : null}
        </div>
    )
};

WInputCheckBox.propTypes = {
    checkInputStyle: PropTypes.instanceOf(Object),
    id: PropTypes.string,
    label: PropTypes.string,
    info: PropTypes.string,
    checked: PropTypes.bool,
    value: PropTypes.string,
    labelclick: PropTypes.func,
    labelstyle: PropTypes.instanceOf(Object),
    sublabelstyle: PropTypes.instanceOf(Object),
    onChange: PropTypes.func,
    sublabel: PropTypes.string,
    ariadescribedby: PropTypes.string,
    ariainvalid: PropTypes.bool,
}

WInputCheckBox.defaultProps = {
    checkInputStyle: {},
    label: 'Label',
    info: "false",
    id: "",
    value: 'input',
    labelclick: null,
    labelstyle: {},
    sublabelstyle: {},
    onChange: null,
    checked: false,
    sublabel: null,
    ariadescribedby: '',
    ariainvalid: false,

}

export default WInputCheckBox;


/* Usage
<WInputCheckBox
    info="true"                             // depending on this info icon will display
    label={fund.fundName}                   //provide label for your checkbox
    name="Fund Names"                       //provide name for your checkbox group
    id={fund.fundID}                        //provide unique id for your checkbox
    value={fund.fundName}                   //provide unique value for your checkbox
    onClick={this.onCheckboxClick} />       //provide clickevent  for your checkbox

    /*****click event example
    onCheckboxClick=(e)=>{
        if(e.target.checked){
         this.setState({selectedFundsList:[...this.state.selectedFundsList,e.target.id]});
        }else{
            this.setState({selectedFundsList: this.state.selectedFundsList.filter(item => item !== e.target.id)});
        }
    }
*/





