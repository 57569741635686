import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../Box';

const StyledLabel = styled(Box)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

export const StyledOptionalSpan = styled.span`
    font: 500 12px/20px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 10px;
`;
const OPTIONAL_TEXT = '[Optional]';

const Label = React.forwardRef((props, ref) => {
    const { children, optional, ...restProps } = props;
    return (
        <StyledLabel ref={ref} as="label" {...restProps}>
            {children}
            {optional && <StyledOptionalSpan>{OPTIONAL_TEXT}</StyledOptionalSpan>}
        </StyledLabel>
    );
});

Label.propTypes = {
    optional: PropTypes.bool,
    children: PropTypes.node,
};

Label.defaultProps = {
    optional: false,
    children: 'Label',
};

export default Label;
