const TRANSIT_ROUTING_NUM = 'Transit Routing Number';
const MIN_MAX_VAL = '$ 3,000 / $ 5,000';
const NAV_MIN_MAX_VAL = '$ 300 / $ 8,000';
const FUND_NAME = 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES';
const TOTAL_SHARES = 'Total Shares';
const LAST_NAV = 'Last NAV (Previous day close)';
const NAV_CHANGE = 'NAV (Change in Percentage)';
const MIN_MAX_AMOUNT = 'Min. / Max. Amount';
const MIN_MAX_WEEK = '52 week Min. / Max. Values';
const SWP_TEXT = 'Systematic Withdrawal Plan';
const AIP_TEXT = 'Automatic Investment Plan';
const CURRENT_VAL = 'Current Value';
const ACC_NUMBER = 'Account Number';
const ACC_NUM1 = 'Account Name 1';
const text = {
    liquidation: {
        pageHeading: 'Sell',
        pageHeading1: 'Sell',
        noPlansText: 'No Sell',
    },
    liquidationAccounts: [
        {
            accountType: 'General Account',
            accounts: [
                {
                    accName: ACC_NUM1,
                    accNumber: {
                        text: ACC_NUMBER,
                        value: '5232-7856-1656',
                    },
                    currentVal: {
                        text: CURRENT_VAL,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: 'Account Name 2',
                    accNumber: {
                        text: ACC_NUMBER,
                        value: '2344-6547-2344',
                    },
                    currentVal: {
                        text: CURRENT_VAL,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
                {
                    accName: 'Account Name 3',
                    accNumber: {
                        text: ACC_NUMBER,
                        value: '9787-2345-8976',
                    },
                    currentVal: {
                        text: CURRENT_VAL,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: 'IRA Account',
            accounts: [
                {
                    accName: ACC_NUM1,
                    accNumber: {
                        text: ACC_NUMBER,
                        value: '4232-7856-1656',
                    },
                    currentVal: {
                        text: CURRENT_VAL,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
        {
            accountType: 'UTMA Account',
            accounts: [
                {
                    accName: ACC_NUM1,
                    accNumber: {
                        text: ACC_NUMBER,
                        value: '4232-7856-1656',
                    },
                    currentVal: {
                        text: CURRENT_VAL,
                        value: '$6000',
                    },
                    holding: {
                        text: 'Holding',
                        value: '$7000',
                    },
                    accInvstPlan: {
                        text: AIP_TEXT,
                        value: 'No',
                    },
                },
            ],
        },
    ],
    accountsAvailableForLiquidation: {
        currentValTxt: CURRENT_VAL,
        holdingTxt: 'Total no. of shares',
        planNameTxt: SWP_TEXT,
        accountTypeTxt: {
            general: 'General Account',
            ira: 'IRA Account',
            utmaOrUgma: 'UTMA Account',
        },
    },
    fundSelectionForLiquidation: {
        title: 'Sell Your Fund',
        description: 'Select a fund under an account which should undergo liquidation',
        allSharesTxt: 'All Shares',
        symbol: {
            dollar: '$ ',
            percent: '%',
        },
        amounttype: {
            allshare: 'allshare',
            dollaramt: 'dollaramt',
            percentamt: 'percentamt',
        },
        NAVdetails: {
            minmaxamt: MIN_MAX_AMOUNT,
            nacchange: NAV_CHANGE,
            lastnav: LAST_NAV,
            minmax52w: MIN_MAX_WEEK,
            separator: '/',
        },
        details: {
            totalshares: TOTAL_SHARES,
            worth: 'Worth',
            approx: '(Approx.)',

            // totalsharesvalue1: '2452',
            // worthvalue1: '$ 6000',
        },
    },
    fundSelection: {
        title: 'Liquidate your Fund',
        description: 'Select a fund under an account which should undergo liquidation',
        allSharesTxt: 'All Shares',
        symbols: {
            dollar: '$',
            percent: '%',
        },
        amounttype: {
            allshare: 'allshare',
            dollaramt: 'dollaramt',
            percentamt: 'percentamt',
        },
        fundList: [
            {
                fundName: FUND_NAME,
                NAVdetails: [
                    {
                        key: MIN_MAX_AMOUNT,
                        value: NAV_MIN_MAX_VAL,
                    },
                    {
                        key: NAV_CHANGE,
                        value: '14.3',
                    },
                    {
                        key: LAST_NAV,
                        value: '14.3',
                    },
                    {
                        key: MIN_MAX_WEEK,
                        value: MIN_MAX_VAL,
                    },
                ],
                details: [
                    {
                        key: TOTAL_SHARES,
                        value: '2452',
                    },
                    {
                        key: 'Worth',
                        value: '$ 6000',
                    },
                ],
            },
            {
                fundName: FUND_NAME,
                NAVdetails: [
                    {
                        key: MIN_MAX_AMOUNT,
                        value: NAV_MIN_MAX_VAL,
                    },
                    {
                        key: NAV_CHANGE,
                        value: '14.3',
                    },
                    {
                        key: LAST_NAV,
                        value: '14.3',
                    },
                    {
                        key: MIN_MAX_WEEK,
                        value: MIN_MAX_VAL,
                    },
                ],
                details: [
                    {
                        key: TOTAL_SHARES,
                        value: '2452',
                    },
                    {
                        key: 'Worth',
                        value: '$ 6000',
                    },
                ],
            },
            {
                fundName: FUND_NAME,
                NAVdetails: [
                    {
                        key: MIN_MAX_AMOUNT,
                        value: NAV_MIN_MAX_VAL,
                    },
                    {
                        key: NAV_CHANGE,
                        value: '14.3',
                    },
                    {
                        key: LAST_NAV,
                        value: '14.3',
                    },
                    {
                        key: MIN_MAX_WEEK,
                        value: MIN_MAX_VAL,
                    },
                ],
                details: [
                    {
                        key: TOTAL_SHARES,
                        value: '2452',
                    },
                    {
                        key: 'Worth',
                        value: '$ 6000',
                    },
                ],
            },
        ],
    },
    fundlist: [
        {
            fundName: 'USAA Science & Technology Adviser',
            maxInvestment: '4000',
            fundNumber: '54',
            max52W: '4000',
            monthlyInvestment: '0',
            risk: 'High',
            changeInNav: '14.3',
            symbol: 'USTCX',
            initialInvestment: '3000',
            min52W: '3000',
            lastNav: '13',
            totalsharesvalue: '2452',
            worthvalue: '6000',
        },
        {
            fundName: 'USAA Growth & Income Adviser',
            maxInvestment: '3000',
            max52W: '5000',
            monthlyInvestment: '0',
            worthAmount: '4000.56',
            symbol: 'USGIX',
            initialInvestment: '1000',
            min52W: '3000',
            fundNumber: '565',
            risk: 'Low',
            changeInNav: '14.3',
            lastNav: '13',
            totalShares: '25.68',
            totalsharesvalue: '2452',
            worthvalue: '7000',
        },
        {
            fundName: 'USAA Intermediate-Term Bond Adviser',
            maxInvestment: '5000',
            fundNumber: '30',
            max52W: '5000',
            monthlyInvestment: '50',
            risk: 'High',
            changeInNav: '14.3',
            symbol: 'UITBX',
            initialInvestment: '3000',
            min52W: '3000',
            lastNav: '13',
            totalsharesvalue: '2452',
            worthvalue: '8000',
        },
        {
            fundName: 'USAA Short-Term Bond Adviser',
            maxInvestment: '7000',
            fundNumber: '42',
            max52W: '7000',
            monthlyInvestment: '0',
            risk: 'Medium',
            changeInNav: '14.3',
            symbol: 'UASBX',
            initialInvestment: '2000',
            min52W: '2000',
            lastNav: '13',
            totalsharesvalue: '2452',
            worthvalue: '9000',
        },
        {
            fundName: 'USAA Growth & Income Adviser',
            maxInvestment: '3000',
            fundNumber: '33',
            max52W: '5000',
            monthlyInvestment: '0',
            risk: 'Low',
            changeInNav: '14.3',
            symbol: 'USGIX',
            initialInvestment: '1000',
            min52W: '3000',
            lastNav: '13',
            totalsharesvalue: '2452',
            worthvalue: '5000',
        },
    ],
    TaxAccountingFields: {
        title: 'Tax Accounting Method',
        description: 'Please contact a Member Service Representative (800) 235-8396 Services if you wish to change the tax accounting method',
        method: {
            methodkey: 'Current Method',
            methodvalue: 'Cost Basis',
        },
    },
    fundSource: {
        fundFrom: {
            detailName: 'Fund Source',
            description: 'Choose how to fund your account.',
            orSeperator: '[or]',
            accverificationmessage:
                'For your account’s security, you will receive a call momentarily to authenticate yourself.',
            tobeVerified: 'To be verified',
            tobeVerifiedAlert: '!',
            tobeVerifiedflag: true,
            methods: [
                {
                    title: 'Fund with a Bank Account',
                    desc: '',
                    method: 'online',
                    // details: [
                    //     {
                    //         accName: 'Bank Account 1',
                    //         accNumber: 'XXX-Xxx-3838',
                    //         isVerified: true,
                    //     },
                    //     {
                    //         accName: 'Bank Account 2',
                    //         accNumber: 'XXX-Xxx-5247',
                    //         isVerified: false,
                    //     },
                    //     {
                    //         accName: 'Add Account',
                    //     },
                    // ],
                    details: [
                        {
                            text: 'Check',
                        },
                    ],
                },
                {
                    title: 'Fund by Check',
                    // desc: 'Select the only below offline payment option',
                    desc: '',
                    method: 'offline',
                    details: [
                        {
                            text: 'Check',
                        },
                    ],
                },
            ],
        },
        Deliveryaddress: {
            detailName: 'Delivery Address',
            description: 'This is sample content paragraph',
        },
        offlineFundDetails: [
            {
                text: 'Check',
            },
            // Commented for Demo
            // {
            //     text: 'Directed SWPs',
            // },
        ],
    },
    addBankAccount: {
        title: 'Add Bank Account',
        description:
            'Upon submission On adding this account you will be taken to verification process with in 14 business days.',
        subtitle: 'Popular Banks',
        otherText: 'others',
        pageHeading: 'Bank Accounts',
        pageTitle: 'This is sample content paragraph',
        addText: 'Add',
        addBankHeading: 'Add Bank Account',
        addBankTitle: 'Add your account by choosing from the popular banks below.',
        menuOptions: 'Delete',
        deleteModalTitle: 'Delete Bank Accounts',
        deleteModalBody: 'Are you sure you want to delete Bank Accounts with <Account Name> and <Account No>',
        deleteModalDeleteText: 'Delete',
        deleteModalCancelText: 'Cancel',
        buttonText: {
            next: 'Next',
            back: 'Back',
            submit: 'Submit',
            cancel: 'Cancel',
        },
        banksText: 'Popular Banks',

        accountDetails: [
            {
                accName: 'Wells Fargo',
                accNumber: {
                    text: ACC_NUMBER,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'Yes',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'Yes',
                },
            },
            {
                accName: 'KBC Bank',
                accNumber: {
                    text: ACC_NUMBER,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'Yes',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'No',
                },
            },
            {
                accName: 'Bank of America',
                accNumber: {
                    text: ACC_NUMBER,
                    value: '0012XXXXXX8666',
                },
                transitRoutingNumber: {
                    text: TRANSIT_ROUTING_NUM,
                    value: '5699XXXX3659',
                },
                systematicWithdrawalPlan: {
                    text: SWP_TEXT,
                    value: 'No',
                },
                automaticInvestmentPlan: {
                    text: AIP_TEXT,
                    value: 'No',
                },
            },
        ],
        popularBankNames: [
            {
                bankName: 'Discover',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.discover.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Discover account online',
                    bankEmailIdText: 'Discover Online ID',
                    bankPasswordText: 'Discover Password',
                },
            },
            {
                bankName: 'Bank of America',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.bankofamerica.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Bank of America account online',
                    bankEmailIdText: 'Bank Of America Online ID',
                    bankPasswordText: 'Bank of America Password',
                },
            },
            {
                bankName: 'Wells Fargo',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.wellsfargo.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Wells Fargo account online',
                    bankEmailIdText: 'Wells Fargo Online ID',
                    bankPasswordText: 'Wells Fargo Password',
                },
            },
            {
                bankName: 'Chase',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.Chase.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Chase account online',
                    bankEmailIdText: 'Bank Chase Online ID',
                    bankPasswordText: 'Bank Chase Password',
                },
            },
            {
                bankName: 'Bank Name 1',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.bankName1.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Bank Name 1 account online',
                    bankEmailIdText: 'Bank bankName 1 Online ID',
                    bankPasswordText: 'Bank bankName 1 Password',
                },
            },
            {
                bankName: 'Bank Name 2',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.bankName2.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Bank Name 2 account online',
                    bankEmailIdText: 'Bank Name 2 Online ID',
                    bankPasswordText: 'Bank Name 2 Password',
                },
            },
            {
                bankName: 'Bank Name 3',
                bankDetails: {
                    webText: 'web:',
                    webUrl: 'http://www.Name3.com',
                    bankCredentialsTitle:
                        'Please enter the same credentials you use to access your Bank Name 3 account online',
                    bankEmailIdText: 'Bank Name 3 Online ID',
                    bankPasswordText: 'Bank Name 3 Password',
                },
            },
        ],
        typeofAccount: {
            text: 'Type of Account',
            value1: 'Savings',
            value2: 'Checking',
        },
        nameofFinancialInstitution: {
            text: 'Name of Financial Institution',
            value: '',
        },
        nameOfAccountOwner: {
            text: 'Name Of Account Owner(s)',
            value: '',
        },
        transitRouting: {
            text: TRANSIT_ROUTING_NUM,
            value: '',
        },
        accountNumber: {
            text: ACC_NUMBER,
            value: '',
        },
        accountOwnerAdd: {
            text: 'Account Owner`s address',
            value1: 'Line1',
            value2: 'Line2',
        },
        zipCode: {
            text: 'ZIP Code',
            value: 'ZIP',
        },
        cityState: {
            text: 'City & State',
            value1: 'City',
            value2: 'State',
        },
        specimenData: {
            heading: 'Specimen',
            title: 'Account number and all owner names must be preprinted and unaltered',
        },

        // radio buttons
        bankAccountTypeOptions: [
            {
                id: '1',
                value: 'Savings',
            },
            {
                id: '2',
                value: 'Checking',
            },
        ],
        bankAccountDetails: [
            {
                label: 'Name of Financial Institution',
                name: 'financialInstitutionName',
            },
            {
                label: 'Name of Account Owner(s)',
                name: 'accountOwner',
            },
            {
                label: TRANSIT_ROUTING_NUM,
                name: 'transitRoutingNumber',
            },
            {
                label: ACC_NUMBER,
                name: 'accountNumber',
            },
            {
                label: 'Account Owner`s address',
                name: 'ownerAddress',
            },
            {
                label: 'ZIP Code',
                name: 'zipCode',
            },
            {
                label: 'City & State',
                name: 'cityState',
            },
        ],
        // progress bar
        progressBarText: 'Gathering your Data',
    },
    Deliveryaddress: {
        detailName: 'Delivery Address',
        description: 'This is sample content paragraph',
    },
    Tradebox: {
        detailName: 'Tradebox',
        description: 'Trading window is currently closed. If submitted, trade will be priced as of next business day',
    },
    MockAPIAccountList: {
        accounts: [
            {
                accountNumber: '378',
                accountName: 'John Smith',
                fundNumber: '030',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '9000',
                currentValue: '4500',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
            },
            {
                accountNumber: '430',
                fundNumber: '030',
                accountName: 'Jeanette',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '7000',
                currentValue: '6000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
            },
            {
                accountNumber: '442',
                fundNumber: '030',
                accountName: 'Nick',
                taxID: '123456788',
                accountType: '100',
                ownerType: '3',
                automaticInvestmentPlan: 'Yes',
                holding: '8000',
                currentValue: '5000',
                masterRegistrationID: '30792017',
                currentAccountBalance: '3000.01',
                accountStatus: 'O',
                availableShares: '3000.01',
            },
        ],
    },
    MockAPIBankList: {
        BankDetails: [
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '1',
                mailUsage: 'P',
                transitRoutingNumber: '123123123',
                bankAccountNumber: '000123123123',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
            {
                paymentMethod: '01',
                paymentInstructionsStartDate: '11/27/2019',
                paymentInstructionsEndDate: '99/99/0099',
                alternatePayee: '2',
                mailUsage: 'P',
                transitRoutingNumber: '1231231445',
                bankAccountNumber: '000123123888',
                nameOfTheBank: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                addressLine5: '',
                city: '',
                state: '',
                ZIPCode: '15547',
                bankAccountType: 'C',
                purchaseFlag: 'N',
                SWPReferenceID: '',
                liquidationPaymentFlag: 'N',
                wireOrderLiquidationPaymentFlag: 'N',
                telephoneLiquidationPaymentFlag: 'N',
                PADPaymentFlag: 'N',
                SWPPaymentFlag: 'N',
                allTransactionsFlag: 'N',
                dividendPaymentFlag: 'Y',
                capitalGainPaymentFlag: 'N',
                toFundNumber: '0',
                toAccountNumber: '0',
                mailingInstruction: '15531',
                TSPFlag: 'N',
                paymentAmount: '0.00',
                percentagePayment: '0.0000',
                ACHComments: '',
            },
        ],
    },
};

export const luquidatepayload = {
    customerId: '45435',
    CompanyNumber: '591',
    FundNumber: '30',
    AccountNumber: '30900035576',
    TypeValueReq: 'A',
    liquidateAmount: '2400',
    PaymentMethod: '01',
    TaxWithholdingCode: 'P',
    AmountBeforeTaxes: '1000',
    AmountAfterTaxes: '43',
    FederalTax: '10',
    StateTax: '10',
    TotalTaxestobewithhold: '5',
    Totalyouwillreceive: '1000',
    TotalWithdrawal: '2000',
};

export default text;
