
/**
 * BAsic Information Component
 ********************************
 *
 * @version 1.0.1
 * @author Sharanya B
 * @description This page displays list of Information available for the user to update / modify their own information
 * @createdDate [06/11/2019]
 * ***************************************
 * @createdDate Sharanya B
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedReason Updated Styles (fonts , spacing between cards) for this page and added Breadcrumbs
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { VCMBasicInfoComponent, WBreadCrumb, CommonButtons, WInputCheckBox } from '../../../common';
import '../../../App.css';
import styles from './deviceStyles';
import './DeviceManagement.css';
import Consts from "../DeliverySettings/constants";

const assignObj = (obj) => { return obj; }

export default class DeviceManagementComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockProp: false,
      /* disabled: true, */
      checkValue: false
    }
  }

  cardHeadreClick = e => {
    e.preventDefault();
    e.target.classList.toggle('expand');
  };

  handleChange = (e) => {
    if(e.target.type === "checkbox") {
      this.setState({checkValue: !!e.target.checked});
      /* this.setState({checkValue: e.target.checked}); */
    }
    /* this.setState({disabled: !!e.target.checked}); */
}

handleBack = () => {
  const {history} = this.props;
      history.goBack();

}

  /* 
   * Method : render
   * Description :The complete DOM will be displayed when the render method is executed
  */
  render() {
    const { blockProp,/*  disabled, */ checkValue } = this.state;
    // const { history } = this.props;

    return (
      <div className="MainContainer">
        {  /* 
            * Component : Header
            * Description :Displays a Header
            */
        }
        <div style={styles.containerStyle}>
          <div className="container">
            {  /* 
                * Component : WBreadCrumb
                * Description :WBreadCrumb will have breadCrumbItems and activeCrumb as props which displays a breadcrumb
                */
            }
            <WBreadCrumb key=""
              breadCrumbItems={styles.bredCrumItmes}
              activeCrumb={styles.activeName}
            />
            <VCMBasicInfoComponent
              labelvalue="Device Management"
              buttonstyle={styles.disableStyle}
              block={blockProp}
            />

            <Accordion defaultActiveKey="0" id="phoneAccordion">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as="h4"
                    variant="link"
                    eventKey="0"
                    className="expand"
                    onClick={this.cardHeadreClick}
                  >
                    {styles.deviceManagement}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0" id="cardBody">
                  <Card.Body>
                    <div>{styles.cardBody}
                    </div>
                    <div className="cardLabel">{styles.cardLabel}</div>
                    <div >
                       <WInputCheckBox
                                    checkboxwrapperstyle={Consts.checkboxwrapperstyle}
                                    checkInputStyle={Consts.checkInputStyle}
                                    checkboxstyle={Consts.checkboxstyle}
                                    labelstyle={Consts.labelstyle}
                                    value={styles.checkLabel}
                                    label={styles.checkLabel}
                                    checked={checkValue}
                                    onChange={this.handleChange}
                                    
                                />
                                <span className="lastUsed">{styles.lastUsed}</span>


                       </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {  /* 
                * Component : VCMCardDeckComponent
                * Description :VCMCardDeckComponent com[ponent will display a header name with broder
                */
            }
          </div>
          <CommonButtons
                            disabled={!checkValue}
                            submitClick={this.handleBack}
                            backstyle={assignObj({display: 'none'})}
                            style={assignObj({ padding: 20 })}
                            submit="true"
                        />
        </div>
        {  /* 
            * Component : Footer
            * Description :Displays a Footer
            */
        }
      </div>
    )
  }
}

DeviceManagementComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

