const consts = {
    uplodDocHeading: 'Upload/ Mail Supporting Docs',
    uplodDocPara1: 'You may also upload the required docs or mail/fax to the address and number mentioned below',
    dropFiletext: 'Drop your files here',
    orText: '[or]',
    selecttext: 'Select Files',
    limitsArr: [
        {
            key: 'Attachment limit',
            value: '10 file',
        },
        {
            key: 'Size limit per file',
            value: '30 MB (megabytes)',
        },
        {
            key: 'Total size limit',
            value: '30 MB (megabytes)',
        },
        {
            key: 'File types allowed',
            value: 'PDF, JPG, JPEG, GIF, BMP, PNG, XLS, DOC, DOCX',
        },
    ],
    mailingText: 'By Mailing',
    address: {
        line1: 'Victory Capital',
        line2: 'P.O. Box 659453',
        line3: 'San Antonio, TX 78265-9009',
    },
    faxOption: 'You can also fax your application to',
    faxNumber: '800-292-8177',
};

export default consts;
