import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';
import styles from '../CommonStyles';

const WIcon = props => {
    const assignObj = obj => {
        return obj;
    };
    const { src, icon, size, alt, fontawesomestyle, style, arialabel, badge,tabIndex } = props;
    const wrapperstyle = assignObj({cursor: 'pointer', ...style });

    return (
        <div data-testid="wicon-component-id" aria-label={arialabel} tabIndex='-1' {...props} style={wrapperstyle}>
            {badge}
            {!src ? (
                <FontAwesomeIcon
                    // aria-label="close"
                    tabIndex={tabIndex}
                    alt={alt}
                    icon={icon}
                    size={size}
                    style={assignObj({ ...styles.fontawesomeIconStyles, ...fontawesomestyle })}
                   
                />
            ) : (
                <Image src={src} alt={alt} tabIndex={tabIndex} {...props} style={assignObj(fontawesomestyle)} />
            )}
        </div>
    );
};

WIcon.propTypes = {
    icon: PropTypes.PropTypes.instanceOf(Object),
    size: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fontawesomestyle: PropTypes.PropTypes.instanceOf(Object),
    style: PropTypes.PropTypes.instanceOf(Object),
    arialabel: PropTypes.string,
    tabIndex:PropTypes.string,
};

WIcon.defaultProps = {
    icon: faUserCircle,
    size: '2x',
    alt: '',
    fontawesomestyle: null,
    src: undefined,
    style: {},
    arialabel: '',
    tabIndex:"-1"
};

export default WIcon;
