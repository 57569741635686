import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const S = {};

S.HandSpan = styled.span`
    cursor: pointer;
`;

const MaskText = props => {

    const { text, startIndex, maskLength, id, style, additionalTxt, maskCharacter } = props;

    const [showMasked, setShowMasked] = useState(false);

    const tooLong = Math.abs(startIndex) >= text.length || Math.abs(maskLength) >= text.length;
    let mask = maskCharacter.repeat(Math.abs(maskLength));
    let masked = maskCharacter.repeat(text.length);
    let appended = '';
    let prepended = '';
    let endIndex;

    if (startIndex > 0 && !tooLong) {
        endIndex = startIndex + maskLength;
        masked = text?.slice(startIndex, endIndex);
        appended = text?.slice(endIndex, text.length);
        prepended = text?.slice(0, startIndex);
    } else if (startIndex < 0 && !tooLong) {
        endIndex = startIndex < 0 ? startIndex - maskLength : startIndex + maskLength;
        masked = text?.slice(startIndex, endIndex);
        prepended = text?.slice(-text.length, startIndex);
        appended = text?.slice(endIndex);
    } else if (startIndex === 0 && !tooLong) {
        masked = text?.slice(0, maskLength);
        appended = text?.slice(maskLength, text.length);
        prepended = '';
    } else {
        masked = text?.slice(0, text.length);
        appended = '';
        prepended = '';
        mask = maskCharacter.repeat(text.length);
    }

    function toggleText() {
        setShowMasked(!showMasked);
    }
    const result = `${prepended}${showMasked ? masked : mask}${appended}`;
    const accNumber = `${additionalTxt} ${result.split('').join(' ')}`;

    return (
        <S.HandSpan id={id} onMouseEnter={toggleText} data-testid='mask-text-test-id' onMouseLeave={toggleText} style={style}>
            <span className="sr-only">
                Account Number{accNumber}
            </span>
            <span aria-hidden="true">
                {result}
            </span>
        </S.HandSpan>
    );
};

MaskText.propTypes = {
    text: PropTypes.string.isRequired,
    startIndex: PropTypes.number,
    maskLength: PropTypes.number,
    maskCharacter: PropTypes.string,
    id: PropTypes.string,
    additionalTxt: PropTypes.string,
};

MaskText.defaultProps = {
    startIndex: 0,
    id: 'mask-text',
    maskCharacter: 'X',
    maskLength: 2,
    additionalTxt: '',
};

export default MaskText;
