import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import routeConstants from 'routeConstants';
import { withRouter } from 'react-router';
import { decrypt } from 'utils';
import { getMaskedValue } from 'modules/AccountRegistration/Utils';
import { resetPreRegistrationInfo } from 'modules/AccountRegistration/Actions/AccountRegistrationActions';
import consts from './constants';
import styles from './Styles';
import './success.css';

export class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }

  componentDidMount() {
    const { resetInfo, location } = this.props;
    const { vcmIdEnc } = location;
    if (vcmIdEnc) {
      (async () => {
        const vcmId = await decrypt(vcmIdEnc);
        this.setState({ vcmId });
      })();
    }
    localStorage.removeItem('token')
    resetInfo();
  }

  onMouseDown = () =>  this.setState(prevState => ({ toggle: !prevState.toggle }));

  onKeyDown = e => (e.keyCode === 32 || e.keyCode === 13) && this.setState(prevState => ({ toggle: !prevState.toggle }));

  getMessage = vcmId => !isEmpty(vcmId)
      ? {
        message:
          'You successfully created your credentials. Your Victory Member Number is',
        info:
          'Please note your member number and keep it securely. You will be required to provide this in case you forget your user id',
      }
      :  {
        message:
          'You successfully created your credentials',
        info:
          'Please note your member number and keep it securely. You will be required to provide this in case you forget your user id',
      };

  DisplayMessage = (vcmId, textMsg, toggle) => !isEmpty(vcmId) ? (
    <div className="container messageBarDiv" style={styles.container}>
      <Row>
        <Col md={12} lg={12} xl={12} role="alert" aria-live="polite">
          <Row style={styles.margin}>
            <div style={styles.message}>
              {textMsg.message}
              {' '}
              {toggle ? vcmId : getMaskedValue(vcmId, 0, '*')}
              <button
                type="button"
                tabIndex={0}
                aria-label={
                  !toggle
                    ? 'Show victory member number'
                    : 'Hide victory member number'
                }
                className="btnVcmId"
                onMouseDown={this.onMouseDown}
                onKeyDown={this.onKeyDown}
              >
                {toggle ? (
                  <FaEye size={20} focusable="false" />
                ) : (
                    <FaEyeSlash size={20} focusable="false" />
                  )}
              </button>
              {consts.fullStop}
            </div>
          </Row>
          {textMsg.info ? (
            <Row style={styles.margin}>
              <p style={styles.info}>{textMsg.info}</p>
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  ):(
    <div className="container messageBarDiv" style={styles.container}>
      <Row>
        <Col md={12} lg={12} xl={12} role="alert" aria-live="polite">
          <Row style={styles.margin}>
            <div style={styles.message}>
              {textMsg.message}
              </div>
            </Row>
        </Col>
      </Row>
    </div>
  ) 

  render() {
    const { location } = this.props;
    const { preReg } = location;
    const preId = !preReg ? "account-registration" : 'successMileStone';
    const { toggle, vcmId = "" } = this.state;
    const successClassName = preReg ? 'success-title' : 'success-title userMangmtSuccess';
    const textMsg = this.getMessage(vcmId);
    return (
      <div id={preId}>
        <div className={successClassName}>
          {!preReg ? (<><h1 className="pageHeading">{consts.successTitle}</h1>
            {this.DisplayMessage(vcmId, textMsg, toggle)}
            <p className='successs-signin'>{consts.signInText} {' '}
              <a className="inlineLInk"
                href={routeConstants.signIn}
                rel="noopener noreferrer" >{consts.signin}</a>
            </p>
            <p className="success-p explanationPara">
              {consts.successNew}
              <span className="anchorSpan">
                <a
                  href="https://www.vcm.com/"
                  className="inlineLInk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {consts.vcm}
                  <span className="hidden-accessible">{consts.extWebsiteMsg}</span>
                  <span aria-hidden="true" className="icon-newpage small-size" />
                  {consts.fullStop}
                </a>
              </span>
            </p>
            <div className="pageHeading">
              {consts.successNewSecond}
              <span className="anchorSpan">
                <a
                  href={`${consts.webURLBase}${consts.webUrl}`}
                  className="inlineLInk"
                  alt="External Website"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {consts.webUrl}
                  <span className="hidden-accessible">
                    {consts.extWebsiteMsg}
                  </span>
                  <span aria-hidden="true" className="icon-newpage large-size" />
                  {consts.fullStop}
                </a>
              </span>
            </div>
          </>) : (
              <>
                <h1 className="pageHeading">{consts.successPostGoLiveTitle}</h1>

                <span className="anchorSpan">
                  <a
                    href={routeConstants.signIn}
                    className="inlineLInk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {consts.signin}
                    {consts.fullStop}
                  </a>
                </span>
              </>
            )
          }
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  resetInfo: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
};

Success.defaultProps = {
  resetInfo: () => { },
  location: {
    vcmIdEnc: '', // encrypted vcmId should be sent
    preReg: false, // true only when send from pre registration screens
  },
  history: {},
};

const mapDispatchToProps = (dispatch) => ({
  resetInfo: () => dispatch(resetPreRegistrationInfo()),
});

export default withRouter(connect(null, mapDispatchToProps)(Success));
