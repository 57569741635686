import { Col, Row } from 'react-bootstrap';
import constDocs, {
    VCM_CODE_TEXT,
    DOCUMENT_CATEGORY,
    DOCUMENT_SOURCE,
    DOCUMENT_CATEGORY_LIST,
    DOCUMENT_SOURCE_LIST,
    CREATION_END_DATE,
    CREATION_START_DATE,
    VCM_ID_ERROR_MESSAGE,
    USSA_MEMBER_ID_ERROR_MESSAGE,
    VCM_DOCUMENT_FORM_CODE
} from '../constDocs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {SelectDropDown ,StatusMessage} from 'modules/CustomerManagementModule/components';
import styled from 'styled-components';
import { WBreadCrumb, PageHeading, CommonButtons, WSpinner, WInput,WButton, Accordian, AccordianTitle, ConformationNewModal, WMessageBar } from 'common';
import { removeServicesAccountHeader,removeXVCMAuthorizationtHeader } from 'shared/Network/Services';
import { isBackOfficeUser } from 'utils/utils';
import DatePicker from 'react-datepicker';
import './Classes.module.css';
import moment from 'moment-timezone';
import { getSessionValues } from 'shared/Helpers/Utils';
import DocumentListingTable from './DocumentListingTable';
import jwt_decode from "jwt-decode";
import { LocalStorageHelper, SessionStorageHelper } from 'utils/sessionHelper';
import { isArray, isEmpty } from 'lodash';
const buttonstyle = { display: 'block', float:"right" };
const clearbuttonstyle = {display: 'block', float:"right" , border:"none", background: "none",color: "#49494a", marginRight:"10px" };

const nextstyle = {
    display: 'none',
};


const SpecialMessageContainer = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e6e6e6;
    opacity: 1;
    text-align: center;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 22px;
    letter-spacing: 0;
    color: #49494a;
    margin-bottom: 3.125rem;
    line-height: 1.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`;
const SelectDocLabel = styled.label`
    margin: 0px;
    text-align: left;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    height: 22px;

    &.text-align {
        padding-left: 0px; 
      }
`;


const breadCrumbURL = [{ url: '/accountholder', name: 'Home' }];

const sortDataItems = ['Ascending', 'Descending'];

const DatePickerRow = styled(Row)`
height:115px;
`;
const OrCol = styled(Col)`
display: flex;
align-items: center;
justify-content:center;
width: 9.5% !important;
max-width: 9.5% !important;
flex: 0 0 9% !important;
`;
const DatePickerStyled = styled(DatePicker)`
&.date-picker-style{
    padding-left:20px;
    height:55px;
}
`;

const activeBreadCrumb = 'Document Center';
const NO_DOCUMENTS_MSG = 'No Documents Available';
const DOCUMENT_API_FAILURE_MSG =
    'We are experiencing a temporary issue when displaying your documents. Please try again shortly.';

class DocumentCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBackOfficeUser:false,
            documentData: [],
            menuArray: [],
            sortByState: sortDataItems[1],
            viewableState: `0`,
            visible: 20,
            accountDescriptorList: [],
            categoryList: [],
            yearList: [],
            selectedYear: '',
            selectedYearValue: '',
            docCategorySelected: [],
            axYearsortByTxt: '',
            axDocCategorysortByTxt: '',
            axSRsortByTxt: '',
            accountInfo: null,
            notificationDesc: '',
            singleSignOnClick: false,
            samlResponse: {},
            popup:null,
            inputVcmidCopy:"",
            errortext: '',
            searchCallStatus:true,
            searchApiRenderCount: 0,
            accountNumberList:[],
            selectedAccountNumber:[],
            selectedViewable:[],
            inputVcmid : '',
            inputVcmidErrorMessage: "",
            inputFisId:"",
            usaaMemberId:"",
            ussaMemberIdErrorMsg:"",
            inputDocumentId:"",
            inputVcmFormCode:"Select",
            inputTitle:"",
            inputDocumentCategory:"Select",
            inputDocumentSource:"Select",
            creationStartDate:"",
            creationEndDate:"",
            rows : [],
            filterData : {
                selectedAccountNumber:"All",
                docCategorySelected:"All",
                selectedViewable:"All",
                vcmid:"",
                fisid:"",
                documentId:""
            },
            searchButtonStatus:true,
            disableField: false,
            transferDocumentModal: false,
            selectedMoveData:"",
            movedVcmId:"",
            movedUsaaMemberId:"",
            selectedRows: [],
            isLoading: false,
            renderCount: 0,
            show: false,
            movedDocumentMsg: '',
            maxSelectedRowErrorStatus: false,
            showAlert: false,
            errorMessage: '',
            renderCountVcmidVerification: 0,
            searchDocumentApiPayload:{},
            apiActionType: '',
            vcmIdUsaaIdErrorMessage: '',
            vcmIdUsaaIdErrorMessage2:""

        };
    }
  
    componentDidMount() {
        document.title = `Document Center | Victory Capital`;
        const { getDocuments, setSelectedYear } = this.props;
        const { documentData } = this.state;

        const currentYear = new Date().getFullYear();
        const tempYearList = [];
   
        const currentDate = new Date();
        let isBackOfficeUserStatus =''
        if(SessionStorageHelper.getItem('msr_dts')){
         isBackOfficeUserStatus = isBackOfficeUser();
        }

        if(isBackOfficeUserStatus == false)
        {
            const sevenYearBeforeDate = new Date();
            const afterSubtractSevenYear = this.subtractYearsFormDate(sevenYearBeforeDate,1,0,7);
            const finalSevenYearBeforeDate = `${afterSubtractSevenYear?.getFullYear()}-${this.addPaddingInText(`${afterSubtractSevenYear?.getMonth()+1}`,'0',2)}-${this.addPaddingInText(`${afterSubtractSevenYear?.getDate()}`,'0',2)}`;

            const todayDate = new Date();
            const finalTodayDate = `${todayDate?.getFullYear()}-${this.addPaddingInText(`${todayDate?.getMonth()+1}`,'0',2)}-${this.addPaddingInText(`${todayDate?.getDate()}`,'0',2)}`;
            if(SessionStorageHelper.getItem('msr_dts')){
            getDocuments(
                    {
                    docCategory: 'all',
                    dateFrom: `${finalSevenYearBeforeDate}`,
                    dateTo: `${finalTodayDate}`,
                    vcmId: this.getSearchableVCMID()
                }
                );
            }
        }

        setSelectedYear(currentYear);
        for (let i = 0; i <= 7; i += 1) {
            tempYearList.push(currentYear - i);
        }

        this.setState({ yearList: [...tempYearList],
            inputVcmid:this.getSearchableVCMID(), disableField: !isBackOfficeUserStatus,isBackOfficeUser:isBackOfficeUserStatus});
        this.setState({ selectedYear: '0', selectedYearValue: `${currentYear}`,
        creationEndDate: new Date(), creationStartDate: this.subtractYearsFormDate(currentDate,1,0,7)});

        this.setState(() => {
            return {
                accountDescriptorList: constDocs.accountDescriptor,
                appliedFilter: {
                    filterStartDate: null,
                    filterEndDate: null,
                    accountDescriptorList: constDocs.accountDescriptor,
                },
                documentData: this.sortByDate(2, documentData),
            };
        });
        document.addEventListener('keydown', this.handleEscapeKey, false);

      
    }


    subtractYearsFormDate = (date,day,month,years) =>
    {
        date.setDate(day);
        date.setMonth(month);
        date.setFullYear(date.getFullYear() - years);
        return date;
    }




    handleAccountNumberFilter = (documentList) =>
    {
        const uniqueAccountNumber = documentList.map((item) => item.accountDescriptor).filter((value, index, self) => self.indexOf(value) === index);
        const uniqueCategoryValue = documentList.map((item) => item.documentCategory).filter((value, index, self) => self.indexOf(value) === index);


        let uniqueCategoryKeyValue = uniqueCategoryValue?.map((item,index)=>{
            return {
                key:item,
                value:item
            }
        })
        let uniqueAccountNumberKeyValue = uniqueAccountNumber?.map((item,index)=>{
            return {
                key:item,
                value:item
            }
        })

        uniqueCategoryKeyValue.splice(0,0,{
            key:"All",
            value:"All"
        })

        uniqueAccountNumberKeyValue.splice(0,0,{
            key:"All",
            value:"All"
        })

        uniqueCategoryKeyValue = uniqueCategoryKeyValue?.filter((item)=>  (item.value !=undefined && item.value !=null && item.value !=""  ))  
        uniqueAccountNumberKeyValue = uniqueAccountNumberKeyValue?.filter((item)=> (item.value !=undefined && item.value !=null && item.value !=""  ))    

        this.setState({
            accountNumberList:uniqueAccountNumberKeyValue,
            categoryList:uniqueCategoryKeyValue,
            searchApiRenderCount:1,
            selectedAccountNumber: uniqueAccountNumberKeyValue[0].value,
            docCategorySelected: uniqueCategoryKeyValue[0].value
        })
    }

    handleVerifyVCMUSSAIdValidation = () =>
    {
        const {loginData} = this.props;
        if(loginData?.vcmidErrorMessage != undefined && loginData?.vcmidErrorMessage != '' && loginData?.vcmUsaaIdVerificationStatus == false && this.state.renderCountVcmidVerification == 0){
            this.setState({
                showAlert:true,
                renderCountVcmidVerification: 1
            })
        }
        else if(loginData?.vcmidErrorMessage == '' && loginData?.vcmUsaaIdVerificationStatus == true && this.state.renderCountVcmidVerification == 0){
            if(this.state.apiActionType == 'searchDocument'){ 
                if(loginData?.verifiedVcmUsaaIdInfo?.message.toLowerCase() == "success")
                {
                    let payload = this.state.searchDocumentApiPayload;
                    payload['vcmId']= loginData?.verifiedVcmUsaaIdInfo?.result?.VCMID;
                    this.props.getDocuments(payload);
                    this.setState({
                        renderCountVcmidVerification:1,
                        vcmIdUsaaIdErrorMessage2 : ''
                    })
                }
                else if(loginData?.verifiedVcmUsaaIdInfo?.message.toLowerCase() == "failure")
                {
                    this.setState({
                        vcmIdUsaaIdErrorMessage2 : loginData?.verifiedVcmUsaaIdInfo?.result,
                        renderCountVcmidVerification: 1
                    })
                }
            }
            else if(this.state.apiActionType == 'transferDocument'){
                if(loginData?.verifiedVcmUsaaIdInfo?.message.toLowerCase() == "success"){
                    if(this.state?.selectedRows?.length > 20){
                        this.setState({
                            maxSelectedRowErrorStatus: true,
                            transferDocumentModal: false,
                            renderCountVcmidVerification:1,
                            vcmIdUsaaIdErrorMessage : ''
                        })
                    }
                    else{
                        this.setState({ 
                            transferDocumentModal: true,
                            selectedMoveData: (this.state.movedVcmId || this.state.movedUsaaMemberId),
                            maxSelectedRowErrorStatus: false,
                            renderCountVcmidVerification:1,
                            vcmIdUsaaIdErrorMessage: ''
                        });
                    }
                }
                else if(loginData?.verifiedVcmUsaaIdInfo?.message.toLowerCase() == "failure"){
                    this.setState({
                        vcmIdUsaaIdErrorMessage : loginData?.verifiedVcmUsaaIdInfo?.result,
                        renderCountVcmidVerification: 1
                    })
                }
                
            }
        }
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            const {documentList, transferDocumentSuccess, transferDocumentFailure, errorMessage} = this.props.documentPreferenceData
            if(documentList?.length > 0 && this.props?.documentPreferenceData?.isSuccess == true && this.state.searchApiRenderCount == 0)
            {
                this.handleAccountNumberFilter(documentList)
            }
            if((transferDocumentSuccess && transferDocumentFailure == false) && this.state.renderCount == 0) {
                this.setState({
                    isLoading: false,
                    renderCount: 1,
                    show: true,
                    movedDocumentMsg: 'Documents moved Successfully'
                })
               const payloadObj = this.createSearchDocumentPayload(false);
               this.props.getDocuments(payloadObj.payload);
               this.props.resetTransferDocument()
            }
            else if((transferDocumentFailure == true && transferDocumentSuccess == false)  && this.state.renderCount == 0){
                this.setState({
                    isLoading: false,
                    renderCount: 1,
                    show: true,
                    movedDocumentMsg: errorMessage
                })
                this.props.resetTransferDocument()
            }

            this.handleVerifyVCMUSSAIdValidation();
        }
        
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKey, false);
    }

    toggleAccordionContent=()=>{
      //toggling accordion
      const targetElement = document.getElementById('Acordion_title_click');
     if (targetElement) {
        targetElement.click();
       }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.documentPreferenceData.documentList !== prevState?.documentPreferenceData?.documentList) {
            const { documentPreferenceData } = nextProps;
            const { documentList = [] } = documentPreferenceData;

            if(documentList?.length)
            {
                removeServicesAccountHeader()
                removeXVCMAuthorizationtHeader();
            }
            return {
                documentData: documentList,
                searchCallStatus:true,
                documentPreferenceData,
                rows: documentList
            };
        }
    }

    backClickHandler = () => {
        const { history, loginData: { getCustomerProfile: { c_: roleIdentifier = 0 } = {} } = {} } = this.props;
        const isMember = roleIdentifier === 2;
        if (history) {
            history.push({
                pathname: isMember ? '/accountholder' : '/accountguestview',
            });
        }
    };

    getNewClearList = list => {
        return list.map(item => {
            const newItem = { ...item };
            if (newItem.checked) {
                newItem.checked = false;
            }
            return newItem;
        });
    };

    onClearFilter = () => {
        this.setState(prevState => {
            const { categoryList, accountDescriptorList } = prevState;
            const newCategoryList = this.getNewClearList(categoryList);
            const newAccountDescriptorList = this.getNewClearList(accountDescriptorList);
            return {
                categoryList: newCategoryList,
                accountDescriptorList: newAccountDescriptorList,
                filterStartDate: null,
                filterEndDate: null,
                filterDateError: '',
                isValidDateRange: false,
            };
        });
    };

    filterDocumentList = filter => {
        const { documentPreferenceData } = this.props;
        const { documentList = [] } = documentPreferenceData;
        const { categoryList } = filter;
        const { selectedYearValue } = this.state;

        let filterByDates = documentList;
        // Filter by categories
        const checkedFilters = categoryList.filter(category => category?.checked === true).map(type => type.key);

        if (checkedFilters.length > 0) {
            let tempList = [];
            const othersSelected = checkedFilters.includes('Others');

            const documentCategoryTypeReducer = (filtered, docListItem) => {
                const { CategoryValue } = docListItem.Category;
                const filteredObj = { ...filtered };

                const docType = constDocs.mappedDocCategories[`${CategoryValue}`];
                const selected = checkedFilters.includes(docType);
                const notMappedToFilter =
                    selectedYearValue >= '2020'
                        ? !constDocs.category.map(type => type.key).includes(docType)
                        : !constDocs.categoryList.map(type => type.key).includes(docType);

                if (selected || (notMappedToFilter && othersSelected)) {
                    if (!filteredObj[`${CategoryValue}`]) {
                        filteredObj[`${CategoryValue}`] = [];
                    }

                    filteredObj[`${CategoryValue}`].push(docListItem);
                    return filteredObj;
                }
                return filteredObj;
            };

            const filteredDocsList = filterByDates.reduce(documentCategoryTypeReducer, {});
            const filterdDocEntiers = Object.values(filteredDocsList);

            filterdDocEntiers.forEach(value => {
                tempList = tempList.concat(value);
            });

            filterByDates = tempList;
        }

        return filterByDates;
    };

    onYearSelection = e => {
        this.setState({ axYearsortByTxt: 'true' });
        setTimeout(() => {
            this.setState({ axYearsortByTxt: 'false' });
        }, 3000);
        const { getDocuments, setSelectedYear } = this.props;
        this.onClearFilter();

        const fieldVal = e.list.find(val => val.key === e.target.value);
        const { value = '' } = fieldVal || {};

        let tempData = this.state.filterData;
        tempData.selectedAccountNumber = "All";
        tempData.docCategorySelected = "All";
        tempData.selectedViewable = "All";
        let formattedYear=value?.slice(0,4)
        this.setState({
            selectedYear: e.target.value,
            selectedYearValue: value,
            sortByState: sortDataItems[1],
            docCategorySelected:"",
            viewableState:"0",
            selectedAccountNumber:[],
            filterData:tempData,
            searchApiRenderCount:0,
            categoryList:[],
            accountNumberList:[]
        });

        let payload = {
            docCategory: 'all',
            dateFrom: `${value}-01-01`,
            dateTo: `${value}-12-31`,
        }
        if(this.state.isBackOfficeUser){
            const formatedEndDate = this.state.creationEndDate?moment(this.state.creationEndDate).format('YYYY-MM-DD'):'';
        payload = {
            vcmId :this.state.inputVcmid,
            usaaId: this.state.usaaMemberId,
            nodeId:this.state.inputDocumentId,
            formCode:this.state.inputVcmFormCode=="Select" ? "" : this.state.inputVcmFormCode,
            title:this.state.inputTitle,
            docCategory:this.state.inputDocumentCategory=="Select" ? "" : this.state.inputDocumentCategory,
            documentSource:this.state.inputDocumentSource =="Select" ? "" : this.state.inputDocumentSource,
            dateFrom:value=="2019 and earlier"?"":`${formattedYear}-01-01`,
            dateTo:`${formattedYear}-12-31`,
        }
            
        }

        if(this.getSearchableVCMID() || this.state?.inputVcmidCopy)
        {
            payload['vcmId'] = (this.state.searchCallStatus == false || this.state.isBackOfficeUser == false) ? this.getSearchableVCMID() : this.state.inputVcmidCopy
           
        }

        if(this.state.inputDocumentId)
        {
            payload['nodeId'] = this.state.inputDocumentId
        }

        if(this.state.inputFisId)
        {
            payload['fisMemberId'] = this.state.inputFisId
        }

        if((this.getSearchableVCMID() || this.state?.inputVcmidCopy) || this.state?.inputDocumentId || this.state?.inputFisId)
        {
            
            getDocuments(payload);
        }

        setSelectedYear(value);
    };


    getFilteredDocs = ({ documentData, filterValue }) => {
        let usaaFormIdFilteredDocs = [];
        if (filterValue === 'Statements') {
            usaaFormIdFilteredDocs = documentData.filter(
                doc => doc.usaaFormId === 'MFMTH' || doc.usaaFormId === 'MFANNCON',
            );
        }
        if (filterValue === 'Trade Confirmations' || filterValue === 'Confirmations') {
            usaaFormIdFilteredDocs = documentData.filter(doc => doc.usaaFormId === 'MUTCONF');
        }
        if (filterValue === 'Tax Documents') {
            usaaFormIdFilteredDocs = documentData.filter(
                doc =>
                    doc.usaaFormId === 'MUT998' ||
                    doc.usaaFormId === 'C1099R' ||
                    doc.usaaFormId === 'C1099B' ||
                    doc.usaaFormId === 'C5498',
            );
        }
        return usaaFormIdFilteredDocs;
    };

    sortByDate = (value, documentData) => {
        let sortedData = {};
        if (documentData !== null && documentData.length > 0) {
            switch (value) {
                case 0:
                    sortedData = {
                        documentData: documentData
                            .slice()
                            .sort(
                                (a, b) =>
                                    new Date(a.DocumentDate.DocumentDateValue) -
                                    new Date(b.DocumentDate.DocumentDateValue),
                            ),
                        sortByState: sortDataItems[0],
                    };
                    break;
                case 1:
                    sortedData = {
                        documentData: documentData
                            .slice()
                            .sort(
                                (a, b) =>
                                    new Date(b.DocumentDate.DocumentDateValue) -
                                    new Date(a.DocumentDate.DocumentDateValue),
                            ),
                        sortByState: sortDataItems[1],
                    };
                    break;
                default:
                    sortedData = {
                        documentData,
                    };
                    break;
            }
        } else {
            sortedData = {
                sortByState: sortDataItems[+value],
            };
        }
        return sortedData;
    };

    getNoDocsStatus = ({ documentsApiHasFailed, isFetching, documentData }) => {
        return (!documentsApiHasFailed && !isFetching && documentData?.length === 0) || isFetching;
    };

    getLoadingStatus = ({ isDownloadingFile, isFetching }) => {
        return (isDownloadingFile || isFetching || this.state.isLoading);
    };

    handleChange = e => {
        console.log('event',e.target.name)
        this.setState({ [e.target.name]: e.target.value },()=>{

            if(this.state.inputVcmid || this.state.inputDocumentId || this.state.inputFisId || this.state.usaaMemberId)
            {
                this.setState({
                    searchButtonStatus:false,
                })
            }
            else
            {
                this.setState({
                    searchButtonStatus:true
                })
            }
            
        });

        if(e.target.name == 'inputVcmid'){
            this.setState({
                usaaMemberId: '',
                inputDocumentId:'',
                vcmIdUsaaIdErrorMessage2: ''
            })
        }
        else if(e.target.name == 'usaaMemberId'){
            this.setState({
                inputVcmid: '',
                inputDocumentId:'',
                vcmIdUsaaIdErrorMessage2: ''
            })
        }
        else{
            this.setState({
                inputVcmid: '',
                vcmIdUsaaIdErrorMessage2: ''
            })
        }
    };

    

    handleDropdown = event => {
        const fieldVal = event.list.find(val => val.key === event.target.value);
        const { key = '' } = fieldVal || {};
        this.setState({[event.target.name] : `${key}`});
    };

    handleStartDate = (dateValue) => {
        this.setState((prevState)=>(
            { ...prevState, creationStartDate: dateValue }
        ));
    };

    handleEndDate = (dateValue) => {
        this.setState((prevState)=>(
            { ...prevState, creationEndDate:dateValue }
        ));
    };

    addPaddingInText = (text,paddingText,textLength) =>
    {
        if(text?.length > 0)
        {
            return text.padStart(textLength,paddingText);
        }
        else
        {
            return text;
        }
    }

    createSearchDocumentPayload = (searchDocumentFlag) =>
    {
        const currentYear = new Date().getFullYear();

        let tempData = this.state.filterData;
        tempData.selectedAccountNumber = "All";
        tempData.docCategorySelected = "All";
        tempData.selectedViewable = "All";

        let vcmIdErrorMsg = "";
        let ussaMemberIdErrorMsg = "";

        let inputVcmIdData = "";
        let inputUssaMemberIdData = "";

        if(this.state?.inputVcmid?.length > 9)
        {
            vcmIdErrorMsg = VCM_ID_ERROR_MESSAGE;
        }
        else
        {
            inputVcmIdData = this.addPaddingInText(this.state?.inputVcmid,'0',9)
        }

        if(this.state?.usaaMemberId?.length > 9)
        {
            ussaMemberIdErrorMsg = USSA_MEMBER_ID_ERROR_MESSAGE
        }
        else
        {
            inputUssaMemberIdData = this.addPaddingInText(this.state?.usaaMemberId,'0',9)
        }

        this.setState({ selectedYear: '0', selectedYearValue: `${currentYear}`,
            viewableState:"0",
            sortByState: sortDataItems[1],
            searchCallStatus:false,
            inputVcmidCopy: this.state.inputVcmid,
            filterData:tempData,
            searchApiRenderCount:0,
            categoryList:[],
            accountNumberList:[],
            documentData:[],
            inputVcmidErrorMessage:vcmIdErrorMsg,
            ussaMemberIdErrorMsg:ussaMemberIdErrorMsg,
            apiActionType:  searchDocumentFlag ? 'searchDocument' : "",
            renderCountVcmidVerification: 0
        });

        const formatedStartDate =this.state.creationStartDate? moment(this.state.creationStartDate).format('YYYY-MM-DD'):'';
        const formatedEndDate = this.state.creationEndDate?moment(this.state.creationEndDate).format('YYYY-MM-DD'):'';
        let payload = {
            vcmId: inputVcmIdData,
            usaaId: inputUssaMemberIdData,
            nodeId:this.state.inputDocumentId,
            formCode:this.state.inputVcmFormCode=="Select" ? "" : this.state.inputVcmFormCode,
            title:this.state.inputTitle,
            docCategory:this.state.inputDocumentCategory=="Select" ? "" : this.state.inputDocumentCategory,
            documentSource:this.state.inputDocumentSource =="Select" ? "" : this.state.inputDocumentSource,
            dateFrom:formatedStartDate,
            dateTo:formatedEndDate,
        }

        return  {payload,vcmIdErrorMsg,ussaMemberIdErrorMsg};
    }

    handleSearchClick =()=>{

        const {getDocuments,resetVcmidVerification} = this.props;
        resetVcmidVerification();

        let payloadObj = this.createSearchDocumentPayload(true);

        if(payloadObj.vcmIdErrorMsg =="" && payloadObj.ussaMemberIdErrorMsg == "" )
        {
            if(this.state.inputVcmid || this.state.inputDocumentId || this.state.usaaMemberId)
            {
                if(this.state.isBackOfficeUser == false ){
                    getDocuments(payloadObj.payload);
                }
                else{
                    if(this.state.inputDocumentId == ''){
                        const {directVerifyMember} = this.props
                        directVerifyMember({
                            memberId: (this.state.inputVcmid || this.state.usaaMemberId),
                            memberType: this.getMemberType(false),
                        });
                        this.setState({
                            searchDocumentApiPayload: payloadObj.payload
                        })
                    }
                    else{
                        getDocuments(payloadObj.payload);
                    }
                    
                }
            }
        }
    }

   handleClearClick=()=>{
        if(this.state.isBackOfficeUser)
        {
            this.setState({ 
                inputVcmid : '',
                inputFisId:"",
                usaaMemberId:"",
                inputDocumentId:"",
                inputVcmFormCode:"Select",
                inputTitle:"",
                inputDocumentCategory:"Select" ,
                inputDocumentSource:"Select",
                creationStartDate:"",
                creationEndDate:"",
                searchButtonStatus:true,
                documentData:[]
            });
        }
        else
        {
            this.setState({ 
                inputFisId:"",
                usaaMemberId:"",
                inputDocumentId:"",
                inputVcmFormCode:"Select",
                inputTitle:"",
                inputDocumentCategory:"Select" ,
                inputDocumentSource:"Select",
                creationStartDate:"",
                creationEndDate:"",
                documentData:[]
            });
        }

    }

    getSearchableVCMID = () =>
    {
        if(SessionStorageHelper.getItem('msr_dts')){
        const msrInfo = getSessionValues();
        return (this.state.isBackOfficeUser ==  false) ? msrInfo?.vcmid : this.state.inputVcmid
        }
    }

  
    assignFilterData = (category,value) =>
    {
        let tempData = this.state.filterData;
        if(category == 'category' )
        {
            tempData.docCategorySelected = value;
        }
        else if(category == 'accountNumber' )
        {
            tempData.selectedAccountNumber = value;
        }
        else if(category == 'viewable' )
        {
            tempData.selectedViewable = value ;
        }
        else if(category == 'vcmid')
        {
            tempData.vcmid = value;
        }

        this.setState({
            filterData:tempData
        })
        return tempData;
    }

    getFilterData = (selectedData,data) =>
    {
        let filterQuery = {} 
    
        if(selectedData.selectedViewable !="All")
        {
            filterQuery['viewable'] = [selectedData.selectedViewable]
        }

        if(selectedData.selectedAccountNumber !="All")
        {
            filterQuery['accountDescriptor'] = [selectedData.selectedAccountNumber]
        }

        if(selectedData.docCategorySelected !="All")
        {
            filterQuery['documentCategory'] = [selectedData.docCategorySelected]
        }

        if(Object.keys(filterQuery).length > 0)
        {
            return this.applyFilterQuery(data, filterQuery);
        }
      
        return data;
    }

    applyFilterQuery = (data, filter) => {

        if(data?.length > 0)
        {
            let query = {};
            for (let keys in filter) {
                if ( (filter[keys].constructor === Object) || (filter[keys].constructor === Array && filter[keys].length > 0)) {
                    query[keys] = filter[keys];
                }
            }
            
            const filteredData = data?.filter( (item) => {
                for (let key in query) {
                    if (item[key] === undefined) {
                        return false;
                    }
                    else if (!query[key].includes(item[key])) {
                        return false;
                    }
                }
                return true;
            });
            return filteredData;
        }
        else
        {
            return [];
        }
    };


    onApplyFilter = (event,filterType) =>
    {
        const selectValue = event?.list?.filter((item)=>item.selected == true);

        const filterParams = this.assignFilterData(filterType,selectValue[0]?.value)

        const { documentPreferenceData } = this.props;
        const { documentList = [] } = documentPreferenceData;
       
        const sortByDateSelection = this.state.sortByState === 'Descending' ? '1' : '0';
        let documentsAfterSorting = this.sortByDate(+sortByDateSelection,[...documentList] );
        this.setState({
            documentData: this.getFilterData(filterParams,documentsAfterSorting.documentData)
        });
    }

    modifyArrayWithJSX = (array) => {
        const modifiedArray = array.map(item => {
        if (typeof item?.value === 'string' && item?.value?.includes(' - ')) {
            const [keyPart, valuePart] = item?.value?.split(' - ');
            item.value = (
              <span style={{ display:'flex',}}>
                <span >{keyPart}</span><span style={{ margin: '0 5px' }}> - </span> <span style={{ overflow:"hidden" , textOverflow: "ellipsis"}}>{valuePart}</span>  
              </span>
            );
          }
           return item;
        });
        return modifiedArray;
      }

       searchDocumentList =(searchval) =>{
        const documentDataList = JSON.parse(JSON.stringify(this.props.documentPreferenceData?.documentList))
        const filterData = documentDataList.filter((item)=>{
            if(searchval == ''){
                return item
            }
            else if(item.DocumentTitle?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
            else if(item.NodeId?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
            else if(item.documentDate?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
            else if(item.viewable?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
            else if(item.documentCategory?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
            else if(item.accountDescriptor?.toLowerCase().includes(searchval?.toLowerCase())){
                return item
            }
        })
        return filterData
        
    }

      handleDocumentSearch = (val)=>{
       const filterData = this.searchDocumentList(val)
       this.setState({
            documentData:filterData
       }) 
      }

      getMemberType =(movedFlag)=>{
        if (movedFlag){
            return (this.state.movedVcmId != '' ? 'VCM' : 'USAA')
        }
         else{
            return (this.state.inputVcmid != '' ? 'VCM' : 'USAA')
         }
      }
    
    handleSubmitClick =()=>{
        const {directVerifyMember,resetVcmidVerification} = this.props
        resetVcmidVerification()
        directVerifyMember({
            memberId: (this.state.movedVcmId || this.state.movedUsaaMemberId),
            memberType: this.getMemberType(true),
        });
        this.setState({
            apiActionType: 'transferDocument',
            renderCountVcmidVerification: 0
        })
        // if(this.state?.selectedRows?.length > 20){
        //     this.setState({
        //         maxSelectedRowErrorStatus: true,
        //         transferDocumentModal: false
        //     })
        // }
        // else{
        //     this.setState({ 
        //         transferDocumentModal: true,
        //         selectedMoveData: (this.state.movedVcmId || this.state.movedUsaaMemberId),
        //         maxSelectedRowErrorStatus: false
        //     });
        // }
    }
    closeTransferDocumentModal =()=>{
        this.setState({ transferDocumentModal: false });    
    }

    movedVcmIdhandleChange = e => {
        this.setState({
            movedUsaaMemberId: '',
            movedVcmId: e.target.value,
        });
    };
    movedUsaaMemberIdhandleChange = e => {
        this.setState({
            movedVcmId: '',
            movedUsaaMemberId: e.target.value,
        });
    };

    handleMovedDocumetDataClearClick = ()=>{
        this.setState({
            movedVcmId: '',
            movedUsaaMemberId: '',
        });
    }

    getSelectedTableList = (selectedList) =>{
        this.setState({
            selectedRows: selectedList,
            maxSelectedRowErrorStatus: false
        })
    }

    getSelectedDocumentIdList =()=>{
        const selectedDocumentId = this.state.selectedRows?.map((item)=>{
            return item.original.NodeId
        })
        return selectedDocumentId
    }

    transferDocument = ()=>{
        const {transferDocuments,loginData}= this.props
        const payload = {
            fromVcmId: this.state.inputVcmid,
            toVcmId: loginData?.verifiedVcmUsaaIdInfo?.result?.VCMID,
            fromUsaaMemberId:this.state.usaaMemberId,
            toUsaaMemberId:this.state.movedUsaaMemberId,
            documentId: this.getSelectedDocumentIdList(),
        };
        transferDocuments(payload);
        this.setState({
            transferDocumentModal: false,
            isLoading: true,
            renderCount: 0
            
        })
    }

    onNotificationClose=()=>{
        this.setState({
            show: false
        })
    }

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    render() {
        const {
            documentData,
            searchButtonStatus,
            inputDocumentCategory,
            inputVcmFormCode,
            inputDocumentSource,
            movedVcmId,
            movedUsaaMemberId,
            transferDocumentModal,
            show,
            showAlert,
            isErrorBar
        } = this.state;
        //console.log('props',this.props)
        const {
            documentPreferenceData: { isFetching = false, isErrorDC = false } = {},
            loginData: { getCustomerProfile: { c_: roleIdentifier = 0 } = {} } = {},
            downloadedFileData: { isLoading: isDownloadingFile = false },
        } = this.props;
        const documentsApiHasFailed = !isFetching && isErrorDC;
        const userHasNoDocuments = this.getNoDocsStatus({ documentsApiHasFailed, isFetching, documentData });

        const isLoading = this.getLoadingStatus({ isDownloadingFile, isFetching });
        const pageHeading = (this.state.isBackOfficeUser) ? "Document Manager" : "Document Center";

        let token = ''
        let decodeToken = ''
        if(LocalStorageHelper.getItem('token')){
           token = LocalStorageHelper.getItem('token');
           decodeToken = jwt_decode(token);
        }
        const customGroupname = decodeToken['custom:groupname'];
        let isAdminDocumentManagerAvailable = false;
        let customGroupArray = customGroupname?.split(',');
        if(
            customGroupArray?.includes('AdminDocumentManager')
            || customGroupArray?.includes('[AdminDocumentManager')
            || customGroupArray?.includes('AdminDocumentManager]')
            )
            {
                isAdminDocumentManagerAvailable = true
            }
        
        const disabledState = (!(!isEmpty(movedVcmId) || !isEmpty(movedUsaaMemberId))  );
       
        return (
            <>
                <WSpinner loading={isLoading} />

                {showAlert && (
                            <WMessageBar text={{info: 'Technical Error'}} error={true} onClose={this.handleMessageBarClose} />
                )}

                {transferDocumentModal &&
                <ConformationNewModal 
                    modalTitle="Approve Application"
                    modalBodyText={`Are you sure you want to transfer documents from ${this.state.inputVcmid || this.state.usaaMemberId} to ${this.state.selectedMoveData} ?`}
                    onPrimaryClick={this.transferDocument}
                    onSecondaryClick={this.closeTransferDocumentModal}
                    secondaryButtonText="Cancel"
                    primaryButtonText={`Yes`}
                />
                }
                {show && 
                <StatusMessage
                    onClose={this.onNotificationClose}
                    text={{message: this.state.movedDocumentMsg,info: null}}
                />
                }
                <div role="main">
                    <div className="container mb-5">
                    {(this.state.isBackOfficeUser ==  false) && 
                        <WBreadCrumb
                            activeCrumb={activeBreadCrumb}
                            breadCrumbItems={breadCrumbURL}
                        />
                    }
                      
                        <Row>
                            <Col xs={12} md={4} xl={5}>
                                <PageHeading>{pageHeading}</PageHeading>
                            </Col>
                        </Row>
                       
                          
                        <Accordian 
                              accordianPaddingLeft={0}
                              id={"searchAccordian"}
                              titleChild={
                                  <Row style={{ display: "flex", justifyContent: "space-between" ,marginTop: '10px' }} tabIndex="-1" >
                                      <Col md={10} xl={11}>
                                          <AccordianTitle
                                              id={'Acordion_title_click'}
                                              accType={'Search'}
                                              className="accordionH2Style"
                                              requireButtonPadding={false}
                                          /></Col>
                                  </Row>
                                  }
                              >
                            <>
                            <fieldset>
                            <legend>Required</legend>
                                <Row className="searchbar">
                                    <Col xs={12} md={3} xl={3} className='searchinputbox pr-0' >
                                        <WInput
                                            ariaDescribedBy="vcmid"
                                            controlid="vcmid"
                                            label="VCM Id"
                                            labelsm={4}
                                            labelmd={12}
                                            valuesm={12}
                                            valuemd={12}
                                            maxlength={13}
                                            value={this.state.inputVcmid}
                                            onChange={this.handleChange}
                                            placeholder=""
                                            name="inputVcmid"
                                            errortext={this.state?.inputVcmidErrorMessage}
                                            type="text"
                                            className="searchinput"
                                            disabled={this.state.disableField}
                                        />
                                    </Col>
                                    <OrCol xs={12} md={1} xl={1} className='p-0'>-OR-</OrCol>

                                    <Col xs={12} md={3} xl={3}  className='searchinputbox p-0' >
                                        <WInput
                                            ariaDescribedBy="usaamemberid"
                                            controlid="usaamemberid"
                                            label="USAA member ID"
                                            labelsm={4}
                                            labelmd={12}
                                            valuesm={12}
                                            valuemd={12}
                                            maxlength={50}
                                            value={this.state.usaaMemberId}
                                            onChange={this.handleChange}
                                            placeholder=""
                                            name="usaaMemberId"
                                            errortext={this.state?.ussaMemberIdErrorMsg}
                                            type="text"
                                            disabled={this.state.disableField}
                                        />

                                        <p></p>
                                    </Col>
                                  
                                        <OrCol xs={12} md={1} xl={1} className='p-0'>-OR-</OrCol>
                                    <Col xs={12} md={4} xl={4} className='pl-0'>
                                        <WInput
                                            ariaDescribedBy="DocumentID"
                                            controlid="DocumentId"
                                            label="Document Id"
                                            labelsm={4}
                                            labelmd={12}
                                            valuesm={12}
                                            valuemd={12}
                                            maxlength={200}
                                            value={this.state.inputDocumentId}
                                            onChange={this.handleChange}
                                            placeholder=""
                                            name="inputDocumentId"
                                            errortext={this.state.errortext}
                                            type="text"
                                        />
                                        <p></p>
                                    </Col>

                                </Row>
                                <Row>
                                    {this.state.vcmIdUsaaIdErrorMessage2 &&
                                    <Col md={12}>
                                        <p className='errormessage mb-0 text-left'>{this.state.vcmIdUsaaIdErrorMessage2}</p>
                                    </Col>
                                    }
                                </Row>
                            </fieldset>
                                  
                            <fieldset>
                            <legend>Optional Filter Criteria</legend>   
                            
                                    <Row className="searchbar">
                                        <Col xs={12} md={4} xl={4}>
                                            <SelectDocLabel
                                                className="col-12 col-md-12 text-align mb-2"
                                                htmlFor={`${VCM_CODE_TEXT}`}
                                            >
                                                {VCM_CODE_TEXT}
                                            </SelectDocLabel>
                                            <SelectDropDown
                                                itemlist={this.modifyArrayWithJSX(VCM_DOCUMENT_FORM_CODE)}
                                                onChange={this.handleDropdown}
                                                value={inputVcmFormCode}
                                                id={`VCMformcode`}
                                                aria-controls="vcmFormCode"
                                                data-testid="vcm-form-code"
                                                name="inputVcmFormCode"
                                            />
                                        </Col>
                                        <Col xs={12} md={4} xl={4} >
                                            <WInput
                                                ariaDescribedBy="Title"
                                                controlid="Title"
                                                label="Title"
                                                labelsm={4}
                                                labelmd={12}
                                                valuesm={12}
                                                valuemd={12}
                                                maxlength={200}
                                                value={this.state.inputTitle}
                                                onChange={this.handleChange}
                                                placeholder=""
                                                name="inputTitle"
                                                errortext={this.state.errortext}
                                                type="text"
                                                inputfieldstyle={{height:'56px',}}

                                            />
                                        </Col>
                                        <Col xs={12} md={4} xl={4}>
                                        <SelectDocLabel
                                                className="col-12 col-md-12 text-align mb-2"
                                                htmlFor={`${DOCUMENT_CATEGORY}`}
                                            >
                                                {DOCUMENT_CATEGORY}
                                            </SelectDocLabel>
                                            <SelectDropDown
                                                itemlist={DOCUMENT_CATEGORY_LIST}
                                                onChange={this.handleDropdown}
                                                value={inputDocumentCategory}
                                                id={`DocumentCategory`}
                                                aria-controls="yearListTxt"
                                                data-testid="filter-by-year"
                                                name="inputDocumentCategory"
                                            />
                                        </Col>
                                    </Row>

                                      
                                    <DatePickerRow >
                                    <Col xs={12} md={4} xl={4}>
                                        <SelectDocLabel
                                                className="col-12 col-md-12 text-align mb-2 mt-md-3"
                                                htmlFor={`${DOCUMENT_SOURCE}`}
                                            >
                                                {DOCUMENT_SOURCE}
                                            </SelectDocLabel>
                                            <SelectDropDown
                                                itemlist={DOCUMENT_SOURCE_LIST}
                                                onChange={this.handleDropdown}
                                                value={inputDocumentSource}
                                                id={`DocumentSource`}
                                                aria-controls="documentSource"
                                                data-testid="document-source"
                                                name="inputDocumentSource"
                                            />
                                        </Col>
                                    <Col xs={12} md={4} xl={4}>
                                    <SelectDocLabel
                                                className="col-12 col-md-12 text-align mt-0 mb-2 mt-md-3"
                                                htmlFor={`${CREATION_START_DATE}`}
                                            >
                                                {CREATION_START_DATE}
                                            </SelectDocLabel>

                                                         <DatePickerStyled
                                                              id={"startDate"}
                                                                selected={this.state.creationStartDate}
                                                                onChange={this.handleStartDate}
                                                                ariaLabelledBy={"CreationStartDate"} 
                                                                ariaDescribedBy="CreationStartDate"
                                                                className='date-picker-style'
                                                             />
                                    </Col>
                                     <Col xs={12} md={4} xl={4}>
                                     <SelectDocLabel
                                                className="col-12 col-md-12 text-align  mt-0 mb-2 mt-md-3"
                                                htmlFor={`${CREATION_END_DATE}`}
                                            >
                                                {CREATION_END_DATE}
                                            </SelectDocLabel>
                                                <DatePickerStyled
                                                    id={"endDate"}
                                                    selected={this.state.creationEndDate}
                                                    onChange={this.handleEndDate}
                                                    ariaLabelledBy={"CreationEndDate"} 
                                                    ariaDescribedBy="CreationEndDate"
                                                    className='date-picker-style'
                                                    />
                                    </Col>

                                    </DatePickerRow>
                            </fieldset>
                            <Row>
                            <Col xl={12} md={12} sm={12}>  
                            <WButton
                                buttontext="Search"
                                buttonstyle={buttonstyle}
                                onClick={this.handleSearchClick}
                                role="link"
                                disabled={(this.state.isBackOfficeUser ? searchButtonStatus : false)}
                            />
                                <WButton
                                    buttontext="Clear"
                                    buttonstyle={clearbuttonstyle}
                                    onClick={this.handleClearClick}
                                    role="link"
                                    nocorner
                            />
                            </Col>
                            </Row>
                                    
                            </>
                        </Accordian>
                       
                        {(isAdminDocumentManagerAvailable && this.state.selectedRows?.length > 0 )&& 
                        <>
                            <Accordian 
                                accordianPaddingLeft={0}
                                id={"searchAccordian"}
                                titleChild={
                                    <Row style={{ display: "flex", justifyContent: "space-between" ,marginTop: '10px' }} tabIndex="-1" >
                                        <Col md={10} xl={11}>
                                            <AccordianTitle
                                                id={'Acordion_title_click'}
                                                accType={'Move Documents'}
                                                className="accordionH2Style"
                                                requireButtonPadding={false}
                                            />
                                        </Col>
                                    </Row>
                                    }
                                >
                            
                                <fieldset>
                                <legend>Required</legend>
                                    <Row className="searchbar">
                                        <Col xs={12} md={3} xl={3} className='searchinputbox pr-0' >
                                            <WInput
                                                ariaDescribedBy="vcmid"
                                                controlid="vcmid"
                                                label="VCM Id"
                                                labelsm={4}
                                                labelmd={12}
                                                valuesm={12}
                                                valuemd={12}
                                                maxlength={13}
                                                value={this.state.movedVcmId}
                                                onChange={this.movedVcmIdhandleChange}
                                                placeholder=""
                                                name="movedVcmId"
                                                errortext={this.state?.inputVcmidErrorMessage}
                                                type="text"
                                                className="searchinput"
                                            />
                                            
                                            
                                        </Col>
                                        <OrCol xs={12} md={1} xl={1} className='p-0'>-OR-</OrCol>

                                        <Col xs={12} md={3} xl={3}  className='searchinputbox p-0' >
                                            <WInput
                                                ariaDescribedBy="usaamemberid"
                                                controlid="usaamemberid"
                                                label="USAA member ID"
                                                labelsm={4}
                                                labelmd={12}
                                                valuesm={12}
                                                valuemd={12}
                                                maxlength={50}
                                                value={this.state.movedUsaaMemberId}
                                                onChange={this.movedUsaaMemberIdhandleChange}
                                                placeholder=""
                                                name="movedUsaaMemberId"
                                                errortext={this.state?.ussaMemberIdErrorMsg}
                                                type="text"
                                            />

                                            
                                        </Col>
                                        <Col xs={12} md={4} xl={4}>
                                            {this.state.vcmIdUsaaIdErrorMessage && 
                                                <p className='errormessage mt-5'>{this.state.vcmIdUsaaIdErrorMessage}</p>
                                            }
                                        </Col>
                                    
                                    </Row>
                                    
                                </fieldset>
                            </Accordian>
                            <Row>
                                <Col xl={12} md={12} sm={12}>  
                                    <WButton
                                        buttontext="Submit"
                                        buttonstyle={buttonstyle}
                                        onClick={this.handleSubmitClick}
                                        disabled={disabledState}
                                    />
                                    <WButton
                                        buttontext="Clear"
                                        buttonstyle={clearbuttonstyle}
                                        onClick={this.handleMovedDocumetDataClearClick}
                                        nocorner
                                    />
                                    {this.state.maxSelectedRowErrorStatus && 
                                    <p className='errormessage mt-3'>Only 20 documents can be moved at a time</p>
                                    }
                                </Col>
                            </Row>
                        </>
                        }
                        {documentsApiHasFailed && !isFetching && (
                            <SpecialMessageContainer className="p-4">
                                {DOCUMENT_API_FAILURE_MSG}
                            </SpecialMessageContainer>
                        )}

                        {((userHasNoDocuments && !isFetching) || (documentData == undefined)) && (
                            <SpecialMessageContainer className="p-4">{NO_DOCUMENTS_MSG}</SpecialMessageContainer>
                        )}
 
                        {(!documentsApiHasFailed && documentData?.length > 0) && 
                            <DocumentListingTable  documentData={documentData} handleDocumentSearch={this.handleDocumentSearch} isAdminDocumentManagerAvailable={isAdminDocumentManagerAvailable}  getSelectedTableList={this.getSelectedTableList} />
                        }


                    </div>

                    {(this.state.isBackOfficeUser ==  false) && 
                        <CommonButtons backClick={this.backClickHandler} nextstyle={nextstyle} />
                    }
                    
                </div>
            </>
        );
    }
}

DocumentCenter.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    documentPreferenceData: PropTypes.instanceOf(Object),
    getDocuments: PropTypes.func,
    transferDocuments: PropTypes.func,
    loginData: PropTypes.instanceOf(Object),
    setSelectedYear: PropTypes.func,
    downloadedFileData: PropTypes.instanceOf(Object),
};

DocumentCenter.defaultProps = {
    getDocuments: () => {},
    transferDocuments: () => {},
    documentPreferenceData: {},
    loginData: {},
    setSelectedYear: () => {},
    downloadedFileData: {},
};

export default DocumentCenter;