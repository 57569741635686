const bredCrumItmes = [
	{ 
		url: '/Profile', 
		name: 'Profile',
		dataAnalyticId:'cms_basicinformation_manageemploymentinfo_breadcrumb_profile'
	}, 
	{ 
		url: '/basicinformation', 
		name: 'Basic Information',
		dataAnalyticId:'cms_basicinformation_manageemploymentinfo_breadcrumb_basicinfo'
	}
];
const componentname = 'Manage Employment Information';
const topHeading = `If you can't find an exact match for your industry or occupation, choose the closest one`;
const line2 = `This information is collected on behalf of each of the VCM companies, and will be used for business purpose, 
including identification, verification of eligibility, and to market their products and services`;
const EMPLOYMENT_INFO = 'Employment Information';
const EMPLOYMENT_STATUS = 'Employment Status';
const INDUSTRY = 'Industry';
const OCCUPATION = 'Occupation';
const EMPLOYER_NAME = 'Employer Name';
const EMPLOYER_ADD='Employer Address';
const PRIMARY_SOURCE_OF_INCOME = 'Primary Source of Income';
const ACTION_LABEL_EMPLOYMENT = 'Manage Employment Information';
const ACTION_TEXT = 'Manage';
const NOF = 'None on file';
const EMPLOYER_ADDRESS_LINE1 = 'Employer Address Line 1 ';
const EMPLOYER_ADDRESS_LINE2 ='Employer Address Line 2 ';
const EMPLOYMENT_STATUS_ERROR = "Please select a employment status";
const PRIMARY_SOURCE_OF_INCOME_ERROR = "Please select a primary source of income";
const INDUSTRY_ERROR = "Please select a industry";
const SAVE = "Save";
const CANCEL = "Cancel";
const NONEONFILE = 'None on file';
const OTHER_EMPLOYMENT = 'Other Employment Status';
const OTHER_INDUSTRY = 'Other Industry';

const ERROR_MESSAGES = {
	employmentStatus: EMPLOYMENT_STATUS_ERROR,
	primarySourceOfIncome: PRIMARY_SOURCE_OF_INCOME_ERROR,
	industry: INDUSTRY_ERROR,
};


export default {
	OTHER_INDUSTRY,
	OTHER_EMPLOYMENT,
	componentname,
	bredCrumItmes,
	topHeading,
	line2,
	EMPLOYMENT_INFO,
	ACTION_LABEL_EMPLOYMENT,
	ACTION_TEXT,
	EMPLOYMENT_STATUS,
	INDUSTRY,
	OCCUPATION,
	EMPLOYER_NAME,
	EMPLOYER_ADD,
	NOF,
	EMPLOYMENT_STATUS_ERROR,
	PRIMARY_SOURCE_OF_INCOME_ERROR,
	INDUSTRY_ERROR,
	ERROR_MESSAGES,
	EMPLOYER_ADDRESS_LINE1,
	EMPLOYER_ADDRESS_LINE2,
	PRIMARY_SOURCE_OF_INCOME,
	SAVE,
	CANCEL,
	NONEONFILE
};