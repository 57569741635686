/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to loop through the investment payment methods
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './InvestmentSelection.css';
import styles from './styles';
import * as CONST from './consts';
import AccGlobalConstants from '../AccountManagementConstants';
import { WCard, MaskText } from '../../../common';
import BankIcon from '../../../assets/bankNew.svg';

/*  lopping offline and online methods */
const InvestmentSelectionPaymentMethods = props => {
    const { fundSource, selectedFundSrcMethod, selectFundcard, hasMonthlyFunds } = props;
    const { assignObj } = AccGlobalConstants;
    const cardTitleStyle = { ...styles.cardtitleStyle, marginTop: '15px', marginLeft: '10px' };
    const cardStyle = { display: 'flex' };
    const getCardTitle = (source, subSource) => {
        if (source.key === 'online') {
            const { bankDetails = {} } = subSource;
            const { bankName = '', bankAccountNumber = '' } = bankDetails;
            return subSource.key === 'add_bnk' ? (
                CONST.onlineCardText
            ) : (
                <>
                    <span>{bankName}</span> <MaskText text={bankAccountNumber} startIndex={4} maskLength={6} />
                </>
            );
        }

        return subSource.value;
    };

    return fundSource.map(source => {
        if (source.key === 'online' || (source.key === 'offline' && !hasMonthlyFunds)) {
            const headerValue = source.key === 'online' ? CONST.onlineHeaderLabel : CONST.offlineHeaderLabel;

            return (
                <div key={source.value}>
                    <div style={styles.subHeadingStyle}>{headerValue}</div>
                    <Row key={Math.random()} className="fundPaymentWrapper" noGutters>
                        {source.subtypes.map(sourceSubType => {
                            const cardTitle = getCardTitle(source, sourceSubType);

                            const bankIcon =
                                source.key === 'online' && sourceSubType.key !== 'add_bnk' ? BankIcon : null;
                            const fundSourceIdentifier = bankIcon
                                ? sourceSubType.bankDetails.salesforceRecordId
                                : sourceSubType.key;
                            return (
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    key={fundSourceIdentifier || sourceSubType.key}
                                    style={styles.fundPaymentColStyle}
                                >
                                    <WCard
                                        className={selectedFundSrcMethod === fundSourceIdentifier ? 'fundSrcSel' : ''}
                                        selected={selectedFundSrcMethod === fundSourceIdentifier}
                                        buttonstyle={styles.displayNoneStyle}
                                        icon={faCheckCircle}
                                        cardstyle={styles.cardStyle}
                                        cardtext=""
                                        cardtitletext={cardTitle}
                                        cardtitlestyle={cardTitleStyle}
                                        cardbodystyle={cardStyle}
                                        titleiconsrc={bankIcon}
                                        fontawesomeiconcard={
                                            selectedFundSrcMethod === fundSourceIdentifier
                                                ? assignObj({
                                                      color: 'rgba(139, 193, 5, 1)',
                                                  })
                                                : assignObj({ color: '#fff' })
                                        }
                                        onClick={selectFundcard(
                                            source.key,
                                            bankIcon ? sourceSubType.bankDetails.salesforceRecordId : sourceSubType.key,
                                            sourceSubType.value,
                                        )}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    {source.key === 'online' && !hasMonthlyFunds && <div style={styles.orStyle}>{CONST.OR}</div>}
                </div>
            );
        }
    });
    /*  end lopping offlin and online methods */
};

InvestmentSelectionPaymentMethods.propTypes = {
    selectFundcard: PropTypes.func,
    fundSource: PropTypes.instanceOf(Array),
    selectedFundSrcMethod: PropTypes.string,
    hasMonthlyFunds: PropTypes.bool,
};

InvestmentSelectionPaymentMethods.defaultProps = {
    fundSource: [],
    selectedFundSrcMethod: '',
    selectFundcard: () => {},
    hasMonthlyFunds: false,
};

export default InvestmentSelectionPaymentMethods;
