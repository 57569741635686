import * as ActionTypes from "../ReduxConstants/MSRActionConstants";

const initialState = {
    isLoading: false,
    topics: [],
    accounts: []
};
const SecureMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PAGE_LOADING:
            return {
                ...state,
                isPageLoading: action.payload
            };
        case ActionTypes.SET_ACCOUNT_LIST: {
            const accounts = action.payload;
            return {
                ...state,
                accounts
            };
        }
        default:
            return state;
    }
};
export default SecureMessageReducer;