import { accOpeningActions, localStateManagementActions } from '../../../shared/Actions';
import { connect } from 'react-redux';
import MarketFinancialInfoCard from './MarketFinancialInfoCard';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    //initialState: state.initialAppData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MarketFinancialInfoCard);
