import React, { memo } from "react";
import {Row,Col} from 'reactstrap';
import { usePaginationPages } from "./usePaginationPages";

function Pagination({ gotoPage, length, pageSize }) {
    const perPage = pageSize
    const {
        canGo,
        currentPage,
        pages,
        goTo,
        goNext,
        goPrev,
    } = usePaginationPages({
        gotoPage,
        length,
        pageSize
    });
    
    const noofRecordsStart= currentPage == 1 ? currentPage : (currentPage * perPage) - (perPage)+1
    const noofRecordsEnd= currentPage* perPage

    return (
        <>
            <Row>
                <Col md={4}>
                   <p> Showing <b>{noofRecordsStart}</b> to <b>{noofRecordsEnd}</b> of <b>{length} </b> entries</p> 
                </Col>
                <Col md={8}>
                    <div className="flex items-center" style={{float:'right'}}>
                        <button
                            onClick={goPrev}
                            disabled={!canGo.previous}
                            className={!canGo.previous ? "m-1 px-2 py-1 border rounded-md" : "m-1 px-2 py-1 border rounded-md paginationbtn"}
                        >
                            Previous
                        </button>
                        {pages.map((page, i) => (
                            <button
                                onClick={() => goTo(page)}
                                key={i}
                                style={{
                                    background: currentPage === page ? "#486d90" : "none",
                                    color: currentPage === page ? "white" : "black"
                                }}
                                className="m-1 px-3 py-1 border rounded-md"
                            >
                                {page}
                            </button>
                        ))}
                        <button
                            onClick={goNext}
                            disabled={!canGo.next}
                            className={!canGo.next ?  "m-1 px-2 py-1 border rounded-md" : "m-1 px-2 py-1 border rounded-md paginationbtn"}
                        >
                            Next
                        </button>

                    </div>
                </Col>
            </Row>

        </>
    );
}

export default memo(Pagination);
