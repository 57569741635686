const cardData =  [
    {
      cardId: 1,
      cardHeading: "Goals",
      cardText: 'This is sample content paragraph',
    },
    {
      cardId: 2,
      cardHeading: "Advice",
      cardText: 'This is sample content paragraph',

    },
    {
      cardId: 3,
      cardHeading: "General Documents",
      cardText: 'This is sample content paragraph',
    },
    {
      cardId: 4,
      cardHeading: "Security & Fraud Alerts",
      cardText: 'This is sample content paragraph',
    },
    {
      cardId: 5,
      cardHeading: "Device Management",
      cardText: 'This is sample content paragraph',
    }
  ]
  export default cardData;