/* eslint-disable import/prefer-default-export */

import { SET_USER_ID } from 'shared/ReduxConstants/AccountRegistrationConstants';

export const setUserId = (data) => (dispatch) => {
  dispatch({
    type: SET_USER_ID,
    payload: {
      ...data,
    },
  });
};
