/**
 * Financial Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Financial information
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import { WInput, VCMSelect } from '../../../common';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';

const assignObj = obj => {
    return obj;
};

class FinancialInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: [],
        };

        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;

        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return {};
    }

    handleBlur = e => {
        const { errorMsg } = this.state;
        let err = '';
        if (e.target.value === '') {
            err = Rules[e.target.name]
                ? checkValidity({
                      rules: Rules[e.target.name],
                      value: e.target.value,
                  })
                : '';
        }
        errorMsg[e.target.name] = err;
        this.setState({ errorMsg });
    };

    handleChange = eventKey => e => {
        if (eventKey === 'annualIncome') {
            const { masterLookupStateData } = this.props;
            const { annual_income: annualIncomeMaster } = masterLookupStateData || {};
            const { value: annualIncomeValue } = annualIncomeMaster || {};
            const updatedArray = annualIncomeValue || [];
            const findex = updatedArray.findIndex(item => item.key === e.target.value);
            const decimalTaxBracket = parseFloat(updatedArray[+findex].taxbracket).toFixed(2);
            this.setState({ taxBracket: decimalTaxBracket.concat('%') });
        }
        this.setState({ [eventKey]: e.target.value });
    };

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    sendDataToParent = () => {
        return { ...this.state, ...{ valid: true } };
    };

    render() {
        const financialInfoText = 'Financial Information';
        const annualText = 'Annual Income ($)';
        // const selectText = 'Select';
        const { taxBracket, taxFilingStatus, annualIncome, netWorth, errorMsg } = this.state;
        const { masterLookupStateData, isJoint } = this.props;
        const {
            annual_income: annualIncomeMaster,
            net_worth: netWorthMaster,
            tax_filling_status: taxFillingStatusMaster,
        } = masterLookupStateData || {};
        const { value: annualIncomeValue } = annualIncomeMaster || {};
        const { value: netWorthValue } = netWorthMaster || {};
        const { value: taxFillingStatusValue } = taxFillingStatusMaster || {};
        const annualIncomeOp = annualIncomeValue || [];
        const netWorthOp = netWorthValue || [];
        const taxFillingStatusOp = taxFillingStatusValue || [];
        const accTypeHeading = isJoint ? 'Joint Owner' : '';
        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            ref={this.accordionRef}
                            style={styles.accordionToggleStyle}
                        >
                            {accTypeHeading} {financialInfoText}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="show">
                            <Row>
                                <div className="container-fluid" style={assignObj({ padding: 0 })}>
                                    <VCMSelect
                                        id="annualIncome"
                                        name="annualIncome"
                                        itemlist={annualIncomeOp}
                                        label={annualText}
                                        onChange={this.handleChange('annualIncome')}
                                        value={annualIncome}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.annualIncome}
                                        noGutters
                                        optional
                                    />
                                    {/* <Form.Group as={Row} noGutters style={styles.labelStyle}>
                                        <Form.Label column sm={4} htmlFor="annualIncome" style={styles.labelTextStyle}>
                                            {annualText}
                                            <span style={styles.sublabel}>{optionalText}</span>
                                        </Form.Label>
                                        <Col sm={5}>
                                            <div style={styles.selectArrow} />
                                            <Form.Control
                                                as="select"
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                id="annualIncome"
                                                name='annualIncome'
                                                value={annualIncome}
                                                isInvalid={!!errorMsg.annualIncome}
                                                style={styles.select}

                                            >
                                                <option value="">{selectText}</option>
                                                {annualIncomeOp &&
                                                    annualIncomeOp.map(item => {
                                                        return (
                                                            <option
                                                                key={item.key}
                                                                value={item.key}
                                                                taxbracket={item.taxbracket}
                                                            >
                                                                {item.value}
                                                            </option>
                                                        );
                                                    })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                    {errorMsg.annualIncome}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group> */}
                                </div>
                            </Row>
                            <Row>
                                <WInput
                                    label="Tax Bracket (%)"
                                    // placeholder="Tax Bracket (percentage)"
                                    className=""
                                    value={taxBracket || ''}
                                    id="taxBracket"
                                    name="taxBracket"
                                    type="text"
                                    onChange={this.handleChange('taxBracket')}
                                    readOnly
                                    errortext={errorMsg.taxBracket}
                                    labelsm={4}
                                    valuesm={5}
                                    optional="true"
                                />
                            </Row>
                            <VCMSelect
                                id="netWorth"
                                name="netWorth"
                                label="Net Worth ($)"
                                itemlist={netWorthOp}
                                onChange={this.handleChange('netWorth')}
                                value={netWorth}
                                labelsm={4}
                                valuesm={5}
                                errortext={errorMsg.netWorth}
                                noGutters
                                optional
                            />
                            <VCMSelect
                                id="taxFilingStatus"
                                name="taxFilingStatus"
                                label="Tax Filing Status"
                                itemlist={taxFillingStatusOp}
                                onChange={this.handleChange('taxFilingStatus')}
                                value={taxFilingStatus}
                                labelsm={4}
                                valuesm={5}
                                errortext={errorMsg.taxFilingStatus}
                                noGutters
                                optional
                            />
                            {/* <Row>
                                <SelectInput
                                    id="netWorth"
                                    name="netWorth"
                                    label="Net Worth (USD)"
                                    className="netWorth"
                                    itemlist={netWorthOp}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    required
                                    value={netWorth}
                                    errortext={errorMsg.netWorth}
                                    labelsm={4}
                                    valuesm={5}
                                    optional="true"
                                />
                            </Row>
                            <Row>
                                <SelectInput
                                    id="taxFilingStatus"
                                    name="taxFilingStatus"
                                    label="Tax Filling Status"
                                    className="taxFillingStatus"
                                    itemlist={taxFillingStatusOp}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    required
                                    value={taxFilingStatus}
                                    errortext={errorMsg.taxFilingStatus}
                                    labelsm={4}
                                    valuesm={5}
                                    optional="true"
                                />
                            </Row> */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}

FinancialInfoCard.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    // localStateData: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
};

FinancialInfoCard.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    // localStateData: {},
    savedState: {},
    isJoint: false,
};

export default FinancialInfoCard;
