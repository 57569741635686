import { Col, Row, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { WButton } from 'common';

const assignObj = obj => {
    return obj;
};

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;



const BtnWrap = styled(Container)`
    display:flex; 
    justify-content: space-between;
    align-items:center;
    padding:20px 0;
    &>:nth-child(2){ 
        margin-left: auto;
        margin-right: 50px;
        button{
            margin-bottom: 0 !important;
        }
    }
    &>:nth-child(3){
        width:160px;
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:3;
        }
        &>:nth-child(2){
            order:2;
            margin: 0;
            button{
                margin:0 !important;
            } 
        }
        &>:nth-child(3){
            order:1;
            width:100%;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;

const CommonButtons = props => {
    const {
        backClick,
        backstyle,
        disabled,
        submit,
        submitClick,
        nextClick,
        nextstyle,
        saveClick,
        savestyle,
        showSave,
        blockstyle,
        submitText,
        nextText,
        backText,
        saveText,
        arialabelback,
        arialabelnext,
        arialabelcancel,
        arialabelsave,
        cancelStyle,
        cancelClick,
        cancelText,
        iscancel,
        analyzecustom,
        dataAnalyticId,
        cancelOffset,
        saveDisable,
    } = props;
    return (
        <div style={blockstyle}>
            {showSave ? (
                <div className="container">
                    <Row
                        style={assignObj({
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 15,
                        })}
                    >
                        <Col xs={2} style={assignObj({ textAlign: 'right' })}>
                            <WButton
                                variant="save"
                                buttontext={saveText}
                                id="saveButton"
                                onClick={saveClick}
                                buttonstyle={assignObj({ float: 'right', display: 'block', ...savestyle })}
                                dataAnalyticId={`${dataAnalyticId}_save`}
                                waitforapiresponse
                                analyzecustom={analyzecustom}
                                disabled={saveDisable}
                                arialabelForBtn={arialabelsave}
                            />
                        </Col>
                    </Row>
                </div>
            ) : null}
            <MainDiv>
                <BtnWrap>
                   
                    <div>
                        <WButton
                            id="desktopBackButton"
                            variant="back"
                            buttontext={backText}
                            onClick={backClick}
                            buttonstyle={assignObj({ float: 'left', display: 'block', ...backstyle })}
                            arialabelForBtn={arialabelback}
                            dataAnalyticId={`${dataAnalyticId}_back`}
                        />
                    </div>
                    {iscancel ? (
                        <div xs={cancelOffset || 2}>
                            <WButton
                                id="desktopCancelButton"
                                variant="cancel"
                                buttontext={cancelText}
                                onClick={cancelClick}
                                buttonstyle={assignObj({ float: 'left', display: 'block', ...cancelStyle })}
                                arialabelForBtn={arialabelcancel}
                                dataAnalyticId={`${dataAnalyticId}_cancel`}
                            />
                        </div>
                    ) : null}
                    <div>
                        <WButton
                            id="desktopNextButton"
                            disabled={disabled}
                            aria-disabled={disabled}
                            buttontext={submit === 'true' ? submitText : nextText}
                            onClick={submit === 'true' ? submitClick : nextClick}
                            analyzecustom={submit === 'true' ? analyzecustom : null}
                            waitforapiresponse={submit === 'true' ? true : false}
                            dataAnalyticId={
                                submit === 'true' ? `${dataAnalyticId}_submit` : `${dataAnalyticId}_next`
                            }
                            arialabelForBtn={arialabelnext}
                            buttonstyle={assignObj({
                                float: 'left',
                                width: '100%',
                                display: 'block',
                                ...nextstyle,
                            })}
                        />
                    </div>
                </BtnWrap>
            </MainDiv>
        </div>
    );
};

CommonButtons.propTypes = {
    backClick: PropTypes.func,
    submitClick: PropTypes.func,
    nextClick: PropTypes.func,
    saveClick: PropTypes.func,
    cancelClick: PropTypes.func,
    savestyle: PropTypes.instanceOf(Object),
    backstyle: PropTypes.instanceOf(Object),
    nextstyle: PropTypes.instanceOf(Object),
    blockstyle: PropTypes.instanceOf(Object),
    cancelStyle: PropTypes.instanceOf(Object),
    submit: PropTypes.string,
    disabled: PropTypes.bool,
    showSave: PropTypes.bool,
    submitText: PropTypes.string,
    nextText: PropTypes.string,
    saveText: PropTypes.string,
    backText: PropTypes.string,
    cancelText: PropTypes.string,
    arialabelback: PropTypes.string,
    arialabelnext: PropTypes.string,
    arialabelcancel: PropTypes.string,
    arialabelsave: PropTypes.string,
    ariadisable: PropTypes.bool,
    iscancel: PropTypes.bool,
    analyzecustom: PropTypes.instanceOf(Object),
    dataAnalyticId: PropTypes.string,
    cancelOffset: PropTypes.instanceOf(Object),
};

CommonButtons.defaultProps = {
    backClick: null,
    submitClick: null,
    nextClick: null,
    saveClick: null,
    savestyle: {},
    backstyle: {},
    nextstyle: {},
    blockstyle: {},
    submit: 'false',
    disabled: false,
    showSave: false,
    submitText: 'Submit',
    nextText: 'Next',
    saveText: 'Save',
    backText: 'Back',
    cancelText: 'Cancel',
    cancelStyle: {},
    cancelClick: null,
    arialabelback: '',
    arialabelnext: '',
    arialabelcancel: '',
    arialabelsave: '',
    ariadisable: true,
    iscancel: false,
    analyzecustom: null,
    dataAnalyticId: '',
    cancelOffset: null,
};

// To display save button this component should be given showSave as prop.
// 'disabled' prop which is given to this component is used to disable only the NEXT or SUBMIT button.

export default CommonButtons;
