const styles = {
    labelStyle: {
        color: '#56565A',
        font: "600 14px/20px 'benton-sans'",
        padding: '0 0 5px 0'
    },
    inputStyle: {
        padding: 0,
        margin: 0
    },
    dobStyle: {
        paddingLeft: 0
    }
}

export default styles;