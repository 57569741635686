import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import checkValidity from 'utils/checkValidity';
import bankAccSpecimen from 'assets/bank-account-specimen.png';
import { bankAccountAction } from 'shared/Actions';
import WCard from '../WCard/WCard';
import WInput from '../WInput/WInput';
import WRadio from '../Radio/Radio';
import WButton from '../WButton/WButton';

const SPECIMEN = 'Check leaf specimen';
const ACCOUNT_TYPE = 'Type of Account';
const SERVICE_ERROR =
    'We do not have sufficient information to verify this bank account. Please try another bank account or send in copy of a voided check through the Message Center.';

const assignObj = obj => {
    return obj;
};
const inlineStyles = {
    submitButtonLabel: { paddingRight: 0, marginLeft: 22 },
};
const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
// Workaround for styled component issue
const StyledColumn = styled(Col)`
    label {
        max-width: ${({ nomargin: { nomargin } }) => (nomargin ? 'none' : '17.8125')}rem;
    }
    max-width: 631px;
    .row {
        padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
        padding-right: 20px;
        div {
            max-width: 350px;
        }
    }
`;
const StyledColumnOne = styled(Col)`
    max-width: 452px;
    max-height: 388.5px;
    @media (max-width: 1200px) {
        && {
            padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
        }
    }
`;

const StyledRow = styled(Row)`
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.4375')}rem;
    padding-bottom: 40px;
`;

const StyledRowAlert = styled(Row)`
    @media (min-width: 1200px) {
        padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '27' : '27.5')}px;
    }
    padding-left: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '30')}px;
    padding-right: ${({ nomargin: { nomargin } }) => (nomargin ? '0' : '27')}px;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const FlexButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 20px 20px 0;
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const radiotext = {
    cololr: '#56565A',
    font: '100 16px/22px benton-sans',
};
const displayNoneStyle = {
    display: 'none',
};
const cardStyle = { display: 'flex', flexDirection: 'column', minHeight: 90, minWidth: 220 };
const specimenCornerColor = { borderLeftColor: 'rgba(139, 193, 5, 1)' };
const specimentTitleStyle = {
    textAlign: 'left',
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 26,
    fontWeight: '600',
    fontFamily: 'yorkten-slab-normal',
    float: 'left',
    borderBottom: '1px solid rgba(139, 193, 5, 1)',
};
const Rules = {
    transitRoutingNumber: { length: 9, isNumeric: true, required: true },
    accountNumber: { minLength: 4, maxLength: 17, isNumeric: true, required: true },
    financialInstitutionName: { maxLength: 30 },
    accountOwner: { maxLength: 30 },
};

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: ${({ nomargin: { nomargin } }) => (nomargin ? 0 : '2.5')}rem;
    margin-bottom: ${({ nomargin: { nomargin } }) => (nomargin ? '2.5' : 0)}rem;
`;

const errorRadioStyles = { color: 'red', font: ' 700 12px/22px benton-sans', position: 'absolute', top: 30 };

class AddBankCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankAccountType: 'Savings',
            bankTransitRoutingNumber: '',
            bankAccountNumber: '',
            bankAccountOwner: '',
            bankName: '',
            enabledAddButton: false,
            bankAccountDetails: [
                { label: 'Account Number', name: 'bankAccountNumber', value: '' },
                { label: 'Transit Routing Number', name: 'bankTransitRoutingNumber', value: '' },
                { label: 'Name of Account Owner(s)', name: 'bankAccountOwner', value: '' },
                { label: 'Name of Financial Institution', name: 'bankName', value: '' },
            ],
            errorMsg: {},
            bankAccountTypeOptions: [
                { value: 'Savings', key: 'Savings', selected: true },
                { value: 'Checking', key: 'Checking' },
            ],
            isVerifyBankSuccess: true,
            isAddBankSuccess: true,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { bankAccountInfo } = nextProps;
        const { bankAccountDetails } = prevState;
        const { verificationData } = bankAccountInfo;
        if (verificationData && verificationData.responseCode === 12004) {
            const bankDetails = [...bankAccountDetails];
            bankDetails[3].value = verificationData.bankName;

            return {
                ...prevState,
                bankName: verificationData.bankName,
                bankAccountDetails: bankDetails,
                isVerifyBankSuccess: true,
                enabledAddButton: true,
            };
        }
        if (verificationData && verificationData.responseCode === 12005) {
            const bankDetails = [...bankAccountDetails];
            bankDetails[3].value = '';

            return {
                ...prevState,
                isVerifyBankSuccess: false,
                bankName: '',
                bankAccountDetails: bankDetails,
                enabledAddButton: false,
            };
        }

        const bankDetails = [...bankAccountDetails];
        bankDetails[3].value = '';
        return {
            ...prevState,
            isVerifyBankSuccess: true,
            bankName: '',
            bankAccountDetails: bankDetails,
            enabledAddButton: false,
        };
    }

    componentDidMount() {
        const { clearVerifyAccDetails, clearAddBankData } = this.props;
        clearAddBankData();
        clearVerifyAccDetails();
    }

    handleBankAccountType = e => {
        const { target } = e;
        const accountType = target.id;
        this.setState({
            bankAccountType: accountType,
            showRadioErrorMsg: false,
            bankAccountTypeOptions: [
                { value: 'Savings', key: 'Savings', selected: accountType === 'Savings' },
                { value: 'Checking', key: 'Checking', selected: accountType === 'Checking' },
            ],
        });
    };

    handleBankAccountDetailsChange = index => e => {
        const { target } = e;
        const { clearAddBankData, clearVerifyAccDetails } = this.props;
        clearAddBankData();

        // this.setState(prevState => {
        const { bankAccountDetails } = this.state;
        let { value } = target;
        if (index === 0 || index === 1) {
            clearVerifyAccDetails();
            value = value.replace(/[^0-9]/g, '');
            bankAccountDetails[+index].value = value;
            // if (index === 2 || index === 3) {
            //     value = value.replace(/[^A-Za-z\s]/gi, '');
            // }

            this.setState({
                bankAccountDetails,
                alertMsg: false,
                enabledAddButton: false,
                isVerifyBankSuccess: true,
                bankName: '',
            });
        }
        if (index === 2) {
            bankAccountDetails[+index].value = value;
            this.setState({
                bankAccountDetails,
                alertMsg: false,
                enabledAddButton: false,
            });
        }
        // });
    };

    validateFields = () => {
        const { bankAccountDetails, bankAccountType, errorMsg, alertMsg } = this.state;
        let radioError = false;
        let valid = false;
        if (bankAccountType !== '') {
            valid = true;
        } else {
            radioError = true;
        }
        let err = '';
        if (bankAccountDetails) {
            bankAccountDetails.forEach(item => {
                if (item.name !== 'financialInstitutionName') {
                    err = Rules[item.name]
                        ? checkValidity({
                              rules: Rules[item.name],
                              value: item.value,
                          })
                        : '';
                    errorMsg[item.name] = err ? 'Please enter a valid value' : '';
                }
            });
            if (Object.keys(errorMsg).length !== 0) {
                bankAccountDetails.forEach(item => {
                    if (item.name !== 'financialInstitutionName') {
                        if (errorMsg[item.name] === null || errorMsg[item.name] === '') {
                            valid = valid && true;
                        } else {
                            valid = false;
                        }
                    }
                });
            }
        }

        valid = valid && !alertMsg;
        this.setState({ errorMsg, showRadioErrorMsg: radioError });
        return valid;
    };

    getState = () => {
        const valid = this.validateFields();
        return { ...this.state, valid };
    };

    handleOnlineSectionBlur = e => {
        const { target } = e;
        let err = '';
        // err = Rules[target.name]
        //     ? checkValidity({
        //           rules: Rules[target.name],
        //           value: target.value,
        //       })
        //     : '';

        if (target.name === 'bankAccountNumber') {
            if (!target.value) {
                err = "Account Number can't be left blank";
            } else if (target.value.length < 4) {
                err = 'Account Number must be between 4 to 17 digits';
            } else {
                const { bankTransitRoutingNumber, bankAccountOwner } = this.state;
                if (bankTransitRoutingNumber !== '' && bankAccountOwner !== '') {
                    const { bankAccountType } = this.state;
                    const { validateBankAccInfo } = this.props;
                    validateBankAccInfo({
                        bankAccountType,
                        transitRoutingNumber: bankTransitRoutingNumber,
                        bankAccountNumber: target.value,
                        accountOwnersName: bankAccountOwner,
                    });
                }
            }
        }
        if (target.name === 'bankTransitRoutingNumber') {
            if (!target.value) {
                err = "Transit Routing Number can't be left blank";
            } else if (target.value.length !== 9) {
                err = 'Transit Routing Number must be 9 digits';
            } else {
                const { bankAccountType, bankAccountNumber, bankAccountOwner } = this.state;
                if( bankAccountOwner !== '' ) {
                    const { validateBankAccInfo } = this.props;
                    validateBankAccInfo({
                        bankAccountType,
                        transitRoutingNumber: target.value,
                        bankAccountNumber,
                        accountOwnersName: bankAccountOwner,
                    });
                }
            }
        }
        if (target.name === 'bankAccountOwner') {
            if (!target.value) {
                err = "Please enter account owner's name";
            } else {
                const { bankAccountType, bankAccountNumber, bankTransitRoutingNumber } = this.state;
                if (bankTransitRoutingNumber !== '') {
                    const { validateBankAccInfo } = this.props;
                    validateBankAccInfo({
                        bankAccountType,
                        transitRoutingNumber: bankTransitRoutingNumber,
                        bankAccountNumber,
                        accountOwnersName: target.value,
                    });
                }
            }
        }

        const { errorMsg } = this.state;
        errorMsg[target.name] = err;
        // const valid = this.validateFields();
        this.setState({
            errorMsg,
            [target.name]: target.value,
            enabledAddButton: !(
                errorMsg.bankName === '' &&
                errorMsg.bankTransitRoutingNumber === '' &&
                errorMsg.bankAccountNumber === '' &&
                errorMsg.bankAccountOwner === ''
            ),
        });
    };

    render() {
        const {
            errorMsg,
            bankAccountTypeOptions,
            // isApiSuccess,
            // alertMsg = false,
            bankAccountType,
            bankAccountDetails,
            showRadioErrorMsg = false,
            isVerifyBankSuccess,
        } = this.state;
        let { enabledAddButton } = this.state;
        const { nomargin, viewOnly, handleAdd, responseCode } = this.props;
        if (responseCode === '4501') {
            enabledAddButton = false;
        }
        let isAddBankSuccess = true;
        if (responseCode) {
            isAddBankSuccess = responseCode !== '4501';
        }
        // const disable = !errorMsg.transitRoutingNumber && !errorMsg.accountNumber && isApiSuccess && !alertMsg;
        // // Workaround for styled component issue
        const marginProps = { nomargin: { nomargin } };
        const radioErrorMsg = 'Please select an Account Type';

        return (
            <>
                <StyledRowAlert {...marginProps} noGutters={!nomargin}>
                    <Col>
                        {!isAddBankSuccess && (
                            <StyledAlert {...marginProps} variant="danger">
                                <StyledPara>{SERVICE_ERROR}</StyledPara>
                            </StyledAlert>
                        )}
                        {!isVerifyBankSuccess && (
                            <StyledAlert {...marginProps} variant="danger">
                                <StyledPara>{SERVICE_ERROR}</StyledPara>
                            </StyledAlert>
                        )}
                    </Col>
                </StyledRowAlert>
                <StyledRow {...marginProps} noGutters>
                    <StyledColumn sm={7} {...marginProps}>
                        <Row noGutters style={assignObj({ marginBottom: 20 })}>
                            <StyledLabel column htmlFor="AccountType">
                                {ACCOUNT_TYPE}
                            </StyledLabel>
                            <input type="hidden" name="AccountType" id="AccountType" />
                            <Col sm={8}>
                                <FlexRow role="radiogroup">
                                    {bankAccountTypeOptions.map(item => {
                                        return (
                                            <WRadio
                                                id={item.value}
                                                key={item.key}
                                                name={item.value}
                                                value={item.value}
                                                selected={bankAccountType === item.value}
                                                onClick={this.handleBankAccountType}
                                                radiotext={radiotext}
                                                // selected={item.selected}
                                                viewOnly={viewOnly}
                                            />
                                        );
                                    })}
                                </FlexRow>
                                {showRadioErrorMsg && <span style={errorRadioStyles}>{radioErrorMsg}</span>}
                            </Col>
                        </Row>
                        {bankAccountDetails.map((data, index) => {
                            const length = { maxlength: 25 };
                            if (index === 0) {
                                length.maxlength = 17;
                                length.minlength = 4;
                            } else if (index === 1) {
                                length.maxlength = 9;
                            } else if (index === 2) {
                                length.maxlength = 30;
                            }
                            return viewOnly && (data.value === undefined || data.value.length === 0) ? null : (
                                <WInput
                                    key={index.toString()}
                                    type="text"
                                    controlid={data.name}
                                    label={data.label}
                                    labelsm={0}
                                    valuesm={8}
                                    placeholder={data.label}
                                    value={data.value || ''}
                                    name={data.name}
                                    id={data.name}
                                    onChange={this.handleBankAccountDetailsChange(index)}
                                    onBlur={this.handleOnlineSectionBlur}
                                    errortext={errorMsg[data.name]}
                                    readOnly={index === 3}
                                    viewOnly={viewOnly}
                                    noGutters
                                    {...length}
                                />
                            );
                        })}
                    </StyledColumn>
                    {viewOnly ? null : (
                        <StyledColumnOne {...marginProps} sm={5}>
                            <FlexCol>
                                <WCard
                                    className="specimenCard"
                                    buttonstyle={displayNoneStyle}
                                    src={bankAccSpecimen}
                                    cardstyle={cardStyle}
                                    leftcornercard={specimenCornerColor}
                                    cardtext="Account number and all owner names must be preprinted and unaltered"
                                    cardtitletext={SPECIMEN}
                                    cardtitlestyle={specimentTitleStyle}
                                    alt={SPECIMEN}
                                />
                            </FlexCol>
                        </StyledColumnOne>
                    )}
                </StyledRow>

                <FlexButtonRow>
                    <WButton
                        submit="submit"
                        buttontext="Add Bank Account"
                        id="add-bank-account"
                        aria-label="add bank account"
                        buttonstyle={inlineStyles.submitButtonLabel}
                        onClick={handleAdd}
                        disabled={!enabledAddButton}
                    />
                </FlexButtonRow>
            </>
        );
    }
}

AddBankCard.propTypes = {
    setStatus: PropTypes.func,
    validateBankAccInfo: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    clearVerifyAccDetails: PropTypes.func,
    bankAccountInfo: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    nomargin: PropTypes.bool,
    viewOnly: PropTypes.bool,
    handleAdd: PropTypes.func,
    apiResponse: PropTypes.string,
    responseMessage: PropTypes.string,
    responseCode: PropTypes.string,
    clearAddBankData: PropTypes.func,
};

AddBankCard.defaultProps = {
    setStatus: () => {},
    validateBankAccInfo: () => {},
    clearBankAccDetails: () => {},
    clearVerifyAccDetails: () => {},
    bankAccountInfo: {},
    savedState: {},
    nomargin: false,
    viewOnly: false,
    handleAdd: () => {},
    apiResponse: '',
    responseMessage: '',
    responseCode: '',
    clearAddBankData: () => {},
};

export const mapStateToProps = state => {
    return {
        bankAccountInfo: state.bankAccountReducerData,
    };
};
export const mapDispatchToProps = {
    validateBankAccInfo: bankAccountAction?.validateBankAccInfo,
    clearBankAccDetails: bankAccountAction?.clearBankAccDetails,
    clearVerifyAccDetails: bankAccountAction?.clearVerifyAccDetails,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddBankCard);
