import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions, loginActions } from '../../../../shared/Actions';
import CreatePassword from './CreatePassword';

export const mapStateToProps = (state /* , props */) => ({
    registerLoginCredentialsState: state.localStateManagementReducerData.registerLoginCredentialsState,
    personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    registerState: state.localStateManagementReducerData.registerState,
    resetPasswordData: state.loginData.resetPassword,
    resetGuestPasswordData: state.loginData.resetPasswordForGuest,
    recoverPwrdGuestEmailVerifyData: state.loginData.recoverPwrdGuestEmailVerify,
    loginState: state.loginData
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...loginActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePassword));
