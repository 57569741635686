/** Personal  Information Card
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide Personal information details
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Card } from 'react-bootstrap';
import { Tooltip } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import { formatDate, isValidDate } from 'utils';
import { WFileUploader, WInput, VCMSelect, RadioList, SSNComponent, VCMDatePicker, WRadio } from '../../../common';
import Consts from '../PersonalInfo/consts';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import Address from '../Address';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';
import EmailDetailsCardComponent from '../Email/EmailDetailsCardComponent';
import PhoneDetailsCardComponent from '../PhoneDetails/PhoneDetailsCardComponent';
import CitizenshipRadioList from './Common/CitizenshipRadioList/CitizenshipRadioList';
import { getDataFromORCondition, isUserGuestOrProspect } from 'utils/utils';
import { checkArrayLengthAndReturnValue, getValidValueOf, isRoleNotGuestAndProspect } from 'commonHelper/CommonHelperFunctions';

const assignObj = obj => {
    return obj;
};
const citizenshipLabel = 'Citizenship';
const genderLabel = 'Gender';
const w8FormLabel = 'Upload W-8 form';
const citizenshipError = 'Please select Citizenship';
// const selectGender = 'Please select Gender';
const w8FormExplain = 'What is W-8 form?';
const w8FormTooltip = 'Upload W8 Form';
const sameAddressRadioLabel = 'Is your physical address the same as your mailing address?';
const sameAddressRadioError = 'Please indicate if the physical address is same as mailing address';
// const sameAddressRadioError = 'Please select a value';
const dateOfBirthLabel = 'Date of Birth';
const contactInfoHeading = 'Contact Information';
const mailToCSR = 'Mail To';
const ortext = '(or)';
const victoryCapital = 'Victory Capital';
const postOffice = 'P.O. Box 659453';
const postAddress = 'San Antonio, TX 78265-9009';

// const hasKey = (obj, key) => (obj ? Object.prototype.hasOwnProperty.call(obj, key) : false);

const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const radioText = { font: '500 16px/20px benton-sans' };

class PersonalInfoCardComponent extends Component {
    constructor(props) {
        super(props);

        const profileCitizenship =
            props.personalInfo.profileInformation?.citizenship !== '' &&
            props.personalInfo.profileInformation?.citizenship === 'Non U.S'
                ? 'nonus'
                : 'us';
        const role = localStorage.getItem('role');
        this.state = {
            errorMsg: {},
            fullSSN: '',
            isSSNerror: false,
            tooltipOpen: false,
            showMenu: false,
            loading: true,
            perDetailsHeadreClick: true,
            contactDetailsHeadreClick: true,
            citizenship: profileCitizenship || 'us', // By default, U.S should be selected
            retrievedState: false,
            prefixLocal: '',
            dateOfBirthLocal: '',
            genderLocal: '',
            maritalLocal: '',
            sameAddress: role === 'Guest' || role === 'Prospect' ? '' : this.getIsSameAddress(),
            // w8FormUpload: '',
            // originalW8FileArray: [],
        };
        this.perMailAdrressPref = React.createRef();
        this.perPhysicalAddressPref = React.createRef();
        // this.jointPerMailAddressPref = React.createRef();
        // this.jointPerPhysicalAddressPref = React.createRef();
        this.phoneDetailsRef = React.createRef();
        this.addressAccordion = React.createRef();
        this.personalRef = React.createRef();
        this.ssnRef = React.createRef();
        // this.jointssnRef = React.createRef();
        this.emailAddressRef = React.createRef();
        // this.jointEmailAdressRef = React.createRef();
        this.checkPersonalInfoParentNextStatus = this.checkPersonalInfoParentNextStatus.bind(this);
        this.checkPersonalInfoParentChildNextStatus = this.checkPersonalInfoParentChildNextStatus.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, masterLookupStateData, localStateData } = nextProps;
        const { genderLocal, maritalLocal, prefixLocal, dateOfBirthLocal } = prevState;
        const { OpenAccPageTwo, accountType = '' } = localStateData;
        const isLocalStateData = localStateData && localStateData[accountType.toString()];

        let prefixLocalState = getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.prefix,'');
        let dateOfBirthLocalState = getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.dateOfBirth,'');
        let genderLocalState = getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.gender,'');
        let maritalLocalState = getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.maritalStatus,'');

        const openAccPageTwoPersonalInfo = OpenAccPageTwo && OpenAccPageTwo.personalInfo;

        if (genderLocal) {
            genderLocalState = genderLocal;
        }

        if (dateOfBirthLocal) {
            dateOfBirthLocalState = dateOfBirthLocal;
        }

        if (maritalLocal) {
            maritalLocalState = maritalLocal;
        }

        if (prefixLocal) {
            prefixLocalState = prefixLocal;
        }

        if (!prevState.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
                citizenship:
                    OpenAccPageTwo && OpenAccPageTwo.personalInfo
                        ? OpenAccPageTwo.personalInfo.citizenship
                        : prevState.citizenship,
                sameAddress:
                    OpenAccPageTwo && OpenAccPageTwo.personalInfo
                        ? OpenAccPageTwo.personalInfo.sameAddress
                        : prevState.sameAddress,
                // w8FormUpload:
                //     openAccPageTwoPersonalInfo && OpenAccPageTwo.personalInfo.w8FormUpload
                //         ? OpenAccPageTwo.personalInfo.w8FormUpload
                //         : prevState.w8FormUpload,
                // originalW8FileArrayFromMount:
                //     openAccPageTwoPersonalInfo && OpenAccPageTwo.personalInfo.originalW8FileArray
                //         ? OpenAccPageTwo.personalInfo.originalW8FileArray
                //         : prevState.originalW8FileArray,
            };
        }
        if (masterLookupStateData.isLoading) {
            return { loading: true };
        }
        if (!masterLookupStateData.isLoading) {
            const prefixReduxState = openAccPageTwoPersonalInfo && OpenAccPageTwo.personalInfo.prefix;
            return {
                loading: false,
                firstName: getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.firstName,''),
                middleInitial: getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.middleInitial,''),
                lastName: getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.lastName,''),
                gender: genderLocalState,
                dateOfBirth:
                    openAccPageTwoPersonalInfo && OpenAccPageTwo.personalInfo.dateOfBirth
                        ? OpenAccPageTwo.personalInfo.dateOfBirth
                        : dateOfBirthLocalState,
                prefix: prefixLocalState || prefixReduxState,
                maritalStatus:
                    openAccPageTwoPersonalInfo && OpenAccPageTwo.personalInfo.maritalStatus
                        ? OpenAccPageTwo.personalInfo.maritalStatus
                        : maritalLocalState,
                suffix: getValidValueOf(isLocalStateData,localStateData[accountType.toString()]?.perInfo?.suffix,''),
                maritalLocal: maritalLocalState,
                prefixLocal: prefixLocalState,
                dateOfBirthLocal: dateOfBirthLocalState,
                genderLocal: genderLocalState,
            };
        }

        // Error Handling
        if (masterLookupStateData.isError) {
            return { errorFromMasterDataApi: true };
        }

        return {};
    }

    componentDidMount() {
        const { masterLookupStateData, getCompositeLookUpData, localStateData } = this.props;
        const { accountType } = localStateData;
        const accType = accountType;
        if (accType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo) {
            const {
                firstName,
                lastName,
                gender,
                suffix,
                dateOfBirth,
                maritalStatus,
                citizenship,
                prefix,
                middleInitial,
            } = localStateData.OpenAccPageTwo.personalInfo;
            this.setState({
                firstName,
                lastName,
                genderLocal: gender,
                suffix,
                dateOfBirth,
                maritalStatus,
                citizenship,
                prefix,
                middleInitial,
            });
        }
        this.setState(
            {
                readOnly: true,
            },
            () => {
                const { errorMSg } = this.state;
                this.checkPersonalInfoParentChildNextStatus(errorMSg);
            },
        );
        // if (accType && localStateData.OpenAccPageTwo) {
        //     const personalData = localStateData.OpenAccPageTwo.personalInfo;
        //     if (personalData.originalW8FileArray !== undefined) {
        //         this.setState({ originalW8FileArrayFromMount: personalData.originalW8FileArray });
        //     }
        // } else {
        const payload = [];
        const compositePayloadData = [
            'citizenship',
            'marital_status',
            'prefix',
            'suffix',
            'relationship',
            'gender',
            'contact_time',
        ];
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
        getCompositeLookUpData(payload);
        // }
    }

    get accordionRef() {
        const emailRef = this.emailAddressRef;

        const {
            isPersonalInfoFeildsValid,
            isPhoneValid,
            isEmailValid,
            isperMailAddressDataValid,
            isSameAddress,
        } = this.checkValidSections();
        const errorSections = [
            {
                element: this.personalRef.current,
                valid: isPersonalInfoFeildsValid,
            },
            {
                element: this.addressAccordion.current,
                valid: isperMailAddressDataValid,
            },
            {
                element: this.phoneDetailsRef.current.contactAccordion.current,
                valid: isPhoneValid,
            },
            {
                element: emailRef.current.emailAccordion.current,
                valid: isEmailValid,
            },
            {
                element: this.addressAccordion.current,
                valid: isSameAddress,
            },
        ];

        return {
            current: {
                scrollIntoView: options => {
                    const firstErrorSection = errorSections.find(section => !section.valid);
                    if (firstErrorSection?.element) {
                        firstErrorSection.element.scrollIntoView(options);
                    }
                },
            },
        };
    }

    handleBlur = eventKey => e => {
        const { errorMsg } = this.state;
        const err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState(
            () => {
                const result = this.checkPersonalInfoParentNextStatus(errorMsg);
                return {
                    errorMsg,
                    ready: result,
                };
            },
            () => {
                this.checkPersonalInfoParentChildNextStatus();
            },
        );
    };

    getMaritalStatusDescription = marital => {
        let maritalStatus = marital;

        switch (marital) {
            case 'D':
                maritalStatus = 'Divorced';
                break;
            case 'M':
                maritalStatus = 'Married';
                break;
            case 'S':
                maritalStatus = 'Single';
                break;
            case 'SE':
                maritalStatus = 'Separated';
                break;
            case 'W':
                maritalStatus = 'Widowed';
                break;
            default:
                break;
        }
        return maritalStatus;
    };

    handleChange = eventKey => e => {
        const { errorMsg } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        if (eventKey === 'dateOfBirthLocal') {
            const { sendDOB } = this.props;
            sendDOB(e.target.value);
            this.setState({ [eventKey]: e.target.value, dateOfBirth: e.target.value });
        }
        if (eventKey === 'maritalLocal') {
            errorMsg['maritalStatus'.toString()] = err;
            this.setState({
                [eventKey]: this.getMaritalStatusDescription(e.target.value),
                errorMsg,
                maritalStatus: e.target.value,
            });
        } else {
            this.setState({ [eventKey]: e.target.value, errorMsg });
        }
    };

    cardHeaderClick = e => {
        e.preventDefault();
        e.target.classList.toggle('expand');
        const { perDetailsHeadreClick } = this.state;
        this.setState({ perDetailsHeadreClick: !perDetailsHeadreClick });
    };

    clearInitialFiles = () => {
        this.setState({
            localForm: null,
            w8FormUpload: undefined,
            originalW8FileArray: null,
            originalW8FileArrayFromMount: null,
        });
    };

    onDrop = files => {
        const fileProperties = ['lastModified', 'lastModifiedDate', 'name', 'size', 'type', 'webkitRelativePath'];
        const fileBlob = files;
        const newFile = {};
        fileProperties.forEach(key => {
            newFile[key.toString()] = fileBlob[key.toString()];
        });

        return newFile;
    };

    uploadW8File = fileData => {
        if (fileData !== undefined) {
            // const { uploadW8Form } = this.props;
            const { originalW8FileArray, W8FileUploaded } = this.state;
            if (originalW8FileArray === null || (originalW8FileArray === undefined && !W8FileUploaded)) {
                // const payload = {
                //     Body: fileData.w8FormUpload[0].base64String,
                // };
                const fileMetaData = this.onDrop(fileData.originalw8FormUpload);
                this.setState({
                    w8FormUpload: fileData.w8FormUpload[0].base64String,
                    originalW8FileArray: [fileData.originalw8FormUpload],
                    fileMetaData,
                });
                //    uploadW8Form(payload);
            }
        }
    };

    toggleTooltip = () => {
        const { tooltipOpen } = this.state;
        this.setState({ tooltipOpen: !tooltipOpen });
    };

    checkEachFieldStatus = finalFields => {
        let error = {};
        let valid = false;
        const stateData = this.state;
        const invalidFiled = [];
        const { localStateData } = this.props;
        const { accountType } = localStateData;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedSSNData } = savedData;
        finalFields.forEach(field => {
            let errorField = '';
            switch (field) {
                case 'dateOfBirth':
                case 'maritalStatus':
                case 'firstName':
                case 'lastName':
                case 'relationship':
                    errorField = Rules[field.toString()]
                        ? checkValidity({
                              rules: Rules[field.toString()],
                              value: stateData[field.toString()] === 'Error' ? '' : stateData[field.toString()],
                          })
                        : '';
                    break;
                case 'SSN':
                    errorField = Rules[field.toString()]
                        ? checkValidity({
                              rules: Rules[field.toString()],
                              value: stateData.fullSSN || (savedSSNData && savedSSNData.fullSSN) || '',
                          })
                        : '';
                    if (errorField !== null) {
                        this.setState({ isSSNerror: true });
                    } else {
                        this.setState({ isSSNerror: false });
                    }
                    break;
                case 'gender':
                case 'citizenship':
                    errorField = stateData[field.toString()] ? !stateData[field.toString()] : true;
                    break;
                default:
                    break;
            }

            if (typeof errorField === 'boolean') {
                valid = errorField === true;
                invalidFiled.push(valid);
            } else if (typeof errorField === 'string') {
                valid = errorField !== '';
                invalidFiled.push(valid);
            }
            error = { ...error, [field]: errorField };
        });

        valid = !invalidFiled.some(filed => filed === true);
        return { error, valid };
    };

    fieldsValid = () => {
        const { errorMsg } = this.state;
        const fields = [
            'dateOfBirth',
            'gender',
            'maritalStatus',
            'citizenship',
            'SSN',
            'sameAddress',
            'firstName',
            'lastName',
        ];
        const status = this.checkEachFieldStatus(fields);

        this.setState({ errorMsg: { ...errorMsg, ...status.error } });
        return status.valid;
    };

    getChildData = () => {
        const phoneDetailsData = this.phoneDetailsRef.current && this.phoneDetailsRef.current.sendDataToParent();
        const perMailAddressData = this.perMailAdrressPref.current && this.perMailAdrressPref.current.getAddress();
        const perPhysicalAddressData =
            this.perPhysicalAddressPref.current && this.perPhysicalAddressPref.current.getAddress();
        const emailAddressData = this.emailAddressRef.current && this.emailAddressRef.current.sendDataToParent();
        const ssnData = this.ssnRef.current && this.ssnRef.current.getSSN();
        return {
            phoneDetailsData,
            perMailAddressData,
            perPhysicalAddressData,
            emailAddressData,
            ssnData,
        };
    };

    openAccrodions = (validPerInfo, validPhoneInfo, validEmailInfo, validAddressInfo) => {
        if (!validPerInfo) {
            // this.personalRef.current.click();
            this.personalRef.current.click();
        }

        if (!validPhoneInfo) {
            this.phoneDetailsRef.current.contactAccordion.current.click();
        }

        if (!validEmailInfo) {
            this.emailAddressRef.current.emailAccordion.current.click();
        }

        if (!validAddressInfo) {
            this.addressAccordion.current.click();
        }
    };

    checkValidSections = () => {
        const childData = this.getChildData();
        const { sameAddress } = this.state;
        const { phoneDetailsData, perMailAddressData, perPhysicalAddressData, emailAddressData, ssnData } = childData;
        const isPersonalInfoFeildsValid = this.fieldsValid();
        const isPhoneValid = phoneDetailsData && phoneDetailsData.valid;
        const isPrimaryEmailValid = emailAddressData && emailAddressData.valid;
        const isSameAddress = sameAddress === 'Y' || sameAddress === 'N';

        const isperMailAddressDataValid = perMailAddressData && perMailAddressData.valid;
        const isperPhysicalAddressDataValid = perPhysicalAddressData ? perPhysicalAddressData.valid : true;
        const isEmailValid = isPrimaryEmailValid;
        if (!isSameAddress && (sameAddress !== 'Y' || sameAddress !== 'N')) {
            this.setState({
                errorMsg: {
                    sameAddress: sameAddressRadioError,
                },
            });
        }

        return {
            isPrimaryEmailValid,
            isPersonalInfoFeildsValid,
            isPhoneValid,
            isEmailValid,
            isperMailAddressDataValid,
            isperPhysicalAddressDataValid,
            ssnData,
            phoneDetailsData,
            perMailAddressData,
            perPhysicalAddressData,
            emailAddressData,
            isSameAddress,
        };
    };

    sendDataToParent = () => {
        const {
            isPersonalInfoFeildsValid,
            isPhoneValid,
            isEmailValid,
            isperMailAddressDataValid,
            isperPhysicalAddressDataValid = true,
            ssnData,
            phoneDetailsData,
            perMailAddressData,
            perPhysicalAddressData,
            emailAddressData,
            isSameAddress,
        } = this.checkValidSections();
        const { primaryPhoneDetails, secondaryPhoneDetails } = phoneDetailsData;
        const isPersonalInfoCardValid =
            isPersonalInfoFeildsValid &&
            isPhoneValid &&
            isEmailValid &&
            isperMailAddressDataValid &&
            isperPhysicalAddressDataValid &&
            isSameAddress;
        const tempAddress = {
            SSN: ssnData && ssnData.fullSSN,
            ssnData,
            emailAddressData,
            primMailAddress: perMailAddressData,
            primPhysicalAddress: perPhysicalAddressData,
            primaryPhoneDetails,
            secondaryPhoneDetails,
        };

        // if (!isPersonalInfoCardValid) {
        //     this.openAccrodions(
        //         isPersonalInfoFeildsValid,
        //         isPhoneValid,
        //         isPrimaryEmailValid,
        //         isperMailAddressDataValid,
        //         isSameAddress,
        //     );
        // }
        return { ...this.state, ...tempAddress, valid: isPersonalInfoCardValid };
    };

    handleSSN = val => {
        this.setState({ fullSSN: val });
    };

    handleRadioBlur = eventKey => e => {
        e.persist();
        const { errorMsg } = this.state;
        const stateData = { ...this.state };
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: stateData[eventKey.toString()],
              })
            : '';
        errorMsg[eventKey.toString()] = err;
        this.setState({ errorMsg, [eventKey]: e.target.value });
    };

    getValue = data => {
        this.setState(data);
    };

    getAddressType = type => {
        switch (type) {
            case 'U.S or U.S Territories':
                return 'us';
            case 'APO (Army or Air Force Post Office)':
                return 'apo';
            case 'FPO (Fleet Post Office)':
                return 'fpo';
            case 'DPO (Diplomatic Post Office)':
                return 'dpo';
            default:
                return 'us';
        }
    };

    isSameAddress = (savedPerMailAddress, savedPhysicalAddress) => {
        if (savedPerMailAddress && savedPhysicalAddress) {
            const isSameAddressType = savedPerMailAddress.addressType === savedPhysicalAddress.addressType;
            const isSameAddrLine1 = savedPerMailAddress.addressLine1 === savedPhysicalAddress.addressLine1;
            const isSameAddrLine2 = savedPerMailAddress.addressLine2 === savedPhysicalAddress.addressLine2;
            const isSameZip = savedPerMailAddress.zip === savedPhysicalAddress.zip;
            const isSameCity = savedPerMailAddress.city === savedPhysicalAddress.city;
            const isSameState = savedPerMailAddress.state === savedPhysicalAddress.state;

            return isSameAddressType && isSameAddrLine1 && isSameAddrLine2 && isSameZip && isSameCity && isSameState;
        }
    };

    getIsSameAddress = () => {
        const { personalInfo } = this.props;
        let savedPerMailAddress = {};
        let savedPhysicalAddress = {};
        if (personalInfo.profileInformation?.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const mailAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address.isMailingAddress);
            if (mailAddressInfo.length > 0) {
                savedPerMailAddress = {
                    addressType: this.getAddressType(mailAddressInfo.addressType),
                    addressLine1: mailAddressInfo[0].addressLine1,
                    addressLine2: mailAddressInfo[0].addressLine2,
                    zip: mailAddressInfo[0].zip,
                    city: mailAddressInfo[0].city,
                    state: mailAddressInfo[0].state,
                };
            }
        }

        if (personalInfo.profileInformation?.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const physicalAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address.isPhysicalAddress);

            if (physicalAddressInfo.length > 0) {
                savedPhysicalAddress = {
                    addressType: this.getAddressType(physicalAddressInfo[0].addressType),
                    addressLine1: physicalAddressInfo[0].addressLine1,
                    addressLine2: physicalAddressInfo[0].addressLine2,
                    zip: physicalAddressInfo[0].zip,
                    city: physicalAddressInfo[0].city,
                    state: physicalAddressInfo[0].state,
                };
            }
        }

        return this.isSameAddress(savedPerMailAddress, savedPhysicalAddress) ? 'Y' : 'N';
    };

    getAlreadySavedData = accountType => {
        const { localStateData, personalInfo } = this.props;
        let savedPerMailAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.mailingAddress;

        if (!savedPerMailAddress && personalInfo.profileInformation?.addressInformation) {
            const { addressInformation } = personalInfo.profileInformation;
            const mailAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address.isMailingAddress);
            if (mailAddressInfo.length > 0) {
                savedPerMailAddress = {
                    addressType: this.getAddressType(mailAddressInfo.addressType),
                    addressLine1: mailAddressInfo[0].addressLine1,
                    addressLine2: mailAddressInfo[0].addressLine2,
                    zip: mailAddressInfo[0].zip,
                    city: mailAddressInfo[0].city,
                    state: mailAddressInfo[0].state,
                };
            }
        }

        let savedPhysicalAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.physicalAddress;

        if (!savedPhysicalAddress && personalInfo.profileInformation?.addressInformation) {
            const { profileInformation } = personalInfo;
            const { addressInformation } = profileInformation;
            const physicalAddressInfo =
                addressInformation.length > 0 && addressInformation.filter(address => address.isPhysicalAddress);

            if (physicalAddressInfo.length > 0) {
                savedPhysicalAddress = {
                    addressType: this.getAddressType(physicalAddressInfo[0].addressType),
                    addressLine1: physicalAddressInfo[0].addressLine1,
                    addressLine2: physicalAddressInfo[0].addressLine2,
                    zip: physicalAddressInfo[0].zip,
                    city: physicalAddressInfo[0].city,
                    state: physicalAddressInfo[0].state,
                };
            }
        }

        const savedSameAddress = this.isSameAddress(savedPerMailAddress, savedPhysicalAddress);

        // Phone Details
        const savedPersonalInfoDetails =
            accountType && localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.personalInfo;

        let savedSSNData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.ssnData;
        if (!savedSSNData && personalInfo.profileInformation?.ssnTin) {
            const { ssnTin } = personalInfo.profileInformation;
            savedSSNData = {
                ssnOne: ssnTin
                    .split('')
                    .slice(0, 3)
                    .join()
                    .replace(/,/g, ''),
                maskedssnOne: 'XXX',
                ssnTwo: ssnTin
                    .split('')
                    .slice(3, 5)
                    .join()
                    .replace(/,/g, ''),
                maskedssnTwo: 'XX',
                ssnThree: ssnTin
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, ''),
                maskedssnThree: ssnTin
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, ''),
                fullSSN: ssnTin,
                errorOneText: '',
                errorTwoText: '',
                errorThreeText: '',
                readOnlySsnOne: true,
                readOnlySsnTwo: true,
                typeSsnOne: 'password',
                typeSsnTwo: 'password',
                retrievedState: true,
                isSSNOneError: false,
                isSSNTwoError: false,
                isSSNThreeError: false,
                maskedFullSSN: `XXX-XX-${ssnTin
                    .split('')
                    .slice(5, 9)
                    .join()
                    .replace(/,/g, '')}`,
            };
        }

        const savedEmailAddressData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.emailAddress;

        return {
            savedPerMailAddress,
            savedPhysicalAddress,
            savedPersonalInfoDetails,
            savedSSNData,
            savedEmailAddressData,
            savedSameAddress,
        };
    };

    getMasterDataValues = () => {
        const { masterLookupStateData } = this.props;
        /** Master Data State handling Start */
        const {
            citizenship: citizenshipMaster,
            marital_status: maritalStatusMaster,
            prefix: prefixMaster,
            suffix: suffixMaster,
            relationship: relationshipMaster,
            gender: genderMaster,
            contact_time: contactTimeMaster,
        } = masterLookupStateData || {};
        const { value: citizenshipMasterValue } = citizenshipMaster || {};
        const { value: maritalStatusMasterValue } = maritalStatusMaster || {};
        const { value: prefixMasterValue } = prefixMaster || {};
        const { value: suffixMasterValue } = suffixMaster || {};
        const { value: relationshipMasterValue } = relationshipMaster || {};
        const { value: genderMasterValue } = genderMaster || {};
        const { value: contactTimeMasterDataValue } = contactTimeMaster || {};
        const contactTimeOp = contactTimeMasterDataValue || [];
        const suffixOp = suffixMasterValue || [];
        const citizenshipOp = citizenshipMasterValue || [];
        const maritalStatus = maritalStatusMasterValue || [];
        const prefixOp = prefixMasterValue || [];
        const relationshipOp = relationshipMasterValue || [];
        const genderOp = genderMasterValue || [];
        /** Master Data State Handling End */
        return {
            contactTimeOp,
            suffixOp,
            citizenshipOp,
            maritalStatus,
            prefixOp,
            relationshipOp,
            genderOp,
        };
    };

    getMaritalStatus = () => {
        const { maritalStatus } = this.state;
        if (maritalStatus === 'Separated') {
            return 'SE';
        }
        return maritalStatus && maritalStatus.split('')[0].toUpperCase();
    };

    formatPhoneNumber = phoneNumberInitial => {
        let formattedPhoneNumber = phoneNumberInitial;
        if (formattedPhoneNumber && formattedPhoneNumber.toString().trim().length > 0) {
            formattedPhoneNumber = formattedPhoneNumber.replace(/[-\s()]/g, '');
            if (formattedPhoneNumber.length === 10) {
                const stateCode = formattedPhoneNumber.substr(0, 3);
                const areaCode = formattedPhoneNumber.substr(3, 3);
                const phoneNumber = formattedPhoneNumber.substr(6, 4);
                formattedPhoneNumber = `${stateCode}-${areaCode}-${phoneNumber}`;
            } else {
                formattedPhoneNumber = '';
            }
        }
        return formattedPhoneNumber;
    };

    checkPersonalInfoParentChildNextStatus() {
        const { errorMsg } = this.state;
        let disableNext = true;
        const perMailAddressData = this.perMailAdrressPref.current;
        const perPhysicalAddressData = this.perPhysicalAddressPref.current;
        const phoneDetailsData = this.phoneDetailsRef.current;
        const emailAddressData = this.emailAddressRef.current;
        const { sameAddress } = this.state;
        // Check personalinfocard status
        disableNext = !this.checkPersonalInfoParentNextStatus(errorMsg);

        if (perMailAddressData) {
            const mailingAddState = { ...perMailAddressData.state };
            disableNext = disableNext || !mailingAddState.ready;
        }
        if (sameAddress && perPhysicalAddressData) {
            const physicalAddState = { ...perPhysicalAddressData.state };
            disableNext = disableNext || !physicalAddState.ready;
        }
        if (phoneDetailsData) {
            const phoneDetailState = { ...phoneDetailsData.state };
            disableNext = disableNext || !phoneDetailState.ready;
        }
        if (emailAddressData) {
            const emailAddState = { ...emailAddressData.state };
            disableNext = disableNext || !emailAddState.ready;
        }
        const { disableNext: prevStateNextDisabled } = this.state;
        if (prevStateNextDisabled !== disableNext) {
            this.setState(
                () => {
                    return {
                        pageReady: !disableNext,
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        }
    }

    checkPersonalInfoParentNextStatus() {
        const {
            firstName,
            lastName,
            dateOfBirth,
            gender,
            maritalStatus,
            citizenship,
            fullSSN,
            sameAddress,
        } = this.state;

        // debugger
        return (
            !!firstName &&
            !!lastName &&
            !!dateOfBirth &&
            !!gender &&
            !!maritalStatus &&
            !!citizenship &&
            !!fullSSN &&
            !!sameAddress
        );
    }

    renderSuffix = suffixOp => {
        const { suffix = '' } = this.state;
        return (
            <>
                <Row>
                    <WInput
                        label="Suffix"
                        optional="true"
                        inputclassname="suffix-select prefilled"
                        name="suffix"
                        id={assignObj(`primaryOwnersuffix`)}
                        value={suffix || ''}
                        type="text"
                        onChange={this.handleChange('suffix')}
                        onBlur={this.handleBlur('suffix')}
                        readOnly
                        labelsm={4}
                        valuesm={5}
                    />
                </Row>
            </>
        );
    };

    renderAddressAccordion = accountType => {
        const { errorMsg, contactDetailsHeadreClick } = this.state;
        const role = localStorage.getItem('role');
        let { sameAddress } = this.state;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedPerMailAddress, savedPhysicalAddress, savedSameAddress } = savedData;
        const sameAddressVal = savedSameAddress ? 'Y' : 'N';
        let showSameAddrErr = false;

        if (errorMsg.sameAddress === '') {
            showSameAddrErr = false;
        } else if (sameAddress === 'Y' || sameAddress === 'N') {
            showSameAddrErr = false;
        } else {
            showSameAddrErr = true;
        }

        sameAddress = role === 'Guest' || role === 'Prospect' ? sameAddress : sameAddress || sameAddressVal;

        return (
            <Accordion defaultActiveKey="0" id={assignObj(`primaryOwnerAddressAccordion`)}>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            aria-expanded={contactDetailsHeadreClick}
                            ref={this.addressAccordion}
                            style={styles.accordionToggleStyle}
                        >
                            {contactInfoHeading}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" id={assignObj(`primaryOwnerAddressAccordionCardBody`)}>
                        <Card.Body>
                            <Address
                                savedState={savedPerMailAddress}
                                ref={this.perMailAdrressPref}
                                labelAdd="Mailing Address"
                                mailRadio
                                required
                                type="mailing"
                                checkStatus={this.checkPersonalInfoParentChildNextStatus}
                            />
                            <Row
                                className="Slabel"
                                role="group"
                                aria-labelledby={assignObj(`primaryOwnerisPhysicalAddressSameasMailingAddress`)}
                            >
                                <span
                                    className="label"
                                    style={styles.labelTextStyle}
                                    id={assignObj(`primaryOwnerisPhysicalAddressSameasMailingAddress`)}
                                >
                                    {sameAddressRadioLabel}
                                </span>
                                <span className="sameAddressSpan">
                                    <div className="sameAddressDiv">
                                        <RadioList
                                            items={Consts.sameAddress.value}
                                            field="sameAddress"
                                            setValue={this.getValue}
                                            selectedValue={sameAddress}
                                            // errorText={sameAddressRadioError}
                                            // initialError={!!errorMsg.sameAddress}
                                            handleBlur={this.handleRadioBlur('sameAddress')}
                                        />
                                    </div>
                                    {showSameAddrErr && (
                                        <div className="errorMsgInputStyle errorSameAddressDiv">
                                            {errorMsg.sameAddress}
                                        </div>
                                    )}
                                </span>
                            </Row>
                            {sameAddress === 'N' ? (
                                <Address
                                    savedState={savedPhysicalAddress}
                                    ref={this.perPhysicalAddressPref}
                                    labelAdd="Physical Address"
                                    required
                                    labelAdd1="Physical Address Line 1"
                                    labelAdd2="Physical Address Line 2"
                                    type="physical"
                                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                                />
                            ) : (
                                ''
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    renderSSNComponent = savedSSNData => {
        const { isSSNerror = false, citizenship } = this.state;
        const role = localStorage.getItem('role');
        let ssnLabel = 'Social Security Number / Tax ID';
        if (citizenship === 'us' || citizenship === 'U.S') {
            ssnLabel = 'Social Security Number';
        }
        return (
            <Row>
                <SSNComponent
                    ref={this.ssnRef}
                    savedstate={savedSSNData}
                    sendfullssn={this.handleSSN}
                    isssnerror={isSSNerror}
                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                    disabled={role !== 'Guest' && role !== 'Prospect'}
                    ssnLabel={ssnLabel}
                />
            </Row>
        );
    };

    render() {
        const { localStateData, initialState, personalInfo } = this.props;
        const role = localStorage.getItem('role');
        const disabled = 
            isRoleNotGuestAndProspect(role) &&
            personalInfo.profileInformation?.citizenship !== '' &&
            personalInfo.profileInformation?.citizenship !== null;

        const perInfoHeading = ' Personal Information';
        const {
            gender,
            citizenship,
            tooltipOpen,
            dateOfBirth,
            prefix,
            errorMsg,
            errorFromMasterData = false,
            originalW8FileArrayFromMount,
            perDetailsHeadreClick,
            firstName = '',
            lastName = '',
            middleInitial = '',
            readOnly = false,
        } = this.state;
        const masterDataValues = this.getMasterDataValues();
        const { contactTimeOp, suffixOp, citizenshipOp, maritalStatus, prefixOp, genderOp } = masterDataValues;

        const { accountType } = localStateData;
        const savedData = this.getAlreadySavedData(accountType);
        const { savedPersonalInfoDetails, savedSSNData } = savedData;
        if (errorFromMasterData) {
            throw new Error('Error From Master Data in Personal Info Card');
        }

        let primaryEmailAddress = '';
        let secondaryEmailAddress = '';
        let isSecondaryEmailEditable = true;
        let primaryEmailInitial = '';
        let secondaryEmailInitial = '';
        // if (initialState && initialState.getCustomerProfile) {
        //     const { getCustomerProfile } = initialState;
        if (personalInfo && personalInfo.profileInformation) {
            const { profileInformation } = personalInfo;
            if (profileInformation && profileInformation.emailInformation) {
                primaryEmailInitial = getDataFromORCondition(profileInformation.emailInformation.primaryEmail,'');
                secondaryEmailInitial = getDataFromORCondition(profileInformation.emailInformation.secondaryEmail ,'');
                isSecondaryEmailEditable = secondaryEmailInitial === '';
            }
            primaryEmailAddress = primaryEmailInitial;
            secondaryEmailAddress = secondaryEmailInitial;
        }

        let emailAddress = {};
        if (savedPersonalInfoDetails && savedPersonalInfoDetails.emailAddress) {
            emailAddress = savedPersonalInfoDetails.emailAddress;
            primaryEmailAddress = emailAddress.primaryEmail.email;
            secondaryEmailAddress = emailAddress.secondaryEmail.email;
            if (isRoleNotGuestAndProspect(role)) {
                secondaryEmailInitial = getDataFromORCondition(personalInfo.profileInformation.emailInformation.secondaryEmail,'');
                isSecondaryEmailEditable = secondaryEmailInitial === '';
            } else {
                isSecondaryEmailEditable = true;
            }
        }

        let primaryPhoneNumber = '';
        let secondaryPhoneNumber = '';
        let primaryContactTime = '';
        let secondaryContactTime = '';
        let primaryCountryCode = '+1';
        let secondaryCountryCode = '+1';
        let primaryIsMobile = false;
        let secondaryIsMobile = false;

        if (isUserGuestOrProspect(role) && initialState && initialState.getCustomerProfile) {
            const { getCustomerProfile } = initialState;
            primaryPhoneNumber = getCustomerProfile.primaryPhone;
            secondaryPhoneNumber = getCustomerProfile.secondaryPhone;
            primaryCountryCode = getDataFromORCondition(getCustomerProfile.primaryPhoneCountryCode,'+1');
            secondaryCountryCode = getDataFromORCondition(getCustomerProfile.secondaryPhoneCountryCode,'+1');
        }
        if (personalInfo && personalInfo.profileInformation) {
            const { profileInformation } = personalInfo;
            const { phoneInformation } = profileInformation;
            // const { primaryPhone: primaryPhoneInitial, secondaryPhone: secondaryPhoneInitial } = profileInformation.phoneInformation;
            if (profileInformation && profileInformation.phoneInformation) {
                primaryPhoneNumber = checkArrayLengthAndReturnValue(phoneInformation , 0,phoneInformation[0].phoneNumber,'');
                secondaryPhoneNumber = checkArrayLengthAndReturnValue(phoneInformation, 1,phoneInformation[1].phoneNumber,'');
                primaryCountryCode = checkArrayLengthAndReturnValue(phoneInformation, 0,phoneInformation[0].phoneCountryCode,'+1');
                secondaryCountryCode = checkArrayLengthAndReturnValue(phoneInformation, 1,phoneInformation[1].phoneCountryCode,'+1');
            }
            // primaryPhoneNumber = this.formatPhoneNumber(primaryPhoneInitial);
            // secondaryPhoneNumber = this.formatPhoneNumber(secondaryPhoneInitial);
            // primaryPhoneNumber = primaryPhoneInitial;
            // secondaryPhoneNumber = secondaryPhoneInitial;
        }

        let contactDetails = {};
        if (savedPersonalInfoDetails && savedPersonalInfoDetails.contactDetails) {
            contactDetails = savedPersonalInfoDetails.contactDetails;
            primaryPhoneNumber = contactDetails.primaryPhone.phoneNumber;
            secondaryPhoneNumber = contactDetails.secondaryPhone.phoneNumber;
            primaryContactTime = contactDetails.primaryPhone.contactTime;
            secondaryContactTime = contactDetails.secondaryPhone.contactTime;
            primaryIsMobile = contactDetails.primaryPhone.isMobile;
            secondaryIsMobile = contactDetails.secondaryPhone.isMobile;
            primaryCountryCode = contactDetails.primaryPhone.countryCode;
            secondaryCountryCode = contactDetails.secondaryPhone.countryCode;
        }
        const startDateMin = new Date();
        let showGenderErr = false;
        if (!errorMsg.gender) {
            showGenderErr = false;
        } else if (gender === 'Male' || gender === 'Female') {
            showGenderErr = false;
        } else {
            showGenderErr = true;
        }
        return (
            <>
                <Accordion defaultActiveKey="0" id={assignObj(`primaryOwnerPersonalAccordion`)}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                as="h2"
                                variant="link"
                                eventKey="0"
                                className="expand"
                                onClick={this.cardHeaderClick}
                                aria-expanded={perDetailsHeadreClick}
                                ref={this.personalRef}
                                style={styles.accordionToggleStyle}
                            >
                                {perInfoHeading}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0" id={assignObj(`primaryOwnerPersonalCardBody`)}>
                            <Card.Body>
                                <VCMSelect
                                    id={assignObj(`primaryOwnerPrefix`)}
                                    name="prefix"
                                    label="Salutation"
                                    itemlist={prefixOp}
                                    onChange={this.handleChange('prefixLocal')}
                                    onBlur={this.handleBlur('prefixLocal')}
                                    value={prefix}
                                    labelsm={4}
                                    valuesm={5}
                                    errortext={errorMsg.prefix}
                                    noGutters
                                    optional
                                />
                                <Row>
                                    <WInput
                                        label="First Name"
                                        aria-required
                                        required
                                        inputclassname={readOnly ? 'prefilled' : ''}
                                        id={assignObj(`primaryOwnerFirstName`)}
                                        name="firstName"
                                        type="text"
                                        onChange={this.handleChange('firstName')}
                                        onBlur={this.handleBlur('firstName')}
                                        value={firstName || ''}
                                        readOnly={readOnly}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={40}
                                        errortext={errorMsg.firstName}
                                    />
                                </Row>
                                <Row>
                                    <WInput
                                        label="Middle Name"
                                        inputclassname={getValidValueOf(readOnly,'prefilled',"")}
                                        optional="true"
                                        sublabel={styles.sublabel}
                                        name="middleInitial"
                                        id={assignObj(`primaryOwnerMiddleInitial`)}
                                        type="text"
                                        onChange={this.handleChange('middleInitial')}
                                        onBlur={this.handleBlur('middleInitial')}
                                        errortext={errorMsg.middleInitial}
                                        readOnly={readOnly}
                                        value={middleInitial || ''}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={40}
                                        aria-required={false}
                                        required={false}
                                    />
                                </Row>
                                <Row>
                                    <WInput
                                        aria-required
                                        label="Last Name"
                                        inputclassname={getValidValueOf(readOnly,'prefilled',"")}
                                        name="lastName"
                                        id={assignObj(`primaryOwnerLastName`)}
                                        value={lastName || ''}
                                        type="text"
                                        required
                                        onChange={this.handleChange('lastName')}
                                        onBlur={this.handleBlur('lastName')}
                                        readOnly={readOnly}
                                        labelsm={4}
                                        valuesm={5}
                                        maxlength={40}
                                        errortext={errorMsg.lastName}
                                    />
                                </Row>
                                {this.renderSuffix(suffixOp)}
                                <VCMDatePicker
                                    name="dateOfBirth"
                                    id={assignObj(`primaryOwnerDateofBirth`)}
                                    max={isValidDate(startDateMin) ? formatDate(startDateMin) : ''}
                                    label={dateOfBirthLabel}
                                    onChange={this.handleChange('dateOfBirthLocal')}
                                    onBlur={this.handleBlur('dateOfBirthLocal')}
                                    errortext={errorMsg.dateOfBirth}
                                    value={dateOfBirth && dateOfBirth.replace(/-/g, '/')}
                                    labelsm={4}
                                    valuesm={5}
                                    noGutters
                                    dobValidation
                                    DOBErrorMsg={Consts.DOBErrorMsg}
                                    required
                                    disabled={role !== 'Guest' && role !== 'Prospect'}
                                />
                                <Row className="Slabel" role="group" aria-labelledby={assignObj(`primaryOwnergender`)}>
                                    <span
                                        className="label"
                                        id={assignObj(`primaryOwnergender`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {genderLabel}
                                    </span>
                                    <div>
                                        <div className="genderRenderStyle">
                                            {genderOp &&
                                                genderOp.length > 0 &&
                                                genderOp.map(field => (
                                                    <WRadio
                                                        radioFor={field.key}
                                                        key={field.key}
                                                        id={field.key}
                                                        value={field.value}
                                                        onBlur={assignObj(this.handleRadioBlur('genderLocal'))}
                                                        onClick={assignObj(this.handleRadioBlur('genderLocal'))}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={!!(gender && gender.toString() === field.key)}
                                                    />
                                                ))}
                                        </div>
                                        <div className="errorMsgInputStyle errorSameAddressDiv">
                                            {showGenderErr ? 'Please select a gender' : ''}
                                        </div>
                                    </div>
                                </Row>
                                <VCMSelect
                                    id={assignObj(`primaryOwnerMarital`)}
                                    name="marital"
                                    label="Marital Status"
                                    itemlist={maritalStatus}
                                    onChange={this.handleChange('maritalLocal')}
                                    onBlur={this.handleBlur('maritalLocal')}
                                    value={this.getMaritalStatus() || ''}
                                    labelsm={4}
                                    valuesm={5}
                                    errortext={errorMsg.maritalStatus}
                                    noGutters
                                    required
                                />
                                <Row
                                    className="Slabel"
                                    role="group"
                                    aria-labelledby={assignObj(`primaryOwnercitizenship`)}
                                >
                                    <span
                                        className="label"
                                        id={assignObj(`primaryOwnercitizenship`)}
                                        style={styles.labelTextStyle}
                                    >
                                        {citizenshipLabel}
                                    </span>
                                    <div className="citizenshipRenderStyle">
                                        <CitizenshipRadioList
                                            items={citizenshipOp}
                                            field="citizenship"
                                            setValue={this.getValue}
                                            selectedValue={citizenship}
                                            errorText={citizenshipError}
                                            initialError={!!errorMsg.citizenship}
                                            handleBlur={this.handleRadioBlur('citizenship')}
                                            disabled={disabled}
                                        />
                                    </div>
                                </Row>
                                {citizenship === 'nonus' && (
                                    <>
                                        <Row className="nonUsForm Slabel">
                                            <span
                                                className="label"
                                                style={styles.labelTextStyle}
                                                id="W8formUploadLabel"
                                            >
                                                {w8FormLabel}
                                            </span>
                                            <button
                                                className="w8FormExplain"
                                                type="button"
                                                id="upload"
                                                aria-label={w8FormTooltip}
                                            >
                                                {w8FormExplain}
                                            </button>
                                            <Tooltip
                                                placement="right"
                                                isOpen={tooltipOpen}
                                                target="upload"
                                                toggle={this.toggleTooltip}
                                            >
                                                <span>{w8FormTooltip}</span>
                                            </Tooltip>
                                            <div>
                                                <WFileUploader
                                                    className="w8Form"
                                                    optional="true"
                                                    sublabel={styles.sublabel}
                                                    label="Upload W-8 form"
                                                    name="w8FormUpload"
                                                    id={assignObj(`primaryOwnerW8FormUpload`)}
                                                    senduploads={this.uploadW8File}
                                                    accept="application/pdf"
                                                    existingFiles={originalW8FileArrayFromMount || undefined}
                                                    clearInitialFiles={this.clearInitialFiles}
                                                    describedby="W8formUploadLabel"
                                                />
                                            </div>
                                        </Row>
                                        <p>{ortext}</p>
                                        <Row className="Slabel">
                                            <span className="label" style={styles.labelTextStyle}>
                                                {mailToCSR}
                                            </span>
                                            <div className="form8Address" id="form8Address">
                                                <b>{victoryCapital}</b>
                                                <span>{postOffice}</span>
                                                <span>{postAddress}</span>
                                            </div>
                                        </Row>
                                    </>
                                )}
                                {this.renderSSNComponent(savedSSNData)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                {this.renderAddressAccordion(accountType)}
                <PhoneDetailsCardComponent
                    ref={this.phoneDetailsRef}
                    // isJoint={isJoint}
                    primaryPhoneNumber={primaryPhoneNumber}
                    secondaryPhoneNumber={secondaryPhoneNumber}
                    primaryContactTime={primaryContactTime}
                    secondaryContactTime={secondaryContactTime}
                    primaryIsMobile={primaryIsMobile}
                    secondaryIsMobile={secondaryIsMobile}
                    primaryCountryCode={primaryCountryCode}
                    secondaryCountryCode={secondaryCountryCode}
                    contactTimeOp={contactTimeOp}
                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                />
                <EmailDetailsCardComponent
                    ref={this.emailAddressRef}
                    // isJoint={isJoint}
                    primaryEmailAddress={primaryEmailAddress}
                    secondaryEmailAddress={secondaryEmailAddress}
                    checkStatus={this.checkPersonalInfoParentChildNextStatus}
                    isSecondaryEmailEditable={isSecondaryEmailEditable}
                    isReadOnly={role !== 'Guest' && role !== 'Prospect'}
                />
            </>
        );
    }
}

PersonalInfoCardComponent.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    savedState: PropTypes.instanceOf(Object),
    // isJoint: PropTypes.bool,
    uploadW8Form: PropTypes.func,
    checkStatus: PropTypes.func,
    sendDOB: PropTypes.func,
    personalInfo: PropTypes.instanceOf(Object),
};

PersonalInfoCardComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    localStateData: {},
    savedState: undefined,
    // isJoint: false,
    initialState: {},
    uploadW8Form: () => {},
    checkStatus: () => {},
    sendDOB: () => {},
    personalInfo: {},
};

export default PersonalInfoCardComponent;
