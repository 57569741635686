const consts = {
    paperFlowHeading: 'Paper',
    agreementHeading: 'Agreement',
    agreementPara1: 'Download and read the SEP/ SIMPLE IRA Custodial Agreement ',
    agreementCard1Title: 'SEP/ SIMPLE IRA Custodial Agreement',
    downloadPdfText: 'Download',
    cardtitlestyle: {
        font: '800 30px/25px yorkten-slab-normal',
        marginBottom: 20,
    },
};

export default consts;
