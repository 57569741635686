import React from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../inputs/CommonStyles';

const CustomSwitch = styled.div`
  height: 26px;
  width: 44px;
  .custom-switch {
    padding-left: 1.5rem;
    height: 26px;
    width: 44px;
    .custom-control-label {
      position: static;
      color: transparent;
      overflow: hidden;
      text-indent: -9999px;
    }
  }
  .custom-switch .custom-control-label::before {
    left: -0.25rem;
    top: 0;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    cursor: pointer;
  }
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #9091b2;
  }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem - 1px);
    left: calc(-0.25rem + 3px);
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 1rem;
    border: 1px solid #757575;
    cursor: pointer;
  }
  .custom-control-label::before {
    background-color: #757575;
    border: 1px solid #757575;
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    border-color: #0060c5;
    background-color: #0060c5;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(24.2px);
  }
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    opacity: 0.5;
  }
  .custom-control-input[disabled] ~ .custom-control-label::before {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .custom-control-input[disabled] ~ .custom-control-label::after {
    cursor: not-allowed;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    outline: 2px dotted #000000;
  }
`;

const onParentClick = (e) => {
  e.stopPropagation();
};

const WSwitch = (props) => {
  const {
    id: switchId,
    onSwitchClick,
    errortext,
    errorstyle,
    label: switchLabel,
    disabled,
    checked,
    arialabelledby,
    ariaRequired,
    name,
    onBlur,
  } = props;
  const assignObj = (obj) => obj;
  const iserror = errortext !== undefined && errortext !== '' && errortext !== null;
  return (
    <CustomSwitch onClick={onParentClick}>
      <Form.Check
        checked={checked}
        disabled={disabled}
        onChange={onSwitchClick}
        type="switch"
        id={switchLabel !== '' ? switchLabel : switchId}
        label={switchId}
        name={name}
        aria-labelledby={arialabelledby}
        aria-required={ariaRequired ? 'true' : 'false'}
        onBlur={onBlur}
      />
      {iserror ? (
        <Form.Text
          style={assignObj({ ...styles.errorStyle, ...errorstyle })}
          role="region"
          id={assignObj(`${switchId}inputErrorText`)}
          aria-labelledby={assignObj(`${switchId}inputErrorText`)}
          aria-live="polite"
        >
          {errortext}
        </Form.Text>
      ) : null}
    </CustomSwitch>
  );
};

WSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  onSwitchClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  arialabelledby: PropTypes.string,
  name: PropTypes.string,
  errortext: PropTypes.string,
  errorstyle: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  ariaRequired: PropTypes.bool,
};

WSwitch.defaultProps = {
  label: '',
  onSwitchClick: () => {},
  disabled: false,
  checked: false,
  arialabelledby: '',
  name: '',
  errortext: '',
  errorstyle: {},
  onBlur: () => {},
  ariaRequired: false,
};

export default WSwitch;
