import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import currencyFormatter from 'currency-formatter';
import { analyzeClickEvent } from '../../../../shared/Actions/AnalyticsActions';
import './AccordionContent.css';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
const AcHolderAcrdCont = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    flex-direction: column;
`;

const assignObj = obj => obj;

const CreateLink = props => {
    const {
        isNewBrokerageTab = false,
        isEducationTab = false,
        accountStatus = '',
        linkId,
        name,
        id,
        isAscensus = false,
        isBrokerage = false,
        history,
        accountType,
        handleMSRAscensusClick,
        handleAscensusClick,
        getDashBoardAccordianLinks,
        getGeneratedSAMLAssertion
    } = props;
    const dispatch = useDispatch();
    function handleAnalytics() {
        const screenName = localStorage.getItem('screenName');
        let linkName = accountType;
        if (accountType === 'R') {
            linkName = '529 Regular';
        } else if (accountType === 'U') {
            linkName = '529 UGMA/UTMA';
        }
        dispatch(
            analyzeClickEvent({
                link: `Dashboard_${screenName}_My Portfolio_${linkName} Account`,
            }),
        );
    }

    function onLinkCLick(e ,fun) {
        e.preventDefault();
        if(isBrokerage){
            // make api call here 
            fun({
                "landingPage": "DASHBOARD",
                "acctId": id
            })
        }else{

            handleAnalytics();
            fun({
                returnURL: history.location.pathname,
                accountNumber: id,
                accountType,
            });
        }

        // handleMSRAscensusClick();
    }

    const netXUrl = `${envConfig.NETX360PLUS_SSO}${getSessionValues().vcmid}`
    const isBrokerageORisAscensus = isAscensus ? true : isBrokerage ? true : false
    return isBrokerageORisAscensus !== true ? (
        <Link
            tabIndex="0"
            data-test="transactionLink"
            id={linkId}
            style={assignObj({ color: '#004A98', fontSize: '16px', lineHeight: '30px', fontWeight: 600 })}
            to={assignObj({
                pathname: '/accountholder/transactions',
                name,
                masterId: id,
                accountType:accountType,
                state: {
                    accountDetails:props.accountDetails,
                    name:props.name,
                    masterId: props.accountID,
                    accountType: props.accountType,
                    accountOwnerDesc: props.accountOwnerDesc,
                    ownerType: props.ownerType,
                    taxId: props.taxId,
                    holdingGroup:props.holdingGroup
               },
            })}
            onClick={handleAnalytics}
            className="portfolioLinks"
        >
            {name}
        </Link>
    ) : (
        <a
            href={isBrokerage ? `${netXUrl}` : "about:blank"}
            tabIndex="0"
            data-test={isBrokerage? "brokerageLink":"ascensusLink"}
            // onKeyDown={isBrokerage? null : assignObj(e => onLinkCLick(e, handleAscensusClick))} // For eslint fix
            onClick={isBrokerage? null : assignObj(e => onLinkCLick(e, handleMSRAscensusClick))}
            role="link"
            id={linkId}
                style={(isNewBrokerageTab || isEducationTab) && accountStatus == 'C' ?
                    assignObj({
                        color: 'black',
                        fontSize: '16px',
                        lineHeight: '30px',
                        fontWeight: 600,
                        cursor: 'pointer',
                        pointerEvents: "none",
                    }) : assignObj({
                color: '#004A98',
                fontSize: '16px',
                lineHeight: '30px',
                fontWeight: 600,
                cursor: 'pointer',
            })}
            target="_blank"
            rel="noopener noreferrer"
            className="portfolioLinks"
            onContextMenu={assignObj(e => e.preventDefault())}
        >
            {name}
        </a>
    );
};

const AccordionContent = props => {
    const { investement, isNewBrokerageTab = false, placeATradeonSingleSignOnClick, getDashBoardAccordianLinks, dashBoardLinkData, history, onClickPopupRedirecton, getGeneratedSAMLAssertion, handleMSRAscensusClick, accountDetailsData, isNewDashboardFeatureEnabled = false,
    isEducationTab = false, isFundsTab = false, isMarketTab = false,holdingGroup,PlaceTradeHider,accountActivityHider } = props;
    const holdingGroupDetails =
        investement &&
        investement.reduce((acc, curr) => {
            acc.push({ id: curr.accountID, name: curr.accountName, amount: curr.balance, ...curr });
            return acc;
        }, []);

    const getAllowTransactionDetails = (accountID,transType,infoData) =>
    {
        const {getPostionData} = props;
        getPostionData(accountID,transType,infoData);
    }

    return (
        <AcHolderAcrdCont>
            {isEducationTab && holdingGroup == "Closed Accounts" &&
                <div role="row" style={{ marginBottom: '3%' }}>
                    <span style={{ cursor: 'pointer', color: 'rgb(0, 74, 152)', fontSize: '12px', fontWeight: 'bold' }}
                        onClick={props.handleEducationApplicationClosedPopUp}
                    >Why am I seeing my closed accounts?</span>
                </div>
            }
            {
                isNewBrokerageTab && isMarketTab && holdingGroup == "Closed Accounts" ?
                    <div role="row">
                        <span style={{ cursor: 'pointer', color: 'rgb(0, 74, 152)', fontSize: '12px', fontWeight: 'bold' }}
                            className='brokerage-account-closed' onClick={props.handleApplicationClosedPopUp}>Why am I seeing my closed accounts?</span><br /><br />
                    </div> : null
            }
            {holdingGroupDetails &&
                holdingGroupDetails.map(item => {
                    const linkId = `${item.id}LinktoTransaction`;
                    // const stringId=item.id.toString()
                    return (
                        isNewDashboardFeatureEnabled ?
                        <>
                            <div className="moblistbox"
                                key={item.id}
                                // className="w-100 portfolioAccordionContent justify-content-between d-flex pr-0 pr-md-2 py-2"
                                role="row"
                            >      
                                <Row className='mb-2'>
                                        <Col xl={(holdingGroup !== "Closed Accounts") ?8:6} md={(holdingGroup !== "Closed Accounts") ?8:6} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className='pr-4'>
                                        
                                            <span role="cell" style={{ width: '230px' }}>
                                         
                                            <CreateLink
                                                handleAscensusClick={handleMSRAscensusClick}
                                                onClickPopupRedirecton={onClickPopupRedirecton}
                                                accountDetails={accountDetailsData}
                                                linkId={linkId}
                                                name={item.name}
                                                isBrokerage={item.isBrokerage}
                                                isAscensus={item.isAscensus}
                                                getDashBoardAccordianLinks={getDashBoardAccordianLinks}
                                                getGeneratedSAMLAssertion={getGeneratedSAMLAssertion}
                                                dashBoardLinkData={dashBoardLinkData}
                                                    isNewBrokerageTab={isNewBrokerageTab}
                                                    isEducationTab={isEducationTab}
                                                history={history}
                                                holdingGroup={holdingGroup}
                                                {...item}
                                            />
                                                {isMarketTab && item?.pendingTransferFlag == true ? <span role="cell" style={{ float: "right", color: "#21847E", fontSize: "12px", textDecoration: "underline" }}>
                                                    Transfer Pending
                                                </span> : null}
                                        </span>
                                        
                                       

                                      
                                            {holdingGroup !== "Closed Accounts" &&
                                        <span role="cell" style={{float:"right", color: "#000", fontSize: "16px"}}>
                                            {currencyFormatter.format(item.amount, { locale: 'en-US' })}
                                                </span>}
                                        
                                       
                                    
                                    </Col>
                                   
                                    { isFundsTab && 
                                        <Col xl={3} md={3} sm={12}  className="ml-2">
                                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                            <p className="fundTextPara" style={{fontSize: '16px'}} onClick={()=>getAllowTransactionDetails(item.accountID,"Buy",item)}>Buy</p>
                                            <p className="fundTextPara" style={{fontSize: '16px'}} onClick={()=>getAllowTransactionDetails(item.accountID,"Sell",item)}>Sell</p>
                                            <p className="fundTextPara" style={{fontSize: '16px'}} onClick={()=>getAllowTransactionDetails(item.accountID,"Exchange",item)}>Exchange</p>
                                            </div>
                                        </Col>
                                    }

                                        {isNewBrokerageTab && item?.accountStatus == 'C' ?

                                            (<Col xl={6} md={6} sm={12}>
                                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                                    <a href={`${envConfig.NETX360PLUS_SSO}${getSessionValues().vcmid}`} target="_blank" rel="noopener noreferrer" style={{ fontSize: '16px', marginRight: '20px' }} >Access Your Documents</a>
                                                </div>
                                            </Col>)

                                            : isMarketTab && 
                                        <Col xl={3} md={3} sm={12}  className="ml-2 pr-0">
                                            <div style={{display:"flex",justifyContent:"left"}}>
                                           {PlaceTradeHider=="PlaceTradeHider"? "" :<a href={`${envConfig.NETX360PLUS_SSO}${getSessionValues().vcmid}`} target="_blank" rel="noopener noreferrer" style={{fontSize: '16px', marginRight: '20px'}} >Place a Trade</a>}

                                            <Link 
                                            id="manageMarketTransaction"
                                            to={assignObj({
                                                pathname: '/manageMarketTransaction',
                                                state: {
                                                     accountDetails:accountDetailsData,
                                                     name:item?.name,
                                                     masterId: item?.accountID,
                                                     accountType: item?.accountType,
                                                     accountOwnerDesc: item?.accountOwnerDesc,
                                                     ownerType: item?.ownerType,
                                                     taxId: item?.taxId,
                                                     holdingGroup:holdingGroup,
                                                     PlaceTradeHider:PlaceTradeHider,
                                                     accountActivityHider:accountActivityHider
                                                },
                                            })}

                                             style={{fontSize: '16px'}} >Manage</Link>
                                            </div>
                                        </Col>
                                    }

                                    { isEducationTab && 
                                            <Col xl={(holdingGroup !== "Closed Accounts") ? 3 : 6} md={(holdingGroup !== "Closed Accounts") ? 3 : 6} sm={12} className={holdingGroup !== "Closed Accounts" ?"ml-2":""}>
                                                <div style={{ display: "flex", justifyContent: holdingGroup !== "Closed Accounts" ? "left" :"space-between"}}>
                                                <CreateLink
                                                    handleAscensusClick={handleMSRAscensusClick}
                                                    onClickPopupRedirecton={onClickPopupRedirecton}
                                                    accountDetails={accountDetailsData}
                                                    linkId={linkId}
                                                    isBrokerage={item.isBrokerage}
                                                    isAscensus={item.isAscensus}
                                                    getDashBoardAccordianLinks={getDashBoardAccordianLinks}
                                                    getGeneratedSAMLAssertion={getGeneratedSAMLAssertion}
                                                    dashBoardLinkData={dashBoardLinkData}
                                                    history={history}
                                                    {...item}
                                                    removeBold={true}
                                                    name={holdingGroup !== "Closed Accounts" ? "Manage" : "Access your Documents"}

                                                />
                                            </div>
                                        </Col>
                                    }
                                </Row>

                            </div>
                            
                        </>
                        :
                        <>
                        <div
                            key={item.id}
                            //  role="row"
                        >
                            <Row
                                style={assignObj({
                                    paddingTop: 24,
                                    // paddingLeft: 26,
                                    textAlign: 'right',
                                    margin: 0,
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                })}
                                className="w-100 portfolioAccordionContent"
                            >
                                <Col
                                    // role="cell"
                                    className="text-left accordPortfolioAlign"
                                    xs={7}
                                >
                                    <CreateLink
                                        getGeneratedSAMLAssertion={getGeneratedSAMLAssertion}
                                        accountDetails={accountDetailsData}
                                        linkId={linkId}
                                        name={item.name}
                                        isAscensus={item.isAscensus}
                                        getDashBoardAccordianLinks={getDashBoardAccordianLinks}
                                        dashBoardLinkData={dashBoardLinkData}
                                        history={history}
                                        {...item}
                                        handleMSRAscensusClick={handleMSRAscensusClick}
                                    />
                                </Col>
                                <Col
                                    // role="cell"
                                    className="w-100 p-0 pl-1 pr-1 portfolioAmount"
                                    style={assignObj({
                                        color: '#49494A',
                                        fontSize: '16px',
                                        lineHeight: '30px',
                                        justifyContent: 'flex-end',
                                        display: 'flex',
                                        width: 'auto',
                                        right: '0',
                                        // position: 'absolute',
                                        minWidth: 107,
                                    })}
                                   
                                >
                                            {holdingGroup !== "Closed Accounts" && currencyFormatter.format(item.amount, { locale: 'en-US' })}
                                </Col>
                            </Row>
                        </div>
                         </>
                    );
                })}
        </AcHolderAcrdCont>
    );
};

AccordionContent.propTypes = {
    investement: PropTypes.instanceOf(Object),
    getDashBoardAccordianLinks: PropTypes.func,
    dashBoardLinkData: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object),
    handleMSRAscensusClick: PropTypes.func,
    getPostionData : PropTypes.func,
};

AccordionContent.defaultProps = {
    investement: {},
    getDashBoardAccordianLinks: () => {},
    dashBoardLinkData: {},
    history: {},
    handleMSRAscensusClick: () => {},
    getPostionData : () => {},
};

CreateLink.propTypes = {
    linkId: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    accountType: PropTypes.string,
    isAscensus: PropTypes.bool,
    getDashBoardAccordianLinks: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    handleMSRAscensusClick: PropTypes.func,
    getPostionData: PropTypes.func,
};

CreateLink.defaultProps = {
    linkId: '',
    name: '',
    id: '',
    accountType: '',
    isAscensus: false,
    getDashBoardAccordianLinks: () => {},
    history: {},
    handleMSRAscensusClick: () => {},
    getPostionData : () => {},
};

export default AccordionContent;
