import React from "react";
import styled from 'styled-components';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import rightArrowGreen from "assets/rightArrowGreen.svg";
import rightArrowBlue from 'assets/rightArrowBlue.svg';
import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import TopLeftCornerColor from 'modules/CustomerManagementModule/components/TopLeftCornerColor';
import Separator from 'modules/CustomerManagementModule/components/Separator';

export const StyledCard = styled(TopLeftCornerColor)`
    padding: 30px;
    height: 100%;
    &&:hover, &&:focus, &&:focus-within {
        outline: 1px solid #3C5A77;
        outline-offset: 0;
    }
    display: ${props => props.text ? 'block' :'flex' };
    justify-content: space-between;
    align-items: center;
`;

export const StyledCardTitle = styled.h2`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #486D90;
    opacity: 1;
    margin: 0;
    width: ${props => props.text ? 'auto' :'calc(100% - 40px)' };
`;

const StyledLink = styled(Link)`
    color: inherit;
    &&:focus {
        outline: none;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
`;

const TEXT = 'data-text';

export const ArrowIcon = styled(StyledLink)`
    position: ${props => props[`${TEXT}`] ? 'absolute' :'static' };
    bottom: ${props => props[`${TEXT}`] ? '30px' :'auto' };
    right: ${props => props[`${TEXT}`] ? '30px' :'auto' };
    height: 30px;
    width: 30px;
    background: url(${rightArrowGreen}) no-repeat;
    ${StyledCard}:hover &, ${StyledCard}:focus &, ${StyledCard}:focus-within & {
        background: url(${rightArrowBlue}) no-repeat;
    }
    background-position: center;
`;

const CardBody = styled.p`
    width: calc(100% - 40px);
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const NavigationCard = (props) => {
    const { cardLink, cardHeading, cardText } = props;
    const title = `Go to ${cardHeading}`;
    const navCardWithDesc = !!cardText;
    const dispatch = useDispatch();

    const analyticslink = (cardLinkVal) => {     
        dispatch(analyzeClickEvent({
            link: `home_ProfilesandPreferences_${cardLinkVal}`
        }))
    }

    return (
        <StyledCard text={navCardWithDesc} cornerSize={35} cornerColor='grey'>
            <StyledCardTitle text={navCardWithDesc}>
                <StyledLink to={cardLink} title={title} onClick={()=>analyticslink(cardLink)}>{cardHeading}</StyledLink>
            </StyledCardTitle>
            <ArrowIcon data-text={navCardWithDesc} tabIndex={-1} to={cardLink} title={title} />
            { navCardWithDesc && (
                <>
                    <Separator mT={0.9375} mB={0.9375} />
                    <CardBody>{cardText}</CardBody>
                </>
            )}
        </StyledCard>
    );
};

NavigationCard.propTypes = {
    cardLink: PropTypes.string.isRequired,
    cardHeading: PropTypes.string,
    cardText: PropTypes.string,
};

NavigationCard.defaultProps = {
    cardHeading: "",
    cardText: "",
};

export default NavigationCard;
