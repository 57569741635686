import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WIcon from '../WIcon/WIcon'
import accountInfoBigIcon from './../../assets/icon-account-info-big.svg';
import ShowHideMaskText from './../ShowHideMaskText/ShowHideMaskText';
import { AccountAddress } from 'common';
import MaskAccountNumber from 'common/MaskAccountNumber/MaskAccountNumber';

const VerticalSeperator = styled.div`
    border-left: 1px solid #d2d2d2;
    margin: 0 1.25rem;
`;

const AccountInfoCardSection = styled.div`
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #D2D2D2;
    opacity: 1;
    padding: 20px 0;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-evenly;
`;

const AccountCardColumn = styled.div`
    flex-direction: column;
`;
const AccountCardRow = styled.div`
    display: flex;
    margin-top:25px
`;

const SelectedAccountData = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: benton-sans;
    font-weight: bold;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const SelectedAccountHeader = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: benton-sans;
    letter-spacing: 0px;
    color: #56565A;
    opacity: 1;
`;

const AddressWrapper = styled.div`
    font-weight: bold;
`;

const iconStyle = {
    marginRight: '10px',
    width: '40px',
    height: '40px'
};

const AccountSelectedInfoCard = props => {
    const { accDetails, hasPriorBalance, shouldShowBalance, onClick } = props;

    const priorYearBalance = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(accDetails.priorYearEndBalance || '0.0'));
    const balance = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(accDetails.calculatedMasterRegBalance || '0.0'));

    let registration = <SelectedAccountData>{accDetails.primaryOwner}</SelectedAccountData>;
    if (accDetails.address && Object.keys(accDetails.address).length) {
        registration = <AddressWrapper><AccountAddress address={accDetails.address} /></AddressWrapper>;
    }
    let accountName = accDetails.accountName;
    if(onClick) {
        accountName = <a href="#" onClick={onClick}>{accDetails.accountName}</a>
    }
    return (
        <AccountInfoCardSection>
            <AccountCardRow>
                <WIcon src={accountInfoBigIcon} alt="" fontawesomestyle={iconStyle} />
                <AccountCardColumn>
                    <SelectedAccountData style={{marginTop:"11px"}}>{accountName}</SelectedAccountData>
                </AccountCardColumn>
            </AccountCardRow>
            <VerticalSeperator />
            <AccountCardColumn>
                <SelectedAccountHeader>Account Type</SelectedAccountHeader>
                <SelectedAccountData>{accDetails.accountType}</SelectedAccountData>
            </AccountCardColumn>
            <VerticalSeperator />
            <AccountCardColumn>
                <SelectedAccountHeader>Registration</SelectedAccountHeader>
                {registration}
            </AccountCardColumn>
            {hasPriorBalance || shouldShowBalance ? <VerticalSeperator /> : null}
            {hasPriorBalance ? (
                <AccountCardColumn>
                    <SelectedAccountHeader>Prior Year End</SelectedAccountHeader>
                    <SelectedAccountData>{priorYearBalance}</SelectedAccountData>
                </AccountCardColumn>
            ) : shouldShowBalance ? 
                (
                    <AccountCardColumn>
                        <SelectedAccountHeader>Current Value</SelectedAccountHeader>
                        <SelectedAccountData>{balance}</SelectedAccountData>
                    </AccountCardColumn>
                ) : null
            }
        </AccountInfoCardSection>
    );
};

AccountSelectedInfoCard.propTypes = {
    accDetails: PropTypes.instanceOf(Object),
    hasPriorBalance: PropTypes.bool,
    shouldShowBalance: PropTypes.bool
};

AccountSelectedInfoCard.defaultProps = {
    accDetails: {},
    hasPriorBalance: false,
    shouldShowBalance: true
};

export default AccountSelectedInfoCard;