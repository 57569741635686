import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";


const initialState = {
    env: 'MOCK',
    email: '',
    securedPIN: 1167,
    phone: '',
    firstName : '',
    middleInitial:'',
    lastName : '',
    suffix:'',
    onlineId: "",
    currentPassword:'',
    currentPIN:'',
    vcmID:"",
    signedInSuccess : false,
    firsttimeloginflag : true,
    role : "Member",
    Online_Id : "",
    companyNumber: "",
    customerId: "",
    loginStatus : 'customer',
    isBusinessEntity: false,
};

function initialReducer (state = initialState, action){
    switch(action.type){
        case ActionTypes.SET_ENV: {
            return {
                ...state,
                env : action.data
            };
        }
        case ActionTypes.VERIFIED_EMAIL: {
            return {
                ...state,
                ...action.data
            };
        }
        case ActionTypes.VERIFIED_PHONE:{
            return {
                ...state,
                ...action
            };
        }
        case ActionTypes.VERIFIED_PIN: {
            return {
                ...state,
                ...action,
                securedPIN : 1145
            };
        }
        case ActionTypes.SIGNED_SUCCESS: {
            return {
                ...state,
                ...action,
            };
        }
        default:
            return state;
    }
} 

export default initialReducer;