import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import {
    SAVE_PAGE_STATE_FAILURE,
    SAVE_PAGE_STATE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_FAILURE,
    SET_NEXT_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_FAILURE,
    NEXT_PAGE_ERROR,
    SET_SELECTED_CONSENT_EXCHANGE_SUCCESS,
    SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS,
    SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS,
    RESET_ECONSENT_EXCHANGE_SUCCESS,
    SET_ECONSENT_NAME_EXCHANGE_SUCCESS,
    SET_ECONSENT_TIME_EXCHANGE_SUCCESS,
    CLEAR_EXCHANGE_DATA,
    SET_CANCEL_EXCHANGE,
    SET_NOT_TO_CANCEL_EXCHANGE,
    SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE,
} from '../ReduxConstants/TransactionActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    isAmend: true,
    amendObj: {},
    amendIndex: null,
    tradingMessage: '',
    isTradingAllowed: true,
    currentPageNumber_exchangeOut: 1,
    currentPageNumber_exchangeIn: 1,
    selectedConsent: false,
    selectedProspectus: false,
    selectedDisclosure: false,
    eConsentName: '',
    eConsentTime: '',
    showCancelExchangeModal: false,
    cancelSelectedAcc: {},
    showAllFunds_exchangeOut: false,
    showAllFunds_exchangeIn: false,
};

function exchangeReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SAVE_EXCHANGE_DATA:
        case ActionTypes.GET_FUNDLIST:
        case ActionTypes.SUBMIT_EXCHANGE_DATA: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.GET_FUNDLIST_LOADER:
        case ActionTypes.SUBMIT_EXCHANGE_DATA_LOADING: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }
        case ActionTypes.GET_FUNDLIST_SUCCESS:
        case ActionTypes.SUBMIT_EXCHANGE_DATA_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_FUNDLIST_ERROR:
        case ActionTypes.SUBMIT_EXCHANGE_DATA_ERROR:
        case ActionTypes.GET_FUNDLIST_FAILURE:
        case ActionTypes.SUBMIT_EXCHANGE_DATA_FAILURE: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        // Sets the page state into redux state
        case SAVE_PAGE_STATE_SUCCESS: {
            return {
                ...state,
                isError: false,
                [`page${action.payload.pageIndex}`]: action.payload.data,
                goToNextPage: false,
            };
        }
        case SAVE_PAGE_STATE_FAILURE: {
            return {
                ...state,
                isError: true,
                error: action.payload,
                goToNextPage: false,
            };
        }
        case SET_NEXT_PAGE_NUMBER_SUCCESS: {
            return {
                ...state,
                [`currentPageNumber_${action.payload.page}`]: state[`currentPageNumber_${action.payload.page}`] + 1,
                [`showAllFunds_${action.payload.page}`]: true,
            };
        }
        case SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE:
        case SET_NEXT_PAGE_NUMBER_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
            };
        }

        case RESET_PAGE_NUMBER_SUCCESS: {
            return {
                ...state,
                currentPageNumber_exchangeOut: 1,
                currentPageNumber_exchangeIn: 1,
                showAllFunds_exchangeOut: false,
                showAllFunds_exchangeIn: false,
            };
        }

        case RESET_PAGE_NUMBER_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
                currentPageNumber_exchangeOut: 1,
                currentPageNumber_exchangeIn: 1,
            };
        }
        case SET_SELECTED_CONSENT_EXCHANGE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedConsent: action.payload,
            };
        }
        case SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedProspectus: action.payload,
            };
        }

        case SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedDisclosure: action.payload,
            };
        }

        case RESET_ECONSENT_EXCHANGE_SUCCESS: {
            return {
                ...state,
                selectedConsent: false,
                selectedProspectus: false,
                selectedDisclosure: false,
                eConsentName: '',
                eConsentTime: '',
            };
        }
        case SET_ECONSENT_NAME_EXCHANGE_SUCCESS: {
            return {
                ...state,
                eConsentName: action.payload,
            };
        }

        case SET_ECONSENT_TIME_EXCHANGE_SUCCESS: {
            return {
                ...state,
                eConsentTime: action.payload,
            };
        }

        case CLEAR_EXCHANGE_DATA: {
            return {
                ...initialState,
            };
        }

        case SET_CANCEL_EXCHANGE: {
            return {
                ...state,
                showCancelExchangeModal: true,
                cancelSelectedAcc: action.payload,
            };
        }

        case SET_NOT_TO_CANCEL_EXCHANGE: {
            return {
                ...state,
                showCancelExchangeModal: false,
                cancelSelectedAcc: {},
            };
        }

        case SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS: {
            return {
                ...state,
                currentPageNumber_exchangeOut: action.payload,
            };
        }

        default:
            return state;
    }
}

export default exchangeReducer;
