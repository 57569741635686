import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import WCard from '../../../../common/WCard/WCard';
import { WSpinner } from '../../../../common';
import '../../Purchase/InvestmentSelection/fundInvestComponent.css';
import { getBeneficiaries } from '../../../../shared/Actions/ManageBeneficiaryActions';
import * as TransactionConstants from '../../TransactionConstants';
import BeneficiaryCard from './BeneficiaryCard';

const PersonalCheckDiv = styled.div`
    padding: 41px 35px 41px 40px;
    margin-bottom: 54px;
    border: 2px solid rgba(139, 193, 5, 1);
    position: relative;
    font: 600 16px/22px 'benton-sans', sans-serif;
    color: #49494a;
    opacity: 1;
`;

const LeftCardCornerStyle = styled.div`
    width: 0;
    height: 0;
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8bc105;
    position: absolute;
    top: -2px;
    left: -2px;
`;

const SectionHeaderRow = styled(Row)`
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 30px;
`;

const BeneCardRow = styled(Row)`
    padding-top: 20px;
`;

const SectionHeadingH3 = styled.h3`
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    margin-bottom: 15px;
    color: rgba(72, 109, 144, 1);
`;

const wireTransferCardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const wireTransferCardBodyStyle = {
    paddingBottom: 0,
};

const wireTransferTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const displayNoneStyle = { display: 'none' };

const { BENEFICIARIES_TEXT, BENEFICIARY_CARD_TEXT } = TransactionConstants;

const BeneficiaryCardComponent = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { SUBMIT_PURCHASE_DATA } = useSelector(state => state.purchaseData);
    const { SUBMIT_EXCHANGE_DATA } = useSelector(state => state.exchangeData);
    const isLoadingBeneficiary = useSelector(state => state.manageBeneficiaryData.isLoading);
    const fundBeneInfo = useSelector(state => state.manageBeneficiaryData.manage_beneficiary);
    const { masterRegId, fundId } = props;
    const [fundAccountNumber, setFundAccountNumber] = useState('');
    const [isLoading, setIsLoading] = useState(isLoadingBeneficiary);
    const [primaryBeneficiary, setPrimaryBeneficiary] = useState([]);
    const [contingentBeneficiary, setContingentBeneficiary] = useState([]);

    useEffect(() => {
        setIsLoading(isLoadingBeneficiary);
    }, [isLoadingBeneficiary]);

    useEffect(() => {
        if (SUBMIT_PURCHASE_DATA && SUBMIT_PURCHASE_DATA.response) {
            setFundAccountNumber(
                SUBMIT_PURCHASE_DATA.response.accountNumber || SUBMIT_PURCHASE_DATA.response.fundAccountNumber,
            );
        } else if (SUBMIT_EXCHANGE_DATA && SUBMIT_EXCHANGE_DATA.fundAccountNumber) {
            setFundAccountNumber(SUBMIT_EXCHANGE_DATA.accountNumber || SUBMIT_EXCHANGE_DATA.fundAccountNumber);
        }
    }, [dispatch, fundId, SUBMIT_PURCHASE_DATA, SUBMIT_EXCHANGE_DATA, masterRegId, fundAccountNumber]);

    useEffect(() => {
        if (fundAccountNumber && fundAccountNumber !== '') {
            dispatch(getBeneficiaries({ masterRegId, fundId }));
        }
    }, [dispatch, masterRegId, fundId, fundAccountNumber]);

    useEffect(() => {
        if (fundBeneInfo && fundBeneInfo.funds && fundAccountNumber !== '') {
            const filteredFund = fundBeneInfo.funds.filter(item => item.fundAccountNumber === fundAccountNumber) || [];
            setPrimaryBeneficiary(filteredFund.length > 0 && filteredFund[0].beneficiaries.primary_Bene);
            setContingentBeneficiary(filteredFund.length > 0 && filteredFund[0].beneficiaries.contingent_Bene);
        }
    }, [fundBeneInfo, fundAccountNumber]);

    function handleRedirect() {
        history.push({
            pathname: '/manageBeneficiariesAccounts',
        });
    }

    return (
        <>
            {isLoading && <WSpinner loading={isLoading} />}
            <PersonalCheckDiv>
                <LeftCardCornerStyle />
                <SectionHeaderRow>
                    <Col xs={12}>
                        <SectionHeadingH3>{BENEFICIARIES_TEXT}</SectionHeadingH3>
                    </Col>
                </SectionHeaderRow>
                <BeneficiaryCard sectionHeading="Primary Beneficiary" beneficiaries={primaryBeneficiary} />
                <BeneficiaryCard sectionHeading="Contingent Beneficiary" beneficiaries={contingentBeneficiary} />
                <BeneCardRow>
                    <Col xs={12}>
                        <WCard
                            buttonstyle={displayNoneStyle}
                            cardtitletext={ReactHtmlParser(BENEFICIARY_CARD_TEXT)}
                            cardtextstyle={wireTransferCardTextStyle}
                            cardbodystyle={wireTransferCardBodyStyle}
                            cardtitlestyle={wireTransferTitleStyle}
                            linkcard="true"
                            leftcornercard={cardBorderStyle}
                            alt=""
                            onClick={handleRedirect}
                        />
                    </Col>
                </BeneCardRow>
            </PersonalCheckDiv>
        </>
    );
};

BeneficiaryCardComponent.propTypes = {
    masterRegId: PropTypes.string,
    fundId: PropTypes.string,
};

BeneficiaryCardComponent.defaultProps = {
    masterRegId: '',
    fundId: '',
};
export default BeneficiaryCardComponent;
