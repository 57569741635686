import makeApiRequest from '../Network/ApiInterface';
import * as TransactionActionConstants from '../ReduxConstants/TransactionActionConstants';


const {
    SAVE_PAGE_STATE_FAILURE,
    SAVE_PAGE_STATE_SUCCESS,
    GET_LIQUIDATION_TRADING_LIMIT_START,
    GET_LIQUIDATION_TRADING_LIMIT_FAILURE,
    GET_LIQUIDATION_TRADING_LIMIT_SUCCESS,
    GET_LIQUIDATION_TRADING_LIMIT,
    CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS,
    CANCEL_TRADING_LIMIT_MESSAGE_FAILURE,
    SET_LIQ_NEXT_PAGE_NUMBER_FAILURE,
    SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_FAILURE,
    SET_TAX_VALIDATION_FAILURE,
    SET_TAX_VALIDATION_SUCCESS,
    SET_FUND_SOURCE_VALIDATION_FAILURE,
    SET_FUND_SOURCE_VALIDATION_SUCCESS,
    SUBMIT_LIQUIDATION_DATA_LOADING,
    SUBMIT_LIQUIDATION_DATA,
    SUBMIT_LIQUIDATION_DATA_SUCCESS,
    SUBMIT_LIQUIDATION_DATA_ERROR,
    GET_STATE_CODE_GROUP_LOADER,
    GET_STATE_CODE_GROUP_FAILURE,
    GET_STATE_CODE_GROUP_SUCCESS,
    GET_STATE_CODE_GROUP,
    GET_STATE_TAXES_FOR_GROUP,
    GET_STATE_TAXES_FOR_GROUP_LOADER,
    GET_STATE_TAXES_FOR_GROUP_SUCCESS,
    GET_STATE_TAXES_FOR_GROUP_FAILURE,
    SET_PAYMENT_METHOD_FAILURE,
    SET_PAYMENT_METHOD_SUCCESS,
    CLEAR_LIQUIDATION_DATA,
    SET_CANCEL_LIQUIDATION,
    SET_NOT_TO_CANCEL_LIQUIDATION,
    SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE,

    RESET_LIQUIDATION_SUBMIT,
    RESET_GO_TO_NEXT_PAGE,
    SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL,
    IS_RESUBMIT_FLOW,
    REMOVE_STEP_DATA
} = TransactionActionConstants;
export const submitLiquidationData = payload => async dispatch => {
    const env = '';
    dispatch({
        type: SUBMIT_LIQUIDATION_DATA_LOADING,
    });
    makeApiRequest(
        SUBMIT_LIQUIDATION_DATA,
        payload,
        env,
        response => {
            // if (response.data) {

            dispatch({
                type: SUBMIT_LIQUIDATION_DATA_SUCCESS,
                payload: response.data,
            });

            // }
            // else {
            //     dispatch({
            //         type: TransactionActionConstants.SUBMIT_LIQUIDATION_DATA_FAILURE,
            //         payload: response,
            //     });
            // }
        },
        error => {
            dispatch({
                type: SUBMIT_LIQUIDATION_DATA_ERROR,
                payload: error,
            });
        },
    );
};

export const getLiquidationTradingLimit = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: GET_LIQUIDATION_TRADING_LIMIT_START,
            });
            const env = '';
            makeApiRequest(
                GET_LIQUIDATION_TRADING_LIMIT,
                payload,
                env,
                response => {
                    if (response) {
                        dispatch({
                            type: GET_LIQUIDATION_TRADING_LIMIT_SUCCESS,
                            payload: response.data,
                        });
                    }
                },
                error => {
                    dispatch({
                        type: GET_LIQUIDATION_TRADING_LIMIT_FAILURE,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: GET_LIQUIDATION_TRADING_LIMIT_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Sets selected payment method in state
 * @param {Object} payload
 */

export const setPaymentMethod = payload => {
    return dispatch => {
        try {
            dispatch({
                type: SET_PAYMENT_METHOD_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SET_PAYMENT_METHOD_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This action is called when the user clicks ok on the trading limit message
 */
export const cancelTradingLimitMessage = () => {
    return async dispatch => {
        try {
            dispatch({
                type: CANCEL_TRADING_LIMIT_MESSAGE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: CANCEL_TRADING_LIMIT_MESSAGE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This is the action to set the state for each page in redux
 */
export const savePageState = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: SAVE_PAGE_STATE_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SAVE_PAGE_STATE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This is the action to set the state for tax validation in redux
 */
export const setTaxValidation = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_TAX_VALIDATION_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SET_TAX_VALIDATION_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This is the action to set the status for fund source validation in redux
 */
export const setFundSourceValidation = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_FUND_SOURCE_VALIDATION_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SET_FUND_SOURCE_VALIDATION_FAILURE,
                payload: error,
            });
        }
    };
};

// /**
//  * Get delivery address to pre-populate when check is selected as payment method
//  */
// export const getCheckDeliveryAddress = () => {
//     return async dispatch => {
//         try {
//             const env = '';
//             dispatch({
//                 type: GET_CHECK_DELIVERY_ADDRESS_LOADER,
//                 payload: { isLoading: true, isError: false },
//             });
//             makeApiRequest(
//                 TransactionActionConstants.GET_CHECK_DELIVERY_ADDRESS,
//                 '',
//                 env,
//                 await (response => {
//                     if (response.data) {
//                         dispatch({
//                             type: GET_CHECK_DELIVERY_ADDRESS_SUCCESS,
//                             payload: response.data,
//                         });
//                     }
//                 }),
//                 error => {
//                     dispatch({
//                         type: GET_CHECK_DELIVERY_ADDRESS_FAILURE,
//                         payload: error,
//                     });
//                 },
//             );
//         } catch (error) {
//             dispatch({
//                 type: GET_CHECK_DELIVERY_ADDRESS_FAILURE,
//                 payload: error,
//             });
//         }
//     };
// };

/**
 * Get delivery address to pre-populate when check is selected as payment method
 */
export const getStateCodeGroup = payload => {
    return dispatch => {
        try {
            const env = '';
            dispatch({
                type: GET_STATE_CODE_GROUP_LOADER,
                payload: { isLoading: true, isError: false },
            });
            makeApiRequest(
                GET_STATE_CODE_GROUP,
                payload,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: GET_STATE_CODE_GROUP_SUCCESS,
                            payload: {
                                taxGroup: response.data.taxGroup,
                                age: response.data.age,
                                state: response.data.address[0].state,
                                customerName: response.data.address[0].customerName,
                                address: response.data.address[0],
                            },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: GET_STATE_CODE_GROUP_FAILURE,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: GET_STATE_CODE_GROUP_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Get state taxes based on group
 */
export const getStateTaxesForGroup = payload => {
    return dispatch => {
        try {
            const env = '';
            dispatch({
                type: GET_STATE_TAXES_FOR_GROUP_LOADER,
                payload: { isLoading: true, isError: false },
            });
            makeApiRequest(
                GET_STATE_TAXES_FOR_GROUP,
                payload,
                env,
                response => {
                    if (response.data) {
                        dispatch({
                            type: GET_STATE_TAXES_FOR_GROUP_SUCCESS,
                            payload: {
                                stateTaxDetails: response.data,
                                distributionAmount: payload.stateTaxPayload.distributionAmount,
                                fedWithHolding: payload.stateTaxPayload.fedWithHolding,
                            },
                        });
                    }
                },
                error => {
                    dispatch({
                        type: GET_STATE_TAXES_FOR_GROUP_FAILURE,
                        payload: error,
                    });
                },
            );
        } catch (error) {
            dispatch({
                type: GET_STATE_TAXES_FOR_GROUP_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Sets the next page number in state
 *
 * @param {Object} payload
 */

export const setNextPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_LIQ_NEXT_PAGE_NUMBER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: SET_LIQ_NEXT_PAGE_NUMBER_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Sets the next page number in state when redirected from view positions
 *
 */

export const setNextPageNumberPositions = pageNumber => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_SUCCESS,
                payload: pageNumber,
            });
        } catch (error) {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_POSITIONS_LIQ_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Resets the page number to 1 in state
 *
 * @param {Object} payload
 */

export const resetPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: RESET_PAGE_NUMBER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: RESET_PAGE_NUMBER_FAILURE,
                payload: error,
            });
        }
    };
};

export const clearLiquidationData = () => dispatch => {
    dispatch({
        type: CLEAR_LIQUIDATION_DATA,
    });
};

export const setCancelLiquidation = selectedFund => dispatch => {
    dispatch({
        type: SET_CANCEL_LIQUIDATION,
        payload: selectedFund,
    });
};

export const setNotToCancelLiquidation = () => dispatch => {
    dispatch({
        type: SET_NOT_TO_CANCEL_LIQUIDATION,
    });
};

export const getCheckDeliveryAddress = () => dispatch => {
   
};

export const resetLiquidationSubmit = () => dispatch => {
    dispatch({
        type: RESET_LIQUIDATION_SUBMIT,
    });
};

export const resetGoToNextPageVariable = () => dispatch => {
    dispatch({
        type: RESET_GO_TO_NEXT_PAGE,
    });
};

export const setProportionateWhenOneFundFail = (payload) => dispatch => {
    dispatch({
        type: SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL,
        payload: payload
    });
}

export const setResubmitFlow = () => dispatch => {
    dispatch({
        type: IS_RESUBMIT_FLOW,
    });
}

export const removeSavedStep = (payload) => dispatch => {
    dispatch({
        type: REMOVE_STEP_DATA,
        payload:payload
    });
}


export const assignFundWhenOneFundFail = (payload) => dispatch => {
    dispatch({
        type: REMOVE_STEP_DATA,
        payload:payload
    });
}