import { connect } from 'react-redux';
import LandingPage from './LandingPage';
import { getMemberInfoByGuid, invalidInfo } from './LandingPage.Action';

const mapStateToProps = (state) => ({
  isMember: state.AccountRegistration.isMember || false,
  isError: state.AccountRegistration.isError || false,
  isInvalid: state.AccountRegistration.isInvalid || false,
  user: state.AccountRegistration.user || {},
});

const mapDispatchToProps = {
  getMemberInfo: getMemberInfoByGuid,
  rejectedInvalidInfo: invalidInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
