import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import constants from 'modules/AccountOptions/constant';

const AccountDetailsHeading = styled.h2`
    text-align: left;
    font: 800 1.375rem/2.8125rem yorkten-slab-normal;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;
const StyledCard = styled.div`
    margin-bottom: 3.125rem;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
`;
const CardSection = styled.div`
    display: flex;
`;
const ColumnOne = styled.div`
    font: 700 1rem/2.25rem benton-sans;
    color: #333333de;
    width: 40%;
`;
const ColumnTwo = styled.div`
    font: 500 1rem/2.25rem benton-sans;
    color: #56565a;
    width: 60%;
`;
const HorizontalSeparator = styled.div`
    border-bottom: 0.0625rem solid rgba(210, 210, 210, 1);
    margin-bottom: 1.25rem;
`;

const AddressData = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: benton-sans;
    letter-spacing: 0px;
    opacity: 1;
`;

const CheckbookComponent = props => {
    const { accountDetails } = props;
    const { fundName, fundAccountNumber, deliveryAddress, accountName, accountNumber } = accountDetails || {};
    return (
        <div>
            <AccountDetailsHeading>{constants.checkbook.accountDetailsHeading}</AccountDetailsHeading>
            <HorizontalSeparator />
            <StyledCard>
                <CardSection>
                    <ColumnOne>{constants.checkbook.accountNameText}</ColumnOne>
                    <ColumnTwo>{accountName}</ColumnTwo>
                </CardSection>
                <CardSection>
                    <ColumnOne>{constants.checkbook.accountNumberText}</ColumnOne>
                    <ColumnTwo>{accountNumber}</ColumnTwo>
                </CardSection>
            </StyledCard>
            <AccountDetailsHeading>{constants.checkbook.deliveryAddressHeading}</AccountDetailsHeading>
            <HorizontalSeparator />
            <StyledCard>
                <CardSection>
                    <ColumnOne>{constants.checkbook.deliveryAddressHeading}</ColumnOne>
                    <ColumnTwo>
                        <AddressData>{deliveryAddress.accountregistrationLine1}</AddressData>
                        <AddressData>{deliveryAddress.accountregistrationLine2}</AddressData>
                        <AddressData>{deliveryAddress.accountRegistrationline3}</AddressData>
                        <AddressData>{deliveryAddress.accountRegistrationline4}</AddressData>
                        <AddressData>{deliveryAddress.accountRegistrationline5}</AddressData>
                        <AddressData>{deliveryAddress.accountRegistrationline6}</AddressData>
                    </ColumnTwo>
                </CardSection>
            </StyledCard>
            <AccountDetailsHeading>{constants.checkbook.fundDetailsHeading}</AccountDetailsHeading>
            <HorizontalSeparator />
            <StyledCard>
                <CardSection>
                    <ColumnOne>{constants.checkbook.fundName.text}</ColumnOne>
                    <ColumnTwo>{fundName}</ColumnTwo>
                </CardSection>
                <CardSection>
                    <ColumnOne>{constants.checkbook.fundAccountNumber.text}</ColumnOne>
                    <ColumnTwo>{fundAccountNumber}</ColumnTwo>
                </CardSection>
            </StyledCard>
        </div>
    );
};

CheckbookComponent.propTypes = {
    accountDetails: PropTypes.instanceOf(Object)
};
CheckbookComponent.defaultProps = {
    accountDetails: {}
};

export default CheckbookComponent;
