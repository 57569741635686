import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import TopLeftCornerColor from '../TopLeftCornerColor';
import KebabMenu from '../KebabMenu';

export const StyledAddressCard = styled(TopLeftCornerColor)`
    margin-top: ${props => props.mT || 0}em;
    margin-left: ${props => props.mL || 0}em;
    height: 100%;
`;

const StyledCardTitle = styled.h2`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #486D90;
    opacity: 1;
    padding: 1.5625rem 1.875rem;
    margin: 0;
    border-bottom: 1px solid #D2D2D2;
`;

const StyledLine = styled.p`
    word-break: break-all;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    align-items: center;
`;

export const CardBody = styled.div`
    padding: ${props => props.pT || 1.875}rem 1.875rem 1.875rem 1.875rem;
`;

const CardWithMenu = (props) => {
    const { cardTitle, Line1, mL, mT,  extradetail, menu, menuOptions, onMenuOptionClick, pT } = props;
    return(
        <StyledAddressCard  mL={mL} mT={mT} cornerSize={25} cornerColor='blue'>
            <StyledCardTitle>{cardTitle}</StyledCardTitle>
            <CardBody pT={pT}>
                <FlexDiv>
                    <StyledLine>{Line1}</StyledLine>
                    { menu && <KebabMenu menuOptions={menuOptions} onMenuOptionClick={onMenuOptionClick} /> }
                </FlexDiv>
                {extradetail}
            </CardBody>
        </StyledAddressCard>
    );
}

CardWithMenu.propTypes = {
    cardTitle: PropTypes.string,
    Line1: PropTypes.string,
    extradetail: PropTypes.node,
    mL: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    mT: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    pT: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    menu: PropTypes.bool,
    menuOptions: PropTypes.instanceOf(Object),
    onMenuOptionClick: PropTypes.func,
};

CardWithMenu.defaultProps = {
    cardTitle: 'Card Title',
    Line1: 'Line 1',
    extradetail: 'Line2',
    mL:0,
    mT:0,
    pT:1.875,
    menu: false,
    menuOptions: [],
    onMenuOptionClick: () => {},
};

export default CardWithMenu;