import envConfig from 'env/EnvConfig';
import RouteConstants from '../../../../routeConstants';

const PATH_CONSTANTS = {
    swp: RouteConstants.systematicwithdrawal,
    beneficiary: '/manageBeneficiariesAccounts',
    article: 'https://investor.vcm.com/tools-resources/investor-learning-center/what-is-a-required-rmd',
    currentYearCalculator: '/rmdCurrentCalculator',
    futureYearCalculator: '/rmdFutureCalculator',
    retirementPlanCalculator: `${envConfig.ENV_URL}/products/retirement`
}
const generalUrls = [
    { url: '/accountholder', name: 'Home' },
];
const RMDLabel = 'Required Minimum Distribution';
const subtitle = 'A required minimum distribution (RMD) is the amount of money that must e withdrawn from a traditional IRA, SEP, or SIMPLE individual retirement account (IRA)';
const RMDInstructionsTitle = 'Instructions to Setup and Manage Automatic Mutual Fund Purchases  ';
const RMDInstructionSubheading = 'Manage Automatic Mutual Fund Purchases';
const RMDInstructionPara1 = `When you make a habit of investing, it can make it easier to achive your financial goals. Get Started in three easy steps`;
const RMDInstructionList = [
    'Choose your Fund',
    `Enter an amount`,
    'Select frequency of transfer',
];
const RMDInstructionPara2 = `There are no fees to set up an automatic investment and you can change the amount at any time`;
const TaxWithholdingTitle = 'Tax Withholding';
const WithholdTaxes = 'Withhold Taxes';
const DontWithholdTaxes = 'Don\'t withhold Taxes';
const FederalTax = 'Federal Tax';
const StateTax = 'State Tax';
const TotalTaxes = 'Total Taxes to be withhold';
const TotalReceived = 'Total you will receive';
const TotalWithdrawal = 'Total Withdrawal';
const DollarSign = '$';
const DepositAccountTitle = 'Deposit Account';
export const DIRECT_SWP_INFO = "This option will help you direct the proceeds of the withdrawal to be reinvested to a new or existing fund.";
const addRMD = {
    title: 'Add Required Minimum Distribution',
    details: 'Details',
    verify: 'Verify'
};
const RMDAccountDetails = [
    'Prior Year-End Balance',
    'Distribution',
    'Deposit To',
    'Tax Withholding',
    'Withdrawal Preference'
];
const RMDFundDetails = [
    'Distribution',
    'Frequency',
    'Next Withdrawal'
];

export const PAY_OPTIONS = {
    BANK: '',
    DIRECT_SWP: 'directedSWP',
    CHECK_ORDER: 'checkOrder'
};

const DepositAccountContent = {
    fundFrom: {
        detailName: DepositAccountTitle,
        description: '',
        orSeperator: '[or]',
        methods: [
            {
                title: 'Online Method',
                desc: '',
                method: 'online',
                details: [
                    {
                        accName: "BANK_ACC1_TEXT",
                        accNumber: 'XXX-Xxx-3838',
                    },
                    {
                        accName: 'Bank Account 2',
                        accNumber: 'XXX-Xxx-5247',
                    },
                    {
                        accName: 'Bank Account 3',
                        accNumber: 'XXX-Xxx-3869',
                    },
                ],
            },
            {
                title: 'Offline Method',
                desc: 'Select one of the below offline payment options.',
                method: 'offline',
                details: [
                    {
                        text: 'Check',

                    },
                    {
                        text: 'Directed SWPs',
                    },

                ],
            },
        ],
    },
    Deliveryaddress: {
        detailName: "Current address on account",
        description: 'Please select and enter the valid address details.',
        addressOptions: [
            { key: 'currentAddress', value: 'Current mailing address' },
            { key: 'newAddress', value: 'Payee and address' },
        ],
        payeeTxt: 'Payee Name',
    },
    investTo: {
        detailName: 'Withdrawal From',
        amtText: 'Amount',
        minAmtTxt: 'Min $',
        titles: {
            column1: "Total Amount",
            column2: 'In',
            column3: 'Amount Utilized',
            column4: 'Amount Unutilized',
            column3Alt: 'Percent Utilized',
            column4Alt: 'Percent Unutilized',
        },
        symbols: {
            dollar: '$',
            percent: '%',
        },
        shares: [
            {
                fundName: "USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES",
                monthlyInvestment: '50',
            },
            {
                fundName: 'Sample Content 2 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                monthlyInvestment: '50',
            },
            {
                fundName: 'Sample Content 3 USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
                monthlyInvestment: '50',
            },
        ],
        key: 'fund_source',
    },
    offlineFundDetails: [
        {
            key: "Check",
            text: 'Check',
        },
        {
            key: "DirectTransfer",
            text: 'Directed Transfer',
        },

    ],
};

const withdrawalFrom = {
    label: 'Withdrawal From',
    totalDistributionAmount: 'Total Distribution Amount',
    IRSMandated: 'IRS Mandated (Based on prior year balance held at Victory Capital)'
};
const APPLY_TO_ALL = 'applyToAll';
const APPLY_TO_ONE_OR_MORE = 'applyToOneOrMore'
const distributionOptions = [{
    key: APPLY_TO_ALL,
    label: 'Apply proportionately across all of my funds'
    }, {
    key: APPLY_TO_ONE_OR_MORE,
    label: 'Apply to one or more funds of my choice'
}];

const renewalOptions = [
    {
        key: 'yes',
        label: 'Yes'
    },
    {
        key: 'no',
        label: 'No'
    }
]

const FREQUENCY = [
    { key: 'B', value: 'Biweekly' },
    { key: 'M', value: 'Monthly' },
    { key: 'Q', value: 'Quarterly' },
    { key: 'T', value: 'Semi-Annually' },
    { key: 'A', value: 'Annually' },
];

const RMDtooltip = 'A required minimum distribution (RMD) is the amount of money that must be withdrawn from a traditional IRA, SEP, or SIMPLE individual retirement account (IRA) by owners and qualified retirement plan participants of retirement age.'

const youSelect = {
    pageGuidance :[
        // page index 0 - Start
        ``, 
        // page index 1 - Select an Account
        `Select an Account from which your RMD will taken from. 
        Reminder: The distribution is based on all your prior year-end
        balance.`, 
        // page index 2 - Provide information
        [
            // for start question    
            `Let us make this easy for you. We can calculate your annual RMD based on the prior year-end balance on the funds you hold with Victory Capital.`,
            // for questions with beneficiaries link
            `Review your beneficiaries regularly. Beneficiaries impact your
            RMD calculations.  Victory Capital does not provide tax advice. Contact your tax advisor for guidance regarding your situation.`
        ], 
        // page index 3 - Select a fund
        [
            // for all funds 
            `Withdrawal from selected funds.`,
            // for one fund
            `Select which fund to withdraw from.`
        ], 
        // page index 4 - Tax Withholding & Depozit Account
        `Set up a regularly scheduled withdrawal. You can set the start date, the frequency, and provide your tax withholding. Victory Capital does not provide tax advice. Contact your tax advisor for guidance regarding your situation.`,

        // page index 5 - Review & Complete
        `Please verify all your information.`, 
    ],
} 

export default {
    generalUrls,
    RMDLabel,
    subtitle,
    RMDInstructionsTitle,
    RMDInstructionSubheading,
    RMDInstructionPara1,
    RMDInstructionList,
    RMDInstructionPara2,
    TaxWithholdingTitle,
    WithholdTaxes,
    DontWithholdTaxes,
    FederalTax,
    StateTax,
    TotalTaxes,
    TotalReceived,
    TotalWithdrawal,
    addRMD,
    DollarSign,
    RMDAccountDetails,
    RMDFundDetails,
    DepositAccountContent,
    distributionOptions,
    renewalOptions,
    FREQUENCY,
    withdrawalFrom,
    APPLY_TO_ALL,
    APPLY_TO_ONE_OR_MORE,
    RMDtooltip,
    youSelect,
    PATH_CONSTANTS
}