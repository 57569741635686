import styled from 'styled-components';
import { Container, Row, Col, Form } from 'react-bootstrap';
import VCMMultiSelectDropDown from '../../../common/WMultiSelectDropDown/VcmMultiSelectDropDownWithLabel';
import VCMSelectDropDown from '../../../common/SelectDropDown/index';
import WIcon from '../../../common/WIcon/WIcon';

export const CreateMessageHeader = styled(Row)`
   & .col-sm-1 {
    max-width:10%;
    }
`;
export const FooterDiv = styled(Row)`
  background:#FAFAFA 0% 0% no-repeat padding-box
  padding-top:20px;
  margin-top: 20px;
  height:90px;  
  margin-left:0px;
  margin-right:0px

  > button > .backButton{
      padding-top:30px;
  }
`;
export const RejectedFileHeader = styled(Row)`
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
`;

export const RejectedFilesContainer = styled.ul`
    padding-left: 20px;
`;
export const RejectedFile = styled.li`
    color: red;
`;

export const ContainerDiv = styled.div`
    margin-left: 0px;
    margin-right: 0px;
`;
export const OptionalTextStyle = styled.span`
    font: 500 12px/20px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 10px;
`;

export const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

export const FormContainer = styled(Container)`
    padding-left: 0px;
    padding-right: 0px;
    & [class*='col-sm'] {
        padding-left: 0px;
        padding-right: 0px;
    }

    & small {
        font-weight: normal;
    }
`;

export const DropDownRow = styled(VCMSelectDropDown)`
    ~ .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
`;

export const MultiSelectDropDownRow = styled(VCMMultiSelectDropDown)`
    margin-right: 0px !important;
    margin-left: 0px !important;
`;
export const RequiredFiledText = styled(Row)`
    text-align: right;
`;

export const CreateSecureMessageHeader = styled.h1`
    color: rgba(72, 109, 144, 1);
    font: 800 30px/36px yorkten-slab-normal;
    border-bottom: 2px solid #8bc105;
    height: fit-content;
    padding-bottom: 10px;
`;

export const FormRow = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    & [class*='col-'] {
        padding-left: 0px;
        padding-right: 2px;
        & label {
            padding-left: 0px;
            padding-right: 0px;
        }
        & input,
        textarea {
            margin-left: 0px !important;
        }

        & small {
            font: 600 12px / 22px benton-sans;
            font-weight: 700 !important;
        }
    }

    > div > .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    > div > div > div > small {
        font-weight: normal;
    }
`;

export const SubmitButtonCol = styled(Col)`
    padding-left: 0px;
`;

export const Label = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

export const StyledInput = styled.input`
    width: 100%;
    height: 3.125rem;
    padding: 1rem 0 1.0625rem 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid ${props => (props.isOpen ? '#486d90' : '#d2d2d2')};
    opacity: 1;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0;
    color: #56565a;
    &:hover {
        border: 1px solid #486d90;
    }
`;

export const FileContainerStyle = styled(Container)`
    margin-left: 15%;
    margin-bottom: 0.5%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
`;

export const FileSizeStyle = styled(Col)`
    font-weight: 600;
    padding-left: 46px;
    padding-bottom: 2%;
    width: 100%;
`;
export const AttachmentTextStyle = styled.div`
    font-weight: 600;
    display: flex;
    flex-direction: row;
`;
export const AttachmentListItem = styled(Row)`
    display: flex;
    padding-bottom: 10px;
    color: #004a98;
    border: 1px solid white;
    background-color: #fafafa;
    padding: 1%;
    margin-left: 0px;
    margin-bottom: 1px;
`;
export const SubListContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
export const RemoveButtonStyle = styled(WIcon)`
    display: flex;
    padding-top: 1px;
`;

export const DeleteTextStyle = styled(Col)`
    margin-left: 0%;
    font-weight: 600;
    cursor: pointer;
`;


export const RemoveButton = styled.button`
    font-family: benton-sans, sans-serif;
    padding-top: 1px;
    font-size: 16px;
    display: inline-flex;
    padding: 0;
    flex-direction:row;
    cursor:pointer;
    border:none;
    cursor: pointer;
    background:transparent
    align-items: center;
`;