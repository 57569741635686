import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import dragDropImage from '../../assets/dragDropImage.png';

const Container = styled.div`
    border: 2px dashed #d2d2d2;
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #d2d2d2;
    background-color: #ffffff;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
`;
const RightContainer = styled(Col)`
    display: block;
    :focus {
        outline: none;
    }
`;
const DropFont = styled.p`
    color: #49494a;
    font-weight: 600;
    cursor: pointer;
`;
const SelectFont = styled.p`
    color: #004a98;
    font-weight: 900;
`;


const Constants = {
    FILE_NOT_SUPOORtED: 'Rejected Files, sorry!',
    FILE_TOO_LARGE: 'File is too large.',
    DROP_FILES: 'Drop your files here',
    SELECT_FILES: 'Select Files',
    DRAG_REJECTED_FILE: 'Sorry, File Rejected',
    OR: '[or]',
    SELECTING_FILES: 'Dropping file...',
};

function FileUploader(props) {
    const { maxSize, minSize, accept, isMultiple, onDropAccepted, onDropRejected } = props;

    const { isDragActive, getRootProps, getInputProps} = useDropzone({
        accept,
        minSize,
        maxSize,
        multiple: isMultiple,
        onDropAccepted,
        onDropRejected,
    });

   
    function getImageStyle() {
        return { paddingBottom: '2%', marginTop: '6%' };
    }

    const showDrapDropText = () => {
        return (
            <>
                {isDragActive && <DropFont className="dropping-files">{Constants.SELECTING_FILES}</DropFont>}
                {!isDragActive && (
                    <>
                        <DropFont className="drop-file-text" >{Constants.DROP_FILES}</DropFont>
                        <p className="or-text">{Constants.OR}</p>
                        <SelectFont className="select-file-text">{Constants.SELECT_FILES}</SelectFont>
                    </>
                )}
            </>
        );
    };

   
    return (
        <Container>
            <RightContainer {...getRootProps()}>
                <img style={getImageStyle()} src={dragDropImage} id="drop-icon-id" alt="Logo" />
                <input id="file-drop-input-id" {...getInputProps()} />
                {showDrapDropText()}
            </RightContainer>
        </Container>
    );
}

FileUploader.propTypes = {
    onDrop: PropTypes.func,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    accept: PropTypes.string,
    isMultiple: PropTypes.bool,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
};

FileUploader.defaultProps = {
    onDrop: () => {},
    maxSize: 31457280,
    minSize: 0,
    isMultiple: false,
    accept: '.PDF, .JPG, .JPEG, .GIF, .BMP, .PNG, .XLSX,.XLS, .DOC, .DOCX',
    onDropAccepted: () => {},
    onDropRejected: () => {},
};
export default FileUploader;
