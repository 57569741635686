import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    value: [],
    currentPageNumber: 1,
    pageSize: 6,
    marstoneNAOPayload: {},
    specialtyApplicationForms: [],
    showConfirmModal: false,
    isLoadingApplication: false,
    accountDefaultNickName: '',
    primaryOwnerVerified: false,
    showCustomerVerificationModal: false,
    showCustomerVerificationModalJoint1: false,
    showCustomerVerificationModalJoint2: false,
    verifyCustomerDetailsErrMsg: '',
    jointOwner1Verified: false,
    jointOwner2Verified: false,
    cognitoTokenForGIACT: '',
    nickNameData: [],
    nickNameDataForManage:[],
    nickNameUpdatedStatus:false
};

function accOpeningReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_REDUX_DATA:
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: false,
            };

        case ActionTypes.LOADING:
        case ActionTypes.GET_ACCOUNT_SUBTYPES_LOADER:
        case ActionTypes.GET_ACCOUNT_TYPES_LOADER:
        case ActionTypes.GET_FUNDLIST_LOADER:
        case ActionTypes.SUBMIT_OPENING_ACCT_LOADER:
        case ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_LOADER:
        case ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.VERIFY_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.ESIGN_SAVE_OPENING_ACCT_LOADER:
        case ActionTypes.GET_FUNDDETAILS_LOADER:
        case ActionTypes.VALIDATE_BANK_ACCOUNT_LOADER:
        case ActionTypes.UPLOAD_AVATAR_LOADER:
        case ActionTypes.UPLOAD_W8FORM_LOADER:
        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START:
        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_START_FOR_MANAGE:  {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        }
       
        case ActionTypes.GET_ACCOUNT_TYPES: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.SELECT_ACCT: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.SELECT_ACCT_TYPE: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.GET_ACCOUNT_SUBTYPES: {
            return {
                ...state,
                ...action,
            };
        }

        case ActionTypes.GET_ACCOUNT_TYPES_SUCCESS:
        case ActionTypes.GET_ACCOUNT_SUBTYPES_SUCCESS:
        case ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.VERIFY_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.ESIGN_SAVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.SUBMIT_OPENING_ACCT_SUCCESS:
        case ActionTypes.RETRIVE_OPENING_ACCT_SUCCESS:
        case ActionTypes.GET_ACCOUNT_FUNDLIST_SUCCESS:
        case ActionTypes.GET_FUNDDETAILS_SUCCESS:
        case ActionTypes.VALIDATE_BANK_ACCOUNT_SUCCESS:
        case ActionTypes.UPLOAD_AVATAR_SUCCESS:
        case ActionTypes.UPLOAD_W8FORM_SUCCESS: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: false,
                isSuccess: true,
            };
        }
        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS: {
            return {
                ...state,
                ...action,
                nickNameData: {
                    ...action.payload,
                    showMessage: true,
                },
                isLoading: false,
                isSuccess: true,
                isError: false,
                nickNameUpdatedStatus: true
            };
        }

        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_SUCCESS_FOR_MANAGE: {
            return {
                ...state,
                ...action,
                nickNameDataForManage: {
                    ...action.payload,
                    showMessage: true,
                },
                isLoading: false,
                isSuccess: true,
                isError: false,
            };
        }
        /* case ActionTypes.GET_FUNDLIST_SUCCESS: {
                return {
                    [ActionTypes.GET_FUNDLIST]: [...action[ActionTypes.GET_FUNDLIST].map(v => ({ ...v, isActive: false }))],
                    isFilterApplied: false,
                    ...state,
                    isLoading: false,
                    isError: false,
                    isSuccess: true

                };
            }
            */
        case ActionTypes.GET_ACCOUNT_TYPES_ERROR:
        case ActionTypes.GET_ACCOUNT_TYPES_FAILURE:
        case ActionTypes.GET_ACCOUNT_SUBTYPES_FAILURE:
        case ActionTypes.GET_ACCOUNT_FUNDLIST_ERROR:
        case ActionTypes.GET_ACCOUNT_FUNDLIST_FAILURE:
        case ActionTypes.ACCT_TYPE_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.PERSONAL_INFO_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.INVEST_SELECT_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.VERIFY_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.PREFERENCE_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.ESIGN_SAVE_OPENING_ACCT_FAILURE:
        case ActionTypes.SUBMIT_OPENING_ACCT_FAILURE:
        case ActionTypes.RETRIVE_OPENING_ACCT_FAILURE:
        case ActionTypes.GET_FUNDDETAILS_FAILURE:
        case ActionTypes.VALIDATE_BANK_ACCOUNT_FAILURE:
        case ActionTypes.GET_FUNDDETAILS_ERROR:
        case ActionTypes.UPLOAD_AVATAR_FAILURE:
        case ActionTypes.UPLOAD_W8FORM_FAILURE: {
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE: {
            return {
                ...state,
                ...action,
                nickNameData: {},
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        case ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FAILURE_FOR_MANAGE: {
            return {
                ...state,
                ...action,
                nickNameDataForManage: {},
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
            };
        }
        case ActionTypes.GET_FUNDDETAILS: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.POPULATE_PENDING_APPLICATION: {
            return {
                ...state,
                ...action,
            };
        }

        case ActionTypes.SET_NEXT_PAGE_NUMBER_NAO_SUCCESS: {
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1,
            };
        }

        case ActionTypes.SET_NEXT_PAGE_NUMBER_NAO_FAILURE: {
            return {
                ...state,
                isError: true,
                error: ActionTypes.NEXT_PAGE_ERROR,
            };
        }

        case ActionTypes.RESET_PAGE_NUMBER_NAO_SUCCESS: {
            return {
                ...state,
                currentPageNumber: 1,
            };
        }

        case ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_SUCCESS: {
            const { specialtyApplicationForms } = action;
            return {
                ...state,
                isError: false,
                isLoading: false,
                specialtyApplicationForms,
            };
        }

        case ActionTypes.SET_SPECIALTY_APPLICATION_SUBTYPE: {
            const { specialtyApplicationSubtype } = action;
            return {
                ...state,
                specialtyApplicationSubtype,
                specialtyApplicationForms: [],
            };
        }

        case ActionTypes.RESET_PAGE_NUMBER_NAO_FAILURE: {
            return {
                ...state,
                isError: true,
                error: ActionTypes.NEXT_PAGE_ERROR,
                currentPageNumber: 1,
            };
        }

        case ActionTypes.GET_SPECIALTY_APPLICATION_FORMS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                forms: [],
            };
        case ActionTypes.SAVE_APPLICATION_LOADER:
        case ActionTypes.DELETE_APPLICATION_LOADER:
        case ActionTypes.GET_SAVED_APPLICATIONS_LOADER:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };

        case ActionTypes.GET_APPLICATION_LOADER:
            return {
                ...state,
                isLoadingApplication: true,
                isError: false,
                isSuccess: false,
            };

        case ActionTypes.GET_SAVED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                savedApplications: action.payload,
            };
        case ActionTypes.GET_SAVED_APPLICATIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                error: action.payload,
                savedApplications: [],
            };

        case ActionTypes.GET_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoadingApplication: false,
                isError: false,
                isSuccess: true,
                application: action.payload.payload,
                uuid: action.payload.uuid,
            };
        case ActionTypes.DELETE_APPLICATION_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                error: action.payload,
                application: {},
            };
        case ActionTypes.GET_APPLICATION_ERROR:
            return {
                ...state,
                isLoadingApplication: false,
                isError: true,
                isSuccess: false,
                error: action.payload,
                application: {},
            };

        case ActionTypes.SAVE_APPLICATION_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                confirmMessage: 'There was an error while saving the data. Data could not be saved.',
                showConfirmModal: true,
            };

        case ActionTypes.SAVE_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                confirmMessage: `Your new account application has been saved, you can find it in 'My Saved Items' on the dashboard whenever you would like to continue.`,
                uuid: action.payload.uuid,
                showConfirmModal: true,
            };

        case ActionTypes.DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                application: {},
                uuid: undefined,
            };

        case ActionTypes.RESET_SHOW_CONFIRM_MODAL:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                showConfirmModal: false,
                confirmMessage: '',
            };

        case ActionTypes.ACCOUNT_OPENING_CLEAR_LOCAL_STATE: {
            return {
                ...initialState,
            };
        }
        case ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_SUCCESS: {
            return {
                ...state,
                marstoneNAOPayload: action.payload,
                isLoading: false,
                isError: false,
            };
        }

        case ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_FAILURE: {
            return {
                ...state,
                marstoneNAOPayload: {},
                isLoading: false,
                isError: true,
            };
        }

        case ActionTypes.GET_DATA_FOR_MARSTORNE_REDIRECTION_LOADER: {
            return {
                ...state,
                marstoneNAOPayload: {},
                isLoading: true,
                isError: false,
            };
        }

        case ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_SUCCESS: {
            const accNickName =
                action.payload.accounts[0] &&
                action.payload.accounts[0].defaultNickName &&
                action.payload.accounts[0].defaultNickName.split('_');
            return {
                ...state,
                accountDefaultNickName: accNickName[0],
                isLoading: false,
                isError: false,
            };
        }

        case ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_FAILURE: {
            return {
                ...state,
                accountDefaultNickName: '',
                isLoading: false,
                isError: true,
            };
        }
        case ActionTypes.GET_DEFAULT_ACCOUNT_NICK_NAME_LOADER: {
            return {
                ...state,
                accountDefaultNickName: '',
                isLoading: true,
                isError: false,
            };
        }

        case ActionTypes.VERIFY_CUSTOMER_DETAILS_LOADER: {
            return {
                ...state,
                showCustomerVerificationModal: false,
                verifyCustomerDetailsErrMsg: '',
                primaryOwnerVerified: false,
                jointOwner1Verified: false,
                jointOwner2Verified: false,
                isLoading: true,
                isError: false,
            };
        }

        case ActionTypes.VERIFY_CUSTOMER_DETAILS_SUCCESS: {
            return {
                ...state,
                primaryOwnerVerified: action.payload.result.customerVerified === 'true',
                showCustomerVerificationModal: action.payload.result.customerVerified === 'false',
                verifyCustomerDetailsErrMsg: ActionTypes.CUSTOMER_NOT_VERIFIED_ERROR_MESSAGE,
                isLoading: false,
                isError: false,
            };
        }
        case ActionTypes.VERIFY_CUSTOMER_DETAILS_FIRST_JOINT_OWNER_SUCCESS: {
            return {
                ...state,
                jointOwner1Verified: action.payload.result.customerVerified === 'true',
                showCustomerVerificationModalJoint1: action.payload.result.customerVerified === 'false',
                verifyCustomerDetailsErrMsg: ActionTypes.CUSTOMER_NOT_VERIFIED_ERROR_MESSAGE,
                isLoading: false,
                isError: false,
            };
        }
        case ActionTypes.VERIFY_CUSTOMER_DETAILS_SECOND_JOINT_OWNER_SUCCESS: {
            return {
                ...state,
                jointOwner2Verified: action.payload.result.customerVerified === 'true',
                showCustomerVerificationModalJoint2: action.payload.result.customerVerified === 'false',
                verifyCustomerDetailsErrMsg: ActionTypes.CUSTOMER_NOT_VERIFIED_ERROR_MESSAGE,
                isLoading: false,
                isError: false,
            };
        }
        case ActionTypes.VERIFY_CUSTOMER_DETAILS_FAILURE: {
            return {
                ...state,
                primaryOwnerVerified: false,
                jointOwner1Verified: false,
                jointOwner2Verified: false,
                showCustomerVerificationModal: true,
                showCustomerVerificationModalJoint1: true,
                showCustomerVerificationModalJoint2: true,
                verifyCustomerDetailsErrMsg: ActionTypes.CUSTOMER_NOT_VERIFIED_ERROR_MESSAGE,
                isLoading: false,
                isError: true,
            };
        }

        case ActionTypes.SET_CUSTOMER_VERIFICATION_SUCCESS: {
            return {
                ...state,
                showCustomerVerificationModal: action.payload,
                showCustomerVerificationModalJoint1: action.payload,
                showCustomerVerificationModalJoint2: action.payload,
            };
        }
        case ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_LOADER: {
            return {
                ...state,
                cognitoTokenForGIACT: '',
                isLoading: true,
                isError: false,
            };
        }
        case ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_SUCCESS: {
            return {
                ...state,
                cognitoTokenForGIACT: action.payload,
                isLoading: false,
                isError: false,
            };
        }
        case ActionTypes.COGNITO_TOKEN_FOR_CUSTOMER_VERIFICATION_FAILURE: {
            return {
                ...state,
                cognitoTokenForGIACT: '',
                isLoading: false,
                isError: true,
            };
        }
        case ActionTypes.SET_PRIMARY_OWNER_VERIFIED_SUCCESS: {
            return {
                ...state,
                primaryOwnerVerified: false,
                jointOwner1Verified: false,
                jointOwner2Verified: false,
                showCustomerVerificationModal: false,
                showCustomerVerificationModalJoint1: false,
                showCustomerVerificationModalJoint2: false,
            };
        }

        case ActionTypes.REMOVE_SAVED_ITEM_LOADER:
            return {
                ...state,
                isLoadingApplication: true,
                isError: false,
                isSuccess: false,
                isLoading: true,
            };
        case ActionTypes.REMOVE_SAVED_ITEM_SUCCESS:
            return {
                ...state,
                isLoadingApplication: false,
                isError: false,
                isSuccess: true,
                removeSavedApplicationPayload: action.payload,
                uuid: action?.payload?.result?.applicationId,
                isLoading: false,
            };
        case ActionTypes.REMOVE_SAVED_ITEM_ERROR:
            return {
                ...state,
                isLoadingApplication: false,
                isError: true,
                isSuccess: false,
                error: action.payload,
                application: {},
                isLoading: false,
            };
        case ActionTypes.REMOVE_RESET_SAVED_LIST:
            return {
                ...state,
                removeSavedApplicationPayload :null
            }

    
        case ActionTypes.NICKNAME_UPDATED_STATUS: {
            return {
                ...state,
                nickNameUpdatedStatus: false,
            };
        }

            
        default:
            return state;
    }
}

export default accOpeningReducer;
