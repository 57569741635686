const userRouteConstants = {
    changeSignInMethodLink: '/ChangeSignInMethodOptions',
    homeLink: '/',
    profileLink: '/profile',
    securityPreferencesLink: '/SecurityPreferences',
    signIn: '/sign-in',
    setupSecurityQuestions: '/setup-security-questions',
    otpivr:'/otp-ivr-select',
    memberDashboard:'/accountholder',
    guestDashboard:'/accountguestview',
    serverError:'/servererror',
    transactionDashboard:'/transactionPurchases'
}

export default userRouteConstants;