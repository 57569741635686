import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { WBreadCrumb } from '../../common';
import './Styles/Styles.css';
import './Styles/KJE.css';
import { CALCULATOR_DESCRIPTION, RETIREMENT_NEST_EGG_CALCULATOR, BREAD_CRUMB_VALUES } from './Constants';
import SocialMediaComponent from './SocialMediaComponent';
import { PlanLabelH1, DescriptionP, CalculatorContainerCol } from './Styles/CalculatorStyles';

function RetirementNestEggCalculator() {
    function script2Loaded() {
        window.KJE.initAfterLoad();
    }

    function paramsScript() {
        const script3 = document.createElement('script');
        script3.src = '/scripts/RetirementNesteggParams.js';
        script3.async = true;
        script3.onload = () => script2Loaded();
        document.body.appendChild(script3);
    }

    function scriptSrc() {
        const script2 = document.createElement('script');
        script2.src = '/scripts/RetirementNestegg.js';
        script2.async = true;
        script2.onload = () => paramsScript();
        document.body.appendChild(script2);
    }

    useEffect(() => {
        document.title = `Retirement NestEgg Calculator: Calculators | Victory Capital`;
        const script1 = document.createElement('script');
        script1.src = '/scripts/KJE.js';
        script1.async = true;
        script1.onload = () => scriptSrc();
        document.body.appendChild(script1);
    });

    return (
        <div className="container" data-test="calculatorContainer">
            <WBreadCrumb
                activeCrumb={RETIREMENT_NEST_EGG_CALCULATOR}
                breadCrumbItems={BREAD_CRUMB_VALUES}
                data-test="breadCrumb"
            />
            <Row>
                <CalculatorContainerCol sm={8}>
                    <PlanLabelH1 data-test="calculatorTitleLabel">{RETIREMENT_NEST_EGG_CALCULATOR}</PlanLabelH1>
                    <DescriptionP data-test="calculatorDescription">{CALCULATOR_DESCRIPTION}</DescriptionP>
                    <div id="KJEAllContent" data-test="calculatorContent" />
                </CalculatorContainerCol>
                <SocialMediaComponent data-test="socialMediaContent" />
            </Row>
        </div>
    );
}

export default RetirementNestEggCalculator;
