import React from 'react';
import PropTypes from 'prop-types';

import { SecurityQuestion } from '../Input/SecurityQuestion';
import { SecurityAnswer } from '../Input/SecurityAnswer';
// import { PrimaryEmail } from '../Input/PrimaryEmail';
// import { AdditionalEmail } from '../Input/AdditionalEmail';
import data from './constants';
import styles from '../UserManagementStyles';

const assignObj = obj => obj;

const SetupSecurityQuestionsForm = (props) => {
    const { state, changed, blurred, questionsData } = props;
    const { form } = state;
    // let additionalEmail = null;

    // additionalEmail = addEmail ? <AdditionalEmail
    //     additionalEmailState={form.additionalEmail}
    //     changed={changed}
    //     blurred={blurred} /> :
    //     <button type="button" id='addEmail' style={data.addEmailStyle} onClick={addEmailHandler}>
    //         {/* <div style={data.plus}>
    //             <div style={data.plusAfter} />
    //             <div style={data.plusBefore} />
    //         </div> */}
    //         {data.addEmailText}
    //     </button>
    return (
        <div>
            <span style={assignObj({...styles.optionalMessage, marginBottom: 30})}>{data.optionalMessage}</span>
            {data.questions.map(question => {
                return (
                    <div key={question.id}>
                        <SecurityQuestion
                            securityQuestion={questionsData}
                            securityQuestionState={form}
                            changed={changed}
                            label={question.label}
                            id={question.id}
                            selectStyle={data.selectStyle} />
                        <SecurityAnswer
                            securityAnswerState={form[question.answerId]}
                            changed={changed}
                            blurred={blurred}
                            label={question.answerLabel}
                            id={question.answerId}
                            answerStyle={data.answerStyle} 
                            aria-required="true"
                            />
                    </div>
                )
            })}
            {/* <div>
                <PrimaryEmail
                    primaryEmailState={form.primaryEmail}
                    changed={changed}
                    blurred={blurred} />
                {additionalEmail}
            </div> */}

        </div>
    );
}

SetupSecurityQuestionsForm.propTypes = {
    questionsData: PropTypes.instanceOf(Object),
    state: PropTypes.instanceOf(Object),
    prefixData: PropTypes.shape({
        result: PropTypes.shape({
            value: PropTypes.array
        })
    }),
    suffixData: PropTypes.shape({
        result: PropTypes.shape({
            value: PropTypes.array
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    // addEmailHandler: PropTypes.func
}

SetupSecurityQuestionsForm.defaultProps = {
    questionsData: null,
    state: {},
    prefixData: null,
    suffixData: null,
    changed: () => {},
    blurred: () => {},
    // addEmailHandler: () => {}
}

export default SetupSecurityQuestionsForm;
