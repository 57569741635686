import * as ActionTypes from '../../../../shared/ReduxConstants/ServiceActionConstants';
import * as TransactionConstants from '../../TransactionConstants';
import { BottomNavSection } from '../../Common/BottomNavWrapper';
import { connect } from 'react-redux';
import ErrorBoundary from '../../Common/ErrorBoundary/errorboundary';
import { getToken } from 'utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReInvestComponent from '../../Common/ReInvest/ReInvestComponent';
import styled from 'styled-components';
import TaxAccounting from '../../Common/TaxAccounting';
import TransactionInvestAccountCardComponent from '../../../../common/TransactionInvestAccountCard/TransactionInvestAccountCardComponent';
import {
    WSpinner,
    AccountInfoCard,
    Accordian,
    AccordianTitle,
    ErrorMessageBar,
    WErrorModal,
    WButton,
    FeeGatesWarningModal,
} from '../../../../common';

const assignObj = obj => {
    return obj;
};

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};

const ButtonStyle = styled.button`
    width: 150px;
    height: 50px;
    opacity: 1;
    text-align: center;
    font: 500 16px/22px Benton Sans;
    letter-spacing: 0;
    border: 1px solid #61285f45;
    &:disabled {
        opacity: 0.5;
    }
`;

const EmptyAccountsP = styled.p`
    font: 500 16px/22px Benton Sans;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SecondaryButton = styled(ButtonStyle)`
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #544a54;
`;
SecondaryButton.displayName = 'SecondaryButton';

const PrimaryButton = styled(ButtonStyle)`
    background: #544a54 0% 0% no-repeat padding-box;
    color: #ffffff;
`;
PrimaryButton.displayName = 'PrimaryButton';

const AccountCard = {
    marginTop: '40px',
    marginBottom: '40px',
};


const LoadMoreDiv = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family; benton-sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #004A98;
    cursor:pointer;
`;

class InvestmentSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fundsAvailable: [],
            selectedFund: [],
            displayNotification: false,
            notificationMsg: '',
            showErrorModal: false,
            modalAlertMessage: '',
            retrivedState: false,
            isLoading: false,
            ReInvestSwitchOn: false,
            switchOn: true,
            switchOff: false,
            ReInvest: true,
            disableNext: true,
            isMoneyMarketFund: false,
            showWarningDialog: false,
            touchedIndex: 0,
            touchedFundName: 'fundName',
            touchedFundPercentValue: '0%',
            touchedFundNo: '',
            touchedFundAccNum: '',
            isFee: false,
            fundCheckStatus: false
        };
        this.exchangeInvSelectionRef = React.createRef();
        this.investToRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            savedState,
            masterLookupStateData,
            fundAccountData,
            accOpeningData,
            exchangeData,
            onSwitchComponent,
        } = nextProps;
        const { fundsAvailable, selectedFund } = prevState;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                fundsAvailable: savedState.fundsAvailable.length
                    ? savedState.fundsAvailable
                    : fundAccountData.fundAccountList,
                disableNext: Object.keys(selectedFund).length,
            };
        }
        if (exchangeData.goToNextPage) {
            onSwitchComponent(true, prevState, 1)();
        }

        return {
            ...prevState,
            masterLookupData: masterLookupStateData,
            isLoading: fundAccountData.isLoading || accOpeningData.isLoading,
            fundsAvailable: fundsAvailable.length ? fundsAvailable : fundAccountData.fundAccountList,
            displayNotification: masterLookupStateData.isError,
            accountNumber: exchangeData.page0 && exchangeData.page0.displayDetails.accountSelection.data[1].value,
            accountName: exchangeData.page0 && exchangeData.page0.displayDetails.accountSelection.data[0].value,
            accountType: exchangeData.page0 && exchangeData.page0.displayDetails.accountSelection.data[2].value,
            disableNext: Object.keys(selectedFund).length,
            isMoneyMarketFund: exchangeData.page1 && exchangeData.page1.selectedFund.fundType === 'Money Market',
        };
    }

    componentDidMount() {

        this.scrollToTop();
        sessionStorage.setItem('stepname', 'Investment Selection');
        const { savedState, editSection, getFunds, exchangeData, setTradingWarning, transactionType } = this.props;
        const { offsetTop = 0 } = {};
        // Set page title for AX
        document.title = TransactionConstants.EXCHANGE_INVESTMENT_SELECTION_FROM_TITLE_TEXT;
        if (editSection === TransactionConstants.ACCOUNT_SELECTION_TEXT) {
            window.scrollTo({
                top: offsetTop,
                behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
            });
        }
        if (!savedState) {
            const masterRegistrationId =
                exchangeData.page0 && exchangeData.page0.displayDetails.accountSelection.data[1].value;
            const accountType = exchangeData.page0 && exchangeData.page0.displayDetails.accountSelection.data[2].value;
            const selectedFund = exchangeData.page1 && exchangeData.page1.selectedFund.symbol;
            const exchangeAmount =
                exchangeData.page1 && exchangeData.page1.selectedFund.amtType === 'allshare'
                    ? exchangeData.page1.selectedFund.accountValue
                    : exchangeData.page1.selectedFund.sellingamt;

            const accountTypeCode =
                exchangeData.page0 &&
                exchangeData.page0.accountSelected &&
                exchangeData.page0.accountSelected.accountTypeCode;
            const socialCode =
                exchangeData.page0 &&
                exchangeData.page0.accountSelected &&
                exchangeData.page0.accountSelected.socialCode;

            const fundListPayload = {
                masterRegistrationId,
                accountType,
                companyNumber: TransactionConstants.USAA_FUND_COMPANY_NUMBER.companyNumber,
                exchangeAmount,
                selectedFund,
                accountTypeCode,
                socialCode
            };
            if (transactionType === 'exchange') {
                if (exchangeData.page2 == undefined || exchangeData.page2 == null) {
                }
                else {
                    this.setState({
                        ReInvestSwitchOn: exchangeData.page2.ReInvestSwitchOn,
                        switchOn: exchangeData.page2.switchOn,
                        switchOff: exchangeData.page2.switchOff,
                        ReInvest: exchangeData.page2.ReInvest,
                    })
                }
            }
            getFunds({ fundListPayload, token: getToken() }, false, false, true);
        }
        setTradingWarning();
        document.getElementById('skipToMain')?.focus();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }
    }

    analyzeClick = name => e => {
        const { analyzeClickEvent } = this.props;
        let linkText = name;
        if (e && e.target) {
            linkText = e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id');
        }
        analyzeClickEvent({
            link: linkText,
        });
    };

    switchOnMethod = name => e => {
        e.preventDefault();
        this.analyzeClick(name);
        this.setState(previousState => ({
            ...previousState,
            switchOn: false,
            switchOff: true,
            ReInvest: false,
        }));
    };

    switchOffMethod = name => e => {
        e.preventDefault();
        this.analyzeClick(name);
        this.setState(previousState => ({
            ...previousState,
            switchOn: true,
            switchOff: false,
            ReInvest: true,
        }));
    };

    handleReInvestChange = e => {
        this.setState({ ReInvest: e.target.value });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
        });
    };

    onFundListCheckboxClick = (index, fundNo, fundAccNum) => {
        // logic to check if gate fund or fee fund if yes show warning else call onYesclick
        let showWarning = false;
        let selectedFundName = '';
        let feePercentValue = '';
        let isFeeFlag = null;
        const { fundsAvailable } = this.state;
        const feeFundIndex = fundsAvailable.findIndex(
            i => i.liquidityNoticeFlag === true && i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum,
        );
        const gateFundIndex = fundsAvailable.findIndex(
            i => i.redemptionNoticeFlag === true && i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum,
        );
        if (feeFundIndex > -1 || gateFundIndex > -1) {
            showWarning = true;
            if (feeFundIndex > -1) {
                isFeeFlag = true;
                selectedFundName = fundsAvailable[+feeFundIndex].fundName;
                feePercentValue = fundsAvailable[+feeFundIndex].imposedFee;
            } else if (gateFundIndex > -1) {
                isFeeFlag = false;
                selectedFundName = fundsAvailable[+gateFundIndex].fundName;
            }
        }
        this.setState(
            {
                showWarningDialog: showWarning,
                isFee: isFeeFlag,
                touchedFundPercentValue: feePercentValue,
                touchedFundName: selectedFundName,
                touchedIndex: index,
                touchedFundNo: fundNo,
                touchedFundAccNum: fundAccNum,
            },
            () => {
                if (!showWarning) this.onYesFeeClick();
            },
        );

    };

    onGateClick = () => {
        this.setState({ showWarningDialog: false });
    };

    onNoFeeClick = event => {
        event.preventDefault();
        this.setState(prevState => {
            const { selectedFund, fundsAvailable, touchedFundNo, touchedFundAccNum } = prevState;
            const fundAvailableIndex = fundsAvailable.findIndex(
                i => i.fundNumber === touchedFundNo && i.fundAccountNumber === touchedFundAccNum,
            );
            if (fundAvailableIndex > -1) {
                fundsAvailable[+fundAvailableIndex].selected = false;
                fundsAvailable[+fundAvailableIndex].checked = false;
            }
            if (selectedFund) {
                const { fundNumber, fundAccountNumber } = selectedFund || {};
                if (fundNumber === touchedFundNo && fundAccountNumber === touchedFundAccNum) {
                    Object.keys(selectedFund).forEach(key => {
                        delete selectedFund[+key];
                    });
                }
            }
            return { showWarningDialog: false, selectedFund };
        });
    };

    onYesFeeClick = event => {
        this.setState(prevState => {
            const {
                fundsAvailable,
                touchedIndex: index,
                touchedFundNo: fundNo,
                touchedFundAccNum: fundAccNum,
            } = prevState;
            const list = [...fundsAvailable];
            let selectedFundData = null;
            const selectedFund = list.map((fund, fundIndex) => {
                const fundObj = { ...fund };
                if (fundObj.fundNumber === fundNo && index === fundIndex && fundObj.fundAccountNumber === fundAccNum) {
                    fundObj.checked = true;
                } else {
                    fundObj.checked = false;
                }
                return fundObj;
            });
            const sfIndex = selectedFund.findIndex(i => i.fundNumber === fundNo && i.fundAccountNumber === fundAccNum);
            selectedFundData = selectedFund[parseInt(sfIndex, 10)];

            return {
                showWarningDialog: false,
                fundsAvailable: selectedFund,
                selectedFund: selectedFundData,
                ReInvestSwitchOn: !selectedFundData.fundAccountNumber,
            };
        });
    };

    handleErrorModalClick = val => {
        if (val === 'close' || val === 'hide') {
            this.setState({ showErrorModal: false, loading: false });
        }
    };

    formatInputValues = val => {
        const tbd = val ? val.replace(/[a-zA-Z,$]/g, '') : 0;
        return tbd || 0.0;
    };

    formatChangeInNav = val => {
        return val && parseFloat(val).toFixed(2);
    };

    handleLoadMore = () => {
        const { setNextPageNumber } = this.props;
        setNextPageNumber({ page: 'exchangeIn' });
    };

    handleFundSummary = fundNumber => {
        const { getFundDetails } = this.props;
        if (fundNumber && fundNumber !== '') {
            const payload = {
                fundNumber,
            };
            getFundDetails(payload);
            this.setState({ fundSummaryClicked: true });
        }
    };

    updateState = prevProps => {
        const { accOpeningData, onSwitchComponent } = this.props;
        //   Fund List  Values end
        // Fund Details API Mapping Start
        if (
            accOpeningData[ActionTypes.GET_FUNDDETAILS] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_FUNDDETAILS] !== prevProps.accOpeningData[ActionTypes.GET_FUNDDETAILS]
        ) {
            const tempData = accOpeningData[ActionTypes.GET_FUNDDETAILS];
            // Error handling
            if (tempData.errorCode && tempData.result) {
                const errorDescription = tempData.result;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: errorDescription,
                    },
                    isErrorBar: true,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else if (!tempData.errorCode) {
                this.setState(
                    {
                        selectedFundForSummary: tempData,
                    },
                    () => {
                        const { fundSummaryClicked, selectedFundForSummary } = this.state;

                        if (Object.values(selectedFundForSummary).length > 0 && fundSummaryClicked) {
                            onSwitchComponent(true, this.state, 21)();
                        }
                    },
                );
            }
        }
    };

    bindComponent() {
        const { fundsAvailable, filterButtonsReq, showErrorModal, modalAlertMessage, isLoading } = this.state;
        const { stateData } = { ...this.state };
        const { exchangeData, transactionType } = this.props;
        const currentPageNumber = exchangeData.currentPageNumber_exchangeIn;
        const showAllFunds = exchangeData.showAllFunds_exchangeIn;
        return (
            <>
                <Accordian
                    ref={this.invSelectionRef}
                    titleChild={
                        <AccordianTitle
                            id={`title_${TransactionConstants.SELECT_MUTUAL_FUND_TEXT.replace(/ /g, '_')}`}
                            accType={TransactionConstants.SELECT_MUTUAL_FUND_TEXT}
                            className={`titleHeading_${TransactionConstants.SELECT_MUTUAL_FUND_TEXT.replace(
                                / /g,
                                '_',
                            )}`}
                        />
                    }
                    titleSeperator
                    btnProps={stateData}
                    filterBtnReq={filterButtonsReq}
                    transactionSubTitle={TransactionConstants.SELECT_FUND_FROM_LIST_BELOW}
                >
                    <div
                        id={TransactionConstants.SELECT_MUTUAL_FUND_TEXT.replace(/ /g, '_')}
                        aria-controls={TransactionConstants.SELECT_MUTUAL_FUND_TEXT}
                    >
                        <WErrorModal
                            show={showErrorModal}
                            message={modalAlertMessage}
                            modalClick={this.handleErrorModalClick}
                        />
                        {!isLoading && fundsAvailable.length === 0 && (
                            <>
                                <EmptyAccountsP>{TransactionConstants.SELECT_OTHER_ACCOUNTS_MESSAGE}</EmptyAccountsP>
                            </>
                        )}

                        {fundsAvailable ? (
                            <>
                                {' '}
                                {fundsAvailable.map(
                                    (item, acctypeindex) =>
                                        acctypeindex <
                                        (showAllFunds
                                            ? fundsAvailable.length
                                            : currentPageNumber *
                                            TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE) && (
                                            <ErrorBoundary key={item.fundNumber + item.fundName}>
                                                <TransactionInvestAccountCardComponent
                                                    key={item.fundNumber}
                                                    id={item.fundNumber + item.fundName?.replace(/ /g, '_')}
                                                    transactionType={transactionType}
                                                    minimumTxt={TransactionConstants.MINIMUM_TEXT}
                                                    fundAutoInvstTxt={TransactionConstants.FUND_MINMAX_TEXT}
                                                    fundNavTxt={TransactionConstants.FUND_NAV_TEXT}
                                                    navChangeTxt={TransactionConstants.NAV_CHANGE_TEXT}
                                                    fundLastNavTxt={TransactionConstants.FUND_LAST_NAV_TEXT}
                                                    fundMinMaxTxt={TransactionConstants.FUND_WEEK_MIN_MAX_TEXT}
                                                    minMaxTxtExchange={TransactionConstants.WEEK_MIN_MAX_TEXT_EXCHANGE}
                                                    fundRiskTxt={TransactionConstants.FUND_RISK}
                                                    accountName={item.fundName}
                                                    fundNumber={item.fundNumber}
                                                    minInvst={this.formatInputValues(item.initialInvestment)}
                                                    maxInvst={this.formatInputValues(
                                                        item.minimumInitialMonthlyInvestment,
                                                    )}
                                                    monthlyInvst={this.formatInputValues(
                                                        item.minimumAutomaticInvestment,
                                                    )}
                                                    nav={this.formatChangeInNav(item.changeInNav)}
                                                    changeInNavPercentage={
                                                        item.changeInNavPercentage
                                                            ? this.formatChangeInNav(item.changeInNavPercentage)
                                                            : '0.00'
                                                    }
                                                    lastNav={this.formatInputValues(item.nav)}
                                                    weekValuesMin={this.formatInputValues(item.min52W)}
                                                    weekValuesMax={this.formatInputValues(item.max52W)}
                                                    riskVal={item.risk}
                                                    switchId={item.fundNumber + acctypeindex}
                                                    onClick={Object.assign(() =>
                                                        this.onFundListCheckboxClick(
                                                            acctypeindex,
                                                            item.fundNumber,
                                                            item.fundAccountNumber,
                                                        ),
                                                    )}
                                                    checked={!!item.checked}
                                                    selected={!!item.checked}
                                                    headerClick
                                                    data-test="invest-account-card"
                                                    existingFund={
                                                        item.fundAccountNumber ? TransactionConstants.EXISTING_FUND : ''
                                                    }
                                                    isRewardShares={item.fundNumber === '33' && !item.fundAccountNumber}
                                                    handleFundSummary={this.handleFundSummary}
                                                />
                                            </ErrorBoundary>
                                        ),
                                )}
                                {Math.ceil(fundsAvailable.length / TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE) >
                                    currentPageNumber &&
                                    !showAllFunds && (
                                        <>
                                            <LoadMoreDiv>
                                                <WButton
                                                    variant="loadMore"
                                                    buttontext={TransactionConstants.TRANSACTION_LOAD_MORE}
                                                    // role="link"
                                                    id="transLoadMoreButton"
                                                    buttonstyle={assignObj(cancelButtonStyles)}
                                                    onClick={this.handleLoadMore}
                                                />
                                            </LoadMoreDiv>
                                        </>
                                    )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </Accordian>
            </>
        );
    }

    render() {
       const {
            masterLookupData,
            isLoading,
            accountNumber,
            accountName,
            ReInvestSwitchOn,
            switchOn,
            switchOff,
            ReInvest,
            isMoneyMarketFund,
            showWarningDialog,
            isFee,
            touchedFundName,
            touchedFundPercentValue,
        } = this.state;
        const {
            onSwitchComponent,
            handleError,
            fundAccountData,
            navigationObj,
            setCancelExchange,
            exchangeData,
            isIRA,
        } = this.props;
        const accountType =
            exchangeData.page0 &&
            exchangeData.page0.displayDetails &&
            exchangeData.page0.displayDetails.accountSelection &&
            exchangeData.page0.displayDetails.accountSelection.data[0] &&
            exchangeData.page0.displayDetails.accountSelection.data[0].value;
        if (masterLookupData.isError) {
            handleError(true);
        }
        if (exchangeData.page2 != undefined && exchangeData.page2 != null && this.state.fundsAvailable.length > 0 && this.state.fundCheckStatus == false) {
            for (let i = 0; i < this.state.fundsAvailable.length; i++) {
                if (this.state.fundsAvailable[i].fundNumber == exchangeData.page2.selectedFund.fundNumber) {
                    this.state.fundsAvailable[i]['checked'] = true;
                    this.state.selectedFund = exchangeData.page2.selectedFund;
                    this.state.fundCheckStatus = true;
                    if (this.state.fundsAvailable[i].fundAccountNumber != undefined) {
                        this.state.disableNext = 26;
                    }
                    else {
                        this.state.disableNext = 20;
                    }
                }
            }
        }

        navigationObj.onPrimaryClick = onSwitchComponent(true, this.state, 2);
        return (
            <div id="#currentPageName" pagename="Investment Selection" data-test="investmentSel-component">
                {isLoading && <WSpinner loading={isLoading} />}
                <div className="container">
                    <div style={AccountCard} data-test="account-info-card">
                        <AccountInfoCard accountName={accountName} accountNumber={accountNumber} />
                    </div>
                    {fundAccountData.isError ? <ErrorMessageBar /> : this.bindComponent()}
                    {showWarningDialog && (
                        <FeeGatesWarningModal
                            isFee={isFee}
                            isLiquidate={false}
                            show={showWarningDialog}
                            fundName={touchedFundName}
                            feePercentValue={touchedFundPercentValue}
                            onPrimaryClick={this.onYesFeeClick}
                            onSecondaryClick={this.onNoFeeClick}
                        />
                    )}
                    {ReInvestSwitchOn ? (
                        <ErrorBoundary>
                            <ReInvestComponent
                                forwardRef={this.investToRef}
                                ReInvestSwitchOn={ReInvestSwitchOn}
                                switchOnMethod={this.switchOnMethod}
                                switchOffMethod={this.switchOffMethod}
                                switchOn={switchOn}
                                switchOff={switchOff}
                                ReInvest={ReInvest}
                                id="reinvest"
                                accIndex={1}
                                labelledBy="reinvestDividends"
                            />
                        </ErrorBoundary>
                    ) : (
                        ''
                    )}
                    {!isIRA() &&
                        accountType.trim() !== TransactionConstants.ARCHER_MSA_ACCOUNT_TYPE &&
                        !isMoneyMarketFund ? (
                        <TaxAccounting costBasis={exchangeData.page1 && exchangeData.page1.selectedFund.costBasis} />
                    ) : (
                        ''
                    )}
                </div>
                <BottomNavSection
                    {...navigationObj}
                    arialabelback="Back to Investment Selection"
                    iscancel
                    showBack
                    disabled={!this.state.disableNext}
                    cancelClickHandler={setCancelExchange}
                />
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        fundAccountData: state.fundAccountData,
        exchangeData: state.exchangeData,
        accOpeningData: state.accOpeningReducerData,
    };
};

InvestmentSelection.propTypes = {
    onSwitchComponent: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    fundAccountData: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    masterLookupStateData: PropTypes.instanceOf(Object),
    exchangeData: PropTypes.instanceOf(Object),
    transactionType: PropTypes.string,
    getCompositeData: PropTypes.func,
    handleError: PropTypes.func,
    getFunds: PropTypes.func,
    handleIRAContribution: PropTypes.func,
    setTradingWarning: PropTypes.func,
    setNextPageNumber: PropTypes.func,
    setCancelExchange: PropTypes.func,
    getFundDetails: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    isIRA: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
};

InvestmentSelection.defaultProps = {
    savedState: null,
    pageIndex: 1,
    editSection: null,
    navigationObj: {
        onCancel: () => { },
        onPrimaryClick: () => { },
        onSecondaryClick: () => { },
        portalRoot: null,
    },
    masterLookupStateData: {},
    fundAccountData: {},
    exchangeData: {},
    transactionType: '',
    handleError: () => { },
    onSwitchComponent: () => { },
    getCompositeData: () => { },
    getFunds: () => { },
    handleIRAContribution: () => { },
    setTradingWarning: () => { },
    setNextPageNumber: () => { },
    setCancelExchange: () => { },
    getFundDetails: () => { },
    accOpeningData: {},
    isIRA: () => { },
    analyzeClickEvent: () => { },
};

export default connect(mapStateToProps)(InvestmentSelection);
