export default {
    pageHeading: 'Individual Account',
    pages: ['Account Type', 'Personal Info', 'Investment Selection', 'Preferences', 'Verify', 'E-Consent'],
    jointOwnertext: 'Joint Owner',
    jointOnwerPerInfo: 'Personal Information - Joint Owner',
    perInfoHeading: 'Personal Information - Primary',
    nickNameLabel: 'Nick Name',
    prefix: 'Prefix',
    suffix: 'Suffix',
    dob: 'Date of Birth',
    gender: 'Gender',
    maritalStatus: 'Marital Status',
    citizenship: 'Citizenship',
    ssn: 'Social Security Number',
    sameAs: 'Same as mailing',
    staticfield: 'staticfield',
    edit: 'Edit',
    initInvestment: 'Initial Investment',
    monthInvestment: 'Monthly Investment',
    primaryPhoneNumber: 'Primary Phone Number',
    secondaryPhoneNumber: 'Secondary Phone Number',
    primaryEmail: 'Primary Email',
    secondaryEmail: 'Secondary Email',
    phJoint: 'Phone Information - Joint Owner',
    phInfo: 'Phone Information',
    primaryMobile: 'Primary Mobile',
    emJoint: 'Email Information -Joint Owner',
    emInfo: 'Email Information',
    emAddress: 'Email Address',
    empJoint: 'Employment Information - Joint Owner',
    empInfoH: 'Employment Information - Primary',
    empStatus: 'Employment Status',
    primarySourceOfIncome: 'Primary Source of Income',
    industry: 'Industry',
    empClassInfo: 'Employee Class Information',
    occ: 'Occupation',
    empName: "Employer's Name",
    empAddress: "Employer's Address",
    finJoint: 'Financial Information - Joint Owner',
    finInfoH: 'Financial Information',
    annualInc: 'Annual Income (USD)',
    taxB: 'Tax Bracket',
    netW: 'Net Worth (USD)',
    taxfile: 'Tax Filing Status',
    milInfoJoint: 'Military Information- Joint Owner',
    milInfoH: 'Military Information',
    milStatus: 'Military Status',
    bos: 'Branch of Service',
    mailingAddressLabel: 'Mailing Address',
    physicalAddressLabel: 'Physical Address',
    rank: 'Rank',
    dos: 'Dates of Service',
    commsrc: 'Commission Source',
    primary: 'Primary',
    contingent: 'Contingent',
    benType: 'Beneficiary Type',
    relation: 'Relationship To Account Holder',
    relationToCustodian: 'Relationship To Custodian',
    distributionPercentage: 'Distribution Percentage',
    fname: 'First Name',
    name: 'Name',
    mname: 'Middle Name',
    lname: 'Last Name',
    snn: 'SSN',
    contributionYearLabel: 'Contribution',
    contributionYearKey: 'Contribution for IRA Account',
    redirect: { pathname: '/esign', state: { referrer: 'currentLocation' } },
    contactInfoCard: {
        cardId: 'contactInfo',
        linkText: 'Edit',
        cardHeading: 'Contact Information',
        fields: [
            {
                uniqueKey: 'contactInfoAddress',
                key: 'contactInfoAddress',
                mailingAddressLabel: 'Mailing Address',
                physicalAddressLabel: 'Physical Address',
                uspsAdresslabel: 'USPS Suggested Address',
                hintText1: 'The address you entered may be incorrect or incomplete',
                hintText2: "Please review our suggestion below, and chose which version you'd like to use.",
                mailingAddress: '',
                physicalAddress: [],
                type: 'contactInfo',
            },
        ],
    },
    accountInfoCard: {
        cardId: 'accountInfo',
        linkText: 'Edit',
        cardHeading: 'VCM Investment Account Information',
        fields: [
            {
                uniqueKey: 'registartionType',
                key: 'Registration Type',
                value: '',
                type: 'staticfield',
            },
        ],
    },
    selectFundsCard: {
        cardId: 'selectFunds',
        linkText: 'Edit',
        cardHeading: 'Selected Mutual Funds',
        fields: [],
    },
    fundingInfoCard: {
        cardId: 'fundingInfo',
        linkText: 'Edit',
        cardHeading: 'Funding Information',
        fields: [
            {
                uniqueKey: 'fundingSourceMethod',
                key: 'Funding Source Method',
                value: '',
                type: 'staticfield',
            },
            {
                uniqueKey: 'fundingSource',
                key: 'Funding Source',
                value: '',
                type: 'staticfield',
            },
            {
                uniqueKey: 'totalInitialInvestment',
                key: 'Total Initial Investment',
                value: '',
                type: 'staticfield',
            },
        ],
    },
    accountPreferencesCard: {
        cardId: 'accountPreferences',
        linkText: 'Edit',
        cardHeading: 'General Account Preferences',
        fields: [
            {
                uniqueKey: 'dividendPreference',
                key: 'Dividend Preference',
                value: '',
                type: 'staticfield',
            },
            {
                uniqueKey: 'documentDeliveryFormat',
                key: 'Desired format for delivery of documents',
                value: '',
                type: 'staticfield',
            },
        ],
    },
    None: '-',
    downloadAriaLabel: 'Download the ',
    taxPayerTooltipHintText:
        'Backup withholding can occur for several reasons; for example, you did not give the financial institution (or other payer) your Social Security or other Taxpayer Identification Number (TIN) in the required manner; the IRS Internal Revenue Service notifies the payer that the TIN you gave is incorrect; you failed to certify that you are not subject to backup withholding; or the IRS Internal Revenue Service notifies the payer that you have underreported interest or dividends on your income tax return. In these cases, the IRS Internal Revenue Service requires USAA to withhold 24% (current rate) of most kinds of reportable payments including your interest payments, dividends and sales proceeds. (See IRS Internal Revenue Service Publication 505, Tax Withholding and Estimated Tax, for more details.)',
    hiddenStyle: { display: 'none' },
    taxWHConsentError: 'Please select an option above to continue',
    TRADIONAL_IRA_TEXT: 'Traditional IRA',
    ROTH_IRA_TEXT: 'Roth IRA',
    SEP_IRA_TEXT: 'SEP IRA',
};
