const gender = {
    key: 'gender',
    value: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
    ],
};

const states = {
    key: 'states',
    value: [
        {
            value: 'Alabama',
            key: 'AL',
        },
        {
            value: 'Alaska',
            key: 'AK',
        },
        {
            value: 'American Samoa',
            key: 'AS',
        },
        {
            value: 'Arizona',
            key: 'AZ',
        },
        {
            value: 'Arkansas',
            key: 'AR',
        },
        {
            value: 'California',
            key: 'CA',
        },
        {
            value: 'Colorado',
            key: 'CO',
        },
        {
            value: 'Connecticut',
            key: 'CT',
        },
        {
            value: 'Delaware',
            key: 'DE',
        },
        {
            value: 'District Of Columbia',
            key: 'DC',
        },
        {
            value: 'Federated States Of Micronesia',
            key: 'FM',
        },
        {
            value: 'Florida',
            key: 'FL',
        },
        {
            value: 'Georgia',
            key: 'GA',
        },
        {
            value: 'Guam',
            key: 'GU',
        },
        {
            value: 'Hawaii',
            key: 'HI',
        },
        {
            value: 'Idaho',
            key: 'ID',
        },
        {
            value: 'Illinois',
            key: 'IL',
        },
        {
            value: 'Indiana',
            key: 'IN',
        },
        {
            value: 'Iowa',
            key: 'IA',
        },
        {
            value: 'Kansas',
            key: 'KS',
        },
        {
            value: 'Kentucky',
            key: 'KY',
        },
        {
            value: 'Louisiana',
            key: 'LA',
        },
        {
            value: 'Maine',
            key: 'ME',
        },
        {
            value: 'Marshall Islands',
            key: 'MH',
        },
        {
            value: 'Maryland',
            key: 'MD',
        },
        {
            value: 'Massachusetts',
            key: 'MA',
        },
        {
            value: 'Michigan',
            key: 'MI',
        },
        {
            value: 'Minnesota',
            key: 'MN',
        },
        {
            value: 'Mississippi',
            key: 'MS',
        },
        {
            value: 'Missouri',
            key: 'MO',
        },
        {
            value: 'Montana',
            key: 'MT',
        },
        {
            value: 'Nebraska',
            key: 'NE',
        },
        {
            value: 'Nevada',
            key: 'NV',
        },
        {
            value: 'New Hampshire',
            key: 'NH',
        },
        {
            value: 'New Jersey',
            key: 'NJ',
        },
        {
            value: 'New Mexico',
            key: 'NM',
        },
        {
            value: 'New York',
            key: 'NY',
        },
        {
            value: 'North Carolina',
            key: 'NC',
        },
        {
            value: 'North Dakota',
            key: 'ND',
        },
        {
            value: 'Northern Mariana Islands',
            key: 'MP',
        },
        {
            value: 'Ohio',
            key: 'OH',
        },
        {
            value: 'Oklahoma',
            key: 'OK',
        },
        {
            value: 'Oregon',
            key: 'OR',
        },
        {
            value: 'Palau',
            key: 'PW',
        },
        {
            value: 'Pennsylvania',
            key: 'PA',
        },
        {
            value: 'Puerto Rico',
            key: 'PR',
        },
        {
            value: 'Rhode Island',
            key: 'RI',
        },
        {
            value: 'South Carolina',
            key: 'SC',
        },
        {
            value: 'South Dakota',
            key: 'SD',
        },
        {
            value: 'Tennessee',
            key: 'TN',
        },
        {
            value: 'Texas',
            key: 'TX',
        },
        {
            value: 'Utah',
            key: 'UT',
        },
        {
            value: 'Vermont',
            key: 'VT',
        },
        {
            value: 'Virgin Islands',
            key: 'VI',
        },
        {
            value: 'Virginia',
            key: 'VA',
        },
        {
            value: 'Washington',
            key: 'WA',
        },
        {
            value: 'West Virginia',
            key: 'WV',
        },
        {
            value: 'Wisconsin',
            key: 'WI',
        },
        {
            value: 'Wyoming',
            key: 'WY',
        },
    ],
};

const sameAddress = {
    key: 'sameAddress',
    value: [
        { key: 'Y', value: 'Yes' },
        { key: 'N', value: 'No' },
    ],
};

const citizenship = {
    key: 'citizenship',
    value: [
        { value: 'U.S', key: 'us' },
        { value: 'Non U.S', key: 'nonus' },
    ],
};

const empStatus = {
    key: 'emp_status',
    value: [
        { key: 's1', value: 'Status1' },
        { key: 's2', value: 'Status2' },
        { key: 's3', value: 'Status3' },
    ],
};
const industry = {
    key: 'contact_time',
    value: [
        { key: 'i1', value: 'ind1' },
        { key: 'i2', value: 'ind2' },
        { key: 'i3', value: 'ind3' },
    ],
};
const annualIncome = {
    key: 'annualincome',
    value: [
        { key: '0', value: '9700 and below', taxbracket: '10' },
        { key: '9701', value: '9701-39475', taxbracket: '12' },
        { key: '39476', value: '39476-84200', taxbracket: '22' },
        { key: '84201', value: '84201-160725', taxbracket: '24' },
        { key: '160726', value: '160726-204100', taxbracket: '32' },
        { key: '204101', value: '204101-510300', taxbracket: '35' },
        { key: '510301', value: '510301 and above', taxbracket: '37' },
    ],
};

const netWorth = {
    key: 'netWorth',
    value: [
        { key: '0', value: '34999 and below ' },
        { key: '35000', value: '35000-49999' },
        { key: '50000', value: '50000-249999' },
        { key: '250000', value: '250000 and above' },
    ],
};
const taxFilling = {
    key: 'tax_filling',
    value: [
        { key: 's', value: 'Single individual ' },
        { key: 'm-j', value: 'Married person filing jointly or surviving spouse' },
        { key: 'm-s', value: 'Married person filing separately ' },
        { key: 'hh', value: 'Head of household' },
        { key: 'qw', value: 'Qualifying widow(er) with dependent children ' },
    ],
};

const militaryServiceBr = {
    key: 'mil_serv_branch',
    value: [
        { key: 'army', value: 'Army' },
        { key: 'navy', value: 'Navy' },
        { key: 'marines', value: 'Marine Cops' },
        { key: 'airforce', value: 'Air Force' },
        { key: 'coast', value: 'Coast Guards' },
    ],
};
const militaryRank = {
    key: 'army_rank',
    value: [
        { key: 'O-1', value: 'O1 - Second Lieutenant' },
        { key: 'O-2', value: 'O2 - First Lieutenant' },
        { key: 'O-3', value: 'O3 - Captain' },
        { key: 'O-4', value: 'O4 - Major' },
        { key: 'O-5', value: 'O5 - Lieutenant Colonel' },
        { key: 'O-6', value: 'O6 - Colonel' },
        { key: 'O-7', value: 'O7 - Brigadier General' },
        { key: 'O-8', value: 'O8 - Major General' },
        { key: 'O-9', value: 'O9 - Lieutenant General' },
        { key: 'O-10', value: 'O10 - General' },
        { key: 'E-1', value: 'E1 - Private' },
        { key: 'E-2', value: 'E2 - Private' },
        { key: 'E-3', value: 'E3 - Private First Class' },
        { key: 'E-4', value: 'E4 - Corporal' },
        { key: 'E-5', value: 'E5 - Sergeant' },
        { key: 'E-6', value: 'E6 - Staff Sergeant' },
        { key: 'E-7', value: 'E7 - Sergeant First Class' },
        { key: 'E-8', value: 'E8 - Master Sergeant' },
        { key: 'E-9', value: 'E9 - Sergeant Major' },
        { key: 'E-10', value: 'E9 - Command Sergeant Major' },
        { key: 'W-1', value: 'W1 - Warrant Officer 1' },
        { key: 'W-2', value: 'W2 - Chief Warrant Officer 2' },
        { key: 'W-3', value: 'W3 - Chief Warrant Officer 3' },
        { key: 'W-4', value: 'W4 - Chief Warrant Officer 4' },
        { key: 'W-5', value: 'W5 - Chief Warrant Officer 5' },
    ],
};
const militaryStatus = {
    key: 'military_status',
    value: [
        { key: 'adn', value: 'Active Duty Military' },
        { key: 'rad', value: 'Retired from Active Duty ' },
        { key: 'sm', value: 'Separated Military ' },
        { key: 'r-ft', value: 'Reserve - Full-time' },
        { key: 'r-pt', value: 'Reserve - Part-time' },
        { key: 'r-r', value: 'Reserve - Retired' },
        { key: 'r-i', value: 'Reserve - Inactive' },
        { key: 'ng-ft', value: 'National Guard - Full-time' },
        { key: 'ng-pt', value: 'National Guard - Part-time' },
        { key: 'ng-r', value: 'National Guard - Retired' },
        { key: 'ng-i', value: 'National Guard - Inactive' },
        { key: 'pp', value: ' Precommissioning Program' },
        { key: 'o', value: 'Other' },
    ],
};

const emailSelect = [
    { key: 'makePrimary', value: 'Make Primary' },
    { key: 'delete', value: 'Delete' },
];

const countryCode = [
    {
        country: 'Afghanistan',
        code: '93',
    },
    {
        country: 'Albania',
        code: '355',
    },
    {
        country: 'Algeria',
        code: '213',
    },
    {
        country: 'American Samoa',
        code: '1-684',
    },
    {
        country: 'Andorra',
        code: '376',
    },
    {
        country: 'Angola',
        code: '244',
    },
    {
        country: 'Anguilla',
        code: '1-264',
    },
    {
        country: 'Bahrain',
        code: '973',
    },
    {
        country: 'Bangladesh',
        code: '880',
    },
    {
        country: 'Barbados',
        code: '1-246',
    },
    {
        country: 'Belgium',
        code: '32',
    },
    {
        country: 'Chile',
        code: '56',
    },
    {
        country: 'China',
        code: '86',
    },
    {
        country: 'Denmark',
        code: '45',
    },
    {
        country: 'Egypt',
        code: '20',
    },
    {
        country: 'Iceland',
        code: '354',
    },
    {
        country: 'India',
        code: '91',
    },
    {
        country: 'Kuwait',
        code: '965',
    },
    {
        country: 'Mexico',
        code: '52',
    },
    {
        country: 'Nepal',
        code: '977',
    },
    {
        country: 'New Zealand',
        code: '64',
    },
    {
        country: 'Norway',
        code: '47',
    },
    {
        country: 'Russia',
        code: '7',
    },
    {
        country: 'Singapore',
        code: '65',
    },
    {
        country: 'Swaziland',
        code: '268',
    },
    {
        country: 'Sri Lanka',
        code: '94',
    },
    {
        country: 'United Arab Emirates',
        code: '971',
    },
    {
        country: 'United Kingdom',
        code: '44',
    },
    {
        country: 'United States',
        code: '1',
    },
];

const personalInfoHeading = 'Personal Information';
const person = 'Personalize';
const upload = 'Upload Image';
const mandatoryText = '* All fields are required except mentioned optional';
const optionalText = '[ Optional ]';
const select = 'Select';
const setAsPrimLabel = 'Set as Primary';
const setAsMobileLabel = 'Is this your mobile number?';
const primaryEmailHeading = 'Primary Email';
const secondaryEmailHeading = 'Secondary Email';
const ACCT_TYPE_INDIVIDUAL = 'Individual';
const ACCT_TYPE_JOINT_TNT_CMN = 'Joint Tenants in Common';
const ACCT_TYPE_JOINT_TNT_ROS = 'Joint Tenants With Right of Survivorship';
const ACCT_TYPE_JOINT_TNT_ETY = 'Joint Tenants in Entirety';
const ACCT_TYPE_TRADITIONAL_IRA = 'Traditional IRA';
const ACCT_TYPE_ROTH_IRA = 'Roth IRA';
const ACCT_TYPE_ROLLOVER_IRA = 'Rollover IRA';
const ACCT_TYPE_SEP_IRA = 'SEP IRA';
const ACCT_TYPE_SIMPLE_IRA = 'Simple IRA';
const ACCT_TYPE_529 = '529 Education Savings Plan';
const ACCT_TYPE_403B = '403b';
const ACCT_TYPE_UGMA_UTMA_TEXT = 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)';
const ACCT_TYPE_UGMA_UTMA = 'UGMA/UTMA';
const RADIO_BUTTON_TYPE = 'radio';
const OTHER_PLACEHOLDER_TEXT = 'If other, please specify';
const DOBErrorMsg = 'You must be 18 years or older to open an account';
export const HelpText_Employment = "Why do we ask for this information?\nWe ask for your employment and financial information for record-keeping and to aid in determining suitable investments for you."
export const HelpeText_Military = "Why do we ask for this information?\nWe ask for your military information to better address and serve you."
const JTIE = 'Joint Tenants in Entirety';
const jointPersonalPhoneLabel='Is this a mobile number?'
export default {
    select,
    gender,
    emailSelect,
    states,
    sameAddress,
    empStatus,
    industry,
    annualIncome,
    netWorth,
    taxFilling,
    militaryServiceBr,
    militaryRank,
    militaryStatus,
    personalInfoHeading,
    person,
    upload,
    mandatoryText,
    optionalText,
    countryCode,
    setAsPrimLabel,
    setAsMobileLabel,
    primaryEmailHeading,
    secondaryEmailHeading,
    addPhoneNumberText: 'Add Another Number',
    citizenship,
    ACCT_TYPE_403B,
    ACCT_TYPE_529,
    ACCT_TYPE_INDIVIDUAL,
    ACCT_TYPE_JOINT_TNT_CMN,
    ACCT_TYPE_JOINT_TNT_ETY,
    ACCT_TYPE_JOINT_TNT_ROS,
    ACCT_TYPE_ROLLOVER_IRA,
    ACCT_TYPE_ROTH_IRA,
    ACCT_TYPE_SEP_IRA,
    ACCT_TYPE_SIMPLE_IRA,
    ACCT_TYPE_TRADITIONAL_IRA,
    ACCT_TYPE_UGMA_UTMA,
    ACCT_TYPE_UGMA_UTMA_TEXT,
    RADIO_BUTTON_TYPE,
    OTHER_PLACEHOLDER_TEXT,
    DOBErrorMsg,
    JTIE,
    jointPersonalPhoneLabel
};
