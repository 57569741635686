import AWS from 'aws-sdk';
import { toBase64 } from '@aws-sdk/util-base64-browser';
import { Auth } from 'aws-amplify';
import envConfig from 'env/EnvConfig';

const awsKey = envConfig.AWS_KMS_KEY;

const awsconfig = {
    "region": envConfig.AWS_PROJECT_REGION,
    "cmkArn": awsKey
};


function getKMSClient(info) {
  const credentials = new AWS.Credentials(
    info.accessKeyId,
    info.secretAccessKey,
    info.sessionToken,
  );
  AWS.config.update({
    region: awsconfig.region,
    credentials,
  });
  return new AWS.KMS();
}

async function encrypt(buffer1) {
  try
  {
     const kmsCredentials = await Auth.currentCredentials() 
    const kms = getKMSClient(kmsCredentials); 

    if(buffer1) {
      const params = {
        'KeyId':  awsKey,
        'EncryptionAlgorithm': 'SYMMETRIC_DEFAULT',
        'Plaintext': buffer1
      };

    const promise = new Promise(function(resolve, reject){
      kms.encrypt(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
          resolve(toBase64(data.CiphertextBlob));
          
        }
      });
    });
    return await promise;
  } else {
    return null;
  }
  }
  catch(error)
  {
    console.log("Encryption failed. Error: ",error)
  }
}
export default encrypt;