import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import MessageCenterButtonsComponent from './MessageCenterButtonsComponent';
import WInputCheckBox from '../../../common/WInputCheckBox/WInputCheckBox';
import * as MCConstants from './constants';
import {
    setSelectAll,
    updateAllSelectedMessages,
    deleteAllSelectedMessages,
    setOrderByOrderType,
} from '../../../shared/Actions/MessageCenterActions';

const MessagesActionContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const MessagesActionButtonsContainerDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 57%;
    @media only screen and (max-width: 992px) {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top: 10px;
    }
`;

const GroupingDiv = styled.div`
    padding: 0px;
    margin: 0px;
    .checkBoxWrapper input[type='checkbox']:checked:after {
        width: 7px;
        height: 14px;
        top: 3px;
        left: 8px;
    }
    width: 150px;
    @media only screen and (max-width: 992px) {
        margin-top: 20px;
    }
`;

const MessagesSortButtonsContainerDiv = styled.div`
    color: rgb(0, 74, 152);
    display: inline-block;
    font-family: benton-sans, sans-serif;
    font-size: 14px;
    color: #56565a;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const labelStyle = {
    color: 'rgb(0,74,152)',
    display: 'inline-block',
    font: '700 16px/10px Segoe UI',
};

const checkInputStyle = {
    width: '25px',
    height: '25px',
    marginRight: '10px',
};

const checkBoxWrapperStyle = {
    marginRight: '0px',
};

const ActionOption = styled.option`
    font: 400 14px benton-sans;
    border-right: 1px solid #d2d2d2;
`;

const ActionDropdownDiv = styled.div`
    position: relative;
    margin: 10px 0px 10px 20px;
`;

const SortLabelDiv = styled.div`
    margin-right: 5px;
    font-family: benton-sans, sans-serif;
    font-size: 14px;
    font-weight: 700;
`;

const selectStyle = {
    width: 150,
    height: 38,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #D2D2D2',
    fontFamily: 'benton-sans',
    fontSize: '14px',
    fontWeight: '600',
    paddingLeft: '15px',
};

const selectStyle576 = {
    width: 80,
    height: 38,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #D2D2D2',
    fontFamily: 'benton-sans',
    fontSize: '14px',
    fontWeight: '600',
    paddingLeft: '5px',
};

const selectArrow = {
    position: 'absolute',
    right: '12px',
    top: '12px',
    width: '10px',
    height: '10px',
    border: 'solid rgba(72, 109, 144, 1)',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

function MessageCenterMessagesComponent() {
    let enableDelete = false;
    let enableRead = false;
    let enableUnread = false;

    const selectedMessages = useSelector(state => state.messageCenterData.selectedMessages);
    const messages = useSelector(state => state.messageCenterData.messages);
    const messageType = useSelector(state => state.messageCenterData.messageType);
    const selectAll = useSelector(state => state.messageCenterData.selectAll);
    const orderType = useSelector(state => state.messageCenterData.orderType);
    const orderByFromState = useSelector(state => state.messageCenterData.orderBy);

    const [orderBy, setOrderBy] = useState(orderByFromState);
    const dispatch = useDispatch();

    const alignSortForDelete =
        messageType === MCConstants.MESSAGE_CENTER_DELETED_VAL
            ? {
                  display: 'flex',
                  justifyContent: 'flex-end',
              }
            : {};

    if (selectedMessages.length > 0) {
        enableDelete = true;
        enableUnread = !!selectedMessages.filter(item => item.isRead).length;
        enableRead = !!selectedMessages.filter(item => !item.isRead).length;
    }

    function onSelectAll() {
        dispatch(setSelectAll(!selectAll));
        if (!selectAll) {
            dispatch(updateAllSelectedMessages(messages));
        } else {
            dispatch(deleteAllSelectedMessages(messageType));
        }
    }

    function onChangeOrderType(e) {
        dispatch(setOrderByOrderType(orderBy, e.target.value));
    }

    const selectAllStrVal = `${selectAll}`;
    return (
        <>
            <MessagesActionContainerDiv data-test="messagesActionContainerDiv" style={alignSortForDelete}>
                {messageType !== MCConstants.MESSAGE_CENTER_DELETED_VAL && (
                    <MessagesActionButtonsContainerDiv data-test="messagesActionButtonsContainerDiv">
                        <GroupingDiv data-test="groupingDiv">
                            <WInputCheckBox
                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                checkInputStyle={checkInputStyle}
                                checked={selectAll}
                                value={selectAllStrVal}
                                onChange={onSelectAll}
                                checkboxstyle={MCConstants.MESSAGE_CENTER_CHECKBOXSTYLE}
                                labelstyle={labelStyle}
                                label={MCConstants.MESSAGE_CENTER_SELECT_ALL}
                            />
                        </GroupingDiv>
                        <MessageCenterButtonsComponent
                            selectAll={selectAll}
                            enableDelete={enableDelete}
                            enableRead={enableRead}
                            enableUnread={enableUnread}
                        />
                    </MessagesActionButtonsContainerDiv>
                )}
                <MessagesSortButtonsContainerDiv data-test="messagesSortButtonsContainerDiv">
                    <SortLabelDiv>{MCConstants.MESSAGE_CENTER_SORT}</SortLabelDiv>
                 
                    <ActionDropdownDiv data-test="actionDropdown">
                        <div data-test="selectArrow" style={selectArrow} />
                        <Form.Control
                            onChange={onChangeOrderType}
                            as="select"
                            data-test="orderType"
                            style={selectStyle}
                            value={orderType}
                        >
                            {MCConstants.selectOptionsSortOrderType.map(item => (
                                <ActionOption key={item.key} value={item.key}>
                                    {item.value}
                                </ActionOption>
                            ))}
                        </Form.Control>
                    </ActionDropdownDiv>
                </MessagesSortButtonsContainerDiv>
            </MessagesActionContainerDiv>
        </>
    );
}

export default MessageCenterMessagesComponent;
