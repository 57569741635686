import React from 'react';
import styled from 'styled-components';
import { Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import WIcon from '../../../common/WIcon/WIcon';
import CreateSecureMessageIcon from '../../../assets/icon_CreateSecureMessage.svg';
import { MESSAGE_CENTER_CREATE_SECURE_MESSAGE, selectOptionsKV, MESSAGE_CENTER_MESSAGE_CENTER } from './constants';
import { deleteAllSelectedMessages } from '../../../shared/Actions/MessageCenterActions';
import { analyzeClickEvent, updateAnalyticsPageDetails } from '../../../shared/Actions/AnalyticsActions';

const assignObj = obj => {
    return obj;
};

const ActionContainerDiv = styled(Row)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 58px;
    margin-left: 5px;
    @media only screen and (max-width: 992px) {
        padding-bottom: 15px;
    }
`;

const MessageCenterLabelH1 = styled.h1`
    color: rgba(72, 109, 144, 1);
    font: 800 30px/36px yorkten-slab-normal;
    border-bottom: 2px solid #8bc105;
    height: fit-content;
    padding-bottom: 10px;
`;

const SecureMessageDiv = styled.div`
    color: rgba(72, 109, 144, 1);
    font: 800 18px/22px yorkten-slab-normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const MessageWrapperDiv = styled.div`
    display: flex;
    width: 70%;
    justify-content: flex-start;
    padding-top: 12px;
    @media only screen and (max-width: 992px) {
        width: 100%;
        padding-bottom: 15px;
    }
    @media only screen and (max-width: 576px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

const ActionOption = styled.option`
    margin: 5px;
`;

const CreateSecureMsgLink = styled.div`
    color: rgb(0, 74, 152);
    cursor: pointer;
    text-decoration: none;
    &:link {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
`;

const ActionDropdownDiv = styled.div`
    position: relative;
    margin: 5px 0 0 40px;
    @media only screen and (max-width: 576px) {
        margin: 5px 0 0 0;
    }
`;

const selectStyle = {
    width: 140,
    height: 38,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    position: 'relative',
    borderRadius: 0,
    font: '600 14px benton-sans',
    paddingLeft: '10px',
    textIndent: '0.01px',
    textOverflow: '',
};

const selectArrow = {
    position: 'absolute',
    right: '12px',
    top: '12px',
    width: '10px',
    height: '10px',
    border: 'solid rgba(72, 109, 144, 1)',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

// Media query break point styling - 576px
const selectArrow576 = {
    position: 'absolute',
    left: '110px',
    top: '12px',
    width: '10px',
    height: '10px',
    border: 'solid rgba(72, 109, 144, 1)',
    borderWidth: '0 0 2px 2px',
    transform: 'rotate(-45deg)',
    zIndex: 1,
};

// Function to return the right media query break point styling
const selectArrowStyle = () => {
    if (window.innerWidth < 576) {
        return selectArrow576;
    }
    return selectArrow;
};

const createSecureMessageLinkStyle = {
    color: 'rgb(0, 74, 152)',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:link': {
        textDecoration: 'none',
    },
    '&:visited': {
        textDecoration: 'none',
    },
    '&:hover': {
        textDecoration: 'none',
    },
    '&:active': {
        textDecoration: 'none',
    },
};

function MessageCenterActionsComponent() {
    const dispatch = useDispatch();

    function onChange(e) {
        dispatch(deleteAllSelectedMessages(e.target.value));
        dispatch(
            updateAnalyticsPageDetails({
                pageInfo: {
                    PageName: `Message Center | ${e.target.value}`,
                    Section: e.target.value,
                    pageDocumenttitle: e.target.value,
                },
            }),
        );
    }

    function onCreatMessageClick(e) {
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id'),
            }),
        );
    }

    return (
        <ActionContainerDiv data-test="actionContainer">
            <MessageWrapperDiv data-test="messageWrapper">
                <MessageCenterLabelH1 data-test="messageCenterLabel">
                    {MESSAGE_CENTER_MESSAGE_CENTER}
                </MessageCenterLabelH1>

                <ActionDropdownDiv data-test="actionDropdown">
                    <div data-test="selectArrow" style={selectArrowStyle()} />
                    <Form.Control onChange={onChange} as="select" data-test="inboxType" style={selectStyle}>
                        {selectOptionsKV.map(item => (
                            <ActionOption key={item.key} value={item.key}>
                                {item.value}
                            </ActionOption>
                        ))}
                    </Form.Control>
                </ActionDropdownDiv>
            </MessageWrapperDiv>
            <SecureMessageDiv data-test="secureMessage">
                <WIcon src={CreateSecureMessageIcon} alt="plus" fontawesomestyle={assignObj({ paddingRight: 10 })} />
                <Link to="/messageCenter/createSecureMessages" style={createSecureMessageLinkStyle}>
                    <CreateSecureMsgLink
                        data-test="secureMsgLink"
                        onClick={onCreatMessageClick}
                        data-analytic-id="MessageCenter_SendSecureMessage"
                    >
                        {MESSAGE_CENTER_CREATE_SECURE_MESSAGE}
                    </CreateSecureMsgLink>
                </Link>
            </SecureMessageDiv>
        </ActionContainerDiv>
    );
}

export default MessageCenterActionsComponent;
