import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setAddressType, setIsSameAddress } from 'shared/Actions/ProfileRelationAction';
import {
    resetAddressInfo
} from "shared/Actions/AddressAction";
import { Address, RadioGroup, FieldValue, SectionTitleWithSeparator, OffScreenText } from "modules/CustomerManagementModule/components";
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { getKeyfromValue, getMetadataValues } from 'modules/CustomerManagementModule/utils';

const physicalAddressOptions = [{key:'Yes',value:'Yes'},{key:'No',value:'No'}];

const handleAddressTypeChange = (dispatch, addressType,masterLookupStateData) => (e) => {
	const { target } = e;
    const { value } = target;
    const addressTypeValue = getKeyfromValue(masterLookupStateData, 'address_type', value);
    dispatch(setAddressType(addressType, addressTypeValue));
}

const onSameAddressChange = (dispatch) => (e) => {
	const isSame = e.target.value === 'Yes';
	dispatch(setIsSameAddress(isSame));
	if(isSame) {
		dispatch(resetAddressInfo('physicalAddress'));
		dispatch(setAddressType('physicalAddress', ''));
	}
}

const AddressComponent = () => {
	const dispatch = useDispatch();
	
	const masterLookupStateData = useSelector(({ masterLookUpData: metaData }) => metaData || {});

	const addressTypeValue = getMetadataValues(masterLookupStateData, 'address_type');
	
	const isPhysicalSameAsMailingAddr = useSelector((state) => {
		const { profileRelationshipData } = state;
        const { isPhysicalSameAsMailingAddr: isPhysicalSameAsMailing } = profileRelationshipData;
		return isPhysicalSameAsMailing;
	});
    
	return (
		<>
			<SectionTitleWithSeparator titleTxt={CONST.ADDRESS_TITLE} optional />
			<div role="group" aria-labelledby="vcm-cms-relation-mailing-address-group">
				<OffScreenText id="vcm-cms-relation-mailing-address-group" hideTxt={CONST.MAILING_ADDRESS} />
				<FieldValue
					as="p"
					id="vcm-cms-mailing-address-type"
					alignItems="stretch"
					label={CONST.ADDRESS_LABEL_TEXT}
					value={
						<RadioGroup
							labelledBy="vcm-cms-mailing-address-type"
							vertical
							options={addressTypeValue}
							onChange={handleAddressTypeChange(dispatch, 'mailingAddressType',masterLookupStateData)}
							data-name={`${CONST.MAILING_ADDRESS} - ${CONST.ADDRESS_LABEL_TEXT}`}
						/>
					}
					labelsm={3}
					valuesm={5}
					mb={1.5625}
					mt={1.5625}
				/>
				<Address 
					addressType="mailingAddress" 
					groupName={CONST.MAILING_ADDRESS} 
					optional
				/>
			</div>
			<FieldValue
				as="p"
				id="vcm-cms-physical-address"
				label={CONST.PHYSICAL_ADDRESS_LABEL}
				value={
					<RadioGroup
						labelledBy="vcm-cms-physical-address"
						options={physicalAddressOptions}
						onChange={onSameAddressChange(dispatch)}
						initialValue="Yes"
						data-name={CONST.PHYSICAL_ADDRESS_LABEL}
					/>
				}
				labelsm={3}
				valuesm={5}
				mb={1.5625}
				mt={1.5625}
			/>
			{ !isPhysicalSameAsMailingAddr && (
			<div role="group" aria-labelledby="vcm-cms-relation-physical-address-group">
				<OffScreenText id="vcm-cms-relation-physical-address-group" hideTxt={CONST.PHYSICAL_ADDRESS} />
			    <FieldValue
					as="p"
					id="vcm-cms-physical-address-type"
					alignItems="stretch"
					label={CONST.ADDRESS_LABEL_TEXT}
					value={
						<RadioGroup
							labelledBy="vcm-cms-physical-address-type"
							vertical
							options={addressTypeValue}
							onChange={handleAddressTypeChange(dispatch,'physicalAddressType', masterLookupStateData)}
							data-name={`${CONST.PHYSICAL_ADDRESS} - ${CONST.ADDRESS_LABEL_TEXT}`}
						/>
					}
					labelsm={3}
					valuesm={5}
					mb={1.5625}
				/>
				<Address 
					addressType="physicalAddress" 
					groupName={CONST.PHYSICAL_ADDRESS}
					optional
				/>
			</div>
			)}
		</>
	)
}

export default AddressComponent