import { connect } from 'react-redux';
import AddPrimaryBeneficiary from './AddPrimaryBeneficiary';
import { manageBeneficiaryActions, localStateManagementActions } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    manageBeneficiaryData: state.manageBeneficiaryData,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...manageBeneficiaryActions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddPrimaryBeneficiary);
