import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import routeConstants from 'routeConstants';

import '../Screens.css';
import SignInBySoftTokenForm from '../../SignInBySoftTokenForm';
import RegisterCardComponent from '../../RegisterCard/RegisterCard';
import { changedHandler, validityHandler } from '../../FormUtils';

class SignInBySoftToken extends Component {
  styles = {
    leftColumn: {
      marginTop: '30px'
    },
    rightColumn: {
      marginTop: '30px'
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      errMsg: null,
      valid: false,
      touched: false,
      rules: {
        length: 6,
        isNumeric: true,
        required: true
      },
      error: false
    };
  
    this.onChange = this.onChange.bind(this);

    this.onBlur = this.onBlur.bind(this);
  }

  

  onChange(event) {
    changedHandler(event, this);
  }

  onBlur() { validityHandler(this); }

  signInHandler = () => {
    const { value, valid, touched } = this.state
    const { history } = this.props
    if (valid && touched) {
      if (Number(value) === 123456) {           //   API Call
        this.setState((state) => ({ ...state, error: false }));
        history.push('/Profile');
      } else {
        this.setState((state) => ({ ...state, error: true }));
      }
    } else {
      this.setState((state) => ({ ...state, error: true }));
    }
  }

  render() {
    return (
      <div className="container-fluid sign-in-page">
        <div className="container">
          <Row className="main">
            <Col lg xl style={this.styles.leftColumn}>
              <SignInBySoftTokenForm state={this.state} signInHandler={this.signInHandler} changed={this.onChange} blurred={this.onBlur}/>
            </Col>
            <Col lg xl style={this.styles.rightColumn}>
              <RegisterCardComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

SignInBySoftToken.propTypes = {
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)])
}

SignInBySoftToken.defaultProps = {
  history: []
}

export default SignInBySoftToken;
