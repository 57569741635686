import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import Config from 'env/Config';

/* **************************\
Function: setJWTToken().
Explanation:
This function will set the token received
from the login API which is required for all other API calls
It method doesn't require any callback, so it is kept as null
============================

\************************** */

export const setJWTToken = data => () => {
  makeApiRequest(ActionTypes.SET_JWT_TOKEN, data, null, null, null);
};

export const setGuidToken = data => () => {
  makeApiRequest(ActionTypes.SET_GUID_TOKEN, data, null, null, null);
};

  export const signedSuccessFlag = (data) => (dispatch, /* getState */) => {
    dispatch({
        type:ActionTypes.SIGNED_SUCCESS,
        signedInSuccess : data,
        loginStatus : 'customer'
    });
};

export const updateEmail = (data) => (dispatch, /* getState */) => {
    dispatch({
      type:ActionTypes.VERIFIED_EMAIL,
      data
  });
};


export const updateEnvironmemt = (data) => (dispatch, /* getState */) => {
  dispatch({
      type:ActionTypes.VERIFIED_PHONE,
      data
  });
};

export const setEnvironment = (data) => (dispatch, /* getState */) => {
  dispatch({
      type:ActionTypes.SET_ENV,
      data
    });
  };


export const getAccountRecoverPreference = (data) => (dispatch) =>{

  const payload = {
    ...data
  };


  makeApiRequest(
    ActionTypes.USER_GET_ACCOUNT_RECOVER_PREFERENCE,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_ACTION_GET_ACCOUNT_RECOVER_SUCCESS,
      data : {
        response
      }
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_ACTION_GET_ACCOUNT_RECOVER_FAILURE,
        data: error
      });
    }
  );
};


export const setAccountRecoverPreference = (data) => (dispatch) =>{
  const payload = {
    ...data
  };
  makeApiRequest(
    ActionTypes.USER_SET_ACCOUNT_RECOVER_PREFERENCE,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_ACTION_SET_ACCOUNT_RECOVER_SUCCESS,
      data : {
        response
      }
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_ACTION_SET_ACCOUNT_RECOVER_FAILURE,
        data: error
      });
    }
  );
};

export const getQuickSignIn = (data) => (dispatch) =>{

  const payload = {
    ...data
  };


  makeApiRequest(
    ActionTypes.USER_GET_QUICK_SIGN_IN,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_GET_QUICK_SIGN_IN_SUCCESS,
      data : {
        response
      }
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_GET_QUICK_SIGN_IN_FAILURE,
        data: error
      });
    }
  );
};


export const setQuickSignIn = (data) => (dispatch) =>{
  const payload = {
    ...data
  };
  makeApiRequest(
    ActionTypes.USER_SET_QUICK_SIGN_IN,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_SET_QUICK_SIGN_IN_SUCCESS,
      data : {
        response
      }
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_SET_QUICK_SIGN_IN_FAILURE,
        data: error
      });
    }
  );
};

export const saveSecurityQuestions = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.USER_SAVE_SECURITY_QUESTIONS_CLEAR,
      saveSecurityQuestions : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.USER_SAVE_SECURITY_QUESTIONS,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_SAVE_SECURITY_QUESTIONS_SUCCESS,
      saveSecurityQuestions : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_SAVE_SECURITY_QUESTIONS_FAILURE,
        saveSecurityQuestions : error,
        isLoading : false
      });
    }
  );
}
};


export const setDocumentPreference = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.USER_SET_DOCUMENT_PREFERENCE_CLEAR,
      documentPreference : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.USER_SET_DOCUMENT_PREFERENCE,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_SET_DOCUMENT_PREFERENCE_SUCCESS,
      documentPreference : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_SET_DOCUMENT_PREFERENCE_FAILURE,
        documentPreference : error,
        isLoading : false
      });
    }
  );
}
};

export const getSavedSecurityQuestions = (data) => (dispatch) =>{

  if(data === "clear"){
    dispatch({
      type : ActionTypes.GET_SAVED_SECURITY_QUESTIONS_CLEAR,
      getSavedSecurityQuestions : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.GET_SAVED_SECURITY_QUESTIONS,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.GET_SAVED_SECURITY_QUESTIONS_SUCCESS,
      getSavedSecurityQuestions : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.GET_SAVED_SECURITY_QUESTIONS_FAILURE,
        getSavedSecurityQuestions : error,
        isLoading : false
      });
    }
  );
}
};



export const getCustomerProfile = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.GET_CUSTOMER_PROFILE_CLEAR,
      getCustomerProfile : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.GET_CUSTOMER_PROFILE,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.GET_CUSTOMER_PROFILE_SUCCESS,
      getCustomerProfile : response.data,
      isLoggedIn: true,
      isLoading : false,
    });
  },
    error => {
      dispatch({
        type : ActionTypes.GET_CUSTOMER_PROFILE_FAILURE,
        getCustomerProfile : error,
        isLoading : false
      });
    }
  );
}
};

export const getSignInMethod = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.USER_GET_SIGN_IN_CLEAR,
      getChangeSignInMethod : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.USER_GET_SIGN_IN_METHOD,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.USER_GET_SIGN_IN_SUCCESS,
      getChangeSignInMethod : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.USER_GET_SIGN_IN_FAILURE,
        getChangeSignInMethod: error,
        isLoading : false
      });
    }
  );
}
};


export const setSignInMethod = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.USER_SET_SIGN_IN_CLEAR,
      changeSignInMethod : ""
    });
  }
  else{
    const payload = {
      ...data
    };

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    makeApiRequest(
      ActionTypes.USER_SET_SIGN_IN_METHOD,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.USER_SET_SIGN_IN_SUCCESS,
        changeSignInMethod : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.USER_SET_SIGN_IN_FAILURE,
          changeSignInMethod: error,
          isLoading : false
        });
      }
    );
  }
};

export const signIn = (data) => (dispatch) =>{
  const payload = {
    ...data
  };

  makeApiRequest(
    ActionTypes.SIGN_IN,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.SIGN_IN_SUCCESS,
      data : response
    });
  },
    error => {
      dispatch({
        type : ActionTypes.SIGN_IN_FAILURE,
        data: error
      });
    }
  );
};

export const verifyEmail = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.VALIDATE_EMAIL_CLEAR,
      verifyEmail : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    const payload = {
      ...data
    };

    makeApiRequest(
      ActionTypes.VALIDATE_EMAIL,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.VALIDATE_EMAIL_SUCCESS,
        verifyEmail : response.data,
        isLoading:false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.VALIDATE_EMAIL_ERROR,
          verifyEmail: error,
          isLoading:false
        });
      }
    );

  }
};


export const signUp = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.SIGN_UP_CLEAR,
      createUser : ""
    });
  }
  else{

    const payload = {
      ...data
    };
    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });


    makeApiRequest(
      ActionTypes.SIGN_UP,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.SIGN_UP_SUCCESS,
        createUser : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.SIGN_UP_ERROR,
          createUser: error,
          isLoading : false
        });
      }
    );

  }
};

export const sendOtp = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.SEND_OTP_CLEAR,
      sendOtp : ""
    });
  }
  else{

    const payload = {
      ...data
    };

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    makeApiRequest(
      ActionTypes.SEND_OTP,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.SEND_OTP_SUCCESS,
        sendOtp : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.SEND_OTP_ERROR,
          sendOtp: error,
          isLoading : false
        });
      }
    );

  }
};


export const verifyOtp = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.VERIFY_OTP_CLEAR,
      verifyOtp : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    const payload = {
      ...data
    };

    makeApiRequest(
      ActionTypes.VERIFY_OTP,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.VERIFY_OTP_SUCCESS,
        verifyOtp : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.VERIFY_OTP_ERROR,
          verifyOtp: error,
          isLoading : false
        });
      }
    );

  }
};


export const sendEmailLink = (data) => (dispatch) =>{



  if(data === ""){
    dispatch({
      type : ActionTypes.SEND_EMAIL_LINK_CLEAR,
      signUp : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    const payload = {
      ...data
    };

    makeApiRequest(
      ActionTypes.SEND_EMAIL_LINK,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.SEND_EMAIL_LINK_SUCCESS,
        signUp : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.SEND_EMAIL_LINK_FAILURE,
          signUp: error,
          isLoading : false
        });
      }
    );

  }
};


export const verifyEmailLink = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.VERIFIED_EMAIL_LINK_CLEAR,
      memberVerify : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

    const payload = {
      ...data
    };



    makeApiRequest(
      ActionTypes.VERIFIED_EMAIL_LINK,
      payload,
      Config.envVariable,
      response => {
    dispatch({
        type : ActionTypes.VERIFIED_EMAIL_LINK_SUCCESS,
        memberVerify : response.data,
        isLoading : false
      });
    },
      error => {
        dispatch({
          type : ActionTypes.VERIFIED_EMAIL_LINK_FAILURE,
          memberVerify: error,
          isLoading : false
        });
      }
    );

  }
};


export const recoverOnlineID = (data) => (dispatch) =>{


  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_ONLINE_ID_CLEAR,
      recoverOnlineID : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

  const payload = {
    ...data
  };



  makeApiRequest(
    ActionTypes.RECOVER_ONLINE_ID,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_ONLINE_ID_SUCCESS,
      recoverOnlineID : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_ONLINE_ID_FAILURE,
        recoverOnlineID: error,
        isLoading : false
      });
    }
  );
  }
};

export const recoverOnlineIDType = (data) => (dispatch) =>{


  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_ONLINE_ID_TYPE_CLEAR,
      recoverOnlineIDType : ""
    });
  }
  else{

    dispatch({
      type : ActionTypes.LOGIN_LOADER,
      isLoading : true
    });

  const payload = {
    ...data
  };



  makeApiRequest(
    ActionTypes.RECOVER_ONLINE_ID_TYPE,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_ONLINE_ID_TYPE_SUCCESS,
      recoverOnlineIDType : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_ONLINE_ID_TYPE_FAILURE,
        recoverOnlineIDType: error,
        isLoading : false
      });
    }
  );
  }
};

export const recoverPasswordGuest = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_CLEAR,
      recoverPasswordGuest : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });



  makeApiRequest(
    ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_SUCCESS,
      recoverPasswordGuest : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL_FAILURE,
        recoverPasswordGuest: error,
        isLoading : false
      });
    }
  );
  }
};

export const recoverPwrdGuestEmailVerify = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_CLEAR,
      recoverPwrdGuestEmailVerify : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });



  makeApiRequest(
    ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_SUCCESS,
      recoverPwrdGuestEmailVerify : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY_FAILURE,
        recoverPwrdGuestEmailVerify: error,
        isLoading : false
      });
    }
  );
 }
};

export const recoverPasswordMemberOrGuest = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_CLEAR,
      memberOrGuest : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });



  makeApiRequest(
    ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_SUCCESS,
      memberOrGuest : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST_FAILURE,
        memberOrGuest: error,
        isLoading : false
      });
    }
  );
 }
};

export const resetPassword = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RESET_FLOW_CLEAR,
      resetPassword : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.RESET_FLOW,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RESET_FLOW_SUCCESS,
      resetPassword : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RESET_FLOW_FAILURE,
        resetPassword : error,
        isLoading : false
      });
    }
  );
  }
};

export const recoverPasswordCustomer = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RECOVER_FLOW_CUSTOMER_CLEAR,
      recoverPwdCustomer : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });


  makeApiRequest(
    ActionTypes.RECOVER_FLOW_CUSTOMER,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RECOVER_FLOW_CUSTOMER_SUCCESS,
      recoverPwdCustomer : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RECOVER_FLOW_CUSTOMER_FAILURE,
        recoverPwdCustomer: error,
        isLoading : false
      });
    }
  );
  }
};

export const validateSecurityQuestions = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.VALIDATE_SECURITY_QUESTIONS_CLEAR,
      validateSecurityQuestions : ""
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.VALIDATE_SECURITY_QUESTIONS,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.VALIDATE_SECURITY_QUESTIONS_SUCCESS,
      validateSecurityQuestions : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.VALIDATE_SECURITY_QUESTIONS_FAILURE,
        validateSecurityQuestions: error,
        isLoading : false
      });
    }
  );
  }
};


export const changePassword = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.CHANGE_FLOW_CLEAR,
      changePassword : ""
    });
  }
  else{
  const payload = {
    ...data
  };


  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.CHANGE_FLOW,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.CHANGE_FLOW_SUCCESS,
      changePassword : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.CHANGE_FLOW_FAILURE,
        changePassword: error,
        isLoading : false
      });
    }
  );
  }
};



export const resendOTPCustomers = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RESEND_OTP_CUSTOMERS_CLEAR,
      resendOTPCustomers : ""
    });
  }
  else{
  const payload = {
    ...data
  };


  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.RESEND_OTP_CUSTOMERS,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RESEND_OTP_CUSTOMERS_SUCCESS,
      resendOTPCustomers : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RESEND_OTP_CUSTOMERS_FAILURE,
        resendOTPCustomers: error,
        isLoading : false
      });
    }
  );
  }
};



export const loginAttempts = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.LOGIN_ATTEMPTS_CLEAR,
      loginAttempts : ""
    });
  }
  else{
  const payload = {
    ...data
  };


  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.LOGIN_ATTEMPTS,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.LOGIN_ATTEMPTS_SUCCESS,
      loginAttempts : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.LOGIN_ATTEMPTS_FAILURE,
        loginAttempts: error,
        isLoading : false
      });
    }
  );
  }
};



export const signUpVerifyOnlineID = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.SIGN_UP_VERFIY_ONLINEID_CLEAR,
      signUpVerifyOnlineID : ""
    });
  }
  else{
  const payload = {
    ...data
  };


  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.SIGN_UP_VERFIY_ONLINEID,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.SIGN_UP_VERFIY_ONLINEID_SUCCESS,
      signUpVerifyOnlineID : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.SIGN_UP_VERFIY_ONLINEID_FAILURE,
        signUpVerifyOnlineID: error,
        isLoading : false
      });
    }
  );
  }
};
export const resetPasswordForGuest = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.RESET_FLOW_GUEST_CLEAR,
      resetPasswordForGuest : ""
    });
  }
  else{
  const payload = {
    ...data
  };


  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.RESET_FLOW_GUEST,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.RESET_FLOW_GUEST_SUCCESS,
      resetPasswordForGuest : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.RESET_FLOW_GUEST_FAILURE,
        resetPasswordForGuest: error,
        isLoading : false
      });
    }
  );
  }
};

export const memberOrGuestIVRVerify = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_CLEAR,
      memberOrGuestIVR : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_SUCCESS,
      memberOrGuestIVR : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY_FAILURE,
        memberOrGuestIVR: error,
        isLoading : false
      });
    }
  );
 }
};


export const directMemberID = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_ID_CLEAR,
      directMemberID : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_ID,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_ID_SUCCESS,
      directMemberID : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_ID_ERROR,
        directMemberID: error,
        isLoading : false
      });
    }
  );
 }
};


export const directSendEmailOTP = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_CLEAR,
      directSendEmailOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };
  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_SUCCESS,
      directSendEmailOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP_ERROR,
        directSendEmailOTP: error,
        isLoading : false
      });
    }
  );
 }
};


export const directVerifyOTP = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_CLEAR,
      directVerifyOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };
  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_VERIFY_OTP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_SUCCESS,
      directVerifyOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_ERROR,
        directVerifyOTP: error,
        isLoading : false
      });
    }
  );
 }
};



export const directSendPhoneOTP = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_CLEAR,
      directSendPhoneOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };
  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_SUCCESS,
      directSendPhoneOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_ERROR,
        directSendPhoneOTP: error,
        isLoading : false
      });
    }
  );
 }
};


export const directSignUp = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_SIGN_UP_CLEAR,
      directSignUp : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_SIGN_UP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_SIGN_UP_SUCCESS,
      directSignUp : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_SIGN_UP_ERROR,
        directSignUp: error,
        isLoading : false
      });
    }
  );
 }
};

export const directStatusofVerifyOTP = (data) => (dispatch) =>{

  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_CLEAR,
      directStatusofVerifyOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };

  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_STATUS_VERIFY,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_SUCCESS,
      directStatusofVerifyOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_STATUS_VERIFY_ERROR,
        directStatusofVerifyOTP: error,
        isLoading : false
      });
    }
  );
 }
};

export const directVerifyPhoneOTP = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_CLEAR,
      directVerifyPhoneOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };
  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_VERIFY_OTP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_SUCCESS,
      directVerifyPhoneOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_VERIFY_OTP_ERROR,
        directVerifyPhoneOTP: error,
        isLoading : false
      });
    }
  );
 }
};

export const directReSendPhoneOTP = (data) => (dispatch) =>{
  if(data === ""){
    dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_CLEAR,
      directReSendPhoneOTP : "",
      isLoading : false
    });
  }
  else{
  const payload = {
    ...data
  };
  dispatch({
    type : ActionTypes.LOGIN_LOADER,
    isLoading : true
  });

  makeApiRequest(
    ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_SUCCESS,
      directReSendPhoneOTP : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP_ERROR,
        directReSendPhoneOTP: error,
        isLoading : false
      });
    }
  );
 }
};


export const genericidLookup = (payload) => (dispatch) =>{

  // dispatch({
  //   type : ActionTypes.LOGIN_LOADER,
  //   isLoading : true
  // });

  makeApiRequest(
    ActionTypes.GENERICID_LOOKUP_START,
    payload,
    Config.envVariable,
    response => {
  dispatch({
      type : ActionTypes.GENERICID_LOOKUP_SUCCESS,
      response : response.data,
      isLoading : false
    });
  },
    error => {
      dispatch({
        type : ActionTypes.GENERICID_LOOKUP_ERROR,
        directReSendPhoneOTP: error,
        isLoading : false
      });
    }
  );
 
};


export const getApplicationConfig = (data) => (dispatch) => {
  const payload = {
    ...data
  };

  dispatch({
    type: ActionTypes.GET_APPLICATION_CONFIG_LOADER,
  });

  makeApiRequest(
    ActionTypes.GET_APPLICATION_CONFIG,
    payload,
    Config.envVariable,
    response => {
      dispatch({
        type: ActionTypes.GET_APPLICATION_CONFIG_SUCCESS,
        data: {
          response
        }
      });
    },
    error => {
      dispatch({
        type: ActionTypes.GET_APPLICATION_CONFIG_ERROR,
        data: error,
        isAxiosError: true
      });
    }
  );
};

export const directVerifyMember = data => dispatch =>{

  const payload = {
    ...data
  };

  // dispatch({
  //   type : ActionTypes.LOGIN_LOADER,
  //   isLoading : true
  // });

  makeApiRequest(
    ActionTypes.DIRECT_VERIFY_MEMBER_ID,
    payload,
    Config.envVariable,
    response => {
      console.log('memberverifysuccess',response)
      if(response?.data){
        dispatch({
          type : ActionTypes.DIRECT_VERIFY_MEMBER_ID_SUCCESS,
          data : response.data
        });
      }
    },
    error => {
      console.log('memberverifyerror')
      dispatch({
        type : ActionTypes.DIRECT_VERIFY_MEMBER_ID_ERROR,
        directVerifyMember: error,
        isLoading : false,
      });
    }
  );
 }


export const resetVcmidVerification = () => dispatch => {
  dispatch({
      type: ActionTypes.DIRECT_VERIFY_MEMBER_ID_CLEAR,
  });
};