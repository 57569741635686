import styled from 'styled-components';
import { Row } from 'react-bootstrap';

const StyledRadio = styled(Row)`
    text-align: left;
    font: Bold 16px/19px benton-sans;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    .container-fluid {
        flex-basis: 10%;
        margin-left: 0;
        padding-left: 0;
        margin-right: 1%;
    }
    .radioQ {
        width: 212px;
        margin-right: 20px;
    }
    .container-fluid label {
        flex-basis: unset;
        width: unset;
        margin-bottom: 16px;
        font-weight: normal;
    }
    label {
        flex: 1;
        margin-right: 10px;
    }
    .card-header {
        padding: 0px 25px;
        background-color: #ffffff;
    }
`;

const Optionaltext = styled.span`
    text-align: left;
    font: 500 14px/22px bentons-sans;
    letter-spacing: 0;
    color: rgba(86, 86, 90, 1);
    padding-left: 5px;
`;

const AddressQ = styled.label`
    margin-bottom: 25px;
    flex-basis: 25.95%;
    padding-right: 10px;
`;

const Hint = styled.div`
    height: 70px;
    position:relative;
    display: flex;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 16px rgba(0,0,0,0.16);
    /*border: 1px solid rgba(210,210,210,1);*/
    width: 90%;
    margin: 15px auto 10px;
    opacity: 1;
    div {
        padding: 15px 60px 15px 30px;
        text-align: left;
        font: 400 14px/18px benton-sans;
        letter-spacing: 0.28px;
        color: #56565a;
        opacity: 1;
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8cbf26;
        position: absolute;
    }
`;

const FAQWrapper = styled.div`
    border: 1px solid rgba(210, 210, 210, 1);
    opacity: 1;
    height: 90px;
    text-align: left;
    font: 600 16px/22px benton-sans;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 30px;
    color: rgba(73, 73, 74, 1);
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8cbf26;
        position: absolute;
    }
    .text-wrap {
        display: flex;
        flex-direction: column;
        padding: 26px 31px 23px;
    }
`;

const SeasonalWrapper = styled.div`
    padding: 0;
    margin: 0;
    .addressFields .row {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .row {
        padding-bottom: 0;
    }
    label.form-label {
        max-width: unset;
        flex-basis: 25.95%;
        padding-right: 10px;
    }
    .addressFields label {
        flex-basis: 38.92%;
    }
    label + .col-sm-7 {
        max-width: 445px;
        flex-basis: 40.67%;
    }
    .addressFields label + .col-sm-7 {
        max-width: 445px;
        flex-basis: 61%;
    }
    && .datePickerRow {
        position: relative;
        width: 255px;
        max-width: 255px;
        padding-right: 20px;
    }
`;

export { StyledRadio, Optionaltext, AddressQ, Hint, FAQWrapper, SeasonalWrapper };
