import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as MessageActionTypes from '../ReduxConstants/MessageCenterConstants';
import * as DocumentCenterActionTypes from '../ReduxConstants/DocumentCenterConstants';
import * as TransactionActivityActions from '../ReduxConstants/TransactionActivityConstants';
import Config from 'env/Config';
import {  getResponseWithoutXAPIKEY, postApplicationJson, get, post, getDashboardProfileData,getDocument,getDocumentListId, searchDocument,getDocumentId, getDashboardAccountType, postDashboardProfileData, setMSRAuthHeaders, setFisTokenAuthHeaers, removeAPIVersionHeader, addAPIVersionHeader, setMSRTransferDocumentHeaders } from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';
import axios from 'axios';

const DashBoardApiEvents = () => {
    ApiEventHub.on(MessageActionTypes.GET_DASHBOARD_WIDGET_MESSAGES, data => {
        get(
            Config.getDashboardWidgetMessages,
            undefined,
            data.endPointURL.MESSAGE_CENTER,
            data.successCallBack,
            data.errorCallback,
        );
    });

    const createDocumentEndPointUrl = data => {
        if (data.payload?.downloadUrl?.length > 0) {
            return data.payload.downloadUrl;
        }
        if (data.payload?.queryString) {
            return `${data.endPointURL.DOCUMENT_CENTER}${Config.getDocument}${data.payload?.queryString}`;
        }
        return `${data.endPointURL.DOCUMENT_CENTER}${Config.getDocument}`;
    };

    ApiEventHub.on(DocumentCenterActionTypes.GET_SINGLE_DOCUMENT, data => {
        const url = createDocumentEndPointUrl(data);
        getDocument(url, data.successCallBack, data.errorCallback, Config.serviceAccount, data.payload);
    });

    ApiEventHub.on(DocumentCenterActionTypes.SEARCH_DOCUMENTS, data => {
        searchDocument(
            Config.searchDocument,
            data.payload,
            data.endPointURL.DOCUMENT_CENTER,
            data.successCallBack,
            data.errorCallback,
            Config.serviceAccount,
        );
    });

    ApiEventHub.on(DocumentCenterActionTypes.GET_DOCUMENT_PDF_LIST, data => {
        getDocumentListId(
            Config.getDocumentPdfList,
            data.payload,
            data.endPointURL.getPortfolioPlannerRedirectionData,
            data.successCallBack,
            data.errorCallback,
            
        );
    });

    ApiEventHub.on(DocumentCenterActionTypes.GET_DOCUMENTID, data => {
        addAPIVersionHeader('2023-06-30')
        getDocumentId(
            Config.getDocumentId,
            data.payload,
            data.endPointURL.DOCUMENT_MANAGER,
            data.successCallBack,
            data.errorCallback,
            
        );
    });

    // Dashboard Profile Data
    ApiEventHub.on(ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY, data => {     

        getDashboardProfileData(
            Config.getPortfolioSummary,
            data.payload,
            data.endPointURL.msrAccountSummary,
            data.successCallBack,
            data.errorCallback,            
        );
    });

    // Dashboard Profile 2 Data
    ApiEventHub.on(ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY, data => {
        getDashboardProfileData(
            Config.getPortfolioSummary,
            data.payload,
            data.endPointURL.msrnewDashboard,
            data.successCallBack,
            data.errorCallback,
            Config.dashboardPortfolioSummaryKey
        );
    });

    // Get enabled feature for Brokerage accounts
    ApiEventHub.on(ActionTypes.GET_ENABLED_FEATURE, data => {
        const apiKey = Config.msrTransactionKey;   
        const params = getSessionValues();
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
           removeAPIVersionHeader();  
        get(
            Config.getEnabledFeature,
            undefined,
            data.endPointURL.msrEnableFeature,
            data.successCallBack,
            data.errorCallback, 
        );
    });

    // Dashboard Drop Down Data
    ApiEventHub.on(ActionTypes.MEMBER_DASHBOARD_DROPDOWN_LINKS, data => {
        postDashboardProfileData(
            Config.msrAscensus,
            data.payload,
            data.endPointURL.msrAscensusAmt,
            data.successCallBack,
            data.errorCallback,                       
        );
    });

    // Get system heath
    ApiEventHub.on(ActionTypes.GET_SYSTEM_HEALTH, data => {
        get(
            Config.getSystemHealth,
            data.payload,
            data.endPointURL.investorApi,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActivityActions.GET_ACCOUNT_ACTIVITIES, data => {
        const {token, vcmid} = data.payload;
        const apiKey = Config.msrTransactionKey;   
        const params = getSessionValues();
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);    
        postApplicationJson(
            Config.getAccountActivities,
            data.payload.payload,
            data.endPointURL.msrTransactionHistory,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActivityActions.CANCEL_ACTIVITY_TRANSACTION, data => {
        const params = getSessionValues();
        const apiKey = Config.msrTransactionKey;   
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey,params.fismemberid);   
        setFisTokenAuthHeaers();
     
        postApplicationJson(
            Config.deleteAccountActivity,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });    

    ApiEventHub.on(ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS, data => {
          
        getResponseWithoutXAPIKEY(
            Config.getDashboardSavedItemsList,
            data.payload,
            data.endPointURL.msrgetDashboardSavedItems,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE, data => {
        const newEndPoint = `${Config.getCompositeData}/${data.payload}`;
        getDashboardAccountType(
            newEndPoint,
            data.payload,
            data.endPointURL.getCompositeData,
            data.successCallBack,
            data.errorCallback,
            Config.metaDataApiKey,
        );
    });


    // Single Sign On Data Call
    ApiEventHub.on(ActionTypes.GET_SAML_ASSERTION, data => {
        postApplicationJson(
            Config.getSmalAssertion,
            data.payload,
            data.endPointURL.singleSignOnRedirection,
            data.successCallBack,
            data.errorCallback, 
        );
    });

};

export default DashBoardApiEvents;
