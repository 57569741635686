import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions} from '../../../../shared/Actions';
import RegisterPassword from './RegisterPassword';



const mapStateToProps = (state /* , props */) => (
    {
        registerOnlineIdState: state.localStateManagementReducerData.registerOnlineIdState,
        personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
        registerState: state.localStateManagementReducerData.registerState,
        jovSsnState: state.localStateManagementReducerData.jovSsnState
    }
  );

const mapDispatchToProps = {
     ...localStateManagementActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterPassword));