const defaultFont='600 0.9375rem/1.375rem benton-sans,sans-serif'

const Styles = {
    listgroup: {
        font: defaultFont,
        padding:0,       
    },
    link: {
        color:'rgba(0, 74, 152, 1)',
        font: defaultFont,
        opacity: 1,
    },
    borderStyle:{
        borderBottom: '0.0625rem solid rgba(210, 210, 210, 1)',
        paddingTop:'1.25rem',
        paddingBottom:'1.25rem'
    },
    saveItems: {
        color: '#727272',
        font: '500 0.875rem/1.375rem benton-sans,sans-serif',
    },

    number: {
        textAlign: 'left',
        font: defaultFont,
        letterSpacing: 0,
        color: '#49494A',
        opacity: 1,
    },

    font: {
        fontSize: '0.875rem',
    },
    
    linkDescription:{
        color: 'rgba(73, 73, 74, 1)',
        font: '500 0.93rem/1.25rem benton-sans,sans-serif',
        marginBottom:0
    }
};

export default Styles;
