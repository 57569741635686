import { connect } from "react-redux";
import moment from 'moment-timezone'
import { get } from 'lodash';
import Consts from './constants';
import { memberPreferencesActions, profileSettingsAction } from '../../../shared/Actions';
import DeliverySettings from "./DeliverySettings";


const mapStateToProps = (state) => {
    const storeData = get(state,'memberPreferencesData.deliveryPreferences', {});
    const profileInformationData = get(state, 'profileInformationData', {});
    const liveChatReducerData = get(state, 'liveChatReducerData', {});
    return {
        storeData,
        liveChatReducerData,
        emailUrgent: get(storeData, Consts.notificationUrgent.emailUrgent, false),
        pushUrgent:get(storeData, Consts.notificationUrgent.pushUrgent, false),
        messageUrgent:get(storeData, Consts.notificationUrgent.messageUrgent, false),
        emailNotification:get(storeData, Consts.notificationPref.emailNotification, false),
        pushNotification:get(storeData, Consts.notificationPref.pushNotification, false),
        messageNotification:get(storeData, Consts.notificationPref.messageNotification, false),
        emailAddress: get(storeData, "personEmail", '??@??.??'),
        phoneNumber: get(storeData, "personPhone", '000000000'),
        phoneCountryCode: get(profileInformationData, 'profileInformation.phoneInformation[0].phoneCountryCode', Consts.defaultValues.phoneCountryCode),
        callTimeZone: get(storeData, Consts.callTime.zone, Consts.defaultValues.timeZone),
        callTimeOption: get(storeData, Consts.callTime.time, "").toLowerCase().split("(")[0].trim(),
        quietTimeZone: get(storeData, Consts.quietTime.zone, Consts.defaultValues.timeZone),
        quietTimeSelected: get(storeData, Consts.quietTime.selected, false),
        quietStartTime: moment(get(storeData, Consts.quietTime.timeStart, ''), Consts.fmt).format(Consts.fmt),
        quietEndTime: moment(get(storeData, Consts.quietTime.timeEnd, ''), Consts.fmt).format(Consts.fmt),
        isLoading: get(state, 'memberPreferencesData.isLoading'),
        isError: get(state, 'memberPreferencesData.isError')
    };
}

const mapDispatchToProps = {
    getMemberPreferences: memberPreferencesActions.getMemberPreferences,
    getMemberProfileInformations: profileSettingsAction.getMemberProfileInformations,
    getMemberMSRPreferences: memberPreferencesActions.getMemberMSRPreferences,
    changeMemberPreferences: memberPreferencesActions.changeMemberPreferences,
    saveData: memberPreferencesActions.saveData,
    resetAPIError: memberPreferencesActions.resetAPIError
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySettings);
