import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import routeConstants from 'routeConstants';
import classes from '../Input/Input.module.css';
import { WInput, WButton, WSpinner, WStepper as UserMngmtStepper, CommonButtons} from '../../../common';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import checkValidity from '../../../utils/checkValidity';
import Rules from "../../AccountManagementModule/PersonalInfo/Rules";
import './UserAccountInformation.css';
import PhoneField from '../../AccountRegistration/components/PhoneField';
import MemberInfo from '../../AccountRegistration/components/MemberInfo';
import SideHeader from '../SideHeader/SideHeader';
import styles from './styles';
import { VerifyPhoneRow, SecurityCodeInfo, data, assignObj, emailOTPError, errorCodeMessageMapping } from './UserAccountInformationConstants';

class UserDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPhoneError: false,
            emailAdd: props.otpDetails.emailAddress,
            primaryPhone: props.otpDetails.primaryNumber,
            secondaryPhone:props.otpDetails.secondaryNumber,
            primaryPhoneCountryCode: props.otpDetails.primaryNumberCountryCode,
            secondaryPhoneCountryCode: props.otpDetails.secondaryNumberCountryCode,
            isMobileSecondary: props.otpDetails.isMobileSecondary,
            isMobilePrimary: props.otpDetails.isMobilePrimary,
            EmailOTP: '',
            errorMsg: [],
            nextDisabled: false,
            cancelled: false,
            emailOtpVerified: false,
            moveNext: false,
            reVerifyEmail: false,
            showEnterOtp: false,
            verifyEmailBtn: false,
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.storeMobileOptChange = this.storeMobileOptChange.bind(this);
        this.handlePhoneBlur = this.handlePhoneBlur.bind(this);
    }

    componentDidMount(){
        const { otpDetails={} } = this.props;
        const {emailAddress, primaryNumber} = otpDetails;
        if ( !emailAddress || (emailAddress && emailAddress === '') || !primaryNumber || (primaryNumber && primaryNumber ==='')) {
            this.setState({nextDisabled: true});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {...prevState}
        const { reVerifyEmail } = newState;
        const { receivedEmailOTP, directVerifyOTP, validatePassCode } = nextProps;
            if (receivedEmailOTP) {
            if (receivedEmailOTP.errorCode !== 0) {
                if (reVerifyEmail) {
                    newState.errorMsg.EmailOTP =  errorCodeMessageMapping[receivedEmailOTP.errorCode];
                } else{
                    newState.errorMsg.emailAdd =  errorCodeMessageMapping[receivedEmailOTP.errorCode];
                // newState.reVerifyEmail = false;
                    newState.showEnterOtp  = newState.reVerifyEmail === true;
                }
            } else {
                newState.nextDisabled = newState.EmailOTP.length < 6;
                newState.showEnterOtp  = true; 
            }
            if (directVerifyOTP && directVerifyOTP.errorCode === 0) {
                const { result } = directVerifyOTP;
                const { verified } = result;
                if (verified === 'true') {
                    newState.nextDisabled = false;
                    newState.emailOtpVerified = true;
                } else {
                    newState.errorMsg.EmailOTP = emailOTPError;
                }
            } else if (directVerifyOTP && directVerifyOTP.errorCode !== 0) {
                newState.errorMsg.EmailOTP = errorCodeMessageMapping[directVerifyOTP.errorCode];
                validatePassCode('');
            }
        }
        return newState;
    }

    componentDidUpdate(prevProps){
        const { receivedEmailOTP, directSendEmailOTP } = prevProps;
        if (receivedEmailOTP && receivedEmailOTP.errorCode !== 0) {
            directSendEmailOTP('');
        }
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    analyticsLastTouch = (event) =>{
        analyticsFormAbandLastTouch(event);
    }

    handleEmailChange = eventKey => e => {
        if (eventKey.toString() === 'EmailOTP') {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
        const { errorMsg } = this.state;
        let err = '';
        errorMsg[eventKey.toString()] = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                rules: Rules[eventKey.toString()],
                value: e.target.value,
            })
            : '';
        errorMsg[eventKey.toString()] = err;
		
		 if (eventKey.toString() === 'EmailOTP') {
        	this.setState({ [eventKey]: e.target.value, errorMsg });
		}else {
			const verifyEmailBtn = errorMsg.emailAdd && errorMsg.emailAdd !== '';
			this.setState({ [eventKey]: e.target.value, errorMsg, verifyEmailBtn });
		}
    };

    handleBlurEmail = () => {
        const {errorMsg, emailAdd} =this.state;
        const { otpDetails, directSendEmailOTP } = this.props;
        const nextDisabled = errorMsg.emailAdd && errorMsg.emailAdd !== '' || (emailAdd !== otpDetails.emailAddress);
        const verifyEmailBtn = errorMsg.emailAdd && errorMsg.emailAdd !== '';
        this.setState({nextDisabled, showEnterOtp: false, verifyEmailBtn });
        directSendEmailOTP('');
    }

    verifyEmail = () => {
        const { emailAdd, errorMsg } = this.state;
        const { directSendEmailOTP } = this.props;
        directSendEmailOTP({ emailAddress: emailAdd });
        errorMsg.EmailOTP = '';
        this.setState({EmailOTP: ''})
    };

    reVerifyEmail = () => {
        const { emailAdd, errorMsg } = this.state;
        const { directSendEmailOTP } = this.props;
        directSendEmailOTP({ emailAddress: emailAdd });
        errorMsg.EmailOTP = '';
        errorMsg.emailAdd = '';
        this.setState({EmailOTP: '', errorMsg, reVerifyEmail: true })
    }
    
    handlePhoneBlur = (type, phoneNumber, isError) => {
        const { nextDisabled, errorMsg, emailAdd } = this.state;
        const { otpDetails } = this.props;
        const nextDisabledisErr = !nextDisabled && isError || errorMsg.emailAdd && errorMsg.emailAdd !== '' || !emailAdd || emailAdd === '' || (emailAdd !== otpDetails.emailAddress);;
        this.setState({
            [type]: phoneNumber,
            showPhoneError: isError,
            nextDisabled: nextDisabledisErr,
        });
    }


    onBack = () => {
        this.setState({cancelled: true});
    }


    renderWStepper = () => {
        const { pageData } = this.props;
        const { stepperPage, isMemberSignUp, isProspectSignUp} = pageData;
        let stepper = '';
        if(isProspectSignUp){
            stepper = <UserMngmtStepper currentPage={1} pages={stepperPage} />;
        }
        if(isMemberSignUp){
            stepper = <UserMngmtStepper currentPage={2} pages={stepperPage} />;
        }
        return stepper;
    }

    storeMobileOptChange = (type, optVal) => {
        if (type === data.primaryPhone) {
            this.setState({ isMobilePrimary: optVal});
        } else {
            this.setState({ isMobileSecondary: optVal});
        }
    }

    checkForModification(){
        const { otpDetails } = this.props;
        const {
            // primaryNumber,
            emailAddress,
            // primaryNumberCountryCode,
            // isMobilePrimary,
            // secondaryNumber,
            // secondaryNumberCountryCode,
            // isMobileSecondary,
        } = otpDetails;
        const {
            emailAdd,
            // primaryPhone,
            // secondaryPhone,
            // primaryPhoneCountryCode,
            // secondaryPhoneCountryCode,
            // isMobileSecondary: isSecondary,
            // isMobilePrimary: isPrimary,
        } = this.state;
       return (emailAddress !== emailAdd);// || (primaryPhone !== primaryNumber) || (primaryNumberCountryCode !== primaryPhoneCountryCode) || (secondaryPhone !== secondaryNumber) || (secondaryNumberCountryCode !== secondaryPhoneCountryCode) || (isMobilePrimary !== isPrimary) || (isMobileSecondary !== isSecondary);
    }

    handleButtonClick() {
        const isModified = this.checkForModification();
        const { validatePassCode, receivedEmailOTP } = this.props;
        const { EmailOTP } = this.state;
        const { EMAIL } = data;
        const payload = {
            verifyOTP: EmailOTP,
            requestType: EMAIL,
        }
        if (!isModified) {
            this.setState({moveNext: true, emailOtpVerified: true});
        } else if (isModified && receivedEmailOTP && receivedEmailOTP.errorCode === 0) {
            this.setState({moveNext: true});
            validatePassCode(payload);
        }
    }

    render() {
        const {
            otpDetails,
            isEmailOTPValidated,
            validateEmailErrorCode,
            receivedEmailOTP,
            isLoading,
            userFields,
            manageLocalState,
            directSendEmailOTP,
        } = this.props;
        const { showPhoneError, emailAdd, primaryPhone, secondaryPhone, errorMsg, EmailOTP, isMobilePrimary, isMobileSecondary, nextDisabled, cancelled, emailOtpVerified, moveNext, primaryPhoneCountryCode, secondaryPhoneCountryCode, showEnterOtp, verifyEmailBtn } = this.state;
        if (isEmailOTPValidated) {
            return <Redirect to="/accountRegistration/onetimecode" push />;
        }
        if (validateEmailErrorCode === 2027) {
            return <Redirect to="/accountLocked" push />;
        }
        if (cancelled) {
            return <Redirect to={routeConstants.termsAndConditions} />
        }
        if (moveNext && emailOtpVerified) {
            const { emailAdd: emailAddress, primaryPhone: primaryNumber, primaryPhoneCountryCode: primaryNumberCountryCode,
                     secondaryPhone: secondaryNumber, secondaryPhoneCountryCode: secondaryNumberCountryCode } = this.state;
            const primaryNumberCountryCodeFormated = primaryNumberCountryCode === null || primaryNumberCountryCode === '' ? '1' : primaryNumberCountryCode;
            const secondaryNumberCountryCodeFormated = secondaryNumberCountryCode === null || secondaryNumberCountryCode === '' ? '1' : secondaryNumberCountryCode;
            const otpDetailsNew = {
                        ...otpDetails,
                      primaryNumber,
                      emailAddress,
                      primaryNumberCountryCode: primaryNumberCountryCodeFormated,
                      isMobilePrimary,
                      secondaryNumber,
                      secondaryNumberCountryCode: secondaryNumberCountryCodeFormated,
                      isMobileSecondary,
                      phoneType: isMobilePrimary ? 'mobile' : 'work',
                    };
                    directSendEmailOTP('');
                    manageLocalState({otpDetails: otpDetailsNew });
                    
            return <Redirect to={routeConstants.otpIvrSelect} />
        }
        return (
            <div className="milestone">
                <div className="memberStepper" >{  this.renderWStepper() }</div>
                <div style={styles.userDetailsContainer}>
                    <WSpinner loading={isLoading} />                
                    <Row>
                        <Col md lg xl>
                            <SideHeader
                                title={data.title}
                                nameTitle={data.subtitle}
                                info={data.info} />
                        </Col>
                        <Col md lg xl>
                            <Container className="verifyDetailsContainer">
                                <MemberInfo userFields={userFields} />
                                <div className="verifyEmailRow mb-4">
                                    <p className="fieldTitle">{data.primaryEmail}</p>
                                    <Row>
                                        <Col md lg xl>
                                            <input
                                                id="mailAdd"
                                                name="emailAdd"
                                                className="analytics-form-fields"
                                                type="text"
                                                onChange={this.handleEmailChange('emailAdd')}
                                                onBlur={this.handleBlurEmail}
                                                onClick={this.analyticsLastTouch}
                                                required
                                                value={emailAdd || ''}
                                                labelsm={4}
                                                valuesm={5}
                                                maxLength="50"
                                            />
                                            {
                                                (errorMsg.emailAdd || (receivedEmailOTP.errorCode !== '0' && receivedEmailOTP.result)) &&
                                                (
                                                    <p
                                                        className={classes.errMsg}
                                                        id="errorMessage"
                                                        role="alert"
                                                        aria-live="polite"
                                                        aria-atomic="true"
                                                    >
                                                        {errorMsg.emailAdd}
                                                    </p>
                                                )
                                            }

                                            <WButton
                                                active={false}
                                                block={false}
                                                disabled={(otpDetails.emailAddress === emailAdd) || errorMsg.emailAdd || verifyEmailBtn}
                                                type='button'
                                                variant='light'
                                                buttontext='Verify Email'
                                                onClick={this.verifyEmail}
                                                buttonstyle={assignObj({ float: 'right', marginTop: 10 })}
                                            />
                                        </Col>
                                    </Row>


                                    {(showEnterOtp&& !emailOtpVerified) && (
                                        <Row>
                                            <Col md lg xl>
                                                <div className="emailOTPContainer">
                                                    <SecurityCodeInfo>
                                                        <b>{data.securityCodeSent}</b>
                                                    </SecurityCodeInfo>
                                                    <WInput
                                                        label="Enter Security Code"
                                                        id="EmailOTP"
                                                        name="EmailOTP"
                                                        required
                                                        value={EmailOTP}
                                                        type="password"
                                                        labelstyle={assignObj({ width: '100%' })}
                                                        valuesm={12}
                                                        labelsm={12}
                                                        onChange={this.handleEmailChange('EmailOTP')}
                                                        onClick={this.analyticsLastTouch}
                                                        maxlength={6}
                                                        errortext={errorMsg.EmailOTP}
                                                        inputfieldstyle={assignObj({borderColor: '#CCC' , borderWidth: '1px', borderStyle:'solid' })}
                                                    />
                                                </div>

                                                <button id="resendOtp" type="button" style={data.style.button} onClick={this.reVerifyEmail}>
                                                    {data.resendText}
                                                </button>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <VerifyPhoneRow className="eleCardWrapComponent mb-4">
                                    <Row>
                                        <Col xs={12} sm={12}>
                                    <p className="fieldTitle">{data.PrimaryPhoneNumber}</p>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <PhoneField
                                                phoneNumber={primaryPhone}
                                                type="primaryPhone"
                                                showPhoneError={showPhoneError}
                                                mobileOpt={isMobilePrimary}
                                                countryCode={primaryPhoneCountryCode}
                                                setMobileOpt={this.storeMobileOptChange}
                                                onParentBlur={this.handlePhoneBlur}
                                                clicked={this.analyticsLastTouch}
                                            />
                                        </Col>
                                    </Row>
                                </VerifyPhoneRow>
                                <VerifyPhoneRow className="eleCardWrapComponent mb-4">
                                    <p className="fieldTitle">{data.SecondaryPhoneNumber}</p>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <PhoneField
                                                phoneNumber={secondaryPhone}
                                                type="secondaryPhone"
                                                showPhoneError={showPhoneError}
                                                mobileOpt={isMobileSecondary}
                                                countryCode={secondaryPhoneCountryCode}
                                                setMobileOpt={this.storeMobileOptChange}
                                                onParentBlur={this.handlePhoneBlur}
                                                clicked={this.analyticsLastTouch}
                                                isOptional
                                            />
                                        </Col>
                                    </Row>
                                </VerifyPhoneRow>
                            </Container>
                        </Col>
                    </Row>   
                    <CommonButtons backstyle={styles.hide} backClick={this.onBack} nextClick={this.handleButtonClick} disabled={nextDisabled} blockstyle={styles.commonButtons} />
                </div>
            </div>
        );
    }
}

UserDetails.propTypes = {
    otpDetails: PropTypes.shape({
        primaryNumber: PropTypes.string,
        secondaryNumber: PropTypes.string,
        emailAddress: PropTypes.string,
        guid: PropTypes.string,
        role: PropTypes.string,
        isMobilePrimary: PropTypes.bool,
        isMobileSecondary: PropTypes.bool,
        primaryNumberCountryCode: PropTypes.string,
        secondaryNumberCountryCode: PropTypes.string,
    }),
    receivedEmailOTP: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    isEmailOTPValidated: PropTypes.bool,
    validateEmailErrorCode: PropTypes.string,
    isLoading: PropTypes.bool,
    directSendEmailOTP: PropTypes.func,
    pageData: PropTypes.shape({
        stepperPage: PropTypes.array, 
        isMemberSignUp: PropTypes.bool,
        isProspectSignUp: PropTypes.bool,
    }),
    userFields: PropTypes.shape({
        email: PropTypes.string,
    }),
    validatePassCode: PropTypes.func,
    directVerifyOTP: PropTypes.shape({
        errorCode: PropTypes.number,
        result: {
            verified: PropTypes.string
        },
    }),
    manageLocalState: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
};

UserDetails.defaultProps = {
    otpDetails: {
        primaryNumber: '',
        secondaryNumber: '',
        emailAddress: '',
        guid: '',
        role: '',
        isMobilePrimary: false,
        isMobileSecondary: false,
        primaryNumberCountryCode: '',
        secondaryNumberCountryCode: '',
    },
    receivedEmailOTP: false,
    history: {},
    isEmailOTPValidated: false,
    validateEmailErrorCode: '',
    isLoading: false,
    directSendEmailOTP: () => { },
    pageData: {
        stepperPage: [], 
        isMemberSignUp: false,
        isProspectSignUp: false,
    },
    userFields: {
        email: ''
    },
    validatePassCode: () => {},
    directVerifyOTP: {
        errorCode: 1,
        result: {
            verified: ''
        }
    },
    manageLocalState: () =>{},
    analyzeFormAdandonment: () =>{},
};

export default UserDetails;
