import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordian, AccordianSymbol } from 'common';
import Tick from 'assets/checkmark.png';

const Flex = styled.div`
    display: flex;
`;
const FlexDiv = styled(Flex)`
    position: relative;
`;

const InstructionDefaultStyle = styled.div`
    background: #fafafa 0% 0% no-repeat padding-box;
    opacity: 1;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
`;

const InstructionTitleStyle = styled(InstructionDefaultStyle)`
    cursor: pointer;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    padding-left: 1.1875rem;
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    transition-delay: 0.35s;
    display: block !important;
    &.expand {
        padding-bottom: 1.875rem;
        transition-delay: 0.35s;
        border-bottom: initial;
        ${FlexDiv} {
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 1rem;
        }
    }
`;
const InstructionBodyStyle = styled(InstructionDefaultStyle)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 1.25rem;
    padding-left: 2.5rem;
    padding-right: 1.25rem;
`;

const SubHeadingStyle = styled.h6`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.3125rem;
`;
export const StyledPara = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: ${props => props.lh || '20'}px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top: ${props => props.mt || '0.9375'}rem;
    margin-bottom: 0.3125rem;
`;
const TickMark = styled.img`
    width: 11px;
    height: 8px;
    margin-right: 1.25rem;
`;

export const InstructionPoints = styled(Flex)`
    align-items: center;
    padding: 1.25rem 0;
    border-bottom: ${props => (props.borderReq ? '1px solid #d2d2d2' : 'none')};
`;
const InstructionTitle = styled.h2`
    margin-left: 0.9375rem;
    color: #486d90;
    font-size: 18px;
`;

// const StyledLink = styled.a``;

const accordianClick = e => {
    e.currentTarget.classList.toggle('expand');
};

const InstructionAIP = props => {
    const {
        instructionsTitle,
        instructionSubheading,
        instructionPara1,
        instructionList,
        instructionPara2,
    } = props;
    return (
        <Accordian
            titleChild={
                <FlexDiv>
                    <AccordianSymbol collapse aria-label={instructionsTitle} />
                    <InstructionTitle>{instructionsTitle}</InstructionTitle>
                </FlexDiv>
            }
            Id="instructions"
            accordionTitleAs={InstructionTitleStyle}
            onAccordianClick={accordianClick}
            openBydefault={false}
        >
            <InstructionBodyStyle>
                {instructionSubheading && <SubHeadingStyle>{instructionSubheading}</SubHeadingStyle>}
                {instructionPara1 && <StyledPara>{instructionPara1}</StyledPara>}
                <div>
                    {instructionList.map((item, index) => (
                        <InstructionPoints borderReq={index < instructionList.length - 1} key={item}>
                            <TickMark src={Tick} alt="tick icon" />
                            <div>{item}</div>
                        </InstructionPoints>
                    ))}
                </div>
                {instructionPara2 && (
                    <StyledPara lh={24} mt={0.625}>
                        {instructionPara2}
                    </StyledPara>
                )}
            </InstructionBodyStyle>
        </Accordian>
    );
};

InstructionAIP.propTypes = {
    instructionsTitle: PropTypes.string,
    instructionSubheading: PropTypes.string,
    instructionPara1: PropTypes.string,
    instructionList: PropTypes.instanceOf(Object),
    instructionPara2: PropTypes.string,
};
InstructionAIP.defaultProps = {
    instructionsTitle: '',
    instructionSubheading: '',
    instructionPara1: '',
    instructionList: [],
    instructionPara2: '',
};

export default InstructionAIP;
