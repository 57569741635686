import React,{useEffect} from 'react'
import { useSelector } from 'react-redux';

const post = "post";
const target = "_blank";
const type = "hidden";


const SingleSignOn = ({SAMLResponse, SSOIndicator, handleSingleSignOnClick}) => {

    useEffect(() => {
        document.forms[0].submit();
        handleSingleSignOnClick()
    }, [])

    // link is provided in configurations api
    const ssoRedirectionLink = useSelector((state) => {
        const { loginData } = state || {}
        const link = loginData.data.response.data.result.find(obj => {
            return obj.key === "REACT_APP_NETXINVESTOR_SSO_URL"
        })
        return link
    });


   
  return (
    <div data-test="singlesignon" id="singlesignon">
        <form method={post} action={ssoRedirectionLink.value} target={target}>
            <input type={type} name="SAMLResponse" value={SAMLResponse} />
            <input type={type} name="SSOIndicator" value={SSOIndicator} />
            
        </form>
    </div>
    )
}


export default SingleSignOn
