import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    accOpeningActions,
    localStateManagementActions,
    loginActions,
    profileSettingsAction,
} from '../../../shared/Actions';
import AccountTypeComponent from './AccountTypeComponent';
import withAccountManagement from '../AccountManagementHOC';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.loginData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...loginActions,
    ...profileSettingsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(AccountTypeComponent)));
