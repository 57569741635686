import { connect } from 'react-redux';
import {
    getTransHolderList,
    setLoadingPage,
    resetTransactionHolder,
    getAccountServiceOptions,
    storeAccountInfoData
} from '../../../shared/Actions/TransactionHistoryActions';
import {
    transactionCompositeDataAction
} from '../../../shared/Actions';
import TransactionHistory from './TransactionHistory';

/**
 * Map State to Props - Maps transactionHolderData from redux state
 * @param {Object} state
 */
const mapStateToProps = state => ({
    transactionHolderData: state.transactionHolderData,
    memberDashboardData: state.memberDashboardData
});

/**
 * Map dispatch to props - For all actions from Transaction history actions
 */
const mapDispatchToProps = {
    getTransHolderList,
    setLoadingPage,
    resetTransactionHolder,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData,
    getAccountServiceOptions,
    storeAccountInfoData
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
