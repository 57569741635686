import ErrorMsg from '../ErrorMsg';
import Flex from '../Flex';
import { getAriaDescribedBy } from 'modules/CustomerManagementModule/utils';
import HintText from '../HintText';
import OffScreenText from '../OffScreenText';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { StyledBoxForInput } from '../InputText';

const DATE_FORMAT = 'MM/DD/YYYY';

const StyledFlex = styled(Flex)`
    max-width: 15.9375rem;
    @media (max-width: 767px) {
        max-width: 100%;
    }
`;

const getCalendarProps = calendarRef => {
    const { current } = calendarRef || {};
    const { setOpen = () => {}, state, input, calendar } = current || {};
    const { open = false } = state || {};
    const { value = '' } = input || {};
    const { componentNode } = calendar || {};
    return { setOpen, open, value, componentNode, input };
};

const InputWithIcon = React.forwardRef((props, ref) => {
    const { calendarRef, iconRef, ...restProps } = props;

    return (
        <div>
            <StyledBoxForInput ref={ref} {...restProps} />
        </div>
    );
});

InputWithIcon.propTypes = {
    calendarRef: PropTypes.instanceOf(Object),
    iconRef: PropTypes.instanceOf(Object),
};

InputWithIcon.defaultProps = {
    calendarRef: null,
    iconRef: null,
};

const onCalendarOpen = calendarRef => () => {
    const { componentNode } = getCalendarProps(calendarRef);
    if (componentNode) {
        const currentMonth = componentNode.querySelector('.react-datepicker__current-month');
        if (currentMonth) {
            currentMonth.parentNode.removeChild(currentMonth);
            currentMonth.setAttribute('aria-live', 'polite');
            currentMonth.setAttribute('aria-atomic', 'true');
        }
        const monthContainer = componentNode.querySelector('.react-datepicker__month-container');
        if (monthContainer) monthContainer.parentNode.insertBefore(currentMonth, monthContainer);
    }
};

const handleCalendarClose = ({ onCalendarClose, calendarRef }) => () => {
    const { input } = getCalendarProps(calendarRef);
    if (document.activeElement !== input) {
        onCalendarClose();
    }
};

const handleKeyDown = ({ calendarRef }) => () => {
    const { open } = getCalendarProps(calendarRef);
    if (open) {
        calendarRef.current.setState({ open: false });
    }
};

const DatePicker = React.forwardRef((props, ref) => {
    const { error, errorTxt, instructionText, srInstructionText, classNames, ...restProps } = props;
    const {
        autoComplete,
        disabled,
        id,
        maxDate,
        minDate,
        name,
        onBlur,
        onChange,
        required,
        selected,
        onCalendarClose,
        filterDate,
        ariaLabelledBy,
        ...inputProps
    } = restProps;

    const hintId = `${id}-hint`;
    const errorId = `${id}-error`;

    const { 'aria-describedby': ariaDescribedBy } = getAriaDescribedBy({
        error,
        errorTxt,
        errorId,
        instructionText,
        hintId,
    });

    const reactDatePickerProps = {
        autoComplete,
        disabled,
        id,
        maxDate,
        minDate,
        name,
        onBlur,
        onChange,
        required,
        selected,
        ariaLabelledBy,
        ariaDescribedBy,
        ariaInvalid: error,
        ariaRequired: required,
        filterDate: typeof filterDate === 'function' ? filterDate : () => {},
    };

    const calendarRef = useRef(null);
    const iconRef = useRef(null);

    // console.log('instructionText',instructionText)
    // console.log('srInstructionText',srInstructionText)
    return (
        <StyledFlex className={classNames} flexDirection="column">
            <ReactDatePicker
                ref={calendarRef}
                customInput={
                    <InputWithIcon
                        ref={ref}
                        as="input"
                        type="text"
                        error={error}
                        calendarRef={calendarRef}
                        iconRef={iconRef}
                        {...inputProps}
                    />
                }
                onKeyDown={handleKeyDown({ calendarRef })}
                chooseDayAriaLabelPrefix=""
                preventOpenOnFocus
                onCalendarOpen={onCalendarOpen(calendarRef)}
                onCalendarClose={handleCalendarClose({ onCalendarClose, calendarRef })}
                calendarClassName="vcm-date-picker-table-semantics"
                {...reactDatePickerProps}
            />
            {!instructionText &&<HintText id="date-format-text" text="MM/DD/YYYY"  srText="MM/DD/YYYY" />}
            {/* !!instructionText && <HintText id={hintId} text={instructionText} srText={srInstructionText} /> */}
            {!!instructionText && <HintText id={hintId} text={props.instructionText} srText={props.instructionText} />}
            {error && !!errorTxt && (
                <ErrorMsg id={errorId} aria-live="assertive" aria-atomic role="alert">
                    <OffScreenText hideTxt="Error:" />
                    <span className="analytics-error-field">{errorTxt}</span>
                </ErrorMsg>
            )}
        </StyledFlex>
    );
});

DatePicker.propTypes = {
    error: PropTypes.bool,
    errorTxt: PropTypes.string,
    instructionText: PropTypes.string,
    srInstructionText: PropTypes.string,
    // Below are the React DatePicker props
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    maxDate: PropTypes.instanceOf(Object),
    minDate: PropTypes.instanceOf(Object),
    name: PropTypes.string,
    classNames: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    onCalendarClose: PropTypes.func,
    filterDate: PropTypes.func,
};

DatePicker.defaultProps = {
    error: false,
    errorTxt: '',
    instructionText: DATE_FORMAT,
    srInstructionText: `Date Format: ${DATE_FORMAT}`,
    // Below are the React DatePicker props
    autoComplete: 'off',
    disabled: false,
    id: 'vcm-date-picker',
    maxDate: new Date('2999-12-31'),
    minDate: new Date('1900-01-01'),
    name: 'vcm-date-picker',
    onBlur: () => {},
    required: false,
    selected: '',
    classNames: '',
    onCalendarClose: () => {},
    filterDate: () => {
        return true;
    },
};

export default DatePicker;