import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
// import LoadingOverlay from 'react-loading-overlay';
// import routeConstants from 'routeConstants';

import checkFormat from 'modules/UserManagementAndSecurityModule/checkFormat';
import Guidelines from 'modules/UserManagementAndSecurityModule/Guidelines';
import { OnlineId } from 'modules/UserManagementAndSecurityModule/Input/OnlineId';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WStepper, WSpinner } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import { checkValidity } from '../../../../utils';
import styles from '../../UserManagementStyles';
import data from './Constants';
import pages from '../JovConstants';

class JovRegisterOnlineId extends Component {

  data = {
    title: "Create your Online Id",
    your: "Your ",
    id: "Online ID",
    created: " is successfully created."
  }

  constructor(props) {
    super(props);
    this.state = {
      cancelled: false,
      form: {
        onlineId: {
          value: '',
          errMsg: null,
          valid: false,
          label: 'Online ID',
          rules: {
            minLength: 5,
            maxLength: 20,
            pattern: new RegExp('^[a-zA-Z0-9]*$'),
            patternMessage: 'The Online ID does not meet the length and format requirements.',
            required: true
          }
        }
      },
      loading: false,
      formIsValid: false,
      upperLower: false, min: false, symbol: false,
    }
    this.onBack = this.onBack.bind(this);

    this.onNext = this.onNext.bind(this);
  
    this.onChange = this.onChange.bind(this);
  
    this.onBlur = this.onBlur.bind(this);
  }

  

  onChange(event) {
    checkFormat(event, 'onlineId', this);
    inputChangedHandler(event,'onlineId', this);
  }

  onBlur() { inputValidityHandler('onlineId', this); }

  onBack() {
    const { manageLocalState } = this.props;
    const idData = { JovRegisterOnlineIdState: { ...this.state } };
    manageLocalState(idData);
    this.setState((state) => ({ ...state, cancelled: true }));
  }



  onNext() {
    const { formIsValid, form } = this.state;
    const { history } = this.props;
    this.setState((state) => ({ ...state, loading: true }));
    if (formIsValid) {

      history.push({
        pathname: '/jov-register-password',
        message: { message: <span>{this.data.your}<strong className="strongMessage">{this.data.id}</strong>{this.data.created}</span> }
      })
    } else {
      const updatedFormState = { ...form }
      const fields = Object.keys(form);
      for (let i = 0; i < fields.length; i += 1) {
        const fieldName = fields[Number(i)]
        const updatedField = { ...updatedFormState[fieldName.toString()] }
        updatedField.errMsg = checkValidity(updatedField);
        updatedFormState[fieldName.toString()] = { ...updatedField }
      }
      this.setState({ form: updatedFormState });
    }
  }

  render() {
    const { cancelled, formIsValid, loading, form } = this.state;
    return (
      <div>
        <WSpinner loading={loading} />
        <div className="container">
          <WStepper className="userWizard" currentPage={4} pages={pages} />
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={this.data.title} />
                <Guidelines state={this.state} type="onlineId" />
            </Col>
            <Col md lg xl>
              <OnlineId
                onlineIdState={form.onlineId}
                changed={this.onChange}
                blurred={this.onBlur} />
            </Col>
          </Row>
        </div>
        <CommonButtons backClick={this.onBack} nextClick={this.onNext} disabled={!formIsValid} blockstyle={styles.commonButtonsHalf} arialabelback="Back to otp step"/>
        {cancelled ? <Redirect to={data.route} /> : null}
      </div>
    );
  }
}

JovRegisterOnlineId.propTypes = {
  manageLocalState: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
};

JovRegisterOnlineId.defaultProps = {
  manageLocalState: () => {},
  history: []
};

export default JovRegisterOnlineId;