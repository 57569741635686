import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import '../Screens.css';
import SecurityDetailsForm from '../../SecurityDetails/SecurityDetailsForm';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons } from '../../../../common';
import { changedHandler, validityHandler } from '../../FormUtils';
import styles from '../../UserManagementStyles'; 

class SecurityDetails extends Component {
    data = {
        title: 'Enter Security Details',
        info: ["Answer to the security questions"],
        message: 'You have entered wrong answer',
        city: 'Hyderabad',
        welcome: 'Welcome ',
        signedAs: ' to Victory Capital. You have successfully logged as '
    }

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            errMsg: null,
            valid: false,
            touched: false,
            rules: {
                required: true
            }
        }
    
        this.onNext = this.onNext.bind(this);

        this.onChange = this.onChange.bind(this);
    
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(event) { changedHandler(event, this); }

    onBlur() { validityHandler(this); }

    onBack = () => this.setState((state) => ({ ...state, cancelled: true }));

    onNext() {
        const { history } = this.props;
        const { value } = this.state;
        let userName = "User Name";
        if (localStorage.getItem("token") && localStorage.getItem("userId")) {
          userName = localStorage.getItem("userId")
        }
        if (value === this.data.city) {
            history.push({
                pathname: '/Profile',
                message: {
                  message: <span>{this.data.welcome}<strong>{userName}</strong>{this.data.signedAs}<strong>{userName}</strong></span>
                }
              });
        } else {
            this.setState((state) => ({ ...state, errMsg: 'You have entered wrong answer' }))
        }
    }

    render() {
        const { cancelled } = this.state;
        return (
            <div className="container-fluid">
                <div className="container">
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader
                                title={this.data.title}
                                info={this.data.info} />
                        </Col>
                        <Col md lg xl>
                            <SecurityDetailsForm
                                securityDetailsFormState={this.state}
                                changed={this.onChange}
                                blurred={this.onBlur} />
                        </Col>
                    </Row>
                </div>
                <CommonButtons backClick={this.onBack} nextClick={this.onNext} blockstyle={styles.commonButtons}/>
                {cancelled ? <Redirect to='/sign-in' /> : null}
            </div>
        );
    }
}

SecurityDetails.propTypes = {
    history: PropTypes.shape([])
}

SecurityDetails.defaultProps = {
    history: []
}

export default SecurityDetails;