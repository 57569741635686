import React, { useRef, Component, useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { ReactComponent as BankIcon } from 'assets/bankNew.svg';
import { ReactComponent as LockIcon } from 'assets/icon-lock-hover.svg';
import { ReactComponent as TickMark } from 'assets/blueTick.svg';
import WireIcon from 'assets/wiretransfer.png';

import Accordian from '../Accordian';
import WSpinner from '../WSpinner/WSpinner';
import WCard from '../WCard/WCard';
import MaskText from '../MaskText/MaskText';
import ConfirmModal from '../ConfirmModal';
import TransactionLimitModal from '../TransactionLimitModal';
import AccordianTitle from '../AccordianTitle';
import StyledButton from '../StyledComponents';
import * as Constants from '../Constants';
import { RECURRING_TRANSFER_PLAN } from './../../modules/AccountOptions/constant';
import {
    TRX_MSR_LIMIT,
    VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION,
    VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION_STAR,
    VALIDATE_TEXT_MSR_WARNING_MESSAGE,
    VALIDATE_TEXT_MSR_WARNING_MESSAGE2,
    VALIDATE_TEXT_MSR_WARNING_MESSAGE_LINK_TEXT,
    SEVEN_DAY_BANK_RULE_WARNING_MESSAGE,
    OVERRIDE_PROCESS_URL

} from '../../modules/TransactionModule/TransactionConstants';

const { USAA_FUND_COMPANY_NUMBER, LIQ_TEXT, INSTRUCTIONS_TEXT, OK_TEXT, LIQ_ADDRESS_CHANGE_TEXT } = Constants;
const displayNoneStyle = { display: 'none' };

const WireTransfer = styled.img`
    padding-right: 0.625rem;
`;

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const cardBodyStyle = {
    paddingBottom: 0,
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
};

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const FundFromDesc = styled.h3`
    margin-bottom: 2.5rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const Orseperator = styled.div`
    position: relative;
    margin: 40px 0px;
    text-indent: 50%;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const AccountNameText = styled.div`
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: ${props => (props.bankAccDisplayRule === 'disabled' ? '#808080' : '#486d90')};
    opacity: 1;
    line-height:inherit;
`;
const AccountNumberText = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${props => (props.bankAccDisplayRule === 'disabled' ? '#808080' : '#49494a')};
    opacity: 1;
    margin-top: 0.25rem;
`;
const AccountErrorMessage = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #ff0000;
    opacity: 1;
    margin-left: 6px;
    margin-top: 4px;
`;
const BankAccDetail = styled(FlexRowDiv).attrs(({ cardId }) => ({ 'aria-describedby': cardId }))`
    margin: ${props => (props.method === 'offline' ? '2.125rem' : '1.5rem')} 1.25rem;
`;
const BankcardWrapper = styled(FlexRowDiv)`
    flex-wrap: wrap;
`;

export const BanckAccCard = styled(StyledButton)`
    display: flex;
    position: relative;
    cursor: ${props => (props.bankAccDisplayRule === 'disabled' ? 'default !important' : 'pointer')};
    width: 21.875rem;
    min-height: 130px;
    border: ${props => (!props.selected ? '1px solid #D2D2D2' : '2px solid #004A98')};
    box-shadow: ${props => (props.selected ? '0px 0px 16px #EEF5DD' : 'none')};
    outline: ${props => (props.selected ? '0' : '1px solid #D2D2D2')};
    background: ${props =>
        props.selected ? '#FAFAFA 0% 0% no-repeat padding-box' : '#ffffff 0% 0% no-repeat padding-box'};
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -1px;
        left: -1px;
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')};
            top: 0px;
            left: 0px;
        }
    }
    &:hover {
        outline: ${props => (props.selected ? '1px solid #004A98' : '1px solid #3c5a77')};
        @media (max-width: 767px) {
            outline: none;
        }
    }
    &:focus {
        outline: ${props => (props.selected ? '1px solid #004A98' : '5px auto -webkit-focus-ring-color')};
        @media (max-width: 767px) {
            outline: none;
        }
    }
`;

const GreenTick = styled(TickMark)`
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
`;


const NameNumberWrapper = styled(FlexColumnDiv)`
    align-self: center;
    margin-left: ${props => (props.marginL ? '1.25rem' : 0)};
`;
const TobeVerified = styled.div`
    text-align: left;
    font: Medium 14px/20px Benton Sans;
    letter-spacing: 0;
    color: #56565a;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;

const VerifyAlert = styled.div`
    background: #e60000 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
`;
const VerifyAlertText = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    color: #ffffff;
    opacity: 1;
    padding-left: 8px;
    padding-bottom: 2px;
`;
const VerifyDiv = styled.div`
    display: flex;
    margin-top: 5px;
`;

const KEYCODE = {
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    SPACE: 32,
    UP: 38,
};

export const onRadioKeyDown = ({
    index,
    elementsRef,
    cardDetails,
    bankAccDisplayRule,
    onCardClick
}) => event => {
    let nextIndex = 'default';
    switch (event.keyCode) {
        case KEYCODE.DOWN:
        case KEYCODE.RIGHT:
            nextIndex = (index + 1) % cardDetails.length;
            break;

        case KEYCODE.UP:
        case KEYCODE.LEFT:
            nextIndex = index ? index - 1 : cardDetails.length - 1;
            break;

        case KEYCODE.SPACE:
            nextIndex = index;
            break;
        default:
            break;
    }
    if (nextIndex !== 'default') {
        onCardClick(nextIndex, bankAccDisplayRule)(event)
        elementsRef.current[+nextIndex].current.focus();
    }
}

const BankAccountDetail = props => {
    const { details, method, bankAccDisplayRule, cardId } = props;
    const { text1 = '', text2 = '' } = details;
    const tobeVerifiedText = 'Verifying for your security';
    return (
        <BankAccDetail method={method}>
            {method === 'online' && text2 && <BankIcon />}
            {method === 'offline' && <WireTransfer src={WireIcon} />}
            <NameNumberWrapper marginL={text2}>
                <AccountNameText bankAccDisplayRule={bankAccDisplayRule}>{text1}</AccountNameText>
                {method !== 'offline' && text2?.toString().trim() && (
                    <AccountNumberText bankAccDisplayRule={bankAccDisplayRule}>
                        <MaskText text={text2?.toString()} startIndex={0} maskLength={6} id={cardId} />
                        <FlexRowDiv>
                            {bankAccDisplayRule === 'disabled' ? (
                                <FlexRowDiv>
                                    <LockIcon /><AccountErrorMessage>{tobeVerifiedText}</AccountErrorMessage>
                                </FlexRowDiv>
                            ) : (
                                ''
                            )}
                        </FlexRowDiv>
                    </AccountNumberText>
                )}
            </NameNumberWrapper>
        </BankAccDetail>
    );
};
BankAccountDetail.propTypes = {
    details: PropTypes.instanceOf(Object),
    method: PropTypes.string,
    bankAccDisplayRule: PropTypes.string,
};
BankAccountDetail.defaultProps = {
    details: {},
    method: '',
    bankAccDisplayRule: '',
};

const getBankDisplayRules = (bankDisplayRules, createdDate) => {
    const currentDate = new Date();
    const tempAccCreatedDate = createdDate.substring(0, createdDate.indexOf('+'));
    const accCreatedDate = new Date(tempAccCreatedDate);
    const diffDays = Math.ceil((currentDate - accCreatedDate) / (1000 * 60 * 60 * 24));
    let displayRule = '';
    return displayRule;
};

const MethodCard = props => {
    const { cardDetails, onCardClick, method, cursorStyle, tobeVerifiedflag,
        bankDisplayRules, liquidationData, handleNextButton,
        checkHandleNextButtonCallCount } = props;
    const tobeVerifiedText = 'To be verified';
    const tobeVerifiedAlert = '!';
    let details;
    let bankAccDisplayRule = '';

    const [radioOptions, setRadioOptions] = useState([]);
    const [cardDetailsData, setCardDetailsData] = useState([]);
    const [countState, setCountState] = useState(0);
    const elementsRef = useRef();


    useEffect(() => {

        setCardDetailsData(cardDetails);
        if (cardDetails.length > 0 && cardDetailsData.length > 0 && checkHandleNextButtonCallCount == 0 &&
            liquidationData != undefined && liquidationData != null &&
            liquidationData.page2 != undefined && liquidationData.paymentMethod.length > 0 &&
            liquidationData.paymentMethod[0].details != null) {
            if (liquidationData.page2.offlineMode == null) {
                for (let i = 0; i < cardDetails.length; i++) {
                    if (liquidationData.paymentMethod[0].details.salesforceRecordId == cardDetailsData[i].salesforceRecordId) {
                        cardDetailsData[i]['selected'] = true;
                        handleNextButton(cardDetailsData[i], cardDetailsData, 'Online', i);
                    }
                    else {
                        cardDetailsData[i]['selected'] = false;
                    }
                    setCountState(1);
                }
            }
            else {
                if ((liquidationData.paymentMethod[0].details == 'Check' || liquidationData.paymentMethod[0].details.text == "Check") && 
                    cardDetailsData[0].text != undefined) {
                    cardDetailsData[0]['selected'] = true;
                    setCountState(1);
                    handleNextButton(cardDetailsData[0], cardDetailsData, 'Offline');
                }

            }

        }

    })

    const myOptions = useRef(cardDetailsData);
    const isInitialSet = useRef(false);

    if (!isInitialSet.current || cardDetailsData !== myOptions.current) {
        myOptions.current = cardDetailsData;

        if (cardDetailsData.length) {
            console.log('fundsource')
            isInitialSet.current = true;
            elementsRef.current = [];
            const newList = cardDetailsData.forEach((item, index) => {
                elementsRef.current[+index] = createRef();
            });
            setRadioOptions(newList);
        }
    }
    return (
        <>
        <BankcardWrapper
                role="radiogroup">
                {cardDetailsData.map((acc, index) => {
                    details = {};
                    if (method === 'offline') {
                        details.text1 = acc.text;
                    } else {
                        details.text1 = acc.bankName || `Bank Account ${index + 1}`;
                        details.text2 = acc.bankAccountNumber || ' ';
                        if (acc.CreatedDate != undefined && acc.CreatedDate != null) {
                            bankAccDisplayRule = getBankDisplayRules(bankDisplayRules, acc.CreatedDate);
                        }

                    }
                    return (
                        <div class="col-12 col-md-6 col-lg-4 my-3" key={`bank-acc-card${index}`}>
                            <BanckAccCard
                                onClick={onCardClick(index, bankAccDisplayRule)}
                                selected={!!acc.selected}
                                key={details.text1 + details.text2 + index}
                                cursorStyle={cursorStyle}
                                marginReq={index < cardDetailsData.length - 1}
                                aria-selected={!!acc.selected}
                                bankAccDisplayRule={bankAccDisplayRule}
                                role="radio"
                                tabIndex={!!acc.selected === true ? "0" : "-1"}
                                aria-checked={!!acc.selected}
                                onKeyDown={onRadioKeyDown({
                                    index,
                                    elementsRef,
                                    cardDetailsData,
                                    bankAccDisplayRule,
                                    onCardClick
                                })}
                                ref={elementsRef.current[+index]}
                            >
                                {!!acc.selected && <GreenTick />}
                                <BankAccountDetail
                                    method={method}
                                    details={details}
                                    cardId={acc.bankAccountNumber}
                                    bankAccDisplayRule={bankAccDisplayRule}
                                />
                            </BanckAccCard>
                        </div>
                    );
                })}
            </BankcardWrapper>
            {method === 'online' && tobeVerifiedflag && (
                <VerifyDiv>
                    <VerifyAlert>
                        <VerifyAlertText>{tobeVerifiedAlert}</VerifyAlertText>
                    </VerifyAlert>
                    <TobeVerified>{tobeVerifiedText}</TobeVerified>
                </VerifyDiv>
            )}
        </>
    );
};
MethodCard.propTypes = {
    method: PropTypes.string,
    cardDetails: PropTypes.instanceOf(Object),
    onCardClick: PropTypes.func,
    cursorStyle: PropTypes.string,
    tobeVerifiedflag: PropTypes.bool,
    bankDisplayRules: PropTypes.instanceOf(Object),
};
MethodCard.defaultProps = {
    method: '',
    cardDetails: [],
    onCardClick: () => { },
    cursorStyle: '',
    tobeVerifiedflag: false,
    bankDisplayRules: {},
};

const MethodDesc = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const FundMethod = props => {
    const { desc, cardDetails, onCardClick, method, cursorStyle, bankDisplayRules, liquidationData, handleNextButton, checkHandleNextButtonCallCount } = props;
    return (
        <>
            {desc && <MethodDesc>{desc}</MethodDesc>}
            <MethodCard
                bankDisplayRules={bankDisplayRules}
                method={method}
                cardDetails={cardDetails}
                onCardClick={onCardClick}
                cursorStyle={cursorStyle}
                liquidationData={liquidationData}
                handleNextButton={handleNextButton}
                checkHandleNextButtonCallCount={checkHandleNextButtonCallCount}

            />
        </>
    );
};
FundMethod.propTypes = {
    desc: PropTypes.string,
    method: PropTypes.string,
    cardDetails: PropTypes.instanceOf(Object),
    onCardClick: PropTypes.func,
    cursorStyle: PropTypes.string,
    bankDisplayRules: PropTypes.instanceOf(Object),
};
FundMethod.defaultProps = {
    desc: '',
    method: '',
    cardDetails: [],
    onCardClick: () => { },
    cursorStyle: '',
    bankDisplayRules: {},
};

class BankCardSec extends Component {
    static checkNextStatus(data) {
        return !data;
    }

    constructor(props) {
        super(props);
        const { pageIndex, fundFrom } = this.props;
        this.state = {
            retrivedState: false,
            selectedBank: null,
            showOptions: false,
            bankAccounts: [],
            offlineCards: [],
            displayDetails: {
                fundFrom: {
                    titleTxt: fundFrom.titleTxt || 'Fund From',
                    editTxt: 'Edit',
                    pageIndex,
                    data: [],
                },
            },
            // not used here, But used in parent
            disableNext: true,
            bankDisplayRules: [],
            shouldValidateACHLimit: props.shouldValidateACHLimit,
            checkTrxMsrLimit: false,
            checkSevenDayRulePopup: false,
            checkHandleNextButtonCallCount: 0
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        const { savedState, fundFrom, plan, bankDisplayRules, validateACHLimitResponseData,flow } = nextProps;

        if (flow && !prevState.bankAccounts.length && savedState.bankAccounts == undefined) {
            return {
                ...savedState,
                retrivedState: true,
                bankAccounts: fundFrom.methods[0].details,
                achAccepted: validateACHLimitResponseData.achAccepted,
                achCustMessage: validateACHLimitResponseData.custMessage,
                isLoading: validateACHLimitResponseData.isLoading,
            };
        }

        if(prevState.retrivedState == false && savedState !=null)
        {
            return {
                ...savedState,
                retrivedState: true,
                achAccepted: validateACHLimitResponseData.achAccepted,
                achCustMessage: validateACHLimitResponseData.custMessage,
                isLoading: validateACHLimitResponseData.isLoading,
            };
        }

        if (!prevState.bankAccounts.length) {
            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                if (fundFrom.methods[0].details.length) {
                    const bankAccounts = fundFrom.methods[0].details;
                    return {
                        ...prevState,
                        bankAccounts,
                        isLoading: validateACHLimitResponseData.isLoading,
                        achAccepted: validateACHLimitResponseData.achAccepted,
                        achCustMessage: validateACHLimitResponseData.custMessage,
                    };
                }
            } else if (fundFrom.methods[1].details.length) {
                
                // This will be used when 'Add Bank Account' feature is implemented
                return {
                    bankAccounts: fundFrom.methods[1].details,
                    offlineCards: fundFrom.methods[0].details,
                    bankDisplayRules,
                    isLoading: validateACHLimitResponseData.isLoading,
                    achAccepted: validateACHLimitResponseData.achAccepted,
                    achCustMessage: validateACHLimitResponseData.custMessage,
                };
            }
        }

        return {
            ...prevState,
            achAccepted: validateACHLimitResponseData.achAccepted,
            achCustMessage: validateACHLimitResponseData.custMessage,
            isLoading: validateACHLimitResponseData.isLoading,
        };
    }

    handleNextButton = (selectedBank, bankAccounts, method, index) => {
        const { handleNextButtonDisable, toggleBank, setPaymentMethod, showOfflineModeOptions } = this.props;
        if (method == 'Online') {
            this.setState({
                disableNext: false,
                checkHandleNextButtonCallCount: 1,
                selectedBank: selectedBank,
                bankAccounts: bankAccounts
            });
            handleNextButtonDisable();

            toggleBank(selectedBank);
            const paymentMethodDetails = [{ mode: 'Online', details: selectedBank }];
            setPaymentMethod(paymentMethodDetails);
            let displayData = [];
            displayData.push(
                {
                    field: 'Funding Source',
                    value: selectedBank.bankName || `Bank Account ${index + 1}`,
                },
                {
                    field: 'Account Number',
                    formattedValue: selectedBank.bankAccountNumber ? (
                        <MaskText
                            text={selectedBank.bankAccountNumber.toString()}
                            startIndex={0}
                            maskLength={6}
                        />
                    ) : (
                        selectedBank.bankAccountNumber
                    ),
                    value: selectedBank.bankAccountNumber,
                },
            );

            const { fundFrom } = this.state.displayDetails;
            fundFrom.data = displayData;
        }
        else {
            this.setState({
                disableNext: false,
                checkHandleNextButtonCallCount: 1,
                selectedBank: selectedBank,
                showOptions: true
            });
            handleNextButtonDisable();

            let showOptions = true;
            showOfflineModeOptions(0, showOptions, true);

            const paymentMethodDetails = [{ mode: 'Offline', details: selectedBank }];
            setPaymentMethod(paymentMethodDetails);
        }
    }

    checkAndShowSevenDayPopUp = (selectedBank) => {

        if (selectedBank != null) {
            let createdDateVal = new Date(selectedBank.CreatedDate);
            let todayData = new Date();
            let diffDate = Math.floor((Date.UTC(todayData.getFullYear(), todayData.getMonth(), todayData.getDate()) - Date.UTC(createdDateVal.getFullYear(), createdDateVal.getMonth(), createdDateVal.getDate())) / (1000 * 60 * 60 * 24));
            if (diffDate <= 7) {
                this.setState({
                    checkSevenDayRulePopup: true,
                })
            }
        }
    }

    onBankAccclick = (index, disabled) => e => {
        if (disabled !== 'disabled') {
            e.stopPropagation();
            const { showOptions } = this.state;
            this.setState(
                prevState => {
                    const { bankAccounts, displayDetails, offlineCards } = prevState;
                    let { selectedBank } = prevState;
                    const { fundFrom } = displayDetails;
                    const displayData = [];
                    selectedBank = null;
                    const bnkAcc = bankAccounts.map((eachacc, accindex) => {
                        const acc = { ...eachacc };
                        if (index === accindex) {
                            acc.selected = !acc.selected;
                        } else {
                            acc.selected = false;
                        }
                        if (acc.selected) {
                            selectedBank = acc;
                            displayData.push(
                                {
                                    field: 'Funding Source',
                                    value: acc.bankName || `Bank Account ${index + 1}`,
                                },
                                {
                                    field: 'Account Number',
                                    formattedValue: acc.bankAccountNumber ? (
                                        <MaskText
                                            text={acc.bankAccountNumber.toString()}
                                            startIndex={0}
                                            maskLength={6}
                                        />
                                    ) : (
                                        acc.bankAccountNumber
                                    ),
                                    value: acc.bankAccountNumber,
                                },
                            );
                        }
                        return acc;
                    });
                    fundFrom.data = displayData;
                    const disableNext = this.constructor.checkNextStatus(selectedBank);
                    const newState = {
                        bankAccounts: bnkAcc,
                        selectedBank,
                        displayDetails,
                        disableNext,
                    };
                    if (showOptions) {
                        // Resetting showOptions
                        newState.showOptions = false;
                        // Resetting offline cards selection
                        newState.offlineCards = offlineCards.map(item => {
                            const acc = { ...item };
                            acc.selected = false;
                            return acc;
                        });
                    }
                    return newState;
                },
                () => {
                    const {
                        checkStatus,
                        toggleBank,
                        showOfflineModeOptions,
                        validateACHLimit,
                        accountNumber,
                        totalSubmitAmount,
                        setPaymentMethod,
                    } = this.props;
                    const { selectedBank } = this.state;
                    if (!showOptions) checkStatus('bankSelection');
                    if (showOptions) {
                        showOfflineModeOptions(index, !showOptions, showOptions);
                    }
                    toggleBank(selectedBank);
                    const paymentMethodDetails = [{ mode: 'Online', details: selectedBank }];
                    setPaymentMethod(paymentMethodDetails);
                    const validateACHPayload = {
                        companyNumber: USAA_FUND_COMPANY_NUMBER.companyNumber,
                        txnType: LIQ_TEXT,
                        masterReg: accountNumber,
                        amount: totalSubmitAmount,
                    };
                    validateACHLimit({ validateACHPayload, token: localStorage.getItem('token') });
                    if (totalSubmitAmount >= TRX_MSR_LIMIT) {
                        this.setState({
                            checkTrxMsrLimit: true
                        })
                    }

                    this.checkAndShowSevenDayPopUp(selectedBank)

                },
            );
        }
        return '';
    };

    onOflineWayClick = index => e => {
        e.stopPropagation();
        const { selectedBank } = this.state;
        const { fundFrom } = this.props;
        this.setState(
            prevState => {
                const { offlineCards, displayDetails, bankAccounts } = prevState;
                const check = fundFrom.methods[0].details;
                const offlineCardsData = offlineCards.length > 0 ? offlineCards : check;
                let { showOptions } = prevState;
                showOptions = false;
                const offCards = offlineCardsData.map((eachacc, accindex) => {
                    const acc = { ...eachacc };
                    if (index === accindex) {
                        acc.selected = !acc.selected;
                    } else {
                        acc.selected = false;
                    }
                    if (acc.selected) {
                        showOptions = true;
                    }
                    return acc;
                });
                const newState = { offlineCards: offCards, showOptions };
                if (selectedBank) {
                    // Resetting selected Bank
                    newState.selectedBank = null;
                    // Resetting display details
                    displayDetails.fundFrom.data = [];
                    newState.displayDetails = displayDetails;
                    // Resetting bank acc selected
                    newState.bankAccounts = bankAccounts.map(item => {
                        const acc = { ...item };
                        acc.selected = false;
                        return acc;
                    });
                }

                const disableNext = this.constructor.checkNextStatus(showOptions);
                newState.disableNext = disableNext;

                return newState;
            },
            () => {
                const { showOfflineModeOptions, setPaymentMethod } = this.props;
                const { showOptions } = this.state;
                showOfflineModeOptions(index, showOptions, true);
                const paymentMethodDetails = [{ mode: 'Offline', details: 'Check' }];
                setPaymentMethod(paymentMethodDetails);
            },
        );
    };

    getComponentState() {
        const { state = {} } = this || {};
        return { ...state };
    }

    redirectSection = () => {
        const { cancelACHLimit, disableNextForACH } = this.props;
        const { bankAccounts } = this.state;
        const accounts = [...bankAccounts];
        let initialBankAccounts = '';
        initialBankAccounts = accounts.map(acc => {
            acc.selected = false;
            return acc;
        });
        this.setState({
            bankAccounts: initialBankAccounts,
            disableNext: true,
            selectedBank: null,
        });
        disableNextForACH();
        cancelACHLimit();
    };


    handleMsrTrxLimit = () => {
        this.setState({ checkTrxMsrLimit: false })
    }

    handleSevenDayRule = () => {
        this.setState({ checkSevenDayRulePopup: false })
    }

    render() {
        const { fundFrom, forwardRef, checkAddressValid = true, liquidationData } = this.props;
        const {
            bankAccounts,
            offlineCards,
            selectedBank,
            showOptions,
            bankDisplayRules,
            isLoading,
            showConfirmModal,
            achCustMessage,
            shouldValidateACHLimit,
        } = this.state;
        // console.log('call1',bankAccounts)
        const checkDetails = offlineCards.length === 0 ? fundFrom.methods[0].details : offlineCards;
        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                {showConfirmModal && shouldValidateACHLimit && (
                    <ConfirmModal
                        modalTitle={INSTRUCTIONS_TEXT}
                        modalBodyText={ReactHTMLParser(achCustMessage)}
                        primaryButtonText={OK_TEXT}
                        onPrimaryClick={this.redirectSection}
                    />
                )}

                {this.state.checkTrxMsrLimit && (
                    <TransactionLimitModal
                        modalTitle={VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION}
                        modalBodyText={VALIDATE_TEXT_MSR_WARNING_MESSAGE}
                        modalBodyText2={VALIDATE_TEXT_MSR_WARNING_MESSAGE2}
                        modalBodyLink1Text={VALIDATE_TEXT_MSR_WARNING_MESSAGE_LINK_TEXT}
                        modalBodyLink1Url={OVERRIDE_PROCESS_URL}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleMsrTrxLimit}
                    />
                )}

                {( this.state.checkSevenDayRulePopup && 
                this.props.componentName == undefined) && (
                    <TransactionLimitModal
                        modalTitle={VALIDATE_TEXT_MSR_WARNING_MESSAGE_ATTENTION_STAR}
                        modalBodyText={SEVEN_DAY_BANK_RULE_WARNING_MESSAGE}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSevenDayRule}
                    />
                )}

                <Accordian
                    ref={forwardRef}
                    key={fundFrom.detailName}
                    titleChild={
                        <AccordianTitle
                            accType={fundFrom.detailName}
                            id={`${fundFrom.detailName.replace(/\s/g, '')}_title`}
                            accIndex={0}
                            accHeaderAs="button"
                        />
                    }
                    titleSeperator
                    aria-labelledby={fundFrom.detailName}
                    aria-controls={fundFrom.detailName}
                    Id={fundFrom.detailName}
                    role="radiogroup"
                >
                    <FlexColumnDiv>
                        <FundFromDesc>{fundFrom.description}</FundFromDesc>
                        {fundFrom.methods.map((item, index) => {
                            let details = item.method === 'online' ? bankAccounts : offlineCards;
                            if (bankAccounts.length < 1 && item.method === 'offline') {
                                details = checkDetails;
                            }
                            const onCardClick = item.method === 'online' ? this.onBankAccclick : this.onOflineWayClick;
                            let cursorStyle = 'pointer';
                            if (
                                (selectedBank && item.method === 'offline') ||
                                (showOptions && item.method === 'online')
                            ) {
                                cursorStyle = 'default';
                            } 
                            return (
                                <React.Fragment key={item.method}>
                                    {index > 0 && bankAccounts && bankAccounts.length > 0 && (
                                        <Orseperator>{fundFrom.orSeperator}</Orseperator>
                                    )}
                                    {!checkAddressValid && item.method === 'offline' ? (
                                        <WCard
                                            buttonstyle={displayNoneStyle}
                                            cardtitletext={LIQ_ADDRESS_CHANGE_TEXT}
                                            cardtextstyle={cardTextStyle}
                                            cardbodystyle={cardBodyStyle}
                                            cardtitlestyle={cardTitleStyle}
                                            linkcard="true"
                                            leftcornercard={cardBorderStyle}
                                            alt=""
                                            src=""
                                            selected
                                        />
                                    ) : (
                                        <FundMethod
                                            title={item.title}
                                            method={item.method}
                                            cardDetails={details}
                                            onCardClick={onCardClick}
                                            cursorStyle={cursorStyle}
                                            desc={item.desc || ''}
                                            bankDisplayRules={bankDisplayRules}
                                            liquidationData={liquidationData}
                                            handleNextButton={this.handleNextButton}
                                            checkHandleNextButtonCallCount={this.state.checkHandleNextButtonCallCount}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </FlexColumnDiv>
                </Accordian>
            </>
        );
    }
}

BankCardSec.propTypes = {
    fundFrom: PropTypes.instanceOf(Object),
    plan: PropTypes.string.isRequired,
    pageIndex: PropTypes.number.isRequired,
    checkStatus: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    showOfflineModeOptions: PropTypes.func,
    toggleBank: PropTypes.func,
    forwardRef: PropTypes.instanceOf(Object).isRequired,
    bankDisplayRules: PropTypes.instanceOf(Object),
    validateACHLimit: PropTypes.func,
    cancelACHLimit: PropTypes.func,
    totalSubmitAmount: PropTypes.number,
    accountNumber: PropTypes.string,
    validateACHLimitResponseData: PropTypes.instanceOf(Object),
    setPaymentMethod: PropTypes.func,
    shouldValidateACHLimit: PropTypes.bool,
    checkAddressValid: PropTypes.bool,
};

BankCardSec.defaultProps = {
    fundFrom: {},
    checkStatus: () => { },
    // savedState: null,
    showOfflineModeOptions: () => { },
    toggleBank: () => { },
    bankDisplayRules: [],
    validateACHLimit: () => { },
    cancelACHLimit: () => { },
    totalSubmitAmount: 0,
    accountNumber: '',
    validateACHLimitResponseData: {},
    setPaymentMethod: () => { },
    shouldValidateACHLimit: true,
    checkAddressValid: true,
};

export default BankCardSec;
