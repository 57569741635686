import get from 'lodash/get';
import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import { linkResponseSuccess, linkResponseError } from "./AnalyticsActions";
 
const UNKNOWN_ERROR = 'unknown error' ;
export const addSWPDetails = payload => dispatch => {
  const env = "";
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS
  });
  makeApiRequest(
    ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS,
    payload,
    env,
    response => {
      if (response.data) {
        if ( response.data.result && response.data.result.success ) {
          dispatch({
            type: ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_SUCCESS,
            response: response.data.result.message
          });
          dispatch(linkResponseSuccess());
      } else {
          dispatch({
            type: ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_FAILURE,
            response: response.data.result.result
          });
          dispatch(linkResponseError(response.data.result.result));
      }
      }
    },
    error => {
      dispatch({
        type: ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(get(error, 'response.statusText', UNKNOWN_ERROR)));
    }
  );
};

export const getSWPDetails = (payload) => dispatch => {
  const env = "";
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS
  });
  makeApiRequest(
    ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_LOADING,
    payload,
    env,
    response => {
      if (response.data) {
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_SUCCESS,
          response: response.data.result
        });
        dispatch(linkResponseSuccess());
      }else{
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_FAILURE,
          response
        });
        dispatch(linkResponseError(response));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.SYS_WITHDRAWAL_GET_SWP_DETAILS_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(error.data));
    }
  );
};

export const skipSWP = payload => dispatch => {
  const env = "MOCKTEST";
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN
  });
  makeApiRequest(
    ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN,
    payload,
    env,
    response => {
      if (response.data) {
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN_SUCCESS,
          response: response.data
        });
        dispatch(linkResponseSuccess());
      }else{
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN_FAILURE,
          response
        });
        dispatch(linkResponseError(response));
      };
    },
    error => {
      dispatch({
        type: ActionTypes.SYS_WITHDRAWAL_SKIP_SWP_PLAN_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(error.data));
    }
  );
};

export const updateSWPDetails = payload => dispatch => {
  const env = '';
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS
  });
  makeApiRequest(
    ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS,
    payload,
    env,
    response => {
      if (response.data) {
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_SUCCESS
        });
        dispatch(linkResponseSuccess());
      }else{
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_FAILURE,
          response: get(response, 'data', { msg: { response: UNKNOWN_ERROR} }) // will display the default error message
        });
        dispatch(linkResponseError( get(response, 'response.data.responseCode', UNKNOWN_ERROR)));
      }
    },
    error => {
      const errorMsg = get(error, 'response.data', { msg: { response: UNKNOWN_ERROR } }); // will display the default error message
      dispatch({
        type: ActionTypes.SYS_WITHDRAWAL_UPDATE_SWP_DETAILS_FAILURE,
        response: errorMsg
      });
      dispatch(linkResponseError(errorMsg));
    }
  );
};

export const deleteSWP = payload => dispatch => {
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS
  });
  makeApiRequest(
    ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS,
    payload,
    null,
    response => {
      if (response.data) {
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS_SUCCESS,
          response: response.data
        });
        dispatch(linkResponseSuccess());
      } else {
        dispatch({
          type: ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS_FAILURE,
          response: response.message
        });
        dispatch(linkResponseError(response.data));
      }
    },
    error => {
      dispatch({
        type: ActionTypes.SYS_WITHDRAWAL_DELETE_SWP_DETAILS_FAILURE,
        response: error.data
      });
      dispatch(linkResponseError(error));
    }
  );
};

export const clearSWPPlanList = () => dispatch => {
  // DISPATCHING ACTION FOR CLEARING EXISTING PLAN LIST
  dispatch({
    type: ActionTypes.SYS_WITHDRAWAL_ADD_SWP_DETAILS_RESET
  });
};


export const dismissError = () => dispatch => {
  dispatch({
      type: ActionTypes.DISMISS_SYS_WITHDRAWAL_ERROR
  });
};