import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';
import {
    SAVE_PAGE_STATE_FAILURE,
    SAVE_PAGE_STATE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_SUCCESS,
    SET_NEXT_PAGE_NUMBER_FAILURE,
    RESET_PAGE_NUMBER_SUCCESS,
    RESET_PAGE_NUMBER_FAILURE,
    SET_SELECTED_CONSENT_EXCHANGE_SUCCESS,
    SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS,
    SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS,
    RESET_ECONSENT_EXCHANGE_SUCCESS,
    SET_ECONSENT_NAME_EXCHANGE_SUCCESS,
    CLEAR_EXCHANGE_DATA,
    SET_CANCEL_EXCHANGE,
    SET_NOT_TO_CANCEL_EXCHANGE,
    SET_ECONSENT_TIME_EXCHANGE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE,
} from '../ReduxConstants/TransactionActionConstants';

export const saveData = data => dispatch => {
    dispatch({
        type: ActionTypes.SAVE_EXCHANGE_DATA,
        ...data,
    });
};


export const submitExchangeData = payload => dispatch => {
    const env = 'MOCKTEST';
    dispatch({
        type: ActionTypes.SUBMIT_EXCHANGE_DATA_LOADING,
    });
    makeApiRequest(
        ActionTypes.SUBMIT_EXCHANGE_DATA,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.SUBMIT_EXCHANGE_DATA_SUCCESS,
                    [ActionTypes.SUBMIT_EXCHANGE_DATA]: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.SUBMIT_EXCHANGE_DATA_FAILURE,
                    [ActionTypes.SUBMIT_EXCHANGE_DATA]: response.data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.SUBMIT_EXCHANGE_DATA_ERROR,
                response: data,
            });
        },
    );
};
/**
 * Sets the next page number in state
 *
 * @param {Object} payload
 */

export const setNextPageNumber = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Sets the next page number in state when redirected from view positions
 *
 */

export const setNextPageNumberPositions = pageNumber => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_SUCCESS,
                payload: pageNumber,
            });
        } catch (error) {
            dispatch({
                type: SET_NEXT_PAGE_NUMBER_POSITIONS_EXCHANGE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Resets the page number to 1 in state
 *
 * @param {Object} payload
 */

export const resetPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: RESET_PAGE_NUMBER_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: RESET_PAGE_NUMBER_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This is the action to set the state for each page in redux
 */
export const savePageState = payload => {
    return dispatch => {
        try {
            dispatch({
                type: SAVE_PAGE_STATE_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: SAVE_PAGE_STATE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This action is called when the user selects or deselects to esign consent
 */
export const setSelectedConsent = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: SET_SELECTED_CONSENT_EXCHANGE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the user selects or deselects prospectus
 */
export const setSelectedProspectus = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the user selects or deselects disclosure
 */
export const setSelectedDisclosure = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the submit of purchase or exchange is clicked
 */
export const resetEConsent = () => {
    return async dispatch => {
        dispatch({
            type: RESET_ECONSENT_EXCHANGE_SUCCESS,
        });
    };
};

/**
 * This action is called when the submit of purchase or exchange is clicked
 */
export const setConsentName = eConsentName => {
    return async dispatch => {
        dispatch({
            type: SET_ECONSENT_NAME_EXCHANGE_SUCCESS,
            payload: eConsentName,
        });
    };
};

/**
 * This action is called when the submit of purchase or exchange is clicked
 */
export const setConsentTime = eConsentTime => {
    return async dispatch => {
        dispatch({
            type: SET_ECONSENT_TIME_EXCHANGE_SUCCESS,
            payload: eConsentTime,
        });
    };
};

export const clearExchangeData = () => dispatch => {
    dispatch({
        type: CLEAR_EXCHANGE_DATA,
    });
};

export const setCancelExchange = selectedFund => dispatch => {
    dispatch({
        type: SET_CANCEL_EXCHANGE,
        payload: selectedFund,
    });
};

export const setNotToCancelExchange = () => dispatch => {
    dispatch({
        type: SET_NOT_TO_CANCEL_EXCHANGE,
    });
};
