import * as TransactionConstants from '../../TransactionConstants';
import classes from '../Classes.module.css';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes, { bool } from 'prop-types';
import React, { Component } from 'react';
import { SelectDropDown } from 'modules/CustomerManagementModule/components';
import styled from 'styled-components';
import styles from './styles';
import {
    WTable,
    WIcon,
    WInput,
    Accordian,
    AccordianTitle,
    WSpinner,
    VictoryDatePicker,
} from '../../../../common';
import './fundInvestComponent.css';
import {currencyFormatterFunction} from '../../../../common/Formatter/CurrencyFormatter';
import { checkSingleEqualityAndReturnValue, getValidValue } from 'commonHelper/CommonHelperFunctions';
const assignObj = obj => {
    return obj;
};

const MandatoryTextDiv = styled.div`
    padding-bottom: 22px;
    font-size: 14px;
`;

const WTableDesktop = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
`;

const WTableMobile = styled.div`
    @media (min-width: 768px) {
        display: none;
    }
`;


//const isMobile = window.matchMedia('(hover: none) and (pointer: coarse)').matches;



const formatDecimal = val => Number(val).toFixed(2);

class FundInvest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSubAmt1: 0,
            totalMnlyInv1: 0,
            selectedAccounts: [],
            // initialInvestErr: '',
        };
    }

    static getDerivedStateFromProps(props) {
        // DO NOT REMOVE: These two constants will be utilized when composite data API throws error
        // const fundOptionsSub = [
        //     { value: 'Subsequent', key: 'sub' },
        //     {
        //         value: 'Subsequent and Monthly',
        //         key: 'sub_mon',
        //     },
        // ];
        // const fundOptionsInitial = [
        //     { value: 'Initial', key: 'init' },
        //     {
        //         value: 'Initial and Monthly',
        //         key: 'init_mon',
        //     },
        // ];
        const element = document.getElementById('fundUrInv1');
        if (props.selectedFund && element) {
            element.scrollIntoView({
                behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
            });
        }
        let fundOptionSelected = 'init';
        let fundOptions = props.masterLookupData?.fund_optionsv1;
        let isProportionate = false;
        if(props.selectedFund?.[0]?.checked && props.selectedFund?.[0]?.fundName == 'Proportionate'){
            fundOptions = {
                "value": [
                    {
                        "value": "One-time",
                        "key": "sub"
                    }
                ],
                "key": "fund_options_subv1"
            };
            isProportionate = true;
            fundOptionSelected = 'sub';
        }
        if (isProportionate == false &&
            props.selectedFund[0] &&
            props.selectedFund[0].fundAccountNumber &&
            props.selectedFund[0].accountValue &&
            props.selectedFund[0].unissuedShares
        ) {
            if (props.selectedFund[0].accountValue >= 0 && props.selectedFund[0].unissuedShares >= 0) {
                // scenario of purchase happened earlier this morning: Subsequent Purchase
                // full liquidaiton occured earlier today: Subsequent Purchase
                fundOptionSelected = 'sub';
                fundOptions = props.masterLookupData.fund_options_subv1;
            } else{
                console.log('do nothing - negative case for code coverage')
            }
        }else{
            console.log('do nothing - negative case for code coverage')
        }
        return {
            selectedAccounts: props.selectedFund,
            totalSubAmt1: props.totalSubAmt,
            totalMnlyInv1: props.totalMnlyInv,
            fundOptionSelected,
            fundOptions,
            // fundOptions:
            //     props.selectedFund.length && props.selectedFund[0].fundAccountNumber
            //         ? fundOptionsSub
            //         : fundOptionsInitial,
        };
    }

    componentDidMount() {
        const element = document.getElementById('fundUrInv1');
        element?.scrollIntoView({
            behavior: TransactionConstants.BEHVIOUR_SMOOTH_VALUE,
        });
    }

    handleFundOptionChange = (index, e) => {
        const { fundOptionHandler } = this.props;
        fundOptionHandler(index, e.target.value);
    };

   
    formatVal = (index,e,type) => {
        const { validateFormFields, } = this.props;

        if(type == 'initInvst')
        {
            const formatedAmout = currencyFormatterFunction(this.state.selectedAccounts[index].initialInvest);
            this.state.selectedAccounts[index].initialInvest = `${formatedAmout.substring(1,formatedAmout.length)}`; 
        }
        else if(type == 'mnthlyInvst')
        {
            const formatedAmout = currencyFormatterFunction(this.state.selectedAccounts[index].monthlyInvest);
            this.state.selectedAccounts[index].monthlyInvest = formatedAmout.substring(1,formatedAmout.length); 
        }
        if (e.target.value.length > 0) {
            e.target.value = Number(e.target.value).toFixed(2);
            validateFormFields();
        }
    };

    formatInputValues = val => {
        // return val.replace(/[a-zA-Z,$.]/g, '');
        return val && parseFloat(val).toFixed(2);
    };

    initialInvestmentOrSubsequent = (para1, para2, equalityCheck1, equalityCheck2, trueResult, faulseResult) => {
        return para1 && para2 && checkSingleEqualityAndReturnValue(equalityCheck1, equalityCheck2, trueResult, faulseResult)
    }

    checkSelectedAccount=(selectedAccounts) =>{
           
            return(
            selectedAccounts[0] &&
            selectedAccounts[0].fundAccountNumber &&
            selectedAccounts[0].accountValue &&
            selectedAccounts[0].unissuedShares )
    }

    bindFundInvestmentTable(device) {
        const {
            removeFund,
            changeSubInv,
            errors,
            showMnthlyInvst,
            fundOptionSelected,
            validateFormFields,
            formatInputValues,
            tradingWindowData,
        } = this.props;
        const { selectedAccounts, totalMnlyInv1, totalSubAmt1, fundOptions } = this.state;
        let defaultFundOption = 'init';
        if (
            this.checkSelectedAccount(selectedAccounts)
        ) {
            if (selectedAccounts[0].accountValue > 0 && selectedAccounts[0].unissuedShares >= 0) {
                // scenario of purchase happened earlier this morning: Subsequent Purchase
                defaultFundOption = 'sub';
            } else if (selectedAccounts[0].accountValue >= 0 && selectedAccounts[0].unissuedShares > 0) {
                // full liquidaiton occured earlier today: Subsequent Purchase
                defaultFundOption = 'sub';
            }
        }

        const currentDate = new Date();
        const nextPADStartDate = new Date(tradingWindowData.aipTradingStartDateKey);
        let diffForPADStartDate = (nextPADStartDate - currentDate) / (1000 * 60 * 60 * 24);
        if (diffForPADStartDate % 1 >= 0.5) {
            diffForPADStartDate -= 1;
        }
        return (
            <>
                <caption className="sr-only">{TransactionConstants.FUND_INVESTMENT_TABLE_CAPTION}</caption>
                <thead style={styles.tableHeaderStyle}>
                    <tr>
                        <th className="table_fundName" scope="col">
                            {TransactionConstants.FUND_NAME}
                        </th>
                        <th id="fund-options" className="table_fundOptions" scope="col">
                            {TransactionConstants.PURCHASE_TYPE}
                        </th>
                        <th className="table_invVal" scope="col" id="th_subsequent">
                            {this.initialInvestmentOrSubsequent(selectedAccounts?.length,
                                selectedAccounts?.[0]?.fundAccountNumber,
                                defaultFundOption, 'sub'
                                , TransactionConstants.AMOUNT_LABEL
                                , TransactionConstants.AMOUNT_LABEL)}
                        </th>
                        <th className="table_invVal " scope="col" id="th_monthly_investment">
                            {TransactionConstants.MONTHLY_INVESTMENT}
                        </th>
                        <th className="table_invDate" scope="col" id="th_monthly_investment_date">
                            {TransactionConstants.STATE_DATE}
                        </th>
                        <th scope="col">{TransactionConstants.ACTION}</th>
                    </tr>
                </thead>
                <tbody style={styles.fundUrInvTableStyle}>
                    {selectedAccounts.map((item, index) => (
                        <tr key={item.fundNumber}>
                            <td data-label={TransactionConstants.FUND_NAME} scope="row" className="fundUrInvCol1">
                                {item.fundName}
                            </td>
                            <td data-label={TransactionConstants.PURCHASE_TYPE} id="purchaseTypeInput">
                                <SelectDropDown
                                    id="fundingOption"
                                    aria-labelledby="fund-options"
                                    data-name={item.fundName}
                                    onChange={Object.assign(e => this.handleFundOptionChange(index, e))}
                                    itemlist={fundOptions?.value}
                                    value={
                                        getValidValue(selectedAccounts[0].fundOptionSelected, defaultFundOption)
                                    }
                                    required
                                />
                            </td>
                            <td
                                data-label={
                                    this.initialInvestmentOrSubsequent(selectedAccounts?.length,selectedAccounts?.[0]?.fundAccountNumber,defaultFundOption,"sub",TransactionConstants.SUBSEQUENT_INVESTMENT,TransactionConstants.INITIAL_INVESTMENT)
                                }
                            >
                                <div
                                    style={assignObj({
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        paddingLeft: '20px',
                                    })}
                                >
                                    <div style={styles.dollarSign} id="subsequent_dollar">
                                        {TransactionConstants.DOLLAR_SYMBOL}
                                    </div>
                                    <div data-test="initial-invest" className="w-100">
                                        <WInput
                                            ariaDescribedBy="subsequent_maxValplaceholder"
                                            controlid="initialInvestment"
                                            label=""
                                            labelsm={0}
                                            labelmd={0}
                                            valuesm={12}
                                            valuemd={12}
                                            maxlength={13}
                                            placeholder=""
                                            name="initialInvest"
                                            inputstyle={assignObj({ marginBottom: 0 })}
                                            type="text"
                                            subtype="transaction"
                                            pattern="\d*"
                                            defaultval={item.initialInvest}
                                            onBlur={Object.assign(e => this.formatVal(index,e,'initInvst'))}
                                            value={item.initialInvest ? item.initialInvest.toString() : ''}
                                            onChange={Object.assign(e => changeSubInv(index, e, 'initInvst'))}
                                            errortext={errors.subInvest}
                                            // errortext={initialInvestErr}
                                            id="#initialInvest"
                                            
                                            arialabelledby="th_subsequent subsequent_dollar"
                                            subtitle = {item.fundName=="Proportionate" ? "" : `${TransactionConstants.MINIMUM_DOLLAR} ${
                                                selectedAccounts[0].fundAccountNumber && defaultFundOption === 'sub'
                                                    ? formatDecimal(formatInputValues(item.minimumAutomaticInvestment))
                                                    : formatDecimal(
                                                          formatInputValues(
                                                              checkSingleEqualityAndReturnValue(fundOptionSelected, "init", item.initialInvestment, item.minimumInitialMonthlyInvestment)
                                                          ),
                                                      )}`} 
                                            errorstyle={assignObj({ position: 'static', fontSize: '12px', fontWeight:600})}
                                        />
                                    
                                    </div>
                                </div>
                            </td>
                            <td data-label={TransactionConstants.MONTHLY_INVESTMENT}>
                                {fundOptionSelected === 'sub' ? (
                                    <span className="sr-only">{TransactionConstants.N_AND_A}</span>
                                ) : (
                                    ''
                                )}
                                {fundOptionSelected.includes('mon') && (
                                    <div
                                        style={assignObj({
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            paddingLeft: '20px',
                                        })}
                                    >
                                        <div style={styles.dollarSign} id="monthly_invest_dollar">
                                            {TransactionConstants.DOLLAR_SYMBOL}
                                        </div>
                                        <div data-test="monthly-invest" className="w-100">
                                            <WInput
                                                ariaDescribedBy="monthlyInvest_maxValplaceholder"
                                                arialabelledby="th_monthly_investment monthly_invest_dollar"
                                                controlid="monthlyInvestment"
                                                label=""
                                                labelsm={0}
                                                labelmd={0}
                                                valuesm={12}
                                                valuemd={12}
                                                placeholder=""
                                                maxlength={13}
                                                pattern="\d*"
                                                disabled={!showMnthlyInvst}
                                                defaultval={item.monthlyInvest}
                                                value={item.monthlyInvest ? item.monthlyInvest.toString() : ''}
                                                name="monthlyInvest"
                                                inputstyle={assignObj({
                                                    marginBottom: 0,
                                                    minWidth: '90px',
                                                })}
                                                type="text"
                                                subtype="transaction"
                                                onBlur={Object.assign(e => this.formatVal(index,e,'mnthlyInvst'))}
                                                onChange={Object.assign(e => changeSubInv(index, e, 'mnthlyInvst'))}
                                                errortext={showMnthlyInvst ? errors.mnthlyInvest : ''}
                                                id="#monthlyInvest"
                                                errorstyle={assignObj({ position: 'static', fontSize:'12px', fontWeight:600})}
                                                subtitle = {`${TransactionConstants.MINIMUM_DOLLAR}
                                                
                                               ${formatDecimal(formatInputValues(item.minimumAutomaticInvestment))}`}
                                               
                                            />
                                          
                                        </div>
                                    </div>
                                )}
                            </td>

                            <td data-label={TransactionConstants.STATE_DATE}>
                                {fundOptionSelected === 'sub' ? (
                                    <span className="sr-only">{TransactionConstants.N_AND_A}</span>
                                ) : (
                                    ''
                                )}
                                {fundOptionSelected.includes('mon') && (
                                    <>
                                        <VictoryDatePicker
                                            name="startDate"
                                            id={assignObj(`startDate${index}${device}`)}
                                            className={
                                                errors.startDate
                                                    ? 'analytics-form-fields errorInputField'
                                                    : 'analytics-form-fields'
                                            }
                                            disabled={!showMnthlyInvst}
                                            value={item.startDate || ''}
                                            minDate={nextPADStartDate}
                                            onDateChange={Object.assign((date, e) =>
                                                changeSubInv(index, e, 'startDate', date),
                                            )}
                                            onDateBlur={validateFormFields}
                                            argument1="startDate"
                                            argument2={index}
                                            errortext={errors.startDate}
                                            colWidth="col-sm-12"
                                            isFilterForPADStartDate
                                            diffForPADStartDate={diffForPADStartDate}
                                            required
                                            datePickerModule="fundSelectionBuy"
                                            ariaLabelledBy="th_monthly_investment_date"
                                            ariaDescribedBy={`StartDateHint${index}${device} Date-error`}
                                            inputAriaDescBy={
                                                errors.startDate
                                                    ? `StartDateHint${index}${device} Date-error`
                                                    : `StartDateHint${index}${device}`
                                            }
                                        />
                                    </>
                                )}
                            </td>
                            <td data-label={TransactionConstants.ACTION} className="removeBtnWrpr">
                                <button
                                    type="button"
                                    key={assignObj(`${item.fundName}-remove`)}
                                    style={styles.removeLink}
                                    onClick={removeFund(item.fundNumber)}
                                    data-test="remove-fund"
                                >
                                    <WIcon icon={faTrashAlt} fontawesomestyle={styles.removeLinkIconStyle} size="1x" />
                                    {TransactionConstants.REMOVE_TEXT}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot style={styles.tableFooterStyle}>
                    <tr>
                        <td scope="row">
                            <div style={styles.fundFooter}>{TransactionConstants.TOTAL_TEXT}</div>
                        </td>
                        <td data-label={TransactionConstants.PURCHASE_TYPE} />
                        <td
                            data-label={
                                selectedAccounts.length &&
                                selectedAccounts[0].fundAccountNumber &&
                                defaultFundOption === 'sub'
                                    ? TransactionConstants.SUBSEQUENT_INVESTMENT
                                    : TransactionConstants.INITIAL_INVESTMENT
                            }
                            className="fundFooterData"
                        >
                           
                            {currencyFormatterFunction(totalSubAmt1)} 
                           
                        </td>
                        <td data-label={TransactionConstants.MONTHLY_INVESTMENT} className="fundFooterData">
                            
                                {currencyFormatterFunction(Number.isNaN(totalMnlyInv1) ? 0 : totalMnlyInv1)}
                           
                        </td>
                        <td data-label={TransactionConstants.STATE_DATE} />
                        <td data-label={TransactionConstants.ACTION} />
                    </tr>
                </tfoot>
            </>
        );
    }

    render() {
        const { masterLookupData } = this.props;

        const loading = masterLookupData.isLoading;

        return (
            <>
                {loading && <WSpinner data-test="loader" loading={loading} />}
                <Accordian
                    titleChild={
                        <AccordianTitle
                            accType={TransactionConstants.INVEST_FUND_TEXT}
                            className="fundInvestmentTable"
                        />
                    }
                >
                    <div id="fundUrInv1" className={classes.fundInvestment} data-test="fund-invest-component">
                        <hr aria-hidden="true" className="mt-0 mb-4" />
                        <div style={styles.accordionInfoStyleFYInv}>{TransactionConstants.CHOOSE_BELOW}</div>
                        <div style={styles.accordionInfoStyleFYInv}>{TransactionConstants.CHOOSE_BELOW1}</div>
                        <MandatoryTextDiv>{TransactionConstants.MANDATORY_TEXT}</MandatoryTextDiv>
                        {/* Table for funding options */}
                        <WTableDesktop>
                            <WTable
                                bordered
                                style={styles.tableColor}
                                className="fundInvestTable"
                                responsive={false}
                                data-test="fundTable"
                            >
                                {this.bindFundInvestmentTable('desktop')}
                            </WTable>
                        </WTableDesktop>
                        <WTableMobile>
                            <WTable
                                bordered
                                style={styles.tableColor}
                                className="fundInvestTable"
                                responsive={false}
                                data-test="fundTable"
                                role="presentation"
                            >
                                {this.bindFundInvestmentTable('mobile')}
                            </WTable>
                        </WTableMobile>
                    </div>
                </Accordian>
            </>
        );
    }
}

FundInvest.propTypes = {
    removeFund: PropTypes.func,
    selectedFund: PropTypes.instanceOf(Object),
    totalSubAmt: Number,
    totalMnlyInv: Number,
    changeSubInv: PropTypes.instanceOf(Function),
    showMnthlyInvst: bool,
    errors: PropTypes.instanceOf(Object),
    fundOptionHandler: PropTypes.func,
    masterLookupData: PropTypes.instanceOf(Object),
    validateFormFields: PropTypes.instanceOf(Function),
    fundOptionSelected: PropTypes.string,
    formatInputValues: PropTypes.instanceOf(Function),
    tradingWindowData: PropTypes.instanceOf(Object),
    tradingWindowData: PropTypes.instanceOf(Object),
};
FundInvest.defaultProps = {
    removeFund: () => {},
    selectedFund: [],
    totalSubAmt: 0,
    totalMnlyInv: 0,
    changeSubInv: () => {},
    showMnthlyInvst: false,
    errors: {},
    fundOptionHandler: () => {},
    masterLookupData: {},
    validateFormFields: () => {},
    fundOptionSelected: 'init',
    formatInputValues: () => {},
    tradingWindowData: {},
};

export default FundInvest;
