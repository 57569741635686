import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import { MarketNAORoot} from 'modules/AccountManagementModule/MarketNAO/MarketNAODataHelper';

const initialState = {
    isLoading: false,
    isMarketNAOError: false,
    isMarketNAOSuccess: false,
    marketNAOInfo: MarketNAORoot(),
    applicationId:'',
    listOfCompletedPages:[],
    statusMessage:'',
    isSuccessLoader:false,
    postStatusMsg: '',
    isPostCallSuccess: false,
    isPostCallError: false,
    transactionRefId:'',
    isKycSuccess:false,
    isKycError:false,
    kycResponseData:{},
    kycResponseCode:0,
    isKycLoader:false,
    isRedirectionFlow: false,
    dataFromSavedApplication: {},
    isMarketNAOSubmitSuccess: false,
    isMarketNAOSubmitError: false,

    isaccountApiSuccess: false,
    isaccountApiError: false,

    ispersonalInfoApiSuccess: false,
    ispersonalInfoApiError: false,

    isaddressApiSuccess: false,
    isaddressApiError: false,

    iscontactInfoApiSuccess: false,
    iscontactInfoApiError: false,

    isemploymentInfoApiSuccess: false,
    isemploymentInfoApiError: false,

    isfinancialInfoApiSuccess: false,
    isfinancialInfoApiError: false,

    ismilitaryInfoApiSuccess: false,
    ismilitaryInfoApiError: false,

    isriskProfileApiSuccess: false,
    isriskProfileApiError: false,

    isbrokerDealerApiSuccess: false,
    isbrokerDealerApiError: false,
    
    isbeneficiariesApiSuccess: false,
    isbeneficiariesApiError: false,

    istrustedContactApiSuccess: false,
    istrustedContactApiError: false,

    iseConsentApiSuccess: false,
    iseConsentApiError: false,
    isaccountFundingApiSuccess: false,
    isaccountFundingApiError: false,
    bankAccountInfoList: [],
    bankAccountInfoListErrorStatus:null,
    bankAccountInfoListMessage:""
};

const submitMarketNAOSuccess=(state,action)=>{

    const errorCodeList = [3009]
    const haserrorCode = action?.payload?.errorCode ? errorCodeList.includes(action.payload.errorCode) : false;
    const errorCode = action?.payload?.errorCode ? action.payload?.errorCode : 503;
    const hasMFBankAccount = action?.payload?.isMFBankAccount ? action?.payload?.isMFBankAccount : false;

    if(action.payload?.transactionRefId != undefined){
        return { ...state, ...action, transactionRefId: action.payload.transactionRefId, statusMessage:action.payload.response, 
            isSuccessLoader:false,isMarketNAOError: false,isMarketNAOSuccess:true, isMarketNAOSubmitSuccess: true, isMarketNAOSubmitError: false,
            hasErrorCode: haserrorCode, marketNAOSubmitErrorCode: errorCode, hasMFBankAccount: hasMFBankAccount
        }
    }else{
        return { ...state, ...action, statusMessage:action.payload.response ,isSuccessLoader:false,isMarketNAOError: false,isMarketNAOSuccess:true, isMarketNAOSubmitSuccess: true, isMarketNAOSubmitError: false, hasErrorCode:false, marketNAOSubmitErrorCode: errorCode, hasMFBankAccount: hasMFBankAccount}

    }

}

const submitMarketNAOFailure = (state, action) => {

    const errorCode = action?.payload?.errorCode ? action.payload?.errorCode : 503

    // some times its returning just "bad request" string in response, so handling in this way
    if (typeof (action.payload) == 'string') {
        return { ...state, ...action, statusMessage: action.payload, isMarketNAOError: true, isMarketNAOSuccess: false, isSuccessLoader: false, isMarketNAOSubmitSuccess: false, isMarketNAOSubmitError: true, marketNAOSubmitErrorCode: errorCode };
    } else if (action.payload?.message == 'ERROR') {
        return { ...state, ...action, statusMessage: action.payload.response, isMarketNAOError: true, isMarketNAOSuccess: false, isSuccessLoader: false, isMarketNAOSubmitSuccess: false, isMarketNAOSubmitError: true, marketNAOSubmitErrorCode: errorCode };
    } else {
        return { ...state, ...action, statusMessage: action.payload?.body, isMarketNAOError: true, isMarketNAOSuccess: false, isSuccessLoader: false, isMarketNAOSubmitSuccess: false, isMarketNAOSubmitError: true, marketNAOSubmitErrorCode: errorCode };
    }
}


function marketNAOReducerData(state = initialState, action) {

    switch (action.type) {
        
       
        case ActionTypes.SUBMIT_MARKET_NAO_INFO_SUCCESS: {
            return submitMarketNAOSuccess(state,action)
            // if(action.payload?.transactionRefId != undefined){
            //     return { ...state, transactionRefId: action.payload.transactionRefId, statusMessage:action.payload.response ,isSuccessLoader:false,isMarketNAOError: false,isMarketNAOSuccess:true, isMarketNAOSubmitSuccess: true, isMarketNAOSubmitError: false}
            // }else{
            //     return { ...state, ...action, statusMessage:action.payload.response ,isSuccessLoader:false,isMarketNAOError: false,isMarketNAOSuccess:true, isMarketNAOSubmitSuccess: true, isMarketNAOSubmitError: false}

            // }
        }

        case ActionTypes.SUBMIT_MARKET_NAO_INFO_FAILURE: {
            // some times its returning just "bad request" string in response, so handling in this way
            return submitMarketNAOFailure(state,action)
        }

        case ActionTypes.SUBMIT_MARKET_NAO_INFO_ERROR: {

            console.log('Eror Reducer',action)
            const errorCode = action?.payload?.errorCode ? action.payload?.errorCode : 503

            return { ...state, ...action, statusMessage:'Error', isMarketNAOError: true,isMarketNAOSuccess:false, isSuccessLoader:false, isMarketNAOSubmitSuccess: false, isMarketNAOSubmitError: true, marketNAOSubmitErrorCode: errorCode
            };
        }

        
        case ActionTypes.SUBMIT_MARKET_NAO_INFO_LOADER: {
            return { ...state, ...action, isSuccessLoader:true, isMarketNAOSubmitSuccess: false, isMarketNAOSubmitError: false};
        }

            // GET API call response
        case ActionTypes.GET_MARKET_NAO_INFO_SUCCESS: {
            return { ...state, ...action,statusMessage:action.payload,isLoading: false ,isSuccessLoader:false,isMarketNAOError: false,isMarketNAOSuccess:true,
            };
        }
    
        case ActionTypes.GET_MARKET_NAO_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true};
        }

        case ActionTypes.GET_MARKET_NAO_INFO_LOADER: {
            return { ...state, ...action, isLoading: true,isMarketNAOSuccess:false, isMarketNAOError: false};
        }

        case ActionTypes.GET_MARKET_NAO_INFO_ERROR: {
            return { ...state, ...action, isLoading: false, isMarketNAOSuccess:false,isMarketNAOError: true};
        }


        // POST API call response


        case ActionTypes.POST_MARKET_NAO_INFO_SUCCESS: {
            return { ...state, isLoading: false ,
                applicationId:action.payload.result.applicationId,
                postStatusMsg: action.payload.message,
                isPostCallSuccess: action.payload.message == 'Success' ? true : false,
                isPostCallError:false,
                [`is${action.responseOf}ApiSuccess`]: true,
                [`is${action.responseOf}ApiError`]: false,
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_INFO_FAILURE: {

            const statusCode = [3005, 5000]

            if(statusCode.includes(action.payload.responseCode)){
                return { ...state, ...action, isLoading: false, isPostCallError:true, isPostCallSuccess: false, postStatusMsg: action.payload.result, [`is${action.responseOf}ApiError`]: true, [`is${action.responseOf}ApiSuccess`]: false};
            }
            else if(action.payload?.result?.exceptionDetails != undefined && action.payload.result.exceptionDetails?.logMessage != undefined && typeof(action.payload.result.exceptionDetails.logMessage) == 'string'){
                // logMesssgae check if itss string, if its object then skip 
                return { ...state, ...action, isLoading: false,isPostCallError:true, isPostCallSuccess: false, postStatusMsg: action.payload.result.exceptionDetails.logMessage, [`is${action.responseOf}ApiError`]: true, [`is${action.responseOf}ApiSuccess`]: false};
            }
            else if(action.payload?.result != undefined && typeof(action.payload.result) == 'string'){
                return { ...state, ...action, isLoading: false,isPostCallError:true, isPostCallSuccess: false, postStatusMsg: action.payload.result, [`is${action.responseOf}ApiError`]: true, [`is${action.responseOf}ApiSuccess`]: false};
            }
            else{
                return { ...state, ...action, isLoading: false, isPostCallError:true, isPostCallSuccess: false, postStatusMsg: 'Error Occured during the process.', [`is${action.responseOf}ApiError`]: true, [`is${action.responseOf}ApiSuccess`]: false};

            }
        }

        case ActionTypes.POST_MARKET_NAO_INFO_LOADER: {
            return { ...state, ...action, isLoading: true,isPostCallError:false, isPostCallSuccess: false, postStatusMsg: '',statusMessage:''};
        }

        case ActionTypes.POST_MARKET_NAO_INFO_ERROR: {
            return { ...state, ...action, isLoading: false,isPostCallError:true, isPostCallSuccess: false, postStatusMsg: action.payload?.message != undefined ? action.payload?.message : 'Error: Internal Server Error.', [`is${action.responseOf}ApiError`]: true};
        }

        // Personal infomation 

        case ActionTypes.POST_MARKET_NAO_PERSONAL_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }

        case ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }


        case ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }

        /// 

        case ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }

        case ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }

        case ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }




        case ActionTypes.POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_SUCCESS,
            ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isMarketNAOError: false,isMarketNAOSuccess:true,
                [`is${action.responseOf}ApiSuccess`]: true
            };
        }
    
        case 
             ActionTypes.POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO_FAILURE,
             ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO_FAILURE: {
            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,
                [`is${action.responseOf}ApiError`]: true
            };
        }

        //  update local state data
        case ActionTypes.UPDATE_MARKET_NAO_INFO: {

            if(action.payload?.isPrimary == true){
            
                return { ...state,
                    marketNAOInfo:
                        {...state.marketNAOInfo, 
                            [action.payload.sectionToBeUpdated]:
                                                                {
                                                                ...state.marketNAOInfo[action.payload.sectionToBeUpdated] ,

                                                                ['primary'] : action.payload.state
                                                            
                                                                } 
                        }
                }
            }
            else if(action.payload?.isSecondary == true){

                if(action.payload?.isPersonalInfo == true)
                {
                    return { ...state,
                        marketNAOInfo:
                            {...state.marketNAOInfo, 
                                [action.payload.sectionToBeUpdated]:
                                                                    {
                                                                    ...state.marketNAOInfo[action.payload.sectionToBeUpdated] ,
    
                                                                    ['secondary'] : action.payload.state
                                                                
                                                                    } 
                            }
                    }
                }
                else
                {
                    return { ...state,
                        marketNAOInfo:
                            {...state.marketNAOInfo, 
                                [action.payload.sectionToBeUpdated]:
                                                                    {
                                                                    ...state.marketNAOInfo[action.payload.sectionToBeUpdated] ,
    
                                                                    ['joint'] : action.payload.state
                                                                
                                                                    } 
                            }
                    }
                }
               
            }else{
                return { ...state,marketNAOInfo:{...state.marketNAOInfo, 
                    [action.payload.sectionToBeUpdated]: action.payload.state }
                };
            }
            
        }



        case ActionTypes.UPDATE_LIST_OF_COMPLETED_PAGES: {
            return { ...state, ...action,  listOfCompletedPages: action.payload.listOfCompletedPages};
        }

        // Get MARKET APPLICATION ID DATA

        
        case ActionTypes.GET_MARKET_APPLICATIONID_DATA_SUCCESS: {
            if(action.payload.message == 'Success'){
                return { ...state, ...action, isLoading: false,dataFromSavedApplication: action.payload.result, isMarketNAOSuccess:true, isMarketNAOError: false};
            }else if(action.payload.message == 'Error'){
                return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true, statusMessage: action.payload.response};
            }

            return { ...state, ...action}
        }

        case ActionTypes.GET_MARKET_APPLICATIONID_DATA_FAILURE: {

            return { ...state, ...action, isLoading: false,isMarketNAOSuccess:false, isMarketNAOError: true,};
        }

        case ActionTypes.GET_MARKET_APPLICATIONID_DATA_LOADER: {
            return { ...state, ...action, isLoading: true,isMarketNAOSuccess:false, isMarketNAOError: false};
        }

        case ActionTypes.GET_MARKET_APPLICATIONID_DATA_ERROR: {

            return { ...state, ...action, isLoading: false, isMarketNAOSuccess:false,isMarketNAOError: true};
        }


        // Kyc verification

        
        case ActionTypes.POST_KYC_VERIFICATION_SUCCESS: {
            return { ...state, isLoading: false ,
                kycResponseData:action.payload.result,
                isKycSuccess:true,
                isKycError:false,
                isKycLoader: false,
                kycResponseCode: action.payload.responseCode,
                marketNAOInfo:{...state.marketNAOInfo, applicationStatus: action.payload.result.overallKYCStatus}
            };
        }
    
        case ActionTypes.POST_KYC_VERIFICATION_FAILURE: {
            return { ...state, isKycLoader: false, kycResponseData:action.payload.result , kycResponseCode: action.payload.responseCode, isKycSuccess:false, isKycError:true};
        }

        case ActionTypes.POST_KYC_VERIFICATION_LOADER: {
            return { ...state, isKycLoader: true, isKycSuccess:false, isKycError:false };
        }

        case ActionTypes.POST_KYC_VERIFICATION_ERROR: {
            const errorObj = {
                exceptionDetails:{
                    logMessage: "Error occured while processing your information."
                }
            }
            return { ...state, isKycLoader: false,kycResponseData:errorObj, kycResponseCode: 0, isKycSuccess:false, isKycError:true};
        }

        
        case ActionTypes.CLEAR_KYC_VERIFICATION_DATA: {
            return { ...state, isKycLoader: false,kycResponseData:{}, kycResponseCode: 0, isKycSuccess:false, isKycError:false};
        }




        case ActionTypes.MARKET_UPDATE_LOCAL_STATE: {
            if(action.payload.message == 'Success'){
                return { ...state, ...action, isLoading: false,marketNAOInfo: action.payload, applicationId: action.payload.applicationId, isMarketNAOSuccess:true, isMarketNAOError: false};
            }else{
                return { ...state, isLoading: false,marketNAOInfo: action.payload, applicationId: action.payload.applicationId};

            }
        }

                
        case ActionTypes.CLEAR_API_STATUS: {
            return { ...state, [`is${action.payload}ApiSuccess`]: false, [`is${action.payload}ApiError`]: false};
        }

        case ActionTypes.CLEAR_MARKET_NAO_INFO: {
            return { ...initialState};
        }

        case ActionTypes.CLEAR_BROKERAGE_BANK_ACCOUNT_INFO: {
            return {
                 ...state, ...action, isLoading: false,
                 bankAccountInfoList:[],
                 bankAccountInfoListErrorStatus:null,
                 bankAccountInfoListMessage:""
            }
        }

        case ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_SUCCESS: {

            return { 
                ...state, ...action, isLoading: false, 
                bankAccountInfoList: action.decryptedBankDetails,
                bankAccountInfoListErrorStatus:false,
                bankAccountInfoListMessage:action.payload?.message
            }
        }

        case ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION_ERROR: {
            return { ...state, ...action, 
                statusMessage:'Error', 
                bankAccountInfoListErrorStatus:true,
                bankAccountInfoListMessage:""

            };
        }

        case ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_SUCCESS: {
            return { ...state, ...action, isLoading: false, isSubmitKycUploadDocSuccess: true, isSubmitKycUploadDocError: false}
         
        }

        case ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_FAILURE: {
          
            return { ...state, ...action, isLoading: false,isSubmitKycUploadDocSuccess: false, isSubmitKycUploadDocError: true }
        }

        case ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_ERROR: {

            return { ...state, ...action, isLoading: false,isSubmitKycUploadDocSuccess:false, isSubmitKycUploadDocError: true };
        }

        
        case ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS_LOADER: {
            return { ...state, ...action, isLoading: true, isSubmitKycUploadDocSuccess: false, isSubmitKycUploadDocError: false};
        }

        default:
            return state;
    }
}

export default marketNAOReducerData;
