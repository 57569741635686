import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import styled from 'styled-components';
import consts from '../../consts';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icon-tick-big.svg';
import { SectionWrapper } from '../commonCSS';
import ShowHideMaskText from './../../../../../../common/ShowHideMaskText/ShowHideMaskText';

const GreenTick = styled(CheckIcon)`
    width: 10%;
    height: 14px;
    background: transparent;
    opacity: 1;
    box-sizing: content-box;
    padding: 5px 5px 5px 0;
`;

const YouSelectSectionItem = styled.div`
    padding: 5px;
`;

const Section = styled.div`
    width: 50%;
    padding-right: 40px;
`;

const RightCheckSection = styled.div`
    width: 90%;
    font-weight: bold;
`;

const SubSection = styled.div`
    padding-bottom: 40px;
`;

const SectionTitle = styled.div`
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    pedding-bottom:10px;
`;

const SectionText = styled.div`
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-49494a);
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const YouSelect = (props) => {
    const {pageIndex, pageSubIndex, answerDetails, accountDetails} = props;

    const getGuidanceText = () => {
        const pageGuidance = get(consts, 'youSelect.pageGuidance', null); 
        if (pageGuidance){
            if ( pageSubIndex === -1)
                return pageGuidance[pageIndex];
            if ( pageSubIndex === 0 || pageSubIndex === 1 )            
                return pageGuidance[pageIndex][pageSubIndex];
        }
        return ''
    }

    const renderSelectedOption = ( isAccountSelection) => {
        const selectedOptions = isAccountSelection ? accountDetails : answerDetails; 
        return selectedOptions.map((detailsItem, index) => {
                return (index === 0 && isAccountSelection)
                    ? (<YouSelectSectionItem key="accountSelection">
                        <SectionWrapper>
                           <GreenTick/>
                           <RightCheckSection>
                               <div>{detailsItem.accountName}</div>
                                <ShowHideMaskText text={detailsItem.accountNumber ? detailsItem.accountNumber.toString() : ''} startIndex={0} maskLength={6} />
                           </RightCheckSection>
                        </SectionWrapper>
                       </YouSelectSectionItem>)
                    : (<YouSelectSectionItem key={`aswer-${index}`}>
                        <SectionWrapper>
                            <GreenTick/><RightCheckSection>{detailsItem}</RightCheckSection>
                        </SectionWrapper>
                    </YouSelectSectionItem>)
        })
    }

    return (<Section>
            {isEmpty(accountDetails) && isEmpty(answerDetails) 
                ? '' 
                : (<SubSection>
                    <SectionTitle>You selected:</SectionTitle>
                    <SectionText>
                        {renderSelectedOption(true)}
                        {renderSelectedOption(false)}
                    </SectionText>
                </SubSection>)
            }
      
            {getGuidanceText() === '' 
                ? '' 
                :(<SubSection>
                    <SectionTitle>Guidance</SectionTitle>
                    <SectionText>
                        {getGuidanceText()} 
                    </SectionText> 
                </SubSection>)
            }
    </Section>
    )
}

YouSelect.propTypes = {
    pageIndex: PropTypes.number,
    pageSubIndex: PropTypes.number,
    accountDetails: PropTypes.instanceOf(Array),
    answerDetails: PropTypes.instanceOf(Array),
};

YouSelect.defaultProps = {
    pageIndex: 0,
    pageSubIndex: -2,
    accountDetails: [],
    answerDetails: [],
};

export default YouSelect;