import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AccountImg from '../../assets/account.png';

const VerticalSeperator = styled.div`
    border-left: 1px solid #E6E6E6;
    margin-left: 1em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
`;

const FlexDiv = styled.div`
    display: flex;
    padding: 15px 0 10px 20px;
`;


const AccountCardTopSection = styled.div`
    display: flex;
    text-align: left;
    font: bold 16px benton-sans, sans-serif;
    letter-spacing: 0.36px;
    color: #54565b;
    opacity: 1;
`;

const StyledCard = styled(Card)`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000000f;
    opacity: 0.94;
`;

const AccountCardBottomColumn = styled(FlexDiv)`
    flex-direction: column;
    
`;
const AccountCardHeadingSpan = styled.span`
    margin-bottom: 15px;
    text-align: left;
    font: 16px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494A;
    line-height: 5px;
    opacity: 1;
    font-weight: 601;
`;
const AccountCardValueSpan = styled.span`
    text-align: left;
    font: 16px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
`;

const ShareTextStyle = styled.span`
    display: inline-block;
    flex: 1;
    font: 600 16px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #004A98;
    opacity: 1;
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AddedDateStyle = styled.span`
font: 14px benton-sans, sans-serif;
letter-spacing: 0;
color: #9B9B9BDE;
opacity: 1;
`;

const AddedDateValueStyle = styled.span`
font: 550 14px benton-sans, sans-serif;
letter-spacing: 0;
color: #56565A;
opacity: 1;
`;

const CardProfileSymbol = styled.img`
    margin-right: 0.625rem;
    margin-left: 0.9375rem;
`;

const accountStyle = {
    border: '1px solid #D2D2D2', borderLeftStyle: 'none' ,padding: '15px', flex: 1.5
}

const shareStyle = {
    border: '1px solid #D2D2D2', flex: 1.3, padding: '18px'
}

const dateStyle = {
    border: '1px solid #D2D2D2', borderRightStyle: 'none', padding: '18px', flex: 0.7
}

const AddedAmendCardBody = props => {
    const { accountName, accountNumber, share, addedDate, currentValue, transactionType, paymentMode, orderStatus, checkboxRequired } = props;
    const addedDateText = 'Date added : ';
    const currentValueText = 'Current Value';
    const transactionTypeText = 'Transaction Type';
    const paymentModeText = 'Payment Mode';
    const orderStatusText = 'Order Status';
    const accName = 'Account Name ';
    let image = null;
    if(checkboxRequired){
        image = <CardProfileSymbol src={AccountImg} alt=''/>;
    }
    return (
        <StyledCard>
                <AccountCardTopSection>
                        <div style={accountStyle}>{image} {accName} {accountName} {accountNumber}</div>
                       
                        <div style={shareStyle}>
                        <ShareTextStyle>{share}</ShareTextStyle>
                        </div>
                       
                        <div style={dateStyle}>
                        <AddedDateStyle>{addedDateText}</AddedDateStyle>
                        <AddedDateValueStyle>{addedDate}</AddedDateValueStyle>
                        </div>
                </AccountCardTopSection>

                <FlexDiv>
                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{currentValueText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{currentValue}</AccountCardValueSpan>
                    </AccountCardBottomColumn>
                    <VerticalSeperator />
                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{transactionTypeText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{transactionType}</AccountCardValueSpan>
                    </AccountCardBottomColumn>
                    <VerticalSeperator />
                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{paymentModeText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{paymentMode}</AccountCardValueSpan>
                    </AccountCardBottomColumn>
                    <VerticalSeperator />
                    <AccountCardBottomColumn>
                        <AccountCardHeadingSpan>{orderStatusText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{orderStatus}</AccountCardValueSpan>
                    </AccountCardBottomColumn>
                </FlexDiv>
        </StyledCard>
    );
};

AddedAmendCardBody.propTypes = {
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    share: PropTypes.string.isRequired,
    addedDate: PropTypes.string.isRequired,
    currentValue: PropTypes.string.isRequired,
    transactionType: PropTypes.string.isRequired,
    paymentMode: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    checkboxRequired: PropTypes.bool.isRequired
};

export default AddedAmendCardBody;
