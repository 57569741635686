import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import InputText from '../InputText';
import ErrorMsg from '../ErrorMsg';

export const Spliter = styled.div`
	margin-left:${props => props.mL || 0}rem;
	margin-right:${props => props.mR || 0}rem;
`
const FlexDiv = styled.div`
	display:flex;
	align-items: center;
	justify-content: space-between;
`;

const areaNumberWidth = {width: "92px"};

const groupNumberWidth = {width: "68px"};

const serialNumberWidth = {width: "100px"}

const SPLITTER = '-';
const BACKSPACE_KEY_CODE = 8;
const DELETE_KEY_CODE = 46;

const ERROR_TXT = 'Invalid SSN';
const INITIAL_ERROR_TXT = 'Please enter SSN';

export const onInputChange = (action, ref, limit) => (e)=> {
	let { value } = e.target;
	value = value.replace(/[^0-9*]/gi, '');
	if(value.length === limit){
		const newValue = value.slice(0,limit);
		action(newValue);
		const { current: inputEl } = ref || {};
		if(inputEl) inputEl.focus();
	}else {
		action(value);
	}
}

export const getOriginalNumber = (originalNumber, type) => {
	return originalNumber.current[type.toString()]; 
}

export const setOriginalNumber = (originalNumber, type, value) => {
	const originalNumberRef = originalNumber;
	originalNumberRef.current[type.toString()] = value; 
}

export const onPasteValues = ({e, limit, selectionEnd, selectionStart, number, numArray, originalNumber, type}) => {
	const numArrayValue = numArray;
	let pasteValue = (e.clipboardData || window.clipboardData).getData('text');
    pasteValue = pasteValue.substring(0, limit +selectionEnd-selectionStart - number.length);
    pasteValue = pasteValue.replace(/[^0-9]/gi, '');
    if(pasteValue) {
    	numArrayValue.splice(selectionStart, selectionEnd-selectionStart, pasteValue);
           setOriginalNumber(originalNumber, type, numArrayValue.join('').replace(/\s/,''));
    }
}

export const onBackspaceKey = ({e, value, ref, selectionStart, selectionEnd, numArray, originalNumber, type}) => {
	const numArrayValue = numArray; 
	if(!value){
        e.preventDefault();
        const { current: inputEl } = ref || {};
		if(inputEl) inputEl.focus();	
    }else {
        const posStart = selectionStart === selectionEnd ? selectionStart-1 : selectionStart;
        const startPos = selectionStart ? posStart : 0;
        const deleteCount = !selectionStart && (selectionStart === selectionEnd) ? 0 : selectionEnd-startPos || 1 ;
        numArrayValue.splice(startPos, deleteCount);
        setOriginalNumber(originalNumber, type, numArrayValue.join(''));
    }
}

export const onDeleteKey = ({value, selectionStart, selectionEnd, numArray, originalNumber, type}) => {
	const numArrayValue = numArray;
	if(value){
        numArrayValue.splice(selectionStart, selectionEnd-selectionStart || 1);
        setOriginalNumber(originalNumber, type, numArrayValue.join(''));	
    }
}

export const onNumberKey = ({e, value, limit, selectionEnd, selectionStart, numArray, originalNumber, type}) => {
	const numArrayValue = numArray;
	if(!e.ctrlKey && ( value.length < limit || (value.length === limit && selectionEnd-selectionStart))){
        numArrayValue.splice(selectionStart, selectionEnd-selectionStart, e.key);
        setOriginalNumber(originalNumber, type, numArrayValue.join('').replace(/\s/,''));	
    }
}

export const onNonNumberKey = ({e, value, limit, selectionEnd, selectionStart, numArray, originalNumber, type}) => {
	const numArrayValue = numArray;
	if(!e.ctrlKey && e.key.length === 1 && (value.length < limit || (value.length === limit && selectionEnd-selectionStart))){
        numArrayValue.splice(selectionStart, selectionEnd-selectionStart, '');
        setOriginalNumber(originalNumber, type, numArrayValue.join(''));
    }
}

export const onKeyEvent = (originalNumber, paste, type, value, limit, ref) => e => {
        const number = getOriginalNumber(originalNumber, type);
        const numArray = number.split('');
        const { selectionStart, selectionEnd }  = e.target;
        switch (true) {
        	
        	case paste: {
        		onPasteValues({e, limit, selectionEnd, selectionStart, number, numArray, originalNumber, type});
        		break;
        	}
     
            case e.keyCode === BACKSPACE_KEY_CODE:{
                onBackspaceKey({e, value, ref, selectionStart, selectionEnd, numArray, originalNumber, type});
                break;
            }
            
            case e.keyCode === DELETE_KEY_CODE: {
            	onDeleteKey({value, selectionStart, selectionEnd, numArray, originalNumber, type});
            	break;
            }
            
            case (!Number.isNaN(+e.key)) : {
            	onNumberKey({e, value, limit, selectionEnd, selectionStart, numArray, originalNumber, type});
            	break;
            }
            
            case (Number.isNaN(+e.key)) : {
            	onNonNumberKey({e, value, limit, selectionEnd, selectionStart, numArray, originalNumber, type});
            	break;
            }

            default:
                break;
        }
    };
    
export const onSsnBlur = ({areaNumRef, groupNumRef, serialNumRef, areaNumber, groupNumber, serialNumber, setError, error, originalNumber, onBlur, optional}) => (e) => {
	const nextFocusedEl = e.relatedTarget;
	const { current: areaInput } = areaNumRef || {};
	const { current: groupInput } = groupNumRef || {};
	const { current: serialInput } = serialNumRef || {};
	
	if ( nextFocusedEl !== areaInput && nextFocusedEl !== groupInput && nextFocusedEl !== serialInput ) {
		const isEmpty = areaNumber.length === 0 && groupNumber.length === 0 && serialNumber.length === 0;
		
		if((areaNumber.length !== 3 || groupNumber.length !== 2 || serialNumber.length !== 4) && !(isEmpty && optional)){
			setError(true);
		}else if(error) {
			setError(false);
		}
		const { area, group, serial } = originalNumber.current;
		onBlur(`${area}${group}${serial}`);
	}
}

const SSNtext = (props) => {
	const { onBlur, initialValue, isSSNError, optional, labelledBy, ...restProps } = props;

	const [areaNumber, setAreaNumber] = useState('');
	const [groupNumber, setGroupNumber] = useState('');
	const [serialNumber, setSerialNumber] = useState('');

	const originalNumber = useRef({
    	area: '',
    	group: '',
    	serial: ''
	});
	const [error, setError] = useState(false);
	const isEmpty = areaNumber.length === 0 && groupNumber.length === 0 && serialNumber.length === 0;
	const errorTxt = (isSSNError || isEmpty) ? INITIAL_ERROR_TXT : ERROR_TXT;
	const areaNumRef = useRef(null);
	const groupNumRef = useRef(null);
	const serialNumRef = useRef(null);
	
	useEffect(()=>{
		const ssnValue = `${initialValue}`;
		const areaInitialVal = ssnValue.slice(0,3);
		const groupInitialVal = ssnValue.slice(3,5);
		const serialInitialval = ssnValue.slice(5,9);
		setAreaNumber(areaInitialVal);
		setGroupNumber(groupInitialVal);
		setSerialNumber(serialInitialval);
		setOriginalNumber(originalNumber, 'area', areaInitialVal);
		setOriginalNumber(originalNumber, 'group', groupInitialVal);
		setOriginalNumber(originalNumber, 'serial', serialInitialval);
	},[initialValue])
	
	return (
		<FlexDiv role="group" aria-labelledby={labelledBy} {...restProps} >
			<InputText 
			    error={error}
			    maxLength={3}
			    ref={areaNumRef} 
			    onChange={onInputChange(setAreaNumber, groupNumRef, 3)}
			    onKeyDown={onKeyEvent(originalNumber, false, 'area', areaNumber, 3)}
			    onBlur={onSsnBlur({optional, areaNumRef, groupNumRef, serialNumRef, areaNumber, groupNumber, serialNumber, setError, error, originalNumber, onBlur})} 
			    value={areaNumber.replace(/[0-9]/gi, '*')} 
				id="vcm-ssn-area-number" 
				name="areanumber"
				aria-label="area number"
				autoComplete="off"
			    style={areaNumberWidth}
				onPaste={onKeyEvent(originalNumber, true, 'area', areaNumber, 3)}
				data-form-field
				data-group-field
			/>
			<Spliter mL={0.8} mR={0.8}>{SPLITTER}</Spliter>
			<InputText 
			    error={error}
			    maxLength={2} 
			    ref={groupNumRef} 
			    onChange={onInputChange(setGroupNumber, serialNumRef, 2)}
			    onKeyDown={onKeyEvent(originalNumber, false, 'group', groupNumber, 2, areaNumRef)}
			    onBlur={onSsnBlur({optional, areaNumRef, groupNumRef, serialNumRef, areaNumber, groupNumber, serialNumber, setError, error, originalNumber, onBlur})} 
			    value={groupNumber.replace(/[0-9]/gi, '*')} 
				id="vcm-ssn-group-number" 
				name="groupnumber"
				aria-label="group number"
				autoComplete="off"
			    style={groupNumberWidth}
				onPaste={onKeyEvent(originalNumber, true, 'group', groupNumber, 2, areaNumRef)}
				data-form-field
				data-group-field
			/>
			<Spliter mL={0.8} mR={0.8}>{SPLITTER}</Spliter>
			<InputText 
			    error={error}
			    maxLength={4} 
			    ref={serialNumRef} 
			    onChange={onInputChange(setSerialNumber, null, 4)}
			    onKeyDown={onKeyEvent(originalNumber, false, 'serial', serialNumber, 4, groupNumRef)}
			    onBlur={onSsnBlur({optional, areaNumRef, groupNumRef, serialNumRef, areaNumber, groupNumber, serialNumber, setError, error, originalNumber, onBlur})} 
			    value={serialNumber} 
				id="vcm-ssn-serial-number"
				name="serialnumber"
				aria-label="serial number"
				autoComplete="off"
			    style={serialNumberWidth}
				onPaste={onKeyEvent(originalNumber, true, 'serial', serialNumber, 4, groupNumRef)}
				data-form-field
				data-group-field
			/>
			{(error) && <ErrorMsg>{errorTxt}</ErrorMsg>}
		</FlexDiv>
	)
}

SSNtext.propTypes = {
	onBlur: PropTypes.func,
	initialValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number,]),
	optional: PropTypes.bool,
	labelledBy: PropTypes.string,
	isSSNError: PropTypes.bool,
};

SSNtext.defaultProps = {
	onBlur: () => {},
	initialValue: '',
	optional: false,
	labelledBy: '',
	isSSNError: false,
};

export default SSNtext;