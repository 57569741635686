import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getDataForMarstoneRedirection, getCompositeLookUpData } from '../../../shared/Actions/AccOpeningActions';
import { manageLocalState } from '../../../shared/Actions/LocalStateManagementActions';
import { getFundAccountsListForNAO } from '../../../shared/Actions/FundAccountActions';
import { WSpinner } from '../../../common';
import * as Consts from './Consts';
import envConfig from 'env/EnvConfig';

const StyledDiv = styled.div`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
`;

const ErrorDiv = styled.div`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #ff0000;
`;

function MarstoneRedirectionComponent() {
    const dispatch = useDispatch();

    const marstoneNAOPayload = useSelector(state => state.accOpeningReducerData.marstoneNAOPayload);
    const isLoading = useSelector(state => state.accOpeningReducerData.isLoading);
    const isError = useSelector(state => state.accOpeningReducerData.isError);
    const masterLookupStateData = useSelector(state => state.masterLookUpData);
    const fundAccountData = useSelector(state => state.fundAccountData);
    const [redirectToNAO, setRedirectToNAO] = useState(false);
    const [isMoneyMartketAccount, setIsMoneyMartketAccount] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('sessionId');

    const getCompositeData = compositePayloadData => {
        const payload = [];
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (!masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
        dispatch(getCompositeLookUpData(payload));
    };

    useEffect(() => {
        const compositePayloadData = [
            'citizenship',
            'marital_status',
            'prefix',
            'suffix',
            'relationship',
            'gender',
            'mailing_addr_type',
            'employment_status',
            'industry',
            'prim_src_income',
            'annual_income',
            'net_worth',
            'tax_filling_status',
            'mil_status',
            'mil_serv_branch',
            'filter_min_inv',
            'filter_fund_type',
            'filter_risk',
            'mil_rank_01',
            'mil_rank_02',
            'mil_rank_03',
            'mil_rank_04',
            'mil_rank_05',
        ];
        getCompositeData(compositePayloadData);
        dispatch(getDataForMarstoneRedirection({ sessionId, token: localStorage.getItem('token') }));
    }, [dispatch]);

    const getSSNData = personalInformation => {
        return {
            ssnOne: personalInformation.ssn.substr(0, 3),
            ssnTwo: personalInformation.ssn.substr(3, 2),
            ssnThree: personalInformation.ssn.substr(5, 4),
            fullSSN: personalInformation.ssn,
        };
    };

    const getPrimaryPhoneDetails = phoneInformation => {
        return {
            phoneNumber: `${phoneInformation.primary.number.substr(0, 3)}-${phoneInformation.primary.number.substr(
                3,
                3,
            )}-${phoneInformation.primary.number.substr(6, 4)}`,
            contactTime: '',
            isMobile: !!(phoneInformation.primary.isMobile === 'true' || phoneInformation.primary.isMobile === true),
            countryCode: phoneInformation.primary.countryCode,
        };
    };

    const getSecondaryPhoneDetails = phoneInformation => {
        return {
            phoneNumber: `${phoneInformation.secondary.number.substr(0, 3)}-${phoneInformation.secondary.number.substr(
                3,
                3,
            )}-${phoneInformation.secondary.number.substr(6, 4)}`,
            contactTime: '',
            isMobile: !!(
                phoneInformation.secondary.isMobile === 'true' || phoneInformation.secondary.isMobile === true
            ),
            countryCode: phoneInformation.secondary.countryCode,
        };
    };

    const getPrimaryEmail = emailAddresses => {
        return {
            email: emailAddresses.primaryEmailAddress,
        };
    };

    const getSecondaryEmail = emailAddresses => {
        return {
            email: emailAddresses.secondaryEmailAddress,
        };
    };

    const getKey = (lookupString, lookupValue) => {
        if (lookupValue && lookupValue.toString().trim() !== '') {
            const lookupList = masterLookupStateData[lookupString.toString()]
                ? masterLookupStateData[lookupString.toString()].value
                : [];
            const lookupData = lookupList.find(item => item.key === lookupValue || item.value === lookupValue);
            if (lookupData) {
                return lookupData.key;
            }
        }
        return '';
    };

    const getRank = (branchKey, rank) => {
        return getKey(`mil_rank_${branchKey}`, rank);
    };

    const getPersonalInfoForAccountType = personalInfo => {
        const { personalInformation, contactInformation, phoneInformation, emailAddresses } = personalInfo;

        return {
            errorMsg: {
                prefixLocal: '',
                middleInitial: null,
                dateOfBirthLocal: '',
                maritalLocal: '',
            },
            fullSSN: personalInformation.ssn,
            isSSNerror: false,
            tooltipOpen: false,
            showMenu: false,
            loading: false,
            perDetailsHeadreClick: true,
            contactDetailsHeadreClick: true,
            citizenship: personalInformation.citizenship === 'us' ? 'U.S' : 'Non U.S',
            retrievedState: false,
            prefixLocal: personalInformation.prefix,
            dateOfBirthLocal: personalInformation.dob,
            genderLocal: personalInformation.gender,
            maritalLocal: personalInformation.maritalStatus,
            gender: personalInformation.gender,
            dateOfBirth: personalInformation.dob,
            prefix: personalInformation.prefix,
            maritalStatus: personalInformation.maritalStatus,
            suffix: personalInformation.suffix,
            readOnly: true,
            pageReady: false,
            ready: false,
            showError: false,
            sameAddress: contactInformation.isPhysicalAddressSame ? 'Y' : 'N',
            SSN: personalInformation.ssn,
            ssnData: getSSNData(personalInformation),
            emailAddressData: {
                primaryEmailDetails: getPrimaryEmail(emailAddresses),
                secondaryEmailDetails: getSecondaryEmail(emailAddresses),
            },
            primMailAddress: {
                lineOne: contactInformation.mailingAddress.addressLine1,
                lineTwo: contactInformation.mailingAddress.addressLine2,
                zipCode: contactInformation.mailingAddress.zipCode,
                city: contactInformation.mailingAddress.city,
                state: contactInformation.mailingAddress.state,
                mailTypeRadio: getKey('mailing_addr_type', contactInformation.mailingAddress.mailingAddressType),
                uspsAddressSelected: false,
                addressFormatApiCalled: false,
                marstoneFlag: true,
            },
            primPhysicalAddress: !contactInformation.isPhysicalAddressSame
                ? {
                      lineOne: contactInformation.physicalAddress.addressLine1,
                      lineTwo: contactInformation.physicalAddress.addressLine2,
                      zipCode: contactInformation.physicalAddress.zipCode,
                      city: contactInformation.physicalAddress.city,
                      state: contactInformation.physicalAddress.state,
                      mailTypeRadio: getKey('mailing_addr_type', contactInformation.mailingAddress.mailingAddressType),
                      uspsAddressSelected: false,
                      addressFormatApiCalled: false,
                      marstoneFlag: true,
                  }
                : null,

            primaryPhoneDetails: getPrimaryPhoneDetails(phoneInformation),
            secondaryPhoneDetails: getSecondaryPhoneDetails(phoneInformation),
        };
    };

    const getEmploymentInfo = employmentInformation => {
        return {
            empStatus: getKey('employment_status', employmentInformation.status),
            empStatusOther: '',
            empClassInfo: '',
            industry: getKey('industry', employmentInformation.industry),
            occupation: employmentInformation.occupation,
            employersName: employmentInformation.employerName,
            primarySourceofIncome: employmentInformation.primarySourceofIncome
                ? getKey('prim_src_income', employmentInformation.primarySourceofIncome)
                : '',
            employerAddress: {
                addressLine1: employmentInformation.address.addressLine1,
                addressLine2: employmentInformation.address.addressLine2,
                zip: employmentInformation.address.zipCode,
                state: employmentInformation.address.state,
                city: employmentInformation.address.zipCode,
            },
        };
    };

    const getFormattedTaxBracket = taxBracket => {
        const isDecimalFormat = parseFloat(taxBracket) % 1 > 0;
        return isDecimalFormat ? taxBracket : `${taxBracket}.00%`;
    };

    const getFinancialInformation = financialInformation => {
        return {
            retrievedState: true,
            taxBracket:
                financialInformation.taxBracket !== '' && getFormattedTaxBracket(financialInformation.taxBracket),
            annualIncome: getKey('annual_income', financialInformation.annualIncome),
            netWorth: getKey('net_worth', financialInformation.netWorth),
            taxFilingStatus: getKey('tax_filling_status', financialInformation.taxFilingStatus),
        };
    };

    const getMilitaryInformation = militaryInformation => {
        return {
            currServingUSM: militaryInformation.hasServed ? 'Y' : 'N',
            commissionCourse: militaryInformation.hasServed ? militaryInformation.commissionSource : '',
            milityStatus: militaryInformation.hasServed ? getKey('mil_status', militaryInformation.militaryStatus) : '',
            branchOfService: militaryInformation.hasServed ? getKey('mil_serv_branch', militaryInformation.branch) : '',
            rank: militaryInformation.hasServed
                ? getRank(getKey('mil_serv_branch', militaryInformation.branch), militaryInformation.rank)
                : '',
            serviceFromDate: militaryInformation.hasServed ? militaryInformation.startDate : '',
            serviceToDate: militaryInformation.hasServed ? militaryInformation.endDate : '',
            militaryOthers: '',
            retrievedState: true,
            showError: false,
        };
    };

    const getOpenAccPageTwoMilitaryInfo = militaryInformation => {
        return {
            currServingUSM: militaryInformation.hasServed ? 'Y' : 'N',
            milityStatus: militaryInformation.hasServed ? getKey('mil_status', militaryInformation.militaryStatus) : '',
            branchOfService: militaryInformation.hasServed ? getKey('mil_serv_branch', militaryInformation.branch) : '',
            rank: militaryInformation.hasServed
                ? getRank(getKey('mil_serv_branch', militaryInformation.branch), militaryInformation.rank)
                : '',
            serviceFromDate: militaryInformation.hasServed ? militaryInformation.startDate : '',
            serviceToDate: militaryInformation.hasServed ? militaryInformation.endDate : '',
            commissionCourse: militaryInformation.hasServed ? militaryInformation.commissionSource : '',
            militaryOthers: '',
        };
    };

    const getOpenAccPageTwoFinancialInfo = financialInformation => {
        return {
            annualIncome: getKey('annual_income', financialInformation.annualIncome),
            taxBracket:
                financialInformation.taxBracket !== '' && getFormattedTaxBracket(financialInformation.taxBracket),
            netWorth: getKey('net_worth', financialInformation.netWorth),
            taxFilingStatus: getKey('tax_filling_status', financialInformation.taxFilingStatus),
        };
    };

    const getOpenAccPageTwoEmpInfo = employmentInformation => {
        return {
            empStatus: getKey('employment_status', employmentInformation.status),
            empStatusOther: '',
            industry: getKey('industry', employmentInformation.industry),
            occupation: employmentInformation.occupation,
            employersName: employmentInformation.employerName,
            employerAddress: {
                addressLine1: employmentInformation.address.addressLine1,
                addressLine2: employmentInformation.address.addressLine2,
                zip: employmentInformation.address.zipCode,
                state: employmentInformation.address.state,
                city: employmentInformation.address.zipCode,
            },
            primarySourceofIncome: employmentInformation.primarySourceofIncome
                ? getKey('prim_src_income', employmentInformation.primarySourceofIncome)
                : '',
            empClassInfo: '',
        };
    };

    const getOpenAccPageTwoPersonalInfo = openAccPersonalInfo => {
        const { personalInformation, contactInformation, phoneInformation, emailAddresses } = openAccPersonalInfo;
        return {
            prefix: getKey('prefix', personalInformation.prefix),
            suffix: getKey('suffix', personalInformation.suffix),
            dateOfBirth: personalInformation.dob,
            gender: getKey('gender', personalInformation.gender),
            maritalStatus: personalInformation.maritalStatus,
            citizenship: getKey('citizenship', personalInformation.citizenship),
            ssnData: getSSNData(personalInformation),
            mailingAddress: {
                addressType: getKey('mailing_addr_type', contactInformation.mailingAddress.mailingAddressType),
                addressLine1: contactInformation.mailingAddress.addressLine1,
                addressLine2: contactInformation.mailingAddress.addressLine2,
                zip: contactInformation.mailingAddress.zipCode,
                city: contactInformation.mailingAddress.city,
                state: contactInformation.mailingAddress.state,
            },
            sameAddress: contactInformation.isPhysicalAddressSame ? 'Y' : 'N',
            physicalAddress: !contactInformation.isPhysicalAddressSame
                ? {
                      addressLine1: contactInformation.physicalAddress.addressLine1,
                      addressLine2: contactInformation.physicalAddress.addressLine2,
                      zip: contactInformation.physicalAddress.zipCode,
                      city: contactInformation.physicalAddress.city,
                      state: contactInformation.physicalAddress.state,
                  }
                : {},
            contactDetails: {
                primaryPhone: getPrimaryPhoneDetails(phoneInformation),
                secondaryPhone: getSecondaryPhoneDetails(phoneInformation),
            },
            emailAddress: {
                primaryEmail: getPrimaryEmail(emailAddresses),
                secondaryEmail: getSecondaryEmail(emailAddresses),
            },
        };
    };

    const getOpenAccountPageTwoInformation = marstonePayloadForMapping => {
        return {
            onlineId: 'onlineId',
            customerId: 'customerid',
            accountType:
                marstoneNAOPayload.accountType.toLowerCase().indexOf('joint') > -1
                    ? ''
                    : marstonePayloadForMapping.accountType,
            personalInfo: getOpenAccPageTwoPersonalInfo(marstonePayloadForMapping.personal),
            employementInfo: getOpenAccPageTwoEmpInfo(marstonePayloadForMapping.personal.employmentInformation),
            financialInfo: getOpenAccPageTwoFinancialInfo(marstonePayloadForMapping.personal.financialInformation),
            militaryInfo: getOpenAccPageTwoMilitaryInfo(marstonePayloadForMapping.personal.militaryInformation),
            jointPerInfo: {},
        };
    };

    const getAccountInformation = accType => {
        let accSelected = '';
        let accIDSelected = '';
        if (accType === 'Individual' || accType.toLowerCase().includes('joint')) {
            accSelected = 'General Investment Account';
            accIDSelected = 'gen_inv_acct';
        } else if (
            accType.trim() === 'Traditional IRA' ||
            accType.trim() === 'Roth IRA' ||
            accType.trim() === 'SEP IRA'
        ) {
            accSelected = 'Individual Retirement Account';
            accIDSelected = 'ira';
        } else if (
            accType.toLowerCase().includes('utma') ||
            accType.toLowerCase().includes('ugma') ||
            accType.toLowerCase().includes('529')
        ) {
            accSelected = 'Investing for Children';
            accIDSelected = 'inv_child';
        } else if (accType.trim() === 'Trust') {
            accSelected = 'Specialty Account';
            accIDSelected = 'spec_acct';
        } else if (accType.trim() === 'Money Market' || accType.trim() === 'Tax Advantaged Money Market') {
            setIsMoneyMartketAccount(true);
        }
        return { accSelected, accIDSelected };
    };

    const getOpenAccPageThreeInformation = marstonePayloadForMapping => {
        return {
            investmentInfo: {
                fundListData: marstonePayloadForMapping.investmentInformation.fundsList,
            },
            filterInfo: {
                minimumInvestmentList: [
                    ...masterLookupStateData.filter_min_inv.value.map(c => ({ ...c, checked: false })),
                ],
                riskList: [...masterLookupStateData.filter_risk.value.map(c => ({ ...c, checked: false }))],
                fundTypeList: [...masterLookupStateData.filter_fund_type.value.map(c => ({ ...c, checked: false }))],
                filteredMIL: [],
                filteredRisk: [],
                filteredFundType: [],
            },
        };
    };
    const formatInputValues = val => {
        return val && parseFloat(val).toFixed(2);
    };

    const getDisplayFundList = marstonePayloadForMapping => {
        const list = [...fundAccountData.fundAccountListForNAO];
        const selectedFundsList = marstonePayloadForMapping.investmentInformation.fundsList;
        if (selectedFundsList.length > 0) {
            for (let i = 0; i < list.length; i += 1) {
                for (let j = 0; j < selectedFundsList.length; j += 1) {
                    if (list[parseInt(i, 10)].fundNumber === selectedFundsList[parseInt(j, 10)].fundNumber) {
                        list[parseInt(i, 10)].checked = true;
                        list[parseInt(i, 10)].initialInvest = list[parseInt(i, 10)].initialInvest
                            ? formatInputValues(list[parseInt(i, 10)].initialInvest)
                            : formatInputValues(list[parseInt(i, 10)].initialInvestment);
                        list[parseInt(i, 10)].monthlyInvest = list[parseInt(i, 10)].monthlyInvest
                            ? formatInputValues(list[parseInt(i, 10)].monthlyInvest)
                            : formatInputValues(list[parseInt(i, 10)].minimumAutomaticInvestment);
                        list[parseInt(i, 10)].minimumAutomaticInvestment = formatInputValues(
                            list[parseInt(i, 10)].minimumAutomaticInvestment,
                        );
                        list[parseInt(i, 10)].initialInvestment = formatInputValues(
                            list[parseInt(i, 10)].initialInvestment,
                        );
                        list[parseInt(i, 10)].startDateValue = list[parseInt(i, 10)].startDateValue
                            ? list[parseInt(i, 10)].startDateValue
                            : list[parseInt(i, 10)].startDate;
                        list[parseInt(i, 10)].fundingOption = list[parseInt(i, 10)].fundingOption
                            ? list[parseInt(i, 10)].fundingOption
                            : 'init';
                        list[parseInt(i, 10)].startDate_err = '';
                        list[parseInt(i, 10)].monthlyInvestment_err = '';
                        list[parseInt(i, 10)].initialInvestment_err = '';
                    }
                }
            }
            return list;
        }
    };

    const formatInvestmentAmount = val => {
        return val ? parseFloat(val).toFixed(2) : 0;
    };

    const getSelectedFundsList = fundsList => {
        const selectedFunds = [...fundsList];
        for (let i = 0; i < fundsList.length; i += 1) {
            selectedFunds[parseInt(i, 10)].fundingOption =
                selectedFunds[parseInt(i, 10)].fundingOption.trim() === 'Initial Investment' ? 'init' : 'init_mon';
            selectedFunds[parseInt(i, 10)].initialInvestment = formatInvestmentAmount(
                selectedFunds[parseInt(i, 10)].initialInvestment,
            );
            selectedFunds[parseInt(i, 10)].initialInvest = formatInvestmentAmount(
                selectedFunds[parseInt(i, 10)].initialInvest,
            );
            selectedFunds[parseInt(i, 10)].monthlyInvest = formatInvestmentAmount(
                selectedFunds[parseInt(i, 10)].monthlyInvest,
            );
            selectedFunds[parseInt(i, 10)].minimumAutomaticInvestment = formatInvestmentAmount(
                selectedFunds[parseInt(i, 10)].minimumAutomaticInvestment,
            );
            selectedFunds[parseInt(i, 10)].minimumInitialMonthlyInvestment = formatInvestmentAmount(
                selectedFunds[parseInt(i, 10)].minimumInitialMonthlyInvestment,
            );
        }
        return selectedFunds;
    };

    const getPayloadMapping = marstonePayloadForMapping => {
        const { employmentInformation } = marstonePayloadForMapping.personal;
        const { militaryInformation } = marstonePayloadForMapping.personal;
        const { financialInformation } = marstonePayloadForMapping.personal;
        return {
            dataFlag: true,
            marstoneFlag: true,
            accSelected: getAccountInformation(marstonePayloadForMapping.accountType).accSelected,
            accIDSelected: getAccountInformation(marstonePayloadForMapping.accountType).accIDSelected,
            masterDataLoadedForOpenAcc: true,
            OpenAccPageOne: {
                companyNumber: marstonePayloadForMapping.companyNumber,
                accountType:
                    marstonePayloadForMapping.accountType.toLowerCase().indexOf('joint') > -1
                        ? ''
                        : marstonePayloadForMapping.accountType,
            },
            companyNumber: marstonePayloadForMapping.companyNumber,
            accountType:
                marstonePayloadForMapping.accountType.toLowerCase().indexOf('joint') > -1
                    ? ''
                    : marstonePayloadForMapping.accountType,
            masterDataLoadedForAccType: true,
            Individual: {
                perInfo: getPersonalInfoForAccountType(marstonePayloadForMapping.personal),
                empInfo: getEmploymentInfo(employmentInformation),
                finInfo: getFinancialInformation(financialInformation),
                milInfo: getMilitaryInformation(militaryInformation),
                benInfo: null,
                jointPerInfo: null,
            },
            OpenAccPageTwo: getOpenAccountPageTwoInformation(marstonePayloadForMapping),
            OpenAccPageThree: getOpenAccPageThreeInformation(marstonePayloadForMapping),
            selectedFundsList: getSelectedFundsList(marstonePayloadForMapping.investmentInformation.fundsList),
            displayFundList: getDisplayFundList(marstonePayloadForMapping),
            selectedFundCount: marstonePayloadForMapping.investmentInformation.fundsList.length,
        };
    };

    const getAccountTypeCode = accountType => {

        switch (accountType) {
            case 'Individual':
                return '100';
            case 'Joint':
                return '100';
            case 'Rollover IRA':
                return '202';
            default:
                return '100';
        }
    };

    const getSocialCode = accountType => {
        switch (accountType) {
            case 'Individual':
                return '1';
            case 'Joint':
                return '3';
            case 'Rollover IRA':
                return '14';
            default:
                return '1';
        }
    };
    useEffect(() => {
        if (Object.keys(marstoneNAOPayload).length > 0) {
            const fundListPayload = {
                companyNumber: marstoneNAOPayload.companyNumber,
                accountTypeCode: getAccountTypeCode(marstoneNAOPayload.accountType),
                socialCode: getSocialCode(
                    marstoneNAOPayload.accountType.toLowerCase().indexOf('joint') > -1
                        ? 'Joint'
                        : marstoneNAOPayload.accountType,
                ),
                accountType: marstoneNAOPayload.accountType,
                state: marstoneNAOPayload.personal.contactInformation.mailingAddress.state,
                token: localStorage.getItem('token'),
            };
            dispatch(getFundAccountsListForNAO(fundListPayload));
        }
    }, [dispatch, marstoneNAOPayload]);

    useEffect(() => {
        if (Object.keys(marstoneNAOPayload).length > 0) {
            if (fundAccountData.fundAccountListForNAO && fundAccountData.fundAccountListForNAO.length > 0) {
                dispatch(manageLocalState(getPayloadMapping(marstoneNAOPayload)));
                setRedirectToNAO(true);
            }
        } else {
            setRedirectToNAO(false);
        }
    }, [fundAccountData, marstoneNAOPayload]);

    const getRedirectionPath = () => {
        return {
            pathname: '/accountType',
            state: { sessionId },
        };
    };

    const getMoneyMarketRedirection = () => {
        return () => {
            window.location.href = envConfig.MARSTONE_MONEY_MARKET_FUND_URL;
            return null;
        };
    };

    return (
        <>
            {(isLoading || fundAccountData.isLoading) && !isError && <WSpinner loading={isLoading} />}
            {isError || fundAccountData.isError ? (
                <ErrorDiv>{Consts.MARSTONE_ERROR_TEXT}</ErrorDiv>
            ) : (
                <div className="container">
                    <StyledDiv>{Consts.MARSTONE_LOADING_TEXT}</StyledDiv>
                    {redirectToNAO && !isMoneyMartketAccount ? <Redirect to={getRedirectionPath()} /> : ''}
                    {isMoneyMartketAccount ? <Route component={getMoneyMarketRedirection()} /> : ''}
                </div>
            )}
        </>
    );
}

export default MarstoneRedirectionComponent;
