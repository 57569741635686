import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WButton from '../WButton/WButton';

const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const ModalDialog = styled.div`
    left: 16%;
    top: 18%;
    z-index: 999;
    width: 67%;
    padding: 40px 120px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
`;
const ConformationModalTitle = styled.div`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;

const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 20px;
`;
class TransactionLimitModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.firstElRef = React.createRef();
        this.lastElRef = React.createRef();
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';
    }

    componentWillUnmount() {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }

    // redirect last tab to first input
    onLastElementTab = e => {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            this.firstElRef.current.focus();
        }
    };

    render() {
        const { modalTitle, modalBodyText, modalBodyText2,
            modalBodyLink1Text,modalBodyLink1Url,
             primaryButtonText, onPrimaryClick,
            } = this.props;

        return (
            <ModalStyle tabIndex={0}>
                <ModalDialog role="dialog" aria-modal="true" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle id="VCM-Modal-title" style={{paddingBottom:'20px'}}> {modalTitle} </ConformationModalTitle>
                   {modalBodyLink1Text ? ( <ConformationModalBody>{modalBodyText} <a href={modalBodyLink1Url}
                    target={'_blank'} rel="noopener noreferrer" ><u>{modalBodyLink1Text}</u></a> {modalBodyText2}</ConformationModalBody>)
                    : 
                    (<ConformationModalBody>{modalBodyText} </ConformationModalBody>)}
                    <br/>
                    <FlexDiv>
                        
                        <WButton
                            ref={this.lastElRef}
                            buttontext={primaryButtonText || 'Ok'}
                            size="sm"
                            onClick={onPrimaryClick}
                            onKeyDown={this.onLastElementTab}
                        />
                    </FlexDiv>
                </ModalDialog>
            </ModalStyle>
        );
    }
}

TransactionLimitModal.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
};

TransactionLimitModal.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    modalBodyText2: null,
    onPrimaryClick: () => {},
    primaryButtonText: '',
};

export default TransactionLimitModal;
