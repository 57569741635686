import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EditGeneralAccountPreferencesComponent from './EditGeneralAccountPreferencesComponent';
import { accountPreferenceActions } from '../../../shared/Actions';
// import { saveData } from 'shared\Actions\InvestmentAccountsPreferenceActions';

const mapStateToProps = (state) => ({
  accountPreferenceData: state.accountPreferenceData
});

const mapDispatchToProps = {
  ...accountPreferenceActions,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(EditGeneralAccountPreferencesComponent));
