import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Styles from './TransactionDashboardStyles';
import { WButton, WMessageBar } from '../../../common';
import { tradingWindowActions } from '../../../shared/Actions';

const transactions = 'Transactions';

class transactionDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchase: false,
            liquidation: false,
            exchange: false,
            // amend: false,
            // cancel: false,
            displayNotification: false,
            notificationMsg: '',
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { location, getTradingClosureWindow, setNavigationFromTransactionDashboard } = this.props;
        setNavigationFromTransactionDashboard();
        getTradingClosureWindow({ token: localStorage.getItem('token') });
        const { state } = location || {};
        const { accLiquidated } = state || {};
        const message = `The Order has been successfully submitted.`;

        this.setState(() => {
            const returnObj = {
                // addedAccounts,
            };
            if (accLiquidated) {
                returnObj.displayNotification = true;
                returnObj.notificationMsg = { message };
            }
            return returnObj;
        });
    }

    buttonClickHandler = e => {
        this.setState({ [e.currentTarget.id]: true });
    };

    // // Handle Amend button click
    // buttonClickAmend=(e)=>{
    //     this.setState({[e.currentTarget.id]:true});
    // }

    onNotificationClose = () => {
        this.setState({ displayNotification: false });
    };

    render() {
        const { purchase, liquidation, exchange, displayNotification, notificationMsg } = this.state; // amend,cancel,
        return (
            <div data-test="transaction-dashboard">
                {displayNotification && (
                    <WMessageBar data-test="message-bar" onClose={this.onNotificationClose} text={notificationMsg} />
                )}
                <div className="container-fluid" style={Styles.containerStyle}>
                    <div className="mainScreen" style={Styles.containerStyle}>
                        <div style={Styles.lableStyle}>{transactions}</div>
                        <div style={Styles.labStyle}>
                            <WButton
                                id="purchase"
                                buttonstyle={Styles.buttnStyle}
                                buttontext="Buy"
                                onClick={this.buttonClickHandler}
                                data-test="buy-button"
                            />
                            {purchase ? <Redirect to="/transactionPurchases" /> : ''}
                        </div>
                        <div style={Styles.labStyle}>
                            <WButton
                                id="liquidation"
                                buttonstyle={Styles.buttnStyle}
                                buttontext="Sell"
                                onClick={this.buttonClickHandler}
                                data-test="sell-button"
                            />
                            {liquidation ? <Redirect to="/liquidation" /> : ''}
                        </div>
                        <div style={Styles.labStyle}>
                            <WButton
                                id="exchange"
                                buttonstyle={Styles.buttnStyle}
                                buttontext="Exchange"
                                onClick={this.buttonClickHandler}
                                data-test="exchange-button"
                            />
                            {exchange ? <Redirect to="/exchange" /> : ''}
                        </div>
                        {/* Hide Day2 content */}
                        {/* <div style = {Styles.labStyle}>
                        <WButton id="amend"
                            buttonstyle= {Styles.buttnStyle}
                                buttontext='Amend'
                                onClick={this.buttonClickAmend}
                        />
                        {amend? <Redirect to='/amendTransaction'/>:''}
                        </div>
                        <div style = {Styles.labStyle}>
                        <WButton id="cancel"
                            buttonstyle= {Styles.buttnStyle}
                                buttontext='Cancel'
                                onClick={this.buttonClick}
                        />
                        {cancel? <Redirect to='/MarketingAndPrivacy'/>:''}
                        </div> */}

                        {/* <WButton />
                        <WButton /> */}
                    </div>
                    <div className="mainScreenBottom" style={Styles.mainScreenbutton} />
                    <div style={Styles.divStyle} />
                </div>
            </div>
        );
    }
}

export const mapDispatchToProps = {
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    setNavigationFromTransactionDashboard: tradingWindowActions.setNavigationFromTransactionDashboard,
};

transactionDashboard.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
    getTradingClosureWindow: PropTypes.func,
    setNavigationFromTransactionDashboard: PropTypes.func,
};

transactionDashboard.defaultProps = {
    getTradingClosureWindow: () => {},
    setNavigationFromTransactionDashboard: () => {},
};

export default connect(null, mapDispatchToProps)(transactionDashboard);
