import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as FundAccountActionTypes from '../ReduxConstants/FundAccountConstants';

const {
    GET_FUND_ACCOUNTS_LIST_START,
    GET_FUND_ACCOUNTS_LIST_SUCCESS,
    GET_FUND_ACCOUNTS_LIST_FAILURE,
    SORT_FUND_ACCOUNTS_LIST_FAILURE,
    SORT_FUND_ACCOUNTS_LIST_SUCCESS,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_START,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
    GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE,
    GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS,
    GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR,
} = FundAccountActionTypes;

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    fundAccountList: [],
    error: {},
    sortByAsc: true,
    // For NAO
    fundAccountListForNAO: [],
    filteredFundsListNAO: [],
    isFiltered: false,
};

function fundAccountReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.GET_ACCOUNT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.GET_ACCOUNT_DETAILS_SUCCESS: {
            // For 204 response will be empty
            const res = action.response; 
            return {
                ...state,
                fundAccountList: res,
                isLoading: false,
                isSuccess: true,
            };
        }
        case ActionTypes.GET_ACCOUNT_DETAILS_FAILURE: {
            return {
                ...state,
                error: action.response,
                isLoading: false,
                isError: true,
            };
        }
        case ActionTypes.GET_ACCOUNT_DETAILS_RESET: {
            return {
                ...initialState,
            };
        }

        case GET_FUND_ACCOUNTS_LIST_FOR_NAO_START:
        case GET_FUND_ACCOUNTS_LIST_START: {
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isError: action.payload.isError,
            };
        }
        case GET_FUND_ACCOUNTS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                // fundAccountList: action.payload.filter((item, index) => index < 10),
                fundAccountList: action.payload,
                // fundAccountList: fundAccountListData,
            };
        }

        case GET_FUND_ACCOUNTS_LIST_FOR_NAO_FAILURE:
        case GET_FUND_ACCOUNTS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload,
            };
        }
        case SORT_FUND_ACCOUNTS_LIST_SUCCESS: {
            // sorting for fund account name
            const fundAccounts = action.fundList;
            if (state.sortByAsc) {
                fundAccounts.sort((a, b) => (a.fundName > b.fundName ? 1 : -1));
            } else {
                fundAccounts.sort((a, b) => (a.fundName > b.fundName ? -1 : 1));
            }
            return {
                ...state,
                fundAccountListForNAO: fundAccounts,
                sortByAsc: !state.sortByAsc,
                isFiltered: false,
            };
        }
        case SORT_FUND_ACCOUNTS_LIST_FAILURE: {
            return {
                ...state,
                error: ActionTypes.SORT_FUND_ERROR,
                isError: true,
            };
        }

        case GET_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                // fundAccountList: action.payload.filter((item, index) => index < 10),
                fundAccountListForNAO: action.payload,
                isFiltered: false,
                // fundAccountList: fundAccountListData,
                NAOFundAccountsList: action.payload,
            };
        }
        case GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_SUCCESS: {
            return {
                ...state,
                // fundAccountListForNAO: action.payload,
                // filteredFundsListNAO: action.payload,
                fundAccountListForNAO: action.payload,
                isFiltered: true,
            };
        }
        case GET_FILTERED_FUND_ACCOUNTS_LIST_FOR_NAO_ERROR: {
            return {
                ...state,
                isError: true,
                fundAccountListForNAO: state.fundAccountListForNAO,
                isFiltered: false,
            };
        }
        default:
            return state;
    }
}

export default fundAccountReducer;
