export default {
    pageHeading: 'Individual Account',
    assignObj: obj => {
        return obj;
    },
    yes: 'Y',
    cards: [
        {
            cardId: 'backupWithholding',
            cardHeading: 'Taxpayer Identification Number/Backup withholding certification',
            mainHeading: true,
            showBorder: false,
        },
        {
            cardId: 'docsToView',
            linkText: '',
            cardHeading: 'Documents to Review',
            showBorder: true,
            mainHeading: false,
        },
        // {
        //     cardId: 'docsToSign',
        //     preLinkText: "You'll need the free ",
        //     postLinkText: ' to view these documents.',
        //     linkText: 'Adobe Reader',
        //     cardHeading: 'Documents to Sign',
        //     showBorder: true,
        //     mainHeading: false,
        // },
    ],
    errorText: 'Please check the box above to continue',
    backUpText:
        'The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.',
    paratext: {
        key: 'paragraph',
        type: 'paragraph',
        value:
            'Under penalties of perjury, I certify that (1) The Social Security number/taxpayer identification number provided on this application is my correct number (or I am waiting fro number to be issued to me) and, (2) unless I have checked the box immediately above this statement, I am not subject to backup withholding because (a) I am exempt from backup withholding, or (b) I have not been notified by the IRS that I am subject to backup withholding of my failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding: and (3) I am a US citizen or other US person.',
    },
    stepperStyle: {
        width: '1107px',
        height: '60px',
        paddingRight: '90px',
        paddingLeft: '150px',
    },
    checkBoxWrapperStyle: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '20px',
    },
    checkBoxInputStyle: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #4F799F',
        borderRadius: 3,
        opacity: 1,
        height: 30,
        width: 30,
        flexShrink: 0,
    },
    checkBoxStyle: { position: 'absolute', top: '7px' },
    labelStyle: {
        paddingTop: '3px',
        textAlign: 'left',
        font: '600 16px/25px benton-sans',
        letterSpacing: 0,
        color: '#3E607E',
        opacity: 1,
    },
    checkLabel:
        'By checking the box, I certify under the penalty of perjury, that all information I have provided is true, complete and accurate',
    pages: ['Account Type', 'Personal Info', 'Investment Selection', 'Preferences', 'Verify', 'E-Consent'],
    technicalError: 'Technical error',
    errorFromEsignMasterDataMsg: 'Error in master Data of Esign',
    hintText:
        'The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.',
};

export const PERSONAL_CHECK_VALUE = '01';
export const WIRETRANSFER_VALUE = '03';
export const BANK_VALUE = '05';
export const NEW_APPLICATION = 'new application';
export const NO_DOCUMENT_AVAILABLE = 'Currently, the document to review is not available';
export const INDIVIDUALS = 'individuals';
export const NON_INDIVIDUALS = 'non-individuals';
export const TRADIONAL_IRA_TEXT = 'Traditional IRA';
export const ROTH_IRA_TEXT = 'Roth IRA';
export const SEP_IRA_TEXT = 'SEP IRA';
