import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';
import * as TransactionActionTypes from '../ReduxConstants/TransactionActionConstants';

// The following actions are not used in web.

export const getData = data => dispatch => {
    dispatch({
        type: ActionTypes.GET_ACCOUNT_SELECTION_DATA,
        ...data,
    });
};

export const saveData = data => dispatch => {
    dispatch({
        type: ActionTypes.SAVE_PURCHASE_DATA,
        ...data,
    });
};

export const submitPurchase = data => dispatch => {
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.SUBMIT_PURCHASE_DATA,
        ...payload,
    });
};

const replaceUndefinedOrNull = (key, value) => {
    if (value === null || value === undefined || value === '') {
        return undefined;
    }
    return value;
};

const getCleanedPayload = payload => {
    const cleanedObject = JSON.stringify(payload, replaceUndefinedOrNull, 4);
    return JSON.parse(cleanedObject);
};

export const getFunds = payload => dispatch => {
    const payloadApi = getCleanedPayload(payload);
    const env = 'GETFUNDS';
    dispatch({
        type: ActionTypes.GET_FUNDLIST_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_FUNDLIST,
        payloadApi,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.GET_FUNDLIST_SUCCESS,
                    [ActionTypes.GET_FUNDLIST]: response.data,
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_FUNDLIST_FAILURE,
                    [ActionTypes.GET_FUNDLIST]: response.data,
                });
            }
        },

        error => {
            dispatch({
                type: ActionTypes.GET_FUNDLIST_ERROR,
                response: error.data,
            });
        },
    );
};

// Following actions are used in web

export const submitPurchaseData = payload => dispatch => {
    const env = '';
    dispatch({
        type: ActionTypes.SUBMIT_PURCHASE_DATA_LOADING,
    });
    makeApiRequest(
        ActionTypes.SUBMIT_PURCHASE_DATA,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: ActionTypes.SUBMIT_PURCHASE_DATA_SUCCESS,
                    [ActionTypes.SUBMIT_PURCHASE_DATA]: {
                        response: response.data,
                        fundAccNumber: payload.fundAccountNumber,
                    },
                });
            } else {
                dispatch({
                    type: ActionTypes.SUBMIT_PURCHASE_DATA_FAILURE,
                    [ActionTypes.SUBMIT_PURCHASE_DATA]: response.data,
                });
            }
        },

        error => {
            dispatch({
                type: ActionTypes.SUBMIT_PURCHASE_DATA_FAILURE,
                response: error.data,
            });
        },
    );
};

/**
 * Sets the next page number in state
 *
 * @param {Object} payload
 */

export const setNextPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.SET_NEXT_PAGE_NUMBER_PURCHASE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.SET_NEXT_PAGE_NUMBER_PURCHASE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Sets the next page number in state when redirected from view positions
 *
 */

export const setNextPageNumberPositions = pageNumber => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_SUCCESS,
                payload: pageNumber,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Resets the page number to 1 in state
 *
 * @param {Object} payload
 */

export const resetPageNumber = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.RESET_PAGE_NUMBER_PURCHASE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.RESET_PAGE_NUMBER_PURCHASE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This is the action to set the state for each page in redux
 */
export const savePageState = payload => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.SAVE_PAGE_STATE_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.SAVE_PAGE_STATE_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * Gets contribution for IRA values
 */
export const getIRAContributionValues = payload =>  dispatch => {
    const env = '';
    dispatch({
        type: TransactionActionTypes.GET_IRA_CONTRIBUTION_VALUES_START,
    });
     makeApiRequest(
        ActionTypes.GET_IRA_CONTRIBUTION_VALUES,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: TransactionActionTypes.GET_IRA_CONTRIBUTION_VALUES_SUCCESS,
                    payload: response.data,
                    stateValue: payload.stateValue,
                });
            } else {
                dispatch({
                    type: TransactionActionTypes.GET_IRA_CONTRIBUTION_VALUES_FAILURE,
                    payload: response.data,
                    stateValue: payload.stateValue,
                });
            }
        },

        error => {
            dispatch({
                type: TransactionActionTypes.GET_IRA_CONTRIBUTION_VALUES_FAILURE,
                payload: error.data,
                stateValue: payload.stateValue,
            });
        },
    );
};

export const clearIraContributionValues = () => dispatch =>  {
    
    dispatch({
        type: TransactionActionTypes.GET_IRA_CONTRIBUTION_VALUES_CLEAR
    });
}

/**
 * This action is called when the user clicks cancel on the iraContributionWarning message
 */
export const cancelIRAContributionWarning = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.CANCEL_IRA_CONTRIBUTION_WARNING_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.CANCEL_IRA_CONTRIBUTION_WARNING_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This action is called when the user clicks cancel on the iraContributionWarning message
 */
export const cancelACHLimit = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.CANCEL_ACH_LIMIT_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.CANCEL_ACH_LIMIT_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This action is called when the user clicks cancel on the iraContributionWarning message
 */
export const acceptIRAContributionWarning = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TransactionActionTypes.ACCEPT_IRA_CONTRIBUTION_WARNING_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: TransactionActionTypes.ACCEPT_IRA_CONTRIBUTION_WARNING_FAILURE,
                payload: error,
            });
        }
    };
};

/**
 * This action is called when the user selects or deselects to esign consent
 */
export const setSelectedConsent = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_SELECTED_CONSENT_PURCHASE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the user selects or deselects prospectus
 */
export const setSelectedProspectus = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the user selects or deselects disclosure
 */
export const setSelectedDisclosure = (consentValue = false) => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS,
            payload: consentValue,
        });
    };
};

/**
 * This action is called when the submit of purchase or exchange is clicked
 */
export const resetEConsent = () => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.RESET_ECONSENT_PURCHASE_SUCCESS,
        });
    };
};

/**
 * This action is called when the submit of purchase or exchange is clicked
 */
export const setConsentTime = eConsentTime => {
    return async dispatch => {
        dispatch({
            type: TransactionActionTypes.SET_ECONSENT_TIME_PURCHASE_SUCCESS,
            payload: eConsentTime,
        });
    };
};

/**
 *
 * Validate rules for ACH transactions
 */
export const validateACHLimit = payload => {
    return  dispatch => {
        const env = '';
        dispatch({
            type: TransactionActionTypes.VALIDATE_ACH_LIMIT_START,
        });
        makeApiRequest(
            ActionTypes.VALIDATE_ACH_LIMIT,
            payload,
            env,
             (response => {
                if (response.data) {
                    dispatch({
                        type: TransactionActionTypes.VALIDATE_ACH_LIMIT_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: TransactionActionTypes.VALIDATE_ACH_LIMIT_FAILURE,
                        payload: response.data,
                    });
                }
            }),

            error => {
                dispatch({
                    type: TransactionActionTypes.VALIDATE_ACH_LIMIT_FAILURE,
                    payload: error.data,
                });
            },
        );
    };
};

export const clearPurchaseData = () => dispatch => {
    dispatch({
        type: TransactionActionTypes.CLEAR_PURCHASE_DATA,
    });
};

export const setCancelPurchase = selectedAccount => dispatch => {
    dispatch({
        type: TransactionActionTypes.SET_CANCEL_PURCHASE,
        payload: selectedAccount,
    });
};

export const setNotToCancelPurchase = () => dispatch => {
    dispatch({
        type: TransactionActionTypes.SET_NOT_TO_CANCEL_PURCHASE,
    });
};

/**
 * Gets  IRA contribution year values
 */
 export const getIRAContributionYearValues = payload => dispatch => {
    const env = '';
    dispatch({
        type: TransactionActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES_START,
    });
     makeApiRequest(
        ActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES,
        payload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: TransactionActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES_SUCCESS,
                    payload: response.data,
                    stateValue: payload.stateValue,
                });
            } else {
                dispatch({
                    type: TransactionActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE,
                    payload: response.data,
                    stateValue: payload.stateValue,
                });
            }
        },

        error => {
            dispatch({
                type: TransactionActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE,
                payload: error.data,
                stateValue: payload.stateValue,
            });
        },
    );
};

export const resetPurchaseSubmit = () => dispatch => {
    dispatch({
        type: TransactionActionTypes.RESET_PURCHASE_SUBMIT,
    });
};

export const resetGoToNextPageVariable = () => dispatch => {
    dispatch({
        type: TransactionActionTypes.RESET_GO_TO_NEXT_PAGE,
    });
};

export const setProportionateWhenOneFundFail = (payload) => dispatch => {
    dispatch({
        type: TransactionActionTypes.SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL,
        payload: payload
    });
}

export const setResubmitFlow = () => dispatch => {
    dispatch({
        type: TransactionActionTypes.IS_RESUBMIT_FLOW,
    });
}