const containerStyle = { backgroundColor: '#F9FBFC', position: 'relative' };

const bredCrumItmes = [{ name: 'Home', url: '/' },{name: 'Profiles & Preferences', url:'/profile'}, {name:'Account Messaging', url:'/accountmessaging'}];

const disableStyle = { display: 'none' };

const alerts = `Alerts for Seurity & Fraud`;
const botmLine = `If suspicious activity occurs on your account, you'll receive an alert. Responding to it as soon as possible helps us prevent financial loss and minimize any impact on you. Learn more about how we safeguard your accounts and what you can do to help at our Security Center on VCM.com`

export default {
    containerStyle,
    bredCrumItmes,
    disableStyle,
    alerts,
    botmLine
}