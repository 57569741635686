import { connect } from 'react-redux';
import {
    verifyProfileEmailInformations,
    otpConfirmProfileEmailInformations,
    updateProfileEmailInformations,
    addProfileEmailInformations,
    clearExistingActions,
} from 'shared/Actions/ProfileSettingsAction';
import { analyzeFormFieldError, analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import AddEmailInfoComponent from './AddEmailInfoComponent';

export const mapStateToProps = state => {
    const { profileInformationData } = state;
    const { isError, error } = profileInformationData;
    return {
        profileInformationData,
        notify: isError,
        message: {
            message: error,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    verifyProfileEmailInformations,
    otpConfirmProfileEmailInformations,
    updateProfileEmailInformations,
    addProfileEmailInformations,
    clearExistingActions,
    analyzeFormFieldError,
    linkResponseError,
    analyzeClickEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(AddEmailInfoComponent));
