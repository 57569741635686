const heading = 'Oops!';
const desc = "We can't seem to find the page you are looking for.";
const buttontext = {
    backgroundColor: '#56565A',
    border: '1px solid #61285F45',
    borderRadius: '50px',
    position: 'absolute',
    top: '120px',
    width: '160px',
    height: '50px',
};
const buttonStyle = { color: '#FFFFFF', font: '600 16px/22px benton-sans' };

export default { heading, desc, buttontext, buttonStyle };
