const pageHeading = 'Traditional IRA';
const deliveryHeading = 'Edit Delivery Preferences';
const deliveryQuestion = 'How would you like to receive documents for all your Investment accounts?';
const taxDocumentsQuestion = 'How would you like to receive tax documents?';
const deliveryPreferences = [
    { key: 'dlvMethStmt', value: 'Investment Statements' },
    { key: 'dlvMethConfirms', value: 'Investment Confirmations' },
    { key: 'dlvMethLit', value: 'Investment Shareholder Reports (includes prospectus and other documents)' },
    { key: 'dlvMethAcctUpdNotify', value: 'Investment Eligible Correspondence' },
    { key: 'dlvMethProxies', value: 'Proxy' },
    { key: 'dlvMethTaxForms', value: 'Tax Documents' },
];
const taxDocuments = [
    { key: 'taxDocuments', value: 'Documents for Tax Accounts?' },
    // {key:'allDocuments',value:'Would you like all of your other documents from VCM Delivered the same way?'},
];
const deliveryValues = {
    key: 'deliveryValues',
    value: [
        { key: 'Online', value: 'Online' },
        { key: 'Paper', value: 'Paper' },
    ],
};
const allDocuments = {
    key: 'allDocuments',
    value: [
        { key: 'Yes', value: 'Yes' },
        { key: 'No', value: 'No' },
    ],
};
const states = {
    key: 'states',
    value: [
        { key: 'AL', value: 'Alabama' },
        { key: 'AK', value: 'Alaska' },
        { key: 'AZ', value: 'Arizona' },
        { key: 'AR', value: 'Arkansas' },
        { key: 'CA', value: 'California' },
    ],
};
const offButtonStyle = {
    borderColor: '#56565A',
    backgroundColor: '#B7B7B7',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
    height: '40px',
    background: '#818181DD 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1,
};
const offButtonStyleDisable = {
    borderColor: '#56565A',
    borderRadius: 30,
    borderWidth: 1,
    width: '50%',
    height: '40px',
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
};
const onButtonStyle = {
    backgroundColor: '#B7B7B7',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '40px',
    background: '#818181DD 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1,
};
const onButtonStyleDisable = {
    borderRadius: '25px !important',
    borderColor: '#56565A',
    zIndex: -1,
    left: '16%',
    borderWidth: 1,
    height: '35px',
    width: '50%',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
};
const TextOnStyle = {
    color: '#56565A',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%',
};
const TextOffStyle = {
    color: '#A2A2A2',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%',
};
const dateOfService = 'Dates of Service';
const erremailAdd = 'Please enter a valid email';
const errServiceToDate = 'End date cannot be prior to Start date';
const optional = '[ Optional ]';
const deliveryHint =
    'You will still get paper documents if other account holders have selected that option. For credit accounts, monthly statements will not be mailed if any account holder has selected online';
const FAQ1 =
    'Some documents may not be immediately available in large Print, Braille and/or Screen Reader formats Have questions?';
const FAQ2 = 'View our';
const FAQ3 = 'for additional assistance.';
const FAQLink = 'FAQ page';
const seasonalHeading = 'Add/Maintain Seasonal Address';
const setAddress = 'Would you like to Set Seasonal Address?';
const applyALL = 'Apply the settings to all of your Accounts';
const activeEditUrl = 'Edit Preferences';
const apply = 'Apply';
const editPreferencesUrls = [
    { url: '/profile', name: 'Profile and Preferences' },
    { url: '/accountPreference', name: 'Document Preferences' },
];
const accounts = {
    name: 'Account Name 1',
    number: '1234-5678-901234',
};
const separator = '|';
const fontawesomestyle = {
  height:'15px',
  width:'15px',
  position:'absolute',
  color:'#004A98',
  top: -10,
  right: -10
};
const radiostyles = {
    minHeight: 0,
};
const mandatoryFieldsHint = '* All fields are required unless mentioned optional';
export default {
    activeEditUrl,
    fontawesomestyle,
    apply,
    allDocuments,
    taxDocumentsQuestion,
    taxDocuments,
    separator,
    accounts,
    editPreferencesUrls,
    offButtonStyleDisable,
    onButtonStyle,
    TextOffStyle,
    offButtonStyle,
    onButtonStyleDisable,
    TextOnStyle,
    pageHeading,
    dateOfService,
    errServiceToDate,
    erremailAdd,
    states,
    optional,
    deliveryValues,
    deliveryHeading,
    deliveryQuestion,
    deliveryPreferences,
    deliveryHint,
    FAQ1,
    FAQ2,
    FAQ3,
    FAQLink,
    seasonalHeading,
    setAddress,
    applyALL,
    radiostyles,
    mandatoryFieldsHint,
    submitText: 'Submit',
    applyText: 'Apply',
    cancelText: 'Cancel',
    checkboxInputWrapperStyle: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '20px',
    },
    checkboxInputStyle: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #4F799F',
        opacity: 1,
        height: 25,
        width: 25,
    },
    checkBoxWrapperStyle: {
        marginBottom: 30,
        background: '#FAFAFA 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        height: 93,
        boxSizing: 'border-box',
        borderBottom: '1px solid rgba(210, 210, 210, 1)',
    },
    flexStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    applyWrapperStyle: {
        background: '#FAFAFA 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        height: 93,
        boxSizing: 'border-box',
        border: '1px solid rgba(210, 210, 210, 1)',
    },
    radioTextStyles: {
        font: '500 14px/22px benton-sans',
    },
};
