import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as TransactionActionConstants from '../ReduxConstants/TransactionActionConstants';

const {
    SAVE_PAGE_STATE_FAILURE,
    SAVE_PAGE_STATE_SUCCESS,
    GET_IRA_CONTRIBUTION_VALUES_SUCCESS,
    GET_IRA_CONTRIBUTION_VALUES_FAILURE,
    GET_IRA_CONTRIBUTION_VALUES_START,
    GET_IRA_CONTRIBUTION_VALUES_CLEAR,
    CANCEL_IRA_CONTRIBUTION_WARNING_FAILURE,
    CANCEL_IRA_CONTRIBUTION_WARNING_SUCCESS,
    CANCEL_ACH_LIMIT_SUCCESS,
    CANCEL_ACH_LIMIT_FAILURE,
    ACCEPT_IRA_CONTRIBUTION_WARNING_SUCCESS,
    ACCEPT_IRA_CONTRIBUTION_WARNING_FAILURE,
    VALIDATE_ACH_LIMIT_SUCCESS,
    VALIDATE_ACH_LIMIT_FAILURE,
    VALIDATE_ACH_LIMIT_START,
    IRA_CONTRIBUTION_WARNING_MESSAGE,
    ACH_CONTRIBUTION_WARNING_MESSAGE,
    SET_NEXT_PAGE_NUMBER_PURCHASE_FAILURE,
    SET_NEXT_PAGE_NUMBER_PURCHASE_SUCCESS,
    RESET_PAGE_NUMBER_PURCHASE_SUCCESS,
    RESET_PAGE_NUMBER_PURCHASE_FAILURE,
    NEXT_PAGE_ERROR,
    SET_SELECTED_CONSENT_PURCHASE_SUCCESS,
    SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS,
    SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS,
    RESET_ECONSENT_PURCHASE_SUCCESS,
    // SET_ECONSENT_NAME_PURCHASE_SUCCESS,
    SET_ECONSENT_TIME_PURCHASE_SUCCESS,
    CLEAR_PURCHASE_DATA,
    SET_CANCEL_PURCHASE,
    SET_NOT_TO_CANCEL_PURCHASE,
    SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_SUCCESS,
    SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_FAILURE,
    GET_IRA_CONTRIBUTION_YEAR_VALUES_START,
    GET_IRA_CONTRIBUTION_YEAR_VALUES_SUCCESS,
    GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE,
    RESET_PURCHASE_SUBMIT,
    RESET_GO_TO_NEXT_PAGE,
    REMOVE_STEP_DATA,
    SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL,
    IS_RESUBMIT_FLOW
} = TransactionActionConstants;

const initialState = {
    savePurchaseSelectedData: {
        key: '',
        order_Id: '',
        transactionType: '',
        lastModified: '',
        orderStatus: '',
        selectedAccountData: {
            accountType: '',
            accountName: '',
            accountNumber: '',
            currentValue: '',
            holdingValue: '',
            AutomaticInvestmentPlan: '',
        },
        selectedFundData: {
            fundName: '',
            fundNumber: '',
            fundingOption: '',
            initialInvestment: '',
            monthlyInvestment: '',
            startDate: '',
            count: '',
            total: '',
        },
        selectedFundSourceData: {
            paymentMode: '',
            fundSourceType: '',
            totalInvestment: '',
            bankAccountName: '',
            bankAccountNumber: '',
        },
        currentSecurities: {
            reinvest: false,
        },
        contribution: {
            contribution: '',
        },
        estimated: {
            estimatedFee: '',
            estimatedNetTradeAmt: '',
            effectiveDate: '',
        },
    },
    isLoading: false,
    isError: false,
    isSuccess: false,
    isAmend: false,
    amendObj: {},
    amendIndex: null,
    accountsAvailable: [],
    paymentMethod: [],
    isIRAContributionAccepted: true,
    iraContributionWarning: '',
    fundAccountNumber: '',
    achAccepted: true,
    custMessage: '',
    goToNextPage: false,
    currentPageNumber: 1,
    selectedConsent: false,
    selectedProspectus: false,
    selectedDisclosure: false,
    eConsentName: '',
    eConsentTime: '',
    cancelPurchase: false,
    showCancelPurchaseModal: false,
    overrideIRAWarning: false,
    cancelSelectedAcc: {},
    showAllFunds: false,
    isIRAContributionSuccess: false,
    isIRAContributionError: false,
    isIRAContributionAcceptedFlag:null,
    proportinateFundPassedList:[],
    proportionateFaildFundList:[],
    isProportionateResubmitFlow:false
};

let proportinateFundPassedList = [];
let pruchaseSubmitAPiCount = 0;
// BEGIN-NOSCAN

function purchaseReducer(state = initialState, action){ // NOSONAR
    switch (action.type) { // NOSONAR

        case ActionTypes.GET_ACCOUNT_SELECTION_DATA:
        case ActionTypes.SAVE_PURCHASE_DATA:
        case ActionTypes.GET_FUNDLIST:
        case ActionTypes.SUBMIT_PURCHASE_DATA: {
            return {
                ...state,
                ...action,
            };
        }
        case ActionTypes.GET_FUNDLIST_LOADER:
        case ActionTypes.SUBMIT_PURCHASE_DATA_LOADING: {
            return {
                ...state,
                ...action,
                isLoading: true,
                isError: false,
                isSuccess: false,
                goToNextPage: false,
            };
        }
        case ActionTypes.GET_FUNDLIST_SUCCESS: {
            return {
                ...state,
            };
        }
        case ActionTypes.SUBMIT_PURCHASE_DATA_SUCCESS: {
            const fundAccNumber = action.SUBMIT_PURCHASE_DATA.fundAccNumber
                ? action.SUBMIT_PURCHASE_DATA.fundAccNumber
                : action.SUBMIT_PURCHASE_DATA.response.accountNumber;
            proportinateFundPassedList.push(action?.SUBMIT_PURCHASE_DATA?.response?.fundAccountNumber)
            pruchaseSubmitAPiCount = pruchaseSubmitAPiCount +1;
            return {
                ...state,
                ...action,
                fundAccountNumber: fundAccNumber,
                isLoading: false,
                isError: false,
                isSuccess: true,
                goToNextPage: true,
                proportinateFundPassedList:proportinateFundPassedList,
                pruchaseSubmitAPiCount: pruchaseSubmitAPiCount
            };
        }
        case ActionTypes.GET_FUNDLIST_ERROR:
        case ActionTypes.GET_FUNDLIST_FAILURE:
        case ActionTypes.GET_ACCOUNT_DETAILS_FAILURE:
        case ActionTypes.SUBMIT_PURCHASE_DATA_FAILURE: {
            pruchaseSubmitAPiCount = pruchaseSubmitAPiCount +1;
            return {
                ...state,
                ...action,
                isLoading: false,
                isError: true,
                isSuccess: false,
                goToNextPage: true,
                pruchaseSubmitAPiCount: pruchaseSubmitAPiCount
            };
        }

        case RESET_PURCHASE_SUBMIT:{
            proportinateFundPassedList = [],
            pruchaseSubmitAPiCount = 0;
            return {
                ...state
            }
        }

        case IS_RESUBMIT_FLOW: {
            return{
                ...state,
                isProportionateResubmitFlow:true
            }
        }
        case RESET_GO_TO_NEXT_PAGE:{

            return{
                ...state,
                goToNextPage:false,

            }
        }
        // Sets the page state into redux state
        case SAVE_PAGE_STATE_SUCCESS: {
            return {
                ...state,
                isError: false,
                [`page${action.payload.pageIndex}`]: action.payload.data,
                goToNextPage: false,
            };
        }

        case SET_PROPORTIONATE_WHEN_ONE_FUND_FAIL: {

            return {
                ...state,
                proportionateFaildFundList: action?.payload
            }
        }
        case SAVE_PAGE_STATE_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                error: action.payload,
                goToNextPage: false,
            };
        }

        case GET_IRA_CONTRIBUTION_VALUES_SUCCESS: {
            return {
                ...state,
                isError: false,
                isIRAContributionAccepted: action.payload.iraAccepted,
                isIRAContributionAcceptedFlag:action.payload.iraAccepted,
                iraContributionWarning: action.payload.custMessage,
                isLoading: false,
                //  fundInvestmentStateValue: action.stateValue,
                fundSourceData: action.stateValue,
                goToNextPage: action.payload.iraAccepted,
                overrideIRAWarning: false,
                isIRAContributionSuccess:true
            };
        }

        case GET_IRA_CONTRIBUTION_YEAR_VALUES_SUCCESS: {
            return {
                ...state,
                isError: false,
                isIRAContributionYearData: action.payload,
                isLoading: false,
            };
        }

        case GET_IRA_CONTRIBUTION_VALUES_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                error: action.payload,
                isIRAContributionAccepted: false,
                isIRAContributionAcceptedFlag:false,
                fundSourceData: action.stateValue,
                iraContributionWarning: IRA_CONTRIBUTION_WARNING_MESSAGE,
                overrideIRAWarning: false,
                isIRAContributionError:true,
                isIRAContributionSuccess:false
            };
        }

        case GET_IRA_CONTRIBUTION_YEAR_VALUES_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                error: action.payload
            };
        }

        case GET_IRA_CONTRIBUTION_VALUES_START: {
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSuccess: false,
                isIRAContributionError:false,
                isIRAContributionSuccess:false
            };
        }
        case GET_IRA_CONTRIBUTION_VALUES_CLEAR: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSuccess: false,
                isIRAContributionError:false,
                isIRAContributionSuccess:false
            };
        }

        case GET_IRA_CONTRIBUTION_YEAR_VALUES_START: {
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSuccess: false,
            };
        }

        case CANCEL_IRA_CONTRIBUTION_WARNING_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                isIRAContributionAccepted: true,
                isIRAContributionAcceptedFlag: true,
                iraContributionWarning: '',
                fundInvestmentStateValue: {},
                overrideIRAWarning: false,
            };
        }

        case CANCEL_IRA_CONTRIBUTION_WARNING_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                isIRAContributionAccepted: true,
                isIRAContributionAcceptedFlag:true,
                iraContributionWarning: '',
                fundInvestmentStateValue: {},
                error: action.payload,
            };
        }

        case CANCEL_ACH_LIMIT_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                achAccepted: true,
                custMessage: '',
            };
        }

        case VALIDATE_ACH_LIMIT_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                achAccepted: false,
                custMessage: ACH_CONTRIBUTION_WARNING_MESSAGE,
            };
        }

        case CANCEL_ACH_LIMIT_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                error: action.payload,
                achAccepted: false,
                custMessage: ACH_CONTRIBUTION_WARNING_MESSAGE,
            };
        }

        case ACCEPT_IRA_CONTRIBUTION_WARNING_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                isIRAContributionAccepted: true,
                isIRAContributionAcceptedFlag: true,
                iraContributionWarning: '',
                overrideIRAWarning: true,
            };
        }

        case ACCEPT_IRA_CONTRIBUTION_WARNING_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                isIRAContributionAccepted: false,
                isIRAContributionAcceptedFlag:false,
                iraContributionWarning: IRA_CONTRIBUTION_WARNING_MESSAGE,
            };
        }
        case VALIDATE_ACH_LIMIT_START: {
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        }
        case VALIDATE_ACH_LIMIT_SUCCESS: {
            return {
                ...state,
                isError: false,
                isLoading: false,
                achAccepted: action.payload.achAccepted,
                custMessage: action.payload.custMessage,
            };
        }

        case SET_NEXT_PAGE_NUMBER_PURCHASE_SUCCESS: {
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1,
                showAllFunds: true,
            };
        }
        case SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_FAILURE:
        case SET_NEXT_PAGE_NUMBER_PURCHASE_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
            };
        }

        case RESET_PAGE_NUMBER_PURCHASE_SUCCESS: {
            return {
                ...state,
                currentPageNumber: 1,
                showAllFunds: false,
            };
        }

        case RESET_PAGE_NUMBER_PURCHASE_FAILURE: {
            return {
                ...state,
                isError: true,
                error: NEXT_PAGE_ERROR,
                currentPageNumber: 1,
            };
        }
        case SET_SELECTED_CONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedConsent: action.payload,
            };
        }

        case SET_PROSPECTUS_CONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedProspectus: action.payload,
            };
        }

        case SET_DISCLOSURE_CONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                isError: false,
                selectedDisclosure: action.payload,
            };
        }

        case RESET_ECONSENT_PURCHASE_SUCCESS: {
            return {
                ...state,
                selectedConsent: false,
                selectedProspectus: false,
                selectedDisclosure: false,
                eConsentName: '',
                eConsentTime: '',
            };
        }

        // case SET_ECONSENT_NAME_PURCHASE_SUCCESS: {
        //     return {
        //         ...state,
        //         eConsentName: action.payload,
        //     };
        // }

        case SET_ECONSENT_TIME_PURCHASE_SUCCESS: {
            return {
                ...state,
                eConsentTime: action.payload,
            };
        }

        case CLEAR_PURCHASE_DATA: {
            return {
                ...initialState,
            };
        }

        case SET_CANCEL_PURCHASE: {
            return {
                ...state,
                showCancelPurchaseModal: true,
                cancelSelectedAcc: action.payload,
            };
        }

        case SET_NOT_TO_CANCEL_PURCHASE: {
            return {
                ...state,
                showCancelPurchaseModal: false,
                cancelSelectedAcc: {},
            };
        }

        case SET_NEXT_PAGE_NUMBER_POSITIONS_PURCHASE_SUCCESS: {
            return {
                ...state,
                currentPageNumber: action.payload,
            };
        }

        default:
            return state;
    }
}
 // END-NOSCAN
export default purchaseReducer;
