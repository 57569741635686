import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'reactstrap';

const StyleTooltip = styled(Tooltip)`
    .tooltip .arrow{display:none !important;}
    .bs-tooltip-auto[x-placement^='top'] .arrow,
    .bs-tooltip-bottom .arrow {
        bottom: 5px;
        right:0px !important;
        
    }
    .bs-tooltip-auto[x-placement^='top'] .arrow::before {
        border-top-color: transparent;
        border-width: 0;
        border-top: 1px solid #dededf;
        border-left: 1px solid #dededf;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        z-index: 1;
        box-shadow: -2px -2px 25px #000000;
        background-color: white;
        
    }
    .bs-tooltip-auto {
        position: absolute;
        will-change: transform;
        top: 0px;
        left:-100px !important;
        transform: translate3d(318px, 889px, 0px);
    }
    .tooltip-inner {
        width: auto;
        max-width:220px;
        padding: 10px 40px 10px 30px;
        color: #212529;
        background-color: #fffefe;
        border: 1px solid #dededf;
        box-shadow: rgb(0 0 0 / 16%) 0rem 0.1875rem 0.375rem;
        border-radius: 0;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        &::before {
            content: '';
            width: 0px;
            height: 0px;
            border-bottom: 25px solid transparent;
            border-right: 25px solid rgba(139, 193, 5, 1);
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .tooltip.show {
        opacity: 1;
    }
`;

const StyledToolTip = props => {
    const {
        placement,
        tooltipOpen,
        targetID,
        triggerEvt,
        tooltipText,
        handleToggle,
        autohide,
        mouseover,
        tooltipTitle
    } = props;
   
    return (
        <StyleTooltip
            placement={placement}
            isOpen={tooltipOpen}
            target={targetID}
            mouseOver={mouseover}
            toggle={handleToggle}
            autohide={autohide}
            trigger={triggerEvt}
        >
            <h2 className="sr-only" tabIndex="-1">{tooltipTitle}</h2>
            
            <span>{tooltipText}</span>
        </StyleTooltip>
    );
};

StyledToolTip.propTypes = {
    placement: PropTypes.string,
    tooltipOpen: PropTypes.bool,
    autohide: PropTypes.bool,
    targetID: PropTypes.string,
    triggerEvt: PropTypes.string,
    tooltipText: PropTypes.string,
    handleToggle: PropTypes.func,
    id: PropTypes.string,
    tooltipTitle: PropTypes.string,
    onKeyDown: PropTypes.func,
};

StyledToolTip.defaultProps = {
    placement: 'bottom',
    triggerEvt: 'focus',
    tooltipText: '',
    targetID: '',
    autohide: false,
    tooltipOpen: false,
    handleToggle: () => {},
    id: '',
    tooltipTitle: '',
    onKeyDown: () => {},
};

export default StyledToolTip;