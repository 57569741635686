/**
 * Tax Documents Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to select preferences for tax documents
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { WSpinner, WErrorModal, WBreadCrumb, CommonButtons, WRadio } from '../../../common';
import consts from './consts';

const PageWrapper = styled.div`
    min-height: 100vh;
    position: relative;
    padding: 33px 10px 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    .card {
        border: none;
        margin-bottom: 5px;
    }
`;

const PageHeading = styled.h1`
    margin-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 430px;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 700 30px/45px yorkten-slab-normal;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
`;

const SubHeading = styled.div`
    text-align: left;
    font: 400 14px/17px benton-sans;
    letter-spacing: 0;
    color: #b2b2b2;
    margin-bottom: 54px;
`;
const Cardd = styled(Card)`
    margin-bottom: 50px;
    > .card-header {
        background-color: #ffffff;
    }
    .card-body {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #ebedef;
        opacity: 1;
        margin-top: 20px;
    }
`;
const SectionHeading = styled.span`
    text-align: left;
    font: 700 22px/35px Yorkten Slab;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    padding-left: 10px;
`;

const CardBody = styled(Card.Body)`
    padding-bottom: 25px;
`;

const RadioSection = styled.div`
    opacity: 1;
`;

const RadioLabel = styled(Col)`
    font-weight: 500;
    font-family: benton-sans;
    font-size: 16px/22px;
    color: #000000;
    padding-bottom: 30px;
    min-width: 600px;
    div {
        max-width: 400px;
    }
`;

const FAQWrapper = styled.div`
    background: #ebedef 0% 0% no-repeat padding-box;
    opacity: 0.5;
    height: 70px;
    text-align: left;
    font: 500 14px/17px benton-sans;
    letter-spacing: 0.28px;
    opacity: 1;
    margin-bottom: 40px;
    div {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 27px;
        padding-right: 89px;
    }
    .faq-link {
        text-align: left;
        text-decoration: underline;
        font: 500 14px/17px benton-sans;
        letter-spacing: 0.28px;
        color: #2a92ee;
    }
`;

export default class TaxDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            onSubmitFlag: false,
            taxDelivery: 'online',
            allDocs: 'yes',
        };
        this.err = '';
    }

    transitionTo = () => {
        this.setState({ onSubmitFlag: true });
    };

    handleRadio = key => e => {
        switch (key) {
            case 'taxDelivery':
                this.setState({ [key]: e.target.id });
                break;
            case 'allDocs':
                this.setState({ [key]: e.target.id });
                break;
            default:
                break;
        }
    };

    renderContent = () => {
        const { taxDelivery, allDocs } = this.state;
        return (
            <Cardd>
                <Card.Header>
                    <SectionHeading>{consts.sectionHeading}</SectionHeading>
                </Card.Header>
                <CardBody>
                    <RadioSection>
                        <Row noGutters>
                            <RadioLabel>
                                <div>{consts.taxDelivery.key}</div>
                            </RadioLabel>
                            {consts.taxDelivery.value.map(field => (
                                <Col key={field.key}>
                                    <WRadio
                                        id={field.key}
                                        value={field.value}
                                        onClick={this.handleRadio(consts.taxDelivery.id)}
                                        selected={taxDelivery === field.key}
                                        radiotext={consts.radiotext}
                                        radiostyles={consts.radiostyles}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Row noGutters>
                            <RadioLabel>
                                <div>{consts.allDocs.key}</div>
                            </RadioLabel>
                            {consts.allDocs.value.map(field => (
                                <Col key={field.key}>
                                    <WRadio
                                        id={field.key}
                                        value={field.value}
                                        onClick={this.handleRadio(consts.allDocs.id)}
                                        selected={allDocs === field.key}
                                        radiotext={consts.radiotext}
                                        radiostyles={consts.radiostyles}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </RadioSection>
                </CardBody>
            </Cardd>
        );
    };

    renderPage = () => {
        return (
            <>
                <PageWrapper>
                    <div className="container">
                        <WBreadCrumb breadCrumbItems={consts.taxDocumentsUrls} activeCrumb={consts.activePage} />
                        <PageHeading>{consts.pageHeading}</PageHeading>
                        <SubHeading>{consts.subheading}</SubHeading>
                        {this.renderContent()}
                        <FAQWrapper>
                            <div>
                                <span>{consts.FAQ1}</span>
                                <span className="faq-link">{consts.FAQ2}</span>
                                <span>{consts.FAQ3}</span>
                            </div>
                        </FAQWrapper>
                        <CommonButtons cancelClick={this.handleCancel} />
                    </div>
                </PageWrapper>
                <hr />
            </>
        );
    };

    render() {
        const { loading, error, onSubmitFlag } = this.state;
        return (
            <div className="MainContainer">
                {<WSpinner loading={loading} />}
                {error && <WErrorModal show={error} message="Technical error" />}
                {!error && this.renderPage()}
                {onSubmitFlag ? <Redirect to="/editPreference" /> : ''}
            </div>
        );
    }
}

TaxDocuments.propTypes = {};

TaxDocuments.defaultProps = {};
