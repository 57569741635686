/**
 * SetupSecurityQuestions
 ********************************
 * @version 1.0.1
 * @author Venu Sugguna
 * @description This page let's the user:
 * 1) Select the security questions and answer them. 2) Add Primary and add Additional email.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
// import routeConstants from 'routeConstants';

import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import '../Screens.css';
import SetupSecurityQuestionsForm from '../../SetupSecurityQuestions';
import SideHeader from '../../SideHeader/SideHeader';
import { WStepper, CommonButtons } from '../../../../common';
import data from './constants';
import styles from '../../UserManagementStyles';

const {stepperColStyle} = styles.stepperStyle;
const backStyle = { display: 'none'};

class SetupSecurityQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logOutRoute: {
        pathname: '/sign-in',
        // message: { message: 'You have been successfully logged out' },
      },
      redirect:false,
      form: {
        answer1: {
          value: '', errMsg: null, valid: false, label: 'Answer 1',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            patternMessage: userConstants.alphabetMessage,
            required: true
          }
        },
        answer2: {
          value: '', errMsg: null, valid: false, label: 'Answer 2',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            patternMessage: userConstants.alphabetMessage,
            required: true
          }
        },
        answer3: {
          value: '', errMsg: null, valid: false, label: 'Answer 3',
          rules: {
            maxLength: 30,
            pattern: userConstants.alphabetRegex,
            patternMessage: userConstants.alphabetMessage,
            required: true
          }
        },
        question1: {
          displayValue: '',
          options: [],
          value: '', 
          rules: {
            pattern: userConstants.selectRegex,
          },
          valid: false
        },
        question2: {
          displayValue: '',
          options: [],
          value: '', rules: {
            pattern: userConstants.selectRegex,
          },
          valid: false
        },
        question3: {
          displayValue: '',
          options: [],
          value: '', rules: {
            pattern: userConstants.selectRegex,
          },
          valid: false
        }
      },
      formIsValid: false,
      ...props.setupSecurityQuestionsState
    };
    this.onBack = this.onBack.bind(this);

    this.onNext = this.onNext.bind(this);
  
    this.onChange = this.onChange.bind(this);
  
    this.onBlur = this.onBlur.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { loginState, history, setDocumentPreference, getCustomerProfile, manageLocalState, customerProfile } = nextProps;
     const { isProfileCompleted }  = customerProfile;
    //  const isProfileCompleted = true; 
    // const { firstName, lastName } = customerProfile;
    // const userName = `${firstName  } ${  lastName}`;
    let updatedState = { ...state };
    if (loginState && loginState.documentPreference && !loginState.isLoading) {
        if (loginState.documentPreference.errorCode === 0) {
          localStorage.setItem('isSecurityQuestionsSet', true);
          if(isProfileCompleted){
            history.push({
              pathname: '/accountholder',
            });
            setDocumentPreference('');
          } else {
            history.push({
              pathname: '/reviewPersonalDetails',
            });
            setDocumentPreference('');
          }
          // history.push({
          //   pathname: '/Profile',
          // });
            setDocumentPreference('');
        } else {
            localStorage.setItem('isSecurityQuestionsSet', false);
            updatedState = { ...state, error: loginState.documentPreference.result };
        }
    }
    if (loginState.getCustomerProfile) {
        const customerData = { customerProfile: { ...loginState.getCustomerProfile } };
        getCustomerProfile('');
        manageLocalState(customerData);
    }
    return updatedState;
   }
   // const { setJWTToken, getCustomerProfile, customerProfile } = this.props;
   
  componentDidMount() {
    const { setJWTToken, getCompositeLookUpData, customerProfile, getCustomerProfile, location } = this.props;
    const { isProfileCompleted, firsttimeloginflag } = customerProfile;
    const { otpVerifyCompleted } = location || {};
    const token = localStorage.getItem('token');
    localStorage.setItem('isSecurityQuestionsSet', false);
    if(token && otpVerifyCompleted){
      setJWTToken(token);
      if(!customerProfile){
          getCustomerProfile({})
      }
    } else {
      this.handleLogOut();
    }
    if(!isProfileCompleted && !firsttimeloginflag) {
      const { history } = this.props;
      const path = '/reviewPersonalDetails';
      history.push(path);
    }
    getCompositeLookUpData(['security_ques']);
  }

  onChange(event, fieldId) { inputChangedHandler(event, fieldId, this); }

  onBlur(fieldId) { inputValidityHandler(fieldId, this); }

  onBack() {
    this.handleLogOut();
  }

  

  onNext() {
    const { manageLocalState, setDocumentPreference } = this.props;
    const quesData = { setupSecurityQuestionsState: { ...this.state } };
    const { setupSecurityQuestionsState } = quesData;
    const { form } = setupSecurityQuestionsState;
    manageLocalState(quesData);

    const payload = {
      "securityQuestions": [
          {
            [form.question1.value] : form.answer1.value,
            [form.question2.value] : form.answer2.value,
            [form.question3.value] : form.answer3.value,
          }
      ],
     // dPref:'paper'
    };

    setDocumentPreference(payload);
  }

  handleLogOut() {
    const token = localStorage.getItem('token');
    if (token) {
        Auth.signOut()
            .then(() => {
                localStorage.clear();
                this.setState({
                    redirect: true,
                    logOutRoute: {
                        pathname: '/sign-in',
                    },
                });
            })
            .catch(() => {
                // console.log(err)
            });
    } else {
        this.setState({
            redirect: true,
            pathname: '/',
        });
    }
}
  // Additional email has been removed
  // addEmailHandler = () => {
  //   this.setState((state) => (
  //     {
  //       ...state,
  //       addEmail: true,
  //       form: {
  //         ...state.form,
  //         additionalEmail: {
  //           value: null, errMsg: null, valid: false, label: 'Additional Email',
  //           rules: { isEmail: true, maxLength: 30 }
  //         }
  //       }
  //     }))
  // };

  render() {
    const { questionsData, location } = this.props;
    // const  questionsData1   = {"value":[{"value":"What is the food you least liked as a child","key":"food_least_liked"},{"value":"What is the name of your first stuffed animal","key":"first_stuffed_animal"},{"value":"What did you earn your first medal or award for","key":"first_medal"},{"value":"What is your favorite security question","key":"sec_ques"},{"value":"What is the toy/stuffed animal you liked the most as a kid","key":"kid_stuffed_animal"},{"value":"What was the first computer game you played","key":"computer_game"},{"value":"What is your favorite movie quote","key":"movie_quote"},{"value":"What was the mascot of the first sports team you played on","key":"sports_mascot"},{"value":"What music album or song did you first purchase","key":"music_album"},{"value":"What is your favorite piece of art","key":"art_piece"},{"value":"What was your grandmother’s favorite dessert","key":"grandmother_dessert"},{"value":"What was the first thing you learnt to cook","key":"cook"},{"value":"What was your dream job as a child","key":"dream_job"},{"value":"Where did you meet your spouse/significant other","key":"spouse_meet"},{"value":"Where did you go for your favorite vacation","key":"vacation"},{"value":"Where were you on New Year’s Eve in the year 2000","key":"new_year_eve"},{"value":"Who is your favorite speaker/orator","key":"speaker"},{"value":"Who is your favorite book/movie character","key":"book"},{"value":"Who is your favorite sports player","key":"sports_player"},{"value":"What is the first name of your maternal grandfather","key":"maternal_grandfather"},{"value":"What was your age at your wedding","key":"wedding_age"},{"value":"What is the name of your first elementary school","key":"elementary_school"},{"value":"What city your father was born in","key":"father_born_city"},{"value":"What is the name of your first employer","key":"first_employer"},{"value":"What is the name of the street you grew up on","key":"stree"}],"key":"security_ques"};
    const { formIsValid, redirect,logOutRoute } = this.state;
    const { showStepper } = location || {};
    let redirectionResult = '';
    if (redirect) {
        redirectionResult = <Redirect to={logOutRoute} />;
    }
    return (
      <div className="container-fluid">
        {redirectionResult}
        <div className="container">
          <div style={data.stepperDivStyle}>
            {/* <WStepper className="userWizard" currentPage={2} pages={data.pages} stepperColStyle={stepperColStyle} /> */}
            { showStepper ? <WStepper
                        className="userWizard"
                        currentPage={2}
                        pages={data.pages}
                        stepperColStyle={stepperColStyle}
                   />:''}
          </div>
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={data.title}
                info={data.info} />
            </Col>
            <Col md lg xl>
              <SetupSecurityQuestionsForm
                questionsData={questionsData}
                state={this.state}
                changed={this.onChange}
                blurred={this.onBlur}
                addEmailHandler={this.addEmailHandler} />
            </Col>
          </Row>
        </div>
        <CommonButtons 
          nextClick={this.onNext} 
          disabled={!formIsValid} 
          blockstyle={styles.commonButtonsHalf} 
          backstyle={backStyle}
          arialabelback="Back to confirm OTP" 
          dataAnalyticId="UMS_SignIn_SetupSecurityQuestions"
        />
      </div>
    );
  }
}

SetupSecurityQuestions.propTypes = {
  questionsData: PropTypes.instanceOf(Object),
  setupSecurityQuestionsState: PropTypes.instanceOf(Object),
  manageLocalState: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
  getCompositeLookUpData: PropTypes.func,
  setDocumentPreference: PropTypes.func,
  getCustomerProfile: PropTypes.func,
  customerProfile: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  setJWTToken: PropTypes.func,
  location: PropTypes.instanceOf(Object)
}

SetupSecurityQuestions.defaultProps = {
  questionsData: null,
  setupSecurityQuestionsState: {},
  manageLocalState: () => { },
  history: [],
  getCompositeLookUpData: () => { },
  loginState: {},
  setDocumentPreference: () => {},
  getCustomerProfile: () => {},
  customerProfile: null,
  setJWTToken: () => {},
  location:{}
}

export default SetupSecurityQuestions;