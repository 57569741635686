const quickStyle = { padding: '10px 20px', minHeight: 60 };

const containerStyle = { marginTop: 20, marginBottom: 50 };

const backButtonStyle = { marginBottom: 42, marginTop: 42 };

export default {
    quickStyle,
    containerStyle,
    backButtonStyle,
};
