import { LocalStorageHelper } from "utils/sessionHelper";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    profileInformation: {},
    relationshipInformation: {},
    hasProfileInfo: false,
    addressLookUpData: {},
    hasAddressLookupInfo: false,
    addressLookupInfo: [],
    physicalAddress: null,
    mailingAddress: null,
    showUSPSVerifiedAddress: false,
    isAccountLocked: false,
    isFTLSuccess: false,
    showFinancialReminder: false,
    accountLevelAddresses: [],
    groupedAccountDetails: [],
    hasAccountLevelAddress: false,
    isFinancialEmpty: false,
    isEmploymentEmpty: false,
    isMilitaryEmpty: false,
    isDeleteAPICalled: false,
};

const getFinancialEmptyStatus = (financialInfo) => {
    const { annualIncome, financialInformationChangedOn, netWorth, taxBracket, taxFilingStatus } = financialInfo || {};
    return !(annualIncome || financialInformationChangedOn || netWorth || taxBracket || taxFilingStatus);
};

const getEmploymentEmptyStatus = (employmentInfo) => {
    const {
        addressLine2,
        employerName,
        employersZipcode,
        employmentStatus,
        industry,
        occupation,
    } = employmentInfo || {};
    return !(addressLine2 || employerName || employmentStatus || industry || occupation || employersZipcode);
};

const getMilitaryEmptyStatus = (militaryInfo) => {
    const {
        branchOfService,
        commissionSource,
        fromDate,
        isCurrentlyServing,
        militaryStatus,
        rank,
        toDate,
    } = militaryInfo || {};
    return (!(branchOfService || commissionSource || fromDate || toDate || rank || militaryStatus) && isCurrentlyServing);
};

const getAddressEmptyStatus = (addressInfo) => {
    return !(addressInfo || []).length;
};

const getPhoneEmptyStatus = (phoneInfo) => {
    return !(phoneInfo || []).length;
};

const getEmailEmptyStatus = (emailInfo) => {
    const { primaryEmail, secondaryEmail} = emailInfo || {};
    return !(primaryEmail || secondaryEmail);
};

const isValidDate = param => {
    if (!param) return false;
    const dateValue = new Date(param);
    return dateValue.getDate().toString() !== 'NaN';
};

const getDateValidated = (currentDate, finChangedOn) => {
    let showFinancialReminder;
    const curDate = currentDate.getDate();
    const finChangedOnDate = finChangedOn.getDate();
    const daysRemainingInFinMonth = 30 - finChangedOnDate;
    const daysDiff = curDate + daysRemainingInFinMonth;
    if(daysDiff < 30){
        showFinancialReminder = false;
    }else {
        showFinancialReminder = true;
    }
    return showFinancialReminder;
};

const getMonthValidated = (currentDate, finChangedOn) => {
    let showFinancialReminder;
    const curMonth = currentDate.getMonth();
    const finChangedOnMonth = finChangedOn.getMonth();
    const monthRemainingInFinYr = 12 - finChangedOnMonth;
    const monthDiff = curMonth + monthRemainingInFinYr;
    
    if(monthDiff < 12){
        showFinancialReminder = false;
    }else if(monthDiff === 12){
        showFinancialReminder = getDateValidated(currentDate, finChangedOn);
    }else {
        showFinancialReminder = true;
    }
    return showFinancialReminder;
};

const getShowFinancialReminder = (financialInformationChangedOn) => {
    let showFinancialReminder;
    const finChangedOn = new Date(financialInformationChangedOn);
    const currentDate = new Date();
    const curYear = currentDate.getFullYear();
    const finChangedOnYear = finChangedOn.getFullYear();
    const yearDiff = curYear - finChangedOnYear;
    if(yearDiff === 0){
        showFinancialReminder = false;
    }else if(yearDiff === 1){
        showFinancialReminder = getMonthValidated(currentDate, finChangedOn);
    }else {
        showFinancialReminder = true;
    }
    return showFinancialReminder;
};

const getAddrSearchParam = (data) => {
    const { isMailingAddress, isSeasonalAddress } = data;
    let searchFor = "isPhysicalAddress";
    if (isMailingAddress) searchFor = "isMailingAddress";
    if (isSeasonalAddress) searchFor = "isSeasonalAddress";
    return searchFor;
};

const getAddrIndex = (addressInformation, data) => {
    const searchFor = getAddrSearchParam(data);
    const index = addressInformation.findIndex((item) => item[`${searchFor}`]);
    return index;
};

const getGroupedAccountDetails = (accountLevelAddresses=[]) => {
    const grouppedAccountDetails = [];
    (accountLevelAddresses || []).forEach((account)=>{
        const indx = grouppedAccountDetails.findIndex(item => item.accountHoldingGroup === account.accountInformation.accountHoldingGroup);
        if(indx !== -1 ) {
            grouppedAccountDetails[`${indx}`].accounts.push({...account});
        } else {
            grouppedAccountDetails.push({
                accountHoldingGroup: account.accountInformation.accountHoldingGroup,
                accounts: [{...account}]
            });
        }
    });
    return grouppedAccountDetails;
};

const getUpdatedAddrResponse = (addressInformation, res) => {
    const newAddrInfo = [...addressInformation];
    const { addressUpdate } = res;
    addressUpdate.forEach((data)=>{
       const index = getAddrIndex(addressInformation, data);
       if (index !== -1) {
           if(data.zip) {
               const {isPhysicalAddress,isMailingAddress} = newAddrInfo[+index];
               if(isPhysicalAddress && isMailingAddress){
                   const searchFor = getAddrSearchParam(data);
                   newAddrInfo[+index][`${searchFor}`] = false;
                   newAddrInfo.push(data);
               }else {
                newAddrInfo[+index] = data;   
               }
           }else {
               newAddrInfo.splice(index,1);
           }
       } else {
           newAddrInfo.push(data);
       } 
    });
    
    return newAddrInfo;
};

function initialReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED:
        case ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION:
        case ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_RELATION_PHONE_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_INFORMATIONS:
        case ActionTypes.PROFILE_DELETE_PROFILE_PHONE_INFORMATIONS:
        case ActionTypes.PROFILE_DELETE_RELATIONSHIP_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_RELATIONSHIP_INFORMATIONS:
        case ActionTypes.PROFILE_ADD_RELATIONSHIP_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_MILITARY_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_OCCUPATION_INFORMATIONS:
        case ActionTypes.PROFILE_DELETE_CONTACT_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_CONTACT_INFORMATIONS:
        case ActionTypes.PROFILE_ADD_CONTACT_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_INFORMATIONS:
        case ActionTypes.PROFILE_ADD_EMAIL_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_EMAIL_INFORMATIONS:
        case ActionTypes.PROFILE_DELETE_EMAIL_INFORMATIONS:
        case ActionTypes.PROFILE_UPDATE_FINANCIAL_INFORMATIONS:
        case ActionTypes.PROFILE_GET_RELATIONSHIP_INFORMATION:
        case ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES:
        case ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES:
        case ActionTypes.PROFILE_VERIFY_EMAIL_INFORMATIONS:
        case ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_INFORMATIONS:
        case ActionTypes.GET_PROFILE_BASIC_INFORMATIONS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.PROFILE_REMOVE_EXISTING_VALUES: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                isAccountLocked: false,
                isFTLSuccess: false,
                isDeleteAPICalled: false,
            };
        }
        
        case ActionTypes.PROFILE_INFORMATIONS_ERROR: {

            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.response,
                updatedResponse:"Error"
            };
        }
        
        // Get Profile Basic Informations

        case ActionTypes.GET_PROFILE_BASIC_INFORMATIONS_SUCCESS: {
            const { response } = action;
            const { result:profileInformation= {} } = response || {};
            let showFinancialReminder = false;
            const { financialInformation, employmentInformation, militaryInformation, addressInformation, emailInformation, phoneInformation } = profileInformation || {};
            const { financialInformationChangedOn = '' } = financialInformation || {};
            
            if(isValidDate(financialInformationChangedOn)){
                showFinancialReminder = getShowFinancialReminder(financialInformationChangedOn);
            }
            const isFinancialEmpty = getFinancialEmptyStatus(financialInformation);
            const isEmploymentEmpty = getEmploymentEmptyStatus(employmentInformation);
            const isMilitaryEmpty = getMilitaryEmptyStatus(militaryInformation);
            const isAddressEmpty = getAddressEmptyStatus(addressInformation);
            const isPhoneEmpty = getPhoneEmptyStatus(phoneInformation);
            const isEmailEmpty = getEmailEmptyStatus(emailInformation);
            
            return {
                ...state,
                profileInformation,
                isLoading: false,
                hasProfileInfo: true,
                showFinancialReminder,
                isFinancialEmpty,
                isEmploymentEmpty,
                isMilitaryEmpty,
                isAddressEmpty,
                isPhoneEmpty,
                isEmailEmpty
            };
        }

        // Get Acount Level Addresses

        // Get User Profile Information 
        case ActionTypes.GET_USER_PROFILE_DATA_SUCCESS: {
            LocalStorageHelper.setItem('role',action?.response?.result?.role)
            return {
                memberProfileInformation: action?.response?.result
            };
        }

        case ActionTypes.GET_USER_PROFILE_DATA_FAILURE: {
            
            return {
                ...state
            };
        }

        case ActionTypes.GET_USER_PROFILE_DATA_ERROR: {
           
            return {
                ...state
            };
        }

        // 

        case ActionTypes.PROFILE_GET_ACCOUNT_LEVEL_ADDRESSES_SUCCESS: {
            const { response } = action;
            const { result: data ={} } = response || {};
            const { accountLevelAddresses=[] } = data || {};
            const groupedAccountDetails = getGroupedAccountDetails(accountLevelAddresses);
            return {
                ...state,
                isLoading: false,
                groupedAccountDetails,
                hasAccountLevelAddress: true,
                accountLevelAddresses
            };
        }

        case ActionTypes.PROFILE_UPDATE_ACCOUNT_LEVEL_ADDRESSES_SUCCESS: {
            const { accountLevelAddresses } = state;
            const { payload, response } = action;
            const { addressId, newMailingAddressId } = response.result;
            const { accountInformation, addressInformation, addressAction } = payload;
            const accIndex = accountLevelAddresses.findIndex((item)=>{
                return item.accountInformation.accountNumber === accountInformation.accountNumber;
            });
            const accountInfo = accountLevelAddresses[+accIndex];
            const addrIndex = accountInfo.addressInformation.findIndex(item => item.addressId === addressInformation.addressId);
            const { isSeasonalAddress, isPhysicalAddress} = addressInformation;
            if(addressAction === 'Edit'){
                const mailingIndex = accountInfo.addressInformation.findIndex(item => item.isMailingAddress);
                const { addressId: mailingAddressId } = accountInfo.addressInformation[+mailingIndex];
                const isPhysicalSameAsMailing = !mailingAddressId;
                if( !isSeasonalAddress && isPhysicalSameAsMailing){
                    if(isPhysicalAddress) {
                        accountInfo.addressInformation.splice(addrIndex,1,{...addressInformation});
                        accountInfo.addressInformation[+mailingIndex].addressId = newMailingAddressId;
                    }else {
                        accountInfo.addressInformation.splice(mailingIndex,1,{...addressInformation, addressId, isMailingAddress: true, isPhysicalAddress: false});
                    }
                    
                }else {
                    accountInfo.addressInformation.splice(addrIndex,1,{...addressInformation});
                }
            }else if(addressAction === 'Delete'){
                accountInfo.addressInformation.splice(addrIndex,1);
            }else {
                accountInfo.addressInformation.push({...addressInformation, addressId});
            }
            const groupedAccountDetails = getGroupedAccountDetails(accountLevelAddresses);
            
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                accountLevelAddresses,
                groupedAccountDetails,
            };
        }

        // Update Profile Informations

        case ActionTypes.PROFILE_UPDATE_PROFILE_PERSONAL_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            Object.keys(res).forEach((key)=>{
                profileInformation[`${key}`] = res[`${key}`];
            });
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true
            };
        }

        // Add Profile Contact Informations

        case ActionTypes.PROFILE_ADD_CONTACT_SUCCESS: {
            const { payload, response } = action;
           const res = action?.response?.result;

           if(state?.profileInformation)
           {
                const { profileInformation } = state;
                const { addressInformation = [] } = profileInformation;
                if(res?.addressUpdate != undefined)
                {
                    profileInformation.addressInformation = getUpdatedAddrResponse(addressInformation, res);
                }
                return {
                    ...state,
                    profileInformation,
                    isLoading: false,
                    isSuccess: true,
                    updatedResponse:response
                };
           }
           else
           {
                return {
                    ...state,
                    isLoading: false,
                    isSuccess: true,
                    updatedResponse:response
                };
           }
           
        }

        case ActionTypes.PROFILE_ADD_CONTACT_CLEAR: {

            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                updatedResponse:null
            };
        }

        // Update Profile Contact Informations

        case ActionTypes.PROFILE_UPDATE_CONTACT_SUCCESS: {
           const res = action.response.result;
           const { profileInformation } = state;
           const { addressInformation = [] } = profileInformation;
           
           profileInformation.addressInformation = getUpdatedAddrResponse(addressInformation, res);
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
                isFTLSuccess: true,
            };
        }
        
        case ActionTypes.PROFILE_LOCK_ACCOUNT: {
            return {
                ...state,
                isAccountLocked: true,
                isLoading: false,
                error: action.response,
            };
        }

        // Delete Profile Contact Informations

        case ActionTypes.PROFILE_DELETE_CONTACT_SUCCESS: {
             const res = action.response.result;
           const { profileInformation } = state;
           const { addressInformation = [] } = profileInformation;
           
           profileInformation.addressInformation = getUpdatedAddrResponse(addressInformation, res);
           return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true
            };
        }

        // Add Profile Email Informations

        case ActionTypes.PROFILE_ADD_EMAIL_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            const email = res.primaryEmail ? 'primaryEmail' : 'secondaryEmail';
            profileInformation.emailInformation[`${email}`] = res[`${email}`];
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
            };
        }
        
        // Update Profile Email Informations

        case ActionTypes.PROFILE_UPDATE_EMAIL_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            if(res.primaryEmail) profileInformation.emailInformation.primaryEmail = res.primaryEmail;
            if(res.secondaryEmail) profileInformation.emailInformation.secondaryEmail = res.secondaryEmail;
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
            };
        }
        
        // Verify Profile Email Informations

        case ActionTypes.PROFILE_VERIFY_EMAIL_SUCCESS: {
            const { profileInformation } = state;
            return {
                ...state,
                profileInformation,
                isLoading: false,
            };
        }
        
        // OPT Confirm Profile Email Informations

        case ActionTypes.PROFILE_OTP_CONFIRM_EMAIL_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            if(res.primaryEmail) profileInformation.emailInformation.primaryEmail = res.primaryEmail;
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
            };
        }

        // Delete Profile Email Informations

        case ActionTypes.PROFILE_DELETE_EMAIL_SUCCESS: {
            const { profileInformation } = state;
            delete profileInformation.emailInformation.secondaryEmail;
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                isDeleteAPICalled: true,
            };
        }

        // Update Profile Occupation Informations

        case ActionTypes.PROFILE_UPDATE_OCCUPATION_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            const { employmentInformation } = profileInformation;
            Object.keys(res).forEach((key)=>{
                employmentInformation[`${key}`] = res[`${key}`];
            });
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true
            };
        }

        // Update Profile Financial Informations

        case ActionTypes.PROFILE_UPDATE_FINANCIAL_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            const { financialInformation } = profileInformation;
            Object.keys(res).forEach((key)=>{
                financialInformation[`${key}`] = res[`${key}`];
            });
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true
            };
        }

        // Update Profile Military Informations

        case ActionTypes.PROFILE_UPDATE_MILITARY_SUCCESS: {
            const res = action.response.result;
            const { profileInformation } = state;
            const { militaryInformation } = profileInformation;
            Object.keys(res).forEach((key)=>{
                militaryInformation[`${key}`] = res[`${key}`];
            });
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true
            };
        }

        // Get Relationship Information
        
        case ActionTypes.PROFILE_GET_RELATIONSHIP_SUCCESS: {
            const { response } = action;
            const { result: relationshipInformation={} } = response || {};
            return {
                ...state,
                relationshipInformation,
                isLoading: false,
                isSuccess: true
            };
        }
        
        // Add Profile Relationship Informations

        case ActionTypes.PROFILE_ADD_RELATIONSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }

        // Update Profile Relationship Informations

        case ActionTypes.PROFILE_UPDATE_RELATIONSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }

        // Delete Profile Relationship Informations

        case ActionTypes.PROFILE_DELETE_RELATIONSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }

        // Delete Profile Phone Informations

        case ActionTypes.PROFILE_DELETE_PROFILE_PHONE_SUCCESS: {
            // getting the prev phone info
            const { profileInformation } = state;
            const { phoneInformation } = profileInformation || {};
            
            
            const index = phoneInformation.findIndex((item) => !item.isPrimary);
            phoneInformation.splice(index,1);
            
            // Updating the phone information
            profileInformation.phoneInformation = phoneInformation;
            
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
                isDeleteAPICalled: true
            };
        }

        // Update Profile Phone Informations

        case ActionTypes.PROFILE_UPDATE_PROFILE_PHONE_SUCCESS: {
            const res = action.response.result;
            // getting the prev phone info
            const { profileInformation } = state;
            const { phoneInformation } = profileInformation || {};
            
            const { phoneUpdate } = res;
            phoneUpdate.forEach((newPhoneInfo)=>{
                const { isPrimary } = newPhoneInfo;
                const index = phoneInformation.findIndex((oldPhoneInfo) => oldPhoneInfo.isPrimary === isPrimary);
                if(index !== -1){
                    phoneInformation.splice(index,1, newPhoneInfo);
                }else {
                    phoneInformation.push(newPhoneInfo);
                }
            });

            // Updating the phone information
            profileInformation.phoneInformation = phoneInformation;
            
            return {
                ...state,
                profileInformation,
                isLoading: false,
                isSuccess: true,
            };
        }

        // Update Relation Phone Informations

        case ActionTypes.PROFILE_UPDATE_RELATION_PHONE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }

        // Update Relation Email Informations

        case ActionTypes.PROFILE_UPDATE_RELATION_EMAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }

        // Update Relation Address Informations

        case ActionTypes.PROFILE_UPDATE_RELATION_ADDRESS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSuccess: true
            };
        }
        
        case ActionTypes.PROFILE_ADDRESS_LOOKUP_INFORMATION_SUCCESS: {
            const { response } = action;
            const { result:addressLookUpData ={}} = response || {};
            const { addressInformation = [] } = addressLookUpData || {};
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                addressLookUpData,
                hasAddressLookupInfo: true,
                addressLookupInfo: addressInformation
            };
        }
        
        case ActionTypes.PROFILE_SET_PHYSICAL_ADDRESS: {
            const { index } = action;
            const { addressLookupInfo = [], physicalAddress } = state;
            const prevState = addressLookupInfo[+index].isPhysicalAddress;
            addressLookupInfo[+index].isPhysicalAddress = !prevState;
            const { index: prevIndex = -1 } = physicalAddress || {};
            if(prevIndex !==-1 && prevIndex !== index ) addressLookupInfo[+prevIndex].isPhysicalAddress = false;
            const newPhysicalAddress = !prevState ? { ...addressLookupInfo[+index], index} : null;
            return {
                ...state,
                addressLookupInfo,
                physicalAddress: newPhysicalAddress,
                showUSPSVerifiedAddress: false,
            };
        }
        
        case ActionTypes.PROFILE_SET_MAILING_ADDRESS: {
            const { index } = action;
            const { addressLookupInfo = [], mailingAddress } = state;
            const prevState = addressLookupInfo[+index].isMailingAddress;
            addressLookupInfo[+index].isMailingAddress = !prevState;
            const { index: prevIndex = -1 } = mailingAddress || {};
            if(prevIndex !==-1 && prevIndex !== index ) addressLookupInfo[+prevIndex].isMailingAddress = false;
            const newMailingAddress = !prevState ? { ...addressLookupInfo[+index], index } : null;
            return {
                ...state,
                addressLookupInfo,
                mailingAddress:newMailingAddress,
                showUSPSVerifiedAddress: false,
            };
        }
        
        case ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED_ERROR:
        case ActionTypes.GET_MULTIPLE_ADDRESS_FORMATTED_SUCCESS: {
            const { response } = action;
            const { addresses=[] } = response || {};
            const { mailingAddress, physicalAddress } = state;
            let isError = false;
            if(addresses.length){
                const mailingAddressResponse = addresses.find(item => item.addressType === '0');
                const physicalAddressResponse = addresses.find(item => item.addressType === '1');
                const sameAddressResponse = addresses.find(item => item.addressType === '2');
                const { description:sameAddrError ='', } = sameAddressResponse || {};
                const { description:mailAddrError ='', } = mailingAddressResponse || {};
                const { description:phyAddrError ='', } = physicalAddressResponse || {};
                if(sameAddressResponse && !sameAddrError){
                    mailingAddress.uspsVerifiedAddress = { ...sameAddressResponse};
                    physicalAddress.uspsVerifiedAddress = { ...sameAddressResponse};   
                }else if(mailingAddressResponse && !mailAddrError) {
                    mailingAddress.uspsVerifiedAddress = { ...mailingAddressResponse};
                } else if(physicalAddressResponse && !phyAddrError){
                    physicalAddress.uspsVerifiedAddress = { ...physicalAddressResponse};
                }
            }else {
                isError = true;
            }
            return {
                ...state,
                mailingAddress,
                physicalAddress,
                showUSPSVerifiedAddress: true,
                isError,
                isLoading: false,
            };
        }
        
        case ActionTypes.PROFILE_SAVE_NEW_ADDRESS: {
            const { newAddress } = action;
            newAddress.uspsVerifiedAddress = { ...newAddress};
            newAddress.isNewAddress = true;
            const { addressLookupInfo } = state;
            addressLookupInfo.push(newAddress);
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                hasAddressLookupInfo: true,
                addressLookupInfo,
            };
        }
        
        case ActionTypes.SHOW_USPS_VERIFIED_ADDRESSES: {
            return {
                ...state,
                isLoading: false,
                showUSPSVerifiedAddress: true,
            };
        }
        
        case ActionTypes.PROFILE_FINANCIAL_REMAINDER_CLOSE: {
            return {
                ...state,
                showFinancialReminder: false,
            };
        }

        case ActionTypes.PROFILE_INFORMATION_EMPTY_NOTIFICATION: {
            return {
                ...state,
                isFinancialEmpty: false,
                isEmploymentEmpty: false,
                isMilitaryEmpty: false,
                isAddressEmpty: false,
                isPhoneEmpty: false,
                isEmailEmpty: false,
            };
        }

        default:
            return state;
    }
}

export default initialReducer;