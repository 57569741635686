import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GeneralAccountPreferencesComponent from './GeneralAccountPreferencesComponent';
import { accountPreferenceActions, loginActions } from "../../../shared/Actions";

const mapStateToProps = (state /* , props */) => (
  {
    accOpeningData: state.accOpeningReducerData,
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    accountPreferenceData: state.accountPreferenceData,
    loginData: state.loginData,
  }
);

const mapDispatchToProps = {
  ...accountPreferenceActions,
  ...loginActions
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(GeneralAccountPreferencesComponent));
