import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import {accOpeningActions, localStateManagementActions} from '../../../shared/Actions';
import PersonalDetailsForm from './PersonalDetailsForm';



export const mapStateToProps = (state /* , props */) => (
    {
      prefixData : state.masterLookUpData.prefix,
      suffixData : state.masterLookUpData.suffix,
      personalDetailsState: state.localStateManagementReducerData.personalDetailsState
    }
  );

const mapDispatchToProps = {
     ...accOpeningActions,
     ...localStateManagementActions
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(PersonalDetailsForm));