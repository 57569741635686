import KebabMenuHoverIcon from 'assets/icon-kebab-menu-hover.svg';
import KebabMenuIcon from 'assets/icon-kebab-menu.svg';
import PropTypes from 'prop-types';
import React, { useRef, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import StyledButton from '../StyledComponents';

const MenuContainer = styled.div`
    position: relative;
`;

const Separator = styled.hr`
    border-top: 1px solid #d2d2d2;
    opacity: 1;
    margin-left: ${props => props.mL || 0}rem;
    margin-right: ${props => props.mR || 0}rem;
    list-style:none;
`;
export const Menu = styled(StyledButton)`
    position: relative;
    height: 31px;
    width: 31px;
    background: url(${props => props.kebabImg}) no-repeat;
    &:hover {
        background: url(${props => props.kebabHoverImg}) no-repeat;
    }
    background-position: center;
`;
const MenuOptionsPopup = styled.ul`
    margin: 0;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.625rem;
    top: 100%;
    right: calc(-1.5rem + 7.5px);
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 10px solid #ffffff;
        top: -0.625rem;
        right: 1.5rem;
    }
`;
const MenuItem = styled.li`
    cursor: pointer;
    display: flex;
    padding: 10px;
    &&&:focus {
        background: #E6E6E6 0% 0% no-repeat padding-box;
        outline: 1px solid #75757A !important;
        z-index: 1;
        outline-offset: 0;
    }
    pointer-events:${props => props.disabled ? 'none': 'auto'};
`;
const MenuIcon = styled.span`
    width: 2.5rem;
    height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
`;

const MenuOptions = styled.span`
    margin-left: 0.3125rem;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: capitalize;
    opacity: 1;
    color:${props => props.disabled ? 'rgba(210, 210, 210, 1)': '#004a98'};
`;

const SPACEBAR_KEY_CODE = 32;
const ENTER_KEY_CODE = 13;
const LEFT_ARROW_KEY_CODE = 37;
const UP_ARROW_KEY_CODE = 38;
const RIGHT_ARROW_KEY_CODE = 39;
const DOWN_ARROW_KEY_CODE = 40;
const ESCAPE_KEY_CODE = 27;
const TAB_KEY_CODE = 9;
const AX_OPTION_LABEL = "More Options on"

export const focusListItem = (code, index, menuitems, menuItemRefs,itemClick,menuRef) => {
    if (code === DOWN_ARROW_KEY_CODE || code === RIGHT_ARROW_KEY_CODE) {
        if (index < menuitems.length - 1) {
            menuItemRefs.current[+index + 1].focus();
        }else {
            menuItemRefs.current[0].focus();
        }
    } else if (code === UP_ARROW_KEY_CODE || code === LEFT_ARROW_KEY_CODE) {
        if (index > 0) {
            menuItemRefs.current[+index - 1].focus();
        }else {
            menuItemRefs.current[menuitems.length-1].focus();
        }
    }else if(code === SPACEBAR_KEY_CODE || code === ENTER_KEY_CODE){
        itemClick()
    }else if(code === TAB_KEY_CODE || code === ESCAPE_KEY_CODE){
        menuRef.current.focus();
    }
};

export const setRef = (menuItemRefs, index)=>(el) =>{
    const refs = menuItemRefs;
    refs.current[+index] = el;
};

export const toggleHover = (menuItemRefs, index, mouseState,isDisabled) => () => {
    if (mouseState === 'enter' && !isDisabled) {
        menuItemRefs.current[+index].focus();
    }
};

export const onKeyEvent = ({index, menuitems, menuItemRefs, menuRef, itemClick,isDisabled}) => e => {
        if (!isDisabled) {
            let nextIndex = 'default';
            switch (e.keyCode) {
                case DOWN_ARROW_KEY_CODE:
                case RIGHT_ARROW_KEY_CODE:
                    nextIndex = (index + 1) % menuitems.length;
                    break;

                case UP_ARROW_KEY_CODE:
                case LEFT_ARROW_KEY_CODE:
                    nextIndex = index ? index - 1 : menuitems.length - 1;
                    break;

                case SPACEBAR_KEY_CODE:
                case ENTER_KEY_CODE:    
                    nextIndex = index;
                    break;
                default:
                    break;
            }
            if (nextIndex !== 'default') {
                const { isDisabled: nextDisabled = false } = menuitems[+nextIndex];
                if (!nextDisabled) {
                    focusListItem(e.keyCode, nextIndex, menuitems, menuItemRefs,itemClick,menuRef);                   
                    menuItemRefs.current[+nextIndex].focus();
                } else {
                    onKeyEvent ({nextIndex, menuitems, menuItemRefs, menuRef, itemClick,isDisabled})(e)                  
                }
            }
        }

      /**  switch (e.keyCode) {
            case ENTER_KEY_CODE:
            case SPACEBAR_KEY_CODE:{
                e.preventDefault();
                itemClick();
                break;
            }

            case DOWN_ARROW_KEY_CODE:
            case LEFT_ARROW_KEY_CODE:{
                e.preventDefault();
                focusListItem(DOWN_ARROW_KEY_CODE, index, menuitems, menuItemRefs);
                break;
            }

            case UP_ARROW_KEY_CODE:
            case RIGHT_ARROW_KEY_CODE:{
                e.preventDefault();
                focusListItem(UP_ARROW_KEY_CODE, index, menuitems, menuItemRefs);
                break;
            }

            case ESCAPE_KEY_CODE:
            case TAB_KEY_CODE: {
                e.preventDefault();
                menuRef.current.focus();
                break;
            }

            default:
                break;
        } **/
    };

const KebabMenu = (props) => {

    const {
        menuitems,
        menubtnlabel,
        onclickbutton,
        showmenu,
        menubtnname,
        menuid
    } = props;

    const menuRef = useRef(null);
    const menuItemRefs = useRef([]);

    useEffect(()=>{
        if(showmenu && menuitems.length){
            const {isDisabled}=menuitems[0]
            if(!isDisabled){
                menuItemRefs.current[0].focus();
            }
        }
    },[showmenu, menuitems]);

    const menuLabel = `${AX_OPTION_LABEL} ${menubtnlabel} `;

    return (
        <MenuContainer>
            <Menu
                kebabImg={KebabMenuIcon}
                kebabHoverImg={KebabMenuHoverIcon}
                onClick={onclickbutton}
                aria-expanded={showmenu}
                aria-haspopup="true"
                ref={menuRef}
                aria-label={menuLabel}
                name={menubtnname}
                id={menuid}
                className={"kebabMenuClass"}
            />
            {showmenu && (
                <MenuOptionsPopup role="menu">
                    {menuitems.map((item, index)=>{
                        const { name="", isDisabled=false, menuOptName="", id, icon="", itemClick= () => {} } = item;
                        const menuItemId = `vcm-menu-item-${id}`
                        return (
                            <Fragment key={index.toString()}>
                                { !!index && <Separator mL={0.625} mR={0.625} role="separator" aria-hidden="true" as="li" /> }
                                <MenuItem
                                    ref={setRef(menuItemRefs, index)}
                                    role="menuitem"
                                    onClick={itemClick}
                                    disabled={isDisabled}
                                    aria-labelledby={menuItemId}
                                    aria-disabled={isDisabled}
                                    tabIndex="-1"
                                    onKeyDown={onKeyEvent({index, menuitems, menuItemRefs, menuRef, itemClick,isDisabled})}
                                    onMouseEnter={toggleHover(menuItemRefs, index, 'enter',isDisabled)}
                                    onMouseLeave={toggleHover(menuItemRefs, index, 'leave'.isDisabled)}
                                >
                                    {icon && <MenuIcon url={icon}/>}
                                    <MenuOptions id={menuItemId} {...(!!menuOptName && {"aria-label": menuOptName})} disabled={isDisabled}>
                                        {name}
                                    </MenuOptions>
                                </MenuItem>
                            </Fragment>
                        )}
                    )}
                </MenuOptionsPopup>
            )}
        </MenuContainer>
    )
}

KebabMenu.propTypes = {
    menuitems: PropTypes.instanceOf(Object),
    onclickbutton: PropTypes.func,
    menubtnlabel: PropTypes.string,
    menubtnname: PropTypes.string,
    showmenu: PropTypes.bool
}

KebabMenu.defaultProps = {
    menuitems: [],
    onclickbutton: () => {},
    menubtnlabel: '',
    menubtnname: '',
    showmenu: false
}

export default KebabMenu;
