import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import styled from 'styled-components';
import styles from './styles';
import { WIcon, WRating } from '../../../common';
import './Performance.css';

const ytd = 'YTD';
const oneYear = '1 Year';
const fiveYear = '5 Year';
const tenYear = '10 Year';
const inception = 'Since Inception';
const monthEnd = 'Month-End Average Annual Total Returns';
const quarterEnd = 'Quarter -End Average Annual Total Returns';
const asOf = 'as of';
const dollar = '$';




const StyledTr = styled.tr`
   
`;

export default class PerformanceComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monthlyList: {},
            quarterlyList: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        const stateVar = state;

        if (
            props.selectedFundDetails !== undefined &&
            props.selectedFundDetails !== null &&
            Object.keys(props.selectedFundDetails).length > 0
        ) {
            stateVar.monthlyList = props.selectedFundDetails.performance.monthly;
            stateVar.quarterlyList = props.selectedFundDetails.performance.quarterly;

            stateVar.facts = [
                {
                    label: `Net Asset Value (Price per share as of ${
                        props.selectedFundDetails.nav_as_of ? props.selectedFundDetails.nav_as_of : ''
                    } )`,
                    value: props.selectedFundDetails.latest_nav
                        ? `${dollar}${Number(props.selectedFundDetails.latest_nav).toFixed(2)}`
                        : 0,
                },
            ];
            return state.facts;
        }

        return {};
    }

    renderTableBody = fund => {
        const assignObj = obj => {
            return obj;
        };
        return (
            <tbody>
                <StyledTr>
                    <td className="dataLabel" style={styles.colLeft}>
                        {ytd}
                    </td>
                    {fund.ytd_nav ? (
                        <td className="dataValue" style={styles.colRight}>
                            {fund.ytd_nav.indexOf('-') === -1
                                ? `${Number(fund.ytd_nav).toFixed(2)}%`
                                : `(${Number(fund.ytd_nav.replace('-', '')).toFixed(2)}%)`}
                        </td>
                    ) : (
                        <td className="dataValue" style={styles.colRight} />
                    )}
                </StyledTr>
                <StyledTr>
                    <td className="dataLabel" style={styles.colLeft}>
                        {oneYear}
                    </td>
                    {fund.oneyear_nav ? (
                        <td className="dataValue" style={styles.colRight}>
                            {fund.oneyear_nav.indexOf('-') === -1 ? (
                                `${Number(fund.oneyear_nav).toFixed(2)}%`
                            ) : (
                                <span style={styles.redIconSpan}>
                                    <WIcon
                                        icon={faAngleDown}
                                        fontawesomestyle={styles.redIconStyle}
                                        alt="down arrow red icon"
                                        isze="2x"
                                    />
                                    {assignObj(`(${Number(fund.oneyear_nav.replace('-', '')).toFixed(2)}%)`)}
                                </span>
                            )}
                        </td>
                    ) : (
                        <td className="dataValue" style={styles.colRight} />
                    )}
                </StyledTr>
                <StyledTr>
                    <td className="dataLabel" style={styles.colLeft}>
                        {fiveYear}
                    </td>
                    {fund.fiveyear_nav ? (
                        <td className="dataValue" style={styles.colRight}>
                            {fund.fiveyear_nav.indexOf('-') === -1 ? (
                                `${Number(fund.fiveyear_nav).toFixed(2)}%`
                            ) : (
                                <span style={styles.redIconSpan}>
                                    <WIcon
                                        icon={faAngleDown}
                                        fontawesomestyle={styles.redIconStyle}
                                        alt="down arrow red icon"
                                        isze="2x"
                                    />
                                    {assignObj(`(${Number(fund.fiveyear_nav.replace('-', '')).toFixed(2)}%)`)}
                                </span>
                            )}
                        </td>
                    ) : (
                        <td className="dataValue" style={styles.colRight} />
                    )}
                </StyledTr>
                <StyledTr>
                    <td className="dataLabel" style={styles.colLeft}>
                        {tenYear}
                    </td>
                    {fund.tenyear_nav ? (
                        <td className="dataValue" style={styles.colRight}>
                            {fund.tenyear_nav.indexOf('-') === -1 ? (
                                `${Number(fund.tenyear_nav).toFixed(2)}%`
                            ) : (
                                <span style={styles.redIconSpan}>
                                    <WIcon
                                        icon={faAngleDown}
                                        fontawesomestyle={styles.redIconStyle}
                                        alt="down arrow red icon"
                                        isze="2x"
                                    />
                                    {assignObj(`(${Number(fund.tenyear_nav.replace('-', '')).toFixed(2)}%)`)}
                                </span>
                            )}
                        </td>
                    ) : (
                        <td className="dataValue" style={styles.colRight} />
                    )}
                </StyledTr>
                <StyledTr>
                    <td className="dataLabel" style={styles.colLeft}>
                        {inception}
                        <span style={styles.tableHeaderSpan}>{fund.inceptionDate}</span>
                    </td>
                    {fund.since_inception_nav ? (
                        <td className="inceptionDataValue" style={styles.colRight}>
                            {fund.since_inception_nav.indexOf('-') === -1 ? (
                                `${Number(fund.since_inception_nav).toFixed(2)}%`
                            ) : (
                                <span style={styles.redIconSpan}>
                                    <WIcon
                                        icon={faAngleDown}
                                        fontawesomestyle={styles.redIconStyle}
                                        alt="down arrow red icon"
                                        isze="2x"
                                    />
                                    {assignObj(`(${Number(fund.since_inception_nav.replace('-', '')).toFixed(2)}%)`)}
                                </span>
                            )}
                        </td>
                    ) : (
                        <td className="inceptionDataValue" style={styles.colRight} />
                    )}
                </StyledTr>
            </tbody>
        );
    };

    render() {
        const { monthlyList, quarterlyList, facts } = this.state;
        const assignObj = obj => {
            return obj;
        };
        const monthlyPerformance = 'Monthly Performance';
        const quarterlyPerformance = 'Quarterly Performance';
        return (
            <>
                <div style={styles.containerStyle}>
                    {facts.map(fact => {
                        const factsLabel = fact.label.split('(');
                        return (
                            <Row key={fact.value} style={styles.quickStyle} className="quickFactsRow" noGutters>
                                <Col md={5} className="quickFactsLabel">
                                    {factsLabel[0]}
                                    <span>{factsLabel.length > 1 ? `${factsLabel[1].replace(')', '')}` : ''}</span>
                                </Col>
                                <Col md={3} className="quickFactsValue">
                                    {fact.value === 'rating' ? (
                                        <WRating name="rating" readOnly value={Number(fact.ratingValue)} />
                                    ) : (
                                        fact.value
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                    <Row>
                    
                    <Col md={6}>
                            <div style={styles.tableHeadingStyle}>
                                {monthEnd}{' '}
                                <span style={styles.tableHeaderSpan}>
                                    {monthlyList && assignObj(`${`${asOf} ${monthlyList.as_of}`}`)}
                                </span>
                            </div>
                            <Table style={styles.tableStyle} className="performanceTable" role="presentation">
                                <caption style={styles.tableCaption} aria-hidden="true">{monthlyPerformance}</caption>
                                {monthlyList && this.renderTableBody(monthlyList)}
                            </Table>
                    </Col>
                      
                    <Col md={6}>
                            <div style={styles.tableHeadingStyle}>
                                {quarterEnd} {' '}
                                <span style={styles.tableHeaderSpan}>
                                    {quarterlyList && assignObj(`${`${asOf} ${quarterlyList.as_of}`}`)}
                                </span>
                            </div>
                            <Table style={styles.tableStyle} className="performanceTable" role="presentation">
                                <caption style={styles.tableCaption} aria-hidden="true">{quarterlyPerformance}</caption>
                                {quarterlyList && this.renderTableBody(quarterlyList)}
                            </Table>
                      
                        </Col>
                        </Row>
                </div>
            </>
        );
    }
}
PerformanceComponent.propTypes = {
    selectedFundDetails: PropTypes.instanceOf(Object),
};

PerformanceComponent.defaultProps = {
    selectedFundDetails: {},
};
