import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '../../../../../common/Radio/Radio';
import '../../../../../common/commonCompStyles.css';


const assignObj = obj => {
    return obj;
};

class CitizenshipRadioList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            selected: '',
        };
        this.radioFor = '';
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { initialError } = nextProps;
        if (!prevState.selected && initialError && !nextProps.selectedValue) {
            return { showError: true };
        }

        return {};
    }

    handleSelect = e => {
        const { setValue } = this.props;
        const value = e.target.id && e.target.id.replace(this.radioFor, '');
        this.setState({ [e.target.className]: value, showError: false, selected: value }, () => {
            setValue(this.state);
        });
    };

    handleBlur = () => {
        const { selected } = this.state;
        const { selectedValue } = this.props;
        if (!selected && !selectedValue) {
            this.setState({ showError: true });
        }
    };

    generateKey = () => {
        const crypto = window.crypto || window.msCrypto;
        const array = new Uint32Array(1);
        return crypto.getRandomValues(array);
        // return Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    };

    render() {
        const { items, field, selectedValue, radiostyles, errorText, handleBlur, disabled } = this.props;
        const radioBlur = handleBlur || this.handleBlur;
        const { showError } = this.state;
        const radioFor = `wradio${this.generateKey()}`;
        const disabledRaio = disabled;
        this.radioFor = radioFor;
        const radioItems = (itemsParam, fieldParam) =>
            itemsParam.map((item, index) => {
                const radioId = `${item.key}${radioFor}`;
                return (
                    <Radio
                        field={field}
                        key={Number(index)}
                        radioFor={radioFor}
                        radiostyles={assignObj({
                            color: 'rgba(51, 51, 51, 1)',
                            fontSize: 16,
                            minHeight: 32,
                            ...radiostyles,
                        })}
                        value={item.value}
                        className={fieldParam}
                        selected={selectedValue === item.key}
                        id={radioId}
                        // onClick={radioClick}
                        onClick={this.handleSelect}
                        onBlur={radioBlur}
                        disabled={disabledRaio}
                    />
                );
            });
        return (
            <React.Fragment key={radioFor}>
                {radioItems(items, field)}
                {showError && (
                    <span
                        className="radioError error"
                        role="region"
                        id={assignObj(`${radioFor}inputErrorText`)}
                        aria-labelledby={assignObj(`${radioFor}inputErrorText`)}
                        aria-live="polite"
                        aria-atomic="true"
                    >
                        {errorText}
                    </span>
                )}
            </React.Fragment>
        );
    }
}

CitizenshipRadioList.propTypes = {
    errorText: PropTypes.string,
    initialError: PropTypes.bool,
    items: PropTypes.instanceOf(Array),
    field: PropTypes.string,
    setValue: PropTypes.func,
    selectedValue: PropTypes.string,
    radiostyles: PropTypes.instanceOf(Object),
    handleBlur: PropTypes.func,
};

CitizenshipRadioList.defaultProps = {
    errorText: 'Please select radio',
    initialError: false,
    items: [],
    field: '',
    setValue: () => {},
    selectedValue: '',
    radiostyles: {},
    handleBlur: undefined,
};

export default CitizenshipRadioList;
