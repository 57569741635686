import * as ActionTypes from 'shared/ReduxConstants/ServiceActionConstants';
import * as TransactionConstants from '../../TransactionConstants';

import {
    AccountInfoCard,
    Accordian,
    AccordianTitle,
    WSpinner,
    WIcon,
    WButton,
    FeeGatesWarningModal
} from 'common'; 
import arrowDown from 'assets/redArrowDown.png';
import {BottomNavSection} from '../../Common/BottomNavWrapper';
import { connect } from 'react-redux';
import greenArrowUp from 'assets/greenArrowUp.svg';
import highRisk from 'assets/highRisk.svg';
import { inputCheck, addCommasToNumberStr, decrypt } from 'utils';

import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import mediumRisk from 'assets/mediumRisk.svg';

import PropTypes from 'prop-types';
import {
    RadioGroup,
    FieldValue,
    InputText,
    ErrorMsg,
    OffScreenText,
} from 'modules/CustomerManagementModule/components';
import React, { Component } from 'react';
import riskLevel1 from 'assets/Level1.png';
import riskLevel2 from 'assets/Level2.png';
import riskLevel3 from 'assets/Level3.png';
import riskLevel4 from 'assets/Level4.png';
import riskLevel5 from 'assets/Level5.png';
import riskLevel6 from 'assets/Level6.png';
import riskLevel7 from 'assets/Level7.png';

import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import "./fundSelection.css";
import currencyFormatter from 'currency-formatter';
import {currencyFormatterFunction, removeComma} from '../../../../common/Formatter/CurrencyFormatter';
import { getSessionValues } from 'shared/Helpers/Utils';
import Proportionate from './Proportionate';
import { addNewElementIntoSellFunds, checkSingleEqualityAndReturnValue, identifyIsProportionate, identifyIsProportionateFundAvailable, isValidValue, removeProportionateFund } from 'commonHelper/CommonHelperFunctions';

const {
    DOLLAR_SYMBOL,
    FUND_WORTH_TEXT,
    FUND_NAV_TEXT,
    FUND_LAST_NAV_TEXT,
    FUND_WEEK_MIN_MAX_TEXT,
    AVAILABLE_SHARES_TEXT,
    FUND_ALL_SHARE_VALUE,
    FUND_PERCENTAMT_SHARE_VALUE,
    SELLINGAMOUNT,
    PERCENT_OF_AMOUNT,
    PERCENT_OF_SHARES,
    CURRENT_SHARES,
    ERRAMOUNTVALID,
    ERRAMOUNT_GREATER_THAN_WORTH,
    ERRAMOUNT90,
    ERRWORTHAMOUNTVALID,
    ERRWORTHAMOUNT_LESS_THAN_ONE_DOLLAR,
    ERRAMOUNT,
    FUND_SELECTION_TEXT,
    BEHVIOUR_SMOOTH_VALUE,
    USAA_FUND_COMPANY_NUMBER,
    TRADITIONAL_IRA,
    ROTH_IRA,
    SEP_IRA,
    EXCHANGE_TRANSACTION_TYPE,
    LIQ_NOT_ENOUGH_BALANCE,
    PERCENTAGE_SYMBOL,
    INCREASE_IN_PERCENTAGE_TEXT,
    DECREASE_IN_PERCENTAGE_TEXT,
    OPEN_PARENTHESES,
    CLOSE_PARENTHESES,
    SEPARATOR_SYMBOL_DASH,
    FUND_RISK,
    CURRENT_VAL_TEXT,
    SELECT_A_FUND_TEXT,
    FUND_LIST_TEXT,
    VIEW_FUND_LIST_TEXT,
    SELECT_FUND_FROM_LIST_BELOW,
    EXCHANGE_FROM,
    ERR_TEXT_ALL_SHARES_AMOUNT,
    EXCHANGE_INVESTMENT_SELECTION_TITLE_TEXT,
    EXCHANGE_DISCLAIMER,
    SELL_DISCLAIMER,
    ERRPERCENTVALID,
    ERRPERCENTAGE,
    ERRPERCENTVALID_SHARE,
    ERRPERCENTAGE_SHARE,
    FUND_ACCOUNT_NUMBER_TEXT,
    SELLERRAMOUNT90
} = TransactionConstants;

const assignObj = obj => {
    return obj;
};

const LoadMoreDiv = styled.div`
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    border: none;
`;

const NoPositionsMessage = styled.h1`
    color: red;
    font-size: 15px;
    margin-top:10px;
`;

const borderStyle = '1px solid #d2d2d2';

const CardContainer = styled.div`
    @media (max-width: 767px){
        &::before {
            border-bottom: 20px solid transparent !important;
            border-left: ${props => (props.selected ? '20px solid #8BC105' : '20px solid #D2D2D2')} !important;
        }
        margin-top: 30px;
    }
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
    margin-top: 1.875rem;
    border: ${props => (props.selected ? '2px solid #004A98' : borderStyle)};
    background: ${props =>
        props.selected ? '#fafafa 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'};
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    &:hover {
        outline: ${props => (props.selected ? 'none' : '1px solid #3c5a77')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0px;
        left: 0px;
    }
    > input[type='radio'] {
        position: absolute;
        width: 100%;
        height: 98%;
        left: 0;
        top: 0.5px;
        z-index: -1;
    }

    &:focus-within {
        // outline: 2px solid #000000;
        outline-offset: 0px;
    }
`;
const CardHeadExchange = styled.div`
    @media (max-width: 767px){
        padding: 22px 21px 11px 21px;
    }
    text-align: left;
    font: Bold 13px/15px Benton Sans;
    letter-spacing: 0;
    opacity: 1;
    padding: 1.25rem 2.4375rem;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
`;
const CardHead = styled.div`
    @media (max-width: 767px){
        padding: 22px 21px 11px 21px;
    }
    text-align: left;
    font: Bold 13px/15px Benton Sans;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    padding: 1.25rem 2.4375rem;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
`;

const CardBodyDetails = styled.div`
    @media (max-width: 767px){
        display: block;
        padding: 20px 21px;
    }
    display: flex;
    border-right: ${borderStyle};
    display: flex;
    padding: 1.875rem;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    align-items: center;
    padding-right: 1rem;
`;

const CardDetailItem = styled.div`
    @media (max-width: 767px){
        margin-left: 0px;
        margin-bottom: ${props => props.mB}px;
    }
    margin-left: ${props => props.mL || '0'}px;
`;

const CardDetailItemTitle = styled.div`
    @media (max-width: 767px){
        margin-bottom: 10px;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;

const CardDetailItemValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding: 1.188rem 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;
const FundFromDesc = styled.h3`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
AccountDetailsFlex.displayName = 'AccountDetailsFlex';

const DistributionErrMsg = styled.div`
    @media (max-width: 767px){
        font-size: 16px;
        line-height:22px;
    }
    display: block;
`;

const UpperCardBody = styled.div`
    @media (max-width: 767px){
        padding: 25px 21px;
    }
    border-top: ${borderStyle};
    opacity: 1;
    padding: 34px 23px 29px;
    color: #49494a;
    border-bottom: ${borderStyle};
`;
const UpperBodyField = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    margin-bottom: 10px;
`;
const UpperBodyValue = styled.div`
    @media (max-width: 767px){
        margin-bottom: 25px;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    display: inline-block;
`;
const UpperBodyRiskValue = styled.div`
    @media (max-width:767px){
        font-size: 12px;
        margin-bottom: 0px;
        margin-left: 0px;
        display: inline-block;
        width:auto;
        margin-left:10px;
    }
    display: block;
    text-align: right;
    font-size: 12px;
    width: 75px;
    margin-left: 2.2rem;
    line-height: 15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;
const UppderBodyValueDiv = styled.span``;

const StyledDiv = styled.div`
    @media (max-width: 767px){
        display:block;
        border-right: 0px;
        padding: 0px;
    }
    display: inline-block;
    padding: 0px 1rem;
    vertical-align: top;
    border-right: ${borderStyle};
`;
const TickMarkDiv = styled.div`
    margin-right: 10px;
    display: inline-block;
`;

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};

const HRDiv = styled.div`
    border: 1px solid #000000;
    margin-right: 10px;
    width: 5px;
    height: 1px;
    display: inline-block;
`;
const ViewFundDetailLink = styled.a`
    @media (max-width: 767px){
        margin-left: 21px;
    }
    font: 600 14px/16px benton-sans;
    letter-spacing: 0;
    border: none;
    cursor: pointer;
    color: #004aa5 !important;
    background: transparent;
    padding: 0;
    float: center;
    margin: 0 2.4375rem;
    top: -20px;
    position: relative;
`;

const CustomDiv = styled.div`  
    label  button{
        margin: 0;
        padding: 0;
    }
    label{
        padding: 0;
        margin: 0;
    }
    label div{
        color: rgb(86, 86, 90); 
        font-weight: bold;
    }
 
}`;

const StyledText = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #49494a;
    opacity: 1;
`;

const sellingOptions = [
    { key: 'allshare', value: 'All Shares' },
    { key: 'dollaramt', value: 'By Amount' },
    { key: 'percentamt', value: 'By % of Amount' },
    { key: 'percentamtshare', value: 'By % of Shares' },
];

const SELLING_OPTIONS = 'Selling Options';

const StyledCol = styled(Col)`
    @media (max-width: 767px){
        padding: 25px 21px;
    }
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 0;
`;

const StyledRow = styled(Row)`
    margin-bottom: ${props => props['data-mb'] || 0}rem;
    margin-top: ${props => props['data-mt'] || 0}rem;
    align-items: ${props => props['data-alignitems'] || 'stretch'};
`;

const StyledAmountRow = styled(StyledRow)`
    @media (max-width: 767px){
        padding-left: 15px;
        padding-bottom: 25px;
    }
`;

const StyledAmountCol = styled(Col)`
    @media (max-width: 767px){
        width: 137px;
    }
`;

const StyledAmountInputCol = styled(Col)`
    @media (max-width: 767px){
        margin-left: 20px;
    }
`;

const StyledAmountPercentInsRow = styled(StyledRow)`
    @media (max-width: 767px){
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const StyledAmountPercentRow = styled(StyledRow)`
    @media (max-width: 767px){
        padding: 0px 15px 25px;
    }
`;

const StyledAmountPercentLabelCol = styled(Col)`
    @media (max-width: 767px){
        width: 131px;
        margin-right: 26px;
    }
`;

const StyledAmountSoldDescSpanDesktop = styled.span`
    @media (max-width: 767px){
        display: none;
    }
`;

const StyledAmountSoldDescSpanMobile = styled.span`
    @media (min-width: 768px){
        display: none;
    }
    padding-left: 15px;
`;

const InputWrapper = styled.div`
    width: ${props => props['data-width']}px;
    display: inline-block;
    margin-right: 15px;
`;

const StyledSpan = styled.span`
    margin-left: 15px;
`;

const StyledRadioGroup = styled(RadioGroup)`
    margin-left: 1rem;
`;

const StyledAccountInfoCard = styled.div`
    @media (max-width: 767px){
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;

const SHARES_SOLD_TEXT = 'Shares to be sold: ';
const AMOUNT_LABEL = 'Enter Amount:';
const AMOUNT_SYMBOL = '$';

export const getAvailableBalance = ({ transactionType, fundDetails }) => {
    const { currentAccountBalance, unissuedBalance } = fundDetails;
    return Number(transactionType === 'liquidate' ? currentAccountBalance : unissuedBalance).toFixed(2);
};

export const getAvailableShares = ({ transactionType, fundDetails }) => {
    const { availableShares, unissuedShares } = fundDetails;
    return parseFloat(transactionType === 'liquidate' ? availableShares : unissuedShares).toFixed(3);
};



export const getDisplayData = ({ worthvalue, sellingamt, amtType, userinputpercent, totalShares, selectedFund }) => {
    const fields = [];
    fields.push({
        key: FUND_ACCOUNT_NUMBER_TEXT,
        value: selectedFund.fundAccountNumber!= null && selectedFund.fundAccountNumber!='' ?selectedFund.fundAccountNumber : <FundFromDesc>{TransactionConstants.NEW_FUND}</FundFromDesc>,
    });
    fields.push({
        key: CURRENT_VAL_TEXT,
        value: `${currencyFormatter.format(worthvalue, {
            locale: 'en-US',
        })}`,
    });
    if (amtType === 'percentamt' || amtType === 'percentamtshare') {
        if (amtType === 'percentamtshare') {
            fields.push({
                key: CURRENT_SHARES,
                value: `${addCommasToNumberStr(totalShares)}`,
            });
        }
        fields.push(
            {
                key: amtType === 'percentamt' ? PERCENT_OF_AMOUNT : PERCENT_OF_SHARES,
                value: `${Number(userinputpercent).toFixed(2)}%`,
            },
            {
                key: SELLINGAMOUNT,
                value: `$${addCommasToNumberStr(Number(sellingamt).toFixed(2))}`,
                optionalLabel: '[approximate]',
            },
        );
    } else {
        fields.push({
            key: SELLINGAMOUNT,
            value: `$${addCommasToNumberStr(Number(sellingamt).toFixed(2))}`,
        });
    }
    return fields.map(item => {
        return { ...item, field: item.key };
    });
};


const ByAmount = props => {
    const { onInputChange, onInputBlur, value, errorMsg, notificationMsgId, inputId } = props;
    let describedBy = null;
    if (notificationMsgId) describedBy = `${notificationMsgId}`;
    if (!errorMsg && !!Number(value)) describedBy += ' vcm-by-amount-description';

    return (
        <StyledAmountRow data-alignitems="center" className="no-gutters">
            <StyledAmountCol id="vcm-by-amount" sm={3}>
                <span>{AMOUNT_LABEL}</span>
                <StyledSpan>{AMOUNT_SYMBOL}</StyledSpan>
            </StyledAmountCol>
            <StyledAmountInputCol>
                <InputWrapper data-width="130">
                    <InputText
                        aria-labelledby="vcm-by-amount"
                        onChange={onInputChange}
                        data-name={AMOUNT_LABEL}
                        value={value}
                        onBlur={onInputBlur}
                        error={!!errorMsg}
                        required
                        aria-describedby={describedBy}
                        id={inputId}
                    />
                </InputWrapper>
            </StyledAmountInputCol>
        </StyledAmountRow>
    );
};



ByAmount.propTypes = {
    onInputChange: PropTypes.func,
    onInputBlur: PropTypes.func,
    value: PropTypes.string,
    errorMsg: PropTypes.string,
    notificationMsgId: PropTypes.string,
    inputId: PropTypes.string,
};

ByAmount.defaultProps = {
    onInputChange: () => {},
    onInputBlur: () => {},
    value: '',
    errorMsg: '',
    notificationMsgId: '',
    inputId: '',
};

const AMOUNT_SOLD_TEXT = 'Amount to be sold: $';
const OUT_OF_TEXT = ' of ';
const PERCENT_LABEL = 'Enter Percentage of Amount:';

const PERCENT_SHARE_LABEL = 'Enter Percentage of Shares:';

const ByPercentage = props => {
    const {
        onInputChange,
        totalAmount,
        totalShare,
        onInputBlur,
        value,
        errorMsg,
        notificationMsgId,
        type,
        transactionType,
        inputId,
        id
    } = props;
    const calculatedAmount = ((Number(value) * Number(totalAmount)) / 100).toFixed(2);
    const calculatedShares = ((Number(value) * Number(totalShare)) / 100).toFixed(3);
    let descriptionText = `${AMOUNT_SOLD_TEXT}${addCommasToNumberStr(
        calculatedAmount,
    )}${OUT_OF_TEXT}$${addCommasToNumberStr(totalAmount)}`;
    let label = PERCENT_LABEL;
    if (type === 'percentamtshare') {
        descriptionText = `${SHARES_SOLD_TEXT}${currencyFormatterFunction(calculatedShares)}${OUT_OF_TEXT}${currencyFormatterFunction(totalShare)}`;
        label = PERCENT_SHARE_LABEL;
    }
    let describedBy = null;
    if (notificationMsgId) describedBy = `${notificationMsgId}`;
    if (!errorMsg && !!Number(value)) describedBy += ' vcm-by-percent-description';
    const axid = `${id}-rb3`;

    return (
        <>
            {type === 'percentamt' && (
                <StyledAmountPercentInsRow data-mb={1} className="no-gutters" >
                    <Col className="offset-sm-3">
                        <StyledText id={axid} aria-live="assertive" aria-atomic="true">
                            {transactionType === 'exchange' ? EXCHANGE_DISCLAIMER : SELL_DISCLAIMER}
                        </StyledText>
                    </Col>
                </StyledAmountPercentInsRow>
            )}
            <StyledAmountPercentRow data-alignitems="center" className="no-gutters">
                <StyledAmountPercentLabelCol sm={3}>
                    <span id="vcm-by-percent">{label}</span>
                </StyledAmountPercentLabelCol>
                <Col>
                    <InputWrapper data-width="96">
                        <InputText
                            aria-labelledby="vcm-by-percent"
                            onChange={onInputChange}
                            data-name={label}
                            value={value}
                            onBlur={onInputBlur}
                            error={!!errorMsg}
                            required
                            aria-describedby={describedBy}
                            id={inputId}
                        />
                    </InputWrapper>
                    <LiveAnnouncer>
                        <LiveMessage message={descriptionText} aria-live="polite" />
                        {!errorMsg && !!Number(value) && <StyledAmountSoldDescSpanDesktop id="vcm-by-percent-description">{descriptionText}</StyledAmountSoldDescSpanDesktop>}
                    </LiveAnnouncer>
                </Col>
            </StyledAmountPercentRow>
            {!errorMsg && !!Number(value) && <StyledAmountSoldDescSpanMobile id="vcm-by-percent-description">{descriptionText}</StyledAmountSoldDescSpanMobile>}
        </>
    );
};

ByPercentage.propTypes = {
    onInputChange: PropTypes.func,
    totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onInputBlur: PropTypes.func,
    value: PropTypes.string,
    errorMsg: PropTypes.string,
    notificationMsgId: PropTypes.string,
    type: PropTypes.string,
    totalShare: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transactionType: PropTypes.string,
    inputId: PropTypes.string,
};

ByPercentage.defaultProps = {
    onInputChange: () => {},
    totalAmount: '',
    onInputBlur: () => {},
    value: '',
    errorMsg: '',
    notificationMsgId: '',
    type: '',
    totalShare: '',
    transactionType: '',
    inputId: '',
};


const CardBottomSection = props => {
    const {
        item,
        onSelectionChange,
        onAmtChange,
        onAmountBlur,
        onPercentChange,
        onPercentBlur,
        index,
        transactionType,
    } = props;

    const selectedOption = sellingOptions.find(each => each.key === item.amtType);
    const { value: amtTypeValue } = selectedOption || {};

    const inputId = `vcm-selling-input-${index}`;
    const radioGroupId = `vcm-selling-options-${index}`;

    const message = item.notificationMsg || item.errorMsgAmt || item.errorMsgPercent;

    const notificationMsgId = message ? `vcm-notification-message-${index}` : '';
    let axText = 'Error:';
    if (item.notificationMsg) axText = 'Suggestion:';

    const availableBalance = getAvailableBalance({ transactionType, fundDetails: item });

    return (
        <Row>
            <Col sm={4}>
                <CardBodyDetails>
                    <CardDetailItem mB={25}>
                        <CardDetailItemTitle>{AVAILABLE_SHARES_TEXT}</CardDetailItemTitle>
                        <CardDetailItemValue>
                            {addCommasToNumberStr(getAvailableShares({ transactionType, fundDetails: item }))}
                        </CardDetailItemValue>
                    </CardDetailItem>
                    <CardDetailItem mL={20}>
                        <CardDetailItemTitle>{FUND_WORTH_TEXT}</CardDetailItemTitle>
                        <CardDetailItemValue>
                            {DOLLAR_SYMBOL}
                            {addCommasToNumberStr(Number(availableBalance).toFixed(2))}{' '}
                        </CardDetailItemValue>
                    </CardDetailItem>
                </CardBodyDetails>
            </Col>
            <StyledCol className="selling-options">
                <FieldValue
                    as="p"
                    id={radioGroupId}
                    label={SELLING_OPTIONS}
                    value={
                        <StyledRadioGroup
                            labelledBy={radioGroupId}
                            options={sellingOptions}
                            onChange={onSelectionChange}
                            data-name={SELLING_OPTIONS}
                            disabled={!item.selected}
                            initialValue={amtTypeValue}
                            flexWrap="wrap"
                            mR={0.7}
                            mL={0}
                            mT={1}
                            rwdRadioGroupDisplayStyle='block'
                        />
                    }
                    labelsm={2}
                    valuesm
                    mb={1.5625}
                    alignItems="stretch"
                    noGutters
                />
                {item.amtType === 'dollaramt' && (
                    <ByAmount
                        onInputChange={onAmtChange}
                        onInputBlur={onAmountBlur}
                        value={item.userinputamt || ''}
                        totalAmount={availableBalance || ''}
                        errorMsg={item.errorMsgAmt}
                        notificationMsgId={notificationMsgId}
                        inputId={inputId}
                    />
                )}
                {(item.amtType === 'percentamt' || item.amtType === 'percentamtshare') && (
                    <ByPercentage
                        onInputChange={onPercentChange}
                        onInputBlur={onPercentBlur}
                        totalAmount={availableBalance || ''}
                        value={item.userinputpercent || ''}
                        errorMsg={item.errorMsgPercent}
                        notificationMsgId={notificationMsgId}
                        type={item.amtType}
                        totalShare={getAvailableShares({ transactionType, fundDetails: item })}
                        transactionType={transactionType}
                        inputId={inputId}
                        id={radioGroupId}
                    />
                )}
                <Row className="no-gutters">
                    <Col className="offset-sm-3">
                        {!!message && (
                            <ErrorMsg id={notificationMsgId} aria-live="assertive" aria-atomic>
                                <OffScreenText hideTxt={axText} />
                                <span>{message}</span>
                            </ErrorMsg>
                        )}
                    </Col>
                </Row>
            </StyledCol>
        </Row>
    );
};

CardBottomSection.propTypes = {
    item: PropTypes.instanceOf(Object),
    onSelectionChange: PropTypes.func,
    onAmtChange: PropTypes.func,
    onAmountBlur: PropTypes.func,
    onPercentChange: PropTypes.func,
    onPercentBlur: PropTypes.func,
    index: PropTypes.number,
    transactionType: PropTypes.string,
};

CardBottomSection.defaultProps = {
    item: {},
    onSelectionChange: () => {},
    onAmtChange: () => {},
    onAmountBlur: () => {},
    onPercentChange: () => {},
    onPercentBlur: () => {},
    index: 0,
    transactionType: '',
};
class FundSelection extends Component {
    static checkNextStatus(data) {
        const { amtType, userinputamt, shareworth, transactionType = '', selectedFundCostBasis = '' } = data;
        if (
            selectedFundCostBasis.trim().toLowerCase() === 'identified lot' ||
            selectedFundCostBasis.trim().toLowerCase() === 'specified lot'
        ) {
            return true;
        }
        if (amtType === FUND_ALL_SHARE_VALUE && shareworth >= 1) {
            return false;
        }

        if (
            transactionType !== 'liquidate' &&
            userinputamt > 0 &&
            ((amtType === FUND_PERCENTAMT_SHARE_VALUE && userinputamt === shareworth) ||
                (amtType !== FUND_ALL_SHARE_VALUE && userinputamt < shareworth))
        ) {
            return false;
        }

        if (
            transactionType === 'liquidate' &&
            userinputamt >= 1 &&
            ((amtType === FUND_PERCENTAMT_SHARE_VALUE && userinputamt === shareworth) ||
                (amtType !== FUND_ALL_SHARE_VALUE && userinputamt < shareworth))
        ) {
            return false;
        }
        return true;
    }

    constructor(props) {
        super(props);
        const { pageIndex } = this.props;
        const fundHeading = FUND_SELECTION_TEXT;
        this.state = {
            fundsAvailable: [],
            selectedFund: null,
            retrivedState: false,
            displayDetails: {
                fundSelection: {
                    titleTxt: fundHeading,
                    editTxt: 'Edit',
                    heading: fundHeading,
                    pageIndex,
                    data: [],
                },
            },
            amtType: '',
            userinputamt: '',
            userinputpercent: '',
            disableNext: true,
            isLoading: false,
            isError: false,
            isTradingAllowed: true,
            setFundClickSelection: false,
            isPrematureDistribution: false,
            selectedFundCostBasis: '',
            showWarningDialog:false,
            touchedIndex:0,
            touchedFundName:'fundName',
            touchedFundPercentValue:'0%',
            touchedFundNo:'',
            touchedFundAccNum:'',
            isFee:false,
            touchedLiquidationNoticeFeeResponse:false,
            fundListwithPercentage:[],
            proportianteSelectedFundsList:[],
            isProportinateSelected:false,
            renderCounter: 0,
            renderCountSelectedFud:1
        };
        this.fundSelectionRef = React.createRef();
        this.dollarTextInput = React.createRef();
    }



    static getFundDetailsFormSavedState = (proportionateFaildFundList,fundListData) =>
    {
        if(proportionateFaildFundList?.length == 1)
        {
            const selectedFundList = fundListData.map((item)=>{

                if(item.fundAccountNumber == proportionateFaildFundList[0]?.fundAccountNumber)
                {
                    return {
                        ...item,
                        checked:true
                    }
                }
                else
                {
                    return {
                        ...item,
                        checked:false
                    }
                }
            })

            return selectedFundList;
        }
        else
        {
            return fundListData;
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            savedState,
            liquidationData,
            exchangeData,
            fundAccountData,
            onSwitchComponent,
            transactionType,
            location,
            getIsPrematureDistribution,
            accOpeningData,
            masterRegistrationAccountData
        } = nextProps;
        const { fundsAvailable, isProportinateSelected,selectedFund,displayDetails,renderCountSelectedFud } = prevState;
        let setFundClickSelection = false;
        const parentDataObj = checkSingleEqualityAndReturnValue(transactionType,EXCHANGE_TRANSACTION_TYPE,exchangeData,liquidationData);
        const accountType = parentDataObj?.page0?.displayDetails?.accountSelection?.data?.[2]?.value || '';
        if (parentDataObj.goToNextPage && parentDataObj.isTradingAllowed) {
            onSwitchComponent(true, prevState, 1)();
        }
        let selectedFundData = '';
        let renderCountValue = renderCountSelectedFud;
        const displayData = displayDetails;
        if(liquidationData?.proportionateFaildFundList?.length == 1)
        {
            selectedFundData = liquidationData?.proportionateFaildFundList[0];
            const displayDataObj = getDisplayData({
                worthvalue:liquidationData?.proportionateFaildFundList[0].currentAccountBalance,
                sellingamt:liquidationData?.proportionateFaildFundList[0].sellingamt,
                amtType:liquidationData?.proportionateFaildFundList[0].amtType,
                userinputpercent:liquidationData?.proportionateFaildFundList[0].userinputpercent,
                totalShares:getAvailableShares({ transactionType, fundDetails: liquidationData?.proportionateFaildFundList[0] }),
                selectedFund:liquidationData?.proportionateFaildFundList[0]
            });
            displayData.fundSelection.heading = liquidationData?.proportionateFaildFundList[0].fundName;
            displayData.fundSelection.data = displayDataObj;
        }
        else if(liquidationData?.page1?.selectedFund && renderCountSelectedFud == 1)
        {
            selectedFundData = liquidationData?.page1?.selectedFund;
            renderCountValue = 2;
        }
        else
        {
            selectedFundData = selectedFund;
        }

        let isPrematureDistribution = false;
        if (
            transactionType !== EXCHANGE_TRANSACTION_TYPE && accountType &&
            (accountType === TRADITIONAL_IRA || accountType === ROTH_IRA || accountType.trim() === SEP_IRA)
        ) {
            isPrematureDistribution = getIsPrematureDistribution();
        }
        if (!prevState.retrivedState && savedState) {
            const fundsAvailableTempdata =
                savedState.fundsAvailable && savedState.fundsAvailable.length
                    ? savedState.fundsAvailable
                    : fundAccountData.fundAccountList;
            // Pre select fund based on location fundNo
            if (location.state && location.state.fundNumber && fundsAvailableTempdata.length > 0) {
                setFundClickSelection = true;
            }
            return {
                ...savedState,
                retrivedState: true,
                fundsAvailable: (liquidationData?.isProportionateResubmitFlow) ? removeProportionateFund(fundsAvailableTempdata) : fundsAvailableTempdata,
                disableNext:(liquidationData?.proportionateFaildFundList?.length > 1 && liquidationData?.isProportionateResubmitFlow),
                isLoading: parentDataObj.isLoading || accOpeningData.isLoading || masterRegistrationAccountData?.isLoading,
                isError: parentDataObj.isError,
                isTradingAllowed: parentDataObj.isTradingAllowed,
                tradingMessage: parentDataObj.tradingMessage,
                setFundClickSelection,
                isPrematureDistribution,
                selectedFund :selectedFundData,
                displayDetails:displayData,
                renderCountSelectedFud:renderCountValue
            };
        }
        const fundsAvailableTemp = fundsAvailable.length ? fundsAvailable : fundAccountData.fundAccountList;
        // Pre select fund based on location fundNo
        if (location.state && location.state.fundNumber && fundsAvailableTemp.length > 0) {
            setFundClickSelection = true;
        }
        return {
            ...prevState,
            accountNumber: parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[1].value,
            accountName: parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[0].value,
            // TODO
            // fundsAvailable: addNewElementIntoSellFunds(fundsAvailableTemp),
            fundsAvailable: liquidationData?.isProportionateResubmitFlow ? FundSelection.getFundDetailsFormSavedState(liquidationData?.proportionateFaildFundList,fundsAvailableTemp) : fundsAvailableTemp,
            // fundsAvailable: fundsAvailableTemp,
            isProportinateSelected:liquidationData?.isProportionateResubmitFlow ? false : isProportinateSelected,
            isLoading: parentDataObj.isLoading || fundAccountData.isLoading || accOpeningData.isLoading || masterRegistrationAccountData?.isLoading,
            isError: parentDataObj.isError,
            isTradingAllowed: parentDataObj.isTradingAllowed,
            tradingMessage: parentDataObj.tradingMessage,
            setFundClickSelection,
            isPrematureDistribution,
            selectedFund : selectedFundData,
            displayDetails:displayData,
            renderCountSelectedFud:renderCountValue
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: BEHVIOUR_SMOOTH_VALUE,
        });
        const {
            getFunds,
            editSection,
            liquidationData,
            exchangeData,
            transactionType,
            savedState,
            setTradingWarning,
            getMemberProfileInformations,
            getTransHolderList,
        } = this.props;
        const parentDataObj = transactionType === 'exchange' ? exchangeData : liquidationData;
        const { current } = this.fundSelectionRef;
        const { offsetTop = 0 } = current || {};
        if (transactionType === 'liquidate') {
            document.title = 'Investment Selection : Sell | Victory Capital';
        } else if (transactionType === 'exchange') {
            document.title = EXCHANGE_INVESTMENT_SELECTION_TITLE_TEXT;
        }
        if (editSection === FUND_SELECTION_TEXT) {
            window.scrollTo({
                top: offsetTop,
                behavior: BEHVIOUR_SMOOTH_VALUE,
            });
        }
        // API call to get account nickname
        const masterId = parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[1].value;
        if (masterId) {
            const companyNumber = '591';
            const transactionHistoryPayload = {
                masterId: masterId,
                companyNumber,
            };

            // const msrInfo = sessionStorage.getItem('msr_dts');
            const params = getSessionValues(); 
           
            const { accesstoken, vcmid, fismemberid } = params;

            getTransHolderList({
                payload: transactionHistoryPayload,
                token: accesstoken,
                vcmid,
                fismemberid,
            });
        }
        if (!savedState) {
            const masterRegId =
                parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[1].value;
            const accountType =
                parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[2].value;

            const accountTypeCode =
                parentDataObj.page0 &&
                parentDataObj.page0.accountSelected &&
                parentDataObj.page0.accountSelected.accountTypeCode;
            const socialCode =
                parentDataObj.page0 &&
                parentDataObj.page0.accountSelected &&
                parentDataObj.page0.accountSelected.socialCode;
            if (transactionType === 'exchange') {
               
                getFunds(
                    {
                        companyNumber: USAA_FUND_COMPANY_NUMBER.companyNumber,
                        masterRegId,
                        accountType,
                        socialCode,
                        accountTypeCode,
                    },
                    true,
                    transactionType,
                );
            } else {
                getFunds(
                    {
                        companyNumber: USAA_FUND_COMPANY_NUMBER.companyNumber,
                        masterRegId,
                        accountType,
                        socialCode,
                        accountTypeCode,
                    },
                    true,
                );
            }
        }
        getMemberProfileInformations(decrypt);
        setTradingWarning();
        sessionStorage.setItem('stepname', 'Fund Selections');
        const skipEl = document.getElementById('skipToMain');
        if (skipEl) skipEl.focus();
        
        if( TransactionConstants.FUND_SOURCE_PAYMENT_METHOD_DETAILS.fundFrom.methods[0].details[0].selected !=undefined)
        {
            TransactionConstants.FUND_SOURCE_PAYMENT_METHOD_DETAILS.fundFrom.methods[0].details[0].selected = false;
        }

    }

    componentDidUpdate = prevProps => {
        const { setFundClickSelection, fundsAvailable, selectedFund } = this.state;
        const { location, transactionType, exchangeData, liquidationData, accOpeningData } = this.props;
        const parentDataObj = transactionType === 'exchange' ? exchangeData : liquidationData;
        const masterRegId = parentDataObj.page0 && parentDataObj.page0.displayDetails.accountSelection.data[1].value;

        // check and add proportionate object/card if not avaiable in the Fund list
        if(fundsAvailable && identifyIsProportionateFundAvailable(fundsAvailable) && this.state.renderCounter == 0 && liquidationData?.isProportionateResubmitFlow == false){
            const updatedFundsAvailableList = addNewElementIntoSellFunds(fundsAvailable)
            this.setState({fundsAvailable: updatedFundsAvailableList, renderCounter: 1})
        }


        if (
            setFundClickSelection &&
            fundsAvailable.length > 0 &&
            selectedFund === null &&
            location.state &&
            location.state.fundNumber &&
            Number(masterRegId) === Number(location.state.masterId) && 
            this.props!=prevProps
        ) {
            const sfIndex = fundsAvailable.findIndex(
                i =>
                    Math.round(i.fundNumber) === Math.round(location.state.fundNumber) &&
                    Math.round(i.fundAccountNumber) === Math.round(location.state.fundAccountNumber),
            );
            if (sfIndex >= 0) {
                this.onFundSelection(sfIndex,null,fundsAvailable[+sfIndex].fundNumber,true,undefined)
            }
        }

        if (accOpeningData !== prevProps.accOpeningData) {
            this.updateState(prevProps);
        }
    };

    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;
        saveChildrenState(this.state, pageIndex);
    }

    updateState = prevProps => {
        const { accOpeningData, onSwitchComponent } = this.props;
        //   Fund List  Values end
        // Fund Details API Mapping Start
        if (
            accOpeningData[ActionTypes.GET_FUNDDETAILS] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_FUNDDETAILS] !== prevProps.accOpeningData[ActionTypes.GET_FUNDDETAILS]
        ) {
            const tempData = accOpeningData[ActionTypes.GET_FUNDDETAILS];
            // Error handling
            if (tempData.errorCode && tempData.result) {
                const errorDescription = tempData.result;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: errorDescription,
                    },
                    isErrorBar: true,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else if (!tempData.errorCode) {
                this.setState(
                    {
                        selectedFundForSummary: tempData,
                    },
                    () => {
                        const { fundSummaryClicked, selectedFundForSummary } = this.state;

                        if (Object.values(selectedFundForSummary).length > 0 && fundSummaryClicked) {
                            onSwitchComponent(true, this.state, 21)();
                        }
                    },
                );
            }
        }
    };

    handleLoadMore = () => {
        const { setNextPageNumber, transactionType } = this.props;
        if (transactionType === 'exchange') {
            setNextPageNumber({ page: 'exchangeOut' });
        } else {
            setNextPageNumber();
        }
    };

    getNewFundList = ({ fundsAvailable, index, transactionType }) => {
        let selectedFundCostBasis = '';
        let selectedFund = null;
        let displayData = [];
        let amtType = '';
        let userinputamt = '';
        let userinputpercent = '';
        let shareWorth = '';
        let fundName = '';
        const newFundList = fundsAvailable.map((item, itemIndex) => {
            const eachFund = item;
            if ((index === itemIndex && !eachFund.selected) || index !== itemIndex) {
                eachFund.userinputamt = '';
                eachFund.amtType = '';
                eachFund.userinputpercent = '';
                eachFund.sellingamt = '';
                eachFund.errorMsgAmt = '';
                eachFund.notificationMsg = '';
                eachFund.errorMsgPercent = '';
                eachFund.errorMsgAllShare = '';
            } else {
                amtType = eachFund.amtType;
                userinputamt = eachFund.userinputamt;
                userinputpercent = eachFund.userinputpercent;
                shareWorth = parseFloat(getAvailableBalance({ transactionType, fundDetails: eachFund }));
            }

            if (index === itemIndex) {
                eachFund.selected = true;
            } else eachFund.selected = false;
            if (eachFund.selected) {
                selectedFund = eachFund;
                selectedFundCostBasis = selectedFund.costBasis;
                fundName = eachFund.fundName;
                displayData = getDisplayData({
                    worthvalue: shareWorth,
                    sellingamt: eachFund.sellingamt,
                    amtType,
                    userinputpercent,
                    totalShares: getAvailableShares({ transactionType, fundDetails: eachFund }),
                    selectedFund
                });
            }
            return eachFund;
        });

        return {
            newFundList,
            fundName,
            selectedFund,
            selectedFundCostBasis,
            displayData,
            amtType,
            userinputamt,
            userinputpercent,
            shareWorth,
        };
    };

    automateFundSelection = (index) => {
        const { fundsAvailable: prevStateFundList,isFee } = this.state;   
        const { setNextPageNumberPositions, transactionType } = this.props;
        if (!prevStateFundList[+index].selected) {
            this.setState(prevState => {
                const { fundsAvailable, displayDetails } = prevState;
                const {
                    newFundList,
                    fundName,
                    selectedFund,
                    selectedFundCostBasis,
                    displayData,
                    amtType,
                    userinputamt,
                    userinputpercent,
                    shareWorth,
                } = this.getNewFundList({ fundsAvailable, index, transactionType });
                displayDetails.fundSelection.heading = fundName;

                const disableNext = this.constructor.checkNextStatus({
                    amtType,
                    userinputamt: +userinputamt,
                    userinputpercent,
                    shareworth: +shareWorth,
                    transactionType,
                    selectedFundCostBasis,
                });

                displayDetails.fundSelection.data = displayData;

                // Set page size based on selected fund index when redirected from view positions
                const pageNumber = (index + 1) / TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE;
                if (parseInt(pageNumber, 10) > 0) {
                    const fundPosition = (index + 1) % TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE;
                    let pageSize = parseInt(pageNumber, 10);
                    if (fundPosition > 0) {
                        pageSize = parseInt(pageNumber, 10) + 1;
                    }
                    setNextPageNumberPositions(pageSize);
                }
                return {
                    fundsAvailable: newFundList,
                    selectedFund,
                    displayDetails,
                    disableNext,
                    touchedLiquidationNoticeFeeResponse:isFee,
                    showWarningDialog:false
                };
            });
        }
    };

    onFundSelection = (index, shareworth,fundNo,callAutomateFundSelection,e) => { 
        let node=null 
        if(e){
            e.stopPropagation() 
            node=e.target.nodeName
        }   
        // logic to check if gate fund or fee fund if yes show warning else call onYesclick
        let showWarning=false
        let selectedFundName=""
        let feePercentValue=""
        let isFeeFlag=null
        const { fundsAvailable,touchedFundNo, touchedLiquidationNoticeFeeResponse=false } = this.state;
        if(fundsAvailable[index]?.fundName == "Proportionate")
        {
            this.splitFundPercentage();
            this.setState({isProportinateSelected:true})
        }
        else
        {
            this.setState({isProportinateSelected:false})
        }
        const feeFundIndex = fundsAvailable.findIndex(i => i.liquidityNoticeFlag === true && i.fundNumber === fundNo);
        const gateFundIndex=fundsAvailable.findIndex(i => i.redemptionNoticeFlag === true && i.fundNumber === fundNo);
        if(feeFundIndex>-1 || gateFundIndex>-1){
            showWarning= true
            if(feeFundIndex>-1){
                showWarning= !touchedLiquidationNoticeFeeResponse  // touchedFundNo!==fundNo
                isFeeFlag=true
                selectedFundName=fundsAvailable[+feeFundIndex].fundName
                feePercentValue=fundsAvailable[+feeFundIndex].imposedFee
            }else if(gateFundIndex>-1){
                isFeeFlag=false
                selectedFundName=fundsAvailable[+gateFundIndex].fundName
            }
        }
        this.setState({
            showWarningDialog:showWarning,
            isFee:isFeeFlag,
            touchedFundPercentValue:feePercentValue,
            touchedFundName:selectedFundName,
            touchedIndex:index,
            touchedFundNo:fundNo,
            touchedShareworth:shareworth,
            touchedNodeName:node
        },()=>{
            if(!showWarning){
                if(gateFundIndex>-1){
                    this.onGateClick()
                }else if(callAutomateFundSelection){
                    let selectedIndex=feeFundIndex >-1 ? feeFundIndex : index
                    this.automateFundSelection(selectedIndex) 
                }else{
                    this.onYesFeeClick() 
                }               
            }
        })


    }

    splitFundPercentage =()=>{

        const totalAmount = this.getTotalBalance(this.state.fundsAvailable);

        const fundList = JSON.parse(JSON.stringify(this.state.fundsAvailable));
        const removedZeroCurrentValueFundListArray = [];
        for(let i=0; i< fundList?.length; i++){
            if(fundList[i]?.fundName != "Proportionate")
            {
                let fundObj = fundList[i];
                fundObj['percentage'] =(parseFloat((fundList[i].currentAccountBalance /totalAmount) * 100 ));
                removedZeroCurrentValueFundListArray.push(fundObj);
            }
        }

        this.setState({
            fundListwithPercentage:removedZeroCurrentValueFundListArray
        })
    }

    calculateFundRatio = (value,index) =>
    {
        const {fundListwithPercentage} = this.state;
        let proportianteSelectedFundsList = [];
        const sellAmount = parseFloat(removeComma(value));
        for(let i=0; i<fundListwithPercentage?.length;i++)
        {
            const percentageValue = parseFloat(fundListwithPercentage[i].percentage);
            const percentageAmount = (sellAmount * (percentageValue/100)).toFixed(2);
           
            let fundInfo = fundListwithPercentage[i];
            fundInfo['sellingamt'] = percentageAmount;
            proportianteSelectedFundsList.push(fundInfo)
        
        }

        this.setState({proportianteSelectedFundsList:proportianteSelectedFundsList,
            isProportinateSelected:true})
    }

    onGateClick=()=>{
        this.setState({showWarningDialog:false})
    }

    onYesFeeClick =e => {
        const { fundsAvailable: prevStateFundList, 
            touchedIndex:index,
            touchedShareworth:shareworth,touchedNodeName:nodeName,isFee} = this.state;
        const { transactionType, showModalBasedOnCostBasis } = this.props;
        let selectedFundCostBasis = '';
        if (!(nodeName === 'INPUT' && prevStateFundList[+index].selected)) {
            this.setState(prevState => {
                const { fundsAvailable, displayDetails } = prevState;

                const {
                    newFundList,
                    fundName,
                    selectedFund,
                    selectedFundCostBasis,
                    displayData,
                    amtType,
                    userinputamt,
                    userinputpercent,
                    shareWorth,
                } = this.getNewFundList({ fundsAvailable, index, transactionType });

                displayDetails.fundSelection.heading = fundName;

                const disableNext = this.constructor.checkNextStatus({
                    amtType,
                    userinputamt: +userinputamt,
                    userinputpercent,
                    shareworth: +shareWorth,
                    transactionType,
                    selectedFundCostBasis,
                });
                showModalBasedOnCostBasis(selectedFundCostBasis);
                displayDetails.fundSelection.data = displayData;
                return {
                    showWarningDialog:false,
                    fundsAvailable: newFundList,
                    selectedFund,
                    displayDetails,
                    disableNext,
                    selectedFundCostBasis,
                    touchedLiquidationNoticeFeeResponse:isFee
                };
            });
        }
    };

    onNoFeeClick=event=>{
        event.preventDefault()
        this.setState((prevState)=>{
            const {fundsAvailable,selectedFund,touchedFundNo}=prevState
            const sfIndex = fundsAvailable.findIndex(i => i.fundNumber === touchedFundNo);
            if(sfIndex>-1) fundsAvailable[+sfIndex].selected=false
            if(selectedFund && selectedFund.fundNumber===touchedFundNo)selectedFund.selected=false
            return {showWarningDialog:false,selectedFund,fundsAvailable,touchedLiquidationNoticeFeeResponse:false}
        })
    }

    onSelectionChange = (index,shareworth) => (e) => {
        const selectedOption = e.options.find(item => item.value === e.target.value);
        const { key: amtType } = selectedOption || {};
        const sellingamt = amtType === 'allshare' ? shareworth : '';
        const { transactionType } = this.props;
        const { selectedFundCostBasis } = this.state;       
        this.setState(prevState => {
            const { fundsAvailable, displayDetails } = prevState;
            let displayData = [];
            const selectedFund = fundsAvailable[+index];
            selectedFund.amtType = amtType;
            selectedFund.userinputamt = '';
            selectedFund.userinputpercent = '';
            selectedFund.errorMsgAmt = '';
            selectedFund.notificationMsg = '';
            selectedFund.errorMsgAllShare = '';
            selectedFund.errorMsgPercent = '';
            selectedFund.sellingamt = sellingamt;

            if (amtType === 'allshare' && sellingamt < 1) {
                selectedFund.errorMsgAllShare = LIQ_NOT_ENOUGH_BALANCE;
            } else {
                selectedFund.errorMsgAllShare = '';
            }
            const userinputamt = '';
            const userinputpercent = '';
            const disableNext = this.constructor.checkNextStatus({
                amtType,
                userinputamt: +userinputamt,
                userinputpercent,
                shareworth: +shareworth,
                transactionType,
                selectedFundCostBasis,
            });
            displayData = getDisplayData({
                worthvalue: shareworth,
                sellingamt: selectedFund.sellingamt,
                amtType,
                userinputpercent,
                totalShares: getAvailableShares({ transactionType, fundDetails: selectedFund }),
                selectedFund
            });
            displayDetails.fundSelection.heading = selectedFund.fundName;
            displayDetails.fundSelection.data = displayData;
            return { fundsAvailable, disableNext, displayDetails };
        });
    };

    onAmtChange = (index, shareworth="") => e => {
        const { transactionType } = this.props;
        const { selectedFundCostBasis } = this.state;
        let amt = '';
        if(e.target.value.length > 0)
        {
            if(e.target.value.includes(',') == true)
            {
                amt = e.target.value.replace(',','')
            }
            else
            {
                amt = e.target.value;
            }
        }
        else
        {
            amt = e.target.value;
        }

        const amount = inputCheck(amt);
        if (amount === null) return;

        const { target } = e;
        this.setState(prevState => {
            const { fundsAvailable } = prevState;
            fundsAvailable[+index].userinputamt = amount;
            const disableNext = this.constructor.checkNextStatus({
                amtType: 'dollaramt',
                userinputamt: +amount,
                shareworth: +shareworth,
                transactionType,
                selectedFundCostBasis,
            });
            return { fundsAvailable, disableNext };
        });
    };

    getErrorMessage = ({ amtType = 'dollaramt', ErrCase }) => {
        const errObj = {
            dollaramt: {
                empty: ERRAMOUNT,
                invalid: ERRAMOUNTVALID,
                greaterThanWorth: ERRAMOUNT_GREATER_THAN_WORTH,
                equalToAllShare: ERR_TEXT_ALL_SHARES_AMOUNT,
                moreThan90Percent: ERRAMOUNT90,
                lessThanOneDollar: ERRWORTHAMOUNTVALID,
            },
            percentamt: {
                empty: ERRPERCENTAGE,
                invalid: ERRPERCENTVALID,
                greaterThanWorth: '',
                equalToAllShare: ERR_TEXT_ALL_SHARES_AMOUNT,
                moreThan90Percent: ERRAMOUNT90,
                lessThanOneDollar: ERRWORTHAMOUNT_LESS_THAN_ONE_DOLLAR,
            },
            percentamtshare: {
                empty: ERRPERCENTAGE_SHARE,
                invalid: ERRPERCENTVALID_SHARE,
                greaterThanWorth: '',
                equalToAllShare: '',
                moreThan90Percent: '',
                lessThanOneDollar: ERRWORTHAMOUNT_LESS_THAN_ONE_DOLLAR,
            },
        };
        return errObj[`${amtType}`]?.[`${ErrCase}`];
    };

    getSellingAmountEligibility = ({ sellingamt, worthvalue, transactionType, amtType }) => {
        let errorMsg = '';
        let notificationMsg = '';

        if (sellingamt && sellingamt > +worthvalue) {
            errorMsg = this.getErrorMessage({ amtType, ErrCase: 'greaterThanWorth' });
        } else if (sellingamt && +sellingamt === +worthvalue) {
            if (amtType === 'percentamtshare') {
                notificationMsg = this.getErrorMessage({ amtType, ErrCase: 'equalToAllShare' });
            } else {
                errorMsg = this.getErrorMessage({ amtType, ErrCase: 'equalToAllShare' });
            }
        } else if (sellingamt && (sellingamt > +worthvalue || sellingamt <= 0)) {
            errorMsg = this.getErrorMessage({ amtType, ErrCase: 'invalid' });
        } else if (sellingamt && transactionType !== 'exchange' && (sellingamt > +worthvalue || sellingamt < 1)) {
            errorMsg = this.getErrorMessage({ amtType, ErrCase: 'lessThanOneDollar' });
        } else if (sellingamt && sellingamt > +worthvalue * 0.9 && sellingamt < +worthvalue) {
            notificationMsg = this.getErrorMessage({ amtType, ErrCase: 'moreThan90Percent' });
        }
        return {
            errorMsg,
            notificationMsg,
        };
    };

    // Method to set error message and fetches the tax calculation on blur
    onAmountBlur = index => e => {
        const { selectedFundCostBasis } = this.state;
        const valueObj = +e.target.value;
        const value = e.target.value ? valueObj.toFixed(2) : e.target.value;
        if (!value) {
            this.setState(prevState => {
                const { fundsAvailable } = prevState;
                const { amtType } = fundsAvailable[+index];
                const inlineErr = this.getErrorMessage({ amtType, ErrCase: 'empty' });
                fundsAvailable[+index].errorMsgAmt = inlineErr;
                return {
                    disableNext: true,
                    fundsAvailable,
                };
            });
        } else {
            this.setState(prevState => {
                const { fundsAvailable, displayDetails } = prevState;
                let displayData = [];
                const { transactionType } = this.props;
                const selectedFund = fundsAvailable[+index];
                const { userinputamt } = selectedFund;

                const worthvalue = +getAvailableBalance({ transactionType, fundDetails: selectedFund });
                let formatedAmount = currencyFormatterFunction(userinputamt)
                fundsAvailable[+index].userinputamt = formatedAmount.substring(1,formatedAmount.length);
                fundsAvailable[+index].sellingamt = Number(userinputamt).toFixed(2);
                const sellingamt = Number(userinputamt).toFixed(2);
                const amtType = 'dollaramt';
                const { userinputpercent } = this.state;
                let disableNext = this.constructor.checkNextStatus({
                    amtType,
                    userinputamt: +sellingamt,
                    userinputpercent,
                    shareworth: +worthvalue,
                    transactionType,
                    selectedFundCostBasis,
                });
                const { errorMsg, notificationMsg } = this.getSellingAmountEligibility({
                    sellingamt,
                    worthvalue,
                    transactionType,
                });
                if (errorMsg) disableNext = true;
                selectedFund.errorMsgAmt = errorMsg;
                selectedFund.notificationMsg = notificationMsg;

                displayData = getDisplayData({
                    worthvalue,
                    sellingamt,
                    amtType,
                    userinputpercent,
                    totalShares: getAvailableShares({ transactionType, fundDetails: selectedFund }),
                    selectedFund
                });
                displayDetails.fundSelection.heading = selectedFund.fundName;
                displayDetails.fundSelection.data = displayData;
                // return { fundsAvailable, disableNext, displayDetails, selectedFund: fundsAvailable[+index]  };
                return { fundsAvailable, disableNext, displayDetails };
            });
        }
    };

    cancelWarningMessage = () => {
        const { cancelTradingLimitMessage } = this.props;
        cancelTradingLimitMessage();
    };

    onPercentChange = index => e => {
        const enteredValue = inputCheck(e.target.value);
        if (enteredValue === null || enteredValue > 100) return;
        const { target } = e;
        const { value: targetValue } = target;
        this.setState(prevState => {
            const { fundsAvailable } = prevState;
            fundsAvailable[+index].userinputpercent = targetValue;
            return { fundsAvailable };
        });
    };

    onPercentBlur = index => e => {
        const { transactionType } = this.props;
        const targetValue = e.target.value;
        const valueObj = +targetValue;
        const value = targetValue ? valueObj.toFixed(2) : targetValue;
        const { selectedFundCostBasis } = this.state;
        if (!value) {
            this.setState(prevState => {
                const { fundsAvailable } = prevState;
                const { amtType } = fundsAvailable[+index];
                const inlineErr = this.getErrorMessage({ amtType, ErrCase: 'empty' });
                fundsAvailable[+index].errorMsgPercent = inlineErr;
                return {
                    disableNext: true,
                    fundsAvailable,
                };
            });
        } else {
            this.setState(prevState => {
                const { fundsAvailable, displayDetails } = prevState;
                let displayData = [];
                const selectedFund = fundsAvailable[+index];
                const { userinputpercent, amtType } = selectedFund;

                const worthvalue = +getAvailableBalance({ transactionType, fundDetails: selectedFund });
                selectedFund.userinputpercent = value;
                const sellingamt = Number(worthvalue * (value / 100)).toFixed(2);
                selectedFund.sellingamt = sellingamt;
                selectedFund.userinputamt = sellingamt;
                let disableNext = this.constructor.checkNextStatus({
                    amtType,
                    userinputamt: +sellingamt,
                    userinputpercent,
                    transactionType,
                    selectedFundCostBasis,
                    shareworth: +worthvalue,
                });
                if (userinputpercent && (+userinputpercent > 100 || +userinputpercent <= 0)) {
                    selectedFund.errorMsgPercent = this.getErrorMessage({ amtType, ErrCase: 'invalid' });
                } else {
                    selectedFund.errorMsgPercent = '';
                }

                const { errorMsg, notificationMsg } = this.getSellingAmountEligibility({
                    sellingamt,
                    worthvalue,
                    transactionType,
                    amtType,
                });
                if (errorMsg) disableNext = true;
                selectedFund.errorMsgPercent = errorMsg;
                selectedFund.notificationMsg = notificationMsg;

                displayData = getDisplayData({
                    worthvalue,
                    sellingamt,
                    amtType,
                    userinputpercent,
                    totalShares: getAvailableShares({ transactionType, fundDetails: selectedFund }),
                    selectedFund
                });

                displayDetails.fundSelection.heading = selectedFund.fundName;
                displayDetails.fundSelection.data = displayData;
                return { fundsAvailable, disableNext, displayDetails };
            });
        }
    };

    formatInputValues = val => {
        return val && parseFloat(val).toFixed(2);
    };

    formatChangeInNav = val => {
        const changeInNav = val && val.toString().replace('-', '');
        return changeInNav && parseFloat(changeInNav).toFixed(2);
    };

    formatAvailableShares = val => {
        return val && parseFloat(val).toFixed(3);
    };

    handleCancelLiquidation = () => {
        const { setCancelLiquidation } = this.props;
        const { selectedFund } = this.state;
        setCancelLiquidation(selectedFund);
    };

    handleCancelExchange = () => {
        const { setCancelExchange } = this.props;
        const { selectedFund } = this.state;
        setCancelExchange(selectedFund);
    };

    handleFundSummary = (fundNumber, event) => {
        event.preventDefault();
        const { getFundDetails } = this.props;
        if (fundNumber && fundNumber !== '') {
            const payload = {
                fundNumber,
            };
            getFundDetails(payload);
            this.setState({ fundSummaryClicked: true });
        }
    };

    getImgSrc = risk => {
        let src;
        switch (risk) {
            case 'Low':
                src = mediumRisk;
                break;
            case 'Medium':
                src = mediumRisk;
                break;
            case 'High':
                src = highRisk;
                break;
            case 'Preservation of Capital':
                src = riskLevel1;
                break;
            case 'Conservative':
                src = riskLevel2;
                break;
            case 'Moderately Conservative':
                src = riskLevel3;
                break;
            case 'Moderate':
                src = riskLevel4;
                break;
            case 'Moderately Aggressive':
                src = riskLevel5;
                break;
            case 'Aggressive':
                src = riskLevel6;
                break;
            case 'Very Aggressive':
                src = riskLevel7;
                break;
            default:
                src = '';
        }
        return src;
    };

    getTotalBalance = fundsAvailable => {
        let totalBalance = 0;
        if (Array.isArray(fundsAvailable) && fundsAvailable?.length > 0) {
            fundsAvailable.forEach(fund => {
                if(fund.fundName != 'Proportionate'){
                    totalBalance += parseFloat(fund.currentAccountBalance);
                }
                else
                {

                } 
            });
        }
        else
        {

        }
        return totalBalance.toFixed(2);
    };

    getTotalShare = fundsAvailable => {
        let totalShare = 0;
        if (Array.isArray(fundsAvailable) && fundsAvailable?.length > 0) {
            fundsAvailable.forEach(fund => {
                if(fund.fundName != 'Proportionate'){
                    totalShare += parseFloat(fund.availableShares);
                }
                else
                {

                }
            });
        }
        else
        {

        }
        return totalShare.toFixed(2);
    };

    getCardHeader = ({ transactionType, cardId, item }) => {
        return (
            <>
                {transactionType === 'exchange' ? (
                    <CustomDiv>
                        <CardHeadExchange id={cardId}>{item.fundName}</CardHeadExchange>

                        <ViewFundDetailLink
                            href="#"
                            aria-label={`View ${item.fundName} details`}
                            onClick={Object.assign(e => this.handleFundSummary(item.fundNumber.toString(), e))}
                            /* tabIndex={item.selected ? 0 : -1} */
                            role="link"
                        >
                            {VIEW_FUND_LIST_TEXT}
                        </ViewFundDetailLink>
                    </CustomDiv>
                ) : (
                    <CardHead id={cardId}>
                        {item.fundName}
                        <br />
                    </CardHead>
                )}
            </>
        );
    };

    getCardMiddleSection = ({ item }) => {
        return (
            <UpperCardBody>
                <StyledDiv>
                    <UpperBodyField>{FUND_LAST_NAV_TEXT}</UpperBodyField>
                    <UppderBodyValueDiv>
                        <UpperBodyValue>
                            {DOLLAR_SYMBOL}
                            {this.formatInputValues(item.nav)}
                        </UpperBodyValue>
                    </UppderBodyValueDiv>
                </StyledDiv>
                <StyledDiv>
                    <UpperBodyField>{FUND_NAV_TEXT}</UpperBodyField>
                    <UppderBodyValueDiv>
                        {!item.fundName.toLowerCase().includes(TransactionConstants.MONEY_MARKET_TEXT) ? (
                            <TickMarkDiv>
                                {+item.changeInNav > 0 ? (
                                    <>
                                        <span className="sr-only">{INCREASE_IN_PERCENTAGE_TEXT}</span>
                                        <WIcon alt="" src={greenArrowUp} size="2x" />
                                    </>
                                ) : (
                                    <>
                                        <span className="sr-only">{DECREASE_IN_PERCENTAGE_TEXT}</span>
                                        <WIcon alt="" src={arrowDown} size="2x" />
                                    </>
                                )}
                            </TickMarkDiv>
                        ) : (
                            <HRDiv />
                        )}
                        {!item.fundName.toLowerCase().includes(TransactionConstants.MONEY_MARKET_TEXT) ? (
                            <UpperBodyValue
                                style={
                                    +item.changeInNav > 0
                                        ? assignObj({ color: '#0D823C' })
                                        : assignObj({ color: '#E60A17' })
                                }
                            >
                                {DOLLAR_SYMBOL}
                                {this.formatChangeInNav(item.changeInNav)} {OPEN_PARENTHESES}
                                {item.changeInNavPercentage
                                    ? this.formatChangeInNav(item.changeInNavPercentage)
                                    : '0.00'}
                                {PERCENTAGE_SYMBOL}
                                {CLOSE_PARENTHESES}
                            </UpperBodyValue>
                        ) : (
                            <UpperBodyValue>
                                {DOLLAR_SYMBOL}
                                {this.formatChangeInNav(item.changeInNav)} {OPEN_PARENTHESES}
                                {item.changeInNavPercentage
                                    ? this.formatChangeInNav(item.changeInNavPercentage)
                                    : '0.00'}
                                {PERCENTAGE_SYMBOL}
                                {CLOSE_PARENTHESES}
                            </UpperBodyValue>
                        )}
                    </UppderBodyValueDiv>
                </StyledDiv>

                <StyledDiv>
                    <UpperBodyField>{FUND_WEEK_MIN_MAX_TEXT}</UpperBodyField>
                    <UppderBodyValueDiv>
                        <UpperBodyValue>
                            {item.min52W !== '-' ? DOLLAR_SYMBOL : ''}
                            {item.min52W !== '-' ? this.formatInputValues(item.min52W) : item.min52W}
                            {SEPARATOR_SYMBOL_DASH}
                            {item.max52W !== '-' ? DOLLAR_SYMBOL : ''}
                            {item.max52W !== '-' ? this.formatInputValues(item.max52W) : item.max52W}
                        </UpperBodyValue>
                    </UppderBodyValueDiv>
                </StyledDiv>

                <StyledDiv style={assignObj({borderRight: '0px'})}>
                    <UpperBodyField
                        style={assignObj({
                            display: 'inline-block',
                        })}
                    >
                        {FUND_RISK}
                        &nbsp;&nbsp;
                        {this.getImgSrc(item.risk) !== '' ? (
                            <WIcon
                                src={this.getImgSrc(item.risk)}
                                style={assignObj({
                                    display: 'inline-block',
                                })}
                                className="riskImg"
                            />
                        ) : (
                            'N/A'
                        )}{' '}
                    </UpperBodyField>
                    <UppderBodyValueDiv>
                        <UpperBodyRiskValue>
                            {item.risk}
                        </UpperBodyRiskValue>
                    </UppderBodyValueDiv>
                </StyledDiv>
            </UpperCardBody>
        );
    };


    onAmountBlurForPropotionate = index => e => {
        const valueObj = +e.target.value;
        const value = e.target.value ? valueObj.toFixed(2) : e.target.value;
        let fundsAvailableData = this.state.fundsAvailable;
        if (!value) {
            fundsAvailableData[index].errorMsgAmt = this.getErrorMessage({ amtType:"dollaramt", ErrCase: 'empty' });
        } else {
            const totalBalance = this.getTotalBalance(fundsAvailableData) || 0;
            const ninetyPercentThreshold = totalBalance * 0.9;
            const enteredAmount = parseFloat(value || 0);
            let disableNext=false;
            let formatedAmount = currencyFormatterFunction(value)
            fundsAvailableData[index].userinputamt = formatedAmount.substring(1,formatedAmount.length);
            fundsAvailableData[index].sellingamt = `${enteredAmount}`;
            if(enteredAmount <= 0)
            {
                fundsAvailableData[index].errorMsgAmt = this.getErrorMessage({ amtType:"dollaramt", ErrCase: 'invalid' });;
                disableNext=true;
            }
            else if(enteredAmount  && enteredAmount > totalBalance){
                fundsAvailableData[index].errorMsgAmt = this.getErrorMessage({ amtType:"dollaramt", ErrCase: 'greaterThanWorth' });
                disableNext=true;
            }
            else if(enteredAmount > ninetyPercentThreshold) {
                this.calculateFundRatio(value,index)
                // Set error message if entered amount exceeds 90% threshold
                fundsAvailableData[index].errorMsgAmt = SELLERRAMOUNT90;
                disableNext=false;
            }
            else{
                this.calculateFundRatio(value,index)
                fundsAvailableData[index].errorMsgAmt = '';
                disableNext=false;
            }
            this.setState({
                fundsAvailable:fundsAvailableData,
                disableNext:disableNext
            })
        }
    };

    
    getAccordianBody = ({
        isPrematureDistribution,
        fundsAvailable,
        accountType,
        isLoading,
        showAllFunds,
        currentPageNumber,
        transactionType,
        nullValue,
        totalBalance,
        totalShare
    }) => {
        return (
            <>
                {isPrematureDistribution && fundsAvailable && fundsAvailable.length >= 1 && (
                    <DistributionErrMsg
                        className="error-msg"
                        aria-describedby={TransactionConstants.L05B_PREMATURE_DISTRIBUTION_ERROR_MESSAGE}
                        tabIndex="0"
                    >
                        {TransactionConstants.L05B_PREMATURE_DISTRIBUTION_ERROR_MESSAGE}
                    </DistributionErrMsg>
                )}
                {accountType.trim() === TransactionConstants.ARCHER_MSA_ACCOUNT_TYPE && (
                    <DistributionErrMsg
                        className="error-msg"
                        aria-describedby={TransactionConstants.ARCHER_MSA_WARNING_MESSAGE}
                        tabIndex="0"
                    >
                        {TransactionConstants.ARCHER_MSA_WARNING_MESSAGE}
                    </DistributionErrMsg>
                )}
                {fundsAvailable.length < 1 && !isLoading && (
                    <NoPositionsMessage aria-describedby={TransactionConstants.NO_POSITIONS_TO_SELL_MSG}>
                        {TransactionConstants.NO_POSITIONS_TO_SELL_MSG}
                    </NoPositionsMessage>
                )}
          
                    <legend className="sr-only">{FUND_LIST_TEXT}</legend>
                    {fundsAvailable &&
                        fundsAvailable.map((item, index) => {
                            const cardId = `vcm-fund-list-${index}`;
                            const cardContainerId = `vcm-fund-list-container-${index}`;
                            return (
                                index <
                                    (showAllFunds
                                        ? fundsAvailable.length
                                        : currentPageNumber * TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE) && (
                                        <>
                                            {item.showProportionate ? 
                                            <>
                                            <Proportionate  
                                            key={index.toString()}
                                            selected={!!item.selected} 
                                            onFundSelection={assignObj(e=>this.onFundSelection(index, item.worthvalue,item.fundNumber,e))}
                                            fundsAvailable={fundsAvailable}
                                            totalBalance={totalBalance}
                                            totalShare={totalShare} 
                                            onAmtChange={this.onAmtChange(index,"")}
                                            onAmountBlur={this.onAmountBlurForPropotionate(index,"")}
                                            inputValue={item.userinputamt}
                                            errorMsgAmt={item.errorMsgAmt}
                                            transactionType={transactionType}
                                            amtTypeValue={item.selected ? 'By Amount' : ''}
                                            />
                                            
                                            </> :
                                    <CardContainer
                                        key={index.toString()}
                                        selected={!!item.selected}
                                        onClick={assignObj(e=>this.onFundSelection(index, item.worthvalue,item.fundNumber,e))}
                                        id={cardContainerId}
                                    >
                                        <input
                                            type="radio"
                                            value={item.fundName}
                                            defaultChecked={!!item.selected}
                                            aria-label={item.fundName}
                                            name={FUND_LIST_TEXT}
                                        />
                                        <div role="group" aria-label={item.fundName}>
                                            {this.getCardHeader({ transactionType, cardId, item })}
                                            {this.getCardMiddleSection({
                                                item,
                                            })}
                                            <CardBottomSection
                                                item={item}
                                                onSelectionChange={this.onSelectionChange(
                                                    index,
                                                    getAvailableBalance({ transactionType, fundDetails: item }),
                                                )}
                                                index={index}
                                                onAmtChange={this.onAmtChange(index, item.worthvalue)}
                                                onAmountBlur={this.onAmountBlur(index, item.worthvalue)}
                                                onPercentChange={this.onPercentChange(index)}
                                                onPercentBlur={this.onPercentBlur(index)}
                                                transactionType={transactionType}
                                            />
                                        </div>
                                    </CardContainer>
                                        }
                                        </>
                                    
                                )
                            );
                        })}
                    {/* {fundsAvailable.length > 1 && <Proportionate fundsAvailable={fundsAvailable}  /> } */}
             
                {fundsAvailable &&
                    Math.ceil(fundsAvailable.length / TransactionConstants.NUMBER_OF_FUNDS_PER_PAGE) >
                        currentPageNumber &&
                    !showAllFunds && (
                        <LoadMoreDiv>
                            <WButton
                                variant="loadMore"
                                buttontext={TransactionConstants.TRANSACTION_LOAD_MORE}
                                role={nullValue}
                                arialabelForBtn={nullValue}
                                id="transLoadMoreButton"
                                buttonstyle={cancelButtonStyles}
                                onClick={this.handleLoadMore}
                            />
                        </LoadMoreDiv>
                    )}
            </>
        );
    };

    render() {
        const {
            fundsAvailable,
            disableNext,
            accountName,
            accountNumber,
            isLoading,
            isPrematureDistribution,
            showWarningDialog,isFee,touchedFundName,touchedFundPercentValue
        } = this.state;
        const {
            navigationObj,
            liquidationData,
            exchangeData,
            transactionType,
            onSwitchComponent,
            fundAccountData,
            handleError,
        } = this.props;

        const parentDataObj = transactionType === 'exchange' ? exchangeData : liquidationData;

        const accountType = parentDataObj?.page0?.displayDetails?.accountSelection?.data?.[2]?.value || '';

        navigationObj.onPrimaryClick = onSwitchComponent(true, this.state, 1);

        const currentPageNumber =
            transactionType === 'exchange'
                ? exchangeData.currentPageNumber_exchangeOut
                : liquidationData.currentPageNumber;

        const showAllFunds =
            transactionType === 'exchange' ? exchangeData.showAllFunds_exchangeOut : liquidationData.showAllFunds;
        const nullValue = null;
        if (fundAccountData.isError) {
            handleError(true);
        }
        navigationObj.arialabelback = 'Back to Account Selection';
        const totalBalance = this.getTotalBalance(fundsAvailable)
        const totalShare = this.getTotalShare(fundsAvailable)
        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}

                <div className="container">
                    <StyledAccountInfoCard data-test="account-info-card">
                        <AccountInfoCard accountName={accountName} accountNumber={accountNumber} />
                    </StyledAccountInfoCard>
                    <Accordian
                        ref={this.fundSelectionRef}
                        key={accountNumber}
                        titleChild={
                            transactionType === 'liquidate' ? (
                                <AccordianTitle accType={SELECT_A_FUND_TEXT} />
                            ) : (
                                <AccordianTitle id="EXCHANGE_FROM_1" accType={EXCHANGE_FROM} />
                            )
                        }
                        titleSeperator
                        labelledBy="EXCHANGE_FROM_1"
                        aria-controls={accountType}
                        Id={accountType}
                        transactionSubTitle={transactionType === 'liquidate' ? SELECT_FUND_FROM_LIST_BELOW : ''}
                        transactionHrStyle={assignObj({marginBottom: '0px'})}
                    >
                        {this.getAccordianBody({
                            isPrematureDistribution,
                            fundsAvailable,
                            accountType,
                            isLoading,
                            showAllFunds,
                            currentPageNumber,
                            transactionType,
                            nullValue,
                            totalBalance,
                            totalShare
                        })}
                    </Accordian>
                    {showWarningDialog&&
                        <FeeGatesWarningModal
                            isFee={isFee}
                            isLiquidate={true}
                            show={showWarningDialog}
                            fundName={touchedFundName}
                            feePercentValue={touchedFundPercentValue}
                            onPrimaryClick={isFee?this.onYesFeeClick:this.onGateClick}
                            onSecondaryClick={this.onNoFeeClick}
                        />
                    } 
                </div>
              
                <BottomNavSection
                    {...navigationObj}
                    showBack
                    iscancel
                    disabled={disableNext}
                    arialabelback="Back to Account Selection"
                    transactionType={transactionType}
                    cancelClickHandler={transactionType === 'exchange' ? this.handleCancelExchange : this.handleCancelLiquidation}
                />
            </>
        );
    }
}


const mapDispatchToProps = {};

export const mapStateToProps = state => {
    return {
        fundListData: state.fundAccountData,
        liquidationData: state.liquidationData,
        fundAccountData: state.fundAccountData,
        exchangeData: state.exchangeData,
        accOpeningData: state.accOpeningReducerData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
    };
};

FundSelection.propTypes = {
    getFunds: PropTypes.func.isRequired,
    saveChildrenState: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    navigationObj: PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    liquidationData: PropTypes.instanceOf(Object),
    fundListData: PropTypes.instanceOf(Object),
    fundAccountData: PropTypes.instanceOf(Object),
    exchangeData: PropTypes.instanceOf(Object),
    transactionType: PropTypes.string,
    cancelTradingLimitMessage: PropTypes.func,
    onSwitchComponent: PropTypes.func,
    checkTradingLimit: PropTypes.func,
    setTradingWarning: PropTypes.func,
    handleError: PropTypes.func,
    setNextPageNumber: PropTypes.func,
    location: PropTypes.instanceOf(Object),
    getIsPrematureDistribution: PropTypes.func,
    getMemberProfileInformations: PropTypes.func,
    setCancelLiquidation: PropTypes.func,
    setCancelExchange: PropTypes.func,
    accOpeningData: PropTypes.instanceOf(Object),
    setNextPageNumberPositions: PropTypes.func,
};

FundSelection.defaultProps = {
    saveChildrenState: () => {},
    savedState: null,
    pageIndex: 0,
    editSection: null,
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        portalRoot: null,
    },
    fundListData: {},
    liquidationData: {},
    fundAccountData: {},
    exchangeData: {},
    transactionType: null,
    cancelTradingLimitMessage: () => {},
    onSwitchComponent: () => {},
    checkTradingLimit: () => {},
    setTradingWarning: () => {},
    handleError: () => {},
    setNextPageNumber: () => {},
    location: {},
    getIsPrematureDistribution: () => {},
    getMemberProfileInformations: () => {},
    setCancelLiquidation: () => {},
    setCancelExchange: () => {},
    accOpeningData: {},
    setNextPageNumberPositions: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(FundSelection);
