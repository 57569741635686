import { analyzeFormFieldError } from 'shared/Actions/AnalyticsActions';
import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as CalenderIcon } from 'assets/CalenderSvg.svg';
import { ReactComponent as DownArrow } from 'assets/DateDownArrow.svg';
import { ReactComponent as UpArrow } from 'assets/DateUpArrow.svg';
import styled from 'styled-components';
import { useClickAway } from '../../hooks';
import { useDispatch } from 'react-redux';

const Button = styled.button`
    background: #ffffff 0% 0% no-repeat padding-box;
    border-style: none;
    &:focus {
        outline: none;
    }
`;
const FlexDiv = styled.div`
    display: flex;
    background: ${props => (props.selected ? '#E6E6E6 0% 0% no-repeat padding-box' : 'transparent')};
    justify-content: space-around;
`;
const ArrowButton = styled(Button)`
    svg {
        width: 0.875rem;
        height: 0.4375rem;
    }
`;
const DateDiv = styled.div`
    display: inline-flex;
    position: relative;
    flex-direction: column;
    width: 100%;
`;
const ArrowSection = styled.div`
    display: flex;
    justify-content: space-around;
`;
const StyledInput = styled.input`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: transparent;
    text-shadow: 0 0 0 #49494a;
    width: 174px;
    height: 3.125rem;
    padding: 0.9375rem 1.25rem;
    background: ${props => (props.disabled ? '#f7f7f7' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: 1px solid ${props => (props.isOpen ? '#486D90' : '#d2d2d2')};
    opacity: 1;
    &:hover {
        border: 1px solid #486d90;
    }
    text-shadow: 0 0 0 #49494a;
`;
const InputCalendarIcon = styled(CalenderIcon)`
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1.25rem;
`;
const InputContainer = styled.div`
    cursor: pointer;
`;
const BottomSection = styled.div`
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
`;
const BottomSectionText = styled(Button)`
    padding: 0.625rem;
    width: 50%;
    text-align: center;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    border-right: ${props => (props.br ? '1px solid #d2d2d2' : 'none')};
`;
const Picker = styled.div`
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 3rem;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 10px solid #ffffff;
        top: -0.625rem;
        left: 13.5rem;
    }
`;
const DateSpan = styled.span`
    width: 4rem;
    height: 1.375rem;
    text-align: center;
    font-family: benton-sans, sans-serif;
    font-weight: ${props => (props.selected ? 600 : 500)};
    font-style: normal;
    font-size: ${props => (props.selected ? '18px' : '16px')};
    line-height: ${props => (props.selected ? '22px' : '20px')};
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const StyledFormatLabel = styled.small`
    display: block;
    margin-top: 0.3125rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
`;
const ErrMsg = styled.small`
    position: absolute;
    display: block;
    margin-top: 0.25rem;
    color: red;
    top: 4rem;
    white-space: nowrap;
`;
const DatePickerContainer = styled.div`
    position: relative;
`;

const ERROR_OFFSCREEN_TEXT = 'Error:';
const cancelTxt = 'Cancel';
const okTxt = 'Ok';
const dateFormat = 'MM/DD/YYYY';
const DEFAULT_MIN = '1900-01-01';
const DEFAULT_MAX = '2999-12-31';

export const VCMDatePickerWithoutLabel = props => {
    // Function to format a date value to MM/DD/YYYY
    const dispatch = useDispatch();

    const formatDateToTwoDigit = (date, locale) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };

        return locale ? date.toLocaleDateString(locale, options) : date.toLocaleDateString([], options);
    };

    // Function to check the value is a valid date string or not
    const checkIsValid = date => {
        const dateValue = new Date(date);
        return dateValue.getDate().toString() !== 'NaN';
    };

    const {
        onChange,
        id,
        min,
        max,
        value,
        name,
        dobValidation,
        errortext,
        onBlur,
        disabled,
        errorTxt,
        maxErrorTxt,
        minErrorTxt,
        emptyErrorTxt,
        labelledby,
        locale,
        inputclassname,
        DOBErrorMsg,
        groupField,
        required,
        autoSetMinDate,
    } = props;
    // Check min and max value are valid - If not valid reset it to default value

    // Setting the Error Text
    const maxErrTxt = errorTxt || maxErrorTxt;
    const minErrTxt = errorTxt || minErrorTxt;

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState('');
    const [displayDate, setDisplayDate] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [placeholderTxt, setPlaceholderText] = useState('Select');
    const [errorMsg, setErrorMsg] = useState(errortext);
    const [minimumDate, setMinimumDate] = useState(checkIsValid(min) ? new Date(min) : new Date(DEFAULT_MIN));
    const [maximumDate, setMaximumDate] = useState(checkIsValid(max) ? new Date(max) : new Date(DEFAULT_MAX));
    // alert(this.defaultProps.min);

    // References to component, and input element therein
    const datePickerRef = useRef(null);
    const inputRef = useRef(null);

    // Getting the current date, month and year
    const day = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    // List of months in a year
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // Number of days in a month
    const totalDays = new Date(year, month + 1, 0).getDate();
    // List of days in a month
    const daysArray = Array.from(Array(totalDays).keys()).map((item, index) => index + 1);
    // Creating a Array to display the picker.
    const displayArray = Array.from(Array(5).keys()).map((item, index) => ({ key: index + 1, value: index + 1 }));

    // Setting error text from props
    useEffect(() => {
        setErrorMsg(errortext || errorMsg);
    }, [errortext]);

    // Setting minimum date as Date type from the min props
    useEffect(() => {
        if (
            checkIsValid(min) &&
            formatDateToTwoDigit(minimumDate, locale) !== formatDateToTwoDigit(new Date(min), locale)
        ) {
            setMinimumDate(new Date(min));
        }
    }, [min, minimumDate]);

    // Setting maximum date as Date type from the max props
    useEffect(() => {
        if (
            checkIsValid(max) &&
            formatDateToTwoDigit(maximumDate, locale) !== formatDateToTwoDigit(new Date(max), locale)
        ) {
            setMaximumDate(new Date(max));
        }
    }, [max, maximumDate]);

    // Setting current date and selected date using value prop and minimum date.
    // If value is empty rest selected date, error msg and placeholder text.
    useEffect(() => {
        let curDate;
        if (value && checkIsValid(value)) {
            curDate = new Date(value);
            setSelectedDate(formatDateToTwoDigit(curDate, locale));
            curDate < minimumDate ? setErrorMsg(minErrTxt) : setErrorMsg('');
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            minimumDate.setHours(0, 0, 0, 0);
            curDate = today < minimumDate ? minimumDate : today;
        }
        if (value === '') {
            setSelectedDate('');
            setErrorMsg('');
            setPlaceholderText('Select');
        }
        setCurrentDate(curDate);
    }, [value, minimumDate]);

    // Storing the date value that is to be returned in onChange
    const prevDisplayValueRef = useRef('initialvalue');
    useEffect(() => {
        prevDisplayValueRef.current = displayDate;
    });
    const prevDisplayValue = prevDisplayValueRef.current;

    // Triggering onChange only if there is a change in valid date value and for first error occurence
    useEffect(() => {
        if (prevDisplayValue !== 'initialvalue' && displayDate !== prevDisplayValue) {
            onChange({ target: { ...inputRef.current, value: displayDate, selectedDate: selectedDate } });
        }
    }, [displayDate, onChange, prevDisplayValue]);

    // Close calendar if user clicks outside of component
    useClickAway(datePickerRef, () => {
        setShowPicker(false);
    });

    // Function to set the error message and date value to be returned.
    const checkError = tempDate => {
        const maxDate = new Date(maximumDate);
        const minDate = new Date(minimumDate);
        maxDate.setHours(0, 0, 0, 0);
        minDate.setHours(0, 0, 0, 0);
        tempDate.setHours(0, 0, 0, 0);
        let displayValue = 'Error';
        if (dobValidation) {
            if (tempDate >= maxDate) {
                // setErrorMsg(`Please enter a past date`);
                setErrorMsg(`Please enter valid date of birth`);
            } else {
                // debugger
                const date = new Date();
                date.setFullYear(date.getFullYear() - 18);
                if (Date.parse(tempDate) <= Date.parse(date)) {
                    setErrorMsg('');
                    displayValue = formatDateToTwoDigit(tempDate, locale);
                } else {
                    setErrorMsg(DOBErrorMsg);
                }
            }
        } else if (tempDate > maxDate) {
            // setErrorMsg(`Please enter a past date`);
            const err = maxErrTxt || `Please select a date max of ${formatDateToTwoDigit(maxDate, locale)}`;
            setErrorMsg(err);
        } else if (tempDate < minDate) {
            const err = minErrTxt || `End date should be greater than Start date.`;
            setErrorMsg(err);
            // setErrorMsg(`Please enter present or future date`);
        } else {
            setErrorMsg('');
            displayValue = formatDateToTwoDigit(tempDate, locale);
        }

        setDisplayDate(displayValue);
    };

    // Function to change the day value
    const onDayArrowClick = isPrev => () => {
        const tempDate = new Date(currentDate.getTime());
        if (isPrev) {
            tempDate.setDate(day + 1);
        } else {
            tempDate.setDate(day - 1);
        }
        setCurrentDate(tempDate);
    };

    // Function to change the month value
    const onMonthArrowClick = isPrev => () => {
        const tempDate = new Date(currentDate.getTime());
        if (isPrev) {
            tempDate.setDate(1);

            tempDate.setMonth(tempDate.getMonth() + 1);

            tempDate.setDate(Math.min(day, new Date(year, tempDate.getMonth() + 1, 0).getDate()));
        } else {
            tempDate.setMonth(month - 1);

            while (tempDate.getMonth() === month) {
                tempDate.setDate(tempDate.getDate() - 1);
            }
        }
        setCurrentDate(tempDate);
    };

    // Function to change the year value
    const onYearArrowClick = isPrev => () => {
        const tempDate = new Date(currentDate.getTime());
        if (isPrev) {
            tempDate.setFullYear(year + 1);
        } else {
            tempDate.setFullYear(year - 1);
        }
        setCurrentDate(tempDate);
    };

    // Function to set the selected date and error message
    // if selected date is not in the range. Also closes the picker
    const onOkClick = () => () => {
        checkError(currentDate);
        setSelectedDate(formatDateToTwoDigit(currentDate, locale));
        setShowPicker(false);
    };

    // Function to set previous date and closes picker on cancel click
    const onCancelClick = () => () => {
        const oldDate = (selectedDate && new Date(selectedDate)) || currentDate;
        setCurrentDate(oldDate);
        setShowPicker(false);
        if (!selectedDate) setPlaceholderText('Select');
    };

    // Function to display the picker and removes placeholder text
    const onPickerClick = () => () => {
        if (!disabled) {
            setPlaceholderText('');
            setShowPicker(show => !show);
            if (autoSetMinDate && checkIsValid(min)) {
                setCurrentDate(new Date(min), locale);
            }
        }
    };
    // Function to set error on blur if field is empty
    function onBlurEvent(e) {
        if (!selectedDate) {
            const err =
                emptyErrorTxt || (dobValidation ? `Please select your Date of Birth` : `Please select a valid date`);
            if (err.length > 0) {
                dispatch(analyzeFormFieldError());
            }
            setErrorMsg(err);
        }
        onBlur(e);
    }

    const errorFieldName = errorMsg ? 'errorInputField' : '';
    const dummyFn = () => {console.log('Dummy Function clicked')};
    const ariaLabel = `Show Date picker ${name}`;
    return (
        <DatePickerContainer ref={datePickerRef}>
            <DateDiv>
                <InputContainer onClick={onPickerClick()}>
                    <StyledInput
                        className={`analytics-form-fields ${inputclassname} ${errorFieldName}`}
                        id={id}
                        name={name}
                        ref={inputRef}
                        isOpen={showPicker}
                        // aria-expanded={showPicker}
                        aria-invalid={!!errorMsg}
                        placeholder={placeholderTxt}
                        onBlur={onBlurEvent}
                        value={selectedDate}
                        onChange={dummyFn}
                        disabled={disabled}
                        aria-describedby={`${id}-hint ${id}-error`}
                        autoComplete="off"
                        aria-labelledby={labelledby}
                        data-name={name}
                        data-form-field={!disabled}
                        data-group-field={groupField}
                        aria-required={required}
                    />
                    <InputCalendarIcon
                        role="button"
                        tabIndex="0"
                        aria-label={ariaLabel}
                        aria-expanded={showPicker}
                        focusable="false"
                    />
                </InputContainer>
                <StyledFormatLabel id={`${id}-hint`}>{dateFormat}</StyledFormatLabel>
                {errorMsg && (
                    <ErrMsg
                        className="errorMsgInputStyle analytics-error-field"
                        aria-live="assertive"
                        id={`${id}-error`}
                    >
                        <span className="sr-only">{ERROR_OFFSCREEN_TEXT}</span>
                        {errorMsg}
                    </ErrMsg>
                )}
            </DateDiv>
            {showPicker && (
                <Picker tabIndex="0">
                    <ArrowSection tabIndex="-1">
                        <ArrowButton type="button" onClick={onMonthArrowClick(false)}>
                            <UpArrow />
                        </ArrowButton>
                        <ArrowButton type="button" onClick={onDayArrowClick(false)}>
                            <UpArrow />
                        </ArrowButton>
                        <ArrowButton type="button" onClick={onYearArrowClick(false)}>
                            <UpArrow />
                        </ArrowButton>
                    </ArrowSection>
                    {displayArray.map((item, index) => {
                        return (
                            <FlexDiv key={item.key} selected={index === 2}>
                                <DateSpan selected={index === 2}>{monthArray[+(month + 10 + index) % 12]}</DateSpan>
                                <DateSpan selected={index === 2}>
                                    {daysArray[+(day + totalDays - 3 + index) % totalDays]}
                                </DateSpan>
                                <DateSpan selected={index === 2}>{year - 2 + index}</DateSpan>
                            </FlexDiv>
                        );
                    })}

                    <ArrowSection>
                        <ArrowButton type="button" onClick={onMonthArrowClick(true)}>
                            <DownArrow />
                        </ArrowButton>
                        <ArrowButton type="button" onClick={onDayArrowClick(true)}>
                            <DownArrow />
                        </ArrowButton>
                        <ArrowButton type="button" onClick={onYearArrowClick(true)}>
                            <DownArrow />
                        </ArrowButton>
                    </ArrowSection>

                    <BottomSection>
                        <BottomSectionText br onClick={onCancelClick()}>
                            {cancelTxt}
                        </BottomSectionText>
                        <BottomSectionText onClick={onOkClick()}>{okTxt}</BottomSectionText>
                    </BottomSection>
                </Picker>
            )}
        </DatePickerContainer>
    );
};

VCMDatePickerWithoutLabel.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dobValidation: PropTypes.bool,
    errortext: PropTypes.string,
    disabled: PropTypes.bool,
    errorTxt: PropTypes.string,
    maxErrorTxt: PropTypes.string,
    minErrorTxt: PropTypes.string,
    emptyErrorTxt: PropTypes.string,
    labelledby: PropTypes.string,
    locale: PropTypes.string,
    DOBErrorMsg: PropTypes.string,
    groupField: PropTypes.bool,
    required: PropTypes.bool,
};

VCMDatePickerWithoutLabel.defaultProps = {
    value: '',
    id: '',
    name: '',
    min: DEFAULT_MIN,
    max: DEFAULT_MAX,
    onChange: () => {},
    onBlur: () => {},
    errortext: '',
    dobValidation: false,
    disabled: false,
    errorTxt: '',
    maxErrorTxt: '',
    minErrorTxt: '',
    emptyErrorTxt: '',
    labelledby: '',
    DOBErrorMsg: 'You must be 18 years or older to open an account',
    groupField: false,
    required: false,
};

const StyledRow = styled(Row)`
    margin-bottom: 1.5625rem;
    align-items: ${props => props['data-alignitems']};
    flex-direction: ${props => props['data-flexdirection']};
`;

const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: rgb(73, 73, 74);
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;

const StyledOptionalSpan = styled.span`
    font: 500 12px/20px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 10px;
`;
const VCMDatePicker = props => {
    const { label, labelsm, valuesm, noGutters, classNameVal, optional, alignItems, flexDirection, ...rest } = props;
    const { id } = rest;
    const datePickerClass = `datePickerRow ${classNameVal}`;
    const optionalText = '[Optional]';
    return (
        <StyledRow
            noGutters={noGutters}
            className={datePickerClass}
            data-alignitems={alignItems}
            data-flexdirection={flexDirection}
        >
            <StyledLabel column sm={labelsm} htmlFor={id}>
                {label}
                {optional && <StyledOptionalSpan>{optionalText}</StyledOptionalSpan>}
            </StyledLabel>
            <Col sm={valuesm}>
                <VCMDatePickerWithoutLabel {...rest} />
            </Col>
        </StyledRow>
    );
};

VCMDatePicker.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    labelsm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)]),
    valuesm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)]),
    noGutters: PropTypes.bool,
    optional: PropTypes.bool,
    classNameVal: PropTypes.string,
    errorTxt: PropTypes.string,
    maxErrorTxt: PropTypes.string,
    minErrorTxt: PropTypes.string,
    emptyErrorTxt: PropTypes.string,
    name: PropTypes.string,
    alignItems: PropTypes.string,
    flexDirection: PropTypes.string,
    locale: PropTypes.string,
    inputclassname: PropTypes.string,
    DOBErrorMsg: PropTypes.string,
    groupField: PropTypes.bool,
};

VCMDatePicker.defaultProps = {
    labelsm: 2,
    valuesm: 4,
    value: '',
    id: '',
    min: '1900-01-01',
    max: '2999-12-31',
    label: '',
    onChange: () => {},
    onBlur: () => {},
    classNameVal: '',
    noGutters: false,
    optional: false,
    errorTxt: '',
    maxErrorTxt: '',
    minErrorTxt: '',
    emptyErrorTxt: '',
    name: '',
    alignItems: 'center',
    flexDirection: 'row',
    inputclassname: '',
    DOBErrorMsg: 'You must be 18 years or older to open an account',
    groupField: false,
};

export default VCMDatePicker;
