import {
    accOpeningActions,
    localStateManagementActions,
    loginActions,
    profileSettingsAction,
    marketNAOInfoActions
} from '../../../shared/Actions';
import { connect } from 'react-redux';
import MarketPersonalInfoComponent from './MarketPersonalInfoComponent';
import withAccountManagement from '../AccountManagementHOC';
import { withRouter } from 'react-router';

export const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.loginData,
    localStateData: state.localStateManagementReducerData,
    masterLookupStateData: state.transactionCompositeDataReducerData,
    profileInformationData: state.profileInformationData,
    marketLocalStateData: state.marketNAOReducerData,
    memberDashboardData: state.memberDashboardData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...loginActions,
    ...profileSettingsAction,
    ...marketNAOInfoActions,
    getMemberProfileInformation: profileSettingsAction.getMemberProfileInformations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(MarketPersonalInfoComponent)));
