import makeApiRequest from '../Network/ApiInterface';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

export const getStateCity = (payload, addressType) => dispatch => {
    const env = '';
    const { zip } = payload;
    const splittedZip = zip.split('-');
    const newPayload = {
        zip: splittedZip[0]
    };

    dispatch({
        type: `${ActionTypes.GET_STATECITY_LOADER}_${addressType}`,
    });
    makeApiRequest(
        ActionTypes.GET_STATECITY,
        newPayload,
        env,
        response => {
            const { data } = response;
            if (data) {
                if (splittedZip[1]) data.zip = `${data.zip}-${splittedZip[1]}`;
                dispatch({
                    type: `${ActionTypes.GET_STATECITY_SUCCESS}_${addressType}`,
                    response: data,
                });
            } else {
                dispatch({
                    type: `${ActionTypes.GET_STATECITY_ERROR}_${addressType}`,
                    response: data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: `${ActionTypes.GET_STATECITY_ERROR}_${addressType}`,
                response: data,
            });
        },
    );
};


export const formatAddrByUSPSService = (payload, addressType) => dispatch => {
    const env = '';
    dispatch({
        type: `${ActionTypes.GET_ADDRESSFORMAT_LOADER}_${addressType}`,
    });
    makeApiRequest(
        ActionTypes.GET_ADDRESSFORMAT,
        payload,
        env,
        response => {
            const { data } = response;
            if (data) {
                if (data.zip4) data.zip = `${data.zip}-${data.zip4}`;
                dispatch({
                    type: `${ActionTypes.GET_FORMATTED_ADDRESS_SUCCESS}_${addressType}`,
                    response: data,
                });
            } else {
                dispatch({
                    type: `${ActionTypes.GET_FORMATTED_ADDRESS_ERROR}_${addressType}`,
                    response: data,
                });
            }
        },
        error => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: `${ActionTypes.GET_FORMATTED_ADDRESS_ERROR}_${addressType}`,
                response: data,
            });
        },
    );
};

export const setAddress = (payload, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ADDRESS_DATA}_${addressType}`,
        addressData: payload,
    });
};

export const setAddressLine1 = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ADDRESS_LINE1}_${addressType}`,
        data
    });
};

export const setAddressLine2 = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ADDRESS_LINE2}_${addressType}`,
        data
    });
};

export const setZipCode = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ZIPCODE}_${addressType}`,
        data
    });
};

export const setAddressLine2Error = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ADDRESS_LINE2_ERROR}_${addressType}`,
        data
    });
};

export const setZipCodeError = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ZIPCODE_ERROR}_${addressType}`,
        data
    });
};

export const setCallAddressAPI = (addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_CALL_ADDRESS_API}_${addressType}`,
    });
};

export const resetAddressInfo = (addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.RESET_ADDRESS_INFO}_${addressType}`,
    });
};

export const setCityValue = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_CITY}_${addressType}`,
        data
    });
};

export const setStateValue = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_STATE}_${addressType}`,
        data
    });
};

export const hideUSPSDialog = (addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.HIDE_USPS_DIALOG}_${addressType}`,
    });
};

export const setAddressReadyStatus = (data, addressType) => dispatch => {
    dispatch({
        type: `${ActionTypes.SET_ADDRESS_READY_STATUS}_${addressType}`,
        data,
    });
};

export const clearActions = (addressType) => (dispatch) => {
    dispatch({
        type: `${ActionTypes.ADDRESS_REMOVE_EXISTING_VALUES}_${addressType}`,
    });
};