import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { isMobileOnly, isTablet ,isIE} from 'react-device-detect';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import PdfViewer from 'modules/AccountRegistration/components/PdfViewer';
import styles from 'modules/AccountRegistration/components/inputs/CommonStyles';
import { isString } from 'lodash';

const DOWNLOAD_PDF = 'Download PDF';

const DocDom = props => {
  const { file, saveAs } = props;
  const onDownloadClick = useCallback(() => window.navigator.msSaveOrOpenBlob(file, saveAs), [file, saveAs]);
  if (isIE) {
      return (
          <button className="downloadPdf" type="button" tabIndex="0" onClick={onDownloadClick}>
              <span className="sr-only">{DOWNLOAD_PDF}</span>
          </button>
      );
  }
  return (
      <a
          href={isString(file) && file.indexOf('.pdf') !== -1 ? file : URL.createObjectURL(file)}
          className="downloadPdf"
          target="_blank"
          rel="noopener noreferrer"
          download={saveAs}
          tabIndex="0"
          role="button"
      >
          <span className="sr-only">{DOWNLOAD_PDF}</span>
      </a>
  );
};


DocDom.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  saveAs: PropTypes.string,
};

DocDom.defaultProps = {
  file: {},
  saveAs: '',
};


const PrintPdf = ({ show, handleHide, file, type, maximumModalHeight, saveAs, isDocumentCenter, title,printIconHider, marketNAOFlow,printStatus = true }) => {
  const tabletOrDeskTopStyle = isTablet
    ? styles.tabletPdfModalStyle
    : styles.pdfModalStyle;

  const mainStyle = {
    ...tabletOrDeskTopStyle,
    maxHeight: maximumModalHeight - 40,
  };
  const mobileStyle = {
    ...styles.mobilePdfModalStyle,
    maxHeight: maximumModalHeight - 40,
  };
  const myDocument = React.createRef();
  // const fileNameArr = (file && file.split('/')) || [];
  // const tempName = fileNameArr[fileNameArr.length - 1];
  return (
    show && (
      <Modal
        style={isMobileOnly ? mobileStyle : mainStyle}
        className="pdfView"
        show={show}
        onHide={() => handleHide(type)}
        dialogClassName="wModalCommon pdfViewer"
        centered
      >
        <Modal.Header>
          <div className="modalHeader">
          {(marketNAOFlow != undefined && marketNAOFlow == 'marketNAOFlow') ? ('') : (<>
            {isDocumentCenter ? (
                
                <DocDom file={file} saveAs={saveAs} />
            ) : (
                        <a
                            href={file.url}
                            className="downloadPdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            tabIndex="0"
                            role="button"
                        >
                            <span className="sr-only">{DOWNLOAD_PDF}</span>
                        </a>
                    
                )} </>)}
            <ReactToPrint
              content={() => myDocument.current}
              bodyClass="printElement"
            >
              {printIconHider=="printIconHider"?null:
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button
                    type="button"
                    className="printPdf"
                    onClick={handlePrint}
                  >
                    <span className="sr-only">Print PDF</span>
                  </button>
                )}
              </PrintContextConsumer>}
            </ReactToPrint>
            <button
              type="button"
              className="closeButton"
              onClick={() => handleHide(type)}
            >
              <span className="sr-only">Close Button</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PdfViewer
            file={file}
            maximumModalHeight={window.innerHeight}
            myDocumentref={myDocument}
          />
        </Modal.Body>
      </Modal>
    )
  );
};

PrintPdf.propTypes = {
  show: PropTypes.bool,
  handleHide: PropTypes.func,
  file: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  maximumModalHeight: PropTypes.number,
};

PrintPdf.defaultProps = {
  show: false,
  handleHide: PropTypes.func,
  file: {},
  type: '',
  maximumModalHeight: 0,
};

export default PrintPdf;
