import {
    accOpeningActions,
    localStateManagementActions,
    loginActions,
    profileSettingsAction,
    tradingWindowActions,
    marketNAOInfoActions,
    transactionCompositeDataAction
} from '../../../shared/Actions';
import MarketAccountTypeComponent from './MarketAccountTypeComponent';
import { connect } from 'react-redux';
import withAccountManagement from '../AccountManagementHOC';
import { withRouter } from 'react-router';
import { postMarketNAOInfo,updateMarketNAOInfo,clearMarketNAOInfo } from 'shared/Actions/MarketNAOActions';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
   // initialState: state.loginData,
    //masterLookupStateData: state.masterLookUpData,
    marketLocalStateData: state.marketNAOReducerData,
    localStateData: state.localStateManagementReducerData,
    loginState: state.loginData,
    profileInformationData : state.profileInformationData,
    memberDashboardData:state.memberDashboardData
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    ...loginActions,
    ...profileSettingsAction,
    ...tradingWindowActions,
    ...marketNAOInfoActions,
    getMemberProfileInformation: profileSettingsAction.getMemberProfileInformations,
    getCompositeData: transactionCompositeDataAction.getTransactionCompositeData,
    postMarketNAOInfo:postMarketNAOInfo,
    updateMarketNAOInfo:updateMarketNAOInfo,
    clearMarketNAOInfo:clearMarketNAOInfo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(MarketAccountTypeComponent)));
