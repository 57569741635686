import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CompareFundsComponent from './CompareFundsComponent';
import { accOpeningActions } from '../../../shared/Actions';

const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompareFundsComponent));
