import Cookies from "js-cookie";
import { getDomainName } from 'utils';

const domainName = getDomainName();
const userid = localStorage.getItem('userId');

export const getDeviceId = (val) => {
   
   return Cookies.get(val) || '';
}

export const setDeviceId = (val ,deviceid, ttlVal) => {
       Cookies.set(val, deviceid, {expires: ttlVal, secure: true, domain: `.${domainName}`});
       // Cookies.set(val, deviceid, {expires: ttlVal});
}

export const getDeviceRegUserPref = (val) => {
    return Cookies.get(val);
}

export const setDeviceRegUserPref = (val, pref, ttlDays) => {
    if(ttlDays!==0) {
           Cookies.set(val, pref, {expires: ttlDays, secure: true, domain: `.${domainName}`});
           // Cookies.set(val, pref, {expires: ttlDays});
    }
    //  else {
    //     Cookies.set(val, pref, {secure: true, domain: `.${domainName}`});
    // }
}

export const setDeviceRegMsg = (msg, msg2) => {
    const regmsg = `${userid}_regmsg`;
    const regmsg2 = `${userid}_regmsg2`;
    Cookies.set(regmsg,msg);
    Cookies.set(regmsg2,msg2);
}

export const getDeviceRegMsg1 = () => {
    const regmsg = `${userid}_regmsg`;
    return Cookies.get(regmsg);
}

export const getDeviceRegMsg2 = () => {
    const regmsg2 = `${userid}_regmsg2`;
    return Cookies.get(regmsg2);
}

export const setDeviceNotificationStatus = (val) => {
    const msgNotify = `${userid}_notify`;
    Cookies.set(msgNotify,val)
}

export const getDeviceNotificationStatus = () => {
    const msgNotify = `${userid}_notify`;
    return Cookies.get(msgNotify);
}
