import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Col, Container } from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import routeConstants from "routeConstants";
import { BottomNavBar, CardWithMenu, PageTitle, AddCard, CardDeck } from 'modules/CustomerManagementModule/components';
import { getAddressAsLines } from 'modules/CustomerManagementModule/utils';
import CONST from 'modules/CustomerManagementModule/ContactInformation/constants';

const StyledLine = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const menuOptions = [{name:"Edit", icon:editIcon},{name:"Delete", icon:deleteIcon}];

const backBtnProps = {};

export default class RelationshipContactInfoComponent extends React.Component {
    
    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        this.clearFlags();
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
    }

    componentDidUpdate(){
        this.clearFlags();
    }
    
    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            address_type:addressType
        } = masterLookupStateData || {};
        const payLoad = [];
        if (!addressType) payLoad.push('address_type');
        return payLoad;
    };
    
    clearFlags = ()=> {
        const { clearRelationActions, profileRelationshipData } = this.props;
        const { isError, isSuccess } = profileRelationshipData;
        if(isSuccess || isError){
            clearRelationActions();
        }
    }

    editAddress = (addressData) => (e) => {
        e.stopPropagation();
        const { history, showNotification } = this.props;
        const msg = {
            message: CONST.FOREIGN_ADDRESS_UPDATE_MSG,
            info: null,
        };
        const { isForeignAddress } = addressData;
        if (isForeignAddress) {
            showNotification({ msg });
        } else {
            history.push(routeConstants.relationshipEditContact, addressData);
        }
    };

    cancel = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipContactInfo_back`
        })
        history.push(routeConstants.manageRelations);
    };

    deleteAddress = (data) => (e) => {
        e.stopPropagation();
        const { profileRelationshipData= {} } = this.props;
        const { familyRelationShipInfo, familyMemberIndex } = profileRelationshipData;
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
        const { relationshipId='' } = familyMemberInfo;
        const deletePayload = {
            role : "Family",
            relationshipId,
            addressUpdate: [
                {
                    ...data,
                    addressType: '',
                    addressLine1: '',
                    addressLine2: '',
                    zip: '',
                    city: '',
                    state: '',
                }
            ]
    };
        const { updateProfileRelationship } = this.props;
        updateProfileRelationship({ ...deletePayload, isDeleteAPICalled: true});
    };
    
    findMailingAddresstype = (address) => {
        return (address || []).find((data) => data.isMailingAddress);
    }
    
    findPhysicalAddresstype = (address) => {
        return (address || []).find((data) => data.isPhysicalAddress);
    }

    onMenuOptionClick = (addObj, addrIndex) => (menuOption) => (e) => {
        const { analyzeClickEvent } = this.props;
        let addrTypeName = '';
        if(addrIndex === 0){
            addrTypeName = 'PhysicalAddress';
        }else{
            addrTypeName = 'MailingAddress';
        }
        if(menuOption === 'Edit'){
            analyzeClickEvent({
                link: `cms_basicinformation_managecontactinfo_${addrTypeName}_edit`
            });
            this.editAddress(addObj)(e);
        }else if(menuOption === 'Delete') {
            analyzeClickEvent({
                link: `cms_basicinformation_managecontactinfo_${addrTypeName}_delete`
            });
            this.deleteAddress(addObj)(e);
        }
    }

    onAddCardClick = (accDetails) => (e) => {
        const { analyzeClickEvent } = this.props;
        e.stopPropagation();
        analyzeClickEvent({
            link: `cms_basicinformation_managerelationshipcontactinfo_Add${!accDetails.isMailingAddress?'PhysicalAddress':'Mailingaddress'}`
        });
        const { history } = this.props;
        history.push(routeConstants.relationshipAddContact, { ...accDetails } );
    }

    render() {
        const { profileRelationshipData } = this.props;
        const { familyRelationShipInfo = [], familyMemberIndex } = profileRelationshipData || {};
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
        const { addressInformation } = familyMemberInfo;
        
        const { isLoading: loading } = profileRelationshipData;
        
        const mailingAddress = this.findMailingAddresstype(addressInformation);
        const physicalAddress = this.findPhysicalAddresstype(addressInformation);
        
        const { line1: phyLine1, line2:phyLine2, line3:phyLine3, line4:phyLine4 } = getAddressAsLines(physicalAddress);
        const { line1:mailLine1, line2:mailLine2, line3:mailLine3, line4:mailLine4 } = getAddressAsLines(mailingAddress);
        
        backBtnProps.onClick = this.cancel;
        backBtnProps['aria-label'] = "Back To Manage Relation Information";

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={CONST.BREADCRUM_MANAGE_RELATION_CONTACT}
                            activeCrumb={CONST.PAGE_NAME_CONTACT_MANAGE}
                        />
                        <PageTitle
                            title={CONST.PAGE_NAME_CONTACT_MANAGE} 
                        />
                        <CardDeck data-mt={2.5} data-mb={7}>
                            {!physicalAddress ?  (<Col md={6} lg={4}>
                                <AddCard
                                    minHeight={15.625}
                                    addTxt={CONST.ADD_PHYSICAL_TEXT}  
                                    onAddCardClick = {this.onAddCardClick({ isPhysicalAddress: true })} 
                            /></Col>):
                            (<Col md={6} lg={4}><CardWithMenu
                                cardTitle = { CONST.PHYSICAL_ADDRESS }
                                Line1 ={phyLine1}
                                extradetail= {
                                    <>
                                        <StyledLine>{phyLine2}</StyledLine>
                                        <StyledLine>{phyLine3}</StyledLine>
                                        {phyLine4 && <StyledLine>{phyLine4}</StyledLine>}
                                    </>
                                    }
                                // menu
                                menuOptions={menuOptions}
                                pT='1.25'
                                onMenuOptionClick={this.onMenuOptionClick(physicalAddress, 0)}
                            /></Col>)
                            }
                            {!mailingAddress ? (<Col md={6} lg={4}>
                                <AddCard
                                    minHeight={15.625}
                                    addTxt={CONST.ADD_MAILING_TEXT}  
                                    onAddCardClick = {this.onAddCardClick({ isMailingAddress: true })} 
                            /></Col>):
                            <Col md={6} lg={4}><CardWithMenu
                                cardTitle = { CONST.MAILING_ADDRESS }
                                Line1 ={mailLine1}
                                extradetail= {
                                    <>
                                        <StyledLine>{mailLine2}</StyledLine>
                                        <StyledLine>{mailLine3}</StyledLine>
                                        {mailLine4 && <StyledLine>{mailLine4}</StyledLine>}
                                    </>
                                    }
                                // menu
                                menuOptions={menuOptions}
                                pT='1.25'
                                onMenuOptionClick={this.onMenuOptionClick(mailingAddress, 1)}
                            /></Col>
                            }
                        </CardDeck>
                    </Container>
                    <BottomNavBar
                        hidePrimaryBtn
                        backBtnProps={backBtnProps}
                    />
            </>
        );
    }
}

RelationshipContactInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    clearRelationActions: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    profileRelationshipData: PropTypes.instanceOf(Object),
    updateProfileRelationship: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    showNotification: PropTypes.func,
};

RelationshipContactInfoComponent.defaultProps = {
    getCompositeLookUpData: () => {},
    clearRelationActions: () => {},
    masterLookupStateData: {},
    profileRelationshipData: {},
    updateProfileRelationship: () => {},
    analyzeClickEvent: ()=>{},
    showNotification: () => {},
};
