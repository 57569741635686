import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions, loginActions, analytics  } from '../../../../shared/Actions';
import OtpIvrSelect from './OtpIvrSelectComponent';

export const mapStateToProps = (state /* , props */) => ({
    verifyOTPData: state.loginData.verifyOtp,
    loginState: state.loginData,
    otpDetails: state.localStateManagementReducerData.otpDetails,
    memberOrGuestIVRVerifyData: state.loginData.memberOrGuestIVR,
    recoverOnlineIdData: state.loginData.recoverOnlineID,
    pageData: state.localStateManagementReducerData.pageData,
    personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    directStatusofVerifyOTPData: state.loginData.directStatusofVerifyOTP,
    customerProfile: state.localStateManagementReducerData.customerProfile,
});

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions,
    ...analytics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OtpIvrSelect));
