import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import VerifyComponent from './VerifyComponent';
import { accOpeningActions, localStateManagementActions } from '../../../shared/Actions';
import withAccountManagement from '../AccountManagementHOC';

const mapStateToProps = (state /* , props */) => ({
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    accOpeningData: state.accOpeningReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAccountManagement(VerifyComponent)));
