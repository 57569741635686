import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import consts from '../../components/constants';
import PdfViewer from '../../components/PdfViewer';
import styles from '../../components/inputs/CommonStyles';

const assignObj = (obj) => obj;

const PrintPdf = ({
  show, handleHide, file, type, maximumModalHeight,
}) => {
  const tabletOrDeskTopStyle = isTablet
    ? styles.tabletPdfModalStyle
    : styles.pdfModalStyle;

  const mainStyle = assignObj({
    ...tabletOrDeskTopStyle,
    maxHeight: maximumModalHeight - 40,
  });
  const mobileStyle = assignObj({
    ...styles.mobilePdfModalStyle,
    maxHeight: maximumModalHeight - 40,
  });

  const myDocument = React.createRef();
  
  return (
    show && (
      <Modal
        style={isMobileOnly ? mobileStyle : mainStyle}
        className="pdfView"
        show={show}
        backdropClassName="pdfModal"
        onHide={assignObj(() => handleHide(type))}
        dialogClassName="wModalCommon pdfViewer"
        centered
      >
        <Modal.Header>
          <div className="modalHeader">
            <a
              href={file.url}
              className="downloadPdf"
              download
              tabIndex="0"
              role="button"
              rel="noopener noreferrer"
              target='_blank'
            >
              <span className="sr-only">{consts.downloadPDF}</span>
            </a>
            <ReactToPrint
              content={assignObj(() => myDocument.current)}
              bodyClass="printElement"
            >
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button
                    type="button"
                    className="printPdf"
                    onClick={handlePrint}
                  >
                    <span className="sr-only">{consts.printPDF}</span>
                  </button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
            <button
              type="button"
              className="closeButton"
              onClick={assignObj(() => handleHide(type))}
            >
              <span className="sr-only">{consts.close}</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PdfViewer
            file={file}
            maximumModalHeight={window.innerHeight}
            myDocumentref={myDocument}
          />
        </Modal.Body>
      </Modal>
    )
  );
};

PrintPdf.propTypes = {
  show: PropTypes.bool,
  handleHide: PropTypes.func,
  file: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  maximumModalHeight: PropTypes.number,
};

PrintPdf.defaultProps = {
  show: false,
  handleHide: PropTypes.func,
  file: {},
  type: '',
  maximumModalHeight: 0,
};

export default PrintPdf;
