import { checkValidity } from '../../../utils';

const validateForm = state => {
    const fields = Object.keys(state);
    let formIsValid = true;
    if(fields.indexOf('victoryId')>-1 || fields.indexOf('usaaId')>-1){
        return (state.victoryId.valid && !!state.victoryId.value)|| (state.usaaId.valid && !!state.usaaId.value)
    }
    for (let i = 0; i < fields.length; i += 1) {
        formIsValid = state[fields[Number(i)]].valid && formIsValid;
    }
    return formIsValid;
};

export const getFormattedValue = value => {
    let formattedValue;
    if (value.length <= 3) {
        formattedValue = value;
    } else if (value.length > 3 && value.length < 7) {
        formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else {
        formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
    }
    return formattedValue;
};

export const inputChangedHandler = (event, fieldId, obj) => {
    const updatedFormState = {
        ...obj.state.form,
    };
    const updatedField = {
        ...updatedFormState[fieldId.toString()],
    };
    if (updatedField.options) {
        updatedField.value = event.target.value;
        updatedField.displayValue = event.target.defaultValue;
    } else if (updatedField.type && updatedField.type === 'number') {
        const isNotNumber = /\D/.test(event.target.value);
        if (!isNotNumber) {
            // accepting only numbers for a field with type:number
            updatedField.value = event.target.value;
        }
    } else if (updatedField.type && updatedField.type === 'mobile') {
        // Converting mobile number into format ###-###-####
        const { value } = event.target;
        const numberValue = value.split('-').join('');
        const formattedValue = getFormattedValue(numberValue);
        updatedField.value = formattedValue;
    } else if (updatedField.rules.equal) {
        // added rules.equal to confirmPassword field
        if (obj.state.form.password.valid) {
            // updating confirmPassword value only if the password field is valid
            updatedField.value = event.target.value;
        } else {
            updatedField.value = '';
        }
    } else {
        updatedField.value = event.target.value;
    }
    if (updatedField.rules.equal) {
        updatedField.valid = checkValidity({ ...updatedField, equalValue: obj.state.form.password.value }) === null;
    } else {
        updatedField.valid = checkValidity({ ...updatedField }) === null;
    }

    if (fieldId.toString() === 'dob' && updatedField.valid){
        updatedField.errMsg = null;
    }

    updatedField.touched = true;
    updatedFormState[fieldId.toString()] = updatedField;
    const formIsValid = validateForm(updatedFormState);
    obj.setState({ form: updatedFormState, formIsValid });
};

export const inputValidityHandler = (fieldId, obj) => {
    const updatedFormState = {
        ...obj.state.form,
    };
    const updatedField = {
        ...updatedFormState[fieldId.toString()],
    };
    // console.log(updatedField.value)
    if (updatedField.rules.equal) {
        updatedField.errMsg = checkValidity({ ...updatedField, equalValue: obj.state.form.password.value });
    } else {
        updatedField.errMsg = checkValidity({ ...updatedField });
    }
    if(fieldId==='onlineId' && obj.state.form.onlineId.context==='registeronlineid') {
        const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
        if(emailRegex.test(obj.state.form.onlineId.value)) {
            updatedField.errMsg = 'User ID cannot be an e-mail ID';
        }
    }

    if (updatedField.errMsg !== null) {
        updatedField.valid = false;
    } else {
        updatedField.valid = true;
    }

    updatedField.touched = true;
    updatedFormState[fieldId.toString()] = updatedField;

    const formIsValid = validateForm(updatedFormState);    
    obj.setState({ form: updatedFormState, formIsValid });
};

export const changedHandler = (event, obj) => {
    const updatedState = {
        ...obj.state,
    };
    if (updatedState.type && updatedState.type === 'number') {
        if (!/\D/.test(event.target.value)) {
            updatedState.value = event.target.value;
        }
    } else {
        updatedState.value = event.target.value;
    }
    updatedState.touched = true;
    updatedState.valid = checkValidity(updatedState) === null;
    obj.setState(updatedState);
};

export const validityHandler = obj => {
    const updatedState = {
        ...obj.state,
    };
    updatedState.errMsg = checkValidity(updatedState);
    if (updatedState.errMsg !== null) {
        updatedState.valid = false;
    } else {
        updatedState.valid = true;
    }
    updatedState.touched = true;
    obj.setState(updatedState);
};
