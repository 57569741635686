import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

const assignObj = obj => {
    return obj;
};

const PasswordStrength = props => {
    const { style, len, valid } = props;
    const styles = {
        container: {
            display: 'flex',
            width: '100%',
            color: 'red',
        },
        main: {
            marginTop: '7px',
            ...style,
        },
        columnGrey: {
            boxSizing: 'border-box',
            backgroundColor: '#D2D2D2',
            height: '4px',
            borderRadius: '4px',
        },
        red: {
            backgroundColor: '#E60000',
        },
        orange: {
            backgroundColor: '#EA7400',
        },
        green: {
            backgroundColor: '#8CBF26',
        },
        para: {
            paddingTop: 2,
            margin: 0,
            font: '600 12px/20px benton-sans',
            color: '#56565A',
            marginBottom: 2
        },
        Col: {
            paddingLeft: 0,
        },
        lastCol: {
            padding: 0,
        },
    };
    const data = {
        len,
        strenth: '',
    };
    if (valid) {
        if (len > 0 && len <= 8) {
            data.strenth = 'Weak';
        } else if (len <= 12 && len > 8) {
            data.strenth = 'Good';
        } else if (len > 12) {
            data.strenth = 'Strong';
        }
    } else if (len > 0) {
        data.strenth = 'Weak';
    }

    let bar1 = <div style={styles.columnGrey} />;
    let bar2 = <div style={styles.columnGrey} />;
    let bar3 = <div style={styles.columnGrey} />;

    if (data.strenth === 'Weak') {
        bar1 = <div style={assignObj({ ...styles.columnGrey, ...styles.red })} />;
    }
    if (data.strenth === 'Good') {
        bar1 = <div style={assignObj({ ...styles.columnGrey, ...styles.orange })} />;
        bar2 = <div style={assignObj({ ...styles.columnGrey, ...styles.orange })} />;
    }
    if (data.strenth === 'Strong') {
        bar1 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
        bar2 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
        bar3 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
    }

    return (
        <div style={styles.main}>
            <div style={styles.container}>
                <Col style={styles.Col}>{bar1}</Col>
                <Col style={styles.Col}>{bar2}</Col>
                <Col style={styles.lastCol}>{bar3}</Col>
            </div>
            <p style={styles.para} aria-live="polite">
                <span className="sr-only">Your password strength is</span>
                {data.strenth}
            </p>
        </div>
    );
};

PasswordStrength.propTypes = {
    valid: PropTypes.bool,
    len: PropTypes.number,
    style: PropTypes.shape({}),
};

PasswordStrength.defaultProps = {
    valid: false,
    len: PropTypes.number,
    style: {},
};
export default PasswordStrength;
