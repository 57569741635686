import React, { Component } from 'react';
import PropTypes from 'prop-types';
import envConfig from 'env/EnvConfig';

let loadScript = true;
export default class ApplyScript extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const { data, pathName, welcomePageLoaded } = props;

    if (pathName === 'default') {
      loadScript = welcomePageLoaded;
    }
    if (loadScript && !window.scriptLoaded) {
      // console.log('@debug for props in analytics component',data);
      window.digitalData = data;
      const script = document.createElement('script');
      script.src = `${envConfig.ADOBE_URL}`;
      // didnt see in envConfig or env file
      script.async = true;
      document.body.appendChild(script);
      window.scriptLoaded = true;
    }
    return state;
  }

  render() {
    return <></>;
  }
}

ApplyScript.propTypes = {
  data: PropTypes.instanceOf(Object),
  pathName: PropTypes.string,
  welcomePageLoaded: PropTypes.bool,
};

ApplyScript.defaultProps = {
  data: {},
  pathName: '',
  welcomePageLoaded: false,
};
