import { connect } from 'react-redux';
import { updateProfileRelationship, clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeFormFieldError, analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import RelationshipAddEmailInfoComponent from './RelationshipAddEmailInfoComponent';

export const mapStateToProps = state => {
    const { profileRelationshipData } = state;
    const { isError } = profileRelationshipData;
    return {
        profileRelationshipData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    updateProfileRelationship,
    clearRelationActions,
    analyzeFormFieldError,
    analyzeClickEvent, 
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(RelationshipAddEmailInfoComponent));
