import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const C = {
    WCardDashboardOverlay: 'WCardDashboardOverlay',
};
const assignObj = obj => obj;

const WCardDashboardOverlay = props => {
    const { children, showCorner, myStyles, showCornerLeft } = props;
    return (
        <div className={C.WCardDashboardOverlay} style={assignObj({ ...styles.subHeader, ...myStyles })} data-test="cardoverlay">
            {showCorner && <div className="cornerPosition" style={styles.corner} />}
            {showCornerLeft && <div className="cornerPosition" style={styles.leftCorner} />}
            {children}
        </div>
    );
};

WCardDashboardOverlay.propTypes = {
    children: PropTypes.instanceOf(Object),
    showCorner: PropTypes.bool,
    myStyles: PropTypes.instanceOf(Object),
    showCornerLeft: PropTypes.bool,
    // myClassNames: PropTypes.string,
};

WCardDashboardOverlay.defaultProps = {
    children: {},
    showCorner: false,
    myStyles: {},
    showCornerLeft: false,
    // myClassNames: '',
};

export default WCardDashboardOverlay;
