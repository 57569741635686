const downloadLabelMargins = {
    padding: 0
}

const submitButtonAlign = {
    paddingTop: 40,
    paddingBottom: 40
}

const subHeading = {
    font: '600 16px/19px benton-sans',
    color: '#49494A',
    marginBottom: 30
}

const downloadImage= {
    marginRight: 30,
    marginLeft: 15
}

export default {
    subHeading,
    downloadLabelMargins,
    submitButtonAlign,
    downloadImage
}