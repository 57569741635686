import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
    WButton,
    WBreadCrumb,
    PageHeading,
    WIcon,
    ConformationModal,
    CommonButtons,
    WMessageBar,
    WSpinner,
} from '../../../common';
import constAmend from './constAmend';
import AmendTransactionCard from './AmendTransactionCard';
import filterActive from '../../../assets/filter-active.png';
import ErrorBoundary from '../Common/ErrorBoundary/errorboundary';

const TRANS_DASHBOARD_URL = '/transactionDashboard';

const breadCrumbURL = [
    { url: TRANS_DASHBOARD_URL, name: 'Home' },
    { url: TRANS_DASHBOARD_URL, name: 'Portfolio' },
    { url: TRANS_DASHBOARD_URL, name: 'Transactions' },
];
const pageButtonStyle = {
    color: '#004A98',
    border: 'none',
    height: 'unset',
    width: 'unset',
    outline: 'none',
    padding: '5 0 0 5px',
    marginRight: 50,
};
const assignObj = obj => {
    return obj;
};
const ModalCancelText = 'Cancel';
const ModalProceedText = 'Proceed';

const rowStyle = { float: 'right', marginTop: '10px' };

const messageBarText = { message: 'The Order has been successfully submitted.', info: '' };

class AmendTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountsAvailable: [],
            checkboxRequired: true,
            buttonFilter: 'Filter',
            variantFilter: 'outline',
            openConfirmationDialog1: false,
            openConfirmationDialog2: false,
            displayNotification: false,
            orderId: '',
            accIndex: 0,
            transactionType: '',
            amount: '',
            fundName: '',
            cancellationData: {},
        };
    }

    componentDidMount() {
        const { pendingTransactionData, getTransactionData, initialState, location } = this.props;
        const payload = {
            customerId: initialState.currentPIN,
            companyNumber: '591',
            fundNumber: '30',
            accountNumber: '3000000017',
        };
        getTransactionData(payload);
        // console.log(pendingTransactionData);
        this.setState({ accountsAvailable: pendingTransactionData.menu });
        if (location.state) {
            const { notification } = location.state;
            this.setState({
                displayNotification: notification,
            });
        }
    }

    handleAmendClick = (accIndex, orderId) => {
        const { accountsAvailable } = this.state;
        this.setState({
            openConfirmationDialog1: true,
            orderId,
            accIndex,
            transactionType: accountsAvailable[+accIndex].data.TransactionType,
            fundName: accountsAvailable[+accIndex].data.selectedFundData.fundName,
            amount: accountsAvailable[+accIndex].data.selectedFundData.funds[0].worthAmount,
        });
    };

    handleCancelClick = (accIndex, orderId, cancellationData) => {
        const { accountsAvailable } = this.state;
        this.setState({
            openConfirmationDialog2: true,
            orderId,
            accIndex,
            transactionType: accountsAvailable[+accIndex].data.TransactionType,
            fundName: accountsAvailable[+accIndex].data.selectedFundData.fundName,
            amount: accountsAvailable[+accIndex].data.selectedFundData.funds[0].worthAmount,
            cancellationData,
        });
    };

    onConfirmationProceedToAmend = () => {
        const { history } = this.props;
        const { orderId, accIndex } = this.state;
        history.push({
            pathname: '/amendProceed',
            state: {
                orderId,
                accIndex,
            },
        });
    };

    onConfirmationProceedToCancel = () => {
        const { history } = this.props;
        const { orderId, accIndex, cancellationData } = this.state;
        history.push({
            pathname: '/amendCancel',
            state: {
                orderId,
                accIndex,
                cancellationData,
            },
        });
    };

    onConfirmationCancel = () => {
        this.setState({ openConfirmationDialog1: false });
        this.setState({ openConfirmationDialog2: false });
    };

    backHandler = () => {
        const { history } = this.props;
        if (history) {
            history.push(TRANS_DASHBOARD_URL);
        }
    };

    render() {
        const activeBreadCrumb = 'Amend';
        const {
            accountsAvailable,
            checkboxRequired,
            buttonFilter,
            variantFilter,
            openConfirmationDialog1,
            openConfirmationDialog2,
            displayNotification,
            orderId,
            transactionType,
            amount,
            fundName,
        } = this.state;
        const { pendingTransactionData } = this.props;
        const error = pendingTransactionData.isError;
        const loading = pendingTransactionData.isLoading;
        // console.log(pendingTransactionData, error);
        // console.log('Git asdfasfsa');
        const amendModalBody = `Are you sure you want to amend ${orderId} ( ${transactionType} of ${amount} of ${fundName} )`;
        const cancelModalBody = `Are you sure you want to Cancel ${orderId} ( ${transactionType} of ${amount} of ${fundName} )`;
        const amendModalText = `Amend Transaction`;
        const cancelModalText = `Cancel Transaction`;
        return (
            <>
                {loading && <WSpinner loading={loading} />}
                <div>
                    {openConfirmationDialog1 && (
                        <ConformationModal
                            modalTitle={amendModalText}
                            modalBodyText={amendModalBody}
                            primaryButtonText={ModalProceedText}
                            secondaryButtonText={ModalCancelText}
                            onPrimaryClick={this.onConfirmationProceedToAmend}
                            onSecondaryClick={this.onConfirmationCancel}
                        />
                    )}
                    {openConfirmationDialog2 && (
                        <ConformationModal
                            modalTitle={cancelModalText}
                            modalBodyText={cancelModalBody}
                            primaryButtonText={ModalProceedText}
                            secondaryButtonText={ModalCancelText}
                            onPrimaryClick={this.onConfirmationProceedToCancel}
                            onSecondaryClick={this.onConfirmationCancel}
                        />
                    )}
                    {displayNotification && <WMessageBar text={messageBarText} />}
                    <div style={constAmend.wrapperStyles}>
                        <div className="container">
                            <WBreadCrumb activeCrumb={activeBreadCrumb} breadCrumbItems={breadCrumbURL} />
                            <PageHeading>{constAmend.amendHeadingText}</PageHeading>
                            <Row style={rowStyle}>
                                <WIcon
                                    src={filterActive}
                                    alt="Filter icon"
                                    fontawesomestyle={assignObj({ paddingTop: 6 })}
                                />
                                <WButton
                                    variant={variantFilter}
                                    buttontext={buttonFilter}
                                    buttonstyle={pageButtonStyle}
                                    onClick={this.handleFilterTransactions}
                                />
                            </Row>
                            <br />
                            <br />
                            <br />

                            {accountsAvailable.length && !loading ? (
                                <ErrorBoundary>
                                    <AmendTransactionCard
                                        accountsAvailable={accountsAvailable}
                                        checkboxRequired={checkboxRequired}
                                        amendMenuClick={this.handleAmendClick}
                                        cancelMenuClick={this.handleCancelClick}
                                        error={error}
                                    />
                                </ErrorBoundary>
                            ) : (
                                ''
                            )}
                        </div>
                        <CommonButtons
                            backClick={this.backHandler}
                            showSave
                            style={assignObj({ padding: 20 })}
                            cancelstyle={assignObj({ display: 'none' })}
                            nextstyle={assignObj({ display: 'none' })}
                            savestyle={assignObj({ display: 'none' })}
                        />
                    </div>
                </div>
            </>
        );
    }
}

AmendTransaction.propTypes = {
    pendingTransactionData: PropTypes.instanceOf(Object).isRequired,
    getTransactionData: PropTypes.func.isRequired,
    initialState: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default AmendTransaction;
