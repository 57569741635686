/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routeConstants from 'routeConstants';
import WSpinner from './components/WSpinner';
import UserDetails from './screens/UserAccountInformation/UserAccountInformationContainer';
import OneTimeCodeListingPage from './screens/OneTimeListingPage/OneTimeCodeListPage.Container';
import './AccountRegistration.css';
import OneTimeCodeVerifyPage from './screens/OneTimeCodeVerifyPage';
import UserId from './screens/CreateUserId/CreateUserId.Container';
import Password from './screens/CreatePassword/CreatePassword.Container';
import Signout from './components/Signout';
import MemberMessage from './components/MemberMessage';
import TimeOutModal from './components/shared/TimeOutModal';
import LandingPage from './screens/LandingPage/LandingPage.Container';
import TermsAndCondition from './screens/TermsAndConditions/TermsAndCondition.Container';
import Success from './screens/Success';
import { digitalData } from './components/constants';
import ApplyScript from './analytics';
import analytics from './components/utils.analytics';
// import Header from './Header';
import LandingContent from './LandingContent';
// import Footer from './Footer';

class AccountRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idle: false,
    };
    this.idleTimer = React.createRef();
    this.ref = React.createRef();
    this.mobileClock = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const pathName = nextProps.location && nextProps.location.pathname.split('/')[2] !== ''
      ? nextProps.location.pathname.split('/')[2]
      : 'default';
      
    if (this.props !== nextProps) {
      analytics.setDigitalData(pathName, nextProps, nextState)(this);
    }
    return true;
  }

  onIdle = () => {
    this.setState({ idle: true });
  };

  handleModalClick = () => {
    this.setState({ idle: false });
  };
  

  handleSkipClick = (e) => {
    e.preventDefault();
    const r = document.getElementById('main-content');
    if (r) {
      r.scrollIntoView();
      r.tabIndex = -1;
      r.focus();
    }
  };

  render() {
    const { loading, location } = this.props;
    const { idle } = this.state;
    const pathName = location.pathname.split('/')[2] !== ''
      ? location.pathname.split('/')[2]
      : 'default';
    const addExtraPading = pathName !== 'userdetails' && pathName !== 'default';


    return (
      <React.StrictMode>
        <WSpinner
          connecting={loading.connecting}
          processing={loading.processing}
          thanks={loading.thanks}
        />
        <IdleTimer
          ref={this.idleTimer}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 3600}
        />
        <main id="account-registration">
          <div id="main-content">
            <Switch>
              <Route exact path={routeConstants.accountRegistration}>
                <LandingPage {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationUserDetails}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <UserDetails {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationTC}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <TermsAndCondition {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationOTL}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <OneTimeCodeListingPage {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationOTV}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <OneTimeCodeVerifyPage {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationUserId}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <UserId {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationPass}>
                <TimeOutModal show={idle} modalClick={this.handleModalClick} />
                <Password {...this.props} />
              </Route>
              <Route path={routeConstants.accountRegistrationSuccess}>
                <Success {...this.props}  />
              </Route>
              <Route path={routeConstants.accountRegistrationSignOut}>
                <Signout />
              </Route>
              <Route path="/accountRegistration/identityCheckFail">
                <MemberMessage page="identityCheckFail" />
              </Route>
            </Switch>
            <LandingContent addExtraPading={addExtraPading} />
          </div>
        </main>
        {/* <Footer /> */}
        <ApplyScript
          data={digitalData}
          pathName={pathName}
          welcomePageLoaded={loading.welcomePageLoaded}
        />
      </React.StrictMode>
    );
  }
}

AccountRegistration.propTypes = {
  location: PropTypes.instanceOf(Object),
  loading: PropTypes.instanceOf(Object),
  welcomePageLoaded: PropTypes.bool,
  accountInfo: PropTypes.instanceOf(Object),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

AccountRegistration.defaultProps = {
  location: {},
  loading: {},
  welcomePageLoaded: false,
  accountInfo: {},
  history: {},
};

const mapStateToProps = (state) => ({
  loading: state.AccountRegistration.loading || false,
  welcomePageLoaded: state.AccountRegistration.welcomePageLoaded || false,
  pageName: state.AccountRegistration.pageName || '',
  accountInfo: state.AccountRegistration.accountInfo || {},
});

export default withRouter(connect(mapStateToProps)(AccountRegistration));
