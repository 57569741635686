/**
 * Change Sign In By Soft token Component
 ***********************************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description By using the Soft token method to change the Sign in mode
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedBy Manjunath V
 * @lastModifiedReason Modified for Back button functionality to hold the prev screen (ChangeSignInMethodOptions) selected radio button
 *                     option to be selected when prev screen (ChangeSignInMethodOptions) page is loaded and implemented Breadcrumbs
 */

import React, { Component, memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import appleStore from 'assets/apple-store.svg';
import playStore from 'assets/play-store.svg';

import routeConstants from 'routeConstants';
import { WIcon, WBreadCrumb, WInput, CommonButtons } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import styles from './styles';
import userStyles from '../UserManagementStyles';
import { inputChangedHandler, inputValidityHandler } from '../FormUtils';
import * as constants from './constants';
import userConstants from '../userConstants';

const { breadCrumbValues, pageHeaderName, downloadLabel, optionalMessage } = constants;

const assignObj = obj => obj;

export class ChangeSignInBySoftTokenComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                credentialId: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: userConstants.changeSoft.credentialId,
                    rules: {
                        pattern: userConstants.alphNumericRegex,
                        patternMessage: userConstants.changeSoft.credentialId + userConstants.alphNumericMessage,
                        required: true,
                    },
                },
                firstToken: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: userConstants.changeSoft.firstToken,
                    type: userConstants.number,
                    rules: {
                        length: 6,
                        isNumeric: true,
                        required: true,
                    },
                },
                secondToken: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: userConstants.changeSoft.secondToken,
                    type: userConstants.number,
                    rules: {
                        length: 6,
                        isNumeric: true,
                        required: true,
                    },
                },
            },
            selectedOptionOnPrevPage: '',
            messageText: null,
        };
        this.clickBack = this.clickBack.bind(this);
        this.clickSubmit = this.clickSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const { state } = location || {};
        if (state) {
            this.setState({
                selectedOptionOnPrevPage: state.selectedOption,
            });
        }
    }

    onChange(event) {
        inputChangedHandler(event, event.target.id, this);
    }

    onBlur(event) {
        this.setState({
            messageText: {
                message: userConstants.changeSoft.notificationMessage,
            },
        });
        inputValidityHandler(event.target.id, this);
    }

    clickSubmit() {
        const { history, setSignInMethod } = this.props;
        const { messageText } = this.state;
        setSignInMethod({
            onlineId: userConstants.test,
            changeSignInMethod: userConstants.softToken, // otp, softtoken,push
            // "preferredOTPDeliveryMethod": "mobile", // "email",    // "mobile","securityQuestions",
            // "deviceName": "iPhoneX",
            // "deviceId": "AEDR12345667"
        });

        if (history) {
            history.push({
                pathname: routeConstants.securityPreferences,
                message: messageText,
            });
        }
    }

    clickBack() {
        const { history } = this.props;
        const { selectedOptionOnPrevPage } = this.state;

        if (history) {
            history.push({
                pathname: routeConstants.changeSignInMethodOptions,
                state: {
                    selectedOption: selectedOptionOnPrevPage,
                },
            });
        }
    }

    render() {
        const { form } = this.state;
        return (
            <div>
                <div className={userConstants.container}>
                    <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb={userConstants.changeSignInMethodText} />

                    <SideHeader title={pageHeaderName} />
                    <Row className={userConstants.w100}>
                        <Col>
                            <p style={styles.subHeading}>{downloadLabel}</p>
                        </Col>
                    </Row>
                    <div className={userConstants.container} style={styles.downloadLabelMargins}>
                        <Row>
                            <Col>
                                <Row>
                                    <a href={userConstants.appStoreLink} aria-label={userConstants.appStore}>
                                        <WIcon src={appleStore} alt="" fontawesomestyle={styles.downloadImage} />
                                    </a>
                                    <a href={userConstants.playStoreLink} aria-label={userConstants.playStore}>
                                        <WIcon src={playStore} alt="" />
                                    </a>
                                </Row>
                            </Col>
                            <Col style={assignObj({ paddingRight: 0 })}>
                                <p style={assignObj({ ...userStyles.optionalMessage, margin: '14px 0 0' })}>
                                    {optionalMessage}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <WInput
                        type={userConstants.text}
                        id={userConstants.changeSoft.credentialIdId}
                        label={userConstants.changeSoft.credentialId}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        value={form.credentialId.value}
                        errortext={form.credentialId.errMsg}
                        inputfieldstyle={userStyles.inputField}
                        inputstyle={assignObj(
                            form.credentialId.errMsg ? { ...styles.errorInputStyle } : { ...styles.inputStyle },
                        )}
                        valuesm={2}
                        errorstyle={styles.errorStyle}
                        labelstyle={styles.labelStyle}
                        maxlength={12}
                    />
                    <WInput
                        type={userConstants.text}
                        id={userConstants.changeSoft.firstTokenId}
                        label={userConstants.changeSoft.firstToken}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        value={form.firstToken.value}
                        errortext={form.firstToken.errMsg}
                        inputfieldstyle={userStyles.inputField}
                        inputstyle={assignObj(
                            form.firstToken.errMsg ? { ...styles.errorInputStyle } : { ...styles.inputStyle },
                        )}
                        valuesm={2}
                        errorstyle={styles.errorStyle}
                        labelstyle={styles.labelStyle}
                        maxlength={6}
                    />

                    <WInput
                        type={userConstants.text}
                        id={userConstants.changeSoft.secondTokenId}
                        label={userConstants.changeSoft.secondToken}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        value={form.secondToken.value}
                        errortext={form.secondToken.errMsg}
                        inputfieldstyle={userStyles.inputField}
                        inputstyle={assignObj({ marginBottom: 50 })}
                        valuesm={2}
                        errorstyle={styles.errorStyle}
                        labelstyle={styles.labelStyle}
                        maxlength={6}
                    />
                </div>
                <CommonButtons submitClick={this.clickSubmit} backClick={this.clickBack} submit={userConstants.trueText} arialabelback="Back to change sign in methods" />
            </div>
        );
    }
}

ChangeSignInBySoftTokenComponent.propTypes = {
    setSignInMethod: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
};

ChangeSignInBySoftTokenComponent.defaultProps = {
    setSignInMethod: () => {},
    history: [],
    location: {},
};

export default memo(ChangeSignInBySoftTokenComponent);