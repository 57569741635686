import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { loginActions, localStateManagementActions, analytics } from '../../../../shared/Actions';
import EnterOtpComponent from './EnterOtpComponent';



export const mapStateToProps = (state /* , props */) => (  
    {
        otp: state.loginData.otp,
        verifyOTPData: state.loginData.verifyOtp,
        directVerifyOTPData: state.loginData.directVerifyPhoneOTP,
        isLoading: state.loginData.isLoading,
        resendOTPCustomersData: state.loginData.resendOTPCustomers,
        directReSendPhoneOTPData:  state.loginData.directReSendPhoneOTP,
        otpDetails: state.localStateManagementReducerData.otpDetails,
        recoverOnlineIdData: state.loginData.recoverOnlineID,
        pageData: state.localStateManagementReducerData.pageData,
        personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    }
  );

const mapDispatchToProps = {
    ...loginActions,
    ...localStateManagementActions,
    ...analytics
};


export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
  )(EnterOtpComponent));