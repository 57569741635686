import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';
import * as documentCenterConstants from '../ReduxConstants/DocumentCenterConstants';

export const getDocuments = payload => dispatch => {
    const successCallback = ({ status, data }) => {
        if ((Number(status) === 200 && data) || Number(status) === 204 || (Number(status) === 206 && data)) {
            dispatch({ type: documentCenterConstants.GET_USER_DOCUMENT_LIST_SUCCESS, payload: data.data });
        } else {
            dispatch({ type: documentCenterConstants.GET_USER_DOCUMENT_LIST_ERROR });
        }
    };

    const errorCallback = error => {
        dispatch({ type: documentCenterConstants.GET_USER_DOCUMENT_LIST_ERROR, payload: error });
    };

    try {
        dispatch({ type: documentCenterConstants.GET_USER_DOCUMENT_LIST_LOADER });
        makeApiRequest(documentCenterConstants.GET_USER_DOCUMENT_LIST, payload, '', successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};


export const transferDocuments = payload => dispatch => {
    const successCallback = ({ status, data }) => {
        if ((Number(status) === 200 && data) || Number(status) === 204 || (Number(status) === 206 && data)) {
            dispatch({ type: documentCenterConstants.GET_TRANSFER_DOCUMENT_LIST_SUCCESS, payload: data.data });
        } else {
            dispatch({ type: documentCenterConstants.GET_TRANSFER_DOCUMENT_LIST_ERROR });
        }
    };

    const errorCallback = error => {
        dispatch({ type: documentCenterConstants.GET_TRANSFER_DOCUMENT_LIST_ERROR, payload: error });
    };

    try {
        dispatch({ type: documentCenterConstants.GET_TRANSFER_DOCUMENT_LIST_LOADER });
        makeApiRequest(documentCenterConstants.GET_TRANSFER_DOCUMENT_LIST, payload, '', successCallback, errorCallback);
    } catch (error) {
        errorCallback(error);
    }
};


export const saveData = data => dispatch => {
    dispatch({
        type: ActionTypes.LOCAL_STATE,
        ...data,
    });
};

export const clearReduxData = (stateKey, obj) => dispatch => {
    dispatch({
        type: ActionTypes.CLEAR_REDUX_DATA,
        [stateKey]: obj,
    });
};

export const toBeRemoved = () => () => {};

// Used for  searching Documents for displaying in DashBoard

export const searchDocuments = payload => dispatch => {
    const env = '';
    dispatch({
        type: documentCenterConstants.SEARCH_DOCUMENTS_LOADER,
    });
    makeApiRequest(
        documentCenterConstants.SEARCH_DOCUMENTS,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.code === '200' || response.status === 200) {
                    dispatch({
                        type: documentCenterConstants.SEARCH_DOCUMENTS_SUCCESS,
                        payload: response.data.data,
                    });
                }
            }else{
                dispatch({
                    type: documentCenterConstants.SEARCH_DOCUMENTS_ERROR,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: documentCenterConstants.SEARCH_DOCUMENTS_ERROR,
                payload: error,
            });
        },
    );
};

// Used for  retrieving a single document for displaying in DashBoard

const reader=(data) => {
    const fileReader = new FileReader(data);  
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new Error('Problem parsing file'));
      };
  
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
  
      fileReader.readAsText(data);
    });
  };

export const retrieveSingleDocument = payload =>async dispatch => {
    const env = '';
    const { nodeId, documentSource, productId, downloadUrl, token } = payload;
    const queryString = productId === ''
        ? `?docid=${nodeId}&download=true&source=${documentSource}&inline=true`
        : `?docid=${nodeId}&download=true&source=${documentSource}&productid=${productId}&inline=true`;

        
    dispatch({
        type: documentCenterConstants.GET_SINGLE_DOCUMENT_LOADER,
    });
    makeApiRequest(
        documentCenterConstants.GET_SINGLE_DOCUMENT,
        { queryString, downloadUrl, token },
        env,
        response => {
            if (response.data) {
                if (response.status === 200) {
                    dispatch({
                        type: documentCenterConstants.GET_SINGLE_DOCUMENT_SUCCESS,
                        documentReceived: response.data,
                    });
                } else {
                    dispatch({
                        type: documentCenterConstants.GET_SINGLE_DOCUMENT_ERROR,
                        payload: response.data,
                    });
                }
            }
        },
        async error => {
            let errorMsg='';
            try {
                const { data } = error;
                // Read file
                const file = await reader(data);
                // Parse content and retrieve 'errorMessages'
                const result = JSON.parse(file);    
                errorMsg= result.errorMessages[`${0}`];
              } catch (readError) {
                // Show a dummy error if sth goes wrong while retrieving 'message'
                errorMsg='We are experiencing systems issues. Please try again.';
              }
            dispatch({
                type: documentCenterConstants.GET_SINGLE_DOCUMENT_ERROR,
                payload: errorMsg,
            });
        },
    );
};

export const getDocumentId = payload => dispatch => {
    const env = '';
    dispatch({
        type: documentCenterConstants.GET_DOCUMENTID_LOADER,
    });
    makeApiRequest(
        documentCenterConstants.GET_DOCUMENTID,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.code === '200' || response.status === 200) {
                    dispatch({
                        type: documentCenterConstants.GET_DOCUMENTID_SUCCESS,
                        payload: response.data,
                    });
                }
            }else{
                dispatch({
                    type: documentCenterConstants.GET_DOCUMENTID_ERROR,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: documentCenterConstants.GET_DOCUMENTID_ERROR,
                payload: error,
            });
        },
    );
};


export const getDocumentPdfList = payload => dispatch => {
    const env = '';
    // dispatch({
    //     type: documentCenterConstants.GET_DOCUMENT_PDF_LIST_LOADER,
    // });
    console.log('apicall')
    makeApiRequest(
        documentCenterConstants.GET_DOCUMENT_PDF_LIST,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.code === '200' || response.status === 200) {
                    dispatch({
                        type: documentCenterConstants.GET_DOCUMENT_PDF_LIST_SUCCESS,
                        payload: response.data,
                    });
                }
            }else{
                dispatch({
                    type: documentCenterConstants.GET_DOCUMENT_PDF_LIST_ERROR,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: documentCenterConstants.GET_DOCUMENT_PDF_LIST_ERROR,
                payload: error,
            });
        },
    );
};


export const uploadDocumentConfirmation = payload => dispatch => {
    const env = '';
    // dispatch({
    //     type: documentCenterConstants.GET_DOCUMENT_PDF_LIST_LOADER,
    // });
    makeApiRequest(
        ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.code === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_SUCCESS,
                        payload: response.data,
                    });
                }
            }else{
                dispatch({
                    type: ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_ERROR,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION_ERROR,
                payload: error,
            });
        },
    );
};


export const setFormattedDocList=(data)=>dispatch=>{
    dispatch({
        type: documentCenterConstants.SET_FORMATTED_DASHBOARD_USER_DOCUMENT,
        data
    });
};

export const setSelectedYear = selectedYear => dispatch => {
    dispatch({
        type: documentCenterConstants.SET_DOCUMENT_CENTER_SELECTED_YEAR,
        payload: selectedYear,
    });
};

export const resetTransferDocument = () => dispatch => {
    dispatch({
        type: documentCenterConstants.RESET_TRANSFER_DOCUMENT,
    });
};







