// import {AccountInfo, PersonalInfo, ContactInfo,AddressInfo,EmploymentInfo,FinancialInfo,MilitaryInfo } from "./MarketNAODataHelper01";
// import { BrokerDealer,RiskProfile } from "./MarketNAODataHelper02";
// import { Beneficiaries, TrustedContact, DeliveryPreferences } from "./MarketNAODataHelper03";




//=======Accountinfo=======
const AccountInfo = {
    accountType: "",
    userID: "",
    marketingRefCode: "",
    investmentAdvisorID: ""

}

//========personalinfo=======


const PersonalInfoPerson = {
    prefix: "",
    firstName: "",
    lastName: "",
    suffix: "",
    middleInitial: "",
    dob: "",
    maskedDob: "",
    gender: "",
    citizenship: "",
    countryOfCitizenship: "",
    countryOfCitizenshipCode: "",
    maritalStatus: "",
    ssn: "",
    maskedSSN: "",
    taxIdIssuingCountry: "",
    errorMessage: "",
    formErrors: {},
    withoutMaskedSSN: ""
}


const PersonalInfoPersonForJoint = {
    prefix: "",
    firstName: "",
    lastName: "",
    suffix: "",
    middleInitial: "",
    dob: "",
    maskedDob: "",
    gender: "",
    relationship:"",
    citizenship: "",
    countryOfCitizenship: "",
    countryOfCitizenshipCode: "",
    maritalStatus: "",
    ssn: "",
    maskedSSN: "",
    taxIdIssuingCountry: "",
    errorMessage: "",
    formErrors: {},
    withoutMaskedSSN: ""
}

 const PersonalInfo = {
    primary: PersonalInfoPerson,
    secondary: PersonalInfoPersonForJoint
};
//========ContactInfo======
 const ContactInfoPerson = {
    contactInfoFormErrors: {},
    phoneInfo: {
        primary: {
            number: "",
            countryCode: "+1",
            mPhoneNumber: "",
            isMobile: false
        },
        secondary: {
            number: "",
            countryCode: "+1",
            isMobile: false,
            mPhoneNumber: ""
        }
    },
    emailAddresses: {
        primaryEmailAddress: "",
        maskedPrimaryEmailAddress: "",
        secondaryEmailAddress: "",
        maskedSecondaryEmailAddress: ""
    },
}

 const ContactInfo = {
    primary: ContactInfoPerson,
    secondary: ContactInfoPerson
};

//======AddressInfo==========


const AddressPerson = {
    isPhysicalAddressSame: 'Yes',
    mailingAddress: {
        mailingAddressType: "",
        addressLine1: "",
        addressLine2: "",
        zipCode: "",
        city: "",
        state: "",
        isMailingAddress: true,
        isPhysicalAddress: false
    },
    physicalAddress: {
        addressLine1: "",
        addressLine2: "",
        zipCode: "",
        city: "",
        state: "",
        isMailingAddress: false,
        isPhysicalAddress: true
    },
    formErrors: {}
}


 const AddressInfo = {
    primary: AddressPerson,
    secondary: AddressPerson
}

//=================EmploymentInfo=====


const EmployentInfoPerson = {
    status: "",
    otherEmployment: "",
    industry: "",
    industryDescription: "",
    primarySource: "",
    occupation: "",
    employerName: "",
    employmentClassName: "",
    address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: ""
    },
    formErrors: {}
}
 const EmploymentInfo = {
    primary: EmployentInfoPerson,
    secondary: EmployentInfoPerson
}
//==============FinancialInfo=====


const FinancialInfoPerson = {
    annualIncome: "",
    taxBracket: 0,
    netWorth: "",
    taxFilingStatus: "",
    formErrors: {}
}
 const FinancialInfo = {
    primary: FinancialInfoPerson,
    secondary: FinancialInfoPerson
}

//========MIlitaryInfo=====
const MilitaryInfoPerson = {

    hasServed: 'N',
    militaryStatus: "",
    otherMilitaryStatus: "",
    branch: "",
    otherBranch: "",
    rank: "",
    commissionSource: "",
    startDate: "",
    endDate: ""
}
 const MilitaryInfo = {
    primary: MilitaryInfoPerson,
    secondary: MilitaryInfoPerson
}

//==============================BrokerDealer=====



const IndividualOrJointPerson = {


    vcEmployee:"",
    brokerDealerEmployment: "",
    employeeOfThisBrokerDealer: "",
    isRelatedToBrokerDealer: "",
    relatedToBrokerDealer: {
        employee: "",
        firstname: "",
        lastName: "",
        relationship: ""
    },
    isEmployeeOfAnotherBrokerDealer: "",
    EmployeeOfAnotherBrokerDealer: {
        firstname: "",
        lastName: ""
    },



    isRelatedToEmployeeOfAnotherBrokerDealer: "",
    relatedToEmployeeOfAnotherBrokerDealer: {
        firstname: "",
        lastName: "",
        relationship: "",
        brokerDealerName: ""
    },

    isMemberEmployeeOfStockExchangeFINRA: "",
    memberEmployeeOfStockExchangeFINRA: {
        nameOfAffiliation: "",
    },
    isOtherBrokerageAccounts: "",
    otherBrokerageAccounts: {
        brokerageName: "",
        yearsOfInvestingExperience: "",
    },

    isDirectorShareholderOfPubliclyTradedCompany: "",
    directorShareholderOfPubliclyTradedCompany: {
        companyName: "",
    },

    isAccountOpenedForForeignInstitution: "",
    accountOpenedForForeignInstitution: {
        isFinancialInstitution: "",
        isPrivateBank: "",
        isForeignBank: "",
        foreignBank: {
            isCentralBank: "",
            isBankingLicense: "",
            isTerritory: "",
            isSubjectToSection311: ""
        },
    },
    isPep: "",
    pep: {
        firstName: "",
        lastName: "",
        officeHeld: "",
        countryOfOffice: ""
    },

    formErrors: {}
}


 const BrokerDealer = {
    primary: IndividualOrJointPerson,
    joint: IndividualOrJointPerson,
}

//========================RiskProfile====



const RiskProfilePerson = {
    investmentObjective: "",
    timeHorizon: "",
    riskExposure: "",
    investmentKnowledge: "",
    investmentGoal: "",
    sourceOFFund: "",
    sourceOFFundInfo: "",
    formErrors: {}
}

 const RiskProfile = {
    primary: RiskProfilePerson
}

//=============================Beneficiaries



 const Beneficiaries = {

    savedBeneficiaryData: {
        beneficiaryPrimaryDataArray: [],
        beneficiaryContingentDataArray: [],
        valid: false,
        toFocus: '',
        savedRelationshipDropDownData:[]
    }
}
//====================TrustedContact=====


const Person = {
    prefix: "",
    firstName: "",
    lastName: "",
    suffix: "",
    middleInitial: null,
    dob: "",
    gender: "",
    errorMessage: "",
    relationship: ""
}


 const TrustedContact = {
    trustedContactFormErrors: {},
    provideTrustedContacts: "",
    ...Person,
    // phone: "",
    // phoneCountryCode: "",
    address: {
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "zipCode": ""
    },
    // isMobile:false,
    primary: {
        "number": "",
        "countryCode": "",
        "isMobile": false
    }
}
//=======================DeliveryPreferences

 const DeliveryPreferences = {
    confirms: "true",
    notifications: "true",
    statements: "true",
    proxy: "true",
    prospectus: "true"
}
//==================

//===========Account Funding

const newBankAccount = {
    accountType:"",
    accountNumber:"",
    routingNumber:"",
    isJointAccount:false
}
const AddBankDetails ={
    selectedBankInfo:[],
    addExistingBankSelect:false,
    addNewBankAccountSelect:false,
    newBankAccount: newBankAccount,
    addBankLaterSelect:false,
    allBankList:[]
}

//=======================Account Funding

const AccountFunding =  { 
    allAvailableBankAccounts:[],
    selectedBankInfo:[],
    fundNow: false,
    recurringFundingAccount:'',
    recurringFundAccountList: [],
    fundFromExistingAccount: false,
    transferAssets: false,
    fundAsDirectRollover: false,
    iraContributionYear:''
}

export const MarketNAORoot = () => ({
   account : AccountInfo,
   personalInfo: PersonalInfo,
   contactInfo: ContactInfo,
   addressInfo: AddressInfo,
   employmentInfo:EmploymentInfo,
   financialInfo:FinancialInfo,
   militaryInfo: MilitaryInfo,
   riskProfile:RiskProfile,
   brokerDealer: BrokerDealer,
   beneficiaries:Beneficiaries,
   trustedContact: TrustedContact,
   deliveryPreferences: DeliveryPreferences,
   addBankDetails: AddBankDetails,
   accountFunding: AccountFunding,
   isRedirectionFlow:false

})


export const createMarketNaRoot = () => {
    let naoRoot = MarketNAORoot

    return naoRoot
}


export const PostMarketNAOInfoHelper = (endURL,payload, applicationId) => {
    // const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
    // const dispatch = useDispatch();

    // const pageIndexPair = [{value:1,key:'account'},{value:2,key:'personalInfo'},{value:1,key:'address'},{value:2,key:'contactInfo'},{value:3,key:'employmentInfo'},{value:3,key:'financialInfo'},{value:4,key:'militaryInfo'},{value:5,key:'riskProfile'},{value:6,key:'brokerDealer'},{value:7,key:'trustedContact'},{value:8,key:'deliveryPreferences'},{value:9,key:'beneficiaries'}]


    // let pageIndex = pageIndexPair.find(item => item.key === endURL);
    

    // const payloadObj = {
    //     applicationId:applicationId,
    //     pageNumber: pageIndex.value,
    //     source: "mobile",
    //     [`${endURL}`]:payload
    // }
      

    // dispatch(postMarketNAOInfo({
    //     url: endURL,
    //     payloadData: payloadObj
    // }))



}