import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';

export const getAccountSummary = data => dispatch => {
    const env = '';
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_LOADER,
    });

    makeApiRequest(
        ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.message === 'Success') {
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS,
                        accountSummary: response.data.result,
                    });
                }
                else if(response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_FAILURE,
                        error: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.MEMBER_DASHBOARD_ACCOUNT_SUMMARY_ERROR,
                error,
            });
        },
    );
};

export const getDashboardAccountSummary = data => dispatch => {
    const env = '';
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_LOADER,
    });

    makeApiRequest(
        ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY,
        payload,
        env,
        response => {
            if (response.data) {
                
                if (response.data.message === 'Success') {
                    dispatch({
                        type: ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_SUCCESS,
                        accountSummary: response.data.result,
                    });
                }
                else if(response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_FAILURE,
                        error: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.MEMBER_NEW_DASHBOARD_ACCOUNT_SUMMARY_ERROR,
                error,
            });
        },
    );
};

export const getEnabledFeature = data => dispatch => {
    const env = '';

    // dispatch({
    //     type: ActionTypes.GET_ENABLED_FEATURE_LOADER,
    // });
    makeApiRequest(
        ActionTypes.GET_ENABLED_FEATURE,
        undefined,
        env,
        response => {
           
            if (response.data) {
                if (response.data.message === 'Success') {
                    dispatch({
                        type: ActionTypes.GET_ENABLED_FEATURE_SUCCESS,
                        getEnabledFeature: response.data.result,
                    });
                }
                else if(response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.GET_ENABLED_FEATURE_FAILURE,
                        getEnabledFeature: response.data,
                    });
                }
            }
        },
        error => {
           
            dispatch({
                type: ActionTypes.GET_ENABLED_FEATURE_ERROR,
                getEnabledFeature:error,
            });
        },
    );
};

export const getDashBoardAccordianLinks = data => dispatch => {
    const env = '';
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.MEMBER_DASHBOARD_DROPDOWN_LOADER,
    });
    makeApiRequest(
        ActionTypes.MEMBER_DASHBOARD_DROPDOWN_LINKS,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.message === 'Success') {
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_DROPDOWN_SUCCESS,
                        dashBoardLink: response.data.result,
                    });
                }
                else if(response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_DROPDOWN_FAILURE,
                        dashBoardLink: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.MEMBER_DASHBOARD_DROPDOWN_ERROR,
                dashBoardLink:error,
            });
        },
    );
};



export const getDashboardWidgetSavedItemsList = data => dispatch => {
    const env = '';
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_LOADER,
    });
    makeApiRequest(
        ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.status === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_SUCCESS,
                        savedItemsResult: response.data,
                    });
                }
                else{
                    dispatch({
                        type: ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_FAILURE,
                        savedItemsResult: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.MEMBER_DASHBOARD_GET_SAVED_ITEMS_ERROR,
                savedItemsResult:error,
            });
        },
    );
};



export const getGeneratedSAMLAssertion = data => dispatch => {
    const env = '';
    const payload = {
        ...data,
    };
    dispatch({
        type: ActionTypes.GET_SAML_ASSERTION_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_SAML_ASSERTION,
        payload,
        env,
        response => {
            if (response.data) {
                if (response.data.message === 'Success') {
                    dispatch({
                        type: ActionTypes.GET_SAML_ASSERTION_SUCCESS,
                        samlAssertionLink: response.data.result,
                    });
                }
                else if(response.data.message === 'Error') {
                    dispatch({
                        type: ActionTypes.GET_SAML_ASSERTION_FAILURE,
                        samlAssertionLink: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_SAML_ASSERTION_ERROR,
                samlAssertionLink:error,
            });
        },
    );
};


export const getSystemHealth = data => dispatch => {
    const env = '';

    dispatch({
        type: ActionTypes.GET_SYSTEM_HEALTH_LOADER,
    });
    makeApiRequest(
        ActionTypes.GET_SYSTEM_HEALTH,
        undefined,
        env,
        response => {
            if (response.data) {
                if (response.status === '200' || response.status === 200) {
                    dispatch({
                        type: ActionTypes.GET_SYSTEM_HEALTH_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: ActionTypes.GET_SYSTEM_HEALTH_FAILURE,
                        payload: response.data,
                    });
                }
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_SYSTEM_HEALTH_ERROR,
                payload: error,
            });
        },
    );
};


export const setSamlInitialData = () => dispatch => {
    dispatch({
        type: ActionTypes.SET_SAML_ASSERTION_INITIAL_DATA,
    });
};

export const resetAllMemberDashbaordData = () => dispatch => {
    dispatch({
        type: ActionTypes.SET_MEMBER_DASHBOARD_INITIAL_DATA,
    });
};

export const resetAllNewMemberDashbaordData = () => dispatch => {
    dispatch({
        type: ActionTypes.SET_MEMBER_DASHBOARD_INITIAL_DATA,
    });
};
