import { analyzeClickEvent } from '../../shared/Actions/AnalyticsActions';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from '../CommonStyles';
import { useDispatch } from 'react-redux';
import WIcon from '../WIcon/WIcon';

const StyledParagraph = styled.span`
    /* Changing P to Span to remove Validator error as P should not come inside Button: Abhinav */
    margin: 0;
    padding: 0;
    display: inline-block;
    font: 800 16px/20px yorkten-slab-normal;
`;

const StyledPrimaryButton = styled(Button)`
    &.btn-light {
        background-color: #486d90;
    }
    &.btn-light:hover {
        background-color: #3c5a77;
    }
    &.btn-light:focus {
       
        background-color: #3c5a77;
    }
    &.btn-light:not(:disabled):not(.disabled):active:focus {
        background-color: #3c5a77;
    }
    &.btn-light .mainButtonCorner {
        border-bottom: 19px solid transparent;
        border-right: 19px solid #8cbf26;
    }
    &.btn-light:hover .mainButtonCorner {
        border-bottom: 19px solid transparent;
        border-right: 19px solid #8bc105;
    }
    &.btn:disabled {
        background-color: #56565a;
        opacity: 1;
        cursor: default;
    }
    &.btn:disabled .mainButtonCorner {
        border-right-color: transparent;
    }
`;
const StyledRelationButton = styled(Button)`
    border-radius: 4px;
    border-color: #707070;
    background-color: #ffffff;
    color: rgba(0, 74, 152, 1);
    text-align: center;
    font-size: 16px;
    font-family: benton-sans;
    font-weight: 600;
    height: 50px;
    width: auto;
    :hover {
        border: 4px solid rgba(178, 255, 0);
        margin: 1px;
    }
    :focus {
        border: 4px solid rgba(178, 255, 0);
        margin: 1px;
    }
    margin: 4px;
`;

const StyledOutlineButton = styled(Button)`
    border-color: #707070;
    background-color: #ffffff;
    color: rgba(0, 74, 152, 1);
    text-align: center;
    font-size: 16px;
    font-family: benton-sans;
    font-weight: 600;
    height: 50px;
    width: 100%;
    :hover {
        color: rgba(0, 74, 152, 1);
    }
    @media (min-width: 768px) {
        width: 100%;
    }
`;
const assignObj = obj => {
    return obj;
};

const opensDialogForSR = `Opens a dialog`;

const WButton = React.forwardRef((props, ref) => {
    const {
        variant,
        active,
        disabled,
        size,
        type,
        block,
        buttonstyle,
        buttontext,
        buttontext2,
        buttontextstyle,
        buttontext2style,
        onClick,
        id,
        nocorner,
        waitforapiresponse,
        analyzecustom,
        dataAnalyticId,
        arialabelForBtn,
        icon,
    } = props;

    const dispatch = useDispatch();

    function analyzeClick(e) {
        onClick(e);
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
                waitforapiresponse,
                analyzecustom,
            }),
        );
    }

    

    if (variant.startsWith('relation')) {
        return (
            <StyledRelationButton
                ref={ref}
                {...props}
                onClick={analyzeClick}
                active={active}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
                block={block}
                style={assignObj({ ...buttonstyle })}
                nocorner={nocorner}
                data-analytic-id={dataAnalyticId}
            >
                {icon ? <WIcon src={icon} style={{ display: ' inline-block', marginRight: '5px' }} alt="" /> : ''}
                <StyledParagraph style={buttontextstyle}>{buttontext}</StyledParagraph>
                <StyledParagraph style={buttontext2style}>{buttontext2}</StyledParagraph>
            </StyledRelationButton>
        );
    }

    if (variant.startsWith('outline')) {
        return (
            <StyledOutlineButton
                ref={ref}
                {...props}
                onClick={analyzeClick}
                active={active}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
                block={block}
                style={assignObj({ ...buttonstyle })}
                nocorner={nocorner}
                data-analytic-id={dataAnalyticId}
                aria-label={arialabelForBtn}
            >
                <StyledParagraph style={buttontextstyle} aria-label={arialabelForBtn}>
                    {buttontext}
                    {buttontext === 'Find Funds' && <span class="sr-only">{opensDialogForSR}</span>}
                </StyledParagraph>
            </StyledOutlineButton>
        );
    }

    if (variant.startsWith('back')) {
        return (
            <Button
                ref={ref}
                {...props}
                onClick={analyzeClick}
                className="backButton"
                active={active}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
                block={block}
                style={assignObj({ ...styles.backButtonStyle, ...buttonstyle })}
                nocorner={nocorner}
                data-analytic-id={dataAnalyticId}
                aria-label={arialabelForBtn}
            >
                {/* <div style={styles.arrow}>
                <div style={styles.arrowAfter} />
                <div style={styles.arrowBefore} />
            </div> */}
                {/* <img src={leftArrow} alt="left-arrow" style={styles.leftArrow}/> */}
                <StyledParagraph style={assignObj({ ...styles.backButtonTextStyle, ...buttontextstyle })}>
                    {buttontext}
                </StyledParagraph>
            </Button>
        );
    }

    if (variant.startsWith('cancel')) {
        return (
            <Button
                ref={ref}
                {...props}
                onClick={analyzeClick}
                className="cancelButton"
                active={active}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
                block={block}
                style={assignObj({ ...styles.cancelButtonStyle, ...buttonstyle })}
                nocorner={nocorner}
                data-analytic-id={dataAnalyticId}
                aria-label={arialabelForBtn}
            >
                {/* <div style={styles.arrow}>
                <div style={styles.arrowAfter} />
                <div style={styles.arrowBefore} />
            </div> */}
                {/* <img src={leftArrow} alt="left-arrow" style={styles.leftArrow}/> */}
                <StyledParagraph style={assignObj({ ...styles.backButtonTextStyle, ...buttontextstyle })}>
                    {buttontext}
                </StyledParagraph>
            </Button>
        );
    }

    if (variant.startsWith('loadMore')) {
        return <Button
            ref={ref}
            {...props}
            onClick={analyzeClick}
            className='loadMore'
            active={active}
            disabled={disabled}
            size={size}
            variant={variant}
            type={type}
            block={block}
            style={assignObj({ ...styles.cancelButtonStyle, ...buttonstyle })}
            nocorner={nocorner}
            data-analytic-id={dataAnalyticId}
        >
            {/* <div style={styles.arrow}>
                <div style={styles.arrowAfter} />
                <div style={styles.arrowBefore} />
            </div> */}
            {/* <img src={leftArrow} alt="left-arrow" style={styles.leftArrow}/> */}
            <StyledParagraph style={assignObj({...styles.backButtonTextStyle, ...buttontextstyle})}>{buttontext}</StyledParagraph>
        </Button>
    }


    if (variant.startsWith('save')) {
        return (
            <Button
                ref={ref}
                {...props}
                onClick={analyzeClick}
                className="saveButton"
                active={active}
                disabled={disabled}
                size={size}
                variant={variant}
                type={type}
                block={block}
                style={assignObj({ ...styles.saveButtonStyle, ...buttonstyle })}
                nocorner={nocorner}
                data-analytic-id={dataAnalyticId}
                aria-label={arialabelForBtn}
            >
                <StyledParagraph style={assignObj({ ...styles.backButtonTextStyle, ...buttontextstyle })}>
                    {buttontext}
                </StyledParagraph>
            </Button>
        );
    }

    return (
        <StyledPrimaryButton
            ref={ref}
            {...props}
            onClick={analyzeClick}
            active={active}
            // disabled={disabled}
            size={size}
            aria-disabled={disabled}
            variant={variant}
            type={type}
            block={block}
            nocorner={nocorner}
            style={
                disabled
                    ? assignObj({ ...styles.disabledButtonStyle, ...buttonstyle })
                    : assignObj({ ...styles.buttonStyle, ...buttonstyle })
            }
            data-analytic-id={dataAnalyticId}
            aria-label={arialabelForBtn}
            className="primaryBtnForMob"
        >
            <StyledParagraph style={buttontextstyle}>{buttontext}</StyledParagraph>
            {disabled || nocorner ? null : <div style={styles.corner} className="mainButtonCorner" />}
        </StyledPrimaryButton>
    );
});

WButton.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg']),
    type: PropTypes.oneOf(['button', 'submit', 'reset', 'null']),
    variant: PropTypes.string,
    buttontext: PropTypes.string,
    buttonstyle: PropTypes.instanceOf(Object),
    block: PropTypes.bool,
    buttontextstyle: PropTypes.instanceOf(Object),
    onClick: PropTypes.func,
    id: PropTypes.string,
    nocorner: PropTypes.bool,
    analyzecustom: PropTypes.instanceOf(Object),
    waitforapiresponse: PropTypes.bool,
    dataAnalyticId: PropTypes.string,
    arialabelForBtn: PropTypes.string,
};

WButton.defaultProps = {
    active: false,
    disabled: false,
    size: 'sm',
    type: 'button',
    variant: 'light',
    buttontext: 'Button',
    buttontext2: '',
    block: false,
    buttonstyle: {},
    buttontextstyle: {},
    buttontext2style: {},
    onClick: () => {},
    id: 'button',
    nocorner: false,
    analyzecustom: null,
    waitforapiresponse: false,
    dataAnalyticId: '',
    arialabelForBtn: '',
};

export default WButton;
