import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WButton from '../../../../../../common/WButton/WButton';
import RmdCard from '../RMDCard';

const ModalStyle = styled.div`
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const ModalDialog = styled.div`
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    width: 90%;
    padding: 40px 120px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #d2d2d2;
    opacity: 1;
`;
const ConformationModalTitle = styled.div`
    text-align: left;
    font: 700 18px/22px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    padding-bottom: 7px;
`;

const ConformationModalBody = styled.div`
    text-align: left;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 20px;
`;

const ColumnWrapper = styled.div`
    display: flex;
`;
const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    margin: 10px 10px 20px 0px;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const closeBtnStyles = {
    closeButton: {
        backgroundColor: 'transparent',
        display: 'inline-block',
        width: '50px',
        height: '50px',
        border: '1.5px solid #49494A',
        borderRadius: '50%',
        position: 'absolute',
        right: 15,
        top: 10
    },
    crossAfter: {
        width: '16px',
        transform: 'rotate(45deg)',
        border: '1.5px solid #49494A',
        position: 'absolute',
        left: '15px',
        top: '22px',
        borderRadius: '3px',
        backgroundColor: '#49494A'
    },
    crossBefore: {
        width: '16px',
        height: '0',
        transform: 'rotate(-45deg)',
        border: '1.5px solid #49494A',
        position: 'absolute',
        left: '15px',
        top: '22px',
        borderRadius: '2px',
        backgroundColor: '#49494A'
    },
}

const DeleteRmdModal = (props) => {
    const { modalTitle, modalBodyText, modalBodyText2, primaryButtonText, onPrimaryClick, item, closeModal } = props;
    return (
            <ModalStyle tabIndex={0}>
                <ModalDialog role="dialog" aria-modal="true" aria-labelledby="VCM-Modal-title">
                    <ConformationModalTitle id="VCM-Modal-title"> {modalTitle} </ConformationModalTitle>
                    <ColumnWrapper>
                        <FlexColumnDiv>
                            <ConformationModalBody> {modalBodyText} </ConformationModalBody>
                            {modalBodyText2 ? <ConformationModalBody> {modalBodyText2} </ConformationModalBody> : null}
                            <FlexDiv>
                                <WButton
                                    buttontext={primaryButtonText || 'Ok'}
                                    size="sm"
                                    onClick={onPrimaryClick}
                                />
                            </FlexDiv>
                        </FlexColumnDiv>
                        <FlexRowDiv>
                            {item && <RmdCard rmdItem={item} />}
                        </FlexRowDiv>
                    </ColumnWrapper>
                    <button
                        type="button"
                        aria-label="Close status message"
                        id="statusMsgClose"
                        onClick={(e) => closeModal()}
                        style={closeBtnStyles.closeButton}
                    >
                        <div>
                            <div style={closeBtnStyles.crossAfter} />
                            <div style={closeBtnStyles.crossBefore} />
                        </div>
                    </button>

                </ModalDialog>
            </ModalStyle>
    )
}

DeleteRmdModal.propTypes = {
    modalTitle: PropTypes.string,
    modalBodyText: PropTypes.string,
    modalBodyText2: PropTypes.string,
    primaryButtonText: PropTypes.string,
    onPrimaryClick: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.shape({})
};

DeleteRmdModal.defaultProps = {
    modalTitle: 'TITLE',
    modalBodyText: 'BODY TEXT',
    modalBodyText2: null,
    closeModal: () => { },
    onPrimaryClick: () => { },
    primaryButtonText: '',
    item: {}
};

export default DeleteRmdModal;
