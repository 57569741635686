import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { WBreadCrumb, WSpinner, VCMSelect } from 'common';
import { getFormattedSSN, formatDateToTwoDigit, isValidDate } from 'utils';
import routeConstants from 'routeConstants';
import {
    BottomNavBar,
    PageTitle,
    FieldValue,
    InstructionalPanel,
    PrefilledInput,
    RadioGroup,
    LinkHighlight,
    OffScreenText,
} from 'modules/CustomerManagementModule/components';
import {
    getKeyfromValue,
    getCountryMetaData,
    getMetadataValues,
    getFullName,
    getMemberId,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';
import CONST from '../constants';

const primaryBtnProps = {};
const cancelBtnProps = {};

export default class PrimaryInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiReqBody: {},
        };
    }

    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        const payLoad = this.getLookUpPayload();
        if (payLoad.length) {
            getCompositeLookUpData(payLoad);
        }
        addFormAbandonUnloadListner(true);
    }   

    componentDidUpdate() {
        addFormFieldsListner();
        const { profileInformationData, clearExistingActions, history, location, analyzeClickEvent, linkResponseError  } = this.props;
        const { isError, isSuccess, message } = profileInformationData;
        const { pathname } = location;
        if (isSuccess) {
            saveBtnLink('cms_basicinformation_managepersonalinfo_save', analyzeClickEvent);
            history.push(routeConstants.basicinformation, { from: pathname });
        }
        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink('cms_basicinformation_managepersonalinfo_save', analyzeClickEvent);
            linkResponseError(errorTxt);
            clearExistingActions();
        }
    }

    componentWillUnmount() {
        const { profileInformationData } = this.props;
        const { isSuccess } = profileInformationData;
        if(!isSuccess){
            const digitalData = getFormAbandData(true);
            const { fieldlasttouch } = digitalData;
            if (fieldlasttouch !== 'none') trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
    }

    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            marital_status: maritalStatus,
            prefix,
            suffix,
            relationship,
            gender,
            citizenship,
            country_2_lettercode: country,
        } = masterLookupStateData;
        const payLoad = [];
        if (!maritalStatus) payLoad.push('marital_status');
        if (!prefix) payLoad.push('prefix');
        if (!suffix) payLoad.push('suffix');
        if (!relationship) payLoad.push('relationship');
        if (!gender) payLoad.push('gender');
        if (!citizenship) payLoad.push('citizenship');
        if (!country) payLoad.push('country_2_lettercode');
        return payLoad;
    };

    handleChange = fieldName => e => {
        const { apiReqBody } = this.state;
        apiReqBody[fieldName.toString()] = e.list.find(item => item.key === e.target.value).value;
        this.setState({
            apiReqBody,
        });
    };

    handleRadioChange = fieldName => e => {
        const { apiReqBody } = this.state;
        apiReqBody[fieldName.toString()] = e.target.value;
        this.setState({
            apiReqBody,
        });
    };

    submitPrimaryInfo = () => {
        const { updatePersonalInformation } = this.props;
        const { apiReqBody } = this.state;
        updatePersonalInformation(apiReqBody);
    };

    getMasterDataValues = masterLookupStateData => {
        const genderData = getMetadataValues(masterLookupStateData, 'gender');
        const mStatusData = getMetadataValues(masterLookupStateData, 'marital_status');
        const prefixData = getMetadataValues(masterLookupStateData, 'prefix');
        const suffixData = getMetadataValues(masterLookupStateData, 'suffix');
        const citizenshipData = getMetadataValues(masterLookupStateData, 'citizenship');
        const countryCodeData = getMetadataValues(masterLookupStateData, 'country_2_lettercode');
        return { genderData, mStatusData, prefixData, suffixData, citizenshipData, countryCodeData };
    };

    cancelPrimaryInfo = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managepersonalinfo_cancel`
        })
        // Needed when implementing cancel dialog
        // const { showCancelConfirmation } = this.props;
        // showCancelConfirmation(this.isAnythingChanged(), routeConstants.basicinformation);
        history.push(routeConstants.basicinformation);
    };

    isAnythingChanged = () => {
        const { profileInformationData } = this.props;
        const { profileInformation } = profileInformationData;
        const { gender = '', maritalStatus = '', prefix = '', suffix = '', countryOfCitizenship = '' } =
            profileInformation || {};
        const { apiReqBody = {} } = this.state;
        const {
            gender: genderChanged = '',
            maritalStatus: maritalStatusChanged = '',
            prefix: prefixChanged = '',
            suffix: suffixChanged = '',
            countryOfCitizenship: countryOfCitizenshipChanged = '',
        } = apiReqBody || {};

        const isGenderChanged = Object.prototype.hasOwnProperty.call(apiReqBody, 'gender') && gender !== genderChanged;
        const isMaritalStatusChanged =
            Object.prototype.hasOwnProperty.call(apiReqBody, 'maritalStatus') && maritalStatus !== maritalStatusChanged;
        const isPrefixChanged = Object.prototype.hasOwnProperty.call(apiReqBody, 'prefix') && prefix !== prefixChanged;
        const isSuffixChanged = Object.prototype.hasOwnProperty.call(apiReqBody, 'suffix') && suffix !== suffixChanged;
        const isCountryOfCitizenshipChanged =
            Object.prototype.hasOwnProperty.call(apiReqBody, 'countryOfCitizenship') &&
            countryOfCitizenship !== countryOfCitizenshipChanged;

        return (
            isGenderChanged ||
            isMaritalStatusChanged ||
            isPrefixChanged ||
            isSuffixChanged ||
            isCountryOfCitizenshipChanged
        );
    };

    telNumber = () => {
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managepersonalinfo_telephoneNumber`
        })
    }

    render() {
        const { apiReqBody } = this.state;
        const { masterLookupStateData, profileInformationData } = this.props;
        const {
            genderData,
            mStatusData,
            prefixData,
            suffixData,
            citizenshipData,
            countryCodeData,
        } = this.getMasterDataValues(masterLookupStateData);

        const { isLoading, profileInformation } = profileInformationData;
        const {
            firstName = '',
            middleInitial = '',
            lastName = '',
            citizenship = '',
            gender = '',
            maritalStatus = '',
            memberId = '',
            prefix = '',
            ssnTin = '',
            suffix = '',
            vcmId = '',
            dateOfBirth = '',
            countryOfCitizenship = '',
        } = profileInformation || {};

        const fullName = getFullName(firstName, middleInitial, lastName);
        const memberIdVal = getMemberId(memberId, vcmId);

        const tel = `tel:${CONST.MSR_NUMBER}`;

        const {
            gender: genderAPIValue = '',
            citizenship: citizenshipAPIValue = '',
            countryOfCitizenship: countryOfCitizenshipAPIValue = '',
            maritalStatus: maritalStatusAPIVal,
        } = apiReqBody;

        const genderValue = genderAPIValue || gender;
        const citizenshipValue = citizenshipAPIValue || citizenship;
        const maritalStatusValue = maritalStatusAPIVal || maritalStatus;

        const formattedSSN = getFormattedSSN(ssnTin, true);
        const formattedDate = isValidDate(dateOfBirth) ? formatDateToTwoDigit(new Date(dateOfBirth)) : '-';

        const countryError = citizenshipValue === 'Non U.S' && !countryOfCitizenship && !countryOfCitizenshipAPIValue;
        const maritalStatusError = !maritalStatusValue;
        const genderError = !genderValue;

        primaryBtnProps.onClick = this.submitPrimaryInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelPrimaryInfo;

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                <Container>
                    <WBreadCrumb breadCrumbItems={CONST.BREADCRUMB_VALUE} activeCrumb={CONST.PAGE_NAME} />
                    <PageTitle title={CONST.PAGE_NAME} showMandatoryTxt />
                    <FieldValue
                        as="p"
                        label={CONST.NAME}
                        value={<PrefilledInput id="vcm-cms-fullname" value={fullName} />}
                        labelsm={3}
                        valuesm={5}
                        mb={1.5625}
                    />
                    <InstructionalPanel mb={1.5625}>
                        {CONST.NAME_NOTE_TEXT1}
                        <LinkHighlight href={tel} onClick={this.telNumber}>
                            <OffScreenText hideTxt={CONST.PHONE_HINT} />
                            {CONST.MSR_NUMBER}
                        </LinkHighlight>
                    </InstructionalPanel>
                    <VCMSelect
                        id="vcm-cms-prefix"
                        label={CONST.PREFIX}
                        name={CONST.PREFIX}
                        itemlist={prefixData}
                        onChange={this.handleChange('prefix')}
                        value={getKeyfromValue(masterLookupStateData, 'prefix', prefix)}
                        labelsm={3}
                        valuesm={5}
                        optional
                        required
                    />
                    <VCMSelect
                        id="vcm-cms-suffix"
                        label={CONST.SUFFIX}
                        name={CONST.SUFFIX}
                        itemlist={suffixData}
                        onChange={this.handleChange('suffix')}
                        value={getKeyfromValue(masterLookupStateData, 'suffix', suffix)}
                        labelsm={3}
                        valuesm={5}
                        optional
                        required
                    />
                    <FieldValue
                        as="p"
                        label={CONST.MEMBER_ID}
                        value={<PrefilledInput id="vcm-cms-member-id" value={memberIdVal} />}
                        labelsm={3}
                        valuesm={5}
                        mb={1.5625}
                    />
                    <FieldValue
                        as="p"
                        label={CONST.SOCIAL_SECURITY_NUMBER}
                        value={<PrefilledInput id="vcm-cms-ssn" value={formattedSSN} />}
                        labelsm={3}
                        valuesm={5}
                        mb={1.5625}
                    />
                    <FieldValue
                        as="p"
                        label={CONST.DOB}
                        value={<PrefilledInput id="vcm-cms-dob" value={formattedDate} />}
                        labelsm={3}
                        valuesm={5}
                        mb={1.5625}
                    />
                    <FieldValue
                        as="p"
                        id="vcm-cms-gender"
                        label={CONST.GENDER}
                        value={
                            <RadioGroup
                                labelledBy="vcm-cms-gender"
                                error={genderError}
                                errorTxt={CONST.GENDER_ERROR}
                                options={genderData}
                                initialValue={gender || ''}
                                onChange={this.handleRadioChange('gender')}
                                data-name={CONST.GENDER}
                                aria-required
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb={1.5625}
                    />
                    <VCMSelect
                        id="vcm-cms-marital-tatus"
                        label={CONST.MARITAL_STATUS}
                        name={CONST.MARITAL_STATUS}
                        itemlist={mStatusData}
                        onChange={this.handleChange('maritalStatus')}
                        value={getKeyfromValue(masterLookupStateData, 'marital_status', maritalStatus)}
                        labelsm={3}
                        valuesm={5}
                        errortext={maritalStatusError ? CONST.MARITAL_STATUS_ERROR : ''}
                        required
                    />
                    <FieldValue
                        as="p"
                        id="vcm-cms-citizenship"
                        label={CONST.CITIZENSHIP}
                        value={
                            <RadioGroup
                                labelledBy="vcm-cms-citizenship"
                                errorTxt={CONST.GENDER_ERROR}
                                options={citizenshipData}
                                initialValue={citizenship || ''}
                                disabled
                                data-name={CONST.CITIZENSHIP}
                                aria-required
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb={citizenshipValue === 'Non U.S' ? '' : 2.97}
                    />
                    {citizenshipValue === 'Non U.S' && (
                        <VCMSelect
                            id="vcm-cms-country-of-citizenship"
                            label={CONST.COUNTRY}
                            name={CONST.COUNTRY}
                            itemlist={getCountryMetaData(countryCodeData)}
                            onChange={this.handleChange('countryOfCitizenship')}
                            value={getKeyfromValue(masterLookupStateData, 'country_2_lettercode', countryOfCitizenship)}
                            labelsm={3}
                            valuesm={5}
                            errortext={countryError ? CONST.COUNTRY_ERROR : ''}
                            required
                        />
                    )}
                </Container>
                <BottomNavBar
                    hideBack
                    showCancelBtn
                    primaryBtnText={CONST.SAVE}
                    primaryBtnProps={primaryBtnProps}
                    cancelBtnProps={cancelBtnProps}
                />
            </>
        );
    }
}

PrimaryInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    getCompositeLookUpData: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    updatePersonalInformation: PropTypes.func,
    clearExistingActions: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    linkResponseError: PropTypes.func,
    // showCancelConfirmation: PropTypes.func,  ---> Needed when implementing cancel dialog
};

PrimaryInfoComponent.defaultProps = {
    masterLookupStateData: {},
    profileInformationData: {},
    getCompositeLookUpData: () => {},
    updatePersonalInformation: () => {},
    clearExistingActions: () => {},
    analyzeClickEvent: () => {},
    linkResponseError: () => {},
    // showCancelConfirmation: () => {},  ---> Needed when implementing cancel dialog
};
