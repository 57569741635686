import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    deviceSelected : true,       
};

function accMessagingDeviceReducer (state = initialState, action){
    // debugger
      switch(action.type){
          case ActionTypes.LOCAL_STATE: {
              return {
                  ...state,
                  ...action,
              };
          }        
          default:
              return state;
      }
  } 

export default accMessagingDeviceReducer;