import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import routeConstants from "routeConstants";

const cmsRedirectTo = {
    pathname: routeConstants.basicinformation,
};

const getCmp = (props, render, Component) => {
    return render ? render(props) : <Component {...props} />;
};

export const getRenderFn = (hasProfileInfo, render, Component) => (props) => {
    return hasProfileInfo ? (
        getCmp(props, render, Component)
    ) : (
        <Redirect to={cmsRedirectTo} />
    );
};

const CMSRoute = ({ component: Component, render, ...rest }) => {
    const hasProfileInfo = useSelector(
        (state) => state.profileInformationData.hasProfileInfo
    );

    return (
        <Route
            {...rest}
            render={getRenderFn(hasProfileInfo, render, Component)}
        />
    );
};

CMSRoute.propTypes = {
    component: PropTypes.instanceOf(Object),
    render: PropTypes.instanceOf(Object),
};

CMSRoute.defaultProps = {
    component: null,
    render: null,
};

export default CMSRoute;
