import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import Config from 'env/Config';
import { post, setAuthHeaders, get , setMSRAuthHeaders, setMSRAPIKey, setSessionToPlaylod, postCompositeApi, addAPIVersionHeader} from './Services';

const AccountSettingsApiEvents = () => {

    const getCompositData = (data) => {        
        const apiKey = Config.metaDataApiKey;
        setMSRAPIKey(apiKey);
        const { payload } = data;
        const { datakey } = payload || {};
        const newEndPoint = datakey ? `${Config.getCompositeData}/${datakey}` : Config.getCompositeData;
        post(
            newEndPoint,
            {},
            data.endPointURL.LOOKUP,
            data.successCallBack,
            data.errorCallback,            
        );
    };

    ApiEventHub.on(ActionTypes.GET_BANK_ACCOUNT_DETAILS, data => {
        post(
            Config.getbankaccounts,
            data.payload,
            data.endPointURL.DASH_BOARD,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.ADD_BANK_ACCOUNT_DETAILS, data => {
        post(
            Config.addbankaccount,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DELETE_BANK_ACCOUNT_DETAILS, data => {
        post(
            Config.deletebankaccount,
            data.payload,
            data.endPointURL.deletebankaccount,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_GET_ACCOUNT_RECOVER_PREFERENCE, data => {
        post(
            Config.getAccountRecoverPreference,
            data.payload,
            data.endPointURL.USER_MANAGEMENT,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_SET_ACCOUNT_RECOVER_PREFERENCE, data => {
        post(
            Config.setAccountRecoverPreference,
            data.payload,
            data.endPointURL.USER_MANAGEMENT,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_ACCOUNTS_LIST, data => {
        const xApikey = Config.msrDividendKey;
        const {accesstoken, vcmid, msrid, fismemberid = "" } = data.payload;
        setMSRAuthHeaders(accesstoken, vcmid, msrid, xApikey, fismemberid) 
        addAPIVersionHeader('2023-10-06')       
        get(
            Config.getMsrDividendInfo,
            '',
            data.endPointURL.msrDividendInfo,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.UPDATE_DIVIDENDS, data => {
        post(
            Config.updateDividends,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_RANK, data => {
        post(
            Config.getmasterdata,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_ACCT_TYPE_COMPOSITE_DATA, data => {
        post(
            Config.getmasterdata,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_PREFIX, data => {
        post(
            Config.getmasterdata,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_ACCOUNT_SUBTYPES, data => {
        let payload= {
            msrid:"",
            vcmid :"",
            fismemberid:"",
            token :""
        }
        setSessionToPlaylod(payload)
        postCompositeApi(
            `${Config.getCompositeData}/${data.payload.datakey}`,
            '',
            data.endPointURL.getCompositeData,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_ACCOUNT_TYPES, data => {
        post(
            Config.getmasterdata,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_PERSONAL_COMPOSITE_DATA, data => {
        post(
            Config.getmasterdata,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_COMPOSITE_DATA, data => {
        let payload= {
            msrid:"",
            vcmid :"",
            fismemberid:"",
            token :""
        }
        setSessionToPlaylod(payload)
        getCompositData(data);
    });
    ApiEventHub.on(ActionTypes.SAVE_BENEFICIARY, data => {
        post(
            Config.addBeneficiaries,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default AccountSettingsApiEvents;
