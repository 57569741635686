  
export const SET_RELATIONSHIP_FIRST_NAME = 'SET_RELATIONSHIP_FIRST_NAME';
export const SET_RELATIONSHIP_TO_FAMILY_MEMBER = 'SET_RELATIONSHIP_TO_FAMILY_MEMBER';
export const SET_RELATIONSHIP_LAST_NAME = 'SET_RELATIONSHIP_LAST_NAME';
export const SET_RELATIONSHIP_MIDDLE_NAME = 'SET_RELATIONSHIP_MIDDLE_NAME';
export const SET_RELATIONSHIP_PREFIX = 'SET_RELATIONSHIP_PREFIX';
export const SET_RELATIONSHIP_SUFIX = 'SET_RELATIONSHIP_SUFIX';
export const SET_RELATIONSHIP_DATEOFBIRTH = 'SET_RELATIONSHIP_DATEOFBIRTH';
export const SET_RELATIONSHIP_GENDER = 'SET_RELATIONSHIP_GENDER';
export const SET_RELATIONSHIP_MARITAL_STATUS = 'SET_RELATIONSHIP_MARITAL_STATUS';
export const SET_RELATIONSHIP_US_CITIZEN = 'SET_RELATIONSHIP_US_CITIZEN';
export const SET_RELATIONSHIP_SSN = 'SET_RELATIONSHIP_SSN';

export const SET_RELATIONSHIP_COUNTRY_CITIZENSHIP = 'SET_RELATIONSHIP_COUNTRY_CITIZENSHIP';

export const SET_RELATIONSHIP_TO_FAMILY_MEMBER_ERROR = 'SET_RELATIONSHIP_TO_FAMILY_MEMBER_ERROR';
export const SET_RELATIONSHIP_FIRST_NAME_ERROR = 'SET_RELATIONSHIP_FIRST_NAME_ERROR';
export const SET_RELATIONSHIP_LAST_NAME_ERROR = 'SET_RELATIONSHIP_LAST_NAME_ERROR';
export const SET_RELATIONSHIP_DATEOFBIRTH_ERROR = 'SET_RELATIONSHIP_DATEOFBIRTH_ERROR';
export const SET_RELATIONSHIP_GENDER_ERROR = 'SET_RELATIONSHIP_GENDER_ERROR';
export const SET_RELATIONSHIP_MARITAL_STATUS_ERROR = 'SET_RELATIONSHIP_MARITAL_STATUS_ERROR';

export const SET_RELATIONSHIP_CITIZEN_ERROR = 'SET_RELATIONSHIP_CITIZEN_ERROR';
export const SET_RELATIONSHIP_COUNTRY_CITIZENSHIP_ERROR = 'SET_RELATIONSHIP_COUNTRY_CITIZENSHIP_ERROR';
export const SET_RELATIONSHIP_SSN_ERROR = 'SET_RELATIONSHIP_SSN_ERROR';

export const SET_RELATIONSHIP_PHONE_COUNTRY = 'SET_RELATIONSHIP_PHONE_COUNTRY';
export const SET_RELATIONSHIP_PHONE_NUMBER = 'SET_RELATIONSHIP_PHONE_NUMBER';
export const SET_RELATIONSHIP_PHONE_ISMOBILE = 'SET_RELATIONSHIP_PHONE_ISMOBILE';
export const SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY = 'SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY';
export const SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER = 'SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER';
export const SET_RELATIONSHIP_SECONDARY_PHONE_ISMOBILE = 'SET_RELATIONSHIP_SECONDARY_PHONE_ISMOBILE';

export const SET_RELATIONSHIP_PHONE_COUNTRY_ERROR = 'SET_RELATIONSHIP_PHONE_COUNTRY_ERROR';
export const SET_RELATIONSHIP_PHONE_NUMBER_ERROR = 'SET_RELATIONSHIP_PHONE_NUMBER_ERROR';
export const SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY_ERROR = 'SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY_ERROR';
export const SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER_ERROR = 'SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER_ERROR';

export const SET_RELATIONSHIP_PRIMARY_EMAIL = 'SET_RELATIONSHIP_PRIMARY_EMAIL';
export const SET_RELATIONSHIP_SECONDARY_EMAIL = 'SET_RELATIONSHIP_SECONDARY_EMAIL';
export const SET_RELATIONSHIP_PRIMARY_EMAIL_ERROR = 'SET_RELATIONSHIP_PRIMARY_EMAIL_ERROR';
export const SET_RELATIONSHIP_SECONDARY_EMAILERROR = 'SET_RELATIONSHIP_SECONDARY_EMAILERROR';

export const PROFILE_RELATIONSHIP_INFORMATION_ERROR = 'PROFILE_RELATIONSHIP_INFORMATION_ERROR';

export const GET_PROFILE_RELATIONSHIP_INFORMATION = 'GET_PROFILE_RELATIONSHIP_INFORMATION';
export const GET_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS = 'GET_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS';

export const UPDATE_PROFILE_RELATIONSHIP_INFORMATION = 'UPDATE_PROFILE_RELATIONSHIP_INFORMATION';
export const UPDATE_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS = 'UPDATE_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS';

export const SET_FAMILY_MEMBER_INDEX = 'SET_FAMILY_MEMBER_INDEX';
export const SET_RELATIONSHIP_ADDRESS_TYPE = 'SET_RELATIONSHIP_ADDRESS_TYPE';
export const SET_IS_SAME_AS_MAILING_ADDRESS = 'SET_IS_SAME_AS_MAILING_ADDRESS';
export const SET_PHONE_EDIT_DETAILS = 'SET_PHONE_EDIT_DETAILS';

export const RELATION_REMOVE_EXISTING_VALUES = 'RELATION_REMOVE_EXISTING_VALUES';
export const RELATION_SET_INITIAL_STATE = 'RELATION_SET_INITIAL_STATE';
export const NEW_RELATION_REMOVE_EXISTING_VALUES = 'NEW_RELATION_REMOVE_EXISTING_VALUES';

export const PROFILE_RELATIONSHIP_SAVE_PERSONAL_INFORMATION = 'PROFILE_RELATIONSHIP_SAVE_PERSONAL_INFORMATION';
export const PROFILE_RELATIONSHIP_SAVE_PHONE_INFORMATION = 'PROFILE_RELATIONSHIP_SAVE_PHONE_INFORMATION';
export const PROFILE_RELATIONSHIP_SAVE_EMAIL_INFORMATION = 'PROFILE_RELATIONSHIP_SAVE_EMAIL_INFORMATION';
export const PROFILE_RELATIONSHIP_SAVE_ADDRESS_INFORMATION = 'PROFILE_RELATIONSHIP_SAVE_ADDRESS_INFORMATION';
