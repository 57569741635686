import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import AccountTypeCard from './AccountTypeCard';
import consts from './consts';

const AccListSecWrapper = styled(Col)`
    text-align: left;
    margin-bottom: 25px;
    padding: 0
    :last-child {
        margin-bottom: 0;
    }
`;
const ListSec = memo(({ accTypeDetailsArr = [], accTypeSelected, handleCardClick, accTypeDetailsKey }) =>
    accTypeDetailsArr.map((data, i) => {
        const list =
            data.subtypes &&
            data.subtypes.map(item => {
                const cardClassName = accTypeSelected === item.value ? 'selected' : '';
                const isFilteredAccountType = item.key === '403b' && accTypeDetailsKey === 'ira'
                return (
                    !isFilteredAccountType && (
                        // item.key !== 'simple' &&
                        // item.key !== 'rollover' && (
                        <AccountTypeCard
                            key={item.key}
                            item={item}
                            onclick={handleCardClick(item.value, item.key)}
                            cardClassName={cardClassName}
                        />
                    )
                );
            });
        return (
            <AccListSecWrapper xs={12} key={Number(i)}>
                {accTypeDetailsKey !== 'spec_acct' && (
                    <h2 style={consts.accSubTypeHeading} acctsubtype={data.key}>
                        {data.value !== 'Others' && data.value}
                    </h2>
                )}
                {list}
            </AccListSecWrapper>
        );
    }),
);

ListSec.propTypes = {
    accTypeDetailsArr: PropTypes.instanceOf(Array),
    accTypeSelected: PropTypes.string,
    handleCardClick: PropTypes.func,
};

ListSec.defaultProps = {
    accTypeDetailsArr: [],
    accTypeSelected: '',
    handleCardClick: () => {},
};

export default ListSec;
