import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { BENEFICIARY_NAME, RELATIONSHIP_TO_ACC_HOLDER, DISTRIBUTION_PERCENTAGE } from '../../TransactionConstants';

const BeneficiaryContainer = styled.div`
    border: 1px solid white;
`;

const BeneSecionHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 30px;
`;

const BeneSectionHeading = styled.h3`
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
`;

const BeneTitleRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
    font: 700 16px/24px benton-sans, yorkten-slab-normal, sans-serif;
`;

const BeneContentRow = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
    font: 500 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
`;

const BeneContentCol = styled(Col)`
    text-align: center;
`;

const BeneNameCol = styled(Col)`
    text-align: left;
    padding-left: 60px;
`;

const BeneficiaryCard = props => {
    const { sectionHeading, beneficiaries } = props;
    return (
        <BeneficiaryContainer>
            {beneficiaries && beneficiaries.length > 0 && (
                <div>
                    <BeneSecionHeaderWrapper>
                        <BeneSectionHeading>{sectionHeading}</BeneSectionHeading>
                    </BeneSecionHeaderWrapper>
                    <BeneTitleRow>
                        <BeneNameCol>{BENEFICIARY_NAME}</BeneNameCol>
                        <BeneContentCol>{RELATIONSHIP_TO_ACC_HOLDER}</BeneContentCol>
                        <BeneContentCol>{DISTRIBUTION_PERCENTAGE}</BeneContentCol>
                    </BeneTitleRow>
                    {beneficiaries.map(bene => (
                        <BeneContentRow key={bene.key}>
                            <BeneNameCol>{bene.bene_Name}</BeneNameCol>
                            <BeneContentCol>{bene.relationship_To_Insured}</BeneContentCol>
                            <BeneContentCol>{bene.distribution_Per}</BeneContentCol>
                        </BeneContentRow>
                    ))}
                </div>
            )}
        </BeneficiaryContainer>
    );
};

BeneficiaryCard.propTypes = {
    sectionHeading: PropTypes.string,
    beneficiaries: PropTypes.arrayOf(Object),
};

BeneficiaryCard.defaultProps = {
    sectionHeading: '',
    beneficiaries: [],
};
export default BeneficiaryCard;
