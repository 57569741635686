
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'react-bootstrap';
import { validatePhone, getFormattedValue } from '../../Utils';
import './PhoneField.css';
import {
  savePhoneNumber,
  saveCountryCode,
  saveMobileOptSelection,
} from '../../screens/UserAccountInformation/UserAccountInformation.action';
import WSwitch from '../WSwitch/WSwitch';
import analytics from '../utils.analytics';

const dataConsts = {
  isThisYourNo: 'Is this your mobile number? ',
}

const assignObj = (obj) => { return obj; }
class PhoneField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: '',
      errorMsg: '',
      countryCode: '+1',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleContryCodeChange = this.handleContryCodeChange.bind(this);
    this.handleCountryCodeBlur = this.handleCountryCodeBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleMobileOptChange = this.handleMobileOptChange.bind(this);
    this.formatStyle = {
      font: '600 12px/20px benton-sans',
      color: '#56565A',
    };
  }

  componentDidMount() {
    const { phoneNumber, countryCode, type } = this.props;
    // ADA fixes
    const checkboxLabel = document.querySelectorAll(`[for=${type}MobileOpt]`);
    if (checkboxLabel !== undefined && !isEmpty(checkboxLabel)){
      checkboxLabel[0].setAttribute('aria-hidden', 'true');
    }

    if (!isEmpty(phoneNumber)) {
      let computedPhoneNumber = phoneNumber;
      if (isEmpty(countryCode) || countryCode === '1') {
        const error = validatePhone(phoneNumber);
        if (isEmpty(error)) {
          computedPhoneNumber = getFormattedValue(phoneNumber);
        }
      }
      this.setState({ phoneNumber: computedPhoneNumber });
    }
    if (!isEmpty(countryCode)) {
      this.setState({ countryCode: `+${countryCode}` });
    }
  }

  handleFocus() {
    const { phoneNumber, errorMsg } = this.state;
    const { showPhoneError } = this.props;
    if (isEmpty(errorMsg) && showPhoneError) {
      const error = validatePhone(phoneNumber);
      this.setState({ errorMsg: error });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const { countryCode } = this.state;
    const { value } = e.target;
    const numberValue = value.split('-').join('');
    const formattedValue = countryCode === '+1' ? getFormattedValue(numberValue) : numberValue;
    // e.target.value = formattedValue;
    this.setState({ phoneNumber: formattedValue, errorMsg: '' });
  }

  handleBlur(e) {
    const { storePhone, isOptional, onParentBlur } = this.props;
    const { countryCode } = this.state;
    const { value } = e.target;
    const numberValue = value.split('-').join('');
    let showPhoneError = false;

    if (!isOptional || (isOptional && !isEmpty(numberValue))) {
      const error = countryCode.replace('+', '') === '1' ? validatePhone(numberValue) : '';
      this.setState({ errorMsg: error });
      showPhoneError = true;
      if (isEmpty(error)) {
        showPhoneError = false;
        storePhone({
          type: e.target.name,
          phoneNumber: numberValue,
        });
      }
    } else {
      storePhone({
        type: e.target.name,
        phoneNumber: numberValue,
      });
    }
    if(onParentBlur && typeof onParentBlur === "function"){
      onParentBlur(e.target.name, numberValue, showPhoneError);
    }
    
  }

  handleContryCodeChange(e) {
    const { value } = e.target;
    if (value.replace('+', '').length < 4){
      this.setState({ countryCode: value });
    }
  }

  handleCountryCodeBlur(e) {
    const { value } = e.target;
    const { type, storeCountryCode, onParentBlur } = this.props;
    const { phoneNumber } = this.state;

    const formattedPhoneNumber = value === '+1'
      ? getFormattedValue(phoneNumber.split('-').join(''))
      : phoneNumber.split('-').join('');

    let countryCode = value;
    if (value.indexOf('+') === -1){
      countryCode = `+${value}`;
    }

    this.setState({ phoneNumber: formattedPhoneNumber, errorMsg: '', countryCode });

    storeCountryCode({
      type,
      countryCode: value.replace('+', ''),
    });

    if(onParentBlur && typeof onParentBlur === "function"){
      onParentBlur(e.target.name, value, '');
    }
  }

  handleMobileOptChange() {
    const { setMobileOpt, type, mobileOpt, storeMobileOpt } = this.props;
    const toggleVal = !mobileOpt ? 'on' : 'off';
    const numberType = (type === 'primaryPhone' || type === 'primaryphone') ? 'primary number' : 'secondary number';
    analytics.triggerClickTrackEvent(
      `Preregister_verifypersonalinformation_Toggle_${toggleVal}_${numberType}`,
    );

    if(storeMobileOpt && typeof storeMobileOpt === "function"){
      storeMobileOpt({type, mobileOpt: !mobileOpt});
    }

    if(setMobileOpt && typeof setMobileOpt === "function"){
      setMobileOpt(type, !mobileOpt);
    }
  }

  render() {
    const { phoneNumber, errorMsg, countryCode } = this.state;
    const { mobileOpt, ariaRequired, type } = this.props;

    if (!isEmpty(errorMsg)) {
      const appErrorStr = errorMsg.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_verifypersonalinformation_error_${appErrorStr}`,
      );
    }
    const maxPhoneNumberLen = countryCode.replace('+', '') === '1' ? 12 : 15;

    const isUserMgmt = ((type=== 'primaryPhone' || type=== 'secondaryPhone') && errorMsg.length)

    const errorStyle = isUserMgmt ? assignObj({
      color: '#e60000',
      font: '700 12px/22px benton-sans',
      lineHeight: '20px',
    }) : null;
    const inputErrorStyle = isUserMgmt ? assignObj({
      border: 'solid 2px rgba(230, 0, 0, 1)',
    }) : null;

    return (
      <>
        <Row>
          <Col xs={3} sm={3} lg={3}>
            <input
              value={countryCode}
              name={`${type}CountryCode`}
              type="text"
              className="countryCode analytics-form-fields"
              style={assignObj({textAlign: 'center'})}
              onKeyPress={assignObj((e) => {
                if (
                  (e.keyCode < 8 || e.keyCode > 40)
                  && /\D/.test(e.key)
                  && e.key === '+'
                  && countryCode.indexOf('+') !== -1
                ) {
                  e.preventDefault();
                }
              })}
              onChange={this.handleContryCodeChange}
              onBlur={this.handleCountryCodeBlur}
              aria-label="Country Code"
              aria-required={ariaRequired ? 'true' : 'false'}
            />
          </Col>

          <Col xs={9} sm={9} lg={9}>
            <input
              value={phoneNumber}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              maxLength={maxPhoneNumberLen}
              onKeyPress={assignObj((e) => {
                if ((e.keyCode < 8 || e.keyCode > 40) && /\D/.test(e.key)) {
                  e.preventDefault();
                }
              })}
              className={errorMsg.length ? 'errorField phonetxt analytics-form-fields' : 'phonetxt analytics-form-fields'}
              name={type}
              id={type}
              type="text"
              aria-describedby={errorMsg.length ? `errorMessage${type}` : null}
              aria-label="Phone Number"
              aria-required={ariaRequired ? 'true' : 'false'}
              aria-invalid={!!errorMsg}
              style={inputErrorStyle}
            />
            {errorMsg && (
              <p
                className="errorText phoneError"
                id={`errorMessage${type}`}
                aria-live="polite"
                aria-atomic="true"
                aria-relevant="all"
                style={errorStyle}
                role="alert"
              >
                <span className="sr-only">Error:</span>
                {errorMsg}
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={10} sm={7} lg={10} className="phoneTypeRowWrapper" style={assignObj({borderTop: '1px solid #d2d2d2', marginTop: '1em'})}>
            <span className="inputLabel" id={`${type}mobileOptLbl`}>{dataConsts.isThisYourNo}</span>
          </Col>
          <Col xs={2} sm={5} lg={2} className="phoneTypeSwitchWrapper" style={assignObj({marginTop: '1em'})}>
            <WSwitch
              onSwitchClick={this.handleMobileOptChange}
              id={`${type}MobileOpt`}
              name={`${type}MobileOpt`}
              checked={mobileOpt}
              arialabelledby={`${type}mobileOptLbl`}
              ariaRequired={!!ariaRequired}
            />
          </Col>
        </Row>
      </>
    );
  }
}

PhoneField.propTypes = {
  storePhone: PropTypes.func,
  type: PropTypes.string,
  phoneNumber: PropTypes.string,
  showPhoneError: PropTypes.bool,
  storeCountryCode: PropTypes.func,
  setMobileOpt: PropTypes.func,
  storeMobileOpt: PropTypes.func,
  mobileOpt: PropTypes.bool,
  isOptional: PropTypes.bool,
  countryCode: PropTypes.string,
  ariaRequired: PropTypes.bool,
  onParentBlur: PropTypes.func,
};

PhoneField.defaultProps = {
  storePhone: () => {},
  type: '',
  phoneNumber: '',
  showPhoneError: false,
  storeCountryCode: () => {},
  setMobileOpt: () => {},
  storeMobileOpt: () => {},
  mobileOpt: false,
  isOptional: false,
  countryCode: '',
  ariaRequired: false,
  onParentBlur: () => {}
};

const mapDispatchToProps = {
  storePhone: savePhoneNumber,
  storeCountryCode: saveCountryCode,
  storeMobileOpt: saveMobileOptSelection,
};

export default connect(null, mapDispatchToProps)(PhoneField);
