const wrapperStyle = {
    /* paddingTop: '40px', */
    paddingBottom: '35px',
    position: 'relative',
};

const openAccHeading = {
    fontSize: 30,
    color: 'rgba(72, 109, 144, 1)',
    fontFamily: 'yorkten-slab-normal',
    fontWeight: '800',
    float: 'left',
    paddingBottom: 10,
    borderBottom: '2px solid rgba(140, 191, 38, 1)',
};

const openAccountErrorMsg = ' Error while launching Open Account From Master Data API';
const openAccText = 'Open a Marketplace Account';

const payloadAccountType = ['brkg_acct_type_v1'];


const displayNone = {
    display: 'none',
};
const fontawesomeiconcard = {
    height: 16,
    width: 16,
};
const cardStyling = {
    margin: '30px 0 0',
    height: '',
    minHeight: '181px',
    cursor: 'pointer',
};
const SELECT_ATLEAST_ONE_ACCOUNT_ERROR_MESSAGE = 'Please select one account to proceed';

const alertStyle = {
    marginTop: '2.5rem',
};
export default {
    alertStyle,
    SELECT_ATLEAST_ONE_ACCOUNT_ERROR_MESSAGE,
    wrapperStyle,
    openAccHeading,
    openAccountErrorMsg,
    openAccText,
    payloadAccountType,
    displayNone,
    fontawesomeiconcard,
    cardStyling,
};
