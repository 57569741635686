import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import userConstants, { getMaskedPhoneNumber } from 'modules/UserManagementAndSecurityModule/userConstants';
import routeConstants from 'routeConstants';
import { assignObj } from 'utils';
import { handleLogout } from 'PrivateRoute/PrivateRoute';
import '../Screens.css';
import getDigitalDataFormAband, {analyticsFormAbandLastTouch} from 'utils/AdobeAnalytics/formAband';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WMessageBar, WSpinner, WRadio, WStepper as UserMngmtStepper, WStepper } from '../../../../common';
import data from './constants';
import styles from '../../UserManagementStyles';
import classes from '../../UserManagementStyles.module.css';
import './OtpIvrSelect.css';
import { getDataFromORCondition } from 'utils/utils';
import { checkORConditionWithParameter, checkSingleEqualityAndReturnValue } from 'commonHelper/CommonHelperFunctions';

const { stepperColStyle } = styles.stepperStyle;

export class OtpIvrSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            primaryNumber: '',
            cancelled: false,
            error: false,
            notificationMsg: null,
            redirect: false,
            otpIvrSelect: '',
            showPopover: true,
            loading: false,
            ivrConfirm: false,
            logOutRoute: {
                pathname: routeConstants.signIn,
            }
        };
    }
    
    componentDidMount() {
        const { otpDetails, manageLocalState } = this.props;
        const {primaryNumber, onlineId, phoneType, backLink, verifyNextLink, backLabel, otpIvrSelect, flow, functionalityFlag} = otpDetails;
        if(flow) {
            if(!primaryNumber) {
                const logOutRoute =  {
                    pathname: routeConstants.signIn,
                    message: userConstants.msgForInvalidPrimNum(flow)
                };
                this.setState({logOutRoute, redirect: true});
            }
            this.setState({primaryNumber, onlineId, phoneType, backLink, verifyNextLink, backLabel, otpIvrSelect, functionalityFlag});
        } else {
            manageLocalState({otpDetails:{}});
            this.setState({redirect: true});
        }
        localStorage.setItem(data.otpVerify, false);
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props){
            const { history, loginState, recoverOnlineID, recoverOnlineIdData, memberOrGuestIVRVerifyData, directStatusofVerifyOTPData } = this.props;
            console.log('@debug for uuid in loginState',loginState);
            const { onlineId, verifyNextLink } = this.state;
            let updatedState = {...this.state};
            if (loginState.sendOtp && loginState.sendOtp.result) {
                updatedState = this.sendOtpDataHelper(loginState.sendOtp);
            }

            if (loginState.directSendPhoneOTP && loginState.directSendPhoneOTP.result) {
                updatedState = this.sendOtpDataHelper(loginState.directSendPhoneOTP);
            }
            
            if(memberOrGuestIVRVerifyData && memberOrGuestIVRVerifyData.result) {
                updatedState = this.memberOrGuestIVRVerifyHelper(memberOrGuestIVRVerifyData);
            }
            if(directStatusofVerifyOTPData && directStatusofVerifyOTPData.result) {
                updatedState = this.directStatusofVerifyOTPHelper(directStatusofVerifyOTPData);
            }
            if(recoverOnlineIdData && recoverOnlineIdData.result) {
                const {errorCode, result} = recoverOnlineIdData;
                if(errorCode === 0) {
                    recoverOnlineID('');
                    localStorage.setItem(data.otpVerify, true);
                    history.push({
                        pathname: verifyNextLink,
                        confirmedOTP: true,
                        onlineId,
                    });
                } else {
                    updatedState.notificationMsg = {message: result.message || ''};
                    recoverOnlineID('');
                }
            }
            
            if(loginState.sendOtp && loginState.sendOtp.response && loginState.sendOtp.response.data && loginState.sendOtp.response.data.message==='Failure') {
                localStorage.setItem(data.otpVerify, false);
                updatedState.loading = false;
                updatedState.notificationMsg = {message: data.apiError };
            }
            this.updatedStateFn(updatedState);
        }
    }

    componentWillUnmount() {
        const { analyzeFormAdandonment } = this.props;
        const digitalData = getDigitalDataFormAband();
        analyzeFormAdandonment(digitalData);
    }

    analyticsLastTouch = (event) =>{
        analyticsFormAbandLastTouch(event);
    }

    directStatusofVerifyOTPHelper = (directStatusofVerifyOTPData) => {
        const { history, directStatusofVerifyOTP, directSendPhoneOTP, otpDetails, manageLocalState } = this.props;
        const { otpIvrSelect} = this.state;
        const updatedState = this.state;
        const {errorCode, result, message} = directStatusofVerifyOTPData;
        updatedState.loading = false;
        updatedState.ivrConfirm = false;

        if(errorCode===0) {
            const { confirmed } = result;
            if(confirmed  === 'true') {
                directStatusofVerifyOTP('');
                directSendPhoneOTP('');
                manageLocalState({otpDetails: {...otpDetails, otpIvrSelect}});
                    history.push({
                        pathname: routeConstants.registerOnlineId,
                        confirmedOTP: true,
                    });
            } else {
                updatedState.notificationMsg = {message: data.ivrFailMessageMemberSignup};
                directStatusofVerifyOTP('');
                directSendPhoneOTP('');
            }
        } else {
            updatedState.notificationMsg = {message};
            directStatusofVerifyOTP('');
            directSendPhoneOTP('');
        }
        return updatedState;
    }

    memberOrGuestIVRVerifyHelper = (memberOrGuestIVRVerifyData) => {
        const { history, memberOrGuestIVRVerify, otpDetails, recoverOnlineID, manageLocalState, customerProfile } = this.props;
        const {onlineId, verifyNextLink, otpIvrSelect} = this.state;
        const { UUID } = customerProfile;
        const updatedState = this.state;
        const {errorCode, result} = memberOrGuestIVRVerifyData;
        updatedState.loading = false;
        if(errorCode===0) {
            if(result.verified === 'true') {
                memberOrGuestIVRVerify('');
                manageLocalState({otpDetails: {...otpDetails, otpIvrSelect}});
                if(otpDetails.flow === 'Recover User Id'){
                    recoverOnlineID({uuid: onlineId, role: otpDetails.role});
                } else {
                    localStorage.setItem(data.otpVerify, true);
                    if(otpDetails.functionalityFlag === 'recoverCustPwd' || otpDetails.functionalityFlag === 'signIn') {
                        history.push({
                            pathname: verifyNextLink,
                            confirmedOTP: true,
                            UUID
                        });
                    } else {
                        history.push({
                            pathname: verifyNextLink,
                            confirmedOTP: true,
                            onlineId,
                        });
                    }
  
                }
            } else {
                localStorage.setItem(data.otpVerify, false);
                updatedState.notificationMsg = {message: data.ivrFailMessage};
                memberOrGuestIVRVerify('');
            }
        } else if(errorCode===2023) { 
            if(otpDetails.flow === 'Recover User Id' || otpDetails.flow === 'Reset Password') {
                // localStorage.setItem(data.otpVerify, false);
                history.push({
                    pathname: routeConstants.signIn,
                    message: userConstants.blockedMessage,
                    failure: true
                });
                handleLogout();
            }
        } else {
            localStorage.setItem(data.otpVerify, false);
            updatedState.notificationMsg = {message: result.message};
            memberOrGuestIVRVerify('');
        }
        return updatedState;
    }

    sendOtpDataHelper = (sendOtpData) => {
        const { onlineId, otpIvrSelect, primaryNumber, functionalityFlag } = this.state;
        const { errorCode, result } = sendOtpData;
        const {history, memberOrGuestIVRVerify, directStatusofVerifyOTP, sendOtp, manageLocalState, otpDetails, location, directSendPhoneOTP, customerProfile } = this.props;
        const { uuid } = location;
        const { UUID } = customerProfile;
        const updatedState = {...this.state};
        const { showStepper } = getDataFromORCondition(location,{});
        const maskedPhoneNumber = getMaskedPhoneNumber(primaryNumber);
        if (errorCode === 0) {
            if(otpIvrSelect=== data.otp) {
                updatedState.loading = false;
                const otpMsgTxt = `We have sent One Time Passcode to your registered Mobile Number ${maskedPhoneNumber}.`;
                manageLocalState({otpDetails: {...otpDetails, otpIvrSelect}});
                sendOtp('');
                directSendPhoneOTP('');
                if(checkORConditionWithParameter(functionalityFlag,'recoverCustPwd',functionalityFlag, 'signIn')) {
                    history.push({
                        pathname: routeConstants.verifyOtp,
                        message: { message: otpMsgTxt },
                        otpIvrSelectScreen: true,
                        showStepper,
                        uuid: checkSingleEqualityAndReturnValue(functionalityFlag,'recoverCustPwd', uuid,UUID),
                    });
                } else {
                    history.push({
                        pathname: routeConstants.verifyOtp,
                        message: { message: otpMsgTxt },
                        otpIvrSelectScreen: true,
                        showStepper,
                        onlineId,
                    });
                }
            } else if (otpDetails.functionalityFlag === 'memberSignUpFlow') {
                const payload = {
                    onlineId,
                };
                directSendPhoneOTP('');
                setTimeout(() => {
                    directStatusofVerifyOTP(payload);
                },25000);
                
            } else {
                let payload = ''; 
                if(checkORConditionWithParameter(functionalityFlag,'recoverCustPwd',functionalityFlag,'signIn')) {
                    payload = {
                        onlineId: checkSingleEqualityAndReturnValue(functionalityFlag,'recoverCustPwd',uuid,UUID),
                        functionalityFlag: otpDetails.functionalityFlag
                    };
                } else {
                    payload = {
                        onlineId,
                        functionalityFlag: otpDetails.functionalityFlag
                    };
                }
                sendOtp('');
                setTimeout(() => {
                    memberOrGuestIVRVerify(payload);
                },25000);
            }
        } else if (errorCode === 2005){
            console.log('@debug for send otp helper error code 2005');
            updatedState.loading = false;
            if (otpDetails.functionalityFlag !== 'memberSignUpFlow') {
                localStorage.clear();
                manageLocalState({otpDetails:{}});
                updatedState.redirect =  true;
                updatedState.notificationMsg = {message: userConstants.after24hr};
                sendOtp('');
                updatedState.logOutRoute =  {
                    pathname: routeConstants.signIn,
                    message: { message: userConstants.after24hr },
                    failure: true,
                };
            } else {
                if(otpIvrSelect=== data.otp) {
                    updatedState.notificationMsg = {message: userConstants.after24hr};
                } else {
                    updatedState.notificationMsg = {message: userConstants.after24hrIVR};
                } 
                directSendPhoneOTP('');
            }
        } else if (errorCode === 2004){
            console.log('@debug for send otp helper error code 2004');
            updatedState.notificationMsg = {message: userConstants.after1hr};
            updatedState.loading = false;
            if (otpDetails.functionalityFlag !== 'memberSignUpFlow') {
                localStorage.clear();
                manageLocalState({otpDetails:{}});
                updatedState.redirect = true;
                sendOtp('');
                updatedState.logOutRoute =  {
                    pathname: routeConstants.signIn,
                    message: { message: userConstants.after1hr },
                    failure: true,
                };
            } else {
                directSendPhoneOTP('');
            }
        }
        else {
            console.log('@debug for send otp helper else');
            updatedState.notificationMsg = {message: result};
            updatedState.loading = false;
            sendOtp('');
            directSendPhoneOTP('');
        }
        return updatedState;
    }

    updatedStateFn =  (updatedState) => {
        this.setState({...updatedState});
    }

    onNext = () => {
        const { sendOtp, directSendPhoneOTP, otpDetails, customerProfile, location } = this.props;
        const { uuid } = location;
        const { UUID } = customerProfile;
        const { otpIvrSelect, onlineId, functionalityFlag, backLink, primaryNumber } = this.state;
        const {primaryNumberCountryCode} = otpDetails;
        if ( backLink === routeConstants.userDetails) {
            const payload = {
                requestType: otpIvrSelect.toUpperCase(),
                phoneNumber: `${primaryNumberCountryCode}${primaryNumber}`
            }
            directSendPhoneOTP(payload);
        } else {
            let payload = '';
            if(functionalityFlag === 'recoverCustPwd' || functionalityFlag === 'signIn') {
                payload = {
                    functionalityFlag,
                    requestType: otpIvrSelect.toUpperCase(),
                    onlineId: functionalityFlag === 'recoverCustPwd' ? uuid: UUID
                };
            } else {
                payload = {
                    functionalityFlag,
                    requestType: otpIvrSelect.toUpperCase(),
                    onlineId
                };
            }
            sendOtp(payload);
        }
        this.setState({loading:true});
    };

  

    radioClick = (e) => {
        this.setState({[e.target.name]: e.target.id});
        this.analyticsLastTouch(e);
    };

    onBack = () => {
        this.setState(state => ({ ...state, cancelled: true }));
    };

    onClose = () => {
        this.setState(state => ({ ...state, notificationMsg: null }));
    };

    renderWStepper = () => {
        const { pageData } = this.props;
        const { stepperPage, isMemberSignUp, isProspectSignUp} = pageData;
        let stepperComp = '';
          if(isProspectSignUp){
            stepperComp = <div className="memberStepper" ><UserMngmtStepper currentPage={2} pages={stepperPage} /></div>;
          }
          if(isMemberSignUp){
            stepperComp = <div className="memberStepper" ><UserMngmtStepper currentPage={3} pages={stepperPage} /></div>;
          }
          return stepperComp;
        }

    render() {
        const { functionalityFlag, cancelled, notificationMsg, focusfrom, primaryNumber, redirect,logOutRoute, otpIvrSelect, error, backLink, backLabel, loading } = this.state;
        const {loginState, manageLocalState, location, pageData , otpDetails} = this.props;
        const { flow } = otpDetails;
        const { showStepper } = location || {};
        const { isMemberSignUp, isProspectSignUp} = pageData;
        const backStyle = functionalityFlag==='signIn' ? {display: 'none'} : '';
        const maskedPhoneNumber = primaryNumber ? getMaskedPhoneNumber(primaryNumber) : '';
        let redirectionResult = '';
        if (redirect) {
            manageLocalState({otpDetails:{}});
            redirectionResult = <Redirect to={logOutRoute} />;
        }
        // console.log('@debug for flow value in otp ivr', flow);
        const analyticId = flow ? `UMS_${flow.replace(/\s+/g,'')}_ChooseSecurityCodeMethod`: `UMS_${flow}_ChooseSecurityCodeMethod`;
        const title = (backLink === routeConstants.userDetails) ? data.directRegistrationTitle : data.title;
        const verifyLabel = (backLink === routeConstants.userDetails) ? data.directRegistrationVerifyLabel : data.verifyLabel;
        return (
            
            <div className="container-fluid">
                {redirectionResult}
                <WSpinner loading={loginState.isLoading || loading} />
                {notificationMsg ? <WMessageBar text={notificationMsg} onClose={this.onClose} focusfrom={focusfrom} /> : null}
                { (isMemberSignUp || isProspectSignUp) && this.renderWStepper() }
                <div className="container">
                    {
                    showStepper && <WStepper
                    className="userWizard"
                    currentPage={0}
                    pages={data.pages}
                    stepperColStyle={stepperColStyle}
               />   
                  }
                    
                    <Row className="main confirm-otpivr">
                        <Col md={6} lg={6} xl={6}>
                            <SideHeader title={title} info={data.getInfo(maskedPhoneNumber)} />
                        </Col>
                        <Col md={1} lg={1} xl={1} />
                        <Col md={5} lg={5} xl={5}>
                            <span className={classes.optionalMessage}>{data.optionalMessage}</span>
                            <Col md={12} lg={12} xl={12} style={data.inputCol}>
                                <span className="verifyAuthPref" aria-hidden="true">{verifyLabel}</span>
                                <Form.Group role="radiogroup" style={data.formGroup} aria-describedby="otpivrSelectError" aria-labelledby="otpivrSelectLabel">
                                    <span className="sr-only" id="otpivrSelectLabel">{verifyLabel}</span>
                                    <WRadio
                                        id="otp"
                                        value="Text"
                                        radioFor="otpIvrSelect"
                                        selected={otpIvrSelect==='otp'}
                                        onClick={this.radioClick}
                                        role="radio"
                                        className="analytics-form-fields"
                                        radiotext={styles.radioText}
                                    />
                                    <WRadio
                                        id="ivr"
                                        value="Voice"
                                        radioFor="otpIvrSelect"
                                        onClick={this.radioClick}
                                        selected={otpIvrSelect==='ivr'}
                                        role="radio"
                                        className="analytics-form-fields"
                                        radiotext={styles.radioText}
                                    />
                                </Form.Group>
                                <span className={classes.errMsg} id="otpivrSelectError">{error}</span>
                            </Col>
                        </Col>
                    </Row>
                 </div>
                <CommonButtons
                    backClick={this.onBack}
                    backstyle={backStyle}
                    nextClick={this.onNext}
                    disabled={!otpIvrSelect}
                    blockstyle={styles.commonButtons}
                    arialabelback={assignObj(`Back to ${backLabel}`)}
                    dataAnalyticId={analyticId}
                />
                {cancelled && <Redirect to={backLink} />}
            </div>
        );
    }
}

OtpIvrSelectComponent.propTypes = {
    sendOtp: PropTypes.func,
    memberOrGuestIVRVerify: PropTypes.func,
    loginState: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    history: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
    otpDetails: PropTypes.instanceOf(Object),
    memberOrGuestIVRVerifyData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    manageLocalState: PropTypes.func,
    recoverOnlineIdData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    recoverOnlineID: PropTypes.func,
    pageData:  PropTypes.shape({
        isMemberSignUp: PropTypes.bool,
        isProspectSignUp: PropTypes.bool,
        stepperPage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
      }),
    directStatusofVerifyOTPData: PropTypes.shape({
        message: PropTypes.string,
        errorCode: PropTypes.number,
        result: PropTypes.shape({
            confirmed: PropTypes.string,
        }),
    }),
    directSendPhoneOTP: PropTypes.func,
    directStatusofVerifyOTP: PropTypes.func,
    customerProfile: PropTypes.instanceOf(Object),
    UUID: PropTypes.string
};

OtpIvrSelectComponent.defaultProps = {
    sendOtp: () => {},
    memberOrGuestIVRVerify: () => {},
    loginState: {},
    history: [],
    location: {},
    otpDetails: {
        primaryNumber: '',
        primaryNumberCountryCode: '',
        phoneType: '',
        onlineId: '',
        functionalityFlag : '',
        backLink: '',
        verifyNextLink: '',
        backLabel: '',
    },
    memberOrGuestIVRVerifyData: {},
    manageLocalState: () => {},
    recoverOnlineIdData: {},
    recoverOnlineID: () => {},
    pageData:{
        isMemberSignUp: false,
        isProspectSignUp: false,
        stepperPage: [],
      },
    directStatusofVerifyOTPData: {},
    directSendPhoneOTP: () => {},
    directStatusofVerifyOTP: () => {},
    customerProfile: {},
    UUID: ''
};

export default OtpIvrSelectComponent;