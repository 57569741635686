import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Alert, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
import { formatDate, isValidDate } from 'utils';
import {
    WInput,
    VCMSelect,
    SSNComponent,
    VCMDatePicker,
    WIcon,
    WRadio,
    ConformationModal,
} from '../../../common';
import Consts from '../PersonalInfo/consts';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import Address from '../Address';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';
import plusIcon from '../../../assets/plusIcon.png';
import removeIcon from '../../../assets/deleteTrash.svg';
import constants from './Consts';
import EmailDetailsCardComponent from '../Email/EmailDetailsCardComponent';
import PhoneDetailsCardComponent from '../PhoneDetails/PhoneDetailsCardComponent';

const assignObj = obj => {
    return obj;
};
const citizenshipLabel = 'Citizenship';
const genderLabel = 'Gender';
const sameAddressRadioLabel = 'Is your physical address the same as your mailing address?';
const dateOfBirthLabel = 'Date of Birth';
const contactInfoHeading = 'Contact Information';

const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

const radioText = { font: '500 16px/20px benton-sans' };

class JointPersonalInfoCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jointOwners: [
                {
                    errorMsg: {},
                    fullSSN: '',
                    isSSNerror: false,
                    tooltipOpen: false,
                    showMenu: false,
                    loading: true,
                    perDetailsHeadreClick: true,
                    contactDetailsHeaderClick: true,
                    citizenship: 'us', // By default, U.S should be selected
                    retrievedState: false,
                    gender: '',
                    dateOfBirth: '',
                    prefix: '',
                    suffix: '',
                    relationship: '',
                    errorFromMasterData: false,
                    firstName: '',
                    lastName: '',
                    middleInitial: '',
                    readOnly: false,
                    maritalStatus: '',
                    jointMailAddressRef: React.createRef(),
                    jointPhysicalAddressRef: React.createRef(),
                    jointPhoneDetailsRef: React.createRef(),
                    jointAddressAccordionRef: React.createRef(),
                    jointPersonalRef: React.createRef(),
                    jointSSNRef: React.createRef(),
                    jointEmailAddressRef: React.createRef(),
                    jointMailAddressData: {},
                    jointPhysicalAddressData: {},
                    jointPhoneDetailsData: {},
                    jointEmailAddressData: {},
                    jointSSNData: {},
                },
            ],
            valid: 'false',
            showMaxAlert: false,
            showDelConfirmDialog: false,
            delIndex: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { localStateData } = nextProps;
        const savedData = localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.jointPerInfo.jointOwners;
        if (savedData && savedData.length > 0) {
            return {
                ...prevState,
                jointOwners: savedData,
            };
        }
        return { ...prevState };
    }

    get accordionRef() {
        const { jointOwners } = this.state;
        const errorSections = [
            {
                element: jointOwners[0].jointPersonalRef.current,
                valid: jointOwners[0].valid,
            },
        ];

        return {
            current: {
                scrollIntoView: options => {
                    const firstErrorSection = errorSections.find(section => !section.valid);
                    if (firstErrorSection?.element) {
                        firstErrorSection.element.scrollIntoView(options);
                    }
                },
            },
        };
    }

    handleBlur = (index, eventKey) => e => {
        const { jointOwners } = this.state;
        const err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        jointOwners[Number(index)].errorMsg[eventKey.toString()] = err;
        this.setState(
            () => {
                const result = this.checkPersonalInfoParentNextStatus(jointOwners[Number(index)].errorMsg);
                return {
                    jointOwners,
                    ready: result,
                };
            },
            // () => {
            //     this.checkPersonalInfoParentChildNextStatus();
            // },
        );
    };

    getMaritalStatusDescription = marital => {
        let maritalStatus = marital;

        switch (marital) {
            case 'D':
                maritalStatus = 'Divorced';
                break;
            case 'M':
                maritalStatus = 'Married';
                break;
            case 'S':
                maritalStatus = 'Single';
                break;
            case 'SE':
                maritalStatus = 'Separated';
                break;
            case 'W':
                maritalStatus = 'Widowed';
                break;
            default:
                break;
        }
        return maritalStatus;
    };

    handleChange = (index, eventKey) => e => {
        const { jointOwners } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        jointOwners[Number(index)].errorMsg[eventKey.toString()] = err;

        const temp = jointOwners[Number(index)];
        if (eventKey === 'maritalLocal') {
            temp[eventKey.toString()] = this.getMaritalStatusDescription(e.target.value);
        } else {
            temp[eventKey.toString()] = e.target.value;
        }
        jointOwners[Number(index)] = temp;
        this.setState({ jointOwners });
    };

    cardHeaderClick = index => e => {
        const { jointOwners } = this.state;
        e.preventDefault();
        e.target.classList.toggle('expand');
        const temp = jointOwners[Number(index)];
        temp.perDetailsHeadreClick = !temp.perDetailsHeadreClick;
        jointOwners[Number(index)] = temp;
        this.setState({ jointOwners });
    };

    clearInitialFiles = () => {
        this.setState({
            localForm: null,
            w8FormUpload: undefined,
            originalW8FileArray: null,
            originalW8FileArrayFromMount: null,
        });
    };

    uploadW8File = fileData => {
        if (fileData !== undefined) {
            const { uploadW8Form } = this.props;
            const { originalW8FileArray, W8FileUploaded } = this.state;
            if (originalW8FileArray === null || (originalW8FileArray === undefined && !W8FileUploaded)) {
                const payload = {
                    Body: fileData.w8FormUpload[0].base64String,
                };
                this.setState({
                    w8FormUpload: fileData.w8FormUpload[0].base64String,
                    originalW8FileArray: [fileData.originalw8FormUpload],
                });
                uploadW8Form(payload);
            }
        }
    };

    toggleTooltip = index => {
        const { jointOwners } = this.state;
        const temp = jointOwners[Number(index)];
        temp.tooltipOpen = !temp.tooltipOpen;
        jointOwners[Number(index)] = temp;
        this.setState({ jointOwners });
    };

    checkEachFieldStatus = jointOwner => {
        let errorMsg = {};
        let valid = false;
        const stateData = jointOwner;
        const invalidFiled = [];
        const fields = this.getFieldsForValidation();
        fields.forEach(field => {
            let errorField = '';
            switch (field) {
                case 'dateOfBirth':
                case 'maritalStatus':
                case 'firstName':
                case 'lastName':
                case 'sameAddress':
                case 'relationship':
                case 'gender':
                    errorField = Rules[field.toString()]
                        ? checkValidity({
                              rules: Rules[field.toString()],
                              value: stateData[field.toString()] === 'Error' ? '' : stateData[field.toString()],
                              label: stateData[field.toString()],
                          })
                        : '';
                    break;
                case 'SSN':
                    errorField = Rules[field.toString()]
                        ? checkValidity({
                              rules: Rules[field.toString()],
                              value: stateData.fullSSN,
                          })
                        : '';
                    if (errorField !== null) {
                        this.setState({ isSSNerror: true });
                    } else {
                        this.setState({ isSSNerror: false });
                    }
                    break;
                case 'citizenship':
                    errorField = !stateData[field.toString()];
                    break;
                default:
                    break;
            }
            if (typeof errorField === 'boolean') {
                valid = errorField === true;
                invalidFiled.push(valid);
            } else if (typeof errorField === 'string') {
                valid = errorField !== '';
                invalidFiled.push(valid);
            }
            errorMsg = { ...errorMsg, [field]: errorField };
        });

        valid = !invalidFiled.some(filed => filed === true);

        return { errorMsg, valid };
    };

    getFieldsForValidation = () => {
        return [
            'dateOfBirth',
            'gender',
            'maritalStatus',
            'citizenship',
            'SSN',
            'sameAddress',
            'firstName',
            'lastName',
            'relationship',
        ];
    };

    getChildData = jointOwner => {
        const {
            jointMailAddressRef,
            jointPhysicalAddressRef,
            jointPhoneDetailsRef,
            jointSSNRef,
            jointEmailAddressRef,
        } = jointOwner;
        const jointMailAddressData = jointMailAddressRef.current && jointMailAddressRef.current.getAddress();
        const jointPhysicalAddressData =
            jointPhysicalAddressRef.current && jointPhysicalAddressRef.current.getAddress();
        const jointPhoneDetailsData = jointPhoneDetailsRef.current && jointPhoneDetailsRef.current.sendDataToParent();
        const jointSSNData = jointSSNRef.current && jointSSNRef.current.getSSN();
        const jointEmailAddressData = jointEmailAddressRef.current && jointEmailAddressRef.current.sendDataToParent();

        return {
            jointPhoneDetailsData,
            jointMailAddressData,
            jointPhysicalAddressData,
            jointEmailAddressData,
            jointSSNData,
        };
    };

    openAccrodions = (validPerInfo, validPhoneInfo, validEmailInfo, validAddressInfo) => {
        const emailRef = this.emailAddressRef;

        if (!validPerInfo) {
            this.personalRef.current.click();
        }

        if (!validPhoneInfo) {
            this.phoneDetailsRef.current.contactAccordion.current.click();
        }

        if (!validEmailInfo) {
            emailRef.current.emailAccordion.current.click();
        }

        if (!validAddressInfo) {
            this.addressAccordion.current.click();
        }
    };

    checkMaxAlertStatus = (type, action = 'added') => {
        let { showMaxAlert = false } = this.state;
        if (action === 'deleted') {
            showMaxAlert = false;
        } else {
            showMaxAlert = true;
        }
        this.setState({ showMaxAlert });
    };

    addAnotherJointOwner = () => {
        const { jointOwners } = this.state;
        if (jointOwners && jointOwners.length < 2) {
            jointOwners.push({
                errorMsg: {},
                fullSSN: '',
                isSSNerror: false,
                tooltipOpen: false,
                showMenu: false,
                loading: true,
                perDetailsHeadreClick: true,
                contactDetailsHeaderClick: true,
                citizenship: 'us', // By default, U.S should be selected
                retrievedState: false,
                gender: '',
                dateOfBirth: '',
                prefix: '',
                relationship: '',
                errorFromMasterData: false,
                firstName: '',
                lastName: '',
                middleInitial: '',
                readOnly: false,
                maritalStatus: '',
                jointMailAddressRef: React.createRef(),
                jointPhysicalAddressRef: React.createRef(),
                jointPhoneDetailsRef: React.createRef(),
                jointAddressAccordionRef: React.createRef(),
                jointPersonalRef: React.createRef(),
                jointSSNRef: React.createRef(),
                jointEmailAddressRef: React.createRef(),
            });
            this.setState({
                jointOwners,
                jointOwnersLength: jointOwners.length,
            });
        } else {
            this.checkMaxAlertStatus();
        }
    };

    sendDataToParent = () => {
        const { jointOwners } = this.state;
        const jointOwnersData = [...jointOwners];
        jointOwnersData.map((jointOwner, index) => {
            const { sameAddress } = jointOwner;
            const {
                jointPhoneDetailsData,
                jointMailAddressData,
                jointPhysicalAddressData,
                jointEmailAddressData,
                jointSSNData,
            } = this.getChildData(jointOwner);

            jointOwnersData[parseInt(index, 10)].jointMailAddressData = jointMailAddressData;
            jointOwnersData[parseInt(index, 10)].jointPhysicalAddressData = jointPhysicalAddressData;
            jointOwnersData[parseInt(index, 10)].jointPhoneDetailsData = jointPhoneDetailsData;
            jointOwnersData[parseInt(index, 10)].jointSSNData = jointSSNData;
            jointOwnersData[parseInt(index, 10)].fullSSN = jointSSNData && jointSSNData.fullSSN;
            jointOwnersData[parseInt(index, 10)].jointEmailAddressData = jointEmailAddressData;

            const { errorMsg, valid } = this.checkEachFieldStatus(jointOwner);
            jointOwnersData[parseInt(index, 10)].errorMsg = errorMsg;
            if (errorMsg.SSN !== null) {
                jointOwnersData[parseInt(index, 10)].isSSNerror = true;
            }

            const isPhoneValid = jointPhoneDetailsData && jointPhoneDetailsData.valid;
            const isEmailValid = jointEmailAddressData && jointEmailAddressData.valid;
            const isJointMailAddressDataValid = jointMailAddressData && jointMailAddressData.valid;
            let isJointPhysicalAddressDataValid = false;
            if (sameAddress === 'Yes') {
                isJointPhysicalAddressDataValid = isJointMailAddressDataValid;
            } else if (sameAddress === 'No') {
                isJointPhysicalAddressDataValid = jointPhysicalAddressData && jointPhysicalAddressData.valid;
            }
            const isJointOwnerValid =
                valid && isPhoneValid && isEmailValid && isJointMailAddressDataValid && isJointPhysicalAddressDataValid;
            jointOwnersData[parseInt(index, 10)].valid = isJointOwnerValid;
            return {};
        });

        this.setState({ jointOwners: jointOwnersData });
        const invalidJointOwners = jointOwnersData.filter(item => item.valid !== true);
        const isJointOwnersValid = invalidJointOwners.length === 0;
        return { jointOwners: [...jointOwnersData], valid: isJointOwnersValid };
    };

    handleSSN = val => {
        this.setState({ fullSSN: val });
    };

    handleRadioBlur = (index, eventKey) => e => {
        e.persist();
        const { jointOwners } = this.state;
        let err = '';
        err = Rules[eventKey.toString()]
            ? checkValidity({
                  rules: Rules[eventKey.toString()],
                  value: e.target.value,
              })
            : '';
        jointOwners[Number(index)].errorMsg[eventKey.toString()] = err;
        const temp = jointOwners[Number(index)];
        temp[eventKey.toString()] = e.target.value;
        jointOwners[Number(index)] = temp;
        this.setState({ jointOwners });
    };

    getValue = data => {
        this.setState(data);
    };

    getAlreadySavedData = accountType => {
        const { localStateData } = this.props;
        const savedPerMailAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.mailingAddress;
        const savedJointPerMailAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.jointPerInfo &&
            localStateData.OpenAccPageTwo.jointPerInfo.jointMailAddress;

        const savedPhysicalAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.physicalAddress;
        const savedJointPhysicalAddress =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.jointPerInfo &&
            localStateData.OpenAccPageTwo.jointPerInfo.jointPhysicalAddress;

        const savedSSNData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.ssnData;

        const savedJointSSNData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.jointPerInfo &&
            localStateData.OpenAccPageTwo.jointPerInfo.jointSSNData;

        const savedJointEmailAddressData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.jointPerInfo &&
            localStateData.OpenAccPageTwo.jointPerInfo.jointEmailAddressData;

        const savedEmailAddressData =
            accountType &&
            localStateData.OpenAccPageTwo &&
            localStateData.OpenAccPageTwo.personalInfo &&
            localStateData.OpenAccPageTwo.personalInfo.emailAddress;

        return {
            savedPerMailAddress,
            savedJointPerMailAddress,
            savedPhysicalAddress,
            savedJointPhysicalAddress,
            savedSSNData,
            savedJointSSNData,
            savedJointEmailAddressData,
            savedEmailAddressData,
        };
    };

    getMasterDataValues = () => {
        const { masterLookupStateData } = this.props;
        /** Master Data State handling Start */
        const {
            citizenship: citizenshipMaster,
            marital_status: maritalStatusMaster,
            prefix: prefixMaster,
            suffix: suffixMaster,
            relationship: relationshipMaster,
            gender: genderMaster,
            contact_time: contactTimeMaster,
        } = masterLookupStateData || {};
        const { value: citizenshipMasterValue } = citizenshipMaster || {};
        const { value: maritalStatusMasterValue } = maritalStatusMaster || {};
        const { value: prefixMasterValue } = prefixMaster || {};
        const { value: suffixMasterValue } = suffixMaster || {};
        const { value: relationshipMasterValue } = relationshipMaster || {};
        const { value: genderMasterValue } = genderMaster || {};
        const { value: contactTimeMasterDataValue } = contactTimeMaster || {};
        const contactTimeOp = contactTimeMasterDataValue || [];
        const suffixOp = suffixMasterValue || [];
        const citizenshipOp = citizenshipMasterValue || [];
        const maritalStatus = maritalStatusMasterValue || [];
        const prefixOp = prefixMasterValue || [];
        const relationshipOp = relationshipMasterValue || [];
        const genderOp = genderMasterValue || [];
        /** Master Data State Handling End */
        return {
            contactTimeOp,
            suffixOp,
            citizenshipOp,
            maritalStatus,
            prefixOp,
            relationshipOp,
            genderOp,
        };
    };

    getJointValues = () => {
        const accTypeHeading = '- Joint Owner';
        const jointText = 'jointOwner';
        return {
            jointText,
            accTypeHeading,
        };
    };

    getMaritalStatus = index => {
        const { jointOwners } = this.state;
        const jointOwnersData = [...jointOwners];
        if (jointOwnersData[parseInt(index, 10)].maritalStatus === 'Separated') {
            return 'SE';
        }
        return (
            jointOwnersData[parseInt(index, 10)].maritalStatus &&
            jointOwnersData[parseInt(index, 10)].maritalStatus.split('')[0].toUpperCase()
        );
    };

    checkPersonalInfoParentNextStatus = errorMsg => {
        const { firstName, lastName, dateOfBirth, gender, maritalStatus, citizenship, fullSSN, sameAddress } = errorMsg;
        return (
            !!firstName &&
            !!lastName &&
            !!dateOfBirth &&
            !!gender &&
            !!maritalStatus &&
            !!citizenship &&
            !!fullSSN &&
            !!sameAddress
        );
    };

    renderSuffix = (suffix, suffixOp, index, errorMsg) => {
        return (
            <>
                <VCMSelect
                    name="suffix"
                    id={assignObj(`jointOwnerSuffix${index}`)}
                    label="Suffix"
                    itemlist={suffixOp}
                    onChange={this.handleChange(index, 'suffix')}
                    onBlur={this.handleBlur(index, 'suffix')}
                    value={suffix || ''}
                    labelsm={4}
                    valuesm={5}
                    errortext={errorMsg && errorMsg.suffix}
                    noGutters
                    optional
                />
            </>
        );
    };

    getAlreadySavedJointOwners = () => {
        const { localStateData } = this.props;
        return localStateData.OpenAccPageTwo && localStateData.OpenAccPageTwo.jointPerInfo;
    };

    getSavedJointMailAddress = jointMailAddressData => {
        return {
            addressType: jointMailAddressData && jointMailAddressData.mailTypeRadio,
            addressLine1: jointMailAddressData && jointMailAddressData.lineOne,
            addressLine2: jointMailAddressData && jointMailAddressData.lineTwo,
            zip: jointMailAddressData && jointMailAddressData.zipCode,
            city: jointMailAddressData && jointMailAddressData.city,
            state: jointMailAddressData && jointMailAddressData.state,
        };
    };

    getSavedJointPhysicalAddress = jointPhysicalAddressData => {
        return {
            addressType: jointPhysicalAddressData && jointPhysicalAddressData.mailTypeRadio,
            addressLine1: jointPhysicalAddressData && jointPhysicalAddressData.lineOne,
            addressLine2: jointPhysicalAddressData && jointPhysicalAddressData.lineTwo,
            zip: jointPhysicalAddressData && jointPhysicalAddressData.zipCode,
            city: jointPhysicalAddressData && jointPhysicalAddressData.city,
            state: jointPhysicalAddressData && jointPhysicalAddressData.state,
        };
    };

    renderAddressAccordion = (jointOwner, index, accountType) => {
        const {
            errorMsg,
            sameAddress,
            contactDetailsHeaderClick,
            jointMailAddressRef,
            jointPhysicalAddressRef,
            jointAddressAccordionRef,
        } = jointOwner;
        const accTypeHeading = '- Joint Owner';
        const jointText = 'jointOwner';

        const sameAddressOp = Consts.sameAddress.value;
        const { jointMailAddressData } = jointOwner;
        const { jointPhysicalAddressData } = jointOwner;
        const savedJointPerMailAddress = jointMailAddressData && this.getSavedJointMailAddress(jointMailAddressData);
        const savedJointPhysicalAddress =
            jointPhysicalAddressData && this.getSavedJointPhysicalAddress(jointPhysicalAddressData);
        return (
            <Accordion defaultActiveKey="0" id={assignObj(`${jointText}AddressAccordion${index}`)}>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick(index)}
                            aria-expanded={contactDetailsHeaderClick}
                            ref={jointAddressAccordionRef}
                        >
                            {contactInfoHeading} {accTypeHeading}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" id={assignObj(`${jointText}AddressAccordionCardBody${index}`)}>
                        <Card.Body>
                            <Address
                                savedState={savedJointPerMailAddress}
                                ref={jointMailAddressRef}
                                labelAdd="Mailing Address"
                                mailRadio
                                required
                                type="mailing"
                                isJoint
                            />
                            <Row
                                className="Slabel"
                                role="group"
                                aria-labelledby={assignObj(`${jointText}isPhysicalAddressSameasMailingAddress`)}
                            >
                                <span
                                    className="label centarizeLabel"
                                    style={styles.labelTextStyle}
                                    id={assignObj(`${jointText}isPhysicalAddressSameasMailingAddress${index}`)}
                                >
                                    {sameAddressRadioLabel}
                                </span>
                                <Col>
                                    <Row>
                                        {sameAddressOp &&
                                            sameAddressOp.length > 0 &&
                                            sameAddressOp.map(field => (
                                                <WRadio
                                                    radioFor={`${field.key}${Object(index)}`}
                                                    key={`${field.key}${Object(index)}`}
                                                    id={`${field.key}${Object(index)}`}
                                                    value={field.value}
                                                    onBlur={this.handleRadioBlur(index, 'sameAddress')}
                                                    onClick={this.handleRadioBlur(index, 'sameAddress')}
                                                    radiodescstyles={radiodescstyles}
                                                    radiostyles={radiostyles}
                                                    radiotext={radioText}
                                                    selected={
                                                        !!(
                                                            sameAddress &&
                                                            sameAddress
                                                                .toString()
                                                                .substring(0, 1)
                                                                .toUpperCase() === field.key
                                                        )
                                                    }
                                                />
                                            ))}
                                    </Row>
                                    <Row>
                                        {errorMsg.sameAddress && (
                                            <span
                                                className="radioError error"
                                                role="region"
                                                id="sameAddressError"
                                                aria-labelledby="sameAddressError"
                                                aria-live="polite"
                                                aria-atomic="true"
                                            >
                                                {errorMsg.sameAddress}
                                            </span>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            {sameAddress === 'No' ? (
                                <Address
                                    savedState={savedJointPhysicalAddress}
                                    ref={jointPhysicalAddressRef}
                                    labelAdd="Physical Address"
                                    required
                                    labelAdd1="Physical Address Line 1"
                                    labelAdd2="Physical Address Line 2"
                                    type="physical"
                                    isJoint
                                />
                            ) : (
                                ''
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    renderSSNComponent = (savedJointSSNData, jointOwner) => {
        const { isSSNerror = false, jointSSNRef } = jointOwner;
        let ssnLabel = 'Social Security Number / Tax ID';
        if (jointOwner.citizenship === 'us' || jointOwner.citizenship === 'U.S') {
            ssnLabel = 'Social Security Number';
        }
        return (
            <Row>
                <SSNComponent
                    ref={jointSSNRef}
                    savedstate={savedJointSSNData}
                    sendfullssn={this.handleSSN}
                    isssnerror={isSSNerror}
                    isJoint
                    ssnLabel={ssnLabel}
                />
            </Row>
        );
    };

    removeJointOwner = action => () => {
        const { jointOwners, delIndex } = this.state;
        if (action && jointOwners.length > 1) {
            jointOwners.splice(delIndex, 1);
        }

        this.setState({ jointOwners, jointOwnersLength: jointOwners.length, showDelConfirmDialog: false });
    };

    handleDelete = index => e => {
        e.stopPropagation();
        e.target.classList.toggle('expand');
        this.setState({ showDelConfirmDialog: true, delIndex: index });
    };

    renderJointOwner = () => {
        const { jointOwners } = this.state;
        return jointOwners.map((jointOwner, index) => {
            const { localStateData } = this.props;
            const perInfoHeading = ' Personal Information';
            const {
                gender,
                citizenship,
                dateOfBirth,
                prefix,
                relationship,
                errorMsg,
                errorFromMasterData = false,
                perDetailsHeadreClick,
                firstName = '',
                lastName = '',
                middleInitial = '',
                readOnly = false,
                suffix,
                jointEmailAddressRef,
                jointPhoneDetailsRef,
                jointPersonalRef,
                jointPhoneDetailsData,
                jointEmailAddressData,
                jointSSNData,
            } = jointOwner;
            const masterDataValues = this.getMasterDataValues();
            const {
                contactTimeOp,
                suffixOp,
                citizenshipOp,
                maritalStatus,
                prefixOp,
                relationshipOp,
                genderOp,
            } = masterDataValues;

            const { accountType } = localStateData;

            const { jointText, accTypeHeading } = this.getJointValues();
            if (errorFromMasterData) {
                throw new Error('Error From Master Data in Personal Info Card');
            }

            const startDateMin = new Date();

            let primaryEmailAddress = '';
            let secondaryEmailAddress = '';
            if (jointEmailAddressData) {
                primaryEmailAddress =
                    jointEmailAddressData.primaryEmailDetails && jointEmailAddressData.primaryEmailDetails.email;
                secondaryEmailAddress =
                    jointEmailAddressData.secondaryEmailDetails && jointEmailAddressData.secondaryEmailDetails.email;
            }

            let primaryPhoneNumber = '';
            let secondaryPhoneNumber = '';
            let primaryContactTime = '';
            let secondaryContactTime = '';
            let primaryIsMobile = false;
            let secondaryIsMobile = false;
            let primaryCountryCode = '+1';
            let secondaryCountryCode = '+1';

            if (jointPhoneDetailsData) {
                primaryPhoneNumber =
                    jointPhoneDetailsData.primaryPhoneDetails && jointPhoneDetailsData.primaryPhoneDetails.phoneNumber;
                secondaryPhoneNumber =
                    jointPhoneDetailsData.secondaryPhoneDetails &&
                    jointPhoneDetailsData.secondaryPhoneDetails.phoneNumber;
                primaryContactTime =
                    jointPhoneDetailsData.primaryPhoneDetails && jointPhoneDetailsData.primaryPhoneDetails.contactTime;
                secondaryContactTime =
                    jointPhoneDetailsData.secondaryPhoneDetails &&
                    jointPhoneDetailsData.secondaryPhoneDetails.contactTime;
                primaryIsMobile =
                    jointPhoneDetailsData.primaryPhoneDetails && jointPhoneDetailsData.primaryPhoneDetails.isMobile;
                secondaryIsMobile =
                    jointPhoneDetailsData.secondaryPhoneDetails && jointPhoneDetailsData.secondaryPhoneDetails.isMobile;
                primaryCountryCode =
                    (jointPhoneDetailsData.primaryPhoneDetails &&
                        jointPhoneDetailsData.primaryPhoneDetails.countryCode) ||
                    '+1';
                secondaryCountryCode =
                    (jointPhoneDetailsData.secondaryPhoneDetails &&
                        jointPhoneDetailsData.secondaryPhoneDetails.countryCode) ||
                    '+1';
            }
            const removeJointOwnerText = 'Remove joint owner';

            return (
                <div key={`jointOwner${assignObj(index)}`}>
                    <Accordion defaultActiveKey="0" id={assignObj(`${jointText}PersonalAccordion${index}`)}>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as="h2"
                                    variant="link"
                                    eventKey="0"
                                    className="expand"
                                    onClick={this.cardHeaderClick(index)}
                                    aria-expanded={perDetailsHeadreClick}
                                    ref={jointPersonalRef}
                                >
                                    {perInfoHeading} {accTypeHeading}
                                    {jointOwners.length > 1 && (
                                        <button
                                            onClick={this.handleDelete(index)}
                                            type="button"
                                            className="deleteFieldBtn"
                                            aria-label={removeJointOwnerText}
                                        >
                                            <WIcon src={removeIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                                        </button>
                                    )}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0" id={assignObj(`${jointText}PersonalCardBody${index}`)}>
                                <Card.Body>
                                    <VCMSelect
                                        id={assignObj(`${jointText}relationship${index}`)}
                                        name="relationship"
                                        itemlist={relationshipOp}
                                        label="Relationship to Account Holder"
                                        onChange={this.handleChange(index, 'relationship')}
                                        onBlur={this.handleBlur(index, 'relationship')}
                                        value={relationship}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.relationship}
                                        noGutters
                                        required
                                    />
                                    <VCMSelect
                                        id={assignObj(`${jointText}Prefix${index}`)}
                                        name="prefix"
                                        label="Prefix"
                                        itemlist={prefixOp}
                                        onChange={this.handleChange(index, 'prefix')}
                                        onBlur={this.handleBlur(index, 'prefix')}
                                        value={prefix}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.prefix}
                                        noGutters
                                        optional
                                    />
                                    <Row>
                                        <WInput
                                            label="First Name"
                                            aria-required
                                            required
                                            inputclassname={readOnly ? 'prefilled' : ''}
                                            id={assignObj(`${jointText}FirstName${index}`)}
                                            name="firstName"
                                            type="text"
                                            onChange={this.handleChange(index, 'firstName')}
                                            onBlur={this.handleBlur(index, 'firstName')}
                                            value={firstName || ''}
                                            readOnly={readOnly}
                                            labelsm={4}
                                            valuesm={5}
                                            maxlength={40}
                                            errortext={errorMsg.firstName}
                                        />
                                    </Row>
                                    <Row>
                                        <WInput
                                            label="Middle Name"
                                            inputclassname={readOnly ? 'prefilled' : ''}
                                            optional="true"
                                            sublabel={styles.sublabel}
                                            name="middleInitial"
                                            id={assignObj(`${jointText}MiddleInitial${index}`)}
                                            type="text"
                                            onChange={this.handleChange(index, 'middleInitial')}
                                            onBlur={this.handleBlur(index, 'middleInitial')}
                                            errortext={errorMsg.middleInitial}
                                            readOnly={readOnly}
                                            value={middleInitial || ''}
                                            labelsm={4}
                                            valuesm={5}
                                            maxlength={40}
                                            aria-required={false}
                                            required={false}
                                        />
                                    </Row>
                                    <Row>
                                        <WInput
                                            aria-required
                                            label="Last Name"
                                            inputclassname={readOnly ? 'prefilled' : ''}
                                            name="lastName"
                                            id={assignObj(`${jointText}LastName${index}`)}
                                            value={lastName || ''}
                                            type="text"
                                            required
                                            onChange={this.handleChange(index, 'lastName')}
                                            onBlur={this.handleBlur(index, 'lastName')}
                                            readOnly={readOnly}
                                            labelsm={4}
                                            valuesm={5}
                                            maxlength={40}
                                            errortext={errorMsg.lastName}
                                        />
                                    </Row>
                                    {this.renderSuffix(suffix, suffixOp, index, errorMsg)}
                                    <VCMDatePicker
                                        name="dateOfBirth"
                                        id={assignObj(`${jointText}DateofBirth${index}`)}
                                        max={isValidDate(startDateMin) ? formatDate(startDateMin) : ''}
                                        label={dateOfBirthLabel}
                                        onChange={this.handleChange(index, 'dateOfBirth')}
                                        onBlur={this.handleBlur(index, 'dateOfBirth')}
                                        errortext={errorMsg.dateOfBirth}
                                        value={dateOfBirth && dateOfBirth.replace(/-/g, '/')}
                                        labelsm={4}
                                        valuesm={5}
                                        noGutters
                                        dobValidation
                                        required
                                    />
                                    <Row
                                        className="Slabel"
                                        role="group"
                                        aria-labelledby={assignObj(`${jointText}gender`)}
                                    >
                                        <span
                                            className="label centarizeLabel"
                                            id={assignObj(`${jointText}gender${index}`)}
                                            style={styles.labelTextStyle}
                                        >
                                            {genderLabel}
                                        </span>
                                        <Col>
                                            <Row>
                                                {genderOp &&
                                                    genderOp.length > 0 &&
                                                    genderOp.map(field => (
                                                        <WRadio
                                                            radioFor={`${field.key}${Object(index)}`}
                                                            key={`${field.key}${Object(index)}`}
                                                            id={`${field.key}${Object(index)}`}
                                                            value={field.value}
                                                            onBlur={this.handleRadioBlur(index, 'gender')}
                                                            onClick={this.handleRadioBlur(index, 'gender')}
                                                            radiodescstyles={radiodescstyles}
                                                            radiostyles={radiostyles}
                                                            radiotext={radioText}
                                                            selected={!!(gender && gender.toString() === field.key)}
                                                        />
                                                    ))}
                                            </Row>
                                            <Row>
                                                {errorMsg.gender && (
                                                    <span
                                                        className="radioError error"
                                                        role="region"
                                                        id="genderError"
                                                        aria-labelledby="genderError"
                                                        aria-live="polite"
                                                        aria-atomic="true"
                                                    >
                                                        {errorMsg.gender}
                                                    </span>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <VCMSelect
                                        id={assignObj(`maritalStatus${index}`)}
                                        name="marital"
                                        label="Marital Status"
                                        itemlist={maritalStatus}
                                        onChange={this.handleChange(index, 'maritalStatus')}
                                        onBlur={this.handleBlur(index, 'maritalStatus')}
                                        value={this.getMaritalStatus(index) || ''}
                                        labelsm={4}
                                        valuesm={5}
                                        errortext={errorMsg.maritalStatus}
                                        noGutters
                                        required
                                    />
                                    <Row
                                        className="Slabel"
                                        role="group"
                                        aria-labelledby={assignObj(`${jointText}citizenship`)}
                                    >
                                        <span
                                            className="label"
                                            id={assignObj(`${jointText}citizenship${index}`)}
                                            style={styles.labelTextStyle}
                                        >
                                            {citizenshipLabel}
                                        </span>

                                        {citizenshipOp &&
                                            citizenshipOp.length > 0 &&
                                            citizenshipOp.map(field => (
                                                <WRadio
                                                    radioFor={`${field.key}${Object(index)}`}
                                                    key={`${field.key}${Object(index)}`}
                                                    id={`${field.key}${Object(index)}`}
                                                    value={field.value}
                                                    onBlur={this.handleRadioBlur(index, 'citizenship')}
                                                    onClick={this.handleRadioBlur(index, 'citizenship')}
                                                    radiodescstyles={radiodescstyles}
                                                    radiostyles={radiostyles}
                                                    radiotext={radioText}
                                                    selected={
                                                        !!(
                                                            citizenship &&
                                                            citizenship
                                                                .toString()
                                                                .replace(/[\s.]/g, '')
                                                                .toLowerCase() === field.key
                                                        )
                                                    }
                                                />
                                            ))}
                                    </Row>
                                    {this.renderSSNComponent(jointSSNData, jointOwner)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    {this.renderAddressAccordion(jointOwner, index, accountType)}
                    <PhoneDetailsCardComponent
                        ref={jointPhoneDetailsRef}
                        isJoint
                        jointText="Joint"
                        primaryPhoneNumber={primaryPhoneNumber}
                        secondaryPhoneNumber={secondaryPhoneNumber}
                        primaryContactTime={primaryContactTime}
                        secondaryContactTime={secondaryContactTime}
                        primaryIsMobile={primaryIsMobile}
                        secondaryIsMobile={secondaryIsMobile}
                        primaryCountryCode={primaryCountryCode}
                        secondaryCountryCode={secondaryCountryCode}
                        contactTimeOp={contactTimeOp}
                        index={index}
                    />
                    <EmailDetailsCardComponent
                        ref={jointEmailAddressRef}
                        isJoint
                        primaryEmailAddress={primaryEmailAddress}
                        secondaryEmailAddress={secondaryEmailAddress}
                        index={index + 20}
                    />
                </div>
            );
        });
    };

    render() {
        const addJointOwnerText = `Add another joint owner`;
        const { showMaxAlert, jointOwners, showDelConfirmDialog } = this.state;
        return (
            <>
                {showDelConfirmDialog && (
                    <ConformationModal
                        modalTitle="Delete Joint Owner Confirmation"
                        modalBodyText="Are you sure you want to delete the Joint Owner information?"
                        primaryButtonText="Delete"
                        secondaryButtonText="cancel"
                        onPrimaryClick={this.removeJointOwner(true)}
                        onSecondaryClick={this.removeJointOwner(false)}
                    />
                )}
                {showMaxAlert && (
                    <StyledAlert variant="danger">
                        <StyledPara>{constants.maxError}</StyledPara>
                    </StyledAlert>
                )}
                {this.renderJointOwner()}

                {jointOwners.length < 2 && (
                    <button
                        onClick={this.addAnotherJointOwner}
                        type="button"
                        className="addAnotherFieldBtn"
                        aria-label={addJointOwnerText}
                    >
                        <WIcon src={plusIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                        {addJointOwnerText}
                    </button>
                )}
            </>
        );
    }
}

JointPersonalInfoCardComponent.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    uploadW8Form: PropTypes.func,
};

JointPersonalInfoCardComponent.defaultProps = {
    masterLookupStateData: {},
    localStateData: {},
    uploadW8Form: () => {},
};

export default JointPersonalInfoCardComponent;
