import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';
import {
    maskEmail,
} from "utils";
import { InstructionalPanel } from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';

const PanelWrap = styled(InstructionalPanel)`
  font: 1em benton-sans;
  cursor: pointer;
`;

const HeaderWrap = styled.div`
  border-bottom: 1px solid #d2d2d2;
  padding: 18px 30px;
  font: 1.125em yorkten-slab-extra-bold;
  color:#004A98;
  text-transform: capitalize;
`;

const BodyWrap = styled.div`
  margin: 18px 30px;
`;

const RelDetails = styled(Col)`
  @media (min-width:992px){  
    border-right: 1px solid #d2d2d2;
    &:last-child {
      border:0;
    }
  }
`;

const OwnerHeader = styled.div`
  font-weight:bold
  margin-bottom: 5px;
`

const RelationshipCard = props => {
  const { onManageRelationshipClick, firstName, lastName, emailId, gender, maritalStatus, familyMembersRelationshipToYou, idKey  } = props;
  const name = `${firstName} ${lastName}`;
  const maskedEmail = maskEmail(emailId);
  const cornerColor = 'blue';

  const relationtoYouFieldId = `vcm-cms-family-relation${idKey}-relationtoyou`;
  const genderFieldId = `vcm-cms-family-relation${idKey}-gender`;
  const emailFieldId = `vcm-cms-family-relation${idKey}-email`;
  const maritalStatusFieldId = `vcm-cms-family-relation${idKey}-marital-status`;

  return (
    <PanelWrap cornerColor={cornerColor} className="p-0 mt-4" onClick={onManageRelationshipClick}>
      <HeaderWrap>{checkValues(name)}</HeaderWrap>
      <BodyWrap>
        <Row>
          <RelDetails lg={4}>
            <OwnerHeader>{CONST.RELATIONSHIP_TO_ACCOUNT_OWNER}</OwnerHeader>
            <div id={relationtoYouFieldId} >{checkValues(familyMembersRelationshipToYou)}</div>
          </RelDetails>
          <RelDetails lg={2}>
            <OwnerHeader>{CONST.GENDER}</OwnerHeader>
            <div id={genderFieldId}>{checkValues(gender)}</div>
          </RelDetails>
          <RelDetails>
            <OwnerHeader>{CONST.EMAIL_ADDRESS}</OwnerHeader>
            <div id={emailFieldId}>{checkValues(maskedEmail)}</div>
          </RelDetails>
          <RelDetails lg={2}>
            <OwnerHeader>{CONST.MARITALSTATUS}</OwnerHeader>
            <div id={maritalStatusFieldId}>{checkValues(maritalStatus)}</div>
          </RelDetails>
        </Row>
      </BodyWrap>
    </PanelWrap>
  );
};

RelationshipCard.propTypes = {
	onManageRelationshipClick : PropTypes.func,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	emailId: PropTypes.string,
	gender: PropTypes.string,
	maritalStatus: PropTypes.string,
  familyMembersRelationshipToYou: PropTypes.string,
  idKey: PropTypes.number,
};

RelationshipCard.defaultProps = {
	onManageRelationshipClick : ()=>{},
	firstName: '',
	lastName: '',
	emailId: '',
	gender: '',
	maritalStatus: '',
  familyMembersRelationshipToYou: '',
  idKey: 1
};

export default RelationshipCard;