import React, { useState, useEffect } from 'react';
import { Accordian, CommonButtons, AccordianTitle, WInput, WRadio, ConfirmModal, WSpinner, WInputCheckBox } from 'common';
import '../MarketNAOStyles.css';
import {Row} from 'react-bootstrap';
import styles from '../../PersonalInfo/styles';
import {assignObj, radiodescstyles, radiostyles, radioText, addBankAccounttext, addexistBankAccounttext,addExistingBankAccounttext,addexistBankAccounttext1, addnewBankAccount, addBankAccountLater,accountTypelabel, accountTypeOptions, isThisJointAccount, isThisJointAccountradioOptions, bankValidationPopupMsg, addBanklaterDesctextpara1, addBanklaterDesctextpara2,bankFundAcknowledmentLink,bankValidationErrorMessage} from './consts'
import {useSelector, useDispatch } from 'react-redux';
import {updateMarketNAOInfo,clearBankAccountInfo,getBankAccountVerificationInfo } from '../../../../shared/Actions/MarketNAOActions';
import {getBrokerageBankAccountList,resetBrokerageAccountListState } from 'shared/Actions/BankAccountAction';
import { encrypt } from 'utils';
import OverLayComponent from 'common/OverLayComponent/OverLayComponent';
import envConfig from 'env/EnvConfig';
import { maskAccountNumber } from 'utils/utils';
import { OK_TEXT } from 'common/Constants';
import { SessionStorageHelper } from 'utils/sessionHelper';

const checkBoxWrapperStyle = {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
};
const checkBoxInputStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #4F799F',
    opacity: 1,
    height: 25,
    width: 25,
    flexShrink: 0,
    marginLeft: 20,
    display: 'inline-block',
};

const checkBoxStyle = { position: 'absolute', top: '10px', display: 'inline' };
const rwdLabelStyle = {
    textAlign: 'left',
    font: '16px/20px benton-sans',
    letterSpacing: 0,
    opacity: 1,
    width: '80%',
    display: 'inline',
    fontWeight: 'normal',
};

export default function AddBankDetails(props) {

    const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);
    const bankAccountInfoList = useSelector(state => state.marketNAOReducerData.bankAccountInfoList);
    const bankAccountInfoListMessage = useSelector(state => state.marketNAOReducerData.bankAccountInfoListMessage);
    const bankAccountInfoListErrorStatus = useSelector(state => state.marketNAOReducerData.bankAccountInfoListErrorStatus);
    const bankAccountDetails = useSelector(state => state.bankAccountReducerData);
    const [state, setStateVariables] = useState(marketNAOInfoReducerData?.marketNAOInfo?.addBankDetails); //allBankList
    const [bankDetails,setBankDetails] = useState(bankAccountDetails?.brokereageBankList?.length > 0 ? bankAccountDetails?.brokereageBankList : marketNAOInfoReducerData?.marketNAOInfo?.addBankDetails?.allBankList)
    const [loadingStatus,setLoadingStatus] = useState(false);
    const dispatch = useDispatch();
    const [compState, setCompState] = useState({nextText: "Next", overLay: false});
    const [counterData,setCounterData] = useState(0);
    const [showModel,setShowModel] = useState(false);
    const [giActResultInfo,setGiActResultInfo] = useState({});
    const [showMSBModal,setShowMSBModal] = useState(false);
    const [brokerageBankListApiSuccess,setBrokerageBankListApiSuccess] = useState(false);
    const isRedirectionFlow = marketNAOInfoReducerData.marketNAOInfo.isRedirectionFlow 

    const initialErrorState = {
        accountNumberError: '',
        routingNumberError: '',
        accountTypeError: '',
        isJointError: ''
      };
      const [formError, setFormError] = useState(initialErrorState);
    
    useEffect(() => {
        dispatch(clearBankAccountInfo())

        if(isRedirectionFlow && state.selectedBankInfo.length > 0 && (state.addExistingBankSelect || state.addNewBankAccountSelect)){
            const bankDetailsData = assignSelectedParameter(state.allBankList, state?.selectedBankInfo)
            
            setBankDetails(bankDetailsData);
            setBrokerageBankListApiSuccess(true)

        }else{
            if(SessionStorageHelper.getItem('msr_dts')){
                dispatch(getBrokerageBankAccountList({
                    "addBankAcctNow": true,
                    "applicationId": marketNAOInfoReducerData?.applicationId
                }));
                setLoadingStatus(true);
            }
        }
       
        // 👇️ scroll to top on page load
        if (!props.TrustedContactVerifyPageHide) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = `Add Bank Page | Victory Capital`;
}
    }, []);

    const isBankcheckboxchecked = () => {
        return bankDetails.some((checkbox) => checkbox.selected);
      };

    const removeSelectedFlag = (data) =>
    {
        const filterData = data?.filter((item)=> item.selected == true);
        const bankDetails = filterData?.map((item=>{
        delete item.selected;
            return {
                ...item
            }
        }))
        return bankDetails;
    }

    const getEncryptedData = async data => {
        return encrypt(data);
    };

    const createPayloadAndSave = async()=>
    {
        const bankData = JSON.parse(JSON.stringify(bankDetails));
        const selectedBankData = removeSelectedFlag(bankData);
        const bankDetailsInfo = JSON.parse(JSON.stringify(selectedBankData));
        let removemaskBank = removeMaskBank(bankDetailsInfo)

        if(state?.addNewBankAccountSelect == true && state?.newBankAccount?.accountNumber && 
            state?.newBankAccount?.routingNumber)
        {
            removemaskBank.push({
                CreatedDate:"",
                SystemModstamp:"",
                accountHolderName:"",
                bankAccountNumber:await getEncryptedData(state?.newBankAccount?.accountNumber),
                bankAccountType:state?.newBankAccount?.accountType,
                bankName:"",
                bankRoutingNumber:await getEncryptedData(state?.newBankAccount?.routingNumber),
                firstName:"",
                lastName:"",
                salesforceRecordId:"",
                registrationType:state?.newBankAccount?.isJointAccount,
                isNew:true
            });
        }

        const addBankDetailsPayload = {
            applicationId: marketNAOInfoReducerData.applicationId,
            pageNumber: 7,
            source: "web",
            addBankAcctNow: ((state?.addNewBankAccountSelect && state?.newBankAccount?.accountNumber != '' && state?.newBankAccount?.routingNumber != '') || state?.addExistingBankSelect) ? true : false,
            bankAccountInfo: {
                brokerageBankList:removemaskBank
            }
        }

        dispatch(getBankAccountVerificationInfo(addBankDetailsPayload));
    }

    const removeMaskBank = (data) =>
    {
        const bankDetails = data?.map((item=>{
        delete item.maskBankName;
        delete item.decryptedBankAccountNumber;
        delete item.decryptedBankRoutingNumber;
            return {
                ...item
            }
        }))
        return bankDetails;
    }
    const exitingBankText=(bankDetails?.length > 0) && addexistBankAccounttext1

    const onInputChange = eventKey => (e) => {
        const targetValue = e.target.value;
        // Validate if the input contains only numbers 0-9
        if (targetValue === '' || /^[0-9]+$/.test(targetValue)) {
            setStateVariables(prevState => ({
                ...prevState,
                newBankAccount: {
                    ...prevState.newBankAccount,
                    [eventKey]: targetValue
                }
            }));
        }
     

    };
  
    const newAccountValidadtion = () => {
        let validFlag = true;
        const newErrors = { ...formError };
      
        const accountNumberValidation = handleAccountNumberBlur();
        const routingNumberValidation = handleRoutingNumberBlur();


        if (!state?.newBankAccount?.accountNumber) {
          newErrors.accountNumberError = bankValidationErrorMessage.accountNumberBlank;
          validFlag = false;
        } else if (!accountNumberValidation.validFlag) {
          newErrors.accountNumberError = accountNumberValidation?.errorMsg;
          validFlag = false;
        } else {
          newErrors.accountNumberError = '';
        }
    
        if (!state?.newBankAccount?.routingNumber) {
          newErrors.routingNumberError = bankValidationErrorMessage.routingNumberBlank;
          validFlag = false;
        } else if (!routingNumberValidation.validFlag) {
          newErrors.routingNumberError = routingNumberValidation?.errorMsg;
          validFlag = false;
        } else {
          newErrors.routingNumberError = '';
        }
      
        if (!state?.newBankAccount?.accountType) {
          newErrors.accountTypeError = bankValidationErrorMessage.accountType;
          validFlag = false;
        } else {
          newErrors.accountTypeError = '';
        }
      
        if (!state?.newBankAccount?.isJointAccount) {
          newErrors.isJointError = bankValidationErrorMessage.jointAccount;
          validFlag = false;
        } else {
          newErrors.isJointError = '';
        }

        
      
        setFormError(newErrors);
      
        return validFlag;
      };

      const handleAccountNumberBlur = () => {
        let validFlag= true
        const newErrors = { ...formError };
        const accountNumber = state?.newBankAccount?.accountNumber;
        if (!/^\d{4,17}$/.test(accountNumber)) {
            newErrors.accountNumberError = bankValidationErrorMessage.accountNumberLength;
            validFlag=false
        }
        else{
            newErrors.accountNumberError = '';
            validFlag=true
        }
        setFormError(newErrors);
        return  {
            validFlag: validFlag,
            errorMsg: bankValidationErrorMessage.accountNumberLength
        }
      };


      const handleRoutingNumberBlur = () => {
        let validFlag= true
        const newErrors = { ...formError};
        const routingNumber = state?.newBankAccount?.routingNumber;
        if (!/^\d{9}$/.test(routingNumber)) {
            newErrors.routingNumberError = bankValidationErrorMessage.routingNumberLength;
            validFlag=false
        }
        else{
            newErrors.routingNumberError = '';
            validFlag=true
        }
        setFormError(newErrors);
        return  {
            validFlag: validFlag,
            errorMsg: bankValidationErrorMessage.routingNumberLength
        }
      };
      
    // on next button click if there are internal step, complete them and later switch next section
    const handleClickNext = () => {
        let validFlag =state?.addNewBankAccountSelect? newAccountValidadtion() : true;
        if (validFlag == true) {
            dispatch(clearBankAccountInfo());
            createPayloadAndSave();
            setCompState(prevState => ({ ...prevState, nextText: "Saving...", overLay: true }));
        }
    };

   
   
    // on back button click if there are internal step, complete them and later switch next section
    const handleClickBack = () => {
        const { clickBack } = props;
        clickBack();
    };


    const radioButtonClick = (eventKey, index) => e => {

        const targetValue = e.target.checked;    
        if(eventKey == 'existingBank')
        {   
            if (state?.addExistingBankSelect) {
            let bankAccountList = bankDetails;
            bankAccountList[index].selected = targetValue;
            setBankDetails(bankAccountList);
            const count = counterData +1;
            setCounterData(count);
            }
        }
        else if(eventKey == "addExistingBankSelect")
        {
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
            let bankAccountList = bankDetails;
            bankAccountList = bankAccountList.map(item => ({ ...item, selected: false }));
            setBankDetails(bankAccountList);
            setCounterData(0);
        }
        else if(eventKey == "accountType")
        {
            setStateVariables(prevState =>({
                ...prevState,
                newBankAccount:{...state['newBankAccount'],
                [eventKey] : index
            }
            }))
        }
        else if(eventKey == "isJointAccount")
        {
            setStateVariables(prevState =>({
                ...prevState,
                newBankAccount:{...state['newBankAccount'],
                [eventKey] : index
            }
            }))
        }
        else if(eventKey == 'addNewBankAccountSelect')
        {
            //  setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
            if (!targetValue) {
                // Checkbox is unchecked,  the input data
                setStateVariables((prevState) => ({
                  ...prevState,
                  [eventKey]: targetValue,
                  newBankAccount: {
                    accountType: '',
                    accountNumber: '',
                    routingNumber: '',
                    isJointAccount: '',
                  },
                }));
                setFormError(prevState => ({
                    ...prevState,
                    accountNumberError: '',
                    routingNumberError: '',
                    accountTypeError: '',
                    isJointError: ''
                    // Add other error fields here if you have more
                  }));
              } else {
                // Checkbox is checked again, do nothing or perform any required action
                setStateVariables((prevState) => ({
                  ...prevState,
                  [eventKey]: targetValue,
                }));
              }
           
         }
        else if(eventKey == 'addBankLaterSelect')
        {
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue, selectedBankInfo:[] }));

            if(targetValue == true)
            {
                setStateVariables(prevState => ({
                    ...prevState,
                    newBankAccount: {
                        ...prevState.newBankAccount,
                        ['accountNumber']: "",
                        ['routingNumber']: "",
                        ['accountType']:"",
                        ['isJointAccount']:false
                    }
                }));
               
            }
        }       
    }


    const assignSelectedParameter = (data,selectedBank) =>
    {
        let selectSalesforceRecordId =[];

        if(selectedBank?.length > 0)
        {
            selectSalesforceRecordId = selectedBank?.map((item=> {
                
                return item?.salesforceRecordId
            }));
        }

        const bankDetails = data?.map((item=>{

            if(selectSalesforceRecordId?.includes(item?.salesforceRecordId) == true )
            {
                return {
                    ...item,
                    selected:true,
                    maskBankName: `${item?.lastName} : ${item?.bankName} : ${maskAccountNumber(item?.decryptedBankAccountNumber)}`,
                    isNew:false
                }
            }
            else
            {
                return {
                    ...item,
                    selected:false,
                    maskBankName: `${item?.lastName} : ${item?.bankName} : ${maskAccountNumber(item?.decryptedBankAccountNumber)}`,
                    isNew:false
                }
            }
        }))

        return bankDetails;
    }

    useEffect(()=>{

        if(bankAccountDetails?.brokereageBankList?.length > 0 && bankAccountDetails?.isBrokerageBankAccountSuccess)
        {
            const bankDetailsData = assignSelectedParameter(bankAccountDetails?.brokereageBankList,state?.selectedBankInfo)
            setBankDetails(bankDetailsData);
            setLoadingStatus(false)
            setBrokerageBankListApiSuccess(true)
        }

        if (bankAccountDetails.isBrokerageBankAccountSuccess == true || bankAccountDetails.isError == true) setLoadingStatus(false)

    },[bankAccountDetails])

    useEffect(()=>{

        if(bankAccountInfoList?.length > 0 || bankAccountInfoListMessage == "Success")
        {
            setLoadingStatus(false)
            setCompState(prevState => ({ ...prevState, nextText: "Saved", overLay: false }));  
            const giActResult = getGiActResult(bankAccountInfoList);  
            setGiActResultInfo(giActResult);
            if(giActResult?.noOfGiactFailBank?.length > 0)
            {
                setShowModel(true)
            }
            else if(giActResult?.noOfMSBBank?.length > 0)
            {
                setShowMSBModal(true);
            }
            else
            {
                saveToLocalStorage(giActResult?.noOfGiactPassBank)
                props.clickNext();
            }
        }
        else if(bankAccountInfoListErrorStatus == true)
        {
            setLoadingStatus(false)
            setCompState(prevState => ({ ...prevState, nextText: "Next", overLay: false }));    
        }
        else if(bankAccountInfoList?.length <= 0){
            setShowModel(false)
        }

    },[bankAccountInfoList,bankAccountInfoListMessage,bankAccountInfoListErrorStatus])

    const abbreviateBankName = (name) => {
        if (name && typeof name === 'string') {
          const words = name.split(' ');
          const bankIndex = words.findIndex(word => word.toLowerCase() === 'bank');
      
          let filteredWords;
          if (bankIndex !== -1) {
            filteredWords = words.slice(0, bankIndex + 1);
          } else {
            filteredWords = words;
          }
      
          const abbreviation = filteredWords.map(word => word.charAt(0)).join('');
          return abbreviation;
        } else {
          return '';
        }
      };

    useEffect(() => {
        if (state?.addBankLaterSelect) {

            setStateVariables(prevState => ({
                ...prevState,
                newBankAccount: { 
                accountNumber:"",
                routingNumber:"",
                accountType:"",
                isJointAccount:"",},
                addExistingBankSelect:false,
                addNewBankAccountSelect:false,
            }));
          const updatedBankDetails = bankDetails.map(item => ({ ...item, selected: false }));
          setBankDetails(updatedBankDetails);

        }
      }, [state?.addBankLaterSelect]);
    
      useEffect(() => {
        if (state.addExistingBankSelect || state.addNewBankAccountSelect) {
          setStateVariables(prevState => ({
            ...prevState,
            addBankLaterSelect: false
          }));
        }
      }, [state.addExistingBankSelect, state.addNewBankAccountSelect]);

      const nextButtonDisabled=()=>{
        let disabled=false
        if(state?.addExistingBankSelect && isBankcheckboxchecked() && !state?.addNewBankAccountSelect){
            disabled=true
        }
        else if(state?.addExistingBankSelect && state?.addNewBankAccountSelect && isBankcheckboxchecked()){
            disabled=true
        }
        else if(!state?.addExistingBankSelect && state?.addNewBankAccountSelect){
            disabled=true
        }
        else if((!state?.addExistingBankSelect && !state?.addNewBankAccountSelect)&&state?.addBankLaterSelect ){
            disabled=true
        }
        return disabled
      }

    const getGiActResult = (bankAccountInfoList)=>
    {
        let noOfGiactFailBank = [];
        let noOfGiactPassBank = [];
        let noOfMSBBank = [];

        for(let i=0; i < bankAccountInfoList?.length; i++){
            const failedValue = (bankAccountInfoList[i]?.giactStatus) ? bankAccountInfoList[i]?.giactStatus?.toLowerCase() : "";
            if(failedValue == "failed")
            {
                noOfGiactFailBank.push(bankAccountInfoList[i]);
            }
            else if(bankAccountInfoList[i]?.msbResponseCode && bankAccountInfoList[i]?.msbResponseCode == '4504')
            { 
                noOfMSBBank.push(bankAccountInfoList[i]); 
            }
            else
            {
                noOfGiactPassBank.push(bankAccountInfoList[i]);
            }
        }
        
        return {
            noOfGiactFailBank:noOfGiactFailBank,
            noOfGiactPassBank:noOfGiactPassBank,
            noOfMSBBank:noOfMSBBank
        }
    }

    const handleConfirmationModel = () =>
    {
        setShowModel(false);
        saveToLocalStorage(giActResultInfo?.noOfGiactPassBank);
        props.clickNext();
    }

    const saveToLocalStorage = (giActPassBankList)=>
    {
        let selectedBankData = JSON.parse(JSON.stringify(giActPassBankList));
        let bankDetailsForLocalStorage = JSON.parse(JSON.stringify(bankDetails));
        const addBankDetailsLocalStoreData = {
            selectedBankInfo:selectedBankData,
            newBankAccount:state.newBankAccount,
            addBankLaterSelect:state.addBankLaterSelect,
            addExistingBankSelect:state?.addExistingBankSelect,
            addNewBankAccountSelect:state?.addNewBankAccountSelect,
            allBankList: bankDetailsForLocalStorage
        }

        // ---------------------------- save to local
        const saveToLocalpayload = {
            sectionToBeUpdated: 'addBankDetails',
            state: addBankDetailsLocalStoreData
        }
        dispatch(updateMarketNAOInfo(saveToLocalpayload));
    }

    const closeMSBModal = ()=>
    {
        setShowMSBModal(false);
    }
    
    return (
        <div className='BankInformation'>
         
            {loadingStatus && <WSpinner loading={loadingStatus} /> }

            {
            compState.overLay && <OverLayComponent />
            }

            {showModel && 
                    
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={bankValidationPopupMsg}
                        primaryButtonText="Continue"
                        onPrimaryClick={handleConfirmationModel}
                    />
            }

            {showMSBModal && 
                <div className='showMSBModal'>
                    <ConfirmModal
                    modalTitle=""
                    modalBodyText={giActResultInfo?.noOfMSBBank?.[0]?.msbResponseMessage}
                    primaryButtonText={OK_TEXT}
                    onPrimaryClick={closeMSBModal}
                    buttonRightAlign={true}
                />
                </div>

            }

            <Accordian
                    accordianPaddingLeft={0}
                    id={AddBankDetails}
                    titleChild={
                        <AccordianTitle
                            accType={'Add Bank Accounts'}
                            className="accordionH2Style"
                            requireButtonPadding={false}
                        />
                    }
                    titleSeperator
                >
                    <div>
                    <Row
                            role="group"
                            aria-labelledby={assignObj(`addNewBankAccount`)}>
                            <span
                                className="label col-sm-12  alignRadioBtnTrustedContact"
                                id={assignObj(`addNewBankAccount`)}
                            >
                                
                                {(bankDetails?.length > 0)  ?  addexistBankAccounttext : addBankAccounttext }
                            </span>
                            <span className="label col-sm-12  alignRadioBtnTrustedContact">{exitingBankText}</span>
                            <div style={{ flexBasis: '100%' }}>
                            {(bankDetails?.length > 0)  &&  
                                        <>
                                        <div className='col-lg-5 col-md-5 mt-3'>
                                                    <WInputCheckBox
                                                        id={`add-newbank-account`}
                                                        checkboxwrapperstyle={checkBoxWrapperStyle}
                                                        checkInputStyle={checkBoxInputStyle}
                                                        checked={state?.addExistingBankSelect}
                                                        value={state?.addExistingBankSelect}
                                                        onChange={radioButtonClick('addExistingBankSelect')}
                                                        checkboxstyle={checkBoxStyle}
                                                        labelstyle={rwdLabelStyle}
                                                        label={addExistingBankAccounttext}
                                                        ariadescribedby="addNewBankAccount"
                                                        data-test="esignCheck"
                                                        ariaHidden={null}
                                                        ariaLabelledby={null}
                                                        ariaRequired
                                                    />
                                                   
                                        </div>
                                        <div className='subsectionbox mt-3'>
                                            <div className='col-lg-7 col-md-7 col-sm-7 offset-sm-1'> 
                                               {brokerageBankListApiSuccess && 

                                                    <> 
                                                    {bankDetails?.map((item,index)=>(
                                                        <>
                                                        <WInputCheckBox
                                                            id={`existingBank${index}`}
                                                            checkboxwrapperstyle={checkBoxWrapperStyle}
                                                            checkboxBackgroungColor={!state?.addExistingBankSelect}
                                                            checkInputStyle={checkBoxInputStyle}
                                                            checked={(state?.addExistingBankSelect) ? item?.selected : false}
                                                            value={item?.bankName}
                                                            onChange={radioButtonClick('existingBank', index)}
                                                            checkboxstyle={checkBoxStyle}
                                                            labelstyle={rwdLabelStyle}
                                                            label={item?.maskBankName}
                                                            ariadescribedby={`existingBank${index}`}

                                                            data-test="esignCheck"
                                                            ariaHidden={null}
                                                            ariaLabelledby={null}
                                                            ariaRequired
                                                        />
                                                    
                                                        </>
                                                    

                                                    ))}
                                                    </>
                                               
                                               }
                                               
                                            </div>
                                        </div>
                                        </>
                                       
                                        }
                                    <div className='col-lg-5 col-md-5 mt-3'>

                                        <WInputCheckBox
                                                id={`addNewBankAccountSelect`}
                                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                                checkInputStyle={checkBoxInputStyle}
                                                checked={state?.addNewBankAccountSelect}
                                                value={state?.addNewBankAccountSelect}
                                                onChange={radioButtonClick('addNewBankAccountSelect')}
                                                checkboxstyle={checkBoxStyle}
                                                labelstyle={rwdLabelStyle}
                                                label={addnewBankAccount}
                                                ariadescribedby="addNewBankAccount"
                                                data-test="esignCheck"
                                                ariaHidden={null}
                                                ariaLabelledby={null}
                                                ariaRequired
                                            />
                                               
                                    </div>

                                    {(state?.addNewBankAccountSelect == true) && 

                                        <div className='subsectionbox newbankbox mt-3'>
                                        <Row className='mb-3'>
                                            <span
                                                className="label alignRadioBtnTrustedContact col-md-3 col-sm-3 offset-sm-1 pl-0"
                                                id={assignObj(`accountType`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {accountTypelabel}
                                            </span>
                                            <span className="col-md-5 col-sm-5 col-offset-1 p-0 marketradioalign">

                                                {accountTypeOptions.map(item => {
                                                    return (
                                                        <WRadio
                                                            radioFor="accountType"
                                                            key={state.accountType + item}
                                                            id={`account-Type-${item}`}
                                                            value={item}
                                                            radiodescstyles={radiodescstyles}
                                                            radiostyles={radiostyles}
                                                            radiotext={radioText}
                                                            selected={state?.newBankAccount?.accountType == item}
                                                            ariaLabel="None"
                                                            ariaDescribedBy="accountType"
                                                            onClick={radioButtonClick('accountType', item)}
                                                        />
                                                    );
                                                })}

                                                    {formError.accountTypeError && (
                                                    <div
                                                        className="errorMsgInputStyle errorSameAddressDiv"
                                                        style={{ width: '100%', float: 'left' }}>
                                                        <div id="errGender">{formError.accountTypeError}</div>
                                                    </div>
                                                )}
                                            </span>
                                            
                                        </Row>

                                        <Row>
                                            <WInput
                                                label="Account Number"
                                                arialabel="AccountNumber"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`AccountNumber`)}
                                                name="accountNumber"
                                                type="text"
                                                value={state?.newBankAccount?.accountNumber}
                                                labelmd={3}
                                                labelsm={{span:3,offset:1}}
                                                valuesm={5}
                                                maxlength={17}
                                                inputfieldstyle={assignObj({ paddingLeft: '15px' })}
                                                onChange={onInputChange('accountNumber')}
                                                onBlur={handleAccountNumberBlur}
                                                errortext={formError.accountNumberError}
                                            />
                                        </Row>
                                        <Row>
                                        <WInput
                                                label="Routing Number"
                                                arialabel="RoutingNumber"
                                                aria-required
                                                required
                                                inputclassname=""
                                                id={assignObj(`RoutingNumber`)}
                                                name="routingNumber"
                                                type="text"
                                                value={state?.newBankAccount?.routingNumber}
                                                labelmd={3}
                                                labelsm={{span:3,offset:1}}
                                                valuesm={5}
                                                maxlength={9}
                                                onChange={onInputChange('routingNumber')}
                                                onBlur={handleRoutingNumberBlur}
                                                errortext={formError.routingNumberError}
                                            />
                                        </Row>
                                        <Row className='mb-3'>
                                            <span
                                                className="label alignRadioBtnTrustedContact col-md-3 col-sm-3 offset-sm-1 pl-0"
                                                id={assignObj(`isThisJointAccount`)}
                                                style={styles.labelTextStyle}
                                            >
                                                {isThisJointAccount}
                                            </span>
                                            <span className="col-md-5 col-sm-5 col-offset-1 p-0 marketradioalign">

                                                {isThisJointAccountradioOptions.map(item => {
                                                    return (
                                                        <WRadio
                                                            radioFor="isThisJointAccount"
                                                            key={state.isThisJointAccount + item}
                                                            id={`isThisJoint-Account-${item}`}
                                                            value={item}
                                                            radiodescstyles={radiodescstyles}
                                                            radiostyles={radiostyles}
                                                            radiotext={radioText}
                                                            selected={state?.newBankAccount?.isJointAccount == item}
                                                            ariaLabel="None"
                                                            ariaDescribedBy="isThisJointAccount"
                                                            onClick={radioButtonClick('isJointAccount', item)}
                                                        />
                                                    );
                                                })}

                                                {formError.isJointError && (
                                                    <div
                                                        className="errorMsgInputStyle errorSameAddressDiv"
                                                        style={{ width: '100%', float: 'left' }}>
                                                        <div id="errGender">{formError.isJointError}</div>
                                                    </div>
                                                )}
                                            </span>
                                        </Row>

                                        </div>
                                    
                                    }                                        
                            </div>
                    </Row>
                    
                               
                    <Row>
                        <div className='col-lg-4 col-md-4 mt-3'>
                                                
                            <WInputCheckBox
                                id={`addBankLaterSelect`}
                                checkboxwrapperstyle={checkBoxWrapperStyle}
                                checkInputStyle={checkBoxInputStyle}
                                checked={state?.addBankLaterSelect}
                                value={state?.addBankLaterSelect}
                                onChange={radioButtonClick('addBankLaterSelect')}
                                checkboxstyle={checkBoxStyle}
                                labelstyle={rwdLabelStyle}
                                label={addBankAccountLater}
                                ariadescribedby="addBankLaterSelect"
                                data-test="esignCheck"
                                ariaHidden={null}
                                ariaLabelledby={null}
                                ariaRequired
                            />
                                            
                        </div>
                        <div className='subsectionbox mt-2 col-lg-12 col-md-12'>
                            <p>
                                {addBanklaterDesctextpara1}
                                <br/><br/>
                            <b> {addBanklaterDesctextpara2}
                            <a 
                            href={`${envConfig.ENV_URL}/content/dam/vcm/brokerage/Bank-Account-and-Funding-Authorization.pdf`} 
                            target="_blank"
                            rel="noopener noreferrer"
                            style={assignObj({ display: 'inline-block',color: '#004a98', textDecoration: 'underline' })}
                                >
                                {bankFundAcknowledmentLink}
                                </a></b>
                            </p>
                        </div>
                    </Row>
                                        
                               

                                       

                                   
                              
                    </div>
                </Accordian>
        

        <div><CommonButtons 
        backClick={handleClickBack} 
        nextClick={handleClickNext} 
        isShowBtn={true} 
        nextText={compState.nextText}
        disabled={nextButtonDisabled()? false : true}
         /></div>
        </div>
    )
}

