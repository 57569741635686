import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";

export const getMemberPreferences = (payload) => (dispatch) => {
    const env = '';

    dispatch({
        type: ActionTypes.GET_MEMBER_PREFERENCES_LOADING,
    });
    makeApiRequest(
        ActionTypes.GET_MEMBER_PREFERENCES,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_MEMBER_PREFERENCES_SUCCESS,
                    payload:response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_MEMBER_PREFERENCES_FAILURE,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_MEMBER_PREFERENCES_FAILURE,
                payload: error
            });
        }
    );
};


export const getMemberMSRPreferences = (payload) => (dispatch) => {
    const env = '';

    console.log('action call ',payload)
    // dispatch({
    //     type: ActionTypes.GET_MEMBER_PREFERENCES_LOADING,
    // });
    makeApiRequest(
        ActionTypes.GET_MEMBER_MSR_PREFERENCES,
        payload,
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.GET_MEMBER_MSR_PREFERENCES_SUCCESS,
                    payload:response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.GET_MEMBER_MSR_PREFERENCES_FAILURE,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.GET_MEMBER_MSR_PREFERENCES_FAILURE,
                payload: error
            });
        }
    );
};


export const changeMemberPreferences = data => dispatch => {
    dispatch({
        type: ActionTypes.CHANGE_MEMBER_PREFERENCES,
        payload: data
    });
};

export const saveData = () => (dispatch, getState) => {
    const { memberPreferencesData } = getState();
    const env = '';

    dispatch({
        type: ActionTypes.UPDATE_MEMBER_PREFERENCES_LOADING,
    });

    makeApiRequest(
        ActionTypes.UPDATE_MEMBER_PREFERENCES,
        {
            ...memberPreferencesData
        },
        env,
        response => {
            if (response.data && response.data.result) {
                dispatch({
                    type: ActionTypes.UPDATE_MEMBER_PREFERENCES_SUCCESS,
                    payload:response.data.result
                });
            } else {
                dispatch({
                    type: ActionTypes.UPDATE_MEMBER_PREFERENCES_FAILURE,
                });
            }
        },
        error => {
            dispatch({
                type: ActionTypes.UPDATE_MEMBER_PREFERENCES_FAILURE,
                payload: error
            });
        }
    );
};

// CLEAR REDUX
export const clearReduxData = (stateKey, obj) => (dispatch) => {
    dispatch({
        type: ActionTypes.CLEAR_REDUX_DATA,
        [stateKey]: obj
    });
};

export const resetAPIError = () => (dispatch) => {
    dispatch({
        type: ActionTypes.RESET_API_ERROR_MEMBER_PREFERENCES
    });
};

