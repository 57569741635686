import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { localStateManagementActions, msrActions, loginActions } from '../../shared/Actions';
import MsrSsoCallback from './MsrSsoCallback';

const mapStateToProps = (state) => ({
    localStateData: state.localStateManagementReducerData,
    redirectFlag : state.msrFlowDetails,   
    loginData: state.loginData

});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...msrActions,   
    ...loginActions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MsrSsoCallback));
