const routeConstants = {
    registerEmail: '/register-email',
    verifyEmail: '/verify-email',
    registerOnlineId: '/register-online-id',
    registerPassword: '/register-password',
    personalDetails: '/personal-details',
    verifySecurityDetails: '/verify-security-details',
    regSuccess: '/success',
    signIn: '/sign-in',
    msrSso:'/msrsso',
    msrSsoCallback:'/msrSsoCallback',
    otpAuth: '/otp-auth',
    confirmOtp: '/confirm-otp',
    setupSecurityQuestions: '/setup-security-questions',
    confirmSecurityAnswers: '/confirm-security-questions',
    signInBySoftToken: '/sign-in-by-soft-token',
    recoverOnlineId: '/recover-online-id',
    retrievePassword: '/retrieve-password',
    checkInbox: '/check-inbox',
    jointOwnerVerificationSsn: '/joint-owner-verification-ssn',
    jointOwnerVerificationMobile: '/joint-owner-verification-mobile',
    jointOwnerVerificationTerms: '/jov-terms-and-conditions',
    jointOwnerVerificationOnlineId: '/jov-register-online-id',
    jointOwnerVerificationPassword: '/jov-register-password',
    otpIvrSelect: '/otp-ivr-select',
    verifyOtp: '/verify-otp',
    createNewPassword: '/create-new-password',
    deliveryPreference: '/delivery-preference',
    termsAndConditions: '/termsAndConditions',
    securityPreferences: '/SecurityPreferences',
    changeSignInCredentials: '/ChangeSignInCredentials',
    changeSignInMethodOptions: '/ChangeSignInMethodOptions',
    changeSignInByOTP: '/ChangeSignInByOTP',
    changeSignInBySoftToken: '/ChangeSignInBySoftToken',
    resetPassword: '/reset-password',
    createPassword: '/create-password',
    changeSignInByPushNotification: '/ChangeSignInByPushNotification',
    mobileQuickSignIn: '/mobile-quick-sign-in',
    modifySecurityQns: '/modify-security-qns',
    home: '/',
    messageCenter: '/messageCenter',
    createSecureMessage: '/messageCenter/createSecureMessages',
    documentCenter: '/documentCenter',
    accessForms: '/accessForms',
    forms: '/forms',
    portfolioPlannerPdf: '/portfolioPlannerPdf',
    transactionDashboard: '/transactionDashboard',
    transactionPurchases: '/transactionPurchases',
    purchaseInvestmentSelection: '/purchaseInvestmentSelection',
    purchaseFundSource: '/purchaseFundSource',
    purchaseReviewConfirm: '/purchaseReviewConfirm',
    terms: '/terms',
    openAccount: '/openAccount',
    accountType: '/accountType',
    personalInfo: '/personalInfo',
    fundSelection: '/fundSelection',
    investmentSelection: '/investmentSelection',
    compareFunds: '/compareFunds',
    fundSummary: '/fundSummary',
    summary: '/summary',
    performance: '/performance',
    quickFacts: '/quickFacts',
    preferences: '/preferences',
    verify: '/verify',
    esign: '/esign',
    saveApplication: '/saveApplication',
    oops: '/oops',
    servererror: '/servererror',
    esa: '/esa',
    application: '/application',
    personalInfoChild: '/personalInfoChild',
    beneficiaryInfoChild: '/beneficiaryInfoChild',
    editPreference: '/editPreference',
    accountPreference: '/accountPreference',
    taxDocuments: '/taxDocuments',
    interestedParties: '/interestedParties',
    addInterestedParty: '/addInterestedParty',
    refreshFinance: '/refreshFinance',
    confirmAccountOpening: '/confirmAccountOpening',
    manageBeneficiaries: '/manageBeneficiaries',
    manageBeneficiariesVerify: '/manageBeneficiariesVerify',
    manageBeneficiariesAccounts: '/manageBeneficiariesAccounts',
    addPrimaryBeneficiary: '/addPrimaryBeneficiary',
    specialtyAccountApplicationForms: '/specialtyAccountApplicationForms',
    addContingentBeneficiary: '/addContingentBeneficiary',
    agreement: '/agreement',
    privacy: '/privacy',
    paperApplication: '/paperApplication',
    uploadOrMail: '/uploadOrMail',
    profile: '/Profile',
    basicinformation: '/basicinformation',
    personalInformation: '/personalInformation',
    relationshipPersonalInfo: '/relationshipPersonalInfo',
    contactInfo: '/contactInfo',
    relationshipContactInfo: '/relationshipContactInfo',
    addContactInfo: '/addContactInfo',
    phoneInfo: '/phoneInfo',
    financialInfo: '/financialInfo',
    empInfo: '/empInfo',
    militaryInfo: '/militaryInfo',
    emailInfo: '/emailInfo',
    relationshipemailInfo: '/relationshipemailInfo',
    addEmailInfo: '/addEmailInfo',
    relationshipAddEmailInfo: '/relationshipAddEmailInfo',
    addPhoneNum: '/addPhoneNum',
    addRelations: '/addRelations',
    manageRelations: '/ManageRelations',
    addAccountAddress: '/addAccountAddress',
    marketingAndPrivacy: '/MarketingAndPrivacy',
    deliverySettings: '/deliverySettings',
    accountmessaging: '/accountmessaging',
    generaldocuments: '/generaldocuments',
    securityFraudalerts: '/security&fraudalerts',
    devicemanagement: '/devicemanagement',
    editEmail: '/editEmail',
    relationshipeditEmail: '/relationshipeditEmail',
    editPhone: '/editPhone',
    editRelations: '/editRelations',
    editContact: '/editContact',
    relationshipEditContact: '/relationshipEditContact',
    relationshipAddContact: '/relationshipAddContact',
    editAccountAddress: '/editAccountAddress',
    accountservices: '/accountservices',
    automaticinvestment: '/automaticinvestment',
    editautomaticinvestment: '/editautomaticinvestment',
    editsystematicwithdrawal: '/editsystematicwithdrawal',
    systematicwithdrawal: '/systematicwithdrawal',
    checkbook: '/checkbook',
    dividends: '/dividends',
    bankaccounts: '/bankaccounts',
    addaccounts: '/addaccounts',
    exchangeSucces: '/exchangeSucces',
    amendTransaction: '/amendTransaction',
    amendProceed: '/amendProceed',
    amendCancel: '/amendCancel',
    liquidation: '/liquidation',
    exchange: '/exchange',
    accountguestview: '/accountguestview',
    accountholder: '/accountholder',
    accountholderTransactions: '/accountholder/transactions',
    accountholderActivity: '/accountholder/activity',
    calculators: '/calculators',
    retirementPlanCalculator: '/retirementPlanCalculator',
    collegeSavingsCalculator: '/collegeSavingsCalculator',
    traditionalIRACalculator: '/traditionalIRACalculator',
    retirementNestEggCalculator: '/retirementNestEggCalculator',
    rmdFutureCalculator: '/rmdFutureCalculator',
    rmdCurrentCalculator: '/rmdCurrentCalculator',
    testingLinks: '/testingLinks',
    FAQs: '/FAQs',
    retirementplans: '/retirementplans',
    createAutomaticInvestment: '/createautomaticinvestment',
    createSystematicWithdrawal: '/createsystematicwithdrawal',
    accountRegistration: '/accountRegistration',
    accountRegistrationUserDetails: '/accountRegistration/userdetails',
    accountRegistrationTC: '/accountRegistration/termsandconditions',
    accountRegistrationOTL: '/accountRegistration/onetimecode',
    accountRegistrationOTV: '/accountRegistration/onetimecodeverify',
    accountRegistrationUserId: '/accountRegistration/enterUserId',
    accountRegistrationPass: '/accountRegistration/enterPassword',
    accountRegistrationSuccess: '/accountRegistration/Success',
    accountRegistrationSignOut: '/accountRegistration/Signout',
    reviewPersonalDetails: '/reviewPersonalDetails',
    manageAddressDetails: '/manageAddressDetails',
    reviewPersonalInfo: '/reviewPersonalInfo',
    memberSignUp: '/memberSignUp',
    userDetails: '/userDetails',
    memberDobValidation: '/memberDobValidation',
    manageRelationPhone: '/managerelationphone',
    relationPhoneAdd: '/relationPhoneAdd',
    relationPhoneEdit: '/relationPhoneEdit',
    marstoneToNAO: '/redirectToNAO',
    requiredMinimumDistribution: '/requiredMinimumDistribution',
    addRequiredMinimumDistribution: '/addRequiredMinimumDistribution',
    editRequiredMinimumDistribution: '/editRequiredMinimumDistribution',
    rmdDiY: '/rmddiy',
    msr: '/msr',
    msrlookup: '/msrlookup',
    systematicwithdrawal: '/systematicwithdrawal',
    marketPersonalInfo: '/marketPersonalInfo',
    marketAccountType:'/marketAccountType',
    marketOpenAccount:'/marketOpenAccount',
    redirectToBrokerageNAO:'/redirectToBrokerageNAO',
    uploadPendingDocuments: '/uploadPendingDocuments',
    manageMarketTransaction: '/manageMarketTransaction',
    ConfirmationFile:'/ConfirmationFile',
};

Object.freeze(routeConstants);

export const preLoginRoutes = [
    routeConstants.registerEmail,
    routeConstants.verifyEmail,
    routeConstants.registerOnlineId,
    routeConstants.registerPassword,
    routeConstants.regSuccess,
    routeConstants.personalDetails,
    routeConstants.verifySecurityDetails,
    routeConstants.signIn,
    routeConstants.msrSso,
    routeConstants.msrSsoCallback,
    routeConstants.otpAuth,
    routeConstants.confirmOtp,
    routeConstants.setupSecurityQuestions,
    routeConstants.confirmSecurityAnswers,
    routeConstants.signInBySoftToken,
    routeConstants.recoverOnlineId,
    routeConstants.retrievePassword,
    routeConstants.checkInbox,
    routeConstants.jointOwnerVerificationSsn,
    routeConstants.jointOwnerVerificationMobile,
    routeConstants.jointOwnerVerificationTerms,
    routeConstants.jointOwnerVerificationOnlineId,
    routeConstants.jointOwnerVerificationPassword,
    routeConstants.verifyOtp,
    routeConstants.otpIvrSelect,
    routeConstants.createNewPassword,
    routeConstants.deliveryPreference,
    routeConstants.termsAndConditions,
    routeConstants.home,
    routeConstants.reviewPersonalInfo,
    routeConstants.memberSignUp,
    routeConstants.termsnConditions,
    routeConstants.userDetails,
    routeConstants.reviewPersonalDetails,
    routeConstants.manageAddressDetails,
    // routeConstants.messageCenter,
    // routeConstants.createSecureMessage,
    routeConstants.memberDobValidation,
    routeConstants.msr,    
    routeConstants.marketPersonalInfo,
    routeConstants.marketAccountType,
    routeConstants.marketOpenAccount,
    //routeConstants.ConfirmationFile,
   // routeConstants.redirectToBrokerageNAO,
    //routeConstants.uploadPendingDocuments,
];

export default routeConstants;
