/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component returns the online payment method details
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import MaskText from '../../../common/MaskText/MaskText';
import consts from './constants';
import './confirmAccountOpening.css';
import AccGlobalConstants from '../AccountManagementConstants';

const OnlineTransferComponent = props => {
    const { heading, paymentDetails } = props;
    const { otherBankState } = paymentDetails || {};
    const { bankAccountDetails, bankAccountType } = otherBankState || {};
    const { onlineTransferHintText, bankAccountTypeLabel } = consts;
    const { assignObj } = AccGlobalConstants;
    return (
        <div className="dividendHint onlineTransferTypecard">
            <Row noGutters className="sectionHeader">
                <Col xs={12}>
                    <h3 className="sectionHeading">{heading}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={8} md={8}>
                    <Row>
                        <Col xs={6} className="onlineTransferLabel">
                            <span>{bankAccountTypeLabel}</span>
                        </Col>
                        <Col xs={6} className="onlineTransferValue">
                            <span>{bankAccountType}</span>
                        </Col>
                    </Row>
                    {bankAccountDetails &&
                        bankAccountDetails.map((data, index) => {
                            return (
                                <Row key={assignObj(`${data.label}-${index}`)}>
                                    <Col xs={6} className="onlineTransferLabel">
                                        <span>{data.label || ''}</span>
                                    </Col>
                                    <Col xs={6} className="onlineTransferValue">
                                        {data.name &&
                                            data.name !== 'accountNumber' &&
                                            data.name !== 'transitRoutingNumber' && <span>{data.value || ''}</span>}
                                        {data.name &&
                                            (data.name === 'accountNumber' || data.name === 'transitRoutingNumber') && (
                                                <MaskText text={data.value.toString()} startIndex={0} maskLength={6} />
                                            )}
                                    </Col>
                                </Row>
                            );
                        })}
                </Col>
            </Row>
            <Row>
                <div className="dividendHint onlineConfirmationcard">
                    <p className="onlineTransferHintTextStyle">{onlineTransferHintText}</p>
                </div>
            </Row>
        </div>
    );
};

OnlineTransferComponent.propTypes = {
    heading: PropTypes.string,
    paymentDetails: PropTypes.instanceOf(Object),
};

OnlineTransferComponent.defaultProps = {
    heading: 'heading',
    paymentDetails: {},
};

export default memo(OnlineTransferComponent);
