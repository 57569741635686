const styles = {
    downloadLabelMargins: {
        paddingLeft: 15,
        marginBottom: 40,
    },
    submitButtonAlign: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    greyStyledText: {
        color: 'grey',
    },
    subHeading: {
        font: '600 16px/22px benton-sans',
        color: '#49494A',
        marginBottom: 30,
    },
    downloadImage: {
        marginRight: 30,
    },
    errorStyle: {
        left: 5,
    },
    labelStyle: { font: '600 16px/22px benton-sans', padding: 0 },
    inputStyle: {
        marginBottom: 20,
    },
    errorInputStyle: {
        marginBottom: 55,
    },
};

export default styles;
