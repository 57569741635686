import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const PlanLabelH1 = styled.h1`
    color: rgba(72, 109, 144, 1);
    font: 800 30px/36px yorkten-slab-normal;
    border-bottom: 2px solid #8bc105;
    height: fit-content;
    padding-bottom: 10px;
    width: 450px;
`;

const DescriptionP = styled.p`
    margin-top: 50px;
    font-size: 16px;
    text-align: justify;
`;

const CalculatorContainerCol = styled(Col)`
    padding-right: 30px;
`;

const ShareLabelRow = styled(Row)`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    display: block;
`;

const IconsRow = styled(Row)`
    padding-bottom: 40px;
    border-bottom: 1px solid gray;
    margin-bottom: 70px;
`;

export { PlanLabelH1, DescriptionP, CalculatorContainerCol, ShareLabelRow, IconsRow };
