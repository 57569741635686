import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import consts from '../../modules/TransactionModule/Purchase/PurchaseSelection/consts';
import WButton from '../WButton/WButton';

const TitleSeperator = styled.hr`
    margin-top: 0px;
    margin-bottom: 1.875rem;
    border-top: 1px solid rgba(210, 210, 210, 1);
`;
const DefaultAccordion = styled(Accordion)`
    margin-bottom: 1.5rem;
`;
const AccordianSubTitle = styled.div`
    @media (max-width: 767px){
        font-size: 14px;
        padding-left: 30px;
    }
    font-weight: 600;
    padding-left: 0.9375rem;
    margin-bottom: 10px;
`;
const cardHeadreClick = e => {
    e.preventDefault();
    const element = e.currentTarget.querySelector('button');
    element.classList.toggle('expand');
    if(element.classList.contains('expand')) {
        element.setAttribute('aria-expanded', false)
    } else{
        element.setAttribute('aria-expanded', true)
    }
};

const WAccordian = React.forwardRef((props, ref) => {
    const {
        children,
        titleChild,
        titleSeperator,
        accordionTitleAs,
        onAccordianClick,
        openBydefault,
        btnProps,
        filterBtnReq,
        className,
        labelledBy,
        Id,
        mB,
        transactionSubTitle,
        transactionHrStyle
    } = props;
    
    const {
        active = false,
        block = false,
        filterFunds = {},
        disabled = false,
        type = 'button',
        variantCancel = 'light',
        compareFunds = {},
        btnStyleNext = {},
    } = btnProps;

    function onclick(e) {
        cardHeadreClick(e);
        onAccordianClick(e);
    }
    let invstSel = null;
    if (filterBtnReq) {
        invstSel = (
            <div>
                <p>Select ONE fund from the list below. Use the filter to narrow the list</p>
                <div>
                    <WButton
                        active={active}
                        block={block}
                        style={consts.btnStyle}
                        buttontext={filterFunds}
                        disabled={disabled}
                        type={type}
                        variant={variantCancel}
                    />
                    <WButton
                        active={active}
                        block={block}
                        style={consts.btnStyleNext}
                        buttontext={compareFunds}
                        disabled={disabled}
                        type={type}
                        variant={variantCancel}
                        buttonstyle={btnStyleNext}
                    />
                </div>
            </div>
        );
    }

    return (
        <DefaultAccordion className={className} ref={ref} defaultActiveKey={openBydefault && '0'}>
            <Accordion.Toggle as={accordionTitleAs}  onClick={onclick} eventKey="0">
                {titleChild}
            </Accordion.Toggle>
            {transactionSubTitle && <AccordianSubTitle>{transactionSubTitle}</AccordianSubTitle>}
            {invstSel}
            {titleSeperator && <TitleSeperator aria-hidden="true"   mB={mB} style={transactionHrStyle}/>}
            <Accordion.Collapse eventKey="0" aria-labelledby={labelledBy} id={Id} role="region" >
                {children}
            </Accordion.Collapse>
        </DefaultAccordion>
    );
});

WAccordian.propTypes = {
    children: PropTypes.node.isRequired,
    titleChild: PropTypes.node.isRequired,
    accordionTitleAs: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    titleSeperator: PropTypes.bool,
    onAccordianClick: PropTypes.func,
    openBydefault: PropTypes.bool,
    filterBtnReq: PropTypes.bool,
    active: PropTypes.bool,
    btnProps: PropTypes.instanceOf(Object),
    className: PropTypes.string,
    labelledBy: PropTypes.string,
    Id: PropTypes.string
};

WAccordian.defaultProps = {
    titleSeperator: false,
    accordionTitleAs: 'div',
    onAccordianClick: () => {},
    openBydefault: true,
    filterBtnReq: false,
    btnProps: {},
    active: false,
    className: '',
    labelledBy: '',
    Id: ''
};

export default WAccordian;
