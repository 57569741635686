import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

export const manageLogoutState = data => dispatch => {
    // debugger;

    dispatch({
        type: ActionTypes.WEB_ACCOUNT_LOGOUT_STATE,
        ...data,
    });
};

export const clearLogoutState = data => dispatch => {
    dispatch({
        type: ActionTypes.WEB_ACCOUNT_CLEAR_LOGOUT_STATE,
        ...data,
    });
};

// export default manageLogoutState

