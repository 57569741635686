// Label constants
export const NO_ACCOUNT_SELECTED_ERROR_TEXT = 'Please select any Account Name to proceed further';
export const ACCOUNT_NAME = 'Account Name';
export const ACCOUNT_NUMBER = 'Account Number';
export const USAA_FUND_COMPANY_NUMBER = { companyNumber: '591' };
export const LIQ_TEXT = 'liq';
export const DOLLAR = '$';
export const WIRE_TRANSFER_MSG1 = ' The transaction will be processed on confirmation of wire transfer details.';
export const WIRE_TRANSFER_MSG2 =
    ' Please take Printout and mail to the address given on the right to initiate "Wire Transfer"';
export const WIRE_TRANSFER_MSG3 = 'Please wait for 3 to 5 business days for completion of your transaction.';
export const WIRE_TRANSFER_MAILING_ADDRESS_HEADING = 'Mailing Address';
export const WIRE_TRANSFER_MAILING_ADDRESS_TEXT =
    'Mellon Trust of New England \n 1 Boston Place \n Boston, Massachusetts 02108';
export const HEADING_TEXT = 'Heading';
export const PERSONAL_CHECK_MSG1 =
    'Please make the check payable to USAA Mutual Funds and include the account number of the mutual fund in the memo section. ';
export const PERSONAL_CHECK_MSG2 = 'Note: We do not accept 3rd party checks, traveler’s checks and money orders.';
export const PERSONAL_CHECK_MSG3 =
    'The transaction will be processed only after receiving the check. Please wait for 3 to 5 business days for completion of your transaction.';
export const SPECIMEN = 'Specimen';
export const ACCOUNT_TYPE_TEXT = 'Account Type';
export const OK_TEXT = 'Ok';
export const INSTRUCTIONS_TEXT = 'Please follow the instructions below';
export const NEXT_TEXT = 'Next';

export const TRADING_MSG_REVIEW_WARNING =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You must place your order prior to 3:59 pm ET to be sure your order gets today’s Net Asset Value';

export const NO_ACCOUNTS_MESSAGE = 'Currently you do not have any accounts to be displayed';

export const USAA_WIRE_TRANSFER_INSTRUCTIONS_HEADING = 'USAA Mutual Funds Account Wire In Instructions';
export const DOMESTIC_TEXT = 'Domestic';
export const ABA_TEXT = 'ABA# ';
export const ABA_NUMBER = '011001234';
export const ATTENTION_TEXT = 'Attention:';
export const USAA_FUND_NAME_TEXT = 'Fund Name: ';
export const ACCOUNT_NAME_TEXT = 'Account Name: ';
export const ACCOUNT_NUMBER_TEXT = 'Account Number';
export const USAA_ACCOUNT_NAME = 'USAA Mutual Funds';
export const USAA_ACCOUNT_NUMBER_TEXT = 'Account Number -';
export const USAA_ACCOUNT_NUMBER = '56-0944';
export const BENEFIT_OF_TEXT = 'For the benefit of:';
export const NAME_TEXT = 'Name: ';
export const USAA_MUTUAL_FUND_ACCOUNT_NUMBER = 'Mutual Fund Account Number: ';

export const LIQ_ADDRESS_CHANGE_TEXT =
    'Your account address has recently been changed. To ensure the security of your account, you will not be able to receive a check for this order';

export const TRADE_TYPE_TEXT = 'Trade Type';
export const SELL_TEXT = 'Sell';
export const PERCENTAGE_SYMBOL = '%';
export const MONEY_MARKET = 'money market';
export const DECREASE = 'decrease';
export const INCREASE = 'increase';
export const INCREASE_IN_PERCENTAGE_TEXT = 'Increase in percentage';
export const DECREASE_IN_PERCENTAGE_TEXT = 'Decrease in percentage';
export const OPEN_PARENTHESES = '(';
export const CLOSE_PARENTHESES = ')';

export const AIP ='AIP';
export const APP_VALUES = {
    CompanyNumber : "591"
}

export const TRANSACTION_TYPE = {
    Buy : "Buy",
    Sell : "Sell",
    Exchange : "Exchange"
}