export const MESSAGE_CENTER_ROUTE ='/messageCenter';
export const CREATE_SECURE_MESSAGES = 'Create Secure Message';
export const CREATE_SECURE_REPLY = 'Reply';

export const ALL_FILED_REQUIRED = '* All fields are requried except mentioned optional';

export const CONFIRMATION_MODAL = {
    TITLE: 'Are you sure?',
    BODY: 'Changes you made will not be saved.',
    PRIMARY_BUTTON: 'Ok',
    SECONDARY_BUTTON: 'Cancel',
};

export const FORMS = {
    TOPIC: 'Topic',
    ACCOUNTS: 'Accounts',
    SUBJECT: 'Subject',
    MESSAGE_BODY: 'Message Body',
};

export const FORM_VALIDATION = {
    TOPIC: 'Please select Topic',
    ACCOUNTS: 'Please select Accounts',
    SUBJECT: 'Please enter Subject',
    SUBJECT_MIN:'Please enter minimum 2 characters',
    SUBJECT_MAX:'Subject can not be more than 50 characters',
    MESSAGE_BODY: 'Please enter Message Body',
    MESSAGE_BODY_MIN: 'Please enter minimum 5 characters',
    MESSAGE_BODY_MAX: 'Message Body can not be more than 500 characters',
};

export const FILE_INFO = {
    ATTACHMENT_LIMIT: 'Attachment limit',
    SIZE_LIMIT: 'Size limit per file',
    TOTAL_LIMIT: 'Total size limit',
    FILE_TYPE: 'File types allowed',
    COLON: ':',
};

export const FILE_OUTPUT = {
    ATTACHMENT_OUTPUT: '10 files',
    SIZE_OUTPUT: '5 MB (megabytes)',
    TOTAL_SIZE_OUTPUT: '20 MB (megabytes)',
    FILE_TYPE_OUTPUT: 'PDF, JPG, JPEG, GIF, BMP, PNG, XLS, XLSX, DOC, DOCX',
};

export const FILE_UPLOADER_TEXT = {
    FILE_MESSAGE: 'Drop your files here',
    OR_TEXT: '[or]',
    FILE_SELECT: 'Select Files',
    BYTES: 'bytes',
    OPTIONAL: 'Attachments [optional]',
};

export const TEXT = {
    DASH: '-',
    MBYTE: 'MB',
    DELETE: 'Delete',
};

export const FILE_UPLOAD_VALIDATION = {
    ATT: 'Attachment',
    OPTIONAL: '[optional]',
    REJECTED_FILES: 'Rejected Files, sorry!',
    ACCEPTS: '.PDF, .JPG, .JPEG, .GIF, .BMP, .PNG, .XLSX,.XLS, .DOC, .DOCX',
    FILE_SIZE_BYTES: 1048576,
    FILE_MAX_SIZE_BYTES: 5242880,
    FILE_TO_LARGE: '( File too large )',
    FILE_MORE_THAN_TEN: "( Can't attach more than 5 files )",
    VALID_FILE_TYPES: ['.PDF', '.JPG', '.JPEG', '.GIF', '.BMP', '.PNG', '.XLSX', '.XLS', '.DOC', '.DOCX'],
    FILE_TYPE_NOT_SUPPORTED: '( File type not supported )',
};
