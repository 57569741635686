import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    isDelete: false,
    accountsOpened: [],
    eligibility: {},
    error: {},
    selectedPlanInfo: {},
    stateCodeGroup: '',
    groupStateTax:{},
    age: 0,
    stateForStateTax: '',
    customerName: '',
};

function rmdReducerData(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action,
            };
        }

        case ActionTypes.GET_RMD_ACCOUNTS: 
        case ActionTypes.GET_RMD_TRANSACTIONS:
        case ActionTypes.ADD_RMD_TRANSACTIONS:
        case ActionTypes.DELETE_RMD_TRANSACTIONS: {
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
                isDelete: false,
            };
        }

        case ActionTypes.GET_RMD_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                accountsOpened: (action.payload.accounts.length ? action.payload.accounts : []),
                eligibility: action.payload.eligibility,
                isLoading: false,
                isError: false,
                isDelete: false,
            };
        }

        case ActionTypes.GET_RMD_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                accountsOpened: (action.payload.accounts.length ? action.payload.accounts : []),
                eligibility: action.payload.eligibility,
                isLoading: false,
                isError: false,
                isDelete: false,
            };
        }

        case ActionTypes.ADD_RMD_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                isDelete: false,
            };
        }

        case ActionTypes.DELETE_RMD_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                isDelete:true,
            };
        }

        case ActionTypes.GET_RMD_ACCOUNTS_FAILURE: {
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
                isDelete: false,
            };
        }

        case ActionTypes.GET_RMD_TRANSACTIONS_FAILURE: {
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
                isDelete: false,
            };
        }

        case ActionTypes.ADD_RMD_TRANSACTIONS_FAILURE: {
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
                isDelete: false,
            };
        }

        case ActionTypes.DELETE_RMD_TRANSACTIONS_FAILURE: {
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false,
                isDelete: false,
            };
        }
        case ActionTypes.GET_RMD_UPDATED_LIST: {
            const items = state.accountsOpened.filter(item=>item.accountNumber !== action.accNumber);
            return {
                ...state,
                accountsOpened: items,
                isLoading: false,
                isError: false,
                isSuccess: true,
                isDelete: false,
            }
        }
        case ActionTypes.GET_RMD_PLAN_DETAILS_RESET: {
            return {
                ...state,
                isDelete: false,
                selectedPlanInfo: {}
            };
        }

        case ActionTypes.DISMISS_RMD_NOTIFICATION_FAILURE: {
            return {
                ...state,
                isError: false,
                isDelete: false,
                error: {},
            };
        }
        case ActionTypes.GET_RMD_RESET: {
            return {
                ...initialState
            };
        }
        case ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_LOADER:
        case ActionTypes.RMD_GET_STATE_CODE_GROUP_LOADER:{
            return{
                ...state,
                isError: false,
                isDelete: false,
                error: {},
            }
        }
        case ActionTypes.RMD_GET_STATE_CODE_GROUP_SUCCESS: {
            return {
                ...state,
                stateCodeGroup: action.payload.taxGroup,
                age: action.payload.age,
                stateForStateTax: action.payload.state,
                customerName: action.payload.customerName,
                isError: false,
                isLoading: false,
            };
        }
        case ActionTypes.RMD_GET_STATE_CODE_GROUP_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                stateCodeGroup: [],
            };
        }
        case ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_SUCCESS: {
            let stateTaxAmount;
            if (action.payload.stateTaxDetails.basis === 'FWH') {
                stateTaxAmount =
                    (parseFloat(action.payload.fedWithHolding) *
                        parseFloat(action.payload.stateTaxDetails.taxPercent)) /
                    100;
            } else {
                stateTaxAmount =
                    (parseFloat(action.payload.distributionAmount) *
                        parseFloat(action.payload.stateTaxDetails.taxPercent)) /
                    100;
            }
            const stateTaxDetails = {
                isWarning: action.payload.stateTaxDetails.isWarning,
                stateTax: stateTaxAmount,
                taxPercent: action.payload.stateTaxDetails.taxPercent,
                basis: action.payload.stateTaxDetails.basis,
            };
            return {
                ...state,
                groupStateTax: stateTaxDetails,
                isError: false,
                isLoading: false,
            };
        }
        case ActionTypes.RMD_GET_STATE_TAXES_FOR_GROUP_FAILURE: {
            return {
                ...state,
                isError: true,
                isLoading: false,
                groupStateTax: {},
            };
        }
        default:
            return state;
    }
}

export default rmdReducerData;
