/**
 * Account Info card generation Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the Account Info  card to verify
 * @createdDate [22/04/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generateAccountInfoCard(accType, data) {
    let accountTypeData = '';
    let accTypeList = [];
    const allCards = [];
    if (accType && data.accIDSelected === 'ira') {
        const accTypeListArr = data.accTypeDetails.value.length > 0 && data.accTypeDetails.value; // masterLookupStateData[data.accIDSelected].value;
        for (let i = 0; i < accTypeListArr.length; i += 1) {
            const tempArr = accTypeListArr[+i].subtypes;
            accountTypeData =
                tempArr &&
                tempArr.find(item => item.key === accType) &&
                tempArr.find(item => item.key === accType).value;
            if (accountTypeData !== undefined && accountTypeData !== '') break;
        }
    } else if (accType) {
        accTypeList = data.accTypeDetails.value.length > 0 && data.accTypeDetails.value[0].subtypes; //    masterLookupStateData[data.accIDSelected].subtypes;
        accountTypeData = (accTypeList && accTypeList.find(item => item.value === accType).value) || Consts.None;
    }
    const { accountInfoCard } = Consts;
    accountInfoCard.fields[0].value = accType;
    allCards.push(accountInfoCard);
    return allCards;
}
