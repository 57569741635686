const data = {
  title: "Retrieve your Password",
  info: ["Enter the details to retrieve your password"],
  onlineIdLabel: 'Online Id',
  onlineId: 'VCM123',
  socialSecurityNumberLabel: 'Social Security Number',
  socialSecurityNumber : 'xxx-xxx-1234',
  enterCaptchaLabel: 'Enter Text above',
  enterCaptchaVal : 'MxDf12',
  sideHeaderBlockStyle: {
    marginBottom: 85
  },
  doesntExistMsg: "User ID doesn't match our records, please try again",
}

export default data