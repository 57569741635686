/**
 * Account Services
 ********************************
 *
 * @version 1.0.0
 * @author Dinesh A
 * @description This page displays list of Account options available
 * @createdDate [06/12/2019]
 * ***************************************
 */

 import { analytics,memberDashboardActions } from 'shared/Actions';
 import { CardDeck, Col } from 'react-bootstrap';
 import { CommonButtons, WBreadCrumb, WCard, PageHeading } from 'common';
 import { connect } from 'react-redux';
 import constants from '../constant';
 import PropTypes from 'prop-types';
 import React, { Component } from 'react';
 import rightArrowGreen from 'assets/rightArrow_green.png';
 import styled from 'styled-components';
 import { Link } from 'react-router-dom';
 import accountService from '../../../assets/accountservice.jpg';
 import dynamicAdvisorvideo from "../../../assets/video_1280.mp4"
 import envConfig from 'env/EnvConfig';

const assignObj = obj => {
    return obj;
};
 const cardStyle = {
     minHeight: '13rem',
     background: '#FFFFFF 0% 0% no-repeat padding-box',
     opacity: 1,
     cursor: 'pointer',
 };
 const cardTitleStyle = {
     font: '800 18px/22px yorkten-slab-normal',
     color: '#486D90',
     paddingBottom: '15px',
     borderBottom: '1px solid #D2D2D2',
 };
 
 const cardTitleLinkStyle = {
     font: '800 18px/22px yorkten-slab-normal',
     color: '#486D90',
 };
 
 const cardTitleH2Style = {
     lineHeight: 'unset',
 };
 
 // Dividends Card has different style so it has the same height as the others
 const cardDividendsStyle = {
     padding: '1.2rem 1.25rem 0.25rem 1.25rem',
 };
 
 const buttonStyle = { display: 'none' };
 
 const fontAwesomeStyle = {
     height: 40,
     width: 40,
 };
 
 const cardColor = {
     color: 'green',
 };
 const cardSubtitleStyle = {
     font: '500 14px/20px benton-sans',
     color: 'rgb(73, 73, 74)',
     maxWidth: '83%',
     textAlign: 'left',
     letterSpacing: 0,
     opacity: 1,
     paddingLeft: '10px',
 };
 const nextStyle = {
     display: 'none',
 };
 
 const FlexDiv = styled.div`
     display: flex;
     margin: 0 0 2.5rem 0;
 `;
 const Title = styled.p`
    font-size:24px;
    color:#000;
    font-weight:500;
`
const BtnLink = styled.p`
    margin-top:30px;
    font-weight:600;
`
const SubTitle = styled.p`
    font-size: 20px;
    color: rgba(0, 74, 152, 1);
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
    line-height: 1.5;
`;
 const breadCrumURL = [{ url: '/accountholder', name: 'Home' }];
 const ACCOUNT_HOLDER = '/accountholder';
 
 const funds_account_service_video_web = `${envConfig.ENV_URL}/content/dam/vcm/brokerage/Victory-Funds-Services.mp4`;
 const brokerage_account_service_video_web = `${envConfig.ENV_URL}/content/dam/vcm/brokerage/Brokerage-Services.mp4`;

 class AccountServices extends Component {
     constructor(props) {
         super(props);
         this.state = {};
     }
 
     componentDidMount() {
         sessionStorage.setItem('stepname', '');
         this.props.getEnabledFeature()
     }
 
     onCardClick = data => () => {
         if (data.url !== '') {
             const path = data.url;
             const { history } = this.props;
             history.push(path);
         }
     };
 
     clickBack = () => {
         const { history } = this.props;
         history.push('/accountholder');
     };
 
     displayCards() {
         this.cardDetails = constants.cardDetails;
         this.cards = [];
 
         this.cards = this.cardDetails.map((item, index) => (
             <Col key={index.toString()} md={4} lg={4} xl={4} role="listitem" className="card-deckMob">
                 <WCard
                     variant="outline"
                     className="rightArrow acntServiceCard"
                     fontawesomestyle={fontAwesomeStyle}
                     cardtitletext={item.cardTitleText}
                     cardsubtitletext={item.cardText}
                     leftcornercard={cardColor}
                     buttonstyle={buttonStyle}
                     src={rightArrowGreen}
                     alt="rightArrowGreen"
                     cardstyle={cardStyle}
                     cardtitlestyle={cardTitleStyle}
                     cardTitleLinkStyle={cardTitleLinkStyle}
                     cardTitleH2Style={cardTitleH2Style}
                     cardsubtitlestyle={cardSubtitleStyle}
                     cardbodystyle={
                         item.url === 'dividends' || item.url === 'systematicwithdrawal' ? cardDividendsStyle : null
                     }
                     onClick={this.onCardClick(item)}
                     mdHeight="280px"
                     lgHeight="198px"
                     marginBtm="30px"
                     dataAnalyticId={item.dataAnalyticsId}
                     waitforapiresponse={item.waitforapiresponse}
                     descid={item.cardTitleText.replace(/\s+/g, '')}
                     tabIndex="0"
                 />
             </Col>
         ));
         return this.cards;
     }
 
     render() {
        const isNewDashboardFeatureEnabled =  
        this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
        this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");
         const isNewDynamicAdvisorFeatureEnabled =
            this.props.memberDashboardData.getEnabledFeature?.features == undefined
                ? false
                : this.props.memberDashboardData.getEnabledFeature?.features.includes('DynamicAdvisor');
        
         return (
             <div>
                 <div className="container">
                     <WBreadCrumb activeCrumb="Account Services" breadCrumbItems={breadCrumURL} />
                     <FlexDiv className="accountServicesHeading">
                         <PageHeading>{constants.pageHeading}</PageHeading>
                     </FlexDiv>
                     {(isNewDashboardFeatureEnabled) ?
                    <>
                    <Title>{constants.AccountServicesTitle}</Title>
                    <p>{constants.AccountServicesDescription}</p>
                    <div className="row mt-5 webvideo">
                        <div className="col-md-6">
                            <SubTitle>Victory Funds Account Services</SubTitle>
                            <video
                                src={funds_account_service_video_web}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    padding: '15px',
                                    border: '1px solid #dedede',
                                }}
                                controls="controls"
                                autoplay="false"
                            />
                        </div>
                        <div className="col-md-6">
                            <SubTitle>Marketplace Account Services</SubTitle>
                            <video
                                src={brokerage_account_service_video_web}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    padding: '15px',
                                    border: '1px solid #dedede',
                                }}
                                controls="controls"
                                autoplay="false"
                            />
                        </div>
                    </div>
                    
                    {isNewDynamicAdvisorFeatureEnabled && (<>
                        <div className="row mt-5 webvideo">
                             <div className="col-md-6  " >
                            <SubTitle>Dynamic Advisor Account Services</SubTitle>
                            <video
                                src={dynamicAdvisorvideo}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    padding: '15px',
                                    border: '1px solid #dedede',
                                }}
                                controls="controls"
                                autoplay="false"
                            />
                            </div>
                            </div>
                            </>) }
                    <Link to={assignObj({ pathname: ACCOUNT_HOLDER })}>
                        <BtnLink>
                            {constants.DashboardLinkText}{' '}
                            <img src={rightArrowGreen} className="rightArrowIcon" />
                        </BtnLink>
                    </Link>
                </>
                    :
                    <>
                     <CardDeck className="acntServiceCard" role="list">
                         {this.displayCards()}
                     </CardDeck>
                     </>
                    }
                 </div>
                 <CommonButtons backClick={this.clickBack} nextstyle={nextStyle} arialabelback={'Back to Home'} />
             </div>
         );
     }
 }
 
 AccountServices.propTypes = {
     history: PropTypes.shape({
         push: PropTypes.func.isRequired,
     }).isRequired,
     analyticsSetStepName: PropTypes.func,
 };
 
 AccountServices.defaultProps = {
     analyticsSetStepName: () => {},
 };
 
 const mapStateToProps = (state) => {
     return {
        memberDashboardData: state.memberDashboardData,
     };
 };
 
 const mapDispatchToProps = {
     analyticsSetStepName: analytics.analyticsSetStepName,
     getEnabledFeature: memberDashboardActions.getEnabledFeature
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(AccountServices);
 