/**
 * Automatic Investment Plans
 ********************************
 *
 * @version 1.0.0
 * @author Dinesh A
 * @description Automatic Investment Plans and Systematic Withdrawal are displayed and managed here
 * @createdDate [10/12/2019]
 * ***************************************
 */

import CompareFundsComponent from 'modules/AccountManagementModule/CompareFunds';
import constants from 'modules/AccountOptions/constant';
import { formatDate, isValidDate, createBreadCrumbItem, getToken } from "utils";
import FundSummaryComponent from 'modules/AccountManagementModule/FundSummary';
import { get } from 'lodash';
import getDigitalDataFormAband from 'utils/AdobeAnalytics/formAband';
import PlanDetails from '../PlanDetails';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RECURRING_TRANSFER_PLAN, GET_ACCOUNT_LIST_CONFIG_KEY_BY_PLAN, PAY_OPTIONS } from '../../constant';
import routeConstants from 'routeConstants';
import routesBreadCrumb from '../../../../utils/Constants/routesBreadcrumb';
import Schedule from '../Schedule';
import styled from 'styled-components';
import {
    VERIFY_PAGE_NAME,
    AIP_VERIFY_EDIT_BUTTON,
    SWP_VERIFY_EDIT_BUTTON,
    ACCOUNT_SERVICE_TYPE_AIP,
    ACCOUNT_SERVICE_TYPE_SWP,
    ACCOUNT_FLOW_TYPE_EDIT,
    BREAD_CRUMB_SCREEN_AIPEDIT,
    BREAD_CRUMB_SCREEN_SWPEDIT,
    AIP_SWP_STEP_NAME_FREQUENCY, 
    AIP_SWP_STEP_NAME_PLAN_DETAILS, 
    AIP_SWP_STEP_NAME_VERIFY, STEP_KEY, AIP_SWP_STEP_NAME_SCHEDULE,
    AIP_NAV_BAR_IDS, SWP_NAV_BAR_IDS
} from '../../../../utils/AdobeAnalytics/Constants';
import { WBreadCrumb, PageHeading, WSpinner, VerifyDetails, WStepper, WMessageBar, MaskText, ConformationModal, ConfirmModal } from 'common';
import WStepperMobile from '../../../../common/WStepper/WStepperMobile';
import './EditPlanComponent.css';
import * as TransactionConstants from '../../../TransactionModule/TransactionConstants';
import axios from 'axios'
import Authenticate from '../../../../common/Authenticate/Authenticate';
import { isTokenAvailable } from 'common/Authenticate/session';
import { removeComma } from 'common/Formatter/CurrencyFormatter';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
import { getMemberRoleFlag } from 'utils/utils';
import { getValidValue } from 'commonHelper/CommonHelperFunctions';
const stepperStyle = { width: 1107, height: 60, paddingRight: 90, paddingLeft: 150, marginBottom: 30 };

const SWPPages = ['Plan Details', 'Frequency', 'Verify'];
const AIPPages = ['Plan Details', 'Schedule', 'Verify'];

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 2.5rem 0;
`;
const Stepper = styled(WStepper)`
    margin-bottom: 40px;
`;

const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

const ShortFundName = styled.div`
    display: flex;
    padding: 0.5rem;
    background-color: #004A98;
    margin-right: 1rem;
    color: #FFFFFF;
`;

const ShareTitle = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

class EditInvestmentOrWithdrawalPlan extends Component {
    constructor(props) {
        super(props);
        const { location } = this.props;
        const { details: editDetails = {}, withdrawalPlanList: fundAccounts } = location.state || {};

        this.state = {
            isFormAband: true,
            pageIndex: 0,
            childrenState: [],
            showCompareFundsScreen: false,
            showFundSummaryScreen: false,
            selectedFunds: [],
            fundDetail: {},
            isEdit: false,
            plan: this.getPlanFromRoute(),
            editDetails,
            analitycsDigitalData: {},
            fundAccounts,
            assocFunds: [],
            iraContributionWarning:'',
            flag:false,
            selectedFrequency:'',
            load:false,
            iraAccepted:true,
            next:false,
            isError:false,
            coverdellFalse:false,
            coverdellNotificationMsg:'',
            coverdellLoad:false,
            showAuthBox:false,
            checkFisError :  false
        };
        this.editSection = null;
        this.bottomNav = React.createRef();
        this.breadCrumbItems = this.getBreadCrumbItems();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.purchaseReducerData.iraContributionWarning != ''){
            return{
                ...prevState,
                iraContributionWarning:nextProps.purchaseReducerData.iraContributionWarning,
                load:false,
                iraAccepted:nextProps.purchaseReducerData.isIRAContributionAccepted,
                isError:nextProps.purchaseReducerData.isError
            }
        }else{
            return{
                ...prevState,
                iraContributionWarning:nextProps.purchaseReducerData.iraContributionWarning,
                iraAccepted:nextProps.purchaseReducerData.isIRAContributionAccepted,
                isError:nextProps.purchaseReducerData.isError
            }
        }
        
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { getFundAccountById } = this.props;
        const { plan, editDetails, pageIndex } = this.state;
        const params = getSessionValues();    
        const {accesstoken, vcmid, msrid, fismemberid} = params;        
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid,
            editDetails
        }

        if (Object.keys(editDetails).length) {
            setTimeout(() => {
                getFundAccountById(msrParams, plan);
            },5000)
        }
        this.setSessionStorageStepName(pageIndex);
        // this.getAssociatedFunds()
        this.getFundAccountList();
        this.setState({
            analitycsDigitalData: {
                accountservicetype: plan === RECURRING_TRANSFER_PLAN.AIP
                    ? ACCOUNT_SERVICE_TYPE_AIP
                    : ACCOUNT_SERVICE_TYPE_SWP,
                accountflowtype: ACCOUNT_FLOW_TYPE_EDIT,
                dividendsandpreferrencetype: '',
                investmentplanamount: ''
            }
        })

    }

    moveNextIndexForLowIra = ()=>{
        this.setState(prevState => {
            return {
                ...prevState,
                pageIndex: prevState.pageIndex + 1,
                iraAccepted:false,
                next:false,
                // iraContributionWarning:'' ,
            };
        });
    
        this.handleCancel()
    }

    componentDidUpdate(prevProps, prevState) {
        const { clearFundPlanInfo, fundPlanData, clearBankAccDetails, bankAccountReducerData, isSuccessfullySaved, fundAccountData } = this.props;
        const { assocFunds =[] } = prevState;
        const { fundAccountList = [] } = fundAccountData || {}; 
        const { bankDetails = [] } = bankAccountReducerData;
        const { planInfo = [] } = fundPlanData;
        const { childrenState,selectedFrequency,iraAccepted, pageIndex,next,iraContributionWarning } = this.state;
        if(!!(iraAccepted) && iraContributionWarning != '' && pageIndex === 1 && prevProps.purchaseReducerData.isIRAContributionAccepted === true && next === true){
            this.moveNextIndexForLowIra()
        }

        if(!!childrenState.length && selectedFrequency === ''){
          this.setState({selectedFrequency:childrenState[2].investVal})
          
        }
   
        if ( !childrenState.length && Object.keys(planInfo)?.length && fundAccountList?.length > 0) {
           
            this.addChildrenState(planInfo);
        }

        if (isSuccessfullySaved && prevProps.isSuccessfullySaved !== isSuccessfullySaved) {
            if (Object.keys(planInfo).length) {
                clearFundPlanInfo();
            }
            if (bankDetails.length) {
               clearBankAccDetails();
            }

            this.onSubmit();
        }

        if(fundAccountList && fundAccountList.length > 0 && assocFunds.length === 0 ) {
            this.getAssociatedFunds(fundAccountList)
        }
    }

    componentWillUnmount() {

        const { clearFundPlanInfo, clearBankAccDetails, analyzeFormAdandonment } = this.props;
        const {isFormAband} = this.state;
        clearFundPlanInfo();
        clearBankAccDetails();
        if( isFormAband ){
            const digitalData = getDigitalDataFormAband();
            analyzeFormAdandonment(digitalData);
        } 
    }

    getAssociatedFunds = (fundAccountList) => {

        const {editDetails } = this.state
        const { fundAccountNumber } = editDetails
        const accounts = [...fundAccountList]
        const obj = {}
        const obj1 = {}
        const obj3 = {}
        let finalObj;

        accounts.forEach(el => {
          obj[el.holdingGroupName] = el.accounts
        })
        
        const data1 = Object.values(obj)
        data1.map((el,index) => {
         return el.map(x => {
            obj1[x.masterRegistrationID] = x.fundsList
          })
        })
        
        const data2 = Object.values(obj1)
        data2.map((el, index) => {
         return obj3[index] =  el
        })
        
        Object.entries(obj3).map(item => {
         return item[1].map(el => {
            if(el.fundAccountNumber === fundAccountNumber){
              finalObj = item[1]
            }
          })
        })
        
        this.setState(() => {
            return {
                assocFunds: finalObj
            }
        })
    }

    setSessionStorageStepName = (pageIndex) => {
        const { plan } = this.state;

        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        switch ( pageIndex){
            case 0: {
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_PLAN_DETAILS); 
                break;
            } 
            case 1: {
                if ( isAIPPlan){
                    sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_SCHEDULE); 
                }else{
                    sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_FREQUENCY); 
                }
                break;
            }
            case 2: {
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_VERIFY); 
                break;
            } 
            default :
                sessionStorage.setItem(STEP_KEY, AIP_SWP_STEP_NAME_PLAN_DETAILS); 
        }
    }

    getBreadCrumbItems = () => {

        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyState = {
            state: {
                accountType,
                accountOwnerDesc,
            },
        };
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: {
                state: {
                    ...historyState.state,
                    name: accountName,
                    masterId: masterRegId,
                }
            },
        };
        const { plan } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const bradcrumbRoutes = [
            routeConstants.accountholder,
            routeConstants.accountservices,
            isAIPPlan ? routeConstants.automaticinvestment : routeConstants.systematicwithdrawal,
        ];
        const analyticsIDButtonScreen = isAIPPlan ? BREAD_CRUMB_SCREEN_AIPEDIT : BREAD_CRUMB_SCREEN_SWPEDIT;
        const breadCrumbItems = bradcrumbRoutes.map(route => createBreadCrumbItem(route, analyticsIDButtonScreen));
        if (masterRegId) {
            breadCrumbItems.splice(1, 1, intermediateRoute);
            breadCrumbItems.splice(2, 1, {
                ...breadCrumbItems[2],
                payload: {
                    state: {
                        ...historyState.state,
                        masterRegId,
                        accountName,
                    }
                },
            });
        }
        return breadCrumbItems;
    }

    addChildrenState = (planInfo) => {
        const { plan = 'automaticInvestment', editDetails } = this.state;
        // Setting isEdit
        const isEdit = true;
        // Setting page index
        const pageIndex = 0;
        // Setting children state
        const { calculatedFundValue, masterRegIdDetails, bankDetails = {}, fedTax: fedTaxPercent = '0.0',
         stateTax: stateTaxPercent = '0.0', payeeId, paymentMethod = '05', address,
         specialPayeeAddress, toFundDetails = {}} = planInfo;
         const { toFundAccountNumber = '', toFund = '' } = toFundDetails

        const {
            accountName: accName = '',
            accountType,
            masterRegistrationID = '',
            primaryOwner = '',
        } = masterRegIdDetails;

        const {
            fundAccountNumber: accNumber = '',
            fundNumber = '',
        } = editDetails;
        const {
            investmentAmount: investedFundAmount = '',
            frequency: schedule = '',
            initialDate: startDate = '',
            endDate = '',
            nextScheduled: nextScheduleDate, 
            fundName,
            shortFundName
        } = planInfo || {};

        // Account Selection Page
        const accountSelection = {};
        accountSelection.accountSelected = {
            accName,
            accountName: accName,
            accountNumber: masterRegistrationID,
            accountType,
            calculatedMasterRegBalance: calculatedFundValue,
            masterRegistrationID,
            primaryOwner,
            address,
            specialPayeeAddress,
            fundsList: [{
                fundAccountNumber: accNumber,
                accountNumber: accNumber,
                accountName: accName,
                fundNumber,
                masterRegistrationID,
                fedTaxPercent,
                stateTaxPercent,
                accountType,
                amt: investedFundAmount,
                investedFundAmount: '',
                availableShares: "0.00",
                calculatedFundValue,
                automaticInvestmentPlan: "Y",
                primaryOwner,
                fundName,
                shortFundName,
                selected: true,
                paymentMethod,
            }]
        };
        accountSelection.displayDetails = {
            accountSelection: {
                titleTxt: 'Account Selection',
                pageIndex: 0,
                data: [
                    {
                        field: 'Account Name',
                        value: accName,
                    },
                    {
                        field: 'Account Number',
                        value: masterRegistrationID.toString(),
                    },
                ],
            },
        };
        // Plan Details Page
        const planDetails = {};
        let displayDetails = {};
        let selectedBank = null;
        let offlineMode = null;
        let offlineCards = [];
        // let offlineFundDetails;
        let showOptions = false;

        if (paymentMethod === "05") {
            const {
                transitRoutingNumber = '',
                bankName = '',
                bankAccountNumber = '',
                bankAccountType = '',
                salesforceRecordId = ''
            } = bankDetails;

            displayDetails = {
                fundFrom: {
                    titleTxt: plan === RECURRING_TRANSFER_PLAN.AIP ? 'Transfer From' : 'Fund To',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [
                        {
                            field: 'Funding Source',
                            value: bankName,
                        },
                        {
                            field: 'Account Number',
                            value: bankAccountNumber,
                            formattedValue: bankAccountNumber ? (
                                <MaskText
                                    text={bankAccountNumber.toString()}
                                    startIndex={0}
                                    maskLength={6} />
                            ) : (bankAccountNumber)
                        },
                    ],
                },
            };

            if (salesforceRecordId) {
                selectedBank = {
                    bankName,
                    bankAccountNumber,
                    bankAccountType,
                    bankRoutingNumber: transitRoutingNumber,
                    payeeId,
                    salesforceRecordId
                }
            }

            offlineMode = PAY_OPTIONS.BANK;
        } else {
            offlineMode = paymentMethod === "09" ? PAY_OPTIONS.DIRECT_SWP : PAY_OPTIONS.CHECK_ORDER;
            offlineCards = [{
                "key": PAY_OPTIONS.CHECK_ORDER,
                "text": "Check",
                "selected": paymentMethod === "01"
            }];

            if (paymentMethod === "09") {
                offlineCards.push({
                    "key": PAY_OPTIONS.DIRECT_SWP,
                    "text": "Directed Systematic Withdrawal Plan",
                    "selected": paymentMethod === "09"
                })
            }

            displayDetails = {
                fundFrom: {
                    titleTxt: 'Fund To',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [],
                },
            };
            showOptions = true;

        }

        planDetails.offlineMode = offlineMode;

        planDetails.bankDetailsSectionState = {
            selectedBank,
            displayDetails,
            disableNext: selectedBank === null && paymentMethod === "05",
            offlineCards,
            showOptions,
            retrivedState: true
        };
        if(paymentMethod === "09" && plan !== RECURRING_TRANSFER_PLAN.AIP) {
            const fundname = this.getFundName(toFund)
            planDetails.investFundDetails = {
                investToFundData: {
                    state: {
                        investToFundName: fundname[0].fundName,
                        investToFundAccNo: toFundAccountNumber,
                        investToFundNo: toFund
                    }
                }
            }
        }

        if (this.isIRAaccount(accountType)) {
            planDetails.taxWithHoldState = {
                stateTaxWithHold: planInfo.feeWaiveFlag !== 'W',
                tax: {
                    fedTaxPercent,
                    stateTaxPercent,
                },
                displayDetails: {
                    titleTxt: 'Tax Witholding Options',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [
                        {
                            field: "Federal Tax",
                            value: fedTaxPercent
                        },
                        {
                            field: "State Tax",
                            value: stateTaxPercent
                        },
                    ]
                }
            }
        }

        planDetails.calculationSectionState = {
            amtConsumed: investedFundAmount,
            amtRemaining: 0,
            disableNext: false,
            displayDetails: {
                investTo: {
                    titleTxt: plan === RECURRING_TRANSFER_PLAN.AIP ? 'Invest To' : 'Withdrawal From',
                    editTxt: 'Edit',
                    pageIndex: 0,
                    data: [
                        {
                            field: 'Fund Name',
                            formattedValue:
                                <ShareNameDiv>
                                    <ShortFundName>
                                    <span className="sr-only">Fund code:{shortFundName.split('').join(' ')}</span>
                                    <span aria-hidden="true">
                                     {shortFundName}
                                    </span>
                                    </ShortFundName>
                                    <ShareTitle>{fundName}</ShareTitle>
                                </ShareNameDiv>,
                            fundNumber,
                            fundAccountNumber: accNumber
                        },
                        {
                            field: plan === RECURRING_TRANSFER_PLAN.AIP ? 'Amount Invested' : 'Total Amount',
                            formattedValue: `$ ${investedFundAmount}`,
                            value: investedFundAmount
                        },
                    ],
                },
            },
            totalAmt: investedFundAmount,
        };
        planDetails.displayDetails = {
            ...planDetails.bankDetailsSectionState.displayDetails,
            ...planDetails.calculationSectionState.displayDetails,
        };
        planDetails.disabled = false;
        planDetails.totalAmount = investedFundAmount;
        // Schedule Page

        let displayScheduleData;
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            displayScheduleData = [
                {
                    field: "Frequency",
                    value: constants.frequency[`${plan}`][schedule.toString()],
                },
                {
                    field: "Next Withdrawal Date",
                    value: nextScheduleDate,
                }
            ];

            if (!isEdit) {
                displayScheduleData.splice(1, 0, {
                    field: "Start Date",
                    value: startDate,
                });
            }

        } else {
            displayScheduleData = [
                {
                    field: "Withdrawal Frequency",
                    value: constants.frequency[`${plan}`][schedule.toString()],
                },
                {
                    field: "Next Withdrawal Date",
                    value: nextScheduleDate,
                }
            ];

            if (!isEdit) {
                displayScheduleData.splice(1, 0, {
                    field: "Beginning on",
                    value: startDate,
                });
                displayScheduleData.splice(2, 0, {
                    field: "Ending by",
                    value: endDate,
                });
            }
        }


        const schedulePage = {
            displayDetails: {
                ...constants.InitialScheduleDisplayDetails,
                schedule: {
                    ...constants.InitialScheduleDisplayDetails.schedule,
                    pageIndex: 1,
                    data: displayScheduleData
                }
            },
            endDate,
            selectedEndDate: endDate,
            ending: endDate ? 'Custom date' : 'Never',
            frequencyText: constants.frequency[`${plan}`][schedule.toString()] || 'Monthly',
            investVal: schedule || 'M',
            nextInvestment: nextScheduleDate,
            onTheDayVal: startDate,
            disableNext: !isValidDate(endDate) || !isValidDate(startDate) || !constants.frequency[`${plan}`][schedule.toString()],
            startDate
        }
        this.setState(prevState => ({
            ...prevState,
            planInfo,
            isEdit,
            pageIndex,
            childrenState: [accountSelection, planDetails, schedulePage],
        }));

    }

    isIRAaccount = (accountType) => {

        const { plan } = this.props;
        return ((accountType.toUpperCase().indexOf('IRA') !== -1 && accountType.toUpperCase().indexOf('ROTH IRA') === -1) || accountType === '215') && plan === RECURRING_TRANSFER_PLAN.SWP
    }

    getFundName = (fundno) => {

        const { assocFunds } = this.state;
        return assocFunds.filter(fund => fund.fundNumber === +fundno)
    }

    saveChildrenState = (data, index) => {

        this.setState(prevState => {
            const { childrenState } = prevState;
            childrenState[(+index) + 1] = data;
            let frequency = prevState.analitycsDigitalData?.accountOptions?.dividendsandpreferrencetype
            if (index === 1) {
                frequency = data.frequencyText
            }
            return {
                childrenState,
                analitycsDigitalData: {
                    accountOptions: {
                        ...prevState.analitycsDigitalData.accountOptions,
                        investmentplanamount: index === 0
                            ? data.totalAmount
                            : prevState?.analitycsDigitalData?.accountOptions?.investmentplanamount,
                        dividendsandpreferrencetype: frequency
                    }
                }
            };
        });
    };

    closeModel = ()=>{
        this.setState({coverdellFalse:false,coverdellNotificationMsg:''})
    }

    fetchData =  async (stateValue) =>{

        const masterReg = stateValue.childrenState[0].accountSelected.masterRegistrationID
        const payload = {
            masterRegId:`${masterReg}`,
            companyNumber:'591'    
        }
        
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': `${envConfig.API_KEY}`
        }
        this.setState({ coverdellLoad:true })
        try{
            const response = await axios.post(`${envConfig.API_SUB_DOMAIN}/trance/msr/accountOwner/age`,payload,{headers:headers})
            if(response.data && response.data.account === false && response.data.age != ''){
                // AGE > 18, HARD STOP
                let message = response.data.custMessage
                // let message = 'Account Beneficiary for this Coverdell account is over 18. No contributions can be made into this account.'
                this.setState({ coverdellLoad:false, coverdellFalse:true, coverdellNotificationMsg:message})
            }
            else if(response.data && response.data.account === true && response.data.age != ''){
                this.setState({ coverdellLoad:false })
                //   run validate ira api if ture i,e AGE is < 18
                this.handleIRAContribution(stateValue.childrenState,stateValue.selectedFrequency);
            }else{
                this.setState({coverdellLoad:false, coverdellFalse:true,coverdellNotificationMsg:TransactionConstants.TRANSACTION_STANDARD_ERROR_MESSAGE})
            }
        }

        catch(error){ 
            this.setState({coverdellFalse:true,coverdellNotificationMsg:TransactionConstants.TRANSACTION_STANDARD_ERROR_MESSAGE})
        }
    }

    onSwitchComponent = bool => () => {
      
        if(this.state.childrenState[1] != undefined && this.state.childrenState[1].selectedFunds !=undefined)
        {
           
        }
        
        // if(bool)
        // {
        //     this.setState({pageIndex : this.state.pageIndex + 1,next : true})
        // }
        // else
        // {
        //     this.setState({pageIndex : this.state.pageIndex -1 })
        // }
        this.setState(prevState => {
            if(prevState.pageIndex === 1 && this.state.plan===RECURRING_TRANSFER_PLAN.AIP && this.isIRA()){
                if(bool){
                    if (prevState.childrenState[0].accountSelected.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA)) {
                        this.fetchData(prevState)
                        return {pageIndex: prevState.pageIndex,next:true}
                    }else{
                        this.handleIRAContribution(prevState.childrenState,prevState.selectedFrequency)
                        return {pageIndex: prevState.pageIndex,next:true}
                    }                    
                }else{
                    return {pageIndex: prevState.pageIndex - 1}
                }
     
            }else{
                return {
                    pageIndex: bool ? prevState.pageIndex + 1 : prevState.pageIndex - 1,
                };
            }
        });
    };
   

    
    onCancel = () => {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const { plan } = this.state;
        const historyObj = {};
        const route =
            plan === RECURRING_TRANSFER_PLAN.AIP
                ? routeConstants.automaticinvestment
                : routeConstants.systematicwithdrawal;
        historyObj.pathname = route;
        if (masterRegId)
            historyObj.state = {
                masterRegId,
                accountName,
                accountType,
                accountOwnerDesc,
            };
        history.push(historyObj);
    };

    onSubmit = () => {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const { plan } = this.state;
        const historyObj = {};
        historyObj.pathname =
            plan === RECURRING_TRANSFER_PLAN.AIP
                ? routeConstants.automaticinvestment
                : routeConstants.systematicwithdrawal;
        historyObj.state = {
            accUpdated: true,
            eventMessage: '',
            stepName: 'Edit',
        };
        if (masterRegId) {
            historyObj.state = {
                ...historyObj.state,
                masterRegId,
                accountName,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    };

    scrollToSection = (index, section) => () => {
        this.editSection = section;
        this.setState(
            () => {
                return {
                    pageIndex: index,
                };
            },
            () => {
                this.editSection = null;
            },
        );
        const { analyzeClickEvent, plan } = this.props;
        analyzeClickEvent({
            link: `${plan === RECURRING_TRANSFER_PLAN.AIP
                ? AIP_VERIFY_EDIT_BUTTON
                : SWP_VERIFY_EDIT_BUTTON}_${section.replace(' ', '').toLowerCase()}`,
        })
    };

    switchToCompareFundsScreen = selectedFunds => {

        this.setState(() => {
            return {
                showCompareFundsScreen: true,
                selectedFunds,
            };
        });
    };

    switchToFundSummaryScreen = fundDetail => {

        this.setState(() => {
            return {
                showFundSummaryScreen: true,
                fundDetail,
            };
        });
    };

    backToPlanDetails = () => {

        this.setState(() => {
            return {
                showCompareFundsScreen: false,
                showFundSummaryScreen: false,
            };
        });
    };

    getFundAccountList = () => {
        const { getAccountList } = this.props;
        const { plan } = this.state;

        getAccountList({ configURLKey: GET_ACCOUNT_LIST_CONFIG_KEY_BY_PLAN[plan] });
    };

    createPlan = () => {

       if(isTokenAvailable() === null)
        {
            this.setState({showAuthBox:true})
        }
        else
        {
           this.moveToNextStep();
        }
    }

    moveToNextStep = () => 
    {
        const { plan } = this.state;
        const planDetails = this.getPlanDetails();
        const { updatePADDetails, updateSWPDetails } = this.props;
        this.setState({isFormAband:false})
        if (planDetails) {
            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                updatePADDetails(planDetails);
            } else {
                updateSWPDetails(planDetails);
            }
        }
    }

    calcWorkingDays = (fromDate, days) => {
        let count = 0;
        while (count < days) {
            fromDate.setDate(fromDate.getDate() + 1);
            if (fromDate.getDay() !== 0 && fromDate.getDay() !== 6) // Skip weekends
                count += 1;
        }
        return fromDate;
    }

    moveNextPage = (actionTaken = '') =>{
        this.setState({showAuthBox: false});

        if(actionTaken != 'closed')
        {
            this.moveToNextStep();
        }
    }

    authBox = () =>{
        this.setState({showAuthBox: true})
    }

    onNotificationForFis = ()=>{
        
        this.setState({
            checkFisError: true
        });
    }

        
    assignPaymentType = (offlineMode) => {
        let paymentType;

        if(offlineMode === PAY_OPTIONS.CHECK_ORDER){
            paymentType = '01'
        } else if(offlineMode === PAY_OPTIONS.DIRECT_SWP) {
            paymentType = '09'
        } else {
            paymentType = '05'
        }

        return paymentType
    }

    checkForMatchingBankInfoSelection = (planInfo, selectedBank) => {

        return (
            planInfo.bankDetails && selectedBank &&
            (
                (
                    selectedBank.bankRoutingNumber === planInfo.bankDetails.transitRoutingNumber &&
                    selectedBank.bankAccountNumber === planInfo.bankDetails.bankAccountNumber
                ) || (planInfo.bankDetails.salesforceRecordId && selectedBank.salesforceRecordId === planInfo.bankDetails.salesforceRecordId)
            )
        )
    }
    

    getPlanDetails = () => {

        const { rmdReducerData } = this.props
        const { plan, childrenState, editDetails, planInfo } = this.state;
        const { addbankvisible, addbankState, calculationSectionState, bankDetailsSectionState, 
            deliveryAddress, taxWithHoldState, offlineMode, investFundDetails = {} } = childrenState[1];
        const frequencyData = childrenState[2];
        const { selectedBank } = bankDetailsSectionState;
        const { shares } = calculationSectionState;
        const taxData = get(taxWithHoldState, 'tax', {});
        const isIraAccount = get(taxWithHoldState, 'isIraAccount', false)
        const { investToFundData = {} } = investFundDetails
        const { state = {} } = investToFundData;
        const { investToFundNo = '' , investToFundAccNo = '' } = state
        let planDetails = {};

        let bankDetails;

        if (addbankvisible) {
            const { otherBankState } = addbankState;
            const { bankAccountDetails, bankAccountType } = otherBankState || {};
            const { value: newBankNamevalue = '' } = getValidValue(bankAccountDetails?.newBankName, {});

            const newBankNumbervalue = bankAccountDetails.filter(item => { return item.name === "accountNumber"}).map( item => item.value)[0] ?? ''
            const newBankTransitRoutingNumbervalue = bankAccountDetails.filter(item => { return item.name === "transitRoutingNumber"}).map( item => item.value)[0] ?? ''

            const { value: newAccountOwnervalue = '' } = getValidValue(bankAccountDetails?.accountOwner, {})
           

            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                bankDetails = {
                    isNewBank: true,
                    bankAccountType,
                    bankAccountNumber: newBankNumbervalue,
                    bankRoutingNumber: newBankTransitRoutingNumbervalue,
                    bankName: newBankNamevalue,
                    accountOwnersName: newAccountOwnervalue
                }
            } else {
                bankDetails = {
                    isNewBank: true,
                    bankAccountType,
                    bankAccountNumber: newBankNumbervalue,
                    transitRoutingNumber: newBankTransitRoutingNumbervalue,
                    bankAccountName: newBankNamevalue,
                    accountOwnersName: newAccountOwnervalue
                }
            }
        } else if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            bankDetails = {
                ...selectedBank
            };
        } else {
            bankDetails = selectedBank ? {
                transitRoutingNumber: selectedBank.bankRoutingNumber,
                bankAccountNumber: selectedBank.bankAccountNumber,
                bankAccountType: selectedBank.bankAccountType,
                bankAccountName: selectedBank.bankName,
                salesforceRecordId: selectedBank.salesforceRecordId                
            } : null;
        }

        if (plan === RECURRING_TRANSFER_PLAN.AIP) {

            planDetails = {
                padId: editDetails.padId,
                ...bankDetails,
                frequency: frequencyData.investVal,
                amountValue: removeComma(this.state.childrenState?.[1]?.selectedFunds?.[0]?.amt),
                fundAccountNumber: this.state.childrenState?.[1]?.selectedFunds?.[0]?.fundAccountNumber,
                fundNumber: this.state.childrenState?.[1]?.selectedFunds?.[0]?.fundNumber,
            }
        } else {
            let payeeId;
            if ((this.checkForMatchingBankInfoSelection(planInfo, selectedBank)) ||(offlineMode === PAY_OPTIONS.CHECK_ORDER && planInfo.paymentMethod === '01')) {
                payeeId = planInfo.payeeId;
            }
            let paymentType = this.assignPaymentType(offlineMode);
            planDetails = {
                dollarAmount: removeComma(this.state.childrenState[1].selectedFunds[0].amt),
                fundAccountNumber: this.state.childrenState[1].selectedFunds[0].fundAccountNumber,
                fundNumber: this.state.childrenState[1].selectedFunds[0].fundNumber,
                payeeId,
                frequency: frequencyData.investVal,
                swpId: editDetails.swpId,
                bankDetails,
                isIraAccount,
                paymentMethod: paymentType,// 05 - Bank, 01- check, 09- Fund Transfer
                ...(offlineMode === PAY_OPTIONS.CHECK_ORDER) && { address: {
                    addressLine1: deliveryAddress.accountregistrationLine2,
                    addressLine2: deliveryAddress.accountRegistrationline3,
                    addressLine3: deliveryAddress.accountRegistrationline4,
                    addressLine4: deliveryAddress.accountRegistrationline5,
                    addressLine5: deliveryAddress.accountRegistrationline6,
                    city: deliveryAddress.accountRegistrationCity,
                    zipCode: deliveryAddress.accountRegistrationZip,
                    residenceCode: deliveryAddress.accountRegistrationIdRes,
                } },
                ...(offlineMode === PAY_OPTIONS.DIRECT_SWP) && {
                    toFundNumber: investToFundNo,
                    toFundAccountNumber: investToFundAccNo,
                },
            };

            if (isIraAccount) {
                planDetails.fedTax = taxData.fedTaxPercent;
                const taxBasis = get(rmdReducerData, 'groupStateTax.basis', '');
                const stateTax = get(rmdReducerData, 'groupStateTax.stateTax', '');
                const stateTaxPer = (Number(stateTax) * 100) / Number(shares[0].amt);
                planDetails.stateTax = taxBasis === 'FWH' ? stateTaxPer.toFixed(2) : taxData.stateTaxPercent;
                planDetails.groupStateTax = get(rmdReducerData, 'stateCodeGroup[0]', 'group1')
                planDetails.originalTaxes = [
                    {
                        fundAccountNumber: shares[0].fundAccountNumber,
                        fedTax: shares[0].fedTaxPercent,
                        stateTax: shares[0].stateTaxPercent
                    }
                ];
                planDetails.swpWithholdCode = +planDetails.stateTax ? '' : 'W';
            }
        }

        return {
            ...planDetails,
            prevFrequency: frequencyData.prevInvestVal,
            ...(frequencyData.prevInvestVal === 'N' && {
                nextInvestmentDate: frequencyData.nextInvestment,
                initialDate: formatDate(this.calcWorkingDays(new Date(), 2), 'MM/DD/YYYY')
            })
        };
    }

    switchComponent = () => {
       
        if(this.state.childrenState[1] != undefined && this.state.childrenState[1].selectedFunds !=undefined)
        {
            
            //this.state.childrenState[1].selectedFunds[0].amt = 500;
        }
        

        const { pageIndex, showCompareFundsScreen, showFundSummaryScreen, selectedFunds, fundDetail, plan, assocFunds } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const activeCrumb = isAIPPlan
            ? routesBreadCrumb.get(routeConstants.editautomaticinvestment)
            : routesBreadCrumb.get(routeConstants.editsystematicwithdrawal);
        const title = isAIPPlan
            ? constants.EDIT_AIP
            : constants.EDIT_SWP;
        const flowSteps = isAIPPlan
            ? AIPPages
            : SWPPages;
        if (showFundSummaryScreen) {
            return <FundSummaryComponent fundDetail={fundDetail} backTo={this.backToPlanDetails} />;
        }

        if (showCompareFundsScreen) {
            return <CompareFundsComponent selectedFunds={selectedFunds} backTo={this.backToPlanDetails} />;
        }

        return (
            <div className="mobile-cont">
                <div className="overRightParentPadding"><WStepperMobile currentPage={pageIndex} pages={flowSteps} /></div>
                <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={this.breadCrumbItems} />
                <FlexDiv>
                    <PageHeading>{title.pageHeading1}</PageHeading>
                </FlexDiv>
                <Stepper style={stepperStyle} currentPage={pageIndex} pages={flowSteps} className="edit-plan-stepper desktopStepper" />
                {this.bindComponent()}
            </div>
        );
    };


    onNotificationClose = (isClosedByTimer) => {
        const { dismissUpdateError, analyzeClickEvent, plan } = this.props;
        const {pageIndex} = this.state;
        const isAipPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        this.setSessionStorageStepName(pageIndex);
        dismissUpdateError();
        if ( !isClosedByTimer){
            analyzeClickEvent({
                link:  isAipPlan 
                    ? `accountservices_aip_edit_notification_error_close`
                    : `accountservices_swp_edit_notification_error_close`,
            });
        } 
    }

    getPlanFromRoute = () => {
        const { location } = this.props;
        const pathname = get(location, 'pathname', '');
        return (pathname === routeConstants.editautomaticinvestment)
            ? RECURRING_TRANSFER_PLAN.AIP
            : RECURRING_TRANSFER_PLAN.SWP;
    }

    getAnalyticsIdForNavBarButton = (stepID) => {
        const {plan} = this.state;
        const isAIPPlan = (plan === RECURRING_TRANSFER_PLAN.AIP);
        return  isAIPPlan 
            ? AIP_NAV_BAR_IDS.edit[Number(stepID)]
            : SWP_NAV_BAR_IDS.edit[Number(stepID)];
    }
    
    displayError = errorsData => {
        let message = ''

        if (errorsData.result) {
            message = get(errorsData, 'result.exceptionDetails.logMessage', '')
        } else if (errorsData.message === 'Network Error') { // use default error message
            message = ''
        } else {
            message = errorsData.message
        }
        return { message }
    }

    bindComponent() {
        const { pageIndex, childrenState, isEdit, plan, analitycsDigitalData, assocFunds } = this.state;
        const {
            accOpeningReducerData,
            getBankAccountInfo,
            bankAccountReducerData,
            fundAccountData,
            rmdReducerData,
            fundPlanData,
        } = this.props;
      
        const [accountSelectionState] = childrenState;
        const { accountSelected } = accountSelectionState || {};
        const tradingWindowData = { canTrade: true };
        const navigationObj = {
            onCancel: this.onCancel,
            onPrimaryClick: this.onSwitchComponent(true),
            onSecondaryClick: this.onSwitchComponent(false),
            portalRoot: this.bottomNav,
        };

        this.setSessionStorageStepName(pageIndex);

        const navBarAnalyticsID = this.getAnalyticsIdForNavBarButton(pageIndex);
        const hasBottomNavCancel = true;

        switch (pageIndex) {
            case 0: {
                const planDetailsContent =
                    plan === RECURRING_TRANSFER_PLAN.AIP ?
                        constants.PlanDetailsAIPContent : constants.PlanDetailsSWPContent;
                const groupStateTax = get(rmdReducerData, 'groupStateTax', '');

                return (accountSelected &&
                    <PlanDetails
                        accDetails={accountSelected}
                        accOpeningReducerData={accOpeningReducerData}
                        fundAccountData={fundAccountData}
                        bankAccountInfo={bankAccountReducerData}
                        getBankAccountInfo={getBankAccountInfo}
                        savedState={childrenState[1]}
                        tempState = {this.state}
                        saveChildrenState={this.saveChildrenState}
                        navigationObj={navigationObj}
                        pageIndex={pageIndex}
                        editSection={this.editSection}
                        plan={plan}
                        switchToCompareFundsScreen={this.switchToCompareFundsScreen}
                        switchToFundSummaryScreen={this.switchToFundSummaryScreen}
                        contents={planDetailsContent}
                        isEditMode={isEdit}
                        onCancel={this.onCancel}
                        rmdReducerData={rmdReducerData}
                        navBarAnalyticsID={navBarAnalyticsID}
                        fundPlanData={fundPlanData}
                        assocFunds={assocFunds}
                        groupStateTax={groupStateTax}
                        savedState2={childrenState}
                        editFlow = {"editFlow"}

                    />
                );
            }
            case 1: {
                const ScheduleContents =
                    plan === RECURRING_TRANSFER_PLAN.AIP ? constants.ScheduleAIPContent : constants.ScheduleSWPContent;
                return (
                    <Schedule
                        accDetails={accountSelected}
                        savedState={childrenState[2]}
                        saveChildrenState={this.saveChildrenState}
                        navigationObj={navigationObj}
                        pageIndex={pageIndex}
                        editSection={this.editSection}
                        plan={plan}
                        contents={ScheduleContents}
                        onCancel={this.onCancel}
                        isEdit={isEdit}
                        navBarAnalyticsID={navBarAnalyticsID}
                        getDateAndFrequency={this.getDateAndFrequency}
                        testFun={this.testFun}
                    />
                );
            }
            case 2: {
                navigationObj.primaryText = 'Submit';
                navigationObj.analyzecustom = analitycsDigitalData;
                const currentState = [...childrenState];
                const mergeDetails = currentState
                    .map(item => item.displayDetails)
                    .reduce((acc, curr) => {
                        return { ...acc, ...curr };
                    });
                const verifyDetails = Array.from(Object.keys(mergeDetails), key => mergeDetails[key.toString()]);
            
                return (
                    <VerifyDetails
                        verifyDetails={verifyDetails}
                        navigationObj={navigationObj}
                        scrollToSection={this.scrollToSection}
                        handleSubmit={this.createPlan}
                        tradingWindowData={tradingWindowData}
                        setCancelLiquidation={this.onCancel}
                        pageName={`${isEdit ? 'Edit' : 'Add'}|${VERIFY_PAGE_NAME}`}
                        waitforapiresponse
                        navBarAnalyticsID={navBarAnalyticsID}
                        hasBottomNavCancel={hasBottomNavCancel}
                        plan={plan}
                        isEdit={isEdit}
                    />
                );
            }
            default: {
                return <React.Fragment></React.Fragment>;
            }
        }
    }

    getDateAndFrequency = (val) => {
        if(['A','Q','M','S'].includes(val)){
            this.setState({selectedFrequency:val})
        }else{
            // this.setState({selectedStartDate:val})
        }
        
    }


    getAccountTypeCode = accountType => {
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Minor Roth IRA':
                return '215';
            case 'Roth IRA - Conversion':
                return '216';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case 'Simple IRA - Non DFI':
                return '210';
            case 'Simple IRA - DFI':
                return '212';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            case 'Coverdell ESA':
                return '219';
            case 'Archer MSA':
                return '220'
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';    
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };


    handleIRAContribution = (stateValue,frequency) => {
        const {getIRAContributionValues} = this.props
        this.setState({flag:true,load:true})

        const startDate= stateValue[2].nextInvestment;
        let noOfTimes=1

        function getDate(strdate){
            const newdate = strdate.split('/');
            const date = {
                month:parseInt(newdate[0]),
                day: parseInt(newdate[1]),
                year: parseInt(newdate[2])
            };
            return date
        }

        const date = getDate(startDate)
     
        if(frequency === 'A'){
            noOfTimes = 1
        }else if(frequency === 'Q'){
            noOfTimes = (Math.floor((12 - date.month)/3) + 1)  //for Jan its 5 not 4
            
        }else if(frequency === 'M'){
            noOfTimes = (Math.round(12 - date.month) + 1)
        }else if(frequency === 'S'){

            let days = new Date().getFullYear() % 4 == 0 ? 366 : 365;
            let day = Math.floor((new Date(startDate) - new Date(new Date(startDate).getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
            noOfTimes = Math.floor((days-day)/14) + 1
        } 

        let total = 0
        // stateValue[0].accountSelected.fundsList.forEach(function(fund){
        //     fund.amt? total += (parseFloat(fund.amt) * noOfTimes) : total ;
        // });
        for(let i=0; i< stateValue[0].accountSelected.fundsList.length; i++)
        {
            if(stateValue[0].accountSelected.fundsList[i].amt!= undefined && stateValue[0].accountSelected.fundsList[i].amt!= null)
            {
                total = total + ((parseFloat(removeComma(stateValue[0].accountSelected.fundsList[i].amt))) * noOfTimes)
            }
        }

        const IRAContributionPayload = {
            amount: total,
            accountType: stateValue[0].accountSelected.accountType,
            accountTypeCode: this.getAccountTypeCode(stateValue[0].accountSelected.accountType && stateValue[0].accountSelected.accountType.trim()),
            socialCode: this.getSocialCode(stateValue[0].accountSelected.accountType && stateValue[0].accountSelected.accountType.trim()),
            year: date?.year,
            masterReg: stateValue[0].accountSelected.masterRegistrationID,
            companyNumber: "591",
            dob: "",
            taxId: "",
            accountSource: "FIS",
            isMember: getMemberRoleFlag()
        };

        const validateIRAPayloadObj = {
            IRAContributionPayload,
            // stateValue,
            token: getToken(),
            isVersion2ApiUrl: true
        };

       getIRAContributionValues(validateIRAPayloadObj)

    }
    handleProceed = () => {
        const { acceptIRAContributionWarning } = this.props;
        
        acceptIRAContributionWarning();

        this.setState(prevState => {
            return {
                pageIndex: prevState.pageIndex + 1 
            };
        });

        this.setState({iraContributionWarning:'',flag:false})
    };


    handleCancel = () => {
        const { cancelIRAContributionWarning } = this.props;
        cancelIRAContributionWarning();
        this.setState({iraContributionWarning:'',flag:false,isError:false})
    };

    isIRA = () => {
        const { childrenState } = this.state;
        if (!!childrenState.length) {
            const { accountType } = childrenState[0].accountSelected;
            return !!(
                accountType === TransactionConstants.TRADITIONAL_IRA ||
                accountType === TransactionConstants.ROTH_IRA ||
                accountType.trim() === TransactionConstants.SEP_IRA ||
                accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ||
                accountType === TransactionConstants.ROLLOVER_IRA || 
                accountType === TransactionConstants.ROTH_CONVERSION_IRA

            );
        } 
        return false;
    };


    render() {
        const { fundAccountData, bankAccountReducerData, accOpeningReducerData, isSaving } = this.props;
        const {iraContributionWarning, flag,load,plan,iraAccepted,isError, coverdellFalse, coverdellNotificationMsg, coverdellLoad, childrenState,showAuthBox} = this.state
        const { isLoading: fundAccountDataLoading } = fundAccountData || {};
        const { isLoading: bankAccountReducerDataLoading } = bankAccountReducerData || {};
        const { isLoading: accOpeningReducerDataLoading } = accOpeningReducerData || {};

        const loading = fundAccountDataLoading || bankAccountReducerDataLoading || accOpeningReducerDataLoading || isSaving || load || coverdellLoad;
        const { hasError = false, errorsData = {} } = this.props;
        return (
            <React.Fragment>

                {coverdellFalse && (
                    <ConfirmModal
                        modalTitle="NOTE:"
                        modalBodyText={coverdellNotificationMsg}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.closeModel}
                    />
                )}

                { !(iraAccepted) && flag && iraContributionWarning != '' && this.isIRA() && plan === RECURRING_TRANSFER_PLAN.AIP && !(isError) && (
                    <ConformationModal
                        modalTitle={childrenState.length != 0 && 
                            childrenState[0].accountSelected.accountType && 
                            childrenState[0].accountSelected.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ?'Coverdell Maximum Contribution Warning':'IRA Maximum Contribution Warning'}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText="Proceed"
                        secondaryButtonText="Cancel"
                        onPrimaryClick={this.handleProceed}
                        onSecondaryClick={this.handleCancel}
                    />
                )}

                {isError && (
                    <ConfirmModal
                        modalTitle={childrenState.length != 0 && 
                            childrenState[0].accountSelected.accountType && 
                            childrenState[0].accountSelected.accountType.toLowerCase().includes(TransactionConstants.COVERDELL_EDUCATION_IRA) ?'Coverdell Maximum Contribution Warning':'IRA Maximum Contribution Warning'}
                        modalBodyText={iraContributionWarning}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.handleCancel}
                    />
                )}

                {hasError && (
                    <WMessageBar
                        error
                        onClose={this.onNotificationClose}
                        text={this.displayError(errorsData)}   // in this moment it is the default error message
                        analyticsPageName="Edit | Error Notification"
                        doNotClose
                    />
                )}
                {loading && <WSpinner loading={loading} />}
                { <Authenticate showAuthBox={showAuthBox}  moveNextPage={this.moveNextPage} onNotificationForFis={this.onNotificationForFis} />}
                <div>
                    <div className="container">{this.switchComponent()}</div>
                    <div ref={this.bottomNav} />
                </div>
            </React.Fragment>
        );
    }
}

EditInvestmentOrWithdrawalPlan.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    accOpeningReducerData: PropTypes.instanceOf(Object),
    getBankAccountInfo: PropTypes.func,
    getAccountList: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    bankAccountReducerData: PropTypes.instanceOf(Object),
    rmdReducerData: PropTypes.instanceOf(Object),
    clearFundPlanInfo: PropTypes.instanceOf(Object),
    fundPlanData: PropTypes.instanceOf(Object),
    // addPADDetails: PropTypes.func,
    isSuccessfullySaved: PropTypes.bool,
    // dismissAddError: PropTypes.func,
    // clearFundAccountList: PropTypes.func,
    clearBankAccDetails: PropTypes.func,
    getFundAccountById: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    analyzeFormAdandonment: PropTypes.func,
    dismissUpdateError: PropTypes.func,
    updatePADDetails: PropTypes.func,
    updateSWPDetails: PropTypes.func,
    plan: PropTypes.string,
    hasError: PropTypes.bool,
    isSaving: PropTypes.bool,
    errorsData: PropTypes.instanceOf(Object),
    getIRAContributionValues: PropTypes.func,
    cancelIRAContributionWarning: PropTypes.func,
    acceptIRAContributionWarning: PropTypes.func,
};

EditInvestmentOrWithdrawalPlan.defaultProps = {
    accOpeningReducerData: {},
    getBankAccountInfo: () => { },
    getAccountList: () => { },
    fundAccountData: {},
    bankAccountReducerData: {},
    // addPADDetails: () => { },
    isSuccessfullySaved: false,
    // dismissAddError: () => { },
    // clearFundAccountList: () => { },
    clearBankAccDetails: () => { },
    getFundAccountById: () => { },
    analyzeClickEvent: () => { },
    analyzeFormAdandonment: () => { },
    dismissUpdateError: () => { },
    updatePADDetails: () => { },
    updateSWPDetails: () => { },
    rmdReducerData: {},
    clearFundPlanInfo: {},
    fundPlanData: {},
    plan: '',
    hasError: false,
    isSaving: false,
    errorsData: {},
    getIRAContributionValues: () => {},
    cancelIRAContributionWarning: () => {},
    acceptIRAContributionWarning: () => {},
};

export default EditInvestmentOrWithdrawalPlan;
