const data = {
    title: 'Check your Inbox - Email Verification required',
    entered: 'You entered ',
    asEmail: ' as the email address that you will use to sign in to your account.',
    info: "To complete your registration, we need to verify that this email address belongs to you.",
    doNotSee: "If you do not see the validation email in your inbox, Click ",
    resend: "Resend",
    resentEmail: 'We have sent you a verification link again, please check your email',
    verify: " Verification",
    headerText: 'Verify Email',
    backText: 'Back to Personal detail',
    styles: {
        nextStyle: {
            display: 'none'
        },
        header: {
            width: "100%",
            marginTop: '40px'
        },
        info: {
            font: '500 14px/20px "benton-sans"',
            color: '#56565A',
            marginBottom: 15
        },
        infoEnd: {
            font: '500 14px/20px "benton-sans"',
            color: '#56565A',
            marginTop: 4,
            marginBottom: 15
        },
        button: {
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            font: '600 14px/17px benton-sans',
            color: '#004A98',
            display: 'block',
            float: 'left'
        },
        doNotSeeStyle: {
            float: 'left',
            display: 'block',
            marginRight: '3px'
        },
        verifyStyle: {
            float: 'left',
            display: 'block',
            marginLeft: '3px'
        }
    }
}

export default data;