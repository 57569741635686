import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';
import styles from '../CommonStyles';

const CustomWInputControlCol = styled(Col)`
    input {
        height: 50px;
        border: 1px solid #d2d2d2;
        border-radius: 0;
        width: 100%;
        padding: 6px 12px;
        color: rgba(73, 73, 74, 1);
        &.prefilled[readonly] {
            background-color: transparent;
            opacity: 1;
            border: none;
            padding-left: 0;
        }
        &[readonly] {
            background-color: rgba(250, 250, 250, 1);
            border-color: #d2d2d2;
        }
        &:hover {
            border-color: rgba(72, 109, 144, 1);
        }
        &:focus {
            outline:none;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
            border-color: rgba(0, 74, 152, 1);
        }
        &[readonly]:focus,
        &[readonly]:hover {
            border-color: #d2d2d2;
        }
    }
`;
const CustomDiv = styled.div`
    .form-group {
        margin-bottom: 25px;
        align-items: center;
    }
`;

const SubTitle = styled.div`
    font-size: 10px;
    font-weight: 600;
    color:#56565a;
`

const assignObj = obj => {
    return obj;
};
const optionalText = '[Optional]';

const numberTypeInput = e => {
    if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189 || e.keyCode === 190) {
        e.preventDefault();
    }
};

const decimalTypeInput = e => {
    const isValid =
        (e.keyCode > 47 && e.keyCode < 58) ||
        (e.keyCode > 95 && e.keyCode < 106) ||
        e.keyCode === 110 ||
        e.keyCode === 190 ||
        e.keyCode === 8 ||
        e.keyCode === 9 ||
        e.keyCode === 37 ||
        e.keyCode === 39;
    if (!isValid) {
        e.preventDefault();
    }
};

const zipcodeTypeInput = e => {
    const isValid =
        (e.keyCode > 47 && e.keyCode < 58) ||
        (e.keyCode > 95 && e.keyCode < 106) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 189;
    if (!isValid) {
        e.preventDefault();
    }
};

const onlyAlphabets = e => {
    const isValid =
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 32;
    if (!isValid) {
        e.preventDefault();
    }
};

const WInput = React.forwardRef((props, ref) => {
    const {
        labelsm,
        labelmd,
        value,
        name,
        onChange,
        onBlur,
        arialabelledby,
        isTabIndex,
        required,
        labelstyle,
        label,
        valuesm,
        valuemd,
        type,
        placeholder,
        errortext,
        inputstyle,
        optional,
        sublabel,
        inputfieldstyle,
        readOnly,
        viewOnly,
        maxlength,
        arialabel,
        id,
        noGutters,
        errorstyle,
        as,
        disabled,
        inputclassname,
        subtype,
        infoMsg,
        dataName,
        dataFormField,
        infoMsgStyle,
        subtitle,
        ariaAssertiveVal,
        errorDataTestId=''
    } = props;
    const iserror = errortext !== undefined && errortext !== '' && errortext !== null;
    const errorStyleBorder = iserror ? { borderColor: '#E60000', borderWidth: '2px' } : {};
    const labelProps = labelsm ? { sm: labelsm } : {};
    const additionalProps = {};
    if (arialabel) additionalProps['aria-label'] = arialabel;
    if (arialabelledby) additionalProps['aria-labelledby'] = arialabelledby;
    return (
        <CustomDiv className="container-fluid" style={styles.paddingZeroStyle}>
            <Form.Group as={Row} noGutters={noGutters} style={assignObj({ ...inputstyle })}>
                <Form.Label
                    column
                    {...labelProps}
                    htmlFor={id}
                    style={assignObj({ ...styles.inputLabelText, ...labelstyle })}
                    className="formLabel"
                    sm={labelsm}
                    md={labelmd}
                >
                    {label}
                    {optional === 'true' ? (
                        <span style={assignObj({ ...styles.subLabel, ...sublabel })}>{optionalText}</span>
                    ) : null}
                </Form.Label>
                {viewOnly ? (
                    <div className="container-fluid" style={styles.paddingZeroStyle}>
                        {value}
                    </div>
                ) : (
                    <CustomWInputControlCol sm={valuesm} md={valuemd}>
                        <Form.Control
                            name={name}
                            id={id}
                            as={as}
                            type={type}
                            onKeyDown={assignObj(e => {
                                if (type === 'number') {
                                    numberTypeInput(e);
                                } else if (subtype === 'transaction') {
                                    decimalTypeInput(e);
                                } else if (subtype === 'zipcode') {
                                    zipcodeTypeInput(e);
                                } else if (subtype === 'characters') {
                                    onlyAlphabets(e);
                                }
                            })}
                            // commenting this code as it is giving console errors.If possible do this kind of validation in ur componnet using utils.Please check properly before commiting code  onKeyPress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                            disabled={disabled}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            readOnly={readOnly}
                            maxLength={maxlength}
                            aria-labelledby={arialabelledby}
                            aria-label={arialabel}
                            required={required}
                            aria-required={required}
                            aria-invalid={!!errortext}
                            style={assignObj({ ...inputfieldstyle, ...errorStyleBorder })}
                            aria-describedby={`${ariaAssertiveVal} ${id}inputErrorText`}
                            className={inputclassname}
                            ref={ref}
                            aria-disabled={disabled || readOnly}
                            data-name={dataName}
                            data-form-field={dataFormField || null}
                            {...additionalProps}
                        />
                        {!!subtitle && <SubTitle id={`${id}-hint`}className="mt-1" style={{fontSize:'12px', whiteSpace: 'nowrap'}}>{subtitle}</SubTitle>}
                        {iserror ? (
                            <Form.Text
                                style={assignObj({ ...styles.errorStyle, ...errorstyle })}
                                role="region"
                                id={assignObj(`${id}inputErrorText`)}
                                aria-live="polite"
                                aria-atomic="true"
                            >
                                {errortext && <span className="sr-only">Error:</span>}
                                <span
                                    data-testid={errorDataTestId}
                                    className="analytics-error-field"
                                    style={{fontWeight:600, fontSize:'12px'}}
                                    // id={assignObj(`${id}inputErrorText`)}
                                   // aria-describedby={assignObj(`${id}inputErrorText`)}
                                    // aria-live="assertive"
                                    // aria-atomic="true"
                                >
                                    {errortext}
                                </span>
                            </Form.Text>
                        ) : null}
                        {infoMsg ? (
                            <Form.Text
                                id={ariaAssertiveVal}
                                aria-live="polite"
                                aria-atomic="true"
                                style={assignObj({ ...styles.infoMessage, ...infoMsgStyle })}
                            >{infoMsg}</Form.Text>
                        ) : null}
                    </CustomWInputControlCol>
                )}
            </Form.Group>
        </CustomDiv>
    );
});

WInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    viewOnly: PropTypes.bool,
    maxlength: PropTypes.number,
    arialabel: PropTypes.string,
    arialabelledby: PropTypes.string,
    isTabIndex: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    labelstyle: PropTypes.instanceOf(Object),
    inputstyle: PropTypes.instanceOf(Object),
    valuesm: PropTypes.number,
    valuemd: PropTypes.number,
    labelsm: PropTypes.number,
    labelmd: PropTypes.number,
    errortext: PropTypes.string,
    optional: PropTypes.string,
    sublabel: PropTypes.instanceOf(Object),
    inputfieldstyle: PropTypes.instanceOf(Object),
    infoMsgStyle: PropTypes.instanceOf(Object),
    errorstyle: PropTypes.instanceOf(Object),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    noGutters: PropTypes.bool,
    as: PropTypes.string,
    disabled: PropTypes.bool,
    inputclassname: PropTypes.string,
    subtype: PropTypes.string,
    infoMsg: PropTypes.string,
    dataName: PropTypes.string,
    dataFormField: PropTypes.bool,
    subtitle: PropTypes.string,
    ariaAssertiveVal: PropTypes.string,
    // defaultval: PropTypes.number
};

WInput.defaultProps = {
    errorDataTestId: '',
    id: '',
    label: 'Label',
    as: 'input',
    type: 'text',
    readOnly: false,
    viewOnly: false,
    maxlength: 20,
    arialabelledby: '',
    isTabIndex: true,
    required: false,
    arialabel: '',
    name: '',
    placeholder: '',
    labelsm: 12,
    labelmd: 4,
    valuesm: 12,
    valuemd: 5,
    value: '',
    labelstyle: {},
    inputstyle: {},
    errortext: '',
    sublabel: {},
    inputfieldstyle: {},
    infoMsgStyle: {},
    errorstyle: {},
    optional: 'false',
    onChange: () => {},
    onBlur: () => {},
    noGutters: true,
    disabled: false,
    inputclassname: '',
    subtype: '',
    infoMsg: '',
    dataName: '',
    dataFormField: false,
    // defaultval:0
    subtitle: '',
    ariaAssertiveVal: ''
};
export default WInput;
