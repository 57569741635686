import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Accordian, AccordianTitle } from 'common';
import constants from '../../constant';

const Description = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top: 1.875rem;
`;
const FlexDiv = styled.div`
    display: flex;
    margin-top: 1.875rem;
`;
const MethodType = styled.div`
    flex: 1;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const CostBasicValue = styled.div`
    flex: 3;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

class TaxAccounting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { TaxAccountingFields } = constants;
        const { title = '', description = '', method } = TaxAccountingFields;
        const { methodkey = '', methodvalue = '' } = method;
        const { costBasis } = this.props;
        const costBasisValue = `${costBasis} ${methodvalue}`;
        return (
            <>
                <Accordian
                    key={title}
                    titleChild={<AccordianTitle accType={title} id={title} accIndex={0} />}
                    titleSeperator
                    labelledBy={title}
                >
                    <>
                        <Description>{description}</Description>
                        <FlexDiv>
                            <MethodType>{methodkey}</MethodType>
                            <CostBasicValue>{costBasisValue}</CostBasicValue>
                        </FlexDiv>
                    </>
                </Accordian>
            </>
        );
    }
}

TaxAccounting.propTypes = {
    costBasis: PropTypes.string,
};
TaxAccounting.defaultProps = {
    costBasis: '',
};
export default TaxAccounting;
