// TODO: If any new account tpye got added please added in the defaultCategoryList

import { SessionStorageHelper } from "utils/sessionHelper";
import CryptoJS from 'crypto-js';

// and push to  it based on the accountType and ownerType code
const categorizeByAccountType = dataList => {
  const defaultCategoryList = [
    {
      accountType: "General Investment Account",
      accounts: []
    },
    {
      accountType: "IRA Account",
      accounts: []
    },
    {
      accountType: "UTMA Account",
      accounts: []
    }
  ];

  dataList.forEach(item => {
    if (item.accountType === "100") {
      if (item.ownerType === "7") {
        defaultCategoryList[2].accounts.push(item);
      } else {
        defaultCategoryList[0].accounts.push(item);
      }
    } else {
      defaultCategoryList[1].accounts.push(item);
    }
  });
  return defaultCategoryList.filter(item => item.accounts.length);
};

export const removeFileExtension = (fileNamePlusExtension = '') => {
  const regex = /\.[A-Za-z0-9]{3,4}\b/gi;
  return fileNamePlusExtension.replace(regex, '');
};

export default categorizeByAccountType;


/* 
    Function to return token value from session
*/
export function getTokenFromSession()
{
    //return JSON.parse(sessionStorage.getItem("msr_dts")).accesstoken; 
    return getSessionValues()?.accesstoken; 
}

export function getSessionValues()
{
  //return JSON.parse(sessionStorage.getItem("msr_dts"));
  return JSON.parse(getUnParsedSessionValues());
}

export function getUnParsedSessionValues()
{
  const encySalt = 'E34078F0DE278AAB608B6A286A64B3CE99D7B3E8232A5B8F11BDF60772D0BB58';
  const decData = CryptoJS.AES.decrypt(SessionStorageHelper.getItem('msr_dts'), encySalt).toString(CryptoJS.enc.Utf8);
  //return JSON.parse(sessionStorage.getItem("msr_dts"));
  return decData;
}
export function setMSRInfoValue(value)
{
  const encySalt = 'E34078F0DE278AAB608B6A286A64B3CE99D7B3E8232A5B8F11BDF60772D0BB58';
  const encData = CryptoJS.AES.encrypt(JSON.stringify(value), encySalt).toString();
  SessionStorageHelper.setItem('msr_dts',encData)
}

export const createDocTitle = ({ title = '', fileName = '' }) =>
    title ? removeFileExtension(title) : removeFileExtension(fileName);
    
export const sortDocumentList = (list = [], order = 'auto') => {
  try {
      let o = order;

      if (list.length <= 1) {
          // console.log('premature');
          return list;
      }

      if (o === 'auto') {
          const firstDate = new Date(list[0].createdDate);
          const lastDate = new Date(list[list.length - 1].createdDate);
          o = firstDate < lastDate ? 'descending' : 'ascending';
      }

      if (o === 'descending' || (typeof order === 'boolean' && !order)) {
          return list
              .slice()
              .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
              .map(record => ({ ...record, docTitle: createDocTitle(record) }));
      }

      if (o === 'ascending' || (typeof o === 'boolean' && order)) {
          return list
              .slice()
              .sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))
              .map(record => ({ ...record, docTitle: createDocTitle(record) }));
      }

      return list;
  } catch {
      return list;
  }
};
