import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { RELATED_ARTICLES } from './Constants';
import LeftArrow from '../../assets/rightArrow_green.png';
import MutualFund from '../../assets/ra-mutualfund.png';
import ETF from '../../assets/ra-ETF.png';
import EducationalSavings from '../../assets/ra-educational-savings.png';
import Retirement from '../../assets/ra-retirement.png';

const articles = [
    { id: 1, img: MutualFund, name: 'What is Mutual Fund?' },
    { id: 2, img: ETF, name: 'What is an exchange traded fund (ETF)?' },
    { id: 3, img: EducationalSavings, name: 'What is a 529 Educational Savings?' },
    { id: 4, img: Retirement, name: 'How can I plan for retirement?' },
];
const RelatedArticlesRow = styled(Row)`
    margin-bottom: 27px;
    font-weight: bold;
    font-size: 18px;
    font-family: benton-sans;
    color: #49494a;
    display: block;
`;
const ArticleImg = styled.img`
    width: 51px;
    height: 51px;
`;

const ArticleRow = styled(Row)`
    padding: 20px 0;
    border-bottom: 1px solid gray;
    :last-child {
        padding-bottom: 50px;
    }
`;

const ArticleNameCol = styled(Col)`
    font-weight: 700;
    font-size: 16px;
    font-family: benton-sans;
    color: #004a98;
    padding: 7px 0 0 14px;
    float: left;
`;
function RelatedArticlesComponent() {
    return (
        <>
            <RelatedArticlesRow>{RELATED_ARTICLES}</RelatedArticlesRow>
            {articles.map(article => (
                <ArticleRow key={article.id}>
                    <Col sm={2}>
                        <ArticleImg src={article.img} alt="article" />
                    </Col>
                    <ArticleNameCol sm={8}>{article.name}</ArticleNameCol>
                    <Col sm={2}>
                        <img src={LeftArrow} alt="arrow" />
                    </Col>
                </ArticleRow>
            ))}
        </>
    );
}

export default RelatedArticlesComponent;
