
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import makeApiRequest from "../Network/ApiInterface";



export const ammendActions = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.AMEND_METHODS_SUCCESS,
        ...data
    });
};


const replaceUndefinedOrNull = (key, value) => {
    if (value === null || value === undefined || value === '') {
        return undefined;
    }
    return value;
};

const getCleanedPayload = (payload) => {
    const cleanedObject = JSON.stringify(payload, replaceUndefinedOrNull, 4);
    return JSON.parse(cleanedObject);
};

export const getTransactionData = payload => (dispatch) => {
    const payloadApi = getCleanedPayload(payload);
    const env = 'GET_PENDINGTRANSACTION';
    dispatch({
        type: ActionTypes.GET_PENDINGTRANSACTION_DATA_LOADING,
    });
    makeApiRequest(
        ActionTypes.GET_PENDINGTRANSACTION_DATA,
        payloadApi,
        env,
        response => {
            if (response.data !== null && response.data !== undefined) {
                if (response.status !== null && response.status !== undefined) {
                    if (response.status === "200" || response.status === 200) {
                        dispatch({
                            type: ActionTypes.GET_PENDINGTRANSACTION_DATA_SUCCESS,
                            [ActionTypes.GET_PENDINGTRANSACTION_DATA]: response.data
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.GET_PENDINGTRANSACTION_DATA_ERROR,
                            [ActionTypes.GET_PENDINGTRANSACTION_DATA]: response.data

                        });
                    }
                }
            } else {
                dispatch({
                    type: ActionTypes.GET_PENDINGTRANSACTION_DATA_ERROR,
                });
            }

        },
        error => {
           // console.log("error");
            dispatch({
                type: ActionTypes.GET_PENDINGTRANSACTION_DATA_ERROR,
                response: error
            });
        }
    );
};

export const cancelTransaction = payload => (dispatch) => {
    const payloadApi = getCleanedPayload(payload);
    const env = 'CANCEL';
    dispatch({
        type: ActionTypes.CANCEL_DATA_LOADING,
    });
    makeApiRequest(
        ActionTypes.CANCEL_DATA,
        payloadApi,
        env,
        response => {
            //  console.log("cancel transactions", JSON.stringify(response));
            if (response.data !== null && response.data !== undefined) {
                if (response.status !== null && response.status !== undefined) {
                    if (response.status === "200" || response.status === 200) {
                        //  console.log("cancel success actions",response.data.status);
                        dispatch({
                            type: ActionTypes.CANCEL_DATA_SUCCESS,
                            [ActionTypes.CANCEL_DATA]: response.data
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.CANCEL_DATA_ERROR,
                            [ActionTypes.CANCEL_DATA]: response.data

                        });
                    }
                }
            } else {
                dispatch({
                    type: ActionTypes.CANCEL_DATA_ERROR,
                });
            }

        },
        error => {
           // console.log("error");
            dispatch({
                type: ActionTypes.GET_PENDINGTRANSACTION_DATA_ERROR,
                response: error
            });
        }
    );
};
