import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constant from '../Common/AccountDashboardConstants';

export default class HighChartErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // error: '',
            errorInfo: '',
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        // console.log({ error, errorInfo });
        this.setState({ errorInfo });
    }

    render() {
        const { hasError, errorInfo } = this.state;
        const {children}=this.props
        const { componentStack = '' } = errorInfo || {}
        const errorData = componentStack.toString
        const { assignObj } = constant
        const highChartErrror = "There was an error in rendering highcharts."
        const detailsError="Click for error details"
        if (hasError) {
            return (
                <div className="col-12 col-md-12">
                    <p style={assignObj({ color: 'red', marginBottom: 0 })}>
                        {highChartErrror}{' '}
                        {/* <span
                                style={{ cursor: 'pointer', color: '#0077FF' }}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Reload this page
                        </span>{' '} */}
                    </p>
                    <div>
                        <details className="error-details" style={assignObj({ fontSize: '11px' })}>
                            <summary>{detailsError}</summary>
                            {errorData}
                        </details>
                    </div>
                </div>
            );
        }
        // next code block goes here
        return children;
    }
}


HighChartErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};