const pageHeading = 'Traditional IRA Account';
const states = {
    key: 'states',
    value: [
        { key: 'AL', value: 'Alabama' },
        { key: 'AK', value: 'Alaska' },
        { key: 'AZ', value: 'Arizona' },
        { key: 'AR', value: 'Arkansas' },
        { key: 'CA', value: 'California' },
    ],
};
const offButtonStyle = {
    borderColor: '#56565A',
    backgroundColor: '#B7B7B7',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
    height: '40px',
    background: '#818181DD 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1,
};
const offButtonStyleDisable = {
    borderColor: '#56565A',
    borderRadius: 30,
    borderWidth: 1,
    width: '50%',
    height: '40px',
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
};
const onButtonStyle = {
    backgroundColor: '#B7B7B7',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '40px',
    background: '#818181DD 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '21px',
    opacity: 1,
};
const onButtonStyleDisable = {
    borderRadius: '25px !important',
    borderColor: '#56565A',
    zIndex: -1,
    left: '16%',
    borderWidth: 1,
    height: '35px',
    width: '50%',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
};
const TextOnStyle = {
    color: '#56565A',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%',
};
const TextOffStyle = {
    color: '#A2A2A2',
    fontWeight: 'bold',
    marginLeft: '30%',
    marginRight: '10%',
};
const dateOfService = 'Dates of Service';
const erremailAdd = 'Please enter a valid email';
const errServiceToDate = 'End date cannot be prior to Start date';
const optional = '[ Optional ]';
const deliveryHint =
    'You will still get paper documents if other account holders have selected that option. For credit accounts, monthly statements will not be mailed if any account holder has selected online';
const FAQ1 =
    'Some documents cannot be immediately available in large Print, Braille and/or Screen Reader formats Have questions? View our';
const FAQ2 = 'for additional assistance.';
const FAQLink = 'FAQ page';
const seasonalHeading = 'Add - New Interested Party - Personal Information';
const activeEditUrl = 'Add';
const editPreferencesUrls = [
    { url: '/', name: 'Account Dashboard' },
    { url: '/', name: 'Account Services' },
    { url: '/accountPreference', name: 'Manage Interested Parties' },
];
const accountFields = [
    { key: 'Account Name', value: 'Sample Content' },
    { key: 'Account Number', value: '56654654' },
];

export default {
    activeEditUrl,
    accountFields,
    editPreferencesUrls,
    offButtonStyleDisable,
    onButtonStyle,
    TextOffStyle,
    offButtonStyle,
    onButtonStyleDisable,
    TextOnStyle,
    pageHeading,
    dateOfService,
    errServiceToDate,
    erremailAdd,
    states,
    optional,
    deliveryHint,
    FAQ1,
    FAQ2,
    FAQLink,
    seasonalHeading,
};
