import { combineReducers } from 'redux';
import messageCenterData from './MessageCenterReducer';
import loginData from './LoginReducer';
import accOpeningReducerData from './AccOpeningReducer';
import initialAppData from './InitialReducer';
import masterLookUpData from './MasterLookUpDataReducer';
import profileInformationData from './ProfileSettingsReducer';
import profileRelationshipData from './ProfileRelationReducer';
import addBankAccountData from './AddBankAccountReducer';
import localStateManagementReducerData from './LocalStateManagementReducer';
import addressFormatData from './AddressFormatReducer';
import accMessagingDocumentsData from './AccMessagingDocumentsReducer';
import accMessagingAdviceData from './AccMessagingAdviceReducer';
import accMessagingDeviceData from './AccMessagingDeviceReducer';
import memberPreferencesData from './MemberPreferencesReducer';
import orderCheckBookReducerData from './OrderCheckBookReducer';
import dividentsReducerData from './DividentsReducer';
import bankAccountReducerData from './BankAccountReducer';
import transactionCompositeDataReducerData from './TransactionCompositeDataReducer';
import manageInterestedPartiesData from './ManageIntrestedPartiesReducer';
import manageBeneficiaryData from './ManageBeneficiaryReducer';
import manageBeneficiariesMasterRegsData from './ManageBeneficiariesMasterRegsReducer';
import accountData from './AccManagementReducer';
import automaticInvestmentData from './AutomaticInvestmentReducer';
import systematicWithdrawalData from './SystamaticWithdrawalReducer';
import investmentWithdrawSaveReducer from './InvestmentWithdrawSaveReducer';
import popularBankReducerData from './AddPopularBankAccountReducer';
import liquidationData from './LiquidationReducer';
import amendReducerData from './AmendReducer';
import purchaseData from './purchaseReducer';
import exchangeData from './ExchangeReducer';
import accountPositionsData from './AccountPositionsReducer';
import accountPreferenceData from './InvestmentAccountsPreferenceReducer';
import rmdReducerData from './RMDReducer';
import documentPreferenceData from './DocumentCenterReducer';
import tradingWindowData from './TradingWindowReducer';

import formsData from './FormsReducer';
import contactCenterData from './ContactCenterReducer';
import secureMessageData from './SecureMessageReducer';
import fundAccountData from './FundAccountReducer';

import moneyAndAssetData from './MoneyAndAssetReducer';
import dashboardTransViewData from './DashboardTransViewReducer';
import marketingAndOffersData from './MarketingAndOffersReducer';

import accountActivityData from './AccountActivityReducer';
import transactionHolderData from './TransactionHistoryReducer';
import memberDashboardData from './MemberDashboardReducer';
import memberNewDashboardReducer from './MemberNewDashboardReducer';
import accountDetailsGuestData from './DashboardAccGuestReducer';
import accessForms from './AccessFormsReducer';
import downloadedFileData from './DownloadFileReducer';

import transactionActivityData from './TransactionActivityReducer';
import masterRegistrationAccountData from './MasterRegistrationAccountReducer';

import dashBoardLinkReducer from './DashBoardLinkReducer';
import analytics from './AnalyticsReducers';

import liveChatReducerData from './LiveChatReducer';
import AccountRegistrationReducer from './AccountRegistrationReducer';

import manageAccountPreferenceData from './ManageAccountPreferenceReducer';
import bankToFundData from './BankToFundReducer';

import msrFlowDetails from './MsrReducer';
import addressReducer from './AddressReducer';

import fundPlanData from './FundPlanReducer';
import specialtyApplicationData from './SpecialtyApplicationReducer';

import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";
import marketNAOReducerData from './MarketNAOReducer'


const appReducers = combineReducers({
    analytics,
    initialAppData,
    loginData,
    accOpeningReducerData,
    masterLookUpData,
    profileInformationData,
    profileRelationshipData,
    addBankAccountData,
    localStateManagementReducerData,
    addressFormatData,
    accMessagingDocumentsData,
    accMessagingAdviceData,
    accMessagingDeviceData,
    orderCheckBookReducerData,
    dividentsReducerData,
    bankAccountReducerData,
    manageInterestedPartiesData,
    manageBeneficiaryData,
    manageBeneficiariesMasterRegsData,
    memberPreferencesData,
    accountData,
    automaticInvestmentData,
    systematicWithdrawalData,
    investmentWithdrawSaveReducer,
    popularBankReducerData,
    liquidationData,
    amendReducerData,
    purchaseData,
    exchangeData,
    accountPositionsData,
    accountPreferenceData,
    rmdReducerData,
    formsData,
    contactCenterData,
    secureMessageData,
    moneyAndAssetData,
    fundAccountData,
    fundPlanData,
    dashboardTransViewData,
    documentPreferenceData,
    marketingAndOffersData,
    messageCenterData,
    accountActivityData,
    transactionHolderData,
    memberDashboardData,
    memberNewDashboardReducer,
    accountDetailsGuestData,
    accessForms,
    downloadedFileData,
    transactionActivityData,
    masterRegistrationAccountData,
    transactionCompositeDataReducerData,
    liveChatReducerData,
    dashBoardLinkReducer,
    tradingWindowData,
    msrFlowDetails,
    AccountRegistration: AccountRegistrationReducer,
    manageAccountPreferenceData,
    address: addressReducer(),
    mailingAddress: addressReducer('mailingAddress'),
    physicalAddress: addressReducer('physicalAddress'),
    bankToFundData,
    specialtyApplicationData,
    marketNAOReducerData
});

// to clear all reducer data when user logs out
const rootReducer = (state, action) => {
    let data = state;
    if (action.type === ActionTypes.USER_LOGOUT) {
        data = undefined;
    }
    return appReducers(data, action);
};

export default rootReducer;
