


/* eslint-disable react/jsx-no-literals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isEmpty } from 'lodash';
import { isTablet } from 'react-device-detect';
import PrintPdf from './PrintPdf';
import VCMCheckbox from '../../components/inputs/VCMCheckbox';
// import VictoryCapitalOnlineAgreement from '../../../../assets/PDF/victory-capital-online-agreement.pdf';
// import VictoryCapitalPrivacyStatement from '../../../../assets/PDF/victory-privacy-statement.pdf';
// import VictoryCapitalTermsOfService from '../../../../assets/PDF/victory-capital-terms-of-service.pdf';
import WStepper from '../../components/WStepper';
import consts from '../../components/constants';
import iconPDf from '../../../../assets/icon-pdf.png';

import './TermsAndCondition.css';
import analytics from '../../components/utils.analytics';
import envConfig from 'env/EnvConfig';

const VictoryCapitalOnlineAgreement = {
  url: envConfig.TERMS_PDF_AGREEMENT,
  withCredentials: true
}

const VictoryCapitalPrivacyStatement = {
  url: envConfig.TERMS_PDF_PRIVACY,
  withCredentials: true
}

const VictoryCapitalTermsOfService = {
  url: envConfig.TERMS_PDF_SERVICE,
  withCredentials: true
}

const assignObj = (obj) => obj;
class TermsAndCondition extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      pdfFile: null,
      showPdf: false,
      type: '',
      isEnabledTerms: false,
      isEnabledAgreement: false,
      isEnabledPrivacy: false,
      isTermsChkDisabled: true,
      isAgreeChkDisabled: true,
      isPrivacyChkDisabled: true,
      errorMsg: '',
      isValidESign: false,
      signedName: '',
      currentDate: `${date.getMonth()
        + 1}/${date.getDate()}/${date.getFullYear()}`,
    };
    this.handleHide = this.handleHide.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
    this.handleChangeTerms = this.handleChangeTerms.bind(this);
    this.handleChangeAgreement = this.handleChangeAgreement.bind(this);
    this.handleeSignChange = this.handleeSignChange.bind(this);
    this.GIACTCustomerVerification = this.GIACTCustomerVerification.bind(this);
    this.agreeRef = React.createRef(this);
    this.privacyRef = React.createRef(this);
    this.termsRef = React.createRef(this);
  }

  componentDidMount() {
    const { resetFlag, history, accountInfo } = this.props;
    
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
    }
    resetFlag();
  }

  onBlurInput(e) {
    const { firstname, lastname } = this.props;

    const { value } = e.target;
    const txtValue = value.trim();

    if (
      e.target.value !== ''
      && txtValue.toLowerCase()
        !== `${firstname.toLowerCase()} ${lastname.toLowerCase()}`
    ) {
      this.setState({
        errorMsg: `First & Last name not matching with ${firstname} ${lastname}`,
        isValidESign: false,
      });
    }
    if (
      txtValue.toLowerCase()
      === `${firstname.toLowerCase()} ${lastname.toLowerCase()}`
    ) {
      this.setState({ signedName: txtValue, isValidESign: true });
    }
  }

  handleHide(pdfType) {
    this.setState({ showPdf: false });
    switch (pdfType) {
    case 'VCOnlineAgree':
      this.agreeRef.current.focus();
      break;
    case 'VCPrivacy':
      this.privacyRef.current.focus();
      break;
    case 'VCTerms':
      this.termsRef.current.focus();
      break;
    default:
      break;
    }
  }

  handleButtonClick(e) {
    let file;
    let {
      isTermsChkDisabled,
      isPrivacyChkDisabled,
      isAgreeChkDisabled,
    } = this.state;

    const {target} = e;

    const pdfType = target.name;
    // setType(pdfType);
    switch (pdfType) {
    case 'VCOnlineAgree':
      analytics.triggerClickTrackEvent(
        'Preregister_termsandconditions_Victory_Capital_Online_Agreement',
      );
      file = VictoryCapitalOnlineAgreement;
      isAgreeChkDisabled = false;
      break;
    case 'VCPrivacy':
      analytics.triggerClickTrackEvent(
        'Preregister_termsandconditions_Victory_Capital_Privacy_Notice',
      );
      file = VictoryCapitalPrivacyStatement;
      isPrivacyChkDisabled = false;
      break;
    case 'VCTerms':
      analytics.triggerClickTrackEvent(
        'Preregister_termsandconditions_Victory_Capital_Terms_of_Service',
      );
      file = VictoryCapitalTermsOfService;
      isTermsChkDisabled = false;
      break;
    default:
      break;
    }
    this.setState({
      type: pdfType,
      pdfFile: file,
      showPdf: true,
      isAgreeChkDisabled,
      isPrivacyChkDisabled,
      isTermsChkDisabled,
    });
  }

  handleChangePrivacy(newValue) {
    this.setState({ isEnabledPrivacy: newValue });
  }

  handleChangeTerms(newValue) {
    this.setState({ isEnabledTerms: newValue });
  }

  handleChangeAgreement(newValue) {
    this.setState({ isEnabledAgreement: newValue });
  }

  GIACTCustomerVerification() {
    const {
      isEnabledPrivacy,
      isEnabledTerms,
      isEnabledAgreement,
      isValidESign,
      signedName,
      currentDate,
    } = this.state;
    const { history, storeESign } = this.props;

    if (
      isEnabledPrivacy
      && isEnabledTerms
      && isEnabledAgreement
      && isValidESign
    ) {
      storeESign({
        eSign: {
          sign: signedName,
          date: currentDate,
        },
      });
      analytics.triggerClickTrackEvent(
        'Preregister_termsandconditions_Continue',
      );
      history.push('/accountRegistration/userdetails');
    }
  }

  handleeSignChange(e) {
    e.preventDefault();
    const { value } = e.target;

    this.setState({
      signedName: value.trim(),
      errorMsg: '',
    });
  }

  render() {
    const {
      pdfFile,
      showPdf,
      type,
      isPrivacyChkDisabled,
      isAgreeChkDisabled,
      isTermsChkDisabled,
      isEnabledTerms,
      isEnabledPrivacy,
      isEnabledAgreement,
      currentDate,
      errorMsg,
      signedName,
    } = this.state;
    const { pages, termsAndConditionsPDF } = consts;
    const { firstname = '', lastname = '' } = this.props;

    const isValid = isEnabledPrivacy
      && isEnabledTerms
      && isEnabledAgreement
      && signedName.toLowerCase()
        === `${firstname.toLowerCase()} ${lastname.toLowerCase()}`;

    return (
      <div className="milestone">
        <PrintPdf
          file={pdfFile}
          show={showPdf}
          handleHide={this.handleHide}
          type={type}
          maximumModalHeight={window.innerHeight}
        />
        <div className="pad contentWrapper">
          <Container fluid>
            <WStepper currentPage={0} pages={pages} />
            <Row className="headingRow" noGutters>
              <Col xs={12} md={12}>
                <h1 className="pageHeader2">{consts.termsCondition}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="vcmBoxContainer">
                  <span className="madatoryText">{consts.mandatoryText}</span>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangePrivacy}
                      value={isEnabledPrivacy}
                      name="Privacy"
                      isDisabled={isPrivacyChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name1}
                      labelledby="disclaimer_privacy"
                      dataRef={this.privacyRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        name='VCPrivacy'
                        onClick={this.handleButtonClick}
                      >
                        {termsAndConditionsPDF.name1}
                        <span className="hidden-accessible">{consts.opensPop}</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img src={iconPDf} alt="" className="pdfImage" />
                      <br aria-hidden="true" />
                      <span id="disclaimer_privacy" className="tc_disclaimer">
                        By checking this box, you agree that you have reviewed
                        our Privacy Policy and you consent to the collection,
                        use and disclosure of your personal data as set out in
                        our Privacy Policy.
                      </span>
                    </p>
                  </div>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangeTerms}
                      value={isEnabledTerms}
                      name="Terms"
                      isDisabled={isTermsChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name2}
                      labelledby="disclaimer_terms"
                      dataRef={this.termsRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        name='VCTerms'
                        onClick={this.handleButtonClick}
                      >
                        {termsAndConditionsPDF.name2}
                        <span className="hidden-accessible">{consts.opensPop}</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img src={iconPDf} alt="" className="pdfImage" />
                      <br aria-hidden="true" />
                      <span id="disclaimer_terms" className="tc_disclaimer">
                        By checking this box, you agree that you have reviewed
                        and agreed to the Terms of Service contained herein to
                        use our site, including any legal or regulatory
                        restrictions.
                      </span>
                    </p>
                  </div>
                  <div className="vcmBox">
                    <VCMCheckbox
                      onChange={this.handleChangeAgreement}
                      value={isEnabledAgreement}
                      name="Agreement"
                      isDisabled={isAgreeChkDisabled}
                      ariaLabel={termsAndConditionsPDF.name3}
                      labelledby="disclaimer_agreement"
                      dataRef={this.agreeRef}
                      required
                    />
                    <p className="vcmBoxText">
                      <button
                        type="button"
                        className="inlineLInkNew"
                        name='VCOnlineAgree'
                        onClick={this.handleButtonClick}
                      >
                        {termsAndConditionsPDF.name3}
                        <span className="hidden-accessible">{consts.opensPop}</span>
                        {/* <span aria-hidden="true" className="icon-newpage" /> */}
                      </button>
                      <img src={iconPDf} alt="" className="pdfImage" />
                      <br aria-hidden="true" />
                      <span id="disclaimer_agreement" className="tc_disclaimer">
                        By checking this box you confirm your agreement to sign
                        this document electronically and acknowledge that your
                        electronic signature shall have the same force and
                        effect as a physical signature
                      </span>
                      <br aria-hidden="true" />
                      <br aria-hidden="true" />
                      <>
                      <span className="privacyPolicySpan">
                          <span>
                          Note: You can access Privacy Policy, Terms of Service, and signed copy of Online Agreement any time from
                          </span>
                        {' '}
                      <a
                        href="https://www.vcm.com/"
                        className="inlineLInk linkCls privacyPolicyLink"
                        target="_blank"
                        rel="noopener noreferrer"
                            aria-describedby={isTablet ? 'external-link' : null}
                      >
                        www.vcm.com
                            <span
                              className={
                                isTablet ? 'sr-only hidenTablet' : 'sr-only'
                              }
                              aria-hidden={isTablet ? 'true' : 'false'}
                            >
                              External Website. Opens New Window
                            </span>
                        <span
                          aria-hidden="true"
                          className="icon-newpage small-size"
                        />
                      </a>
                        <span>.</span>
                        </span>
                      </>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>


            <Row className="signContanier" noGutters>
              <Col md lg xl>
                <div>
                  <label htmlFor="vcm_name" className="inputLabel">
                    Type your name here
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    id="vcm_name"
                    required
                    aria-required
                    className={errorMsg.length ? 'errorField' : ''}
                    aria-describedby={
                      errorMsg.length
                        ? 'errorMessage vcm_name_desc'
                        : 'vcm_name_desc'
                    }
                    aria-invalid={!!errorMsg.length}
                    onChange={this.handleeSignChange}
                    onBlur={this.onBlurInput}
                  />
                </div>
                <p aria-hidden="true">{`/${firstname} ${lastname}/`}</p>
                {errorMsg.length !== 0 && (
                <p
                  className="errorText"
                  id="errorMessage"
                  aria-live="polite"
                  aria-atomic="true"
                  aria-relevant="all"
                  role="alert"
                >
                  {errorMsg}
                </p>
                )}
                <label className="announceLabel mb-0">
                  <span aria-hidden="true">
                    To accept Terms and Conditions, please type your name
                    exactly the way it&apos;s shown above
                  </span>
                  <span className="sr-only" id="vcm_name_desc">
                    To accept Terms and Conditions, please type your name
                    exactly as
                    {' '}
                    {` ${firstname} ${lastname}`}
                  </span>
                </label>
              </Col>
              <Col lg={3} md={5} className="dateComp">
                <Row>
                  <Col lg={12} md={12}>
                    <div className="dateLblContainer">
                      <label htmlFor="vcm_date" className="inputLabel m-0">
                        Date
                      </label>
                    </div>
                  </Col>

                  <Col lg={12} md={12}>
                    <div className="dateTxtContainer">
                      <input
                        type="text"
                        id="vcm_date"
                        value={`${currentDate}`}
                        readOnly
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <div className="btnWrapper" style={assignObj({ marginTop: 10 })}>
                      <div aria-hidden="true" />
                      <button
                        className="vcmbtn vcmTermsBtn"
                        type="submit"
                        disabled={!isValid}
                        onClick={this.GIACTCustomerVerification}
                      >
                        <span className="vcmBtnText">
                          {!isValid ? consts.termsCondition : consts.continueP}
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

TermsAndCondition.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  storeESign: PropTypes.func,
  resetFlag: PropTypes.func,
  accountInfo: PropTypes.instanceOf(Object),
};

TermsAndCondition.defaultProps = {
  firstname: '',
  lastname: '',
  history: {},
  storeESign: () => {},
  resetFlag: () => {},
  accountInfo: {},
};

export default TermsAndCondition;
