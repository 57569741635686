import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Styles from './Styles';
import ACCESS_FORMS_CONSTANTS from './Constants';

const FormsHeader = props => {
    const {handleselectedTab,hadleFormTypeFilter,setSelectedCategory, selectedCategory,selectedTabTitle,parentTags,selectedTab} = props;
    const [searchVal, setSearchVal] = useState('');
    const [internalSearchVal, setinternalSearchVal] = useState('');
    const [tags,setTags] = useState(parentTags);
  
    const freePDFtext = () => {
        return (
            <Styles.Paragraph>
                {ACCESS_FORMS_CONSTANTS.FREEPDF_1}
                <Styles.Link target='_blank' rel="noopener noreferrer" href={ACCESS_FORMS_CONSTANTS.PRF_URL}>{ACCESS_FORMS_CONSTANTS.PDF}</Styles.Link>
                {ACCESS_FORMS_CONSTANTS.FREEPDF_2}
            </Styles.Paragraph>
        );
    };

    const getKey = (key, index) => {
        return `${key}-${index}`;
    };

    function toggleActive(e,selectedTab,selectedTabTitle) {
        const id = e.target.getAttribute('data-id');

        const newTags = tags.map(tag => {
            const newTag = tag;
            newTag.selected = tag.id === id;
            
            return newTag;
        });       
        setTags(newTags);
        handleselectedTab(selectedTab,selectedTabTitle);
    }

    const hadleRadioButtonOption =(e,selectedType)=>{
        setSelectedCategory(selectedType)
        hadleFormTypeFilter()
    }
    
    useEffect(() => {
        const delayDebouncFun = setTimeout(() => {
            selectedTab != "msr-forms"? props.setSearchItem(searchVal) : props.setSearchInternalVal(internalSearchVal)
        },2000)

        return () => clearTimeout(delayDebouncFun)

    }, [searchVal,internalSearchVal])

    useEffect(()=>{
        selectedTab!=="msr-forms" ? setinternalSearchVal("") : setSearchVal("")
    },[selectedTab])
    
    return (
        <>
            <p>{freePDFtext()}</p>
            {selectedTab != "msr-forms" && (
            <div id="formsTable_filter" class="dataTables_filter">
                <label className='searchlabel'>Search:
                    <input type="search" id="search" class="" onChange={(e)=> setSearchVal(e.target.value)} placeholder="Search by Form name or ID" aria-controls="formsTable" />
                </label>
            </div>
            )}
            <Row className='mt-5'>
                {tags.map((element, index) => (
                    <Col sm={2} className='formtabbox mr-2' key={getKey('popular-col', index)}> 
                       
                        {(!element.selected  && element.count > 0 ) && (
                            <Styles.BoxLink
                                src="#"
                                key={getKey('popular', index)}
                                variant="link"
                                onClick={(e)=>toggleActive(e,element.value,element.title)}
                                data-id={element.id}
                                data-testid={element.id}
                                data-value={element.value}
                            >
                              {element.label}
                            </Styles.BoxLink>
                        )}
                        {element.selected && 
                            <Styles.BoxArrowDown
                                                        
                            >
                                <Styles.BoxArrowDown1>  {element.label}</Styles.BoxArrowDown1>
                            </Styles.BoxArrowDown>}
                    </Col>
                ))}
            </Row>
            
            <div className='category-radio-btn' id="formRadioCategory">
            <Row className="divformRadioCategory" id="divformRadioCategory">
                    <h4>Form Types:</h4>
                    <input type="radio" class="category-radio-btn" id="radioBtn" name="category" value="all" checked={selectedCategory == 'All'} onClick={(e)=>hadleRadioButtonOption(e,'All')}/>All
                    <input type="radio" class="category-radio-btn" name="category" value="service" checked={selectedCategory == 'service'} onClick={(e)=>hadleRadioButtonOption(e,'service')}/>Service
                    <input type="radio" class="category-radio-btn" name="category" value="accountApplication" checked={selectedCategory == 'account-application'} onClick={(e)=>hadleRadioButtonOption(e,'account-application')}/>Account Application
            </Row>
            </div>
            <Row data-testid='form-title'>
                <Styles.FormTitle >{selectedTabTitle}</Styles.FormTitle>
            </Row>
            <Styles.FormHeader data-testid='form-header-cols'>
                <Col sm={2}>{ACCESS_FORMS_CONSTANTS.FORM_ID}</Col>
                <Col sm={4}>{ACCESS_FORMS_CONSTANTS.FORM_NAME}</Col>
                <Col sm={6}>
                    {selectedTab === "msr-forms" && (
                       <div id="formsTable_filter" className="dataTables_filter">
                            <label className='internalsearchlabel'> Search Internal Forms:
                            <input
                              type="search"
                              id="search"
                              className=""
                              onChange={(e) => setinternalSearchVal(e.target.value)}
                              placeholder="Search by Form name or ID"
                              aria-controls="formsTable"
                            />
                            </label>
                        </div>
                    )}
                </Col>
            </Styles.FormHeader>
        </>
    );
};

FormsHeader.propTypes = {
    pdfUrlData: PropTypes.arrayOf(PropTypes.object),
};

FormsHeader.defaultProps = {
    pdfUrlData: [],
};
export default FormsHeader;
