/**
 * Email Address Details Card
 ****************************************
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Form } from 'react-bootstrap';
import checkValidity from '../../../utils/checkValidity';
import Rules from '../PersonalInfo/Rules';
import styles from '../PersonalInfo/styles';
import AccGlobalConstants from '../AccountManagementConstants';

class EmailDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errEmail: '',
            isKeyDown: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { savedEmailAddress } = props;
        const { email, isKeyDown, errEmail } = state;
        if (email === '' && !isKeyDown && errEmail === '') {
            return {
                ...state,
                email: savedEmailAddress,
            };
        }
        return state;
    }

    handleEmailAddressBlur = () => e => {
        const { required } = this.props;
        let errEmail = Rules[e.target.name.toString()]
            ? checkValidity({
                  rules: Rules[e.target.name.toString()],
                  value: e.target.value,
              })
            : '';
        if (required) {
            const { value } = e.target;
            if (value.length === 0) {
                errEmail = 'Please enter email address';
            }
        }
        this.setState({
            errEmail,
            isKeyDown: false,
            email: e.target.value,
        });
    };

    handleEmailAddressChange = () => e => {
        this.setState({ [e.target.name]: e.target.value, isKeyDown: true });
    };

    getEmailDetails = () => {
        return { ...this.state };
    };

    render() {
        const { emailType, required, index, isJoint, type, isReadOnly } = this.props;
        const { email, errEmail } = this.state;
        const { assignObj } = AccGlobalConstants;
        const jointText = isJoint ? 'jointOwner' : '';
        const EMAIL_INFORMATION_LABEL = ' Email Information';
        const EMAIL_LABEL = ' Email';
        return (
            <div className="phoneWrapper">
                <h3 style={styles.mobileHeaderStyle}>
                    {emailType}
                    {EMAIL_INFORMATION_LABEL}
                </h3>

                {/* -------------------------start-------------------------------- */}
                <div className="fieldWrapperDiv">
                    <Form.Group as={Row} className="no-gutters phoneNumberGroup" style={styles.labelStyle}>
                        <span className="label" style={styles.labelTextStyle}>
                            {emailType}
                            {EMAIL_LABEL}
                        </span>
                        <div className="PhNumberWrapper col-sm-6">
                            <div className="inputDiv">
                                <Form.Control
                                    type="text"
                                    onChange={this.handleEmailAddressChange()}
                                    onBlur={this.handleEmailAddressBlur()}
                                    required={required}
                                    id={`${jointText}${type}email${index}`}
                                    name="email"
                                    value={email || ''}
                                    isInvalid={!!errEmail}
                                    maxLength={30}
                                    // onKeyDown={this.numberTypeInput}
                                    aria-required={required}
                                    aria-label={assignObj(`email for ${emailType}`)}
                                    readOnly={isReadOnly}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="errorMsgInputStyle"
                                    id={assignObj(`${emailType}emailAddress_error`)}
                                    aria-atomic="true"
                                    aria-live="polite"
                                >
                                    {errEmail}
                                </Form.Control.Feedback>
                            </div>
                        </div>
                    </Form.Group>
                </div>
                {/* -------------------------end---------------------------------- */}
            </div>
        );
    }
}

EmailDetails.propTypes = {
    required: PropTypes.bool,
    emailType: PropTypes.string,
    savedEmailAddress: PropTypes.string,
    isJoint: PropTypes.bool,
    index: PropTypes.number,
    type: PropTypes.string,
    isReadOnly: PropTypes.bool,
};

EmailDetails.defaultProps = {
    required: false,
    emailType: '',
    savedEmailAddress: '',
    isJoint: 'false',
    index: 100,
    type: '',
    isReadOnly: false,
};

export default EmailDetails;
