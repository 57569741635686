import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { analyzeClickEvent } from '../../../shared/Actions/AnalyticsActions';
import classes from './Input.module.css';


export const OnlineId = (props) => {
    const { changed, blurred, clicked, onlineIdState, preferred, showForgot, inputStyle, readonly } = props;

    const errorText = 'Error:';

    const data = {
        config: {
            id: "onlineId",
            type: 'text',
            label: 'User ID',
            labelPreferred: 'Preferred User Id'
        },
        change: (event) => changed(event, 'onlineId'),
        blur: () => blurred('onlineId'),
        click: (event) => clicked(event),
        tooltipInfo: 'Should contain only Alphabets and Numerics',
        onlineIdText: 'Forgot User ID?',
    }
    const dispatch = useDispatch();
    function analyzeClick(e){
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
            }),
        );
    }
    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (onlineIdState.errMsg) {
        inputClasses.push(classes.Invalid);
    }

    const errorId = `${data.config.id}_error`;

    inputElement = <input
        maxLength={onlineIdState.maxLength}
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={onlineIdState.value}
        onChange={data.change}
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        aria-required="true"
        aria-describedby={errorId}
        aria-invalid={!!onlineIdState.errMsg}
        readOnly={readonly} />;

    return (
        <div className={classes.Input} style={inputStyle}>
            <label className={classes.Label} htmlFor={data.config.id}>
                {preferred ? data.config.labelPreferred : data.config.label}
            </label>
            {inputElement}
            {onlineIdState.errMsg ? <p className={classes.errMsg} role="alert" id={errorId}><span className="sr-only">{errorText}</span>{onlineIdState.errMsg}</p> : null}
            {showForgot ? <Link role="link" to='/recover-online-id' className={classes.forgot} data-analytic-id="UMS_SignIn_ForgotUserId" onClick={analyzeClick} id="forgotOnlineId">{data.onlineIdText}</Link> : null}
        </div>
    );
}

OnlineId.propTypes = {
    onlineIdState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        rules: PropTypes.shape({
            minLength: PropTypes.number,
            maxLength: PropTypes.number,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            required: PropTypes.bool
        })
    }),
    changed: PropTypes.func,
    blurred: PropTypes.func,
    preferred: PropTypes.bool,
    showForgot: PropTypes.bool,
    inputStyle: PropTypes.instanceOf(Object),
    readonly: PropTypes.bool,
}

OnlineId.defaultProps = {
    onlineIdState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        maxLength:'',
        rules: {
            minLength: 5,
            maxLength: 20,
            pattern: '^[a-zA-Z0-9]*$',
            patternMessage: 'Should contain only Alphabets and Numerics',
            required: true
        }
    },
    changed: () => { },
    blurred: () => { },
    preferred: false,
    showForgot: false,
    inputStyle: {},
    readonly: false,
}

export default OnlineId;