import React from 'react';
import { Col } from 'react-bootstrap';
import RelatedArticlesComponent from './RelatedArticlesComponent';
import Facebook from '../../assets/facebook-icon.svg';
import LinkedIn from '../../assets/linkedInlogo-blue.png';
import Twitter from '../../assets/twitter-logo-blue.png';
import Mail from '../../assets/mail-icon.png';
import { SHARE_LABEL } from './Constants';
import { ShareLabelRow, IconsRow } from './Styles/CalculatorStyles';

function SocialMediaComponent() {
    return (
        <Col sm={4}>
            <ShareLabelRow>{SHARE_LABEL}</ShareLabelRow>
            <IconsRow>
                <Col>
                    <img src={Facebook} alt="Facebook" />
                </Col>
                <Col>
                    <img src={LinkedIn} alt="LinkedIn" />
                </Col>
                <Col>
                    <img src={Twitter} alt="Twitter" />
                </Col>
                <Col>
                    <img src={Mail} alt="Mail" width="43" />
                </Col>
            </IconsRow>
            <RelatedArticlesComponent />
        </Col>
    );
}

export default SocialMediaComponent;
