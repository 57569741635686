/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { WButton } from '../../../../../../common';

import './PopoverGuide.css';

const assignObj = obj => {
	return obj;
};
const cancelButtonStyles = {
	color: 'rgb(0, 74, 152)',
};


const useEventListener = (eventName, handler, element = window) => {
	// Create a ref that stores handler
	const savedHandler = useRef();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			// Make sure element supports addEventListener
			// On 
			const isSupported = element && element.addEventListener;
			if (!isSupported) return;

			// Create event listener that calls handler function stored in ref
			const eventListener = event => savedHandler.current(event);

			// Add event listener
			element.addEventListener(eventName, eventListener);

			// Remove event listener on cleanup
			// eslint-disable-next-line consistent-return
			return () => element.removeEventListener(eventName, eventListener);
		},
		[eventName, element] // Re-run if eventName or element changes
	);
};


const PopoverGuide = (props) => {
	const { children, showPopoverId, onClick, data } = props;


	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);

	const handler = event => {
		if (show && event.target.id !== ref.current.id) {
			setShow(!show);
		}
	};

	useEventListener('click', handler);

	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	return (
		<div ref={ref} id={showPopoverId}>
			<div role='button' tabIndex={0} onClick={handleClick}>
				{children}
			</div>

			<Overlay
				show={show}
				target={target}
				placement='bottom'
				container={ref.current}
				containerPadding={30}
			>
				<Popover id={showPopoverId} role="alert">
					<Popover.Content>
						<WButton
							variant=""
							buttontext="Delete this RMD"
							id="deleteButton"
							buttonstyle={assignObj(cancelButtonStyles)}
							onClick={(e) => {
								onClick(data)
							}}
						/>
					</Popover.Content>
				</Popover>
			</Overlay>
		</div >
	);
};


PopoverGuide.propTypes = {
	children: PropTypes.element.isRequired,
	showPopoverId: PropTypes.string
}

PopoverGuide.defaultProps = {
	showPopoverId: ''
}


export default PopoverGuide;
