import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../Box';

const StyledParagraph = styled(Box)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #49494a;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0.9375em 0;
`;

const PrefilledInput = React.forwardRef((props, ref) => {
    const { value } = props;
    return (
        <StyledParagraph ref={ref} as="p" {...props}>
            {value}
        </StyledParagraph>
    );
});
PrefilledInput.propTypes = {
    value: PropTypes.string,
};

PrefilledInput.defaultProps = {
    value: '',
};

export default PrefilledInput;
