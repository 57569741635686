import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, clone } from 'lodash';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import routeConstants from 'routeConstants';
import ContactCenterWidget from 'modules/AccountDashboardModule/Common/ContactCenter/ContactCenterWidgetContainer';
import accountHolderCardData from 'modules/AccountDashboardModule/AccountHolderView/AccountHolderConstants';
import { dateInputIds } from 'common/DateInput';
import { BottomNavWrapper, WRadio, DateInput } from '../../../../../../common';
import { rmdAnswerQuestions, questionType, endAnswerRedirect} from './RMDQuestionsConstants';
import YouSelect from '../YouSelect';
import { SectionWrapper, Section } from '../commonCSS';
import { isValidDate } from 'utils';

const { footerData } = accountHolderCardData;

const QuestionLink = styled.div`
    margin: 25px 0 0 30px;
`;

const RadioGrup = styled.div`
    display: flex;
    flex-direction: column;
`;

const radiostyles = {
    minHeight: 0,
    margin: 0,
};

const QuestionText = styled.div`
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-49494a);
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;
const contactWidgetStyle= {
    maxHeight: "100%"
};

class RMDQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
                pageSubIndex: 0,
                answersDetails: [],
                youSelectDetails:[],
                selectedAnswerBirthDate: {
                    [dateInputIds.day]:'x',
                    [dateInputIds.month]:'x',
                    [dateInputIds.year]:'x',
                    label:''
                },
                selectedAnswer: null,
                currentQuestion: rmdAnswerQuestions,
                disabledNextBtn: true,
                invalidDate: false,
                doRedirect:false,
            }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;
        const { resetDataForNextPage } = this.state;

        saveChildrenState(this.state, pageIndex, resetDataForNextPage);
    }
    
    onChangeAnswer = (event) => {
        const { currentQuestion } = this.state;
        const selectedAnswerId = event.currentTarget.id;
        const selectedAnswer = currentQuestion.answers.find(answer => answer.id === selectedAnswerId);

        this.setState({
            selectedAnswer,
            disabledNextBtn: false 
        });
    }

    getSelectedAnswerValue = () => {
        const {currentQuestion, selectedAnswerBirthDate, selectedAnswer} = this.state;
        return (currentQuestion.type === questionType.birthDate) 
            ? selectedAnswerBirthDate.label
            : selectedAnswer.label
    }
    
    getSelectedAnswer = () => {
        const { currentQuestion, selectedAnswer } = this.state;
        return (currentQuestion.type === questionType.birthDate) 
            ? currentQuestion.answers[0] 
            : selectedAnswer
    }

    getYouSelectDetails = () => {
        const selectedAnswer = this.getSelectedAnswer();
        const selectedAnswerLiteral = get(selectedAnswer, 'selectedAnswerLiteral', ''); 
        const  { currentQuestion } = this.state;
        const isBirthDateQuestion = currentQuestion.type === questionType.birthDate;
        const birthDate = this.getSelectedAnswerValue(); 
        
        return (isBirthDateQuestion)
            ? `${selectedAnswerLiteral} ${birthDate}`
            : selectedAnswerLiteral
    }

    searchTree = (element, matchingTitle) => {
        if(element.question == matchingTitle){
             return element;
        }
        if (element.answers != null){
             const itemAnswers = element.answers;
             let i;
             let result = null;
             for(i=0; result == null && i < itemAnswers.length; i++){
                  result = this.searchTree(itemAnswers[i], matchingTitle);
             }
             return result;
        }
        return null;
   }

    onBackClick = () => {
        const { navigationObj } = this.props;

        const { answersDetails, youSelectDetails } = this.state;
        const newYouSelectDetailss = clone(youSelectDetails);
        newYouSelectDetailss.pop();
        const newAnswersDetails = clone(answersDetails);
        const prevQuestionData = newAnswersDetails.pop(); 
        const prevQTitle = prevQuestionData?.field;
        const prevQuestionItem = prevQTitle ? this.searchTree(rmdAnswerQuestions, prevQTitle) : null;

        if ( prevQuestionItem) {
            this.setState({
                pageSubIndex: 0,
                currentQuestion:  prevQuestionItem,
                answersDetails: [...newAnswersDetails],
                selectedAnswer: null,
                disabledNextBtn: true,
                youSelectDetails: newYouSelectDetailss
            })
        } else {
            navigationObj.onSecondaryClick();
        }
    }

    onNextRedirectTo = () => {
        const { currentQuestion } = this.state;
        const { navigationObj } = this.props;

        if (currentQuestion.answers.length === 0) {
            if (currentQuestion.redirectTo.route === endAnswerRedirect.selectAFund.route 
            && currentQuestion.redirectTo.route === endAnswerRedirect.selectAllFounds.route) {
                navigationObj.onPrimaryClick();
            } else if (currentQuestion.redirectTo.route === endAnswerRedirect.downloadTheForm.route ) {
                navigationObj.onShowDownloadForm();
            } else if (currentQuestion.redirectTo.route === endAnswerRedirect.rmdDIY.route ){
                const { history, accountSelected } = this.props;
                history.push({
                    pathname: currentQuestion.redirectTo.route,
                    state: {
                        accountSelected: accountSelected
                    }
                });
            } 
        } 
    }

    redirectToBeneficiaries = () => {
        const { history, accountSelected } = this.props;
        history.push({
            pathname: routeConstants.manageBeneficiaries,
            state: {
                masterRegId: accountSelected.masterRegId
            }
        });
    }

    onNextClick = () => {
        const  { currentQuestion } = this.state;
        const selectedAnswer = this.getSelectedAnswer();
        const youSelectDetailsItem = this.getYouSelectDetails();
        const youSelectEmpty = youSelectDetailsItem === '';
        const linkLabel = get(selectedAnswer,'linkInfo.linkLabel', null);
        const doRedirect = selectedAnswer.answers.length === 0;
        this.setState(prevState => ({
                pageSubIndex: linkLabel === null ? -2 : 1, // -2 do not show Guidance| 1 - show Guidance from sublevel 1
                currentQuestion: selectedAnswer,
                answersDetails: [
                    ...prevState.answersDetails,
                    {
                        field: currentQuestion.question,
                        value: this.getSelectedAnswerValue(),
                    }
                ],
                selectedAnswer: null,
                disabledNextBtn: true,
                doRedirect,
                resetDataForNextPage: prevState.isMasterRegLevel !== selectedAnswer.isMasterRegLevel,
                youSelectDetails: youSelectEmpty 
                    ? [...prevState.youSelectDetails]
                    : [
                        ...prevState.youSelectDetails,
                        youSelectDetailsItem
                    ],
                isMasterRegLevel:selectedAnswer.isMasterRegLevel
        }), ()=>{
            if (doRedirect) this.onNextRedirectTo();
        })
    }

    onChangeBirthDate = (e) => {
        const inputId = e.target.id; 
        const inputValue = e.target.value; 
        const {selectedAnswerBirthDate} = this.state;
        const birthDate = {
            ...selectedAnswerBirthDate,
            [inputId]: inputValue
        }

        const birthDateLabel = `${birthDate[dateInputIds.month]}/${birthDate[dateInputIds.day]}/${birthDate[dateInputIds.year]}`;
        const isInvalidDate = !isValidDate(birthDateLabel) || birthDate.inputYear.length!==4;
        let showErrorForBirthDate = false; 
        if ( isInvalidDate){
            for (const [key, value] of Object.entries(birthDate)) {
                showErrorForBirthDate = !(key !== 'label' && value === 'x') || (key === "inputYear" && value!==4)
            }
        }

        this.setState({
            disabledNextBtn:isInvalidDate,
            showErrorForBirthDate,
            selectedAnswerBirthDate: {
                ...birthDate,
                label: birthDateLabel
            }
        })

    }

    render() {
        const { accountSelected = {}, navigationObj, RMDCalc, pageIndex } = this.props;
        const { disabledNextBtn, currentQuestion, selectedAnswer, youSelectDetails, pageSubIndex, doRedirect, showErrorForBirthDate } = this.state;
        const showChat = get(selectedAnswer, 'onSelect.showChat', false);
        const youSelectAccountDetails = accountSelected?[accountSelected]:[];

        return (
            <div>
                <SectionWrapper>
                    <YouSelect pageIndex={pageIndex}
                        pageSubIndex={pageSubIndex}
                        accountDetails={youSelectAccountDetails}
                        answerDetails={youSelectDetails} />
                    <Section>
                        <QuestionText>{currentQuestion.question}</QuestionText>
                        { (currentQuestion.type === questionType.radio) &&
                            <RadioGrup role='radiogroup'>
                                {currentQuestion.answers.map(answerItem => {
                                    return (
                                        <WRadio
                                            id={answerItem.id}
                                            key={answerItem.id}
                                            name={answerItem.name}
                                            value={answerItem.label}
                                            radiostyles={radiostyles}
                                            selected={answerItem.id === selectedAnswer?.id}
                                            onClick={this.onChangeAnswer}
                                        />
                                    );
                                })}
                            </RadioGrup>}
                            { (currentQuestion.type === questionType.birthDate) 
                                && <DateInput onChange={this.onChangeBirthDate} showErrorForBirthDate={showErrorForBirthDate}/>}
                            { currentQuestion.linkInfo 
                                && <QuestionLink>
                                   <a onClick={this.redirectToBeneficiaries} href="#">
                                            {currentQuestion.linkInfo.linkLabel}
                                   </a>
                                </QuestionLink>}
                            { showChat  &&  <>
                                        <ContactCenterWidget footerData={footerData} showCorner={true} cardOverlayStyles={contactWidgetStyle} /> 
                                </>}
                    </Section>
                </SectionWrapper>

                <BottomNavWrapper
                    {...navigationObj}
                    onPrimaryClick = {this.onNextClick}
                    onSecondaryClick = {this.onBackClick}
                    showBack={true}
                    disabled={disabledNextBtn}
                    // dataAnalyticId={RMD_ADD_PLAN_DETAILS_NAV_BUTTON_ID}
                />
            </div>
        );
    }
}

RMDQuestions.propTypes = {
    navigationObj:PropTypes.shape({
        onCancel: PropTypes.func,
        onPrimaryClick: PropTypes.func,
        onSecondaryClick: PropTypes.func,
        onShowDownloadForm: PropTypes.func,
        portalRoot: PropTypes.instanceOf(Object),
    }),
    getEligibleAccounts: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }), 
    pageIndex: PropTypes.number,
};

RMDQuestions.defaultProps = {
    navigationObj: {
        onCancel: () => {},
        onPrimaryClick: () => {},
        onSecondaryClick: () => {},
        onShowDownloadForm: () => {},
        portalRoot: null,
    },
    getEligibleAccounts: () => { },
    history: {},
    pageIndex: 2,
};

export default withRouter(RMDQuestions) ;