import Button from '../Button';
import KebabMenuHoverIcon from 'assets/icon-kebab-menu-hover.svg';
import KebabMenuIcon from 'assets/icon-kebab-menu.svg';
import PropTypes from 'prop-types';
import React, { useState, useRef, Fragment, useEffect} from 'react';
import Separator from '../Separator';
import styled from 'styled-components';
import { useClickAway } from 'hooks';
import '../KebabMenu/kebab.css';

const MenuContainer = styled.div`
    position: relative;
    z-index: 2;
`;
export const Menu = styled(Button)`
    position: relative;
    height: 31px;
    width: 31px;
    background: url(${props => props.kebabImg}) no-repeat;
    &:hover {
        background: url(${props => props.kebabHoverImg}) no-repeat;
    }
    background-position: center;
    @media (max-width: 767px) {
        left: 10px;
    }
`;
const MenuOptionsPopup = styled.ul`
width : 8rem;
    margin: 0;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.625rem;
    top: 100%;
    right: calc(-1.5rem + 7.5px);
    @media (max-width: 767px) {
        right: calc(-1.5rem + 0.5px);
    }
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 10px solid #ffffff;
        top: -0.625rem;
        right: 1.5rem;
    }
`;
const MenuItem = styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    &&&:focus {
        background: #e6e6e6 0% 0% no-repeat padding-box;
        outline: 1px solid #75757a !important;
        z-index: 1;
        outline-offset: 0;
    }
    @media (max-width: 767px) {
        padding: 10px 1.625rem;
    }
`;

const MenuOptions = styled.span`
    margin-left: 0.3125rem;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    white-space: nowrap;
    text-transform: capitalize;
    opacity: 1 !important;
    ${props => props.iconStyle}
`;

export const MenuIcon = styled.span`
    min-width: 1rem;
    min-height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
    background-size: contain;
    ${props => props.iconStyle}
`;

const SPACEBAR_KEY_CODE = ' ';
const ENTER_KEY_CODE = 'Enter';
const LEFT_ARROW_KEY_CODE = 'ArrowLeft';
const UP_ARROW_KEY_CODE = 'ArrowUp';
const RIGHT_ARROW_KEY_CODE = 'ArrowRight';
const DOWN_ARROW_KEY_CODE = 'ArrowDown';
const ESCAPE_KEY_CODE = 'Escape';
const TAB_KEY_CODE = 'Tab';
const AX_OPTION_LABEL = 'More Options on';

export const focusListItem = (code, index, menuOptions, menuItemRefs) => {
    if (code === DOWN_ARROW_KEY_CODE) {
        if (index < menuOptions.length - 1) {
            menuItemRefs.current[+index + 1].focus();
        } else {
            menuItemRefs.current[0].focus();
        }
    } else if (code === UP_ARROW_KEY_CODE) {
        if (index > 0) {
            menuItemRefs.current[+index - 1].focus();
        } else {
            menuItemRefs.current[menuOptions.length - 1].focus();
        }
    }
};

export const setRef = (menuItemRefs, index) => el => {
    const refs = menuItemRefs;
    refs.current[+index] = el;
};

export const toggleHover = (menuItemRefs, index, mouseState) => () => {
    if (mouseState === 'enter') {
        menuItemRefs.current[+index].focus();
    }
};

export const onMenuClick = setShowMenu => () => {
    setShowMenu(prevState => !prevState);
};

export const onKeyEvent = ({
    name,
    index,
    menuOptions,
    menuItemRefs,
    setShowMenu,
    menuRef,
    onMenuOptionClick,
    isDisabled,
}) => e => {
    switch (e.key) {
        case ENTER_KEY_CODE:
        case SPACEBAR_KEY_CODE: {
            e.preventDefault();
            if(!isDisabled) {
                setShowMenu(false);
                menuRef.current.focus();
                onMenuOptionClick(name)(e);
            }
            break;
        }

        case DOWN_ARROW_KEY_CODE:
        case LEFT_ARROW_KEY_CODE: {
            e.preventDefault();
            focusListItem(DOWN_ARROW_KEY_CODE, index, menuOptions, menuItemRefs);
            break;
        }

        case UP_ARROW_KEY_CODE:
        case RIGHT_ARROW_KEY_CODE: {
            e.preventDefault();
            focusListItem(UP_ARROW_KEY_CODE, index, menuOptions, menuItemRefs);
            break;
        }

        case ESCAPE_KEY_CODE:
        case TAB_KEY_CODE: {
            e.preventDefault();
            setShowMenu(false);
            menuRef.current.focus();
            break;
        }

        default:
            break;
    }
};

const onOptionClick = ({ onMenuOptionClick, name, menuRef, isDisabled }) => e => {
    if(!isDisabled){
        menuRef.current.focus();
        onMenuOptionClick(name)(e);
    }
};

const KebabMenu = props => {
    const { menuOptions, onMenuOptionClick, menuBtnLabel, isNotKebab, CustomBtn } = props;

    const menuRef = useRef(null);
    const menuItemRefs = useRef([]);

    const [showMenu, setShowMenu] = useState(false);

    useClickAway(menuRef, () => {
        if (showMenu) {
            setShowMenu(false);
        }
    });

    useEffect(() => {
        if (showMenu && menuOptions.length) {
            menuItemRefs.current[0].focus();
        }
    }, [showMenu, menuOptions]);

    const menuLabel = `${AX_OPTION_LABEL} ${menuBtnLabel}`;

    return (
        <MenuContainer className="mobileMenuContainer">
            {isNotKebab ? (
                <CustomBtn
                    onClick={onMenuClick(setShowMenu)}
                    aria-expanded={showMenu.toString()}
                    aria-haspopup="true"
                    ref={menuRef}
                    aria-label={menuLabel}
                />
            ) : (
                <Menu
                    className="mobileKebabMenu"
                    kebabImg={KebabMenuIcon}
                    kebabHoverImg={KebabMenuHoverIcon}
                    onClick={onMenuClick(setShowMenu)}
                    aria-expanded={showMenu.toString()}
                    aria-haspopup="true"
                    ref={menuRef}
                    aria-label={menuLabel}
                />
            )}
            {showMenu && (
                <MenuOptionsPopup role="menu" className="menuOptionMobile">
                    {menuOptions.map((item, index) => {
                        const { name = '', isDisabled = false, icon = null, menuOptName = '', iconStyle = null } = item;
                        const menuItemId = `vcm-menu-item-${name}`;
                        return (
                            <Fragment key={index.toString()}>
                                {!!index && (
                                    <Separator mL={0.625} mR={0.625} role="separator" aria-hidden="true" as="li" />
                                )}
                                <MenuItem
                                    ref={setRef(menuItemRefs, index)}
                                    role="menuitem"
                                    onClick={onOptionClick({ onMenuOptionClick, name, menuRef, isDisabled })}
                                    disabled={isDisabled}
                                    aria-labelledby={menuItemId}
                                    aria-disabled={isDisabled}
                                    tabIndex="-1"
                                    className={isDisabled ? 'textDisabled':''}
                                    onKeyDown={onKeyEvent({
                                        name,
                                        index,
                                        menuOptions,
                                        menuItemRefs,
                                        setShowMenu,
                                        menuRef,
                                        onMenuOptionClick,
                                        isDisabled,
                                    })}
                                    onMouseEnter={toggleHover(menuItemRefs, index, 'enter')}
                                    onMouseLeave={toggleHover(menuItemRefs, index, 'leave')}
                                >
                                    {icon && <MenuIcon url={icon} iconStyle={iconStyle} />}
                                    <MenuOptions id={menuItemId} iconStyle={iconStyle} {...(!!menuOptName && { 'aria-label': menuOptName })}>
                                        {name}
                                    </MenuOptions>
                                </MenuItem>
                            </Fragment>
                        );
                    })}
                </MenuOptionsPopup>
            )}
        </MenuContainer>
    );
};

KebabMenu.propTypes = {
    menuOptions: PropTypes.instanceOf(Object),
    onMenuOptionClick: PropTypes.func,
    menuBtnLabel: PropTypes.string,
    isNotKebab: PropTypes.bool,
    CustomBtn: PropTypes.instanceOf(Object),
};

KebabMenu.defaultProps = {
    menuOptions: [],
    onMenuOptionClick: () => {},
    menuBtnLabel: '',
    isNotKebab: false,
    CustomBtn: null
};

export default KebabMenu;
