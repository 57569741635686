import React, { Component } from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';


const RenderRow = (props) =>{
    return props.keys.map((key)=>{
        return <td key={props.data[(key).toString()]}>{props.data[(key).toString()]}</td>
    })
}

const assignObj = (obj) => { return obj; };

export default class HOCTable extends Component {
 

  getKeys= (data) =>{
      return Object.keys(data[0]);
  };
  

  getRowsData = () =>{
    const {data} = this.props;
    const items = data;
    const keys = this.getKeys(data);
    return items.map((row, index)=>{
    return <tr key={parseInt(index, 10)}><RenderRow key={parseInt(index, 10)} data={row} keys={keys}/></tr>
    })
  };

  render(   ) {
    const {borderless, responsive, size, theadstyle, thstyle, data} = this.props;
    const keys=this.getKeys(data);
    return (
      <div>
        <Table {...this.props} borderless={borderless} responsive={responsive} size={size}> 
            <thead style={assignObj({borderBottomWidth:'thin',borderBottomStyle:'ridge',borderBottomColor:'#707070',...theadstyle})}>
              <tr>
               { keys.map((key,index)=>{
                    return  <th style={thstyle} key={parseInt(index, 10)}>{key.toUpperCase()}</th>
                })}
              </tr>
            </thead>
            <tbody>{this.getRowsData()}</tbody>              
        </Table>       
      </div>
    );
  }
}

HOCTable.propTypes={
  data:PropTypes.instanceOf(Array),
  borderless:PropTypes.bool,
  responsive:PropTypes.bool,
  size:PropTypes.string,
  theadstyle:PropTypes.instanceOf(Object),
  thstyle:PropTypes.instanceOf(Object)
}

HOCTable.defaultProps={     
  borderless:false,
  responsive:true,
  size:'',
  data:[],
  theadstyle:{},
  thstyle:{}
}

/**
 *  Usage
 *  dataList1:[
                {'Years': 'YTD', 'Percentage': '15.39%'},
                {'Years': '1 Year', 'Percentage': '1.95%'},
                {'Years': '5 Year', 'Percentage': '10.55%'},
                {'Years': '10 Year', 'Percentage': '12.92%'},
                {'Years': 'Since Inception(10/15/1981)', 'Percentage': '8.8.%'},
            ],
 *  <HOCTable data={this.state.compareData} borderless={true} theadstyle={{borderBottomColor:'red'}} thstyle={{color:'#333333DE',fontSize:16,fontWeight:'bold'}}/> 
 */