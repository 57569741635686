import React from 'react';
import PropTypes from 'prop-types';
import styles from '../CommonStyles';
import WModal from  '../WModal/WModal';
import WButton from  '../WButton/WButton';


const assignObj = (obj) => { return obj; };

const WErrorModal= (props) => {
    const { show, message, dataAnalyticId } = props;
    const handleHide = () =>props.modalClick('hide');
    const handleErrorModalClose = () => props.modalClick('close');
    if(show) {
        return (
            <WModal 
            style={styles.popupModalStyle}
            closebutton=""
            header="Please follow the instructions below"  
            modalheaderstyle={styles.popupModalHeaderStyle}     
            modalfooterstyle={styles.popupModalFooterStyle}                     
            headertextstyle={styles.popupModalHeaderTextStyle}
            modalbodystyle={styles.popupModalBodyStyle}                            
            size="lg" 
            dialogClassName="myModal" 
            show={show} 
            onHide={handleHide} 
            body=
            {
               <div style={styles.popupModalDivStyle}>
               <div style={styles.popupModalBodyTextStyle}>{message}</div>
               </div>
            } 
            footer={
                <div style={assignObj({marginTop:10})}>
                    <WButton buttontext="CLOSE" 
                        buttonstyle={styles.popupModalButtonStyle} 
                        buttontextstyle={styles.popupModalButtonTextStyle} 
                        onClick={handleErrorModalClose}
                        dataAnalyticId={dataAnalyticId}
                    />
                </div>
            }                           
        />
        );
    } 
        return null;
    
} 

WErrorModal.propTypes={
    modalClick: PropTypes.func,
    show: PropTypes.bool,
    message: PropTypes.string,
    dataAnalyticId: PropTypes.string,
}

WErrorModal.defaultProps={     
    modalClick: () => {},
    show: false,
    message: "Error Message",
    dataAnalyticId: ''
}

export default WErrorModal;