import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RMD_FREQUENCY } from 'modules/AccountOptions/constant';
import { get } from 'lodash';
import { MaskText } from '../../../../../../common';
import { formatCurrency } from '../../../../../../utils';
import PopoverGuide from './Popover'

/*
 * 
 * STYLED COMPONENTS START
 */

const RMDCardContainer = styled.div`
  min-width: 353px;
  border: 1px solid black;  
  background-color: #fff;
  margin: 10px 10px 20px 0px;
`;

const CardInfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 12px;
`;

const CardInfoData = styled.div`
  font-family: 'Benton Sans';
  font-size: 14px;
  text-align: right;
`;

const CardInfoTitle = styled.div`
  font-weight: bold;
  font-family: 'Roboto Slab';
  font-size: 14px;
`;



const Section = styled(CardInfoRow)`
 padding: 10px 0px;
 flex-direction: column;
`;

const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    /* cursor: pointer; */
`

const ShareTitleSection = styled(FlexRowDiv)`
    align-items: center;
    padding: 1.25rem 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    opacity: 1;
`;

const ShareBody = styled.div``;

const ShareTitle = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;

const IndividualShare = styled.div`
    opacity: 1;
    position: relative;
    outline: ${props => (props.selected ? '2px solid #004A98' : '1px solid #D2D2D2')};
    background: ${props => (props.selected ? '#FAFAFA' : '#ffffff')} 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    border-radius: 0;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8CBF26' : '35px solid #D2D2D2')};
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const ShareNameDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
`;


/**
 * 
 * STYLED COMPONENTS END
 */


const maskLength = string => string.length - 4;

const getCurrentYear = () => new Date().getFullYear();

const getPriorYear = () => getCurrentYear() - 1;

const frequencyConvert = (frequency, withdrawalDate = '') => {
  let frequencyTxt = '';

  if (frequency) {
    const freqSearch = frequency.toUpperCase();
    const obj = RMD_FREQUENCY.find(item => item.key === freqSearch)

    if (Object.keys(obj).length) {
      frequencyTxt = obj.value;

      if (freqSearch === 'M') {

        if (withdrawalDate.endsWith('1')) {
          frequencyTxt += ` on the ${withdrawalDate}st`;
        }

        else if (withdrawalDate.endsWith('2')) {
          frequencyTxt += ` on the ${withdrawalDate}nd`;
        }

        else if (withdrawalDate.endsWith('3')) {
          frequencyTxt += ` on the ${withdrawalDate}rd`;
        }

        else {
          frequencyTxt += ` on the ${withdrawalDate}th`;

        }

      }
    }
  }

  return frequencyTxt;
}

const generateId = () => `__${Math.random().toString(36).substr(2, 9)}`;

const RMD_LISTING_CONSTANTS = {
  accountNumber: 'Account Number',
  currentYearDistribution: `${getCurrentYear()} RMD`,
  priorYear: `${getPriorYear()} Year-end Balance`,
  currentBalance: 'Current Balance',
  scheduled: 'Scheduled',
  depositTo: 'Fund To',
  taxWithholding: 'Tax Withholding',
}

const RmdCard = (props) => {
  const {
    rmdItem: {
      accountName,
      accountNumber,
      priorYearEndBalance,
      calculatedMasterRegBalance,
      scheduledVal,
      scheduled,
      withdrawalDate,
      depositTo,
      bankDetails,
      fedTax,
      stateTax,
      isOfflineAccount
    },
    onClick,
    showPopover,
  } = props;

  const id = generateId()
  const bankAccountNumber = get(bankDetails, 'bankAccountNumber', '');
  return (
    <RMDCardContainer>
      <IndividualShare >
        <ShareTitleSection>
          {
            (showPopover && !isOfflineAccount) ? (
              <PopoverGuide onClick={onClick} data={accountNumber} showPopoverId={id}>
                <ShareNameDiv>
                  <ShareTitle id={id}>{accountName}</ShareTitle>
                </ShareNameDiv>
              </PopoverGuide>
            ) : (
                <ShareNameDiv>
                  <ShareTitle id={id}>{accountName}</ShareTitle>
                </ShareNameDiv>
              )
          }

        </ShareTitleSection>
        <ShareBody>
          <CardInfoRow>
            <CardInfoTitle>{RMD_LISTING_CONSTANTS.accountNumber}</CardInfoTitle>
            <CardInfoData>
              <MaskText text={`${accountNumber}`} startIndex={0} maskLength={maskLength(`${accountNumber}`)} />
            </CardInfoData>
          </CardInfoRow>
            {/* <CardInfoTitle>{RMD_LISTING_CONSTANTS.currentYearDistribution}</CardInfoTitle>
            <CardInfoData>{formatCurrency(distribution)}</CardInfoData> */}
          <Section>
            <CardInfoRow>
              <CardInfoTitle>{RMD_LISTING_CONSTANTS.priorYear}</CardInfoTitle>
              <CardInfoData>{formatCurrency(priorYearEndBalance)}</CardInfoData>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>{RMD_LISTING_CONSTANTS.currentBalance}</CardInfoTitle>
              <CardInfoData>{formatCurrency(calculatedMasterRegBalance)}</CardInfoData>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>{RMD_LISTING_CONSTANTS.scheduled}</CardInfoTitle>
              <CardInfoData>
                {formatCurrency(`${scheduledVal}`)}<br />
                {frequencyConvert(scheduled, withdrawalDate)}
              </CardInfoData>
            </CardInfoRow>
          </Section>
          <CardInfoRow>
            <CardInfoTitle>{RMD_LISTING_CONSTANTS.depositTo}</CardInfoTitle>
            <CardInfoData>
              {depositTo === '01' ? 'Check' : 'Bank Account '}
              {depositTo === '05' && bankAccountNumber && <MaskText text={bankAccountNumber.toString()} startIndex={0} maskLength={maskLength(bankAccountNumber.toString())} />}
            </CardInfoData>
          </CardInfoRow>
          <CardInfoRow>
            <CardInfoTitle>{RMD_LISTING_CONSTANTS.taxWithholding}</CardInfoTitle>
            <CardInfoData>
              <div>Federal {fedTax}%</div>
              <div>State {stateTax}%</div>
            </CardInfoData>
          </CardInfoRow>
        </ShareBody>
      </IndividualShare>
    </RMDCardContainer>
  )
}

RmdCard.propTypes = {
  rmdItem: PropTypes.shape({
    accountName: PropTypes.string,
    accountType: PropTypes.string,
    accountNumber: PropTypes.number,
    priorYearEndBalance: PropTypes.number,
    currentYearBalance: PropTypes.number,
    depositTo: PropTypes.string,
    withdrawalPreference: PropTypes.string,
    fedTax: PropTypes.string,
    stateTax: PropTypes.string,
    scheduled: PropTypes.string,
    scheduledVal: PropTypes.number,
    withdrawalDate: PropTypes.string,
    isOfflineAccount: PropTypes.bool
  }),
  onClick: PropTypes.func,
  showPopover: PropTypes.bool
}

RmdCard.defaultProps = {
  rmdItem: {
    accountName: '',
    accountType: '',
    accountNumber: 0,
    priorYearEndBalance: 0,
    currentYearBalance: 0,
    depositTo: '',
    withdrawalPreference: '',
    fedTax: '',
    stateTax: '',
    scheduled: '',
    withdrawalDate: '',
    scheduledVal: 0,
    isOfflineAccount: false
  },
  onClick: () => { },
  showPopover: false
}

export default RmdCard;