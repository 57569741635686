/**
 * Security Preferences Component
 ********************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description
 * @createdDate [10/12/2019]
 * ***************************************
 * @createdDate Manjunath V
 * @lastModifiedDate
 * @lastModifiedReason
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
// import LoadingOverlay from 'react-loading-overlay';

import { WBreadCrumb, CommonButtons, WSpinner } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import data from './constants';
import { SecurityQuestion } from '../Input/SecurityQuestion';
import { SecurityAnswer } from '../Input/SecurityAnswer';
import { inputValidityHandler, inputChangedHandler } from '../FormUtils';
import styles from '../UserManagementStyles';
import classes from '../Input/Input.module.css';
import updateForm from './updateForm';
import userConstants from '../userConstants';
import { getSessionValues } from 'shared/Helpers/Utils';

const assignObj = obj => obj;

const breadCrumbValues = [
    { name: 'Home', url: '/' },
    { name: 'Profiles & Preferences', url: '/Profile' },
    { name: 'Security Preferences', url: '/SecurityPreferences' },
];
const pageName = 'Modify Security Questions';
const modifyLabel = 'Select and answer to three security Questions';

export default class ModifySecurityQnsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                answer1: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    touched: false,
                    rules: {
                        maxLength: 30,
                        pattern: userConstants.alphabetRegex,
                        patternMessage: userConstants.alphabetMessage,
                        required: true,
                    },
                },
                answer2: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    touched: false,
                    rules: {
                        maxLength: 30,
                        pattern: userConstants.alphabetRegex,
                        patternMessage: userConstants.alphabetMessage,
                        required: true,
                    },
                },
                answer3: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    touched: false,
                    rules: {
                        maxLength: 30,
                        pattern: userConstants.alphabetRegex,
                        patternMessage: userConstants.alphabetMessage,
                        required: true,
                    },
                },
                question1: { value: '', options: [], valid: true, rules: {} },
                question2: { value: '', options: [], valid: true, rules: {} },
                question3: { value: '', options: [], valid: true, rules: {} },
            },
            updated: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState, history, saveSecurityQuestions } = nextProps;
        let updatedState = { ...state };
        let updatedStateForm = { ...updatedState.form };
        // const fields = Object.keys(updatedStateForm);

        // console.log(updatedStateForm);
        if (loginState && loginState.getSavedSecurityQuestions && !loginState.isLoading) {
            // console.log(loginState);
            if (loginState.getSavedSecurityQuestions.errorCode === 0) {
                if (!updatedState.updated) {
                    const savedData = loginState.getSavedSecurityQuestions.result[0];
                    updatedStateForm = {...updateForm(updatedStateForm, savedData)}
                    // const questions = Object.keys(savedData);
                    // for (let i = 0; i < questions.length; i += 1) {
                    //     updatedStateForm = {
                    //         ...updatedStateForm,
                    //         [fields[Number(i)]]: {
                    //             ...updatedStateForm[fields[Number(i)]],
                    //             value: savedData[questions[Number(i)]],
                    //         },
                    //         [fields[i + 3]]: {
                    //             ...updatedStateForm[fields[i + 3]],
                    //             value: questions[Number(i)],
                    //         },
                    //     };
                    // }
                    // console.log(updatedStateForm)
                    updatedState = { ...updatedState, form: { ...updatedStateForm }, updated: true };
                }
            } else {
                updatedState = { ...state, error: loginState.getSavedSecurityQuestions.result };
            }
        }

        if (loginState && loginState.saveSecurityQuestions && !loginState.isLoading) {
            // console.log(loginState);
            if (loginState.saveSecurityQuestions.errorCode === 0) {
                updatedState = { ...updatedState, updated: false };
                saveSecurityQuestions('');
                history.push({
                    pathname: '/SecurityPreferences',
                    message: { message: 'You have successfully modified your Security Questions' },
                });
            } else {
                updatedState = { ...state, error: loginState.saveSecurityQuestions.result };
            }
        }
        return updatedState;
    }

    componentDidMount() {
        const { getCompositeLookUpData, getSavedSecurityQuestions, setJWTToken } = this.props;
        const token = localStorage.getItem('token');
        if(token){
            setJWTToken(token);
        }
        getCompositeLookUpData(['security_ques']);
        // const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues(); 
          
        const {accesstoken, vcmid } = params;        
        const msrParams = {
            accesstoken,
            vcmid,            
        }
        getSavedSecurityQuestions(msrParams);
    }

    onSubmit() {
        const { saveSecurityQuestions, getSavedSecurityQuestions } = this.props;
        const { form } = this.state;
        const payload = {
            securityQuestions: [
                {
                    [form.question1.value]: form.answer1.value,
                    [form.question2.value]: form.answer2.value,
                    [form.question3.value]: form.answer3.value,
                },
            ]
        };
        getSavedSecurityQuestions('clear');
        saveSecurityQuestions(payload);
    }

    onChange(event, fieldId) {
        inputChangedHandler(event, fieldId, this);
    }

    onBlur(fieldId) {
        inputValidityHandler(fieldId, this);
    }

    addEmailHandler = () => {
        this.setState(state => ({
            ...state,
            addEmail: true,
            form: {
                ...state.form,
                additionalEmail: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    touched: false,
                    rules: { isEmail: true, maxLength: 30, required: true },
                },
            },
        }));
    };

    assignObj = obj => {
        return obj;
    };

    render() {
        const { questionsData, loginState } = this.props;
        const { form, error } = this.state;
        const { isLoading } = loginState;
        // console.log('@debug for questions data ',questionsData);
        return (
            <div>
                <WSpinner loading={isLoading} />
                {/* <LoadingOverlay active={isLoading} spinner styles={assignObj({ wrapper: { overflow: 'hidden' } })} /> */}
                <div className="container">
                    <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb="Modify Security Questions" />
                    <SideHeader title={pageName} />
                    <div style={assignObj({ display:'flex', justifyContent: 'space-between' })}>
                        <span style={styles.subHeading}> {modifyLabel} </span>
                        <span style={assignObj({ ...styles.optionalMessage, marginBottom: 30 })}>
                            {data.optionalMessage}
                        </span>
                    </div>
                    {data.questions.map(question => {
                        return (
                            <div key={question.id}>
                                <Row>
                                    <Col md lg xl>
                                        <SecurityQuestion
                                            securityQuestion={questionsData}
                                            securityQuestionState={form}
                                            changed={this.onChange}
                                            label={question.label}
                                            id={question.id}
                                        />
                                    </Col>
                                    <Col md lg xl>
                                        <SecurityAnswer
                                            securityAnswerState={form[question.answerId]}
                                            changed={this.onChange}
                                            blurred={this.onBlur}
                                            label={question.answerLabel}
                                            title={question.answerLabel}
                                            id={question.answerId}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                    {error ? <p className={classes.errMsg}>{error}</p> : null}
                    {/* <div>
                        <Form.Group role="radiogroup" id="deliveryPreference">
                            <legend style={data.labelStyle} htmlFor="deliveryPreference">{data.label}</legend>
                            <WRadio id='onlineRadio' value={data.onlineText} selected={online} onClick={this.clickOnline} />
                            <WRadio id='postRadio' value={data.postText} selected={post} onClick={this.clickPost} />
                        </Form.Group>
                    </div> */}
                </div>
                <CommonButtons
                    backstyle={styles.hide}
                    submit="true"
                    submitClick={this.onSubmit}
                    blockstyle={styles.CommonButtonsHalf}
                />
            </div>
        );
    }
}

ModifySecurityQnsComponent.propTypes = {
    saveSecurityQuestions: PropTypes.func,
    setJWTToken: PropTypes.func,
    getCompositeLookUpData: PropTypes.func,
    getSavedSecurityQuestions: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    questionsData: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
};

ModifySecurityQnsComponent.defaultProps = {
    getCompositeLookUpData: () => {},
    getSavedSecurityQuestions: () => {},
    loginState: {},
    saveSecurityQuestions: () => {},
    setJWTToken: () => {},
    questionsData: null,
    history: {},
};
