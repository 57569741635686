import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import makeApiRequest from '../Network/ApiInterface';

export const getTransactionCompositeData = payload => {
    return async dispatch => {
        try {
            const env = '';
            for (let i = 0; i < payload.data.datakey.length; i += 1) {
                const resultKey = payload.data.datakey[+i];
                makeApiRequest(
                    ActionTypes.GET_TRANSACTION_COMPOSITE_DATA,
                    { datakey: resultKey,
                      flow : payload?.marketNAOFlow == "marketNaoFlow" ? payload.marketNAOFlow : "" },
                    env,
                    response => {
                        if (response.data) {
                            dispatch({
                                type: ActionTypes.GET_TRANSACTION_COMPOSITE_DATA_SUCCESS,
                                //  payload: { value: response.data[0].datavalue, key: resultKey },
                                //  payload: response.data[0].datavalue.value,
                                [resultKey]: response.data[0].datavalue,
                                key: resultKey,
                            });
                        }
                    },
                    error => {
                        dispatch({
                            type: ActionTypes.GET_TRANSACTION_COMPOSITE_DATA_FAILURE,
                            payload: error,
                        });
                    },
                );
            }
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_TRANSACTION_COMPOSITE_DATA_FAILURE,
                payload: error,
            });
        }
    };
};

export default getTransactionCompositeData;
