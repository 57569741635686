import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
  isLoading: false,
  masterdatalist: {
    value: [
      {
        value: "General Investment Account",
        description: `An account that doesn’t have the restrictions — or tax benefits — of a retirement account.`,
        key: "gen_inv_acct",
      },
      {
        value: "IRA (Retirement Account)",
        description: `An individual retirement account (IRA) allows you to save money for retirement with some tax-benefits.`,
        key: "ira",
      },
      {
        value: "Investing for Children",
        description: `An account that allows you to save for education or invest on behalf of a minor.`,
        key: "inv_child",
      },
      {
        value: "Specialty Account",
        description: `An account for trustees wanting to invest on behalf of a trust or individuals wanting to invest on behalf of a corporation or other organization.`,
        key: "spec_acct",
      },
    ],
  },
  key: "acct_type",
};

const AccountDetailsReducerGuest = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ACCOUNT_DETAILS_GUEST: {
      return {...state,};
    }
    case ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_SUCCESS: {
      return {...state,...action,isLoading: false,};
    }
    case ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_LOADER: {
      return { ...state, ...action, isLoading: true };
    }
    case ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_FAILURE: {
        return { ...state, ...action, isLoading: false};
    }
    case ActionTypes.GUEST_DASHBOARD_ACCOUNT_TYPE_ERROR: {
        return { ...state, ...action, isLoading: false};
    }
    default:
      return state;
  }
};

export default AccountDetailsReducerGuest;
