import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import ApiEventHub from './ApiEventHub';
import Config from 'env/Config';
import {
    post,
    get,
    setMSRAuthHeaders,
    removeAPIVersionHeader
} from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';


const MarketNAOApiEvents = () => {
 
    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

  

    ApiEventHub.on(ActionTypes.GET_MARKET_NAO_INFO, data => {
          
        get(
            Config.getmarketNAOInfo,
            data.payload,
            data.endPointURL.DASH_BOARD,
            data.successCallBack,
            data.errorCallback,
        );
    });



     
    ApiEventHub.on(ActionTypes.SUBMIT_MARKET_NAO_INFO, data => {
    
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        removeAPIVersionHeader(); 
        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey,'nofisMemberID');
        post(
            Config.openaccountInd,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    
    // Personal info 
    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_INFO, data => {
       
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_ADDRESS_INFO, data => {
        
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_CONTACT_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_EMPLOYMENT_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_MILITRAY_INFO, data => {

        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_FINANCIAL_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_TRUSTED_CONTACT_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    ApiEventHub.on(ActionTypes.POST_MARKET_NAO_PERSONAL_DELIVERY_PREFERENCES_INFO, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };
        post(
            `${Config.postmarketNAOInfo}/${data.payload.url}`,
            data.payload.payloadData,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    ApiEventHub.on(ActionTypes.GET_MARKET_APPLICATIONID_DATA, data => {
        
        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
        
        // console.log('@@@ token',token)
        get(
            Config.getmarketNAOInfo,
            data.payload,
            data.endPointURL.getCompositeData,
            data.successCallBack,
            data.errorCallback,
        );
    });


    ApiEventHub.on(ActionTypes.POST_KYC_VERIFICATION, data => {
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
        
        post(
            `${Config.kycVerification}`,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

    ApiEventHub.on(ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_VERIFICATION, data => {
        post(
            `${Config.getBrokereageBankaccountsVerification}`,
            data.payload,
            data.endPointURL.getDashboardSavedItems,
            data.successCallBack,
            data.errorCallback,
        );
    });

    
    ApiEventHub.on(ActionTypes.SUBMIT_KYC_UPLOADED_DOCUMENTS, data => {   
        removeAPIVersionHeader(); 
        const options = {
            headers: {  'Content-Type': 'application/json' },
        };

        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey,'nofisMemberID');
        
        post(
            Config.submitKycUploadedDocuments,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });

}

export default MarketNAOApiEvents;

