const amendHeadingText = 'Amend';

const wrapperStyles = {
    backgroundColor: '#FFFFFF',
    minHeight: '90vh',
    position: 'relative',
    color: '#56565A',
    fontFamily: 'Roboto Slab',
};

const amendHeadingStyles = {
    top: '142px',
    left: '113px',
    width: '137px',
    height: '29px',
    textAlign: 'left',
    verticalAlign: 'middle',
    font: 'Bold 22px/35px Roboto Slab',
    letterSpacing: '0',
    color: '#56565A',
    opacity: 1,
    display: 'inline-block'
}

const btnStyleSubmit = {
    width: '160px',
    float:'right',
    color: '#FFFFFF',
}

const btnStyle = {
    width: '160px'
}

export default {
    wrapperStyles,
    amendHeadingText,
    amendHeadingStyles,
    btnStyleSubmit,
    btnStyle
}