const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 'normal',
    marginTop:8
};

const radiostyles = { color: 'rgba(73, 73, 74, 1)', fontSize: 16, marginBottom:25 };

const radiotext = {
    fontWeight: '600'
}

const subHeading = {
    font: '600 16px/19px benton-sans',
    color: '#49494A',
    marginBottom: 30
}

export default {
    radiodescstyles,
    radiostyles,
    radiotext,
    subHeading
}