import { connect } from 'react-redux';
import { get } from 'lodash';
import { manageBeneficiaryActions, analytics } from 'shared/Actions/';
import ManageBeneficiaries from './ManageBeneficiariesComponent';
import { mockedBeneficiariesMultiples }  from './mocks';

export const getBeneficiariesMock = () => dispatch => {
    dispatch({
        type: 'GET_BENEFICIARY_SUCCESS',
        payload: {manage_beneficiary: mockedBeneficiariesMultiples.result}
      });
    dispatch(analytics.linkResponseSuccess());
};

const mapStateToProps = (state) => ({
    manageBeneficiaryData: state.manageBeneficiaryData,
    originalBeneficiaryData: get(state, 'manageBeneficiaryData.manage_beneficiary', {}),
    isSuccessAddBeneficiary: state.manageBeneficiaryData.isSuccessAddBeneficiary,
    isLoadingAddBeneficiary: state.manageBeneficiaryData.isLoadingAddBeneficiary,
    isErrorAddBeneficiary: state.manageBeneficiaryData.isErrorAddBeneficiary,
    isLoadingDeleteBeneficiary: state.isLoadingDeleteBeneficiary,
    isErrorDeleteBeneficiary: state.isErrorDeleteBeneficiary,
    isSuccessDeleteBeneficiary: state.isSuccessDeleteBeneficiary
});

const mapDispatchToProps = {
    getBeneficiaries: manageBeneficiaryActions.getBeneficiaries, // () => getBeneficiariesMock(), // 
    saveBeneficiaryData: manageBeneficiaryActions.saveBeneficiaryData,
    analyzeClickEvent: analytics.analyzeClickEvent,
    clearBeneficiaryData: manageBeneficiaryActions.clearBeneficiaryData
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBeneficiaries);
