/**
 * Server Error Page Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component is displayed when there is an error in application
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Header, Footer, WButton } from '../../../common';
import './ServerErrorPage.css';
import consts from './Consts';

export default class APIReturnedStatusCodeErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errDesc: '',
            redirect: false,
        };
    }

    handleRefresh = () => {
        this.setState({ redirect: true });
    };

    render() {
        const { errorInfo } = this.props;
        let { errDesc } = this.state;
        const { redirect } = this.state;
        if (errorInfo.errors && errorInfo.errors.length > 0) {
            errDesc = errorInfo.errors[0].message;
        } else {
            errDesc = errorInfo.data && errorInfo.data.message;
        }
        return (
            <div>
                <Header />
                <div className="container">
                    <h2 style={consts.pageHeaderStyle}>{consts.APIErrorHeader}</h2>
                    <div style={consts.headerStyle}>
                        {consts.statusCode} {errorInfo.status}
                    </div>
                    <div style={consts.descStyle}>
                        {consts.messageFromAPI} {errDesc}
                    </div>
                    <WButton buttontext="Refresh" buttonstyle={consts.refreshStyle} onClick={this.handleRefresh} />
                    {redirect ? <Redirect push to="/" /> : ''}
                </div>
                <Footer hideDesc />
            </div>
        );
    }
}

APIReturnedStatusCodeErrorPage.propTypes = {
    errorInfo: PropTypes.instanceOf(Object),
};

APIReturnedStatusCodeErrorPage.defaultProps = {
    errorInfo: {},
};
