const validErrorMessage = 'Please enter a valid value';
const emptyErrorMessage = 'Please enter a value';

const Rules = {
    // "transitRoutingNumber" : { "length": 9,"isNumeric": true,required:true},
    // "accountNumber" :{"length": 10, "isNumeric": true,required:true},
    // "financialInstitutionName" :{"maxLength":30,required:true},
    // "accountOwner" :{"maxLength": 30,required:true},
    initialInvestment: { minValue: 1, minValueMsg: validErrorMessage },
    monthlyInvestment: { minValue: 1, minValueMsg: validErrorMessage },
    fundingOption: { required: true, requiredMsg: emptyErrorMessage },
    startDate: {
        required: true,
        requiredMsg: emptyErrorMessage,
        futureDate: true,
        futureDateErrorMsg: validErrorMessage,
    },
};

export default Rules;
