import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import { inputCheck } from 'utils';
import contents from 'modules/AccountOptions/constant';
import Accordian from '../Accordian';
import AccordianTitle from '../AccordianTitle';
import WRadio from '../Radio/Radio';
import VCMSelect from '../SelectDropDown';
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';

const Description = styled.legend`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 1.563rem;
`;
const assignObj = obj => {
    return obj;
};
const StyledInput = styled.input`
    width: 100%;
    height: 3rem;
    border-radius: 0px;
    && {
        border: 1px solid ${props => (props.error ? 'red' : 'rgb(210, 210, 210)')};
        &:hover {
            border-color: ${props => (props.error ? 'red' : 'rgba(72, 109, 144, 1)')};
        }
        &:focus {
            border-color: ${props => (props.error ? 'red' : 'rgba(0, 74, 152, 1)')};
        }
    }
`;
const StyledDollar = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-right: ${props => (props.mR ? `${props.mR}rem` : '10px')};
`;
const StyledAmt = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const radiotext = {
    cololr: '#56565A',
    font: '500 16px/24px benton-sans',
};
const rowStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center' };

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
`;
const StyledRow = styled(Row)`
    margin-bottom: 1.875rem;
    align-items: center;
`;

const StyledLabel = styled(Form.Label)`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    && {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`;
const LabelInstruction = styled.span`
    font: 500 12px/14px benton-sans;
    color: rgb(86, 86, 90);
    opacity: 1;
    margin-left: 0.625rem;
    position: relative;
    top: -1px;
`;
const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
`;
const RequiredDiv = styled.div`
    text-align: right;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top: 2.5rem;
`;
const LabelColRow = props => {
    const { label, labelsm, valuesm, id, children, extraCol, labelInstruction } = props;
    return (
        <StyledRow>
            <StyledLabel column sm={labelsm} htmlFor={id}>
                {label}
                {labelInstruction && <LabelInstruction>{labelInstruction}</LabelInstruction>}
            </StyledLabel>
            <StyledCol sm={valuesm}>{children}</StyledCol>
            {extraCol}
        </StyledRow>
    );
};
LabelColRow.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    labelsm: PropTypes.number,
    valuesm: PropTypes.number,
    children: PropTypes.node.isRequired,
    extraCol: PropTypes.node,
    labelInstruction: PropTypes.string,
};

LabelColRow.defaultProps = {
    labelsm: 2,
    valuesm: 4,
    id: '',
    label: '',
    extraCol: '',
    labelInstruction: '',
};

const ErrMsg = styled.small.attrs(({forErr}) => ({'htmlFor':forErr, 'aria-live':'polite','aria-atomic':true}))`
    position: absolute;
    display: block;
    margin-top: 0.25rem;
    color: red;
    width: max-content;
`;

class TaxWithholdingOptions extends Component {
    // Method to check the status of this component to enable or disable the next button
    static checkNextStatus(data) {
        const { taxMethod, federalTaxPercent, stateTaxPercent, isTaxWithholdNeed } = data;
        let disableNext = true;
        if (isTaxWithholdNeed) {
            if (
                taxMethod &&
                Number(federalTaxPercent) + Number(stateTaxPercent) <= 99 &&
                federalTaxPercent > 0 &&
                stateTaxPercent > 0
            ) {
                disableNext = false;
            }
        } else {
            disableNext = false;
        }
        return disableNext;
    }

    constructor(props) {
        super(props);
        const { pageIndex } = this.props;
        this.state = {
            taxMethod: '',
            amount: '',
            federalTaxPercent: '',
            federalTaxAmt: 0,
            stateTaxPercent: '',
            stateTaxAmt: 0,
            totalWithhold: 0,
            totalReceiveAmt: 0,
            totalWithdrawal: 0,
            isTaxWithholdNeed: false,
            retrivedState: false,
            errorMsg: {
                amt: '',
                federaltax: '',
                statetax: '',
            },
            // Commented for lint error - state not required to this component but used in parent
            // disableNext: false,
            // displayDetails: null,
        };
        this.defaultDisplayDetails = {
            taxWithholdOptions: {
                titleTxt: 'Tax Withholding Options',
                editTxt: 'Edit',
                heading: 'Withhold Taxes',
                pageIndex,
                data: [],
            },
        };

        this.onTaxMethodChange = this.onTaxMethodChange.bind(this);
        this.onSwitch = this.onSwitch.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }

        return null;
    }

    // On changing the tax method the taxes are calculated
    onTaxMethodChange(e) {
        const taxMethod = e.target.value;
        this.setState(
            prevState => {
                const { isTaxWithholdNeed } = prevState;
                let { amount, federalTaxPercent, stateTaxPercent } = prevState;
                amount = +amount;
                federalTaxPercent = +federalTaxPercent;
                stateTaxPercent = +stateTaxPercent;
                const {
                    federalTaxAmt,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                } = this.calculateTaxes(taxMethod, amount, federalTaxPercent, stateTaxPercent);
                const disableNext = this.constructor.checkNextStatus({
                    taxMethod,
                    federalTaxPercent,
                    stateTaxPercent,
                    isTaxWithholdNeed,
                });
                const data = {
                    isTaxWithholdNeed,
                    amount,
                    federalTaxPercent,
                    federalTaxAmt,
                    stateTaxPercent,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                    taxMethod,
                    disableNext,
                };
                return {
                    taxMethod,
                    federalTaxAmt,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                    disableNext,
                    displayDetails: this.setDisplayDetails(data),
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    }

    // Updates the amount value on change after validation
    onAmtChange = e => {
        const amount = inputCheck(e.target.value);
        if (amount === null) return;

        this.setState(() => {
            return {
                amount,
            };
        });
    };

    // Updates the state and federal tax percent's on change after validation
    onTaxPercentChange = param => e => {
        const enteredValue = inputCheck(e.target.value);
        if (enteredValue === null || enteredValue > 100) return;

        this.setState(() => {
            const returnObj = {};
            if (param === 'federaltax') {
                returnObj.federalTaxPercent = enteredValue;
            } else {
                returnObj.stateTaxPercent = enteredValue;
            }
            return returnObj;
        });
    };


    assignValueObjParams = (param, e, value) => {
        let valueObj = {};
        if(param === 'amt'){
            valueObj.amount = e.target.value ? value.toFixed(2) : e.target.value;
        }else if(param === 'federaltax' ){
            valueObj.federalTaxPercent = e.target.value ? value.toFixed(2) : e.target.value;
        }else if(param === 'statetax' ){
            valueObj.stateTaxPercent = e.target.value ? value.toFixed(2) : e.target.value;
        }

        return valueObj
    }

    isValueLessThanOneOrHigherThan98 = (value) => {
        return(value && (value < 1 || value > 98))
    }

    validateStateAndFederalTax = (errorMsgParams, federalTaxPercent, stateTaxPercent) => {

        let errorMsg = errorMsgParams
        if ((Number(federalTaxPercent) * 100 + Number(stateTaxPercent) * 100) / 100 <= 99) {
            errorMsg.statetax = '';
            errorMsg.federaltax = '';
        }

        return errorMsg
    }


    // Method to set error message and fetches the tax calculation on blur
    onInputBlur = param => e => {
        const value = +e.target.value;
        const valueObj = this.assignValueObjParams(param, e, value)
       
        if (!value) {
            const inlineErr = getValidValueOf(e?.target?.value, 'Please enter a valid amount', 'Please enter the amount');
            this.setState(
                prevState => {
                    const { errorMsg } = prevState;
                    errorMsg[`${param}`] = inlineErr;
                    return {
                        disableNext: true,
                        errorMsg,
                        federalTaxAmt: 0,
                        stateTaxAmt: 0,
                        totalWithhold: 0,
                        totalReceiveAmt: 0,
                        totalWithdrawal: 0,
                        ...valueObj
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        } else {
            this.setState(
                prevState => {
                    const {
                        taxMethod,
                        isTaxWithholdNeed,
                    } = prevState;
                    
                    let {
                        amount,
                        federalTaxPercent,
                        stateTaxPercent,
                        errorMsg,
                    } = prevState;
                    
                    amount = +amount;
                    federalTaxPercent = +federalTaxPercent;
                    stateTaxPercent = +stateTaxPercent;

                    // Validating the percentage inputs
                    if (param !== 'amt') {
                        if (this.isValueLessThanOneOrHigherThan98(value)) {
                            errorMsg[`${param}`] = getValidValueOf(value < 1, 'Min percentage allowed is 1', 'Max percentage allowed is 98');
                            return {
                                disableNext: true,
                                errorMsg,
                                federalTaxAmt: 0,
                                stateTaxAmt: 0,
                                totalWithhold: 0,
                                totalReceiveAmt: 0,
                                totalWithdrawal: 0,
                                ...valueObj
                            };
                        }
                        if (federalTaxPercent && stateTaxPercent) {
                            if ((Number(federalTaxPercent) * 100 + Number(stateTaxPercent) * 100) / 100 > 99) {
                                errorMsg.statetax = '';
                                errorMsg.federaltax = '';
                                errorMsg[`${param}`] =
                                    'Federal tax and State tax percentage should not exceed 99 in total';
                                return {
                                    disableNext: true,
                                    errorMsg,
                                    federalTaxAmt: 0,
                                    stateTaxAmt: 0,
                                    totalWithhold: 0,
                                    totalReceiveAmt: 0,
                                    totalWithdrawal: 0,
                                    ...valueObj
                                };
                            }

                            errorMsg = this.validateStateAndFederalTax(errorMsg, federalTaxPercent, stateTaxPercent)
                        }
                    }

                    // Clearing validity checking error
                    errorMsg[`${param}`] = '';

                    const {
                        federalTaxAmt,
                        stateTaxAmt,
                        totalWithhold,
                        totalReceiveAmt,
                        totalWithdrawal,
                    } = this.calculateTaxes(taxMethod, amount, federalTaxPercent, stateTaxPercent);

                    const disableNext = this.constructor.checkNextStatus({
                        taxMethod,
                        federalTaxPercent,
                        stateTaxPercent,
                        isTaxWithholdNeed,
                    });
                    const data = {
                        isTaxWithholdNeed,
                        amount,
                        federalTaxPercent,
                        federalTaxAmt,
                        stateTaxPercent,
                        stateTaxAmt,
                        totalWithhold,
                        totalReceiveAmt,
                        totalWithdrawal,
                        taxMethod,
                        disableNext,
                    };
                    return {
                        amount: amount.toFixed(2),
                        federalTaxPercent: federalTaxPercent ? federalTaxPercent.toFixed(2) : '',
                        federalTaxAmt,
                        stateTaxPercent: stateTaxPercent ? stateTaxPercent.toFixed(2) : '',
                        stateTaxAmt,
                        totalWithhold,
                        totalReceiveAmt,
                        totalWithdrawal,
                        errorMsg,
                        disableNext,
                        displayDetails: this.setDisplayDetails(data),
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        }
    };

    // Method to switch the tax withhold option
    onSwitch = value => () => {
        this.setState(
            prevState => {
                let {
                    amount,
                    federalTaxPercent,
                    federalTaxAmt,
                    stateTaxPercent,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                    taxMethod,
                } = prevState;

                const { errorMsg } = prevState;
                const isTaxWithholdNeed = value;

                if (isTaxWithholdNeed) {
                    amount = '';
                    federalTaxPercent = '';
                    federalTaxAmt = 0;
                    stateTaxPercent = '';
                    stateTaxAmt = 0;
                    totalWithhold = 0;
                    totalReceiveAmt = 0;
                    totalWithdrawal = 0;
                    taxMethod = '';
                    errorMsg.amt = '';
                    errorMsg.federaltax = '';
                    errorMsg.statetax = '';
                }
                const disableNext = this.constructor.checkNextStatus({
                    taxMethod,
                    federalTaxPercent,
                    stateTaxPercent,
                    isTaxWithholdNeed,
                });
                const data = {
                    isTaxWithholdNeed,
                    amount,
                    federalTaxPercent,
                    federalTaxAmt,
                    stateTaxPercent,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                    taxMethod,
                    disableNext,
                };
                return {
                    isTaxWithholdNeed,
                    amount,
                    federalTaxPercent,
                    federalTaxAmt,
                    stateTaxPercent,
                    stateTaxAmt,
                    totalWithhold,
                    totalReceiveAmt,
                    totalWithdrawal,
                    taxMethod,
                    disableNext,
                    displayDetails: this.setDisplayDetails(data),
                    errorMsg,
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    };

    // Method to set the data to display in verify page
    setDisplayDetails(data) {
        let displayDetails = null;
        const {
            isTaxWithholdNeed,
            amount,
            federalTaxPercent,
            federalTaxAmt,
            stateTaxPercent,
            stateTaxAmt,
            totalWithhold,
            totalReceiveAmt,
            totalWithdrawal,
            taxMethod,
            disableNext,
        } = data;
        if (!disableNext) {
            if (isTaxWithholdNeed) {
                displayDetails = {};
                const taxWithholdOptions = { ...this.defaultDisplayDetails.taxWithholdOptions };
                const displayData = [];
                displayData.push(
                    {
                        field: 'Is the requested amount',
                        value: taxMethod,
                    },
                    {
                        field: `Amount ${taxMethod}`,
                        value: `$ ${Number(amount).toFixed(2)}`,
                    },
                    {
                        field: 'Federal Tax',
                        value: `${Number(federalTaxPercent).toFixed(2)}% - $ ${Number(federalTaxAmt).toFixed(2)}`,
                    },
                    {
                        field: 'State Tax',
                        value: `${Number(stateTaxPercent).toFixed(2)}% - $ ${Number(stateTaxAmt).toFixed(2)}`,
                    },
                    {
                        field: 'Total Taxes to be withhold',
                        value: `$ ${Number(totalWithhold).toFixed(2)}`,
                    },
                    {
                        field: 'Total you will receive',
                        value: `$ ${Number(totalReceiveAmt).toFixed(2)}`,
                    },
                    {
                        field: 'Total Withdrawal',
                        value: `$ ${Number(totalWithdrawal).toFixed(2)}`,
                    },
                );
                taxWithholdOptions.data = displayData;
                displayDetails.taxWithholdOptions = taxWithholdOptions;
            }
        }
        return displayDetails;
    }

    // Method to get the state of this component
    getComponentState() {
        const { state = {} } = this || {};
        return { ...state };
    }

    //Method to remove space
    removeSpaces(originalstring) {
        return originalstring.split(" ").join("");
    }

    // Method for tax calculations
    calculateTaxes(taxMethod, amount, federalTaxPercent, stateTaxPercent) {
        let { federalTaxAmt, stateTaxAmt, totalWithhold, totalReceiveAmt, totalWithdrawal } = this.state;
        if (amount && federalTaxPercent && stateTaxPercent && taxMethod) {
            if (taxMethod === 'Before Taxes') {
                federalTaxAmt = Number(((amount * federalTaxPercent) / 100).toFixed(2));
                stateTaxAmt = Number(((amount * stateTaxPercent) / 100).toFixed(2));
                totalWithhold = (federalTaxAmt * 100 + stateTaxAmt * 100) / 100;
                totalReceiveAmt = (amount * 100 - totalWithhold * 100) / 100;
                totalWithdrawal = amount;
            } else if (taxMethod === 'After Taxes') {
                totalReceiveAmt = amount;
                const totalWithholdPercent = federalTaxPercent / 100 + stateTaxPercent / 100;
                const totalReceivePercent = (100 - totalWithholdPercent * 100) / 100;
                totalWithdrawal = Number((totalReceiveAmt / totalReceivePercent).toFixed(2));
                federalTaxAmt = Number(((totalWithdrawal * federalTaxPercent) / 100).toFixed(2));
                stateTaxAmt = Number(((totalWithdrawal * stateTaxPercent) / 100).toFixed(2));
                totalWithhold = (federalTaxAmt * 100 + stateTaxAmt * 100) / 100;
            }
        } else {
            federalTaxAmt = 0;
            stateTaxAmt = 0;
            totalWithhold = 0;
            totalReceiveAmt = 0;
            totalWithdrawal = 0;
        }

        return {
            federalTaxAmt,
            stateTaxAmt,
            totalWithhold,
            totalReceiveAmt,
            totalWithdrawal,
        };
    }

    render() {
        const { SWPTaxWithHoldingOptions, requiredfield = '' } = contents;
        const {
            heading = '',
            description = '',
            dropDownList,
            txt1 = '',
            txt2 = '',
            txt3 = '',
            txt4 = '',
            txt5 = '',
            txt6 = '',
            txt7 = '',
            txt8 = '',
            txt9 = '',
            symbol,
            taxFieldInstruction = '',
        } = SWPTaxWithHoldingOptions;
        const { dollar = '' } = symbol;
        const {
            taxMethod,
            amount,
            federalTaxPercent,
            federalTaxAmt,
            stateTaxPercent,
            stateTaxAmt,
            totalWithhold,
            totalReceiveAmt,
            totalWithdrawal,
            isTaxWithholdNeed,
            errorMsg,
        } = this.state;

        const { forwardRef } = this.props;
        const withholdtax = 'Withhold Taxes';
        const donotwithholdtax = 'Do not withhold Taxes';
        let taxAmount;
        if (taxMethod === 'After Taxes') { taxAmount = txt9 } else if(taxMethod === 'Before Taxes') { taxAmount = txt2 } else { taxAmount = txt8 }

        return (
            <>
                <RequiredDiv>{requiredfield}</RequiredDiv>
                <Accordian
                    ref={forwardRef}
                    key={heading}
                    titleChild={<AccordianTitle accType={heading} id={heading} accIndex = {0}/>}
                    titleSeperator
                    labelledBy={heading}
                >
                    <>
                        <Description>{description}</Description>
                        <>
                            <Row style={assignObj({ marginBottom: 20 })} role="radiogroup">
                                <Col sm={6}>
                                    <div style={rowStyle}>
                                        <WRadio
                                            id={this.removeSpaces(donotwithholdtax)}
                                            value={donotwithholdtax}
                                            selected={!isTaxWithholdNeed}
                                            onClick={this.onSwitch(false)}
                                            radiotext={radiotext}
                                            radioFor={this.removeSpaces(donotwithholdtax)}
                                        />
                                        <WRadio
                                            id={this.removeSpaces(withholdtax)}
                                            value={withholdtax}
                                            selected={isTaxWithholdNeed}
                                            onClick={this.onSwitch(true)}
                                            radiotext={radiotext}
                                            radioFor={this.removeSpaces(withholdtax)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        {isTaxWithholdNeed && (
                            <>
                                <VCMSelect
                                    labelsm={3}
                                    valuesm={3}
                                    id={this.removeSpaces(txt1)}
                                    itemlist={dropDownList}
                                    label={txt1}
                                    onChange={this.onTaxMethodChange}
                                    value={taxMethod}
                                />
                                <LabelColRow labelsm={3} valuesm={2} id={this.removeSpaces(txt2)} label={taxAmount} flex>
                                    <StyledDollar mR={0.625}>{dollar}</StyledDollar>
                                    <div>
                                        <StyledInput
                                            onChange={this.onAmtChange}
                                            value={amount}
                                            type="text"
                                            error={!!errorMsg.amt}
                                            name={txt2}
                                            id={this.removeSpaces(txt2)}
                                            onBlur={this.onInputBlur('amt')}
                                        />
                                        {errorMsg.amt && <ErrMsg forErr={this.removeSpaces(txt2)}>{errorMsg.amt}</ErrMsg>}
                                    </div>
                                </LabelColRow>
                                <LabelColRow
                                    labelsm={3}
                                    valuesm={2}
                                    id={this.removeSpaces(txt3)}
                                    label={txt3}
                                    extraCol={
                                        <StyledSpan>
                                            <StyledDollar>{dollar}</StyledDollar> <StyledAmt>{federalTaxAmt.toFixed(2)}</StyledAmt>
                                        </StyledSpan>
                                    }
                                    labelInstruction={taxFieldInstruction}
                                >
                                    <div>
                                        <StyledInput
                                            onChange={this.onTaxPercentChange('federaltax')}
                                            value={federalTaxPercent}
                                            type="text"
                                            error={!!errorMsg.federaltax}
                                            name={txt3}
                                            id={this.removeSpaces(txt3)}
                                            onBlur={this.onInputBlur('federaltax')}
                                        />
                                        {errorMsg.federaltax && <ErrMsg forErr={this.removeSpaces(txt3)}>{errorMsg.federaltax}</ErrMsg>}
                                    </div>
                                </LabelColRow>

                                <LabelColRow
                                    labelsm={3}
                                    valuesm={2}
                                    id={this.removeSpaces(txt4)}
                                    label={txt4}
                                    extraCol={
                                        <StyledSpan>
                                            <StyledDollar>{dollar}</StyledDollar> <StyledAmt> {stateTaxAmt.toFixed(2)}</StyledAmt>
                                        </StyledSpan>
                                    }
                                    labelInstruction={taxFieldInstruction}
                                >
                                    <div>
                                        <StyledInput
                                            onChange={this.onTaxPercentChange('statetax')}
                                            value={stateTaxPercent}
                                            type="text"
                                            error={!!errorMsg.statetax}
                                            name={txt4}
                                            id={this.removeSpaces(txt4)}
                                            onBlur={this.onInputBlur('statetax')}
                                        />
                                        {errorMsg.statetax && <ErrMsg forErr={this.removeSpaces(txt4)}>{errorMsg.statetax}</ErrMsg>}
                                    </div>
                                </LabelColRow>

                                <LabelColRow labelsm={3} valuesm={2} id={this.removeSpaces(txt5)} label={txt5}>
                                    <input type="hidden" name={txt5} id={this.removeSpaces(txt5)} />
                                    <StyledSpan>
                                        <StyledDollar> {dollar}</StyledDollar> <StyledAmt>{totalWithhold.toFixed(2)}</StyledAmt>
                                    </StyledSpan>
                                </LabelColRow>

                                <LabelColRow labelsm={3} valuesm={2} id={this.removeSpaces(txt6)} label={txt6}>
                                    <input type="hidden" name={txt6} id={this.removeSpaces(txt6)} />
                                    <StyledSpan>
                                        <StyledDollar>{dollar}</StyledDollar> <StyledAmt>{totalReceiveAmt.toFixed(2)}</StyledAmt>
                                    </StyledSpan>
                                </LabelColRow>

                                <LabelColRow labelsm={3} valuesm={2} id={this.removeSpaces(txt7)} label={txt7}>
                                    <input type="hidden" name={txt7} id={this.removeSpaces(txt7)} />
                                    <StyledSpan>
                                        <StyledDollar> {dollar}</StyledDollar> <StyledAmt>{totalWithdrawal.toFixed(2)}</StyledAmt>
                                    </StyledSpan>
                                </LabelColRow>
                            </>
                        )}
                    </>
                </Accordian>
            </>
        );
    }
}

TaxWithholdingOptions.propTypes = {
    forwardRef: PropTypes.instanceOf(Object).isRequired,
    savedState: PropTypes.instanceOf(Object),
    checkStatus: PropTypes.func,
    pageIndex: PropTypes.number.isRequired,
    options: PropTypes.bool
};

TaxWithholdingOptions.defaultProps = {
    savedState: null,
    options: true,
    checkStatus: () => {},
};

export default TaxWithholdingOptions;
