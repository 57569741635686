// Action types

export const DELETE_ALL_SELECTED_MESSAGES_SUCCESS = 'DELETE_ALL_SELECTED_MESSAGES_SUCCESS';
export const DELETE_ALL_SELECTED_MESSAGES_ERROR = 'DELETE_ALL_SELECTED_MESSAGES_ERROR';

export const DELETE_SELECTED_MESSAGES_SUCCESS = 'DELETE_SELECTED_MESSAGES_SUCCESS';
export const DELETE_SELECTED_MESSAGES_ERROR = 'DELETE_SELECTED_MESSAGES_ERROR';

export const UPDATE_ALL_SELECTED_MESSAGES_SUCCESS = 'UPDATE_ALL_SELECTED_MESSAGES_SUCCESS';
export const UPDATE_ALL_SELECTED_MESSAGES_ERROR = 'UPDATE_ALL_SELECTED_MESSAGES_ERROR';

export const UPDATE_SELECTED_MESSAGES_SUCCESS = 'UPDATE_SELECTED_MESSAGES_SUCCESS';
export const UPDATE_SELECTED_MESSAGES_ERROR = 'UPDATE_SELECTED_MESSAGES_ERROR';

export const SET_DISPLAY_MESSAGES_SUCCESS = 'SET_DISPLAY_MESSAGES_SUCCESS';
export const SET_DISPLAY_MESSAGES_ERROR = 'SET_DISPLAY_MESSAGES_ERROR';

export const SET_SELECT_ALL_SUCCESS = 'SET_SELECT_ALL_SUCCESS';
export const SET_SELECT_ALL_ERROR = 'SET_SELECT_ALL_ERROR';

export const SET_MC_CONFIRMATION_MSG_SUCCESS = 'SET_MC_CONFIRMATION_MSG_SUCCESS';
export const SET_MC_CONFIRMATION_MSG_ERROR = 'SET_MC_CONFIRMATION_MSG_ERROR';

export const DELETE_MC_MESSAGES = 'DELETE_MC_MESSAGES';
export const SOFT_DELETE_MESSAGE_SUCCESS = 'SOFT_DELETE_MESSAGE_SUCCESS';
export const SOFT_DELETE_MESSAGE_START = 'SOFT_DELETE_MESSAGE_START';
export const SOFT_DELETE_MESSAGE_ERROR = 'SOFT_DELETE_MESSAGE_ERROR';

export const SHOW_DELETE_CONFIRM_DIALOG_SUCCESS = 'SHOW_DELETE_CONFIRM_DIALOG_SUCCESS';
export const SHOW_DELETE_CONFIRM_DIALOG_ERROR = 'SHOW_DELETE_CONFIRM_DIALOG_ERROR';

export const SHOW_SUCCESS_MSG_DIALOG_SUCCESS = 'SHOW_SUCCESS_MSG_DIALOG_SUCCESS';
export const SHOW_SUCCESS_MSG_DIALOG_ERROR = 'SHOW_SUCCESS_MSG_DIALOG_ERROR';

export const READ_MC_MESSAGES = 'READ_MC_MESSAGES';
export const READ_MC_MESSAGE_ERROR = 'READ_MC_MESSAGE_ERROR';
export const READ_MC_MESSAGE_SUCCESS = 'READ_MC_MESSAGE_SUCCESS';
export const READ_MC_MESSAGE_START = 'READ_MC_MESSAGE_START';

export const GET_MC_MESSAGES = 'GET_MC_MESSAGES';
export const GET_MC_MESSAGES_START = 'GET_MC_MESSAGES_START';
export const GET_MC_MESSAGES_SUCCESS = 'GET_MC_MESSAGES_SUCCESS';
export const GET_MC_MESSAGES_ERROR = 'GET_MC_MESSAGES_ERROR';

export const SEND_SECURE_MESSAGE = 'SEND_SECURE_MESSAGE';
export const SEND_SECURE_MESSAGE_SUCESS = 'SEND_SECURE_MESSAGE_SUCESS';
export const SEND_SECURE_MESSAGE_ERROR = 'SEND_SECURE_MESSAGE_ERROR';

export const UPDATE_MESSAGE_TYPE_SUCCESS = 'UPDATE_MESSAGE_TYPE_SUCCESS';
export const UPDATE_MESSAGE_TYPE_ERROR = 'UPDATE_MESSAGE_TYPE_ERROR';

export const SET_PAGE_SIZE_SUCCESS = 'SET_PAGE_SIZE_SUCCESS';
export const SET_PAGE_SIZE_ERROR = 'SET_PAGE_SIZE_ERROR';

export const SET_ORDER_BY_ORDER_TYPE_SUCCESS = 'SET_ORDER_BY_ORDER_TYPE_SUCCESS';
export const SET_ORDER_BY_ORDER_TYPE_ERROR = 'SET_ORDER_BY_ORDER_TYPE_ERROR';

export const SET_MESSAGE_TYPE = 'SET_MESSAGE_TYPE';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

// User Messages to be displayed
export const MESSAGE_CENTER_CONFIRM_MESSAGE = 'The message(s) have been deleted successfully.';
export const SEND_SECURE_MESSAGE_CONFIRM_MESSAGE = 'Your request is successfully completed.';
export const SEND_SECURE_MESSAGE_ERROR_MESSAGE = 'Internal serve issue, please try again.';

// String constants used
export const MESSAGE_CENTER_DATE_VAL = 'date';
export const MESSAGE_CENTER_DESCENDING_VAL = 'descending';
export const MESSAGE_CENTER_ASCENDING_VAL = 'ascending';
export const MESSAGE_CENTER_INBOX_VAL = 'inbox';
export const MESSAGE_CENTER_SENT_VAL = 'sent';
export const MESSAGE_CENTER_DELETED_VAL = 'deleted';

export const GET_DASHBOARD_WIDGET_MESSAGES = 'GET_DASHBOARD_WIDGET_MESSAGES';
export const GET_DASHBOARD_WIDGET_MESSAGES_START = 'GET_DASHBOARD_WIDGET_MESSAGES_START';
export const GET_DASHBOARD_WIDGET_MESSAGES_SUCCESS = 'GET_DASHBOARD_WIDGET_MESSAGES_SUCCESS';
export const GET_DASHBOARD_WIDGET_MESSAGES_ERROR = 'GET_DASHBOARD_WIDGET_MESSAGES_ERROR';
export const SET_LOGIN_DISPLAY_MESSAGE_SUCCESS = 'SET_LOGIN_DISPLAY_MESSAGE_SUCCESS';

export const REMOVE_MC_DOCUMENT = 'REMOVE_MC_DOCUMENT';
export const REMOVE_MC_DOCUMENT_ERROR = 'REMOVE_MC_DOCUMENT_ERROR';
export const REMOVE_MC_DOCUMENT_SUCCESS = 'REMOVE_MC_DOCUMENT_SUCCESS';
export const REMOVE_MC_DOCUMENT_START = 'REMOVE_MC_DOCUMENT_START';
export const REMOVE_MC_DOCUMENT_RESET = 'REMOVE_MC_DOCUMENT_RESET';
export const REMOVE_MC_MESSAGE_RESET = 'REMOVE_MC_MESSAGE_RESET';

export const GET_UPLOAD_SESSION_SUCCESS = 'GET_UPLOAD_SESSION_SUCCESS';
export const GET_UPLOAD_SESSION_START = 'GET_UPLOAD_SESSION_START';
export const GET_UPLOAD_SESSION_ERROR = 'GET_UPLOAD_SESSION_ERROR';

export const UPLOAD_FILE_STATUS = 'UPLOAD_FILE_STATUS';
export const STORE_UPLOADED_FILE_DOCUMENT_ID = 'STORE_UPLOADED_FILE_DOCUMENT_ID';

export const RESET_DELETED_FILE_INFO = 'RESET_DELETED_FILE_INFO';