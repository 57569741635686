import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';

export const LastName = (props) => {
    const { changed, blurred, clicked, lastNameState, disable } = props
    const data = {
        config: {
            id: "lastName",
            type: 'text',
            label: 'Last Name'
        },
        change: (event) => changed(event, 'lastName'),
        blur: () => blurred('lastName'),
        click: (event) => clicked(event)
    }

    let inputElement = null;
    const inputClasses = [classes.InputElement];
    const errorId = `${data.config.id}_error`;

    if (!lastNameState.valid && lastNameState.touched) {
        inputClasses.push(classes.Invalid);
    }

    inputElement = <input
        id={data.config.id}
        type={data.config.type}
        className={`analytics-form-fields ${inputClasses.join(' ')}`}
        value={lastNameState.value}
        onChange={data.change}
        onBlur={data.blur}
        onClick={clicked ? data.click: ""}
        aria-describedby={errorId}
        aria-invalid={!!lastNameState.errMsg}
        aria-required="true"
        readOnly={disable} />;

    return (
        <div className={classes.Input}>
            <label className={classes.Label} htmlFor={data.config.id}>{data.config.label}</label>
            {inputElement}
            {lastNameState.errMsg ? <p className={classes.errMsg} aria-live="polite" aria-atomic="true"  id={errorId}>{lastNameState.errMsg}</p> : null}
        </div>
    );

};

LastName.propTypes = {
    lastNameState: PropTypes.shape({
        value: PropTypes.string,
        errMsg: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        rules: PropTypes.shape({
            maxLength: PropTypes.number,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            required: PropTypes.bool
        })
    }),
    disable: PropTypes.bool,
    changed: PropTypes.func,
    blurred: PropTypes.func
};

LastName.defaultProps = {
    lastNameState: {
        value: '',
        errMsg: null,
        valid: true,
        touched: false,
        rules: {
            maxLength: 30,
            pattern: '^[a-zA-Z]*$',
            patternMessage: 'Should contain only Alphabets',
            required: true
        }
    },
    disable: false,
    changed: () => { },
    blurred: () => { }
};

export default LastName;