/**
 * Change Sign In Method options Component
 ***********************************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description This page displays the different methods to change the sign in mode
 * @createdDate [06/11/2019]
 * ***************************************
 * @createdDate Manjunath V
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedReason Modified for toggling Save button based on radio buttons click and added Breadcrumbs
 */

import React, { Component, memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import LoadingOverlay from 'react-loading-overlay';
import routeConstants from 'routeConstants';
import { WRadio, WBreadCrumb, CommonButtons, WSpinner } from '../../../common';
import SideHeader from '../SideHeader/SideHeader';
import styles from './styles';
import userConstants from '../userConstants';

const assignObj = obj => obj;
const { breadCrumbValues } = userConstants.changeSignInMethodOptions;

const {
    pageHeaderName,
    signInOptionsLabels,
    updateText,
    otpRdoBtn,
    softTokenRdoBtn,
    pushNotificationRdoBtn,
} = userConstants.changeSignInMethodOptions;

export class ChangeSignInMethodOptionsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
        };

        this.clickBack = this.clickBack.bind(this);
        this.clickNext = this.clickNext.bind(this);
        this.handleRadioClick = this.handleRadioClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState, getSignInMethod } = nextProps;
        // console.log(loginState)
        let updatedState = null;
        if (loginState && loginState.getChangeSignInMethod && loginState.getChangeSignInMethod.errorCode === 0) {
            // console.log(loginState.getChangeSignInMethod)
            const option = loginState.getChangeSignInMethod.result.signInMethod;
            if (option === userConstants.otp) {
                updatedState = { ...state, selectedOption: otpRdoBtn, currentOption: otpRdoBtn };
            } else if (option === userConstants.push) {
                updatedState = { ...state, selectedOption: pushNotificationRdoBtn, currentOption: pushNotificationRdoBtn };
            } else if (option === userConstants.softToken) {
                updatedState = { ...state, selectedOption: softTokenRdoBtn, currentOption: softTokenRdoBtn };
            }
            getSignInMethod('');
        }
        return updatedState;
    }

    componentDidMount() {
        const { location, getSignInMethod, setJWTToken } = this.props;
        const { state } = location || {};
        const onlineId = localStorage.getItem(userConstants.userId);
        const token = localStorage.getItem(userConstants.token);
        if (token) {
            setJWTToken(token);
        }
        getSignInMethod({
            onlineId,
        });
        if (state) {
            this.setState({
                selectedOptionOnPrevPage: state.selectedOption,
            });
        }
    }

    handleRadioClick = e => {
        e.preventDefault();
        if (!e.currentTarget.selected) {
            this.setState({
                selectedOption: e.target.id,
            });
        }
    };

    clickNext = () => {
        let pageName = '';
        const { selectedOption } = this.state;
        const { history } = this.props;

        if (selectedOption === otpRdoBtn) {
            pageName = userConstants.changeSignInMethodOptions.changeByOtp;
        } else if (selectedOption === softTokenRdoBtn) {
            pageName = userConstants.changeSignInMethodOptions.changeBySoft;
        } else if (selectedOption === pushNotificationRdoBtn) {
            pageName = userConstants.changeSignInMethodOptions.changeByPush;
        }
        if (history) {
            history.push({
                pathname: pageName,
                state: { selectedOption },
            });
        }
    };

    clickBack() {
        const { history } = this.props;
        if (history) {
            history.push({
                pathname: routeConstants.securityPreferences,
            });
        }
    }

    render() {
        const { selectedOption, currentOption } = this.state;
        const { loginState } = this.props;
        const { isLoading } = loginState;
        let toggleNextButton = '';

        if (selectedOption === '') {
            toggleNextButton = true;
        } else {
            toggleNextButton = false;
        }

        return (
            <div>
                <WSpinner loading={isLoading} />
                {/* <LoadingOverlay
                    active={isLoading}
                    spinner
                    styles={assignObj({ wrapper: { overflow: userConstants.hidden } })}
                /> */}
                <div className={userConstants.container}>
                    <WBreadCrumb
                        breadCrumbItems={breadCrumbValues}
                        activeCrumb={userConstants.changeSignInMethodText}
                    />
                    <SideHeader title={pageHeaderName} />
                    <Row className={userConstants.w100}>
                        <Col style={assignObj({ order: 2 })}>
                            <small
                                id={userConstants.changeSignInMethodOptions.lastUpdateId}
                                className={userConstants.changeSignInMethodOptions.updateTextClass}
                            >
                                {updateText}
                            </small>
                        </Col>
                        <Col md={9} lg={9} xl={9}>
                            <Form.Group role={userConstants.radioGroup} aria-labelledby="chooseSigninMethods">
                                <span className="sr-only" id="chooseSigninMethods" style={styles.subHeading}>{signInOptionsLabels}</span>
                                <WRadio
                                    value={
                                        userConstants.otpText + (otpRdoBtn === currentOption
                                            ? userConstants.current
                                            : '')
                                    }
                                    id={otpRdoBtn}
                                    desc={userConstants.changeSignInMethodOptions.otpSubText}
                                    radiotext={styles.radiotext}
                                    radiodescstyles={styles.radiodescstyles}
                                    radiostyles={styles.radiostyles}
                                    selected={selectedOption === otpRdoBtn}
                                    onClick={this.handleRadioClick}
                                />
                                {/* <WRadio
                                    value={userConstants.softTokenText + (softTokenRdoBtn === currentOption
                                        ? userConstants.current
                                        : '')}
                                    id={softTokenRdoBtn}
                                    desc={userConstants.changeSignInMethodOptions.softSubText}
                                    radiotext={styles.radiotext}
                                    radiodescstyles={styles.radiodescstyles}
                                    radiostyles={styles.radiostyles}
                                    selected={selectedOption === softTokenRdoBtn}
                                    onClick={this.handleRadioClick}
                                />

                                <WRadio
                                    value={userConstants.pushText + (pushNotificationRdoBtn === currentOption
                                        ? userConstants.current
                                        : '')}
                                    id={pushNotificationRdoBtn}
                                    desc={userConstants.changeSignInMethodOptions.pushSubText}
                                    radiotext={styles.radiotext}
                                    radiodescstyles={styles.radiodescstyles}
                                    radiostyles={styles.radiostyles}
                                    selected={selectedOption === pushNotificationRdoBtn}
                                    onClick={this.handleRadioClick}
                                /> */}
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.clickBack}
                    nextClick={this.clickNext}
                    disabled={toggleNextButton}
                    arialabelback={userConstants.changeSignInMethodOptions.arialabelback}
                />
            </div>
        );
    }
}

ChangeSignInMethodOptionsComponent.propTypes = {
    setJWTToken: PropTypes.func,
    getSignInMethod: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
};

ChangeSignInMethodOptionsComponent.defaultProps = {
    setJWTToken: () => {},
    getSignInMethod: () => {},
    loginState: {},
    history: [],
    location: {},
};

export default memo(ChangeSignInMethodOptionsComponent);
