import * as AccountActions from '../ReduxConstants/TransactionActivityConstants';

const initialState = {
    isPageLoading: false,
    pageNumber: 0,
    isMoreDataAvailable: true,
    activities: [],
    showMessage: false,
    message: '',
    nextPage: false,
};
const TransactionActivityReducer = (state = initialState, action) => {
    const pageSize = 9;

    const getFirstPageList = activities => {
        const sortedData = activities?.sort((a, b) => {
            return new Date(Date.parse(b.date)) - new Date(Date.parse(a.date));
        });

        return sortedData?.length > pageSize ? sortedData.slice(0, pageSize) : sortedData;
    };

    const loadMore = () => {
        const { accountActivities, activities } = state;
        const currentPage = parseInt(activities?.length / pageSize, 10);

        return accountActivities.slice(0, (currentPage + 1) * pageSize);
    };

    const sortByStatus = activities => {
        const pendeningStatus = activities?.filter(activity => activity?.status.toUpperCase() === 'PENDING');
        const restOfRecords = activities?.filter(activity => activity?.status.toUpperCase() !== 'PENDING');
        return pendeningStatus.concat(restOfRecords);
    };

    const createAccountActivitiesState = payload => {
        const sortedActivities = sortByStatus(payload);
        const activities = getFirstPageList(sortedActivities);

        return {
            ...state,
            accountActivities: sortedActivities,
            activities,
            nextPage: activities?.length !== payload?.length,
            isPageLoading: false,
            showMessage: false,
        };
    };

    const setErrorResponseState = () => {
        return {
            ...state,
            isPageLoading: false,
            showMessage: true,
            accountActivities: [],
            // activities: [],
            message: 'Oh snap! You got an error from backend! Please retry after sometime',
        };
    };

    const setLoaingImageState = () => {
        return {
            ...state,
            isPageLoading: true,
            showMessage: false,
        };
    };

    switch (action.type) {
        case AccountActions.GET_ACCOUNT_ACTIVITIES_ERROR: {
            return setErrorResponseState();
        }

        case AccountActions.CANCEL_ACTIVITY_TRANSACTION: {
            return setLoaingImageState();
        }
        case AccountActions.GET_ACCOUNT_ACTIVITIES: {
            return setLoaingImageState();
        }
        case AccountActions.GET_ACCOUNT_ACTIVITIES_SUCCESS: {
            return createAccountActivitiesState(action.payload);
        }

        case AccountActions.LOAD_MORE: {
            const activities = loadMore();
            const { accountActivities } = state;

            return {
                ...state,
                activities,
                nextPage: activities?.length !== accountActivities?.length,
            };
        }

        case AccountActions.CANCEL_ACTIVITY_TRANSACTION_SUCCESS: {
            // const { accountActivities } = state;
            // const activities = accountActivities;

            // return {
            //     ...createAccountActivitiesState(activities),
            //     showMessage: true,
            //     message: 'Your transaction has been canceled',
            // };
            const { accountActivities } = state;
            // const activities = accountActivities;
            const activities = accountActivities.map(activity => {
                if (action.payload.tranDesc.includes('EXCHANGE')) {
                    if (
                        activity.batchNumber === action.payload.batchNumber &&
                        activity.sequenceNumber === action.payload.sequenceNumber &&
                        activity.date === action.payload.date
                    ) {
                        const activityData = activity;
                        activityData.status = 'cancelled';
                        return activityData;
                    }
                } else if (activity.uuid === action.payload.uuid) {
                    const activityData = activity;
                    activityData.status = 'cancelled';
                    return activityData;
                }
                return activity;
            });
            return {
                ...createAccountActivitiesState(activities),
                showMessage: true,
                message: 'Your transaction has been canceled',
            };
        }

        case AccountActions.CANCEL_ACTIVITY_TRANSACTION_ERROR: {
            return setErrorResponseState();
        }

        default:
            return state;
    }
};

export default TransactionActivityReducer;
