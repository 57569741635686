import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const ARIA_DISABLED = 'aria-disabled';
const BOLD_TEXT = 'boldText';
export const StyledDiv = styled.div`
  display: block;
  position: relative;
  margin-left: ${props => props.mL}rem;
  margin-right: ${props => props.mR}rem;
  margin-top: ${props => props.mT}rem;
  margin-bottom: ${props => props.mB}rem;
  padding-left: 30px;
  border: thin transparent solid;
  text-align: left;
  font-family: benton-sans, sans-serif;
  font-weight: ${props => (props[`${ARIA_DISABLED}`] ? '400' : '500' )};
  font-weight: ${(props) => {
      if(props[`${ARIA_DISABLED}`]) {
          return '400'
      } 
      if(props[`${BOLD_TEXT}`]) {
          return '600'
      }
          return '500'
      }}
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #56565A;
  cursor: ${props => props[`${ARIA_DISABLED}`] ? 'default' : 'pointer'};
  pointer-events: ${props => props[`${ARIA_DISABLED}`] ? 'none' : 'initial'};
  &:before, &:after {
      display: block;
      position: absolute;
      border-radius: 50%;
      content: '';
  }
  &:before {
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid ${props => props[`${ARIA_DISABLED}`] ? '#E6E6E6': '#49494A' };
      opacity: 1;
  }
  &[aria-checked=true]::after {
      top: 5px;
      left: 5px;
      border: 5px solid #49494A;
      content: '';
  }
`;

const Radio = React.forwardRef((props, fwdRef) =>{
    const { label, selected, mL, mB, disabled, ...restProps } = props;
    return (
        <StyledDiv 
            ref={fwdRef}
            role="radio"
            aria-checked={selected}
            aria-disabled={disabled}
            mL={mL}
            mB={mB}
            {...restProps}
        >
        {label}
        </StyledDiv>
  )
});

Radio.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    mL: PropTypes.number,
    mB: PropTypes.number,
    disabled: PropTypes.bool,
};

Radio.defaultProps = {
    label: '',
    selected: false,
    mL: 0,
    mB: 0,
    disabled: false,
};

export default Radio;