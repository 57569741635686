/* eslint-disable class-methods-use-this */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { LiveAnnouncer, LiveMessenger } from 'react-aria-live';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { encrypt } from 'utils';
import WStepper from '../../components/WStepper';
import PasswordStrength from '../../components/PasswordStrength';
import conts from '../../components/constants';
import { ContainsUpperLower, ContainsSpecialChar } from '../../Utils';
import BackButton from '../../components/inputs/BackButton';
import analytics from '../../components/utils.analytics';

const assignObj = (obj) => obj;
class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: [
        { description: 'Be at least 8 characters in length', isValid: false },
        {
          description:
            'Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)',
          isValid: false,
        },
        {
          description: 'Have at least one numerical character (e.g. 0-9)',
          isValid: false,
        },
        {
          description:
            'Have at least one special character from this list: ^$*.[/]{}()?!@#%&,><:;|_~`',
          isValid: false,
        },
      ],
      password: null,
      toggle: false,
      isFormValid: false,
      error: '',
    };
    this.target = React.createRef();
  }

  componentDidMount() {
    const { history, accountInfo } = this.props;
  
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
    }
  }

  componentDidUpdate() {
    const { pwdValidate, history, resetPwdError } = this.props;
    if (pwdValidate === 'Success') {
      
      history.push({
        pathname: '/accountRegistration/Success',
        preReg: true,
        // message: { message: `<span>You successfully created your credentials. Your Victory Member Number is <strong>${vcmId}</strong><br/>Please note your member number and keep it securely. You will be required to provide this in case you forget your user id</span>` }
      })
      resetPwdError();
      window.scriptLoaded = false;
    } else if (pwdValidate === 'Failure') {
      document.getElementById('userPass').focus();
    }
  }

  getMessage() {
    const { rules } = this.state;
    const msg = rules.map(({ description }) => description).join('.');
    return msg.length > 0
      ? `Create Your Password. Format guidelines: ${msg}`
      : '';
  }

  getErrorMessage(first, second, third, fourth) {
    if (!first.isValid || !second.isValid || !third.isValid || !fourth.isValid) {
      return 'The Password does not follow the password policy';
    }
    return '';
  }

  getUnstatisfiedMessage(first, second, third, fourth) {
    let adaMessage = 'Passsword ';

      if (!first.isValid) {
      adaMessage += `${first.description}.`;
    }
      if (!second.isValid) {
      adaMessage += `${second.description}.`;
    }
      if (!third.isValid) {
      adaMessage += `${third.description}.`;
    }
      if (!fourth.isValid) {
      adaMessage += `${fourth.description}.`;
      }
    return adaMessage;
  }
  
  getOnChangeADAMessage(first, second, third, fourth) {
    let adaMessage = '';

    if (first.isValid && second.isValid && third.isValid && fourth.isValid) {
      adaMessage = 'All the required guidelines to create passsword are satisified';
    }
    return adaMessage;
  }

  guidanceComp = (rules) => (<div>
    <div className="rulesWrapper" id="passwordMsg" aria-describedby="userPass">
      <h2 className="rulesHeading">{conts.format}</h2>
      <ul className="rules" id="rulesMsg">
        {rules.map(({ description, isValid }, index) => (
          <li className="displayFlex" key={Number(index)}>
            <div className="ruleListIcon">
              <div
                className={isValid ? 'filledCircle' : 'circle'}
              >
                <div className="tick" />
              </div>
            </div>
            <div className="ruleListText">{description}</div>
            {isValid ? (
              <span className="sr-only">{conts.guideSatisfied}</span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  </div>);

  passwordComp = (errorText) => {
    const {
      rules, password, toggle
    } = this.state;

    const { apperror, resetPwdError } = this.props;
    return(<LiveAnnouncer>
      <LiveMessenger>
        {({ announcePolite }) => (
          <input
            id="userPass"
            className={errorText ? 'errorField' : ''}
            value={password}
            type={toggle ? 'text' : 'password'}
            minLength="8"
            maxLength="50"
            ref={this.target}
            required
            aria-required
            aria-describedby={
              errorText
                ? 'rulesMsg passwordError'
                : 'rulesMsg'
            }
            aria-invalid={!!errorText}
            autoComplete="off"
            onKeyPress={this.handleKeyPress}
            onFocus={assignObj(() => {
              setTimeout(() => {
                announcePolite('');
              }, 100);
            })}
            onBlur={assignObj(() => {
              if (
                !isEmpty(password)
                && password.length > 0
              ) {
                  const [
                    first,
                    second,
                    third,
                    fourth,
                  ] = rules;
                setTimeout(() => {
                  announcePolite(
                    this.getUnstatisfiedMessage(
                      first,
                      second,
                      third,
                      fourth,
                    ),
                  );
                }, 100);
              this.setState({ error: this.getErrorMessage(first, second, third, fourth) });
              } else {
              this.setState({ error: 'Password is mandatory. It cannot be left blank.' });
              }
            })}
            onChange={assignObj((e) => {
              if (!isEmpty(apperror)) {
                resetPwdError();
              }
              let [first, second, third, fourth] = rules;
              const text = e.target.value;
              first = {
                ...first,
                isValid: text.length >= 8,
              };
              second = {
                ...second,
                isValid: ContainsUpperLower(text),
              };
              third = {
                ...third,
                isValid: /[0-9]/.test(text),
              };
              fourth = {
                ...fourth,
                isValid: ContainsSpecialChar(text),
              };

              setTimeout(() => {
                announcePolite(
                  this.getOnChangeADAMessage(
                    first,
                    second,
                    third,
                    fourth,
                  ),
                );
              }, 100);

              this.setState({
                rules: [first, second, third, fourth],
                password: text,
                isFormValid:
                  first.isValid
                  && second.isValid
                  && third.isValid
                  && fourth.isValid,
              });
            })}
          />
        )}
      </LiveMessenger>
    </LiveAnnouncer>)
  };

  handleKeyPress = (e) => {
    const key = String.fromCharCode(
      !e.charCode ? e.which : e.charCode,
    );
    if (
      !ContainsSpecialChar(key)
      && !/[A-Za-z0-9]/.test(key)
    ) {
      e.preventDefault();
      return false;
    }
    return true;
  }



  handleButtonClick() {
    analytics.triggerClickTrackEvent(
      'Preregister_createpassword_Enter_A_New_Password',
    );
  }

  handleBackButtonClick() {
    const { resetPwdError } = this.props;
    analytics.triggerClickTrackEvent(
      'Preregister_createpassword_Back_to_User_ID',
    );
    resetPwdError();
  }

  verifyPassword() {
    const {
      verifyPwd,
      accountInfo,
      userId,
      eSignInitial,
      primaryPhone,
      eSignDate,
      email,
      primaryPhoneCountryCode,
      isMobilePrimary,
      secondaryPhone,
      secondaryPhoneCountryCode,
      isMobileSecondary,
    } = this.props;

    const { password } = this.state;
    (async () => {
      const newPassEncrypt = await encrypt(password, accountInfo);
      verifyPwd({
        ...accountInfo,
        onlineId: userId.toLowerCase(),
        password: newPassEncrypt,
        emailAddress: email,
        eSignInitial,
        eSignDate,
        primaryPhone,
        primaryPhoneCountryCode: !isEmpty(primaryPhoneCountryCode)
          ? primaryPhoneCountryCode
          : '1',
        isMobilePrimary,
        secondaryPhone,
        secondaryPhoneCountryCode,
        isMobileSecondary,
      });
    })();
  }

  render() {
    const {
      rules, password, toggle, isFormValid, error,
    } = this.state;

    const { apperror } = this.props;

    const errorText = error || apperror;
    if (!isEmpty(errorText)) {
      const appErrorStr = errorText.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_createpassword_error_${appErrorStr}`,
      );
    }

    return (
      <div className="milestone">
        <div className="pad contentWrapper">
          <Container fluid>
            <WStepper currentPage={4} pages={conts.pages} />
            <Row className="headingRow">
              <Col className="descWrapper" xs={12} sm={6}>
                <h1 className="descWrapperHeading">{conts.createPassword}</h1>
              </Col>
            </Row>
            <Row>
              <Col className="descWrapper flexColBackOpt" xs={12} sm={6}>
                {this.guidanceComp(rules)}
              </Col>
              <Col xs={12} sm={6} className="RHSWrapper">
                <Container style={assignObj({ margin: 0 })}>
                  <Row>
                    <Col className="noPad">
                      <label htmlFor="userPass" className="inputLabel">
                        {conts.password}
                      </label>
                      <form
                        onSubmit={assignObj((e) => {
                          e.preventDefault();
                          this.verifyPassword();
                        })}
                      >
                        <div
                          style={assignObj({
                            position: 'relative',
                            display: 'inline-block',
                          })}
                          className="createPassword"
                        >
                        {this.passwordComp(errorText)}
                          <PasswordStrength len={password} />
                          {errorText && (
                            <p
                              className="errorText"
                              aria-live="polite"
                              aria-atomic="true"
                              id="passwordError"
                            >
                              {errorText}
                            </p>
                          )}
                          <button
                            type="button"
                            tabIndex={0}
                            aria-label={
                              !toggle ? 'Show password' : 'Hide password'
                            }
                            style={assignObj({
                              position: 'absolute',
                              top: 12,
                              right: 0,
                            })}
                            onMouseDown={assignObj(() => this.setState({ toggle: !toggle }))}
                            onKeyDown={assignObj((e) => (e.keyCode === 32)
                              && this.setState({ toggle: !toggle }))}
                          >
                            {toggle ? (
                              <FaEye size={20} focusable="false" />
                            ) : (
                              <FaEyeSlash size={20} focusable="false" />
                            )}
                          </button>
                        </div>

                        <p />
                        <div className="btnWrapper">
                          {/* <BackButton
                            link="/enterUserId"
                            backTo="Back to User ID"
                            className="d-block d-sm-none passwordBckBtn"
                            onClick={this.handleBackButtonClick}
                          /> */}
                          <button
                            className="vcmbtn sameWidthButton"
                            type="submit"
                            disabled={!isFormValid}
                            onClick={this.handleButtonClick}
                          >
                            <span className="vcmBtnText">
                              {!isFormValid ? conts.passwordBtn : 'Continue'}
                            </span>
                          </button>
                        </div>
                      </form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className="RHSWrapper">
                <BackButton
                  link="/accountRegistration/enterUserId"
                  backTo="Back to User ID"
                  className="d-none d-sm-block passwordBckBtn"
                  onClick={this.handleBackButtonClick}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

Password.propTypes = {
  verifyPwd: PropTypes.func,
  accountInfo: PropTypes.instanceOf(Object),
  userId: PropTypes.string,
  apperror: PropTypes.string,
  pwdValidate: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  eSignInitial: PropTypes.string,
  eSignDate: PropTypes.string,
  email: PropTypes.string,
  primaryPhone: PropTypes.string,
  primaryPhoneCountryCode: PropTypes.string,
  isMobilePrimary: PropTypes.bool,
  secondaryPhone: PropTypes.string,
  secondaryPhoneCountryCode: PropTypes.string,
  isMobileSecondary: PropTypes.bool,
  resetPwdError: PropTypes.func,
};

Password.defaultProps = {
  verifyPwd: () => {},
  accountInfo: {},
  userId: '',
  apperror: '',
  pwdValidate: '',
  history: {},
  eSignInitial: '',
  eSignDate: '',
  email: '',
  primaryPhone: '',
  primaryPhoneCountryCode: '',
  isMobilePrimary: false,
  secondaryPhone: '',
  secondaryPhoneCountryCode: '',
  isMobileSecondary: false,
  resetPwdError: () => {},
};

export default Password;
