/**
 * edit event Component- Transition event in verify
 *******************************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component handles the edit event
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */

export default function handleEvent(cardId) {
    let item = cardId;
    if (cardId.indexOf('benInformation') > -1) {
        item = 'benInfo';
    }
    const personalInfoPath = '/personalInfo';
    switch (item) {
        case 'accountInfo':
            return { edit: true, editTarget: '/accountType' };
        case 'personalInfo':
        case 'jointPersonalInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'contactInfo':
        case 'jointContactInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'phoneInfo':
        case 'jointPhoneInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'emailInfo':
        case 'jointEmailInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'empInfo':
        case 'jointEmpInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'financialInfo':
        case 'jointFinancialInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'militaryInfo':
        case 'jointMilitaryInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'benInfo':
            return { edit: true, editTarget: personalInfoPath };
        case 'selectFunds':
            return { edit: true, editTarget: '/fundSelection' };
        case 'fundingInfo':
            return { edit: true, editTarget: '/investmentSelection' };
        case 'accountPreferences':
            return { edit: true, editTarget: '/preferences' };
        case 'iraContributionInfo':
            return { edit: true, editTarget: '/investmentSelection' };
        default:
            return {};
    }
}
