import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const C = {
    WCardOverlay: 'WCardOverlay',
};
const assignObj = obj => obj;

const WCardOverlay = props => {
    const { children, showCorner, myStyles } = props;
    return (
        <div className={C.WCardOverlay} style={assignObj({ ...myStyles, ...styles.subHeader })} data-test="cardoverlay">
            {showCorner && <div className = "cornerPosition" style={styles.corner} />}
            {children}
        </div>
    );
};

WCardOverlay.propTypes = {
    children: PropTypes.instanceOf(Object),
    showCorner: PropTypes.bool,
    myStyles: PropTypes.instanceOf(Object),
    // myClassNames: PropTypes.string,
};

WCardOverlay.defaultProps = {
    children: {},
    showCorner: false,
    myStyles: {},
    // myClassNames: '',
};

export default WCardOverlay;
