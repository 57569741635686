import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import WInput from '../WInput/WInput';
import '../CommonStyles';

const StyledCol = styled(Col)`
    padding: 0;
    &&.uspsSuggession .card {
        border: 1px solid #ffffff;
        // margin-left: 40px;
        // width: calc(100% - 40px);
        max-width: 340px;
        margin-bottom: 0;
        float: right;
        :hover {
            outline-color: #d2d2d2;
        }
        .card-body {
            padding: 0;
            text-align: center;
        }
    }
    &&.addFieldsWrapper .form-group.row {
        margin-bottom: 30px;
    }
`;

const assignObj = obj => {
    return obj;
};

const horizontalStyle = {
    display: 'flex',
    flexDirection: 'row',
};
class Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryAddress: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState, checkDeliveryAddress } = nextProps;
        const { deliveryAddress } = prevState;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                deliveryAddress: savedState.deliveryAddress !== '' ? savedState.deliveryAddress : checkDeliveryAddress,
            };
        }
        return {
            ...prevState,
            deliveryAddress: deliveryAddress !== '' ? deliveryAddress : checkDeliveryAddress,
        };
    }

    render() {
        const { labelAdd1, optional, labelsm, wrapperGutter } = this.props;
        const { deliveryAddress } = this.state;

        const inlineStyles = {
            deliveryAddressTextArea: { height: 160, borderRadius: 0, background: '#fff' },
        };

        return (
            <div className="addressFields">
                <Row style={horizontalStyle} noGutters={wrapperGutter}>
                    <StyledCol xs={12} md={8} lg={6} className="addFieldsWrapper">
                        <WInput
                            label={labelAdd1}
                            placeholder="<Example: Apt 5, Box 30…>"
                            type="area"
                            name={labelAdd1}
                            value={deliveryAddress}
                            labelsm={labelsm}
                            valuesm={5}
                            maxlength={500}
                            //  optional="true"
                            aria-required={false}
                            noGutters
                            readOnly={assignObj(optional !== 'true')}
                            as="textarea"
                            inputfieldstyle={inlineStyles.deliveryAddressTextArea}
                        />
                    </StyledCol>
                </Row>
            </div>
        );
    }
}

Address.propTypes = {
    labelAdd1: PropTypes.string,
    savedState: PropTypes.instanceOf(Object),
    optional: PropTypes.string,
    labelsm: PropTypes.number,
    wrapperGutter: PropTypes.bool,
    checkDeliveryAddress: PropTypes.string,
};

Address.defaultProps = {
    labelAdd1: 'Address',
    savedState: {},
    optional: 'false',
    labelsm: 5,
    wrapperGutter: false,
    checkDeliveryAddress: '',
};

export default Address;
