import { connect } from 'react-redux';
import ManageBeneficiariesAccounts from './AccountsComponent';
import { 
    getBeneficiariesMasterRegs,
    localStateManagementActions, 
    analytics 
} from '../../../shared/Actions';
import { manageMasterRegBeneficiariesMultiples } from './mocks';


export const getBeneficiariesMock = () => dispatch => {
    dispatch({
        type: 'GET_BENEFICIARIES_MASTER_REGS_SUCCESS',
        payload: {masterRegs:  manageMasterRegBeneficiariesMultiples.result}
      });
    dispatch(analytics.linkResponseSuccess());
};

const mapStateToProps = (state /* , props */) => ({
    initialState: state.initialAppData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
    manageBeneficiariesMasterRegsData: state.manageBeneficiariesMasterRegsData,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    getBeneficiariesMasterRegs: getBeneficiariesMasterRegs,
    analyzeClickEvent: analytics.analyzeClickEvent,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ManageBeneficiariesAccounts);
