/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the funding  and investment info details card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

const mask = value => (value ? value.replace(/\d(?=\d{4})/g, 'X') : '');

const accountDetailsCard = (data, fundingInfoCard) => {
    const { otherBankState } = data.onlineBankPaymentDetails || {};
    const { bankAccountDetails = [] } = otherBankState || {};
    const { staticfield } = Consts;
    let fundingInfoCardFieldsList = [];
    if (data.selectedMethod === 'online') {
        const { fundSource } = data.OpenAccPageFour.investmentInfo.selectedFundSource;
        const bankAccountTypeField = {
            uniqueKey: 'bankAccountType',
            key: ' Type of Account',
            value: fundSource.accountType || Consts.None,
            type: staticfield,
        };
        fundingInfoCardFieldsList = bankAccountDetails.map(item => {
            const value = item.name === 'accountNumber' ? mask(item.value) : item.value;
            return {
                uniqueKey: item.name || 'name',
                key: item.label || 'label',
                value: value || Consts.None,
                type: staticfield,
            };
        });
        fundingInfoCard.fields.push(...fundingInfoCardFieldsList, bankAccountTypeField);
    }
    return fundingInfoCard;
};

const fundDetails = data => {
    const { selectedFundSource } = data.OpenAccPageFour.investmentInfo;

    const { fundType } = selectedFundSource.fundSource;
    let fundTypeName = '';
    switch (fundType) {
        case 'pers_check':
            fundTypeName = 'Check';
            break;
        case 'wire_transfer':
            fundTypeName = 'Wire-transfer';
            break;
        default:
            fundTypeName = 'Bank';
    }
    const fundSourceMethod = selectedFundSource.fundSource.bankAccountNumber || fundTypeName;
    const fundMethod =
        selectedFundSource.paymentMethod === 'online'
            ? selectedFundSource.fundSource.institutionName
            : 'Offline Method';
    return { fundMethod, fundSourceMethod };
};

const formatInputValues = val => {
    // return val ? val.toString().replace(/[a-zA-Z,$.]/g, '') : 0;
    return val ? val.toString().replace(/[a-zA-Z,$]/g, '') : 0;
};

export default function generateFundingInfoCard(masterLookupStateData, data) {
    const allFundCards = [];
    let fundingInfoCard = {};
    let selectFundsCard = {};
    const { initInvestment, staticfield } = Consts;

    if (data.selectedFundsList) {
        const fieldsList = data.selectedFundsList.map(item => {
            const heading = [
                {
                    uniqueKey: item.fundNumber || 'fundNumber',
                    key: item.fundName || 'fundName',
                    value: item.fundName || Consts.None,
                    type: 'heading',
                },
            ];
            const initialInvestment = [
                {
                    uniqueKey: item.fundNumber || 'fundNumber',
                    key: initInvestment,
                    value: `$${Number(formatInputValues(item.initialInvest)).toFixed(2)}` || Consts.None,
                    type: staticfield,
                },
            ];

            const monthlyInvestment = [
                {
                    uniqueKey: item.fundNumber || 'fundNumber',
                    key: 'Monthly Investment',
                    value:
                        item.fundingOption !== 'init'
                            ? `$${Number(formatInputValues(item.monthlyInvest)).toFixed(2)}` || Consts.None
                            : `$0.00`,
                    type: staticfield,
                },
            ];
            const startDate = [
                {
                    uniqueKey: item.startDate || 'StartDate',
                    key: item.fundingOption !== 'init' ? 'Start Date' : '',
                    value: item.fundingOption !== 'init' ? `${item.startDate}` || Consts.None : '',
                    type: staticfield,
                },
            ];

            return [...heading, ...initialInvestment, ...monthlyInvestment, ...startDate];
        });
        let finalList = [];
        for (let i = 0; i < fieldsList.length; i += 1) {
            finalList = [...finalList, ...fieldsList[i.toString()]];
        }
        selectFundsCard = Consts.selectFundsCard;
        selectFundsCard.fields = finalList;
        allFundCards.push(selectFundsCard);
        const { fundMethod = '', fundSourceMethod = '' } = fundDetails(data);
        fundingInfoCard = JSON.parse(JSON.stringify(Consts.fundingInfoCard));
        fundingInfoCard.fields[0].value = fundMethod; //  data.selectedMethod || '';
        fundingInfoCard.fields[1].value = fundSourceMethod; // data.selectedFundSrcMethod || '';
        fundingInfoCard.fields[2].value =
            `$${parseFloat(
                parseFloat(data.totalInitialInvestment) + (parseFloat(data.totalMonthlyInvestment) || 0),
            ).toFixed(2)}` || Consts.None;
        accountDetailsCard(data, fundingInfoCard);
        allFundCards.push(fundingInfoCard);
    }
    return allFundCards;
}
