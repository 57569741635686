import Card from 'react-bootstrap/Card';
import DownloadFile from '../DownloadFile/DownloadFile';
import pdfIcon from '../../assets/icon_pdf.png';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { toLocaleUTCDateString } from '../../utils/utils';
import viewIcon from '../../assets/Icon_View.svg';

// https://dev.memberapi.vcm.com/documentcenter/document-api/search-document/search?category=accessform
// https://dev.memberapi.vcm.com/documentcenter/document-api/search-document/search?category=opt_ret_program

const DATA_UNREAD = 'data-unread';

const StyledCardTwo = styled(Card)`
    margin-top: 2.51875rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0px ${props => (props[DATA_UNREAD.toString()] ? '2px #486D90' : '1px #D2D2D2')};
    && {
        border: none;
        border-radius: 0;
    }
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props[DATA_UNREAD.toString()] ? '' : '35px solid #486D90')};
        position: absolute;
        top: 0;
        left: 0;
    }
    @media (max-width: 767px) {
        &::before {
            border-bottom: 20px solid transparent;
            border-left: ${props => (props[DATA_UNREAD.toString()] ? '' : '20px solid #486D90')};
        }
    }
`;
const AccountCardTopSection = styled.div`
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        color: #004a98;
        padding: 1.625rem 2.5rem 1.625rem 1.9375rem;
        flex-direction: row;
        padding-left: ${props => (props.unread ? '1rem' : '1.9375rem')};
    }
    border-bottom: 0.0625rem solid #d2d2d2;
    text-align: left;
    font: 800 1.125rem/2.8125rem yorkten-slab-normal, sans-serif;
    flex-direction: column;
    padding: 30px 20px;
    @media (max-width: 767px) {
        padding-left: 0px;
        & > div {
            padding-left: 20px;
        }
        & > h2 {
            padding-left: 8px;
        }
    }
`;

const readUnreadIndicator = css`
    &::before {
        width: 8px;
        height: 8px;
        content: '';
        background: #004a98 0% 0% no-repeat padding-box;
        margin-right: 10px;
        border-radius: 50%;
        padding-left: 4px;
        padding-right: 4px;
    }
    @media (max-width: 767px) {
        &::before {
            margin-right: 5px;
        }
    }
`;

const Flex = styled.h2`
    color: #004a98;
    font: 800 1.125rem/1.6rem yorkten-slab-normal, sans-serif;
    display: flex;
    align-items: center;
    word-break: break-all;
    ${props => (props.unread ? readUnreadIndicator : '')};
`;
const FlexDiv = styled.div`
    padding: 30px 20px;
    flex-direction: column;
    > div:last-child {
        margin-bottom: 0px;
    }
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        padding: 1.625rem 2.5rem 1.625rem 1.9375rem;
    }
`;
const VerticalSeperator = styled.div`
    border-left: 0.0625rem solid rgba(0, 0, 0, 0.1);
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
`;
const AccountCardBottomSection = styled.div`
    width: 100%;
    margin-bottom: 25px;
    @media (min-width: 768px) {
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
        width: 30%;
        margin-bottom: 0;
    }
`;
const AccountCardHeadingSpan = styled.div`
    margin-bottom: 0.625rem;
    text-align: left;
    font: 600 1rem/1.375rem benton-sans;
    color: #49494a;
`;
const AccountCardValueSpan = styled.div`
    text-align: left;
    font: 500 1rem/1.25rem benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const getUnreadStatus = (readIndicator, alternateDocumentLink) => {
    return !alternateDocumentLink ? readIndicator !== 'true' : false;
};

const getReadUnreadText = unread => {
    return unread ? 'Unread' : 'Read';
};

class ViewDocumentCard extends Component {
    formatDateForSR = dateStr => {
        const [day, month, year] = dateStr.split('/');
        return `${year}/${month}/${day}`;
    };

    render() {
        const {
            documentTitle,
            fileName,
            account,
            documentDate,
            category,
            status,
            nodeId,
            alternateDocumentId,
            documentSource,
            alternateDocumentLink,
            readIndicator,
        } = this.props;
        const { AccountNameText, AccountNameValue } = account;
        const { DocumentDateText, DocumentDateValue } = documentDate;
        const { CategoryText, CategoryValue } = category;
        const { StatusText, StatusValue } = status;
        let dateStamp = '-';

        if (DocumentDateValue) {
            dateStamp = toLocaleUTCDateString(DocumentDateValue);
        }

        let isLink = false;
        const isLinkTitle = ['Victory Capital Terms of Service'];
        const isNotPDF =
            alternateDocumentLink
                .split('.')
                .pop()
                .toUpperCase() !== 'PDF';
        if (alternateDocumentLink && isNotPDF && CategoryValue.toUpperCase() === 'GENERAL') {
            isLink = isLinkTitle.find(key => (key.toUpperCase() === documentTitle.toUpperCase()) !== undefined);
        }

        const saveAs = `${fileName}`;

        const AcctNameValue = AccountNameValue === '_' ? 'Not Available' : AccountNameValue;

        const unread = getUnreadStatus(readIndicator, alternateDocumentLink);

        return (
            <StyledCardTwo data-unread={unread}>
                <AccountCardTopSection unread={unread}>
                    <Flex unread={unread} className="mb-md-0 mb-1">
                        {documentTitle}
                    </Flex>
                    <span className="sr-only">{getReadUnreadText(unread)}</span>
                    <div className="ml-md-4 ml-0">
                        <DownloadFile
                            alternateDocumentId={alternateDocumentId}
                            downloadUrl={alternateDocumentLink}
                            nodeId={nodeId}
                            documentSource={documentSource}
                            docCategory={CategoryValue}
                            iconSrc={isLink ? viewIcon : pdfIcon}
                            text={isLink ? 'View' : 'Download'}
                            saveAs={saveAs}
                            isDocumentCenter
                            optionalScreenReaderMsg={documentTitle}
                            unread={unread}
                            opens={isLink ? 'link' : 'PDF'}
                        />
                    </div>
                </AccountCardTopSection>
                <FlexDiv>
                    <AccountCardBottomSection>
                        <AccountCardHeadingSpan>{DocumentDateText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan aria-label={this.formatDateForSR(dateStamp)}>
                            {dateStamp}
                        </AccountCardValueSpan>
                    </AccountCardBottomSection>
                    <VerticalSeperator className="d-none d-md-block" />
                    <AccountCardBottomSection>
                        <AccountCardHeadingSpan>{CategoryText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{CategoryValue.replace(/_/gi, ' ')}</AccountCardValueSpan>
                    </AccountCardBottomSection>
                    {StatusValue.length > 0 && (
                        <>
                            <VerticalSeperator className="d-none d-md-block" />
                            <AccountCardBottomSection>
                                <AccountCardHeadingSpan>{StatusText}</AccountCardHeadingSpan>
                                <AccountCardValueSpan>{StatusValue}</AccountCardValueSpan>
                            </AccountCardBottomSection>
                        </>
                    )}
                    <VerticalSeperator className="d-none d-md-block" />
                    <AccountCardBottomSection>
                        <AccountCardHeadingSpan>{AccountNameText}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>
                            <span aria-hidden="true">{AccountNameValue}</span>
                            <span className="sr-only">{AcctNameValue}</span>
                        </AccountCardValueSpan>
                    </AccountCardBottomSection>
                </FlexDiv>
            </StyledCardTwo>
        );
    }
}

ViewDocumentCard.defaultProps = {
    status: {
        StatusText: '',
        StatusValue: '',
    },
    documentSource: '',
    alternateDocumentId: '',
    alternateDocumentLink: '',
    readIndicator: '',
};

ViewDocumentCard.propTypes = {
    nodeId: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    documentTitle: PropTypes.string.isRequired,
    documentSource: PropTypes.string,
    alternateDocumentLink: PropTypes.string,
    alternateDocumentId: PropTypes.string,
    account: PropTypes.shape({
        AccountNameText: PropTypes.string,
        AccountNameValue: PropTypes.string,
    }).isRequired,
    documentDate: PropTypes.shape({
        DocumentDateText: PropTypes.string,
        DocumentDateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    category: PropTypes.shape({
        CategoryText: PropTypes.string,
        CategoryValue: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
        StatusText: PropTypes.string,
        StatusValue: PropTypes.string,
    }),
    readIndicator: PropTypes.string,
};

export default ViewDocumentCard;
