
const accountJson={
"general":[
   {
        accountName:'Account General 1',
        accountNumber:'xxxx-xxxx-9123',
        currentValue:'$ 6000',
        holding:'$ 7000',
        automaticInvestmentPlan:'Yes'
    },
    {
        accountName:'Account General 2',
        accountNumber:'xxxx-xxxx-1388',
        currentValue:'$ 4500',
        holding:'$ 9000',
        automaticInvestmentPlan:'Yes'
    }
],
"ira":[
    {
         accountName:'Account IRA 1',
         accountNumber:'xxxx-xxxx-5546',
         currentValue:'$ 2000',
         holding:'$ 1000',
         automaticInvestmentPlan:'No'
     },
     {
         accountName:'Account IRA 2',
         accountNumber:'xxxx-xxxx-2461',
         currentValue:'$ 2200',
         holding:'$ 7000',
         automaticInvestmentPlan:'No'
     }
 ],
 "utma":[
    {
         accountName:'Account UTMA 1',
         accountNumber:'xxxx-xxxx-1134',
         currentValue:'$ 200',
         holding:'$ 100',
         automaticInvestmentPlan:'No'
     },
     {
         accountName:'Account UTMA 2',
         accountNumber:'xxxx-xxxx-7092',
         currentValue:'$ 220',
         holding:'$ 700',
         automaticInvestmentPlan:'No'
     }
 ]};

function initialReducer(state = accountJson, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default initialReducer;