import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import Styles from './Styles';
import { WIcon, DownloadFile } from '../../../common';
import ACCESS_FORMS_CONSTANTS from './Constants';
import { removeFileExtension } from '../../../shared/Helpers/Utils';

import pdfIcon from '../../../assets/icon_pdf.png';
import iconOffer from '../../../assets/icon_offers.png';

const FormList = props => {
    const { accessForms } = props;
    const { forms } = accessForms;
    const fileUploadIconStyle = { order: 2, width: 20, height: 20, cursor: 'pointer', marginLeft: 5 };
    const inlineStyle = {
        rating: {
            width: 14,
            height: 14,
        },
        ratingCol: { span: 1, offset: 1 },
        downloadFile: {
            fontWeight: 400,
            fontFamily: 'Benton Sans',
            fontSize: 16,
            textAlign: 'left',
            marginTop: 0,
        },
    };

    const getKey = (item, index) => {
        return `${item}-${index}`;
    };

    return (
        <Styles.Forms data-testid="form-list">
            {forms.length === 0 && <Styles.NoRecords>{ACCESS_FORMS_CONSTANTS.NO_RECORDS_FOUND}</Styles.NoRecords>}
            {forms.map((form, index) => {
                return (
                    <Styles.FormItem
                        data-testid={getKey('data-test-id-form', index)}
                        key={getKey('form-key', index)}
                        className="form-row"
                    >
                        <Col sm={2}>{form.formid}</Col>
                        <Col sm={3}>{form.docCategory.replace('_',' ')}</Col>
                        <Col sm={5}>
                            <Styles.LinkIconDiv>
                                <DownloadFile
                                    nodeId={form.alternateDocumentId || form.nodeId}
                                    documentSource={form.documentSource}
                                    saveAs={form.fileName}
                                    linkStyle={inlineStyle.downloadFile}
                                    text={removeFileExtension(form.title ? form.title : form.fileName)}
                                    downloadUrl={form.alternateDocumentLink}
                                    iconSrc={pdfIcon}
                                    iconStyle={fileUploadIconStyle}
                                />
                                {/* <WIcon src={pdfIcon} /> */}
                            </Styles.LinkIconDiv>
                            <p>{form.fileDescription}</p>
                        </Col>
                        <Styles.Rating sm={inlineStyle.ratingCol}>
                            {form.tag && <WIcon src={iconOffer} fontawesomestyle={inlineStyle.rating} />}
                            <small>{form.tag}</small>
                        </Styles.Rating>
                    </Styles.FormItem>
                );
            })}
        </Styles.Forms>
    );
};

FormList.propTypes = {
    accessForms: PropTypes.instanceOf(Object),
};

FormList.defaultProps = {
    accessForms: {},
};

export default FormList;
