import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {       
    "accountType": "Traditional IRA",               
    "accName":"Sample Content",
    "accNumber":"7667-3345-1111",                  
    "receiveInvestmentAcc":"How would you like to receive documents for your Investment account?",
    "preferences":[
        {
            "preferenceType": "Investment Statements and Inserts",     
            "investmentStatements":[
                { "key": "online", "name": "Online", "selected":true },
                { "key": "paper", "name": "Paper", "selected":false }
            ]
        },
        {
            "preferenceType": "Investment Confirmations and Associated Prospectuses",     
            "investmentStatements":[
                { "key": "online", "name": "Online", "selected":false },
                { "key": "paper", "name": "Paper", "selected":true }
            ]
        },
        {
            "preferenceType": "Investment Shareholder Reports (includes prospectus other documents)",     
            "investmentStatements":[
                { "key": "online", "name": "Online", "selected":true },
                { "key": "paper", "name": "Paper", "selected":false }
            ]
        },
        {
            "preferenceType": "Investment Eligible Correspondence",     
            "investmentStatements":[
                { "key": "online", "name": "Online", "selected":false },
                { "key": "paper", "name": "Paper", "selected":true }
            ]
        }                   
    ],
    "receiveTaxDocuments":"How would you like to receive tax documents?",
    "taxDocuments":[
        {
            "documentType": "Documents for Tax Accounts?",     
            "documentStatements":[
                { "key": "online", "name": "Online", "selected":true },
                { "key": "paper", "name": "Paper", "selected":false }
            ]
        },
        {
            "documentType": "Would you like all of your other documents from VCM Delivered the same way?",     
            "documentStatements":[
                { "key": "yes", "name": "Yes", "selected":false },
                { "key": "no", "name": "No", "selected":true }
            ]
        }
    ]
};

function accMessagingDocumentsReducer (state = initialState, action){
    // debugger
      switch(action.type){
          case ActionTypes.LOCAL_STATE: {
              return {
                  ...state,
                  ...action,
              };
          }        
          default:
              return state;
      }
  } 

export default accMessagingDocumentsReducer;