import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ApplicationList = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 60px;
`;

const Heading = styled.h3`
    margin-top: 60px;
`;

class SaveApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savedApplications: [],
        };
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accOpeningData } = nextProps;
        if (accOpeningData.savedApplications && accOpeningData.savedApplications.length > 0) {
            return {
                savedApplications: accOpeningData.savedApplications,
            };
        }
        return {};
    }

    componentDidMount() {
        const { getSavedApplications } = this.props;
        getSavedApplications({ token: localStorage.getItem('token') });
    }

    render() {
        const { savedApplications } = this.state;
        return (
            <div className="container">
                <Heading>Saved Applications</Heading>
                {savedApplications.length > 0 &&
                    savedApplications.map(application => (
                        <ApplicationList>
                            <Link
                                to={{
                                    pathname: '/accountType',
                                    state: {
                                        uuid: application.uuid,
                                    },
                                }}
                            >
                                {application.accountType}
                            </Link>
                        </ApplicationList>
                    ))}
            </div>
        );
    }
}

SaveApplication.propTypes = {
    accOpeningData: PropTypes.instanceOf(Object),
    getSavedApplications: PropTypes.func,
};

SaveApplication.defaultProps = {
    accOpeningData: {},
    getSavedApplications: () => {},
};

export default SaveApplication;
