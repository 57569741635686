import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import envConfig from 'env/EnvConfig';

const captchaSiteKey = envConfig.RECAPTCHA_SITE_KEY;
//  const captchaSiteKey = "6LdX_QAVAAAAAExHiNdyqeX5XLjKS9s7KaElp84i";

class Captcha extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const { onCaptchaChange, size, onCaptchaErrored, onCaptchaExpired } = this.props;
        return (
            <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={onCaptchaChange}
                size={size}
                className="captchaCont"
                onExpired={onCaptchaExpired}
                onErrored={onCaptchaErrored}
            />
        );
    }
}

Captcha.propTypes = {
    onCaptchaChange: PropTypes.func,
    onCaptchaErrored: PropTypes.func,
    onCaptchaExpired: PropTypes.func,
    size: PropTypes.string,
};

Captcha.defaultProps = {
    onCaptchaChange: () => {},
    onCaptchaErrored: () => {},
    onCaptchaExpired: () => {},
    size: "normal"
};

export default Captcha;
