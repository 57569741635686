/**
 * Address Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to provide address
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [28/03/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedReason added VD styles
 */

 import accMgmtConst from '../AccountManagementConstants';
 import checkValidity from '../../../utils/checkValidity';
 import CONST from './consts';
 import MediaQuery from 'react-responsive';
 import PropTypes from 'prop-types';
 import React, { Component } from 'react';
 import { Row, Col, Alert } from 'react-bootstrap';
 import Rules from '../PersonalInfo/Rules';
 import styled from 'styled-components';
 import styles from '../PersonalInfo/styles';
 import { WInput, WCard, WRadio, ConfirmModal } from '../../../common';
 import '../../../common/CommonStyles';
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts';
import { getDataFromORCondition } from 'utils/utils';
import { checkORConditionWithParameter, getValidValueOf,checkSingleEqualityAndReturnValue } from 'commonHelper/CommonHelperFunctions';
 
 
 const StyledPara = styled.p`
     margin-bottom: 0;
     text-align: left;
     font-family: benton-sans, sans-serif;
     font-weight: 600;
     font-style: normal;
     font-size: 18px;
     line-height: 22px;
     letter-spacing: 0;
     color: #49494a;
     opacity: 1;
 `;
 
 const StyledAlert = styled(Alert)`
     padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
     background: #fcf1f2 0% 0% no-repeat padding-box;
     box-shadow: 0px 0px 25px #000029;
     border: 2px solid #eea7ad;
     opacity: 1;
     border-radius: inherit;
     margin-top: 2.2rem;
     margin-bottom: 3.75rem;
 `;
 
 const AlertWrapper = styled(Row)`
     div[class^='col'] {
         padding: 0;
     }
 `;
 

 
 class Address extends Component {
     constructor(props) {
         super(props);
         this.state = {
             lineOne: '',
             lineTwo: '',
             zipCode: '',
             city: '',
             state: '',
             errorMsg: {
                 lineOne: '',
                 lineTwo: '',
                 zipCode: '',
                 cityName: '',
                 stateName: '',
             },
             mailTypeRadio: '',
             stateApiCalled: false,
             displayUSPSSuggestion: false,
             selectedUspsAddress: false,
             formatApiCalled: false,
             masterDataLoaderForMailRadio: false,
             verifyingAddress: false,
             ShowModal:false,
         };
         this.isError = false;
     }
 
     static getDerivedStateFromProps(props, prevState) {
         const { addressFormatData, savedState } = props;
         const { formatApiCalled, errorMsg } = prevState;
         let { alertMsg } = prevState;
 
         const { isSuccess, GET_ADDRESSFORMAT = {}, addressFormatSuccess, isError } = addressFormatData;
 
         if (isError && formatApiCalled) {
             const { description = '' } = GET_ADDRESSFORMAT;
             // errorMsg.lineTwo = description;
             alertMsg = description || CONST.ADDRESS_VALID_ERROR;
             return {
                 addressValid: false,
                 showAlert: true,
                 alertMsg,
                 formatApiCalled: false,
                 verifyingAddress: false,
             };
         }
         const { masterLookupStateData } = props;
         const { address_type: mail } = getDataFromORCondition(masterLookupStateData,{}) ;
         let { value: mailingAddr } = getDataFromORCondition(mail,{});
         mailingAddr = mailingAddr || [];
         if (isSuccess && formatApiCalled && addressFormatSuccess) {
             const { addressLine1 = '', addressLine2 = '', zip, city, state } = GET_ADDRESSFORMAT;
             const formatedAddress1 = addressLine1.trim().length > 0 ? `${addressLine1},` : '';
             const formatedAddress2 = addressLine2.trim().length > 0 ? `${addressLine2},` : '';
             let { showAlert } = prevState;
             // alertMsg = '';
             const { mailTypeRadio } = prevState;
             const poBoxErr = 'Post Office Box is not accepted as a physical address.';
             const usAddr = mailingAddr.length > 0 && mailingAddr[0].key;
             errorMsg.city = null;
             errorMsg.state = null;
             errorMsg.lineOne = checkSingleEqualityAndReturnValue(errorMsg.lineOne , poBoxErr, poBoxErr ,null);
             errorMsg.lineTwo = checkSingleEqualityAndReturnValue(errorMsg.lineTwo , poBoxErr , poBoxErr , null);
            if (mailTypeRadio !== usAddr && mailTypeRadio?.toUpperCase() !== city) {
                 showAlert = true;
                 alertMsg = `Not a valid ${mailTypeRadio?.toUpperCase()} address`;
             }
 
             return {
                 zipCode: zip,
                 city,
                 state,
                 lineOneFormatted: addressLine1,
                 lineTwoFormatted: addressLine2,
                 formatApiCalled: false,
                 addressFormatApiCalled: true,
                 errorMsg,
                 displayUSPSSuggestion: true,
                 uspsSuggestedAddress: `${formatedAddress1} ${formatedAddress2} ${city}, ${state}-${zip}`,
                 verifyingAddress: false,
                 cityName: city,
                 stateName: state,
                 retrievedState: true,
                 addressValid: true,
                 showAlert,
                 alertMsg,
             };
         }
        
         const mailTypeRadio = savedState?.addressType || mailingAddr.length > 0 && mailingAddr[0]?.key;
         if (!prevState.retrievedState && !savedState) {
             errorMsg.city = null;
             errorMsg.state = null;
             errorMsg.lineOne = null;
             errorMsg.lineTwo = null;
 
             return {
                 ...savedState,
                 errorMsg,
                 retrievedState: true,
                 lineOne: '',
                 lineTwo: '',
                 zipCode: '',
                 cityName: '',
                 stateName: '',
                 mailTypeRadio,
             };
         }
         if (!prevState.retrievedState && savedState) {
             errorMsg.city = null;
             errorMsg.state = null;
             errorMsg.lineOne = null;
             errorMsg.lineTwo = null;
             return {
                 ...savedState,
                 errorMsg,
                 retrievedState: true,
                 lineOne: getValidValueOf(savedState?.addressLine1, savedState?.addressLine1, ''),
                 lineTwo: getValidValueOf(savedState?.addressLine2, savedState?.addressLine2, ''),
                 zipCode: getValidValueOf(savedState?.zip, savedState?.zip, ''),
                 cityName: getValidValueOf(savedState?.city, savedState?.city, ''),
                 stateName: getValidValueOf(savedState?.state, savedState?.state, ''),
                 mailTypeRadio,
             };
         }
         return prevState;
     }
 
     componentDidMount() {

         const { masterLookupStateData, getCompositeLookUpData, mailRadio, localStateData } = this.props;
         const { masterDataLoaderForMailRadio, lineTwo, zipCode, formatApiCalled, errorMsg } = this.state;
         if (mailRadio && !masterDataLoaderForMailRadio) {
             const payload = [];
             const compositePayloadData = ['address_type'];
 
             for (let i = 0; i < compositePayloadData.length; i += 1) {
                 const tempkey = compositePayloadData[parseInt(i, 10)];
                 if (masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                     payload.push(tempkey);
                 }
             }
             getCompositeLookUpData(payload);
             this.setState({ masterDataLoaderForMailRadio: true });
         }
         this.updateErrorStatus();
         // USPS validation when redirected from marstone
         const primaryMailAddress =
             localStateData &&
             localStateData.Individual &&
             localStateData.Individual.perInfo &&
             localStateData.Individual.perInfo.primMailAddress;
         if (
             lineTwo &&
             zipCode.trim().length > 0 &&
             !formatApiCalled &&
             primaryMailAddress &&
             !primaryMailAddress.uspsAddressSelected &&
             !primaryMailAddress.addressFormatApiCalled &&
             primaryMailAddress.marstoneFlag
         ) {
             let formatApiInvoked = false;
             const stateApiCalled = false;
             const { zipCode: zipError } = errorMsg;
             formatApiInvoked = this.callAddressFormatAPI(zipError, formatApiCalled, stateApiCalled);
             this.setState({ formatApiCalled: formatApiInvoked });
         }
     }
 
     componentDidUpdate() {
         this.updateErrorStatus();
     }
 
     // if function is calle without parameters then define a default parameter so that it works even if a funcrionis called with parameter
     // action can be 'save or 'next
     getAddress(action = 'defaultVal') {
         const { isRequired, labelAdd2, isJoint, index, type } = this.props;
         const { errorMsg } = this.state;
         const jointText = isJoint ? 'jointOwner' : '';
         const trimLabelAdd2 = labelAdd2.split(' ').join('');
         const addressLine2Id = `${jointText}${type}${trimLabelAdd2}${index}`;
         const zipcodeId = `${jointText}${type}zipcode${index}`;
 
         // if action is save then dont validate
         let save = action == 'save'
         let valid = isRequired && !save ? this.validateFields() : false;
 
         // when save button is clicked reset the errors in individual input fields and error banner
         if(action === 'save'){
             this.onSaveResetStateValues()
         }
         let toFocus = '';
         if (errorMsg.lineTwo) {
             toFocus = addressLine2Id;
         } else if (errorMsg.zipCode) {
             toFocus = zipcodeId;
         }
         return { ...this.state, valid, toFocus };
     }
 
     // reset error messages/banner if any upon clicking save button, this is called from its parent using ref
     onSaveResetStateValues = () =>{
         this.setState({ errorMsg:{}, showAlert: false });
     }
 
     validateFields = () => {
         const { errorMsg, uspsAddressSelected, displayUSPSSuggestion, cityName, mailTypeRadio } = this.state;
         const { mailRadio, addressFormatData, masterLookupStateData } = this.props;
         const invalidFiled = [];
         let fields = ['lineOne', 'lineTwo', 'zipCode', 'city', 'state'];
         if (mailRadio) {
             fields = [...fields, 'mailTypeRadio'];
         }
         let valid = false;
         const stateData = this.state;
         let fieldNameVal = '';
         fields.forEach(fieldName => {
             let err = '';
             const errMsgId = this.getErrorMsgId(fieldName);
             switch (fieldName) {
                 case 'lineTwo':
                     fieldNameVal = 'Address Line 1';
                     break;
                 case 'lineOne':
                     fieldNameVal = 'Address Line 2';
                     break;
                 default:
                     fieldNameVal = fieldName;
             }
             err = Rules[errMsgId.toString()]
                 ? checkValidity({
                       rules: Rules[errMsgId.toString()],
                       value: stateData[fieldName.toString()],
                       label: fieldNameVal,
                   })
                 : '';
             err = fieldName === 'mailTypeRadio' ? !stateData[fieldName.toString()] : err;

             if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
                 const boxErrorMsg = this.validateBoxAddressInfo(err);
                 errorMsg[fieldName.toString()] = boxErrorMsg;
                 err = boxErrorMsg;
             }
             else {
                 errorMsg[fieldName.toString()] = err;
             }

           // errorMsg[fieldName.toString()] = err;
             if (typeof err === 'boolean') {
                 invalidFiled.push(valid);
                 valid = err === true;
             } else if (typeof err === 'string') {
                 invalidFiled.push(valid);
                 valid = err !== '';
             }
         });
         let displayAlert = false;
         let msg = '';
         valid = !invalidFiled.some(filed => filed === true);
         const { optional } = this.props;
        
         const { address_type: mail } = masterLookupStateData || {};
         const { value: mailingAddr } = mail || {};
         const mailTypeRadioTemp = mailingAddr != undefined && mailingAddr.length > 0 && mailingAddr[0]?.key;
         if (addressFormatData.isError || !valid) {
             displayAlert = true;
             valid = false;
             msg =
                 (addressFormatData.GET_ADDRESSFORMAT && addressFormatData.GET_ADDRESSFORMAT.description) ||
                 CONST.ADDRESS_ERROR;
 
             if (msg === CONST.ADDRESS_ERROR && optional === 'true') {
                 msg = '';
                 displayAlert = false;
             }
         }
 
         if (!uspsAddressSelected && displayUSPSSuggestion) {
             valid = false;
             displayAlert = true;
             msg = CONST.uspsErrorMessage;
         }
 
         if (mailTypeRadio && mailTypeRadio !== mailTypeRadioTemp && mailTypeRadio.toUpperCase() !== cityName) {
             valid = false;
             displayAlert = true;
             msg = `${CONST.uspsAddresMismatchError}${mailTypeRadio.toUpperCase()}`;
         }
 
         if (stateData.zipCode.substring(0, 5) === '12345') {
             valid = false;
             displayAlert = true;
             msg = CONST.ADDRESS_VALID_ERROR;
         }
 
         this.setState({ errorMsg, showAlert: displayAlert, alertMsg: msg });
         return valid;
     };
 
     handleChange = fieldName => e => {
         const { target } = e;
         const { value = '' } = target || {};
         const { optional } = this.props;
         const targetValue = e.target.value;
         let isInvalidInput = false;
         if (fieldName === 'lineOne' || fieldName === 'lineTwo') {
             // As per FIS :: so it looks like the only special characters that FIS will accept in that address field is '-', '/', '&', and '#', and "'".
             isInvalidInput = targetValue.match(/[!@$%^*(),.?":;{}_+|<>=~`\[\]\\]/g) || targetValue.length > 40;
         } else if (fieldName === 'zipCode') {
             isInvalidInput =
                 targetValue.match(/[a-zA-Z!@$%^*(),.?";:{}_+#&|<>=~`\[\]'\\/]/g) || targetValue.length > 10;
         }
         if (!isInvalidInput) {
             if (optional === 'false') {
 
                 const {handleStateChange} = this.props;
                 if(this.props?.typeData == 'physical' )
                 {
                     handleStateChange('physical');
                 }
 
                 this.setState(prevState => {
                     let cityChanged = null;
                     let stateChanged = null;
                     let formatApiCalledChanged = null;
                     let stateApiCalledChanged = null;
                     let verifyingAddress = false;
 
                     if (fieldName === 'zipCode' && value.trim().length === 5) {
                         cityChanged = '';
                         stateChanged = '';
                         formatApiCalledChanged = false;
                         stateApiCalledChanged = false;
                         verifyingAddress = true;
                     } else {
                         const { cityName, stateName, formatApiCalled, stateApiCalled } = prevState;
                         cityChanged = cityName;
                         stateChanged = stateName;
                         formatApiCalledChanged = formatApiCalled;
                         stateApiCalledChanged = stateApiCalled;
                     }
                     return {
                         [fieldName]: value,
                         uspsAddressSelected: false,
                         formatApiCalled: formatApiCalledChanged,
                         cityName: cityChanged,
                         stateName: stateChanged,
                         verifyingAddress,
                         stateApiCalled: stateApiCalledChanged,
                     };
                 });
             } else {
                 this.setState({ [fieldName]: value });
             }
         }
     };
 
     checkAddressStatus = (data, errorMsg) => {
         const { mailRadio } = this.props;
         let valid = data.uspsAddressSelected; // && !!lineTwo && !!zipCode && !!city && !!state
         if (mailRadio) {
             valid = valid && !!data.mailTypeRadio;
         }
         valid = valid && !(errorMsg.lineOne && errorMsg.lineTwo && errorMsg.city && errorMsg.state) && !data.showAlert;
         return valid;
     };
 
     getErrorMsgId = fieldName => {
         let errMsgId = fieldName;
         const { type } = this.props;
         if (fieldName === 'lineTwo' && type === 'physical') {
             errMsgId = 'lineTwoPhysical';
         } else if (fieldName === 'lineOne' && type === 'physical') {
             errMsgId = 'lineOnePhysical';
         } else {
             errMsgId = fieldName;
         }
         return errMsgId;
     };

     getPoBoxErrorMessage = (err) =>
     {
        if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
            return this.validateBoxAddressInfo(err);
        }
        else {
            return err;
        }
     }

     handleAllowPoBoxText = () =>
     {
        return (this.props?.allowPoBoxText !=undefined && this.props?.allowPoBoxText==true && this?.props?.flowName == "MarketNAO" && this?.props?.type == "physical" )
     }
 
     handleBlur = fieldName => e => {

         const {  addressFormatData, labelAdd2, isJoint, type, index } = this.props;
         
         const { zipCode, errorMsg, uspsAddressSelected, mailTypeRadio } = this.state;
         const jointText = getValidValueOf(isJoint,'jointOwner',"");
         const trimLabelAdd2 = labelAdd2.split(' ').join('');
         const addressLine2Id = `${jointText}${type}${trimLabelAdd2}${index}`; 
         let { cityName, stateName } = this.state;
         const stateApiCalled = false;
         let formatApiCalled = false;
         let fieldNameVal = '';
         switch (fieldName) {
             case 'lineTwo':
                 fieldNameVal = 'Address Line 1';
                 break;
             case 'lineOne':
                 fieldNameVal = 'Address Line 2';
                 break;
             default:
                 fieldNameVal = fieldName;
         }
 
         const { GET_STATECITY } =  getDataFromORCondition(addressFormatData,{});
         const { zip = '', city = '', state = '' } = getDataFromORCondition(GET_STATECITY,{});
         const { zipCode: zipError } = errorMsg;
         let err = '';
         let errMsgId = '';
         // if (optional === 'false' ) {
         errMsgId = this.getErrorMsgId(fieldName);
         const { value } = e.target;
 
         if(this.state?.lineTwo !="" && (this.state?.zipCode !="" || this.state?.zip !="") && this.props.handleValidationsOnBlur !=undefined && this.props.handleValidationsOnBlur !=null && this.props.handleValidationsOnBlur ==true)
        {
            err = Rules[errMsgId.toString()]
            ? checkValidity({
                  rules: Rules[errMsgId.toString()],
                  value,
                  label: fieldNameVal,
              })
            : '';
             if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
                 errorMsg[fieldName.toString()] = this.validateBoxAddressInfo(err);
             }
             else {
                 errorMsg[fieldName.toString()] = err;
             }
        }
        else if(checkORConditionWithParameter(this.props.handleValidationsOnBlur,undefined,this.props.handleValidationsOnBlur,null))
        {
            err = Rules[errMsgId.toString()]
            ? checkValidity({
                  rules: Rules[errMsgId.toString()],
                  value,
                  label: fieldNameVal,
              })
            : '';

            errorMsg[fieldName.toString()] = this.getPoBoxErrorMessage(err)

            // if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
            //     errorMsg[fieldName.toString()] = this.validateBoxAddressInfo(err);
            // }
            // else {
            //     errorMsg[fieldName.toString()] = err;
            // }
        }
        else if(this.handleAllowPoBoxText())
        {
            err = Rules[errMsgId.toString()]
            ? checkValidity({
                  rules: Rules[errMsgId.toString()],
                  value,
                  label: fieldNameVal,
              })
            : '';
            errorMsg[fieldName.toString()] = this.validateBoxAddressInfo(err);
        }
         // }
         // call address format Api
         if (
             (fieldName === 'zipCode' && value.trim().length >= 5) ||
             (fieldName === 'lineTwo' && zipCode.trim().length === 5)
         ) {

             formatApiCalled = this.callAddressFormatAPI(zipError, formatApiCalled, stateApiCalled);
         }
 
         this.setState(() => {
             if (zipCode === zip && (cityName !== city || stateName !== state)) {
                 cityName = city;
                 stateName = state;
             }
             if (zipError) {
                 cityName = '';
                 stateName = '';
             }
 
             const data = {
                 mailTypeRadio,
                 uspsAddressSelected,
             };
             const result = this.checkAddressStatus(data, errorMsg);
 
             return {
                 errorMsg,
                 stateApiCalled,
                 formatApiCalled,
                 cityName,
                 stateName,
                 ready: result,
             };
         },()=>{
             if(fieldName === 'zipCode' && addressFormatData.isError && formatApiCalled)
             document.getElementById(addressLine2Id).focus();
         });
         // } else {
         //     this.handleOptionalAddressFieldsBlur(fieldName, e);
         // }
     };
 
     callAddressFormatAPI = zipError => {

         let formatApiCalled = false;
         const { getAddressFormat } = this.props;
         const { lineTwo, lineOne, zipCode } = this.state;
         if (lineTwo && zipCode && !zipError && !formatApiCalled) {
             formatApiCalled = true;
 
             getAddressFormat({
                 addressLine1: lineOne,
                 addressLine2: lineTwo,
                 zip: zipCode,
                 city: '',
                 state: '',
             });
 
             this.setState({ verifyingAddress: true, showAlert: false });
         }
         return formatApiCalled;
     };
 
     handleOptionalAddressFieldsBlur = (fieldName, e) => {
         let errMsgId = '';
         let err = '';
         const { errorMsg } = this.state;
         if (fieldName === 'lineOne') {
             errMsgId = 'optionalLineOne';
         } else if (fieldName === 'lineTwo') {
             errMsgId = 'optionalLineTwo';
         } else if (fieldName === 'zipCode') {
             errMsgId = 'optionalZipCode';
         } else if (fieldName === 'cityName') {
             errMsgId = 'optionalCity';
         } else {
             errMsgId = 'optionalState';
         }
         err = Rules[errMsgId.toString()]
             ? checkValidity({
                   rules: Rules[errMsgId.toString()],
                   value: e.target.value,
               })
             : '';
 
         errorMsg[fieldName.toString()] = err;
         this.setState({ errorMsg });
     };
 
     getValue = e => {
         const { masterLookupStateData, handleAddressTypeInfo } = this.props;
         const { address_type: mail } = masterLookupStateData || {};
         const { value: mailingAddr } = mail || {};
         const mailingAddrType = mailingAddr || [];
         // if (data.mailTypeRadio) {
         const mailTypeKey =
             mailingAddrType &&
             mailingAddrType.filter(field => {
                 return field.value === e.target.value;
             });
         if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
             handleAddressTypeInfo(mailTypeKey[0].key)
         }
 
         this.setState({
             // ...data,
             addressLine1: '',
             addressLine2: '',
             addressType: mailTypeKey[0].key,
             zip: '',
             lineOne: '',
             lineTwo: '',
             zipCode: '',
             city: '',
             state: '',
             cityName: '',
             stateName: '',
             errorMsg: {
                 lineOne: '',
                 lineTwo: '',
                 zipCode: '',
                 cityName: '',
                 stateName: '',
             },
             showAlert: false,
             alertMsg: '',
             stateApiCalled: false,
             displayUSPSSuggestion: false,
             selectedUspsAddress: false,
             formatApiCalled: false,
             masterDataLoaderForMailRadio: false,
             verifyingAddress: false,
             mailTypeRadio: mailTypeKey[0].key,
         });
         // } else {
         //     this.setState(data);
         // }
     };
 
     handleUspsAddress = () => {
         const { errorMsg, lineOneFormatted, lineTwoFormatted, mailTypeRadio, state } = this.state;
         const {  labelAdd2, isJoint, type,index, pageIndex, handleNextDisable  } = this.props;
         const accountTypeSelected= sessionStorage.getItem("accountTypeSelected")
         const jointText = isJoint ? 'jointOwner' : '';
         const trimLabelAdd2 = labelAdd2.split(' ').join('');
         const addressLine2Id = `${jointText}${type}${trimLabelAdd2}${index}`;
         const getFocusAX = document.getElementById(addressLine2Id);
         const stateNameValidation= state=="LA" || state=="PR";
         (accountTypeSelected=="Joint" || accountTypeSelected==MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT)  && pageIndex==0 &&  handleNextDisable( stateNameValidation) // this is the common component
         this.setState(
             () => {
                 const data = {
                     uspsAddressSelected: true,
                     mailTypeRadio,
                     // showAlert: false,
                     showAlert: true,
                 };
                 const valid = this.checkAddressStatus(data, errorMsg);
 
                 return {
                     uspsAddressSelected: true,
                     showAlert: false,
                     // showAlert: true,
                     displayUSPSSuggestion: false,
                     lineOne: lineOneFormatted,
                     lineTwo: lineTwoFormatted,
                     errorMsg,
                     alertMsg: null,
                     // alertMsg: 'Please select on USPS Recommendation address to proceed further',
                     ready: valid,
                     verifyingAddress: false,
                     ShowModal: (accountTypeSelected=="Joint" || accountTypeSelected== MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT) && pageIndex==0 ? stateNameValidation : false ,
                 };
             },
             () => {
                 const { checkStatus } = this.props;
                 checkStatus();
                 getFocusAX && getFocusAX.focus();
             },
         );
     };

    handleStateNameValidation=()=>{
          this.setState({ShowModal: false})
    }
 
     handleRadioBlur = eventKey => e => {
         e.persist();
         const { errorMsg, mailTypeRadio, uspsAddressSelected, showAlert } = this.state;
         let err = '';
         err = Rules[eventKey.toString()]
             ? checkValidity({
                   rules: Rules[eventKey.toString()],
                   value: mailTypeRadio,
               })
             : '';
         if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
             errorMsg[eventKey.toString()] = this.validateBoxAddressInfo(err);
         }
         else {
             errorMsg[eventKey.toString()] = err;
         }
         this.setState(
             () => {
                 const data = {
                     mailTypeRadio,
                     uspsAddressSelected,
                     showAlert,
                 };
                 const result = this.checkAddressStatus(data, errorMsg);
                 return {
                     errorMsg,
                     ready: result,
                 };
             },
             () => {
                 const { checkStatus, handleAddressTypeInfo } = this.props;

                 if (this.props?.allowPoBoxText != undefined && this.props?.allowPoBoxText == true) {
                     handleAddressTypeInfo(mailTypeRadio)
                 }

                 checkStatus();
             },
         );
     };
 
     updateErrorStatus() {
         const { lineOne, zipCode, cityName, stateName, errorMsg } = this.state;
         let isError = false;
         const prevErrorStatus = this.isError;
         this.isError = false;
         Object.keys(errorMsg).forEach(item => {
             isError = isError || !!errorMsg[item.toString()];
         });
         if (!lineOne || !zipCode || !cityName || !stateName || isError) {
             this.isError = true;
         }
         if (prevErrorStatus !== this.isError) {
             const { onStatusChange } = this.props;
             onStatusChange();
         }
     }
     validateBoxAddressInfo = (err) => {
         const { addressTypeInfo } = this.props;
         if (addressTypeInfo != undefined && addressTypeInfo && CONST.usTerritories.includes(addressTypeInfo) == false && err == CONST.boxErrorMessage) {
             return "";
         }
         else {
             return err;
         }
     }

     render() {
         const {
             labelAdd1,
             labelAdd2,
             masterLookupStateData,
             mailRadio,
             physicalRadio,
             optional,
             type,
             isJoint,
             index,
             labelsm,
             labelmd,
             valuesm,
             valuemd,
             wrapperGutter,
         } = this.props;
         const { assignObj, horizontalStyle } = accMgmtConst;
         const {
             uspsButtonText,
             uspsCardSubText,
             uspsCardHeaderText,
             uspsCardHeaderTextStyle,
             uspsCardStyle,
             uspsCardTextStyle,
             uspsCardSubTextStyle,
             uspsVerifyingAddressTitle,
             uspsVerifyingAddressText,
             uspsStateNameError,
         } = CONST;
         const { address_type: mail } = masterLookupStateData || {};
         const { value: mailingAddr } = mail || {};
         const mailingAddrType = mailingAddr || [];
         const {
             errorMsg,
             //   lineOneFormatted,
             //   lineTwoFormatted,
             lineOne,
             lineTwo,
             zipCode,
             mailTypeRadio,
             uspsSuggestedAddress,
             // verifyingAddress,
             ShowModal,
         } = this.state;
          const {verifyPersonalHider,verifyPhoneHider,verifyEmailHider,verifyJointPersonalHider,verifyJointPhoneHider,verifyJointEmailHider}=this.props
         let { displayUSPSSuggestion, showAlert, alertMsg = '', cityName, stateName, verifyingAddress } = this.state;
         // let { uspsSuggestedAddress } = this.state;
         // if (displayUSPSSuggestion) {
         //     uspsSuggestedAddress = `${lineOneFormatted},${lineTwoFormatted},${cityName},${stateName}-${zipCode}`;
         // }

 
        //  if (addressFormatData.stateCitySuccess && !addressFormatData.isLoading) {
        //      this.callAddressFormatAPI(zipError);
        //  }
         const jointText = isJoint ? 'jointOwner' : '';
         const radiodescstyles = {
             color: 'rgba(86, 86, 90, 1)',
             fontSize: 14,
             fontWeight: 400,
         };
 
         const radiostyles = {
             color: 'rgba(73, 73, 74, 1)',
             fontSize: 16,
             minHeight: 'unset',
         };
 
         const radioText = { font: '500 16px/20px benton-sans' };
         const mailTyperadio = (
             <Row className="Slabel">
                 <span
                     className="col-md-4 label"
                     style={assignObj({ ...styles.labelTextStyle, paddingLeft: 0 })}
                     id={assignObj(`${jointText}MailingAddress`)}
                 >
                     {(physicalRadio != undefined && physicalRadio == true ) ?
                      CONST.PHYSICAL_ADDRESS : CONST.MAIL_ADDRESS}
                 </span>
                 <div
                     className="mailTypeList "
                     id={assignObj(`${jointText}mailTypeList${index}`)}
                     role="radiogroup"
                     aria-labelledby={assignObj(`${jointText}MailingAddress`)}
                     aria-required="true"
                 >
                     {/* <RadioList
                         items={mailingAddrType}
                         field="mailTypeRadio"
                         setValue={this.getValue}
                         selectedValue={mailTypeRadio}
                         radiostyles={assignObj({ marginBottom: 20 })}
                         errorText={CONST.MAIL_ADDRESS_HINT}
                         initialError={!!errorMsg.mailTypeRadio}
                         handleBlur={this.handleRadioBlur('mailTypeRadio')}
                     /> */}
 
                     {mailingAddrType &&
                         mailingAddrType.length > 0 &&
                         mailingAddrType.map(field => (
                             <WRadio
                                 radioFor={`${jointText}mailTypeRadio${index}`}
                                 key={field.key}
                                 id={`${jointText}${field.key}${index}`}
                                 value={field.value}
                                 onBlur={assignObj(this.handleRadioBlur(
                                    (physicalRadio !=null &&  physicalRadio == true) ? 'physicalTypeRadio' :'mailTypeRadio'))}
                                 onClick={this.getValue}
                                 radiodescstyles={radiodescstyles}
                                 radiostyles={radiostyles}
                                 radiotext={radioText}
                                 selected={
                                 !!(mailTypeRadio && mailTypeRadio.toString() === field.key)}
                                 ariaLabel={field.key}
                             />
                         ))}
                 </div>
             </Row>
         );
         const trimLabelAdd1 = labelAdd1.split(' ').join('');
         const trimLabelAdd2 = labelAdd2.split(' ').join('');
         const addressLine1Id = `${jointText}${type}${trimLabelAdd1}${index}`;
         const addressLine2Id = `${jointText}${type}${trimLabelAdd2}${index}`;
 
         const cityId = `${jointText}${type}city${index}`;
         const stateId = `${jointText}${type}state${index}`;
         const zipcodeId = `${jointText}${type}zipcode${index}`;
         const uspsSuggesstedCardId = `${jointText}${type}uspsSuggestedCardId`;
         let errorLine2 = '';
         let errorZipCode = '';
         if (optional === 'true' && lineTwo.trim().length === 0 && zipCode.trim().length === 0) {
             errorLine2 = '';
             errorZipCode = '';
         } else {
             errorLine2 = errorMsg.lineTwo;
             errorZipCode = errorMsg.zipCode;
         }
 
         let isValidAddress = true;
 
         if (mailTypeRadio && mailTypeRadio !== 'U.S. or U.S. Territories' && mailTypeRadio.toUpperCase() !== cityName) {
             isValidAddress = false;
         }
 
         if (zipCode.trim().substring(0, 5) === '12345') {
             isValidAddress = false;
             displayUSPSSuggestion = false;
             showAlert = true;
             alertMsg = CONST.ADDRESS_VALID_ERROR;
             cityName = '';
             stateName = '';
             verifyingAddress = false;
         }
 
         // const alertId = `${jointText}${type}alertId`;
         return (
         	verifyPersonalHider || verifyPhoneHider || verifyEmailHider || verifyJointPersonalHider || verifyJointPhoneHider|| verifyJointEmailHider ? null : 
             <>
                 <div className="addressFields">
                     {(mailRadio || physicalRadio ) && mailTyperadio}
                     <AlertWrapper>
                         <Col sm={12}>
                             {showAlert && (
                                 <StyledAlert
                                     variant="danger"
                                     aria-atomic="true"
                                     aria-live="assertive"
                                     aria-describedby={alertMsg}
                                 >
                                     <StyledPara>{alertMsg}</StyledPara>
                                 </StyledAlert>
                             )}
                         </Col>
                     </AlertWrapper>
                     <MediaQuery maxDeviceWidth={768}>
                         <Row style={horizontalStyle} noGutters={wrapperGutter}>
                             {isValidAddress && displayUSPSSuggestion && (
                                 <WCard
                                     id={uspsSuggesstedCardId}
                                     cardstyle={uspsCardStyle}
                                     cardtitlestyle={uspsCardHeaderTextStyle}
                                     cardtitletext={uspsCardHeaderText}
                                     cardsubtitletext={uspsCardSubText}
                                     cardsubtitlestyle={uspsCardSubTextStyle}
                                     buttontext={uspsButtonText}
                                     cardtext={uspsSuggestedAddress || ''}
                                     cardtextstyle={uspsCardTextStyle}
                                     onClick={this.handleUspsAddress}
                                     arialabelForBtn="Use USPS Recommended Address"
                                 />
                             )}
 
                             {verifyingAddress && !displayUSPSSuggestion && (
                                 <WCard
                                     id={uspsSuggesstedCardId}
                                     cardstyle={uspsCardStyle}
                                     cardtitlestyle={uspsCardHeaderTextStyle}
                                     cardtitletext={uspsVerifyingAddressTitle}
                                     cardsubtitletext={uspsVerifyingAddressText}
                                     cardsubtitlestyle={uspsCardSubTextStyle}
                                     cardtextstyle={uspsCardTextStyle}
                                     buttonstyle={{ display: 'none' }}
                                 />
                             )}
                             {ShowModal && 
                                <ConfirmModal
                                    modalTitle={""}
                                    modalBodyText={uspsStateNameError}
                                    primaryButtonText="OK"
                                    onPrimaryClick={this.handleStateNameValidation}
                                />
                             }
                         </Row>
                     </MediaQuery>
                     <Row style={horizontalStyle} noGutters={wrapperGutter}>
                         <WInput
                             label={labelAdd1}
                             id={addressLine2Id}
                             name={labelAdd2}
                             placeholder="<Example:123 NW Main St>"
                             type="text"
                             onChange={this.handleChange('lineTwo')}
                             onBlur={this.handleBlur('lineTwo')}
                             required
                             optional={optional}
                             value={lineTwo}
                             // errortext={errorMsg.lineTwo}
                             errortext={errorLine2}
                             labelsm={labelsm}
                             labelmd={labelmd}
                             valuesm={valuesm}
                             valuemd={valuemd}
                             maxlength={40}
                             aria-required
                             noGutters
                             infoMsg={type === 'physical' ? errorMsg.lineTwo?'': "" : ''}
                             infoMsgStyle={{fontWeight:'bold', fontSize:'12px'}}
                             ariaAssertiveVal={type}
                             // arialabelledby={addressLine2Id}
                         />
                     </Row>
                     <Row style={horizontalStyle} noGutters={wrapperGutter}>
                         <WInput
                             label={labelAdd2}
                             placeholder="<Example: Apt 5, Box 30…>"
                             type="text"
                             id={addressLine1Id}
                             name={labelAdd1}
                             onChange={this.handleChange('lineOne')}
                             onBlur={this.handleBlur('lineOne')}
                             value={lineOne}
                             errortext={errorMsg.lineOne}
                             labelsm={labelsm}
                             labelmd={labelmd}
                             valuesm={valuesm}
                             valuemd={valuemd}
                             maxlength={40}
                             optional="true"
                             aria-required={false}
                             noGutters
                             // arialabelledby={addressLine1Id}
                         />
                     </Row>
                     <Row style={horizontalStyle} noGutters={wrapperGutter}>
                         <WInput
                             label="Zip Code"
                             type="text"
                             id={zipcodeId}
                             name="zipCode"
                             onChange={this.handleChange('zipCode')}
                             onBlur={this.handleBlur('zipCode')}
                             required
                             subtype="zipcode"
                             value={zipCode}
                             // errortext={errorMsg.zipCode}
                             errortext={errorZipCode}
                             labelsm={labelsm}
                             labelmd={labelmd}
                             valuesm={valuesm}
                             valuemd={valuemd}
                             maxlength={10}
                             optional={optional}
                             aria-required
                             noGutters
                             arialabelledby={zipcodeId}
                             ariaAssertiveVal={`${jointText}${type}uspsSuggestedCardId`}
                         />
                     </Row>
                     <Row style={horizontalStyle} noGutters={wrapperGutter}>
                         <WInput
                             label="City"
                             id={cityId}
                             name="cityName"
                             type="text"
                             placeholder=""
                             onChange={this.handleChange('cityName')}
                             onBlur={this.handleBlur('cityName')}
                             required
                             value={cityName}
                             errortext={errorMsg.cityName}
                             labelsm={labelsm}
                             labelmd={labelmd}
                             valuesm={valuesm}
                             valuemd={valuemd}
                             readOnly
                             maxlength={30}
                             optional={optional}
                             aria-required
                             noGutters
                             arialabelledby={cityId}
                         />
                     </Row>
                     <Row style={horizontalStyle} noGutters={wrapperGutter}>
                         <WInput
                             label="State/Territory"
                             type="text"
                             id={stateId}
                             name="stateName"
                             placeholder=""
                             onChange={this.handleChange('stateName')}
                             onBlur={this.handleBlur('stateName')}
                             required
                             value={stateName}
                             errortext={errorMsg.stateName}
                             labelsm={labelsm}
                             labelmd={labelmd}
                             valuesm={valuesm}
                             valuemd={valuemd}
                             readOnly
                             maxlength={30}
                             optional={optional}
                             aria-required
                             noGutters
                             arialabelledby={stateId}
                         />
                     </Row>
                     <MediaQuery minDeviceWidth={767}>
                         <Row style={horizontalStyle} noGutters={wrapperGutter}>
                             {isValidAddress && displayUSPSSuggestion && (
                                 <WCard
                                     id={uspsSuggesstedCardId}
                                     cardstyle={uspsCardStyle}
                                     cardtitlestyle={uspsCardHeaderTextStyle}
                                     cardtitletext={uspsCardHeaderText}
                                     cardsubtitletext={uspsCardSubText}
                                     cardsubtitlestyle={uspsCardSubTextStyle}
                                     buttontext={uspsButtonText}
                                     cardtext={uspsSuggestedAddress || ''}
                                     cardtextstyle={uspsCardTextStyle}
                                     onClick={this.handleUspsAddress}
                                     arialabelForBtn="Use USPS Recommended Address"
                                     tabindex="0"
                                     aria-live="assertive"
                                     aria-atomic="true"
                                 />
                             )}

                             {ShowModal &&
                             <ConfirmModal
                                modalTitle={""}
                                modalBodyText={uspsStateNameError}
                                primaryButtonText="OK"
                                onPrimaryClick={this.handleStateNameValidation}
                             />
                             }
 
                             {verifyingAddress && !displayUSPSSuggestion && (
                                 <WCard
                                     id={uspsSuggesstedCardId}
                                     cardstyle={uspsCardStyle}
                                     cardtitlestyle={uspsCardHeaderTextStyle}
                                     cardtitletext={uspsVerifyingAddressTitle}
                                     cardsubtitletext={uspsVerifyingAddressText}
                                     cardsubtitlestyle={uspsCardSubTextStyle}
                                     cardtextstyle={uspsCardTextStyle}
                                     buttonstyle={{ display: 'none' }}
                                     tabindex="0"
                                     aria-live="assertive"
                                     aria-atomic="true"
                                 />
                             )}
                         </Row>
                     </MediaQuery>
                 </div>
             </>
         );
     }
 }
 
 Address.propTypes = {
     masterLookupStateData: PropTypes.instanceOf(Object),
     addressFormatData: PropTypes.instanceOf(Object),
     getCompositeLookUpData: PropTypes.func,
     isRequired: PropTypes.bool,
     labelAdd1: PropTypes.string,
     labelAdd2: PropTypes.string,
     getAddressFormat: PropTypes.func,
     savedState: PropTypes.instanceOf(Object),
     mailRadio: PropTypes.bool,
     onStatusChange: PropTypes.func,
     optional: PropTypes.string,
     type: PropTypes.string,
     isJoint: PropTypes.bool,
     labelsm: PropTypes.number,
     valuesm: PropTypes.number,
     wrapperGutter: PropTypes.bool,
     checkStatus: PropTypes.func,
     localStateData: PropTypes.instanceOf(Object),
     index: PropTypes.number,
 };
 
 Address.defaultProps = {
     masterLookupStateData: {},
     addressFormatData: {},
     getCompositeLookUpData: () => {},
     isRequired: true,
     labelAdd1: 'Address Line 1 ',
     labelAdd2: 'Address Line 2 ',
     getAddressFormat: () => {},
     savedState: {},
     mailRadio: false,
     onStatusChange: () => {},
     optional: 'false',
     type: '',
     isJoint: false,
     index: 0,
     labelsm: 12,
     labelmd: 4,
     valuesm: 12,
     valuemd: 5,
     wrapperGutter: false,
     checkStatus: () => {},
     localStateData: {},
 };
 
 export default Address;
 