import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import ConfirmSecurityQuestionsForm from './ConfirmSecurityQuestionsForm';



const mapStateToProps = (state /* , props */) => (
    {
      savedData : state.localStateManagementReducerData.setupSecurityQuestionsState,
      preferenceData: state.localStateManagementReducerData.preferenceState
    }
  );

export default withRouter(connect(
    mapStateToProps
  )(ConfirmSecurityQuestionsForm));