import * as TransactionConstants from '../../TransactionConstants';
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import EditIcon from '../../../../assets/edit.png';
import {
    getAvailableShares,
    getAvailableBalance,
    getDisplayData,
} from 'modules/TransactionModule/Liquidation/FundSelection/index';
import { OptionalLabel } from 'modules/CustomerManagementModule/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { WButton, WCard, MaskText } from '../../../../common';
import '../../../../App.css';

const assignObj = obj => {
    return obj;
};


const cancelButtonStylesDesktop = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
    marginTop: '10px',
};

const displayNoneStyle = { display: 'none' };

const VerifySection = styled.div``;

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const RwdFlexRowDiv = styled.div`
    @media (max-width: 767px) {
        display: block;
        margin-bottom: 20px;
    }
    display: flex;
    flex-direction: row;
`;

const StyledH3 = styled.h3`
    @media (max-width: 767px) {
        font-size: 22px;
    }
`;

const StyledH4 = styled.div`
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

const TitleTxt = styled.div`
    @media (max-width: 767px) {
        text-align: left;
        height: auto;
    }
    height: 32px;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    color: #486d90;
    opacity: 1;
    text-align: left;
    letter-spacing: 0;
`;


const TradingWindowWarning = styled(Row)`
    margin: 20px 0;
    @media (max-width: 767px) {
        text-align: left;
        height: auto;
    }
    .leftCornerCard {
        @media (max-width: 767px) {
            border-bottom: 20px solid transparent !important;
            border-left: 20px solid #8bc105 !important;
        }
    }
`;

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
    top: '1px',
    left: '1px',
};

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const cardBodyStyle = {
    paddingBottom: 0,
    border: '2px solid #004A98',
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const SectionBody = styled(FlexColumnDiv)`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
`;

const Field = styled.div`
    @media (max-width: 767px) {
        margin-bottom: 0.5rem;
    }
    flex: 0 0 15em;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
`;
const Value = styled.div`
    @media (max-width: 767px) {
        margin-bottom: 0.5rem;
        margin-left: 0px;
    }
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
    margin-left: 10px;
`;
const FundField = styled.div`
    @media (max-width: 767px) {
        margin-bottom: 0.5rem;
    }
    font-size: 16px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-bottom: 2.5rem;
`;
const SectionTitle = styled(FlexRowDiv)`
    justify-content: space-between;
    align-items: center;
`;

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const FundFromDesc = styled.h3`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const buttonstyle = { display: 'block' };
const editText = 'Edit';

const EditImage = styled.img`
    margin-left: auto;
    @media (max-width: 767px) {
        margin-top: -13px;
    }
`;

const EditDiv = styled.div`
    cursor: pointer;
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-left: 0.313rem;
    margin-top: 4px;
    float: right;
    @media (max-width: 767px) {
        margin-top: 0px;
    }
`;

EditDiv.displayName = 'EditDiv';

const EditButton = styled.button`
    background-color: inherit;
    border: none;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
`;

const HeadingSrOnly = styled.h2`
    @media (max-width: 767px) {
        display: none;
    }
`;

const BtnWrap = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    & > :nth-child(2) {
        margin-left: auto;
        margin-right: 50px;
        button {
            margin-bottom: 0 !important;
        }
    }
    @media (max-width: 767px) {
        & > :nth-child(1) {
            order: 3;
        }
        & > :nth-child(2) {
            order: 2;
            margin: 0;
            button {
                margin: 0 !important;
            }
        }
        & > :nth-child(3) {
            order: 1;
            width: 100%;
            button {
                width: 100% !important;
            }
        }
        & > :not(:first-child) {
            margin-bottom: 20px;
        }
        flex-direction: column;
    }
`;

class ExchangeReview extends Component {
    constructor(props) {
        super(props);
        const { exchangeReducerData } = this.props;

        this.state = {
            cards: exchangeReducerData.page2.selectedFund.fundAccountNumber
                ? TransactionConstants.REVIEW_EXCHANGE_CONFIRM_DATA
                : TransactionConstants.REVIEW_EXCHANGE_CONFIRM_DATA_WITH_DIVIDENDS,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        document.title = 'Review and Confirm: Exchange| Victory Capital';
        const { cards } = this.state;
        const { exchangeReducerData } = this.props;

        const exchangeStateData = [exchangeReducerData.page0, exchangeReducerData.page1, exchangeReducerData.page2];
        this.updatePurchaseData(cards, exchangeStateData);

        sessionStorage.setItem('stepname', 'Review & Confirm');
        document.getElementById('skipToMain').focus();
    }

    formatInputValues = val => {
        return val ? val.replace(/[a-zA-Z,$.]/g, '') : 0;
    };

    getCardHeading = heading => {
        if (heading === TransactionConstants.ACCOUNT_SELECTION_TEXT) {
            return TransactionConstants.ACCOUNT_INFORMATION;
        }
        if (heading === TransactionConstants.ACCORDIAN_HEADING) {
            return TransactionConstants.FUND_DETAILS_TEXT;
        }
        return heading;
    };

    populateTradeTypeValues = dataObj => {
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            field.key = TransactionConstants.TRADE_TYPE_TEXT;
            field.value = TransactionConstants.EXCHANGE_TEXT;
            return null;
        });
        return dataObj;
    };

    populateAccSelValues = (data, actualData) => {
        const dataObj = { ...data };
        const { transactionHolderData } = this.props;
        dataObj.pageIndex = 0;
        dataObj.section = TransactionConstants.ACCOUNT_SELECTION_TEXT;
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (field.key === TransactionConstants.ACCOUNT_NAME_TEXT) {
                field.value =
                    transactionHolderData &&
                    transactionHolderData.transactionList &&
                    transactionHolderData.transactionList.accountNickName
                        ? transactionHolderData.transactionList.accountNickName
                        : '';
            } else if (field.key === 'Account Number') {
                field.value = actualData[0].accountSelected.accountNumber;
            }
            return null;
        });
        return dataObj;
    };

    populateInvSelValues = (data, actualData) => {
        const dataObj = { ...data };
        dataObj.pageIndex = 2;
        dataObj.section = 'Investment Selection';
        dataObj.fundName = actualData[2].selectedFund ? actualData[2].selectedFund.fundName : '';
        dataObj.fundAccountNumber = actualData[2].selectedFund ? actualData[2].selectedFund.fundAccountNumber : '';
        return dataObj;
    };

    populateDividendsValues = (data, actualData) => {
        const dataObj = { ...data };
        dataObj.pageIndex = 2;
        dataObj.section = 'Investment Selection';
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (field.key === 'Reinvest Earnings, Income and Capital Gains') {
                if (actualData[2].ReInvest) {
                    field.value = 'Yes, I want to Reinvest';
                } else {
                    field.value = `No, I don't want to Reinvest`;
                }
            }
            return null;
        });
        return dataObj;
    };

    populateFundAccValues = (data, actualData) => {
        const dataObj = { ...data };
        dataObj.pageIndex = 1;
        dataObj.section = 'Fund Selections';
        const { selectedFund } = actualData?.[1] || {};
        const { fundName, sellingamt, userinputpercent, amtType,fundAccountNumber } = selectedFund || {};
        dataObj.fundName = fundName;
        dataObj.fundAccountNumber = fundAccountNumber;
        dataObj.fields = getDisplayData({
            worthvalue: getAvailableBalance({ transactionType: 'exchange', fundDetails: selectedFund }),
            sellingamt,
            amtType,
            userinputpercent,
            totalShares: getAvailableShares({ transactionType: 'exchange', fundDetails: selectedFund }),
            selectedFund
        });
        return dataObj;
    };

    updatePurchaseData = (carddata, actualData) => {
        const data = carddata.map(dataKey => {
            const dataObj = { ...dataKey };
            let dataVal;
            if (dataObj.cardId === 'tradeType') {
                dataVal = this.populateTradeTypeValues(dataObj);
            } else if (dataObj.cardId === 'accountSelection') {
                dataVal = this.populateAccSelValues(dataObj, actualData);
            } else if (dataObj.cardId === 'mutualFund') {
                dataVal = this.populateInvSelValues(dataObj, actualData);
            } else if (dataObj.cardId === 'dividends') {
                dataVal = this.populateDividendsValues(dataObj, actualData);
            } else if (dataObj.cardId === 'sellingFunds') {
                dataVal = this.populateFundAccValues(dataObj, actualData);
            }

            return dataVal;
        });

        this.setState({ cards: data });
    };

    render() {
        const { cards } = this.state;
        const {
            onSwitchComponent,
            scrollToSection,
            tradingWindowData,
            pageIndex,
            setCancelExchange,
            exchangeReducerData,
        } = this.props;
        const isInitialExchange = !(
            exchangeReducerData &&
            exchangeReducerData.page2 &&
            exchangeReducerData.page2.selectedFund &&
            exchangeReducerData.page2.selectedFund.fundAccountNumber
        );
        const reviewData = cards.map(item => {
            return (
                <div data-test="review-page" key={item.cardHeading}>
                    <VerifySection key={item.cardHeading}>
                        <SectionTitle>
                                    <TitleTxt>
                                        <StyledH3>{item.cardHeading}</StyledH3>
                                    </TitleTxt>
                                {item.cardHeading != 'Account Selection' ? (
                                     <>
                                    {item.cardId !== TransactionConstants.TRADE_TYPE_VALUE ? (
                                    <EditButton
                                        onClick={scrollToSection(item.pageIndex, item.section)}
                                        aria-label={item.cardHeading ? `Edit ${item.cardHeading}` : ''}
                                        data-analytic-id={`Transactions_Exchange_ReviewConfirm_${item.cardHeading}Edit`}
                                    >
                                        <EditImage src={EditIcon} alt="" />
                                        <EditDiv name={this.getCardHeading(item.cardHeading)} value={editText}>
                                            {editText}
                                        </EditDiv>
                                    </EditButton>
                                               
                                            ) : null}
                                        </>
                                    ) : ('')}

                        </SectionTitle>
                        <hr aria-hidden="true" />
                        <SectionBody>
                            {item.cardHeading === 'Selected Mutual Fund' ? (
                                <FlexRowDiv>
                                    <FundField>
                                        <StyledH4>{item.fundName}</StyledH4>
                                    </FundField>
                                </FlexRowDiv>
                            ) : (
                                ''
                            )}
                            {item.cardHeading === 'Selected Mutual Fund' ? (
                               <FlexRowDiv>
                                <Field>{TransactionConstants.FUND_ACCOUNT_NUMBER_TEXT}</Field>
                                <Value> {item.fundAccountNumber!= null && item.fundAccountNumber!='' ?item.fundAccountNumber : <FundFromDesc>{TransactionConstants.NEW_FUND}</FundFromDesc>}</Value>
                               </FlexRowDiv>
                            ) : (
                                ''
                            )}
                            {item.cardHeading === 'Selling Funds' ? (
                                <FlexRowDiv>
                                    <FundField>
                                        <StyledH4>{item.fundName}</StyledH4>
                                    </FundField>
                                </FlexRowDiv>
                            ) : (
                                ''
                            )}
                            {item.fields.map((data, index) => (
                                <RwdFlexRowDiv key={data.key + data.value + index.toString()}>
                                    <Field>{data.key}</Field>
                                    {data.optionalLabel && <OptionalLabel>{data.optionalLabel}</OptionalLabel>}
                                    <Value>
                                        {data.key === 'Account Number' ? (
                                            <p>{data.value}</p>
                                        ) : (
                                            data.value
                                        )}
                                    </Value>
                                </RwdFlexRowDiv>
                            ))}
                        </SectionBody>
                    </VerifySection>
                </div>
            );
        });

        return (
            <>
                <div className="container exchangeReviewContainer">
                    <HeadingSrOnly className="sr-only">{'Review & Confirm'}</HeadingSrOnly>
                    <div>{reviewData}</div>
                    {!tradingWindowData.canTrade && (
                        <TradingWindowWarning>
                            <Col xs={12}>
                                <WCard
                                    buttonstyle={displayNoneStyle}
                                    cardtitletext={tradingWindowData.tradingClosureMsg.message}
                                    cardtextstyle={cardTextStyle}
                                    cardbodystyle={cardBodyStyle}
                                    cardtitlestyle={cardTitleStyle}
                                    linkcard="true"
                                    leftcornercard={cardBorderStyle}
                                    alt=""
                                    src=""
                                    selected
                                />
                            </Col>
                        </TradingWindowWarning>
                    )}
                </div>
                <MainDiv>
                    <BtnWrap>

                        <div>
                            <WButton
                                variant="back"
                                buttontext={TransactionConstants.BACK_TEXT}
                                onClick={onSwitchComponent(false)}
                                buttonstyle={buttonstyle}
                                aria-label="Back to Fund Your Account"
                                id="desktopBackButton"
                            />
                        </div>
                        <div>
                            <WButton
                                variant="cancel"
                                buttontext="Cancel"
                                id="desktopCancelButton"
                                buttonstyle={assignObj(cancelButtonStylesDesktop)}
                                onClick={setCancelExchange}
                            />
                        </div>
                        <div>
                            <WButton
                                buttontext={
                                    isInitialExchange
                                        ? TransactionConstants.NEXT_TEXT
                                        : TransactionConstants.SUBMIT_TEXT
                                }
                                onClick={onSwitchComponent(true, this.state, pageIndex)}
                                data-test="submit-button"
                                buttonstyle={buttonstyle}
                                className="TransactionSubmit"
                                id={isInitialExchange ? 'desktopNextButton' : 'desktopSubmitButton'}
                            />
                        </div>
                    </BtnWrap>
                </MainDiv>
            </>
        );
    }
}

ExchangeReview.propTypes = {
    onSwitchComponent: PropTypes.instanceOf(Function),
    scrollToSection: PropTypes.instanceOf(Function),
    exchangeReducerData: PropTypes.instanceOf(Object),
    tradingWindowData: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number,
    setCancelExchange: PropTypes.func,
    transactionHolderData: PropTypes.instanceOf(Object),
};

ExchangeReview.defaultProps = {
    onSwitchComponent: () => {},
    scrollToSection: () => {},
    pageIndex: 0,
    exchangeReducerData: {},
    tradingWindowData: {},
    setCancelExchange: () => {},
    transactionHolderData: {},
};

export const mapStateToProps = state => {
    return {
        exchangeReducerData: state.exchangeData,
    };
};

export default connect(mapStateToProps)(ExchangeReview);
