const masterData = {
    // gender: {
    //     value: [
    //         { value: 'Male', key: 'Male' },
    //         { value: 'Female', key: 'Female' },
    //     ],
    // },
};

export default masterData;
