import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { clearRelationActions } from 'shared/Actions/ProfileRelationAction';
import { useSelector, useDispatch } from 'react-redux';
import routeConstants from 'routeConstants';
import {
	WBreadCrumb,
} from "common";
import PersonalInfoView from 'modules/CustomerManagementModule/PersonalInformation/PersonalInfoView/PersonalInfoView';
import ContactInfoView from "modules/CustomerManagementModule/ContactInformation/ContactInfoView/ContactInfoView";
import PhoneInfoView from "modules/CustomerManagementModule/PhoneInformation/PhoneInfoView/PhoneInfoView";
import EmailInfoView from "modules/CustomerManagementModule/EmailInformation/EmailInfoView/EmailInfoView";
import { BottomNavBar, InstructionalPanel } from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import {analyzeClickEvent} from 'shared/Actions/AnalyticsActions';

const RELATION_INFO_INFO_SUCCESS = "Successfully added relationship Information";

const findPrimaryphone = (phone) => {
   return (phone || []).find((data) => data.isPrimary);
}
    
const findSecondaryphone = (phone) => {
   return (phone || []).find((data) => !data.isPrimary);
}
const findMailingAddresstype = (address) => {
    return (address || []).find((data) => data.isMailingAddress);
}
    
const findPhysicalAddresstype = (address) => {
    return (address || []).find((data) => data.isPhysicalAddress);
}

export const onManageClick = (dispatch, history, route) => () => {
    const pageName = route.split('/').join('');
    dispatch(analyzeClickEvent({
        link: `cms_basicinformation_manage${pageName}`
    }))
	history.push(route);
}

export const onBackClick = (dispatch, history) => () => {
    dispatch(analyzeClickEvent({
        link: `cms_basicinformation_manageRelationship_back`
    }))
	history.push(routeConstants.basicinformation);
}

const backBtnProps = {};

const RelationshipManage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
    
	
	const familyMemberIndex = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyMemberIndex: familyMemberIndexVal } = profileRelationshipData;
        return familyMemberIndexVal;
    });
    
	const familyRelationShipInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyRelationShipInfo: familyRelationShipInfoVal } = profileRelationshipData;
        return familyRelationShipInfoVal;
    });
    
    const isError = useSelector(({profileRelationshipData: {isError: apiError}}) => apiError);
    const isSuccess = useSelector(({profileRelationshipData: {isSuccess: apiSuccess}}) => apiSuccess);
    
    useEffect(()=>{
        if(familyMemberIndex === -1 ){
            history.push(routeConstants.basicinformation);
        }
        if(isError || isSuccess) dispatch(clearRelationActions());
    },[dispatch, familyMemberIndex, history,  isError, isSuccess])
    
    const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};

    const notify = isError || isSuccess;
    const message = isSuccess ? RELATION_INFO_INFO_SUCCESS : null;

    const statusNotification = withNotification()({
        notify,
        message: {
            message,
            info: null,
        },
    });
    
	const {
            firstName = '',
            middleInitial = '',
            lastName = '',
            citizenship = '',
            gender = '',
            maritalStatus = '',
            prefix = '',
            ssnTin = '',
            suffix = '',
            vcmId = '',
            dateOfBirth = '',
            countryOfCitizenship = '',
            emailInformation = {},
            addressInformation = [],
            phoneInformation = [],
        } = familyMemberInfo;
        
	const { primaryEmail = '', secondaryEmail = '' } = emailInformation || {};
	
	const primaryPhone = findPrimaryphone(phoneInformation);
	const secondaryPhone = findSecondaryphone(phoneInformation);
	
	const mailingAddress = findMailingAddresstype(addressInformation);
    const physicalAddress = findPhysicalAddresstype(addressInformation);
    
    backBtnProps.onClick = onBackClick(dispatch, history);
    backBtnProps['aria-label'] = "Back to basic information";
	
	return (
		<>
            {statusNotification}
			<Container>
					<WBreadCrumb
						breadCrumbItems={CONST.bredCrumItmes}
						activeCrumb={CONST.BREADCRUM_RELATION_NAME}
					/>
					<PersonalInfoView 
                            firstName={firstName}
                            middleInitial={middleInitial}
                            lastName={lastName}
                            citizenship={citizenship}
                            gender={gender}
                            maritalStatus={maritalStatus}
                            prefix={prefix}
                            ssnTin={ssnTin}
                            suffix={suffix}
                            vcmId={vcmId}
                            dateOfBirth={dateOfBirth}
                            countryOfCitizenship={countryOfCitizenship}
                            onManageClick={onManageClick(dispatch, history, routeConstants.relationshipPersonalInfo)}
                            pageTitle={CONST.RELATIOSHIP_MAIN_PAGE_NAME}
                            isPersonalRelation
                        />
                        <ContactInfoView 
                            mailingAddress={mailingAddress}
                            physicalAddress={physicalAddress}
                            onManageClick={onManageClick(dispatch, history, routeConstants.relationshipContactInfo)}
                        />
                        <PhoneInfoView
                            primaryPhone = {primaryPhone}
                            SecondaryPhone = {secondaryPhone}
                            onManageClick={onManageClick(dispatch, history, routeConstants.manageRelationPhone)}
                        />
                        <EmailInfoView
                            primaryEmail={primaryEmail}
                            secondaryEmail={secondaryEmail}
                            onManageClick={onManageClick(dispatch, history, routeConstants.relationshipemailInfo)}
                        />
                        <InstructionalPanel mb={1.5625} mbt={1.25}>
                          {CONST.SECURITY_REASONS}
                        </InstructionalPanel>
			</Container>
			<BottomNavBar
                hidePrimaryBtn
                backBtnProps={backBtnProps}
            />
		</>
	)
}

export default RelationshipManage