import React, {useRef, useState,useEffect} from 'react'
import Beneficiaries from './Beneficiaries'
import TrustedContact from './TrustedContact'
import '../MarketNAOStyles.css'
import { relationships, salutations, suffixes } from './consts'
import { mandatoryFieldlabel, mandatoryText } from'../consts'
import AddBankDetails from './AddBankDetails';
import { useSelector } from 'react-redux'

export default function AccountFeatures(props) {

    const initialState = {
      provideTrustedContacts: "",
      relationship: '',
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      DOB: '',
      gender: '',
      addressLine: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      stateTerritory: '',
      country: '',
      phoneCountryCode: '',
      phoneNumber: '',
      mobileNumber: '',
      emailAddress: ''
  }
  const memberDashboardData = useSelector(state => state.memberDashboardData)
  // if IRA account is selected then 3 steps else 2 steps for individual account 
  const isIRAAccountSelected = props.isIRAAccountSelected
  const hasFundingStep = memberDashboardData.isEnableFeatureSuccess && memberDashboardData.getEnabledFeature.features.includes('BankAccountFunding') ? true : false
  // const noOfSteps = isIRAAccountSelected ? 2 : 1
  let noOfSteps = 0;
  if(hasFundingStep && isIRAAccountSelected){
    noOfSteps = 3
  }
  else if(isIRAAccountSelected){
    noOfSteps = 2
  }
  else if(isIRAAccountSelected == false && hasFundingStep == true){
    noOfSteps = 2
  }
  else{
    noOfSteps = 1
  }
  const isButtonType = props.isButtonType == 'next' ? 0 : (noOfSteps - 1)
  const [state, setStateVariables] = useState(initialState);
  const [pageIndex, setpageIndex] = useState(isButtonType);


useEffect(() => {

  if(isIRAAccountSelected){
    if(props.subAHPageIndex == 'beneficiaries'){
      setpageIndex(0)
    }else if(props.subAHPageIndex == 'trustedContact') {
      setpageIndex(1)
    }
    else if(props.subAHPageIndex == 'edelivery') {
      setpageIndex(1)
    }
    else if(props.subAHPageIndex == 'addBankAccount') {
      setpageIndex(2)
    }
    // clear sub page value after setting page index
    props.setAHsubPageIndex('')
    
  }else{
    if(props.subAHPageIndex == 'trustedContact'){
      setpageIndex(0)
    }
    else if(props.subAHPageIndex == 'edelivery') {
      setpageIndex(0)
    }
    else if(props.subAHPageIndex == 'addBankAccount') {
      setpageIndex(1)
    }
    // clear sub page value after setting page index
    props.setAHsubPageIndex('')
  }
  
}, [props.subAHPageIndex])


  
  useEffect(() => {
      if (props.onEdited) {
        setpageIndex(1);
      }
      
      if (props.onEditedIRA) {
        setpageIndex(2);
      }
      props.onEdit(false)
      props.onEditIRA(false)
  }, [props.onEdited])
  

  // SCROLL TO TOP FUNCTION
    const scrollToTop = () => {
        setTimeout(() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        }))
    }

    useEffect(()=>{
      scrollToTop()
    },[pageIndex])

  
  let trustedContactRef = useRef({});


  const onSwitchComponent = bool => {


    if(pageIndex >= 0 && pageIndex < noOfSteps){ 
      if(bool){
        setpageIndex(pageIndex + 1);
      }else{
        setpageIndex(pageIndex - 1);
      }
    } else{
      const pageIndexValue = pageIndex;
      setpageIndex(pageIndexValue);
    }
  };

  // on next button click if there are internal step, complete them and later switch next section
  const clickNext = () => {
    if(pageIndex == (noOfSteps-1)){
      props.onNextClick()
    }else{
      onSwitchComponent(true)
    }
  };


  // on back button click if there are internal step, complete them and later switch next section
  const clickBack = () => {
    if(pageIndex == 0){
      props.onBackClick()
    }
    else
    {
      setpageIndex(pageIndex - 1);
    }

  };

  function stateHandler(x, y) {
      return setStateVariables(prevState => ({ ...prevState, [x]: y }));
  }

  function stateHandler2(x, y, z) {
      return setStateVariables(prevState => ({ ...prevState, x: y[z] }));
  }

  const handleChangeDropDown = (eventKey,e) => {
    const targetValue = e.target.value;
    if (eventKey == 'relationship') {
        stateHandler2(state.relationship, relationships, targetValue)
    } else if (eventKey == 'prefix') {
        stateHandler2(state.prefix, salutations, targetValue)
    } else if (eventKey == 'suffix') {
        stateHandler2(state.suffix, suffixes, targetValue)
    } else {
        stateHandler(eventKey, targetValue)
    }
};

  // loop through components/Steps 
  const bindComponent = () => {
    if(isIRAAccountSelected){
      switch (pageIndex) {
        case 0: {
          return (
            <Beneficiaries
            clickBack = {clickBack}
            clickNext = {clickNext}
            /> );
        }
        case 1: {
          return (
            <TrustedContact  
            handleChangeDropDown = {handleChangeDropDown}
            trustedContactRef = {trustedContactRef}
            clickBack = {clickBack}
            clickNext = {clickNext}
             />
          );
        }
        case 2: {
          return (
            <AddBankDetails  
            clickBack = {clickBack}
            clickNext = {clickNext}
             />
          );
        }
      
        default: {
            return <React.Fragment></React.Fragment>;
        }
    }
    }else{
      switch (pageIndex) {
        case 0: {
          return (
            <TrustedContact 
            handleChangeDropDown = {handleChangeDropDown}
            trustedContactRef = {trustedContactRef}
            clickBack = {clickBack}
            clickNext = {clickNext}
            />
          );
        }
        case 1: {
          return (
            <AddBankDetails  
            clickBack = {clickBack}
            clickNext = {clickNext}
             />
          );
        }
        default: {
            return <React.Fragment></React.Fragment>;
        }
      }
    }
    }
  
  return (  
    <div>
      <div className='banner step-banner'>
        <h6 className='white-text center'>Step {(pageIndex + 1)} of {noOfSteps}</h6>
        <p className='white-text center'>Please verify and complete your account features</p>
      </div>


      {(pageIndex + 1 == 1) ||  (pageIndex + 1 == 2 && noOfSteps ==3)? <div className='madatorytextstyle' >
        <span style={mandatoryFieldlabel} >
          {mandatoryText}
        </span>
      </div>:null}
      

      {bindComponent()}

    </div>
  )
}
