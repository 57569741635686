import { cloneDeep } from 'lodash';
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    accountsOpened: [],
    editAccount: {}
};


/**
*
* @param {Object} obj
* @returns {String} one of 'Paper' 'Online' 'Online & Paper'
*/
function deliveryPreference(obj) {
    const preferences = Object.values(obj);

    // 1. Delivery preference is set to just 'Paper'
    const paper = preferences.every(elem => elem.includes('P'));

    // 2. Delivery preference is set to just 'Online'
    const online = preferences.every(elem => elem.includes('E'));

    if (paper) return 'Paper';

    if (online) return 'Online';

    // 3. Delivery preference is set to both 'Online & Paper'
    return 'Online & Paper';
}

function accPreferenceReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOCAL_STATE: {
            return {
                ...state,
                ...action,
            };
        }

        case ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE: {
            return {
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }

        case ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_SUCCESS: {
            return {
                ...state,
                accountsOpened: state.accountsOpened.map(item => ({
                    ...item,
                    accounts: item.accounts.map(itm => {
                        const deliveryPreferences = action.payload.accounts.find(acc => acc.deliveryPreferences.groupId === itm.deliveryPreferences.groupId);
                        if (deliveryPreferences) {
                            return deliveryPreferences.deliveryPreferences.groupId === itm.deliveryPreferences.groupId ? {
                                ...itm,
                                ...deliveryPreferences
                            } : itm;
                        }
                        return itm;
                    })
                })),
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }

        case ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }

        case ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_LOADER: {
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }

        case ActionTypes.SAVE_OPENED_ACCOUNTS_PREFERENCE_FOR_ALL: {
            const accountsOpened = cloneDeep(state.accountsOpened);
            const changedAccountPreferences = accountsOpened.map(acc => ({
                ...acc,
                accounts: acc.accounts.map(item => ({
                    ...item,
                    deliveryPreference: deliveryPreference(action.payload.deliveryPreferences),
                    deliveryPreferences: action.payload.deliveryPreferences,
                }))
            }));
            return {
                ...state,
                accountsOpened: changedAccountPreferences
            };
        }

        case ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES: {
            return {
                isLoading: false,
                isError: false,
                isSuccess: false
            };
        }

        case ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }

        case ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_FAILURE: {
            return {
                ...action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }

        case ActionTypes.GET_OPENED_ACCOUNTS_PREFERENCES_LOADER: {
            return {
                ...action.payload,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }

        case ActionTypes.SET_ACCOUNT_PREFERENCES: {
            const account = state.accountsOpened.find((ac) => ac.accounts.find((acItem) => acItem.accNumber === action.payload.accountNumber)).accounts.find((accounts) => accounts.accNumber === action.payload.accountNumber);

            return {
                ...state,
                editAccount: {
                    ...account,
                    accountType: action.payload.accountType
                },
                isSuccess: false
            };
        }

        default:
            return state;
    }
}

export default accPreferenceReducer;