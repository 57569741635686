const data = {
    title: "Confirm One Time Passcode",
    getInfo: number => [`We have sent One Time Passcode to your register Mobile no ${number}`],
    wrongOtp: 'Incorrect passcode, please try again or request new passcode',
    unknownLogin: 'We have sent One Time Passcode to your mobile',
    resent: 'As requested, we have sent you a new one time passcode.',
    enterOtp: 'Enter One Time Passcode: ',
    proceed: ' to proceed.',
    pages: ['One Time Passcode Authentication', 'Confirm One Time Passcode', 'Setup Security Questions', 'Preference', 'Confirmation'],
    stepperDivStyle: {width: '100%'},
    inputCol: {paddingLeft: 0, paddingRight: 14},
    optionalMessage: '* All fields are required unless mentioned optional'
  }

  export default data