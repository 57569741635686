import React from 'react';
import PropTypes from 'prop-types';
import { truncate, isEmpty } from 'lodash';
import styled from 'styled-components';
import iconPdf from '../../../../assets/icon_pdf.png';
import iconAlertRed from '../../../../assets/icon-Alert-Red.svg'
import constant from '../AccountDashboardConstants'


const AchMsgListMain = styled.div`
            display: block;
            border-bottom: 1px solid rgba(210, 210, 210, 1);
            position: relative;
            padding: 15px 0px 15px 15px;
            &::before {
                position: absolute;
                content: '';
                display: inline-block;
                background: rgba(0, 74, 152, 1);
                height: 8px;
                width: 2px;
                padding-left: 8px;
                left: 0;
                top: 21px;
                border-radius: 50%;
            }
`;

const AchMsgListDate = styled.div`
            color:rgba(114, 114, 114, 1);
            font: 500 14px/22px benton-sans,sans-serif;
            margin-left:13px;
`;

const AchMsgListText = styled.div`
            color:rgba(73, 73, 74, 1);
            font: 600 15px/22px benton-sans,sans-serif;
            cursor:pointer;
            margin-left:13px;
            word-break: break-word;
`;

const AttachmentIcon = styled.img`
            width: 20px;
            height: 20px;
            margin-left: 5px;                           
`;


const UnReadSubjectDiv = styled.div`
    font: 600 15px/22px benton-sans,sans-serif;
    letter-spacing: 0;
    color:rgba(0, 74, 152, 1);
    opacity: 1;
    cursor:pointer;
    margin-left:13px;
    word-break: break-word;
`;

const ErrorDiv = styled.div`
    font: 500 16px/24px benton-sans,sans-serif;
    letter-spacing: 0;
    color:red;
    opacity: 1;
    cursor:pointer;
    margin-left:13px;
`;

const { assignObj } = constant

const MessageCenterList = (props) => {
    const { date, text, atch, handleTextClick, handlePdfClick, showAlertIcon, isRead, errorMsg } = props;
    const content = truncate((text), {
        'length': 60,
        'separator': ''
    })
    const altText = `Opens the ${content} PDF in a New window`;
    

    const getMessageContent = () => {
        return (
            <span className="anchorSpan" tabIndex="-1">
                <a
                    href="about:blank"
                    className="inlineLInk linkTestClr"
                    target="_self"
                    rel="noopener noreferrer"
                    tabIndex="0"
                    onClick={assignObj(e=>e.preventDefault())}
                >
                    {content}
                </a>
            </span>
        )
    }
    return (
        <AchMsgListMain role="listitem" className="items-list">
            <AchMsgListDate>
                {date}
                {atch ?
                    <a
                        href="about:blank"
                        className="inlineLInk linkTestClr"
                        target="_self"
                        rel="noopener noreferrer"
                        tabIndex="-1"
                        onClick={assignObj(e=>e.preventDefault())}
                    >
                        <AttachmentIcon src={iconPdf} alt={altText} tabIndex="0" onClick={handlePdfClick} style={assignObj({ cursor: 'pointer' })} />
                    </a> : ''}
                {showAlertIcon ? <AttachmentIcon src={iconAlertRed} alt="Urgent Message" /> : ''}
            </AchMsgListDate>
            {isRead ?
                <AchMsgListText onClick={handleTextClick} tabIndex="-1" >
                    {getMessageContent()}
                </AchMsgListText>
                :
                <UnReadSubjectDiv onClick={handleTextClick} tabIndex="-1">
                    {getMessageContent()}
                </UnReadSubjectDiv>
            }
            {!isEmpty(errorMsg) && <ErrorDiv>{errorMsg}</ErrorDiv>}

        </AchMsgListMain>
    );
};

MessageCenterList.propTypes = {
    date: PropTypes.string,
    text: PropTypes.string,
    atch: PropTypes.bool,
    handleTextClick: PropTypes.func,
    handlePdfClick: PropTypes.func,
    showAlertIcon: PropTypes.bool,
    isRead: PropTypes.bool,
    errorMsg: PropTypes.string
};

MessageCenterList.defaultProps = {
    date: '24/05/2020',
    text: 'This is the dummy text with link',
    atch: false,
    handleTextClick: () => { },
    handlePdfClick: () => { },
    showAlertIcon: false,
    isRead: true,
    errorMsg: ''
};

export default MessageCenterList;
