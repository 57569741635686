import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetPreRegistrationInfo } from '../Actions/AccountRegistrationActions';

const data = {
  title: 'Session Expired',
  para1: 'For your security, you will need to',
  para2: 'to complete the registration process.',
  tryAgain: 'try again',
}

const assignObj = (obj) => { return obj; }
class Signout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    const { resetInfo } = this.props;
    

    const token = window.localStorage.getItem('token');
    if (token) {
      this.setState({ token });
    }
    resetInfo();
  }

  render() {
    const { token } = this.state;
    return (
      <div className="success-title">
        <h1 className="pageHeading">{data.title}</h1>
        <p className="success-p explanationPara" style={assignObj({ textAlign: 'center' })}>
          {data.para1}
          {' '}
          {!isEmpty(token) ? (
            <Link to={`/accountRegistration/?param=${token}`} className="linkCls">
              <span>{data.tryAgain}</span>
            </Link>
          ) : (
              'try again'
            )}
          {' '}
          {data.para2}
        </p>
      </div>
    );
  }
}

Signout.propTypes = {
  resetInfo: PropTypes.func,
};

Signout.defaultProps = {
  resetInfo: () => { },
};

const mapDispatchToProps = (dispatch) => ({
  resetInfo: () => dispatch(resetPreRegistrationInfo()),
});
export default connect(null, mapDispatchToProps)(Signout);
