import { connect } from 'react-redux';
import { getCompositeLookUpData } from 'shared/Actions/AccOpeningActions';
import { updatePersonalInformation, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { withFormCancelDialog, withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent, linkResponseError } from 'shared/Actions/AnalyticsActions';
import PrimaryInfoComponent from './PrimaryInfoComponent';

export const mapStateToProps = (state) => {
    const { profileInformationData, masterLookUpData } = state;
    const { isError } = profileInformationData;

    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    getCompositeLookUpData,
    updatePersonalInformation,
    clearExistingActions,
    analyzeClickEvent,
    linkResponseError
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withNotification(withFormCancelDialog(PrimaryInfoComponent)));
