import React from "react";
import PropTypes from 'prop-types';
import editIcon from 'assets/EditPencil.svg';
import styled from 'styled-components';
import {
    formatPhoneNumber,
} from "utils";
import { FieldValue, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from 'modules/CustomerManagementModule/PhoneInformation/constants';

const StyledLine = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #49494A;
    margin: 0;
`;

const StyledEmptyValue = styled(StyledLine)`
    line-height: 24px;
`;


export const PrefilledContent = (props) => {
    const { number, type} = props;
    let content;
    if(number !== 'None On File'){
        content = <>
                    <StyledLine>{number}</StyledLine>
                    <StyledLine>{type}</StyledLine>
                 </>
    }else{
        content = <StyledEmptyValue>{CONST.NONEONFILE}</StyledEmptyValue>
    }
    return content;    
};

PrefilledContent.propTypes = {
    number: PropTypes.string,
    type: PropTypes.string,
};

PrefilledContent.defaultProps = {
    number: '',
    type: '',
};

const PhoneInfoView = (props) => {
    const { onManageClick, primaryPhone, SecondaryPhone, hideManage } = props;
    const { phoneNumber:primaryNumber = '', phoneCountryCode:primaryContryCode = '', isMobile:isMobilePrimary = false } = primaryPhone || {};
    const { phoneNumber:secondaryNumber = '', phoneCountryCode:secondaryContryCode = '', isMobile:isMobileSecondary = false } = SecondaryPhone || {};
    const formattedPrimaryPhone = formatPhoneNumber(primaryNumber, true, primaryContryCode);
    const primaryPhoneType =  isMobilePrimary ? CONST.MOBILE : CONST.NON_MOBILE;
    const formattedSecondaryPhone = formatPhoneNumber(secondaryNumber, true, secondaryContryCode);
    const secondaryPhoneType =  isMobileSecondary ? CONST.MOBILE : CONST.NON_MOBILE;
    return(
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.PHONE_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_PHONE}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='1.45'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.PRIMARY_PHONE_NUMBER}
                    alignItems="stretch"
                    value={
                        <PrefilledContent
                            number={checkValues(formattedPrimaryPhone)}
                            type={primaryPhoneType}
                        />
                    }
                    mb='1.7'
                    labelsm={3}
                    valuesm={5}
                />
                <FieldValue
                    as="p"
                    label={CONST.SECONDARY_PHONE_NUMBER}
                    alignItems="stretch"
                    value={
                        <PrefilledContent
                            number={checkValues(formattedSecondaryPhone)}
                            type={secondaryPhoneType}
                        />
                    }
                    mb='2.5'
                    labelsm={3}
                    valuesm={5}
                />
            </>
        );
}

PhoneInfoView.propTypes = {
    onManageClick: PropTypes.func,
    primaryPhone: PropTypes.instanceOf(Object),
    SecondaryPhone: PropTypes.instanceOf(Object),
    hideManage: PropTypes.bool,
}

PhoneInfoView.defaultProps = {
    onManageClick: () => {},
    primaryPhone: {},
    SecondaryPhone: {},
    hideManage:false,
}

export default PhoneInfoView;