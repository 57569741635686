import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    selectedItem:{},
    account:{},
    fund:{},
    tax:{},
    bank:{},
    schedule:{},
};

function initialReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.INVEST_WITHDRAW_SAVE_ITEM:{
            return{
                ...state,
                selectedItem:action.data
            };
        }

        case ActionTypes.INVEST_WITHDRAW_SAVE_ACCOUNT:{
            return{
                ...state,
                account:action.data
            };
        }
        case ActionTypes.INVEST_WITHDRAW_SAVE_FUND:{
            return{
                ...state,
                fund:action.data
            };
        }
        case ActionTypes.INVEST_WITHDRAW_SAVE_BANK:{
            return{
                ...state,
                bank:action.data
            };
        }
        case ActionTypes.INVEST_WITHDRAW_SAVE_TAX:{
            return{
                ...state,
                tax:action.data
            };
        }
        case ActionTypes.INVEST_WITHDRAW_SAVE_SCHEDULE:{
            return{
                ...state,
                schedule:action.data
            };
        }
        case ActionTypes.RESET_INVEST_WITHDRAW_DATA: {
            return {
              ...initialState
            };
          }
        default:
            return state;
    }
}

export default initialReducer;