import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

export const addBankAccountAction = data => (dispatch) => {
    const payload = {
      ...data
    };

    const env = 'ADDBANKACCOUNT';

    dispatch({
      type :  ActionTypes.ADD_BANK_ACCOUNT_LOADER,
      isloading : true
    });

    makeApiRequest(
      ActionTypes.ADD_BANK_ACCOUNT,
      payload,
      env,
      response => {
    dispatch({
        type :  ActionTypes.ADD_BANK_ACCOUNT_SUCCESS,
        [ActionTypes.ADD_BANK_ACCOUNT] :response.data
      });
    },
      error => {
        dispatch({
          type :  ActionTypes.ADD_BANK_ACCOUNT_ERROR,
          data: error
        });
      }
    );
  };

  export const toBeRemoved = () => () => {
  };

