import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routeConstants from '../../routeConstants';
import { manageLocalState } from '../../shared/Actions/LocalStateManagementActions';

const withAccountManagement = AccOpeningComponent => props => {
   // const { localStateData } = props;
   // const { accSelected } = localStateData;
    const history = useHistory();
    // Dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.uuid) {
            dispatch(manageLocalState({ uuid: props.location.state.uuid }));
            history.push({ pathname: routeConstants.marketAccountType });
        } else if (props.location && props.location.state && props.location.state.sessionId) {
            history.push({ pathname: props.location.pathname });
        } 
        else if(props?.location?.pathname == "/marketAccountType")
        {
            history.push({ pathname: routeConstants.marketAccountType });
        }
        // else if (!accSelected && window.location.pathname !== routeConstants.openAccount) {
        //     history.push({ pathname: routeConstants.openAccount });
        // }
    }, [history]);

    return (
        <AccOpeningComponent {...props} uuid={props.location && props.location.state && props.location.state.uuid} />
    );
};
export default withAccountManagement;
