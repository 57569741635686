/**
 * Interested Parties Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to manage interested parties
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { WBreadCrumb, WSpinner, WErrorModal } from '../../../common';
import consts from './consts';
import AccountInfoWrapper from './AccountInfoWrapper';

const PageWrapper = styled.div`
    min-height: 80vh;
    position: relative;
    padding: 0 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    .card:first-child {
        border: none;
    }
`;

const PageHeading = styled.h1`
    margin-top: 30px;
    margin-bottom: 50px;
    padding-bottom: 10px;
    width: 385px;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 700 30px/45px yorkten-slab-normal;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
`;

const Cardd = styled(Card)`
    > .card-header {
        background-color: #ffffff;
    }
`;
const SectionHeading = styled.span`
    text-align: left;
    font: 700 22px/35px Yorkten Slab;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    padding-left: 10px;
`;
const Optionaltext = styled.span`
    text-align: left;
    font: Bold 12px/22px benton-sans;
    letter-spacing: 0;
    color: #698aac;
    opacity: 1;
    padding-left: 16px;
`;

const CardBody = styled(Card.Body)`
    padding-bottom: 25px;
    .card {
        border: none;
    }
`;

export default class InterestedParties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            onSubmitFlag: false,
            add: false,
        };
        this.err = '';
    }

    transitionTo = () => {
        this.setState({ onSubmitFlag: true });
    };

    generateKey = () => {
        return Math.floor(Math.random() * (10000 - 100 + 1)) + 100;
    };

    addClick = () => {
        this.setState({ add: true });
    };

    renderContent = () => {
        return consts.accounts.map(account => {
            return (
                <Accordion defaultActiveKey="0">
                    <Cardd>
                        <Card.Header>
                            <Accordion.Toggle as="h4" variant="link" eventKey="0" className="expand" style={{ cursor: 'pointer' }}>
                                <SectionHeading>{account.accountType}</SectionHeading>
                                {account.subText && <Optionaltext>{account.subText}</Optionaltext>}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0" id="cardBody">
                            <CardBody>
                                {account.accountsUnder.map(eachAccount => (
                                    <AccountInfoWrapper account={eachAccount} handleClick={this.addClick} />
                                ))}
                            </CardBody>
                        </Accordion.Collapse>
                    </Cardd>
                </Accordion>
            );
        });
    };

    renderPage = () => {
        return (
            <>
                <PageWrapper>
                    <div className="container">
                        <WBreadCrumb
                            breadCrumbItems={consts.generalPreferencesUrls}
                            activeCrumb={consts.activeGeneralUrl}
                        />
                        <PageHeading>{consts.pageHeading}</PageHeading>
                        {this.renderContent()}
                    </div>
                </PageWrapper>
                <hr />
            </>
        );
    };

    render() {
        const { loading, error, onSubmitFlag, add } = this.state;
        return (
            <div className="MainContainer">
                {<WSpinner loading={loading} />}
                {error && <WErrorModal show={error} message="Technical error" />}
                {!error && this.renderPage()}
                {add && <Redirect to="/addInterestedParty" />}
                {onSubmitFlag ? <Redirect to="/editPreference" /> : ''}
            </div>
        );
    }
}

InterestedParties.propTypes = {};

InterestedParties.defaultProps = {};
