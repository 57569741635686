import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {localStateManagementActions} from '../../../../shared/Actions';
import JovMobile from './JovMobile';

const mapStateToProps = (state) => (
    {
        jovSsnState: state.localStateManagementReducerData.jovSsnState
    }
);

const mapDispatchToProps = {
    ...localStateManagementActions
};

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(JovMobile));