import resendIcon from 'assets/icon_resend.svg';
import styled from 'styled-components';

const SecurityCodeInfo = styled.span`
    margin-bottom: 1.875em;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    color: #4b4b4b;
    display: block;
    padding: 20px;
    margin-top: 2.5em;
    &::before{
        content: "";
        width: 0;
        height: 0;
        border-bottom: 19px solid transparent;
        border-left: 19px solid #8bc105;
        position: absolute;
        top: 41px;
        left: 15px;
    }
`;
const VerifyPhoneRow = styled.div`
    border: 1px solid #d2d2d2;
    padding: 25px;
    position: relative;
    &::after{
        content: "";
        width: 0;
        height: 0;
        border-bottom: 30px solid transparent;
        border-left: 30px solid #d2d2d2;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const data = {
    title: 'Review / Update Profile Information',
    info: ['We need a few minutes of your time to verify your information so that we can contact you'],
    style: {
        button: {
            float: 'right',
            font: '600 12px/14px benton-sans',
            color: '#004A98',
            padding: '0 0 2px 24px',
            background: `white url(${resendIcon}) no-repeat left bottom`,
            border: 'none',
        },
    },
    resendText: 'Resend Security Code',
    resendCLass: ' enterOtp',
    securityCodeSent: 'You will be receiving an email from our Security Partner, GIACT, which contains a security code you will need to continue. If you do not receive the email, verify the email you have entered is correct.',
    enterSecurityCode: ', Enter the Security Code below.',
    primaryEmail: 'Primary Email Address',
    primaryPhone: 'primaryPhone',
    EMAIL: 'EMAIL',
    PrimaryPhoneNumber: 'Primary Phone Number',
    SecondaryPhoneNumber: 'Secondary Phone Number [Optional]',
};

export const errorCodeMessageMapping = {
    2004: 'Please try generating new Security Code after an hour', //validate passcode
    2005: 'We can not process your request at this time. Please try again after 24 hours.',//validatepasscode
    2006: 'Unable to Process your request at this time. Please try again.',
    2007: 'Member Not verified, Please do the verification',
    2008: 'Please generate a new Security Code.',//validatepasscode
    2009: 'Error while verifying the status',//ivr
    2010: 'Error while confirming security code',
    2015: 'Incorrect passcode, please try again or request a new passcode',//validatepasscode
    // 2020: 'Member Already Registered',
    // 2021: 'Error fetching member Info',
    // 2022: 'USAA Member match not found',
    // 2023: "User doesn't exist.",
    // 2025: 'User ID already exists',
    2026: 'OTP not verified or member already registered',
    2027: 'Please generate a new Security Code.',
    2028: 'Incorrect Security code, please try again or request new Security code',//validatepasscode
  };

  const emailOTPError = 'Incorrect Security code, please try again or request new Security code';
const assignObj = (obj) => { return obj; }

export { VerifyPhoneRow, SecurityCodeInfo, data, assignObj, emailOTPError };