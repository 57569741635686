import {
    focusToNonFocusableEl
} from '../../modules/CustomerManagementModule/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { STEP_KEY } from 'utils/AdobeAnalytics/Constants';
import styled from 'styled-components';
import styles from './Styles';

const StyledP = styled.p`
    @media (max-width: 767px) {
        padding-left: 0px !important;
        font-weight: 400 !important;
        font-size:18px!important;
        line-height: 24px!important;
    }
`;

const StyledAlertDiv = styled.div`
    @media (max-width: 767px) {
        max-height: unset !important;
        padding: 30px 0px !important;
        background:#F4F8EC!important;
    }
    .messageBarDiv{
        @media (max-width: 767px) {
            padding-left:20px;
        }
    }
`;

const StyledCloseBtnClose = styled(Col)`
    @media (max-width: 767px) {
        margin-top: 20px;
    }
`;

const StyledButton = styled.button`
    @media (max-width: 767px) {
        width: 40px !important;
        height: 40px !important;
    }
`;

const StyledCrossDiv = styled.div`
    @media (max-width: 767px) {
        left: 10px !important;
        top: 17px !important;
    }
`;

const CLOSE_BY_TIMER = 'closeByTimer';
class WMessageBar extends Component {
    constructor(props) {
        super(props);
        this.messageContainerRef = React.createRef();
        this.state = {
            close: false,
            time: '30s',
        };
        this.timerId = null;  
    }

    componentDidMount() {
        let time = 30;
        const { text, doNotClose, analyticsPageName } = this.props;
        document.getElementById('statusMsgClose').focus();
        if (text && !doNotClose) {
            this.timerId = setInterval(() => {
                time -= 1;
                if (time) {
                    this.setState(state => ({ ...state, time: `${time}s` }));
                } else {
                    this.closeHandler(CLOSE_BY_TIMER);
                }
            }, 1000);
        }
        sessionStorage.setItem(STEP_KEY, analyticsPageName);
        focusToNonFocusableEl(this.messageContainerRef.current);
    }

    componentDidUpdate(prevProps) {
        const { text } = this.props;
        const { text: prevText = null } = prevProps;
        if (prevText !== text) {
            focusToNonFocusableEl(this.messageContainerRef.current);
        }
    }

    componentWillUnmount() {
        if (this.timerId) clearInterval(this.timerId);
    }

    closeHandler = isClosedByTimer => {
        const { onClose, focusfrom } = this.props;
        clearInterval(this.timerId);
        this.timerId = null;
        this.setState(
            state => ({ ...state, close: true }),
            () => onClose(isClosedByTimer === CLOSE_BY_TIMER), // To notify the parent component is notification is closed or not
        );
        if (focusfrom) {
            document.getElementById(focusfrom).focus();
        } else if (document.getElementById('skipToMain')) {
            document.getElementById('skipToMain').focus();
        }
    };

    render() {
        const { close, time } = this.state;
        const { text, error, doNotClose, optionalZIndex } = this.props;

        const mainStyles = optionalZIndex
            ? {
                  ...styles.main,
                  zIndex: optionalZIndex,
              }
            : styles.main;

        const mainErrorStyles = optionalZIndex
            ? {
                  ...styles.mainError,
                  zIndex: optionalZIndex,
              }
            : styles.mainError;

        const snapErrorText =
            'We are experiencing technical difficulties. We are actively working to resolve the issue.';
        let messageBar = text ? (
            <div style={styles.relative}>
                <StyledAlertDiv style={error ? mainErrorStyles : mainStyles}>
                    <div className="container messageBarDiv" style={styles.container}>
                        <Row>
                            <Col xs={9} sm={9} md={11} lg={11} xl={11} ref={this.messageContainerRef}>
                                <Row style={styles.margin}>
                                    <StyledP style={styles.message}>{text.message || snapErrorText}</StyledP>
                                </Row>
                                {text.info ? (
                                    <Row style={styles.margin}>
                                        <p style={styles.info}>{text.info}</p>
                                    </Row>
                                ) : null}
                            </Col>
                            <StyledCloseBtnClose xs={3} sm={3} md={1} lg={1} xl={1}>
                                <div style={text.info ? styles.timer1 : styles.timer2}>
                                    {!doNotClose && <div style={styles.time}>{time}</div>}
                                    <StyledButton
                                        type="button"
                                        aria-label="Close status message"
                                        id="statusMsgClose"
                                        onClick={this.closeHandler}
                                        style={styles.closeButton}
                                    >
                                        <div style={styles.cross}>
                                            <StyledCrossDiv style={styles.crossAfter} />
                                            <StyledCrossDiv style={styles.crossBefore} />
                                        </div>
                                    </StyledButton>
                                </div>
                            </StyledCloseBtnClose>
                        </Row>
                    </div>
                </StyledAlertDiv>
            </div>
        ) : null;

        if (close) {
            messageBar = null;
        }
        return messageBar;
    }
}

WMessageBar.propTypes = {
    text: PropTypes.shape({
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    error: PropTypes.bool,
    onClose: PropTypes.func,
    focusfrom: PropTypes.string, // if alert showed on the same page, the element which called this alert should be passed
    doNotClose: PropTypes.bool,
    analyticsPageName: PropTypes.string,
    optionalZIndex: PropTypes.number,
};

WMessageBar.defaultProps = {
    text: {
        message: null,
        info: null,
    },
    error: false,
    onClose: () => {},
    focusfrom: '',
    doNotClose: false,
    analyticsPageName: '',
    optionalZIndex: null,
};

export default WMessageBar;
