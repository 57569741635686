import React, { Component } from 'react';
import PropTypes from 'prop-types'; // , { array }
import styled from 'styled-components';
import { Accordian, PlanInfoCardTitle } from '../../../common';
import AddedAmendCardBody from '../../../common/AmendCard/AddedAmendCardBody';
import constAmend from './constAmend';
import editIcon from '../../../assets/EditPencil.svg';
import deleteIcon from '../../../assets/deleteTrash.svg';

const Flex = styled.div`
    display: flex;
`;

const Menu = styled.div`
    position: relative;
    &::after {
        content: '\\2807';
        color: #004a98;
        font-size: 27px;
    }
`;
const MenuOptionsPopup = styled.div`
    cursor: pointer;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;

    top: 2.5rem;
    left: -4.375rem;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 10px solid #ffffff;
        top: -0.625rem;
        left: 4.375rem;
    }
`;
const MenuOptions = styled.div`
    margin-left: 0.3125rem;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;

const MenuItem = styled(Flex)`
    display: flex;
    cursor: pointer;
    padding: 20px;
`;
const MenuIcon = styled.span`
    width: 1rem;
    height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
`;
const MenuOptionsBorder = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    margin-right: 20px;
`;
const StyledAccordianCard = styled(Accordian)`
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #486d90;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

class AmendTransactionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountsAvailable: [],
            checkboxRequired: true,
            menuArray: [],
        };
    }

    componentDidMount() {
        const { accountsAvailable, error } = this.props;
        window.addEventListener('click', this.handleOutsideClick);
        this.setState({
            accountsAvailable,
        });
        if (error) {
            throw new Error('Network Error...');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick);
    }

    onMenuClick = accIndex => e => {
        const { menuArray, accountsAvailable } = this.state;
        e.stopPropagation();
        if (menuArray.length > 0) {
            this.handleOutsideClick();
            return;
        }
        const temp = [];
        const l = accountsAvailable.length;
        for (let i = 0; i < l; i += 1) {
            temp[+i] = false;
        }
        temp[+accIndex] = true;
        this.setState({
            menuArray: temp,
        });
    };

    handleOutsideClick = () => {
        this.setState({ menuArray: [] });
    };

    onAmendMenuClick = (accIndex, orderId) => e => {
        const { amendMenuClick } = this.props;
        e.stopPropagation();
        amendMenuClick(accIndex, orderId);
        this.handleOutsideClick();
    };

    onCancelMenuClick = (accIndex, orderId) => e => {
        const { accountsAvailable } = this.state;
        const { cancelMenuClick } = this.props;
        e.stopPropagation();
        cancelMenuClick(accIndex, orderId, accountsAvailable[+accIndex]);
        this.handleOutsideClick();
    };

    render() {
        const { accountsAvailable, checkboxRequired, menuArray } = this.state;
        const showImgBool = true;
        return (
            <>
                {accountsAvailable.map((account, accIndex) => (
                    <StyledAccordianCard
                        key={account.key}
                        titleChild={
                            <PlanInfoCardTitle showImg={showImgBool} accNumber={account.title}>
                                <Menu onClick={this.onMenuClick(accIndex)}>
                                    {menuArray[+accIndex] && (
                                        <MenuOptionsPopup>
                                            <MenuItem onClick={this.onAmendMenuClick(accIndex, account.title)}>
                                                <MenuIcon url={editIcon} />
                                                <MenuOptions>{constAmend.menuOptions[0]}</MenuOptions>
                                            </MenuItem>
                                            <MenuOptionsBorder />
                                            <MenuItem onClick={this.onCancelMenuClick(accIndex, account.title)}>
                                                <MenuIcon url={deleteIcon} />
                                                <MenuOptions>{constAmend.menuOptions[1]}</MenuOptions>
                                            </MenuItem>
                                        </MenuOptionsPopup>
                                    )}
                                </Menu>
                            </PlanInfoCardTitle>
                        }
                    >
                        <AddedAmendCardBody
                            accountName={account.data.selectedAccountData.accountName}
                            accountNumber={account.data.selectedAccountData.accountNumber}
                            share={account.data.selectedFundData.fundName}
                            addedDate={account.data.Dateadded}
                            currentValue={account.data.selectedAccountData.currentValue}
                            transactionType={account.data.TransactionType}
                            paymentMode={account.data.selectedFundSourceData.paymentMode}
                            orderStatus={account.data.OrderStatus}
                            checkboxRequired={checkboxRequired}
                        />
                    </StyledAccordianCard>
                ))}
            </>
        );
    }
}

AmendTransactionCard.propTypes = {
    // accountsAvailable: PropTypes.array.isRequired, // changed this for lint purpose
    accountsAvailable: PropTypes.instanceOf(Array).isRequired,
    amendMenuClick: PropTypes.func.isRequired,
    cancelMenuClick: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
};

export default AmendTransactionCard;
