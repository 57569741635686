/* eslint-disable import/prefer-default-export */
import {
  SET_E_SIGNATURE,
  SET_RESET_WELCOMPAGE_INFO,
} from 'shared/ReduxConstants/AccountRegistrationConstants';

export const storeESignature = (data) => (dispatch) => {
  if (data !== '') {
    dispatch({
      type: SET_E_SIGNATURE,
      payload: {
        ...data,
      },
    });
  }
};

export const resetWelcomPageFlag = () => (dispatch) => {
  // dispatchWithData(SET_RESET_WELCOMPAGE_INFO)(dispatch);
  dispatch({
    type: SET_RESET_WELCOMPAGE_INFO,
  });
};
