import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    accounts: [],
    editStatus: "",
};

function manageAccountPreferenceReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_ACCOUNT_PREFERENCE_LOADER: {
            return { ...state, ...action, isLoading: true,isError: false,isSuccess:false};
        }
        case ActionTypes.GET_ACCOUNT_PREFERENCE_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isError: false,isSuccess:true};
        }
        case ActionTypes.GET_ACCOUNT_PREFERENCE_FAILURE: {
            return { ...state, ...action, isLoading: false,isSuccess:false, isError: true };
        }
        case ActionTypes.GET_ACCOUNT_PREFERENCE_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess:false,isError: true };
        }
        case ActionTypes.EDIT_ACCOUNT_PREFERENCE_LOADER: {
            return { ...state, ...action, isLoading: true,isError: false,isSuccess:false};
        }
        case ActionTypes.EDIT_ACCOUNT_PREFERENCE_SUCCESS: {
            return { ...state, ...action, isLoading: false ,isError: false,isSuccess:true};
        }
        case ActionTypes.EDIT_ACCOUNT_PREFERENCE_FAILURE: {
            return { ...state, ...action, isLoading: false,isSuccess:false, isError: true };
        }
        case ActionTypes.EDIT_ACCOUNT_PREFERENCE_ERROR: {
            return { ...state, ...action, isLoading: false, isSuccess:false,isError: true };
        }
        case ActionTypes.EDIT_ACCOUNT_PREFERENCE_INITIAL_STATE: {
            return { ...state, ...action, isLoading: false,isError: false,isSuccess:false, editStatus: "", error: {}};
        }
        default:
            return state;
    }
}

export default manageAccountPreferenceReducer;
