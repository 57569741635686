import '../MarketNAOStyles.css'
import { Col, Row } from 'react-bootstrap';
import { Accordian, AccordianTitle,WIcon} from 'common';
import editIcon from '../../../../assets/EditPencil.svg';
import React, { useState, useEffect , useRef} from 'react';
import {useSelector} from  "react-redux";
import TrustedContact from '../AccountFeatures/TrustedContact';
import {  MessagePopup } from 'modules/CustomerManagementModule/components';
import { E_Delivery_Msg }from '../AccountFeatures/consts'
import useAutoFocus from './useAutoFocus'

   //For Avoiding div repeatations
   const DefaultLinesReducer = (verifyHeading, verifyValue) => {
    return (
      <div className="card-list-wrapper">
        <div class="row">
          <div class="label-key col-lg-4 col-md-4 col-sm-12" >
            <span>{verifyHeading}</span>
          </div>
          <div className="label-DefaultValue col-6 col-lg-6"><span>{verifyValue}</span></div>
        </div>
      </div>
    )
  }


const linesReducer = (verifyHeading, verifyValue) => {
  return (
    <div className="card-list-wrapper">
      <div class="row">
        <div class="label-key col-lg-4 col-md-4 col-sm-12 col-12">
          <span>{verifyHeading}</span>
        </div>
        <div class="label-value col-8"><span>{verifyValue}</span></div>
      </div>
    </div>
  )
}

const VerifyAccountFeatures=(props)=>{
      const assignObj = obj => {
    return obj;
};

  const formatAddress = (address) => {

    const { addressLine1, addressLine2, city, state, zipCode } = address

    if ((addressLine1 && addressLine2 == "")) {
      return "-"
    }

    let addressFormatter = ""

    if (addressLine1 == "") {
      addressFormatter = <span>{addressLine2},{city},{state} {zipCode}</span>
    } else if (addressLine2 == "") {
      addressFormatter = <span>{addressLine1},{city},{state} {zipCode}</span>
    } else {
      addressFormatter = <span>{addressLine2},{addressLine1},{city},{state} {zipCode}</span>
    }

    return addressFormatter

  }


 //from Redux state
     const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData?.marketNAOInfo);
      const trustedContact=marketNAOInfoReducerData?.trustedContact
       const Delivery = marketNAOInfoReducerData.deliveryPreferences

  let isoDate = trustedContact.dob;
  let dateUS = new Date(isoDate);
  const date=dateUS.toLocaleDateString('en-US',{year:"numeric",month:"2-digit",day:"2-digit"});
 
 


  //Trusted contact hide state
  const [TrustedContactVerifyPageHide, setTrustedContactVerifyPageHide] = useState(false)
  //e delivery message pop up functionality
  const [deliveryPopUp, setDeliveryPopUp] = useState(false)


   
//focus sections
  const trustedContactRef=useRef(null)
  useAutoFocus(TrustedContactVerifyPageHide,trustedContactRef)


  
 



  const accountfeatureHide = TrustedContactVerifyPageHide 
   useEffect(() => {
    props.setEditDisabler(!accountfeatureHide)
  }, [accountfeatureHide])


return (
        <>
    <MessagePopup show={deliveryPopUp} modalBodyText={E_Delivery_Msg} secondaryButtonText="Ok" onSecondaryClick={() => setDeliveryPopUp(!deliveryPopUp)} />
    {TrustedContactVerifyPageHide ?
      <div> <TrustedContact TrustedContactVerifyPageHide={TrustedContactVerifyPageHide} AfterEditVerifyHide={(val) => setTrustedContactVerifyPageHide(val)} /></div> : <div>
        <Accordian
        accordianPaddingLeft={0}
        id={'TrustedContact'}
        titleChild={
          <Row style={{ display: "flex", justifyContent: "space-between" }} ref={trustedContactRef} tabIndex="-1">
            <Col  md={10} xl={11}>
          <AccordianTitle
            accType={'Trusted Contact'}
            className="accordionH2Style"
            requireButtonPadding={false}
          /></Col>

        
          <Col md={2} xl={1} className="sum-prelink">
              {props.editDisabler && props.showEditButton&&<Row noGutters>
                        <WIcon
                            alt=""
                            src={editIcon}
                            size={"1px"}
                            className="editIcon"
                            fontawesomestyle={assignObj({ paddingLeft: 0 })}
                        />
                <button type="button" className="sum-linktext mt-1" onClick={() => setTrustedContactVerifyPageHide(true)}>
                          <span>Edit</span>
                        </button>
                    </Row>}
                </Col>
                
        
        </Row>
        }
        titleSeperator
      >

        <div className="sum-card-wrap">
         {linesReducer("Relationship",  trustedContact.relationship == "" ? "-" : <span>{trustedContact.relationship}</span>)} 
         {linesReducer("Name",trustedContact?.firstName == "" && trustedContact?.lastName == "" ? "-" : <span>{trustedContact?.prefix} {trustedContact?.firstName} {trustedContact?.lastName}</span>)} 
         {linesReducer("Date of Birth", date=="Invalid Date"?"-":date)} 
         {linesReducer("Gender",trustedContact.gender == "" ? "-" : trustedContact.gender)} 
         {linesReducer("Primary Phone", trustedContact.primary.number == "" ? "-" : <span>{trustedContact.primary.countryCode} {trustedContact.primary.number}</span>)} 
         {linesReducer("Primary Email",trustedContact.email == "" ? "-" : trustedContact.email)} 
         {linesReducer("Address",formatAddress(trustedContact.address)) } 
         
        </div>

      </Accordian>
           </div>}
    <Row>
      <Col md={12} xl={12} style={{position: 'relative'}}>
      <hr className='borderforverify'></hr>
      <Row>
      <Col md={10} xl={11}>
        <Accordian
          accordianPaddingLeft={0}
          id={"Delivery Preferences"}
          titleChild={
            <Row>
              <Col md={12} xl={12}>
                <AccordianTitle
                  accType={'Delivery Preferences'}
                  className="accordionH2Style"
                  requireButtonPadding={false}
                />
              </Col>
            </Row>
          }>
          <div className="sum-card-wrap mt-4">
            {DefaultLinesReducer("Confirms", Delivery.confirms == "true" ? "ELECTRONIC" : "PAPER")}
            {DefaultLinesReducer("Notifications", Delivery.notifications == "true" ? "ELECTRONIC" : "PAPER")}
            {DefaultLinesReducer("Statements", Delivery.statements == "true" ? "ELECTRONIC" : "PAPER")}
            {DefaultLinesReducer("Proxy", Delivery.proxy == "true" ? "ELECTRONIC" : "PAPER")}
            {DefaultLinesReducer("Prospectus", Delivery.prospectus == "true" ? "ELECTRONIC" : "PAPER")}
          </div> 
        </Accordian>
      </Col>
      <Col md={2} xl={1} className="sum-prelink">
        {props.editDisabler && props.showEditButton &&
          <Row noGutters>
            <WIcon
              src={editIcon}
              size={"1px"}
              className="editIcon"
              fontawesomestyle={assignObj({ paddingLeft: 0 })}
            />
            <button type="button" className="sum-linktext mt-1" onClick={() => setDeliveryPopUp(!deliveryPopUp)}>
              <span>Edit</span>
            </button>
          </Row>
        }
      </Col>
      </Row>
      </Col>
    </Row>
      </>

   )}

   export default VerifyAccountFeatures