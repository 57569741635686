/**
 * Automatic Investment Plans
 ********************************
 *
 * @version 1.0.0
 * @author Dinesh A
 * @description Automatic Investment Plans are displayed and managed here
 * @createdDate [10/12/2019]
 * ***************************************
 */

import AddIcon from 'assets/add.svg';
import BackBtn from 'modules/CustomerManagementModule/components/BackBtn/index';
import {
    CommonButtons,
    WBreadCrumb,
    PageHeading,
    WMessageBar,
    WSpinner,
    Accordian,
    AccordianTitle,
    PlanInfoCardTitle,
    StyledButton,
    WErrorModal,
    KebabMenu,
    ConfirmModal,
} from 'common';
import constants from 'modules/AccountOptions/constant';
import deleteIcon from 'assets/deleteTrash.svg';
import DisabledDeleteIcon from 'assets/DeleteDisabled.png';
import DisabledEditIcon from 'assets/EditDisabled.png';
import editIcon from 'assets/EditPencil.svg';
import { get } from 'lodash';
import InstructionAIP from 'modules/AccountOptions/Common/Instructions';
import { isValidDate, createBreadCrumbItem } from 'utils';
import PlanInfoCardBody from '../PlanInfoCardBody';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RECURRING_TRANSFER_PLAN, KEY_CODE } from '../../constant';
import routeConstants from 'routeConstants';
import routesBreadCrumb from '../../../../utils/Constants/routesBreadcrumb';
import SkipPlan from '../SkipPlan';
import * as TransactionConstants from '../../../TransactionModule/TransactionConstants';
import {
    STEP_KEY,
    AIP_INITIAL_PAGE_NAME,
    SWP_INITIAL_PAGE_NAME,
    AIP_HOME_NAV_BUTTON_ID,
    SWP_HOME_NAV_BUTTON_ID,
    AIP_ADD_BUTTON,
    SWP_ADD_BUTTON,
    AIP_EDIT_BUTTON,
    SWP_EDIT_BUTTON,
    AIP_DELETE_BUTTON,
    SWP_DELETE_BUTTON,
    AIP_DELETE_PAGE_NAME,
    SWP_DELETE_PAGE_NAME,
    ACCOUNT_SERVICE_TYPE_AIP,
    ACCOUNT_SERVICE_TYPE_SWP,
    ACCOUNT_FLOW_TYPE_AIP_DELETE,
    ACCOUNT_FLOW_TYPE_SWP_DELETE,
    BREAD_CRUMB_SCREEN_AIP,
    BREAD_CRUMB_SCREEN_SWP,
} from '../../../../utils/AdobeAnalytics/Constants';
import styled from 'styled-components';
import { ConfirmationMessage } from 'modules/CustomerManagementModule/components';
import { isTokenAvailable } from 'common/Authenticate/session';
import Authenticate from 'common/Authenticate/Authenticate';
import {currencyFormatterFunction} from 'common/Formatter/CurrencyFormatter';
import { getSessionValues } from 'shared/Helpers/Utils';
const NO_BANK_ACCOUNTS= 'No bank on file.  Please direct investor to add their bank online or submit the "Automatic Investment Plan Form."'
const NOTIFICATION_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
};

const Flex = styled.div`
    display: flex;
`;
const AddSection = styled(StyledButton)`
    display: flex;
    align-self: center;
`;

// Description not needed for now
// const AccountDescription = styled.div`
//     margin-bottom: 1.875rem;
//     text-align: left;
//     font-family: benton-sans, sans-serif;
//     font-weight: 600;
//     font-style: normal;
//     font-size: 16px;
//     line-height: 24px;
//     letter-spacing: 0;
//     color: #49494a;
//     opacity: 1;
// `;
export const MenuIcon = styled.span`
    width: 1rem;
    height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
`;
const FlexDiv = styled(Flex)`
    justify-content: space-between;
    margin: 0 0 2.5rem 0;
`;
const AddText = styled.span`
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;
AddText.displayName = 'AddText';
const AddSymbolImg = styled.img`
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
`;
const StyledBackBtn = styled.div`
    margin-bottom: 40px;
`;
const NoPlansAvailable = styled.div`
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #e6e6e6;
    opacity: 1;
    text-align: center;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0;
    color: #49494a;
    height: 9.375rem;
    margin-bottom: 3.125rem;
    vertical-align: middle;
    line-height: 9.375rem;
    @media (max-width: 767px) {
        display: flex !important;
        align-items: center !important;
        line-height: 24px !important;
    }
`;

const StyledAccordianCard = styled(Accordian)`
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #486d90;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;
const StyledAccordianHeading = styled(Accordian)`
    margin-bottom: 3.75rem;
`;
const nextStyle = {
    display: 'none',
};
const backStyle = {
    display: 'none',
};
const cancelStyle = {
    display: 'none',
};


const LOT_ERROR =
    'Since your tax accounting method is Identified Lot, we are unable to process your order online. Please contact a Member Service Representative to assist you with your order.';

export const PlanInfoCard = props => {
    const {
        onMenuClick,
        onEditMenuClick,
        onDeleteMenuClick,
        onSkipClick,
        accountName,
        accNumber,
        accountDetails,
    } = props;
    return (
        <StyledAccordianCard
            titleChild={<PlanInfoCardTitle accName={accountName} divider={false} hasDollarBillIcon hasMask />}
            Id={accountName.replace(/\s/g, '')}
        >
            <React.Fragment>
                {accountDetails.map((accountData, index) => (
                    <PlanInfoCardBody
                        key={index}
                        details={accountData.details}
                        share={accountData.share}
                        addedDate={accountData.dateDetails}
                        onSkipClick={onSkipClick}
                        skipText={constants.skipText}
                    >
                        <KebabMenu
                            onclickbutton={e => onMenuClick(e, index)}
                            showmenu={accountData.showMenu}
                            menubtnlabel={accountData.share.fundName}
                            menubtnname={`More options on ${accountName} ${accNumber}`}
                            menuitems={[
                                {
                                    id: `edit-${accNumber}`,
                                    menuOptName: `Edit ${accountData.share.fundName}`,
                                    name: constants.menuOptions[0],
                                    icon:
                                        accountData.editDeleteFlag || accountData.viewOnlyFlag
                                            ? DisabledEditIcon
                                            : editIcon,
                                    itemClick: e => onEditMenuClick(e, index),
                                    isDisabled: accountData.editDeleteFlag || accountData.viewOnlyFlag,
                                },
                                {
                                    id: `delete-${accNumber}`,
                                    menuOptName: `Delete ${accountData.share.fundName}`,
                                    name: constants.menuOptions[1],
                                    icon:
                                        accountData.editDeleteFlag || accountData.viewOnlyFlag
                                            ? DisabledDeleteIcon
                                            : deleteIcon,
                                    itemClick: onDeleteMenuClick,
                                    isDisabled: accountData.editDeleteFlag || accountData.viewOnlyFlag,
                                },
                            ]}
                        />
                    </PlanInfoCardBody>
                ))}
            </React.Fragment>
        </StyledAccordianCard>
    );
};

PlanInfoCard.propTypes = {
    onMenuClick: PropTypes.func,
    showMenu: PropTypes.bool,
    onEditMenuClick: PropTypes.func,
    ariaLabelEdit: PropTypes.string,
    onDeleteMenuClick: PropTypes.func,
    ariaLabelDelete: PropTypes.string,
    onSkipClick: PropTypes.func,
    accountName: PropTypes.string,
    accNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    details: PropTypes.instanceOf(Object),
    share: PropTypes.instanceOf(Object),
    dateDetails: PropTypes.instanceOf(Object),
    fundDetails: PropTypes.instanceOf(Object),
};

PlanInfoCard.defaultProps = {
    onMenuClick: () => {},
    showMenu: false,
    onEditMenuClick: () => {},
    ariaLabelEdit: '',
    onDeleteMenuClick: () => {},
    ariaLabelDelete: '',
    onSkipClick: () => {},
    accountName: '',
    accNumber: '',
    accountDetails: [],
    share: {},
    dateDetails: {},
    fundDetails: {},
};

export class InvestmentOrWithdrawalPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addedAccounts: [],
            bankDetails: [],
            isMenuOpen: false,
            openMenuId: null,
            displayNotification: false,
            isDeleteNotification: false,
            openConformationDialog: false,
            skipAcc: null,
            skipIndex: null,
            showSkip: false,
            notificationMsg: '',
            notificationType: NOTIFICATION_TYPE.SUCCESS,
            deleteModalBody: '',
            skipDisableSubmit: true,
            skipFromDate: '',
            skipToDate: '',
            analitycsDigitalData: {
                accountOptions: {
                    accountservicetype: ACCOUNT_SERVICE_TYPE_AIP,
                    accountflowtype: '',
                    dividendsandpreferrencetype: '',
                    investmentplanamount: '',
                },
            },
            showErrorModal: false,
            modalAlertMessage: '',
            threeDotElem:'',
            showAuthBox : false,
            actionPerform : '',
            showConfirmModal: false,
        };
        this.myRef = React.createRef();
        this.bankDetailsSectionRef = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.onDeleteMenuClick = this.onDeleteMenuClick.bind(this);
        this.onConformationCancel = this.onConformationCancel.bind(this);
        this.onConformationDelete = this.onConformationDelete.bind(this);
        this.breadCrumbItems = this.getBreadCrumbItems();
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { automaticInvestmentData, withdrawalPlanList, swpIsDelete, swpIsError, swpMsgError, plan ,bankAccountReducerData} = nextProps;
        const { existingPlanList: investmentPlanList = [] } = automaticInvestmentData || {};
        const { addedAccounts, openMenuId } = state;
        const isErrorReceived = swpIsError || automaticInvestmentData?.isError;

        // check if we have a deletion
        if ((automaticInvestmentData?.isDelete || swpIsDelete) && openMenuId) {
            // we just had a delete
            if (addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details.length < 2) {
                if (addedAccounts[+openMenuId.acctypeindex].accounts.length < 2) {
                    addedAccounts.splice(+openMenuId.acctypeindex, 1);
                } else {
                    addedAccounts[+openMenuId.acctypeindex].accounts.splice(+openMenuId.accindex, 1);
                }
            } else {
                addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details.splice(
                    +openMenuId.fundIndex,
                    1,
                );
            }

            nextProps.resetAIPDeleteFlag();
            const message = `You have successfully deleted ${plan === RECURRING_TRANSFER_PLAN.AIP ?'your':'an'} Automatic ${
                plan === RECURRING_TRANSFER_PLAN.AIP ? 'Investment' : 'Withdrawal/Exchange'
            } Plan.`;
            return {
                addedAccounts,
                isMenuOpen: false,
                openMenuId: null,
                openConformationDialog: false,
                displayNotification: true,
                notificationMsg: { message },
                notificationType: NOTIFICATION_TYPE.SUCCESS,
                bankDetails: bankAccountReducerData.bankDetails.length > 0 ? bankAccountReducerData.bankDetails: [],
            };
        }

        if (isErrorReceived) {
           return {
                displayNotification: true,
                notificationMsg: swpIsError ? { ...swpMsgError } : { ...automaticInvestmentData.error },
                notificationType: NOTIFICATION_TYPE.ERROR,
            };
        }

        if (withdrawalPlanList.length) {
            return {
                addedAccounts: withdrawalPlanList,
                bankDetails: bankAccountReducerData.bankDetails.length > 0 ? bankAccountReducerData.bankDetails: []
            };
        }
        if (investmentPlanList.length) {
            return {
                addedAccounts: investmentPlanList,
                bankDetails: bankAccountReducerData.bankDetails.length > 0 ? bankAccountReducerData.bankDetails: []
            };
        }
        return {
            bankDetails: bankAccountReducerData.bankDetails.length > 0 ? bankAccountReducerData.bankDetails: []
        }
        return null;
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick, false);
        document.addEventListener('keydown', this.handleEscapeKey, false);
        const { plan, location, getSWPDetails ,getBankAccountInfo} = this.props;
        const { accAdded, accUpdated, eventMessage } = location.state || {};
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues(); 
        const {accesstoken, vcmid, msrid, fismemberid} = params;        
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid
        }
        this.setSessionStorageStepName();
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            const { getPADDetails } = this.props;
            
            getPADDetails({ msrParams });
        } else {
            getSWPDetails({ msrParams });
        }
        const planName =
            plan === RECURRING_TRANSFER_PLAN.AIP
                ? `Investment`
                : `Withdrawal/Exchange`;
        const message = accAdded
            ? `You have successfully created your Automatic ${planName} Plan. ${eventMessage}`
            : `You have successfully updated your Automatic ${planName} Plan. ${eventMessage}`;

        this.setState(() => {
            const returnObj = {};
            if (accAdded || accUpdated) {
                returnObj.displayNotification = true;
                returnObj.notificationMsg = { message };
                returnObj.notificationType = NOTIFICATION_TYPE.SUCCESS;
            }
            returnObj.analitycsDigitalData = {
                accountservicetype:
                    plan === RECURRING_TRANSFER_PLAN.AIP ? ACCOUNT_SERVICE_TYPE_AIP : ACCOUNT_SERVICE_TYPE_SWP,
                accountflowtype: '',
                dividendsandpreferrencetype: '',
                investmentplanamount: '',
            };
            return returnObj;
        });
        getBankAccountInfo({ token: accesstoken, vcmid });
        this.props.getEnabledFeature()
    }

    componentWillUnmount() {
        const { clearAIPPlanList, clearSWPPlanList } = this.props;

        window.removeEventListener('click', this.handleOutsideClick, false);
        document.removeEventListener('keydown', this.handleEscapeKey, false);
        clearSWPPlanList();
        clearAIPPlanList();
    }

    onMenuClick = (e, acctypeindex, accindex, fundIndex) => {
        e.stopPropagation();
        
        for (let i = 0; i < document.querySelectorAll("button.kebabMenuClass").length; ++i)
        if (document.querySelectorAll("button.kebabMenuClass")[i] === e.target) { this.state.threeDotElem = i; }

        this.setState(prevState => {
            const { addedAccounts, isMenuOpen, openMenuId } = prevState;
            addedAccounts[+acctypeindex].accounts[+accindex].details[+fundIndex].showMenu = !addedAccounts[
                +acctypeindex
            ].accounts[+accindex].details[+fundIndex].showMenu;
            const isSameIndex =
                isMenuOpen &&
                openMenuId.acctypeindex === acctypeindex &&
                openMenuId.accindex === accindex &&
                openMenuId.fundIndex === fundIndex;
            if (isMenuOpen && !isSameIndex) {
                addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details[
                    +openMenuId.fundIndex
                ].showMenu = false;
            }
            return {
                addedAccounts,
                isMenuOpen: !(isMenuOpen && isSameIndex),
                openMenuId:
                    isMenuOpen && isSameIndex
                        ? null
                        : {
                              acctypeindex,
                              accindex,
                              fundIndex,
                          },
            };
        });
    };

    onEditMenuClick = (event, accDetails, accountType, fundIndex) => {
        if (event) {
            event.stopPropagation();
        }
        const { history, plan, analyzeClickEvent, withdrawalPlanList } = this.props;
        const pathname =
            // plan === RECURRING_TRANSFER_PLAN.AIP ? '/editautomaticinvestment' : '/editsystematicwithdrawal';
            plan === RECURRING_TRANSFER_PLAN.AIP
            ? routeConstants.editautomaticinvestment
            : routeConstants.editsystematicwithdrawal;
        
            const fundDetails = get(accDetails, `details[${fundIndex}]`, {});
        if (fundDetails.costBasis && fundDetails.costBasis != 'average') {
            this.setState(prevState => {
                return {
                    isMenuOpen: false,
                    showErrorModal: true,
                    modalAlertMessage: LOT_ERROR,
                };
            });
            return false;
        }
        const details = {
            fundAccountNumber: fundDetails.fundAccountNumber,
            fundNumber: fundDetails.fundNumber,
        };

        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            details.padId = fundDetails.padId;
        } else {
            details.swpId = fundDetails.swpId;
        }

        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountOwnerDesc, accountType: accountTypeHistoryState } = state || {};

        const historyObj = {
            pathname,
            state: {
                plan,
                edit: true,
                details,
                withdrawalPlanList,
            },
        };

        if (masterRegId) {
            historyObj.state = {
                ...historyObj.state,
                masterRegId,
                accountName,
                accountType: accountTypeHistoryState,
                accountOwnerDesc,
            };
        }

        history.push(historyObj);

        analyzeClickEvent({
            link: plan === RECURRING_TRANSFER_PLAN.AIP ? AIP_EDIT_BUTTON : SWP_EDIT_BUTTON,
            waitforapiresponse: true,
        });
    };

    onDeleteMenuClick(e) {
        if (e) {
            e.stopPropagation();
        }
        const { plan } = this.props;
        sessionStorage.setItem(STEP_KEY, 'Delete');

        this.setState(prevState => {
            const { addedAccounts, openMenuId } = prevState;
            const acc =
                addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details[+openMenuId.fundIndex];
            acc.showMenu = false;
            const planName = plan === RECURRING_TRANSFER_PLAN.AIP ? 'Investment' : 'Withdrawal/Exchange';

            if (acc.costBasis && acc.costBasis != 'average') {
                return {
                    isMenuOpen: false,
                    showErrorModal: true,
                    modalAlertMessage: LOT_ERROR,
                };
            }

            return {
                isMenuOpen: false,
                openConformationDialog: true,
                isDeleteNotification: true,
                deleteModalBody: `Are you sure you want to delete this Automatic ${planName} Plan?`,
                analitycsDigitalData: {
                    accountOptions: {
                        ...prevState.analitycsDigitalData.accountOptions,
                        accountflowtype:
                            plan === RECURRING_TRANSFER_PLAN.AIP
                                ? ACCOUNT_FLOW_TYPE_AIP_DELETE
                                : ACCOUNT_FLOW_TYPE_SWP_DELETE,
                    },
                },
            };
        });
        const { analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: plan === RECURRING_TRANSFER_PLAN.AIP ? AIP_DELETE_BUTTON : SWP_DELETE_BUTTON,
        });
    }

    setSessionStorageStepName = () => {
        const { plan } = this.props;
        if (plan === RECURRING_TRANSFER_PLAN.AIP) {
            sessionStorage.setItem(STEP_KEY, AIP_INITIAL_PAGE_NAME);
        } else {
            sessionStorage.setItem(STEP_KEY, SWP_INITIAL_PAGE_NAME);
        }
    };

    onConformationDelete() {

        if(isTokenAvailable() === null)
        {
            this.setState({showAuthBox:true,actionPerform : 'delete',openConformationDialog : false});
        }
        else
        {
           this.moveToNextStep('delete');
        }
    }

    getBreadCrumbItems = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: {
                state: {
                    name: accountName,
                    masterId: masterRegId,
                    accountType,
                    accountOwnerDesc,
                },
            },
        };
        const bradcrumbRoutes = [routeConstants.accountholder, routeConstants.accountservices];
        const { plan } = this.props;
        const analyticsIDButtonScreen =
            plan === RECURRING_TRANSFER_PLAN.AIP ? BREAD_CRUMB_SCREEN_AIP : BREAD_CRUMB_SCREEN_SWP;
        const breadCrumbItems = bradcrumbRoutes.map(route => createBreadCrumbItem(route, analyticsIDButtonScreen));
        if (masterRegId) breadCrumbItems.splice(1, 1, intermediateRoute);
        return breadCrumbItems;
    };

    onNotificationClose = isClosedByTimer => {
        const { history, analyzeClickEvent, plan } = this.props;
        const { notificationType } = this.state;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const isSuccessNotification = notificationType === NOTIFICATION_TYPE.SUCCESS;
        const historyObj = {
            state: { accAdded: false, accUpdated: false, message: '' },
        };
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountOwnerDesc, accountType: accountTypeHistoryState } = state || {};
        if (masterRegId) {
            historyObj.state = {
                ...historyObj.state,
                masterRegId,
                accountName,
                accountType: accountTypeHistoryState,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
        this.setState({
            displayNotification: false,
            isDeleteNotification: false,
            notificationType: NOTIFICATION_TYPE.SUCCESS,
            notificationMsg: '',
        });
        this.setSessionStorageStepName();
        if (!isClosedByTimer) {
            analyzeClickEvent({
                link: `${isAIPPlan ? 'accountservices_aip' : 'accountservices_swp'}_notification_${
                    isSuccessNotification ? 'success' : 'error'
                }_close`,
            });
        }
    };

    onConformationCancel() {
        sessionStorage.setItem(STEP_KEY, AIP_DELETE_PAGE_NAME);
        this.setState(() => {
            return {
                openMenuId: {},
                openConformationDialog: false,
                isDeleteNotification: false,
            };
        },function(){
            document.querySelectorAll("button.kebabMenuClass")[this.state.threeDotElem].focus();
        });
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    authBox = () =>{
        this.setState({showAuthBox: true})
    }

    add = () => {
        const{bankDetails}= this.state;
        if(bankDetails.length == 0){
           this.setState({showConfirmModal: true})
        }
         else if(isTokenAvailable() === null)
        {
            this.setState({showAuthBox:true,actionPerform : 'add'})
        }
        else
        {
           this.moveToNextStep('add');
        }
        
    };

    onSkipClick = (accDetails, index) => () => {
        this.setState(() => {
            return {
                showSkip: true,
                skipAcc: accDetails,
                skipIndex: index,
            };
        });
    };

    onSkipSubmit = () => {
        // Call API here
        this.setState(prevState => {
            const { plan } = this.props;
            const planName =
                plan === RECURRING_TRANSFER_PLAN.AIP ? 'Automatic Investment Plan' : 'Systematic Withdrawal';
            const { skipIndex, addedAccounts, skipFromDate, skipToDate } = prevState;
            const { acctypeindex, accindex } = skipIndex;
            addedAccounts[+acctypeindex].accounts[+accindex].suspendedFromDate = skipFromDate;
            addedAccounts[+acctypeindex].accounts[+accindex].suspendedToDate = skipToDate;
            return {
                showSkip: false,
                skipAcc: null,
                skipIndex: null,
                skipFromDate: '',
                skipToDate: '',
                skipDisableSubmit: true,
                displayNotification: true,
                notificationMsg: { message: `You have successfully Skipped your ${planName}.` },
                notificationType: NOTIFICATION_TYPE.SUCCESS,
            };
        }, this.scrollToTop);
    };

    onSkipCancel = () => {
        this.setState(() => {
            return {
                showSkip: false,
                skipAcc: null,
                skipIndex: null,
            };
        }, this.scrollToTop);
    };

    onSkipDateChange = id => e => {
        const { target } = e;
        this.setState(prevState => {
            let { skipFromDate, skipToDate } = prevState;
            if (id === 'skipFromDate') {
                skipFromDate = target.value;
                if (
                    skipFromDate &&
                    (!isValidDate(skipToDate) ||
                        (isValidDate(skipToDate) && new Date(skipFromDate) > new Date(skipToDate)))
                ) {
                    skipToDate = '';
                }
            } else if (id === 'skipToDate') {
                skipToDate = target.value;
            }
            return {
                skipDisableSubmit: !isValidDate(skipFromDate) || !isValidDate(skipToDate),
                skipFromDate,
                skipToDate,
            };
        });
    };

    clickBack = () => {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyObj = {};
        const backRoute = masterRegId ? routeConstants.accountholderTransactions : routeConstants.accountservices;
        historyObj.pathname = backRoute;
        if (masterRegId) {
            historyObj.state = {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    };

    handleEscapeKey = e => {
        const { openConformationDialog, isMenuOpen } = this.state;
        if (e.keyCode === KEY_CODE.ESCAPE) {
            if (openConformationDialog) {
                this.setState({ openConformationDialog: false });
            }
            if (isMenuOpen) {
                this.closeOpenMenu();
            }
        }
        if (e.keyCode === KEY_CODE.TAB && isMenuOpen) {
            this.closeOpenMenu();
        }
    };

    closeOpenMenu = () => {
        this.setState(prevState => {
            const { addedAccounts, openMenuId } = prevState;
            addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details[
                +openMenuId.fundIndex
            ].showMenu = false;
            return {
                addedAccounts,
                isMenuOpen: false,
                openMenuId: {},
            };
        });
    };

    handleOutsideClick() {
        const { isMenuOpen } = this.state;
        if (isMenuOpen) {
            this.closeOpenMenu();
        }
    }

    renderExistingPlans = (instructions, addedAccounts, cardContent, planName) => {
        let details;
        let dateDetails;
        return (
            <>
                <InstructionAIP {...instructions} />
                
                {addedAccounts.map((item, acctypeindex) => (
                    <StyledAccordianHeading
                        key={item.accountType}
                        titleChild={
                            <AccordianTitle
                                accType={item.accountType}
                                id={item.accountType?.replace(/\s/g, '')}
                                accHeaderAs="button"
                            />
                        }
                        titleSeperator
                        accordionTitleAs="h2"
                        Id={item.accountType?.replace(/\s/g, '') + acctypeindex}
                    >
                        <div>
                            {/* Description not needed for now */}
                            {/* <AccountDescription>{item.accountDesc}</AccountDescription> */}
                            {item.accounts &&
                                item.accounts.length &&
                                item.accounts.map((eachacc, accindex) => {
                                    details = [];
                                    if (get(eachacc, 'details', []).length) {
                                        eachacc.details.map(plan => {
                                            if (planName === RECURRING_TRANSFER_PLAN.AIP) {
                                                if (plan.suspendedFromDate && plan.suspendedToDate) {
                                                    dateDetails = {
                                                        text: cardContent.skipDateTxt,
                                                        value: `${plan.suspendedFromDate} - ${plan.suspendedToDate}`,
                                                    };
                                                } else {
                                                    dateDetails = {
                                                        text: cardContent.dateAddedTxt,
                                                        value: plan.datePADSetup,
                                                    };
                                                }

                                                details.push({
                                                    showMenu: plan.showMenu,
                                                    // dateDetails,
                                                    share: plan.share,
                                                    id: plan.padId,
                                                    fundNumber: plan.fundNumber,
                                                    fundAccountNumber: plan.fundAccountNumber,
                                                    editDeleteFlag: plan.editDeleteFlag,
                                                    viewOnlyFlag: plan.viewOnlyFlag,
                                                    details: [
                                                        {
                                                            text: cardContent.fundSourceTxt,
                                                            value: plan.payeeBankName,
                                                        },
                                                        {
                                                            text: cardContent.freqTxt,
                                                            value: constants.frequency[`${planName}`][plan.schedule],
                                                        },
                                                        {
                                                            text: cardContent.onTheDayTxt,
                                                            value: plan.datePADSetup,
                                                        },
                                                        {
                                                            text: cardContent.amtTxt,
                                                            value: ` ${currencyFormatterFunction(plan.investedFundAmount)}`,
                                                        },
                                                        {
                                                            text: cardContent.nxtTxt,
                                                            value: plan.nextSchedulePADDate,
                                                        },
                                                    ],
                                                });
                                            } else {
                                                if (plan.suspendedFromDate && plan.suspendedToDate) {
                                                    dateDetails = {
                                                        text: cardContent.skipDateTxt,
                                                        value: `${plan.suspendedFromDate} - ${plan.suspendedToDate}`,
                                                    };
                                                } else {
                                                    dateDetails = {
                                                        text: cardContent.dateAddedTxt,
                                                        value: plan.dateSWPSetup,
                                                    };
                                                }
                                                details.push({
                                                    showMenu: plan.showMenu,
                                                    editDeleteFlag: plan.editDeleteFlag,
                                                    viewOnlyFlag: plan.viewOnlyFlag,
                                                    // dateDetails,
                                                    share: plan.share,
                                                    id: plan.swpId,
                                                    details: [
                                                        {
                                                            text: cardContent.freqTxt,
                                                            value: constants.frequency[planName][plan.schedule],
                                                        },
                                                        {
                                                            text: cardContent.onTheDayTxt,
                                                            value: plan.dateSWPSetup,
                                                        },
                                                        {
                                                            text: cardContent.amtTxt,
                                                            value: `${currencyFormatterFunction(plan.investedFundAmount)}`,
                                                        },
                                                        {
                                                            text: cardContent.fundSourceTxt,
                                                            value: plan.payeeBankName,
                                                        },
                                                        {
                                                            text: cardContent.nxtTxt,
                                                            value: plan.nextScheduleSWPDate,
                                                        },
                                                    ],
                                                });
                                            }
                                        });
                                    }
                                    const ariaLabelEdit = `Edit ${eachacc.accountName}`;
                                    const ariaLabelDelete = `Delete ${eachacc.accountName}`;
                                    return (
                                        <PlanInfoCard
                                            key={eachacc.masterRegId + accindex.toString()}
                                            onMenuClick={(event, fundIndex) => {
                                                this.onMenuClick(event, acctypeindex, accindex, fundIndex);
                                            }}
                                            showMenu={eachacc.showMenu}
                                            onEditMenuClick={(event, fundIndex) => {
                                                this.onEditMenuClick(event, eachacc, item.accountType, fundIndex);
                                            }}
                                            ariaLabelEdit={ariaLabelEdit}
                                            onDeleteMenuClick={this.onDeleteMenuClick}
                                            ariaLabelDelete={ariaLabelDelete}
                                            onSkipClick={this.onSkipClick(eachacc, {
                                                acctypeindex,
                                                accindex,
                                            })}
                                            accountName={eachacc.accountName}
                                            accNumber={eachacc.masterRegId}
                                            accountDetails={details}
                                        />
                                    );
                                })}
                        </div>
                    </StyledAccordianHeading>
                ))}
            </>
        );
    };

    handleErrorModalClick = val => {
        if (val === 'close' || val === 'hide') {
            this.setState({ showErrorModal: false, modalAlertMessage: '' });
        }
    };

    getAnalyticsNotificationPageName = () => {
        const { location } = this.props;
        const { notificationType, isDeleteNotification } = this.state;
        const isErrorNotification = notificationType === NOTIFICATION_TYPE.ERROR;
        const locationStepName = get(location, 'state.stepName', null);
        const deleteNotificationPageName = isDeleteNotification && 'Delete';
        const pageNamePart1 = locationStepName || deleteNotificationPageName || 'Notification';
        const pageNamePart2 = isErrorNotification ? 'Error' : 'Success';
        return `${pageNamePart1}|${pageNamePart2}`;
    };

    onAccountServiceBackClick = () => {
        const { history } = this.props;
        history.push(routeConstants.accountservices);
    };

    onNotificationForFis = ()=>{
        
        this.setState({
            checkFisError: true
        });
    }

    moveToNextStep = (action) => 
    {
        if(action == 'add')
        {
            const { history, plan, location } = this.props;
            const { state } = location || {};
            const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
            const historyObj = {};
            historyObj.pathname =
                plan === RECURRING_TRANSFER_PLAN.AIP
                    ? routeConstants.createAutomaticInvestment
                    : routeConstants.createSystematicWithdrawal;
            historyObj.state = {
                plan,
            };
            if (masterRegId) {
                historyObj.state = {
                    ...historyObj.state,
                    masterRegId,
                    accountName,
                    accountType,
                    accountOwnerDesc,
                };
            }
            history.push(historyObj);
            const { analyzeClickEvent } = this.props;
            analyzeClickEvent({
                link: plan === RECURRING_TRANSFER_PLAN.AIP ? AIP_ADD_BUTTON : SWP_ADD_BUTTON,
                waitforapiresponse: true,
            });
        }
        else if(action == 'delete')
        {
            const { deleteAutoInvestPlan, deleteSystematicWidthdrawalPlan } = this.props;
            const { addedAccounts, openMenuId } = this.state;
            const acc =
                addedAccounts[+openMenuId.acctypeindex].accounts[+openMenuId.accindex].details[+openMenuId.fundIndex];
            const { plan } = this.props;
            this.setSessionStorageStepName();
            if (plan === RECURRING_TRANSFER_PLAN.AIP) {
                sessionStorage.setItem(STEP_KEY, AIP_DELETE_PAGE_NAME);
                deleteAutoInvestPlan({
                    padId: acc.padId,
                    fundAccountNumber: acc.fundAccountNumber,
                    fundNumber: acc.fundNumber,
                });
            } else {
                sessionStorage.setItem(STEP_KEY, SWP_DELETE_PAGE_NAME);
                deleteSystematicWidthdrawalPlan({
                    swpId: acc.swpId,
                    fundAccountNumber: acc.fundAccountNumber,
                    fundNumber: acc.fundNumber,
                });
            }
            this.setState({ openConformationDialog: false });
        }
        
    }

    moveNextPage = (actionTaken = '') =>{
        this.setState({showAuthBox: false})
        if(actionTaken != 'closed')
        {
            if(this.state.actionPerform == 'delete')
            {
                this.moveToNextStep('delete');
            }
            else
            {
                this.moveToNextStep('add');
            }
        }
    }

    closeModel = ()=>{
        this.setState({showConfirmModal:false})
    }

    render() {
        const {
            addedAccounts,
            openConformationDialog,
            skipAcc,
            showSkip,
            displayNotification,
            notificationMsg,
            notificationType,
            deleteModalBody,
            skipDisableSubmit,
            skipFromDate,
            skipToDate,
            analitycsDigitalData,
            showErrorModal,
            modalAlertMessage,
            showAuthBox,
            showConfirmModal
        } = this.state;
        const { plan, automaticInvestmentData, swpIsLoading } = this.props;
        const isAIPPlan = plan === RECURRING_TRANSFER_PLAN.AIP;
        const navProps = {
            dataAnalyticId: isAIPPlan ? AIP_HOME_NAV_BUTTON_ID : SWP_HOME_NAV_BUTTON_ID,
        };
        if (showSkip) {
            navProps.submitClick = this.clickSubmit;
            navProps.submit = 'true';
            navProps.disabled = skipDisableSubmit;
            navProps.backstyle = backStyle;
            navProps.arialabelnext = 'Submit click';
            navProps.iscancel = true;
            navProps.arialabelcancel = 'Cancel click';
            navProps.cancelClick = this.clickCancel;
        } else {
            navProps.backClick = this.clickBack;
            navProps.nextstyle = nextStyle;
            navProps.arialabelback = 'Back to account services';
            navProps.cancelStyle = cancelStyle;
        }
        const {
            isLoading: automaticInvestmentDataLoading,
            isError: automaticInvestmentDataError,
            error: automaticInvestmentDataErrorData,
        } = automaticInvestmentData || {};
        const loading = swpIsLoading || automaticInvestmentDataLoading;
        const isErrorNotification = notificationType === NOTIFICATION_TYPE.ERROR;
        let activeCrumb = isAIPPlan
            ? routesBreadCrumb.get(routeConstants.automaticinvestment)
            : routesBreadCrumb.get(routeConstants.systematicwithdrawal);
        let titleContent = isAIPPlan ? constants.AIP : constants.SWP;
        let skipContent = isAIPPlan ? constants.skipAIP : constants.skipSWP;
        let cardContent = isAIPPlan ? constants.addedAccountsForAIPNew : constants.addedAccountsForSWPNew;
        const instructions = {};
        if (isAIPPlan) {
            instructions.instructionsTitle = constants.instructionsTitle;
            instructions.instructionSubheading = constants.instructionSubheading;
            instructions.instructionPara1 = constants.instructionPara1;
            instructions.instructionList = constants.instructionList;
            instructions.instructionPara2 = constants.instructionPara2;
        } else {
            instructions.instructionsTitle = constants.SWPinstructionsTitle;
            instructions.instructionSubheading = constants.SWPinstructionSubheading;
            instructions.instructionPara1 = constants.SWPinstructionPara1;
            instructions.instructionList = constants.SWPinstructionList;
            instructions.instructionPara2 = constants.SWPinstructionPara2;
        }

        const analyticsPageName = this.getAnalyticsNotificationPageName();

        const { location } = this.props;
        const { state: historyState } = location || {};
        const { masterRegId } = historyState || {};

        const isNewDashboardFeatureEnabled =  
        this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
        this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");
        return (
            <>
                {loading && <WSpinner loading={loading} />}
                { <Authenticate showAuthBox={showAuthBox}  moveNextPage={this.moveNextPage} onNotificationForFis={this.onNotificationForFis} />}
                {showConfirmModal && (<ConfirmModal
                        modalTitle="NOTE:"
                        modalBodyText={NO_BANK_ACCOUNTS}
                        primaryButtonText={TransactionConstants.OK_TEXT}
                        onPrimaryClick={this.closeModel}
                    />
                )}
                <div>
                    {displayNotification && (
                        <WMessageBar
                            error={isErrorNotification}
                            onClose={this.onNotificationClose}
                            text={notificationMsg}
                            analyticsPageName={analyticsPageName}
                            doNotClose
                        />
                    )}
                    <div className="container">
                        <WBreadCrumb activeCrumb={activeCrumb} breadCrumbItems={this.breadCrumbItems} />
                        {(isNewDashboardFeatureEnabled) ?
                        <>
                             {!!masterRegId && (
                            <StyledBackBtn>
                                <BackBtn text="Back to Account Positions" onClick={this.clickBack} />
                            </StyledBackBtn>
                            )}
                        </>
                        :
                        <>
                            {!!masterRegId && (
                            <StyledBackBtn>
                                <BackBtn text="Back to Account Services" onClick={this.onAccountServiceBackClick} />
                            </StyledBackBtn>
                            )}
                        </>
                        }
                       
                        {openConformationDialog && (
                            
                                <ConfirmationMessage
                                    modalTitle={titleContent.deleteModalTitle}
                                    modalBodyText={deleteModalBody}
                                    primaryButtonText={constants.deleteModalDeleteText}
                                    secondaryButtonText={constants.deleteModalCancelText}
                                    onPrimaryClick={this.onConformationDelete}
                                    onSecondaryClick={this.onConformationCancel}
                                    dataAnalyticId={AIP_DELETE_BUTTON}
                                    waitforapiresponse={true}
                                    analyzecustom={analitycsDigitalData}
                                    show={openConformationDialog}
                                />
                            
                        )}
                        {showErrorModal && (
                            <WErrorModal
                                show={showErrorModal}
                                message={modalAlertMessage}
                                modalClick={this.handleErrorModalClick}
                            />
                        )}
                        {showSkip ? (
                            <SkipPlan
                                planDetatils={skipAcc}
                                contents={skipContent}
                                onSubmit={this.onSkipSubmit}
                                onCancel={this.onSkipCancel}
                                onDateChange={this.onSkipDateChange}
                                skipFromDate={skipFromDate}
                                skipToDate={skipToDate}
                                analyzecustom={analitycsDigitalData}
                            />
                        ) : (
                            <>
                                <FlexDiv className="addNewPlan">
                                    <PageHeading>{titleContent.pageHeading}</PageHeading>
                                   {  isAIPPlan ? (<AddSection
                                        aria-label="Add new plan"
                                        onClick={this.add}
                                        role="link"
                                        className="addNewPlanBtn"
                                    >
                                        <AddSymbolImg src={AddIcon} alt="" />
                                        <AddText>{constants.addText}</AddText>
                                    </AddSection>): ('')} 
                                </FlexDiv>
                                {addedAccounts.length ? (
                                    this.renderExistingPlans(instructions, addedAccounts, cardContent, plan)
                                ) : (
                                    <>
                                        <InstructionAIP {...instructions} />
                                        <NoPlansAvailable>
                                            {loading ? constants.loading : titleContent.noPlansText}
                                        </NoPlansAvailable>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <CommonButtons {...navProps} />
                </div>
            </>
        );
    }
}

InvestmentOrWithdrawalPlans.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    plan: PropTypes.string.isRequired,
    getSWPDetails: PropTypes.func,
    getPADDetails: PropTypes.func,
    deleteAutoInvestPlan: PropTypes.func,
    deleteSystematicWidthdrawalPlan: PropTypes.func,
    resetAIPDeleteFlag: PropTypes.func,
    clearAIPPlanList: PropTypes.func,
    clearSWPPlanList: PropTypes.func,
    swpIsLoading: PropTypes.bool,
    swpIsDelete: PropTypes.bool,
    automaticInvestmentData: PropTypes.instanceOf(Object),
    withdrawalPlanList: PropTypes.arrayOf(Object),
    swpMsgError: PropTypes.instanceOf(Object),
    swpIsError: PropTypes.bool,
    analyzeClickEvent: PropTypes.func,
};
InvestmentOrWithdrawalPlans.defaultProps = {
    getSWPDetails: () => {},
    getPADDetails: () => {},
    deleteAutoInvestPlan: () => {},
    deleteSystematicWidthdrawalPlan: () => {},
    resetAIPDeleteFlag: () => {},
    clearAIPPlanList: () => {},
    clearSWPPlanList: () => {},
    swpIsLoading: false,
    swpIsDelete: false,
    automaticInvestmentData: {},
    withdrawalPlanList: [],
    swpMsgError: {},
    swpIsError: false,
    analyzeClickEvent: () => {},
};
