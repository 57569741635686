const amendHeadingText = 'Transactions';
const ACC_NUMBER = 'Account Number';
const HIDDEN_ACC_NUM = 'xxxx-xxxx-xxxx';
const DAT_TEXT = 'Date added : ';
const CURR_VAL = 'Current Value';
const TRANS_TYPE = 'Transaction Type';
const PAY_MODE = 'Payment Mode';
const ORDER_STATUS = 'Order Status';

const wrapperStyles = {
    backgroundColor: '#FFFFFF',
    minHeight: '80vh',
    position: 'relative',
    color: '#56565A',
    fontFamily: 'Roboto Slab',
};

const btnStyleFilter = {
    float: 'right',
    top: '131px',
    left: '1133px',
    width: '120px',
    height: '40px',
    color: '#5D83AE',
    display: 'inline-block',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #61285F45',
    opacity: '1',
}

const btnStyleBack = {
    top: '131px',
    left: '1133px',
    width: '120px',
    height: '40px',
    color: '#5D83AE',
    display: 'inline-block',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #61285F45',
    opacity: '1'
}

const accAvailable = [
    {
        showMenu: false,
        orderId: 'PUR10282019011',
        accName: 'Account Name 1',
        accNumber: {
            text: ACC_NUMBER,
            value: HIDDEN_ACC_NUM
        },
        share: {
            text: 'USSPX VCM 500 INDEX FUND MEMBER CLASS SHARES',
            value: '5'
        },
        addedDate: {
            text: DAT_TEXT,
            value: '20/01/2020'
        },
        currValue: {
            text: CURR_VAL,
            value: '$ 1300'
        },
        transType: {
            text: TRANS_TYPE,
            value: 'Purchase',
        },
        paymentMode: {
            text: PAY_MODE,
            value: 'Net Banking'
        },
        orderStatus: {
            text: ORDER_STATUS,
            value: 'Pending'
        }
    },
    {
        showMenu: false,
        orderId: 'PUR10282019123',
        accName: 'Account Name 2',
        accNumber: {
            text: ACC_NUMBER,
            value: HIDDEN_ACC_NUM
        },
        share: {
            text: 'USSPX VCM 350 INDEX FUND MEMBER CLASS SHARES',
            value: '5'
        },
        addedDate: {
            text: DAT_TEXT,
            value: '16/01/2020'
        },
        currValue: {
            text: CURR_VAL,
            value: '$ 6550'
        },
        transType: {
            text: TRANS_TYPE,
            value: 'Liquidation',
        },
        paymentMode: {
            text: PAY_MODE,
            value: 'Wire Transfer'
        },
        orderStatus: {
            text: ORDER_STATUS,
            value: 'Pending'
        }
    },
    {
        showMenu: false,
        orderId: 'PUR10282019024',
        accName: 'Account Name 3',
        accNumber: {
            text: ACC_NUMBER,
            value: HIDDEN_ACC_NUM
        },
        share: {
            text: 'USSPX VCM 200 INDEX FUND MEMBER CLASS SHARES',
            value: '5'
        },
        addedDate: {
            text: DAT_TEXT,
            value: '20/01/2020'
        },
        currValue: {
            text: CURR_VAL,
            value: '$ 3654'
        },
        transType: {
            text: TRANS_TYPE,
            value: 'Exchange',
        },
        paymentMode: {
            text: PAY_MODE,
            value: 'In Order'
        },
        orderStatus: {
            text: ORDER_STATUS,
            value: 'Pending'
        }
    }
]

const fundDetails = {
    NAVdetails: [
        {
            key: 'Min. / Max. Amount',
            value: '$ 300 / $ 8,000',
        },
        {
            key: 'NAV (Change in Percentage)',
            value: '14.3',
        },
        {
            key: 'Last NAV (Previous day close)',
            value: '14.3',
        },
        {
            key: '52 week Min. / Max. Values',
            value: '$ 3,000 / $ 5,000',
        },
    ],
    details: [
        {
            key: 'Total Shares',
            value: '2452',
        },
        {
            key: 'Worth',
            value: '$ 6000 (Approx.)',
        },
    ],
}

const menuOptions = ['Amend', 'Cancel'];

export default {
    wrapperStyles,
    amendHeadingText,
    btnStyleFilter,
    btnStyleBack,
    accAvailable,
    menuOptions,
    fundDetails
}