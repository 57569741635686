/**
 * Summary Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to view funds summary
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';
import styled from 'styled-components';
import { WIcon } from '../../../common';
import disclosureData from '../../../common/Disclosure/disclosureData';
import PDFIcon from '../../../assets/pdfdownloadsmall.png';
import './summaryComponent.css';
import mediumRisk from '../../../assets/mediumRisk.svg';
import highRisk from '../../../assets/highRisk.svg';
import riskLevel1 from '../../../assets/Level1.png';
import riskLevel2 from '../../../assets/Level2.png';
import riskLevel3 from '../../../assets/Level3.png';
import riskLevel4 from '../../../assets/Level4.png';
import riskLevel5 from '../../../assets/Level5.png';
import riskLevel6 from '../../../assets/Level6.png';
import riskLevel7 from '../../../assets/Level7.png';
import envConfig from 'env/EnvConfig';

const StyledWIcon = styled(WIcon)`
    @media (max-width: 767px) {
        height: 20px;
    }
`;
const minInvest = 'Investment Minimums';
const initInvest = 'Initial Investment';
const initAndMonthly = 'Initial and Monthly Investment';
const monthly = 'monthly';
const moreInfo = 'More Information';
const riskLevel = 'Risk:';
const ws = ' with ';
const downloadText = 'Download';
const fundFactText = 'Fund Fact Sheet';
const dollar = '$';

const assignObj = obj => {
    return obj;
};


export default class SummaryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryFund: {},
            disclosureDocs: {},
            summaryText: '',
        };
    }

    static getDerivedStateFromProps(props) {
        if (
            props.selectedFundDetails !== undefined &&
            props.selectedFundDetails !== null &&
            Object.keys(props.selectedFundDetails).length > 0
        ) {
            const disclosureDocs =
                disclosureData &&
                disclosureData.filter(fund => Number(fund.fundCode) === Number(props.selectedFundDetails.entity_id));

            return {
                summaryFund: props.selectedFundDetails,
                disclosureDocs,
                summaryText: disclosureDocs[0] && disclosureDocs[0].summaryText,
            };
        }

        return {};
    }

    getImgSrc = risk => {
        let src = '';
        switch (risk) {
            case 'Low':
                src = mediumRisk;
                break;
            case 'Medium':
                src = mediumRisk;
                break;
            case 'High':
                src = highRisk;
                break;
            case 'Preservation of Capital':
                src = riskLevel1;
                break;
            case 'Conservative':
                src = riskLevel2;
                break;
            case 'Moderately Conservative':
                src = riskLevel3;
                break;
            case 'Moderate':
                src = riskLevel4;
                break;
            case 'Moderately Aggressive':
                src = riskLevel5;
                break;
            case 'Aggressive':
                src = riskLevel6;
                break;
            case 'Very Aggressive':
                src = riskLevel7;
                break;
            default:
                src = '';
        }
        return src;
    };

    openFundFactUrl = pdfLocation => {
        if (pdfLocation !== '') {
            if (envConfig.FUND_FACT_HOST) {
                window.open(`${envConfig.FUND_FACT_HOST}${pdfLocation}`);
            } else {
                window.open(`https://vcm.com${pdfLocation}`);
            }
        }
    };

    openDisclosureUrl = pdfLocation => {
        if (pdfLocation !== '') {
            window.open(pdfLocation);
        }
    };

    handlePdf = () => {
        window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
    };

    render() {
        const { summaryFund, disclosureDocs, summaryText } = this.state;

        return (
            <div style={styles.mb30} className="summaryCardWrapper">
                <div style={styles.flexStyle}>
                    <span className="riskSpan" style={styles.flexSpanStyle}>
                        <span className="riskLabel" style={styles.riskSpanStyle}>
                            {riskLevel}
                        </span>
                        {summaryFund.risk}
                    </span>
                    <StyledWIcon src={this.getImgSrc(summaryFund.risk)} />
                </div>
                <p className="summaryPara" style={styles.summaryParaStyles}>
                    {summaryText}
                    {/* {summaryFund.fundDescription} */}
                </p>
                <div style={styles.summarySubHeadingStyle}>{minInvest}</div>
                <Row style={styles.initialInvestmentStyle}>
                    <Col md={4}>
                        <span style={styles.invest}>{initInvest}</span>
                    </Col>
                    <Col md={4}>
                        {summaryFund.initialInvestment ? (
                            <span style={styles.amount}>
                                {summaryFund.initialInvestment ? Number(summaryFund.initialInvestment).toFixed(2) : 0}
                            </span>
                        ) : (
                            <span style={styles.amount}>
                                {summaryFund.init_invest ? Number(summaryFund.init_invest).toFixed(2) : 0}
                            </span>
                        )}
                    </Col>
                </Row>
                <Row className="monthlyInvestmentStyle">
                    <Col md={4}>
                        <span style={styles.invest}>{initAndMonthly}</span>
                    </Col>

                    <Col md={4}>
                        {summaryFund.minimumAutomaticInvestment ? (
                            <span style={styles.amount}>
                                {dollar}
                                {summaryFund.minimumInitialMonthlyInvestment
                                    ? Number(summaryFund.minimumInitialMonthlyInvestment).toFixed(2)
                                    : 0}
                                &nbsp;{ws}
                                &nbsp;
                                {dollar}
                                {summaryFund.minimumAutomaticInvestment
                                    ? Number(summaryFund.minimumAutomaticInvestment).toFixed(2)
                                    : 0}
                                &nbsp;{monthly}
                            </span>
                        ) : (
                            <span style={styles.amount}>
                                {dollar}
                                {summaryFund.minimumInitialMonthlyInvestment
                                    ? Number(summaryFund.minimumInitialMonthlyInvestment).toFixed(2)
                                    : 0}
                                &nbsp;{ws}
                                &nbsp;
                                {dollar}
                                {summaryFund.auto_invest_min ? Number(summaryFund.auto_invest_min).toFixed(2) : 0}
                                &nbsp;{monthly}
                            </span>
                        )}
                    </Col>
                </Row>
                {Object.entries(disclosureDocs).length > 0 &&
                    disclosureDocs.map(item => {
                        return (
                            <>
                                {item.fundFactPdf && (
                                    <>
                                        <div
                                            style={styles.summarySubHeadingStyle}
                                            key={assignObj(`${fundFactText}${item.fundName}`)}
                                        >
                                            {moreInfo}
                                        </div>
                                        <Row>
                                            <Col md={12} style={styles.pdfColStyle3}>
                                                <button
                                                    type="button"
                                                    className="download-link"
                                                    onClick={Object.assign(() =>
                                                        this.openFundFactUrl(item.fundFactPdf),
                                                    )}
                                                    aria-label={assignObj(`${fundFactText}${item.fundName}`)}
                                                >
                                                    {fundFactText}
                                                </button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        );
                    })}

                <div className="dowlodLinkListWrapper">
                    {summaryFund.moreInfoPDFs &&
                        summaryFund.moreInfoPDFs.map(item => {
                            return (
                                <Row noGutters key={item.key} style={styles.pdfStyle} className="pdfDownloadLink">
                                    <Col sm={10} style={styles.pdfColStyle1}>
                                        <WIcon icon={faCheck} fontawesomestyle={styles.fontawesomestyle} size="1x" />
                                        {item.value}
                                    </Col>
                                    <Col sm={2} style={styles.pdfColStyle2}>
                                        {/* <a
                                            type="a"
                                            className="download-link"
                                            href={samplPDFLink}
                                            download
                                            aria-label={assignObj(`${downloadText}${item.value}`)}
                                        >
                                            <WIcon src={PDFIcon} className="pdfIcon" size="1x" />
                                            {downloadText}
                                        </a> */}
                                        <WIcon
                                            src={PDFIcon}
                                            className="pdfIcon"
                                            fontawesomestyle={assignObj({ paddingLeft: 0 })}
                                        />
                                        <button
                                            type="button"
                                            className="download-link"
                                            onClick={this.handlePdf}
                                            aria-label={assignObj(`${downloadText}${item.value}`)}
                                        >
                                            {downloadText}
                                        </button>
                                    </Col>
                                </Row>
                            );
                        })}
                </div>
            </div>
        );
    }
}


SummaryComponent.propTypes = {
    selectedFundDetails: PropTypes.instanceOf(Object),
};

SummaryComponent.defaultProps = {
    selectedFundDetails: {},
};
