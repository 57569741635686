/**
 * Beneficiary Card Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to add beneficiaries
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [03/31/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { WIcon, WErrorModal } from '../../../common';
import BeneficiaryForm from '../BeneficiaryForm';
import '../PersonalInfo/style.css';
import styles from '../PersonalInfo/styles';
import plusIcon from '../../../assets/plusIcon.png';

const BeneficiarisHeading = 'Beneficiaries';
const childUTMAHeading = 'Personal Information - Child Beneficiary';
const oneOrMoreBenetext = 'You may name one or more people or entities as beneficiaries';
const primaryPercAlloc = 'Primary distributions must total 100 percent';
const addPrimaryBeneficiaryText = 'Add Primary Beneficiary';
const addContingentBeneficiaryText = 'Add Contingent Beneficiary';
const noteText = "Note : Different states have different definitions and ways to determine a child's minor status.";
const distributionPercentageHint = 'Total Distribution Percentage of Primary Beneficiary = 100 %';
const distributionPercentageHintCon = 'Total Distribution Percentage of Contingent Beneficiary = 100 %';

class BeneficiaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countPrimary: 1,
            countContingent: 0,
            displayPrimaryBenfList: [],
            displayContingentBenfList: [],
            savedState: null,
            beneficiaryrefsPrimary: [],
            beneficiaryrefsContingent: [],
        };
        this.accordionRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        // const { cardState = {}, beneficiaryPrimaryDataArray = [], beneficiaryContingentDataArray = [] } =
        //     savedState || {};
        const { beneficiaryPrimaryDataArray = [], beneficiaryContingentDataArray = [] } = savedState || {};
        if (!prevState.retrievedState && savedState) {
            const countPrimary =
                savedState.beneficiaryPrimaryDataArray && savedState.beneficiaryPrimaryDataArray.length;
            return {
                // ...cardState,
                // beneficiaryDataArray,
                beneficiaryPrimaryDataArray,
                beneficiaryContingentDataArray,
                retrievedState: true,
                countPrimary: countPrimary || 1,
                countContingent:
                    (savedState.beneficiaryContingentDataArray && savedState.beneficiaryContingentDataArray.length) ||
                    0,
            };
        }
        return { ...prevState };
    }

    componentDidMount = () => {
        const {
            countPrimary,
            displayPrimaryBenfList,
            displayContingentBenfList,
            savedState,
            beneficiaryrefsPrimary,
            beneficiaryrefsContingent,
            beneficiaryPrimaryDataArray,
            beneficiaryContingentDataArray,
        } = this.state;
        if (countPrimary === 1 && displayPrimaryBenfList.length === 0 && !savedState) {
            const refs = this.generateBeneficiaryRefs('p');
            const payload = {
                cardId: 1,
                cardRef: refs[+countPrimary - 1],
            };
            displayPrimaryBenfList.push(payload);
        } else if (displayPrimaryBenfList.length > 1) {
            displayPrimaryBenfList.map((item, index) => {
                const newItem = item;
                newItem.cardRef = beneficiaryrefsPrimary[+index];
                return newItem;
            });
        } else if (displayPrimaryBenfList.length === 0 && !savedState && beneficiaryPrimaryDataArray.length > 0) {
            const displayPrimBenList = [];
            let primRefs = [];
            beneficiaryPrimaryDataArray.map((item, index) => {
                // const refs = this.generateBeneficiaryRefs('p');
                primRefs = [...primRefs, React.createRef()];
                const payload = {
                    cardId: index + 1,
                    cardRef: primRefs[+index],
                };
                displayPrimBenList.push(payload);
                return {};
            });
            this.setState({ displayPrimaryBenfList: displayPrimBenList, beneficiaryrefsPrimary: primRefs });
        }
        if (displayContingentBenfList.length > 0) {
            displayContingentBenfList.map((item, index) => {
                const newItem = item;
                newItem.cardRef = beneficiaryrefsContingent[+index];
                return newItem;
            });
        } else if (displayContingentBenfList.length === 0 && !savedState && beneficiaryContingentDataArray.length > 0) {
            const displayConBenList = [];
            let contRefs = [];
            beneficiaryContingentDataArray.map((item, index) => {
                // const refs = this.generateBeneficiaryRefs('c');
                contRefs = [...contRefs, React.createRef()];
                const payload = {
                    cardId: index + 1,
                    cardRef: contRefs[+index],
                };
                displayConBenList.push(payload);
                return {};
            });
            this.setState({ displayContingentBenfList: displayConBenList, beneficiaryrefsContingent: contRefs });
        }
    };

    generateBeneficiaryRefs = ben => {
        const { beneficiaryrefsPrimary = [], beneficiaryrefsContingent = [] } = this.state;
        const final =
            ben === 'p'
                ? [...beneficiaryrefsPrimary, React.createRef()]
                : [...beneficiaryrefsContingent, React.createRef()];
        if (ben === 'p') {
            this.setState({ beneficiaryrefsPrimary: final });
        } else {
            this.setState({ beneficiaryrefsContingent: final });
        }
        return final;
    };

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    addPrimaryBeneficiary = () => {
        let { countPrimary } = this.state;
        const { displayPrimaryBenfList = [] } = this.state;
        if (displayPrimaryBenfList.length < 100) {
            countPrimary += 1;
            const refs = this.generateBeneficiaryRefs('p');
            const payload = {
                cardId: countPrimary,
                cardRef: refs[+countPrimary - 1],
            };
            displayPrimaryBenfList.push(payload);
            this.setState({ displayPrimaryBenfList, beneficiaryrefsPrimary: refs, countPrimary }, () => {
                document.getElementById(`beneficiaryP${countPrimary}BeneficiaryType`).focus();
            });
        } else {
            this.setState({ showErrorModal: true, alertMessage: 'max 100 beneficiaries allowed', countPrimary });
        }
    };

    addContingentBeneficiary = () => {
        let { countContingent } = this.state;
        const { displayContingentBenfList = [] } = this.state;
        if (displayContingentBenfList.length < 100) {
            countContingent += 1;
            const refs = this.generateBeneficiaryRefs('c');
            const payload = {
                cardId: countContingent,
                cardRef: refs[+countContingent - 1],
            };
            displayContingentBenfList.push(payload);
            this.setState({ displayContingentBenfList, beneficiaryrefsContingent: refs, countContingent }, () => {
                document.getElementById(`beneficiaryC${countContingent}BeneficiaryType`).focus();
            });
        } else {
            this.setState({ showErrorModal: true, alertMessage: 'max 100 beneficiaries allowed', countContingent });
        }
    };

    removeBeneficiary = (index, benType) => {
        const {
            displayPrimaryBenfList = [],
            displayContingentBenfList = [],
            beneficiaryrefsContingent,
            beneficiaryrefsPrimary,
        } = this.state;
        let displayBenfList = [];
        let beneficiaryrefs = [];
        let count = 0;
        if (benType === 'P') {
            displayBenfList = displayPrimaryBenfList;
            beneficiaryrefs = beneficiaryrefsPrimary;
            count = 1;
        } else {
            displayBenfList = displayContingentBenfList;
            beneficiaryrefs = beneficiaryrefsContingent;
        }
        if (displayBenfList.length > count) {
            const removeCardIndex = displayBenfList.findIndex(item => item.cardId.toString() === index);
            const removeBenRef = displayBenfList[+removeCardIndex].cardRef;
            const removeBenRefIndex = beneficiaryrefs.findIndex(item => item === removeBenRef);
            displayBenfList.splice(removeCardIndex, 1);
            beneficiaryrefs.splice(removeBenRefIndex, 1);
            if (benType === 'P') {
                this.setState({ displayPrimaryBenfList: displayBenfList, beneficiaryrefsPrimary: beneficiaryrefs });
            } else {
                this.setState({
                    displayContingentBenfList: displayBenfList,
                    beneficiaryrefsContingent: beneficiaryrefs,
                });
            }
            // this.setState({ displayBenfList,beneficiaryrefs });
        } else {
            this.setState({ showErrorModal: true, alertMessage: '1 beneficiary is mandatory' });
        }
    };

    handleDistribution = arr => {
        let totalDistribution = 0;
        const { accountSelected } = this.props;
        const childAccount = accountSelected.indexOf('child') > -1;
        if (!childAccount) {
            for (let i = 0; i < arr.length; i += 1) {
                if (Object.keys(arr[+i].length !== 0)) {
                    totalDistribution += Number(arr[+i].distributionPercentage);
                }
            }
            if (totalDistribution !== 100) {
                return true;
            }
        }
        return false;
    };

    handleValidation = arr => {
        const res = arr.filter(item => item.valid === false);
        if (res.length > 0) {
            return false;
        }
        return true;
    };

    handleDistributionErrorMsg = benType => {
        if (benType === 'P') {
            this.setState({ distributionPercentageErrorPrimary: false });
        } else {
            this.setState({ distributionPercentageErrorContingent: false });
        }
        // benType==='P' ? this.setState({ distributionPercentageErrorPrimary: false }) : this.setState({ distributionPercentageErrorContingent: false }) ;
    };

    getBeneficiaryArrayValues = arr => {
        return arr.map(item => {
            let data = {};
            if (item.current) {
                data = item.current.sendDataToBeneficaryCard();
            }
            return data;
        });
    };

    sendDataToParent = () => {
        const { beneficiaryrefsPrimary, beneficiaryrefsContingent } = this.state;
        const beneficiaryPrimaryDataArray = this.getBeneficiaryArrayValues(beneficiaryrefsPrimary);
        const beneficiaryContingentDataArray = this.getBeneficiaryArrayValues(beneficiaryrefsContingent);
        // const beneficiaryContingentDataArray = beneficiaryrefsContingent.map(item => {
        //     let data = {};
        //     if (item.current) {
        //         data = item.current.sendDataToBeneficaryCard();
        //     }
        //     return data;
        // });

        let resultValidation =
            this.handleValidation(beneficiaryPrimaryDataArray) && this.handleValidation(beneficiaryContingentDataArray);
        if (this.handleDistribution(beneficiaryPrimaryDataArray)) {
            this.setState({ distributionPercentageErrorPrimary: true });
            resultValidation = false;
        } else {
            this.setState({ distributionPercentageErrorPrimary: false });
        }
        if (beneficiaryContingentDataArray.length > 0 && this.handleDistribution(beneficiaryContingentDataArray)) {
            this.setState({ distributionPercentageErrorContingent: true });
            resultValidation = false;
        } else {
            this.setState({ distributionPercentageErrorContingent: false });
        }

        // if (!resultValidation) {
        //     this.accordionRef.current.click();
        // }

        return {
            beneficiaryPrimaryDataArray,
            beneficiaryContingentDataArray,
            // cardState: this.state,
            valid: resultValidation,
        };
    };

    handleErrorModal = () => {
        this.setState({ showErrorModal: false, alertMessage: '' });
    };

    render() {
        const { accountSelected, accountType } = this.props;
        const {
            displayPrimaryBenfList,
            displayContingentBenfList,
            beneficiaryPrimaryDataArray = [],
            beneficiaryContingentDataArray = [],
            distributionPercentageErrorPrimary = false,
            distributionPercentageErrorContingent = false,
            alertMessage,
            showErrorModal,
        } = this.state;
        const childAccount = accountSelected === 'inv_child';
        const isUTMA = accountType === 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)';
        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            ref={this.accordionRef}
                            style={styles.accordionToggleStyle}
                        >
                            {childAccount ? childUTMAHeading : BeneficiarisHeading}
                        </Accordion.Toggle>
                    </Card.Header>
                    <WErrorModal message={alertMessage} show={showErrorModal} modalClick={this.handleErrorModal} />
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {!childAccount && (
                                <>
                                    <h5 className="oneOrMoreBenetext" style={styles.mobileHeaderStyle}>
                                        {oneOrMoreBenetext}
                                    </h5>
                                    <span className="notError primaryPercAlloc">{primaryPercAlloc}</span>
                                </>
                            )}
                            {childAccount && <span className="notError primaryPercAlloc">{noteText}</span>}
                            {displayPrimaryBenfList &&
                                displayPrimaryBenfList.map((item, index) => {
                                    return (
                                        <BeneficiaryForm
                                            isChild={childAccount}
                                            savedState={beneficiaryPrimaryDataArray[+index]}
                                            key={item.cardId}
                                            index={item.cardId.toString()}
                                            ref={item.cardRef}
                                            isUTMA={isUTMA}
                                            handleDistributionErrorMsg={this.handleDistributionErrorMsg}
                                            distributionPercentageError={distributionPercentageErrorPrimary}
                                            sendToBeneficiaryParentToRemove={this.removeBeneficiary}
                                            benType={childAccount ? '' : 'P'}
                                        />
                                    );
                                })}
                            {!childAccount && (
                                <>
                                    <div className="distributionPercentageHint">
                                        <p>{distributionPercentageHint}</p>
                                    </div>
                                    <div className="benBtnWrapper">
                                        <button
                                            type="button"
                                            className="addAnotherFieldBtn addBtn addPrimaryBen"
                                            onClick={this.addPrimaryBeneficiary}
                                            aria-label={addPrimaryBeneficiaryText}
                                        >
                                            <WIcon src={plusIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                                            {addPrimaryBeneficiaryText}
                                        </button>
                                    </div>
                                </>
                            )}
                            {displayContingentBenfList &&
                                displayContingentBenfList.map((item, index) => {
                                    return (
                                        <BeneficiaryForm
                                            isChild={childAccount}
                                            savedState={beneficiaryContingentDataArray[+index]}
                                            key={item.cardId}
                                            index={item.cardId.toString()}
                                            ref={item.cardRef}
                                            handleDistributionErrorMsg={this.handleDistributionErrorMsg}
                                            distributionPercentageError={distributionPercentageErrorContingent}
                                            sendToBeneficiaryParentToRemove={this.removeBeneficiary}
                                            benType="C"
                                        />
                                    );
                                })}

                            {!childAccount && (
                                <>
                                    {displayContingentBenfList.length > 0 && (
                                        <div className="distributionPercentageHint">
                                            <p>{distributionPercentageHintCon}</p>
                                        </div>
                                    )}
                                    <div className="benBtnWrapper">
                                        <button
                                            type="button"
                                            className="addAnotherFieldBtn addBtn addContingentBen"
                                            onClick={this.addContingentBeneficiary}
                                            aria-label={addContingentBeneficiaryText}
                                        >
                                            <WIcon src={plusIcon} fontawesomestyle={styles.iconImgStyle} alt="" />
                                            {addContingentBeneficiaryText}
                                        </button>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
BeneficiaryCard.propTypes = {
    accountSelected: PropTypes.string,
    savedState: PropTypes.instanceOf(Object),
    accountType: PropTypes.string,
};

BeneficiaryCard.defaultProps = {
    accountSelected: '',
    savedState: {},
    accountType: '',
};
export default BeneficiaryCard;
