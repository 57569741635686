/* eslint-disable no-debugger */
import Amplify, { Auth } from 'aws-amplify';
import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import WSpinner from '../WSpinner/WSpinner';
import { SessionStorageHelper } from 'utils/sessionHelper';
import { getSessionValues, setMSRInfoValue } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
import { handleRetriveCount, setDataInSessionStorage, setErrorStatus } from './MSRSSOHelper';
class MSRSSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorAvail : false,
            spinner: true,
            genericidLookupData:null,
            renderCount:0,
            lookupApiCallCount:0
        };
    }

    setMSRInfoIntoSession = (authToken,acToken,fismemberid,usaaid) =>
    {
        localStorage.setItem('token', authToken);
        localStorage.setItem('accessToken', acToken);
        const localVcmId = sessionStorage.getItem('vcmid') ? sessionStorage.getItem('vcmid') : ''
        const localMsrId = sessionStorage.getItem('msrid') ? sessionStorage.getItem('msrid') : ''
        const applicationid = sessionStorage.getItem('applicationid') ? sessionStorage.getItem('applicationid') : ''
        const landingpage = sessionStorage.getItem('landingpage') ? sessionStorage.getItem('landingpage') : ''
        
            if (acToken) {
            
            const msrPayload = {
                accesstoken: acToken,
                fismemberid: fismemberid ? fismemberid : '' ,
                msrid: localMsrId ? localMsrId : '',
                usaaid:usaaid ? usaaid : '',
                vcmid: localVcmId ? localVcmId : '',
                applicationId:applicationid,
                landingpage: landingpage
            }

            setMSRInfoValue(msrPayload);

            console.log('msrPayload',msrPayload)
            
            sessionStorage.removeItem('vcmid');
            sessionStorage.removeItem('msrid');
            sessionStorage.removeItem('applicationid');
            sessionStorage.removeItem('landingpage');
            this.props.getMSRStatus();                
        }    
    }

    componentDidMount() {

        sessionStorage.removeItem('msr_dts')
        sessionStorage.setItem("isRefresh",true)
        document.title = 'Victory Capital Management';
        localStorage.clear();
        const {
            location: { search },
            history,
            genericidLookup
        } = this.props;
        
        const queryParams = queryString.parse(search);
        const {vcmid = '', msrid = '', applicationid='', landingpage='', fismemberid='', usaaid='' } = queryParams

        const REDIRECTURLSIGNIN = envConfig.REDIRECTURLSIGNIN_SSO;
        const REDIRECTURLSIGNOUT = envConfig.REDIRECTURLSIGNOUT_SSO;
        const REDIRECTPASSIVEURLSIGNIN = envConfig.REDIRECTPASSIVEURLSIGNIN_SSO;
        const landingPagePath = '/sign-in'

        let isPassiveRegion = window.location.hostname.startsWith('passive.')
        // let retriveCount = 0;
        setDataInSessionStorage(search,vcmid,msrid,applicationid,landingpage);
        this.setState({})
        const configuration = {            
                region: envConfig.AWS_COGNITO_REGION_SSO,
                userPoolId: envConfig.AWS_USER_POOLS_ID_SSO,
                userPoolWebClientId: envConfig.AWS_USER_POOLS_WEB_CLIENT_ID_SSO,
                oauth: {
                    domain: envConfig.OAUTH_DOMAIN_SSO,
                    scope: [envConfig.AWS_SCOPE_SSO],
                    redirectSignIn: isPassiveRegion ? REDIRECTPASSIVEURLSIGNIN : REDIRECTURLSIGNIN,
                    redirectSignOut: REDIRECTURLSIGNOUT,
                    responseType: 'code',                    
                },
        };

        Amplify.configure(configuration);

        if (search && search.includes('code')) {
            setTimeout(async () => {
                await Auth.currentSession()
                    .then(response => {

                        SessionStorageHelper.removeItem('retriveCount')
                        console.log('accessToken',response)
                        const { accessToken, idToken } = response;
                        const acToken = accessToken.jwtToken;
                        const authToken = idToken.jwtToken;                       
                        const decodeToken = jwt_decode(authToken);
                        this.setState(state => ({
                            ...state,
                            errorAvail: setErrorStatus(decodeToken)
                        }))
                        const {errorAvail} = this.state;  


                        if(errorAvail){
                            console.log('1 error',decodeToken)
                            const {err_code} = decodeToken;
                            this.setState({spinner:false})
                        }else{
                            this.setMSRInfoIntoSession(authToken,acToken,fismemberid,usaaid);            
                        }
                        
                    })
                    .catch(e => {
                        let retriveCount = parseInt(SessionStorageHelper.getItem('retriveCount'))
                        if (retriveCount <= 2) {

                            console.log('retriveCount @@ initial',retriveCount)
                            retriveCount += 1;
                            SessionStorageHelper.setItem('retriveCount',retriveCount)
                            Auth.federatedSignIn({provider: envConfig.FEDERATED_SIGNIN_PROVIDER_SSO})
                            .then(response => {
                                console.log('Sign In reinitiated',response);
                                retriveCount = handleRetriveCount(response,retriveCount);
                            }).catch(e => {
                                console.log('retriveCount @@ catch',retriveCount)

                                console.log('Sign in initiation failed')
                                if (retriveCount <= 2) {
                                    retriveCount += 1;
                                }
                                else
                                {
                                    SessionStorageHelper.removeItem('retriveCount')
                                    sessionStorage.setItem("isRefresh",false)
                                }
                                
                            })


                        } else {
                            console.log('@@@ end')
                            SessionStorageHelper.removeItem('retriveCount')
                            sessionStorage.setItem("isRefresh",false)
                            this.setState({spinner:false})

                            // history.push({
                            //     pathname: landingPagePath,
                            //     state: { err: 'ERRDESC', errDesc: e },
                            // });

                        }                
                    });
            }, 5000);
        }
        else {    
            localStorage.removeItem('token');
            localStorage.removeItem('accessToken');     
            Auth.federatedSignIn({provider: envConfig.FEDERATED_SIGNIN_PROVIDER_SSO}).then(response => {
                console.log('Login initiated');
            }).catch(e => {
                console.log('Login initiation failed');    
            })                         
        }
    }


    updateRedirectUrlToHistory = () =>
    {
        const {history} = this.props;
        const msrDataObj = getSessionValues();
        const applicationId =  msrDataObj.applicationId.trim();
        const msrData =  msrDataObj;
        const isDocumentCenterText = msrData?.landingpage?.toLowerCase()?.trim();
        if(applicationId)
        {
            console.log('5')

            history.push({
                pathname:'/redirectToBrokerageNAO',
                state:{
                    applicationId: applicationId
                }
            });
        }  
        else if(msrData?.vcmid == "" || isDocumentCenterText == "documentcenter")
        {
            history.push('/documentCenter');
        }     
        else
        {
            history.push('/accountholder');
        } 

    }

    componentDidUpdate(prevState) {
        const {
            redirectFlag: { isMSRFlow },
            history,
            genericidLookup
        } = this.props;       

        

        console.log('component DID mount',this.props)
        console.log('component DID mount MSRRR',isMSRFlow)
        if (isMSRFlow) {    
            
            console.log('1')
            /// Gaurav code Start

         if(this.state.renderCount == 0)
         {
            console.log('2')

            const msrInfoData = getSessionValues();
            const payload = {
                requestPayload :{
                "id": msrInfoData?.vcmid,
                "idType": "VCM"
            }
           ,
            headers: {
                accessToken:msrInfoData?.accesstoken
            }
             }


            if(this.state.lookupApiCallCount == 0)
            {
                genericidLookup(payload);
                this.setState({
                    lookupApiCallCount:1
                 })
            }
            
            if(this.props !== prevState)
            {

            console.log('3')

                if(this.props.loginData?.genericidLookupData !=null)
                {
            console.log('4')

                    this.setState({
                        genericidLookupData:this.props.loginData?.genericidLookupData,
                        spinner:false,
                        renderCount:1
        
                    })
                    let storageData = getSessionValues();
   
                    storageData['fismemberid'] =this.props.loginData?.genericidLookupData?.result?.fisid;
                    storageData['usaaid'] = this.props.loginData?.genericidLookupData?.result?.usaaid; 
                   

                    setMSRInfoValue(storageData)
                    // const encData = CryptoJS.AES.encrypt(JSON.stringify(storageData), encySalt).toString(); 

                    // SessionStorageHelper.setMSRInfoData('msr_dts',encData)
                    
                    //sessionStorage.setItem('msr_dts',JSON.stringify(storageData))

                        this.updateRedirectUrlToHistory();

                }
               
            }
            /// 30-12-2022 Gaurav
            else if(this.props.loginData?.genericidLookupData !=null && this.props.loginData?.genericidLookupData?.error == true)
            {
                this.setState({
                    renderCount:1
                })
            }
            // 30-12-2022 Gaurav
         }

         console.log('7')
         /// Gaurav code End

        }

        console.log('8 outside if')

       
    }
    


    render() {
        const {spinner,genericidLookupData} = this.state;
        return (
            <div className="container">   
            {
                (genericidLookupData !=null) &&
                <div>
                    <p>USAAID : {genericidLookupData?.result?.usaaid}</p>
                    <p>FISID : {genericidLookupData?.result?.fisid}</p>
                    <p>ASCENSUSID : {genericidLookupData?.result?.ascensusid}</p>
                    <p>Root9AccountID : {genericidLookupData?.result?.root9AccountId}</p>
                    <p>VCMID : {genericidLookupData?.result?.vcmId}</p>
                </div>
            }             
                <WSpinner loading={spinner}/>                
            </div>
        );
    }
}

MSRSSO.defaultProps = {
    history: {},
    location: {},
};

MSRSSO.propTypes = {
    history: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
};

export default MSRSSO;