import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import buffer from 'buffer';
import envConfig from 'env/EnvConfig';

const awsKey = envConfig.AWS_KMS_KEY;

const awsconfig = {
    "region": envConfig.AWS_PROJECT_REGION,
    "cmkArn": awsKey
};

function getKMSClient(info) {
  // console.log('@debug', awsconfig);
  const credentials = new AWS.Credentials(
    info.accessKeyId,
    info.secretAccessKey,
    info.sessionToken,
  );
  AWS.config.update({
    region: awsconfig.region,
    credentials,
    correctClockSkew: true,
  });
  return new AWS.KMS();
}


async function decrypt(inputData) {
  const kmsCredentials = await Auth.currentCredentials(); 

  const kms = getKMSClient(kmsCredentials); 

  if(inputData) {
    const params = {
      'KeyId': awsKey,
      'EncryptionAlgorithm': 'SYMMETRIC_DEFAULT',
      'CiphertextBlob': buffer.Buffer.from(inputData, 'base64'),
    };
    const promise = new Promise(function(resolve, reject){
      //  console.log('@debug for params ', params);
      kms.decrypt(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
          resolve(data.Plaintext.toString());
        }
      });
    });
    return await promise;
  } 
    return null;
  
  
  
}
export default decrypt;
           