import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as ActivityActions from 'shared/Actions/TransactionActivitiesActions';
import TransactionActivity from './TransactionActivity';

const mapStateToProps = state => ({
    transactionActivityData: state.transactionActivityData,
});

const mapDispatchToProps = {
    ...ActivityActions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionActivity));
