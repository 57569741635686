import styled from 'styled-components';

import { Col, Row } from 'react-bootstrap';

const fontSize = {
    regular: 400,
    medium: 500,
    extraBold: 800,
    bold: 700,
};
const Styles = {
    NoRecords: styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-top: 5px;
    `,
    FormTitle: styled(Col)`
        color: rgba(72, 109, 144, 1);
        font: 800 24px/36px yorkten-slab-normal;
       
        height: fit-content;
        padding-bottom: 10px;
      
    `,
    Container: styled.div`
        & .row {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 15px;
        }
        & [class*='col-'] {
            padding-left: 0px;
            padding-right: 0px;
        }
    `,
    Link: styled.a`
        color: #004a98;
        text-decoration: underline;
        cursor: pointer;
        opacity: 1;
        font-weight: ${fontSize.medium};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 19px;
    `,
    SubTitle: styled.div`
        font-weight: bold;
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 26px;
        color: #004a98;
    `,
    Paragraph: styled.div`
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 26px;
        color: #49494a;
    `,
    FormHeader: styled(Row)`
        margin-top: 25px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 2px solid #8bc105;
        font: Bold 16px/20px benton-sans,sans-serif;
        line-height: 24px;
        color: #49494a;
        padding-bottom: 15px;
        text-align: left;
        color: #004a98;
    `,
    FormItem: styled(Row)`
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: ${fontSize.regular};
        font-family: 'benton-sans';
        font-size: 16px;
        line-height: 20px;
    `,
    FormItem: styled(Col)`
    padding-left:15px !important;
    padding-right:15px !important;
    `,
    LinkIconDiv: styled.div`
        display: flex;

        & a {
            margin-right: 5px;
        }
    `,
    Rating: styled(Col)`
        text-align: right;
        display: flex;
        padding-left: 15px !important;
        & small {
            margin-left: 15px !important;
        }
    `,
    SortBy: styled(Col)`
        color: #004a98;
        font-weight: ${fontSize.extraBold};
        font-family: Yorkten Slab;
        font-size: 16px;
        line-height: 20px;
        display: flex !important;
        padding-left: 15px;
    `,

    BoxLink: styled.div`
    color: #486d90;
        text-decoration: none;
        cursor: pointer;
        opacity: 1;
        font-weight: bold;
        font-family: yorkten-slab-normal,sans-serif;
        font-size: 22px;
        line-height: 19px;
        text-align: center;
        height: 76px;
        padding-top: 23px;
        padding: 15px 20px 25px 20px;
        cursor: pointer;
        
    `,
   
    BoxArrowDown1: styled.span`
        display:inline-block;
        line-height: 30px;
        border-bottom:3px solid #486d90;
    `,
    BoxArrowDown: styled.div`
        
        box-sizing: border-box;
        box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
        text-align: center;
        height: 76px;
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #486d90;
        opacity: 1;
        font-weight: bold;
        font-family: yorkten-slab-normal,sans-serif;
        font-size: 22px;
        line-height: 22px;
        padding-top: 17px;
        padding: 15px 20px 25px 20px;
        :after {
            top: auto;
            bottom: -9px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #fff;
            height: 20px;
            width: 20px;
            position: absolute;
            content: '';
            background: #fff;
            border: 0;
            transform: rotate(45deg);
            left: calc(50% - 10px);
            box-shadow: 3px 4px 5px -2px rgb(0 0 0 / 30%);
        }
        :hover{
            cursor:pointer;
            border:1px solid #000;
        }
    `,
   
    Forms: styled.div`
        .form-row:nth-child(even) {
            background: #fafafa 0% 0% no-repeat padding-box;
        }
    `,
    ImgSrc: styled.img`
        height: 20px;
        width: 20px;
    `,
    Footer: styled.div`
    cursor: pointer;
    `,
};

export default Styles;
