import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import StyledToolTip from 'common/WToolTip/StyledToolTip';
import styles from '../CommonStyles';

const StyledButton = styled.button`
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    background: url(../../assets/hint-small.png) no-repeat center;
    width: 20px;
    height: 20px;
    padding: 0;
`;
const StyledFormGroup = styled(Form.Group)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;
const StyledFormLabel = styled(Form.Label)`
    color: rgba(73, 73, 74, 1);
    font-size: 16px;
    font-family: benton-sans;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 5px;
    line-height: 22px;
`;

class WMultipleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: '',
        };
    }

    toggleToolTip = id => e => {
        // debugger
        let toolTipOpen = '';
        if (e.target.id === id && e.type === 'focusin') {
            toolTipOpen = id;
        }
        this.setState({ tooltipOpen: toolTipOpen });
    };

    render() {
        const { onChange, clearchecked, labelstyle, data, info } = this.props;
        const { tooltipOpen } = this.state;
        return (
            <div className="container-fluid" style={styles.paddingZeroStyle}>
                {data.map((item, index) => {
                    return (
                        <div key={Number(index)}>
                            <StyledFormGroup id={Number(index)}>
                                <Form.Control
                                    style={{ padding: '0px' }}
                                    tabIndex="0"
                                    as="input"
                                    type="checkbox"
                                    role="option"
                                    name={item.key || ''}
                                    value={item.value}
                                    id={item.value}
                                    onChange={onChange}
                                    checked={clearchecked === 'true' ? false : !!item.checked}
                                />
                                <StyledFormLabel style={labelstyle} htmlFor={item.value}>
                                    {item.value}
                                    {info === 'true' && (
                                        <>
                                            <StyledButton className="infoIcon" id={`infoIcon-${item.key}`} />
                                            <StyledToolTip
                                                placement="bottom"
                                                tooltipOpen={tooltipOpen === `infoIcon-${item.key}`}
                                                targetID={`infoIcon-${item.key}`}
                                                handleToggle={this.toggleToolTip(`infoIcon-${item.key}`)}
                                                autohide={true}
                                                triggerEvt="focus"
                                                tooltipText={item.value}
                                            />
                                        </>
                                    )}
                                </StyledFormLabel>
                            </StyledFormGroup>
                        </div>
                    );
                })}
            </div>
        );
    }
}

WMultipleCheckbox.propTypes = {
    // id:PropTypes.string,
    info: PropTypes.string,
    data: PropTypes.instanceOf(Array),
    clearchecked: PropTypes.bool.isRequired,
    labelstyle: PropTypes.instanceOf(Object),
    onChange: PropTypes.func,
};

WMultipleCheckbox.defaultProps = {
    info: 'false',
    data: [],
    labelstyle: {},
    onChange: null,
};

export default WMultipleCheckbox;

/* Usage
<WMultipleCheckbox 
    info="true"                             // depending on this info icon will display   
    data=[
        {
            label:'CheckBox1',
            value:'CheckBox1',        
        },
        {
            label:'CheckBox2',
            value:'CheckBox2',                   
        }
    ]                                       //provide array for your checkbox labels                
    onClick={this.onCheckboxClick} />       //provide clickevent  for your checkbox

    /*****click event example 
    onCheckboxClick=(e)=>{    
        if(e.target.checked){
            this.state.filterMinimumInvestmentList.push(e.target.value);            
        }else{         
            const index=this.state.filterMinimumInvestmentList.indexOf(e.target.value)
            if(index>-1){
                this.state.filterMinimumInvestmentList.splice(index);
            }            
        }  
    }
*/
