import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {localStateManagementActions} from '../../../../shared/Actions';
import JovSSN from './JovSSN';

const mapStateToProps = (state) => (
    {
        jovSsnState: state.localStateManagementReducerData.jovSsnState
    }
);

const mapDispatchToProps = {
    ...localStateManagementActions
};

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(JovSSN));