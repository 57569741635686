// Message center Form labels

export const MESSAGE_CENTER_INBOX = 'Inbox';
export const MESSAGE_CENTER_SENT_ITEMS = 'Sent Items';
export const MESSAGE_CENTER_DELETED_ITEMS = 'Deleted Items';
export const MESSAGE_CENTER_CREATE_SECURE_MESSAGE = 'Create Secure Message';
export const MESSAGE_CENTER_DATE = 'Date';
export const MESSAGE_CENTER_ASCENDING = 'Ascending';
export const MESSAGE_CENTER_DATE_DESCENDING = 'Descending';
export const MESSAGE_CENTER_DELETE = 'Delete';
export const MESSAGE_CENTER_MARK_AS_READ = 'Mark as Read';
export const MESSAGE_CENTER_MARK_AS_UNREAD = 'Mark as Unread';
export const MESSAGE_CENTER_LOAD_MORE = 'Load More';
export const MESSAGE_CENTER_SORT = 'Sort by date: ';
export const MESSAGE_CENTER_SELECT_ALL = 'Select All';
export const MESSAGE_CENTER_MESSAGE_CENTER = 'Message Center';
export const MESSAGE_CENTER_DELETE_MODAL_TITLE = 'Delete Message(s)';
export const MESSAGE_CENTER_DELETE_MODAL_BODY_TEXT = 'Are you sure you want to delete message(s)?';
export const MESSAGE_CENTER_CANCEL = 'Cancel';
export const MESSAGE_CENTER_URGENT_MESSAGE = 'urgent';
export const MESSAGE_CENTER_NO_MESSAGES = 'Currently, you do not have any messages.';
export const MESSAGE_CENTER_READ_ALT = 'Read';
export const MESSAGE_CENTER_UNREAD_ALT = 'Unread';
export const MESSAGE_CENTER_MESSAGE_TAG_ALT = 'Message tag';
export const MESSAGE_CENTER_ELLIPSIS = '...';
export const MESSAGE_CENTER_REPLY = 'Reply';

// Message center values
export const MESSAGE_CENTER_INBOX_VAL = 'inbox';
export const MESSAGE_CENTER_DELETED_VAL = 'deleted';
export const MESSAGE_CENTER_SENT_VAL = 'sent';
export const MESSAGE_CENTER_DATE_VAL = 'date';
export const selectOptionsKV = [
    { key: 'inbox', value: MESSAGE_CENTER_INBOX },
    { key: 'sent', value: MESSAGE_CENTER_SENT_ITEMS },
    { key: 'deleted', value: MESSAGE_CENTER_DELETED_ITEMS },
];

export const selectOptionsSortOrderBy = [{ key: 'date', value: MESSAGE_CENTER_DATE }];
export const selectOptionsSortOrderType = [
    { key: 'descending', value: MESSAGE_CENTER_DATE_DESCENDING },
    { key: 'ascending', value: MESSAGE_CENTER_ASCENDING },
];

export const MESSAGE_CENTER_CHECKBOXSTYLE = { position: 'absolute', top: '10px' };
export const MESSAGE_CENTER_LAST_LOGIN_DISPLAY_TIMER = 10000;
export const MESSAGE_CENTER_MORE_THAN_FIVE_MESSAGE = 'You have more than 5 new messages since your last login';
export const MESSAGE_CENTER_NO_NEW_MESSAGE = 'No new messages since your last login';
