/**
 * Add/edit Primary/Contingent Beneficaiaries Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to Add/edit Primary/Contingent Beneficaiaries
 * @createdDate [03/12/2020]
 * ***************************************
 * @lastModifiedDate [03/17/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
// import Accordion from 'react-bootstrap/Accordion';
// import PDFIcon from '../../../assets/pdfdownloadsmall.png';
import { WBreadCrumb, WIcon } from '../../../common';
import consts from './consts';
import './AddprimaryBeneficiary.css';
import '../ManageBeneficiaries/ManageBeneficiaries.css';

const assignObj = obj => {
    return obj;
};
class AddPrimaryBeneficiary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // errorMsg: [],
            beneficiaryData: [],
            showMenu: '',
        };
    }

    componentDidMount() {
        // const { manageBeneficiaryData } = this.props;
        // if (
        //     this.props &&
        //     manageBeneficiaryData &&
        //     manageBeneficiaryData.manage_beneficiary &&
        //     manageBeneficiaryData.manage_beneficiary
        // ) {
        //     this.setState({ beneficiaryData: manageBeneficiaryData.manage_beneficiary });
        // }
    }

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    showOptions = e => {
        const { showMenu } = this.state;
        const temp = showMenu === e.target.name ? '' : e.target.name;
        this.setState({ showMenu: temp });
    };

    render() {
        const { benType } = this.props;
        const data = {
            icon: '',
            accountName: 'Sample Content',
            accountNumber: '1234-5678-90123',
            accountType: 'Roth IRA',
            registrationOwner: 'Sample Content',
            balance: '$ 23541',
        };
        return (
            <div className="beneficiaryWrapper manageBen">
                <Container>
                    <WBreadCrumb
                        breadCrumbItems={consts.generalUrls}
                        activeCrumb={consts.activeUrl[benType.toString()]}
                    />
                    <Row noGutters className="beneFiciaryHeaderWrapper">
                        <h1 className="pageHeading">{consts.activeUrl[benType.toString()]} </h1>
                    </Row>
                    <Row noGutters className="beneficiaryHeader">
                        <div className="beneficiaryHeaderCol">
                            <WIcon src={data.icon || ''} fontawesomestyle={assignObj({ margin: 0 })} />
                            <span className="headerval" data-key="accountName">
                                {data.accountName}
                            </span>
                            <span className="headerval" data-key="accountNumber">
                                {data.accountNumber}
                            </span>
                        </div>
                        <div className="beneficiaryHeaderCol">
                            <span className="headerkey" data-key="accountName">
                                {data.accountType}
                            </span>
                            <span className="headerval" data-key="accountNumber">
                                {data.accountName}
                            </span>
                        </div>
                        <div className="beneficiaryHeaderCol">
                            <span className="headerkey" data-key="accountName">
                                {consts.registrationOwner}
                            </span>
                            <span className="headerval" data-key="accountNumber">
                                {data.registrationOwner}
                            </span>
                        </div>
                        <div className="beneficiaryHeaderCol">
                            <span className="headerkey" data-key="accountName">
                                {consts.balance}
                            </span>
                            <span className="headerval" data-key="accountNumber">
                                {data.balance}
                            </span>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

AddPrimaryBeneficiary.propTypes = {
    benType: PropTypes.string,
    //  manageBeneficiaryData:PropTypes.func,
};

AddPrimaryBeneficiary.defaultProps = {
    benType: 'primary',
    //  manageBeneficiaryData:()=>{}
};

export default AddPrimaryBeneficiary;
