import Constants from 'modules/AccountOptions/constant';
import { formatDate, isValidDate } from 'utils';
import { PageHeading, Accordian, AccordianTitle, VCMDatePicker } from 'common';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
`;
const FlexRowDiv = styled(Flex)`
    flex-direction: row;
    align-items: center;
`;
export const DetailsRow = styled(FlexRowDiv)`
    padding: 1.25rem;
    background: ${props => (props.index % 2 ? '#FFFFFF' : '#fafafa')} 0% 0% no-repeat padding-box;
    opacity: 1;
`;

const Field = styled.div`
    flex: 0 0 15em;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const Value = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    white-space: pre-line;
`;
const NoteTxt = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;

    margin-bottom: 3.75rem;
    padding: 1.625rem 1.875rem;

    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;

    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;
const DetailsSec = styled.div`
    margin-top: 2.5rem;
    margin-bottom: 2.3125rem;
`;

const RequiredDiv = styled.div`
    text-align: right;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;

const SkipPlan = props => {
    const { planDetatils, contents, onDateChange, skipFromDate, skipToDate } = props;
    const { detailsSection, dateSection } = contents || {};
    const { details: planDetailsContent = [], title = '', note = '' } = detailsSection || {};
    const { title: dateSecTitle = '', details: dateSecDetails = [] } = dateSection || {};
    const {
        accountName: accName = '',
        'accountNumber(masterRegId)': accNumber = '',
        investedFundAmount: amount = '',
        endDate = 'Never',
        payeeBankNam: fundFrom = '',
        datePADInitialized: padStartDate = '',
        dateSWPInitialized: swpStartDate = '',
        schedule = 'M',
        share = {},
    } = planDetatils || {};

    const startDate = padStartDate || swpStartDate;
    const minStartDate = new Date();
    const minEndDate = skipFromDate ? new Date(skipFromDate) : new Date();
    minEndDate.setDate(minEndDate.getDate() + 1);
    let defaultMax = new Date();
    defaultMax = new Date(defaultMax.setFullYear(defaultMax.getFullYear() + 100));
    const maxDate = isValidDate(endDate) ? formatDate(endDate) : formatDate(defaultMax);
    
    const value = {
        "0": `${accName} / Account Number ${accNumber}`,
        "1": fundFrom,
        "2": share.text,
        "3": `${Constants.symbols.dollar} ${amount}`,
        "4": Constants.frequency[schedule.toString()],
        "5": startDate,
        "6": endDate,
    };
    const getValue = (index) =>{
        return value[+index];
    }

    const planDetailsArr = planDetailsContent.map((item, index) => {
        const newItem = { ...item };
        newItem.value = getValue(index);
        return newItem;
    });
    
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            <Flex>
                <PageHeading>{title}</PageHeading>
            </Flex>
            <DetailsSec>
                {planDetailsArr.map((data, index) => {
                    return (
                        <DetailsRow index={index} key={data.key + index.toString()}>
                            <Field>{data.key}</Field>
                            <Value>{data.value}</Value>
                        </DetailsRow>
                    );
                })}
            </DetailsSec>
            {note && <NoteTxt>{note}</NoteTxt>}
            <RequiredDiv>{Constants.requiredfield}</RequiredDiv>
            <Accordian key={dateSecTitle} titleChild={<AccordianTitle accType={dateSecTitle} />} titleSeperator>
                <>
                    {dateSecDetails.map((data, index) => {
                        return (
                            <VCMDatePicker
                                id={data.id}
                                key={data.id + index.toString()}
                                min={data.id === 'skipFromDate' ? formatDate(minStartDate) : formatDate(minEndDate)}
                                max={maxDate}
                                onChange={onDateChange(data.id)}
                                label={data.key}
                                value={data.id === 'skipFromDate' ? skipFromDate : skipToDate}
                                valuesm={3}
                                errorTxt={data.id === 'skipFromDate' ? 'This cannot be a past date' : 'This cannot be a past date or current date'}
                            />
                        );
                    })}
                </>
            </Accordian>
        </>
    );
};

SkipPlan.propTypes = {
    planDetatils: PropTypes.instanceOf(Object),
    contents: PropTypes.instanceOf(Object),
    onDateChange: PropTypes.func,
    skipFromDate: PropTypes.string,
    skipToDate: PropTypes.string,
};
SkipPlan.defaultProps = {
    planDetatils: {},
    contents: {},
    onDateChange: () => {},
    skipFromDate: '',
    skipToDate: '',
};

export default SkipPlan;
