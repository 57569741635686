const summaryParaStyles = {
    marginTop: 21,
    marginBottom: 35,
    font: '16px/25px benton-sans',
    color: '#56565A',
    textAlign: 'justify',
    fontSize: 16,
};

const summaryBoxStyle = {
    marginTop: 36,
    border: 1,
    borderStyle: 'solid',
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    width: 219,
};

const summarySubHeadingStyle = {
    font: '800 20px/35px Roboto Slab',
    color: '#56565A',
    textDecoration: 'underline',
    fontSize: 20,
};

const summaryHeadingStyle = {
    font: '800 30px/36px yorkten-slab-normal',
    color: 'rgba(72, 109, 144, 1)',
    fontSize: 30,
    float: 'left',
    paddingBottom: 5,
    borderBottom: '2px solid rgba(140, 191, 38, 1)',
    marginBottom: 40,
};

const amount = {
    font: '16px/22px benton-sans',
    color: '#56565A',
    fontSize: 16,
};

const invest = {
    font: ' 700 16px/19px benton-sans',
    color: ' #333333DE',
    fontSize: 16,
};
const containerStyle = { backgroundColor: '#F9FBFC', position: 'relative' };

const headerStyle = {
    color: 'rgba(0, 74, 152, 1)',
    marginTop: 37,
    marginBottom: 47,
    font: '800 16px/20px yorkten-slab-normal',
    background: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: 42,
    position: 'relative',
    height: 32,
    textTransform: 'capitalize',
    opacity: 1,
};

const navStyle = {
    width: 'fit-content',
    border: 'none',
    paddingBottom: '20px',
};

const navLinkStyle = { marginRight: 0, paddingRight: 0 };

const iconImgStyle = { position: 'absolute', top: 0, left: 0, width: 32, height: 32 };

const nextstyle = { display: 'none' };

export default {
    navStyle,
    navLinkStyle,
    headerStyle,
    summaryParaStyles,
    summaryBoxStyle,
    summarySubHeadingStyle,
    summaryHeadingStyle,
    amount,
    invest,
    containerStyle,
    iconImgStyle,
    nextstyle,
};
