import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { localStateManagementActions, loginActions, analytics } from '../../../../shared/Actions';
import RegisterPassword from './RegisterPassword';

export const mapStateToProps = (state /* , props */) => (
    {
        registerOnlineIdState: state.localStateManagementReducerData.registerOnlineIdState,
        registerState: state.localStateManagementReducerData.registerState,
        personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
        loginState: state.loginData,
        directSignUpData:  get(state, 'loginData.directSignUp', {}),
        otpDetails: state.localStateManagementReducerData.otpDetails,
        pageData: state.localStateManagementReducerData.pageData,
    }
  );

const mapDispatchToProps = {
     ...localStateManagementActions,
     ...loginActions,
     ...analytics
  };


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterPassword));