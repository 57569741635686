import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import envConfig from 'env/EnvConfig';

class Captcha extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      onCaptchaChange, size, isBottom, bottomPosition,
    } = this.props;
    return (
      <ReCAPTCHA
        sitekey={envConfig.RECAPTCHA_SITE_KEY}
        onChange={onCaptchaChange}
        className="pre-reg-captcha"
        size={size}
        style={{
          bottom: isBottom ? bottomPosition : 0,
        }}
      />
    );
  }
}

Captcha.propTypes = {
  onCaptchaChange: PropTypes.func,
  size: PropTypes.string,
  isBottom: PropTypes.bool,
  bottomPosition: PropTypes.number,
};

Captcha.defaultProps = {
  onCaptchaChange: () => {},
  size: 'normal',
  isBottom: false,
  bottomPosition: 0,
};

export default Captcha;
