

export const  getCalculatedGroupStateTax = (groupStateTax,FWH_TEXT,fedTaxAmount,DAT_TEXT,amount) => {
    let groupStateTaxValue = '';
    if (groupStateTax.basis === FWH_TEXT && fedTaxAmount > 0) {
        groupStateTaxValue = ((groupStateTax.stateTax * 100) / fedTaxAmount).toFixed(2);
    } else if (groupStateTax.basis === DAT_TEXT) {
        groupStateTaxValue = ((groupStateTax.stateTax * 100) / amount).toFixed(2);
    }
    return groupStateTaxValue;
}

export const isStateTaxGroup = (stateCodeGroup,GROUP_2B_TEXT,GROUP_3_TEXT,GROUP_4_TEXT,GROUP_5_TEXT) =>
{
  
    return (stateCodeGroup === GROUP_2B_TEXT ||
    stateCodeGroup === GROUP_3_TEXT ||
    stateCodeGroup === GROUP_4_TEXT ||
    stateCodeGroup === GROUP_5_TEXT)
}

export const getGroup3DistAmountMinRule = (stateCodeGroup,GROUP_3_TEXT,residenceState,amount) =>
{
    return (stateCodeGroup === GROUP_3_TEXT && residenceState === 'VT' && amount < 200)
}

export const getGroup4DistAmountMinRule = (stateCodeGroup,GROUP_4_TEXT,residenceState,amount) =>
{
    return (stateCodeGroup === GROUP_4_TEXT && (residenceState === 'AR' || residenceState === 'KS') && amount < 200)
}

export const getGroup5DistAmountMinRule = (stateCodeGroup,GROUP_5_TEXT,residenceState) =>
{
    return (stateCodeGroup === GROUP_5_TEXT && (residenceState === 'NC' || residenceState === 'MI' || residenceState === 'CT'))
}

export const getStateCodeWIthStateTaxWithHold  = (stateCodeGroup,stateCodeValue,stateTaxWithHold,residenceState,residenceStateValue) =>
{
    return ( 
        stateCodeGroup === stateCodeValue &&
        stateTaxWithHold &&
        residenceState === residenceStateValue);
}

export const getStateCodeWithAgeAndTotalTaxValue = (stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,residenceStateValue,totalTaxes) =>
{
    return (
        getStateCodeWIthStateTaxWithHold(stateCodeGroup,GROUP_2B_TEXT,stateTaxWithHold,residenceState,residenceStateValue) 
        && (age < 59.5 && totalTaxes <= 99)
        );
}

export const getStateCodeWithAgeValue = (stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,residenceStateValue) =>
{
    return (
        getStateCodeWIthStateTaxWithHold(stateCodeGroup,GROUP_2B_TEXT,stateTaxWithHold,residenceState,residenceStateValue) 
        && (age < 59.5 )
        );
}

export const validateStateTaxMinRule = (setIsError,setErrorObj,setTaxValidation,stateTaxValue,groupStateTaxPercent,stateCodeGroup,GROUP_2B_TEXT,age,stateTaxWithHold,residenceState,STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS,STATE_TAX_MIN_VALUE_WARNING_MSG) =>
{
    if (+stateTaxValue < groupStateTaxPercent || !stateTaxValue) {
        setIsError(true);
        if (
            stateCodeGroup === GROUP_2B_TEXT &&
            age < 59.5 &&
            stateTaxWithHold &&
            residenceState === 'MS'
        ) {
            setErrorObj(
                `${STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS} ${groupStateTaxPercent}%.`,
            );
        } else {
            setErrorObj(`${STATE_TAX_MIN_VALUE_WARNING_MSG} ${groupStateTaxPercent}%.`);
        }
        setTaxValidation(true);
    } else {
        setIsError(false);
        setTaxValidation(false);
    }
}

export const handleStateTaxKeyDownValue = (e,setStateTaxDecimalFound,stateTaxDecimalFound) =>
{
        /* Logic to prevent user to input more than one decimal point */
        if (e.keyCode === 110) {
            setStateTaxDecimalFound(true);
        } else {
            setStateTaxDecimalFound(false);
        }
        if (e.keyCode === 110 && e.target.value === '') {
            setStateTaxDecimalFound(true);
        }
        // check for no negative symbol
        if (
            e.keyCode === 189 ||
            e.keyCode === 187 ||
            e.keyCode === 109 ||
            e.keyCode === 107 ||
            e.keyCode === 69 ||
            e.keyCode === 17 ||
            e.keyCode === 91 ||
            e.keyCode === 86 ||
            (stateTaxDecimalFound && e.keyCode === 110)
        ) {
            e.preventDefault();
        }

        return true;
}

export const handleFedTaxKeyDownValue = (e,setFedTaxDecimalFound,fedTaxDecimalFound)=> {
    /* Logic to prevent user to input more than one decimal point */
    if (e.keyCode === 110) {
        setFedTaxDecimalFound(true);
    } else {
        setFedTaxDecimalFound(false);
    }
    if (e.keyCode === 110 && e.target.value === '') {
        setFedTaxDecimalFound(true);
    }
    // check for no negative symbol
    if (
        e.keyCode === 189 ||
        e.keyCode === 187 ||
        e.keyCode === 109 ||
        e.keyCode === 107 ||
        e.keyCode === 69 ||
        e.keyCode === 17 ||
        e.keyCode === 91 ||
        e.keyCode === 86 ||
        e.keyCode === 38 ||
        e.keyCode === 40 ||
        (fedTaxDecimalFound && e.keyCode === 110)
    ) {
        e.preventDefault();
    }
    return true;
}

export const getDisabledStateForTaxInput = (stateCodeGroup,GROUP_3_TEXT,GROUP_4_TEXT,fedTaxAmount,group3DistAmountMinRule,group4DistAmountMinRule,GROUP_5_TEXT) =>
{
    return (
        (stateCodeGroup === GROUP_3_TEXT && fedTaxAmount > 0) ||
        (stateCodeGroup === GROUP_4_TEXT && fedTaxAmount > 0) ||
        group3DistAmountMinRule ||
        group4DistAmountMinRule ||
        stateCodeGroup === GROUP_5_TEXT ||
        false
    )
}

export const getStatusWithTwoAndAndOneEqunityCondition = (value1,value2,equalityPara1,equalityPara2) =>
{
    return (value1 && value2 && (equalityPara1 === equalityPara2));
}

export const checkDecimalValueLengthMoreTheTwo = (decimalValue) =>
{
    return (decimalValue && decimalValue?.length === 2 && decimalValue?.[1]?.length > 2);
}

export const checkDecimalValueLengthMoreTheOneAndSingleEquality = (decimalValue,e) =>
{
    return ( decimalValue[0].length > 1 && e.target.value.charAt(0) === '0');
}

export const checkStateCodeGroupAndFedTax = (stateCodeGroup,firstGroupValue,secondGroupValue,fedTax,fedTaxValue) =>
{
    return ( 
        (stateCodeGroup === firstGroupValue || stateCodeGroup === secondGroupValue) &&
    (fedTax || fedTax === fedTaxValue)
    
    );
}

export const getStateTaxStateCodeAndAge = (isSetStateTax,stateCodeGroup,GROUP_2B_TEXT,age,ageValue) =>
{
    return(
        (isSetStateTax || (stateCodeGroup === GROUP_2B_TEXT && age < 59.5))
    );
}

export const checkStateCodeGroupWithOneValue = (stateCodeGroup,groupText)=>
{
    return (
        stateCodeGroup && stateCodeGroup.length !== 0 && stateCodeGroup !== groupText
    )
}

export const getValueGretherThen_99_AND_100 = (e,decimalValue) =>
{
    return (parseFloat(e.target.value) > 100 || decimalValue[1] > 99)
}

export const handleDecimalTwoValue = (decimalValue) =>
{
    if (decimalValue?.length === 2) {
        return true;
    } else {
        return false;
    }
}

export const getSubStringValue = (decimalValue,e) =>
{
    let value =e.target.value;
    if (checkDecimalValueLengthMoreTheTwo(decimalValue)) {
        value = e.target.value.substr(0, e.target.value.length - 1);
    }
    return value;
}

export const getValueBasedOnIndex = (decimalValue,e) =>
{
    let value =e.target.value;
    if (checkDecimalValueLengthMoreTheOneAndSingleEquality(decimalValue,e)) {
        value = e.target.value.indexOf('0') === 0 ? e.target.value.substring(1) : e.target.value;
    }
    return value;
}