import * as Constants from './Constants';
import * as Styles from './Styles';
import FileUploader from 'common/FileUploader/FileUploader';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RemoveButton from 'assets/btnRemove.svg';
import { Row, Col ,ProgressBar} from 'react-bootstrap';
import styled from 'styled-components';

const StyledImg = styled.img`
    height: 15px;
    width: 15px;
`;

const getValidationStatus = ({ validateFile, attachedFileSize, attachedFiles, tempValidFiles }) => {
    let isValid = true;
    const errors = [];
    if (
        !Constants.FILE_UPLOAD_VALIDATION.VALID_FILE_TYPES.some(type => validateFile.name.toUpperCase().includes(type))
    ) {
        isValid = false;
        errors.push(Constants.FILE_UPLOAD_VALIDATION.FILE_TYPE_NOT_SUPPORTED);
    }

    if (attachedFileSize >= Constants.FILE_UPLOAD_VALIDATION.TOTAL_FILE_MAX_SIZE_BYTES) {
        isValid = false;
        errors.push(Constants.FILE_UPLOAD_VALIDATION.TOTAL_FILE_SIZE_TO_LARGE);
    } 
    if ( validateFile.size > Constants.FILE_UPLOAD_VALIDATION.FILE_MAX_SIZE_BYTES){
        isValid = false;
        errors.push(Constants.FILE_UPLOAD_VALIDATION.FILE_TO_LARGE)
    }

    if (attachedFiles.length + tempValidFiles.current.length + 1 > 
                Constants.FILE_UPLOAD_VALIDATION.ATTACHMENT_LIMIT) {
        isValid = false;
        errors.push(Constants.FILE_UPLOAD_VALIDATION.FILE_MORE_THAN_TWENTY_FIVE);
    }

    if (validateFile.name.search(/[<>"*|?/:]/) > 0) {
        isValid = false;
        errors.push(Constants.FILE_UPLOAD_VALIDATION.FILE_SPECIAL_CHARACTER);
    }
    return {
        isValid,
        errors,
    };
};

const Attachments = props => {

    const {
        attachedFiles,
        setAttachment,
        rejectedFiles,
        setRejectedfiles,
        optional,
        label,
        labelsm,
        valuesm,
        showInstructions,
        attachementsm,
        labelAs,
        uploadFilesWithProgressBar,
        setTargetNoOfApiCall,
        setCountApiCallTime,
        updateProgressBarValueAfterDetelted
        } = props;


    const tempValidFiles = useRef([]);

    const getKeyName = (element, key) => {
        return `${element}-${key}`;
    };

    const deleteFile = event => {
        const selectedIndex = event.target.getAttribute('data-file-index');
        updateProgressBarValueAfterDetelted(attachedFiles.length,selectedIndex);
       
    };

    const convertKBToMB = fileSize => {
        return (fileSize / Constants.FILE_UPLOAD_VALIDATION.FILE_SIZE_BYTES).toFixed(2);
    };

    const displayFileDetails = file => {
        const filePath =
            file.path.length > 30 ? `${file.path.substring(0, 30)}...` : `${file.path} ${Constants.TEXT.DASH}`;

        const rejectError = file.reason ? file.reason[0] : '';

        return `${filePath} ${convertKBToMB(file.size)} ${Constants.TEXT.MBYTE} ${rejectError}`;
    };

    const showRejectedFile = (rejectedFile, index) => {
        const fileName = displayFileDetails(rejectedFile.file);
        const contactFileAndReason = () => {
            return `${fileName} - ${rejectedFile.reason[0]}`;
        };
        return (
            <Styles.RejectedFile
                data-testid={getKeyName('rejected-file', index)}
                key={getKeyName(rejectedFile.file.name, index)}
            >
                {contactFileAndReason()}
            </Styles.RejectedFile>
        );
    };

    const validatAttachedFile = useCallback(
        validateFile => {
            let attachedFileSize = validateFile.size;
            attachedFiles.map(file => {
                attachedFileSize += file.size;
                return true;
            });
            tempValidFiles.current.map(file => {
                attachedFileSize += file.size;
                return true;
            });
            return getValidationStatus({ validateFile, attachedFileSize, attachedFiles, tempValidFiles });
        },
        [attachedFiles],
    );

    const attachFiles = useCallback(
        acceptedFiles => {
            setRejectedfiles([]);
            tempValidFiles.current = [];
            acceptedFiles.map(file => {
                const validation = validatAttachedFile(file);
                if (validation.isValid) {
                    tempValidFiles.current.push(file);
                } else {
                    setRejectedfiles([{ file, reason: validation.errors }]);
                }

                return true;
            });
            
            setCountApiCallTime(attachedFiles.length);
            setTargetNoOfApiCall((attachedFiles.length + tempValidFiles.current.length));
        

            let fileArray = []
            for(let i=0;i<tempValidFiles?.current?.length;i++)
            {
                fileArray.push({
                    fileData:tempValidFiles?.current[i],
                    percent:0
                })
            }
            setAttachment(previousFiles => [...previousFiles, ...fileArray]);
        },
        [setAttachment, setRejectedfiles, validatAttachedFile],
    );

    const onDropRejected = useCallback(
        files => {
            setRejectedfiles([]);
            files.map(file => {
                const validation = validatAttachedFile(file);

                if (!validation.isValid) {
                    setRejectedfiles(previousRejFiles => [...previousRejFiles, { file, reason: validation.errors }]);
                }

                return true;
            });
        },
        [setRejectedfiles, validatAttachedFile],
    );

    function attachmentStyle() {
        return { span: 2, offset: labelsm };
    }

    function colonStyle() {
        return { span: -1, offset: -1 };
    }
    
    return (
        <>
            <Styles.FormRow>
                <Col sm={labelsm}>
                    <Styles.StyledLabel as={labelAs} id="attachment" className="mb-2">
                        {label}
                        {optional && (
                            <Styles.OptionalTextStyle>
                                {Constants.FILE_UPLOAD_VALIDATION.OPTIONAL}
                            </Styles.OptionalTextStyle>
                        )}
                    </Styles.StyledLabel>
                </Col>
                <Col sm={valuesm}>
                    <>
                        {attachedFiles.map((file, index) => {
                            return (
                                <Row
                                    data-testid={getKeyName('row-data-file', index)}
                                    key={getKeyName('row-file-', index)}
                                >
                                    <Col sm={attachementsm}>
                                        <Styles.AttachmentListItem>
                                            <Col sm={6}>
                                                <li key={getKeyName('file-li-', index)}>{displayFileDetails(file.fileData)}</li>
                                            </Col>
                                            <Col sm={2} data-testid={getKeyName('remove-data-file', index)}>
                                                <Styles.RemoveButton
                                                    tabIndex="0"
                                                    id={getKeyName('remove-file', index)}
                                                    key={getKeyName('file-', file?.fileData?.name)}
                                                   // onClick={deleteFile}
                                                    data-file-index={index}
                                                    aria-label={`Delete file: ${file?.fileData?.name}`}
                                                >
                                                    <StyledImg src={RemoveButton}  onClick={deleteFile} 
                                                    data-file-index={index}
                                                    alt="" />
                                                    <Styles.DeleteTextStyle
                                                        sm={1}
                                                        onClick={deleteFile}
                                                        data-file-index={index}
                                                    >
                                                        {Constants.TEXT.DELETE}
                                                    </Styles.DeleteTextStyle>
                                                </Styles.RemoveButton>
                                            </Col>
                                            <Col sm={4}>
                                            <ProgressBar now={file.percent}
                                             active
                                             label={`${file.percent}%`} 
                                            />
                                            </Col>
                                        </Styles.AttachmentListItem>
                                    </Col>
                                </Row>
                            );
                        })}
                        {rejectedFiles.length > 0 && (
                            <Row
                                data-testid={getKeyName('row-data-rejected-file')}
                                key={getKeyName('row-rejected-file-')}
                            >
                                <Col sm={12}>
                                    <Styles.RejectedFileHeader>
                                        <div>
                                            <strong>{Constants.FILE_UPLOAD_VALIDATION.REJECTED_FILES}</strong>
                                        </div>
                                    </Styles.RejectedFileHeader>
                                    <Styles.RejectedFilesContainer>
                                        {rejectedFiles.map((rejctedFile, indexx) => {
                                            return showRejectedFile(rejctedFile, indexx);
                                        })}
                                    </Styles.RejectedFilesContainer>
                                </Col>
                            </Row>
                        )}
                    </>
                    <FileUploader
                        onDropAccepted={attachFiles}
                        onDropRejected={onDropRejected}
                        isMultiple
                        accept={Constants.FILE_UPLOAD_VALIDATION.ACCEPTS}
                        maxSize={Constants.FILE_UPLOAD_VALIDATION.FILE_MAX_SIZE_BYTES}
                        uploadFilesWithProgressBar = {uploadFilesWithProgressBar}
                       // forProgressBarPercentage={forProgressBarPercentage}
                    />
                </Col>
            </Styles.FormRow>
            {showInstructions && (
                <Styles.FormRow>
                    <Col sm={12} className="mt-4">
                        <Row className="mb-3">
                            <Col sm={attachmentStyle()} className="font-weight-bold">
                                {Constants.FILE_INFO.ATTACHMENT_LIMIT}
                            </Col>
                            <Col aria-hidden="true" sm={colonStyle()} className="d-none d-md-block">
                                {Constants.FILE_INFO.COLON}
                            </Col>
                            <Styles.FileSizeStyle className="px-0 pl-md-5">
                                {Constants.FILE_OUTPUT.ATTACHMENT_OUTPUT}
                            </Styles.FileSizeStyle>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={attachmentStyle()} className="font-weight-bold">
                                {Constants.FILE_INFO.SIZE_LIMIT}
                            </Col>
                            <Col aria-hidden="true" sm={colonStyle()} className="d-none d-md-block">
                                {Constants.FILE_INFO.COLON}
                            </Col>
                            <Styles.FileSizeStyle className="px-0 pl-md-5">
                                {Constants.FILE_OUTPUT.SIZE_OUTPUT}
                            </Styles.FileSizeStyle>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={attachmentStyle()} className="font-weight-bold">
                                {Constants.FILE_INFO.TOTAL_LIMIT}
                            </Col>
                            <Col aria-hidden="true" sm={colonStyle()} className="d-none d-md-block">
                                {Constants.FILE_INFO.COLON}
                            </Col>
                            <Styles.FileSizeStyle className="px-0 pl-md-5">
                                {Constants.FILE_OUTPUT.TOTAL_SIZE_OUTPUT}
                            </Styles.FileSizeStyle>
                        </Row>
                        <Row>
                            <Col sm={attachmentStyle()} className="font-weight-bold">
                                {Constants.FILE_INFO.FILE_TYPE}
                            </Col>
                            <Col aria-hidden="true" sm={colonStyle()} className="d-none d-md-block">
                                {Constants.FILE_INFO.COLON}
                            </Col>
                            <Styles.FileSizeStyle className="px-0 pl-md-5">
                                {Constants.FILE_OUTPUT.FILE_TYPE_OUTPUT}
                            </Styles.FileSizeStyle>
                        </Row>
                    </Col>
                </Styles.FormRow>
            )}
        </>
    );
};

Attachments.propTypes = {
    attachedFiles: PropTypes.arrayOf(Object),
    setAttachment: PropTypes.func,
    rejectedFiles: PropTypes.arrayOf(Object),
    setRejectedfiles: PropTypes.func,
    updateProgressBarValueAfterDetelted: PropTypes.func,
    uploadFilesWithProgressBar: PropTypes.func,
    label: PropTypes.string,
    labelsm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)]),
    valuesm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)]),
    optional: PropTypes.bool,
    showInstructions: PropTypes.bool,
    attachementsm: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.instanceOf(Object),
    ]),
    labelAs: PropTypes.string,
};

Attachments.defaultProps = {
    attachedFiles: [],
    setAttachment: () => {},
    updateProgressBarValueAfterDetelted:()=> {},
    rejectedFiles: [],
    setRejectedfiles: () => {},
    uploadFilesWithProgressBar: ()=>{},
    label: Constants.FILE_UPLOAD_VALIDATION.ATT,
    labelsm: '2',
    valuesm: '10',
    optional: true,
    showInstructions: true,
    attachementsm: '12',
    labelAs: 'label',
};
export default Attachments;