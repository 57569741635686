import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions, loginActions, analytics } from 'shared/Actions';
import RegisterOnlineId from './RegisterOnlineId';

const mapStateToProps = (state /* , props */) => ({
    loginState: state.loginData,
    registerOnlineIdState: state.localStateManagementReducerData.registerOnlineIdState,
    personalDetailsState: state.localStateManagementReducerData.personalDetailsState,
    pageData: state.localStateManagementReducerData.pageData,
    otpDetails: state.localStateManagementReducerData.otpDetails,
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...loginActions,
    ...analytics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterOnlineId));
