import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from 'styled-components';
import routeConstants from 'routeConstants';
import editIcon from 'assets/add.svg';
import { getRelationshipInformations, setFamilyMemberIndex } from 'shared/Actions/ProfileRelationAction';
import { TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import {analyzeClickEvent} from 'shared/Actions/AnalyticsActions';
import RelationshipCard from 'modules/CustomerManagementModule/RelationShip/RelationshipInfoView/RelationshipCard';
import { decrypt } from 'utils';
import { getSessionValues } from 'shared/Helpers/Utils';

const StyleInfo = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    margin-top: 1.375rem;
    margin-bottom: 1.75rem;
`;

export const onManageRelationClick = (familyMemberIndex, dispatch, history) => () => {
	dispatch(analyzeClickEvent({
	 	link: `cms_basicinformation_managerelationshipAccountOwner`
	}))
	dispatch(setFamilyMemberIndex(familyMemberIndex));
	history.push(routeConstants.manageRelations);
}

const RelationshipInfoView = (props) => {
	const { onManageClick, hideAdd } = props;
	
	const dispatch = useDispatch();
    const history = useHistory();
	
	const hasRelationshipInfo = useSelector((state)=>{
		const { profileRelationshipData } = state;
        const { hasRelationshipInfo: hasRelationshipInfoVal } = profileRelationshipData;
		return hasRelationshipInfoVal;
	})
	
	const familyRelationShipInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { familyRelationShipInfo: familyRelationShipInfoVal } = profileRelationshipData;
        return familyRelationShipInfoVal;
    });
    
    React.useEffect(() => {
		// const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();   
		const {accesstoken, vcmid } = params;        
		const msrParams = {
			accesstoken,
			vcmid,                
		}
    	if(!hasRelationshipInfo){
    		dispatch(getRelationshipInformations(decrypt, msrParams));
    	}
    }, [hasRelationshipInfo, dispatch]);

	return( 
		<>
			<TitleWithAction
					sectionTitle
					title={<SectionTitle>{CONST.RELATION_INFO}</SectionTitle>}
					ariaLabel={CONST.ACTION_LABEL_RELATION}
					onActionClick={onManageClick}
					actionText={CONST.ACTION_TEXT}
					actionIcon={editIcon}
					hideAction={hideAdd}
			/>
			{ familyRelationShipInfo.length ? familyRelationShipInfo.map((familyMemberInfo, familyMemberIndex)=>{
				const { firstName ='', lastName='', emailInformation, gender='', maritalStatus='', familyMembersRelationshipToYou='' } = familyMemberInfo;
				const { primaryEmail='' } = emailInformation || {};
				return (
					<RelationshipCard
					    key={familyMemberIndex.toString()}
					    firstName={firstName}
					    lastName={lastName}
					    gender={gender}
					    maritalStatus={maritalStatus}
					    emailId={primaryEmail}
					    familyMembersRelationshipToYou={familyMembersRelationshipToYou}
						onManageRelationshipClick={onManageRelationClick(familyMemberIndex, dispatch, history)}
						idKey={familyMemberIndex + 1}
				    />
				);
			}) : <StyleInfo>{CONST.EMPTY_FAMILY_RELATION_INFO}</StyleInfo>
				
			}
		</>
	);
}

RelationshipInfoView.propTypes = {
	onManageClick : PropTypes.func,
	hideAdd: PropTypes.bool
};

RelationshipInfoView.defaultProps = {
	onManageClick : ()=>{},
	hideAdd:false
};

export default RelationshipInfoView;