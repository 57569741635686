const filterHeadingText = {
    fontWeight: 800,
};

const filterContainerStyle = { paddingLeft: 50, paddingRight: 50 };

export default {
    filterHeadingText,
    filterContainerStyle,
};
