const CONTACT_INFO = 'Contact Information';

const ACTION_LABEL_CONTACT = 'Manage contact information';
 const ACTION_TEXT = 'Manage';
  const MAILING_ADD = 'Mailing Address';
 const PHYSICAL_ADD = 'Physical Address';
 
const CONTACT_INFO_SUCCESS = "Contact Information Saved successfully";
const CONTACT_INFO_DELETE = "Contact Information Deleted successfully";
 const BASICINFORMATION_URL = '/basicinformation';
 const BASICINFORMATION = 'Basic Information';
 const MANAGEBREADCRUMRELATIONS = "Manage Relationship";
const MANAGEBREADCRUMRELATIONS_URL = '/ManageRelations';
const BREADCRUM_MANAGE_CONTACT = [
    {
        url: '/Profile', 
        name:'Profile',
        dataAnalyticId:'cms_basicinformation_managecontactinfo_breadcrumb_profile'
    }, 
    {
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managecontactinfo_breadcrumb_basicinfo'
    }
];
const BREADCRUM_MANAGE_RELATION_CONTACT = [
    {
        url: '/Profile', 
        name:'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_breadcrumb_profile'
    }, 
    {
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_breadcrumb_basicinfo'
    },
    {
        url: MANAGEBREADCRUMRELATIONS_URL,
        name: MANAGEBREADCRUMRELATIONS,
        dataAnalyticId:'_managerelationship_managerelationship_managecontactinfo_breadcrumb_managerelationship'

    }
];

const PAGE_NAME_CONTACT_MANAGE = 'Manage Contact Information';

 const ACTION_LABEL_CONTACT_ADD = 'Add contact information';
 const ACTION_ICON_ALT_CONTACT_ADD = 'Add contact information icon';
 const ACTION_TEXT_CONTACTE_ADD = 'Add';


const BREADCRUM_CONTACT_EDIT = [
    { 
        url: '/Profile', 
        name: 'Profile', 
        dataAnalyticId:'cms_basicinformation_managecontactinfo_editcontactinfo_breadcrumb_profile'
    }, 
    { 
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managecontactinfo_editcontactinfo_breadcrumb_basicinfo'
    },
    { 
        url: '/contactInfo', 
        name: PAGE_NAME_CONTACT_MANAGE,
        dataAnalyticId:'cms_basicinformation_managecontactinfo_editcontactinfo_breadcrumb_managecontactinfo'
    }
];
const BREADCRUM_CONTACT_ADD = [
    { 
        url: '/Profile', 
        name: 'Profile', 
        dataAnalyticId:'cms_basicinformation_managecontactinfo_addcontactinfo_breadcrumb_profile'
    }, 
    { 
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managecontactinfo_addcontactinfo_breadcrumb_basicinfo'
    },
    { 
        url: '/contactInfo', 
        name: PAGE_NAME_CONTACT_MANAGE,
        dataAnalyticId:'cms_basicinformation_managecontactinfo_addcontactinfo_breadcrumb_managecontactinfo'
    }
];
const BREADCRUM_RELATION_CONTACT_EDIT = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_editcontactinfo_breadcrumb_profile'
    }, 
    { 
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_editcontactinfo_breadcrumb_basicinfo'
    },
    {
        url: MANAGEBREADCRUMRELATIONS_URL, 
        name: MANAGEBREADCRUMRELATIONS,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_editcontactinfo_breadcrumb_managerelationship'
    },
    { 
        url: '/relationshipContactInfo', 
        name: PAGE_NAME_CONTACT_MANAGE,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_editcontactinfo_breadcrumb_managecontactinfo'
    }
];
const BREADCRUM_RELATION_CONTACT_ADD = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_addcontactinfo_breadcrumb_profile'
    }, 
    { 
        url: BASICINFORMATION_URL, 
        name: BASICINFORMATION,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_addcontactinfo_breadcrumb_basicinfo'
    },
    {
        url: MANAGEBREADCRUMRELATIONS_URL, 
        name: MANAGEBREADCRUMRELATIONS,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_addcontactinfo_breadcrumb_managerelationship'
    },
    { 
        url: '/relationshipContactInfo', 
        name: PAGE_NAME_CONTACT_MANAGE,
        dataAnalyticId:'cms_basicinformation_managerelationship_managecontactinfo_addcontactinfo_breadcrumb_managecontactinfo'
    }
];
const PAGE_NAME_CONTACT_ADD = 'Add Contact Information';
const SEASONAL_CONTACT_ADD_TITLE = 'Add Seasonal Contact Information';
const SEASONAL_CONTACT_EDIT_TITLE = 'Edit Seasonal Contact Information';
const PAGE_NAME_CONTACT_EDIT = 'Edit Contact Information';
const ADDRESS_TYPE = 'Address Type';
const SET_MAILING_ADDRESS = 'Set as Mailing Address';
const SET_PHYSICAL_ADDRESS = 'Set as Physical Address';
const MANDATORY_TEXT = "* All fields are required unless mentioned optional";
const SECURITY_INSTRUCTION = 'For security reasons, not all the information can be updated online';
const NONEONFILE = 'None on file';
const PHYSICAL_ADDRESS = "Physical Address";
const MAILING_ADDRESS = "Mailing Address";
const SESSIONAL_ADDRESS = 'Sessional Address';
const PROFILE_CONTACT_INFO= 'Profile Contact Information';
const PROFILE_CONTACT_INFO_ID= 'Profile-Contact-Information';
const GENERAL_INVESTMENT_ACC= 'General Investment Account';
const SEASONAL_TEXT = 'Seasonal Address';
const ADD_SEASONAL_TEXT = 'Add Seasonal Address';
const STARTDATE_LABEL = "Start Date";
const ENDDATE_LABEL = "End Date";

const ACCOUNT_NUMBER = "Account Number";

const ADD_PHYSICAL_TEXT = 'Add Physical Address';

const ADD_MAILING_TEXT = 'Add Mailing Address';

const ADDRESS_TYPE_ERROR = 'Please select Address Type';
const MAKE_SAME_AS_ADDRESS = 'Apply this changes of address to all of my accounts.';
const MAKING_CHANGE = 'Your are making the changes for';
const VERIFY_TEXT = 'The request to update may take a few minutes.You can verify later looking up your address on your accounts.';
const ACTION_LABEL_ADDRESS_ADD = 'Add Address information';
const ACTION_TEXT_ADD_ADDRESS = 'Add';
const ACTION_ICON_ALT_ADDRESS_ADD = 'Add address information icon';

const SHOW = "Show";
const HIDE = "Hide";
const SAVE = "Save";
const CANCEL = "Cancel";
const FOREIGN_ADDRESS_UPDATE_MSG = "To update your address, please contact a Member Service Representative (210) 876-6700.";
 
 export default {
  ACCOUNT_NUMBER,
  FOREIGN_ADDRESS_UPDATE_MSG,
  ADD_MAILING_TEXT,
  ADD_PHYSICAL_TEXT,
  CONTACT_INFO,
  MAILING_ADD,
  PHYSICAL_ADD,
  ACTION_LABEL_CONTACT,
  ACTION_TEXT,
  CONTACT_INFO_SUCCESS,
  BREADCRUM_MANAGE_CONTACT,
  PAGE_NAME_CONTACT_MANAGE,
  SEASONAL_CONTACT_ADD_TITLE,
  SEASONAL_CONTACT_EDIT_TITLE,
  ACTION_LABEL_CONTACT_ADD,
  ACTION_ICON_ALT_CONTACT_ADD,
  ACTION_TEXT_CONTACTE_ADD,
  BREADCRUM_CONTACT_EDIT,
  BREADCRUM_CONTACT_ADD,
  PAGE_NAME_CONTACT_ADD,
  PAGE_NAME_CONTACT_EDIT,
  ADDRESS_TYPE,
  SET_MAILING_ADDRESS,
  SET_PHYSICAL_ADDRESS,
  MANDATORY_TEXT,
  SECURITY_INSTRUCTION,
  NONEONFILE,
  PHYSICAL_ADDRESS,
  MAILING_ADDRESS,
  PROFILE_CONTACT_INFO,
  PROFILE_CONTACT_INFO_ID,
  GENERAL_INVESTMENT_ACC,
  SEASONAL_TEXT,
  ADD_SEASONAL_TEXT,
  STARTDATE_LABEL,
  ENDDATE_LABEL,
  
  ADDRESS_TYPE_ERROR,
  MAKE_SAME_AS_ADDRESS,
  MAKING_CHANGE,
  SESSIONAL_ADDRESS,
  VERIFY_TEXT,
  BREADCRUM_MANAGE_RELATION_CONTACT,
  BREADCRUM_RELATION_CONTACT_EDIT,
  BREADCRUM_RELATION_CONTACT_ADD,
  ACTION_LABEL_ADDRESS_ADD,
  ACTION_TEXT_ADD_ADDRESS,
  ACTION_ICON_ALT_ADDRESS_ADD,
  CONTACT_INFO_DELETE,
  SHOW,
  HIDE,
  SAVE,
  CANCEL
  };