const data = {
    onlineId: 'sevenu',
    password: 'Abcd123$',
    forgotText: 'I forgot my ',
    or: ' or ',
    onlineIdText: 'Online Id',
    passwordText: 'Password',
    errorText: 'Invalid Password or online ID',
    header: 'Sign In',
    agreeText: 'By clicking "Sign In" I agree to the Victory Capital ',
    termsOfUse: 'Terms of Use',
    styles: {
        header: {
            font: "800 30px/36px yorkten-slab-normal",
            marginBottom: '30px',
            color: "#486D90",
            display: 'inline-block',
            borderBottom: "2px solid #8CBF26",
            paddingBottom: "10px"
        },
        para: {
            /* float: 'left', */
            font: "500 14px/20px benton-sans",
            color: '#56565A'
        },
        termsOfUse: {
            marginBottom: 30,
            marginTop: 23,
            lineHeight: 0,
            display: 'inline-block'
        },
        agreeStyle: {
            display: 'block',
            float: 'left'
        },
        main: {
            maxWidth: '445px'
        },
        strong: {
            font: "600 14px/17px benton-sans",
            textDecoration: 'underline',
            color: '#004A98',
            border: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginLeft: 3,
            whiteSpace: 'nowrap',
            display: 'block',
            float: 'left'
        },
        inputStyle: {
            paddingBottom: 29
        }
    }
}

export default data;