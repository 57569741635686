import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import currencyFormatter from 'currency-formatter';
import AccountInfoIcon from 'assets/icon-account-info-big.svg';
import { PageHeading, PrimaryBtn, KebabMenu } from 'modules/CustomerManagementModule/components';
import {
    masterRegistrationAccountActions,
    accOpeningActions,
    memberDashboardActions,
    liquidationActions,
    tradingWindowActions,
} from '../../../shared/Actions';
import { WBreadCrumb, StyledButton, WSpinner, WMessageBar } from '../../../common';
import * as TransactionConstants from '../../TransactionModule/TransactionConstants';
import TransactionTableList from './TransactionTableList';
import UpdateText from './UpdateText';
import * as Constants from './Constants';
import Styles from './Styles';
import BackNavigation from '../Common/BackNavigation/BackNavigation';
import leftArrowGreen from '../../../assets/leftArrow_green.png';
import editIcon from '../../../assets/pencil.png';
import '../Common/Styles.css';
import './Account.css';
import downArrowIcon from '../../../assets/angleDownCircle.png';
import constant from './AccountHolderConstants';
import {isTokenAvailable} from '../../../common/Authenticate/session'
import Authenticate from '../../../common/Authenticate/Authenticate'
import {purchaseActions} from '../../../shared/Actions';
import { getSessionValues } from 'shared/Helpers/Utils';
import { isValueNotEmpty, validateUndefinedNullAndEmptyValue } from 'utils/utils';
import { LocalStorageHelper, SessionStorageHelper } from 'utils/sessionHelper';
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';
const CustomBtn = React.forwardRef((props, ref) => {
    return (
        <PrimaryBtn ref={ref} {...props}>
            {Constants.PLACE_A_TRADE}
        </PrimaryBtn>
    );
});




const StyledHeaderRow = styled.div`
    display: 'flex';
    align-items: 'center';
    flex-direction: 'row';
    justify-content: 'space-between';
    margin-bottom: 21px;
`;


const SideHeaderDiv = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        border-bottom: 0.125rem solid rgb(139, 193, 5);
        display: flex;
        align-items: center;
        margin-bottom: 21px;
    }
`;
const EditBtn = styled.button`
    // text-align: right;
    // padding: 0;
    border: none;
    background: none;
    font: 400 16px/19px benton-sans;
    letter-spacing: 0;
    color: #0000ff;
    opacity: 1;
    // display: inline-block;
    flex-direction: row;
    // float: left;
    // margin-left: 10px;
`;

const ManageText = styled.p`
    margin-bottom:1px;
    text-decoration:underline;
    font: 600 12px/19px benton-sans;
    color:#000;
    cursor:pointer;
`;

const borderColor = '1px solid #D2D2D2';
const assignObj = obj => obj;

const convertUTCtoLocalTime = time => {
    const dateStr = time;
    return moment(new Date(dateStr))
        .local()
        .format('MMMM DD, YYYY hh:mm A');
};

const getLocalTime = time => {
    if (time !== '') {
        const dateTimeString = convertUTCtoLocalTime(time);
        const splitTime = dateTimeString && dateTimeString.split(',');
        const [splittedDate, splittedTime] = splitTime;
        const trimmedDate = splittedDate.trim('');
        let splittedYear = [];
        if (splittedTime) {
            splittedYear = splittedTime.trim().split(' ');
        }
        return ` ${trimmedDate}, ${splittedTime ? splittedYear[0] : ''} `;
    }
    return ``;
};

const AccountOptionsLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    a {
        text-align: left;
        font-family: benton-sans, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #004a98;
        opacity: 1;
    }
`;

const StyledSpan = styled.span`
    font-size: 14px;
    line-height: 22px;
`;

const AccountImgData = styled.div`
    background: url(${AccountInfoIcon}) no-repeat;
    min-height: 3rem;
    min-width: 3rem;
`;

const LeftMargined = styled.div`
    margin-left: ${props => props.mL}px;
`;

class TransactionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hadTenItems: 10,
            offset: 1,
            offSetScale: 10,
            displayTradeOption: false,
            displayFundOption: false,
            tradeLocation: {},
            redirectToPurchaseMasterReg: false,
            redirectToLiquidationMasterReg: false,
            redirectToExchangeMasterReg: false,
            displayNotification: false,
            notificationMsg: '',
            masterId: 0,
            accountName: '',
            totalAmount: 0,
            mostRecentDate: '',
            accountType: '',
            registration: {},
            allowedTransactions: {},
            showModal: false,
            showAccountNo: false,
            updatedAccNickname: '',
            isNicknameUpdated: false,
            accountAddress: [],
            fundSummaryClicked: false,
            idOfCurrentFocusElement: '',
            showAuthBox:false,
            checkFisError :  false,
            portfoliovisualizerLink: '',
            details: {},
            menuOptionCheckArray : [],
            menuOptions : [] ,
            functinCallTime : 0,
            currentYearIRA : 0,
            prevYearIRA : 0,
            currYearIRALimit : 0,
            prevYearIRALimit : 0,
            currentYearVal : 0,
            preYear : 0,
            checkIRAContributionSHow : false,
            dashboardFlipFlop:true,
            moveToNXILinkText:"",
            redirectToNAI:false,
            redirectToLink:'',
            manageTextDescription:"",
            addressEditMode:false,
            redirectToBasicProfile:false
        };
        this.menuRef = React.createRef();
        this.menuItemRefs = React.createRef();
        this.perPhysicalAddressPref = React.createRef();
        this.perMailingAddressPref = React.createRef();
      
    }


  

    static setTradeLocationPathAndState = (masterRegAccountList, prevState, location) => {
        const { masterId = '0' } = location.state || {};
        let tradeLocation = {};
        const accountInfo = JSON.parse(LocalStorageHelper.getItem('accountInfo'));
        if (masterRegAccountList && masterRegAccountList.length > 0) {
            // Purchase
            if (prevState.redirectToPurchaseMasterReg && masterId !== 0 ) {
                tradeLocation = {
                    pathname: '/transactionPurchases',
                    state: { pageIndex: 1, masterId: accountInfo.masterId },
                };
            }
            // Liquidation
            if (prevState.redirectToLiquidationMasterReg && masterId !== 0) {
                tradeLocation = {
                    pathname: '/liquidation',
                    state: { pageIndex: 1, masterId: accountInfo.masterId },
                };
            }
            // Exchange
            if (prevState.redirectToExchangeMasterReg && masterId !== 0) {
                tradeLocation = {
                    pathname: '/exchange',
                    state: { pageIndex: 1, masterId: accountInfo.masterId },
                };
            }
        }
        return tradeLocation
    }

    
    static calculateTotalAmount = (transactionHolderData) => {

        let totalAmount = 0;
        const { transactionList } = transactionHolderData;
        const { fundList } = transactionList;

        if (fundList && fundList.length > 0) {
            totalAmount = fundList
                .map(el => Number(el.currentValue))
                .reduce((a, c) => {
                    return a + c;
                });
        }


        return totalAmount
    }


    static setSelectedAccountAddress = (accountSelected) => {
        let address;
         
        if (accountSelected && Object.keys(accountSelected).length > 0) {
            const customerNameLineBreak = getValidValueOf(accountSelected?.accountRegistrationLine1,'\n','')
            const addressLine1Break = accountSelected?.addressLine2 ? '\n': ''
            const addressLine2Break = accountSelected?.city ? '\n': ''
            const addressLine3Break = accountSelected?.state ? '\n': ''
            const addressLine4Break = accountSelected?.qualifier ? ' ': ''

            const splitCustomerName =
                accountSelected.accountRegistrationLine1 && accountSelected.accountRegistrationLine1.split('<br/>');
            const formatCustomerName =
                splitCustomerName && splitCustomerName.length > 1 ? splitCustomerName.join('\n') : splitCustomerName;

            let customerAccountType = accountSelected.accountRegistrationLine4;
            customerAccountType =
                customerAccountType === ' ' ? accountSelected.accountRegistrationLine3 : customerAccountType;
            customerAccountType =
                customerAccountType === ' ' ? accountSelected.accountRegistrationLine2 : customerAccountType;

            address =
                Object.keys(accountSelected).length > 0
                    ? `${formatCustomerName}${customerNameLineBreak}${customerAccountType
                          .trim()
                          .replace(/[^\w\s]/gi, '')}${customerAccountType ? '\n' : ''}${accountSelected.addressLine2 &&
                          accountSelected.addressLine2.trim()}${addressLine4Break}${accountSelected.qualifier &&
                          accountSelected.qualifier.trim()}${addressLine1Break}${accountSelected.city &&
                          accountSelected.city.trim()}${addressLine2Break}${accountSelected.state &&
                          accountSelected.state.trim()}${addressLine3Break}${accountSelected.zipCode &&
                          accountSelected.zipCode.trim()}`
                    : '';
        }else{
            address = '';
        }


        return address

    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            masterRegistrationAccountData,
            accOpeningReducerData,
            location,
            transactionHolderData,
            liquidationData,
            iRAContributionYearValues
        } = nextProps;
        const { notificationMsg , ownerType, taxId, accountDetails,updatedAccNickname} = prevState;
        let { masterId = 0, name = '',state } = location;
        let {accountOwnerDesc} = location.state || {};
        if (masterId !== 0 && state?.masterId !=undefined) {
            const accountInfo = {
                masterId:state?.masterId,
                accountName:state?.name,
                accountType:state?.accountType,
                ownerType:state?.ownerType,
                taxId:state?.taxId,
                accountOwnerDesc:state?.accountOwnerDesc
            }
            LocalStorageHelper.setItem('masterId', masterId);
            LocalStorageHelper.setItem('accountName', name);
            LocalStorageHelper.setItem('accountInfo',JSON.stringify(accountInfo))
        } else if (masterId == 0 && LocalStorageHelper.getItem('accountInfo') !=null) {
            masterId = JSON.parse(LocalStorageHelper.getItem('accountInfo')).masterId ? JSON.parse(LocalStorageHelper.getItem('accountInfo')).masterId  : masterId;
            name = JSON.parse(LocalStorageHelper.getItem('accountInfo')).accountName ? JSON.parse(LocalStorageHelper.getItem('accountInfo')).accountName : name;
        }else{
            console.log('do nothing - negative case for code coverage')
        }

        let tradeLocation = TransactionHistory.setTradeLocationPathAndState(masterRegistrationAccountData?.masterRegAccountList, prevState, location);
        let totalAmount = TransactionHistory.calculateTotalAmount(transactionHolderData);

        const { transactionList } = transactionHolderData;

        if (nextProps.accOpeningReducerData.nickNameData !== prevState.nickNameData) {
            const { status } = accOpeningReducerData.nickNameData;
            if (status === 200 && accOpeningReducerData?.nickNameUpdatedStatus) {

                const accountData = JSON.parse(LocalStorageHelper.getItem('accountInfo'));
                const accountInfo = {
                    masterId:accountData?.masterId,
                    accountName:updatedAccNickname,
                    accountType:accountData?.accountType,
                    ownerType:accountData?.ownerType,
                    taxId:accountData?.taxId,
                    accountOwnerDesc:accountData?.accountOwnerDesc
                }
                LocalStorageHelper.setItem('accountInfo',JSON.stringify(accountInfo))

                return {
                    ...prevState,
                    nickNameData: accOpeningReducerData.nickNameData,
                    showModal: false,
                    isNicknameUpdated: true,
                };
            }
        }

        const accountSelected = liquidationData.address;
        let address = TransactionHistory.setSelectedAccountAddress(accountSelected);


        return {
            ...prevState,
            tradeLocation,
            masterId,
            accountDetails,
            accountOwnerDesc,
            ownerType, 
            taxId,
            accountName: (updatedAccNickname !="") ? updatedAccNickname : name,
            accountAddress: address,
            totalAmount:
                totalAmount.toString().split('.')[1] && totalAmount.toString().split('.')[1].length === 3
                    ? Math.round(totalAmount * 100) / 100
                    : totalAmount.toFixed(2),
            displayNotification: masterRegistrationAccountData.isError || transactionHolderData.isError ? true : false,
            notificationMsg:
                masterRegistrationAccountData.isError || transactionHolderData.isError
                    ? { message: TransactionConstants.NOTIFICATION_ERROR_MESSAGE }
                    : notificationMsg,
            mostRecentDate: transactionHolderData.transactionList.mostRecentDate
                ? transactionHolderData.transactionList.mostRecentDate
                : '',
            accountType: transactionHolderData.transactionList.accountType
                ? transactionHolderData.transactionList.accountType
                : '',
            registration: transactionHolderData.transactionList.registration
                ? transactionHolderData.transactionList.registration
                : {},
            allowedTransactions: transactionList.allowedTransactions
                ? transactionHolderData.transactionList.allowedTransactions
                : [],
            details: transactionHolderData,
            prevYearIRA :iRAContributionYearValues.isIRAContributionYearData 
            ? iRAContributionYearValues.isIRAContributionYearData.prevYearIRA : 0.00,
            prevYearIRALimit:iRAContributionYearValues.isIRAContributionYearData 
            ?iRAContributionYearValues.isIRAContributionYearData.prevYearIRALimit : 0.00,
            currentYearIRA:iRAContributionYearValues.isIRAContributionYearData 
            ? iRAContributionYearValues.isIRAContributionYearData.currYearIRA : 0.00,
            currYearIRALimit:iRAContributionYearValues.isIRAContributionYearData 
            ?iRAContributionYearValues.isIRAContributionYearData.currYearIRALimit:0.00
            

        };
    }

    getValue = (value1,value2) =>
    {
        if(value1)
        {
            return value1
        }
        else
        {
            return value2
        }
    }

    componentDidMount() {
        let da  = new Date();
        this.setState({
            currentYearVal : da.getFullYear(),
            preYear : (da.getFullYear() - 1)
        });
        
        const { getTransHolderList, setLoadingPage, getAccountAddress,history,getIRAContributionYearValues,getAccountServiceOptions,getEnabledFeature ,location,storeAccountInfoData} = this.props;

    if(SessionStorageHelper.getItem('msr_dts')){
        window.addEventListener('mousedown', this.handleOutsideClick);
        document.title = `Account holder Transactions | Victory Capital`;
        getEnabledFeature()
        const { ownerType, taxId } = location.state || {};
        const { masterId, tradeLocation,accountOwnerDesc} = this.state;
        const { pathname = '' } = tradeLocation || {};
        const companyNumber = 591;

        const accountInfoObj = JSON.parse(LocalStorageHelper.getItem('accountInfo'));
        let masterIdValue = this.getValue(masterId,accountInfoObj?.masterId)
        let accountOwnerDescValue =  this.getValue(accountOwnerDesc,accountInfoObj?.accountOwnerDesc);
        let ownerTypeValue = this.getValue(ownerType,accountInfoObj?.ownerType);
        let taxIDValue = this.getValue(taxId,accountInfoObj?.taxId);

        const transactionHistoryPayload = {
            masterId:masterIdValue,
            companyNumber,
        };
     
        setLoadingPage();
        if (pathname !== '/accountholder') {
            // const msrInfo = sessionStorage.getItem('msr_dts');
            const params = getSessionValues()
            const { accesstoken, vcmid, fismemberid } = params;

            getTransHolderList({
                payload: transactionHistoryPayload,
                token: accesstoken,
                vcmid,
                fismemberid,
            });
            const accountAddressPayload = {
                companyNumber,
                masterReg: masterIdValue,
                token: accesstoken,
                vcmid,
                fismemberid,
            };
            if(accountOwnerDesc != undefined && accountOwnerDesc != null && accountOwnerDesc != ''){
                storeAccountInfoData({ accountOwnerDescValue, ownerTypeValue, taxIDValue })
            }
           
            getAccountAddress(accountAddressPayload);
            if(accountOwnerDesc == undefined || accountOwnerDesc == null || accountOwnerDesc == ''){
                const {storeAccountData} = this.props?.transactionHolderData || {};
                getAccountServiceOptions({ 
                    accountOwnerDesc:accountOwnerDescValue,
                    ownerType:ownerTypeValue,
                    taxId: taxIDValue
                });
            }
            else{
                getAccountServiceOptions({ accountOwnerDesc:accountOwnerDescValue, ownerType:ownerTypeValue, taxId:taxIDValue });
            }
           
            this.props.getEnabledFeature()
            // To do: we will remove the static RegId data when we have actual data coming in
        }

        let currentYear = da.getFullYear();
        let accountType='';
        let masterID = '';
        if(history.location.accountType != undefined )
        {   
            LocalStorageHelper.setItem("accountType",history.location.accountType.trim());
            LocalStorageHelper.setItem("masterRegID",history.location.masterId);
        }
        accountType = LocalStorageHelper.getItem("accountType");
        masterID = LocalStorageHelper.getItem("masterRegID");

        let accountTypeCode = this.getAccountTypeCode(accountType);
        const payload  = {
            companyNumber : "591",
            year : currentYear,
            accountType : accountType,
            masterReg : masterID,
            accountTypeCode :accountTypeCode,
            socialCode : this.getSocialCode(accountType),
            isMember:true
        };
        
        let showIraTable  = ["200","203","215","202","216","219"];
        if(showIraTable.includes(accountTypeCode))
        {   
            this.setState({checkIRAContributionSHow :  true});
            getIRAContributionYearValues(payload);
        }
        else
        {
            this.setState({checkIRAContributionSHow :  false});
        }     
    }
    }

    updateFundDetails = (fundList, totalValue, fundListDetails, link, totalFundPercentage) => {
        if (fundList != undefined) {
            fundListDetails = fundList.map(fund => {
                let properties = {
                    ticker: fund.ticker,
                    amount: fund.currentValue,
                };

                return properties;
            });

            let tempLink = '';

            fundListDetails.forEach((fund, index) => {
                // round 2 decimal digits
                const value = (parseFloat(fund.amount) / totalValue) * 100;
               

                tempLink += `&symbol${index + 1}=${fund.ticker}&allocation${index + 1}_1=${value}`;
            });
            return tempLink;
        }
    };

    componentDidUpdate() {
        // round without decimal
        const initialAmount = this.state.details.transactionList.totalCurrentValue;
        const endYear = new Date().getFullYear();
        const startYear = endYear - 20;

        let totalValue = this.state.details.transactionList.totalCurrentValue;
        let fundList = this.state.details.transactionList.fundList;

        let fundListDetails = [];
        let link = '';
        let totalFundPercentage = null;

        const urlLinkData = this.updateFundDetails(fundList, totalValue, fundListDetails, link, totalFundPercentage);

        const r = '';
        if (urlLinkData != undefined) {
          
            this.state.portfoliovisualizerLink = `https://www.portfoliovisualizer.com/backtest-portfolio?
            timePeriod=${r}4
            &startYear=${startYear}
            &firstMonth=${r}1
            &endYear=${endYear}
            &lastMonth=${r}12
            &calendarAligned=${r}true
            &includeYTD=${r}false
            &startDate=${r}
            &endDate=${r}
            &initialAmount=${Math.round(initialAmount)}
            &annualOperation=${r}0
            &annualAdjustment=${r}0
            &inflationAdjusted=${r}true
            &annualPercentage=${r}0.0
            &frequency=${r}4
            &rebalanceType=${r}1
            &absoluteDeviation=${r}5.0
            &relativeDeviation=${r}25.0
            &leverageType=${r}0
            &leverageRatio=${r}0.0
            &debtAmount=${r}0
            &debtInterest=${r}0.0
            &maintenanceMargin=${r}25.0
            &leveragedBenchmark=${r}false
            &reinvestDividends=${r}true
            &showYield=${r}false
            &showFactors=${r}false
            &factorModel=${r}3
            &benchmark=${r}
            &benchmarkSymbol=${r}
            &portfolioNames=${r}false
            &portfolioName1=${r}Portfolio+1
            &portfolioName2=${r}Portfolio+2
            &portfolioName3=${r}Portfolio+3
            ${urlLinkData}&

            &total1= 
            &total2=
            &total3=`;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleOutsideClick);
        const { clearLiquidationData } = this.props;

        clearLiquidationData();
    }

    getViewableTransHolderList() {
        const { offSetScale, offset } = this.state;
        const maxRowSize = 10;
        const { transactionHolderData } = this.props;
        const { transactionList } = transactionHolderData;
        const { fundList } = transactionList;
        if (fundList && fundList.length > maxRowSize) {
            return fundList.slice(0, offSetScale * offset);
        }
        return fundList;
    }

    loadMoreData = () => {
        const { offset } = this.state;
        this.setState({ offset: offset + 1 });
    };

    handleOutsideClick = e => {
        if (e) {
            if (e.target.id === 'tradeButton') {
                return;
            }
            if (e.target.id === 'buytradeButtonMenuItem' || e.target.id === 'buytradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'selltradeButtonMenuItem' || e.target.id === 'selltradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'exchangetradeButtonMenuItem' || e.target.id === 'exchangetradeButtonMenuOptions') {
                return;
            }
        }
        this.setState({ displayTradeOption: false });
    };

    onClickTrade = () => {

        const { displayTradeOption } = this.state;

        this.setState({
            displayTradeOption: !displayTradeOption,
            idOfCurrentFocusElement: 'buytradeButtonMenuItem',
        });
        setTimeout(() => {
            document.getElementById('buytradeButtonMenuItem').focus();
        });
    };

    loadAccountList = ()=>
    {
        const { getAccountList } = this.props;
            if(isTokenAvailable() === null ){
         
                this.setState({showAuthBox:true})
                getAccountList(
                    {
                        companyNumber: '591',
                        token: LocalStorageHelper.getItem('token'),
                    },
                    true,
                    'purchase',
                );
            }else{
                this.setState({showAuthBox:false})
                getAccountList(
                    {
                        companyNumber: '591',
                        token: LocalStorageHelper.getItem('token'),
                    },
                    true,
                    'purchase',
                );
            }
    }

    onPurchaseClickTrade = () => {
        const { getAccountList,resetNickNameUpdatedStatus } = this.props;
        this.setState({ redirectToPurchaseMasterReg: true });
        this.handleOutsideClick();
        resetNickNameUpdatedStatus();
        // TODO:
        
        if(isTokenAvailable() === null ){
         
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: LocalStorageHelper.getItem('token'),
                },
                true,
                'purchase',
            );
        }else{
            getAccountList(
                {
                    companyNumber: '591',
                    token: LocalStorageHelper.getItem('token'),
                },
                true,
                'purchase',
            );
        } 
    
    };

    onLiquidationClickTrade = () => {
        const { getAccountList,resetNickNameUpdatedStatus } = this.props;
        this.setState({ redirectToLiquidationMasterReg: true });
        this.handleOutsideClick();
        resetNickNameUpdatedStatus()

        if(isTokenAvailable() === null ){
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: LocalStorageHelper.getItem('token'),
                },
                true,
                'liquidate',
            );
        }else{
            getAccountList(
            {
                companyNumber: '591',
                token: LocalStorageHelper.getItem('token'),
            },
            true,
            'liquidate',
            );
        }
    };

    onExchangeClickTrade = () => {
     const { getAccountList,resetNickNameUpdatedStatus } = this.props;
        this.setState({ redirectToExchangeMasterReg: true });
        this.handleOutsideClick();
        resetNickNameUpdatedStatus();
        if(isTokenAvailable() === null ){
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: LocalStorageHelper.getItem('token'),
                },
                true,
                'exchange',
            );
        }else{
            getAccountList(
            {
                companyNumber: '591',
                token: LocalStorageHelper.getItem('token'),
            },
            true,
            'exchange',
            );
        } 
       
    };

    editTitleClick = () => {
        this.setState({ showModal: true, isNicknameUpdated: false });
    };

    cancelEditTitleClick = () => {
        this.setState({ showModal: false });
        document.getElementById('edit_btn_id').focus();
    };

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
        });
    };

    submitEditTitleClick = accountName => {
        const { updateAccountNickname } = this.props;
        const { masterId } = this.state;
        const companyNumber = 591;
        if (updateAccountNickname !== '') {
            const payload = {
                masterRegId: masterId,
                newNickname: accountName,
                companyNumber,
            };
            updateAccountNickname(payload);
            if(accountName !="")
            {
                this.setState({ updatedAccNickname: accountName });
            }
        }
    };

    accountNoClick = () => {
        this.setState(prevState => ({
            showAccountNo: !prevState.showAccountNo,
        }));
    };

    fundSummaryUpdate = () => {
        this.setState(prevState => ({
            fundSummaryClicked: !prevState.fundSummaryClicked,
        }));
    };

    onNavigate = e => {
        e.preventDefault();
        const { displayTradeOption, allowedTransactions, idOfCurrentFocusElement } = this.state;
        let nextFocusElement;
        if (e.which === 38) {
            if (idOfCurrentFocusElement === 'buytradeButtonMenuItem') {
                nextFocusElement = 'exchangetradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'selltradeButtonMenuItem') {
                nextFocusElement = 'buytradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'exchangetradeButtonMenuItem') {
                nextFocusElement = 'selltradeButtonMenuItem';
            }
            document.getElementById(nextFocusElement).focus();
            this.setState({ idOfCurrentFocusElement: nextFocusElement });
        }

        if (e.which === 40) {
            e.preventDefault();
            if (idOfCurrentFocusElement === 'buytradeButtonMenuItem') {
                nextFocusElement = 'selltradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'selltradeButtonMenuItem') {
                nextFocusElement = 'exchangetradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'exchangetradeButtonMenuItem') {
                nextFocusElement = 'buytradeButtonMenuItem';
            }
            document.getElementById(nextFocusElement).focus();
            this.setState({ idOfCurrentFocusElement: nextFocusElement });
        }
        // click event that navigate to a certain path
        if (
            e.which === 13 &&
            allowedTransactions.includes('Buy') &&
            idOfCurrentFocusElement === 'buytradeButtonMenuItem'
        ) {
            this.onPurchaseClickTrade('purchase');
        }
        if (
            e.which === 13 &&
            allowedTransactions.includes('Sell') &&
            idOfCurrentFocusElement === 'selltradeButtonMenuItem'
        ) {
            this.onLiquidationClickTrade('liquidate');
        }
        if (
            e.which === 13 &&
            allowedTransactions.includes('Exchange') &&
            idOfCurrentFocusElement === 'exchangetradeButtonMenuItem'
        ) {
            this.onExchangeClickTrade('exchange');
        } else if (e.which === 27 || e.which === 9) {
            this.setState({
                displayTradeOption: !displayTradeOption,
                idOfCurrentFocusElement: '',
            });
            document.getElementById('tradeButtonMenu').focus();
        }

    };

    onMenuOptionClick = menuOption => () => {
        if (menuOption === constant.buy) {
            this.onPurchaseClickTrade();
        } else if (menuOption === constant.sell) {
            this.onLiquidationClickTrade();
        } else if (menuOption === constant.exchange) {
            this.onExchangeClickTrade();
        }
    };

    moveNextPage = () =>{
        this.setState({showAuthBox: false})
    }

    authBox = () =>{
        this.setState({showAuthBox: true})
    }

    onNotificationForFis = ()=>{
        
        this.setState({
            checkFisError: true
        });
    }

    onNotificationCloseFis = ()=>
    {
        this.setState({
            checkFisError: false
        });
    }

    
    // Below Changes   --- For adding  IRA Contributions Year, author : Gaurav Kumar (Hyniva) 
    getAccountTypeCode = accountType => {
        switch (accountType?.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Minor Roth IRA':
                return '215';
            case 'Roth IRA - Conversion':
                return '216';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case 'Simple IRA - Non DFI':
                return '210';
            case 'Simple IRA - DFI':
                return '212';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            case 'Coverdell ESA':
                return '219';
            case 'Archer MSA':
                return '220'
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case 'Roth IRA - Conversion':
                return '14';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };


    addDecimalZeroes=(num)=> {
        if(typeof(num) === 'string'){
            num = parseFloat(num.replace(/,/g, ''))
            let numWithZeroes = num.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2});
            return numWithZeroes
        }
        else{
            return num
        } 
    }

    addTwoDecimalZeroes=(currentYearIRA)=>{
        return `${currencyFormatter.format(currentYearIRA, {
            locale: 'en-US',
        })}`;
    }


    // updateMenuOptions = (transaction, constant) => {
    //     const { menuOptionCheckArray, allowedTransactions } = this.state;
    //     if (allowedTransactions?.includes(transaction) && !menuOptionCheckArray.includes(transaction)) {
    //         this.state.menuOptionCheckArray.push(allowedTransactions[allowedTransactions.indexOf(transaction)])
    //         this.state.menuOptions.push({ name: constant })
    //     }
    // }

    updateMenuOptions = () => {
        const { menuOptionCheckArray, allowedTransactions } = this.state;
    
        const addMenuOption = (transactionType, constantName) => {
            if (allowedTransactions?.includes(transactionType) && !menuOptionCheckArray.includes(transactionType)) {
                this.state.menuOptionCheckArray.push(allowedTransactions[allowedTransactions.indexOf(transactionType)])
                this.state.menuOptions.push({ name: constantName })
            }
        };
    
        if (allowedTransactions?.length > 0) {
            addMenuOption('Buy', constant.buy);
            addMenuOption('Sell', constant.sell);
            addMenuOption('Exchange', constant.exchange);
        }
    };

    showOpenDialogText = (isNicknameUpdated) => {
        let dialogText = null

        if(!isNicknameUpdated){
            dialogText = 'Opens a dialog'
        }

        return dialogText
    }
    

    render() {

        const {
            mostRecentDate,
            tradeLocation,
            displayNotification,
            notificationMsg,
            masterId,
            accountName,
            totalAmount,
            allowedTransactions,
            showModal,
            accountType,
            accountAddress,
            isNicknameUpdated,
            updatedAccNickname,
            fundSummaryClicked,
            showAuthBox,
            accountOwnerDesc,
        } = this.state;

        const {
            transactionHolderData,
            masterRegistrationAccountData,
            getAccountList,
            getFundDetails,
            accOpeningReducerData,
        } = this.props;
        const isLoading =
            transactionHolderData.isLoading ||
            masterRegistrationAccountData.isLoading ||
            accOpeningReducerData.isLoading;
        const viewableTransactionList = this.getViewableTransHolderList();

        const { nickNameData } = accOpeningReducerData;
        const { showMessage=false, message='' } = nickNameData;
        const successMessage = () => {
            return { message };
        };
        const getRecentDate = getLocalTime(mostRecentDate);

        const { accountServiceOptionsLinks = [] ,accountManagePreferences = []} = transactionHolderData;


        this.updateMenuOptions()



        const isNewDashboardFeatureEnabled =  
        this.props?.memberDashboardData?.getEnabledFeature?.features == undefined ? false:
        this.props?.memberDashboardData?.getEnabledFeature?.features.includes("Brokerage");
        return (
            <div className="borderDesign" data-testid="trans-act-hist">
                {isLoading && (
                    <WSpinner
                        aria-busy="true"
                        aria-live="polite"
                        aria-label="Please wait while we load more data"
                        loading={isLoading}
                    />
                )}

                { <Authenticate showAuthBox={showAuthBox}  moveNextPage={this.moveNextPage} onNotificationForFis={this.onNotificationForFis} />}
                {tradeLocation.pathname && showAuthBox===false && isTokenAvailable() && <Redirect to={tradeLocation} />}
                {displayNotification && <WMessageBar text={notificationMsg} onClose={this.onNotificationClose} />}
                {this.state.checkFisError && <WMessageBar text={TransactionConstants.MSR_INVALID_CREDENTIAL} onClose={this.onNotificationCloseFis} />}
                {showMessage && <WMessageBar text={successMessage()} />}
                {(isNewDashboardFeatureEnabled) ?
                <>
                <div className="container">
                    <div className="transContainer">
                        <WBreadCrumb breadCrumbItems={Constants.INFO.BREADCRUMB_VALUES} activeCrumb={accountName} />

                        <StyledHeaderRow className="row tradeMenuBtnWrapper tradeMenuBtn">
                            <Col xs={12} md={9}>
                                <SideHeaderDiv>
                                    <AccountImgData />
                                    <LeftMargined mL="18">
                                        <PageHeading as="h1">
                                            {isNicknameUpdated
                                                ? `${updatedAccNickname}`?.substring(0, 18)
                                                : accountName.substring(0, 18)}
                                        </PageHeading>
                                    </LeftMargined>
                                    <EditBtn id="edit_btn_id" onClick={this.editTitleClick}>
                                        <Styles.ConstantStyleEdit>
                                            <Styles.EditImage alt="" src={editIcon} />
                                            <p>{Constants.EDIT_TEXT}</p>
                                        </Styles.ConstantStyleEdit>
                                        <span className="sr-only">
                                            {this.showOpenDialogText(isNicknameUpdated)}
                                        </span>
                                    </EditBtn>
                                    {showModal && (
                                        <UpdateText
                                            onPrimaryClick={this.submitEditTitleClick}
                                            onSecondaryClick={this.cancelEditTitleClick}
                                        />
                                    )}
                                </SideHeaderDiv>
                            </Col>
                            {
                                <Col xs={12} md={3} align="right">

                                    <KebabMenu
                                        menuOptions={this.state.menuOptions}
                                        onMenuOptionClick={this.onMenuOptionClick}
                                        menuBtnLabel="MenuItem"
                                        isNotKebab
                                        CustomBtn={CustomBtn}
                                    />

                                </Col>
                            }
                        </StyledHeaderRow>
                        <Container className="pl-0">
                            <Row
                                style={assignObj({
                                    border: borderColor,
                                    marginBottom: '25px',
                                    backgroundColor: '#f7f7f7',
                                })}
                            >

                                <Col xs={12} sm={12} md={6}>
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            style={assignObj({
                                                borderRight: borderColor,
                                                backgroundColor: '#ffffff',
                                                textAlign: 'center',
                                                height: '260px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingTop: '35px',
                                            })}
                                        >
                                            <div className="acc-activity-main">
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.CURR_VALUE}
                                                    {/* <Styles.FormNavHeader href={`#${Constants.INFO.AS_ON}`} aria-label={Constants.INFO.CURR_VALUE}> {Constants.INFO.STAR}</Styles.FormNavHeader> */}
                                                </span>
                                                <p className="font-weight-bold" style={assignObj({ fontSize: '20px',textAlign:"center" })}>
                                                    {currencyFormatter.format(totalAmount, { locale: 'en-US' })}
                                                </p>
                                            </div>
                                            <Link
                                                style={assignObj({
                                                    height: '21px',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    lineHeight: '20px',
                                                    display: 'inline-block',
                                                    color: 'rgba(0, 74, 152, 1)',
                                                })}
                                                to={assignObj({
                                                    pathname: '/accountholder/activity',
                                                    state: {
                                                        name: accountName,
                                                        masterRegId: masterId,
                                                    },

                                                })}
                                            >
                                                {Constants.INFO.V_AC_ACTIVITY}
                                            </Link>
                                                 {/*  Below Changes  3209  --- For adding  IRA Contributions , author :  Gaurav Kumar (Hyniva) */}

                                                     { this.state.checkIRAContributionSHow ? (
                                                    <div>
                                                    <hr/>
                                                    <div className="row mainDiv" >
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <table className="tableDiv">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="tableHeaderWidth2"></th>
                                                                        <th style={{fontWeight : "normal"}}> <span className="iraContributionFontSize">Contributions</span></th>
                                                                        <th style={{fontWeight : "normal"}}><span className="iraContributionFontSize">Limit</span></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.preYear}:</span></td>
                                                                        <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.prevYearIRA)}</b></span></td>
                                                                        <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.prevYearIRALimit}</b></span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.currentYearVal}:</span></td>
                                                                        <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.currentYearIRA)}</b></span></td>
                                                                        <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.currYearIRALimit}</b></span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    ) : (

                                                        <div></div>
                                                    )}
                                               
                                                {/* End 3209 here author :  Gaurav Kumar (Hyniva)*/}
                                        </Col>
                                        <Col xs={12} sm={6} md={6}>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.ACC_NUMBER}
                                                </span>
                                                <p className="font-weight-bold">
                                                    {masterId}
                                                </p>
                                            </div>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.ACC_TYPE}
                                                </span>
                                                {(isNewDashboardFeatureEnabled == true) ? (
                                                          <p className="font-weight-bold">{Constants.INFO.VICTORY_FUNDS}: {accountType}</p>
                                                    ) : (
                                                        <p className="font-weight-bold">{accountType}</p>
                                                )}         
                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={3}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.REG}
                                                </span>
                                                <p className="font-weight-bold">
                                                    <pre
                                                        style={assignObj({
                                                            fontSize: '14px',
                                                            fontFamily:
                                                                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                                                        })}
                                                    >
                                                        {accountAddress}
                                                    </pre>
                                                </p>
                                            </div>
                                        </Col>
                                      
                                    </Row>
                                </Col>
                                <Col
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                style={assignObj({
                                                    backgroundColor: '#ffffff',
                                                    textAlign: 'left',
                                                    minHeight: '260px',
                                                    alignItems: 'left',
                                                    justifyContent: 'left',
                                                    paddingTop: '15px',
                                                    paddingBottom: '15px'
                                                })}
                                            >
                                                <div>
                                                    <StyledSpan>{Constants.INFO.MANAGE_SERVICES}</StyledSpan>
                                                    <AccountOptionsLinksContainer>
                                                        {accountServiceOptionsLinks.map(item => {
    
                                                            if(this.state.accountType === 'Coverdell ESA' && item.text === "Beneficiaries"){
                                                                
                                                            }else{
                                                                return (
                                                                    <div>
                                                                        <Link
                                                                            to={assignObj({
                                                                                pathname: item.route,
                                                                                state: {
                                                                                    masterRegId: masterId,
                                                                                    accountName,
                                                                                    accountType,
                                                                                    accountOwnerDesc,
                                                                                },
                                                                            })}
                                                                        >
                                                                           <ManageText>{item.text}</ManageText> 
                                                                        </Link>
                                                                    </div>
                                                                );
                                                            }
                                                            
                                                        })}
                                                    </AccountOptionsLinksContainer>
                                                </div>    
                                                {(isNewDashboardFeatureEnabled == true) &&
                                                <div className='mt-3'>
                                                <StyledSpan>{Constants.INFO.MANAGE_PREFERENCES}</StyledSpan>
                                                <AccountOptionsLinksContainer>
                                                    {accountManagePreferences.map((item,index) => {
    
                                                        return (
                                                            <div  id={`manageTextPreferences${index}`}>
                                                                <Link
                                                                    to={assignObj({
                                                                        pathname: item.route,
                                                                        state: {
                                                                            masterRegId: masterId,
                                                                            accountName,
                                                                            accountType,
                                                                            accountOwnerDesc,
                                                                        },
                                                                    })}
                                                                >
                                                                    <ManageText>{item.text}</ManageText> 
                                                                </Link>
                                                            </div>
                                                        );
                                                    
                                                        
                                                    })}
                                                </AccountOptionsLinksContainer>
                                                </div> 
                                                }                                    
                                            </Col> 
                            </Row>
                            <Row>
                                <Styles.FormContainer
                                    key="1"
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="media-trans-history-table"
                                >
                                    {!transactionHolderData.isLoading && (
                                        <TransactionTableList
                                            tHolder={viewableTransactionList}
                                            getAccountList={getAccountList}
                                            masterRegistrationAccountData={masterRegistrationAccountData}
                                            masterId={masterId}
                                            allowedTransactions={allowedTransactions}
                                            getFundDetails={getFundDetails}
                                            accOpeningReducerData={accOpeningReducerData}
                                            fundSummaryUpdate={this.fundSummaryUpdate}
                                            authBox={this.authBox}
                                            showAuthBox={showAuthBox}
                                        />
                                    )}
                                </Styles.FormContainer>
                            </Row>
                            {mostRecentDate && !fundSummaryClicked && (
                                <Row>
                                    <div className="mt-3 ml-3">
                                        <p style={assignObj({ fontSize: '14px' })} id={Constants.INFO.AS_ON}>
                                            {Constants.INFO.STAR}
                                            {Constants.INFO.AS_ON_VALUE_TEXT} {mostRecentDate ? `${getRecentDate}` : ''}{' '}
                                        </p>
                                    </div>
                                </Row>
                            )}

                            <Row>
                                {transactionHolderData.transactionList.fundList &&
                                viewableTransactionList &&
                                transactionHolderData.transactionList.fundList.length !==
                                    viewableTransactionList.length ? (
                                    <Styles.LoadMore id="loadmore items">
                                        <StyledButton
                                            style={assignObj({ display: 'flex' })}
                                            onClick={this.loadMoreData}
                                        >
                                            <Styles.ConstantStyle>{Constants.INFO.LOAD_MORE}</Styles.ConstantStyle>
                                            <img alt="loadmore" src={downArrowIcon} />
                                        </StyledButton>
                                    </Styles.LoadMore>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Container>
                    </div>
                </div>
                </>
                :
                <>
                    <div className="container">
                    <div className="transContainer">
                        <WBreadCrumb breadCrumbItems={Constants.INFO.BREADCRUMB_VALUES} activeCrumb={accountName} />

                        <StyledHeaderRow className="row tradeMenuBtnWrapper tradeMenuBtn">
                            <Col xs={12} md={9}>
                                <SideHeaderDiv>
                                    <AccountImgData />
                                    <LeftMargined mL="18">
                                        <PageHeading as="h1">
                                            {isNicknameUpdated
                                                ? `${updatedAccNickname}`?.substring(0, 18)
                                                : accountName.substring(0, 18)}
                                        </PageHeading>
                                    </LeftMargined>
                                    <EditBtn id="edit_btn_id" onClick={this.editTitleClick}>
                                        <Styles.ConstantStyleEdit>
                                            <Styles.EditImage alt="" src={editIcon} />
                                            <p>{Constants.EDIT_TEXT}</p>
                                        </Styles.ConstantStyleEdit>                        
                                        <span className="sr-only">
                                            {this.showOpenDialogText(isNicknameUpdated)}
                                        </span>
                                    </EditBtn>
                                    {showModal && (
                                        <UpdateText
                                            onPrimaryClick={this.submitEditTitleClick}
                                            onSecondaryClick={this.cancelEditTitleClick}
                                        />
                                    )}
                                </SideHeaderDiv>
                            </Col>
                            {
                                <Col xs={12} md={3} align="right">


                                    <KebabMenu
                                        menuOptions={this.state.menuOptions}
                                        onMenuOptionClick={this.onMenuOptionClick}
                                        menuBtnLabel="MenuItem"
                                        isNotKebab
                                        CustomBtn={CustomBtn}
                                    />

                                </Col>
                            }
                        </StyledHeaderRow>
                        <Container className="pl-0">
                            <Row
                                style={assignObj({
                                    border: borderColor,
                                    marginBottom: '25px',
                                    backgroundColor: '#f7f7f7',
                                })}
                            >

                                <Col xs={12} sm={12} md={6}>
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={8}
                                            style={assignObj({
                                                borderRight: borderColor,
                                                backgroundColor: '#ffffff',
                                                textAlign: 'center',
                                                height: '260px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingTop: '35px',
                                            })}
                                        >
                                            <div className="acc-activity-main">
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.CURR_VALUE}
                                                </span>
                                                <p className="font-weight-bold" style={assignObj({ fontSize: '20px',textAlign:"center" })}>
                                                    {currencyFormatter.format(totalAmount, { locale: 'en-US' })}
                                                </p>
                                            </div>
                                            <Link
                                                style={assignObj({
                                                    height: '21px',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    lineHeight: '20px',
                                                    display: 'inline-block',
                                                    color: 'rgba(0, 74, 152, 1)',
                                                })}
                                                to={assignObj({
                                                    pathname: '/accountholder/activity',
                                                    state: {
                                                        name: accountName,
                                                        masterRegId: masterId,
                                                    },
                                               
                                                })}
                                            >
                                                {Constants.INFO.V_AC_ACTIVITY}
                                            </Link>
                                                 {/*  Below Changes  3209  --- For adding  IRA Contributions , author :  Gaurav Kumar (Hyniva) */}

                                                     { this.state.checkIRAContributionSHow ? (
                                                    <div>
                                                    <hr/>
                                                    <div className="row mainDiv" >
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <table className="tableDiv">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="tableHeaderWidth2"></th>
                                                                        <th style={{fontWeight : "normal"}}> <span className="iraContributionFontSize">Contributions</span></th>
                                                                        <th style={{fontWeight : "normal"}}><span className="iraContributionFontSize">Limit</span></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.preYear}:</span></td>
                                                                        <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.prevYearIRA)}</b></span></td>
                                                                        <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.prevYearIRALimit}</b></span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.currentYearVal}:</span></td>
                                                                        <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.currentYearIRA)}</b></span></td>
                                                                        <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.currYearIRALimit}</b></span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    ) : (

                                                        <div></div>
                                                    )}
                                               
                                                {/* End 3209 here author :  Gaurav Kumar (Hyniva)*/}
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.ACC_NUMBER}
                                                </span>
                                                <p className="font-weight-bold">
                                                    {masterId}
                                                </p>
                                            </div>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.ACC_TYPE}
                                                </span>
                                                <p className="font-weight-bold">{accountType}</p>
                                            </div>
                                        </Col>
                                      
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <div
                                                className="acc-activity-main"
                                                style={assignObj({ paddingTop: '15px' })}
                                            >
                                                <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                                    {Constants.INFO.REG}
                                                </span>
                                                <p className="font-weight-bold">
                                                    <pre
                                                        style={assignObj({
                                                            fontSize: '14px',
                                                            fontFamily:
                                                                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                                                        })}
                                                    >
                                                        {accountAddress}
                                                    </pre>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Styles.FormContainer
                                    key="1"
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="media-trans-history-table"
                                >
                                {/*TODO:  */}
                                    {!transactionHolderData.isLoading && (
                                        <TransactionTableList
                                            tHolder={viewableTransactionList}
                                            getAccountList={getAccountList}
                                            masterRegistrationAccountData={masterRegistrationAccountData}
                                            masterId={masterId}
                                            allowedTransactions={allowedTransactions}
                                            getFundDetails={getFundDetails}
                                            accOpeningReducerData={accOpeningReducerData}
                                            fundSummaryUpdate={this.fundSummaryUpdate}
                                            authBox={this.authBox}
                                            showAuthBox={showAuthBox}
                                        />
                                    )}
                                </Styles.FormContainer>
                            </Row>
                            {mostRecentDate && !fundSummaryClicked && (
                                <Row>
                                    <div className="mt-3 ml-3">
                                        <p style={assignObj({ fontSize: '14px' })} id={Constants.INFO.AS_ON}>
                                            {Constants.INFO.STAR}
                                            {Constants.INFO.AS_ON_VALUE_TEXT} {mostRecentDate ? `${getRecentDate}` : ''}{' '}
                                        </p>
                                    </div>
                                </Row>
                            )}

                            <Row>
                                {transactionHolderData.transactionList.fundList &&
                                viewableTransactionList &&
                                transactionHolderData.transactionList.fundList.length !==
                                    viewableTransactionList.length ? (
                                    <Styles.LoadMore id="loadmore items">
                                        <StyledButton
                                            style={assignObj({ display: 'flex' })}
                                            onClick={this.loadMoreData}
                                        >
                                            <Styles.ConstantStyle>{Constants.INFO.LOAD_MORE}</Styles.ConstantStyle>
                                            <img alt="loadmore" src={downArrowIcon} />
                                        </StyledButton>
                                    </Styles.LoadMore>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Container>
                    </div>
                </div>
                </>
    }
                <div
                    className="container-fluid"
                    role="contentinfo"
                    style={assignObj({ backgroundColor: '#FAFAFA', borderTop: borderColor })}
                >
                    <div className="container pt-4 pb-4">
                        <BackNavigation
                            text={Constants.INFO.BACK_TO_PORT}
                            url={Constants.INFO.URL}
                            icon={leftArrowGreen}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

TransactionHistory.propTypes = {
    location: PropTypes.shape({
        masterId: PropTypes.string,
        name: PropTypes.string,
    }),
    transactionHolderData: PropTypes.instanceOf(Object),
    getTransHolderList: PropTypes.func,
    setLoadingPage: PropTypes.func,
    isLoading: PropTypes.bool,
    getAccountList: PropTypes.func,
    masterRegistrationAccountData: PropTypes.instanceOf(Object),
    accOpeningReducerData: PropTypes.instanceOf(Object),
    liquidationData: PropTypes.instanceOf(Object),
    getFundDetails: PropTypes.func,
    updateAccountNickname: PropTypes.func,
    resetAllMemberDashbaordData: PropTypes.func,
    getAccountAddress: PropTypes.func,
    clearLiquidationData: PropTypes.func,
    getTradingClosureWindow: PropTypes.func,
    getIRAContributionYearValues: PropTypes.func,
    iRAContributionYearValues: PropTypes.func,
};

TransactionHistory.defaultProps = {
    location: {},
    transactionHolderData: {},
    getTransHolderList: () => {},
    setLoadingPage: () => {},
    isLoading: true,
    getAccountList: () => {},
    masterRegistrationAccountData: {},
    getFundDetails: () => {},
    accOpeningReducerData: {},
    liquidationData: {},
    updateAccountNickname: () => {},
    resetAllMemberDashbaordData: () => {},
    getAccountAddress: () => {},
    clearLiquidationData: () => {},
    getTradingClosureWindow: () => {},
    getIRAContributionYearValues : ()=>{},
    iRAContributionYearValues: ()=>{}

};

export const mapStateToProps = state => {
    return {
        iRAContributionYearValues : state.purchaseData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
        accOpeningReducerData: state.accOpeningReducerData,
        liquidationData: state.liquidationData
    };
};
export const mapDispatchToProps = {
    getAccountList: masterRegistrationAccountActions.getAccountsList,
    updateAccountNickname: accOpeningActions.updateAccountNickname,
    getFundDetails: accOpeningActions.getFundDetailsData,
    resetAllMemberDashbaordData: memberDashboardActions.resetAllMemberDashbaordData,
    getAccountAddress: liquidationActions.getStateCodeGroup,
    clearLiquidationData: liquidationActions.clearLiquidationData,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    getIRAContributionYearValues : purchaseActions.getIRAContributionYearValues,
    getEnabledFeature: memberDashboardActions.getEnabledFeature,
    resetNickNameUpdatedStatus: accOpeningActions.resetNickNameUpdatedStatus
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
