/* eslint-disable no-underscore-dangle */
import isEmpty from 'lodash/isEmpty';
import {
  digitalData,
  pageDocument,
} from './constants';

const triggerClickTrackEvent = (name) => {
  if (window._satellite) {
    window.digitalData = {
      link: name,
    };
    window._satellite.track('pre register click');
  }
};

const triggerErrorEvent = (errors) => {
  if (window._satellite) {
    window.digitalData = {
      linkerror: {
        errors,
      },
    };
    window._satellite.track('preregister error click');
  }
};

const getHierarchy = (pageHier, currentPage, pathName, pageName) => {
  let hierarchy = pageHier === '' ? currentPage : `${pageHier}|${currentPage}`;
  if (pathName === 'default') {
    if (!isEmpty(pageName)) {
      digitalData.page.pageInfo.PageName = pageName;
    }
    hierarchy = currentPage;
  } else {
    const hierArr = hierarchy.split('|');
    let prevItem = '';
    const itemList = [];
    hierArr.forEach((item) => {
      if (prevItem !== item) {
        prevItem = item;
        itemList.push(prevItem);
      }
    });
    hierarchy = itemList.join('|');
  }
  return hierarchy;
}

const setDigitalData = (pathName, nextProps, nextState) => (instance) => {
  const { pageName } = nextProps;
  const { pageHier = '', prevPageUrl } = nextState;
  const pageInfo = pageDocument.filter(info => info.key === pathName);
  const pageDetails = pageInfo.length ? pageInfo[0] : {};
  if (pathName === 'Signout') {
    digitalData.page.pageInfo.previousPagename = pageDetails.previousPage;
  }
  digitalData.page.pageInfo.pageDocumenttitle = pageDetails.pageDocumenttitle;
  if (pathName !== 'Signout') {
    digitalData.page.pageInfo.previousPagename = pageDetails.previousPage;
    if (pathName === 'termsandconditions' && !isEmpty(pageName)) {
      digitalData.page.pageInfo.previousPagename = pageName;
    }
  }
  digitalData.page.pageInfo.PageName = pageDetails.pageName;

  const currentPage = String(pageDetails.pageName).replace(
      'Preregistration|',
      '',
  );
  const hierarchy = getHierarchy(pageHier, currentPage, pathName, pageName);
  digitalData.page.pageInfo.pageHier = hierarchy;
  if (prevPageUrl !== window.document.URL) {
    digitalData.page.pageInfo.pageRef = prevPageUrl;
    instance.setState({
      pageHier: hierarchy,
      prevPageUrl: window.document.URL,
      pathName,
    });
  } else {
    instance.setState({ pageHier: hierarchy, pathName });
  }
  digitalData.page.pageInfo.pageURL = window.document.URL;
  return null;
};
export default { triggerClickTrackEvent, setDigitalData, triggerErrorEvent };
