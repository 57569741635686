import React from "react";
import styled from "styled-components";


export const InputCheckBox = styled.input`
    &&&{
        width: 25px;
        height: 25px;
        margin-right: 15px;
        position: relative;
        background: ${props => props.disabled? '#E6E6E6' : '#ffffff' } 0% 0% no-repeat padding-box;
        opacity: 1;
        border: 1px solid #56565A;
        
        &:hover {
            border: 1px solid #486D90;
        }
        &:checked {
            border-color: #004A98;
        }    
        &:checked::after {
            content: '';
            width: ${props => props.partial ? 0 : 8}px;
            height: 13px;
            border: solid #004A98;
            border-width: 0 2px 2px 0;
            top: ${props => props.partial ? 5 : 3}px;
            left: ${props => props.partial ? 10 : 7}px;
            position: absolute;
            background-color: #fff;
            transform: rotate(${props => props.partial ? 90 : 45}deg);
            
        }
    }
`;

const CheckBox = (props) => {
    return <InputCheckBox type="checkbox" {...props}/>
}

export default CheckBox;