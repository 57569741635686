const role =  localStorage.getItem('role');
const breadCrumbValues = [  { name: 'Home', url: role==='Member' ? '/accountholder': '/accountguestview' },
{ name: 'Profiles & Preferences', url: '/Profile' }
];
const pageName = "Security Preferences";
const fontAwesomeStyle = {
    height: 40, width: 40
};
const cardSubtitleStyle = {
    font: '500 14px/20px benton-sans',
    color: '#49494A',
    maxWidth: '80%',
    textAlign: 'left',
    letterSpacing: 0,
    opacity: 1,
    paddingLeft: '10px'
};
const cardStyle = {
    margin: '0 0 30px 0',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    cursor: "pointer",
};
const cardStyleDisabled = {
    margin: '0 0 30px 0',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 0.5,
    cursor: "not-allowed",
};
const cardTitleStyle = {
    font: '800 18px/22px yorkten-slab-normal',
    color: '#486D90',
    textTransform: 'capitalize',
    marginBottom: 5,
    paddingBottom: 0
};

const cardColor = {
    color: 'green'
};

export default{
    breadCrumbValues, pageName, fontAwesomeStyle, cardSubtitleStyle, cardColor, cardStyle, cardTitleStyle, cardStyleDisabled
}