export const GET_ACCOUNT_ACTIVITIES = 'GET_ACCOUNT_ACTIVITIES';
export const GET_ACCOUNT_ACTIVITIES_SUCCESS = 'GET_ACCOUNT_ACTIVITIES_SUCCESS';
export const GET_ACCOUNT_ACTIVITIES_ERROR = 'GET_ACCOUNT_ACTIVITIES_ERROR';

export const CANCEL_ACTIVITY_TRANSACTION = 'CANCEL_ACTIVITY_TRANSACTION';
export const CANCEL_ACTIVITY_TRANSACTION_SUCCESS = 'CANCEL_ACTIVITY_TRANSACTION_SUCCESS';
export const CANCEL_ACTIVITY_TRANSACTION_ERROR = 'CANCEL_ACTIVITY_TRANSACTION_ERROR';

export const LOAD_MORE_SUCCESS = 'LOAD_MORE_SUCCESS';
export const LOAD_MORE = 'LOAD_MORE_ACTIVITY_TRANSACTION';
