import { analyzeClickEvent } from 'shared/Actions/AnalyticsActions';
import { Card, ToggleButton } from 'react-bootstrap';
import PDFIcon from '../../assets/pdfdownloadsmall.png';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from '../CommonStyles';
import { useDispatch } from 'react-redux';
import WButton from '../WButton/WButton';
import WIcon from '../WIcon/WIcon';
import './WCard.css';

const StyledCard = styled(Card)`
    background-color: ${props => (props.selected ? '#FAFAFA' : '#FFFFFF')};
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 0;
    outline: ${props => (props.selected ? '0' : '1px solid #D2D2D2')};
    border: ${props => (!props.selected ? '2px solid #fff' : '2px solid #004A98')};
    position: relative;
    border-radius: 0;
    &:hover {
        outline: ${props => (props.selected ? '0' : '1px solid #3C5A77')};
    }
    &:focus {
        outline: ${props => (props.selected ? '0' : '2px solid #3C5A77')};
    }
    .leftCornerCard {
        width: 0;
        height: 0;
        border-bottom: 20px solid transparent;
        border-left: ${props => (!props.selected ? '20px solid #D2D2D2' : '20px solid #8BC105')};
        position: absolute;
        top: -1px;
        left: -1px;

        @media (max-width: 767px) {
            top: ${props => (!props.selected ? '-2px' : '0px')};
            left: ${props => (!props.selected ? '-2px' : '0px')};
        }
    }
`;

const StyledDiv = styled.div`
    margin-bottom: ${props => props.marginBtm || 'auto'};
    width: 100%;
    @media (min-width: 768px) and (max-width: 991px) {
        height: ${props => props.mdHeight || 'auto'};
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        height: ${props => props.lgHeight || 'auto'};
    }
`;
const CustomDiv = styled.div`
    > label > input[type='radio']{
        height: 15px;
        position: absolute;
        width: 100%;
        left:0;
        top:0.5px;
        z-index: -1;
    }   
    label  button{
        margin: 0;
        padding: 0;
    }
    label{
        padding: 0;
        margin: 0;
    }
    label div{
        color: rgb(86, 86, 90); 
        font-weight: bold;
    }
 
}`;
const assignObj = obj => {
    return obj;
};


const CardBody = (props, restProps) => {

    const {
        cardbodystyle,
        cardtitlestyle,
        cardsubtitlestyle,
        cardtitletext,
        cardtitlearialabel,
        cardsubtitletext,
        cardtextstyle,
        cardtext,
        downloadtext,
        pdflink,
        buttontext,
        download,
        titleiconsrc,
        titleiconstyle,
    } = props;

    return (
        <Card.Body style={cardbodystyle}>
            {titleiconsrc ? <WIcon src={titleiconsrc} style={titleiconstyle} /> : <></>}
            <Card.Title
                role="heading"
                aria-level={cardtitlearialabel}
                style={assignObj({ ...styles.cardTitleStyle, ...cardtitlestyle })}
            >
                {cardtitletext}
            </Card.Title>
            {cardsubtitletext ? (
                <Card.Subtitle
                    className="mb-2"
                    style={assignObj({...cardsubtitlestyle })}
                >
                    {cardsubtitletext}
                </Card.Subtitle>
            ) : null}
            {cardtext ? (
                <Card.Text style={assignObj({ ...styles.cardTextStyle, ...cardtextstyle })}>
                    {cardtext}
                </Card.Text>
            ) : null}
            {downloadtext === 'false' ? (
                <WButton {...restProps} />
            ) : (
                <a
                    type="a"
                    className="download-link"
                    href={pdflink}
                    target="_blank"
                    aria-label={buttontext}
                    download={download}
                    rel="noopener noreferrer"
                >
                    <WIcon src={PDFIcon} className="pdfIcon" size="1x" />
                    {buttontext}
                </a>
            )}
        </Card.Body>
    )
}

const WCard = props => {
    const {
        icon,
        cardstyle,
        cardimgstyle,
        imgvariant,
        src,
        cardbodystyle,
        cardtitlestyle,
        cardTitleLinkStyle,
        cardTitleH2Style,
        cardsubtitlestyle,
        cardtitletext,
        cardsubtitletext,
        cardtextstyle,
        cardtext,
        fontawesomeiconcard,
        leftcornercard,
        selected,
        buttonstyle,
        linkcard,
        descid,
        titleiconsrc,
        titleiconstyle,
        onClick,
        dataAnalyticId,
        waitforapiresponse,
        tabIndex,
        role,
        haveToggleButton,
        renderingComponent,
    } = props;
    // Removing parse Error
    const { lgHeight, mdHeight, marginBtm, alt, ...restProps } = props;
    const buttonNeeded =buttonstyle.display === 'none' && linkcard === 'false';
    const screenWidth = 767
    const dispatch = useDispatch();

    function analyzeClick(e) {
        onClick(e);
        dispatch(
            analyzeClickEvent({
                link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
                waitforapiresponse,
            }),
        );
    }
    return (
        <StyledDiv lgHeight={lgHeight} mdHeight={mdHeight} marginBtm={marginBtm} className="w-card">
           
            {buttonNeeded ? (
                <StyledCard
                    style={assignObj({ ...cardstyle })}
                    {...restProps}
                    selected={selected}
                    aria-checked={selected}
                    role={role}
                    onClick={analyzeClick}
                    onKeyPress={analyzeClick}
                    data-analytic-id={dataAnalyticId}
                >
                    <div>
                        <div style={assignObj({ ...leftcornercard })} className="leftCornerCard" />
                        {icon && (
                            <WIcon
                                {...restProps}
                                fontawesomestyle={assignObj({ ...styles.fontawesomeIconCard, ...fontawesomeiconcard })}
                                aria-describedby=""
                                role=""
                            />
                        )}
                        {src !== '' && !icon && (
                            <>
                                {renderingComponent === 'fundSourceBuy' && <h5 className="sr-only">{cardtitletext}</h5>}
                                <Card.Img
                                    style={assignObj({ ...cardimgstyle })}
                                    variant={imgvariant}
                                    src={src}
                                    alt={alt}
                                    tabIndex="0"
                                />
                            </>
                        )}

                        <Card.Body style={cardbodystyle} id={descid}>
                            {haveToggleButton ? (
                                <CustomDiv>
                                    <ToggleButton
                                        key={'1'}
                                        type="radio"
                                        variant="secondary"
                                        name="radio"
                                        style={assignObj({
                                            width: '100%',
                                            backgroundColor: '#FFFFFF',
                                            boder: 0,
                                        })}
                                        value={cardtitletext}
                                        checked={selected}
                                        aria-checked={selected}
                                        className="btn btn-outline-light"
                                    >
                                        {titleiconsrc ? <WIcon src={titleiconsrc} style={titleiconstyle} /> : <></>}

                                        <Card.Title style={assignObj({ ...styles.cardTitleStyle, ...cardtitlestyle })}>
                                            {cardtitletext}
                                        </Card.Title>

                                        {cardsubtitletext ? (
                                            <Card.Subtitle
                                                className="mb-2"
                                                style={assignObj({ ...styles.cardsubtitletext, ...cardsubtitlestyle })}
                                            >
                                                {cardsubtitletext}
                                            </Card.Subtitle>
                                        ) : null}
                                    </ToggleButton>

                                    {cardtext ? (
                                        <Card.Text style={assignObj({ ...styles.cardTextStyle, ...cardtextstyle })}>
                                            {cardtext}
                                        </Card.Text>
                                    ) : null}
                                </CustomDiv>
                            ) : (
                                <>
                                    {' '}
                                    {titleiconsrc ? <WIcon src={titleiconsrc} style={titleiconstyle} /> : <></>}
                                    <Card.Title style={assignObj({ ...styles.cardTitleStyle, ...cardtitlestyle })}>
                                        {renderingComponent === 'fundSourceBuy' ? (
                                            <h2 aria-hidden="true" className="p-0 m-0">
                                                {cardtitletext}
                                            </h2>
                                        ) : (
                                            <h2
                                                role={window.innerWidth > screenWidth ? 'heading' : 'presentation'}
                                                className="px-0"
                                                style={assignObj({ ...cardTitleH2Style })}
                                            >
                                                <span
                                                    role="link"
                                                    className="m-0"
                                                    tabIndex="0"
                                                    style={assignObj({ ...cardTitleLinkStyle })}
                                                >
                                                    {cardtitletext}
                                                </span>
                                            </h2>
                                        )}
                                    </Card.Title>
                                    {cardsubtitletext ? (
                                        <Card.Subtitle
                                            className="mb-2"
                                            style={assignObj({ ...styles.cardsubtitletext, ...cardsubtitlestyle })}
                                        >
                                            {cardsubtitletext}
                                        </Card.Subtitle>
                                    ) : null}
                                    {cardtext ? (
                                        <Card.Text style={assignObj({ ...styles.cardTextStyle, ...cardtextstyle })}>
                                            {cardtext}
                                        </Card.Text>
                                    ) : null}
                                </>
                            )}
                        </Card.Body>
                    </div>
                </StyledCard>
            ) : (
                <StyledCard
                    style={assignObj({ ...cardstyle })}
                    {...restProps}
                    selected={selected}
                >
                    <div style={assignObj({ ...leftcornercard })} className="leftCornerCard" />
                    {icon && (
                        <WIcon
                            {...restProps}
                            fontawesomestyle={assignObj({ ...styles.fontawesomeIconCard, ...fontawesomeiconcard })}
                        />
                    )}
                    {!icon && src !== '' && !icon && (
                        <Card.Img
                            style={assignObj({ ...cardimgstyle })}
                            variant={imgvariant}
                            src={src}
                            alt={alt}
                            tabIndex={tabIndex}
                        />
                    )}

                        {CardBody(props, restProps)}
                </StyledCard>
            )}
        </StyledDiv>
    );
};

WCard.propTypes = {
    cardstyle: PropTypes.instanceOf(Object),
    src: PropTypes.string,
    alt: PropTypes.string,
    cardtitlestyle: PropTypes.instanceOf(Object),
    cardsubtitlestyle: PropTypes.instanceOf(Object),
    cardtitletext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cardtitlearialabel: PropTypes.string,
    cardTitleLinkStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cardTitleH2Style: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cardtextstyle: PropTypes.instanceOf(Object),
    cardtext: PropTypes.string,
    cardsubtitletext: PropTypes.string,
    cardbodystyle: PropTypes.instanceOf(Object),
    icon: PropTypes.instanceOf(Object),
    imgvariant: PropTypes.string,
    cardimgstyle: PropTypes.instanceOf(Object),
    leftcornercard: PropTypes.instanceOf(Object),
    fontawesomeiconcard: PropTypes.instanceOf(Object),
    downloadtext: PropTypes.string,
    pdflink: PropTypes.string,
    download: PropTypes.bool,
    buttonstyle: PropTypes.instanceOf(Object),
    linkcard: PropTypes.string, // pass linkcard="true" if card is already inside link or focus is not needed
    mdHeight: PropTypes.string,
    lgHeight: PropTypes.string,
    marginBtm: PropTypes.string,
    descid: PropTypes.string,
    titleiconsrc: PropTypes.string,
    titleiconstyle: PropTypes.instanceOf(Object),
    waitforapiresponse: PropTypes.bool,
    tabIndex: PropTypes.string,
    role: PropTypes.string,
    haveToggleButton: PropTypes.bool,
    renderingComponent: PropTypes.string,
};

WCard.defaultProps = {
    cardtitletext: 'Title',
    cardtitlearialabel: '',
    cardtext: null,
    icon: null,
    imgvariant: '',
    cardstyle: null,
    alt: null,
    cardtitlestyle: null,
    cardTitleLinkStyle: null,
    cardTitleH2Style: null,
    cardsubtitlestyle: null,
    cardtextstyle: null,
    cardsubtitletext: null,
    cardbodystyle: null,
    cardimgstyle: null,
    src: '',
    leftcornercard: {},
    fontawesomeiconcard: {},
    downloadtext: 'false',
    pdflink: '',
    download: false,
    buttonstyle: { display: 'inline-block' },
    linkcard: 'false',
    mdHeight: '',
    lgHeight: '',
    marginBtm: '',
    descid: '',
    titleiconsrc: null,
    titleiconstyle: null,
    waitforapiresponse: false,
    tabIndex: null,
    role: null,
    haveToggleButton: false,
    renderingComponent: '',
};

export default WCard;
