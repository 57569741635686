/**
 * Change Sign In By Push Notification Component
 ***********************************************
 *
 * @version 1.0.1
 * @author Manjunath V
 * @description By using the Push Notification method to change the Sign in mode
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedBy Manjunath
 * @lastModifiedReason Modified for Back button functionality to hold the prev screen (ChangeSignInMethodOptions) selected radio button
 *                     option to be selected when prev screen (ChangeSignInMethodOptions) page is loaded and implemented Breadcrumbs
 */

import React, { Component, memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router';
import { WIcon, WBreadCrumb, CommonButtons, WSpinner } from 'common';
import appleStore from "assets/apple-store.svg";
import playStore from "assets/play-store.svg";
import routeConstants from 'routeConstants';
import SideHeader from '../SideHeader/SideHeader';
import styles from './styles';
import userConstants from '../userConstants';

const { pageHeaderName, downloadLabel, successMessage, failureMessage } = userConstants.changePush;
const {breadCrumbValues} = userConstants.changeSignInMethodOptions;

/* const assignObj = obj => {
    return obj;
}; */

export class ChangeSignInByPushNotificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptionOnPrevPage: '',
            back: false,
            loading: false,
            error: true,
            messageText: { message: userConstants.changePush.successMessage },
        };
        this.clickBack = this.clickBack.bind(this);
    }

    // static getDerivedStateFromProps(nextProps, state) {
    //     const { loginState } = nextProps;
    //     console.log(loginState)
    //     let updatedState = null;
    //     if (loginState && loginState.data && loginState.data.response) {
    //         if (loginState.data.response.status === 200) {
    //             const statusCode = loginState.data.response.data.statusCode;
    //             if (statusCode === 201) {
    //                 updatedState = { ...state, back: true, loading: false }
    //             }
    //             // else {
    //             //     updatedState = { ...state, back: true, loading: false, error: true }
    //             // }
    //         }
    //     }
    //     return updatedState;
    // }

    componentDidMount() {
        const { location } = this.props;
        const { state } = location || {};
        if (state) {
            this.setState({
                selectedOptionOnPrevPage: state.selectedOption,
            });
        }
    }

    clickSubmit = () => {
        const { messageText } = this.state;
        const { history } = this.props;

        history.push({
            pathname: routeConstants.securityPreferences,
            message: messageText,
        });
    };

    clickBack() {
        const { history } = this.props;
        const { selectedOptionOnPrevPage } = this.state;
        // setSignInMethod({
        //     "onlineId": "test",
        //     "changeSignInMethod": "push", // otp, softtoken,push
        //     // "preferredOTPDeliveryMethod": "mobile", // "email",    // "mobile","securityQuestions",
        //     // "deviceName": "iPhoneX",
        //     // "deviceId": "AEDR12345667"
        // });
        if (history) {
            history.push({
                pathname: routeConstants.changeSignInMethodOptions,
                state: {
                    selectedOption: selectedOptionOnPrevPage,
                },
            });
        }
        // this.setState({
        //     loading: true
        // });
    }

    render() {
        const { back, loading, selectedOptionOnPrevPage, error } = this.state;
        const route = {
            payload: {
                pathname: routeConstants.securityPreferences,
                state: { selectedOption: selectedOptionOnPrevPage },
                message: successMessage,
                errMsg: failureMessage,
                error,
            },
        };
        // console.log(this.state)
        return (
            <div>
                <WSpinner loading={loading} />
                {/* <LoadingOverlay active={loading} spinner styles={assignObj({ wrapper: { overflow: userConstants.hidden } })} /> */}
                <div className={userConstants.container}>
                    <WBreadCrumb breadCrumbItems={breadCrumbValues} activeCrumb={userConstants.changeSignInMethodText} />
                    <SideHeader title={pageHeaderName} />
                    <div className={userConstants.container} style={styles.downloadLabelMargins}>
                        <Row className={userConstants.w100}>
                            <Col>
                                <p style={styles.subHeading}> {downloadLabel} </p>
                            </Col>
                        </Row>
                    </div>
                    <div className={userConstants.container} style={styles.downloadLabelMargins}>
                        <Row>
                            <a href={userConstants.appStoreLink}>
                                <WIcon src={appleStore} alt={userConstants.appStore} fontawesomestyle={styles.downloadImage} />
                            </a>
                            <a href={userConstants.playStoreLink}>
                                <WIcon src={playStore} alt={userConstants.playStore} />
                            </a>
                        </Row>
                    </div>
                    <CommonButtons backClick={this.clickBack} submitClick={this.clickSubmit} submit={userConstants.trueText} arialabelback="Back to change sign in methods" />
                    {back ? <Redirect to={route.payload} /> : null}
                </div>
            </div>
        );
    }
}

ChangeSignInByPushNotificationComponent.propTypes = {
    // setSignInMethod: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    location: PropTypes.instanceOf(Object),
};

ChangeSignInByPushNotificationComponent.defaultProps = {
    // setSignInMethod: () => {},
    history: [],
    location: {},
};

export default memo(ChangeSignInByPushNotificationComponent)
