import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import routeConstants from "routeConstants";
import CONST from "modules/CustomerManagementModule/EmailInformation/constants";
import { BottomNavBar, PageTitle, FieldValue, InputText, InstructionalPanel } from 'modules/CustomerManagementModule/components';
import {
    isValidEmail,
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;
export default class RelationshipAddEmailInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
        };
    }

    componentDidMount() {
        const { isEdit, location } = this.props;
        const { state } = location || {};
        const { email } = state || {};
        if (isEdit) {
            const stateObj = {
                fields: {
                    emailId: email,
                },
            };
            
            this.setState(stateObj);
        }
        addFormAbandonUnloadListner(isEdit);
    }

    componentDidUpdate(){
        addFormFieldsListner();
        const { profileRelationshipData, clearRelationActions, history, analyzeClickEvent, linkResponseError, isEdit } = this.props;
        const { isError, isSuccess, message } = profileRelationshipData;
        if(isError){
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_Managerelationship_${isEdit?'edit':'add'}emailinfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearRelationActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_Managerelationship_${isEdit?'edit':'add'}emailinfo_save`, analyzeClickEvent);
            history.push(routeConstants.relationshipemailInfo);
        }
    }

    componentWillUnmount(){
        const { profileRelationshipData, isEdit } = this.props;
        const { isSuccess } = profileRelationshipData;
        if(!isSuccess){
            const digitalData = getFormAbandData(isEdit);
            const { fieldlasttouch } = digitalData;
            if (!isEdit || (isEdit && fieldlasttouch !== 'none')) trackFormAbandonData(digitalData);
        }
        removeFormAbandonUnloadListner();
        
    }

    updateOrAddEmailInfo = () => {
        const { fields } = this.state;
        const {
            profileRelationshipData,
            updateProfileRelationship,
            location,
        } = this.props;
        const { familyRelationShipInfo, familyMemberIndex } = profileRelationshipData;
        const familyMemberInfo = familyRelationShipInfo[+familyMemberIndex] || {};
        const { relationshipId='' } = familyMemberInfo;
        
        const { state } = location || {};
        const { isPrimary=false } = state || {};
        let payLoad;
        if (isPrimary) {
            payLoad = {
                role : "Family",
                relationshipId,
                primaryEmail: fields.emailId,
            };
        } else {
            payLoad = {
                role : "Family",
                relationshipId,
                secondaryEmail: fields.emailId,
            };
        }
       updateProfileRelationship(payLoad);
    };

    handleChange = (e) => {
        const { fields } = this.state;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
            errors: {},
        });
    };

    validateEmail = () => {
        const { fields, errors } = this.state;
        const { emailId='' } = fields;
        errors.emailId = '';
        if(!emailId) {
            errors.emailId = CONST.EMAIL_ERROR_EMPTY;
        }else if(!isValidEmail(emailId)) {
            errors.emailId = CONST.EMAIL_ERROR_INVALID;
        }
        if(errors.emailId.length>0){
            const { analyzeFormFieldError } = this.props;
            analyzeFormFieldError()
        }
        
        this.setState({
            errors,
        });
    };
    
    cancelEmailInfo = () => {
        const {  history, analyzeClickEvent, isEdit } = this.props;
        // const { isPrimary } = this.state;
        analyzeClickEvent({
            link: `cms_basicinformation_Managerelationship_${isEdit?'edit':'add'}emailinfo_cancel`
            
        })
        history.push(routeConstants.relationshipemailInfo);
    }

    isAnythingChanged = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { email='' } = state || {};
        const { fields, errors } = this.state;
        const { emailId='' } = fields;
        const { emailId: emailErr } = errors;
        const isEmailChanged = Object.prototype.hasOwnProperty.call(fields, "emailId") && emailId !== email;
        return !emailErr && isEmailChanged;
    }

    render() {
        const {
            fields,
            errors,
        } = this.state;
        const { emailId:emailError = '' } = errors;
        const { emailId:emailValue = '' } = fields;
        const { isEdit, profileRelationshipData, location } = this.props;
        const { state } = location || {};
        const { isPrimary=false } = state || {};
        
        const { isLoading = false } = profileRelationshipData;

        primaryBtnProps.onClick = this.updateOrAddEmailInfo;
        primaryBtnProps.disabled = !this.isAnythingChanged();
        cancelBtnProps.onClick = this.cancelEmailInfo;

        return (
            <>
                {isLoading && <WSpinner loading={isLoading} />}
                    <Container>
                        <WBreadCrumb
                            breadCrumbItems={CONST.RELATIONSHIP_ADD_EMAIL_BREADCRUMB_VALUE}
                            activeCrumb={isEdit? CONST.PAGE_NAME_EDIT_EMAIL :CONST.PAGE_NAME_ADD_EMAIL}
                        /> 
                        <PageTitle
                            title={isEdit? CONST.PAGE_NAME_EDIT_EMAIL :CONST.PAGE_NAME_ADD_EMAIL}
                            showMandatoryTxt
                        />
                        <FieldValue
                            htmlFor="emailId"
                            label={CONST.EMAIL_ID}
                            value={
                                <InputText
                                    id="emailId"
                                    name="emailId"
                                    value={emailValue}
                                    onChange={this.handleChange}
                                    onBlur={this.validateEmail}
                                    maxLength={50}
                                    error={!!emailError}
                                    errorTxt={emailError}
                                    data-name={CONST.EMAIL_ID}
                                    data-form-field
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                        />
                        <InstructionalPanel mbt={2.67}>{ isPrimary ? CONST.PRIMARY_EMAIL_NOTE : CONST.SECONDARY_EMAIL_NOTE }</InstructionalPanel>
                    </Container>
                    
                    <BottomNavBar
                        hideBack
                        showCancelBtn
                        primaryBtnText={CONST.SAVE}
                        primaryBtnProps={primaryBtnProps}
                        cancelBtnProps={cancelBtnProps}
                        hidePrimaryBtn = {hideFlag}
                    />
                
            </>
        );
    }
}

RelationshipAddEmailInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    isEdit: PropTypes.bool,
    clearRelationActions: PropTypes.func,
    profileRelationshipData: PropTypes.instanceOf(Object),
    updateProfileRelationship: PropTypes.func,
    analyzeFormFieldError: PropTypes.func,
    analyzeClickEvent: PropTypes.func, 
    linkResponseError: PropTypes.func
};
RelationshipAddEmailInfoComponent.defaultProps = {
    isEdit: false,
    clearRelationActions: () => {},
    profileRelationshipData: {},
    updateProfileRelationship: () => {},
    analyzeFormFieldError: () => {},
    analyzeClickEvent: () => {}, 
    linkResponseError: () => {}
};
