import React from "react";
import PropTypes from 'prop-types';
import editIcon from 'assets/EditPencil.svg';
import { AddressView, FieldValue, PrefilledInput, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import { checkValues } from 'modules/CustomerManagementModule/utils';
import CONST from 'modules/CustomerManagementModule/EmploymentInformation/constants';

const getIsEmpInfoReq = (employmentStatus) => {
    const empClassForNoEmployer = ['Retired', 'Unemployed', 'Homemaker', 'Student'];
    return empClassForNoEmployer.includes(employmentStatus);
}

const EmploymentInfoView = (props) => {
    const { employmentStatus, industry, occupation, employerName,addressLine1, addressLine2, employersCity, employersState, primarySourceOfIncome, employersZipcode, onManageClick, hideManage } = props;
    
    const isEmployerDetailsNotReq =  getIsEmpInfoReq(employmentStatus); 
    
    return(
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{CONST.EMPLOYMENT_INFO}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL_EMPLOYMENT}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='0.625'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.EMPLOYMENT_STATUS}
                    value={
                        <PrefilledInput
                            id="vcm-cms-employmentStatus"
                            value={checkValues(employmentStatus)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.INDUSTRY}
                    value={
                        <PrefilledInput
                            id="vcm-cms-industry"
                            value={checkValues(industry)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                {isEmployerDetailsNotReq ? (
                    <FieldValue
                        as="p"
                        label={CONST.PRIMARY_SOURCE_OF_INCOME}
                        value={
                            <PrefilledInput
                                id="vcm-cms-income"
                                value={checkValues(primarySourceOfIncome)}
                            />
                        }
                        labelsm={3}
                        valuesm={5}
                        mb='1.75'
                    />
                    ) : (
                <>  
                <FieldValue
                    as="p"
                    label={CONST.OCCUPATION}
                    value={
                        <PrefilledInput
                            id="vcm-cms-occupation"
                            value={checkValues(occupation)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.625'
                />
                <FieldValue
                    as="p"
                    label={CONST.EMPLOYER_NAME}
                    value={
                        <PrefilledInput
                            id="vcm-cms-employerName"
                            value={checkValues(employerName)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='1.5'
                />
                <FieldValue
                    as="p"
                    label={CONST.EMPLOYER_ADD}
                    alignItems="stretch"
                    value={
                        <AddressView
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            city={employersCity}
                            state={employersState}
                            zip={employersZipcode}
                        />
                    }
                    mb='1.75'
                    labelsm={3}
                    valuesm={5}
                />
                </>)
                }
            </>
        );
}

export default EmploymentInfoView;

EmploymentInfoView.propTypes = {
    employmentStatus: PropTypes.string,
    industry: PropTypes.string,
    occupation: PropTypes.string,
    employerName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    employersCity: PropTypes.string,
    employersState: PropTypes.string,
    primarySourceOfIncome: PropTypes.string,
    employersZipcode: PropTypes.string,
    onManageClick: PropTypes.func,
    hideManage: PropTypes.bool,
}

EmploymentInfoView.defaultProps = {
    employmentStatus: '',
    industry: '',
    occupation: '',
    employerName: '',
    addressLine1: '',
    addressLine2: '',
    employersCity: '',
    employersState: '',
    primarySourceOfIncome: '',
    employersZipcode: '',
    onManageClick: () => {},
    hideManage:false,
}

