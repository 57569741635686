import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import Separator from '../../CustomerManagementModule/components/Separator';
import Toggle from '../../CustomerManagementModule/components/Toggle';
import TopLeftCornerColor from '../../CustomerManagementModule/components/TopLeftCornerColor';
import Label from '../../CustomerManagementModule/components/Label';
import { MobileNumber } from './MobileNumber';

const PanelWrap = styled(TopLeftCornerColor)`
	padding: 1.5em 2em;
	margin: 1.5em 0 2.5em;
	font: 1em benton-sans;
`
const HeaderWrap = styled.h2`
  border-bottom: 1px solid #d2d2d2;
  font: 1.125em yorkten-slab-extra-bold;
  color:#004A98;
	text-transform: capitalize;
	padding-bottom: 0.625em;
`
const FlexDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1em 0 0;
`
const Input = styled(Row)`
	margin-top: ${(props) => props['data-mt']}rem;
	margin-bottom: ${props => props['data-mb']}rem;
`

// const COUNTRY_HINT_TEXT = 'Country';
// const PHONE_HINT_TEXT = '(000)-000-0000';
const PHONEOPTION_HINT_TEXT = 'Is this a mobile number?';

export const MobileNoCard = (props) => {
	// const { toggleOn, changeToggle, countryCodeState, mobileNumberState, changed, blurred, disable} = props;
	// const {cardTitle} = props;
	const { cardTitle, changeIsMobile,countryCodeState, mobileNumberState, changed, blurred, clicked } = props;
	
	// const { setCountryCode } = relationPhoneInformation;
	const cornerColor = 'grey';
	return (
		<PanelWrap cornerColor={cornerColor}>
			<HeaderWrap>{cardTitle}</HeaderWrap>
			<Input data-mt={1.5} data-mb={1.5}>
			<MobileNumber 
				countryCodeState={countryCodeState}
				mobileNumberState={mobileNumberState}
				changed={changed}
				blurred={blurred}
				clicked={clicked}
				// disable={disable} 
			/>
			</Input>
			<Separator aria-hidden="true"/>
			<FlexDiv>
				<Label id="mobileNumber" >{PHONEOPTION_HINT_TEXT}</Label>
				<Toggle 
				//	toggleOn={toggleOn} 
				//	onChange={changeToggle}				
					onChange={changeIsMobile}
					aria-labelledby= "mobileNumber"
				/>
			</FlexDiv>
		</PanelWrap>
	)
}

MobileNoCard.propTypes = {
	cardTitle: PropTypes.string,
	countryCodeValue: PropTypes.string,
	phoneNumberValue: PropTypes.string,
	isMobileValue: PropTypes.bool,
	changeCountryCode: PropTypes.func,
	changePhoneNumber: PropTypes.func,
	onCountryCodeBlur: PropTypes.func,
	onCountryCodeFocus: PropTypes.func,
	isSignUp: PropTypes.bool
};

MobileNoCard.defaultProps = {
	cardTitle: 'Card Title',
	relationPhoneInformation: {},
	countryCodeValue: '',
	phoneNumberValue: '',
	isMobileValue: false,
	changeCountryCode: () => {},
	changePhoneNumber: () => {},
	onCountryCodeBlur: () => {},
	onCountryCodeFocus: () => {},
	isSignUp: false
};

export default MobileNoCard;