
const setProfileInformationToLocalStorage = (profileInformationData = {profileInformation:{}}) => {
    const {profileInformation} = profileInformationData;
  
    console.log('@@@@',profileInformation)
  
    let profileRootObject = {}
    let mailingAddress = {}
    let physicalAddress = {}
    let empInfoData = {}
    let finInfoData = {}
    let milInfoData = {}
  
    // Personal info 
  
    let personalInfoPrimaryObj = {
      citizenship:profileInformation?.citizenship != (undefined || null) ? profileInformation?.citizenship : '',
      countryOfCitizenship: profileInformation?.countryOfCitizenship  != (undefined || null) ? profileInformation?.countryOfCitizenship : '',
      countryOfCitizenshipCode: profileInformation?.citizenship != (undefined || null) ? profileInformation?.citizenship : '',
      dob: profileInformation?.role == 'Member' ? profileInformation?.m_dateOfBirth : "",
      firstName: profileInformation?.firstName != (undefined || null) ? profileInformation?.firstName : '',
      gender: profileInformation?.gender != (undefined || null) ? profileInformation?.gender : '',
      lastName: profileInformation?.lastName != (undefined || null) ? profileInformation?.lastName : '',
      maritalStatus: profileInformation?.maritalStatus != (undefined || null) ? profileInformation?.maritalStatus : '',
      maskedDob: profileInformation?.m_dateOfBirth != (undefined || null) ? profileInformation?.m_dateOfBirth : '',
      maskedSSN: profileInformation?.m_ssnTin != (undefined || null) ? profileInformation?.m_ssnTin : '',
      middleInitial: profileInformation?.middleInitial != (undefined || null) ? profileInformation?.middleInitial : '',
      prefix: profileInformation?.prefix != (undefined || null) ? profileInformation?.prefix : '',
      ssn: profileInformation?.m_ssnTin != (undefined || null) ? profileInformation?.m_ssnTin  : '',
      suffix: profileInformation?.suffix != (undefined || null) ? profileInformation?.suffix : '',
      taxIdIssuingCountry: ""
    }
  
    profileRootObject.personalInfo = personalInfoPrimaryObj
  
    // Address info
  
    physicalAddress = {
      addressLine1: profileInformation?.addressInformation[0]?.addressLine1 != (undefined || null) ? profileInformation?.addressInformation[0]?.addressLine1 : '',
      addressLine2: profileInformation?.addressInformation[0]?.addressLine2 != (undefined || null) ? profileInformation?.addressInformation[0]?.addressLine2 : '',
      city: profileInformation?.addressInformation[0]?.city != (undefined || null) ? profileInformation?.addressInformation[0]?.city : '',
      mailingAddressType: profileInformation?.addressInformation[0]?.mailTypeRadio != (undefined || null) ?  profileInformation?.addressInformation[0]?.mailTypeRadio : '',
      state:  profileInformation?.addressInformation[0]?.state != (undefined || null) ? profileInformation?.addressInformation[0]?.state : '',
      zipCode: profileInformation?.addressInformation[0]?.zip != (undefined || null) ? profileInformation?.addressInformation[0]?.zip : '',
      isMailingAddress:false,
      isPhysicalAddress: true
    }
  
    mailingAddress = {
      addressLine1: profileInformation?.addressInformation[1]?.addressLine1 != (undefined || null) ? profileInformation?.addressInformation[1]?.addressLine1 : '',
      addressLine2: profileInformation?.addressInformation[1]?.addressLine2 != (undefined || null) ? profileInformation?.addressInformation[1]?.addressLine2 : '',
      city: profileInformation?.addressInformation[1]?.city != (undefined || null) ? profileInformation?.addressInformation[1]?.city : '',
      mailingAddressType: profileInformation?.addressInformation[1]?.mailTypeRadio != (undefined || null) ? profileInformation?.addressInformation[1]?.mailTypeRadio : '',
      state:  profileInformation?.addressInformation[1]?.state != (undefined || null) ? profileInformation?.addressInformation[1]?.state : '',
      zipCode: profileInformation?.addressInformation[1]?.zip != (undefined || null) ?profileInformation?.addressInformation[1]?.zip  : '',
      isMailingAddress:true,
      isPhysicalAddress: false
    }
  
    let addressInfo = {
      physicalAddress :physicalAddress,
      mailingAddress : mailingAddress,
      isPhysicalAddressSame:"Yes"
    }
  
    profileRootObject.addressInfo = addressInfo
  
    // Contact info 
    const contactInfo = {
      emailAddresses: {
          maskedPrimaryEmailAddress: "",
          maskedSecondaryEmailAddress: "",
          // primaryEmailAddress: profileInformation?.emailInformation?.m_primaryEmail != (undefined || null) ? profileInformation?.emailInformation?.m_primaryEmail : '',
          // secondaryEmailAddress:profileInformation?.emailInformation?.m_secondaryEmail != (undefined || null) ? profileInformation?.emailInformation?.m_secondaryEmail : ''

          primaryEmailAddress: profileInformation?.emailInformation?.primaryEmail != (undefined || null) ? profileInformation?.emailInformation?.primaryEmail : '',
          secondaryEmailAddress:profileInformation?.emailInformation?.secondaryEmail != (undefined || null) ? profileInformation?.emailInformation?.secondaryEmail : ''
      },
      phoneInfo:{
          primary:{
              countryCode: profileInformation?.phoneInformation[0].phoneCountryCode != (undefined || null) ? profileInformation?.phoneInformation[0].phoneCountryCode : '',
              isMobile: profileInformation?.phoneInformation[0].isMobile != (undefined || null) ? profileInformation?.phoneInformation[0].isMobile : '',
              number: "",
              mPhoneNumber:profileInformation?.phoneInformation[0].m_phoneNumber != (undefined || null) ? profileInformation?.phoneInformation[0]?.m_phoneNumber : '',
          },
          secondary:{
              countryCode: profileInformation?.phoneInformation[1]?.phoneCountryCode != (undefined || null) ? profileInformation?.phoneInformation[1].phoneCountryCode : '',
              isMobile: profileInformation?.phoneInformation[1]?.isMobile != (undefined || null) ? profileInformation?.phoneInformation[1].isMobile  : '',
              number: "",
              mPhoneNumber:profileInformation?.phoneInformation[1]?.m_phoneNumber != (undefined || null) ? profileInformation?.phoneInformation[1]?.m_phoneNumber : '',
          }
      }
  
    }
  
    profileRootObject.contactInfo = contactInfo
  
    // Employment info 
    empInfoData = {
      address:{
          addressLine1: profileInformation?.employmentInformation?.addressLine1 != (undefined || null) ? profileInformation?.employmentInformation?.addressLine1 : '',
          addressLine2: profileInformation?.employmentInformation?.addressLine2 != (undefined || null) ? profileInformation?.employmentInformation?.addressLine2 : '',
          city: profileInformation?.employmentInformation?.employersCity != (undefined || null) ? profileInformation?.employmentInformation?.employersCity : '',
          state: profileInformation?.employmentInformation?.employersState != (undefined || null) ? profileInformation?.employmentInformation?.employersState : '',
          zipCode: profileInformation?.employmentInformation?.employersZipcode != (undefined || null) ? profileInformation?.employmentInformation?.employersZipcode : '',
        },
        employerName:profileInformation?.employmentInformation?.employerName != (undefined || null) ? profileInformation?.employmentInformation?.employerName : '',
        industry: profileInformation?.employmentInformation?.industry != (undefined || null) ? profileInformation?.employmentInformation?.industry : '',
        industryDescription: profileInformation?.employmentInformation?.industryDescription != (undefined || null) ? profileInformation?.employmentInformation?.industryDescription : '',
        occupation: profileInformation?.employmentInformation?.occupation != (undefined || null) ? profileInformation?.employmentInformation?.occupation : '',
        otherEmployment: profileInformation?.employmentInformation?.empStatusOther != (undefined || null) ? profileInformation?.employmentInformation?.empStatusOther : '',
        primarySource:profileInformation?.employmentInformation?.primarySourceOfIncome != (undefined || null) ? profileInformation?.employmentInformation?.primarySourceOfIncome : '',
        status: profileInformation?.employmentInformation?.employmentStatus != (undefined || null) ? profileInformation?.employmentInformation?.employmentStatus : ''
      }
  
    profileRootObject.employmentInfo = empInfoData
  
  
    finInfoData = {
      annualIncome: profileInformation?.financialInformation?.annualIncome != (undefined || null) ? profileInformation?.financialInformation?.annualIncome : '',
      netWorth: profileInformation?.financialInformation?.netWorth != (undefined || null) ? profileInformation?.financialInformation?.netWorth : '',
      taxBracket: profileInformation?.financialInformation?.taxBracket != (undefined || null) ? profileInformation?.financialInformation?.taxBracket : '',
      taxFilingStatus: profileInformation?.financialInformation?.taxFilingStatus != (undefined || null) ? profileInformation?.financialInformation?.taxFilingStatus : ''
    }
  
    profileRootObject.financialInfo = finInfoData
  
    // Military info 
    milInfoData = {
      branch: profileInformation?.militaryInformation?.branchOfService != (undefined || null) ? profileInformation?.militaryInformation?.branchOfService : '',
      commissionSource: profileInformation?.militaryInformation?.commissionCourse != (undefined || null) ? profileInformation?.militaryInformation?.commissionCourse : '',
      startDate: profileInformation?.militaryInformation?.fromDate != (undefined || null) ? profileInformation?.militaryInformation?.fromDate : '',
      endDate:  profileInformation?.militaryInformation?.toDate != (undefined || null) ? profileInformation?.militaryInformation?.toDate : '',
      hasServed: profileInformation?.militaryInformation?.currServingUSM == true ? "Y":"N",
      militaryStatus: profileInformation?.militaryInformation?.milityStatus != (undefined || null) ? profileInformation?.militaryInformation?.milityStatus : '',
      rank: profileInformation?.militaryInformation?.rank != (undefined || null) ? profileInformation?.militaryInformation?.rank : '',
      otherBranch:"",
      otherMilitaryStatus:""
    }
  
    profileRootObject.militaryInfo = milInfoData
  
    return profileRootObject
}

export {setProfileInformationToLocalStorage}