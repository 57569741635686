import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * This is a temporary file and will be removed after links are available for SIT.
 */
const CalculatorDiv = styled.div`
    width: 90%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 15px auto;
`;

const LinksDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const EachLink = styled(Link)`
    padding: 10px;
`;

const CalculatorH2 = styled.h2`
    padding-top: 30px;
`;

function CalculatorsComponent() {
    return (
        <div className="container">
            <CalculatorDiv>
                <CalculatorH2>Temporary Links for SIT</CalculatorH2>
                <div>
                    These links are added as dashboard is not ready. Will be removed once dashboard links are available
                    for SIT.
                </div>
                <LinksDiv>
                    <EachLink to="/openAccount">New Account Opening</EachLink>
                    <EachLink to="/calculators">Calculators</EachLink>
                    <EachLink to="/saveApplication">Saved Applications</EachLink>
                </LinksDiv>
            </CalculatorDiv>
        </div>
    );
}

export default CalculatorsComponent;
