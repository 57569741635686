/* Component Name : VCMCardDeckComponent
 * Author : Sharanya Bothumanchi
 * Modified Date: 13/11/2019
 * 
*/

import React, { Component } from "react";
import PropTypes from 'prop-types';
import styles from '../CommonStyles';
import '../../App.css'
import WIcon from '../WIcon/WIcon';


/** Component is created to display multiple cards as a deck
 * Componet has following properties
 * label - Display the label
 * cardDeck - Display card in a dec format
 * when the component is used, it requires following props
 * topLabelStyle - Css Style to display label
 * topLabelValue - Name of the label
 * items - card items to display
 * bottomLabelStyle - Css Style to display label
 * bottomLabelStyle - Name of the label
*/

// const VCMBasicInfoComponent = props => {
//   const {id, basiclabelstyle, labelvalue, linestyle} = props;
//   const assignObj = (obj) => { return obj; };
//   return (  
//   <div style={assignObj({marginBottom: '30px'})}>
//     <label style={assignObj({ ...styles.basicLabelStyle, ...basiclabelstyle })} htmlFor={id}>{labelvalue}</label>
//     <WButton {...props}/>
//     <hr style={assignObj({ ...styles.lineStyle, ...linestyle })}/>
//   </div>
// )}
const assignObj = (obj) => { return obj; }

class VCMBasicInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  manageBasicClick = () => {
    const { card, cardid } = this.props;
    const { history } = this.props;
    if (history && card !== undefined) {
      history.push(`/${cardid}`, card);
    } else {
      history.push(`/${cardid}`);
    }

  }

  render() {
    const { labelvalue, headerProp, cardid, linestyle, iconImage, buttontext, iconStyle, buttonstyle } = this.props;
    return (
      <div>
        {headerProp ?
          <div style={assignObj({ display: 'flex', justifyContent: 'space-between' })}>

            <h2 style={assignObj({ ...styles.basicLabelStyle, marginBottom: '25px' })}>{labelvalue}</h2>
            {iconImage ?
              <div style={assignObj({ display: 'flex', flexDirection: 'row' })}>

                <button style={assignObj({ ...styles.manageStyle, ...buttonstyle })} type="button" onClick={this.manageBasicClick}><div style={assignObj({ display: 'inline-block' })} ><WIcon src={iconImage} alt="" fontawesomestyle={iconStyle} /></div> {buttontext}</button>
              </div>
              : ''}
            {/* <WButton {...this.props} onClick={this.manageBasicClick} /> */}
          </div>
          : <div>
            <div style={assignObj({ display: 'flex', justifyContent: 'space-between' })}>

              <h2 style={assignObj({ ...styles.basicLabelStyle1, marginBottom: '10px' })} htmlFor={cardid}>{labelvalue}</h2>
              <div style={assignObj({ display: 'flex', alignItems: 'center' })}>

                <button id={cardid} style={assignObj({ ...styles.manageStyle, ...buttonstyle })} type="button" onClick={this.manageBasicClick}><div style={assignObj({ display: 'inline-block' })} ><WIcon src={iconImage} alt="" fontawesomestyle={iconStyle} /></div> {buttontext}</button>
              </div>
              {/* <WButton {...this.props} onClick={this.manageBasicClick} />  */}

            </div>
            <hr aria-hidden="true" style={assignObj({ ...styles.lineStyle1, ...linestyle })} />
          </div>
        }
      </div>

    )

  }
}

VCMBasicInfoComponent.propTypes = {
  linestyle: PropTypes.instanceOf(Object),
  labelvalue: PropTypes.string,
  card: PropTypes.instanceOf(Object),
  cardid: PropTypes.string,
  buttontext: PropTypes.string,
  iconImage: PropTypes.string,
  headerProp: PropTypes.bool,
  iconStyle: PropTypes.instanceOf(Object),
  buttonstyle: PropTypes.instanceOf(Object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

VCMBasicInfoComponent.defaultProps = {
  labelvalue: "Text",
  basiclabelstyle: {},
  linestyle: {},
  card: undefined,
  cardid: 'text',
  headerProp: true,
  buttontext: 'test',
  iconImage: '',
  iconStyle: {},
  buttonstyle: {},
  history: {}
}

export default VCMBasicInfoComponent;