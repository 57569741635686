/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/**
 * AccountInfoWrapper Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component is to display Interested Parties for each account
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion, Row, Card } from 'react-bootstrap';
import consts from './consts';
import ContractInfo from './ContractInfo';

const CardHeader = styled(Card.Header)`
    background: #ececec 0% 0% no-repeat padding-box;
    border: 1px solid #dcdcdc;
    opacity: 1;
    max-height: 80px;
`;

const CardBody = styled(Card.Body)`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dcdcdc;
    opacity: 0.94;

    .field-key {
        text-align: left;
        font: 700 16px/21px Roboto Slab;
        letter-spacing: 0;
        color: #56565a;
        opacity: 1;
    }
    .field-value {
        text-align: left;
        font: 500 30px/36px benton-sans;
        letter-spacing: 0;
        color: #56565a;
        opacity: 1;
    }
    .field-wrap {
        min-width: 150px;
    }
    .split {
        margin-right: 45px;
        margin-left: 45px;
    }
    .card-header {
        background: #fbfbfb 0% 0% no-repeat padding-box;
        border: 1px solid #5d83ae99;
        opacity: 1;
        max-height: 80px;
    }
`;

const Cardd = styled(Card)`
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
`;

const HeaderSection = styled(Row)`
    display: inline;
    justify-content: space-between;
    span {
        text-align: left;
        font: 600 16px/35px Roboto Slab;
        letter-spacing: 0;
        color: #54565b;
        opacity: 1;
    }
    button {
        padding-top: 7px;
        background: none;
        border: none;
        text-align: center;
        font: 600 16px/22px benton-sans;
        letter-spacing: 0;
        color: #5d83ae;
        opacity: 1;
    }
    .separator {
        margin-right: 15px;
        margin-left: 15px;
    }
    div {
        display: inline;
    }
`;

export default class AccountInfoWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleAddClick = this.handleAddClick.bind(this);
    }

    generateKey = () => {
        return Math.floor(Math.random() * (10000 - 100 + 1)) + 100;
    };

    handleAddClick = () => {
        const { handleClick } = this.props;
        handleClick();
    };

    render() {
        const { account } = this.props;
        return (
            <Accordion defaultActiveKey="0">
                <Cardd>
                    <CardHeader>
                        <Accordion.Toggle as="div" variant="link" eventKey="0" className="expand" style={{ cursor: 'pointer' }}>
                            <HeaderSection noGutters>
                                <Row noGutters>
                                    <div>
                                        <span>
                                            {consts.accountNameLabel} {consts.eifen} {account.accountName}
                                        </span>
                                        <span className="separator">{consts.separator}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {consts.accountNumberLabel} {consts.eifen} {account.accountNumber}
                                        </span>
                                    </div>
                                </Row>
                                <Row noGutters>
                                    <button onClick={this.handleAddClick} type="button">
                                        {consts.addLink}
                                    </button>
                                </Row>
                            </HeaderSection>
                        </Accordion.Toggle>
                    </CardHeader>
                    <Accordion.Collapse eventKey="0" id="cardBody">
                        <CardBody>
                            {account.contracts.map(contract => (
                                <ContractInfo contract={contract} />
                            ))}
                        </CardBody>
                    </Accordion.Collapse>
                </Cardd>
            </Accordion>
        );
    }
}

AccountInfoWrapper.propTypes = {
    editClick: PropTypes.func,
    headerFields: PropTypes.instanceOf(Object),
    content: PropTypes.instanceOf(Object),
};

AccountInfoWrapper.defaultProps = {
    editClick: () => {},
    headerFields: [],
    content: [],
};
