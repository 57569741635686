import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone'
import { isEmpty, isObject, isEqual } from 'lodash';
import constant from '../AccountDashboardConstants';
import SideHeader from '../SideHeaderMain/SideHeader';
import WCardOverlay from '../WCardOverlay/WCardOverlay';
import iconDocCenter from '../../../../assets/icon_document_center.png';
import MessageCenterList from '../MessageCenterList/MessageCenterList';


class DocumentCenter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const { searchDocuments, dashboardDocumentData } = this.props
        const { dashboardDocumentList } = dashboardDocumentData || {}
        const authToken = localStorage.getItem('token');
        if (isEmpty(dashboardDocumentList)) {
            const documentPayload = {
                searchPayload: {
                    contentType: 'cm:content',
                    vcmDocMetadata: {
                        vcmId: '',
                    },
                },
                authToken,
            };
            searchDocuments(documentPayload);
        } else if (!isEmpty(dashboardDocumentList)) {
            this.generateDocJson(dashboardDocumentList);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            const { dashboardDocumentData } = this.props
            const { dashboardDocumentList, isSuccess, isError, payload, documentReceived } = dashboardDocumentData || {};

            const { dashboardDocumentData: prevDashboardDocumentData } = prevProps
            const { dashboardDocumentList: prevDashboardDocumentList, documentReceived: prevDocumentReceived } = prevDashboardDocumentData || {};
            if (Object.keys(dashboardDocumentData).length &&
                isSuccess && !isEqual(dashboardDocumentList, prevDashboardDocumentList) && !isEmpty(dashboardDocumentList)) {
                this.generateDocJson(dashboardDocumentList)
            }
            if (Object.keys(dashboardDocumentData).length && isError && !isEqual(dashboardDocumentData, prevDashboardDocumentData)) {
                this.handleAPIError(payload)
            }
            if (Object.keys(dashboardDocumentData).length &&
                isSuccess && !isEqual(documentReceived, prevDocumentReceived)) {
                this.resetErrorMessage()
                this.convertBlobToFile(documentReceived, prevState.selectedFileName)
            }

        }
    }

    resetErrorMessage = () => {
        const { selectedFileName } = this.state
        const { dashboardDocumentData } = this.props
        const { formattedDocList: docJson } = dashboardDocumentData || {}
        const index = docJson && docJson.findIndex((item) => item.fileName === selectedFileName)
        if (index > -1) {
            const { setFormattedDocList } = this.props
            docJson[+index].errorMessage = ''
            setFormattedDocList(docJson)
        }
    }

    handleAPIError = (error) => {
        const { commonErrorMsg } = constant
        let errorMsg = error
        const { data } = error
        const { selectedFileName } = this.state
        const { dashboardDocumentData } = this.props
        const { formattedDocList: docJson } = dashboardDocumentData || {}
        const index = docJson && docJson.findIndex((item) => item.fileName === selectedFileName)
        if (error && data) {
            const { errorMessages, message } = data
            if (!isEmpty(errorMessages)) {
                const [firstMsg] = errorMessages
                errorMsg = firstMsg
            } else if (!isEmpty(message)) {
                errorMsg = message
            }
        }
        if (isObject(errorMsg)) {
            errorMsg = commonErrorMsg
        }
        if (index > -1) {
            const { setFormattedDocList } = this.props
            docJson[+index].errorMessage = errorMsg
            setFormattedDocList(docJson)
        } else if (isEmpty(docJson)) {
            this.setState({ searchErrormsg: errorMsg })
        }
    }




    convertBlobToFile = (data, selectedFileName) => {
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(data, selectedFileName);
        }
        // else {
        //     // In FF link must be added to DOM to be clicked
        //     const link = document.createElement('a')
        //     link.href = window.URL.createObjectURL(data)
        //     link.download = selectedFileName
        //     link.setAttribute('target', '_blank');
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }

        window.open(URL.createObjectURL(data));

        //   window.open((URL.createObjectURL(data)),selectedFileName);

        //  window.open(URL.createObjectURL(data)?selectedFileName);
        //   // const {selectedFileName}=this.state
        //     // console.log(data)
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(data)
        // console.log(link.href)
        // link.download = selectedFileName
        // link.setAttribute('target', '_blank');
        // document.body.appendChild(link);
        // link.click();
        //     link.remove();
        //     setTimeout(() => {
        //         window.URL.revokeObjectURL(data);
        //     }, 1500);


    }

    sortFunction = (a, b) => {
        const dateA = new Date(a.createdDate).getTime();
        const dateB = new Date(b.createdDate).getTime();
        return dateA > dateB ? -1 : 1;
    };


    generateDocJson = (data) => {
        let docJson = []
        const { setFormattedDocList, dashboardDocumentData } = this.props
        const { formattedDocList } = dashboardDocumentData || {}
        if (isEmpty(formattedDocList) && data && Object.keys(data).length) {
            const sortedData = data.sort(this.sortFunction)
            docJson = sortedData &&
                sortedData.filter((v, index) => (index < 4))
                    .map((v, index) => ({
                        id: index,
                        date: moment(new Date(v.createdDate)).format("MM/DD/YYYY"),
                        text: this.removeFileExtension(v.title || v.fileName || v.description),
                        atchmnt: Boolean(v.viewable),
                        nodeId: v.nodeId,
                        fileName: v.fileName,
                        documentSource: v.documentSource,
                        isRead: false,
                        docCategory: v.docCategory,
                        alternateDocumentId: v.alternateDocumentId,
                        alternateDocumentLink: v.alternateDocumentLink
                    }));
            setFormattedDocList(docJson)
        }
    }

    handleDocClick = (e) => {
        // Analytics Implementation
        const { analyzeClickEvent } = this.props
        const linkName = e.target.innerText || ''
        const screenName = localStorage.getItem("screenName")
        analyzeClickEvent({
            link: `Dashboard_${screenName}_DocumentCenter_${linkName}`
        })
        this.setState({ redirectToDocumentCenter: true })
    }

    handlePDFClick = (id) => (e) => {
        e.preventDefault()
        const { retrieveSingleDocument, analyzeDownLoadClickEvent, dashboardDocumentData } = this.props
        const { formattedDocList: docJson } = dashboardDocumentData || {}
        const { alternateDocumentId, alternateDocumentLink, fileName, nodeId, documentSource, docCategory } = docJson[+id]
        const isAlternateDocumentLink = Boolean(alternateDocumentLink);
        const isAlternateDocumentId = Boolean(alternateDocumentId);
        if (isAlternateDocumentLink) {
            window.open(alternateDocumentLink);
        } else {
            let productId = '';
            const source = documentSource.toLowerCase() || 'alfresco';
            const docId = alternateDocumentId || nodeId;
            const category = docCategory.toUpperCase();

            if (isAlternateDocumentId && source === 'broadridge') {
                productId = this.getProductId(category)
            }

            const payload = {
                token: localStorage.getItem('token'),
                nodeId: docId,
                productId,
                downloadUrl: alternateDocumentLink,
                documentSource: source
            }
            retrieveSingleDocument(payload)
        }
        // Analytics Implementation
        const screenName = localStorage.getItem("screenName")
        analyzeDownLoadClickEvent({
            link: `Dashboard_${screenName}_DocumentCenter_${fileName}(Download)`,
        })
        this.setState({ selectedFileName: fileName })
    }

    removeFileExtension = (fileNamePlusExtension = '') => {
        const regex = /\.[A-Za-z0-9]{3,4}\b/gi;
        return fileNamePlusExtension.replace(regex, '');
    };

    getProductId = (category) => {
        let prodId = ''
        if (category === 'STATEMENT') {
            prodId = 'STMT'
        } else if (category === 'CONFIRM') {
            prodId = 'CDLY'
        } else if (category === 'TAX') {
            prodId = 'YPCT'
        } else if (category === 'ACCOUNT_SERVICING' || category === 'ACCOUNT_MAINTENANCE') {
            prodId = 'LTRS'
        }
        return prodId;
    }

    render() {
        const { cardOverlayStyles, dashboardDocumentData } = this.props
        const { assignObj, documentcenter, noDocumentsText } = constant
        const { formattedDocList: docJson } = dashboardDocumentData || {}
        const { redirectToDocumentCenter = false, searchErrormsg = '' } = this.state
        const NoDocuments = isEmpty(docJson) ? noDocumentsText : ''
        return <><WCardOverlay
            myStyles={assignObj({ minHeight: '467px', maxHeight: '467px', ...cardOverlayStyles })}
        >
            <div>
                <SideHeader
                    title={documentcenter}
                    imgSrc={iconDocCenter}
                    width="16px"
                    height="22px"
                    //  alt="documentcenter"
                    id="documentCenter"
                    fontStyles={assignObj({ font: '800 21px/23px  yorkten-slab-normal' })}
                    headStyles={assignObj({ marginBottom: 0 })}
                />
                <div
                    // role="region" 
                    aria-labelledby="documentCenter" className="ach-document-center-main">
                    <div className="actualdocuments">
                        <div role="list" className="list-items">
                            {docJson && docJson
                                .map(item => {
                                    return (
                                        <MessageCenterList
                                            key={item.id}
                                            date={item.date}
                                            text={item.text}
                                            atch={item.atchmnt}
                                            handlePdfClick={this.handlePDFClick(item.id)}
                                            handleTextClick={this.handleDocClick}
                                            isRead={item.isRead}
                                            errorMsg={item.errorMessage}
                                        />
                                    );
                                })
                            }
                        </div>
                        {searchErrormsg !== '' ?
                            <div style={assignObj({
                                font: '500 16px/24px benton-sans,sans-serif',
                                color: 'red',
                                padding: '15px 0px 0px 15px',
                            })}>{searchErrormsg}</div> :
                            (isEmpty(docJson) && <div style={assignObj({ padding: '15px 0px 0px 15px', font: '500 16px/24px benton-sans,sans-serif', })}>{NoDocuments}</div>)
                        }
                    </div>
                </div>
            </div>
        </WCardOverlay>
            {redirectToDocumentCenter && <Redirect to='/documentCenter' />}
        </>
    }

}


DocumentCenter.propTypes = {
    cardOverlayStyles: PropTypes.instanceOf(Object),
    dashboardDocumentData: PropTypes.instanceOf(Object),
    searchDocuments: PropTypes.func,
    retrieveSingleDocument: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    analyzeDownLoadClickEvent: PropTypes.func,
    setFormattedDocList: PropTypes.func
};

DocumentCenter.defaultProps = {
    cardOverlayStyles: {},
    dashboardDocumentData: {},
    searchDocuments: () => { },
    retrieveSingleDocument: () => { },
    analyzeClickEvent: () => { },
    analyzeDownLoadClickEvent: () => { },
    setFormattedDocList: () => { }
};

export default DocumentCenter;
