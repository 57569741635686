import { useEffect } from "react";

const useAutoFocus=(initialState,ref)=>{


  useEffect(() => {  

  initialState==false && ref.current.focus() 

  },[initialState])
 

}

export default useAutoFocus