import { connect } from 'react-redux';
import get from 'lodash/get';
import UserAccountInformation from './UserAccountInformation';
import {
  validateEmailOTP,
  resetFlagsForListingPage,
  setEmailError,
} from './UserAccountInformation.action';

const mapStateToProps = (state) => ({
  userFields: get(state, 'AccountRegistration.user', {}),
  accountInfo: get(state, 'AccountRegistration.accountInfo', {}),
  receivedEmailOTP: get(state, 'AccountRegistration.receivedEmailOTP', false),
  passcode: get(state, 'AccountRegistration.OTP', ''),
  isEmailOTPValidated: get(
    state,
    'AccountRegistration.isEmailOTPValidated',
    false,
  ),
  emailOTPError: get(state, 'AccountRegistration.emailOTPError', ''),
  validateEmailErrorCode: get(
    state,
    'AccountRegistration.validateEmailErrorCode',
    '',
  ),
  updatedemail: get(state, 'AccountRegistration.updatedemail', ''),
});

const mapDispatchToProps = {
  validatePassCode: validateEmailOTP,
  resetFlagsForListingPage,
  setEmailFieldError: setEmailError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccountInformation);
