import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import // faAngleUp,
// faAngleDown,
'@fortawesome/free-solid-svg-icons';
// import riskHigh from '../../assets/riskhigh.PNG';
// import riskMedium from '../../assets/riskmedium.PNG';
import mediumRisk from '../../assets/mediumRisk.svg';
import highRisk from '../../assets/highRisk.svg';
import investIconUp from '../../assets/investIconUp.svg';
// import scoreDownIcon from '../../assets/scoreDownIcon.svg';
import scoreDownIcon from '../../assets/redArrowDown.png';
import riskLevel1 from '../../assets/Level1.png';
import riskLevel2 from '../../assets/Level2.png';
import riskLevel3 from '../../assets/Level3.png';
import riskLevel4 from '../../assets/Level4.png';
import riskLevel5 from '../../assets/Level5.png';
import riskLevel6 from '../../assets/Level6.png';
import riskLevel7 from '../../assets/Level7.png';
// import riskLow from '../../assets/risklow.PNG';
import styles from '../CommonStyles';
import WIcon from '../WIcon/WIcon';
import WSwitch from '../WSwitch/WSwitch';

const AccountCardFlex = styled.div`
    display: flex;
`;
const AccountCardTopSection = styled(AccountCardFlex)`
    justify-content: space-between;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
    opacity: 1;
`;
const AccountCardBottomColumn = styled(AccountCardFlex)`
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 300px;
    padding: 0 15px;
    border-left: 1px solid #d2d2d2;
    &:first-child {
        padding-left: 0;
        border: none;
    }
    &:last-child {
        padding-right: 0;
    }
`;

// font: 600 16px/19px benton-sans;
//   float: right;
const AccountCardHeadingSpan = styled.span`
    text-align: left;
    font: 600 16px/22px benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 8px;
    .riskValue {
        font: 500 14px/22px benton-sans;
        margin-left: 20px;
    }
`;

const AccountCardValueSpan = styled.span`
    text-align: left;
    font: 14px/17px benton-sans;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    display: flex;
    &.iconCol {
        padding-left: 34px;
        position: relative;
    }
`;

const StyledCard = styled(Card)`
    cursor: ${props => props.cursor};
    margin-bottom: 40px;
    outline: ${props => (props.selected ? 'none' : '1px solid #D2D2D2')};
    && {
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border: ${props => (props.selected ? '2px solid #8BC105' : '2px solid #fff')};
        border-radius: 0;
    }
    opacity: 1;
    &:hover {
        outline: ${props => (props.selected ? 'none' : '1px solid #3C5A77')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -2px;
        left: -2px;
    }
`;

const CardContainer = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-top: 1.875rem;
    border: ${props => (props.selected ? '1px solid #8BC105' : '1px solid #D2D2D2')};
    background: ${props =>
        props.selected ? '#fafafa 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'};
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    &:hover {
        outline: ${props => (props.selected ? 'none' : '1px solid #3c5a77')};
    }
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: ${props => (props.selected ? '35px solid #8BC105' : '35px solid #D2D2D2')};
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

const StyledFundName = styled.h3`
    font: 800 18px/45px yorkten-slab-normal;
    margin-bottom: 25px;
    line-height: 1;
    color: #004a98;
    cursor: pointer;
`;

const StyledCardRow = styled(AccountCardFlex)`
    position: relative;
    padding-top: 30px;
    &::after {
        content: '';
        width: calc(100% + 76px);
        height: 0;
        border-top: 1px solid #d2d2d2;
        position: absolute;
        top: 0;
        left: -38px;
    }
`;

const StyledCardBody = styled(Card.Body)`
    && {
        padding: 1.25rem 2.25rem;
    }
`;

const FundCard = props => {
    const {
        accountName,
        onClick,
        details,
        onSwitchClick,
        checked,
        selected,
        fundNumber,
        headerClick,
        onCardClick,
        isSwitch,
    } = props;
    const getImgSrc = risk => {
        let src = '';
        // switch (risk) {
        //     case 'Low':
        //         src = mediumRisk;
        //         break;
        //     case 'Medium':
        //         src = mediumRisk;
        //         break;
        //     case 'High':
        //         src = highRisk;
        //         break;
        //     // default:
        // }
        switch (risk) {
            case 'Low':
                src = mediumRisk;
                break;
            case 'Medium':
                src = mediumRisk;
                break;
            case 'High':
                src = highRisk;
                break;
            case 'Preservation of Capital':
                src = riskLevel1;
                break;
            case 'Conservative':
                src = riskLevel2;
                break;
            case 'Moderately Conservative':
                src = riskLevel3;
                break;
            case 'Moderate':
                src = riskLevel4;
                break;
            case 'Moderately Aggressive':
                src = riskLevel5;
                break;
            case 'Aggressive':
                src = riskLevel6;
                break;
            case 'Very Aggressive':
                src = riskLevel7;
                break;
            default:
                src = '';
        }
        return src;
    };

    const getAccountCardBottomColumn = item => {
        const increase = 'Increased By';
        const decrease = 'Decreased By';
        switch (item.key) {
            case 'Risk:':
                return (
                    <>
                        <AccountCardHeadingSpan>
                            {item.key}
                            <span className="riskValue">{item.value}</span>
                        </AccountCardHeadingSpan>
                        <AccountCardValueSpan>
                            {getImgSrc(item.value) !== '' ? <WIcon src={getImgSrc(item.value)} /> : 'N/A'}
                        </AccountCardValueSpan>
                    </>
                );
            case 'Change in NAV':
                return (
                    <>
                        <AccountCardHeadingSpan>{item.key}</AccountCardHeadingSpan>
                        <AccountCardValueSpan className="iconCol">
                            {item.value > 0 && (
                                <WIcon
                                    src={investIconUp}
                                    arialabel={increase}
                                    size="2x"
                                    fontawesomestyle={styles.tableIconStyle}
                                />
                            )}
                            {item.value <= 0 && (
                                <WIcon
                                    src={scoreDownIcon}
                                    arialabel={decrease}
                                    size="2x"
                                    fontawesomestyle={styles.tableIconStyleRed}
                                />
                            )}
                            {Math.abs(item.value).toFixed(2)}
                        </AccountCardValueSpan>
                    </>
                );
            default:
                return (
                    <>
                        <AccountCardHeadingSpan>{item.key}</AccountCardHeadingSpan>
                        <AccountCardValueSpan>{item.value}</AccountCardValueSpan>
                    </>
                );
        }
    };

    return (
        <>
            {!isSwitch ? (
                <StyledCard
                    tabIndex="0"
                    onClick={onCardClick}
                    selected={selected}
                    cursor={!headerClick ? 'pointer' : 'default'}
                >
                    <StyledCardBody>
                        <AccountCardTopSection>
                            <StyledFundName role="link" htmlFor={fundNumber.toString()} onClick={onClick}>
                                {accountName}
                            </StyledFundName>
                            <div>
                                <WSwitch id={fundNumber.toString()} onSwitchClick={onSwitchClick} checked={checked} />
                            </div>
                        </AccountCardTopSection>

                        <StyledCardRow>
                            {details.map((item, index) => {
                                return (
                                    <AccountCardBottomColumn key={index.toString()}>
                                        {getAccountCardBottomColumn(item)

                                        // item.key!=='Risk:' ?
                                        //     <><AccountCardHeadingSpan>{item.key}</AccountCardHeadingSpan>
                                        //         <AccountCardValueSpan>{item.value}</AccountCardValueSpan></>
                                        //     : <><AccountCardHeadingSpan>{item.key}<span className="riskValue">{item.value}</span></AccountCardHeadingSpan>
                                        //         <AccountCardValueSpan><WIcon src={getImgSrc(item.value)}/></AccountCardValueSpan></>
                                        }
                                    </AccountCardBottomColumn>
                                );
                            })}
                        </StyledCardRow>
                    </StyledCardBody>
                </StyledCard>
            ) : (
                <CardContainer onClick={onSwitchClick} selected={!!selected} checked={!!selected}>
                    <StyledCardBody>
                        <AccountCardTopSection>
                            <StyledFundName>{accountName}</StyledFundName>
                            {/* <div>
                        <WSwitch id={fundNumber.toString()} onSwitchClick={onSwitchClick} checked={checked}/>
                    </div> */}
                        </AccountCardTopSection>

                        <StyledCardRow>
                            {details.map((item, index) => {
                                return (
                                    <AccountCardBottomColumn key={index.toString()}>
                                        {getAccountCardBottomColumn(item)

                                        // item.key!=='Risk:' ?
                                        //     <><AccountCardHeadingSpan>{item.key}</AccountCardHeadingSpan>
                                        //         <AccountCardValueSpan>{item.value}</AccountCardValueSpan></>
                                        //     : <><AccountCardHeadingSpan>{item.key}<span className="riskValue">{item.value}</span></AccountCardHeadingSpan>
                                        //         <AccountCardValueSpan><WIcon src={getImgSrc(item.value)}/></AccountCardValueSpan></>
                                        }
                                    </AccountCardBottomColumn>
                                );
                            })}
                        </StyledCardRow>
                    </StyledCardBody>
                </CardContainer>
            )}
        </>
    );
};

FundCard.propTypes = {
    accountName: PropTypes.string,
    onClick: PropTypes.func,
    details: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    onSwitchClick: PropTypes.func,
    checked: PropTypes.bool,
    selected: PropTypes.bool,
    fundNumber: PropTypes.string,
    headerClick: PropTypes.bool,
    onCardClick: PropTypes.func,
    isSwitch: PropTypes.bool,
};
FundCard.defaultProps = {
    onClick: () => {},
    accountName: 'Account Name',
    details: [],
    onSwitchClick: () => {},
    checked: false,
    selected: false,
    fundNumber: '',
    headerClick: false,
    onCardClick: () => {},
    isSwitch: false,
};

export default FundCard;
