import React from 'react';
import { ReactComponent as ChatBubbleIcon } from '../../../assets/chat_bubble.svg';
import { ReactComponent as ContactUsEmailIcon } from '../../../assets/IKcon_Mail.svg';
import envConfig from 'env/EnvConfig';

const title = 'Title';
const dummyLink = 'Please reset password.';
const dummyText='This is sample content paragraph'
const general='General Investment Accounts'
const retirement='Retirement Accounts'
const minor='Investing for Minors'
const speciality='Specialty Accounts'
const etf='ETF Accounts'

const accountHolderCardData = {
    footerData: [
        { id: 'ic-modal', SvgComponent: <ChatBubbleIcon focusable="false"/>, message: 'Live Chat', link: '/accountholder', show:false },
        {
            id: 'secure-message',
            SvgComponent: <ContactUsEmailIcon focusable="false" />,
            message: 'Send us a Secure Message',
            link: '/messageCenter/createSecureMessages',
            show:true
        }
    ],
    defaultHighchartData : [
        { id: 0, holdingGroup: general, balance: '32600.00', c: '#00ADEE', r: 'rgba(0, 173, 238, 0.05)' ,open:false},
        { id: 1, holdingGroup: retirement, balance: '15000.00', c: '#7A2C7B', r: 'rgba(122, 44, 123, 0.05)',open:false },
        { id: 2, holdingGroup: minor, balance: '8910.00', c: '#0D823C', r: 'rgba(13, 130, 60, 0.05)',open:false },
        { id: 3, holdingGroup: speciality, balance: '8950.00', c: '#9D6915', r: 'rgba(157, 105, 21, 0.05)',open:false },
        { id: 4, holdingGroup: '529 Accounts', balance: '8960.30', c: '#56565A', r: 'rgba(86, 86, 90, 0.05)',open:false },
        { id: 5, holdingGroup: etf, balance: '89044.00', c: '#145A8F', r: 'rgba(20, 90, 143, 0.05)',open:false },
    ],
    defaultHighChartForBrokerageData :[
        { id: 1, holdingGroup: general, balance: '32600.00', c: '#cf7b2d', r: 'rgba(207, 123, 45, 0.05)', open: false },
        { id: 2,holdingGroup: retirement,balance: '15000.00',c: '#cccf2d',r: 'rgba(204, 207, 45, 0.05)',open: false},
        { id: 3, holdingGroup: speciality, balance: '8950.00', c: '#9D6915', r: 'rgba(157, 105, 21, 0.05)',open:false },
        { id: 4, holdingGroup: '529 Accounts', balance: '8960.30', c: '#56565A', r: 'rgba(86, 86, 90, 0.05)',open:false },
        { id: 5, holdingGroup: etf, balance: '89044.00', c: '#145A8F', r: 'rgba(20, 90, 143, 0.05)',open:false },
    ],
    defaultHighChartForChildrenData :[
        { id: 1, holdingGroup: general, balance: '32600.00', c: '#cf7b2d', r: 'rgba(207, 123, 45, 0.05)', open: false },
        {id: 2,holdingGroup: retirement,balance: '15000.00',c: '#cccf2d',r: 'rgba(204, 207, 45, 0.05)',open: false},
        { id: 3, holdingGroup: speciality, balance: '8950.00', c: '#9D6915', r: 'rgba(157, 105, 21, 0.05)',open:false },
        { id: 4, holdingGroup: '529 Accounts', balance: '8960.30', c: '#56565A', r: 'rgba(86, 86, 90, 0.05)',open:false },
        { id: 5, holdingGroup: etf, balance: '89044.00', c: '#145A8F', r: 'rgba(20, 90, 143, 0.05)',open:false },
    ],
    defaultHighChartForDynamicAdvisorData :[
        { id: 1, holdingGroup: general, balance: '32600.00', c: '#cf7b2d', r: 'rgba(207, 123, 45, 0.05)', open: false },
        {id: 2,holdingGroup: retirement,balance: '15000.00',c: '#cccf2d',r: 'rgba(204, 207, 45, 0.05)',open: false},
        { id: 3, holdingGroup: speciality, balance: '8950.00', c: '#9D6915', r: 'rgba(157, 105, 21, 0.05)',open:false },
        { id: 4, holdingGroup: '529 Accounts', balance: '8960.30', c: '#56565A', r: 'rgba(86, 86, 90, 0.05)',open:false },
        { id: 5, holdingGroup: etf, balance: '89044.00', c: '#145A8F', r: 'rgba(20, 90, 143, 0.05)',open:false },
    ],
    // defaultHighchartData : [
    //     { id: 0, holdingGroup: general, balance: '32600.00', c: '#00ADEE', r: 'rgba(0, 173, 238, 0.14)' ,open:false},
    //     { id: 1, holdingGroup: retirement, balance: '15000.00', c: '#7a2c7b', r: 'rgba(122, 44, 123, 0.13)',open:false },
    //     { id: 2, holdingGroup:minor, balance: '8900.00', c: '#80bd01', r: 'rgba(128, 189, 1, 0.05)',open:false },
    //     { id: 3, holdingGroup: speciality, balance: '8990.00', c: '#0000FF', r: 'rgb(0, 0, 255,0.5)',open:false },
    //     { id: 4, holdingGroup: '529 Accounts', balance: '6900.00', c: '#FFFF00', r: 'rgb(255, 255, 0,0.5)',open:false },
    //     { id: 5, holdingGroup: etf, balance: '8970.00', c: '#800000', r: 'rgb(128, 0, 0,0.5)',open:false },
    // ],
    NoPortofolioErrorMsg:'Portfolio Information cannot be Displayed',
    savedItems : {
        cardId: 1,
        cardHeading: 'My Saved Items',
        cardLinks: [
            {
                id: 0,
                linktext: 'Complete your Roth IRA Account application',
                link: '#',
                savedate: 'Saved Feb 28,2020, 3:40pm EST',
                attachment: false,
            },
            {
                id: 1,
                linktext: 'Recommendation from portfolio planner',
                link: '#',
                savedate: 'March 10,2020, 4:03pm EST',
                attachment: true,
            },
        ],
    },
    commonData: {
        mapData: [32600.0, 15000.0, 8900.0],
        amountTitle: 'Portfolio Value as of ',
        NewAmountTitle: 'Value as of ',
        pageName: 'My Portfolio',
        messagCenter: 'Message Center',
        mysaveditems: 'My Saved Items',
        documentcenter: 'Document Center',
        contactcenter: 'Contact Center',
        financialorder: 'Resources and Tools',
        placeanorder: 'Place a Trade',
       // placeanorder: 'Place an Order',
       // placeanordercontent: 'For changes to 529 Educational Savings Plans, select the account in your portfolio to perform actions.',
        placeanordercontent:"To add to your 529 account, or make any changes to your account, please select it from My Portfolio ",
        offers: 'Features',
        titleSub1: title,
        titleSub2: title,
        titleSub3: title,
        contactNum: title,
        breadCrumbValues: [
            { name: 'Home', url: '/' },
            { name: 'Account Dashboard', url: '/accountdashboard' },
        ],
        heading: 'First card heading',
        totalAmount: '$ 56,500.00',
        percentage: '100%',
        totalPortfolio: 'Total Portfolio',
        holdingGroup: 'Holding Group',
        mutualFund: 'Mutual Fund Accounts',
        brokerageAccounts: 'Marketplace Accounts',
        balanceUsd: 'Current Value ($US)',
        graphHd: ['General', 'Retirement', 'Educational'],
        bColors: [
            { c: '#00ADEE', r: 'rgba(0, 173, 238, 0.14)' },
            { c: '#7a2c7b', r: 'rgba(122, 44, 123, 0.13)' },
            { c: '#80bd01', r: 'rgba(128, 189, 1, 0.05)' },
        ],
        falseBool: false,
        marstoneText : "Marstone",
        powerText : "POWERED BY",
        TM: 'TM',
        docJson: [
            {
                id: 0,
                date: '01/05/2020',
                text: 'Your password is going to expire in 3 days, please reset your password.',
                link: dummyLink,
                atchmnt: false,
            },
            { id: 1, date: '02/05/2020', text: 'Your email address has been updated.', link: dummyLink, atchmnt: true },
            {
                id: 2,
                date: '03/05/2020',
                text: 'Your Quarterly Mutual Funds Statement for Account IRA.',
                link: dummyLink,
                atchmnt: false,
            },
            {
                id: 3,
                date: '04/05/2020',
                text: 'Your Security preferences have been updated',
                link: dummyLink,
                atchmnt: false,
            },
            { id: 4, date: '05/05/2020', text: dummyText, link: dummyLink, atchmnt: false },
            { id: 5, date: '06/05/2020', text: dummyText, link: dummyLink, atchmnt: true },
            { id: 6, date: '07/05/2020', text: dummyText, link: dummyLink, atchmnt: true },
            { id: 7, date: '08/05/2020', text: dummyText, link: dummyLink, atchmnt: false },
            { id: 8, date: '09/05/2020', text: dummyText, link: dummyLink, atchmnt: false },
            { id: 9, date: '10/05/2020', text: dummyText, link: dummyLink, atchmnt: true },
            { id: 10, date: '11/05/2020', text: dummyText, link: dummyLink, atchmnt: true },
        ],
    },
    resourcesTools: [
        {
            id: 1,
            link: `${envConfig.ENV_URL}/products`,
            linkText:'Learn About Our Products',
            Desc:'Choose from an array of products, including Mutual Funds, ETFs, Retirement Products, and a 529 savings plan. Which is right for you?',
        },
        {
            id: 2,
            link: `${envConfig.ENV_URL}/advice-tools/investor-learning-center`,
            linkText:'Achieve Your Goals',
            Desc:'Interested in learning more about investment basics or how to invest to achieve specific goals, such as saving for college or retirement? Learn more',
        },
    ],
    pageconsts: {
        headerLine: 'Portfolio Planner',
        portPlanner:
            'Building an investment portfolio to support your investment goals is as easy as answering a few simple questions. Get started now.',
    },
    features: [
        {
            id: 0,
            link: `${envConfig.ENV_URL}/products/mutual-funds`,
            linkText: 'USAA® Mutual Funds',
            Desc: 'Learn about the wide variety of USAA Mutual Funds that are available to help you meet your goals.',
        },
        {
            id: 1,
            link: `${envConfig.ENV_URL}/products/usaa-529-college-savings-plan`,
            linkText: 'USAA® 529 Education Savings Plan',
            Desc: 'Save for future education expenses by investing in a tax-advantaged USAA 529 Savings Plan.',
        },
        {
            id: 2,
            link:`${envConfig.ENV_URL}/products/retirement`,
            linkText: 'Planning for a Secure Retirement',
            Desc:
                'Victory Capital makes retirement planning simple and accessible, offering a full range of solutions.',
        },
    ],
    placeOrderBtns: [
        { id: 0, name: 'Buy', link: '/transactionPurchases' },
        { id: 1, name: 'Sell', link: '/liquidation' },
        { id: 2, name: 'Exchange', link: '/exchange' },
    ],
    dashboardOpenAnAccount:{
        name: 'Open a New Account', link: '/openAccount'
    },
    openBrokerageAccount:{
        name: 'Open a New Marketplace Account', link: '/marketOpenAccount'
    },
    assignObj: obj => obj,
    buy: 'Buy',
    sell: 'Sell',
    exchange: 'Exchange',
    viewDetails: 'View Details',
    holdingGroupDescription:"This table provides holding group details of the member",
    stopCodeSuccessResponse:
    [
                    {
                        "fundAccountNumber": "3000000145",
                        "stopCode": "9",
                        "warningDesc": "There is an issue with one of your accounts that may cause some activities to fail, please contact MSR for assistance."
                    },
                    {
                        "fundAccountNumber": "3000000146",
                        "stopCode": "9",
                        "warningDesc": "There is an issue with one of your accounts that may cause some activities to fail."
                    },
                    {
                        "fundAccountNumber": "3000000147",
                        "stopCode": "1",
                        "warningDesc": "There is an issue with all of your accounts that may cause some activities to fail."
                    }
    ],
    stopCodeEmptyResponse:[],
    dummyAccountDetails:{
            "totalAmount": "18648.26",
            "accounts": [
                {
                    "accountID": 10102447,
                    "accountName": "Individual-10102447",
                    "accountType": "Individual",
                    "balance": 109980,
                    "groupName": general,
                    "isAscensus": false,
                    "lastModifiedDate": "2020-05-13T11:37:32.000+0000",
                    "stopCode": "8",
                    "warningDesc": "There is an issue with one of your accounts that may cause some activities to fail, please contact MSR for assistance."
                },
                {
                    "accountName": "R_50555568523",
                    "accountID": 50555568523,
                    "accountType": "R",
                    "groupName":minor,
                    "lastModifiedDate": "2020-05-23T06:04:55.000+0000",
                    "isAscensus": true,
                    "stopCode": "0",
                    "warningDesc": "",
                    "balance": 4452.19
                },
                {
                    "accountName": "R_50555568530",
                    "accountID": 50555568530,
                    "accountType": "R",
                    "groupName": minor,
                    "lastModifiedDate": "2020-05-26T13:50:10.000+0000",
                    "isAscensus": true,
                    "stopCode": "0",
                    "warningDesc": "",
                    "balance": 4364.33
                }
            ]
        },
        REMOVE_SAVED_ITEM_MESSAGE: 'Are you sure you want to delete the saved application ?',
        REMOVE_SAVED_ITEM_HEDING: 'Delete Saved Application',
        sortArray:[
            general,
            retirement,
            minor,
            speciality,
            etf
        ],    
    transactionMessage : {
        buyMessage : "This account has been closed. You are unable to purchase. For more information regarding your account, please see your Account Activity or contact a Representative at 1-800-235-8396.",
        sellMessage : "This account has been closed. You are unable to sell. For more information regarding your account, please see your Account Activity or contact a Representative at 1-800-235-8396.",
        exchangeMessage : "This account has been closed. You are unable to exchange. For more information regarding your account, please see your Account Activity or contact a Representative at 1-800-235-8396."
    }

};
        

export default accountHolderCardData
