import { connect } from "react-redux";
import { localStateManagementActions,analytics, msrActions, loginActions } from "../../shared/Actions";
import Header from './Header';

const mapStateToProps = (state) => ({            
        localStateData: state.localStateManagementReducerData,
        msrFlag: state.msrFlowDetails.isMSRFlow,
        loginState: state.loginData,    
})

const mapDispatchToProps = {
    ...localStateManagementActions,...analytics, ...msrActions, ...loginActions
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);