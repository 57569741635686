import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import * as MasterRegistrationActionTypes from '../ReduxConstants/MasterRegistrationActionConstants';
import * as TransactionActionTypes from '../ReduxConstants/TransactionActionConstants';
import * as FundAccountActionTypes from '../ReduxConstants/FundAccountConstants';
import {addAPIVersionHeader, postCompositeApi, removeAPIVersionHeader, setFisTokenAuthHeaers, setMSRAuthHeaders} from './Services';
import Config from 'env/Config';
import {
    post,
    get,
    postApplicationJson,
    setAuthHeaders,
    getTransactionHistory,
    setMSRAuthSpecificHeaders,
} from './Services';
import { setSessionToPlaylod } from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';

const TransactionApiEvents = () => {
    ApiEventHub.on(ActionTypes.GET_ACCOUNT_DETAILS, data => {
        setSessionToPlaylod(data.payload);
        addAPIVersionHeader('2023-10-06')
        get(
            data.payload.configURLKey ? Config[data.payload.configURLKey] : Config.getaccountlist,
            undefined,
            data.endPointURL.getMsrAccountOptions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MasterRegistrationActionTypes.GET_MASTER_REGISTRATION_ACCOUNT_LIST, data => {
        // setAuthHeaders(data.payload.token);
        removeAPIVersionHeader()
        setSessionToPlaylod(data.payload);
        get(
            `${Config.getMasterRegAccounts}?companyNumber=${data.payload.companyNumber}`,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME, data => {
        // setAuthHeaders(data.payload.token);
        post(
            Config.updateAccountNickName,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.UPDATE_MASTER_REGISTRATION_ACCOUNT_NICK_NAME_FOR_MANAGE, data => {
        // setAuthHeaders(data.payload.token);
        post(
            Config.updateAccountNickNameForManage,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MasterRegistrationActionTypes.GET_MASTER_REG_ACCOUNT_LIST_LIQUIDATE, data => {
        // setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);
        removeAPIVersionHeader()

        get(
            `${Config.getMasterRegAccountsForLiquidate}?companyNumber=${data.payload.companyNumber}&transactionType=liquidation_transaction`,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(MasterRegistrationActionTypes.GET_EXCHANGE_MASTER_REGISTRATION_ACCOUNT_LIST, data => {
        // setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload)
        removeAPIVersionHeader()
        get(
            `${Config.getExchangeMasterRegAccounts}?companyNumber=${data.payload.companyNumber}`,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActionTypes.GET_LIQUIDATION_TRADING_LIMIT, data => {
        // setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);

        postApplicationJson(
            `${Config.getLiquidationTradingLimit}`,
            data.payload.tradingLimitPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActionTypes.GET_TRADING_CALENDAR, data => {
        // setAuthHeaders(data.payload.token);
        removeAPIVersionHeader()

        setSessionToPlaylod(data.payload);
        get(
            Config.getTradingCalendar,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_BANKACCOUNTS, data => {    
        setSessionToPlaylod(data.payload);
        removeAPIVersionHeader();
        get(
            Config.getbankaccounts,
            '',
            data.endPointURL.msrBankAccountList,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.ADD_BANK_ACCOUNT_NAO_TRANS, data => {
        post(
            Config.addBank,
            data.payload.addBankPayload,
            data.endPointURL.accountManagementEndPoints,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(FundAccountActionTypes.GET_FUND_ACCOUNTS_LIST, data => {
        setSessionToPlaylod(data.payload);
        removeAPIVersionHeader()
        post(
            Config.getFundAccountList,
            {
                companyNumber: data.payload.companyNumber,
                masterRegId: data.payload.masterRegId,
                accountType: data.payload.accountType,
                socialCode: data.payload.socialCode,
                accountTypeCode: data.payload.accountTypeCode,
            },
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );

        /* get(
            `${Config.getFundAccountList}?companyNumber=${data.payload.companyNumber}&masterRegId=${data.payload.masterRegId}&accountType=${data.payload.accountType}&socialCode=${data.payload.socialCode}&accountTypeCode=${data.payload.accountTypeCode}`,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        ); */
    });

    ApiEventHub.on(FundAccountActionTypes.GET_FUND_ACCOUNTS_LIST_FOR_NAO, data => {
        setAuthHeaders(data.payload.token);
        get(
            `${Config.getFundAccountsListForNAO}?companyNumber=${data.payload.companyNumber}&accountType=${data.payload.accountType}&state=${data.payload.state}&socialCode=${data.payload.socialCode}&accountTypeCode=${data.payload.accountTypeCode}`,
            undefined,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SUBMIT_PURCHASE_DATA, data => {
        setSessionToPlaylod(data.payload); 
        setFisTokenAuthHeaers();
        post(
            Config.purchasefunds,
            data.payload.purchasePayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActionTypes.SUBMIT_LIQUIDATION_DATA, data => {
        setSessionToPlaylod(data.payload);
        setFisTokenAuthHeaers();
        post(
            Config.liquidationtransaction,
            data.payload.liquidationPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SUBMIT_EXCHANGE_DATA, data => {
        //setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload); 
        setFisTokenAuthHeaers();
        post(
            Config.exchagetransaction,
            data.payload.submitPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.GET_PENDINGTRANSACTION_DATA, data => {
        setAuthHeaders(data.payload.token);
        post(
            Config.getpendingtransactionhistory,
            data.payload,
            data.endPointURL.MOCK,
            data.successCallBack,
            data.errorCallback,
        );
    });
    ApiEventHub.on(ActionTypes.CANCEL_DATA, data => {
        setAuthHeaders(data.payload.token);
        post(Config.cancelTransaction, data.payload, data.endPointURL.MOCK, data.successCallBack, data.errorCallback);
    });


    ApiEventHub.on(ActionTypes.GET_TRANSACTION_HOLDER_LIST, data => {   

        setSessionToPlaylod(data.payload)
        addAPIVersionHeader('2023-10-06')
        getTransactionHistory(
            `${Config.getTransactionHolder}`,
            data.payload,
            data.endPointURL.msrGetTransaction,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_TRANSACTION_HOLDER_LIST_FOR_MANAGE, data => {
        const params = getSessionValues();
        const apiKey = Config.msrTransactionApiKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
      
        post(
            `${Config.getTransactionHolderForManage}`,
            data.payload,
            data.endPointURL.msrBrokerageTransactions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_TRANSACTION_COMPOSITE_DATA, data => {

        const payLoad = {
            msrid:"",
            vcmid:"",
            fismemberid:"",
            token:""
        }
        setSessionToPlaylod(payLoad)
        postCompositeApi(
            `${Config.getCompositeData}/${data.payload.datakey}`,
            '',
            data.endPointURL.getCompositeData,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_IRA_CONTRIBUTION_VALUES, data => {
        //setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);
        const isVersion2ApiUrl = data.payload?.isVersion2ApiUrl != undefined ? true : false
        postApplicationJson(
            `${isVersion2ApiUrl ? Config.getContributionForMarketIRA : Config.getContributionForIRA}`,
            data.payload.IRAContributionPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    // Gaurav (Hyniva) Added 10-01-2022
    ApiEventHub.on(ActionTypes.GET_IRA_CONTRIBUTION_YEAR_VALUES, data => {
        setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);
        post(
            Config.getIRAContributionYearValues,
            data.payload,
            data.endPointURL.getIRAContributionYearValues,
            data.successCallBack,
            data.errorCallback,
        );
    });
    //  Gaurav (Hyniva) Added 10-01-2022 End

    ApiEventHub.on(TransactionActionTypes.GET_STATE_CODE_GROUP, data => {
        const xApikey = Config.msrAccountOptionKey;
        const { token, vcmid, fismemberid = '' } = data.payload;
        const { masterReg: masterRegId, companyNumber } = data.payload;
        const cnum = companyNumber.toString();
        setMSRAuthSpecificHeaders(token, vcmid, xApikey, fismemberid);
        setSessionToPlaylod(data.payload);
        removeAPIVersionHeader()
        post(
            `${Config.getStateCodeGroup}`,
            { masterRegId, companyNumber: cnum },
            data.endPointURL.msrTransactions,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(TransactionActionTypes.GET_STATE_TAXES_FOR_GROUP, data => {
        setSessionToPlaylod(data.payload)
        post(
            Config.getStateTaxesForGroup,
            data.payload.stateTaxPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.VALIDATE_ACH_LIMIT, data => {
        //setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);
        post(
            Config.validateACHLimit,
            data.payload.validateACHPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(FundAccountActionTypes.GET_EXISTING_FUND_ACCOUNTS_LIST, data => {
        
        setSessionToPlaylod(data.payload);
        //setAuthHeaders(data.payload.token);
        removeAPIVersionHeader()
        post(
            Config.getExistingFundAccountList,
            {
                companyNumber: data.payload.companyNumber,
                masterRegId: data.payload.masterRegId,
                accountType: data.payload.accountType,
                socialCode: data.payload.socialCode,
                accountTypeCode: data.payload.accountTypeCode,
            },
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(FundAccountActionTypes.GET_EXCHANGE_FUND_ACCOUNTS_LIST, data => {
      
        const params = getSessionValues();
        const apiKey = Config.msrTransactionKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
        removeAPIVersionHeader()
        // get(
        //     `${Config.getExchangeFundAccountList}?companyNumber=${data.payload.companyNumber}&masterRegId=${data.payload.masterRegId}&accountType=${data.payload.accountType}&socialCode=${data.payload.socialCode}&accountTypeCode=${data.payload.accountTypeCode}`,
        //     undefined,
        //     data.endPointURL.TRANSACTION,
        //     data.successCallBack,
        //     data.errorCallback,
        // );
        post(
            Config.getExchangeFundAccountList,
            data.payload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );


    });

    ApiEventHub.on(FundAccountActionTypes.GET_EXCHANGE_IN_FUND_ACCOUNTS_LIST, data => {
        //setAuthHeaders(data.payload.token);
        setSessionToPlaylod(data.payload);
        removeAPIVersionHeader();
        post(
            Config.getExchangeInFundAccountList,
            data.payload.fundListPayload,
            data.endPointURL.TRANSACTION,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_ACCOUNT_SERVICE_OPTIONS, data => {
        const params = getSessionValues();
        const apiKey = Config.msrTransactionApiKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
        removeAPIVersionHeader();
        post(
            `${Config.getAccountServiceOptions}`,
            data.payload,
            data.endPointURL.DASH_BOARD,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_BROKEREAGE_BANK_ACCOUNTS_LIST, data => {
        const params = getSessionValues();
        const apiKey = Config.msrTransactionApiKey;
        setMSRAuthHeaders(params.accesstoken, params.vcmid, params.msrid, apiKey, params.fismemberid);
        console.log('config',Config.getBrokereageBankaccounts)
        post(
            Config.getBrokereageBankaccounts,
            data.payload,
            data.endPointURL.DASH_BOARD,
            data.successCallBack,
            data.errorCallback,
        );
    });

};

export default TransactionApiEvents;
