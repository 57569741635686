import { connect } from 'react-redux';
import PersonalInfoCardComponent from './PersonalInfoCardComponent';
import { accOpeningActions, localStateManagementActions } from '../../../shared/Actions';

export const mapStateToProps = (state /* , props */) => ({
    accOpeningData: state.accOpeningReducerData,
    initialState: state.loginData,
    masterLookupStateData: state.masterLookUpData,
    localStateData: state.localStateManagementReducerData,
});

const mapDispatchToProps = {
    ...accOpeningActions,
    ...localStateManagementActions,
    
    
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PersonalInfoCardComponent);
