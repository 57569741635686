import { SessionStorageHelper } from "utils/sessionHelper";

export const setDataInSessionStorage = (search,vcmid,msrid,applicationid,landingpage) =>
{
    if(!SessionStorageHelper.IsItemExists('retriveCount'))
    {
        SessionStorageHelper.setItem('retriveCount','0')
    }
    
    console.log('vcm ID local',vcmid)
    if (search && search.includes('vcmid')) {
        
        console.log('search containing vcmId')
        sessionStorage.setItem('vcmid', vcmid);
        sessionStorage.setItem('msrid', msrid);
        sessionStorage.setItem('applicationid', applicationid);
        sessionStorage.setItem('landingpage', landingpage);
    }else{
        console.log('search vcmId MISSING')
    }
}


export const setErrorStatus = (decodeToken) =>
{
    let showError = false;
    Object.keys(decodeToken).forEach(item => {
        if(item === 'err_code'){
            showError=true
        }
    })

    return showError;
}


export const handleRetriveCount = (response,retriveCount) =>
{
    if(response?.status == 400)
    {
        if(parseInt(SessionStorageHelper.getItem('retriveCount')))
        {
            retriveCount = parseInt(SessionStorageHelper.getItem('retriveCount'));
            console.log('retriveCount @@ then',retriveCount)
        }
        
        if (retriveCount <= 2) {
            retriveCount += 1;
            SessionStorageHelper.setItem('retriveCount',retriveCount)

        }
        else
        {
            sessionStorage.setItem("isRefresh",false)
        }
    }
    
    return retriveCount;
}