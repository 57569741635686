/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the preferences card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generatePreferencesCard(masterLookupStateData, data) {
    let accountPreferencesCard = {};
    if (data && data.accountPreferences) {
        let prefData = '';
        let prosData = '';
        if (data.accountPreferences.dividendCapitalGain) {
            const prefList = masterLookupStateData['div_capGain_pref'.toString()].value;
            prefData = prefList.find(item => item.key === data.accountPreferences.dividendCapitalGain).value;
        }
        if (data.accountPreferences.documentDeliveryFormat) {
            const prosList = masterLookupStateData['stmt_pros_rep'.toString()].value;
            prosData = prosList.find(item => item.key === data.accountPreferences.documentDeliveryFormat).value;
        }
        accountPreferencesCard = Consts.accountPreferencesCard;
        accountPreferencesCard.fields[0].value = prefData;
        accountPreferencesCard.fields[1].value = prosData;
    }
    return accountPreferencesCard;
}
