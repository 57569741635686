import React, { Component } from "react";
import styled from 'styled-components';
import {Row, Col, Container } from 'react-bootstrap';
import PropTypes from "prop-types";
import {
    WBreadCrumb,
    WSpinner,
    PageHeading
} from "common";
import {
    formatPhoneNumber,
} from "utils";
import routeConstants from 'routeConstants';
import plusIcon from "assets/plusIcon.png";
import deleteIcon from 'assets/deleteTrash.svg';
import editIcon from 'assets/EditPencil.svg';
import { BottomNavBar, TitleWithAction, CardWithMenu } from 'modules/CustomerManagementModule/components';
import CONST from "modules/CustomerManagementModule/PhoneInformation/constants";

const FlexDiv = styled(Row)`
    margin-top: ${props => props['data-margintop']}rem;
    margin-Bottom: ${props => props['data-marginbottom']}rem;
`;

const StyledLine1 = styled.p`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
    margin: 0;
`;

const primaryMenuOptions = [{name:"Edit", icon:editIcon, menuOptName:"Edit the primary phone number"}];
const secondaryMenuOptions = [{name:"Edit", icon:editIcon, menuOptName:"Edit the secondary phone number"},{name:"Delete", icon:deleteIcon, menuOptName:"Delete the secondary phone number"}];

const backBtnProps = {};

export default class PhoneInfoComponent extends Component {

    componentDidMount() {
        const { getCompositeLookUpData, masterLookupStateData } = this.props;
        this.clearFlags();
        const { contact_time: contactTime, phone_type: phoneType } = masterLookupStateData;
        const payLoad = [];
        if(!contactTime) payLoad.push('contact_time');
        if(!phoneType) payLoad.push('phone_type')
        getCompositeLookUpData(payLoad);
    }
    
    componentDidUpdate(){
        this.clearFlags();
    }
    
    clearFlags = ()=> {
        const { profileInformationData, clearExistingActions } = this.props;
        const { isError, isSuccess } = profileInformationData;
        if(isSuccess || isError){
            clearExistingActions();
        }
    }

    addPhoneNum = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managephoneinfo_addphonenumber`
        })
        history.push(routeConstants.addPhoneNum);
    };

    cancel = () => {
        const { history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_managephoneinfo_back`
        })
        history.push(routeConstants.basicinformation);
    };

    deleteCard = () => (e) => {
        e.stopPropagation();
        const { deleteProfilePhoneInformations } = this.props;
        const payLoad = {
                phoneUpdate: [{
                    phoneNumber: "",
                    phoneCountryCode: "",
                    phoneType: "",
                    isPrimary: false,
                }],
            }
        deleteProfilePhoneInformations(payLoad);
    };

    editPhone = (phoneObj) => (e) => {
        e.stopPropagation();
        const { history } = this.props;
        history.push(routeConstants.editPhone, { phoneObj });
    };
    
    onMenuOptionClick = (phoneObj) => (menuOption) => (e) => {
        const { analyzeClickEvent } = this.props;
        if(menuOption === 'Edit'){
            analyzeClickEvent({
                link: `cms_basicinformation_managephoneinfo_editbutton`
            });
            this.editPhone(phoneObj)(e);
        }else if(menuOption === 'Delete') {
            analyzeClickEvent({
                link: `cms_basicinformation_managephoneinfo_deletebutton`
            });
            this.deleteCard()(e);
        }
    }

    render() {
        const { profileInformationData } = this.props;
        const { isLoading: loading, profileInformation } = profileInformationData;
        const { phoneInformation = [] } = profileInformation || {};
        const hideAdd = phoneInformation.length === 2;

        backBtnProps.onClick = this.cancel;
        backBtnProps['aria-label'] = "Back to basic information";

        return (
            <>
                {loading && <WSpinner loading={loading} />}
                <Container>
                    <WBreadCrumb
                        breadCrumbItems={CONST.BREADCRUM_ITEMS}
                        activeCrumb={CONST.PAGE_NAME}
                    />
                    <TitleWithAction
                       title={<PageHeading>{CONST.PAGE_NAME}</PageHeading>}
                       ariaLabel={CONST.ACTION_LABEL_PHONE_ADD}
                       onActionClick={this.addPhoneNum}
                       actionText={CONST.ACTION_TEXT_PHONE_ADD}
                       actionIcon={plusIcon}
                       hideAction={hideAdd}
                    />
                    <FlexDiv data-margintop={2.5} data-marginbottom={7} >
                        {phoneInformation.map((item, index)=>{
                        const NumberType = item.isMobile ? CONST.MOBILE : CONST.NON_MOBILE;
                            return (
                                <Col md={6} lg={4} key={index.toString()}>
                                    <CardWithMenu 
                                        key={index.toString()}
                                        cardTitle = { item.isPrimary ? CONST.PRIMARY_PHONE_NUMBER : CONST.SECONDARY_PHONE_NUMBER}
                                        Line1 ={formatPhoneNumber(item.phoneNumber, true, item.phoneCountryCode)}
                                        extradetail= {<StyledLine1>{NumberType}</StyledLine1>}
                                        menu
                                        menuOptions={item.isPrimary ? primaryMenuOptions : secondaryMenuOptions}
                                        onMenuOptionClick={this.onMenuOptionClick(item)}
                                    />
                                </Col>
                                )
                        })}
                    </FlexDiv>
                </Container>
                <BottomNavBar
                    hidePrimaryBtn
                    backBtnProps={backBtnProps}
                />
            </>
        );
    }
}

PhoneInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    getCompositeLookUpData: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    deleteProfilePhoneInformations: PropTypes.func,
    clearExistingActions: PropTypes.func,
    analyzeClickEvent: PropTypes.func
};

PhoneInfoComponent.defaultProps = {
    getCompositeLookUpData: () => {},
    masterLookupStateData: {},
    profileInformationData: {},
    deleteProfilePhoneInformations: ()=>{},
    clearExistingActions: ()=>{},
    analyzeClickEvent: ()=>{}
};
