/* eslint-disable class-methods-use-this */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import consts from '../../components/constants';
import Radio from '../../components/inputs/RadioInput';
import WStepper from '../../components/WStepper';
import analytics from '../../components/utils.analytics';
import './OneTimeCodeListingPage.css';
import { getMaskedPhoneFormat, getOTPErrorMsg } from '../../Utils';

const { pages } = consts;
const assignObj = (obj) => obj;

class OTCDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      preference: '',
      label: 'Select Options',
      phoneNumber: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.requestPasscode = this.requestPasscode.bind(this);
  }

  componentDidMount() {
    const {
      history,
      accountInfo,
      resetEmailOTP,
      user,
      resetFlg,
      ivrStatus,
    } = this.props;
    
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
      // return;
    } 
    const { primaryPhoneCountryCode } = user;
    if (!isEmpty(primaryPhoneCountryCode) && primaryPhoneCountryCode !== '1') {
      this.setState({ preference: 'IVR' });
    }
    resetEmailOTP();
    if (!isEmpty(ivrStatus)) {
      resetFlg();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      history, resetFlg, OTPSuccess, OTCMsg, ivrStatus,
    } = nextProps;
    const { phoneNumber, preference } = prevState;
    if (OTPSuccess === 'success') {
      resetFlg();
      window.scriptLoaded = false;
      history.push({
        pathname: '/accountRegistration/onetimecodeverify',
        state: {
          phoneNumber,
          requestType: preference,
        },
      });
    } else if (OTPSuccess === 'failed') {
      return {
        error: getOTPErrorMsg(OTCMsg),
      };
    }

    if (ivrStatus === 'success') {
      resetFlg();
      window.scriptLoaded = false;
      history.push({ pathname: '/accountRegistration/enterUserId' });
    } else if (ivrStatus === 'failed') {
      return {
        error: 'One Time Passcode validation failed, please try again.',
      };
    }
    if (ivrStatus === 'rejected') {
      resetFlg();
      window.scriptLoaded = false;
      history.push('/accountRegistration/identityCheckFail');
    }
    if (isEmpty(OTPSuccess) && isEmpty(ivrStatus)) {
      return {
        error: '',
      };
    }
    return prevState;
  }

  handleChange(e) {
    const { name, id } = e.target;
    const { OTPSuccess, resetFlg } = this.props;
    if (id === 'OTP') {
      this.setState({ label: 'Send text to my phone' });
    } else {
      this.setState({ label: 'Call me with the code' });
    }
    if (!isEmpty(OTPSuccess)) {
      resetFlg();
    } // console.log(name, id);
    this.setState({ [name]: id });
  }

  handleButtonClick() {
    analytics.triggerClickTrackEvent(
      'Preregister_generatepasscode_Continue_To_Verify',
    );
  }

  requestPasscode() {
    const { user, accountInfo, getVerifyPasscode } = this.props;
    const { preference } = this.state;
    const { primaryPhoneCountryCode, primaryPhone } = user;

    const countryCode = isEmpty(primaryPhoneCountryCode)
      ? '1'
      : primaryPhoneCountryCode;

    const phoneNum = `${countryCode}${primaryPhone}`;
    this.setState({
      phoneNumber: `${phoneNum.replace(/[^A-Z0-9]/gi, '')}`,
    });
    getVerifyPasscode({
      ...accountInfo,
      phoneNumber:
        accountInfo.verfiy === 'true'
          ? accountInfo.phoneNumber
          : `${phoneNum.replace(/[^A-Z0-9]/gi, '')}`,
      requestType: preference,
    });
  }

  render() {
    const { error, preference, label } = this.state;
    const { user } = this.props;
    const { primaryPhone = '', primaryPhoneCountryCode = '1' } = user;
    if (!isEmpty(error)) {
      const appErrorStr = error.replace(/ /g, '_');
      analytics.triggerErrorEvent(
        `preregister_generatepasscode_error_${appErrorStr}`,
      );
    }

    const infoMsg = `You have identified ${getMaskedPhoneFormat(
      primaryPhone,
      primaryPhoneCountryCode,
    )} to receive a One Time Passcode.  This step is a feature that adds an extra layer of security by delivering a code that you can use to verify your account.  If this phone number cannot receive a text message, please use the Voice option to receive an automated phone call`;


    return (
      <div className="milestone">
        <div className="pad contentWrapper">
          <Container fluid>
            <WStepper currentPage={2} pages={pages} />
            <Row>
              <Col
                className="descWrapper otpDescWrapper flexColBackOpt"
                xs={12}
                sm={7}
              >
                <div>
                  <h1 className="descWrapperHeading">
                    {consts.passcodeVerify}
                  </h1>
                  <p className="descWrapperParad">
                    {infoMsg}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={5} className="RHSWrapper">
                <Container style={assignObj({ margin: 0 })}>
                  <Row>
                    <Col className="noPad">
                      <Form
                        onSubmit={assignObj((e) => {
                          e.preventDefault();
                          this.requestPasscode();
                        })}
                      >
                        <div className="rowWrapper">
                          <span className="otp inputLabel" aria-hidden="true">
                            {consts.preference}
                          </span>
                          <Form.Row
                            style={assignObj({ marginLeft: 0 })}
                            role="radiogroup"
                            aria-labelledby="deliveryPrefLabel"
                          >
                            <span className="sr-only" id="deliveryPrefLabel">
                              {consts.preference}
                            </span>
                            <Radio
                              value="Text"
                              id="OTP"
                              radioFor="preference"
                              selected={preference === 'OTP'}
                              dataDisabled={
                                !isEmpty(primaryPhoneCountryCode)
                                && primaryPhoneCountryCode !== '1'
                              }
                              onClick={this.handleChange}
                            />
                            <Radio
                              value="Voice"
                              id="IVR"
                              radioFor="preference"
                              selected={preference === 'IVR'}
                              onClick={this.handleChange}
                            />
                          </Form.Row>

                          {error && (
                            <p
                              className="errorText"
                              aria-live="polite"
                              aria-atomic="true"
                              aria-relevant="additions"
                              id="phoneTypeError"
                            >
                              {error}
                            </p>
                          )}
                        </div>
                        <div className="btnWrapper">
                          <button
                            className="vcmbtn"
                            type="submit"
                            onClick={this.handleButtonClick}
                            disabled={
                              !(preference !== undefined && preference.length)
                            }
                          >
                            <span className="vcmBtnText">{label}</span>
                          </button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

OTCDetails.propTypes = {
  user: PropTypes.shape({
    primaryPhone: PropTypes.string,
    primaryPhoneCountryCode: PropTypes.string,
  }),
  accountInfo: PropTypes.instanceOf(Object),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  getVerifyPasscode: PropTypes.func,
  OTPSuccess: PropTypes.string,
  OTCMsg: PropTypes.string,
  resetFlg: PropTypes.func,
  resetEmailOTP: PropTypes.func,
  ivrStatus: PropTypes.string,
};

OTCDetails.defaultProps = {
  user: {
    primaryPhone: '',
    primaryPhoneCountryCode: '',
  },
  accountInfo: {},
  history: {},
  getVerifyPasscode: () => {},
  OTPSuccess: '',
  OTCMsg: '',
  resetFlg: () => {},
  resetEmailOTP: () => {},
  ivrStatus: '',
};

export default OTCDetails;
