/**
 * SetupSecurityQuestions
 ********************************
 * @version 1.0.1
 * @author Venu Sugguna
 * @description This page let's the user confirm the answers and preferences given in Security qusetions setup page. 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import LoadingOverlay from 'react-loading-overlay';
// import routeConstants from 'routeConstants';

import '../Screens.css';
import ConfirmSecurityQuestionsForm from '../../ConfirmSecurityQuestions';
import SideHeader from '../../SideHeader/SideHeader';
import { WStepper, CommonButtons, WSpinner } from '../../../../common';
import data from './constants';
import styles from '../../UserManagementStyles'; 

let formattedData = [];
let userId = "User Id";
// const assignObj = obj => obj;
const {stepperColStyle} = styles.stepperStyle;

class ConfirmSecurityQuestions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      logOutRoute: {
        pathname: '/sign-in',
        // message: { message: 'You have been successfully logged out' },
      },
      redirect: false
    }
    this.onNext = this.onNext.bind(this);

    this.onBack = this.onBack.bind(this);
  }
  
  componentDidMount(){
    const { setJWTToken, getCustomerProfile, customerProfile } = this.props;
    const token = localStorage.getItem('token');
    userId = localStorage.getItem("userId")
    if(token){
        setJWTToken(token);
        if(!customerProfile){
            getCustomerProfile({})
        }
    } else {
      this.handleLogOut();
    }
  }
  
  static getDerivedStateFromProps(nextProps, state) {
  const { loginState, history, setDocumentPreference, getCustomerProfile, manageLocalState, customerProfile } = nextProps;
  let updatedState = { ...state };
  // console.log(updatedStateForm);

  if (loginState && loginState.documentPreference && !loginState.isLoading) {
    const { firstName, lastName } = customerProfile;
    const userName = `${firstName  } ${  lastName}`;
      // console.log(loginState);
      if (loginState.documentPreference.errorCode === 0) {
        history.push({
          pathname: '/Profile',
          message: {
            message: <span>{data.welcome}<strong className="strongMessage">{userName}</strong>{data.signedAs}<strong className="strongMessage">{userId}</strong></span>,
            info: data.setup
          }
        });
          setDocumentPreference('');
      } else {
          updatedState = { ...state, error: loginState.documentPreference.result };
      }
  }
  if (loginState.getCustomerProfile) {
      const customerData = { customerProfile: { ...loginState.getCustomerProfile } };
      getCustomerProfile('');
      manageLocalState(customerData);
  }
  return updatedState;
}

  onBack() {
    const { history } = this.props;
    const path = 'delivery-preference';
    history.push(path);
  }

  onNext() {
    const { preferenceData, setDocumentPreference, savedData } = this.props
    const {form} = savedData;
    const payload = {
        // "onlineId": "vcm123456",
        securityQuestions: [
            {
                [form.question1.value]: formattedData[0].desc,
                [form.question2.value]: formattedData[1].desc,
                [form.question3.value]: formattedData[2].desc,
            },
        ],
        // "secEmail": "testtest@gmail.com",
        dPref: preferenceData.online ? 'electronic' : 'paper',
        // "primaryEmail": "testtest1233@gmail.com"
    };
    setDocumentPreference(payload);
  }

  handleLogOut() {
    const token = localStorage.getItem('token');
    if (token) {
        Auth.signOut()
            .then(() => {
                // console.log(data);
                localStorage.clear();
                this.setState({
                    redirect: true,
                    logOutRoute: {
                        pathname: '/sign-in',
                        // message: { message: '' },
                    },
                });
            })
            .catch(() => {
                // console.log(err)
            });
        // else {
        //     this.setState({redirect: false})
        // }
    } else {
        // console.log('no token');
        this.setState({
            redirect: true,
            pathname: '/',
        });
    }
}

  render() {
    const { savedData, preferenceData, loginState } = this.props;
    const {isLoading} = loginState;
    const {redirect,logOutRoute} = this.state;
    // console.log(savedData)
    let redirectionResult = '';
    if (redirect) {
        redirectionResult = <Redirect to={logOutRoute} />;
    }
    if (savedData) {
        const { form } = savedData;
        // const fields = Object.keys(form);
        formattedData = [
            { id: 'question1', title: 'Security Question 1', label: form.question1.displayValue, desc: form.answer1.value },
            { id: 'question2', title: 'Security Question 2', label: form.question2.displayValue, desc: form.answer2.value },
            { id: 'question3', title: 'Security Question 3', label: form.question3.displayValue, desc: form.answer3.value }
        ]
        // if (form.primaryEmail.value) {
        //     formattedData.push({ id: 'primaryEmail', label: 'Primary Email', desc: form.primaryEmail.value });
        // }
        // if (fields.includes('additionalEmail') && form.additionalEmail.value) {
        //     formattedData.push({ id: 'additionalEmail', label: 'Additional Email', desc: form.additionalEmail.value })
        // }
        formattedData.push({
            id: 'delivery',
            label: 'Document delivery preference',
            desc: preferenceData.online ? 'Deliver all my documents online at vcm.com' : 'Deliver all my documents by post'
        })

    }

    return (
      <div className="container-fluid">
        {redirectionResult}
        <WSpinner loading={isLoading} />
        {/* <LoadingOverlay active={isLoading} spinner styles={assignObj({ wrapper: { overflow: 'hidden' } })} /> */}
        {savedData ? null : <Redirect to="setup-security-questions" />}
        <div className="container">
          <div style={data.stepperDivStyle}>
            <WStepper className="userWizard" currentPage={4} pages={data.pages} stepperColStyle={stepperColStyle} />
          </div>
          <Row className="main">
            <Col md lg xl>
              <SideHeader
                title={data.title}
                info={data.info} />
            </Col>
            <Col md lg xl>
              <ConfirmSecurityQuestionsForm formattedData={formattedData}/>
            </Col>
          </Row>
        </div>
        <CommonButtons backClick={this.onBack} submitClick={this.onNext} submit="true" blockstyle={data.commonButtons} arialabelback="Back to Preference" />
      </div>
    );
  }
}

ConfirmSecurityQuestions.propTypes = {
  getCustomerProfile: PropTypes.func,
  customerProfile: PropTypes.instanceOf(Object),
  manageLocalState: PropTypes.func,
  savedData: PropTypes.instanceOf(Object),
  preferenceData: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  setDocumentPreference: PropTypes.func,
  setJWTToken: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
}

ConfirmSecurityQuestions.defaultProps = {
  getCustomerProfile: () => {},
  customerProfile: null,
  manageLocalState: () => {},
  savedData: null,
  preferenceData: null,
  loginState: {},
  setDocumentPreference: () => {},
  setJWTToken: () => {},
  history: []
}

export default ConfirmSecurityQuestions;