import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getTransactionData } from "shared/Actions/AmendActions";
import {localStateManagementActions} from '../../../shared/Actions';
import AmendTransaction from "./AmendTransaction";

const mapStateToProps = state => {
    return {
        pendingTransactionData: state.amendReducerData,
        initialState: state.initialAppData,
        localStateManagementData: state.localStateManagementReducerData
    }  
}

const mapDispatchToProps = {
    getTransactionData,
    ...localStateManagementActions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmendTransaction));