import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";

const initialState = {
    "manage_beneficiary": {},
    savedBeneficiaryData: {
        "key": "",
        "account_Type": "",
        "account_Name": "",
        "account_Number": "",
        "accumulated_Value": "",
        "distribution_Per": "",
        "transfer_on_Death_Bene": [],
        "primary_Bene": [],
        "contingent_Bene": [],
        "new_Primary_Bene": [],
        "new_Contingent_Bene": []
    },
    isLoadingAddBeneficiary: false,
    isSuccessAddBeneficiary: false,
    isErrorAddBeneficiary: false,
    error: null,
    newBeneficiaryFlag: false
};


function manageBeneficiaryReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_BENEFICIARY:
            {
                return {
                    ...state,
                    ...action,
                    isLoading: false,
                    isError: false,
                    isSuccess: false
                };
            }
        case ActionTypes.GET_BENEFICIARY_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                isError: false,
                isSuccess: true
            };
        }
        case ActionTypes.GET_BENEFICIARY_LOADING: {
            return {
                ...action.payload,
                isSuccessAddBeneficiary: false,
                isLoading: true,
                isError: false,
                isSuccess: false
            };
        }
        case ActionTypes.GET_BENEFICIARY_FAILURE: {
            return {
                ...state,
                error: action.error,
                isLoading: false,
                isError: true,
                isSuccess: false
            };
        }
        case ActionTypes.GET_EDIT_BENEFICIARY:
            {
                return {
                    ...state,
                    ...action
                };
            }
        case ActionTypes.DELETE_BENEFICIARY:
            {
                return {
                    ...state,
                    ...action
                };
            }
        case ActionTypes.DELETE_BENEFICIARY_LOADING:
            {
                return {
                    ...state,
                    isLoadingDeleteBeneficiary: true,
                    isSuccessDeleteBeneficiary: false,
                    isErrorDeleteBeneficiary: false
                };
            }
        case ActionTypes.DELETE_BENEFICIARY_SUCCESS:
            {
                return {
                    ...state,
                    isLoadingDeleteBeneficiary: false,
                    isSuccessDeleteBeneficiary: true,
                    isErrorDeleteBeneficiary: true
                };
            }
        case ActionTypes.DELETE_BENEFICIARY_ERROR:
            {
                return {
                    ...state,
                    error: action.error,
                    isSuccessAddBeneficiary: false,
                    isLoadingDeleteBeneficiary: false,
                    isErrorDeleteBeneficiary: true
                };
            }
        case ActionTypes.CLEAR_BENEFICIARY_DATA:
            {
                return {
                    ...initialState,
                    ...action
                };
            }
        case ActionTypes.SAVE_BENEFICIARY_LOADING:
            {
                return {
                    ...state,
                    isLoadingAddBeneficiary: true,
                    isSuccessAddBeneficiary: false,
                    isErrorAddBeneficiary: false
                };
            }
        case ActionTypes.SAVE_BENEFICIARY_SUCCESS:
            {
                return {
                    ...state,
                    isLoadingAddBeneficiary: false,
                    isSuccessAddBeneficiary: true,
                    isErrorAddBeneficiary: false
                };
            }
        case ActionTypes.SAVE_BENEFICIARY_ERROR:
            {
                return {
                    ...state,
                    error: action.error,
                    isLoadingAddBeneficiary: false,
                    isSuccessAddBeneficiary: false,
                    isErrorAddBeneficiary: true
                };
            }
        default:
            return state;
    }
}

export default manageBeneficiaryReducer;
