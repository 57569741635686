import ApiEventHub from './ApiEventHub';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import Config from 'env/Config';
import { post, setAuthHeaders, setAuthHeadersGuid, setAuthHeadersWithOutGuid, setMSRAuthHeaders, getMemberProfileInformationsData, setMSRProfileApiHeader, setHeaderForGenericidLookup,getApplicationConfig, removeAPIVersionHeader } from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';

const UserManagementApiEvents = () => {
    ApiEventHub.on(ActionTypes.SET_JWT_TOKEN, data => {
        setAuthHeaders(data.payload);
    });

    ApiEventHub.on(ActionTypes.SET_GUID_TOKEN, data => {
        setAuthHeadersGuid(data.payload);
    });

    ApiEventHub.on(ActionTypes.SET_ENV, data => {
        setAuthHeaders(data.payload);
    });

    ApiEventHub.on(ActionTypes.USER_GET_QUICK_SIGN_IN, data => {
        post(
            Config.getQuickSignIn,
            data.payload,
            data.endPointURL.USER_MANAGEMENT,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_SET_QUICK_SIGN_IN, data => {
        post(
            Config.setQuickSignIn,
            data.payload,
            data.endPointURL.USER_MANAGEMENT,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_SAVE_SECURITY_QUESTIONS, data => {
        post(
            Config.saveSecurityQuestions,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_SET_DOCUMENT_PREFERENCE, data => {
        setAuthHeaders('');
        post(
            Config.documentPreference,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_SET_SIGN_IN_METHOD, data => {
        post(
            Config.setChangeSignInMethod,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.USER_GET_SIGN_IN_METHOD, data => {
        post(
            Config.getChangeSignInMethod,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.VALIDATE_EMAIL, data => {
        setAuthHeaders('');
        post(
            Config.validateEmail,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SIGN_UP, data => {
        setAuthHeaders('');
        post(Config.signUp, data.payload, data.endPointURL.devEnvironment, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.SEND_OTP, data => {
        setAuthHeaders('');
        post(Config.sendOTP, data.payload, data.endPointURL.devEnvironment, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.VERIFY_OTP, data => {
        setAuthHeaders('');
        post(Config.verifyOTP, data.payload, data.endPointURL.devEnvironment, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.SEND_EMAIL_LINK, data => {
        setAuthHeaders('');
        post(
            Config.sendEmailLink,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.VERIFIED_EMAIL_LINK, data => {
        setAuthHeaders('');
        post(
            Config.verifiedEmailLink,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RECOVER_ONLINE_ID, data => {
        setAuthHeaders('');
        post(
            Config.recoverOnlineID,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RECOVER_FLOW_GUEST_SEND_MAIL, data => {
        setAuthHeaders('');
        post(
            Config.recoverPasswordGuest,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RECOVER_FLOW_GUEST_EMAIL_VERIFY, data => {
        setAuthHeaders('');
        post(
            Config.recoverPwrdGuestEmailVerify,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RECOVER_FLOW_MEMBER_OR_GUEST, data => {
        setAuthHeaders('');
        post(
            Config.recoverPasswordMemberOrGuest,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RESET_FLOW, data => {
        setAuthHeaders('');
        post(
            Config.recoverPassword,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RESET_FLOW_GUEST, data => {
        setAuthHeaders('');
        post(
            Config.resetPasswordForGuest,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RECOVER_FLOW_CUSTOMER, data => {
        setAuthHeaders('');
        post(
            Config.recoverPasswordCustomer,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_CUSTOMER_PROFILE, data => {
        const params = getSessionValues();
        data.payload.msrid = params.msrid;
        data.payload.vcmid = params.vcmid;
        data.payload.fismemberid = params.fismemberid;
        data.payload.token = params.accesstoken;
        const apiKey = Config.msrAPIKey;

        setMSRProfileApiHeader(params.accesstoken, params.vcmid, apiKey,params.msrid);
        removeAPIVersionHeader();
        getMemberProfileInformationsData(
            Config.msrUserCustomerProfile,
            data.payload,
            data.endPointURL.newMsrUserProfileView,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.VALIDATE_SECURITY_QUESTIONS, data => {
        post(
            Config.validateSecurityQuestions,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_SAVED_SECURITY_QUESTIONS, data => {
        const xAPIKey = Config.msrSecurityListKey;
        const {accesstoken="", vcmid=""} = data.payload;
        setMSRAuthHeaders(accesstoken,vcmid, "", xAPIKey);
        post(
            Config.msrSecurityList,
            '',
            data.endPointURL.msrSecurityListAPI,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.CHANGE_FLOW, data => {
        post(
            Config.changePassword,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.RESEND_OTP_CUSTOMERS, data => {
        setAuthHeaders('');
        post(
            Config.resendOTPCustomer,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.LOGIN_ATTEMPTS, data => {
        setAuthHeaders('');
        post(
            Config.loginAttempts,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.SIGN_UP_VERFIY_ONLINEID, data => {
        if (data?.payload?.flow){
            const value = data;
            delete value.payload.flow;
            setAuthHeadersWithOutGuid();
        } else {
            setAuthHeaders('');
        }
        post(
            Config.signUpOnlineVerify,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.MEMBER_OR_GUEST_IVR_VERIFY, data => {
        setAuthHeaders('');
        post(Config.verifyIVR, data.payload, data.endPointURL.devEnvironment, data.successCallBack, data.errorCallback);
    });

    ApiEventHub.on(ActionTypes.RECOVER_ONLINE_ID_TYPE, data => {
        setAuthHeaders('');
        post(
            Config.recoverOnlineIDType,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_ID, data => {
        if (data?.payload?.flow){
            const value = data;
            delete value.payload.flow;
            setAuthHeadersWithOutGuid();
        } else {
            setAuthHeaders('');
        }
        post(
            Config.directMemberID,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_VERIFY_MEMBER_ID, data => {
        const options = {
            headers: {  'msrid': '1234' }
        };
        post(
            Config.directVerifyMember,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
            options
        );
    });


    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_SEND_EMAIL_OTP, data => {
        post(
            Config.directSendEmailOTP,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_VERIFY_OTP, data => {
        post(
            Config.directVerifyOTP,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_STATUS_VERIFY, data => {
        post(
            Config.directStatusofVerifyOTP,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_SIGN_UP, data => {
        if (data?.payload?.guid){
            const {guid} = data.payload;
            setAuthHeadersGuid(guid);
        }
        post(
            Config.directSignUp,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.DIRECT_MEMBER_SEND_PHONE_OTP, data => {
        post(
            Config.directSendPhoneOTP,
            data.payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });


    ApiEventHub.on(ActionTypes.GENERICID_LOOKUP_START, data => {

        const payload = data?.payload?.requestPayload
        const xAPIKey = Config.msrAPIKey;
        setHeaderForGenericidLookup(data.payload?.headers?.accessToken,
        xAPIKey);
        post(
            Config.genericidLookuplist,
            payload,
            data.endPointURL.devEnvironment,
            data.successCallBack,
            data.errorCallback,
        );
    });

    ApiEventHub.on(ActionTypes.GET_APPLICATION_CONFIG, data => {
        getApplicationConfig(
            Config.getApplicationConfig,
            data.payload,
            data.endPointURL.getCompositeData,
            data.successCallBack,
            data.errorCallback,
            Config.devApiKey
        );
    });

};

export default UserManagementApiEvents;
