const bredCrumItmes = [
    { 
        url: '/Profile', 
        name: 'Profile',
        dataAnalyticId:'cms_basicinformation_managemilitaryinfo_breadcrumb_profile' 
    }, 
    { 
        url: '/basicinformation', 
        name: 'Basic Information',
        dataAnalyticId:'cms_basicinformation_managemilitaryinfo_breadcrumb_basicinfo'
    }
];
const breadCrumName = 'Manage Military Information';
const topHeading = `Please provide current military information. We want to ensure that our members receive our most competitive offers and rates and are addressed according to correct military protocol`;
const line = `Are you currently serving, or have you ever served in the US Military?`;
const dateService = 'Dates of Service';
const line2 = `This information is collected on behalf of each of the VCM companies, and will be used for business purpose, including identification, verification of eligibility, and to market their products and services` ;
const MILITARY_INFO = 'Military Information';
const MILITARY_STATUS = 'Military Status';
const BRANCH_SERVICE= 'Branch of Service';
const DATE_SERVICE = 'Dates of Service';
const COMMISSION_SOURCE = 'Commission Source';
const RANK = 'Rank';
const CURRENTLY_SERVIING = 'Are you currently serving, or have you ever served in the US Military?';
const CURRENT_MILITARY = 'Provide your current military information';
const NOF = 'None on file';
const OTHER_MILITARY_STATUS = "Other Military Status";
const OTHER_BRANCH_SERVICE = "Other Branch of Service";

const ACTION_LABEL_MILITARY = 'Manage Military Information';
const ACTION_TEXT = 'Manage';
const SAVE = "Save";
const CANCEL = "Cancel";

export default {
    OTHER_MILITARY_STATUS,
    OTHER_BRANCH_SERVICE,
    bredCrumItmes,
    breadCrumName,
    topHeading,
    line,
    line2,
    dateService,
    ACTION_TEXT,
    MILITARY_INFO,
    ACTION_LABEL_MILITARY,
    CURRENT_MILITARY,
    MILITARY_STATUS,
    BRANCH_SERVICE,
    DATE_SERVICE,
    COMMISSION_SOURCE,
    RANK,
    CURRENTLY_SERVIING,
    NOF,
    SAVE,
    CANCEL
};