import { get } from 'lodash';
import * as ActionTypes from "../ReduxConstants/ServiceActionConstants";


const initialState = {
  existingPlanList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  isSkip:false,
  isDelete:false,
  isAdd:false,
  isUpdate:false,
  deleteResponse:{},
  error: {}
};

function initialReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.AUTO_INVEST_GET_PAD_DETAILS:
    case ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS:
    case ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS:
    case ActionTypes.SKIP_INVEST_WITHDRAW_PLAN:
    case ActionTypes.DELETE_INVEST_WITHDRAW_PLAN: {
      return {
        ...state,
        isLoading: true,
        isError:false,
        isSuccess:false,
        isAdd: false,
        isUpdate:false,
      };
    }
    case ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS_SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isAdd:true
        };
      }
    case ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS_SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isUpdate:true
        };
      }
    case ActionTypes.SKIP_INVEST_WITHDRAW_PLAN_SUCCESS:
      {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isSkip:true
        };
      }
    case ActionTypes.DELETE_INVEST_WITHDRAW_PLAN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isDelete:true,
        deleteResponse:action.response
      };
    }
    case ActionTypes.RESET_DELETE_FLAG: {
      return {
        ...state,
        isDelete: false,
      };
    }
    case ActionTypes.AUTO_INVEST_GET_PAD_DETAILS_FAILURE:
    case ActionTypes.AUTO_INVEST_ADD_PAD_DETAILS_FAILURE:
    case ActionTypes.AUTO_INVEST_UPDATE_PAD_DETAILS_FAILURE:
    case ActionTypes.SKIP_INVEST_WITHDRAW_PLAN_FAILURE:
    case ActionTypes.DELETE_INVEST_WITHDRAW_PLAN_FAILURE: {
      let dataError = action.response;
      const dataResponseMessages = JSON.parse(get(action.response, 'data.response', null));
      if (dataResponseMessages && dataResponseMessages.messages.length) {
        let message = 'Oh snap! You got an error from backend!';
        dataResponseMessages.messages.forEach(err => {
          message = `${message} ${err.msg}`;
        });

        dataError = {
          message
        };
      }

      return {
        ...state,
        error: dataError,
        isLoading: false,
        isError: true,
        isUpdate:false
      };
    }
    case ActionTypes.DISMISS_INVESTMENT_WITHDRAWAL_ERROR: {
      return {
        ...state,
        error: {},
        isLoading: false,
        isError: false
      };
    }
    case ActionTypes.AUTO_INVEST_GET_PAD_DETAILS_SUCCESS: {
      // For 204 response will be empty
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        existingPlanList: action.response
      };
    }
    case ActionTypes.CLEAR_ERROR:{
      return {
        ...state,
        isLoading: false,
        isError:false,
        isDelete:false
      };
    }
    case ActionTypes.RESET_EXT_LIST: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

export default initialReducer;
