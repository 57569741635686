/* eslint-disable class-methods-use-this */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import { LiveAnnouncer, LiveMessenger } from 'react-aria-live';
import { isEmpty } from 'lodash';
import WStepper from '../../components/WStepper';

import {
  ContainsSpecialUserIdChar,
  ContainsEitherUpperOrLower,
  ContainsNumeric,
} from '../../Utils';

import consts from '../../components/constants';
import analytics from '../../components/utils.analytics';

const assignObj = (obj) => obj;
class UserId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: '',
      rules: [
        { description: 'Must be at least 5 characters in length', isValid: false },
        {
          description:
            'May contain alphabetic characters in lowercase (i.e. a-z) and numerical characters (i.e. 0-9)',
          isValid: false,
        },
        {
          description:
            'Must not contain special characters (e.g. ~!@#$%^&*()_-+=) or spaces',
          isValid: false,
        },
      ],
      isFormValid: false,
      error: '',
    };
    this.target = React.createRef();
  }

  componentDidMount() {
    const { history, accountInfo } = this.props;
    window.scriptLoaded = false;
    
    if (isEmpty(accountInfo) || accountInfo === undefined) {
      history.push('/accountRegistration/Signout');
    }
  }

  getMessage(rules) {
    const msg = rules.map(({ description }) => description).join('.');
    return msg.length > 0 ? `Accepted Format: ${msg}` : '';
  }

  getErrorMessage(first, second, last) {
    if (!first.isValid) {
      return 'should be at least 5 characters';
    }
    if (!last.isValid || !second.isValid) {
      return 'The User ID does not meet the length and format requirements.';
    }
    return '';
  }

  getUnstatisfiedMessage(first, second, last) {
    let adaMessage = 'User ID ';
      if (!first.isValid) {
      adaMessage += `${first.description}.`;
    }
      if (!second.isValid) {
      adaMessage += `${second.description}.`;
    }
      if (!last.isValid) {
      adaMessage += `${last.description}.`;
      }
    return adaMessage;
  }
  
  getOnChangeADAMessage(text, first, second, last) {
    let adaMessage = '';

    if (first.isValid && second.isValid && last.isValid) {
      adaMessage = 'All the required guidelines to create User ID are satisified';
      return adaMessage;
    }

    return adaMessage;
  }

  guidanceComp =(rules) => (
    <div className="rulesWrapper" id="userIdmsg">
      <h2 className="rulesHeading">{consts.format}</h2>
      <ul className="rules" id="rulesmsg">
        {rules.map(({ description, isValid }, index) => (
          <li className="displayFlex" key={Number(index)}>
            <div className="ruleListIcon">
              <div className={isValid ? 'filledCircle' : 'circle'}>
                <div className="tick" />
              </div>
            </div>
            <div className="ruleListText">{description}</div>
            {isValid ? (
              <span className="sr-only">{consts.guideSatisfied}</span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );

  saveToContext() {
    const { saveUserId } = this.props;
    const { userid, isFormValid } = this.state;
    saveUserId({
      userid,
      isUseridValid: isFormValid,
    });
  }

  handleButtonClick() {
    analytics.triggerClickTrackEvent('Preregister_createuserid_Submit');
  }
  

  render() {
    const { userid, rules, isFormValid, error } = this.state;
    const { history } = this.props;

    return (
      <div className="milestone">
        <div className="pad contentWrapper">
          <Container fluid>
            <WStepper currentPage={3} pages={consts.pages} />
            <Row className="headingRow">
              <Col className="descWrapper" xs={12} sm={6}>
                <h1 className="descWrapperHeading">{consts.onlineTitle}</h1>
              </Col>
            </Row>
            <Row>
              <Col className="descWrapper" xs={12} sm={6}>
                {this.guidanceComp(rules)}
              </Col>
              <Col xs={12} sm={6} className="RHSWrapper">
                <Container style={assignObj({ margin: 0 })}>
                  <Row>
                    <Col className="noPad">
                      <label htmlFor="userId" className="inputLabel">
                        {consts.preferredId}
                      </label>
                      <form
                        onSubmit={assignObj((e) => {
                          e.preventDefault();
                          this.saveToContext();
                          window.scriptLoaded = false;
                          history.push({
                            pathname: '/accountRegistration/enterPassword',
                            state: { userid },
                          });
                        })}
                      >
                        <div>
                          <LiveAnnouncer>
                            <LiveMessenger>
                              {({ announcePolite }) => (
                                <input
                                  id="userId"
                                  value={userid}
                                  type="text"
                                  minLength="5"
                                  maxLength="20"
                                  className={error ? 'errorField' : ''}
                                  ref={this.target}
                                  required
                                  aria-required
                                  aria-describedby={
                                    error
                                      ? 'rulesMsg passwordError'
                                      : 'rulesMsg'
                                  }
                                  aria-invalid={!!error}
                                  onFocus={assignObj(() => {
                                    setTimeout(() => {
                                      announcePolite('');
                                    }, 100);
                                  })}
                                  onBlur={assignObj(() => {
                                    if (!isEmpty(userid) && userid.length > 0) {
                                      const [first, second, last] = rules;
                                      setTimeout(() => {
                                        announcePolite(
                                          this.getUnstatisfiedMessage(
                                            first,
                                            second,
                                            last,
                                          ),
                                        );
                                      }, 100);
                                      this.setState({ error: this.getErrorMessage(first, second, last) });
                                    } else {
                                      this.setState({ error: 'User Id is mandatory. It cannot be left blank.' });
                                    }
                                  })}
                                  onChange={assignObj((e) => {
                                    const text = e.target.value
                                      .split(' ')
                                      .join('');
                                    let [first, second, last] = rules;
                                    first = {
                                      ...first,
                                      isValid: text.length >= 5,
                                    };
                                    second = {
                                      ...second,
                                      isValid:
                                        ContainsEitherUpperOrLower(text)
                                        || ContainsNumeric(text),
                                    };
                                    last = {
                                      ...last,
                                      isValid:
                                        !ContainsSpecialUserIdChar(text)
                                        && text.length > 0,
                                    };
                                    setTimeout(() => {
                                      announcePolite(
                                        this.getOnChangeADAMessage(
                                          text,
                                          first,
                                          second,
                                          last,
                                        ),
                                      );
                                    }, 100);

                                    this.setState({
                                      rules: [first, second, last],
                                      userid: text,
                                      isFormValid: first.isValid && last.isValid,
                                    });
                                  })}
                                />
                              )}
                            </LiveMessenger>
                          </LiveAnnouncer>
                          {error && (
                            <p
                              className="errorText"
                              aria-live="polite"
                              aria-atomic="true"
                              id="passwordError"
                            >
                              {error}
                            </p>
                          )}
                          <p className="para" />
                        </div>
                        <div>
                          <button
                            className="vcmbtn sameWidthButton"
                            type="submit"
                            onClick={this.handleButtonClick}
                            disabled={!isFormValid}
                          >
                            <span className="vcmBtnText">
                              {!isFormValid ? consts.enterId : 'Continue'}
                            </span>
                          </button>
                        </div>
                      </form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

UserId.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  saveUserId: PropTypes.func,
  accountInfo: PropTypes.instanceOf(Object),
};

UserId.defaultProps = {
  history: {},
  saveUserId: () => {},
  accountInfo: {},
};

export default UserId;
