import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
// import { Auth } from 'aws-amplify';
// import { Redirect } from 'react-router';
// import LoadingOverlay from 'react-loading-overlay';
import routeConstants from 'routeConstants';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';

import { changedHandler } from '../../FormUtils';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WMessageBar, WSpinner } from '../../../../common';
import data from './Constants';
import styles from '../../UserManagementStyles';

// const assignObj = obj => {
//     return obj;
// };

class VerifyEmail extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
            notificationMsg: null,
            error: null,
        };
        this.onBack = this.onBack.bind(this);

        this.resendHandler = this.resendHandler.bind(this);
    
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const { message } = location || {};

        if (message) {
            this.setState({
                notificationMsg: message,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { loginState, history } = nextProps;
        // const localData = { personalDetailsState : {...state} };
        let updatedState = null;


        if (loginState && loginState.memberVerify) {
            if (loginState.memberVerify.errorCode === 0) {
                history.push(routeConstants.termsAndConditions);
            } else {
                updatedState = { ...state, error: loginState.memberVerify.result };
            }
        }
        return updatedState;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const { loginState } = this.props;
            const { signUp } = loginState;
            let updatedState = { ...this.state };
            
        if(signUp.errorCode === 0 && signUp.message === 'Success'){
            updatedState = this.resendSuccessHandler();
        } else if (signUp.errorCode === 5001 && signUp.message === 'Failure') {
            updatedState = this.resendFailureHandler();
        }
            this.updateStateFn(updatedState);
        }
    }

    onChange(event) {
        changedHandler(event, this);
    }

    onBack() {
        const { history } = this.props;
        history.push('/personal-details');
    }

    resendSuccessHandler = () => {
        const stateData = this.state;
        const updatedState = { ...stateData };
        updatedState.notificationMsg = { ...stateData.notificationMsg, message: data.resentEmail };
        return updatedState;
    }
    
    resendFailureHandler = () => {
        const stateData = this.state;
        const { loginState } = this.props;
        const { signUp } = loginState;
        const updatedState = { ...stateData };
        updatedState.notificationMsg = { ...stateData.notificationMsg, message: signUp.result };
        return updatedState;
    }
    
    updateStateFn = updatedState => {
        this.setState({ ...updatedState });
    };

    verifyHandler = () => {
        const { registerState, personalDetailsState, verifyEmailLink } = this.props;
        const { uuid } = personalDetailsState;
        const payload = {
            [userConstants.emailAddress]: registerState.value,
            uuid,
        };
        verifyEmailLink(payload);
    };

    onClose = () => {
        this.setState(state => ({ ...state, notificationMsg: null }));
    };

    resendHandler() {
        const { sendEmailLink, personalDetailsState } = this.props;
        const { form } = personalDetailsState;
        // const localData = { personalDetailsState : {...this.state} };
        const payload = {
            emailAddress: form.emailId.value,
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            phoneNumber: (form.mobileNumber.value).split('-').join(''),
            middleName: form.middleName.value,
            // "prefix": form.prefix.displayValue,
            // "suffix": form.suffix.displayValue
            "prefix": form.prefix.value,
            "suffix": form.suffix.value
        };
        sendEmailLink(payload);
        // this.setState(state => ({ ...state, notificationMsg: { message: data.resentEmail } }));
    }

    render() {
        const { notificationMsg } = this.state;
        const { personalDetailsState, loginState } = this.props;
        const { form } = personalDetailsState;
        const sideHeaderData = {
            title: data.title,
        };
        return (
            <div className="container-fluid">
                {notificationMsg ? <WMessageBar text={notificationMsg} onClose={this.onClose} /> : null}
                <WSpinner loading={loginState.isLoading} />
                <div className="container">
                    <Row>
                        <Col>
                            <div style={data.styles.header}>
                                <SideHeader title={sideHeaderData.title} />
                            </div>
                            <div>
                                <p style={data.styles.info}>
                                    <span>
                                        {data.entered}
                                        <strong style={styles.infoStrong}>{form.emailId.value}</strong>
                                        {data.asEmail}
                                    </span>
                                </p>
                                <p style={data.styles.info}>{data.info}</p>
                                <p style={data.styles.infoEnd}>
                                    <span>
                                        <span style={data.styles.doNotSeeStyle}>{data.doNotSee}</span>
                                        <button
                                            id="resend"
                                            type="button"
                                            style={data.styles.button}
                                            onClick={this.resendHandler}
                                        >
                                            {data.resend}
                                        </button>
                                        <span style={data.styles.verifyStyle}>{data.verify}</span>
                                    </span>
                                </p>
                            </div>
                            {/* <WButton id="#verifyButton" buttontext="Verify" onClick={this.verifyHandler} /> */}
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.onBack}
                    nextstyle={data.styles.nextStyle}
                    blockstyle={styles.commonButtons}
                    arialabelback= {data.backText}
                    dataAnalyticId="UMS_Signup_Verify_Email"
                />
            </div>
        );
    }
}

VerifyEmail.propTypes = {
    location: PropTypes.instanceOf(Object),
    sendEmailLink: PropTypes.func,
    verifyEmailLink: PropTypes.func,
    personalDetailsState: PropTypes.instanceOf(Object),
    loginState: PropTypes.instanceOf(Object),
    registerState: PropTypes.instanceOf(Object),
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
};

VerifyEmail.defaultProps = {
    location: {},
    registerState: {},
    sendEmailLink: () => {},
    verifyEmailLink: () => {},
    personalDetailsState: {form:{emailId: {value: ''}}},
    loginState: {},
    history: [],
};

export default VerifyEmail;
