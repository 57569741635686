import React from "react";
import PropTypes from 'prop-types';
import editIcon from 'assets/EditPencil.svg';
import {
    getFormattedSSN,
    formatDateToTwoDigit,
    isValidDate
} from "utils";
import {
    checkValues,
    getFullName,
    getMemberId
} from "modules/CustomerManagementModule/utils";
import { FieldValue, PrefilledInput, TitleWithAction, SectionTitle} from 'modules/CustomerManagementModule/components';
import CONST from '../constants';

const PersonalInfoView = (props) => {
    const {
            firstName,
            middleInitial,
            lastName,
            citizenship,
            gender,
            maritalStatus,
            memberId,
            prefix,
            ssnTin,
            suffix,
            vcmId,
            dateOfBirth,
            countryOfCitizenship,
            pageTitle,
            isPersonalRelation,
            onManageClick,
            hideManage,
        } = props;
    const fullName = getFullName(firstName, middleInitial, lastName);
    const memberIdVal = getMemberId(memberId,vcmId);
    
    const formattedSSN = getFormattedSSN(ssnTin, true);
    const formattedDate = isValidDate(dateOfBirth) ? formatDateToTwoDigit(new Date(dateOfBirth)) : '';
    return (
            <>
                <TitleWithAction
                    sectionTitle
                    title={<SectionTitle>{pageTitle}</SectionTitle>}
                    ariaLabel={CONST.ACTION_LABEL}
                    onActionClick={onManageClick}
                    actionText={CONST.ACTION_TEXT}
                    actionIcon={editIcon}
                    mb='0.625'
                    hideAction={hideManage}
                />
                <FieldValue
                    as="p"
                    label={CONST.NAME}
                    value={
                        <PrefilledInput
                            id="vcm-cms-fullname"
                            value={checkValues(fullName)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.PREFIX}
                    value={
                        <PrefilledInput
                            id="vcm-cms-prefix"
                            value={checkValues(prefix)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.SUFFIX}
                    value={
                        <PrefilledInput
                            id="vcm-cms-suffix"
                            value={checkValues(suffix)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                {!isPersonalRelation && 
                    <>
                        <FieldValue
                            as="p"
                            label={CONST.MEMBER_ID}
                            value={
                                <PrefilledInput
                                    id="vcm-cms-member-id"
                                    value={memberIdVal}
                                />
                            }
                            labelsm={3}
                            valuesm={5}
                            mb='0.0625'
                        /> 
                    </>
                }
                <FieldValue
                    as="p"
                    label={CONST.SOCIAL_SECURITY_NUMBER}
                    value={
                        <PrefilledInput
                            id="vcm-cms-ssn"
                            value={checkValues(formattedSSN)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.DOB}
                    value={
                        <PrefilledInput
                            id="vcm-cms-dob"
                            value={checkValues(formattedDate)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.GENDER}
                    value={
                        <PrefilledInput
                            id="vcm-cms-gender"
                            value={checkValues(gender)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.MARITAL_STATUS}
                    value={
                        <PrefilledInput
                            id="vcm-cms-marital"
                            value={checkValues(maritalStatus)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='0.0625'
                />
                <FieldValue
                    as="p"
                    label={CONST.CITIZENSHIP}
                    value={
                        <PrefilledInput
                            id="vcm-cms-citizenship"
                            value={checkValues(citizenship)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb={(citizenship === 'Non U.S') ? '0.0625' : '1.75'}
                />
                {
                    (citizenship === 'Non U.S') && 
                    <FieldValue
                    as="p"
                    label={CONST.COUNTRY}
                    value={
                        <PrefilledInput
                            id="vcm-cms-countryOfCitizenship"
                            value={checkValues(countryOfCitizenship)}
                        />
                    }
                    labelsm={3}
                    valuesm={5}
                    mb='1.75'
                />
                }
            </>
        );
}

PersonalInfoView.propTypes = {
    firstName: PropTypes.string,
    middleInitial: PropTypes.string,
    lastName: PropTypes.string,
    citizenship: PropTypes.string,
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    memberId: PropTypes.string,
    prefix: PropTypes.string,
    ssnTin: PropTypes.string,
    suffix: PropTypes.string,
    vcmId: PropTypes.string,
    dateOfBirth: PropTypes.string,
    countryOfCitizenship: PropTypes.string,
    onManageClick: PropTypes.func,
    pageTitle: PropTypes.string,
    isPersonalRelation: PropTypes.bool,
    hideManage: PropTypes.bool,
}

PersonalInfoView.defaultProps = {
    firstName: '',
    middleInitial: '',
    lastName: '',
    citizenship: '',
    gender: '',
    maritalStatus: '',
    memberId: '',
    prefix: '',
    ssnTin: '',
    suffix: '',
    vcmId: '',
    dateOfBirth: '',
    countryOfCitizenship: '',
    onManageClick: () => {},
    pageTitle:CONST.PERSONAL_INFO,
    isPersonalRelation: false,
    hideManage:false,
}

export default PersonalInfoView;