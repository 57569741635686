import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import MemberSignUpComponent from './MemberSignUpComponent';
import { localStateManagementActions, loginActions } from '../../../shared/Actions';

export const mapStateToProps = (state /* , props */) => ({
    directMemberIDData: get(state, 'loginData.directMemberID', {}),
    isLoading: state.loginData.isLoading
});

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...loginActions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberSignUpComponent));
