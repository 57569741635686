import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import WStepper from "../WStepper/WStepper";
import { PageHeading } from "../BasicComponents";

const Stepper = styled(WStepper)`
    padding: 60px 0;
    width: 100%;
`;

const Heading = styled(PageHeading)`
    float: none;
    display: inline-block;
    margin-bottom: 20px;
`;

const StyledRow = styled(Row)`
    margin-bottom: 3.75rem;
`;

const stepperStyle = { width: '100%', height: 60 };

const TwoSectionPageTemplate = (props) => {
    const {
        headingTxt,
        pageDescription,
        pageInformation,
        currentPageIndex,
        pageDetails,
        mdForSectionOne,
        mdForSectionTwo
    } = props;
    return (
        <Container>
            <Stepper currentPage={currentPageIndex} pages={pageDetails} stepperColStyle={stepperStyle} />
            <StyledRow>
                <Col xs={12} md={mdForSectionOne}>
                    <Heading>{headingTxt}</Heading>
                    {pageDescription}
                </Col>
                <Col xs={12} md={mdForSectionTwo}>
                    {pageInformation}
                </Col>
            </StyledRow>
        </Container>
    );
};

TwoSectionPageTemplate.propTypes = {
    headingTxt: PropTypes.string,
    pageDescription: PropTypes.instanceOf(Object),
    pageInformation: PropTypes.instanceOf(Object),
    currentPageIndex: PropTypes.number,
    pageDetails: PropTypes.instanceOf(Object),
    mdForSectionOne: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    mdForSectionTwo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
};

TwoSectionPageTemplate.defaultProps = {
    headingTxt: "",
    pageDescription: null,
    pageInformation: null,
    currentPageIndex: 0,
    pageDetails: [],
    mdForSectionOne: 6,
    mdForSectionTwo: true,
};

export default TwoSectionPageTemplate;
