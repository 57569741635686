/**
 * Terms And Conditions Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to view terms and conditions
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy HARI UG
 * @lastModifiedReason added VD styles
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CommonButtons, WInputCheckBox, WIcon, WStepper } from 'common';
// import queryString from 'query-string';
// import routeConstants from 'routeConstants';
import './TermsAndConditions.css';
import Consts from './Consts';
import signUpPages from '../SignUpConstants';
import styles from '../../UserManagementStyles';

const { stepperColStyle, stepperTextStyle } = styles.stepperStyle;

export default class TermsAndConditionsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            termsAndConditionsConsent: false,
            termsSelected: true,
        };
    }

    componentDidMount() {
        const { personalDetailsState, localStateData, history } = this.props;
        if(!personalDetailsState || !personalDetailsState.uuid) {
            history.push("/");
        }

        if (localStateData.termsAndConditionsConsent !== undefined) {
            this.setState({ termsAndConditionsConsent: localStateData.termsAndConditionsConsent });
        }
    }

    handleNext = () => {
        const { termsAndConditionsConsent } = this.state;
        if (termsAndConditionsConsent) {
            this.setState({ next: true });
            this.saveToLocal();
        } else if (!termsAndConditionsConsent) {
            this.setState({ termsSelected: false });
        }
    };

    handleBack = () => {
        const { history } = this.props;
        this.saveToLocal();
        history.push('./personal-details');
    };

    saveToLocal = () => {
        const { manageLocalState } = this.props;
        const { termsAndConditionsConsent } = this.state;
        const data = {
            termsAndConditionsConsent,
        };
        manageLocalState(data);
    };

    handleChange = e => {
        if (e.target.checked) {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: true });
        } else {
            this.setState({ termsAndConditionsConsent: e.target.checked, termsSelected: false });
        }
    };

    render() {
        const {
            heading,
            desc1,
            desc2,
            desc3,
            subheading,
            listItems,
            list1,
            list2,
            list3,
            checkBoxLabel,
            iconStyle,
            faStyle,
            checkBoxLabelStyle,
            checkBoxWrapperStyle,
            checkInputsStyle,
            commonButtonStyle,
            redirectTo,
        } = Consts;
        const { next, termsAndConditionsConsent, termsSelected } = this.state;
        return (
            <>
                <div>
                    <div className="terms-wrapper">
                        <div className="container">
                            <WStepper
                                className="userWizard"
                                currentPage={1}
                                pages={signUpPages}
                                stepperColStyle={stepperColStyle}
                                stepperTextStyle={stepperTextStyle}
                            />
                            <Row noGutters>
                                {' '}
                                <h1 className="terms-heading">{heading}</h1>{' '}
                            </Row>
                            <div className="terms-content">
                                <p className="terms-desc">
                                    <span>{desc1}</span>
                                    <span className="boldText">{desc2}</span>
                                    <span>{desc3}</span>
                                </p>
                                <div className="list-terms">
                                    <h2 className="terms-subheading">{subheading}</h2>
                                    <ul className="terms-points">
                                        <li className="term-list" key="stactic">
                                            <WIcon
                                                icon={faCheck}
                                                style={iconStyle}
                                                size="sm"
                                                fontawesomestyle={faStyle}
                                            />
                                            <span>{list1}</span>
                                            <span className="boldText2">{list2}</span>
                                            <span>{list3}</span>
                                        </li>
                                        {listItems.map(item => (
                                            <li key={item} className="term-list">
                                                <WIcon
                                                    icon={faCheck}
                                                    style={iconStyle}
                                                    size="sm"
                                                    fontawesomestyle={faStyle}
                                                />
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="check-terms">
                                    <Row className="check-content" noGutters>
                                        <WInputCheckBox
                                            id="termsConsent"
                                            checked={termsAndConditionsConsent}
                                            onChange={this.handleChange}
                                            labelstyle={checkBoxLabelStyle}
                                            checkboxwrapperstyle={checkBoxWrapperStyle}
                                            checkInputStyle={checkInputsStyle}
                                            ariadescribedby="termsConsentError"
                                            label={checkBoxLabel}
                                        />
                                    </Row>
                                </div>
                                {!termsSelected && (
                                    <span
                                        className="error-msg"
                                        aria-live="polite"
                                        aria-atomic="true"
                                        id="termsConsentError"
                                    >
                                        {Consts.radioError}
                                    </span>
                                )}
                            </div>
                        </div>
                        <CommonButtons
                            nextClick={this.handleNext}
                            style={commonButtonStyle}
                            backClick={this.handleBack}
                            disabled={!termsAndConditionsConsent}
                            arialabelback="Back to Personal Details"
                        />
                        {next ? <Redirect to={redirectTo} /> : ''}
                    </div>
                </div>
            </>
        );
    }
}

TermsAndConditionsComponent.propTypes = {
    history: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    // location: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Object)]),
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    personalDetailsState: PropTypes.instanceOf(Object),
};

TermsAndConditionsComponent.defaultProps = {
    // location: {},
    history: [],
    localStateData: {},
    manageLocalState: () => {},
    personalDetailsState: {},
};
