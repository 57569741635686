const data = {
    invAttempt: 'Invalid attempt. ',
    youHave: 'You have ',
    attempt: ' attempt left',
    attempts: ' attempt(s) left',
    blockedText1: 'Your online account has been locked',
    inactivityText: ' days of inactivity.',
    blockedReport: 'Please contact Victory Capital Management service Rep at ',
    contactNo: '800-235-8396',
    welcome: 'Welcome ',
    signedAs: ' to Victory Capital. You have successfully logged as ',
    setup: 'Set up your Security Preferences Now',
    wrongCredntials: "User ID or password you entered doesn't match our records, please try again",
    inactiveMessage: "Your password is expired, please change your password by clicking the forgot password link below.",
    victoryFundsIncestorQ: "Are you Victory Funds Investor?",
    signinHere: "Sign in here",
    extWebsiteAlert: "External Website. Opens New Window",
}

export default data