import React from 'react';
import 'simplebar/dist/simplebar.min.css';
import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import store from './shared/Store/index';
import AppRouter from './Routes';
import theme from './styles/Themes/index';

function App() {
    return (
        <div className="appContainer">
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <LastLocationProvider>
                            <AppRouter />
                        </LastLocationProvider>
                    </Router>
                </ThemeProvider>
            </Provider>
        </div>
    );
}

export default App;
