const sectionHeading = 'Tax Documents';
const pageHeading = 'General Account Preferences';
const subheading = 'This is sample content paragraph';
const taxDelivery = {
    id: 'taxDelivery',
    key: 'How would you like to receive documents for all tax accounts?',
    value: [
        { key: 'online', value: 'Online' },
        { key: 'paper', value: 'Paper' },
    ],
};
const allDocs = {
    id: 'allDocs',
    key: 'Would you like all of your other documents from VCM Delivered the same way?',
    value: [
        { key: 'yes', value: 'Yes' },
        { key: 'no', value: 'No' },
    ],
};
const FAQ2 = 'FAQ page';
const FAQ1 =
    'Some documents any not be immediately available in large Print, Braille and/or Screen Reader formats Have questions? View our ';
const FAQ3 = ' for additional assistance.';
const successMessage = 'Delivery Preference for Tax Documents has been updated successfully';
const taxDocumentsUrls = [
    { url: '/', name: 'Profile and Preferences' },
    { url: '/', name: 'Account Messaging' },
];
const activePage = 'Tax Documents';

const radioText = {
    textAlign: 'left',
    font: '500 14px/17px benton-sans',
    letterApacing: '0.28px',
    color: '#333333DE',
    opacity: 1,
};
const radiostyles = { color: 'rgba(73, 73, 74, 1)', fontSize: 16 };

export default {
    pageHeading,
    subheading,
    radiostyles,
    radioText,
    sectionHeading,
    taxDelivery,
    activePage,
    allDocs,
    FAQ1,
    FAQ2,
    FAQ3,
    successMessage,
    taxDocumentsUrls,
};
