import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Separator from '../Separator';
import Label from '../Label';
import Toggle from '../Toggle';

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${props => props.mT || 0}px;
    label {
        width: calc(60%);
    }
`;

const MAILING_ADDRESS = 'Mailing Address';
const PHYSICAL_ADDRESS = 'Physical Address';

const AddressToggles = (props) => {
    const { mailingAddress, physicalAddress, onMailingAddressChange, onPhysicalAddressChange, idKey } = props;
    const toggleMailLabelId = `vcm-cms-set-mailing-address-${idKey}`;
    const togglePhyLabelId = `vcm-cms-set-physical-address-${idKey}`;
    const toggleMailId = `vcm-cms-toggle-mailing-address-${idKey}`;
    const togglePhyId = `vcm-cms-toggle-physical-address-${idKey}`;
    return (
        <>
            <Separator mT={0.9375} mB={0.9375} />
            <FlexDiv>
                <Label id={toggleMailLabelId}>{MAILING_ADDRESS}</Label>
                <Toggle id={toggleMailId} aria-labelledby={toggleMailLabelId} toggleOn={mailingAddress} onChange={onMailingAddressChange} />
            </FlexDiv>
            <FlexDiv mT={10}>
                <Label id={togglePhyLabelId}>{PHYSICAL_ADDRESS}</Label>
                <Toggle id={togglePhyId} aria-labelledby={togglePhyLabelId} toggleOn={physicalAddress} onChange={onPhysicalAddressChange} />
            </FlexDiv>
        </>
        )
}

AddressToggles.propTypes = {
    mailingAddress: PropTypes.bool,
    physicalAddress: PropTypes.bool,
    onMailingAddressChange: PropTypes.func,
    onPhysicalAddressChange: PropTypes.func,
    idKey: PropTypes.string,
};

AddressToggles.defaultProps = {
    mailingAddress: false,
    physicalAddress: false,
    onMailingAddressChange: ()=>{},
    onPhysicalAddressChange: ()=>{},
    idKey: ''
};

export default AddressToggles;