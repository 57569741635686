import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import constants from 'modules/TransactionModule/Liquidation/constant';
import AddBankCard from './AddBankCard';

// This component intends to give view for add bank functionality from other flows.
// i.e. Adding new bank during a transaction or AIP/SWP plan creation or account opening.
// This component is different from AddAccount in Account options module.
// AddPopularBankAccount, PopularBank and PopularBankCard are components available in common.
// Please make use of these for Day 2 functionality.

const requiredTxt = '* All fields are required unless mentioned optional';

const RequiredDiv = styled.div`
    text-align: right;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const VerificationBox = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    opacity: 1;
    margin: 40px 65px 30px 95px;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #8bc105;
        position: absolute;
    }
`;
const VerificationMessage = styled.p`
    margin: 10px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin: 36px 95px 35px 49px;
`;
const AddBankAccountCard = styled.div`
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #eef5dd;
    outline: 2px solid #8bc105;
    opacity: 1;
    margin-bottom: 50px;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 35px solid transparent;
        border-left: 35px solid #8bc105;
        position: absolute;
    }
`;
const TitleContainer = styled(Row)`
    padding-top: 2.5625rem;
    padding-left: 30px;
    padding-right: 27px;
    justify-content: space-between;
`;
const AddBankAccTxt = styled(Col)`
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;
const HarizondalSeprator = styled.div`
    border-top: 1px solid #d2d2d2;
    opacity: 1;
    margin: 0 30px 0 27px;
`;
class AddBankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popularBankNames: [],
            popularbankdisp: true,
            otherbankdisp: false,
            singlebankdisp: false,
            selectedBank: null,
            disableNext: true,
            retrivedState: false,
        };
        this.otherBankRef = React.createRef();
        this.preferedBankState = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }
        return null;
    }

    componentDidMount() {
        const { addBankAccount } = constants;
        const { popularBankNames } = addBankAccount;
        this.setState({
            popularBankNames,
        });
    }

    // For Day 2
    // onPreferedBankClick = val => () => {
    //     this.setState({
    //         popularbankdisp: false,
    //         otherbankdisp: false,
    //         singlebankdisp: true,
    //         selectedBank: val,
    //     });
    // };

    // onOthersClick = () => {
    //     this.setState({
    //         popularbankdisp: false,
    //         otherbankdisp: true,
    //         singlebankdisp: false,
    //     });
    // };

    getState = () => {
        const otherBankRef = this.otherBankRef.current;
        let otherBankState = {};
        let preferedBankState = {};
        if (otherBankRef) {
            otherBankState = { ...otherBankRef.getState() };
        }
        if (this.preferedBankState) {
            preferedBankState = { ...this.preferedBankState };
        }
        return { ...this.state, otherBankState, preferedBankState };
    };

    setStatus = (data = null) => {
        const otherBankRef = this.otherBankRef.current;
        let { disableNext } = this.state;
        if (otherBankRef) {
            disableNext = otherBankRef.disableNext;
        }
        if (data) {
            if (data.username && data.passwrd) {
                this.preferedBankState = {
                    username: data.username,
                    passwrd: data.passwrd,
                };
                disableNext = false;
            } else {
                this.preferedBankState = null;
                disableNext = true;
            }
        }

        this.setState(
            () => {
                return {
                    disableNext,
                };
            },
            () => {
                const { checkStatus } = this.props;
                checkStatus();
            },
        );
    };

    render() {
        const { addBankAccount } = constants;
        const { title, description } = addBankAccount;
        const { otherBankState } = this.state;
        const {
            note,
            handleAdd,
            apiResponse,
            responseMessage,
            responseCode,
            clearAddBankData,
            // validateBankAccInfo,
            // clearVerifyAccDetails,
        } = this.props;
        return (
            <>
                <AddBankAccountCard>
                    <TitleContainer noGutters>
                        <AddBankAccTxt>{title}</AddBankAccTxt>
                        <RequiredDiv>{requiredTxt}</RequiredDiv>
                    </TitleContainer>
                    <HarizondalSeprator />
                    <AddBankCard
                        ref={this.otherBankRef}
                        savedState={otherBankState}
                        setStatus={this.setStatus}
                        handleAdd={handleAdd}
                        apiResponse={apiResponse}
                        responseMessage={responseMessage}
                        responseCode={responseCode}
                        clearAddBankData={clearAddBankData}
                        // validateBankAccInfo={validateBankAccInfo}
                        // clearVerifyAccDetails={clearVerifyAccDetails}
                    />
                    {note && (
                        <VerificationBox>
                            <VerificationMessage>{description}</VerificationMessage>
                        </VerificationBox>
                    )}
                </AddBankAccountCard>
            </>
        );
    }
}
AddBankAccount.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    checkStatus: PropTypes.func,
    note: PropTypes.bool,
    handleAdd: PropTypes.func,
    apiResponse: PropTypes.string,
    responseMessage: PropTypes.string,
    responseCode: PropTypes.string,
    clearAddBankData: PropTypes.func,
    // validateBankAccInfo: PropTypes.func,
    // clearVerifyAccDetails: PropTypes.func,
};

AddBankAccount.defaultProps = {
    savedState: null,
    checkStatus: () => {},
    note: false,
    handleAdd: () => {},
    apiResponse: '',
    responseMessage: '',
    responseCode: '',
    clearAddBankData: () => {},
    // validateBankAccInfo: () => {},
    // clearVerifyAccDetails: () => {},
};

export default AddBankAccount;
