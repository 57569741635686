import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

export const StyledToggle = styled.div`
    position: relative;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    background: ${props => props['aria-checked'] ? '#0060C5' : '#D2D2D2'} 0% 0% no-repeat padding-box;
    &::after {
        top: 3px;
        left: 3px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 1rem;
        content: '';
        position: absolute;
        transform: ${props => props['aria-checked'] ? 'translateX(24px)' : 'none'};
        transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    &:focus {
        box-shadow: 0px 0px 16px rgba(0,0,0,0.16);
        outline: none;
    }
`;

export const toggleButtonClickHandler = (setChecked, onChange, checked) => () => {
    setChecked(p => !p);
    onChange(!checked);
};

export const toggleButtonKeydownHandler = (setChecked, onChange, checked) => (event) => {
    if (event.keyCode === 32) {
        event.preventDefault();
    }
    else if (event.keyCode === 13) {
        event.preventDefault();
        setChecked(p => !p);
        onChange(!checked);
    }
};

export const toggleButtonKeyupHandler = (setChecked, onChange, checked) => (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      setChecked(p => !p);
      onChange(!checked);
    }
};


const Toggle = (props) =>{
    const { toggleOn, onChange, ...restProps } = props;
    
    const [checked, setChecked] = useState(toggleOn);
    
    
    useEffect(()=>{
        setChecked(toggleOn);
    },[toggleOn]);
    
    return (
    <StyledToggle
        tabIndex="0"
        role="checkbox"
        aria-checked={checked}
        onClick={toggleButtonClickHandler(setChecked, onChange, checked)}
        onKeyDown={toggleButtonKeydownHandler(setChecked, onChange, checked)}
        onKeyUp={toggleButtonKeyupHandler(setChecked, onChange, checked)}
        {...restProps}
    />
  )
}

Toggle.propTypes = {
    onChange: PropTypes.func,
    toggleOn: PropTypes.bool,
};

Toggle.defaultProps = {
    onChange: () => {},
    toggleOn: false,
};

export default Toggle;