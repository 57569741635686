import * as ActionTypes from "../ReduxConstants/MSRActionConstants";

const initialState = {
    isPageLoading: false,
    isSortByPopular: false,
    forms: [{
        id: 1,
        formId: "XXXXXX101",
        formName: "Sample Content Form 1",
        formDescription: "Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 1",
        isPopular: true

    }, {
        id: 2,
        formId: "XXXXXX102",
        formName: "Form 2",
        formDescription: "To read more about John and his team, click here. To submit your own, click here. ",
        categoryName: "Category 2",
        isPopular: false
    },
    {
        id: 3,
        formId: "XXXXX103",
        formName: "Sample Content Form 3",
        formDescription: "Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 3",
        isPopular: true

    },
    {
        id: 4,
        formId: "XXXXX104",
        formName: "Form 4",
        formDescription: "Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 4",
        isPopular: true

    }, {
        id: 5,
        formId: "XXXX105",
        formName: "Form 5",
        formDescription: "To read more about John and his team, click here. To submit your own, click here. ",
        categoryName: "Category 5",
        isPopular: false
    },
    {
        id: 6,
        formId: "XXXXXX106",
        formName: "Form 6",
        formDescription: "Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 6",
        isPopular: true

    },
    {
        id: 7,
        formId: "XXXX107",
        formName: "Sample Content Form 7",
        formDescription: "Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 7",
        isPopular: false

    }, {
        id: 8,
        formId: "XXXXXX108",
        formName: "Sample Content Form 8",
        formDescription: "To read more about John and his team, click here. To submit your own, click here. ",
        categoryName: "Category 8",
        isPopular: false
    },
    {
        id: 9,
        formId: "XXXXX109",
        formName: "Sample Content Form 9",
        formDescription: "To read more about John and his team, click here. To submit your own, click here. Our culture is strong because we find the time to give back to our communities.Our culture is strong because we find the time to give back to our communities.",
        categoryName: "Category 9",
        isPopular: true

    },
    ],
    pageNumber: 0,
    isMoreDataAvailable: true,

};
const FormsReducer = (state = initialState, action) => {
    const currentState = state;
    switch (action.type) {
        case ActionTypes.SET_ACCESS_FORMS: {
            if (currentState.forms != null && currentState.forms.length !== 0) {
                currentState.forms = [...currentState.forms, ...action.payload];
            } else
                currentState.forms = [...action.payload];
            return currentState;
        }
        case ActionTypes.SORT_BY_POPULAR: {
           // const temp = (action.payload) ? currentState.forms.find((itm) => itm.isPopular === true) : initialState.forms;
           const temp = (action.payload) ? currentState.forms.sort((a, b) => b.isPopular - a.isPopular) : currentState.forms;
            return {
                ...state,
                forms: temp,
                isSortByPopular: action.payload,
            };
        }
        case ActionTypes.SET_PAGE_LOADING:
            return {
                ...state,
                isPageLoading: action.payload
            };
        case ActionTypes.SET_MORE_DATA_AVAILABLE:
            return {
                ...state,
                isMoreDataAvailable: action.payload
            };
        case ActionTypes.INCREMENT_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: state.pageNumber + 1
            };

        default:
            return state;
    }
};
export default FormsReducer;