import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import PropTypes from "prop-types";
import InstructionalPanel from '../InstructionalPanel';
import Label from '../Label';

export const PanelWrap = styled(InstructionalPanel)`
	font: 1em benton-sans;
	margin-top: ${props => props['data-mt']}rem;
	margin-bottom: ${props => props['data-mb']}rem;
`
const HeaderWrap = styled.div`
  border-bottom: 1px solid #d2d2d2;
  font: 1.125em yorkten-slab-extra-bold;
  color:#004A98;
	text-transform: capitalize;
	padding-bottom: 0.625em;
`
export const FlexDiv = styled(Row)`
	margin-top: ${props => props['data-mt']}rem;
	display:flex;
	align-items:center;
`
const EMAIL_HINT_TITLE = 'Email';

const EmailCard = (props) => {
	const { emailTitle, labelFor, value } = props;
	const cornerColor = 'grey';
	return (
		<PanelWrap data-mt={1.5} data-mb={2.5} cornerColor={cornerColor}>
			<HeaderWrap>{emailTitle}</HeaderWrap>
			<FlexDiv data-mt={1.5}>
				<Label column sm={3} htmlFor={labelFor}>
					{EMAIL_HINT_TITLE}
				</Label>
				<Col xs={9}>{value}</Col>
			</FlexDiv>
    </PanelWrap>
  )
}

EmailCard.propTypes = {
	emailTitle: PropTypes.string,
    labelFor: PropTypes.string,
    value: PropTypes.node,
};

EmailCard.defaultProps = {
	emailTitle: '',
    labelFor: '',
    value: '',
};

export default EmailCard;