const pageHeading = 'Manage Interested Parties';
const accounts = [
    {
        accountType: 'Traditional IRA',
        accountsUnder: [
            {
                accountName: 'Sample Content account Name 1',
                accountNumber: '56654654',
                contracts: [
                    {
                        contractNumber: '123456789',
                        name: 'Bob John',
                        relationship: 'Financial Advisor',
                        accountsTagged: 3,
                    },
                    {
                        contractNumber: '123456789',
                        name: 'David M',
                        relationship: 'Son',
                        accountsTagged: 1,
                    },
                ],
            },
        ],
    },
    {
        accountType: 'Roth IRA',
        accountsUnder: [
            {
                accountName: 'Sample Content account Name 2',
                accountNumber: '56654654',
                contracts: [
                    {
                        contractNumber: '123456789',
                        name: 'Helen Smith',
                        relationship: 'Wife',
                        accountsTagged: 1,
                    },
                    {
                        contractNumber: '123456789',
                        name: 'Bob John',
                        relationship: 'Daughter',
                        accountsTagged: 3,
                    },
                ],
            },
        ],
    },
    {
        accountType: 'Individual Mutual Fund Account ',
        subText: 'Transfer on Death Beneficiaries',
        accountsUnder: [
            {
                accountName: 'Sample Content account Name 3',
                accountNumber: '56654654',
                contracts: [
                    {
                        contractNumber: '123456789',
                        name: 'Robert Smith',
                        relationship: 'Brother',
                        accountsTagged: 1,
                    },
                    {
                        contractNumber: '123456789',
                        name: 'Bob John',
                        relationship: 'Step Son',
                        accountsTagged: 3,
                    },
                ],
            },
        ],
    },
];
const accountNameLabel = 'Account Name';
const accountNumberLabel = 'Account Number';
const contractNumberLabel = 'Contract Number';
const contractNameLabel = 'Name';
const relationshipLabel = 'Relationship to Account Holder';
const numbersTaggedLabel = 'Number of Accounts Taggesd';
const eifen = '-';
const addLink = 'Add';
const editLink = 'Edit';
const deleteLink = 'Delete';
const separator = '|';
const generalPreferencesUrls = [
    { url: '/', name: 'Account Dashboard' },
    { url: '/', name: 'Account Services' },
];
const icon = '||';
const hash = '#';
const activeGeneralUrl = 'Manage Interested Parties';
const menuOptions = ['Edit', 'Delete'];
export default {
    pageHeading,
    icon,
    accountNameLabel,
    accountNumberLabel,
    contractNumberLabel,
    contractNameLabel,
    relationshipLabel,
    numbersTaggedLabel,
    addLink,
    editLink,
    deleteLink,
    eifen,
    accounts,
    hash,
    activeGeneralUrl,
    separator,
    generalPreferencesUrls,
    menuOptions,
};
