
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getDividentsInfo, analytics } from 'shared/Actions';
import  DirectedDividends from './Dividents';

const mapStateToProps = (state) => {
    const accountsAvailable = get(state,'dividentsReducerData.dividendsInfo', []);
    const isLoadingGetAccountList = get(state,'dividentsReducerData.isLoadingGetAccountList', null);
    const isErrorGetAccountList = get(state,'dividentsReducerData.isErrorGetAccountList', null);
    const isSuccessGetAccountList = get(state,'dividentsReducerData.isSuccessGetAccountList', null);
    const isSuccessPostUpdateDividends = get(state,'dividentsReducerData.isSuccessPostUpdateDividends', null);
    const isLoadingPostUpdateDividends = get(state,'dividentsReducerData.isLoadingPostUpdateDividends', null);
    const isErrorPostUpdateDividends = get(state,'dividentsReducerData.isErrorPostUpdateDividends', null);
    const updatedFunds = get(state,'dividentsReducerData.updatedFunds', []);

    return {
        accountsAvailable,
        isLoadingGetAccountList,
        isSuccessGetAccountList,
        isErrorGetAccountList,
        isSuccessPostUpdateDividends,
        isLoadingPostUpdateDividends,
        isErrorPostUpdateDividends,
        updatedFunds
    }
}

const mapDispatchToProps = {
    ...getDividentsInfo,
    analyzeClickEvent: analytics.analyzeClickEvent,
    analyzeFormAdandonment: analytics.analyzeFormAdandonment,
    resetUpdateFlag: getDividentsInfo.resetUpdateFlag
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectedDividends);
