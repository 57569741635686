import makeApiRequest from "../Network/ApiInterface";
import * as ActionTypes from "../ReduxConstants/RelationConstants";

export const setFirstName = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_FIRST_NAME,
        value,
    });
};

export const setFamilyRelationShip = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_TO_FAMILY_MEMBER,
        value,
    });
};

export const setLastName = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_LAST_NAME,
        value,
    });
};

export const setMiddleName = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_MIDDLE_NAME,
        value,
    });
};

export const setprefix = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PREFIX,
        value,
    });
};

export const setsufix = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SUFIX,
        value,
    });
};

export const setDateOfBirth = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_DATEOFBIRTH,
        value,
    });
};

export const setGender = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_GENDER,
        value,
    });
};

export const setMaritalStatus = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_MARITAL_STATUS,
        value,
    });
};

export const setCitizen = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_US_CITIZEN,
        value,
    });
};

export const setSSN = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SSN,
        value,
    });
};

export const setCountryCode = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PHONE_COUNTRY,
        value,
    });
};

export const setFamilyRelationError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_TO_FAMILY_MEMBER_ERROR,
        value,
    });
};

export const setFirstNameError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_FIRST_NAME_ERROR,
        value,
    });
};

export const setLastNameError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_LAST_NAME_ERROR,
        value,
    });
};


export const setDateOfBirthError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_DATEOFBIRTH_ERROR,
        value,
    });
};

export const setGenderError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_GENDER_ERROR,
        value,
    });
};

export const setMaritalStatusError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_MARITAL_STATUS_ERROR,
        value,
    });
};

export const setCitizenShipError = (value) => (dispatch) => {
    dispatch({
          type: ActionTypes.SET_RELATIONSHIP_CITIZEN_ERROR,
          value,
    });
};

export const setCitizenShipCountryError = (value) => (dispatch) => {
    dispatch({
          type: ActionTypes.SET_RELATIONSHIP_COUNTRY_CITIZENSHIP_ERROR,
          value,
    });  
};

export const setSSNError = (value) => (dispatch) => {
    dispatch({
          type: ActionTypes.SET_RELATIONSHIP_SSN_ERROR,
          value,
    });  
};

export const setPhoneNumber = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PHONE_NUMBER,
        value,
    });
};

export const setIsMobile = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PHONE_ISMOBILE,
        value,
    });
};

export const setSecondaryCountryCode = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY,
        value,
    });
};

export const setSecondaryPhoneNumber = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER,
        value,
    });
};

export const setSecondaryIsMobile = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_ISMOBILE,
        value,
    });
};

export const setPrimaryEmail = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PRIMARY_EMAIL,
        value,
    });
};

export const setPrimaryEmailError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PRIMARY_EMAIL_ERROR,
        value,
    });
};

export const setSecondaryEmail = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_EMAIL,
        value,
    });
};

export const setSecondaryEmailError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_EMAILERROR,
        value,
    });
};

export const setPhoneCountryError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PHONE_COUNTRY_ERROR,
        value,
    });
};

export const setPhoneNumberError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_PHONE_NUMBER_ERROR,
        value,
    });
};

export const setSecondaryPhoneCountryError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_COUNTRY_ERROR,
        value,
    });
};

export const setSecondaryPhoneNumberError = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_SECONDARY_PHONE_NUMBER_ERROR,
        value,
    });
};

export const setCountryOfCitizen = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_COUNTRY_CITIZENSHIP,
        value,
    });
};

async function decryptRelationsData(decrypt, data, dispatch){
    const { result:relationshipInformation } = data || {};
    const { memberAssociationDetails } = relationshipInformation || {};
    const { Family } = memberAssociationDetails || {};
    const familyRelationShipInfo = Family || [];
    const results = [];

    let errorInDecryption = false;

    familyRelationShipInfo.forEach((item, index) => {
        
        if(item.dateOfBirth) results.push(new Promise( resolve => {
            decrypt(item.dateOfBirth).then((value)=>{
                resolve({ index, name: 'dateOfBirth', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));
        if(item.ssnTin) results.push(new Promise( resolve => {
            decrypt(item.ssnTin).then((value)=>{
                resolve({ index, name: 'ssnTin', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));
        if(item.vcmId) results.push(new Promise( resolve => {
            decrypt(item.vcmId).then((value)=>{
                resolve({ index, name: 'vcmId', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));
    });

    const decryptedValues = await Promise.all(results);
    decryptedValues.forEach((item) => {
        if(item.success) familyRelationShipInfo[item.index][item.name] = item.value; 
    });

    dispatch({
        type: ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS,
        response: data || {},
    });

    if(errorInDecryption){
        dispatch({
            type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
            response: 'Error happened during decrypting sensitive profile information',
        });
    }
}

export const getRelationshipInformations = (decrypt, payload) => (dispatch) => {
    const env = "";
    dispatch({
        type: ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION,
    });
    makeApiRequest(
        ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION,
        payload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                if(decrypt){
                    decryptRelationsData(decrypt, data, dispatch);
                }else {
                    dispatch({
                        type: ActionTypes.GET_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS,
                        response: data || {},
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
                response: data,
            });
        }
    );
};

async function decryptRelationsUpdateData(decrypt, data, dispatch, isDeleteAPICalled){
    const { result:relationshipInformation } = data || {};
    const { dateOfBirth, ssnTin, vcmId} = relationshipInformation || {};
    const results = [];

    let errorInDecryption = false;

        if(dateOfBirth) results.push(new Promise( resolve => {
            decrypt(dateOfBirth).then((value)=>{
                resolve({ name: 'dateOfBirth', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));
        if(ssnTin) results.push(new Promise( resolve => {
            decrypt(ssnTin).then((value)=>{
                resolve({ name: 'ssnTin', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));
        if(vcmId) results.push(new Promise( resolve => {
            decrypt(vcmId).then((value)=>{
                resolve({ name: 'vcmId', value, success: true });
            }).catch(()=>{
                resolve({success: false});
                errorInDecryption = true;
            });
        }));

    const decryptedValues = await Promise.all(results);
    decryptedValues.forEach((item) => {
        if(item.success) relationshipInformation[item.name] = item.value; 
    });

    dispatch({
        type: ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS,
        response: data,
        isDeleteAPICalled
    });

    if(errorInDecryption){
        dispatch({
            type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
            response: 'Error happened during decrypting sensitive profile information',
        });
    }
}

export const updateProfileRelationship = (payload, decrypt) => (dispatch) => {
    const { isDeleteAPICalled= false, ...restPayload} = payload;
    const env = "";
    dispatch({
        type: ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION,
    });
    makeApiRequest(
        ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION,
        restPayload,
        env,
        (response) => {
            const { data } = response;
            const { responseCode } = data;
            if (responseCode === '6000') {
                if(decrypt){
                    decryptRelationsUpdateData(decrypt, data, dispatch, isDeleteAPICalled);
                }else {
                    dispatch({
                        type: ActionTypes.UPDATE_PROFILE_RELATIONSHIP_INFORMATION_SUCCESS,
                        response: data,
                        isDeleteAPICalled
                    });
                }
            } else {
                dispatch({
                    type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
                    response: data,
                });
            }
        },
        (error) => {
            const { response } = error || {};
            const { data = {} } = response || {};
            dispatch({
                type: ActionTypes.PROFILE_RELATIONSHIP_INFORMATION_ERROR,
                response: data,
            });
        }
    );
};

export const setFamilyMemberIndex = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_FAMILY_MEMBER_INDEX,
        value,
    });
};

export const setAddressType = (addressType, value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_RELATIONSHIP_ADDRESS_TYPE,
        value,
        addressType,
    });
};

export const setIsSameAddress = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_IS_SAME_AS_MAILING_ADDRESS,
        value,
    });
};

export const setPhoneEditDetails = (value) => (dispatch) => {
  dispatch({
        type: ActionTypes.SET_PHONE_EDIT_DETAILS,
        value,
    });
};

// CLEAR EXISTING ACTION TYPES

export const clearRelationActions = () => (dispatch) => {
    dispatch({
        type: ActionTypes.RELATION_REMOVE_EXISTING_VALUES,
    });
};

// CLEAR EXISTING AND SET INITIAL STATE ACTION TYPES

export const setInitialRelationState = () => (dispatch) => {
    dispatch({
        type: ActionTypes.RELATION_SET_INITIAL_STATE,
    });
};

// CLEAR EXISTING ACTION TYPES

export const clearNewRelationActions = () => (dispatch) => {
    dispatch({
        type: ActionTypes.NEW_RELATION_REMOVE_EXISTING_VALUES,
    });
};


// SAVE RELATION PERSONAL DETAILS

export const savePersonalInformation = (personalInformation) => (dispatch) => {
    dispatch({
          type: ActionTypes.PROFILE_RELATIONSHIP_SAVE_PERSONAL_INFORMATION,
          personalInformation,
      });
};
  
// SAVE RELATION PHONE DETAILS
  
export const savePhoneInformation = (phoneInformation, isprimary, isdelete) => (dispatch) => {
dispatch({
        type: ActionTypes.PROFILE_RELATIONSHIP_SAVE_PHONE_INFORMATION,
        phoneInformation,
        isprimary,
        isdelete
    });
};
  
// SAVE RELATION EMAIL DETAILS

export const saveEmailInformation = (emailInformation, isprimary, isdelete) => (dispatch) => {
dispatch({
        type: ActionTypes.PROFILE_RELATIONSHIP_SAVE_EMAIL_INFORMATION,
        emailInformation,
        isprimary,
        isdelete
    });
};

// SAVE RELATION ADDRESS DETAILS

export const saveAddressInformation = (addressInformation, isphysical, isdelete) => (dispatch) => {
dispatch({
        type: ActionTypes.PROFILE_RELATIONSHIP_SAVE_ADDRESS_INFORMATION,
        addressInformation,
        isphysical,
        isdelete
    });
};
