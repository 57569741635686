import styled from 'styled-components';

const LinkHighlight = styled.a`
    text-decoration: underline;
    &:visited{
        color: #5D285F;
    };
    &:link{
        color:#004a98;
    };
    &:hover, 
    &:active {
        color:#fff;
        background:#004a98;
    };
`
export default LinkHighlight;