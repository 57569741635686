/**
 * BAsic Information Component
 ********************************
 *
 * @version 1.0.1
 * @author Sharanya B
 * @description This page displays list of Information available for the user to update / modify their own information
 * @createdDate [06/11/2019]
 * ***************************************
 * @createdDate Sharanya B
 * @lastModifiedDate [06/12/2019]
 * @lastModifiedReason Updated Styles (fonts , spacing between cards) for this page and added Breadcrumbs
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { VCMBasicInfoComponent, WBreadCrumb, CommonButtons } from '../../../common';
import '../../../App.css';
import styles from './securityStyles';

const assignObj = (obj) => { return obj; }

export default class SecurityFraudComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockProp: false,
    }
  }

  cardHeadreClick = e => {
    e.preventDefault();
    e.target.classList.toggle('expand');
  };

  /* 
   * Method : render
   * Description :The complete DOM will be displayed when the render method is executed
  */
  render() {
    const { blockProp } = this.state;
    // const { history } = this.props;

    return (
      <div className="MainContainer">
        {  /* 
            * Component : Header
            * Description :Displays a Header
            */
        }
        <div style={styles.containerStyle}>
          <div className="container">
            {  /* 
                * Component : WBreadCrumb
                * Description :WBreadCrumb will have breadCrumbItems and activeCrumb as props which displays a breadcrumb
                */
            }
            <WBreadCrumb key=""
              breadCrumbItems={styles.bredCrumItmes}
              activeCrumb={styles.activeName}
            />
            <VCMBasicInfoComponent
              labelvalue="Security & Fraud Alerts"
              buttonstyle={styles.disableStyle}
              block={blockProp}
            />

            <Accordion defaultActiveKey="0" id="phoneAccordion">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as="h4"
                    variant="link"
                    eventKey="0"
                    className="expand"
                    onClick={this.cardHeadreClick}
                  >
                    {styles.alerts}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0" id="cardBody">
                  <Card.Body>
                    <div>{styles.botmLine}</div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {  /* 
                * Component : VCMCardDeckComponent
                * Description :VCMCardDeckComponent com[ponent will display a header name with broder
                */
            }
          </div>
          <CommonButtons backClick={this.backClick} showSave style={assignObj({ padding: 20, })} cancelstyle={assignObj({ display: 'none' })} savestyle={assignObj({ display: 'none' })} nextstyle={assignObj({ display: 'none' })} />
        </div>
        {  /* 
            * Component : Footer
            * Description :Displays a Footer
            */
        }
      </div>
    )
  }
}

SecurityFraudComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

