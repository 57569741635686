import React from 'react';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import PropTypes from "prop-types";

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

export const StyledAlert = styled(Alert)`
    padding: 1.875rem 2.5rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: ${props => props['data-mt']}rem;
    margin-bottom: ${props => props['data-mb']}rem;
`;

const ErrorAlert = (props) => {
    const { alertMsg, mB, mT } = props;
    return (
            <StyledAlert data-mt={mT} data-mb={mB} variant="danger">
                <StyledPara>{alertMsg}</StyledPara>
            </StyledAlert>
        )
}

ErrorAlert.propTypes = {
    mB: PropTypes.number,
    mT: PropTypes.number,
    alertMsg: PropTypes.string,
};

ErrorAlert.defaultProps = {
    mB: 0,
    mT: 0,
    alertMsg: null,
};

export default ErrorAlert;