import CommonButtons from '../WCommon/CommonButtons';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';



export const BottomNavSection = props => {
    const {
        showBack,
        disabled,
        onSecondaryClick,
        onPrimaryClick,
        primaryText,
        onCancel,
        portalRoot,
        arialabelback,
        arialabelnext,
        dataAnalyticId,
        analyzecustom,
        iscancel,
        cancelClickHandler,
        cancelClick
    } = props;
    const navProps = {
        disabled,
        arialabelback,
        arialabelnext,
        dataAnalyticId,
        analyzecustom,
        iscancel,
        cancelClickHandler,
        cancelClick
    };
    if (showBack) {
        navProps.backClick = onSecondaryClick;
    } else {
        navProps.backClick = onCancel;
    }
    if(iscancel) {
        navProps.cancelClick = onCancel;
    }
    if (primaryText.toLowerCase() === 'submit') {
        navProps.submitClick = onPrimaryClick;
        navProps.submit = 'true';
        navProps.isShowBtn = true;
    } else if(primaryText.toLowerCase() === 'next') {
        navProps.nextClick = onPrimaryClick;
        navProps.isShowBtn = true;
    }else {
        navProps.nextClick = onPrimaryClick;
        navProps.isShowBtn = false;
    }
    if (portalRoot.current) {
        return ReactDOM.createPortal(<CommonButtons {...navProps} />, portalRoot.current);
    }

    return (
        <></>
    );
};

BottomNavSection.propTypes = {
    showBack: PropTypes.bool,
    disabled: PropTypes.bool,
    primaryText: PropTypes.string,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    block: PropTypes.bool,
    onPrimaryClick: PropTypes.func,
    portalRoot: PropTypes.instanceOf(Object),
    arialabelback: PropTypes.string,
    arialabelnext: PropTypes.string,
    dataAnalyticId: PropTypes.string,
    waitforapiresponse: PropTypes.bool,
    arialabelForBtn: PropTypes.string,
    nocorner: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset', 'null']),
};
BottomNavSection.defaultProps = {
    active: false,
    showBack: false,
    disabled: false,
    primaryText: 'Next',
    onCancel: () => {},
    onClick: () => {},
    onSecondaryClick: PropTypes.func,
    onPrimaryClick: PropTypes.func,
    portalRoot: null,
    arialabelback: '',
    arialabelnext: '',
    dataAnalyticId: '',
    waitforapiresponse: false,
    arialabelForBtn: '',
};

export default BottomNavSection;
