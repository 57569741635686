import React, { Component } from 'react';
import PropTypes from 'prop-types';
import consts from './consts';
import { CommonButtons,WBreadCrumb,  Accordian, AccordianTitle, FundAccountCard  } from '../../../common';
import styles from '../AccountMessagingMainScreen/profileStyles';
// import PurchaseAccounts from './PurchaseAccount';
// import WAccordian from '../../AccountOptions/CommonComponents/WAccordian';
// import AccountTypeAccordianTitle from '../../AccountOptions/CommonComponents/AccountTypeAccordianTitle';
// import AccountCardComponent from '../../AccountOptions/CommonComponents/WAccountCard';

const assignObj = (obj) => { return obj; }

const breadCrumbValues = [{ name: 'Home', url: '/' },{name: 'Profiles & Preferences', url:'/profile'}, {name:'Account Messaging', url:'/accountmessaging'}];

class GeneralDocumentsComponent extends Component {
    constructor(props) {
        super(props);
        // const { pageIndex } = this.props;
        this.state = {
            accountsAvailable: [],
            checkboxRequired: false,
            purchaseText: 'This is sample content paragraph',
            selected: false,
            displayDetails: {
                accountSelection: {
                    titleTxt: 'Account Selection',
                    editTxt: 'Edit',
                   
                    data: [],
                },
            },
            accountSelectedType:[],
            accountSelected: null
        };

        this.accountSelectionRef = React.createRef();
    }

    // componentDidMount() {
    //     this.setState(() => {
    //         return {
    //             accountsAvailable: consts.accountsAvailable,
    //         };
    //     });
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { savedState } = nextProps;

        // console.log('@debug for accOpeningReducerData ',JSON.stringify(purchaseReducerData));
        // console.log('@debug for FundListData ', fundsData({
        //     companyId: '591',
        // }));

        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
            };
        }
        return null;
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const { savedState, editSection } = this.props;
        const { current } = this.accountSelectionRef;
        const { offsetTop = 0 } = current || {};
        if (editSection === 'Account Selection') {
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
       
       // console.log(funds.data);    

        if (!savedState) {
            // const accountsAvailable = consts.accountsAvailable;
            this.setState(() => {
                return {
                    accountsAvailable: consts.accountsAvailable,
                };
            });
        }

    }

    componentWillUnmount() {
        const { saveChildrenState, pageIndex } = this.props;
        saveChildrenState(this.state, pageIndex);
    }

    // nextHandler = () => {
    // const { history } = this.props;
    //     if (history) {
    //         history.push({
    //             pathname: '/purchaseInvestmentSelection'
    //         });
    //     }
    // }

    accountClickHandler = (accounttypeindex, accountindex) => e => {
        e.stopPropagation();
        this.setState(prevState => {
            const { accountsAvailable, displayDetails } = prevState;
            const { accountSelection } = displayDetails;
            let accountSelected = null;
            let accountSelectedType = null;
            const displayData = [];
           //  console.log(x ,'@inside', y);
           // this.setState({selected:true});
           const allAccounts = accountsAvailable.map((item, acctypeindex) => {
            const accType = { ...item };
            const { accounts } = accType;
            accType.accounts = accounts.map((eachacc, accindex) => {
                const acc = { ...eachacc };
                if (accounttypeindex === acctypeindex && accountindex === accindex) {
                    acc.checked = !acc.checked;
                } else {
                    acc.checked = false;
                }
                if (acc.checked) {
                    accountSelected = acc;
                    displayData.push(
                        {
                            field: 'Account Name',
                            value: acc.accName,
                        },
                        {
                            field: 'Account Number',
                            value: acc.accNumber.value,
                        },
                    );
                }
                return acc;
            });
            return accType;
        });
        accountSelection.data = displayData;
        accountSelectedType = accountsAvailable[+accounttypeindex];
        // this.setState({accountsAvailable: allAccounts, displayDetails.accountSelection.data : displayData});
        // console.log('@debug for account selected ',JSON.stringify(accountSelected));
        // console.log('@debug for display Details ',JSON.stringify(accountSelectedType));
        return {
            accountsAvailable: allAccounts,
            accountSelected,
            displayDetails,
            accountSelectedType
        };
        });
      
    
    }

    bindComponent() {
        const { accountsAvailable } = this.state;
            // return <PurchaseAccounts 
            //             accountsAvailable={accountsAvailable}
            //             checkboxRequired={checkboxRequired}
            //             clicked = { () => this.accountClickHandler(x,y)}
            //             selected = {selected}
            //             />;
        let cardDetails;
        return (
                <>
                {
                accountsAvailable.map((item, acctypeindex) => (
                <Accordian
                    ref={this.accountSelectionRef}
                    key={item.accountType}
                    titleChild={<AccordianTitle accType={item.accountType} />}
                    titleSeperator
                >
                  
                    <div>
                        {item.accounts.map((eachacc, accindex) => {
                            cardDetails = [];
                            cardDetails.push(eachacc.taxDoc,eachacc.report, eachacc.privacy);
                            return (
                            <FundAccountCard
                                key={eachacc.accName}
                                accountName={eachacc.accName}
                                accountNumber={eachacc.accNumber.value}
                                onClick = {this.accountClickHandler(acctypeindex, accindex) }     
                                selected={!!eachacc.checked}    
                                details={cardDetails}   
                            />
                        )})}
                    </div>
                </Accordian>
            ))
                        }
        
                </>)
    }
    
    render() {
        const { purchaseText } = this.state;
        return(

          <div className="MainContainer">
        {  /* 
        * Component : Header
        * Description :Displays a Header
        */
        }
        <div style={styles.containerStyle}>
          <div className="container">
            <WBreadCrumb
              breadCrumbItems={breadCrumbValues}
              activeCrumb="General Doucments"
            />
            <div>
                <p style={consts.purchaseText}>{purchaseText}</p>
                {this.bindComponent()}
                <hr />
            </div>
          </div>
        </div>
        <CommonButtons backClick={this.backClick}  showSave style={assignObj({ padding: 20, })} cancelstyle={assignObj({ display: 'none' })} savestyle={assignObj({ display: 'none' })} nextstyle={assignObj({display:'none'})} />
        {  /* 
        * Component : Footer
        * Description :Displays a Footer
        */
        }
      </div>
            
        )
    }
}

GeneralDocumentsComponent.propTypes = {
    saveChildrenState: PropTypes.func,
    savedState: PropTypes.instanceOf(Object),
    pageIndex: PropTypes.number.isRequired,
    editSection: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};
GeneralDocumentsComponent.defaultProps = {
    saveChildrenState: () => {},
    savedState: null,
    editSection: null,
};

export default GeneralDocumentsComponent;