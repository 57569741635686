import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPrimaryEmail, setSecondaryEmail, setPrimaryEmailError, setSecondaryEmailError } from 'shared/Actions/ProfileRelationAction';
import { InputText, EmailCard, SectionTitleWithSeparator } from "modules/CustomerManagementModule/components";
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import { isValidEmail } from 'modules/CustomerManagementModule/utils';

const setEmail = (dispatch, action) => (e)=>{
    const { target } = e;
    const { value } = target;
    dispatch(action(value));
}

const validateEmail = (dispatch, action) => (e) => {
	const { target } = e;
    const { value } = target;
    let error = '';
    if (value && !isValidEmail(value)) {
        error = "Please enter valid Email Address.";
    }
    dispatch(action(error));
};

const EmailCardComponent = () => {
	
	const emailInfo = useSelector((state) => {
        const { profileRelationshipData } = state;
        const { relationEmailInformation } = profileRelationshipData;
        return relationEmailInformation;
    });
    
    const { primaryEmail, secondaryEmail, primaryEmailError, secondaryEmailError } = emailInfo;
    
    const dispatch = useDispatch();
    
	return (
		<>
			<SectionTitleWithSeparator titleTxt={CONST.EMAIL_TITLE} optional />
			<Row>
				<Col xs md={8}>
					<EmailCard 
						labelFor="vcm-cms-relation-primary-email" 
						emailTitle={CONST.PRIMARY_EMAIL_TITLE} 
						value={
							<InputText 
								id="vcm-cms-relation-primary-email" 
								name={CONST.EMAIL} 
								value={primaryEmail}
								onChange={setEmail(dispatch, setPrimaryEmail)}
								onBlur={validateEmail(dispatch, setPrimaryEmailError)}
								maxLength={50}
                                error={!!primaryEmailError}
								errorTxt={primaryEmailError}
								aria-label={CONST.AX_ADDEMAILID_TXT}
								data-form-field
								data-name={CONST.PRIMARY_EMAIL_TITLE}
							/>
						} 
					/>
					<EmailCard 
						labelFor="vcm-cms-relation-secondary-email" 
						emailTitle={CONST.SECONDARY_EMAIL_TITLE} 
						value={
							<InputText id="vcm-cms-relation-secondary-email" 
								name={CONST.EMAIL} 
								value={secondaryEmail}
								onChange={setEmail(dispatch, setSecondaryEmail)}
								onBlur={validateEmail(dispatch, setSecondaryEmailError)}
								maxLength={50}
                                error={!!secondaryEmailError}
								errorTxt={secondaryEmailError}
								aria-label={CONST.AX_ADDEMAILID_TXT}
								data-form-field
								data-name={CONST.SECONDARY_EMAIL_TITLE}
							/>
						} 
					/>
				</Col>
			</Row>
		</>
	)
}

export default EmailCardComponent