export const ARIA_BACK_BUTTON_LABEL = 'Back To Account Type';

export const breadCrumbText = {
    BREAD_CRUMB_1_NAME: 'Home',
    BREAD_CRUMB_2_NAME: 'Open an Account',
};

export const TITLE_TEXT = ' Application';

export const GEN_INSTRUCTIONS =
    'Access and submit your account applications here. Once your application is uploaded, we will let you know when we receive it and keep you informed every step of the way.';

export const steps = {
    S1: {
        NUMBER: '1',
        HEADING: 'Download the Form',
        INSTRUCTIONS:
            'Review the descriptions below to determine which account type is suitable for you and then indicate that on the form',
    },
    S2: {
        NUMBER: '2',
        HEADING: 'Copy and Paste Your Member Number',
        INSTRUCTIONS:
            'Please include your Member Number on the new account application. We’ve made it easy for you to copy and paste this directly into the form.',
    },
    S3: {
        NUMBER: '3',
        HEADING: 'Submit Your Form and Supporting Documents',
    },
};

export const memberNumberCardConstants = {
    HEADING: 'Member Number',
    LINK: 'Copy',
    BODY: 'Please make a note of your Member Number. You may need it later when filling the form.',
};

export const mailToCardConstants = {
    HEADING: 'Mail To:',
};

export const NO_RECORDS_FOUND = 'Oops... No forms were found.';

export const addresses = {
    '403b': {
        ADDRESSEE: 'USAA Funds 403B',
        STREET_NUMBER: 'PO Box# 182904',
        CITY_STATE_ZIP: 'Columbus Ohio 43218-2904',
    },
    other: {
        ADDRESSEE: 'USAA Mutual Funds',
        STREET_NUMBER: 'PO Box# 182903',
        CITY_STATE_ZIP: 'Columbus Ohio 43218-2903',
    },
};

export const formIds = {
    bene_ira:          { formId: '134595' },
    guard_ira:         { formId: '47545' },
    rollover:          { formId: '99531' },
    trust:             { formId: '47773' },
    estate:            { formId: '47772' },
    opt_ret_program:   { formId: '26258' },
    '403b':            { formId: '26300' },
    simple:            { formId: '99266S' },
    transfer_on_death: { formId: '47772' },
    conserve:          { formId: '47772' },
    guardian:          { formId: '47772' },
    com_prop:          { formId: '47772' },
    pension:           { formId: '47772' },
    profit_share:      { formId: '47772' },
    sole_prop:         { formId: '47772' },
    invest_club:       { formId: '47772' },
    broker:            { formId: '47772' },
    financial:         { formId: '47772' },
    biz_corp:          { formId: '47772' },
};
