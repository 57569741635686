import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {loginActions, localStateManagementActions} from '../../../../shared/Actions';
import RegisterEmail from './RegisterEmail';

const mapStateToProps = (state) => (
    {
        loginState: state.loginData,
        registerState: state.localStateManagementReducerData.registerState
    }
);

const mapDispatchToProps = {
    ...localStateManagementActions,
    ...loginActions
};

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(RegisterEmail));