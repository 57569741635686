import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Separator from '../Separator';

export const SectionTitle = styled.h2`
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0px;
    color: #486D90;
    opacity: 1;
    margin: 0px;
`;

const AdditionalTxt = styled.span`
    margin-left: 5px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #56565A;
    opacity: 1;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const OPTIONAL_TEXT = "[ Optional ]";

const SectionTitleWithSeparator = (props) =>{
    const { titleTxt, optional } = props
    return(
        <>
            <FlexDiv>
                <SectionTitle>{titleTxt}</SectionTitle>
                {optional && <AdditionalTxt>{OPTIONAL_TEXT}</AdditionalTxt>}
            </FlexDiv>
            <Separator mT={0.125} aria-hidden="true" />
        </>
    );
}

SectionTitleWithSeparator.propTypes = {
    titleTxt: PropTypes.string,
    optional: PropTypes.bool,
};

SectionTitleWithSeparator.defaultProps = {
    titleTxt: '',
    optional: false
};

export default SectionTitleWithSeparator;