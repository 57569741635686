import components from './components';

const theme = {
    components,
    space: [0, 4, 8, 16, 32, 64],
    fontSizes: [12, 14, 16, 20, 24, 32],
    colors: {
        white: '#FFFFFF',
        reds: [],
        greens: ['#8CBF26'],
        blues: ['#486D90', '#4F799F'],
        whites: ['#F7F7F7', '#E6E6E6'],
        greys: ['#56565A'],
    },
    fonts: {
        bentonSans: 'benton-sans,sans-serif',
        yorktenSlab: 'yorkten-slab-normal,sans-serif',
        sansSerif: 'sans-serif',
    },
    fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    lineHeights: [],
    letterSpacings: [],
    sizes: [],
    borders: {
        headingUnderline: '2px solid #8BC105;',
    },
    borderWidths: [],
    borderStyles: {},
    radii: [0],
    shadows: {},
    zIndices: [],
    transitions: {},
    breakpoints: ['40em', '52em', '64em', '80em'],
};

theme.fontSizes.large = 30;
// Default button sizes
theme.sizes.buttonWidth = 160;
theme.sizes.buttonHeight = 50;

export default theme;
