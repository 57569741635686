const subTitleColor = 'rgba(73, 73, 74, 1)';

const Styles = {
    subtitle: {
        color: subTitleColor,
        font: '500 14px/1.25em benton-sans,sans-serif',
        marginBottom: 12,
    },

    subTitleHeading: {
        color: subTitleColor,
        font: '500 16px/24px benton-sans,sans-serif',
        letterSpacing: '0px',
    },

    greetmsg: {
        color: 'rgb(72, 109, 144)',
        font: '500 20px/24px yorkten-slab-normal',
        paddingBottom: 10,
        display: 'inline-block',        
    },

    ContainerBg: {
        background: '#F7F7F7 0% 0% no-repeat padding-box',
        paddingBottom: '50px',
        borderBottom: '2px solid #D2D2D2',
        opacity: 1,
    },
};

export default Styles;
