import plusIcon from 'assets/plusIcon.png';

const data = {
    questions: [
        { id: 'question1', label: 'Question 1', answerId: 'answer1', answerLabel: 'Answer 1' },
        { id: 'question2', label: 'Question 2', answerId: 'answer2', answerLabel: 'Answer 2' },
        { id: 'question3', label: 'Question 3', answerId: 'answer3', answerLabel: 'Answer 3' },
    ],
    addEmailStyle: {
        color: '#004A98',
        font: '800 16px/20px yorkten-slab-normal',
        marginBottom: '30px',
        backgroundColor: 'transparent',
        border: 'none',
        paddingLeft: 25,
        float: 'right',
        background: `url(${plusIcon}) no-repeat left`,
        textTransform: 'capitalize'
    },
    plus: {
        position: 'relative',
        width: '13px',
        display: 'inline-block',
        height: '13px',
        marginRight: '10px'
    },
    plusAfter: {
        position: 'absolute',
        width: '13px',
        height: '3px',
        backgroundColor: '#8BC105',
        top: '6px'
    },
    plusBefore: {
        position: 'absolute',
        width: '13px',
        height: '3px',
        backgroundColor: '#8BC105',
        transform: 'rotate(90deg)',
        top: '6px'
    },
    addEmailText: 'Add Additional Email',
    selectStyle: {paddingBottom: 10},
    answerStyle: {paddingBottom: 40},
    optionalMessage: '* All fields are required unless mentioned optional'
}

export default data;