/**
 * General Account Preferences Component
 ****************************************
 *
 * @version 1.0.1
 * @author HARI UG
 * @description This component allows user to manage and view  account preferences for different accounts
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [16/04/2020]
 * @lastModifiedBy Lalitha
 * @lastModifiedReason Code Refactoring and added VD styles
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
    WRadioList,
} from '../../../common';
import AccountInfo from './AccountInfo';
import CONSTANTS from "./consts";
import AccGlobalConstants from '../AccountManagementConstants';
import '../EditGeneralAccountPreferences/EditGeneralAccountPreferences.css';
import {
    StyledPageWrapper,
    StyledPageHeading,
    StyledSubPara,
    StyledCard,
    StyledOptionalText,
    StyledCardBody,
    StyledDocReceptionQ,
} from './styles';
import { GET_CUSTOMER_PROFILE_SUCCESS } from 'shared/ReduxConstants/ServiceActionConstants';
import { getSessionValues } from 'shared/Helpers/Utils';

export default class GeneralAccountPreferencesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            docReceptionQ: 'docReceptionQPaper',
            isBusinessUser: false,
        };
    }

    componentDidMount() {
        const { getOpenedAccountsPreferences, accountPreferenceData: { accountsOpened }, getCustomerProfile } = this.props;
          
        const params = getSessionValues() 
        const {accesstoken, vcmid, fismemberid,msrid} = params;
        const msrParams = {
            accesstoken,
            vcmid,            
            fismemberid,
            msrid
        } 
        getOpenedAccountsPreferences(msrParams);
        const payLoad = {
            payloadData : {"loginLatitude":"","loginLongitude":"","deviceId":""}};
        getCustomerProfile(payLoad);
    }

    handleEditAccountPreferences = ({ accountType, account, accounts }) => () => {
        const { history, setAccountPreferences } = this.props
        const { isBusinessUser } = this.state
        setAccountPreferences({ accountNumber: account.accNumber, accountType })
        history.push({
            pathname: '/editPreference',
            state: {
                isBusinessUser: isBusinessUser
            }
        });
    }

    cardHeaderClick = (e) => {
        e.preventDefault();
        e.target.classList.toggle('expand');
    };

    handleRadioChange = e => {
        this.setState({ docReceptionQ: e.target.value })
    }

    getDeliveryOptionsForAllAccounts = () => {
        const { docReceptionQ } = this.state;
        const { accountPreferenceData: { accountsOpened } } = this.props
        let deliveryOptionsForAllAccountsArray = []
        accountsOpened.map((accountType) => {
            accountType.accounts.map((account) => {
                const option = docReceptionQ === 'docReceptionQOnline' ? 'E' : 'P'
                if (account.ownerType === "P") {
                    return deliveryOptionsForAllAccountsArray.push(
                        {
                            "deliveryPreferences": {
                                "groupId": account.deliveryPreferences.groupId,
                                "dlvMethStmt": option,
                                "dlvMethConfirms": option,
                                "dlvMethLit": option,
                                "dlvMethAcctUpdNotify": option,
                                "dlvMethProxies": option,
                                "dlvMethTaxForms": option
                            }
                        }
                    )
                }
            })
        })

        return deliveryOptionsForAllAccountsArray
    }

    renderContent = () => {
        const { accountPreferenceData: { accountsOpened } } = this.props;
        if (accountsOpened && Array.isArray(accountsOpened)) {
            return accountsOpened.map((account) => {
                return (
                    <Accordion defaultActiveKey="0" key={`${account.accountType}`}>
                        <StyledCard>
                            <Card.Header>
                                <Accordion.Toggle
                                    variant="link"
                                    eventKey="0"
                                    className="expand accordionHeader buttonAccordion"
                                    onClick={this.cardHeaderClick}
                                    aria-controls="cardBody"
                                    id={account.accountType.replace(/\s+/g, '')}
                                    style={AccGlobalConstants.assignObj({ cursor: 'pointer' })}
                                    tabIndex='0'
                                >
                                    {account.accountType}
                                    {account.accountDesc && <StyledOptionalText>{account.accountDesc}</StyledOptionalText>}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse
                                eventKey="0"
                                id="cardBody"
                                role="region"
                                aria-labelledby={account.accountType.replace(/\s+/g, '')}
                            >
                                <StyledCardBody>
                                    {account.accounts.map((accnt) => (
                                        <AccountInfo
                                            key={`${accnt.accNumber}_${accnt.accName}`}
                                            editClick={this.handleEditAccountPreferences({
                                                accountType: account.accountType,
                                                account: accnt, 
                                                accounts: account.accounts
                                            })}
                                            account={accnt}
                                        />)
                                    )}
                                </StyledCardBody>
                            </Accordion.Collapse>
                        </StyledCard>
                    </Accordion>
                )
            });
        } 
        return (<div></div>)
        
    };

    renderPage = () => {
        const { docReceptionQ = "E" } = this.state;
        const radioItems = CONSTANTS.deliveryValues.value.map(item => {
            return {
                ...item,
                disabled: this.state.isBusinessUser && 'Online' === item.key
            }
        });
        return (
            <>
                <StyledPageWrapper>
                    <div className="container">
                        <WBreadCrumb breadCrumbItems={CONSTANTS.generalPreferencesUrls} activeCrumb={CONSTANTS.activeGeneralUrl} />
                        <StyledPageHeading>{CONSTANTS.pageHeading}</StyledPageHeading>
                        <StyledSubPara><span>{CONSTANTS.para1}</span><span>{CONSTANTS.para2}</span><span>{CONSTANTS.para3}</span></StyledSubPara>
                        <StyledDocReceptionQ >
                            <div className="radioListDocQWrapper" role="radiogroup" aria-labelledby="docReceptionQ">
                                <label htmlFor="docReceptionQ">
                                    {CONSTANTS.applyAllText}
                                    <span>{AccGlobalConstants.optionalText}</span>
                                </label>
                                <WRadioList
                                    id="docReceptionQ"
                                    radioFor="docReceptionQ"
                                    radioItems={radioItems}
                                    onChange={this.handleRadioChange}
                                    selectedValue={docReceptionQ}
                                    radiostyles={CONSTANTS.radiostyles}
                                />
                            </div>
                        </StyledDocReceptionQ>
                        {this.renderContent()}
                    </div>
                </StyledPageWrapper>
                <hr />
            </>
        );
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { loginData } = nextProps;
        if( GET_CUSTOMER_PROFILE_SUCCESS === loginData.type ) {
            if(loginData.getCustomerProfile && loginData.getCustomerProfile.isBusinessEntity) {
                return {
                    isBusinessUser: loginData.getCustomerProfile.isBusinessEntity
                }
            }
        }

        return null;
    }

    render() {
        const { accountPreferenceData: { isLoading, isError} } = this.props;

        return (
            <div className="MainContainer">
                {<WSpinner loading={isLoading} />}
                {!isError && this.renderPage()}
            </div>
        );
    }
}

GeneralAccountPreferencesComponent.propTypes = {
    getOpenedAccountsPreferences: PropTypes.func.isRequired,
    accountPreferenceData: PropTypes.instanceOf(Object),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

GeneralAccountPreferencesComponent.defaultProps = {
    accountPreferenceData: {}
};
