import React from 'react';
import data from './Constants';

const getAttemptsMessage = number => {
    return (
        <span>
            {data.invAttempt}
            {data.youHave}
            <strong className="strongMessage">{number}</strong>
            {number > 1 ? data.attempts : data.attempt}
        </span>
    );
};

export default getAttemptsMessage;