/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the personal Info card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import consts from './Consts';

function generateContactInfoCard(mailingAdd, physicalAdd, heading) {
    return {
        cardId: heading !== undefined ? `jointContactInfo` : 'contactInfo',
        linkText: 'Edit',
        cardHeading: heading !== undefined ? `Contact Information-${heading}` : 'Contact Information',
        fields: [
            {
                uniqueKey: 'contactInfoAddressUniqueKey',
                key: 'contactInfoAddressKey',
                mailingAddressLabel: 'Mailing Address',
                physicalAddressLabel: 'Physical Address',
                uspsAdresslabel: 'USPS Suggested Address',
                hintText1: 'The address you entered may be incorrect or incomplete',
                hintText2: "Please review our suggestion below, and chose which version you'd like to use.",
                mailingAddress: mailingAdd,
                physicalAddress: physicalAdd,
                type: 'contactInfo',
            },
        ],
    };
}

const { staticfield } = consts;

const yesOrNo = (cond, yes, no) => {
    return cond ? yes : no;
};

const basicDetailsInfo = (perInfo, masterLookupStateData) => {
    let genderData = '';
    let maritalData = '';
    let citizenshipData = '';
    if (perInfo.gender) {
        const genderList = masterLookupStateData['gender'.toString()].value;
        genderData = genderList.find(item => item.key === perInfo.gender).value;
    }
    if (perInfo.maritalLocal) {
        const maritalList = masterLookupStateData['marital_status'.toString()].value;
        maritalData =
            maritalList.find(item => item.value === perInfo.maritalLocal) &&
            maritalList.find(item => item.value === perInfo.maritalLocal).value;
    }
    if (perInfo.citizenship) {
        const citizenshipList = masterLookupStateData['citizenship'.toString()].value;
        citizenshipData =
            citizenshipList.find(item => item.key === perInfo.citizenship) &&
            citizenshipList.find(item => item.key === perInfo.citizenship).value;
    }
    return { genderData, maritalData, citizenshipData };
};

const addressDetails = (perInfo, heading) => {
    let mailingAddress = [];
    let physicalAddress = [];
    const physicalAddressObject = yesOrNo(
        heading !== undefined,
        perInfo.jointPhysicalAddress,
        perInfo.primPhysicalAddress,
    );
    const mailingAddressObject = yesOrNo(heading !== undefined, perInfo.jointMailAddress, perInfo.primMailAddress);
    if (mailingAddressObject) {
        mailingAddress = `${mailingAddressObject.lineOne}${mailingAddressObject.lineOne !== '' ? ',' : ''}${
            mailingAddressObject.lineTwo
        },${mailingAddressObject.cityName},${mailingAddressObject.stateName}-${mailingAddressObject.zipCode}`;
        if (mailingAddress === ',,,-') {
            mailingAddress = consts.None;
        }
    }
    if (perInfo.sameAddress === 'Y') {
        physicalAddress = mailingAddress; // 'Same as Primary Address';
    } else if (physicalAddressObject) {
        physicalAddress = `${physicalAddressObject.lineOne}${physicalAddressObject.lineOne !== '' ? ',' : ''}${
            physicalAddressObject.lineTwo
        },${physicalAddressObject.cityName},${physicalAddressObject.stateName}-${physicalAddressObject.zipCode}`;
        if (physicalAddress === ',,,-') {
            mailingAddress = consts.None;
        }
    }
    return { mailingAddress, physicalAddress };
};

// const emailDestails = (perInfo, heading) => {
//     const primaryEmail = [];
//     const secondaryEmail = [];
//     const emailAddressArray = yesOrNo(
//         heading !== undefined,
//         perInfo.jointEmailAddressData && perInfo.jointEmailAddressData.emailAddress,
//         perInfo.emailAddressData && perInfo.emailAddressData.emailAddress,
//     );
//     if (emailAddressArray && emailAddressArray.length > 0) {
//         for (let i = 0; i < emailAddressArray.length; i += 1) {
//             const item = emailAddressArray[+i];
//             if (item !== null) {
//                 const isPrimary = item.isPrimary === true;
//                 const label = yesOrNo(isPrimary, 'Primary Email', 'Secondary Email');
//                 const field = {
//                     uniqueKey: `emailAddress${i}`,
//                     key: label,
//                     value: item.email,
//                     type: staticfield,
//                 };
//                 if (isPrimary) {
//                     primaryEmail.push(field);
//                 } else {
//                     secondaryEmail.push(field);
//                 }
//             }
//         }
//     }
//     return { primaryEmail, secondaryEmail };
// };

export default function generatePersonalInfoCard(masterLookupStateData, perInfo, nickName, heading) {
    const personalcards = [];
    let personalInfoCard = {};
    const { dob, gender, maritalStatus, citizenship, ssn, nickNameLabel } = consts;
    if (perInfo) {
        const { mailingAddress, physicalAddress } = addressDetails(perInfo, heading);
        const { genderData = '', maritalData = '', citizenshipData = '' } = basicDetailsInfo(
            perInfo,
            masterLookupStateData,
        );
        personalInfoCard = {
            cardId: yesOrNo(heading !== undefined, 'jointPersonalInfo', 'personalInfo'),
            linkText: 'Edit',
            cardHeading: yesOrNo(heading !== undefined, consts.jointOnwerPerInfo, consts.perInfoHeading),
            fields: [
                {
                    uniqueKey: 'nickName',
                    key: nickNameLabel,
                    value: nickName || consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'name',
                    key: consts.name,
                    value: `${perInfo.firstName} ${perInfo.lastName}`,
                    type: staticfield,
                },
                {
                    uniqueKey: 'dateOfBirth',
                    key: dob,
                    value: perInfo.dateOfBirth,
                    type: staticfield,
                },
                {
                    uniqueKey: 'gender',
                    key: gender,
                    value: genderData,
                    type: staticfield,
                },
                {
                    uniqueKey: 'maritalStatus',
                    key: maritalStatus,
                    value: maritalData,
                    type: staticfield,
                },
                {
                    uniqueKey: 'citizenship',
                    key: citizenship,
                    value: citizenshipData,
                    type: staticfield,
                },
                {
                    uniqueKey: 'ssnTin',
                    key: ssn,
                    value: yesOrNo(
                        heading !== undefined,
                        perInfo.jointSSNData && perInfo.jointSSNData.maskedFullSSN,
                        perInfo.ssnData && perInfo.ssnData.maskedFullSSN,
                    ),
                    type: staticfield,
                },
            ],
        };
        personalcards.push(personalInfoCard);
        const { edit, phJoint, phInfo, emJoint, emInfo } = consts;

        if (heading !== undefined) {
            const generatedJointContactCard = generateContactInfoCard(mailingAddress, physicalAddress, heading);
            personalcards.push(generatedJointContactCard);
        } else {
            const generatedContactCard = generateContactInfoCard(mailingAddress, physicalAddress, heading);
            personalcards.push(generatedContactCard);
        }
        const { primaryPhoneDetails, secondaryPhoneDetails } = perInfo;
        const { primaryEmailDetails, secondaryEmailDetails } = perInfo.emailAddressData;

        const formatPhoneNumber = phoneNumberInitial => {
            if (phoneNumberInitial && phoneNumberInitial.length === 10) {
                let formattedPhoneNumber = phoneNumberInitial.replace(/\d(?=\d{4})/g, 'X');
                if (formattedPhoneNumber && formattedPhoneNumber.toString().trim().length > 0) {
                    formattedPhoneNumber = formattedPhoneNumber.replace(/[-\s()]/g, '');
                    if (formattedPhoneNumber.length === 10) {
                        const stateCode = formattedPhoneNumber.substr(0, 3);
                        const areaCode = formattedPhoneNumber.substr(3, 3);
                        const phoneNumber = formattedPhoneNumber.substr(6, 4);
                        formattedPhoneNumber = `${stateCode}-${areaCode}-${phoneNumber}`;
                    } else {
                        formattedPhoneNumber = '';
                    }
                }
                return formattedPhoneNumber;
            }
            return phoneNumberInitial && phoneNumberInitial.replace(/\d(?=\d{4})/g, 'X');
        };

        const phoneInfoCard = {
            cardId: yesOrNo(heading !== undefined, 'jointPhoneInfo', 'phoneInfo'),
            linkText: edit,
            cardHeading: yesOrNo(heading !== undefined, phJoint, phInfo),
            fields: [
                {
                    uniqueKey: 'primaryPhone',
                    key: 'Primary Phone',
                    value: `${primaryPhoneDetails.countryCode} ${formatPhoneNumber(primaryPhoneDetails.phoneNumber)}`,
                    type: staticfield,
                },
                {
                    uniqueKey: 'secondaryPhone',
                    key: 'Secondary Phone',
                    value: `${
                        secondaryPhoneDetails.phoneNumber ? secondaryPhoneDetails.countryCode : ''
                    } ${formatPhoneNumber(secondaryPhoneDetails.phoneNumber) || consts.None}`,
                    type: staticfield,
                },
            ],
        };
        personalcards.push(phoneInfoCard);
        // let emailFields = [];
        // const { primaryEmail = [], secondaryEmail = [] } = emailDestails(perInfo, heading);
        const emailFields = [
            {
                uniqueKey: 'primaryEmail',
                key: 'Primary Email',
                value: primaryEmailDetails.email,
                type: staticfield,
            },
            {
                uniqueKey: 'secondaryEmail',
                key: 'Secondary Email',
                value: secondaryEmailDetails.email,
                type: staticfield,
            },
        ];
        const emailInfoCard = {
            cardId: yesOrNo(heading !== undefined, 'jointEmailInfo', 'emailInfo'),
            linkText: 'Edit',
            cardHeading: yesOrNo(heading !== undefined, emJoint, emInfo),
            fields: emailFields,
        };
        personalcards.push(emailInfoCard);
    }
    return personalcards;
}
