/**
 * Dashboard Screen Component Styles and Constants
 ********************************
 *
 * @version 1.0.1
 * @author Numaan
 * @description The styles and constants required for the component will be taken from this file
 * @createdDate [11/03/2020]
 * @createdBy Numaan
 * ***************************************
 * @lastModifiedDate [10/06/2020]
 * @lastModifiedBy Lalitha K
 * @lastModifiedChanges added Stop Code Processing
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CardDeck, Col, Row, Nav,Button, Collapse } from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';

import moment from 'moment-timezone';
import { get, has, isLength, isEmpty, isEqual, isObject, orderBy, chain } from 'lodash';
import { WSpinner,WMessageBar,ConfirmModal,ConfirmModalFixedWidth } from '../../../common';
import SideHeader from '../Common/SideHeaderMain/SideHeader';
import axios from 'axios';
import WCardOverlay from '../Common/WCardOverlay/WCardOverlay';
import HighChart from './HighChart';
import iconAllocation from '../../../assets/icon_allocation.png';

import iconPortfolio from '../../../assets/icon_portfolio.png';

import styles from './accountstyle';

import '../Common/Styles.css';

import AccordionTitleContent from '../Common/AccordionTitleContent/AccordionTitleContent';
import traingleicon from '../../../assets/alert.png';
import AccordionContent from '../Common/AccordionContent/AccordionContent';
import accountHolderCardData from './AccountHolderConstants';
import { closedApplicationPopUpMessage, closedEducationApplicationPopUpMessage } from './Constants';
import constant from '../Common/AccountDashboardConstants';
import StopCodeAlert from '../Common/StopCodeAlert/StopCodeAlert';
import NewDashboard from './NewDashboard';
import HighChartErrorBoundary from './HighChartErrorBoundary';
import { Redirect } from 'react-router-dom';
import { APP_VALUES,TRANSACTION_TYPE } from 'common/Constants';
import { LocalStorageHelper, SessionStorageHelper } from '../../../utils/sessionHelper';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
import { convert_CST_Timezone } from 'utils/utils';
const apiClosedAccountStatusCode = '9'
const apiClosedAccountStatus = 'C'
const CLOSED_ACCOUNT = 'Closed Accounts'
class AccountHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // saveDateShow: 'true',
            hcgValues: [],
            totalAmount: 0,
            highchartData: [],
            mfAccountData: [],
            brAccountsData:[],
            daAccountsData: [],
            childrenAccountsData:[],
            graphPlottingValues: [],
            marstoneClick: false,
            showStopCode: false,
            isError: false,
            message: '',
            latestAggregatedBalanceDate: '',
            accountSummaryValuesProcessed: false,
            chartLoading: false,
            spinner: false,
            isAscensus: false,
            isFIS: false,
            isFISAscensus: false,
            systemHealthDown : false,
            systemHealthDownMessage : '',
            isSystemHealthDataAvailable: false,
            dashbordContentData:[],
            dashboardErrorObj: {
                isFunds : false,
                isEducation : false,
                isMarket: false,
                isDynamicAdvisor:false,
            },
            systemHealth:{
                fis:"",
                pershing:"",
                ascensus:""
            },
            renderCount:0,
            mfAccountRenderCount:0,
            brAccountRenderCount:0,
            childrenAccountRenderCount:0,
            daAccountRenderCount: 0,
            savedItemsForDashbord:[],
            savedItemsListrendercount:0,
            savedItemModal:false,
            isLoading : false,
            renderCountSavedItem: 0,
            profileStatusSaveCount : 0,
            removeAccountType:"",
            transactionAction:"",
            tradeLocation:null,
            transactionComformationModel:false,
            allowedTransactionsMessage: "",
            hasDynamicAccounts:false,
            isApplicationClosed: false,
            isEducationApplicationClosed: false,

        };
        this.accountHolderRef = React.createRef();
    }

    componentDidMount() {
        const {
            clearLocalStateForNAO,
            clearLocalStateForAccOpening,
            getPhoneSupportHoursOfOperation,
            resetAllNewMemberDashbaordData,
            resetAllMemberDashbaordData,
            clearMarketNAOInfo,
            resetTransactionHolder,
            clearMasterRegInfo,
            manageLocalState,
            localStateData,
            getSystemHealth
        } = this.props;


        clearMarketNAOInfo();
        resetTransactionHolder();
        clearMasterRegInfo();

        if(SessionStorageHelper.getItem('msr_dts')){
            const params = getSessionValues()
            const { vcmid } = params;

            this.setState({
                vcmId:vcmid
            })

        }
        clearLocalStateForNAO();
        clearLocalStateForAccOpening();
        resetAllNewMemberDashbaordData();
        resetAllMemberDashbaordData();
         this.setState(state => ({
            ...state
        }));

        if(SessionStorageHelper.getItem('msr_dts')){
            this.props.getEnabledFeature()
            getSystemHealth();
        }

        const {
            displayStopCodeInDashboard = false
        } = localStateData || {};
        const stopCodeDisplay = SessionStorageHelper.getItem('isStopCodeShown') || displayStopCodeInDashboard
        manageLocalState({ displayStopCodeInDashboard: stopCodeDisplay }); 
    }

    loadDashboardContent = ()=>
    {
        const url = `${envConfig.ENV_URL}/content/vcm/language-masters/en/dashboard-marketing-content/jcr:content/root/responsivegrid/dashboardmarketingco.dashboardmarketingcontent.json`;
        // const url = "https://qa.investor.vcm.com/content/vcm/language-masters/en/dashboard-marketing-content/jcr:content/root/responsivegrid/dashboardmarketingco.dashboardmarketingcontent.json";
        const instance = axios.create({});
        delete instance.defaults.headers.common['x-api-key'];
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['msrid'];
        delete instance.defaults.headers.common['fismemberid'];
        delete instance.defaults.headers.common['vcmid'];
        instance
            .get(url, {
                // headers: {
                //     Authorization: `${envConfig.PRELOGIN_HEADER_AUTH}`,
                // },
         })
            .then(response => {
                this.setState({dashbordContentData : response.data})
            })
            .catch((error) => {
            });
     }
 

    setProfileInformationToLocalStorage = () =>
    {
        const {memberProfileInformation} = this.props.profileInformationData;
        const {updateMarketNAOInfo} = this.props;
        let personalInfoPrimaryObj = {
            citizenship:memberProfileInformation?.citizenship,
            countryOfCitizenship: memberProfileInformation?.countryOfCitizenship,
            countryOfCitizenshipCode: memberProfileInformation?.citizenship,
            dob:  memberProfileInformation?.m_dateOfBirth,
            firstName: memberProfileInformation?.firstName,
            gender: memberProfileInformation?.gender,
            lastName: memberProfileInformation?.lastName,
            maritalStatus: memberProfileInformation?.maritalStatus,
            maskedDob: memberProfileInformation?.m_dateOfBirth,
            maskedSSN: memberProfileInformation?.m_ssnTin,
            middleInitial: memberProfileInformation?.middleInitial,
            prefix: memberProfileInformation?.prefix,
            ssn: memberProfileInformation?.m_ssnTin,
            suffix: memberProfileInformation?.suffix,
            taxIdIssuingCountry: ""
        }

        const saveToLocalPersonalInfoPayload = {
            sectionToBeUpdated:'personalInfo',
            state: personalInfoPrimaryObj,
            isPrimary:true,
            isSecondary: false
        }
        
        updateMarketNAOInfo(saveToLocalPersonalInfoPayload);

        if(memberProfileInformation?.addressInformation.length > 0)
        {
            let mailingAddress = null;
            let physicalAddress = null;
            if(memberProfileInformation?.addressInformation[0]?.isPhysicalAddress == true)
            {
                  physicalAddress = {
                    addressLine1: memberProfileInformation?.addressInformation[0].addressLine1,
                    addressLine2: memberProfileInformation?.addressInformation[0].addressLine2,
                    city: memberProfileInformation?.addressInformation[0].city,
                    mailingAddressType: memberProfileInformation?.addressInformation[0].addressType,
                    state:  memberProfileInformation?.addressInformation[0].state,
                    zipCode: memberProfileInformation?.addressInformation[0].zip,
                    isMailingAddress:false,
                    isPhysicalAddress: true
                }
            }

            if(memberProfileInformation?.addressInformation[1]?.isMailingAddress == true)
            {
                 mailingAddress = {
                    addressLine1: memberProfileInformation?.addressInformation[1].addressLine1,
                    addressLine2: memberProfileInformation?.addressInformation[1].addressLine2,
                    city: memberProfileInformation?.addressInformation[1].city,
                    mailingAddressType: memberProfileInformation?.addressInformation[1].addressType,
                    state:  memberProfileInformation?.addressInformation[1].state,
                    zipCode: memberProfileInformation?.addressInformation[1].zip,
                    isMailingAddress:true,
                    isPhysicalAddress: false
                }
            }

            const addressInfo = {
                physicalAddress :physicalAddress,
                mailingAddress : mailingAddress,
                isPhysicalAddressSame:"Yes"
            }

            const saveToLocalAddressPayload = {
                sectionToBeUpdated:'addressInfo',
                state: addressInfo,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalAddressPayload);

        }

        if(memberProfileInformation?.emailInformation)
        {
            const contactInfo = {
                emailAddresses: {
                    maskedPrimaryEmailAddress: "",
                    maskedSecondaryEmailAddress: "",
                    primaryEmailAddress: memberProfileInformation.emailInformation?.primaryEmail,
                    secondaryEmailAddress:memberProfileInformation.emailInformation?.secondaryEmail
                },
                phoneInfo:{
                    primary:{
                        countryCode: memberProfileInformation.phoneInformation[0]?.phoneCountryCode,
                        isMobile: memberProfileInformation.phoneInformation[0]?.isMobile,
                        number: memberProfileInformation.phoneInformation[0]?.phoneNumber,
                        mPhoneNumber:"",
                    },
                    secondary:{
                        countryCode: memberProfileInformation.phoneInformation[1]?.phoneCountryCode,
                        isMobile: memberProfileInformation.phoneInformation[1]?.isMobile,
                        number: memberProfileInformation.phoneInformation[1]?.phoneNumber,
                        mPhoneNumber:"",
                    }
                }
    
            }

            const saveToLocalContactPayload = {
                sectionToBeUpdated:'contactInfo',
                state: contactInfo,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalContactPayload);
    
        }
        if(memberProfileInformation?.employmentInformation)
        {
            const empInfoData = {
                address:{
                    addressLine1: memberProfileInformation?.employmentInformation?.addressLine1,
                    addressLine2: memberProfileInformation?.employmentInformation?.addressLine2,
                    city: memberProfileInformation?.employmentInformation?.employersCity,
                    state: memberProfileInformation?.employmentInformation?.employersState,
                    zipCode: memberProfileInformation?.employmentInformation?.employersZipcode,
                },
                employerName:memberProfileInformation?.employmentInformation?.employerName,
                industry: memberProfileInformation?.employmentInformation?.industry,
                industryDescription: memberProfileInformation?.employmentInformation?.industryDescription,
                occupation: memberProfileInformation?.employmentInformation?.occupation,
                otherEmployment: memberProfileInformation?.employmentInformation?.empStatusOther,
                primarySource:memberProfileInformation?.employmentInformation?.primarySourceOfIncome,
                status: memberProfileInformation?.employmentInformation?.employmentStatus
              }

            const saveToLocalEmploymentPayload = {
                sectionToBeUpdated:'employmentInfo',
                state: empInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalEmploymentPayload);
        }

        if(memberProfileInformation?.financialInformation)
        {
            const finInfoData = {
                annualIncome: memberProfileInformation?.financialInformation?.annualIncome,
                netWorth: memberProfileInformation?.financialInformation?.netWorth,
                taxBracket: memberProfileInformation?.financialInformation?.taxBracket,
                taxFilingStatus: memberProfileInformation?.financialInformation?.taxFilingStatus
            }

            const saveToLocalFinancialPayload = {
                sectionToBeUpdated:'financialInfo',
                state: finInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalFinancialPayload);
        }


        if(memberProfileInformation?.militaryInformation)
        {
            const milInfoData = {
                branch: memberProfileInformation?.militaryInformation?.branchOfService,
                commissionSource: memberProfileInformation?.militaryInformation?.commissionCourse,
                startDate: memberProfileInformation?.militaryInformation?.fromDate,
                endDate:  memberProfileInformation?.militaryInformation?.toDate,
                hasServed: memberProfileInformation?.militaryInformation?.currServingUSM == true ? "Y":"N",
                militaryStatus: memberProfileInformation?.militaryInformation?.milityStatus,
                rank: memberProfileInformation?.militaryInformation?.rank,
                otherBranch:"",
                otherMilitaryStatus:""
            }

            const saveToLocalMilitaryPayload = {
                sectionToBeUpdated:'militaryInfo',
                state: milInfoData,
                isPrimary:true,
                isSecondary: false
            }
            
            updateMarketNAOInfo(saveToLocalMilitaryPayload);

        }
        
    }

    accountSummaryCalls = () => {
        const {getMemberProfileInformation, getDashboardWidgetSavedItemsList, getDashboardAccountSummary, getAccountSummary, memberDashboardData} = this.props

        if(this.state.renderCount ==0 && memberDashboardData?.isEnableFeatureSuccess && memberDashboardData?.getEnabledFeature?.features != undefined){
            let params='';
            if(SessionStorageHelper.getItem('msr_dts')){
                params = getSessionValues();
            }
            const { accesstoken, vcmid, msrid, fismemberid } = params;
            const msrParams = {
                authToken: accesstoken,
                vcmId: vcmid,
                msrId: msrid,
                fismemberid,
            };
            
            const isNewDashboardFeatureEnabled = memberDashboardData?.getEnabledFeature?.features.includes('Brokerage');
            if(isNewDashboardFeatureEnabled)
            {
                getDashboardWidgetSavedItemsList(msrParams);
                getMemberProfileInformation()
                getDashboardAccountSummary(msrParams);
            }
            else
            {
                getAccountSummary(msrParams);
            }


            this.state.renderCount = 1;
        }
    }


    assignTradeLocationPathName = () => {
        const {transactionAction} = this.state
        let tradePathName = ''

        if(transactionAction == "Buy")
        {
            tradePathName = "/transactionPurchases"
        }
        else if(transactionAction == "Sell")
        {
            tradePathName = "/liquidation";
        }
        else if(transactionAction == 'Exchange')
        {
            tradePathName = "/exchange";
        }

        return tradePathName
    }


    
    assignHoldingGroupInfoAccToFundType = (displayStopCodeInDashboard) => {
        const {memberNewDashboardReducer, profileInformationData} = this.props
        const {profileInformation} = profileInformationData;


        const mutualFund = memberNewDashboardReducer?.accountSummary?.accountDetails?.mutualFund;
        const children = memberNewDashboardReducer?.accountSummary?.accountDetails?.children;
        const brokerage = memberNewDashboardReducer?.accountSummary?.accountDetails?.brokerage;
        const dynamicadvisor = memberNewDashboardReducer?.accountSummary?.accountDetails?.dynamicAdvisor;

        if (!isEmpty(mutualFund?.accounts)) {
            this.handleHoldingForMFGroups (
                mutualFund.accounts,
                mutualFund.total,
                displayStopCodeInDashboard,
            );
        }
        else
        {
            this.state.dashboardErrorObj.isFunds = true;
        }

        if (!isEmpty(brokerage?.accounts)) {
            this.handleHoldingGroupsForBRaccounts(
                brokerage?.accounts
            );
        }
        else
        {
            this.state.dashboardErrorObj.isMarket = true;
        }

        if (!isEmpty(children?.accounts)) {
            this.handleHoldingGroupsForChildrenAccounts(
                children?.accounts
            );
        }
        else
        {
            this.state.dashboardErrorObj.isEducation = true;
        }
        if (!isEmpty(dynamicadvisor?.accounts)) {
            this.setState({hasDynamicAccounts: true})

            this.handleHoldingGroupsForDAaccounts(dynamicadvisor?.accounts);
        } else {
            this.state.dashboardErrorObj.isDynamicAdvisor = true;
        }

        if(profileInformation != undefined && profileInformation != null && Object.keys(profileInformation).length > 0 && this.state.profileStatusSaveCount == 0)
        {
            this.state.profileStatusSaveCount = 1;
            this.setProfileInformationToLocalStorage();
        }
    }


    getAccountListOfTransTypesCategory = () => {

        const {transactionHolderData, getAccountList } = this.props

        if(transactionHolderData?.transactionList?.allowedTransactions?.includes(this.state.transactionAction) == true)
        {
            let transTypesCategory = "";
            if(this.state.transactionAction == TRANSACTION_TYPE.Buy)
            {
                transTypesCategory = 'purchase';
            }
            else if(this.state.transactionAction == TRANSACTION_TYPE.Sell)
            {
                transTypesCategory = 'liquidate';
            }
            else if(this.state.transactionAction == TRANSACTION_TYPE.Exchange)
            {
                transTypesCategory = 'exchange';
            }

            getAccountList(
                {
                    companyNumber: APP_VALUES.CompanyNumber,
                    token: localStorage.getItem('token'),
                },
                true,
                transTypesCategory,
            );
        }
        else
        {
            this.state.spinner = false;
            this.state.transactionComformationModel = true;
            this.state.allowedTransactionsMessage = transactionHolderData?.transactionList?.notAllowedTransactionsMessage[`${this.state.transactionAction?.toLowerCase()}`];
        }

    }


    updateSavedApplicationList = () => {
        const {accOpeningData} = this.props;
        if(accOpeningData?.removeSavedApplicationPayload != null && this.state.renderCountSavedItem == 0)
        {
            if(accOpeningData?.isSuccess)
            {
                this.state.isLoading = false;
                let afterRemovedSavedItemList = this.state.savedItemsForDashbord.cardLinks?.filter(item => item.uuid !=  this.state.savedItemUuid);
                this.state.savedItemsForDashbord.cardLinks = afterRemovedSavedItemList;
                this.setState({
                    renderCountSavedItem:1
                })

            }
        }

    }

    showSystemHealthBannerWhenDown = () => {

        const {memberDashboardData} = this.props
        if(this.state.isSystemHealthDataAvailable == false)
        {
            this.state.isSystemHealthDataAvailable = true;
            if(( memberDashboardData.accountSummary.accountDetails.health.fis 
                || memberDashboardData.accountSummary.accountDetails.health.ascensus
                || memberDashboardData.accountSummary.accountDetails.health.pershing ) 
                && memberDashboardData.accountSummary.accountDetails.health.message != ''
            ){                            
                this.setState({
                    systemHealthDown : true,
                    systemHealthDownMessage : memberDashboardData.accountSummary.accountDetails.health.message
                })
            }
        }
    }

    showSystemHealth = () => {
        const {memberDashboardData} = this.props

        if (memberDashboardData?.systemHealth &&
            Object.keys(memberDashboardData.systemHealth).length > 0 &&
            memberDashboardData.systemHealth?.health != undefined
        ) {
            const systemHealth = {
                fis: memberDashboardData.systemHealth.health?.fis?.state,
                pershing: memberDashboardData.systemHealth.health?.pershing?.state,
                ascensus: memberDashboardData.systemHealth.health?.ascensus?.state,
            };

            this.state.systemHealth = systemHealth;
        }
    }


    assignHoldingGroupForBrokerageOrVictoryFund = (displayStopCodeInDashboard) => {
        const {memberDashboardData} = this.props
        const { accountSummary } = memberDashboardData || {};


        const { accounts, brAccounts, totalAmount } = get(accountSummary, 'accountDetails', {});
        if (!isEmpty(accounts)) {
            this.handleHoldingGroups(accounts, totalAmount, displayStopCodeInDashboard);
        }
        
        if (!isEmpty(brAccounts)) {
            this.handleHoldingGroupsForBRaccounts(
                brAccounts
            );
        }
    }

    componentDidUpdate(prevProps) {

        this.accountSummaryCalls()

        this.updateSavedApplicationList()

      
        if (this.props !== prevProps) {
            const { accountSummaryValuesProcessed } = this.state;
            const { memberDashboardData, localStateData ,memberNewDashboardReducer } = this.props;
            const { dashBoardLink, accountSummary, isSuccess } = memberDashboardData || {};
            const {
                // dashboardAccountSummary,
                displayStopCodeInDashboard = false,
            } = localStateData || {};

            const isNewDashboardFeatureEnabled = 
            this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
            this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");


            const { memberDashboardData: prevMemberDashboardData } = prevProps;
            const {
                dashBoardLink: prevDashBoardLink,
            } = prevMemberDashboardData || {};

            // Handle HoldingGroup Account Details

            if (
                has(accountSummary.accountDetails, 'accounts') && 
                !accountSummaryValuesProcessed &&
                isSuccess
                
            ) {
                // this could be for old dashboard, check the code if executiong
                this.assignHoldingGroupForBrokerageOrVictoryFund(displayStopCodeInDashboard)
            }

             // New Dashboard Changes

            if ( isNewDashboardFeatureEnabled && memberNewDashboardReducer.isNewDashboardSuccess) {
                this.assignHoldingGroupInfoAccToFundType(displayStopCodeInDashboard)
            }

            // If chart data is not available i.e,. beta user new dashboard then dont wait for the chartdata to disable loading, disable by force
            if(isNewDashboardFeatureEnabled && this.state.shouldChartLoad){
                this.state.shouldChartLoad = false
                this.onChartLoad()
            }

            if(memberDashboardData.accountSummary.accountDetails?.health){
                // System health banner in account holder page if any of the ?/ascensus/pershing system are down
                this.showSystemHealthBannerWhenDown()

            }

            if (Object.keys(dashBoardLink).length && isSuccess && !isEqual(dashBoardLink, prevDashBoardLink)) {
                this.handleAscensusClick(dashBoardLink);
            }
            // Check for Errors
            this.checkErrors();

            this.showSystemHealth()


            const {savedItemsResult} = this.props.memberDashboardData
            const itemlist = savedItemsResult?.result?.applications
            
            if(Array.isArray(itemlist)== true && itemlist.length > 0   && this.state.savedItemsListrendercount == 0 ){
                const brokeragesaveditems = itemlist.filter((item)=> item?.isBrokerage)
                this.generateSavedItemsJsonForMarket(brokeragesaveditems);
                this.state.savedItemsListrendercount = 1
            }
        }

        const {memberProfileInformation} = this.props.profileInformationData;
        if(memberProfileInformation != undefined && memberProfileInformation != null && Object.keys(memberProfileInformation).length > 0 && this.state.profileStatusSaveCount == 0)
        {
            this.state.profileStatusSaveCount = 1;
            this.setProfileInformationToLocalStorage();
        }

        // Buy , Sell and Exchange Transaction code 

        const { transactionHolderData,masterRegistrationAccountData} = this.props;
      
        if(transactionHolderData?.transactionList?.length != 0 && this.state.renderCountForTransction == 0)
        {
 
            this.getAccountListOfTransTypesCategory()
            this.state.renderCountForTransction = 1;
        }

        if(transactionHolderData?.transactionList?.allowedTransactions?.length != 0 && masterRegistrationAccountData?.masterRegAccountList?.length > 0 && this.state.renderCountForTransction == 1)
        {
            const {brokerageTradingDetails} = this.state;
            const {loginState} = this.props;
            const { getCustomerProfile: customerProfile } = loginState || {};
            let transPayload  = {
                pathname: '',
                state: { 
                    pageIndex: 1, 
                    masterId : brokerageTradingDetails.accountID,
                    name : brokerageTradingDetails.name,
                    accountType : brokerageTradingDetails.accountType, 
                    accountOwnerDesc : brokerageTradingDetails.accountOwnerDesc,
                    ownerType : brokerageTradingDetails.ownerType, 
                    taxId : brokerageTradingDetails.taxId, 
                    accountDetails : customerProfile,
                    newDashboard : "newDashboard"
                },
            };
            const accountInfoData = { 
                pageIndex: 1, 
                masterId : brokerageTradingDetails.accountID,
                accountName : brokerageTradingDetails.name,
                accountType : brokerageTradingDetails.accountType, 
                accountOwnerDesc : brokerageTradingDetails.accountOwnerDesc,
                ownerType : brokerageTradingDetails.ownerType, 
                taxId : brokerageTradingDetails.taxId, 
                accountDetails : customerProfile,
                newDashboard : "newDashboard"
            }
            LocalStorageHelper.setItem('accountInfo',JSON.stringify(accountInfoData))

            transPayload.pathname = this.assignTradeLocationPathName()
            this.state.tradeLocation = transPayload;
            this.state.renderCountForTransction = 2;
        }
    }

    componentWillUnmount() {
        localStorage.removeItem('masterId');
        localStorage.removeItem('accountName');
    }

    formatAccounts = ascendingSortedAccounts => {
        return ascendingSortedAccounts.reduce((acc, curr) => {
            const index = acc.findIndex(item => {
                return item.groupName === curr.groupName;
            });
            if (index !== -1) {
                if (curr.stopCode === '9') {
                    if (curr.isAscensus) {
                        acc[+index].accounts.push(curr);
                    }
                } else {
                    acc[+index].accounts.push(curr);
                }
            } else if (curr.stopCode === '9') {
                if (curr.isAscensus) {
                    acc.push({ groupName: curr.groupName, accounts: [curr] });
                }
            } else {
                acc.push({ groupName: curr.groupName, accounts: [curr] });
            }
            return acc;
        }, []);
    };

    formatDate = (date) => {
        const currentTimeZone = moment().tz(moment.tz.guess()).format('z');
        const formattedDate = moment(new Date(date)).format("MMMM DD, YYYY hh:mm A");
        return `${formattedDate} ${currentTimeZone}`
    }
   
    formatLinkText = (text, isBrokerage = false, isDynamicAdvisor = false) => {
        if(text.includes('529')){
            return `Complete your ${text} Account Application`
        } else if (isBrokerage == true && isDynamicAdvisor == true){
            return `Complete your Dynamic Advisor ${text} Account Application`
        }else if(isBrokerage == true){
            return `Complete your Marketplace ${text} Account Application`
        }
        else
        {
            return `Complete your Victory Funds ${text} Account Application`
        }
    }

    generateSavedItemsJsonForMarket = async (data) => {
        const { savedItemsForDashboard } = constant;
        if (data && Object.keys(data).length && data.length>0) {
            const sortedData = await data.sort(function(a,b){ return new Date(b.updateAt) - new Date(a.updateAt) })
            const formattedJson = sortedData &&
                sortedData.map((item, index) => ({
                    id: index,
                    savedate: this.formatDate(item.updateAt),
                    linktextValue: this.formatLinkText(item.accountType, item.isBrokerage, item.isDynamicAdvisor),
                    attachment: false,
                    link: '/accountType',
                    // link: '/openAccount',
                    uuid:item.applicationId,
                    accountText:item.accountType,
                    isBrokerage: item.isBrokerage,
                    updatedAt: convert_CST_Timezone(item.updateAt),
                }));
           savedItemsForDashboard.cardLinks = formattedJson
        }
        this.setState({ savedItemsForDashbord : savedItemsForDashboard })
    }


    getDisplayOrder = (accountStatus) => {
        let displayOrder = 0;
        if (accountStatus === apiClosedAccountStatus)
            displayOrder = 10;

        return displayOrder;
    }

    reduceHelperFunction = (curr, acc, index) => {

        if (curr.stopCode === apiClosedAccountStatusCode) {
            if (curr.isAscensus) {
                acc[+index].accounts.push(curr);
            }
        } else {
            acc[+index].accounts.push(curr);
        }
    }

    prepareAccountIntoGroups = (acc, curr, viewOrder) => {

        const groupCategory = this.getGroupName(curr)

        const index = acc.findIndex(item => {
            return item.groupName === groupCategory;
        });

        if (index !== -1) {
            this.reduceHelperFunction(curr, acc, index)
        } else if (curr.stopCode === apiClosedAccountStatusCode) {
            if (curr.isAscensus) {
                acc.push({ groupName: groupCategory, accounts: [curr], displayOrder: viewOrder });
            }
        } else {
            acc.push({ groupName: groupCategory, accounts: [curr], displayOrder: viewOrder });
        }

    }

    getGroupName = (curr) => {
        const isClosedAccount = curr.accountStatus === apiClosedAccountStatus;
        const groupName = isClosedAccount ? CLOSED_ACCOUNT : curr.groupName

        return groupName
    }

    sortedAccountsData = (groupedAccounts) => {
        const sortedAccountsData = groupedAccounts.sort((a, b) => {
            if (a.displayOrder === 10 && b.displayOrder !== 10) return 1;
            if (a.displayOrder !== 10 && b.displayOrder === 10) return -1;
            return 0;
        })
        return sortedAccountsData
    };


    brformatAccounts = ascendingSortedAccounts => {
        let viewOrder = 0
        const ascendingSortedAccountsData = ascendingSortedAccounts.reduce((acc, curr) => {
        viewOrder = this.getDisplayOrder(curr.accountStatus);
        this.prepareAccountIntoGroups(acc, curr, viewOrder)
            return acc;
        }, []);
        const sortedGroupAccountData = this.sortedAccountsData(ascendingSortedAccountsData)
        return sortedGroupAccountData
    };

    daformatAccounts = ascendingSortedAccounts => {
        return ascendingSortedAccounts.reduce((acc, curr) => {
            const index = acc.findIndex(item => {
                return item.groupName === curr.groupName;
            });
            if (index !== -1) {
                if (curr.stopCode === '9') {
                    if (curr.isAscensus) {
                        acc[+index].accounts.push(curr);
                    }
                } else {
                    acc[+index].accounts.push(curr);
                }
            } else if (curr.stopCode === '9') {
                if (curr.isAscensus) {
                    acc.push({ groupName: curr.groupName, accounts: [curr] });
                }
            } else {
                acc.push({ groupName: curr.groupName, accounts: [curr] });
            }

            return acc;
        }, []);
    };

    handleHoldingGroups = (accounts, totalAmount, isStopCodeDisplayNotComplete) => {
        const sortedData = accounts.sort(this.sortFunction);
        const [firstItem] = sortedData;
        const latestAggregatedBalanceDate = moment(new Date(firstItem.lastModifiedDate)).format('MMMM DD, YYYY');
        // ordering accounts by account Name alphabetically ascending order
        const ascendingSortedAccounts = orderBy(accounts, ['accountName'], ['asc']);
        let groupedAccounts = this.formatAccounts(ascendingSortedAccounts);
        // ordering holding group by below sort array
        const { sortArray } = accountHolderCardData;
        groupedAccounts = chain(sortArray)
            .map(item => {
                const index = groupedAccounts.findIndex(fun => item === fun.groupName);
                if (index > -1) {
                    return groupedAccounts[+index];
                }
                return undefined;
            })
            .filter(i => i !== undefined)
            .value();
        const { defaultHighchartData, NoPortofolioErrorMsg } = accountHolderCardData;
        if (isLength(groupedAccounts) <= isLength(defaultHighchartData)) {
            groupedAccounts = groupedAccounts.map((group, index) => {
                let groupTotal = 0;
                let percentageValue = 0;
                if (has(group, 'accounts')) {
                    group.accounts.forEach(account => {
                        groupTotal += account.balance;
                    });
                    percentageValue = Math.round((groupTotal * 100) / totalAmount);
                }
                const formattedGroupTotal = currencyFormatter.format(groupTotal, { locale: 'en-US' });
                const { c, r, open } = defaultHighchartData[+index];
                return {
                    ...group,
                    groupTotal,
                    formattedGroupTotal,
                    percentageValue,
                    c,
                    r,
                    index,
                    open,
                };
            });
            // handle Stop Code
            let displayWarning = false;
            let warningMsg = '';
            if (!isStopCodeDisplayNotComplete) {
                // const { manageLocalState } = this.props;
                const stopCodeResult = this.handleStopCode(accounts);
                const { showStopCode, stopCodeErrorMsg } = stopCodeResult || {};
                displayWarning = showStopCode;
                warningMsg = stopCodeErrorMsg;
            }
           
            const plottingValues = this.getPlottingValuesForGraph(groupedAccounts);

            let { message, isError } = this.state;
            if (isEmpty(groupedAccounts)) {
                message = NoPortofolioErrorMsg;
                isError = true;
            }

            this.setState({
                highchartData: groupedAccounts,
                graphPlottingValues: plottingValues,
                totalAmount,
                showStopCode: displayWarning,
                stopCodeErrorMsg: warningMsg,
                latestAggregatedBalanceDate,
                message,
                isError,
                accountSummaryValuesProcessed: true,
                spinner: false,
            });
        }
    };

    handleHoldingForMFGroups = (accounts, totalAmount, isStopCodeDisplayNotComplete) => {
        const sortedData = accounts.sort(this.sortFunction);
        const [firstItem] = sortedData;
        const latestAggregatedBalanceDate = moment(new Date(firstItem.lastModifiedDate)).format('MMMM DD, YYYY');
        // ordering accounts by account Name alphabetically ascending order
        const ascendingSortedAccounts = orderBy(accounts, ['accountName'], ['asc']);
        let groupedAccounts = this.formatAccounts(ascendingSortedAccounts);
        // ordering holding group by below sort array
        const { sortArray } = accountHolderCardData;
        groupedAccounts = chain(sortArray)
            .map(item => {
                const index = groupedAccounts.findIndex(fun => item === fun.groupName);
                if (index > -1) {
                    return groupedAccounts[+index];
                }
                return undefined;
            })
            .filter(i => i !== undefined)
            .value();
        const { defaultHighchartData, NoPortofolioErrorMsg } = accountHolderCardData;
        if (isLength(groupedAccounts) <= isLength(defaultHighchartData)) {
            groupedAccounts = groupedAccounts.map((group, index) => {
                let groupTotal = 0;
                let percentageValue = 0;
                if (has(group, 'accounts')) {
                    group.accounts.forEach(account => {
                        groupTotal += account.balance;
                    });
                    percentageValue = Math.round((groupTotal * 100) / totalAmount);
                }
                const formattedGroupTotal = currencyFormatter.format(groupTotal, { locale: 'en-US' });

                const { c, r, open } = defaultHighchartData[+index];
                if(this.state.mfAccountRenderCount == 0)
                {
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                   
                }
                else
                {
                    const { c, r, open } = this.state.mfAccountData[+index];
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                }
                
            });
            // handle Stop Code
            let displayWarning = false;
            let warningMsg = '';
            if (!isStopCodeDisplayNotComplete) {
                const stopCodeResult = this.handleStopCode(accounts);
                const { showStopCode, stopCodeErrorMsg } = stopCodeResult || {};
                displayWarning = showStopCode;
                warningMsg = stopCodeErrorMsg;
            }

            const plottingValues = this.getPlottingValuesForGraph(groupedAccounts);

            let { message, isError } = this.state;
            if (isEmpty(groupedAccounts)) {
                message = NoPortofolioErrorMsg;
                isError = true;
            }
            this.setState({
                mfAccountData: groupedAccounts,
                graphPlottingValues: plottingValues,
                totalAmount,
                showStopCode: displayWarning,
                stopCodeErrorMsg: warningMsg,
                latestAggregatedBalanceDate,
                message,
                isError,
                accountSummaryValuesProcessed: true,
            });
        }
    };

    handleHoldingGroupsForBRaccounts = (accounts, totalAmount, isStopCodeDisplayNotComplete) => {
        const sortedData = accounts.sort(this.sortFunction);
        const [firstItem] = sortedData;
        // ordering accounts by account Name alphabetically ascending order
        const ascendingSortedAccounts = orderBy(accounts, ['accountName'], ['asc']);
        let groupedAccounts = this.brformatAccounts(ascendingSortedAccounts);
        // ordering holding group by below sort array
       
        const { defaultHighChartForBrokerageData, } = accountHolderCardData;
        if (isLength(groupedAccounts) <= isLength(defaultHighChartForBrokerageData)) {
            groupedAccounts = groupedAccounts.map((group, index) => {
                let groupTotal = 0;
                let percentageValue = 0;
                if (has(group, 'accounts')) {
                    group.accounts.forEach(account => {
                        groupTotal += account.balance;
                    });
                    percentageValue = Math.round((groupTotal * 100) / totalAmount);
                }
                const formattedGroupTotal = currencyFormatter.format(groupTotal, { locale: 'en-US' });
                const { c, r, open } = defaultHighChartForBrokerageData[+index];
                return {
                    ...group,
                    groupTotal,
                    formattedGroupTotal,
                    percentageValue,
                    c,
                    r,
                    index,
                    open,
                };
            });
           
            this.setState({
                brAccountsData: groupedAccounts,
            });
         }
    };

    handleHoldingGroupsForChildrenAccounts = (accounts, totalAmount, isStopCodeDisplayNotComplete) => {
        const sortedData = accounts.sort(this.sortFunction);
        const [firstItem] = sortedData;
        // ordering accounts by account Name alphabetically ascending order
        const ascendingSortedAccounts = orderBy(accounts, ['accountName'], ['asc']);
        let groupedAccounts = this.brformatAccounts(ascendingSortedAccounts);
 
        // ordering holding group by below sort array
        const { defaultHighChartForChildrenData } = accountHolderCardData;
        if (isLength(groupedAccounts) <= isLength(defaultHighChartForChildrenData)) {
            groupedAccounts = groupedAccounts.map((group, index) => {
                let groupTotal = 0;
                let percentageValue = 0;
                if (has(group, 'accounts')) {
                    group.accounts.forEach(account => {
                        groupTotal += account.balance;
                    });
                    percentageValue = Math.round((groupTotal * 100) / totalAmount);
                }
                const formattedGroupTotal = currencyFormatter.format(groupTotal, { locale: 'en-US' });
                const { c, r, open } = defaultHighChartForChildrenData[+index];

                if(this.state.childrenAccountRenderCount == 0)
                {
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                }
                else
                {
                    const { c, r, open } = this.state.childrenAccountsData[+index];
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                }
                
            });
         
             this.setState({
                childrenAccountsData: groupedAccounts,
             });
          }
     };

     handleHoldingGroupsForDAaccounts = (accounts, totalAmount, isStopCodeDisplayNotComplete) => {
        const sortedData = accounts.sort(this.sortFunction);
        const [firstItem] = sortedData;
        // ordering accounts by account Name alphabetically ascending order
        const ascendingSortedAccounts = orderBy(accounts, ['accountName'], ['asc']);
        let groupedAccounts = this.daformatAccounts(ascendingSortedAccounts);

        // ordering holding group by below sort array
    

        const { defaultHighChartForDynamicAdvisorData } = accountHolderCardData;
        if (isLength(groupedAccounts) <= isLength(defaultHighChartForDynamicAdvisorData)) {
            groupedAccounts = groupedAccounts.map((group, index) => {
                let groupTotal = 0;
                let percentageValue = 0;
                if (has(group, 'accounts')) {
                    group.accounts.forEach(account => {
                        groupTotal += account.balance;
                    });
                    percentageValue = Math.round((groupTotal * 100) / totalAmount);
                }
                const formattedGroupTotal = currencyFormatter.format(groupTotal, { locale: 'en-US' });
                const { c, r, open } = defaultHighChartForDynamicAdvisorData[+index];

                if (this.state.daAccountRenderCount == 0) {
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                } else {
                    const { c, r, open } = this.state.daAccountsData[+index];
                    return {
                        ...group,
                        groupTotal,
                        formattedGroupTotal,
                        percentageValue,
                        c,
                        r,
                        index,
                        open,
                    };
                }
            });

            this.setState({
                daAccountsData: groupedAccounts,
            });
        }
    };


    getPlottingValuesForGraph = groupedAccounts => {
        const plottingValues = [];
        const dummyPlottingValues = groupedAccounts.map(item => {
            return {
                groupTotal: item.groupTotal,
                formattedGroupTotal: item.formattedGroupTotal,
                groupName: item.groupName,
            };
        });
        dummyPlottingValues.forEach((item, index) => {
            let lblDist = 23;
            if (index === 0) {
                lblDist = -140;
            }
            plottingValues.push({
                y: item.groupTotal,
                name: item.groupName,
                formattedGroupTotal: item.formattedGroupTotal,
                dataLabels: {
                    enabled: false,
                    distance: lblDist,
                    style: { font: '500 14px/22px benton-sans,sans-seriff' },
                },
            });
        });
        return plottingValues;
    };

    checkErrors = () => {
        const { memberDashboardData } = this.props;
        const { isError } = memberDashboardData || {};
        if (Object.keys(memberDashboardData).length && isError) {
            this.handleProfileAPIError(memberDashboardData);
        }
    };

    sortFunction = (a, b) => {
        const dateA = new Date(a.lastModifiedDate).getTime();
        const dateB = new Date(b.lastModifiedDate).getTime();
        return dateA > dateB ? -1 : 1;
    };

    withInSoftCodeRange = value => {
        if (value !== '0' && value !== '8' && value !== '9') {
            return true;
        }
        return false;
    };

    withInHardCodeRange = value => {
        if (value === '8' || value === '9') {
            return true;
        }
        return false;
    };

    handleStopCode = data => {
        let stopCodeDisplay = false;
        let msg = '';
        if (data && Object.keys(data).length) {
            const hardStopCodeDataIndex = data.findIndex(item => this.withInHardCodeRange(item.stopCode));
            const softStopCodeDataIndex = data.findIndex(item => this.withInSoftCodeRange(item.stopCode));
            if (hardStopCodeDataIndex > -1) {
                msg = data[+hardStopCodeDataIndex].warningDesc;
                stopCodeDisplay = true;
            } else if (softStopCodeDataIndex > -1) {
                msg = data[+softStopCodeDataIndex].warningDesc;
                stopCodeDisplay = true;
            }
        }
        return { showStopCode: stopCodeDisplay, stopCodeErrorMsg: msg };
    };

    handleCloseStopCode = () => {
        const { manageLocalState } = this.props;
        manageLocalState({ displayStopCodeInDashboard: true });
        this.setState({ showStopCode: false, stopCodeErrorMsg: '' });
    };

    handleProfileAPIError = data => {
        const { commonErrorMsg } = constant;
        const { showStopCode, stopCodeErrorMsg } = this.state;
        let errorMsg = data.error;
        let profileErrorDisplay = false;
        let warningDisplay = showStopCode;
        let warningMsg = stopCodeErrorMsg;
        const { dashBoardLink, accountSummary } = data;
        if (errorMsg && isObject(errorMsg)) {
            errorMsg = commonErrorMsg;
            profileErrorDisplay = true;
        }
        if (has(accountSummary, 'response')) {
            errorMsg = get(accountSummary, 'response', '');
            profileErrorDisplay = true;
        }
        if (has(dashBoardLink, 'response')) {
            warningDisplay = true;
            warningMsg = get(dashBoardLink.response.data, 'message', '');
        }
        if (has(data.error, 'response')) {
            errorMsg = get(data.error, 'response', '') || errorMsg;
            profileErrorDisplay = true;
        }

        this.setState({
            message: errorMsg,
            isError: profileErrorDisplay,
            showStopCode: warningDisplay,
            stopCodeErrorMsg: warningMsg,
            chartLoading: false,
            spinner: false,
        });
    };

    cardHeaderClick = e => {
        e.target.classList.toggle('expand');
    };

    marstone = e => {
        this.setState(state => ({
            ...state,
            marstoneClick: true,
        }));
        const { analyzeClickEvent } = this.props;
        const linkName = e.target.innerText || '';
        const screenName = localStorage.getItem('screenName');
        analyzeClickEvent({
            link: `Dashboard_${screenName}_Resources and Tools_${linkName}`,
        });
        setTimeout(() => {
            this.setState(state => ({
                ...state,
                marstoneClick: false,
            }));
        }, 0);
    };

    handleAccordionClick = (id, open, acctype) => e => {
        e.preventDefault();
        this.setState({mfAccountRenderCount:1,brAccountRenderCount:1,childrenAccountRenderCount:1,daAccountRenderCount: 1,})
        const { analyzeClickEvent } = this.props;
        const isNewDashboardFeatureEnabled =
        this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
        this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");
        let state = 'collapsed';
        if (!open) {
            state = 'expanded';
        }
        const linkName = e.target.innerText || '';
        const screenName = localStorage.getItem('screenName');
        analyzeClickEvent({
            link: `Dashboard_${screenName}_My Portfolio_${linkName}_${state}`,
        });
       
        if(acctype == 'mf-account'){
            const { highchartData,mfAccountData } = this.state;
            if(!isNewDashboardFeatureEnabled){
             highchartData[+id].open = !open;
                this.setState({highchartData });
            }
            else{
               let tempMfAccountData = mfAccountData;
               tempMfAccountData[+id].open = !open;
               this.setState({ mfAccountData:tempMfAccountData });
            }    
         }
         else if(acctype == 'br-account'){
             const { brAccountsData } = this.state;
             brAccountsData[+id].open = !open;
             this.setState({brAccountsData });
         }
        else if(acctype == 'ch-account')
        {
            const { childrenAccountsData } = this.state;
            childrenAccountsData[+id].open = !open;
            this.setState({childrenAccountsData });
        }
        else if (acctype == 'da-account') {
            const { daAccountsData } = this.state;
            daAccountsData[+id].open = !open;
            this.setState({ daAccountsData });
        }
        
        
    };

    handleAscensusClick = data => {
        const endpointURL = envConfig.ASCENSUS_API;
        const link = `${endpointURL}?encPayload=${data.cipherText}&signedToken=${data.signedToken}&token=${data.token}&Nav=${data.nav}&aesEncKey=${data.encodedAesSessionKey}`;
        window.open(link, '_blank');
    };

    onChartLoad = () => {
        this.setState({ chartLoading: false });
    };

    handleMSRAscensusClick = () => {
        this.setState({ showStopCode: true, stopCodeErrorMsg: 'Please verify in sales force for 529 Plan' });
    };

    onSingleSignOnClick = (payload) => {   
        SessionStorageHelper.setItem('brAccountClicked',true)
        this.props.getGeneratedSAMLAssertion(payload)
    }

    placeATradeonSingleSignOnClick = (payload) => {   
        // SessionStorageHelper.setItem('brAccountClicked',true)
        this.props.getGeneratedSAMLAssertion(payload)
    }

    handleSingleSignOnClick = () =>{
        this.setState({singleSignOnClick:false})
    }

    showDashboardTabs = () =>{
        let tabsShow = 'brokerage';
        if(tabsShow == 'default'){
            return(
            <Nav variant="pills">
                <Nav.Item className='tabbox'>
                <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                </Nav.Item>

                <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="victory funds" className="tablink second">Victory Funds<br/><img src={traingleicon} className="triangleIcon" style={{width:'20px',height:'auto'}} /></Nav.Link>
                </Nav.Item>
                <Nav.Item className='tabbox'>
                        <Nav.Link eventKey="markets" className="tablink fourth">Marketplace</Nav.Link>
                </Nav.Item>
                <Nav.Item className='tabbox'>
                <Nav.Link eventKey="education" className="tablink third">Education</Nav.Link>
                </Nav.Item>
            </Nav>
            )
        }
        else if(tabsShow == 'brokerage' || tabsShow == 'victory funds'){
            return(
                <Nav variant="pills">
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="victory funds" className="tablink second">Victory Funds</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="education" className="tablink third">Education</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                        <Nav.Link eventKey="markets" className="tablink fourth">Marketplace</Nav.Link>
                    </Nav.Item>
                </Nav>
                )
        }else{
            return(
                <Nav variant="pills">
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="overview" className="tablink first">Overview</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="victory funds" className="tablink second">Victory Funds</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                        <Nav.Link eventKey="markets" className="tablink fourth">Marketplace</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='tabbox'>
                    <Nav.Link eventKey="education" className="tablink third">Education</Nav.Link>
                    </Nav.Item>
                </Nav>
                )
        }
    }


    getAllowedTransaction = (accountID,transType,infoData) =>
    {
        const {resetTransactionHolder,clearMasterRegInfo} = this.props;
        resetTransactionHolder();
        clearMasterRegInfo();
        this.setState({
            transactionAction:transType,
            spinner:true,
            renderCountForTransction:0,
            brokerageTradingDetails : infoData
        })
        const {getTransHolderList} = this.props;
      
        const transactionHistoryPayload = {
            masterId:accountID,
            companyNumber : APP_VALUES.CompanyNumber
        };
        //const msrInfo = SessionStorageHelper.getMsrObj() ;
        const params = getSessionValues()
        const { accesstoken, vcmid, fismemberid } = params;

        getTransHolderList({
            payload: transactionHistoryPayload,
            token: accesstoken,
            vcmid,
            fismemberid,
        });
      
    }

    handleConfirmation = ()=>
    {
        this.setState({transactionComformationModel:false})
    }



    removeSavedListItem = (uuid,index,item)=>
    {
        const {resentRemoveSavedList} = this.props;
        resentRemoveSavedList();
        this.setState({ savedItemModal:true,
            savedItemUuid:uuid,
            removeSavedItemIndex:index,
            removeAccountType:item.accountText,
            isRemovingApplicationBrokerage: item.isBrokerage
        });
    }

    closeSavedListItemModal = () => {
        this.setState({ savedItemModal:false });
    };

    confirmRemove = () =>
    {
        this.setState({savedItemModal:false,renderCountSavedItem:0,isLoading : true})
        const {removeSavedItemApi} = this.props;
        const {savedItemUuid,removeAccountType, isRemovingApplicationBrokerage} = this.state;
        const uuid = savedItemUuid;

        if(isRemovingApplicationBrokerage)
        {
            const applicationPayload = {
                "applicationId": uuid,
                "isBrokerage": isRemovingApplicationBrokerage
            };
            removeSavedItemApi(applicationPayload);
        }
        else
        {
            const applicationPayload = {
                "applicationId": uuid,
                "isBrokerage": isRemovingApplicationBrokerage,
                "accountType": removeAccountType
            };
            removeSavedItemApi(applicationPayload);
        }
    }

    handleApplicationClosedPopUp = () => {
        this.setState({
            isApplicationClosed: !this.state.isApplicationClosed
        });
    }

    handleEducationApplicationClosedPopUp = () => {
        this.setState({
            isEducationApplicationClosed: !this.state.isEducationApplicationClosed
        });
    }


     render() {
         const {
            totalAmount,
            highchartData,
            brAccountsData,
            showStopCode,
            stopCodeErrorMsg,
            isError,
            message,
            latestAggregatedBalanceDate,
            graphPlottingValues,
            chartLoading,
            mfAccountData,
            childrenAccountsData,
            systemHealthDown,
            systemHealthDownMessage,
            savedItemsForDashbord,
            savedItemModal,
            showModel,
            tradeLocation,
            spinner,
            transactionComformationModel,
            daAccountsData,
             isApplicationClosed,
             isEducationApplicationClosed,
        } = this.state;

        const { getDashBoardAccordianLinks, dashBoardLinkData, history,profileInformationData,memberDashboardData,memberNewDashboardReducer,loginState} = this.props;
        const { commonData, assignObj, holdingGroupDescription,notifyMe,dashboardOpenAnAccount,dashboardAccountServices,openBrokerageAccount,REMOVE_SAVED_ITEM_MESSAGE,
            REMOVE_SAVED_ITEM_HEDING  } = accountHolderCardData;
        const { pageName, amountTitle, holdingGroup,mutualFund,brokerageAccounts, balanceUsd, percentage, totalPortfolio,NewAmountTitle } = commonData;
        const formattedTotalAmount = `${currencyFormatter.format(totalAmount, { locale: 'en-US' })}`;
        const isFamilyAndFriends = 
        this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
        this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");
        const isNewDashboardFeatureEnabled = 
        this.props.memberDashboardData.getEnabledFeature?.features == undefined ? false:
        this.props.memberDashboardData.getEnabledFeature?.features.includes("Brokerage");
        const isNewDashboardFeatureEnabledForDynamicAdvisor =
        this.props.memberDashboardData.getEnabledFeature?.features == undefined
            ? false
            : this.props.memberDashboardData.getEnabledFeature?.features.includes('DynamicAdvisor');
        const { isLoading: newMemberDashboardLoader } = memberNewDashboardReducer || {};
        const { isLoading: memberDashboardDataLoading } = memberDashboardData || {};
        const { isLoading: isLoading } = memberNewDashboardReducer || {};
       
        const { getCustomerProfile: customerProfile } = loginState || {};

        const systemHealthMessage = {
                info: ' ',
                message:systemHealthDownMessage
            }

        
        return (
            <div className="containerBg alertcontainercustom">
                <WSpinner loading={
                        memberDashboardDataLoading ||
                        chartLoading
                        || isLoading || 
                        this.state.isLoading || 
                        spinner
                    } />

                <StopCodeAlert show={showStopCode} apiErrorMsg={stopCodeErrorMsg} onClick={this.handleCloseStopCode} />

                {tradeLocation?.pathname && <Redirect to={tradeLocation} />}
                
                {savedItemModal &&  
                <ConfirmModalFixedWidth 
                        modalTitle={REMOVE_SAVED_ITEM_HEDING}
                        modalBodyText={REMOVE_SAVED_ITEM_MESSAGE}
                        secondaryButtonText={"No"}
                        onPrimaryClick={this.confirmRemove}
                        onSecondaryClick={this.closeSavedListItemModal}
                        primaryButtonText={"Yes"}
                
                    />
              }

                { (!isNewDashboardFeatureEnabled && isFamilyAndFriends && systemHealthDown) && <WMessageBar text={systemHealthMessage} dontScrollUp={true} error={systemHealthDown} onClose={this.handleCloseErrorMsg} />}
             
                {transactionComformationModel && 
                    <ConfirmModal
                    modalTitle=""
                    modalBodyText={this.state.allowedTransactionsMessage}
                    primaryButtonText="Ok"
                    onPrimaryClick={this.handleConfirmation}
                />
               }

                {isApplicationClosed &&
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={closedApplicationPopUpMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleApplicationClosedPopUp}
                    />
                }

                {isEducationApplicationClosed &&
                    <ConfirmModal
                        modalTitle=""
                        modalBodyText={closedEducationApplicationPopUpMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleEducationApplicationClosedPopUp}
                    />
                }

                <div className="container">
                    { !isNewDashboardFeatureEnabled &&
                    <CardDeck key="1" style={assignObj({ paddingTop: '20px' })}>
                        <Col key="1" md={12} lg={12} xl={12}>
                            <WCardOverlay
                                className="ac-holder-wcardoverlay"
                                myStyles={assignObj({ padding: '1rem 1rem 1rem 1.6875rem' })}
                                showCorner
                            >
                                <div className="balance-amount">
                                    <div className="imageComponent" style={styles.pullLeft}>
                                        <img src={iconPortfolio} alt="" className="mr-4" height="41px" width="61px" />
                                    </div>
                                    <Row noGutters className="w-100" style={styles.balanceTileStyle}>
                                        <Col style={styles.balanceStyles}>
                                            <div className="amount" style={styles.pullLeft}>
                                                <p className="mb-0" style={styles.amount}>
                                                    {!isError && graphPlottingValues.length > 0
                                                        ? formattedTotalAmount
                                                        : ''}
                                                </p>
                                                <span className="titleText" style={styles.titleTxt}>
                                                    {!isError && graphPlottingValues.length > 0
                                                        ? amountTitle + latestAggregatedBalanceDate
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className="clear" style={styles.clear} />
                                        </Col>
                                        <Col style={styles.openAccountBTnColStyle}>
                                        
                                        </Col>
                                    </Row>
                                </div>
                            </WCardOverlay>
                        </Col>
                    </CardDeck>
                    }
{/* ////////////////////////////for main page card */}

                    <CardDeck key="2">
                    { isNewDashboardFeatureEnabled ? 
                     <NewDashboard 
                     latestAggregatedBalanceDate={moment(new Date(this.props.memberNewDashboardReducer?.accountSummary?.accountDetails?.asofDate)).format('LL')} 
                     formattedTotalAmount={formattedTotalAmount} 
                                handleApplicationClosedPopUp={this.handleApplicationClosedPopUp}
                                handleEducationApplicationClosedPopUp={this.handleEducationApplicationClosedPopUp}
                     isError={isError} 
                     graphPlottingValues={graphPlottingValues}
                     NewAmountTitle={NewAmountTitle}
                     pageName={pageName}
                     notifyMe={notifyMe}
                     onChartLoad={this.onChartLoad}
                     savedItemsList={savedItemsForDashbord}
                     isNewDashboardFeatureEnabled={isNewDashboardFeatureEnabled}
                     isNewDashboardFeatureEnabledForDynamicAdvisor={isNewDashboardFeatureEnabledForDynamicAdvisor}
                     daAccountsData={daAccountsData}
                     showModel={showModel}
                     handleShow={this.handleShow}
                     customerProfile={customerProfile}
                     onSingleSignOnClick={
                         this.onSingleSignOnClick
                     }
                     getDashBoardAccordianLinks={
                         getDashBoardAccordianLinks
                     }
                  
                     history={history}
                     handleAscensusClick={
                         this.handleAscensusClick
                     }
                     onClickPopupRedirecton={this.onClickPopupRedirecton}
                     handleAccordionClick={this.handleAccordionClick}
                     mfaccountsData={mfAccountData}
                     braccountsData={brAccountsData}
                     childrenAccountsData={childrenAccountsData}
                     holdingGroupDescription={holdingGroupDescription}
                     // Redirection methods
                     placeATradeonSingleSignOnClick={this.placeATradeonSingleSignOnClick}
                     dashboardOpenAnAccount={dashboardOpenAnAccount}
                     dashboardAccountServices={dashboardAccountServices}
                     loadDashboardContent={this.loadDashboardContent}
                     dashboardApiResponseData = {this.props.memberNewDashboardReducer}
                     dashbordContentData= {this.state.dashbordContentData}
                     savedItemsForDashbord={this.state.savedItemsForDashbord}
                     systemHealth={this.state.systemHealth}
                     removeSavedListItem = {this.removeSavedListItem}
                     wealthInformationData = {profileInformationData?.profileInformation}
                     isHNWDataObj = {customerProfile}
                     chartLoading={newMemberDashboardLoader}
                     roleInfo = {this.props?.loginState?.getCustomerProfile?.actualRole}
                     openBrokerageAccount = {openBrokerageAccount}
                     getPostionData = {this.getAllowedTransaction}
                     />

                 : 
                        <Col key="1" md={12} lg={12} xl={12} className="portfolioChart">
                            <WCardOverlay
                                className="ac-holder-wcardoverlay"
                                myStyles={assignObj({ marginTop: '30px', minHeight: '29.1875rem' })}
                            >
                                <div>
                                    <SideHeader
                                        title={pageName}
                                        imgSrc={iconAllocation}
                                        width="20px"
                                        height="20px"
                                    />
                                    <div className="graph-main" style={assignObj({ position: 'relative' })}>
                                        <div className="chartMain">
                                            <div
                                                className="showBlankScreen"
                                                style={assignObj({
                                                    display: graphPlottingValues.length < 1 ? 'block' : '',
                                                })}
                                            />
                                            {!isError && graphPlottingValues.length > 0 && (
                                                <>
                                                    <HighChartErrorBoundary>
                                                        <HighChart
                                                            mapData={graphPlottingValues}
                                                            handleChartLoad={this.onChartLoad}
                                                        />
                                                        <div
                                                      
                                                            className="highChartCenter"
                                                        >
                                                            <div>
                                                                <span>{totalPortfolio}</span>
                                                            </div>
                                                            <div
                                                                style={assignObj({
                                                                    border: '2px solid #D2D2D2',
                                                                    display: 'inline-block',
                                                                    textAlign: 'center',

                                                                })}
                                                            >
                                                                <span
                                                                    style={assignObj({
                                                                        fontWeight: 700,
                                                                        fontSize: '16px',
                                                                    })}
                                                                >
                                                                    {' '}
                                                                    {currencyFormatter.format(totalAmount, {
                                                                        locale: 'en-US',
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <div className="text-center">
                                                                <span
                                                                    style={assignObj({
                                                                        fontSize: '20px',
                                                                        color: '#49494A',
                                                                        fontWeight: '600',
                                                                    })}
                                                                >
                                                                    {percentage}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </HighChartErrorBoundary>
                                                </>
                                            )}
                                        </div>
                                        <div
                                                className="chartDetails"
                                                role="table"
                                                aria-label="HoldingGroupDetails"
                                                aria-describedby="holdingGroupDetails_desc"
                                            >
                                            <div className="row">
                                            {/* ------------------Mutual fund accounts------------------ */}
                                            {!isError && !isEmpty(highchartData) && (
                                            <div className="col-md-12"> 
                                            
                                            <div
                                                className="holding-group-main"
                                                style={assignObj({
                                                    marginLeft: 2,
                                                    font: '500 14px/22px benton-sans,sans-serif',
                                                    color: '#49494A',
                                                })}
                                            >
                                                <div role="rowgroup">
                                                    <div className="head p-2" role="row">
                                                        <span role="columnheader">{holdingGroup}</span>
                                                        <span role="columnheader">{balanceUsd}</span>
                                                    </div>
                                                </div>
                                                <div role="rowgroup">
                                                    <div className="head p-2" role="row">
                                                        <span role="columnheader">{mutualFund}</span>
                                                       
                                                    </div>
                                                </div>
                                                <div className="holding-group-acordian">
                                                    {highchartData.length > 0 &&
                                                        highchartData.map((item, index) => {
                                                            const accordionContentId = `accordionContentRegion${index}`;
                                                            const accordionId = `accordionTitle${index}`;
                                                            return (
                                                                <div role="rowgroup" key={accordionId}>
                                                                    <div
                                                                        role="row"
                                                                        style={assignObj({
                                                                            paddingTop: 10,
                                                                            paddingBottom: 16,
                                                                            borderBottom:
                                                                                '1px solid rgba(210, 210, 210, 1)',
                                                                        })}
                                                                    >
                                                                        <Row
                                                                            className="w-100"
                                                                            data-test="AccordionTitleContent"
                                                                            noGutters
                                                                        >
                                                                            <Col md={6} lg={6} role="cell">
                                                                                <Button
                                                                                    onClick={this.handleAccordionClick(
                                                                                        index,
                                                                                        item.open,
                                                                                        'mf-account'
                                                                                    )}
                                                                                    aria-controls={
                                                                                        accordionContentId
                                                                                    }
                                                                                    aria-expanded={item.open}
                                                                                    variant="h3"
                                                                                    id={accordionId}
                                                                                    className="p-0 w-100"
                                                                                >
                                                                                    <AccordionTitleContent.Title
                                                                                        accType={assignObj({
                                                                                            holdingGroup:
                                                                                                item.groupName,
                                                                                            balance:
                                                                                                item.groupTotal,
                                                                                            c: item.c,
                                                                                            r: item.r,
                                                                                            open: item.open,
                                                                                        })}
                                                                                    />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col
                                                                                md={6}
                                                                                lg={6}
                                                                                style={assignObj({
                                                                                    position: 'relative',
                                                                                })}
                                                                                role="cell"
                                                                            >
                                                                                <AccordionTitleContent.Balance
                                                                                    accType={assignObj({
                                                                                        holdingGroup:
                                                                                            item.groupName,
                                                                                        balance: item.groupTotal,
                                                                                        c: item.c,
                                                                                        r: item.r,
                                                                                        open: item.open,
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Collapse in={item.open} className="holding-sub-group">
                                                                            <div
                                                                                id={accordionContentId}
                                                                                role="region"
                                                                                aria-labelledby={accordionId}
                                                                            >
                                                                                <AccordionContent
                                                                                    amount={item.groupTotal}
                                                                                    investement={item.accounts}
                                                                                    getDashBoardAccordianLinks={
                                                                                        getDashBoardAccordianLinks
                                                                                    }
                                                                                    dashBoardLinkData={
                                                                                        dashBoardLinkData
                                                                                    }
                                                                                    
                                                                                    history={history}
                                                                                   
                                                                                    handleMSRAscensusClick={
                                                                                        this.handleMSRAscensusClick
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </Collapse>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                               
                                                       
                                               {!isError && !isEmpty(brAccountsData) && (
                                            <div className="rowgroup"> 
                                            
                                            <div
                                                className="holding-group-main"
                                                style={assignObj({
                                                    marginLeft: 2,
                                                    font: '500 14px/22px benton-sans,sans-serif',
                                                    color: '#49494A',
                                                })}
                                            >
                                                <div role="rowgroup">
                                                    <div className="head p-2" role="row">
                                                    <span role="columnheader">{brokerageAccounts}</span>
                                                    </div>
                                                </div>
                                                <div className="holding-group-acordian">
                                                    {brAccountsData.length > 0 &&
                                                        brAccountsData.map((item, index) => {
                                                            const accordionContentId = `accordionContentRegion${index}`;
                                                            const accordionId = `accordionTitle${index}`;
                                                            return (
                                                                <div role="rowgroup" key={accordionId}>
                                                                    <div
                                                                        role="row"
                                                                        style={assignObj({
                                                                            paddingTop: 10,
                                                                            paddingBottom: 16,
                                                                            borderBottom:
                                                                                '1px solid rgba(210, 210, 210, 1)',
                                                                        })}
                                                                    >
                                                                        <Row
                                                                            className="w-100"
                                                                            data-test="AccordionTitleContent"
                                                                            noGutters
                                                                        >
                                                                            <Col md={6} lg={6} role="cell">
                                                                                <Button
                                                                                    onClick={this.handleAccordionClick(
                                                                                        index,
                                                                                        item.open,
                                                                                        'br-account'
                                                                                    )}
                                                                                    aria-controls={
                                                                                        accordionContentId
                                                                                    }
                                                                                    aria-expanded={item.open}
                                                                                    variant="h3"
                                                                                    id={accordionId}
                                                                                    className="p-0 w-100"
                                                                                >
                                                                                    <AccordionTitleContent.Title
                                                                                        accType={assignObj({
                                                                                            holdingGroup:
                                                                                                item.groupName,
                                                                                            balance:
                                                                                                item.groupTotal,
                                                                                            c: item.c,
                                                                                            r: item.r,
                                                                                            open: item.open,
                                                                                        })}
                                                                                    />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col
                                                                                md={6}
                                                                                lg={6}
                                                                                style={assignObj({
                                                                                    position: 'relative',
                                                                                })}
                                                                                role="cell"
                                                                            >
                                                                                <AccordionTitleContent.Balance
                                                                                    accType={assignObj({
                                                                                        holdingGroup:
                                                                                            item.groupName,
                                                                                        balance: item.groupTotal,
                                                                                        c: item.c,
                                                                                        r: item.r,
                                                                                        open: item.open,
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Collapse in={item.open} className="holding-sub-group">
                                                                            <div
                                                                                id={accordionContentId}
                                                                                role="region"
                                                                                aria-labelledby={accordionId}
                                                                            >
                                                                                <AccordionContent
                                                                                    amount={item.groupTotal}
                                                                                    investement={item.accounts}
                                                                                    getDashBoardAccordianLinks={
                                                                                        getDashBoardAccordianLinks
                                                                                    }
                                                                                    dashBoardLinkData={
                                                                                        dashBoardLinkData
                                                                                    }
                                                                                    getGeneratedSAMLAssertion={
                                                                                        this.onSingleSignOnClick
                                                                                    }
                                                                                    history={history}
                                                                                   
                                                                                    handleMSRAscensusClick={
                                                                                        this.handleMSRAscensusClick
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </Collapse>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                               
                                                        {/* -------------------------- BR accounts------------------- */}
                                                        
                                       
                                                </div>
                                                </div>
                                            </div>
                                           
                                            )} 
                                                </div>
                                                </div>
                                            </div>
                                           
                                            )} 

                                           
                                        
                                            </div> 
                                        </div>
                                          

                                        {isError && (
                                            <div
                                                style={assignObj({
                                                    position: 'absolute',
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    background: ' #ffffff',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'red',
                                                    font: '500 16px/24px benton-sans,sans-serif',
                                                })}
                                            >
                                                {message}
                                            </div>
                                        )}
                                    </div>
                               </div>
                            </WCardOverlay>
                        </Col>
                    }
                    </CardDeck>
                </div>
            </div>
        );
    }
}

AccountHolder.defaultProps = {
    localStateData: {},
    memberDashboardData: {},
    memberNewDashboardReducer:{},
    profileInformationData: {},
    getAccountSummary: () => {},
    getDashBoardAccordianLinks: () => {},
    dashBoardLinkData: {},
    history: {},
    manageLocalState: () => {},
    analyzeClickEvent: () => {},
    clearLocalStateForNAO: () => {},
    clearLocalStateForAccOpening: () => {},
    getPhoneSupportHoursOfOperation: () => {},
    clearMarketNAOInfo: () => {},
    resetTransactionHolder: () => {},
    clearMasterRegInfo: () => {},
    resetAllNewMemberDashbaordData: () => {},
    resetAllMemberDashbaordData: () => {},
    getMarketSavedApplications:()=>{},
    loginState: {},
    getCustomerProfile: () => {},
};

AccountHolder.propTypes = {
    memberDashboardData: PropTypes.instanceOf(Object),
    memberNewDashboardReducer:PropTypes.instanceOf(Object),
    profileInformationData: PropTypes.instanceOf(Object),
    getAccountSummary: PropTypes.func,
    getDashBoardAccordianLinks: PropTypes.func,
    dashBoardLinkData: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    analyzeClickEvent: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    clearLocalStateForAccOpening: PropTypes.func,
    getPhoneSupportHoursOfOperation: PropTypes.func,
    clearMarketNAOInfo: PropTypes.func,
    resetTransactionHolder: PropTypes.func,
    resetAllNewMemberDashbaordData: PropTypes.func,
    resetAllMemberDashbaordData: PropTypes.func,
    getMarketSavedApplications: PropTypes.func,
    loginState: PropTypes.instanceOf(Object),
    getCustomerProfile: PropTypes.func,
};

export default AccountHolder;
