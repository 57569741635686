/**
 * Investment Selection List Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component allows user to filter,compare and select mutual funds and payment method
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Karibandi Lalitha
 * @lastModifiedReason added message bars
 */

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import {
//     faMoneyCheckAlt,
//     faHandshake,
//     faUniversity,
//     faMoneyBillAlt,
//     faPhoneAlt,
// } from '@fortawesome/free-solid-svg-icons';
import {
    // WInputCheckBox,
    WBreadCrumb,
    WButton,
    CommonButtons,
    WStepper,
    WModal,
    WSpinner,
    WIcon,
    WSaveModal,
    WMessageBar,
    FundCard,
    WAlertComponent,
    ShowFilterCriteria,
    ConformationModal,
} from '../../../common';
import './FundSelection.css';
import FilterFundsComponent from '../FilterFunds/FilterFundsComponent';
import styles from './styles';
import * as CONST from './consts';
import * as ActionTypes from '../../../shared/ReduxConstants/ServiceActionConstants';
import filterActive from '../../../assets/filter-active.png';
import angleDownCircle from '../../../assets/angleDownCircle.png';
import sortIcon from '../../../assets/iconSortby.svg';
import AccGlobalConstants from '../AccountManagementConstants';

const breadCrumURL = [
    { url: '/accountholder', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];

const breadCrumbURLGuest = [
    { url: '/accountguestview', name: 'Home' },
    { url: '/openAccount', name: 'Open Account' },
];


const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
};

export default class FundSelectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // errorMsg: [],
            showFilterFunds: false,
            fundListReturnedByAPI: [],
            selectedFundsList: [],
            loading: true,
            selectedFundCount: 0,
            // fundsList:[...ApiReturnedFundsList.map(c=>({...c,checked:false}))],
            displayFundList: [],
            minimumInvestmentList: [],
            riskList: [],
            fundTypeList: [],
            filteredMIL: [],
            filteredRisk: [],
            filteredFundType: [],
            filterList: [],
            clearFilters: false,
            totalInitialInvestment: 0,
            totalMonthlyInvestment: 0,
            noMoreFunds: false,
            minimumAscendFlag: false,
            fundNameAscendFlag: false,
            monthlyAscendFlag: false,
            riskAscendFlag: false,
            next: false,
            back: false,
            cancel: false,
            showSaveModal: false,
            errorFromMasterData: false,
            showAlert: false,
            errorMessage: '',
            filteredFundsList: [],
            selectedFundIndex: 0,
            showConfirmCancelModal: false,
        };
    }

    /* ---------------------- Component LifeCycle Methods -------------------------- */

    static getDerivedStateFromProps(nextProps, prevState) {
        const { fundAccountData, localStateData, accOpeningData } = nextProps;
        const { selectedFundsList } = prevState;
        let displayFundList = [];
        displayFundList = localStateData.displayFundList
            ? localStateData.displayFundList
            : fundAccountData.fundAccountListForNAO;

        return {
            ...prevState,
            displayFundList,
            loading: fundAccountData.isLoading,
            selectedFundCount: selectedFundsList.length,
            minimumInvestmentList: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.minimumInvestmentList
                : prevState.minimumInvestmentList,
            riskList: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.riskList
                : prevState.riskList,
            fundTypeList: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.fundTypeList
                : prevState.fundTypeList,
            filteredMIL: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.filteredMIL
                : prevState.filteredMIL,
            filteredRisk: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.filteredRisk
                : prevState.filteredRisk,
            filteredFundType: localStateData.OpenAccPageThree
                ? localStateData.OpenAccPageThree.filterInfo.filteredFundType
                : prevState.filteredFundType,
            showConfirmModal: accOpeningData.showConfirmModal,
            confirmMessage: accOpeningData.confirmMessage,
        };
    }

    componentDidMount() {
        // API call
        const {
            getFundAccountsListForNAO,
            // getFundAccountsList,
            localStateData,
            masterLookupStateData,
            getCompositeLookUpData,
        } = this.props;
        // const { displayFundList } = this.state;
        const token = localStorage.getItem('token');
        // if (displayFundList.length === 0) {

        const fundListPayload = {
            companyNumber: localStateData.companyNumber !== undefined ? localStateData.companyNumber : '591',
            accountTypeCode: this.getAccountTypeCode(localStateData.accountType),
            socialCode: this.getSocialCode(localStateData.accountType),
            accountType: localStateData.accountType,
            state: localStateData.OpenAccPageTwo.personalInfo.mailingAddress.state,
            token,
        };
        getFundAccountsListForNAO(fundListPayload);
        // }
        if (localStateData.displayFundList) {
            this.setState({ displayFundList: localStateData.displayFundList });
        }
        if (localStateData.selectedFundsList) {
            this.setState({ selectedFundsList: localStateData.selectedFundsList }, this.caculateTotalInvestment);
        }
        if (localStateData.selectedFundCount) {
            this.setState({ selectedFundCount: localStateData.selectedFundCount });
        }
        if (localStateData.masterDataLoadedForFundSelection) {
            this.setState({ masterDataLoadedForFundSelection: localStateData.masterDataLoadedForFundSelection });
        }
        // if (localStateData.masterDataLoadedForFundSelection) {
        //     this.setState({
        //         minimumInvestmentList: masterLookupStateData.filter_min_inv.value,
        //         fundTypeList: masterLookupStateData.filter_fund_type.value,
        //         riskList: masterLookupStateData.filter_risk.value,
        //         loading: false,
        //     });
        // } else {
        const payload = [];
        const compositePayloadData = CONST.compositePayload;
        for (let i = 0; i < compositePayloadData.length; i += 1) {
            const tempkey = compositePayloadData[parseInt(i, 10)];
            if (this.props && masterLookupStateData && !masterLookupStateData[tempkey.toString()]) {
                payload.push(tempkey);
            }
        }
        getCompositeLookUpData(payload);
        // }
    }

    componentDidUpdate(prevProps) {
        const { showErrorModal } = this.state;
        if (this.props !== prevProps) {
            this.updateState(prevProps);
        }

        if (showErrorModal) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    /* ----------------------End  Component LifeCycle Methods -------------------------- */

    handleSuccessConfirmation = () => {
        const { resetShowConfirmModal } = this.props;
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        resetShowConfirmModal();
    };

    handleExit = () => {
        const { clearLocalStateForNAO, resetShowConfirmModal, history } = this.props;
        clearLocalStateForNAO();
        resetShowConfirmModal();
        const role = localStorage.getItem('role');
        const pathname = role === 'Member' ? '/accountholder' : '/accountguestview';
        this.setState({ showConfirmModal: false, confirmMessage: '' });
        history.push({
            pathname,
        });
    };

    handleCancelClick = () => {
        this.setState({ showConfirmCancelModal: true });
    };

    handleCancelNo = () => {
        this.setState({ showConfirmCancelModal: false, confirmMessage: '' });
    };

    getAccountTypeCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };

    updateState = prevProps => {
        const { accOpeningData, masterLookupStateData } = this.props;
        //  let tempFundListData = [];
        // let tempFilterMinInvList = [];
        // let tempFilterFTList = [];
        // let tempFilterRiskList = [];
        // let errorList: [];

        // Loader values
        if (masterLookupStateData.isLoading || accOpeningData.isLoading) {
            this.setState({ loading: true });
        } else if (masterLookupStateData.isSuccess || accOpeningData.isSuccess) {
            this.setState({ loading: false });
        }
        //   Loader Values End

        //   Save Function Values Start
        this.handleSaveAPI(accOpeningData, prevProps);

        //   Fund List  Values end
        if (
            accOpeningData[ActionTypes.GET_FUNDLIST] !== undefined &&
            accOpeningData[ActionTypes.GET_FUNDLIST] !== null &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_FUNDLIST] !== prevProps.accOpeningData[ActionTypes.GET_FUNDLIST]
        ) {
            this.handleFundListAPI(accOpeningData);
        }
        //   Fund List  Values end
        // Fund Details API Mapping Start
        if (
            accOpeningData[ActionTypes.GET_FUNDDETAILS] &&
            !accOpeningData.isLoading &&
            accOpeningData[ActionTypes.GET_FUNDDETAILS] !== prevProps.accOpeningData[ActionTypes.GET_FUNDDETAILS]
        ) {
            this.handleFundDetailsAPI(accOpeningData);
        }

        // Error
        this.handleAPIError(accOpeningData, prevProps);

        this.populateMasterData(masterLookupStateData, prevProps);
    };

    handleFundListAPI = accOpeningData => {
        const tempFundListData = accOpeningData[ActionTypes.GET_FUNDLIST];
        if (tempFundListData.length > 0) {
            this.setState(
                {
                    fundListReturnedByAPI: tempFundListData,
                    displayFundList: [
                        ...tempFundListData.map(c => ({
                            ...c,
                            checked: false,
                            monthlyInvestment_value: c.monthlyInvestment,
                            initialInvestment_value: c.initialInvestment,
                        })),
                    ],
                },
                this.calculateDisplayFundList(0),
                this.caculateTotalInvestment(),
            );
        } else if (tempFundListData.errorCode && tempFundListData.errorMessage) {
            const errorDescription = tempFundListData.errorMessage;
            this.setState(
                {
                    showAlert: true,
                    errorMessage: {
                        info: `${errorDescription}----Please select other filter combination`,
                    },
                    isErrorBar: true,
                    displayFundList: [],
                    selectedFundsList: [],
                },
                this.calculateDisplayFundList(0),
                this.caculateTotalInvestment(),
            );
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    handleFundDetailsAPI = accOpeningData => {
        const tempData = accOpeningData[ActionTypes.GET_FUNDDETAILS];
        if (tempData.errorCode && tempData.result) {
            const errorDescription = tempData.result;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: errorDescription,
                },
                isErrorBar: true,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } else if (!tempData.errorCode) {
            this.setState(
                {
                    selectedFundForSummary: tempData,
                },
                () => {
                    const { fundSummaryClicked, compareFundsClicked, selectedFundForSummary } = this.state;

                    if (Object.values(selectedFundForSummary).length > 0) {
                        if (fundSummaryClicked) {
                            this.setState({ showFundSummary: true, showCompareFunds: false });
                        } else if (compareFundsClicked) {
                            this.setState({ showFundSummary: false, showCompareFunds: true });
                        }
                    }
                },
            );
        }
    };

    handleSaveAPI = (accOpeningData, prevProps) => {
        if (
            accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT &&
            !accOpeningData.isLoading &&
            accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT !== prevProps.accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT
        ) {
            // let msg;
            if (accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.status) {
                //  msg = accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.status;
                this.setState({ showSaveModal: true, loading: false });
            } else {
                const msg = accOpeningData.INVEST_SELECT_SAVE_OPENING_ACCT.message;
                this.setState({
                    showAlert: true,
                    errorMessage: {
                        info: msg,
                    },
                    isErrorBar: true,
                    loading: false,
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    handleAPIError = (accOpeningData, prevProps) => {
        // Error
        if (
            accOpeningData.isError &&
            accOpeningData.response &&
            accOpeningData.response !== prevProps.accOpeningData.response
        ) {
            const msg = accOpeningData.response.errorMessage || accOpeningData.response.message;
            this.setState({
                showAlert: true,
                errorMessage: {
                    info: msg,
                },
                isErrorBar: true,
                loading: false,
            });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    populateMasterData = (masterLookupStateData, prevProps) => {
        let tempFilterMinInvList = [];
        let tempFilterFTList = [];
        let tempFilterRiskList = [];
        if (masterLookupStateData !== prevProps.masterLookupStateData) {
            if (masterLookupStateData.filter_min_inv && masterLookupStateData.filter_min_inv.value) {
                tempFilterMinInvList = masterLookupStateData.filter_min_inv.value;
                this.setState({
                    minimumInvestmentList: [...tempFilterMinInvList.map(c => ({ ...c, checked: false }))],
                });
            }
            if (masterLookupStateData.filter_risk && masterLookupStateData.filter_risk.value) {
                tempFilterRiskList = masterLookupStateData.filter_risk.value;
                this.setState({ riskList: [...tempFilterRiskList.map(c => ({ ...c, checked: false }))] });
            }
            if (masterLookupStateData.filter_fund_type && masterLookupStateData.filter_fund_type.value) {
                tempFilterFTList = masterLookupStateData.filter_fund_type.value;
                this.setState({ fundTypeList: [...tempFilterFTList.map(c => ({ ...c, checked: false }))] });
            }

            // Master data API Error
            if (masterLookupStateData.isError === true) {
                this.setState({ errorFromMasterData: true });
            } else if (masterLookupStateData.isError === false) {
                this.setState({ errorFromMasterData: false });
            }
            //
            this.setState({ masterDataLoadedForFundSelection: true });
        }
    };

    /* ---------------------Calculate the fundslist to be displayed --------------------*/

    calculateDisplayFundList = lastIndex => {
        const { displayFundList } = this.state;
        const disArr = [...displayFundList];
        const updatedList = [];
        if (lastIndex < disArr.length) {
            for (let i = 0 || lastIndex; i < disArr.length; i += 1) {
                if (updatedList.length < 10) {
                    updatedList.push(disArr[parseInt(i, 10)]);
                }
            }
            this.setState({ displayFundList: updatedList });
        } else if (disArr.length > 0) {
            this.setState({ noMoreFunds: true, displayFundList: [] });
        }
    };

    /* ---------------------End Calculate the fundslist to be displayed --------------------*/
    caculateTotalInvestment = () => {
        const { selectedFundsList } = this.state;
        const arr = [...selectedFundsList];
        let totalInitial = 0;
        let totalMonthly = 0;
        for (let i = 0; i < arr.length; i += 1) {
            totalInitial += Number(arr[+i].initialInvestment);
            totalMonthly += Number(arr[+i].monthlyInvestment);
        }
        // let disableNext=true
        // if(arr.length>0){
        //     disableNext=false
        // }
        this.setState({
            totalInitialInvestment: parseFloat(totalInitial).toFixed(2),
            totalMonthlyInvestment: parseFloat(totalMonthly).toFixed(2),
            // disableNext
        });
    };

    handleFilterFunds = () => {
        const { showFilterFunds } = this.state;
        this.setState({ showFilterFunds: !showFilterFunds });
    };

    onFundListCheckboxClick = (index, e) => {
        const { displayFundList, selectedFundsList = [], selectedFundCount } = this.state;
        e.stopPropagation();
        const list = [...displayFundList];
        for (let i = 0; i < list.length; i += 1) {
            if (list[parseInt(i, 10)].fundNumber === e.target.id) {
                if (selectedFundsList.length < 10 || (selectedFundsList.length >= 10 && e.target.checked === false)) {
                    list[parseInt(i, 10)].checked = e.target.checked;
                }
                list[parseInt(i, 10)].initialInvest = list[parseInt(i, 10)].initialInvest
                    ? this.formatInputValues(list[parseInt(i, 10)].initialInvest)
                    : this.formatInputValues(list[parseInt(i, 10)].initialInvestment);
                list[parseInt(i, 10)].monthlyInvest = list[parseInt(i, 10)].monthlyInvest
                    ? this.formatInputValues(list[parseInt(i, 10)].monthlyInvest)
                    : this.formatInputValues(list[parseInt(i, 10)].minimumAutomaticInvestment);
                list[parseInt(i, 10)].minimumAutomaticInvestment = this.formatInputValues(
                    list[parseInt(i, 10)].minimumAutomaticInvestment,
                );
                list[parseInt(i, 10)].initialInvestment = this.formatInputValues(
                    list[parseInt(i, 10)].initialInvestment,
                );
                list[parseInt(i, 10)].startDateValue = list[parseInt(i, 10)].startDateValue
                    ? list[parseInt(i, 10)].startDateValue
                    : list[parseInt(i, 10)].startDate;
                list[parseInt(i, 10)].fundingOption = list[parseInt(i, 10)].fundingOption
                    ? list[parseInt(i, 10)].fundingOption
                    : 'init';
                list[parseInt(i, 10)].startDate_err = '';
                list[parseInt(i, 10)].monthlyInvestment_err = '';
                list[parseInt(i, 10)].initialInvestment_err = '';
            }
        }
        if (e.target.checked) {
            if (selectedFundsList.length < 10) {
                const sfIndex = list.findIndex(fund => fund.fundNumber === e.target.id);
                selectedFundsList.push(list[parseInt(sfIndex, 10)]);
                this.caculateTotalInvestment();
                this.setState({ selectedFundCount: selectedFundCount + 1, selectedFundsList, showErrorModal: false });
            } else {
                this.setState({
                    showErrorModal: true,
                    modalAlertMessage: 'Please select upto 10 funds only.',
                    selectedFundsList,
                    displayFundList,
                });
            }
        } else {
            const eindex = selectedFundsList.findIndex(fundSel => fundSel.fundNumber === e.target.id);
            if (eindex > -1) {
                selectedFundsList.splice(eindex, 1);
                this.setState({ selectedFundsList });
                this.caculateTotalInvestment();
            }
            this.setState({ selectedFundCount: selectedFundCount - 1, showErrorModal: false });
        }
        // if (selectedFundsList.length > 0) {
        //     this.setState({ showErrorModal: false });
        // }

        this.setState({ displayFundList: list });
    };

    loadMoreClick = () => {
        const { setNextPageNumber } = this.props;
        setNextPageNumber();
    };

    handleClearFilters = () => {
        const {
            localStateData,
            fundAccountData,
            filterFundsListNAOForLocalState,
            resetPageNumber,
            filterFundsListNAO,
        } = this.props;
        const { OpenAccPageThree } = localStateData;
        if (OpenAccPageThree) {
            //  call save to local
            const { minimumInvestmentList, riskList, fundTypeList } = this.state;
            const filterData = {
                minimumInvestmentList,
                riskList,
                fundTypeList,
                filteredMIL: [],
                filteredRisk: [],
                filteredFundType: [],
            };
            this.saveToLocal(true, filterData);
        }
        const { NAOFundAccountsList } = fundAccountData;
        const payload = { filteredMIL: [], filteredRisk: [], filteredFundType: [], NAOFundAccountsList };
        if (localStateData.displayFundList) {
            filterFundsListNAOForLocalState(payload);
        } else {
            filterFundsListNAO(payload);
        }
        resetPageNumber();
        this.setState({
            clearFilters: true,
            filteredMIL: [],
            filteredRisk: [],
            filteredFundType: [],
            filterList: [],
        });
    };

    handleApplyFilters = () => {
        const { filteredMIL, filteredRisk, filteredFundType, displayFundList } = this.state;
        const {
            filterFundsListNAO,
            fundAccountData,
            localStateData,
            filterFundsListNAOForLocalState,
            resetPageNumber,
        } = this.props;
        const { NAOFundAccountsList } = fundAccountData;
        const payload = { filteredMIL, filteredRisk, filteredFundType, NAOFundAccountsList };
        const updateDisplayArray = [...displayFundList];
        for (let i = 0; i < updateDisplayArray.length; i += 1) {
            updateDisplayArray[parseInt(i, 10)].checked = false;
        }

        if (localStateData.displayFundList) {
            filterFundsListNAOForLocalState(payload);
        } else {
            filterFundsListNAO(payload);
        }
        resetPageNumber();
        this.setState({
            showFilterFunds: false,
            selectedFundsList: [],
            selectedFundCount: 0,
            filterList: [...filteredMIL, ...filteredRisk, ...filteredFundType],
            displayFundList: updateDisplayArray,
        });
    };

    handleMILFilter = list => {
        this.setState({ filteredMIL: list });
    };

    handleRiskFilter = list => {
        this.setState({ filteredRisk: list });
    };

    handleFTFilter = list => {
        this.setState({ filteredFundType: list });
    };

    handleParent = () => {
        this.setState({
            clearFilters: false,
        });
    };

    handleCompareFunds = () => {
        const { getFundDetailsData } = this.props;
        const { selectedFundsList } = this.state;
        this.saveToLocal();
        if (selectedFundsList.length > 1) {
            if (selectedFundsList.length < 5) {
                // const payload = {}; // replace when APi is returning records with fundNumbers
                /* selectedFundsList.map((item,index)=>{
                    payload[`fundNumber${index+1}`] = item.fundNumber;
                    return payload;
                });
                getFundDetailsData(payload); */

                const finalPayload = {
                    fundNumber1: '330',
                    fundNumber2: '331',
                };
                getFundDetailsData(finalPayload);
                this.setState({ compareFundsClicked: true });
            } else {
                this.setState({ showErrorModal: true, modalAlertMessage: CONST.compareAlertMsg });
            }
        } else {
            this.setState({ showErrorModal: true, modalAlertMessage: CONST.compareAlertMsg });
        }
    };

    handleFundSummary = (fund, index) => {
        let { displayFundList } = this.state;
        const token = localStorage.getItem('token');

        displayFundList = displayFundList.map((item, curIndex) => {
            const fundItem = { ...item };
            if (curIndex === index) {
                fundItem.selected = !fund.selected;
            } else {
                fundItem.selected = false;
            }
            return fundItem;
        });
        this.setState({ displayFundList, selectedFundIndex: index });
        const { getFundDetailsData } = this.props;
        this.saveToLocal();
        const payload = {
            fundNumber: fund.fundNumber,
            token,
        };

        getFundDetailsData(payload);
        // this.setState({ showFundSummary: true, showCompareFunds: false });
        this.setState({ fundSummaryClicked: true });
    };

    handleFundNameSort = e => {
        const { displayFundList } = this.state;
        e.stopPropagation();
        const list = [...displayFundList];
        const { sortFundList, localStateData, sortFundListLocalState } = this.props;
        if (localStateData.displayFundList) {
            sortFundListLocalState(list);
        } else {
            sortFundList(list);
        }
    };

    handleMinimumSort = e => {
        const { displayFundList, minimumAscendFlag } = this.state;
        e.stopPropagation();
        const list = [...displayFundList];
        this.setState({ minimumAscendFlag: !minimumAscendFlag }, () => {
            const sortedList = this.getSortedData(list, 'initialInvestment', minimumAscendFlag);
            this.setState({ displayFundList: sortedList });
        });
    };

    getSortedData = (data, prop, isAsc) => {
        return data.sort((a, b) => {
            return (a[prop.toString()] < b[prop.toString()] ? -1 : 1) * (isAsc ? 1 : -1);
        });
    };

    handleMonthlySort = e => {
        const { displayFundList, monthlyAscendFlag } = this.state;
        e.stopPropagation();
        const list = [...displayFundList];
        this.setState({ monthlyAscendFlag: !monthlyAscendFlag }, () => {
            const sortedList = this.getSortedData(list, 'monthlyInvestment', monthlyAscendFlag);
            this.setState({ displayFundList: sortedList });
        });
    };

    handleRiskSort = e => {
        const { displayFundList, riskAscendFlag } = this.state;
        e.stopPropagation();
        const list = [...displayFundList];
        this.setState({ riskAscendFlag: !riskAscendFlag }, () => {
            const sortedList = this.getSortedData(list, 'risk', riskAscendFlag);
            this.setState({ displayFundList: sortedList });
        });
    };

    handleModal = val => {
        if (val === 'continue') {
            this.setState({ showSaveModal: false, loading: false });
        } else {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
            this.setState({ showSaveModal: false, cancel: true, loading: false });
        }
    };

    handleCancel = () => {
        this.setState({ cancel: true });
    };

    handleBack = () => {
        const { setCustomerVerificationStatus } = this.props;
        setCustomerVerificationStatus();
        this.setState({ back: true }, this.saveToLocal());
    };

    validateFields = () => {
        const { selectedFundsList } = this.state;
        let flag = true;
        if (selectedFundsList && selectedFundsList.length === 0) {
            flag = false;
            this.setState({ showErrorModal: true, modalAlertMessage: CONST.ATLEAST_ONE_FUND_ERROR_MESSAGE });
        }
        return flag;
    };

    saveToLocal = (clearFilter = false, filterData = {}, action = '') => {
        const {
            fundListReturnedByAPI,
            displayFundList,
            selectedFundsList,
            masterDataLoadedForFundSelection,
            totalInitialInvestment,
            totalMonthlyInvestment,
            selectedFundCount,
            minimumInvestmentList,
            riskList,
            fundTypeList,
            filteredMIL,
            filteredRisk,
            filteredFundType,
        } = this.state;
        const { manageLocalState, localStateData, saveApplication } = this.props;
        const data = {
            displayFundList,
            selectedFundsList,
            masterDataLoadedForFundSelection,
            totalInitialInvestment,
            totalMonthlyInvestment,
            fundListReturnedByAPI,
            selectedFundCount,
        };
        let payload = {
            investmentInfo: {
                fundListData: selectedFundsList,
            },
            filterInfo: {
                minimumInvestmentList,
                riskList,
                fundTypeList,
                filteredMIL,
                filteredRisk,
                filteredFundType,
            },
        };

        if (clearFilter) {
            payload = {
                investmentInfo: {
                    fundListData: [],
                },
                filterInfo: filterData,
            };
        }
        if (action === 'save') {
            const savePayload = { ...localStateData, OpenAccPageThree: payload, ...data };
            saveApplication({
                token: localStorage.getItem('token'),
                saveApplicationPayload: {
                    accountType: localStateData.accountType,
                    payload: savePayload,
                },
            });
        } else {
            manageLocalState({ OpenAccPageThree: payload, ...data });
        }
    };

    handleNext = () => {
        this.setState({ next: this.validateFields() }, () => {
            this.saveToLocal();
        });
    };

    handleSave = () => {
        this.setState({ showSaveModal: false }, () => {
            if (this.validateFields()) {
                this.saveToLocal(false, {}, 'save');
            }
        });
    };

    handleHide = () => this.setState({ showFilterFunds: false });

    formatInputValues = val => {
        // return val ? parseFloat(val.toString().replace(/[a-zA-Z,$.]/g, '')) : 0;
        return val && parseFloat(val).toFixed(2);
    };

    getfundsDetailsList = fund => {
        const isWeekValuesMinAvailable = fund.min52W !== '-';
        const isWeekValuesMaxAvailable = fund.max52W !== '-';
        return [
            {
                key: 'Min. / Min. Amount w/ Auto Investing',
                value: ` $ ${parseFloat(this.formatInputValues(fund.initialInvestment)).toFixed(2)} / $ ${parseFloat(
                    this.formatInputValues(fund.minimumInitialMonthlyInvestment),
                ).toFixed(2)} w/ $ ${parseFloat(this.formatInputValues(fund.minimumAutomaticInvestment)).toFixed(2)}`,
                // value: ` $ ${Number(fund.initialInvestment_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')} / $ ${Number(fund.maxInvestment).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')} w/ $ ${Number(fund.monthlyInvestment_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`,
            },
            {
                key: 'Change in NAV',
                value: `${parseFloat(fund.changeInNav).toFixed(2)}`,
            },
            {
                key: 'Last NAV (Previous day close)',
                value: `$ ${parseFloat(fund.lastNav).toFixed(2)}`,
            },
            {
                key: '52 week Min. / Max. Values',
                value: `${isWeekValuesMinAvailable ? '$ ' : ''}${
                    isWeekValuesMinAvailable ? parseFloat(fund.min52W).toFixed(2) : fund.min52W
                } / ${isWeekValuesMaxAvailable ? '$ ' : ''}${
                    isWeekValuesMaxAvailable ? parseFloat(fund.max52W).toFixed(2) : fund.max52W
                }`,
                // value: `$ ${Number(fund.min52W).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')} / $ ${Number(fund.max52W).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`,
            },
            {
                key: 'Risk:',
                value: fund.risk,
            },
        ];
    };

    handleMessageBarClose = () => {
        this.setState({ showAlert: false });
    };

    removeFilterCriteriaHandler = keyVal => e => {
        let { filteredMIL, filteredRisk, filteredFundType } = this.state;
        const { filterList, minimumInvestmentList, riskList, fundTypeList } = this.state;

        const { filterFundsListNAO, fundAccountData } = this.props;
        const { NAOFundAccountsList } = fundAccountData;

        e.stopPropagation();

        filteredMIL = filteredMIL.filter(item => item !== keyVal);
        filteredRisk = filteredRisk.filter(item => item !== keyVal);
        filteredFundType = filteredFundType.filter(item => item !== keyVal);
        const filterListValues = filterList.filter(item => item !== keyVal);

        const minimumInvestmentIndex = minimumInvestmentList.findIndex(item => item.value === keyVal);
        if (minimumInvestmentIndex >= 0) {
            minimumInvestmentList[parseInt(minimumInvestmentIndex, 10)].checked = false;
        }

        const riskListIndex = riskList.findIndex(item => item.value === keyVal);
        if (riskListIndex >= 0) {
            riskList[parseInt(riskListIndex, 10)].checked = false;
        }

        const fundTypeListIndex = fundTypeList.findIndex(item => item.value === keyVal);
        if (fundTypeListIndex >= 0) {
            fundTypeList[parseInt(fundTypeListIndex, 10)].checked = false;
        }

        this.setState({
            filteredMIL,
            filteredRisk,
            filteredFundType,
            filterList: filterListValues,
            minimumInvestmentList,
            riskList,
            fundTypeList,
        });

        const payload = { filteredMIL, filteredRisk, filteredFundType, NAOFundAccountsList };
        filterFundsListNAO(payload);
    };

    render() {
        const {
            showSaveModal,
            showFilterFunds,
            minimumInvestmentList,
            riskList,
            fundTypeList,
            clearFilters,
            displayFundList,
            next,
            back,
            cancel,
            loading,
            selectedFundCount,
            errorFromMasterData,
            showFundSummary,
            selectedFundForSummary,
            showAlert,
            errorMessage,
            showCompareFunds,
            filteredMIL,
            filteredFundType,
            filteredRisk,
            filterList,
            isErrorBar,
            showErrorModal,
            modalAlertMessage,
            selectedFundIndex,
            showConfirmModal,
            confirmMessage,
            showConfirmCancelModal,
            // disableNext=true
        } = this.state;

        const { accountOpeningPages, assignObj } = AccGlobalConstants;
        if (errorFromMasterData) {
            throw new Error(CONST.invSelectMasterDataError);
        }
        const { getPageHeading } = AccGlobalConstants;
        const { localStateData, accOpeningData } = this.props;
        const { accountType } = localStateData || {};
        const pageHeadingText = getPageHeading(accountType);
        const accountTypeBackLabel = 'Back to Personal Info';

        const fundSummeryData = Object.assign(
            selectedFundForSummary ? selectedFundForSummary : {},
            displayFundList ? displayFundList[Number(selectedFundIndex)] : {},
        );

        return (
            <>
                {accOpeningData.isLoadingApplication && <WSpinner loading={accOpeningData.isLoadingApplication} />}
                {loading ? <WSpinner loading={loading} /> : ''}
                {showConfirmModal && (
                    <ConformationModal
                        modalTitle="Save Successful"
                        modalBodyText={confirmMessage}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleSuccessConfirmation}
                        secondaryButtonText="Exit"
                        onSecondaryClick={this.handleExit}
                    />
                )}
                {showConfirmCancelModal && (
                    <ConformationModal
                        modalTitle={AccGlobalConstants.CANCEL_APPLICATION_MSG_TITLE}
                        modalBodyText={AccGlobalConstants.CANCEL_APPLICATION_MSG_BODY}
                        primaryButtonText="Ok"
                        onPrimaryClick={this.handleExit}
                        secondaryButtonText="Cancel"
                        onSecondaryClick={this.handleCancelNo}
                    />
                )}
                <div>
                    <div style={styles.containerStyle}>
                        {showAlert ? (
                            <WMessageBar text={errorMessage} error={isErrorBar} onClose={this.handleMessageBarClose} />
                        ) : null}
                        <div className="container investSelContainer">
                            <WBreadCrumb
                                activeCrumb={accountType}
                                breadCrumbItems={
                                    localStorage.getItem('role') === 'Member' ? breadCrumURL : breadCrumbURLGuest
                                }
                            />
                            <h1 style={styles.pageHeaderStyle}>{pageHeadingText}</h1>
                            <WStepper style={styles.stepperStyle} currentPage={2} pages={accountOpeningPages} />
                            <h2 style={styles.pageSubheaderStyle}>{CONST.CHOOSE_FUNDS}</h2>
                            <Row style={assignObj({ marginBottom: 40, borderBottom: '1px solid #CECECE' })} noGutters>
                                <Col style={styles.pageSubheaderTextStyle}>{CONST.SELECT_FUNDS}</Col>
                            </Row>
                            <WSaveModal show={showSaveModal} modalClick={this.handleModal} />
                            <WModal
                                closebutton="true"
                                header="Filter Funds"
                                headertextstyle={styles.filterHeaderTextStyle}
                                size="lg"
                                dialogClassName="myModal"
                                show={showFilterFunds}
                                onHide={this.handleHide}
                                bodystyle={styles.filterFontStyles}
                                body={
                                    <FilterFundsComponent
                                        minimumInvestmentList={minimumInvestmentList}
                                        minimumInvestmentListSel={filteredMIL}
                                        riskList={riskList}
                                        riskListSel={filteredRisk}
                                        fundTypeList={fundTypeList}
                                        fundTypeListSel={filteredFundType}
                                        onFilterMinimumInvestmentList={this.handleMILFilter}
                                        onFilterFundTypeList={this.handleFTFilter}
                                        onFilterRiskList={this.handleRiskFilter}
                                        clearCheckedFilters={clearFilters}
                                        parent={this.handleParent}
                                    />
                                }
                                footer={
                                    <div style={assignObj({ marginRight: 50 })}>
                                        <WButton
                                            variant="outline"
                                            buttontext="Clear Filters"
                                            className="clearFilters"
                                            buttonstyle={styles.clearFilterButtonStyle}
                                            onClick={this.handleClearFilters}
                                        />
                                        <WButton
                                            buttontext="APPLY FILTER"
                                            buttonstyle={styles.applyFilterButtonStyle}
                                            buttontextstyle={styles.applyFilterButtonTextStyle}
                                            onClick={this.handleApplyFilters}
                                        />
                                    </div>
                                }
                            />
                            <WAlertComponent
                                tabIndex="-1"
                                show={showErrorModal}
                                alertmsg={modalAlertMessage}
                                style={assignObj({ marginBottom: '2.5rem' })}
                            />

                            <Row noGutters>
                                <Col className="noOfItemsSelected">
                                    {displayFundList ? (
                                        <>
                                            <span>{selectedFundCount}</span>
                                            {CONST.OF} {}
                                            <span>{displayFundList.length}</span>
                                            {CONST.ITEMS_SELECTED}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Col>
                                <Col className="tableAction" md="auto">
                                    <WIcon
                                        src={filterActive}
                                        alt="Filter icon"
                                        className="filterIcon"
                                        fontawesomestyle={assignObj({ paddingLeft: 0 })}
                                    />
                                    <WButton
                                        variant="outline"
                                        buttontext="Filter Funds"
                                        buttonstyle={styles.pageButtonStyle}
                                        onClick={this.handleFilterFunds}
                                        aria-haspopup="true"
                                        aria-expanded={showFilterFunds}
                                    />
                                </Col>
                                {/* <Col className="tableAction" md="auto">
                                    <WIcon
                                        src={compareFundsActive}
                                        alt="Compare icon"
                                        className="compareIcon"
                                        fontawesomestyle={assignObj({ paddingLeft: 0 })}
                                        arialabel="Compare Funds"
                                    />
                                    <WButton
                                        variant="outline"
                                        buttontext="Compare Funds"
                                        className="compareFundsButton"
                                        buttonstyle={styles.pageButtonStyle}
                                        onClick={this.handleCompareFunds}
                                        aria-haspopup="true"
                                    />
                                </Col> */}
                                <Col className="tableAction" md="auto">
                                    <WIcon
                                        src={sortIcon}
                                        alt="Sort icon"
                                        className="sortIcon"
                                        fontawesomestyle={assignObj({ paddingLeft: 0 })}
                                        arialabel="Sort by Fund Name"
                                    />
                                    <WButton
                                        variant="outline"
                                        buttontext="Sort By"
                                        buttonstyle={styles.pageButtonStyle}
                                        onClick={this.handleFundNameSort}
                                    />
                                </Col>
                            </Row>

                            {!!filterList.length && (
                                <Row style={assignObj({ display: 'table-row' })} noGutters>
                                    <ShowFilterCriteria
                                        filterList={filterList}
                                        removeFilterCriteriaHandler={this.removeFilterCriteriaHandler}
                                    />
                                </Row>
                            )}

                            {displayFundList &&
                                displayFundList.length > 0 &&
                                displayFundList.map(
                                    (fund, index) =>
                                        index < accOpeningData.currentPageNumber * accOpeningData.pageSize && (
                                            <FundCard
                                                key={fund.fundName + index.toString()}
                                                accountName={fund.fundName}
                                                fundNumber={fund.fundNumber}
                                                details={this.getfundsDetailsList(fund)}
                                                onSwitchClick={Object.assign(e =>
                                                    this.onFundListCheckboxClick(index, e),
                                                )}
                                                checked={!!fund.checked}
                                                // selected={!!fund.selected}
                                                selected={!!fund.checked}
                                                onClick={Object.assign(e => this.handleFundSummary(fund, index, e))}
                                                headerClick
                                            />
                                        ),
                                )}

                            {displayFundList && displayFundList.length === 0 && !loading && (
                                <p className="loadMore"> {CONST.NO_FUNS} </p>
                            )}
                            {!loading &&
                                displayFundList &&
                                displayFundList.length > 0 &&
                                Math.ceil(displayFundList.length / accOpeningData.pageSize) >
                                    accOpeningData.currentPageNumber && (
                                    <div className="loadMore">
                                        <button type="button" onClick={this.loadMoreClick}>
                                            {CONST.LOAD}
                                            {/* <WIcon icon={faAngleDown} size="2x" fontawesomestyle={styles.loadMoreIcon} /> */}
                                            <WIcon
                                                src={angleDownCircle}
                                                size="2x"
                                                fontawesomestyle={styles.loadMoreIcon}
                                            />
                                        </button>{' '}
                                    </div>
                                )}
                        </div>
                        <CommonButtons
                            nextClick={this.handleNext}
                            saveClick={this.handleSave}
                            backClick={this.handleBack}
                            cancelClick={this.handleCancelClick}
                            arialabelback={accountTypeBackLabel}
                            showSave
                            iscancel
                            cancelText="Cancel"
                            cancelStyle={assignObj(cancelButtonStyles)}
                            cancelOffset={assignObj({
                                span: 2,
                                offset: 6,
                            })}
                            // disabled={disableNext}
                        />
                        {next ? (
                            <Redirect
                                push
                                to={assignObj({
                                    pathname: '/investmentSelection',
                                    state: { fundsList: displayFundList },
                                })}
                            />
                        ) : (
                            ''
                        )}
                        {cancel ? <Redirect push to="/" /> : ''}
                        {back ? <Redirect push to="/personalInfo" /> : ''}
                        {showFundSummary ? (
                            <Redirect
                                push
                                to={assignObj({
                                    pathname: '/fundSummary',
                                    state: {
                                        detail: fundSummeryData,
                                    },
                                })}
                            />
                        ) : (
                            ''
                        )}
                        {showCompareFunds ? (
                            <Redirect
                                push
                                to={assignObj({
                                    pathname: '/compareFunds',
                                    state: {
                                        detail: selectedFundForSummary,
                                    },
                                })}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </>
        );
    }
}

FundSelectionComponent.propTypes = {
    accOpeningData: PropTypes.instanceOf(Object),
    localStateData: PropTypes.instanceOf(Object),
    masterLookupStateData: PropTypes.instanceOf(Object),
    saveApplication: PropTypes.func,
    manageLocalState: PropTypes.func,
    getFundDetailsData: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    getFundAccountsListForNAO: PropTypes.func,
    fundAccountData: PropTypes.instanceOf(Object),
    setNextPageNumber: PropTypes.func,
    sortFundList: PropTypes.func,
    sortFundListLocalState: PropTypes.func,
    getCompositeLookUpData: PropTypes.func,
    filterFundsListNAO: PropTypes.func,
    filterFundsListNAOForLocalState: PropTypes.func,
    resetPageNumber: PropTypes.func,
    resetShowConfirmModal: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    setCustomerVerificationStatus: PropTypes.func,
};

FundSelectionComponent.defaultProps = {
    accOpeningData: {},
    localStateData: {},
    masterLookupStateData: {},
    saveApplication: () => {},
    manageLocalState: () => {},
    getFundDetailsData: () => {},
    clearLocalStateForNAO: () => {},
    getFundAccountsListForNAO: () => {},
    fundAccountData: {},
    setNextPageNumber: () => {},
    sortFundList: () => {},
    sortFundListLocalState: () => {},
    getCompositeLookUpData: () => {},
    filterFundsListNAO: () => {},
    filterFundsListNAOForLocalState: () => {},
    resetPageNumber: () => {},
    resetShowConfirmModal: () => {},
    history: {},
    setCustomerVerificationStatus: () => {},
};
