import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const styles = {
    head: {
        position: 'relative',
        marginBottom: '1.4375rem',
        display: 'block',
        borderBottom: '0.125rem solid #8BC105',
    },
    title: {
        font: '800 1.375rem/1.5rem  yorkten-slab-normal',
        color: 'rgb(72, 109, 144)',
    },
    subtitle: {
        font: '600 24px/28px benton-sans,sans-serif',
        color: '#56565A',
    },
    info: {
        font: '500 14px/22px benton-sans, sans-serif',
        color: '#49494A',
        marginBottom: 0,
    },
    wh: {
        position: 'relative',
    },
    imgSec: {
        position: 'relative',
        top: '-0.3125rem',
    },
    alertRed: {
        position: 'absolute',
        top: '-2px',
        left: '8px',
        borderRadius: '5px',
        backgroundColor: '#c71c2c',
        fontSize: '13px',
        padding: '0px 4px',
        color: '#FFFFFF',
        fontWeight: 800,
    },
    noBorderBottom:{
        borderBottom:''
    }
};
const assignObj = obj => obj;
const SideHeader = props => {
    const {
        info,
        title,
        subtitle,
        imgSrc,
        alerts,
        width,
        height,
        h1Styles,
        headStyles,
        imgStyles,
        alt,
        id,
        fontStyles,
        h1Title,
        noBorderBottom=false
    } = props;
    const showALert = alerts !== null && alerts !== 0; // Boolean(alerts !== null);
    let alertsCount=''
    if(showALert){
        alertsCount=alerts>99? '99+' : alerts
    }     
    const messagesText = 'Messages';

    return (
        <div data-test="sideheader">
            <div className="side-header" style={assignObj({ ...styles.head, ...headStyles, borderBottom:`${noBorderBottom ? styles.noBorderBottom.borderBottom : styles.head.borderBottom}` })}>
                <div className="image-section" style={assignObj({ ...styles.imgSec })}>
                    {imgSrc && (
                        <Card.Img
                            src={imgSrc}
                            alt={alt}
                            className="mt-2 float-left"
                            style={assignObj({
                                width,
                                height,
                                position: 'relative',
                                ...imgStyles,
                                marginRight: '1.25rem',
                            })}
                        />
                    )}
                    {showALert && (
                        <span style={styles.alertRed} aria-hidden="true">
                            {alertsCount}
                        </span>
                    )}
                </div>
                <h2 title={h1Title} id={id} style={assignObj({ ...styles.title, ...h1Styles, ...fontStyles })}>
                    {title}
                    {showALert && (
                        <span className="sr-only">
                            {alertsCount}
                            {messagesText}{' '}
                        </span>
                    )}
                </h2>
                <div className="clear" />
            </div>
            {subtitle ? <h2 style={styles.subtitle}>{subtitle}</h2> : null}
            {info
                ? info.map((para, index) => (
                      <p style={styles.info} key={Number(index + 1)}>
                          {para}
                      </p>
                  ))
                : null}
        </div>
    );
};

SideHeader.propTypes = {
    info: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    title: PropTypes.string,
    h1Title:PropTypes.string,
    subtitle: PropTypes.string,
    imgSrc: PropTypes.string,
    alt: PropTypes.string,
    alerts: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    id: PropTypes.string,
    headStyles: PropTypes.shape(React.style),
    h1Styles: PropTypes.shape(React.style),
    imgStyles: PropTypes.shape(React.style),
    fontStyles: PropTypes.shape(React.style),
};

SideHeader.defaultProps = {
    info: null,
    title: 'Title',
    h1Title:'',
    subtitle: null,
    imgSrc: null,
    alerts: null,
    width: '',
    height: '',
    alt: '',
    headStyles: {},
    h1Styles: {},
    imgStyles: {},
    id: '',
    fontStyles: {},
};
export default SideHeader;
