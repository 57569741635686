import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { WBreadCrumb, CommonButtons, WSpinner, WMessageBar, WButton } from 'common';
import routeConstants from 'routeConstants';
import consts from './consts';
import './RMDInfoCard/RMD.css'
import RMDInfoFooter from './RMDComponents/RMDInfoFooter';
import RmdCard from './RMDComponents/RMDCard';
import DeleteRmdModal from './RMDComponents/DeleteRmdModal';
import { getSessionValues } from 'shared/Helpers/Utils';

const nextStyle = {
    display: 'none',
};

const NOTIFICATION_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error'
}

const RmdsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box; 
`;

const CreateRMDContainer = styled.div`
    margin-bottom: 1rem;
`;

const RulesTitle = styled.h3`
    font-size: 1.625rem;
`;

const RulesInfo = styled.div`
    font-size: 1rem;
    max-width: 712px;
    margin-bottom: 2rem;
`;


class RequiredMinimumDistribution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rmdList: [],
            showDeleteModal: false,
            deletedRmd: null,
            deletedAccNumber: null,
            showMenu: false,
            displayNotification: false,
            notificationMsg: '',
            notificationType: NOTIFICATION_TYPE.SUCCESS,
        };
    }

    componentDidMount() {
        const { getRMDTransactions, location } = this.props;
        const { accAdded, accUpdated } = location.state || {};
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();   
        const {accesstoken, vcmid, msrid, fismemberid} = params;        
        const msrParams = {
            accesstoken,
            vcmid,
            msrid,
            fismemberid
        }
        getRMDTransactions(msrParams);

        const message = accAdded ? `You have successfully created a Required Minimum Distribution.` : `You have successfully updated a Required Minimum Distribution.`;

        this.setState(() => {
            const returnObj = {};
            if (accAdded || accUpdated) {
                returnObj.displayNotification = true;
                returnObj.notificationMsg = { message };
                returnObj.notificationType = NOTIFICATION_TYPE.SUCCESS;
            }
            return returnObj;
        });
    }

    static getDerivedStateFromProps(nextProps, state) {
       const {isDelete, updateRmdList, rmdList, isError, error} = nextProps;
       const {deletedAccNumber} = state;
        if(isError) {
            return  {
                rmdList,
                displayNotification: true,
                notificationMsg : { message: error.message },
                notificationType : NOTIFICATION_TYPE.ERROR
            }
        }
       if(isDelete && !isError) {
            updateRmdList({accountNumber:deletedAccNumber})
            return  {
                rmdList,
                displayNotification: true,
                notificationMsg : { message: "You have successfully deleted the withdrawal." },
                notificationType : NOTIFICATION_TYPE.SUCCESS
            }
       }
       return {
            rmdList
        }
    }

    componentWillUnmount() {
        const { resetRmdList } = this.props;
        resetRmdList();
    }

    clickBack = () => {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyObj = {};
        const backRoute = masterRegId ? routeConstants.accountholderTransactions : routeConstants.accountholder;
        historyObj.pathname = backRoute;
        if (masterRegId) {
            historyObj.state = {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    }

    onNotificationClose = () => {
        const { history } = this.props;
        history.push({
            state: { accAdded: false, accUpdated: false, message: '' },
        });
        this.setState({
            displayNotification: false,
            notificationType: NOTIFICATION_TYPE.SUCCESS,
            notificationMsg: ''
        });
    };

    onAddClick = (account) => () => {
        const { history } = this.props;
        history.push({
            pathname: routeConstants.addRequiredMinimumDistribution,
            state: {
                accountNumber: account.accountNumber
            }
        });
    }

    onMenuClick = (account) => () => {
        this.setState(prevState => ({
            showMenu: !prevState.showMenu
        }))
    }

    onEditClick = (account) => () => {
        const { history } = this.props;
        history.push({
            pathname: routeConstants.editRequiredMinimumDistribution,
            state: {
                accountNumber: account.accountNumber,
                edit: true,
            }
        });
    }

    onCloseModal = () => {
        this.setState({
            showDeleteModal: false
        })
    }

    setDeletedRmd = (rmdAccNr) => {
        const { rmdList } = this.state;
        const fundRmd = rmdList.find(item => item.accountNumber === rmdAccNr)
        this.setState({
            showDeleteModal: true,
            deletedRmd: fundRmd,
            deletedAccNumber: rmdAccNr
        })
    }

    gotoadd = () => {
        const { history } = this.props;
        history.push({
            pathname: routeConstants.addRequiredMinimumDistribution,
        });
    }

    deleteRmd = () => {
        const { deletedAccNumber } = this.state;
        const { deleteRMDTransaction } = this.props;

        deleteRMDTransaction({ accountNumber: deletedAccNumber });

        this.setState({ showDeleteModal: false });
    }
    redirectTo = (componentName) => {
        const { history } = this.props;
        history.push({
            pathname: consts.PATH_CONSTANTS[componentName]
        });
    }

    render() {
        const { isLoading, isEligible } = this.props;
        const { displayNotification,notificationType , notificationMsg, showDeleteModal, deletedRmd, rmdList } = this.state;
        
        const isErrorNotification = notificationType === NOTIFICATION_TYPE.ERROR;
        return (
            <>
                {displayNotification && (<WMessageBar
                            error={isErrorNotification}
                            onClose={this.onNotificationClose}
                            text={notificationMsg}
                            doNotClose
                />)}
                <Container>
                    <WBreadCrumb breadCrumbItems={consts.generalUrls}
                        activeCrumb={consts.RMDLabel} />
                    {isLoading && <WSpinner loading={isLoading} />}
                    
                    <h1 className="pageHeading"> {consts.RMDLabel} </h1>
                    <RulesTitle> {consts.RMDRulesTitle} </RulesTitle>
                    <RulesInfo>The CARES Act enabled any taxpayer with an RMD due in 2020 from a retirement plan, including an IRA, to skip those RMDs this year. This IRS guidance includes anyone who turned age 70 1/2 in 2019 and would have had to take the first RMD by April 1, 2020. You can still take a distribution from your retirement accounts, but with RMDs suspended for 2020, you can wait until 2021 before you must take your next minimum distribution.</RulesInfo>
                    {isEligible && (
                        <>
                            <RmdsContainer>
                                {rmdList.length ? rmdList.map(rmdItem => (<RmdCard key={rmdItem.accountNumber} rmdItem={rmdItem} onClick={this.setDeletedRmd} showPopover />)) : '' }
                            </RmdsContainer>
                        </>
                    )}
                    {/* disabled based on env !!!!! */}
                    {!isLoading && isEligible && consts.enableRmd && <CreateRMDContainer><WButton onClick={this.gotoadd} buttontext={rmdList.length ? "Create another RMD" : "Create RMD"} /></CreateRMDContainer>}
                    {showDeleteModal && <DeleteRmdModal
                        modalTitle="Stop withdrawal"
                        modalBodyText="Are you sure you want to stop this withdrawal?"
                        modalBodyText2="Your RMD calculation is based on the prior year-end balance. Any withdrawals made this year will not impact the calculated required minimum distribution amount."
                        primaryButtonText="Stop withdrawal"
                        item={deletedRmd}
                        onPrimaryClick={this.deleteRmd}
                        closeModal={this.onCloseModal}
                    />}
                    <RMDInfoFooter redirectTo={this.redirectTo}/>
                </Container>

                <CommonButtons backClick={this.clickBack} nextstyle={nextStyle} />
            </>
        )
    }
}

RequiredMinimumDistribution.propTypes = {
    isLoading: PropTypes.bool,
    isEligible: PropTypes.bool,
    rmdList: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.shape({}),
}

RequiredMinimumDistribution.defaultProps = {
    isLoading: false,
    isEligible: false,
    rmdList: [],
    history: {}
}

export default RequiredMinimumDistribution;