const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 'normal',
    marginTop: 8
};

const radiostyles = { color: 'rgba(73, 73, 74, 1)', fontSize: 16 };

const radiotext = {
    fontWeight: '600'
}

const subHeading = {
    font: '600 16px/19px benton-sans',
    color: '#49494A',
    marginBottom: 30
}

const checkBox = {
    marginBottom: 40,
    marginTop: 20
}

const checkBoxHeading = {
    marginBottom: 10,
    color: '#49494A'
}

const labelstyle = {
    position: 'relative',
    textAlign: 'left',
    font: '600 16px/22px benton-sans',
    color: '#49494A'
}

const checkInputStyle = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #004A98',
    height: 25,
    width: 25,
    marginRight: 10
}

export default {
    radiodescstyles,
    radiostyles,
    radiotext,
    subHeading,
    checkBox,
    checkBoxHeading,
    labelstyle,
    checkInputStyle
}