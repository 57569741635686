import React,{useRef,useEffect, useState} from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import WCardList from '../WCardList/WCardList';
import Styles from './Styles';
import constant from '../AccountDashboardConstants';
import SideHeader from '../SideHeaderMain/SideHeader';


export default function WLinkCard({ cardData, src, width, height, alt, customStyles, errorMessage }) {
    const { cardLinks } = cardData;
    const { assignObj, noSavedItemsText, mySavedItemsTitle } = constant
    const NoSavedItems = isEmpty(cardLinks) ? noSavedItemsText : ''
    const savedRef = useRef(null);
    const [role,setRole]=useState('')

    useEffect(()=>{
        if(savedRef.current && savedRef.current.scrollHeight > savedRef.current.offsetHeight){
            setRole("region")
        }   
    },[role,setRole,savedRef,cardLinks])

    return (
        <Card style={Styles.cardContainer}>
            <SideHeader title={mySavedItemsTitle} imgSrc={src} width={width} height={height} alt={alt} id="mySavedItems" headStyles={assignObj({ marginBottom: 0 })} />
            <Card.Body ref={savedRef} className="roman cardHeight" style={assignObj({ ...customStyles })} data-test='linkcard' role={role} aria-labelledby="mySavedItems">
                {!isEmpty(cardLinks) && <Card.Title>
                    <WCardList card={cardLinks} />
                </Card.Title>}
                {!isEmpty(errorMessage) ?
                    <div style={assignObj({ color: 'red', padding: '15px 0px 0px 15px', font: '500 16px/24px benton-sans,sans-serif', })}>{errorMessage}</div> :
                    <div style={assignObj({ padding: '15px 0px 0px 15px', font: '500 16px/24px benton-sans,sans-serif', })}>{NoSavedItems}</div>
                }
            </Card.Body>
        </Card>
    );
}

WLinkCard.propTypes = {
    cardData: PropTypes.instanceOf(Object),
    src: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    alt: PropTypes.string,
    customStyles: PropTypes.instanceOf(Object),
    errorMessage: PropTypes.string
};
WLinkCard.defaultProps = {
    cardData: {},
    src: '',
    height: '',
    width: '',
    alt: '',
    customStyles: {},
    errorMessage: ''
};
