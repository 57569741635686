import React, { useCallback, useState, useEffect } from 'react';
import { useTable,useGlobalFilter, useSortBy, usePagination, useExpanded, useRowSelect  } from 'react-table';
import { Row, Col, Table, CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import SortIndicator from '../../../assets/sor-indicator.svg';
import SortIndicatorUpArrow from '../../../assets/sort-indicator-up-arrow.svg';
import GlobalFilter from './GlobalFilter';
import Pagination from "./Pagination";

const C = {};

const S = {
    rectangle: {
        position: 'relative',
        top: '3px',
        left: '0',
        width: '55px',
        height: '26px',
        background: '#004A98 0% 0% no-repeat padding-box',
        opacity: '1',
        color: '#ffffff',
        fontSize: '12px',
    },
    p: {
        top: '5px',
        left: 'calc(50% - 21px)',
        width: '42px',
        height: '14px',
        textAlign: 'center',
        fontSize: '12px',
        letterSpacing: '0',
        color: '#FFFFFF',
        opacity: '1',
        margin: 0,
        position: 'absolute',
    },
    th: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '1em',
        lineHeight: '22px',
        padding: '8px',
        borderTop: 'none',
        color: '#333',
        width: '250px'
       
    },
    td: {
        fontSize: '0.875em',
        textAlign: 'left',
        paddingLeft: '0px',
        color: '#49494A',
        width: '250px'
    },
    arrowStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignContent: ' space-evenly',
    },
    basis1: {
        flexBasis: '30%',
    },
    basis2: {
        flexBasis: '70%',
    },
};



const assignObj = obj => obj;

const DocumentListingTable = props => {
    const {  p, th, td } = S;
    const { documentData, isAdminDocumentManagerAvailable, onDocumentDataChange} = props;
    const data = React.useMemo(() => [...documentData], [documentData]);
    const sortByDate = useCallback((a, b) => {
        if (!a.values.effectiveDate || !b.values.effectiveDate) {
            return Number.MIN_VALUE;
        }
        return new Date(Date.parse(a.values.effectiveDate)) - new Date(Date.parse(b.values.effectiveDate));
    }, []);

    const sortBy = React.useMemo(() => sortByDate, [sortByDate]);
    const columns = React.useMemo(
        () => {

            const commonColumns = [
                {
                    Header: 'Document Name',
                    accessor: 'download',
                    width: 150,
                    disableSortBy: true,
                },
                {
                    Header: 'Document Date',
                    accessor: 'documentDate',
                    width: 200,
                    sortType: sortBy,
                },
                {
                    Header: 'Viewable',
                    accessor: 'viewable',
                    width: 200
                },
                {
                    Header: 'Category',
                    accessor: 'documentCategory',
                    width: 200
                },
                {
                    Header: 'Account',
                    accessor: 'accountDescriptor',
                    width: 200
                },
                {
                    Header: 'Document Id',
                    accessor: 'NodeId',
                    width: 300
                },
                {
                    Header: '',
                    accessor: 'documentTitle',
                    width: 10
                }
            ];
                
          
            if (isAdminDocumentManagerAvailable) {
                commonColumns.unshift({
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className='HeaderCheckbox'>
                            <span className='selectHeader'>Select</span>
                            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div className='checkboxCenter'>
                            {row.original.isDeleted == true  ? (
                                
                        <input type="checkbox" disabled className="disbaleCheckbox"/>
                    ) : (
                        
                        <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                        
                    )}
                        </div>
                    ),
                    accessor: 'selected',
                    width: 300,
                    disableSortBy: true,
                });
             }
            
        return commonColumns;
}, [isAdminDocumentManagerAvailable]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
        page,
        gotoPage,
        setPageSize, state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 25 }, }, useGlobalFilter,useSortBy, useExpanded, usePagination, useRowSelect
        );

    useEffect(() => {
        const updatedDocumentData = data.map((row) => {
          const selected = selectedFlatRows.some((r) => r.original.id === row.id);
          return { ...row, selected };
        });
        props.getSelectedTableList(selectedFlatRows)
      }, [selectedFlatRows, data]);

    const determineAx = (cell) => {
        const ax = { header: '', value: '' };
        try {
            const { column: { Header }, value } = cell;
            ax.header = Header
            ax.value = value

        } catch (error) {
            ax.header = ''
            ax.value = ''
        }
        return ax;
    }
    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

   
    const sortIndicatorImageRenderer = (column) => {

        let sortIndicatory;

        if(column?.isSorted){
            sortIndicatory = <img src={SortIndicatorUpArrow} alt="sort_indicator" />
        }else if(column?.disableSortBy){
            sortIndicatory = ''
        }else{
            sortIndicatory = <img src={SortIndicator} alt="sort_indicator" />
        }
       
        return sortIndicatory
    }
    return (
        <>
            <Row style={{ margin: '0 auto', }}>
                <Col md={2} className='pl-0'>
                    Show entries 
                    <CustomInput
                        type='select'
                        value={pageSize}
                        onChange={onChangeInSelect}
                        className='form-control'
                    >
                        {[10, 15, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </CustomInput>
                </Col>
                <Col md={7}  ></Col>
                <Col md={3}  className='p-0'>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </Col>
            </Row>
           
            <Table bordered
                tabIndex='0'
                aria-label='Your account activity table'
                {...getTableProps()}
                className="table table-striped mb-3 mt-3 documentCenterTable"
                style={assignObj({ borderBottom: '1px solid #d2d2d2' })}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup?.getHeaderGroupProps()}>
                            {headerGroup?.headers.map((column, i) => (
                                <th
                                    {...column?.getHeaderProps(column?.getSortByToggleProps())}
                                    style={assignObj({ color: '#004A98', ...th })}
                                    sortable={i === 3 ? 'true' : 'false'}
                                >
                                    {column?.render('Header')}
                                    <span>
                                        {sortIndicatorImageRenderer(column)}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.length > 0 &&
                        page.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <>
                                    <tr tabIndex='0' aria-label={`row ${rowIndex} of ${rows.length}`} {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            const { header, value } = determineAx(cell);
                                            return (
                                                <td tabIndex='0' aria-label={`${header}-${value}`} {...cell.getCellProps()} style={td}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                </tbody>
            </Table>
            <Pagination
            gotoPage={gotoPage}
            length={data.length}
            pageSize={pageSize}
            setPageSize={setPageSize}
            />
        </>
    );
};

export default DocumentListingTable;

