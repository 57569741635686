/**
 * Email Address Commponent Card
 ****************************************
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion, Card, Alert } from 'react-bootstrap';
import AccGlobalConstants from '../AccountManagementConstants';
import EmailDetails from './EmailDetails';

const { assignObj } = AccGlobalConstants;

const StyledPara = styled.p`
    margin-bottom: 0;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const StyledAlert = styled(Alert)`
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
`;

class EmailDetailsCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryEmailDetailsData: {},
            secondaryEmailDetailsData: {},
        };
        this.primaryEmailDetailsRef = React.createRef();
        this.secondaryEmailDetailsRef = React.createRef();
        this.emailAccordion = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const { savedState } = props;
        if (!state.retrievedState && savedState) {
            return {
                ...savedState,
                retrievedState: true,
            };
        }
        return state;
    }

    isValidPrimaryEmailAddress = emailDetails => {
        const { email, errEmail } = emailDetails;
        return email.length !== 0 && !errEmail;
    };

    sendDataToParent = () => {
        const primaryEmailDetailsData =
            this.primaryEmailDetailsRef.current && this.primaryEmailDetailsRef.current.getEmailDetails();
        const secondaryEmailDetailsData =
            this.secondaryEmailDetailsRef.current && this.secondaryEmailDetailsRef.current.getEmailDetails();
        if (!this.isValidPrimaryEmailAddress(primaryEmailDetailsData)) {
            this.setState({ showAlert: true });
        } else {
            this.setState({ showAlert: false });
        }
        return {
            primaryEmailDetails: primaryEmailDetailsData,
            secondaryEmailDetails: secondaryEmailDetailsData,
            valid: this.isValidPrimaryEmailAddress(primaryEmailDetailsData),
        };
    };

    cardHeaderClick = e => {
        e.preventDefault();
        e.target.classList.toggle('expand');
    };

    render() {
        const {
            isJoint,
            primaryEmailAddress,
            secondaryEmailAddress,
            index,
            isReadOnly,
            isSecondaryEmailEditable,
        } = this.props;
        const { primaryEmailDetailsData, secondaryEmailDetailsData, showAlert } = this.state;
        const emailInfoHeading = 'Email Information';
        const accTypeHeading = isJoint ? ' - Joint Owner' : '';
        const jointText = isJoint ? 'jointOwner' : 'primaryOwner';
        const EMAIL_ERROR = ' Please enter primary email address';

        return (
            <Accordion defaultActiveKey="0" id={assignObj(`${jointText}PhoneAccordion`)}>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as="h2"
                            variant="link"
                            eventKey="0"
                            className="expand"
                            onClick={this.cardHeaderClick}
                            ref={this.emailAccordion}
                        >
                            {emailInfoHeading} {accTypeHeading}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" id={assignObj(`${jointText}PhoneAccordionCardBody`)}>
                        <div>
                            {showAlert && (
                                <StyledAlert variant="danger">
                                    <StyledPara>{EMAIL_ERROR}</StyledPara>
                                </StyledAlert>
                            )}
                            <Card.Body>
                                <EmailDetails
                                    ref={this.primaryEmailDetailsRef}
                                    // isLoading={initialState.isLoading}
                                    required
                                    savedState={primaryEmailDetailsData}
                                    emailType="Primary"
                                    isJoint={isJoint}
                                    savedEmailAddress={primaryEmailAddress}
                                    index={index}
                                    type="primary"
                                    isReadOnly={isReadOnly}
                                />
                                <EmailDetails
                                    ref={this.secondaryEmailDetailsRef}
                                    savedState={secondaryEmailDetailsData}
                                    // isLoading={initialState.isLoading}
                                    emailType="Secondary"
                                    isJoint={isJoint}
                                    savedEmailAddress={secondaryEmailAddress}
                                    index={index}
                                    type="secondary"
                                    isReadOnly={isReadOnly && !isSecondaryEmailEditable}
                                />
                            </Card.Body>
                        </div>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}

EmailDetailsCardComponent.propTypes = {
    savedState: PropTypes.instanceOf(Object),
    isJoint: PropTypes.bool,
    primaryEmailAddress: PropTypes.string,
    secondaryEmailAddress: PropTypes.string,
    index: PropTypes.number,
    isReadOnly: PropTypes.bool,
    isSecondaryEmailEditable: PropTypes.bool,
};

EmailDetailsCardComponent.defaultProps = {
    savedState: {},
    isJoint: false,
    primaryEmailAddress: '',
    secondaryEmailAddress: '',
    index: 0,
    isReadOnly: false,
    isSecondaryEmailEditable: true,
};

export default EmailDetailsCardComponent;
