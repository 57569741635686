
import currencyFormatter from 'currency-formatter';

const currencyFormatterFunction=(amount)=>{
    // if the amount is 100.1 return 100.10, for 100.11 returns 100.11 and 100 returns 100.00
    return `${currencyFormatter.format(amount, { locale: 'en-US' })}`
}

const currencyFormatterWithoutDollarSign = (amount) =>
{
   const formatedAmout = currencyFormatterFunction(amount);
   return formatedAmout.substring(1,formatedAmout.length); 
}

const removeComma = (amount) =>
{
   if( amount !=undefined  && amount != null && amount !="" && amount?.length > 0)
   {
      return amount?.replaceAll(',','');
   }
   else
   {
      return amount;
   }
}

export {currencyFormatterFunction,currencyFormatterWithoutDollarSign,removeComma};
