import { connect } from 'react-redux';
import {
    dashboardGuestViewActions,
    localStateManagementActions,
    analytics,
    accOpeningActions,
    loginActions
} from '../../../shared/Actions';
import GuestViewDashboard from './GuestViewDashboard';

const mapStateToProps = state => ({
    accountTypes: state.accountDetailsGuestData.masterdatalist,
    localStateData: state.localStateManagementReducerData,
    dashboardDocumentData :  state.documentPreferenceData,
    loginState: state.loginData, 
});

const mapDispatchToProps = {
    ...dashboardGuestViewActions,
    ...localStateManagementActions,
    ...analytics,
    ...accOpeningActions,
    ...loginActions
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestViewDashboard);
