import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { analytics } from "../../shared/Actions";

import twitterlogo from '../../assets/twitterlogo.svg';
import linkedInlogo from '../../assets/linkedInlogo.svg';
import facebooklogo from '../../assets/facebooklogo.png';
import youtubelogo from '../../assets/youtubelogo.png';
import instalogo from '../../assets/instalogo.png';
import vcmfooterlogo from '../../assets/vcmfooterlogo.svg';
import CONSTS from '../Footer/CONST';
import '../commonCompStyles.css'

const StyledDiv = styled.div`
    @media only screen and (max-width: 992px) {
        justify-content: flex-start;
    }
`;

const StyledLink = styled(Link)`
    display:flex;
    font: 700 16px/20px yorkten-slab-normal,sans-serif;
    color: rgba(0, 74, 152, 1);
    margin-bottom:28px;
    width:67%;
    &:hover , &:visited, &:focus{
        text-decoration: none;
        color:rgba(0, 74, 152, 1);
    }
`;

const StyledA = styled.a`
    display:flex;
    font: 700 16px/20px yorkten-slab-normal,sans-serif;
    color: rgba(0, 74, 152, 1);
    margin-bottom:28px;
    width:67%;
    &:hover ,&:visited, &:focus{
        text-decoration: none;
        color:rgba(0, 74, 152, 1);
    }
`;

const StyledFooterDiv = styled.div`
    padding-left: 30px;
    border-left: 1px solid rgb(230, 230, 230);
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
        padding-left: 0px;
        border-left: none;
    }
`;

const assignObj = obj => {
    return obj;
};

class FooterLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // Analytics Implementation Start
    handleFooterLinksClick = (linkText) => (e) => {
        const { analyzeClickEvent } = this.props
        analyzeClickEvent({
            link: `Footer_${linkText}`
        })
    }

    handleSocialMediaIconsClick = (e) => {
        const { analyzeClickEvent } = this.props
        const linkName = e.currentTarget.ariaLabel || ''
        analyzeClickEvent({
            link: `Footer_socialIcons_${linkName}`
        })
    }
    // Analytics Implementation End

    externalSiteHtml = () => {
        return <>
            <span className="sr-only" data-test="externalWeb">{CONSTS.externalWeb}</span>
            <span aria-hidden="true" className="icon-newpage" />
        </>
    }

    render() {
        return (
            <div data-test="footerLinks">
                <div className="container" id="postLoginFooterImage" style={assignObj({ marginBottom: 55, marginTop: 40 })}>
                    {/* <WIcon src={vcmfooterlogo} alt="VCM Footer Logo" className="img-fluid" style={assignObj({ cursor: 'default' })} /> */}
                    <img
                        alt="VCM Footer Logo"
                        className="img-fluid"
                        src={vcmfooterlogo}
                        style={assignObj({ cursor: 'default' })}
                    />
                </div>
                <div
                    id="postLoginFooterLinks"
                    className="container"
                    style={assignObj({ display: 'flex', flexDirection: 'row', marginBottom: 29 })}
                >
                    <Row style={assignObj({ width: '100%' })} noGutters>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            {/* <Row className="footerlinksrow" role="list">
                                {CONSTS.footerLinks.map((item) => {
                                    return <Col xs={6} md={3} lg={3} xl={3} key={item.id} className="footerlinks" role="listitem">
                                        {item.linkText === 'Forms' ?
                                            <StyledLink to={assignObj({
                                                pathname: '/accessForms',
                                                state: {
                                                    detail: 'selectedFundForSummary',
                                                },
                                            })} onClick={this.handleFooterLinksClick(item.linkText)}>{item.linkText}</StyledLink>
                                            :
                                            <StyledA href={item.link} target={item.target} rel="noopener noreferrer" onClick={this.handleFooterLinksClick(item.linkText)}>
                                                {item.linkText}{this.externalSiteHtml()}
                                            </StyledA>
                                        }
                                    </Col>
                                })}
                            </Row> */}
                            <ul style={CONSTS.styles.unorderedList}>
                                {CONSTS.footerLinks.map((item) => {
                                    return <li key={item.id} className="col-6 col-md-3 col-lg-3 col-xl-3 p-0 footerlinks">
                                        {item.linkText === 'Forms' ?
                                            <StyledLink to={assignObj({
                                                pathname: '/accessForms',
                                                state: {
                                                    detail: 'selectedFundForSummary',
                                                },
                                            })} onClick={this.handleFooterLinksClick(item.linkText)}>{item.linkText}</StyledLink>
                                            :
                                            <StyledA href={item.link} target={item.target} rel="noopener noreferrer" onClick={this.handleFooterLinksClick(item.linkText)}>
                                                {item.linkText}
                                                {/* {(item.linkText === 'Mutual Fund Prospectuses' || item.linkText === 'ETF Prospectuses') &&
                                                    <>
                                                        <span className="sr-only" data-test="externalWeb">{CONSTS.externalWeb}</span>
                                                        <span aria-hidden="true" className="icon-newpage" style={assignObj({ display: "inline-table" })} />
                                                    </>
                                                } */}
                                            </StyledA>
                                        }
                                    </li>
                                })}
                            </ul>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <StyledFooterDiv>
                                <Row style={CONSTS.styles.connectRow} noGutters>
                                    <Col style={CONSTS.styles.connectCol}>{CONSTS.CONNECTWITHUS}</Col>
                                </Row>
                                <Row noGutters>
                                    <ul style={CONSTS.styles.unorderedList} className="col-12 footerSocialMediaList">
                                        <li>
                                            <a href={CONSTS.facebookLink} data-label="Facebook" target="_blank" rel="noopener noreferrer" onClick={this.handleSocialMediaIconsClick} >
                                                <img
                                                    alt="FaceBook Logo-External Website. Opens New window"
                                                    src={facebooklogo}
                                                    style={CONSTS.styles.navLogo}

                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={CONSTS.instagramLink} data-label="Instagram" target="_blank" rel="noopener noreferrer" onClick={this.handleSocialMediaIconsClick}>
                                                <img
                                                    alt="Instagram Logo-External Website. Opens New window"
                                                    src={instalogo}
                                                    style={CONSTS.styles.navLogo}

                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={CONSTS.linkedInLink} data-label="LinkedIn" target="_blank" rel="noopener noreferrer" onClick={this.handleSocialMediaIconsClick}>
                                                <img
                                                    alt="LinkedIn Logo-External Website. Opens New window"
                                                    src={linkedInlogo}
                                                    style={CONSTS.styles.navLogo}

                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={CONSTS.twitterLink} data-label="Twitter" target="_blank" rel="noopener noreferrer" onClick={this.handleSocialMediaIconsClick}>
                                                <img
                                                    alt="Twitter Logo-External Website. Opens New window"
                                                    src={twitterlogo}
                                                    style={CONSTS.styles.navLogo}

                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={CONSTS.youtubeLink} data-label="YouTube" target="_blank" rel="noopener noreferrer" onClick={this.handleSocialMediaIconsClick}>
                                                <img
                                                    alt="Youtube Logo-External Website. Opens New window"
                                                    src={youtubelogo}
                                                    style={CONSTS.styles.navLogo}

                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </Row>
                            </StyledFooterDiv>
                        </Col>
                    </Row>
                </div>
                <div style={assignObj({ backgroundColor: 'rgb(72, 109, 144)' })}>
                    <StyledDiv
                        className="container"
                        style={assignObj({
                            color: '#FFFFFF',
                            height: 49,
                            display: 'flex',
                            paddingTop: 19,
                            font: '500 13px/23px benton-sans',
                        })}
                    >
                        <span><span aria-hidden="true">{CONSTS.COPYRIGHTSYMBOL}</span>{CONSTS.COPYRIGHTYEAR} {CONSTS.COPYRIGHT}</span>
                    </StyledDiv>
                </div>
            </div>
        );
    }
}


FooterLinks.propTypes = {
    analyzeClickEvent: PropTypes.func,
};

FooterLinks.defaultProps = {
    analyzeClickEvent: () => { },
};


export const mapDispatchToProps = {
    ...analytics
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(FooterLinks);
