const CC = "ContactCenter_";
const SM = "SecureMessage_";
const AF = "AccessForms_";
export const SET_CONTACT_DATA = `${CC}SetContactData`;
export const GET_ACCOUNT_LIST=`${SM}GetAccountsAPIData`;
export const SET_ACCOUNT_LIST=`${SM}SetAccounts`;
export const GET_TOPIC_LIST = `${SM}GetTopicsAPIData`;
export const SET_TOPIC_LIST=`${SM}SetTopics`;


export const SUBMIT_MESSAGE = `${AF}SubmitMessage`;
export const SET_ACCESS_FORMS = `${AF}SetAccessForms`;
export const SET_PAGE_LOADING = `${AF}SetPageLoading`;
export const INCREMENT_PAGE_NUMBER = `${AF}incrementPageNum`;
export const SET_MORE_DATA_AVAILABLE = `${AF}SetMoreDataAvail`;
export const SORT_BY_POPULAR = `${AF}SortByPopular`;
export const GET_ACCOUNT_LIST_ERROR="GET_ACCOUNT_LIST_ERROR";