
import React, {useState,useEffect} from 'react'
import { CommonButtons, WRadio, WInputCheckBox } from 'common';
import './MarketNAOStyles.css'
import SummaryCard from '../Verify/SummaryCard';
import { Row } from 'react-bootstrap';
import "../Esign/Esign.css"
import styled from 'styled-components';
import { useSelector,useDispatch } from 'react-redux';
import {postMarketNAOInfo, submitMarketNAOInfo} from '../../../shared/Actions/MarketNAOActions'
import { MARKET_NAO_ACCOUNT_TYPES } from '../consts';


//Consts are here
  const checkLabel='By checking the box, I certify under the penalty of perjury, that all information I have provided is true, complete and accurate'
  const radioText1 = 'I reported an incorrect social security number to the IRS with my tax filing.'
  const radioText2 = 'I failed to report all interest and dividends on a previous years tax return.'
  const cardHeading = 'Taxpayer Identification Number/Backup withholding certification'
  const hintText = 'The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.'
  const hintText1 ='The IRS has notified me that I am subject to backup withholding as a result of:'
  const preLinkText ='Under penalties of perjury, I certify that (1) The Social Security number/taxpayer identification number provided on this application is my correct number (or I am waiting for number to be issued to me) and, (2) unless I have checked the box immediately above this statement, I am not subject to backup withholding because(a) I am exempt from backup withholding, or (b) I have not been notified by the IRS that I am subject to backup withholding of my failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding: and (3) I am a US citizen or other US person.'
  const radioOptions = ['The Internal Revenue Services(IRS) has not notified me that I am subject to backup withholding.', 'The IRS has notified me that I am subject to backup withholding as a result of:'];
  const checkBoxWrapperStyle = {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
};
const checkBoxInputStyle = {
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #4F799F',
  borderRadius: 3,
  opacity: 1,
  height: 30,
  width: 30,
  flexShrink: 0,
};

const checkBoxStyle = { position: 'absolute', top: '7px' };
const labelStyle = {
  paddingTop: '3px',
  textAlign: 'left',
  letterSpacing: 0,
  opacity: 1,
};
const CheckWrapper = styled.div`
background: #f0f4f7 0% 0% no-repeat padding-box;
opacity: 1;
text-align: left;
font: 500 16px/25px benton-sans, sans-serif;
letter-spacing: 0;
color: #3e607e;
margin-top: 10px;
`;



const CheckContentCommonStyles = styled.div`
justify-content: space-between;
padding-top: 35px;
padding-bottom: 35px;
flex-wrap: nowrap;
display: inline-flex;
padding-left: 20px;
letter-spacing: 0;
opacity: 1;

.checkBoxWrapper input[type='checkbox']:checked:after {
    width: 8px;
    height: 14px;
    top: 2px;
    left: 8px;
}
`;
const CheckContent = styled(CheckContentCommonStyles)`
width: 100%;
`;
 const radiodescstyles = {
    color: 'rgba(86, 86, 90, 1)',
    fontSize: 14,
    fontWeight: 400,
};

 const radiostyles = {
    color: 'rgba(73, 73, 74, 1)',
    fontSize: 16,
    minHeight: 'unset',
};
 const radioText = { font: '500 16px/20px benton-sans' };


//Main Function

export default function EContest(props) {


  const initialState = {
        radioButton1: "The Internal Revenue Services(IRS) has not notified me that I am subject to backup withholding.",
       }

  const [state, setStateVariables] = useState(initialState);
  const[checkbox1,setCheckBox1]=useState(false)
  const[checkbox2,setCheckBox2]=useState(false)
  const[finalConsent,setFinalConsent]=useState(false)
  const[error,seterror]=useState(false)
  const dispatch = useDispatch();
  const marketNAOInfoReducerData = useSelector(state => state.marketNAOReducerData);

  useEffect(() => {
   return () => {
   document.title = `Confirmation Page | Victory Capital`;
    }
  },[props.onSubmit]);


//handlers are here
 const radioButtonClick = (eventKey, buttonType) => e => {
        const targetValue = e.target.value

        if (eventKey == 'radioButton1') {
            setStateVariables(prevState => ({ ...prevState, radioButton1: targetValue }));
        } else {
            setStateVariables(prevState => ({ ...prevState, [eventKey]: targetValue }));
        }

    }

function handleCheckBox1() {
        setCheckBox1(!checkbox1);
    }
function handleCheckBox2() {
        setCheckBox2(!checkbox2);
    }

function handleFinalConsent() {
        setFinalConsent(!finalConsent);
        seterror(!finalConsent)
    }


const ErrorMsg=()=><span className="econsentError">Please check the box above to continue</span>
                        

const onSubmitClick = () => {

  if (finalConsent==true){

  // const userConsentState = {
  //   notifiedIRS: state.radioButton1.includes('IRS'),
  //   notifiedIRSTrue: {
  //     reportedIncorrectSSN:  checkbox1,
  //     failedToReportInterestAndDivident:  checkbox2
  //   },
  //   eConsent: finalConsent
  // }

  let applicationId = marketNAOInfoReducerData.applicationId

  if(marketNAOInfoReducerData.applicationId == ''){
    applicationId = marketNAOInfoReducerData.marketNAOInfo?.applicationId
  }
  const eConsentPayload = {
    applicationId: applicationId,
    source: "web",
    eConsent: {
      notifiedIRS: !state.radioButton1.includes('(IRS)'),
      documentAcknowledge: finalConsent
    }
  }

  // // Call eContest api
  dispatch(postMarketNAOInfo({
    url: 'eConsent',
    payloadData: eConsentPayload
  }))


  if(marketNAOInfoReducerData.marketNAOInfo?.applicationStatus != undefined || marketNAOInfoReducerData.marketNAOInfo?.account.accountType != MARKET_NAO_ACCOUNT_TYPES.JOINT_ACCOUNT){
    const submitPayload = {
      applicationId: applicationId ,
      nao:"brokerage",
    }
    
    // // Call submit api
    dispatch(submitMarketNAOInfo({
      payload: submitPayload
    }))
  }
  


  // // // move to next screen
  props.onSubmit()
}
  else{
  seterror(!finalConsent)
  }

}



 // SCROLL TO TOP FUNCTION
 const scrollToTop = () => {
  setTimeout(() => window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
  }))
  }

  useEffect(()=>{
  scrollToTop()
    document.title = `E-Consent Page | Victory Capital`;
  },[])

 //Rendering is done here   
  return (
    <div>
      <div className='banner step-banner'>
     
      <p className='white-text center'>Please Review Important Account Information</p>
    </div>

      <SummaryCard
        cardHeading={cardHeading}
      />
       <div>
       {radioOptions.map(item => {
                                                return (
                                                    <WRadio
                                                        radioFor="radioButton1"
                                                        key={state.radioButton1+ item}
                                                        id={state.radioButton1 + item + 1}
                                                        value={item}
                                                        radiodescstyles={radiodescstyles}
                                                        radiostyles={radiostyles}
                                                        radiotext={radioText}
                                                        selected={state.radioButton1 == item}
                                                        ariaLabel="None"
                                                        // ariaRequired="true"
                                                        ariaDescribedBy="radioButton1"
                                                        onClick={radioButtonClick('radioButton1', item)}
                                                    />
                                                );
                                            })}
                                            </div>
                            {state.radioButton1 ==`${hintText1}` && (
                                    <div className="summary-radio-desc">
                                      <Row className ='radio-dec-item'>
                                           <WInputCheckBox
                                            id="esignCheck"
                                            checkboxwrapperstyle={checkBoxWrapperStyle}
                                            checkInputStyle={checkBoxInputStyle}
                                            checked={checkbox1}
                                            value={`${checkbox1}`}
                                            onChange={handleCheckBox1}
                                            checkboxstyle={checkBoxStyle}
                                            labelstyle={labelStyle}
                                            label={radioText1}
                                            ariadescribedby="esignCheck_error"
                                          // ariainvalid={consent}
                                          />
                                          </Row>
                                          <Row className ='radio-dec-item'>
                                          <WInputCheckBox
                                              id="esignCheck"
                                              checkboxwrapperstyle={checkBoxWrapperStyle}
                                              checkInputStyle={checkBoxInputStyle}
                                              checked={checkbox2}
                                              value={`${checkbox2}`}
                                              onChange={handleCheckBox2}
                                              checkboxstyle={checkBoxStyle}
                                              labelstyle={labelStyle}
                                              label={radioText2}
                                              ariadescribedby="esignCheck_error"
                                            // ariainvalid={consent}
                                            />
                                            </Row>
                                          </div>)}
          <br></br>
        <div className='summary-para '>
           <p>{preLinkText}</p>
        </div>
      
        
        <CheckWrapper>
      <CheckContent>
          <WInputCheckBox
            id="esignCheck"
             checkboxwrapperstyle={checkBoxWrapperStyle}
            checkInputStyle={checkBoxInputStyle}
            checked={finalConsent}
            value={`${finalConsent}`}
            onChange={handleFinalConsent}
             checkboxstyle={checkBoxStyle}
             labelstyle={labelStyle}
            label={checkLabel}
            ariadescribedby="esignCheck_error"
            // ariainvalid={consent}
          />
          
          </CheckContent>         
          </CheckWrapper>
          {error==!finalConsent&&(<ErrorMsg/>)}
         
       
       <div className="listWrapperDiv">
          <div class="summary-content" role="radiogroup">
           
            <ul class="sum-card-wrap"></ul>
          </div>                  
      </div>
      <div>
      <Row noGutters>
        <div className="econsentHint">{hintText}</div>
      </Row>
      </div>

      <div className='footer'>
        <CommonButtons 
        backClick={props.onBackClick} 
        submit={'true'} 
        submitClick={onSubmitClick} 
        nextText="Submit" 
        isShowBtn={true}
        />
      </div>
    </div>
  )
}
