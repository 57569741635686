import {
  REJECTED_CREATE_MEMBER,
  RESOLVED_CREATE_MEMBER,
  SET_RESET_PASSWORD_INFO,
} from 'shared/ReduxConstants/AccountRegistrationConstants';

import {
  API_CREATE_MEMBER
} from 'shared/ReduxConstants/ServiceActionConstants';

import accountRegistrationAPICall from '../../service/AccountRegistrationService';
import { dispatchWithData, dispatchLoading } from '../../utils.actions';

const dispatchValidatePasswordError = (error) => (dispatch) => {
  if (error.response && error.response.data && error.response.data.result) {
    if (error.response.data.result === 'User account already exists') {
      dispatchWithData(REJECTED_CREATE_MEMBER, {
        pwdError: 'User ID already exists',
        pwdValidate: 'Failure',
      })(dispatch);
    } else {
      dispatchWithData(REJECTED_CREATE_MEMBER, {
        pwdError: error.response.data.result,
        pwdValidate: 'Failure',
      })(dispatch);
    }
  } else {
    dispatchWithData(REJECTED_CREATE_MEMBER, {
      pwdError: 'Error occured while Creating UserID and Password',
      pwdValidate: 'Failure',
    })(dispatch);
  }
}

export const validatePassword = (data) => (dispatch) => {
  if (data === '') return;
  dispatchLoading('SSNAuth')(dispatch);

  accountRegistrationAPICall(
    API_CREATE_MEMBER,
    data,
    (response) => {
      if (response.status === 200) {
        if (response.data && response.data.message === 'Failure') {
          dispatchWithData(REJECTED_CREATE_MEMBER, {
            pwdError: response.data.result,
            pwdValidate: 'Failure',
          })(dispatch);
        } else if (response.data && response.data.message === 'Success') {
          dispatchWithData(RESOLVED_CREATE_MEMBER, {
            pwdError: '',
            pwdValidate: 'Success',
          })(dispatch);
        }
      } else {
        dispatchWithData(REJECTED_CREATE_MEMBER, {
          pwdError: `${response.statusCode} Error occured while Creating member`,
          pwdValidate: 'Failure',
        })(dispatch);
      }
    },
    (error) => {
      dispatchValidatePasswordError(error)(dispatch);
    },
  );
};

export const resetPasswordError = () => (dispatch) => {
  dispatchWithData(SET_RESET_PASSWORD_INFO, {
    pwdError: '',
    pwdValidate: '',
  })(dispatch);
};
