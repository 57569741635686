import {ContainsSpecialUserIdChar}  from './userConstants';

const checkFormat = (event, field, obj) => {
    const { value } = event.target;
    if (field === 'password') {
        obj.setState((state) => ({
            ...state,
            min: value.length >= 8,
            upperLower: /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(value),
            numeral: /[0-9]/.test(value),
            symbol: /[\W_]/.test(value),
            noStartEndNumber: /^(?=^[\D].*[\D]$).*$/.test(value)
        }))
    } else if (field === 'onlineId') {
        obj.setState((state) => ({
            ...state,
            min: value.length >= 5,
            upperLower: /[a-zA-Z0-9]/.test(value),
            symbol: value.length >= 1 ? !ContainsSpecialUserIdChar(value) : false
        }))
    }
}

export default checkFormat;
