import { Card } from 'react-bootstrap';
import './ManageBeneficiaries.css';
import styled from 'styled-components';

const VerticalSeparator = styled.div`
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    @media (max-width: 991px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
`;
const StyledCard = styled(Card)`
    margin: 0 0 2.5rem 0;
    &::before {
        content: '';
        width: 0rem;
        height: 0rem;
        border-bottom: 2.1875rem solid transparent;
        border-left: 2.1875rem solid #486D90;
        position: absolute;
        }
`;
const FlexDiv = styled.div`
    display: flex;
    padding: 1.60625rem 2.5rem 1.64375rem 2.5rem;
`;
const AccountCardBottomSection = styled.div`
    width: 30%;
    text-align: left;
    letter-spacing: 0;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
`;
const AccountCardHeadingSpan = styled.div`
    margin-bottom: 0.625rem;
    text-align: center;
    font: 600 16px/22px benton-sans;
    color: #49494A;
    opacity: 1;
`;
const AccountCardHeadingSpanLeft = styled.div`
    margin-bottom: 0.625rem;
    text-align: left;
    font: 600 16px/22px benton-sans;
    color: #49494A;
    opacity: 1;
`;
const AccountCardValueSpan = styled.div`
    text-align: center;
    font: 500 16px/20px benton-sans;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
`;

export {VerticalSeparator, StyledCard, FlexDiv, AccountCardBottomSection, AccountCardHeadingSpan, AccountCardHeadingSpanLeft, AccountCardValueSpan}