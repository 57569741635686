import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { messageCenterActions,analytics } from '../../../../shared/Actions';
import MessageCenterWidget from './MessageCenterWidget';

const mapStateToProps = (state) => ({
        messageCenterData :  state.messageCenterData,
    
})

const mapDispatchToProps = {
    ...messageCenterActions,...analytics
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageCenterWidget);