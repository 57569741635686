import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';

const assignObj = (obj) => obj;

const PasswordStrength = (props) => {
  const { style, len } = props;
  const styles = {
    container: {
      display: 'flex',
      width: '100%',
      color: 'red',
    },
    main: {
      marginTop: '10px',
      ...style,
    },
    columnGrey: {
      boxSizing: 'border-box',
      backgroundColor: '#D2D2D2',
      height: '4px',
      borderRadius: '4px',
    },
    red: {
      backgroundColor: '#FF0000',
    },
    orange: {
      backgroundColor: '#EA7400',
    },
    green: {
      backgroundColor: '#8CBF26',
    },
    para: {
      margin: 0,
      font: '600 12px/20px benton-sans',
      color: '#56565A',
    },
    Col: {
      paddingLeft: 0,
    },
    lastCol: {
      padding: 0,
    },
  };
  const data = {
    len,
    strenth: '',
    message: 'Your password strength is',
  };
  function scorePassword(pass) {
    let score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[`${i}`]] = (letters[pass[`${i}`]] || 0) + 1;
      score += 5.0 / letters[pass[`${i}`]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };
    let variationCount = 0;
    Object.keys(variations).forEach((key) => {
      variationCount += variations[`${key}`] === true ? 1 : 0;
    });
    score += (variationCount - 1) * 10;
    return Number(score);
  }

  let bar1 = <div style={styles.columnGrey} />;
  let bar2 = <div style={styles.columnGrey} />;
  let bar3 = <div style={styles.columnGrey} />;
  let strength = '';

  const score = scorePassword(data.len);
  if (score > 80) {
    bar1 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
    bar2 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
    bar3 = <div style={assignObj({ ...styles.columnGrey, ...styles.green })} />;
    strength = 'Strong';
  }
  if (score <= 80 && score > 60) {
    bar1 = (
      <div style={assignObj({ ...styles.columnGrey, ...styles.orange })} />
    );
    bar2 = (
      <div style={assignObj({ ...styles.columnGrey, ...styles.orange })} />
    );
    strength = 'Good';
  }
  if (score <= 60 && score > 30) {
    bar1 = <div style={assignObj({ ...styles.columnGrey, ...styles.red })} />;
    strength = 'Weak';
  }
  if (score === 0) {
    bar1 = <div style={styles.columnGrey} />;
    bar2 = <div style={styles.columnGrey} />;
    bar3 = <div style={styles.columnGrey} />;
  }

  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <Col style={styles.Col}>{bar1}</Col>
        <Col style={styles.Col}>{bar2}</Col>
        <Col style={styles.lastCol}>{bar3}</Col>
      </div>
      <div style={styles.para} aria-live="polite" aria-atomic="true">
      {!isEmpty(strength) && (
          <>
        <span className="sr-only">{data.message}</span>
        {strength}
          </>
      )}
      </div>
    </div>
  );
};

PasswordStrength.propTypes = {
  len: PropTypes.number,
  style: PropTypes.shape({}),
};

PasswordStrength.defaultProps = {
  len: PropTypes.number,
  style: {},
};
export default PasswordStrength;
