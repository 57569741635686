const styles= {
    commonButtons: { marginTop: 100 },
    commonButtonsThreeFourth: { marginTop: 70 },
    commonButtonsHalf: { marginTop: 20 },
    hide: { display: 'none' },
    infoStrong: {font: '700 16px/24px benton-sans'},
    radioText: {font: '600 16px/22px benton-sans'},
    subHeading: { font: '600 16px/22px benton-sans', color: '#49494A', marginBottom: 30 },
    inputField: {borderRadius: 0, marginLeft: 5, width: 160, height: 50},
    optionalMessage: {font: '500 16px/20px benton-sans, sans-serif', display: 'flex', justifyContent: 'flex-end', marginBottom: 20, color: '#49494A'},
    cardStyle: {
        margin: '0 0 30px 0',
        minHeight: '155px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        opacity: 1,
        cursor: "pointer"
      },
      cardTitleStyle: {
        font: '800 18px/22px yorkten-slab-normal',
        color: '#486D90',
        paddingBottom: '15px',
        borderBottom: '1px solid #D2D2D2',
        textTransform: 'capitalize'
      },
      cardBodyStyle: {
        padding : '28px'
      },
      errorStyle: {position: 'relative'},
      stepperStyle: {
        stepperColStyle: {
          marginBottom: 0
        },
        stepperTextStyle: {
          maxWidth: 85
        }
      }
}

export default styles;