import CryptoJS from "crypto-js";
import envConfig from "env/EnvConfig";

const SessionStorageHelper  = {
    getItem: (itemKey) => {
        return (sessionStorage.getItem(itemKey));
    },
    setItem :(itemKey,value) =>  {
        return (sessionStorage.setItem(itemKey,value));
    },
    getItemDec: (itemKey) => {
        const encySalt = envConfig.ADOBE_ANALYTICS_ENCY_SALT;
        const encItem = sessionStorage.getItem(itemKey);
        const decryptedValue = decryptAES(encItem,encySalt);
        return decryptedValue;
    },
    getMsrObj: () => {
        const encySalt = envConfig.ADOBE_ANALYTICS_ENCY_SALT;
        const decData = CryptoJS.AES.decrypt(sessionStorage.getItem('msr_dts'), encySalt).toString(CryptoJS.enc.Utf8);
        return decData;
    },
    setItemAsJson : (key, value) => {
        sessionStorage.setItem(key, this.toJSON(value));
    },
    getItemAsJson : (key) => {
        try {
            return JSON.parse(sessionStorage.getItem(key))
        } catch (e) {
            console.log("Error while parsing JSON value ...");
        }
        return ("");
    },
    parseItem : (value) => {
        return JSON.parse(value);
    },
    toJSON : (value) => {
        return JSON.stringify(value);
    },
    removeItem : (key) => {
        sessionStorage.removeItem(key)
    },
    IsItemExists :(key) => {
        return (sessionStorage.getItem(key) !== null ? true : false)
    },
    setWithExpiry:(key, value, expiryInDays) =>{
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + (86400000 * expiryInDays),
        }
        sessionStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry:(key) =>{
        const itemStr = sessionStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
    },
}
const LocalStorageHelper  = {
    getItem: (key) => {
        return (localStorage.getItem(key));
    },
    setItem :(key,value) =>  {
        return (localStorage.setItem(key,value));
    },
    setItemAsJson : (key, value) => {
        localStorage.setItem(key, this.toJSON(value));
    },
    getItemAsJson : (key) => {
        try {
            return JSON.parse(localStorage.getItem(key))
        } catch (e) {
            console.log("Error while parsing JSON value ...");
        }
        return ("");
    },
    parseItem : (value) => {
        return JSON.parse(value);
    },
    toJSON : (value) => {
        return JSON.stringify(value);
    },
    removeItem : (key) => {
        localStorage.removeItem(key)
    },
    IsItemExists :(key) => {
        return (localStorage.getItem(key) !== null ? true : false)
    }
}

const decryptAES = (encryptedBase64, key) => {
    if(encryptedBase64 && key)
    {
        const decrypted = CryptoJS?.AES?.decrypt(encryptedBase64, key);
        if (decrypted) {
          try {
            const str = decrypted.toString(CryptoJS.enc.Utf8);
            if (str.length > 0) {
              return str;
            } else {
              return 'error 1';
            }
          } catch (e) {
            return 'error 2';
          }
        }
        return 'error 3';
    }
};

// localStorage.setItem('isPreloginRedirection', false)
// export const token = localStorage.getItem('token');
//    Cookies.set(key, val, {expires: 1000, secure: true, domain: `.${domainName}`});
export { SessionStorageHelper, LocalStorageHelper}