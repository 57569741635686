import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import currencyFormatter from 'currency-formatter';
import AccountInfoIcon from 'assets/icon-account-info-big.svg';
import { PageHeading, PrimaryBtn } from 'modules/CustomerManagementModule/components';
import {
    masterRegistrationAccountActions,
    accOpeningActions,
    memberDashboardActions,
    liquidationActions,
    tradingWindowActions,
    transactionHistoryActions
} from '../../../shared/Actions';
import { WBreadCrumb, WSpinner, WButton, WMessageBar,WRadio } from '../../../common';
import * as TransactionConstants from '../../TransactionModule/TransactionConstants';
import UpdateText from './UpdateText';
import * as Constants from './Constants';
import Styles from './Styles';
import BackNavigation from '../Common/BackNavigation/BackNavigation';
import leftArrowGreen from '../../../assets/leftArrow_green.png';
import editIcon from '../../../assets/pencil.png';
import '../Common/Styles.css';
import './Account.css';
import constant from './AccountHolderConstants';
import {isTokenAvailable} from '../../../common/Authenticate/session'
import Authenticate from '../../../common/Authenticate/Authenticate'
import {purchaseActions} from '../../../shared/Actions';
import backArrow from 'assets/leftbackarrow.png';
import bankImage from '../../../assets/ra-retirement.png';
import rightArrow from 'assets/rightArrow_green.png';
import Address  from '../../AccountManagementModule/AddressTransaction';
import {
    getKeyfromValue
} from 'modules/CustomerManagementModule/utils';
import { getSessionValues, getTokenFromSession } from 'shared/Helpers/Utils';
import { SessionStorageHelper } from 'utils/sessionHelper';
import SingleSignOn from '../Common/SingleSignOn/SingleSignOn';
import envConfig from 'env/EnvConfig';
import { hasValidValue, validateForEmptyAndGetValue, validateForUndefinedAndGetValue } from 'utils/utils';


const StyledHeaderRow = styled.div`
    display: 'flex';
    align-items: 'center';
    flex-direction: 'row';
    justify-content: 'space-between';
    margin-bottom: 21px;
`;

const SideHeaderDiv = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        border-bottom: 0.125rem solid rgb(139, 193, 5);
        display: flex;
        align-items: center;
        margin-bottom: 21px;
    }
`;
const EditBtn = styled.button`
    // text-align: right;
    // padding: 0;
    border: none;
    background: none;
    font: 400 16px/19px benton-sans;
    letter-spacing: 0;
    color: #0000ff;
    opacity: 1;
    // display: inline-block;
    flex-direction: row;
    // float: left;
    // margin-left: 10px;
`;

const borderColor = '1px solid #D2D2D2';
const radiostyles = {
    minHeight: 0,
    marginBottom: 0
};
const SectionTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding-top: 20px;
`;
const BtnWrap = styled.div`
    display:Flex; 
    justify-content: flex-start;
    align-items:center;
    padding:20px 0;
    &>:nth-child(1){
        margin-right:50px;
    }
    @media (max-width:767px){
        &>:nth-child(1){
            order:1;
            margin-right:0;
            width:100%;
            button{
                margin:0 !important;
            }            
        }
        &>:nth-child(2){
            order:2;
            button{
                width: 100% !important;
            }
        }
        &>:not(:first-child){
            margin-bottom:20px;
        }
        flex-direction:column;
    }
`;
const assignObj = obj => obj;

const convertUTCtoLocalTime = time => {
    const dateStr = time;
    return moment(new Date(dateStr))
        .local()
        .format('MMMM DD, YYYY hh:mm A');
};


const AccountOptionsLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    a {
        text-align: left;
        font-family: benton-sans, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #004a98;
        opacity: 1;
    }
`;

const StyledSpan = styled.span`
    font-size: 14px;
    line-height: 22px;
`;

const AccountImgData = styled.div`
    background: url(${AccountInfoIcon}) no-repeat;
    min-height: 3rem;
    min-width: 3rem;
`;

const LeftMargined = styled.div`
    margin-left: ${props => props.mL}px;
`;


const ManageText = styled.p`
    margin-bottom:1px;
    text-decoration:underline;
    font: 600 12px/19px benton-sans;
    color:#000;
    cursor:pointer;
`;

const ManageLink = styled.p`
    position: absolute;
    bottom: 0px;
    right:20px;
    font-family: yorkten-slab-normal,sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #004A98;
    text-decoration: underline;
    line-height: 2;
    cursor:pointer;
`;

const BackLink = styled.p`
    color:#000;
    font-size:13px;
    font-weight:600;
    text-align:left;
    cursor : pointer;
`;

class ManageMarketTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showRequiredErrorMessage: false,
            samlResponse: {},
            singleSignOnClick:false,
            hadTenItems: 10,
            offset: 1,
            offSetScale: 10,
            displayTradeOption: false,
            displayFundOption: false,
            tradeLocation: {},
            redirectToPurchaseMasterReg: false,
            redirectToLiquidationMasterReg: false,
            redirectToExchangeMasterReg: false,
            displayNotification: false,
            notificationMsg: '',
            masterId: 0,
            accountName: '',
            totalAmount: 0,
            mostRecentDate: '',
            accountType: '',
            registration: {},
            allowedTransactions: {},
            showModal: false,
            showAccountNo: false,
            updatedAccNickname: '',
            isNicknameUpdated: false,
            accountAddress: [],
            fundSummaryClicked: false,
            idOfCurrentFocusElement: '',
            showAuthBox:false,
            checkFisError :  false,
            portfoliovisualizerLink: '',
            details: {},
            menuOptionCheckArray : [],
            menuOptions : [] ,
            functinCallTime : 0,
            currentYearIRA : 0,
            prevYearIRA : 0,
            currYearIRALimit : 0,
            prevYearIRALimit : 0,
            currentYearVal : 0,
            preYear : 0,
            checkIRAContributionSHow : false,
            dashboardFlipFlop:true,
            allowedTransactionsErrorStatus : false,
            errorMessageAllowedTransaction : '',
            accountServiceOptionTemp : [
                {
                    "text": "Automatic Investing"
                },
                {
                    "text": "Automatic Withdrawal"
                },
                {
                    "text": "Dividends and Capital Gains"
                },
                {
                    "text": "Bank Accounts"
                },
                {
                    "text": "Beneficiaries"
                },
                {
                    "text": "Required Minimum Distribution"
                }
            ],
            accountManagePreferences : [
                {
                    "text": "Delivery Preferences"
                },
                {
                    "text": "Phone/Email"
                },
                {
                    "text": "Trusted Contact"
                },
                {
                    "text": "Mailing Address"
                }
            ],
            moreToNXILinkText: "",
            manageTextDescription: "",
            redirectToNAI : false,
            redirectToLink:'',
            redirectToBasicProfile : false,
            addressEditMode : false,
            sameAddress : "",
            allRegistrations:"",
            allRegistrationsList : [
                {key: 'Yes',value: 'Yes',selected: false},
                {key: 'No',value: 'No',selected: false},
            ],
            ismailingSameOptionList:[
                {key: 'Yes',value: 'Yes',selected: false},
                {key: 'No',value: 'No',selected: false},
            ],
            physicalAddresSavedObj :{
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "zip": ""
            },
            mailingAddresSavedObj :{
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "zip": ""
            },
            loadingStatus:false,
            successMessageStatus:false,
            successMessageText:"",
            apiCountState:0,
            positionApiResponse: null,
            mailingAddressDisplay:null,
            renderCount:0
        };
        this.menuRef = React.createRef();
        this.menuItemRefs = React.createRef();
		this.perPhysicalAddressPref = React.createRef();
        this.perMailingAddressPref = React.createRef();
        this.checkPersonalInfoParentNextStatus = this.checkPersonalInfoParentNextStatus.bind(this);
        this.checkPersonalInfoParentChildNextStatus = this.checkPersonalInfoParentChildNextStatus.bind(this);
    }


    static setTradeLocation = (prevState, masterRegistrationAccountData, masterId) => {

        let tradeLocation = {}

        if (masterRegistrationAccountData.masterRegAccountList?.length > 0) {
            // TODO:Purchase
            if (prevState.redirectToPurchaseMasterReg && masterId !== 0 ) {
                tradeLocation = {
                    pathname: '/transactionPurchases',
                    state: { pageIndex: 1, masterId },
                };
            }

            // Liquidation
            if (prevState.redirectToLiquidationMasterReg && masterId !== 0) {
                tradeLocation = {
                    pathname: '/liquidation',
                    state: { pageIndex: 1, masterId },
                };
            }
            // Exchange
            if (prevState.redirectToExchangeMasterReg && masterId !== 0) {
                tradeLocation = {
                    pathname: '/exchange',
                    state: { pageIndex: 1, masterId },
                };
            }
        }

        return tradeLocation
    }


    static setAddressDetails = (transactionHolderData) => {
        const accountSelected = transactionHolderData?.transactionListForManage?.result?.registration;
        let address = '';
        if(transactionHolderData?.transactionListForManage != undefined )
        {
            if (Object.keys(transactionHolderData?.transactionListForManage)?.length > 0) {
       
                const customerNameLineBreak = validateForEmptyAndGetValue(accountSelected?.accountRegistrationLine1, '\n', '')
                const customerNameLineBreak2 = validateForEmptyAndGetValue(accountSelected?.accountRegistrationLine2, '\n', '')
                const addressLine2Break = validateForEmptyAndGetValue(accountSelected?.city, '\n', '')
                const addressLine3Break = validateForEmptyAndGetValue(accountSelected?.state, '\n', '')
                const accountRegistrationLine2Local = accountSelected.accountRegistrationLine2 ? accountSelected.accountRegistrationLine2?.trim() : ''
                const accountSelectedObj = {
                    addressLine2: hasValidValue(accountSelected?.addressLine2, accountSelected?.addressLine2?.trim(), ''),
                    city: hasValidValue(accountSelected?.city, accountSelected?.city?.trim(), ''),
                    state: hasValidValue(accountSelected?.state, accountSelected?.state?.trim(), ''),
                    zip:hasValidValue(accountSelected?.zip, accountSelected?.zip?.trim(), ''),
                }
    
                const splitCustomerName =
                    accountSelected?.accountRegistrationLine1 && accountSelected.accountRegistrationLine1.split('<br/>');
                const splitCustomerName2 =
                    accountSelected?.accountRegistrationLine2 && accountSelected.accountRegistrationLine2?.split('<br/>');
                const formatCustomerName =
                    splitCustomerName && splitCustomerName.length > 1 ? splitCustomerName.join('\n') : splitCustomerName;
                const formatCustomerName2 =
                splitCustomerName2 && splitCustomerName2.length > 1 ? splitCustomerName2.join('\n') : splitCustomerName2;
    
                let customerAccountType = accountSelected?.accountRegistrationLine3 ? accountSelected.accountRegistrationLine3?.trim() : ''
                customerAccountType =
                    customerAccountType === ' ' ? accountSelected.accountRegistrationLine3?.trim() : customerAccountType?.trim();
                customerAccountType =
                    customerAccountType === ' ' ? accountRegistrationLine2Local : customerAccountType?.trim();
    
                address = accountSelected && Object.keys(accountSelected).length > 0
                        ? `${formatCustomerName}${customerNameLineBreak}${formatCustomerName2}${customerNameLineBreak2}${customerAccountType}${customerNameLineBreak}${
                            accountSelectedObj.addressLine2}${customerNameLineBreak}${accountSelectedObj.city}${addressLine2Break}${accountSelectedObj.state}${addressLine3Break}${accountSelectedObj.zip}`
                        : '';
            }
        }
        return address
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            masterRegistrationAccountData,
            accOpeningReducerData,
            location,
            transactionHolderData,
            iRAContributionYearValues
        } = nextProps;

        const { notificationMsg } = prevState;
        let { masterId = 0, name = '' ,accountOwnerDesc} = location.state || {};
        if (masterId !== 0) {
            localStorage.setItem('masterId', masterId);
            localStorage.setItem('accountName', name);
        }else if (masterId === 0 && localStorage.getItem('masterId') != 0) {
            masterId = validateForUndefinedAndGetValue(localStorage.getItem('masterId'), masterId);
            name = validateForUndefinedAndGetValue(localStorage.getItem('accountName'), name);
        }

        let tradeLocation = ManageMarketTransaction.setTradeLocation(prevState, masterRegistrationAccountData, masterId);

        let totalAmount = 0;
        const { transactionList } = transactionHolderData;
        const { fundList } = transactionList;

        if (fundList && fundList.length > 0) {
            totalAmount = fundList
                .map(el => Number(el.currentValue))
                .reduce((a, c) => {
                    return a + c;
                });
        }

        if (nextProps.accOpeningReducerData.nickNameDataForManage !== prevState.nickNameDataForManage) {
            const { message } = accOpeningReducerData.nickNameDataForManage;
            if (message === "Success") {
                return {
                    ...prevState,
                    nickNameDataForManage: accOpeningReducerData.nickNameDataForManage,
                    showModal: false,
                    isNicknameUpdated: true,
                };
            }
        }

        let address = ManageMarketTransaction.setAddressDetails(nextProps?.transactionHolderData);

        return {
            ...prevState,
            tradeLocation,
            masterId,
            accountOwnerDesc,
            accountName: name,
            accountAddress: address,
            totalAmount:
                totalAmount.toString().split('.')[1] && totalAmount.toString().split('.')[1].length === 3
                    ? Math.round(totalAmount * 100) / 100
                    : totalAmount.toFixed(2),
            displayNotification: masterRegistrationAccountData.isError || transactionHolderData.isError ? true : false,
            notificationMsg:
                masterRegistrationAccountData.isError || transactionHolderData.isError
                    ? { message: TransactionConstants.NOTIFICATION_ERROR_MESSAGE }
                    : notificationMsg,

            mostRecentDate: validateForUndefinedAndGetValue(transactionHolderData?.transactionList?.mostRecentDate, ''),
            accountType: validateForUndefinedAndGetValue(transactionHolderData?.transactionList?.accountType, ''),
            registration: validateForUndefinedAndGetValue(transactionHolderData?.transactionList?.registration, {}),
            allowedTransactions: validateForUndefinedAndGetValue(transactionHolderData?.transactionList?.allowedTransactions, []),
            positionApiResponse:transactionHolderData?.transactionListForManage?.result,
            details: transactionHolderData,
            prevYearIRA : validateForUndefinedAndGetValue(iRAContributionYearValues?.isIRAContributionYearData?.prevYearIRA, 0.00), 
            prevYearIRALimit: validateForUndefinedAndGetValue(iRAContributionYearValues?.isIRAContributionYearData?.prevYearIRALimit, 0.00), 
            currentYearIRA: validateForUndefinedAndGetValue(iRAContributionYearValues?.isIRAContributionYearData?.currYearIRA, 0.00),
            currYearIRALimit:validateForUndefinedAndGetValue(iRAContributionYearValues?.isIRAContributionYearData?.currYearIRALimit, 0.00) 
            

        };
    }


    componentDidMount() {
        let da  = new Date();
        this.setState({
            currentYearVal : da.getFullYear(),
            preYear : (da.getFullYear() - 1)
        });
        
        const { getTransHolderListForManage, setLoadingPage,history,getIRAContributionYearValues,getCompositeData,getAccountServiceOptions,location,clearContactDetails } = this.props;
        clearContactDetails();
        window.addEventListener('mousedown', this.handleOutsideClick);
        document.title = `Account holder Transactions | Victory Capital`;
        const { masterId, tradeLocation ,accountOwnerDesc} = this.state;
        const { pathname = '' } = tradeLocation || {};
        const companyNumber = 591;
        const transactionHistoryPayload = {
            accountNumber: masterId,
            registrationType: accountOwnerDesc
        };
       
        setLoadingPage();
        if (pathname !== '/accountholder') {

            getTransHolderListForManage(transactionHistoryPayload);
           
            getCompositeData({ data: { datakey: ['address_type'] },
                token: getTokenFromSession()});
            const { accountOwnerDesc } = this.state;
            const { ownerType, taxId } = location.state || {};
            getAccountServiceOptions({ accountOwnerDesc, ownerType, taxId });
        }

        let currentYear = da.getFullYear();
        let accountType='';
        let masterID = '';
        if(history.location.accountType != undefined )
        {   
            localStorage.setItem("accountType",history.location.accountType.trim());
            localStorage.setItem("masterRegID",history.location.masterId);
        }
        accountType = localStorage.getItem("accountType");
        masterID = localStorage.getItem("masterRegID");

        let accountTypeCode = this.getAccountTypeCode(accountType);
        const payload  = {
            companyNumber : "591",
            year : currentYear,
            accountType : accountType,
            masterReg : masterID,
            accountTypeCode :accountTypeCode,
            socialCode : this.getSocialCode(accountType),
            isMember:true
        };
        
        let showIraTable  = ["200","203","215","202","216"];
        if(showIraTable.includes(accountTypeCode))
        {   
            this.setState({checkIRAContributionSHow :  true});
            getIRAContributionYearValues(payload);
        }
        else
        {
            this.setState({checkIRAContributionSHow :  false});
        }

     
    }

    updateFundDetails = (fundList, totalValue, fundListDetails, link, totalFundPercentage) => {
        if (fundList != undefined) {
            fundListDetails = fundList.map(fund => {
                let properties = {
                    ticker: fund.ticker,
                    amount: fund.currentValue,
                };

                return properties;
            });

            let tempLink = '';

            fundListDetails.forEach((fund, index) => {
                // round 2 decimal digits
                const value = (parseFloat(fund.amount) / totalValue) * 100;
               

                tempLink += `&symbol${index + 1}=${fund.ticker}&allocation${index + 1}_1=${value}`;
            });
            return tempLink;
        }
    };

    componentDidUpdate(prevProps) {
        // round without decimal
        const initialAmount = this.state.details.transactionList.totalCurrentValue;
        const endYear = new Date().getFullYear();
        const startYear = endYear - 20;

        let totalValue = this.state.details.transactionList.totalCurrentValue;
        let fundList = this.state.details.transactionList.fundList;

        let fundListDetails = [];
        let link = '';
        let totalFundPercentage = null;

        const urlLinkData = this.updateFundDetails(fundList, totalValue, fundListDetails, link, totalFundPercentage);

        const {transactionHolderData,profileInformationData} = this.props;
        if(transactionHolderData?.transactionListForManage?.result?.addressInfo?.length > 0 && this.state.renderCount == 0 )
        {
            const mailingAddress = transactionHolderData?.transactionListForManage?.result?.addressInfo.filter(item => item.isMailingAddress == true);
            this.setState({
                mailingAddressDisplay: mailingAddress[0],
                renderCount:1
            })
        }
        if(profileInformationData?.updatedResponse?.message == "Success" && this.state.apiCountState == 0)
            {
                this.setState({
                    successMessageStatus:true,
                    loadingStatus:false,
                    successMessageText: Constants.ApiSuccessMessage,
                    apiCountState:1,
                    addressEditMode:false
                })
            }
            else if(profileInformationData?.updatedResponse != undefined && profileInformationData?.updatedResponse == "Error" && this.state.apiCountState == 0)
            {
                this.setState({
                    successMessageStatus:true,
                    loadingStatus:false,
                    successMessageText:Constants.ApiSuccessErrorMessage,
                    apiCountState:1
                })
            }

        const r = '';
        if (urlLinkData != undefined) {
          
            this.state.portfoliovisualizerLink = `https://www.portfoliovisualizer.com/backtest-portfolio?
            timePeriod=${r}4
            &startYear=${startYear}
            &firstMonth=${r}1
            &endYear=${endYear}
            &lastMonth=${r}12
            &calendarAligned=${r}true
            &includeYTD=${r}false
            &startDate=${r}
            &endDate=${r}
            &initialAmount=${Math.round(initialAmount)}
            &annualOperation=${r}0
            &annualAdjustment=${r}0
            &inflationAdjusted=${r}true
            &annualPercentage=${r}0.0
            &frequency=${r}4
            &rebalanceType=${r}1
            &absoluteDeviation=${r}5.0
            &relativeDeviation=${r}25.0
            &leverageType=${r}0
            &leverageRatio=${r}0.0
            &debtAmount=${r}0
            &debtInterest=${r}0.0
            &maintenanceMargin=${r}25.0
            &leveragedBenchmark=${r}false
            &reinvestDividends=${r}true
            &showYield=${r}false
            &showFactors=${r}false
            &factorModel=${r}3
            &benchmark=${r}
            &benchmarkSymbol=${r}
            &portfolioNames=${r}false
            &portfolioName1=${r}Portfolio+1
            &portfolioName2=${r}Portfolio+2
            &portfolioName3=${r}Portfolio+3
            ${urlLinkData}&

            &total1= 
            &total2=
            &total3=`;
        }
        if (this.props !== prevProps) {
            const { memberDashboardData } = this.props;
            const { memberDashboardData: prevMemberDashboardData } = prevProps;

        }

    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleOutsideClick);
        const { clearLiquidationData } = this.props;

        clearLiquidationData();
    }

    getViewableTransHolderList() {
        const { offSetScale, offset } = this.state;
        const maxRowSize = 10;
        const { transactionHolderData } = this.props;
        const { transactionList } = transactionHolderData;
        const { fundList } = transactionList;
        if (fundList && fundList.length > maxRowSize) {
            return fundList.slice(0, offSetScale * offset);
        }
        return fundList;
    }

    loadMoreData = () => {
        const { offset } = this.state;
        this.setState({ offset: offset + 1 });
    };

    handleOutsideClick = e => {
        if (e) {
            if (e.target.id === 'tradeButton') {
                return;
            }
            if (e.target.id === 'buytradeButtonMenuItem' || e.target.id === 'buytradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'selltradeButtonMenuItem' || e.target.id === 'selltradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'exchangetradeButtonMenuItem' || e.target.id === 'exchangetradeButtonMenuOptions') {
                return;
            }
        }
        this.setState({ displayTradeOption: false });
    };

    onClickTrade = () => {

        const { displayTradeOption } = this.state;

        this.setState({
            displayTradeOption: !displayTradeOption,
            idOfCurrentFocusElement: 'buytradeButtonMenuItem',
        });
        setTimeout(() => {
            document.getElementById('buytradeButtonMenuItem').focus();
        });
    };

    loadAccountList = ()=>
    {
        const { getAccountList } = this.props;
            if(isTokenAvailable() === null ){
         
                this.setState({showAuthBox:true})
                getAccountList(
                    {
                        companyNumber: '591',
                        token: localStorage.getItem('token'),
                    },
                    true,
                    'purchase',
                );
            }else{
                this.setState({showAuthBox:false})
                getAccountList(
                    {
                        companyNumber: '591',
                        token: localStorage.getItem('token'),
                    },
                    true,
                    'purchase',
                );
            }
    }

    onPurchaseClickTrade = () => {
        const { getAccountList } = this.props;
        this.setState({ redirectToPurchaseMasterReg: true });
        this.handleOutsideClick();
        // TODO:
        
        if(isTokenAvailable() === null ){
         
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: localStorage.getItem('token'),
                },
                true,
                'purchase',
            );
        }else{
            getAccountList(
                {
                    companyNumber: '591',
                    token: localStorage.getItem('token'),
                },
                true,
                'purchase',
            );
        } 
    
    };

    onLiquidationClickTrade = () => {
        const { getAccountList } = this.props;
        this.setState({ redirectToLiquidationMasterReg: true });
        this.handleOutsideClick();


        if(isTokenAvailable() === null ){
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: localStorage.getItem('token'),
                },
                true,
                'liquidate',
            );
        }else{
            getAccountList(
            {
                companyNumber: '591',
                token: localStorage.getItem('token'),
            },
            true,
            'liquidate',
            );
        }
    };

    onExchangeClickTrade = () => {
     const { getAccountList } = this.props;
        this.setState({ redirectToExchangeMasterReg: true });
        this.handleOutsideClick();
        if(isTokenAvailable() === null ){
            this.setState({showAuthBox:true})
            getAccountList(
                {
                    companyNumber: '591',
                    token: localStorage.getItem('token'),
                },
                true,
                'exchange',
            );
        }else{
            getAccountList(
            {
                companyNumber: '591',
                token: localStorage.getItem('token'),
            },
            true,
            'exchange',
            );
        } 
       
    };

    editTitleClick = () => {
        this.setState({ showModal: true, isNicknameUpdated: false });
    };

    cancelEditTitleClick = () => {
        this.setState({ showModal: false });
        document.getElementById('edit_btn_id').focus();
    };

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
        });
    };

    submitEditTitleClick = accountName => {
        const { updateAccountNickname, updateAccountNicknameForManage } = this.props;
        const { masterId } = this.state;
       
        if (updateAccountNickname !== '') {
            const payload = {
               accountNumber: masterId,
                accountNickName: accountName
            };
            updateAccountNicknameForManage(payload);
            this.setState({ updatedAccNickname: accountName });
        }
    };

    accountNoClick = () => {
        this.setState(prevState => ({
            showAccountNo: !prevState.showAccountNo,
        }));
    };

    fundSummaryUpdate = () => {
        this.setState(prevState => ({
            fundSummaryClicked: !prevState.fundSummaryClicked,
        }));
    };

    onNavigate = e => {
        e.preventDefault();
        const { displayTradeOption, allowedTransactions, idOfCurrentFocusElement } = this.state;
        let nextFocusElement;
        if (e.which === 38) {
            if (idOfCurrentFocusElement === 'buytradeButtonMenuItem') {
                nextFocusElement = 'exchangetradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'selltradeButtonMenuItem') {
                nextFocusElement = 'buytradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'exchangetradeButtonMenuItem') {
                nextFocusElement = 'selltradeButtonMenuItem';
            }
            document.getElementById(nextFocusElement).focus();
            this.setState({ idOfCurrentFocusElement: nextFocusElement });
        }

        if (e.which === 40) {
            e.preventDefault();
            if (idOfCurrentFocusElement === 'buytradeButtonMenuItem') {
                nextFocusElement = 'selltradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'selltradeButtonMenuItem') {
                nextFocusElement = 'exchangetradeButtonMenuItem';
            } else if (idOfCurrentFocusElement === 'exchangetradeButtonMenuItem') {
                nextFocusElement = 'buytradeButtonMenuItem';
            }
            document.getElementById(nextFocusElement).focus();
            this.setState({ idOfCurrentFocusElement: nextFocusElement });
        }
        // click event that navigate to a certain path
        if (
            e.which === 13 &&
            allowedTransactions.includes('Buy') &&
            idOfCurrentFocusElement === 'buytradeButtonMenuItem'
        ) {
            this.onPurchaseClickTrade('purchase');
        }
        if (
            e.which === 13 &&
            allowedTransactions.includes('Sell') &&
            idOfCurrentFocusElement === 'selltradeButtonMenuItem'
        ) {
            this.onLiquidationClickTrade('liquidate');
        }
        if (
            e.which === 13 &&
            allowedTransactions.includes('Exchange') &&
            idOfCurrentFocusElement === 'exchangetradeButtonMenuItem'
        ) {
            this.onExchangeClickTrade('exchange');
        } else if (e.which === 27 || e.which === 9) {
            this.setState({
                displayTradeOption: !displayTradeOption,
                idOfCurrentFocusElement: '',
            });
            document.getElementById('tradeButtonMenu').focus();
        }

    };

    onMenuOptionClick = menuOption => () => {
        if (menuOption === constant.buy) {
            this.onPurchaseClickTrade();
        } else if (menuOption === constant.sell) {
            this.onLiquidationClickTrade();
        } else if (menuOption === constant.exchange) {
            this.onExchangeClickTrade();
        }
    };

    moveNextPage = () =>{
        this.setState({showAuthBox: false})
    }

    authBox = () =>{
        this.setState({showAuthBox: true})
    }

    onNotificationForFis = ()=>{
        
        this.setState({
            checkFisError: true
        });
    }

    onNotificationCloseFis = ()=>
    {
        this.setState({
            checkFisError: false
        });
    }

    
    // Below Changes   --- For adding  IRA Contributions Year, author : Gaurav Kumar (Hyniva) 
    getAccountTypeCode = accountType => {
        switch (accountType?.trim()) {
            case 'Individual':
                return '100';
            case 'Joint Tenants With Right of Survivorship':
                return '100';
            case 'Joint Tenants in Common':
                return '100';
            case 'Joint Tenants in Entirety':
                return '100';
            case 'Traditional IRA':
                return '200';
            case 'Roth IRA':
                return '215';
            case 'Minor Roth IRA':
                return '215';
            case 'Roth IRA - Conversion':
                return '216';
            case 'Rollover IRA':
                return '202';
            case 'SEP IRA':
                return '203';
            case 'Simple IRA':
                return '212';
            case 'Simple IRA - Non DFI':
                return '210';
            case 'Simple IRA - DFI':
                return '212';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '100';
            case 'Coverdell ESA':
                return '219';
            case 'Archer MSA':
                return '220'
            default:
                return '100';
        }
    };

    getSocialCode = accountType => {
        switch (accountType && accountType.trim()) {
            case 'Individual':
                return '1';
            case 'Joint Tenants With Right of Survivorship':
                return '3';
            case 'Joint Tenants in Common':
                return '3';
            case 'Joint Tenants in Entirety':
                return '3';
            case 'Traditional IRA':
                return '14';
            case 'Roth IRA':
                return '14';
            case 'Rollover IRA':
                return '14';
            case 'SEP IRA':
                return '14';
            case 'Simple IRA':
                return '14';
            case 'Roth IRA - Conversion':
                return '14';
            case '529 College Saving Plan':
                return '';
            case '529 Education Savings Plan':
                return '';
            case 'Uniform Gifts/Transfers to Minors(UGMA/UTMA)':
                return '7';
            default:
                return '1';
        }
    };


    addDecimalZeroes=(num)=> {
        if(typeof(num) === 'string'){
            num = parseFloat(num.replace(/,/g, ''))
            let numWithZeroes = num.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2});
            return numWithZeroes
        }
        else{
            return num
        } 
    }

    addTwoDecimalZeroes=(currentYearIRA)=>{
        return `${currencyFormatter.format(currentYearIRA, {
            locale: 'en-US',
        })}`;
    }

    placeATradeonSingleSignOnClick = (payload) => {   
        window.open(`${envConfig.NETX360PLUS_SSO}${getSessionValues().vcmid}`, "_blank");
    }

    onSingleSignOnClick = (payload) => {   
        SessionStorageHelper.setItem('brAccountClicked',true)
        this.props.getGeneratedSAMLAssertion(payload)
    }
        
    handleSingleSignOnClick = () =>{
        this.setState({singleSignOnClick:false})
    }


    showManagePrefrenceDashboard = (textType="") =>
    {
        let redirectToNAITemp = true;
        let redirectLink = '';

        if(textType == 'Phone/Email')
        {
           this.setState({
            redirectToBasicProfile : true
           })
        }
        else if(textType == 'Automatic Investing' || textType == 'Bank Accounts' ||
         textType == 'Automatic Withdrawal' ||
         textType == 'Dividends and Capital Gains') 
        {
            if(textType == "Bank Accounts")
            {
                redirectLink = `Manage ${textType}`;
            }
            else
            {
                redirectLink = `Launch ${textType}`;
            }
            redirectToNAITemp = true;
        }
        else
        {
            redirectToNAITemp = false;
        }

        const managePreferencsMessage  = Constants.INFO.MANAGE_PREFERENCES_KEY_MESSAGE.filter((item)=> item.key == textType)

        this.setState({
            dashboardFlipFlop: !this.state.dashboardFlipFlop,
            moreToNXILinkText : textType,
            redirectToNAI : redirectToNAITemp,
            redirectToLink:redirectLink,
            manageTextDescription : managePreferencsMessage[0]?.message,
            addressEditMode : false
        })
    }

    getMasterDataValues = masterLookupStateData => {
        const { address_type: addressTypesMaster } = masterLookupStateData || {};
        const { value: addressTypesMasterValue } = addressTypesMaster || {};

        const addressTypeData = addressTypesMasterValue || [];
        return { addressTypeData };
    };

    handleRadioChange = e => {
        const { fields, error } = this.state;
        const { target } = e;
        const { value } = target;
        const { masterLookupStateData } = this.props;
        const addressTypeValue = getKeyfromValue(masterLookupStateData, 'address_type', value);
        fields.addressType = addressTypeValue;
        if (error.addressType) error.addressType = '';
        this.setState({
            fields,
            error,
        });
    };

  

   handleEditSubmit = () =>
    {
        const {addProfileContactInformations,clearContactDetails} = this.props;
        clearContactDetails();
        if(this.state.sameAddress ==="" || this.state.allRegistrations ===""){
            this.setState({showRequiredErrorMessage: true})
        }
        const mailingInfo = this.perMailingAddressPref?.current?.getAddress();
        if(mailingInfo?.valid == true && this.state.sameAddress !=""  && this.state.sameAddress =="Yes"  && this.state.allRegistrations !="")
        {
            this.setState({loadingStatus:true,
            apiCountState:0,
            successMessageStatus:false,
            successMessageText:"" })
            const {history} = this.props;
            const {location} = history;
            const {state} = location;
            const {physicalAddresSavedObj} = this.state;

            const mailingAdd = {
                "addressType": mailingInfo?.mailTypeRadio,
                "addressLine1":mailingInfo?.lineOne,
                "addressLine2":mailingInfo?.lineTwo,
                "city": mailingInfo?.city,
                "state": mailingInfo?.state,
                "zip": mailingInfo?.zipCode,
                "isPhysicalAddress": false,
                "isMailingAddress": true,
                "isSeasonalAddress": false,
                "isForeignAddress": false
            }

            const phyAdd = {
                "addressType":"U.S. or U.S. Territories",
                "addressLine1":physicalAddresSavedObj?.addressLine1,
                "addressLine2":physicalAddresSavedObj?.addressLine2,
                "city": physicalAddresSavedObj?.city,
                "state": physicalAddresSavedObj?.state,
                "zip": physicalAddresSavedObj?.zip,
                "isPhysicalAddress": true,
                "isMailingAddress": false,
                "isSeasonalAddress": false,
                "isForeignAddress": false
            }

            const sendingData = {
               "source":"Web",
               "requestTo": "brokerage",
               "addressInfo":[mailingAdd,phyAdd],
               "isMailingPhysicalSame":true,
               "accountNumber":state?.masterId,
               "applyToAllAccounts" : (this.state.allRegistrations == "Yes") ? true : false,
               "isFamilyAndFriends": true

            }
            addProfileContactInformations(sendingData);
        }
        else if(mailingInfo?.valid == true &&  this.state.sameAddress !="" && this.state.sameAddress =="No" && this.state.allRegistrations !="")
        {
            const physicalInfo = this.perPhysicalAddressPref?.current?.getAddress();
            if(physicalInfo?.valid == true)
            {
                this.setState({loadingStatus:true,
                    apiCountState:0,
                    successMessageStatus:false,
                    successMessageText:"" })

                this.setState({loadingStatus:true,apiCountState:0})
                const {history} = this.props;
                const {location} = history;
                const {state} = location;
    
                const mailingAdd = {
                    "addressType": mailingInfo?.mailTypeRadio,
                    "addressLine1":mailingInfo?.lineOne,
                    "addressLine2":mailingInfo?.lineTwo,
                    "city": mailingInfo?.city,
                    "state": mailingInfo?.state,
                    "zip": mailingInfo?.zipCode,
                    "isPhysicalAddress": false,
                    "isMailingAddress": true,
                    "isSeasonalAddress": false,
                    "isForeignAddress": false
                }
    
                const phyAdd = {
                    "addressType": physicalInfo?.mailTypeRadio,
                    "addressLine1":physicalInfo?.lineOne,
                    "addressLine2":physicalInfo?.lineTwo,
                    "city": physicalInfo?.city,
                    "state": physicalInfo?.state,
                    "zip": physicalInfo?.zipCode,
                    "isPhysicalAddress": true,
                    "isMailingAddress": false,
                    "isSeasonalAddress": false,
                    "isForeignAddress": false
                }
    
                const sendingData = {
                   "source":"Web",
                   "requestTo": "brokerage",
                   "addressInfo":[mailingAdd,phyAdd],
                   "isMailingPhysicalSame":false,
                   "accountNumber":state?.masterId,
                   "applyToAllAccounts" : (this.state.allRegistrations == "Yes") ? true : false,
                   "isFamilyAndFriends": true
                }
                addProfileContactInformations(sendingData);
            }
            
           
        } 
    }
    
    radioButtonClick = (eventKey) => e => {

       if(eventKey == "sameAddress")
       {
        
            let updatedList =  this.state.ismailingSameOptionList.map((item,index)=>{

                if(e.target.value == item.value)
                {
                    return {key: item.key,value:item.value,selected: true}
                }
                else
                {
                    return {key: item.key,value:item.value,selected: false}
                }
            })

            this.setState({
                ismailingSameOptionList: updatedList,
                sameAddress:e.target.value
            })
       }
       else if(eventKey == "allRegistrations")
       {
            let updatedList =  this.state.allRegistrationsList.map((item,index)=>{

                if(e.target.value == item.value)
                {
                    return {key: item.key,value:item.value,selected: true}
                }
                else
                {
                    return {key: item.key,value:item.value,selected: false}
                }
            })

            this.setState({
                allRegistrationsList: updatedList,
                allRegistrations:e.target.value
            })

       }
        
    }

    handleRadioBlur = () => {
        const { location } = this.props;
        const { state } = location;
        const { addressType } = state || {};
        const { error, fields } = this.state;
        if (!fields.addressType && !addressType) error.addressType = Constants.ADDRESS_TYPE_ERROR;
        this.setState({
            fields,
            error,
        });
    };

    assignMailingAndPhysicalAddress = () =>
    {
        const physicalAdd = this.state.positionApiResponse?.addressInfo?.filter(item => item.isPhysicalAddress == true);
        const mailingAdd = this.state.positionApiResponse?.addressInfo?.filter(item => item.isMailingAddress == true);

        const phyAdd = {
            "addressLine1": physicalAdd[0]?.addressLine2,
            "addressLine2":  physicalAdd[0]?.addressLine1,
            "city": physicalAdd[0]?.city,
            "state": physicalAdd[0]?.state,
            "isSeasonalAddress": physicalAdd[0]?.isSeasonalAddress,
            "isForeignAddres": physicalAdd[0]?.isForeignAddres,
            "zip": physicalAdd[0]?.zip,
            "isPhysicalAddress": physicalAdd[0]?.isPhysicalAddress,
            "isMailingAddress": physicalAdd[0]?.isMailingAddress
        }

        const mailAdd = {
            "addressLine1": mailingAdd[0]?.addressLine2,
            "addressLine2":  mailingAdd[0]?.addressLine1,
            "city": mailingAdd[0]?.city,
            "state": mailingAdd[0]?.state,
            "isSeasonalAddress": mailingAdd[0]?.isSeasonalAddress,
            "isForeignAddres": mailingAdd[0]?.isForeignAddres,
            "zip": mailingAdd[0]?.zip,
            "isPhysicalAddress": mailingAdd[0]?.isPhysicalAddress,
            "isMailingAddress": mailingAdd[0]?.isMailingAddress
        }

        this.setState({
            physicalAddresSavedObj: phyAdd,
            mailingAddresSavedObj: mailAdd
        })
    }

    handleEditMode = (cancel = "default") =>
    {
        this.assignMailingAndPhysicalAddress()
        this.setState({
            addressEditMode:!this.state.addressEditMode,
            sameAddress:"",
            showRequiredErrorMessage:false,
            allRegistrations:"",
            allRegistrationsList:[
                {key: 'Yes',value: 'Yes',selected: false},
                {key: 'No',value: 'No',selected: false},
            ],
            ismailingSameOptionList:[
                {key: 'Yes',value: 'Yes',selected: false},
                {key: 'No',value: 'No',selected: false},
            ],
        })

        if(cancel == "cancel")
        {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }
    checkPersonalInfoParentNextStatus() {
        const {
            firstName,
            lastName,
            ownerDateOfBirth,
            residentCardNumber,
            passportNumber,
            ownerResidentExpiry,
            ownerPassportExpiry,
            gender,
            maritalStatus,
            citizenship,
            resident,
            fullSSN,
            sameAddress,
        } = this.state;

        // debugger
        return (
            !!firstName &&
            !!lastName &&
            !!residentCardNumber &&
            !!passportNumber &&
            !!ownerResidentExpiry &&
            !!ownerPassportExpiry &&
            !!ownerDateOfBirth &&
            !!gender &&
            !!maritalStatus &&
            !!citizenship &&
            !!resident &&
            !!fullSSN &&
            !!sameAddress
        );
    }


    checkPersonalInfoParentChildNextStatus() {
        const { errorMsg } = this.state;
        let disableNext = true;
        const perMailAddressData = this.perMailAdrressPref.current;
        const perPhysicalAddressData = this.perPhysicalAddressPref.current;
        const perMailingAddressData = this.perMailingAddressPref.current;
        const phoneDetailsData = this.phoneDetailsRef.current;
        const emailAddressData = this.emailAddressRef.current;
        const { sameAddress } = this.state;
        // Check personalinfocard status
        disableNext = !this.checkPersonalInfoParentNextStatus(errorMsg);

        if (perMailAddressData) {
            const mailingAddState = { ...perMailAddressData.state };
            disableNext = disableNext || !mailingAddState.ready;
        }

        if (perMailingAddressData) {
            const mailingAddState = { ...perMailingAddressData.state };
            disableNext = disableNext || !mailingAddState.ready;
        }


        if (sameAddress && perPhysicalAddressData) {
            const physicalAddState = { ...perPhysicalAddressData.state };
            disableNext = disableNext || !physicalAddState.ready;
        }
        if (phoneDetailsData) {
            const phoneDetailState = { ...phoneDetailsData.state };
            disableNext = disableNext || !phoneDetailState.ready;
        }
        if (emailAddressData) {
            const emailAddState = { ...emailAddressData.state };
            disableNext = disableNext || !emailAddState.ready;
        }
        const { disableNext: prevStateNextDisabled } = this.state;
        if (prevStateNextDisabled !== disableNext) {
            this.setState(
                () => {
                    return {
                        pageReady: !disableNext,
                    };
                },
                () => {
                    const { checkStatus } = this.props;
                    checkStatus();
                },
            );
        }
    }

    allowedBuyTransactions = (allowedTransactions) =>
    {
        
        if(allowedTransactions.includes('Buy') == true)
        {
           if(this.state.menuOptionCheckArray.includes("Buy")==false)
           {
                this.state.menuOptionCheckArray.push(allowedTransactions[allowedTransactions.indexOf('Buy')])
                this.state.menuOptions.push({ name: constant.buy })
           }
        }
    }

    allowedSellTransactions = (allowedTransactions) =>
    {
        if(allowedTransactions.includes('Sell') == true)
        {
           if(this.state.menuOptionCheckArray.includes("Sell")==false)
           {
                this.state.menuOptionCheckArray.push(allowedTransactions[allowedTransactions.indexOf('Sell')])
                this.state.menuOptions.push( { name: constant.sell })
           }
        }
    }

    allowedExchangeTransactions = (allowedTransactions) =>
    {
        if(allowedTransactions.includes('Exchange') == true)
        {
           if(this.state.menuOptionCheckArray.includes("Exchange")==false)
           {
                this.state.menuOptionCheckArray.push(allowedTransactions[allowedTransactions.indexOf('Exchange')])
                this.state.menuOptions.push( { name: constant.exchange })
           }
        } 
    }

    handleLoadingStatus = (transactionHolderData,masterRegistrationAccountData,accOpeningReducerData) =>
    {
        return (transactionHolderData.isLoading ||
        masterRegistrationAccountData.isLoading ||
        accOpeningReducerData.isLoading);
    }

    

    render() {

        const {
            tradeLocation,
            displayNotification,
            notificationMsg,
            masterId,
            accountName,
            allowedTransactions,
            showModal,
            accountAddress,
            isNicknameUpdated,
            updatedAccNickname,
            showAuthBox,
            moreToNXILinkText,
            manageTextDescription,
            redirectToNAI,
            successMessageText,
            successMessageStatus,
            error,
            samlResponse
        } = this.state;
        const {
            transactionHolderData,
            masterRegistrationAccountData,
            accOpeningReducerData,
			masterLookupStateData,
            location
        } = this.props;
        const isLoading = this.handleLoadingStatus(transactionHolderData,masterRegistrationAccountData,accOpeningReducerData)

        const placeTradeHider  = this.props.history.location?.state?.PlaceTradeHider || {};
        const accountActivityHider=this.props.history.location?.state?.accountActivityHider

        const { nickNameDataForManage } = accOpeningReducerData;
        const { showMessage, message } = nickNameDataForManage;
        const successMessage = () => {
            return { message };
        };
        const accountNo = accountName.split('-');
        const { accountServiceOptionsLinks = [] ,accountManagePreferences = []} = transactionHolderData || {};

        this.allowedBuyTransactions(allowedTransactions);
        this.allowedSellTransactions(allowedTransactions);
        this.allowedExchangeTransactions(allowedTransactions);
        if(this.state.redirectToBasicProfile)
        {
            return (
                <Redirect to="/basicInformation" />
            )
        }
        const totalAmount = transactionHolderData?.transactionListForManage?.result?.totalCurrentValue;
        const accountType =  transactionHolderData?.transactionListForManage?.result?.registrationType;
        return (
            <div className="borderDesign" data-testid="trans-act-hist">
                {(isLoading == true || this.state.loadingStatus == true) && (
                    <WSpinner
                        aria-busy="true"
                        aria-live="polite"
                        aria-label="Please wait while we load more data"
                        loading={true}
                    />
                )}

                { <Authenticate showAuthBox={showAuthBox}  moveNextPage={this.moveNextPage} onNotificationForFis={this.onNotificationForFis} />}
                {tradeLocation.pathname && showAuthBox===false && isTokenAvailable() && <Redirect to={tradeLocation} />}
                {displayNotification && <WMessageBar text={notificationMsg} onClose={this.onNotificationClose} />}
                {this.state.checkFisError && <WMessageBar text={TransactionConstants.MSR_INVALID_CREDENTIAL} onClose={this.onNotificationCloseFis} />}
                
                {showMessage && <WMessageBar text={successMessage()} />}
                {successMessageStatus && <WMessageBar text={{
                    message:successMessageText
                }}  onClose={this.onNotificationClose} /> }
                <div className="container">

                { this.state.singleSignOnClick && <SingleSignOn handleSingleSignOnClick={this.handleSingleSignOnClick} SAMLResponse={samlResponse.SAMLResponse} SSOIndicator={samlResponse.SSOIndicator} />}

                    <div className="transContainer">
                        <WBreadCrumb breadCrumbItems={Constants.INFO.BREADCRUMB_VALUES} activeCrumb={accountName} />

                        <StyledHeaderRow className="row tradeMenuBtnWrapper tradeMenuBtn">
                            <Col xs={12} md={9}>
                                <SideHeaderDiv>
                                    <AccountImgData />
                                    <LeftMargined mL="18">
                                        <PageHeading as="h1">
                                            {isNicknameUpdated
                                                ? `${updatedAccNickname}-${accountNo[1]}`.substring(0, 18)
                                                : accountName.substring(0, 18)}
                                        </PageHeading>
                                    </LeftMargined>
                                    <EditBtn id="edit_btn_id" onClick={this.editTitleClick}>
                                        <Styles.ConstantStyleEdit>
                                            <Styles.EditImage alt="" src={editIcon} />
                                            <p>{Constants.EDIT_TEXT}</p>
                                        </Styles.ConstantStyleEdit>
                                        <span className="sr-only">
                                            {isNicknameUpdated === false ? 'Opens a dialog' : null}
                                        </span>
                                    </EditBtn>
                                    {showModal && (
                                        <UpdateText
                                            onPrimaryClick={this.submitEditTitleClick}
                                            onSecondaryClick={this.cancelEditTitleClick}
                                        />
                                    )}
                                </SideHeaderDiv>
                            </Col>
                            {
                                <Col xs={12} md={3} align="right">
                                {placeTradeHider== "PlaceTradeHider" ? "" : <PrimaryBtn
                                        onClick={()=>this.placeATradeonSingleSignOnClick({TRADING_ORDERTYPECHOICE: "neworder"})}>
                                        {Constants.PLACE_A_TRADE}
                                    </PrimaryBtn>}


                                </Col>
                            }
                        </StyledHeaderRow>
                        <Container className="pl-0">
                        {(this.state.dashboardFlipFlop == true) ? (
                        <Row
                        style={assignObj({
                            border: borderColor,
                            marginBottom: '25px',
                            backgroundColor: '#f7f7f7',
                        })}
                    >
                        <Col xs={12} sm={12} md={6}>
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    style={assignObj({
                                        borderRight: borderColor,
                                        backgroundColor: '#ffffff',
                                        textAlign: 'center',
                                        height: '275px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingTop: '35px',
                                    })}
                                >
                                    <div className="acc-activity-main">
                                        <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                            {Constants.INFO.CURR_VALUE}
                                        </span>
                                        <p className="font-weight-bold" style={assignObj({ fontSize: '20px' })}>
                                            {currencyFormatter.format(totalAmount, { locale: 'en-US' })}
                                        </p>
                                    </div>
                                   {accountActivityHider=="accountActivityHider"?"":<Link
                                        style={assignObj({
                                            height: '21px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            lineHeight: '20px',
                                            display: 'inline-block',
                                            color: 'rgba(0, 74, 152, 1)',
                                        })}
                                        to={assignObj({
                                            pathname: '/accountholder/activity',
                                            state: {
                                                name: accountName,
                                                masterRegId: masterId,
                                            },
                                        })}
                                    >
                                        {Constants.INFO.V_AC_ACTIVITY}
                                    </Link>}
                                            {/*  Below Changes  3209  --- For adding  IRA Contributions , author :  Gaurav Kumar (Hyniva) */}

                                                { this.state.checkIRAContributionSHow ? (
                                            <div>
                                            <hr/>
                                            <div className="row mainDiv" >
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <table className="tableDiv">
                                                        <thead>
                                                            <tr>
                                                                <th className="tableHeaderWidth2"></th>
                                                                <th style={{fontWeight : "normal"}}> <span className="iraContributionFontSize">Contributions</span></th>
                                                                <th style={{fontWeight : "normal"}}><span className="iraContributionFontSize">Limit</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.preYear}:</span></td>
                                                                <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.prevYearIRA)}</b></span></td>
                                                                <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.prevYearIRALimit}</b></span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="tableHeaderWidth2"><span className="iraContributionFontSize">{this.state.currentYearVal}:</span></td>
                                                                <td className="secondColumn"><span className="iraContributionFontSize"><b>{this.addTwoDecimalZeroes(this.state.currentYearIRA)}</b></span></td>
                                                                <td className="thirdColumn"><span className="iraContributionFontSize"><b>&#36;{this.state.currYearIRALimit}</b></span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </div>
                                            ) : (

                                                <div></div>
                                            )}
                                        
                                        {/* End 3209 here author :  Gaurav Kumar (Hyniva)*/}
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <div
                                        className="acc-activity-main"
                                        style={assignObj({ paddingTop: '15px' })}
                                    >
                                        <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                            {Constants.INFO.ACC_NUMBER}
                                        </span>
                                        <p className="font-weight-bold">
                                            {masterId}
                                        </p>
                                    </div>
                                    <div
                                        className="acc-activity-main"
                                        style={assignObj({ paddingTop: '15px' })}
                                    >
                                        <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                            {Constants.INFO.ACC_TYPE}
                                        </span>
                                        <p className="font-weight-bold">Brokerage:{accountType}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <div
                                        className="acc-activity-main"
                                        style={assignObj({ paddingTop: '15px' })}
                                    >
                                        <span style={assignObj({ fontSize: '14px', lineHeight: '22px' })}>
                                            {Constants.INFO.REG}
                                        </span>
                                        <p className="font-weight-bold">
                                            <pre
                                                style={assignObj({
                                                    fontSize: '14px',
                                                    fontFamily:
                                                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                                                })}
                                            >
                                                {accountAddress}
                                            </pre>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col 
                                             xs={12}
                                             sm={12}
                                             md={3}
                                             style={assignObj({
                                                 backgroundColor: '#ffffff',
                                                 textAlign: 'left',
                                                 minHeight: '275px',
                                                 alignItems: 'left',
                                                 justifyContent: 'left',
                                                 paddingTop: '15px',
                                                 paddingBottom: '15px'
                                             })}
                                         >
                                          <div>
                                                 <StyledSpan>{Constants.INFO.MANAGE_SERVICES}</StyledSpan>
                                                 <AccountOptionsLinksContainer>
                                                     {accountServiceOptionsLinks.map((item,index) => {

                                                            return (
                                                                <div id={`manageTextServices${index}`}>
                                                                        <ManageText onClick={()=>{this.showManagePrefrenceDashboard(item.text)}}>{item.text}</ManageText>
                                                                </div>
                                                            );
                                         
                                                     })}
                                                 </AccountOptionsLinksContainer>
                                             </div>  
 
                                              <div className='mt-3'>
                                                 <StyledSpan>{Constants.INFO.MANAGE_PREFERENCES}</StyledSpan>
                                                 <AccountOptionsLinksContainer>
                                                     {accountManagePreferences.map((item,index) => {

                                                        return (
                                                            <div  id={`manageTextPreferences${index}`}>
                                                                <ManageText onClick={()=>{this.showManagePrefrenceDashboard(item.text)}}>{item.text}</ManageText>
                                                            </div>
                                                        );
                                                   
                                                         
                                                     })}
                                                 </AccountOptionsLinksContainer>
                                             </div>                                            
                                         </Col> 
                    </Row>
                        ):(
                            <Row
                            className='displayManageBox'
                            style={assignObj({
                                border: borderColor,
                                marginBottom: '25px',
                                minHeight: '275px',
                            })}
                        >
                            <div  className="col-xl-4 col-ms-12 col-md-4"
                             style={assignObj({
                                borderRight: borderColor,
                                textAlign: 'center',
                                minHeight: '275px',
                                backgroundColor: '#f7f7f7',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '15px',
                            })}
                            >
                
                                   
                                        <div className="acc-activity-main" id="backButtonDiv">
                                        <BackLink onClick={this.showManagePrefrenceDashboard} ><img src={backArrow} style={{width:'12px', height: '12px', marginRight: '4px'}} />Back to Account Overview</BackLink>
                                            
                                            <p id="textheading" className="font-weight-bold" style={assignObj({ fontSize: '20px', marginTop: '80px',color:'#000' })}>
                                                {moreToNXILinkText}
                                            </p>
                                        </div>

                            </div>
                            <div className="col-xl-8 col-ms-12 col-md-8 displayRightBox" style={{backgroundColor: '#fff'}}>
                                        <div
                                            className="acc-activity-main displayinnerBox"
                                            style={assignObj({paddingLeft: '60px'})}
                                        >
                        
                                            {(moreToNXILinkText == 'Mailing Address' ) ? (
                                                <>
                                                {(this.state.addressEditMode == false) ? (
                                                       <div>
                                                       <div style={{float:"right"}}>
                                                           <p className='Edit' style={{fontWeight: "bold",cursor:"pointer"}} onClick={this.handleEditMode} >Edit</p>
                                                       </div>
                                                       <div className='mt-4'>
                                                           <p className='title' style={{fontWeight: "bold"}}>Address Type</p>
                                                           <p style={{lineHeight: "1px"}}>U.S. or U.S.Territories</p>
                                                       </div>

                                                       <div style={{float:'left',marginTop:"9%"}}>
                                                           <p className='title' style={{fontWeight: "bold",lineHeight: "1px"}}>Address</p>
                                                           <p>{this.state.mailingAddressDisplay?.addressLine1} {this.state.mailingAddressDisplay?.addressLine2} {this.state.mailingAddressDisplay?.city} {this.state.mailingAddressDisplay?.state} {this.state.mailingAddressDisplay?.zip}</p>
                                                       </div>
                                                   </div>
                                                ) : (
                                                    <div className='mt-4'>
                                
                                                            <Address
                                                              savedState={this.state.mailingAddresSavedObj}
                                                              ref={this.perMailingAddressPref}
                                                              required
                                                              labelAdd1="Address Line 1"
                                                              labelAdd2="Address Line 2"
                                                              type="Mailing"
                                                              physicalRadio= {true}
                                                              />
                                
                                                        <div className="mobileCMSFieldValue">
                                                            <SectionTitle>{Constants.IsmailingSame}</SectionTitle>
                                                            {this.state.ismailingSameOptionList.map(item =>
                                                                <WRadio
                                                                    id={`isSameAddress${item.key}`}
                                                                    key={item.key}
                                                                    name={item.value}
                                                                    value={item.value}
                                                                    radioFor={item.value}
                                                                    radiostyles={radiostyles}
                                                                    selected={item.selected}
                                                                    onClick={this.radioButtonClick('sameAddress')}
                                                                />
                                                            )}

                                                        {this.state.sameAddress === '' && this.state.showRequiredErrorMessage ? <><p style={{color:'red'}}>Mandatory field</p></> : null}

                                                        {(this.state.sameAddress == "No") && 
                                                            <div className='mt-5'>
                                                              <Address
                                                            savedState={this.state.physicalAddresSavedObj}
                                                            ref={this.perPhysicalAddressPref}
                                                            required
                                                            labelAdd1="Physical Address Line 1"
                                                            labelAdd2="Physical Address Line 2"
                                                            type="physical"
                                                            physicalRadio= {false}
                                                           
                                                            />
                                                            </div>
                                                        }        
                                                        </div>
                                                        <div className="mobileCMSFieldValue">
                                                        <SectionTitle>{Constants.ApplyRegistrationtext}</SectionTitle>
                                                        {this.state.allRegistrationsList.map(item =>
                                                            <WRadio
                                                                id={`allRegistrationsList${item.key}`}
                                                                key={item.key}
                                                                name={item.value}
                                                                value={item.value}
                                                                radioFor={item.value}
                                                                radiostyles={radiostyles}
                                                                selected={item.selected}
                                                                onClick={this.radioButtonClick('allRegistrations')}
                                                            />
                                                        )}

                                                        {this.state.allRegistrations === '' && this.state.showRequiredErrorMessage ? <><p style={{color:'red'}}>Mandatory field</p></> : null}
                                                              
                                                        </div>
                                                        <BtnWrap>
                                                        <div style={{float:'left'}}>
                                                            <WButton
                                                                submit="submit"
                                                                buttontext="Save"
                                                                tabIndex="0"
                                                                id="create-secure-submit"
                                                                onClick={this.handleEditSubmit}
                                                            />
                                                        </div>
                                                        <div style={{float:'left'}}>
                                                            <WButton
                                                                id="cancelButton"
                                                                variant="cancel"
                                                                buttontext="Cancel"
                                                                onClick={()=>this.handleEditMode('cancel')}
                                                                data-analytic-id="message_center_create_secure_message_cancel"
                                                            />
                                                    </div>
                                                   
                                                    </BtnWrap>
                                                    </div>
                                                )}
                                             
                                                </>
                                            ) : (
                                                 <p  id="textDescription">
                                              
                                                 {manageTextDescription}
                                             </p>
                                            )}
                                           

                                            {(moreToNXILinkText == 'Automatic Withdrawal') && 
                                                <div style={{marginTop:"10%"}} id="automaticWithdrawalText" >

                                                    <p style={{fontWeight:"bold"}}>Phone number: (888) 555 - 5555</p>
                                                    
                                                </div>}

                                                {(moreToNXILinkText == 'Bank Accounts') && 
                                                <div style={{marginTop:"2%"}} id="manageBankImage">

                                                    <img src={bankImage} style={{height:"140px"}} id="bankImage" />
                                                    
                                                </div>}


                                            {(redirectToNAI == true) && 
                                                    <ManageLink 
                                                    id="redirectManageLink" onClick={()=>this.placeATradeonSingleSignOnClick({TRADING_ORDERTYPECHOICE: "neworder"})} >{this.state.redirectToLink}<img src={rightArrow} className='transferArrowIcon' /></ManageLink>
                                                
                                            }
                                            
                                        </div>
                                   
                            </div>
                        </Row>

                        )} 
                        </Container>
                    </div>
                </div>
                <div
                    className="container-fluid"
                    role="contentinfo"
                    style={assignObj({ backgroundColor: '#FAFAFA', borderTop: borderColor })}
                >
                    <div className="container pt-4 pb-4">
                        <BackNavigation
                            text={Constants.INFO.BACK_TO_PORT}
                            url={Constants.INFO.URL}
                            icon={leftArrowGreen}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ManageMarketTransaction.propTypes = {
    location: PropTypes.shape({
        masterId: PropTypes.string,
        name: PropTypes.string,
    }),
    history: PropTypes.instanceOf(Object).isRequired,
    transactionHolderData: PropTypes.instanceOf(Object),
    getTransHolderList: PropTypes.func,
    setLoadingPage: PropTypes.func,
    isLoading: PropTypes.bool,
    getAccountList: PropTypes.func,
    masterRegistrationAccountData: PropTypes.instanceOf(Object),
    accOpeningReducerData: PropTypes.instanceOf(Object),
    liquidationData: PropTypes.instanceOf(Object),
    getFundDetails: PropTypes.func,
    updateAccountNicknameForManage: PropTypes.func,
    resetAllMemberDashbaordData: PropTypes.func,
    getAccountAddress: PropTypes.func,
    clearLiquidationData: PropTypes.func,
    getTradingClosureWindow: PropTypes.func,
    getAccountServiceOptions: PropTypes.func,
    getIRAContributionYearValues: PropTypes.func,
    iRAContributionYearValues: PropTypes.func,
};

ManageMarketTransaction.defaultProps = {
    location: {},
    transactionHolderData: {},
    getTransHolderListForManage: () => {},
    setLoadingPage: () => {},
    isLoading: true,
    getAccountList: () => {},
    masterRegistrationAccountData: {},
    getFundDetails: () => {},
    accOpeningReducerData: {},
    liquidationData: {},
    updateAccountNicknameForManage: () => {},
    resetAllMemberDashbaordData: () => {},
    getAccountAddress: () => {},
    clearLiquidationData: () => {},
    getTradingClosureWindow: () => {},
    getIRAContributionYearValues : ()=>{},
    iRAContributionYearValues: ()=>{},
	getAccountServiceOptions: () => {},
};

export const mapStateToProps = state => {
    return {
        iRAContributionYearValues : state.purchaseData,
        masterRegistrationAccountData: state.masterRegistrationAccountData,
        accOpeningReducerData: state.accOpeningReducerData,
        liquidationData: state.liquidationData,
		masterLookupStateData: state.masterLookUpData
    };
};
export const mapDispatchToProps = {
    getAccountList: masterRegistrationAccountActions.getAccountsList,
    updateAccountNicknameForManage: accOpeningActions.updateAccountNicknameForManage,
    getFundDetails: accOpeningActions.getFundDetailsData,
    resetAllMemberDashbaordData: memberDashboardActions.resetAllMemberDashbaordData,
    getAccountAddress: liquidationActions.getStateCodeGroup,
    clearLiquidationData: liquidationActions.clearLiquidationData,
    getTradingClosureWindow: tradingWindowActions.getTradingClosureWindow,
    getIRAContributionYearValues : purchaseActions.getIRAContributionYearValues,
	getAccountServiceOptions: transactionHistoryActions.getAccountServiceOptions,
    ...accOpeningActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageMarketTransaction);
