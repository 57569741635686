export const DOLLAR_SYMBOL = '$ ';
export const PERCENT_SYMBOL = '%';


export const TAX_WITHHOLD_TITILE = 'Tax Withholding Options';
export const AMOUNT_LABEL = 'Amount';
export const FEDERAL_TAX_LABEL = 'Federal Tax';
export const STATE_TAX_LABEL = 'State Tax';
export const TOTAL_TAXES_TO_WITHHOLD_LABEL = 'Total Taxes to be withheld';
export const TOTAL_RECEIVED_LABEL = 'Total you will receive';
export const TOTAL_WITHDRAWAL = 'Total Withdrawal';
export const WITHHOLDING_OPTIONS_TEXT = 'Select your State Tax withholding options :';
export const DO_NOT_WITHHOLD_TAX_TEXT = 'Do not withhold Taxes';
export const WITHHOLD_TAX_TEXT = 'Withhold Taxes';
export const TAX_WITHHOLD_WARNING = 'Federal Tax + State Tax must be less than 99%';
export const GROUP5_TAX_WARNING_MSG = 'To change the State Tax, please call MSR';
export const STATE_TAX_INPUT_NAME = 'stateTaxPercent';
export const FEDERAL_TAX_INPUT_NAME = 'FederalTaxPercent';
export const DIST_AMOUNT_INPUT_NAME = 'DistributionAmount';
export const RADIO_BUTTON_TYPE = 'radio';
export const STATE_TAX_MIN_VALUE_WARNING_MSG = 'State Tax must be at least ';

export const TRADING_MSG_REVIEW_WARNING =
    'Market Hours for Mutual Funds: Mutual Funds orders remain open until the market closes at 4:00 pm ET. You must place your order prior to 3:59 pm ET to be sure your order gets today’s Net Asset Value';

export const FWH_TEXT = 'FWH';
export const DAT_TEXT = 'DAT';
export const GROUP_1_TEXT = 'group1';
export const GROUP_2A_TEXT = '';
export const GROUP_2B_TEXT = 'group2b';
export const GROUP_3_TEXT = 'group3';
export const GROUP_4_TEXT = 'group4';
export const GROUP_5_TEXT = 'group5';
export const TYPE_TEXT = 'text';
export const SAVINGS_TEXT = 'Savings';

export const STATE_TAX_MIN_VALUE_WARNING_MSG_GROUP2B_MS =
    'For premature distribution for state MS, minimum state tax is ';
export const STATE_TAX_ON_FEDTAX_TEXT = 'State Tax [%] of Federal Tax';

export const TAX_TEXT={
    fedTaxAmount: 'Federal Tax amount',
    fedTaxPercent: 'Federal Tax percent',
    stateTaxAmount: 'State Tax amount',
    stateTaxPercent: 'State Tax percent',
    totalReceived: 'Total received',
    totalTaxAmount: 'Total Tax amount',
    stateTaxWithHeld: 'Total State Tax withheld'
}