const checkValidity = element => {
    const { rules, value, label } = element;
    let isValid = true;
    let errMsg = null;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value && value.trim() !== '' && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            if (rules.requiredMsg) {
                errMsg = rules.requiredMsg;
            } else if (label === 'SSN') {
                errMsg = 'Please enter a valid social security or tax ID number.';
            } else if (label === 'User ID') {
                errMsg =
                    /* 'The User ID does not meet the length and format requirements.' */ 'Please enter a valid User ID.';
            } else if (label === 'Password' || label === 'Current Password' || label === 'Confirm Password') {
                errMsg = /* 'The Password does not follow the password policy.' */ 'Please enter a valid password.';
            } else {
                errMsg = `${label !== undefined ? label : 'Field'} is mandatory. It cannot be left blank.`;
            }
            return errMsg;
        }
    } else if (value === '' || value === null) {
        return null;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            if (rules.minLengthMessage) {
                errMsg = rules.minLengthMessage;
            } else if (label === 'User ID') {
                errMsg = 'The User ID does not meet the length and format requirements.';
            } else {
                errMsg = `${label !== undefined ? label : 'Field'} should be at least ${rules.minLength} characters`;
            }
            return errMsg;
        }
    }

    var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (rules.isValidZip) {
        isValid = isValidZip.test(value) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = `${label !== undefined ? label : 'Field'} should be in XXXXX or XXXXX-XXXX format`;
            return errMsg;
        }
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.maxLengthMsg
                ? rules.maxLengthMsg
                : `${label !== undefined ? label : 'Field'} should be max ${rules.maxLength} characters`;
            return errMsg;
        }
    }

    if (rules.length) {

        if(value.includes('XXXXX')){
            // SSN will come in this format from api (XXXXX6458) - so if it has 5X then its from api else its been modified by user so it will run else case
            // if the user changes the the intial X boxes for SSN then he will stay in the same page, modify code accordingly if you want that feature too. 
            // there are 3 connected files -> PersonalInfocardcomponent, personalinfocompnent and ssn file to check the flow and make changes 
            //    last modified by Rahul 
            isValid = value.slice(5,9).length === rules.length && isValid;
        }else{
        // run ele if user changes initial SNN value i,e XXXXX
            isValid = value.length === rules.length && isValid;
        }
       
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.lengthMsg
                ? rules.lengthMsg
                : `${
                      label !== undefined ? label : 'Field'
                  } is not in correct format. Please check your input and enter again.`;
            return errMsg;
        }
    }

    if (rules.pattern) {
        isValid = rules.pattern.test(value) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.patternMessage;
            return errMsg;
        }
    }

    if (rules.isEmail) {
        const pattern = /^\w+[+.\w-]*@\w+[+.\w-]*\.([a-z]{2,4}|\d+)$/i;
        isValid = pattern.test(value) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'Your email address is not valid. Please enter a valid email address.';
            return errMsg;
        }
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        // console.log('pattern',value.slice(0,5))
        // if all first 5 digits are X then check just last 4 digit for pattern else check for all digits.
         // SSN will come in this format from api (XXXXX6458) - so if it has 5X then its from api else its been modified by user so it will run else case
        if(value.includes('XXXXX')){
            isValid = pattern.test(value.slice(6,9)) && isValid;
        }else{
            isValid = pattern.test(value) && isValid;
        }
        
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = `${label !== undefined ? label : 'Field'} should be numbers only`;
            return errMsg;
        }
    }

    if (rules.equal) {
        isValid = value === element.equalValue && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.equalMessage;
            return errMsg;
        }
    }

    if (rules.maxValue) {
        isValid = value <= rules.maxValue && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.maxValueMsg;
            return errMsg;
        }
    }

    if (rules.minValue) {
        isValid = value >= rules.minValue && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.minValueMsg;
            return errMsg;
        }
    }

    if (rules.dobDate) {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        let existingDate = value;
        const dateString = `${existingDate}`.toLowerCase();
        const maskString = 'xx/xx/';
        const isMasked = dateString.includes(maskString);
        if (isMasked) {
            const year = dateString.replace('xx/xx/', '');
            existingDate = `01/01/${year}`;
        }
        isValid = Date.parse(existingDate) <= Date.parse(date);
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'You must be 18 years or older to open a new account';
            return errMsg;
        }
        return errMsg;
    }

    if (rules.futureDate) {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        isValid = Date.parse(value) >= Date.parse(date);
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = rules.futureDateErrorMsg ? rules.futureDateErrorMsg : 'Please enter present or future data';
            return errMsg;
        }
    }

    if (rules.pastDate) {
        const date = new Date();
        isValid = Date.parse(value) <= Date.parse(date);
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'Please enter a past Date';
            return errMsg;
        }
    }

    if (rules.percentRule) {
        isValid = value <= 100 && value > 0;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'Enter a valid value'; // "Percentage should be atleast 1 and cannot exceed 100";
            return errMsg;
        }
    }

    if (rules.alphaNumeric) {
        const pattern = /^[a-zA-Z0-9 ]*$/;
        isValid = pattern.test(value) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = `${label !== undefined ? label : 'Field'} should be alphanumeric`;
            return errMsg;
        }
    }

    if (rules.phoneNumber) {
        const pattern = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\\.]{0,1}[0-9]{3}[-\s\\.]{0,1}[0-9]{4}$/;
        const digits = value.replace(/\D/g, '');
        isValid = pattern.test(digits) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'Please enter 10 digit Phone Number';
            return errMsg;
        }
    }

    if (rules.isChar) {
        const pattern = /^[a-zA-Z ]*$/;
        isValid = pattern.test(value) && isValid;
        if (isValid) {
            // errMsg = null;
        } else {
            errMsg = 'Please enter valid Characters';
            return errMsg;
        }
    }

    if(rules.noPoBox) {
        const pattern = /\b(?:PO|BOX)\b/gi;
        // /\b(?:PO|BOX)\b/gi => find PO or BOX in the sentence
        // /P?O?BOX /i => find BOX with space after

        isValid = pattern.test(value) && isValid;
        if (isValid) {
            errMsg = 'Post Office Box is not accepted as a physical address.';
            return errMsg;
            // errMsg = null;
        }
    }
    return errMsg;
};

/*
Format of input to this function
Eg:
element = {
    rules: {
            minLength: 5,
            maxLength: 20,
            pattern: '^[a-zA-Z0-9]*$', \\ Regular Expression
            patternMessage: 'Should contain only Alphabets and Numerics', \\Message if pattern is not satisfied
            required: true   \\for mandatory fields
            isNumeric: true  \\If the field needs to contain only numbers
            "minValue":1, "minValueMsg": "Enter a valid value"
            "maxValue":1, "minValueMsg": "Enter a valid value"
            dobDate:true
            futureDate:true
            pastDate:true

        }
    value: " " \\value of the field
}
*/

export default checkValidity;
