import React from 'react';
import styled from 'styled-components';
import { specialtyAccountDisclaimerConstants as DISCLAIMER } from './CONST';

const S = {};

S.Section = styled.div`
    display: flex;
    flex-flow: column nowrap;
    font-family: 'benton-sans';
    color: #49494a;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
`;

S.DisclaimerSection = styled(S.Section)`
    font-weight: 700;
    margin: 0px 0px 40px 0px;
    justify-content: space-between;
    > * {
        margin: 10px 0px;
    }
`;

S.DisclaimerHeading = styled.span``;

S.DisclaimerParagraph = styled.span`
    font-weight: 400;
`;

S.DisclaimerFooter = styled.span``;

const SpecialtyAccountDisclaimer = () => (
    <S.DisclaimerSection>
        <S.DisclaimerHeading>{DISCLAIMER.HEADING}</S.DisclaimerHeading>
        <S.DisclaimerParagraph>{DISCLAIMER.BODY.PARA1}</S.DisclaimerParagraph>
        <S.DisclaimerParagraph>{DISCLAIMER.BODY.PARA2}</S.DisclaimerParagraph>
        <S.DisclaimerFooter>{DISCLAIMER.FOOTER}</S.DisclaimerFooter>
    </S.DisclaimerSection>
);

export default SpecialtyAccountDisclaimer;
