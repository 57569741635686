import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from "react-bootstrap";
import AccountInfoIcon from 'assets/icon-account-info-big.svg';
import Button from '../Button';
import OffScreenText from '../OffScreenText';

const BodyWrap = styled(Row)`
    border: 1px solid #d2d2d2;
    font-size: 0.875em;
    padding-bottom: 1.5em;
    margin: 2em 0;
    &>div{
        margin-top: 1.5em;
        &:first-child {
            display: flex;
        }
    }
    background: #FAFAFA 0% 0% no-repeat padding-box;
`;

const AccountImgData = styled.div`
    background: url(${AccountInfoIcon}) no-repeat;
    padding-left: 4rem;
    min-height: 3rem;
    flex:auto;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ShowLink = styled(Button)`
    font-family: benton-sans-medium;
    color:#004a98;
`;

const RelDetails = styled(Col)`
    @media (min-width:768px){  
        border-right: 1px solid #d2d2d2;
        padding: 0 2em;
        &:last-child {
            border:0;
        }
    }
`;

const StyledHeading = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #56565A;
    opacity: 1;
`;


const StyledValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #49494A;
    opacity: 1;
`;

const SHOW = "Show";
const HIDE = "Hide";
const SHOW_LABEL = "Show Account Number";
const HIDE_LABEL = "Hide Account Number";
const ACCOUNT_NUMBER = "Account Number";
const ACCOUNT_TYPE = "Account Type";
const REGISTRATION_OWNER = "Registration Owner";

const AccountDetailsCard = props => {
    const { accountName, accountNumber, toggleAccNumberView, showAccNumber, accountType, primaryOwner } = props;

    let MASK_TOGGLE_TEXT = SHOW;
    let MASK_TOGGLE_ARIA_LABEL = SHOW_LABEL;

    if(showAccNumber){
        MASK_TOGGLE_TEXT = HIDE;
        MASK_TOGGLE_ARIA_LABEL = HIDE_LABEL;
    }
    return (
        <BodyWrap>
            <RelDetails md={5} lg={4}>
                <AccountImgData>
                    <StyledValue>{accountName}</StyledValue>
                    <FlexDiv>
                        <StyledValue>
                            <OffScreenText hideTxt={ACCOUNT_NUMBER} />
                            {accountNumber}
                        </StyledValue>
                        <ShowLink aria-label={MASK_TOGGLE_ARIA_LABEL} onClick={toggleAccNumberView}>
                            {MASK_TOGGLE_TEXT}
                        </ShowLink>
                    </FlexDiv>
                </AccountImgData>
            </RelDetails>
            <RelDetails md={3} lg={4}>
                <StyledHeading>{ACCOUNT_TYPE}</StyledHeading>
                <StyledValue>{accountType}</StyledValue>
            </RelDetails>
            <RelDetails md={4}>
                <StyledHeading>{REGISTRATION_OWNER}</StyledHeading>
                <StyledValue>{primaryOwner}</StyledValue>
            </RelDetails>
        </BodyWrap>
    );
};

AccountDetailsCard.propTypes = {
    accountName: PropTypes.string,
    accountNumber: PropTypes.oneOfType([ PropTypes.string, PropTypes.number,]),
    toggleAccNumberView: PropTypes.func,
    showAccNumber: PropTypes.bool,
    accountType: PropTypes.string,
    primaryOwner: PropTypes.string,
};

AccountDetailsCard.defaultProps = {
    accountName: '',
    accountNumber: '',
    toggleAccNumberView: () => {},
    showAccNumber: false,
    accountType: '',
    primaryOwner: '',
};

export default AccountDetailsCard;
