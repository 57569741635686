/**
 * Bank Accounts
 ********************************
 *
 * @version 1.0.0
 * @author Ramyakrishna K
 * @description Bank Accounts are displayed and managed here
 * @createdDate [27/12/2019]
 * ***************************************
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CommonButtons, WBreadCrumb, WMessageBar, ConformationModal, StyledButton, WSpinner } from 'common';
import CannotDeleteModal from 'modules/AccountOptions/Common/CannotDeleteModal';
import { bankAccountAction, analytics } from 'shared/Actions';
import routeConstants from 'routeConstants';
import constants from 'modules/AccountOptions/constant';
import {BANKS_INITIAL_PAGE, BANK_ACCOUNTS,BREAD_CRUMB_SCREEN_BANK_ACCOUNTS } from '../../../../utils/AdobeAnalytics/Constants';
import BankAccountsComponent from '../BankAccountComponent';
import { getSessionValues } from 'shared/Helpers/Utils';
import routesBreadCrumb from 'utils/Constants/routesBreadcrumb';
import { createBreadCrumbItem } from 'utils';

const breadCrumbURL = [{ url: '/accountholder', name: 'Home' }];
const Heading = styled.h1`
    display: inline-block;
    border-bottom: 0.125rem solid #8CBF26;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.875rem;
    color: #486D90;
    opacity: 1;
    padding-bottom: 0.625rem;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;
`;


const nextStyle = {
    display: 'none',
};
const backStyle = {
    display: 'none',
};

export class BankAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountDetails: [],
            openIndex: null,
            isMenuOpen: false,
            openConformationDialog: false,
            deleteModalBody: '',
            openConformationDialog2: false,
            okModalBody: '',
            displayNotification: false,
            notificationMsg: '',
            displayNotification2: false,
            notificationMsg2: '',
            showSkip: false,
            loading: false,
        };
        this.clickBack = this.clickBack.bind(this);
        this.onMenuHandler = this.onMenuHandler.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.onDeleteMenuClick = this.onDeleteMenuClick.bind(this);
        this.onConformationCancel = this.onConformationCancel.bind(this);
        this.onConformationDelete = this.onConformationDelete.bind(this);
        this.onConformationOk = this.onConformationOk.bind(this);
        this.breadCrumbItems = this.getBreadCrumbItems();
    }
    /* ---------------------- Component LifeCycle Methods -------------------------- */

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.bankAccountReducerData.bankDetails.length > 0) {
            const accountDetails = [];

            for(let bankId in nextProps.bankAccountReducerData.bankDetails) {
                let bankAccount = nextProps.bankAccountReducerData.bankDetails[bankId];
                    accountDetails.push( {
                            key: bankId,
                            accName: bankAccount.bankName,
                            accNumber: {
                                text: constants.bankAccountList.bankAccountNumberText,
                                value: bankAccount.bankAccountNumber,
                            },
                            transitRoutingNumber: {
                                text: constants.bankAccountList.bankRoutingNumberText,
                                value: bankAccount.bankRoutingNumber,
                            },
                            systematicWithdrawalPlan: {
                                text: constants.bankAccountList.bankAccountSWPText,
                                value: bankAccount.swp && ( bankAccount.swp.toLowerCase() === 'yes' || bankAccount.swp.toLowerCase() === 'true' ) ?
                                    constants.bankAccountList.yes
                                    :
                                    constants.bankAccountList.no,
                            },
                            automaticInvestmentPlan: {
                                text: constants.bankAccountList.bankAccountAIPText,
                                value: bankAccount.aip && ( bankAccount.aip.toLowerCase() === 'yes' || bankAccount.aip.toLowerCase() === 'true' ) ?
                                    constants.bankAccountList.yes
                                    :
                                    constants.bankAccountList.no,
                            },
                            bankAccountType: {
                                text: constants.bankAccountList.bankAccountTypeText,
                                value: bankAccount.bankAccountType,
                            },
                    } );
            }
            return {
                accountDetails,
                loading: false
            }
        }

        return {
            loading: nextProps.bankAccountReducerData.isLoading
        };
    }

    componentDidMount() {
        const { location, getBankAccounts } = this.props;
        //const msrInfo = sessionStorage.getItem("msr_dts");
        const params = getSessionValues();
        const {accesstoken, vcmid} = params;
        getBankAccounts({ token: accesstoken, vcmid });
        this.setState({
            loading: true,
        });

        window.addEventListener('click', this.handleOutsideClick, false);
        document.addEventListener('keydown', this.handleEscapeKey, false);
        this.setState({
            notificationMsg2: { message: constants.bankAccount.accountsRetrievedSuccessMsg },
        });
        if (location && location.state && location.state.message) {
            this.setState({
                displayNotification2: true,
            });
        }
        sessionStorage.setItem(BANKS_INITIAL_PAGE.STEP_KEY, BANKS_INITIAL_PAGE.STEP_NAME);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, false);
        document.removeEventListener('keydown', this.handleEscapeKey, false);
    }

    onMenuHandler = (index) => e => {
        e.stopPropagation();
        this.setState(prevState => {
            const { accountDetails, openIndex, isMenuOpen } = prevState;
            const isSameIndex = openIndex === index;
            if (isMenuOpen && !isSameIndex) {
                accountDetails[+openIndex].show = false;
            }
            accountDetails[+index].show = !accountDetails[+index].show;

            return {
                accountDetails,
                openIndex: isMenuOpen && isSameIndex ? null : index,
                isMenuOpen: !(isMenuOpen && isSameIndex)
            };
        })
    }

    onDeleteMenuClick(e) {
        e.stopPropagation();
        this.setState(prevState => {
            const { accountDetails, openIndex } = prevState;
            const acc = accountDetails[+openIndex];
            acc.show = false;
            if (accountDetails[+openIndex].automaticInvestmentPlan.value === 'No' && accountDetails[+openIndex].systematicWithdrawalPlan.value === 'No') {
                return {
                    isMenuOpen: false,
                    openConformationDialog: true,
                    openConformationDialog2: false,
                    deleteModalBody: `${constants.bankAccount.deleteModalBody1} ${acc.accName} ${constants.bankAccount.deleteModalBody3} ${acc.accNumber.value}`,
                };
            }
            return {
                isMenuOpen: false,
                openConformationDialog: false,
                openConformationDialog2: true,
                okModalBody: `${constants.bankAccount.cannotDeleteModalBody} ${acc.accName} ${constants.bankAccount.deleteModalBody3} ${acc.accNumber.value} ${constants.bankAccount.cannotDeleteModalBody2}`,
            };
        });
    }

    onConformationOk(e) {
        e.stopPropagation();
        this.setState(() => {
            return {
                isMenuOpen: false,
                openConformationDialog: false,
                openConformationDialog2: false,
                displayNotification: false,
            };
        }, this.scrollToTop);
    }

    onConformationDelete() {
        this.setState(prevState => {
            const { accountDetails, openIndex } = prevState;
            const acc = accountDetails[+openIndex];
            if (acc) {
                accountDetails.splice(+openIndex, 1);
            }
            const { history } = this.props;
            if (history) {
                history.push({
                    pathname: '/bankaccounts',
                });
            }
            return {
                accountDetails,
                openIndex: {},
                openConformationDialog: false,
                openConformationDialog2: false,
                displayNotification: true,
                displayNotification2: false,
                notificationMsg: { message: `Your ${acc.accName} has been deleted.` },
            };
        }, this.scrollToTop);
    }

    onNotificationClose = () => {
        this.setState({
            displayNotification: false,
            displayNotification2: false,
        });
    };

    onConformationCancel() {
        this.setState(() => {
            return {
                openIndex: {},
                openConformationDialog: false,
                openConformationDialog2: false,
                displayNotification: false,
                displayNotification2: false,
            };
        });
    }

    getBreadCrumbItems = () => {
        const { location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyState = {
            state: {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            },
        };
        const intermediateRoute = {
            name: accountName,
            url: routeConstants.accountholderTransactions,
            payload: historyState,
        };
        const bradcrumbPaths = [routeConstants.accountholder];
        const breadCrumbItems = bradcrumbPaths.map(route => createBreadCrumbItem(route, BREAD_CRUMB_SCREEN_BANK_ACCOUNTS));
        if (masterRegId) {
            breadCrumbItems.splice(1, 1, intermediateRoute);
        }
        return breadCrumbItems;
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    assignObj = (obj) => { return obj; }

    add = (e) => {
        const { history, analyzeClickEvent } = this.props;
        history.push(routeConstants.addaccounts);
        analyzeClickEvent({
            link: e.target.getAttribute('data-analytic-id') || e.currentTarget.getAttribute('data-analytic-id'),
        })
    };

    handleEscapeKey = e => {
        const { openConformationDialog, openConformationDialog2, isMenuOpen } = this.state;
        if (e.keyCode === 27) {
            if (openConformationDialog) {
                this.setState({ openConformationDialog: false });
            }
            if (openConformationDialog2) {
                this.setState({ openConformationDialog2: false });
            }
            if (isMenuOpen) {
                this.closeOpenMenu();
            }
        }
    };

    closeOpenMenu = () => {
        this.setState(prevState => {
            const { accountDetails, openIndex } = prevState;
            accountDetails[+openIndex].show = false;
            return {
                accountDetails,
                isMenuOpen: false,
                openIndex: {},
            }
        });
    };


    handleOutsideClick() {
        const { isMenuOpen } = this.state;
        if (isMenuOpen) {
           this.closeOpenMenu();
        }
    }

    clickBack() {
        const { history, location } = this.props;
        const { state } = location || {};
        const { masterRegId, accountName, accountType, accountOwnerDesc } = state || {};
        const historyObj = {};
        const backRoute = masterRegId ? routeConstants.accountholderTransactions : routeConstants.accountholder;
        historyObj.pathname = backRoute;
        if (masterRegId) {
            historyObj.state = {
                name: accountName,
                masterId: masterRegId,
                accountType,
                accountOwnerDesc,
            };
        }
        history.push(historyObj);
    }

    render() {
        const { accountDetails, openConformationDialog2, openConformationDialog, displayNotification, displayNotification2, notificationMsg,
            notificationMsg2, deleteModalBody, okModalBody, showSkip, loading } = this.state;
        const { bankAccountReducerData: { isError } } = this.props;
        const navProps = {};
        if (showSkip) {
            navProps.backstyle = backStyle;
        } else {
            navProps.backClick = this.clickBack;
            navProps.nextstyle = nextStyle;
            navProps.arialabelback = 'Back click';
            navProps.dataAnalyticId = BANK_ACCOUNTS
        }
        return (
            <>
            {loading ? <WSpinner loading={loading} /> : ''}
            <div>
                {displayNotification &&
                    <WMessageBar error onClose={this.onNotificationClose} text={notificationMsg} />}
                {displayNotification2 && (
                    <WMessageBar onClose={this.onNotificationClose} text={notificationMsg2} />
                )}
                {isError && <WMessageBar onClose={this.onNotificationClose} error={isError} />}
                {openConformationDialog2 && (
                    <CannotDeleteModal
                        modalTitle={constants.bankAccount.deleteModalTitle}
                        modalBodyText={okModalBody}
                        secondaryButtonText={constants.bankAccount.cannotDeleteModalText}
                        onSecondaryClick={this.onConformationOk}
                    />
                )}
                {openConformationDialog && (
                    <ConformationModal
                        modalTitle={constants.bankAccount.deleteModalTitle}
                        modalBodyText={deleteModalBody}
                        modalBodyText3={constants.bankAccount.deleteModalBody3}
                        modalBodyText4={constants.bankAccount.deleteModalBody4}
                        primaryButtonText={constants.bankAccount.deleteModalDeleteText}
                        secondaryButtonText={constants.bankAccount.deleteModalCancelText}
                        onPrimaryClick={this.onConformationDelete}
                        onSecondaryClick={this.onConformationCancel}
                    />
                )}
                <div className="container">
                    <WBreadCrumb
                            activeCrumb={routesBreadCrumb.get(routeConstants.bankaccounts)}
                            breadCrumbItems={this.breadCrumbItems}
                    />
                    <FlexDiv>
                        <Heading>{constants.bankAccount.pageHeading}</Heading>
                    </FlexDiv>
                    {accountDetails.length > 0 ? accountDetails.map((eachAcc, index) => (
                        <BankAccountsComponent
                            key={eachAcc.key}
                            id={eachAcc.id}
                            accName={eachAcc.accName}
                            accNumber={eachAcc.accNumber}
                            transitRoutingNumber={eachAcc.transitRoutingNumber}
                            systematicWithdrawalPlan={eachAcc.systematicWithdrawalPlan}
                            automaticInvestmentPlan={eachAcc.automaticInvestmentPlan}
                            bankAccountType={eachAcc.bankAccountType}
                            onMenuHandler={this.onMenuHandler(index)}
                            show={!!eachAcc.show}
                            onDeleteMenuClick={this.onDeleteMenuClick}
                        />
                    )) : !loading && !isError ? constants.bankAccountList.noBankAccounts : ''}
                </div>
                <CommonButtons {...navProps} />
            </div>
        </>
        )
    }
}

BankAccounts.propTypes = {
    getBankAccounts: PropTypes.func,
    bankAccountReducerData: PropTypes.instanceOf(Object),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            message: PropTypes.string
        })
    }).isRequired,
    analyzeClickEvent: PropTypes.func,
};

BankAccounts.defaultProps = {
    getBankAccounts: () => {},
    bankAccountReducerData: {},
    analyzeClickEvent: () => {},
}

export const mapStateToProps = state => {
    return {
        bankAccountReducerData: state.bankAccountReducerData,
    };
};

export const mapDispatchToProps = {
    getBankAccounts: bankAccountAction.getBankAccounts,
    analyzeClickEvent: analytics.analyzeClickEvent
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankAccounts));