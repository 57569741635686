/**
 * Upload Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description Upload for paper flow
 * @createdDate [27/03/2020]
 * ***************************************
 * @lastModifiedDate [27/03/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
// import { Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { WIcon } from '../../../common';
import PDFIcon from '../../../assets/pdfdownloadsmall.png';
import consts from './consts';
import constants from '../ManageBeneficiaries/consts';
import '../AgreementPaperFlow/agreementPaperFlow.css';
import './uploadComponent.css';

const assignObj = obj => {
    return obj;
};

class UploadComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentDidMount() {

    // }

    handlePdf = () => {
        window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
    };

    handleBack = () => {
        //  this.setState({back: true})
    };

    handleSubmit = () => {
        //  this.setState({submit: true})
    };

    Input = ({ accept, onFiles }) => {
        const { id, name } = this.props;
        // const uniques = Object.values(
        //     files.reduce((a, c) => {
        //         a[c.meta] = c;
        //         return a;
        //     }, {}),
        // );
        return (
            <label htmlFor={id} className="dzu-inputLabel">
                <span className="strongSpan">{consts.dropFiletext}</span>
                <span className="orText">{consts.orText}</span>
                {consts.selecttext}
                <input
                    type="file"
                    id={id}
                    name={name}
                    accept={accept}
                    onChange={assignObj(e => this.handleChange(e, onFiles))}
                />
            </label>
        );
    };

    handleChange = (e, onFiles) => {
        this.getFilesFromEvent(e).then(chosenFiles => {
            onFiles(chosenFiles);
        });
    };

    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject));
            });
        });
    };

    render() {
        // const {
        // } = this.state;
        const { flow } = this.props;
        const {
            uplodDocHeading,
            uplodDocPara1,
            //  dropFiletext,
            orText,
            //  selecttext,
            limitsArr,
            mailingText,
            address,
            faxOption,
            faxNumber,
        } = consts;
        const headerRowClassName = flow !== 'paperFlow' ? '' : 'headerRow';
        return (
            <div className="uploadComponent">
                <Row noGutters className={headerRowClassName}>
                    <Col>
                        {flow !== 'paperFlow' ? (
                            <h1 className="pageHeading">{uplodDocHeading}</h1>
                        ) : (
                            <h2 className="pageSubHeading">{uplodDocHeading}</h2>
                        )}
                    </Col>
                </Row>
                <Row noGutters className="dividendHint beneficiaryBoxModel">
                    <Col sm={10}>
                        <h2> {constants.boxModelHeading1} </h2>
                        <p>
                            {' '}
                            {constants.boxModelPara1}{' '}
                            <a href="/" className="inlineLink">
                                {' '}
                                {constants.boxModelParaItem1}{' '}
                            </a>{' '}
                            {constants.boxModelParaItem2}{' '}
                            <a href="/" className="inlineLink">
                                {' '}
                                {constants.boxModelParaItem3}{' '}
                            </a>{' '}
                        </p>
                    </Col>
                    <Col sm={2}>
                        <a
                            type="a"
                            className="download-link"
                            href={constants.samplPDFLink}
                            //  target="_blank"
                            aria-label={constants.downloadText}
                        >
                            <WIcon src={PDFIcon} className="pdfIcon" size="1x" />
                            {constants.downloadText}
                        </a>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <p className="uploadDocPara">{uplodDocPara1}</p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Dropzone InputComponent={this.Input} />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        {limitsArr.map(item => {
                            return (
                                <div className="limitsItemWrapper" key={item.key}>
                                    <span className="labelSpan orText">{item.key}</span>
                                    <span className="strongSpan">{item.value}</span>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <span className="orText orOption">{orText}</span>
                        <span className="orOption strongSpan">{mailingText}</span>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col className="mailingAddress">
                        <span className="addressLine line1">{address.line1}</span>
                        <span className="addressLine">{address.line2}</span>
                        <span className="addressLine">{address.line3}</span>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <span className="orText orOption">{orText}</span>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <span className="orText">
                            {faxOption}
                            <a href={assignObj(`tel:${faxNumber}`)} className="telephoneLink">
                                {faxNumber}
                            </a>
                        </span>
                    </Col>
                </Row>
            </div>
        );
    }
}

UploadComponent.propTypes = {
    flow: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
};

UploadComponent.defaultProps = {
    flow: '',
    id: '',
    name: '',
};

export default UploadComponent;
