import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { localStateManagementActions } from '../../../../shared/Actions';
import Preferences from './Preferences';



export const mapStateToProps = (state /* , props */) => (
  {
    preferenceState: state.localStateManagementReducerData.preferenceState
  }
);

const mapDispatchToProps = {
  ...localStateManagementActions
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Preferences));