/* eslint-disable react/no-unused-state */
import { Accordian, AccordianTitle, AccountAddress, MaskText } from 'common';
import CustomOption from '../../Common/CustomOption';
import { Flex, RadioGroup, Label, SelectDropDown, FieldValue } from 'modules/CustomerManagementModule/components';
import { isMobile } from 'react-device-detect';
import { PAY_OPTIONS } from 'modules/AccountOptions/constant';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { TransferToAnotherFund } from 'modules/AccountOptions/Dividends/ManageCard';
import './fundToStyle.css';

const StyledDiv = styled.div`
    margin-bottom: ${props => props.mB || 0}rem;
    margin-top: ${props => props.mT || 0}rem;
`;

const DELIVERY_ADDRESS = 'Delivery Address';
const { BANK, CHECK_ORDER: CHECK, DIRECT_SWP: INVEST_ANOTHER_FUND } = PAY_OPTIONS;
const SELECT_BANK = 'Select Bank';
const PAYMENT_OPTIONS = {
    '01': CHECK,
    '05': BANK,
    '09': INVEST_ANOTHER_FUND,
};

const INVEST_FUND = 'Invest to another fund';
const CustomOpt = true;
const BankTxt = 'BankAccounts';
const displaySelValBold =  true;

const StyledSpan = styled.span`
text-align: left;
font-family: benton-sans, sans-serif;
font-weight: 600;
font-style: normal;
line-height: 28px;
font-size: 16px;
letter-spacing: 0px;
color: #004a98;
`;

const accountNoMobileFormat = (value = '') => {
    const valToString = (value || '').toString();
    return `XXX${valToString.substr(valToString.length - 4)}`;
};

const DropdownSelectedVal = props => {
    const { spanValue } = props;
        return <StyledSpan>
            {spanValue && spanValue!== 'Select' ?
                <MaskText text={spanValue.toString()} startIndex={0} maskLength={6} />
                : spanValue
            }
            </StyledSpan>
}
DropdownSelectedVal.propTypes = {
    spanValue: PropTypes.string
}

DropdownSelectedVal.defaultProps = {
    spanValue: ''
}

class FundTo extends Component {
    constructor() {
        super();
        this.state = {
            fundOptions: [
                {
                    key: BANK,
                    value: 'Transfer to Bank Account',
                },
                {
                    key: CHECK,
                    value: 'Send a Check',
                },
                {
                    key: INVEST_ANOTHER_FUND,
                    value: INVEST_FUND,
                },
            ],
            selectedFundOptions: '',
            bankList: [],
            selFundNames:'',
            nonSelFundNames: [],
            retrievedState: true,
            selBankValue:'',
            investToSelVal:'',
            investToSel:'',
            disableNext: true,
            isEditMode: false,
            offlineMode: null,
            selectedShares:[],
            bankDetailsSection:{},
            investToFundName:'',
            investToFundAccNo:'',
            investToFundNo:''

        };
        this.dropdownref = React.createRef()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { bankList, isEditMode } = nextProps;
        const { bankList: bankListState} = prevState;
        // console.log('@log for savedState '+JSON.stringify(savedState))
        if (bankList.length && !bankListState.length) {
            return {
                bankList: bankList.map(item => {
                    return isMobile? { ...item, key: item.salesforceRecordId, value: `${accountNoMobileFormat(item.bankAccountNumber)}-${item.bankName||''}` }:
                    { ...item, key: item.salesforceRecordId, value: item.bankAccountNumber }
                }),
            };
        }  
        if(isEditMode) {
            return {
                isEditMode
            }
        }
        return null;
    }

    componentDidMount() {
        // this.updatePaymentOption();
    }

    componentDidUpdate(prevProps) {
        // const { savedState: oldFundDetails } = prevProps;
         const { savedState, selectedFundsCount } = this.props;
         const { retrievedState } = this.state;
        // const { offlineMode: pldPaymentMethod } = oldFundDetails;
        // const { offlineMode } = savedState;
        // if (pldPaymentMethod !== offlineMode) {
        //     this.updatePaymentOption();
        // }
        // console.log('@log for savedState ',savedState)
        if(prevProps !== this.props && savedState && savedState!==null && retrievedState) {
            const { offlineMode = '' } = savedState
            let paymentType;
            if(offlineMode === PAY_OPTIONS.CHECK_ORDER){
                paymentType = '01'
                this.updateCheckData(paymentType)
            } else if(offlineMode === PAY_OPTIONS.DIRECT_SWP) {
                paymentType = '09'
                this.updateInvestFundData(savedState, paymentType)
            } else {
                paymentType = '05'
                this.updateBankData(savedState, paymentType)
            }
            // this.updateVal(paymentType)
        }
        const { selectedFundsCount: prevSelectedFundsCount } = prevProps;
        if (selectedFundsCount !== prevSelectedFundsCount) {
            this.updateValues();
        }
        const { disableNext } = this.state;
        const newDisableNext = this.getNextStatus();
        if (newDisableNext !== disableNext) {
            this.updateNextStatus(newDisableNext);
        }
    }

    getNextStatus = () => {
        const { selectedFundOptions, selBankValue, investToSelVal, showBankAccErrorTxt } = this.state;
        const { selectedFundsCount } = this.props;
        let disableNext = true;
        if (
            selectedFundOptions === PAY_OPTIONS.CHECK_ORDER ||
            (selectedFundOptions === PAY_OPTIONS.BANK && selBankValue && !showBankAccErrorTxt) ||
            (selectedFundOptions === PAY_OPTIONS.DIRECT_SWP && investToSelVal)
        ) {
            disableNext = false;
        } 
        if(selectedFundsCount === 0) {
            disableNext = true;
        }
        return disableNext;
    }

    updateNextStatus = (disableNext) => {
        const { checkStatus } = this.props;
        this.setState(
            {
                disableNext,
            },
            checkStatus,
        );
    }

    updateCheckData = (paymentType) => {
        this.setState(() => {
            return {
                selectedFundOptions: PAYMENT_OPTIONS[`${paymentType}`],
                offlineMode: PAY_OPTIONS.CHECK_ORDER,
                retrievedState: false,
            }
        })
    }

    updateBankData = (savedState, paymentType) => {
        const { bankDetailsSectionState } = savedState
        const { selectedBank } = bankDetailsSectionState
        const { bankList: bankDetails } = this.state
        if(bankDetailsSectionState && selectedBank) {
            const { salesforceRecordId } = selectedBank
            
            const selBank = bankDetails.filter(el => (el.salesforceRecordId === salesforceRecordId))
            const bankObj =  {selectedBank:selBank[0]}
            // if(selBank){
            //      updateEditBankData(selBank.bankName, selBank.bankAccountNumber)
            // }
            this.setState(() => {
                return {
                    selectedFundOptions: PAYMENT_OPTIONS[`${paymentType}`],
                    retrievedState: bankDetails.length > 0 ? false: true,
                    selBankValue: salesforceRecordId,
                    bankDetailsSection: bankObj,
                    offlineMode: PAY_OPTIONS.BANK
                }
            })
        }
    }

    updateInvestFundData = async(savedState, paymentType) => {
      // const { fromFundDetails } = this.props
      const { investFundDetails = {} }   = savedState
      const { investToFundData = {} } = investFundDetails
      const { state = {} } = investToFundData
      const { investToFundName = '', investToFundAccNo = '', investToFundNo = ''} = state
      const { isEditMode } = this.state
      let data;
      if(!isEditMode) {
        data = await this.populateFromFundToFundValues()
      } else {
        data = await this.populateFromFundToFundValuesForEdit()
      }
      const { selFundNames = '', nonSelFundNames} = data
      nonSelFundNames.map(el => {
        const isValid = isMobile ? (el.value.substring(8) === investToFundName): (el.value.substring(6) === investToFundName)
        if(isValid) {
            this.setState(() => {
                return {
                    selFundNames,
                    nonSelFundNames,
                    selectedFundOptions: PAYMENT_OPTIONS[`${paymentType}`],
                    retrievedState: false,
                    investToSel:el.key,
                    offlineMode: PAY_OPTIONS.DIRECT_SWP,
                    investToSelVal: el.key,
                    investToFundName,
                    investToFundAccNo,
                    investToFundNo
                    // disableNext: false
                };
            });
        }
        return null
      })
      
    }

    updateValues = async() => {
        const val = await this.populateFromFundToFundValues()
        this.updateState(val)
    }

    updateState = (val) => {
        const { selFundNames = '', nonSelFundNames} = val
        // const { selectedFundOptions } = this.state
        if(nonSelFundNames.length < 1) {
            this.setState(() => {
                return {
                   selFundNames,
                   nonSelFundNames,
                   selectedFundOptions: 'checkOrder'
                }
            })
        } else {
            this.setState(() => {
                return {
                   selFundNames,
                   nonSelFundNames
                }
            })
        } 
        
    }
    
    getFundData = (savedState) => {
        const { bankDetailsSectionState } = savedState
        const { selectedBank } = bankDetailsSectionState
        const { bankAccountNumber } = selectedBank
        return bankAccountNumber
    }

    updatePaymentOption = () => {
        const { savedState = '' } = this.props;
        if(savedState !== null) {
            const { offlineMode = '' } = savedState
            let paymentType;
            if(offlineMode === PAY_OPTIONS.CHECK_ORDER){
                paymentType = '01'
            } else if(offlineMode === PAY_OPTIONS.DIRECT_SWP) {
                paymentType = '09'
            } else {
                paymentType = '05'
            }
            this.setState(() => {
                return {
                    selectedFundOptions: this.getPaymentOption(paymentType),
                };
            });
        }
    };

    getPaymentOption = paymentMethod => {
        return PAYMENT_OPTIONS[`${paymentMethod}`];
    };

    populateFromFundToFundValuesForEdit = async() => {
        const { assocFunds, shares } = this.props
        // console.log('@log for assocFunds & selectedFunds',assocFunds, selectedFunds,shares)
        if(assocFunds && assocFunds.length > 0) {
            const data = shares[0]
            const { shortFundName, fundAccountNumber, fundName } = data;
            let selFundNames =  '';
            let nonSelFundNames = ''
            selFundNames = `${shortFundName} ${fundName}`
            nonSelFundNames =  await assocFunds.filter(fund => fund.fundAccountNumber !== fundAccountNumber)
            .map((fund) => {
                return isMobile ? { ...fund, key: fund.fundAccountNumber, value: `${accountNoMobileFormat(fund.fundAccountNumber)}-${fund.fundName}` }:
                { ...fund, key: fund.fundAccountNumber, value: `${fund.shortFundName} ${fund.fundName}` };
            })
            return { selFundNames, nonSelFundNames }
        }
       return null
    }
    
    populateFromFundToFundValues = async() => {
        let selFundNames =  '';
        let nonSelFundNames = ''
        const {shares, selectedFunds} = this.props
        if(selectedFunds && selectedFunds.length > 0) {
            const selFund =  selectedFunds.filter(fund => fund['selected'] === true)
            if(selFund.length > 0) {
                    selFund.forEach(item => {
                    selFundNames = `${selFundNames}${selFundNames.length > 0 ? ',': ''}${item.shortFundName} ${item.fundName}`
                })
            }
            nonSelFundNames = await selectedFunds.filter(fund => (fund.selected===false) || !(fund.selected))
            .map((fund) => {
                return isMobile ? { ...fund, key: fund.fundAccountNumber, value: `${accountNoMobileFormat(fund.fundAccountNumber)}-${fund.fundName}`}:
                    { ...fund, key: fund.fundAccountNumber, value: `${fund.shortFundName} ${fund.fundName}` };
            })
        } else {
            nonSelFundNames = shares.map((fund) => {
                    return isMobile ? { ...fund, key: fund.fundAccNo, value: `${accountNoMobileFormat(fund.fundAccountNumber)}-${fund.fundName}` }:
                    { ...fund, key: fund.fundAccountNumber, value: `${fund.shortFundName} ${fund.fundName}`};
            })
        }
        return {selFundNames, nonSelFundNames}
    }

    onFundOptionChange = async(e) => {
        const { isEditMode } = this.props
        const { target, options } = e;
        const { value } = target;
        const { key: selectedKey } = options.find(item => item.value === value);    
        let data = ''
        if(value === INVEST_FUND && !isEditMode) {
            data = await this.populateFromFundToFundValues()
        } else if(value === INVEST_FUND && isEditMode) {
            data = await this.populateFromFundToFundValuesForEdit()
        }
        // fundToOptionChange(selectedKey)
        const { selFundNames = '', nonSelFundNames} = data
        this.setState(() => {
            return {
                selectedFundOptions: selectedKey,
                offlineMode:selectedKey,
                selFundNames,
                nonSelFundNames
            };
        });
    };

    handleBankAccNoChange = (e) => {
        const { bankDisplayRules } = this.props
        const { target, list=[] } = e
        const { value } = target
        list.filter(acc => acc.key === value).map(el => {   
            const data = this.getBankDisplayRules(bankDisplayRules,el.CreatedDate)
            const bankObj =  {selectedBank:el}
            if(data === 'disabled') {
                this.setState({
                    showBankAccErrorTxt: true
                })
            } else{
                this.setState({
                    bankDetailsSection: bankObj,
                    selBankValue: el.salesforceRecordId,
                    showBankAccErrorTxt: false
                })
            }
            return null
        })
        
        
       // handleBankAccChange(e,rule)
    }

    handleInvestFundChange = (e) => {
        const { selectedFunds, isEditMode, assocFunds = [] } = this.props
        const { target } = e;
        const { value } = target;
        const fundDetails = isEditMode ? this.getFundNo(value, assocFunds, isEditMode) : this.getFundNo(value, selectedFunds)
        const { fundNo, fundAccNo, fundName } = fundDetails
        this.setState({
            investToFundName: fundName,
            investToFundNo: fundNo,
            investToFundAccNo: fundAccNo,
            investToSelVal: value
        })
    }
    // getEditFundNo = (fund, allFunds) => {
    //     let fundNo = ''
    //     let fundAccNo = ''
    //     allFunds.filter(el => el.fundName === fund).map(obj => {
    //         fundNo = obj.fundNumber
    //         fundAccNo = obj.fundAccountNumber 
             
    //     })
    //     return {fundNo, fundAccNo}
    // }
    getFundNo = (fund, allFunds) => {
        let fundNo = ''
        let fundAccNo = ''
        let fundName = ''
        allFunds.filter(el => el.fundAccountNumber === +fund).map(obj => {
            fundNo = obj.fundNumber
            fundAccNo = obj.fundAccountNumber 
            fundName = obj.fundName
          return null
        })
        return {fundNo, fundAccNo, fundName}
    }

    getBankDisplayRules = (bankDisplayRules, createdDate) => {
        const currentDate = new Date();
        const tempAccCreatedDate = createdDate.substring(0, createdDate.indexOf('+'));
        const accCreatedDate = new Date(tempAccCreatedDate);
        const diffDays = Math.ceil((currentDate - accCreatedDate) / (1000 * 60 * 60 * 24));
        let displayRule = '';
        if (diffDays < bankDisplayRules.lower_limit) {
            displayRule = 'disabled';
        }
        return displayRule;
    };

    checkforNonSelFundVal = () => {
        const { assocFunds, shares } = this.props
        const data = shares[0];
        const { fundAccountNumber } = data;
        const nonSelFundNames =  assocFunds.filter(fund => fund.fundAccountNumber !== fundAccountNumber)
        return (nonSelFundNames.length < 2) ? true: false
    }

    render() {
        const { title, description, forwardRef, checkAddress, shares, investToFundName, isEditMode } = this.props;
        const { fundOptions, selectedFundOptions, bankList, showBankAccErrorTxt, selFundNames,nonSelFundNames, selBankValue, investToSel } = this.state;
        const accordionTitleId = `${title.replace(/\s/g, '')}_title`;
        // const { shortFundName = '', fundName = '' } = fromFundDetails;
        // const fundNameWithTicker = `${shortFundName} ${fundName}`;
        const { value: selectedFundOptionsValue = '' } =
            fundOptions.find(item => item.key === selectedFundOptions) || {};
        const fundOptionsRef = fundOptions.map(item => {
            let disabled = false;
            if (item.key === INVEST_ANOTHER_FUND && shares.length < 2 && !isEditMode) disabled = true;
            if (item.key === INVEST_ANOTHER_FUND && isEditMode)  {
                disabled = this.checkforNonSelFundVal();
            } 
            return { ...item, disabled };
        })
        const errorText = showBankAccErrorTxt ? 'Verifying for your security': null 
        
        return (
            <>
                <Accordian
                    ref={forwardRef}
                    labelledBy={accordionTitleId}
                    titleChild={
                        <AccordianTitle accType={title} id={accordionTitleId} accIndex={0} accHeaderAs="button" />
                    }
                    titleSeperator
                    Id={title}
                >
                    <Flex flexDirection="column">
                        <StyledDiv mB="1.875">
                            <Label as="h3" id="vcm-swp-fund-to-description">
                                {description}
                            </Label>
                        </StyledDiv>
                          
                        <RadioGroup
                            labelledBy="vcm-swp-fund-to-description"
                            options={fundOptionsRef}
                            onChange={this.onFundOptionChange}
                            initialValue={selectedFundOptionsValue}
                            className="fundToRadioWrapper"
                        />
                        <span className="sr-only">Choose how you will receive your fund.</span>
                        {selectedFundOptions === BANK && (
                            <FieldValue
                                label={SELECT_BANK}
                                as="p"
                                id="vcm-swp-fund-to-select-bank"
                                value={
                                    <SelectDropDown
                                        aria-labelledby="vcm-swp-fund-to-select-bank"
                                        id="vcm-swp-fund-to-select-bank-drop-down"
                                        itemlist={bankList}
                                        onChange={this.handleBankAccNoChange}
                                        errortext={errorText}
                                        CustomOpt={CustomOpt}
                                        CustomOption={CustomOption}
                                        value={selBankValue}
                                        displaySelValBold={displaySelValBold} 
                                        CustomSelectedVal={DropdownSelectedVal}
                                        name={BankTxt}
                                    />
                                }
                                mt="1.875"
                            />
                        )}
                        {selectedFundOptions === CHECK && (
                            <>
                                <StyledDiv mT="1.875" mB="1">
                                    <Label as="h3" id="vcm-swp-fund-to-delivery-address">
                                        {DELIVERY_ADDRESS}
                                    </Label>
                                </StyledDiv>
                                <AccountAddress address={checkAddress} />
                            </>
                        )}
                        {selectedFundOptions === INVEST_ANOTHER_FUND && (
                            <StyledDiv mT="1.875">
                                <TransferToAnotherFund
                                    id="vcm-swp-fund-to-another-fund"
                                    fromFund={selFundNames}
                                    toFundOptions={nonSelFundNames}
                                    handleInvestFundChange={this.handleInvestFundChange}
                                    investToFundName={investToFundName}
                                    value={investToSel}
                                />
                            </StyledDiv>
                        )}
                    </Flex>
                </Accordian>
            </>
        );
    }
}

FundTo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    forwardRef: PropTypes.instanceOf(Object).isRequired,
    bankList: PropTypes.instanceOf(Object),
    checkAddress: PropTypes.instanceOf(Object),
    // fromFundDetails: PropTypes.instanceOf(Object),
    // toFundDetails: PropTypes.instanceOf(Object),
    selectedFundsCount: PropTypes.number,
    checkStatus: PropTypes.func,
    isEditMode: PropTypes.bool,
    savedState: PropTypes.instanceOf(Object),
    offlineMode: PropTypes.string,
    assocFunds: PropTypes.instanceOf(Object),
    shares: PropTypes.instanceOf(Object),
    shortFundName: PropTypes.string,
    fundAccountNumber: PropTypes.number,
    fundName: PropTypes.string,
    selectedFunds: PropTypes.instanceOf(Object),
    bankDisplayRules: PropTypes.instanceOf(Object),
    investToFundName: PropTypes.string
};
FundTo.defaultProps = {
    title: '',
    description: '',
    bankList: [],
    checkAddress: {},
    // fromFundDetails: {},
    // toFundDetails: [],
    selectedFundsCount: 0,
    checkStatus: () => {},
    isEditMode: false,
    savedState: {},
    offlineMode: '',
    assocFunds: [],
    shares: [],
    shortFundName: '',
    fundAccountNumber: 123456,
    fundName: '',
    selectedFunds: [],
    bankDisplayRules: {},
    investToFundName:''
};
export default FundTo;
