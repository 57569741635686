/**
 * Open Account Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description This component allows user to choose between different Account types like 529,IRA etc..
 * @createdDate [06/11/2019]
 * ***************************************
 * @lastModifiedDate [09/01/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason added VD styles
 */

import AccGlobalConstants from '../AccountManagementConstants';
import consts from './consts';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import routeConstants from 'routeConstants';
import { Row, Col } from 'react-bootstrap';
import { WBreadCrumb, WCard, WSpinner, CommonButtons, WAlertComponent } from '../../../common';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import "./MarketOpenAccountComponent.css"
import PrintPdf from '../../../common/TermsAndConditions/PrintPdf';
import envConfig from 'env/EnvConfig';
import { SessionStorageHelper } from 'utils/sessionHelper';


const { assignObj } = AccGlobalConstants;

const breadCrumURL = [{ url: '/accountholder', name: 'Home' }];

export default class MarketOpenAccountComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accIDSelected: '',
            accSelected: '',
            next: false,
            loading: true,
            accTypeList: [],
            errorFromApi: false,
            error: {},
            HelperTetXForAccountsection:false,
            pdf: null,
            pdfShow: false,
        };
    }

    componentDidMount() {

        document.title = `Open a Marketplace Account | Victory Capital`;
        const {localStateData,getCompositeData } = this.props;
        sessionStorage.setItem('isRefresh', true);
        if (localStateData.accSelected !== undefined) {
            this.setState({ accSelected: localStateData.accSelected });
        }
        if (localStateData.accIDSelected !== undefined) {
            this.setState({ accIDSelected: localStateData.accIDSelected });
        }
        if (localStateData.masterDataLoadedForOpenAcc !== undefined) {
            this.setState({ masterDataLoadedForOpenAcc: localStateData.masterDataLoadedForOpenAcc });
        }
        const payload = consts.payloadAccountType;
        if(SessionStorageHelper.getItem('msr_dts')){
        getCompositeData({ data: { datakey: payload }, token: getTokenFromSession() });
        }
        else{
            //nothing for test cases covered
        }

        const marketNAOSavedReducerAccountType = this.props?.marketLocalStateData?.marketNAOInfo?.account?.accountType
        if(marketNAOSavedReducerAccountType != ''){
            this.setState({ accIDSelected: marketNAOSavedReducerAccountType?.includes('IRA') ?'ira_brkg_acct' : 'gen_inv_brkg_acct_v1',
                accSelected : marketNAOSavedReducerAccountType?.includes('IRA') ? 'Individual Retirement Account' : 'General Investment Account' });
        }
        else{
            //nothing for test cases covered
        }
    }

    componentDidUpdate(prevProps /* , prevState */) {     
        if (this.props !== prevProps) {
            this.updateState();
        }
        let h = '';
        for (let index = 0; index < document.getElementsByClassName('card').length; index += 1) {
            if (index === 0) {
                h = document.getElementsByClassName('card')[0].clientHeight;
            } else if (h >= document.getElementsByClassName('card')[parseInt(index, 10)].clientHeight)
                document.getElementsByClassName('card')[parseInt(index, 10)].style.height = `${h}px`;
            else {
                h = document.getElementsByClassName('card')[parseInt(index, 10)].clientHeight;
                for (let i = index; i > -1; i -= 1) {
                    document.getElementsByClassName('card')[parseInt(i, 10)].style.height = `${h}px`;
                }
            }
        }


    }

    componentWillUnmount() {
        sessionStorage.setItem('isRefresh', false);
    }

    updateState = () => {
        const { masterLookupStateData } = this.props;
        let accTypeList = [];
        if (masterLookupStateData.brkg_acct_type_v1 && masterLookupStateData.brkg_acct_type_v1.value) {
            accTypeList = masterLookupStateData.brkg_acct_type_v1.value;
             this.setState({ accTypeList, masterDataLoadedForOpenAcc: true });
        }
        if (masterLookupStateData.isLoading === true) {
            this.setState({ loading: true });
        } else if (masterLookupStateData.isLoading === false) {
            this.setState({ loading: false });
        }
        // Error handling for Master Data
        if (masterLookupStateData.isError) {
            this.setState({ errorFromApi: true, error: masterLookupStateData[consts.payloadAccountType] });
        }
    };

    onCardClick = (itemKey, itemValue) => e => {
        e.stopPropagation();
        const { accIDSelected } = this.state;
        const { localStateData, manageLocalState } = this.props;
        if (localStateData.marstoneFlag === true && localStateData.accountType === 'Money Market') {
            manageLocalState({
                accIDSelected: itemKey,
                accSelected: itemValue,
            });
        } else if (accIDSelected !== itemKey) {
            const { clearLocalStateForNAO } = this.props;
            clearLocalStateForNAO();
        }
        this.setState({
            accSelected: itemValue,
            accIDSelected: itemKey,
            showAlert: false,
        });
    };


    handleNext = e => {
        e.stopPropagation();
        const { accIDSelected } = this.state;
        if (accIDSelected === 'trans_acct') {
            this.saveToLocal();
            const { history } = this.props;
            history.push(routeConstants.transferAccount);
        } else if (accIDSelected) {
            this.setState({ next: true }, () => {
                this.saveToLocal();
            });
        } else {
            this.setState({ showAlert: true },()=>{
                let inputElm=document.getElementsByClassName('btn btn-outline-light btn btn-secondary')[0];
                inputElm.children[0].focus();
            });
        }
    };
    

    saveToLocal = () => {
        const { manageLocalState } = this.props;
        const { masterDataLoadedForOpenAcc, accSelected, accIDSelected } = this.state;
        const data = {
            accSelected,
            accIDSelected,
            masterDataLoadedForOpenAcc,
        };
        manageLocalState(data);
        sessionStorage.setItem('accountSelected', accSelected);
    };
    
    render() {
        const { accTypeList, showAlert, loading, next, errorFromApi, error, accIDSelected } = this.state;
        if (errorFromApi) {
            throw new Error(error);
        }
        const activeCrumb = 'Open a Marketplace Account';

        const cards = accTypeList.map(item => {
            const iconColor = accIDSelected === item.key ? { color: '#004A98' } : { color: '#fff' };
            return (
                <Col xs={12} sm={12} md={6} lg={4} key={item.key} id="accountsName">
                    <WCard
                        className=""
                        selected={accIDSelected === item.key}
                        acctid={item.key}
                        icon={faCheckCircle}
                        fontawesomeiconcard={assignObj({ ...iconColor, ...consts.fontawesomeiconcard })}
                        cardtitletext={item.value}
                        cardtext={item.description}
                        aria-describedby={assignObj(`${item.key}_desc`)}
                        aria-checked={accIDSelected === item.key}
                        descid={assignObj(`${item.key}_desc`)}
                        buttonstyle={assignObj({ display: 'none' })}
                        cardstyle={consts.cardStyling}
                        cardtitlestyle={assignObj({
                            fontSize: '1.1em',
                            paddingBottom: 10,
                            borderBottom: '1px solid #D2D2D2',
                        })}
                        cardbodystyle={assignObj({ padding: '22px 25px 30px 20px' })}
                        cardtextstyle={assignObj({ fontSize: '1.0em', paddingRight: 32, marginBottom: 10 })}
                        onClick={this.onCardClick(item.key, item.value)}
                        haveToggleButton
                    />
                </Col>
            );
        });

        //Account type pdf functions
        const { pdfShow, pdf } = this.state
        const accountTypeDeatailspdfLink = {
            url: envConfig.MARKET_ACCOUNT_TYPE_DETAILS_DOCURL,
            withCredentials: true,
        }
        const PdfShowFunction = () => {
            this.setState({ pdfShow: true })
            this.setState({ pdf: accountTypeDeatailspdfLink })
        }
        const pdfHideFunction = () => {
            this.setState({ pdfShow: false })
        }
        return (
             <>
                <WSpinner loading={loading} />
                  <div>
                      <div style={consts.wrapperStyle}>
                           <div className="container" role="radiogroup" aria-labelledby="open_account_label_gorup">
                               <WBreadCrumb
                                   activeCrumb={activeCrumb}
                                   breadCrumbItems={breadCrumURL}
                               />
                               <Row>
                                   <Col>
                                       <h1 id="open_account_label_gorup" style={consts.openAccHeading}>
                                           {consts.openAccText}
                                       </h1>
                                   </Col>
                               </Row>
                               <p className='accountTypePdf'><a href={`${envConfig.ENV_URL}/marketplace/account-types`} target="_blank"
                                style={assignObj({ display: 'inline-block',color: '#004a98', textDecoration: 'underline' })}> Tell me more about different Account Types</a></p>
                            <PrintPdf
                                file={pdf}
                                show={pdfShow}
                                handleHide={pdfHideFunction}
                                maximumModalHeight={window.innerHeight}
                                printIconHider="printIconHider"
                            />
                               <WAlertComponent
                                   show={showAlert}
                                   alertmsg={consts.SELECT_ATLEAST_ONE_ACCOUNT_ERROR_MESSAGE}
                                   style={consts.alertStyle}
                               />
                               <Row>{cards}</Row>
                               {next ? <Redirect to="/marketAccountType" /> : ''}
                           </div>
                       </div>
                       <CommonButtons
                           nextClick={this.handleNext}
                           backstyle={consts.displayNone}
                           isShowBtn={true}
                           disabled={false}
                      />
             </div>
            </>
        );
    }
}

MarketOpenAccountComponent.propTypes = {
    masterLookupStateData: PropTypes.instanceOf(Object),
    getCompositeLookUpData: PropTypes.func,
    localStateData: PropTypes.instanceOf(Object),
    manageLocalState: PropTypes.func,
    clearLocalStateForNAO: PropTypes.func,
    initialState: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object).isRequired,
};

MarketOpenAccountComponent.defaultProps = {
    masterLookupStateData: {},
    getCompositeLookUpData: () => {},
    localStateData: {},
    manageLocalState: () => {},
    clearLocalStateForNAO: () => {},
    initialState: {},
};
