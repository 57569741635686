
const FAMILY_RELATION = 'Family Member\'s relationship to you';
const FIRSTNAME= 'First Name';
const LASTNAME = 'Last Name';
const MIDDLENAME = 'Middle Name';
const PREFIX = 'Salutation';
const SUFIX = 'Name Suffix';
const DATEOFBIRTH = 'Date of birth';
const GENDER = 'Gender';
const MARITALSTATUS = 'Marital Status';
const USCITIZEN = 'US Citizen';
const SSN = 'Social Security Number';
const bredCrumItmes = [
	{ 
		url: '/Profile', 
		name: 'Profile',
		dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_profile'
	}, 
	{ 
		url: '/basicinformation', 
		name: 'Basic Information',
		dataAnalyticId:'cms_basicinformation_managerelationship_breadcrumb_basicinfo'
	}
];
const BREADCRUM_RELATION_NAME = 'Manage Relationship';
const breadCrumName = 'Add Relationship Information';
const ADD_RELATIONSHIP_TITLE = 'Add Relationship Information';
const PHONE_NUMBER_TITLE = 'Phone Number';
const EMAIL_TITLE = 'Email';
const ADDRESS_TITLE = 'Address';
const RELATION_INFO = 'Manage relationship';
const MANDATORY_TEXT = "* All fields are required except mentioned optional";
const PRIMARY_PHONE_TITLE = 'Primary Phone Number';
const SECONDARY_PHONE_TITLE = 'Secondary Phone Number';
const PRIMARY_EMAIL_TITLE = 'Primary Email';
const SECONDARY_EMAIL_TITLE = 'Secondary Email';
const ADDRESS_LABEL_TEXT = 'Address Type'
const PHYSICAL_ADDRESS_LABEL = 'Is your physical address same as mailing address?';
const ACTION_LABEL_RELATION = 'Manage relationship information';
const ACTION_TEXT = 'ADD';
const SECURITY_REASONS = 'For security reasons, not all the information can be updated online';
const COUNTRY_OF_CITIZEN = 'Country of Citizenship ';
const PROOF_OF_CITIZEN = 'Proof of Citizenship or Residency';
const PASSPORT_NUMBER = 'Passport Number';
const PASSPORT_AUTHORITY = 'Passport Issuing Authority';
const RESIDENT_CARD_NUMBER = 'Permanent Resident Card Number';
const PASSPORT_EXPIRATION_DATE= 'Expiration Date';
const ADDED_PERSON_HAVE_SSN = 'Does the person you are adding have a Social Security number?';
const ADDED_PERSON_HAVE_SSN_DATA = [{value: 'Yes',key:'Yes'},{value:'No',key:'No'}];
const RELATIOSHIP_MAIN_PAGE_NAME = 'Relationship Information';
const COUNTRY_ERROR = "Please select a country";
const GENDER_ERROR = "Please select a gender";
const MARITAL_STATUS_ERROR = "Please select a marital status";
const FAMILY_RELATION_TO_YOU_ERROR = "Please select a family member's relationship to you";
const FIRST_NAME_ERROR = "Please enter first name";
const LAST_NAME_ERROR = "Please enter last name";
const DATEOFBIRTH_ERROR = "Please select date of birth";
const INVALID_DATE_ERROR = "Invalid Date";
const CITIZENSHIP_ERROR = "Please select a citizenship";
const CITIZENSHIP_PROOF_ERROR = "Please select a proof";
const PASSPORT_NUMBER_ERROR = "Please enter passport number";
const PASSPORT_AUTHORITY_ERROR = "Please enter passport issuing authority";
const EXPIRATION_ERROR = "Please select expiration date";
const PERSON_HAVE_SSN_ERROR = "Please select whether person have SSN";
const RESIDENT_NUMBER_ERROR = "Please select permanent resident card number";
const SSN_ERROR = "Please select SSN";

const EMPTY_FAMILY_RELATION_INFO = "Provide your family relationship information";
const RELATIONSHIP_TO_ACCOUNT_OWNER = 'Relationship to Account Owner';
const EMAIL_ADDRESS = 'Email Address';
const AX_ADDEMAILID_TXT = "Add email id";
const SAVE = "Save";
const CANCEL = "Cancel";
const MOBILE = 'Mobile';
const NON_MOBILE = 'Non Mobile';

const MAILING_ADDRESS = 'Mailing Address';
const PHYSICAL_ADDRESS = 'Physical Address';

export default {
	MAILING_ADDRESS,
	PHYSICAL_ADDRESS,
	EMPTY_FAMILY_RELATION_INFO,
	bredCrumItmes,
	breadCrumName,
	BREADCRUM_RELATION_NAME,
	ADD_RELATIONSHIP_TITLE,
	PHONE_NUMBER_TITLE,
	EMAIL_TITLE,
	ADDRESS_TITLE,
	PRIMARY_PHONE_TITLE,
	SECONDARY_PHONE_TITLE,
	PRIMARY_EMAIL_TITLE, 
	SECONDARY_EMAIL_TITLE,
	PHYSICAL_ADDRESS_LABEL,
	ADDRESS_LABEL_TEXT,
	ACTION_TEXT,
	FAMILY_RELATION,
	FIRSTNAME,
	LASTNAME,
	MIDDLENAME,
	PREFIX,
	SUFIX,
	DATEOFBIRTH,
	GENDER,
	MARITALSTATUS,
	USCITIZEN,
	SSN,
	RELATION_INFO,
	ACTION_LABEL_RELATION,
	MANDATORY_TEXT,
	SECURITY_REASONS,
	COUNTRY_OF_CITIZEN,
	PROOF_OF_CITIZEN,
	PASSPORT_NUMBER,
	PASSPORT_AUTHORITY,
	RESIDENT_CARD_NUMBER,
	PASSPORT_EXPIRATION_DATE,
	ADDED_PERSON_HAVE_SSN,
	ADDED_PERSON_HAVE_SSN_DATA,
	RELATIOSHIP_MAIN_PAGE_NAME,
	MARITAL_STATUS_ERROR,
	FAMILY_RELATION_TO_YOU_ERROR,
	GENDER_ERROR,
	COUNTRY_ERROR,
	FIRST_NAME_ERROR,
	LAST_NAME_ERROR,
	DATEOFBIRTH_ERROR,
	RELATIONSHIP_TO_ACCOUNT_OWNER,
	EMAIL_ADDRESS,
	AX_ADDEMAILID_TXT,
	SAVE,
	CANCEL,
	INVALID_DATE_ERROR,
	MOBILE,
	NON_MOBILE,
	CITIZENSHIP_ERROR,
	CITIZENSHIP_PROOF_ERROR,
	PASSPORT_NUMBER_ERROR,
	PASSPORT_AUTHORITY_ERROR,
	EXPIRATION_ERROR,
	PERSON_HAVE_SSN_ERROR,
	RESIDENT_NUMBER_ERROR,
	SSN_ERROR
};