/**
 * Agreement Component
 ****************************************
 *
 * @version 1.0.1
 * @author Anjana Joy
 * @description Agreement for paper flow
 * @createdDate [27/03/2020]
 * ***************************************
 * @lastModifiedDate [27/03/2020]
 * @lastModifiedBy Anjana Joy
 * @lastModifiedReason
 */

import React, { Component } from 'react';
// import { PropTypes } from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { WCard, CommonButtons, WStepper } from '../../../common';
import AccGlobalConstants from '../AccountManagementConstants';
import consts from './consts';
import './agreementPaperFlow.css';

class AgreementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            back: false,
        };
    }

    // componentDidMount() {

    // }

    handlePdf = () => {
        window.open('https://www.antennahouse.com/XSLsample/pdf/sample-link_1.pdf');
    };

    handleBack = () => {
        this.setState({ back: true });
    };

    handleNext = () => {
        this.setState({ next: true });
    };

    render() {
        const { next, back } = this.state;
        const {
            paperFlowHeading,
            agreementHeading,
            agreementPara1,
            agreementCard1Title,
            downloadPdfText,
            cardtitlestyle,
        } = consts;

        return (
            <div>
                <Container className="paperFlowPage agreementPage">
                    <Row noGutters>
                        <Col>
                            <h1 className="pageHeading">{paperFlowHeading}</h1>
                            <WStepper pages={AccGlobalConstants.paperFlowPages} currentPage={1} />
                        </Col>
                    </Row>
                    <Row noGutters className="headerRow">
                        <Col>
                            <h2 className="pageSubHeading">{agreementHeading}</h2>
                        </Col>
                    </Row>
                    <Row noGutters className="contentRow">
                        <Col sm={12}>
                            <p className="strongPara">{agreementPara1}</p>
                        </Col>
                    </Row>
                    <Row noGutters className="cardColWrapper">
                        <WCard
                            className=""
                            cardtitletext={agreementCard1Title}
                            cardtitlestyle={cardtitlestyle}
                            buttontext={downloadPdfText}
                            pdflink="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                            downloadtext="true"
                            download
                        />
                    </Row>
                </Container>
                <CommonButtons backClick={this.handleBack} nextClick={this.handleNext} />
                {next && <Redirect to="/privacy" />}
                {back && <Redirect to="/accountType" />}
            </div>
        );
    }
}

export default AgreementComponent;
