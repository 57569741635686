import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
// import LoadingOverlay from 'react-loading-overlay';
import routeConstants from 'routeConstants';
import userConstants from 'modules/UserManagementAndSecurityModule/userConstants';
import queryString from 'query-string';
import Guidelines from 'modules/UserManagementAndSecurityModule/Guidelines';
import checkFormat from 'modules/UserManagementAndSecurityModule/checkFormat';
import { ConfirmPassword } from 'modules/UserManagementAndSecurityModule/Input/ConfirmPassword';
import { Password } from 'modules/UserManagementAndSecurityModule/Input/Password';
import '../Screens.css';
import SideHeader from '../../SideHeader/SideHeader';
import { CommonButtons, WSpinner } from '../../../../common';
import { inputChangedHandler, inputValidityHandler } from '../../FormUtils';
import {encrypt} from '../../../../utils';
import styles from '../../UserManagementStyles';
import data from './Constants';

/* const assignObj = obj => {
    return obj;
}; */

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upperLower: false,
            min: false,
            symbol: false,
            numeral: false,
            noStartEndNumber: false,
            cancelled: false,
            formIsValid: false,
            form: {
                password: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: 'Password',
                    rules: {
                        maxLength: 50,
                        pattern: userConstants.passwordRegex,
                        patternMessage: userConstants.passwordMessage,
                        required: true,
                    },
                },
                confirmPassword: {
                    value: '',
                    errMsg: null,
                    valid: false,
                    label: userConstants.confirmPasswordLabel,
                    rules: {
                        equal: true,
                        equalMessage: 'Both passwords do not match',
                        required: true,
                    },
                },
            },
            loading: false,
            guestuuid: '',
        };
        this.onBack = this.onBack.bind(this);

        this.onNext = this.onNext.bind(this);

        this.onChange = this.onChange.bind(this);

        this.onBlur = this.onBlur.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { history, manageLocalState, resetPasswordData, resetPassword, resetGuestPasswordData, resetPasswordForGuest, recoverPwrdGuestEmailVerify, recoverPwrdGuestEmailVerifyData, location } = props;
        const updatedState = { ...state };
        if(recoverPwrdGuestEmailVerifyData && recoverPwrdGuestEmailVerifyData.result) {
            const { errorCode, /* message, */ result } = recoverPwrdGuestEmailVerifyData;
            if(errorCode === 0) {
                updatedState.guestuuid = queryString.parse(location.search);
                recoverPwrdGuestEmailVerify('');
            } else {
                recoverPwrdGuestEmailVerify('');
                history.push({
                    pathname: routeConstants.signIn,
                    failure: true,
                    message: {message: result}, // check the text with Devashish
                });
            }
        }
        if (resetPasswordData) {
            const { errorCode, message, result } = resetPasswordData;
            if (errorCode === 0 && message === 'Success') {
                resetPassword('');
                manageLocalState({otpDetails:{}});
                history.push({
                    pathname: routeConstants.signIn,
                    message: { message: userConstants.resetSuccess },
                });
            } else {
                updatedState.form.confirmPassword.errMsg = result;
                resetPassword('');
            }
        }
        if (resetGuestPasswordData) {
            const { errorCode, message, result } = resetGuestPasswordData;
            if (errorCode === 0 && message === 'Success') {
                resetPasswordForGuest('');
                manageLocalState({otpDetails:{}});
                history.push({
                    pathname: routeConstants.signIn,
                    message: { message: userConstants.resetSuccess },
                });
            } else {
                updatedState.form.confirmPassword.errMsg = result;
                resetPasswordForGuest('');
            }
        }
        return updatedState;
    }
    
    componentDidMount() {
        const { history, location, recoverPwrdGuestEmailVerify, manageLocalState } = this.props;
        const { uuid } = queryString.parse(location.search);
        manageLocalState({dataFlag:true})
        if(!uuid && !location.UUID) {
            manageLocalState({otpDetails: {}})
            history.push({
                pathname: routeConstants.signIn,
            });
        }
        if(uuid) {
            recoverPwrdGuestEmailVerify({uuid});
        }
      
    }

    onChange(event, fieldId) {
        checkFormat(event, fieldId, this);
        inputChangedHandler(event, fieldId, this);
    }

    onBlur(fieldId) {
        inputValidityHandler(fieldId, this);
    }

    onBack() {
        this.setState(state => ({ ...state, cancelled: true }));
    }

    onNext() {
        const { resetPassword, location, resetPasswordForGuest } = this.props;
        const { UUID } = location;
        const { uuid } = queryString.parse(location.search);
        const { form } = this.state;
        const { value } = form.password;
        // history.push({
        //     pathname: routeConstants.signIn,
        //     message: { message: userConstants.resetSuccess },
        // });
        (async () => {
            const passvalue = await encrypt(value);
            if(UUID){
                const payload = {
                    uuid:UUID,
                    password: passvalue,
                };
                resetPassword(payload);
            } else {
                const payload = {
                    uuid,
                    password: passvalue,
                };
                resetPasswordForGuest(payload);
            }
        })();
    }

    render() {
        const { form, cancelled, formIsValid } = this.state;
        const { loginState } = this.props;
        return (
            <div>
                <WSpinner loading={loginState.isLoading} />
                <div className="container">
                    <Row className="main">
                        <Col md lg xl>
                            <SideHeader title={data.title} />
                            <Guidelines state={this.state} type="password" />
                        </Col>
                        <Col md lg xl>
                            <Password
                                passwordState={form.password}
                                changed={this.onChange}
                                blurred={this.onBlur}
                                showStrength
                            />
                            <ConfirmPassword
                                confirmPasswordState={form.confirmPassword}
                                changed={this.onChange}
                                blurred={this.onBlur}
                            />
                        </Col>
                    </Row>
                </div>
                <CommonButtons
                    backClick={this.onBack}
                    submit="true"
                    submitClick={this.onNext}
                    disabled={!formIsValid}
                    backstyle={styles.hide}
                    blockstyle={styles.commonButtonsHalf}
                    dataAnalyticId="UMS_Signup_CreatePassword"
                />
                {cancelled ? <Redirect to={data.route} /> : null}
            </div>
        );
    }
}

CreatePassword.propTypes = {
    resetPasswordData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    resetPassword: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    loginState: PropTypes.instanceOf(Object),
    recoverPwrdGuestEmailVerify: PropTypes.func,
    recoverPwrdGuestEmailVerifyData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    resetGuestPasswordData: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    resetPasswordForGuest: PropTypes.func,
    manageLocalState: PropTypes.func,
};

CreatePassword.defaultProps = {
    resetPasswordData: {},
    resetPassword: () => {},
    history: [],
    location: {},
    loginState: {},
    recoverPwrdGuestEmailVerify: () => {},
    recoverPwrdGuestEmailVerifyData: {},
    resetGuestPasswordData: {},
    resetPasswordForGuest: () => {},
    manageLocalState: () => {},
};

export default CreatePassword;
