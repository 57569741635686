import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import ApiEventHub from './ApiEventHub';
import { get, put, setMSRAuthHeaders } from './Services';
import Config from 'env/Config';

const ProfileAndPreferencesEvents = () => {
  ApiEventHub.on(ActionTypes.GET_MEMBER_PREFERENCES, data => {
    const {accesstoken = "", vcmid = ""} = data.payload;
    const xAPIKey = Config.msrMemberPreferenceKey;
    setMSRAuthHeaders(accesstoken, vcmid, '', xAPIKey );
    get(
        Config.msrMemberPreference,
        '',
        data.endPointURL.msrMemberPreferenceAPI,
        data.successCallBack,
        data.errorCallback,
    );
  });


  ApiEventHub.on(ActionTypes.GET_MEMBER_MSR_PREFERENCES, data => {
    const {accesstoken = "", vcmid = "", msrid="", fismemberid=""} = data.payload;
    const xAPIKey = Config.msrAPIKey;
    setMSRAuthHeaders(accesstoken, vcmid, msrid, xAPIKey, fismemberid );
    get(
        Config.getMemberMSRPreferences,
        '',
        data.endPointURL.BasePath,
        data.successCallBack,
        data.errorCallback,
    );
  });


  ApiEventHub.on(ActionTypes.UPDATE_MEMBER_PREFERENCES, data => {
    put(
        Config.updateMemberPreferences,
        data.payload,
        data.endPointURL.devEnvironment,
        data.successCallBack,
        data.errorCallback,
    );
  });
  
};

export default ProfileAndPreferencesEvents;
