/* eslint-disable react/jsx-no-literals */


/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import './WelcomePage.css';
import analytics from './utils.analytics';
import Captcha from './captcha';
import envConfig from 'env/EnvConfig';

const PreRegistrationCard = ({
  cardDisplayName,
  isBottom,
  bottomPosition,
  history,
}) => {
  const [toggle, setToggle] = useState(false);

  const assignObj = (obj) => obj;

  const handleClick = assignObj(() => {
    const googleCaptchaToggle = envConfig
      ? envConfig.GOOGLE_CAPTCHA_TOGGLE
      : 'on';
    analytics.triggerClickTrackEvent('Preregister_welcomepage_registernow');
    window.scriptLoaded = false;
    if (googleCaptchaToggle === 'off') {
      history.push('/accountRegistration/termsandconditions');
    } else {
    setToggle(true);
    }
  });
  
  const onCaptchaChange = assignObj(() => {
    analytics.triggerClickTrackEvent(
      'Preregister_welcomepage_registernow_verification_success',
    );
    history.push('/accountRegistration/termsandconditions');
  });
  return (
    <Card
      className={`preRegistrationStepsCard ${cardDisplayName}`}
      style={assignObj({ paddingLeft: 11 })}
    >
      <Card.Body>
        <Card.Title>
          <h2 className="preRegCardHeader" id="preRegCardHeaderLabel">
            How to pre-register:
          </h2>
        </Card.Title>
        <div>
          <ol className="howToPreReg">
            <li>
              <span className="preRegCount">1</span>
              Review our Terms & Conditions
            </li>
            <li>
              <span className="preRegCount">2</span>
              Verify your Identity
            </li>
            <li>
              <span className="preRegCount">3</span>
              Create an Online ID & Password
            </li>
          </ol>

          <div className="preRegBtnWrapper">
            <p className="preRegTxt">
              Pre-registration will take less than 5 minutes.
            </p>
            {toggle ? (
              <Captcha
                onCaptchaChange={onCaptchaChange}
                isBottom={isBottom}
                bottomPosition={bottomPosition}
              />
            ) : (
              <button
                type="button"
                className="vcmbtn preRegButton"
                style={assignObj({
                  bottom: isBottom ? bottomPosition : 0,
                })}
                onClick={handleClick}
              >
                <span className="vcmBtnText">Register Now</span>
              </button>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
PreRegistrationCard.propTypes = {
  cardDisplayName: PropTypes.string,
  isBottom: PropTypes.bool,
  bottomPosition: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

PreRegistrationCard.defaultProps = {
  cardDisplayName: '',
  isBottom: false,
  bottomPosition: 0,
  history: {},
};
export default PreRegistrationCard;
