import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-bootstrap';
import { WButton, WCard, MaskText } from '../../../../common';
import '../../../../App.css';
import EditIcon from '../../../../assets/edit.png';
import * as TransactionConstants from '../../TransactionConstants';
import { isTokenAvailable } from 'common/Authenticate/session';
import { currencyFormatterFunction } from 'common/Formatter/CurrencyFormatter';

const VerifySection = styled.div``;

const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const displayNoneStyle = { display: 'none' };

const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const FlexRowDiv1 = styled.div`
    display: flex;
    flex-direction: row;
`;
const StyledInfoCol = styled(Col)`
    @media (max-width: 767px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;
const TitleTxt = styled.h2`
    text-align: center;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;

const EditImage = styled.img`
    margin-left: auto;
`;

const EditDiv = styled.div`
    cursor: pointer;
    text-align: right;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-left: 0.313rem;
    margin-top: 4px;
    float: right;
`;

EditDiv.displayName = TransactionConstants.EDIT_DIV;

const Separator = styled.hr`
    border: 1px solid #d2d2d2;
    opacity: 1;
    margin-top: 0.125rem;
`;

const SectionBody = styled(FlexColumnDiv)`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
`;

const Field = styled.div`
    flex: 0 0 15em;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
`;
const Value = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 2.5rem;
`;

const FundField = styled.h3`
    font-size: 16px;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    margin-bottom: 2.5rem;
`;

const SectionTitle = styled(FlexRowDiv1)`
    justify-content: space-between;
    align-items: center;
`;
const FundFromDesc = styled.h3`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const ButtonStyle = styled.button`
    width: 150px;
    height: 50px;
    opacity: 1;
    text-align: center;
    font: 500 16px/22px Benton Sans;
    letter-spacing: 0;
    border: 1px solid #61285f45;
    &:disabled {
        opacity: 0.5;
    }
`;

const SecondaryButton = styled(ButtonStyle)`
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #544a54;
`;
SecondaryButton.displayName = 'SecondaryButton';

const PrimaryButton = styled(ButtonStyle)`
    background: #544a54 0% 0% no-repeat padding-box;
    color: #ffffff;
`;
const WarningWindow = styled(Row)`
    margin: 20px 0;
`;
PrimaryButton.displayName = 'PrimaryButton';

const editText = 'Edit';

const MainDiv = styled.div`
    margin-bottom: 30px;
    margin-top: 3px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border: 1px solid #efeded;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

const cardBorderStyle = {
    borderLeft: '35px solid #8BC105',
    top: '1px',
    left: '1px',
};

const cardTextStyle = {
    font: '500 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    margin: 0,
};

const cardBodyStyle = {
    paddingBottom: 0,
    border: '2px solid #004A98',
};

const cardTitleStyle = {
    font: '600 16px/20px benton-sans, sans-serif',
    color: 'fontColor',
    textAlign: 'left',
    marginBottom: 11,
};

const fundsourcetxt = 'Fund Source';

const notOnFile = 'Not on file.';

const assignObj = obj => {
    return obj;
};
const buttonstyle = { display: 'block' };

const cancelButtonStyles = {
    color: 'rgb(0, 74, 152)',
    marginBottom: '15px',
    marginLeft: '13px',
};

const BtnWrap = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    & > :nth-child(2) {
        margin-left: auto;
        margin-right: 50px;
        button {
            margin-bottom: 0 !important;
        }
    }
    @media (max-width: 767px) {
        & > :nth-child(1) {
            order: 3;
        }
        & > :nth-child(2) {
            order: 2;
            margin: 0;
            button {
                margin: 0 !important;
            }
        }
        & > :nth-child(3) {
            order: 1;
            width: 100%;
            button {
                width: 100% !important;
            }
        }
        & > :not(:first-child) {
            margin-bottom: 20px;
        }
        flex-direction: column;
    }
`;

class PurchseReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: TransactionConstants.REVIEW_CONFIRM_DATA,
        };
        this.bottomNav1 = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { purchaseReducerData } = nextProps;
        const { savedState } = nextProps;
        if (!prevState.retrivedState && savedState) {
            return {
                ...savedState,
                retrivedState: true,
                isLoading: purchaseReducerData.isLoading,
            };
        }
      
        return {
            ...prevState,
            isLoading: purchaseReducerData.isLoading,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        sessionStorage.setItem(TransactionConstants.STEPNAME_VALUE, TransactionConstants.REVIEW_CONFIRM_TEXT);
        const { purchaseReducerData, setTradingWarning, isIRA,resetPurchaseSubmit } = this.props;
        const { cards } = this.state;
        const cardsArray = [...cards];
        if (!isIRA() && cardsArray.length === 6) {
            cardsArray.pop();
            if (purchaseReducerData.page1?.isProportinateSelected || (purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0].fundAccountNumber)) {
                cardsArray.splice(3, 1);
            }
        } else if (purchaseReducerData.page1?.isProportinateSelected || (purchaseReducerData.page1 && purchaseReducerData.page1.selectedAccounts[0].fundAccountNumber)) {
            cardsArray.splice(3, 1);
        }

        const purchaseStateData = [purchaseReducerData.page0, purchaseReducerData.page1, purchaseReducerData.page2];
        this.updatePurchaseData(cardsArray, purchaseStateData);
        setTradingWarning();
        resetPurchaseSubmit()
    }

    getCardHeading = heading => {
        if (heading === TransactionConstants.ACCOUNT_SELECTION_TEXT) {
            return TransactionConstants.ACCOUNT_INFORMATION;
        }
        if (heading === TransactionConstants.ACCORDIAN_HEADING) {
            return TransactionConstants.FUND_DETAILS_TEXT;
        }
        return heading;
    };

    populateTradeTypeValues = dataObj => {
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            field.key = TransactionConstants.TRADE_TYPE_TEXT;
            field.value = TransactionConstants.BUY_TEXT;
            return null;
        });
        return dataObj;
    };

    populateAccSelValues = (data, actualData) => {
        const dataObj = { ...data };
        dataObj.pageIndex = 0;
        dataObj.section = TransactionConstants.ACCOUNT_SELECTION_TEXT;
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (field.key === TransactionConstants.ACCOUNT_NAME_TEXT) {
                field.value = actualData[0].accountSelected.accountName;
                
            } else if (field.key === TransactionConstants.ACCOUNT_NUMBER_TEXT) {
                field.value = actualData[0].accountSelected.accountNumber;
                
            }
            return null;
        });
        return dataObj;
    };

    populateInvSelValues = (data, actualData) => {
        const { purchaseReducerData } = this.props;
        const fundOptionSelectedKey =
            purchaseReducerData.page1 &&
                purchaseReducerData.page1.fundOptionSelected &&
                purchaseReducerData.page1.fundOptionSelected.includes(TransactionConstants.INIT_TEXT)
                ? TransactionConstants.AMOUNT_LABEL
                : TransactionConstants.ONE_TIME_INVESTMENT_VALUE;
        const dataObj = { ...data };
        dataObj.pageIndex = 1;
        dataObj.section = TransactionConstants.INVESTMENT_SELECTION_TEXT;
        dataObj.fundName = actualData[1].selectedAccounts[0].fundName;
        dataObj.fundAccountNumber = actualData[1].selectedAccounts[0].fundAccountNumber;
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (
                field.key === TransactionConstants.AMOUNT_LABEL ||
                field.key === TransactionConstants.ONE_TIME_INVESTMENT_VALUE
            ) {
                field.key = fundOptionSelectedKey;
                field.value = currencyFormatterFunction(Number(actualData[1].totalSubAmt).toFixed(2));
            } else if (field.key === TransactionConstants.MONTHLY_INVESTMENT) {
                field.value = actualData[1].showMnthlyInvst
                    ? currencyFormatterFunction(Number(actualData[1].totalMnlyInv).toFixed(2))
                    : notOnFile;
            } else if (fieldKey.key === TransactionConstants.START_DATE_TEXT) {
                field.value = actualData[1].showMnthlyInvst ? actualData[1].selectedAccounts[0].startDate : notOnFile;
            }
            return null;
        });
        return dataObj;
    };

    populateDividendsValues = (data, actualData) => {
        const dataObj = { ...data };
        dataObj.pageIndex = 2;
        dataObj.section = fundsourcetxt;
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (field.key === TransactionConstants.DIVIDEND_CAPITAL_GAINS_TEXT) {
                if (actualData[2].ReInvest) {
                    field.value = TransactionConstants.YES_REINVEST;
                } else {
                    field.value = TransactionConstants.NO_REINVEST;
                }
            }
            return null;
        });
        return dataObj;
    };

    populateFundAccValues = (data, actualData) => {
        const { purchaseReducerData } = this.props;
        const dataObj =
            actualData[2].payMethods.newAcc || actualData[2].payMethods.bankAcc
                ? TransactionConstants.ONLINE_FUND_SOURCE_OBJ
                : { ...data };
        let bankAccDetails = {};
        dataObj.pageIndex = 2;
        dataObj.section = fundsourcetxt;
        dataObj.fields.map(fieldKey => {
            const field = fieldKey;
            if (field.key === TransactionConstants.FUND_SOURCE_TEXT) {
                if (actualData[2].payMethods.check) {
                    field.value = TransactionConstants.CHECK_ORDER_TEXT;
                } else if (actualData[2].payMethods.wireTransfer) {
                    field.value = TransactionConstants.WIRE_TRANSFER_TEXT;
                } else if (actualData[2].payMethods.newAcc || actualData[2].payMethods.bankAcc) {
                    bankAccDetails =
                        purchaseReducerData.page2 &&
                        purchaseReducerData.page2.bankAccounts.filter(acc => acc.selected === true);
                    field.value = bankAccDetails[0].bankName
                        ? bankAccDetails[0].bankName
                        : TransactionConstants.BANK_ACCOUNT_TEXT;
                }
            } else if (
                (actualData[2].payMethods.newAcc || actualData[2].payMethods.bankAcc) &&
                field.key === TransactionConstants.ACCOUNT_NUMBER_TEXT
            ) {
                field.value = (
                    <MaskText
                        text={bankAccDetails[0].bankAccountNumber}
                        startIndex={0}
                        maskLength={
                            bankAccDetails[0].bankAccountNumber && bankAccDetails[0].bankAccountNumber.length - 4
                        }
                    />
                );
            } else if (field.key === TransactionConstants.TOTAL_INVESTMENT_TEXT) {
                field.value = currencyFormatterFunction(Number(actualData[1].totalSubAmt + actualData[1].totalMnlyInv).toFixed(2));
            }
            return null;
        });
        return dataObj;
    };

    notSEPIRA = (cardHeading) => {
        const { purchaseReducerData } = this.props;

        if ((purchaseReducerData.page1.accountName === 'SEP IRA' ||
            purchaseReducerData.page1.accountName === 'Simple IRA - DFI' ||
            purchaseReducerData.page1.accountName === 'Simple IRA - Non DFI') && cardHeading === 'Contribution') {
            return false
        } else {
            return true
        }
    }


    populateIRAContributionValues = (data, actualData) => {
        const employerKeyValue = actualData[0].accountSelected.accountName === TransactionConstants.SEP_IRA ||
            actualData[0].accountSelected.accountName === TransactionConstants.SIMPLE_IRA_DFI ||
            actualData[0].accountSelected.accountName === TransactionConstants.SIMPLE_IRA_NON_DFI ?
            TransactionConstants.EMPLOYER_CONTRIBUTIONS : TransactionConstants.CONTRIBUTION_IRA;
        const dataObj = { ...data };
        dataObj.pageIndex = 2;
        dataObj.section = fundsourcetxt;
        // TODO: this if condition needs to be fixed when the test dat is coming in right
        if (actualData[0].accountSelectedType.accountType !== TransactionConstants.IRA_HOLDING_GROUP_NAME) {
            dataObj.fields.map(fieldKey => {
                const field = fieldKey;
                if (field.key === TransactionConstants.CONTRIBUTION_IRA || TransactionConstants.EMPLOYER_CONTRIBUTIONS) {
                    field.key = employerKeyValue;

                    if (actualData[0].accountSelected.accountName === TransactionConstants.SEP_IRA ||
                        actualData[0].accountSelected.accountName === TransactionConstants.SIMPLE_IRA_DFI ||
                        actualData[0].accountSelected.accountName === TransactionConstants.SIMPLE_IRA_NON_DFI) {
                        field.value = TransactionConstants.EMPLOYER_YEAR_TEXT[0];
                    }
                    else if (field.key === TransactionConstants.CONTRIBUTION_IRA) {
                        field.value =
                            Number(actualData[2].IRAContributionYear) === 0
                                ? TransactionConstants.CONTRIBUTION_OPTIONS[0]
                                : TransactionConstants.CONTRIBUTION_OPTIONS[1];
                    }
                }
                return null;
            });
        } else {
            dataObj.fields.map(fieldKey => {
                const field = fieldKey;
                if (field.key === TransactionConstants.CONTRIBUTION_IRA) {
                    field.value = TransactionConstants.NOT_ON_FILE_TEXT;
                }
                return null;
            });
        }
        return dataObj;
    };

    updatePurchaseData = (carddata, actualData) => {
        const data = carddata.map(dataKey => {
            const dataObj = { ...dataKey };
            let dataVal;
            if (dataObj.cardId === TransactionConstants.TRADE_TYPE_VALUE) {
                dataVal = this.populateTradeTypeValues(dataObj);
            } else if (dataObj.cardId === TransactionConstants.ACCOUNT_SELECTION) {
                dataVal = this.populateAccSelValues(dataObj, actualData);
            } else if (dataObj.cardId === TransactionConstants.VCM_FUNDS_TEXT) {
                dataVal = this.populateInvSelValues(dataObj, actualData);
            } else if (dataObj.cardId === TransactionConstants.DIVIDENDS_TEXT) {
                dataVal = this.populateDividendsValues(dataObj, actualData);
            } else if (dataObj.cardId === TransactionConstants.FUND_ACCOUNT_TEXT) {
                dataVal = this.populateFundAccValues(dataObj, actualData);
            } else if (dataObj.cardId === TransactionConstants.CONTRIBUTION_TEXT) {
                dataVal = this.populateIRAContributionValues(dataObj, actualData);
            }
            return dataVal;
        });

        this.setState({ cards: data });
    };



    authenticateBeforeSubmit = (e) => {
        if (isTokenAvailable() === null) {
            // if there is no token then show the token box for authentication
            this.props.authBox()
        } else {
            // Run the submit if you have the token in local storage 
            this.props.submitData(e)
        }

    }
    render() {
        const { cards } = this.state;
        const {
            onSwitchComponent,
            scrollToSection,
            tradingWindowData,
            pageIndex,
            paymentType,
            purchaseReducerData,
            setCancelPurchase,
        } = this.props;

        const isOffline = paymentType && (paymentType.check || paymentType.wireTransfer);

        const isInitialPurchase =
            !!purchaseReducerData &&
            purchaseReducerData.page1 &&
            purchaseReducerData.page1.fundOptionSelected &&
            purchaseReducerData.page1.fundOptionSelected.includes(TransactionConstants.INIT_TEXT);
        const isProportinateSelected = purchaseReducerData.page1.isProportinateSelected;

        const proportianteSelectedFundsList =  purchaseReducerData?.page1?.proportianteSelectedFundsList;

        return (
            <div id="#currentPageName" name={TransactionConstants.REVIEW_CONFIRM_TEXT} data-test="review-page">
                <div className="container">
                    {cards.map((item, index) => {
                        const ariaLabel = `Edit ${this.getCardHeading(item.cardHeading)}`;
                        return (
                            <VerifySection key={item.cardHeading}>
                                <SectionTitle>
                                    <TitleTxt>{this.getCardHeading(item.cardHeading)}</TitleTxt>
                                    {item.cardHeading != 'Account Selection' ? (
                                        <>
                                            {this.notSEPIRA(item.cardHeading) && item.cardId !== TransactionConstants.TRADE_TYPE_VALUE ? (
                                                <div tabIndex={index} role="link">
                                                    <EditImage src={EditIcon} alt="edit icon" />
                                                    <EditDiv
                                                        role="button"
                                                        onClick={scrollToSection(item.pageIndex, item.section)}
                                                        name={this.getCardHeading(item.cardHeading)}
                                                        aria-label={ariaLabel}
                                                        value={editText}
                                                    >
                                                        {editText}
                                                    </EditDiv>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : ('')}

                                </SectionTitle>
                                <Separator aria-hidden="true" />
                                {(item.cardId === TransactionConstants.VCM_FUNDS_TEXT && isProportinateSelected) ? (
                                    <>
                                        {proportianteSelectedFundsList && proportianteSelectedFundsList.map((data,index)=>(
                                        <SectionBody>
                                                
                                                <FlexRowDiv>
                                                    <FundField>{data.fundName}</FundField>
                                                </FlexRowDiv>

                                                <FlexRowDiv>
                                                    <Field>{TransactionConstants.FUND_ACCOUNT_NUMBER_TEXT}</Field>
                                                    <Value>{data.fundAccountNumber}</Value>
                                                </FlexRowDiv>

                                                <FlexRowDiv key={`${index}1`}>
                                                    <Field>Amount</Field>
                                                    <Value>{currencyFormatterFunction(Number(data.initialInvest).toFixed(2))}</Value>
                                                </FlexRowDiv>

                                                <FlexRowDiv key={`${index}2`}>
                                                    <Field>Monthly Investment</Field>
                                                    <Value>{notOnFile}</Value>
                                                </FlexRowDiv>

                                                <FlexRowDiv key={`${index}3`}>
                                                    <Field>Start Date</Field>
                                                    <Value>{notOnFile}</Value>
                                                </FlexRowDiv>
                                
                                    </SectionBody>
                                        ))}
                                    </>
                                ) : (
                                     <SectionBody>
                                     {item.cardId === TransactionConstants.VCM_FUNDS_TEXT ? (
                                         <FlexRowDiv>
                                             <FundField>{item.fundName}</FundField>
                                         </FlexRowDiv>
                                         
                                     ) : (
                                         ''
                                     )}
                                     {item.cardId === TransactionConstants.VCM_FUNDS_TEXT ? (
                                         <FlexRowDiv>
                                             <Field>{TransactionConstants.FUND_ACCOUNT_NUMBER_TEXT}</Field>
                                             <Value> {item.fundAccountNumber!= null && item.fundAccountNumber!='' ?item.fundAccountNumber : <FundFromDesc>{TransactionConstants.NEW_FUND}</FundFromDesc>}</Value>
                                         </FlexRowDiv>
                                         
                                     ) : (
                                         ''
                                     )}
                                     {item.fields.map((data, indexVal) => (
                                         <FlexRowDiv key={data.key + data.value + indexVal.toString()}>
                                             <Field>{data.key}</Field>
                                             <Value>{data.value}</Value>
                                         </FlexRowDiv>
                                     ))}
                                 </SectionBody>
                                )}
                            </VerifySection>
                        );
                    })}
                    {!tradingWindowData.canTrade && !isOffline && (
                        <WarningWindow>
                            <StyledInfoCol xs={12}>
                                <WCard
                                    buttonstyle={displayNoneStyle}
                                    cardtitletext={tradingWindowData.tradingClosureMsg.message}
                                    cardtextstyle={cardTextStyle}
                                    cardbodystyle={cardBodyStyle}
                                    cardtitlestyle={cardTitleStyle}
                                    linkcard="true"
                                    leftcornercard={cardBorderStyle}
                                    alt=""
                                    src=""
                                    selected
                                />
                            </StyledInfoCol>
                        </WarningWindow>
                    )}
                    {paymentType.check && isOffline && (
                        <WarningWindow>
                            <StyledInfoCol xs={12}>
                                <WCard
                                    buttonstyle={displayNoneStyle}
                                    cardtitletext={TransactionConstants.WARNING_WINDOW_CHECK_WIRE_TRANSFER}
                                    cardtextstyle={cardTextStyle}
                                    cardbodystyle={cardBodyStyle}
                                    cardtitlestyle={cardTitleStyle}
                                    linkcard="true"
                                    leftcornercard={cardBorderStyle}
                                    alt=""
                                    src=""
                                    selected
                                />
                            </StyledInfoCol>
                        </WarningWindow>
                    )}
                </div>
               
                <MainDiv>
                    <BtnWrap>
                        <div>
                            <WButton
                                variant="back"
                                buttontext={TransactionConstants.BACK_TEXT}
                                onClick={onSwitchComponent(false)}
                                buttonstyle={buttonstyle}
                                role="link"
                            />
                        </div>
                        <div>
                            <WButton
                                variant="cancel"
                                buttontext="Cancel"
                                id="cancelButton"
                                buttonstyle={assignObj(cancelButtonStyles)}
                                onClick={setCancelPurchase}
                            />
                        </div>
                        <div>
                            <WButton
                                buttontext={
                                    isInitialPurchase
                                        ? TransactionConstants.NEXT_TEXT
                                        : TransactionConstants.SUBMIT_TEXT
                                }
                                onClick={
                                    isInitialPurchase ? onSwitchComponent(true, this.state, pageIndex) : this.authenticateBeforeSubmit
                                }
                                data-test="submit-button"
                                buttonstyle={buttonstyle}
                                role="link"
                            />
                        </div>
                    </BtnWrap>
                </MainDiv>
            </div>
        );
    }
}

PurchseReview.propTypes = {
    onSwitchComponent: PropTypes.instanceOf(Function),
    scrollToSection: PropTypes.instanceOf(Function),
    pageIndex: PropTypes.number,
    savedState: PropTypes.instanceOf(Object),
    purchaseReducerData: PropTypes.instanceOf(Object),
    setTradingWarning: PropTypes.func,
    isIRA: PropTypes.func,
    tradingWindowData: PropTypes.instanceOf(Object),
    paymentType: PropTypes.instanceOf(Object),
    submitData: PropTypes.func,
    setCancelPurchase: PropTypes.func,
};

PurchseReview.defaultProps = {
    onSwitchComponent: () => { },
    scrollToSection: () => { },
    pageIndex: 0,
    savedState: () => { },
    purchaseReducerData: {},
    setTradingWarning: () => { },
    isIRA: () => { },
    tradingWindowData: {},
    paymentType: {},
    submitData: () => { },
    setCancelPurchase: () => { },
};
export const mapStateToProps = state => {
    return {
        purchaseReducerData: state.purchaseData,
    };
};

export default connect(mapStateToProps)(PurchseReview);
