import React, { Component } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types'
import { WButton, WBreadCrumb, WRadio, AccountInfoCard, PageHeading, Accordian, AccordianTitle } from '../../../../common';
import constAP from './constAP';
import constAmend from '../constAmend';
import {ReactComponent as TickMark} from '../../../../assets/PathIcon.svg';

const dollarSymbol = '$';
const percentageSymbol = '%';
const allSharesText = 'All Shares';
const assignObj = (obj) => { return obj; }

const TRANS_DASHBOARD_URL = '/transactionDashboard';
const breadCrumbURL = [
  { url: TRANS_DASHBOARD_URL, name: 'Home' },
  { url: TRANS_DASHBOARD_URL, name: 'Portfolio' },
  { url: TRANS_DASHBOARD_URL, name: 'Transactions' },
];

const dummyText = 'This is sample content paragraph';

// const flexDivStyle = { marginTop: '12px' };

const Description = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;

// const FlexRow = styled.div`
//     display: flex;
// `;

const StyledInput = styled.input`
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-left: 0.625rem;
`;

const AllShareLabel = styled.label`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #56565a;
    opacity: 1;
`;

const CardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 1.875rem;
    border: ${props => (props.selected ? '1px solid #8BC105' : '1px solid #D2D2D2')};
    background: ${props =>
        props.selected ? '#fafafa 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'};
    box-shadow: ${props => (props.selected ? '0px 0px 15px #8bc10529' : 'none')};
    height: 17.5rem;
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      border-bottom: 35px solid transparent;
      border-left: 35px solid #8BC105;
      position: absolute;
      top: -1px;
      left: -1px;
`;
const CardHead = styled.div`
    text-align: left;
    font: Bold 13px/15px Benton Sans;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
    padding: 1.375rem 2.5rem;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
`;
const CardBody = styled.div`
    display: flex;
    border-top: 1px solid #d2d2d2;
    opacity: 1;
    height: 100%;
`;
const CardBodyDetails = styled.div`
    display: flex;
    padding-left: 2.5rem;
    border-right: 1px solid #d2d2d2;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    opacity: 1;
    flex-grow: 0.4 ;
`;
const CardBodyInputSec = styled.div`
    display: flex;
    padding: 0px 2.188rem;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
`;
const CardDetailItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`;
const CardDetailItemTitle = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-bottom: 0.625rem;
`;

const CardDetailItemValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;

const AccountDetailsFlex = styled.div`
    display: flex;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding: 1.188rem 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b6;
    opacity: 1;
    text-align: left;
    font: Bold 18px/35px Roboto Slab;
    letter-spacing: 0.36px;
    color: #54565b;
`;
AccountDetailsFlex.displayName = 'AccountDetailsFlex';

const UpperCardBody = styled.div`
    display: flex;
    border-top: 1px solid #d2d2d2;
    opacity: 1;
    justify-content: space-around;
`;
const UpperBodyField = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin: 2.188rem 1.5rem 0px 0px;
`;
const UpperBodyValue = styled.div`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin: 0.625rem 1.5rem 2.188rem 0px;
`;
const UpperBodyValueDiv = styled.span`
    display: flex;
`;
const TickMarkDiv = styled.div`
    margin: 0.438rem 0.313rem 0px 0px;
`;

class AmendProceed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      block: false,
      buttonSubmit: 'Submit',
      disabled: false,
      type: 'button',
      variantSubmit: 'light',
      variantBack: 'outline',
      buttonCancel: 'Cancel',
      amendData: [{ id: 1, accName: '', accNumber: '', shareType: '', fundDetails: {} }],
      radioType: 'shares- 0',
      dollarValue: '',
      percentageValue: ''
    }
  }

  componentDidMount() {
    const {location} = this.props;
    try {
      if (location.state) {
        const { accIndex } = location.state;
        const temp = [{
          id: 1,
          accName: constAmend.accAvailable[+accIndex].accName,
          accNumber: `${constAmend.accAvailable[+accIndex].accNumber.value}`,
          shareType: constAmend.accAvailable[+accIndex].share.text,
          fundDetails: constAmend.fundDetails
        }]
        this.setState({
          amendData: temp
        })
      }
    } catch (e) {
      // console.log('Page opened directly, did not found for which account you want to do liquidation.');
    }
  }

  radioSelecthandler = (e) => {
    this.setState({
      radioType: e.target.id,
    })
    this.inputHandler(e);
  }

  cancelHandler = () => {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: '/amendTransaction'
      });
    }
  }

  submitHandler = () => {
    const { history } = this.props;
    if (history) {
      history.push({
        pathname: '/amendTransaction',
        state: {
          notification: true
        }
      });
    }
  }

  inputHandler = (e) => {
    if (e.target.id === 'shares- 0') {
      this.setState({
        dollarValue: '',
        percentageValue: ''
      })
    }
    if (e.target.id === 'dollar- 0') {
      this.setState({
        dollarValue: '5500',
        percentageValue: ''
      })
    }
    if (e.target.id === 'percentage- 0') {
      this.setState({
        dollarValue: '',
        percentageValue: '82'
      })
    }
  }

  bindComponent() {
    const {amendData, radioType, dollarValue, percentageValue} = this.state;
    return (
      <>
      <br/>
      <br/>
      <br/>
      <AccountInfoCard accountName={amendData[0].accName} accountNumber={amendData[0].accNumber} />
      <Accordian
        titleChild={<AccordianTitle accType="Liquidation Your Fund" />}
        titleSeperator
        btnProps={this.state}
      >
        <>
          <Description>{dummyText}</Description>
          {amendData.map((data, index) => {
            const { details = [], NAVdetails = [] } = data.fundDetails || {};
            const sharesRadioIndex = `shares- ${index}`;
            const dollarRadioIndex = `dollar- ${index}`;
            const percentageRadioIndex = `percentage- ${index}`;
            return (
              <CardContainer
              key={index.toString()}
              >
                <CardHead>{data.shareType}</CardHead>
                <UpperCardBody>
                    {NAVdetails.map((eachNavDetails, eachNavIndex) => {
                        return (
                            <div key={eachNavIndex.toString()}>
                                <UpperBodyField>{eachNavDetails.key}</UpperBodyField>
                                <UpperBodyValueDiv>
                                    {eachNavDetails.key === 'Last NAV (Previous day close)' && (
                                        <TickMarkDiv>
                                            <TickMark />
                                        </TickMarkDiv>
                                    )}
                                    <UpperBodyValue>{eachNavDetails.value}</UpperBodyValue>
                                </UpperBodyValueDiv>
                            </div>
                        );
                    })}
                </UpperCardBody>
                <CardBody>
                    <CardBodyDetails>
                        {details.map((eachDetail, eachIndex) => {
                            return (
                                <CardDetailItem key={eachIndex.toString()}>
                                    <CardDetailItemTitle>{eachDetail.key}</CardDetailItemTitle>
                                    <CardDetailItemValue>{eachDetail.value}</CardDetailItemValue>
                                </CardDetailItem>
                            );
                        })}
                    </CardBodyDetails>
                    <CardBodyInputSec>
                      {/* <FlexRow>
                        <div style={flexDivStyle}>
                        <WRadio
                              onClick={this.radioSelecthandler}
                              value='All Shares'
                              radioFor="liquidationAmt"
                              selected={radioType === sharesRadioIndex}
                              id={sharesRadioIndex}
                          />

                        </div>
                      </FlexRow>
                      <FlexRow>
                        <div style={flexDivStyle}>
                          <WRadio
                              onClick={this.radioSelecthandler}
                              value={dollarSymbol}
                              radioFor="liquidationAmt"
                              selected={radioType === dollarRadioIndex}
                              id={dollarRadioIndex}
                          />
                        </div>
                          <input
                              type="number"
                              value={dollarValue}
                              onChange={this.inputHandler}
                          />
                      </FlexRow>
                      <FlexRow>
                        <div style={flexDivStyle}>
                          <WRadio
                              onClick={this.radioSelecthandler}
                              value={percentageSymbol}
                              radioFor="liquidationAmt"
                              selected={radioType === percentageRadioIndex}
                              id={percentageRadioIndex}
                          />
                        </div>
                          <input
                              type="number"
                              value={percentageValue}
                              onChange={this.inputHandler}
                          />
                      </FlexRow> */}
                      <div style={assignObj({ display: 'flex'})}>
                        <WRadio
                          id={assignObj(`shares- ${index}`)}
                          value={allSharesText}
                          selected={radioType === sharesRadioIndex}
                          onClick={this.radioSelecthandler}
                        />
                
                      </div>
                      <div style={assignObj({ display: 'flex' })}>
                        <WRadio
                          id={assignObj(`dollar- ${index}`)}
                          value=''
                          selected={radioType === dollarRadioIndex}
                          onClick={this.radioSelecthandler}
                        />
                        <AllShareLabel>{dollarSymbol}</AllShareLabel>
                        <StyledInput
                          type="number"
                          value={dollarValue}
                          onChange={this.inputHandler}
                          style={assignObj({width:'130px', height:'50px'})}
                        />
                      </div>
                      <div style={assignObj({ display: 'flex' })}>
                        <WRadio
                          id={assignObj(`percentage- ${index}`)}
                          value=''
                          selected={radioType === percentageRadioIndex}
                          onClick={this.radioSelecthandler}
                        />
                        <AllShareLabel>{percentageSymbol}</AllShareLabel>
                        <StyledInput
                          type="number"
                          value={percentageValue}
                          onChange={this.inputHandler}
                          style={assignObj({width:'130px', height:'50px'})}
                        />
                      </div>
                    </CardBodyInputSec>
                </CardBody>                
              </CardContainer>
            )
          })}
        </>
      </Accordian>
      </>
    );
  }

  render() {
    const activeBreadCrumb = 'Amend';
    const { active, block, buttonSubmit, disabled, type, variantSubmit, variantBack, buttonCancel } = this.state;
    return (
      
        <div style={constAP.wrapperStyles}>
          <div className="container">
            <WBreadCrumb activeCrumb={activeBreadCrumb} breadCrumbItems={breadCrumbURL}/>
            <PageHeading>{constAP.amendHeadingText}</PageHeading>
            {this.bindComponent()}

            <WButton
              active={active}
              block={block}
              style={constAP.btnStyle}
              buttontext={buttonCancel}
              disabled={disabled}
              type={type}
              variant={variantBack}
              onClick={this.cancelHandler}
            />
            <hr />
            <WButton
              active={active}
              block={block}
              style={constAP.btnStyleSubmit}
              buttontext={buttonSubmit}
              disabled={disabled}
              type={type}
              variant={variantSubmit}
              buttonstyle={constAP.btnStyleSubmit}
              onClick={this.submitHandler}
            />
          </div>
        </div>
      
    )
  }
}

AmendProceed.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default AmendProceed
