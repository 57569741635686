import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import {
    WBreadCrumb,
    WSpinner,
} from "common";
import routeConstants from "routeConstants";
import { formatDate, replaceSpecialChar, encrypt, decrypt } from 'utils';
import CONST from 'modules/CustomerManagementModule/RelationShip/constants';
import PhoneCardComponent from 'modules/CustomerManagementModule/RelationShip/RelationshipInfoAdd/PhoneInfoAdd';
import EmailCardComponent from 'modules/CustomerManagementModule/RelationShip/RelationshipInfoAdd/EmailInfoAdd';
import PersonalInfoAdd from 'modules/CustomerManagementModule/RelationShip/RelationshipInfoAdd/PersonalInfoAdd';
import AddressComponent from 'modules/CustomerManagementModule/RelationShip/RelationshipInfoAdd/AddressInfoAdd';
import { BottomNavBar } from 'modules/CustomerManagementModule/components';
import {
    getFormAbandData,
    addFormFieldsListner,
    getErrorMessage,
    saveBtnLink,
    addFormAbandonUnloadListner,
    removeFormAbandonUnloadListner,
    trackFormAbandonData,
} from 'modules/CustomerManagementModule/utils';

const primaryBtnProps = {};
const cancelBtnProps = {};
const hideFlag = true;
class RelationshipInfoComponent extends Component {

    componentDidMount() {
        const { getCompositeLookUpData } = this.props;
        const payLoad = this.getLookUpPayload();
        if(payLoad.length){
            getCompositeLookUpData(payLoad);
        }
        addFormAbandonUnloadListner();
    }
    
    componentDidUpdate() {
        addFormFieldsListner();
        const { profileRelationshipData, clearRelationActions, history, analyzeClickEvent, linkResponseError } = this.props;
        const { isError, isSuccess, message } = profileRelationshipData;
        if (isError) {
            const errorTxt = getErrorMessage(message);
            saveBtnLink(`cms_basicinformation_addpersonalrelationshipInfo_save`, analyzeClickEvent);
            linkResponseError(errorTxt);
            clearRelationActions();
        }
        if (isSuccess) {
            saveBtnLink(`cms_basicinformation_addpersonalrelationshipInfo_save`, analyzeClickEvent);
            history.push(routeConstants.basicinformation);
        }
    }

    componentWillUnmount() {
        const { profileRelationshipData } = this.props;
        const { isSuccess } = profileRelationshipData;
        if(!isSuccess){
            const digitalData = getFormAbandData();
            trackFormAbandonData(digitalData);
        }
        const { resetAddressInfo, setInitialRelationState } = this.props;
        resetAddressInfo('mailingAddress');
        resetAddressInfo('physicalAddress');
        setInitialRelationState();
        removeFormAbandonUnloadListner();
    }

    getLookUpPayload = () => {
        const { masterLookupStateData } = this.props;
        const {
            marital_status: maritalStatus,
            prefix,
            suffix,
            gender,
            citizenship,
            country_2_lettercode: country,
            relationship: relationToCustomer,
            citizenship_proof: citizenshipProof,
            address_type: addressType
        } = masterLookupStateData;
        const payLoad = [];
        if (!maritalStatus) payLoad.push('marital_status');
        if (!prefix) payLoad.push('prefix');
        if (!suffix) payLoad.push('suffix');
        if (!gender) payLoad.push('gender');
        if (!citizenship) payLoad.push('citizenship');
        if (!country) payLoad.push('country_2_lettercode');
        if(!relationToCustomer) payLoad.push('relationship');
        if(!citizenshipProof) payLoad.push('citizenship_proof');
        if (!addressType) payLoad.push('address_type');  
        return payLoad;
    }

    validateRelationInfo = () => {
    const { profileRelationshipData, setFamilyRelationError, setFirstNameError, setLastNameError,
        setDateOfBirthError, setGenderError, setMaritalStatusError, setCitizenShipError } = this.props; // setPersonHaveSSNError
        const { 
            personalInformation={},
        } = profileRelationshipData;
        const {
            firstName = '',
            familyRelationShip = '',
            lastName = '',
            dateOfBirth = '',
            gender = '',
            maritalStatus = '',
            citizen = '',
        } = personalInformation || {};
        if(!familyRelationShip) setFamilyRelationError(CONST.FAMILY_RELATION_TO_YOU_ERROR);
        if(!firstName) setFirstNameError(CONST.FIRST_NAME_ERROR);
        if(!lastName) setLastNameError(CONST.LAST_NAME_ERROR);
        if(!dateOfBirth) setDateOfBirthError(CONST.DATEOFBIRTH_ERROR);
        if(!gender) setGenderError(CONST.GENDER_ERROR);
        if(!maritalStatus) setMaritalStatusError(CONST.MARITAL_STATUS_ERROR);
        if(!citizen) setCitizenShipError(CONST.CITIZENSHIP_ERROR);   
        this.validateCitizenshipInfo(); 
        // this.validateCitizenshipInfo2();    
    }

    validateCitizenshipInfo = () => {
        const { profileRelationshipData, setCitizenShipCountryError } = this.props; // setPersonHaveSSNError
            const { 
                personalInformation={},
            } = profileRelationshipData;
            const {                
                citizen = '',
                countryOfCitizen = '',                
            } = personalInformation;           
            if((citizen === 'Non U.S') && (!countryOfCitizen)) setCitizenShipCountryError(CONST.COUNTRY_ERROR);           
        }        
    
    addRelationInfo = () => {
        const { profileRelationshipData, updateProfileRelationship, mailingAddressData, physicalAddressData, analyzeClickEvent } = this.props;
        const { 
            personalInformation={},
            relationEmailInformation={},
            relationPhoneInformation={},
            isPhysicalSameAsMailingAddr=false,
            mailingAddressType='', 
            physicalAddressType='',
        } = profileRelationshipData;
        const checkPhysicalAddressType = isPhysicalSameAsMailingAddr ? mailingAddressType : physicalAddressType;
        const { USPSVerifiedAddress={} } = mailingAddressData;
        const { USPSVerifiedAddress: USPSVerifiedPhysicalAddress={} } = physicalAddressData;
        const {
            addressLine1 = '',
            addressLine2 = '',
            city = '',
            state = '',
            zip = '',
        } = USPSVerifiedAddress;
        const {
            addressLine1:phyaddressLine1 = isPhysicalSameAsMailingAddr ? addressLine1: '',
            addressLine2:phyaddressLine2 = isPhysicalSameAsMailingAddr ? addressLine2: '',
            city:phycity = isPhysicalSameAsMailingAddr ? city: '',
            state:phystate = isPhysicalSameAsMailingAddr ? state: '',
            zip:phyzip = isPhysicalSameAsMailingAddr ? zip: '',
        } = USPSVerifiedPhysicalAddress;
        const {
            firstName = '',
            familyRelationShip = '',
            lastName = '',
            middleName = '',
            preFix = '',
            suFix = '',
            dateOfBirth = '',
            gender = '',
            maritalStatus = '',
            citizen = '',
            countryOfCitizen = '',
            // proofOfCitizen = '',
            // passportNumber = '',
            // passportAuthority = '',
            // residentNumber = '',
            // expirationDate = '',
            // personHaveSSN = '',
            ssn = '',
        } = personalInformation || {};
        const {
            countryCode = '',
            phoneNumber = '',
            isMobile = false,
            secondaryCountryCode = '',
            secondaryPhoneNumber = '',
            secondaryIsMobile = false
        } = relationPhoneInformation || {};
        const {
            primaryEmail = '',
            secondaryEmail = '',
        } = relationEmailInformation ||{};        
        if(this.isAnythingChanged()){
            (async () => {
                const newssn = await encrypt(ssn);
                const newDOB = await encrypt(dateOfBirth);
                const payLoad = {
                    role : "Family",
                    familyMembersRelationshipToYou: familyRelationShip,
                    firstName,
                    lastName,
                    middleIntial:middleName,
                    prefix:preFix,
                    suffix:suFix,
                    dateOfBirth:newDOB,
                    gender,
                    maritalStatus,
                    citizenship:citizen,
                    ssnTin:newssn,
                    countryOfCitizenship:countryOfCitizen,
                    // residencyProof:proofOfCitizen,
                    // permanentResidentNumber:residentNumber,
                    // passportNumber,
                    // passportIssuingAuthority:passportAuthority,
                    // passportExpirationDate:expirationDate,
                    // isSsnAvailable:personHaveSSN,
                    phoneUpdate:[
                        {
                            phoneNumber: replaceSpecialChar(phoneNumber),
                            phoneCountryCode: countryCode,
                            isMobile,
                            isPrimary:true,
                        },
                        {
                            phoneNumber: replaceSpecialChar(secondaryPhoneNumber),
                            phoneCountryCode: secondaryCountryCode,
                            isMobile: secondaryIsMobile,
                            isPrimary:false,
                        }
                    ],
                    primaryEmail,
                    secondaryEmail,
                    addressUpdate: [
                        {
                            addressType:mailingAddressType,
                            addressLine1,
                            addressLine2,
                            zip,
                            city,
                            state,
                            isPhysicalAddress:false,
                            isMailingAddress:true
                        },
                        {
                            addressType:checkPhysicalAddressType,
                            addressLine1:phyaddressLine1,
                            addressLine2:phyaddressLine2,
                            zip:phyzip,
                            city:phycity,
                            state:phystate,
                            isPhysicalAddress:true,
                            isMailingAddress:false
                        }
                    ]
                };
                updateProfileRelationship(payLoad, decrypt);
            })();     
        }else {
            this.validateRelationInfo();
        }
        analyzeClickEvent({
            link: `cms_basicinformation_addrelationshipInfo_save`
        })
    }
    
    cancelRelationInfo = () => {
        const {  history, analyzeClickEvent } = this.props;
        analyzeClickEvent({
            link: `cms_basicinformation_addrelationshipInfo_cancel`
        })
        history.push(routeConstants.basicinformation);
    }
    
    RestrictCurrentDate = () => {
    	const currDate = new Date();
    	const currYear = currDate.getUTCFullYear();
    	const CurrMonth = currDate.getUTCMonth();
    	const currDay = currDate.getUTCDate();
    	const prevDay = currDay -1;
    	return new Date(currYear, CurrMonth, prevDay);
    }
    
    // getMaxDate = () => {
    // 	const currDate = new Date();
    // 	const currYear = currDate.getFullYear();
    // 	const maxYear = currYear + 9;
    // 	return new Date(maxYear, 11);
    // }
    
    isAnythingChanged = () => {
        const { profileRelationshipData } = this.props;
        const { 
            personalInformation={},
        } = profileRelationshipData;
        const {
            firstName = '',
            familyRelationShip = '',
            lastName = '',
            dateOfBirth = '',
            gender = '',
            maritalStatus = '',
            citizen = '',
            countryOfCitizen = '',            
            ssn = '',
            familyRelatioshipError = '',
            firstNameError = '',
            lastNameError = '',
            // dateOfBirthError = '',
            genderError = '',
            maritalStatusError = '',
        } = personalInformation || {};
        const ValidDOB = formatDate(this.RestrictCurrentDate()) >= formatDate(dateOfBirth);
        
        // const validexpirationDate = (formatDate(this.getMaxDate()) >= formatDate(expirationDate)) || (formatDate(expirationDate) <= formatDate(new Date()));
        
        const checkValidSSN = ssn ? (ssn && (ssn.length>=9)) : true;
        const validBasicFields =  !!(firstName && familyRelationShip && lastName && dateOfBirth && ValidDOB && gender && maritalStatus && !firstNameError && !familyRelatioshipError && !lastNameError  && !genderError && !maritalStatusError);
        const validFieldsCitizenasUS =  (citizen === 'U.S');
        const validFieldsCitizenasNonUs =  !!((citizen === 'Non U.S') && countryOfCitizen);
                
        return (validBasicFields && checkValidSSN && (validFieldsCitizenasUS || validFieldsCitizenasNonUs));
    }
    
    render() {
        const { profileRelationshipData } = this.props;
        
        const loading = profileRelationshipData.isLoading;

        primaryBtnProps.onClick = this.addRelationInfo;
        cancelBtnProps.onClick = this.cancelRelationInfo;
        
        return(
					<>
						{loading && <WSpinner loading={loading} />}
						<Container>								
								<div className="container">
										<WBreadCrumb
												breadCrumbItems={CONST.bredCrumItmes}
												activeCrumb={CONST.breadCrumName}
										/>
										<PersonalInfoAdd/>
										<PhoneCardComponent/>
										<EmailCardComponent/>
										<AddressComponent/>
								</div>
						</Container>
						<BottomNavBar
                            hideBack
                            showCancelBtn
                            primaryBtnText={CONST.SAVE}
                            primaryBtnProps={primaryBtnProps}
                            cancelBtnProps={cancelBtnProps}
                            hidePrimaryBtn = {hideFlag}
						/>
					</>
				);
    }
}

RelationshipInfoComponent.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    profileRelationshipData: PropTypes.instanceOf(Object),
    mailingAddressData: PropTypes.instanceOf(Object),
    physicalAddressData: PropTypes.instanceOf(Object),
    updateProfileRelationship: PropTypes.func,
    clearRelationActions: PropTypes.func,
    setFamilyRelationError: PropTypes.func,
    setFirstNameError: PropTypes.func,
    setLastNameError: PropTypes.func,
    setDateOfBirthError: PropTypes.func,
    setGenderError: PropTypes.func,
    setMaritalStatusError: PropTypes.func,
    getCompositeLookUpData: PropTypes.func,
    masterLookupStateData: PropTypes.instanceOf(Object),
    resetAddressInfo: PropTypes.func,
    setInitialRelationState: PropTypes.func,
    setCitizenShipError: PropTypes.func,
    setCitizenShipCountryError: PropTypes.func,    
    // setPersonHaveSSNError: PropTypes.func,
    analyzeClickEvent: PropTypes.func, 
    linkResponseError: PropTypes.func
}

RelationshipInfoComponent.defaultProps = {
    profileRelationshipData: {},
    mailingAddressData: {},
    physicalAddressData: {},
    updateProfileRelationship: () => {},
    clearRelationActions: () => {},
    setFamilyRelationError: () => {},
    setFirstNameError: () => {},
    setLastNameError: () => {},
    setDateOfBirthError: () => {},
    setGenderError: () => {},
    setMaritalStatusError: () => {},
    getCompositeLookUpData: () => {},
    masterLookupStateData: {},
    resetAddressInfo: () => {},
    setInitialRelationState: () => {},
    setCitizenShipCountryError: () => {},    
    // setPersonHaveSSNError: () => {},
    setCitizenShipError: () => {},
    analyzeClickEvent: () => {},
    linkResponseError: () => {}
}


export default RelationshipInfoComponent;