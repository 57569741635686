import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import currencyFormatter from 'currency-formatter';
import arrowUp from '../../../assets/Icon_Price_Up.png';
import arrowDown from '../../../assets/Icon_Price_Down.png';
import { addCommasToNumberStr } from '../../../utils';
import FundSummary from './FundSummary';
import TransactionTableHeader from './TransactionTableHeader';
import * as ActionTypes from '../../../shared/ReduxConstants/ServiceActionConstants';
import { getTokenFromSession } from 'shared/Helpers/Utils';
import WIcon from '../../../common/WIcon/WIcon';
import { setTokenWithExpiry, isTokenAvailable } from '../../../common/Authenticate/session';
import styles from '../../../common/CommonStyles';
import { getValidValueOf } from 'commonHelper/CommonHelperFunctions';
const defaultColor = 'rgb(73, 73, 74)';
const S = {
    th: {
        borderBottom: '2px solid #8BC105',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#4494A',
        textAlign: 'right',
    },
    td: {
        fontSize: '14px',
        lineHeight: '17px',
        color: defaultColor,
        maxWidth: '80px',
        paddingLeft: '24px',
    },
    nd: {
        fontSize: '14px',
        lineHeight: '17px',
        color: defaultColor,
        maxWidth: '80px',
        paddingLeft: '34px',
    },

    ticker: {
        fontSize: '12px',
        lineHeight: '14px',
        width: '5%',
    },
    fundName: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '18px',
        color: defaultColor,
        textAlign: 'left',
        width: '30%',
        marginLeft: '-7%',
    },
    rectangle: {
        position: 'relative',
        left: '6.5px',
        top: '3px',
        width: '55px',
        height: '26px',
        background: '#004A98 0% 0% no-repeat padding-box',
        opacity: '1',
        color: '#ffffff',
        fontSize: '12px',
        cursor: 'pointer',
    },
    p: {
        top: '6px',
        left: 'calc(50% - 21px)',
        width: '42px',
        height: '14px',
        textAlign: 'center',
        font: 'Medium 12px/14px Benton Sans',
        letterSpacing: '0',
        color: '#FFFFFF',
        opacity: '1',
        margin: 0,
        position: 'absolute',
    },
    arrowStyles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    basis1: {
        flexBasis: '31%',
        height: '35px',
    },
    basis2: {
        flexBasis: '30%',
    },
    noValue: {
        display: 'flex',
        fontWeight: 'bold',
        float: 'right',
        paddingRight: '3px',
        fontSize: '12px',
        color: 'black',
    },
    dayChange: {
        color: '#0D823C',
    },
    iconStyle: {
        height: '13px',
        width: '14px',
    },
    fundDetailTableCaption: 'Fund Detail Table',
};
const MenuOptionsPopup = styled.div`
    cursor: pointer;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    opacity: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // top: 3.7rem;
    left: 0.629rem;
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 10px solid #ffffff;
        top: -0.625rem;
        left: 3.75rem;
    }
`;
const TickerButton = styled.button`
    position: 'relative',
    background: none;
    border: none;
    left: '6.5px',
    top: '3px',
    width: '55px',
    height: '26px',
    background: '#004A98 0% 0% no-repeat padding-box',
    opacity: '1',
    color: '#ffffff',
    fontSize: '12px',
    cursor: 'pointer',
    paddingLeft: '7px'
`;
const Flex = styled.div`
    display: flex;
`;
const MenuItem = styled(Flex)`
    display: flex;
    cursor: pointer;
    padding: 20px;
`;
const MenuOptions = styled.div`
    margin-left: 0.3125rem;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1em;
    line-height: 20px;
    letter-spacing: 0;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
`;
const MenuIcon = styled.span`
    width: 1rem;
    height: 1.25rem;
    background: url(${props => props.url}) no-repeat bottom left;
`;
const MenuOptionsBorder = styled.span`
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    margin-right: 20px;
`;
const assignObj = obj => obj;

const StyledCol = styled(Col)`
    font-family: benton-sans, sans-serif;
    font-size: 12px;
    line-height: 21px;
    padding-top: 5px;
    text-align: right;
    @media (max-width: 767px) {
        text-align: left;
    }
`;
const DesktopWIcon = styled(WIcon)`
    @media (max-width: 767px) {
        display: none;
    }
`;

const StyledColGainLoss = styled(Col)`
    margintop: -15px;
    font-family: benton-sans, sans-serif;
    font-size: 12px;
    line-height: 21px;
    text-align: right;
    padding-right: 15px;
    @media (max-width: 767px) {
        text-align: left;
    }
`;

const StyledColNavChange = styled(Col)`
    font-family: benton-sans, sans-serif;
    font-size: 12px;
    line-height: 21px;
    
    text-align: center;
    // display: flex;
    // flex-direction:column;
    padding-left: 0px !important;
    padding-right: 0px !important;

    @media (max-width: 767px) {
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
    }
`;
const StyledColLeft = styled(Col)`
    font-family: benton-sans, sans-serif;
    font-size: 12px;
    line-height: 20px;
    padding-top: 5px;
    text-align: left;
`;

const StyledRow = styled(Row)`
    width: 100%;
    // font-size: 12px;
    // height: 50px;
    line-height: 50px;
    margin-top: 10px;
    letter-spacing: 0px;
    justify-content: space-between;
    margin-left: 0.1%;
    border-bottom: '2px solid #8BC105';
    color: #49494a;
`;

const fundNameStyles = {
    fontWeight: 'bold',
    color: '#004a98',
    cursor: 'pointer',
};

class TransactionTableList extends Component {
    static TableBody(props) {
        const {
            tHolder,
            onClickFund,
            onNavigate,
            onPurchaseClickFund,
            onLiquidationClickFund,
            onExchangeClickFund,
            handleFundSummary,
            menuArray,
            allowedTransactions,
            tickerMenuItemRefs
        } = props;
        const Key = (item, index) => {
            return `${item}-${index}`;
        };

        const changeIsNegative = oneDayChangePercentage => {
            return oneDayChangePercentage && oneDayChangePercentage.includes('-') && oneDayChangePercentage !== '-';
        };

        const getUnrealizedGainsValue = (unrealizedGainsNagetive, th, truncatedUnrealizedGainsValue) => {
            let unrealizedGainsValue 
            if (!unrealizedGainsNagetive && th.unrealizedGains !== '-') {
                
                unrealizedGainsValue = `${currencyFormatter.format(truncatedUnrealizedGainsValue, {
                    locale: 'en-US',
                })}`;
            } else if (unrealizedGainsNagetive && th.unrealizedGains !== '-') {
                if(typeof truncatedUnrealizedGainsValue == 'string' &&  truncatedUnrealizedGainsValue.includes('-'))
                {
                    unrealizedGainsValue = `${currencyFormatter.format(truncatedUnrealizedGainsValue, {
                        locale: 'en-US',
                    })}`;
                }
                else{
                    unrealizedGainsValue = `-${currencyFormatter.format(truncatedUnrealizedGainsValue, {
                        locale: 'en-US',
                    })}`;
                }
              
            } else {
                unrealizedGainsValue = '';
            }
           
            return unrealizedGainsValue 
            
        }

        const getOneDayChangePriceValue = (isNegative, oneDayChangePriceTruncatedValue) => {

            const zeroValue = '$0.00';
            let oneDayChangePriceValue;

            if (!isNegative) {
                oneDayChangePriceValue = `${currencyFormatter.format(oneDayChangePriceTruncatedValue, {
                    locale: 'en-US',
                })}`;
            } else if (isNegative) {
                oneDayChangePriceValue = `-${currencyFormatter.format(oneDayChangePriceTruncatedValue, {
                    locale: 'en-US',
                })}`;
            } else {
                oneDayChangePriceValue = (
                    <span
                        style={assignObj({
                            ...S.noValue,
                        })}
                    >
                        {zeroValue}
                    </span>
                );
            }

            return oneDayChangePriceValue
        }

        const getOneDayAccountChangeValue = (isNegativeAccNav, oneDayAccountChange) => {
            let oneDayAccountChangeValue;

            if (!isNegativeAccNav) {
                oneDayAccountChangeValue = `${currencyFormatter.format(oneDayAccountChange, {
                    locale: 'en-US',
                })}`;
            } else if (isNegativeAccNav) {
                oneDayAccountChangeValue = `-${currencyFormatter.format(oneDayAccountChange, {
                    locale: 'en-US',
                })}`;
            } else {
                oneDayAccountChangeValue = '$0.00';
            }
 
            return oneDayAccountChangeValue
        }


        const unrealizedGainsSection = (th, match) => {

            const upDownArrow = changeIsNegative(th.unrealizedGains) ? (
                <span className="arrowUpAndDown">
                    <DesktopWIcon alt="decreased by" src={arrowDown} fontawesomestyle={styles.tableIconStyle} />
                </span>
            ) : (
                <span className="arrowUpAndDown">
                    <DesktopWIcon alt="increased by" src={arrowUp} size="2x" fontawesomestyle={styles.tableIconStyle} />
                </span>
            );


                        
            const truncatedUnrealizedGains = match ? parseFloat(match[1]) : th.unrealizedGains;
            const truncatedUnrealizedGainsValue = th.unrealizedGains !== '-' ? truncatedUnrealizedGains : '-';
            const unrealizedGainsArrow = th.unrealizedGains !== '-' ? upDownArrow : '';
            const unrealizedGainsNagetive = changeIsNegative(th.unrealizedGains);
            let unrealizedGainsValue = getUnrealizedGainsValue(unrealizedGainsNagetive, th, truncatedUnrealizedGainsValue)


            return(
                <>
                    <span style={S.basis1}>{unrealizedGainsArrow}</span>
                    {unrealizedGainsNagetive ? (
                        <span
                            style={assignObj({
                                ...S.basis2,
                                color: '#C71C2C',
                            })}
                        >
                            {unrealizedGainsValue}
                        </span>
                    ) : (
                        ''
                    )}
                    {!unrealizedGainsNagetive ? (
                        <span
                            style={assignObj({
                                ...S.basis2,
                                color: '#0D823C',
                            })}
                        >
                            {unrealizedGainsValue}
                        </span>
                    ) : (
                        ''
                    )}
                    {th.unrealizedGains === '-' ? <span>{'$0.00'}</span> : ''}
                
                </>
            )
        }

        const showDesktopIcon = (isNegative) => {

            return(
                <>
                    {isNegative ? (
                        <span className="arrowUpAndDown">
                            <DesktopWIcon
                                alt="decreased by"
                                src={arrowDown}
                                fontawesomestyle={styles.tableIconStyle}
                            />
                        </span>
                    ) : (
                        <span className="arrowUpAndDown">
                            <DesktopWIcon
                                alt="increased by"
                                src={arrowUp}
                                size="2x"
                                fontawesomestyle={styles.tableIconStyle}
                            />
                        </span>
                    )}
                </>
            )

        }

        return (
            <>
                 {tHolder.map((th, index) => {
                    const isNegative = changeIsNegative(th.oneDayChangePrice);
                    const isNegativeAccNav = changeIsNegative(th.oneDayChangeInCurrentValue);
                    let match = th.totalShares.toString().match('(\\d+\\.\\d{3})(\\d)');
                    const truncatedTotalShares = getValidValueOf(match, parseFloat(match?.[1]), th?.totalShares);

                    const trancateTwoDecimalReg = '(\\d+\\.\\d{2})(\\d)';
                    const emptyValue = '-';

                    // oneDayChangePriceTruncatedValue
                    match = th.oneDayChangePrice.toString().match(trancateTwoDecimalReg);
                    const oneDayChangePriceTruncatedValue = getValidValueOf(match, parseFloat(match?.[1]), th?.oneDayChangePrice);

                    let oneDayChangePriceValue = getOneDayChangePriceValue(isNegative, oneDayChangePriceTruncatedValue)

                    // oneDayAccountChangeValue
                    match = th.oneDayChangeInCurrentValue.toString().match(trancateTwoDecimalReg);
                    const oneDayAccountChange = getValidValueOf(match, parseFloat(match?.[1]), th?.oneDayChangeInCurrentValue);

                    let oneDayAccountChangeValue = getOneDayAccountChangeValue(isNegativeAccNav, oneDayAccountChange);

                    
                    match = th.oneDayChangePercentage.toString().match(trancateTwoDecimalReg);
                    const truncatedOneDayChangePercentage = getValidValueOf(match, parseFloat(match?.[1]), th?.oneDayChangePercentage);
                    const truncatedCurrentValue = th.currentValue ? Math.round(th.currentValue * 100) / 100 : 0;

                    match = th.totalCost.toString().match(trancateTwoDecimalReg);
                    let totalCostValue;
                    const truncatedTotalCost = getValidValueOf(match, parseFloat(match?.[1]), th?.totalCost);
                    if (th.totalCost !== '-') {
                        totalCostValue = currencyFormatter.format(truncatedTotalCost, {
                            locale: 'en-US',
                        });
                    } else {
                        totalCostValue = emptyValue;
                    }

                    match = th.unrealizedGains.toString().match(trancateTwoDecimalReg);

                    return (
                        <StyledRow
                            data-testid={Key('data-test-id-th-table', index)}
                            key={Key('th-table-key', index)}
                            className="transactTable"
                            scope="row"
                            role="row"
                        >
                            <StyledColLeft sm={1} onClick={onClickFund(index)} role="cell">
                                <TickerButton
                                    id="tickerButton"
                                    role="button"
                                    type="button"
                                    aria-haspopup="true"
                                    aria-controls="tickerMenuContainer"
                                    aria-live="polite"
                                    aria-expanded={menuArray[+index] ? 'true' : 'false'}
                                    className="rectangle"
                                    style={S.rectangle}
                                >
                                    {th.ticker}
                                </TickerButton>
                                {menuArray[+index] && (
                                    <MenuOptionsPopup
                                        aria-activedescendant="tickerButton"
                                        aria-labelledby="tickerButton"
                                        id="tickerMenuContainer"
                                        aria-orientation="vertical"
                                        role="menu"
                                    >
                                        {allowedTransactions.includes('Buy') && (
                                            <>
                                                <MenuItem
                                                    ref={tickerMenuItemRefs}
                                                    onKeyDown={onNavigate(index)}
                                                    role="menuitem"
                                                    tabIndex="0"
                                                    id="buytradeButtonMenuItem"
                                                    aria-label="Buy"
                                                    onClick={onPurchaseClickFund(index)}
                                                >
                                                    <MenuIcon />
                                                    <MenuOptions
                                                        id="buytradeButtonMenuOptions"
                                                        style={{ minWidth: '6.2rem' }}
                                                    >
                                                        {'Buy'}
                                                    </MenuOptions>
                                                </MenuItem>
                                                <MenuOptionsBorder />
                                            </>
                                        )}
                                        {allowedTransactions.includes('Sell') && (
                                            <>
                                                <MenuItem
                                                    ref={tickerMenuItemRefs}
                                                    onKeyDown={onNavigate(index)}
                                                    role="menuitem"
                                                    tabIndex="0"
                                                    id="selltradeButtonMenuItem"
                                                    aria-label="Sell"
                                                    onClick={onLiquidationClickFund(index)}
                                                >
                                                    <MenuIcon />
                                                    <MenuOptions
                                                        id="selltradeButtonMenuOptions"
                                                        style={{ minWidth: '6.2rem' }}
                                                    >
                                                        {'Sell'}
                                                    </MenuOptions>
                                                </MenuItem>
                                                <MenuOptionsBorder />
                                            </>
                                        )}
                                       {allowedTransactions.includes('Exchange') && (
                                            <>
                                                <MenuItem
                                                    ref={tickerMenuItemRefs}
                                                    onKeyDown={onNavigate(index)}
                                                    role="menuitem"
                                                    tabIndex="0"
                                                    id="exchangetradeButtonMenuItem"
                                                    aria-label="Exchange"
                                                    onClick={onExchangeClickFund(index)}
                                                >
                                                    <MenuIcon />
                                                    <MenuOptions
                                                        id="exchangetradeButtonMenuOptions"
                                                        style={{ minWidth: '6.2rem' }}
                                                    >
                                                        {'Exchange'}
                                                    </MenuOptions>
                                                </MenuItem>
                                                <MenuOptionsBorder />
                                            </>
                                        )}
                                        <MenuItem
                                            ref={tickerMenuItemRefs}
                                            onKeyDown={onNavigate(th.fundNumber)}
                                            tabIndex="0"
                                            role="menuitem"
                                            id="fundSummaryButtonMenuItem"
                                            aria-label="View Details"
                                            onClick={handleFundSummary(th.fundNumber)}
                                        >
                                            <MenuIcon />
                                            <MenuOptions
                                                id="fundSummaryButtonMenuOptions"
                                                style={{ minWidth: '6.2rem' }}
                                            >
                                                {'View Details'}
                                            </MenuOptions>
                                        </MenuItem>
                                        <MenuOptionsBorder /> 
                                    </MenuOptionsPopup>
                                )}
                            </StyledColLeft>
                            <StyledColLeft sm={2} onClick={onClickFund(index)} style={assignObj(fundNameStyles)}>
                                {th.fundName}
                            </StyledColLeft>
                            <StyledCol sm={1}>{th.fundAccountNumber ? th.fundAccountNumber : ''}</StyledCol>
                            <StyledCol sm={1} role="cell" className="fn-rtn text-right p-0">
                                {th.nav ? currencyFormatter.format(Number(th.nav).toFixed(2), { locale: 'en-US' }) : ''}
                            </StyledCol>
                            <StyledColNavChange md={1} role="cell" className='text-right'>
                                {isNegative !== null && th.oneDayChangePercentage !== '-' ? (
                                    <span style={S.basis1}>
                                       {showDesktopIcon(isNegative)}
                                    </span>
                                ) : (
                                    <span> </span>
                                )}
                                {isNegative !== null && th.oneDayChangePercentage !== '-' ? (
                                    <span style={assignObj({ ...S.basis2, color: isNegative ? '#C71C2C' : '#0D823C' })}>
                                        {oneDayChangePriceValue}
                                    </span>
                                ) : (
                                    <span
                                        style={assignObj({
                                            ...S.basis2,
                                            color: 'black',
                                        })}
                                    >
                                        {oneDayChangePriceValue}
                                    </span>
                                )}
                                <br />

                                {th.oneDayChangePercentage !== '-' ? (
                                    <span style={assignObj({ ...S.basis2, color: isNegative ? '#C71C2C' : '#0D823C' })}>
                                        {truncatedOneDayChangePercentage}%
                                    </span>
                                ) : (
                                    <span>0.0%</span>
                                )}
                            </StyledColNavChange>
                            <StyledCol sm={1} className='text-right p-0'>
                                {addCommasToNumberStr(Number(truncatedTotalShares).toFixed(3))}
                            </StyledCol>
                            <StyledCol sm={1} className='text-right p-0'>
                                {currencyFormatter.format(truncatedCurrentValue, {
                                    locale: 'en-US',
                                })}
                            </StyledCol>
                            <StyledColNavChange md={1} className='text-right'>
                                {isNegativeAccNav !== null && th.oneDayChangePercentage !== '-' ? (
                                    <span style={S.basis1}>
                                        {isNegativeAccNav ? (
                                            <span className="arrowUpAndDown">
                                                <DesktopWIcon
                                                alt="decreased by"
                                                src={arrowDown}
                                                fontawesomestyle={styles.tableIconStyle}
                                                />
                                            </span>
                                        ) : (
                                            <span className="arrowUpAndDown">
                                                <DesktopWIcon
                                                alt="increased by"
                                                src={arrowUp}
                                                size="2x"
                                                fontawesomestyle={styles.tableIconStyle}
                                                />
                                            </span>
                                        )}
                                    </span>
                                ) : (
                                    <span> </span>
                                )}
                                {isNegativeAccNav !== null && th.oneDayChangePercentage !== '-' ? (
                                    <span
                                        style={assignObj({
                                            ...S.basis2,
                                            color: isNegativeAccNav ? '#C71C2C' : '#0D823C',
                                        })}
                                    >
                                        {oneDayAccountChangeValue}
                                    </span>
                                ) : (
                                    <span
                                        style={assignObj({
                                            ...S.basis2,
                                            color: 'black',
                                        })}
                                    >
                                        {oneDayAccountChangeValue}
                                    </span>
                                )}
                                <br />
                                {th.oneDayChangePercentage !== '-' ? (
                                    <span
                                        style={assignObj({
                                            ...S.basis2,
                                            color: isNegativeAccNav ? '#C71C2C' : '#0D823C',
                                        })}
                                    >
                                        {truncatedOneDayChangePercentage}%
                                    </span>
                                ) : (
                                    <span>0.0%</span>
                                )}
                            </StyledColNavChange>
                            <StyledCol sm={1} className='text-right p-0'>
                                {/*  This needs to be updated to  totalCost */}
                                {totalCostValue}
                            </StyledCol>
                            <StyledColGainLoss md={2} role="cell">
                               
                                {unrealizedGainsSection(th, match)}

                            </StyledColGainLoss>
                            {/* <StyledColNavChange sm={3}>&nbsp;</StyledColNavChange> */}
                            {/* <StyledCol sm={1}>
                                {/* COST BASIS */}
                            {/*   {th.costBasis !== '-' ? th.costBasis : ''}
                            </StyledCol> */}
                        </StyledRow>
                    );
                })}
            </>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            menuArray: [],
            tradeLocation: {},
            redirectToPurchaseFunds: false,
            redirectToLiquidationFunds: false,
            redirectToExchangeFunds: false,
            selectedFund: {},
            fundSummaryClicked: false,
            idOfCurrentFocusElement: '',
        };
        this.tickerMenuItemRefs = React.createRef(null);
        this.onNavigate = this.onNavigate.bind(this);
        this.handleFundSummary = this.handleFundSummary.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { masterRegistrationAccountData, masterId } = nextProps;
        const {
            redirectToPurchaseFunds,
            redirectToLiquidationFunds,
            redirectToExchangeFunds,
            selectedFund,
        } = prevState;
        let tradeLocation = {};

        if (masterRegistrationAccountData.masterRegAccountList?.length > 0) {
            // Purchase funds
            if (redirectToPurchaseFunds && selectedFund && masterId !== 0) {
                tradeLocation = {
                    pathname: '/transactionPurchases',
                    state: {
                        pageIndex: 1,
                        masterId,
                        fundNumber: selectedFund.fundNumber,
                        fundAccountNumber: selectedFund.fundAccountNumber,
                    },
                };
            }
            // Liquidation funds
            if (redirectToLiquidationFunds && selectedFund && masterId !== 0) {
                tradeLocation = {
                    pathname: '/liquidation',
                    state: {
                        pageIndex: 1,
                        masterId,
                        fundNumber: selectedFund.fundNumber,
                        fundAccountNumber: selectedFund.fundAccountNumber,
                    },
                };
            }
            // Exchange funds
            if (redirectToExchangeFunds && selectedFund && masterId !== 0) {
                tradeLocation = {
                    pathname: '/exchange',
                    state: {
                        pageIndex: 1,
                        masterId,
                        fundNumber: selectedFund.fundNumber,
                        fundAccountNumber: selectedFund.fundAccountNumber,
                    },
                };
            }
        }
        return {
            ...prevState,
            tradeLocation,
        };
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onClickFund = fundIndex => e => {
        e.stopPropagation();
        const { tHolder } = this.props;
        const { menuArray } = this.state;

        if (menuArray.length > 0) {
            this.handleOutsideClick();
            if (menuArray[+fundIndex]) {
                return;
            }
        }
        const temp = [];
        const l = tHolder.length;
        for (let i = 0; i < l; i += 1) {
            temp[+i] = false;
        }
        temp[+fundIndex] = true;
        this.setState({
            menuArray: temp,
            // will remove this when we get FundNumber from API response
            selectedFund: tHolder[+fundIndex] ? tHolder[+fundIndex] : [],
            idOfCurrentFocusElement: 'buytradeButtonMenuItem',
        });
        setTimeout(() => {
            document.getElementById('buytradeButtonMenuItem').focus();
        });
    };

    onNavigate = fundIndex => e => {
        //   debugger
        e.preventDefault();
        const { menuArray } = this.state;
        const { getAccountList } = this.props;
        const arr = [
            document.getElementById('buytradeButtonMenuItem'),
            document.getElementById('selltradeButtonMenuItem'),
            document.getElementById('exchangetradeButtonMenuItem'),
            document.getElementById('fundSummaryButtonMenuItem'),
        ];
        // arrow up
        if (e.which === 38) {
            if (e.target.id === 'buytradeButtonMenuItem') {
                arr[3].focus();
            }
            if (e.target.id === 'selltradeButtonMenuItem') {
                arr[0].focus();
            }
            if (e.target.id === 'exchangetradeButtonMenuItem') {
                arr[1].focus();
            }
            if (e.target.id === 'fundSummaryButtonMenuItem') {
                arr[2].focus();
            }
        }
        // arrow downnn
        if (e.which === 40) {
            if (e.target.id === 'buytradeButtonMenuItem') {
                arr[1].focus();
            }
            if (e.target.id === 'selltradeButtonMenuItem') {
                arr[2].focus();
            }
            if (e.target.id === 'exchangetradeButtonMenuItem') {
                arr[3].focus();
            }
            if (e.target.id === 'fundSummaryButtonMenuItem') {
                arr[0].focus();
            }
        }
        // click event that navigate to buy/sell/exchange
        if (e.which === 13 && fundIndex !== '' && fundIndex !== undefined && e.target.id === 'buytradeButtonMenuItem') {
            getAccountList({ companyNumber: '591', token: localStorage.getItem('token') }, true, 'purchase');
            this.setState({ redirectToPurchaseFunds: true });
        }
        if (
            e.which === 13 &&
            fundIndex !== '' &&
            fundIndex !== undefined &&
            e.target.id === 'selltradeButtonMenuItem'
        ) {
            getAccountList({ companyNumber: '591', token: localStorage.getItem('token') }, true, 'liquidate');
            this.setState({ redirectToLiquidationFunds: true });
        }
        if (
            e.which === 13 &&
            fundIndex !== '' &&
            fundIndex !== undefined &&
            e.target.id === 'exchangetradeButtonMenuItem'
        ) {
            getAccountList({ companyNumber: '591', token: localStorage.getItem('token') }, true, 'exchange');
            this.setState({ redirectToExchangeFunds: true });
        }
        if (e.which === 13 && e.target.id === 'fundSummaryButtonMenuItem') {
            this.handleFundSummary(fundIndex)(e);
        }
        if (e.which === 27 || e.which === 9) {
            this.setState({
                menuArray: !menuArray,
            });
            setTimeout(() => {
                document.getElementById('tickerButton').focus();
            });
        }
    };

    handleOutsideClick = e => {
        if (e) {
            if (e.target.id === 'tradeButton') {
                return;
            }
            if (e.target.id === 'buytradeButtonMenuItem' || e.target.id === 'buytradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'selltradeButtonMenuItem' || e.target.id === 'selltradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'exchangetradeButtonMenuItem' || e.target.id === 'exchangetradeButtonMenuOptions') {
                return;
            }
            if (e.target.id === 'fundSummaryButtonMenuItem' || e.target.id === 'fundSummaryButtonMenuOptions') {
                return;
            }
        }
        this.setState({ menuArray: [] });
    };

    onPurchaseClickFund = fundIndex => e => {
        e.stopPropagation();
        const { getAccountList, authBox } = this.props;
        this.setState({ redirectToPurchaseFunds: true });
        this.handleOutsideClick();

        //  make changes here
        if (fundIndex !== '' && fundIndex !== undefined) {
            if (isTokenAvailable() === null) {
                // setTokenWithExpiry('msr_auth','123456789',500000)
                authBox();
                // get data of master Reg
                getAccountList(
                    {
                        companyNumber: '591',
                        //token: localStorage.getItem('token'),
                        token: getTokenFromSession(),
                    },
                    true,
                    'purchase',
                );
            } else {
                // authBox();
                // get data of master Reg
                getAccountList(
                    {
                        companyNumber: '591',
                        //token: localStorage.getItem('token'),
                        token: getTokenFromSession(),
                    },
                    true,
                    'purchase',
                );
            }
        }
    };

    onLiquidationClickFund = fundIndex => e => {
        e.stopPropagation();
        const { getAccountList, authBox } = this.props;

        if (fundIndex !== '' && fundIndex !== undefined) {
            this.setState({ redirectToLiquidationFunds: true });
            this.handleOutsideClick();

            if (isTokenAvailable() === null) {
                authBox();
                // get data of master Reg
                getAccountList(
                    {
                        companyNumber: '591',
                        token: localStorage.getItem('token'),
                    },
                    true,
                    'liquidate',
                );
            } else {
                // get data of master Reg
                // authBox();
                getAccountList(
                    {
                        companyNumber: '591',
                        token: localStorage.getItem('token'),
                    },
                    true,
                    'liquidate',
                );
            }
        }
    };

    onExchangeClickFund = fundIndex => e => {
        e.stopPropagation();
        const { getAccountList, authBox } = this.props;

        if (fundIndex !== '' && fundIndex !== undefined) {
            // get data of master Reg
            this.setState({ redirectToExchangeFunds: true });
            this.handleOutsideClick();
            authBox();
            // get data of master Reg
            getAccountList(
                {
                    companyNumber: '591',
                    token: localStorage.getItem('token'),
                },
                true,
                'exchange',
            );
        }
    };

    handleFundSummary = fundNumber => e => {
        // debugger
        e.preventDefault();
        e.stopPropagation();
        const { getFundDetails, fundSummaryUpdate } = this.props;
        if (fundNumber && fundNumber !== '') {
            const payload = {
                fundNumber,
            };
            getFundDetails(payload);
            fundSummaryUpdate();
            this.setState({ fundSummaryClicked: true });
        }
    };

    backToViewPostion = () => {
        const { fundSummaryUpdate } = this.props;
        this.setState({ fundSummaryClicked: false });
        fundSummaryUpdate();
    };

    bindComponent() {
        const { tHolder, allowedTransactions, accOpeningReducerData, showAuthBox } = this.props;
        const { menuArray, tradeLocation, fundSummaryClicked, selectedFund } = this.state;

        if (
            fundSummaryClicked &&
            accOpeningReducerData[ActionTypes.GET_FUNDDETAILS] &&
            Object.keys(accOpeningReducerData[ActionTypes.GET_FUNDDETAILS]).length > 0
        ) {
            return (
                <FundSummary
                    accOpeningData={accOpeningReducerData}
                    selectedFund={selectedFund}
                    backToViewPostion={this.backToViewPostion}
                />
            );
        }
        return (
            <>
                <TransactionTableHeader />
                <div className="trans-table-start row">
                    {tradeLocation.pathname && showAuthBox === false && isTokenAvailable() && (
                        <Redirect to={tradeLocation} />
                    )}
                    <table className="table table-striped mb-0">
                        <TransactionTableList.TableBody
                            tickerMenuItemRefs={this.tickerMenuItemRefs}
                            onDetailsTab={this.onDetailsTab}
                            tHolder={tHolder}
                            onClickFund={this.onClickFund}
                            onNavigate={this.onNavigate}
                            onPurchaseClickFund={this.onPurchaseClickFund}
                            onLiquidationClickFund={this.onLiquidationClickFund}
                            onExchangeClickFund={this.onExchangeClickFund}
                            handleFundSummary={this.handleFundSummary}
                            menuArray={menuArray}
                            allowedTransactions={allowedTransactions}
                        />
                    </table>
                </div>
            </>
        );
    }

    render() {
        return <div role="table">{this.bindComponent()}</div>;
    }
}

TransactionTableList.defaultProps = {
    tHolder: [],
    masterRegistrationAccountData: [],
    getAccountList: () => {},
    masterId: '',
    allowedTransactions: [],
    getFundDetails: () => {},
    accOpeningReducerData: {},
    fundSummaryUpdate: () => {},
    getUnrealizedGainsValue: () => {},
    getOneDayChangePriceValue: () =>{}
};

TransactionTableList.propTypes = {
    tHolder: PropTypes.arrayOf(
        PropTypes.shape({
            ticker: PropTypes.string,
            fundName: PropTypes.string,
            currentValue: PropTypes.string,
            totalShares: PropTypes.string,
            nav: PropTypes.string,
            oneDayChangePrice: PropTypes.string,
            oneDayChangePercentage: PropTypes.string,
            getUnrealizedGainsValue: PropTypes.string,
            getOneDayChangePriceValue:PropTypes.string,
        }),
    ),
    getFundDetails: PropTypes.func,
    getAccountList: PropTypes.func,
    fundSummaryUpdate: PropTypes.func,
    masterRegistrationAccountData: PropTypes.instanceOf(Object),
    masterId: PropTypes.string,
    allowedTransactions: PropTypes.instanceOf(Object),
    accOpeningReducerData: PropTypes.instanceOf(Object),
    getUnrealizedGainsValue: PropTypes.instanceOf(Object),
    getOneDayChangePriceValue: PropTypes.instanceOf(Object),
};

export default TransactionTableList;
