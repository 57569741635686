// CONSTANTS
const PAGES = [
    "One Time Passcode Authentication",
    "Confirm One Time Passcode",
    "Setup Security Questions",
    "Personal Details",
    "Manage Address",
];
const PAGES_SIGNUP = [
    "Personal details",
    "Terms and Conditions",
    "Create Online ID",
    "Create Password",
];

const MSR_NUMBER = "+1-800-235-8396";

const KEYS_VAL = [
    { key: "memberId", description: "Member ID" },
    { key: "rank", description: "Shorthand Rank" },
    { key: "firstName", description: "First Name" },
    { key: "lastName", description: "Last Name" },
    { key: "ssnTin", description: "Social Security Number" },
    { description: "-" },
    { key: "dateOfBirth", description: "Date of Birth" },
    { description: "-" },
    { key: "email", description: "Email Address" },
    { description: "-" },
    { key: "primaryNumber", description: "Primary Phone Number" },
    { key: "secondaryNumber", description: "Secondary Phone Numer" },
];

const KEYS_VAL_SIGNUP = [
    { key: "prefix", description: "Salutation" },
    { key: "firstName", description: "First Name" },
    { key: "middleName", description: "Middle Name" },
    { key: "lastName", description: "Last Name" },
    { key: "suffix", description: "Name Suffix" },
    { description: "-" },
    { key: "phoneNumber", description: "Primary Phone Number" },
    { description: "-" },
    { key: "emailAddress", description: "Email Address" },
];
// const PAGE_HEADING = "REVIEW YOUR PERSONAL DETAILS";
const PAGE_HEADING = "Review Your Personal Details";

const LINE1_DESCRIPTION =
    "If you want to make changes to your personal information";
    
const LINE2_DESCRIPTION = "please call member service representative at ";

const LINE3_DESCRIPTION = "after completing this process.";


export {
    PAGES,
    MSR_NUMBER,
    KEYS_VAL,
    PAGE_HEADING,
    LINE1_DESCRIPTION,
    LINE2_DESCRIPTION,
    LINE3_DESCRIPTION,
    KEYS_VAL_SIGNUP,
    PAGES_SIGNUP
};