import axios from 'axios';

const AxiosMiddleWare = store => next => action => {
    axios.interceptors.response.use(
        res => res,
        err => {
            store.dispatch({ type: 'API_ERROR', payload: err });
            return err;
        },
    );

    return next(action);
};

export default AxiosMiddleWare;