import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { toBase64 } from '@aws-sdk/util-base64-browser';
import envConfig from 'env/EnvConfig';

const awsKey = envConfig.AWS_KMS_KEY;

const awsconfig = {
    "region": envConfig.AWS_PROJECT_REGION,
    "cmkArn": awsKey
};


function getKMSClient(info) {
  // console.log('@debug', awsconfig);
  const credentials = new AWS.Credentials(
    info.accessKeyId,
    info.secretAccessKey,
    info.sessionToken,
  );
  AWS.config.update({
    region: awsconfig.region,
    credentials,
    correctClockSkew: true,
  });
  return new AWS.KMS();
}

async function encrypt(buffer1) {
  const kmsCredentials = await Auth.currentCredentials();

  const kms = getKMSClient(kmsCredentials); 

  
  if(buffer1) {
    const params = {
      'KeyId':  awsKey,
      'EncryptionAlgorithm': 'SYMMETRIC_DEFAULT',
      'Plaintext': buffer1
    };
    const promise = new Promise(function(resolve, reject){
      // console.log('@debug for params ', params);
      kms.encrypt(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
          resolve(toBase64(data.CiphertextBlob));
          
        }
      });
    });
    return await promise;
  } 
    return null;
  
  
  
}
export default encrypt;
           