import { connect } from 'react-redux';
import { updateAccountLevelAddresses, clearExistingActions } from 'shared/Actions/ProfileSettingsAction';
import { setAddress, resetAddressInfo } from 'shared/Actions/AddressAction';
import { withNotification } from 'modules/CustomerManagementModule/Hoc';
import { analyzeClickEvent, analyzeFormFieldError, linkResponseError } from 'shared/Actions/AnalyticsActions';
import AddOrEditAccountAddress from './AddOrEditAccountAddress';

export const mapStateToProps = state => {
    const { profileInformationData, masterLookUpData, address } = state;
    const { isError } = profileInformationData;

    return {
        masterLookupStateData: masterLookUpData,
        profileInformationData,
        addressInfo: address,
        notify: isError,
        message: {
            message: null,
            info: null,
        },
    };
};

const mapDispatchToProps = {
    clearExistingActions,
    updateAccountLevelAddresses,
    setAddress,
    resetAddressInfo,
    analyzeClickEvent,
    analyzeFormFieldError,
    linkResponseError
};

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(AddOrEditAccountAddress));
