import React, { Component } from "react";
import { PropTypes } from 'prop-types';
import { Row, Col, Form } from "react-bootstrap";

const assignObj = (obj) => { return obj; }
const dobLabel = 'Date of Birth';
const monthDefVal = 'MM';
const dateDefVal = 'DD';
const yearDefVal = 'YYYY';
class DOB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: '',
      date: '',
      year: ''
    };
  }

  handleDOB = (e) => {
    const {setValue} =this.props;
      e.preventDefault();
      this.setState({[e.target.id]: e.target.value},()=>{
        const {month, date, year} = this.state;
          const dob={dob: `${month}/${date}/${year}`};
          setValue({dob})
      });
  }

  handleBlur = e => {
    if (!e.target.value) {
      e.target.style.outline = "1px solid red";
    }else {
      e.target.style.outline = "";
    }
  };

  render() {
    const {month, year} = this.state;
    const {id} = this.props;
    const monthA = [];
    for (let m = 1; m <= 12; m+=1) {
       const mV= m < 10 ? `0${m}` : m;
      monthA.push(
        <option key={m} value={mV}>
          {mV}
        </option>
      );
    }
    const isLeapYear = (yearS) => {
        return yearS ? ((yearS % 4 === 0 && yearS % 100 !== 0) || yearS % 400 === 0) : false;
      }
    let dateN = 31;
    if(month === '02') {        
        dateN = isLeapYear(year) ? 29 : 28;
    } else if(['04', '06', '09', '11'].indexOf(month) > -1 ){
        dateN = 30;
    }
    const dateA = [];
    for (let d = 1; d <= dateN; d+=1) {
        const dV=d < 10 ? `0${d}` : d;
        dateA.push(
          <option key={d} value={dV}>
            {dV}
          </option>
        );
      }
      const yearA = [];
    for (let y = 1900; y <= 2100; y+=1) {
        yearA.push(
          <option key={y} value={y}>
            {y}
          </option>
        );
      }
    return (
      <Form.Group as={Row} noGutters style={assignObj({width: '100%'})}>
        <Form.Label column sm={2} htmlFor={id}>
          {dobLabel}
        </Form.Label>
        <Col sm={4} id={id} style={assignObj({display: "flex"})}>
          <Form.Control as="select" onChange={this.handleDOB} onBlur={this.handleBlur} id="month" style={assignObj({width: 62, padding: 5, marginRight: '6%'})} required>
            <option value="">{monthDefVal}</option>
            {monthA}
          </Form.Control>
          <Form.Control as="select" onChange={this.handleDOB} onBlur={this.handleBlur} id="date" style={assignObj({width: 62, padding: 5, marginRight: '6%'})} required>
            <option value="">{dateDefVal}</option>
            {dateA}
          </Form.Control>
          <Form.Control as="select" onChange={this.handleDOB} onBlur={this.handleBlur} id="year" style={assignObj({width: 80, padding: 5, marginRight: '6%'})} required>
            <option value="">{yearDefVal}</option>
            {yearA}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  }
}

DOB.propTypes={
  id:PropTypes.string,
  setValue:PropTypes.func
}

DOB.defaultProps={ 
  id:'',
  setValue:null
  
}

export default DOB;
