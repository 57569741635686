/*
 * Component Name : AccountMessagingMainComponent
 * Author : Sharanya Bothumanchi
 * Modified Date: 13/12/2019
 * 
*/

/*
 * Dashboard which will have all following options to manage the customer information
 * Basic Information - Information of a customer
 * Delivery Settings
 * Account Messaging
 * Security
 * Marketing & Privacy
 * 
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardDeck, Col } from 'react-bootstrap';
import { WCard, CommonButtons, WBreadCrumb, WMessageBar } from '../../../common';
import styles from './profileStyles';
// import listIcon from "../../../assets/th-large-solid.svg";
import cardData from './constants';
import rightArrowGreen from "../../../assets/rightArrow_green.png";

const assignObj = (obj) => { return obj; }
const containerName = "Account Messaging"

const breadCrumbValues = [{ name: 'Home', url: '/' },{name: 'Profiles & Preferences', url:'/profile'}];

export default class AccountMessaginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardDeckData: cardData,
      notificationMsg: null
    }
    // this.cardClickHandler = this.cardClickHandler.bind(this);
  }

  /** event Name - cardClickHandler (Click Event)
    * Params - data - Selected crad data
    * when clicked on the card based on card title it will route to the particular screen
  */
  componentDidMount() {
    const { location } = this.props;
    const { message } = location || {};

    if (message) {
      this.setState({
        notificationMsg: message
      });
    }
  }

  cardClickHandler = (data) => () => {
    const routePath = data.cardHeading.toLowerCase().replace(/ /g, '');
    const { history } = this.props;
    if (history) {
      history.push(`/${routePath}`);
    }
  }

  backClick = () => {
    const { history } = this.props;
    if (history) {
      history.push(`/profile`);
    }
  }

  /** Renders Complete Components */
  render() {
    const { cardDeckData, notificationMsg } = this.state;
    const cardArray = cardDeckData.map(data => {
      return (
        /** WCardComponent common Card Component */
        <Col key={data.cardId} md={4} lg={4} xl={4}>
          <WCard
            className="rightArrow"
            variant='outline'
            cardtitletext={data.cardHeading}
            cardtext={data.cardText}
            buttonstyle={styles.disableStyle}
            cardstyle={styles.cardstyle}
            cardtextstyle={styles.cardtextstyle}
            cardtitlestyle={styles.cardtitlestyle}
            cardbodystyle={styles.cardbodystyle}
            alt="rightArrowGreen"
            onClick={this.cardClickHandler(data)}
            src={rightArrowGreen}
          />
        </Col>
      )
    })
    return (<div>
      {notificationMsg ? <WMessageBar text={notificationMsg} /> : null}
      <div className="MainContainer">
        {  /* 
        * Component : Header
        * Description :Displays a Header
        */
        }
        <div style={styles.containerStyle}>
          <div className="container">
            <WBreadCrumb
              breadCrumbItems={breadCrumbValues}
              activeCrumb="Account Messaging"
            />
            <div style={styles.manageClickButton} key="">
              <h1 style={styles.topLabelStyle}>{containerName}</h1>
              <CardDeck>
                {cardArray}
              </CardDeck>
            </div>
          </div>
        </div>
        <CommonButtons backClick={this.backClick}  showSave style={assignObj({ padding: 20, })} cancelstyle={assignObj({ display: 'none' })} savestyle={assignObj({ display: 'none' })} nextstyle={assignObj({display:'none'})} />
        {  /* 
        * Component : Footer
        * Description :Displays a Footer
        */
        }
      </div>
    </div>
    )
  }
}

AccountMessaginComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      message: PropTypes.string
    }),
  }),
};

AccountMessaginComponent.defaultProps = {
  history: {},
  location: {}
}
