import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DownloadFile from '../../../common/DownloadFile/DownloadFile';
import IconPdf from '../../../assets/icon_pdf.png';
import { BasicCard } from '../../../common';
import { removeFileExtension } from '../../../shared/Helpers/Utils';

const S = {};

S.Card = styled(BasicCard)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

S.CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`;

S.FormTitle = styled.h1`
    color: #004a98;
    font: 600 16px/22px benton-sans;
`;

S.Icon = styled(IconPdf)`
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.8125rem;
    margin-left: 1.25rem;
`;

const cardProps = {
    height: '121px',
    triSideLength: '35px',
};

const downloadLinkIconStyle = { width: 20, height: 20, cursor: 'pointer', marginLeft: 5 };

const downloadLinkStyle = { marginTop: 0 };
function FormDownloadCard({ alternateDocumentId, alternateDocumentLink, docCategory, documentSource, fileName: fileNameFromMetaData, nodeId, title, }) {

    let fileName;

    if (title) {
        fileName = removeFileExtension(title);
    } else {
        fileName = removeFileExtension(fileNameFromMetaData);
    }

    return (
        <>
            <S.Card {...cardProps}>
                <S.CardContent>
                    <S.FormTitle>{fileName}</S.FormTitle>
                    <DownloadFile
                      nodeId={alternateDocumentId || nodeId}
                      documentSource={documentSource}
                      docCategory={docCategory}
                      downloadUrl={alternateDocumentLink}
                      saveAs={fileNameFromMetaData}
                      iconSrc={IconPdf}
                      iconStyle={downloadLinkIconStyle}
                      linkStyle={downloadLinkStyle}
                    />
                </S.CardContent>
            </S.Card>
        </>
    );
};

FormDownloadCard.defaultProps = {
    title: '',
    fileName: '',
    nodeId: null,
    docCategory: null,
    documentSource: null,
    alternateDocumentId: null,
    alternateDocumentLink: '',
};

FormDownloadCard.propTypes = {
    title: PropTypes.string,
    nodeId: PropTypes.string,
    fileName: PropTypes.string,
    docCategory: PropTypes.string,
    documentSource: PropTypes.string,
    alternateDocumentId: PropTypes.string,
    alternateDocumentLink: PropTypes.string,
};

export default FormDownloadCard;
