import styled from 'styled-components';

const colorMapping = {
    green: '#8bc105',
    blue: '#486D90',
    grey: '#D2D2D2',
};

const getColor = (color) => {
    return colorMapping[`${color}`] || colorMapping.green;
}

const TopLeftCornerColor = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;

    position: relative;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: ${props => props.cornerSize || 25}px solid transparent;
        border-left: ${props => props.cornerSize || 25}px solid ${props => getColor(props.cornerColor)};
        position: absolute;
        top: -1px;
        left: -1px;
    }
`;

export default TopLeftCornerColor;