import consts from '../consts'
import IconAccount from 'assets/icon-account-info-big.svg';
import PropTypes from 'prop-types';
import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { WButton, AccordianSymbol, MaskText, WSpinner } from 'common';
import axios from 'axios'
import { Container,  Table } from 'react-bootstrap';
// import config from '../../../../../shared/Network/Config';
import Config from 'env/Config';
import { useSelector, useDispatch } from 'react-redux'
import MaskAccountNumber from 'common/MaskAccountNumber/MaskAccountNumber';
import envConfig from 'env/EnvConfig';



const VerticalSeperator = styled.div`
    border-left: 1px solid #cecece;
    margin-left: 1em;
    margin-right: 1em;
`;

const FlexDiv = styled.div`
    display: flex;
`;
const AccountTitleSection = styled(FlexDiv)`
    position: relative;
    margin: 0.875rem 2.4375rem;
    justify-content: space-between;
    opacity: 1;
    align-items: center;
    cursor: pointer;
`;
const AccountNameAndNumber = styled(FlexDiv)`
    position: relative;
    text-align: left;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #486d90;
    opacity: 1;
`;

const CardSymbol = styled.img`
    margin-right: 0.625rem;
    margin-left: 0.9375rem;
    width: 2.5rem;
    height: 2.5rem;
`;

const SelfCenteredDiv = styled.div`
    align-self: center;
    @media (max-width: 767px){
        align-self: flex-start!important;
    }
    span{
        @media (max-width: 767px){
            display:block;
        }
    }
`;
const StyledHr = styled.hr`
    margin-top: 0rem;
    margin-bottom: 0;
`;
const DetailsSec = styled(FlexDiv)`
    padding: 1.25rem 2.5rem;
    align-items: center;
    justify-content: space-between;
`;
const DetailColumns = styled(FlexDiv)``;
const AccountCardBottomColumn = styled(FlexDiv)`
    flex-direction: column;
    display: flex;
    justify-content: space-between;
`;
const AccountCardHeadingSpan = styled.span`
    margin-bottom: 0.625rem;
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
`;
const AccountCardValueSpan = styled.span`
    text-align: left;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    @media (max-width: 991px) {
        max-width: 116px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const RightExtendedVerticalSeperator = styled(VerticalSeperator)`
    margin: 0 2rem;
    @media (max-width: 991px) {
        margin: 0 0.5rem;
    }
`;

const MenuContainer = styled.div`
    position: relative;
`;

const primaryBene = 'Primary beneficiary';
const contigentBene = 'Contingent beneficiary';

export const KebabMenu = (props) => {
    const {
        showMenu,
        setShowView,
        showView,
        beneInfoItems,
        masterRegId,
        fetchData,
        setIsLoading,
    } = props;
 

   //this function will call when view button is clicked.
    const onEditClick = async() => {

        if(!showView){

            if(Object.keys(beneInfoItems).length === 0){

                setIsLoading(true);
                fetchData(masterRegId)  
            }
        }
        if(showView == false)
        {
            setShowView(!showView)
        }
        else
        {
            setIsLoading(true);
            fetchData(masterRegId)   
        }
       // setShowView(!showView)
    }

    return (
        <MenuContainer>
            <span style={{fontFamily:"yorkten-slab-normal,sans-serif",color:'#486d90',fontWeight:'800'}} onClick={onEditClick}>{showView == true ? `Refresh`: `View`}</span>
           
        </MenuContainer>
    )
}

KebabMenu.propTypes = {
    onMenuClick: PropTypes.func,
    showMenu: PropTypes.bool,
    onEditMenuClick: PropTypes.func,
    ariaLabelEdit: PropTypes.string,
}

KebabMenu.defaultProps = {
    onMenuClick: () => { },
    showMenu: false,
    onEditMenuClick: () => { },
    ariaLabelEdit: ''
}



const renderNoData = () => (
    <p className="no-beneficiary-data" style={{textAlign:'center'}}>This account currently has no beneficiaries on file.</p>
);

const renderMultipleBeneficiaries = () => (
    <p className="multiple-beneficiary-data" style={{textAlign:'center', padding:'1.5rem', marginLeft:'-1.5rem'}}>Multiple beneficiaries are currently on the file. To update your beneficiaries, select “Update”. To review your existing beneficiaries, please contact a Member Service Representative at 1-800-235-8396.</p>
);

//this function is called to show the table view of beneficiaries in the container 
const getBenDetailstable = (data, accType, beneType, errorText)=>{


    if((data.result === false)) {
        return renderMultipleBeneficiaries();
    }else if((data.result === true)){
        const primaryData = data.beneficiaries['primary_Bene'];
        const contigentData  = data.beneficiaries['contingent_Bene'];
    
        const beneTemp = beneType === 'Primary beneficiary' ? primaryData : contigentData
        if(data.beneficiaries.length < 1){
            return renderNoData();
        }else if(beneTemp.length > 0){
            const beneficiaryTableData = beneTemp.map(item => (
                <Table className="beneDetailsTable position-relative" tabIndex="0" role="presentation" key={`${item.key}`}>
                <thead>
                    <tr>
                       <th scope="col" style={{borderBottom: '0px'}}>{accType === 'Individual' ? 'Transfer on Death Beneficiaries' : 'Beneficiary Name'}</th>
                       <th scope="col" style={{borderBottom: '0px'}}>Relationship to Account Owner</th>
                       <th scope="col" style={{borderBottom: '0px'}}>Distribution Percentage</th>
               
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="name" data-label={accType === 'Individual' ? 'Transfer on Death Beneficiaries' : 'Beneficiary Name'} id={(`beneficiary_${item.bene_Name}`).replace(/\s/g, "")}>
                            {item.bene_Name}
                        </td>
                                
                        <td data-label='relationshipToAccountOwner'>
                            {item.relationship_To_Insured === '' ? 'Not Applicable' : 
                              getRelationShipLabel(item.relationship_To_Insured)
                            }               
                        </td>
                        <td className="distribution-percentage_cell"  data-label='distributionPercentage'>
                            <div style={{ position: 'relative' }}>
                                {item.distribution_Per} %
                            </div>
        
                        </td>
                              
                    </tr>
                </tbody>
            </Table>
            ))

            return beneficiaryTableData
      
    }}else {
        return <p style={{textAlign:'center', color: '#FF0000'}}>{errorText.errorMsg}</p>
    }
    
}


const getRelationShipLabel=(code) =>{
    let codeNumber = Number(code);
    if (!isNaN(codeNumber)) {
        switch (codeNumber) {
            case 0:
                return 'Spouse';
            case 1:
                return 'Non-spouse';
            default:
                return 'Not Applicable';
        }
    } else {
        return code ? code : 'Not Applicable';
    }
}

const AccountInfoCardTitle = props => {
    const { account, onMenuClick, onEditMenuClick, onAddClick, showMenu, displayAccordianSymbol, menuLabel,masterRegId } = props;
    const label = `Account Name ${account.accountName} with Account Number ${account.accountNumber}`
    const depositTo = account.depositTo === '01' ? 'Check' : (account.depositTo === '05' ? 'Bank Account' : '-')

    const [showView, setShowView] = useState(false)
    //const viewedData = beneInfoItems

    const [beneInfoItems, setbeneDetails] = useState({})
    const [errorState, seterrorState] = useState({error:false,errorMsg:''})
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

   

    // get item 
    const manageBeneficiariesDataFromLocalStorage = JSON.parse(sessionStorage.getItem("manageBeneficiaryData"));

    const [beneficiaryLocalData, setbeneficiaryLocalData] = useState(manageBeneficiariesDataFromLocalStorage  !=null ? manageBeneficiariesDataFromLocalStorage : [] )

    useEffect(() => {
        if(beneficiaryLocalData.length >= 1){
            beneficiaryLocalData.forEach((item) => { 
                if(item.masterRegId === masterRegId){
                    // setShowView(true)
                    setbeneDetails(item)
                }
            })
        }
       
    }, [])


    useEffect(() => {
    
    })

    //function for calling api data of beneficaries.
    const fetchData =  async (masterRegId) =>{
        
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': `${envConfig.API_KEY}`,
        }

        const payload = {
           "masterRegId": `${masterRegId}`
        }
        setIsLoading(true);
        try{
            
            const response = await axios.post(`${envConfig.API_SUB_DOMAIN}/${Config.postMsrBeneficiaries}`, 
            payload,{headers:headers})
            if(response.data && response.data.message != 'failure'){
                setShowView(true)
                let addMasterRegid = response.data
                addMasterRegid.masterRegId = masterRegId
                beneficiaryLocalData.push(addMasterRegid)

                setbeneDetails(addMasterRegid)
                setbeneficiaryLocalData(beneficiaryLocalData)
                const manageBeneficiariesDataTest = JSON.parse(sessionStorage.getItem("manageBeneficiaryData"));
                if(manageBeneficiariesDataTest != null)
                {
                    let tempManagerBeneficiariesData = JSON.parse(sessionStorage.getItem("manageBeneficiaryData",JSON.stringify(beneficiaryLocalData)));
                    tempManagerBeneficiariesData.push(addMasterRegid);
                    sessionStorage.setItem("manageBeneficiaryData",JSON.stringify(tempManagerBeneficiariesData))
                }
                else
                {
                    sessionStorage.setItem("manageBeneficiaryData",JSON.stringify(beneficiaryLocalData))
                }
                setIsLoading(false)
            }else{
                // setbeneDetails(viewData)
                const errorArray = ['4001','4002','4003','4004'];
                if(response.response.data.responseCode == errorArray[0]){
                    seterrorState(prevState => ({
                        ...prevState,
                            error: true,
                            errorMsg:response.response.data.result.exceptionDetails.logMessage,
                        }))
                }else if(response.response.data.responseCode == errorArray[1]){
                    seterrorState(prevState => ({
                        ...prevState,
                            error: true,
                            errorMsg:response.response.data.result.exceptionDetails.logMessage,
                        }))
                } else if(response.response.data.responseCode == errorArray[2]){
                    seterrorState(prevState => ({
                        ...prevState,
                            error: true,
                            errorMsg:response.response.data.result.exceptionDetails.logMessage,
                        }))

                } else if(response.response.data.responseCode == errorArray[3]){
                    seterrorState(prevState => ({
                        ...prevState,
                            error: true,
                            errorMsg:response.response.data.result.exceptionDetails.logMessage,
                        }))

                }
                setIsLoading(false);
            }
        }catch(error){
            seterrorState(prevState => ({
                ...prevState,
                    error: true,
                    errorMsg:'this is the error Msg'
                }))
            setIsLoading(false);    
        }
        
    }


    return (
        <div>
            <WSpinner loading={isLoading} />
            <AccountTitleSection className="account-section">
                <FlexDiv>
                    {displayAccordianSymbol ? (
                        <AccordianSymbol aria-label={label} />
                    ): null}
                    <CardSymbol src={IconAccount} alt="" />
                    <AccountNameAndNumber className="account-details">
                        <SelfCenteredDiv >{account.accountName === '' ? account.accountType : account.accountName}</SelfCenteredDiv>
                    </AccountNameAndNumber>
                </FlexDiv>
                {account.withdrawalPreference === 'No Distribution set up' ?
                    <WButton
                        buttontext='Add'
                        onClick={(event, account) => { onAddClick(event, account) }}
                    /> : <KebabMenu
                    beneInfoItems={beneInfoItems}
                    setbeneDetails={setbeneDetails}
                        dispatch={dispatch}
                        setIsLoading={setIsLoading}
                        masterRegId={account.key}
                        fetchData={fetchData}
                        onMenuClick={(event, account) => { onMenuClick(event, account) }}
                        showMenu={showMenu}
                        onEditMenuClick={(event, account) => { onEditMenuClick(event, account) }}
                        menuLabel={menuLabel}
                        setShowView={setShowView}
                        showView={showView}
                    />}

            </AccountTitleSection>


            {/*this is for opening the container when view button is clicked for primary and contigent is not empty */}
            {(showView && Object.keys(beneInfoItems).length > 0 && (beneInfoItems.beneficiaries != '' && beneInfoItems.beneficiaries != undefined)) && errorState.error == false  &&
                <>
                
                <div className="primaryBeneWrapper">
                <div className="benSectionHeaderWrapper" style={{backgroundColor:'#486D90'}}>
                    <h4 className="beneSectionHeading" style={{color:'white',marginLeft:'4vw'}} aria-hidden="true">{primaryBene}</h4>
                </div>
                                        {/* PRIMARY BENE */}
                <div className="benSectionTableWrapper">
                    <Container style={{width:'80vw'}}>
                        {getBenDetailstable(beneInfoItems,account.accountType,primaryBene)}
                    </Container>
                </div>
                </div>

                                        {/* Conti BENE and if conti bene is empty this will not show*/ }
                {beneInfoItems.beneficiaries.contingent_Bene == '' ? '' :
                    <div className="primaryBeneWrapper">
                        <div className="benSectionHeaderWrapper" style={{backgroundColor:'#486D90'}}>
                            <h4 className="beneSectionHeading" style={{color:'white',marginLeft:'4vw'}} aria-hidden="true">{contigentBene}</h4>
                        </div>  
                    
                        <div className="benSectionTableWrapper">
                            <Container style={{width:'80vw'}}>
                                {getBenDetailstable(beneInfoItems, account.accountType, contigentBene)}
                            </Container>
                        </div> 
                    </div>
                }
                
                </>
            }
            {/*this is for opening the container when view button is clicked for no bene and multiple bene */}
            {((showView && Object.keys(beneInfoItems).length > 0 && (beneInfoItems.beneficiaries == '' || beneInfoItems.beneficiaries == undefined)) || errorState.error == true) &&
              <>
                <div className="primaryBeneWrapper">
                <div className="benSectionHeaderWrapper" style={{backgroundColor:'#486D90'}}>
                    <h4 className="beneSectionHeading" style={{color:'white',marginLeft:'4vw'}} aria-hidden="true">{primaryBene}</h4>
                </div>
                                        {/* PRIMARY BENE */}
                <div className="benSectionTableWrapper">
                {console.log("done frf check ")}
                    <Container style={{width:'65vw'}}>
                        {getBenDetailstable(beneInfoItems,account.accountType,primaryBene,errorState)}
                    </Container>
                </div>
                </div>
              </>
            }

            {account.distribution ?
                <div>
                    <StyledHr />
                    <DetailsSec>
                        <DetailColumns>
                            <React.Fragment>
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan>{consts.RMDAccountDetails[0]}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>$ {account.priorYearEndBalance}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                                <RightExtendedVerticalSeperator />
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan>{consts.RMDAccountDetails[1]}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>$ {account.distribution}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                                <RightExtendedVerticalSeperator />
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan>{consts.RMDAccountDetails[2]}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>{depositTo}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                                <RightExtendedVerticalSeperator />
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan>{consts.RMDAccountDetails[3]}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>{account.taxWitholding === 'Y' ? 'Yes' : 'No'}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                                <RightExtendedVerticalSeperator />
                                <AccountCardBottomColumn>
                                    <AccountCardHeadingSpan>{consts.RMDAccountDetails[4]}</AccountCardHeadingSpan>
                                    <AccountCardValueSpan>{account.withdrawalPreference}</AccountCardValueSpan>
                                </AccountCardBottomColumn>
                            </React.Fragment>
                        </DetailColumns>
                    </DetailsSec> </div> : null}
        </div>

    );
};

AccountInfoCardTitle.propTypes = {
    account: PropTypes.object,
    onMenuClick: PropTypes.func,
    onEditMenuClick: PropTypes.func,
    onAddClick: PropTypes.func,
    displayAccordianSymbol: PropTypes.bool
};

AccountInfoCardTitle.defaultProps = {
    account: {},
    onMenuClick: () => { },
    onEditMenuClick: () => { },
    onAddClick: () => { },
    displayAccordianSymbol: true
};

export default AccountInfoCardTitle;
