import CommonButtons from './CommonButtons';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

export const BottomNavSection = props => {
    const {
        showBack,
        disabled,
        onSecondaryClick,
        onPrimaryClick,
        primaryText,
        onCancel,
        portalRoot,
        arialabelback,
        arialabelnext,
        dataAnalyticId,
        analyzecustom,
        iscancel,
        cancelClick,
        cancelOffset,
        cancelStyle,
        nextText,
        transactionType,
        cancelClickHandler,
    } = props;
    const navProps = {
        disabled,
        arialabelback,
        arialabelnext,
        dataAnalyticId,
        analyzecustom,
        iscancel,
        cancelClick,
        cancelOffset,
        cancelStyle,
        nextText,
        transactionType,
        cancelClickHandler,
    };
    if (showBack) {
        navProps.backClick = onSecondaryClick;
    } else {
        navProps.backClick = onCancel;
    }
    if(iscancel) {
        navProps.cancelClick = cancelClickHandler;
    }

    if (primaryText.toLowerCase() === 'submit') {
        navProps.submitClick = onPrimaryClick;
        navProps.submit = 'true';
    } else {
        navProps.nextClick = onPrimaryClick;
    }
    if (portalRoot.current) {
        return ReactDOM.createPortal(<CommonButtons {...navProps} />, portalRoot.current);
    }

    return (
        <></>
    );
};

BottomNavSection.propTypes = {
    showBack: PropTypes.bool,
    disabled: PropTypes.bool,
    primaryText: PropTypes.string,
    onCancel: PropTypes.func,
    onSecondaryClick: PropTypes.func,
    onPrimaryClick: PropTypes.func,
    portalRoot: PropTypes.instanceOf(Object),
    arialabelback: PropTypes.string,
    arialabelnext: PropTypes.string,
    dataAnalyticId: PropTypes.string,
    transactionType: PropTypes.string,
    cancelClickHandler: PropTypes.func,
};
BottomNavSection.defaultProps = {
    showBack: false,
    disabled: false,
    primaryText: 'Next',
    onCancel: () => {},
    onSecondaryClick: PropTypes.func,
    onPrimaryClick: PropTypes.func,
    portalRoot: null,
    arialabelback:'',
    arialabelnext:'',
    dataAnalyticId:'',
    transactionType: null,
    cancelClickHandler: () => {},
};

export default BottomNavSection;
