const accTypeDetails = {
    key: 'ira',
    value: [
        {
            key: 'ind',
            value: 'For Individuals',
            subtypes: [
                {
                    key: 'trad',
                    value: 'Traditional IRA',
                    description: 'Sample Content',
                },
                {
                    key: 'roth',
                    value: 'Roth IRA',
                    description: 'Sample Content',
                },
                {
                    key: 'rollover',
                    value: 'Rollover IRA',
                    description: 'Sample Content',
                },
            ],
        },
    ],
};

const nextStyle = {display:'none'};

const cancelButtonStyle = {
    marginBottom: '20px',
    marginTop:'20px'
  };

const btnStyle = {
    width: '100%'
}
const btnStyleNext = {
    width: '160px',
    float:'right'
}

const purchaseText = {
    paddingTop: '20px',
    paddingBottom: '30px',
    font: '16x/22px Benton sans-serif',
    color: '#56565a',
    
}

const wrapperStyles = {
    backgroundColor: '#F9FBFC',
    minHeight: '80vh',
    paddingTop: '30px',
    position: 'relative',
    color: '#56565A',
};
const accTypeHeading = {
    margin: '50px 0 10px',
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
};
const accSubTypeHeading = {
    margin: '30px 0 10px 15px',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '16px',
};

const accSubTypeList = {
    listStyleType: 'none',
    paddingLeft: '0',
    color: '#333333DE',
};
const accTypeColStyle = { textAlign: 'left' };
const radiodescstyles = {
    color: 'rgba(51, 51, 51, 0.75)',
    fontSize: 14,
};
const stepperStyle = { width: 200, height: 60, paddingRight: 90, paddingLeft: 150 };
const radiostyles = { color: 'rgba(51, 51, 51, 1)', fontSize: 16 };

const accountsAvailable = [
    {
        accountType: 'General Account',
        accounts: [
            {
                accName: 'Account Name 1',
                accNumber: {
                    text: 'Account Number1',
                    value: '4232-7856-1656',
                },
                taxDoc: {
                    text: 'Tax Documents',
                    value: 'Online',
                },
                report: {
                    text: 'Annual Reports',
                    value: 'Paper',
                },
                privacy: {
                    text: 'Privacy Promise (General Accounts)',
                    value: 'Online',
                },
            },
            {
                accName: 'Account Name 2',
                accNumber: {
                    text: 'Account NumberA',
                    value: '2344-6547-2344',
                },
                taxDoc: {
                    text: 'Tax DocumentA',
                    value: 'Online',
                },
                report: {
                    text: 'Annual ReportA',
                    value: 'Paper',
                },
                privacy: {
                    text: 'Privacy Promise (General Account)A',
                    value: 'Online',
                },
            }
        ],
    },
    {
        accountType: 'IRA Account',
        accounts: [
            {
                accName: 'Account Name 3',
                accNumber: {
                    text: 'Account Number',
                    value: '4232-7856-1657',
                },
                taxDoc: {
                    text: 'Tax Document',
                    value: 'Online',
                },
                report: {
                    text: 'Annual Report',
                    value: 'Paper',
                },
                privacy: {
                    text: 'Privacy Promise (General Account)B',
                    value: 'Online',
                },
            },
        ],
    },
    {
        accountType: 'UTMA Account',
        accounts: [
            {
                accName: 'Account Name 4',
                accNumber: {
                    text: 'Account Number',
                    value: '4232-7856-1658',
                },
                taxDoc: {
                    text: 'Tax Document',
                    value: 'Online',
                },
                report: {
                    text: 'Annual Report',
                    value: 'Paper',
                },
                privacy: {
                    text: 'Privacy Promise (General Account)C',
                    value: 'Online',
                },
            },
        ],
    },
];
export default {
    wrapperStyles,
    accTypeHeading,
    accSubTypeHeading,
    accSubTypeList,
    accTypeDetails,
    stepperStyle,
    radiodescstyles,
    accTypeColStyle,
    radiostyles,
    accountsAvailable,
    btnStyle,
    btnStyleNext,
    purchaseText,
    cancelButtonStyle,
    nextStyle
};
