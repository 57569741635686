/**
 * Investment Selection Payment Methods Component
 ****************************************
 *
 * @version 1.0.1
 * @author Karibandi Lalitha
 * @description This component generates the financial info details card to verify
 * @createdDate [24/03/2020]
 * ***************************************
 * @lastModifiedDate
 * @lastModifiedBy
 * @lastModifiedReason
 */
import Consts from './Consts';

export default function generateFinancialInfoCard(masterLookupStateData, finInfo, heading) {
    let financialInfoCard = {};
    const { staticfield } = Consts;
    if (finInfo) {
        let annualIncomeData = '';
        let netWorthData = '';
        let taxFilingData = '';
        if (finInfo.annualIncome) {
            const annualIncomeList = masterLookupStateData['annual_income'.toString()].value;
            annualIncomeData = annualIncomeList.find(item => item.key === finInfo.annualIncome).value;
        }
        if (finInfo.netWorth) {
            const netWorthList = masterLookupStateData['net_worth'.toString()].value;
            netWorthData = netWorthList.find(item => item.key === finInfo.netWorth).value;
        }
        if (finInfo.taxFilingStatus) {
            const taxFilingList = masterLookupStateData['tax_filling_status'.toString()].value;
            taxFilingData = taxFilingList.find(item => item.key === finInfo.taxFilingStatus).value;
        }
        financialInfoCard = {
            cardId: heading !== undefined ? 'jointFinancialInfo' : 'financialInfo',
            linkText: Consts.edit,
            cardHeading: heading !== undefined ? Consts.finJoint : Consts.finInfoH,
            fields: [
                {
                    uniqueKey: 'annualIncome',
                    key: Consts.annualInc,
                    value: annualIncomeData || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'taxBracket',
                    key: Consts.taxB,
                    value: finInfo.taxBracket || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'netWorth',
                    key: Consts.netW,
                    value: netWorthData || Consts.None,
                    type: staticfield,
                },
                {
                    uniqueKey: 'taxFilingStatus',
                    key: Consts.taxfile,
                    value: taxFilingData || Consts.None,
                    type: staticfield,
                },
            ],
        };
    }
    return financialInfoCard;
}
