import { connect } from 'react-redux';
import { get } from 'lodash';
import { orderCheckBookAction, analytics } from 'shared/Actions';
import  CheckbookMain from './CheckBookMain';

const mapStateToProps = (state) => {
    return {
        checkBookInfo: get(state,'orderCheckBookReducerData.checkBookInfo', []),
        isLoading: get(state,'orderCheckBookReducerData.isLoading', false),
        isError: get(state,'orderCheckBookReducerData.isError', false),
        isSuccess: get(state,'orderCheckBookReducerData.isSuccess', false),
        isLoadingPostUpdateCheckbook: get(state,'orderCheckBookReducerData.isLoadingPostUpdateCheckbook', false),
        isErrorPostUpdateCheckbook: get(state,'orderCheckBookReducerData.isErrorPostUpdateCheckbook', false),
        isSuccessPostUpdateCheckbook: get(state,'orderCheckBookReducerData.isSuccessPostUpdateCheckbook', false),
        updateError: get(state,'orderCheckBookReducerData.error', null)
    }
}

const mapDispatchToProps = {
    getCheckBookInfo : orderCheckBookAction.getCheckBookInfo,
    updateCheckbook: orderCheckBookAction.updateCheckbook,
    analyzeClickEvent: analytics.analyzeClickEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckbookMain);