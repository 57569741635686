import React from 'react';
import PropTypes from 'prop-types';
// import {Spinner} from 'react-bootstrap';
import Lottie from 'react-lottie';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './WSpinner.css';
import styles from '../CommonStyles';
import loader from '../../assets/loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const WSpinner = ({ loading }) => {
    return (
        <div>
            <div>
                {loading && (
                    // <ReactCSSTransitionGroup
                    //     transitionLeaveTimeout={0}
                    //     transitionEnterTimeout={0}
                    //     transitionName="loading"
                    // >
                        <div key={1} style={styles.spinnerDiv} className="example vcmSpinner" aria-busy="true" aria-live="polite">
                            {/* <Spinner key={3} animation="border" variant="primary" style={styles.spinnerStyle} /> */}
                            <Lottie options={defaultOptions} height={340} width={150} />
                        </div>
                    // </ReactCSSTransitionGroup>
                )}
            </div>
        </div>
    );
};

WSpinner.propTypes={
    loading: PropTypes.bool,
}

WSpinner.defaultProps={
    loading: false     
}

export default WSpinner;



